import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from "../../../../../../../utils/hook";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import _ from "lodash";
import { strParams } from "../../../../../../../utils/publicFun";
import { Loader, Messager } from "rootnet-ui";
import gd from "../../../../../../../base/global";
import { API2 } from "rootnet-core/base";
import { Button, Empty, Modal, Upload } from "antd";
import { base64Encode } from "../../../../../../common/base64Func";
import { FormInput } from "rootnet-edit";
import { UploadOutlined } from "@ant-design/icons";
import './DevProjectStageUploadArea.scss'
import { useFuncCode } from "../../../../../../common/commonMethod";

function DevProjectStageUploadArea(props) {
    const { id, allUserRes, stageDocList, stageObj, updateDoc, setShowList, stageIndex, setStageDocList, setEditFormData, funcCode, setFileCount, isUpload = true, isDel = true } = props
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const { doFetch } = useApi()
    const fileAction = useRef()
    const { data: fileListRes, doFetch: getFileList } = useGet()
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [renameFileInfo, setRenameFileInfo] = useState()
    const [rename, setRename] = useState()
    const [renameConfirmLoading, setRenameConfirmLoading] = useState(false)
    const { doFetch: updateStage } = usePost()
    const [inited, setInited] = useState(false)
    const { data: filePreviewUrlOptions } = useGet('common/globalconst?globalConst=filePreviewUrl')

    const filePreviewUrl = useMemo(() => {
        return _.get(_.head(filePreviewUrlOptions), 'interiorId')
    }, [filePreviewUrlOptions])

    const refresh = useCallback((newStageDocList) => {
        const queryParams = {
            referenceId: id,
            funcCode
        }
        getFileList('/mapping/files/queryNew?' + strParams(queryParams)).then(res => {
            const currentStageDocList = _.isNil(newStageDocList) ? stageDocList : newStageDocList
            const stageDoc = _.filter(res, x => _.includes(currentStageDocList, x.uid))
            if (setFileCount) {
                setFileCount(_.size(stageDoc))
            }
            const handleList = _.map(stageDoc, x => ({
                ...x,
                originalName: x.name,
                name: `【${_.get(_.find(allUserRes, item => item.userAccount === x.createUser), 'userName')}】${x.fullName}`
            }))
            setFileList(handleList)
        })
    }, [getFileList, id, allUserRes, stageDocList, funcCode, setFileCount])

    useEffect(() => {
        if (_.isNil(stageDocList)) return
        if (inited) return
        if (_.isNil(id)) return
        refresh(stageDocList)
        setInited(true)
    }, [id, refresh, inited, stageDocList])

    const handleCancel = useCallback(() => {
        setPreviewVisible(false)
    }, [])

    const handlePreview = useCallback(async (file) => {
        if (!isImg(_.get(file, 'fullName'))) return
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    }, [])

    const handleChange = useCallback(({ file, fileList: newFileList }) => {
        if (!_.isNil(_.get(file, 'url')) || !_.isNil(_.get(file, 'response.url'))) {
            const currentItem = ({
                uid: _.get(file, 'response.uid') || _.get(file, 'uid'),
                type: _.get(file, 'type'),
                name: _.get(file, 'response.name') || _.get(file, 'name'),
                status: 'done',
                url: _.get(file, 'response.url') || _.get(file, 'url'),
            })
            const url = fileAction.current === 'add' ? '/mapping/files/add' : '/mapping/files/delete'
            let postParams
            if (fileAction.current === 'add') {
                postParams = [{ ...currentItem, referenceId: id, funcCode }]
            } else {
                const uid = _.get(file, 'response.uid') || _.get(file, 'uid')
                const fileId = _.get(_.find(fileListRes, x => x.uid === uid), 'id')
                postParams = [fileId]
            }
            doFetch(url, 'post', postParams).then(() => {
                Messager.show(fileAction.current === 'add' ? '添加成功' : '删除成功', { icon: 'success' });

                const uid = _.get(file, 'response.uid') || _.get(file, 'uid')
                const newStageDocList = fileAction.current === 'add' ? _.concat([], stageDocList || [], [uid]) : _.filter(stageDocList, x => x !== uid)
                const newStage = {
                    ...stageObj,
                    relateDocument: _.join(newStageDocList, ',')
                }
                updateStage('/userProject/stage/update', newStage).then(() => {
                    if (setShowList) {
                        setShowList(oldList => {
                            return _.map(oldList, (item, i) => {
                                if (i !== stageIndex) return item
                                return ({
                                    ...item,
                                    relateDocument: _.join(newStageDocList, ',')
                                })
                            })
                        })
                    }
                    if (setEditFormData) {
                        setEditFormData(old => ({
                            ...old,
                            relateDocument: _.join(newStageDocList, ',')
                        }))
                    }

                    if (setStageDocList) {
                        setStageDocList(newStageDocList)
                    }
                    if (updateDoc) {
                        updateDoc()
                    }
                    setLoading(false)
                    refresh(newStageDocList)
                }).catch((err) => {
                    Messager.show(err._message, { icon: 'error' })
                })
            }).catch((err) => {
                Messager.show(err._message, { icon: 'error' })
            })
        }
        const postFileList = _.map(newFileList, x => ({
            ...x,
            id: _.get(x, id),
            uid: _.get(x, 'response.uid') || _.get(x, 'uid'),
            type: _.get(x, 'type'),
            name: _.get(x, 'response.name') || _.get(x, 'name'),
            status: 'done',
            url: _.get(x, 'response.url') || _.get(x, 'url'),
        }))
        setFileList(postFileList)
        if (setFileCount) {
            setFileCount(_.size(postFileList))
        }
    }, [fileAction, doFetch, id, refresh, fileListRes, updateDoc, stageDocList, stageObj, updateStage, stageIndex, setShowList, setStageDocList, setEditFormData, funcCode, setFileCount])

    const showUploadList = useMemo(() => {
        return ({
            showPreviewIcon: true,
            showDownloadIcon: true,
            showRemoveIcon: isDel,
        })
    }, [isDel])

    const checkFileSize = useCallback((file) => {
        if (file.size / 1024 / 1024 < 30) {
            return true
        } else {
            Messager.show('上传文件大小不超过30M', { icon: 'error' });
            refresh()
            return false
        }
    }, [refresh])

    const customRequest = useCallback((options) => {
        setLoading(true)
        fileAction.current = 'add'
        const { onSuccess, onError, file } = options;
        if (!checkFileSize(file)) {
            return setLoading(false)
        }
        const fileData = new FormData()
        fileData.append('file', file)
        let postParams = {
            bucketName: 'requirement',
            createUser: gd._user.operator_name,
        }
        API2.post('/minioCommon/uploadFile?' + strParams(postParams), fileData).then(res => {
            const fileItem = {
                uid: res.data.id,
                name: `${res.data.name}${res.data.ext}`,
                status: 'done',
                url: res.data.url,
                createUser: gd._user.operator_name,
            }

            onSuccess(fileItem)
        }).catch(err => {
            onError()
            setLoading(false)
            Messager.show(err._message, { icon: 'error' });
        })
    }, [checkFileSize])

    const onDownload = useCallback((file) => {
        const url = _.get(file, 'url') || _.get(file, 'response.url')
        window.open(url, "_self")
    }, [])

    const onRemove = useCallback((file) => {
        fileAction.current = 'remove'
        const postParams = {
            id: _.get(file, 'response.uid') || _.get(file, 'uid'),
            updateUser: gd._user.operator_name,
        }
        API2.post('/minioCommon/removeFile', postParams).then(() => {
            return true
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
            return false
        })
    }, [])

    const [delAuth] = useFuncCode(['0000'])

    const itemRender = useCallback((originNode, file) => {
        const createUser = _.get(file, 'createUser') || _.get(file, 'response.createUser')
        return <div className={`list-render flex center-y ${(createUser === gd._user.operator_name || delAuth) ? '' : 'can-not-remove-item'}`}>
            {originNode}
            {
                <Button className={'rename-btn'} onClick={() => {
                    if (createUser === gd._user.operator_name) {
                        setRenameFileInfo(file)
                    }
                }} disabled={createUser !== gd._user.operator_name}>重命名</Button>
            }
            <Button className={'rename-btn'} onClick={() => {
                const previewParams = `${_.get(file, 'url')}&fullfilename=${_.trim(_.get(file, 'fullName'))}`
                const lastDotIndex = _.lastIndexOf(previewParams, '.')
                const suffix = previewParams.substring(lastDotIndex)
                const name = previewParams.slice(0, lastDotIndex) + _.get(file, 'id')
                const handleParams = name + _.toLower(suffix)
                const previewUrl = encodeURIComponent(base64Encode(handleParams))
                window.open(filePreviewUrl + '/onlinePreview?url=' + previewUrl)
            }}>预览</Button>
        </div>
    }, [filePreviewUrl, delAuth])

    const closeRenameModal = useCallback(() => {
        setRename(null)
        setRenameFileInfo(null)
        setRenameConfirmLoading(false)
    }, [])

    const renameSubmit = useCallback(() => {
        setLoading(true)
        setRenameConfirmLoading(true)
        const postParams = {
            id: _.get(renameFileInfo, 'response.uid') || _.get(renameFileInfo, 'uid'),
            name: rename
        }
        API2.post('/minioCommon/modifyFileName', postParams).then(() => {
            setLoading(false)
            Messager.show("重命名成功", { icon: "success" });
            closeRenameModal()
            refresh()
            setRenameConfirmLoading(false)
        }).catch(err => {
            setLoading(false)
            Messager.show(err._message, { icon: 'error' });
            setRenameConfirmLoading(false)
        })
    }, [renameFileInfo, rename, closeRenameModal, refresh])

    const renameButtonProps = useMemo(() => {
        return ({
            disabled: _.isNil(rename) || rename === ''
        })
    }, [rename])

    const showDisplay = useCallback(({ value }) => {
        return <div style={{ width: 300 }}>
            {value}
        </div>
    }, [])

    return <div className={`dev-project-stage-upload-area flex-y ${_.isEmpty(fileList) ? 'empty-field-list' : ''}`}>
        <Upload
            customRequest={customRequest}
            listType="picture"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            onDownload={onDownload}
            onRemove={onRemove}
            showUploadList={showUploadList}
            itemRender={itemRender}
        >
            {isUpload && <UploadButton />}
        </Upload>
        <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            zIndex={3000}
            style={{ maxHeight: '100%', maxWidth: '96%', top: 10 }}
            width={'unset'}
        >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Modal
            visible={!_.isNil(renameFileInfo)}
            title={'重命名'}
            onCancel={closeRenameModal}
            onOk={renameSubmit}
            zIndex={3000}
            okText="确认"
            cancelText="取消"
            okButtonProps={renameButtonProps}
            confirmLoading={renameConfirmLoading}
        >
            <FormInput labelWidth={100} horizontal label='原名' value={_.get(renameFileInfo, 'originalName')} component={showDisplay} />
            <div />
            <FormInput labelWidth={100} componentWidth={300} horizontal label='重命名' value={rename} onChange={setRename} />
        </Modal>
        {loading && <Loader fill />}
        {_.isEmpty(fileList) && !loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </div>
}

function isImg(name) {
    let idx = _.lastIndexOf(name, '.')
    if (idx === -1) {
        console.warn('name格式不正确，请检查');
        return;
    }
    let type = name.substr(idx + 1);
    const imgArr = [
        'bmp', 'jpg', 'png', 'tif', 'gif',
        'pcx', 'tga', 'exif', 'fpx', 'svg',
        'psd', 'cdr', 'pcd', 'dxf', 'ufo',
        'eps', 'ai', 'raw', 'WMF', 'webp'
    ];
    return imgArr.indexOf(type) !== -1;
}

function UploadButton() {
    return <Button icon={<UploadOutlined />}>点击上传附件</Button>
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default DevProjectStageUploadArea;
import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import './CustomerSystemMgt.scss'
import CustomerSystemAdd from "./customerSystemAdd/CustomerSystemAdd";
import _ from "lodash";
import convertTableAlign from "../../common/view/convertTableAlign";
import findConvert from "../../common/view/findConvert";
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";
import ViewArea from "../../common/view/ViewArea";
import {Box} from "../../common/commonComponent";
import { DataGrid, Pagination } from "rootnet-ui";
import {TextIconBtn} from "../../common/TextIconBtn";
import CustomerDetail from "./customerDetail/CustomerDetail";
import {useFuncCode} from "../../common/commonMethod";
import { pathSearchFor } from '../../../utils/publicFun';
import { isNil } from 'rootnet-core/format';

const ENTER_DETAIL_FIELD = 'bug_project.projectName'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setDetailInfo } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o, i, d) => convertEditField(v, o, fieldItem, d)
            }
        }
    }

    function convertEditField(v, o, fieldItem, listData){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if(tableField === ENTER_DETAIL_FIELD){
            const switchList = _.map(listData, x => ({text: findConvert(fieldItem,_.get(x,fieldItem.columnId),convertCollection, dateOptions), value: x.id}))
            return <div className={'common-display-field enter-detail-field-wrap flex center-y'} onClick={()=> {
                setDetailInfo({
                    id: _.get(o,'id'),
                    mode: 'detail',
                    switchList: switchList,
                })
            }}>
                <div className="enter-detail-field">
                    {showValue || '-'}
                </div>
            </div>
        }
        return <div className={'common-edit-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '2502'

function CustomerSystemMgt(props) {
    const {detailInfo, setDetailInfo, currentId, setCurrentId, refreshViewCount} = props
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(100)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [currentInfo, setCurrentInfo] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

    useEffect(()=>{
        if(refreshViewCount === 0) return
        refreshList()
    },[refreshViewCount, refreshList])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams:{
            setDetailInfo
        }})

    const [addAuth] = useFuncCode(['250201'])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            {
                addAuth &&
                <TextIconBtn icon='tianjia' text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
            }
        </div>
    }, [addAuth])

    const onRowClick = useCallback((item) => {
        setCurrentId(item.id)
    },[setCurrentId])

    useEffect(()=>{
        if(_.isNil(currentId)) return
        _.forEach(dataGridList, o => {
            return o._rowClass = o?.id === currentId ? 'select_row' : ''
        })
        forceUpdate()
    },[currentId,dataGridList])

    return <div className={`customer-system-mgt fill flex-y ${!isNil(detailInfo)?'hidden-page':''}`}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize}}/>
        <Box title={'客户系统管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
            <DataGrid option={options} data={dataGridList} onRowClick={onRowClick}/>
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        {
            _.get(currentInfo, 'mode') === 'add' &&
                <CustomerSystemAdd close={()=>setCurrentInfo(null)} refreshViewList={refreshList}/>
        }
        {/*<CustomerSystemDetailDialog/>*/}
    </div>
}

// const initInfo = {
//     // id: '167045686435516416',
//     id: '167045702566809600',
//     mode: 'detail'
// }

function CustomerSystem(props){
    const { location } = props;
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [detailInfo, setDetailInfo] = useState(isNil(initId) ? null : {id: initId})
    const [currentId, setCurrentId] = useState(initId)
    const [refreshViewCount, refreshViewList] = useReducer((x) => x + 1, 0)

    return <>
        {
           !isNil(detailInfo) && <CustomerDetail {...{detailInfo, setDetailInfo, setCurrentId, refreshViewList}}/>
        }
        <CustomerSystemMgt {...{detailInfo, setDetailInfo, currentId, setCurrentId, refreshViewCount}}/>
    </>
}

export default CustomerSystem;
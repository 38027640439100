import _ from "lodash";
import {dateFormat} from 'rootnet-core/dateFormat';
import { isNil, number_format } from "rootnet-core/format";
import convertOptions from "../ConvertOptions";

export default function findConvert(filedItem, value, convertCollection, dateOptions = []){
    const isDate = _.get(filedItem,'fieldHtmlType') === "5"
    if(isDate){
        if(_.isNil(value)) return null
        const dateFormatTarget = convertOptions(_.get(filedItem,'dateStyle') || null, dateOptions)
        if(_.isNil(dateFormatTarget)) return null
        return _.get(filedItem,'dateStyle') ? dateFormat(convertOptions(_.get(filedItem,'dateStyle') || null, dateOptions) || 'YYYY-MM-DD',new Date(value)) : value
    }

    // 数字精度
    if(!isNil(_.get(filedItem,'accuracy'))){
        return number_format(value,'N'+filedItem.accuracy)
    }

    const isMultiple = _.includes(['3','8'],filedItem.fieldHtmlType)
    const showValue = isMultiple?_.split(value,/、|,|\^/):value
    const convertRule = _.find(convertCollection, (v,k) => k === `${filedItem.tableName}.${filedItem.fieldId}`)
    if(_.isNil(convertRule)){
        return value
    }else{
        if(isMultiple){
            const filterValue = _.filter(showValue,v => !isNil(v))
            return _.join(_.map(filterValue, x => convertOptions(x, convertRule, 'text','value',{showOrigin: true})),'，')
        }else{
            return convertOptions(value, convertRule,'text','value',{showOrigin: true})
        }
    }
}
import React, {useCallback, useState} from 'react';
import {Dialog, Loader, Messager} from "rootnet-ui";
import {FormInput} from "rootnet-edit";
import UserSelect from "../../common/personSelectPop/UserSelect";
import _ from 'lodash'
import {usePost} from "rootnet-biz/es/hooks";

function WorkFlowBatchCurrentUserDialog(props) {
    const {list, funcCode, businessType, close, afterRefresh} = props
    const [currentUser, setCurrentUser] = useState()
    const {doFetch: submitPost} = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const postParams = {
            businessList: list,
            funcCode,
            businessType,
            currentUser
        }
        submitPost('/WorkflowBusiness/updateCurrentUser',postParams).then(()=>{
            setSubmitLoading(false)
            afterRefresh()
            close()
        }).catch(err => {
            Messager.show(err._message, {icon: 'error'})
            setSubmitLoading(false)
        })
    },[list,funcCode, businessType, submitPost, submitLoading, close, afterRefresh, currentUser])

    return <Dialog header={'批量修改处理人'} confirmButtonDisabled={_.isNil(currentUser)} confirm={submit} cancel={close}>
        {
            submitLoading && <Loader fill/>
        }
        <FormInput label={'处理人'} value={currentUser} onChange={setCurrentUser} component={UserSelect} horizontal labelWidth={80} componentWidth={180}/>
    </Dialog>
}

export default WorkFlowBatchCurrentUserDialog;
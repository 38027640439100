import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './MenuInfo.scss'
import {TextIconBtn} from "../../../../common/TextIconBtn";
import {Display, FormInput, Input, RadioGroup, Select, Form} from "rootnet-edit";
import convertOptions from "../../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import {Button, Loader, Messager} from 'rootnet-ui'
import {Empty} from "antd";
import {usePost} from "rootnet-biz/es/hooks";

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=functype',
    '/common/globalconst?globalConst=state',
]

function MenuInfo(props) {
    const {id, refreshViewList, currentSystemList, treeOptions, refreshTreeList} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [mode, setMode] = useState('detail')
    const [editFormData, setEditFormData] = useState()
    const {data: initDetail, doFetch: getDetail} = useGet()
    const [formError, setFormError] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)
    const {doFetch: submitPost} = usePost()

    const refreshDetail = useCallback(()=>{
        setMode('detail')
        if(_.isNil(id)) return
        getDetail('/uac/menu/info?id='+id).then(res => {
            setEditFormData(res)
        })
    },[id,getDetail])

    useEffect(() => {
        refreshDetail()
    },[refreshDetail])

    const [funcTypeOptions, statusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const isDetail = useMemo(()=>{
        return mode === 'detail'
    },[mode])

    const canSubmit = useMemo(()=>{
        return !_.some(_.values(formError),x=>x)
    },[formError])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        submitPost('/uac/menu/edit',editFormData).then(()=>{
            setSubmitLoading(false)
            Messager.show('修改成功', { icon: 'success' })
            refreshViewList()
            refreshTreeList()
            setMode('detail')
            refreshDetail()
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitLoading, editFormData, submitPost, refreshDetail, refreshViewList, refreshTreeList])

    return <div className={'menu-info-wrap flex-y'}>
        {
            _.isNil(id) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        {
            submitLoading && <Loader fill/>
        }
        {
            !_.isNil(id) &&
            <div className="has-data-info-wrap flex-y">
                <div className="header flex">
                    <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail?'进入编辑':'退出编辑'}
                                 onClick={()=>{
                                     setMode(old => old === 'detail'? 'edit': 'detail')
                                     setEditFormData(initDetail)
                                 }}/>
                </div>
                <div className="menu-info flex-y">
                    <Form value={editFormData} onChange={setEditFormData} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
                        <HFormInput label={'编码'} bind={'funcCode'} component={isDetail? Display: Input} required/>
                        <HFormInput label={'菜单名称'} bind={'funcName'} component={isDetail? Display: Input} required/>
                        <HFormInput label={'类型'} bind={'funcTypeStr'} component={isDetail? Display: Select} required
                                    options={funcTypeOptions} convert={v => convertOptions(v, funcTypeOptions)}/>
                        <HFormInput
                            label={'状态'}
                            bind={'statusStr'}
                            component={isDetail? Display: RadioGroup}
                            options={statusOptions}
                            convert={v => convertOptions(v, statusOptions)}
                            required
                        />
                        <HFormInput label={'上级菜单'} bind={'pid'} clear component={isDetail? Display: Select} tree options={treeOptions}
                                    convert={v => convertOptions(v, currentSystemList)}/>
                        <HFormInput label={'排序号'} bind={'order'} component={isDetail? Display: Input}/>
                        <HFormInput label={'icon'} bind={'icon'} component={isDetail? Display: Input}/>
                        <HFormInput label={'url'} bind={'menuUrl'} component={isDetail? Display: Input}/>
                        <div className={'role-name-wrap flex'}>
                            <div className="mock-label">所属角色</div>
                            <div className={'role-name'}>
                                {_.get(editFormData,'roleNames')}
                            </div>
                        </div>
                    </Form>
                </div>
                {
                    !isDetail &&
                    <div className="footer flex">
                        <Button primary disabled={!canSubmit} onClick={submit}>保存</Button>
                        <Button normal onClick={()=>{
                            setMode( 'detail')
                            setEditFormData(initDetail)
                        }}>取消</Button>
                    </div>
                }
            </div>
        }
    </div>
}

export default MenuInfo;
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/es/hooks'
import { Display, FormInput } from 'rootnet-edit'
import { DataGrid, Tooltip } from 'rootnet-ui'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../../components'
import convertOptions from '../../../../common/ConvertOptions'
import AutoTextarea from '../../autoTextarea'
import './index.scss'

const RELEASE_VERSION_URL = '/bug/tracer/detail/release'

function getColumns(props) {
  const { allUserRes } = props
  return [
    { header: '版本编号', bind: 'releaseID', width: 140, tooltip: true, },
    { header: '版本日期', bind: 'issueDate', width: 90, tooltip: true, align: 'center', convert: v => dateFormat('YYYY-MM-DD', toDate.str14ToDate(v)) },
    { header: '版本负责人', bind: 'functionary', width: 90, tooltip: true, align: 'center', convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '版本状态', bind: 'state', width: 90, tooltip: true, align: 'center' },
    { header: '客户系统', bind: 'mulCustID', width: 185, convert: v => <Tooltip title={v}><div className='ellipsis-line'>{v}</div></Tooltip> },
  ]
}

const gridOptsFor = (columns) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  autoFill: true,
  virtualized: true,
  columns,
})

export default function PostMessage(props) {
  const { id, allUserRes, isDetail, editFormData, setEditFormData } = props
  const [imprintExpand, setImprintExpand] = useState(true)
  const [releaseVersionExpand, setReleaseVersionExpand] = useState(true)
  const { data, loading, doFetch } = useGet()
  const option = useMemo(() => gridOptsFor(getColumns({ allUserRes })), [allUserRes])

  useEffect(() => {
    if (_.isNil(id)) return
    doFetch(`${RELEASE_VERSION_URL}?tracerId=${id}`)
  }, [doFetch, id])

  return (
    <div className='post-Message'>
      <div className="area-wrap">
        <div className="area-header flex center-y">
          <Icon
            name="arrow_drop_down"
            className='fold-icon'
            style={{ transform: imprintExpand ? 'none' : 'rotate(-90deg)' }}
            onClick={() => setImprintExpand(x => !x)}
          />
          菜单位置
        </div>
        <div className="area-content-wrap" style={{ height: imprintExpand ? '' : 0 }}>
          <FormInput
            label=''
            value={editFormData?.releaseNotes}
            counter={false}
            maxLength={99999}
            horizontal
            labelWidth={110}
            componentWidth={200}
            component={isDetail ? Display : AutoTextarea}
            onChange={v => setEditFormData(x => _.assign({}, x, { releaseNotes: v }))}
          />
        </div>
      </div>
      <div className="area-wrap">
        <div className="area-header flex center-y">
          <Icon
            name="arrow_drop_down"
            className='fold-icon'
            style={{ transform: releaseVersionExpand ? 'none' : 'rotate(-90deg)' }}
            onClick={() => setReleaseVersionExpand(x => !x)}
          />
          发布版本
        </div>
        <div className="area-content-wrap" style={{ height: releaseVersionExpand ? '' : 0 }}>
          <DataGrid loading={loading} data={data} option={option} />
        </div>
      </div>
    </div>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput, TextArea, RadioGroup } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import './index.scss'

const MAKE_TAGS_URL = '/verQuery/save'

export default function MakeTagsLog(props) {// buildinMethod, modifyCodeType, buildinMethodMemo
  const { refreshList, close, releaseID, signOpt, list, selectedKey } = props
  const [params, setParams] = useState()
  const { doFetch } = usePost()

  useEffect(() => {
    if (_.size(list) === 1) {
      const { buildinMethod, buildinMethodMemo } = _.head(list) || {}
      setParams({ buildinMethod, buildinMethodMemo })
    }
  }, [list])

  const confirm = useCallback(() => {
    const { buildinMethod, buildinMethodMemo } = params
    if (!_.includes(_.map(signOpt, o => o.value), buildinMethod)) return Messager.show('请填写制作方式', { icon: 'error' })
    if (_.size(buildinMethodMemo) <= 0 && params?.buildinMethod !== null) return Messager.show('请填写备注', { icon: 'error' })
    const addList = _.map(list, o => _.assign({}, o, { releaseID, modifyCodeType: o.modifiedType }, params))

    doFetch(MAKE_TAGS_URL, addList)
      .then(() => {
        close()
        refreshList()
        Messager.show('制作成功', { icon: 'success' })
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [close, doFetch, list, params, refreshList, releaseID, signOpt])

  const handChange = useCallback((o, k) => {//selectedKey
    if (k === 'buildinMethod') {
      const text = `${selectedKey}，直接获取`
      if (o[k] === '0') o.buildinMethodMemo = text
      else if (o[k] === '1') {
        if (o.buildinMethodMemo === text) o.buildinMethodMemo = ''
      }
      else if (o[k] === null){
        if (o.buildinMethodMemo === text) o.buildinMethodMemo = ''
      }
    }
    setParams(o)
  }, [selectedKey])

  return (
    <Dialog
      header='制作标记'
      cancel={close}
      confirm={confirm}
      className='make-tags-log'
      confirmButtonText='保存'
    >
      <Form value={params} onChange={handChange} >
        <FormInput required horizontal label='制作方式' bind='buildinMethod' component={RadioGroup} options={signOpt} />
        <FormInput
          required={params?.buildinMethod !== null}
          horizontal
          label='备注'
          bind='buildinMethodMemo'
          component={TextArea}
          labelWidth={80}
          componentWidth={360}
          counter={false}
          maxLength={5000}
          className={cls('reason', { error: (_.size(params?.buildinMethodMemo) <= 0 && params?.buildinMethod !== null) })}
        />
      </Form>
    </Dialog>
  )
}

import React, {useCallback, useMemo, useReducer, useState} from 'react';
import _ from "lodash";
import convertTableAlign from "../../common/view/convertTableAlign";
import findConvert from "../../common/view/findConvert";
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";
import ViewArea from "../../common/view/ViewArea";
import {Box} from "../../common/commonComponent";
import {DataGrid, Pagination, Switch} from "rootnet-ui";
import './MenuMgt.scss'
import clsx from "clsx";
import MenuMgtTwoColumns from "./menuMgtTwoColumns/MenuMgtTwoColumns";
import {TextIconBtn} from "../../common/TextIconBtn";
import {Popover} from "antd";
import {pathSearchFor} from "../../../utils/publicFun";
import MenuDetailDialog from "./menuDetailDialog/MenuDetailDialog";
import { useGet } from 'rootnet-biz/es/hooks';
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions';

const ENTER_DETAIL_FIELD = 'uac_sysfunc.funcName'
const ALL_SYSTEM_URL = '/common/globalconst?globalConst=System'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentInfo } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`

        if(tableField === ENTER_DETAIL_FIELD){
            return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
                <div className="enter-detail-field flex">
                    <div className="enter-detail-field-text" onClick={()=> {
                        setCurrentInfo({
                            id: _.get(o,'id'),
                            mode: 'detail'
                        })
                    }}>
                        {showValue || '-'}
                    </div>
                </div>
            </div>
        }

        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 100
    }
}

const FUNC_CODE = '880501'

function MenuMgt(props) {
    const { location } = props
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(100)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [isTree, setIsTree] = useState(false)
    const [showAddType, setShowAddType] = useState(false)
    const [initSystem, setInitSystem] = useState('BS')
    const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
    const [countInnerTree, updateInnerTree] = useReducer((x) => x + 1, 0)
    const [sortConfig, setSortConfig] = useState()
    const { data: allSystemRes } = useGet(ALL_SYSTEM_URL)

    const allSystemOptions = useMemo(()=>{
        if(_.isEmpty(allSystemRes)) return []
        return convertGlobalConstOptions(allSystemRes)
    },[allSystemRes])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions,
        getOptions, getColumns, columnsAppendParams: {setCurrentInfo},
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                const sortList = _.sortBy(data, x => x[sort.column])
                return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
            }
        },
    })

    const AddTypeSelect = useCallback(()=>{
        return <div className={'menu-system-add-group flex-y'}>
            {
                _.map(allSystemOptions, o => {
                    return <div className="menu-system-item flex center-y" key={o.value} onClick={()=>{
                        setInitSystem(o.value)
                        setCurrentInfo({mode: 'add'})
                        setShowAddType(false)
                    }}>
                        <div className="type-name">{o.text}</div>
                    </div>
                })
            }
        </div>
    },[allSystemOptions])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <Switch checkedComponent='树状' uncheckedComponent='平铺' checked={isTree} onChange={setIsTree}/>
            <Popover content={<AddTypeSelect/>} trigger="click" placement="bottomRight" open={showAddType} onOpenChange={setShowAddType}>
                <TextIconBtn text='新增' icon='tianjia'/>
            </Popover>
        </div>
    }, [isTree, showAddType])

    const showList = useMemo(() => {
        let showList = dataGridList || []
        if (!_.isNil(sortConfig)) {
            showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, dataGridList])

    return <div className={clsx('menu-mgt fill flex-y', {'is-tree': isTree})}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize}}/>
        <Box title={'菜单管理'} className='flex-y x-card-singlegrid view-table' data={list} extra={extra} loading={boxLoading} error={error}>
            <DataGrid option={options} data={showList} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        <MenuMgtTwoColumns refreshViewList={refreshList} {...{isTree, countInnerTree}}/>
        {
            !_.isNil(currentInfo) &&
            <MenuDetailDialog close={()=>setCurrentInfo(null)} refreshViewList={refreshList} {...{currentInfo, initSystem, updateInnerTree}}/>
        }
    </div>
}


export default MenuMgt;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './UsedProductDetail.scss'
import {Dialog, Messager} from 'rootnet-ui'
import {DatePicker, Form, FormInput, Select} from "rootnet-edit";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import {usePost} from "rootnet-biz/es/hooks";
import {dateFormat, toDate} from "rootnet-core/dateFormat";

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props}/>

const OPTIONS_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/common/globalconst?globalConst=broker',
]

function UsedProductDetail(props) {
    const {currentInfo, close, customerOnlyId, refreshList} = props
    const {id, mode} = useMemo(()=> currentInfo || {},[currentInfo])
    const [formData, setFormData] = useState()
    const [infoError, setInfoError] = useState()
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const {doFetch: submitPost} = usePost()
    const {doFetch: getDetail} = useGet()

    useEffect(()=>{
        if(_.isNil(id)) return
        getDetail('/use/product/useproductinfo?id='+id).then(res => {
            setFormData(res)
        })
    },[getDetail, id])

    const [productOptions, moduleOptionsRes, brokerOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4] = optionsRes
        const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        return [
            productOptions,
            d3,
            _.map(d4, x => ({text: x.interiorId, value: x.interiorId})),
        ]
    },[optionsRes])

    const moduleOptions = useMemo(()=>{
        if(_.isEmpty(moduleOptionsRes)) return []
        if(_.isNil(_.get(formData, 'productId'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === formData.productId)
        return _.map(filterModuleOptions, x => ({text: x.subSysName, value: x.subSysId, tag: x.productId}))
    },[formData, moduleOptionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            if(bind === 'productId'){
                cloneObj['subSysId'] = null
                cloneObj['extConntoBrokersList'] = null
            }
            return cloneObj
        })
    },[])

    const brokersRequired = useMemo(()=>{
        return _.get(formData,'productId') === 'TradeAdapter'
    },[formData])

    useEffect(()=>{
        if(brokersRequired){
            if(_.isEmpty(_.get(formData,'extConntoBrokersList'))){
                setInfoError(x=>_.assign({},x,{'extConntoBrokersList': '必填项！'}))
            }
        }else{
            setInfoError(x => _.assign({},x,{'extConntoBrokersList': null}))
        }
    },[formData,brokersRequired])

    const canSubmit = useMemo(()=>{
        return _.every(infoError, v => _.isNil(v))
    },[infoError])

    const submit = useCallback(()=>{
        const postParams = {
            ...formData,
            customerOnlyId: customerOnlyId
        }
        const url = mode === 'add' ? '/use/product/add' : '/use/product/update'
        submitPost(url,postParams).then(()=>{
            Messager.show("提交成功", { icon: 'success' })
            refreshList()
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitPost, formData, customerOnlyId, close, refreshList, mode])

    return <Dialog header={`使用产品`} className={'used-product-detail'} confirmButtonDisabled={!canSubmit} confirm={submit} cancel={close}>
        <Form value={formData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({},x,v))}>
            <HFormInput label={'产品'} bind={'productId'} required search component={Select} tree options={productOptions}/>
            <HFormInput label={'子产品'} bind={'subSysId'}  required search component={Select} options={moduleOptions} componentClass={'sub-sys-id-options'}/>
            <HFormInput label={'启用日期'} bind={'publicDate'} required component={DatePicker} bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}/>
            <HFormInput label={'对接券商'} bind={'extConntoBrokersList'} required={brokersRequired} disabled={!brokersRequired} search component={Select} options={brokerOptions} multiple/>
        </Form>
    </Dialog>
}

export default UsedProductDetail;
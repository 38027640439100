import _ from "lodash";

export function createLine(data){
    const projectList = _.filter(data, x => x.level === 1)
    const moduleList = _.filter(data, x => x.level === 2)
    const submoduleList = _.filter(data, x => x.level === 3)

    let links = [
        {
            source: `root`,
            target: `right-root`,
        }
    ]
    links = createBothSize(links, projectList, 'productAssociationSystemDos')
    links = createBothSize(links, moduleList, 'productAssociationSystemSonDos')
    links = createLeftLine(links, submoduleList)
    links = createGroupLine(links, data)
    return links
}

function createBothSize(links, list, childName){
    _.forEach(list, x => {
        const leftLine = {
            source: `left-${x.id}`,
            target: x.id,
        }
        const rightLine = {
            source: x.id,
            target: `right-${x.id}`,
        }
        links.push(leftLine)
        const childList = _.get(x,childName)
        if(childList.length !== 0)  links.push(rightLine)
    })
    return links
}

function createLeftLine(links, list){
    _.forEach(list, x => {
        const leftLine = {
            source: `left-${x.id}`,
            target: x.id,
        }
        links.push(leftLine)
    })
    return links
}

function createGroupLine(links, data){
    const groupByList = _.groupBy(data, x => _.get(x, 'group'))

    _.forEach(groupByList, (v,k)=>{
        if(k === 'undefined') return
        const head = _.head(v)
        const last = _.last(v)
        links.push({
            source: head.id,
            target: last.id,
            symbol: 'none',
        })
    })
    return links
}
import React from 'react'
import _ from 'lodash'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
// import { uniqKeyFor } from '../../../../project_share/utils/utils'

const { str14ToDate } = toDate

function getCollect(data, bind, fixed = 1) {
  const sum = _.reduce(data, (acc, o) => {
    return _.isNaN(_.toNumber(o[bind])) ? acc + 0 : acc + _.toNumber(o[bind])
  }, 0)

  return <div
    style={{ color: (bind === 'gapPersonnel' && sum.toFixed(1) >= 0.5) ? 'red' : 'black' }}
    className='summary-bold'>
    {sum.toFixed(fixed)}
  </div>
}

const summaryDom = <div className='total-row'><div className='summary'>汇总</div></div>

function getSum(data, bind) {
  const sum = _.reduce(data, (acc, o) => {
    return _.isNaN(_.toNumber(o[bind])) ? acc + 0 : acc + _.toNumber(o[bind])
  }, 0)
  return sum.toFixed(1)
}

function removerNan(num, isPercentage = false, fixed = 1) {
  const numVal = _.isNaN(_.toNumber(num)) ? 0.0 : _.round(_.toNumber(num), fixed)
  if (isPercentage) {
    if (_.indexOf((numVal * 100).toString(), '.') === -1) return `${numVal * 100}%`
    else {
      const l = _.get(_.split((numVal * 100).toString(), '.'), '[1][0]', '0')
      return `${parseInt(numVal * 100)}${l === '0' ? '%' : '.' + l + '%'}`
    }
  }
  return numVal
}

function getBind(bind, key) {
  return bind + key.substring(0, _.size(key) - 1)
}

export function personnelGapFun(data, flag = false, positionOptions) {
  if (flag) return []
  const modelData = _.groupBy(data, 'modelName')

  return _.map(modelData, (v, k) => {

    const moduleSummary = _.assign(..._.map(_.groupBy(v, 'month'), (list, key) => ({
      [getBind('demandResource', key)]: getCollect(list, 'demandResource'),
      [getBind('numberPersonnel', key)]: getCollect(list, 'numberPersonnel'),
      [getBind('gapPersonnel', key)]: getCollect(list, 'gapPersonnel'),
      [getBind('numberCurrent', key)]: getCollect(list, 'numberCurrent'),
      [getBind('recruitmentPlanned', key)]: getCollect(list, 'recruitmentPlanned', 0),
    })))

    const moduleCoefficient = summaryDom

    const listChildren = _.map(_.groupBy(v, 'postionName'), (val, key) => {

      const positionSummary = _.assign(..._.map(_.groupBy(val, 'month'), (list, keys) => ({
        [getBind('demandResource', keys)]: getCollect(list, 'demandResource'),
        [getBind('numberPersonnel', keys)]: getCollect(list, 'numberPersonnel'),
        [getBind('gapPersonnel', keys)]: getCollect(list, 'gapPersonnel'),
        [getBind('numberCurrent', keys)]: getCollect(list, 'numberCurrent'),
        [getBind('recruitmentPlanned', keys)]: getCollect(list, 'recruitmentPlanned', 0),
      })))

      const postCoefficient = summaryDom
      const keyData = _.head(val)
      return {
        all: key,
        key: `${keyData.model}${keyData.postion}${keyData.id}`,
        moduleCoefficient: postCoefficient,
        sortSerial: _.get(_.find(positionOptions, item => item.interiorId === _.get(_.head(val), 'postion')), 'sortSerial'),
        ...positionSummary,
        children: _.map(_.groupBy(_.orderBy(val, 'techLevel', 'desc'), 'techLevel'), (value, keys) => {

          const monthlyData = _.assign(..._.map(value, item => ({
            key: item.id,
            [getBind('demandResource', item.month)]: removerNan(item.demandResource),
            [getBind('numberPersonnel', item.month)]: removerNan(item.numberPersonnel),
            [getBind('gapPersonnel', item.month)]: removerNan(item.gapPersonnel),
            [getBind('numberCurrent', item.month)]: removerNan(item.numberCurrent),
            [getBind('recruitmentPlanned', item.month)]: removerNan(item.recruitmentPlanned, false, 0),
            [getBind('id', item.month)]: item.idPlan,
            [getBind('ids', item.month)]: item.id,
            moduleCoefficient: removerNan(item.moduleCoefficient, true),
            ..._.pick(item, ['model', 'postion'])
          })))

          return {
            all: keys,
            noKey: true,
            ...monthlyData,
          }
        })
      }
    })
    const keyData = _.head(v)
    return {
      all: k,
      key: `${keyData.model}${keyData.id}`,
      moduleCoefficient,
      ...moduleSummary,
      children: _.orderBy(listChildren, 'sortSerial', 'asc')
    }
  })
}

export function workloadFun(data, capacityData, flag = false) {
  if (flag) return []
  const listData = _.assign(..._.map(_.groupBy(capacityData, 'modelName'), (v, k) => {
    return {
      [k]: _.assign(..._.map(_.groupBy(v, 'month'), (val, key) => {
        return { [key]: _.sum(_.map(val, item => _.isNaN(_.toNumber(item.newProduct)) ? 0 : _.toNumber(item.newProduct))).toFixed(1) }
      }))
    }
  }))

  const modelData = _.groupBy(data, 'modelName')
  return _.map(modelData, (v, k) => {
    return _.assign(..._.map(v, item => {
      const differenceWorkload = removerNan(removerNan(item.forecastWorkload) - _.get(listData, `${k}.${item.month}`, '0.0'))
      return _.assign({
        [getBind('existingCapacity', item.month)]: _.get(listData, `${k}.${item.month}`, '0.0'), // 现有产能 removerNan(item.existingCapacity)
        [getBind('capacityExistingCapacity', item.month)]: removerNan(item.capacityExistingCapacity),
        [getBind('forecastWorkload', item.month)]: removerNan(item.forecastWorkload), // 预测工作量
        [getBind('differenceWorkload', item.month)]: differenceWorkload,//removerNan(item.differenceWorkload)
      }, _.pick(item, ['modelId', 'modelName', 'month']))
    }))
  })
}

export function totalAmountFun(data, flag = false) {
  if (flag) return []
  const modelData = _.groupBy(data, 'modelName')
  return _.map(modelData, (v, key) => {
    const summary = _.assign(..._.map(_.groupBy(v, 'month'), (list, keys) => ({
      [getBind('workLoad', keys)]: getCollect(list, 'workLoad'),
      [getBind('workLoads', keys)]: getSum(list, 'workLoad'),
    })))
    const summaryTotal = getCollect(v, 'workLoad')
    const summaryTotals = getSum(v, 'workLoad')
    return {
      id: _.head(v).id,
      model: key,
      ...summary,
      total: summaryTotal,
      totals: summaryTotals,
      children: _.map(_.groupBy(v, 'workTypeName'), (val, keys) => {
        const list = _.assign(..._.map(val, item => {
          return _.assign({
            model: keys,
            [getBind('workLoad', item.month)]: removerNan(item.workLoad),
            [getBind('id', item.month)]: item.id,
          }, _.pick(item, ['modelName', 'month']))
        }))
        const filterNum = _.filter(list, (v, k) => {
          return _.startsWith(k, 'workLoad') && !_.isNaN(_.toNumber(v))
        })
        const total = _.sum(_.map(filterNum, v => _.toNumber(v))).toFixed(1)
        return _.assign(list, { total, id: `${_.head(v).id}-${_.head(val).id}` })
      })
    }

  })
}

export function moduleInformationFun(data, flag = false, userPostOpt) {
  if (flag) return []
  let index = 0
  const modelData = _.groupBy(data, 'moduleName')
  return _.concat(..._.map(modelData, (v, k) => {
    index++

    const list = _.map(_.groupBy(v, 'positionName'), (val, key) => {
      return _.assign(..._.map(val, item => ({
        ..._.omit(item, 'techLevel'),
        [item.techLevel]: item.techLevelPercent,
        [`${item.techLevel}Id`]: item.id,
        sortSerial: _.get(_.find(userPostOpt, o => o.interiorId === item.position), 'sortSerial', '0'),
        id: `${item.id}-${item.id}`,
        index
      })))
    })
    return _.orderBy(list, 'sortSerial', 'asc')
  }))
}

export function existingPersonnelFun(data, flag = false) {
  if (flag) return []
  const userData = _.groupBy(data, 'userName')
  return _.map(userData, (v, k) => {
    const collect = _.map(v, item => {
      const effectiveDate = dateFormat('YYYY-MM-DD', str14ToDate(item.effectiveDate))
      const list = _.assign({}, item, { effectiveDate })
      return _.omit(list, ['userName', 'skill', 'techLevelNow', 'joinDateNow'])
    })
    return {
      ..._.head(v),
      collect,
      effectiveDate: dateFormat('YYYY-MM-DD', str14ToDate(_.get(_.head(v), 'effectiveDate'))),
      children: _.compact(_.map(v, (item, i) => {
        const effectiveDate = dateFormat('YYYY-MM-DD', str14ToDate(item.effectiveDate))
        const list = _.assign(item, { effectiveDate, collect })
        if (i === 0) return null
        return _.omit(list, ['userName', 'skill', 'techLevelNow', 'joinDateNow'])
      }))
    }
  })
}
import _ from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { Loader, Tooltip } from 'rootnet-ui'
import { Tree } from 'antd'
import { useTreeExpend } from '../../../../../../../commonV2/hooks'
import './index.scss'

export default function LeftList(props) {
  const { selectedKeys, setSelectedKeys, modifyTypeRes, loading } = props
  const wrapperDom = useRef()
  const { expendIcon, expend, wrapperEmpyt } = useTreeExpend({ wrapperDom })

  const modifyTypeOpt = useMemo(() => {
    if (_.isEmpty(modifyTypeRes)) return []
    return _.map(modifyTypeRes, o => ({ title: o.displayName, key: o.interiorId, }))
  }, [modifyTypeRes])

  useEffect(() => {
    if (_.isEmpty(modifyTypeOpt)) return
    setSelectedKeys([_.get(_.head(modifyTypeOpt), 'key')])
  }, [modifyTypeOpt, setSelectedKeys])

  return (
    <div className='left-list flex-y'>
      {
        expend && <>
          <div className='title'>
            <span>技术栈列表</span>
            <span>
              <Tooltip title='收起'>{expendIcon}</Tooltip>
            </span>
          </div>
          <div className='left-list-tree-data' style={{ width: 200 }}>
            {
              loading && <Loader fill />
            }
            <Tree
              blockNode
              treeData={modifyTypeOpt}
              selectedKeys={selectedKeys}
              // height={wrapperSize?.height - 16}
              onSelect={(_keys, item) => {
                const { node } = item
                setSelectedKeys([node.key])
              }}
            />

          </div>
        </>
      }

      {
        !expend && <>
          <div className='expendIconWrap'>
            <Tooltip title='展开'>{expendIcon}</Tooltip>
          </div>
          {wrapperEmpyt}
        </>
      }
    </div>
  )
}

import React from 'react'
import _ from 'lodash'
import { gd } from 'rootnet-core/base';
import './index.scss'

// http://bi.croot.com
// http://172.20.101.16:3300

export default function DevProjectStatement(props) {
  const { projectId } = props
  const http = _.split(window.location.href, '://')[0]

  const onload = (e) => {
    if (!sessionStorage.getItem('current_user')) {
      gd.User = null;
      sessionStorage.setItem('user-session-expired', 1);
      window.location.reload();
    }
  };
  return <iframe className="bi-page" title="bi" src={`${http}://bi.croot.com/#/login?ssoToken=${gd.User.uuid}&return=${encodeURIComponent(`/visualReportFull?initId=1151522173010051072&filterViewSql=userproject.projectID='${projectId}'`)}`} onLoad={onload} />
  // 
}

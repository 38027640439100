import React, { useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash'
import PersonDepartGroupSelectPop from "./PersonDepartGroupSelectPop";
import './index.scss'

function AssigningUser(props) {
    const { hiddenUserList, multiple, allowClear, mustList, close, groupValue, departValue } = props
    const [stateValue, setStateValue] = useState()
    const [isInited, setIsInited] = useState(false)

    const isControlled = useMemo(() => {
        return !_.isNil(props.value) || _.isNil(props.defaultValue)
    }, [props.value, props.defaultValue])

    const initData = useCallback((data) => {
        if (multiple) {
            let handleData
            if (_.isArray(data)) {
                handleData = data
            } else {
                handleData = data ? _.split(data, ',') : null
            }
            setStateValue(handleData)
        } else {
            setStateValue(data)
        }
        setIsInited(true)
    }, [multiple])

    useEffect(() => {
        if (isControlled) {
            initData(props.value)
        }
    }, [isControlled, initData, props.value])

    useEffect(() => {
        if (isInited && !isControlled) return
        if (_.isNil(props.value)) return
        initData(props.value)
    }, [props.value, initData, isInited, isControlled])

    useEffect(() => {
        if (isInited && isControlled) return
        if (_.isNil(props.defaultValue)) return
        initData(props.defaultValue)
    }, [props.defaultValue, initData, isInited, isControlled])

    const onSelectConfirm = useCallback(newValue => {
        setStateValue(newValue)
        if (props.onChange) {
            props.onChange(newValue)
        }
    }, [props])

    return <PersonDepartGroupSelectPop defaultValue={stateValue}
        {...{ onSelectConfirm, hiddenUserList, multiple, allowClear, mustList, close, groupValue, departValue }} />
}

AssigningUser.defaultProps = {
    placeholder: '请选择',
    multiple: true,
    disabled: false,
    allowClear: true,
    marginLeft: false,
}

export default AssigningUser;
import React from 'react'
import { FormInput } from 'rootnet-edit'
import { Button } from 'rootnet-ui'
import { Icon } from './../../../../components';
import _ from 'lodash'
import cls from 'clsx'
import './option.scss'

const btnList = [
	{ text: '上周', value: 'last' },
	{ text: '本周', value: '' },
	{ text: '下周', value: 'next' }
]

function Option(props) {
	const { search, weekChange, inpSearch, leftHid, rightConvert } = props

	return <div className='work-time-detail-opt'>
		<div className='optionLeft'>
			{
				!leftHid &&
				<FormInput onChange={inpSearch} prefix={<Icon name="sousuo" />} />
			}
			{
				_.map(btnList, ({ text, value }) => {
					return <Button 
						className={cls('btn', { active: value==='' })} 
						key={value} 
						onClick={() => weekChange(value)} 
					>
						{text}
					</Button>
				})
			}
		</div>
		<div className='optionRight'>
			{
				rightConvert && rightConvert
				
			}
			{
				!rightConvert &&
				<Button primary onClick={_.debounce(search, 300)}>提交</Button>
			}
		</div>
	</div>
}

export default Option
import React, { useCallback } from 'react'
import _ from 'lodash'
import { Icon } from '../../../../../../../../components'

export default function SelectTypeItem(props) {
  const { icon, data, clear, bind = 'value', label = 'text' } = props //header

  const onClear = useCallback((val) => {
    clear(x => _.filter(x, item => item[bind] !== val))
  }, [clear, bind])
  return (
    <div className='select-type-list'>
      {/* <div className='select-type-list-head'>{header}</div> */}
      <div className='select-type-list-content'>
        {
          _.map(data, item => {
            return <div className='select-item flex center-y' key={item[bind]}>
              <div>
                <Icon name={icon} style={{ marginRight: 8, fontSize: 16 }} />
                {item[label]}
                <span style={{ color: '#989EA4' }}>
                  {item?.indexValidFlag === 0 && '（离职）'}
                </span>
              </div>
              <Icon className='clear-icon' name='baocuo' onClick={() => onClear(item[bind])} />
            </div>
          })
        }
      </div>
    </div>
  )
}

import React, { useMemo, useState } from 'react';
import { Dialog, DataGrid } from 'rootnet-ui';
import _ from 'lodash';
import convertOptions from "../../../../common/ConvertOptions";
import { FormInput, TextArea } from "rootnet-edit";
import './DiffReasonDialog.scss'

function getOption() {
    return {
        autoFill: true,
        columns: [
            { header: '', bind: 'fieldName', align: 'right' },
            { header: '修改前', bind: 'beforeValue', width: 300, tooltip: true },
            { header: '修改后', bind: 'afterValue', width: 300, tooltip: true, style: { color: '#E15722' } },
        ]
    }
}

function DiffReasonDialog(props) {
    const { diffKeyList, oldObj, newObj, formConfig, submit, close, allUserRes, projectStatusOpt } = props
    const [editReason, setEditReason] = useState()

    const option = useMemo(() => {
        return getOption()
    }, [])

    const list = useMemo(() => {
        const initOptions = [
            {
                label: "项目经理",
                bind: "principal",
                convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'),
            },
            {
                label: "项目状态",
                bind: "projectStatus",
                convert: v => convertOptions(v, projectStatusOpt),
            },
            {
                label: "项目简介",
                bind: "projectDesc",
            },
        ]
        const allOptions = _.concat(initOptions, ..._.map(formConfig, 'children'))
        console.log(allOptions);
        return _.map(diffKeyList, key => {
            return {
                fieldId: key,
                fieldName: convertOptions(key, allOptions, 'label', 'bind'),
                beforeValue: convertValue(_.get(oldObj, key), key) || '-',
                afterValue: convertValue(_.get(newObj, key), key) || '-',
            }
        })
        function convertValue(value, key) {
            const formItem = _.find(allOptions, x => x.bind === key)
            const convert = _.get(formItem, 'convert')
            return convert ? convert(value) : value
        }
    }, [diffKeyList, oldObj, newObj, formConfig, allUserRes, projectStatusOpt])

    return <Dialog className={'diff-reason-dialog'} headerVisible={false} cancel={close} confirm={() => submit({ editReason, diffKeyList })}
        confirmButtonDisabled={(_.isNil(editReason) || editReason === '')}>
        <DataGrid option={option} data={list} />
        <FormInput label={'修改理由'} component={TextArea} value={editReason} onChange={setEditReason}
            componentWidth={720} required />
    </Dialog>
}

export default DiffReasonDialog;
import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { Popover, Checkbox, Badge, Table } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { ScheduleManagementContext } from '../../../../../common/Context'
import { selectOption } from '../../../../../common/commonMethod'
import { strParams } from '../../../../../../utils/publicFun'
import gd from '../../../../../../base/global'
import { getColumns } from '../../../../../versionMgt/versionMsg/components/versionDetail/components/permission'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import Options from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/options'
import './index.scss'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import JoinApply from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/joinApply'
import { InventoryExport, ScriptExport, ModifyFileExport, CheckListExport } from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/exportLog'
import RelevanceReq from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/relevanceReq/relevanceReq'
import RelevanceIssue from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/relevanceIssue/relevanceIssue'
import CopyMessage from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/copyMessage'
import DelMessage from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/delMessage'
import RegressionMessage from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/regressionMessage'
import ImportFromVersion from '../../../../../versionMgt/versionMsg/components/importFromVersion/ImportFromVersion'
import ShuttleColumn from '../../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/shuttleColumn'
import RequirementDetailDialog from '../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import DevListDetailDialog from '../../../../../devListMgt/devListDetailDialog'
import TrackingDetailDialog from '../../../../../trackingManagementMgt/components/trackingDetailDialog'
import IssueDetailDialog from '../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import DefectUpdateDialog from '../../../../../testMgt/defect/controls/DefectUpdateDialog'

const CheckboxGroup = Checkbox.Group;

const JOIN_APPLY_URL = '/releaseStory/story/log'
const REQ_LIST_URL = '/releaseStory/list'
const UNPLANNED_VERSION_URL = '/userProject/getNoReleaseTask'
const EXPORT_INVENTORY_LIST_URL = '/releaseStory/list/export'
const OPERATION_URL = '/ReleaseStory/addReleaseStoryList'
const EXPORT_REQ_MATRIX_URL = '/releaseStory/storyMatrix/export' //导出需求矩阵表
const ALL_COLUMNS_URL = '/releaseStory/getCustomShowField' //所有列表
const EXPORT_MEETING_LIST_URL = '/releaseStory/ratMeetingList/export'
const EDIT_URL = '/ReleaseStory/update'
const VERSION_LIST_URL = '/develop/release/list?stateList=T,C,L,R&versionType=0'
const ALL_LIST_URL = '/userProject/getAllReleaseTask'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=storyType', // 需求类型
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor', //工作流状态
  '/common/globalconst?globalConst=PRIORITYLIST', //优先级
  '/common/globalconst?globalConst=Yes_Or_No', //是否
]

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo', // 产品
  '/common/globalconst?globalConst=ProductLine', // 产品
  '/verQuery/queryCustOptions', // 客户系统
]

const LIST_URL = {
  undefined: ALL_LIST_URL, //所有版本
  'planned-version': REQ_LIST_URL, //已规划版本
  'no-plan-version': UNPLANNED_VERSION_URL, //未规划版本
}

const plainOptions = [
  { label: '父需求', value: 'FREQ' },
  { label: '需求', value: 'REQ' },
  { label: 'ISSUE', value: 'ISSUE' },
  { label: '开发自提单', value: 'DEV' },
  { label: '历史缺陷', value: 'DEFECT' },
  { label: '任务', value: 'TASK' },
]

const optValues = _.map(plainOptions, o => o.value)

export default function TaskVersion(props) {
  const { releaseIds, allUserRes, isReqCode, isProjectCode, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, isDisabled, principal, poUser, snowId, isShow, isUnplanned, projectId, projectName, mulcustId, isDisplay, currentSelect, isClient, versionOption } = props
  const [joinApply, setJoinApply] = useState()
  const [typeChange, setTypeChange] = useState(optValues)
  const [typeNum, setTypeNum] = useState({})
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [expanded, setExpanded] = useState(true)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [params, setParams] = useState()
  const [exportType, setExportType] = useState()
  const [actionType, setActionType] = useState()
  const [changeData, setChangeData] = useState({})
  const [editInfo, setEditInfo] = useState()
  const [untreatedNum, setUntreatedNum] = useState(0)
  const { data: listRes, doFetch, loading } = useGet()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const { doFetch: operationFetch } = usePost()
  const [showDetail, setShowDetail] = useState()
  const [totalWorkload, setTotalWorkload] = useState(0)
  const [openColumn, setOpenColumn] = useState(false)
  const { data: allColumns } = usePost(ALL_COLUMNS_URL)
  const { data: versionData } = useGet(VERSION_LIST_URL)

  const { data, doFetch: getList } = useGet()
  const [searchNum, setSearchNum] = useState()
  const isPlanning = useMemo(() => {
    return (isReqCode || isProjectCode || gd.User.operator_id === principal || gd.User.operator_id === poUser)
  }, [isReqCode, isProjectCode, principal, poUser])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionData)) return []
    return _.map(_.orderBy(versionData, 'issueDate', 'desc'), o => ({ text: o.releaseId, value: o.releaseId }))
  }, [versionData])

  useEffect(() => {
    setExpanded(true)
    setSelectedRows([])
    setExpandedRowKeys([])
    setSelectedRowKeys([])
    setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), !_.isNil(x?.updateTime) && { updateTime: { begin: null, end: null } }))
  }, [releaseIds])

  const refresh = useCallback(() => {
    if (_.size(_.split(releaseIds, ',')) !== 1) return
    getList(`${JOIN_APPLY_URL}?auditType=1&releaseStatus=C&releaseId=${releaseIds}`)
      .then(res => {
        setUntreatedNum(_.size(_.filter(res, o => o.auditFlag === 'U')))
      })
  }, [releaseIds, getList])

  useEffect(() => {
    refresh()
  }, [refresh])

  const [storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const [productTreeOptions, productOptions, customerSystemOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    return [
      productTreeOptions,
      _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag })),
      selectOption(d3, ['projectName', 'projectId']),
    ]
  }, [optionsRes])

  const dataSource = useMemo(() => {
    if (_.isEmpty(listRes)) return []
    return filterType(_.get(listRes, 'treeNodes', []), typeChange, releaseIds)
  }, [listRes, typeChange, releaseIds])

  const search = useCallback((par = {}) => {
    setSearchNum(_.size(_.keys(par)))
    const time = _.isNil(par?.updateTime) ? {} : { beginDate: par.updateTime.begin, endDate: par.updateTime.end }
    const current = _.includes([undefined, 'planned-version', 'no-plan-version'], currentSelect) ? currentSelect : 'planned-version'
    const pars = {
      undefined: _.assign({}, _.omit(par, ['updateTime']), { releaseId: releaseIds, projectId, releaseIdList: releaseIds }, time), //所有版本
      'planned-version': _.assign({}, _.omit(par, ['updateTime']), { releaseId: releaseIds }, time), //已规划版本
      'no-plan-version': _.assign({}, _.omit(par, ['updateTime']), { projectId, releaseIdList: releaseIds }, time), //未规划版本
    }[current]
    doFetch(`${_.get(LIST_URL, current)}?${strParams(pars)}`)
  }, [releaseIds, doFetch, currentSelect, projectId])

  const editChange = useCallback(({ newVal, oldVal, id }) => {
    if (newVal === oldVal || _.isNil(id)) { setEditInfo(null); return }
    const postParams = [
      {
        releaseid: releaseIds,
        storyid: id,
        regression: newVal,
      }
    ]
    operationFetch(EDIT_URL, postParams)
      .then(() => {
        search(params)
        setEditInfo(null)
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [operationFetch, releaseIds, search, params,])

  const dataManipulation = useCallback((par, flag = true) => {
    //releaseid: currentId, type, optionType: mode, storyidList
    operationFetch(OPERATION_URL, par)
      .then(() => {
        flag && search(params)
        flag && setChangeData({})
        if (_.includes(['delValues', 'copyValues'], actionType)) {
          setSelectedRowKeys([])
          setSelectedRows([])
        }

        Messager.show('成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [actionType, operationFetch, params, search])

  const reqList = useMemo(() => {
    return getReqList(_.get(listRes, 'treeNodes', []))
  }, [listRes])

  const issueList = useMemo(() => {
    return getIssueList(_.get(listRes, 'treeNodes', []))
  }, [listRes])

  const columns = useMemo(() => {
    return getColumns({
      productOptions, customerSystemOpt, storyTypeOptions, allUserRes, setActionType,
      setChangeData, isDisabled, expanded, setExpanded, setShowDetail, workflowOpt, priorityOpt, yseOrNoOpt, editInfo, setEditInfo, editChange: editChange, isPlanning,
      allColumns, customField: _.get(listRes, 'customField', ''), isWrap: _.get(listRes, 'isWrap', false), isShow, isDisplay,
    })
  }, [allUserRes, customerSystemOpt, expanded, isDisabled, productOptions, storyTypeOptions, workflowOpt, listRes, allColumns, priorityOpt, yseOrNoOpt, editInfo, isPlanning, editChange, isShow, isDisplay])

  const listExport = useCallback(() => {
    if (_.isNil(releaseIds)) return
    const time = _.isNil(params?.updateTime) ? {} : { beginDate: params.updateTime.begin, endDate: params.updateTime.end }
    const typeList = _.join(typeChange, ',')
    const current = _.includes([undefined, 'planned-version', 'no-plan-version'], currentSelect) ? currentSelect : 'planned-version'
    const par = {
      undefined: _.assign({}, _.omit(params, ['updateTime']), { releaseId: releaseIds, typeList, projectId }, time),
      'planned-version': _.assign({}, _.omit(params, ['updateTime']), { releaseId: releaseIds, typeList }, time),
      'no-plan-version': _.assign({}, { projectId, releaseIdList: releaseIds }),
    }[current]
    const url = {
      undefined: '/userProject/exportAllReleaseTask',
      'planned-version': EXPORT_INVENTORY_LIST_URL,
      'no-plan-version': '/userProject/exportNoReleaseTask',
    }[current]
    downloadGetProgress(`${url}?${strParams(par)}`, '导出列表', () => { })
  }, [releaseIds, downloadGetProgress, params, typeChange, projectId, currentSelect])

  const exportDemandMatrixList = useCallback(() => {
    if (_.isNil(releaseIds)) return
    downloadGetProgress(`${EXPORT_REQ_MATRIX_URL}?${strParams({ releaseId: releaseIds })}`, '导出需求矩阵列表', () => { })
  }, [releaseIds, downloadGetProgress])

  const exportMeetingList = useCallback(() => {
    if (_.isNil(releaseIds)) return
    downloadGetProgress(`${EXPORT_MEETING_LIST_URL}?${strParams({ releaseId: releaseIds })}`, '导出RAT会议清单', () => { })
  }, [releaseIds, downloadGetProgress])

  useEffect(() => {
    if (_.isEmpty(listRes)) return
    const tileList = tileData(parentReq(_.get(listRes, 'treeNodes', [])))
    const groupData = _.groupBy(tileList, 'type')
    const numObj = _.assign({}, ..._.map(plainOptions, o => ({ [o.value]: _.size(_.get(groupData, o.value, [])) })))
    setTypeNum(numObj)
  }, [listRes])

  useEffect(() => {
    search()
  }, [releaseIds, doFetch, search])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
  };

  useEffect(() => {
    if (actionType === 'delValues' || actionType === 'copyValues' || actionType === 'regressionValues') {
      if (_.size(selectedRows) === 0) {
        setActionType(null)
        Messager.show('请选择数据后再进行操作', { icon: 'error' })
      }
    }
  }, [actionType, selectedRows])

  const RightFun = useCallback(() => {
    const getNumOpt = _.map(plainOptions, o => {
      return _.assign({}, o, {
        label: o.value === 'FREQ' ? o.label : <span style={{ color: '#8A8888' }}>{`${o.label}：${_.get(typeNum, o.value, 0)}`}</span>
      })
    })
    return <div className='flex req-fun'>
      <div className='type-num'>
        <span>总工作量：{totalWorkload}</span>
      </div>
      <div className='type-change'>
        <CheckboxGroup options={getNumOpt} value={typeChange} onChange={v => {
          const filterList = _.filter(v, k => _.includes(['REQ', 'FREQ'], k))
          if (_.size(filterList) === 1 && _.includes(filterList, 'FREQ')) setTypeChange(x => {
            return _.includes(x, 'REQ') ? _.filter(v, k => k !== 'FREQ') : _.concat(v, ['REQ'])
          })
          else setTypeChange(v)
        }} />
      </div>
      {
        (!isUnplanned && isShow && (isReqCode || gd.User.operator_id === principal || gd.User.operator_id === poUser)) &&
        <Badge count={untreatedNum} size="small" offset={[8, 0]}>
          <TextIconBtn icon='' text='版本变动申请' onClick={() => setJoinApply(true)} />
        </Badge>
      }
      <MoreOperations {...{ setExportType, listExport, exportDemandMatrixList, exportMeetingList, isUnplanned, isShow }} />
      {
        !isUnplanned && isShow && isPlanning &&
        <ReleasePlanning setActionType={setActionType} isDisabled={isDisabled} />
      }
      <TextIconBtn icon='shezhi' text='' onClick={() => setOpenColumn(true)} />
    </div>
  }, [totalWorkload, typeChange, typeNum, listExport, exportDemandMatrixList, exportMeetingList, isReqCode, principal, poUser, untreatedNum, isPlanning, isDisabled, isShow, isUnplanned,])

  useEffect(() => {
    if (_.isEmpty(listRes)) return
    let filterData = parentReq(_.get(listRes, 'treeNodes', []))
    const tileList = tileData(filterData)
    filterData = tileList
    filterData = _.filter(filterData, o => _.includes(['REQ', 'ISSUE'], o.type))
    const sumWorkload = _.sum(_.map(filterData, o => _.toNumber(o.workload)))
    const sum = parseInt(sumWorkload * 100)
    setTotalWorkload(sum / 100)
  }, [listRes])

  useEffect(() => {
    if (_.isEmpty(dataSource)) return
    if (expanded) setExpandedRowKeys(getKey(dataSource))
    else setExpandedRowKeys([])
  }, [dataSource, expanded])

  return (
    <div className='dev-project-task-version version-requirement-mgt flex-y'>
      <div className='header'>
        <Options {...{ searchNum, params, setParams, search, productTreeOptions, customerSystemOpt, storyTypeOptions, workflowOpt, yseOrNoOpt, RightFun }} />
      </div>
      <div className='req-table'>
        <Table
          bordered
          size='small'
          rowKey={record => {
            if (record.type === 'TASK') return record.pid + record.storyId
            return record.storyId
          }}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          rowSelection={{ ...rowSelection, checkStrictly: false, selectedRowKeys }}
          pagination={false}
          scroll={{ y: 9999999, x: 1300 }}
          expandedRowKeys={expandedRowKeys}
          onExpand={(bool, row) => {
            if (bool) setExpandedRowKeys(x => [...x, row.storyId])
            else {
              const index = expandedRowKeys.findIndex((e) => e === row.storyId)
              const newArray = [...expandedRowKeys]
              newArray.splice(index, 1)
              setExpandedRowKeys(newArray)
            }
          }}
        />
      </div>
      {
        (joinApply && !_.isEmpty(allUserRes)) &&
        <JoinApply
          close={() => setJoinApply(false)}
          {...{ refresh, allUserRes, data, refreshHome: () => search(params) }}
        />
      }
      {
        exportType === 'releaseNote' &&
        <InventoryExport
          {...{ currentId: releaseIds, mulcustId, isClient, projectId, isAll: _.isNil(currentSelect) }}
          close={() => setExportType(null)}
        />
      }
      {
        exportType === 'script' &&
        <ScriptExport
          close={() => setExportType(null)}
          {...{ currentId: releaseIds, projectId, isAll: _.isNil(currentSelect) }}
        />
      }
      {
        exportType === 'modifyFile' &&
        <ModifyFileExport
          close={() => setExportType(null)}
          {...{ currentId: releaseIds, projectName, projectId, isAll: _.isNil(currentSelect) }}
        />
      }
      {
        exportType === 'checklist' &&
        <CheckListExport
          {...{ currentId: releaseIds, mulcustId, versionOption, isClient, isAll: _.isNil(currentSelect), projectId }}
          close={() => setExportType(null)}
        />
      }
      <RelevanceReq
        textHint='只允许导入版本所属项目下的需求'
        open={actionType === 'REQ'}
        appendObj={{ factorViewSql: `(View_Release_Story.projectId = '${projectId}')` }}
        loading={loading}
        data={reqList}
        releaseid={releaseIds}
        close={() => setActionType(null)}
        refresh={() => {
          search(params)
        }}
      />
      <RelevanceIssue
        textHint='只允许导入版本所属项目下的ISSUE'
        appendObj={{ factorViewSql: `(View_Release_Story.projectId = '${projectId}')` }}
        open={actionType === 'ISSUE'}
        loading={loading}
        data={issueList}
        releaseid={releaseIds}
        close={() => setActionType(null)}
        refresh={() => {
          search(params)
        }}
      />
      {
        (actionType === 'copy' || (actionType === 'copyValues' && _.size(selectedRows) > 0)) &&
        <CopyMessage
          {...{ projectId }}
          yseOrNoOpt={yseOrNoOpt}
          selectedRows={selectedRows}
          actionType={actionType}
          changeData={changeData}
          versionOpt={versionOpt}
          currentId={releaseIds}
          isDisabled={isDisabled}
          dataManipulation={dataManipulation}
          close={() => setActionType(null)}
        />
      }
      {
        (actionType === 'del' || (actionType === 'delValues' && _.size(selectedRows) > 0)) &&
        <DelMessage
          header={
            <span className='DelMessage-lz'>
              删除
              <span>（已选{actionType === 'del' ? _.size(changeData.storyidList) : _.size(selectedRows)}个）</span>
            </span>
          }
          close={() => setActionType(null)}
          currentId={releaseIds}
          actionType={actionType}
          changeData={changeData}
          selectedRows={selectedRows}
          dataManipulation={dataManipulation}
        />
      }
      {
        ((actionType === 'regressionValues' && _.size(selectedRows) > 0)) &&
        <RegressionMessage
          yseOrNoOpt={yseOrNoOpt}
          close={() => setActionType(null)}
          selectedRows={selectedRows}
          currentId={releaseIds}
          refresh={() => {
            search(params)
          }}
        />
      }
      {
        actionType === 'version' &&
        <ImportFromVersion close={() => setActionType(null)}
          refreshList={() => {
            refresh()
            search(params)
          }}
          {...{ currentId: releaseIds, snowId, projectId }} />
      }
      {
        actionType === 'planVersion' &&
        <ImportFromVersion close={() => setActionType(null)}
          versionType={false}
          refreshList={() => {
            refresh()
            search(params)
          }}
          appendObj={{ versionType: '1' }}
          {...{ currentId: releaseIds, snowId }} />
      }
      {
        openColumn && <ShuttleColumn
          {...{ allColumns, customField: _.get(listRes, 'customField', ''), isWrap: _.get(listRes, 'isWrap', false) }}
          refresh={() => {
            refresh()
            search(params)
            setSelectedRowKeys([])
            setSelectedRows([])
          }}
          close={() => setOpenColumn(false)}
        />
      }
      {
        _.get(showDetail, 'type') === 'REQ' &&
        <RequirementDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
          delAuth={reqDelAuth} storyTypeOptions={storyTypeOptions}
        />
      }
      {
        _.get(showDetail, 'type') === 'DEV' &&
        <DevListDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
          delAuth={reqDelAuth} storyTypeOptions={storyTypeOptions}
        />
      }
      {
        _.get(showDetail, 'type') === 'TASK' &&
        <TrackingDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
        />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }} showChildList={[]}
          {...{ isFuncCodeApply, isFuncCodeQaCheck }}
        />
      }
      {
        _.get(showDetail, 'type') === 'DEFECT' &&
        <DefectUpdateDialog
          mode={'detail'}
          close={() => setShowDetail(null)}
          currentId={_.get(showDetail, 'id')}
          refreshList={() => {
            refresh()
            search(params)
          }}
        />
      }
    </div>
  )
}

function filterType(data, condition, currentId) {
  const currentIds = _.split(currentId, ',')
  let filterList = _.cloneDeep(data)
  const tileList = tileData(filterList)
  filterList = tileList

  if (!_.includes(condition, 'FREQ')) filterList = _.filter(filterList, o => (!(o.type === 'REQ' && !_.includes(_.map(currentIds, x => _.includes(o?.releaseIds, x)), true))))
  if (!_.includes(condition, 'REQ')) filterList = _.filter(filterList, o => (o.type !== 'REQ'))
  if (!_.includes(condition, 'ISSUE')) filterList = _.filter(filterList, o => o.type !== 'ISSUE')
  if (!_.includes(condition, 'DEFECT')) filterList = _.filter(filterList, o => o.type !== 'DEFECT')
  if (!_.includes(condition, 'TASK')) filterList = _.filter(filterList, o => o.type !== 'TASK')
  if (!_.includes(condition, 'DEV')) filterList = _.filter(filterList, o => o.type !== 'DEV')
  if (_.size(condition) === 1 && _.includes(condition, 'TASK')) {
    const obj = {}
    _.forEach(filterList, o => {
      if (o.type === 'TASK') obj[o.storyId] = o
    })
    filterList = _.values(obj)
  }
  return treeData(filterList)
}

function parentReq(data, arr = []) {
  _.forEach(data, o => {
    if (_.isEmpty(o.subStoryList)) arr.push(o)
    else {
      if (_.includes(_.map(o.subStoryList, x => x.type), 'REQ')) parentReq(o.subStoryList, arr)
      else arr.push(o)
    }
  })
  return arr
}

function getReqList(data) {
  let filterList = tileData(data)
  filterList = _.filter(filterList, o => (o.type === 'REQ' || o.type === 'DEV'))
  return _.map(filterList, item => (_.assign({
    interiorReqNo: item.storyId,
  }, _.pick(item, ['title', 'id']))))
}

function getIssueList(data) {
  let filterList = tileData(data)
  filterList = _.filter(filterList, o => o.type === 'ISSUE')
  return _.map(filterList, item => ({
    id: item.id,
    issueId: item.storyId,
    shortDesc: item.title,
  }))
}

function treeData(data) {
  _.forEach(data, o => { delete o.children })
  let map = {}
  _.forEach(data, o => {
    if (o.type === 'TASK') {
      map[o.storyId + o.pid] = o
    } else map[o.id] = o
  })

  let val = [];
  _.forEach(data, o => {
    let parent = map[o.pid];
    if (parent) ((parent.children) || (parent.children = [])).push(o);
    else val.push(o);
  })

  return val;
}

function tileData(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o)
    if (!_.isEmpty(o.subStoryList)) tileData(o.subStoryList, arr)
  })
  return arr
}

function getKey(data, arr = [], id = 'storyId') {
  _.forEach(data, o => {
    // if (o.type === 'TASK') return
    arr.push(o[id])
    if (!_.isEmpty(o.children)) getKey(o.children, arr, id)
  })
  return arr
}

function MoreOperations({ setExportType, listExport, exportDemandMatrixList, exportMeetingList, isUnplanned, isShow }) {
  const [clicked, setClicked] = useState(false)

  const onClick = useCallback((mode) => {
    setExportType(mode)
    setClicked(false)
  }, [setExportType])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={listExport}>导出列表</div>
      {!isUnplanned && <div onClick={() => onClick('script')}>导出脚本</div>}
      {!isUnplanned && <div onClick={() => onClick('modifyFile')}>导出修改文件</div>}
      {(isShow && !isUnplanned) && <div onClick={exportDemandMatrixList}>导出需求矩阵列表</div>}
      {(isShow && !isUnplanned) && <div onClick={exportMeetingList}>导出RAT会议清单</div>}
      {!isUnplanned && <div onClick={() => onClick('checklist')}>导出checklist</div>}
      {!isUnplanned && <div onClick={() => onClick('releaseNote')}>导出releaseNote</div>}
    </div>
  }, [listExport, exportDemandMatrixList, exportMeetingList, onClick, isUnplanned, isShow])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='导出' onClick={() => setClicked(x => !x)} />
    </Popover>
  )
}

function ReleasePlanning({ setActionType, isDisabled }) {
  const [clicked, setClicked] = useState(false)
  const className = useMemo(() => isDisabled ? 'item-operation-disabled' : '', [isDisabled])
  const onClick = useCallback((mode) => {
    setActionType(mode)
    setClicked(false)
  }, [setActionType])
  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('REQ')}>导入需求/自提单</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('ISSUE')}>导入ISSUE</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('version')}>从版本导入</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('planVersion')}>从预规划项目导入</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('delValues')}>从该版本中移除</div>
      <div onClick={() => onClick('copyValues')}>复制至其他版本</div>
      <div onClick={() => onClick('regressionValues')}>编辑回归标识</div>
    </div>
  }, [className, isDisabled, onClick])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='版本规划' onClick={() => setClicked(x => !x)} />
    </Popover>
  )
}

import React, { useState, useMemo, useEffect, useLayoutEffect, useCallback } from 'react'
import _ from 'lodash'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import TotalCost from './totalCost'
import DetailCost from './detailCost'
import useExactHeight from '../../../../common/hooks/useExactHeight'
import { errorTips } from '../../../../../utils/messagerTips'
import './index.scss'
import { isNil } from '../../../../appraise/components/method'
import { dateFormat } from 'rootnet-core/dateFormat'

const LIST_URL = '/workingHoursManagement/selectCostForProject'
const MAN_HOUR_URL = '/userProject/getWorkloadDistribution'

const initParams = {
  pageNum: 1,
  pageSize: 20,
}

export default function DevProjectCostBudgeting(props) {
  const { projectId, isPo, isPMM, isShow, isPm, projectInfo } = props
  const [totalCost, setTotalCost] = useState()
  const [summaryInfoExpanded, setSummaryInfoExpanded] = useState(true)
  const [exactContainerRef, containerRef, updateHeight] = useExactHeight(40)
  const { doFetch, data: resData, loading, error } = usePost()
  const { doFetch: hourFetch, data: hourData } = useGet()

  const hourRefresh = useCallback(() => {
    if (_.isNil(projectId)) return
    hourFetch(`${MAN_HOUR_URL}?id=${projectId}`)
  }, [hourFetch, projectId])

  const { rows: data = [], total, pageNum } = useMemo(() => {
    const { pageResult, ...res } = resData || {}
    setTotalCost(res)
    return _.assign(pageResult, res)
  }, [resData])

  const refresh = useCallback((params) => {
    let obj = {}
    if (!isNil(_.get(params, 'workCycle'))) {
      const { begin, end } = _.get(params, 'workCycle')
      const beginDate = dateFormat('YYYY-MM-DD', new Date(begin))
      const endDate = dateFormat('YYYY-MM-DD', new Date(end))
      obj = {
        beginDate: {
          begin: beginDate + ' 00:00:00',
          end: endDate + ' 23:59:59',
        },
        endDate: {
          begin: beginDate + ' 00:00:00',
          end: endDate + ' 23:59:59',
        }
      }
    }
    const postParams = _.assign({}, _.omit(params, ['workCycle']), { projectId }, obj)
    doFetch(LIST_URL, postParams)
      .catch(err => errorTips(err._message))
  }, [doFetch, projectId])

  const onScroll = useCallback((e) => {
    if (e.deltaY > 0) {
      setSummaryInfoExpanded(false)
    }
  }, [])

  useEffect(() => {
    const rightPanel = document.querySelector('.project-cost-detail')
    if (rightPanel) {
      rightPanel.addEventListener('mousewheel', onScroll)
    }
    return () => {
      if (rightPanel) {
        rightPanel.removeEventListener('mousewheel', onScroll)
      }
    }
  }, [onScroll])

  useEffect(() => refresh(initParams), [refresh])

  useEffect(() => hourRefresh(), [hourRefresh])

  useLayoutEffect(() => {
    return updateHeight(summaryInfoExpanded);
  }, [summaryInfoExpanded, updateHeight]);

  return (
    <div className='dev-project-cost-budgeting fill'>
      {/* 成本合计 */}
      <div className="containerParent" ref={exactContainerRef} onTransitionEnd={transitionEnd}>
        <div className='container' ref={containerRef}>
          <TotalCost {...{ totalCost, summaryInfoExpanded, setSummaryInfoExpanded, isPo, isPMM, isPm, isShow, projectInfo, hourData }} />
        </div>
      </div>
      {/* 成本明细 */}
      <DetailCost {...{ projectId, setTotalCost, data, loading, error, total, pageNum, initParams, refresh }} />
    </div>
  )
  function transitionEnd() {
    if (summaryInfoExpanded) exactContainerRef.current.style.height = 'auto';//设置为`auto`的原因是需要保证子级菜单展开时，父级菜单的高度响应变化
  }
}

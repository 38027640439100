import _ from 'lodash'
import cls from 'clsx'
import { N2 } from 'rootnet-core/format'
import { Messager } from 'rootnet-ui'
import { Input, Select, CheckBox, Display, DatePicker, TextArea } from 'rootnet-edit'
import { dateFormat } from 'rootnet-core/dateFormat'
import UserSelect from "../../../common/personSelectPop/UserSelect";
// import { Icon } from '../../../../project_share/components'

const publicUrl = '/common/globalconst?globalConst='
const WORKTIME_URL = '/worktime/costcenter?activeFlag=Y'
const URL = [
  publicUrl + 'PSAProjectType',
  '/common/user/positions?posIds=PM,SurpportEM,SurpportPM,PMO',
  '/develop/product/list',
  publicUrl + 'PSAProjectStatus',
  '/customer/customerList',
  '/common/globalpara?paraId=StdPrice',
  '/common/globalpara?paraId=StdPrice_service',
  '/common/userinfo',
  WORKTIME_URL,
]

const firstColumnWidth = 130
const detailsFirstColumnWidth = 100

function getTime(time) {
  return dateFormat('YYYY-MM-DD', time)
}


const actionForm = {
  //新增一级项目
  add: {
    header: '新增一级项目',
    url: URL,
    form: (props) => {
      const { isPriceFlag, isAllow, setShowContract, params, setParams, setTurnOn, handleInConvert, suffix } = props
      return [
        {
          required: true,
          toolbar: false,
          label: '项目类型',
          multiple: true,
          labelWidth: firstColumnWidth,
          component: Select,
          bind: 'projectType',
        },
        {
          required: true,
          search: true,
          label: '项目经理',
          labelWidth: 140,
          component: UserSelect,
          bind: 'projectManager',
        },
        {
          disabled: true,
          label: '项目状态',
          labelWidth: 100,
          component: Select,
          bind: 'projectStatus',
        },
        {
          required: true,
          label: '项目名称',
          colSpan: true,
          labelWidth: firstColumnWidth,
          bind: 'projectName',
        },
        {
          search: true,
          multiple: true,
          label: '产品',
          labelWidth: 100,
          component: Select,
          bind: 'productId',
          clear: true,
        },
        {
          label: '客户',
          labelWidth: firstColumnWidth,
          component: Select,
          bind: 'customer',
          search: true,
          clear: true,
        },
        {
          label: '核价项目',
          bind: 'priceFlag',
          labelWidth: 140,
          disabled: !isPriceFlag,
          component: CheckBox,
        },
        {
          required: params?.priceFlag,
          label: '核价单号',
          labelWidth: 100,
          bind: 'priceId',
          component: params?.priceFlag ? Input : Display,
        },
        {
          required: true,
          label: '计划开始日期',
          labelWidth: firstColumnWidth,
          component: DatePicker,
          bind: 'startDate',
        },
        {
          required: true,
          label: '计划结束日期',
          labelWidth: 140,
          component: DatePicker,
          bind: 'endDate',
        },
        {
          label: '合同名称',
          disabled: isAllow,
          labelWidth: firstColumnWidth,
          colSpan: true,
          placeholder: '请选择',
          bind: 'contractName',
          onFocus: () => setShowContract(true),
          suffix: suffix(() => setParams({ ...params, contractName: '', contractNum: '' }))
        },
        {
          label: '合同编号',
          disabled: isAllow,
          labelWidth: 100,
          bind: 'contractNum',
          placeholder: '请选择',
          onFocus: () => setShowContract(true),
        },
        {},
        {
          required: !isAllow,
          disabled: isAllow,
          onFocus: () => { if (Number(params?.budgetCost) === 0) { setParams({ ...params, budgetCost: '' }) } },
          onBlur: () => { if (params?.budgetCost === '') { setParams({ ...params, budgetCost: 0 }) } },
          label: '预算成本(元)',
          type: 'number',
          digit: 1,
          labelWidth: firstColumnWidth,
          bind: 'budgetCost',
          format: v => N2(v),
        },
        {
          required: !isAllow,
          disabled: isAllow,
          onFocus: () => { if (Number(params?.budgetRev) === 0) { setParams({ ...params, budgetRev: '' }) } },
          onBlur: () => { if (params?.budgetRev === '') { setParams({ ...params, budgetRev: 0 }) } },
          label: '预算收入(元)',
          type: 'number',
          digit: 1,
          labelWidth: 140,
          bind: 'budgetRev',
          format: v => N2(v),
        },
        {
          disabled: true,
          label: '预算利润率',
          labelWidth: 100,
          suffix: '%',
          bind: 'expectedRateOfReturn',
        },
        {
          required: !isAllow,
          disabled: isAllow,
          onFocus: () => { if (Number(params?.budgetCostTime) === 0) { setParams({ ...params, budgetCostTime: '' }) } },
          onBlur: () => { if (params?.budgetCostTime === '') { setParams({ ...params, budgetCostTime: 0 }) } },
          label: '预算成本(人日)',
          digit: 1,
          labelWidth: firstColumnWidth,
          bind: 'budgetCostTime',
          type: 'number',
          lineFeed: true,
          format: v => _.isNil(v) || v === '' ? '-' : _.toNumber(v).toFixed(1),
        },
        {
          required: Number(params?.budgetCost) > 0,
          disabled: !Number(params?.budgetCost) > 0,
          onFocus: () => setTurnOn(1),
          bindInConvert: handleInConvert,
          text: handleInConvert,//('', 'costCenterName'),
          label: '成本中心',
          labelWidth: firstColumnWidth,
          bind: 'costCenterName',
          placeholder: '请选择',
        },
        {
          required: Number(params?.budgetRev) > 0,
          disabled: !Number(params?.budgetRev) > 0,
          bindInConvert: handleInConvert,
          text: handleInConvert,//('', 'revenueCenterName'),
          onFocus: () => setTurnOn(2),
          labelWidth: 140,
          bind: 'revenueCenterName',
          label: '收入中心',
          placeholder: '请选择',
        },
        {},
        {
          required: true,
          label: '项目简介',
          placeholder: '请输入至少5位',
          colSpan: 3,
          maxLength: 500,
          className: cls('reason', { error: _.size(_.trim(params?.memo)) >= 5 ? false : true }),
          labelWidth: firstColumnWidth,
          componentWidth: 870,
          component: TextArea,
          bind: 'memo',
        }
      ]
    },
    change: (options, key, { params, setParams, changeOptions, customer, stdPriceDev, stdPriceService }) => {
      if (key === 'priceFlag') {
        if (_.includes(options.projectType, 'CUS') && !options[key]) {
          options.contractName = ''
          options.contractNum = ''
          options.budgetCost = 0
          options.budgetRev = 0
          options.expectedRateOfReturn = 0
          options.budgetCostTime = 0
          options.costCenterName = ''
          options.revenueCenterName = ''
          options.revenueCenter = null
          options.revenueCenterId = null
          options.costCenter = null
          options.costCenterId = null
        }
        if (!options[key]) {
          options.priceId = null
        }
      }

      if (key === 'projectType') {
        if (_.includes(options[key], 'CUS') && !options.priceFlag) {
          options.contractName = ''
          options.contractNum = ''
          options.budgetCost = 0
          options.budgetRev = 0
          options.expectedRateOfReturn = 0
          options.budgetCostTime = 0
          options.costCenterName = ''
          options.revenueCenterName = ''
        }
        if (_.size(options[key]) === 1 && options[key][0] === 'DEV') {
          options.businessName = ''
          options.customer = 'NBGJ'
          options.businessId = ''
          // setBusinessName(false)
        }
        //  else if (_.size(options[key]) >= 1) {
        // setBusinessName(true)
        // } else {
        // setBusinessName(false)
        // }
      }
      if ((key === 'startDate' || key === 'endDate') && dateFormat('YYYYMMDDHHMMSS', options['startDate']) === dateFormat('YYYYMMDDHHMMSS', options['endDate'])) {
        Messager.show('开始日期与结束日期不能相同！', { icon: 'error' })
        options[key] = null
      } else if (key === 'startDate' && options['endDate'] && dateFormat('YYYYMMDDHHMMSS', options[key]) > dateFormat('YYYYMMDDHHMMSS', options['endDate'])) {
        options[key] = null
        Messager.show('开始日期不能大于结束日期！', { icon: 'error' })
      } else if (key === 'endDate' && options['startDate'] && dateFormat('YYYYMMDDHHMMSS', options[key]) < dateFormat('YYYYMMDDHHMMSS', options['startDate'])) {
        options[key] = null
        Messager.show('结束日期不能小于开始日期！', { icon: 'error' })
      }


      if (key === 'budgetCost' || key === 'budgetCostTime') {
        const projectType = _.get(params, 'projectType')
        const currentNorm = _.includes(projectType, "DEV") || projectType === 'DEV' ? stdPriceDev.paraValue : stdPriceService.paraValue
        if (Number(options[key]) === 0) {
          if (key === 'budgetCost') {
            _.assign(options, { budgetCostTime: 0 })
          } else {
            _.assign(options, { budgetCost: 0 })
          }
        } else {
          key === 'budgetCostTime' && (options['budgetCost'] = _.round(options[key] * (currentNorm * 8), 1))
          key === 'budgetCost' && (options['budgetCostTime'] = _.round(options[key] / (currentNorm * 8), 1))
        }
      }

      if (key === 'budgetCost' || key === 'budgetCostTime') {
        if (Number(options[key]) === 0) _.assign(options, { costCenter: '', costCenterId: '', costCenterName: null })
      }
      if (key === 'budgetRev') {
        if (Number(options[key]) === 0) _.assign(options, { revenueCenter: '', revenueCenterId: '', revenueCenterName: null })
      }


      if (key === 'budgetCost' || key === 'budgetRev' || key === 'budgetCostTime') {
        if (options[key] === '' || options[key] <= 0) {
          _.assign(options, { expectedRateOfReturn: 0 })
        } else {
          if (key === 'budgetCost' || key === 'budgetCostTime') _.assign(options, { expectedRateOfReturn: (_.round((params.budgetRev - options['budgetCost']) / params.budgetRev * 100, 1)).toFixed(1) })
          if (key === 'budgetRev') _.assign(options, { expectedRateOfReturn: (_.round((options[key] - params.budgetCost) / options[key] * 100, 1)).toFixed(1) })
        }
      }

      if (!_.isNil(_.get(options, 'projectType'))) {
        if (_.isEmpty(options['projectType'])) return
        if (_.head(options['projectType']) === 'DEV') {
          if (!_.isNil(_.get(options, 'projectManager'))) {
            changeOptions(options, options['projectManager'])
          }
        } else if (!_.isNil(_.get(options, 'customer'))) {
          const salesman = _.get(_.find(customer, x => x.value === options['customer']), 'salesman')
          changeOptions(options, salesman)
        }
      }
      setParams(_.assign({ ...options }, { expectedRateOfReturn: _.isFinite(Number(options.expectedRateOfReturn)) ? options.expectedRateOfReturn : 0 }))
    },
    confirm: ({ params, doFetch, cancels, setMessage, search }) => {
      const startDate = getTime(params.startDate)
      const endDate = getTime(params.endDate)
      const addData = _.assign({}, params, {
        projectType: params.projectType.join(','),
        productId: params.productId ? params.productId.join(',') : '',
        leafProjectFlag: 0,
        priceFlag: params.priceFlag ? 'Y' : 'N',
        startDate,
        endDate,
      })
      doFetch('/project/add', 'post', addData).then(() => {
        search()
        cancels()
        Messager.show('提交成功！', { icon: 'success' })
      }).catch((err) => {
        setMessage(err._message)
      })
    },
    error: ({ params, setError }) => {
      const obj = {}
      if (params?.priceFlag) obj.priceId = '必须填！'
      else obj.priceId = null
      if (_.size(params?.priceId) > 0) obj.priceId = null
      if (params?.priceFlag) obj.priceFlag = null
      if (_.size(params?.projectType) === 0) obj.projectType = '必须填！'
      else { obj.projectType = null }
      obj.costCenterName = (_.size(_.get(params, 'costCenterName')) === 0 && Number(_.get(params, 'budgetCost')) > 0) ? '必须填！' : null
      obj.revenueCenterName = (_.size(_.get(params, 'revenueCenterName')) === 0 && Number(_.get(params, 'budgetRev')) > 0) ? '必须填！' : null
      setError(x => _.assign({}, x, obj))
    }
  },
  //新增子级项目
  addSubproject: {
    header: '新增子级项目',
    url: URL,
    form: (props) => {
      const { params, setParams, setShowContract, setTurnOn, handleInConvert, Checked, forceUpdate } = props
      const leaf = (_.find(['ONSITE', 'PRO', 'SER'], v => v === _.get(params, 'projectType'))) ? { label: '', component: Display, value: '', } : {
        disabled: params?.projectType !== 'IMP' && params?.priceFlag,
        bind: 'leafProjectFlag',
        component: Checked,
        label: '叶子项目',
        labelWidth: 100,
      }

      return [
        { required: true, label: '项目类型', labelWidth: firstColumnWidth, component: Select, bind: 'projectType', },
        { required: true, search: true, label: '项目经理', labelWidth: 140, component: UserSelect, bind: 'projectManager', },
        { required: true, label: '项目状态', labelWidth: 100, component: Select, bind: 'projectStatus' },
        { required: true, label: '子项目名称', labelWidth: firstColumnWidth, colSpan: 2, bind: 'projectName', },
        { required: true, search: true, label: '产品', multiple: true, labelWidth: 100, component: Select, bind: 'productId' },
        { label: '客户', labelWidth: firstColumnWidth, component: Select, bind: 'customer', disabled: true, },
        { bind: 'priceFlag', labelWidth: 140, component: CheckBox, label: '核价项目', disabled: true, },
        { label: '核价单号', labelWidth: 100, bind: 'priceId', component: Display, },
        { required: true, label: '计划开始日期', labelWidth: firstColumnWidth, component: DatePicker, bind: 'startDate', clear: false, },
        { required: true, label: '计划结束日期', labelWidth: 140, component: DatePicker, bind: 'endDate', clear: false, },
        leaf,
        { label: '合同名称', labelWidth: firstColumnWidth, colSpan: true, placeholder: '请选择', bind: 'contractName', component: Display, onFocus: () => setShowContract(true) },
        { label: '合同编号', labelWidth: 100, bind: 'contractNum', placeholder: '请选择', component: Display, onFocus: () => setShowContract(true), },
        {},
        { label: '最大可分配成本(元)', labelWidth: firstColumnWidth, component: Display, bind: 'assignableCost', },
        { label: '最大可分配收入(元)', labelWidth: 140, component: Display, bind: 'enableCostAssignment' },
        { label: '', component: Display, value: '', },
        {
          digit: 1,
          type: 'number',
          required: true,
          labelWidth: firstColumnWidth,
          bind: 'budgetCost',
          label: '预算成本(元)',
          format: v => N2(v),
          onBlur: () => { if (params?.budgetCost === '') { setParams({ ...params, budgetCost: 0 }) } },
          onFocus: () => { if (Number(params?.budgetCost) === 0) { setParams({ ...params, budgetCost: '' }) } },
        },
        {
          digit: 1,
          required: true,
          type: 'number',
          labelWidth: 140,
          bind: 'budgetRev',
          format: v => N2(v),
          label: '预算收入(元)',
          onBlur: () => { if (params?.budgetRev === '') { setParams({ ...params, budgetRev: 0 }) } },
          onFocus: () => { if (Number(params?.budgetRev) === 0) { setParams({ ...params, budgetRev: '' }) } },
        },
        { disabled: true, label: '预算利润率', labelWidth: 100, suffix: '%', bind: 'expectedRateOfReturn', },
        {
          digit: 1,
          type: 'number',
          lineFeed: true,
          required: true,
          labelWidth: firstColumnWidth,
          bind: 'budgetCostTime',
          label: '预算成本(人日)',
          format: v => _.isNil(v) || v === '' ? '-' : _.toNumber(v).toFixed(1),
          onBlur: () => { if (params?.budgetCostTime === '') { setParams({ ...params, budgetCostTime: 0 }) } },
          onFocus: () => { if (Number(params?.budgetCostTime) === 0) { setParams({ ...params, budgetCostTime: '' }) } },
        },
        {
          labelWidth: firstColumnWidth,
          label: '成本中心',
          placeholder: '请选择',
          bind: 'costCenterName',
          onFocus: () => setTurnOn(1),
          bindInConvert: handleInConvert,
          required: Number(params?.budgetCost) > 0,
          disabled: !Number(params?.budgetCost) > 0,
          text: handleInConvert,
        },
        {
          labelWidth: 140,
          label: '收入中心',
          placeholder: '请选择',
          bind: 'revenueCenterName',
          onFocus: () => setTurnOn(2),
          bindInConvert: handleInConvert,
          required: Number(params?.budgetRev) > 0,
          disabled: !Number(params?.budgetRev) > 0,
          text: handleInConvert,
        },
        {},
        {
          required: true,
          bind: 'memo',
          colSpan: 3,
          label: '项目简介',
          labelWidth: firstColumnWidth,
          maxLength: 500,
          placeholder: '请输入至少5位',
          onChange: forceUpdate,
          className: cls('reason', { error: _.size(_.trim(params?.memo)) >= 5 ? false : true }),
          component: TextArea,
          componentWidth: 870,
        },
      ]
    },
    change: (options, key, props) => {
      const { params, setParams, changeOptions, getFirstLevel, userRes, stdPriceDev, stdPriceService, echoData, projectType, costCenterRes } = props
      if (key === 'projectType') {
        if (options[key] !== 'IMP' && options?.priceFlag) {
          options.leafProjectFlag = false
        }

        if (options[key] === 'DEV') {
          options.customer = 'NBGJ'
        } else {
          options.customer = echoData.customer
        }
      }

      if ((key === 'startDate' || key === 'endDate') && dateFormat('YYYYMMDDHHMMSS', options['startDate']) === dateFormat('YYYYMMDDHHMMSS', options['endDate'])) {
        Messager.show('开始日期与结束日期不能相同！', { icon: 'error' })
        options[key] = null
      } else if (key === 'startDate' && options['endDate'] && dateFormat('YYYYMMDDHHMMSS', options[key]) > dateFormat('YYYYMMDDHHMMSS', options['endDate'])) {
        options[key] = null
        Messager.show('开始日期不能大于结束日期！', { icon: 'error' })
      } else if (key === 'endDate' && options['startDate'] && dateFormat('YYYYMMDDHHMMSS', options[key]) < dateFormat('YYYYMMDDHHMMSS', options['startDate'])) {
        options[key] = null
        Messager.show('结束日期不能小于开始日期！', { icon: 'error' })
      }

      if (key === 'budgetCost' || key === 'budgetCostTime') {
        const projectType = _.get(params, 'projectType')
        const currentNorm = _.includes(projectType, "DEV") || projectType === 'DEV' ? stdPriceDev.paraValue : stdPriceService.paraValue
        if (Number(options[key]) === 0) {
          if (key === 'budgetCost') {
            _.assign(options, { budgetCostTime: 0 })
          } else {
            _.assign(options, { budgetCost: 0 })
          }
        } else {
          key === 'budgetCostTime' && (options['budgetCost'] = _.round(options[key] * (currentNorm * 8), 1))
          key === 'budgetCost' && (options['budgetCostTime'] = _.round(options[key] / (currentNorm * 8), 1))
        }
      }

      if (key === 'budgetCost' || key === 'budgetCostTime') {
        if (Number(options[key]) === 0) _.assign(options, { costCenter: '', costCenterId: '', costCenterName: null })
      }
      if (key === 'budgetRev') {
        if (Number(options[key]) === 0) _.assign(options, { revenueCenter: '', revenueCenterId: '', revenueCenterName: null })
      }

      if (key === 'leafProjectFlag') _.assign(options, { leafProjectFlag: options[key] ? 1 : 0 })
      if (key === 'budgetCost' || key === 'budgetRev') {
        if (options[key] === '' || options[key] <= 0) {
          _.assign(options, { expectedRateOfReturn: '' })
        } else {
          if (key === 'budgetCost' || key === 'budgetCostTime') _.assign(options, { expectedRateOfReturn: (_.round((params.budgetRev - options['budgetCost']) / params.budgetRev * 100, 1)).toFixed(1) })
          if (key === 'budgetRev') _.assign(options, { expectedRateOfReturn: (_.round((options[key] - params.budgetCost) / options[key] * 100, 1)).toFixed(1) })
        }
      }
      if (_.includes(_.map(projectType, x => x.value), 'DEV')) {
        if (!_.isNil(_.get(options, 'projectManager'))) {
          changeOptions(options, options['projectManager'])
        }
      } else {
        if (_.toNumber(_.get(options, 'budgetCost')) !== 0 && (_.isNil(_.get(options, 'costCenter')) || _.get(options, 'costCenter') === '')) {
          _.assign(options,
            {
              costCenter: _.get(echoData, 'costCenter'),
              costCenterId: _.get(echoData, 'costCenter'),
              costCenterName: _.get(echoData, 'costCenterName'),
            })
        }
        if (_.toNumber(_.get(options, 'budgetRev')) !== 0 && (_.isNil(_.get(options, 'revenueCenter')) || _.get(options, 'revenueCenter') === '')) {
          if (!_.isNil(_.get(options, 'projectManager'))) {
            const deptId = _.get(_.find(userRes, x => x.userAccount === options['projectManager']), 'department')
            const costCenter = _.find(costCenterRes, x => x.deptId === deptId && (getFirstLevel(costCenterRes, x, 'id', 'pid').costId === '01'))
            _.assign(options,
              {
                revenueCenter: _.get(costCenter, 'costId'),
                revenueCenterId: _.get(costCenter, 'costId'),
                revenueCenterName: _.get(costCenter, 'name'),
              })
          }
        }
      }
      setParams(x => _.assign(x, options, { expectedRateOfReturn: _.isFinite(Number(options.expectedRateOfReturn)) ? options.expectedRateOfReturn : 0 }))
    },
    confirm: ({ params, doFetch, cancels, setMessage, echoData, search }) => {
      const startDate = dateFormat('YYYY-MM-DD', params.startDate)
      const endDate = dateFormat('YYYY-MM-DD', params.endDate)
      const addData = _.assign({}, {
        ...params,
        assignableCost: echoData.assignableCost,
        enableCostAssignment: echoData.enableCostAssignment,
        priceFlag: params.priceFlag ? 'Y' : 'N',
        productId: _.get(params, 'productId') ? params.productId.join(',') : "",
        leafProjectFlag: params.leafProjectFlag ? 1 : 0,
        startDate,
        endDate
      })

      doFetch('/project/add', 'post', addData).then(res => {
        Messager.show('提交成功！', { icon: 'success' })
        search()
        cancels()
      }).catch((err) => {
        setMessage(err._message)
      })
    },
    error: ({ params, setError, echoData }) => {
      const obj = {}
      obj.costCenterName = (_.size(_.get(params, 'costCenterName')) === 0 && Number(_.get(params, 'budgetCost')) > 0) ? '必须填！' : null
      obj.revenueCenterName = (_.size(_.get(params, 'revenueCenterName')) === 0 && Number(_.get(params, 'budgetRev')) > 0) ? '必须填！' : null
      if (_.toNumber(_.get(params, 'budgetCost')) > Number(_.get(echoData, 'assignableCost'))) {
        obj.budgetCost = `数据不能大于最大可分配成本${_.get(echoData, 'assignableCost')}元`
      } else {
        obj.budgetCost = null
      }
      if (_.toNumber(_.get(params, 'budgetRev')) > Number(_.get(echoData, 'enableCostAssignment'))) {
        obj.budgetRev = `数据不能大于最大可分配收入${_.get(echoData, 'enableCostAssignment')}元`
      } else {
        obj.budgetRev = null
      }
      setError(x => _.assign({}, x, obj))
    },
    defaultV: (listOpt, echoData, setParams) => {
      const { projectType } = listOpt || {}
      setParams(x => _.assign(x, {
        projectType: _.get(projectType, '[0].value'),
        customer: _.get(projectType, '[0].value') === 'DEV' ? 'NBGJ' : echoData?.customer,
        expectedRateOfReturn: 0
      }))
    }
  },
  //编辑
  edit: {
    header: '编辑',
    url: URL,
    form: (props) => {
      const { level, setShowContract, params, echoData, handleInConvert, setParams, setTurnOn, childrenProjectList } = props
      const isFirstLevelProject = _.isNil(_.get(echoData, 'pid'))
      const stateDisable = _.get(echoData, "projectStatus") === '04'
      const isTrue = (_.isNil(echoData?.budgetCost) && _.isNil(echoData?.budgetRev)) ? 1 : 0
      const isLevel = level !== 0
      const isLeafProjectFlag = _.isEmpty(childrenProjectList)
      const leaf = (Number(level) === 0 || (_.find(['ONSITE', 'PRO', 'SER'], v => v === _.get(params, 'projectType')))) ? {
        label: '', component: Display, value: '',
      } : {
        label: '叶子项目',
        component: CheckBox,
        bind: 'leafProjectFlag',
        disabled: Number(isTrue) || Number(level) === 4 || _.get(echoData, 'leafProjectFlag') === '1' || !isLeafProjectFlag,
      }
      return _.compact([
        {
          required: Number(level) === 0,
          disabled: Number(level) !== 0,
          multiple: Number(level) === 0,
          toolbar: false,
          label: '项目类型',
          labelWidth: firstColumnWidth,
          component: Select,
          bind: 'projectType',
          search: true,
        },
        {
          required: true,
          label: '项目经理',
          labelWidth: 140,
          component: UserSelect,
          search: true,
          bind: 'projectManager',
        },
        {
          required: true,
          label: '项目状态',
          labelWidth: 100,
          component: Select,
          bind: 'projectStatus',
          disabled: stateDisable,
        },
        {
          required: true,
          label: '项目名称',
          labelWidth: firstColumnWidth,
          bind: 'projectName',
        },
        {
          label: '项目ID',
          labelWidth: 140,
          disabled: true,
          bind: 'id',
        },
        {
          required: Number(level) !== 0,
          label: '产品',
          search: true,
          multiple: true,
          labelWidth: 100,
          component: Select,
          bind: 'productId'
        },
        {
          label: '客户',
          labelWidth: firstColumnWidth,
          component: Select,
          bind: 'customer',
          disabled: !isFirstLevelProject,
          search: true,
        },
        {
          bind: 'priceFlag',
          labelWidth: 140,
          component: CheckBox,
          label: '核价项目',
          disabled: true,
        },
        {
          label: '核价单号',
          labelWidth: 100,
          bind: 'priceId',
          component: Display,
        },
        {
          required: true,
          label: '计划开始日期',
          labelWidth: firstColumnWidth,
          component: DatePicker,
          bind: 'startDate',
          clear: false,
        },
        {
          required: true,
          label: '计划结束日期',
          labelWidth: 140,
          component: DatePicker,
          bind: 'endDate',
          clear: false,
        },
        {
          label: '',
          value: '',
          component: Display,
        },
        {
          label: '实际开始日期',
          labelWidth: firstColumnWidth,
          bindInConvert: handleInConvert,
          component: Display,
          bind: 'actualStartDate',
        },
        {
          label: '实际结束日期',
          labelWidth: 140,
          bindInConvert: handleInConvert,
          component: Display,
          bind: 'actualEndDate',
        },
        leaf,
        {
          label: '合同名称',
          labelWidth: firstColumnWidth,
          colSpan: true,
          placeholder: '请选择',
          bind: 'contractName',
          onFocus: () => setShowContract(true),
          component: isFirstLevelProject ? Input : Display,
        },
        {
          label: '合同编号',
          labelWidth: 100,
          bind: 'contractNum',
          placeholder: '请选择',
          onFocus: () => setShowContract(true),
          component: isFirstLevelProject ? Input : Display,
        },
        {},
        isLevel && { disabled: true, label: '最大可分配成本(元)', labelWidth: firstColumnWidth, bind: 'assignableCost', component: Display, format: v => N2(v) },
        isLevel && { disabled: true, label: '最大可分配收入(元)', component: Display, format: v => N2(v), labelWidth: 140, bind: 'enableCostAssignment', },
        isLevel && { label: '', component: Display, value: '', },
        {
          digit: 1,
          required: true,
          type: 'number',
          labelWidth: firstColumnWidth,
          format: v => N2(v),
          bind: 'budgetCost',
          label: '预算成本(元)',
          onBlur: () => { if (params?.budgetCost === '') { setParams({ ...params, budgetCost: 0 }) } },
          onFocus: () => { if (Number(params?.budgetCost) === 0) { setParams({ ...params, budgetCost: '' }) } },
        },
        {
          digit: 1,
          type: 'number',
          required: true,
          labelWidth: 140,
          bind: 'budgetRev',
          format: v => N2(v),
          label: '预算收入(元)',
          onBlur: () => { if (params?.budgetRev === '') { setParams({ ...params, budgetRev: 0 }) } },
          onFocus: () => { if (Number(params?.budgetRev) === 0) { setParams({ ...params, budgetRev: '' }) } },
        },
        {
          suffix: '%',
          disabled: true,
          labelWidth: 100,
          label: '预算利润率',
          bind: 'expectedRateOfReturn',
        },
        {
          digit: 1,
          type: 'number',
          labelWidth: firstColumnWidth,
          required: true,
          label: '预算成本(人日)',
          bind: 'budgetCostTime',
          lineFeed: true,
          format: v => _.isNil(v) || v === '' ? '-' : _.toNumber(v).toFixed(1),
          onBlur: () => { if (params?.budgetCostTime === '') { setParams({ ...params, budgetCostTime: 0 }) } },
          onFocus: () => { if (Number(params?.budgetCostTime) === 0) { setParams({ ...params, budgetCostTime: '' }) } },
        },
        {
          required: Number(params?.budgetCost) > 0,
          label: '成本中心',
          disabled: !Number(params?.budgetCost) > 0,
          onFocus: () => setTurnOn(1),
          labelWidth: firstColumnWidth,
          bind: 'costCenterName',
          placeholder: '请选择',
          bindInConvert: handleInConvert,
          text: handleInConvert,
        },
        {
          required: Number(params?.budgetRev) > 0,
          label: '收入中心',
          disabled: !Number(params?.budgetRev) > 0,
          onFocus: () => setTurnOn(2),
          labelWidth: 140,
          bind: 'revenueCenterName',
          placeholder: '请选择',
          bindInConvert: handleInConvert,
          text: handleInConvert,
        },
        {},
        {
          bind: 'memo',
          labelWidth: firstColumnWidth,
          required: true,
          label: '项目简介',
          placeholder: '请输入至少5位',
          maxLength: 500,
          componentWidth: 870,
          className: cls('reason', { error: _.size(_.trim(params?.memo)) >= 5 ? false : true }),
          component: TextArea,
        },
      ])
    },
    change: (options, key, props) => {
      const { params, setParams, changeOptions, stdPriceDev, stdPriceService, echoData, level, customer, parentDetail } = props
      if (key === 'projectType') {
        if (_.size(options[key]) === 1 && options[key][0] === 'DEV') {
          options.customer = ''
        } else {
          options.customer = echoData.customer
        }
      }
      if ((key === 'startDate' || key === 'endDate') && dateFormat('YYYYMMDDHHMMSS', options['startDate']) === dateFormat('YYYYMMDDHHMMSS', options['endDate'])) {
        Messager.show('开始日期与结束日期不能相同！', { icon: 'error' })
        options[key] = params[key]
      } else if (key === 'startDate' && options['endDate'] && dateFormat('YYYYMMDDHHMMSS', options[key]) > dateFormat('YYYYMMDDHHMMSS', options['endDate'])) {
        options[key] = params[key]
        Messager.show('开始日期不能大于结束日期！', { icon: 'error' })
      } else if (key === 'endDate' && options['startDate'] && dateFormat('YYYYMMDDHHMMSS', options[key]) < dateFormat('YYYYMMDDHHMMSS', options['startDate'])) {
        options[key] = params[key]
        Messager.show('结束日期不能小于开始日期！', { icon: 'error' })
      }

      if (key === 'budgetCost' || key === 'budgetCostTime') {
        const projectType = _.get(params, 'projectType')
        const currentNorm = _.includes(projectType, "DEV") || projectType === 'DEV' ? stdPriceDev.paraValue : stdPriceService.paraValue
        if (Number(options[key]) === 0) {
          if (key === 'budgetCost') {
            _.assign(options, { budgetCostTime: 0 })
          } else {
            _.assign(options, { budgetCost: 0 })
          }
        } else {
          key === 'budgetCostTime' && (options['budgetCost'] = _.round(options[key] * (currentNorm * 8), 1))
          key === 'budgetCost' && (options['budgetCostTime'] = _.ceil(_.ceil(options[key] / (currentNorm * 8), 2), 1))
        }
      }

      if (key === 'budgetCost' || key === 'budgetCostTime') {
        if (Number(options[key]) === 0) _.assign(options, { costCenter: '', costCenterId: '', costCenterName: null })
      }
      if (key === 'budgetRev') {
        if (Number(options[key]) === 0) _.assign(options, { revenueCenter: '', revenueCenterId: '', revenueCenterName: null })
      }

      if (key === 'budgetCost' || key === 'budgetRev' || key === 'budgetCostTime') {
        if (options[key] === '' || options[key] <= 0) {
          _.assign(options, { expectedRateOfReturn: 0 })
        } else {
          if (key === 'budgetCost' || key === 'budgetCostTime') _.assign(options, { expectedRateOfReturn: (_.round((params.budgetRev - options['budgetCost']) / params.budgetRev * 100, 1)).toFixed(1) })
          if (key === 'budgetRev') _.assign(options, { expectedRateOfReturn: (_.round((options[key] - params.budgetCost) / options[key] * 100, 1)).toFixed(1) })
        }
      }

      if (!_.isNil(_.get(options, 'projectType'))) {
        if (_.isEmpty(options['projectType'])) return
        if (_.head(options['projectType']) === 'DEV') {
          if (!_.isNil(_.get(options, 'projectManager'))) {
            changeOptions(options, options['projectManager'])
          }
        } else {
          if (level === '0') {
            const salesman = _.get(_.find(customer, x => x.value === options['customer']), 'salesman')
            changeOptions(options, salesman)
          } else {
            if (_.toNumber(_.get(options, 'budgetCost')) !== 0 && (_.isNil(_.get(options, 'costCenter')) || _.get(options, 'costCenter') === '')) {
              _.assign(options,
                {
                  costCenter: _.get(parentDetail, 'costCenter'),
                  costCenterId: _.get(parentDetail, 'costCenter'),
                  costCenterName: _.get(parentDetail, 'costCenterName'),
                })
            }
            if (_.toNumber(_.get(options, 'budgetRev')) !== 0 && (_.isNil(_.get(options, 'revenueCenter')) || _.get(options, 'revenueCenter') === '')) {
              _.assign(options,
                {
                  revenueCenter: _.get(parentDetail, 'revenueCenter'),
                  revenueCenterId: _.get(parentDetail, 'revenueCenter'),
                  revenueCenterName: _.get(parentDetail, 'revenueCenterName'),
                })
            }
          }
        }
      }
      setParams(_.assign(options, { expectedRateOfReturn: _.isFinite(Number(options.expectedRateOfReturn)) ? options.expectedRateOfReturn : 0 }))
    },
    confirm: ({ params, doFetch, cancels, setMessage, echoData, search }) => {
      let treeFlag;
      if (
        Number(_.get(echoData, 'leafProjectFlag')) === 1 &&
        Number(_.get(echoData, 'leafProjectFlag')) === (params.leafProjectFlag ? 1 : 0)
      ) treeFlag = 0
      else if (params.leafProjectFlag) treeFlag = 1
      else treeFlag = null
      const startDate = dateFormat('YYYY-MM-DD', params.startDate)
      const endDate = dateFormat('YYYY-MM-DD', params.endDate)
      const editData = _.assign({}, params, {
        startDate: `${startDate} 00:00:00`,
        endDate: `${endDate} 00:00:00`,
        treeFlag,
        assignableCost: echoData.assignableCost,
        enableCostAssignment: echoData.enableCostAssignment,
        productId: _.get(params, 'productId') ? params.productId.join(',') : "",
        projectType: _.isArray(params.projectType) ? params.projectType.join(',') : params.projectType,
        leafProjectFlag: params.leafProjectFlag === true ? 1 : 0
      })

      doFetch('/project/edit', 'post', editData).then(() => {
        Messager.show('提交成功！', { icon: 'success' })
        search()
        cancels()
      }).catch((err) => {
        setMessage(err._message)
      })
    },
    error: ({ params, setError, echoData, assignedCost, level }) => {
      const obj = {}
      obj.costCenterName = (_.size(_.get(params, 'costCenterName')) === 0 && Number(_.get(params, 'budgetCost')) > 0) ? '必须填！' : null
      obj.revenueCenterName = (_.size(_.get(params, 'revenueCenterName')) === 0 && Number(_.get(params, 'budgetRev')) > 0) ? '必须填！' : null
      if (!_.isNil(assignedCost) && _.toNumber(_.get(params, 'budgetCost')) < _.toNumber(assignedCost)) {
        obj.budgetCost = `数据不能小于已分配成本${assignedCost}元`
      } else {
        if (_.toNumber(_.get(params, 'budgetCost')) > Number(_.get(echoData, 'assignableCost'))) {
          obj.budgetCost = `数据不能大于最大可分配成本${_.get(echoData, 'assignableCost')}元`
        } else {
          obj.budgetCost = null
        }
        if (_.toNumber(_.get(params, 'budgetRev')) > Number(_.get(echoData, 'enableCostAssignment'))) {
          obj.budgetRev = `数据不能大于最大可分配收入${_.get(echoData, 'enableCostAssignment')}元`
        } else {
          obj.budgetRev = null
        }
      }

      if (Number(level) === 0) {
        obj.budgetCost = null
        obj.budgetRev = null
      }
      setError(x => _.assign({}, x, obj))
    },
    defaultV: (listOpt, echoData, setParams, data) => {
      const { stdPriceDev, stdPriceService } = listOpt
      const projectType = Number(data?.level) === 0 ? data.projectType.split(',') : data.projectType
      const currentNorm = _.includes(projectType, "DEV") || projectType === 'DEV' ? stdPriceDev?.paraValue : stdPriceService?.paraValue
      setParams(x => {
        return _.assign(x, (_.isNil(x?.budgetCostTime) && !_.isNil(currentNorm)) && {
          budgetCostTime: echoData?.budgetCost ? _.round(echoData?.budgetCost / (currentNorm * 8), 1) : 0,
        })
      })
    }
  },
  //详情
  details: {
    header: '详情',
    form: (props) => {
      const { isTrue, level, params, handleInConvert, defaultData, Checked } = props
      const subsidy = (Number(level) === 0 || Number(params?.leafProjectFlag) === 1)
      const budget = (Number(level) !== 0 && Number(params?.leafProjectFlag) !== 1)

      const displayData = isTrue ? [] : [
        {},
        { label: '预算成本(元)', labelWidth: detailsFirstColumnWidth, suffixStyle: '', bindInConvert: handleInConvert, bind: 'budgetCost', capitalize: _.get(defaultData, 'budgetCost'), },
        { label: '预算收入(元)', labelWidth: 120, suffixStyle: '', bindInConvert: handleInConvert, bind: 'budgetRev', capitalize: _.get(defaultData, 'budgetRev'), },
        { label: '', value: '', },
        subsidy && { label: '项目补贴(元)', labelWidth: detailsFirstColumnWidth, bind: 'projectSubsidies', bindInConvert: handleInConvert, capitalize: _.get(defaultData, 'projectSubsidies') },
        subsidy && { label: '立项补贴(元)', labelWidth: 120, bind: 'approveSubsidies', bindInConvert: handleInConvert, capitalize: _.get(defaultData, 'approveSubsidies'), },
        subsidy && { label: '', value: '', },
        !budget && { label: '预算总成本(元)', labelWidth: detailsFirstColumnWidth, bind: 'totalCost', bindInConvert: handleInConvert, capitalize: _.add(Number(_.get(defaultData, 'budgetCost')), Number(_.get(defaultData, 'projectSubsidies'))) },
        !budget && { label: '预算总收入(元)', labelWidth: 120, bind: 'totalRevenue', bindInConvert: handleInConvert, capitalize: _.add(Number(_.get(defaultData, 'budgetRev')), Number(_.get(defaultData, 'approveSubsidies'))), },
        !budget && { label: '', value: '', },

        { label: '成本中心', labelWidth: detailsFirstColumnWidth, bindInConvert: handleInConvert, bind: 'costCenterName', },
        { label: '收入中心', labelWidth: 120, bindInConvert: handleInConvert, bind: 'revenueCenterName', },
        { label: '预算利润率', labelWidth: 100, bindInConvert: handleInConvert, bind: 'expectedRateOfReturn', text: '预算利润率＝（预算收入-预算成本）/预算收入 * 100', },
      ]

      return [
        { label: '项目类型', labelWidth: detailsFirstColumnWidth, bind: 'projectTypeName', },
        { label: '项目经理', labelWidth: 120, bind: 'projectManagerName', },
        { label: '项目状态', labelWidth: 100, bind: 'projectStatus', bindInConvert: handleInConvert, },
        { label: '项目名称', labelWidth: detailsFirstColumnWidth, bind: 'projectName', },
        { label: '项目ID', labelWidth: 120, bind: 'id', },
        { label: '产品', labelWidth: 100, bind: 'productName', bindInConvert: handleInConvert, className: 'product-ellipsis', text: _.get(params, 'productName') },
        { label: '客户', labelWidth: detailsFirstColumnWidth, bind: 'customer', bindInConvert: handleInConvert, },
        { label: '核价项目', labelWidth: 120, bind: 'priceFlag', bindInConvert: handleInConvert, },
        { label: '核价单号', labelWidth: 100, bind: 'priceId', },
        { label: '计划开始日期', labelWidth: detailsFirstColumnWidth, bind: 'startDate', bindInConvert: handleInConvert, },
        { label: '计划结束日期', labelWidth: 120, bind: 'endDate', componentWidth: 536, bindInConvert: handleInConvert, },
        { label: '实际开始日期', labelWidth: detailsFirstColumnWidth, bind: 'actualStartDate', bindInConvert: handleInConvert, },
        { label: '实际结束日期', labelWidth: 120, bind: 'actualEndDate', componentWidth: 536, bindInConvert: handleInConvert, },
        { label: '合同名称', labelWidth: detailsFirstColumnWidth, bind: 'contractName', componentWidth: 536, },
        { label: '合同编号', labelWidth: 100, bind: 'contractNum', },
        { label: '商机名称', labelWidth: detailsFirstColumnWidth, bind: 'businessName', componentWidth: 536, },

        Number(params?.leafProjectFlag) === 1 ?
          {
            component: Checked
          } :
          {
            label: '',
            value: '',
          }
        ,
        ...displayData,
        {},
        { label: '项目简介', labelWidth: detailsFirstColumnWidth, bindInConvert: handleInConvert, componentWidth: 830, bind: 'memo' }
      ]
    }
  },
}

export default actionForm
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, Messager } from 'rootnet-ui'
import { Display, Form, FormInput, Grid, Select } from "rootnet-edit";
import { useGet, useApi } from "../../../utils/hook";
import _ from "lodash";
import './UpdateDialog.scss'

const urls = [
    '/common/userinfo?indexValidFlag=1',
    "/common/globalconst?globalConst=ActiveFlag"
]

const noBlankReg = new RegExp(/^(?!(\s+$))/)

const HFormInput = (props) => {
    return <FormInput horizontal componentWidth={180} labelWidth={120} required {...props} />;
}

function UpdateDialog(props) {
    const { close, refresh, mode, header, initData, path, deptOptionsRes, pDeptId, setShowFocus, uniqCostIdList, allActiveCostCenterRes } = props
    const [error, setError] = useState(null)
    const { data: optionsRes } = useGet(urls)
    const [formData, setFormData] = useState(initData)
    const { doFetch } = useApi()

    const existDeptList = useMemo(() => {
        const pid = _.get(initData, 'pid') || ''
        const nextLevelList = _.filter(allActiveCostCenterRes, x => x.pid === pid)
        return _.map(nextLevelList, x => x.deptId)
    }, [allActiveCostCenterRes, initData])

    const deptDisabledOptions = useMemo(() => {
        const firstLevelDepList = _.filter(deptOptionsRes, x => x.parentDept === (_.isNil(pDeptId) ? "" : pDeptId))
        const currentDeptId = _.get(initData, 'deptId')
        const omitList = _.filter(existDeptList, x => x !== currentDeptId)
        const canSelectList = _.map(firstLevelDepList, x => ({ value: x.departMentId, text: x.departMentName, _disabled: _.includes(omitList, x.departMentId), manager: x.manager }))
        return _.sortBy(canSelectList, '_disabled')
    }, [deptOptionsRes, pDeptId, existDeptList, initData])

    useEffect(() => {
        if (mode !== 'edit') {
            const pid = _.get(initData, 'pid') ? _.get(initData, 'pid') : ''
            doFetch(`/worktime/costcenter/getNextChildId?pid=${pid}`, 'get').then(res => {
                setFormData({ ...initData, costId: res })
            })
        }
    }, [doFetch, mode, initData])

    const [principalOptions, activeFlagOptions] = useMemo(() => {
        if (_.isNil(optionsRes)) return []
        const [d1, d2] = optionsRes
        return [
            _.map(d1, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}-${x.userName}` })),
            _.map(d2, x => ({ value: x.interiorId, text: x.displayName }))
        ]
    }, [optionsRes])

    const checkCostId = useCallback((v) => {
        if (!noBlankReg.test(v)) return '必填项！'
        const initCostId = _.get(initData, 'costId')
        const otherCostIdList = _.filter(uniqCostIdList, x => x !== initCostId)
        const formCostId = _.get(formData, 'costId')
        if (_.includes(otherCostIdList, formCostId)) return '该成本中心ID已存在！'
        return null
    }, [uniqCostIdList, initData, formData])

    const handleFormData = useCallback((options, key) => {
        let newFormData = { ...options }
        const deptId = _.get(newFormData, 'deptId')
        if (key === 'deptId' && !_.isNil(deptId)) {
            const deptInfo = _.find(deptDisabledOptions, x => x.value === deptId)
            const name = _.get(newFormData, 'name')
            const principal = _.get(newFormData, 'principal')
            if (_.isNil(name) || name === '') {
                const deptName = _.get(deptInfo, 'text')
                newFormData = { ...newFormData, name: deptName }
            }
            if (_.isNil(principal)) {
                const deptManager = _.get(deptInfo, 'manager')
                console.log('deptInfo', deptInfo)
                console.log('deptManager', deptManager)
                newFormData = { ...newFormData, principal: deptManager }
            }
        }
        setFormData(newFormData)
    }, [deptDisabledOptions])

    return <Dialog contentClassName='cost-center-dialog' cancel={close} header={header} confirm={submit} style={{ minHeight: 180 }}>
        {
            path !== '' && <div className="path flex">
                <div style={{ width: 100, marginLeft: 10 }}>上级成本中心：</div>
                {path}
            </div>
        }
        <Form error={error} onError={setError} value={formData} onChange={handleFormData}>
            <Grid cols={2}>
                {mode !== 'add' && !_.isNil(_.get(initData, 'pid')) && <HFormInput label='部门' bind='deptId' component={Select} search clear required={false} options={deptDisabledOptions} />}
                <HFormInput label='负责人' bind='principal' component={Select} search options={principalOptions} />
                {mode === 'add' && <FormInput label='' component={Display} value='' componentWidth={180} />}
                <HFormInput colSpan={2} label='成本中心名称' bind='name' type='custom' validate={v => noBlankReg.test(v) ? null : '必填项！'} />
                <HFormInput label='成本中心ID' bind='costId' disabled={mode === 'edit'} type='custom' validate={checkCostId} />
                {mode === 'edit' && <HFormInput label='状态' bind='activeFlag' component={Select} search options={activeFlagOptions} />}
            </Grid>
        </Form>
    </Dialog>

    function submit() {
        if (_.some(_.values(error), x => x)) {
            const errMsg = _.find(_.values(error), x => !_.isNil(x))
            Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
            return
        }
        const params = mode !== 'edit' ? _.assign({}, formData, { activeFlag: 'Y' }) : formData
        if (mode === 'addChild' || mode === 'edit') setShowFocus({ pid: params.pid, costId: params.costId })
        else setShowFocus({})
        const urls = {
            add: '/costcenter/add',
            addChild: '/costcenter/add',
            edit: '/costcenter/modify'
        }
        doFetch(urls[mode], 'post', params).then(() => {
            Messager.show(`${header}成功`, { icon: 'success' })
            close()
            refresh()
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }
}

export default UpdateDialog;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Button,DataGrid,Pagination} from 'rootnet-ui'
import {DatePicker, Form, FormInput} from "rootnet-edit";
import {Box} from "../../common/commonComponent";
import _ from "lodash";
import './UserLoginLog.scss'
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {useGet} from "../../../utils/hook";
import {strParams} from "../../../utils/publicFun";

function getColumns(props){
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
        { header: '用户', bind: 'userName', align: 'center',width: 150, tooltip: true, sortable: true },
        { header: '岗位', bind: 'positionName', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '登录时间', bind: 'loginDateTime', align: 'center', width: 150, tooltip: true, sortable: true,
            convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
        { header: '退出时间', bind: 'logoutDateTime', align: 'center', width: 150, tooltip: true, sortable: true,
            convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
        { header: '在线时长（h）', bind: 'keepTime', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: 'IP地址', bind: 'ip', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '登录地点', bind: 'loginAddress', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '浏览器', bind: 'explore', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '操作系统', bind: 'computer', align: 'center', width: 150, tooltip: true, sortable: true },
    ]
}

function getOptions(columns){
    return {
        fixedLeft: 1,
        autoFill: true,
        resizable: true,
        virtualized: false,
        nilText: '-',
        emptyText: '-',
        columns,
    }
}

const initPageParams = {
    pageNum: 1,
    pageSize: 20,
}

function UserLoginLog(props) {
    const {initParams, close} = props
    const [params, setParams] = useState({...initParams,...initPageParams})
    const {data: listRes, doFetch: getList} = useGet()

    const refresh = useCallback(()=>{
        getList('/loginCountLog/list?'+strParams(params))
    },[params,getList])

    useEffect(()=>{
        refresh()
    },[refresh])

    const { pageSize, total, pageNum, rows: list } = useMemo(() => listRes || {}, [listRes]);

    const options = useMemo(()=>{
        return getOptions(getColumns())
    },[])

    return <Dialog header={'详情'} className={'user-login-log-wrap flex-y'} cancel={close} contentClassName={'scroll-y'}>
        <div className={'flex-y content-wrap'}>
            <Option search={setParams} initParams={params}/>
            <Box title={'用户登录日志'} className='flex-y x-card-singlegrid data-grid' data={list}>
                <DataGrid data={list} option={options}/>
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                            onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
            </Box>
        </div>
    </Dialog>
}

function Option(props){
    const {search, initParams} = props
    const [params, setParams] = useState(initParams)

    return <div className={'c-options'}>
        <Form value={params} onChange={setParams}>
            <FormInput label={'登录时间'} bind={'startTime'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
            <span className={'form-text'}>至</span>
            <FormInput bind={'endTime'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={()=>search({...params,pageNum: 1,pageSize: 20,})}>查询</Button>
            <Button className={'reset-btn'} text onClick={() => {
                setParams(initParams)
            }}>重置</Button>
        </div>
    </div>
}

export default UserLoginLog;
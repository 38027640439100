import React, { useMemo, useEffect, useCallback, useReducer, useState } from 'react'
import _ from 'lodash'
import { DataGrid } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Box } from '../../../../../common/commonComponent'
import convertOptions from '../../../../../common/ConvertOptions'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import { strParams } from '../../../../../../utils/publicFun'
import RightMailDetail from './rightMailDetail'
import ReleaseVersionLog from './releaseVersionLog'
import './index.scss'

const RELEASE_RECORD_LIST_URL = '/releaseHistory/cust/list' //发布记录列表
const CUSTOMER_LIST_URL = '/verQuery/queryCustOptions' //客户系统

const { str14ToDate } = toDate

function getColumns(props) {
  const { allUserRes, customerOpt } = props
  return [{ header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
  { header: '发布客户系统', bind: 'customerId', width: 160, tooltip: true, convert: v => convertOptions(v, customerOpt) },
  { header: '发布人', bind: 'releaseUser', width: 120, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
  {
    header: '发布时间', bind: 'releaseDate', width: 135, tooltip: true, convert: v => {
      if (isNil(v)) return '-'
      return dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    }
  },
  ]
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  // fixedRight: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

export default function ReleaseRecord(props) {
  const { currentId: releaseId, allUserRes } = props
  const [params, setParams] = useState()
  const [open, setOpen] = useState(false)
  const [listData, setListData] = useState()
  const { data, error, loading, doFetch } = useGet()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { data: customerData, doFetch: customerFetch } = useGet()

  const customerOpt = useMemo(() => {
    if (_.isEmpty(customerData)) return []
    return _.map(customerData, o => ({ text: o.projectName, value: o.projectId }))
  }, [customerData])

  useEffect(() => {
    if (isNil(releaseId)) return
    customerFetch(`${CUSTOMER_LIST_URL}?${strParams({ releaseId })}`)
  }, [releaseId, customerFetch])

  const option = useMemo(() => {
    return getOptions(getColumns({ allUserRes, customerOpt }))
  }, [allUserRes, customerOpt])

  const extra = useMemo(() => {
    return <TextIconBtn text='发布' icon='' onClick={() => setOpen(true)} />
  }, [])

  const onRowClick = useCallback((item) => {
    setParams(item)
    _.forEach(data, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [data])

  const refreshList = useCallback(() => {
    if (_.isNil(releaseId)) return
    doFetch(`${RELEASE_RECORD_LIST_URL}?${strParams({ releaseId })}`)
      .then(res => {
        setListData(_.head(_.orderBy(res, 'releaseDate', 'desc')))
      })
  }, [doFetch, releaseId])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  useEffect(() => {
    if (isNil(listData)) return
    onRowClick(listData)
  }, [onRowClick, listData])

  return (
    <div className='release-record-list' >
      <Box className='flex-y x-card-singlegrid' title='发布列表' error={error} loading={loading} data={data} extra={extra} >
        <DataGrid data={_.orderBy(data, 'releaseDate', 'desc')} option={option} onRowClick={onRowClick} />
        <RightMailDetail {...{ params, refreshList }} />
      </Box>
      {open && <ReleaseVersionLog {...{ releaseId, refreshList, close: () => setOpen(false) }} />}
    </div>
  )
}
import React, {useCallback, useMemo} from 'react';
import './TrackerDesignInfo.scss'
import {Display, Form, FormInput, Select, Input, DatePicker} from 'rootnet-edit'
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import _ from "lodash";
import convertOptions from "../../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import {filterDisplayFlagOptions} from "../../../../common/publicFunc";
import TextAreaInput from "../../../../common/textAreaInput/TextAreaInput";
import gd from "../../../../../base/global";

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=AUDITLIST',
]

function TrackerDesignInfo(props) {
    const {isDetail, allUserRes, editFormData, setEditFormData, editFormError, setEditFormError, formData} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [auditListOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[setEditFormData])

    const showDetail = useMemo(()=>{
        return isDetail || _.get(formData,'auditPeople') !== gd.User.operator_id
    },[isDetail, formData])

    return <div className={'tracker-design-info flex center-y'}>
        <div className="design-info-form">
            <Form value={editFormData} onChange={changeForm} error={editFormError} onError={setEditFormError}>
                <HFormInput label={'设计人员'} bind={'creatorUserId'} component={Display}
                            convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                {
                    showDetail ? <HFormInput label='分析设计用时' bind='designUseTime' component={Display} convert={v => changeHourMinuteStr(v)} /> :
                        <div style={{ display: 'inline-block' }}>
                            <div className='real-use-time'>分析设计用时</div>
                            <div style={{ display: 'inline-block', width: 204 }}>
                                <HFormInput bind='designUseTimeHour' type='number' component={Input} componentWidth={45} placeholder='小时' min={0} max={999} digit={0} />小时
                                <HFormInput bind='designUseTimeMinute' type='number' component={Input} componentWidth={45} placeholder='分钟' min={0} max={59} digit={0} />分钟
                            </div>
                        </div>
                }
                <div/>
                <HFormInput label={'评审人员'} bind={'auditPeople'} component={showDetail ? Display : UserSelect} clear
                            convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                <HFormInput label={'评审标志'} bind={'auditPassFlag'} component={showDetail ? Display : Select} clear
                            options={filterDisplayFlagOptions(auditListOptions)} convert={v => convertOptions(v, auditListOptions)}/>
                <HFormInput label={'发现问题数量'} bind={'auditErrCount'} component={showDetail ? Display : Input} type={'number'} min={0} digit={0}/>
                <HFormInput label={'评审时间'} bind={'auditDate'} component={showDetail? Display:DatePicker} clear
                            bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}
                            convert={v => {
                                if(_.isNil(v)) return '-'
                                if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.dateStringToDate(v))
                                return dateFormat('YYYY-MM-DD', v) || '-'
                            }}/>
                <div/>
                {
                    showDetail && <div className={'textarea-field-wrap flex'}>
                        <HFormInput label='评审描述' componentWidth={0} component={Display} convert={()=>''}/>
                        <div className={'textarea-show-value'} style={{width: 501}}>
                            {
                                _.get(editFormData,'auditErrInfo')
                            }
                        </div>
                    </div>
                }
                {
                    !showDetail && <HFormInput className={'text-area-input-edit'} label='评审描述' bind='auditErrInfo'
                                               component={TextAreaInput} componentWidth={501} />
                }
            </Form>
        </div>
    </div>
}

function changeHourMinuteStr(str, mode) {
    if (str !== '0' && str !== '' && str !== null) {
        const hour = (Math.floor(str / 60)).toString().length < 2 ? '0' + (Math.floor(str / 60)).toString() :
            (Math.floor(str / 60)).toString()
        const minute = (str % 60).toString().length < 2 ? '0' + (str % 60).toString() : (str % 60).toString()
        if (mode === 'hour') return hour
        if (mode === 'minute') return minute
        return hour + '小时' + minute + '分钟';
    }
    else {
        return '';
    }
}

export default TrackerDesignInfo;
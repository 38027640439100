// 获取浏览器信息
export default function getExplorerInfo() {
    let explorer = window.navigator.userAgent.toLowerCase() ;
    //ie
    if (explorer.indexOf("msie") >= 0) {
        let ver=explorer.match(/msie ([\d.]+)/)[1];
        return {type:"IE",version:ver};
    }
    //firefox
    else if (explorer.indexOf("firefox") >= 0) {
        let ver=explorer.match(/firefox\/([\d.]+)/)[1];
        return {type:"Firefox",version:ver};
    }
    //Chrome
    else if(explorer.indexOf("chrome") >= 0){
        let ver=explorer.match(/chrome\/([\d.]+)/)[1];
        return {type:"Chrome",version:ver};
    }
    //Opera
    else if(explorer.indexOf("opera") >= 0){
        let ver=explorer.match(/opera.([\d.]+)/)[1];
        return {type:"Opera",version:ver};
    }
    //Safari
    else if(explorer.indexOf("Safari") >= 0){
        let ver=explorer.match(/version\/([\d.]+)/)[1];
        return {type:"Safari",version:ver};
    }
    else if(explorer.indexOf("safari") >= 0){
        let ver=explorer.match(/version\/([\d.]+)/)[1];
        return {type:"Safari",version:ver};
    }
}
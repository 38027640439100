import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import _ from "lodash";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import ViewArea from "../common/view/ViewArea";
import {Box} from "../common/commonComponent";
import {DataGrid, Pagination} from "rootnet-ui";
import {API1} from "../../base/task";
import {TextIconBtn} from "../common/TextIconBtn";
import {isNil} from "rootnet-core/format";
import {pathSearchFor} from "../../utils/publicFun";
import IssueDetailDialog from "./components/issueDetailDialog/IssueDetailDialog";
import {useFuncCode} from "../common/commonMethod";

const ENTER_DETAIL_FIELD = 'ProductionIssue.ShortDesc'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentInfo } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if (tableField === ENTER_DETAIL_FIELD){
            return <div className={'can-enter-text'} onClick={() => {
                setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
                {showValue}
            </div>
        }
        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '1900'

function IssueTracerMgt(props) {
    const { location } = props;
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [factor, setFactor] = useState()
    const [isFuncCodeApply] = useFuncCode(['1902'])
    const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
    const [sortConfig, setSortConfig] = useState()
    const [focusId, setFocusId] = useState()

    const tracerType = useMemo(()=>{
        return _.get(params,'View_Issue_bugAndFixTracerId.type')
    },[params])

    const factorViewSql = useMemo(()=>{
        if(isNil(tracerType)) return null
        const tracerTypeList = _.split(tracerType,',')
        const tracerTypeStringList = _.map(tracerTypeList, x => `'${x}'`)
        return `View_Issue_bugAndFixTracerId.type in (${_.join(tracerTypeStringList, ',')})`
    },[tracerType])

    const appendObj = useMemo(()=>{
        if(_.isNil(factorViewSql)) return null
        return {
            factorViewSql
        }
    },[factorViewSql])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: {setCurrentInfo},
        optionsAppendParams: {
            sort: sortConfig,
                onSort: (data, sort) => {
                setSortConfig(sort)
                return _.orderBy(data, x => x[sort.column], sort.direction)
            }
        },
    })

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        API1.downloadPost('/UserSetting/exportExcel', postParams)
    }, [currentViewId, fieldList, factor])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
        </div>
    }, [exportViewList])

    const showChildList = useMemo(() => {
        return _.filter(dataGridList, x => !_.get(x, '_children')) || []
    }, [dataGridList])

    const showList = useMemo(() => {
        let showList = showChildList || []
        if (!_.isNil(sortConfig)) {
            showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, showChildList])

    const currentIndex = useMemo(() => {
        const currentId = _.get(currentInfo, 'id')
        if (_.isNil(currentId)) return 0
        return _.findIndex(showList, x => _.get(x, 'id') === currentId)
    }, [currentInfo, showList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentInfo(old => {
            const cloneObj = { ...old }
            const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
            cloneObj['id'] = newId
            setFocusId(newId)
            return cloneObj
        })
    }, [showList, currentIndex])

    return <div className={' fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize}}/>
        <Box title={'ISSUE研发任务'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
            <Table {...{ options, showList, dataGridList, focusId }} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        {
            _.get(currentInfo, 'mode') === 'detail' &&
            <IssueDetailDialog close={() => setCurrentInfo(null)} refreshViewList={refreshList} {...{ isFuncCodeApply, currentInfo, switchCurrentItem, showChildList, currentIndex }} />
        }
    </div>
}

function Table(props) {
    const { options, showList, dataGridList, focusId } = props
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const onRowClick = useCallback((item) => {
        _.forEach(dataGridList, o => {
            return o._rowClass = item?.id === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    }, [dataGridList])

    useEffect(() => {
        if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    return <DataGrid option={options} data={showList} onRowClick={onRowClick} className='issue-data-grid' />
}


export default IssueTracerMgt;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DataGrid, Pagination, Button } from 'rootnet-ui'
import { useGet } from "rootnet-biz/es/hooks";
import { strParams } from "../../../../utils/publicFun";
import _ from "lodash";
import { Box } from "../../../common/commonComponent";
import './DefectLinkTracerDialog.scss'
import { Form, FormInput, Select, Radio } from "rootnet-edit";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import convertOptions from "../../../common/ConvertOptions";
import gd from "../../../../base/global";

function getColumns(props) {
    const { statusOptions, currentId } = props
    return [
        { width: 40, convert: (v, o) => <TableRadio {...{ o, currentId }} /> },
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
        { header: 'ID', bind: 'tracerId', width: 180, tooltip: true },
        { header: '标题', bind: 'tracerTitle', width: 300, tooltip: true },
        { header: '状态', bind: 'tracerState', width: 130, tooltip: true, convert: v => convertOptions(v, statusOptions) },
        { header: '开发人员', bind: 'userName', width: 160, tooltip: true,  },
        { header: '创建人员', bind: 'userIdName', width: 80, tooltip: true,  },
    ]

    function TableRadio(props) {
        const { o, currentId } = props
        return <Radio value={o.tracerId === currentId} />
    }
}

function getOption(columns) {
    return {
        columns,
        selection: true,
        autoFill: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
    }
}

const getInitParams = (initReleaseId) => {
    return ({
        pageNum: 1,
        pageSize: 20,
        id: null,
        status: ['T0', 'T1', 'T2'],
        // status: null,
        versionId: initReleaseId,
        testMan: gd.User.operator_id,
        branch: 0,
        idOrTitle: null,
    })
}

const optionsUrl = [
    '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C',
    '/common/globalconst?globalConst=TRACERSTATE',
    '/common/userinfo?indexValidFlag=1'
]

function DefectLinkTracerDialog(props) {
    const { close, changeForm, initTracerId, update, initReleaseId } = props
    const [params, setParams] = useState(getInitParams(initReleaseId))
    const { data, doFetch, loading, error } = useGet()
    const [currentId, setCurrentId] = useState(initTracerId)
    const { data: optionsRes } = useGet(optionsUrl)

    const [versionOptions, statusOptions, testManOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3] = optionsRes
        return [
            convertGlobalConstOptions(d1),
            _.map(d2, x => ({ value: x.interiorId, text: `${x.interiorId} - ${x.displayName}` })),
            _.map(d3, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}` }))
        ]
    }, [optionsRes])

    const { pageNum, pageSize, total, rows: list } = useMemo(() => data || {}, [data])

    useEffect(() => {
        doFetch('/test_case/selectconditionsinformation?type=Trace&' + strParams({ ...params }))
    }, [doFetch, params])

    const option = useMemo(() => {
        return getOption(getColumns({ statusOptions, currentId }))
    }, [statusOptions, currentId])

    const confirm = useCallback(() => {
        changeForm(x => {
            const newItem = _.assign({}, { ...x, tracerId: currentId })
            if (update) update(newItem)
            return newItem
        })
        close()
    }, [changeForm, close, currentId, update])

    return <Dialog header={'关联研发任务'} contentClassName={'defect-link-tracer-dialog'} cancel={close} confirm={confirm}>
        <Options initParams={params} search={setParams} {...{ versionOptions, statusOptions, testManOptions }} />
        <Box title='研发任务列表' className='trace-list x-card-singlegrid flex-y' data={list} loading={loading} error={error}>
            <DataGrid option={option} data={list} onRowClick={o => setCurrentId(o.tracerId)} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
    </Dialog>
}

function Options(props) {
    const { initParams, search, versionOptions, statusOptions, testManOptions } = props
    const [params, setParams] = useState(initParams)

    return <div className='c-options'>
        <Form value={params} onChange={setParams}>
            <FormInput label='搜索ID或标题' bind='idOrTitle' clear />
            <FormInput label='版本ID' bind='versionId' component={Select} search clear options={versionOptions} />
            <FormInput label='状态' bind='status' component={Select} multiple search clear options={statusOptions} />
            <FormInput label='测试人员' bind='testMan' component={Select} search clear options={testManOptions} />
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={() => search({ ...params, pageNum: 1, pageSize: 20, })}>查询</Button>
        </div>
    </div>
}

export default DefectLinkTracerDialog;
import React, { useEffect, useCallback, useState, useMemo } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Box } from '../../../common/commonComponent'
import Option from './option'
import AcceptDialog from './acceptDialog'
import RefuseDialog from './refuseDialog'
import TransferHandlerDialog from './transferHandlerDialog'
import gd from '../../../../base/global'
import FlowRecordDialog from './flowRecordDialog'
import TimeDetailsList from './timeDetailsList'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import { downCsv, normalTransCsv } from '../../../common/download'
import './index.scss'
import CsvExportNumLog from '../../../common/csvExportNumLog'
import WorkHoursDetailDrawer from './workHoursDetailDrawer'

const LIST_URL = '/workingHoursManagement/selectPage'
const EDIT_URL = '/workingHoursManagement/updateReview'
const TRANS_FER_HANDLER_EDIT_URL = '/workingHoursManagement/updateReviewCurrentUser'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=ProductLine',//项目产品线
  '/UserSetting/getUniversalInterfaces?code=id&codeName=ProjectName&tableName=UserProject', //项目
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&filter=Sort&filterParams=事务new&tableName=WorkType&pIdParams=pId', //事务
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&globalConst=working_hours_type&tableName=GlobalConst', //工时类型
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&globalConst=workHoursStatus&tableName=GlobalConst', //项目状态
  '/common/globalconst?globalConst=workingProjectType' //项目工时类型
]

const OPTIONS_URLS = [
  '/common/department?activeFlag=Y'
]


const gridOptsFor = (columns) => ({
  nilText: '-',
  fixedLeft: 2,
  fixedRight: 1,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});

const statusColor = {
  '1': '#FCA000',
  '2': '#00BC81',
  '3': '#FF4D56',
}

let isDisabled = false

export default function WorkTimeReviewAndSearchList(props) {
  const { initParams, title, extra, getColumn, selectItems, setSelectItems, currentInfo, setCurrentInfo, exportTitle } = props
  const [parameter, setParameter] = useState(initParams)
  const [pageSize, setPageSize] = useState(20)
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch, data: resData, loading, error } = usePost()
  const { doFetch: editFetch } = usePost()
  const { rows: data = [], total, pageNum } = useMemo(() => resData || {}, [resData])
  const { type, typeFlag } = useMemo(() => parameter || {}, [parameter])
  const isWorkTimeSearch = useMemo(() => exportTitle === '工时查询', [exportTitle])

  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [productLinesOpt, projectOpt, affairOpt, manHourTypeOpt, stateOpt, workingProjectTypeOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const [deptOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes || []
    // const firstLevelDepList = _.filter(d1, x => _.get(x, 'parentDept') === "")
    // const treeDeptOptions = buildTree(d1, firstLevelDepList, "departMentId", "parentDept", 0)
    return [
      _.map(d1, o => ({ text: o.departMentName, value: o.departMentId, pid: o.parentDept }))
    ]
  }, [optionsRes])

  const affairOptins = useMemo(() => {
    const parentList = _.filter(affairOpt, o => _.isNil(o.pid))
    const childrenList = _.filter(affairOpt, o => !_.isNil(o.pid))
    return _.map(parentList, o => {
      const children = _.filter(childrenList, item => o.value === item.pid)
      return _.assign({}, o, { children })
    })
  }, [affairOpt])

  const refresh = useCallback((params = {}) => {
    const { period } = params
    const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
    const getParams = _.assign({}, params, !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval })
    setSelectItems([])
    doFetch(LIST_URL, _.omit(getParams, ['period']))
  }, [doFetch, setSelectItems])

  useEffect(() => {
    refresh(initParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const submit = useCallback(({ list, params }) => {
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.map(list, (o, i) => {
      const findItem = _.find(_.get(o, 'workingHoursReviews', []), item => item.reviewer === gd.User.operator_id)
      const initDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'beginDate')))

      const penetrationBeginDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'beginDate')))
      const penetrationEndDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'endDate')))
      const { period } = parameter
      const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
      const searchParams = _.assign({},
        parameter,
        !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval },
        { pageNum: 1, pageSize: 1000, typeFlag: 'selectRecordPage', },
        (parameter?.typeFlag === 'selectGroupByProjectPage' && o?.type === 'dev') && { penetrationDev: o.projectId, penetrationBeginDate, penetrationEndDate },
        (parameter?.typeFlag === 'selectGroupByProjectPage' && o?.type !== 'dev') && { penetrationTransaction: o.projectId, penetrationBeginDate, penetrationEndDate },
        parameter?.typeFlag === 'selectEmployeeGroupingPage' && { penetratingEmployee: o.userAccount, penetrationBeginDate, penetrationEndDate },
      )

      return _.assign({},
        { relateId: o.id },
        _.pick(findItem, ['id', 'reviewer']),
        params,
        params?.state === '2' && { refuseImpactFlag: 'Y', initDate },
        (parameter?.typeFlag !== 'selectRecordPage') && { workingHoursQueries: [searchParams] },

      )
    })
    editFetch(EDIT_URL, postParams)
      .then(() => {
        refresh(parameter)
        setIsDisable(false)
        setCurrentInfo(null)
        setSelectItems([])
        Messager.show('操作成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, editFetch, setCurrentInfo, refresh, parameter, setSelectItems])

  const transferHandlerSubmit = useCallback(({ list, params }) => {
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.map(list, (o, i) => {

      const penetrationBeginDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'beginDate')))
      const penetrationEndDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'endDate')))
      const { period } = parameter
      const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
      const searchParams = _.assign({},
        parameter,
        !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval },
        { pageNum: 1, pageSize: 1000, typeFlag: 'selectRecordPage', },
        (parameter?.typeFlag === 'selectGroupByProjectPage' && o?.type === 'dev') && { penetrationDev: o.projectId, penetrationBeginDate, penetrationEndDate },
        (parameter?.typeFlag === 'selectGroupByProjectPage' && o?.type !== 'dev') && { penetrationTransaction: o.projectId, penetrationBeginDate, penetrationEndDate },
        parameter?.typeFlag === 'selectEmployeeGroupingPage' && { penetratingEmployee: o.userAccount, penetrationBeginDate, penetrationEndDate },
      )

      return _.assign({}, { relateId: o.id }, params,
        (parameter?.typeFlag !== 'selectRecordPage') && { workingHoursQueries: [searchParams] })
    })
    editFetch(TRANS_FER_HANDLER_EDIT_URL, postParams)
      .then(() => {
        refresh(parameter)
        setIsDisable(false)
        setCurrentInfo(null)
        setSelectItems([])
        Messager.show('操作成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, editFetch, setCurrentInfo, refresh, parameter, setSelectItems])

  const option = useMemo(() => gridOptsFor(getColumn({ type: typeFlag, setCurrentInfo, submit, statusColor, active: type, workingProjectTypeOpt })), [typeFlag, getColumn, setCurrentInfo, submit, type, workingProjectTypeOpt])

  const detailOption = useMemo(() => gridOptsFor(_.filter(getColumn({ type: 'selectRecordPage', setCurrentInfo, submit, statusColor, flag: true, workingProjectTypeOpt }), (v, i) => i !== 0)), [getColumn, setCurrentInfo, submit, workingProjectTypeOpt])

  const csvExport = useCallback((downNum = 1000) => {
    if (isDisabled) return
    isDisabled = true
    const { period } = parameter
    const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
    const getParams = _.assign({}, parameter, { pageNum: 1, pageSize: downNum, }, !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval })
    editFetch(LIST_URL, _.omit(getParams, ['period']))
      .then((res) => {
        const { rows: list } = res
        const find = '\n'
        const options = _.filter(getColumn({ type: typeFlag, workingProjectTypeOpt }), o => !_.isNil(o?.bind))
        const newList = _.map(list, o => _.assign({}, o, { description: _.replace(o.description, new RegExp(find, 'g'), '') }))
        const exportData = normalTransCsv(options, newList)
        downCsv(exportData, exportTitle)
        isDisabled = false
        setCurrentInfo(null)
        Messager.show('操作成功', { icon: 'success' })
      })
      .catch(err => {
        isDisabled = false
        Messager.show(err._message, { icon: 'error' })
      })
  }, [editFetch, parameter, setCurrentInfo, typeFlag, getColumn, exportTitle, workingProjectTypeOpt])

  useEffect(() => {
    if (_.get(currentInfo, 'mode') === 'export') csvExport()
  }, [currentInfo, csvExport])

  const listData = useMemo(() => {
    if (_.isEmpty(data)) return []
    return _.map(data, o => {
      o['standardSunday'] = _.isNil(o.standardSunday) ? o.standardSunday : _.toNumber(o.standardSunday)
      o['users'] = _.isNil(o.users) ? o.users : _.toNumber(o.users)
      o['useDay'] = _.isNil(o.useDay) ? o.useDay : _.toNumber(o.useDay)
      return o
    })
  }, [data])

  return (
    <>
      <div className='fill flex-y work-time-review-and-search-list'>
        <Option search={refresh} {...{ initParams, setParameter, active: type, productLinesOpt, projectOpt, affairOpt: affairOptins, manHourTypeOpt, stateOpt, deptOptions, workingProjectTypeOpt, pageSize, setPageSize }} />
        <Box className='flex-y x-card-singlegrid' title={title} data={listData} error={error} extra={extra} loading={loading} >
          <DataGrid data={listData} option={option} selection={selectItems} onSelectionChange={setSelectItems} />
          <Pagination selector total={total} current={pageNum} pageSize={pageSize} onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParameter(x => {
              const params = _.assign({}, x, { pageNum, pageSize })
              refresh(params)
              return params
            })
          }} />
        </Box>
      </div>
      {/* 导出选择数量 */}
      {
        _.get(currentInfo, 'mode') === 'downloadExcel' &&
        <CsvExportNumLog
          downLoad={csvExport}
          close={() => setCurrentInfo(null)}
        />
      }
      {/* 转移处理人 */}
      {
        _.get(currentInfo, 'mode') === 'transferHandler' &&
        <TransferHandlerDialog
          isWorkTimeSearch={isWorkTimeSearch}
          confirm={transferHandlerSubmit}
          size={_.size(_.get(currentInfo, 'ids'))}
          close={() => setCurrentInfo(null)}
          list={_.get(currentInfo, 'list')}
        />
      }
      {/* 接受 */}
      {
        _.get(currentInfo, 'mode') === 'accept' &&
        <AcceptDialog
          confirm={submit}
          size={_.size(_.get(currentInfo, 'ids'))}
          close={() => setCurrentInfo(null)}
          list={_.get(currentInfo, 'list')}
        />
      }
      {/* 拒绝 */}
      {
        _.get(currentInfo, 'mode') === 'refuse' &&
        <RefuseDialog
          confirm={submit}
          size={_.size(_.get(currentInfo, 'ids'))}
          close={() => setCurrentInfo(null)}
          list={_.get(currentInfo, 'list')}
        />
      }
      {/* 流转记录 */}
      <FlowRecordDialog
        open={_.get(currentInfo, 'mode') === 'flowRecord'}
        close={() => setCurrentInfo(null)}
        item={_.get(currentInfo, 'item')}
      />
      <TimeDetailsList
        option={detailOption}
        open={_.get(currentInfo, 'mode') === 'detail'}
        close={() => setCurrentInfo(null)}
        params={_.assign({}, _.get(currentInfo, 'params', {}), parameter, { typeFlag: 'selectRecordPage' })}
      />
      <WorkHoursDetailDrawer
        open={_.get(currentInfo, 'mode') === 'workTimeReport'}
        close={() => setCurrentInfo(null)}
        params={_.get(currentInfo, 'params')}
        user={_.get(currentInfo, 'user')}
      />
    </>
  )
}

// function convertDepartment(item) {
//   return { text: item.departMentName, value: item.departMentId }
// }

// function buildTree(allList, list, keyName, parentName, level) {
//   return _.map(list, listItem => {
//     const childList = _.filter(allList, allItem => _.get(allItem, parentName) === _.get(listItem, keyName))
//     if (level >= 2 || childList.length === 0) return convertDepartment(listItem)
//     return _.assign(convertDepartment(listItem), { children: buildTree(allList, childList, keyName, parentName, level + 1) })
//   })
// }


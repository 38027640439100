import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {_cache} from "../utils/cache";
import {useHistory} from "react-router-dom";
import {clearCache, dropByCacheKey, getCachingKeys} from "react-router-cache-route";
import {Menu} from "antd";
import {usePost} from "../utils/hook";

export default function useRouterCache(){
    const [activeMenuList, setActiveMenuList] = useState(_.filter(_cache.get('activeMenuList'), x => !_.isNil(x)) || [])
    const [activeUrl, setActiveUrl] = useState(_.split(window.location.hash.slice(1),'?')[0] || '/workplace');
    const history = useHistory();
    const {doFetch: addFunctionCount} = usePost()

    const onMenuItemClick = useCallback((menuItem) => {
        if(_.isEmpty(_.get(menuItem,'children'))){
            addFunctionCount('/functionCount/add',{functionName: menuItem.funcCode})
        }
        const currentUrl = menuItem.url
        if (_.isNil(currentUrl) || currentUrl === '') return
        if (!_.includes(_.map(activeMenuList, x => x.url), currentUrl)) {
            setActiveMenuList(oldActiveList => _.concat([], oldActiveList, [menuItem]))
        }
        setActiveUrl(currentUrl)
    }, [activeMenuList,addFunctionCount])

    useEffect(() => {
        _cache.set('activeUrl', activeUrl)
    }, [activeUrl])

    useEffect(() => {
        _cache.set('activeMenuList', activeMenuList)
    }, [activeMenuList])

    const onTabChange = useCallback((key) => {
        setActiveUrl(key)
        history.push(key)
    }, [history])

    const onTabRemove = useCallback((key) => {
        if (activeMenuList.length === 1) return
        let toUrl = key
        dropByCacheKey(key)
        if (key === activeUrl) {
            const currentIndex = _.findIndex(activeMenuList, x => x.url === key)
            if (currentIndex !== 0) {
                const lastItem = activeMenuList[currentIndex - 1]
                toUrl = lastItem.url
                onTabChange(toUrl)
            } else {
                const nextItem = activeMenuList[currentIndex + 1]
                toUrl = nextItem.url
                onTabChange(toUrl)
            }
        }
        setActiveMenuList(oldList => [..._.filter(oldList, x => x.url !== key)])
    }, [activeMenuList, activeUrl, onTabChange])

    const onCloseMenuClick = useCallback((action) => {
        if (action === 'close-all') {
            const allMenuList = _cache.get('allMenuList')
            clearCache()
            const homeItem = _.find(allMenuList, x => x.url === '/workplace')
            onTabChange(homeItem.url)
            setActiveMenuList([homeItem])
        } else if (action === 'close-other') {
            const clearList = _.filter(getCachingKeys(), x => !_.includes([activeUrl,'/workplace'],x))
            setActiveMenuList(oldList => {
                return _.filter(oldList, x => _.includes([activeUrl,'/workplace'],x.url))
            })
            _.forEach(clearList, x => dropByCacheKey(x))
        }
    }, [onTabChange, activeUrl])

    const CloseMenu = (
        <Menu onClick={e => onCloseMenuClick(e.key)} className='menu-panels-close'>
            <Menu.Item key="close-other">
                关闭其他标签页
            </Menu.Item>
            <Menu.Item key="close-all">
                关闭全部标签页
            </Menu.Item>
        </Menu>
    );

    return {
        activeMenuList,
        activeUrl,
        onMenuItemClick,
        onTabChange,
        onTabRemove,
        CloseMenu,
        setActiveUrl
    }
}
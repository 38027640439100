import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import './CustomerServiceInfoContent.scss'
import { TextIconBtn } from "../../common/TextIconBtn";
import Icon from "../../../components/Icon";
import CustomerServiceInfoHeader
    from "./customerServiceInfoHeader/CustomerServiceInfoHeader";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import _ from "lodash";
import { Badge, Tabs } from "antd";
import CustomerServiceBaseInfo
    from "./customerServiceBaseInfo/CustomerServiceBaseInfo";
import ChangeRecord from "../../common/ChangeRecord";
import RequirementUploadArea
    from "../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea";
import { Button, Messager, Loader, Dialog } from 'rootnet-ui'
import { isNil } from "../../appraise/components/method";
import RequirementAddDialog from "../../requirementMgt/requirementAddDialog/RequirementAddDialog";
import IssueAdd from "../../issueMgt/components/issueAdd/IssueAdd";

const { TabPane } = Tabs;

const FUNC_CODE = '20'

function CustomerServiceInfoContent(props) {
    const { close, currentInfo, switchCurrentItem, showChildList, currentIndex, refreshViewList } = props
    const { mode: initMode = 'detail', id: initId } = currentInfo || {}
    const [formData, setFormData] = useState()
    const [editFormData, setEditFormData] = useState()
    const { data: allUserRes } = useGet('/common/userinfo')
    const [formError, setFormError] = useState()
    const [activeKey, setActiveKey] = useState('info')
    const [id, setId] = useState(initId)
    const { doFetch: getDetail } = useGet()
    const descRef = useRef()
    const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
    const [countStatus, updateStatus] = useReducer((x) => x + 1, 1000) // 动态
    const [fileCount, setFileCount] = useState(0)
    const [switchDisabled, setSwitchDisabled] = useState(false)
    const [contentLoading, setContentLoading] = useState(false)
    const { doFetch: submitPost } = usePost()
    const [relateFileInfo, setRelateFileInfo] = useState()
    const [showDialog, setShowDialog] = useState()

    const [canMove, setCanMove] = useState(false);
    const [htmlWidth, setHtmlWidth] = useState(340);
    const prevXRef = useRef(null);
    const panelRef = useRef(null)

    const { apiAuthFlag } = useMemo(() => editFormData || {}, [editFormData])

    useEffect(() => {
        const { id } = currentInfo || {}
        if (!_.isNil(id)) {
            setId(id)
        }
    }, [currentInfo])

    const refreshDetail = useCallback(() => {
        if (_.isNil(id)) return
        setSwitchDisabled(true)
        setContentLoading(true)
        getDetail('/customerService/list?id=' + id).then(res => {
            setFormData(res)
            setEditFormData(res)
            setSwitchDisabled(false)
            setContentLoading(false)
        }).catch(err => {
            setSwitchDisabled(false)
            setContentLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
        updateDynamic()
        updateStatus()
    }, [getDetail, id])

    useEffect(() => {
        refreshDetail()
    }, [refreshDetail])

    const [mode, setMode] = useState(initMode)

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const switchMode = useCallback((resetDesc = false, toMode) => {
        if (resetDesc) {
            if (descRef.current) {
                descRef.current.setContent(_.get(formData, 'reqDetail'))
            }
        }
        setEditFormData(formData)
        if (toMode) {
            setMode(toMode)
        } else {
            setMode(x => x === 'detail' ? 'edit' : 'detail')
        }
    }, [formData])

    const canSubmit = useMemo(() => {
        if (isNil(_.get(editFormData, 'issueDesc'))) {
            return false
        }
        if (isNil(_.get(editFormData, 'currentUser'))) {
            return false
        }
        return !_.some(_.values(formError), x => x)
    }, [formError, editFormData])

    const submit = useCallback((submitForm) => {
        if (_.isNil(submitForm) && isNil(descRef.current.getContent())) {
            return Messager.show("请填写详细描述")
        }
        const form = submitForm ? submitForm : editFormData
        if (contentLoading) return
        setContentLoading(true)
        let handleForm = {}
        _.forEach(form, (v, k) => {
            if (_.isNil(v)) {
                handleForm[k] = ''
            } else {
                handleForm[k] = v
            }
        })
        let nullList = []
        _.forEach(form, (v, k) => {
            if (isNil(v)) {
                nullList.push({
                    field: k,
                    value: v
                })
            }
        })
        const submitParams = {
            ...form,
            reqDetail: submitForm ? _.get(submitForm, 'reqDetail') : _.replace(descRef.current.getContent(), /\n/g, ''),
            setNull: nullList
        }
        submitPost('/customerService/modify', _.omit(submitParams, ['currentUser'])).then(() => {
            const currentUserParams = {
                businessId: _.get(editFormData, 'id'),
                businessType: 'customer',
                funcCode: FUNC_CODE,
                currentUser: _.get(editFormData, 'currentUser'),
                title: _.get(editFormData, 'title')
            }
            if (_.get(formData, 'currentUser') === _.get(editFormData, 'currentUser')) {
                afterRefresh()
            } else {
                submitPost('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                    afterRefresh()
                }).catch(err => {
                    Messager.show(err._message, { icon: 'error' })
                    setContentLoading(false)
                })
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setContentLoading(false)
        })
        function afterRefresh() {
            Messager.show('修改成功', { icon: 'success' })
            setContentLoading(false)
            refreshDetail()
            switchMode(false, 'detail')
            if (refreshViewList) {
                refreshViewList()
            }
        }
    }, [contentLoading, editFormData, submitPost, switchMode, refreshViewList, refreshDetail, formData])

    const canCreateIssue = useMemo(() => {
        if (_.get(formData, 'msgType') === '9' || _.get(formData, 'issueKind') === '03') {
            if (_.isNil(_.get(formData, 'issueId'))) {
                return true
            }
        }
        return false
    }, [formData])

    const canCreateReq = useMemo(() => {
        if (_.get(formData, 'msgType') === '9' || _.get(formData, 'issueKind') === '03') {
            if (_.isNil(_.get(formData, 'interiorReqNo'))) {
                return true
            }
        }
        return false
    }, [formData])

    return <div className={'customer-service-info-content flex-y'}>
        <div className="mock-dialog-header flex">
            <div className="dialog-title">
                客户服务详情信息
            </div>
            <div className="mock-right-header flex center-y">
                {
                    isDetail &&
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'bug-copy'} className={`header-edit-text-icon`} text={'创建issue'} disabled={!canCreateIssue} onClick={() => {
                            if (isNil(apiAuthFlag)) return Messager.show('请编辑补充信息后重试', { icon: 'error' })
                            if (fileCount === 0) {
                                setShowDialog({
                                    module: 'createIssue',
                                    params: { serviceId: id }
                                })
                            } else {
                                setRelateFileInfo({
                                    text: 'issue',
                                    module: 'createIssue',
                                    relateParams: { serviceId: id, serviceFileId: id },
                                    notRelateParams: { serviceId: id },
                                })
                            }
                        }} />
                    </span>
                }
                {
                    isDetail &&
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'xuqiu1'} className={`header-edit-text-icon`} text={'创建需求'} disabled={!canCreateReq} onClick={() => {
                            if (isNil(apiAuthFlag)) return Messager.show('请编辑补充信息后重试', { icon: 'error' })
                            if (fileCount === 0) {
                                setShowDialog({
                                    module: 'createReq',
                                    params: { serviceId: id }
                                })
                            } else {
                                setRelateFileInfo({
                                    text: '需求',
                                    module: 'createReq',
                                    relateParams: { serviceId: id, serviceFileId: id },
                                    notRelateParams: { serviceId: id },
                                })
                            }
                        }} />
                    </span>
                }
                <span style={{ marginRight: 16 }}>
                    <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                        switchMode(true)
                        // refreshDetail(id)
                    }} />
                </span>
                <div className={'close-area flex center'} onClick={close}>
                    <Icon name={'quxiao'} className={'close-icon'} />
                </div>
            </div>
        </div>
        <CustomerServiceInfoHeader {...{ editFormData, isDetail, setEditFormData, allUserRes, id, refreshViewList, refreshDetail, updateDynamic, countStatus }} />
        <div className="main-content-wrap flex"
            onMouseMove={handleMove} ref={panelRef}
            onMouseLeave={() => setCanMove(false)}
            onMouseUp={() => setCanMove(false)}
        >
            <div className="left-content flex" >
                <Tabs activeKey={activeKey} onChange={setActiveKey}>
                    <TabPane tab='详细信息' key={'info'} forceRender={true}>
                        <CustomerServiceBaseInfo {...{ formError, setFormError, editFormData, setEditFormData, isDetail, allUserRes, descRef, formData, submit, updateDynamic, refreshDetail }} />
                    </TabPane>
                    <TabPane tab={
                        <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                            附件
                        </Badge>
                    } key={'doc'} forceRender={true}>
                        <div className="file-wrap">
                            <RequirementUploadArea funcCode={'20'} {...{ id, mode, setFileCount, allUserRes }} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div className='drag-wrap' onMouseDown={() => setCanMove(true)}><Icon name='yidongshu' /></div>
            <div className={'dynamic-wrap'} key={countDynamic} style={{ flexWrap: 'wrap', width: htmlWidth }}>
                <ChangeRecord name='客户服务信息' funcCode={'20'} referenceId={id} id={_.get(editFormData, 'csrecordID')}
                    title={_.get(editFormData, 'issueDesc')} linkUrl={`/customerServiceInfo?initId=${id}`}
                    commentWidth={'90%'} />
            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div />
            {
                _.isEmpty(showChildList) ? <div /> :
                    <div className="footer-switch flex center-y">
                        <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                            onClick={() => {
                                if (switchDisabled) return
                                if (!_.includes([-1, 0], currentIndex)) {
                                    switchCurrentItem('previous')
                                }
                            }}>
                            <Icon name='xiangqian' />&nbsp;上一个
                        </div>
                        <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                            onClick={() => {
                                if (switchDisabled) return
                                if (!(currentIndex === showChildList?.length - 1)) {
                                    switchCurrentItem('next')
                                }
                            }}>
                            下一个&nbsp;<Icon name='xianghou' />
                        </div>
                        <div className="list-num">
                            {currentIndex + 1}/{showChildList?.length}
                        </div>
                    </div>
            }
            <div className="btn-group flex">
                {
                    isDetail &&
                    <Button normal onClick={close}>关闭</Button>
                }
                {
                    !isDetail &&
                    <Button normal onClick={() => {
                        switchMode(true)
                        setEditFormData(formData)
                    }}>取消</Button>
                }
                {
                    !isDetail &&
                    <Button primary onClick={() => submit()} disable={!canSubmit}>确认</Button>
                }
            </div>
        </div>
        {contentLoading && <Loader fill />}
        {
            relateFileInfo &&
            <Dialog headerVisible={false} footerVisible={false} className={'content-center-dialog'}>
                <div className={'service-relate-file-content flex-y'}>
                    是否将附件转入{_.get(relateFileInfo, 'text')}
                    <div className={'relate-file-btn-group flex'}>
                        <Button primary onClick={() => {
                            setShowDialog({
                                module: _.get(relateFileInfo, 'module'),
                                params: _.get(relateFileInfo, 'relateParams'),
                            })
                            setRelateFileInfo(null)
                        }}>是</Button>
                        <Button normal onClick={() => {
                            setShowDialog({
                                module: _.get(relateFileInfo, 'module'),
                                params: _.get(relateFileInfo, 'notRelateParams'),
                            })
                            setRelateFileInfo(null)
                        }}>否</Button>
                    </div>
                </div>
            </Dialog>
        }
        {
            _.get(showDialog, 'module') === 'createReq' &&
            <RequirementAddDialog afterRefresh={() => {
                refreshDetail()
            }} close={() => setShowDialog(null)} currentInfo={_.get(showDialog, 'params')} />
        }
        {
            _.get(showDialog, 'module') === 'createIssue' &&
            <IssueAdd close={() => setShowDialog(null)} refreshViewList={refreshDetail} currentInfo={_.get(showDialog, 'params')} />
        }
    </div>

    function handleMove(e) {
        if (!canMove) return;
        e.preventDefault();
        e.stopPropagation();
        const prevX = prevXRef.current;
        prevXRef.current = e.clientX
        if (prevX === null) return null;
        const offset = e.clientX - prevX;
        setHtmlWidth(x => {
            if (x - offset < 360) {
                prevXRef.current = prevX
                return 360
            } else if ((x - offset) > 650) {
                prevXRef.current = prevX
                return x
            } else {
                return x - offset
            }
        });
    }
}

export default CustomerServiceInfoContent;
import React, {useCallback, useMemo, useState} from 'react';
import _ from "lodash";
import convertTableAlign from "../../common/view/convertTableAlign";
import findConvert from "../../common/view/findConvert";
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import {useGet} from "rootnet-biz/lib/hooks";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";
import {DataGrid, Messager, Pagination} from "rootnet-ui";
import {API1} from "../../../base/task";
import {TextIconBtn} from "../../common/TextIconBtn";
import ViewArea from "../../common/view/ViewArea";
import {Box} from "../../common/commonComponent";
import {CheckBox} from "rootnet-edit";
import {useFuncCode} from "../../common/commonMethod";
import DelMessage from "../../../components/DelMessage";
import {Icon} from "../../../components";
import './SalesProductViewMgt.scss'
import SalesProductViewDetailDialog from "./SalesProductViewDetailDialog";
import convertOptions from "../../common/ConvertOptions";
import ImportApiDialog from "../../common/ImportApiDialog";
import clsx from "clsx";
import {_cache} from "../../../utils/cache";

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, selectedIdList, setSelectedIdList, setDelId, delAuth, setCurrentInfo, isSelectedAll, list } = props

    const customColumns = []

    return [
        { header: <div className={clsx('first-col flex center', { 'selected': isSelectedAll })}>
                <div className={'check-box'} onClick={() => {
                    if (isSelectedAll) {
                        setSelectedIdList([])
                    } else {
                        setSelectedIdList(_.map(list,'id') || [])
                    }
                }}>
                    <CheckBox value={isSelectedAll} />
                </div>
                <div className={'index-num'}>
                    #
                </div>
            </div>
            , align: 'center',stretch: false, width: 40, convert: (v, o) => {
                const id = _.get(o, 'id')
                return <CheckBox value={_.includes(selectedIdList, id)} onChange={()=>{
                    if(_.includes(selectedIdList, id)){
                        setSelectedIdList(old => _.filter(old, x => x !== id))
                    }else{
                        setSelectedIdList(old => _.concat(old, [id]))
                    }
                }}/>
            }},
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center', stretch: false,  },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== 'sales_productInfo.moduleName',
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`

        if(tableField === 'sales_productInfo.moduleName'){
            return <div className="enter-detail-field-wrap flex center-y">
                <div className="enter-detail-field-text can-enter-text one-line-text" onClick={()=> {
                    setCurrentInfo({
                        id: _.get(o,'id'),
                        mode: 'detail'
                    })
                }}>
                    {showValue || '-'}
                </div>
                {
                    delAuth &&
                    <Icon name={'shanchu2'} className="enter-detail-field-delete-icon"
                          onClick={() => setDelId(o.id)} />
                }
            </div>
        }

        if(tableField === 'sales_productInfo.openFlag'){
            const convertRule = _.find(convertCollection, (v,k) => k === 'sales_productInfo.openFlag')
            const color = convertOptions(v, convertRule,'color','value',{showOrigin: true}) || '#000'
            return <div style={{color: color, border: `1px solid ${color}`, padding: '0 8px', borderRadius: 4}}>
                {showValue || '-'}
            </div>
        }
        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

function getImportColumns(props){
    return [
        {header: '产品模块名称', bind: 'moduleName',width: 100, tooltip: true},
        {header: '产品', bind: 'system',width: 100, tooltip: true},
        {header: '销售类别', bind: 'productType',width: 100, tooltip: true},
        {header: '一级类别', bind: 'firstLevel',width: 100, tooltip: true},
        {header: '二级类别', bind: 'secondLevel',width: 100, tooltip: true},
        {header: '三级类别', bind: 'thirdLevel',width: 100, tooltip: true},
        {header: '可售标识', bind: 'openFlag',width: 100, tooltip: true},
        {header: '产品经理', bind: 'manager',width: 100, tooltip: true},
        {header: '发布日期', bind: 'releaseDate',width: 100, tooltip: true},
        {header: '单位', bind: 'unit',width: 100, tooltip: true},
        {header: '数量', bind: 'quantity',width: 100, tooltip: true},
        {header: '标准单价', bind: 'priceStand',width: 100, tooltip: true},
    ]
}

const FUNC_CODE = '41'

function SalesProductViewMgt(props) {
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [factor, setFactor] = useState()
    const {data: allowExport} = useGet(`/FuncTable/getAllowExportFlag?funcCode=`+FUNC_CODE)
    const [selectedIdList, setSelectedIdList] = useState([])
    const [dialogInfo, setDialogInfo] = useState()
    const [delId, setDelId] = useState()
    const [currentInfo, setCurrentInfo] = useState()
    const [showDialogInfo, setShowDialogInfo] = useState()
    const currentMenuInfo = useMemo(()=>_.find(_cache.get('allMenuList'), x => x?.url === '/salesProductViewMgt'),[])

    const userPositionList = useMemo(()=>JSON.parse(localStorage.getItem('userPositionList')),[])
    const isManager = useMemo(()=>{
        return _.includes(userPositionList, 'salesProductMgt')
    },[userPositionList])

    const [editAuth, addAuth, delAuth] = useFuncCode(["120110",'120120','120130'])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const afterRefresh = useCallback(()=>{
        setSelectedIdList([])
    },[])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor,
        afterRefresh: afterRefresh
    })

    const dateOptions = useGetDateOptions()

    const isSelectedAll = useMemo(()=>{
        if (_.isEmpty(list)) return false
        return _.every(list, item => _.includes(selectedIdList,item.id))
    },[list, selectedIdList])

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: {selectedIdList, setSelectedIdList, setDelId, delAuth, setCurrentInfo, isSelectedAll, list}
    })

    const exportViewList = useCallback(()=>{
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        Messager.show('导出中...')
        API1.downloadPost('/UserSetting/exportExcel',postParams)
    },[currentViewId, fieldList, factor])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            {
                delAuth &&
                <TextIconBtn text='删除' icon='shanchu' disabled={!_.size(selectedIdList) > 0} onClick={()=>setDialogInfo({module: 'del'})} />
            }
            {
                addAuth &&
                <TextIconBtn text='导入' icon={'daoru'} onClick={()=>setShowDialogInfo({module: 'import'})}/>
            }
            {
                allowExport === 'Y' &&
                <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList}/>
            }
            {
                addAuth &&
                <TextIconBtn text='新增' icon={'tianjia'} onClick={()=>setCurrentInfo({mode: 'add'})}/>
            }
        </div>
    }, [exportViewList, allowExport, selectedIdList, delAuth, addAuth])

    const importOption = useMemo(()=>{
        return getOptions(getImportColumns())
    },[])

    return <div className={'sales-product-view-mgt fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize}}/>
        <Box title={currentMenuInfo?.funcName || '营销产品清单'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
            <DataGrid option={options} data={dataGridList} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        {
            dialogInfo?.module === 'del' &&
            <DelMessage url={'/salesproductInfo/delete'} refresh={refreshList} method={'post'} params={selectedIdList} close={()=>setDialogInfo(null)} confirmButtonText={'删除'}>
                模块会被删除。
                <br/>
                此操作不可撤销，是否确定删除？
            </DelMessage>
        }
        {
            delId &&
            <DelMessage url={'/salesproductInfo/delete'} refresh={refreshList} method={'post'} params={[delId]} close={()=>setDelId(null)} confirmButtonText={'删除'}>
                模块会被删除。
                <br/>
                此操作不可撤销，是否确定删除？
            </DelMessage>
        }
        {
            currentInfo &&
            <SalesProductViewDetailDialog close={()=>setCurrentInfo(null)} {...{currentInfo, editAuth, refreshList, isManager}}/>
        }
        {
            showDialogInfo?.module === 'import' &&
            <ImportApiDialog close={()=>setShowDialogInfo(null)} template={`/field/download?flag=4`} abnormalParams={{whichPage: 4}}
                             defaultOptions={importOption} refresh={refreshList} importUrl={'/field/upload'} parameter={{flag: 4,isApproval:1}} title={'产品清单'}
                             abnormal={'/field/export'} exportFieldname={'salesProductinfoExcelVos'}/>
        }
    </div>
}

export default SalesProductViewMgt;
import React, { useCallback } from 'react'
import { Dialog } from 'rootnet-ui'

export default function TurnParent(props) {
  const { close, submit } = props

  const confirm = useCallback(() => {
    submit({ pid: '' })
    close()
  }, [close, submit])

  return (
    <Dialog
      cancel={close}
      header={'变更为父级任务'}
      confirm={confirm}
      // className='export-csv-dialog export-api-dialog'
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0
      }}
    >
      确定变更为父级任务吗？
    </Dialog>
  )
}

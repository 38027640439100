import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Button, Messager } from 'rootnet-ui'
import RequirementDetailHeader from "./requirementDetailHeader/RequirementDetailHeader";
import RequirementDetailRightPanel from "./requirementDetailRightPanel/RequirementDetailRightPanel";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import { Badge, Popover, Tabs, Tooltip } from 'antd'
import RequirementInfo from "./requirementInfo/RequirementInfo";
import Icon from "../../../components/Icon";
import _ from "lodash";
import RequirementStage from "./requirementStage/RequirementStage";
import ChangeRecord from "../../common/ChangeRecord";
import RequirementUploadArea from "./requirementUploadArea/RequirementUploadArea";
import RequirementBusiness from "./requirementBusiness/RequirementBusiness";
import { useFuncCode, useRolePermissions } from "../../common/commonMethod";
import { strParams } from "../../../utils/publicFun";
import { gridOptsFor } from './components/method';
import TableDetails from './components/TableDetails';
import CustomerCommunicationRecord from './CustomerCommunicationRecord';
import './RequirementDetailContent.scss'
import gd from '../../../base/global';
import { dateFormat, toDate } from 'rootnet-core/dateFormat';
import SyncToJiraDialog from "./syncToJira/SyncToJiraDialog";
import { TextIconBtn } from "../../common/TextIconBtn";
import RequirementAddDialog from "../requirementAddDialog/RequirementAddDialog";
import PopoverComment from "../../testMgt/defect/controls/PopoverComment";
import TrackingAddDialog from "../../trackingManagementMgt/components/trackingAddDialog/TrackingAddDialog";
import { isNil } from "rootnet-core/format";
import CustomerServiceInfoAddDialog
    from "../../customerServiceInfo/customerServiceInfoAddDialog/CustomerServiceInfoAddDialog";
import RequirementDetailDialog from "./RequirementDetailDialog";
import CustomerServiceInfoDetailDialog
    from "../../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog";
import TrackingDetailDialog from "../../trackingManagementMgt/components/trackingDetailDialog";
import ChildrenReqViewTab from "./childrenReqViewTab/ChildrenReqViewTab";
import PublicDefect from '../../common/publicPage/publicDefect'
import PublicUseCase from '../../common/publicPage/publicUseCase';
import TaskTableDetails from './components/taskTableDetails';
import JoinPlanVersion from './joinPlanVersion';
import CodeCommitList from "../../common/codeCommitList/CodeCommitList";
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions';

const { TabPane } = Tabs;
const SERVICE_URL = '/customerServiceInfoNew/getCustomerServiceInfo'
const TASK_URL = '/customerServiceInfoNew/getUserprojectlistinfo'
const VERSION_PLAN_UNDER_APPROVAL_URL = '/ReleaseStoryLog/getReleaseStoryLogList?type=PLANREQ'
const AFFILIATED_PROJECT_URL = '/projectAuditLog/getUserProject'  //所属项目下拉

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=storyType',
]

const FUNC_CODE = '1501'

function RequirementDetailContent(props) {
    const { storyID: storyIdC = null, isCS, outerAllDetailAuth } = window.external
    const { refreshViewList = () => { }, currentInfo = {}, close = () => { }, switchCurrentItem, showChildList, currentIndex,
        setCurrentInfo, delAuth } = props
    const { mode: initMode = 'detail' } = currentInfo
    const [mode, setMode] = useState(initMode)
    const { doFetch: getDetail } = useGet()
    const [formData, setFormData] = useState()
    const [editFormData, setEditFormData] = useState()
    const { data: allUserRes } = useGet('/common/userinfo')
    const { doFetch: submitForm } = usePost()
    const descRef = useRef()
    const solutionRef = useRef()
    const customerRecordsRef = useRef() // 客户沟通记录
    const [infoError, setInfoError] = useState()
    const [activeKey, setActiveKey] = useState('info')
    const [count, updateDynamic] = useReducer((x) => x + 1, 0)
    const [countService, updateService] = useReducer((x) => x + 1, 0)
    const [countTask, updateTask] = useReducer((x) => x + 1, 0)
    const [countDoc, updateDoc] = useReducer((x) => x + 1, 10000)
    const [countStage, updateStage] = useReducer((x) => x + 1, 20000)
    const [countChild, updateChild] = useReducer((x) => x + 1, 30000)
    const [id, setId] = useState()
    const [switchDisabled, setSwitchDisabled] = useState(false)
    const { data: customerOptionsRes, doFetch: getProjectId } = useGet()
    const [showSyncJira, setShowSyncJira] = useState(false)
    const [showAddChild, setShowAddChild] = useState(false)
    const { data: canAddChildReq, doFetch: canAddChildReqDoFetch } = useGet()
    const [hasChildrenReq, setHasChildrenReq] = useState(false)
    // const {doFetch: updateJiraDoFetch} = usePost()
    const [commentText, setCommentText] = useState()
    const [commentPopoverVisible, setCommentPopoverVisible] = useState(false)
    const [showCreateServerInfo, setShowCreateServerInfo] = useState(false)
    const [showAddTrack, setShowAddTrack] = useState(false)
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [isClose, setIsClose] = useState(true)
    const [fileCount, setFileCount] = useState(0)
    const { data: allVersionRes } = useGet('/develop/release/list?versionType=0')
    const [showJoinPlanVersion, setShowJoinPlanVersion] = useState(false)
    const { doFetch: getPlanUnderApprobal, data: planUnderApprobal } = useGet()
    const [isDisable, setIsDisable] = useState(false)
    const [isAll] = useRolePermissions(['ALL'])
    const { data: affiliatedProjectRes, doFetch: getaffiliatedProject } = usePost()
    const { doFetch: getTracerIdList } = useGet()
    const [codeCommitBizList, setCodeCommitBizList] = useState()
    const [showCodeCommitTab] = useFuncCode(['150105'])
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [storyTypeOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    useEffect(() => {
        if (_.isNil(id)) return
        getTracerIdList('/bug/tracer/getStoryTracer?id=' + id).then(res => {
            let bizList = []
            _.map(res, (v, k) => {
                bizList = _.concat([], k, v)
            })
            setCodeCommitBizList(bizList)
        })
    }, [getTracerIdList, id])

    const refreshPlanVersion = useCallback(() => {
        if (_.isNil(id)) return
        getPlanUnderApprobal(`${VERSION_PLAN_UNDER_APPROVAL_URL}&${strParams({ storyId: id, auditType: '1', auditFlag: 'U' })}`)
    }, [getPlanUnderApprobal, id])

    const leftAllProjectOptions = useMemo(() => {
        if (_.isEmpty(affiliatedProjectRes)) return []

        return _.sortBy(_.map(affiliatedProjectRes, o => {
            return {
                label: o.projectName,
                text: o.projectName,
                value: o.projectId,
                examine: o.examine,
                status: o.status,
            }
        }), 'text')
    }, [affiliatedProjectRes])

    useEffect(() => {
        refreshPlanVersion()
    }, [refreshPlanVersion])

    useEffect(() => {
        getaffiliatedProject(AFFILIATED_PROJECT_URL, ['4', '6'])
    }, [getaffiliatedProject])

    const { doFetch: getVerifyResult } = useGet()

    const refreshAddChild = useCallback(() => {
        if (_.isNil(id)) return
        canAddChildReqDoFetch('/story/getContainStory?id=' + id)
    }, [canAddChildReqDoFetch, id])

    useEffect(() => {
        refreshAddChild()
    }, [refreshAddChild])

    useEffect(() => {
        if (_.get(editFormData, 'pid') && activeKey === 'childrenReq') {
            setActiveKey('info')
        }
    }, [editFormData, activeKey])

    useEffect(() => {
        const { id } = currentInfo || {}
        if (!_.isNil(id)) {
            setId(id)
        }
    }, [currentInfo])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const handShowAddTrack = useCallback(() => {
        getVerifyResult(`/bug/tracer/add/check?${strParams({ storyId: _.get(formData, 'id') })}`)
            .then(() => {
                setShowAddTrack(true)
            }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [getVerifyResult, formData])

    const refreshDetail = useCallback((id, storyIdC, open) => {
        if (_.isNil(id) && _.isNil(storyIdC)) return
        setSwitchDisabled(true)
        getDetail('/story/getDetail?' + strParams({ id, storyId: storyIdC })).then(res => {
            let handleFormData = { ...res }
            if (handleFormData['description']) {
                // handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '<br/>')
                handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '')
            }
            handleFormData['_planReleaseList'] = _.map(_.get(res, 'planReleaseStoryList'), o => _.assign({}, o, { value: o.releaseid }))
            setFormData(handleFormData)
            setEditFormData(handleFormData)
            setSwitchDisabled(false)
            setId(res.id)
            open && setShowCreateServerInfo(true)
        }).catch(err => {
            setSwitchDisabled(false)
            Messager.show(err._message, { icon: 'error' })
        })
        updateDynamic()
        updateTask()
    }, [getDetail, updateDynamic])

    const refreshReleaseList = useCallback((flag = false) => {
        if (_.isNil(id) && _.isNil(storyIdC)) return
        getDetail('/story/getDetail?' + strParams({ id, storyId: storyIdC })).then(res => {
            const releaseList = _.get(res, 'releaseList')
            const planReleaseList = _.map(_.get(res, 'planReleaseStoryList'), o => _.assign({}, o, { value: o.releaseid }))
            // setFormData(x => ({...x,releaseList}))
            setEditFormData(old => {
                const clone = _.clone(old)
                clone['releaseList'] = releaseList
                clone['_flag'] = flag
                const oldPlanReleaseList = _.get(old, '_planReleaseList')
                clone['_planReleaseList'] = flag ? oldPlanReleaseList : planReleaseList
                clone['projectAuditLog'] = res.projectAuditLog
                return clone
            })
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [id, storyIdC, getDetail])

    useEffect(() => {
        if (_.isNil(id) && _.isNil(storyIdC)) return
        setSwitchDisabled(true)
        getDetail('/story/getDetail?' + strParams({ id, storyId: storyIdC })).then(res => {
            let handleFormData = { ...res }
            if (handleFormData['description']) {
                // handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '<br/>')
                handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '')
            }
            handleFormData['_planReleaseList'] = _.map(_.get(res, 'planReleaseStoryList'), o => _.assign({}, o, { value: o.releaseid }))
            setFormData(handleFormData)
            setEditFormData(handleFormData)
            setSwitchDisabled(false)
            setId(res.id)
            getProjectId('/story/getbugProjectName?id=' + _.get(res, 'custSystem'))
        }).catch(err => {
            setSwitchDisabled(false)
            Messager.show(err._message, { icon: 'error' })
        })
        updateDynamic()
    }, [id, storyIdC, getDetail, getProjectId])

    const switchMode = useCallback((resetDesc = false) => {
        if (resetDesc) {
            if (descRef.current) {
                // let initText = _.replace(_.get(formData, 'description'), /\n/g, '<br/>')
                let initText = _.replace(_.get(formData, 'description'), /\n/g, '')
                descRef.current.setContent(initText || '')
            }
            if (solutionRef.current) {
                // let initText = _.replace(_.get(formData, 'solution'), /\n/g, '<br/>')
                let initText = _.replace(_.get(formData, 'solution'), /\n/g, '')
                solutionRef.current.setContent(initText || '')
            }
        }
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [formData])

    const canSubmit = useMemo(() => {
        if (!_.get(editFormData, 'title')) {
            return false
        }
        if (!_.get(editFormData, 'currentUser') && !isClose) {
            return false
        }
        return !_.some(_.values(infoError), x => x)
    }, [infoError, editFormData, isClose])

    const needBizFlag = useMemo(() => {
        if (_.get(editFormData, 'type') !== 'CUS') return 'N'
        const reqSort = _.get(editFormData, 'reqSort')
        return _.includes(['SPC', 'NORMAL', 'FUNCTION'], reqSort) ? 'Y' : 'N'
    }, [editFormData])

    const checkRichTextRequired = useCallback(() => {
        return purifyRichText(descRef.current.getContent()) === ""
        function purifyRichText(richText = '') {
            const regex = /(<([^>]+)>)/ig
            return richText.replace(regex, "")
                .replace(/\s/g, "")
                .replace(/ /g, "")
                .replace(/&nbsp;/g, "")
        }
    }, [])

    const submit = useCallback((flag = true, filterArray = []) => {
        if (isDisable) return
        setIsDisable(true)
        const customerRecords = _.isNil(customerRecordsRef.current) ? _.get(formData, 'custcommunicationlog') : customerRecordsRef.current.getContent()
        if (JSON.stringify(editFormData) === JSON.stringify(formData)
            && descRef.current.getContent() === _.get(formData, 'description')
            && solutionRef.current.getContent() === _.get(formData, 'solution')
            && customerRecords === _.get(formData, 'custcommunicationlog')) {
            switchMode()
            return Messager.show('无变更')
        }
        if (!_.isEmpty(editFormData?._planReleaseList) && flag) {
            const valuses = formData.planReleaseList

            const findSealPlate = _.filter(editFormData?._planReleaseList, o => (!_.includes(valuses, o?.value) && o?.state === 'C'))
            if (!_.isEmpty(findSealPlate)) {
                setIsDisable(false)
                setShowJoinPlanVersion(findSealPlate)
                return
            }
        }
        if (checkRichTextRequired()) {
            return Messager.show("请填写描述")
        }
        if (descRef?.current.loading || solutionRef?.current.loading) {
            return Messager.show("图片上传中，请稍后保存")
        }
        if (_.size(removeHtmlTags(customerRecords)) > 30000) {
            return Messager.show("客户沟通记录填写内容已超过三万字符，请修改", { icon: 'error' })
        }
        const newProject = _.get(editFormData, 'leafProjectIds', [])
        const oldProject = _.get(formData, 'leafProjectIds', [])
        // const projectAuditLogs = _.get(editFormData, 'projectAuditLogs', [])
        // const filterProjectY = _.get(leftAllProjectOptions, o => o.examine === 'Y')
        const leafProjectIdAdd = _.filter(newProject, v => !_.includes(oldProject, v))
        const leafProjectIdDel = _.filter(oldProject, v => !_.includes(newProject, v))


        const postParams = _.assign(
            {
                ...editFormData,
                needBizFlag: needBizFlag,
                leafProjectIdAdd,
                leafProjectIdDel,
                // salesprojectid: _.get(editFormData,'salesprojectid')? _.get(editFormData,'salesprojectid') : '',
                // assessauditor: _.get(editFormData,'assessauditor')? _.get(editFormData,'assessauditor') : '',
                // assessflag: _.get(editFormData,'assessflag')? _.get(editFormData,'assessflag') : '',
                // impassessor: _.get(editFormData,'impassessor')? _.get(editFormData,'impassessor') : '',
                // devUser: _.get(editFormData,'devUser')? _.get(editFormData,'devUser') : '',
                // analystTestUser: _.get(editFormData,'analystTestUser')? _.get(editFormData,'analystTestUser') : '',
                feevalue: _.get(editFormData, 'feevalue') ? N2Float(_.get(editFormData, 'feevalue')) : '',
                workload: _.get(editFormData, 'workload') ? N2Float(_.get(editFormData, 'workload')) : '',
                impworkload: _.get(editFormData, 'impworkload') ? N2Float(_.get(editFormData, 'impworkload')) : '',
                releaseList: _.get(editFormData, 'releaseList') ? _.get(editFormData, 'releaseList') : [],
                description: _.replace(descRef.current.getContent(), /\n/g, ''),
                // description: descRef.current.getContent(),
                solution: _.replace(solutionRef.current.getContent(), /\n/g, ''),
                // solution: solutionRef.current.getContent(),
                custcommunicationlog: isNil(customerRecords) ? null : _.replace(customerRecords, /\n/g, ''),
                // custcommunicationlog: customerRecords,
                updateuserStoryBusiness: gd.User.operator_name,
                createuserStoryBusiness: gd.User.operator_name,
                toBusinessConfirmationDate: isNil(editFormData?.toBusinessConfirmationDate) ? '' : editFormData?.toBusinessConfirmationDate,
                pricingDate: isNil(editFormData?.pricingDate) ? '' : editFormData?.pricingDate,
                quotationDate: isNil(editFormData?.quotationDate) ? '' : editFormData?.quotationDate,
                stagnationReason: isNil(editFormData?.stagnationReason) ? '' : editFormData?.stagnationReason,
            },
            _.get(formData, 'businessauditstate') !== _.get(editFormData, 'businessauditstate') && {
                businessaudittime: dateFormat('YYYYMMDDHHMMSS', new Date()),
                businessauditor: gd.User.operator_name,
            },
        )

        const setNull = []
        _.forEach(postParams, (v, k) => {
            if (_.isNil(v) || v === '') {
                // setNull.push({
                //     field: k,
                //     value: v
                // })
                setNull.push(k)
            }
        })

        postParams['setNull'] = setNull
        submitForm('/story/updateDetailNew', _.omit(postParams, ['updateTime', 'releaseList', 'releaseStoryList', 'planReleaseList', 'planReleaseStoryList', 'currentUser'])).then(() => {
            const releaseParams = {
                storyid: id,
                type: 'REQ',
                releaseidList: _.get(editFormData, 'releaseList')
            }
            const values = _.compact(_.map(_.concat(filterArray, planUnderApprobal), 'releaseId'))
            const filterArr = _.filter(_.compact(_.get(editFormData, '_planReleaseList')), o => !_.includes(values, o.value))
            const planReleaseParams = {
                storyid: id,
                type: 'PLANREQ',
                releaseidList: _.map(filterArr, 'value')
            }

            const url = '/ReleaseStory/updateForReleaseStory'
            Promise.all([submitForm(url, releaseParams), submitForm(url, planReleaseParams)])
                .then(res => {
                    refreshPlanVersion()
                    setIsDisable(false)
                    if (_.get(formData, 'currentUser') === _.get(editFormData, 'currentUser')) {
                        afterRefresh(_.head(res))
                    } else {
                        const currentUserParams = {
                            businessId: _.get(editFormData, 'id'),
                            businessType: 'req',
                            funcCode: FUNC_CODE,
                            currentUser: _.get(editFormData, 'currentUser'),
                            title: _.get(editFormData, 'title')
                        }
                        submitForm('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                            afterRefresh(_.head(res))
                        }).catch(err => Messager.show(err._message, { icon: 'error' }))
                    }
                })
                .catch(err => {
                    setIsDisable(false)
                    Messager.show(err._message, { icon: 'error' })
                })
        }).catch(err => {
            setIsDisable(false)
            Messager.show(err._message, { icon: 'error' })
        })

        function afterRefresh(res) {
            Messager.show(res || '修改成功', { icon: 'success' })
            const isStateEqual = (_.get(formData, 'businessauditstate') !== _.get(editFormData, 'businessauditstate'))
            if (_.get(editFormData, 'reqSort') === 'FUNCTION' && isStateEqual && _.get(editFormData, 'businessauditstate') === 'Y') {
                refreshDetail(id, undefined, true)
            } else refreshDetail(id)
            switchMode()
            refreshAddChild()
            refreshViewList()
            updateStage()
        }
    }, [submitForm, editFormData, refreshDetail, switchMode, refreshViewList, needBizFlag, formData, id, checkRichTextRequired, refreshAddChild, planUnderApprobal, isDisable, refreshPlanVersion])

    const [editAuth, businessAuth, allDetailAuth] = useFuncCode(['1504', '1506', '1507'])

    const showAllDetailAuth = useMemo(() => {
        return outerAllDetailAuth || allDetailAuth
    }, [outerAllDetailAuth, allDetailAuth])

    const defaultDefectParams = useMemo(() => {
        return { 'test_defect.storyId': id, 'test_defect.issueId': null }
    }, [id])

    const defaultUseCaseParams = useMemo(() => {
        if (_.isNil(_.get(formData, 'storyId'))) return {}
        return { 'View_test_caseRelated_Req.relateId': _.get(formData, 'storyId'), 'View_test_caseRelated_Issue.relateId': null, 'test_defect_case_related.defectId': null }
    }, [formData])

    // const syncJiraDisabled = useMemo(()=>{
    //     if(_.isNil(editFormData)) return true
    //     if(_.get(editFormData,'pid')) return false
    //     return hasChildrenReq
    // },[editFormData, hasChildrenReq])

    const isParent = useMemo(() => {
        if (_.isNil(editFormData)) return true
        return _.isNil(_.get(editFormData, 'pid'))
    }, [editFormData])

    // const updateJira = useCallback(()=>{
    //     const params = {
    //         ids: [_.get(editFormData,'storyId')],
    //         flag: '2'
    //     }
    //     updateJiraDoFetch('/jira/story/push',params).then(()=>{
    //         Messager.show('同步成功', { icon: 'success' })
    //     }).catch(err => Messager.show(err._message, { icon: 'error' }))
    // },[editFormData, updateJiraDoFetch])

    useEffect(() => {
        const dialog = document.querySelector('.requirement-detail-content')
        dialog.addEventListener('click', () => setCommentPopoverVisible(false))
    }, [])

    const outerVersionOptions = useMemo(() => {
        const versionList = _.get(formData, 'releaseList')
        return _.map(versionList, item => {
            const findItem = _.find(allVersionRes, x => x.releaseId === item)
            return {
                text: findItem?.releaseName,
                value: findItem?.releaseId,
            }
        })
    }, [formData, allVersionRes])

    return <React.Fragment>
        <div className="requirement-detail-content flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title flex center-y">
                    需求信息
                    {
                        _.get(editFormData, 'pid') &&
                        <div className="parent-req-header flex center-y">
                            <div className="parent-req-tag">父需求</div>
                            <div className="parent-req-title" onClick={() => {
                                // window.open("/#/requirementMgt?initId="+_.get(editFormData, 'pid'), "_blank")
                                setShowDetailDialog({
                                    module: 'req',
                                    id: _.get(editFormData, 'pid')
                                })
                            }}>{_.get(editFormData, 'pidTitle')}</div>
                        </div>
                    }
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        (_.get(formData, 'reqSort') === 'FUNCTION' && _.get(formData, 'businessauditstate') === 'Y') &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'gongnengliebiao'} className={`header-edit-text-icon`} text={'功能申请'} onClick={() => setShowCreateServerInfo(true)} />
                        </span>
                    }
                    {
                        formData &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'-task'} className={`header-edit-text-icon`} text={'创建研发任务'} disabled={hasChildrenReq} onClick={handShowAddTrack} />
                        </span>
                    }
                    {/*<span style={{marginRight: 16}}>*/}
                    {/*    <TextIconBtn icon={'daoru'} className={`header-edit-text-icon`} text={'同步到jira'} disabled={syncJiraDisabled}  onClick={()=> {*/}
                    {/*        if(_.get(editFormData,'jiraId')){*/}
                    {/*            updateJira()*/}
                    {/*        }else{*/}
                    {/*            setShowSyncJira(true)*/}
                    {/*        }*/}
                    {/*    }}/>*/}
                    {/*</span>*/}
                    {
                        !_.get(editFormData, 'pid') && <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'tianjia'} className={`header-edit-text-icon`} text={'添加子需求'} disabled={!canAddChildReq} onClick={() => setShowAddChild(true)} />
                        </span>
                    }
                    {
                        editAuth &&
                        <span>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                                switchMode(true)
                                // setEditFormData(formData)
                                refreshDetail(id)
                            }} />
                        </span>
                    }
                    {
                        (!storyIdC) &&
                        <div className={'close-area flex center'} onClick={close}>
                            <Icon name={'quxiao'} className={'close-icon'} />
                        </div>
                    }
                </div>
            </div>
            <RequirementDetailHeader {...{ editFormData, setEditFormData, isDetail, allUserRes, formData, id, refreshViewList, refreshAddChild, refreshDetail, updateDynamic, setIsClose, storyTypeOptions }} />
            <div className="requirement-detail-main-panel flex">
                <div className="requirement-detail-left-panel">
                    <Tabs activeKey={activeKey} onChange={setActiveKey}>
                        <TabPane tab='描述' key={'info'} forceRender={true}>
                            <RequirementInfo initFormData={formData}
                                {...{ isDetail, editFormData, setEditFormData, descRef, infoError, setInfoError, customerOptionsRes, getProjectId, solutionRef }} />
                        </TabPane>
                        {
                            !_.isNil(editFormData) && !_.get(editFormData, 'pid') &&
                            <TabPane tab='子需求' key={'childrenReq'} forceRender={true}>
                                <div className={'requirement-table-details'} key={countChild}>
                                    {/*<ChildrenReqTab {...{id, allUserRes, setCurrentInfo, delAuth, refreshViewList, storyTypeOptions,formData,setHasChildrenReq,canAddChildReq, refreshDetail}}/>*/}
                                    <ChildrenReqViewTab {...{ id, setCurrentInfo, delAuth, refreshViewList, formData, setHasChildrenReq, canAddChildReq, refreshDetail }} />
                                </div>
                            </TabPane>
                        }
                        {
                            showAllDetailAuth &&
                            <TabPane tab='阶段' key={'stage'}>
                                <RequirementStage reqInfo={formData} bizType={'req'} {...{ id, allUserRes, updateDoc, updateDynamic, countStage, editAuth, outerVersionOptions }} />
                            </TabPane>
                        }
                        {
                            businessAuth && showAllDetailAuth && isParent &&
                            <TabPane tab='商务信息' key={'business'} forceRender={true}>
                                <RequirementBusiness {...{ isDetail, allUserRes, editFormData, setEditFormData }} />
                            </TabPane>
                        }
                        <TabPane tab='缺陷' key={'defect'}>
                            <PublicDefect defaultParams={defaultDefectParams} addDefaultParams={{
                                storyId: _.get(formData, 'id'),
                                interiorReqNo: _.get(formData, 'storyId'),
                                productId: _.get(formData, 'productId'),
                                releaseId: _.size(_.get(formData, 'releaseList', [])) === 1 ? _.head(_.get(formData, 'releaseList')) : null,
                                // fixVersion: _.size(_.get(formData, 'releaseList', [])) === 1 ? _.head(_.get(formData, 'releaseList')) : null,
                                fixProjectIdList: _.size(_.get(formData, 'releaseList', [])) === 1 ? [_.get(_.head(_.get(formData, 'releaseStoryList')), 'projectId')] : null,
                                projectId: _.size(_.get(formData, 'releaseList', [])) === 1 ? _.get(_.head(_.get(formData, 'releaseStoryList')), 'projectId') : null,
                            }} />
                        </TabPane>
                        <TabPane tab='用例' key={'useCase'}>
                            <PublicUseCase
                                defaultParams={defaultUseCaseParams}
                                addDefaultParams={{
                                    productId: _.get(formData, 'productId'),
                                    moduleId: _.get(formData, 'subSysId'),
                                }}
                                addDefaultReq={[{
                                    id: _.get(formData, 'id'),
                                }]}
                            />
                        </TabPane>
                        {
                            showAllDetailAuth &&
                            <TabPane tab="客户服务信息" key="service">
                                <div className='requirement-table-details' key={countService}>
                                    <TableDetails {...{ url: SERVICE_URL, option: gridOptsFor('service', setShowDetailDialog), id: _.get(formData, 'id') }} />
                                </div>
                            </TabPane>
                        }
                        {
                            showAllDetailAuth &&
                            <TabPane tab="任务清单" key='task'>
                                <div className='requirement-table-details' key={countTask}>
                                    <TaskTableDetails {...{ url: TASK_URL, option: gridOptsFor('task', setShowDetailDialog), id: _.get(formData, 'storyId'), setShowDetailDialog }} />
                                </div>
                            </TabPane>
                        }
                        {
                            showAllDetailAuth &&
                            <TabPane tab='客户沟通记录' key='record'>
                                <CustomerCommunicationRecord initFormData={formData} {...{ isDetail, editFormData, customerRecordsRef }} />
                            </TabPane>
                        }
                        {
                            showCodeCommitTab &&
                            <TabPane tab='代码提交记录' key={'codeCommit'}>
                                <CodeCommitList bizIdList={codeCommitBizList} />
                            </TabPane>
                        }
                        {
                            showAllDetailAuth &&
                            <TabPane tab={
                                <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                                    附件
                                </Badge>
                            } key={'doc'} forceRender={true}>
                                <div className="file-wrap" key={countDoc}>
                                    <RequirementUploadArea afterDelRefresh={updateStage} funcCode={'15'} isReq={true} {...{ id, mode, allUserRes, isCS, setFileCount }} />
                                </div>
                            </TabPane>
                        }
                        {
                            showAllDetailAuth &&
                            <TabPane tab="动态" key="dynamic">
                                <div className={'dynamic-wrap'} key={count}>
                                    <ChangeRecord name='需求' funcCode={FUNC_CODE} referenceId={id} associatedField={'title'} associatedFieldName={'阶段任务'} id={_.get(editFormData, 'storyId')} title={_.get(editFormData, 'title')} linkUrl={`/requirementMgt?initId=${id}`} />
                                </div>
                            </TabPane>
                        }
                    </Tabs>
                </div>
                <RequirementDetailRightPanel initFormData={formData}
                    {...{
                        isDetail, allUserRes, editFormData, setEditFormData, needBizFlag, showAllDetailAuth,
                        infoError, setInfoError, id, isParent, hasChildrenReq, refreshReleaseList,
                        planUnderApprobal, isAll, leftAllProjectOptions
                    }} />
            </div>
            <div className="mock-footer flex center-y">
                <div />
                {
                    _.isEmpty(showChildList) ? <div /> :
                        <div className="footer-switch flex center-y">
                            <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (switchDisabled) return
                                    if (!_.includes([-1, 0], currentIndex)) {
                                        switchCurrentItem('previous')
                                    }
                                }}>
                                <Icon name='xiangqian' />&nbsp;上一个
                            </div>
                            <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (switchDisabled) return
                                    if (!(currentIndex === showChildList?.length - 1)) {
                                        switchCurrentItem('next')
                                    }
                                }}>
                                下一个&nbsp;<Icon name='xianghou' />
                            </div>
                            <div className="list-num">
                                {currentIndex + 1}/{showChildList?.length}
                            </div>
                            <Popover content={<PopoverComment referenceId={id} funcCode={FUNC_CODE} {...{ allUserRes, commentText, setCommentText }}
                                title={`【${_.get(formData, 'storyId')}】${_.get(formData, 'title')}`} linkUrl={`/requirementMgt?initId=${id}`}
                                close={() => setCommentPopoverVisible(false)} />}
                                trigger="click" placement="topRight" open={commentPopoverVisible}>
                                <Tooltip title="评论信息">
                                    <div className={'comment-wrap flex center'} onClick={() => setCommentPopoverVisible(x => !x)}>
                                        <Icon name={'zidingyishezhi1'} className={'comment-icon'} />
                                    </div>
                                </Tooltip>
                            </Popover>
                        </div>
                }
                <div className="btn-group flex">
                    {
                        isDetail && !storyIdC &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        !isDetail &&
                        <Button normal onClick={() => {
                            switchMode(true)
                            setEditFormData(formData)
                        }}>取消</Button>
                    }
                    {
                        !isDetail &&
                        <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                    }
                </div>
            </div>
        </div>
        {
            showCreateServerInfo &&
            <CustomerServiceInfoAddDialog close={() => setShowCreateServerInfo(false)} refreshViewList={updateService}
                initForm={{
                    issueDesc: _.get(formData, 'title'),
                    issueKind: '07',
                    status: '01',
                    customer: _.get(formData, 'custSystem'),
                    linker: _.get(formData, 'custcontact'),
                    custContactInfo: _.get(formData, 'custcontactinfo'),
                    productType: _.get(formData, 'productId'),
                    subSysId: _.get(formData, 'subSysId'),
                    promiseDate: toDate.str14ToDate(_.get(formData, 'custexpecteddate')) > new Date() ? dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(formData, 'custexpecteddate'))) : null,
                    reqDetail: _.get(formData, 'description'),
                    interiorReqNo: _.get(formData, 'id'),
                }}
            />
        }
        {
            showSyncJira &&
            <SyncToJiraDialog close={() => setShowSyncJira(false)} id={_.get(editFormData, 'storyId')} flag={'req'}
                afterRefresh={
                    () => {
                        refreshDetail(id)
                        refreshViewList()
                        refreshAddChild()
                    }
                }
            />
        }
        {
            showAddChild &&
            <RequirementAddDialog afterRefresh={() => {
                refreshViewList()
                updateChild()
                refreshDetail(id)
            }
            } close={() => setShowAddChild(false)}
                currentInfo={{ pid: id, parentTitle: _.get(formData, 'title') }} {...{ storyTypeOptions }} />

        }
        {
            showJoinPlanVersion &&
            <JoinPlanVersion
                id={id}
                type={'PLANREQ'}
                close={() => setShowJoinPlanVersion(false)}
                afterRefresh={(flag) => {
                    refreshPlanVersion()
                    refreshReleaseList(flag)
                }}
                submit={submit}
                showJoinPlanVersion={showJoinPlanVersion}
            />
        }
        {
            showAddTrack && <TrackingAddDialog close={() => setShowAddTrack(false)}
                afterRefresh={() => updateTask()}
                initForm={{
                    bugType: 'N',
                    tracerPri: _.get(formData, 'priority'),
                    productId: _.get(formData, 'productId'),
                    subSysId: _.get(formData, 'subSysId'),
                    bugSource: _.get(formData, 'type') === 'CUS' ? "S1" : 'S0',
                    issueway: _.get(convertIssueWay, _.get(formData, 'releaseType')),
                    projectId: _.get(formData, 'custSystem'),
                    securityName: _.get(formData, 'custSystem') ? [_.get(formData, 'custSystem')] : null,
                    bugDesc: _.get(formData, 'description'),
                    fixDesc: _.get(formData, 'solution'),
                    storyId: _.get(formData, 'storyId'),
                    id: _.get(formData, 'id'),
                    tracerTitle: _.get(formData, 'title'),
                }}
            />
        }
        {
            _.get(showDetailDialog, 'module') === 'req' &&
            <RequirementDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'customerService' &&
            <CustomerServiceInfoDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'tracker' &&
            <TrackingDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
    </React.Fragment>
}

const convertIssueWay = {
    '0': '0',
    '1': '2',
    '2': '4',
}

function N2Float(valueParams) {
    if (_.isNil(valueParams)) return ''
    let value = Math.round(parseFloat(valueParams) * 100) / 100;
    let s = value.toString().split(".");
    if (s.length === 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

export default RequirementDetailContent;

function removeHtmlTags(html) {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
}
import React from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { OperationList } from "../../common/OperationList"
import convertOptions from '../../common/ConvertOptions'
import { strParams } from '../../../utils/publicFun'

export default function getColumn(props) {
  const { type } = props
  if (type === 'selectRecordPage') return pressColumn(props)
  if (type === 'selectGroupByProjectPage') return pressProject(props)
  if (type === 'selectEmployeeGroupingPage') return pressStaff(props)

}

// 按记录列示
function pressColumn(props) {
  const { setCurrentInfo, statusColor, submit, flag, workingProjectTypeOpt } = props

  return _.compact([
    { selection: true },
    {
      header: '员工', bind: 'userAccountName', sortable: true, align: 'left', width: 100, tooltip: true, convert: (v, o) => {
        return <div
          className='enter-detail-field'
          onClick={() => {
            const initDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'beginDate')))
            const initUser = _.get(o, 'userAccount')
            setCurrentInfo({ mode: 'workTimeReport', params: `?${strParams({ initDate, initUser, isDisplay: true })}`, user: v })
          }}
        >
          {v}
        </div>
      }
    },
    { header: '工号', bind: 'userCode', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '部门', bind: 'departmentName', sortable: true, align: 'left', width: 130, tooltip: true, convert: v => v },
    { header: '工时周期', bind: 'workCycle', sortable: true, align: 'left', width: 155, tooltip: true, convert: v => v },
    { header: '工时类型', bind: 'typeName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '项目/事务', bind: 'projectName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    {
      header: '项目工时类型', bind: 'workingProjectType', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => {
        return convertOptions(v, workingProjectTypeOpt)
      },
      csvConvert: v => convertOptions(v, workingProjectTypeOpt)
    },
    {
      header: '描述', bind: 'description', sortable: true, align: 'left', width: 160, convert: v => {
        return <Tooltip title={v} overlayStyle={{ whiteSpace: 'pre-wrap' }}>
          <div className='ellipsis-line'>{v}</div>
        </Tooltip>
      }, csvConvert: v => v
    },
    { header: '标准周工作日', bind: 'standardSunday', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '工时（周占比）', bind: 'percentage', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    {
      header: '工时（小时）', bind: 'useDay', sortable: true, align: 'left', width: 100, tooltip: true, convert: getUseDay, csvConvert: getUseDay
    },
    {
      header: '状态', bind: 'stateName', sortable: true, align: 'left', width: 100, tooltip: true, convert: (v, o) => {
        return <div style={{ color: statusColor[_.get(o, 'state')] }}>{v}</div>
      }, csvConvert: v => v
    },
    {
      header: '提交日期', bind: 'createTime', sortable: true, align: 'left', width: 135, tooltip: true, convert: v => {
        if (_.isNil(v)) return '-'
        return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
      },
      csvConvert: v => {
        if (_.isNil(v)) return '-'
        return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
      }
    },
    {
      header: '当前处理人', bind: 'workingHoursReviews', sortable: true, align: 'left', width: 130, tooltip: true, convert: v => {
        const reviewerName = _.compact(_.map(v, item => item.reviewerName))
        return _.join(reviewerName, ',')
      },
      csvConvert: v => {
        const reviewerName = _.compact(_.map(v, item => item.reviewerName))
        return _.join(reviewerName, '，')
      }
    },//reviewerName
    { header: '项目编号', bind: 'projectCode', sortable: true, align: 'left', width: 150, tooltip: true, convert: v => v },
    !flag && { header: '操作', align: 'center', width: flag ? 120 : 90, convert: createOperation },
  ])

  function getUseDay(v, o) {
    const { standardSunday, percentage } = o
    const useDay = _.replace(percentage, '%', '') * 0.01
    return (standardSunday * useDay * 8).toFixed(1)
  }

  function createOperation(v, o) {
    const options = _.compact([
      flag && { text: '接受', onClick: () => submit({ list: [o], params: { state: '1' } }) },
      flag && { text: '拒绝', onClick: () => setCurrentInfo({ mode: 'refuse', ids: [o.id], list: [o] }) },
      !flag && { text: '流转记录', onClick: () => setCurrentInfo({ mode: 'flowRecord', item: o }) },
    ])
    return <OperationList options={options} />
  }
}
// 阿布项目/事务分组
function pressProject(props) {
  const { setCurrentInfo } = props
  return [
    { selection: true },
    {
      header: '项目/事务', bind: 'projectName', sortable: true, align: 'left', width: 130, tooltip: true, convert: (v, o) => <div className='enter-detail-field' onClick={() => {
        const penetrationBeginDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'beginDate')))
        const penetrationEndDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'endDate')))
        setCurrentInfo({ mode: 'detail', params: o?.type === 'dev' ? { penetrationDev: o.projectId, penetrationBeginDate, penetrationEndDate } : { penetrationTransaction: o.projectId, penetrationBeginDate, penetrationEndDate } })
      }}>{v}</div>,
      csvConvert: v => v
    },
    { header: '工时周期', bind: 'workCycle', sortable: true, align: 'left', width: 155, tooltip: true, convert: v => v },
    { header: '工时类型', bind: 'typeName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '项目编号', bind: 'projectCode', sortable: true, align: 'left', width: 150, tooltip: true, convert: v => v },
    { header: '项目PO', bind: 'poName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '项目状态', bind: 'pstatusName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '立项时间', bind: 'pbeginDate', sortable: true, align: 'left', width: 145, tooltip: true, convert: v => v },
    { header: '预计上线时间', bind: 'pcompleteDate', sortable: true, align: 'left', width: 145, tooltip: true, convert: v => v },
    { header: '标准周工作日', bind: 'standardSunday', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '员工人数', bind: 'users', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '工时（人日）', bind: 'useDay', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
  ]
}
//按员工分组
function pressStaff(props) {
  const { setCurrentInfo } = props
  return [
    { selection: true },
    {
      header: '员工', bind: 'userAccountName', sortable: true, align: 'left', width: 100, tooltip: true, convert: (v, o) => <div className='enter-detail-field' onClick={() => {
        const penetrationBeginDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'beginDate')))
        const penetrationEndDate = dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(o, 'endDate')))
        setCurrentInfo({ mode: 'detail', params: { penetratingEmployee: o.userAccount, penetrationBeginDate, penetrationEndDate } })
      }}>{v}</div>,
      csvConvert: v => v
    },
    { header: '工号', bind: 'userCode', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '部门', bind: 'departmentName', sortable: true, align: 'left', width: 130, tooltip: true, convert: v => v },
    { header: '上级', bind: 'mangerName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '工时周期', bind: 'workCycle', sortable: true, align: 'left', width: 155, tooltip: true, convert: v => v },
    { header: '标准周工作日', bind: 'standardSunday', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '工时合计（周占比）', bind: 'percentage', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '工时（人日）', bind: 'useDay', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
  ]
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager, Loader, Switch} from 'rootnet-ui'
import {Form, FormInput, Grid, Select,Input} from "rootnet-edit";
import _ from "lodash";
import {useApi, useGet} from "../../../utils/hook";
import {Icon} from "../../../components";
import TextAreaWithTooltip from "../common/TextAreaWithTooltip";
import {Tooltip} from "antd";

const HFormInput = (props) =>  <FormInput horizontal labelWidth={120} componentWidth={280} {...props}/>

const OPTIONS_URLS = [
    '/fieldType/select',
    '/fieldDict/select',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
]

const WHICH_OPTIONS = {
    'add': '0',
    'edit': '1'
}

function EditDialog(props) {
    const {close, mode, initData, refresh,approvalMode,checkFlag} = props
    const isEdit = mode === 'edit'
    const [formData, setFormData] = useState(initData)
    const [error, setError] = useState()
    const [isReload, setIsReload] = useState(false)
    const [fieldDictNameDisable, setFieldDictNameDisable] = useState(true)
    const {data: optionRes} = useGet(OPTIONS_URLS)
    const {doFetch} = useApi()
    const [loading, setLoading] = useState(false)
    const {data: uniqListRes, doFetch: getUniqListRes} = useGet()
    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(()=>{
        getUniqListRes('/fieldCommon/getName?whichPage=1')
    },[getUniqListRes])

    const uniqList = useMemo(()=>{
        return _.map(uniqListRes, x => `${x.code}-${x.sonCode}`)
    },[uniqListRes])

    useEffect(()=>{
        setFieldDictNameDisable(!isReload)
    },[isReload])

    const reload = useCallback((value)=>{
        // setFormData(x => ({...x,fieldDictId: null,fieldDictName: null}))
        setIsReload( value)
    },[])

    const [fieldTypeOptions, fieldDictIdOptions, productOptions] = useMemo(()=>{
        if(_.isEmpty(optionRes)) return []
        const [d1,d2,d3,d4] = optionRes
        return [
            _.map(d1, x => ({value: x.fieldTypeCode, text: `${x.fieldTypeCode}-${x.fieldTypeName}`, tag: `${x.fieldTypeCode}-${x.fieldTypeName}`})),
            _.map(d2, x => ({value: x.fieldDictId, text: `${x.fieldDictId}-${x.fieldDictName}` || '-', tag: x.fieldDictId, name: x.fieldDictName})),
            _.map(_.groupBy(d3,product => product.productLine), (productLineItem, productLine) => ({
                text: _.get(_.find(d4, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
                children: _.map(productLineItem, x => ({value: x.productId, text: x.productName}))
            })),
        ]
    },[optionRes])

    useEffect(()=>{
        if(_.isNil(fieldDictIdOptions)) return
        const initFieldDictId = _.get(initData,'fieldDictId')
        if(_.isNil(initFieldDictId)) return
        setIsReload(_.isNil(_.find(fieldDictIdOptions, x => x.value === initFieldDictId)))
    },[initData,fieldDictIdOptions])

    const formChange = useCallback((data)=>{
        if(!isReload && !_.isNil(data.fieldDictId)){
            data.fieldDictName = _.find(fieldDictIdOptions, x => x.value === data.fieldDictId)?.name
        }
        setFormData(data)
    },[fieldDictIdOptions,isReload])

    const check = useCallback(()=>{
        if(checkFlag === '1'){
            if(!_.get(formData, 'memo')){
                Messager.show('请填写必填项', { icon: 'error' })
                return false
            }
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }else{
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }
    },[error, checkFlag, formData])

    const submit = useCallback(()=>{
        if(!check()) return
        const isPending = approvalMode === 'pending'
        const url = approvalMode === 'default' || mode === 'add' || _.get(formData,'auditFlag') === '1' ? '/fieldDict/save' : '/field/updateAudit'
        const postParams = {...formData,whichPage:'1',whichOption:WHICH_OPTIONS[mode],isAgree: isPending? '0': null,
            isApproval: checkFlag,displayOrder: _.toNumber(_.get(formData,'displayOrder'))}
        setLoading(true)
        setSubmitDisable(true)
        doFetch(url, 'post',postParams).then(()=>{
            setSubmitDisable(false)
            Messager.show(checkFlag==='1'?`数据已提交，待审核`:'提交成功，已入库', { icon: 'success' })
            refresh()
            close()
        }).catch(err => {
            if([1058, 1059, 1060].includes(err._code)){
                setError(x => ({x, fieldDictId: err._message}))
            }
            setSubmitDisable(false)
            Messager.show(err._message, { icon: err._message === '当前数据您已提交审批，若修改请撤销审批'?'info':'error' });
            setLoading(false)
        })
    },[check,doFetch,formData,close,refresh,approvalMode,mode,checkFlag])

    useEffect(()=>{
        const fieldDictId = _.get(formData,'fieldDictId') || ''
        const fieldDictSubCode = _.get(formData,'fieldDictSubCode') || ''
        const uniq  = `${fieldDictId}-${fieldDictSubCode}`
        if(!isEdit && uniq !== '-'){
            if(_.includes(uniqList, uniq)){
                setError(x=>_.assign({},x,{'fieldDictSubCode': '子项标识重复'}))
            }else if (!_.isNil(fieldDictSubCode) && fieldDictSubCode !== ''){
                setError(x=>_.assign({},x,{'fieldDictSubCode': null}))
            }
        }
    },[formData,uniqList,isEdit])

    const TipsDom = useCallback(()=>{
        return <div>
            <div>字段命名规则：</div>
            <div>1.小驼峰规则（小驼峰式命名法: 第一个单词以小写字母开始,第二个单词的首字母大写。例如:firstName、lastName）</div>
            <div>2.字段的单词都需要来源于标准词根</div>
            <div>3.字段名称规则为标准词根英文全称拼接或者英文简称拼接，不允许混用</div>
            <div>4.最多输入30个字符</div>
        </div>
    },[])

    return <Dialog header={isEdit?'修改':'新增'} className={'constant-defined-edit-dialog'} cancel={close} contentStyle={{padding: 20}} confirm={submit} confirmButtonDisabled={submitDisable} confirmButtonText={submitDisable?'提交中':'提交'}>
        <Form value={formData} onChange={formChange} error={error} onError={setError}>
            <Grid cols={1}>
                <div>
                    <HFormInput label='常量标识' search bind='fieldDictId' required componentWidth={isEdit?280:194} component={isReload ? Input : Select} options={fieldDictIdOptions} disabled={isEdit} maxLength={30}/>
                    <Tooltip title={<TipsDom/>} placement={'bottom'}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: '14px', color: '#5477FF', width: 14, height: 14, cursor: 'pointer', marginRight: 6 }}/>
                        </span>
                    </Tooltip>
                    <Switch checkedComponent={'新建'} uncheckedComponent={'选择'} checked={isReload} onChange={reload} style={{width: 56,display:isEdit?'none':''}}/>
                </div>
                <HFormInput label='常量名称' bind='fieldDictName' disabled={fieldDictNameDisable}/>
                <HFormInput label='根网数据类型' bind='fieldTypeCode' required component={Select} options={fieldTypeOptions} search/>
                <HFormInput label='使用产品' bind='productId' required component={Select} options={productOptions} tree search multiple bindInConvert={v => v && v.split(',')} bindOutConvert={v => v&&v.join(',')}/>
                <div style={{height: 16,display:isEdit?'none':''}}>
                    <div style={{marginLeft: 128,display: _.get(error,'fieldDictSubCode')==='子项标识重复'?'block':'none'}} className={'form-error-msg'}>
                        <Icon name='tixing' style={{marginRight: 5}}/>
                        子项标识重复
                    </div>
                </div>
                <HFormInput label='子项标识' bind='fieldDictSubCode' required disabled={isEdit}/>
                <HFormInput label='子项名称' bind='fieldDictSubName' required/>
                <HFormInput label='顺序' bind='displayOrder' required type={"number"} digit={2}/>
                <HFormInput label='子项说明' bind='fieldDictSubDesc' component={TextAreaWithTooltip} value={_.get(formData, 'fieldDictSubDesc')} className={'desc-textarea'} required={false}/>
                <HFormInput label='申请理由' bind='memo' component={TextAreaWithTooltip} value={_.get(formData, 'memo')} className={`desc-textarea ${checkFlag === '1'? 'desc-required' :''}`} required={checkFlag === '1'}/>
            </Grid>
        </Form>
        {loading && <Loader fill text={'提交中...'}/>}
    </Dialog>
}


function checkRequire(error){
    if(_.some(_.values(error),x=>x)){
        const errMsg = _.find(_.values(error), x=>!_.isNil(x))
        Messager.show(errMsg==='必填项！'?'请填写必填项':errMsg, { icon: 'error' })
        return false
    }
    return true
}


export default EditDialog;
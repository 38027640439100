import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import './WorkFlowDetail.scss'
import {FormInput, Select, Form, Input, Display} from "rootnet-edit";
import ArrowCrumbs from "../../common/ArrowCrumbs";
import {Card, Button, Messager, Loader} from 'rootnet-ui'
import {useGet, usePost} from "rootnet-biz/es/hooks";
import WorkFlowGraph from "../workFlowGraph/WorkFlowGraph";
import _ from 'lodash'
import convertOptions from "../../common/ConvertOptions";
import WorkflowEditablePanel from "./workflowEditablePanel/WorkflowEditablePanel";
import useGetFieldConvertCollection from "../../common/view/hooks/useGetFieldConvertCollection";
import workflowInitPosition from './workflowInitPosition'
import {isNil} from "../../appraise/components/method";

// const conditionGroup = [
//     {
//         conditionRelation: 'and',
//         conditionList: [
//             {
//                 isConditionGroup: false,
//                 fieldName: '创建人',
//                 relation: '不是',
//                 value: '纪文倩'
//             },
//             {
//                 isConditionGroup: false,
//                 fieldName: '标题',
//                 relation: '不包含',
//                 value: '需求'
//             },
//             {
//                 isConditionGroup: true,
//                 conditionRelation: 'or',
//                 conditionList: [
//                     {
//                         fieldName: '创建人',
//                         relation: '不是',
//                         value: '纪文倩'
//                     },
//                     {
//                         fieldName: '标题',
//                         relation: '不包含',
//                         value: '需求'
//                     },
//                 ]
//             }
//         ]
//     },
//     {
//         conditionRelation: 'or',
//         conditionList: [
//             {
//                 isConditionGroup: false,
//                 fieldName: '创建人',
//                 relation: '不是',
//                 value: '纪文倩'
//             },
//             {
//                 isConditionGroup: false,
//                 fieldName: '标题',
//                 relation: '不包含',
//                 value: '需求'
//             },
//         ]
//     }
// ]

const OPTIONS_URLS = [
    '/workflow/getWorkflowType',
]

function WorkFlowDetail(props) {
    const {currentInfo, setCurrentInfo, listOptions} = props
    const [editData, setEditData] = useState()
    const [currentId, setCurrentId] = useState(_.get(currentInfo,'id'))
    const [mode, setMode] = useState(_.get(currentInfo,'mode') || 'edit')
    const {data: workFlowGraphInfo, doFetch: getWorkFlowGraph} = useGet()
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const [graphData, setGraphData] = useState()
    const [forceRender, renderGraph] = useReducer((x) => x + 1, 0)
    const {doFetch: submitPost} = usePost()
    const {doFetch: getOnlyId} = useGet()
    const {data: fieldListRes, doFetch: getFieldList } = useGet()
    const [loading, setLoading] = useState(false)

    // useEffect(()=>{
    //     console.log('workFlowGraphInfo',workFlowGraphInfo)
    // },[workFlowGraphInfo])

    const [workflowTypeOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes
        return [
            _.map(d1, x => ({value: x.pageEngineId, text: x.pageName, pageCode: x.pageCode}))
        ]

    },[optionsRes])

    const [pageCode, funcCode] = useMemo(()=>{
        const wtype = _.get(editData,'wtype')
        if(_.isNil(wtype)) return []
        const findItem = _.find(workflowTypeOptions, x => x.value === wtype)
        return [
            _.get(findItem,'pageCode'),
            wtype
        ]
    },[editData,workflowTypeOptions])

    const fieldList = useMemo(()=>{
        const fields = _.get(fieldListRes,'formItems')
        return _.map(fields, x => ({
            ...x,
            tableField: x.tableName + '.' + x.fieldId
        }))
    },[fieldListRes])

    const convertCollection = useGetFieldConvertCollection(fieldList, setLoading)

    useEffect(()=>{
        if(_.isNil(pageCode)) return
        getFieldList('/SystemFormPage/getSystemFormPage?pageCode='+pageCode)
    },[getFieldList, pageCode])

    useEffect(()=>{
        if(_.get(currentInfo,'mode') !== 'add') return
        getOnlyId('/test_case/productGetOnlyId').then(res => setCurrentId(res)).catch(err => {
            Messager.show(err.Message, {icon: 'error'})
        })
    },[getOnlyId, currentInfo])

    const refreshDetail = useCallback(()=>{
        getWorkFlowGraph('/workflow/info?id='+currentId).then(res => {
            setEditData(res)
            setGraphData(JSON.parse(_.get(res,'workflowTemporaryShow.position') || '') || {})
            setTimeout(()=>{
                renderGraph()
            },500)
        }).catch(err => {
            Messager.show(err.Message, {icon: 'error'})
        })
    },[getWorkFlowGraph, currentId])

    useEffect(()=>{
        if(_.isNil(currentId)) return
        if(_.get(currentInfo,'mode') === 'add') return
        refreshDetail()
    },[currentInfo, refreshDetail, currentId])

    useEffect(()=>{
        if(_.get(currentInfo,'mode') === 'add'){
            setGraphData(workflowInitPosition)
            setTimeout(()=>{
                renderGraph()
            },500)
        }
    },[mode, currentInfo])

    const isDetail = useMemo(()=>{
        return mode === 'detail'
    },[mode])

    const arrowCrumbsData = useMemo(()=>{
        return [
            { text: '工作流首页' },
            {
                text: <FormInput search horizontal component={Select} options={listOptions}
                                 value={currentId}
                                 componentWidth={230}
                                 onChange={newId => {
                                     if(mode === 'add'){
                                         setMode('detail')
                                     }
                                     setCurrentId(newId)
                                     renderGraph()
                                 }}/>,
                active: true,
            }
        ]
    },[listOptions, currentId, renderGraph, mode])

    const submit = useCallback(()=>{
        let handleGraphCells = _.get(graphData,'cells')
        _.forEach(handleGraphCells, cell => {
            if(cell.shape === 'edge'){
                let labels = _.get(cell,'labels')
                cell['labels'] = _.filter(labels, x => {
                    return !isNil(_.get(x,'attrs.label.text'))
                })
            }
        })

        const allNode = _.filter(_.get(graphData,'cells'), x => {
            return x.shape === 'custom-circle' && _.get(x,'attrs.text.text') === '全部'
        })
        const allNodeIdList = _.map(allNode, 'id')
        const allToNodeEdge = _.filter(_.get(graphData,'cells'), x => {
            return x.shape === 'edge' && _.includes(allNodeIdList,_.get(x,'source.cell'))
        })
        const allToNodeEdgeIdList = _.map(allToNodeEdge, 'id')
        const submitParams = {
            ..._.pick(editData,['name','wtype','memo']),
            funcCode: _.get(editData,'wtype'),
            id: currentId,
            workflowTemporaryShow: {
                position: JSON.stringify({cells: handleGraphCells})
            },
            factorList: _.map(handleGraphCells, x => ({
                shape: x.shape === 'edge' ? 'edge' : 'node',
                id: x.id,
                name: x.shape === 'edge' ? _.get(x,'labels.0.attrs.label.text') : _.get(x,'attrs.text.text'),
                startFactor: x.shape === 'edge' ? _.get(x,'source.cell') : null,
                endFactor: x.shape === 'edge' ? _.get(x,'target.cell') : null,
                fieldConfig: _.includes(allToNodeEdgeIdList, x.id) && x.shape === 'edge' ? 'Y' : 'N',
            }))
        }
        submitPost('/workflow/workflowUpdate',submitParams).then(()=>{
            Messager.show('成功', {icon: 'success'})
            setMode('detail')
            refreshDetail()
        }).catch(err => Messager.show(err.Message, {icon: 'error'}))
    },[editData, graphData, submitPost, currentId, refreshDetail])

    const extra = useMemo(()=>{
        return <div className={'card-extra flex center-y'}>
            {
                isDetail &&
                <Button primary onClick={()=>setMode('edit')}>编辑</Button>
            }
            {
                !isDetail &&
                <div className={'flex center-y'}>
                    {/*<Icon name={'tixing'} className={'icon'}/>*/}
                    {/*<div>您正在编辑工作流，是否发布</div>*/}
                    <Button primary onClick={submit}>发布</Button>
                    <Button normal onClick={()=>{
                        if(mode === 'add'){
                            setCurrentInfo(null)
                        }else{
                            setEditData(workFlowGraphInfo)
                            setGraphData(JSON.parse(_.get(workFlowGraphInfo,'workflowTemporaryShow.position')))
                            setMode('detail')
                        }
                    }}>放弃</Button>
                </div>
            }
        </div>
    },[isDetail, submit, mode, setCurrentInfo, workFlowGraphInfo])

    return <div className={'work-flow-detail flex-y fill'}>
        {
            loading && <Loader fill/>
        }
        <div className="work-flow-detail-header">
            <ArrowCrumbs data={arrowCrumbsData} goBack={() => setCurrentInfo(null)} />
        </div>
        <Card title={'基本信息'} className={'base-info-card'} extra={extra}>
            <div className={'base-info-wrap'}>
                <Form value={editData} onChange={setEditData}>
                    <FormInput label={'名称'} bind={'name'} horizontal labelWidth={90} componentWidth={350} component={isDetail? Display: Input} required/>
                    <FormInput label={'应用'} bind={'wtype'} horizontal labelWidth={90} componentWidth={180} required
                               component={isDetail? Display:Select} options={workflowTypeOptions}
                               convert={v => convertOptions(v, workflowTypeOptions)}/>
                    <div/>
                    <FormInput label={'说明'} bind={'memo'} horizontal labelWidth={90} componentWidth={636} component={isDetail? Display: Input}/>
                </Form>
            </div>
        </Card>
        <Card title={'流程图'} className={'graph-card'}>
            {
                mode === 'detail' &&
                <WorkFlowGraph initData={_.get(workFlowGraphInfo,'workflowTemporaryShow.position')}/>
            }
            {
                mode !== 'detail' &&
                // <EditableGraph workflowId={currentId} {...{graphData, setGraphData, forceRender, setHandledGraphData, workflowTypeOptions, funcCode, fieldList}}/>
                <WorkflowEditablePanel workflowId={currentId} {...{graphData, setGraphData, forceRender, pageCode, fieldList, convertCollection, funcCode}}/>
            }
        </Card>
    </div>
}

export default WorkFlowDetail;
import _ from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Dialog, DataGrid, Messager, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../../../../../common/view/convertTableAlign'
import findConvert from '../../../../../../common/view/findConvert'
import useGetViewConfig from '../../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../../common/view/hooks/useRefreshList'
import { Box } from '../../../../../../common/commonComponent'
import ViewArea from '../../../../../../common/view/ViewArea'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import useGetDateOptions from '../../../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../../../common/view/hooks/useGetTreeList'
import { ScheduleManagementContext } from '../../../../../../common/Context'
import './index.scss'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
      }
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '084000'

export default function ExportDeliveryLog(props) {
  const { releaseId, close } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const appendObj = useMemo(() => {
    if (_.isNil(releaseId)) return {}
    return { factorViewSql: `story_stage_delivery_export.releaseId = '${releaseId}'` }
  }, [releaseId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    Messager.show('导出中...')
    downloadPostProgress('/UserSetting/exportExcel', postParams, '交付件导出日志')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [exportViewList, allowExport])
  return (
    <Dialog
      header='交付件导出日志'
      className='export-delivery-log'
      cancel={close}
      confirmButtonVisible={false}
    >
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'交付件导出日志'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </Dialog>
  )
}

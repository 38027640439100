import React, { useState, useCallback, useRef } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/lib/hooks'
import ProcessUserGroupList from './controls/processUserGroupList'
import { strParams } from '../../../utils/publicFun'
import ProcessUserGroupPersonnel from './controls/processUserGroupPersonnel'
import './index.scss'
import { Icon } from '../../../components'

const GET_USER_GROUP_PERSONNEL = '/uac/workflow/select/user'

export default function ProcessUserGroupMgt() {
  const [currentId, setCurrentId] = useState()
  const { data: userGroupPersonnelData, doFetch, loading, error } = useGet()

  const [canMove, setCanMove] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState(500);
  const prevXRef = useRef(null);
  const panelRef = useRef(null)

  const refreshuserGroupPersonnel = useCallback((id) => {
    if (!_.isNil(id)) doFetch(`${GET_USER_GROUP_PERSONNEL}?${strParams({ id })}`)
  }, [doFetch])

  return (
    <div className='process-user-group-mgt fill'>
      <div className='flex process-user-group-mgt-content'
        onMouseMove={handleMove} ref={panelRef}
        onMouseLeave={() => setCanMove(false)}
        onMouseUp={() => setCanMove(false)}
      >
        <ProcessUserGroupList {...{ currentId, setCurrentId, refreshuserGroupPersonnel, htmlWidth }} />
        <div className='process-user-group-mgt-trag' onMouseDown={() => setCanMove(true)}><Icon name='yidongshu' /></div>
        <ProcessUserGroupPersonnel {...{ data: userGroupPersonnelData, loading, error, currentId }} />
      </div>
    </div>
  )

  function handleMove(e) {
    if (!canMove) return;
    e.preventDefault();
    e.stopPropagation();
    const prevX = prevXRef.current;
    prevXRef.current = e.clientX
    if (prevX === null) return null;
    const offset = e.clientX - prevX;
    setHtmlWidth(x => {
      if (x + offset < 500) {
        prevXRef.current = prevX
        return 500
      } else if ((x + offset) > 820) {
        prevXRef.current = prevX
        return x
      } else {
        return x + offset
      }
    });
  }

}

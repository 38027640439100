import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Button, Dialog, Messager} from 'rootnet-ui'
import {DatePicker, Form, FormInput, Select} from "rootnet-edit";
import {Icon} from "../../../../components";
import _ from "lodash";
import {Cache} from "../../../../base/cache";
import {useApi, useGet} from "../../../../utils/hook";
import {isNil} from 'rootnet-core/format'

const CACHE_KEY = 'createView'
const HFormInput = (props) => <FormInput horizontal componentWidth={180} labelWidth={90} {...props}/>

function MemberInfoCard(props) {
    const {userInfoRes,convertOptions,type,history,createParams, setCreateParams, initListRes, scene, setIsChangedForm} = props
    const [isExpand, setIsExpand] = useState(true)
    const [memberBind, setMemberBind] = useState(null)
    const [selectedList, setSelectedList] = useState([])
    const {data: statusRes} = useGet('/common/globalconst?globalConst=ProjectStatus')
    const {data: menuRes} = useGet('/common/uacFuncctrl?moduleId=BS')
    const {doFetch} = useApi()
    const {data: viewNameList} = useGet('/view/getDistinctViewName')
    const [error, setError] = useState(null)

    useEffect(()=>{
        const viewName = _.get(createParams, 'viewName')
        const initViewName = _.get(_.get(initListRes,'0'), 'viewName')
        const filterViewNameList = _.filter(viewNameList, x => x !== initViewName)
        if(isNil(viewName)) return setError(x=>_.assign({},x,{'viewName': '必填项'}))
        if(_.includes(filterViewNameList, viewName)) return setError(x=>_.assign({},x,{'viewName': '名称已存在'}))
        setError(x=>_.assign({},x,{'viewName': null}))
    },[createParams,initListRes,viewNameList])

    const isSaveBtnDisable = useMemo(()=>{
        return !_.every(_.values(error),_.isNil)
    },[error])

    const statusOptions = useMemo(()=>{
        return _.map(statusRes,x => ({value: x.interiorId, text: x.displayName}))
    },[statusRes])

    const markNoChildren = useCallback((list)=>{
        if(list.length === 0 ) {
            return true
        }
        _.forEach(list, item => {
            if(item.type === 'user') return _.assign(item, {_noChildren: false})
            if(item.type === 'department') _.assign(item, {_noChildren: markNoChildren(item.children)})
            if(_.every(item.children, x => x._noChildren)) _.assign(item, {_noChildren: true, children: []})
        })
        return false
    },[])

    const delNoChildren = useCallback((item)=>{
        if(_.every(item.children, x => x._noChildren)) _.assign(item, {children: []})
        const filterList = _.filter(item.children, x => {
            return !x._noChildren
        })
        _.assign(item, {children: filterList})
        _.forEach(item.children, x => delNoChildren(x))
    },[])

    const getDepartmentChildren = useCallback((allUserList,allDepartmentList,department)=>{
        const userList = _.filter(allUserList, x => x.department === department)
        const userOptions = _.map(userList, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`,type: 'user'}))
        const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
        const childDepartmentOptions = _.map(childDepartmentList, x => ({
            text: x.departMentName,
            value: x.departMentId,
            type: 'department',
            children: getDepartmentChildren(allUserList,allDepartmentList,x.departMentId),
        }))
        return [...childDepartmentOptions,...userOptions]
    },[])

    const [userAccountTree,userAccountOptions] = useMemo(()=>{
        const [d1,d2] = userInfoRes || []
        const userAccountOptions = _.map(d1, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))
        const activeDepartmentList = _.filter(d2, x => x.activeFlag === 'Y')
        let options = getDepartmentChildren(d1,activeDepartmentList,'')
        markNoChildren(options)
        _.forEach(options, x => delNoChildren(x))
        options = _.filter(options, x => !x._noChildren)
        return [options,userAccountOptions]
    },[userInfoRes,getDepartmentChildren,markNoChildren,delNoChildren])

    return <div className='member-info-card'>
        <Card title={scene === 'add'? '创建视图':'编辑视图'} className='right-card' style={{marginTop: 0,marginLeft: 0,sortable:true,width: isExpand?360:102}}>
            <div className="right-card-wrap flex">
                <div/>
                {
                    isExpand &&
                    <div className="form-wrap flex-y center-x">
                        <Form value={createParams} onChange={v => {
                            setIsChangedForm('isChanged');
                            setCreateParams(v)
                        }} error={error} onError={setError}>
                            <HFormInput bind="viewName" label="名称" required/>
                            <HFormInput bind="viewStatus" label="状态" component={Select} required options={statusOptions}/>
                            <HFormInput bind="beginDate" label="开始日期" component={DatePicker} clear/>
                            <HFormInput bind="endDate" label="结束日期" component={DatePicker} clear/>
                            <MemberFormItem bind='adminUser' label='可编辑人员'/>
                            <MemberFormItem bind='focusUser' label='关注人员'/>
                            <Button primary className='form-save-btn' onClick={save} disable={isSaveBtnDisable}>保存</Button>
                        </Form>
                    </div>
                }
                <div className="fold-area flex center">
                    <Icon name='shuangjiantou-xiangyou' className='fold-icon'
                          onClick={()=>setIsExpand(x => !x)} style={{transform: isExpand ? '' : 'rotate(180deg)'}}/>
                </div>
            </div>
        </Card>
        {
            memberBind &&
            <Dialog header={memberBind==='adminUser'?'可编辑人员':'关注人员'} contentClassName='flex-y center-x' cancel={closeDialog} confirm={confirmDialog}>
                <FormInput component={Select} options={userAccountTree} placeholder='请选择人员' tree
                           componentWidth={280} search clear multiple value={selectedList} onChange={checkSelectMemberLength}/>
                <div className='selected-member-list'>
                    <div className="label">所选人员</div>
                    <div className="list">
                        {
                            _.map(selectedList, userAccount => {
                                const userName = _.get(_.find(userAccountOptions, x => x.value === userAccount),'text')
                                return <div className='selected-item flex' key={userAccount}>
                                    <div className="username">{userName}</div>
                                    <Icon name='shanchu' className='del-icon' onClick={()=>delSelected(userAccount)}/>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Dialog>
        }
    </div>

    function checkSelectMemberLength(list){
        const strList = JSON.stringify(list)
        if(strList.length > 1000){
            Messager.show("当前所选择人员已超过上线，请重新选择",{icon: 'error'})
        }else{
            setSelectedList(list)
        }
    }

    function submitList(oldList, newList, keyName){
        const oldKeyList = _.map(oldList, x => x[keyName]) || []
        const newKeyList = _.map(newList, x => x[keyName]) || []
        const addList = _.filter(newList, x => !_.includes(oldKeyList, x[keyName]))
        const delList = _.filter(oldList, x => !_.includes(newKeyList, x[keyName]))
        const handleAddList = _.map(addList, x => ({...x,actionType: 'Add'}))
        const handleDelList = _.map(delList, x => ({...x,actionType: 'Del'}))
        return [...handleAddList, ...handleDelList]
    }

    function save(){
        let {projectList = [], taskList = [], issueList = []} = Cache.get(CACHE_KEY) || {}
        projectList = _.filter(projectList, x => x.leafProjectFlag === '1')
        const [,oldProjectList,oldTaskList,oldIssueList] = initListRes || []
        const submitProjectList = submitList(oldProjectList,projectList,'projectId')
        const submitTaskList = submitList(oldTaskList,taskList,'projectDetailId')
        const submitIssueList = submitList(oldIssueList,issueList,'issueId')
        const pathname = props.history.location.pathname
        const funcCode = _.get(_.find(menuRes, x => x.url === `/${pathname.split('/')[1]}`),'funcCode')
        projectList = _.map(submitProjectList, x => ({
            ...x,
            detailType: 'Prj',
            detailId: 'ALL',
            funcCode: funcCode
        }))
        taskList = _.map(submitTaskList, x => ({
            ...x,
            detailType: 'Prj',
            sourceId: x.projectId,
            detailId: x.projectDetailId,
            funcCode: funcCode
        }))
        issueList = _.map(submitIssueList, x => ({
            ...x,
            detailType: 'Issue',
            sourceId: x.issueId,
            detailId: 'ALL',
            funcCode: funcCode
        }))
        const postParams = _.assign({},createParams,
            {viewType: type,funcCode,projectList,
                taskList: type ==='Prj'?[]:taskList,
                issueList: type ==='Prj'?[]:issueList,
            })
        doFetch('/view/addView','post',postParams).then(()=>{
            Messager.show('保存视图成功',{icon:'success'})
            Cache.set(CACHE_KEY,{})
            history.push('/projectView')
        }).catch(err => {
            Messager.show(err._message,{icon:'error'})
        })
    }

    function confirmDialog(){
        setCreateParams(x => {
            x[memberBind] = selectedList
            return x
        })
        setMemberBind(null)
        setSelectedList([])
    }

    function closeDialog(){
        setMemberBind(null)
        setSelectedList([])
    }

    function delSelected(userAccount){
        const filterList = _.filter(selectedList, item => item.split('--')[0] !== userAccount)
        setSelectedList(filterList)
    }

    function MemberFormItem(props){
        const {bind, label} = props
        const showText = createParams[bind] && createParams[bind].length === 1 ?
            convertOptions(createParams[bind][0],userAccountOptions) :`已选择了${createParams[bind].length}项`
        return <div className='member-form-item flex'>
            <div className="label">{label}：</div>
            <div className="member-form" onClick={openDialog}>
                {showText}
            </div>
        </div>

        function openDialog(){
            setSelectedList(createParams[bind])
            setMemberBind(bind)
        }
    }
}

export default MemberInfoCard;
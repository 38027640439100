import React, {useCallback, useMemo, useState} from 'react';
import _ from 'lodash'
import {uniqKeyFor} from "../../../project_share/utils/utils";
import './ViewPop.scss'
import {Divider} from 'antd'
import {TextIconBtn} from "../TextIconBtn";
import ViewUpdateDialog from "./ViewUpdateDialog";
import Icon from "../../../components/Icon";
import DelMessage from "../../../components/DelMessage";
import {useFuncCode} from "../commonMethod";
import ViewSortDialog from "./viewSortDialog/ViewSortDialog";
import ShareViewUser from './shareViewUser';

function ViewPop(props) {
    const {viewList,currentViewId, setCurrentViewId,funcCode,closeViewPop,allColumns,refreshViewList,refresh,
        outerSystemViewEditAuth, resetParams=()=>{}, refreshParams=()=>{},onOuterViewItemClick} = props
    const [updatePopInfo, setUpdatePopInfo] = useState()
    const [delId, setDelId] = useState()
    const [showViewSort, setShowViewSort] = useState(false)
    const [shareInfo, setShareInfo] = useState()

    const [systemViewList, customViewList] = useMemo(()=>{
        if(_.isEmpty(viewList)) return []
        return [
            _.filter(viewList, x => x.userAccount === 'system'),
            _.filter(viewList, x => x.userAccount !== 'system'),
        ]
    },[viewList])

    const onViewItemClick = useCallback((viewId)=>{
        setCurrentViewId(viewId)
        refreshParams()
        closeViewPop()
    },[setCurrentViewId,closeViewPop,refreshParams])

    const afterDelRefresh = useCallback(()=>{
        if(delId === currentViewId){
            const omitList = _.filter(viewList, x => x.id !== delId)
            let currentView = _.find(omitList, x => x.lastViewedFlag === "Y")
            if(_.isNil(currentView)){
                currentView = _.find(omitList, x => x.userAccount === 'system')
            }
            if(_.isNil(currentView)){
                currentView = _.head(omitList)
            }
            const lastViewedId = _.get(currentView,'id')
            setCurrentViewId(lastViewedId)
        }else{
            refreshViewList()
        }
        closeViewPop()
    },[delId,currentViewId,refreshViewList,viewList,setCurrentViewId, closeViewPop])

    const [innerSystemViewEditAuth] = useFuncCode(['2201'])

    const systemViewEditAuth = useMemo(()=>outerSystemViewEditAuth || innerSystemViewEditAuth, [outerSystemViewEditAuth, innerSystemViewEditAuth])

    return <div className='view-pop'>
        <div className="view-group">
            <div className={'view-title'}>个人视图</div>
            {
                _.map(customViewList, viewItem => (<div className={`view-item flex center-y ${viewItem.id === currentViewId ? 'active': ''}`}
                                                        key={uniqKeyFor()}>
                    <div className={'view-name flex'} onClick={()=> {
                        onViewItemClick(viewItem.id)
                        if(onOuterViewItemClick){
                            onOuterViewItemClick()
                        }
                    }}>{viewItem.subType}</div>
                    <Icon className={'view-item-edit-icon'} name={'bianji2'} onClick={()=>setUpdatePopInfo({mode: 'edit', initViewInfo: viewItem})}/>
                    <Icon className={'view-item-edit-icon'} name={'fuzhi'} onClick={()=>setUpdatePopInfo({mode: 'copy', initViewInfo: viewItem})}/>
                    <Icon className={'view-item-edit-icon'} name={'fenxiang'} onClick={()=>setShareInfo({mode: 'share', id: viewItem.id, menuCode: viewItem.menuCode,})}/>
                    <Icon className={'view-item-del-icon'} name={'shanchu2'} onClick={()=>setDelId(viewItem.id)}/>
                </div>))
            }
        </div>
        {
            !_.isEmpty(systemViewList) &&
            <Divider/>
        }
        {
            !_.isEmpty(systemViewList) &&
            <div className="view-group">
                <div className={'view-title'}>系统视图</div>
                {
                    _.map(systemViewList, viewItem => (<div className={`view-item flex center-y ${viewItem.id === currentViewId ? 'active': ''}`}
                                                            key={uniqKeyFor()}>
                        <div className={'view-name flex'} onClick={()=>onViewItemClick(viewItem.id)}>{viewItem.subType}</div>
                        {
                            systemViewEditAuth &&
                            <Icon className={'view-item-edit-icon'} name={'bianji2'} onClick={()=>setUpdatePopInfo({mode: 'edit', initViewInfo: viewItem})}/>
                        }
                        <Icon className={'view-item-edit-icon'} name={'fuzhi'} onClick={()=>setUpdatePopInfo({mode: 'copy', initViewInfo: viewItem})}/>
                        {
                            systemViewEditAuth &&
                            <Icon className={'view-item-del-icon'} name={'shanchu2'} onClick={()=>setDelId(viewItem.id)}/>
                        }
                    </div>))
                }
            </div>
        }
        <Divider/>
        <div className="view-item flex center-y" style={{paddingLeft: 6}} onClick={()=>setUpdatePopInfo({mode: 'add'})}>
            <TextIconBtn icon='zengjia' text='新建视图'/>
        </div>
        <div className="view-item flex center-y" style={{paddingLeft: 6}} onClick={()=>setShowViewSort(true)}>
            <TextIconBtn icon='todo_list' text='视图排序'/>
        </div>
        {
            !_.isNil(shareInfo) &&
            <ShareViewUser 
                id={_.get(shareInfo, 'id')}
                menuCode={_.get(shareInfo, 'menuCode')} 
                close={() => setShareInfo(null)} 
            />
        }
        {
            !_.isEmpty(updatePopInfo) &&
            <ViewUpdateDialog {...{updatePopInfo, setUpdatePopInfo,funcCode,closeViewPop,setCurrentViewId,allColumns,refresh,systemViewEditAuth,currentViewId, resetParams}}/>
        }
        {
            !_.isNil(delId) &&
            <DelMessage close={()=>setDelId(null)} refresh={afterDelRefresh} method={'post'} url={'/UserSetting/maintain'} params={{id: delId, action: 'del'}}>
                确定删除该视图？
            </DelMessage>
        }
        {
            showViewSort &&
            <ViewSortDialog viewList={_.sortBy(viewList,'sort')} close={()=>setShowViewSort(false)} {...{funcCode,refreshViewList,closeViewPop}}/>
        }
    </div>
}

export default ViewPop;
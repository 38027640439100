import React, { useState, useMemo, useCallback, useContext, useRef, useEffect } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { Box } from '../../../../common/commonComponent'
import ViewArea from '../../../../common/view/ViewArea'
import { ScheduleManagementContext } from '../../../../common/Context'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import RiskFollowUpDetail from './riskFollowUpDetail'
import { CustomizeFormInput } from '../../../../common/customizeOptions/CustomizeOptions'
import './index.scss'
import { Tooltip } from 'antd'
import { Icon } from '../../../../../components'

const EDIT_URL = '/riskFollow/update'

const PRIMARY_KEY = 'id'
const ENTER_DETAIL_FIELD = 'RiskFollow.riskTitle'
const EDITABLE_FIELD = [
  'RiskFollow.isOccurred',
  'RiskFollow.isHomePage',
  'RiskFollow.probability',
  'RiskFollow.riskStatus',
  'RiskFollow.riskRating',
]

const REPLACE_POST_PARAMS = {
  isOccurred: 'isOccurred',
  isHomePage: 'isHomePage',
  probability: 'probability',
  riskStatus: 'riskStatus',
  riskRating: 'riskRating',
}

const SPECIAL_FIELD_CONVERT = {
  'RiskFollow.probability': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'RiskFollow.riskStatus': (value, showValue, color) => {
    return <div style={{
      color,
      borderRadius: 4,
      padding: '0 8px',
      border: `1px solid ${color}`,
    }}>{showValue}</div>
  },
  'RiskFollow.riskRating': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
}

function IsOccurredHeader() {
  return <div>
    是否已发生
    <Tooltip title='如已发生，请创建问题跟踪单'>
      <span>
        <Icon name='bangzhu' style={{ color: '#5477ff' }} />
      </span>
    </Tooltip>
  </div>
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, updateShowList, isPo, isPMM } = props

  const { editId = null, editField = null } = editInfo || {}

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: `${fieldItem.tableName}.${fieldItem.fieldId}` === 'RiskFollow.isOccurred' ? <IsOccurredHeader /> : fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[tableField])) {
      const getColor = _.get(_.find(convertCollection[tableField], o => o.value === v), 'color')
      showValue = SPECIAL_FIELD_CONVERT[tableField](v, showValue, getColor)
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
      </div>
    }
    if (!_.includes(EDITABLE_FIELD, tableField)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (o?.id === editId && editField === tableField) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType}
        convertCollection={convertCollection}
        defaultValue={handleValue}
        clear={fieldItem.requiredFlag === 'N'}
        allowClear={fieldItem.requiredFlag === 'N'}
        defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`}
        componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => currentTableValueRef.current = handleValue}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (isPo || isPMM) {
      return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
        {showValue || '-'}
      </div>
    }
    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === ENTER_DETAIL_FIELD), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '3636'

export default function DevProjectRiskFollowUp(props) {
  const { projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions, allUserRes, isShow } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [factor, setFactor] = useState()
  const [currentInfo, setCurrentInfo] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()

  // 表格编辑
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()

  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    if (_.isNil(projectId)) return
    return ({ 'RiskFollow.projectId': projectId, })
  }, [projectId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    let postParams = {
      // defectId: primaryId,
      id: editInfo['editId'],
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    editTable(EDIT_URL, [postParams]).then(() => {
      afterRefresh()
    }).catch((err) => {
      Messager.show(err._message, { icon: 'error' });
    })
    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable])

  const { options, dataGridList } = useGetTreeList({ fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, updateShowList, isPo, isPMM }, })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '风险跟进')
  }, [fieldList, currentViewId, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      {(isShow && (isPo || isPMM)) && <TextIconBtn icon={'tianjia'} text={'新增'} onClick={() => setCurrentInfo({ mode: 'add' })} />}
    </div>
  }, [isPo, isPMM, exportViewList, isShow])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  return (
    <div className={'dev-project-risk-follow-up fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={'风险跟进'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['add', 'detail'], _.get(currentInfo, 'mode')) &&
        <RiskFollowUpDetail
          close={() => setCurrentInfo(null)}
          refreshViewList={refreshList}
          {...{ currentInfo, projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions, allUserRes }}
        />
      }
    </div>
  )
}

import React, {useCallback, useMemo, useState} from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import {Menu, Dropdown, Button, Switch} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import {DataGrid, Pagination, Messager} from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertTableAlign from '../../common/view/convertTableAlign'
import findConvert from '../../common/view/findConvert'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import ViewArea from '../../common/view/ViewArea'
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import { Icon } from '../../../components'
import DelMessage from '../../../components/DelMessage'
import ContentTemplateDetailDialog from './controls/contentTemplateDetailDialog'
import { strParams } from '../../../utils/publicFun'
import './index.scss'
import TransferMgtLog from './controls/transferMgtLog'
import { useFuncCode } from '../../common/commonMethod'
import AssigningAuthorization from './controls/assigningAuthorization'

const ENTER_DETAIL_FIELD = 'demoTxt.demoName'

const DELETE_URL = '/demoTxt/delete'
const CATEGORY_URL = '/common/globalconst?globalConst=DemoTxtCategory'

const BTN_JURISDICTION_FUNC_CODE = ['881001', '881002', '881003']

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, changeStatus } = props
  const customColumns = []

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === ENTER_DETAIL_FIELD) {
      const categoryColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'demoTxt.category'),'columnId')
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentInfo({ mode: 'detail', id: o.id })
        }}>{showValue}</div>
        {
          <Icon name={'bianji2'} className="enter-detail-field-delete-icon"
            onClick={() => setCurrentInfo({ mode: 'edit', id: o.id })} />
        }
        {
          <Icon name={'shanchu2'} className="enter-detail-field-delete-icon"
            onClick={() => setCurrentInfo({ mode: 'delete', id: o.id })} />
        }
        {
          _.get(o,categoryColumnId) === '0' && <Icon style={{ fontSize: 18 }} name={'weituoshouquan'} className="enter-detail-field-delete-icon"
            onClick={() => setCurrentInfo({ mode: 'authorization', id: o.id })} />
        }
      </div>
    }
    if(tableField === 'demoTxt.status'){
      return <Switch checked={v === 'Y'} checkedChildren={'启用'} unCheckedChildren={'禁用'}
                     onClick={newStatus => changeStatus(o.id, newStatus ? 'Y': 'N')}/>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }}>
      {showValue || '-'}
    </div>
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '8810'

export default function ContentTemplateMgt() {
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [currentInfo, setCurrentInfo] = useState()
  const [checkedKeys, setCheckKeys] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: categoryRes } = useGet(CATEGORY_URL)
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [isCommonFuncCode, isPersonFuncCode, isCommonDefaultFuncCode] = useFuncCode(BTN_JURISDICTION_FUNC_CODE)
  const {doFetch: submitPost} = usePost()
  const [sortConfig, setSortConfig] = useState()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const changeStatus = useCallback((id, status)=>{
    submitPost('/demoTxt/editSingle',{id,status}).then(() => {
      Messager.show('修改成功', { icon: 'success' });
      refreshList()
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  },[submitPost, refreshList])

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions,
    getColumns, columnsAppendParams: { setCurrentInfo, changeStatus },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        const sortList = _.sortBy(data, x => x[sort.column])
        return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
      }
    },
  })

  const creatTemplate = useMemo(() => {
    if (_.isEmpty(categoryRes)) return <></>

    return <Menu
      onClick={(e) => setCurrentInfo({ mode: 'add', type: e.key })}
      items={
        _.map(categoryRes, o => ({
          label: o.displayName,
          key: o.interiorId,
          disabled: !((o.interiorId === '0' && isCommonFuncCode) || (o.interiorId === '1' && isPersonFuncCode) || (o.interiorId === '2' && isCommonDefaultFuncCode)),
        }))
      }
    />
  }, [categoryRes, isCommonFuncCode, isPersonFuncCode, isCommonDefaultFuncCode])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon={'return2fanhuiyou'} text='转移管理' onClick={() => {
        if (_.isEmpty(checkedKeys)) return Messager.show('请选择模板', { icon: 'error' })
        setCurrentInfo({ mode: 'transferMgt' })
      }} />
      <Dropdown
        overlay={creatTemplate}
        trigger='click'
        className={clsx('content-template-button', { 'button-disabled': false })}
        disabled={false}
      >
        <Button size='small'>创建模板 <DownOutlined /></Button>
      </Dropdown>
    </div>
  }, [creatTemplate, checkedKeys])

  return (
    <div className={'content-template-mgt fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'内容模板管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} selection={checkedKeys} onSelectionChange={setCheckKeys} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['add', 'edit', 'detail'], _.get(currentInfo, 'mode')) &&
        <ContentTemplateDetailDialog
          {...{ currentInfo }}
          initTemplateType={_.get(currentInfo, 'type')}
          refreshViewList={refreshList}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'delete' &&
        <DelMessage
          close={() => { setCurrentInfo(null); setCheckKeys([]) }}
          refresh={refreshList}
          method={'get'}
          url={`${DELETE_URL}?${strParams({ id: _.get(currentInfo, 'id') })}`}
        >
          确定删除当前内容？
        </DelMessage>
      }
      {
        _.get(currentInfo, 'mode') === 'transferMgt' &&
        <TransferMgtLog
          idList={_.map(checkedKeys, 'id')}
          close={() => { setCurrentInfo(null) }}
          {...{ setCheckKeys, refreshList }}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'authorization' &&
        <AssigningAuthorization
          isSubmit={true}
          id={_.get(currentInfo, 'id')}
          close={() => { setCurrentInfo(null) }}
          refresh={refreshList}
        />
      }
    </div>
  )
}

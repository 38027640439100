import React, { useCallback, useContext, useEffect, useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import { Messager, Button } from 'rootnet-ui'
import { Form, FormInput, Display, Select, DatePicker } from 'rootnet-edit'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { ValueContext } from '../../../common/Context'
import { useApi, useGet } from '../../../../utils/hook'
import { selectOption } from '../../../common/commonMethod'
import TinyEditor from "../../../common/richTextEditor/TinyEditor"
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'

const EDIT_URL = '/test/plan/update'

function getTime(begin, end) {
  if (_.isNil(begin) && _.isNil(end)) return null
  const { timestampToDate } = toDate
  const dateTime = (v) => dateFormat('YYYY-MM-DD', timestampToDate(v))
  return `${begin ? dateTime(begin) : ''} - ${end ? dateTime(end) : ''}`
}

const OPT_URL = [
  '/common/userinfo?indexValidFlag=1',
  '/common/department',
  '/common/globalconst?globalConst=testDefectOriginPhase',
  '/UserSetting/getUniversalInterfaces?code=ProjectID&codeName=ProjectName&tableName=UserProject&filter=status&filterParams=0,2,3,4,5,6', // 关联项目
  '/test_case/tracerversionplanecho', // 关联版本
  '/common/globalconst?globalConst=testplanStatus',
]

export default function EssentialInformation() {
  const { timestampToDate } = toDate
  const { basicInformationData: data, allRefresh } = useContext(ValueContext)
  const [params, setParams] = useState()
  const [activeBind, setActiveBind] = useState()
  const [bindName, setBindName] = useState()
  const { data: optData } = useGet(OPT_URL)
  const richEditorRef = useRef()
  const { doFetch } = useApi()

  useEffect(() => {
    if (data) {
      setParams(_.assign({}, data, {
        cycle: getTime(data?.beginDate, data?.endDate),
        cycles: { begin: timestampToDate(data?.beginDate), end: timestampToDate(data?.endDate) }
      }))
    } else setParams({})
  }, [data, timestampToDate])

  useEffect(() => {
    if (activeBind === 'describe') richEditorRef.current.setContent(_.isNull(data.describe) ? '' : data.describe)
  }, [activeBind, data])

  const markNoChildren = useCallback((list) => {
    if (list.length === 0) {
      return true
    }
    _.forEach(list, item => {
      if (item.type === 'user') return _.assign(item, { _noChildren: false })
      if (item.type === 'department') _.assign(item, { _noChildren: markNoChildren(item.children) })
      if (_.every(item.children, x => x._noChildren)) _.assign(item, { _noChildren: true, children: [] })
    })
    return false
  }, [])

  const delNoChildren = useCallback((item) => {
    if (_.every(item.children, x => x._noChildren)) _.assign(item, { children: [] })
    const filterList = _.filter(item.children, x => {
      return !x._noChildren
    })
    _.assign(item, { children: filterList })
    _.forEach(item.children, x => delNoChildren(x))
  }, [])

  const getDepartmentChildren = useCallback((allUserList, allDepartmentList, department) => {
    const userList = _.filter(allUserList, x => x.department === department)
    const userOptions = _.map(userList, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`, type: 'user' }))
    const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
    const childDepartmentOptions = _.map(childDepartmentList, x => ({
      text: x.departMentName,
      value: x.departMentId,
      type: 'department',
      _disabled: true,
      children: getDepartmentChildren(allUserList, allDepartmentList, x.departMentId),
    }))
    return [...childDepartmentOptions, ...userOptions]
  }, [])

  const [userAccountTree, stageOpt, projectOpt, editionOpt, statusOpt] = useMemo(() => {
    const [d1, d2, d3, d4, d5, d6] = optData || []
    const activeDepartmentList = _.filter(d2, x => x.activeFlag === 'Y')
    let userAccountTree = getDepartmentChildren(d1, activeDepartmentList, '')
    markNoChildren(userAccountTree)
    _.forEach(userAccountTree, x => delNoChildren(x))
    userAccountTree = _.filter(userAccountTree, x => !x._noChildren)
    const stageOpt = convertGlobalConstOptions(_.filter(d3, o => o.displayFlag !== 'N'))
    const projectOpt = selectOption(d4, ['displayName', 'interiorId'])
    const editionOpt = selectOption(d5, ['ReleaseName', 'ReleaseID'])
    const statusOpt = convertGlobalConstOptions(d6)
    return [userAccountTree, stageOpt, projectOpt, editionOpt, statusOpt]
  }, [delNoChildren, getDepartmentChildren, markNoChildren, optData])

  const showComponent = useCallback((formItem) => {
    if (activeBind !== formItem.bind) return Display
    return formItem.component
  }, [activeBind])

  const showBind = useCallback((formItem) => {
    if (activeBind === formItem.bind) return formItem.bindName
    return formItem.bind
  }, [activeBind])

  const onBlurForm = useCallback((bind) => {
    setActiveBind(null)
    setBindName(bind)
  }, [])

  const onDoubleClick = useCallback((bind) => {
    setActiveBind(bind)
  }, [])

  useEffect(() => {
    if (bindName && _.isNil(activeBind)) {
      const describe = richEditorRef.current.getContent() ? richEditorRef.current.getContent() : ''
      if (bindName === 'describe' && describe === data[bindName]) return setBindName(null)
      if (params[bindName] === data[bindName] && bindName !== 'describe') return setBindName(null)

      const parameter = _.pick(params, ['id', 'planName', 'owner', bindName])
      let timeParams = {}

      if (bindName === 'cycles') {
        if (params[bindName] === getTime(data['beginDate'], data['endDate'])) return setBindName(null)
        const { begin, end } = params[bindName] || {}
        const beginDate = Date.parse(new Date(begin))  //dateFormat('YYYYMMDDHHMMSS', begin)
        const endDate = Date.parse(new Date(end))  // dateFormat('YYYYMMDDHHMMSS', end)
        timeParams = { beginDate, endDate }
      }

      doFetch(EDIT_URL, 'post', _.assign(_.omit(parameter, ['cycles']), timeParams, bindName === 'describe' && { describe }))
        .then(() => {
          allRefresh()
          setBindName(null)
          Messager.show(`修改成功`, { icon: 'success' })
        })
        .catch((err) => {
          setBindName(null)
          Messager.show(err._message, { icon: 'error' })
        })
    }
  }, [activeBind, bindName, data, doFetch, params, allRefresh])

  const searchList = [
    [
      {
        tree: true,
        label: '负责人',
        bind: 'ownerName',
        bindName: 'owner',
        componentWidth: 200,
        component: Select,
        options: userAccountTree,
        search: true,
      },
      {
        label: '状态',
        bind: 'statusName',
        bindName: 'status',
        component: Select,
        options: statusOpt,
      },
    ],
    [
      {
        label: '测试阶段',
        bind: 'stageName',
        bindName: 'stage',
        componentWidth: 200,
        component: Select,
        options: stageOpt,
      },
      {
        label: '周期',
        bind: 'cycle',
        bindName: 'cycles',
        component: DatePicker,
        range: true,
      },
    ],
    {
      label: '关联版本',
      bind: 'relatedReleaseName',
      bindName: 'relatedReleaseId',
      component: Select,
      options: editionOpt,
      search: true,
    },
    {
      label: '关联项目',
      bind: 'projectName',
      bindName: 'projectId',
      component: Select,
      options: projectOpt,
      search: true,
    },
    {
      label: '描述',
      bind: 'describe',
      bindName: 'describe',
    }
  ]

  return (
    <div className='essentialInformation' style={{ paddingBottom: activeBind === 'describe' ? 38 : 12 }} >
      <p>基本信息</p>
      <div>
        <Form value={params} onChange={setParams}>
          {
            _.map(searchList, (item, i) => {

              if (_.isArray(item)) {
                return (
                  <div key={i} className='flex'>
                    {
                      _.map(item, o => {
                        return <div key={o.bind}
                          onMouseUp={() => onDoubleClick(o.bind)}
                          onBlur={() => onBlurForm(o.bindName)}
                          className={`edit-formInput`}
                        >
                          <FormInput
                            horizontal
                            labelWidth={70}
                            {...o}
                            bind={showBind(o)}
                            component={showComponent(o)} />
                        </div>
                      })
                    }
                  </div>
                )
              }

              if (item.bind === 'describe') {
                return <div key={item.bind}
                  className='edit-formInput'
                  onMouseUp={() => onDoubleClick(item.bind)}
                >
                  <div
                    style={{ height: 'auto' }}
                    className={`rich-text-area flex ${activeBind === 'describe' ? 'describe-show' : 'describe'}`}
                  >
                    <label>{item.label}</label>
                    <TinyEditor ref={richEditorRef} />
                    {
                      activeBind === 'describe' && <div className='describe-position'>
                        <Button primary onClick={() => onBlurForm(item.bindName)}>保存</Button>
                        <Button onClick={() => setActiveBind(null)}>取消</Button>
                      </div>
                    }
                    <div className='dangerously' dangerouslySetInnerHTML={{ __html: _.get(params, 'describe') }} />
                  </div>
                </div>
              }

              return (
                <div
                  key={item.bind}
                  className={`
                  edit-formInput 
                  ${(item.bind === 'describe' && 'describe' !== activeBind) ? 'edit-describe' : ''} 
                  ${(item.bind === 'describe' && 'describe' === activeBind) ? 'edit-active' : ''}`
                  }
                  onMouseUp={() => onDoubleClick(item.bind)}
                  onBlur={() => onBlurForm(item.bindName)}
                >
                  <FormInput
                    horizontal
                    labelWidth={70}
                    {...item}
                    bind={showBind(item)}
                    component={showComponent(item)} />
                </div>
              )
            })
          }
        </Form>
      </div>
    </div >
  )

}

import React, { useState, useMemo, useEffect, useRef, useCallback, useContext } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { usePost } from 'rootnet-biz/es/hooks'
import { DataGrid, Pagination, Tooltip } from 'rootnet-ui'
import convertTableAlign from '../../common/view/convertTableAlign'
import findConvert from '../../common/view/findConvert'
import ResourceDeptSearch from '../../resourcePoolMgt/resourceSchedule/resourceDeptSearch/ResourceDeptSearch'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import ViewArea from '../../common/view/ViewArea'
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import { useTreeExpend } from '../../commonV2/hooks'
import ImportApiDialog from '../../common/ImportApiDialog'
import { Icon } from '../../../components'
import { N2 } from 'rootnet-core/format'
import './index.scss'
import CostRateCancel from './controls/costRateCancel'
import CostRateEditLog from './controls/costRateEditLog'
import { ScheduleManagementContext } from '../../common/Context'

const IMPORT_URL = '/costrate/field/upload'
const TEMPLATE_URL = '/costrate/field/download?flag=0'
const EXPORT_ABNORMAL_DATA_URL = '/costrate/field/export'

const EDITABLE_FIELD = ['department']
const ENTER_DETAIL_FIELD = 'department'

const exportOption = {
  resizable: true,
  virtualized: false,
  fixedLeft: 1,
  fixedRight: 1,
  nilText: '-',
  emptyText: '-',
  columns: [
    { header: '部门', bind: 'departmentName', aligh: 'left', width: 280, tooltip: true, sortable: true, },
    { header: '职级', bind: 'techLevel', aligh: 'left', width: 120, sortable: true },
    { header: '单价(元/小时)', bind: 'price', align: 'right', width: 120, convert: N2, sortable: true },
    { header: '生效日期', bind: 'effectiveDate', width: 120, sortable: true },
  ]
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, handlePopId, setHandlePopId } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      const statusColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'demoTxt.status'), 'columnId')
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'edit', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
        <Popover
          trigger={'click'}
          placement={'bottom'}
          destroyTooltipOnHide={true}
          open={_.get(o, 'id') === handlePopId}
          onOpenChange={visible => {
            if (visible) setHandlePopId(_.get(o, 'id'))
            else setHandlePopId(null)
          }}
          content={
            <div className={'cost-rate-handle-popover flex-y'}>
              <div className='cost-rate-handle-item flex center-y' onClick={() => {
                if (statusColumnId === 'N') return
                setCurrentInfo({ mode: 'cancel', id: o.id })
                setHandlePopId(null)
              }}>
                <div className="handle-text">作废</div>
              </div>
            </div>
          }
        >
          <span>
            <Icon name={'gengduo'} className={'more-handle-icon'} />
          </span>
        </Popover>
      </div>
    }
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 200
  }
}

const FUNC_CODE = '27'

export default function CostRateList(props) {
  const { treeDeptOptions, techLevelOptions, statusOpt } = props //deptRes
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [searchDept, setSearchDept] = useState(null)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(200)
  const [isLoading, setIsLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState()
  const [checkedKeys, setCheckKeys] = useState()
  const [currentInfo, setCurrentInfo] = useState(null)
  const [factor, setFactor] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [handlePopId, setHandlePopId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const wrapperDom = useRef()
  const { expendIcon, expend, wrapperEmpyt } = useTreeExpend({ wrapperDom })

  useEffect(() => {
    if (searchDept) { }
    setCheckKeys([])
  }, [searchDept])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    //jobrecord_user.[department]
    return ({ 'costrate.department': searchDept, })
  }, [searchDept])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, handlePopId, setHandlePopId }, optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => sort.column, sort.direction)
      }
    },
  })

  const showList = useMemo(() => {
    let showList = dataGridList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, dataGridList])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '信息列表')
  }, [downloadPostProgress, currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn text='导入' icon='daoru' onClick={() => setCurrentInfo({ mode: 'import' })} />
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      <TextIconBtn text='新增' icon='tianjia' onClick={() => setCurrentInfo({ mode: 'add' })} />
    </div>
  }, [exportViewList])

  return (
    <div className='cost-rate-list fill flex-y'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize,
          replaceParams
        }} />

      <Box title={'信息列表'} className='flex-y' data={dataGridList} extra={extra} loading={boxLoading} error={error}>
      </Box>
      <div className={'two-columns-wrap flex'}>
        {
          expend ? <div className="left-panel flex-y">
            <div style={{ textAlign: 'center' }}><Tooltip title='收起'>{expendIcon}</Tooltip></div>
            <ResourceDeptSearch changeDept={setSearchDept} />
          </div> : <div className='flex flex-y expend-depart-n'>
            <div className='expendIconWrap'>
              <Tooltip title='展开'>{expendIcon}</Tooltip>
            </div>
            {wrapperEmpyt}
          </div>
        }

        <div className="right-panel flex-y">
          <DataGrid option={options} data={showList} loading={boxLoading} error={error} selection={checkedKeys} onSelectionChange={setCheckKeys} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </div>
      </div>
      {
        _.includes(['add', 'edit'], _.get(currentInfo, 'mode')) &&
        <CostRateEditLog
          refresh={refreshList}
          close={() => setCurrentInfo(false)}
          {...{ currentInfo, treeDeptOptions, techLevelOptions, statusOpt }}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'import' &&
        <ImportApiDialog
          title='costRate模板'
          abnormal={EXPORT_ABNORMAL_DATA_URL}
          importUrl={IMPORT_URL}
          template={TEMPLATE_URL}
          close={() => setCurrentInfo(false)}
          refresh={refreshList}
          parameter={{ flag: 0, isApproval: 0 }}
          abnormalParams={{ whichPage: 0 }}
          exportFieldname='costRateVos'
          defaultOptions={exportOption}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'cancel' &&
        <CostRateCancel
          close={() => setCurrentInfo(false)}
          refresh={refreshList}
          currentId={_.get(currentInfo, 'id')}
        />
      }
    </div>
  )
}

import React, { useState, useCallback, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { Tabs, Tree, Empty } from 'antd'
import { Button } from 'rootnet-ui'
import { Input } from 'rootnet-edit'
import { Icon } from '../../../../components'
import { buildUserDeptTree } from './buildDeptTree'
import { selectOption } from '../../../common/commonMethod'
import './departmentSelectPop.scss'

export default function DepartmentSelectPop(props) {
  const { onSelectConfirm, setVisible, multiple, allowClear, allDeptRes, defaultValue, isAdd } = props
  const [activeTab, setActiveTab] = useState('department')
  const [expandedKeys, setExpandedKeys] = useState(['dept-0'])
  const [selectDept, setSelectDept] = useState(multiple ? [] : null)
  const [searchText, setSearchText] = useState()

  const onExpand = useCallback((expandedKeys) => {
    setExpandedKeys(expandedKeys)
  }, [])

  useEffect(() => {
    if (_.isEmpty(allDeptRes)) return
    if (_.isNil(defaultValue)) return
    const allDeptOpt = selectOption(allDeptRes, ['departMentName', 'departMentId'])
    if (multiple) {
      setSelectDept(_.filter(allDeptOpt, x => _.includes(defaultValue, x.value)))
    } else {
      setSelectDept(_.find(allDeptOpt, x => defaultValue === x.value))
    }
  }, [defaultValue, allDeptRes, multiple])

  const onNodeClick = useCallback((key) => {
    setExpandedKeys(oldList => {
      if (_.includes(oldList, key)) {
        return _.filter(oldList, x => x !== key)
      } else {
        return [...oldList, key]
      }
    })
  }, [])

  const deptUserTreeData = useMemo(() => {
    if (_.isEmpty(allDeptRes)) return []
    return buildUserDeptTree({
      multiple,
      allDeptRes,
      onNodeClick,
      setSelectDept,
      searchText,
      isAdd,
    })
  }, [allDeptRes, multiple, onNodeClick, searchText, isAdd])

  const onConfirm = useCallback(() => {
    const departValue = _.map(selectDept, 'value')
    if (multiple) onSelectConfirm(departValue)
    else onSelectConfirm(_.get(selectDept, 'value'))
    setVisible(false)
  }, [selectDept, onSelectConfirm, setVisible, multiple])

  return <div className={'department-select-pop flex-y'}>
    <Input
      autoFocus
      className={'department-search'}
      placeholder={'请搜索部门'}
      value={searchText}
      onChange={setSearchText}
      suffix={<Icon name={'sousuo1'} className={'department-search-icon'} />}
    />
    <div className="department-select-main-panel flex">
      <div className="department-select-left">
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={[
            {
              label: `部门`,
              key: 'department',
              children: <div className="dept-user-warp flex">
                <div className="dept-user-warp flex">
                  <Tree
                    treeData={deptUserTreeData}
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                  />
                </div>
              </div>,
            }
          ]}
        />
      </div>
      <div className="department-select-right flex-y">
        <div className="department-select-right-header flex center-y">
          <div className="department-selected-num-wrap flex">
            已选部门
            {
              !_.isEmpty(selectDept) && multiple &&
              <div className="department-selected-num">
                {_.size(selectDept)}项
              </div>
            }
          </div>
          {
            allowClear && <div className="clear-all-selected" onClick={() => {
              setSelectDept(multiple ? [] : null)
            }}>
              清空所选
            </div>}
        </div>
        <div className="department-select-right-content flex-y">
          {
            _.isEmpty(selectDept) &&
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
          {
            !_.isEmpty(selectDept) &&
            <SelectTypeItem icon='zengjiazibumen' data={multiple ? selectDept : [selectDept]} clear={setSelectDept} multiple={multiple} />
          }
        </div>
      </div>
    </div>
    <div className="confirm-footer flex center-y">
      <div className="confirm-footer-left"></div>
      <div className="confirm-footer-right flex">
        <Button normal onClick={() => {
          setVisible(false)
        }}>取消</Button>
        <Button primary onClick={onConfirm}>确定</Button>
      </div>
    </div>
  </div>
}

function SelectTypeItem(props) {
  const { icon, data, clear, bind = 'value', label = 'text', multiple } = props
  const onClear = useCallback((val) => {
    if (multiple) clear(x => _.filter(x, item => item[bind] !== val))
    else clear(null)
  }, [clear, bind, multiple])

  return (
    <div className='select-type-list'>
      <div className='select-type-list-content'>
        {
          _.map(data, item => {
            return <div className='select-item flex center-y' key={item[bind]}>
              <div><Icon name={icon} style={{ marginRight: 8, fontSize: 16 }} />{item[label]}</div>
              <Icon className='clear-icon' name='baocuo' onClick={() => onClear(item[bind])} />
            </div>
          })
        }
      </div>
    </div>
  )
}


import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {usePost} from "rootnet-biz/lib/hooks";
import {Display, Form, FormInput, Grid, Input, Select} from "rootnet-edit";
import {errorTips, successTips} from "../../../../../../../utils/messagerTips";
import _ from 'lodash'
import {TextIconBtn} from "../../../../../../common/TextIconBtn";
import {Icon} from "../../../../../../../components";
import {Button, Dialog, MessageBox} from "rootnet-ui";
import './TraceComponentAddDialog.scss'
import convertOptions from "../../../../../../common/ConvertOptions";

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={250} {...props} />

const dialogTitle = {
    'add': '新增组件',
    'edit': '编辑组件',
    'detail': '组件详情',
}

function TraceComponentAddDialog(props) {
    const {close, initMode, id, refreshList} = props
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const [mode, setMode] = useState(initMode || 'detail')
    const {data: initData, doFetch: getDetail} = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)
    const {data: sourceCodePathRes, doFetch: getSourceCodePath} = usePost()
    const {data: allComponentList, doFetch: getAllComponentList} = usePost()
    const {doFetch: submitPost} = usePost()
    const [showTips, setShowTips] = useState(false)

    useEffect(()=>{
        getAllComponentList('/devops/uprods/app/program/list',{})
    },[getAllComponentList])

    const allSourceCodePathList = useMemo(()=>{
        return _.map(allComponentList,'sourceCodePath')
    },[allComponentList])

    useEffect(()=>{
        getSourceCodePath('/devops/uscs/code/project/list',{})
    },[getSourceCodePath])

    const sourceCodePathOptions = useMemo(()=>{
        if(_.isNil(sourceCodePathRes)) return []
        return _.map(sourceCodePathRes, x => ({
            value: x?.httpUrl,
            text: x?.httpUrl,
        }))
    },[sourceCodePathRes])

    const {doFetch: getEnumConfig} = usePost()
    const [programEndOptions, setProgramEndOptions] = useState()
    const [languageOptions, setLanguageOptions] = useState()
    useEffect(()=>{
        getEnumConfig('/devops/uds/enum/config/list',{enumIdList:['programEnd','programLanguage']}).then(res => {
            setProgramEndOptions(_.map(_.filter(res, x => x.enumId === 'programEnd'),x => ({
                value: _.toNumber(x.enumValues),
                text: x.enumShowValue,
            })))
            setLanguageOptions(_.map(_.filter(res, x => x.enumId === 'programLanguage'),x => ({
                value: _.toNumber(x.enumValues),
                text: x.enumShowValue,
            })))
        })
    },[getEnumConfig])

    const isDetail = useMemo(()=>{
        return mode === 'detail'
    },[mode])

    const refreshDetail = useCallback(()=>{
        if(_.isNil(id)) return
        getDetail('/devops/uprods/app/program/detail',{id})
    },[getDetail, id])

    useEffect(()=>{
        refreshDetail()
    },[refreshDetail])

    const initDetail = useCallback(()=>{
        setFormData(initData)
    },[initData])

    useEffect(()=>{
        initDetail()
    },[initDetail])

    const canSubmit = useMemo(()=>{
        return !_.some(_.values(formError),x=>x)
    },[formError])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const url = mode === 'add' ? '/devops/uprods/app/program/add' : '/devops/uprods/app/program/update'
        submitPost(url, formData).then(()=>{
            setSubmitLoading(false)
            refreshList()
            successTips(mode === 'add' ? '添加成功' : '修改成功')
            close()
        }).catch((err)=>{
            errorTips(err?._message)
            setSubmitLoading(false)
        })
    },[submitPost, mode, formData, close, refreshList, submitLoading])

    const preSubmit = useCallback(()=>{
        if(_.includes(allSourceCodePathList, formData?.sourceCodePath)){
            setShowTips(true)
        }else{
            submit()
        }
    },[formData, allSourceCodePathList, submit])

    return <Dialog className={'trace-component-add-dialog'} headerVisible={false} footerVisible={false}>
        <div className="mock-dialog-header flex center-y">
            <div className="mock-dialog-header-text">{dialogTitle[mode]}</div>
            <div className="mock-dialog-header-right flex center-y">
                {
                    mode !== 'add' &&
                    <TextIconBtn icon='bianji2' text={mode === 'detail' ? '进入编辑' : '退出编辑'} onClick={()=>{
                        setMode(old => old === 'edit' ? 'detail' : 'edit')
                        initDetail()
                    }}/>
                }
                <div className="mock-dialog-close-icon flex center" onClick={close}>
                    <Icon name={'close'}/>
                </div>
            </div>
        </div>
        <div className="mock-dialog-content flex-y">
            <Form value={formData} onChange={setFormData} error={formError} onError={setFormError}>
                <Grid cols={2}>
                    <HFormInput label={'组件名称'} bind={'name'} required component={isDetail?Display:Input}/>
                    <HFormInput label={'组件ID'} bind={'shortName'} required component={isDetail?Display:Input}/>
                    <HFormInput label={'所属端'} bind={'end'} required component={isDetail?Display:Select} options={programEndOptions}
                                convert={v => convertOptions(v, programEndOptions)}/>
                    <HFormInput label={'开发语言'} bind={'language'} required component={isDetail?Display:Select} options={languageOptions}
                                convert={v => convertOptions(v, languageOptions)}/>
                    <HFormInput label={'源码路径'} bind={'sourceCodePath'} required component={isDetail?Display:Select}
                                search colSpan={2} options={sourceCodePathOptions}/>
                    <HFormInput label={'打包路径'} bind={'packagePath'} required component={isDetail?Display:Input}/>
                    <HFormInput label={'应用名称'} bind={'appName'} required component={isDetail?Display:Input}/>
                </Grid>
            </Form>
        </div>
        <div className="mock-dialog-footer flex center-y">
            <div/>
            <div className={'mock-dialog-footer-right flex center-y'}>
                {
                    _.includes(['add','detail'], mode) &&
                    <Button normal onClick={close}>关闭</Button>
                }
                {
                    mode === 'edit' &&
                    <Button normal onClick={()=>{
                        initDetail()
                        setMode('detail')
                    }}>取消</Button>
                }
                {
                    !isDetail &&
                    <Button primary disabled={!canSubmit} onClick={preSubmit}>确定</Button>
                }
            </div>
        </div>
        {
            showTips &&
            <MessageBox
                className={'content-center-dialog'}
                header="提示"
                cancel={() => setShowTips(false)}
                confirm={()=>{
                    submit()
                    setShowTips(false)
                }}
            >
                所选GIT路径已存在组件，是否确认增加？
            </MessageBox>
        }
    </Dialog>
}

export default TraceComponentAddDialog;
import { useRef, useCallback } from 'react';

export function getTargetElement(
    target,
    defaultElement,
) {
    if (!target) {
        return defaultElement;
    }

    let targetElement;

    if (typeof target === 'function') {
        targetElement = target();
    } else if ('current' in target) {
        targetElement = target.current;
    } else {
        targetElement = target;
    }

    return targetElement;
}

export function usePersistFn(fn) {
    const ref = useRef(() => {
        throw new Error('Cannot call an event handler while rendering.');
    });

    ref.current = fn;

    const persist = useCallback(
        (...args) => {
            const refFn = ref.current;
            if (refFn) {
                return refFn(...args);
            }
            return null;
        },
        [ref],
    );

    if (typeof fn === 'function') {
        return persist;
    }
    return undefined;
}

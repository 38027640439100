import _ from 'lodash'

function getTreeList(tree) {
  _.forEach(tree, item => {
    item.key = item.id
    item.text = item.name
    item.value = item.id
    item.title = item.name
    item.children && getTreeList(item.children)
  })
  return tree
}

export function filterTreeList(data) {
  const treeList = _.filter(data, item => item.id !== null)
  return getTreeList(treeList)
}

export function localRefresh(defaultData, replaceData) {
  _.forEach(defaultData, (item, index) => {
    const findData = _.find(replaceData, o => o.id === item.id)
    if (findData) {
      defaultData[index] = _.assign({}, findData, _.pick(item, ['onlyId', 'caseName', 'level']))
    }
    if (item.testCaseList) {
      localRefresh(item.testCaseList, replaceData)
    }
  })
  return _.clone(defaultData)
}
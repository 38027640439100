import React, {useMemo, useState} from 'react';
import { parseDiff, Diff, Hunk} from 'react-diff-view';
import 'react-diff-view/style/index.css'
import './CodeDiff.scss'
import _ from 'lodash'
import clsx from "clsx";
import {Divider} from "antd";
import {isNil} from "rootnet-core/format";
import {uniqKeyFor} from "rootnet-biz/es/utils";
import {Icon} from "../../../components";

const EMPTY_FILE_NAME = '/dev/null'

function CodeDiff(props) {
    const {diffText = ''} = props
    const [currentFileIndex, setCurrentFileIndex] = useState(0)
    const [viewType, setViewType] = useState(sessionStorage.getItem('codeDiffViewType') || 'unified')

    const files = useMemo(()=>{
        if(isNil(diffText)) return null
        return parseDiff(diffText)
    },[diffText])

    const currentDiffContent = useMemo(()=>{
        return _.get(files,currentFileIndex) || ''
    },[files,currentFileIndex])

    return <div className={'code-diff-wrap fill flex'}>
        <div className="code-diff-path-wrap flex-y">
            {
                _.map(files, (item, index) => {
                    const isAdd = item?.oldPath === EMPTY_FILE_NAME
                    const isDel = item?.newPath === EMPTY_FILE_NAME
                    const isEdit = !isAdd && !isDel && !_.isEmpty(item?.hunks)
                    const isRename = !isAdd && !isDel && _.isEmpty(item?.hunks)
                    return <div className={clsx('code-diff-path-item flex center-y',{active: index === currentFileIndex})}
                                onClick={()=>setCurrentFileIndex(index)} key={uniqKeyFor()}>
                        {
                            isAdd && <Icon name={'tianjia1'} style={{color: '#35B37E'}}/>
                        }
                        {
                            isDel && <Icon name={'jianqu'} style={{color: '#FF5630'}}/>
                        }
                        {
                            isEdit && <Icon name={'bianji14'} style={{color: '#FFAB00', fontSize: 15, marginRight: 5}}/>
                        }
                        {
                            isRename && <Icon name={'ra'} style={{color: '#0049B0', fontSize: 15, marginRight: 5}}/>
                        }
                        {item?.newPath === EMPTY_FILE_NAME ? item?.oldPath : item?.newPath}
                    </div>
                })
            }
        </div>
        <div className="code-diff-content flex">
            {renderFile(currentDiffContent, viewType)}
        </div>
        <div className={'switch-vew-type flex center-y'}>
            <Icon name={'twocolumn'} className={clsx({'active': viewType === 'split'})} onClick={()=> {
                setViewType('split')
                sessionStorage.setItem('codeDiffViewType', 'split')
            }}/>
            <Icon name={'twocolumnb'} className={clsx({'active': viewType === 'unified'})} onClick={()=> {
                setViewType('unified')
                sessionStorage.setItem('codeDiffViewType', 'unified')
            }}/>
        </div>
    </div>
}

const renderFile = ({ oldRevision, newRevision, type, hunks }, viewType) => {
    return (
        <Diff key={oldRevision + '-' + newRevision} viewType={viewType} diffType={type} hunks={hunks}>
            {
                hunks => _.map(hunks, (hunk, index) => <>
                    {
                        index !== 0 &&
                        <tbody className={'hunks-divider'}>
                            <tr>
                                <td>
                                    <Divider/>
                                </td>
                                <td>
                                    <Divider/>
                                </td>
                                <td>
                                    <Divider/>
                                </td>
                                {
                                    viewType === 'split' &&
                                    <td>
                                        <Divider/>
                                    </td>
                                }
                            </tr>
                        </tbody>
                    }
                    <Hunk key={hunk.content} hunk={hunk} />
                </>)
            }
        </Diff>
    )
}

export default CodeDiff;
import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {Box} from "../../common/commonComponent";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import {Form, FormInput, Select, Input} from "rootnet-edit";
import _ from 'lodash'
import {Button, DataGrid, Messager} from 'rootnet-ui'
import './SalesProductList.scss'
import {TextIconBtn} from "../../common/TextIconBtn";
import SaleProductUpdateDialog from "./SaleProductUpdateDialog";
import {strParams} from "../../../utils/publicFun";
import {isNil, N2} from "rootnet-core/format";
import {handleTree, selectAll} from "../../common/TreeFunc";
import {Icon} from "../../../project_share/components";
import convertOptions from "../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import {usePost} from "rootnet-biz/es/hooks";
import DelMessage from "../../../components/DelMessage";
import ExportApiDialog from "../../common/ExportApiDialog";
import removeEmptyField from "../../common/removeEmptyField";
import CascadeSelect from "../../common/cascadeSelect/CascadeSelect";
import {toDate,dateFormat} from 'rootnet-core/dateFormat'
import { Switch } from 'antd';

const OPTIONS_URLS = [
    '/common/globalconst?globalConst=productline',
    '/common/globalconst?globalConst=salesSystem',
    '/common/globalconst?globalConst=salesProductType',
    '/common/globalconst?globalConst=salesSystemList',
]

function getColumns(props){
    const {handleTreeClick,expandList,salesSystemOptions, salesProductTypeOptions, setInfo, editInfo, changeInfo,
        setChangeInfo, submitChange, isManager,productLineOptions,allUserRes} = props
    return _.concat([
        {header: '系统', bind: 'system',width: 100, tooltip: true, convert:(v, o, i) =>  parentAddIcon(v, o, i, 0, 'system',salesSystemOptions)},
        {header: '类别', bind: 'productType',width: 100, tooltip: true, convert: (v, o, i) =>  parentAddIcon(v, o, i, 1,'productType',salesProductTypeOptions)},
        {header: '模块名称', bind: 'moduleName',width: 200, tooltip: true, convert: (v, o, i) =>  parentAddIcon(v, o, i, 2,'moduleName')},
        {header: '模块ID', bind: 'moduleId',width: 200, tooltip: true, convert: paddingConvert},
        {header: '产品线', bind: 'productLine',width: 80, tooltip: true,convert: v => <div style={{padding: "0 8px"}}>{convertOptions(v,productLineOptions)}</div>},
        {header: '单位', bind: 'unit',width: 100, convert:(v, o) =>   <div style={{padding: "0 8px"}}>{v}</div>},
        {header: '数量', bind: 'quantity',width: 100, convert:(v, o) =>  <div style={{padding: "0 8px"}}>{v}</div>},
    ],isManager?[
        {header: '标准单价', bind: 'priceStand',width: 100, align: 'right', convert:(v, o) => <div style={{padding: "0 8px"}}>{N2(v)=== '-' ? null : N2(v)}</div>}
    ]:[],[
        {header: '启用标识', bind:'openFlag',width: 85,stretch: false,align: 'center',convert: (v, o) => {
            if([2,3].includes(o._level)){
             return <div style={{padding: "0 8px"}}>
                 {<Switch checked={v === 'Y'} checkedChildren={'启用'} unCheckedChildren={'禁用'} disabled={true} onChange={value => submitChange({...o,openFlag: value?'Y':'N'})}/>}
             </div>
            }
            }},
        {header: '修改人', bind: 'updateUser',width: 80, tooltip: true, convert: v => <div style={{padding: "0 8px"}}>{convertOptions(v, allUserRes,'userName','userAccount')}</div>},
        {header: '修改时间', bind: 'updateTime',width: 140, tooltip: true, convert: v => <div style={{padding: "0 8px"}}>{dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.num14ToDate(v))}</div>},
    ])

    function paddingConvert(v){
        return <div style={{padding: '0 12px'}}>{v}</div>
    }

    // function editableTableItem({v,o,convert, bind, extraParams, align}){
    //     if(_.includes([0,1], o._level)) return null
    //     return <div className={'editable-table-item flex center-y'}>
    //         {
    //             o._id === _.get(editInfo, '_id') &&  _.get(editInfo, 'bind') === bind &&
    //             <Input value={changeInfo} autoFocus={true} onChange={setChangeInfo} onEnter={onChangeFormEnter} onBlur={onChangeFormEnter} {...extraParams}/>
    //         }
    //         {
    //             (o._id !== _.get(editInfo, '_id') || _.get(editInfo, 'bind') !== bind) &&
    //             <div className={'normal-table-item'} style={{textAlign: align}} onClick={()=>onEditIconClick(o, bind)}>{_.isNil(convert)? v : (convert(v) === '-' ? null : convert(v))}</div>
    //         }
    //     </div>
    // }

    function parentAddIcon(v, o, i, level, bind, options){
        const showValue = _.isNil(options) ? v : convertOptions(v, options)
        if(_.includes([2,3],o._level)){
            if(bind === 'moduleName'){
                return <div className={'table-item-wrap flex center-y'}>
                    {
                        o._level === 2 &&
                        <div className='arrow-drop-down-group flex center-y'>
                            <Icon name={_.get(o,'_expand')?'biaogeshouqi':"biaogezhankai"} className='arrow-drop-down' onClick={() => {handleTreeClick(expandList,o,i,'_parent','keyPath')}}/>
                            {
                                (o._id !== _.get(editInfo, '_id') || _.get(editInfo, 'bind') !== 'moduleName') &&
                                <div className={'flex center-y'}>
                                    <div className={'tree-text'} onClick={()=>setInfo({mode: 'detail', currentInfo: o})}>{v}</div>
                                    <div className={'handle-icon-group flex center-y'}>
                                        {/*{*/}
                                        {/*    editAuth &&*/}
                                        {/*    <Icon className={'edit-icon'} name={'bianji2'} onClick={()=>onEditIconClick(o, bind)}/>*/}
                                        {/*}*/}
                                        {/*{*/}
                                        {/*    delAuth &&*/}
                                        {/*    <Icon className={'remove-icon'} name={'shanchu2'} onClick={()=>setDelInfo(o)}/>*/}
                                        {/*}*/}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        o._id === _.get(editInfo, '_id') &&  _.get(editInfo, 'bind') === 'moduleName' &&
                        <Input value={changeInfo} onChange={setChangeInfo} autoFocus={true} onBlur={onChangeFormEnter} onEnter={onChangeFormEnter} style={{marginLeft: o._level === 2 ? 0 :60}}/>
                    }
                    {
                        (o._id !== _.get(editInfo, '_id') || _.get(editInfo, 'bind') !== 'moduleName') && o._level === 3 &&
                        <div className={'flex center-y'}>
                            <div className={'table-item-text'} onClick={()=>setInfo({mode: 'detail', currentInfo: o})}>{showValue}</div>
                            <div className={'handle-icon-group flex center-y'}>
                                {/*{*/}
                                {/*    editAuth &&*/}
                                {/*    <Icon className={'edit-icon'} name={'bianji2'} onClick={()=>onEditIconClick(o, bind)}/>*/}
                                {/*}*/}
                                {/*{*/}
                                {/*    delAuth &&*/}
                                {/*    <Icon className={'remove-icon'} name={'shanchu2'} onClick={()=>setDelInfo(o)}/>*/}
                                {/*}*/}
                            </div>
                        </div>
                    }
                </div>
            }else if(_.includes(['system','productType'], bind)){
                return null
            }
        }
        return o._level === level ? addLeftIcon(showValue, o, i) : ''
    }

    function addLeftIcon(v, o, i){
        return <div className='arrow-drop-down-group flex center-y' onClick={() => {handleTreeClick(expandList,o,i,'_parent','keyPath')}}>
            <Icon name={_.get(o,'_expand')?'biaogeshouqi':"biaogezhankai"} className='arrow-drop-down'/>
            <span>{v}</span>
        </div>
    }

    function onChangeFormEnter(){
        const newParams = {...editInfo['currentObj']}
        newParams[editInfo['bind']] = changeInfo
        submitChange(newParams)
    }

    // function onEditIconClick(currentObj, bind){
    //     setChangeInfo(null)
    //     setEditInfo({_id: currentObj._id, bind, currentObj, oldValue: currentObj[bind]})
    // }
}

function getOption(cols){
    return {
        autoFill: true,
        resizable: true,
        virtualized: true,
        columns: cols,
    }
}

function SalesProductList(props) {
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const [params, setParams] = useState()
    const [info, setInfo] = useState()
    const {doFetch: getList} = useGet()
    const [showList, setShowList] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [editInfo, setEditInfo] = useState()
    const [changeInfo, setChangeInfo] = useState()
    const [expandList, setExpandList] = useState()
    const {doFetch} = usePost()
    const [delInfo, setDelInfo] = useState()
    const [showImportDialog, setShowImportDialog] = useState()
    const [showBatchDel, setShowBatchDel] = useState(false)
    const [currentId, setCurrentId] = useState()
    const [newCurrentId, setNewCurrentId] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const userPositionList = JSON.parse(localStorage.getItem('userPositionList'))
    const isManager = _.includes(userPositionList, 'salesProductMgt')
    const {data: allUserRes} = useGet('/common/userinfo')

    useEffect(()=>{
        if(_.isNil(newCurrentId)) return
        if(currentId !== newCurrentId) return
        const newIndex = _.findIndex(showList, x => x.keyPath === newCurrentId)
        if(newIndex !== -1){
            document.querySelector('.sales-product-list').querySelector('.grid').scrollTop = newIndex * 35
            setNewCurrentId(null)
        }
    },[showList, newCurrentId, currentId, setNewCurrentId])

    const update = useCallback((updateItem)=>{
        const _id = _.get(updateItem, '_id')
        setExpandList(oldList => updateList(oldList, _id))
        setShowList(oldList => updateList(oldList, _id))
        function updateList(list, _id){
            return _.map(list, x => x._id === _id ? updateItem: x)
        }
    },[])

    useEffect(()=>{
        if(!_.isNil(editInfo)){
            setChangeInfo(editInfo['oldValue'])
        }
    },[editInfo])

    const submitChange = useCallback((newParams)=>{
        if(!_.isNil(editInfo) && editInfo.oldValue === newParams[editInfo.bind]) return setEditInfo(null)
        doFetch('/salesproductInfo/modify', newParams).then(()=>{
            Messager.show(`修改成功`, { icon: 'success' })
            update(newParams)
            setEditInfo(null)
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
            setEditInfo(null)
        })
    },[doFetch,update,editInfo])

    const getExpandList = useCallback((listRes)=>{
        if(_.isNil(listRes)) return []
        if(listRes.length === 0){
            setExpandList([])
            setShowList([])
            return []
        }
        const treeList = _.map(_.groupBy(listRes, x => x['system']),(firstItem, firstName) => ({
            keyPath: handleNull(firstName),
            system: firstName,
            _oneChild: firstItem.length === 1,
            _child: _.map(_.groupBy(firstItem, x => x['productType']), (secondItems, secondName) => ({
                keyPath: handleNull(firstName) + handleNull(secondName),
                productType: secondName,
                _oneChild: secondItems.length === 1,
                _child: _.map(_.filter(secondItems, item => _.isNil(item.pid) || item.pid === ''), (thirdItems)=>({
                    keyPath: handleNull(firstName) + handleNull(secondName) + handleNull(thirdItems.id),
                    // moduleId: thirdItems.moduleId,
                    // system: firstName,
                    // productType: secondName,
                    // id:thirdItems.id,
                    // moduleName: thirdItems.moduleName,
                    // unit: thirdItems.unit,
                    // quantity: thirdItems.quantity,
                    // priceStand: thirdItems.priceStand,
                    // productLine: thirdItems.productLine,
                    // memo: thirdItems.memo,
                    ...thirdItems,
                    moduleId: thirdItems.moduleId,
                    system: firstName,
                    productType: secondName,
                    _oneChild: thirdItems.length === 1,
                    _child: _.map(_.filter(secondItems, item => item.pid === thirdItems.id), item => ({
                        keyPath: handleNull(firstName) + handleNull(secondName) + handleNull(thirdItems.id) + item.id,
                        ...item,
                    }))
                }))
            }))
        }))

        function handleNull (v){
            return isNil(v)?'NULL':v
        }
        const handleList = handleTree(treeList, '_child', 'keyPath')
        setExpandList(handleList)
        setShowList(handleList)
    },[])

    const [productLineOptions, salesSystemOptions, salesProductTypeOptions, salesSystemListOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4] = optionsRes
        return [
            _.map(d1, x => ({text: x.displayName, value: x.memo})),
            ..._.map([d2, d3, d4], x => convertGlobalConstOptions(x))
        ]
    },[optionsRes])

    const refresh = useCallback(()=>{
        if(_.isNil(salesSystemListOptions)) return
        let searchParams = {...params}
        let systemList = _.concat([],_.get(params,'system') || [])
        _.forEach(_.get(params,'system'), item => {
            const childrenList = _.map(_.filter(salesSystemListOptions, x => x.pid === item),'value')
            systemList = _.concat(systemList,childrenList)
        })
        searchParams['system'] = _.isEmpty(systemList) ? null : systemList
        getList('/salesproductInfo/list?'+strParams(searchParams)).then(listRes => {
            getExpandList(listRes)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[params, getList,getExpandList, salesSystemListOptions])

    useEffect(()=>{
        refresh()
    },[refresh])

    const extra = useMemo(()=>{
        return <div className={'flex'}>
            <TextIconBtn text='导出' icon={'daochu'} onClick={()=>setShowImportDialog('export')}/>
        </div>
    },[])

    const handleTreeClick = useCallback((expandList,o,i,parentName, keyName)=>{
        const updateItem = _.assign({},showList[i],{_expand: !showList[i]._expand})
        if(o._expand){
            const endIndex = _.reduce(showList.slice(i+1),(acc,x,index)=>{
                if(acc!==-1) return acc;
                const currentIndex = (index + i + 1);
                return (showList[currentIndex]._level <= o._level) ? currentIndex : acc;
            },-1);
            setShowList(showList.slice(0,i).concat(updateItem,endIndex !== -1? showList.slice(endIndex): []))
        }else{
            const filterList = _.filter(expandList, item => item[parentName] === o[keyName])
            const addList = _.map(filterList, item => _.assign(item, {_level: o._level+1,_expand: false}))
            setShowList(showList.slice(0,i).concat(updateItem,addList,showList.slice(i+1)))
        }
    },[showList])

    const onSelectionChange = useCallback((keyPath)=>{
        const changeItem = _.find(expandList,x => x.keyPath === keyPath)
        const handleList = selectAll(changeItem, expandList,selectedList, '_parent', '_child', 'keyPath')
        setSelectedList(_.clone(handleList))
    },[expandList,selectedList])

    const selectedKeyPathList = useMemo(()=>{
        return _.map(selectedList,'keyPath')
    },[selectedList])

    const headerCheck = useCallback((checked)=>{
        setSelectedList(checked?expandList:[])
    },[expandList])

    const option = useMemo(()=>{
        return getOption(getColumns({handleTreeClick,expandList,salesSystemOptions, salesProductTypeOptions,productLineOptions,
            setInfo,editInfo, setEditInfo, changeInfo, setChangeInfo, submitChange, setDelInfo, onSelectionChange,selectedKeyPathList,
            headerCheck,isManager,allUserRes}))
    },[handleTreeClick,expandList,salesSystemOptions, salesProductTypeOptions, setInfo,editInfo,changeInfo, submitChange,
        onSelectionChange,selectedKeyPathList,headerCheck,isManager,productLineOptions,allUserRes])

    const delIdList = useMemo(()=>{
        if(_.isNil(delInfo)) return
        const id = _.get(delInfo,'id')
        const pid = _.get(delInfo,'pid')
        if(_.isNil(pid)){
            const childList = _.filter(expandList, x => _.get(x, 'pid') === id)
            const childIdList = _.map(childList, 'id')
            return [...childIdList, id]
        }else{
            return [id]
        }
    },[delInfo, expandList])

    const afterRemoveUpdate = useCallback((delIdList, key = '_id')=>{
        setExpandList(oldList => filterRemove(oldList))
        setShowList(oldList => filterRemove(oldList))
        setSelectedList([])
        function filterRemove(list){
            return _.filter(list, x => !_.includes(delIdList, _.get(x, key)))
        }
    },[])

    const batchDelIdList = useMemo(()=>{
        return _.map(_.filter(selectedList, x => !_.isNil(_.get(x,'id'))),'id')
    },[selectedList])

    const batchDelUidList = useMemo(()=>{
        return _.map(selectedList, '_id')
    },[selectedList])

    useEffect(()=>{
        _.forEach(showList, o => {
            return o._rowClass = o?.keyPath === currentId ? 'select_row' : ''
        })
        forceUpdate()
    },[currentId,showList])

    const onRowClick = useCallback((item) => {
        setCurrentId(item.keyPath)
    },[setCurrentId])

    return <div className={'fill flex-y sales-product-list'}>
        <Options initParams={params} search={setParams} {...{salesSystemOptions, salesProductTypeOptions,salesSystemListOptions}}/>
        <Box title={'产品清单'} className={'x-card-singlegrid'} data={showList} extra={extra}>
            <DataGrid className='x-card-singlegrid' data={showList} option={option} onRowClick={onRowClick}/>
        </Box>
        {
            !_.isNil(info) &&
            <SaleProductUpdateDialog close={()=>setInfo(null)} {...{productLineOptions, salesSystemOptions, salesProductTypeOptions,
                info, refresh, update, setCurrentId, setNewCurrentId, isManager, allUserRes}}/>
        }
        {
            !_.isNil(delInfo) &&
            <DelMessage url={'/salesproductInfo/delete'} refresh={()=>afterRemoveUpdate(delIdList, 'id')} method={'post'} params={delIdList} close={()=>setDelInfo(null)} confirmButtonText={'删除'}>
                模块 {delInfo.moduleName} 会被删除。
                {

                    _.isNil(_.get(delInfo,'pid')) ? '该模块下的子模块会一起删除。': ''
                }
                <br/>
                此操作不可撤销，是否确定删除？
            </DelMessage>
        }
        {
            showBatchDel &&
            <DelMessage url={'/salesproductInfo/delete'} refresh={()=>afterRemoveUpdate(batchDelUidList, '_id')} method={'post'} params={batchDelIdList} close={()=>setShowBatchDel(false)} confirmButtonText={'删除'}>
                此操作不可撤销，是否确定删除？
            </DelMessage>
        }
        {
            showImportDialog === 'export' &&
            <ExportApiDialog
                name='产品清单导出'
                close={() => setShowImportDialog(null)}
                option={option}
                parameter={removeEmptyField(params)}
                url={'/salesproductInfo/explorer'}
            />
        }

        {/*{*/}
        {/*    showImportDialog === 'import' &&*/}
        {/*    <ImportApiDialog close={()=>setShowImportDialog(null)} template={`/field/download?flag=4`} abnormalParams={{whichPage: 4}}*/}
        {/*                     defaultOptions={option} refresh={refresh} importUrl={'/field/upload'} parameter={{flag: 4,isApproval:1}} title={'产品清单'}*/}
        {/*                     abnormal={'/field/export'} exportFieldname={'salesProductinfoExcelVos'}/>*/}
        {/*}*/}
    </div>
}

function getInitParams(){
    return {
        system: null,
        projectType: null,
        moduleName: null,
        moduleId: null
    }
}

function Options(props){
    const {salesSystemListOptions, salesProductTypeOptions, initParams, search} = props
    const [params, setParams] = useState(initParams)

    return <div className={'c-options'}>
        <Form value={params} onChange={setParams}>
            <FormInput label='系统' bind='system' clear search options={salesSystemListOptions} component={CascadeSelect} multiple/>
            <FormInput label='类别' bind='projectType' clear search options={salesProductTypeOptions} component={Select}/>
            <FormInput label='模块ID' bind='moduleId'/>
            <FormInput label='模块名称' bind='moduleName'/>
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={()=>search(params)}>查询</Button>
            <Button className={'reset-btn'} text onClick={()=>{
                setParams(getInitParams())
                search(getInitParams())
            }}>重置</Button>
        </div>
    </div>
}

export default SalesProductList;
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import { DataGrid } from 'rootnet-ui';
import clsx from "clsx";
import { Icon } from '../../components';
import gd from '../../base/global';
import './TreeTable.scss'

const TreeContext = createContext({});

function TreeTable(props) {
  const {
    option,
    columns,
    defaultExpanded = false,
    data,
    childrenColumnName,
    indentSize,
    className,
    treeColumnIndex,
    icon,
    currentId,
    currentIdName,
    ...rest
  } = props
  const [is, setIs] = useState(false)
  const flatTreeData = useFlatTreeData(data, childrenColumnName, defaultExpanded);
  const treeColumns = useTreeColumns(columns, indentSize, treeColumnIndex);
  const options = useTreeOption(option, treeColumns)
  const treeData = useMemo(() => {
    if (is) { }
    return getRenderTreeData(flatTreeData)
  }, [flatTreeData, is])

  return <TreeContext.Provider value={{ forceUpdate: setIs, icon }}>
    <Table {...{ options, className, treeData, rest, currentId, currentIdName }} />
  </TreeContext.Provider>
}
TreeTable.defaultProps = {
  childrenColumnName: "children",
  indentSize: 24,
  treeColumnIndex: 0,
  defaultExpanded: true
}

export default React.memo(TreeTable);

function Table(props) {
  const { options, className, treeData, rest, currentId, currentIdName } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const onRowClick = useCallback((item, index) => {
    gd[currentIdName] = index
    _.forEach(treeData, (o, i) => {
      return o._rowClass = i === index ? 'select_row' : ''
    })
    forceUpdate()
  }, [currentIdName, treeData])

  useEffect(() => {
    if (currentId) onRowClick({}, currentId)
  }, [currentId, onRowClick])

  return <DataGrid option={options}
    className={clsx('biz-tree-table', className)}
    data={treeData}
    onRowClick={onRowClick}
    {...rest} />

}

function useTreeOption(option, treeColumns) {
  return useMemo(() => {
    return _.assign({}, option, { columns: treeColumns });
  }, [option, treeColumns]);
}

function useTreeColumns(columns, indentSize, treeColumnIndex) {
  return useMemo(() => {
    compose(
      convertExpandCol,
      fp.get(treeColumnIndex),
      curryGetLeaf('children')
    )(columns)
    return columns;

    function convertExpandCol(expandableCol) {
      const originalConvert = expandableCol.convert;
      if (!_.get(expandableCol.convert, 'isConverted')) {
        expandableCol.convert = handleConvert;
        expandableCol.convert.isConverted = true;
      }

      function handleConvert(...params) {
        const [v, o] = params;
        const result = _.isFunction(originalConvert) ? originalConvert(...params) : v;
        return <div className='biz-tree-table-expand-col'
          style={{ paddingLeft: indentSize * o.__depth }}>
          {o.__expandable && <ExpandButton itemData={o} />}
          {result}
        </div>
      }
    }
  }, [columns, indentSize, treeColumnIndex])
}

function ExpandButton({ itemData }) {
  const { forceUpdate, icon } = useContext(TreeContext);
  const { befor, after, color = '' } = icon
  const handleClick = useCallback(() => {
    itemData.__expanded = !itemData.__expanded;
    forceUpdate(x => !x);
  }, [itemData, forceUpdate]);

  return <span className={clsx('expand-icon', { expanded: itemData.__expanded })}
    onClick={handleClick}>
    <Icon className='icon-hover' style={{ color }} name={itemData.__expanded ? after : befor} size={16} />
  </span>
}

function useFlatTreeData(data, childrenColumnName, defaultExpanded) {
  return useMemo(() => {
    const result = [];
    flattenData(_.cloneDeep(data), 0, 'root');
    return result;

    function flattenData(data, depth, pathId) {
      _.forEach(data, (x, i) => {
        const itemPathId = `${pathId}.${i}`;
        x.__depth = depth;
        x.__pathId = x.key = itemPathId;//相比索引使用__pathId作为key性能更好，因为__pathId唯一且固定，而索引是动态变化的
        result.push(x);
        if (_.isArray(x[childrenColumnName])) {
          x.__expandable = true;
          x.__expanded = defaultExpanded;
          flattenData(x[childrenColumnName], depth + 1, itemPathId);
        }
      })
    }
  }, [data, childrenColumnName, defaultExpanded])
}

function getRenderTreeData(flatTreeData) {
  if (!_.isArray(flatTreeData)) return [];
  let result = _.clone(flatTreeData);
  hiddenData(result);
  return result;

  function hiddenData() {
    _.forEach(flatTreeData, (shrinkItem) => {
      if (!shrinkItem.__expandable) return null;
      if (shrinkItem.__expanded) return null;
      result = _.filter(result, item => {
        if (item.__pathId === shrinkItem.__pathId) return true;
        return !hasChildren(item.__pathId, shrinkItem.__pathId);
      })
    })
  }
}

function hasChildren(itemPath, rootPath) {
  const itemPaths = _.split(itemPath, '.');
  const rootPaths = _.split(rootPath, '.');
  return rootPaths.every((x, i) => (x === itemPaths[i]));
}

function compose(...funcs) {
  if (funcs.length === 0) return arg => arg;
  if (funcs.length === 1) return funcs[0];
  return funcs.reduce((a, b) => (...args) => a(b(...args)))
}

function curryGetLeaf(childNodeName) {
  return function getLeaf(data, result = []) {
    _.forEach(data, (c) => {
      if (_.isArray(c[childNodeName])) return getLeaf(c[childNodeName], result)
      result.push(c);
    })
    return result;
  }
}
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Button, Messager } from 'rootnet-ui'
import { Form, FormInput, DatePicker, Select } from 'rootnet-edit'
import { dateFormat } from 'rootnet-core/dateFormat'
import { useGet } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../utils/publicFun'
import convertGlobalConstOptions from '../common/ConvertGlobalConstOptions'
import { selectOption } from '../common/commonMethod'
import ProjectManpowerSummaryDetailInfo from './controls/projectManpowerSummaryDetailInfo'
import DisplaySelect from '../common/displaySelect/DisplaySelect'
import './index.scss'

const LIST_URL = '/humanManpower/list'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=HumanProject',        //项目分类
  // '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version', // 版本项目
  '/UserSetting/getUniversalInterfaces?code=projectID&codeName=ProjectName&tableName=View_userProject_status&filter=userProjectType&filterParams=CHA,PRD,VER,SOL', // 研发项目
  '/common/globalconst?globalConst=HumanManpowerType',
  '/UserSetting/getUniversalInterfaces?code=projectID&codeName=ProjectName&tableName=userproject', // 所有研发项目
]

// const MAINTAIN_PROJECT_URL = '/humanSpecialProject/project' //维护项目
const AFFAIR_URL = '/worktime/jobtype/list?newFlag=0' //事务

const initParams = { displayMonth: getFutureMonthDay(), preType: ['03'] }

export default function ProjectManpowerSummaryMgt() {
  const [dateInterval, setDateInterval] = useState(getFutureMonthDay())
  const [parameter, setParameter] = useState(initParams)
  const { data, doFetch, loading, error } = useGet()
  const { data: globalconstRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: affairRes } = useGet(AFFAIR_URL)
  const { data: allUserRes } = useGet('/common/userinfo')

  const affairOpt = useMemo(() => {
    if (_.isEmpty(affairRes)) return []
    return selectOption(affairRes, ['name', 'id'])
  }, [affairRes])

  const [itemClassOpt, devProjectOpt, projectTypeOpt, allDevProjectOpt] = useMemo(() => {
    if (_.isEmpty(globalconstRes)) return []
    return _.map(globalconstRes, d => convertGlobalConstOptions(d))
  }, [globalconstRes])

  const projectAllOpt = useMemo(() => {
    if (_.isNil(allDevProjectOpt) || _.isNil(affairOpt)) return []
    return _.concat(allDevProjectOpt, affairOpt)
  }, [allDevProjectOpt, affairOpt])

  const projectOpt = useMemo(() => {
    if (_.isEmpty(itemClassOpt)) return []
    return _.compact(_.map(itemClassOpt, o => {
      if (o.value === '03') {
        return {
          _disabled: true,
          ...o,
          children: devProjectOpt,
        }
      } else if (o.value === '04') {
        return {
          _disabled: true,
          ...o,
          children: affairOpt,
        }
      } else return
    }))
  }, [itemClassOpt, devProjectOpt, affairOpt])

  const refresh = useCallback((params = initParams) => {
    const { displayMonth, ...res } = params
    const getParams = _.assign({}, res, {
      beginDate: dateFormat('YYYY-MM-DD', _.get(displayMonth, 'begin')),
      endDate: dateFormat('YYYY-MM-DD', _.get(displayMonth, 'end')),
      status: '01',
    })
    doFetch(`${LIST_URL}?${strParams(getParams)}`)
  }, [doFetch])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className='fill flex-y project-manpower-summary-mgt'>
      <Option search={refresh} {...{ setParameter, setDateInterval, projectOpt, itemClassOpt }} />
      <ProjectManpowerSummaryDetailInfo
        data={_.get(data, 'humanManpowerPipeline')}
        totalData={_.get(data, 'totalHumanManpowerPipelines')}
        refresh={() => refresh(parameter)}
        {...{ loading, error, dateInterval, projectOpt, projectTypeOpt, projectAllOpt, allUserRes, itemClassOpt, parameter }}
      />
    </div>
  )
}

function Option(props) {
  const { search, setDateInterval, setParameter, projectOpt, itemClassOpt } = props
  const [params, setParams] = useState(initParams)

  const handChange = useCallback((o, key) => {
    if (key === 'displayMonth') {
      const dateArr = getMonthBetween(o[key])
      const len = _.size(dateArr)
      if (len > 12) {
        Messager.show('日期区间不能超过12个月', { icon: 'error' })
        o[key] = getFutureMonthDay(11, new Date(`${dateArr[0]}-01`))
      }
    }
    setParams(o)
  }, [])

  return <div className='flex c-options project-manpower-summary-option'>
    <Form value={params} onChange={handChange}>
      <FormInput
        range
        horizontal
        mode='month'
        bind='displayMonth'
        component={DatePicker}
        bindOutConvert={d => {
          const mydate = new Date(_.get(d, 'end'))
          const SY = mydate.getFullYear();
          const SM = mydate.getMonth() + 1;
          const lastDate = new Date(SY, SM, 0).getDate()
          return _.assign({}, d, { end: new Date(`${SY}-${SM}-${lastDate}`) })
        }}
      />
      <FormInput horizontal label='项目/事务' bind='projectId' component={Select} options={projectOpt} search clear tree />
      <FormInput horizontal label='预估类型' bind='preType' component={DisplaySelect} options={itemClassOpt} search clear multiple />
    </Form>
    <div className='search-btn-group flex'>
      <Button className={'search-btn'} primary onClick={() => {
        search(params)
        setParameter(params)
        setDateInterval(_.get(params, 'displayMonth'))
      }} >查询</Button>
      <Button className={'reset-btn'} text onClick={() => {
        setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), initParams))
        search(initParams)
        setParameter(initParams)
        setDateInterval(getFutureMonthDay())
      }} >重置</Button>
    </div>
  </div>
}

function getMonthBetween(props) {
  const { begin, end } = props

  const result = []
  const s = _.split(dateFormat('YYYY-MM-DD', begin), '-')
  const e = _.split(dateFormat('YYYY-MM-DD', end), '-')
  const min = new Date();
  const max = new Date();
  min.setFullYear(s[0], s[1]);
  max.setFullYear(e[0], e[1]);
  const curr = min;
  while (curr <= max) {
    const month = curr.getMonth();
    let str = (month === 0 ? curr.getFullYear() - 1 : curr.getFullYear()) + "-" + (month === 0 ? 12 : (month < 10) ? '0' + month : month);
    const s = curr.getFullYear() + "-12";
    if (str === s) {
      str = curr.getFullYear() + "-12";
    }
    result.push(str);
    curr.setMonth(month + 1);
  }
  return result;
}

function getFutureMonthDay(monthNum = 3, d = new Date()) {
  //来源
  const SY = d.getFullYear();
  const SM = d.getMonth();
  const SD = d.getDate();
  //结果
  const mydate = new Date(SY, String(SM + monthNum).padStart(2, '0'), String(SD).padStart(2, '0'));
  const yyyy = mydate.getFullYear();
  let MM = (mydate.getMonth() + 1).toString().padStart(2, '0');
  const lastDate = new Date(yyyy, MM, 0).getDate();
  let dd = mydate.getDate().toString().padStart(2, '0');
  if (MM === 2 && dd > 28) {
    if (yyyy % 100 === 0 && yyyy % 400 === 0) {
      dd = 29
    } else if (yyyy % 100 !== 0 && yyyy % 4 === 0) {
      dd = 29;
    } else {
      dd = 28;
    }
  }
  return { begin: new Date(`${SY}-${SM + 1}-01`), end: new Date(`${yyyy}-${MM}-${lastDate}`) };
}

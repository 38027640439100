import React, {useCallback, useMemo, useState} from 'react';
import './CustomerVoiceDetailInfo.scss'
import Icon from "../../../../components/Icon";
import {DatePicker, Display, Form, FormInput, Select, Input} from "rootnet-edit";
import _ from "lodash";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import convertOptions from "../../../common/ConvertOptions";
import {Image, Tag, Tooltip} from "antd";
import RichTextEditor from "../../../common/richTextEditor/TinyEditor";
import {TextIconBtn} from "../../../common/TextIconBtn";
import ViewQueryDialog from "../../../common/view/viewQueryDialog/ViewQueryDialog";
import DelMessage from "../../../../components/DelMessage";
import RequirementDetailDialog from "../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import IssueDetailDialog from "../../../issueMgt/components/issueDetailDialog/IssueDetailDialog";
import {usePost} from "rootnet-biz/lib/hooks";
import {Messager} from "rootnet-ui";
import CustomerServiceInfoDetailDialog
    from "../../../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog";

const HFormInput = props => <FormInput horizontal labelWidth={112} componentWidth={180} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=PRIORITYLIST',
    'common/globalconst?globalConst=voiceCloseType',
]

const OPTIONS_URLS = [
    '/story/getbugProject',
    'common/globalconst?globalConst=soundSource',
]

function CustomerVoiceDetailInfo(props) {
    const {isDetail, editFormData, setEditFormData, allUserRes, editFormError, setEditFormError, descRef, resolveRef, submit,
        refreshDetail} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [customerInfoExpand, setCustomerInfoExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const [resolveExpand, setResolveExpand] = useState(true)
    const [relationExpand, setRelationExpand] = useState(true)
    const [imageVisible, setImageVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [showRelationDialog, setShowRelationDialog] = useState()
    const [delParams, setDelParams] = useState()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const {doFetch: relatePost} = usePost()
    const [delRelateInfo, setDelRelateInfo] = useState()

    const [priorityOptions, closeTypeOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [custIdOptions, soundSourceTreeOptions, soundSourceOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1, d2] = optionsRes
        const firstLevelSoundSource = _.filter(d2, x => _.isNil(x.pId))
        const soundSourceTreeOptions = _.map(firstLevelSoundSource, x => ({
            text: x.displayName,
            value: x.interiorId,
        }))
        _.forEach(soundSourceTreeOptions, item => {
            const childrenList = _.filter(d2, x => x.pId === item.value)
            item['children'] = _.map(childrenList, x => ({
                text: x.displayName,
                value: x.interiorId,
            }))
        })
        return [
            _.map(d1, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, tCustId: x.customer })),
            soundSourceTreeOptions,
            convertGlobalConstOptions(d2)
        ]
    },[optionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData( oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[setEditFormData])

    const previewImage = useCallback(e => {
        if(e.target.tagName === 'IMG'){
            setImageSrc(e.target.src)
            setImageVisible(true)
        }
    },[])

    const delRelate = useCallback((type, id, bizId)=>{
        const relateParams = [{
            type: type,
            voiceId: _.get(editFormData,'id'),
            action: 'del',
            relateIds: [id],
            realRelateIds: [bizId],
        }]
        relatePost('/customerVoice/relateVoice', relateParams).then(() => {
            Messager.show('修改成功', { icon: 'success' })
            refreshDetail()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[editFormData, relatePost, refreshDetail])

    const onRelate = useCallback((items, type, fieldName)=>{
        const submitList = []
        const oldIdList = _.map(_.get(editFormData,fieldName),'id')
        const newIdList = _.map(items,'id')
        const addList = _.filter(items, x => !_.includes(oldIdList, x.id))
        if(!_.isEmpty(addList)){
            submitList.push({
                type,
                voiceId: _.get(editFormData,'id'),
                action: 'add',
                relateIds: _.map(addList,'id'),
                realRelateIds: _.map(addList,'bizId'),
            })
        }
        const delList = _.filter(_.get(editFormData,fieldName), x => !_.includes(newIdList, x.id))
        if(!_.isEmpty(delList)){
            submitList.push({
                type,
                voiceId: _.get(editFormData,'id'),
                action: 'del',
                relateIds: _.map(delList,'id'),
                realRelateIds: _.map(delList,'relateId'),
            })
        }
        if(_.isEmpty(submitList)) return
        relatePost('/customerVoice/relateVoice', submitList).then(() => {
            Messager.show('修改成功', { icon: 'success' })
            refreshDetail()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[editFormData, relatePost, refreshDetail])

    return <div className={'customer-voice-detail-info'}>
        <div className="area-wrap base-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: baseInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setBaseInfoExpand(v => !v)}/>
                基本信息
            </div>
            <div className="area-content-wrap" style={{height: baseInfoExpand ? '' : 0}}>
                <Form value={editFormData} onChange={changeForm} error={editFormError} onError={v => setEditFormError(x => _.assign({},x,v))}>
                    <HFormInput label={'发生日期'} bind={'happenDate'} component={isDetail ? Display : DatePicker} required
                                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                                convert={v => {
                                    if (_.isNil(v)) return '-'
                                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD', v) || '-'
                                }}
                    />
                    <HFormInput label={'优先级'} bind={'priority'} required component={isDetail ? Display : Select}
                                options={priorityOptions} convert={v => convertOptions(v, priorityOptions)}/>
                    <HFormInput label={'声音来源'} bind={'soundSourceList'} required multiple search tree
                                component={isDetail ? Display : Select} options={soundSourceTreeOptions}
                                convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, soundSourceOptions)),'、')}>
                                    <div className={'ellipsis-line'}>
                                        {_.join(_.map(v, x => convertOptions(x, soundSourceOptions)),'、')}
                                    </div>
                                </Tooltip>}
                    />
                    <HFormInput label={'关闭类型'} bind={'closeType'} component={Display} convert={v => convertOptions(v, closeTypeOptions)}/>
                    <HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
                                convert={v => {
                                    if (_.isNil(v)) return '-'
                                    if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                                }}
                    />
                </Form>
            </div>
        </div>
        <div className="area-wrap base-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: customerInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setCustomerInfoExpand(v => !v)}/>
                客户信息
            </div>
            <div className="area-content-wrap" style={{height: customerInfoExpand ? '' : 0}}>
                <Form value={editFormData} onChange={changeForm} error={editFormError} onError={v => setEditFormError(x => _.assign({},x,v))}>
                    <HFormInput label={'客户名称'} bind={'customer'} required component={isDetail ? Display : Select} search
                                options={custIdOptions} convert={v => convertOptions(v, custIdOptions)}/>
                    <HFormInput label={'客户联系人'} bind={'customerLinker'} required component={isDetail? Display: Input}/>
                    <HFormInput label={'联系方式'} bind={'contactUs'} component={isDetail? Display: Input}/>
                </Form>
            </div>
        </div>

        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: relationExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setRelationExpand(v => !v)}/>
                主业务流关联
            </div>
            <div className="area-content-wrap" style={{height: relationExpand ? '' : 0}}>
                {
                    _.isEmpty(_.get(editFormData,'relateReqList')) &&
                    <TextIconBtn icon={'sousuo1'} text={'关联需求'} className={'add-relation-icon'} onClick={()=>setShowRelationDialog('req')}/>
                }
                {
                    !_.isEmpty(_.get(editFormData,'relateReqList')) &&
                    <div className={'relation-items-wrap flex center-y'}>
                        <div className={'relation-text'}>
                            关联的需求：
                        </div>
                        {
                            _.map(_.get(editFormData,'relateReqList'), item => {
                                return <Tag color="#5477FF" closable={true} className={'flex center-y'} onClose={(e)=> {
                                    e.preventDefault()
                                    setDelRelateInfo({
                                        type: 'req',
                                        id: item.id,
                                        bizId: item.relateId
                                    })
                                }}>
                                    <span className={'tag-text'} onClick={()=>{
                                        setShowDetailDialog({
                                            module: 'req',
                                            id: item.id
                                        })
                                    }}>
                                        {item.relateId}
                                    </span>
                                </Tag>
                            })
                        }
                        <Icon name={'bianji2'} className={'handle-icon edit-icon'} onClick={()=>setShowRelationDialog('req')}/>
                    </div>
                }
                <div/>
                {
                    _.isEmpty(_.get(editFormData,'relateIssueList')) &&
                    <TextIconBtn icon={'sousuo1'} text={'关联ISSUE'} className={'add-relation-icon'} onClick={()=>setShowRelationDialog('issue')}/>
                }
                {
                    !_.isEmpty(_.get(editFormData,'relateIssueList')) &&
                    <div className={'relation-items-wrap flex center-y'}>
                        <div className={'relation-text'}>
                            关联的ISSUE：
                        </div>
                        {
                            _.map(_.get(editFormData,'relateIssueList'), item => {
                                return <Tag color="#FF5454" closable={true} className={'flex center-y'} onClose={(e)=> {
                                    e.preventDefault()
                                    setDelRelateInfo({
                                        type: 'issue',
                                        id: item.id,
                                        bizId: item.relateId
                                    })
                                }}>
                                    <span className={'tag-text'} onClick={()=>{
                                        setShowDetailDialog({
                                            module: 'issue',
                                            id: item.id
                                        })
                                    }}>
                                        {item.relateId}
                                    </span>
                                </Tag>
                            })
                        }
                        <Icon name={'bianji2'} className={'handle-icon edit-icon'} onClick={()=>setShowRelationDialog('issue')}/>
                    </div>
                }
                <div/>
                {
                    _.isEmpty(_.get(editFormData,'relateCustomerList')) &&
                    <TextIconBtn icon={'sousuo1'} text={'关联服务单'} className={'add-relation-icon'} onClick={()=>setShowRelationDialog('customer')}/>
                }
                {
                    !_.isEmpty(_.get(editFormData,'relateCustomerList')) &&
                    <div className={'relation-items-wrap flex center-y'}>
                        <div className={'relation-text'}>
                            关联的服务单：
                        </div>
                        {
                            _.map(_.get(editFormData,'relateCustomerList'), item => {
                                return <Tag color="#2db7f5" closable={true} className={'flex center-y'} onClose={(e)=> {
                                    e.preventDefault()
                                    setDelRelateInfo({
                                        type: 'customer',
                                        id: item.id,
                                        bizId: item.relateId
                                    })
                                }}>
                                    <span className={'tag-text'} onClick={()=>{
                                        setShowDetailDialog({
                                            module: 'customer',
                                            id: item.id
                                        })
                                    }}>
                                        {item.relateId}
                                    </span>
                                </Tag>
                            })
                        }
                        <Icon name={'bianji2'} className={'handle-icon edit-icon'} onClick={()=>setShowRelationDialog('customer')}/>
                    </div>
                }
            </div>
        </div>
        <div className="area-wrap desc-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: descExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setDescExpand(v => !v)}/>
                问题详细描述
                {
                    !isDetail &&
                    <span style={{color: 'red'}}>（必填）</span>
                }
            </div>
            <div className="rich-text-detail-wrap flex" style={{display: isDetail && descExpand ? "block": 'none'}}>
                <div onClick={previewImage} dangerouslySetInnerHTML={{__html: _.get(editFormData,'details')}}/>
            </div>
            <div className="rich-text-area" style={{display: !isDetail && descExpand ? "block": 'none'}}>
                <RichTextEditor ref={descRef}/>
            </div>
        </div>
        <div className="area-wrap desc-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: resolveExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setResolveExpand(v => !v)}/>
                处理方案
                {/*{*/}
                {/*    !isDetail &&*/}
                {/*    <span style={{color: 'red'}}>（必填）</span>*/}
                {/*}*/}
            </div>
            <div className="rich-text-detail-wrap flex" style={{display: isDetail && resolveExpand ? "block": 'none'}}>
                <div onClick={previewImage} dangerouslySetInnerHTML={{__html: _.get(editFormData,'solution')}}/>
            </div>
            <div className="rich-text-area" style={{display: !isDetail && resolveExpand ? "block": 'none'}}>
                <RichTextEditor ref={resolveRef}/>
            </div>
        </div>
        <Image
            style={{ display: 'none' }}
            src={imageSrc}
            preview={{
                visible: imageVisible,
                src: imageSrc,
                onVisibleChange: visible => {
                    setImageVisible(visible)
                }
            }}
        />
        {
            showRelationDialog === 'req' &&
            <ViewQueryDialog close={()=>setShowRelationDialog(null)} initValue={_.map(_.get(editFormData,'relateReqList'),'id')}
                             outerSetItem={items => onRelate(items,'req','relateReqList')} funcCode={'0004'} bizField={'story.storyId'} bizName={'需求'} multiple={true}
                             initItemValue={_.map(_.get(editFormData,'relateReqList'), x => ({id: x.id, bizId: x.relateId}))}
            />
        }
        {
            showRelationDialog === 'issue' &&
            <ViewQueryDialog close={()=>setShowRelationDialog(null)} initValue={_.map(_.get(editFormData,'relateIssueList'),'id')}
                             outerSetItem={items => onRelate(items,'issue','relateIssueList')} funcCode={'0005'} bizField={'ProductionIssue.IssueId'} bizName={'ISSUE'} multiple={true}
                             initItemValue={_.map(_.get(editFormData,'relateIssueList'), x => ({id: x.id, bizId: x.relateId}))}
            />
        }
        {
            showRelationDialog === 'customer' &&
            <ViewQueryDialog close={()=>setShowRelationDialog(null)} initValue={_.map(_.get(editFormData,'relateCustomerList'),'id')}
                             outerSetItem={items => onRelate(items,'customer','relateCustomerList')} funcCode={'2001'} bizField={'CustomerServiceInfo.IssueDesc'} bizName={'客户服务信息'} multiple={true}
                             initItemValue={_.map(_.get(editFormData,'relateCustomerList'), x => ({id: x.id, bizId: x.relateId}))}
            />
        }
        {
            delParams &&
            <DelMessage close={()=>setDelParams(null)} refresh={()=>submit(delParams)}>
                确认解除关联吗？
            </DelMessage>
        }
        {
            _.get(showDetailDialog,'module') === 'req' &&
            <RequirementDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog,'id')
            }}/>
        }
        {
            _.get(showDetailDialog,'module') === 'issue' &&
            <IssueDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog,'id')
            }}/>
        }
        {
            _.get(showDetailDialog,'module') === 'customer' &&
            <CustomerServiceInfoDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog,'id')
            }}/>
        }
        {
            delRelateInfo &&
            <DelMessage refresh={()=>delRelate(delRelateInfo.type, delRelateInfo.id, delRelateInfo.bizId)}
                        close={()=>setDelRelateInfo(null)}
            >
                确认解除关联吗？
            </DelMessage>
        }
    </div>
}

export default CustomerVoiceDetailInfo;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './MenuUsageStatistics.scss'
import {useGet} from "rootnet-biz/es/hooks";
import {Box} from "../../common/commonComponent";
import _ from "lodash";
import {DatePicker, Form, FormInput, Select} from "rootnet-edit";
import {Button,DataGrid,Pagination} from "rootnet-ui";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {strParams} from "../../../utils/publicFun";
import {_cache} from "../../../utils/cache";

function getColumns(props){
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
        { header: '功能名称', bind: 'functionName', align: 'center',width: 150, tooltip: true, sortable: true },
        { header: '用户', bind: 'userId', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '部门', bind: 'departmentName', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '岗位', bind: 'positionName', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '使用次数', bind: 'useNumInt', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '最近使用时间', bind: 'latelyUseTime', align: 'center', width: 150, tooltip: true, sortable: true,
            convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v))},
    ]
}

function getOptions(columns){
    return {
        autoFill: true,
        resizable: true,
        virtualized: false,
        nilText: '-',
        emptyText: '-',
        columns,
    }
}

const INIT_PARAMS = {
    userId: null,
    functionName: null,
    startTime: null,
    endTime: null,
    pageNum: 1,
    pageSize: 20,
}

function MenuUsageStatistics(props) {
    const [params, setParams] = useState(INIT_PARAMS)
    const {data: listRes, doFetch: getList} = useGet()

    const { pageSize, total, pageNum, rows: list } = useMemo(() => listRes || {}, [listRes]);

    const refresh = useCallback(()=>{
        getList('/functionCount/list?'+strParams(params))
    },[getList,params])

    useEffect(()=>{
        refresh()
    },[refresh])

    const options = useMemo(()=>{
        return getOptions(getColumns())
    },[])

    return <div className={'menu-usage-statistics fill flex-y'}>
        <Option search={setParams} initParams={params}/>
        <Box title={'功能使用情况'} className='flex-y x-card-singlegrid' data={list}>
            <DataGrid data={list} option={options}/>
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
    </div>
}

function Option(props){
    const {search, initParams} = props
    const [params, setParams] = useState(initParams)
    const { data: allUserRes } = useGet('/common/userinfo')

    const userOptions = useMemo(()=>{
        if(_.isEmpty(allUserRes)) return []
        return _.map(allUserRes, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}-${x.userName}`}))
    },[allUserRes])

    const allMenuOptions = useMemo(()=>{
        const menu = _.filter(_cache.get('allMenuList'), x => x.funcType === 0)
        return generate_menu(null,menu,1)
    },[])

    return <div className={'c-options'}>
        <Form value={params} onChange={setParams}>
            <FormInput label={'用户'} bind={'userId'} component={Select} clear search options={userOptions}/>
            <FormInput label={'功能名称'} bind={'functionName'} component={Select} tree clear search options={allMenuOptions} multiple/>
            <FormInput label={'登录时间'} bind={'startTime'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
            <span className={'form-text'}>至</span>
            <FormInput bind={'endTime'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={()=>search({...params,pageNum: 1,pageSize: 20,})}>查询</Button>
            <Button className={'reset-btn'} text onClick={() => {
                setParams(INIT_PARAMS)
            }}>重置</Button>
        </div>
    </div>
}

function generate_menu(temp, data, level) {
    const subs = _.filter(data, o => o.funcLevel === level);
    _.forEach(subs, r => {
        r.text = r.funcName;
        r.value = r.funcCode;
        if (temp) {
            const parent = _.find(temp, o => _.startsWith(r.funcCode, o.funcCode));
            if (parent) {
                if (_.isNil(parent.children))
                    parent.children = [];
                parent.children.push(r);
            }
        }
    })
    if (subs.length > 0)
        generate_menu(subs, data, level + 1);
    return subs;
}

export default MenuUsageStatistics;
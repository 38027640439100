import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, Button, Messager } from 'rootnet-ui';
import { Display, Form, FormInput } from 'rootnet-edit'
import _ from "lodash";
import './ImpTaskInfoDialog.scss'
import { useGet, usePost } from "rootnet-biz/es/hooks";
import RichTextEditor from '../../../../common/richTextEditor/TinyEditor'
import { getTaskFormConfig } from "./getTaskFormConfig";
import ImpReportWorkTimeDialog from "../impReportWorkTimeDialog/ImpReportWorkTimeDialog";
import { strParams } from "../../../../../utils/publicFun";
import gd from "../../../../../base/global";
import { Icon } from "../../../../../components";
import { TextIconBtn } from '../../../../common/TextIconBtn';

const milestoneFlagOptions = [
    { text: '是', value: '1' },
    { text: '否', value: '0' },
]

const HFormInput = (props) => <FormInput labelWidth={120} componentWidth={200} horizontal {...props} />

function ImpTaskInfoDialog(props) {
    const { close, currentTaskInfo, planStatusOptions, priorityOptions, segmentOptions, listRefresh, isPrincipal,
        projectId, showList, refreshViewList, setCurrentTaskInfo, outerUserRes } = props
    const { mode: initMode, id, serial, initFormInfo } = currentTaskInfo || {}
    const { segment: initSegment } = initFormInfo || {}
    const [mode, setMode] = useState(initMode)
    const [formInfo, setFormInfo] = useState(initFormInfo)
    const [editFormInfo, setEditFormInfo] = useState(initFormInfo)
    const { data: allUserRes } = useGet('/common/userinfo')
    const richEditorRef = useRef()
    const { doFetch: getInfo } = useGet()
    const { doFetch: update } = usePost()
    const [formError, setFormError] = useState()
    const [showReportWorkTime, setShowReportWorkTime] = useState(false)
    const { doFetch: getTaskTimeInfo } = useGet()
    const [taskTimeInfo, setTaskTimeInfo] = useState()

    const listIndex = useMemo(() => {
        return _.findIndex(showList, x => _.get(x, 'id') === id)
    }, [showList, id])

    const getSegmentSort = useCallback((value) => {
        return _.get(_.find(segmentOptions, x => x.value === value), 'sortSerial')
    }, [segmentOptions])

    const isTaskPrincipal = useMemo(() => {
        return _.get(formInfo, 'principal') === _.get(gd, '_user.operator_id')
    }, [formInfo])

    useEffect(() => {
        getTaskTimeInfo('/worktime/project/detail?' + strParams({
            userAccount: gd.User.operator_id,
            projectType: 'SERVICE',
            taskId: id
        })).then(res => setTaskTimeInfo(_.head(res)))
            .catch((err) => {
                Messager.show(err._message, { icon: 'error' })
            })
    }, [getTaskTimeInfo, id])

    const isDetailMode = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const refresh = useCallback(() => {
        if (_.isNil(id)) return
        getInfo('/implementTask/list/info?id=' + id).then(res => {
            richEditorRef.current.setContent(_.get(res, 'detail') ? _.get(res, 'detail') : '')
            setFormInfo(res)
            setEditFormInfo(res)
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [id, getInfo])

    useEffect(() => {
        refresh()
    }, [refresh])

    const completedNotesRequired = useMemo(() => {
        if (_.isNil(editFormInfo)) return false
        return editFormInfo['milestoneFlag'] === '1'
    }, [editFormInfo])

    const formConfig = useMemo(() => {
        return getTaskFormConfig({
            planStatusOptions, priorityOptions, segmentOptions, milestoneFlagOptions,
            allUserRes: allUserRes || outerUserRes, completedNotesRequired, mode, principal: _.get(editFormInfo, 'principal')
        })
    }, [planStatusOptions, priorityOptions, segmentOptions, allUserRes, completedNotesRequired, mode, outerUserRes, editFormInfo])

    const requiredKeyList = useMemo(() => {
        return _.map(_.filter(formConfig, x => _.get(x, 'required')), 'bind')
    }, [formConfig])

    const saveBtnDisabled = useMemo(() => {
        return _.some(requiredKeyList, key => {
            const value = _.get(editFormInfo, key)
            return _.isNil(value) || value === ''
        })
    }, [requiredKeyList, editFormInfo])

    const submit = useCallback(() => {
        const postParams = {
            ...editFormInfo,
            detail: richEditorRef.current.getContent()
        }
        if (mode === 'add') {
            postParams['serial'] = serial
        }
        if (_.get(editFormInfo, 'segment') !== initSegment && _.size(showList) !== 0) {
            let normalSort
            if (listIndex + 1 !== _.size(showList)) {
                normalSort = getSegmentSort(_.get(editFormInfo, 'segment')) <= getSegmentSort(_.get(showList[listIndex + 1], 'segment'))
                    && getSegmentSort(_.get(editFormInfo, 'segment')) >= getSegmentSort(_.get(showList[listIndex], 'segment'))
            } else {
                normalSort = false
            }
            if (!normalSort) {
                const biggerSortItem = _.find(showList, x => getSegmentSort(_.get(x, 'segment')) > getSegmentSort(_.get(editFormInfo, 'segment')))
                if (_.isNil(biggerSortItem)) {
                    postParams['serial'] = _.toNumber(_.get(_.last(showList), 'serial')) + 1
                } else {
                    const biggerSortItemIndex = _.findIndex(showList, x => x.id === biggerSortItem.id)
                    if (biggerSortItemIndex === 0) {
                        postParams['serial'] = _.toNumber(_.get(biggerSortItem, 'serial')) / 2
                    } else {
                        postParams['serial'] = (_.toNumber(_.get(showList[biggerSortItemIndex - 1], 'serial')) + _.toNumber(_.get(biggerSortItem, 'serial'))) / 2
                    }
                }
            }
        }
        const url = mode === 'add' ? '/implementTask/add' : '/implementTask/update'
        update(url, postParams).then(() => {
            Messager.show(mode === 'add' ? '添加' : '修改成功', { icon: 'success' })
            if (mode === 'add') {
                close()
            } else {
                setMode('detail')
            }
            refresh()
            listRefresh()
            refreshViewList()
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [editFormInfo, refresh, listRefresh, update, mode, close, serial, initSegment, showList, listIndex, getSegmentSort, refreshViewList])

    useEffect(() => {
        if (editFormInfo?.milestoneFlag === '0') {
            return setFormError(x => _.assign({}, x, { completedNotes: null }))
        }
        const completedNotes = _.get(editFormInfo, 'completedNotes')
        if (editFormInfo?.milestoneFlag === '1' && (_.isNil(completedNotes) || completedNotes === '')) {
            return setFormError(x => _.assign({}, x, { completedNotes: '必填项！' }))
        }
        return setFormError(x => _.assign({}, x, { completedNotes: null }))
    }, [editFormInfo])

    const onFormChange = useCallback((changeObj, bind) => {
        let personParams = {}
        if (bind === 'principal') {
            let newUserList = editFormInfo['userList']
            if (!_.isNil(editFormInfo['principal'])) {
                newUserList = _.filter(newUserList, x => x !== editFormInfo['principal'])
            }
            newUserList = _.union(newUserList, [changeObj['principal']])
            personParams = { userList: _.clone(newUserList) }
        }
        if (bind === 'userList') {
            if (!_.isNil(editFormInfo['principal'])) {
                if (!_.includes(changeObj['userList'], editFormInfo['principal'])) {
                    return Messager.show('负责人必须为参与人')
                }
            }
        }
        setEditFormInfo(oldObj => {
            const newObj = { ...oldObj, ...changeObj, ...personParams }
            if (_.get(newObj, 'planBeginDate') && _.get(newObj, 'planEndDate')) {
                if (_.includes(['planBeginDate', 'planEndDate'], bind)) {
                    if (_.get(newObj, 'planBeginDate') > _.get(newObj, 'planEndDate')) {
                        if (bind === 'planBeginDate') {
                            Messager.show('计划开始时间不能晚于计划结束时间')
                        } else {
                            Messager.show('计划结束时间不能早于计划开始时间')
                        }
                        return oldObj
                    }
                }
            }
            if (_.get(newObj, 'actualBeginDate') && _.get(newObj, 'actualEndDate')) {
                if (_.includes(['actualBeginDate', 'actualEndDate'], bind)) {
                    if (_.get(newObj, 'actualBeginDate') > _.get(newObj, 'actualEndDate')) {
                        if (bind === 'actualBeginDate') {
                            Messager.show('实际开始时间不能晚于实际结束时间')
                        } else {
                            Messager.show('实际结束时间不能早于实际开始时间')
                        }
                        return oldObj
                    }
                }
            }
            return newObj

        })
    }, [editFormInfo])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        const switchIndex = switchDirection === 'next' ? listIndex + 1 : listIndex - 1
        setCurrentTaskInfo({
            mode: 'detail',
            id: _.get(showList[switchIndex], 'id'),
            listIndex: switchIndex
        })
    }, [setCurrentTaskInfo, showList, listIndex])

    return <Dialog header={'任务'} className={'imp-task-info-dialog'} footerVisible={false} cancel={close} headerVisible={false}>
        <div className='imp-task-info-content'>
            <div className='mock-dialog-header flex'>
                <div className="dialog-title">
                    任务
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        mode !== 'add' && (isPrincipal || isTaskPrincipal) &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetailMode ? '进入编辑' : '退出编辑'} onClick={() => {
                                if (isDetailMode) {
                                    setMode('edit')

                                    richEditorRef.current.setContent(_.get(editFormInfo, 'detail') ? _.get(editFormInfo, 'detail') : '')
                                } else {
                                    setMode('detail')
                                    setEditFormInfo(formInfo)
                                }
                            }} />
                        </span>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <div className={`imp-task-info-main-panel ${isDetailMode ? 'detail-mode' : ''}`}>
                <Form value={editFormInfo} onChange={onFormChange} error={formError} setError={setFormError}>
                    {
                        _.map(formConfig, config => {
                            return <HFormInput {...config} key={config.bind} component={isDetailMode ? Display : _.get(config, 'component')}
                                bindInConvert={isDetailMode ? v => v : _.get(config, 'bindInConvert')}
                                bindOutConvert={isDetailMode ? v => v : _.get(config, 'bindOutConvert')}
                                disabledList={[_.get(editFormInfo, 'principal')]}
                            />
                        })
                    }
                </Form>
                <div className="rich-text-form-item flex">
                    <div className="mock-label">任务描述</div>
                    <div className="rich-text-area" style={{ display: isDetailMode ? 'none' : 'unset' }}>
                        <RichTextEditor ref={richEditorRef} />
                    </div>
                    <div className="rich-text-value-show-wrap" style={{ display: isDetailMode ? 'unset' : 'none' }}>
                        {
                            _.isNil(_.get(editFormInfo, 'detail')) || _.get(editFormInfo, 'detail') === '' ?
                                <div>-</div>
                                : <div dangerouslySetInnerHTML={{ __html: _.get(editFormInfo, 'detail') }} />
                        }
                    </div>
                </div>
            </div>
            <div className="imp-task-info-footer">
                <div className={'empty-footer-area'} />
                {
                    isDetailMode &&
                    <div className={'footer-switch flex center-y'}>
                        <div className={`footer-switch-item flex center ${_.includes([-1, 0], listIndex) ? 'disabled' : ''}`}
                            onClick={() => {
                                if (!_.includes([-1, 0], listIndex)) {
                                    switchCurrentItem('previous')
                                }
                            }}>
                            <Icon name='xiangqian' />
                        </div>
                        <div className="list-num">
                            {listIndex + 1}/{showList?.length}
                        </div>
                        <div className={`footer-switch-item flex center ${listIndex === showList?.length - 1 ? 'disabled' : ''}`}
                            onClick={() => {
                                if (!(listIndex === showList?.length - 1)) {
                                    switchCurrentItem('next')
                                }
                            }}>
                            <Icon name='xianghou' />
                        </div>
                    </div>
                }
                <div className="btn-handle-group flex">
                    {
                        isDetailMode && !_.isNil(taskTimeInfo) &&
                        <Button normal className={"imp-task-info-footer-btn"} onClick={() => setShowReportWorkTime(true)}>填写工时</Button>
                    }
                    {
                        isDetailMode && _.isNil(taskTimeInfo) &&
                        <Button normal className={"imp-task-info-footer-btn"} onClick={close}>关闭</Button>
                    }
                    {
                        !isDetailMode &&
                        <Button normal className={"imp-task-info-footer-btn"} onClick={() => {
                            if (mode === 'add') {
                                close()
                            } else {
                                setMode('detail')
                                setEditFormInfo(formInfo)
                            }
                        }}>取消</Button>
                    }
                    {
                        !isDetailMode &&
                        <Button primary className={"imp-task-info-footer-btn"} onClick={submit} disabled={saveBtnDisabled}>保存</Button>
                    }
                </div>
            </div>
        </div>
        <ImpReportWorkTimeDialog taskId={id} taskName={_.get(editFormInfo, 'itemName')} {...{ projectId, showReportWorkTime, setShowReportWorkTime, taskTimeInfo }} />
    </Dialog>
}

export default ImpTaskInfoDialog;
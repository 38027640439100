import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Select} from "antd";
import _ from 'lodash'
import cls from "clsx";
import {REQUIRED_ERROR, useControlledError} from "../../common/personSelect/useControlledError";
import './VersionSelect.scss'

function VersionSelect(props) {
    const {multiple, required, ref, defaultOpen, style, size, onError, options, isControlled, optionFilterProp} = props
    const [stateValue, setStateValue] = useState();
    // const {data: optionsRes, doFetch: getOptionsRes} = useGet()
    const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);
    const [isInited, setIsInited] = useState(false)

    // useEffect(()=>{
    //     getOptionsRes('/develop/release/list?stateList=T')
    // },[getOptionsRes])
    //
    // const options = useMemo(()=>{
    //     return _.map(optionsRes, x => ({label:x.releaseId, value: x.releaseId}))
    // },[optionsRes])

    const initData = useCallback((data)=>{
        if(multiple){
            const initList = []
            let handleData
            if(_.isArray(data)){
                handleData = data
            }else{
                handleData = _.split(data, ',')
            }
            _.forEach(handleData, item => {
                const findItem = _.find(options, x => x.value === item)
                if(!_.isNil(findItem)) initList.push(findItem)
            })
            setStateValue(initList)
        }else{
            const findItem = _.find(options, x => x.value === data)
            setStateValue(findItem)
        }
        setIsInited(true)
    },[options,multiple])

    useEffect(()=>{
        if(isInited && !isControlled) return
        if(_.isNil(props.value)) return
        if(_.isEmpty(options)) return
        initData(props.value)
    },[props.value,options,initData, isInited, isControlled])

    useEffect(()=>{
        if(isInited) return
        if(_.isNil(props.defaultValue)) return
        if(_.isEmpty(options)) return
        initData(props.defaultValue)
    },[props.defaultValue,options,initData,isInited])

    const handleSelect = useCallback((newValue)=>{
        setStateValue(newValue)
        if(props.onChange){
            if(props.multiple){
                const valueList = _.map(newValue, 'value')
                props.onChange(valueList, newValue)
            }else{
                props.onChange(_.get(newValue, 'value'), newValue)
            }
        }
    },[props])

    useEffect(() => {
        const err = (required && _.isEmpty(stateValue)) ? REQUIRED_ERROR : null;
        if (err !== error) {
            setStateError(err);
            if (props.onError)
                props.onError(err);
        }
    }, [stateValue,required,onError,props,error,setStateError])

    const show_error = useMemo(()=>{
        return error && errorVisible
    },[error,errorVisible])

    const handleBlur = useCallback(()=>{
        setUserActive(true)
        if(props.onBlur){
            props.onBlur()
        }
    },[props,setUserActive])

    return <Select options={options} labelInValue mode={multiple ? 'multiple' : 'unset'} value={stateValue} onChange={handleSelect} required={required} ref={ref} defaultOpen={defaultOpen}
                   style={style} allowClear={_.isNil(props.allowClear) ? !multiple : props.allowClear} showSearch 
                   placeholder={props.placeholder ? props.placeholder :'请选择'} className={cls('version-select',{error: show_error},props.className)} onBlur={handleBlur} size={size}
                   disabled={props.disabled} optionFilterProp={optionFilterProp}/>
}

VersionSelect.defaultProps = {
    style: {},
    size:'middle',
    required: false,
    disabled: false,
    multiple: false,
    autoFocus: false,
    optionFilterProp: 'value'
}

export default VersionSelect;
import React from "react";
import TextIconBtn from "../../project_share/components/TextIconBtn";
import {isNil} from 'rootnet-core/format'
import _ from 'lodash';

//ylf
export const normalTransCsv = _.curry(transCsv(','));

//转换csv数据格式--ylf
export function transCsv(sym = '\t') {
    return (config, data) => {
        if (!_.isArray(data) || !_.isArray(config)) return '';
        config = config.filter(x => ((x.header || x.text) !== '#'));
        return headerFor(config).concat(contentFor(config, data));

        function headerFor(config) {
            return _.map(config, o => (o.header || o.text)).join(sym).concat('\n')
        }

        function contentFor(config, data) {
            return data.map(rowTextFor).join('\n');

            function rowTextFor(o) {
                return config.map((x) => {
                    let columnText = formatBind(x, o);
                    return _.isNil(columnText) ? '-' : `"${columnText}"`
                }).join(sym);

                function formatBind(opt, data) {
                    const text = _.get(data, opt.bind);
                    if (opt.csvConvert === true) return opt.convert(text, data);
                    if (typeof opt.csvConvert === 'function') return opt.csvConvert(text, data);
                    return text
                }
            }
        }
    }
}

export function DownCsvV2(props){
    const {tit,options,value,mode,...rest}  = props;

    return <TextIconBtn icon='xiazai2' text={'CSV'} onClick={() => downCsv(dataFor(), tit)} {...rest} />;

    function dataFor() {
        const d = mode==='ref' ? value.current : value;
        return normalTransCsv(options,d);
    }
}

export function downCsv(data, tit) {
    const str = encodeURIComponent(data);
    const csv = document.createElement('a');
    const href_header = isNil(data) ? `data:text/csv;charset=utf-8,` : `data:text/csv;charset=utf-8,\ufeff`;
    csv.href = href_header.concat(str);
    csv.download = tit.concat('.csv');
    csv.click();
}
import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { BaseTable, useTablePipeline, features } from 'ali-react-table'
import { departmentInitData } from '../../../../components/formatData'
import ManpowerDetailLog from '../manpowerDetailLog'
import './index.scss'
import { isNil } from 'rootnet-core/format'

function getColumns(props) {
  const { monthArray, setOpen } = props
  const columnHead = [
    { name: '部门', code: 'departmentName', width: 230, lock: true, },
    {
      name: '合计', code: 'projectId', width: 150, lock: true, align: 'center', children: [
        { name: '占用评估', code: 'workloadOccupiedSummary', width: 75, align: 'center', },
        { name: '部门人力', code: 'departmentManpowerSummary', width: 75, align: 'center', },
      ]
    },
  ]
  const columnMonth = _.map(monthArray, o => {
    return {
      name: o, code: 'projectId', width: 150, align: 'center',
      children: [
        {
          name: '占用评估', code: `workloadOccupied${o}`, width: 75, align: 'center', render: (v, d) => {
            if (d.flag || v === 0) return v
            return <span style={{ cursor: 'pointer' }} onClick={() => {
              setOpen(_.get(d, `manpowerPipelineId${o}`))
            }}>
              {v}
            </span>
          }
        },
        {
          name: '部门人力', code: `departmentManpower${o}`, width: 75, align: 'center', render: v => {
            return isNil(v) ? 0 : v
          }
        },
      ]
    }
  })
  return _.concat(columnHead, _.compact(columnMonth))
}

export default function DepartmentDetail(props) {
  const { data, loading, monthArray, projectOpt, projectOption, itemClassOpt, refresh, statusOpt } = props //error
  const [openKeys, onChangeOpenKeys] = useState()
  const [open, setOpen] = useState()

  const dataSource = useMemo(() => departmentInitData(data), [data])

  const pipeline = useTablePipeline()
    .input({
      dataSource, columns: getColumns({
        monthArray,
        setOpen
      })
    })
    .primaryKey('id')
    .use(
      features.treeMode({
        clickArea: 'cell',
        openKeys,
        onChangeOpenKeys,
      }),
    )

  useEffect(() => {
    if (_.isEmpty(dataSource)) return
    onChangeOpenKeys(_.map(dataSource, 'id'))
  }, [dataSource])

  return (
    <div className='manpower-department-detail'>
      <BaseTable
        isLoading={loading}
        {...pipeline.getProps()}
        style={{
          '--row-height': "34px",
          '--header-bgcolor': '#F5F5F5',
          '--header-color': '#252525'
        }}
      />
      {
        open &&
        <ManpowerDetailLog {...{ initId: open, projectOpt, projectOption, itemClassOpt, refresh, statusOpt }} close={() => setOpen(null)} />
      }
    </div>
  )
}

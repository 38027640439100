import React, { useState, useMemo, useEffect, useCallback } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Button, Switch, Loader, Messager } from 'rootnet-ui'
import { Form, FormInput, TextArea, Select, Input } from 'rootnet-edit'
import { parseParams } from '../../../../project_share/utils/utils'
import { Icon } from '../../../../project_share/components'
import { useGet, useApi } from '../../../../utils/hook'
import Header from '../../Header'
import './ModifyMetadata.scss'

const WHICH_OPTIONS = {
  'Add': '0',
  'Edit': '1',
  'Del': '2'
}

const OPTIONS_URLS = [
  [
    '/fieldType/select',
    '/fieldDict/select',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
  ],
  [
    '/fieldType/select',
    '/fieldDict/select'
  ],
  [
    '/common/globalconst?globalConst=mysqlType',
    '/common/globalconst?globalConst=sqlserverType',
    '/common/globalconst?globalConst=oracleType',
  ],
]
// /field/updateAudit
export default function ModifyMetadata(props) {
  const { history, location } = props
  const defaultData = JSON.parse(parseParams(location.search.split('?')[1]).data)
  const isEdit = defaultData.action === 'Edit'
  const [error, setError] = useState()
  const [isReload, setIsReload] = useState(false)
  const [fieldDictNameDisable, setFieldDictNameDisable] = useState(true)
  const [params, setParams] = useState(defaultData)
  const { doFetch } = useApi()
  const { data: optionRes, loading } = useGet(OPTIONS_URLS[defaultData.tabIndex])
  const { data: uniqListRes } = useGet(`/fieldCommon/getName?whichPage=${defaultData.tabIndex}`)

  const { data: menuRes } = useGet('/common/uacFuncctrl?moduleId=BS')

  const tabDetail = useMemo(() => {
    if (_.isEmpty(menuRes)) return []
    return _.filter(menuRes, x => x.funcType === 3)
  }, [menuRes])

  const getCheckFlag = useCallback((funcName) => {
    return _.toString(_.get(_.find(tabDetail, x => x.funcName === funcName), 'checkFlag')) || '0'
  }, [tabDetail])

  const uniqList = useMemo(() => {
    if (_.isEmpty(uniqListRes)) return []
    if (params.tabIndex !== 1) return _.map(uniqListRes, x => x.code)
    if (params.tabIndex === 1) return _.map(uniqListRes, x => `${x.code}-${x.sonCode}`)
  }, [uniqListRes, params])

  const [fieldTypeOptions, fieldDictIdOptions, productOptions] = useMemo(() => {
    if (defaultData.tabIndex === 2 || _.isEmpty(optionRes)) return []
    const [d1, d2, d3, d4] = optionRes
    return [
      _.map(d1, x => ({ value: x.fieldTypeCode, text: `${x.fieldTypeCode}-${x.fieldTypeName}`, tag: `${x.fieldTypeCode}-${x.fieldTypeName}` })),
      (defaultData.tabIndex === 0) ?
        _.map(d2, x => ({ value: x.fieldDictId, text: `${x.fieldDictId}-${x.fieldDictName}`, tag: `${x.fieldDictId}-${x.fieldDictName}` })) :
        _.map(d2, x => ({ value: x.fieldDictId, text: `${x.fieldDictId}-${x.fieldDictName}` || '-', tag: x.fieldDictId, name: x.fieldDictName })),
      _.map(_.groupBy(d3, product => product.productLine), (productLineItem, productLine) => ({
        text: _.get(_.find(d4, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
        children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName }))
      }))
    ]
  }, [optionRes, defaultData])

  const [mysqlTypeOptions, sqlserverOptions, oracleOptions] = useMemo(() => {
    if (defaultData.tabIndex !== 2 || _.isEmpty(optionRes)) return []
    return _.map(optionRes, x => convertGlobalConstOptions(x))
  }, [optionRes, defaultData])

  const reload = useCallback(() => {
    setParams(x => ({ ...x, fieldDictId: null, fieldDictName: null }))
    setIsReload(x => !x)
  }, [])

  const formChange = useCallback((data) => {
    if (!isReload && !_.isNil(data.fieldDictId)) {
      data.fieldDictName = _.find(fieldDictIdOptions, x => x.value === data.fieldDictId)?.name
    }
    setParams(data)
  }, [fieldDictIdOptions, isReload])

  const check = useCallback(() => {
    return checkRequire(error)
  }, [error])

  useEffect(() => {
    setFieldDictNameDisable(!isReload)
  }, [isReload])

  useEffect(() => {
    if (params.tabIndex === 2) return
    const fieldDescName = params.tabIndex === 0 ? 'fieldDesc' : 'fieldDictSubDesc'
    const fieldDesc = _.get(params, fieldDescName)
    if (params.tabIndex === 0) {
      if (!isEdit && _.get(params, 'fieldCode') && _.includes(uniqList, params.fieldCode)) {
        setError(x => _.assign({}, x, { 'fieldCode': '字典名重复' }))
      }
      if (_.isEmpty(fieldDesc)) setError(x => _.assign({}, x, { [fieldDescName]: '必填项！' }))
    }
    if (params.tabIndex === 1) {
      const fieldDictId = _.get(params, 'fieldDictId') || ''
      const fieldDictSubCode = _.get(params, 'fieldDictSubCode') || ''
      const uniq = `${fieldDictId}-${fieldDictSubCode}`
      if (!isEdit && uniq !== '-') {
        if (_.includes(uniqList, uniq)) {
          setError(x => _.assign({}, x, { 'fieldDictSubCode': '子项标识重复' }))
        } else if (!_.isNil(fieldDictSubCode) && fieldDictSubCode !== '') {
          setError(x => _.assign({}, x, { 'fieldDictSubCode': null }))
        }
      }
      if (_.isEmpty(fieldDesc)) setError(x => _.assign({}, x, { [fieldDescName]: '必填项！' }))
    }
  }, [params, isEdit, uniqList])

  useEffect(() => {
    if (params.tabIndex === 2) {
      if (!isEdit && _.get(params, 'fieldTypeCode')) {
        if (_.includes(uniqList, params.fieldTypeCode)) {
          setError(x => _.assign({}, x, { 'fieldTypeCode': '根网数据类型重复' }))
        }
        if (!/^R_.*/.exec(params.fieldTypeCode)) {
          setError(x => _.assign({}, x, { 'fieldTypeCode': '根网数据类型必须以R_开头' }))
        }
      }
      const dbList = _.pick(params, ['mysqlType', 'sqlserverType', 'oracleType'])
      if (_.isEmpty(dbList) || _.every(dbList, _.isNil)) {
        setError(x => _.assign({}, x, { 'dbRequired': '请至少选择一种数据库类型' }))
      } else {
        setError(x => _.assign({}, x, { 'dbRequired': null }))
      }
    }
    return
  }, [params, uniqList, isEdit])

  return (
    <div className='ModifyMetadata'>
      <Header goBack={() => history.goBack()} content='修改' icon='xiangqian' />
      <section>
        {loading && <Loader />}
        <div>
          <Form value={params} onChange={formChange} error={error} onError={setError}>
            {
              params.tabIndex === 0 && <>
                <div style={{ display: _.get(error, 'fieldCode') === '字典名重复' ? 'block' : 'none' }} className={'form-error-msg'}>
                  <Icon name='tixing' style={{ marginRight: 5 }} />
                        字典名重复
                </div>
                <FormInput bind='fieldCode' required disabled={isEdit} label='字典名' />
                <FormInput bind='fieldName' required disabled={isEdit} label='字典名称' />
                <FormInput bind='fieldTypeCode' required label='根网数据类型' component={Select} options={fieldTypeOptions} search />
                <FormInput bind='fieldDictId' label='常量标识' options={fieldDictIdOptions} component={Select} search clear />
                <FormInput bind='productId' label='使用产品' tree clear search multiple options={productOptions} component={Select}
                  bindInConvert={v => v && v.split(',')} bindOutConvert={v => v && v.join(',')} />
                <FormInput bind='fieldDesc' className={cls({ error: _.size(params.fieldDesc) === 0 })} required label='字典说明' component={TextArea} />
              </>
            }
            {
              params.tabIndex === 1 && <>
                <div className='constant-identifier'>
                  <FormInput label='常量标识' bind='fieldDictId' required disabled={isEdit} component={isReload ? Input : Select} options={fieldDictIdOptions} />
                  <Switch checkedComponent={'新建'} uncheckedComponent={'选择'} checked={isReload} onChange={reload} style={{ width: 56, display: isEdit ? 'none' : '' }} />
                </div>
                <FormInput label='常量名称' bind='fieldDictName' disabled={fieldDictNameDisable} />
                <FormInput label='根网数据类型' bind='fieldTypeCode' required component={Select} options={fieldTypeOptions} search />
                <div style={{ display: _.get(error, 'fieldDictSubCode') === '子项标识重复' ? 'block' : 'none' }} className={'form-error-msg'}>
                  <Icon name='tixing' style={{ marginRight: 5 }} />
                  子项标识重复
                </div>
                <FormInput label='子项标识' bind='fieldDictSubCode' required disabled={isEdit} />
                <FormInput label='子项名称' bind='fieldDictSubName' required />
                <FormInput label='子项说明' bind='fieldDictSubDesc' className={cls({ error: _.size(params.fieldDictSubDesc) === 0 })} required component={TextArea} />
              </>
            }
            {
              params.tabIndex === 2 && <>
                <FormInput bind='fieldTypeCode' required label='根网数据类型' />
                <FormInput bind='fieldTypeName' required label='类型名称' />
                <div style={{ display: _.get(error, 'dbRequired') ? 'block' : 'none' }} className={'form-error-msg'}>
                  <Icon name='tixing' style={{ marginRight: 5 }} />
                  {_.get(error, 'dbRequired')}
                </div>
                <FormInput bind='mysqlType' label='mysql类型' component={Select} options={mysqlTypeOptions} search clear />
                <FormInput bind='sqlserverType' label='sqlserver类型' component={Select} options={sqlserverOptions} search clear />
                <FormInput bind='oracleType' label='oracle类型' component={Select} options={oracleOptions} search clear />
                <FormInput bind='normLength' label='字段长度' />
                <FormInput bind='normPrecision' label='精度' />
                <FormInput bind='dictTypeDesc' label='类型说明' component={TextArea} />
              </>
            }
          </Form>
        </div>
      </section>
      <footer>
        <Button primary onClick={submit} >修改</Button>
      </footer>
    </div>
  )
  function submit() {
    if (!check()) return
    const url = params.action === 'Add' || _.get(params, 'auditFlag') === '1' ? '/field/save' : '/field/updateAudit'
    const postParams = _.assign({}, params, {
      isAgree: '0',
      isApproval: getCheckFlag(params.tabName),
      whichPage: params.tabIndex,
      whichOption: WHICH_OPTIONS[params.action]
    })
    doFetch(url, 'post', postParams).then(() => {
      history.push('/metadataMgt')
      Messager.show(getCheckFlag(params.tabName) === '1' ? `数据已提交，待审核` : '提交成功，已入库', { icon: 'success' })
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
    })
  }
}

function convertGlobalConstOptions(data) {
  return _.map(data, x => ({ value: x.interiorId, text: x.displayName }))
}

function checkRequire(error) {
  if (_.some(_.values(error), x => x)) {
    const errMsg = _.find(_.values(error), x => !_.isNil(x))
    Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
    return false
  }
  return true
}
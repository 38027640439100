import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Input, Table} from "antd";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import useGet from "rootnet-biz/es/hooks/useGet";
import {usePost} from "rootnet-biz/es/hooks";
import _ from "lodash";
import {strParams} from "../../../../utils/publicFun";
import TemplateDetail from "../../../common/publicPage/publicTemplate/control/TemplateDetail";
import {Dialog, MessageBox, Messager} from 'rootnet-ui'
import './RequirementTemplateListDialog.scss'

const { Search } = Input;

function getColumns(props){
    const {setTemplateInfo, pageNum} = props
    return [
        {
            title: '#',
            width: 40,
            render: (text, record, index) => `${10 * (pageNum - 1) + index + 1}`
        },
        {
            title: '模版名称',
            dataIndex: 'templateName',
            key: 'templateName',
            width: 250,
            render: (v, o) => <span style={{color: '#5477ff', cursor: 'pointer'}} onClick={()=>setTemplateInfo({mode: 'detail', data: o})}>{v}</span>
        },
        {
            title: '类型',
            dataIndex: 'projectType',
            key: 'projectType',
            width: 80,
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: ()=> '启用'
        },
        {
            title: '创建人',
            dataIndex: 'createUser',
            key: 'createUser',
            width: 80,
        },
        {
            title: '更新时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            width: 100,
            render: v => dateFormat('YYYY-MM-DD')(toDate.str14ToDate(v))
        },
        {
            title: '任务数',
            dataIndex: 'num',
            key: 'num',
            width: 80,
        },
    ]
}

function RequirementTemplateListDialog(props) {
    const {close, refresh, templateType, submitParams, submitUrl, userprojectType} = props
    const [selectedList, setSelectedList] = useState()
    const {data: list, doFetch: getList, loading} = useGet()
    const [searchText, setSearchText] = useState()
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const {doFetch: replaceTemplate} = usePost()
    const [templateInfo, setTemplateInfo] = useState()
    const [pageNum, setPageNum] = useState(1)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const paginationProps = useMemo(()=>{
        return ({
            pageSize: 10,
            onChange: setPageNum,
            current: pageNum
        })
    },[pageNum])

    useEffect(()=>{
        const searchParams = {
            status: 'Y',
            templateType,
            templateName: searchText === '' ? null : _.trim(searchText)
        }
        getList('/projectTemplate/allList?'+strParams(searchParams))
        setPageNum(1)
    },[searchText, getList, templateType])

    const columns = useMemo(()=>{
        return getColumns({setTemplateInfo, pageNum})
    },[pageNum])

    const handleRow = useCallback(record => {
        return {
            onClick: event => {
                event.currentTarget.getElementsByClassName("ant-radio-wrapper")[0].click();
            },
        };
    },[])

    const confirm = useCallback(()=>{
        if(confirmLoading) return
        setConfirmLoading(true)
        const postParams = {
            ...submitParams,
            templateId: _.head(selectedList)
        }
        replaceTemplate(submitUrl,postParams).then(()=>{
            setConfirmLoading(false)
            Messager.show('模版引入成功', { icon: 'success' })
            refresh()
            setShowConfirmDialog(false)
            close()
        }).catch((err) => {
            setConfirmLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[ submitParams, selectedList,replaceTemplate,close,refresh, confirmLoading, submitUrl])

    const dataSource = useMemo(()=>{
        if(_.isEmpty(list)) return []
        if(!_.isNil(userprojectType)) return _.filter(list,o=>o.projectType === userprojectType)
        return list
    },[list, userprojectType])

    return <Dialog header={'模版列表'} cancel={close} className={'requirement-template-list-dialog x'} contentClassName={'flex-y'}
                   confirm={()=>setShowConfirmDialog(true)} confirmButtonDisabled={_.isEmpty(selectedList)}>
        <Search value={searchText} onChange={e => setSearchText(e.target.value)} placeholder={'模版名称'} style={{ width: 200, marginBottom: 10 }}/>
        <Table size={'small'} dataSource={dataSource} columns={columns} rowKey="id" pagination={paginationProps} sticky={true}
               rowSelection={{
                   type: 'radio',
                   onChange: setSelectedList
               }}
               loading={loading}
               onRow={handleRow}
               scroll={{y: 400 }}/>
        {
            showConfirmDialog &&
            <MessageBox header='提示'  className={'content-center-dialog'} cancel={()=>setShowConfirmDialog(false)} confirm={confirm}>
                此操作会覆盖已有阶段列表，请谨慎操作！
            </MessageBox>
        }
        {
            templateInfo &&
            <TemplateDetail showFooterTips switch={setTemplateInfo} listData={dataSource} params={templateInfo?.data} close={()=>setTemplateInfo(null)} templateType={templateType}/>
        }
    </Dialog>
}

export default RequirementTemplateListDialog;
import React from 'react';
import {Dialog} from 'rootnet-ui'
import './RequirementDetailDialog.scss'
import RequirementDetailContent from "./RequirementDetailContent";

function RequirementDetailDialog(props) {
    const {refreshViewList = ()=>{}, close = ()=>{}} = props

    return <Dialog className={'requirement-detail-dialog'} header={'需求信息'} headerVisible={false} footerVisible={false}>
        <RequirementDetailContent {...props} {...{refreshViewList,close}}/>
    </Dialog>
}

export default RequirementDetailDialog;
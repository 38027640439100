import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LeftMenu } from './LeftMenu';
import Developing from './Developing';
import { MessageDemo, EditorDemo } from './demo';
import WorkBench from './develop/WorkBench';
import ProjectList from './project/ProjectList';
import Approval from './project/approval/Approval';
import { TaskList, TaskDetails } from './task/TaskList';
import { TaskDetailsPro } from '../views/project/taskDetail/TaskList';
import CostCenter from './costManage/costCenter/CostCenter';
import WorkTimeAdd from './costManage/workTime/WorkTime';
// import WorkTimeQuery from './costManage/workTime/worktimeQuery/WorkTimeQuery';
import WorkTimeQuery from './costManage/workTimeQuery/WorkTimeQuery'
import WorkTimeApprove from "./costManage/workTimeApprove/WorkTimeApprove";
// import Subsidy from './costManage/subsidy/Subsidy'
import SubsidyManagement from './costManage/subsidyManagement/subsidyManagement'
import ProjectCostManagement from './ProjectCost/presalesprojectcost/ProjectCost';
import DevprojectCosttManagement from './ProjectCost/devprojectcost/devprojectcost'
import DevprojectCosttDetails from './ProjectCost/devprojectcost/controls/Details'
import ImpprojectCostManagement from './ProjectCost/impprojectcost/impprojectcost'
import ImpprojectCostDetails from './ProjectCost/impprojectcost/controls/Details'
// import SubsidyDetail from './costManage/subsidy/controls/Detail'
import ProjectManagement from './costManage/projectManagement/projectManagement'
import Authoritydev from './authority/authoritydev/authoritydev'
import Authorityimp from './authority/authorityimp/authorityimp'
import Authoritypresales from './authority/authoritypresales/authoritypresales'
import ProjectView from "./viewMgt/projectView/ProjectView";
import CreateView from "./viewMgt/createView/CreateView";
import SystemMgt from "./systemMgt/systemMgt/SystemMgt";
import ModuleMgt from "./systemMgt/moduleMgt/ModuleMgt";
import VersionMsg from "./versionMgt/versionMsg";
import ReportDemo from './demo/ReportView';
import MetadataMgt from "./metadataMgt/MetadataMgt";
import MetadataDetails from "./MobileTerminal/MetadataMgtMob/MyAgent/Details";
import MetadataExamine from "./MobileTerminal/MetadataMgtMob/MyAgent/Examine";
import ModifyMetadata from "./MobileTerminal/MetadataMgtMob/MyAgent/ModifyMetadata"
import CostRateMgt from './costRateMgt'
import ContractInfo from "./contractMgt/contractInfo/ContractInfo";
// import { UACPage } from './Embed_Uac';
import ScheduleManagement from './common/ScheduleManagement';
import MissingHours from "./costManage/workTimeQuery/MissingHours";
import MyCenter from "./myCenter/myCenter";
import MobileHomePage from "./mobileView/MobileHomePage";
import MobileAttendanceMgt from "./mobileView/MobileAttendanceMgt/MobileAttendanceMgt";
import FieldDefinition from "./databaseMgt/fieldDefinition/FieldDefinition";
import VersionUpgradeQuery from "./versionMgt/versionUpgradeQuery/VersionUpgradeQuery";
import { Tabs, Dropdown } from 'antd';
import _ from 'lodash'
import './MainPanel.scss'
import { uniqKeyFor } from "../project_share/utils/utils";
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import Icon from "../components/Icon";
import TestPlan from './testMgt/testPlan';
import TestCase from './testMgt/testCase';
import Defect from './testMgt/defect/Defect';
import PreCheckReport from './reportQuery/preCheckReport';
import SalesProductList from "./salesProductMgt/salesProductList/SalesProductList";
import FuncTableWrap from "./databaseMgt/funcTable";
import LoginStatistics from "./loginStatistics/LoginStatistics";
import MenuUsageStatistics from "./loginStatistics/menuUsageStatistics/MenuUsageStatistics";
import CustomerServiceInfo from "./customerServiceInfo/CustomerServiceInfo";
import TemplateImpMgt from "./impMgt/templateMgt/TemplateMgt";
import ImpProjectMgt from "./impMgt/impProjectMgt/ImpProjectMgt";
import ImpDisplayBoardMgt from './impMgt/impDisplayBoardMgt';
import RequirementMgt from "./requirementMgt/RequirementMgt";
import TemplateMgt from './systemManagement/templateMgt'
import Appraise from './appraise'
import IssueMgt from "./issueMgt/IssueMgt";
import ToDoList from './todolist';
import ConferenceRecords from "./conferenceMgt/conferenceRecords/ConferenceRecords";
import CustomerSystemMgt from "./customerInformationMgt/customerSystemMgt/CustomerSystemMgt";
import ResourceForecast from './resourcePoolMgt/resourceForecast';
import ResourceSchedule from "./resourcePoolMgt/resourceSchedule/ResourceSchedule";
import ReqImportForecast from "./resourcePoolMgt/reqImportForecast/ReqImportForecast";
import TrackingManagementMgt from './trackingManagementMgt';
import WorkFlowMgt from "./workFlow/WorkFlowMgt";
import StageMgt from "./stageMgt/StageMgt";
import DevProjectMgt from './devProjectMgt';
import GlobalConstMgt from "./globalConstMgt/GlobalConstMgt";

import DailyReportMgt from "./dailyReportMgt/DailyReportMgt";
import ManHoursQuery from "./manHours/manHoursQuery/ManHoursQuery";
import CustomerReleaseRecMgt from "./customerReleaseRecMgt/CustomerReleaseRecMgt";
import Workplace from "./workplace/Workplace";
import CustomerMgt from './customerInformationMgt/customerMgt';
import CustomUseProduct from './customUseProduct/CustomUseProduct'
import { MenuContext, ScheduleManagementContext } from './common/Context';
import SalesLeadsMgt from './salesLeadsMgt';
import MenuMgt from "./menuMgt/menuMgt/MenuMgt";
import CustomizeMenuGroup from "./menuMgt/customizeMenuGroup/CustomizeMenuGroup";
import RoleMgt from './authorityMgt/roleMgt';
import UserGroupMgt from './authorityMgt/userGroupMgt';
import DepartmentAndUserMgt from './authorityMgt/departmentAndUserMgt';
import ProcessUserGroupMgt from './authorityMgt/processUserGroupMgt';
import ContentTemplateMgt from './systemManagement/contentTemplateMgt';
import WorkDayMgt from './systemManagement/workWeekAndWorkDayMgt/workDayMgt';
import WorkWeekMgt from './systemManagement/workWeekAndWorkDayMgt/workWeekMgt';
import PersonCostRate from "./costRateMgt/personCostRate/PersonCostRate";
import WorkflowTimeDetailsMgt from './workflowTimeDetailsMgt';
import ManpowerPipelineEstimationMgt from './manpowerPipelineEstimationMgt'
import DevListMgt from './devListMgt';
import ProjectManpowerSummaryMgt from './projectManpowerSummaryMgt';
import ProductMgt from "./productMgt/ProductMgt";
import ReqAnalysis from "./requirementMgt/reqAnalysis/ReqAnalysis";
import WorkTimeReport from "./workTimeMgt/workTimeReport/WorkTimeReport";
import WorkTimeReview from './workTimeMgt/workTimeReview';
import WorkTimeSearch from './workTimeMgt/workTimeSearch';
import WorkTimeDeletion from './workTimeMgt/workTimDeletion';
import PlanVersionMgt from './versionMgt/planVersionMgt';
import IssueTracerMgt from "./issueMgt/issueTracerMgt";
import DataAuthMgt from "./authorityMgt/dataAuthMgt/DataAuthMgt";
import CustomerVoiceMgt from "./customerVoiceMgt/CustomerVoiceMgt";
import VersionRelationMgt from './versionMgt/versionRelationMgt';
import ReviewMinutesMgt from './conferenceMgt/reviewMinutesMgt';
import MessageMgt from './authorityMgt/messageMgt';
import ApiAuthTable from './apiAuthTable';
import MessageInfoMgt from './authorityMgt/messageInfoMgt';
import RequirementMgtList from './requirementMgtList';
import HistoryTestDefect from './testMgt/historyTestDefect';
import SalesProductViewMgt from "./salesProductMgt/salesProductViewMgt/SalesProductViewMgt";
import RabbitMqMgt from './systemManagement/rabbitMqMgt';
import CustomerContactMgt from './customerInformationMgt/customerContactMgt';

const CLIENT = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'

export function MainPanel(props) {
	const { history } = props
	const { menuData, currentMenu, setCurrentMenu } = useContext(ScheduleManagementContext)
	const { activeMenuList,
		activeUrl,
		onMenuItemClick,
		onTabChange,
		onTabRemove,
		CloseMenu } = useContext(MenuContext)
	return <div className="main-panel">
		{
			CLIENT === 'Mobile' ?
				(
					<Switch>
						<Route path='/mobileHome' component={MobileHomePage} />
						<Route path='/mobileAttendanceMgt' component={MobileAttendanceMgt} />
						<Route path='/metadataMgt/details' component={MetadataDetails} exact />
						<Route path='/metadataMgt/details/edit' component={ModifyMetadata} />
						<Route path='/metadataMgt/examine' component={MetadataExamine} />
						<Route component={MobileHomePage} />
					</Switch>
				) : (
					<>
						<LeftMenu {...{ history, menuData, currentMenu, setCurrentMenu, onMenuItemClick }} />
						<div className={'flex-y right-main-panel'}>
							<div className={`flex menu-tab-link ${activeMenuList.length < 2 ? 'no-tab-close-icon' : ''}`} >
								<div style={{ display: 'flex', flex: 1 }}>
									<Tabs
										type="editable-card"
										size='small'
										hideAdd
										activeKey={activeUrl}
										onEdit={onTabRemove}
										onChange={onTabChange}
										items={
											_.map(activeMenuList, activeMenuItem => ({
												label: activeMenuItem.funcName,
												key: activeMenuItem.url === '' ? uniqKeyFor() : activeMenuItem.url,
												closable: _.get(activeMenuItem, 'url') !== '/workplace'
											}))
										} />
									{/*
											_.map(activeMenuList, activeMenuItem => (
												<TabPane
													tab={activeMenuItem.funcName}
													key={activeMenuItem.url === '' ? uniqKeyFor() : activeMenuItem.url}
												/>))
											*/}
									{
										activeMenuList.length > 1 && <div className='close-menu-drop-down'>
											<Dropdown overlay={CloseMenu} trigger={['click']}>
												<div className="close-menu-drop-down-item">
													<Icon name='shibai' style={{ fontSize: '16px', paddingTop: '8px' }} />
												</div>
											</Dropdown>
										</div>
									}
								</div>
							</div>
							<div className="stretch">
								<CacheSwitch>
									<CacheRoute path="/work-platform" cacheKey="/work-platform" component={WorkBench} />
									<CacheRoute path="/project" cacheKey="/project" exact component={ProjectList} />
									<CacheRoute path="/project/task/detail/:id" cacheKey="/project/task/detail/:id" component={TaskDetailsPro} />
									<CacheRoute path="/approval" cacheKey="/approval" component={Approval} />
									<CacheRoute path="/task" cacheKey="/task" component={TaskList} exact />
									<CacheRoute path="/task/detail/:id" cacheKey="/task/detail/:id" component={TaskDetails} />
									<CacheRoute path="/costcenter" cacheKey="/costcenter" component={CostCenter} />
									<CacheRoute path='/costrate' cacheKey='/costrate' component={CostRateMgt} />
									<CacheRoute path='/worktimequery' cacheKey='/worktimequery' component={WorkTimeQuery} />
									<CacheRoute path="/worktimeapprove" cacheKey="/worktimeapprove" component={WorkTimeApprove} />
									<CacheRoute path='/worktimeadd' cacheKey='/worktimeadd' component={WorkTimeAdd} />
									<Route path='/projectView' cacheKey='/projectView' component={ProjectView} />
									<Route path='/createView' cacheKey='/createView' component={CreateView} />
									<CacheRoute path='/subsidy' cacheKey='/subsidy' component={SubsidyManagement} exact />
									<CacheRoute path="/demo/message" cacheKey="/demo/message" component={MessageDemo} />
									<CacheRoute path="/demo/editor" cacheKey="/demo/editor" component={EditorDemo} />
									<CacheRoute path="/devprojectcost" cacheKey="/devprojectcost" component={DevprojectCosttManagement} exact />
									<CacheRoute path="/devprojectcost/Details" cacheKey="/devprojectcost/Details" component={DevprojectCosttDetails} />
									<CacheRoute path="/impprojectcost" cacheKey="/impprojectcost" component={ImpprojectCostManagement} exact />
									<CacheRoute path="/impprojectcost/Details" cacheKey="/impprojectcost/Details" component={ImpprojectCostDetails} />
									<CacheRoute path="/presalesprojectcost" cacheKey="/presalesprojectcost" component={ProjectCostManagement} />
									<CacheRoute path="/authoritydev" cacheKey="/authoritydev" component={Authoritydev} />
									<CacheRoute path="/authorityimp" cacheKey="/authorityimp" component={Authorityimp} />
									<CacheRoute path="/authoritypresales" cacheKey="/authoritypresales" component={Authoritypresales} />
									<CacheRoute path='/projectManagement' cacheKey='/projectManagement' component={ProjectManagement} />
									<CacheRoute path='/appSystemMgt' cacheKey='/appSystemMgt' component={SystemMgt} />
									<CacheRoute path='/moduleMgt' cacheKey='/moduleMgt' component={ModuleMgt} />
									<CacheRoute path='/versionMsg' cacheKey='/versionMsg' component={VersionMsg} />
									<CacheRoute path='/testCaseMgt' cacheKey='/testCaseMgt' component={TestCase} />
									<CacheRoute path='/testPlanMgt' cacheKey='/testPlanMgt' component={TestPlan} />
									<CacheRoute path='/testDefectMgt' cacheKey='/testDefectMgt' component={Defect} />
									<CacheRoute path='/report-demo' cacheKey='/report-demo' component={ReportDemo} />
									<CacheRoute path='/contractInfo' cacheKey='/contractInfo' component={ContractInfo} />
									<CacheRoute path='/metadataMgt' cacheKey='/metadataMgt' component={MetadataMgt} />
									<CacheRoute path='/versionUpgradeQuery' cacheKey='/versionUpgradeQuery' component={VersionUpgradeQuery} />
									{/* <CacheRoute path='/uac' component={UACPage} /> */}
									<CacheRoute path='/missingHours' cacheKey='/missingHours' component={MissingHours} />
									<CacheRoute path='/myCenter' cacheKey='/myCenter' component={MyCenter} />
									<CacheRoute path='/fieldDefinition' cacheKey='/fieldDefinition' component={FieldDefinition} />
									<CacheRoute path='/preCheckReport' cacheKey='/preCheckReport' component={PreCheckReport} />
									<CacheRoute path='/salesProductMgt' cacheKey='/salesProductMgt' component={SalesProductList} />
									<CacheRoute path='/funcTable' cacheKey='/funcTable' component={FuncTableWrap} />
									<CacheRoute path='/loginStatistics' cacheKey='/loginStatistics' component={LoginStatistics} />
									<CacheRoute path='/menuUsageStatistics' cacheKey='/menuUsageStatistics' component={MenuUsageStatistics} />
									<CacheRoute path='/customerServiceInfo' cacheKey='/customerServiceInfo' component={CustomerServiceInfo} />
									<CacheRoute path='/templateImpMgt' cacheKey='/templateImpMgt' component={TemplateImpMgt} />
									<CacheRoute path='/templateMgt' cacheKey='/templateMgt' component={TemplateMgt} />
									<CacheRoute path='/impProjectMgt' cacheKey='/impProjectMgt' component={ImpProjectMgt} />
									<CacheRoute path='/impDisplayBoardMgt' cacheKey='/impDisplayBoardMgt' component={ImpDisplayBoardMgt} />
									<CacheRoute path='/requirementMgt' cacheKey='/requirementMgt' component={RequirementMgt} />
									<CacheRoute path='/360Evaluate' cacheKey='/360Evaluate' component={Appraise} />
									<CacheRoute path='/issue' cacheKey='/issue' component={IssueMgt} />
									<CacheRoute path='/todolist' cacheKey='/todolist' component={ToDoList} />
									<CacheRoute path='/conferenceRecords' cacheKey='/conferenceRecords' component={ConferenceRecords} />
									<CacheRoute path='/customerSystemMgt' cacheKey='/customerSystemMgt' component={CustomerSystemMgt} />
									<CacheRoute path='/ResourceForecast' cacheKey='/ResourceForecast' component={ResourceForecast} />
									<CacheRoute path='/ResourceScheduling' cacheKey='/ResourceScheduling' component={ResourceSchedule} />
									<CacheRoute path='/StoryScheduling' cacheKey='/StoryScheduling' component={ReqImportForecast} />
									<CacheRoute path='/trackingManagementMgt' cacheKey='/trackingManagementMgt' component={TrackingManagementMgt} />
									<CacheRoute path='/stageMgt' cacheKey='/stageMgt' component={StageMgt} />
									<CacheRoute path='/devProjectMgt' cacheKey='/devProjectMgt' component={DevProjectMgt} />
									<CacheRoute path='/staffReportMgt' cacheKey='/staffReportMgt' component={DailyReportMgt} />
									<CacheRoute path='/workflow' cacheKey='/workflow' component={WorkFlowMgt} />
									<CacheRoute path='/customerReleaseRecMgt' cacheKey='/customerReleaseRecMgt' component={CustomerReleaseRecMgt} />
									<CacheRoute path='/manHours' cacheKey='/manHours' component={ManHoursQuery} />
									<CacheRoute path='/customerMgt' cacheKey='/customerMgt' component={CustomerMgt} />
									<CacheRoute path='/globalconstMgt' cacheKey='/globalconstMgt' component={GlobalConstMgt} />
									<CacheRoute path='/prodCustUseInfo' cacheKey='/prodCustUseInfo' component={CustomUseProduct} />
									<CacheRoute path='/workplace' cacheKey='/workplace' component={Workplace} />
									<CacheRoute path='/salesLeadsMgt' cacheKey='/salesLeadsMgt' component={SalesLeadsMgt} />
									<CacheRoute path='/menuMgt' cacheKey='/menuMgt' component={MenuMgt} />
									<CacheRoute path='/customizeMenuGroup' cacheKey='/customizeMenuGroup' component={CustomizeMenuGroup} />
									<CacheRoute path='/roleMgt' cacheKey='/roleMgt' component={RoleMgt} />
									<CacheRoute path='/userGroupMgt' cacheKey='/userGroupMgt' component={UserGroupMgt} />
									<CacheRoute path='/departmentAndUserMgt' cacheKey='/departmentAndUserMgt' component={DepartmentAndUserMgt} />
									<CacheRoute path='/processUserGroupMgt' cacheKey='/processUserGroupMgt' component={ProcessUserGroupMgt} />
									<CacheRoute path='/contentTemplateMgt' cacheKey='/contentTemplateMgt' component={ContentTemplateMgt} />
									<CacheRoute path='/workDayMgt' cacheKey='/workDayMgt' component={WorkDayMgt} />
									<CacheRoute path='/workWeekMgt' cacheKey='/workWeekMgt' component={WorkWeekMgt} />
									<CacheRoute path='/personCostRate' cacheKey='/personCostRate' component={PersonCostRate} />
									<CacheRoute path='/workflowTimeDetailsMgt' cacheKey='/workflowTimeDetailsMgt' component={WorkflowTimeDetailsMgt} />
									<CacheRoute path='/manpowerPipelineEstimationMgt' cacheKey='/manpowerPipelineEstimationMgt' component={ManpowerPipelineEstimationMgt} />
									<CacheRoute path='/projectManpowerSummaryMgt' cacheKey='/projectManpowerSummaryMgt' component={ProjectManpowerSummaryMgt} />
									<CacheRoute path='/devMgt' cacheKey='/devMgt' component={DevListMgt} />
									<CacheRoute path='/productMgt' cacheKey='/productMgt' component={ProductMgt} />
									<CacheRoute path='/reqAnalysis' cacheKey='/reqAnalysis' component={ReqAnalysis} />
									<CacheRoute path='/workTimeReport' cacheKey='/workTimeReport' component={WorkTimeReport} />
									<CacheRoute path='/workTimeReview' cacheKey='/workTimeReview' component={WorkTimeReview} />
									<CacheRoute path='/workTimeSearch' cacheKey='/workTimeSearch' component={WorkTimeSearch} />
									<CacheRoute path='/workTimDeletion' cacheKey='/workTimDeletion' component={WorkTimeDeletion} />
									<CacheRoute path='/planVersionMgt' cacheKey='/planVersionMgt' component={PlanVersionMgt} />
									<CacheRoute path='/issueTracer' cacheKey='/issueTracer' component={IssueTracerMgt} />
									<CacheRoute path='/dataAuthMgt' cacheKey='/dataAuthMgt' component={DataAuthMgt} />
									<CacheRoute path='/customerVoiceMgt' cacheKey='/customerVoiceMgt' component={CustomerVoiceMgt} />
									<CacheRoute path='/versionRelationMgt' cacheKey='/versionRelationMgt' component={VersionRelationMgt} />
									<CacheRoute path='/reviewMinutesMgt' cacheKey='/reviewMinutesMgt' component={ReviewMinutesMgt} />
									<CacheRoute path='/messageMgt' cacheKey='/messageMgt' component={MessageMgt} />
									<CacheRoute path='/apiAuthTable' cacheKey='/apiAuthTable' component={ApiAuthTable} />
									<CacheRoute path='/messageInfoMgt' cacheKey='/messageInfoMgt' component={MessageInfoMgt} />
									<CacheRoute path='/requirementMgtList' cacheKey='/requirementMgtList' component={RequirementMgtList} />
									<CacheRoute path='/historyTestDefectMgt' cacheKey='/historyTestDefectMgt' component={HistoryTestDefect} />
									<CacheRoute path='/rabbitMqMgt' cacheKey='/rabbitMqMgt' component={RabbitMqMgt} />
									<CacheRoute path='/customerLinkerMgt' cacheKey='/customerLinkerMgt' component={CustomerContactMgt} />
									<CacheRoute path='/salesProductViewMgt' cacheKey='/salesProductViewMgt' component={SalesProductViewMgt} />

									<CacheRoute component={Developing} />
								</CacheSwitch>
								<ScheduleManagement />
							</div>
						</div>
					</>
				)
		}

	</div>
}
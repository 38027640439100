import React from 'react'
import { Dialog } from 'rootnet-ui'
import TodoListDetailContent from './todoListDetailContent'
import './index.scss'

export default function TodoListDetailDialog(props) {
  return (
    <Dialog className={'todo-list-detail-dialog'} headerVisible={false} footerVisible={false}>
      <TodoListDetailContent {...props} />
    </Dialog>
  )
}

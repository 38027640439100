import React from 'react'
import { Drawer } from 'rootnet-ui'
import WorkTimeReport from '../../../workTimeReport/WorkTimeReport'
import './index.scss'

export default function WorkHoursDetailDrawer(props) {
  const { open, close, params, user } = props
  return (
    <Drawer
      open={open}
      header={`工时详情-${user}`}
      cancel={close}
      onOutsideClick={close}
      footerVisible={false}
      className='work-hours-detail-drawer'
      contentStyle={{ padding: '4px 8px', overflowY: 'auto' }}
    >
      {
        open && <WorkTimeReport location={{ search: params }} />
      }
    </Drawer>
  )
}

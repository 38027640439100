import React from "react";
import { MessageBox, Messager } from 'rootnet-ui';
import { useApi } from "../utils/hook";
import './DelMessage.scss'

export default function DelMessage(props) {
    const { header = '提示', close, url, refresh, children, submitText, confirmButtonText, method = 'get', params = {}} = props;
    const { doFetch } = useApi();
    return <MessageBox header={header} confirm={handleConfirm} cancel={close} confirmButtonText={confirmButtonText} className={'content-center-dialog common-del-message-dialog'}>
        {children}
    </MessageBox>

    function handleConfirm() {
        if(url){
            doFetch(url, method, params).then(() => {
                Messager.show(submitText ? submitText : '删除成功', { icon: 'success' });
                refresh();
                close();
            }).catch((err) => {
                Messager.show(err._message, { icon: 'error' });
                close();
            })
        }else{
            refresh();
            close();
        }
    }
}

import React, { useMemo, useCallback, useState, useContext } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Dialog, Button, Messager } from 'rootnet-ui'
import { Form, FormInput, Select, RadioGroup, CheckBox, DatePicker } from 'rootnet-edit'
import { ScheduleManagementContext } from '../../../../../../../common/Context'
import { strParams } from '../../../../../../../../utils/publicFun'
import { isNil } from '../../../../../../../appraise/components/method'
import './index.scss'
import { dateFormat } from 'rootnet-core/dateFormat'

const MODIFY_FILE_TYPE_URL = '/exportModifyFile/check/list'
const EXPORT_MODIFY_FILE_URL = '/exportModifyFile/differenceExport' //修改文件

export default function ContrastEditFile(props) {
  const { versionList, close } = props
  const [params, setParams] = useState({})
  const [error, setError] = useState()
  const [modifiedType, setModifiedType] = useState()
  const { data: modifyTypeRes, loading } = useGet(MODIFY_FILE_TYPE_URL)
  const [onlyMakeList, setOnlyMakeList] = useState(true)
  const { downloadGetProgress } = useContext(ScheduleManagementContext)

  const modifyTypeOpt = useMemo(() => {
    if (_.isEmpty(modifyTypeRes)) return []
    const key = _.keys(modifyTypeRes)
    const val = _.values(modifyTypeRes)
    return _.map(key, (v, i) => ({ text: val[i], value: v }))
  }, [modifyTypeRes])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionList)) return []
    return _.map(versionList, o => ({ text: o.releaseName, value: o.releaseID }))
  }, [versionList])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (_.isNil(modifiedType)) return Messager.show('请选择导出文件列表类型', { icon: 'error' })
    const fileUpdateTime = dateFormat('YYYY-MM-DD HH:MM:SS', _.get(params, 'fileUpdateTime'))
    downloadGetProgress(`${EXPORT_MODIFY_FILE_URL}?${strParams({ ...params, modifiedType, onlyMakeList: onlyMakeList ? 'Y' : 'N', fileUpdateTime })}`, '导出修改文件', close)
  }, [downloadGetProgress, modifiedType, onlyMakeList, close, check, params])

  return (
    <Dialog
      header='对比修改文件'
      className='contrast-edit-file-log'
      cancel={close}
      footerVisible={false}
      loading={loading}
    >
      <div className="content-panel flex-y">
        <Form value={params} onChange={setParams} error={error} onError={setError}>
          <FormInput required horizontal label='选择对比的主版本' bind='releaseId' component={Select} options={versionOpt} search />
          <FormInput required horizontal label='主版本修改文件时间' bind='fileUpdateTime' component={DatePicker} mode='datetime' clear />
          <FormInput required horizontal label='选择参与对比的版本' bind='releaseIdAnother' component={Select} options={versionOpt} search />
          <div className='label-text'>选择导出文件列表类型：</div>
          <RadioGroup value={modifiedType} options={modifyTypeOpt} onChange={setModifiedType} />
        </Form>
      </div>
      <div className="mock-footer flex center-y">
        <div>
          <CheckBox value={onlyMakeList} onChange={setOnlyMakeList}>仅导出可制作清单</CheckBox>
        </div>
        <div className="btn-group flex">
          <Button normal onClick={close}>取消</Button>
          <Button primary onClick={confirm}>确认</Button>
        </div>
      </div>
    </Dialog>
  )
}

// function filterOpt(opt, currentData) {
//   return _.filter(opt, o => o.value !== currentData)
// }

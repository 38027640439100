import { useState, useEffect, useRef, useCallback, } from 'react';
import _ from 'lodash';
import { API3 as API } from 'rootnet-core/base';

export function useApi() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cancel_ref = useRef(API.cancelTokenSource());

  const doFetch = useCallback((url, method = 'get', params) => {
    const token = cancel_ref.current.token;

    if (_.isEmpty(url)) return Promise.reject('url为空');
    if (_.isArray(url)) {
      setLoading(true);
      return new Promise((resolve, reject) => {
        Promise.all(url.map(x => API.get(x, token))).then(res => {
          setData(res);
          setError(null);
          setLoading(false);
          resolve(res);
          return res;
        }).catch(err => {
          setError(err);
          setLoading(false);
          reject(err);
          return err;
        });
      });
    } else {
      if (url.includes('undefined')) return Promise.reject('参数未设置完全');
      setLoading(true);
      if (method === 'post') {
        return new Promise((resolve, reject) => {
          API.post(url, params, token).then((res) => {
            setData(res.data);
            setError(null);
            setLoading(false);
            resolve(res.data);
            return res;
          }).catch((err) => {
            setError(err);
            setLoading(false);
            reject(err)
            return err;
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          API.get(url, token).then((res) => {
            setData(res.data);
            setError(null);
            setLoading(false);
            resolve(res.data);
            return res.data;
          }).catch((err) => {
            setError(err);
            setLoading(false);
            reject(err);
            return err;
          })
        })
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      if (cancel_ref.current) {
        cancel_ref.current.cancel();
        cancel_ref.current = null;
      }
    }
  }, []);

  return { data, doFetch, loading, error, setLoading };
}

export function useGet(url) {
  const [status, setStatus] = useState({ data: null, loading: true, error: null });
  const pre = useRef(status);
  const cancel_ref = useRef(API.cancelTokenSource());
  const update = useCallback((url) => {
    const s = pre.current;
    if (!s.loading)
      setStatus({ data: s.data, loading: true, error: s.error });
    const token = cancel_ref.current.token;
    if (_.isArray(url)) {
      Promise.all(_.map(url, o => API.get(o, token))).then((values) => {
        setStatus({ data: _.map(values, v => v.data), loading: false, error: null });
      }).catch((err) => {
        console.error(err);
        setStatus({ data: null, loading: false, error: err });
      })
    } else {
      API.get(url, token).then((res) => {
        setStatus({ data: res.data, loading: false, error: null });
      }).catch((err) => {
        console.error(err);
        setStatus({ data: null, loading: false, error: err });
      });
    }
  }, [pre])

  useEffect(() => {
    pre.current = status;
  }, [status])

  useEffect(() => {
    if (_.isString(url) || _.isArray(url))
      update(url);
  }, [url, update])

  useEffect(() => {
    return () => {
      if (cancel_ref.current) {
        cancel_ref.current.cancel();
        cancel_ref.current = null;
      }
    }
  }, [])

  return { ...status, doFetch: update };
}

export function usePost(url, params) {
  const [status, setStatus] = useState({ data: null, loading: true, error: null });
  const pre = useRef(status);
  const cancel_ref = useRef(API.cancelTokenSource());
  const update = useCallback((url, params) => {
    const s = pre.current;
    if (!s.loading)
      setStatus({ data: s.data, loading: true, error: s.error });
    API.post(url, params, cancel_ref.current.token).then((res) => {
      setStatus({ data: res.data, loading: false, error: null });
    }).catch((err) => {
      console.error(err);
      setStatus({ data: null, loading: false, error: err });
    });
  }, [pre]);

  useEffect(() => {
    pre.current = status;
  }, [status])

  useEffect(() => {
    if (_.isString(url))
      update(url, params);
    return () => {
      if (cancel_ref.current) {
        cancel_ref.current.cancel();
        cancel_ref.current = null;
      }
    }
  }, [url, params, update])

  return { ...status, doFetch: update };
}
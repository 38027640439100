import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import {Display, Form, FormInput, Select} from "rootnet-edit";
import {useApi, useGet} from "../../../utils/hook";
import './UpdateTaskDialog.scss'
import _ from 'lodash'
import {strParams} from "../../../utils/publicFun";
import UserSelect from '../../common/personSelectPop/UserSelect';

const HFormInput = (props) => <FormInput labelWidth={80} horizontal {...props}/>

function UpdateTaskDialog(props) {
    const {close, taskDetail, projectId, updateOptions,refresh} = props
    const [titleOptions, issueStateOptions, principalOptions] = updateOptions
    const {mode, id} = taskDetail
    const [params, setParams] = useState({IssueState: '0'})
    const [remainTime, setRemainTime] = useState(0)
    const {data:echoData, doFetch:getEchoData} = useGet()
    const {doFetch} = useApi()
    const [error, setError] = useState()

    useEffect(()=>{
        if(mode === 'add'){
            getEchoData('/devproject/echoresidualday?projectId='+projectId)
        }else{
            getEchoData('/devproject/echolist?'+strParams({projectId,id}))
        }
    },[mode, getEchoData, projectId, id])

    useEffect(()=>{
        if(_.isNil(echoData)) return
        if(mode === 'add'){
            setRemainTime(echoData)
        }else{
            const initParams = {
                title: echoData.title,
                IssueState: echoData.issueState,
                DesignPrincipal:echoData.designPrincipal,
                DesignUseDay:_.toNumber(echoData.designUseDay).toFixed(2),
                inputUserName:echoData.inputUserName,
                inputDate:echoData.inputDate
            }
            setParams(initParams)
            setRemainTime(_.toNumber(echoData.remainedDay).toFixed(2))
        }
    },[mode,echoData])

    const handleFormChange = useCallback((options, key)=>{
        if(key === 'DesignUseDay'){
            if(options['DesignUseDay'] === '-'){
                setParams(x => ({...x, DesignUseDay:null}))
                return Messager.show('请输入正数', { icon: 'error' })
            }
            if(!/^[+-]?(0|([1-9]\d*))(\.)?(\d{0,1})?$/.test(options['DesignUseDay']) && options['DesignUseDay'] !== ''){
                return Messager.show('计划投入最多输入一位小数', { icon: 'error' })
            }
        }
        setParams(options)
    },[])

    const showRemainTime = useMemo(()=>{
        const formTime = _.toNumber((_.get(params,'DesignUseDay'))) || 0
        if(_.isNil(echoData)){
            return (_.toNumber(remainTime) - formTime).toFixed(2)
        }else{
            return (_.toNumber(remainTime) - formTime + (_.toNumber(_.get(echoData,'designUseDay')|| 0) )).toFixed(2)
        }
    },[params, remainTime,echoData])

    const check = useCallback(()=>{
        if(_.some(_.values(error),x=>x)){
            const errMsg = _.find(_.values(error), x=>!_.isNil(x))
            Messager.show(errMsg==='必填项！'?'请填写必填项':errMsg, { icon: 'error' })
            return false
        }
        return true
    },[error])

    const submit = useCallback(()=>{
        if(!check()) return
        const title = _.get(params, 'title')
        const jobTypeId = _.get(_.find(titleOptions, x => x.value === title),'jobTypeId')
        if(mode === 'add'){
            const postParams = {
                ...params,
                projectId,
                ID:id,
                jobTypeId,
                DesignUseDay: _.toNumber(_.get(params, 'DesignUseDay')) || 0
            }
            doFetch('/devproject/addtasklist','post',postParams).then(()=>{
                Messager.show('添加成功', { icon: 'success' })
                refresh()
                close()
            }).catch(err => {
                Messager.show(err._message, { icon: 'error' })
            })
        }else{
            const postParams = {
                ...params,
                ID:id,
                jobTypeId,
                DesignUseDay: _.toNumber(_.get(params, 'DesignUseDay')) || 0
            }
            doFetch('/devproject/updatetasklist','post',postParams).then(()=>{
                Messager.show('修改成功', { icon: 'success' })
                refresh()
                close()
            }).catch(err => {
                Messager.show(err._message, { icon: 'error' })
            })
        }
    },[mode, params, close,doFetch,refresh,id,projectId,titleOptions,check])

    return <Dialog header={mode === 'add'?'新增':'修改'} className={'update-task-dialog'} cancel={close} confirm={submit}>
        <Form value={params} onChange={handleFormChange} error={error} onError={setError}>
            <HFormInput label='任务分类' bind='title' component={Select} options={titleOptions} disabled={mode === 'edit'} required/>
            <HFormInput label='任务状态' bind='IssueState' component={Select} options={issueStateOptions} required/>
            <HFormInput label='负责人' bind='DesignPrincipal' component={UserSelect} allowClear={false} options={principalOptions} search required/>
            <HFormInput label='计划投入' bind='DesignUseDay' suffix='人日' type='number'/>
            { mode === 'edit' && <HFormInput label='创建人' bind='inputUserName' component={Display} componentWidth={180}/> }
            { mode === 'edit' && <HFormInput label='创建时间' bind='inputDate'  component={Display} componentWidth={180}/> }
        </Form>
        <div className={'remain-time'}>
            项目可分配工时：{showRemainTime}人日
        </div>
    </Dialog>
}

export default UpdateTaskDialog;
import React, { useMemo, useReducer, useState, useRef, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Select, FormInput, Dialog, DatePicker, TextArea, InputGroup, Messager, MessageBox } from 'rootnet-ui';
import { GetTime, Dateformat } from 'rootnet-core/format';
import { useGet } from "../../../utils/hook";
import API1 from "../../../base/task";

//UI组件
export function ProjectDialog(props) {
    const { info: { type, values }, close, update } = props;
    const urls = useMemo(() => urlsFor(type), [type]);
    const { data } = useGet(urls);
    const [params, paramsDispatch] = useReducer(paramsReducer, { status: '0', useDay: 0 });
    const [message, setMessage] = useState(null);
    const [productLine, setProductLine] = useState(null);
    const groupRef = useRef();

    const isEdit = useMemo(()=>type === 'edit',[type]);

    useEffect(() => {
        if (isEdit) {
            const value_ = _.pick(values, ['id', 'projectName', 'custId', "principal", "status", "projectSort", "useDay", "beginDate", "completeDate", "productId", "synopsis", "auditStatus", "auditMemo", 'creatEndDate', 'devBeginDate', 'devEndDate', 'testBeginDate', 'testEndDate']);
            paramsDispatch({ type: 'init', value: value_ })
        }
    }, [isEdit, values]);

    const [ops_customer, ops_pm, ops_productLine, ops_product_source, ops_category] = useMemo(() => {
        if (!_.isArray(data)) return [];
        const [d1, d2, d3, d4, d5, d6] = data;
        const ops_customer = _.map(d1, o => ({ text: o.projectName, value: o.projectId }));
        const ops_pm = _.map(d2, o => ({ text: ''.concat(o.userAccount, '-', o.userName), value: o.userAccount }));
        const ops_productLine = _.map(d3, o => ({ text: o.displayName, value: o.interiorId }));
        const ops_product = _.map(d4, o => ({ text: o.productName, value: o.productId, productLine: o.productLine }));
        let ops_category = _.map(d5, o => ({ text: o.displayName, value: o.interiorId }));
        d6 && paramsDispatch({ type: 'synopsis', value: _.get(d6, 'demoValue') });
        if(isEdit) editDisposeOpts();
        return [ops_customer, ops_pm, ops_productLine, ops_product, ops_category];

        function editDisposeOpts() {
            if(_.get(values,'projectSort') === '1'){
                ops_category = _.filter(ops_category,x=>x.value==='1');
            }else{
                ops_category = _.filter(ops_category,x=>x.value!=='1')
            }
        }
    }, [data,isEdit,values]);

    const ops_product = useMemo(() => {
        if (_.isNil(productLine)) return ops_product_source;
        return _.filter(ops_product_source, o => o.productLine === productLine);
    }, [productLine, ops_product_source]);

    const [ops_begin, ops_end] = useMemo(() => {
        return [dateOptFor({ end: params.completeDate }), dateOptFor({ begin: params.beginDate })];
    }, [params]);

    const header = isEdit ? '修改项目' : '新建项目';
    const inputChange = useCallback(type => (value) => paramsDispatch({ type, value }), []);
    const selectChange = useCallback(type => (e, o) => paramsDispatch({ type, value: _.get(o, 'value') }), []);
    const dateChange = useCallback(type => d => paramsDispatch({ type, value: GetTime(d) }), []);

    return <Dialog className="create-project-dialog" header={header} cancel={close} confirm={commit}>
        <InputGroup ref={groupRef}>
            <FormInput label="项目名称："
                name='projectName'
                required horizontal
                placeholder='请输入'
                maxLength={40}
                value={values.projectName}
                onChange={inputChange('projectName')} />
            <FormInput label="客户："
                name='custId'
                options={ops_customer}
                required horizontal search
                component={Select}
                value={values.custId}
                onChange={selectChange('custId')} />
            <FormInput label="项目经理："
                name='principal'
                options={ops_pm}
                required horizontal search
                component={Select}
                value={values.principal}
                onChange={selectChange("principal")} />
            <FormInput label="产品线："
                name='productLine'
                options={ops_productLine}
                required horizontal
                component={Select}
                disabled={isEdit}
                value={values.productLine}
                onChange={(e, o) => setProductLine(o.value)} />
            <FormInput label="产品："
                name='productId'
                options={ops_product}
                required horizontal search
                component={Select}
                value={values.productId}
                onChange={selectChange("productId")} />
            <FormInput label="分类：" className='projectSort'
                name='projectSort'
                options={ops_category}
                required horizontal
                component={Select}
                value={values.projectSort}
                onChange={selectChange("projectSort")} />
            <FormInput label="设计开始时间："
                name='beginDate'
                required horizontal
                component={DatePicker}
                option={ops_begin}
                value={dateValueFor(values.beginDate)}
                onChange={dateChange('beginDate')} />
            <FormInput label='设计截止时间：' horizontal required component={DatePicker}
                name='creatEndDate'
                value={dateValueFor(values.creatEndDate)}
                onChange={dateChange('creatEndDate')} />
            <FormInput label='开发开始时间：' horizontal required component={DatePicker}
                name='devBeginDate'
                value={dateValueFor(values.devBeginDate)}
                onChange={dateChange('devBeginDate')} />
            <FormInput label='开发截止时间：' horizontal required component={DatePicker}
                name='devEndDate'
                value={dateValueFor(values.devEndDate)}
                onChange={dateChange('devEndDate')} />
            <FormInput label='测试开始时间：' horizontal required component={DatePicker}
                name='testBeginDate'
                value={dateValueFor(values.testBeginDate)}
                onChange={dateChange('testBeginDate')} />
            <FormInput label='测试截止时间：' horizontal required component={DatePicker}
                name='testEndDate'
                value={dateValueFor(values.testEndDate)}
                onChange={dateChange('testEndDate')} />
            <FormInput label="项目结束时间："
                name='completeDate'
                required horizontal
                component={DatePicker}
                option={ops_end}
                value={dateValueFor(values.completeDate)}
                onChange={dateChange('completeDate')} />
            <FormInput label="计划投入(人日)："
                name='useDay'
                required
                horizontal
                placeholder='请输入'
                type='number'
                min={0}
                value={params.useDay}
                disabled={params.projectSort === '1'}
                onChange={inputChange('useDay')} />
            <FormInput label="项目简介："
                horizontal maxLength={1000}
                component={TextArea}
                value={params.synopsis}
                onTextChange={inputChange('synopsis')} />
        </InputGroup>
        {message && <MessageBox header="提示" confirm={() => setMessage(null)} cancelButtonVisible={false} className={'content-center-dialog'}>{message}</MessageBox>}
    </Dialog>;

    function commit() {
        if (!groupRef.current.validate()) return;
        const url = isEdit ? 'develop/project/modify' : 'develop/project/add';
        API1.post(url, params).then(res => {
            Messager.show('信息提交成功', { icon: 'success' });
            update();
            close();
        }).catch(err => {
            setMessage(err._message);
        })
    }
}

//函数
function paramsReducer(state, action) {
    const { type, value } = action;
    // console.log('action', action);
    const strategy = {
        init: () => {
            state = value
        },
        projectSort: () => {
            state.projectSort = value;
            if (value === '1') state.useDay = 0;
        }
    };
    strategy[type] ? strategy[type]() : state[type] = value;
    return { ...state };
}

function urlsFor(type) {
    const urls = ["/common/system?status=02", "common/user/position?posId=PM", "/common/globalconst?globalConst=ProductLine", "develop/product/list", "/common/globalconst?globalConst=DEVProjectSort"];
    if (type === 'add') urls.push("/develop/demotxt?demoId=61");
    return urls;
}

function dateOptFor(o) {
    const vf = d => x => new Date(x ? Dateformat(x, 'day') : d);

    const { begin, end } = o;
    const date = {
        begin: vf('0001-01-01')(begin),
        end: vf('9999-12-31')(end)
    };
    return {
        limit: date,
        available: date
    };
}

function dateValueFor(value) {
    const reg = new RegExp(/^(\d{4})(\d{2})(\d{2})\d{6}$/);
    if (reg.test(value)) {
        let [, y, m, d] = reg.exec(value);
        m--;
        return new Date(y, m, d);
    }
}
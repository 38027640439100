import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, Button, DataGrid, Messager, Switch } from 'rootnet-ui'
import { Form, FormInput, Input, Select } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../utils/publicFun'
import { Box } from '../../../../../common/commonComponent'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import ProjectMaintenanceAddLog from './projectMaintenanceAddLog'
import convertOptions from '../../../../../common/ConvertOptions'
import './index.scss'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import ProjectMaintenanceEditLog from './projectMaintenanceEditLog'

const LIST_URL = '/humanSpecialProject/list'
const EDIT_URL = '/humanSpecialProject/update'
const STATUS_URL = '/common/globalconst?globalConst=HumanManpowerStatus'

function getColumns(props) {
  const { editUpdate, editInfo, allUserRes, projectTypeOpt, setValue, statusOpt, setMode, setParams } = props
  const { editId = null, editField = null } = editInfo || {}
  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    { header: '项目名称', bind: 'projectName', width: 150, convert: (v, o) => convertEditField(v, o, 'projectName') },
    { header: '项目类型', bind: 'projectType', tooltip: true, width: 130, convert: v => convertOptions(v, projectTypeOpt) },
    {
      header: '状态', bind: 'status', width: 110, convert: (v, o) => {
        return <Switch
          checked={v === '01'}
          onChange={v => editUpdate({ bind: 'status', newVal: v ? '01' : '02', id: o.id, oldVal: v, isSwitch: true })}
          checkedComponent={_.get(_.find(statusOpt, o => o.value === '01'), 'text')}
          uncheckedComponent={_.get(_.find(statusOpt, o => o.value === '02'), 'text')}
        />
      }
    },
    { header: '创建人', bind: 'createUser', tooltip: true, width: 100, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '更新人', bind: 'updateUser', tooltip: true, width: 100, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      header: '更新时间', bind: 'updateTime', tooltip: true, width: 140, convert: v => {
        return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
      }
    },
  ]
  function convertEditField(v, o, bind) {
    if (editId === o.id && editField === bind) {
      return <Input
        defaultValue={v}
        onChange={val => setValue(val)}
        onBlur={() => editUpdate({ bind, val: v, id: o.id, item: o })}
        onEnter={() => editUpdate({ bind, val: v, id: o.id, item: o })}
      />
    }
    return <span
      style={{
        cursor: 'pointer',
        color: '#5477ff',
      }}
      onClick={() => {
        setMode('edit')
        setParams(o)
        // setEditInfo({
        //   editId: o.id,
        //   editField: bind,
        // })
      }}
    >
      {v}
    </span>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
})

export default function ManpowerProjectMaintenanceLog(props) {
  const { close, projectTypeOpt, refreshAffair, refreshList } = props
  const [mode, setMode] = useState()
  const [parameter, setParameter] = useState()
  const [editInfo, setEditInfo] = useState()
  const [params, setParams] = useState()
  const [value, setValue] = useState()
  const { data, doFetch, error, loading } = useGet()
  const { data: allUserRes } = useGet('/common/userinfo')
  const { data: statusRes } = useGet(STATUS_URL)
  const { doFetch: upDateFetch } = usePost()

  const statusOpt = useMemo(() => {
    if (_.isEmpty(statusRes)) return []
    return convertGlobalConstOptions(statusRes)
  }, [statusRes])

  const search = useCallback((params) => {
    doFetch(`${LIST_URL}?${strParams(params)}`)
  }, [doFetch])

  const editUpdate = useCallback((props) => {
    const { val, id, bind, newVal, isSwitch } = props
    if (!isSwitch) {
      if (val === value || _.isNil(value)) return destroyValue()
    }
    const postParams = _.assign({}, {
      id,
      [bind]: isSwitch ? newVal : value
    })
    upDateFetch(EDIT_URL, postParams)
      .then(() => {
        search(parameter)
        refreshList()
        refreshAffair()
        destroyValue()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [search, parameter, value, upDateFetch, refreshAffair, refreshList])

  const option = useMemo(() => getOptions(getColumns({ editUpdate, editInfo, setEditInfo, allUserRes, projectTypeOpt, setValue, statusOpt, setMode, setParams })), [editUpdate, editInfo, allUserRes, projectTypeOpt, statusOpt])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn style={{ color: '#5477ff' }} text='添加' icon='tianjia' onClick={() => setMode('add')} />
      {/* <TextIconBtn text='导出' icon='daochu' onClick={() => setMode('export')} /> */}
    </div>
  }, [])

  useEffect(() => {
    search()
  }, [search])

  return (
    <Dialog
      header='项目维护'
      confirmButtonVisible={false}
      cancelButtonText={'关闭'}
      cancel={close}
      className='manpower-project-maintenance-log'
    >
      <Option {...{ search, setParameter, projectTypeOpt }} />
      <Box title={'项目维护列表'} error={error} data={data} loading={loading} className='flex-y x-card-singlegrid' extra={extra}>
        <DataGrid data={data} option={option} />
      </Box>
      {
        mode === 'add' &&
        <ProjectMaintenanceAddLog
          refresh={() => search(parameter)}
          close={() => setMode(null)}
          {...{ projectTypeOpt, refreshAffair }}
        />
      }
      {
        mode === 'edit' &&
        <ProjectMaintenanceEditLog
          refresh={() => search(parameter)}
          close={() => setMode(null)}
          {...{ projectTypeOpt, refreshAffair, statusOpt, initParams: params, setParams, refreshList }}
        />
      }
    </Dialog>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

function Option(props) {
  const { search, setParameter, projectTypeOpt } = props
  const [params, setParams] = useState()
  return <div className='flex c-options manpower-project-maintenance-option'>
    <Form value={params} onChange={setParams}>
      <FormInput horizontal label='项目名称' bind='projectName' component={Input} />
      <FormInput horizontal label='项目类型' bind='projectType' component={Select} options={projectTypeOpt} clear />
    </Form>
    <div className='search-btn-group flex'>
      <Button className={'search-btn'} primary onClick={() => {
        search(params)
        setParameter(params)
      }} >查询</Button>
    </div>
  </div>
}

//node_modules
import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import cls from "classnames";
import _ from 'lodash';
import { Card, LinkButton } from 'rootnet-ui';
import { Dateformat, isNil } from 'rootnet-core/format';

//Local_modules
import { Icon } from '../../../components'
import { useGet } from '../../../utils/hook';

//scss_style
import './Details.scss';

const urls = [
    "common/globalconst?globalConst=TaskStatus",
    "common/globalconst?globalConst=PRIORITYLIST",
]

// const issueState = ['进行中', '关闭', '暂缓']

function Tail(props) {
    const { data, dataNew } = props
    return (
        _.map(dataNew, (o, i) => {
            const { name, bind, convert, isShow = true, cn = '' } = o
            let x = null
            _.map(data, (v) => {
                x = _.get(v, bind, null);
                if (isNil(x)) return x = '-'
                if (_.isFunction(convert))
                    x = convert(x, v);
            })
            return isShow && <div className={cls('r tail', cn)} key={i}>
                <span className='text'>{name}</span>
                <span className='value'>{x}</span>
            </div>

        })
    )
}

export default function Details(props) {
    let { id } = useParams()
    const history = useHistory();
    const { data, doFetch } = useGet();
    const { data: dataName } = useGet(urls);
    const o = _.get(data, '[0]')
    const interiorReqNo_ = !isNil(_.get(o, 'interiorReqNo'))
    const projectName = _.get(o, 'projectName')

    const [issueState, projectPri] = useMemo(() => {
        const [d1, d2] = dataName || [null, null]
        const [issueS, projectP] = [d1, d2]
        return [issueS, projectP]
    }, [dataName])


    useEffect(() => {
        doFetch(`/develop/project/task/list?id=${id}`);
    }, [id, doFetch]);

    const dataNew = [
        { name: '项目名称：', bind: 'projectId', cn: 'span2' },
        { name: '需求编号：', bind: 'interiorReqNo', isShow: interiorReqNo_ },
        { name: '任务：', bind: 'title', cn: 'span2' },
        {
            name: '状态：', bind: 'issueState', convert: x => {
                const o = _.find(issueState, ['interiorId', x])
                return _.get(o, 'displayName')
            }
        },
        {
            name: '性质：', bind: 'issueType', convert: x => {
                if (x === 'NEW') return '新增'
                if (x === 'BUG') return '修改'
            }
        },
        { name: '客户：', bind: 'bondproviderName' },
        { name: '负责人：', bind: 'pricipalName' },
        {
            name: '优先级：', bind: 'projectPri', convert: x => {
                const o = _.find(projectPri, ['interiorId', x])
                return _.get(o, 'displayName')
            }
        },
        { name: '客户部门：', bind: 'custDepart', isShow: interiorReqNo_ },
        { name: '费用部门：', bind: 'feeDepartment', isShow: interiorReqNo_ },
        { name: '版本编号：', bind: 'releaseProject' },
        { name: '问题编号：', bind: 'issueId' },
        { name: '设计完成时间：', bind: 'designCompleteDate', convert: x => Dateformat(x) },
        { name: '设计人日：', bind: 'designUseDay' },
        { name: '开发完成时间：', bind: 'devCompleteDate', convert: x => Dateformat(x) },
        { name: '开发人日：', bind: 'devUseDay' },
        { name: '测试完成时间：', bind: 'testCompleteDate', convert: x => Dateformat(x) },
        { name: '测试人日：', bind: 'testUseDay' },
        { name: '研发任务ID：', bind: 'tracerId', convert: (x, o) => `${x}-${o.tracerTitle}`, cn: 'span3' },
    ]

    return <React.Fragment>
        <div className='r backLink' >
            <LinkButton style={{ marginLeft: '26px' }} circle onClick={() => history.go(-1)}>
                <Icon name="erjititlefanhui" />
            </LinkButton>
            {projectName}
        </div>
        <Card title='任务详情' style={{ height: '100%', marginTop: 0 }} >
            <div className='r details'>
                <Tail dataNew={dataNew} data={data} />
            </div>
        </Card>
    </React.Fragment >
}


import React, { useCallback, useEffect, useMemo, useRef, useState, useReducer } from 'react';
import './IssueDetailContent.scss'
import _ from "lodash";
import { Button, Messager, Loader } from 'rootnet-ui'
import { Badge, Popover, Tabs, Tooltip } from "antd";
import Icon from "../../../../components/Icon";
import IssueDetailHeader from "./issueDetailHeader/IssueDetailHeader";
import IssueDetailRightPanel from "./issueDetailRightPanel/IssueDetailRightPanel";
import IssueInfo from "./issueInfo/IssueInfo";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import ChangeRecord from '../../../common/ChangeRecord';
import RequirementUploadArea from '../../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea';
import AssociatedService from './associatedService';
import AssociatedTracking from './associatedTracking';
// import ReflectionImprovement from './reflectionImprovement';
import IssueAnalysis from "./issueAnalysis/IssueAnalysis";
import { strParams } from "../../../../utils/publicFun";
import ReproduceBug from "./reproduceBug/ReproduceBug";
import CustomerRecord from "./customerRecord/CustomerRecord";
import { useFuncCode, useRolePermissions } from "../../../common/commonMethod";
import TestAnalysis from './testAnalysis';
import RootCauseAnalysis from './rootCauseAnalysis';
import QaCheckDialog from '../qaCheckDialog';
// import QaPage from './qaPage';
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions';
import SyncToJiraDialog from "../../../requirementMgt/requirementDetailDialog/syncToJira/SyncToJiraDialog";
import { TextIconBtn } from "../../../common/TextIconBtn";
import PopoverComment from "../../../testMgt/defect/controls/PopoverComment";
import TrackingAddDialog from "../../../trackingManagementMgt/components/trackingAddDialog/TrackingAddDialog";
import RequirementStage from "../../../requirementMgt/requirementDetailDialog/requirementStage/RequirementStage";
import PublicDefect from '../../../common/publicPage/publicDefect';
import PublicUseCase from '../../../common/publicPage/publicUseCase';
import WorkFlowChangePop from '../../../workFlow/workFlowChangePop/WorkFlowChangePop';
import JoinPlanVersion from '../../../requirementMgt/requirementDetailDialog/joinPlanVersion';
import { isNil } from '../../../appraise/components/method';
import gd from '../../../../base/global';
import CodeCommitList from "../../../common/codeCommitList/CodeCommitList";

const FUNC_CODE = '19'

const { TabPane } = Tabs;

const OPTIONS_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/common/globalconst?globalConst=AUDITTYPE',
]

const DUTY_AFFIRM_URL = '/issue/responserDetail'
const VERSION_UNDER_APPROVAL_URL = '/ReleaseStoryLog/getReleaseStoryLogList' //审批中版本
const VERSION_PLAN_UNDER_APPROVAL_URL = '/ReleaseStoryLog/getReleaseStoryLogList?type=PLANISSUE'
const AFFILIATED_PROJECT_URL = '/projectAuditLog/getUserProject'  //研发项目下拉

function IssueDetailContent(props) {
    const { IssueId: CSIssueId = null } = window.external
    const { refreshViewList = () => { }, currentInfo = {}, close = () => { }, switchCurrentItem, showChildList, currentIndex, isFuncCodeApply } = props
    const { mode: initMode = 'detail' } = currentInfo
    const [mode, setMode] = useState(initMode)
    const [id, setId] = useState()
    const { doFetch: getDetail } = useGet()
    const [formData, setFormData] = useState()
    const [editFormData, setEditFormData] = useState()
    const [editFormError, setEditFormError] = useState()
    const { data: allUserRes } = useGet('/common/userinfo')
    const { data: deptOptionsRes } = useGet(`/common/department?activeFlag=Y`)
    const { data: rethinkImproveTemplate } = useGet(`/issue/getDemoTxt?id=80`)
    const [activeKey, setActiveKey] = useState('info')
    const infoRichEditorRef = useRef()
    const resolveRef = useRef()
    const optStepRef = useRef()
    const logListRef = useRef()
    const customerRecordRef = useRef()
    const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
    const [countDoc, updateDoc] = useReducer((x) => x + 1, 0) // 附件
    const [countTracer, updateTracer] = useReducer((x) => x + 1, 0) // 附件
    const [countStage, updateStage] = useReducer((x) => x + 1, 0)
    const { doFetch: submitPost } = usePost()
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { data: allVersionOptionsRes } = useGet('/customerserviceinfo/selectiveCustomerReleaseRec')
    const [switchDisabled, setSwitchDisabled] = useState(false)
    const { data: whyHowRes } = useGet('/issue/getDemoTxt?id=41')
    const [contentLoading, setContentLoading] = useState(true)
    const [fileCount, setFileCount] = useState(0)
    const [openQaCheck, setOpenQaCheck] = useState(false)
    const [showSyncJira, setShowSyncJira] = useState(false)
    // const {doFetch: updateJiraDoFetch} = usePost()
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [commentText, setCommentText] = useState()
    const [commentPopoverVisible, setCommentPopoverVisible] = useState(false)
    const [showAddTrack, setShowAddTrack] = useState(false)
    const [isClose, setIsClose] = useState(false)
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)
    const [dutyAffirmId, setDutyAffirmId] = useState()
    const { doFetch: getDutyAffirmId } = useGet()
    const { doFetch: getUnderApprobal, data: underApprobal } = useGet()
    const { data: allVersionRes } = useGet('/develop/release/list?versionType=0')
    const [showJoinPlanVersion, setShowJoinPlanVersion] = useState(false)
    const { doFetch: getPlanUnderApprobal, data: planUnderApprobal } = useGet()
    const [isAll, isIssueBg, isEditIssueKind] = useRolePermissions(['ALL', 'issuebg', 'wtxz'])
    const [summaryReasons, setSummaryReasons] = useState({ severity: false, complexity: false })
    const { data: affiliatedProjectRes, doFetch: getaffiliatedProject } = usePost()
    const { doFetch: getTracerIdList } = useGet()
    const [codeCommitBizList, setCodeCommitBizList] = useState()

    const refreshTracerIdList = useCallback(() => {
        if (_.isNil(id)) return
        getTracerIdList('/bug/tracer/getIssueTracer?id=' + id).then(res => {
            let bizList = []
            _.map(res, (v, k) => {
                bizList = _.concat([], k, v)
            })
            setCodeCommitBizList(bizList)
        })
    }, [getTracerIdList, id])

    useEffect(() => {
        refreshTracerIdList()
    }, [refreshTracerIdList])

    const { inputUser } = useMemo(() => formData || {}, [formData])
    const { versionId, issuesType } = useMemo(() => editFormData || {}, [editFormData])

    const isShowCause = useMemo(() => {
        const findItem = _.find(allVersionRes, o => o.releaseId === versionId)
        const isOfficialEdition = _.get(findItem, 'productType2') === 'R'
        return (issuesType === 'TESTONLY' && isOfficialEdition)
    }, [issuesType, versionId, allVersionRes])

    const isReasonChange = useMemo(() => {
        return isIssueBg || inputUser === gd.User.operator_id
    }, [isIssueBg, inputUser])

    const refreshPlanVersion = useCallback(() => {
        if (_.isNil(id)) return
        getPlanUnderApprobal(`${VERSION_PLAN_UNDER_APPROVAL_URL}&${strParams({ storyId: id, auditType: '1', auditFlag: 'U' })}`)
    }, [getPlanUnderApprobal, id])

    const leftAllProjectOptions = useMemo(() => {
        if (_.isEmpty(affiliatedProjectRes)) return []

        return _.sortBy(_.map(affiliatedProjectRes, o => {
            return {
                label: o.projectName,
                text: o.projectName,
                value: o.projectId,
                examine: o.examine,
                status: o.status,
            }
        }), 'text')
    }, [affiliatedProjectRes])

    useEffect(() => {
        refreshPlanVersion()
    }, [refreshPlanVersion])

    useEffect(() => {
        getaffiliatedProject(AFFILIATED_PROJECT_URL, ['5', '6'])
    }, [getaffiliatedProject])

    useEffect(() => {
        const { id } = currentInfo || {}
        if (!_.isNil(id)) {
            setId(id)
        }
    }, [currentInfo])

    useEffect(() => {
        if (_.isNil(_.get(formData, 'issueId'))) return
        getDutyAffirmId(`${DUTY_AFFIRM_URL}?${strParams({ issueId: _.get(formData, 'issueId') })}`)
            .then(res => setDutyAffirmId(res.id))
    }, [formData, getDutyAffirmId])

    const deptUserListOptions = useMemo(() => {
        const userOptions = _.map(allUserRes, x => ({ text: x.userName, value: x.userAccount, tag: `${x.userName}-${x.userAccount}` })) || []
        const deptOptions = _.map(deptOptionsRes, x => ({ text: x.departMentName, value: x.departMentId, tag: `${x.departMentName}-${x.departMentId}` })) || []
        return _.concat(userOptions, deptOptions)
    }, [allUserRes, deptOptionsRes])

    const [productTreeOptions, productOptions, moduleOptionsRes, reviewStatusOpt] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4] = optionsRes
        const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        return [
            productTreeOptions,
            _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag })),
            d3,
            convertGlobalConstOptions(d4),
        ]
    }, [optionsRes])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.get(editFormData, 'bizProcess') === '04') {
            const filterModuleOptions = _.filter(moduleOptionsRes, x => ['STOCK', 'FUTURE'].includes(x.extConn))
            return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
        } else {
            if (_.isNil(_.get(editFormData, 'productName'))) return []
            const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === editFormData.productName)
            return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
        }
    }, [editFormData, moduleOptionsRes])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const refreshRichTextRef = useCallback((newData) => {
        const data = newData || formData
        if (infoRichEditorRef.current) {
            // infoRichEditorRef.current.setContent(_.replace(_.get(data, 'impact') || '', /\n/g, '<br/>'))
            infoRichEditorRef.current.setContent(_.replace(_.get(data, 'impact') || '', /\n/g, ''))
        }
        if (resolveRef.current) {
            // resolveRef.current.setContent(_.replace(_.get(data, 'whyAndHow') || '', /\n/g, '<br/>'))
            resolveRef.current.setContent(_.replace(_.get(data, 'whyAndHow') || '', /\n/g, ''))
        }
        if (optStepRef.current) {
            // optStepRef.current.setContent(_.replace(_.get(data, 'optStep') || '', /\n/g, '<br/>'))
            optStepRef.current.setContent(_.replace(_.get(data, 'optStep') || '', /\n/g, ''))
        }
        if (logListRef.current) {
            // logListRef.current.setContent(_.replace(_.get(data, 'logList') || '', /\n/g, '<br/>'))
            logListRef.current.setContent(_.replace(_.get(data, 'logList') || '', /\n/g, ''))
        }
        if (customerRecordRef.current) {
            // customerRecordRef.current.setContent(_.replace(_.get(data, 'communicateNote') || '', /\n/g, '<br/>'))
            customerRecordRef.current.setContent(_.replace(_.get(data, 'communicateNote') || '', /\n/g, ''))
        }
    }, [formData])

    const refreshDetail = useCallback((id) => {
        if (_.isNil(id) && _.isNil(CSIssueId)) return
        setSwitchDisabled(true)
        const searchParams = {
            id,
            issueId: CSIssueId
        }
        setContentLoading(true)
        getDetail('/issue/detail?' + strParams(searchParams)).then(res => {
            let detail = { ...res }
            detail['influence'] = _.split(_.get(res, 'influence'), '^')
            detail['issueKind'] = _.split(_.get(res, 'issueKind'), '^')
            detail['issueKindDetail'] = _.split(_.get(res, 'issueKindDetail'), '^')
            detail['newVersion'] = _.split(_.get(res, 'newVersion'), '^')
            detail['extConntoBrokers'] = _.split(_.get(res, 'extConntoBrokers'), '^')
            detail['_planReleaseList'] = _.map(_.get(res, 'planReleaseStoryList'), o => _.assign({}, o, { value: o.releaseid }))
            setFormData(detail)
            setEditFormData(detail)
            refreshRichTextRef(detail)
            setId(res.id)
            setSwitchDisabled(false)
            setContentLoading(false)
        }).catch(err => {
            setSwitchDisabled(false)
            setContentLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
        updateDynamic()
    }, [getDetail, refreshRichTextRef, CSIssueId])

    const refreshReleaseList = useCallback((flag = false) => {
        if (_.isNil(id) && _.isNil(CSIssueId)) return
        const searchParams = {
            id,
            issueId: CSIssueId
        }
        getUnderApprobal(`${VERSION_UNDER_APPROVAL_URL}?${strParams({ storyId: id, auditType: '1', auditFlag: 'U', type: 'ISSUE' })}`)
        getDetail('/issue/detail?' + strParams(searchParams)).then(res => {
            const releaseIdList = _.get(res, 'releaseIdList')
            const planReleaseStoryList = _.map(_.get(res, 'planReleaseStoryList'), o => _.assign({}, o, { value: o.releaseid }))
            // setFormData(x => ({...x,releaseIdList}))
            setEditFormData(x => {
                const oldPlanReleaseList = _.get(x, '_planReleaseList')
                return { ...x, releaseIdList, _planReleaseList: flag ? oldPlanReleaseList : planReleaseStoryList, projectAuditLog: res.projectAuditLog }
            })
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [id, CSIssueId, getDetail, getUnderApprobal])

    useEffect(() => {
        if (_.isNil(id) && _.isNil(CSIssueId)) return
        if (_.isNil(rethinkImproveTemplate)) return
        setSwitchDisabled(true)
        const searchParams = {
            id,
            issueId: CSIssueId
        }
        setContentLoading(true)
        getUnderApprobal(`${VERSION_UNDER_APPROVAL_URL}?${strParams({ storyId: id, auditType: '1', auditFlag: 'U', type: 'ISSUE' })}`)
        getDetail('/issue/detail?' + strParams(searchParams)).then(res => {
            let detail = { ...res }
            detail['influence'] = _.split(_.get(res, 'influence'), '^')
            detail['issueKind'] = _.split(_.get(res, 'issueKind'), '^')
            detail['issueKindDetail'] = _.split(_.get(res, 'issueKindDetail'), '^')
            detail['newVersion'] = _.split(_.get(res, 'newVersion'), '^')
            detail['extConntoBrokers'] = _.split(_.get(res, 'extConntoBrokers'), '^')
            detail['_planReleaseList'] = _.map(_.get(res, 'planReleaseStoryList'), o => _.assign({}, o, { value: o.releaseid }))
            setFormData(detail)
            setEditFormData(detail)
            setSwitchDisabled(false)
            setTimeout(() => {
                if (infoRichEditorRef.current) {
                    // infoRichEditorRef.current.setContent(_.replace(_.get(detail, 'impact') || '', /\n/g, '<br/>'))
                    infoRichEditorRef.current.setContent(_.replace(_.get(detail, 'impact') || '', /\n/g, ''))
                }
                if (resolveRef.current) {
                    // resolveRef.current.setContent(_.replace(_.get(detail, 'whyAndHow') || '', /\n/g, '<br/>'))
                    resolveRef.current.setContent(_.replace(_.get(detail, 'whyAndHow') || '', /\n/g, ''))
                }
                if (optStepRef.current) {
                    // optStepRef.current.setContent(_.replace(_.get(detail, 'optStep') || '', /\n/g, '<br/>'))
                    optStepRef.current.setContent(_.replace(_.get(detail, 'optStep') || '', /\n/g, ''))
                }
                if (logListRef.current) {
                    // logListRef.current.setContent(_.replace(_.get(detail, 'logList') || '', /\n/g, '<br/>'))
                    logListRef.current.setContent(_.replace(_.get(detail, 'logList') || '', /\n/g, ''))
                }
                if (customerRecordRef.current) {
                    // customerRecordRef.current.setContent(_.replace(_.get(detail, 'communicateNote') || '', /\n/g, '<br/>'))
                    customerRecordRef.current.setContent(_.replace(_.get(detail, 'communicateNote') || '', /\n/g, ''))
                }
            }, 1000)
            setId(res.id)
            setContentLoading(false)
        }).catch(err => {
            setContentLoading(false)
            setSwitchDisabled(false)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [id, getDetail, rethinkImproveTemplate, CSIssueId, getUnderApprobal])

    const switchMode = useCallback((resetDesc = false) => {
        if (resetDesc) {
            refreshRichTextRef()
        }
        setEditFormData(formData)
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [refreshRichTextRef, formData])

    const canSubmit = useMemo(() => {
        if (!_.get(editFormData, 'shortDesc')) return false
        if (!_.get(editFormData, 'currentUser') && !isClose) return false
        return !_.some(_.values(editFormError), x => x)
    }, [editFormData, editFormError, isClose])

    const submit = useCallback((flag = true, filterArray = []) => {
        if (confirmLoading) return
        if (infoRichEditorRef.current?.loading || resolveRef.current?.loading) {
            return Messager.show("图片上传中，请稍后保存")
        }
        if (JSON.stringify(editFormData) === JSON.stringify(formData)
            && checkRichTextRequired(infoRichEditorRef.current.getContent(), _.get(formData, 'impact'))
            && checkRichTextRequired(resolveRef.current.getContent(), _.get(formData, 'whyAndHow'))
            && checkRichTextRequired(optStepRef.current.getContent(), _.get(formData, 'optStep'))
            && checkRichTextRequired(logListRef.current.getContent(), _.get(formData, 'logList'))
            && checkRichTextRequired(customerRecordRef.current.getContent(), _.get(formData, 'communicateNote'))
        ) {
            switchMode()
            return Messager.show('无变更')
        }

        if (!_.isEmpty(editFormData?._planReleaseList) && flag) {
            const valuses = formData.planReleaseIdList

            const findSealPlate = _.filter(editFormData?._planReleaseList, o => (!_.includes(valuses, o?.value) && o?.state === 'C'))
            if (!_.isEmpty(findSealPlate)) {
                setShowJoinPlanVersion(findSealPlate)
                return
            }
        }
        if (['08', '03', '04', '05', '06', '10'].includes(editFormData?.issueStatus) && checkRichTextRequired(resolveRef.current.getContent(), _.get(whyHowRes, 'demoValue'))) {
            setActiveKey('analysis')
            return Messager.show('请填写【分析与方案】中的解决方案')
        }
        const { complexityRemarks = null, severityRemarks = null } = editFormData
        const postParams = { ..._.omit(editFormData, ['complexityRemarks', 'severityRemarks']) }
        const rootType = _.split(_.get(editFormData, 'rootType'), ',')
        if (!isNil(complexityRemarks) || !isNil(severityRemarks)) {
            const severity = isNil(severityRemarks) ? null : `严重程度变更理由：${severityRemarks}`
            const complexity = isNil(complexityRemarks) ? null : `是否低级问题变更理由：${complexityRemarks}`
            postParams['remarks'] = _.join(_.compact([severity, complexity]), ' / ')
        }
        postParams['rootType'] = rootType[_.size(rootType) - 1]
        postParams['influence'] = _.join(_.get(editFormData, 'influence'), '^')
        postParams['issueKind'] = _.join(_.get(editFormData, 'issueKind'), '^')
        postParams['issueKindDetail'] = _.join(_.get(editFormData, 'issueKindDetail'), '^')
        postParams['newVersion'] = _.join(_.get(editFormData, 'newVersion'), '^')
        postParams['extConntoBrokers'] = _.join(_.get(editFormData, 'extConntoBrokers'), '^')
        postParams['impact'] = _.replace(infoRichEditorRef.current.getContent(), /\n/g, '')
        postParams['whyAndHow'] = _.replace(resolveRef.current.getContent(), /\n/g, '')
        postParams['optStep'] = _.replace(optStepRef.current.getContent(), /\n/g, '')
        postParams['logList'] = _.replace(logListRef.current.getContent(), /\n/g, '')
        postParams['communicateNote'] = _.replace(customerRecordRef.current.getContent(), /\n/g, '')
        setConfirmLoading(true)
        submitPost('/issue/detailModify', _.omit(postParams, ['currentUser'])).then(() => {
            const releaseParams = {
                storyid: id,
                type: 'ISSUE',
                releaseidList: _.get(editFormData, 'releaseIdList')
            }
            const values = _.map(_.concat(filterArray, planUnderApprobal), 'releaseId')
            const filterArr = _.filter(_.compact(_.get(editFormData, '_planReleaseList')), o => !_.includes(values, o.value))
            const planReleaseParams = {
                storyid: id,
                type: 'PLANISSUE',
                releaseidList: _.map(filterArr, 'value')
            }
            const url = '/ReleaseStory/updateForReleaseStory'
            Promise.all([submitPost(url, releaseParams), submitPost(url, planReleaseParams)])
                .then(() => {
                    refreshPlanVersion()
                    if (_.get(formData, 'currentUser') === _.get(editFormData, 'currentUser')) {
                        afterRefresh()
                    } else {
                        const currentUserParams = {
                            businessId: _.get(editFormData, 'id'),
                            businessType: 'issue',
                            funcCode: FUNC_CODE,
                            currentUser: _.get(editFormData, 'currentUser'),
                            title: _.get(editFormData, 'shortDesc')
                        }
                        submitPost('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                            afterRefresh()
                        }).catch(err => {
                            Messager.show(err._message, { icon: 'error' })
                            setConfirmLoading(false)
                        })
                    }
                }).catch(err => {
                    Messager.show(err._message, { icon: 'error' })
                    setConfirmLoading(false)
                })
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setConfirmLoading(false)
        })
        function afterRefresh() {
            setSummaryReasons(x => {
                return _.assign(..._.map(x, (v, k) => ({ [k]: false })))
            })
            setConfirmLoading(false)
            Messager.show('修改成功', { icon: 'success' })
            refreshDetail(id)
            switchMode()
            if (refreshViewList) {
                refreshViewList()
            }
            updateDoc()
            updateStage()
        }
    }, [editFormData, formData, id, refreshDetail, refreshViewList, submitPost, switchMode, whyHowRes, confirmLoading, planUnderApprobal, refreshPlanVersion])

    const allVersionOptions = useMemo(() => {
        const allVersion = _.map(allVersionOptionsRes, x => ({ text: x.releaseid, value: x.releaseid }))
        const allVersionValueList = _.map(allVersion, 'value')
        const oldVersionId = _.get(formData, 'versionId')
        if (_.isNil(oldVersionId)) {
            return allVersion
        } else if (_.includes(allVersionValueList, oldVersionId)) {
            return allVersion
        } else {
            return _.concat([{ text: oldVersionId, value: oldVersionId }], allVersion)
        }
    }, [allVersionOptionsRes, formData])

    const refreshPart = useCallback(() => {
        refreshDetail(id)
        refreshViewList()
        updateDoc()
    }, [id, refreshDetail, refreshViewList])

    const [dutyAffirmAuth, showCodeCommitTab] = useFuncCode(['1903', '1915'])

    // const updateJira = useCallback(()=>{
    //     const params = {
    //         ids: [_.get(editFormData,'issueId')],
    //         flag: '2'
    //     }
    //     updateJiraDoFetch('/jira/issue/push',params).then(()=>{
    //         Messager.show('同步成功', { icon: 'success' })
    //     }).catch(err => Messager.show(err._message, { icon: 'error' }))
    // },[editFormData, updateJiraDoFetch])

    const defaultDefectParams = useMemo(() => {
        return { 'test_defect.issueId': id, 'test_defect.storyId': null }
    }, [id])

    const defaultUseCaseParams = useMemo(() => {
        return { 'View_test_caseRelated_Issue.relateId': _.get(formData, 'issueId'), 'View_test_caseRelated_Req.relateId': null, 'test_defect_case_related.defectId': null }
    }, [formData])

    useEffect(() => {
        const dialog = document.querySelector('.issue-detail-content')
        dialog.addEventListener('click', () => setCommentPopoverVisible(false))
    }, [])

    const outerVersionOptions = useMemo(() => {
        const versionList = _.get(formData, 'releaseIdList')
        return _.map(versionList, item => {
            const findItem = _.find(allVersionRes, x => x.releaseId === item)
            return {
                text: findItem?.releaseName,
                value: findItem?.releaseId,
            }
        })
    }, [formData, allVersionRes])

    return <div className={'issue-detail-content flex-y'}>
        <div className="mock-dialog-header flex">
            <div className="dialog-title">
                ISSUE详情
            </div>
            <div className="mock-right-header flex center-y">
                {
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'-task'} className={`header-edit-text-icon`} text={'创建研发任务'} onClick={() => setShowAddTrack(true)} />
                    </span>
                }
                {/*<span style={{marginRight: 16}}>*/}
                {/*    <TextIconBtn icon={'daoru'} className={`header-edit-text-icon`} text={'同步到jira'} onClick={()=> {*/}
                {/*        if(_.get(editFormData,'jiraId')){*/}
                {/*            updateJira()*/}
                {/*        }else{*/}
                {/*            setShowSyncJira(true)*/}
                {/*        }*/}
                {/*    }}/>*/}
                {/*</span>*/}
                {
                    (dutyAffirmAuth && !_.isNil(dutyAffirmId)) &&
                    <span style={{ marginRight: 16 }}>
                        <Popover
                            destroyTooltipOnHide={true}
                            content={<WorkFlowChangePop defaultParams={{ issueId: id }} businessId={dutyAffirmId} close={() => setShowWorkFlowPop(null)} refreshList={() => {
                                refreshDetail(id)
                            }} funcCode={'59'} businessType={'response'} />}
                            onOpenChange={visible => {
                                if (!visible) {
                                    setShowWorkFlowPop(null)
                                }
                            }}
                            trigger={'click'}
                            placement="bottomRight"
                            open={showWorkFlowPop}
                        >
                            <TextIconBtn icon={'fuhe1'}
                                onClick={() => setShowWorkFlowPop(true)}
                                className={`header-edit-text-icon`} text={'责任认定'} />
                        </Popover>
                    </span>
                }
                {/*{*/}
                {/*    isFuncCodeQaCheck &&*/}
                {/*    <span style={{ marginRight: 16 }}>*/}
                {/*        <TextIconBtn icon={'QAyanshou'} className={`header-edit-text-icon`} text={'QA检查'} onClick={() => setOpenQaCheck(true)} />*/}
                {/*    </span>*/}
                {/*}*/}
                <span style={{ marginRight: 16 }}>
                    <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                        switchMode(true)
                        // setEditFormData(formData)
                        refreshDetail(id)
                    }} />
                </span>
                {
                    (!CSIssueId) &&
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                }
            </div>
        </div>
        <IssueDetailHeader {...{ editFormData, isDetail, setEditFormData, setActiveKey, allUserRes, id, refreshViewList, refreshDetail, updateDynamic, setIsClose }} />
        <div className="issue-detail-main-panel flex">
            <div className="issue-detail-left-panel">
                <Tabs activeKey={activeKey} onChange={setActiveKey}>
                    <TabPane tab='详细信息' key={'info'} forceRender={true}>
                        <IssueInfo {...{ infoRichEditorRef, isDetail, editFormData }} />
                    </TabPane>
                    <TabPane tab='分析与方案' key={'analysis'} forceRender={true}>
                        <IssueAnalysis initFormData={formData} {...{
                            isDetail, editFormData, setEditFormData, allUserRes,
                            resolveRef, editFormError, setEditFormError, allVersionOptions, id, refreshReleaseList, underApprobal, planUnderApprobal, isAll, summaryReasons, setSummaryReasons, isReasonChange, leftAllProjectOptions, isEditIssueKind
                        }} />
                    </TabPane>
                    <TabPane tab='阶段' key={'stage'}>
                        <RequirementStage reqInfo={formData} editAuth={true} bizType={'issue'} {...{ id, allUserRes, updateDoc, updateDynamic, countStage, outerVersionOptions }} />
                    </TabPane>
                    <TabPane tab='缺陷' key={'defect'}>
                        <PublicDefect defaultParams={defaultDefectParams} addDefaultParams={{
                            issueId: _.get(formData, 'id'),
                            issueIdName: _.get(formData, 'issueId'),
                            productId: _.get(formData, 'productName'),
                            releaseId: _.size(_.get(formData, 'releaseIdList', [])) === 1 ? _.head(_.get(formData, 'releaseIdList')) : null,
                            // fixVersion: _.size(_.get(formData, 'releaseIdList', [])) === 1 ? _.head(_.get(formData, 'releaseIdList')) : null,
                            fixProjectIdList: _.size(_.get(formData, 'releaseIdList', [])) === 1 ? [_.get(_.head(_.get(formData, 'releaseStoryList')), 'projectId')] : null,
                            projectId: _.size(_.get(formData, 'releaseIdList', [])) === 1 ? _.get(_.head(_.get(formData, 'releaseStoryList')), 'projectId') : null,
                        }} />
                    </TabPane>
                    <TabPane tab='用例' key={'useCase'}>
                        <PublicUseCase
                            defaultParams={defaultUseCaseParams}
                            addDefaultParams={{
                                productId: _.get(formData, 'productName'),
                                moduleId: _.get(formData, 'prodsubsysid'),
                            }}
                            addDefaultIssue={[{
                                id: _.get(formData, 'id'),
                            }]}
                        />
                    </TabPane>
                    <TabPane tab='故障重现' key={'reproduceBug'} forceRender={true}>
                        <ReproduceBug {...{ isDetail, editFormData, setEditFormData, allUserRes, optStepRef, logListRef }} />
                    </TabPane>
                    <TabPane tab='客户沟通纪录' key={'customerRecord'} forceRender={true}>
                        <CustomerRecord {...{ isDetail, editFormData, setEditFormData, customerRecordRef }} />
                    </TabPane>
                    <TabPane tab='测试分析' key={'testAnalysis'} forceRender={true}>
                        <TestAnalysis {...{ isDetail, editFormData, setEditFormData, allUserRes }} />
                    </TabPane>
                    <TabPane tab='根因分析' key={'improve'} forceRender={true}>
                        <RootCauseAnalysis {...{ id, isDetail, editFormData, setEditFormData, refresh: refreshPart, allUserRes, statusOpt: reviewStatusOpt }} />
                    </TabPane>
                    <TabPane tab={
                        <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                            附件
                        </Badge>
                    } key={'doc'} forceRender={true}>
                        <div className="file-wrap" key={countDoc}>
                            <RequirementUploadArea funcCode={FUNC_CODE} afterDelRefresh={updateStage} {...{ id, mode, setFileCount, allUserRes }} />
                        </div>
                    </TabPane>
                    <TabPane tab='关联研发任务' key={'trace'}>
                        <div className={'dynamic-wrap'} key={countTracer}>
                            <AssociatedTracking id={id} issueId={_.get(formData, 'issueId')} defaultData={formData} refreshDetail={(id) => {
                                refreshDetail(id)
                                refreshTracerIdList()
                            }} />
                        </div>
                    </TabPane>
                    <TabPane tab='关联服务单' key={'serv'}>
                        <AssociatedService {...{ productOptions, id, allUserRes, isFuncCodeApply }} defaultData={formData} issueId={_.get(formData, 'issueId')} />
                    </TabPane>
                    {
                        showCodeCommitTab &&
                        <TabPane tab='代码提交记录' key={'codeCommit'}>
                            <CodeCommitList bizIdList={codeCommitBizList} />
                        </TabPane>
                    }
                    <TabPane tab='动态' key={'dynamic'}>
                        <div className={'dynamic-wrap'} key={countDynamic}>
                            <ChangeRecord name='ISSUE' funcCode={FUNC_CODE} referenceId={id} id={_.get(editFormData, 'issueId')} title={_.get(editFormData, 'shortDesc')} linkUrl={`/issue?initId=${id}`} />
                        </div>
                    </TabPane>
                    {/* <TabPane tab='QA' key={'QA'} forceRender={true}>
                        <QaPage {...{ editFormData, allUserRes, statusOpt: reviewStatusOpt }} />
                    </TabPane> */}
                </Tabs>
            </div>
            <IssueDetailRightPanel {...{
                isDetail, editFormData, setEditFormData, allUserRes, editFormError, setEditFormError,
                productTreeOptions, productOptions, moduleOptions, deptUserListOptions, allVersionOptionsRes,
                allVersionOptions, formData, moduleOptionsRes, summaryReasons, setSummaryReasons, isReasonChange, isShowCause, isEditIssueKind
            }} />
            {(contentLoading || confirmLoading) && <Loader fill />}
        </div>
        <div className="mock-footer flex center-y">
            <div />
            {
                _.isEmpty(showChildList) ? <div /> :
                    <div className="footer-switch flex center-y">
                        <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                            onClick={() => {
                                if (switchDisabled) return
                                if (!_.includes([-1, 0], currentIndex)) {
                                    switchCurrentItem('previous')
                                }
                            }}>
                            <Icon name='xiangqian' />&nbsp;上一个
                        </div>
                        <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                            onClick={() => {
                                if (switchDisabled) return
                                if (!(currentIndex === showChildList?.length - 1)) {
                                    switchCurrentItem('next')
                                }
                            }}>
                            下一个&nbsp;<Icon name='xianghou' />
                        </div>
                        <div className="list-num">
                            {currentIndex + 1}/{showChildList?.length}
                        </div>
                        <Popover content={<PopoverComment referenceId={_.get(formData, 'id')} funcCode={FUNC_CODE} {...{ allUserRes, commentText, setCommentText }}
                            title={`【${_.get(formData, 'issueId')}】${_.get(formData, 'shortDesc')}`} linkUrl={`/issue?initId=${_.get(formData, 'id')}`}
                            close={() => setCommentPopoverVisible(false)} />}
                            trigger="click" placement="topRight" open={commentPopoverVisible}>
                            <Tooltip title="评论信息">
                                <div className={'comment-wrap flex center'} onClick={() => setCommentPopoverVisible(x => !x)}>
                                    <Icon name={'zidingyishezhi1'} className={'comment-icon'} />
                                </div>
                            </Tooltip>
                        </Popover>
                    </div>
            }
            <div className="btn-group flex">
                {
                    isDetail && !CSIssueId &&
                    <Button normal onClick={close}>关闭</Button>
                }
                {
                    !isDetail &&
                    <Button normal onClick={() => {
                        switchMode(true)
                        setEditFormData(formData)
                    }}>取消</Button>
                }
                {
                    !isDetail &&
                    <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                }
            </div>
        </div>
        {/* <DutyAffirmDialog close={() => setShowDutyAffirmDialog(false)} visible={showDutyAffirmDialog}
            {...{ id, editFormData, refreshDetail, deptOptionsRes, formData, updateDynamic }} /> */}
        {openQaCheck && <QaCheckDialog close={() => setOpenQaCheck(false)} {...{ id: _.get(currentInfo, 'id'), refresh: refreshPart }} />}
        {
            showSyncJira &&
            <SyncToJiraDialog close={() => setShowSyncJira(false)} id={_.get(editFormData, 'issueId')} flag={'issue'}
                afterRefresh={
                    () => {
                        refreshDetail(id)
                        refreshViewList()
                    }
                }
            />
        }
        {
            showAddTrack && <TrackingAddDialog close={() => setShowAddTrack(false)}
                afterRefresh={() => updateTracer()}
                initForm={{
                    bugType: 'M',
                    tracerPri: _.get(convertTracerPri, _.get(formData, 'effectGrade')),
                    productId: _.get(formData, 'productName'),
                    subSysId: _.get(formData, 'prodsubsysid'),
                    bugSource: 'S1',
                    projectId: _.get(formData, 'custId'),
                    securityName: _.get(formData, 'custId') ? [_.get(formData, 'custId')] : null,
                    bugDesc: _.get(formData, 'impact'),
                    fixDesc: _.get(formData, 'whyAndHow'),
                    issueId: _.get(formData, 'issueId'),
                    tracerTitle: _.get(formData, 'shortDesc'),
                }}
            />
        }
        {
            showJoinPlanVersion &&
            <JoinPlanVersion
                id={id}
                type={'PLANISSUE'}
                close={() => setShowJoinPlanVersion(false)}
                afterRefresh={(flag) => {
                    refreshPlanVersion()
                    refreshReleaseList(flag)
                }}
                submit={submit}
                showJoinPlanVersion={showJoinPlanVersion}
            />
        }
    </div>
}

const convertTracerPri = {
    '01': 'T',
    '02': 'A',
    '03': 'B',
    '04': 'B',
    '05': 'C',
}

function checkRichTextRequired(value1 = '', value2 = '') {
    return (value1 || '') === (value2 || '')
    // return purifyRichText(value1 || '') === purifyRichText(value2 || '')
    // function purifyRichText(richText = '') {
    //     // const regex = /(<([^>]+)>)/ig
    //     // return richText.replace(regex, "")
    //     //     .replace(/\s/g,"")
    //     //     .replace(/&nbsp;/g,"")
    //     // richText.replace(/<p>/g,"")
    //     // richText.replace(/</p>/g,"")
    //     return richText.replace(/\s/g, "")
    //         .replace(/<p>/g, "")
    //         .replace(/<\/p>/g, "")
    //         .replace(/<br\/>/g, "")
    //         .replace(/&nbsp;/g, "")
    // }
}

export default IssueDetailContent;
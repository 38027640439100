import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Dialog, Loader, Messager } from 'rootnet-ui'
import './ReqStageUpdateDialog.scss'
import { TextIconBtn } from "../../common/TextIconBtn";
import Icon from "../../../components/Icon";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import RequirementStageUpdateHeader
    from "../../requirementMgt/requirementDetailDialog/requirementStage/requirementStageUpdateDialog/requirementStageUpdateHeader/RequirementStageUpdateHeader";
import _ from "lodash";
import ChangeRecord from "../../common/ChangeRecord";
import RequirementStageInfo
    from "../../requirementMgt/requirementDetailDialog/requirementStage/requirementStageUpdateDialog/requirementStageInfo/RequirementStageInfo";
import { Tabs } from "antd";
import ConferenceRecordsAdd from "../../conferenceMgt/conferenceRecords/conferenceRecordsAdd/ConferenceRecordsAdd";
import ReqStageRelateConference
    from "../../requirementMgt/requirementDetailDialog/requirementStage/requirementStageUpdateDialog/reqStageRelateConference/ReqStageRelateConference";
import RequirementDetailDialog from "../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import { dateFormat } from "rootnet-core/dateFormat";
import IssueDetailDialog from "../../issueMgt/components/issueDetailDialog/IssueDetailDialog";
import { useFuncCode } from "../../common/commonMethod";
import gd from "../../../base/global";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import DevListDetailDialog from '../../devListMgt/devListDetailDialog';
import ConcernedIcon from '../../common/concernedIcon';
import { isNil } from '../../appraise/components/method';

const milestoneFlagOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

const { TabPane } = Tabs;
const DIALOG_TITLE = {
    'edit': '编辑阶段',
    'detail': '阶段详情',
}

const STAGE_RELATE_CONFERENCE_TYPE = {
    'Req-Review': {
        type: 'REQ',
        title: '【需求评审】'
    },
    'Design-Detailed': {
        type: 'DS',
        title: '【设计评审】'
    },
    'Design-Outline': {
        type: 'DS',
        title: '【设计评审】'
    },
    'Design-Test': {
        type: 'TC',
        title: '【测试用例评审】'
    },
}

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=reqMileStone',
    'common/globalconst?globalConst=PlanStatus',
]

const initDeliveryList = [{ id: 1, value: null, type: 'add' }]

function ReqStageUpdateDialog(props) {
    const { close, currentInfo, refreshList = () => { }, switchCurrentItem, showChildList, currentIndex } = props
    const { id: stageId, mode: initMode } = currentInfo
    const [mode, setMode] = useState(initMode || 'detail')
    const [editFormData, setEditFormData] = useState()
    const [formError, setFormError] = useState()
    const { doFetch: update } = usePost()
    const [showAddConference, setShowAddConference] = useState(false)
    const { data: initDetail, doFetch: getDetail } = useGet()
    const [activeKey, setActiveKey] = useState('info')
    const [countInnerDynamic, updateInnerDynamic] = useReducer((x) => x + 1, 0)
    const [countConference, updateConference] = useReducer((x) => x + 1, 1000)
    const [switchDisabled, setSwitchDisabled] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [bizType, setBizType] = useState()
    const [countStatus, updateStatus] = useReducer((x) => x + 1, 0)
    const [workFlowText, setWorkFlowText] = useState()
    const [deliveryList, setDeliveryList] = useState(initDeliveryList)
    const { data: allUserRes } = useGet('/common/userinfo')
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [stageOptions, statusOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const refreshDetail = useCallback(() => {
        setSwitchDisabled(true)
        getDetail('/story/stage/info?stageId=' + stageId).then(res => {
            setEditFormData(res)
            setSwitchDisabled(false)
            setBizType(_.get(res, 'bizType'))
            updateStatus()
            setDeliveryList(_.isEmpty(_.get(res, 'delivery')) ? initDeliveryList : _.get(res, 'delivery'))
        }).catch(err => {
            setSwitchDisabled(false)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getDetail, stageId])

    useEffect(() => {
        if (initMode === 'add') {
            setEditFormData({ milestoneflag: 'N' })
        } else {
            refreshDetail()
        }
    }, [initMode, refreshDetail])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const switchMode = useCallback((reset = false) => {
        if (reset) {
            setEditFormData(initDetail)
            setDeliveryList(_.isEmpty(_.get(initDetail, 'delivery')) ? initDeliveryList : _.get(initDetail, 'delivery'))
        }
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [initDetail])

    const [PMAuth] = useFuncCode(['152603'])

    const canEditPlan = useMemo(() => {
        if (mode === 'add') return true
        if (PMAuth) return true
        const canEditPersonList = [_.get(initDetail, 'createuser'), _.get(initDetail, 'owner'), _.get(initDetail, 'currentUser')]
        if (workFlowText === '初始化' && _.includes(canEditPersonList, gd.User.operator_id)) {
            return true
        }
        return false
    }, [workFlowText, PMAuth, initDetail, mode])

    const canSubmit = useMemo(() => {
        if (!_.every(deliveryList, o => !isNil(o.delivery))) return false
        if (!_.get(editFormData, 'title')) {
            return false
        }
        if (!_.get(editFormData, 'status')) {
            return false
        }
        return !_.some(_.values(formError), x => x)
    }, [formError, editFormData, deliveryList])

    const submit = useCallback(() => {
        const submitForm = _.omit(initDetail, 'relatedocument')
        const submitEditForm = _.omit(editFormData, 'relatedocument')
        if (JSON.stringify(submitForm) === JSON.stringify(submitEditForm)) {
            if (JSON.stringify(_.get(initDetail, 'delivery')) === JSON.stringify(deliveryList)) {
                setMode('detail')
                // return Messager.show('无变更')
                return
            }
        }
        if (submitLoading) return
        setSubmitLoading(true)
        let completeTimeActual = _.get(editFormData, 'completetimeactual')
        if (_.isNil(completeTimeActual) && _.get(editFormData, 'status') === '03') {
            completeTimeActual = dateFormat('YYYYMMDD', new Date()) + '000000'
        }

        // 版本计划
        let recordList = []
        const initVersionList = _.get(initDetail, 'releaseIdList')
        const currentVersionList = _.get(editFormData, 'releaseIdList')
        _.forEach(initVersionList, x => {
            if (!_.includes(currentVersionList, x)) {
                const delId = _.get(_.find(_.get(editFormData, 'recordList'), item => item.releaseid === x), 'id')
                recordList.push({
                    optionType: 'DEL',
                    id: delId,
                })
            }
        })
        _.forEach(currentVersionList, x => {
            if (!_.includes(initVersionList, x)) {
                recordList.push({
                    optionType: 'ADD',
                    releaseid: x,
                    storyid: _.get(editFormData, 'id'),
                })
            }
        })
        const addItems = _.filter(deliveryList, o => o.type === 'add')
        const editItems = _.filter(deliveryList, o => o.type === 'update')
        const fileterList = _.filter(_.get(initDetail, 'delivery'), o => !_.includes(_.map(deliveryList, 'id'), o.id))
        const delItems = _.map(fileterList, o => ({ ...o, type: 'del' }))
        const deliveryData = _.map(_.concat(addItems, editItems, delItems), o => ({ ...o, stageId }))
        const postParams = _.assign({}, editFormData, {
            workloadplan: _.get(editFormData, 'workloadplan') ? N2Float(_.get(editFormData, 'workloadplan')) : null,
            workloadactual: _.get(editFormData, 'workloadactual') ? N2Float(_.get(editFormData, 'workloadactual')) : null,
            completetimeactual: completeTimeActual,
            recordList,
        }, mode === 'add' && { delivery: deliveryList, })
        const url = mode === 'add' ? '/story/stage/add' : '/story/stage/update'
        update(url, postParams).then(() => {
            if (mode === 'add') {
                setSubmitLoading(false)
                Messager.show('添加成功')
                refreshList()
                close()
            } else {
                update('/story/stage/updateDelivery', deliveryData)
                    .catch(err => Messager.show(err.Message, { icon: 'error' }))
                const currentUserParams = {
                    businessId: _.get(editFormData, 'id'),
                    businessType: 'stage',
                    funcCode: '1526',
                    currentUser: _.get(editFormData, 'currentUser'),
                    title: _.get(editFormData, 'title')
                }
                update('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                    Messager.show('修改成功', { icon: 'success' })
                    refreshList()
                    refreshDetail()
                    updateInnerDynamic()
                    setMode('detail')
                    setSubmitLoading(false)
                }).catch(err => {
                    setSubmitLoading(false)
                    Messager.show(err.Message, { icon: 'error' });
                })
            }
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err.Message, { icon: 'error' });
        })
    }, [editFormData, mode, refreshList, close, update, refreshDetail, initDetail, updateInnerDynamic, submitLoading, deliveryList, stageId])

    const currentAddConferenceInfo = useMemo(() => {
        return _.get(STAGE_RELATE_CONFERENCE_TYPE, _.get(editFormData, 'stage'))
    }, [editFormData])

    const currentAddConferenceVersionText = useMemo(() => {
        const version = _.get(initDetail, 'storyReleaseList')
        if (_.isEmpty(version)) return ''
        return `【${_.join(version, ',')}】`
    }, [initDetail])

    const headerTagName = useMemo(() => {
        const name = {
            'req': '需求',
            'dev': '自提单',
            'issue': 'issue',
        }
        return _.get(name, bizType) || '-'
    }, [bizType])

    const versionOptions = useMemo(() => {
        return _.map(_.get(initDetail, 'storyReleaseStoryList'), x => ({
            text: x.releaseid,
            value: x.releaseid,
        }))
    }, [initDetail])

    return <Dialog className={'req-stage-update-dialog'} headerVisible={false} footerVisible={false}>
        <div className="content-wrap flex-y">
            <div className="mock-dialog-header flex center-y">
                <div className="dialog-title flex center-y">
                    <div>{DIALOG_TITLE[mode]}</div>
                    <div className="req-header flex center-y">
                        <div className={`req-tag ${bizType}`}>{headerTagName}</div>
                        <div className="req-title-wrap flex center-y">
                            <div className="req-title" onClick={() => {
                                setShowDetailDialog({
                                    module: bizType,
                                    id: _.get(editFormData, 'storyId')
                                })
                            }}>
                                {_.get(editFormData, 'storyTitle')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mock-right-header flex center-y">
                    <ConcernedIcon
                        top={0}
                        type='STAGE'
                        mode='detail'
                        referenceId={editFormData?.id}
                    />
                    {
                        mode !== 'add' &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'tianjia'} className={`header-edit-text-icon`} text={'创建会议纪要'} onClick={() => {
                                setShowAddConference(true)
                            }} />
                        </span>
                    }
                    {
                        mode !== 'add' &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                                switchMode(true)
                            }} />
                        </span>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <RequirementStageUpdateHeader id={stageId} refreshViewList={refreshList} updateDynamic={updateInnerDynamic}
                {...{
                    editFormData, isDetail, setEditFormData, statusOptions, mode,
                    allUserRes, refreshDetail, countStatus, setWorkFlowText
                }} />
            <div className="content-main-panel flex">
                {
                    submitLoading && <Loader fill />
                }
                <div className="detail-left-panel">
                    <Tabs activeKey={activeKey} onChange={setActiveKey}>
                        <TabPane tab='描述' key={'info'}>
                            <RequirementStageInfo stageObj={initDetail} storyId={_.get(initDetail, 'storyId')}
                                {...{
                                    editFormData, setEditFormData, formError, setFormError, stageOptions,
                                    milestoneFlagOptions, mode, isDetail, allUserRes, canEditPlan, versionOptions, deliveryList, setDeliveryList
                                }} />
                        </TabPane>
                        {
                            mode !== 'add' &&
                            <TabPane tab='会议纪要' key={'conference'}>
                                <div className={'conference-tab-wrap'} key={countConference}>
                                    <ReqStageRelateConference afterRefresh={refreshList}
                                        listUrl={'/story/stage/recordListByStage'}
                                        disassociateUrl={'/story/stage/disassociate/record'}
                                        relateUrl={'/story/stage/related/record'}
                                        {...{ stageId, updateInnerDynamic }} />
                                </div>
                            </TabPane>
                        }
                    </Tabs>
                </div>
                {
                    mode !== 'add' &&
                    <div className="dynamic-wrap" key={countInnerDynamic}>
                        {
                            !_.isNil(stageId) &&
                            <ChangeRecord name={'阶段任务'} funcCode={'1526'} referenceId={stageId} id={_.get(editFormData, 'stageId')}
                                title={_.get(editFormData, 'title')} followBtn={false} commentWidth={'90%'} />
                        }
                    </div>
                }
            </div>
            <div className="mock-footer flex center-y">
                <div />
                {
                    _.isEmpty(showChildList) ? <div /> :
                        <div className="footer-switch flex center-y">
                            <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (switchDisabled) return
                                    if (!_.includes([-1, 0], currentIndex)) {
                                        switchCurrentItem('previous')
                                    }
                                }}>
                                <Icon name='xiangqian' />&nbsp;上一个
                            </div>
                            <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (switchDisabled) return
                                    if (!(currentIndex === showChildList?.length - 1)) {
                                        switchCurrentItem('next')
                                    }
                                }}>
                                下一个&nbsp;<Icon name='xianghou' />
                            </div>
                            <div className="list-num">
                                {currentIndex + 1}/{showChildList?.length}
                            </div>
                        </div>
                }
                <div className="btn-group flex">
                    {
                        isDetail &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        !isDetail &&
                        <Button normal onClick={() => {
                            if (mode === 'add') {
                                close()
                            } else {
                                switchMode(true)
                            }
                        }}>取消</Button>
                    }
                    {
                        !isDetail &&
                        <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                    }
                </div>
            </div>
            {
                showAddConference &&
                <ConferenceRecordsAdd close={() => setShowAddConference(false)}
                    refreshList={() => {
                        updateInnerDynamic()
                        refreshList()
                        updateConference()
                    }}
                    initForm={{
                        sSubject: `${_.get(currentAddConferenceInfo, 'title') || ''}${currentAddConferenceVersionText}${_.get(initDetail, 'storyTitle') || ''}`,
                        sConferenceType: _.get(currentAddConferenceInfo, 'type') || null,
                        scustomer: _.get(initDetail, 'storyCustomer') || null,
                        stageSource: 'req',
                        stageId: stageId
                    }}
                />
            }
        </div>
        {
            _.get(showDetailDialog, 'module') === 'req' &&
            <RequirementDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'issue' &&
            <IssueDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'dev' &&
            <DevListDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
    </Dialog>
}

function N2Float(valueParams) {
    if (_.isNil(valueParams)) return ''
    let value = Math.round(parseFloat(valueParams) * 100) / 100;
    let s = value.toString().split(".");
    if (s.length === 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

export default ReqStageUpdateDialog;
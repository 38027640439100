import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import { Popover } from 'antd'
import { CheckBox } from 'rootnet-edit'
import { Card, Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertTableAlign from '../../common/view/convertTableAlign'
import findConvert from '../../common/view/findConvert'
import convertOptions from '../../common/ConvertOptions'
import WorkFlowChangePop from '../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { CustomizeFormInput } from '../../common/customizeOptions/CustomizeOptions'
import { Icon } from '../../../components'
import ConcernedIcon from '../../common/concernedIcon'
import { pathSearchFor } from '../../../utils/publicFun'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import { useFuncCode } from '../../common/commonMethod'
import { isNil } from '../../appraise/components/method'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import { API1 } from 'rootnet-core/base'
import { TextIconBtn } from '../../common/TextIconBtn'
import ViewArea from '../../common/view/ViewArea'
import DefectDataGrid from '../defect/controls/DefectDataGrid'
import LeftPanel from '../defect/controls/LeftPanel'
import RightPanel from '../defect/controls/RightPanel'
import DefectUpdateDialog from '../defect/controls/DefectUpdateDialog'
import DefectDelayVersion from '../defect/controls/delayVersion/DefectDelayVersion'
import ExportApiDialog from '../../common/ExportApiDialog'

const PRIMARY_KEY = 'defectId'
const ENTER_DETAIL_FIELD = 'title'

// 'productId'
const EDITABLE_FIELD = []
const SEVERITY_COLOR = {
  '01': '#F5221B',
  '02': '#FF8C00',
  '03': '#FFC20E',
  '04': '#5477ff',
  '05': '#A9A9A9',
}
const PRIORITY_COLOR = {
  '01': '#F85E5E',
  '02': '#93C36B',
  '03': '#97AFD0',
}
const STATUS_COLOR = {
  'new': '#93C36B',
  'closed': '#A9A9A9',
  'suspended': '#A9A9A9',
  'rejected': '#A9A9A9',
  'postponed': '#A9A9A9',
  'in_progress': '#5477ff',
  'resolved': '#5477ff',
  'verified': '#5477ff',
  'reopened': '#5477ff',
}

const SPECIAL_FIELD_CONVERT = {
  'severity': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: SEVERITY_COLOR[value], border: `1px solid ${SEVERITY_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'priority': (value, showValue) => {
    return <div className={'priority-field'} style={{ background: PRIORITY_COLOR[value], padding: '1px 12px 2px', color: '#fff', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'status': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: STATUS_COLOR[value], border: `1px solid ${STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

const REPLACE_POST_PARAMS = {
  'currentOwner': 'current_owners'
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentId, setMode, editInfo, setEditInfo,
    updateShowList, workFlowId, setWorkFlowId, refreshList, selectedIdList, setSelectedIdList, delayAuth, isSelectedAll, list, currentTableValueRef } = props
  const { editId = null, editField = null } = editInfo || {}

  const customColumns = [
    // { header: '追加参数', bind:'', width: 150 }
  ]
  return [
    {
      header: <div className={clsx('fist-col flex center', { 'selected': isSelectedAll })}>
        <div className={'check-box'} onClick={() => {
          if (isSelectedAll) {
            setSelectedIdList([])
          } else {
            setSelectedIdList(_.map(list, 'id'))
          }
        }}>
          <CheckBox value={isSelectedAll} />
        </div>
        <div className={'index-num'}>
          #
        </div>
      </div>
      , width: 40, align: 'center', convert: (v, o, i) => {
        const id = _.get(o, 'id')
        return delayAuth ? <div className={clsx('fist-col flex center', { 'selected': _.includes(selectedIdList, id) })}>
          <div className={'check-box'}>
            <CheckBox value={_.includes(selectedIdList, id)} onChange={() => {
              if (_.includes(selectedIdList, id)) {
                setSelectedIdList(old => _.filter(old, x => x !== id))
              } else {
                setSelectedIdList(old => _.concat(old, [id]))
              }
            }} />
          </div>
          <div className={'index-num'}>
            {i + 1}
          </div>
        </div> : i + 1
      }
    },
  ].concat(_.map(fieldList, x => handleColumn(x)),
    // [{header: '操作', bind: '', align: 'center', width: 120, isTransfer: false, convert: (v,o)=>{
    //     return (_.get(o,'_level') === 0 && !_.get(o,'_oneChild')) ? '-' :renderOperation(v,o)
    //  }}]
  )

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return _.find(customColumns, x => x.bind === fieldItem.columnId) || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        fieldId: fieldItem.fieldId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId === ENTER_DETAIL_FIELD ? false : true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
    }
    if (tableField === 'workflow_business.factorId') {
      const workFlowStatusOptions = _.find(convertCollection, (v, k) => k === tableField)
      const workflowStatusColor = convertOptions(v, workFlowStatusOptions, 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={FUNC_CODE} businessType={'defect'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={!_.isNil(o.id) && o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowStatusColor, border: `1px solid ${workflowStatusColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => currentTableValueRef.current = handleValue}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentId(o.id)
          setMode('detail')
        }}>{showValue}</div>
        <Icon name={'bianji2'} className="enter-detail-field-edit-icon" onClick={() => onEditFieldClick({ o, fieldItem })} />
        <ConcernedIcon
          left={8}
          type='DEFECT'
          referenceId={o?.id}
          flag={_.get(o, 'perColl', 'N') === 'Y'}
        />
      </div>
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'test_defect.title'), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }

  // function renderOperation(v, o) {
  //     const operateOption = [
  //         {
  //             text: '详情',
  //             mode: 'detail',
  //             onClick: ()=>{
  //                 setCurrentId(o.id)
  //                 setMode('detail')
  //             }
  //         },
  //         {
  //             text: '编辑',
  //             mode: 'edit',
  //             onClick: ()=>{
  //                 setCurrentId(o.id)
  //                 setMode('edit')
  //             }
  //         }
  //     ];
  //     return <OperationList options={operateOption}/>;
  // }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: false,
  autoFill: true,
});

const optionsUrl = [
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
]

const FUNC_CODE = '050406'

export default function HistoryTestDefect(props) {
  const { location } = props;
  const { initId = null } = useMemo(() => pathSearchFor(location.search), [location]);
  const [params, setParams] = useState()
  const [isTable] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [factor, setFactor] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [selectedIdList, setSelectedIdList] = useState([])
  const afterViewRefresh = useCallback(() => { setSelectedIdList([]) }, [])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, afterRefresh: afterViewRefresh })
  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])
  const [currentId, setCurrentId] = useState(initId)
  const [mode, setMode] = useState(_.isNil(initId) ? null : 'detail')
  const [sortConfig, setSortConfig] = useState()
  const [pageSize, setPageSize] = useState(100)
  const [workFlowId, setWorkFlowId] = useState()
  const [showDialog, setShowDialog] = useState()
  const [isSelectedAll, setIsSelectedAll] = useState()

  // 表格编辑
  // const [currentTableValue, setCurrentTableValue] = useState()
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()

  const [delayAuth] = useFuncCode(['050408'])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    let postParams = {
      // defectId: primaryId,
      ids: [editInfo['editId']]
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    if (postParams['fieldName'] === 'title' && isNil(postParams['fieldValue'])) {
      setEditInfo(null)
      currentTableValueRef.current = null
      return
    }
    if (editInfo['fieldId'] === 'currentUser') {
      if (isNil(updateValue)) {
        setEditInfo(null)
        currentTableValueRef.current = null
        return
      }
      const currentUserParams = {
        businessId: _.get(editInfo, 'editId'),
        businessType: 'defect',
        funcCode: FUNC_CODE,
        currentUser: updateValue,
        title: _.get(editInfo, 'title')
      }
      editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    } else {
      editTable('/test_defect/updateSingle', postParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    }

    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable])

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection, getOptions, getColumns,
    columnsAppendParams: {
      setCurrentId, setMode, editInfo, setEditInfo, currentTableValueRef, updateShowList, workFlowId,
      setWorkFlowId, refreshList, selectedIdList, setSelectedIdList, delayAuth, isSelectedAll, list
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        const sortList = _.sortBy(data, x => x[sort.column])
        return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
      }
    }
  })

  useEffect(() => {
    if (_.isEmpty(list)) return setIsSelectedAll(false)
    setIsSelectedAll(_.size(selectedIdList) === _.size(list))
  }, [list, selectedIdList])

  const { data: optionsRes } = useGet(optionsUrl)

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  const [productOptions, moduleOptionsRes] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    return [productOptions, d3]
  }, [optionsRes])

  // 两栏左右滑动
  const [canMove, setCanMove] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState(350);
  const prevXRef = useRef(null);
  const panelRef = useRef(null);

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      const sortList = _.sortBy(showChildList, x => x[sortConfig.column])
      showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  const currentIndex = useMemo(() => {
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [showList, currentId])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentId(switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id)
  }, [showList, setCurrentId, currentIndex])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='daochu' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList])

  useEffect(() => {
    if (_.isEmpty(list)) return
    if (_.isNil(currentId)) {
      setCurrentId(_.get(_.head(list), 'id'))
    }
  }, [list, currentId])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const input = document.querySelector('.defect-data-grid-table').querySelector('.tooltip input')
    if (!_.isNil(input)) {
      input.focus()
      input.click()
    }
  }, [editInfo])

  return <div className='defect fill flex-y'>
    <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
      {...{
        getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
        currentViewId, setCurrentViewId, params, pageSize
      }} />
    <Card title={'历史缺陷信息列表'} className={`flex-y x-card-singlegrid ${isTable ? '' : 'is-not-table'}`} style={{ display: isFullScreen ? 'none' : '' }}
      loading={boxLoading} error={error} data={dataGridList} extra={extra}>
      {
        isTable && <DefectDataGrid {...{
          listRes, setParams, options, dataGridList, sortConfig, isTree, currentId,
          refreshList, setCurrentId, pageSize, setPageSize, selectedIdList
        }} />
      }
    </Card>
    {
      !isTable && <div className={`defect-panel flex ${isFullScreen ? 'defect-panel-full-screen' : ''} ${canMove ? 'resize' : ''}`}
        onMouseMove={handleMove} ref={panelRef}
        onMouseLeave={() => setCanMove(false)}
        onMouseUp={() => setCanMove(false)}>
        {!isFullScreen && <div className={'left-panel-remove-wrap flex'}>
          <div className={'left-panel-wrap'} style={{ width: htmlWidth }}>
            <LeftPanel {...{ listRes, fieldList, setParams, currentId, setCurrentId, convertCollection, pageSize, setPageSize }} />
          </div>
          <div className={clsx("line", { resize: canMove })}
            onMouseDown={() => setCanMove(true)} />
        </div>}
        <RightPanel {...{
          isFullScreen, setIsFullScreen, list, currentId, setCurrentId, convertCollection, refreshList,
          productOptions, moduleOptionsRes
        }} />
      </div>
    }
    {
      _.includes(['add', 'edit', 'detail'], mode) &&
      <DefectUpdateDialog close={() => setMode(null)} {...{
        convertCollection, mode, setMode, currentId, setCurrentId, refreshList,
        switchCurrentItem, currentIndex, showChildList, productOptions, moduleOptionsRes
      }} />
    }
    {
      mode === 'export' && <ExportApiDialog
        name='缺陷导出'
        close={() => setMode(null)}
        option={getBind(options)}
        url={'/test_case/export'}
        parameter={_.assign({}, { flag: 2 }, params)}
      />
    }
    {
      showDialog === 'delay' &&
      <DefectDelayVersion close={() => setShowDialog(null)} afterRefresh={() => {
        refreshList()
        setSelectedIdList([])
      }} ids={selectedIdList} />
    }
  </div>

  function handleMove(e) {
    if (!canMove) return;
    e.preventDefault();
    e.stopPropagation();
    const prevX = prevXRef.current;
    prevXRef.current = e.clientX
    if (prevX === null) return null;
    const offset = e.clientX - prevX;
    setHtmlWidth(x => {
      if (x + offset < 256) {
        prevXRef.current = prevX
        return 256
      } else if ((x + offset) > (panelRef.current.offsetWidth / 2)) {
        prevXRef.current = prevX
        return x
      } else {
        return x + offset
      }
    });
  }

}

function getBind(opt) {
  if (!opt) return []
  const option = _.cloneDeep(opt)
  _.forEach(option.columns, o => {
    if (o.bind) {
      o.bind = o.fieldId
    }
  })
  return option
}

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100,
  }
}

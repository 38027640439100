import React from 'react'
import { Dialog } from 'rootnet-ui'
import ManpowerDetailContent from './manpowerDetailContent'
import './index.scss'

export default function ManpowerDetailLog(props) {

  return (
    <Dialog
      headerVisible={false}
      footerVisible={false}
      className='manpower-detail-dialog'
    >
      <ManpowerDetailContent {...props} />
    </Dialog>
  )
}

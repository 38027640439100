import React from 'react'
import _ from 'lodash'
import { Input } from 'rootnet-edit'
import { Icon } from '../../../../components'


const month = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']

const option = (columns) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  autoFill: true,
  virtualized: true,
  columns,
})

function intToChinese(num) {
  const arr = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
  return `${arr[num]}月`
}

function combined(d, bind) {
  const sum = _.reduce(d, (acc, x) => {
    const num = _.isNaN(_.toNumber(x[bind])) ? 0 : _.toNumber(x[bind])
    return _.toNumber(acc) + num
  }, 0)
  return sum.toFixed(1)
}

export function workloadColumns(props) {
  const { isImport = false, editInfo, setEditInfo, editUpdate, setValue, isFuncCodeEdit, expanded, setExpanded, open, put } = props
  const { editId = null, editField = null } = editInfo || {}
  const importColumns = [
    { header: '工作分类', bind: 'workTypeName', width: 90, tooltip: true },
    { header: '模块', bind: 'moduleName', align: 'left', width: 180, tooltip: true },
  ].concat(
    _.map(month, (v, i) => ({
      header: intToChinese(i),
      bind: v,
      align: 'center',
      width: 90,
      tooltip: true,
      convert: (v, o) => removerNan(v)
    }))
  )
  const pageColumns = [
    {
      name: <div className='model-icon'>
        <span style={{ cursor: 'pointer' }} onClick={() => setExpanded(x => {
          if (!x) open()
          else put()
          return !x
        })}>
          <Icon name={expanded ? 'biaogeshouqi' : 'biaogezhankai'} />
        </span>
        <span style={{ marginLeft: 8 }}>模块</span>
      </div>, code: 'model', align: 'left', width: 180, lock: true,
    },
    { name: '总计', code: 'total', align: 'center', width: 90, lock: true, },
  ].concat(
    _.map(month, (v, i) => ({
      name: intToChinese(i),
      code: `workLoad${i + 1}`,
      align: 'center',
      width: 90,
      render: (v, o) => convertEditField(v, o, `id${i + 1}`)
    })))
  return isImport ? importColumns : pageColumns
  function removerNan(num) {
    return _.isNaN(_.toNumber(num)) ? 0 : _.toNumber(num).toFixed(1)
  }
  function convertEditField(v, o, bind) {
    if (!isFuncCodeEdit || _.isNaN(_.toNumber(v))) return v
    if (editId === o[bind] && editField === bind) {
      return <Input
        autoFocus
        defaultValue={v}
        type='number'
        digit={1}
        min={0}
        max={999999999}
        onChange={num => setValue(num)}
        onBlur={() => editUpdate(v, o[bind])}
        onEnter={() => editUpdate(v, o[bind])}
      />
    }
    return <div
      className='convert-edit-field-style'
      onClick={() => setEditInfo({ editId: o[bind], editField: bind })}
    >
      {v}
      <Icon className='edit-icon' name='bianji2' />
    </div>
  }
}
export function workloadGridOptsFor(summary = false) {
  return _.assign({
    nilText: '-',
    fixedLeft: 2,
    emptyText: '-',
    resizable: true,
    autoFill: true,
    virtualized: false,
  }, summary &&
  {
    summary: [
      [
        { header: '总计' },
        { header: d => combined(d, 'totals'), align: "center" },
      ].concat(
        _.map(month, (v, i) => ({
          header: d => combined(d, `workLoads${i + 1}`),
          align: "center"
        }))
      )
    ]
  })
}

export function personnelOption(props) {
  const columns = [
    {
      header: '姓名', bind: 'userName', align: 'left', width: 130, tooltip: true, convert: (v, o) => {
        if (o.indexValidFlag === 0) return <div style={{ color: '#bbbbbb' }}>{v}（已离职）</div>
        return v
      }
    },
    { header: '岗位', bind: 'postionName', align: 'center', width: 90, tooltip: true },
    { header: '职级', bind: 'techLevel', align: 'center', width: 60, tooltip: true },
    { header: '产出系数', bind: 'outputFactor', align: 'left', width: 90, tooltip: true },
    { header: '新人系数', bind: 'newUserFactor', align: 'left', width: 90, tooltip: true },
    { header: '综合系数', bind: 'convertFactor', align: 'left', width: 90, tooltip: true },
  ]
  return option(columns)
}

export function recruitmentOption(props) {
  const columns = [
    { header: '月份', bind: 'month', align: 'left', width: 80, tooltip: true },
    { header: '岗位', bind: 'positionName', align: 'center', width: 90, tooltip: true },
    { header: '职级', bind: 'techLevel', align: 'center', width: 60, tooltip: true },
    { header: '招聘个数', bind: 'planRecruit', align: 'left', width: 90, tooltip: true },
    { header: '可投入', bind: 'inputAvailable', align: 'left', width: 90, tooltip: true },
  ]
  return option(columns)
}
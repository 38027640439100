import React, { useCallback, useMemo, useState, Fragment } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { Dialog, Button, Messager } from 'rootnet-ui'
import { Form, FormInput, } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../common/ConvertOptions'
import { Icon } from '../../../../components'
import { filterDisplayFlagOptions } from '../../../common/publicFunc'
import formList from './formList'
import { selectOption } from '../../../common/commonMethod'
import DevProjectMember from './devProjectMember'
import { dateFormat } from 'rootnet-core/dateFormat'
import './index.scss'


const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={200} {...props} />

const ADD_URL = '/userProject/add'

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=typeOfRDProject', //项目类型
  // '/UserSetting/getUniversalInterfaces?code=id&codeName=projectName&tableName=PSAProject_LeafFlag_Type', //父级项目
  '/common/globalconst?globalConst=ProductLine', //产品线
]

const OPTIONS_URLS = [
  '/develop/product/list', //产品
  '/userProject/getParentProject',
]

export default function DevProjectAddDialog(props) {
  const { initType, close, refreshList = () => { } } = props
  const [step, setStep] = useState('info') //填详情是: info，项目成员: member
  const [type, setType] = useState(initType)
  const [formData, setFormData] = useState()
  const [error, setError] = useState()
  const [expand, setExpand] = useState({ basicInfo: true, describe: true })
  const [showTypeSelect, setShowTypeSelect] = useState(false)
  const [memberCanSubmit, setMemberCanSubmit] = useState(false)
  const [continuous, setContinuous] = useState(false)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { doFetch } = usePost()

  const { productLine } = useMemo(() => formData || {}, [formData]) //useday, projectBudget

  const isPat = useMemo(() => _.includes(['PAT', 'SOL'], type), [type])

  const [devProjectTypeOptions, productLineOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const [productOptions, parentProjectOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2] = optionsRes || []
    const parentProjectOpt = _.map(d2, o => ({ text: `${o.projectName}${_.isNil(o.poUser) ? '' : ' - PO：' + o.poUser}`, value: o.projectID }))
    return [
      d1, parentProjectOpt
    ]
  }, [optionsRes])

  const productOpt = useMemo(() => {
    if (_.isNil(productLine) && _.isEmpty(productOptions)) return []
    const filterData = _.filter(productOptions, o => o.productLine === productLine)
    return selectOption(filterData, ['productName', 'productId'])
  }, [productLine, productOptions])

  const formListArr = useMemo(() => {
    return formList({ parentProjectOptions, productLineOptions, productOpt, isPat, isAdd: true, })
  }, [parentProjectOptions, productLineOptions, productOpt, isPat])

  const TypeSelect = useCallback(() => {
    const displayDevProjectTypeOptions = filterDisplayFlagOptions(devProjectTypeOptions)
    return <div className={'add-dev-project-type-select flex-y'}>
      {
        _.map(displayDevProjectTypeOptions, item => (
          <div className={`type-name flex center-y ${item.value === type ? 'current-type' : ''}`} key={item.value}
            onClick={() => {
              setType(item.value)
              setShowTypeSelect(false)
            }}>
            {item.text}
          </div>
        ))
      }
    </div>
  }, [type, devProjectTypeOptions])

  const TitlePrefix = useCallback(() => {
    return <Popover
      content={<TypeSelect />}
      trigger="click"
      placement="bottom"
      open={showTypeSelect}
      overlayStyle={{ paddingTop: 0 }}
      onOpenChange={setShowTypeSelect}
    >
      <div
        className={'title-prefix flex center-y'}
        onClick={() => setShowTypeSelect(true)} >
        {convertOptions(type, devProjectTypeOptions)}
        <Icon className={'arrow-icon'} name={'zhankaijiantouxia'} />
      </div>
    </Popover >
  }, [type, showTypeSelect, devProjectTypeOptions])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(error), x => x)
  }, [error])

  const nextStep = useCallback(() => {
    if (_.size(_.trim(_.get(formData, 'projectName'))) <= 0) return Messager.show('请填写项目名称', { icon: 'error' })
    if (_.size(_.trim(_.get(formData, 'synopsis'))) <= 0) return Messager.show('请填写项目简介', { icon: 'error' })
    setStep('member')
  }, [formData])

  const memberChange = useCallback((list) => {
    setFormData(x => _.assign({}, x, { rdMemberPermissions: list }))
  }, [])

  const handChange = useCallback((o, bind) => {
    if (bind === 'projectName') {
      o[bind] = _.trim(o['projectName'])
    }
    if (bind === 'productLine') {
      o['productID'] = null
    }
    setFormData(o)
  }, [])

  const submit = useCallback(() => {
    if (continuous) return
    setContinuous(true)
    const { dateInterval, productID } = formData || {}
    const { begin, end } = dateInterval || {}
    const postParams = _.assign({}, _.omit(formData, ['dateInterval', 'productID']), {
      userprojectType: type,
      beginDate: dateFormat('YYYY-MM-DD HH:MM:SS', begin),
      completeDate: dateFormat('YYYY-MM-DD HH:MM:SS', end),
    }, !_.isNil(productID) && { productID })
    doFetch(ADD_URL, postParams)
      .then(() => {
        refreshList()
        setContinuous(false)
        close()
        Messager.show('新增成功', { icon: 'success' })
      })
      .catch(err => {
        setContinuous(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [continuous, doFetch, formData, close, refreshList, type])

  // useEffect(() => {
  //   setError(x => _.assign({}, x, {
  //     useday: isPat ? null : isNil(useday) ? '必填项！' : null,
  //     projectBudget: isPat ? null : isNil(projectBudget) ? '必填项！' : null,
  //   }))
  // }, [isPat, useday, projectBudget, formData])

  return (
    <Dialog
      cancel={close}
      header='新增研发项目'
      footerVisible={false}
      className={'dev-project-add-dialog'}
    >
      <div className="content-wrap flex-y">
        {
          step === 'info' &&
          <Form value={formData} onChange={handChange} error={error} onError={err => setError(x => _.assign({}, x, err))}>
            <HFormInput required label={'项目名称'} bind='projectName' prefix={<TitlePrefix />} componentWidth={872} />

            {
              _.map(formListArr, item => {
                return <div key={item.bind} className={`area-wrap area-wrap${item.bind}`}>
                  <div className="area-header flex center-y">
                    <Icon
                      name="arrow_drop_down"
                      className='fold-icon'
                      style={{ transform: expand[item.bind] ? 'none' : 'rotate(-90deg)' }}
                      onClick={() => setExpand(x => _.assign({}, x, { [item.bind]: !x[item.bind] }))}
                    />
                    {item.label}
                  </div>
                  <div className="area-content-wrap" style={{ height: expand[item.bind] ? '' : 0 }}>
                    {
                      _.map(item.children, o => {
                        return <HFormInput key={o.bind} {...o} />
                      })
                    }

                  </div>
                </div>
              })
            }

          </Form>
        }
        {
          <div style={{ height: '100%', display: step === 'member' ? 'block' : 'none' }}>
            <DevProjectMember onChange={memberChange} canSubmit={setMemberCanSubmit} {...{ step }} />
          </div>
        }
      </div>
      <div className="mock-footer flex center-y">
        <div className='footer-hint'>
          项目创建后，请发起【项目财务编码】申请单，审核完成后，自动回写“<span>预估工作量、项目预算、预测收入</span>”！
        </div>
        <div className="btn-group flex">
          {
            step === 'info' &&
            <Fragment>
              <Button normal onClick={close}>取消</Button>
              <Button primary disable={!canSubmit} onClick={nextStep}>下一步</Button>
            </Fragment>
          }
          {
            step === 'member' &&
            <Fragment>
              <Button normal onClick={() => setStep('info')}>上一步</Button>
              <Button primary disable={memberCanSubmit} onClick={submit}>确定</Button>
            </Fragment>
          }
        </div>
      </div>
    </Dialog>
  )
}

import React, { useCallback, useMemo, useState, useContext, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Button, Messager } from 'rootnet-ui'
import { Table, Popconfirm, InputNumber, Input, Form as AntdForm, TreeSelect } from 'antd'
import { isNil } from 'rootnet-core/format'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Display } from 'rootnet-edit'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { Icon } from '../../../../../../components'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import { uniqKeyFor } from '../../../../../../project_share/utils/utils'
import { ManpowerEditContext } from '../../../../../common/Context'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertOptions from '../../../../../common/ConvertOptions'
import './manpowerDetailContent.scss'
import { strParams } from '../../../../../../utils/publicFun'

const DETAIL_URL = '/humanManpower/details'
const DEL_URL = '/humanManpower/delete'
const EDIT_URL = '/humanManpower/update'
const DEPARTMENT_MANPOWER_URL = '/humanManpower/getDepartmentNum'

function getColumns(props) {
  const { editable, projectOption, itemClassOpt, delDataItem } = props
  const allOption = _.concat(..._.map(projectOption, o => o.children))
  const cols = [
    {
      title: '#',
      width: 40,
      align: 'center',
      render: (v, o, i) => i + 1
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>项目/事务</span>,
      dataIndex: 'projectId',
      key: 'projectId',
      editable,
      width: 100,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }
        return _.get(_.find(allOption, o => o.value === value), 'title') || value
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>预估类型</span>,
      dataIndex: 'type',
      key: 'type',
      width: 90,
      render: value => {
        return _.get(_.find(itemClassOpt, o => o.value === value), 'text') || value
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>占用工作量评估</span>,
      dataIndex: 'workloadOccupied',
      key: 'workloadOccupied',
      editable,
      width: 80,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }
        return `${value}人月`
      }
    }
  ]
  const operation = [
    {
      title: '操作',
      width: 30,
      align: 'center',
      fixed: 'right',
      render: (v, o) => {
        return <>
          <Popconfirm
            title="确认删除？"
            onConfirm={() => delDataItem(o.id)}
            okText="删除"
            cancelText="取消"
            overlayInnerStyle={{ padding: 8, width: 200 }}
          >
            <Icon className={'manpower-del-icon'} name='shanchu' style={{ fontSize: 16 }} />
          </Popconfirm>
        </>
      }
    }
  ]
  return editable ? _.concat(cols, operation) : cols
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default function ManpowerDetailContent(props) {
  const { initId, close, projectOption, itemClassOpt, statusOpt, refresh } = props
  const [mode, setMode] = useState('detail')
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [editFormError, setEditFormError] = useState()
  const [data, setData] = useState([])
  const [delData, setDelData] = useState([])
  const [continuous, setContinuous] = useState(false)
  const { doFetch: getDetail } = useGet()
  const { doFetch: delFetch } = useGet()
  const { data: allDeptRes } = useGet('/common/department?activeFlag=Y')
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch: getDepartmentManpower } = useGet()
  const { doFetch: editFetch } = usePost()

  const [form] = AntdForm.useForm()
  const isEdit = useMemo(() => mode === 'edit', [mode])
  const isDetail = useMemo(() => mode === 'detail', [mode])

  const delClick = useCallback(() => {
    delFetch(`${DEL_URL}?id=${initId}`)
      .then(() => {
        refresh()
        Messager.show('删除成功', { icon: 'success' })
        close()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [delFetch, initId, refresh, close])

  const refreshDetail = useCallback((id) => {
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?id=${id}`)
      .then(res => {
        const evaluationDate = toDate.str14ToDate(res.evaluationDate)
        const filterData = _.filter(res.humanProjectEstimates, o => (o.projectStatus === '01' || isNil(o.projectStatus)))
        const evaluationSummary = (_.sum(_.map(filterData, o => _.isNil(o.workloadOccupied) ? 0 : Number(o.workloadOccupied) * 100)) / 100)
        if (isEdit) {
          const currentDate = _.split(dateFormat('YYYY-MM-DD', new Date()), '-')
          const upDateDate = _.split(dateFormat('YYYY-MM-DD', evaluationDate), '-')
          const currentNum = `${currentDate[0]}${currentDate[1]}`
          const upDateNum = `${upDateDate[0]}${upDateDate[1]}`
          if (upDateNum < currentNum) {
            setData(_.map(filterData, o => _.assign({}, o, { actionType: 'edit' })))
            setFormData(_.omit({ ...res, evaluationDate, evaluationSummary }, ['endDate', 'beginDate', 'humanProjectEstimates']))
            setEditFormData(_.omit({ ...res, evaluationDate, evaluationSummary }, ['endDate', 'beginDate', 'humanProjectEstimates']))
          } else {
            getDepartmentManpower(`${DEPARTMENT_MANPOWER_URL}?${strParams({ departmentId: res.departmentId })}`)
              .then((res1) => {
                setData(_.map(filterData, o => _.assign({}, o, { actionType: 'edit' })))
                setFormData(_.omit({ ...res, evaluationDate, evaluationSummary, departmentManpower: res1 }, ['endDate', 'beginDate', 'humanProjectEstimates']))
                setEditFormData(_.omit({ ...res, evaluationDate, evaluationSummary, departmentManpower: res1 }, ['endDate', 'beginDate', 'humanProjectEstimates']))
              })
              .catch(err => Messager.show(err._message, { icon: 'error' }))
          }
        } else {
          setData(_.map(filterData, o => _.assign({}, o, { actionType: 'edit' })))
          setFormData(_.omit({ ...res, evaluationDate, evaluationSummary }, ['endDate', 'beginDate', 'humanProjectEstimates']))
          setEditFormData(_.omit({ ...res, evaluationDate, evaluationSummary }, ['endDate', 'beginDate', 'humanProjectEstimates']))
        }
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getDetail, getDepartmentManpower, isEdit])

  const delDataItem = useCallback((id) => {
    setData(x => {
      const filterData = _.filter(x, o => o.actionType !== 'add')
      setDelData(d => _.concat(d, _.compact([_.find(filterData, o => o.id === id)])))
      return _.filter(x, o => o.id !== id)
    })
  }, [])

  const EditableRow = useCallback(({ index, ...restProps }) => {
    const currentIndex = [].findIndex(x => x.id === restProps['data-row-key'])
    return <AntdForm form={form} component={false}>
      <ManpowerEditContext.Provider value={{ form }}>
        <SortableItem index={currentIndex} {...restProps} />
      </ManpowerEditContext.Provider>
    </AntdForm>
  }, [form])

  const handleSave = useCallback((row, bind) => {
    const index = _.findIndex(data, x => x.id === row.id)
    setData(oldList => {
      const newList = _.map(oldList, (item, i) => {
        if (i !== index) return item
        return ({
          ...item,
          ...row
        })
      })
      return _.sortBy(newList, 'serial')
    })
  }, [data, setData])

  const columns = useMemo(() => {
    return _.map(getColumns({ editable: isEdit, projectOption, itemClassOpt, delDataItem }), col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          showList: data,
          projectOption,
        })
      }
    })
  }, [projectOption, itemClassOpt, data, isEdit, delDataItem, handleSave])

  const canSubmit = useMemo(() => {
    const isError = !_.some(_.values(editFormError), x => x)
    const requiredList = _.map(data, o => (!isNil(o.projectId) && !isNil(o.workloadOccupied)))
    const isRequiredList = _.every(requiredList)
    return isError && isRequiredList
  }, [editFormError, data])

  const handClick = useCallback(() => {
    if (continuous) return
    setContinuous(true)
    const delList = _.map(delData, o => _.assign({}, o, { actionType: 'del' }))
    const newData = _.map(data, o => {
      if (o.actionType === 'add') return _.omit(o, ['id'])
      return o
    })
    const date = dateFormat('YYYY-MM-DD', editFormData.evaluationDate)
    const evaluationDate = date
    const postParams = _.assign({}, _.omit(editFormData, 'evaluationDate'), {
      evaluationDate,
      humanProjectEstimates: _.concat(newData, delList)
    })
    editFetch(EDIT_URL, postParams)
      .then(() => {
        refresh()
        setMode('detail')
        setContinuous(false)
        Messager.show('编辑成功', { icon: 'success' })
      })
      .catch(err => {
        setContinuous(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [editFetch, refresh, editFormData, data, delData, continuous])

  useEffect(() => {
    if (_.isEmpty(data)) return
    const evaluationSummary = (_.sum(_.map(data, o => _.isNil(o.workloadOccupied) ? 0 : Number(o.workloadOccupied) * 100)) / 100)
    setFormData(x => _.assign({}, x, { evaluationSummary }))
    setEditFormData(x => _.assign({}, x, { evaluationSummary }))
  }, [data])

  useEffect(() => {
    if (_.isNil(initId)) return
    refreshDetail(initId)
  }, [refreshDetail, initId])

  return (
    <div className={'manpower-detail-content flex-y'}>
      <div className="mock-dialog-header flex">
        <div className="dialog-title">
          {isEdit ? '编辑' : '详情'}
        </div>
        <div className="mock-right-header flex center-y">
          <Popconfirm
            title="确认删除？"
            onConfirm={delClick}
            okText="删除"
            cancelText="取消"
            overlayInnerStyle={{ padding: 8, width: 200 }}
          >
            <TextIconBtn icon='shanchu' text='删除' />
          </Popconfirm>
          <TextIconBtn
            icon={'bianji2'}
            className={`header-edit-text-icon`}
            text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
              setMode(x => x === 'detail' ? 'edit' : 'detail')
              if (isDetail) setEditFormData(formData)
            }}
          />
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>
      <div className="manpower-detail-main-panel flex">
        <div style={{ width: '100%' }}>
          <Form
            value={editFormData}
            onChange={setEditFormData}
            error={editFormError}
            onError={setEditFormError}
          >
            <FormInput
              required={isEdit}
              horizontal
              labelWidth={70}
              componentWidth={180}
              bind='evaluationDate'
              label='月份'
              component={Display}
              mode='month'
              convert={v => {
                const getDate = _.split(dateFormat('YYYY-MM-DD', v), '-')
                const month = _.size(_.split(v, '-')) >= 2 ? v : `${getDate[0]}-${getDate[1]}`
                return month
              }}
            />
            <FormInput
              required={isEdit}
              horizontal
              labelWidth={70}
              componentWidth={180}
              bind='departmentId'
              label='部门'
              component={Display}
              convert={v => convertOptions(v, allDeptRes, 'departMentName', 'departMentId')}
            />
            <FormInput horizontal labelWidth={70} componentWidth={180} bind='departmentManpower' label='部门人力' component={Display} convert={v => {
              return isNil(v) ? v : `${v}人`
            }} />
            {
              isDetail &&
              <>
                <FormInput horizontal labelWidth={70} componentWidth={180} bind='updateUser' label='更新人' component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                <FormInput horizontal labelWidth={70} componentWidth={180} bind='updateTime' label='更新时间' component={Display} convert={v => dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))} />
              </>
            }
            <FormInput required={isEdit} horizontal labelWidth={70} componentWidth={180} bind='status' label='状态' component={Display} options={statusOpt} convert={v => convertOptions(v, statusOpt)} />
            <FormInput horizontal bind='evaluationSummary' label='占用评估汇总' component={Display} convert={v => {
              return isNil(v) ? v : `${v}人月`
            }} />
          </Form>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '8px 16px' }}>
          {
            isEdit &&
            <TextIconBtn style={{ color: '#5477ff' }} text='添加评估' icon='tianjia' onClick={() => setData(x => _.concat([], x, [{ id: uniqKeyFor(), actionType: 'add' }]))} />
          }
        </div>
        <Table
          size={'small'}
          rowClassName={'editable-row'}
          columns={columns}
          pagination={false}
          dataSource={data}
          rowKey="id"
          sticky={true}
          scroll={{ y: 359}}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: EditableRow,
              cell: EditableCell
            }
          }}
        />
      </div>
      <div className="mock-footer flex center-y">
        <div />

        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              setMode('detail')
              setEditFormData(formData)
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary onClick={handClick} disable={!canSubmit}>确认</Button>
          }
        </div>
      </div>
    </div>
  )

  function DraggableContainer(props) {
    return <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      {...props}
    />
  }
}

function EditableCell(props) {
  const { title, editable, children, dataIndex, record, handleSave, must, showList, projectOption, ...restProps } = props
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const { form } = useContext(ManpowerEditContext || {})

  useEffect(() => {
    if (editing) {
      if (!_.isNil(inputRef.current)) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  const toggleEdit = useCallback(() => {
    setEditing(x => !x);
    let value
    value = record[dataIndex]
    form.setFieldsValue({ [dataIndex]: value });
  }, [dataIndex, record, form])

  const save = () => {
    try {
      form.validateFields().then(changeObj => {
        const key = _.head(_.keys(changeObj))
        let newObj = changeObj
        toggleEdit();
        if (key === 'projectId') {
          const allOption = _.concat(..._.map(projectOption, o => o.children))
          newObj = _.assign({}, changeObj, {
            type: _.get(_.find(allOption, o => o.value === changeObj[key]), 'pid'),
            projectType: _.get(_.find(allOption, o => o.value === changeObj[key]), 'status')
          })
        }
        if (changeObj[key] !== record[key]) {
          handleSave({ ...record, ...newObj }, key);
        }
        if (!_.isNil(inputRef.current)) {
          inputRef.current.blur()
        }
        setEditing(false)
      })
    } catch (errInfo) {
      console.error(errInfo);
    }
  }

  const treeData = _.orderBy(projectOption, 'value', 'desc')

  let childNode = children;
  if (editable) {
    if (editing) {
      let editNode
      if (dataIndex === 'projectId') {
        editNode = (<TreeSelect autoFocus ref={inputRef} onSelect={save} allowClear={false} onBlur={() => setEditing(false)} treeData={treeData} showSearch treeNodeFilterProp='title' />)
      } else if (dataIndex === 'workloadOccupied') {
        editNode = (<InputNumber addonAfter={'人月'} ref={inputRef} min={0} onPressEnter={save} onBlur={save} />)
      } else if (!isNil(dataIndex)) {
        editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
      } else {
        editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
      }

      childNode = (
        <AntdForm.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: must,
              message: `${title}不能为空`,
            },
          ]}
        >
          {editNode}
        </AntdForm.Item>
      )
    } else {
      childNode = (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>;
}

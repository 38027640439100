import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, DataGrid} from 'rootnet-ui'
import { Form, FormInput, Select} from "rootnet-edit";
import '../common/SystemMgtCommon.scss'
import {Icon} from "../../../components";
import _ from 'lodash'
import {Route, Switch} from "react-router-dom";
import ModuleDetail from "./ModuleDetail";
import useToDetail from "../../commonV2/hooks/useToDetail";
import {useGet} from "../../../utils/hook";
import {pathSearchFor, strParams} from "../../../utils/publicFun";
import DisplayFormItem from "../common/DisplayFormItem";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import {Box} from "../../common/commonComponent";
import {TextIconBtn} from "../../../project_share/components";
import OperationList from "../../../project_share/components/OperationList";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import Remark from "../common/Remark";
import gd from "../../../base/global";

export default function Router() {
    return <Switch>
        <Route path='/moduleMgt' component={ModuleMgt} exact/>
        <Route path='/moduleMgt/detail' component={ModuleDetail}/>
    </Switch>
}

const getColumns = (props) => {
    const {toDetailWithParams,stateOptions, collocationOptions, releaseOptions, offerOptions} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '模块名称', bind: 'prodSubsysName',sortable: true,width: 200, tooltip: true},
        {header: '负责人', bind: 'prodSubsysManager',sortable: true,width: 100},
        {header: '所属产品', bind: 'belonging',sortable: true,width: 100},
        {header: '模块状态', bind: 'prodSubsysState',sortable: true,width: 100, convert: v => convertOptions(v,stateOptions)},
        {header: '配置方式', bind: 'prodSubsysCollocation',sortable: true,width: 100, convert: v => convertOptions(v,collocationOptions)},
        {header: '发布方式', bind: 'prodSubsysRelease',sortable: true,width: 100, convert: v => convertOptions(v,releaseOptions)},
        {header: '报价方式', bind: 'prodSubsysOffer',sortable: true,width: 100, convert: v => convertOptions(v,offerOptions)},
        {header: '被引用次数', bind: 'prodSubsysUsed',sortable: true,width: 100, align: 'right'},
        {header: '包含子模块', bind: 'prodSubsysModulars',sortable: true,width: 200,tooltip: true},
        {header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation}
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick: () => toDetailWithParams({productId:o.productId,prodSubsysId: o.prodSubsysId,prodSubsysName: o.prodSubsysName})
            },
        ];
        return <OperationList options={operateOption}/>;
    }
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    virtualized: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
});

const INIT_PARAMS = {
    prodSubsysName: null,
    productId: [],
    productManager: [],
    prodSubsysManager: [],
}

function transformHistoryParams(params, key){
    let value = _.get(params,key)
    return value?value.split(','):null
}

function getTransformObj(params, keyList){
    const newObj = {}
    _.forEach(keyList, x => newObj[x] = transformHistoryParams(params, x))
    return newObj
}

const OPTIONS_URLS = [
    '/common/globalconst?globalConst=state',
    '/common/globalconst?globalConst=collocationType',
    '/common/globalconst?globalConst=releaseType',
    '/common/globalconst?globalConst=offerType',
]

const MANAGER_OPTIONS_URLS = [
    '/common/productManager',
    '/common/prodSubsysManager',
]

function ModuleMgt(props) {
    const {history} = props
    const searchParams = pathSearchFor(history.location.search)
    const handleHistoryParams = useMemo(()=>{
        const newObj = getTransformObj(searchParams,['productId','productManager','prodSubsysManager'])
        return _.assign({},searchParams,newObj)
    },[searchParams])

    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const [stateOptions, collocationOptions, releaseOptions, offerOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        return _.map(optionsRes, x => convertGlobalConstOptions(x))
    },[optionsRes])

    const {data: managerRes} = useGet(MANAGER_OPTIONS_URLS)

    const [params, setParams] = useState(_.isEmpty(searchParams)?INIT_PARAMS:handleHistoryParams)

    const {data: list, doFetch: getList, loading, error} = useGet()
    const [isCard, setIsCard] = useState(true)

    const [productManagerOptions,prodSubsysManagerOptions] = useMemo(()=>{
        const [d1, d2] = managerRes || []
        return [
            _.map(d1, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`})),
            _.map(d2, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))
        ]
    },[managerRes])

     const canSearch = useMemo(()=>{
        if(_.some([productManagerOptions,prodSubsysManagerOptions], _.isEmpty)) return false
        const userAccount = _.get(gd, '_user.operator_id');
        if(_.find(productManagerOptions, x => x.value === userAccount)){
            setParams(x => ({...x, productManager: [userAccount]}))
        }else if(_.find(prodSubsysManagerOptions, x => x.value === userAccount)){
            setParams(x => ({...x, prodSubsysManager: [userAccount]}))
        }else{
            setParams(x => ({...x, productManager: [], prodSubsysManager: []}))
        }
        return true
    },[productManagerOptions,prodSubsysManagerOptions])


    useEffect(()=>{
        if(!canSearch) return
        getList('/prodSubsys/list?'+strParams(params))
    } ,[getList,params,canSearch])

    const toDetail = useToDetail('/moduleMgt/detail')

    const toDetailWithParams = useCallback((pathParams)=>{
        const itemInfo = _.mapKeys(params, (v, k) => 'item_' + k)
        toDetail({...pathParams,...itemInfo})
    },[params,toDetail])

    const option = useMemo(()=>{
        return getTableOption(getColumns({searchParams,toDetailWithParams,stateOptions, collocationOptions, releaseOptions, offerOptions}))
    },[searchParams,toDetailWithParams,stateOptions, collocationOptions, releaseOptions, offerOptions])

    const cardExtra = useMemo(()=>{
        return <div className='flex card-extra center-y'>
            <TextIconBtn text='新建模块' icon={'tianjia'}/>
            <TextIconBtn text={`切换${isCard?'表格':'卡片'}`} icon={isCard?'biaoge':'tubiao'} onClick={()=>setIsCard(x => !x)}/>
        </div>
    },[isCard])

    return <div className='module-mgt fill flex-y system-mgt-common'>
        <Options initParams={params} search={setParams} productManagerOptions={productManagerOptions} prodSubsysManagerOptions={prodSubsysManagerOptions}/>
        <Box title='模块列表' className='flex-y x-card-singlegrid' data={list} extra={cardExtra}
             style={{flex: isCard?0:1}} loading={loading} error={error}>
            {
                !isCard &&
                <DataGrid data={list} option={option}/>
            }
        </Box>
        {
            isCard &&
            <div className="card-group flex">
                {
                    _.map(list, (item, i) => {
                        return <div className="card-item card-detail flex-y" key={i}>
                            <div className="card-header flex center-y">
                                <div className="card-title">{item.prodSubsysName}</div>
                                <Icon name='shanchu2' className='del-icon'/>
                            </div>
                            <DisplayForm value={item} stateOptions={stateOptions}/>
                            <div className="bottom-text flex center"
                                 onClick={()=>toDetailWithParams({productId:item.productId,prodSubsysId: item.prodSubsysId,prodSubsysName: item.prodSubsysName})}>
                                模块详情
                            </div>
                        </div>
                    }).concat(new Array(4).fill(0).map(() => (<div key={uniqKeyFor()} className='empty-item'/> )))
                }
            </div>
        }
    </div>
}

function DisplayForm(props){
    const {value, stateOptions} = props
    return <div className='display-form flex' style={{padding: '0 10px'}}>
        <DisplayFormItem text={value.prodSubsysManager} label='负责人'/>
        <DisplayFormItem text={value.belonging} label='所属产品'/>
        <DisplayFormItem text={convertOptions(value.prodSubsysState,stateOptions)} label='模块状态'/>
        <Remark text={value.prodSubsysModulars} label='包含子模块' oneLine/>
        <Remark text={value.directions} label='备注' oneLine/>
    </div>
}

const OPTION_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
]

function Options(props){
    const {initParams, search,productManagerOptions,prodSubsysManagerOptions} = props
    const [params, setParams] = useState(initParams)
    const {data: optionsRes} = useGet(OPTION_URLS)

    const [productOptions] = useMemo(()=>{
        const [d1, d2] = optionsRes || []
        const productOptions = _.map(_.groupBy(d1,product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName')|| productLine || '无产品线',
            children: _.map(productLineItem, x => ({value: x.productId, text: x.productName}))
        }))
        return [productOptions]
    },[optionsRes])

    useEffect(()=>{
        if(_.some([productManagerOptions,prodSubsysManagerOptions], _.isNil)) return
        const userAccount = _.get(gd, '_user.operator_id');
        if(_.find(productManagerOptions, x => x.value === userAccount)){
            setParams(x => ({...x, productManager: [userAccount]}))
        }else if(_.find(prodSubsysManagerOptions, x => x.value === userAccount)){
            setParams(x => ({...x, prodSubsysManager: [userAccount]}))
        }else{
            setParams(x => ({...x, productManager: [], prodSubsysManager: []}))
        }
    },[productManagerOptions,prodSubsysManagerOptions])

    return <div className='c-options'>
        <Form onChange={setParams} value={params}>
            <FormInput label="模块名称" bind="prodSubsysName"/>
            <FormInput label="产品" component={Select} bind="productId" options={productOptions} clear search tree multiple/>
            <FormInput label="产品经理" component={Select} bind="productManager" options={productManagerOptions} clear search multiple/>
            <FormInput label="模块负责人" component={Select} bind="prodSubsysManager" options={prodSubsysManagerOptions} clear search multiple/>
        </Form>
        <Button primary onClick={() => search({...params})} className='btn'>查询</Button>
        <Button text onClick={() => {
            const userAccount = _.get(gd, '_user.operator_id');
            let initParams = {...INIT_PARAMS}
            if(_.find(productManagerOptions, x => x.value === userAccount)){
                initParams = {...initParams,productManager: [userAccount]}
            }else if(_.find(prodSubsysManagerOptions, x => x.value === userAccount)){
                initParams = {...initParams,prodSubsysManager: [userAccount]}
            }
            setParams({...initParams})
            search({...initParams})
        }} className='btn reset-btn'>重置</Button>
    </div>
}
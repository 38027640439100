import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { strParams } from "../../../project_share/utils/utils";

export default function useToDetail(detailPath){
    const history = useHistory();

    return useCallback(function (o){
        return history.push({pathname:detailPath,search:strParams(o)})
    },[history,detailPath])
}

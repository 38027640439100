import React from 'react';
import pic from '../assets/images/nodata.png';

export default function Nodata(props) {
    let { userPic = pic, text = '没有找到相关数据', width = 80,Component } = props;
    return <div className="flex center" style={{ height: '100%' }}>
        <div className="flex-y center" style={{ margin: '48px 0' }}>
            <img src={userPic} alt="" width={width} style={{ marginBottom: 8 }} />
            <span style={{ color: '#7A8199', fontSize: '14px', marginTop: '4px' }}>{text}</span>
            {Component && <Component/>}
        </div>
    </div>
}

import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import './ResourceSchedule.scss'
import Icon from "../../../project_share/components/Icon";
import ResourceDeptSearch from "./resourceDeptSearch/ResourceDeptSearch";
import {FormInput, Select, Form} from "rootnet-edit";
import ResourceSummaryInfo from "./resourceSummaryInfo/ResourceSummaryInfo";
import ResourceInfoCard from "./resourceInfoCard/ResourceInfoCard";
import useExactHeight from "../../common/hooks/useExactHeight";
import {dateFormat} from "rootnet-core/dateFormat";
import { Tabs } from 'antd';
import ResourceProjectSearch from "./resourceProjectSearch/ResourceProjectSearch";
import {usePost} from "rootnet-biz/es/hooks";
import _ from "lodash";
import {useGet} from "../../../utils/hook";
import {strParams} from "../../../utils/publicFun";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import DateSelector from "../../common/dateSelector/DateSelector";

const { TabPane } = Tabs;

const initForm ={
    firstDate: dateFormat('YYYY-MM-DD',new Date()),
    lastDate: dateFormat('YYYY-MM-DD',new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 30)),
    dateRange: {
        begin: dateFormat('YYYY-MM-DD',new Date()),
        end: dateFormat('YYYY-MM-DD',new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 30))
    }
}

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=userPost',
]

function ResourceSchedule(props) {
    const [isFold, setIsFold] = useState(false)
    const [summaryInfoExpanded,setSummaryInfoExpanded] = useState(true)
    const [exactContainerRef,containerRef,updateHeight] = useExactHeight(40)
    const [formData, setFormData] = useState(initForm)
    const [activeTab, setActiveTab] = useState('department')
    const {data: summaryLevelRes, doFetch: getSummaryLevel, loading: summaryLevelLoading, error: summaryLevelError} = usePost()
    const {data: summaryModuleRes, doFetch: getSummaryModule, loading: summaryModuleLoading, error: summaryModuleError} = usePost()
    const {data: moduleRes, doFetch: getModule} = useGet('/demandInput/moduleList')
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [selectedProjectId, setSelectedProjectId] = useState()
    const [forceUpdateSummary, setForceUpdateSummary] = useState(0)

    const [positionOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const selectedDeptId = useMemo(()=>{
        return _.get(formData,'department') || null
    },[formData])

    const selectedModuleList = useMemo(()=>{
        return _.get(formData,'moduleList') || []
    },[formData])

    useEffect(()=>{
        const params = {
            department: selectedDeptId
        }
        getModule('/demandInput/moduleList?'+strParams(params))
    },[selectedDeptId, getModule])

    const moduleOptions = useMemo(()=>{
        if(_.isEmpty(moduleRes)){
            setFormData(old => {
                if(_.isEmpty(_.get(old,'moduleList'))) return old
                return {
                    ...old,
                    moduleList: []
                }
            })
            return []
        }
        const moduleIdList = _.map(moduleRes,'moduleId')
        if(!_.isEmpty(selectedModuleList) && !_.every(selectedModuleList, x => _.includes(moduleIdList,x))){
            setFormData(old => {
                if(_.isEmpty(_.get(old,'moduleList'))) return old
                return {
                    ...old,
                    moduleList: []
                }
            })
        }
        return _.map(moduleRes, x => ({value: x.moduleId, text: `${x.moduleId}-${x.moduleIdName}`}))
    },[moduleRes, selectedModuleList])

    const refreshSummaryTable = useCallback(()=>{
        if(_.isNil(formData)) return
        if(_.isNil(_.get(formData,'department'))) return
        const searchParams = {
            ...formData
        }
        if(!_.isNil(selectedProjectId)){
            searchParams['relatedType'] = 'PRJ'
            searchParams['relatedTask'] = selectedProjectId
        }
        getSummaryLevel('/userPlan/summaryLevel',searchParams)
        getSummaryModule('/userPlan/summaryModule',searchParams)
    },[formData, getSummaryLevel,getSummaryModule, selectedProjectId])

    useEffect(()=>{
        refreshSummaryTable()
        // 强制刷新汇总信息
        console.log(forceUpdateSummary)
    },[refreshSummaryTable,forceUpdateSummary])

    const onScroll = useCallback((e)=>{
        if(e.deltaY > 0){
            setSummaryInfoExpanded(false)
        }
    },[])

    useEffect(()=>{
        const rightPanel = document.querySelector('.resource-info-card')
        if(rightPanel){
            rightPanel.addEventListener('mousewheel', onScroll)
        }
        return ()=>{
            if(rightPanel){
                rightPanel.removeEventListener('mousewheel', onScroll)
            }
        }
    },[onScroll])

    useLayoutEffect(()=>{
        return updateHeight(summaryInfoExpanded);
    },[summaryInfoExpanded,updateHeight]);

    const changeDept = useCallback((deptId)=>{
        const newDeptId = deptId === '' ? '0' : deptId
        setFormData(old => ({
            ...old,
            department: newDeptId
        }))
        setSelectedProjectId(null)
    },[])

    const onFormChange = useCallback((newObj, bind)=>{
        setFormData(oldObj => {
            const cloneObj = _.clone(newObj)
            if(bind === 'dateRange'){
                if(_.isNil(_.get(newObj,'dateRange'))){
                    cloneObj['firstDate'] = null
                    cloneObj['lastDate'] = null
                }else{
                    cloneObj['firstDate'] = _.get(newObj, 'dateRange.begin')
                    cloneObj['lastDate'] = _.get(newObj, 'dateRange.end')
                }
            }
            return {...oldObj,...cloneObj}
        })
    },[])

    return <div className={'resource-schedule-wrap flex fill'}>
        <div className="resource-schedule-left-wrap flex" style={{width: isFold ? 40 : 330}}>
            <div className={'dept-search-wrap flex'} style={{display: isFold ? 'none' :'flex'}}>
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="部门" key="department">
                        <ResourceDeptSearch {...{changeDept}}/>
                    </TabPane>
                    <TabPane tab="项目" key="project">
                        <ResourceProjectSearch {...{selectedDeptId,selectedProjectId, setSelectedProjectId}}/>
                    </TabPane>
                </Tabs>
            </div>
            <div className="fold-bar flex center" onClick={()=>setIsFold(x => !x)} style={{width: isFold ? 40 : 22}}>
                <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{transform: isFold ?  'none' : 'rotate(180deg)'}}/>
            </div>
        </div>
        <div className="resource-schedule-right-wrap flex-y">
            <div className="search-condition-wrap">
                <Form value={formData} onChange={onFormChange}>
                    {/*<FormInput bind={'firstDate'} label={'开始时间'} component={DatePicker} horizontal labelWidth={80}*/}
                    {/*           bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>*/}
                    {/*<FormInput bind={'lastDate'} label={'结束时间'} component={DatePicker} horizontal labelWidth={80}*/}
                    {/*           bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>*/}
                    <FormInput bind={'dateRange'} componentWidth={260} component={DateSelector} required allowClear={false}/>
                    <FormInput bind={'moduleList'} label={'模块'} component={Select} horizontal labelWidth={80} options={moduleOptions} search clear multiple/>
                </Form>
            </div>
            <div className="resource-schedule-right-main-panel flex-y">
                <div className="containerParent" ref={exactContainerRef} onTransitionEnd={transitionEnd}>
                    <div className='container' ref={containerRef}>
                        <ResourceSummaryInfo {...{summaryInfoExpanded,setSummaryInfoExpanded,summaryLevelRes,summaryModuleRes,selectedProjectId,
                            summaryLevelLoading,summaryLevelError,summaryModuleLoading,summaryModuleError,positionOptions,selectedDeptId}}/>
                    </div>
                </div>
                <ResourceInfoCard {...{formData,positionOptions,selectedDeptId,selectedProjectId,setForceUpdateSummary}}/>
            </div>
        </div>
    </div>

    function transitionEnd() {
        if(summaryInfoExpanded) exactContainerRef.current.style.height = 'auto';//设置为`auto`的原因是需要保证子级菜单展开时，父级菜单的高度响应变化
    }
}

export default ResourceSchedule;
import React, {useCallback, useEffect, useState} from 'react';
import './AppBarQuickCreate.scss'
import {TextIconBtn} from "../common/TextIconBtn";
import {Popover, Tooltip} from "antd";
import QuickCreatePop from "./QuickCreatePop";
import IssueAdd from "../issueMgt/components/issueAdd/IssueAdd";
import _ from 'lodash'
import RequirementAddDialog from "../requirementMgt/requirementAddDialog/RequirementAddDialog";
import {useGet} from "rootnet-biz/es/hooks";
import FollowMattersDetail from "../common/ChangeRecord/components/followMattersDetail";
import CustomerServiceInfoAddDialog
    from "../customerServiceInfo/customerServiceInfoAddDialog/CustomerServiceInfoAddDialog";
import DevProjectAddDialog from "../devProjectMgt/components/devProjectAddDialog";
import AddVersion from "../versionMgt/versionMsg/components/addVersion";
import CustomerVoiceAdd from "../customerVoiceMgt/customerVoiceAdd/CustomerVoiceAdd";
import DefectUpdateDialog from "../testMgt/defect/controls/DefectUpdateDialog";

function AppBarQuickCreate(props) {
    const [addInfo, setAddInfo] = useState()
    const [showPop, setShowPop] = useState(false)
    const {data: onlyId, doFetch: getOnlyId} = useGet()

    const refreshOnlyId = useCallback(()=>{
       getOnlyId('/test_case/productGetOnlyId')
    },[getOnlyId])

    useEffect(()=>{
        refreshOnlyId()
    },[refreshOnlyId])

    return <div className={'app-bar-quick-create-wrap'}>
        <Popover trigger={'click'} placement={'bottomRight'} content={<QuickCreatePop {...{setAddInfo, setShowPop}}/>}
                 open={showPop} onOpenChange={setShowPop} overlayClassName={'app-bar-quick-create-pop'}>
            <div className={'app-bar-quick-create'}>
                <Tooltip title='快速创建' placement={'left'}>
                    <TextIconBtn icon='tianjia' text='' size={20}/>
                </Tooltip>
            </div>
        </Popover>
        {
            _.get(addInfo,'module') === 'req' &&
            <RequirementAddDialog close={()=>setAddInfo(null)} initType={_.get(addInfo,'initType')}/>
        }
        {
            _.get(addInfo,'module') === 'issue' &&
            <IssueAdd close={() => setAddInfo(null)}/>
        }
        {
            _.get(addInfo,'module') === 'defect' &&
            <DefectUpdateDialog mode={'add'} close={()=>setAddInfo(null)}/>
        }
        {
            _.get(addInfo,'module') === 'todoList' &&
            <FollowMattersDetail
                id={onlyId}
                funcCode={'23'}
                mode={'add'}
                close={() => setAddInfo(null)}
                refresh={refreshOnlyId}
                linkUrl={`/todolist?initId=${onlyId}`}
                pid={null}
            />
        }
        {
            _.get(addInfo,'module') === 'customerService' &&
            <CustomerServiceInfoAddDialog close={()=>setAddInfo(null)}/>
        }
        {
            _.get(addInfo,'module') === 'devProject' &&
            <DevProjectAddDialog close={()=>setAddInfo(null)} initType={_.get(addInfo,'initType')}/>
        }
        {
            _.get(addInfo,'module') === 'version' &&
            <AddVersion close={()=>setAddInfo(null)}/>
        }
        {
            _.get(addInfo,'module') === 'customerVoice' &&
            <CustomerVoiceAdd close={()=>setAddInfo(null)}/>
        }
    </div>
}

export default AppBarQuickCreate;
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState, useReducer, useContext } from 'react'
import { useGet } from 'rootnet-biz/lib/hooks'
import { isNil } from 'rootnet-core/format'
import { DataGrid } from 'rootnet-ui'
import { strParams } from '../../../../../../../utils/publicFun'
import { Box } from '../../../../../../common/commonComponent'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../../../../common/ConvertOptions'
import { OperationList } from '../../../../../../common/OperationList'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import VersionSelectLog from './versionSelectLog'
import DelMessage from '../../../../../../../components/DelMessage'
import VersionMakingDetailDialog from './versionMakingDetailDialog'
import VersionMakingAddDialog from './versionMakingAddDialog'
import './index.scss'
import gd from '../../../../../../../base/global'
import { ScheduleManagementContext } from '../../../../../../common/Context'

const LIST_URL = '/releaseProducer/list' //版本制作列表
const VERSION_TYPE_URL = '/common/globalconst?globalConst=BuildType' //版本制作类型
const DEL_URL = '/releaseProducer/delete' //删除
const EXPORT_URL = '/releaseProducer/list/export'

const getColumns = (props) => {
  const { versionTypeOpt, allUserRes, setIsReview, setIsDel, setCurrentInfo } = props
  const loginUser = gd.User.operator_id
  return [//convert
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '版本制作类型', bind: 'productType', width: 140, tooltip: true, convert: getProductType, sortable: true },
    { header: '制作人', bind: 'producter', width: 100, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'), sortable: true },
    { header: '版本制作复核人', bind: 'reviewProducter', width: 115, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'), sortable: true },
    { header: '复核时间', bind: 'reviewTime', width: 110, tooltip: true, sortable: true },
    {
      header: '复核结果', bind: 'reviewResult', width: 90, tooltip: true, convert: v => {
        if (v === 'Y') return '通过'
        else if (v === 'Y') return '不通过'
        return '-'
      }, sortable: true
    },
    { header: '前一版本号', bind: 'previousRelease', width: 160, tooltip: true, sortable: true, },
    { header: '本次版本号', bind: 'thisRelease', width: 160, tooltip: true, sortable: true, },
    { header: '版本制作基础', bind: 'makeBasic', width: 160, tooltip: true, sortable: true, },
    { header: '备注', bind: 'memo', width: 180, tooltip: true, sortable: true, },
    { header: '制作次数', bind: 'imakeTimes', width: 90, tooltip: true, sortable: true, },
    { header: '制作所用时间（H）', bind: 'imakeTime', width: 135, tooltip: true, sortable: true, },
    { header: '代码是否已备份', bind: 'saveCode', width: 120, tooltip: true, sortable: true, },
    { header: '代码备份位置', bind: 'codePosition', width: 600, tooltip: true, sortable: true, },
    { header: '操作', align: 'center', width: 110, convert: renderOperation },
  ]

  function getProductType(v) {
    const text = convertOptions(_.trim(v), versionTypeOpt)
    return <div className='enter-detail-field' onClick={() => setCurrentInfo({ id: v, mode: 'detail' })}>{text}</div>
  }

  function renderOperation(v, o) {
    const operateOption = [
      {
        text: '复核',
        disabled: loginUser !== o.reviewProducter,
        onClick: () => {
          if (loginUser !== o.reviewProducter) return
          setIsReview(_.trim(o.productType))
        }
      },
      {
        text: '删除',
        onClick: () => setIsDel(_.trim(o.productType))
      }
    ];
    return <OperationList options={operateOption} />;
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  fixedRight: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

export default function VersionMakingMsg(props) {
  const { currentId, allUserRes, isShow } = props
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const [isReview, setIsReview] = useState(null)
  const [isDel, setIsDel] = useState(null)
  const [isAdd, setIsAdd] = useState(false)
  const [productType, setProductType] = useState()
  const [currentInfo, setCurrentInfo] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch, data, loading, error } = useGet()
  const { data: versionTypeData } = useGet(VERSION_TYPE_URL)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const versionTypeOpt = useMemo(() => {
    if (_.isEmpty(versionTypeData)) return []
    return convertGlobalConstOptions(versionTypeData)
  }, [versionTypeData])

  const option = useMemo(() => {
    return getOptions(getColumns({ versionTypeOpt, allUserRes, setIsReview, setIsDel, setCurrentInfo }))
  }, [allUserRes, versionTypeOpt])

  const extra = useMemo(() => {
    return <div className='flex'>
      <TextIconBtn text='导出' icon='xiazai2' onClick={() => {
        if (isDisabled) return
        downloadGetProgress(`${EXPORT_URL}?${strParams({ releaseId: currentId })}`, '导出版本制作列表', () => { }, setIsDisabled)
      }} />
      {isShow && <TextIconBtn icon='tianjia' text='增加制作类型' onClick={() => setIsAdd(true)} />}
    </div>
  }, [downloadGetProgress, currentId, isDisabled, isShow])

  const productTypeList = useMemo(() => {
    const list = _.map(data, o => _.trim(o.productType))
    return _.filter(versionTypeOpt, o => !_.includes(list, o.value))
  }, [data, versionTypeOpt])

  const refreshList = useCallback(() => {
    if (isNil(currentId)) return
    doFetch(`${LIST_URL}?${strParams({ releaseId: currentId })}`)
  }, [currentId, doFetch])

  const onRowClick = useCallback((item) => {
    setProductType(item.productType)
  }, [])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  useEffect(() => {
    if (isNil(productType)) return
    _.forEach(data, o => {
      return o._rowClass = productType === o?.productType ? 'select_row' : ''
    })
    forceUpdate()
  }, [data, productType])

  return (
    <div className='version-making-msg'>
      <Box
        className='flex-y x-card-singlegrid'
        title='版本制作列表'
        loading={loading}
        error={error}
        data={data}
        extra={extra}
      >
        <DataGrid data={data} option={option} onRowClick={onRowClick} />
      </Box>
      {
        isAdd && <VersionMakingAddDialog close={() => setIsAdd(null)} {...{ currentId, versionTypeOpt: productTypeList, allUserRes, refreshList, productTypeList }} />
      }
      {
        _.get(currentInfo, 'mode') === 'detail' && <VersionMakingDetailDialog close={() => setCurrentInfo(null)} {...{ currentInfo, currentId, versionTypeOpt, allUserRes, refreshList, productTypeList }} />
      }
      {isReview && <VersionSelectLog {...{ currentId, isReview, refreshList }} close={() => setIsReview(false)} />}
      {
        isDel && <DelMessage close={() => setIsDel(null)} refresh={refreshList} url={DEL_URL + '?' + strParams({ releaseId: currentId, productType: isDel })}>
          确定删除？
        </DelMessage>
      }
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import _ from "lodash";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import { CustomizeFormInput } from "../common/customizeOptions/CustomizeOptions";
import { Box } from "../common/commonComponent";
import { DataGrid, Pagination, Messager } from "rootnet-ui";
import RequirementAddDialog from "./requirementAddDialog/RequirementAddDialog";
import RequirementDetailDialog from "./requirementDetailDialog/RequirementDetailDialog";
import { TextIconBtn } from "../common/TextIconBtn";
import './RequirementMgt.scss'
import useGetIdList from "../common/view/hooks/useGetIdList";
import useGetAppendData from "../common/view/hooks/useGetAppendData";
import { useGet } from "rootnet-biz/es/hooks";
import convertOptions from "../common/ConvertOptions";
import {
    REQUIREMENT_PRIORITY_COLOR,
    REQUIREMENT_STATUS_COLOR,
    REQUIREMENT_ANALYSISTIMEDOUT_COLOR,
} from "./requirementColor";
import { useFuncCode } from "../common/commonMethod";
import { API1 } from "../../base/task";
import ViewArea from "../common/view/ViewArea";
import { pathSearchFor } from '../../utils/publicFun';
import { Tag, Popover, Tooltip } from 'antd'
import convertGlobalConstOptions from "../common/ConvertGlobalConstOptions";
import WorkFlowChangePop from "../workFlow/workFlowChangePop/WorkFlowChangePop";
import Icon from "../../components/Icon";
import RequirementCopyDialog from "./requirementCopyDialog/RequirementCopyDialog";
import ApplyJoinVersionDialog from "./applyJoinVersionDialog/ApplyJoinVersionDialog";
import DelMessage from "../../components/DelMessage";
import clsx from "clsx";
import { isNil } from "rootnet-core/format";
import { CheckBox } from 'rootnet-edit';
import WorkFlowBatchCurrentUserDialog from "../workFlow/workFlowBatchCurrentUserDialog/WorkFlowBatchCurrentUserDialog";
import WorkFlowBatchCloseDialog from "../workFlow/workFlowBatchCloseDialog/WorkFlowBatchCloseDialog";
import { filterDisplayFlagOptions } from "../common/publicFunc";
import ConcernedIcon from '../common/concernedIcon';

const PRIMARY_KEY = 'storyId'
const ENTER_DETAIL_FIELD = 'story.title'

const EDITABLE_FIELD = [
    'story.priority', 'workflow_business.currentUser'
    // 'story.status'
    // 'story.currentUser'
]

const RICH_TEXT_FIELD = ['story_extend.custCommunicationLog']

const REPLACE_POST_PARAMS = {}

const SPECIAL_FIELD_CONVERT = {
    'story.analysisTimedOut': (value, showValue) => {
        return <div className='flex center-y'>
            <span style={{
                backgroundColor: REQUIREMENT_ANALYSISTIMEDOUT_COLOR[value],
                marginRight: 8,
                display: 'inline-block',
                width: 8,
                height: 8,
                borderRadius: '50%'
            }}></span>{showValue}
        </div>
    },
    'story.status': (value, showValue) => {
        return <div style={{ color: REQUIREMENT_STATUS_COLOR[value], border: `1px solid ${REQUIREMENT_STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
            {showValue}
        </div>
    },
    'story.priority': (value, showValue) => {
        return <div style={{ background: REQUIREMENT_PRIORITY_COLOR[value], padding: '0px 12px', color: '#fff', borderRadius: 4 }}>
            {showValue}
        </div>
    },
}

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, delAuth, setJoinVersionId,
        updateShowList, treeStageOptions, setEditOldValue, workFlowId, setWorkFlowId, refreshList, setCopyInfo, setDelInfo, canAddChildReq,
        handlePopId, setHandlePopId, onExpandIconClick, foldList, addChildDone, canCopy, getCanCopy, selectedIdList, setSelectedIdList,
        isSelectedAll, list, showParent } = props
    const { editId = null, editField = null } = editInfo || {}

    const customColumns = [
        {
            fieldId: 'statusExt', bind: 'addRes1', convert: v => {
                return <div className={'common-display-field flex center-y'}>
                    {/*<div style={{height: 8, width: 8, marginRight: 8, borderRadius: '50%', background: REQUIREMENT_CN_STATUS_COLOR[v]}} />*/}
                    {v}
                </div>
            }
        },
        {
            fieldId: 'releaseId', bind: 'list', convert: v => {
                return <div className={'common-display-field flex center-y'}>
                    {_.join(v, ', ')}
                </div>
            }, tooltip: true
        },
    ]

    return [
        {
            header: <div className={clsx('fist-col flex center', { 'selected': isSelectedAll })}>
                <div className={'check-box'} onClick={() => {
                    if (isSelectedAll) {
                        setSelectedIdList([])
                    } else {
                        setSelectedIdList(_.map(list, 'id'))
                    }
                }}>
                    <CheckBox value={isSelectedAll} />
                </div>
                <div className={'index-num'}>
                    #
                </div>
            </div>
            , width: 40, align: 'center', convert: (v, o, i) => {
                const id = _.get(o, 'id')
                return <div className={clsx('fist-col flex center', { 'selected': _.includes(selectedIdList, id) })}>
                    <div className={'check-box'}>
                        <CheckBox value={_.includes(selectedIdList, id)} onChange={() => {
                            if (_.includes(selectedIdList, id)) {
                                setSelectedIdList(old => _.filter(old, x => x !== id))
                            } else {
                                setSelectedIdList(old => _.concat(old, [id]))
                            }
                        }} />
                    </div>
                    <div className={'index-num'}>
                        {i + 1}
                    </div>
                </div>
            }
        },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        } else {
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: !_.includes(_.concat([ENTER_DETAIL_FIELD], RICH_TEXT_FIELD), `${fieldItem.tableName}.${fieldItem.fieldId}`),
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem) {
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if (!_.isNil(SPECIAL_FIELD_CONVERT[tableField])) {
            showValue = SPECIAL_FIELD_CONVERT[tableField](v, showValue)
        }
        if (_.includes(RICH_TEXT_FIELD, tableField)) {
            return <Tooltip title={<div className='req-rich-text-val' dangerouslySetInnerHTML={{ __html: showValue }} />}><div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div></Tooltip>
        }
        if (tableField === ENTER_DETAIL_FIELD) {
            return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
                <div className="left-enter-field-wrap flex center-y ">
                    {
                        _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') && showParent &&
                        <Icon name={_.includes(foldList, o.id) ? 'biaogezhankai' : 'biaogeshouqi'} className={'expand-icon'} onClick={() => onExpandIconClick(o.id)} />
                    }
                    {
                        _.get(o, '_isParent') && _.get(o, '_isEmptyChildren') && showParent &&
                        <div className={'empty-parent'} />
                    }
                    {
                        !_.get(o, '_isParent') && showParent &&
                        <div className={'empty-child'} />
                    }
                    {
                        _.get(o, '_isParent') && showParent &&
                        <Tag color="blue">父</Tag>
                    }
                    {
                        !_.get(o, '_isParent') && showParent &&
                        <Tag color="gold">子</Tag>
                    }
                    <div className="enter-detail-field flex">
                        <div className="enter-detail-field-text" onClick={() => {
                            setCurrentInfo({
                                id: _.get(o, 'id'),
                                mode: 'detail'
                            })
                        }}>
                            {showValue || '-'}
                        </div>
                    </div>
                </div>
                <ConcernedIcon
                    type='REQ'
                    referenceId={o?.id}
                    flag={_.get(o, 'perColl', 'N') === 'Y'}
                />
                {
                    showParent &&
                    <Popover open={_.get(o, 'id') === handlePopId && addChildDone} onOpenChange={visible => {
                        if (visible) {
                            setHandlePopId(_.get(o, 'id'))
                            if (o.pid) {
                                getCanCopy(o.pid)
                            }
                        } else {
                            setHandlePopId(null)
                        }
                    }}
                        trigger={'click'}
                        content={<div className={'requirement-handle-popover flex-y'}>
                            {
                                _.isNil(_.get(o, 'pid')) &&
                                <div className={clsx('requirement-handle-item flex center-y', { 'able': canAddChildReq, 'disabled': !canAddChildReq })} onClick={() => {
                                    if (canAddChildReq) {
                                        setCurrentInfo({
                                            mode: 'add',
                                            pid: _.get(o, 'id'),
                                            parentTitle: showValue
                                        })
                                        setHandlePopId(null)
                                    }
                                }}>
                                    <Icon name={'tianjia'} className={'handle-icon'} />
                                    <div className="handle-text">创建子需求</div>
                                </div>
                            }
                            <div className={clsx('requirement-handle-item flex center-y', { 'able': _.isNil(o.pid) || canCopy, 'disabled': !(_.isNil(o.pid) || canCopy) })} onClick={() => {
                                if (_.isNil(o.pid) || canCopy) {
                                    setCopyInfo({
                                        id: _.get(o, 'id'),
                                        title: showValue,
                                        isParent: _.isNil(_.get(o, 'pid'))
                                    })
                                    setHandlePopId(null)
                                }
                            }}>
                                <Icon name={'wendang'} className={'handle-icon'} />
                                <div className="handle-text">复制</div>
                            </div>
                            <div className={clsx('requirement-handle-item flex center-y', { 'able': !(_.get(o, '_isParent') && !_.get(o, '_isEmptyChildren')), 'disabled': _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') })} onClick={() => {
                                if (_.get(o, '_isParent') && !_.get(o, '_isEmptyChildren')) return
                                setJoinVersionId(o.id)
                                setHandlePopId(null)
                            }}>
                                <Icon name={'xinjianchanpinchi'} className={'handle-icon'} />
                                <div className="handle-text">申请加入已封板的版本</div>
                            </div>
                            {
                                delAuth &&
                                <div className="requirement-handle-item flex center-y del-item" onClick={() => {
                                    setDelInfo({
                                        id: _.get(o, 'id'),
                                        isParent: _.isNil(_.get(o, 'pid'))
                                    })
                                    setHandlePopId(null)
                                }}>
                                    <Icon name={'shanchu'} className={'handle-icon'} />
                                    <div className="handle-text">删除</div>
                                </div>
                            }
                        </div>} placement={'bottom'} destroyTooltipOnHide={true}>
                        <span>
                            <Icon name={'gengduo'} className={'more-handle-icon'} />
                        </span>
                    </Popover>
                }
            </div>
        }
        if (tableField === 'workflow_business.factorId') {
            const options = _.get(convertCollection, tableField)
            const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
            return <Popover destroyTooltipOnHide={true}
                content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={FUNC_CODE} businessType={'req'} />}
                onOpenChange={visible => {
                    if (!visible) {
                        setWorkFlowId(null)
                    }
                }}
                trigger={'click'}
                placement="bottom"
                open={o.id === workFlowId}
            >
                <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
                    style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
                    {showValue || '-'}
                </div>
            </Popover>
        }
        if (!_.includes(EDITABLE_FIELD, tableField)) {
            return <div className={'common-display-field'}>
                {showValue || '-'}
            </div>
        }
        if (o?.id === editId && editField === tableField) {
            const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
            const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
            return <CustomizeFormInput
                fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue}
                clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
                bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
                componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
                viewConditionType={fieldItem.viewConditionType} customOptions={tableField === 'story.status' ? treeStageOptions : null}
                onFocus={() => {
                    currentTableValueRef.current = handleValue
                    setEditOldValue(handleValue)
                }} tree={tableField === 'story.status'}
                onChange={value => {
                    if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
                        updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
                    } else {
                        currentTableValueRef.current = value
                    }
                }}
                onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
                onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
            />
        }
        return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
            {showValue || '-'}
        </div>
    }

    function onEditFieldClick({ o, fieldItem }) {
        const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === ENTER_DETAIL_FIELD), 'columnId')
        setEditInfo({
            editId: o?.id,
            title: _.get(o, titleColumnId),
            editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
            fieldId: fieldItem.fieldId,
            columnId: fieldItem.columnId,
            fieldHtmlType: fieldItem.fieldHtmlType,
            viewConditionType: fieldItem.viewConditionType,
        })
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 100
    }
}

// const INIT = {id: "918923175008403456", mode: "edit"}

const OPTIONS_URLS = [
    'common/globalconst?globalConst=ReqStatus',
]

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=storyType',
]

const FUNC_CODE = '1501'

function RequirementMgt(props) {
    const { location, appendObj, outerFuncCode, showParent = true } = props;
    const { customer: customerCS = null, CSRecordID, relateCSAttach, cleanAll, isCS } = window.external
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const replaceAllParams = useMemo(() => {
        if (!!customerCS) return ({ 'story.customer': customerCS })
        return null
    }, [customerCS])

    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(100)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(outerFuncCode || FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const [focusId, setFocusId] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [sortConfig, setSortConfig] = useState()
    const [factor, setFactor] = useState()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [initType, setInitType] = useState('CUS')
    const [showAddType, setShowAddType] = useState(false)
    const [workFlowId, setWorkFlowId] = useState()
    const [copyInfo, setCopyInfo] = useState()
    const [delInfo, setDelInfo] = useState()
    const [handlePopId, setHandlePopId] = useState()
    const [joinVersionId, setJoinVersionId] = useState()
    const [foldList, setFoldList] = useState([])
    const [canAddChildReq, setCanAddChildReq] = useState(false)
    const { doFetch: authDoFetch } = useGet()
    const [addChildDone, setAddChildDone] = useState(false)
    const [canCopy, setCanCopy] = useState(false)
    const showAllNodesRef = useRef(true)
    const [selectedIdList, setSelectedIdList] = useState([])
    const afterViewRefresh = useCallback(() => { setSelectedIdList([]) }, [])
    const [isSelectedAll, setIsSelectedAll] = useState()
    const [showDialogInfo, setShowDialogInfo] = useState()

    useEffect(() => {
        if (_.isNil(handlePopId)) return
        authDoFetch('/story/getContainStory?id=' + handlePopId).then(res => {
            setCanAddChildReq(res)
            setAddChildDone(true)
        })
    }, [authDoFetch, handlePopId])

    useEffect(() => {
        if (_.isNil(handlePopId)) {
            setCanAddChildReq(false)
            setAddChildDone(false)
        }
    }, [handlePopId])

    const getCanCopy = useCallback((pid) => {
        authDoFetch('/story/getContainStory?id=' + pid).then(res => {
            setCanCopy(res)
        })
    }, [authDoFetch])

    const [storyTypeOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    useEffect(() => {
        if (_.isNil(CSRecordID)) return
        setCurrentInfo({
            mode: 'add',
            CSRecordID,
            relateCSAttach
        })
    }, [CSRecordID, relateCSAttach])

    // 表格编辑
    const currentTableValueRef = useRef()
    const [editInfo, setEditInfo] = useState()
    const [showDataGridList, setShowDataGridList] = useState()
    const { doFetch: editTable } = usePost()
    const [editOldValue, setEditOldValue] = useState()

    const [stageOptions, treeStageOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes || {}
        const treeStageOptions = _.map(_.groupBy(d1, x => x.memo), (stateList, stageText) => {
            return ({
                text: stageText,
                value: stageText,
                _disabled: true,
                children: _.map(stateList, x => ({ value: x.interiorId, text: x.displayName }))
            })
        })
        return [
            _.map(d1, x => ({ value: x.interiorId, text: x.memo })),
            treeStageOptions
        ]
    }, [optionsRes])

    const updateShowList = useCallback(({ newValue, primaryId }) => {
        if (_.isNil(editInfo)) return
        const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
        if (JSON.stringify(updateValue) === JSON.stringify(editOldValue)) {
            setEditInfo(null)
            currentTableValueRef.current = null
            return
        }
        let postParams = {
            // defectId: primaryId,
            ids: [editInfo['editId']]
        }
        const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
        if (_.isNil(replaceParams)) {
            // postParams[editInfo['fieldId']] = updateValue
            postParams['fieldName'] = editInfo['fieldId']
            postParams['fieldValue'] = updateValue
        } else {
            postParams[replaceParams] = updateValue
        }
        if (editInfo['fieldId'] === 'currentUser') {
            if (isNil(updateValue)) {
                setEditInfo(null)
                currentTableValueRef.current = null
                return
            }
            const currentUserParams = {
                businessId: _.get(editInfo, 'editId'),
                businessType: 'req',
                funcCode: FUNC_CODE,
                currentUser: updateValue,
                title: _.get(editInfo, 'title')
            }
            editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                afterRefresh()
            }).catch((err) => {
                Messager.show(err._message, { icon: 'error' });
            })
        } else {
            editTable('/story/updateSingle', postParams).then(() => {
                afterRefresh()
            }).catch((err) => {
                Messager.show(err._message, { icon: 'error' });
            })
        }

        function afterRefresh() {
            setShowDataGridList(oldList => {
                const cloneList = _.clone(oldList)
                _.forEach(cloneList, item => {
                    if (item.id === _.get(editInfo, 'editId')) {
                        item[editInfo.columnId] = updateValue
                        if (editInfo.editField === 'story.status') {
                            item['addRes1'] = convertOptions(updateValue, stageOptions)
                        }
                    }
                })
                return cloneList
            })
            setEditInfo(null)
            currentTableValueRef.current = null
            Messager.show('修改成功', { icon: 'success' });
        }
    }, [editInfo, editTable, editOldValue, stageOptions])

    useEffect(() => {
        if (_.isNil(editInfo)) return
        const allInput = document.querySelector('.requirement-data-grid').querySelectorAll('input')
        const input = _.find(allInput, x => !_.includes(x.parentElement.classList, "rootnet-checkbox"))
        // let selections = [...allInput].filter(element => !element.closest(".rootnet-checkbox"));
        // const input = document.querySelector('.requirement-data-grid').querySelector('input')
        if (!_.isNil(input)) {
            input.focus()
            input.click()
        }
    }, [editInfo])

    useEffect(() => {
        if (_.isNil(editInfo)) return
        const rootnetSelect = document.querySelector('.requirement-data-grid').querySelector('.rootnet-select')
        if (rootnetSelect) {
            rootnetSelect.addEventListener('mousewheel', e => {
                e.cancelBubble = true
            })
        }
    }, [editInfo])

    // C端列表默认查询

    const idList = useGetIdList(list)
    const appendData = useGetAppendData(idList, '/story/viewInterface')

    const appendList = useMemo(() => {
        const appendFieldList = _.map(appendData, x => _.pick(x, ['addRes1', 'list']))
        _.forEach(appendFieldList, item => {
            _.forEach(item, (value, key) => {
                if (key === 'addRes1') {
                    item[key] = convertOptions(value, stageOptions)
                } else {
                    item[key] = value
                }
            })
        })
        // 数字类型字段排序
        let decimalList = list
        _.forEach(fieldList, fieldItem => {
            if (fieldItem['fieldDbType'] === "DECIMAL") {
                _.forEach(decimalList, item => {
                    item[fieldItem.columnId] = _.toNumber(item[fieldItem.columnId])
                })
            }
        })
        return _.map(decimalList, (x, i) => _.assign(x, appendFieldList[i]))
    }, [list, appendData, fieldList, stageOptions])

    useEffect(() => {
        setShowDataGridList(appendList)
    }, [appendList])

    const appendObjs = useMemo(() => {
        return { ...appendObj, collectionType: 'REQ' }
    }, [appendObj])

    const refreshList = useRefreshList({
        currentViewId, params, allColumns, getList, setParams, funcCode,
        getFactor: setFactor, showAllNodes: showAllNodesRef.current ? 'Y' : 'N', afterRefresh: afterViewRefresh, appendObj: appendObjs
    })

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const dateOptions = useGetDateOptions()

    const [addAuth, allDetailAuth, delAuth] = useFuncCode(['150101', '1507', '1503'])

    const onExpandIconClick = useCallback((id) => {
        setFoldList(oldList => {
            if (_.includes(oldList, id)) {
                return _.filter(oldList, x => x !== id)
            } else {
                return _.concat(oldList, [id])
            }
        })
    }, [])

    const { options, dataGridList } = useGetTreeList({
        fieldList, list: showDataGridList, convertCollection,
        dateOptions, getOptions, getColumns, sortable: true,
        columnsAppendParams: {
            setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, updateShowList, delAuth, setJoinVersionId, canAddChildReq, addChildDone,
            treeStageOptions, setEditOldValue, workFlowId, setWorkFlowId, refreshList, setCopyInfo, setDelInfo, handlePopId, setHandlePopId, onExpandIconClick, foldList,
            canCopy, getCanCopy, selectedIdList, setSelectedIdList, isSelectedAll, list, showParent
        },
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                if (_.isEmpty(data)) return []
                setSortConfig(sort)
                const sortAllList = data.sort((a, b) => sortFun(a, b, sort.column))
                const sortAllDirectionList = sort.direction === 'asc' ? sortAllList : _.reverse(_.clone(sortAllList))
                const sortFirstLevelDirectionList = _.filter(sortAllDirectionList, x => _.isNil(_.get(x, 'pid')))
                let handleList = []
                _.forEach(sortFirstLevelDirectionList, item => {
                    const childrenList = _.filter(sortAllDirectionList, x => _.get(x, 'pid') === item.id)
                    handleList.push(item)
                    _.forEach(childrenList, x => {
                        handleList.push(x)
                    })
                })
                return handleList
            }
        },
    })

    const showChildList = useMemo(() => {
        return _.filter(dataGridList, x => !_.get(x, '_children')) || []
    }, [dataGridList])

    const handleList = useMemo(() => {
        let allList
        if (_.isNil(sortConfig)) {
            allList = showChildList || []
        } else {
            const sortList = _.sortBy(showChildList || [], x => x[sortConfig.column])
            allList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
        }
        const firstLevelList = _.filter(allList, x => _.isNil(_.get(x, 'pid')))
        let handleList = []
        _.forEach(firstLevelList, item => {
            let parentItem = { ...item }
            const childrenList = _.filter(allList, x => _.get(x, 'pid') === item.id)
            const childrenIdList = _.map(childrenList, x => x.id)
            parentItem = { ...parentItem, childrenIdList, _isParent: true, _isEmptyChildren: _.isEmpty(childrenList) }
            handleList.push(parentItem)
            _.forEach(childrenList, x => {
                handleList.push({ ...x, _isParent: false })
            })
        })
        return handleList
    }, [showChildList, sortConfig])

    const showList = useMemo(() => {
        return _.filter(handleList, x => !_.includes(foldList, _.get(x, 'pid')))
    }, [handleList, foldList])

    useEffect(() => {
        if (_.isEmpty(showList)) return setIsSelectedAll(false)
        setIsSelectedAll(_.size(selectedIdList) === _.size(showList))
    }, [showList, selectedIdList])

    useEffect(() => {
        if (_.isNil(focusId)) return
        _.forEach(showList, o => {
            return o._rowClass = o?.id === focusId ? 'select_row' : ''
        })
        forceUpdate()
    }, [focusId, showList])

    const onRowClick = useCallback((item) => {
        setFocusId(item.id)
    }, [])

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            ...appendObj,
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: outerFuncCode || FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        API1.downloadPost('/UserSetting/exportExcel', postParams)
    }, [currentViewId, fieldList, factor, outerFuncCode, appendObj])

    const AddTypeSelect = useCallback(() => {
        // onClick={() => { setCurrentInfo({mode: 'add'}) }}
        const displayStoryTypeOptions = filterDisplayFlagOptions(storyTypeOptions)
        const filterData = _.filter(displayStoryTypeOptions, o => o.value !== 'DEV')
        return <div className={'story-type-add-group flex-y'}>
            {
                _.map(filterData, item => (
                    <div className="story-type-item flex center-y" key={item.value} onClick={() => {
                        setInitType(item.value)
                        setCurrentInfo({ mode: 'add' })
                        setShowAddType(false)
                    }}>
                        <div className="type-tag flex center">{item.value}</div>
                        <div className="type-name">{item.text}</div>
                    </div>
                ))
            }

        </div>
    }, [storyTypeOptions])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <TextIconBtn icon='bianji' text='修改处理人' disabled={_.isEmpty(selectedIdList)} onClick={() => setShowDialogInfo({ module: 'editCurrentUser' })} />
            <TextIconBtn icon='bianji' text='置为关闭' disabled={_.isEmpty(selectedIdList)} onClick={() => setShowDialogInfo({ module: 'closeWorkFlow' })} />
            {
                !isCS &&
                <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
            }
            {
                addAuth &&
                <Popover content={<AddTypeSelect />} trigger="click" placement="bottomRight" open={showAddType} onOpenChange={setShowAddType}>
                    <TextIconBtn text='新增' icon='tianjia' />
                </Popover>
            }
        </div>
    }, [addAuth, exportViewList, isCS, showAddType, selectedIdList])

    const currentIndex = useMemo(() => {
        const currentId = _.get(currentInfo, 'id')
        if (_.isNil(currentId)) return 0
        return _.findIndex(showList, x => _.get(x, 'id') === currentId)
    }, [currentInfo, showList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentInfo(old => {
            const cloneObj = { ...old }
            const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
            cloneObj['id'] = newId
            setFocusId(newId)
            return cloneObj
        })
    }, [showList, currentIndex])

    const extraBtnArea = useMemo(() => {
        return showParent && <div className={'flex center-y'}>
            <CheckBox defaultValue={true} onChange={v => { showAllNodesRef.current = v }}
                style={{ marginTop: 6 }}
            >全部子需求</CheckBox>
        </div>
    }, [showParent])

    const [bizIdField, titleField] = useMemo(() => {
        if (_.isEmpty(fieldList)) return []
        const bizIdField = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'story.storyId'), 'columnId')
        const titleField = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'story.title'), 'columnId')
        return [bizIdField, titleField]
    }, [fieldList])

    const selectedItems = useMemo(() => {
        return _.map(selectedIdList, id => {
            const findItem = _.find(dataGridList, x => x.id === id)
            return {
                businessId: id,
                businessIdName: _.get(findItem, bizIdField),
                title: _.get(findItem, titleField),
            }
        })
    }, [selectedIdList, dataGridList, bizIdField, titleField])

    return (
        <div className={'requirement-mgt fill flex-y'}>
            <ViewArea funcCode={outerFuncCode || FUNC_CODE} allOptions={optionsConfig} search={setParams} resetSearch={cleanAll} loading={boxLoading}
                {...{
                    getFieldList, allColumns, replaceAllParams, refreshList, total, getInitParams, optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize, extraBtnArea
                }} />
            <Box title={'需求管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                <DataGrid option={options} data={showList} onRowClick={onRowClick} className={'requirement-data-grid'} />
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, { pageNum, pageSize }))
                    }} />
            </Box>
            {
                joinVersionId &&
                <ApplyJoinVersionDialog id={joinVersionId} close={() => setJoinVersionId(null)} type={'REQ'} />
            }
            {
                !_.isNil(copyInfo) &&
                <RequirementCopyDialog close={() => setCopyInfo(null)} {...{ copyInfo, refreshList, setCurrentInfo }} />
            }
            {
                _.get(currentInfo, 'mode') === 'add' &&
                <RequirementAddDialog afterRefresh={refreshList} close={() => setCurrentInfo(null)} {...{ currentInfo, initType }} />
            }
            {
                _.includes(['detail', 'edit'], _.get(currentInfo, 'mode')) &&
                <RequirementDetailDialog refreshViewList={refreshList} close={() => setCurrentInfo(null)} setCurrentInfo={setCurrentInfo}
                    outerAllDetailAuth={allDetailAuth} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, delAuth, storyTypeOptions }} />
            }
            {
                !_.isNil(delInfo) &&
                <DelMessage close={() => setDelInfo(null)} refresh={refreshList} method={'get'} url={'/story/deleteStory?id=' + _.get(delInfo, 'id')}>
                    {
                        _.get(delInfo, 'isParent') ? '父需求删除，其下子需求一同删除，是否继续？' : '是否删除该需求？'
                    }
                </DelMessage>
            }
            {
                _.get(showDialogInfo, 'module') === 'editCurrentUser' &&
                <WorkFlowBatchCurrentUserDialog list={selectedItems} funcCode={FUNC_CODE} businessType={'req'}
                    close={() => setShowDialogInfo(null)} afterRefresh={refreshList} />
            }
            {
                _.get(showDialogInfo, 'module') === 'closeWorkFlow' &&
                <WorkFlowBatchCloseDialog list={selectedItems} funcCode={FUNC_CODE} businessType={'req'} bizName={'需求'}
                    close={() => setShowDialogInfo(null)} afterRefresh={refreshList} />
            }
        </div>
    )
}

function sortFun(a, b, bind) {
    if (_.isNil(a[bind])) return -1
    const aStr = isNil(a[bind]) ? '' : a[bind]
    const bStr = isNil(b[bind]) ? '' : b[bind]
    if (_.isNumber(a[bind]) && _.isNumber(b[bind])) {
        return a[bind] - b[bind]
    }
    if (_.isNumber(a[bind]) ^ _.isNumber(b[bind])) {
        return (_.toNumber(a[bind]) || 0) - (_.toNumber(b[bind]) || 0)
    }
    return aStr.localeCompare(bStr)
}

export default RequirementMgt;
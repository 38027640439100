import {useGet} from "../../../../utils/hook";
import React, {useEffect, useMemo, useReducer} from "react";
import _ from "lodash";
import {DatePicker, FormInput, Select} from "rootnet-edit";
import UserSelect from "../../personSelectPop/UserSelect";
import {isNil} from "rootnet-core/format";
import CascadeSelect from "../../cascadeSelect/CascadeSelect";
import convertGlobalConstOptions from "../../ConvertGlobalConstOptions";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import DateSelector from "../../dateSelector/DateSelector";
import moment from "moment";
import './DynamicFormInput.scss'

const {dateStringToDate} = toDate

const DynamicFormInput = props => {
    const {conditionItem,filterColumnsOptions, dynamicTimeOptions, disabled, dynamicDepartmentOptions, dynamicPersonOptions, dynamicDateOptions} = props
    const {data: optionsRes, doFetch: getOptions} = useGet()
    const {relation} = conditionItem
    const [countDynamicTime, forceUpdate] = useReducer((x) => x + 1, 0)

    const [dynamicTimeFirst, dynamicTimeSecond] = useMemo(()=>{
        console.log('render dynamic time', countDynamicTime)
        const dynamicTimeFirst = getPreviewTime(_.get(conditionItem,'firstCount'), _.get(conditionItem,'firstDTStyle'))
        const dynamicTimeSecond = getPreviewTime(_.get(conditionItem,'secondCount'), _.get(conditionItem,'secondDTStyle'))
        return [dynamicTimeFirst, dynamicTimeSecond]
    },[conditionItem, countDynamicTime])

    const [fieldDateSource,fieldDateDetailSource, fieldHtmlType, fieldViewConditionType] = useMemo(()=>{
        const field = _.get(conditionItem, 'field')
        const currentItem = _.find(filterColumnsOptions, x => x.value === field)
        const fieldDateSource = _.get(currentItem, 'fieldDateSource')
        const fieldDateDetailSource = _.get(currentItem, 'fieldDateDetailSource')
        const fieldHtmlType = _.get(currentItem,'fieldHtmlType')
        const fieldViewConditionType = _.get(currentItem,'viewConditionType')
        return [fieldDateSource, fieldDateDetailSource, fieldHtmlType, fieldViewConditionType]
    },[conditionItem,filterColumnsOptions])

    useEffect(()=>{
        if(_.includes(['In','Not In'], relation) && !_.isNil(fieldDateSource) && !_.isNil(fieldDateDetailSource)){
            getOptions(fieldDateDetailSource)
        }
    },[getOptions,fieldDateDetailSource,fieldDateSource,relation])

    if(_.includes(['Null','Not Null','Is Me','Empty','NonEmpty'], relation)){
        return <span/>
    }else if(fieldViewConditionType === 'date' && _.includes(['>=', '<='], relation) && _.get(conditionItem,'dynamicSign') === 'y'){
        // 动态时间
        return <FormInput componentWidth={260} bind={'value'} component={Select} disabled={disabled}
                          options={dynamicDateOptions} />
    }else if(fieldViewConditionType === 'personnel' && _.get(conditionItem,'dynamicSign') === 'y'){
        // 动态人员
        return <FormInput componentWidth={260} bind={'value'} component={Select} disabled={disabled}
                          options={dynamicPersonOptions} />
    }else if(fieldViewConditionType === 'personnel'){
        // 人员
        const multiple = _.includes(['In','Not In','Like','Not Like'], relation)
        return <FormInput componentWidth={260} bind={'value'} multiple={multiple} component={UserSelect} disabled={disabled}
                          bindInConvert={v => {
                              if(isNil(v)) return null
                              return multiple?_.split(v,','):v
                          }} bindOutConvert={v => multiple?(v || []).join(','):v}
        />
    }else if(fieldViewConditionType === 'departmentType' && _.get(conditionItem,'dynamicSign') === 'y'){
        // 动态部门
        return <FormInput componentWidth={260} bind={'value'} component={Select} disabled={disabled}
                          options={dynamicDepartmentOptions} />
    }else if(_.includes(['In','Not In'], relation) && !_.isNil(fieldDateSource) && !_.isNil(fieldDateDetailSource) && _.includes(['7','8'],fieldHtmlType)){
        const displayOptions = _.filter(optionsRes, x => _.get(x,'displayFlag') !== 'N')
        return <FormInput componentWidth={260} bind={'value'} multiple search component={CascadeSelect} disabled={disabled}
                          options={convertGlobalConstOptions(displayOptions)} bindInConvert={v => v?_.split(v,','):null} bindOutConvert={v => (v || []).join(',')}/>
    }else if(_.includes(['In','Not In'], relation) && !_.isNil(fieldDateSource) && !_.isNil(fieldDateDetailSource)){
        const displayOptions = _.filter(optionsRes, x => _.get(x,'displayFlag') !== 'N')
        return <FormInput componentWidth={260} bind={'value'} multiple search component={Select} disabled={disabled}
                          options={convertGlobalConstOptions(displayOptions)} bindInConvert={v => _.split(v,',')} bindOutConvert={v => (v || []).join(',')}/>
    }else if(_.includes(['Like','Not Like','=','<>','In','Not In'], relation)){
        return <FormInput componentWidth={260} bind={'value'} disabled={disabled}/>
    }else if(_.includes(['>=', '<='], relation) && fieldViewConditionType !== 'date'){
        return <FormInput componentWidth={260} bind={'value'} disabled={disabled}/>
    }else if(_.includes(['>=', '<='], relation) && fieldViewConditionType === 'date'){
        return <FormInput componentWidth={260} bind={'value'} component={DatePicker} mode='datetime' disabled={disabled}
                          bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
    }else if(relation === 'betweenAnd'){
        return <FormInput {...props} componentWidth={260} bind={'value'} component={DateSelector} range
                          bindInConvert={convertInRangeTime} bindOutConvert={convertOutRangeTime}/>
    }else if(relation === 'dynamicTime'){
        return <span className={'dynamic-time-wrap flex center-y'}>
            <FormInput {...props} componentWidth={50} bind={'firstCount'} placeholder={''} type={'number'} onChange={()=>forceUpdate()}/>
            <FormInput {...props} componentWidth={62} bind={'firstDTStyle'} component={Select} options={dynamicTimeOptions} placeholder={''} onChange={()=>forceUpdate()}/>
            <span style={{marginRight: 6}}>-</span>
            <FormInput {...props} componentWidth={50} bind={'secondCount'} placeholder={''} type={'number'} onChange={()=>forceUpdate()}/>
            <FormInput {...props} componentWidth={62} bind={'secondDTStyle'} component={Select} options={dynamicTimeOptions} placeholder={''} onChange={()=>forceUpdate()}/>
            <div className={'preview-time first'} key={countDynamicTime}>
                {dynamicTimeFirst}
            </div>
            <div className={'preview-time second'} key={countDynamicTime + 100}>
                {dynamicTimeSecond}
            </div>
        </span>
    }else{
        return <span/>
    }
}

function getPreviewTime(num, type){
    if(type === 'theDay'){
        return moment().format('YYYY-MM-DD')
    }else if(type === 'beforeTheDay'){
        return moment().subtract(_.toNumber(num) || 0, 'days').format('YYYY-MM-DD')
    }else if(type === 'beforeTheWeek'){
        return moment().subtract(_.toNumber(num) || 0, 'weeks').format('YYYY-MM-DD')
    }else if(type === 'beforeTheMonth'){
        return moment().subtract(_.toNumber(num) || 0, 'months').format('YYYY-MM-DD')
    }else if(type === 'afterTheDay'){
        return moment().add(_.toNumber(num) || 0, 'days').format('YYYY-MM-DD')
    }else if(type === 'afterTheWeek'){
        return moment().add(_.toNumber(num) || 0, 'weeks').format('YYYY-MM-DD')
    }else if(type === 'afterTheMonth'){
        return moment().add(_.toNumber(num) || 0, 'months').format('YYYY-MM-DD')
    }else{
        return null
    }
}

function convertInTime(v){
    return _.isNil(v)? null: _.replace(v, /'.0'/g,'')
}

// function convertOutTime(v){
//     return _.isNil(v)? null: v + '.0'
// }

// function convertInRangeTime(v){
//     if(_.isNil(v)) return null
//     const obj = JSON.parse(v)
//     return {
//         begin: dateStringToDate(convertInTime(_.get(obj, 'begin'))),
//         end: dateStringToDate(convertInTime(_.get(obj, 'end'))),
//     }
// }
//
// function convertOutRangeTime(v){
//     if(_.isNil(v)) return null
//     return JSON.stringify(
//         {
//             begin: convertOutTime(dateFormat('YYYY-MM-DD HH:MM:SS',_.get(v, 'begin'))),
//             end: convertOutTime(dateFormat('YYYY-MM-DD HH:MM:SS',getLastDate(_.get(v, 'end')))),
//         }
//     )
// }

// function getLastDate(date){
//     if(_.isNil(date)) return null
//     return new Date(new Date(date.toLocaleDateString()).getTime()+24*60*60*1000-1)
// }

function convertInRangeTime(v){
    if(isNil(v)) return null
    const obj = JSON.parse(v)
    return {
        begin: dateStringToDate(convertInTime(_.get(obj, 'begin'))),
        end: dateStringToDate(convertInTime(_.get(obj, 'end'))),
    }
}

function convertOutRangeTime(v){
    if(isNil(v)) return null
    return JSON.stringify(
        {
            begin: _.get(v, 'begin') + ' 00:00:00.0',
            end: _.get(v, 'end') + ' 23:59:59.9',
        }
    )
}

export default DynamicFormInput

import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Steps } from 'antd'
import { SelectionMethod, Verification, ResetPassword, ResetSuccess } from './stepStyle'
import logo from '../../assets/images/rootnetLogo.png'
import './index.scss'
import {pathSearchFor} from "../../utils/publicFun";

const { Step } = Steps

const steps = [
  { id: 0, title: '选择验证方式' },
  { id: 1, title: '验证' },
  { id: 2, title: '重置密码' },
  { id: 3, title: '重置成功' },
]

export default function ForgetPassword(props) {
  const { userName = null } = useMemo(() => pathSearchFor(_.get(props.location, 'search')), [props]);
  const [current, setCurrent] = useState(0)
  const [action, setAction] = useState()
  const [user, setUser] = useState(userName)

  const loginPage = useCallback(() => props.history.push('/login'), [props])

  const content = useMemo(() => {
    const next = () => { setCurrent(x => x + 1) }
    const prev = () => { setCurrent(x => x - 1) }
    if (current === 0) return <SelectionMethod {...{ setAction, next }} />
    else if (current === 1) return <Verification {...{ action, next, prev, setUser, user }} />
    else if (current === 2) return <ResetPassword  {...{ next, action, user }} />
    else if (current === 3) return <ResetSuccess loginPage={loginPage} />
  }, [action, current, loginPage, user])

  return (
      <div className='forget-password'>

        {/* 头部 */}
        <div className='header'>
          <div className='width-center'>
            <img src={logo} alt='' onClick={loginPage} />
            <div className='header-text'>
              <div>找回密码</div>
              <div>请您按照操作步骤填写找回密码</div>
            </div>
          </div>
        </div>

        {/* finish  process  wait*/}
        <div className='forget-password-content flex-y'>
          <div className='width-center flex-y'>
            <div className='step-process'>
              <Steps current={current} >
                {_.map(steps, item => <Step key={item.id} title={item.title} />)}
              </Steps>
            </div>

            {content}

          </div>
        </div>

      </div>
  )
}


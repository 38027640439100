import React, { useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { MessageBox } from 'rootnet-ui'
import './ArrowCrumbs.scss'

export default function ArrowCrumbs(props) {
  const { data, goBack, messageInfo, useTips } = props
  const [message, setMessage] = useState(false);
  return (
    <>
      <div className='arrow-crumbs'>
        <ul className='breadcrumb'>
          {
            _.map(data, (item, i) => {
              return <li
                key={i}
                className={cls('breadcrumb-item', { active: item?.active })}
                onClick={() => _.isNil(item.onClick) ? onClick(i, useTips) : item.onClick()}
              >
                {item.text}
              </li>
            })
          }
        </ul>
      </div>
      {
        message &&
        <MessageBox
          className={'content-center-dialog'}
          header="提示"
          cancel={() => setMessage(false)}
          confirm={goBack}
        >
          {messageInfo}
        </MessageBox>
      }
    </>
  )
  function onClick(index, flag) {
    if (flag) return setMessage(true)
    if (index === 0) return goBack()
    return null
  }
}

ArrowCrumbs.defaultProps = {
  messageInfo: '离开当前页面，内容将不被保存',
  useTips: false
}


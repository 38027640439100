import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import './CustomerDetail.scss'
import { Button, Messager } from 'rootnet-ui'
import { Badge, Tabs } from 'antd'
import ArrowCrumbs from "../../../common/ArrowCrumbs";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import _ from 'lodash'
import { isNil } from "../../../appraise/components/method";
import CustomerInfo from "./customerInfo/CustomerInfo";
import VersionUpdateRecord from "./versionUpdateRecord/VersionUpdateRecord";
import ChangeRecord from "../../../common/ChangeRecord";
import RequirementUploadArea
    from "../../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea";
import ContactPerson from "./contactPerson/ContactPerson";
import SpecialComponentCatalog from "./specialComponentCatalog/SpecialComponentCatalog";
import UsedProduct from "./usedProduct/UsedProduct";
import EmailReminder from "./emailReminder/EmailReminder";
import { FormInput, Select } from "rootnet-edit";
import CustomerInformation from './customerInformation';
import ApiAuthList from './apiAuthList';

const { TabPane } = Tabs;

function CustomerDetail(props) {
    const { detailInfo, setDetailInfo, setCurrentId, refreshViewList } = props
    const { id: initId } = useMemo(() => detailInfo || {}, [detailInfo])
    const [activeTab, setActiveTab] = useState('baseInfo')
    const { data: allUserRes } = useGet('/common/userinfo')
    const { data: allSystem, doFetch: getAllSystem } = useGet()
    const [formData, setFormData] = useState()
    const [editFormData, setEditFormData] = useState()
    const [infoError, setInfoError] = useState()
    const [mode, setMode] = useState('detail')
    const { doFetch: getDetail } = useGet()
    const { doFetch: submitChange } = usePost()
    const [dynamicCount, updateDynamic] = useReducer((x) => x + 1, 0)
    const [countDoc, updateDoc] = useReducer((x) => x + 1, 20000) // 附件
    const [fileCount, setFileCount] = useState(0)
    const [id, setId] = useState(initId)

    const { configurationTopic, customerID } = useMemo(() => formData || {}, [formData])

    useEffect(() => {
        getAllSystem('/common/system')
    }, [getAllSystem])

    const allSystemOptions = useMemo(() => {
        if (_.isEmpty(allSystem)) return []
        return _.sortBy(_.map(allSystem, x => ({ text: x.projectName, value: x.id, projectId: x.projectId })), 'projectId')
    }, [allSystem])

    useEffect(() => {
        if (_.isNil(id)) return
        updateDynamic()
        updateDoc()
    }, [id])

    useEffect(() => {
        setEditFormData(formData)
    }, [formData])

    const refreshDetail = useCallback(() => {
        getDetail('/bug/project/customerinfo?id=' + id).then(res => {
            let handleData = { ...res }
            handleData['emailList'] = _.filter(_.split(_.get(res, 'emailList'), ';'), x => !isNil(x))
            handleData['cclist'] = _.filter(_.split(_.get(res, 'cclist'), ';'), x => !isNil(x))
            setFormData(handleData)
        })
    }, [getDetail, id])

    useEffect(() => {
        refreshDetail()
    }, [refreshDetail])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const switchMode = useCallback(() => {
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [])

    const arrowCrumbsData = useMemo(() => {
        return [
            { text: '客户系统管理' },
            {
                text: <FormInput search horizontal component={Select} options={allSystemOptions} value={id}
                    componentWidth={230}
                    onChange={newId => {
                        setId(newId)
                        setCurrentId(newId)
                        setMode('detail')
                    }} />,
                active: true,
            }
        ]
    }, [allSystemOptions, id, setCurrentId])

    const canSubmit = useMemo(() => {
        if (_.isEmpty(_.get(editFormData, 'emailList'))) return false
        if (_.isEmpty(_.get(editFormData, 'cclist'))) return false
        return _.every(infoError, _.isNil)
    }, [infoError, editFormData])

    const submit = useCallback(() => {
        const postParams = _.clone(editFormData)
        postParams['emailList'] = handleEmail(_.get(editFormData, 'emailList'))
        postParams['cclist'] = handleEmail(_.get(editFormData, 'cclist'))
        postParams['id'] = id
        submitChange('/bug/project/update/customerinfo', postParams).then(() => {
            Messager.show('修改成功', { icon: 'success' })
            refreshDetail()
            updateDynamic()
            if (!_.isNil(refreshViewList)) {
                refreshViewList()
            }
            setMode('detail')
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })

        function handleEmail(list) {
            let emailString = ''
            _.forEach(list, x => {
                emailString += x + ';'
            })
            return emailString
        }
    }, [submitChange, editFormData, refreshDetail, refreshViewList, id])

    const extraRight = useMemo(() => {
        return <div className={'extra-right-wrap flex'}>
            {
                activeTab === 'baseInfo' && !isDetail && <Button primary className={'edit-btn'} disabled={!canSubmit} onClick={() => {
                    submit()
                }}>保存</Button>
            }
            {
                activeTab === 'baseInfo' &&
                <Button normal className={'edit-btn'} onClick={() => {
                    switchMode()
                    setEditFormData(formData)
                }}>{isDetail ? '编辑' : '退出编辑'}</Button>
            }
        </div>
    }, [isDetail, formData, switchMode, canSubmit, submit, activeTab])

    const tabBarExtraContent = useMemo(() => {
        return {
            left: <ArrowCrumbs data={arrowCrumbsData} goBack={() => setDetailInfo(null)} />,
            right: extraRight,
        }
    }, [arrowCrumbsData, setDetailInfo, extraRight])

    return <div className={'customer-detail-wrap flex-y fill'}>
        <div className="customer-detail flex-y">
            <Tabs activeKey={activeTab} onChange={setActiveTab} centered tabBarExtraContent={tabBarExtraContent} destroyInactiveTabPane={true}>
                <TabPane tab='基本信息' key={'baseInfo'}>
                    <CustomerInfo {...{ editFormData, setEditFormData, infoError, setInfoError, isDetail, allUserRes, configurationTopic }} />
                </TabPane>
                {
                    _.get(formData, 'deploymentMethod') === '02' &&
                    <TabPane tab='客户信息' key={'customerInformation'}>
                        <CustomerInformation {...{ allUserRes, systemId: _.get(formData, 'projectId') }} />
                    </TabPane>
                }
                <TabPane tab='版本升级纪录' key={'versionUpdateRecord'}>
                    <VersionUpdateRecord {...{ allUserRes, id, updateDynamic }} />
                </TabPane>
                <TabPane tab='使用产品' key={'usedProduct'}>
                    <UsedProduct {...{ id, allUserRes }} />
                </TabPane>
                <TabPane tab='特殊组件目录' key={'specialComponentCatalog'}>
                    <SpecialComponentCatalog {...{ id }} />
                </TabPane>
                <TabPane tab='联系人' key={'contactPerson'}>
                    <ContactPerson {...{ id, allUserRes, customerID }} />
                </TabPane>
                <TabPane tab='提醒邮箱地址' key={'emailReminder'}>
                    <EmailReminder  {...{ id }} />
                </TabPane>
                <TabPane tab='API授权表' key={'apiAuthList'}>
                    <ApiAuthList projectId={_.get(formData, 'projectId')} {...{ allUserRes, allSystemOptions }} />
                </TabPane>
                <TabPane tab={
                    <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                        附件
                    </Badge>
                } key={'doc'} forceRender={true}>
                    <div className="file-wrap" key={countDoc}>
                        <RequirementUploadArea funcCode={'2502'} {...{ id, mode, setFileCount, allUserRes }} />
                    </div>
                </TabPane>
                <TabPane tab='动态' key={'dynamic'}>
                    <div className={'dynamic-wrap'} key={dynamicCount}>
                        <ChangeRecord name='客户系统信息' funcCode={'2502'} referenceId={id} id={id} title={_.get(editFormData, 'projectName')} />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    </div>
}

export default CustomerDetail;
import React, { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { FormInput, Select } from 'rootnet-edit'
import { selectOption } from '../../../../../../../common/commonMethod'
import { isNil } from '../../../../../../../appraise/components/method'

const ADD_URL = '/ReleaseStory/addReleaseStoryList' //关联

export default function ImportVersion(props) {
  const { close, refreshList, selection, setSelection, versionList } = props
  const [id, setId] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const versionOpt = useMemo(() => {// T C
    if (_.isEmpty(versionList)) return []
    return selectOption(_.filter(versionList, o => _.includes(['T', 'C'], o.state)), ['releaseName', 'releaseID'])
  }, [versionList])

  const confirm = useCallback(() => {
    if (isNil(id)) return Messager.show('请选择版本后在进行操作！', { icon: 'error' })
    if (isDisabled) return
    setIsDisabled(true)
    const reqData = getTypeParams({ data: selection, type: 'REQ', params: { releaseid: id } })
    const issueData = getTypeParams({ data: selection, type: 'ISSUE', params: { releaseid: id } })
    const defectData = getTypeParams({ data: selection, type: 'DEFECT', params: { releaseid: id } })
    doFetch(ADD_URL, _.concat(reqData, issueData, defectData))
      .then(() => {
        setSelection([])
        setIsDisabled(false)
        refreshList()
        close()
        Messager.show(`导入成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })

  }, [doFetch, isDisabled, selection, close, refreshList, setSelection, id])

  return (
    <Dialog
      header={'导入版本'}
      // className={'view-query-dialog'}
      cancel={close}
      confirm={confirm}
    // confirmButtonDisabled={initLoading}
    >
      <FormInput onChange={setId} component={Select} horizontal label='版本' options={versionOpt} search componentWidth={230} />
    </Dialog>
  )
}

function getTypeParams({ data, type, params = {} }) {
  const filterData = _.filter(data, o => o.taskType === type)
  if (_.isEmpty(filterData)) return []
  const storyidList = _.map(filterData, 'id')
  return _.assign({}, params, { type, optionType: 'ADD', storyidList })
}

import React, {useCallback, useEffect, useState} from 'react';
import {useGet, usePost} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import ConferenceRecordsTodoList from "../../conferenceRecordsAdd/ConferenceRecordsTodoList";
import {strParams} from "../../../../../utils/publicFun";
import {toDate,dateFormat} from 'rootnet-core/dateFormat'
import {Messager} from 'rootnet-ui'
import FollowMattersDetail from "../../../../common/ChangeRecord/components/followMattersDetail";
import './ConferenceRecordsDetailTodo.scss'
import {API1} from "rootnet-core/base";

function ConferenceRecordsDetailTodo(props) {
    const {cid, allUserRes, updateDynamic, funcCode = '2401', url = '/conferenceRecords?initId='} = props //,isEdit = true 
    const {doFetch: getTodoList} = useGet()
    const [todoList, setTodoList] = useState([])
    const {doFetch: updateRow} = usePost()
    const [showAdd, setShowAdd] = useState(false)

    const refreshList = useCallback(()=>{
        if(_.isNil(cid)) return
        getTodoList('/todolist/getTodolistrecord?'+strParams({
            relateId: cid,
            funcCode: funcCode
        })).then(res => {
            let cloneList = _.map(_.clone(res), x => ({
                ...x,
                otherPerson: _.split(_.get(x,'otherPerson'),','),
                requireDate: dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(x,'requireDate'))),
            }))
            setTodoList(cloneList)
        })
    },[getTodoList, cid, funcCode])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const updateEditRow = useCallback((updateItem)=>{
        const handledUpdateItem = {
            ...updateItem,
            // todolistOrOther: 0,
            otherPerson: _.join(_.get(updateItem,'otherPerson'),','),
        }
        updateRow('/todolist/update',handledUpdateItem).then(()=>{
            Messager.show('修改成功', { icon: 'success' })
            updateDynamic()
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[updateDynamic, updateRow])

    const onExport = useCallback(()=>{
        API1.downloadPost('/conferencerecord/downLoad', [cid])
    },[cid])

    return <div className={'conference-records-detail-todo'}>
        <div className={'add-btn-wrap flex'}>
            <span className={'add-text'} onClick={onExport}>导出</span>
            {/* {isEdit&&<span className={'add-text'} onClick={()=>setShowAdd(true)}>添加跟进事项</span>} */}
        </div>
        <ConferenceRecordsTodoList editable={false} showDetail={true} showStatusCol={true} {...{todoList, setTodoList, allUserRes, updateEditRow,refreshList}}/>
        {
            showAdd &&
            <FollowMattersDetail id={cid} relateId={cid} funcCode={funcCode} mode={'add'} close={()=>setShowAdd(false)}
                                 refresh={refreshList} linkUrl={url + cid}/>
        }
    </div>
}

export default ConferenceRecordsDetailTodo;
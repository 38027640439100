import React, { useEffect, useMemo, useState } from 'react';
import './ReqAnalysis.scss'
import { Form, FormInput, Select } from "rootnet-edit";
import UserSelect from "../../common/personSelectPop/UserSelect";
import { Button, DataGrid } from "rootnet-ui";
import { Box } from "../../common/commonComponent";
import { useGet } from "../../../utils/hook";
import _ from "lodash";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import { TextIconBtn } from "../../common/TextIconBtn";
import ExportCsvDialog from "../../common/ExportCsvDialog";
import { usePost } from "rootnet-biz/es/hooks";
import convertOptions from "../../common/ConvertOptions";
import ReqAnalysisSearchDialog from "./reqAnalysisSearchDialog/ReqAnalysisSearchDialog";
import Icon from "../../../components/Icon";
import { Tooltip } from "antd";
import { isNil } from '../../appraise/components/method';

function getColumns(props) {
    const { searchStatusOptions, allUserRes, setSearchIdList } = props
    return _.concat([
        {
            header: '需求分析人', bind: 'analystUser', tooltip: true, width: 180, sortable: true,
            convert: v => <div style={{ fontWeight: 'bold' }}>{convertOptions(v, allUserRes, 'userName', 'userAccount')}</div>,
            csvConvert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
        },
        {
            header: <div className={'flex center-y'}>
                超期数量
                <Tooltip title={
                    <div>
                        当前在"需求审核、需求分析"的需求，从创建时间至今的耗时，超过标准时长的数量
                    </div>
                }>
                    <span>
                        <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF', marginLeft: 6 }} />
                    </span>
                </Tooltip>
            </div>, bind: 'overdue', tooltip: true, width: 100, align: 'center', sortable: true,
            convert: (v, o) => v === 0 ? <div style={{ color: 'red' }}>{v}</div> : <div className={'can-enter-text overdue-num'} onClick={() => setSearchIdList(o.overdue_ids)}>{v}</div>
        },
        {
            header: '合计', bind: 'total', tooltip: true, width: 100, align: 'center', sortable: true,
            convert: (v, o) => v === 0 ? v : <div className={'can-enter-text total-field'} onClick={() => setSearchIdList(_.get(o, `total_ids`))}>{v}</div>
        }
    ], _.map(searchStatusOptions, item => ({
        header: item.text, bind: item.value, tooltip: true, width: 100, align: 'center', sortable: true,
        convert: (v, o) => v === 0 ? v : <div className={'can-enter-text'} onClick={() => setSearchIdList(_.get(o, `${item.value}_ids`))}>{v}</div>
    })))
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedLeft: 3,
    nilText: '-',
    emptyText: '-',
});

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=workflowStoryStatus',
]

function ReqAnalysis(props) {
    const initParams = {
        analystUserList: isNil(localStorage.getItem('reqAnalysis-user')) ? null : JSON.parse(localStorage.getItem('reqAnalysis-user')),
        statusList: isNil(localStorage.getItem('reqAnalysis-status')) ? null : JSON.parse(localStorage.getItem('reqAnalysis-status')),
    }
    const [params, setParams] = useState(initParams)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [showExportDialog, setShowExportDialog] = useState(false)
    const { data: list, doFetch, error } = usePost()
    const [loading, setLoading] = useState(false)
    const { data: allUserRes } = useGet('/common/userinfo')
    const [searchIdList, setSearchIdList] = useState()

    useEffect(() => {
        if (_.isEmpty(_.get(params, 'analystUserList'))) return
        setLoading(true)
        doFetch('/storyAnalysis/list', params).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [doFetch, params])

    const [statusOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const searchStatusOptions = useMemo(() => {
        if (_.isEmpty(_.get(params, 'statusList'))) return statusOptions
        return _.filter(statusOptions, x => _.includes(_.get(params, 'statusList'), x.value))
    }, [statusOptions, params])

    const option = useMemo(() => {
        return getOption(getColumns({ searchStatusOptions, allUserRes, setSearchIdList }))
    }, [searchStatusOptions, allUserRes])

    const extra = useMemo(() => {
        return <TextIconBtn icon='xiazai2' text='导出' onClick={() => setShowExportDialog(true)} disabled={_.isEmpty(list)} />
    }, [list])

    return <div className={'req-analysis-wrap fill flex-y'}>
        <Options initParams={params} search={setParams} {...{ statusOptions }} />
        <Box title={'需求分析统计'} className={'x-card-singlegrid'} data={list} extra={extra} loading={loading} error={error}>
            <DataGrid className='x-card-singlegrid' data={list} option={option} />
        </Box>
        {
            showExportDialog && <ExportCsvDialog close={() => setShowExportDialog(false)} list={list} option={option} title={`需求分析统计`} />
        }
        {
            !_.isEmpty(searchIdList) &&
            <ReqAnalysisSearchDialog close={() => setSearchIdList(null)} idList={searchIdList} />
        }
    </div>
}

function Options(props) {
    const { initParams, search, statusOptions } = props
    const [params, setParams] = useState(initParams)

    const filterList = useMemo(() => {
        if (_.isEmpty(statusOptions)) return
        return _.filter(statusOptions, x => !_.includes([
            '172096195508834304',
            '172096195559165953',
            '172096195559165954',
            '172096195559165955',
            '172096195559165956',
            "172096194976157696",//需求提出
            "172096195341062144",//需求评估
            "172096195366227968",//需求评估审核
            "172096195559165966",//分析复核
            "172096195420753920",//待版本规划
        ], x.value))
    }, [statusOptions])

    useEffect(() => {
        if (_.isEmpty(filterList)) return
        setParams(x => _.assign({}, x, _.isEmpty(x?.statusList) && {
            statusList: _.map(filterList, 'value')
        }))
    }, [filterList])

    return <div className={'c-options'}>
        <Form value={params} onChange={setParams}>
            <FormInput label={'需求分析人(必选)'} bind={'analystUserList'} component={UserSelect} multiple required className={'options-component'} componentWidth={400} />
            <FormInput label='超时标准（日）' bind="standard" type={'number'} min={0} digit={1} />
            <FormInput label='需求状态' bind="statusList" component={Select} multiple options={statusOptions} clear />
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={() => {
                localStorage.setItem('reqAnalysis-user', JSON.stringify(_.get(params, 'analystUserList')))
                localStorage.setItem('reqAnalysis-status', JSON.stringify(_.get(params, 'statusList')))
                search({ ...params })
            }} disabled={_.isEmpty(_.get(params, 'analystUserList'))}>查询</Button>
            <Button className={'reset-btn'} text onClick={() => {
                localStorage.setItem('reqAnalysis-user', JSON.stringify(null))
                localStorage.setItem('reqAnalysis-status', JSON.stringify(_.map(filterList, 'value')))
                search(getInitParams(_.map(filterList, 'value')))
                setParams(getInitParams(_.map(filterList, 'value')))
            }}>重置</Button>
        </div>
    </div>
}

function getInitParams(statusList) {
    return {
        analystUserList: null,
        standard: null,
        statusList,
    }
}

export default ReqAnalysis;
import React, { useState, useCallback, useMemo, useRef } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/lib/hooks'
import RoleList from './controls/roleList'
import RoleMenu from './controls/roleMenu'
import { strParams } from '../../../utils/publicFun'
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions'
import './index.scss'
import { Icon } from '../../../components'

const SYSTEM_URL = '/common/globalconst?globalConst=system'
const SELECT_MENU_URL = '/uac/role/select/menu'

export default function RoleMgt() {
  const [currentId, setCurrentId] = useState()
  const { data: roleMenuList, doFetch, loading } = useGet()
  const { data: systemRes } = useGet(SYSTEM_URL)

  const [canMove, setCanMove] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState(700);
  const prevXRef = useRef(null);
  const panelRef = useRef(null)

  const systemOpt = useMemo(() => {
    if (_.isEmpty(systemRes)) return []
    return convertGlobalConstOptions(systemRes)
  }, [systemRes])

  const refreshRoleMenu = useCallback((roleId) => {
    if (!_.isNil(roleId)) doFetch(`${SELECT_MENU_URL}?${strParams({ roleId })}`)
  }, [doFetch])

  const defaultCheckedKeys = useMemo(() => {
    if (_.isEmpty(roleMenuList)) return null
    const groupByData = _.groupBy(roleMenuList, 'moduleID')
    return _.assign({}, ..._.map(groupByData, (arr, k) => ({ [k]: _.map(arr, o => o.funcCode) })))
  }, [roleMenuList])

  return (
    <div className='role-mgt fill'>
      <div className='flex role-mgt-content'
        onMouseMove={handleMove} ref={panelRef}
        onMouseLeave={() => setCanMove(false)}
        onMouseUp={() => setCanMove(false)}
      >
        <RoleList {...{ currentId, setCurrentId, refreshRoleMenu, systemOpt, defaultCheckedKeys, roleMenuList, htmlWidth }} />
        <div className='role-mgt-trag' onMouseDown={() => setCanMove(true)}><Icon name='yidongshu' /></div>
        <RoleMenu {...{ data: roleMenuList, loading, currentId, systemOpt }} />
      </div>
    </div>
  )

  function handleMove(e) {
    if (!canMove) return;
    e.preventDefault();
    e.stopPropagation();
    const prevX = prevXRef.current;
    prevXRef.current = e.clientX
    if (prevX === null) return null;
    const offset = e.clientX - prevX;
    setHtmlWidth(x => {
      if (x + offset < 500) {
        prevXRef.current = prevX
        return 500
      } else if ((x + offset) > 820) {
        prevXRef.current = prevX
        return x
      } else {
        return x + offset
      }
    });
  }

}

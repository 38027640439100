import React from 'react'
import { Input } from 'rootnet-edit'

const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z~!@#$%^&*()_+<>?:"{},./|;'[\]]{6,12}$/
const warning = '密码长度6~12位，同时包含字母和数字'

export default function InputPassWord(props) {
  const { value, ...res } = props;
  console.log(reg.test(value))
  return <Input
    value={value}
    type='password'
    {...res}
    error={reg.test(value) ? '' : warning}
  />
}
import _ from 'lodash'
import React, { useMemo } from 'react'
import { DataGrid } from 'rootnet-ui'
import { Icon } from '../../../../components'
import { Box } from '../../../common/commonComponent'
import { summaryProjectInitData } from '../../components/formatData'
import './index.scss'

function getColumns(props) {
  const { monthArray } = props
  const columnHead = [
    { header: '', bind: 'name', width: 150, align: 'center', },
  ]
  const columnContent = _.map(monthArray, v => {
    return {
      header: v,
      align: 'center',
      bind: `month${v}`,
    }
  })
  const columnFooter = [
    { header: '合计', bind: 'total', width: 130, align: 'center', },
  ]
  return _.concat(columnHead, columnContent, columnFooter)
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  fixedRight: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
})

const title = <div className='header flex'>
  <div className='title'>汇总信息</div>
  <div className='hint'>仅包含有效部门的管道汇总</div>
</div>

export default function ProjectManpowerSummaryDetailSummary(props) {
  const { summaryInfoExpanded, setSummaryInfoExpanded, data, loading, error, monthArray } = props

  const option = useMemo(() => getOptions(getColumns({ monthArray })), [monthArray])

  const showList = useMemo(() => summaryProjectInitData(data), [data])

  const extra = useMemo(() => {
    return <div className={'extra-wrap flex center-y'}>
      <div className={'expand-text flex center-y'} onClick={() => setSummaryInfoExpanded(x => !x)}>
        <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{ transform: summaryInfoExpanded ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
        {summaryInfoExpanded ? "收起" : '展开'}
      </div>
    </div>
  }, [summaryInfoExpanded, setSummaryInfoExpanded])

  return (
    <div className='project-manpower-summary-detail-summary'>
      <Box className='flex-y x-card-singlegrid' title={title} data={data} error={error} loading={loading} extra={extra}>
        <DataGrid border data={showList} option={option} />
      </Box>
    </div>
  )
}

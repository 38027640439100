import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Tooltip, Loader} from 'rootnet-ui';
import './WorkplaceSummaryInfo.scss'
import {Icon} from "../../../project_share/components";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from 'lodash'
import clsx from "clsx";
import DelayInfoDialog from "./delayInfoDialog/DelayInfoDialog";

function WorkplaceSummaryInfo(props) {
    const {fullScreenCard} = props
    const {data: userInfo} = useGet('/common/user/nowUserInfo')
    const [showDelayDialog, setShowDelayDialog] = useState(false)

    const {data: delayInfo, doFetch: getDelayInfo, loading} = useGet()

    const refresh = useCallback(()=>{
        getDelayInfo('/workCalendar/delayEvent/count')
    },[getDelayInfo])

    useEffect(()=>{
        refresh()
    },[refresh])

    const extra = useMemo(()=>{
       return <Icon name={'shuaxin1'} className={'can-click-icon handle-icon'} onClick={refresh}/>
    },[refresh])

    return <div className={clsx('workplace-summary-info flex-y', {'hidden-card': !_.isNil(fullScreenCard)})}>
        <Card title={`欢迎回来，${_.get(userInfo,'userName') || ''}`} extra={extra}>
            {
                loading && <Loader fill/>
            }
            <div className="info-content flex center-y">
                <div className="info-item flex center-y">
                    <Icon name={'daiban'} symbol={true} className={'info-icon'}/>
                    <div className="info-detail flex-y">
                        <div className="title">
                            我的待办
                        </div>
                        <div className="num">
                            {_.get(delayInfo,'toDo') || 0}
                        </div>
                    </div>
                </div>
                <div className="divider"/>
                <div className="info-item flex center-y">
                    <Icon name={'drxx40'} symbol={true} className={'info-icon'}/>
                    <div className="info-detail flex-y">
                        <div className="title">
                            我的已办
                        </div>
                        <div className="num">
                            {_.get(delayInfo,'done') || 0}
                        </div>
                    </div>
                </div>
                <div className="divider"/>
                <div className="info-item flex center-y">
                    <Icon name={'bug-copy'} symbol={true} className={'info-icon'}/>
                    <div className="info-detail flex-y">
                        <div className="title">
                            待办ISSUE
                        </div>
                        <div className="num">
                            {_.get(delayInfo,'issueToDo') || 0}
                        </div>
                    </div>
                </div>
                <div className="divider"/>
                <div className="info-item flex center-y">
                    <Icon name={'icon-test'} symbol={true} className={'info-icon'}/>
                    <div className="info-detail flex-y">
                        <div className="title">
                            超期未完成&即将超期
                        </div>
                        <div className="num-wrap flex center-y">
                            <div className="num can-enter-text" onClick={()=>setShowDelayDialog(true)}>
                                {_.get(delayInfo,'delayCount') || 0}
                            </div>
                            <Tooltip title='我负责的今天应完成，但未完成的事项'>
                                <Icon name='bangzhu' className={'help-icon'} style={{ color: '#5477FF', fontSize: '16px' }} />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
        {
            showDelayDialog &&
            <DelayInfoDialog close={()=>setShowDelayDialog(false)}/>
        }
    </div>
}

export default WorkplaceSummaryInfo;
import _ from 'lodash'
import React, { useState, useMemo, useEffect } from 'react'
import { N2 } from 'rootnet-core/format'
import { Button, DataGrid } from 'rootnet-ui'
import { Select, Form, FormInput } from 'rootnet-edit'
import { Box } from '../../common/commonComponent'
import { useGet } from '../../../utils/hook'
import { strParams, summaryWithHeader } from '../../../utils/publicFun'
import OperationList from '../../../project_share/components/OperationList'
import { addAll } from '../../../utils/jsondata'

const urls = [
  '/common/system',
  '/common/userinfo',
  '/common/globalconst?globalConst=ProjectStatus'
]

const getColumns = (history, presalesProStatusOpt, params) => {
  return (
    [
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 70 },
      { header: '客户', bind: 'customerName', align: 'left', tooltip: true },
      { header: '项目名称', bind: 'projectName', align: 'left', width: 300, tooltip: true },
      { header: '项目类型', bind: 'projectTypeName', align: 'left', width: 150, tooltip: true },
      { header: '项目状态', bind: 'projectStatus', align: 'left', width: 78, convert: v => _.get(_.find(presalesProStatusOpt, x => x.value === v), 'name') },
      { header: '项目经理', bind: 'principalName', align: 'left', width: 78 },
      { header: '计划工时(人日)', bind: 'planTime', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '计划成本(元)', bind: 'planCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '实际工时(人日)', bind: 'useTime', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '实际成本(元)', bind: 'useCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '操作', align: 'center', width: 60, convert: createOperation },
    ]
  )
  function createOperation(v, o, i) {
    const options = [
      { text: '详情', onClick: () => history.push(`/devprojectcost/Details?${getParams()}`) }
    ]
    return <OperationList options={options} />
    function getParams() {
      const origin = _.omit(o, ['customer', 'projectName', 'principal', 'projectStatus']);
      origin.projectNames = o.projectName;
      return strParams(_.assign(origin, params));
    }
  }
}

const gridOptsFor = (options, data) => ({
  nilText: '-',
  fixedLeft: 2,
  fixedRight: 1,
  emptyText: '-',
  resizable: true,
  columns: options,
  virtualized: false,
  summary: [
    summaryWithHeader(options, data)
  ]
});

export default function DevprojectCost(props) {
  const { history } = props
  const { data: optsData } = useGet(urls)
  const { data, error, loading, doFetch } = useGet()
  const historyParams = useMemo(() => history.location.pathParams, [history])
  const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
  const [params, setParams] = useState(historyParams || { customer: '', projectStatusList: ['0', '1', '2', '3'], principal: userAccount, })

  const opts = useMemo(() => {
    const [devClient, proManager, presalesProStatus] = optsData || []
    return [
      _.map(devClient, v => ({ value: v.projectId, text: ''.concat(v.custType, '-', v.projectName) })),
      _.map(proManager, v => ({ value: v.userAccount, text: v.userName, tag: `${v.userAccount}${v.userName}` })),
      _.map(presalesProStatus, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
    ]
  }, [optsData])
  const presalesProStatusOpt = opts[2]
  const option = useMemo(() => gridOptsFor(getColumns(history, presalesProStatusOpt, params), data), [history, presalesProStatusOpt, params, data])
  return (
    <div className='flex-y fill projectcost'>
      <Option search={search} opts={opts} params={params} setParams={setParams} />
      <Box style={{ flex: 1, marginTop: 4 }} className='flex-y x-card-singlegrid' title='项目列表' error={error} loading={loading} data={data}>
        <DataGrid data={data} option={option} />
      </Box>
    </div>
  )
  function search(o) {
    setParams(o)
    doFetch(`/devproject/list?${strParams(o)}`)
  }
}

function Option({ search, opts, params }) {
  const [devClientOpt, proManagerOpt, presalesProStatusOpt] = opts
  const [parameter, setParameter] = useState(params)
  useEffect(() => {
    search(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='x rims_options'>
      <Form onChange={setParameter} value={parameter}>
        <FormInput label="客户" component={Select} bind="customer" search options={addAll(devClientOpt)} />
        <FormInput label="项目名称" bind="projectName" search />
        <FormInput label="项目经理" component={Select} bind="principal" search clear options={proManagerOpt} />
        <FormInput label="项目状态" component={Select} bind="projectStatusList" multiple options={presalesProStatusOpt} />
      </Form>
      <Button style={{ marginLeft: 0 }} className='btn' primary onClick={() => { search(parameter) }}>查询</Button>
    </div>
  )
}

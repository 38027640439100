import React, { useContext, useEffect } from 'react'
import { Drawer } from 'rootnet-ui'
import { ValueContext } from '../../../common/Context'
import EssentialInformation from './essentialInformation'
import OverallSituation from './overallSituation'


export default function DetailDrawer() {
  const { allLoading, openDetail, setOpenDetail, allRefresh } = useContext(ValueContext)

  useEffect(() => {
    if(openDetail) allRefresh()
  }, [allRefresh, openDetail])

  return (
    <Drawer
      header='详情'
      loading={allLoading}
      className='testPlan-detail-Drawer'
      cancel={() => setOpenDetail(false)}
      onOutsideClick={() => setOpenDetail(false)}
      open={openDetail} demount={true}
      footerVisible={false}
    >
      <div className='information'>
        {/* 基本信息 */}
        <EssentialInformation />
        {/* 整体情况 */}
        <OverallSituation />
      </div>
    </Drawer>
  )
}

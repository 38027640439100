import React from 'react';
import _ from "lodash";
import {Tooltip} from 'rootnet-ui'
import './Remark.scss'

function Remark(props){
    const {label, text, twoLine = false,oneLine = false, labelWidth = 80, style} = props
    return <div className={`remark flex ${twoLine?'two-line':''} ${oneLine?'one-line':''}`} style={style}>
        <div className="label" style={{width: labelWidth}}>{label}</div>
        {
            _.isEmpty(text) &&  <div className='show-text'>{text || '-'}</div>
        }
        {
            !_.isEmpty(text) &&
            <Tooltip title={text || '-'}>
                <div className='show-text'>{text || '-'}</div>
            </Tooltip>
        }
    </div>
}

export default Remark;
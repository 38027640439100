import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DataGrid, Switch, Messager} from 'rootnet-ui'
import './ViewDetail.scss'
import {Box} from "../../../common/commonComponent";
import {useEditTransfer, useGet} from "../../../../utils/hook";
import _ from 'lodash'
import {TextIconBtn} from "../../../common/TextIconBtn";
import {pathSearchFor} from "../../../../utils/publicFun";
import {toDate, dateFormat} from 'rootnet-core/dateFormat'
import ImportDialog from "./ImportDialog";
import LogDialog from "./LogDialog";
import {Icon} from "../../../../project_share/components";
import ViewDesc from "./ViewDesc";
import { isNil } from 'rootnet-core/format'
import DetailsGoBackWithTextClick from "../../../common/DetailsGoBackWithTextClick";
import ExportCsvDialog from "../../../common/ExportCsvDialog";

const { str14ToDate } = toDate;

const detailOverdueColor = {
    '测试超期':'#40A9FF',
    '设计超期':'#9254DE',
    '开发超期':'#FA541C',
}

const getColumns = (props) => {
    return [
        {header: '项目', bind: 'projectName',width: 160, tooltip: true},
        {header: '项目状态', bind: 'projectStatusName',width: 90, tooltip: true},
        {header: 'ID', bind: 'id',width: 160, tooltip: true},
        {header: 'ID标题', bind: 'idtitle',width: 160, tooltip: true},
        {header: '任务清单', bind: 'detailTitle',width: 250, tooltip: true},
        {header: '任务状态', bind: 'detailStateName',width: 100, tooltip: true},
        {header: '当前阶段负责人', bind: 'detailPrincipal',width: 110, tooltip: true},
        {header: '研发超期状态', bind: 'detailOverDue',width: 100, tooltip: true,convert:convertDetailOverDue},
        {header: '研发任务ID', bind: 'tracerId',width: 165, tooltip: true},
        {header: '研发任务状态', bind: 'tracerStateName',width: 100, tooltip: true, convert: (v, o)=> v?`${o.tracerState}-${v}`:'', csvConvert: (v, o)=> v?`${o.tracerState}-${v}`:''},
        {header: '设计计划完成', bind: 'designCompleteDate',width: 100, tooltip: true, convert: convertDate, csvConvert: convertDate},
        {header: '开发计划完成', bind: 'devCompleteDate',width: 100, tooltip: true, convert: convertDate, csvConvert: convertDate},
        {header: '测试计划完成', bind: 'testCompleteDate',width: 100, tooltip: true, convert: convertDate, csvConvert: convertDate},
        {header: '设计（人日）', bind: 'designUseDay',width: 110, tooltip: true,align: 'right'},
        {header: '开发（人日）', bind: 'devUseDay',width: 110, tooltip: true,align: 'right'},
        {header: '测试（人日）', bind: 'testUseDay',width: 110, tooltip: true,align: 'right'},
        {header: '任务创建人员', bind: 'detailCreatUser',width: 100, tooltip: true},
        {header: '设计负责人', bind: 'detailDesignUser',width: 100, tooltip: true},
        {header: '客户', bind: 'systemName',width: 100, tooltip: true},
        {header: '任务创建日期', bind: 'detailInputDate',width: 100, tooltip: true, convert: convertDate, csvConvert: convertDate},
        {header: '总计划（人日）', bind: 'totalUseDay',width: 120, tooltip: true,align: 'right'},
        {header: '设计完成（人日）', bind: 'designOverDay',width: 120, tooltip: true,align: 'right'},
        {header: '开发完成（人日）', bind: 'devOverDay',width: 130, tooltip: true,align: 'right'},
        {header: '测试完成（人日）', bind: 'testOverDay',width: 130, tooltip: true,align: 'right'},
        {header: '总完成（人日）', bind: 'totalOverDay',width: 130, tooltip: true,align: 'right'},
        {header: '完成标识', bind: 'completeFlag',width: 100, tooltip: true},
        {header: '需求计划发布', bind: 'reqPuDate',width: 100, tooltip: true, convert: convertDate, csvConvert: convertDate},
    ]
    function convertDate(v){
        return dateFormat('YYYY-MM-DD', str14ToDate(v))
    }
    function convertDetailOverDue(v){
        return v?<div className='detail-overdue' style={{color: detailOverdueColor[v], borderColor: detailOverdueColor[v]}}>{v}</div>:null
    }
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
});

function ViewDetail(props) {
    const {location} = props;
    const pathParams = useMemo(() => pathSearchFor(location.search), [location]);
    const {viewId:initViewId} = pathParams
    const [showImportDialog, setShowImportDialog] = useState(false)
    const [showExportDialog, setShowExportDialog] = useState(false)
    const [showLog, setShowLog] = useState(false)
    const {data: listRes, doFetch: getViewDetailList, loading, error} = useGet()
    const [viewId, setViewId] = useState(initViewId)
    const [viewName, setViewName] = useState("")
    const [showList, setShowList] = useState([])
    const [isTree, setIsTree] = useState(false)

    useEffect(()=>{
        const localStorageOptions = localStorage.getItem('viewDetailList')
        if(!_.isNil(localStorageOptions)) return
        const initLocalStorageOptions = _.map(getColumns(), (x,i) => ({...x, order: i, text: x.header})).splice(0,16)
        localStorage.setItem('viewDetailList', JSON.stringify(initLocalStorageOptions))
    },[])

    const {viewBeginDate, viewEndDate,list} = useMemo(()=>(listRes || {}),[listRes])

    const handleTreeClick = useCallback((handleList,o,i,parentName, keyName)=>{
        const updateItem = _.assign({},showList[i],{_expand: !showList[i]._expand})
        if(o._expand){
            const endIndex = _.reduce(showList.slice(i+1),(acc,x,index)=>{
                if(acc!==-1) return acc;
                const currentIndex = (index + i + 1);
                return (showList[currentIndex]._level <= o._level) ? currentIndex : acc;
            },-1);
            setShowList(showList.slice(0,i).concat(updateItem,endIndex !== -1? showList.slice(endIndex): []))
        }else{
            const filterList = _.filter(handleList, item => item[parentName] === o[keyName])
            const addList = _.map(filterList, item => _.assign(item, {_level: o._level+1,_expand: false}))
            setShowList(showList.slice(0,i).concat(updateItem,addList,showList.slice(i+1)))
        }
    },[showList])

    const { DialogR_: TransferR, EditBtn, opts, setConfig } = useEditTransfer({ options: getColumns(), name: 'viewDetailList' });

    function handleTree(treeData, childrenName, keyName) {
        const treeList = []
        add(_.cloneDeep(treeData))
        return treeList

        function add(data, level = 0, parent = null) {
            if (!_.isArray(data)) return treeList
            data.forEach(item => {
                item._level = level
                item._parent = parent
                item._hasChildren = hasChildren(item)
                item._expand = true;
                item._childrenList = _.map(item[childrenName], x => x[keyName])
                treeList.push(item)
                if (item._hasChildren) add(item[childrenName], level + 1, item[keyName])
            })

            function hasChildren(item) {
                return _.has(item, childrenName) && !_.isEmpty(item[childrenName])
            }
        }
    }
    
    useEffect(()=>{
        if(isTree && opts.length < 4){
            Messager.show('请选择至少四列显示')
            setIsTree(false)
        }
    },[opts, isTree])

    const handleList = useMemo(()=>{
        if(_.isNil(list) || list.length === 0) return []
        if(opts.length < 4) return list
        const bindList = _.map(opts.slice(0,3), x => x.bind)
        if(bindList.length < 3) return []
        const treeList = _.map(_.groupBy(list, x => x[bindList[0]]),(firstItem, firstName) => ({
            keyPath: handleNull(firstName),
            firstName,
            _oneChild: firstItem.length === 1,
            _child: _.map(_.groupBy(firstItem, x => x[bindList[1]]), (secondItems, secondName) => ({
                keyPath: handleNull(firstName) + handleNull(secondName),
                secondName,
                _oneChild: secondItems.length === 1,
                _child: _.map(_.groupBy(secondItems, x => x[bindList[2]]), (thirdItems, thirdName) => ({
                    keyPath: handleNull(firstName) + handleNull(secondName) + handleNull(thirdName),
                    thirdName,
                    _oneChild: thirdItems.length === 1,
                    _child: _.map(thirdItems, item => ({
                        keyPath: handleNull(firstName) + handleNull(secondName) + handleNull(thirdName) + item.uid,
                        ...item
                    }))
                }))
            }))
        }))
        function handleNull (v){
            return isNil(v)?'NULL':v
        }
        const expandList = handleTree(treeList, '_child', 'keyPath')
        let handleList = margin(expandList, 2)
        handleList = margin(handleList, 1)
        handleList = margin(handleList, 0)
        return handleList
    },[opts,list])

    useEffect(()=>{
        setShowList(handleList)
    },[handleList])

    const refresh = useCallback(()=>{
        getViewDetailList('/viewDetail/getViewDetail?viewId='+viewId)
    },[viewId,getViewDetailList])

    useEffect(()=>{
        refresh()
    },[refresh])

    useEffect(() => {
        setConfig(x=>_.assign({},x,{options:getColumns()}))
    }, [ setConfig ]);

    const addLeftIcon = useCallback((v, o, i) => {
        return <span className='arrow-drop-down-group' onClick={() => {handleTreeClick(handleList,o,i,'_parent','keyPath')}}>
            <Icon name="arrow_drop_down" className='arrow_drop_down'
                  style={{transform: _.get(o,'_expand') ? 'none' : 'rotate(-90deg)'}}/>
            <span>{v}</span>
        </span>
    },[handleTreeClick,handleList])

    const option = useMemo(()=>{
        if(!isTree){
            const newOpts = _.map(opts, x => _.assign({},x,{sortable: true}))
            return getTableOption(newOpts)
        }
        if(opts.length < 4) return getTableOption(opts)
        const bindName = ['firstName', 'secondName', 'thirdName']
        const handleOpts = _.map(opts, (x,index) => {
            if(index >= 3) return x
            return _.assign({},x,
                {
                    convert: (v, o, i) => {
                        const showValue = x.convert ? x.convert(v) : v
                        if(o._merge) return <span style={{marginLeft: 17}}>{showValue}</span>
                        return o._level === index ? addLeftIcon(showValue, o, i) : ''
                    },
                    bind: bindName[index],
                    width: x.width + 20
                })
        })
        return getTableOption(handleOpts)
    },[opts,addLeftIcon,isTree])

    const extra = useMemo(()=>{
        return <div className='extra-group flex center-y'>
            <Switch checkedComponent='树状' uncheckedComponent='平铺' checked={isTree} onChange={setIsTree}/>
            <EditBtn />
            <TextIconBtn icon='wendang' text='清单变动' onClick={()=>setShowLog(true)}/>
            <TextIconBtn icon='daoru' text='导入版本' onClick={()=>setShowImportDialog(true)}/>
            <TextIconBtn icon='xiazai2' text='导出' onClick={()=>setShowExportDialog(true)} disabled={_.isEmpty(list)}/>
        </div>
    },[isTree,list])

    const viewDescProps = useMemo(()=>{
        return ({pathParams,viewBeginDate,viewEndDate,list,viewId,setViewId,loading,error,setViewName})
    },[pathParams,viewBeginDate,viewEndDate,list,viewId,setViewId,loading,error])

    return  <div className='view-detail flex-y fill'>
        <DetailsGoBackWithTextClick onClick={goBack}/>
        <ViewDesc {...viewDescProps}/>
        <Box title='视图明细' className='view-detail-card x-card-singlegrid' data={showList}
             special={<TransferR />} extra={extra} loading={loading} error={error}>
            <DataGrid option={option} data={isTree?showList:list}/>
        </Box>
        {
            showImportDialog && <ImportDialog close={close} viewId={viewId} refresh={refresh}/>
        }
        {
            showExportDialog && <ExportCsvDialog close={()=>setShowExportDialog(false)} list={list} option={option} title={`${viewName}_${dateFormat('YYYYMMDD',new Date())}`}/>
        }
        {
            showLog && <LogDialog close={close} viewId={viewId}/>
        }
    </div>

    function margin(list, level){
        let skipFlag = -1;
        const handleList = []
        _.forEach(list, (v,index) => {
            if(skipFlag === index) return
            if(v._level === level && v._oneChild){
                handleList.push(_.assign({},list[index+1],v,{_merge: true}))
                skipFlag = index+1;
                return
            }
            handleList.push(v)
        })
        return handleList
    }

    function close(){
        setShowImportDialog(false)
        setShowLog(false)
    }

    function goBack() {
        props.history.push({
            pathname: '/projectView',
            pathParams: pathParams
        })
    }
}

export default ViewDetail;
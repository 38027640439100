import React, {useCallback, useEffect, useMemo} from 'react';
import {Loader} from "rootnet-ui";
import {pathSearchFor} from "./utils/publicFun";
import _ from "lodash";
import gd from "./base/global";
import {_cache} from "./utils/cache";
import qs from "qs";
import {parseParams, strParams} from "./project_share/utils/utils";
import {login, loginCheck, ssoLogout} from "./utils/account";
import {isNil} from "./views/appraise/components/method";

const CLIENT = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'

const MOBILE_HOME_PAGE = '/mobileHome'
const PC_HOME_PAGE = '/workplace'

function SsoLogin(props) {
    const { location } = props;
    const { ssoToken = null, wechat = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const uuid = gd.User?.uuid

    useEffect(()=>{
        if(_.isNil(uuid) && _.isNil(ssoToken)){
            ssoLogout({location})
        }
    },[uuid, ssoToken, location])

    const toPageWithAddTab = useCallback(url => {
        const menuList = _cache.get('allMenuList')
        const firstTabItem = _.find(menuList, x => x.url === url)
        _cache.set('activeMenuList', [firstTabItem])
        props.history.push(url)
    }, [props])

    const loginOK = useCallback(() => {
        const search_string = _.trimStart(props.location.search, "?");
        const query = qs.parse(search_string);
        const { wechat, ...all } = _.isNil(_.get(query, 'return')) ? {} : parseParams(query.return.split('?')[1])
        if (wechat) query.return = `${query.return.split('?')[0]}?${strParams(all)}`
        if (!isNil(query.return)) {
            return toPageWithAddTab(unescape(query.return));
        } else {
            return toPageWithAddTab(CLIENT === 'Mobile' ? MOBILE_HOME_PAGE : PC_HOME_PAGE)
        }
    }, [props, toPageWithAddTab])

    useEffect(() => {
        // CS登录
        const task = gd.task_autologin;
        if (task && !task.executed) {
            task.executed = true;
            login(task.username, task.password, task.encrypted).then(() => {
                localStorage.setItem('current_user_info', null)
                loginOK();
            })
        }

        if (sessionStorage.getItem("user-session-expired")) {
            sessionStorage.removeItem("user-session-expired");
            ssoLogout({location})
        }
    }, [loginOK, wechat, location])

    // const jurisdiction = useCallback(async (name) => {
    //     const res = await api.get(`/common/uacUserRole`)
    //     const userPositionList = _.map(res.data, x => x.posId)
    //     localStorage.setItem('userPositionList', JSON.stringify(userPositionList))
    // }, [])

    useEffect(() => {
        const token = ssoToken || uuid
        if (token) {
            loginCheck({token, location: props.location})
                .then((res) => {
                    loginOK()
                })
        }
    }, [props, loginOK, ssoToken, uuid])

    // useEffect(() => {
    //     const url = decodeURIComponent(props.location.search);
    //     const authorizationCode = parseParams(url.split('?')[2]);
    //     const { wechat } = authorizationCode;
    //     if (wechat) {
    //         weChat(wechat)
    //             .then((res) => {
    //                 if(isNil(res.data)){
    //                     ssoLogout({location: props.location})
    //                 }else{
    //                     sessionStorage.setItem('isWechat', 'true')
    //                     if (res.data){
    //                         gd.User = res.data
    //                     }
    //                     if (CLIENT === 'Mobile'){
    //                         jurisdiction(res.data.operator_name)
    //                     }
    //                     loginOK()
    //                 }
    //             })
    //     }
    // }, [props, loginOK, jurisdiction, uuid])

    return <div className="fill flex-y center-y">
        <Loader fill large text='正在登录中...' />
    </div>
}

export default SsoLogin;
import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Dialog, Card, ScrollViewer } from 'rootnet-ui'
import { CheckBox, Grid, FormInput, Form } from 'rootnet-edit'
import { Icon } from '../../../../project_share/components'
// import api from '../../../../base/task'
import { strParams } from '../../../../utils/publicFun'
import { ScheduleManagementContext } from '../../../common/Context'
import './MetadataExportDialog.scss'

export default function MetadataExportDialog(props) {
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const { close, tabIndex, list, option, defaultOptions, approvalMode, searchName, title } = props
  const [params, setParams] = useState()
  const [all, setAll] = useState(false)
  const [downloadOptions, setDownloadOptions] = useState()

  const columns = useMemo(() => {
    const options = _.get(defaultOptions, 'columns') || _.get(option, 'columns')
    return _.filter(options, o => o.header !== '操作')
  }, [defaultOptions, option])

  const getColumns = useCallback((checkedList) => {
    return setDownloadOptions(_.compact(_.map(checkedList, v => _.find(columns, o => v === o.header))))
  }, [columns])

  const selectAll = useCallback((value) => {
    setAll(value)
    setParams(_.fromPairs(_.map(columns, o => [o.bind, value])))
  }, [columns])

  useEffect(() => {
    selectAll(true)
  }, [selectAll])

  return (
    <Dialog
      cancel={close}
      header={'导出'}
      confirm={exportData}
      className='MetadataExport'
      confirmButtonText='确认导出'
      confirmButtonDisabled={_.size(list) === 0 || _.size(downloadOptions) === 0}
    >
      <HFormInput value={all} onChange={value => selectAll(value, columns)}>全选</HFormInput>

      <Card title='基础字段' extra={<div className='selectedFields'>当前选定的字段</div>}>

        <div>
          <Grid cols={4}>
            <Form value={params} onChange={handleFormChange}>
              {
                columns.map(v => {
                  return <HFormInput key={v.bind} bind={v.bind}>{v.header}</HFormInput>
                })
              }
            </Form>
          </Grid>
        </div>

        <DisplayData params={params} columns={columns} setParams={setParams} setAll={setAll} getColumns={getColumns} />

      </Card>

    </Dialog>
  )

  function exportData() {
    const fieldList = _.map(downloadOptions, o => o.bind)
    const mode = approvalMode === 'default' ? '' : approvalMode
    const parameter = {
      whichPage: tabIndex,
      approvalMode: mode,
      searchName,
      fieldList: _.join(fieldList, ','),
    }
    downloadGetProgress(`/field/exportField?${strParams(parameter)}`, title, close)
  }

  function HFormInput(props) {
    return <FormInput componentWidth={150} component={CheckBox}  {...props} />
  }

  function handleFormChange(formObj) {
    const screenData = {}
    const val = _.values(formObj)
    _.forEach(formObj, (val, key) => { if (val) screenData[key] = val })
    if (_.size(val) === _.size(columns) && _.every(val)) setAll(true)
    else setAll(false)
    setParams(screenData)
  }
}

function DisplayData(props) {
  const { params, columns, setParams, setAll, getColumns } = props
  const [checkedList, setCheckedList] = useState([])

  const changeOrder = useCallback((drag, o) => {
    const t = _.without(checkedList, drag);
    const index = _.indexOf(t, o);
    t.splice(index, 0, drag)
    const dataOrder = []
    _.forEach(t, v => _.find(columns, o => { if (v === o.header) dataOrder.push(o) }));
    const filterData = _.reduce(dataOrder, (initialData, o) => { initialData[o.bind] = true; return initialData }, {})
    setCheckedList([...t, '']);
    setParams(filterData);
  }, [checkedList, columns, setParams])

  const dragObjRef = useRef()

  useEffect(() => {//当前选定的字段
    const selectedFields = []
    const listArr = _.map(params, (val, key) => { if (val) return key })
    _.map(listArr, v => { _.map(columns, o => { if (o.bind === v) selectedFields.push(o.header) }) })
    setCheckedList([...selectedFields, ''])
  }, [params, columns])

  useEffect(() => {
    getColumns(checkedList)
  }, [checkedList, getColumns])

  return (
    <div>
      <ScrollViewer>
        {
          _.map(checkedList, (v, i) => {
            return (
              <DragItem
                key={i}
                value={v}
                dragObjRef={dragObjRef}
                changeOrder={changeOrder}
              >
                {v && `∷ ${v}`}{v && < Icon onClick={() => deleteField(v)} name='close' />}
              </DragItem>
            )
          })
        }
      </ScrollViewer>
    </div>
  )

  function deleteField(val) {
    const filterData = {}
    const list = _.filter(checkedList, v => v !== val)
    const data = _.filter(columns, o => _.find(list, v => o.header === v))
    _.forEach(data, o => filterData[o.bind] = true)
    if (_.size(_.compact(list)) === _.size(columns)) setAll(true)
    else setAll(false)
    setParams(filterData)
    setCheckedList([...list, ''])
  }

}

function DragItem(props) {
  const { children, value, dragObjRef, changeOrder } = props

  return <div className={cls("dragItem", { cancelStyle: !value })}
    draggable={value !== ''}
    onDragStart={e => handleDragStart(e, value)}
    onDragEnter={e => handleDragEnter(e, value)}
    onDragLeave={handleDragLeave}
    onDrop={e => handleDrop(e, value)}
    onDragOver={e => handleDragOver(e, value)}
    onDragEnd={handleDragEnd}
  >
    {children}
  </div>

  function handleDragStart(e, o) {
    dragObjRef.current = o;
  }
  function handleDragEnd() {
    dragObjRef.current = null;
  }
  function handleDragEnter(e, o) {
    if (dragObjRef.current === o) return;
    const ele = e.currentTarget;
    if (ele) ele.classList.add("dragover");
  }
  function handleDragLeave(e) {
    const ele = e.currentTarget;
    if (ele) ele.classList.remove("dragover");
  }
  function handleDrop(e, o) {
    const ele = e.currentTarget;
    if (ele) ele.classList.remove("dragover");
    if (dragObjRef.current === o) return;
    const drag = dragObjRef.current;
    dragObjRef.current = null;
    changeOrder(drag, o);
  }
  function handleDragOver(e, o) {
    e.preventDefault();
    if (o !== dragObjRef.current) e.dataTransfer.dropEffect = "move";
    else e.dataTransfer.dropEffect = "none";
  }
}


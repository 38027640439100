import React, { useEffect, useState } from 'react';
import { Card, Button } from 'rootnet-ui';
import { Form, Grid, FormInput, Select } from 'rootnet-edit';
import { api } from 'rootnet-core/base';
import _ from 'lodash';
import './ReportView.scss';

function HFormInput(props) {
    return <FormInput labelWidth={80} componentWidth={280} horizontal {...props} />
}

const NAME = "generic-report-test";

function initState() {
    const item = sessionStorage.getItem(NAME);
    if (item)
        return JSON.parse(item);
    return { query: null, template: null, expression: null }
}

function getTemplateInfo(name) {
    name = _.trim(name);
    const array = _.split(name, '.');
    return [array[0], array[1] === "xlsx" ? "excel" : "word"];
}

export default function ReportView(props) {

    const [state, setState] = useState(initState())
    const [options, setOptions] = useState();
    const [text, setText] = useState();
    const [loading, setLoading] = useState(false);

    function handleClick() {
        setLoading(true);
        sessionStorage.setItem(NAME, JSON.stringify(state));
        const { query } = state;
        const [template, type] = getTemplateInfo(state.template);

        const url = `/generic-report/${type}/${template}${query ? `?${query}` : ""}`
        api.downloadGet(url).then(() => {
            setText("Generate successfully.")
            setLoading(false);
        }).catch(err => {
            console.error(err.text);
            setText(err.text);
            setLoading(false);
        });
    }

    function handleTest() {
        setLoading(true);
        sessionStorage.setItem(NAME, JSON.stringify(state));
        const { query, expression } = state;
        const url = "/generic-report/evaluate-expression";
        const form = new FormData();
        if (query)
            form.append("params", query);
        form.append("expression", expression)
        api.post(url, form).then((res) => {
            setText(JSON.stringify(res.data, null, 2));
            setLoading(false);
        }).catch(err => {
            console.error(err.text);
            setText(err.text);
            setLoading(false);
        });
    }

    useEffect(() => {
        api.get("/report-test/template-files").then((res) => {
            setOptions(_.map(res.data, o => { return { text: o, value: o } }));
        })
    }, [])

    return <div className="fill" style={{ display: "flex" }}>
        <Card title="报表测试" className="generic-report-test" loading={loading}>
            <div className="report-input">
                <Form value={state} onChange={setState}>
                    <Grid cols={2}>
                        <HFormInput label="参数" bind="query" />
                        <span>(例子:productId=100&productType=0)</span>
                        <HFormInput label="模板" bind="template" component={Select} options={options} />
                        <Button primary onClick={handleClick}>生成报表</Button>
                        <HFormInput label="表达式" bind="expression" />
                        <Button primary onClick={handleTest}>表达式测试</Button>
                    </Grid>
                </Form>
            </div>
            <textarea className="report-info" value={text} readOnly />
        </Card>
    </div>
}
import React, { useEffect, useState, useMemo } from 'react';
import { TextIconBtn } from '../../common/TextIconBtn';
import { getTargetElement } from './helper';

function getCss(obj, attribute) {
  if (obj?.currentStyle) {
    return obj.currentStyle[attribute];
  }

  return window.getComputedStyle(obj, null)[attribute];
}

const useTreeExpend = (props) => {
  const {
    wrapperDom = null,
    wrapperEmpytText = '点击展开可查看列表详情',
    wrapperWidth = '48px',
    size = '16px',
  } = props;
  const [expend, setExpend] = useState(true);
  const [wrapperStl, setWrapperStl] = useState({
    width: 'auto',
    overflow: 'auto',
  });
  const el = getTargetElement(wrapperDom);

  useEffect(() => {
    if (wrapperStl) return;
    if (el) {
      setWrapperStl({
        width: getCss(el, 'width'),
        overflow: getCss(el, 'overflow'),
      });
    }
  }, [el, wrapperStl]);

  useEffect(() => {
    if (!el) return;
    if (!expend) {
      el.style.width = wrapperWidth;
      el.style.overflow = 'hidden';
    } else {
      el.style.width = wrapperStl.width;
      el.style.overflow = wrapperStl.overflow;
    }
  }, [el, expend, wrapperStl, wrapperWidth]);

  const expendIcon = useMemo(() => <TextIconBtn
    onClick={() => setExpend(x => !x)}
    icon="tiaojianshouqi"
    size={size}
    style={{ transform: expend ? 'rotate(180deg)' : '', marginLeft: 8, display: 'inline-block' }}
  />, [expend, size]);

  const wrapperEmpyt = useMemo(() => {
    const stl = {
      width: '14px',
      color: '#7a8199',
      fontSize: '12px',
      margin: '0 auto',
      height: '100%',
      lineHeight: '14px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    };
    return (
      <div style={stl} onClick={() => setExpend(true)}>
        {wrapperEmpytText}
      </div>
    );
  }, [wrapperEmpytText]);

  return {
    expend,
    expendIcon,
    setExpend,
    wrapperEmpyt,
  };
};

export default useTreeExpend;

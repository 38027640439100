import React from 'react'
import { Dialog } from 'rootnet-ui'
import InfoFormLogContent from './InfoFormLogContent'
import './index.scss'

export default function InfoFormLog(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog className={'linker-info-form-log'} header={'需求信息'} headerVisible={false} footerVisible={false}>
      <InfoFormLogContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

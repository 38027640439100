import React, {useCallback, useMemo, useReducer} from 'react';
import {Card} from 'rootnet-ui';
import Icon from "../../../../../components/Icon";
import {Form, FormInput, Select} from "rootnet-edit";
import './RelationCard.scss'
import _ from 'lodash'
import {uniqKeyFor} from "../../../../../project_share/utils/utils";
import {useGet} from "rootnet-biz/es/hooks";
import {Empty, Button} from "antd";
import {isNil} from "../../../../appraise/components/method";

function getNewItem(){
    return {
        id: uniqKeyFor(),
        tableName: null,
        tableRelation: null,
        tableFilter: null,
        slaveFilterConditions: []
    }
}

function getInitList(){
    return [getNewItem()]
}

const HFormInput = (props) => <FormInput horizontal {...props}/>
function RelationCard(props) {
    const {fullScreen, setFullScreen, tableRes,relation, setRelation} = props
    const {data: tableJoinRes} = useGet('/common/globalconst?globalConst=TableJoin')
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const tableOptions = useMemo(()=>{
        return _.map(tableRes, x => ({value: x.tableName, text: x.tableName}))
    },[tableRes])

    const tableJoinOptions = useMemo(()=>{
        return _.map(tableJoinRes, x => ({ value: x.interiorId, text: x.interiorId}))
    },[tableJoinRes])

    const extra = useMemo(()=>{
        return <Icon name={fullScreen === 'relation'?'suoxiao':'fangda'} className={'full-screen-icon'} onClick={onClickFullScreenIcon}/>
        function onClickFullScreenIcon(){
            setFullScreen(x => x?null:'relation')
        }
    },[fullScreen, setFullScreen])

    const addItem = useCallback((outerKey, innerIndex)=>{
        const newItem = {
            id: uniqKeyFor(),
            tableName: null,
            tableRelation: null,
            tableFilter: null,
            slaveFilterConditions: []
        }
        setRelation(old => {
            if(_.isEmpty(old)) return getInitList()
            if(isNil(outerKey)){
                return _.concat(_.slice(old, 0, innerIndex+1),[newItem],_.slice(old, innerIndex+1))
            }else{
                const list = _.get(old, outerKey) || []
                _.set(old, outerKey, _.concat(_.slice(list, 0, innerIndex+1),[newItem],_.slice(list, innerIndex+1)))
            }
            return old
        })
        forceUpdate()
    },[setRelation])

    const delItem = useCallback((outerKey, innerIndex)=>{
        setRelation(old => {
            if(isNil(outerKey)){
                return _.concat(_.slice(old, 0, innerIndex),_.slice(old, innerIndex+1))
            }else{
                const list = _.get(old, outerKey)
                _.set(old, outerKey, _.concat(_.slice(list, 0, innerIndex),_.slice(list, innerIndex+1)))
                return old
            }
        })
        forceUpdate()
    },[setRelation])

    const changeForm = useCallback((newObj, bind, key)=>{
        setRelation(old => {
            _.set(old, key+'.'+bind, newObj[bind])
            if(bind === 'tableFilter'){
                return old
            }
            return _.clone(old)
        })
    },[setRelation])

    const RelationGroup = useCallback((props)=>{
        const {item, index, parentKey = '', level = 0} = props
        const innerKey = `${parentKey}${index}`
        return <div className={'relation-item-wrap flex-y'} key={item.id} style={{paddingLeft: level === 0 ? 0 : 30}}>
            <div className="relation-item flex center-y">
                <Icon name={'zengjia'} className={'tree-add-icon'} onClick={()=>addItem(`${parentKey}${index}.slaveFilterConditions`, -1)}/>
                <Form value={item} onChange={(newObj, bind) => changeForm(newObj, bind, innerKey)}>
                    <HFormInput bind={'tableName'} label={'从表'} component={Select} labelWidth={40} componentWidth={160} search clear options={tableOptions}/>
                    <HFormInput bind={'tableRelation'} label={'关联关系'} component={Select} labelWidth={80} componentWidth={120} search options={tableJoinOptions}/>
                    <HFormInput bind={'tableFilter'} label={'从表过滤条件'} labelWidth={100} className={'condition-form-input'} placeholder={`例如：table.field = 'value'`}/>
                </Form>
                <Icon name={'tianjia'} className={'add-icon'} onClick={()=>addItem(_.trimEnd(parentKey,'.'), index)}/>
                <Icon name={'shanjian'} className={'del-icon'} onClick={()=>delItem(_.trimEnd(parentKey,'.'), index)}/>
            </div>
            {
                _.map(item.slaveFilterConditions, (childItem, childIndex) => {
                    return RelationGroup({item: childItem, index: childIndex, parentKey: parentKey + index + '.slaveFilterConditions.', level: level + 1})
                })
            }
        </div>
    },[changeForm, tableOptions, tableJoinOptions, addItem, delItem])

    return <Card title={'关联关系'} className={`relation-card-wrap ${fullScreen === 'relation' ?'full-screen':''}`}
                 contentClass={'flex-y relation-card'} extra={extra} style={{display: fullScreen && (fullScreen !== 'relation') ? 'none' : ''}}>
        {
            _.map(relation, (item, index) => {
                return RelationGroup({item, index})
            })
        }
        {
            _.isEmpty(relation) && <div className={'flex center fill'}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} onClick={()=>addItem()}>
                    <Button>创建关联关系</Button>
                </Empty>
            </div>
        }
    </Card>
}

export default RelationCard;
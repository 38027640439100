import _ from 'lodash'
import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'
import { Tooltip } from 'antd'
import { DataGrid, Dialog, Pagination, Messager } from 'rootnet-ui'
import { CheckBox } from 'rootnet-edit'
import gd from '../../../../base/global'
import { Icon } from '../../../../components'
import { useApi, useGet } from '../../../../utils/hook'
import { Box } from '../../../common/commonComponent'
import SelectUseCaseOpt from './selectUseCaseOpt'
import { TableContext, ValueContext } from '../../../common/Context'
import { strParams } from '../../../../utils/publicFun'
import './selectUseCaseLog.scss'

const ECHO_URL = '/test/plan/selectAssociatedCategory'
const DEMAND_AND_TRACKING_URL = '/test_case/selectconditionsinformation' // 需求和研发任务展示信息
const SUBMIT_URL = '/test/plan/addConditions'

const getOption = (selectData) => {
  const selectedIdList = _.map(selectData, x => x['id'])
  return {
    autoFill: true,
    virtualized: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
    columns: [
      { width: 40, algin: 'center', convert: (v, o) => <CheckBox value={_.includes(selectedIdList, _.get(o, 'id'))} /> },
      { header: 'ID', bind: 'interiorReqNo', tooltip: true, width: 120 },
      { header: '标题', bind: 'title', tooltip: true, width: 230 },
      { header: '状态', bind: 'reqStatus', tooltip: true, width: 80, convert: getStatus },
    ],
    onRenderRow: o => {
      const isCurrent = _.includes(selectedIdList, _.get(o, 'id'))
      return {
        style: { backgroundColor: isCurrent ? '#EDF1FF' : '' },
      }
    },
  }
  function getStatus(v, o) {
    return `${v}-${o.ConstDisplayName}`
  }
}

function getInitParams(pageSize = 20) {
  return {
    pageNum: 1,
    pageSize: pageSize
  }
}

const INIT_PARAMS = {
  idOrTitle: null,
  status: null,
  versionId: null,
  branch: 0,
}

const reqStatus = { status: ['R0', 'R1', 'R2', 'R3'], branch: 0, ...getInitParams() }
const tracerState = (testMan) => ({ status: ['T0', 'T1', 'T2'], branch: 0, testMan, ...getInitParams() })

export default function SelectUseCaseLog() {
  const { planId } = useContext(ValueContext)
  const { modeAll, setModeAll, refresh } = useContext(TableContext)
  const [selectData, setSelectData] = useState([])
  const [params, setParams] = useState(modeAll.mode === 'req' ? reqStatus : tracerState(`${gd._user.operator_name}dt`))
  const [listData, setListData] = useState([])
  const { data, loading, error, doFetch: getList } = useGet()
  const { data: echoData } = useGet(`${ECHO_URL}?${strParams({ type: modeAll.mode, planId })}`)
  const { doFetch } = useApi()
  const { pageSize, total, pageNum, rows } = data || {}

  const id = useMemo(() => 'id', [])

  const refreshing = useCallback((parameter) => {
    const par = _.assign({}, parameter,
      parameter?.status && { status: _.join(parameter?.status, ',') })
    const url = `${DEMAND_AND_TRACKING_URL}?${strParams({ type: _.capitalize(modeAll.mode), ...par })}`
    getList(url)
  }, [getList, modeAll.mode])

  useEffect(() => {
    const obj = modeAll.mode === 'req' ? reqStatus : tracerState(`${gd._user.operator_name}dt`)
    refreshing({ ...getInitParams(), ...obj, branch: 0 })
  }, [modeAll.mode, refreshing])

  useEffect(() => {
    if (echoData) {
      if (modeAll?.mode === 'trace') {
        _.map(echoData, o => {
          o.interiorReqNo = o.tracerId
          o.reqStatus = o.tracerState
          o.title = o.tracerTitle
        })
        setSelectData(echoData)
      }
      else setSelectData(echoData)

    }
  }, [echoData, modeAll])

  useEffect(() => {//需求 研发任务
    if (!_.isEmpty(rows)) {
      if (modeAll?.mode === 'trace') {
        _.map(rows, o => {
          o.interiorReqNo = o.tracerId
          o.reqStatus = o.tracerState
          o.title = o.tracerTitle
        })
        setListData(rows)
      }
      else setListData(rows)
    } else { setListData([]) }
  }, [modeAll, rows])

  const onRowClick = useCallback(o => {
    const idField = 'id'
    const currentId = _.get(o, idField)
    const selectedIdList = _.map(selectData, x => x[idField])
    if (_.includes(selectedIdList, currentId)) {
      setSelectData(oldList => _.filter(oldList, item => item[idField] !== currentId))
    } else {
      setSelectData(oldList => ([...oldList, { [idField]: currentId, ...o }]))
    }
  }, [selectData])

  const option = useMemo(() => getOption(selectData), [selectData])

  return <Dialog
    header={'选择用例'}
    className='selectUseCaseDialog'
    confirm={confirm}
    cancel={() => setModeAll(null)}
  >
    <SelectUseCaseOpt INIT={modeAll.mode === 'req' ? INIT_PARAMS : { ...INIT_PARAMS, testMan: null }} mode={modeAll.mode} refresh={refreshing} setParams={setParams} params={params} />
    <div className='flex'>
      <div>
        <Box data={[0]} className='x-card-singlegrid' loading={loading} error={error}>
          <DataGrid
            className='x-datagrid'
            onRowClick={onRowClick}
            option={option}
            data={listData}
          />
          <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
            onChange={(pageNum, pageSize) => refreshing(_.assign({}, params, { pageNum, pageSize }))} />
        </Box>
      </div>

      <div>
        <div>已选 {_.size(selectData)} 项</div>
        <ul>
          {
            _.map(selectData, o => {
              return <Tooltip key={o[id]} title={o.title}>
                <li>
                  <span>{o.title}</span>
                  <span><Icon name='baocuo' onClick={() => delSelect(o)} /></span>
                </li>
              </Tooltip>
            })
          }
        </ul>
      </div>
    </div>
  </Dialog >

  function delSelect(o) {
    const delData = _.filter(selectData, item => item[id] !== o[id])
    setListData([...listData])
    setSelectData(delData)
  }

  function confirm() {
    if (scalarArrayEquals(echoData, selectData)) {
      setModeAll(false)
      Messager.show('数据未修改', { icon: 'info' })
      return
    }

    const parameter = _.assign({},
      {
        planId,
        type: modeAll.mode,
        relateIdListAdd: checkData(selectData, echoData),
        relateIdListDev: checkData(echoData, selectData),
      })

    doFetch(SUBMIT_URL, 'post', parameter)
      .then(() => {
        refresh()
        setModeAll(false)
        Messager.show(`成功`, { icon: 'success' })
      })
      .catch((err) => {
        setModeAll(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }

  function scalarArrayEquals(arr1, arr2) {
    return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.find(arr1, item => item[id] === o[id]))
  }

  function checkData(arr1, arr2) {
    return _.compact(_.map(arr1, o => { if (!_.find(arr2, item => item[id] === o[id])) return o[id] }))
  }

}
import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { Dialog, Button, DataGrid, Pagination } from 'rootnet-ui'
import { Form, FormInput, Select, CheckBox } from 'rootnet-edit'
import { Box } from '../../../common/commonComponent'
import { Icon } from '../../../../components'
import { useGet } from '../../../../utils/hook'
import { strParams } from '../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import gd from '../../../../base/global'
import { FormContext } from '../../../common/Context'
import initData from './initData'

const DEFECT_URL = '/test_defect/get/testDefect/list'
const DEFAULT_LIST_URL = '/test/common/get/operations'
const OPTIONS_URL = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectStatus',
  '/UserSetting/getUniversalInterfaces?code=userAccount&codeName=userName&tableName=jobrecord_user'
]

const STATUS_COLOR = {
  'new': '#93C36B',
  'closed': '#A9A9A9',
  'suspended': '#A9A9A9',
  'rejected': '#A9A9A9',
  'postponed': '#A9A9A9',
  'in_progress': '#5477ff',
  'resolved': '#5477ff',
  'verified': '#5477ff',
  'reopened': '#5477ff',
}

const queryCondition = (userAccount) => ([
  { label: '最近浏览', key: 0 },
  { label: '全部', key: 1, branch: 0 },
  { label: '我创建的', key: 2, branch: 1 },
  { label: '打开的', key: 3, branch: 2 },
  { label: '已完成的', key: 4, branch: 3 },
])

const getOption = ({ selectedList, statusOpt }) => {
  const idField = 'defectId'
  const selectedIdList = _.map(selectedList, x => x._id || x[idField])
  return {
    autoFill: true,
    virtualized: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
    columns: [
      { width: 40, algin: 'center', convert: (v, o) => <CheckBox value={_.includes(selectedIdList, _.get(o, idField))} /> },
      { header: 'ID', bind: 'defectId', tooltip: true, width: 120 },
      { header: '标题', bind: 'title', tooltip: true, width: 230, },
      { header: '状态', bind: 'status', tooltip: true, width: 80, convert: status },
    ],
    onRenderRow: o => {
      const isCurrent = _.includes(selectedIdList, _.get(o, idField))
      return {
        style: { backgroundColor: isCurrent ? '#EDF1FF' : '' },
      }
    },
  }
  function status(v, o) {
    const color = STATUS_COLOR[v]
    return <div style={{ border: `1px solid ${color}`, color, padding: '0 8px', borderRadius: 4 }}>
      {_.get(_.find(statusOpt, item => v === item.value), 'text')}
    </div>
  }
}

const INIT_PARAMS = {
  idOrTitle: null,
  statusList: null,
  userAccount: null,
}

function getInitParams(pageSize = 20) {
  return {
    pageNum: 1,
    pageSize: pageSize,
  }
}

export default function AssociatedDefects(props) {
  const { submitAssociation, defectData } = useContext(FormContext)
  const { setRelation } = props
  const [selectedList, setSelectedList] = useState(defectData || [])
  const [params, setParams] = useState()
  const [listData, setListData] = useState()
  const [active, setActive] = useState(0)
  const { data, loading, error, doFetch } = useGet()
  const { data: allList } = useGet(OPTIONS_URL)
  const { data: defaultData, doFetch: getFetch, loading: defaultLoading, error: defaultError } = useGet()

  const [statusOpt, userAccountOpt] = useMemo(() => {
    if (allList) {
      const [d1, d2] = allList
      return [
        _.orderBy(convertGlobalConstOptions(d1), 'value', 'asc'),
        _.orderBy(convertGlobalConstOptions(d2), 'value', 'asc'),
      ]
    }
    return []
  }, [allList])

  const { pageSize, total, pageNum, rows } = useMemo(() => data || {}, [data]);

  const refresh = useCallback((parameter) => {
    const par = _.assign({}, parameter,
      parameter?.status && { status: _.join(parameter?.status, ',') })
    doFetch(`${DEFECT_URL}?${strParams(par)}`)
  }, [doFetch])

  useEffect(() => {
    if (active === 0) getFetch(`${DEFAULT_LIST_URL}?type=07`)
  }, [active, getFetch])

  useEffect(() => {
    if (active === 0) setListData(initData(_.compact(defaultData), 'defect'))
    else if (!_.isEmpty(rows)) setListData(rows)
    else setListData([])
  }, [active, defaultData, rows])

  const option = useMemo(() => {
    return getOption({ selectedList, statusOpt })
  }, [selectedList, statusOpt])

  const onRowClick = useCallback(o => {
    const idField = 'defectId'
    const currentId = _.get(o, idField)
    const selectedIdList = _.map(selectedList, x => x._id)
    if (_.includes(selectedIdList, currentId)) {
      setSelectedList(oldList => _.filter(oldList, item => item._id !== currentId))
    } else {
      setSelectedList(oldList => ([...oldList, { _id: currentId, ...o }]))
    }
  }, [selectedList])

  useEffect(() => {
    _.forEach(selectedList, item => item._id = item.defectId)
  }, [selectedList])

  return (
    <Dialog
      header={'关联缺陷'}
      className='relationDialog'
      confirm={confirm}
      cancel={() => setRelation(false)}
    >
      <SelectQuery {...{ setParams, refresh, pageSize, active, setActive }} />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 8, overflow: 'hidden' }}>
        <Options {...{ active, statusOpt, userAccountOpt, pageSize, refresh, params, setParams }} />
        <div className='flex' style={{ flex: 1 }}>
          <div className='query-list'>
            <Box
              data={[0]}
              className='x-card-singlegrid'
              error={active === 0 ? defaultError : error}
              loading={active === 0 ? defaultLoading : loading}
            >
              <DataGrid
                className='x-datagrid'
                option={option}
                data={listData}
                onRowClick={onRowClick}
              />
              {
                active !== 0 && <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
                  onChange={(pageNum, pageSize) => refresh(_.assign({}, params, { pageNum, pageSize }))} />
              }
            </Box>
          </div>

          <div className='selected-list'>
            <div>已选 {_.size(selectedList)} 项</div>
            <ul>
              {
                _.map(selectedList, o => {
                  return <Tooltip key={o.id} title={o.title}>
                    <li>
                      <span>{o.title}</span>
                      <span><Icon name='baocuo' onClick={() => delSelect(o)} /></span>
                    </li>
                  </Tooltip>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  )

  function delSelect(o) {
    const delData = _.filter(selectedList, item => item.defectId !== o.defectId)
    setSelectedList(delData)
    setListData([...listData])
  }

  function confirm() {
    submitAssociation('defect', selectedList)
    setRelation(false)
  }
}

function Options(props) {
  const { refresh, params, setParams, pageSize, statusOpt, userAccountOpt, active } = props

  return <div className={'customize-options'}>
    <div className='c-options'>
      <Form value={params} onChange={setParams}>

        <FormInput label='搜索ID或标题' className='searchIconInput' prefix={<Icon name='sousuo1' />} bind='idOrTitle' placeholder='搜索ID或标题' />
        <FormInput label='状态' bind='statusList' component={Select} options={statusOpt} multiple search clear componentWidth={150} />
        <FormInput label='创建人' bind='userAccount' component={Select} options={userAccountOpt} search clear componentWidth={150} />
      </Form>
      <div className="search-btn-group flex">
        <Button
          primary
          disable={active === 0}
          className={'search-btn'}
          onClick={() => refresh({ ...params, ...getInitParams(pageSize) })}>查询</Button>
        <Button disable={active === 0} className={'reset-btn'} text onClick={() => {
          refresh({ ...getInitParams(pageSize), branch: params?.branch })
          setParams({ ...INIT_PARAMS, branch: params?.branch })
        }}>重置</Button>
      </div>
    </div>
  </div >
}

function SelectQuery(props) {
  const { setParams, refresh, pageSize, active, setActive } = props

  const parameter = useMemo(() => queryCondition(gd.User.operator_id), [])

  return <div className='query-condition'>
    {
      _.map(parameter, item => {
        return <div key={item.key}>
          <span
            className={active === item.key ? 'active' : ''}
            onClick={() => handClick(item)}
          >
            {item.label}
          </span>
        </div>
      })
    }
  </div>

  function handClick(data) {
    setActive(data.key)
    if (data.key === 0) return
    refresh({ branch: data.branch, ...getInitParams(pageSize) })
    setParams(_.assign({}, { branch: data.branch }, INIT_PARAMS))
  }

}

import React, {useCallback, useMemo, useState} from 'react';
import {DataGrid, Dialog, Messager} from "rootnet-ui";
import {usePost} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import './WorkFlowBatchCloseDialog.scss'
import {Tabs} from "antd";

function getSuccessColumns(props){
    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center', stretch: false },
        { header: 'id', bind: 'businessIdName', width: 180, tooltip: true, sortable:true},
        { header: '标题', bind: 'title', width: 200, tooltip: true, sortable:true},
    ]
}

function getFailColumns(props){
    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center', stretch: false },
        { header: 'id', bind: 'businessIdName', width: 180, tooltip: true, sortable:true},
        { header: '标题', bind: 'title', width: 300, tooltip: true, sortable:true},
        { header: '失败原因', bind: 'failReason', width: 200, tooltip: true, sortable:true},
    ]
}

function getOption(cols){
    return {
        autoFill: true,
        resizable: true,
        virtualized: true,
        fixedLeft: 1,
        nilText: '-',
        emptyText: '-',
        columns: cols,
    }
}

function WorkFlowBatchCloseDialog(props) {
    const {list, funcCode, businessType, close, afterRefresh, bizName = ''} = props
    const {doFetch: submitPost} = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [showResultDialog, setResultDialog] = useState(false)
    const [result, setResult] = useState()

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const postParams = _.map(list, x => ({
            ...x,
            funcCode,
            businessType,
        }))
        submitPost('/WorkflowBusiness/batchCloseWorkflow',postParams).then((res)=>{
            setSubmitLoading(false)
            if(_.isEmpty(_.get(res,'failList'))){
                afterRefresh()
                close()
            }else{
                afterRefresh()
                setResult(res)
                setResultDialog(true)
            }
        }).catch(err => {
            Messager.show(err._message, {icon: 'error'})
            setSubmitLoading(false)
        })
    },[list,funcCode, businessType, submitPost, submitLoading, close, afterRefresh])

    const [successOption, failOption] = useMemo(()=>{
        return [
            getOption(getSuccessColumns()),
            getOption(getFailColumns()),
        ]
    },[])

    const tabItems = useMemo(()=>{
       return [
           {
               label: '成功执行列表', key: 'success', children: <DataGrid data={_.get(result,'successList')} option={successOption}/>
           },
           {
               label: '失败执行列表', key: 'fail', children: <DataGrid data={_.get(result,'failList')} option={failOption}/>
           },
       ]
    },[result, successOption, failOption])

    return <Dialog header={'提示'} className={'content-center-dialog'} confirm={submit} cancel={close}>
        确认批量关闭所选{bizName}吗？
        {
            showResultDialog && <Dialog header={'处理结果'} className={'workflow-batch-close-dialog'} confirmButtonVisible={false} cancel={()=>{
                setResultDialog(false)
                close()
            }} cancelButtonText={'关闭'}>
                <Tabs items={tabItems} />
            </Dialog>
        }
    </Dialog>
}

export default WorkFlowBatchCloseDialog;
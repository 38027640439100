import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import ViewQueryDialog from '../../../../../../../common/view/viewQueryDialog/ViewQueryDialog'

const ADD_URL = '/ReleaseStory/addReleaseStoryList' //关联

export default function AssociatedVersion(props) {
  const { close, selectedVersion, defaultData, refreshList, searchAll } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const submit = useCallback((list) => {
    if (isDisabled) return
    setIsDisabled(true)
    const reqData = getTypeParams({ data: list, type: 'REQ', params: { releaseid: selectedVersion } })
    const issueData = getTypeParams({ data: list, type: 'ISSUE', params: { releaseid: selectedVersion } })
    // const defectData = getTypeParams({ data: list, type: 'DEFECT', params: { releaseid: selectedVersion } })
    doFetch(ADD_URL, _.concat(reqData, issueData,))
      .then(() => {
        setIsDisabled(false)
        refreshList()
        close()
        Messager.show(`导入成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, isDisabled, refreshList, close, selectedVersion])

  return (
    <ViewQueryDialog
      close={close}
      isClose={false}
      bizField={'View_Project_AllTask.taskType'}
      initValue={_.map(defaultData, o => o.id)}
      initItemValue={defaultData}
      multiple={true}
      funcCode={'1622'}
      outerSetItem={submit}
      refreshViewList={refreshList}
      appendObj={searchAll}
    />
  )
}

function getTypeParams({ data, type, params = {} }) {
  const filterData = _.filter(data, o => o.bizId === type)
  if (_.isEmpty(filterData)) return []
  const storyidList = _.map(filterData, 'id')
  return _.assign({}, params, { type, optionType: 'ADD', storyidList })
}


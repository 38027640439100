import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Dialog, Messager } from "rootnet-ui";
import { useGet, usePost } from "rootnet-biz/lib/hooks";
import _ from 'lodash'
import { Tooltip, Tabs } from "antd";
import Icon from "../../../components/Icon";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import { CheckBox, DatePicker, Display, Form, FormInput, Input, RadioGroup, Select, TextArea } from "rootnet-edit";
import convertOptions from "../../common/ConvertOptions";
import UserSelect from "../../common/personSelectPop/UserSelect";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import './SalesProductViewDetailDialog.scss'
import ChangeRecord from "../../common/ChangeRecord";
import { strParams } from '../../../utils/publicFun';
import { isNil } from '../../appraise/components/method';

const { TabPane } = Tabs;

const HFormInput = (props) => <FormInput horizontal componentWidth={200} labelWidth={110} {...props} />


const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=salesSystem',
    '/common/globalconst?globalConst=salesProductType',
    '/common/globalconst?globalConst=firstLevel',
    '/common/globalconst?globalConst=secondLevel',
    '/common/globalconst?globalConst=thirdLevel',
    '/common/globalconst?globalConst=productline',
    '/common/globalconst?globalConst=openFlag',
]

const getInitParams = () => {
    return {
        moduleName: null,
        pid: null,
        unit: null,
        quantity: null,
        priceStand: null,
        memo: null,
        manager: null,
        releaseDate: null,
        firstLevel: null,
        secondLevel: null,
        thirdLevel: null,
        openFlag: 'Y',
    }
}


function SalesProductViewDetailDialog(props) {
    const { currentInfo, close, editAuth, refreshList, isManager } = props
    const allUserRes = useMemo(() => JSON.parse(localStorage.getItem('all_user')), [])
    const { mode: initMode, id } = useMemo(() => currentInfo, [currentInfo])
    const [mode, setMode] = useState(initMode)
    const { data: detailInfo, doFetch: getDetail } = useGet()
    const [formData, setFormData] = useState(getInitParams())
    const { data: globalConstOptionsRes, doFetch: getGlobalConst } = useGet()
    const [error, setError] = useState()
    const [continueAdd, setContinueAdd] = useState(true)
    const { doFetch: submitPost } = usePost()
    const [loading, setLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('info')
    const [count, updateDynamic] = useReducer((x) => x + 1, 0)
    const [openType, setOpenType] = useState()
    const [levelName, setLevelName] = useState()
    const [isLevel, setIsLevel] = useState(false)
    const { doFetch } = useGet()

    const refreshGlobalConst = useCallback(() => {
        getGlobalConst(GLOBAL_CONST_OPTIONS_URLS)
    }, [getGlobalConst])

    const confirmType = useCallback(() => {
        if (isNil(_.trim(levelName))) return Messager.show('请填写类别名称后在进行操作！', { icon: 'error' })
        if (isLevel) return
        setIsLevel(true)
        doFetch(`/common/addLevelType?${strParams({ levelType: openType, name: levelName })}`)
            .then(() => {
                refreshGlobalConst()
                Messager.show('增加成功', { icon: 'success' });
                setIsLevel(false)
                setOpenType(null)
            })
            .catch(err => {
                setIsLevel(false)
                Messager.show(err._message, { icon: 'error' });
            })
    }, [openType, isLevel, levelName, doFetch, refreshGlobalConst])

    const [salesSystemOptions, salesProductTypeOptions, firstLevelOptions, secondLevelOptions, thirdLevelOptions,
        productLineOptions, openFlagOptions] = useMemo(() => {
            if (_.isEmpty(globalConstOptionsRes)) return []
            return _.map(globalConstOptionsRes, x => {
                const allList = convertGlobalConstOptions(x)
                return _.filter(allList, x => _.get(x, 'displayFlag') !== 'N')
            })
        }, [globalConstOptionsRes])

    const refreshDetail = useCallback(() => {
        if (_.isNil(id)) return
        getDetail('/salesproductInfo/info?id=' + id).then(res => {
            setFormData(res)
        })
    }, [id, getDetail])

    useEffect(() => {
        refreshDetail()
    }, [refreshDetail])

    const confirmDisabled = useMemo(() => {
        return _.some(_.values(error), x => x)
    }, [error])

    const submit = useCallback(() => {
        if (loading) return
        setLoading(true)
        const url = mode === 'add' ? '/salesproductInfo/add' : '/salesproductInfo/modify'
        const submitParams = {
            ...formData,
            manager: formData?.manager || '',
            releaseDate: formData?.releaseDate || '',
            secondLevel: formData?.secondLevel || '',
            thirdLevel: formData?.thirdLevel || '',
        }
        submitPost(url, submitParams).then(res => {
            setLoading(false)
            Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' });
            if (mode === 'add') {
                if (continueAdd) {
                    setFormData(x => ({ ...x, ...getInitParams() }))
                } else {
                    close()
                }
                refreshList()
                updateDynamic()
            } else {
                refreshDetail()
                setMode('detail')
                refreshList()
                updateDynamic()
            }
        }).catch(err => {
            setLoading(false)
            Messager.show(err._message, { icon: 'error' });
        })
    }, [loading, mode, formData, submitPost, refreshList, refreshDetail, close, continueAdd])

    const onFormChange = useCallback((newObj, bind) => {
        setFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'system') {
                cloneObj['productLine'] = convertOptions(newObj?.system, salesSystemOptions, 'memo')
            }
            return cloneObj
        })
    }, [salesSystemOptions])

    useEffect(() => {
        refreshGlobalConst()
    }, [refreshGlobalConst])

    return <Dialog className={`sales-product-view-detail-dialog ${mode}-mode`} cancel={close}
        headerVisible={false} footerVisible={false}>
        <div className="sales-product-detail-content">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    {mode === 'add' ? '添加产品信息' : '产品信息'}
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        mode !== 'add' && editAuth &&
                        <Tooltip title={mode === 'detail' ? '进入编辑' : '退出编辑'}>
                            <span>
                                <Icon name={'bianji2'} className={`header-edit-icon ${mode === 'detail' ? '' : 'edit-active-icon'}`} onClick={() => {
                                    setMode(x => x === 'edit' ? 'detail' : 'edit')
                                    setFormData(detailInfo)
                                }} />
                            </span>
                        </Tooltip>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <div className="sales-product-detail-main-panel">
                <Tabs activeKey={activeKey} onChange={setActiveKey}>
                    <TabPane tab='详细信息' key={'info'} forceRender={true}>
                        <Form value={formData} onChange={onFormChange} error={error} onError={setError}>
                            <HFormInput label={'产品'} bind={'system'} required search options={salesSystemOptions}
                                convert={v => convertOptions(v, salesSystemOptions)} component={mode === 'detail' ? Display : Select} />
                            <HFormInput label={'产品线'} bind={'productLine'} required search options={productLineOptions} labelWidth={124}
                                convert={v => convertOptions(v, productLineOptions)} component={Display} />
                            <HFormInput label={'销售类别'} bind={'productType'} required search options={salesProductTypeOptions}
                                convert={v => convertOptions(v, salesProductTypeOptions)} component={mode === 'detail' ? Display : Select} />
                            <HFormInput label={'可售标识'} bind={'openFlag'} required search options={openFlagOptions}
                                convert={v => convertOptions(v, openFlagOptions)} component={mode === 'detail' ? Display : RadioGroup} />
                            <HFormInput label={'产品模块名称'} bind={'moduleName'} required component={mode === 'detail' ? Display : Input} />
                            <HFormInput label={'产品模块ID'} bind={'moduleId'} required component={Display} />
                            <HFormInput label={'产品经理'} bind={'manager'} clear component={mode === 'detail' ? Display : UserSelect}
                                convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount', { showOrigin: true })}
                            />
                            <HFormInput label={'发布日期'} bind={'releaseDate'} component={mode === 'detail' ? Display : DatePicker} clear labelWidth={124}
                                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                                convert={v => {
                                    if (_.isNil(v)) return '-'
                                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD', v) || '-'
                                }}
                            />
                            <HFormInput label={'一级类别'} bind={'firstLevel'} required search options={firstLevelOptions}
                                convert={v => convertOptions(v, firstLevelOptions)} component={mode === 'detail' ? Display : Select} />
                            {
                                mode !== 'detail' &&
                                <Icon name='tianjia' className='sales-product-add-icon' onClick={() => setOpenType('firstLevel')} />
                            }
                            <HFormInput label={'二级类别'} bind={'secondLevel'} search options={secondLevelOptions} clear
                                convert={v => convertOptions(v, secondLevelOptions)} component={mode === 'detail' ? Display : Select} />
                            {
                                mode !== 'detail' &&
                                <Icon name='tianjia' className='sales-product-add-icon' onClick={() => setOpenType('secondLevel')} />
                            }
                            <HFormInput label={'三级类别'} bind={'thirdLevel'} search options={thirdLevelOptions} clear
                                convert={v => convertOptions(v, thirdLevelOptions)} component={mode === 'detail' ? Display : Select} />
                            {
                                mode !== 'detail' &&
                                <Icon name='tianjia' className='sales-product-add-icon' onClick={() => setOpenType('thirdLevel')} />
                            }
                            <HFormInput label={'单位'} bind={'unit'} component={mode === 'detail' ? Display : Input} />
                            <HFormInput label={'数量'} bind={'quantity'} type={'number'} component={mode === 'detail' ? Display : Input} />
                            {
                                isManager &&
                                <HFormInput label={'标准单价'} bind={'priceStand'} type={'number'} component={mode === 'detail' ? Display : Input} labelWidth={124} />
                            }
                            <div />
                            <HFormInput label={'描述'} bind={'memo'} componentWidth={540} component={mode === 'detail' ? Display : TextArea} componentClass={'long-text'} />
                            {
                                mode !== 'add' &&
                                <div>
                                    <HFormInput label={'创建人'} bind={'createUser'} component={Display}
                                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                                    <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
                                        convert={v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.num14ToDate(v))} />
                                    <HFormInput label={'修改人'} bind={'updateUser'} component={Display}
                                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                                    <HFormInput label={'修改时间'} bind={'updateTime'} component={Display}
                                        convert={v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.num14ToDate(v))} />
                                </div>
                            }
                            {
                                mode === 'add' &&
                                <div className="continue-add">
                                    <CheckBox value={continueAdd} onChange={setContinueAdd}>继续创建下一条</CheckBox>
                                </div>
                            }
                        </Form>
                    </TabPane>
                    <TabPane tab="动态" key="dynamic">
                        <div className={'dynamic-wrap'} key={count}>
                            <ChangeRecord name='营销产品清单' funcCode={'41'} referenceId={id} associatedField={'system'} associatedFieldName={'产品'} id={id} title={_.get(detailInfo, 'system')} linkUrl={`/salesProductViewMgt`} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div className="mock-footer flex center-y">
                <div />
                <div className="btn-group flex">
                    {
                        mode !== 'edit' &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        mode === 'edit' &&
                        <Button normal onClick={() => {
                            setMode('detail')
                            setFormData(detailInfo)
                        }}>取消</Button>
                    }
                    {
                        mode !== 'detail' &&
                        <Button primary onClick={submit} disable={confirmDisabled}>确认</Button>
                    }
                </div>
            </div>
        </div>
        {
            !_.isNil(openType) &&
            <Dialog
                header='增加'
                cancel={() => setOpenType(null)}
                confirm={confirmType}
            >
                <HFormInput required labelWidth={80} label='类别名称' bind='name' onChange={setLevelName} />
            </Dialog>
        }
    </Dialog>
}

export default SalesProductViewDetailDialog;
import React from 'react';
import clsx from "clsx";
import _ from 'lodash';
import { Card } from 'rootnet-ui';
import './LeftMenu.scss';

export function LeftMenu(props) {
    const { data, productId, loading, error, onClick } = props;
    return <Card className="details-left-menu" loading={loading} error={error} >
        {
            _.map(data, (x, i) => {
                return <div className={clsx('details-left-menu-item', { active: _.toString(x.id) === _.toString(productId) })}
                    key={i} onClick={() => onClick(x.id)}>
                    {x.projectName}
                </div>
            })
        }
    </Card>
}

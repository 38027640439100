import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ReqStageRelateConference.scss'
import {DataGrid} from 'rootnet-ui'
import {useGet} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import Icon from "../../../../../../components/Icon";
import {Tooltip} from "antd";
import DelMessage from "../../../../../../components/DelMessage";
import {TextIconBtn} from "../../../../../common/TextIconBtn";
import ConferenceQueryDialog from "../../conferenceQueryDialog/ConferenceQueryDialog";
import ConferenceRecordsDetailDialog
    from "../../../../../conferenceMgt/conferenceRecords/conferenceRecordsDetail/ConferenceRecordsDetailDialog";
import {strParams} from "../../../../../../utils/publicFun";
import ReviewMinutesDetailDialog from '../../../../../conferenceMgt/reviewMinutesMgt/controls/reviewMinutesDetailDialog';

function getColumns(props){
    const {setDisassociateId, setShowDetailDialog} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center',stretch: false, width: 40 },
        { header: '会议纪要名称', bind: 'ssubject', width: 180, tooltip: true,
            convert: (v, o) => <div className={'name-wrap'} onClick={()=>{
                setShowDetailDialog({
                    module: o.sconferenceType === 'AM' ? 'reviewMinutes' : 'conference',
                    id: _.get(o, 'iid')
                })
            }}>
                {v}
            </div> },
        { header: '记录人', bind: 'saddPerson', width: 80, stretch: false, tooltip: true },
        { header: '记录时间', bind: 'dAddTime', width: 140, stretch: false, tooltip: true },
        { header: '操作', bind: '', width: 60, stretch: false,align: 'center',
            convert: (v,o) => <Tooltip title={'取消关联'}>
                <span className={'flex center'} onClick={()=>setDisassociateId(o.iid)}>
                    <Icon name={'quxiaoguanlian'} className={'disassociate-icon'}/>
                </span>
            </Tooltip>},
    ]
}

function getOption(columns){
    return {
        columns,
        autoFill: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
    }
}

function ReqStageRelateConference(props) {
    const {stageId, afterRefresh, updateInnerDynamic, listUrl, disassociateUrl, relateUrl, isShow = true} = props
    const {data: list, doFetch: getList} = useGet()
    const [disassociateId, setDisassociateId] = useState()
    const [showSelectConference, setShowSelectConference] = useState(false)
    const [showDetailDialog, setShowDetailDialog] = useState()

    const refreshList = useCallback(()=>{
        if(_.isNil(stageId)) return
        getList(listUrl+'?'+strParams({stageId}))
    },[stageId, getList, listUrl])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const option = useMemo(()=>{
        return getOption(getColumns({setDisassociateId, setShowDetailDialog}))
    },[])

    const afterUpdateRefresh = useCallback(()=>{
        refreshList()
        afterRefresh()
        updateInnerDynamic()
    },[refreshList,afterRefresh,updateInnerDynamic])

    return <div className={'req-stage-relate-conference flex-y'}>
        <div className={'relate-conference-icon-wrap flex'}>
            {isShow && <TextIconBtn icon={'guanlian'} text={'关联会议纪要'} onClick={()=>setShowSelectConference(true)}/>}
        </div>
        <DataGrid data={list} option={option} className='x-datagrid'/>
        {
            disassociateId &&
            <DelMessage url={disassociateUrl} params={{referenceId: stageId, recordId: disassociateId}}
                        close={()=>setDisassociateId(null)} refresh={afterUpdateRefresh} method={'post'}>
                确认解除关联吗？
            </DelMessage>
        }
        {
            showSelectConference &&
            <ConferenceQueryDialog close={()=>setShowSelectConference(false)}
                                   initInfo={{id: stageId, initList: _.map(list, 'iid')}}
                                   afterUpdateRefresh={afterUpdateRefresh}
                                   relateUrl={relateUrl}
            />
        }
        {
            _.get(showDetailDialog,'module') === 'conference' &&
            <ConferenceRecordsDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                cid: _.get(showDetailDialog,'id')
            }}/>
        }
        {
            _.get(showDetailDialog,'module') === 'reviewMinutes' &&
            <ReviewMinutesDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                cid: _.get(showDetailDialog,'id')
            }}/>
        }
    </div>
}

export default ReqStageRelateConference;
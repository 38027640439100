import React, { useMemo, useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import convertTableAlign from '../../../../../common/view/convertTableAlign'
import findConvert from '../../../../../common/view/findConvert'
import useGetViewConfig from '../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../common/view/hooks/useRefreshList'
import useGetTreeList from '../../../../../common/view/hooks/useGetTreeList'
import useGetDateOptions from '../../../../../common/view/hooks/useGetDateOptions'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import ViewArea from '../../../../../common/view/ViewArea'
import { Box } from '../../../../../common/commonComponent'
import { useContext } from 'react'
import { ScheduleManagementContext } from '../../../../../common/Context'
import { strParams } from '../../../../../../utils/publicFun'
import ReqStageUpdateDialog from '../../../../../stageMgt/reqStageUpdateDialog/ReqStageUpdateDialog'
import convertOptions from '../../../../../common/ConvertOptions'
import WorkFlowChangePop from '../../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import './index.scss'
import { gd } from 'rootnet-core/base'
import ExportDeliveryLog from './exportDeliveryLog'

const EXPORT_URL = '/checkList/exportStage' //手册编写 需求分析与评审

const ENTER_DETAIL_FIELD = 'story_stage.stage'
const RICH_TEXT_FIELD = ['story.description']

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, workFlowId, setWorkFlowId, refreshList } = props

  const customColumns = []

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: !_.includes(_.concat(RICH_TEXT_FIELD, [ENTER_DETAIL_FIELD]), `${fieldItem.tableName}.${fieldItem.fieldId}`),
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === 'workflow_business.factorId') {
      const convertRule = _.find(convertCollection, (v, k) => k === tableField)
      const color = convertOptions(v, convertRule, 'color')
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)}
          refreshList={refreshList} funcCode={FUNC_CODE} businessType={'stage'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: color, border: `1px solid ${color}`, padding: '0 8px', borderRadius: 4, cursor: 'pointer' }}>
          {showValue || '-'}
        </div>
      </Popover>
    }

    if (_.includes(RICH_TEXT_FIELD, tableField)) {
      return <Tooltip title={<div className='version-req-stage-task-rich-text-value' dangerouslySetInnerHTML={{ __html: showValue?.replace(/\n/g, '<div/>') }} />}>
        <div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div>
      </Tooltip>
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
      </div>
    }
    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});


function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '083000'

export default function VersionReqStageTask(props) {
  const { currentId, productOwner, isReqStageExport } = props
  const { downloadPostProgress, downloadGetProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [factor, setFactor] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentInfo, setCurrentInfo] = useState(null)
  const [workFlowId, setWorkFlowId] = useState()
  const [selectList, setSelectList] = useState([])

  useEffect(() => {
    const findItem = _.find(fieldList, fieldItem => `${fieldItem.tableName}.${fieldItem.fieldId}` === 'story_stage.stage')
    if (_.isNil(findItem)) return
    _.forEach(list, o => {
      o['stage'] = o[_.get(findItem, 'columnId')]
    })
  }, [list, fieldList])

  const isProductOwner = useMemo(() => {
    return productOwner === gd.User.operator_id || isReqStageExport
  }, [productOwner, isReqStageExport])

  const appendObj = useMemo(() => {
    return { 'factorViewSql': `ReleaseInfo.ReleaseID='${currentId}'` }
  }, [currentId])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, workFlowId, setWorkFlowId, refreshList }, })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
      ...appendObj,
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '需求阶段任务')
  }, [currentViewId, fieldList, factor, downloadPostProgress, appendObj])

  const exportTestRecord = useCallback(() => {
    downloadGetProgress(`/releaseinfo/export/stage?${strParams({ releaseId: currentId })}`, '验收测试记录')
  }, [downloadGetProgress, currentId])

  const exportAnalyseAndReview = useCallback(() => {
    const list = _.filter(selectList, o => o.stage === 'Req-Review')
    if (_.isEmpty(list)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
    const stageIdList = _.map(list, 'id')
    const getParams = { releaseId: currentId, stageIdList, stone: 'Req-Review' }
    downloadGetProgress(`${EXPORT_URL}?${strParams(getParams)}`, '导出需求分析与评审交付件', () => setSelectList([]))
  }, [selectList, currentId, downloadGetProgress])

  const exportManualWriting = useCallback(() => {
    const list = _.filter(selectList, o => o.stage === 'writeManual')
    if (_.isEmpty(list)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
    const stageIdList = _.map(list, 'id')
    const getParams = { releaseId: currentId, stageIdList, stone: 'writeManual' }
    downloadGetProgress(`${EXPORT_URL}?${strParams(getParams)}`, '导出手册编写交付件', () => setSelectList([]))
  }, [selectList, currentId, downloadGetProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='' text='交付件导出日志' onClick={() => setCurrentInfo({ mode: 'deliveryLog' })} />
      <MoreOperations {...{ exportViewList, exportTestRecord, exportAnalyseAndReview, exportManualWriting, isProductOwner }} />
    </div>
  }, [exportViewList, exportTestRecord, exportAnalyseAndReview, exportManualWriting, isProductOwner])

  const replaceParams = useMemo(() => {
    return ({ 'ReleaseInfo.ReleaseID': currentId, })
  }, [currentId])

  return (
    <div className={'version-req-stage-task fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={'需求阶段任务'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} selection={selectList} onSelectionChange={setSelectList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'detail' &&
        <ReqStageUpdateDialog
          close={() => setCurrentInfo(null)}
          {...{ currentInfo, refreshList }}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'deliveryLog' &&
        <ExportDeliveryLog
          close={() => setCurrentInfo(null)}
          {...{ releaseId: currentId }}
        />
      }
    </div>
  )
}

function MoreOperations({ exportViewList, exportTestRecord, exportAnalyseAndReview, exportManualWriting, isProductOwner }) {
  const [clicked, setClicked] = useState(false)
  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={exportViewList}>导出列表</div>
      <div onClick={exportTestRecord}>导出验收测试记录</div>
      {
        isProductOwner &&
        <>
          <div onClick={exportAnalyseAndReview}>导出需求分析与评审交付件</div>
          <div onClick={exportManualWriting}>导出手册编写交付件</div>
        </>
      }
    </div>
  }, [exportViewList, exportTestRecord, exportAnalyseAndReview, exportManualWriting, isProductOwner])
  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='导出' onClick={() => setClicked(x => !x)} />
    </Popover>
  )
}

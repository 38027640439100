import React, { useState } from "react";
import Icon from "../../project_share/components/Icon";
import { MessageBox } from 'rootnet-ui';
import '../../project_share/components/DetailsGoBack.scss';
import { P_Clsx } from "../../project_share/utils/utils-sp";

export default function DetailsGoBackWithTextClick(props) {
    const { text, onClick, useTips, className, style, messageInfo } = props;
    const [message, setMessage] = useState(false);

    return <div className={P_Clsx('all-go-back', className)} style={style}>
        <Icon name='fanhui' onClick={useTips ? () => setMessage(true) : onClick} />
        <span className="all-back-title" onClick={useTips ? () => setMessage(true) : onClick} style={{cursor: 'pointer'}}>{text}</span>
        {
            message &&
            <MessageBox
                className={'content-center-dialog'}
                header="提示"
                cancel={() => setMessage(false)}
                confirm={onClick}
            >
                {messageInfo}
            </MessageBox>
        }
    </div>;
}
DetailsGoBackWithTextClick.defaultProps = {
    useTips: false,//是否使用弹窗
    messageInfo: '离开当前页面，内容将不被保存',//弹窗提示信息
};

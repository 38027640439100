import React from 'react'
import { Dialog } from 'rootnet-ui'
import RoleDetailContent from './roleDetailContent'
import './index.scss'

export default function RoleDetailDialog(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog className={'role-detail-dialog'} headerVisible={false} footerVisible={false}>
      <RoleDetailContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

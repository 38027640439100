import React, { useEffect, useState } from 'react';
import { DatePicker, Display, Form, FormInput, Input, RadioGroup, Select, TextArea } from "rootnet-edit";
import convertOptions from "../../../../../common/ConvertOptions";
import UserSelect from "../../../../../common/personSelectPop/UserSelect";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import _ from "lodash";
import { Popover } from "antd";
import RequirementStageUploadArea from "../../requirementStageUploadArea/RequirementStageUploadArea";
import Icon from "../../../../../../components/Icon";
import './RequirementStageInfo.scss'

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={180} {...props} />

function RequirementStageInfo(props) {
    const { editFormData, setEditFormData, formError, setFormError, stageOptions, milestoneFlagOptions, mode, isDetail,
        stageObj, stageIndex, storyId, allUserRes, updateDoc, setShowList, canEditPlan, versionOptions, deliveryList, setDeliveryList } = props

    const [docCount, setDocCount] = useState(0)
    const [stageDocList, setStageDocList] = useState([])

    useEffect(() => {
        const value = _.get(stageObj, 'relatedocument')
        setStageDocList(value ? _.split(value, ',') : [])
    }, [stageObj])

    useEffect(() => {
        setDocCount(_.size(stageDocList))
    }, [stageDocList])

    return <div className={'requirement-stage-info'}>
        <Form value={editFormData} onChange={setEditFormData} error={formError} onError={setFormError}>
            {
                mode === 'add' &&
                <HFormInput label={'任务名称'} bind={'title'} component={isDetail ? Display : Input} required componentWidth={487} />
            }
            <HFormInput label={'阶段'} bind={'stage'} component={isDetail ? Display : Select} convert={v => convertOptions(v, stageOptions)} options={stageOptions} required />
            <HFormInput label={'负责人'} bind={'owner'} component={isDetail ? Display : UserSelect} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} required marginLeft />
            <HFormInput label={'版本计划'} bind={'releaseIdList'} component={isDetail ? Display : Select} componentWidth={487} options={versionOptions}
                convert={v => _.join(_.map(v, x => convertOptions(x, versionOptions)), ',')} multiple clear />
            <HFormInput disabled={!canEditPlan} label={'计划开始时间'} bind={'taskStartTime'} component={isDetail ? Display : DatePicker} clear bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD', v) || '-'
                }} />
            <HFormInput label={'计划完成时间'} bind={'completetimeplan'} component={isDetail ? Display : DatePicker} disabled={!canEditPlan} clear
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD', v) || '-'
                }} />
            <HFormInput label={'计划投入'} bind={'workloadplan'} suffix={'人日'} clear convert={v => _.isNil(v) ? '-' : (v + '人日')} disabled={!canEditPlan}
                type={'number'} digit={2} min={0} component={isDetail ? Display : Input} />
            <HFormInput disabled={!canEditPlan} label={'进度'} bind={'speed'} suffix={'%'} type={'number'} min={0} max={100} digit={0} component={isDetail ? Display : Input} convert={v => {
                if (_.isNil(v)) return '-'
                return `${v}%`
            }} />
            <HFormInput label={'实际完成时间'} bind={'completetimeactual'} component={isDetail ? Display : DatePicker} clear
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD', v) || '-'
                }} />
            <HFormInput label={'实际投入'} bind={'workloadactual'} suffix={'人日'} convert={v => _.isNil(v) ? '-' : (v + '人日')}
                type={'number'} digit={2} min={0} component={isDetail ? Display : Input} />
            <HFormInput label={'里程碑'} bind={'milestoneflag'} component={isDetail ? Display : RadioGroup}
                options={milestoneFlagOptions} convert={v => convertOptions(v, milestoneFlagOptions)} />
            {
                mode !== 'add' &&
                <Popover zIndex={1000} content={<RequirementStageUploadArea stageDocList={stageDocList} stageObj={stageObj} stageIndex={stageIndex} id={storyId} funcCode={'15'}
                    {...{ allUserRes, updateDoc, setShowList, setStageDocList, setEditFormData }} />}>
                    <span style={{ marginLeft: mode === 'edit' ? 132 : 46 }} className={'relate-document-icon-wrap'}>
                        <Icon name={'lianjie'} style={{ fontSize: 14 }} className={'relate-document-icon'} />({docCount})
                    </span>
                </Popover>
            }

            {
                _.map(deliveryList, (o, i) => {
                    return <div
                        key={i}
                        className='delivery-list-item flex'
                        style={{ paddingLeft: i === 0 ? 0 : 110 }}
                    >
                        <HFormInput
                            required
                            maxLength={500}
                            componentWidth={487}
                            value={_.get(o, 'delivery')}
                            className='text-area-memo'
                            placeholder='不涉及可填"无"'
                            label={i === 0 ? '交付物' : ''}
                            onChange={v => setDeliveryList(x => {
                                const filterData = _.filter(x, d => d.id !== o.id)
                                return _.orderBy(_.concat(filterData, [{ id: o.id, delivery: v, type: _.get(o, 'type') === 'add' ? 'add' : 'update' }]), 'id', 'asc')
                            })}
                            component={isDetail ? Display : TextArea}
                        />
                        {
                            i !== 0 && !isDetail && <Icon name='close' onClick={() => {
                                setDeliveryList(x => _.filter(x, d => d.id !== o.id))
                            }} />
                        }
                    </div>
                })
            }
            {
                !isDetail &&
                <div className='delivery-list-add-item' >
                    <span onClick={() => {
                        const newId = _.max(_.map(deliveryList, 'id')) + 1
                        setDeliveryList(x => _.concat(x, [{ id: newId, value: null, type: 'add' }]))
                    }}>
                        <Icon name='tianjia' style={{ marginRight: 4, fontSize: 14 }} />添加一行
                    </span>
                </div>
            }
            <HFormInput className='text-area-memo' label={'内容描述'} bind={'description'} component={isDetail ? Display : TextArea} componentWidth={487} maxLength={500} />
            <HFormInput className='text-area-memo' label={'备注'} bind={'memo'} component={isDetail ? Display : TextArea} componentWidth={487} maxLength={500} />
        </Form>
    </div>
}

export default RequirementStageInfo;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Form} from "rootnet-edit";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import clsx from "clsx";
import {Button, Messager, Loader} from 'rootnet-ui'
import './WorkFlowChangePop.scss'
import {strParams} from "../../../utils/publicFun";
import usePost from "rootnet-biz/es/hooks/usePost";
import {Empty} from "antd";
import {CustomizeFormInput} from "../../common/customizeOptions/CustomizeOptions";
import useGetFieldConvertCollection from "../../common/view/hooks/useGetFieldConvertCollection";
import Icon from "../../../components/Icon";
import WorkFlowGraphDialog from "../workFlowGraph/WorkFlowGraphDialog";
import {TextIconBtn} from "../../common/TextIconBtn";
import CheckConditionGroup from '../../common/view/checkConditionGroup/CheckConditionGroup';
import {isNil} from "../../appraise/components/method";
import convertOptions from "../../common/ConvertOptions";
import moment from "moment";

const funcCodeTrans = {
    '1501': '8866',
    '19': '8819',
    '20': '8820',
    '0504': '880504',
    '26': '8826',
    '1526': '881526',
    '23': '8823',
    '0851': '880851',
}

const pickField = ['conditionList','conditionRelation','isConditionGroup','relation','value','field']

function recursionParams(data, arr = []){
    _.forEach(data, o=>{
        arr.push(_.pick(o,pickField))
        if(!_.isEmpty(o.conditionList)) recursionParams(o.conditionList)
    })
    return arr
}

function WorkFlowChangePop(props) {
    const {businessId, close = ()=>{}, refreshList= ()=>{}, funcCode, businessType, defaultParams = {}} = props
    const {data: statusList,doFetch: getStatusList} = useGet()
    const {data: workFlowInfo, doFetch: getWorkFlowInfo} = useGet()
    const { doFetch: getFieldCollection} = useGet()
    const [formData, setFormData] = useState()
    const {doFetch: submitChange} = usePost()
    const [statusInfo, setStatusInfo] = useState()
    const {data: authInfo, doFetch: getAuth} = useGet()
    const [formLoading, setFormLoading] = useState(false)
    const [formError, setFormError] = useState()
    const [initForm, setInitForm] = useState()
    const {data: conditionInfo, doFetch: getCondition} = useGet()
    const [loading, setLoading] = useState(false)
    const [formType, setFormType] = useState()
    const {data: workFlowGraph, doFetch: getWorkFlowGraph} = useGet()
    const [showWorkFlowGraph, setShowWorkFlowGraph] = useState(false)
    const {data: fieldListRes, doFetch: getFieldList } = useGet()
    const {doFetch: getCurrentCondition} = useGet()
    const {doFetch: getConditionFetch} = usePost()
    const [fieldCollection, setFieldCollection] = useState(null)
    const [conditionGroup, setConditionGroup] = useState(null)
    const [noMatch, setNoMatch] = useState(false)
    const [checkBeforeConditionDone, setCheckBeforeConditionDone] = useState(false)
    const [noMatchText, setNoMatchText] = useState()
    const {doFetch: getLimitDept} = usePost()
    const [allowRange, setAllowRange] = useState()

    useEffect(()=>{
        const workflowId = _.get(workFlowInfo,'workflowId')
        if(_.isNil(workflowId)) return
        getWorkFlowGraph('/workflowTemporaryShow/getWorkflowTemporaryShow?workflowId='+workflowId)
    },[workFlowInfo, getWorkFlowGraph])

    // 获取人员选择范围
    useEffect(()=>{
        if(_.isNil(workFlowInfo)) return
        const { id }=  workFlowInfo || {}
        if(_.isNil(id)) return
        if(_.isNil(statusInfo)) return
        if(_.isNil(businessId)) return
        const getParams = {
            id,
            factorId: _.get(statusInfo,'endFactor'),
            stage:'department',
        }
        getCurrentCondition(`/WorkflowCondition/getCondition?${strParams(getParams)}`).then(res => {
            if(_.isNil(res)){
                setAllowRange([])
            }else{
                const factorList = []
                _.forEach(res, x => {
                    const {conditionRelation, conditionList} = x
                    const factor = JSON.stringify({
                        conditionRelation,
                        conditionList: recursionParams(conditionList,[])
                    })
                    factorList.push(factor)
                })
                const postParams = _.assign({}, {
                    businessId,
                    businessType,
                    factorId: _.get(statusInfo,'endFactor'),
                    menuCode: funcCode,
                    factorList
                })
                getLimitDept('/WorkflowCondition/departmentCondition', postParams).then(res => {
                    setAllowRange(res.range)
                })
            }
        })
    },[workFlowInfo, statusInfo, businessId, getCurrentCondition, businessType, funcCode, getLimitDept])

    useEffect(()=>{
        if(_.isNil(workFlowInfo)) return
        const { id }=  workFlowInfo || {}
        if(_.isNil(id)) return
        if(_.isNil(statusInfo)) return
        if(_.isNil(businessId)) return
        const getParams = {
            id,
            factorId: _.get(statusInfo,'endFactor'),
            stage:'before',
        }
        getCurrentCondition(`/WorkflowCondition/getCondition?${strParams(getParams)}`)
        .then(res=>{
            if(_.isNil(res)) {
                getNextFieldConfigList()
            } else{
                const {conditionRelation, conditionList} = _.head(res)
                const factor = JSON.stringify({
                        conditionRelation,
                        conditionList: recursionParams(conditionList,[])
                })
                const postParams = _.assign({}, {
                    businessId,
                    businessType,
                    factorId: _.get(statusInfo,'endFactor'),
                    menuCode: funcCode,
                    factor:factor
                })
                getConditionFetch('/WorkflowCondition/beforeCondition', postParams)
                .then(res => {
                    if(res.circulation){
                        getNextFieldConfigList()
                    }else{
                        setCheckBeforeConditionDone(true)
                        setFieldCollection(null)
                        setNoMatch(true)
                        if(res.isShowConditionText){
                            setNoMatchText(res.conditionText)
                        }else{
                            setConditionGroup(_.head(res.workflowCondition))
                        }
                    }
                })
                .catch(err => {
                    setCheckBeforeConditionDone(true)
                    setFieldCollection(null)
                    setNoMatch(true)
                    setConditionGroup(null)
                    Messager.show(err._message, {icon: 'error'})
                })
            }
        })

        function getNextFieldConfigList(){
            setConditionGroup(null)
            setNoMatch(false)
            const searchParams = {
                workflowId: _.get(workFlowInfo, 'workflowId'),
                startFactor: _.get(statusInfo, 'startFactor'),
                endFactor: _.get(statusInfo,'endFactor'),
                isManager: _.get(statusInfo,'isManager'),
                businessId,
                funcCode,
            }
            getFieldCollection('/WorkflowFieldConfig/getNextFieldConfigList?'+strParams(searchParams))
                .then(res => {
                    setCheckBeforeConditionDone(true)
                    setFieldCollection(res)
                })
                .catch(err => {
                    setCheckBeforeConditionDone(true)
                    setFieldCollection(null)
                    Messager.show(err._message, { icon: 'error' })
                })
        }
    },[getCurrentCondition, workFlowInfo, statusInfo, getConditionFetch, businessId, businessType,funcCode, getFieldCollection, getLimitDept])

    const isPassCondition = useMemo(()=>{
        return _.get(conditionInfo,'pass')
    },[conditionInfo])

    const fieldList = useMemo(()=>{
        if(_.isNil(fieldCollection)) return []
        const fieldList =  _.map(fieldCollection, x => _.get(x,'systemFormItemFieldDefinition'))
        return _.filter(fieldList, x => !_.isNil(x))
    },[fieldCollection])

    const convertCollection = useGetFieldConvertCollection(fieldList,setFormLoading)

    const noAuth = useMemo(()=>{
        return _.get(authInfo,'contains') === false
    },[authInfo])

    // 获取全字段
    useEffect(()=>{
        if(_.isNil(funcCode)) return
        const pageCode = _.get(funcCodeTrans,funcCode) || funcCode
        getFieldList('/SystemFormPage/getSystemFormPage?pageCode='+pageCode)
    },[getFieldList, funcCode])

    const allFieldList = useMemo(()=>{
        const fields = _.get(fieldListRes,'formItems')
        return _.map(fields, x => ({
            ...x,
            tableField: x.tableName + '.' + x.fieldId
        }))
    },[fieldListRes])

    // 权限判断
    useEffect(()=>{
        if(_.isNil(businessId)) return
        if(_.isNil(funcCode)) return
        if(_.isNil(statusInfo)) return
        const searchParams = {
            id: businessId,
            funcCode: _.get(funcCodeTrans,funcCode) || funcCode,
            factorIdStart: _.get(statusInfo,'startFactor'),
            factorIdEnd: _.get(statusInfo,'endFactor'),
            isManager: _.get(statusInfo,'isManager'),
        }
        getAuth('/WorkflowUsergroup/getContainsWorkflowUsergroup?'+strParams(searchParams))
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    },[getAuth, businessId, funcCode, statusInfo])

    useEffect(()=>{
        if(_.isNil(workFlowInfo)) return
        if(_.isNil(businessId)) return
        if(_.isNil(funcCode)) return
        if(_.isNil(statusInfo)) return
        const postParams = {
            id: _.get(workFlowInfo,'id'),
            factorId: _.get(statusInfo,'endFactor'),
            funcCode: funcCode,
            businessId: businessId,
        }
        getCondition('/WorkflowCondition/issueCondition?'+strParams(postParams))
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    },[getCondition, businessId, funcCode, statusInfo, workFlowInfo])

    const submit = useCallback(()=>{
        setLoading(true)
        let handleSubmitForm = []

        _.forEach(formData, (v, k) => {
            const splitField = _.split(k,'.')
            const item = {
                businessId: businessId,
                tableName: splitField[0],
                fieldId: splitField[1],
                oldValue: _.get(formType,k) === '1' ? null :_.get(initForm,k),
                newValue: v,
                funcCode: funcCode,
            }
            if(k === 'workflow_business.currentUser'){
                item['oldValue'] = null
            }
            handleSubmitForm.push(item)
        })
        const postParams = _.assign({},{
            id: _.get(workFlowInfo,'id'),
            factorId: _.get(statusInfo,'endFactor'),
            funcCode: funcCode,
            businessId: businessId,
            fieldConfigList: handleSubmitForm,
            businessType,
        }, defaultParams)
        const getParams = {
            id: _.get(workFlowInfo,'id'),
            factorId: _.get(statusInfo,'endFactor'),
            stage:'after',
        }
        const postPar = _.assign({},{
            businessId,
            configId: _.get(statusInfo,'id'),
            workflowId: _.get(statusInfo,'workflowId'),
        }, !isNil(_.get(formData, 'workflow_business.currentUser')) && { currentUser: _.get(formData, 'workflow_business.currentUser') })

        getConditionFetch('/WorkflowCondition/exclusionCondition', postPar)
        .then(res=>{
            if(res === true){
                getCurrentCondition(`/WorkflowCondition/getCondition?${strParams(getParams)}`)
                .then(res=>{
                    if(_.isNil(res)){
                        submitForm()
                    }else{
                        const {conditionRelation, conditionList} = _.head(res)
                        const factor = JSON.stringify({
                            conditionRelation,
                            conditionList: recursionParams(conditionList,[])
                        })
                        const postPar = _.assign({}, {
                            businessId,
                            businessType,
                            factorId: _.get(statusInfo,'endFactor'),
                            menuCode: funcCode,
                            factor,
                            fieldConfigList: handleSubmitForm,
                        })
                        getConditionFetch('/WorkflowCondition/afterCondition', postPar)
                            .then(res => {
                                if(res.circulation){
                                    submitForm()
                                }else{
                                    setLoading(false)
                                    setNoMatch(true)
                                    if(res.isShowConditionText){
                                        setNoMatchText(res.conditionText)
                                    }else{
                                        setConditionGroup(_.head(res.workflowCondition))
                                    }
                                }
                            })
                            .catch(err => {
                                setConditionGroup(null)
                                Messager.show(err._message, {icon: 'error'})
                                setLoading(false)
                            })
                    }
                })
            }
        })
        .catch(err => {
            setLoading(false)
            Messager.show(err._message, {icon: 'error'})
        })
        function submitForm(){
            submitChange('/WorkflowBusiness/update',postParams).then(()=>{
                submitChange('/WorkflowBusiness/automaticCustomer', postParams).then(()=>{
                    setLoading(false)
                    refreshList()
                    close()
                }).catch(()=>{
                    setLoading(false)
                    refreshList()
                    close()
                })
            }).catch(err => {
                setLoading(false)
                Messager.show(err._message, {icon: 'error'})
            })
        }
    },[submitChange, workFlowInfo, statusInfo,close,refreshList,funcCode, businessId, formData, initForm, formType, businessType, getCurrentCondition, getConditionFetch, defaultParams])

    useEffect(()=>{
        if(_.isNil(businessId)) return
        const params = {
            businessType,
            businessId
        }
        getWorkFlowInfo('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    },[getWorkFlowInfo, businessId, businessType])

    useEffect(()=>{
        if(_.isNil(businessId)) return
        const params = {
            businessId,
            funcCode
        }
        getStatusList('/WorkflowConfig/getWorkflowActionConfigList?'+strParams(params))
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    },[getStatusList, businessId, funcCode])

    const canSubmit = useMemo(()=>{
        if(_.isNil(authInfo)) return false
        if(!isPassCondition) return false
        if(noAuth) return false
        if(_.isNil(statusInfo)) return false
        if(!checkBeforeConditionDone) return false
        if(noMatch) return false
        const fields = _.map(fieldList, fieldItem => `${fieldItem.tableName}.${fieldItem.fieldId}`)
        if(_.some(_.pick(formError,fields), x => !_.isNil(x))) return false

        // 阶段：计划开始时间、计划结束时间校验
        const stageBeginTime = _.get(formData,'story_stage.taskStartTime')
        const stageEndTime = _.get(formData,'story_stage.completeTimePlan')
        if(!_.isNil(stageBeginTime) && !_.isNil(stageEndTime)){
            if(moment(stageBeginTime).isAfter(stageEndTime)) return false
        }
        return true
    },[statusInfo, noAuth, authInfo, formError, fieldList, isPassCondition, noMatch, checkBeforeConditionDone, formData])

    useEffect(()=>{
        let defaultFormData = {}
        let defaultFormType = {}
        _.forEach(fieldCollection, x => {
            const fieldItem = _.get(x,'systemFormItemFieldDefinition')
            if(_.isNil(fieldItem)) return
            defaultFormData[`${fieldItem.tableName}.${fieldItem.fieldId}`] = x.defaultValueName
            defaultFormType[`${fieldItem.tableName}.${fieldItem.fieldId}`] = x.defaultType
        })
        setInitForm(defaultFormData)
        setFormData(defaultFormData)
        setFormType(defaultFormType)
    },[fieldCollection])

    const onFormChange = useCallback((newObj, bind)=>{
        setNoMatch(false)
        setFormData(newObj)
    },[])

    const normalStatusList = useMemo(()=>{
        return _.filter(statusList, x => x.isManager !== 'Y')
    },[statusList])

    useEffect(()=>{
        if(_.isEmpty(normalStatusList)) return
        setCheckBeforeConditionDone(false)
        setNoMatchText(null)
        setStatusInfo(_.head(normalStatusList))
    },[normalStatusList])

    const managerStatusList = useMemo(()=>{
        return _.filter(statusList, x => x.isManager === 'Y')
    },[statusList])

    return <div className={'work-flow-change-pop flex'}>
        {
            showWorkFlowGraph && <WorkFlowGraphDialog currentNodeName={_.get(workFlowInfo,'factorName')} initData={workFlowGraph} close={()=>setShowWorkFlowGraph(false)}/>
        }
        <div className="work-flow-change-left flex-y">
            <div className="status-list-wrap flex-y">
                {
                    _.map(normalStatusList, item => (<div onClick={()=> {
                        setCheckBeforeConditionDone(false)
                        setNoMatchText(null)
                        setStatusInfo(item)
                    }} key={item.endFactor}
                                                    className={clsx('status-item flex center', {selected:item.id === _.get(statusInfo,'id')})}>
                        {item.actionName}
                        {
                            item.id === _.get(statusInfo,'id') &&
                            <div className={'item-selected flex center'}>
                                √
                            </div>
                        }
                    </div>))
                }
                {
                    !_.isEmpty(managerStatusList) && <div className={'manager-status-list-wrap flex-y'}>
                        <div className="manager-text">
                            流程外流转操作：
                        </div>
                        {
                            _.map(managerStatusList, item => (<div onClick={()=> {
                                setCheckBeforeConditionDone(false)
                                setNoMatchText(null)
                                setStatusInfo(item)
                            }} key={item.endFactor}
                                                                  className={clsx('status-item flex center', {selected:item.id === _.get(statusInfo,'id')})}>
                                {item.actionName}
                                {
                                    item.id === _.get(statusInfo,'id') &&
                                    <div className={'item-selected flex center'}>
                                        √
                                    </div>
                                }
                            </div>))
                        }
                    </div>
                }
                {
                    _.isEmpty(statusList) && !_.isNil(statusList) &&
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'无流转动作'}/>
                }
            </div>
            <TextIconBtn className={'work-flow-show-btn'} disabled={_.isNil(workFlowGraph)} onClick={()=>setShowWorkFlowGraph(true)} text={'查看工作流'}/>
            {/*<Button normal className={'work-flow-show-btn'} disabled={_.isNil(workFlowGraph)} onClick={()=>setShowWorkFlowGraph(true)}></Button>*/}
        </div>
        <div className="work-flow-change-right flex-y">
            <div className={'handle-btn-group flex'}>
                <Button primary className={'handle-btn'} onClick={submit} disabled={!canSubmit}>确认</Button>
                <Button normal className={'handle-btn'} onClick={close}>取消</Button>
            </div>
            {
                (formLoading || loading) && <Loader fill/>
            }
            {
                !_.isNil(statusInfo) && !_.isNil(conditionInfo) && !isPassCondition &&
                <div className={'condition-message flex'}>
                    <Icon name={'tixing'} className={'tips-icon'}/>
                    <div className={'condition-message-text'}>{_.get(conditionInfo,'message')}</div>
                </div>
            }
            {
                !noAuth && isPassCondition &&
                <div className="status-form flex-y">
                    <div className={'next-node-tips flex center-y'}>
                        流转至
                        <div className={'next-node-name'} style={{color: _.get(statusInfo,'endFactorColor') || '#000', border: `1px solid ${_.get(statusInfo,'endFactorColor') || '#000'}`}}>
                            {_.get(statusInfo,'endFactorName')}
                        </div>
                    </div>
                     {
                        (!_.isNil(conditionGroup) || !isNil(noMatchText)) &&
                        <div className={'condition-tips-wrap flex center-y'}>
                            <Icon className={'condition-tips-icon'} name={'tixing'}/>
                            <div className="condition-tips-text">符合以下条件才可以流转</div>
                        </div>
                    }
                    {
                        !isNil(noMatchText) && <div className={'no-match-text'}>
                            {noMatchText}
                        </div>
                    }
                    {
                        !_.isNil(conditionGroup) &&
                        <CheckConditionGroup allColumns={allFieldList || []} editData={conditionGroup} setEditData={setConditionGroup} readOnly={true}/>
                    }
                    {
                        _.isEmpty(fieldCollection) && _.isNil(conditionGroup) && isNil(noMatchText) &&
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    }
                    {
                        !isNil(_.get(statusInfo,'workflowRemind.textRemind')) &&
                        <div className={'next-node-tips flex center-y'}>
                            提示：
                            <div className="node-tips-text">
                                {
                                    _.get(statusInfo,'workflowRemind.textRemind')
                                }
                            </div>
                        </div>
                    }
                    {
                        !_.isEmpty(fieldCollection) &&
                        <Form value={formData} onChange={onFormChange} error={formError} onError={setFormError}>
                        {
                            _.map(fieldCollection, x => {
                                const fieldItem = _.get(x,'systemFormItemFieldDefinition')
                                if(_.isNil(fieldItem)) return
                                return <CustomizeFormInput
                                    label={x.fieldName} horizontal={true} labelWidth={120} componentWidth={200} defaultValue={x.defaultValueName}
                                    fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} clear={x.isRequired !== '1'}
                                    allowClear={x.isRequired !== '1'}
                                    bind={`${fieldItem.tableName}.${fieldItem.fieldId}`}
                                    viewConditionType={fieldItem.viewConditionType}
                                    required={x.isRequired === '1'}
                                    key={x.id}
                                    fieldHtmlDetailType={fieldItem.fieldHtmlDetailType}
                                    accuracy={fieldItem.accuracy}
                                    allowDeptList={`${fieldItem.tableName}.${fieldItem.fieldId}` === 'workflow_business.currentUser' ? convertOptions('department',allowRange,'valueList','type') : []}
                                    allowUserList={`${fieldItem.tableName}.${fieldItem.fieldId}` === 'workflow_business.currentUser' ? convertOptions('user',allowRange,'valueList','type') : []}
                                    placeholder={isNil(x.placeholder)?undefined:x.placeholder}
                                    disabled={_.get(x,'disabled')}
                                />
                            })
                        }
                    </Form>
                    }
                </div>
            }
            {
                noAuth && isPassCondition &&
                <div className="no-auth-status-wrap flex-y">
                    <div className="no-auth-text">当前状态流转您无权限操作！</div>
                    <div className="auth-tips">有权限操作人员如下：</div>
                    <div className="auth-tips-item flex">
                        <div className="auth-tips-label">
                            用户组：
                        </div>
                        <div className="auth-tips-text">
                            {_.get(authInfo,'userGroup')}
                        </div>
                    </div>
                    <div className="auth-tips-item flex">
                        <div className="auth-tips-label">
                            人员字段：
                        </div>
                        <div className="auth-tips-text">
                            {_.get(authInfo,'peopleField')}
                        </div>
                    </div>
                    <div className="auth-tips-item flex">
                        <div className="auth-tips-label">
                            部门：
                        </div>
                        <div className="auth-tips-text">
                            {_.get(authInfo,'department')}
                        </div>
                    </div>
                    <div className="auth-tips-item flex">
                        <div className="auth-tips-label">
                            其他用户：
                        </div>
                        <div className="auth-tips-text">
                            {_.get(authInfo,'otherUser')}
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}

export default WorkFlowChangePop;

import React, { useState, useCallback, useMemo, useContext } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Dialog } from 'rootnet-ui'
import { Form, FormInput, RadioGroup, Select } from 'rootnet-edit'
import { ScheduleManagementContext } from '../../../common/Context'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'

const EXPORT_SCRIPT_URL = '/releaseinfo/export/script'
const FILE_ENCODING_URL = '/common/globalconst?globalConst=CHARSETS'

const scriptOpt = [
  { value: 'ORACLE', text: 'ORACLE' },
  { value: 'MYSQL', text: 'MYSQL' },
  { value: 'SQLSERVER', text: 'SQLSERVER' },
  { value: 'DM', text: 'DM' },
]

const scriptTypeOpt = [
  { value: 'CTS', text: 'CTS' },
  { value: 'other', text: '非CTS' },
]

export default function ExportScriptsLog(props) {
  const { close, tracerIds } = props
  const [params, setParams] = useState({ type: 'ORACLE', charsets: 'GB2312', scriptType: 'CTS' })
  const { data: charsetsRes } = useGet(FILE_ENCODING_URL)
  const { downloadPostProgress } = useContext(ScheduleManagementContext)

  const charsetsOpt = useMemo(() => {
    if (_.isEmpty(charsetsRes)) return []
    return convertGlobalConstOptions(charsetsRes)
  }, [charsetsRes])

  const confirm = useCallback(() => {
    const postParams = _.assign({}, params, { tracerIds, releaseIds: '' })
    downloadPostProgress(EXPORT_SCRIPT_URL, postParams, '研发任务脚本文件', close)
  }, [close, downloadPostProgress, params, tracerIds])

  return (
    <Dialog
      header='导出脚本'
      className='script-export-log'
      confirm={confirm}
      cancel={close}
    >
      <Form value={params} onChange={setParams}>
        <FormInput horizontal label='数据库' bind='type' component={RadioGroup} options={scriptOpt} />
        <div></div>
        <FormInput horizontal label='特殊脚本格式' bind='scriptType' component={RadioGroup} options={scriptTypeOpt} />
        <div></div>
        <FormInput horizontal label='文件编码' bind='charsets' component={Select} options={charsetsOpt} />
      </Form>
    </Dialog>
  )
}

import React from 'react';
import _ from 'lodash';
import Project from './../project/project';
import Leave from './../leave/leave';
import TrackingSheet from './../trackingSheet/TrackingSheet';
import Server from './../server/Server';
import Issue from './../issue/Issue';
import Version from './../version/Version';
import SeverAndSupport from './../serverAndSupport/ServerAndSupport';
import './rightMenu.scss';

function RightMenu(props) {
    const { sonData,childData } = props;
	return <div className="work-time-detail-right">
        { _.get(sonData, 'id') === 13 && <Leave {...props} />}
        { _.get(sonData, 'id') === 19 && <Server {...props} />}
        { _.get(sonData, 'id') === 25 && <Project {...props} />}
        { _.get(sonData, 'id') === 24 && <TrackingSheet {...props} />}
        { _.get(sonData, 'id') === 38 && <Issue {...props} />}
        { _.get(childData,'id') === 6 && <Version {...props} />}
        { _.get(childData,'id') === 7 && <Version {...props} />}
        { _.get(sonData, 'id') === 8 && <SeverAndSupport {...props} />}
        
	</div>
}

export default RightMenu

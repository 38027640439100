import React, { useState, useMemo } from 'react'
import { Tabs } from 'antd'
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/lib/hooks'
import { FormInput, Select } from 'rootnet-edit'
import { strParams } from '../../../../../utils/publicFun'
import ArrowCrumbs from '../../../../common/ArrowCrumbs'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import SalesLeads from './salesLeads'
import SystemListing from './systemListing'
import AssociatedProject from './associatedProject'
import CustomerReq from './customerReq'
import CustomerIssue from './customerIssue'
import CustomerServiceTicket from './customerServiceTicket'
import './index.scss'

export default function CustomerDetail(props) { //allUserRes
  const { currentId, setCurrentId, history, goBack, firstOrder } = props
  const [activeTab, setActiveTab] = useState(isNil(firstOrder) ? 'detail' : firstOrder)

  const { data: customerRes } = useGet('/UserSetting/getUniversalInterfaces?code=t_custId&codeName=t_custName&tableName=bug_tcust')

  const listOpt = useMemo(() => {
    if (_.isEmpty(customerRes)) return []
    return convertGlobalConstOptions(customerRes)
  }, [customerRes])

  const goBackList = useMemo(() => {
    return [
      { text: '客户信息管理' },
      {
        text: <FormInput search horizontal
          options={listOpt}
          component={Select}
          value={currentId}
          onChange={v => {
            setCurrentId(v)
            history.replace({ pathname: '/customerMgt', search: strParams({ initId: v, firstOrder: activeTab }) })
          }}
        />,
        active: true
      },
    ]
  }, [activeTab, currentId, history, listOpt, setCurrentId])

  const operations = useMemo(() => {
    return {
      left: <div>
        <ArrowCrumbs data={goBackList} goBack={goBack} />
      </div>
    }
  }, [goBack, goBackList])

  return (
    <div className='customer-detail'>
      <Tabs
        centered
        className='customer-detail-tabs'
        activeKey={activeTab}
        tabBarExtraContent={operations}
        onChange={v => {
          history.replace({ pathname: '/customerMgt', search: strParams({ initId: currentId, firstOrder: v }) })
          setActiveTab(v)
        }}
        items={[
          {
            label: '销售机会',
            key: 'salesLeads',
            children: <SalesLeads customerId={currentId} />,
          },
          {
            label: '系统清单',
            key: 'systemListing',
            children: <SystemListing customerId={currentId} />,
          },
          {
            label: '关联项目',
            key: 'associatedProject',
            children: <AssociatedProject customerId={currentId} />,
          },
          {
            label: '客户需求',
            key: 'customerReq',
            children: <CustomerReq customerId={currentId} />,
          },
          {
            label: '客户ISSUE',
            key: 'customerIssue',
            children: <CustomerIssue customerId={currentId} />,
          },
          {
            label: '客户服务单',
            key: 'customerServiceTicket',
            children: <CustomerServiceTicket customerId={currentId} />,
          },
        ]}
      >
      </Tabs>
    </div>
  )
}

import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/es/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Dialog, Button, Messager, MessageBox } from 'rootnet-ui'
import { RadioGroup, DatePicker } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import ImportResults from './importResults'
import api from '../../../../../base/task'
import './index.scss'

const { str8ToDate } = toDate

const accept = ".xlsx"
const IMPORT_URL = '/resourceInfo/uploadExcel'

const options = [
  { text: "新增", value: 'add' },
  { text: "更新", value: 'edit' },
]

export default function ResourcePoolImport(props) {
  const { header, close, showExport, template, refresh, forecastYear } = props
  const [open, setOpen] = useState(false)
  const [name, setName] = useState()
  const [message, setMessage] = useState(false)
  const [disable, setDisable] = useState(false)
  const [params, setParams] = useState({ actionType: 'add', forecastYear })
  const { data, doFetch } = usePost()

  const addFile = useCallback(() => {
    setDisable(true)
    const fileData = new FormData()
    _.forEach(params, (val, key) => fileData.append(key, val))
    doFetch(IMPORT_URL, fileData).then(() => {
      refresh()
      setDisable(false)
      setOpen(true)
      Messager.show(`执行成功`, { icon: 'success' })
    }).catch(err => {
      setDisable(false)
      setMessage(err._message)
    })
  }, [doFetch, params, refresh])

  return (
    <Dialog
      header={header}
      cancel={close}
      className='resource-pool-import'
      // loading={loading}
      confirm={_.debounce(addFile, 300)}
      confirmButtonDisabled={!name || disable}
      confirmButtonText='导入'
    >
      <DataPreparation {...{ template }} />
      <div>
        <p>② 选择预测年份</p>
        <div style={{ marginBottom: 14 }}>
          <DatePicker
            mode='year'
            value={str8ToDate(params['forecastYear'] + '0101')}
            onChange={(v) => setParams(x => _.assign({}, x, { forecastYear: dateFormat('YYYY', v) }))}
          />
        </div>
      </div>
      <div>
        <p>③ 选择操作动作</p>
        <div style={{ marginBottom: 14 }}>
          <RadioGroup
            options={options}
            value={params['actionType']}
            onChange={(v) => setParams(x => _.assign({}, x, { actionType: v }))}
          />
        </div>
      </div>
      <UploadData name={name} setName={setName} setParams={setParams} />

      {
        open && <ImportResults {...{ data, showExport, closeAll: close }} />
      }

      {
        message &&
        <MessageBox
          header='提示'
          cancelButtonVisible={false}
          confirm={() => setMessage(null)}
          className={'content-center-dialog'}>
          {message}
        </MessageBox>
      }
    </Dialog>
  )
}

ResourcePoolImport.defaultProps = {
  header: '工作量导入',
  close: () => { },
  parameter: {},
  showExport: true,
}

function DataPreparation(props) {//准备数据
  const { template } = props

  return (
    <div>
      <p>① 准备数据</p>
      <p>
        请导入当前部门所有模块按照月度预测的标准工作量（T3人日）
      </p>
      <p className='Download'>
        <span onClick={() => api.download(template)}><Icon name='daochu' /> <span>下载导入模板</span></span>
      </p>
    </div>
  )
}

function UploadData(props) {//上传数据
  const { name, setName, setParams } = props
  return (
    <div>
      <p>④ 上传数据文件</p>
      <p>目前支持的文件类型为{accept}。</p>

      <label htmlFor={'inputBtn'}>
        <Button cancel style={{ marginLeft: '18px' }}>添加文件</Button>
      </label>
      <input id={'inputBtn'} type="file"
        className="file-input" onChange={fileChange}
        accept={accept}
      />
      <span style={{ marginLeft: '10px' }}>{name}</span>
    </div>
  )

  function fileChange(e) {
    const file = _.get(e, 'target.files[0]')
    // const fileData = new FormData()
    // _.forEach(parameter, (val, key) => fileData.append(key, val))
    // fileData.append('file', file)
    const fileType = _.get(file, 'name').split('.').pop()
    if (fileType !== 'xlsx') return Messager.show('仅支持 .xlsx 格式的文件', { icon: 'error' })
    setName(_.get(file, 'name'))
    setParams(x => _.assign({}, x, { file }))
  }

}
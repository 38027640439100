//node_modules
import React, { useState, useMemo, useReducer, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Select, TextInput, FormInput, Button, DatePicker } from 'rootnet-ui';
import { DataChange, isNil } from 'rootnet-core/format'
//Local_modules
import { useGet } from '../../utils/hook';
import { strParams } from '../../utils/publicFun';
import Detail from './common/Details'
import Panel from './common/Panel'

//scss_style
import './TaskList.scss';

const urls = [
    "develop/project/list",
    "common/userinfo",
    "common/globalconst?globalConst=TaskStatus"
];

const url_list = 'develop/project/task/list'

function reducer(state, action) {
    const { name, value } = action
    if (name === 'project') {
        state.projectId = _.get(value, 'value')
    }
    if (name === 'testCompleteDate') {
        state.beginDate = DataChange(_.get(value, 'begin'))
        state.endDate = DataChange(_.get(value, 'end'))
    }
    return { ...state, [name]: value }
}
const intopt = {
    issueStateList: '0'
}
function Option(props) {
    const { data_opt: data, search } = props
    const project = _.get(data, '[0][0]')
    const [state, dispatch] = useReducer(reducer, intopt)
    const [options_manager, options_pricipal, options_status, projectId] = useMemo(() => {
        const [d1, d2, d3] = data || [null, null, null];
        const [ops_m, ops_p, ops_s] = [d1, d2, d3]
        const projectId = _.get(project, 'value')
        return [ops_m, ops_p, ops_s, projectId];
    }, [data, project]);


    useEffect(() => {
        if (isNil(projectId)) return
        const params = { projectId, project, ...intopt }
        search(params)
    }, [search, projectId, project])


    function query() {
        search(state)
    }

    const { issueStateList } = state

    return <div className="list-option">
        <FormInput label="项目" component={Select}
            search
            options={options_manager}
            onChange={(e, o) => { dispatch({ name: "project", value: o }) }}
            value={projectId}
        />
        <FormInput label="负责人" component={Select}
            search
            options={options_pricipal}
            onChange={(e, o) => { dispatch({ name: "principal", value: _.get(o, 'value') }) }}
        />
        <FormInput label="研发任务ID" component={TextInput} placeholder='请输入'
            onChange={(e, o) => { dispatch({ name: "tracerId", value: e }) }}
        />
        <FormInput label="截止时间" component={DatePicker} range
            onChange={e => { dispatch({ name: "testCompleteDate", value: e }) }}
        />
        <FormInput label="状态" component={Select}
            options={options_status}
            onChange={(e, o) => { dispatch({ name: "issueStateList", value: _.get(o, 'value') }) }}
            value={issueStateList}
        />
        <Button primary onClick={query}>查询</Button>
    </div>
}

function TaskList(props) {
    const { match: { path } } = props
    const [param, setParam] = useState({ issueStateLis: 0 });
    const { data: dataOpt } = useGet(urls);
    const { data, doFetch, loading, error } = useGet()

    const data_opt = useMemo(() => {
        const [d1, d2, d3] = dataOpt || [null, null, null];
        const item = { text: "全部", value: "" };
        const ops_m = [..._.map(d1, o => { return { text: o.projectName, value: o.projectId, value2: _.pick(o, ['custId', 'productId', 'projectSort']) } })];
        const ops_p = [item, ..._.map(d2, o => { return { text: ''.concat(o.userAccount, '-', o.userName), value: o.userAccount } })];
        const ops_s = [item, ..._.map(d3, o => { return { text: o.displayName, value: o.interiorId } })];
        return [ops_m, ops_p, ops_s]
    }, [dataOpt])

    const data_panel = useMemo(() => {
        const ops_p = _.drop(_.get(data_opt, [1], null))
        const ops_s = _.drop(_.get(data_opt, [2], null))
        return [ops_p, ops_s]
    }, [data_opt])

    const search = useCallback((arg) => {
        console.log('arg', arg)



        setParam(arg);
        const optParams = ['projectId', 'id', 'principal', 'beginDate', 'endDate', 'tracerId', 'issueStateList']
        const params = _.pick(arg, optParams)
        doFetch(`${url_list}?${strParams(params)}`)
    }, [doFetch]);

    return <div className="v-task-list">
        <Option search={search} data_opt={data_opt} />
        <Panel param={param} data={data} loading={loading} error={error} data_panel={data_panel} {...props} path={path} />
    </div>
}

function TaskDetails(props) {
    return <Detail {...props} />
}

export {
    TaskDetails,
    TaskList
}


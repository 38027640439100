import React, { useEffect, useMemo, useState, useContext } from 'react';
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { Box } from "../../common/commonComponent";
import { DataGrid, Button, Messager, Pagination } from 'rootnet-ui'
import { useGet, usePost } from "../../../utils/hook";
import _ from "lodash";
import cls from "clsx";
import { Dateformat } from "rootnet-core/format";
import './WorkTimeQuery.scss'
import { DatePicker, Form, FormInput, Select } from "rootnet-edit";
import { hasData } from "rootnet-core/system";
import { ScheduleManagementContext } from '../../common/Context'
import { TextIconBtn } from '../../common/TextIconBtn';
import gd from "../../../base/global";
import { strParams } from '../../../utils/publicFun';
// import api from '../../../base/task';
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";

const { str8ToDate, dateStringToDate } = toDate

function weekFirstEnd() {
    const zeroTime = new Date(new Date().toLocaleDateString()).getTime();
    const start = new Date(zeroTime);
    const end = new Date(zeroTime + 24 * 60 * 60 * 1000 - 1);
    const firstDay = new Date(start - (new Date().getDay() - 1) * 1000 * 60 * 60 * 24)
    const endDay = new Date(end - (new Date().getDay() - 7) * 1000 * 60 * 60 * 24)
    return {
        beginDate: dateFormat('YYYY-MM-DD HH:MM:SS', firstDay),
        endDate: dateFormat('YYYY-MM-DD HH:MM:SS', endDay),
    }
}

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20,
        ...weekFirstEnd(),
        userAccount: _.get(gd, '_user.operator_id')
    }
}

const getColumns = (props) => {
    const { stateOptions } = props
    return {
        resizable: true,
        virtualized: false,
        fixedLeft: 2,
        columns: [
            { header: '部门', bind: 'departmentName', align: 'left', width: 140, tooltip: true, sortable: true },
            { header: '用户', bind: 'userName', align: 'left', width: 140, tooltip: true, sortable: true },
            { header: '工作日期', bind: 'workDate', align: 'left', width: 140, tooltip: true, sortable: true, convert: v => dateFormat('YYYY-MM-DD', str8ToDate(v)) },
            { header: '工作类型', bind: 'jobTypeName', align: 'left', width: 140, tooltip: true, sortable: true },
            {
                header: '用时(h)',
                bind: 'auditUseTime',
                align: 'right',
                width: 70, sortable: true,
                convert: (v) => v ? parseFloat(_.toNumber((v / 60).toFixed(1))) : '',
            },
            {
                header: '状态',
                bind: 'status',
                align: 'center', sortable: true,
                convert: v => <span className={cls('colorSpan', v)}>{convertOptions(v, stateOptions)}</span>,
                width: 80
            },
            { header: '工作内容', bind: 'content', align: 'left', width: 145, tooltip: true, sortable: true },
            { header: '详情', bind: 'remark', align: 'left', width: 145, tooltip: true, sortable: true },
            { header: 'UT标识', bind: 'utFlag', align: 'center', width: 70, sortable: true },
            { header: '客户系统', bind: 'custProjectName', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: '项目类型', bind: 'projectType', align: 'left', width: 100, tooltip: true, sortable: true },
            { header: '项目名称', bind: 'projectName', align: 'left', width: 155, tooltip: true, sortable: true },
            { header: '任务', bind: 'taskName', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: '研发任务', bind: 'tracerId', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: '服务单', bind: 'csRecordId', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: 'ISSUE', bind: 'issueId', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: '版本', bind: 'releaseId', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: '确认人', bind: 'auditPersonName', align: 'left', width: 125, tooltip: true, sortable: true },
            { header: '确认时间', bind: 'auditTime', align: 'left', convert: v => Dateformat(v), width: 125, tooltip: true, sortable: true }

        ]
    }
}

function WorkTimeQuery(props) {
    const { downloadGetProgress } = useContext(ScheduleManagementContext)
    const [params, setParams] = useState(getInitParams())
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { data: stateRes } = useGet('/common/globalconst?globalConst=WorkTimeStatus')

    const stateOptions = useMemo(() => {
        return convertGlobalConstOptions(stateRes)
    }, [stateRes])

    useEffect(() => {
        const url = `/worktime/alllist`;
        getList(url, params)
    }, [params, getList])

    const { pageSize, total, pageNum, rows: list } = useMemo(() => listRes || {}, [listRes]);

    const option = useMemo(() => getColumns({ stateOptions }), [stateOptions])

    return <div className='fill flex-y work-time-query'>
        <Options initParams={params} search={setParams} />
        <Box
            title='工时查询'
            className='flex-y x-card-singlegrid'
            data={list}
            loading={loading}
            error={error}
            extra={<TextIconBtn icon='daochu' text='导出' onClick={_.debounce(downLoad, 300)} />}
        >
            <DataGrid data={list} option={option} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
    </div>

    function downLoad() {
        if (_.size(list) > 0) {
            const arr = []
            _.forEach(params, (v, s) => { if (_.isEmpty(v)) arr.push(s) })
            if (_.size(list)) {
                downloadGetProgress(`/worktime/export?${strParams(_.omit(params, arr))}`, '时间工具导出记录')
            }
        } else {
            Messager.show('当前工时查询记录为空，请重新选择查询条件', { icon: 'error' })
        }
    }
}

const OptionsUrl = [
    '/common/department?activeFlag=Y', //部门
    '/common/userinfo?indexValidFlag=1', //用户 indexValidFlag = 1
    '/worktime/jobtype/list',//工作类型 显示 name
    '/common/globalconst?globalConst=WorkTimeStatus', //状态 globalconst = 'WorkTimeStatus'
    '/common/globalconst?globalConst=projectType', //项目类型  GlobalConst='projectType'
    // '/common/system', //客户
    '/customer/customerList',//客户
]

const RFormInput = (props) => <FormInput componentWidth={160}  {...props} />

function buildTree(allList, list, keyName, parentName) {
    return _.map(list, listItem => {
        const childList = _.filter(allList, allItem => _.get(allItem, parentName) === _.get(listItem, keyName))
        if (childList.length === 0) return convertDepartment(listItem)
        return _.assign(convertDepartment(listItem), { children: buildTree(allList, childList, keyName, parentName) })
    })
}

function convertDepartment(item) {
    return { text: item.departMentName, value: item.departMentId }
}

function Options(props) {
    const { initParams, search } = props
    const [params, setParams] = useState(initParams)
    const { data: optionRes } = useGet(OptionsUrl)
    const [isExpand, setIsExpand] = useState(false)

    const [departmentOptions, userAccountOptions, jobTypeOptions, statusListOptions, projectTypeOptions, devClientOpt] = useMemo(() => {
        if (_.isNil(optionRes)) return []
        const [d1, d2, d3, d4, d5, d6] = optionRes

        const firstLevelDepList = _.filter(d1, x => _.get(x, 'parentDept') === "")
        const departmentOptions = buildTree(d1, firstLevelDepList, "departMentId", "parentDept")
        const userAccountOptions = _.map(d2, x => (_.assign({}, { value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}` })))
        const jobTypeOptions = _.map(d3, x => treeDate(x, 'id', 'name'));
        const statusListOptions = _.map(d4, x => treeDate(x, 'interiorId', 'displayName'));
        const projectTypeOptions = _.map(_.filter(d5, x => x.interiorId !== 'SALES'), x => treeDate(x, 'interiorId', 'displayName'));
        const devClientOpt = _.map(d6, o => ({ text: o.tCustname, value: o.tCustid, tag: `${o.tCustname}${o.tCustid}` }))
        return [departmentOptions, userAccountOptions, jobTypeOptions, statusListOptions, projectTypeOptions, devClientOpt]

        function treeDate(x, id, text) {
            if (hasData(_.get(x, 'children'))) {
                return {
                    text: `${_.get(x, text)}`,
                    value: `${_.get(x, id)}`,
                    children: x.children.map(v => treeDate(v, id, text))
                }
            }
            return { text: `${_.get(x, text)}`, value: `${_.get(x, id)}` }
        }
    }, [optionRes])

    return <div className='work-time-query-options flex' style={{ height: isExpand ? 144 : 72 }}>
        <div className="form-group">
            <Form onChange={handleChange} value={params}>
                <RFormInput label="部门" search multiple tree clear component={Select} options={departmentOptions}
                    bind="departmentList" />
                <RFormInput label="用户" search clear component={Select} options={userAccountOptions} bind="userAccount" />
                <RFormInput label="工作类型" search tree clear component={Select} multiple options={jobTypeOptions}
                    bind="jobTypeList" />
                <RFormInput label="开始时间" component={DatePicker} bind="beginDate" bindInConvert={dateStringToDate}
                    bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS', v)} />
                <RFormInput label="结束时间" component={DatePicker} bind="endDate" bindInConvert={dateStringToDate}
                    bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS', getDayEndTime(v))} />
                <RFormInput label="状态" multiple clear component={Select} options={statusListOptions} bind="statusList" />
                <RFormInput label="客户" search component={Select} clear options={devClientOpt} bind="custProjectId" />
                <RFormInput label="项目类型" multiple clear component={Select} options={projectTypeOptions}
                    bind="projectType" />
                <RFormInput label="项目名称" bind="projectName" componentWidth={336} />
            </Form>
        </div>
        <div className='btn-group flex'>
            <div className='fold-text' onClick={() => setIsExpand(x => !x)}>{isExpand ? '收起' : '展开'}</div>

            <Button primary className='work-time-query-button' onClick={onSearch}>查询</Button>
        </div>

    </div>

    function handleChange(options, key) {
        if (key === 'beginDate' && options[key] > options['endDate']) return Messager.show('开始时间不能大于结束时间', { icon: 'error' })
        if (key === 'endDate' && options[key] < options['beginDate']) return Messager.show('结束时间不能小于开始时间', { icon: 'error' })
        setParams(options)
    }

    function getDayEndTime(v) {
        return new Date(new Date(v.toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
    }

    function onSearch() {
        let jobTypeList = _.get(params, 'jobTypeList')
        jobTypeList = jobTypeList ? _.map(jobTypeList, x => _.toNumber(x)) : []
        search(_.assign({}, params, { jobTypeList: jobTypeList }))
    }
}

export default WorkTimeQuery;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DataGrid, Messager, Switch } from 'rootnet-ui'
import './DevelopProjectDialog.scss'
import { Form, FormInput, Input, TextArea, Select } from "rootnet-edit";
import { useApi, useGet } from "../../../../utils/hook";
import _ from "lodash";
import './RadioDialog.scss'
import Icon from "../../../../project_share/components/Icon";
import WaterMarkDialog from "../../../common/WaterMarkDialog";
import gd from "../../../../base/global";

const getTableOption = (props) => {
    const { projectType } = props
    return {
        autoFill: true,
        columns: [
            { selection: true },
            {
                header: '项目',
                bind: 'projectName',
                width: 240,
                tooltip: true
            },
            {
                header: '任务',
                bind: 'taskName',
                width: 240,
                tooltip: true
            }
        ].concat(
            projectType === 'DEV' ? [] : [
                {
                    header: '销售负责人',
                    bind: 'sales',
                    width: 90,
                    tooltip: true
                },
            ],
            [
                {
                    header: '项目经理',
                    bind: 'newProjectManager',
                    width: 90,
                    tooltip: true
                },
                {
                    header: projectType === 'DEV' ? '系统' : '客户',
                    bind: 'customer',
                    width: 120,
                    tooltip: true
                },
                {
                    header: '授权用时(h)',
                    bind: 'planTime',
                    align: 'right',
                    convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : '',
                    width: 100
                },
                {
                    header: '累计投入(h)',
                    bind: 'sumUseTime',
                    align: 'right',
                    convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : '',
                    width: 100
                },
            ]
        )
    }
}

const INIT_FORM_DATA = {
    auditUseTime: null,
}

function DevelopProjectDialog(props) {
    const { close, date, jobType, refresh, usedTime, cardDetail, id, beforeCutoffDay, isSelf, searchUser, showCutoffDay } = props
    const isAdd = useMemo(() => _.isNil(cardDetail), [cardDetail])
    const initFormData = useMemo(() => isAdd ? INIT_FORM_DATA : { auditUseTime: cardDetail.auditUseTime / 60 }, [isAdd, cardDetail])
    const initRemark = useMemo(() => isAdd ? '' : cardDetail.remark, [cardDetail, isAdd])
    const dateNoLine = useMemo(() => date.replace(/-/g, ''), [date])
    const [formData, setFormData] = useState(initFormData)
    const { data: list, doFetch: getList } = useGet()
    const [error, setError] = useState(null)
    const [selectedList, setSelectList] = useState([])
    const { doFetch } = useApi()
    const [remark, setRemark] = useState(initRemark)
    const [projectType, setProjectType] = useState()
    const [showContinueDialog, setShowContinueDialog] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [showCanSelect, setShowCanSelect] = useState(isSelf)
    const [countTime, setCountTime] = useState(0)
    const { data: reqOptionsRes, doFetch: getReqOptionsRes } = useGet()
    const [reqParams, setReqParams] = useState(_.get(cardDetail, 'interiorReqNo'))

    const reqOptions = useMemo(() => {
        if (_.isEmpty(reqOptionsRes)) return []
        const options = _.map(reqOptionsRes, x => ({
            value: x.interiorReqNo,
            // text: <div>
            //     {
            //         `${x.interiorReqNo}-${x.title}`.length > 32 &&
            //         <Tooltip title={`${x.interiorReqNo}-${x.title}`}>
            //             <div className={'req-select-item'}>{}</div>
            //         </Tooltip>
            //     }
            //     {
            //         `${x.interiorReqNo}-${x.title}`.length <= 32 &&
            //         <div className={'req-select-item'}></div>
            //     }
            // </div>,
            text: `${x.interiorReqNo}-${x.title}`,
            projectID: x.projectID,
        }))
        return _.uniqBy(options, x => x.value)
    }, [reqOptionsRes])

    useEffect(() => {
        if (projectType === 'DEV') {
            getReqOptionsRes('/worktime/interiorReqNoList/query?userAccount=' + gd.User.operator_id)
        }
    }, [getReqOptionsRes, projectType])

    useEffect(() => {
        if (_.some([jobType, dateNoLine], _.isNil)) return
        let projectType
        if (isAdd) {
            projectType = id === 15 ? 'DEV' : 'PRESALES'
        } else {
            projectType = cardDetail.jobTypePid === 15 ? 'DEV' : 'PRESALES'
        }
        setProjectType(projectType)
        const url = `/worktime/project/detail?jobTypePid=25&projectType=${projectType}&jobType=${jobType}&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}${_.isNil(reqParams) ? '' : '&interiorReqNo=' + reqParams}`
        getList(url)
    }, [getList, jobType, dateNoLine, cardDetail, id, isAdd, searchUser, reqParams])

    useEffect(() => {
        let auditUseTime = _.toNumber(_.get(formData, 'auditUseTime'))
        if (auditUseTime <= 0) {
            setError(x => _.assign({}, x, { 'auditUseTime': '实际用时需大于0' }))
            return
        }
        if (!isAdd) {
            auditUseTime = auditUseTime - cardDetail.auditUseTime / 60
        }
        if (!_.isNil(auditUseTime) && ((auditUseTime * 60 + usedTime + countTime * 60) > 8 * 60)) {
            setError(x => _.assign({}, x, { 'auditUseTime': '每天用时不能大于8小时' }))
            return
        }
        if (selectedList.length !== 0 && !_.isNil(auditUseTime) && !_.isNil(_.get(selectedList, '0.planTime'))) {
            const planTime = _.toNumber(selectedList[0].planTime)
            const sumUseTime = _.toNumber(selectedList[0].sumUseTime)
            if (planTime && ((sumUseTime + auditUseTime * 60) > planTime)) {
                setError(x => _.assign({}, x,
                    {
                        'auditUseTime': `累计投入【${(sumUseTime / 60 + auditUseTime).toFixed(1)}小时】不能超过${projectType === 'DEV' ? '计划用时' : '授权用时'}【${(planTime / 60).toFixed(1)}小时】，
                    请联系${projectType === 'DEV' ? '项目经理' : '任务负责人'}${selectedList[0].newProjectManager ? selectedList[0].newProjectManager : ''}进行授权`
                    }))
                return
            }
        }
        setError(x => _.assign({}, x, { 'auditUseTime': null }))
    }, [formData, usedTime, selectedList, cardDetail, isAdd, countTime, projectType])

    const isSubString = useCallback((arr, subString) => {
        let hasSubString = false
        _.forEach(arr, item => {
            if (_.includes(_.toLower(item), _.toLower(subString))) hasSubString = true
        })
        return hasSubString
    }, [])

    const filterList = useMemo(() => {
        if (_.isNil(list)) return []
        let uniqList = _.uniqBy(list, x => x['taskId'])
        if (!isAdd) {
            const taskId = _.get(_.find(list, x => x.id === cardDetail.id), 'taskId')
            const initSelect = _.filter(uniqList, x => x.taskId === taskId)
            if (initSelect.length === 0) {
                uniqList = [cardDetail]
            } else {
                const cardIndex = _.findIndex(uniqList, x => x.id === cardDetail.id)
                uniqList.unshift(uniqList.splice(cardIndex, 1)[0])
                if (!isSelf) uniqList = initSelect
            }
        }


        if (!_.isNil(reqParams)) {
            const filterProjectID = _.get(_.find(reqOptionsRes, x => x.interiorReqNo === reqParams), 'projectID')
            uniqList = _.filter(uniqList, x => _.toString(x.projectId) === filterProjectID)
        }

        if (showCanSelect) {
            if (isAdd) {
                uniqList = _.filter(uniqList, x => _.toNumber(x.planTime) > _.toNumber(x.sumUseTime))
            } else {
                const taskId = _.get(_.find(list, x => x.id === cardDetail.id), 'taskId')
                uniqList = _.filter(uniqList, x => (x.taskId === taskId || _.toNumber(x.planTime) > _.toNumber(x.sumUseTime)))
            }
        }
        if (_.isNull(searchText)) return uniqList
        return _.filter(uniqList, item => isSubString(_.values(_.pick(item, 'projectName', 'taskName')), _.trim(searchText)))
    }, [list, isSubString, searchText, isAdd, cardDetail, showCanSelect, isSelf, reqParams, reqOptionsRes])

    useEffect(() => {
        if (_.isEmpty(filterList)) return
        if (_.isNil(selectedList)) return
        if (selectedList.length !== 0 && _.isNil(_.find(filterList, x => x === selectedList[0]))) {
            setSelectList(old => {
                const findItem = _.find(filterList, x => _.get(x, 'taskId') === _.get(old[0], 'taskId'))
                return _.isNil(findItem) ? [] : [findItem]
            })
        }
    }, [filterList, selectedList])

    const filterReqOptions = useMemo(() => {
        if (_.isEmpty(selectedList)) return reqOptions
        return _.filter(reqOptions, x => x.projectID === _.toString(_.get(_.head(selectedList), 'projectId')))
    }, [reqOptions, selectedList])

    useEffect(() => {
        if (isAdd || _.isNil(list)) return
        const taskId = _.get(_.find(list, x => x.id === cardDetail.id), 'taskId')
        const initSelect = _.filter(filterList, x => x.taskId === taskId)
        setSelectList(initSelect)
    }, [filterList, cardDetail, list, isAdd, close])

    const option = useMemo(() => {
        return getTableOption({ projectType });
    }, [projectType]);

    return <div>
        <WaterMarkDialog cancel={close} header={`${projectType === 'DEV' ? '研发' : '售前'}（${date}）`} className='develop-project-dialog radio-dialog'
            confirm={submit} confirmButtonVisible={isSelf}>
            <div className="options">
                <div className="form-group">
                    <Form error={error} onError={setError} value={formData} onChange={handleChangeFormData}>
                        <FormInput label='实际用时(h)' bind='auditUseTime' horizontal componentWidth={120} digit={1} labelWidth={100} type='number' required min={0.1} />
                    </Form>
                    {
                        projectType === 'DEV' &&
                        <FormInput label='需求' value={reqParams} onChange={setReqParams} horizontal componentWidth={250} labelWidth={50} component={Select} options={filterReqOptions} clear search />
                    }
                </div>
                <div className="options-right flex center-y">
                    <Switch className='option-switch' checked={showCanSelect} onChange={setShowCanSelect} checkedComponent='可填' uncheckedComponent='全部' />
                    <Input value={searchText} onChange={setSearchText} placeholder='请搜索' prefix={<Icon name='sousuo1' className='search-icon' />} />
                </div>
            </div>
            <div className='data-grid-wrap x-datagrid'>
                <DataGrid data={filterList} option={option} onSelectionChange={singleChoice} selection={selectedList} />
            </div>
            <TextArea value={remark} onChange={setRemark} placeholder='请输入工作内容说明...' className='remark-input' maxLength={100000} counter={false} />
        </WaterMarkDialog>
        {
            showContinueDialog &&
            <Dialog headerVisible={false} confirm={clear} cancel={exit} className={'content-center-dialog'}>
                增加成功，是否继续增加？
            </Dialog>
        }
    </div>

    function handleChangeFormData(formObj, bind) {
        if (bind === 'auditUseTime' && _.toNumber(_.get(formObj, 'auditUseTime')) > 8) {
            return Messager.show('实际用时上限为8h')
        }
        setFormData(formObj)
    }

    function clear() {
        const auditUseTime = _.toNumber(formData.auditUseTime)
        setCountTime(x => x + auditUseTime)
        setShowContinueDialog(false)
        setFormData(INIT_FORM_DATA)
        setSelectList([])
        setRemark('')
        const url = `/worktime/project/detail?jobTypePid=25&projectType=${projectType}&jobType=${jobType}&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`
        getList(url)
    }

    function exit() {
        setShowContinueDialog(false)
        close()
    }

    function singleChoice(arr) {
        if (selectedList.length === 0) {
            setSelectList(arr)
        } else {
            const filterItem = _.find(arr, item => item.taskId !== selectedList[0].taskId)
            setSelectList(_.isNil(filterItem) ? [] : [filterItem])
        }
    }

    function check() {
        if (_.some(_.values(error), x => x)) {
            const errMsg = _.find(_.values(error), x => !_.isNil(x))
            Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
            return false
        }
        const auditUseTime = _.toNumber(formData.auditUseTime)
        if (auditUseTime < 0 || auditUseTime > 8) {
            Messager.show('实际用时需在0-8h之间', { icon: 'error' })
            return false
        }
        if (selectedList.length === 0) {
            Messager.show('请选择项目', { icon: 'error' })
            return false
        }
        return true
    }

    function submit() {
        if (beforeCutoffDay) return Messager.show(`${showCutoffDay}前的成本已核算，不允许修改工时`, { icon: 'error' })
        if (!check()) return
        const selectedItem = selectedList[0]
        const reqId = _.get(_.find(reqOptionsRes, x => x.interiorReqNo === reqParams), 'reqId')
        const params = {
            jobType: selectedItem.jobType,
            workDate: dateNoLine,
            content: projectType === 'DEV' ? '开发项目相关' : '售前工作',
            remark: remark,
            auditUseTime: formData.auditUseTime * 60,
            reqId: reqId,
            status: isAdd ? 'approve' : null,
            custProjectId: selectedItem.custProjectId,
            projectType: projectType,
            projectId: selectedItem.projectId,
            taskId: selectedItem.taskId,
            taskName: selectedItem.taskName,
            actionType: "ADD",
        }
        const postParams = isAdd ? [params] : [
            _.assign({}, params, {
                id: cardDetail.id,
                actionType: "EDIT"
            })
        ]
        doFetch('/worktime/edit', 'post', postParams).then(() => {
            Messager.show(`添加成功`, { icon: 'success' })
            refresh()
            if (isAdd) {
                setShowContinueDialog(true)
                return
            }
            close()
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
        })
    }
}

export default DevelopProjectDialog;
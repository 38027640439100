import _ from 'lodash'
import React, { useCallback, useEffect, useState, useReducer } from 'react'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Transfer, Messager, Button } from 'rootnet-ui'
import { strParams } from '../../../../utils/publicFun'
import './index.scss'
import {FormInput, Select} from "rootnet-edit";

const GET_ALL_ROLE_URL = '/uac/role/get/all/role'
const GET_DEFAULT_DATA_URL = '/uac/role/select/role'
const EDIT_URL = '/uac/role/set/role'

export default function AssigningRole(props) {
  const { close, type, id, funcCode, refresh } = props
  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])
  const [isDisable, setIsDisable] = useState(false)
  const [count, forceUpdate] = useReducer((x) => x + 1, 0)
  const { data, loading } = useGet(GET_ALL_ROLE_URL)
  const { data: defaultTarget, doFetch: getDetail } = useGet()
  const { doFetch } = usePost()
  const [defaultRole, setDefaultRole] = useState()
  const {data: initDefaultRole, doFetch: getDefaultRole} = useGet()

  useEffect(()=>{
    console.log('defaultRole',defaultRole)
  },[defaultRole])

  useEffect(()=>{
    const valueList = _.map(target,'value')
    if(!_.isNil(defaultRole) && !_.includes(valueList, defaultRole)){
      setDefaultRole(null)
    }
  },[target, defaultRole])

  const handChange = useCallback((target, source) => {
    setTarget(target)
    setSource(source)
  }, [])

  useEffect(() => {
    if (_.isEmpty(data)) return
    const filterList = _.filter(data, o => _.toNumber(o.status) === 0)
    const initData = _.map(filterList, o => ({ text: o.roleName, tag: `${_.toLower(o.roleID)}${_.toUpper(o.roleID)}${o.roleID}`, value: o.roleID }))
    if (_.isEmpty(defaultTarget)) {
      setSource(initData)
    } else {
      const targetKeys = _.map(defaultTarget, o => o.roleId)
      const filterData = _.filter(initData, o => !_.includes(targetKeys, o.value))
      setSource(filterData)
    }
    forceUpdate()
  }, [data, defaultTarget])

  useEffect(() => {
    if (_.isEmpty(data)) return
    if (((_.isArray(id) && _.size(id) === 1) || !_.isArray(id))) {
      const optId = _.isArray(id) ? _.head(id) : id
      getDetail(`${GET_DEFAULT_DATA_URL}?${strParams({ type, optId })}`)
        .then(res => {
          const keys = _.map(res, o => o.roleId)
          const filterData = _.filter(data, o => _.includes(keys, o.roleID))
          setTarget(_.map(filterData, o => ({ text: o.roleName, tag: `${_.toLower(o.roleID)}${_.toUpper(o.roleID)}${o.roleID}`, value: o.roleID })))
          forceUpdate()
          getDefaultRole('uac/role/select/defaultRole?'+strParams({userId:optId})).then(res => {
            setDefaultRole(res)
          })
        })
    }
  }, [id, type, data, getDetail, getDefaultRole])

  const confirm = useCallback(() => {
    const defaultKeys = _.map(defaultTarget, o => o.roleId)
    const keys = _.map(target, o => o.value)
    if (_.size(defaultKeys) === _.size(keys)) {
      if (_.every(keys, v => _.includes(defaultKeys, v))) {
        if(initDefaultRole === defaultRole){
          return Messager.show('无变更')
        }
      }
    }
    if (isDisable) return
    setIsDisable(true)
    const postParams = {
      type,
      funcCode,
      optIdList: _.isArray(id) ? id : [id],
      roleIdList: keys,
      defaultRole,
    }
    doFetch(EDIT_URL, postParams)
      .then(() => {
        refresh()
        setIsDisable(false)
        Messager.show('分配成功', { icon: 'success' })
        close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })

  }, [doFetch, close, target, type, funcCode, defaultTarget, refresh, id, isDisable, defaultRole, initDefaultRole])

  return (
    <Dialog
      header='分配角色'
      confirm={confirm}
      cancel={close}
      loading={loading}
      confirmButtonText='保存'
      footerVisible={false}
      className='assigning-role-dialog'
    >
      <div className='flex flex-y' style={{ flex: 1, overflow: 'hidden' }} >
        <div className='role-content flex-y' key={count} style={{ flex: 1, overflow: 'hidden' }}>
          <div className={'flex space-between'}>
            <div className='prompt-text'>重要提示：批量分配会清空用户原有角色</div>
            <div style={{ textAlign: 'right', fontSize: 12, color: '#7A8199' }}>已选角色</div>
          </div>
          <Transfer
            source={source}
            target={target}
            onChange={handChange}
          />
          <FormInput component={Select} label='默认角色' options={target} horizontal componentWidth={210}
                     value={defaultRole} onChange={setDefaultRole} clear search/>
        </div>
        <div className='role-footer flex'>
          <div className='footer-lef'></div>
          <div className='footer-rig btn-group flex'>
            <Button normal onClick={close}>取消</Button>
            <Button primary onClick={confirm} >保存</Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tabs, Badge } from 'antd'
import { Dialog, DataGrid, Messager } from 'rootnet-ui'
import { FormInput, TextArea } from 'rootnet-edit'
import { getColumnsUntreated, getColumnsOther } from './getColumns'
import { Icon } from '../../../../../../../components'
import { usePost } from 'rootnet-biz/lib/hooks'
import './index.scss'
import RequirementDetailDialog from '../../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import IssueDetailDialog from '../../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'

const { TabPane } = Tabs

const getOption = (columns) => ({
  columns,
  autoFill: true,
  resizable: true,
  virtualized: false,
  fixedLeft: 2,
  nilText: '-',
  emptyText: '-',
});

export default function JoinApply(props) {
  const { close, refresh, allUserRes, data, refreshHome } = props
  const [activeTab, setActiveTab] = useState('untreated')
  const [open, setOpen] = useState(false)
  const [applyId, setApplyId] = useState(null)
  const [selectData, setSelectData] = useState([])
  const [showDetail, setShowDetail] = useState()

  const optionUntreated = useMemo(() => {
    return getOption(getColumnsUntreated({ setOpen, setApplyId, allUserRes, setShowDetail }))
  }, [allUserRes])

  const optionOther = useMemo(() => {
    return getOption(getColumnsOther({ allUserRes, setShowDetail }))
  }, [allUserRes])

  const getId = useCallback(() => {
    setApplyId(_.map(selectData, o => o.id))
  }, [selectData])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <>
      <Dialog
        header='版本变动申请'
        cancel={close}
        style={{ width: 980, height: 500 }}
        className='req-join-apply'
        confirmButtonVisible={false}
      >
        {/* <Badge count={1} size="small" offset={[8, 0]}> */}
        <Tabs activeKey={activeTab} onChange={setActiveTab}  >
          <TabPane tab={<Badge count={_.size(_.filter(data, o => o.auditFlag === 'U'))} size="small" offset={[8, 0]}>未处理</Badge>} key="untreated">
            <div className='untreated-tables'>
              {
                _.size(selectData) > 0 && <div className='select-operation flex'>
                  <div>已选择{_.size(selectData)}项</div>
                  <div className='flex' style={{ marginLeft: 36 }}>
                    <div className='flag-agree' onClick={() => { setOpen('agree'); getId() }}>同意<Icon name='chenggong' /></div>
                    <div className='flag-reject' onClick={() => { setOpen('reject'); getId() }}>拒绝<Icon name='shibai' /></div>
                  </div>
                </div>
              }
              <DataGrid data={_.filter(data, o => o.auditFlag === 'U')} option={{ ...optionUntreated, fixedRight: 1 }} onSelectionChange={setSelectData} selection={selectData} />
            </div>
          </TabPane>
          <TabPane tab={<Badge count={_.size(_.filter(data, o => o.auditFlag === 'N'))} size="small" offset={[8, 0]}>已拒绝</Badge>} key="denied">
            <DataGrid data={_.filter(data, o => o.auditFlag === 'N')} option={optionOther} />
          </TabPane>
          <TabPane tab={<Badge count={_.size(_.filter(data, o => o.auditFlag === 'Y'))} size="small" offset={[8, 0]}>已同意</Badge>} key="agree">
            <DataGrid data={_.filter(data, o => o.auditFlag === 'Y')} option={optionOther} />
          </TabPane>
        </Tabs>
      </Dialog>
      {open !== false && <SelectLog setCheck={setOpen} check={open} close={() => setOpen(false)} {...{ refresh, applyId, refreshHome, setSelectData }} />}
      {
        _.get(showDetail, 'type') === 'REQ' &&
        <RequirementDetailDialog
          close={() => setShowDetail(null)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
        />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
        />
      }
    </>
  )
}

function SelectLog(props) { // auditFlag 动作  auditMemo
  const { close, applyId, check, setCheck, refresh, refreshHome, setSelectData } = props
  const [value, setValue] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()
  const confirm = useCallback(() => {
    if (_.size(value) <= 0) return Messager.show('请填写说明', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    const auditFlag = check === 'agree' ? 'Y' : 'N'
    doFetch('/ReleaseStoryLog/update', { idList: applyId, auditFlag, auditMemo: value })
      .then(() => {
        setSelectData([])
        refreshHome()
        refresh()
        close()
        setIsDisable(false)
        Messager.show('申请成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [applyId, check, close, doFetch, refresh, refreshHome, value, isDisable, setSelectData])
  return < Dialog
    header='操作说明'
    cancel={close}
    confirm={confirm}
    className='req-select-log'
    confirmButtonText='保存'
  >
    <div className='select-log flex'>
      <div className={cls('agree', { checked: check === 'agree' })} onClick={() => setCheck('agree')}>同意<Icon name='chenggong' /></div>
      <div className={cls('reject', { checked: check === 'reject' })} onClick={() => setCheck('reject')}>拒绝<Icon name='shibai' /></div>
    </div>
    <FormInput
      required
      horizontal
      label='说明'
      value={value}
      onChange={setValue}
      component={TextArea}
      labelWidth={53}
      componentWidth={360}
      maxLength={200}
      className={cls('reason', { error: _.size(value) <= 0 })}
    />
  </ Dialog >
}

import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Input } from 'antd'
import { Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { Icon } from '../../../../../components'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import AssociatedTrackingList from './components/AssociatedTrackingList'
import AssociatedTrackingLog from './components/AssociatedTrackingLog'
import useGet from 'rootnet-biz/es/hooks/useGet'
import { useApi } from '../../../../../utils/hook'
import useCommon from '../../../../common/hooks/useCommon'
import { strParams } from '../../../../../utils/publicFun'
import './index.scss'
import TrackingAddDialog from "../../../../trackingManagementMgt/components/trackingAddDialog/TrackingAddDialog";

const OPERATION_URL = '/issue/detailModify'
const LIST_URL = '/issue/tracerId'
const DEMAND_AND_TRACKING_URL = '/test_case/selectconditionsinformation'

const TYPE_OBJ = {
  'ssourceTracer': 'BUG',
  'tracerId': 'FIX',
}

export default function AssociatedTracking(props) {
  const { isCS } = window.external
  const { defaultData, issueId, id, refreshDetail } = props
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(null)
  const [trackingData, setTrackingData] = useState()
  const [changeTrace, setChangeTrace] = useState(null)
  const [showTrace, setShowTrace] = useState({ ssourceTracer: false, tracerId: false })
  const { doFetch } = useApi()
  const addCommon = useCommon()
  const { data, doFetch: getFetch, loading, error } = useGet()
  const { doFetch: getTrace } = useGet()
  const [showAddTrack, setShowAddTrack] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isDel, setIsDel] = useState(false)

  const refresh = useCallback((id) => {
    if ((isNil(id) || id === 'NONE')) return
    getFetch(`${LIST_URL}?issueId=${id}`)
  }, [getFetch])

  const submitTrace = useCallback((type) => {
    const val = changeTrace[type]
    const idOrTitle = isNil(val) ? null : _.trim(val)
    const params = { type: 'Trace', branch: 0, idOrTitle, pageNum: 1, pageSize: 20, }
    getTrace(`${DEMAND_AND_TRACKING_URL}?${strParams(params)}`).then(res => {
      if (_.size(res?.rows) === 1 && _.get(_.head(res?.rows), 'tracerId') === val) {
        const traceList = type === 'ssourceTracer' ? _.get(data, '[0]', []) : _.get(data, '[1]', [])
        if (_.find(traceList, o => o.tracerId === val)) return Messager.show('无需重复选择', { icon: 'error' })
        doFetch(OPERATION_URL, 'post', { issueId, id, flag: '1', tracerId: val, type: TYPE_OBJ[type], actionType: 'add' })
          .then(() => {
            addCommon({ ids: [val], type: '06' })
            refresh(issueId)
            refreshDetail(id)
            setShowTrace(x => _.assign({}, x, { [type]: false }))
            Messager.show('关联成功', { icon: 'success' })
          })
          .catch(err => Messager.show(err._message, { icon: 'error' }))
      } else Messager.show('请输入正确的研发任务编号', { icon: 'error' })
    })
  }, [addCommon, changeTrace, data, doFetch, getTrace, id, issueId, refresh, refreshDetail])

  const submit = useCallback((idList) => {
    if (isSubmit) return
    setIsSubmit(true)
    const trackId = _.join(idList, '^')
    doFetch(OPERATION_URL, 'post', { id, issueId, flag: '1', tracerId: trackId, type: TYPE_OBJ[type], actionType: 'add' })
      .then(() => {
        addCommon({ ids: idList, type: '06' })
        refresh(issueId)
        refreshDetail(id)
        setIsSubmit(false)
        Messager.show('关联成功', { icon: 'success' })
      })
      .catch(err => {
        setIsSubmit(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [addCommon, doFetch, id, issueId, refresh, refreshDetail, type, isSubmit])

  const handClick = useCallback((data, type) => {
    setOpen(true)
    setType(type)
    setTrackingData(_.map(data, o => (_.assign(o, { ConstDisplayName: o.ConstDisplayName }))))
  }, [])

  const delTrack = useCallback((tId, type) => {
    // const idList = _.map(_.filter(list, o => _.includes(tId, o.tracerId)), o => o.id)
    if (isDel) return
    setIsDel(true)
    const trackId = _.join(tId, '^')
    doFetch(OPERATION_URL, 'post', { issueId, flag: '1', id, issueTracerId: trackId, actionType: 'del', type: TYPE_OBJ[type] })
      .then(() => {
        refresh(issueId)
        refreshDetail(id)
        setIsDel(false)
        Messager.show('已取消关联', { icon: 'success' })
      })
      .catch(err => {
        setIsDel(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, id, issueId, refresh, refreshDetail, isDel])

  const extra = useCallback((data, type) => {
    return <>
      {
        type === 'tracerId' &&
        <TextIconBtn icon={'-task'} style={{ margin: '8px 0 8px 8px' }} text={'创建研发任务'} onClick={() => setShowAddTrack(true)} />
      }
      {
        showTrace[type] &&
        <div>
          <Input
            size='middle'
            // options={traceOpt}
            placeholder="研发任务编号"
            onChange={e => setChangeTrace({ [type]: e.target.value })}
          // onSelect={v => setSelectTrace(v)}
          // onSearch={_.debounce(searchTrace, 300)}
          />
          <Button onClick={_.debounce(() => submitTrace(type), 300)} type="primary" size='middle' >
            <Icon name='baocun' />
          </Button>
        </div>
      }
      {
        !showTrace[type] &&
        <TextIconBtn style={{ marginRight: 8 }} icon='bianji2' text='输入研发任务' onClick={() => setShowTrace(x => _.assign({}, x, { [type]: true }))} />
      }
      <TextIconBtn style={{ margin: '8px 0 8px 8px' }} icon='sousuo1' text='关联研发任务' onClick={() => handClick(data, type)} />
    </>
  }, [handClick, showTrace, submitTrace])

  useEffect(() => {
    refresh(issueId)
  }, [issueId, refresh])

  return (
    <div className={`associated-tracking ${isCS ? '' : 'not-cs'}`}>
      <AssociatedTrackingList
        {...{ extra, loading, error, data: _.get(data, '[0]', []), delTrack, type: 'ssourceTracer', title: '引发问题研发任务' }}
        id={defaultData?.ssourceTracer}
      />
      <AssociatedTrackingList
        {...{ extra, loading, error, data: _.get(data, '[1]', []), delTrack, type: 'tracerId', title: '修复问题研发任务' }}
        id={defaultData?.tracerId}
      />
      {open && <AssociatedTrackingLog delTrack={delTrack} submit={submit} trackingData={_.map(trackingData, o => (_.assign({ _id: o.tracerId, }, o)))} close={() => setOpen(false)} />}
      {
        showAddTrack && <TrackingAddDialog close={() => setShowAddTrack(false)}
          afterRefresh={() => refresh(issueId)}
          initForm={{
            bugType: 'M',
            tracerPri: _.get(convertTracerPri, _.get(defaultData, 'effectGrade')),
            productId: _.get(defaultData, 'productName'),
            subSysId: _.get(defaultData, 'prodsubsysid'),
            bugSource: 'S1',
            projectId: _.get(defaultData, 'custId'),
            securityName: _.get(defaultData, 'custId') ? [_.get(defaultData, 'custId')] : null,
            bugDesc: _.get(defaultData, 'impact'),
            fixDesc: _.get(defaultData, 'whyAndHow'),
            issueId: _.get(defaultData, 'issueId'),
            tracerTitle: _.get(defaultData, 'shortDesc'),
          }}
        />
      }
    </div>
  )
}

const convertTracerPri = {
  '01': 'T',
  '02': 'A',
  '03': 'B',
  '04': 'B',
  '05': 'C',
}

import React from 'react';
import {Tooltip} from 'rootnet-ui'
import './DescItem.scss'

function DescItem(props) {
    const {header, text = '-', showTips = false, maxWidth = 265} = props
    return <div className='desc-item flex-y center-x'>
        <div className="desc-header">{header}</div>
        {
            showTips &&
            <Tooltip title={text || '-'}>
                <div className="desc-text" style={{maxWidth: maxWidth}}>{text || '-'}</div>
            </Tooltip>
        }
        {
            !showTips && <div className="desc-text">{text || '-'}</div>
        }

    </div>
}

export default DescItem;
import React, {useCallback, useMemo} from 'react';
import './SortCard.scss'
import {Card} from 'rootnet-ui';
import {Popover, Table} from 'antd';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { Radio } from 'antd';
import {TextIconBtn} from "../../../../common/TextIconBtn";
import _ from "lodash";
import AddFieldListPopover from "../addFieldListPopover/AddFieldListPopover";
import Icon from "../../../../../components/Icon";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

function getColumns(props){
    const {onRadioChange,delItem} = props
    return [
        {
            title: '',
            dataIndex: '',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '字段名称',
            dataIndex: 'fieldName',
            key: 'fieldName',
            width: 120,
        },
        {
            title: '字段排序',
            dataIndex: 'displayLevel',
            key: 'displayLevel',
            width: 220,
            render: (value, obj, index) => {
                return <Radio.Group value={value} onChange={e => onRadioChange(e.target.value, index)}>
                    <Radio value={'ASC'}>升序</Radio>
                    <Radio value={'DESC'}>降序</Radio>
                </Radio.Group>
            }
        },
        {
            title: '操作',
            key: 'operation',
            width: 60,
            render: (value, obj, index) => <span className={'delete-text'} onClick={()=>delItem(index)}>删除</span>,
        },
    ];
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function SortCard(props) {
    const {fullScreen, setFullScreen,primaryFieldList,sortList, setSortList} = props

    const showList = useMemo(()=>{
        if(_.isEmpty(primaryFieldList)) return []
        const allIdList = _.map(primaryFieldList, x => x.id)
        const filterShowList = _.filter(sortList, x => _.includes(allIdList, x.fieldId))
        return _.map(filterShowList, item => {
            const fieldItem = _.find(primaryFieldList, x => x.id === item.fieldId)
            item['fieldName'] = `${fieldItem.name}-${fieldItem.fieldName}`
            item['tableFieldName'] = `${fieldItem.tableName}.${fieldItem.fieldId}`
            return item
        })
    },[primaryFieldList,sortList])

    const notAddList = useMemo(()=>{
        const idList = _.map(sortList, x => x.fieldId)
        return _.filter(primaryFieldList, x => !_.includes(idList,x.id))
    },[sortList, primaryFieldList])

    const onRadioChange = useCallback((value, index)=>{
        setSortList(oldList => {
            const tempList = _.clone(oldList)
            tempList[index]['displayLevel'] = value
            return tempList
        })
    },[setSortList])

    const extra = useMemo(()=>{
        return <div className={'flex center-y'}>
            <Popover trigger="click" content={<AddFieldListPopover list={notAddList} setList={setSortList} defaultParams={{displayLevel: 'ASC'}}/>}>
                <TextIconBtn icon={'tianjia'} text={'添加字段'} disabled={_.isEmpty(notAddList)}/>
            </Popover>
            <Icon name={fullScreen === 'filterCondition'?'suoxiao':'fangda'} className={'full-screen-icon'} onClick={onClickFullScreenIcon}/>
        </div>

        function onClickFullScreenIcon(){
            setFullScreen(x => x ? null : 'sortCard')
        }
    },[notAddList,setSortList,fullScreen, setFullScreen])

    const delItem = useCallback(delIndex => {
        setSortList(oldList => {
            const tempList = _.clone(oldList)
            return _.filter(tempList, (x,i) => delIndex !== i)
        })
    },[setSortList])

    const columns = useMemo(()=>{
        return getColumns({onRadioChange,delItem})
    },[onRadioChange,delItem])

    return <Card title={'排序'} className={`sort-card-wrap ${fullScreen === 'sortCard' ?'full-screen':''}`} contentClass={'flex-y center sort-card'}
                 extra={extra} style={{display: fullScreen && (fullScreen !== 'sortCard') ? 'none' : ''}}>
        <Table
            size={'small'}
            sticky={true}
            pagination={false}
            dataSource={showList}
            columns={columns}
            rowKey="fieldId"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />
    </Card>


    function onSortEnd({ oldIndex, newIndex }){
        if (oldIndex !== newIndex) {
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setSortList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }

    function DraggableBodyRow({ className, style, ...restProps }){
        const index = sortList.findIndex(x => x.fieldId === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    }
}

export default SortCard;
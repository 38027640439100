import React, { useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { Checkbox, Select } from 'antd'
import { Messager, Tooltip } from 'rootnet-ui'
import { Display, Form, FormInput } from 'rootnet-edit'
import { useGet } from 'rootnet-biz/lib/hooks' //usePost
import HeadTag from '../headTag'
import { essentialInfo, productionRelated } from './formList'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { selectOption, useFuncCode, useRolePermissions } from '../../../../common/commonMethod'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { strParams } from '../../../../../utils/publicFun'
import VersionMilestone from './versionMilestone'
import VersionContent from './versionContent'
import { isNil } from 'rootnet-core/format'
import { Icon } from '../../../../../components'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'
import gd from '../../../../../base/global'

const { str14ToDate } = toDate

const HFormInput = props => {
  const { tooltip, icon, ...res } = props
  if (isNil(tooltip)) return <FormInput horizontal labelWidth={153} componentWidth={230} {...res} />
  return <>
    <FormInput horizontal labelWidth={153} componentWidth={230} {...res} />
    <Tooltip title={tooltip}>
      <span>
        <Icon name={icon} style={{ fontSize: 14, color: '#5477ff' }} />
      </span>
    </Tooltip>
  </>
}

// const VERSION_LIST_URL = '/releaseinfo/basic/version' // 版本
const CHECK_VERSION_URL = '/releaseinfo/basic/version/check' // 基础版本检查
const CHECK_VERSION_KEY_URL = '/releaseinfo/back/version/check'

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo', // 产品
  '/common/globalconst?globalConst=ProductLine', // 产品
  '/develop/product/subproduct/list', // 子产品
  '/verQuery/queryCustOptions', // 客户系统
  '/UserSetting/getUniversalInterfaces?code=ProjectID&codeName=ProjectName&tableName=UserProject&filter=status&filterParams=0,2,3,4,5,6', // 项目
  '/project/leafList', //项目翻译
]

const GLOBAL_CONST_URLS = [
  '/common/globalconst?globalConst=VERSIONSTATE', // 版本状态
  '/common/globalconst?globalConst=PRODUCTTYPE2', // 版本类型
  '/common/globalconst?globalConst=PRODUCTTYPE', // 版本性质
  '/common/globalconst?globalConst=ProductRound2', // 版本规模
  '/common/globalconst?globalConst=ProdectTpye3', // 版本分类
]

export default function VersionForm(props) {
  const { isDetail, params, setParams, error, setError, mode, releaseContentVoList, releaseMilestoneList, setReleaseContentVoList, setReleaseMilestoneList, allUserRes, versionOpt, defaultData, projectDisable = false,
  } = props
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstRes } = useGet(GLOBAL_CONST_URLS)
  const { doFetch: checkFetch } = useGet()
  const { doFetch: getFetch } = useGet()
  const [isEditProject] = useFuncCode(['081006']) //是否可以编辑项目
  const [isPMM, isQA] = useRolePermissions(['PMM', 'QA'])
  // const { data: versionListRes, doFetch } = usePost()
  const { projectid, functionary } = useMemo(() => defaultData || {}, [defaultData])

  const { releasebasic, mulcustid, producttype, noGlobalversioncontrol, updatetomainlineleastversion, shouldgotoreleaseid, producttype2, sproductround, moretimerequest, versionInner, versionApprovalDate } = useMemo(() => params || {}, [params])

  const [stateOpt, typeOpt, natureOpt, scaleOpt, classifyOpt] = useMemo(() => {
    if (_.isEmpty(globalConstRes)) return []
    return _.map(globalConstRes, v => convertGlobalConstOptions(v))
  }, [globalConstRes])

  const [productTreeOptions, productOptions, moduleOptionsRes, customerSystemOpt, projectOpt, projectAllOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3, d4, d5, d6] = optionsRes
    const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, verAb: x.verAb }))
    }))
    return [
      productTreeOptions,
      _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag, verAb: x.verAb })),
      d3,
      _.concat([{ label: '全部客户', value: 'ALL' }], _.map(d4, o => ({ label: o.projectName, value: o.projectId }))),
      selectOption(d5, ['displayName', 'interiorId']),
      selectOption(d6, ['ProjectName', 'id']),
    ]
  }, [optionsRes])

  const projectOptions = useMemo(() => {
    let addDefault = []
    if (_.isEmpty(projectOpt) && _.isEmpty(projectAllOpt)) return [] //
    const filterData = _.filter(projectOpt, o => !isNil(o.value))
    if (!isNil(projectid) && _.isNil(_.find(filterData, o => o.value === projectid))) {
      addDefault = [_.find(projectAllOpt, o => o.value === projectid)]
    }
    return _.concat(filterData, _.compact(addDefault))
  }, [projectAllOpt, projectOpt, projectid])

  const moduleOptions = useMemo(() => {
    if (_.isEmpty(moduleOptionsRes)) return []
    if (_.isNil(_.get(params, 'productid'))) return []
    const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === params.productid)
    return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, verAb: x.verAb }))
  }, [moduleOptionsRes, params])

  const versionListOpt = useMemo(() => {
    let addDefault = []
    if (_.isEmpty(versionOpt)) return []
    const filterData = _.filter(versionOpt, o => !isNil(o.value))
    if (!isNil(releasebasic) && _.isNil(_.find(filterData, o => o.value === releasebasic))) {
      addDefault = [{ text: releasebasic, value: releasebasic }]
    }
    return _.concat(filterData, addDefault)
  }, [versionOpt, releasebasic])

  const essentialInfoList = useMemo(() => {
    return essentialInfo({
      mode,
      productTreeOptions, productOptions, moduleOptions,
      stateOpt, typeOpt, natureOpt, scaleOpt, classifyOpt, allUserRes,
      customerSystemOpt, projectOpt: projectOptions, projectAllOpt,
      functionary, projectDisable, isEditProject, versionInner, isPMM, isQA, defaultData,
    })
  }, [allUserRes, classifyOpt, customerSystemOpt, mode, moduleOptions, natureOpt, productOptions, productTreeOptions, projectAllOpt, projectOptions, scaleOpt, stateOpt, typeOpt, functionary, projectDisable, isEditProject, versionInner, isPMM, isQA, defaultData])

  const productionRelatedList = useMemo(() => {
    return productionRelated({ mode, versionListOpt, releasebasic, producttype, noGlobalversioncontrol })
  }, [mode, noGlobalversioncontrol, producttype, releasebasic, versionListOpt])

  useEffect(() => {
    let isShouldgotoreleaseid = false
    if (_.includes(['Y', 'B', 'J'], producttype) && isNil(shouldgotoreleaseid)) isShouldgotoreleaseid = true
    if (_.includes(['Y', 'B', 'J'], producttype) && releasebasic === '无基础版本') isShouldgotoreleaseid = false
    setError(x => {
      return _.assign({}, x, {
        updatetomainlineleastversion: (_.includes(['Y', 'B', 'J'], producttype) && isNil(updatetomainlineleastversion)) ? '必填项！' : null,
        shouldgotoreleaseid: isShouldgotoreleaseid ? '必填项！' : null,
        mulcustid: (isNil(mulcustid) || _.isEmpty(mulcustid)) ? '必填项！' : null,
        producttype: isNil(producttype) ? '必填项！' : null,
        sproductround: isNil(sproductround) ? '必填项！' : null,
        moretimerequest: isNil(moretimerequest) ? '必填项！' : null,
        versionApprovalDate: isNil(versionApprovalDate) ? '必填项！' : null,
      }, producttype2 === 'M' && {
        producttype: null, sproductround: null, moretimerequest: null
      },
        (
          (functionary !== gd.User.operator_id && !isPMM && mode !== 'add') ||
          versionInner === 'Y') && { versionApprovalDate: null })
    })

  }, [mulcustid, producttype, releasebasic, setError, shouldgotoreleaseid, updatetomainlineleastversion, producttype2, sproductround, moretimerequest, params, versionApprovalDate, versionInner, mode, functionary, isPMM])

  // useEffect(() => {
  //   const nowdate = new Date();
  //   nowdate.setMonth(nowdate.getMonth() - 1)
  //   const y = nowdate.getFullYear()
  //   const m = nowdate.getMonth() + 1
  //   const d = nowdate.getDate()
  //   const formatwdate = new Date(y + '-' + m + '-' + d)

  //   const endDate = new Date()
  //   endDate.setTime(endDate.getTime() + 24 * 60 * 60 * 1000)
  //   doFetch(VERSION_LIST_URL, { endTime: dateFormat('YYYY-MM-DD', endDate), startTime: dateFormat('YYYY-MM-DD', formatwdate), states: ['T', 'C', 'R', 'L'] })
  // }, [doFetch])

  const handChange = useCallback((o, k) => {
    if (k === 'productid' && mode === 'add') {
      const verAb = _.get(_.find(productOptions, item => item.value === o[k]), 'verAb') + '_'
      o['releaseid'] = verAb
      o['defaultReleaseid'] = verAb
    }
    if (k === 'subsysid' && mode === 'add') {
      const verAb = _.get(_.find(moduleOptions, item => item.value === o[k]), 'verAb')
      if (!isNil(verAb)) {
        o['releaseid'] = verAb + '_'
        o['defaultReleaseid'] = verAb + '_'
      }
    }
    if (k === 'state') databaseConditionals()
    if (k === 'issuedate') databaseConditionals()
    if (k === 'noGlobalversioncontrol') {
      if (o[k] && !isNil(o['releasebasic'])) {
        getFetch(`/releaseinfo/basic/database?ReleaseId=${o['releasebasic']}`)
          .then(res => {
            o['globalversioncontrol'] = res
            setParams(_.assign({}, o))
          })
      }
      if (o[k] && _.isNil(o['releasebasic'])) Messager.show('基础版本为空，此项置灰。', { icon: 'error' })
      if (!o[k]) databaseConditionals()

    }
    if (k === 'producttype') verConditionals()
    if (k === 'releasebasic') {
      verConditionals()
      if (o[k] !== '无基础版本' && o['noGlobalversioncontrol']) {
        o['makebasic'] = null
        getFetch(`/releaseinfo/basic/database?ReleaseId=${o['releasebasic']}`)
          .then(res => {
            o['globalversioncontrol'] = res
            setParams(_.assign({}, o))
          })
      }
    }
    if (k === 'releaseid') {//版本编号
      databaseConditionals()
      if (isNil(_.trim(o[k]))) {
        o['releasebasic'] = null
        setReleaseContentVoList(x => _.filter(x, item => !(item.default)))
        setReleaseMilestoneList(x => _.filter(x, item => !(item.default)))
      }
    }
    if (isNil(_.trim(o?.releaseid)) && 'releasebasic' === k) {
      o[k] = null
      Messager.show('请填写版本编号后在进行操作', { icon: 'error' })
    }
    if ('releasebasic' === k && !isNil(o?.releaseid)) {//currentId
      if (o.releaseid === o[k]) {
        o[k] = null
        setParams(_.assign({}, o))
        Messager.show('【不能选择此版本，原因是：基础版本不能设置为当前版本！】', { icon: 'error' })
      }
      checkFetch(`${CHECK_VERSION_KEY_URL}?${strParams({ ReleaseID: o?.releaseid, checkId: o[k] })}`).then(res => {
        if (res === true) {
          o[k] = null
          setParams(_.assign({}, o))
          Messager.show('【不能选择此版本，原因是：该版本是由当前版本衍生而来的！】', { icon: 'error' })
        }

      })
      checkFetch(`${CHECK_VERSION_URL}?${strParams({ ReleaseID: o?.releaseid, checkId: o[k] })}`).then(res => {
        if (res === true) {
          o[k] = null
          Messager.show('【不能选择此版本，原因是：该版本是由当前版本衍生而来的！】', { icon: 'error' })
        }
      })
    }
    if (k === 'versionApprovalDate' || k === 'issuedate') {
      if (o['issuedate'] < o['versionApprovalDate']) {
        o[k] = null
        Messager.show('计划发布日期需大于等于版本立项日期', { icon: 'error' })
      }
    }
    function verConditionals() {
      if (_.includes(['Y', 'B', 'J'], o.producttype) && !isNil(o.releasebasic) && o.releasebasic === '无基础版本') o['shouldgotoreleaseid'] = null
    }
    function databaseConditionals() {
      // if (!isNil(defaultData?.globalversioncontrol)) return
      if (!_.includes(['R', 'L'], o['state']) && !isNil(_.trim(o['releaseid']))) {
        o['globalversioncontrol'] = `${o['releaseid']}_${dateFormat('YYYYMMDD', str14ToDate(o['issuedate']))}`
      }
    }
    if (!isNil(o['globalversioncontrol']) && o['noGlobalversioncontrol']) o['globalversioncontrol'] = null
    setParams(o)
  }, [checkFetch, getFetch, mode, moduleOptions, productOptions, setParams, setReleaseContentVoList, setReleaseMilestoneList])

  return (
    <div className='version-form-list'>
      <HeadTag text={'版本基本信息'} />
      <Form value={params} onChange={handChange} error={error} onError={v => setError(x => _.assign({}, x, v))}>
        {
          _.map(essentialInfoList, item => {
            if (item.bind === 'sprodecttpye3' && !isQA) {
              return <div className='checkbox-group-style' key={item.bind}>
                <label style={{ width: 153 }}>版本分类</label>
                <Checkbox.Group
                  disabled={mode === 'detail'}
                  value={_.split(_.get(params, `${item.bind}`, []), '^')}
                  options={item?.options}
                  onChange={v => setParams(x => _.assign({}, x, { [item.bind]: _.join(v, '^') }))}
                />
              </div>
            } else if (item.bind === 'mulcustid' && !isDetail && !isQA) {
              return <div className={clsx('mulcust-select-style', { required: true })} key={item.bind}>
                <label style={{ width: 140 }}>发布客户系统</label>
                <Select
                  className={clsx({ 'mulcustid-style-color': _.isEmpty(_.compact(_.split(_.get(params, 'mulcustid'), '^'))) })}
                  mode='multiple'
                  placeholder='请选择发布客户系统'
                  style={{ width: '642px' }}
                  allowClear
                  options={customerSystemOpt}
                  optionFilterProp='label'
                  value={_.compact(_.split(_.get(params, 'mulcustid', ''), '^'))}
                  onChange={v => {
                    const size = _.size(v) - 1
                    let val = v[size] === 'ALL' ? ['ALL'] : v
                    if (_.includes(v, 'ALL') && v[size] !== 'ALL') {
                      val = _.filter(val, k => k !== 'ALL')
                    }
                    setParams(x => _.assign({}, x, { mulcustid: _.join(val, '^') }))
                  }}
                />
              </div>
            }
            if (_.includes(['producttype', 'sproductround', 'moretimerequest'], item.bind) && producttype2 === 'M' && !isQA) {
              return <HFormInput key={item.bind}  {...item} required={false} clear={true} />
            }
            if (_.includes(['versionApprovalDate', 'issuedate'], item.bind) && isQA) {
              return <HFormInput key={item.bind}  {...item} required={(isDetail) ? false : _.get(item, 'required', false)} />
            }
            return <HFormInput key={item.bind}  {...item} required={(isDetail || isQA) ? false : _.get(item, 'required', false)} component={isQA ? Display : _.get(item, 'component')} />
          })
        }
      </Form>
      <HeadTag text={'版本内容'} />
      <VersionContent data={releaseContentVoList} setData={setReleaseContentVoList} {...{ isDetail: !isDetail, customerSystemOpt, currentId: params?.releaseid, isQA }} />
      <HeadTag text={'版本制作相关'} />
      <Form value={params} onChange={handChange} error={error} onError={v => setError(x => _.assign({}, x, v))}>
        {
          _.map(productionRelatedList, item => {
            if (item.bind === 'morereleasebase' && !isDetail && !isQA) {
              return <div className='mulcust-select-style' key={item.bind}>
                <label style={{ width: 154 }}>收拢版本清单</label>
                <Select
                  mode='multiple'
                  placeholder='请选择'
                  style={{ width: '628px' }}
                  allowClear
                  options={_.map(versionListOpt, o => ({ label: o.text, value: o.value }))}
                  optionFilterProp='label'
                  value={_.compact(_.split(_.get(params, 'morereleasebase', ''), '^'))}
                  onChange={v => {
                    if (_.size(v) > 20) return Messager.show('当前字段最多选择20个', { icon: 'error' })
                    setParams(x => _.assign({}, x, { morereleasebase: _.join(v, '^') }))
                  }}
                />
              </div>
            }
            if (item.tooltip) {
              const val = _.get(params, `${item.bind}`)
              if (isNil(val)) return <HFormInput key={item.bind} {...item} required={(isDetail || isQA) ? false : _.get(item, 'required', false)} component={isQA ? Display : _.get(item, 'component')} />
              return <Tooltip key={item.bind} title={_.get(params, `${item.bind}`)}>
                <HFormInput key={item.bind} {...item} required={(isDetail || isQA) ? false : _.get(item, 'required', false)} component={isQA ? Display : _.get(item, 'component')} />
              </Tooltip>
            }
            if (item.bind === 'noGlobalversioncontrol') {
              return <div style={{ display: 'inline-block' }}>
                <HFormInput key={item.bind} {...item} required={(isDetail || isQA) ? false : _.get(item, 'required', false)} component={isQA ? Display : _.get(item, 'component')} />
                <div style={{ width: 216, display: 'inline-block' }} />
              </div>
            }
            if (_.includes(['updatetomainlineleastversion', 'shouldgotoreleaseid'], item.bind)) {
              let isRequired = _.includes(['Y', 'B', 'J'], _.get(params, 'producttype', ''))
              if (item.bind === 'shouldgotoreleaseid') {
                if (_.includes(['Y', 'B', 'J'], producttype) && isNil(shouldgotoreleaseid)) isRequired = true
                if (_.includes(['Y', 'B', 'J'], producttype) && releasebasic === '无基础版本') isRequired = false
              }
              return <HFormInput key={item.bind} {...item} required={isQA ? false : isRequired} clear={!_.includes(['Y', 'B', 'J'], _.get(params, 'producttype', ''))} component={isQA ? Display : _.get(item, 'component')} />
            }
            return <HFormInput key={item.bind} {...item} required={(isDetail || isQA) ? false : _.get(item, 'required', false)} component={isQA ? Display : _.get(item, 'component')} />
          })
        }
      </Form>
      <HeadTag text={'版本里程碑'} />
      <VersionMilestone data={releaseMilestoneList} setData={setReleaseMilestoneList} {...{ isDetail: !isDetail, allUserRes, currentId: params?.releaseid, isQA }} />
      {
        mode !== 'add' &&
        <>
          <HeadTag text={'创建信息'} />
          <Form value={params} onChange={handChange}>
            <HFormInput label='创建人' bind='screateuser' component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
            <HFormInput label='创建时间' bind='dcreatetime' component={Display} convert={v => {
              if (_.isNil(v)) return '-'
              if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
              return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
            }} />
          </Form>
        </>
      }
    </div>
  )
}

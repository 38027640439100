import React, { useCallback, useEffect, useState, useReducer } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Transfer, Messager } from 'rootnet-ui'
import { FormInput, RadioGroup } from 'rootnet-edit'
import './index.scss'

const EDIT_COLUMNS_URL = '/releaseStory/customShowField/save'

const options = [
  { text: "是", value: true },
  { text: "否", value: false },
]

export default function ShuttleColumn(props) {
  const { close, allColumns = [], customField, refresh, isWrap = false } = props
  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])
  const [warp, setWarp] = useState(isWrap)
  const [count, forceUpdate] = useReducer((x) => x + 1, 0)
  const { doFetch } = usePost()

  const handChange = useCallback((target, source) => {
    setSource(source)
    setTarget(target)
  }, [])

  const submit = useCallback(() => {
    doFetch(`${EDIT_COLUMNS_URL}`, { fields: _.join(_.map(target, o => o.tag), ','), isWrap: warp })
      .then(() => {
        close()
        refresh()
        Messager.show('成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, target, close, refresh, warp])

  useEffect(() => {
    const hidden = _.filter(allColumns, o => !_.includes(_.split(customField, ','), o.value))
    const display = _.compact(_.map(_.split(customField, ','), v => _.find(allColumns, o => o.value === v)))
    setSource(_.map(hidden, o => ({
      text: o.label,
      tag: o.value,
    })))
    setTarget(_.map(display, o => ({
      text: o.label,
      tag: o.value,
    })))
    forceUpdate()
  }, [allColumns, customField])

  return (
    <Dialog className='req-shuttle-column-log' header='修改列表' confirm={submit} cancel={close}>
      <FormInput horizontal value={warp} onChange={setWarp} label='是否换行' bind='isWrap' component={RadioGroup} options={options} />
      <React.Fragment key={count}>
        <Transfer source={source} target={target} onChange={handChange} />
      </React.Fragment>
    </Dialog>
  )
}

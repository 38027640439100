import _ from 'lodash'
import cls from 'clsx'
import React, { useCallback, useState } from 'react'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, TextArea } from 'rootnet-edit'
import './index.scss'

const PUBLISH_EMAIL_URL = '/releaseLaunch/sendEmail' //发送邮件

const HFormInput = props => <FormInput horizontal labelWidth={65}  {...props} /> //required

export default function PostMailLog(props) {
  const { initParams, closeAll } = props
  const [params, setParams] = useState(initParams)
  const [error, setError] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (_.size(params?.context) <= 0) return Messager.show('请填内容', { icon: 'error' })
    if (isDisabled) return
    setIsDisabled(true)
    doFetch(PUBLISH_EMAIL_URL, params)
      .then((res) => {
        setIsDisabled(false)
        closeAll()
        const text = res
        const textList = _.split(text,'但邮件发送失败')
        let showText = ''
        if(_.size(textList) > 1){
          showText = <div>
            <span>{_.get(textList,0)}</span>
            <span style={{color: 'red'}}>但邮件发送失败</span>
            <span>{_.get(textList,1)}</span>
          </div>
        }else{
          showText = text
        }
        Messager.show(showText, { icon: 'success' })
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [params, doFetch, closeAll, check, isDisabled])

  return (
    <Dialog
      className='post-mail-log'
      header='邮件预览'
      confirm={confirm}
      cancel={closeAll}
      confirmButtonText='发送邮件'
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <HFormInput required label='收件人' bind='user' />
        <HFormInput label='抄送人' bind='ccuser' />
        <HFormInput required label='主题' bind='title' />
        <HFormInput
          required
          label='内容'
          bind='context'
          component={TextArea}
          counter={false}
          maxLength={99999}
          className={cls('reason', { error: _.size(_.trim(params?.context)) > 0 ? false : true })}
        />
      </Form>
    </Dialog>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import metadataConfig from './metadataConfig'
import _ from 'lodash'
import { N2 } from 'rootnet-core/format';
import {FormInput, Radio, Select} from "rootnet-edit";
import {compose, strParams} from "../../utils/publicFun";
import {useApi, useGet} from "../../utils/hook";
import {Box} from "../common/commonComponent";
import {DataGrid,Messager,Pagination, Dialog} from 'rootnet-ui'
import {TextIconBtn} from "../../project_share/components";
import convertGlobalConstOptions from "../common/ConvertGlobalConstOptions";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import convertOptions from "../common/ConvertOptions";
import OperationList from "../../project_share/components/OperationList";
import MetadataImportDialog from "./importAndExport/metadataImport/MetadataImportDialog";
import MetadataExportDialog from "./importAndExport/metadataExport/MetadataExportDialog";
import MetadataLogDrawer from "./common/MetadataLogDrawer";
import ApprovalDrawer from "./common/ApprovalDrawer";
import FieldDictDrawer from "./common/FieldDictDrawer";
import {Icon} from "../../components";
import DataTypeDrawer from './common/DataTypeDrawer';
import {selectOption, useFuncCode} from '../common/commonMethod'
import TextAreaWithTooltip from "./common/TextAreaWithTooltip";

const {str14ToDate} = toDate
const formatTime = compose(dateFormat('YYYY-MM-DD HH:MM:SS'),str14ToDate)

const OPTIONS_URLS = [
    '/fieldType/select',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
]

const AUDIT_FLAG_COLOR = [
    '#FAAD14',
    '#F5221B',
    '#33CC8A',
    '#5477FF',
    '#7A8199',
]

function convertTime(v){
    return v?v.slice(0,-2):null
}

const getColumns = (props) =>{
    const {setDialogData, setDelItem,approvalMode,auditFlagOptions,recall,setShowDrawer,setCurrentList,setLogParams,
        config,tabIndex,setFieldDictId,setDataTypeId,editAuth, delAuth} = props
    const approvalOptions = [
        { header: '操作人', bind: 'optName',sortable: true,width: 80, tooltip: true},
        { header: '操作时间', bind: 'inputTime',sortable: true,width: 160, convert: formatTime},
        { header: '操作类型', bind: 'action',sortable: true,width: 100, tooltip: true},
        { header: '审核状态', bind: 'auditFlag',sortable: true,width: 100, tooltip: true, convert: convertAuditFlag},
        { header: '审核人', bind: 'auditOptName',sortable: true,width: 100, tooltip: true},
        { header: '审核时间', bind: 'auditTime',sortable: true,width: 160, tooltip: true, convert: v => _.includes(['0','3'],tabIndex) ?formatTime(v):convertTime(v)},
    ]
    const handleOptions = [
        { header: '操作', align: 'center', width: 140, convert: (v, o) => renderOperation(v, o) },
    ]
    const fieldOptions = [
        { header: '根网数据类型', bind: 'fieldTypeCode', width: 120,convert: v => (<div className={'field-dict-id-in-cols'} onClick={()=>setDataTypeId(v)}>{v}</div>) },
        { header: '常量标识', bind: 'fieldDictId', convert: v => (<div className={'field-dict-id-in-cols'} onClick={()=>setFieldDictId(v)}>{v}</div>)},
        { header: '字典说明', bind: 'fieldDesc', width: 120, tooltip: true },
    ]

    const constantOptions=[
        { header: '根网数据类型', bind: 'fieldTypeCode', width: 120, tooltip: true,convert: v => (<div className={'field-dict-id-in-cols'} onClick={()=>setDataTypeId(v)}>{v}</div>) },
        { header: '子项标识', bind: 'fieldDictSubCode', width: 120, tooltip: true },
        { header: '子项名称', bind: 'fieldDictSubName', width: 120, tooltip: true },
        { header: '顺序', bind: 'displayOrder', width: 120, tooltip: true, convert:N2 },
        { header: '说明', bind: 'fieldDictSubDesc', width: 120, tooltip: true  },
    ]

    const cols = config.cols.concat(
        tabIndex === '0' ? fieldOptions : [],
        tabIndex === '1' ? constantOptions : [],
        approvalMode === 'default'? []:approvalOptions,handleOptions
    )

    const selectCols = [{selection:true, bind: 'dataGridSelection'}]

    return approvalMode === 'approval' ? selectCols.concat(cols) : cols

    function convertAuditFlag(value){
        return <div className={'convert-audit-flag flex center'}>
            <div className="circle" style={{background: AUDIT_FLAG_COLOR[value]}}/>
            {convertOptions(value,auditFlagOptions)}
        </div>
    }

    function getOptionsList(options, optionsList){
        let authList = delAuth ? optionsList : _.filter(optionsList, x => x!== '删除')
        authList = editAuth ? authList : _.filter(authList, x => x!== '修改')
        return _.filter(options, x => _.includes(authList, x.text))
    }

    function getOptions(optionsList,o){
        const allOptions = [
            {
                text: '日志',
                onClick: () => {

                    setLogParams(_.pick(o,config.logParams))
                }
            },
            {
                text: '修改',
                disabled: _.includes(['0','2'],o.auditFlag) || o.action === 'Del',
                onClick: () => setDialogData({mode: o.action === 'Add'?'add':'edit', initData: o})
            },
            {
                text: '删除',
                onClick: () => setDelItem(o)
            },
            {
                text: '审核',
                onClick: () => {
                    setCurrentList([o])
                    setShowDrawer('approval')
                }
            },
            {
                text: '撤回',
                disabled: _.includes(['1','2','3'],o.auditFlag),
                onClick: () => recall(o)
            },
        ]
        return getOptionsList(allOptions, optionsList)
    }

    function renderOperation(v, o){
        const defaultOptions = getOptions(['日志','修改','删除'],o)
        const listOptions = getOptions(['日志','审核'],o)
        const pendingOptions = getOptions(['修改','撤回','日志'],o)
        const strategy = {
            'default': defaultOptions,
            'approval': listOptions,
            'pending': pendingOptions
        }
        return <OperationList options={strategy[approvalMode]} />
    }
}

const getTableOption = (columns,config,currentId) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
    onRenderRow:o =>{
        const isCurrent = JSON.stringify(_.pick(o,config.logParams)) === currentId
        return {
            style: {backgroundColor:isCurrent?'#edf3f9':''},
        }
    },
});

const INIT_PARAMS = {
    pageNum: 1,
    pageSize: 20,
    searchName: '',
    dictTypeCode: null,
}

const optionSliceList = [
    6,7,8,6
]

function MetadataPage(props) {
    const {title,tabIndex,checkFlag,canCheck,initApprovalMode} = props
    const config = useMemo(() => _.find(metadataConfig, x => x.title === title),[title])
    const [dialogData, setDialogData] = useState({})
    const [delItem, setDelItem] = useState()
    const {data: listRes, doFetch: getList, loading, error} = useGet()
    const [showImportDialog, setShowImportDialog] = useState({})
    const [approvalMode, setApprovalMode] = useState(initApprovalMode)
    const {doFetch} = useApi()
    const {data: auditFlagRes} = useGet('/common/globalconst?globalConst=auditFlag')
    const [showDrawer, setShowDrawer] = useState(null)
    const [currentList, setCurrentList] = useState([])
    const {data: approvalNumRes, doFetch: getApprovalNum} = useGet()
    const { data: optionRes } = useGet(OPTIONS_URLS)
    const [logParams, setLogParams] = useState()
    const {data: logDetail, doFetch: getLogDetail} = useApi()
    const [params, setParams] = useState(INIT_PARAMS)
    const [selectedList, setSelectedList] = useState([])
    const {data: allList, doFetch: getAllList} = useGet()
    const [fieldDictId, setFieldDictId] = useState()
    const [dataTypeId,setDataTypeId] = useState()
    const [currentId, setCurrentId] = useState()
    const [filterAuditFlag, setFilterAuditFlag] = useState(['0','1','3'])
    const [delReason, setDelReason] = useState()

    const [addAuth, delAuth, editAuth] = useFuncCode(['0903','0904','0905'])

    const [crootDataTypeOpt, productOptions] = useMemo(()=>{
        if(_.isEmpty(optionRes)) return []
        const [d1, d2, d3] = optionRes
        return[
            selectOption(d1,['fieldTypeCode,fieldTypeName','fieldTypeCode']),
            _.map(_.groupBy(d2,product => product.productLine), (productLineItem, productLine) => ({
                text: _.get(_.find(d3, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
                children: _.map(productLineItem, x => ({value: x.productId, text: x.productName})),
                _disabled: true,
                value: 'noUse'
            }))
        ]
    },[optionRes])

    const onRowClick = useCallback((o) => {
        setCurrentId(JSON.stringify(_.pick(o,config.logParams)))
    },[config])

    useEffect(()=>{
        const getParams = {
            whichPage: tabIndex,
            searchName:_.get(params,'searchName'),
            dictTypeCode:_.get(params,'dictTypeCode')
        }
        getAllList('/field/selectAll?'+strParams(getParams))
    },[tabIndex,params,getAllList])

    const list = useMemo(()=>{
        const {rows} = listRes || {}
        let auditList = listRes
        if(!_.isEmpty(filterAuditFlag) && approvalMode === 'pending'){
            auditList = _.filter(listRes, x => _.includes(filterAuditFlag,_.get(x,'auditFlag')))
        }
        return approvalMode === 'default' ? rows : auditList
    },[listRes, approvalMode, filterAuditFlag])

    const { pageSize, total, pageNum } = useMemo(()=>(listRes || {}),[listRes]);

    useEffect(()=>{
        if(_.isNil(logParams)) return
        getLogDetail('/field/change?'+strParams({...logParams,whichPage: tabIndex}),'get').then((res)=>{
            if(_.isEmpty(res)) {
                setLogParams(null)
                return Messager.show('暂无日志数据')
            }
            setShowDrawer('metadataLog')
        })
    },[logParams,getLogDetail,tabIndex])

    const {approval:approvalNum, approvaled: approvaledNum, reject: rejectNum} = useMemo(() => approvalNumRes || {},[approvalNumRes])

    const showPending = useMemo(()=>{
        if(_.isEmpty(approvalNumRes)) return false
        const omitNum = _.omit(approvalNumRes, ['approval'])
        return !_.every(omitNum, x => x === 0)
    },[approvalNumRes])

    const auditFlagOptions = useMemo(()=>{
        if(_.isEmpty(auditFlagRes)) return []
        return convertGlobalConstOptions(auditFlagRes)
    },[auditFlagRes])

    const refresh = useCallback(()=>{
        const url = config.listUrls[approvalMode]
        setSelectedList([])
        getList(url+strParams(params))
        getApprovalNum('/fieldCommon/getNumber?whichPage='+tabIndex)
    },[params,getList,approvalMode,tabIndex,getApprovalNum,config])

    useEffect(()=>{
        refresh()
    },[refresh])

    const recall = useCallback((o)=>{
        const postParams = {
            ...o,
            isAgree: '3',
            whichPage: _.toString(tabIndex),
            whichOption: '0',
        }
        doFetch('/field/updateAudit','post',postParams).then(()=>{
            Messager.show('撤回成功',{icon:'success'});
            refresh()
        }).catch((err)=>{
            Messager.show(err._message,{icon:'error'});
        })
    },[doFetch, refresh, tabIndex])

    const [option,defaultOptions] = useMemo(()=>{
        const optionProps = {setDialogData,setDelItem,approvalMode,auditFlagOptions,recall,setCurrentList,
            setShowDrawer,setLogParams,config,tabIndex,setFieldDictId,setDataTypeId,editAuth, delAuth}
        return [
            getTableOption(getColumns(optionProps),config,currentId),
            getTableOption(getColumns(_.assign({},optionProps,{approvalMode: 'default'})),config,currentId)
        ]
    },[approvalMode,auditFlagOptions,recall,config,tabIndex,setFieldDictId,currentId,editAuth, delAuth])

    const batchAudit = useCallback(()=>{
        if(selectedList.length === 0) return Messager.show('请选择需要审核的数据')
        setCurrentList(selectedList)
        setShowDrawer('approval')
    },[selectedList])

    const extra = useMemo(()=>{
        return <div className='extra-area flex'>
            {
                addAuth &&
                <TextIconBtn text='导入' icon={'daoru'} onClick={()=>setShowImportDialog({mode: 'import'})}/>
            }
            <TextIconBtn text='导出' icon={'daochu'} onClick={()=>setShowImportDialog({mode: 'export'})}/>
            {
                addAuth &&
                <TextIconBtn text='新增' icon={'tianjia'} onClick={()=>setDialogData({mode: 'add'})}/>
            }
            {
                approvalMode === 'approval' &&
                <TextIconBtn text='批量审核' icon={'heyueguanli'} onClick={batchAudit}/>
            }
        </div>
    },[approvalMode,batchAudit,addAuth])

    const EditDialog = useMemo(()=>config.editDialog,[config])

    const delParams = useMemo(()=>{
        return ({...delItem,whichOption: '2',whichPage:tabIndex,isApproval: checkFlag, memo:delReason})
    },[delItem,tabIndex,checkFlag,delReason])

    const editDialogRefresh = useCallback(()=>{
        if(checkFlag==='1'){
            if(approvalMode ==='pending'){
                refresh()
            }else{
                setApprovalMode('pending')
            }
        }else{
            refresh()
        }
    },[checkFlag,refresh,approvalMode])

    const delPost = useCallback(()=>{
        doFetch(config.saveUrl,'post',delParams).then(()=>{
            Messager.show(checkFlag==='1'?'已提交删除，待审核':'已删除', { icon: 'success' });
            refresh()
            setDelItem(null)
            setDelReason(null)
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[doFetch, config, checkFlag, refresh, delParams])

    return <div className={'metadata-page fill flex-y'}>
        <div className="options flex">
            <div className={'filter-options-group'}>
                <FormInput placeholder={config.optionsLabel} value={_.get(params,'searchName')} componentWidth={230} suffix={<Icon name={'sousuo1'}/>}
                             onChange={v => setParams(x => _.assign({},x, {searchName: v,pageNum:1}))}/>
                {
                    ((Number(tabIndex) === 0||Number(tabIndex) === 1) && approvalMode === 'default') &&
                    <>
                        <FormInput search clear value={_.get(params,'dictTypeCode')} placeholder='根网数据类型' component={Select} options={crootDataTypeOpt} onChange={v => setParams(x => _.assign({},x, {dictTypeCode: v, pageNum: 1}))} />
                        <FormInput tree search clear value={_.get(params,'productId')} placeholder='使用产品' component={Select} options={productOptions} onChange={v => setParams(x => _.assign({},x, {productId: v, pageNum: 1}))} />
                    </>
                }
                {
                    approvalMode === 'pending' &&
                        <FormInput label={'审核状态'} multiple placeholder={'审核状态'} component={Select} horizontal options={auditFlagOptions} value={filterAuditFlag} onChange={setFilterAuditFlag}/>
                }
            </div>
            <div className="approval-group flex" style={{display: checkFlag === '1'?'':'none'}}>
                {
                    (canCheck || showPending) &&
                    <div className="approval-select flex-y center">
                        <div className="radio-wrap" onClick={()=>setApprovalMode('default')}>
                            <Radio value={approvalMode === 'default'}>{config.title}</Radio>
                        </div>
                        <div style={{height: 16}}/>
                    </div>
                }
                {
                    canCheck &&
                    <div className="approval-select flex-y center">
                        <div className="radio-wrap" onClick={()=>setApprovalMode('approval')}>
                            <Radio value={approvalMode === 'approval'}>审核清单</Radio>
                        </div>
                        <div className="radio-desc">
                            待审核: {approvalNum}/条
                        </div>
                    </div>
                }
                {
                    showPending &&
                    <div className="approval-select flex-y center">
                        <div className="radio-wrap" onClick={()=>setApprovalMode('pending')}>
                            <Radio value={approvalMode === 'pending'}>提交审核清单</Radio>
                        </div>
                        <div className="radio-desc flex">
                            <div className="radio-desc-item">
                                待审核: {approvaledNum}/条
                            </div>
                            <div className="radio-desc-item">
                                驳回: {rejectNum}/条
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <Box data={list} title={config.title} extra={extra} className={'x-card-singlegrid'} loading={loading} error={error}>
            <DataGrid data={list} option={option} selection={selectedList} onSelectionChange={list=>{setSelectedList(list)}} onRowClick={onRowClick}/>
            {
                approvalMode === 'default' &&
                <Pagination pageSize={ pageSize } total={ total } current={ pageNum } selector
                            onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>
            }
        </Box>
        {
            !_.isEmpty(dialogData) &&
            <EditDialog close={()=>setDialogData(null)} refresh={editDialogRefresh}
                        approvalMode={approvalMode} checkFlag={checkFlag} {...dialogData}/>
        }
        {
            _.get(showImportDialog,'mode') === 'import' &&
            <MetadataImportDialog close={()=>setShowImportDialog(null)} {...{tabIndex,refresh,defaultOptions,checkFlag,title}}/>
        }
        {
            _.get(showImportDialog,'mode') === 'export' &&
            <MetadataExportDialog close={()=>setShowImportDialog(null)} list={approvalMode==='default'?allList:list}
                                  searchName={_.get(params,'searchName')} {...{tabIndex,defaultOptions,approvalMode,title}}/>
        }
        {
            !_.isEmpty(delItem) &&
            <Dialog header={'删除'} className={'content-center-dialog metadata-del-message-dialog'} confirmButtonDisabled={!_.trim(delReason)} confirm={delPost}
                    cancel={()=>{
                        setDelItem(null)
                        setDelReason(null)
                    }}>
                <FormInput label={'删除理由'} required horizontal component={TextAreaWithTooltip} componentWidth={400}
                           className={'desc-textarea desc-required'} value={delReason} onChange={setDelReason}/>
            </Dialog>
        }
        <MetadataLogDrawer open={showDrawer === 'metadataLog'} cancel={()=> {
            setShowDrawer(null)
            setLogParams(null)
        }} logDetail={logDetail} auditFlagOptions={auditFlagOptions} defaultOptions={defaultOptions}/>
        <ApprovalDrawer open={showDrawer === 'approval'} cancel={()=>setShowDrawer(null)} formatTime={formatTime} currentList={currentList}
                        option={option} hasSelection={approvalMode === 'approval'} optionSlice={optionSliceList[tabIndex]}
                        refresh={refresh} tabIndex={tabIndex}/>
        <FieldDictDrawer open={!_.isNil(fieldDictId)} cancel={()=>setFieldDictId(null)} fieldDictId={fieldDictId}/>
        <DataTypeDrawer open={!_.isNil(dataTypeId)} cancel={()=>setDataTypeId(null)} dataTypeId={dataTypeId}/>
    </div>
}

export default MetadataPage;
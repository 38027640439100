import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState, useReducer } from 'react'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { isNil } from 'rootnet-core/format'
import { Button, Messager } from 'rootnet-ui'
import { strParams } from '../../../../../../../utils/publicFun'
import VersionForm from '../../../versionForm'
import './index.scss'

const VERSION_DETAIL_URL = '/releaseinfo/detail'
const EDIT_URL = '/releaseinfo/update'
const PROJECT_CHANGE_URL = '/releaseStory/scheduleTaskProject' //项目发生改变时

let isDisabled = false

export default function BasicInformation(props) {
  const { currentId, mode, setMode, allUserRes, setMulcustId, versionOpt, setPrincipal, updateDynamic, setProductId, setSnowId, setState, setPoUser, setDetailData, upDateDefect = () => { } } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [releaseContentVoList, setReleaseContentVoList] = useState([])
  const [releaseMilestoneList, setReleaseMilestoneList] = useState([])
  const [count, updateCount] = useReducer((x) => x + 1, 0)
  const { data: defaultData, doFetch } = useGet()
  const { doFetch: getFetch } = usePost()

  const refresh = useCallback((id) => {
    if (_.isNil(id)) return
    doFetch(`${VERSION_DETAIL_URL}?${strParams({ releaseid: id })}`).then(res => {
      setProductId(res.productid)
      setPrincipal(res.functionary)
      setSnowId(res.snowId)
      setState(res.state)
      setPoUser(res.pouser)
      setDetailData(res)
      setMulcustId(_.compact(_.split(res.mulcustid, '^')))
    })
  }, [doFetch, setDetailData, setMulcustId, setPoUser, setPrincipal, setProductId, setSnowId, setState])

  const isDetail = useMemo(() => mode === 'detail', [mode])

  useEffect(() => {
    if ((isDetail && !_.isNil(defaultData)) || (currentId && !_.isNil(defaultData))) {
      setReleaseContentVoList(_.isEmpty(defaultData.releaseContentVoList) ? [] : defaultData.releaseContentVoList)
      setReleaseMilestoneList(_.isEmpty(defaultData.releaseMilestoneList) ? [] : defaultData.releaseMilestoneList)
      setParams(_.assign({}, _.omit(defaultData, ['sdf'])))
    }
  }, [currentId, defaultData, isDetail])

  useEffect(() => {
    if (currentId || isDetail) {
      refresh(currentId)
    }
  }, [currentId, isDetail, refresh])

  const check = useCallback((flag = false) => {
    return _.every(_.values(error), isNil)
  }, [error])

  const submit = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    isDisabled = true
    updateCount()
    getFetch(EDIT_URL, _.assign({}, params, { releaseContentVoList, releaseMilestoneList, subsysid: _.isNil(params?.subsysid) ? '' : params.subsysid }))
      .then(() => {
        const { projectid, releaseid } = params || {}
        if (_.get(defaultData, 'projectid') !== projectid) {
          getFetch(PROJECT_CHANGE_URL, { projectId: projectid, releaseId: releaseid })
            .catch(err => {
              Messager.show(err._message, { icon: 'error' })
            })
        }
        isDisabled = false
        upDateDefect()
        updateDynamic()
        updateCount()
        setMode('detail')
        // refresh(currentId)
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        isDisabled = false
        updateCount()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [check, getFetch, params, releaseContentVoList, releaseMilestoneList, setMode, updateDynamic, upDateDefect, defaultData])

  return (
    <div className='basic-information'>
      <VersionForm {...{ isDetail, currentId, params, setParams, error, setError, mode, releaseContentVoList, releaseMilestoneList, setReleaseContentVoList, setReleaseMilestoneList, allUserRes, versionOpt, defaultData }} />
      {

        !isDetail && <div className='footer' key={count}>
          <Button disable={isDisabled} primary onClick={_.debounce(submit, 300)}>保存</Button>
          <Button onClick={() => setMode('detail')}>取消</Button>
        </div>
      }
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import _ from 'lodash'
import clsx from "clsx";
import { Checkbox, Popover, Tooltip } from "antd";
import { useGet, usePost } from 'rootnet-biz/lib/hooks';
import { Dialog, Messager, Button, Loader } from 'rootnet-ui'
import { FormInput, Select, Form, DatePicker, TextArea } from "rootnet-edit";
import { dateFormat } from "rootnet-core/dateFormat";
import Icon from "../../../components/Icon";
import RichTextEditor from "../../common/richTextEditor/TinyEditor";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import { strParams } from "../../../utils/publicFun";
import UserSelect from '../../common/personSelectPop/UserSelect';
import convertOptions from "../../common/ConvertOptions";
import CascadeSelect from "../../common/cascadeSelect/CascadeSelect";
import DisplaySelect from '../../common/displaySelect/DisplaySelect';
import RequirementAddUploadArea from '../../requirementMgt/requirementAddDialog/requirementAddUploadArea/RequirementAddUploadArea';
import gd from '../../../base/global';
import './index.scss'

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props} />
const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSeverity',
  'common/globalconst?globalConst=BizProcess',
  'common/globalconst?globalConst=storyType',
  '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=R,L',
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSource',
  'common/globalconst?globalConst=releaseType',
]

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
  '/verQuery/queryCustOptions',
]

const CONTINUE_ADD_REPLACE_PARAMS = {
  title: null,
  testSuggest: null,
}



export default function DevListAddLog(props) {
  const initData = {
    severity: '03',
    custSystem: 'NBGJ',
    customer: 'NBGJ',
    assessor: gd.User.operator_id,
  }
  const { initType = 'DEV', afterRefresh, close, currentInfo } = props
  const { CSRecordID, relateCSAttach, serviceId, serviceFileId } = currentInfo
  const [baseInfoExpand, setBaseInfoExpand] = useState(true)
  const [expand, setExpand] = useState({
    describe: true,
    solution: true,
    userTestSuggestion: true,
    accessory: true,
  })
  const descRef = useRef()  //描述
  const solutionRef = useRef() //解决方案
  const [formData, setFormData] = useState(initData)
  const [formError, setFormError] = useState()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: customerOptionsRes, doFetch: getProjectId } = useGet()
  // const {data: salesProjectIdRes, doFetch: getSalesProjectId} = useGet()
  const { doFetch: addReq } = usePost()
  const [continueAdd, setContinueAdd] = useState(false)
  const { data: onlyId, doFetch: getOnlyId } = useGet()
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch: getCSInitInfo } = useGet()
  const { doFetch: uploadCSFile } = usePost()
  const [countFile, updateFile] = useReducer((x) => x + 1, 60000)
  const [type, setType] = useState(initType)
  const [showTypeSelect, setShowTypeSelect] = useState(false)
  const { doFetch: getServiceInfo } = useGet()
  const { doFetch: getAPI } = useGet()
  const { doFetch: postAPI } = usePost()
  const [addLoading, setAddLoading] = useState(false)

  useEffect(() => {
    getProjectId('/story/getbugProject?id=NBGJ')
  }, [getProjectId])

  useEffect(() => {
    if (_.isNil(CSRecordID)) return
    getCSInitInfo('/serviceSelf/getCustomerServiceInfo?id=' + CSRecordID).then(res => {
      const replaceParams = {
        custSystem: res.customer,
        custContact: res.linker,
        custContactInfo: res.custContactInfo,
        custInputDate: dateFormat('YYYY-MM-DD', new Date()),
        productId: res.productType,
        subSysId: res.subSysId,
        // interfaceUser: res.principal,
        currentUser: res.analyst,
        priority: res.priority,
        title: res.issueDesc,
      }
      setFormData(x => _.assign({}, x, replaceParams))
      setTimeout(() => {
        let initDescText = res.reqDetail || ''
        let initSolutionText = res.solution || ''
        if (descRef.current) {
          descRef.current.setContent(initDescText)
        }
        if (solutionRef.current) {
          solutionRef.current.setContent(initSolutionText)
        }
      }, 2000)
      getProjectId('/story/getbugProject?id=' + res.customer)
    })
  }, [CSRecordID, getCSInitInfo, getProjectId])

  useEffect(() => {
    if (_.isNil(serviceId)) return
    getServiceInfo('/customerService/list?id=' + serviceId).then(res => {
      const replaceParams = {
        title: res.issueDesc,
        priority: res.priority,
        productId: res.productType,
        subSysId: res.subSysId,
        custContact: res.linker,
        custContactInfo: res.custContactInfo,
        custSystem: res.customer,
        // interfaceUser: res.principal,
        CSRecordID: res.csrecordID,
      }
      if (descRef.current) {
        descRef.current.setContent(res.reqDetail)
        solutionRef.current.setContent(res.solution)
      }
      setFormData(x => _.assign({}, x, replaceParams))
      getProjectId('/story/getbugProject?id=' + _.get(res, 'customer'))
    })
  }, [serviceId, getServiceInfo, getProjectId])

  const getNewOnlyId = useCallback(() => {
    getOnlyId('/test_case/productGetOnlyId').then(id => {
      setFormData(x => _.assign({}, x, { id }))
      if (!!relateCSAttach) {
        const fileParams = { id: relateCSAttach, storyId: id, funcCode: "15" }
        uploadCSFile('/serviceSelf/add?' + strParams(fileParams))
          .then(() => {
            updateFile()
          }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
          })
      }
      if (!!serviceFileId) {
        getAPI('/mapping/files/queryNew?' + strParams({ funcCode: '20', referenceId: serviceFileId })).then(serviceFileList => {
          const newFileList = _.map(serviceFileList, x => ({
            funcCode: '15',
            name: x.fullName,
            referenceId: id,
            status: "done",
            type: x.type,
            uid: x.uid,
            url: x.url
          }))
          postAPI('/mapping/files/add', newFileList).then(() => {
            updateFile()
          }).catch(err => Messager.show(err._message, { icon: 'error' }))
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
      }
    })
  }, [getOnlyId, relateCSAttach, uploadCSFile, postAPI, getAPI, serviceFileId])

  useEffect(() => {
    getNewOnlyId()
  }, [getNewOnlyId])

  const customerOptions = useMemo(() => {
    if (_.isEmpty(customerOptionsRes)) return []
    return _.map(customerOptionsRes, x => ({ text: x.customerName, value: x.customer }))
  }, [customerOptionsRes])

  const [severityOptions, bizProcessOptions, storyTypeOptions, releaseIdOptions, sourceOptions, releaseTypeOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const releaseIdExtendOptions = useMemo(() => {
    return releaseIdOptions
  }, [releaseIdOptions])

  const [productOptions, moduleOptionsRes, custSystemOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3, d4] = optionsRes
    const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))

    return [
      productOptions,
      d3,
      _.map(d4, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}` })),
    ]
  }, [optionsRes])

  const moduleOptions = useMemo(() => {
    if (_.isEmpty(moduleOptionsRes)) return []
    if (_.isNil(_.get(formData, 'productId'))) return []
    const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === formData.productId)
    return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId }))
  }, [formData, moduleOptionsRes])

  const TypeSelect = useCallback(() => {
    const option = _.filter(storyTypeOptions, o => o.value === 'DEV')
    return <div className={'dev-add-story-type-select flex-y'}>
      {
        _.map(option, item => (
          <div className={`type-name flex center-y ${item.value === type ? 'current-type' : ''}`} key={item.value}
            onClick={() => {
              setType(item.value)
              setShowTypeSelect(false)
            }}>
            {item.text}
          </div>
        ))
      }
    </div>
  }, [storyTypeOptions, type])

  const TitlePrefix = useCallback(() => {
    return <Popover content={<TypeSelect />} trigger="click" placement="bottom" open={showTypeSelect}
      onOpenChange={visible => {
        setShowTypeSelect(visible)
      }} overlayStyle={{ paddingTop: 0 }}>
      <div className={clsx('title-prefix flex center-y', { 'disabled': true, 'able': true })} onClick={() => {
        setShowTypeSelect(true)
      }}>
        {convertOptions(type, storyTypeOptions)}
        <Icon className={'arrow-icon'} name={'zhankaijiantouxia'} />
      </div>
    </Popover>
  }, [type, storyTypeOptions, showTypeSelect])

  const canSubmit = useMemo(() => {
    if (!_.get(formData, 'title')) return false
    return !_.some(_.values(formError), x => x)
  }, [formData, formError])

  const changeForm = useCallback((newObj, bind) => {
    setFormData(oldObj => {
      let cloneObj = { ...oldObj, ...newObj }
      if (bind === 'custSystem') {
        cloneObj['salesProjectId'] = null
        getProjectId('/story/getbugProject?id=' + _.get(newObj, 'custSystem'))
      }
      if (bind === 'reqSort') {
        if (!_.includes(['SPC', 'NORMAL', 'FUNCTION'], cloneObj['reqSort'])) {
          cloneObj['salesProjectId'] = null
        }
      }
      return cloneObj
    })
  }, [getProjectId])

  useEffect(() => {
    if (_.isEmpty(customerOptionsRes)) return
    const valueList = _.map(customerOptionsRes, 'customer')
    if (!_.isNil(formData) && !_.includes(valueList, _.get(formData, 'customer'))) {
      const customer = _.get(customerOptionsRes, '0.customer')
      changeForm({ customer })
    }
  }, [customerOptionsRes, formData, changeForm])

  // const salesProjectIdRequired = useMemo(()=>{
  //     if(type !== 'CUS') return false
  //     const reqSort = _.get(formData, 'reqSort')
  //     return _.includes(['SPC','NORMAL','FUNCTION'],reqSort)
  // },[formData, type])

  const needBizFlag = useMemo(() => {
    if (type !== 'CUS') return 'N'
    const reqSort = _.get(formData, 'reqSort')
    return _.includes(['SPC', 'NORMAL', 'FUNCTION'], reqSort) ? 'Y' : 'N'
  }, [formData, type])

  const clearUrl = useCallback(() => {
    let url = window.location.href;
    if (url.indexOf("?") !== -1) {
      url = url.replace(/(\?)[^'"]*/, '');
      window.history.pushState({}, 0, url);
    }
  }, [])

  const continueClear = useCallback(() => {
    clearUrl()
    setFormData(x => _.assign({}, x, CONTINUE_ADD_REPLACE_PARAMS))
    descRef.current.setContent('')
    solutionRef.current.setContent('')
    getNewOnlyId()
  }, [getNewOnlyId, clearUrl])

  const checkRichTextRequiredDesc = useCallback(() => {
    return (purifyRichText(descRef.current.getContent()) === "")
    function purifyRichText(richText = '') {
      const regex = /(<([^>]+)>)/ig
      return richText.replace(regex, "")
        .replace(/\s/g, "")
        .replace(/ /g, "")
        .replace(/&nbsp;/g, "")
    }
  }, [])

  const checkRichTextRequiredSolution = useCallback(() => {
    return (purifyRichText(solutionRef.current.getContent()) === "")
    function purifyRichText(richText = '') {
      const regex = /(<([^>]+)>)/ig
      return richText.replace(regex, "")
        .replace(/\s/g, "")
        .replace(/ /g, "")
        .replace(/&nbsp;/g, "")
    }
  }, [])

  const submit = useCallback(() => {
    if (checkRichTextRequiredDesc()) {
      return Messager.show("请填写描述")
    }
    if (checkRichTextRequiredSolution()) {
      return Messager.show("请填写解决方案")
    }
    if (descRef.current.loading || solutionRef.current.loading) {
      return Messager.show("图片上传中，请稍后保存")
    }
    if (addLoading) return
    setAddLoading(true)
    const postParams = {
      ...formData,
      type: type,
      status: 'R0',
      businessType: 'req',
      needBizFlag: needBizFlag,
      currentUser: !!_.get(formData, 'currentUser') ? _.get(formData, 'currentUser') : _.get(formData, 'assessor'),
      description: _.replace(descRef.current.getContent() || '', /\n/g, ''),
      solution: _.replace(solutionRef.current.getContent() || '', /\n/g, ''),
    }
    if (CSRecordID) {
      postParams['CSRecordID'] = CSRecordID
    }
    addReq('/story/add', postParams).then(() => {
      Messager.show('添加成功', { icon: 'success' })
      afterRefresh()
      setAddLoading(false)
      if (continueAdd) {
        continueClear()
      } else {
        close()
      }
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
      setAddLoading(false)
    })
  }, [addLoading, formData, type, needBizFlag, CSRecordID, addReq, afterRefresh, continueAdd, continueClear, close, checkRichTextRequiredDesc, checkRichTextRequiredSolution])

  const isCUS = useMemo(() => {
    return type === 'CUS'
  }, [type])

  useEffect(() => {
    if (isCUS) {
      if (_.isNil(_.get(formData, 'reqLevel'))) {
        setFormError(x => _.assign({}, x, { 'reqLevel': '必填项！' }))
      }
    } else {
      setFormError(x => _.assign({}, x, { 'reqLevel': null }))
    }
  }, [isCUS, formData])

  return <Dialog
    footerVisible={false}
    headerVisible={false}
    header={'新增开发自提单'}
    className={'dev-list-add-dialog'}
  >
    <div className="mock-dialog-header flex">
      <div className="dialog-title">
        新增开发自提单
      </div>
      <div className="mock-right-header flex center-y">
        <div className={'close-area flex center'} onClick={() => {
          clearUrl()
          close()
        }}>
          <Icon name={'quxiao'} className={'close-icon'} />
        </div>
      </div>
    </div>
    <div className="content-wrap flex-y">
      {
        addLoading && <Loader fill />
      }
      <HFormInput label={'标题'} value={_.get(formData, 'title')} onChange={v => setFormData(x => ({ ...x, title: v }))} prefix={<TitlePrefix />} required componentWidth={772} />
      <div className="area-wrap base-info-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
          基本信息
        </div>
        <div className="area-content-wrap">
          <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
            <HFormInput search label={'严重程度'} bind={'severity'} component={DisplaySelect} options={severityOptions} required />
            <HFormInput label={'所属产品'} bind={'productId'} required search component={Select} tree options={productOptions} />
            <HFormInput label={'子产品'} bind={'subSysId'} required search component={Select} options={moduleOptions} componentClass={'sub-sys-id-options'} />
            <HFormInput label={'发布方式'} bind={'releaseType'} required search component={Select} options={releaseTypeOptions} />
            <HFormInput label={'客户系统'} bind={'custSystem'} required search component={Select} options={custSystemOptions} />
            <HFormInput label={'提出客户'} bind={'customer'} required search component={Select} options={customerOptions} />
            <HFormInput label={'负责人'} bind={'assessor'} required component={UserSelect} />
            <HFormInput label={'业务流程环节'} bind={'bizProcess'} component={CascadeSelect} options={bizProcessOptions} />
            <HFormInput required search label={'根源'} bind={'source'} component={CascadeSelect} options={sourceOptions || []} clear />
            <HFormInput search label={'发现版本'} bind={'findVersion'} component={DisplaySelect} required options={releaseIdExtendOptions} clear />
            <HFormInput label='工作量' bind='workload' type='number' min={0} suffix='人日' />
            <HFormInput label={'计划发布日期'} bind={'releaseDate'} component={DatePicker} clear />
            <Tooltip title={'五个工作日内进行的商务确认有效'} overlayStyle={{ width: 430, maxWidth: 430, whiteSpace: 'pre-wrap' }}>
              <span className={'cursor-pointer'}>
                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
              </span>
            </Tooltip>
          </Form>
        </div>
      </div>
      {/* 描述 */}
      <div className="area-wrap desc-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: _.get(expand, 'describe') ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(x => _.assign({}, x, { describe: !x['describe'] }))} />
          描述
          <span style={{ color: 'red' }}>（必填）</span>
        </div>
        <div className="rich-text-area" style={{ display: _.get(expand, 'describe') ? 'block' : 'none' }}>
          <RichTextEditor ref={descRef} />
        </div>
      </div>
      {/* 解决方案 */}
      <div className="area-wrap desc-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: _.get(expand, 'solution') ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(x => _.assign({}, x, { solution: !x['solution'] }))} />
          解决方案
          <span style={{ color: 'red' }}>（必填）</span>
        </div>
        <div className="rich-text-area" style={{ display: _.get(expand, 'solution') ? 'block' : 'none' }}>
          <RichTextEditor ref={solutionRef} />
        </div>
      </div>
      {/* 用户测试建议 */}
      <div className="area-wrap desc-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: _.get(expand, 'userTestSuggestion') ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(x => _.assign({}, x, { userTestSuggestion: !x['userTestSuggestion'] }))} />
          用户测试建议
        </div>
        <div className="rich-text-area" style={{ display: _.get(expand, 'userTestSuggestion') ? 'block' : 'none' }}>
          <TextArea value={_.get(formData, 'testSuggest')} onChange={(value) => setFormData(x => ({ ...x, testSuggest: value }))}
            maxLength={1000} className={'test-suggest-textarea'} />
        </div>
      </div>
      <div className="area-wrap desc-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: _.get(expand, 'accessory') ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(x => _.assign({}, x, { accessory: !x['accessory'] }))} />
          附件
        </div>
        <div className="doc-area" style={{ display: _.get(expand, 'accessory') ? 'block' : 'none' }} key={countFile}>
          <RequirementAddUploadArea id={onlyId} allUserRes={allUserRes} funcCode={'15'} />
        </div>
      </div>
    </div>
    <div className="mock-footer flex center-y">
      <div className={'continue-add-check'}>
        {
          _.isNil(serviceId) && _.isNil(serviceFileId) && _.isNil(relateCSAttach) && _.isNil(CSRecordID) &&
          <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
        }
      </div>
      <div className="btn-group flex">
        <Button normal onClick={() => {
          clearUrl()
          close()
        }}>取消</Button>
        <Button primary disable={!canSubmit} onClick={submit}>确认</Button>
      </div>
    </div>
  </Dialog>
}

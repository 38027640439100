import React, { useMemo, useState, useCallback, useEffect, useReducer, Fragment } from 'react';
import _ from "lodash";
import {Popover, Tooltip} from 'antd' //Tag
import { DataGrid, Pagination } from "rootnet-ui";
import usePost from "rootnet-biz/es/hooks/usePost";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import { Box } from "../common/commonComponent";
import ViewArea from "../common/view/ViewArea";
import { pathSearchFor } from '../../utils/publicFun';
import TodoListDetailDialog from './components/todoListDetailDialog';
import FollowMattersDetail from '../common/ChangeRecord/components/followMattersDetail';
import { TextIconBtn } from '../common/TextIconBtn';
import './index.scss'
import { Icon } from '../../components';
import { API1 } from '../../base/task'
import {isNil} from "rootnet-core/format";
import convertOptions from "../common/ConvertOptions";
import WorkFlowChangePop from "../workFlow/workFlowChangePop/WorkFlowChangePop";
import ImportApiDialog from "../common/ImportApiDialog";
import ConcernedIcon from '../common/concernedIcon';

const FUNC_CODE = '23'
const ENTER_DETAIL_FIELD = 'subject'

function getImportColumns(props) {
  return [
    { header: '主题', bind: 'subject', width: 200, tooltip: true },
    { header: '负责人', bind: 'principal', width: 120, tooltip: true },
    { header: '参与人', bind: 'otherPerson', width: 120, tooltip: true },
    { header: '归属部门', bind: 'dataDepartment', width: 120, tooltip: true },
    { header: '提出人', bind: 'sponsor', width: 120, tooltip: true },
    { header: '时间要求', bind: 'requireDateName', width: 120, tooltip: true},
    { header: '优先级', bind: 'priority', width: 120, tooltip: true },
    { header: '审核人', bind: 'auditPerson', width: 120, tooltip: true },
    { header: '事项类型', bind: 'eventType', width: 120, tooltip: true },
    { header: '详细描述', bind: 'note', width: 200, tooltip: true },
  ]
}

const STATUS_URL = [
  '/common/globalconst?globalConst=FollowUpStatus',
  '/common/globalconst?globalConst=todoOverDue',
]

const SPECIAL_FIELD_CONVERT = {
  'overdue': (value, showValue, { overdueOpt }) => {
    const backgroundColor = _.get(_.find(overdueOpt, o => o.interiorId === value), 'displayColor')
    return <div className={'status-field flex center'} >
      <div style={{ backgroundColor, borderRadius: '50%', width: 8, height: 8, marginRight: 8, }} />
      <div style={{ color: backgroundColor }}>{showValue}</div>
    </div>
  },
  'status': (value, showValue, { statusOpt }) => {
    const backgroundColor = _.get(_.find(statusOpt, o => o.interiorId === value), 'displayColor')
    return <div className={'status-field flex center'} style={{ backgroundColor, color: 'white', padding: '0px 8px', borderRadius: 4, width: 64 }}>
      {showValue}
    </div>
  },
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, onExpandIconClick, foldList, statusOpt, overdueOpt,
    workFlowId, setWorkFlowId, refreshList } = props
  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }

  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue, { statusOpt, overdueOpt })
    }
    // if (fieldItem.fieldId === 'status') {
    //   const requireDate = o[_.find(fieldList, item => item.fieldId === 'requireDate')?.columnId]
    //   // const status = _.find(fieldList, item => item.fieldId === 'status')?.columnId
    //   return stateMatter({ requireDate, status: v })
    // }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'} style={{ width: '100%' }}>
        <div className="left-enter-field-wrap flex center-y" style={{ width: '100%' }}>
          {
            _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') &&
            <Icon name={_.includes(foldList, o.id) ? 'biaogezhankai' : 'biaogeshouqi'} className={'expand-icon'} onClick={() => onExpandIconClick(o.id)} />
          }
          {
            _.get(o, '_isParent') && _.get(o, '_isEmptyChildren') &&
            <div className={'empty-parent'} />
          }
          {
            !_.get(o, '_isParent') &&
            <div className={'empty-child'} />
          }
          {/* {
            _.get(o, '_isParent') &&
            <Tag color="blue">父</Tag>
          }
          {
            !_.get(o, '_isParent') &&
            <Tag color="gold">子</Tag>
          } */}
          <Tooltip title={showValue}>
            <div className="enter-detail-field" style={{ flex: 1 }} onClick={() => {
              setCurrentInfo({
                id: _.get(o, 'id'),
                mode: 'detail'
              })
            }}>
              {showValue || '-'}
            </div>
          </Tooltip>
          <ConcernedIcon
            left={8}
            type='TODOLIST'
            referenceId={o?.id}
            flag={_.get(o, 'perColl', 'N') === 'Y'}
          />
        </div>
      </div>
    }

    if(tableField === 'workflow_business.factorId'){
      const convertRule = _.find(convertCollection, (v,k) => k === tableField)
      const color = convertOptions(v, convertRule, 'color')
      return <Popover destroyTooltipOnHide={true}
                      content={<WorkFlowChangePop businessId={workFlowId} close={()=>setWorkFlowId(null)}
                                                  refreshList={refreshList} funcCode={FUNC_CODE} businessType={'todo'}/>}
                      onOpenChange={visible => {
                        if(!visible){
                          setWorkFlowId(null)
                        }
                      }}
                      trigger={'click'}
                      placement="bottom"
                      open={o.id === workFlowId}
      >
        <div className={'common-display-field cursor-pointer'} onClick={()=>setWorkFlowId(o.id)}
             style={{color: color, border: `1px solid ${color}`,padding: '0 8px', borderRadius: 4}}>
          {showValue || '-'}
        </div>
      </Popover>
    }

    if(tableField === 'todoListRecord.note'){
      let regex = /(<([^>]+)>)/ig
      return <div>
        {(showValue?.replace(regex, "")?.replace(/&nbsp;/gi, " ")) || '-'}
      </div>
    }

    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100
  }
}

export default function ToDoList(props) {
  const { location } = props;
  const { initId = null, ...defaultParams } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
  const [params, setParams] = useState()//_.assign(getInitParams(), !_.isNil(defaultParams) && defaultParams)
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [focusId, setFocusId] = useState()
  const [sortConfig, setSortConfig] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const [pageSize, setPageSize] = useState(100)
  const [onlyId, setOnlyId] = useState(null)
  const [factor, setFactor] = useState()
  const [upDate, setUpDate] = useReducer((x) => x + 1, 0)
  const [foldList, setFoldList] = useState([])
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const { doFetch: getOnlyId } = useGet()
  const { data: optionRes } = useGet(STATUS_URL)
  const [initRestSearch, setInitRestSearch] = useState()
  const [workFlowId, setWorkFlowId] = useState()
  const [showImport, setShowImport] = useState(false)

  const [statusOpt, overdueOpt] = useMemo(() => {
    if (_.isEmpty(optionRes)) return []
    return optionRes
  }, [optionRes])

  useEffect(() => {
    if (JSON.stringify(initRestSearch) === JSON.stringify(defaultParams)) return
    setInitRestSearch(defaultParams)
  }, [defaultParams, initRestSearch])

  const initSearchParams = useCallback(() => {
    return {
      ...initRestSearch,
      ...getInitParams()
    }
  }, [initRestSearch])

  const appendObj = useMemo(() => {
    return {collectionType: 'TODOLIST'}
},[])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const onExpandIconClick = useCallback((id) => {
    setFoldList(oldList => {
      if (_.includes(oldList, id)) {
        return _.filter(oldList, x => x !== id)
      } else {
        return _.concat(oldList, [id])
      }
    })
  }, [])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, sortable: true,
    columnsAppendParams: { setCurrentInfo, onExpandIconClick, foldList, statusOpt, overdueOpt, workFlowId, setWorkFlowId, refreshList },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        if(_.isEmpty(data)) return []
        setSortConfig(sort)
        const sortAllList = data.sort((a, b)=>sortFun(a, b, sort.column))
        const sortAllDirectionList = sort.direction === 'asc' ? sortAllList : _.reverse(_.clone(sortAllList))
        const sortFirstLevelDirectionList = _.filter(sortAllDirectionList, x => _.isNil(_.get(x,'pid')))
        let handleList = []
        _.forEach(sortFirstLevelDirectionList, item => {
          const childrenList = _.filter(sortAllDirectionList, x => _.get(x,'pid') === item.id)
          handleList.push(item)
          _.forEach(childrenList, x => {
            handleList.push(x)
          })
        })
        return handleList
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const handleList = useMemo(() => {
    let allList = showChildList || []
    // if(!_.isNil(sortConfig) && !isTree){
    //     const sortList = _.sortBy(showChildList, x => x[sortConfig.column])
    //     showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    // }
    const firstLevelList = _.filter(allList, x => _.isNil(_.get(x, 'pid')))
    let handleList = []
    _.forEach(firstLevelList, item => {
      let parentItem = { ...item }
      const childrenList = _.filter(allList, x => _.get(x, 'pid') === item.id)
      const childrenIdList = _.map(childrenList, x => x.id)
      parentItem = { ...parentItem, childrenIdList, _isParent: true, _isEmptyChildren: _.isEmpty(childrenList) }
      handleList.push(parentItem)
      _.forEach(childrenList, x => {
        handleList.push({ ...x, _isParent: false })
      })
    })
    return handleList
  }, [showChildList])

  const showList = useMemo(() => {
    return _.filter(handleList, x => !_.includes(foldList, _.get(x, 'pid')))
  }, [handleList, foldList])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <>
      <TextIconBtn icon='daoru' text='导入' onClick={() => setShowImport(true)} />
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      <TextIconBtn onClick={() => setOpen(true)} icon='tianjia' text='新增' />
    </>
  }, [exportViewList])

  useEffect(() => {
    if (open) getOnlyId('/test_case/productGetOnlyId').then(res => setOnlyId(res))
  }, [getOnlyId, open])

  return (
    <Fragment>
      <div className={'todo-list-page fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, optionsConfig, getInitParams: initSearchParams,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Box title={'跟进事项'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <Table {...{ options, showList, dataGridList, focusId }} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
      </div>
      {
        (open && !_.isNil(onlyId)) &&
        <FollowMattersDetail
          id={onlyId}
          funcCode={'23'}
          mode={'add'}
          close={() => { setOpen(false); setOnlyId(null) }}
          refresh={refreshList}
          linkUrl={`/todolist?initId=${onlyId}`}
          pid={currentInfo?.id}
          upDate={setUpDate}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'detail' &&
        <TodoListDetailDialog close={() => { setCurrentInfo(null)}} refreshViewList={refreshList} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, setFocusId, upDate }} />
      }
      {
        showImport &&
        <ImportApiDialog close={() => setShowImport(false)} template={`/field/download?flag=9`}
                         defaultOptions={getImportColumns()} refresh={refreshList} importUrl={'/todolist/file/upload'} title={'跟进事项'}
                         abnormal={''} showExport={false} />
      }
    </Fragment>
  )
}

function Table(props) {
  const { options, showList, dataGridList, focusId } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const onRowClick = useCallback((item) => {
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [dataGridList])

  useEffect(() => {
    if (focusId) onRowClick({ id: focusId })
  }, [focusId, onRowClick])

  return <DataGrid option={options} data={showList} onRowClick={onRowClick} className='todo-list-data-grid' />
}


function sortFun(a, b, bind) {
  if (_.isNil(a[bind])) return -1
  const aStr = isNil(a[bind]) ? '' : a[bind]
  const bStr = isNil(b[bind]) ? '' : b[bind]
  if(_.isNumber(a[bind]) && _.isNumber(b[bind])){
    return a[bind] - b[bind]
  }
  if(_.isNumber(a[bind]) ^ _.isNumber(b[bind])){
    return (_.toNumber(a[bind]) || 0) - (_.toNumber(b[bind]) || 0)
  }
  return aStr.localeCompare(bStr)
}

// 获取用户角色
import useGet from "rootnet-biz/es/hooks/useGet";
import {useMemo} from "react";
import _ from "lodash";

export default function usePosition(positionList){
    const {data: allPositionRes} = useGet('/common/uacUserRole')
    return useMemo(()=>{
        if(_.isEmpty(allPositionRes)) return []
        const allPosition = _.map(allPositionRes,'posId')
        return _.map(positionList, x => _.includes(allPosition, x))
    },[allPositionRes, positionList])
}
import React, {useEffect, useMemo, useState} from 'react';
import {Card, Button, DataGrid} from 'rootnet-ui'
import './ReqForecast.scss'
import ResourceImportRequirement
    from "../../resourceSchedule/resourceSummaryInfo/resourceImportRequirement/ResourceImportRequirement";
import {Icon} from "../../../../components";
import _ from "lodash";
import {Radio, Tooltip} from "antd";
import {toDate,dateFormat} from 'rootnet-core/dateFormat'

const forecastTypeOptions = [
    { label: '预测一', value: '1' },
    { label: '预测二', value: '2' },
]

function getColumns(props){
    return [
        {header: '模块', bind: 'moduleName', width: 150, tooltip: true},
        {header: '岗位', bind: 'positionName', width: 80},
        {header: '职级', bind: 'techLevel', width: 60},
        {header: '所需投入（人日）', bind: 'investment', width: 110},
        {header: '预计开始时间', bind: 'beginDate', width: 100, convert: v => dateFormat('YYYY-MM-DD')(toDate.str14ToDate(v))},
        {header: '预计结束时间', bind: 'endDate', width: 100, convert: v => dateFormat('YYYY-MM-DD')(toDate.str14ToDate(v))},
        {header: '推荐人', bind: 'recommendUserName', width: 100,tooltip: true, convert:v =>  <Tooltip title={_.join(v, ', ')}>
                <span>{_.join(v, ', ')}</span>
            </Tooltip> },
    ]
}

function getOption(columns){
    return {
        autoFill: true,
        resizable: true,
        columns,
    }
}

function ReqForecast(props) {
    const {moduleOptions, summaryInfoExpanded, setSummaryInfoExpanded,formData} = props
    const [showImportDialog, setShowImportDialog] = useState(false)
    const [showForecast, setShowForecast] = useState(false)
    const [forecastType, setForecastType] = useState("1")
    const [forecastList, setForecastList] = useState()
    const [resourceCalculate, setResourceCalculate] = useState()
    const [saveInfo, setSaveInfo] = useState()

    useEffect(()=>{
        const positionSortList = ['demand','frontEndDev','backEndDev','otherDev','test']
        const showList = _.get(resourceCalculate,forecastType === "1" ?'demandInputByNow':'demandInputByRemain')
        const sortList =  _.sortBy(showList,[sortModule, sortPosition, sortRank])
        setForecastList(sortList)
        function sortModule(o){
            const moduleList = _.uniq(_.map(resourceCalculate,'module'))
            return _.findIndex(moduleList,x => x === _.get(o,'module'))
        }
        function sortPosition(o){
            return _.findIndex(positionSortList,x => x === _.get(o,'position'))
        }
        function sortRank(o){
            return _.get(o,'techLevel')
        }
    },[resourceCalculate,forecastType])

    const department = useMemo(()=>{
        return _.get(formData,'department')
    },[formData])

    useEffect(()=>{
        if(department === '0') return
        setSaveInfo(null)
        setResourceCalculate(null)
        setForecastList(null)
        setShowForecast(false)
    },[department])

    const extra = useMemo(()=>{
        return <div className={'extra-wrap flex center-y'}>
            <div className={'expand-text flex center-y'} onClick={()=>setSummaryInfoExpanded(x => !x)}>
                <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{transform: summaryInfoExpanded ?  'rotate(-90deg)' : 'rotate(90deg)'}}/>
                {summaryInfoExpanded? "收起":'展开'}
            </div>
            <Button primary className={'extra-import-btn'} onClick={()=>setShowImportDialog(true)}>导入需求</Button>
        </div>
    },[summaryInfoExpanded, setSummaryInfoExpanded])

    const option = useMemo(()=>{
        return getOption(getColumns())
    },[])

    return <div className={'req-forecast'}>
        <Card title={'排期预测'} className={'outer-card'} contentClass={'outer-card-content flex'} extra={extra}>
            {
                !showForecast &&
                <div className={'add-req-wrap flex center'} onClick={()=>setShowImportDialog(true)}>
                    <div className={'add-req-placeholder flex center-y'}>
                        <Icon name={'tianjia'} className={'add-req-icon'}/>
                        <div className="add-req-text">请导入要排期的需求</div>
                    </div>
                </div>
            }
            {
                showForecast &&
                <div className="req-forecast-summary flex">
                    <div className="req-forecast-summary-left flex-y">
                        <div className={'req-forecast-summary-item'}>需求总量：{_.get(resourceCalculate,'totalWorkload')}人日</div>
                        <div className={'req-forecast-summary-item'}>需求编号：{_.get(resourceCalculate,'storyId') || '-'}</div>
                        <div className={'req-forecast-summary-item'}>预测一：最高优先级从现在开始</div>
                        <div className={'req-forecast-summary-item time'}>最早交付时间： {dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(resourceCalculate,'deliverDateByNow')))}</div>
                        <div className={'req-forecast-summary-item'}>预测二：按照剩余资源排期</div>
                        <div className={'req-forecast-summary-item time'}>最早交付时间： {dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(resourceCalculate,'deliverDateByRemain')))}</div>
                    </div>
                    <div className="req-forecast-summary-right flex-y">
                        <Radio.Group options={forecastTypeOptions} value={forecastType} onChange={e => setForecastType(e.target.value)} optionType="button" buttonStyle="solid"/>
                        <DataGrid data={forecastList} option={option}/>
                    </div>
                </div>
            }
        </Card>
        {
            showImportDialog &&
            <ResourceImportRequirement close={()=>setShowImportDialog(false)}
                                       {...{moduleOptions,department,setShowForecast,setResourceCalculate,setShowImportDialog,saveInfo, setSaveInfo}}/>
        }
    </div>
}

export default ReqForecast;
import React, {useCallback, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import {Form, FormInput, CheckBox} from "rootnet-edit";
import './RequirementCopyDialog.scss'
import {useGet} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import {strParams} from "../../../utils/publicFun";

function RequirementCopyDialog(props) {
    const {copyInfo, close, refreshList,setCurrentInfo} = props
    const {id, isParent} = copyInfo
    const [formData, setFormData] = useState(copyInfo)
    const [formError, setFormError] = useState(copyInfo)
    const [copyChild, setCopyChild] = useState(false)
    const {doFetch} = useGet()

    const canSubmit = useMemo(()=>{
       return _.every(formError, _.isNil)
    },[formError])

    const submit = useCallback(()=>{
        const params = {
            id,
            title: _.get(formData,'title'),
            sonStory: copyChild? '0' : null
        }
        doFetch('/story/copy?'+strParams(params)).then(newId=>{
            Messager.show('复制成功', { icon: 'success' })
            if(setCurrentInfo){
                setCurrentInfo({
                    id: newId,
                    mode: 'detail'
                })
            }
            close()
            refreshList()
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    },[id, doFetch, close,refreshList,formData,copyChild,setCurrentInfo])

    return <Dialog className={'requirement-copy-dialog'} header={'复制需求'} confirmButtonText={'保存'} cancel={close} confirm={submit} confirmButtonDisabled={!canSubmit}>
        <Form value={formData} onChange={setFormData} error={formError} onError={setFormError}>
            <FormInput bind={'title'} label={'标题'} required horizontal componentWidth={400}/>
        </Form>
        {
            isParent &&
            <CheckBox value={copyChild} onChange={setCopyChild}>复制子需求</CheckBox>
        }
    </Dialog>
}

export default RequirementCopyDialog;
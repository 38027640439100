import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './UsedProduct.scss'
import {useGet} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import {uniqKeyFor} from "../../../../../project_share/utils/utils";
import {DataGrid} from 'rootnet-ui'
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import {TextIconBtn} from "../../../../common/TextIconBtn";
import {Icon} from "../../../../../components";
import UsedProductDetail from "./usedProductDetail/UsedProductDetail";
import DelMessage from "../../../../../components/DelMessage";
import convertOptions from "../../../../common/ConvertOptions";
import {strParams} from "../../../../../utils/publicFun";
import {Box} from "../../../../common/commonComponent";

const { str14ToDate } = toDate

const YesNoOptions = [
    {value: 'Y', text: '是'},
    {value: 'N', text: '否'},
]

function getColumns(props){
    const {foldProductList, onFoldIconClick, setCurrentInfo, setDelId, allUserRes} = props
    return [
        { header: '产品名称', bind: '_product', width: 150, tooltip: true, convert: v => {
            const isFold = _.includes(foldProductList,v)
            return _.isNil(v)?null:<div className={'product-wrap flex center-y'}>
                <Icon name={isFold?'biaogezhankai':'biaogeshouqi'} className={'expand-icon'} onClick={()=>onFoldIconClick(v)}/>
                <div className="product-name">{v}</div>
            </div>
            }},
        { header: '子产品名称', bind: 'subSysName', width: 200, tooltip: true,
            convert: (v, o) => <div className={'enter-detail-field-wrap flex center-y'}>
                <div className="enter-detail-field" onClick={()=>setCurrentInfo({
                    id: o.id,
                    mode: 'edit'
                })}>{v}</div>
                <Icon name={'shanchu'} className="del-icon" onClick={()=>setDelId(o.id)}/>
            </div>},
        { header: '启用时间', bind: 'publicDate', width: 100, tooltip: true, convert: v => dateFormat('YYYY-MM-DD')(str14ToDate(v))},
        { header: '是否过期', bind: 'overdue', width: 60, tooltip: true, convert: v => convertOptions(v, YesNoOptions)},
        { header: '对接券商', bind: 'extConntoBrokers', width: 150, tooltip: true},
        { header: '创建人', bind: 'createUser', width: 100, tooltip: true, convert:v => convertOptions(v, allUserRes,'userName','userAccount')},
        { header: '创建时间', bind: 'createTime', width: 150, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(str14ToDate(v))},
    ]
}

function getOptions(cols){
    return {
        fixedLeft: 1,
        resizable: true,
        columns: cols,
        virtualized: true,
        autoFill: true,
    }
}

function UsedProduct(props) {
    const {id, allUserRes} = props
    const {data: listRes, doFetch: getList} = useGet()
    const [list, setList] = useState([])
    const [foldProductList, setFoldProductList] = useState([])
    const [currentInfo, setCurrentInfo] = useState()
    const [delId, setDelId] = useState()

    const refreshList = useCallback(()=>{
        if(_.isNil(id)) return
        getList('/use/product/list?id='+id)
    },[id, getList])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    useEffect(()=>{
        if(_.isNil(listRes)) return
        let allList = []
        _.forEach(listRes, (subProductList, product) => {
            const parentKey = uniqKeyFor()
            const pushItem = {
                _level: 0,
                _key: parentKey,
                _product: product,
            }
            allList.push(pushItem)
            _.forEach(subProductList, item => {
                const pushItem = {
                    _level: 1,
                    _parentKey: parentKey,
                    _parentProduct: product,
                    ...item,
                }
                allList.push(pushItem)
            })
        })
        setList(allList)
    },[listRes])

    const showList = useMemo(()=>{
        return _.filter(list, x => {
            return !_.includes(foldProductList, _.get(x, '_parentProduct'))
        })
    },[list, foldProductList])

    const onFoldIconClick = useCallback((key)=>{
        setFoldProductList(oldList => {
            if(_.includes(oldList,key)){
                return _.filter(oldList, x => x !== key)
            }else{
                return _.concat(oldList, [key])
            }
        })
    },[])

    const option = useMemo(()=>{
        return getOptions(getColumns({foldProductList, onFoldIconClick, setCurrentInfo, setDelId, allUserRes}))
    },[foldProductList, onFoldIconClick, allUserRes])

    const extra = useMemo(()=>{
        return <TextIconBtn icon={'tianjia'} text='添加' className={'add-btn'} onClick={()=>setCurrentInfo({mode: 'add'})}/>
    },[])

    return <div className={'used-product flex-y'}>
        <Box title={'使用产品'} className='flex-y x-card-singlegrid' extra={extra} data={showList}>
            <DataGrid data={showList} option={option}/>
        </Box>
        {
            !_.isNil(currentInfo) &&
            <UsedProductDetail customerOnlyId={id} close={()=>setCurrentInfo(null)} {...{currentInfo,refreshList}}/>
        }
        {
            !_.isNil(delId) &&
            <DelMessage close={()=>setDelId(null)} refresh={refreshList} method={'get'} url={'/use/product/delete?'+strParams({id:delId,customerOnlyId:id})}>
                确定删除？
            </DelMessage>
        }
    </div>
}

export default UsedProduct;
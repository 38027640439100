import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { Box } from '../../../../common/commonComponent'
import ViewArea from '../../../../common/view/ViewArea'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import convertOptions from '../../../../common/ConvertOptions'
import WorkFlowChangePop from '../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import ReqStageUpdateDialog from '../../../../stageMgt/reqStageUpdateDialog/ReqStageUpdateDialog'
import { Icon } from '../../../../../components'
import './index.scss'

const ENTER_DETAIL_FIELD = 'story_stage.title'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, workFlowId, setWorkFlowId, refreshList } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'enter-detail-field'} onClick={() => {
        setCurrentInfo({ mode: 'detail', id: o.id })
      }}>{showValue}</div>
    }
    if (tableField === 'workflow_business.factorId') {
      const convertRule = _.find(convertCollection, (v, k) => k === tableField)
      const color = convertOptions(v, convertRule, 'color')
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)}
          refreshList={refreshList} funcCode={'1526'} businessType={'stage'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: color, border: `1px solid ${color}`, padding: '0 8px', borderRadius: 4, cursor: 'pointer' }}>
          {showValue || '-'}
        </div>
      </Popover>
    }

    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '050302'

export default function PlanStageLog(props) {
  const { id, close } = props
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentInfo, setCurrentInfo] = useState()
  const [workFlowId, setWorkFlowId] = useState()

  // const replaceParams = useMemo(() => {
  //   if (_.isNil(id)) return
  //   return _.assign({}, { 'story_stage.storyId': id })
  // }, [id])

  const appendObj = useMemo(() => {
    return { factorViewSql: `story_stage.storyId='${id}'` }
  }, [id])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, appendObj })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, workFlowId, setWorkFlowId, refreshList }, })

  const header = useMemo(() => {
    return <div className='header-title flex'>
      <div className='log-title'>测试阶段列表</div>
      <div className='hint-btn'>
        <Tooltip title='过滤测试阶段：测试方案设计与评审、测试用例设计与评审、迭代/需求验收测试、系统测试、回归测试'>
          <span><Icon name='bangzhu' /></span>
        </Tooltip>
      </div>
    </div>
  }, [])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
    </div>
  }, [])

  return (
    <Dialog
      header={header}
      confirmButtonVisible={false}
      cancel={close}
      className='plan-stage-log'
    >
      <div className={'plan-stage-content fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Box title={'测试阶段列表'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <DataGrid option={options} data={dataGridList} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
      </div>
      {
        currentInfo &&
        <ReqStageUpdateDialog close={() => setCurrentInfo(null)}
          {...{ currentInfo, refreshList }} />
      }
    </Dialog>
  )
}

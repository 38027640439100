import _ from 'lodash'
import React from 'react'
import { dateFormat } from 'rootnet-core/dateFormat'
import { Display, FormInput } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import { uniqKeyFor } from '../../../../../project_share/utils/utils'
import './CardModule.scss'

function tasks(data, o) {
  const findData = _.find(data, item => item?.id === o?.id)
  return findData?.addRes1
}

const HFormInput = (props) => <FormInput labelWidth={50} horizontal component={Display} {...props} />

export default function CardModule(props) {

  const { data, user, isFuncCodeAdd, isFuncCodeEdit, setModeAll, appendData, convertCollection } = props

  return (
    <>
      {
        <div className="template-card-group flex">
          {
            _.map(data, item => {
              return <div className="card-item card-detail flex-y" key={item.id} onClick={() => setModeAll({ mode: isFuncCodeEdit ? 'edit' : 'detail', data: item })}>
                <div className="card-header flex center-y">
                  <div className="card-title">{item.templateName}</div>
                  <div>
                    {isFuncCodeAdd && <Icon name='tongjihuizong' className='del-icon' onClick={(e) => { e.stopPropagation(); setModeAll({ mode: 'copy', data: item }) }} />}
                    {user === item.createUser && <Icon name='shanchu2' className='del-icon' onClick={(e) => { e.stopPropagation(); setModeAll({ mode: 'delete', id: item.id }) }} />}
                  </div>
                </div>
                <div className='content' >
                  <HFormInput label='类型' value={translate(convertCollection, 'projectType', item.projectType)} />
                  <HFormInput label='创建人' labelWidth={65} value={translate(convertCollection, 'createUser', item.createUser)} />
                  <HFormInput label='任务数' value={tasks(appendData, item)} />
                  <HFormInput label='创建时间' labelWidth={65} value={dateFormat('YYYY-MM-DD HH:MM:SS', new Date(item.createTime))} />
                  <HFormInput label='状态' value={item.status === 'Y' ? '启用' : '禁用'} />
                  <HFormInput label='更新时间' labelWidth={65} value={dateFormat('YYYY-MM-DD HH:MM:SS', new Date(item.updateTime))} />
                </div>
              </div>
            }).concat(new Array(4).fill(0).map(() => (<div key={uniqKeyFor()} className='empty-item' />)))
          }
        </div>
      }
    </>
  )
}

// function isReplace(params) {
//   return _.isNil(params) ? '-' : params
// }

function translate(data, name, value) {
  const list = _.get(data, `project_template.${name}`, [])
  return _.get(_.find(list, o => o.value === value), 'text')
}
import React, { useCallback } from 'react'
import cls from 'clsx'
import _ from 'lodash'
import { Icon } from '../../../../../components'
import './DataSwitch.scss'

export default function DataSwitch(porps) {
  const { index, data, switchList, setCurrentId = null, isFuncCodeEdit = false, getType } = porps

  const switchData = useCallback((flag) => {
    if (flag) {
      if (index === 1) return
      !_.isNil(setCurrentId) && setCurrentId(_.get(data, `${[index - 2]}.id`))
      switchList(_.assign({}, { mode: isFuncCodeEdit ? 'edit' : 'detail', type: getType(data[index - 2]), data: data[index - 2] }))
    }
    else {
      if (index === _.size(data)) return
      !_.isNil(setCurrentId) && setCurrentId(_.get(data, `${[index]}.id`))
      switchList(_.assign({}, { mode: isFuncCodeEdit ? 'edit' : 'detail', type: getType(data[index]), data: data[index] }))
    }
  }, [index, setCurrentId, data, switchList, isFuncCodeEdit, getType])

  return (
    <div className='data-switch'>
      <div className={cls('last', { disable: index === 1 })} onClick={() => switchData(true)}><Icon name='xiangqian' /></div>
      <div className='location'>{index}/{_.size(data)}</div>
      <div className={cls('next', { disable: index === _.size(data) })} onClick={() => switchData(false)}><Icon name='xianghou' /></div>
    </div>
  )
}

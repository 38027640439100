import React from 'react';
import './Icon.scss';
import { P_Clsx } from "../utils/utils-sp";

/*
维护者：mw
时间: 2020-06-18
*/
export default function Icon(props) {
    const { name, onClick, className, style, rotate, symbol } = props;
    const icon_name = name ? name : "help";
    if (symbol) {
        return <svg className="symbol-icon" aria-hidden="true" onClick={onClick} style={style}>
            <use xlinkHref={`#icon-${icon_name}`}></use>
        </svg >
    }
    const cn = P_Clsx("icon", "iconfont", `icon-${icon_name}`, { rotate: rotate }, className);
    return <i className={cn} style={style} onClick={onClick} />
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {usePost} from "rootnet-biz/es/hooks";
import {Box} from "../../../../../common/commonComponent";
import {DataGrid} from "rootnet-ui";
import './VersionRelateComponent.scss'
import {TextIconBtn} from "../../../../../common/TextIconBtn";
import {Popover} from "antd";
import {Icon} from "../../../../../../components";
import _ from 'lodash'
import {CheckBox} from "rootnet-edit";
import VersionSelectBranchDialog from "./versionSelectBranchDialog/VersionSelectBranchDialog";
import convertOptions from "../../../../../common/ConvertOptions";

function getColumns(props){
    const {list, isSelectedAll, selectedIdList, setSelectedIdList, setShowDialogInfo, allUserRes} = props
    return [
        { header: <CheckBox value={isSelectedAll} onClick={()=>{
            if(isSelectedAll){
                setSelectedIdList([])
            }else{
                setSelectedIdList(_.map(list,'programId'))
            }
        }}/>
            , width: 40, align: 'center',stretch: false, convert: (v, o, i) => {
                const programId = _.get(o,'programId')
                return <CheckBox value={_.includes(selectedIdList, programId)} onChange={()=>{
                    if(_.includes(selectedIdList, programId)){
                        setSelectedIdList(old => _.filter(old, x => x !== programId))
                    }else{
                        setSelectedIdList(old => _.concat(old, [programId]))
                    }
                }}/>
            }},
        { header: '组件名称', bind: 'appName', width: 200, tooltip: true },
        { header: 'git项目', bind: 'gitUrl', width: 300, tooltip: true },
        { header: 'dev分支', bind: 'branchName', width: 300, tooltip: true },
        { header: 'release分支', bind: 'releaseBranchName', width: 300, tooltip: true },
        { header: '创建人', bind: 'creator', width: 130, tooltip: true, stretch: false, convert: v => convertOptions(v, allUserRes, 'userName','userAccount') },
        { header: '创建时间', bind: 'createTime', width: 150, tooltip: true, stretch: false },
        { header: '操作', bind: '', width: 180, tooltip: true, align: 'center',stretch: false,
            convert: (v, o) => {
            return <div className={'all-operate flex center-y'}>
                <Popover overlayClassName={'version-relate-component-select-branch-pop'} placement={'bottom'} content={<div className={'flex-y'}>
                    <div className={'relate-branch-item flex center-y'} onClick={()=>{
                        setShowDialogInfo({
                            module: 'createBranch',
                            idList: [o?.programId],
                            type: 0,
                        })
                    }}>
                        <Icon name={'line-gitbranchfenzhi'} className={'relate-branch-icon'}/>
                        dev分支
                    </div>
                    <div className={'relate-branch-item flex center-y'} onClick={()=>{
                        setShowDialogInfo({
                            module: 'createBranch',
                            idList: [o?.programId],
                            type: 1,
                        })
                    }}>
                        <Icon name={'line-gitbranchfenzhi'} className={'relate-branch-icon'}/>
                        release分支
                    </div>
                </div>}>
                    <span>创建</span>
                </Popover>
                <Popover overlayClassName={'version-relate-component-select-branch-pop'} placement={'bottom'} content={<div className={'flex-y'}>
                    <div className={'relate-branch-item flex center-y'} onClick={()=>{
                        setShowDialogInfo({
                            module: 'relateBranch',
                            idList: [o?.programId],
                            type: 0,
                        })
                    }}>
                        <Icon name={'line-gitbranchfenzhi'} className={'relate-branch-icon'}/>
                        dev分支
                    </div>
                    <div className={'relate-branch-item flex center-y'} onClick={()=>{
                        setShowDialogInfo({
                            module: 'relateBranch',
                            idList: [o?.programId],
                            type: 1,
                        })
                    }}>
                        <Icon name={'line-gitbranchfenzhi'} className={'relate-branch-icon'}/>
                        release分支
                    </div>
                </div>}>
                    <span>关联</span>
                </Popover>
            </div>
            }},
    ]
}

function getOptions(columns){
    return {
        nilText: '-',
        emptyText: '-',
        resizable: true,
        autoFill: true,
        virtualized: true,
        fixedLeft: 2,
        fixedRight: 1,
        columns,
    }
}

function VersionRelateComponent(props) {
    const {currentId} = props
    const allUserRes = useMemo(()=>JSON.parse(localStorage.getItem('all_user')),[])
    const {data: list, doFetch: getList, loading, error} = usePost()
    const [selectedIdList, setSelectedIdList] = useState([])
    const [showDialogInfo, setShowDialogInfo] = useState()

    const isSelectedAll = useMemo(()=>{
        if(_.isEmpty(list)) return false
        return _.size(selectedIdList) === _.size(list)
    },[selectedIdList, list])

    const refreshList = useCallback(()=>{
        if(_.isNil(currentId)) return
        getList('/devops/uprods/product/program/getAllProgram',{releaseId: currentId}).then(()=>{
            setSelectedIdList([])
        })
    },[getList, currentId])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const option = useMemo(()=>{
        return getOptions(getColumns({list, isSelectedAll, selectedIdList, setSelectedIdList, setShowDialogInfo, allUserRes}))
    },[list, isSelectedAll, selectedIdList, allUserRes])

    const extra = useMemo(()=>{
        return <div className={'extra-wrap flex center-y'}>
            <TextIconBtn icon={'shuaxin1'} text={'刷新列表'} onClick={refreshList}/>
            <TextIconBtn icon={'tianjia'} text={'创建dev分支'} disabled={_.isEmpty(selectedIdList)} onClick={()=>{
                setShowDialogInfo({
                    module: 'createBranch',
                    idList: selectedIdList,
                    type: 0,
                })
            }}/>
            <TextIconBtn icon={'tianjia'} text={'创建release分支'} disabled={_.isEmpty(selectedIdList)} onClick={()=>{
                setShowDialogInfo({
                    module: 'createBranch',
                    idList: selectedIdList,
                    type: 1,
                })
            }}/>
        </div>
    },[selectedIdList, refreshList])

    return <Box className='version-relate-component flex-y x-card-singlegrid' title='关联组件列表' data={list}
                extra={extra} loading={loading} error={error}>
        <DataGrid data={list} option={option} />
        {
            !_.isNil(showDialogInfo) &&
            <VersionSelectBranchDialog close={()=>setShowDialogInfo(null)} releaseBizId={currentId} componentList={list}
                                       {...{showDialogInfo, refreshList}}/>
        }
    </Box>
}

export default VersionRelateComponent;
import React, { useMemo, useState, useCallback, useContext } from 'react'
import _, { isNil } from 'lodash'
import { Popover } from 'antd'
import { BaseTable, useTablePipeline, features } from 'ali-react-table'
import { Box } from '../../../common/commonComponent'
import { projectSummaryInit } from '../../components/formatData'
import convertOptions from '../../../common/ConvertOptions'
import { TextIconBtn } from '../../../common/TextIconBtn'
import ProjectManpowerSummaryDetailLog from '../projectManpowerSummaryDetailLog'
import { ScheduleManagementContext } from '../../../common/Context'
import './index.scss'
import { strParams } from '../../../../utils/publicFun'
import { dateFormat } from 'rootnet-core/dateFormat'

const EXPORT_URL = '/humanManpower/downLoad'

function getColumns(props) {
  const { monthArray, itemClassOpt, setOpen, projectAllOpt, allUserRes } = props

  const columnHead = [
    { name: '#', code: '', width: 40, lock: true, align: 'center', render: (v, o, i) => i + 1 },
    { name: '项目/事务', code: 'projectId', width: 150, lock: true, render: v => convertOptions(v, projectAllOpt) || v, features: { sortable: true } },
    {
      name: '项目预算人力（人日）', code: 'useday', width: 155, lock: true, align: 'center', features: { sortable: true }
    },
    {
      name: '管道预估合计（人日）', code: 'pipelineEstimatesTotal', width: 165, lock: true, align: 'center', render: (v, o) => {
        const estimate = _.toNumber(_.get(o, 'useday', 0))
        const total = _.toNumber(v)
        const isAffair = _.get(o, 'type') === '04'
        if (isAffair) return v
        return <span style={{ color: total > estimate ? 'red' : '' }}>{v}</span>
      }, features: { sortable: true }
    },
    {
      name: '预估类型', code: 'type', width: 100, lock: true, align: 'center', render: v => convertOptions(v, itemClassOpt), features: { sortable: true }
    },
    {
      name: '项目类型', code: 'userProjectTypeName', width: 100, lock: true, align: 'center', features: { sortable: true }
    },
    {
      name: '项目状态', code: 'userProjectStatusName', width: 100, lock: true, align: 'center', features: { sortable: true }
    },
    {
      name: '项目PO', code: 'projectMembersPO', width: 100, lock: true, align: 'center', render: v => {
        return convertOptions(v, allUserRes, 'userName', 'userAccount')
      }, features: { sortable: true }
    },
    {
      name: '占用评估合计', code: 'workloadOccupiedSummary', width: 115, lock: true, align: 'center', render: v => {
        return <span style={{ fontWeight: 'bold' }}>{v}</span>
      }, features: { sortable: true }
    },
  ]
  const columnMonth = _.map(monthArray, o => {
    return {
      name: o, code: `workloadOccupied,${o}`, width: 100, align: 'center', render: (v, d) => {
        if (isNil(v)) return v
        return <span
          style={{
            cursor: 'pointer',
            color: '#5477ff',
            padding: '0 12px',
            fontWeight: 700,
          }}
          onClick={() => {
            // setOpen(_.get(d, `manpowerPipelineId${o}`))
            setOpen({ ...d, evaluationDate: o })
          }}
        >{v}</span>
      }
      , features: { sortable: true }
    }
  })
  return _.concat(columnHead, _.compact(columnMonth))
}

const title = <div className='header flex'>
  <div className='title'>详细信息</div>
  {/* <div className='hint'>单位：人日</div> */}
</div>

export default function ProjectManpowerSummaryList(props) {
  const { data, loading, error, monthArray, projectAllOpt, allUserRes, itemClassOpt, parameter } = props
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const [open, setOpen] = useState()

  const listExport = useCallback((flag = false) => {
    const { displayMonth, ...res } = parameter
    const getParams = _.assign({}, res, {
      beginDate: dateFormat('YYYY-MM-DD', _.get(displayMonth, 'begin')),
      endDate: dateFormat('YYYY-MM-DD', _.get(displayMonth, 'end')),
      status: '01',
    })
    downloadGetProgress(`${EXPORT_URL}?${strParams(_.assign({}, getParams, flag === true && { downLoadFlag: true }))}`, flag === true ? '导出项目部门提报' : '导出列表')
  }, [downloadGetProgress, parameter])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <MoreExport {...{ listExport }} />
    </div>
  }, [listExport])

  const columns = useMemo(() => getColumns({ monthArray, itemClassOpt, setOpen, projectAllOpt, allUserRes }), [projectAllOpt, monthArray, itemClassOpt, allUserRes])

  const dataSource = useMemo(() => projectSummaryInit(data), [data])

  const pipeline = useTablePipeline()
    .input({ dataSource, columns })
    .use(
      features.sort({
        mode: 'single',
        highlightColumnWhenActive: false,
      })
    )

  return (
    <div className='project-manpower-summary-list'>
      <Box title={title} data={dataSource} extra={extra} loading={loading} error={error} className='flex-y x-card-singlegrid'>
        <BaseTable {...pipeline.getProps()}
          isLoading={loading}
          style={{
            '--row-height': "34px",
            '--header-bgcolor': '#F5F5F5',
            '--header-color': '#252525'
          }}
        />
      </Box>
      {
        open &&
        <ProjectManpowerSummaryDetailLog {...{ initParams: open, itemClassOpt, projectAllOpt }} close={() => setOpen(null)} />
      }
    </div>
  )
}

function MoreExport(props) {
  const { listExport } = props
  const [clicked, setClicked] = useState(false)

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={listExport}>导出列表</div>
      <div onClick={() => listExport(true)}>导出项目部门提报</div>
    </div>
  }, [listExport])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='导出' onClick={() => setClicked(x => !x)} />
    </Popover>
  )
}

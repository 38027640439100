import React, { useMemo, useState, useCallback, useReducer, useEffect, useContext } from 'react';
import _ from "lodash";
import { DataGrid, Pagination, Button, Messager } from "rootnet-ui";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import { Box } from "../common/commonComponent";
import ViewArea from "../common/view/ViewArea";
import GlobalSearch from './components/globalSearch';
import { pathSearchFor } from '../../utils/publicFun';
import TrackingDetailDialog from './components/trackingDetailDialog';
import { TRACKING_STATUS_COLOR } from './components/trackingColor';
import './index.scss'
import { TextIconBtn } from "../common/TextIconBtn";
import { Popover } from "antd";
import WorkFlowChangePop from "../workFlow/workFlowChangePop/WorkFlowChangePop";
import { ScheduleManagementContext } from '../common/Context';
import ExportScriptsLog from './components/exportScriptsLog';
import { useFuncCode } from '../common/commonMethod';
import ConcernedIcon from '../common/concernedIcon';

const ENTER_DETAIL_FIELD = 'tracerTitle'
const EDITABLE_FIELD = ['tracerTitle']

const SPECIAL_FIELD_CONVERT = {
  'bug_tracer.tracerState': (value, showValue) => {
    return <div style={{ color: TRACKING_STATUS_COLOR[value], border: `1px solid ${TRACKING_STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, workFlowId, setWorkFlowId, refreshList } = props
  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId === ENTER_DETAIL_FIELD ? false : true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[tableField])) {
      showValue = SPECIAL_FIELD_CONVERT[tableField](v, showValue)
    }
    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={FUNC_CODE} businessType={'tracer'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentInfo({ mode: 'detail', id: o.id })
        }}>{showValue}</div>
        <ConcernedIcon
          left={8}
          type='TRACE'
          referenceId={o?.id}
          flag={_.get(o, 'perColl', 'N') === 'Y'}
        />
      </div>
    }

  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 200
  }
}

const FUNC_CODE = '26'

export default function TrackingManagementMgt(props) {
  const { location } = props;
  const { isCS } = window.external
  const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(200)
  const [isLoading, setIsLoading] = useState(true)
  const [visible, setVisible] = useState(false) //全文检索
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [sortConfig, setSortConfig] = useState()
  const [focusId, setFocusId] = useState()
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const [workFlowId, setWorkFlowId] = useState()
  const [isExport] = useFuncCode(['2602'])
  const [selectItems, setSelectItems] = useState()

  const appendObj = useMemo(() => {
    return { collectionType: 'TRACE' }
  }, [])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const extraBtnArea = useMemo(() => {
    return <Button onClick={() => setVisible(true)} className={'search-btn'} primary>全文检索</Button>
  }, [])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { setCurrentInfo, workFlowId, setWorkFlowId, refreshList },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '研发任务管理')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        isExport &&
        <TextIconBtn icon='xiazai2' text='导出脚本文件' onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择后在进行操作', { icon: 'error' })
          setCurrentInfo({ mode: 'exportScripts' })
        }} />
      }
      {
        !isCS &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [exportViewList, isExport, isCS, selectItems])

  return (
    <div className={'tracking-management-mgt fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, extraBtnArea,
        }} />
      <Box title={'研发任务管理'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error} extra={extra}>
        <Table {...{ options, showList, dataGridList, focusId, setFocusId, selectItems, setSelectItems }} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      <GlobalSearch visible={visible} close={() => setVisible(false)} />
      {
        _.get(currentInfo, 'mode') === 'detail' && <TrackingDetailDialog close={() => setCurrentInfo(null)} refreshViewList={refreshList} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, setFocusId }} />
      }
      {
        _.get(currentInfo, 'mode') === 'exportScripts' &&
        <ExportScriptsLog tracerIds={_.map(selectItems, 'id')} close={() => { setCurrentInfo(null); setSelectItems(null); }} />
      }
    </div>
  )
}

function Table(props) {
  const { options, showList, dataGridList, focusId, setFocusId, selectItems, setSelectItems } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const onRowClick = useCallback((item) => {
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    setFocusId(item?.id)
    forceUpdate()
  }, [dataGridList, setFocusId])

  useEffect(() => {
    if (focusId) onRowClick({ id: focusId })
  }, [focusId, onRowClick])

  return <DataGrid option={options} data={showList} onRowClick={onRowClick} className='todo-list-data-grid' selection={selectItems} onSelectionChange={setSelectItems} />
}
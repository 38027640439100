import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import './ViewSortDialog.scss'
import { MenuOutlined } from '@ant-design/icons';
import {Switch, Table} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import _ from 'lodash'
import {usePost} from "rootnet-biz/es/hooks";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }}
    />
));

function getColumns(props){
    const {changeShowOuter} = props
    return [
        {
            title: '',
            dataIndex: 'sort',
            width: 40,
            align: 'center',
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '显示在外层',
            dataIndex: 'showOuter',
            width: 100,
            align: 'center',
            render: (value, obj) => {
                return <Switch checked={value} onClick={checked => changeShowOuter(checked, obj)}/>
            }
        },
        {
            title: '视图名称',
            dataIndex: 'subType',
            className: 'drag-visible',
        },
    ]
}

const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

function ViewSortDialog(props) {
    const {viewList, funcCode, refreshViewList, close, closeViewPop} = props
    const [dataSource, setDataSource] = useState([]);
    const {doFetch: submitPost} = usePost()

    useEffect(()=>{
        setDataSource(viewList || [])
    },[viewList])

    const changeShowOuter = useCallback((checked, obj)=>{
        setDataSource(oldList => {
            const tempList = _.clone(oldList)
            _.forEach(tempList, x => {
                if(x.id === obj.id){
                    x['showOuter'] = checked
                }
            })
            return tempList
        })
    },[])

    const columns = useMemo(()=>{
        return getColumns({changeShowOuter})
    },[changeShowOuter])

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const DraggableContainer = (props) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    function onSortEnd({ oldIndex, newIndex }){
        if (oldIndex !== newIndex) {
            const newList = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            setDataSource(newList)
        }
    }

    const submit = useCallback(()=>{
        const listInfo = _.map(dataSource, (x, index) => ({
            viewId: x.id,
            showOuter: x.showOuter,
            sort: index + 1,
        }))
        const submitParams = {
            funcCode,
            list: listInfo
        }
        submitPost('/UserSetting/setViewSort',submitParams).then(() => {
            Messager.show('修改成功', { icon: 'success' })
            refreshViewList()
            closeViewPop()
            close()
        }).catch(err => {
            Messager.show(err.Message, {icon: 'error'})
        })
    },[funcCode, dataSource, submitPost, refreshViewList, close, closeViewPop])

    return <Dialog header={'视图排序'} className={'view-sort-dialog'} confirm={submit} cancel={close}>
        <div className={'view-sort-wrap'}>
            <Table
                size={'small'}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowKey="id"
                scroll={{y: 'calc(100% - 38px)'}}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
        </div>
    </Dialog>
}

export default ViewSortDialog;
import React, { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { MessageBox, Messager } from 'rootnet-ui'
import { dateFormat } from 'rootnet-core/dateFormat'
import { FormInput } from 'rootnet-edit'
import { N2 } from 'rootnet-core/format'
import { Icon } from '../../../components'
import { useApi } from '../../../utils/hook'
import { TabContext } from '../../common/Context'

const ADD_URL = '/costrate/add'

export default function EditBox(props) {
  const { refresh: costRateRefresh } = useContext(TabContext)
  const { val, data, refresh } = props
  const [value, setValue] = useState()
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const { doFetch } = useApi()

  useEffect(() => {
    setValue(val)
  }, [val])

  const confirm = useCallback(() => {
    const effectiveDate = dateFormat('YYYY-MM-DD', new Date())
    doFetch(ADD_URL, 'post', { ..._.pick(data, ['department', 'techLevel']), price: value, status: 'Y', effectiveDate })
      .then(() => {
        refresh()
        costRateRefresh()
        setShow(false)
        setOpen(false)
        Messager.show('保存成功', { icon: 'success' })
      })
      .catch(err => {
        setShow(false)
        setOpen(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [costRateRefresh, data, doFetch, refresh, value])

  const keydown = useCallback((e) => {
    if (e.keyCode === 13 && val !== value) {
      confirm()
    } else if (e.keyCode === 27) {
      setShow(false)
      setOpen(false)
      setValue(val)
    }
  }, [confirm, val, value])

  const noChange = useCallback(() => {
    if (val === value) setOpen(false)
    else setShow(true)
  }, [val, value])

  useEffect(() => {
    if (show) {
      window.addEventListener('keydown', _.debounce(keydown, 300))
      return () => window.removeEventListener('keydown', keydown)
    }
  }, [keydown, show])

  if (!open) {
    return <span onClick={() => setOpen(true)} className='convertPrice'>
      {N2(val)}<Icon name='bianji2' />
    </span>
  }

  return (
    <>
      <div className='edit-Box-input'>
        <FormInput
          autoFocus
          digit={2}
          type='number'
          value={value}
          format={v => N2(v)}
          onChange={setValue}
          onBlur={noChange}
          onEnter={_.debounce(noChange, 300)}
          className='change-input'
        />
      </div>
      {
        show && <MessageBox
          header='提示'
          confirm={_.debounce(confirm, 300)}
          className='convert-price-edit'
          cancel={() => { setShow(false); setOpen(false); setValue(val) }}
        >
          确认保存么？
        </MessageBox>
      }
    </>
  )

}

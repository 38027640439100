import _ from 'lodash'
import { Input, DatePicker, Display } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import DisplaySelect from '../../../../common/displaySelect/DisplaySelect'
import convertOptions from '../../../../common/ConvertOptions'
import { isNil } from '../../../../appraise/components/method'

export const GLOBAL_CONST_URLS = [
  '/common/globalconst?globalConst=ProductLine', //产品线
  '/common/globalconst?globalConst=PRODUCTTYPE2', // 版本类型
  '/common/globalconst?globalConst=PRODUCTTYPE', // 版本性质
  '/common/globalconst?globalConst=VERSIONPRESTATE', // 版本状态
]

export const OPTIONS_URLS = [
  '/verQuery/queryCustOptions', // 发布客户系统
]

export function formList(props) {
  const { productLineOpt, typeOpt, natureOpt, stateOpt, versionListOpt, allUserRes, mode } = props
  const isDetail = mode === 'detail'
  const isAdd = mode === 'add'
  const versionListOpts = _.concat([{ value: '无基础版本', text: '无基础版本' }], versionListOpt)

  return _.compact([
    {
      required: true,
      label: '名称',
      bind: 'releasename',
      component: isDetail ? Display : Input,
    },
    !isAdd && {
      required: true,
      label: 'ID',
      bind: 'releaseid',
      component: Display,
    },
    {
      clear: true,
      search: true,
      label: '产品线',
      bind: 'productLine',
      component: isDetail ? Display : DisplaySelect
      ,
      options: productLineOpt,
      convert: v => convertOptions(v, productLineOpt),
    },
    {
      clear: true,
      search: true,
      label: '版本类型',
      bind: 'producttype2',
      component: isDetail ? Display : DisplaySelect
      ,
      options: typeOpt,
      convert: v => convertOptions(v, typeOpt),
    },
    {
      clear: true,
      search: true,
      label: '版本性质',
      bind: 'producttype',
      component: isDetail ? Display : DisplaySelect
      ,
      options: natureOpt,
      convert: v => convertOptions(v, natureOpt),
    },
    {
      required: true,
      search: true,
      label: '状态',
      bind: 'state',
      component: isDetail ? Display : DisplaySelect,
      icon: 'bangzhu',
      title: '仅“立项完成”状态，允许版本规划导入！',
      options: stateOpt,
      convert: v => convertOptions(v, stateOpt),
    },
    {
      labelWidth: 81,
      clear: true,
      search: true,
      label: '基础版本',
      bind: 'releasebasic',
      component: isDetail ? Display : DisplaySelect
      ,
      options: versionListOpts,
      convert: v => convertOptions(v, versionListOpts),
    },
    // {
    //   clear: true,
    //   search: true,
    //   multiple: true,
    //   label: '发布客户系统',
    //   bind: 'mulcustid',
    //   component: isDetail ? Display : DisplaySelect,
    //   options: customerSystemOpt,
    //   convert: v => {
    //     if (isNil(v)) return null
    //     let arr = []
    //     arr = v
    //     if (typeof v === 'string') arr = _.split(v, ',')
    //     convertOptions(v, customerSystemOpt)
    //     return _.join(_.map(arr, val => convertOptions(val, customerSystemOpt)), '，')
    //   },
    // },
    {
      multiple: true,
      required: true,
      search: true,
      label: '负责人',
      bind: 'functionarys',
      component: isDetail ? Display : UserSelect,
      convert: v => {
        if (isNil(v)) return null
        let users = []
        users = v
        if (typeof v === 'string') users = _.split(v, ',')
        return _.join(_.map(users, val => convertOptions(val, allUserRes, 'userName', 'userAccount')), '，')
      },
    },
    {
      clear: true,
      label: '计划发布日期',
      bind: 'issuedate',
      component: isDetail ? Display : DatePicker,
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      bindInConvert: v => toDate.str14ToDate(v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
  ])
}
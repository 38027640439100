import Axios from 'axios';
import gd from '../base/global';
import _ from 'lodash';

export function Initialize() {

    const settings = window.extends_settings;
    if (settings && settings.api_server && settings.api_server.enable) {
        if (_.isArray(settings.api_server.url)) {
            gd.servers = _.map(settings.api_server.url, o => {
                const u = o.trim();
                return Axios.create({ baseURL: u });
            })
        } else
            gd.servers = [Axios.create({ baseURL: settings.api_server.url })];
    }

    if (window.external && window.external.OptId && window.external.OptPwd) {
        gd.hybrid = true;
        gd.User = null;
        gd.task_autologin = { executed: false, username: window.external.OptId, password: window.external.OptPwd, encrypted: window.external.Encrypted === false ? false : true };
    }
}
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, Dialog, Messager, Pagination, Button } from "rootnet-ui";
import './ImportFromVersion.scss'
import { FormInput, Select } from "rootnet-edit";
import _ from "lodash";
import { Box } from "../../../../common/commonComponent";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import { usePost } from "rootnet-biz/lib/hooks";
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { selectOption } from '../../../../common/commonMethod';

function getColumns(props) {
    return [
        { selection: true },
        { header: '编号', bind: 'number', width: 180, tooltip: true, sortable: true, },
        { header: '标题', bind: 'title', width: 200, tooltip: true, sortable: true, },
        { header: '类型', bind: 'typeName', width: 120, tooltip: true, sortable: true, },
        { header: '创建人', bind: 'createUser', width: 120, tooltip: true, sortable: true, },
        {
            header: '创建时间', bind: 'createTime', width: 150, tooltip: true, sortable: true,
            convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.dateStringToDate(v))
        },
    ]
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const GLOBAL_CONST_OPTIONS_URLS = '/UserSetting/getUniversalInterfaces?code=releaseId&codeName=releaseId&tableName=View_ReleaseInfo_Version'


function ImportFromVersion(props) {
    const { close, currentId, snowId, refreshList, versionType = true, appendObj = {}, projectId } = props
    const [params, setParams] = useState(getInitParams())
    const [selectedList, setSelectedList] = useState()
    const { data: globalConstOptionsRes, doFetch: getOption } = useGet()
    const { data: listRes, doFetch: getList, error } = usePost()
    const { doFetch: submitPost } = usePost()
    const [listLoading, setListLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const { pageNum, pageSize, total, rows: list } = useMemo(() => listRes || {}, [listRes])

    useEffect(() => {//['/develop/release/list?versionType=0']
        const url = versionType ? [`${GLOBAL_CONST_OPTIONS_URLS}${_.isNil(projectId) ? '' : '&filter=projectId&filterParams=' + projectId}`] : ['/develop/release/list?versionType=1']
        getOption(url)
    }, [versionType, getOption, projectId])

    const [versionOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        if (versionType) {
            return _.map(globalConstOptionsRes, x => _.filter(convertGlobalConstOptions(x), o => o.value !== currentId))
        }

        return [_.filter(selectOption(_.filter(_.head(globalConstOptionsRes), o => o.stateKey === 'L'), ['releaseName', 'releaseId']), o => o.value !== currentId)]

    }, [globalConstOptionsRes, versionType, currentId])

    useEffect(() => {
        if (_.isNil(_.get(params, 'proReleaseId'))) return
        setListLoading(true)
        const searchParams = _.assign({}, {
            ...params,
            releaseId: currentId,
        }, !versionType && { type: 'PLAN' })
        getList('/releaseinfo/gerRelationInfo', searchParams).then(res => {
            const list = _.get(res, 'rows')
            setSelectedList(old => {
                const resultIdList = _.map(list, 'id')
                return _.map(old, item => {
                    if (_.includes(resultIdList, item.id)) {
                        return _.find(list, x => x.id === item.id)
                    } else {
                        return item
                    }
                })
            })
            setListLoading(false)
        }).catch(err => {
            setListLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [params, getList, currentId, versionType])

    const options = useMemo(() => {
        return getOptions(getColumns())
    }, [])

    const submit = useCallback(() => {
        if (submitLoading) return
        setSubmitLoading(true)
        const submitParams = {
            list: selectedList,
            releaseId: currentId,
            referenceId: snowId,
            selectVersion: _.get(params, 'proReleaseId'),
            ...appendObj
        }
        submitPost('/releaseinfo/addVersionAssociation', submitParams).then(() => {
            Messager.show(`导入成功`, { icon: 'success' })
            setSubmitLoading(false)
            refreshList()
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setSubmitLoading(false)
        })
    }, [submitPost, selectedList, snowId, close, submitLoading, refreshList, currentId, params, appendObj])

    return <Dialog header={<div>
        <span>{versionType ? '从版本回归' : '从预规划项目导入'}</span>
        <span style={{ color: '#BBBBBB', fontWeight: 'normal', paddingLeft: 8 }}>所选版本须为当前版本同所属项目下的版本，批量操作复制和回归！</span>
    </div>} className={'import-from-version-dialog'}
        cancel={close} footerVisible={false} >
        <div className={'import-from-version-content flex-y'}>
            <div>
                <FormInput value={_.get(params, 'proReleaseId')} onChange={(v) => {
                    setSelectedList([])
                    setParams(old => ({ ...old, pageNum: 1, proReleaseId: v }))
                }} label={versionType ? '选择版本' : '选择预规划项目'} required horizontal
                    component={Select} componentWidth={300} options={versionOptions} search />
            </div>
            <div className="table-wrap flex-y">
                <Box title={'清单列表'} className='flex-y x-card-singlegrid' data={list} loading={listLoading} error={error}>
                    <DataGrid option={options} data={list} selection={selectedList} onSelectionChange={setSelectedList} />
                    <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setParams(x => _.assign({}, x, { pageNum, pageSize }))
                        }} />
                </Box>

            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div className='text-hint'>
                {versionType ? '说明：数据已过滤升级路径中版本（含当前版本）已发布的内容。' : <>仅允许预规划项目状态为”<span style={{ color: 'red' }}>立项完成</span>“的数据导入，请预规划项目的负责人，及时变更状态！</>}
            </div>
            <div className="btn-group flex">
                <Button normal onClick={close}>取消</Button>
                <Button primary onClick={submit} disable={_.isEmpty(selectedList)}>导入版本</Button>
            </div>
        </div>
    </Dialog>
}

export default ImportFromVersion;
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'

export function getTreeOpt(data) {
  const filterData = _.filter(data, x => isNil(x.pId))
  const filterList = _.filter(data, x => !isNil(x.pId))
  const distinguishData = _.groupBy(filterList, x => x.pId)
  return _.map(filterData, item => {
    const children = _.find(distinguishData, (v, k) => k === item.interiorId)
    return _.assign({
      text: item.displayName,
      value: item.interiorId,
      displayFlag: item.displayFlag,
    }, !_.isEmpty(children) && { _disabled: true, children: tier(children, distinguishData) })
  })
}

function tier(data, allData) {
  return _.map(data, item => {
    const children = _.find(allData, (v, k) => k === item.interiorId)
    return _.assign({
      text: item.displayName,
      value: item.interiorId,
    }, !_.isEmpty(children) && { _disabled: true, children: tier(children, allData) })
  })
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DataGrid} from "rootnet-ui";
import convertOptions from "../../../../../common/ConvertOptions";
import {useGet, usePost} from "rootnet-biz/lib/hooks";
import _ from "lodash";
import {gd} from "rootnet-core/base";
import {errorTips, infoTips, successTips} from "../../../../../../utils/messagerTips";
import TraceRunPipelineBranchSelect from "./traceRunPipelineBranchSelect/TraceRunPipelineBranchSelect";
import {Icon} from "../../../../../../components";
import './TraceRelatePipelineList.scss'
import clsx from "clsx";
import {Tag} from "antd";

const STATUS_COLOR = {
    0: '#165DFF',
    1: '#00A950',
    2: '#F60421',
    3: '#F76A00',
    4: '#97AFD0',
}

function getColumns(props){
    const {allUserRes, pipelineTypeOptions, findBranch, stopPipeline, pipelineStateOptions, devOpsUrl} = props

    return [
        { header: '流水线名称', bind: 'pipelineName', width: 180, tooltip: true,
            convert: (v, o) => <div className={'flex center-y'}>
                <div className={'status-dot'} style={{height: 8, width: 8, borderRadius: '50%',
                    backgroundColor: _.get(STATUS_COLOR,o?.state,'#97AFD0'), marginRight: 6}}/>
                <div className={'can-enter-text'} onClick={()=>{
                    if(_.isNil(o?.historyId)){
                        infoTips('当前流水线无历史运行记录')
                    }else{
                        window.open(`${devOpsUrl}/#/login?ssoToken=${gd.User.uuid}&hiddenLoginLoading=true&return=${encodeURIComponent(`/pipelineManager?initId=${o?.id}&initTab=runHistory&initHistoryId=${o?.historyId}&historyDetailFullScreen=true`)}`,'_blank')
                    }
                }}>
                    {v}
                </div>
            </div> },
        { header: '流水线分类', bind: 'pipelineType', width: 180, tooltip: true, convert: v => convertOptions(v, pipelineTypeOptions) },
        { header: '源分支', bind: 'sourceBranchName', width: 180, tooltip: true },
        { header: '目标分支', bind: 'targetBranchName', width: 180, tooltip: true },
        { header: '运行状态', bind: 'state', width: 120, tooltip: true,
            convert: v => {
                const color = {
                    0: 'processing',
                    1: 'success',
                    2: 'error',
                    3: 'warning',
                }
                return <Tag color={_.get(color,v)}>
                    {convertOptions(v, pipelineStateOptions, 'text', 'value', {emptyText: '未执行'})}
                </Tag>
            } },
        { header: '运行人', bind: 'creator', width: 120, tooltip: true, stretch: false, convert: v => convertOptions(v, allUserRes, 'userName','userAccount') },
        { header: '最后运行时间', bind: 'lastRunTime', width: 150, tooltip: true, stretch: false },
        { header: '操作', bind: '', width: 120, tooltip: true, align: 'center', convert: (v, o)=>{
            return <div className={'flex center-y'}>
                <Icon name={'zhihang'} className={clsx('run-icon',{disabled: _.includes([0,3], o?.state)})}
                      onClick={()=> {
                          if(_.includes([0,3], o?.state)) return
                          findBranch(o?.id)
                      }}/>
                <Icon name={'tingzhi'} className={clsx('run-icon stop',{disabled: !_.includes([0,3], o?.state)})}
                      onClick={()=>{
                          if(!_.includes([0,3], o?.state)) return
                          stopPipeline(o?.id, o?.historyId)
                      }}
                />
            </div>
            }},
    ]
}

function getOptions(columns){
    return {
        nilText: '-',
        emptyText: '-',
        resizable: true,
        autoFill: true,
        virtualized: true,
        fixedRight: 1,
        columns,
    }
}

function TraceRelatePipelineList(props) {
    const {list, traceBizId, refreshList, listLoading} = props
    const allUserRes = useMemo(()=>JSON.parse(localStorage.getItem('all_user')),[])
    const {data: devOpsUrl} = useGet('/devops/ubs/common/getSystemPathUrl')
    const {data: branchList, doFetch: getBranchList} = useGet()
    const {doFetch: runPipelinePost} = usePost()
    const [showDialogInfo, setShowDialogInfo] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)

    const {doFetch: getEnumConfig} = usePost()
    const [pipelineTypeOptions, setPipelineTypeOptions] = useState()
    const [pipelineStateOptions, setPipelineStateOptions] = useState()
    useEffect(()=>{
        getEnumConfig('/devops/uds/enum/config/list',{enumIdList:['pipelineTypeShow','pipelineState']}).then(res => {
            setPipelineTypeOptions(_.map(_.filter(res, x => x.enumId === 'pipelineTypeShow'),x => ({
                value: x.enumValues,
                text: x.enumShowValue,
            })))
            setPipelineStateOptions(_.map(_.filter(res, x => x.enumId === 'pipelineState'),x => ({
                value: _.toNumber(x.enumValues),
                text: x.enumShowValue,
            })))
        })
    },[getEnumConfig])

    const stopPipeline = useCallback((pipelineId, historyId)=>{
        if(_.isNil(historyId)){
            return infoTips('该流水线未运行')
        }
        const postParams = {
            id: pipelineId,
            historyId,
        }
        runPipelinePost('/devops/ubs/pipeline/releasePipelineStop',postParams).then(()=>{
            successTips('流水线已停止运行')
            refreshList()
        }).catch(err => {
            errorTips(err._message)
        })
    },[runPipelinePost, refreshList])

    const runPipeline = useCallback((pipelineId)=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const postParams = {
            id: pipelineId,
            taskMRID: traceBizId,
        }
        runPipelinePost('/devops/ubs/pipeline/runMRPipeline',postParams).then(()=>{
            setSubmitLoading(false)
            successTips('流水线开始运行')
            refreshList()
        }).catch(err => {
            setSubmitLoading(false)
            errorTips(err._message)
        })
    },[runPipelinePost, traceBizId, refreshList, submitLoading])

    const runPipelineWithBranch = useCallback((branchName)=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const postParams = {
            id: showDialogInfo?.pipelineId,
            traceId: traceBizId,
            targetBranchName: branchName
        }
        runPipelinePost('/devops/ubs/pipeline/updateRunPipelineDetail',postParams).then(()=>{
            setSubmitLoading(false)
            successTips('流水线开始运行')
            refreshList()
            setShowDialogInfo(null)
        }).catch(err => {
            setSubmitLoading(false)
            errorTips(err._message)
        })
    },[runPipelinePost, traceBizId, refreshList, showDialogInfo, submitLoading])

    const findBranch = useCallback((pipelineId)=>{
        if(_.isNil(traceBizId)) return
        getBranchList(`/devops/ubs/pipeline/queryReleaseBranchList?traceId=${traceBizId}&pipelineId=${pipelineId}`).then(branchList => {
            if(_.isEmpty(branchList)){
                infoTips('请先关联分支再运行流水线')
            } else if(_.size(branchList) === 1){
                runPipeline(pipelineId)
            } else{
                setShowDialogInfo({
                    module: 'selectRunBranch',
                    pipelineId
                })
            }
        })
    },[traceBizId, getBranchList, runPipeline])

    const option = useMemo(()=>{
        return getOptions(getColumns({allUserRes, pipelineTypeOptions, findBranch, stopPipeline, pipelineStateOptions, devOpsUrl}))
    },[allUserRes, pipelineTypeOptions, findBranch, stopPipeline, pipelineStateOptions, devOpsUrl])


    return <>
        <DataGrid className={'trace-relate-pipeline-list'} data={list} option={option} loading={listLoading}/>
        {
            showDialogInfo?.module === 'selectRunBranch' &&
            <TraceRunPipelineBranchSelect close={()=>setShowDialogInfo(null)} onSubmit={runPipelineWithBranch} {...{branchList}}/>
        }
    </>
}

export default TraceRelatePipelineList;
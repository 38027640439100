import React from 'react';

export const FormContext = React.createContext();
export const ValueContext = React.createContext();
export const ModeContext = React.createContext();
export const TableContext = React.createContext();
export const TabContext = React.createContext();
export const ScheduleManagementContext = React.createContext();
export const GuideContext = React.createContext();
export const ImpProjectEditableContext = React.createContext();
export const TemplateEditContext = React.createContext();
export const RequirementStageEditContext = React.createContext();
export const TodoListInConferenceRecordsEditContext = React.createContext();
export const ParticipantListInConferenceRecordsEditContext = React.createContext();
export const VersionMilestoneInConferenceRecordsEditContext = React.createContext();
export const VersionContentInConferenceRecordsEditContext = React.createContext();
export const WorkFlowEditContext = React.createContext();
export const FuncTableShowFieldContext = React.createContext();
export const DevProjectStageEditContext = React.createContext();
export const GlobalConstEditContext = React.createContext();
export const MenuContext = React.createContext();
export const ManpowerAddContext = React.createContext();
export const ManpowerEditContext = React.createContext();
export const ProductContext = React.createContext();
import React, { useMemo, useState, useCallback, useEffect, useRef, useContext } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tooltip } from 'antd'
import { Dialog, MessageBox, Messager } from 'rootnet-ui'
import { FormInput, Select } from 'rootnet-edit'
import usePost from "rootnet-biz/es/hooks/usePost"
import { TextIconBtn } from '../../../common/TextIconBtn'
import { ModeContext, ScheduleManagementContext } from '../../../common/Context'
import { useGet, useApi } from '../../../../utils/hook'
import { MoreOperations } from './bubbleCard'
import ExportApiDialog from '../../../common/ExportApiDialog'
import LeftList from './LeftList'
import RightDataGrid from './rightDataGrid'
import InfoForm from './infoForm'
import DelMessage from '../../../../components/DelMessage'
import ImportApiDialog from '../../../common/ImportApiDialog'
import AssociatedTestPlan from './AssociatedTestPlan'
import CopyMove from './copyMove'
import useGetViewConfig from '../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../common/view/hooks/useGetTreeList'
import convertTableAlign from '../../../common/view/convertTableAlign'
import findConvert from '../../../common/view/findConvert'
import { Icon } from '../../../../components'
import gd from '../../../../base/global'
import UpdateStatusDialog from "./UpdateStatusDialog";
import convertOptions from '../../../common/ConvertOptions'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import './testCaseDetail.scss'
import ArrowCrumbs from '../../../common/ArrowCrumbs'
import TagsPopover from "../../../tagsMgt/tagsArea/tagsPopover/TagsPopover";
import { useFuncCode } from "../../../common/commonMethod";
import { isNil } from '../../../appraise/components/method'

const DEL_URL = '/test_case/delete'
const EXPORT_URL = '/test_case/export'
const LEFT_TREE_URL = '/testcasetable/list'
const IMPORT_URL = '/test_case/field/upload'
const TEMPLATE_URL = '/field/download?flag=7' //模板下载
const EXPORT_ABNORMAL_DATA_URL = '/test_case/field/export' //导出异常数据

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

function selectTree(data) {
  _.map(data, item => {
    item.value = item.id
    item.text = item.name
    if (!_.isEmpty(item.children)) selectTree(item.children)
  })
  return data
}

function strParams(params) {
  if (!_.isObject(params)) return '';
  const paramsArray = [];
  _.mapKeys(params, (value, key) => {
    !isNil(value) && paramsArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  });
  return paramsArray.join('&')
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setModeAll, tableIdOptions = [] } = props
  return [
    { selection: true },
    { header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function convertEditField(v, o, fieldItem) {
    switch (fieldItem.fieldId) {
      case 'caseName':
        return <>
          <span className='enter-detail-field' onClick={() => setModeAll({ mode: 'detail', data: o })}>
            {findConvert(fieldItem, v, convertCollection, dateOptions) || '-'}
          </span>
          <span className='edit-icon'>
            <Tooltip title='编辑' getPopupContainer={trigger => trigger.parentNode}>
              <span>
                <Icon name='bianji2' onClick={() => setModeAll({ mode: 'edit', data: o })} />
              </span>
            </Tooltip>
            <Tooltip title='删除' getPopupContainer={trigger => trigger.parentNode}>
              <span>
                <Icon
                  name='shanchu2' onClick={() => setModeAll({ mode: 'delete', id: [o.id] })} />
              </span>
            </Tooltip>
          </span>
        </>
      default:
        return findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
    }
  }
  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return _.assign({}, {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        dataIndex: fieldItem.fieldId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => {
          if (_.get(fieldItem, 'fieldId') === 'tableId') return convertOptions(v, tableIdOptions)
          return convertEditField(v, o, fieldItem)
        }
      }, _.includes(['View_test_caseRelated_Issue'], fieldItem.tableName) && { key: 'issueRelateId' },
        _.includes(['View_test_caseRelated_Req'], fieldItem.tableName) && { key: 'reqRelateId' },
        _.includes(['libraryId'], fieldItem.fieldId) && { key: 'name' },
        _.includes(['View_test_defect_case_related'], fieldItem.tableName) && { key: 'defectRelateId' })
    }
  }
}

function getBind(opt, flag = false) {
  if (!opt) return []
  const option = _.cloneDeep(opt)
  if (flag) {
    _.forEach(option.columns, o => {
      if (o.bind) {
        o.bind = o?.key || o.dataIndex
      }
    })
  } else {
    _.forEach(option.columns, o => {
      if (o.bind) {
        o.bind = o.dataIndex
      }
    })
  }
  return option
}

let sortConfig = {}

const getOptions = (columns) => ({
  columns,
  autoFill: true,
  virtualized: false,
  resizable: true,
  fixedLeft: 3,
  nilText: '-',
  emptyText: '-',
});

const conditional = ({ setDataSort }) => ({
  sort: sortConfig,
  onSort: (data, sort) => {
    sortConfig = sort
    setDataSort(sort)
    const sortList = _.orderBy(data, sort.column, sort.direction)
    return sortList
  },
})

export default function TestCaseDetail(props) {
  const { detail, setDetail } = props
  const { o, libraryList, initId } = detail
  const [defaultFetch, setFefaultFetch] = useState(false)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [treeId, setTreeId] = useState('0')
  const [selectedTreeKeyArr, setSelectedTreeKeyArr] = useState()
  const [treeList, setTreeList] = useState([])
  const [modeAll, setModeAll] = useState(initId ? { mode: 'detail', data: { id: initId } } : null) // 增 删 改
  const [dataSort, setDataSort] = useState()
  const [treeListId, setTreeListId] = useState(o.id)
  const [selectData, setSelectData] = useState([]) //测试用例选中数据
  const [currentId, setCurrentId] = useState()
  const [resetDirectory, setResetDirectory] = useState()
  // 两栏左右滑动
  const [canMove, setCanMove] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState(300);
  const prevXRef = useRef(null);
  const panelRef = useRef(null);

  const queryParamsRef = useRef(null)
  const [factor, setFactor] = useState()

  const { data: treeDat, doFetch: treeFetch, loading: treeLoading } = useGet()

  const [currentViewId, setCurrentViewId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig('0501', setIsLoading)
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes])
  const [getFactorDone, setGetFactorDone] = useState(false)
  const { data: getSqlData, doFetch: getSql } = useApi()
  const queryData = useMemo(() => ({ caseLibraryId: treeListId, id: currentViewId, params, factor }), [currentViewId, factor, params, treeListId])
  useEffect(() => {
    queryParamsRef.current = queryData
  }, [queryData])
  const treeRefresh = useCallback((queryParams, flag = false) => {
    const { caseLibraryId, id, params, factor, libraryId = null } = queryParams
    if (_.isUndefined(params)) return
    let factorPar = {}

    if (factor) {

      factorPar = _.get(JSON.parse(factor), 'conditionList')
      const filterFactor = _.filter(factorPar, o => o.field !== 'test_case.tableId')
      factorPar = _.assign({}, JSON.parse(factor), { conditionList: filterFactor })

      const parameter = { caseLibraryId: libraryId ? libraryId : caseLibraryId, id, factor: JSON.stringify(factorPar) }

      const paramsData = _.omit(params, ['pageNum', 'pageSize', 'test_case.tableId'])
      const par = _.map(_.keys(paramsData), v => {
        const val = _.get(_.split(v, 'test_case.'), '[1]')
        return [[val], _.get(params, `test_case.${val}`)]
      })
      getSql('/UserSetting/getWhereCondition', 'post', _.assign({ id: currentViewId, action: "query", menuCode: "0501", type: "01", factor: JSON.stringify(factorPar) })).then(res => {
        treeFetch(`${LEFT_TREE_URL}?${strParams(_.omit(_.assign(parameter, _.fromPairs(par), { factor: res }, libraryId && { libraryId }), ['reviewTime']))}`)
      })
      setResetDirectory(flag)
      setFefaultFetch(false)
      setGetFactorDone(false)
    }
  }, [currentViewId, getSql, treeFetch])

  const afterViewRefresh = useCallback(() => {
    setGetFactorDone(true)
    treeRefresh(queryParamsRef.current)
  }, [treeRefresh])
  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, afterRefresh: afterViewRefresh })
  const dateOptions = useGetDateOptions()
  const treeOpt = useMemo(() => selectTree(libraryList), [libraryList])
  const { data: tableIdOptionsRes, doFetch: getTableIdOptions } = useGet()

  const { doFetch: placeOnFilePost } = usePost()
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [oldQueryParams, setOldQueryParams] = useState()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])



  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: '0501',
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '测试用例导出')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  useEffect(() => {
    if (!getFactorDone) return
    if (_.isUndefined(params) || _.isUndefined(factor)) return
    const searchParams = { ...queryData, params, factor }
    if (JSON.stringify(oldQueryParams) === JSON.stringify(searchParams)) return
    setOldQueryParams(searchParams)
    treeRefresh(searchParams)
  }, [queryData, params, factor, treeRefresh, oldQueryParams, getFactorDone])

  useEffect(() => {
    if (!_.isNil(factor)) {
      getSql('/UserSetting/getWhereCondition', 'post', _.assign({ id: currentViewId, action: "query", menuCode: "0501", type: "01", factor: factor }))
    }
  }, [currentViewId, factor, getSql, params])

  useEffect(() => {
    if (defaultFetch) {
      treeRefresh(queryData, true)
    }
  }, [defaultFetch, queryData, treeRefresh])

  const tableIdOptions = useMemo(() => {
    if (_.isEmpty(tableIdOptionsRes)) return []
    return convertGlobalConstOptions(tableIdOptionsRes)
  }, [tableIdOptionsRes])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setModeAll, tableIdOptions }, optionsAppendParams: conditional({ setDataSort })
  })

  const replaceParams = useMemo(() => {
    return _.assign({}, { 'test_case.libraryId': treeListId, 'test_case.tableId': treeId === '0' ? null : treeId })
  }, [treeId, treeListId])

  const refreshLists = useCallback(() => {
    refreshList(replaceParams)
    getId()
    getTableIdOptions('/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=test_case_table')
  }, [refreshList, getTableIdOptions, replaceParams])

  // useEffect(() => {
  //   if (treeListId || currentViewId) { }
  //   setFefaultFetch(true)
  // }, [treeListId, currentViewId])

  // useEffect(() => {
  //   setParams(x =>
  //     _.assign({}, treeId !== '0' ? x : _.omit(x, ['test_case.tableId']),
  //       { 'test_case.libraryId': treeListId },
  //       treeId !== '0' && { 'test_case.tableId': treeId })
  //   )
  // }, [treeId, treeListId])

  useEffect(() => {
    sortConfig = {}
  }, [treeId])

  const dataList = useMemo(() => {
    if (dataSort) { }
    if (_.isEmpty(sortConfig)) return dataGridList
    return _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
  }, [dataGridList, dataSort])

  const parameter = useMemo(() => {
    if (!_.isNil(getSqlData)) {
      return { flag: 0, id: currentViewId, factor: getSqlData }
    }
  }, [currentViewId, getSqlData])

  // 归档
  const saveCase = useCallback(() => {
    const ids = _.map(selectData, o => o.id)
    placeOnFilePost('/test_case/placeOnFile', ids).then(() => {
      refreshLists()
      Messager.show('已归档', { icon: 'success' })
      setModeAll(null)
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
      setModeAll(null)
    })
  }, [placeOnFilePost, refreshLists, selectData])

  const goBack = useMemo(() => {
    return [
      { text: '用例管理' },
      {
        text: <FormInput tree search horizontal
          options={treeOpt}
          component={Select}
          defaultValue={treeListId}
          onChange={v => { setTreeListId(v) }}
        />, active: true
      }
    ]
  }, [treeListId, treeOpt])

  const [createTagAuth, createRelationAuth, saveFileAuth] = useFuncCode(['880401', '880402', '050166'])

  const contextValue = {
    getSqlData,
    allColumns,
    currentId,
    setCurrentId,
    replaceParams,
    treeRefresh,
    queryData,
    setParams,
    treeList,
    libraryId: treeListId,
    tableId: treeId,
    selectData,
    setSelectData,
    modeAll,
    setModeAll,
    useCaseList: dataList,
    data: { dataGridList: dataList, options, pageSize, total, pageNum, boxLoading, optionsConfig, getInitParams, funcCode, error },
    refresh: () => { refreshLists(); setSelectData([]) },
    delSelect,
    updateStatusSelect,
    useCasesTree: treeOpt,
    sortConfig,
    selectedTreeKeyArr: _.join(selectedTreeKeyArr, ','),
    getFieldList,
    refreshList,
    currentViewId,
    setCurrentViewId,
    params,
    setPageSize,
  }

  return (
    <ModeContext.Provider value={contextValue}>
      <div className={cls('testCaseDetail')}>
        {/* 头部 */}
        <div className='header'>

          <div className='lef'>
            <ArrowCrumbs data={goBack} goBack={() => setDetail(false)} />
          </div>

          <div className='rig'>
            <TextIconBtn icon='tianjia' text='新增' onClick={() => setModeAll({ mode: 'add' })} />
            <MoreOperations {...{ createRelationAuth, saveFileAuth, exportViewList }} />
          </div>

        </div>
        {/* 中间内容 */}
        <div className={`content ${canMove ? 'resize' : ''}`}
          onMouseMove={handleMove} ref={panelRef}
          onMouseLeave={() => setCanMove(false)}
          onMouseUp={() => setCanMove(false)}
        >
          <LeftList
            id={treeListId}
            treeDat={treeDat}
            queryData={queryData}
            setTreeId={setTreeId}
            htmlWidth={htmlWidth}
            treeLoading={treeLoading}
            treeRefresh={treeRefresh}
            setTreeList={setTreeList}
            refreshLists={refreshLists}
            resetDirectory={resetDirectory}
            selectedTreeKeyArr={selectedTreeKeyArr}
            setSelectedTreeKeyArr={setSelectedTreeKeyArr}
          />
          <div className='left-drag' onMouseDown={() => setCanMove(true)}>
            <Icon name='yidongshu' />
          </div>
          <RightDataGrid />

        </div>

      </div>

      {/* 添加 修改 详情 */}
      {_.includes(['add', 'edit', 'detail'], modeAll?.mode) && <InfoForm />}

      {/* 导出 */}
      {
        modeAll?.mode === 'export' && <ExportApiDialog
          name='测试用例导出'
          action='post'
          close={() => setModeAll(null)}
          option={getBind(options)}
          url={EXPORT_URL}
          parameter={parameter}
        />
      }

      {/* 导入 */}
      {modeAll?.mode === 'import' &&
        <ImportApiDialog
          title='测试用例'
          abnormal={EXPORT_ABNORMAL_DATA_URL}
          importUrl={IMPORT_URL}
          template={TEMPLATE_URL}
          close={() => setModeAll(null)}
          refresh={() => { refreshLists(); treeRefresh(queryData); setSelectData([]) }}
          parameter={{ flag: 0 }}
          abnormalParams={{ whichPage: 0 }}
          exportFieldname='testCaseVos'
          defaultOptions={getBind(options, true)}
        />
      }

      {/* 移动 复制 */}
      {_.includes(['move', 'copy'], modeAll?.mode) && <CopyMove />}

      {/* 关联到测试计划 */}
      {modeAll?.mode === 'plan' && <AssociatedTestPlan />}

      {/* 删除 */}
      {
        modeAll?.mode === 'delete' && <DelMessage
          header={<span className='DelMessage-lz'>删除用例<span>（已选{_.size(modeAll.id)}个用例）</span></span>}
          method='post'
          refresh={() => { refreshLists(); treeRefresh(queryData); setSelectData([]) }}
          close={() => setModeAll(null)}
          params={{ caseIds: modeAll.id, action: 'DEL' }}
          url={DEL_URL}
        >确定删除用例吗？</DelMessage>
      }
      {
        modeAll?.mode === 'updateStatus' &&
        <UpdateStatusDialog header={<span className='DelMessage-lz'>修改用例状态<span>（已选{_.size(modeAll.id)}个用例）</span></span>}
          close={() => setModeAll(null)} caseIds={modeAll.id}
          refresh={() => { refreshLists(); treeRefresh(queryData); setSelectData([]) }} />
      }
      {
        modeAll?.mode === 'saveCase' &&
        <MessageBox header='提醒' className={'content-center-dialog'} confirm={saveCase} cancel={() => setModeAll(null)}>
          请确认归档
        </MessageBox>
      }
      {
        modeAll?.mode === 'addTags' &&
        <Dialog header='批量新增标签' className={'case-batch-add-tags-dialog'} footerVisible={false} cancel={() => setModeAll(null)}>
          <TagsPopover id={null} idList={_.map(selectData, o => o.id)} funcCode={'0501'} createTagAuth={createTagAuth} isBatch={true}
            close={() => {
              Messager.show('批量新增标签成功', { icon: 'success' })
              setSelectData([])
              setModeAll(null)
            }}
            refreshTags={refreshLists}
          />
        </Dialog>
      }
    </ModeContext.Provider>
  )

  function delSelect() {
    if (_.isEmpty(selectData)) return Messager.show('请选中数据后在进行操作', { icon: 'error' })
    const selectId = _.map(selectData, o => o.id)
    setModeAll({ mode: 'delete', id: selectId })
  }

  function updateStatusSelect() {
    if (_.isEmpty(selectData)) return Messager.show('请选中数据后在进行操作', { icon: 'error' })
    const selectId = _.map(selectData, o => o.id)
    setModeAll({ mode: 'updateStatus', id: selectId })
  }

  function getId() {
    setCurrentId(_.get(gd, 'testCaseRightDataGrid', null))
  }

  function handleMove(e) {
    if (!canMove) return;
    e.preventDefault();
    e.stopPropagation();
    const prevX = prevXRef.current;
    prevXRef.current = e.clientX
    if (prevX === null) return null;
    const offset = e.clientX - prevX;
    setHtmlWidth(x => {
      if (x + offset < 136) {
        prevXRef.current = prevX
        return 136
      } else if ((x + offset) > (panelRef.current.offsetWidth / 2)) {
        prevXRef.current = prevX
        return x
      } else {
        return x + offset
      }
    });
  }

}

import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {Button, Dialog, Messager} from 'rootnet-ui'
import Icon from "../../../components/Icon";
import _ from "lodash";
import {DatePicker, Form, FormInput, Select} from "rootnet-edit";
import './CustomerVoiceAdd.scss'
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import RichTextEditor from "../../common/richTextEditor/TinyEditor";
import RequirementAddUploadArea
    from "../../requirementMgt/requirementAddDialog/requirementAddUploadArea/RequirementAddUploadArea";
import {Checkbox} from "antd";
import {usePost} from "rootnet-biz/lib/hooks";

const FUNC_CODE = '0851'

const HFormInput = props => <FormInput horizontal labelWidth={112} componentWidth={180} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=PRIORITYLIST',
]

const OPTIONS_URLS = [
    '/story/getbugProject',
    'common/globalconst?globalConst=soundSource',
]

function CustomerVoiceAdd(props) {
    const {close, afterRefresh = ()=>{}} = props
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [customerInfoExpand, setCustomerInfoExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const [docExpand, setDocExpand] = useState(true)
    const richEditorRef = useRef()
    const [countFile, updateFile] = useReducer((x) => x + 1, 10000)
    const {data: onlyId ,doFetch: getOnlyId} = useGet()
    const {data: allUserRes} = useGet('/common/userinfo')
    const [continueAdd, setContinueAdd] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const {doFetch: addPost} = usePost()

    const getNewOnlyId = useCallback(()=>{
        getOnlyId('/test_case/productGetOnlyId').then(id => {
            setFormData(x => _.assign({}, x, {id}))
            updateFile()
        })
    },[getOnlyId])

    useEffect(()=>{
        getNewOnlyId()
    },[getNewOnlyId])

    const [priorityOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [custIdOptions, soundSourceOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1, d2] = optionsRes
        const firstLevelSoundSource = _.filter(d2, x => _.isNil(x.pId))
        const soundSourceOptions = _.map(firstLevelSoundSource, x => ({
            text: x.displayName,
            value: x.interiorId,
        }))
        _.forEach(soundSourceOptions, item => {
            const childrenList = _.filter(d2, x => x.pId === item.value)
            item['children'] = _.map(childrenList, x => ({
                text: x.displayName,
                value: x.interiorId,
            }))
        })
        return [
            _.map(d1, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, tCustId: x.customer })),
            soundSourceOptions,
        ]
    },[optionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setFormData( oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[])

    const canSubmit = useMemo(()=>{
        if(!_.get(formData, 'title')) return false
        return !_.some(_.values(formError),x=>x)
    },[formData, formError])

    const checkRichTextRequired = useCallback(()=>{
        return purifyRichText(richEditorRef.current.getContent()) === ""
        function purifyRichText(richText = ''){
            const regex = /(<([^>]+)>)/ig
            return richText.replace(regex, "")
                .replace(/\s/g,"")
                .replace(/ /g,"")
                .replace(/&nbsp;/g,"")
        }
    },[])

    const continueClear = useCallback(()=>{
        setFormData(x => _.assign({},x))
        richEditorRef.current.setContent('')
        getNewOnlyId()
    },[getNewOnlyId])

    const submit = useCallback(()=>{
        if(checkRichTextRequired()){
            return Messager.show("请填写描述")
        }
        if(richEditorRef.current.loading){
            return Messager.show("图片上传中，请稍后保存")
        }
        if(addLoading) return
        setAddLoading(true)
        const postParams = {
            ...formData,
            details: richEditorRef.current.getContent(),
        }
        addPost('/customerVoice/addCustomerVoice',postParams).then(()=>{
            Messager.show('添加成功', { icon: 'success' })
            afterRefresh()
            setAddLoading(false)
            if(continueAdd){
                continueClear()
            }else{
                close()
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setAddLoading(false)
        })
    },[addLoading, addPost, afterRefresh, checkRichTextRequired, close, continueAdd, continueClear, formData])

    return <Dialog header={'新增客户声音'}  className={'customer-voice-add'} footerVisible={false} cancel={close}>
        <div className="content-wrap flex-y">
            <HFormInput label={'标题'} value={_.get(formData, 'title')} onChange={v => setFormData(x => ({...x, title: v}))} required componentWidth={808}/>
            <div className="area-wrap base-info-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{transform: baseInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setBaseInfoExpand(v => !v)}/>
                    基本信息
                </div>
                <div className="area-content-wrap" style={{height: baseInfoExpand ? '' : 0}}>
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
                        <HFormInput label={'发生日期'} bind={'happenDate'} required component={DatePicker}
                                    bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
                        <HFormInput label={'优先级'} bind={'priority'} required component={Select} options={priorityOptions}/>
                        <HFormInput label={'声音来源'} bind={'soundSourceList'} required multiple tree component={Select} options={soundSourceOptions}/>
                    </Form>
                </div>
            </div>
            <div className="area-wrap base-info-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{transform: customerInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setCustomerInfoExpand(v => !v)}/>
                    客户信息
                </div>
                <div className="area-content-wrap" style={{height: customerInfoExpand ? '' : 0}}>
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
                        <HFormInput label={'客户名称'} bind={'customer'} required component={Select} options={custIdOptions} search/>
                        <HFormInput label={'客户联系人'} bind={'customerLinker'} required />
                        <HFormInput label={'联系方式'} bind={'contactUs'} />
                    </Form>
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{transform: descExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setDescExpand(v => !v)}/>
                    描述
                    <span style={{color: 'red'}}>（必填）</span>
                </div>
                <div className="rich-text-area" style={{display: descExpand ? 'block': 'none'}}>
                    <RichTextEditor ref={richEditorRef}/>
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{transform: docExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setDocExpand(v => !v)}/>
                    附件
                </div>
                <div className="doc-area" style={{display: docExpand ? 'block': 'none'}} key={countFile}>
                    <RequirementAddUploadArea id={onlyId} allUserRes={allUserRes} funcCode={FUNC_CODE}/>
                </div>
            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div className={'continue-add-check'}>
                <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
            </div>
            <div className="btn-group flex">
                <Button normal onClick={()=>{
                    close()
                }}>取消</Button>
                <Button primary disable={!canSubmit} onClick={submit}>确认</Button>
            </div>
        </div>
    </Dialog>
}

export default CustomerVoiceAdd;
import React, { useEffect } from 'react';
import { useGet } from '../../../../utils/hook';
import CardList from './CardList';
import _ from 'lodash';
import './Modify.scss';

const options = [
    {
        title: '',
        list: [
            { label: "项目结束时间", bind: "completeDate", tooltip: false },
            { label: "计划投入(人日)", bind: "useDay", tooltip: false },
        ]
    }
]

//项目 -- 变更记录
function Modify(props) {
    let { data, doFetch, loading, error } = useGet();
    const { search, title } = props;
    useEffect(() => {
        const url = `develop/project/changelist?id=${_.get(search, 'id')}`;
        doFetch(url);
    }, [doFetch, search]);

    return <CardList
        title={title}
        loading={loading}
        error={error}
        options={options}
        className="approval-modify x"
        data={data || {}}
    />
}

export default Modify;
import React,{useState,useEffect,useMemo} from 'react';
import _ from 'lodash';
import Echarts from 'echarts-for-react';
import {Card} from 'rootnet-ui';
import { Dateformat } from 'rootnet-core/format';
import { pathParamsFor, strParams } from "../../../utils/publicFun";
import { useGet } from "../../../utils/hook";
import {  ShowItem } from "../../common/Details";
import { MilestoneCardCore } from "../../common/MilestoneCardCore";
import { ProjectProgress } from "./ProjectProgress";
import { Completeness } from "./Completeness";
import { ProgressLead } from "./ProgressLead";
import './BaseInfo.scss';
import { LeftMenu } from "../LeftMenu";

//里程碑
function MilestoneCard(props) {
    const {products,productId} = props;
    const {data,doFetch,loading,error} = useGet();

    useEffect(()=>{
        if(!_.isNil(productId)){
            const params_ = {
                type:'DEV',
                iid:productId
            };
            const url = `common/milestone/list?`.concat(strParams(params_));
            doFetch(url);
        }
    },[productId,doFetch]);

    const product = useMemo(()=>_.find(products,x=>_.toString(x.id)===_.toString(productId)),[products,productId]);
    return <Card className='milestone-card' loading={loading} error={error}
                 title={_.get(product, 'projectName') ? `${_.get(product, 'projectName')}里程碑` : '里程碑'}>
        <MilestoneCardCore product={product} data={data}/>
    </Card>
}

//任务进度
const TaskProgressOptsFor = (finishedNum,timeLead)=> {
    return {
        series: [
            {
                type: 'pie',
                radius: ['50%', '65%'],
                center: ['50%','45%'],
                label: { show: false, },
                animation:false,
                data: [
                    {value: finishedNum, name: '',itemStyle:{normal:{color:'#38E097'},emphasis:{color:'#38E097'}}},
                    {value: timeLead, name: '',itemStyle:{normal:{color:'#f0f3f7'},emphasis:{color:'#f0f3f7'}}},
                ]
            }
        ]
    };
};

function TaskProgress(props) {
    const {projectId} = props;
    const {data,doFetch,loading,error} = useGet();

    useEffect(()=>{
        if(!_.isNil(projectId)){
            const url = `develop/project/task/statistic?projectId=${projectId}`;
            doFetch(url);
        }
    },[projectId,doFetch]);

    const totalDay = _.get(data,'totalNum');
    const finishedNum = _.get(data,'finishedNum');
    const timeLead = totalDay - finishedNum;

    return <Card title='任务进度' className='task-progress' loading={loading} error={error}>
        {data && <Echarts option={TaskProgressOptsFor(finishedNum,timeLead)} style={{height:240}}/>}
        <div className="task-progress-content">
            {finishedNum}/{totalDay}
        </div>
        <div className="task-progress-footer">
            <div className='top'>已完成/总数</div>
            <div className='bottom'>任务数</div>
        </div>
    </Card>
}

//基本信息卡片
const baseInfoOpts = [
    [
        {label:'项目名称',bind:'projectName'},
        {label:'创建人',bind:'inputUser'},
        {label:'创建时间',bind:'inputDate',format:(d)=>Dateformat(d)},
        {label:'状态',bind:'status'},
        {label:'分类',bind:'projectSort'},
        {label:'项目经理',bind:'principalName'},
        {label:'客户',bind:'custId'},
        {label:'产品线',bind:'productLine'},
        {label:'产品',bind:'productName'},
        {label:'设计开始时间',bind:'beginDate',format:(d)=>Dateformat(d)},
        {label:'设计截止时间',bind:'creatEndDate',format:(d)=>Dateformat(d)},
        {label:'开发开始时间',bind:'devBeginDate',format:(d)=>Dateformat(d)},
        {label:'开发截止时间',bind:'devEndDate',format:(d)=>Dateformat(d)},
        {label:'测试开始时间',bind:'testBeginDate',format:(d)=>Dateformat(d)},
        {label:'测试截止时间',bind:'testEndDate',format:(d)=>Dateformat(d)},
        {label:'项目结束时间',bind:'completeDate',format:(d)=>Dateformat(d)},
        {label:'计划投入',bind:'useDay'},
        {label:'审批人',bind:'auditorName'},
        {label:'审批时间',bind:'auditTime',format:(d)=>Dateformat(d)},
        {label:'审批状态',bind:'auditStatus'},
    ],
    [
        {label:'审批备注',bind:'auditMemo'}
    ],
    [
        {label:'项目简介',bind:'synopsis',tooltip:false}
    ]
];

function BaseInfoCard(props) {
    const {params} = props;
    const {data,doFetch,loading,error} = useGet();

    useEffect(()=>{
        const url = `/develop/project/list?`.concat(strParams(params));
        doFetch(url);
    },[params,doFetch]);

    return <Card className='base-info-card' title='基本信息' loading={loading} error={error}>
        {
            baseInfoOpts.map((x,i)=>{
                return <div className="baseInfo-card-area" key={i}>
                    {
                        x.map((x2,i2)=>{
                            return <ShowItem key={i2} data={_.get(data,'0')} {...x2}/>
                        })
                    }
                </div>
            })
        }
    </Card>
}

function BaseInfo(props) {
    const { location: { search } } = props;
    const [params,setParams] = useState(pathParamsFor(search));
    const [productId,setProductId] = useState(_.get(params,'id'));
    const {data,loading,error} = useGet('develop/project/list?'.concat(strParams(_.omit(params,'id'))));

    useEffect(()=>{
        setParams(x=>{
            if(_.toString(x.id)===_.toString(productId)) return x;
            return _.assign({},x,{id:productId})
        });
    },[productId]);

    const projectId = useMemo(()=>_.get(_.find(data,x=>_.toString(x.id)===_.toString(productId)),'projectId'),[data,productId]);
    console.log(projectId)
    return (<div className='project-list-baseInfo'>
        <LeftMenu productId={productId} data={data} loading={loading} error={error} onClick={setProductId}/>
        <div className='main-content'>
            <MilestoneCard productId={productId} products={data}/>
            <Completeness projectId={projectId}/>
            <ProgressLead projectId={projectId}/>
            <ProjectProgress projectId={projectId}/>
            <TaskProgress projectId={projectId}/>
            <BaseInfoCard params={params}/>
        </div>
    </div>);
}

export default BaseInfo;
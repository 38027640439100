import {useEffect, useState} from 'react';
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import convertGlobalConstOptions from "../../ConvertGlobalConstOptions";
import {isNil} from "../../../appraise/components/method";

export default function
    useGetFieldConvertCollection(fieldList,setIsLoading) {
    const {data: fieldConvertList, doFetch: getFieldConvertList} = useGet()
    const [uniqueSourceList, setUniqueSourceList] = useState([])
    const [convertCollection, setConvertCollection] = useState()

    // 数据源去重
    useEffect(()=>{
        if(_.isEmpty(fieldList)) return
        const globalCountFieldList = _.filter(fieldList, x => !isNil(x.fieldDateSource) && !isNil(_.trim(x.fieldDateDetailSource)))
        const globalCountList = _.map(globalCountFieldList, x => x.fieldDateDetailSource)
        setUniqueSourceList(_.uniq(globalCountList))
    },[fieldList,getFieldConvertList])

    // 请求翻译接口
    useEffect(()=>{
        if(_.isEmpty(uniqueSourceList)) return
        getFieldConvertList(uniqueSourceList)
    },[uniqueSourceList, getFieldConvertList])

    // 翻译接口结果集转为对象形式
    useEffect(()=>{
        if(_.isEmpty(fieldConvertList)) return
        const globalCountFieldList = _.filter(fieldList, x => !isNil(x.fieldDateSource) && !isNil(_.trim(x.fieldDateDetailSource)))
        const globalCountNameSourceList = _.map(globalCountFieldList, x => ({
            tableField: `${x.tableName}.${x.fieldId}`,
            dataSource: x.fieldDateDetailSource
        }))
        const convertCollection = {}
        _.forEach(globalCountNameSourceList, (obj) => {
            convertCollection[obj.tableField] = convertGlobalConstOptions(getSource(obj.dataSource))
        })
        setConvertCollection(convertCollection)

        function getSource(source){
            const index = _.findIndex(uniqueSourceList, x => x === source )
            return fieldConvertList[index]
        }
    },[fieldList,fieldConvertList, uniqueSourceList])

    useEffect(()=>{
        if(_.isEmpty(uniqueSourceList)){
            setIsLoading(false)
        }else{
            setIsLoading(_.isNull(fieldConvertList))
        }
    },[setIsLoading,fieldConvertList, uniqueSourceList])

    return convertCollection
}
import React, {useCallback, useMemo, useState} from 'react';
import {Dialog, Messager, Loader} from 'rootnet-ui'
import './DefectDelayVersion.scss'
import {FormInput, Select} from 'rootnet-edit'
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import {usePost} from "rootnet-biz/es/hooks";

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C'
]

function DefectDelayVersion(props) {
    const {close, afterRefresh, ids} = props
    const [fixVersion, setFixVersion] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {doFetch: submitPost} = usePost()
    const [loading, setLoading] = useState(false)

    const [versionOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const submit = useCallback(()=>{
        if(loading) return
        setLoading(true)
        const submitParams = {
            fieldName: "fixVersion",
            fieldValue: fixVersion,
            ids: ids
        }
        submitPost('/test_defect/updateSingle', submitParams).then(()=>{
            Messager.show('修改成功', { icon: 'success' })
            setLoading(false)
            if(afterRefresh){
                afterRefresh()
            }
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setLoading(false)
        })
    },[submitPost, close, afterRefresh, ids, fixVersion, loading])

    return <Dialog className={'defect-delay-version'} header={'遗留缺陷导入修复版本'} confirmButtonDisabled={_.isNil(fixVersion)}
                   confirm={submit} cancel={close}>
        <FormInput label={'修复版本'} value={fixVersion} onChange={setFixVersion} required horizontal componentWidth={230}
                   component={Select} options={versionOptions} search/>
        {
            loading &&
            <Loader fill/>
        }
    </Dialog>
}

export default DefectDelayVersion;
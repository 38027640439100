import React,{useEffect} from 'react';
import _ from 'lodash';
import Echarts from 'echarts-for-react';
import {Card} from 'rootnet-ui';
import {Dateformat,GetTime,N2} from 'rootnet-core/format';
import { useGet } from "../../../utils/hook";

//项目进度
const projectProgressDataFor = (data)=>{
    const beginDate = new Date(Dateformat(_.get(data,'beginDate'))).getTime();//开始时间
    const completeDate = new Date(Dateformat(_.get(data,'completeDate'))).getTime();//结束时间
    const allWork = _.get(data,'allWork');//总工作量
    let time_lead = ((completeDate - beginDate) / 86400000) + 1;//总天数
    time_lead = _.isNaN(time_lead) ? 0 : time_lead;
    const everyDayWork = allWork / time_lead;//每日工作量

    //x轴时间
    const xData1 = _.reduce(Array(time_lead),(acc,x,i)=>{
        acc.push(Dateformat(GetTime(new Date(beginDate + 86400000*i))));
        return acc;
    },[]);

    //实际工作量
    const seriesData1 = _.map(_.get(data,'realProgressList',[]),x=>x.remainingWork);

    //预期工作量
    const seriesData2 = _.reduce(Array(time_lead),(acc,x,i)=>{
        acc.push((allWork - everyDayWork*i).toFixed(2));
        return acc;
    },[]);

    return {
        names:['实际进度','预期进度'],
        xData:[xData1],
        seriesData:[seriesData1,seriesData2],
        color:['#5477FF','#FF9A54'],
        yMins:[0],
        yMaxs:[allWork],
        yAxisNames:['剩余工作量']
    }
};

const Line = (props)=>{
    const {data:{names,xData,seriesData,color,yAxisNames,yMaxs,yMins}} = props;

    const option = {
        tooltip: {
            trigger: 'axis'
        },
        legend: [{
            show: true,
            icon: "roundRect",
            itemWidth: 14,
            itemHeight: 14,
            itemGap: 40,
            textStyle: {
                padding: [2, 0, 0, 0],
            },
            top: 12
        }],
        color,
        emphasis: [{
            itemStyle: {
                barBorderWidth: 1,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0,0,0,0.5)'
            }
        }],
        grid: [{
            top: '32%',
            right: '10%',
            bottom: '16%',
            left: '6%',
            containLabel: true
        }],
        xAxis: _.map(xData,data=>{
            return {
                type: 'category',
                axisTick: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        type: 'dashed',
                    }
                },
                boundaryGap: false,
                axisLabel:{
                    showMaxLabel: true
                },
                data,
            }
        }),
        yAxis: _.map(yAxisNames,(e,i)=>{
            return {
                name:e,
                max: yMaxs[i],
                min: yMins[i],
                interval: (yMaxs[i] - yMins[i]) / 3,
                nameGap: 20,
                axisLabel: {formatter:N2},
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
            }
        }),
        dataZoom: [{
            type: 'inside'
        }, {
            type: 'slider'
        }],
        series: seriesData.map((x,i)=>{
            return {
                name:names[i],
                type:'line',
                data:x,
                smooth: true,
                symbol: 'none'
            }
        })
    };

    return <Echarts option={option} style={{height:240}}/>;
};

export function ProjectProgress(props){
    const {projectId} = props;
    const {data,doFetch,loading,error} = useGet();

    useEffect(()=>{
        if(!_.isNil(projectId)){
            const url = `/develop/project/progressday?projectId=${projectId}`;
            doFetch(url);
        }
    },[doFetch,projectId]);

    return <Card className='project-progress' title='项目进度' loading={loading} error={error}>
        {data && <Line data={projectProgressDataFor(data)}/>}
    </Card>
}
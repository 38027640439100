import React, {useMemo, useState} from 'react';
import {Dialog} from 'rootnet-ui'
import _ from 'lodash'
import {Select} from "rootnet-edit";
import './DataAuthAddRole.scss'

function DataAuthAddRole(props) {
    const {currentRoleList, allRoleOptions, close, onAddRole} = props
    const [selectedRole, setSelectedRole] = useState()

    const currentRoleIdList = useMemo(()=>{
        return _.map(currentRoleList,'roleId')
    },[currentRoleList])

    const roleOptions = useMemo(()=>{
        return _.filter(allRoleOptions, x => !_.includes(currentRoleIdList, x.value))
    },[allRoleOptions, currentRoleIdList])

    return <Dialog header={'添加角色'} className={'data-auth-add-role content-center-dialog'}
                   confirmButtonDisabled={_.isNil(selectedRole)} cancel={close} confirm={()=>onAddRole(selectedRole)}>
        <Select value={selectedRole} onChange={setSelectedRole} options={roleOptions} className={'long-options'} search/>
    </Dialog>
}

export default DataAuthAddRole;
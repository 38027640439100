import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import _ from "lodash";
import { Form, FormInput, Select } from 'rootnet-edit';
import { useGet } from "rootnet-biz/lib/hooks";
import { DataGrid, Pagination, Button } from "rootnet-ui";
import { Box } from '../../common/commonComponent';
import { TextIconBtn } from '../../common/TextIconBtn';
import { strParams } from '../../../utils/publicFun';
import { ScheduleManagementContext } from '../../common/Context';
import './index.scss'
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions';

const LIST_URL = '/releaseRelation/getVersionContentPage'
const EXPORT_URL = '/releaseRelation/export/releaseRelation'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=VERSIONSTATE',//状态
  '/UserSetting/getUniversalInterfaces?code=ReleaseBasic&codeName=ReleaseBasic&tableName=ReleaseInfo', //基础版本
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=View_productInfo&pIdParams=pId', //产品
  '/UserSetting/getUniversalInterfaces?code=subSysId&codeName=subSysName&tableName=prodSubsysInfo', //子产品
  '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=ReleaseInfo' //关联版本
]

function getColumns(props) {

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    { header: '版本ID', bind: 'releaseId', width: 200, tooltip: true },
    { header: '版本状态', bind: 'releaseState', width: 150, tooltip: true },
    { header: '基础版本', bind: 'releaseBasic', width: 150, tooltip: true },
    { header: '产品', bind: 'productName', width: 150, tooltip: true },
    { header: '子产品', bind: 'subSysName', width: 150, tooltip: true },
    { header: '关联版本', bind: 'relateReleaseId', width: 150, tooltip: true },
    { header: '关联版本状态', bind: 'state', width: 150, tooltip: true },
  ]

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20,
    releaseId: null,
    releaseStateList: null,
    releaseBasicList: null,
    productIdList: null,
    subSysIdList: null,
    relateReleaseIdList: null,
    stateList: null,
  }
}

export default function VersionRelationMgt() {
  const [params, setParams] = useState(getInitParams())
  const { data: listRes, doFetch: getList, loading, error } = useGet()
  const { pageSize, total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [stateOpt, releaseBasicOpt, productOpt, subSysOpt, relateReleaseOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const { downloadGetProgress } = useContext(ScheduleManagementContext)

  const refresh = useCallback(() => {
    getList(`${LIST_URL}?${strParams(params)}`)
  }, [getList, params])

  const options = useMemo(() => getOptions(getColumns()), [])

  const exportViewList = useCallback(() => {
    downloadGetProgress(`${EXPORT_URL}?${strParams(params)}`, '版本关联导出')
  }, [downloadGetProgress, params])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className='version-relation-mgt fill flex-y'>
      <Option search={setParams} initParams={params} {...{ stateOpt, releaseBasicOpt, productOpt, subSysOpt, relateReleaseOpt }} />
      <Box title={'版本关联查询'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={loading} error={error}>
        <DataGrid option={options} data={list} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </div>
  )
}

function Option(props) {
  const { search, initParams, stateOpt, releaseBasicOpt, productOpt, subSysOpt, relateReleaseOpt } = props
  const [params, setParams] = useState(initParams)
  return <div className={'c-options'}>
    <Form value={params} onChange={setParams}>
      <FormInput label='版本ID' bind='releaseId' />
      <FormInput label='版本状态' bind='releaseStateList' component={Select} multiple search clear options={stateOpt} />
      <FormInput label='基础版本' bind='releaseBasicList' component={Select} multiple search clear options={releaseBasicOpt} />
      <FormInput label='产品' bind='productIdList' component={Select} multiple search clear options={productOpt} />
      <FormInput label='子产品' bind='subSysIdList' component={Select} multiple search clear options={subSysOpt} />
      <FormInput label='关联版本' bind='relateReleaseIdList' component={Select} multiple search clear options={relateReleaseOpt} />
      <FormInput label='关联版本状态' bind='stateList' component={Select} multiple search clear options={stateOpt} />
    </Form>
    <div className="search-btn-group flex">
      <Button className={'search-btn'} primary onClick={() => search({ ...params, pageNum: 1, pageSize: 20, })}>查询</Button>
      <Button className={'reset-btn'} text onClick={() => {
        search(getInitParams())
        setParams(getInitParams())
      }}>重置</Button>
    </div>
  </div>
}
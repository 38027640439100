import React, { useEffect, useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import { Dialog, Messager, Button } from 'rootnet-ui'
import { Form, FormInput, Display, Select, Input } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../utils/publicFun'
import convertOptions from '../../../../../common/ConvertOptions'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import TextAreaInput from '../../../../../common/textAreaInput/TextAreaInput'
import gd from '../../../../../../base/global'
import './matchcodeDetails.scss'
import { Icon } from '../../../../../../components'

const REVIEW_DETAIL_URL = '/bug/tracer/dev/detail'
const EDIT_URL = '/bug/tracer/dev/edit'

const { TabPane } = Tabs
const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props} />

export default function MatchcodeDetails(props) {
  const { id, loginUser, close, allUserRes, refresh, stateOpt, codeReviewResultOpt, tracerState, isClose } = props
  const [mode, setMode] = useState('detail')
  const [params, setParams] = useState()
  const [activeKey, setActiveKey] = useState('codeReviewMemo')
  const { doFetch: getFetch } = useGet()
  const { doFetch } = usePost()

  const isDetail = useMemo(() => {
    return mode === 'detail'
  }, [mode])

  const refreshData = useCallback(() => {
    getFetch(`${REVIEW_DETAIL_URL}?${strParams({ Id: id })}`)
      .then(res => {
        setParams(_.assign({}, res, {
          hour: changeHourMinutestr(res.codeReviewUseTime, 'hour'),
          minute: changeHourMinutestr(res.codeReviewUseTime, 'minute'),
        }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getFetch, id])

  const confirm = useCallback(() => {
    const postParams = _.assign({}, _.omit(params, ['hour', 'minute']), {
      codeReviewUseTime: _.toNumber(params?.hour) * 60 + _.toNumber(params?.minute)
    })
    doFetch(EDIT_URL, postParams)
      .then(() => {
        refresh()
        refreshData()
        setMode('detail')
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, params, refresh, refreshData])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  return (
    <Dialog
      style={{ width: 920 }}
      className='matchcode-details'
      headerVisible={false}
      footerVisible={false}
    >
      <div className='matchcode-detail-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title">
            代码检视详情
          </div>
          <div className="mock-right-header flex center-y">
            {
              (loginUser === gd.User.operator_id && tracerState !== 'T6') &&
              <span>
                <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                  if (isClose) return Messager.show('状态已关闭，不允许修改', { icon: 'error' })
                  refreshData()
                  setMode(x => x === 'detail' ? 'edit' : 'detail')
                }} />
              </span>
            }
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <div className="matchcode-detail-main-panel">
          <Form value={params} onChange={setParams}>
            <div>
              <HFormInput label='开发人员' bind='userId' component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
              <HFormInput label='开发状态' bind='currentState' component={Display} convert={v => convertOptions(v, stateOpt)} />
              {
                isDetail ? <HFormInput label='检视用时' bind='codeReviewUseTime' component={Display} convert={v => changeHourMinutestr(v)} /> :
                  <div style={{ display: 'inline-block' }}>
                    <div className='code-review-use-time'>检视用时</div>
                    <div style={{ display: 'inline-block', width: 204 }}>
                      <HFormInput bind='hour' type='number' component={Input} componentWidth={45} placeholder='小时' min={0} max={999} digit={0} />小时
                      <HFormInput bind='minute' type='number' component={Input} componentWidth={45} placeholder='分钟' min={0} max={59} digit={0} />分钟
                    </div>
                  </div>
              }
              <HFormInput label='检视结果' bind='codeReviewResult' options={codeReviewResultOpt} component={isDetail ? Display : Select} convert={v => convertOptions(v, codeReviewResultOpt)} />
              <HFormInput type='number' label='发现问题个数' bind='codeReviewErrCount' component={isDetail ? Display : Input} min={0} max={99999} digit={0} />
            </div>
            <div style={{ padding: '0 8px', overflow: 'hidden' }}>
              <Tabs activeKey={activeKey} onChange={setActiveKey}>
                <TabPane tab='检视内容及问题说明' key={'codeReviewMemo'}>
                  <HFormInput label='' bind='codeReviewMemo' component={isDetail ? Display : TextAreaInput} />
                </TabPane>
              </Tabs>
            </div>
          </Form>
        </div>
        {/* 底部 */}
        <div className="mock-footer flex center-y">
          <div className="btn-group flex">
            {
              isDetail &&
              <Button normal onClick={close}>关闭</Button>
            }
            {
              !isDetail &&
              <Button normal onClick={() => {
                refreshData()
                setMode(x => x === 'detail' ? 'edit' : 'detail')
              }}>取消</Button>
            }
            {
              !isDetail &&
              <Button primary onClick={confirm} disable={false}>确认</Button>
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function changeHourMinutestr(str, mode) {
  if (str !== '0' && str !== '' && str !== null) {
    const hour = (Math.floor(str / 60)).toString().length < 2 ? '0' + (Math.floor(str / 60)).toString() :
      (Math.floor(str / 60)).toString()
    const minute = (str % 60).toString().length < 2 ? '0' + (str % 60).toString() : (str % 60).toString()
    if (mode === 'hour') return hour
    if (mode === 'minute') return minute
    return hour + '小时' + minute + '分钟';
  }
  else {
    return '';
  }
}
// import _ from 'lodash'
// import cls from 'clsx'
import React  from 'react'
import { Drawer } from 'rootnet-ui'
// import { useApi } from '../../../../utils/hook'
import './DrawerTemplate.scss'
// import { compose } from "../../../../utils/publicFun";
// import { dateFormat, toDate } from "rootnet-core/dateFormat";
import ChangeRecord from '../../../common/ChangeRecord'

// const formatTime = compose(dateFormat('YYYY-MM-DD HH:MM:SS'), toDate.str14ToDate);

// const isShow = Object.freeze({
//   '预算成本': true,
//   '预算收入': true,
//   '成本中心': true,
//   '收入中心': true,
//   '开始日期': true,
//   '结束日期': true,
// })

const FUNC_CODE = '1390'

export default function DrawerTemplate(props) {
  const { open, setOpen } = props
  const { id } = open
  // const { doFetch, loading, data } = useApi()
  // const [sortData, setSortData] = useState()

  // useEffect(() => {
  //   if (id) {
  //     doFetch(`/project/change?projectId=${id}`)
  //   }
  // }, [id, doFetch])

  // useEffect(() => {
  //   if (data) {
  //     const sortArr = _.orderBy(data, 'modifyTime', 'desc')
  //     let firstTime = ''
  //     let num = 0
  //     _.map(sortArr, o => {
  //       if (firstTime === o.modifyTime) {
  //         num++
  //         if (num >= 1) o.modifyTime = ''
  //       } else {
  //         num = 0
  //         firstTime = o.modifyTime
  //       }
  //     })
  //     const createData = {
  //       actionType: 'CREATE',
  //       modifyData: "创建项目",
  //       modifyReason: null,
  //       modifyTime: formatTime(_.get(open, 'createTime')),
  //       modifyUser: _.get(open, 'createUserName'),
  //       newValue: _.get(open, 'projectName'),
  //       oldValue: _.get(open, 'projectName'),
  //     }
  //     setSortData([...sortArr, createData])
  //   }
  // }, [data, open])

  return (
    <Drawer
      open={open}
      header='动态'
      // loading={loading}
      className='project-drawer'
      cancelButtonVisible={false}
      confirmButtonVisible={false}
      cancel={() => setOpen(false)}
      onOutsideClick={() => setOpen(false)}
    >
      {/* <ul className='project-history-record'>
        {
          _.map(sortData, (o, i) => {
            return (
              <li key={i}>
                <div>
                  <span>{_.split((_.get(o, 'modifyTime')), ' ')[0]}</span>
                  <span>{_.split((_.get(o, 'modifyTime')), ' ')[1]}</span>
                </div>
                <div></div>
                <div className={cls({ reasonColor: _.get(isShow, `${o.modifyData}`) })}></div>
                <div className={cls({ reason: _.get(isShow, `${o.modifyData}`) })}>
                  {
                    o.actionType === 'CREATE' &&
                    <span>
                      {`${_.get(o, 'modifyUser')}创建了项目`}
                      <span style={{ color: 'red' }}>{_.get(o, 'newValue')}</span>
                    </span>
                  }
                  {
                    o.modifyData === '是否叶子项目标识' && o.actionType !== 'CREATE' &&
                    <span>
                      {`${_.get(o, 'modifyUser')}将此项目变更为`}
                      <span style={{ color: 'red' }}>叶子项目</span>
                    </span>
                  }
                  {
                    o.modifyData !== '是否叶子项目标识' && o.actionType !== 'CREATE' &&
                    <span>
                      {`${_.get(o, 'modifyUser')}将${_.get(o, 'modifyData')}`}由
                      '<span style={{ color: 'red' }}>{_.get(o, 'oldValue')}</span>'调整为
                      '<span style={{ color: 'red' }}>{_.get(o, 'newValue')}</span>'
                    </span>
                  }
                  {
                    _.get(isShow, `${o.modifyData}`) &&
                    <span style={{ color: '#1890ff' }}>
                      修改理由：
                      {
                        _.get(o, 'modifyReason') ?
                          <span dangerouslySetInnerHTML={{ __html: _.get(o, 'modifyReason').replace(/\n/g, '<br/>').replace(/\t/g, '&emsp;') }} /> :
                          null
                      }
                    </span>
                  }
                </div>
              </li>
            )
          })
        }
      </ul> */}
      <div className='project-record'>
        {
          open &&
          <ChangeRecord name='项目管理' funcCode={FUNC_CODE} id={id} referenceId={id} />
        }

      </div>
    </Drawer>
  )
}

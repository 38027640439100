import DataDictEditDialog from './dataDict/EditDialog'
import ConstantDefineEditDialog from './constantDefine/EditDialog'
import RootnetDataTypeEditDialog from './rootnetDataType/EditDialog'
import DictStandardRootEditDialog from './dictStandardRoot/EditDialog'
import {toDate,dateFormat} from 'rootnet-core/dateFormat';
import {compose} from "../../utils/publicFun";

const formatTime = compose(dateFormat('YYYY-MM-DD HH:MM:SS'),toDate.str14ToDate);

const metadataConfig = [
    {
        title: '数据字典',
        listUrls: {
            'default': '/field/list?',
            'approval': '/field/auditList?approvalMode=approval&',
            'pending': '/field/auditList?approvalMode=pending&'
        },
        optionsLabel: '字典名 / 字典名称 / 常量标识',
        saveUrl: '/field/save',
        cols: [
            { header: '字典名', bind: 'fieldCode', width: 120, tooltip: true },
            { header: '字典名称', bind: 'fieldName', width: 120, tooltip: true },
            { header: '使用产品', bind: 'productIdName', width: 120, tooltip: true },
            // { header: '根网数据类型', bind: 'fieldTypeCode', width: 120 },
            // { header: '常量标识', bind: 'fieldDictId' , convert: v => (<div className={'fieldDictIdInCols'}>{v}</div>)},
            // { header: '字典说明', bind: 'fieldDesc', width: 120, tooltip: true },
        ],
        logParams:['fieldCode'],
        editDialog: DataDictEditDialog
    },
    {
        title: '常量定义',
        listUrls: {
            'default': '/fieldDict/list?',
            'approval': '/fieldDict/auditList?approvalMode=approval&',
            'pending': '/fieldDict/auditList?approvalMode=pending&'
        },
        optionsLabel: '常量标识/名称 或 子项标识/名称',
        saveUrl: '/fieldDict/save',
        cols: [
            { header: '常量标识', bind: 'fieldDictId', width: 120, tooltip: true },
            { header: '常量名称', bind: 'fieldDictName', width: 120, tooltip: true },
            { header: '使用产品', bind: 'productIdName', width: 120, tooltip: true },
        ],
        logParams:['fieldDictId','fieldDictSubCode'],
        editDialog: ConstantDefineEditDialog
    },
    {
        title: '根网数据类型',
        listUrls: {
            'default': '/fieldType/list?',
            'approval': '/fieldType/auditList?approvalMode=approval&',
            'pending': '/fieldType/auditList?approvalMode=pending&'
        },
        optionsLabel: '根网数据类型 / 名称',
        saveUrl: '/fieldType/save',
        cols: [
            { header: '根网数据类型', bind: 'fieldTypeCode', width: 120, tooltip: true },
            { header: '类型名称', bind: 'fieldTypeName', width: 120, tooltip: true },
            { header: 'mysql类型', bind: 'mysqlType', width: 110, tooltip: true },
            { header: 'sqlserver类型', bind: 'sqlserverType', width: 110, tooltip: true },
            { header: 'oracle类型', bind: 'oracleType', width: 110, tooltip: true },
            { header: '字段长度', bind: 'normLength', width: 80, align: 'right' },
            { header: '精度', width: 80, bind: 'normPrecision' , align: 'right'},
            { header: '类型说明', bind: 'dictTypeDesc', width: 120, tooltip: true },
        ],
        logParams:['fieldTypeCode'],
        editDialog: RootnetDataTypeEditDialog
    },
    {
        title: '标准词根查询',
        listUrls: {
            'default': '/dictStandardRoot/list?',
            'approval': '/dictStandardRoot/auditList?approvalMode=approval&',
            'pending': '/dictStandardRoot/auditList?approvalMode=pending&'
        },
        optionsLabel: '中文全称 / 英文全称',
        saveUrl: '/dictStandardRoot/save',
        cols: [
            { header: '中文全称', bind: 'name', width: 120, tooltip: true },
            { header: '英文全称', bind: 'enName', width: 120, tooltip: true },
            { header: '英文缩写', bind: 'enAb', width: 110, tooltip: true },
            { header: '来源', bind: 'sourceName', width: 110, tooltip: true },
            { header: '创建人员', bind: 'createOptName', width: 110, tooltip: true },
            { header: '创建时间', bind: 'createTime', width: 110, tooltip: true, convert: formatTime, type:'time' },
        ],
        logParams:['id'],
        editDialog: DictStandardRootEditDialog
    },
]

export default metadataConfig
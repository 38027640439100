import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {DataGrid, Dialog, MessageBox, Messager} from 'rootnet-ui'
import './TraceRelateComponentSelectDialog.scss'
import {Box} from "../../../../../../common/commonComponent";
import {usePost} from "rootnet-biz/lib/hooks";
import {Icon} from "../../../../../../../components";
import _ from 'lodash'
import {CheckBox, Input} from "rootnet-edit";
import {successTips} from "../../../../../../../utils/messagerTips";
import TraceComponentAddDialog from "../traceComponentAddDialog/TraceComponentAddDialog";
import {Tooltip} from "antd";

function getColumns(props){
    const {selectedId, setSelectedId, setSelectedItem} = props
    return [
        { header: '', width: 40, align: 'center', stretch: false, convert: (v, o) => {
            return <CheckBox value={selectedId === o?.id} onChange={v => {
                setSelectedId(o?.id)
                setSelectedItem(o)
            }}/>
            }},
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center', stretch: false },
        { header: '组件ID', bind: 'shortName', width: 150, tooltip: true },
        { header: '组件名称', bind: 'name', width: 150, tooltip: true },
        { header: 'git路径', bind: 'sourceCodePath', width: 300, tooltip: true },
    ]
}

function getOptions(columns){
    return {
        autoFill: true,
        resizable: true,
        virtualized: false,
        nilText: '-',
        emptyText: '-',
        columns,
    }
}

function TraceRelateComponentSelectDialog(props) {
    const {close, traceBizId, refreshList, id, isRelated} = props
    const {data: list, doFetch: getList, loading, error} = usePost()
    const [searchText, setSearchText] = useState()
    const [selectedId, setSelectedId] = useState()
    const [selectedItem, setSelectedItem] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [submitLoading, setSubmitLoading] = useState(false)
    const {doFetch: submitPost} = usePost()
    const [showTips, setShowTips] = useState(false)
    const [showDialogInfo, setShowDialogInfo] = useState()

    const refreshAllComponentList = useCallback(()=>{
        getList('/devops/uprods/app/program/permissionList',{keyword: searchText})
    },[getList, searchText])

    useEffect(()=>{
        refreshAllComponentList()
    },[refreshAllComponentList])

    const option = useMemo(()=>{
        return getOptions(getColumns({selectedId, setSelectedId, setSelectedItem}))
    },[selectedId])

    const changeSearchProjectText = useCallback((v)=>{
        setSearchText(v)
    },[])

    const extra = useMemo(()=>{
        return <div className={'extra-wrap flex center-y'}>
            <Input placeholder={'搜索关键字'} style={{width: 284, margin: '8px 0'}}
                   prefix={<Icon name={'search'} style={{margin: '0 6px'}}/>}
                   onChange={_.debounce(changeSearchProjectText, 800)}
            />
            <Tooltip title={'创建组件'} placement={'top'}>
                <span>
                    <Icon className={'can-click-icon'} style={{fontSize: 18, margin: '0 12px'}} name={'tianjia'}
                          onClick={()=>setShowDialogInfo({module: 'componentInfo', mode: 'add'})}/>
                </span>
            </Tooltip>
        </div>
    },[changeSearchProjectText])

    const onRowClick = useCallback((item)=>{
        setSelectedId(item?.id)
        setSelectedItem(item)
    },[])

    useEffect(() => {
        _.forEach(list, o => {
            return o._rowClass = selectedId === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    }, [list, selectedId])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const postParams = {
            id,
            programId: selectedItem?.programId,
            traceId: traceBizId
        }
        submitPost('/devops/uprods/trace/program/addOrUpdate', postParams).then(()=>{
            setSubmitLoading(false)
            successTips('关联成功')
            refreshList()
            close()
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err._message, { icon: 'error' });
        })
    },[submitLoading, traceBizId, selectedItem, refreshList, close, submitPost, id])

    const preSubmit = useCallback(()=>{
        if(isRelated){
            setShowTips(true)
        }else{
            submit()
        }
    },[isRelated, submit])

    return <Dialog header={'选择组件'} className={'trace-relate-component-select-dialog'} confirmButtonDisabled={_.isNil(selectedId)}
                   cancel={close} confirm={preSubmit}>
        <Box className='flex-y x-card-singlegrid' title='组件列表' data={list} error={error} loading={loading} extra={extra}>
            <DataGrid data={list} option={option} onRowClick={onRowClick}/>
        </Box>
        {
            showTips &&
            <MessageBox header="提示" className={'content-center-dialog'}
                        cancel={()=>setShowTips(false)}
                        confirm={submit}>组件已关联，继续操作将会替换原组件，是否继续？</MessageBox>
        }
        {
            showDialogInfo?.module === 'componentInfo' &&
            <TraceComponentAddDialog close={()=>setShowDialogInfo(null)}
                                     refreshList={refreshAllComponentList}
                                     initMode={showDialogInfo?.mode} id={showDialogInfo?.id}/>
        }
    </Dialog>
}

export default TraceRelateComponentSelectDialog;
import React, { useContext, useState, useRef } from 'react'
import _ from 'lodash';
import { Tree } from 'antd'
import { Dialog, Messager } from 'rootnet-ui';
import { useApi } from '../../../../utils/hook'
import { useSize } from '../../../commonV2/hooks'
import { Icon } from '../../../../components';
import { ValueContext } from '../../../common/Context';

const DRAG_URL = '/testcasetable/update/all'

export default function TreeDrag() {
  const { setTreeDrag, treeDrag, refresh, queryData } = useContext(ValueContext)
  const [treeList, setTreeList] = useState(treeDrag)
  const { doFetch } = useApi()
  const treeContentDom = useRef()
  const wrapperSize = useSize(treeContentDom)

  const onDrop = info => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          if (info.dropToGap) data[i].pid = '0'
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...treeList];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.children || []).length > 0 && // Has children
      info.node.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setTreeList(data)
  };

  return <Dialog
    header='调整目录位置'
    confirm={confirm}
    cancel={() => setTreeDrag(false)}
    className='treeDrag'
  >
    <span>拖拽目录，调整它在用例列表顺序和位置</span>
    <div ref={treeContentDom}>
      <Tree
        draggable
        blockNode
        onDrop={onDrop}
        defaultExpandAll
        treeData={treeList}
        height={wrapperSize?.height - 8}
        className='draggable-tree'
        switcherIcon={<Icon name='zhankaijiantoushang' className='transform-rotate' />}
      >

      </Tree>
    </div>
  </Dialog>

  function confirm() {
    const params = recursion(treeList)
    doFetch(DRAG_URL, 'post', params)
      .then(() => {
        refresh(queryData)
        setTreeDrag(false)
        Messager.show(`调整目录位置成功`, { icon: 'success' })
      })
      .catch((err) => {
        setTreeDrag(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }
}


function recursion(data, pid = '0', arr = [], sort = [], level = 1) {
  _.map(data, (o, i) => {
    o.pid = pid
    if (pid === '0' && level === 1) sort = [i]
    else if (_.size(sort) >= level) sort = [..._.slice(sort, 0, level - 1), i]
    else sort.push(i)
    arr.push({ id: o.id, pid: o.pid, name: o.name, sort: _.join(sort, '-') })
    if (!_.isEmpty(o.children)) recursion(o.children, o.id, arr, sort, level + 1)
  })
  return arr
}
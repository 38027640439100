import React, { useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination } from 'rootnet-ui'
import { Icon } from '../../../../components'
import { FormContext } from '../../../common/Context'
import { OperationList } from '../../../common/OperationList'
import { TextIconBtn } from '../../../common/TextIconBtn'
import convertTableAlign from '../../../common/view/convertTableAlign'
import findConvert from '../../../common/view/findConvert'
import { usePost } from 'rootnet-biz/lib/hooks'
import useRefreshList from '../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../common/view/hooks/useGetTreeList'
import useGetViewConfig from '../../../common/view/hooks/useGetViewConfig'
import ViewArea from '../../../common/view/ViewArea'
import { Box } from '../../../common/commonComponent'

const ENTER_DETAIL_FIELD = 'title'

function getColumns(props) {//test_caseRelated.caseId
  const { fieldList, convertCollection, dateOptions, setDisassociation, setShowDetail, flag } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)), flag ? [] : [{ header: '操作', width: 50, algin: 'center', convert: renderOperation },])

  function renderOperation(_v, o, _i, d) {
    const operateOption = [
      {
        text: <Icon name='quxiaoguanlian' />,
        onClick: () => setDisassociation({ type: 'demand', data: _.filter(d, item => o.id !== item.id) })
      }
    ]
    return <OperationList options={operateOption} />;
  }

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)

    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      const categoryColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'story.storyId'), 'columnId')
      console.log()
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setShowDetail({ type: _.includes(o[categoryColumnId], 'DEV') ? 'dev' : 'req', id: o.id })
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }}>
      {showValue || '-'}
    </div>
  }

}

const getOptions = (options) => (_.assign({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
  fixedRight: 1
}));

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '0008'

export default function Demand(props) {
  const { flag } = props
  const { setDisassociation, setRelation, setShowDetail, caseId } = useContext(FormContext)

  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    return ({ 'test_caseRelated.caseId': caseId })
  }, [caseId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setDisassociation, setShowDetail, flag } })

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        !flag && <TextIconBtn
          style={{ margin: '8px 0', display: 'inline-block' }}
          icon='sousuo1'
          text='关联需求'
          onClick={() => setRelation('demand')}
        />
      }
    </div>
  }, [setRelation, flag])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading} replaceParams={replaceParams}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'关联需求'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </div>
  )
}

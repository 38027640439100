import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { Tooltip, Select, Popover } from 'antd'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import gd from '../../../../../../base/global'
import { Icon } from '../../../../../../components'
import convertOptions from '../../../../../common/ConvertOptions'

const { str14ToDate } = toDate

export function getDisabled({ poUser, state, principal, isReqCode, isProjectCode, principals }) {
  if (_.includes(['R', 'L'], state)) return true
  else if (_.includes(['T'], state) && (poUser === gd.User.operator_id || principal === gd.User.operator_id || isReqCode || isProjectCode || _.includes(principals, gd.User.operator_id))) {
    return false
  } else if (_.includes(['C'], state) && (poUser === gd.User.operator_id || principal === gd.User.operator_id || isReqCode || _.includes(principals, gd.User.operator_id))) {
    return false
  }
  return true
}

const TYPE_COLOR = {
  'REQ': '#5477FF',
  'TASK': '#5C595B',
  'ISSUE': '#FF5454',
  'DEFECT': '#EC641C',
  'DEV': '#5477FF',
}

export function getColumns(props) {
  const { productOptions, customerSystemOpt, storyTypeOptions, allUserRes, setActionType, setChangeData,
    isDisabled, expanded, setExpanded, setShowDetail, workflowOpt, allColumns, customField, isWrap, priorityOpt, yseOrNoOpt, editInfo, setEditInfo, editChange, isPlanning, isCopy = true, isBaseLine, isPo, isALL, isState, isShow = true, isDisplay = false } = props
  const { editId = null } = editInfo || {}
  function UnfoldAndStow({ text }) {
    return <>
      <Icon
        style={{ fontSize: 12, marginRight: 8, fontWeight: 400, cursor: 'pointer' }}
        onClick={() => { setExpanded(x => !x) }} name={expanded ? 'biaogeshouqi' : 'biaogezhankai'} />
      {text}
    </>
  }

  function sortFun(a, b, c, bind) {//ascend  descend
    if (_.isNil(a[bind])) return -1
    const aStr = isNil(a[bind]) ? '' : a[bind]
    const bStr = isNil(b[bind]) ? '' : b[bind]
    return aStr.localeCompare(bStr)
  }
  const columnsWidth = _.assign({}, {
    title: 500,
    storyId: 160,
    storyType: 110,
    status: 145,
    assessor: 85,
    devUser: 85,
    currentUser: 85,
    workload: 135,
    productId: 150,
    custSystem: 100,
    updateUser: 95,
    updateTime: 150,
    testUser: 95,
    createUser: 95,
    createTime: 150,
    description: 500,
    solution: 500,
    testSuggest: 500,
    regression: 80,
  }, !isCopy && { custCommunicationLog: 300 })
  const columnsRender = {
    title: { render: changeTitleStyle },
    storyType: { render: v => convertOptions(v, storyTypeOptions) },
    status: { render: getStatus },
    assessor: { render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    devUser: { render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    currentUser: {
      render: v => {
        const val = _.join(_.map(_.split(v, '^'), val => (convertOptions(val, allUserRes, 'userName', 'userAccount') || val)), ',')
        return <Tooltip title={val}><div className='hide-display'>{val}</div></Tooltip>
      }
    },
    productId: { render: v => convertOptions(v, productOptions) },
    custSystem: {
      render: v => {
        const val = convertOptions(v, customerSystemOpt)
        return <Tooltip title={val}><div className='hide-display'>{val}</div></Tooltip>
      }
    },
    updateUser: { render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v },
    updateTime: { render: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)) },
    testUser: { render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v },
    createUser: { render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v },
    createTime: { render: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)) },
    testSuggest: { render: memoStyle },
    description: { render: memoStyle },
    solution: { render: memoStyle },
    csRecordId: { render: tooltipText },
    priority: { render: v => convertOptions(v, priorityOpt) },
    regression: { render: getRegression },
    releaseIds: { render: tooltipText },
    custCommunicationLog: { render: htmlTooltipText },
  }

  const filterCustomField = _.compact(_.map(_.split(customField, ','), v => {
    if (isDisplay) {
      //updateUser updateTime regression
      if (_.includes(['updateUser', 'updateTime', 'regression'], v)) return
      return v
    }
    return v
  }))

  return _.map(_.compact(_.map(filterCustomField, v => _.find(allColumns, o => o.value === v))), (o, i) => {
    return _.assign({
      title: o.label,
      key: o.value,
      dataIndex: o.value,
      align: 'left',
      width: _.get(columnsWidth, o.value, 120),
    }, i === 0 && {
      title: <UnfoldAndStow text={o.label} />,
      fixed: 'left',
      width: _.toNumber(_.get(columnsWidth, o.value, 120)) + 50,
    }, i !== 0 && {
      sorter: (a, b, c) => sortFun(a, b, c, o.value)
    }, _.includes(['developmentWorkload', 'productWorkload', 'testWorkload', 'workload'], o.value) && {
      sorter: (a, b) => {
        const aNum = isNil(a.workload) ? 0 : a.workload
        const bNum = isNil(b.workload) ? 0 : b.workload
        return aNum - bNum
      }
    }, _.get(columnsRender, o.value, {}))
  })

  function tooltipText(v) {
    return <Tooltip title={v}><div className='init-style-display'>{v}</div></Tooltip>
  }

  function htmlTooltipText(v) {
    return <Tooltip title={<div style={{ maxHeight: 500, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: v }} />}><div className='init-style-display'>{v}</div></Tooltip>
  }

  function memoStyle(v) {//memo-linefeed
    return <div className={isWrap ? 'memo-linefeed' : 'init-style-display'} dangerouslySetInnerHTML={{ __html: v }} />
  }

  function getRegression(v, o) {//regression
    if ((!isPlanning || (o.type === 'REQ' && _.isNil(o.inRelease)) || o.type === 'TASK') && isShow) {
      return convertOptions(v, yseOrNoOpt)
    }
    if (editId === o.id) {
      const yseOrNoOptions = _.map(yseOrNoOpt, o => ({
        value: o.value,
        label: o.text,
      }))
      return <Select
        autoFocus
        value={v}
        options={yseOrNoOptions}
        onChange={(val) => editChange({ newVal: val, oldVal: v, id: o.id })}
        onBlur={() => setEditInfo(null)}
      />
    }
    return <div
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between'
      }}
      onClick={() => setEditInfo({ editId: o.id })}>
      {convertOptions(v, yseOrNoOpt)}
      <Icon style={{ color: '#5477ff' }} name='bianji2' />
    </div>
  }

  function getStatus(v, o) {
    const val = convertOptions(v, workflowOpt) || '-'
    const color = convertOptions(v, workflowOpt, 'color')
    const style = {
      color: color,
      border: `1px solid ${color}`,
      padding: '0px 8px',
      borderRadius: '4px',
      display: 'inline-block',
    }
    return <div style={{ width: '100%', overflow: 'hidden' }}>
      <div style={style}>{val}</div>
    </div>
  }
  function changeTitleStyle(v, o) {
    return <div className='flex title-hidden'>
      <div className='type-color' style={{ backgroundColor: TYPE_COLOR[o.type], marginLeft: (o.type === 'REQ' || o.type === 'DEV') ? 1 : 0 }}>
        {_.toUpper(o.type) === 'DEFECT' ? 'BUG' : _.toUpper(o.type)}
      </div>
      <Tooltip title={v}>
        <div
          className={`text`}
          onClick={() => {
            if (o.type === 'REQ') {
              setShowDetail({
                type: 'REQ',
                id: o.id
              })
            } else if (o.type === 'ISSUE') {
              setShowDetail({
                type: 'ISSUE',
                id: o.id
              })
            } else if (o.type === 'TASK') {
              setShowDetail({
                type: 'TASK',
                id: o.id
              })
            } else if (o.type === 'DEFECT') {
              setShowDetail({
                type: 'DEFECT',
                id: o.id
              })
            } else if (o.type === 'DEV') {
              setShowDetail({
                type: 'DEV',
                id: o.id
              })
            }
          }}>
          {v}
        </div>
      </Tooltip>
      {
        ((o.type !== 'TASK') && isShow) &&
        <ItemOperations {...{ id: getKeys([o], [], 'id'), type: o.type, setActionType, setChangeData, isDisabled, isCopy, isBaseLine, isPo, isALL, isState }} />
      }
    </div>
  }
}

function getKeys(data, arr = [], id = 'storyId', level = 1) {
  if (level === 1) {
    const datas = _.head(data)
    if (!_.isEmpty(datas?.children) && _.find(datas.children, o => o.type === 'TASK')) arr.push(datas[id])
    else if (_.isEmpty(datas?.children)) arr.push(datas[id])
    else getKeys(datas.children, arr, id, level + 1)
  } else {
    _.forEach(data, o => {
      if (o.type === 'TASK') return
      arr.push(o[id])
      if (!_.isEmpty(o.children)) getKeys(o.children, arr, id, level + 1)
    })
  }
  return arr
}

function ItemOperations(props) {
  const { id, type, setActionType, setChangeData, isDisabled, isCopy, isBaseLine, isPo, isALL, isState } = props
  const className = useMemo(() => isDisabled ? 'item-operation-disabled' : '', [isDisabled])
  const isBaseLineItem = type === 'DEFECT' && isBaseLine
  //dataManipulation('DEL', [id], type)
  const [clicked, setClicked] = useState(false)
  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      {
        (isBaseLineItem && isState) &&
        <div className={className} onClick={() => {
          if (!isDisabled) {
            setClicked(false)
            setActionType('delDefect')
            setChangeData({ type, optionType: 'DEL', storyidList: id })
          }
        }}>
          移出基线
        </div>
      }
      {
        !isBaseLineItem &&
        <div className={className} onClick={() => {
          if (!isDisabled) {
            setActionType('del')
            setChangeData({ type, optionType: 'DEL', storyidList: id })
          }
        }}>{isCopy ? '从该版本中移除' : '移除'}</div>
      }
      {
        (isCopy && !isBaseLineItem) &&
        <div onClick={() => {
          setActionType('copy')
          setChangeData({ type, optionType: 'ADD', storyidList: id })
        }}>复制至其他版本</div>
      }
    </div>
  }, [className, id, isDisabled, setActionType, setChangeData, type, isCopy, isBaseLineItem, isState])

  if (isBaseLineItem && !(isPo || isALL)) return <></>

  return (<Popover
    trigger="click"
    open={clicked}
    placement='bottomLeft'
    content={<Content />}
    onOpenChange={(visible) => setClicked(visible)}
    overlayClassName='popoverMore'
  >
    <span className='more-operations' onClick={() => setClicked(x => !x)}><Icon name='gengduo' /></span>
  </Popover>)
}
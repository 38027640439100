import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { isNil, N2 } from 'rootnet-core/format'
import { Tooltip, Popover } from 'antd'
import { Button, Messager } from 'rootnet-ui'
import { copyText } from 'rootnet-core/clipboard'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Display, Select, DatePicker, Input } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'
import convertOptions from '../../../../common/ConvertOptions'
import ChangeRecord from '../../../../common/ChangeRecord'
import { selectOption } from '../../../../common/commonMethod'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { TextIconBtn } from '../../../../common/commonComponent'
import { filterDisplayFlagOptions } from "../../../../common/publicFunc";
import './index.scss'
import MemberEditLog from './memberEditLog'
import ProjectStage from './projectStage'
import gd from '../../../../../base/global'
import ProjectOverview from './projectOverview'
import MajorRisk from './majorRisk'
import CascadeSelect from '../../../../common/cascadeSelect/CascadeSelect'
import StageQualityAuditDetail from './stageQualityAuditDetail'
import clsx from 'clsx'

const DETAIL_URL = '/userProject/info'
const EDIT_URL = '/userProject/update'
const STAGE_DETAIL_URL = '/userProject/stageVoList'
const OVERVIEW_DETAIL_URL = '/userProject/generalView'
const REPAIR_DEFICENCY_URL = '/userProject/generalViewTest'
const OPEN_URL = 'http://family.croot.com/api/login/sso/oa'

const OPTIONS_URLS = [
  '/develop/product/list' //产品
]

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=typeOfRDProject', //项目类型
  'common/globalconst?globalConst=projectStatusNew',  //项目状态
  // '/UserSetting/getUniversalInterfaces?code=id&codeName=projectName&tableName=PSAProject&filter=LeafProjectFlag&filterParams=1', //父级项目
  '/common/globalconst?globalConst=ProductLine', //产品线
  'common/globalconst?globalConst=projectRole', //项目角色
  '/common/globalconst?globalConst=PlanStatus' //计划任务状态
  // 'common/globalconst?globalConst=DEVProjectSort', //项目分类
]

const STAGE_FLOW_LIST = [
  { color: '#C2E3C7', icon: 'chenggong', text: '通过', value: '01' },
  { color: '#FFD700', icon: 'chenggong', text: '有条件通过', value: '02' },
  { color: '#D8230C', icon: 'shijian1', text: '待办', value: '04' },
  { color: '#DC3C28', icon: 'shibai', text: '不通过', value: '03' },
  { color: '#858585', icon: 'shijian1', text: '等待', value: '05' },
]


const HFormInput = props => <FormInput horizontal labelWidth={158} componentWidth={230} {...props} />

export default function DevProjectInfo(props) {
  const { currentId, allUserRes, countDynamic, updateDynamic, setProjectId, setProjectInfo, isPMM, isPo, setIsPo, rankOptions, whetherOptions, riskStatusOptions, projectId, setIsCM, isQA, updateOuterFile, setIsImport, isShow, isCMRole, isALL, isBudget, usedData, isPm, setIsPm } = props
  const [expandStage, setExpandStage] = useState(true)
  const [expandOverview, setExpandOverview] = useState(true)
  const [expandBasic, setExpandBasic] = useState(true)
  const [expandMember, setExpandMember] = useState(true)
  const [expandSystem, setExpandSystem] = useState(true)
  const [expandmajorRisk, setExpandmajorRisk] = useState(true)
  const [expandBudget, setExpandBudget] = useState(true)
  const [isFold, setIsFold] = useState(true)
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [mode, setMode] = useState('detail')
  const [showCopyPop, setShowCopyPop] = useState(false)
  const [debounce, setDebounce] = useState(false)
  const [editTitle, setEditTitle] = useState(false)
  const { data: defaultData, doFetch, loading, } = useGet()
  const { data: defaultStageData, doFetch: getStage } = useGet()
  const { data: defaultOverviewData, doFetch: getOverview } = useGet()
  const { data: defaultRepairDeficiencyData, doFetch: getRepairDeficiency } = useGet()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { doFetch: update } = usePost()
  const [keyNodes, setKeyNodes] = useState()
  const [stageQualityAudit, setStageQualityAudit] = useState()

  const { productUsedWorkload, devUsedWorkload, testUsedWorkload, manageUsedWorkload } = useMemo(() => usedData || {}, [usedData])
  const { userprojectType } = useMemo(() => defaultData || {}, [defaultData])
  const { productLine } = useMemo(() => params || {}, [params])
  const { fatherStageVoList, userProjectStageVoList } = useMemo(() => defaultStageData || {}, [defaultStageData])

  // const isPat = useMemo(() => _.includes(['PAT', 'SOL'], type), [type])

  // const isDetail = useMemo(() => mode === 'detail', [mode])
  const isEdit = useMemo(() => mode === 'edit', [mode])
  const isQaEdit = useMemo(() => mode === 'qaEdit', [mode])
  const isEditBudget = useMemo(() => mode === 'editBudget', [mode])
  const isMember = useMemo(() => mode === 'member', [mode])

  const [devProjectTypeOptions, projectStatusRes, productLineOptions, projectRoleOptions, statusOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const projectStatusOpt = useMemo(() => {
    if (_.isEmpty(projectStatusRes)) return []
    return _.concat(projectStatusRes, [])
  }, [projectStatusRes])

  const [productOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes || []
    return [
      d1,
    ]
  }, [optionsRes])

  const productOpt = useMemo(() => {
    if (_.isNil(productLine) && _.isEmpty(productOptions)) return []
    const filterData = _.filter(productOptions, o => o.productLine === productLine)
    return selectOption(filterData, ['productName', 'productId'])
  }, [productLine, productOptions])

  const refresh = useCallback((id, flag = true) => {
    if (_.isNil(id)) return
    doFetch(`${DETAIL_URL}?id=${id}`)
      .then(res => {
        setIsImport(res?.isImport)
        setProjectId(res.projectID)
        const { beginDate, completeDate, rdMemberPermissions } = res
        const dateInterval = { begin: toDate.str14ToDate(beginDate), end: toDate.str14ToDate(completeDate) }
        const userProject = _.map(_.groupBy(rdMemberPermissions, 'globalconstId'), (list, k) => {
          return {
            globalconstId: k,
            projectMembers: _.join(_.map(list, 'projectMembers'), ',')
          }
        })
        const poUser = _.split(_.get(_.find(userProject, o => o.globalconstId === 'PO'), 'projectMembers'), ',')
        const cmUser = _.split(_.get(_.find(userProject, o => o.globalconstId === 'CM'), 'projectMembers'), ',')
        const pmUser = _.split(_.get(_.find(userProject, o => o.globalconstId === 'PM'), 'projectMembers'), ',')
        setIsPm(_.includes(pmUser, gd.User.operator_id))
        setIsPo(_.includes(poUser, gd.User.operator_id))
        setIsCM(_.includes(cmUser, gd.User.operator_id))
        setParams(x => _.assign({}, res, { dateInterval, rdMemberPermissions: userProject, keyNodes: x?.keyNodes }))
        setProjectInfo(_.assign({}, res, { dateInterval, rdMemberPermissions: userProject }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
    if (flag) {
      getStage(`${STAGE_DETAIL_URL}?id=${id}`)
        .catch(err => Messager.show(err._message, { icon: 'error' }))
      getOverview(`${OVERVIEW_DETAIL_URL}?id=${id}`)
        .catch(err => Messager.show(err._message, { icon: 'error' }))
      getRepairDeficiency(`${REPAIR_DEFICENCY_URL}?id=${id}`)
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
  }, [doFetch, getStage, getOverview, getRepairDeficiency, setProjectId, setProjectInfo, setIsPo, setIsCM, setIsImport, setIsPm])

  useEffect(() => {
    if (currentId) {
      refresh(currentId)
    }
  }, [currentId, refresh])

  const copy = useCallback((value) => {
    copyText(value);
    setShowCopyPop(false)
    Messager.show('数据已复制到剪切板', { icon: 'success' });
  }, [])

  const CopyArea = useCallback(() => {
    return <div className={'flex dev-project-title-copy-popover center-y'}>
      <Button onClick={() => copy(_.get(params, 'projectName'))} normal>复制项目名称</Button>
      <Button onClick={() => copy(_.get(params, 'leafProjectId'))} normal>复制ID</Button>
    </div>
  }, [copy, params])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const handChange = useCallback((o, bind) => {
    if (bind === 'productLine') {
      o['productID'] = null
    }
    setParams(o)
  }, [])

  const confirm = useCallback((flag = false, appendObj = {}, isBudget) => {
    if (JSON.stringify(defaultData) === JSON.stringify(params)) {
      setEditTitle(false)
      setMode('detail')
      return Messager.show('无变更', { icon: 'info' })
    }
    if (!check() && !flag) return Messager.show('请填写必填项', { icon: 'error' })
    if (isNil(_.trim(params?.projectName))) return Messager.show('请填写项目名称', { icon: 'error' })
    if (!flag && isBudget) {
      const productWorkload = _.toNumber(_.get(params, 'productWorkload', 0))
      const devWorkload = _.toNumber(_.get(params, 'devWorkload', 0))
      const testWorkload = _.toNumber(_.get(params, 'testWorkload', 0))
      const manageWorkload = _.toNumber(_.get(params, 'manageWorkload', 0))
      const total = (productWorkload + devWorkload + testWorkload + manageWorkload)
      const useday = _.toNumber(_.get(params, 'useday', 0))
      if (total > useday) return Messager.show('产品、研发、测试、管理的预计工作量合计，不允许超过预计工作量，请重新编辑！', { icon: 'error' })
      if (productWorkload < _.toNumber(productUsedWorkload)) return Messager.show(`产品预计工作量需超过已用工作量${_.toNumber(productUsedWorkload)}（人日），请重新编辑，具体使用情况请项目PO在【项目成本预算】页签下查看！`, { icon: 'error' })
      if (devWorkload < _.toNumber(devUsedWorkload)) return Messager.show(`研发预计工作量需超过已用工作量${_.toNumber(devUsedWorkload)}（人日），请重新编辑，具体使用情况请项目PO在【项目成本预算】页签下查看！`, { icon: 'error' })
      if (testWorkload < _.toNumber(testUsedWorkload)) return Messager.show(`测试预计工作量需超过已用工作量${_.toNumber(testUsedWorkload)}（人日），请重新编辑，具体使用情况请项目PO在【项目成本预算】页签下查看！`, { icon: 'error' })
      if (manageWorkload < _.toNumber(manageUsedWorkload)) return Messager.show(`管理预计工作量需超过已用工作量${_.toNumber(manageUsedWorkload)}（人日），请重新编辑，具体使用情况请项目PO在【项目成本预算】页签下查看！`, { icon: 'error' })
    }
    if (debounce) return
    setDebounce(true)
    const { dateInterval } = params || {}
    const { begin, end } = dateInterval || {}
    const postParams = _.assign({}, _.omit(params, ['dateInterval']), {
      beginDate: dateFormat('YYYY-MM-DD HH:MM:SS', begin),
      completeDate: dateFormat('YYYY-MM-DD HH:MM:SS', end),
    }, appendObj)
    update(EDIT_URL, postParams)
      .then(() => {
        Messager.show('修改成功', { icon: 'success' })
        setMode('detail')
        updateDynamic()
        setDebounce(false)
        setEditTitle(false)
        refresh(currentId)
      }).catch((err) => {
        setEditTitle(false)
        setDebounce(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [check, currentId, debounce, defaultData, params, refresh, update, updateDynamic, productUsedWorkload, devUsedWorkload, testUsedWorkload, manageUsedWorkload])

  const memberEdit = useCallback((rdMemberPermissions, flag) => {
    if (debounce) return
    setDebounce(true)
    const list = _.map(rdMemberPermissions, o => _.assign({}, o, { userProjectId: currentId }))
    const projectManager = _.get(_.find(rdMemberPermissions, o => o.globalconstId === 'PO'), 'projectMembersArray')
    update('/rdMemberPermissions/update', list)
      .then(() => {
        if (!flag) confirm(true, { projectManager })
        Messager.show('修改成功', { icon: 'success' })
        setMode('detail')
        refresh(currentId, false)
        updateDynamic()
        setDebounce(false)
      }).catch((err) => {
        setDebounce(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [update, currentId, debounce, updateDynamic, refresh, confirm])

  const handQualityAudit = useCallback(() => {
    const list = _.map(_.groupBy(userProjectStageVoList, 'keyNodes'), arr => _.head(arr))
    const filterData = _.compact(_.map(list, o => {
      const findItem = _.find(userProjectStageVoList, item => (o.keyNodes === item.keyNodes && item.isBaselineRelease === 'Y'))
      if (_.isNil(findItem)) return
      if (_.includes(['09', '10'], _.get(findItem, 'status'))) return
      return o
    }))
    const findReject = _.find(filterData, o => o.qualityResult === '03')
    const findCommission = _.find(filterData, o => o.qualityResult === '04')
    const isAllFinish = _.every(filterData, o => o.qualityResult === '01')
    const isAllConditionFinish = _.every(filterData, o => o.qualityResult === '02')
    if (isAllFinish || isAllConditionFinish) {
      const findItem = _.last(filterData)
      setStageQualityAudit({ id: _.get(findItem, 'id'), mode: 'edit' })
      return
    }
    if (!_.isNil(findReject)) {
      setStageQualityAudit({ id: _.get(findReject, 'id'), mode: 'edit' })
      return
    } else if (!_.isNil(findCommission)) {
      setStageQualityAudit({ id: _.get(findCommission, 'id'), mode: 'edit' })
      return
    } else {
      const findItem = _.find(filterData, o => o.qualityResult === '05')
      setStageQualityAudit({ id: _.get(findItem, 'id'), mode: 'edit' })
      return
    }
  }, [userProjectStageVoList])

  const auditAuthority = useMemo(() => (isPMM || isQA), [isPMM, isQA])

  useEffect(() => {
    if (_.isNil(keyNodes)) return
    setParams(x => _.assign({}, x, { keyNodes }))
  }, [keyNodes])

  const isDisplay = useMemo(() => {
    if (isShow) return (isPMM || isPo)
    return isCMRole || isPMM || isALL
  }, [isShow, isCMRole, isPMM, isALL, isPo])

  return (
    <React.Fragment>
      <div className='dev-project-info-wrap'>
        <div className="dev-project-handle-header flex center-y">
          <div className='handle-title'>
            {
              !editTitle &&
              <div className="dev-title-wrap flex center-y">
                <div className="flex center-y dev-type">{_.toUpper(userprojectType)}</div>
                <div className="dev-title" onClick={() => {
                  if (isDisplay) setEditTitle(true)
                }}>
                  <Tooltip title={_.get(params, 'projectName')}>
                    {_.get(params, 'projectName')}
                  </Tooltip>
                </div>
                <Popover content={<CopyArea />} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                  <Icon name={'fuzhi'} className={'copy-icon'} />
                </Popover>
              </div>
            }
            {//onBlur onEnter
              editTitle && <FormInput
                autoFocus
                labelWidth={100}
                horizontal
                bind={'projectName'}
                label={'项目名称'}
                required
                value={_.get(params, 'projectName')}
                onBlur={() => confirm(true)}
                onEnter={() => confirm(true)}
                onChange={v => setParams(x => ({ ...x, projectName: _.trim(v) }))} maxLength={250} />
            }
            {auditAuthority && <Button primary onClick={handQualityAudit}>质量审核</Button>}
          </div>
          <div className="handle-group flex"></div>
        </div>
        <div className='dev-project-info'>
          <div className='left-detail'>
            {/* 项目阶段 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <span>
                  <Icon
                    name="arrow_drop_down"
                    className='fold-icon'
                    style={{ transform: expandStage ? 'none' : 'rotate(-90deg)', display: 'inline-block' }}
                    onClick={() => setExpandStage(x => !x)}
                  />
                  项目阶段
                </span>
                <Tooltip
                  placement="bottom"
                  overlayClassName='stage-help-tooltip'
                  title={<div className='stage-help-tooltip-content'>
                    <div style={{ marginBottom: 6 }}>QA阶段质量审计状态icon示例：</div>
                    {
                      _.map(STAGE_FLOW_LIST, (o, i) => {
                        return <div key={i} className='flex stage-help-item'>
                          <div className='left-icon'><Icon name={o.icon} style={{ color: o.color }} /></div>
                          <div className='right-text'>{o.text}</div>
                        </div>
                      })
                    }
                  </div>}
                >
                  <span style={{ marginLeft: 6 }}>
                    <Icon name='bangzhu' className='stage-help-icon' />
                  </span>
                </Tooltip>
              </div>
              <div className="area-content-wrap" style={{ height: expandStage ? '' : 0 }}>
                <ProjectStage
                  setKeyNodes={setKeyNodes}
                  statusOptions={statusOptions}
                  data={fatherStageVoList}
                  allData={userProjectStageVoList}
                  {...{ setStageQualityAudit, STAGE_FLOW_LIST, auditAuthority }}
                />
              </div>
            </div>
            {/* 项目概览 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <Icon
                  name="arrow_drop_down"
                  className='fold-icon'
                  style={{ transform: expandOverview ? 'none' : 'rotate(-90deg)' }}
                  onClick={() => setExpandOverview(x => !x)}
                />
                项目概览
              </div>
              <div className="area-content-wrap flex" style={{ height: expandOverview ? '' : 0 }}>
                <ProjectOverview params={_.assign({}, defaultOverviewData, !_.isNil(defaultRepairDeficiencyData) && _.pick(defaultRepairDeficiencyData, ['testNumComplete', 'testNumPercent', 'testNumSum', 'totalDefect', 'defectDiscovery']))} />
              </div>
            </div>
            {/* 基本信息 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <span>
                  <Icon
                    name="arrow_drop_down"
                    className='fold-icon'
                    style={{ display: 'inline-block', transform: expandBasic ? 'none' : 'rotate(-90deg)' }}
                    onClick={() => setExpandBasic(x => !x)}
                  />
                  基本信息
                </span>
                {
                  (isDisplay || isQA) &&
                  <span style={{ marginLeft: isEdit ? 24 : 0 }}>

                    {((isDisplay && !isEdit) || (isQA && !isDisplay && !isQaEdit)) && <TextIconBtn text='' icon='bianji2' onClick={() => {
                      setMode(isDisplay ? 'edit' : 'qaEdit')
                      refresh(currentId, false)
                    }} />}
                    {
                      ((isDisplay && isEdit) || (isQA && isQaEdit)) && <>
                        <Button primary onClick={() => confirm()}>保存</Button>
                        <Button text onClick={() => {
                          setMode('detail')
                          refresh(currentId)
                        }}>取消</Button>
                      </>
                    }
                  </span>
                }
              </div>
              <div className="area-content-wrap" style={{ height: expandBasic ? '' : 0 }}>
                <Form value={params} onChange={handChange} error={error} onError={setError}>
                  <HFormInput label='项目类型' bind='userprojectType' component={!isEdit ? Display : Select} options={filterDisplayFlagOptions(devProjectTypeOptions)} convert={v => convertOptions(v, devProjectTypeOptions)} />
                  <HFormInput label='项目阶段' bind='keyNodes' component={Display} />
                  <HFormInput required label='项目状态' bind='status' component={!isEdit ? Display : CascadeSelect} options={filterDisplayFlagOptions(projectStatusOpt)} convert={v => convertOptions(v, projectStatusOpt)} />
                  <HFormInput label='关联PSA项目' bind='leafProjectId' component={Display} />
                  <HFormInput label='项目财务编码' bind='financialCode' component={Display} convert={v => {
                    if (_.isNil(v)) {
                      if (!isShow) return v
                      if (isDisplay || isPm) {
                        return <div
                          className='application-code-skip'
                          onClick={() => window.open(`${OPEN_URL}?templateId=6276151935863375537`)}
                        >申请编码</div>
                      }
                      return v
                    }
                    return v
                  }} />
                  <HFormInput required label='产品线' bind='productLine' component={!isEdit ? Display : Select} options={filterDisplayFlagOptions(productLineOptions)} convert={v => convertOptions(v, productLineOptions)} />
                  <HFormInput label='产品' bind='productID' component={!isEdit ? Display : Select} options={productOpt} convert={v => convertOptions(v, selectOption(productOptions, ['productName', 'productId']))} />
                  <HFormInput label='实际发布日期' bind='actualReleaseDate' component={Display} convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                  }} />
                  {/* 计划日期 */}
                  <HFormInput required range label='计划日期' bind='dateInterval' component={((isEdit && isQA) || isQaEdit) ? DatePicker : Display} convert={o => {
                    if (_.isNil(o?.begin) || _.isNil(o?.end)) return ''
                    const begin = dateFormat('YYYY-MM-DD', o.begin)
                    const end = dateFormat('YYYY-MM-DD', o.end)
                    return `${begin} 至 ${end}`
                  }} />
                  <HFormInput label='父级项目' bind='pidName' component={Display} />
                  {
                    ((isEdit && isQA) || isQaEdit) &&
                    <HFormInput required label='计划发布时间' bind='scheduledRelease' component={DatePicker}
                      bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                  }
                  {
                    (!isEdit || !isQA) &&
                    <HFormInput required label='计划发布时间' bind='scheduledRelease' component={Display} />
                  }
                  <HFormInput label='TR5/TP5评审通过日期' bind='reviewApprovalDate' component={((isEdit && isQA) || isQaEdit) ? DatePicker : Display}
                    bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)} clear convert={v => {
                      if (_.isNil(v)) return '-'
                      if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                      return dateFormat('YYYY-MM-DD', v) || '-'
                    }} />
                  <HFormInput label='需求变更率' bind='storyChangeRate' component={((isEdit && isQA) || isQaEdit) ? Input : Display}
                    type={'number'} digit={2} min={0} max={99999} suffix={'%'} convert={v => v ? `${v}%` : '-'}
                  />
                  {
                    !isEdit && <div className={'textarea-field-wrap flex'}>
                      <HFormInput required label='项目简介' componentWidth={0} component={Display} convert={() => ''} />
                      <div className={'textarea-show-value'} style={{ width: 634 }}>
                        {
                          _.get(params, 'synopsis')
                        }
                      </div>
                    </div>
                  }
                  {
                    isEdit && <HFormInput required className={'text-area-input-edit'} label='项目简介' bind='synopsis'
                      component={TextAreaInput} componentWidth={634} />
                  }

                </Form>
              </div>
            </div>
            {/* 工时预算 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <span>
                  <Icon
                    name="arrow_drop_down"
                    className='fold-icon'
                    style={{ display: 'inline-block', transform: expandBudget ? 'none' : 'rotate(-90deg)' }}
                    onClick={() => setExpandBudget(x => !x)}
                  />
                  工时预算
                  <Tooltip
                    title='项目预算执行情况，以及工时、预算的变更申请，请项目PO在【项目成本预算】页签下查看和处理！'
                  >
                    <span>
                      <Icon name='bangzhu' style={{ color: '#5477FF', fontSize: 14, marginLeft: 2 }} />
                    </span>
                  </Tooltip>
                </span>
                {
                  isDisplay &&
                  <span style={{ marginLeft: isEditBudget ? 24 : 0 }}>

                    {!isEditBudget && <TextIconBtn text='' icon='bianji2' onClick={() => {
                      setMode('editBudget')
                      refresh(currentId, false)
                    }} />}
                    {
                      isEditBudget && <>
                        <Button primary onClick={() => confirm(false, {}, true)}>保存</Button>
                        <Button text onClick={() => {
                          setMode('detail')
                          refresh(currentId)
                        }}>取消</Button>
                      </>
                    }
                  </span>
                }
              </div>
              <div className="area-content-wrap" style={{ height: expandBudget ? '' : 0 }}>
                <Form value={params} onChange={handChange} error={error} onError={setError}>
                  <HFormInput label='项目预算（元）' bind='projectBudget' component={(isBudget && isEditBudget) ? Input : Display} type={'number'} digit={2} min={0} convert={v => {
                    if (isNil(v)) return null
                    return N2(v)
                  }} />
                  <HFormInput label='预计工作量(人日)' bind='useday' component={(isBudget && isEditBudget) ? Input : Display} type={'number'} digit={1} min={0} />
                  <HFormInput label='产品预计工作量(人日)' bind='productWorkload' component={(isEditBudget) ? Input : Display} type={'number'} digit={1} min={0} />
                  <HFormInput label='研发预计工作量(人日)' bind='devWorkload' component={(isEditBudget) ? Input : Display} type={'number'} digit={1} min={0} />
                  <HFormInput label='测试预计工作量(人日)' bind='testWorkload' component={(isEditBudget) ? Input : Display} type={'number'} digit={1} min={0} />
                  <HFormInput label='管理预计工作量(人日)' bind='manageWorkload' component={(isEditBudget) ? Input : Display} type={'number'} digit={1} min={0} />
                  <HFormInput label='初始预估工作量(人日)' bind='initialUseDay' component={(isBudget && isEditBudget) ? Input : Display} type={'number'} digit={1} min={0} />
                  <HFormInput label='预测收入（元）' bind='predictedRevenue' component={(isBudget && isEditBudget) ? Input : Display} type={'number'} digit={2} min={0} convert={v => {
                    if (isNil(v)) return null
                    return N2(v)
                  }} />
                </Form>
              </div>
            </div>
            {/* 项目成员 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <span>
                  <Icon
                    name="arrow_drop_down"
                    className='fold-icon'
                    style={{ display: 'inline-block', transform: expandMember ? 'none' : 'rotate(-90deg)' }}
                    onClick={() => setExpandMember(x => !x)}
                  />
                  项目成员
                </span>
                {
                  isDisplay &&
                  <span>
                    <TextIconBtn text='' icon='bianji2' onClick={() => {
                      refresh(currentId, false)
                      setMode('member')
                    }} />
                  </span>
                }
              </div>
              <div className="area-content-wrap" style={{ height: expandMember ? '' : 0 }}>
                {
                  _.map(_.get(params, 'rdMemberPermissions'), item => {
                    return <HFormInput
                      key={item.globalconstId}
                      label={findName(projectRoleOptions, item.globalconstId)}
                      component={Display}
                      value={getUserName(allUserRes, _.split(_.get(item, 'projectMembers'), ','))}
                      className={clsx({ 'text-area-detail-style': isDisplay })}
                    />
                  })
                }
              </div>
            </div>
            {/* 重大风险 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <Icon
                  name="arrow_drop_down"
                  className='fold-icon'
                  style={{ transform: expandmajorRisk ? 'none' : 'rotate(-90deg)' }}
                  onClick={() => setExpandmajorRisk(x => !x)}
                />
                重大风险
              </div>
              <div className="area-content-wrap flex" style={{ height: expandmajorRisk ? '' : 0 }}>
                <MajorRisk
                  params={params}
                  confirm={() => confirm()}
                  setParams={setParams}
                  refresh={() => refresh(currentId, false)}
                  {...{ isPMM, isPo, rankOptions, whetherOptions, riskStatusOptions, projectId, isDisplay }}
                />
              </div>
            </div>
            {/* 系统信息 */}
            <div className={`area-wrap`} >
              <div className="area-header flex center-y">
                <Icon
                  name="arrow_drop_down"
                  className='fold-icon'
                  style={{ transform: expandSystem ? 'none' : 'rotate(-90deg)' }}
                  onClick={() => setExpandSystem(x => !x)}
                />
                系统信息
              </div>
              <div className="area-content-wrap" style={{ height: expandSystem ? '' : 0 }}>
                <Form value={params}>
                  <HFormInput label='创建人' bind='inputUser' component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                  <HFormInput label='修改人' bind='updatedUser' component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                  <HFormInput label='创建时间' bind='inputDate' component={Display} convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                  }} />
                  <HFormInput label='修改时间' bind='updatedDate' component={Display} convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                  }} />
                </Form>
              </div>
            </div>
          </div>
          <div className="fold-bar flex center" onClick={() => setIsFold(x => !x)} style={{ width: isFold ? 40 : 22 }}>
            <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{ transform: isFold ? 'none' : 'rotate(180deg)' }} />
          </div>
          <div className={'dynamic-wrap'} style={{ display: isFold ? 'block' : 'none', width: 320 }} key={countDynamic}>
            <ChangeRecord name='研发项目' funcCode={'36'} referenceId={currentId} id={currentId} title={_.get(params, 'projectName')} linkUrl={`/devProjectMgt?initId=${currentId}`} commentWidth={300} todoListName={'问题跟踪'} />
          </div>
        </div>

      </div>
      {
        _.includes(['detail', 'edit'], _.get(stageQualityAudit, 'mode')) &&
        <StageQualityAuditDetail
          close={() => setStageQualityAudit(null)}
          refreshViewList={() => refresh(currentId)}
          allData={userProjectStageVoList}
          currentInfo={stageQualityAudit}
          projectId={currentId}
          // initParams={_.pick(defaultStageData, ['processAuditCompliance', 'processAuditInspection'])}
          {...{ STAGE_FLOW_LIST, updateOuterFile, allUserRes, userprojectType }}
        />
      }
      {
        isMember &&
        <MemberEditLog
          submit={memberEdit}
          defaultData={_.cloneDeep(_.get(params, 'rdMemberPermissions', []))}
          close={() => setMode('detail')}
          loading={loading}
        />
      }
    </React.Fragment>
  )
}

function getUserName(data, userList) {
  const userNameArr = _.map(userList, v => convertOptions(v, data, 'userName', 'userAccount'))
  return _.join(userNameArr, '，')
}

function findName(data, id) {
  return _.get(_.find(data, o => o.value === id), 'text')
}
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {DataGrid, MessageBox} from 'rootnet-ui'
import {Radio} from "rootnet-edit";
import './CreateView.scss'
import {Box} from "../../common/commonComponent";
import OperationList from "../../../project_share/components/OperationList";
import {TextIconBtn} from "../../common/TextIconBtn";
import {Route, Switch, useHistory} from "react-router-dom";
import CreateProjectView from "./controls/CreateProjectView";
import CreateTaskView from "./controls/CreateTaskView";
import CreateIssueView from "./controls/CreateIssueView";
import {useGet} from "../../../utils/hook";
import _ from "lodash";
import {toDate, dateFormat} from 'rootnet-core/dateFormat'
import {Cache} from '../../../base/cache'
import MemberInfoCard from "./controls/MemberInfoCard";
import {isNil} from 'rootnet-core/format'
import {pathSearchFor, strParams} from "../../../utils/publicFun";
import DetailsGoBackWithTextClick from "../../common/DetailsGoBackWithTextClick";

const CACHE_KEY = 'createView'
function getInitParams(){
    return Cache.get(CACHE_KEY)
}

const { str14ToDate } = toDate;

export default function Router() {
    return <Switch>
        <Route path='/createView/createProjectView' component={CreateProjectView}/>
        <Route path='/createView/createTaskView' component={CreateTaskView}/>
        <Route path='/createView/createIssueView' component={CreateIssueView}/>
        <Route component={CreateView}/>
    </Switch>
}

const getProjectColumns = (props) => {
    const {convertDate,projectStatusOptions,convertOptions,setDelInfo} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '项目名称', bind: 'projectName',sortable:true,width: 140, tooltip: true},
        {header: '项目经理', bind: 'projectManagerName',sortable:true,width: 90},
        {header: '状态', bind: 'projectStatus',sortable:true,width: 90, convert: v => convertOptions(v,projectStatusOptions)},
        {header: '开始日期', bind: 'startDate',sortable:true,width: 100,convert: convertDate},
        {header: '结束日期', bind: 'endDate',sortable:true,width: 100,convert: convertDate},
        {header: '产品', bind: 'productName',sortable:true,width: 90},
        {header: '客户', bind: 'customerName',sortable:true,width: 90},
        {header: '操作', bind: '', align: 'center', width: 90, isTransfer: false, convert: renderOperation}
    ]
    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '移除',
                mode: 'delete',
                onClick: ()=>setDelInfo({o, listName:'projectList',primaryKey:'projectId'})
            }
        ];
        return <OperationList options={operateOption}/>;
    }

}

const getVersionTaskColumns = (props) => {
    const {statusOptions,convertOptions,convertDate,setDelInfo} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '项目名称', bind: 'projectName',sortable:true,width: 140, tooltip: true},
        {header: '需求ID', bind: 'interiorReqNo',sortable:true,width: 160, tooltip: true},
        {header: '任务清单', bind: 'projectDetailName',sortable:true,width: 250, tooltip: true},
        {header: '状态', bind: 'projectDetailStatus',sortable:true,width: 100, convert: v => convertOptions(v,statusOptions)},
        {header: '设计负责人', bind: 'detailUserName',sortable:true,width: 100},
        {header: '设计计划完成', bind: 'designCompleteDate',sortable:true,width: 100, convert: convertDate},
        {header: '开发计划完成', bind: 'devCompleteDate',sortable:true,width: 100, convert: convertDate},
        {header: '测试计划完成', bind: 'testCompleteDate',sortable:true,width: 100, convert: convertDate},
        {header: '研发任务ID', bind: 'trackingId',sortable:true,width: 160, tooltip: true},
        {header: '研发任务状态', bind: 'trackingStatus',sortable:true,width: 160, tooltip: true},
        {header: '操作', bind: '', align: 'center', width: 90, isTransfer: false, convert: renderOperation}
    ]
    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '移除',
                mode: 'delete',
                onClick: ()=>setDelInfo({o, listName:'taskList',primaryKey:'projectDetailId'})
            }
        ];
        return <OperationList options={operateOption}/>;
    }

}
const getVersionIssueColumns = (props) => {
    const {convertOptions,convertDate,issueStatusOptions,effectGradeOptions,issueKindOptions,setDelInfo} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '问题编号', bind: 'issueId',sortable:true,width: 160, tooltip: true},
        {header: '发生日期', bind: 'occurDate',sortable:true,width: 100, convert: convertDate},
        {header: '问题简单描述', bind: 'shortDesc',sortable:true,width: 200, tooltip: true},
        {header: '分类标记', bind: 'issuesType',sortable:true,width: 100},
        {header: '问题状态', bind: 'issueStatus',sortable:true,width: 100, tooltip: true, convert: v => convertOptions(v,issueStatusOptions)},
        {header: '修复问题研发任务', bind: 'tracerId',sortable:true,width: 160, tooltip: true},
        {header: '严重程度', bind: 'effectGrade',sortable:true,width: 100, convert: v => convertOptions(v,effectGradeOptions)},
        {header: '问题性质', bind: 'issueKind',sortable:true,width: 100, tooltip: true, convert: v => convertOptions(v,issueKindOptions)},
        {header: '产品名称', bind: 'productName',sortable:true,width: 100, tooltip: true},
        {header: '客户', bind: 'customerName',sortable:true,width: 100},
        {header: '系统', bind: 'custIdName',sortable:true,width: 100},
        {header: '负责人', bind: 'personChargeName',sortable:true,width: 100},
        {header: '分析人', bind: 'principalName',sortable:true,width: 100},
        {header: '记录日期', bind: 'inputDate',sortable:true,width: 100, convert: convertDate},
        {header: '操作', bind: '', align: 'center', width: 90, isTransfer: false, convert: renderOperation}
    ]
    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '移除',
                mode: 'delete',
                onClick: ()=>setDelInfo({o, listName:'issueList',primaryKey:'issueId'})
            }
        ];
        return <OperationList options={operateOption}/>;
    }

}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedLeft: 2,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
});

const CONVERT_OPTIONS_URLS = [
    '/common/globalconst?globalConst=ProjectStatus',
    '/common/globalconst?globalConst=ISSUESTATUS',
    '/common/globalconst?globalConst=EffectGrade',
    '/common/globalconst?globalConst=ISSUEKIND',
    '/common/globalconst?globalConst=PSAProjectStatus',
]

const DEPARTMENT_URLS = [
    '/common/userinfo?indexValidFlag=1',
    '/common/department',
]

const INIT_LIST_URLS = [
    '/viewDetail/contentViewList',
    '/viewDetail/contentProject',
    '/viewDetail/contentItem',
    '/viewDetail/contentIssue',
]

const INIT_CREATE_PARAMS = {
    adminUser:[],
    focusUser:[],
    viewStatus:'0',
    viewName: null
}
const INIT_VIEW_OBJ = {
    taskList: []
}

function CreateView(props) {
    const {location} = props;
    const pathParams = useMemo(() => pathSearchFor(location.search), [location]);
    const {type: initType,scene = 'add', viewId, clear = 'none', hasChanged = 'none'} = pathParams
    const history = useHistory();
    const [type, setType] = useState(initType || 'Prj')
    const {data: userInfoRes} = useGet(DEPARTMENT_URLS)
    const [expandIndex, setExpandIndex] = useState(0)
    const {data: convertOptionsRes} = useGet(CONVERT_OPTIONS_URLS)
    const [delInfo, setDelInfo] = useState(null)
    const {data: typeRes} = useGet('/common/globalconst?globalConst=viewType')
    const {data: initListRes, doFetch: getInitListRes, loading} = useGet()
    const [createParams, setCreateParams] = useState(INIT_CREATE_PARAMS)
    const [viewObj, setViewObj] = useState(INIT_VIEW_OBJ)
    const [isChangedForm, setIsChangedForm] = useState(hasChanged)
    const [isShowExitDialog, setIsShowExitDialog] = useState(false)

    const isLoading = useMemo(()=>{
        return scene === 'add' ? false: loading
    },[scene,loading])

    useEffect(()=>{
        if(scene !== 'edit') return
        const handleUrls = _.map(INIT_LIST_URLS, url => `${url}?viewId=${viewId}`)
        getInitListRes(handleUrls)
    },[scene, viewId, getInitListRes])

    useEffect(()=>{
        if(clear === 'done') return
        Cache.set(CACHE_KEY, null)
        Cache.set('CREATE_VIEW_PARAMS', null)
    },[pathParams,clear])

    const initCreateParams = useMemo(()=>{
        const [d1] = initListRes || []
        return d1
    },[initListRes])

    const globalConstOptions = useCallback((res)=>{
        return _.map(res, x => ({value: x.interiorId, text: x.displayName}))
    },[])

    const typeOptions = useMemo(()=>{
        return globalConstOptions(typeRes)
    },[typeRes,globalConstOptions])

    const delList = useCallback((props)=>{
        const {o, listName, primaryKey} = props
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, listName)
        all[listName] = listName === 'projectList'?delProject(o, currentCache):_.filter(currentCache, x => x[primaryKey] !== o[primaryKey])
        Cache.set(CACHE_KEY, all)
        setDelInfo(null)
        setViewObj({...all})
        function delProject(obj, list){
            const filterList = _.filter(list, x => x[primaryKey] !== obj[primaryKey])
            const parent = _.find(filterList, x => x[primaryKey] === obj.pid)
            if(!_.isNil(parent)) return delProject(parent, filterList)
            return filterList
        }
    },[])

    // 初始化列表
    const [taskList,issueList,projectList] = useMemo(()=>{
        if(clear === 'none') return []
        if(_.isNil(getInitParams())){
            if(_.isNil(initListRes)) return []
            const [, editProjectList, editTaskList, editIssueList] = initListRes || []
            const all = {
                projectList: editProjectList,
                taskList: editTaskList,
                issueList: editIssueList
            }
            Cache.set(CACHE_KEY,all)
            return [editTaskList, editIssueList, editProjectList]
        }
        const cache = getInitParams() || viewObj
        const {taskList,issueList,projectList} = cache
        const handleProjectList = _.filter(projectList, x => x.leafProjectFlag !== '0')
        return [taskList, issueList, handleProjectList]
    },[initListRes, viewObj, clear])

    const optionsObj = useMemo(()=>{
        const [d1,d2,d3,d4,d5] = convertOptionsRes || []
        const statusOptions = globalConstOptions(d1)
        const issueStatusOptions = globalConstOptions(d2)
        const effectGradeOptions = globalConstOptions(d3)
        const issueKindOptions = globalConstOptions(d4)
        const projectStatusOptions = globalConstOptions(d5)
        return {statusOptions,issueStatusOptions,effectGradeOptions,issueKindOptions,projectStatusOptions}
    },[convertOptionsRes,globalConstOptions])

    const convertOptions = useCallback((v, options)=>{
        return _.get(_.find(options, x => x.value === v),'text')
    },[])

    const convertDate = useCallback((v)=>{
        return dateFormat('YYYY-MM-DD', str14ToDate(v))
    },[])

    const option = useCallback((type = 'project')=>{
        const props = {convertOptions,convertDate,setDelInfo,...optionsObj}
        const strategy = {
            project: getProjectColumns(props),
            versionTask: getVersionTaskColumns(props),
            versionIssue: getVersionIssueColumns(props)
        }
        return getTableOption(strategy[type])
    },[optionsObj, convertOptions, convertDate,setDelInfo])

    const clickExpandIcon = useCallback((isExpanded, index)=>{
        setExpandIndex(isExpanded?0:index)
    },[])

    const Extra = useCallback((props)=>{
        const {text, url, index, listName} = props
        const isExpanded = index === expandIndex
        return <div className='flex'>
            <TextIconBtn icon='tiaojianshouqi' text={isExpanded?'收起':'展开'} onClick={()=>clickExpandIcon(isExpanded, index)}/>
            <TextIconBtn icon='tianjia' text={text} onClick={()=>toListPage(listName,url,isChangedForm)}/>
        </div>
        function toListPage(listName,url,isChangedForm){
            Cache.set('CREATE_VIEW_PARAMS', createParams)
            const handleUrl = `${url}?${strParams({...pathParams, clear: 'done',type: type, hasChanged: isChangedForm})}`
            const taskList = _.get(Cache.get(CACHE_KEY),'taskList')
            const isTaskList = listName === 'task' && !(isNil(taskList) || taskList.length === 0)
            const toUrl = isTaskList ? `${handleUrl}&id=${taskList[0].id}`:handleUrl
            history.push(toUrl)
        }
    },[history,clickExpandIcon,expandIndex,pathParams,createParams,type,isChangedForm])

    useLayoutEffect(()=>{
        const cacheCreateParams = Cache.get('CREATE_VIEW_PARAMS')
        if(_.isNil(cacheCreateParams)){
            if(_.isNil(initCreateParams)) return
            const initParams = {
                ...initCreateParams,
                beginDate: toDate(_.get(initCreateParams, 'beginDate')) ,
                endDate: toDate(_.get(initCreateParams, 'endDate')) ,
                adminUser:emptyArray(_.get(initCreateParams, 'adminUser')),
                focusUser:emptyArray(_.get(initCreateParams, 'focusUser')),
            }
            return setCreateParams(initParams)
        }
        setCreateParams(cacheCreateParams)
        function emptyArray(v){
            return _.isNil(v)?[]:v
        }
        function toDate(v){
            return isNil(v)?null:str14ToDate(v)
        }
    },[initCreateParams])

    const goBack = useCallback(()=>{
        props.history.push({
            pathname: '/projectView',
            pathParams: pathParams
        })
    },[pathParams,props])

    const beforeExit = useCallback(()=>{
        const [, initProjectList, initTaskList, initIssueList] = initListRes || []
        const compare = [
            {
                oldList: initProjectList,
                newList: projectList,
                keyName: 'projectId'
            },
            {
                oldList: initTaskList,
                newList: taskList,
                keyName: 'projectDetailId'
            },
            {
                oldList: initIssueList,
                newList: issueList,
                keyName: 'issueId'
            },
        ]

        const isChangedList = _.some(compare, x => isChanged(x.oldList, x.newList, x.keyName))
        if(isChangedForm === 'none' && !isChangedList){
            goBack()
        }else{
            setIsShowExitDialog(true)
        }

        function isChanged(oldList, newList, keyName){
            const oldKeyList = _.map(oldList, x => x[keyName])
            const newKeyList = _.map(newList, x => x[keyName])
            let hasChanged = false
            _.forEach(oldKeyList, x => {
                if(!_.includes(newKeyList, x)) hasChanged = true
            })
            _.forEach(newKeyList, x => {
                if(!_.includes(oldKeyList, x)) hasChanged = true
            })
            return hasChanged
        }
    },[initListRes,projectList,taskList,issueList,goBack,isChangedForm])

    return  <div className='create-view fill flex-y'>
        <div>
            {scene === 'add' && <div className={'flex type-radio-group center-y'}>
                {
                    _.map(typeOptions, x => (<div key={x.value}>
                        <Radio value={type===x.value} name='type' onChange={()=>setType(x.value)} style={{cursor: 'pointer'}}>
                            <span onClick={()=>setType(x.value)}>{x.text}</span>
                        </Radio>
                    </div>))
                }
            </div>}
            {scene === 'edit' && <DetailsGoBackWithTextClick onClick={beforeExit} />}
        </div>
        <div className="card-group flex">
            <div className="left-card-wrap flex-y">
                {
                    type === 'Prj' &&
                    <Box title='实体项目' className='flex-y x-card-singlegrid left-card' data={projectList} loading={isLoading}
                         extra={<Extra text='关联项目' url={'/createView/createProjectView'}/>}>
                        <DataGrid option={option('project')} data={projectList}/>
                    </Box>
                }
                {
                    type === 'Ver' &&
                    <div className='version-card-group flex-y'>
                        <Box title='实体项目' className={`left-card x-card-singlegrid ${[0,1].includes(expandIndex)?'':'narrow-card'}`} data={projectList} loading={isLoading}
                             extra={<Extra listName='project' text='关联项目' url={'/createView/createProjectView'} index={1}/>}>
                            <DataGrid option={option('project')} data={projectList}/>
                        </Box>
                        <Box title='任务清单' className={`left-card x-card-singlegrid ${[0,2].includes(expandIndex)?'':'narrow-card'}`} data={taskList} loading={isLoading}
                             extra={<Extra listName='task' text='关联任务' url={'/createView/createTaskView'} index={2}/>}>
                            <DataGrid option={option('versionTask')} data={taskList}/>
                        </Box>
                        <Box title='ISSUE清单' className={`left-card x-card-singlegrid ${[0,3].includes(expandIndex)?'':'narrow-card'}`} data={issueList} loading={isLoading}
                             extra={<Extra listName='issue' text='关联ISSUE' url={'/createView/createIssueView'} index={3}/>}>
                            <DataGrid option={option('versionIssue')} data={issueList}/>
                        </Box>
                    </div>
                }
            </div>
            <MemberInfoCard {...{userInfoRes,convertOptions,type,history,createParams, setCreateParams,initListRes, scene, setIsChangedForm}}/>
        </div>
        {
            delInfo &&
            <MessageBox header='提示' confirm={() => delList(delInfo)} cancel={() => setDelInfo(null)} className={'content-center-dialog'}>
            确认移除吗？
            </MessageBox>
        }
        {
            isShowExitDialog &&
            <MessageBox header='提示' confirm={goBack} cancel={()=>setIsShowExitDialog(false)} className={'content-center-dialog'}>
                当前内容未保存，确定离开吗？
            </MessageBox>
        }
    </div>

}
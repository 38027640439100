import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Loader, Popup,Messager,Tooltip,MessageBox} from 'rootnet-ui'
import {Icon} from '../../../../components'
import Api from '../../../../base/task'
import EditDialog from './EditDialog'
import _ from 'lodash'
import TrackingListDialog from "./TrackingListDialog";
import IssuesDialog from "./IssuesDialog";
import LeaveDialog from "./LeaveDialog";
import DevelopProjectDialog from "./DevelopProjectDialog";
import ServiceDialog from "./ServiceDialog";
import {dateFormat,toDate} from 'rootnet-core/dateFormat'
import WaitingWorkDialog from './WaitingWorkDialog'

const {str14ToDate} = toDate

const statusList = {
    reject: {text: '已拒绝', color: '#F5221B'},
    submit: {text: '待确认', color: '#FA9A14'}
}

function List(props) {
    const [isShow, setIsShow] = useState(false)
    const [jobInfo, setJobInfo] = useState({})
    const {contData = [], loading, getWeekCont, commonInfo,refresh,hasCostRate,isSelf,searchUser,lastCutOffDate = '',limitCutOffDate,beforeCutOffDate} = props
    const {serviceList, otherList, jobTypeList} = commonInfo
    const [windowHeight, setWindowHeight] = useState(document.documentElement.clientHeight)
    const [dialogData, setDialogData] = useState({})
    const cardGroupRef = useRef(null)
    const [cardHeightList, setCardHeightList] = useState([])
    const [usedTime, setUsedTime] = useState(0)
    const [cardDetail, setCardDetail] = useState()
    const [clickDate, setClickDate] = useState()
    const [delDetail, setDelDetail] = useState(null)

    useEffect(()=>{
        setCardHeightList([])
    },[contData])

    useLayoutEffect(()=>{
        if(cardGroupRef.current){
            setTimeout(()=>{
                try{
                    setCardHeightList(_.map(cardGroupRef.current.querySelectorAll('.card-group'), x=>_.min([x.clientHeight,windowHeight-240])))
                }catch (err){
                    console.error(err)
                }
            },100)
        }
    },[windowHeight,contData,refresh])

    const onResize = useCallback(() => {
        setWindowHeight(document.documentElement.clientHeight)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return (() => {
            window.removeEventListener('resize', onResize)
        })
    }, [onResize,contData,refresh])

    const jobTypeId = useMemo(()=>_.get(_.find(jobTypeList, x => x.name === dialogData.mode),'id'),[jobTypeList,dialogData])

    const closeDialog = useCallback(() => {
        setDialogData({})
        setCardDetail(null)
    },[])

    const dialogParams = useMemo(()=>{
        return {
            refresh: getWeekCont,
            cardDetail: cardDetail,
            usedTime,
            close: closeDialog,
            jobTypeList,
            isSelf,
            searchUser
        }
    },[getWeekCont,cardDetail,usedTime,closeDialog,jobTypeList,isSelf,searchUser])

    return <div className='worktimeContent'>
        {
            //   (!loading&&!noData)?<Nodata text='点击右上角添加工时按钮添加内容吧～'/>
            // :
            <div className='content'>
                <div className="container fill" ref={cardGroupRef}>
                    {
                        _.map(contData, (item, index) => {
                            const {hour = '00', minute = '00'} = toHourMinute(item.totalTime)
                            // const isAccord = _.toNumber(hour) >= 8
                            // const isFinish=isAccord&&item.itemStatus
                            return <div className='card' key={item.date} onClick={()=>{setClickDate(item.date)}}>
                                {/*<div className='schedule' style={{background: isFinish?'#33CC8A':'#F5221B'}}></div>*/}
                                <div className="time-header flex">
                                    <span
                                        className='dateItem'>{convertDate(new Date(item.date))}({item.whichDay})</span>
                                    <span className='totalTime'
                                          style={{color: getTotalColor(_.toNumber(hour),_.toNumber(minute))}}>{`${hour}小时${minute}分钟`}</span>
                                </div>
                                <div className="card-group-wrap" style={{height: cardHeightList[index]?cardHeightList[index]:8}}>
                                    <div className={[`card-group ${cardHeightList[index]>=windowHeight-248?'overflow':''}`,]} >
                                        {
                                            _.map(item.workCont, (items,index) => {
                                                const options = {...items, limitCutOffDate,lastCutOffDate,beforeCutOffDate, handleClick}
                                                return <div key={index} className='card-item-warp' onClick={()=> {
                                                    setCardDetail(items)
                                                }}>
                                                    <CardItem options={options} getWeekCont={getWeekCont} usedTime={_.toNumber(item.totalTime)}
                                                              setDelDetail={setDelDetail} isSelf={isSelf}/>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                {isSelf && <AddTimeBtn jobTypeList={jobTypeList} setDialogData={setDialogData} date={item.date}
                                             otherList={otherList}
                                             usedTime={_.toNumber(item.totalTime)} setUsedTime={setUsedTime}
                                             setClickDate={setClickDate}
                                             setJobInfo={setJobInfo} hasCostRate={hasCostRate}
                                            {...{lastCutOffDate,limitCutOffDate,beforeCutOffDate}}/>}
                            </div>
                        })
                    }
                </div>
            </div>}
        {loading && <Loader fill/>}
        {isShow &&
        <EditDialog jobInfo={jobInfo} jobType={jobTypeList} close={()=>{setIsShow(false);closeDialog()}} modify={true}
                    getWeekCont={getWeekCont} usedTime={usedTime} date={clickDate} mode={dialogData.mode}
                    isSelf={isSelf} searchUser={searchUser} {...{lastCutOffDate,limitCutOffDate,beforeCutOffDate}}/>}
        {dialogData.id === 24 && <TrackingListDialog jobType={jobTypeId} {...dialogParams} {...dialogData}/>}
        {[8,39,19,6,7,38].includes(dialogData.id) && <IssuesDialog jobType={dialogData.id} {...dialogParams} {...dialogData}/> }
        {dialogData.id === 13  && <LeaveDialog jobType={jobTypeId} {...dialogParams} {...dialogData}/>}
        {dialogData.id === 9999 && <WaitingWorkDialog jobType={jobTypeId} {...dialogParams} {...dialogData} />}
        {[25,15,28].includes(dialogData.id)&& <DevelopProjectDialog jobType={dialogData.id} {...dialogParams} {...dialogData}/>}
        {dialogData.id === 2001 && <ServiceDialog jobType={dialogData.id} {...dialogParams} {...dialogData}/>}
        {
            dialogData.id && ![24,8,39,19,6,7,38,13,25,15,28,2001,9999].includes(dialogData.id) &&
                <EditDialog  close={()=>{setIsShow(false);closeDialog()}} jobInfo={jobInfo} modify={false} getWeekCont={getWeekCont}
                             usedTime={usedTime} date={clickDate} mode={dialogData.mode} isSelf={isSelf}
                             searchUser={searchUser} {...{lastCutOffDate,limitCutOffDate,beforeCutOffDate}}/>
        }
        {
            delDetail && <MessageBox className={'content-center-dialog'} header='提示' confirm={ ()=>delCardItem(delDetail) } cancel={ ()=>setDelDetail(null) }>
                确认删除吗？
            </MessageBox>
        }
    </div>



    async function delCardItem(id){
        try {
            await Api.post('/worktime/edit', [{id, actionType: 'DEL'}])
            Messager.show('删除成功！', {icon: 'success'});
            setDelDetail(null)
            await getWeekCont()
        } catch (err) {
            Messager.show(err.Message, {icon: 'error'});
        }
    }

    function getTotalColor(hour,minute) {
        if(hour === 0 && minute === 0) return '#7A8199'
        if(hour < 8) return '#E15722'
        return '#5477FF'
    }

    function handleClick(options,hours) {
        // if(!isSelf) return
        setUsedTime(hours)

        const {jobTypePid = '', jobType, utFlag = '', id} = options
        if ([...serviceList, ...otherList].includes(jobTypePid)) {
            const type = otherList.includes(jobTypePid) ? 'other' : 'service'
            const children = _.get(_.find(jobTypeList, {id: jobTypePid}), 'children')
            const site = _.findIndex(children, {id: jobType})

            setJobInfo({id, utFlag, type, children, site,jobTypeId: options.jobTypePid})
            return setIsShow(true)
        }

        const hasSub = [25,26]
        let jobTypeItem =  _.find(jobTypeList, item =>  item.id === options.jobType )

        if(_.isNil(jobTypeItem)){
            _.forEach(jobTypeList, item =>{
                _.forEach(item.children, child =>{
                    if(child.id === options.jobType) jobTypeItem = _.assign({},child,{id: item.id})
                    _.forEach(child.children, sub =>{
                        if(sub.id === options.jobType) jobTypeItem = _.assign({},child,{id: item.id})
                    })
                })
            })
        }
        if(jobTypeItem.id === 8) jobTypeItem = _.assign({},jobTypeItem,{id: 39})
        if(hasSub.includes(jobTypeItem.id)){
            _.forEach(jobTypeList, item =>{
                _.forEach(item.children, child =>{
                    if(child.id === options.jobType) jobTypeItem = _.assign({},child,{id: child.id})
                    _.forEach(child.children, sub =>{
                        if(sub.id === options.jobType) jobTypeItem = _.assign({},child,{id: child.id})
                    })
                })
            })
        }
        const date = dateFormat('YYYY-MM-DD',str14ToDate(options.workDate))
        let beforeCutoffDay
        let showCutoffDay
        const now = dateFormat('YYYY-MM-DD',new Date())
        if(date <= beforeCutOffDate) {
            beforeCutoffDay = true
            showCutoffDay = beforeCutOffDate
        }else if(date <= lastCutOffDate && now > limitCutOffDate){
            beforeCutoffDay = true
            showCutoffDay = lastCutOffDate
        }else{
            beforeCutoffDay = false
        }
        setDialogData({mode:jobTypeItem.name,id:jobTypeItem.id,date:date,detail:jobTypeItem,beforeCutoffDay,showCutoffDay })
    }


}

function AddTimeBtn(props) {
    const {jobTypeList, setDialogData, date, usedTime, setUsedTime,setClickDate,otherList,setJobInfo,lastCutOffDate,limitCutOffDate,beforeCutOffDate} = props //hasCostRate
    const moreRef = useRef(null)
    const subRef = useRef(null)
    const [showPop, setShowPop] = useState(false)
    const [showSubPop, setShowSubPop] = useState(null)
    const [nowDate, setNowDate] = useState(dateFormat('YYYYMMDD',new Date()))
    const subMenuList = useMemo(()=>_.get(_.find(jobTypeList, x=>x.id===showSubPop),'children'),[showSubPop,jobTypeList])
    const handleJobTypeList = useMemo(()=>{
        const clickDate = date.replace(/-/g, '')
        if(clickDate > nowDate) return _.filter(jobTypeList, item => item.id === 13)
        return jobTypeList
    },[date,jobTypeList,nowDate])

    useOnClickOutside(moreRef,subRef, () => {
        setShowPop(false)
        setShowSubPop(null)
    })
    return <div>
        <div className='add-time' onClick={() => {
            if(date <= beforeCutOffDate) return Messager.show(`${lastCutOffDate}前的成本已核算，不允许修改工时`, {icon: 'error'})
            const now = dateFormat('YYYY-MM-DD',new Date())
            if(date <= lastCutOffDate && now > limitCutOffDate) return Messager.show(`${lastCutOffDate}前的成本已核算，不允许修改工时`, {icon: 'error'})
            setNowDate(dateFormat('YYYYMMDD',new Date()))
            setShowPop(x => !x)
            setClickDate(date)
        }}>
            <Icon name='zengjia' style={{marginRight: 5}}/>
            <span className='add-time-text'>
                添加工时
                {
                    showPop && <Popup portal open={showPop} placement='auto' className='show-pop'>
                        <div className='work-time-select-list' ref={moreRef}>
                            {_.map(handleJobTypeList, (item, index) =>
                                <div className={`work-time-select-list-item flex ${index !==0 && item.sort !== handleJobTypeList[index-1].sort?'top-border':''} ${showSubPop === item.id ? 'active': ''}`}
                                     key={item.id} onMouseEnter={(e) => menuClick(e, item, 'hover')} onClick={(e) => menuClick(e, item, 'click')}>
                                <div className='job-type-name'>{item.name}</div>
                                {
                                    (item.utFlag === 'Y' || (!_.isEmpty(item.children) && _.every(item.children, x => x.utFlag === 'Y'))) &&
                                    <div className='ut'>UT</div>
                                }
                                {
                                    [25,26].includes(item.id) &&
                                    <Icon className={'show-child-icon'} name={'xianghou'}/>
                                }
                                {
                                    [25,26].includes(item.id) && item.id === showSubPop && <Popup portal open={showSubPop} placement='right' className='show-pop'>
                                        <div className='work-time-select-list' ref={subRef}>
                                            {_.map(subMenuList, item => <div className='work-time-select-list-item' key={item.id} onClick={(e) => menuClick(e, item, 'click')}>
                                                <div className='job-type-name'>{item.name}</div>
                                                {
                                                    (item.utFlag === 'Y' || (!_.isEmpty(item.children) && _.every(item.children, x => x.utFlag === 'Y'))) &&
                                                    <div className='ut'>UT</div>
                                                }
                                            </div>)}
                                        </div>
                                    </Popup>
                                }
                            </div>)}
                        </div>
                    </Popup>
                }
            </span>
        </div>
    </div>

    function menuClick(e, v, action) {
        e.preventDefault();
        e.stopPropagation();
        if(action === 'hover' && ![25,26].includes(v.id)) return setShowSubPop(null)
        if([25,26].includes(v.id)){
            setShowSubPop(v.id)
            return
        }
        setJobInfo(_.assign(v,{type:otherList.includes(v.id)?'other':'service'}))
        setUsedTime(usedTime)
        setDialogData({mode: v.name, date,id:v.id, detail: v})
        setShowPop(false)
        setShowSubPop(null)
    }
}

function convertDate(date) {
    return `${date.getMonth() + 1}月${date.getDate()}日`
}

function CardItem(props) {
    const {options={},usedTime,setDelDetail,isSelf} = props
    const statusRef = useRef(null)
    const {content = '', id = '', auditUseTime = '', utFlag = '', status = '', auditPersonName = '',
        workDate = '', limitCutOffDate= '' ,lastCutOffDate = '',beforeCutOffDate, handleClick, remark} = options //需要修改key依赖的属性
    const {hour = '00', minute = '00'} = toHourMinute(auditUseTime)
    const showFlag = ['submit', 'reject']
    const color = _.get(statusList[status], 'color')

    const canEdit = useMemo(()=>{
        const date = dateFormat('YYYY-MM-DD')(toDate.str14ToDate(workDate))
        if(date <= beforeCutOffDate) return false
        const now = dateFormat('YYYY-MM-DD',new Date())
        if(date <= lastCutOffDate && now > limitCutOffDate) return false
        return true
    },[limitCutOffDate,lastCutOffDate,workDate,beforeCutOffDate])

    const tooltipText = useMemo(()=>{
        return <div>
            <div>{content}</div>
            <div>{remark}</div>
        </div>
    },[content,remark])
    return <div key={id} className='cardItem'>
        <div className='cardTitle'>
            <p className='status'>
                {showFlag.includes(status) && <>
                    <span className={'optionStatus'} ref={statusRef}
                          style={{color: `${color}`,border: `1px solid ${color}`}}>{_.get(statusList[status], 'text')}</span>
                    <span className='optionName'>{auditPersonName}</span>
                </>}
            </p>
            <p className='delUt'>
                {utFlag === 'Y' && <em className='ut'>UT</em>}
            </p>
        </div>
        <div className='cardCont' onClick={() => { handleClick(options,usedTime) }}>
            <p>
                <span>{`${hour}小时${minute}分钟`}</span>
                { canEdit && isSelf &&
                <Icon name='shanchu' onClick={e => handleDelete(e, id)}></Icon>}
            </p>
            <Tooltip title={tooltipText} arrow={false} style={{maxWidth: 280,fontSize: 14}}>
                <span className='card-item-content'>{content}</span>
            </Tooltip>
        </div>
        { _.get(options,'rejectReason') && <Tooltip title={_.get(options,'rejectReason')} target={statusRef.current}/>}
    </div>

    function handleDelete(e, id) {
        e.preventDefault()
        e.stopPropagation()
        setDelDetail(id)
    }
}

function toHourMinute(minutes) {
    minutes = _.toNumber(minutes)
    if (!_.isNaN(minutes)) {
        let hour = Math.floor(minutes / 60)
        if (hour < 100)
            hour = ('00' + hour).slice(-2);
        const minute = ('00' + minutes % 60).slice(-2)
        return {hour, minute};
    }
    return {hour: '00', minute: '00'}
}

function useOnClickOutside(ref1,ref2, handler) {
    useEffect(() => {
            const listener = event => {
                if(ref2.current){
                    if(ref1.current.contains(event.target) || ref2.current.contains(event.target)) return
                }else{
                    if (!ref1.current || ref1.current.contains(event.target)) return
                }
                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref1,ref2, handler]
    );
}

export default List
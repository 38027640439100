import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Drawer, Tooltip, Messager} from 'rootnet-ui'
import {Radio} from 'rootnet-edit'
import './ApprovalDrawer.scss'
import TextAreaWithTooltip from "./TextAreaWithTooltip";
import _ from 'lodash'
import {useApi, useGet} from "../../../utils/hook";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import {strParams} from "../../../utils/publicFun";

const WHICH_OPTIONS = {
    'Add': '0',
    'Edit': '1',
    'Del': '2'
}

const BEFORE_DATA = [
    {
        url: '/field/get',
        params: ['fieldCode']
    },
    {
        url: '/fieldDict/get',
        params: ['fieldDictId','fieldDictSubCode']
    },
    {
        url: '/fieldType/get',
        params: ['fieldTypeCode']
    },
    {
        url: '/dictStandardRoot/get',
        params: ['id']
    },
]

function ApprovalDrawer(props) {
    const {open, cancel, currentList, refresh, option, optionSlice, tabIndex, formatTime, hasSelection} = props
    const [isAgree, setIsAgree] = useState(null)
    const [memo, setMemo] = useState()
    const {data: beforeChangeData, doFetch: getBeforeChangeData} = useGet()
    const {doFetch} = useApi()
    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(()=>{
        if(_.isEmpty(currentList) || currentList.length !== 1 || _.get(currentList,'action') === 'Add') return
        const beforeDetail = BEFORE_DATA[tabIndex]
        const getParams = {}
        _.forEach(beforeDetail.params, x => getParams[x] = _.get(currentList[0], x))
        getBeforeChangeData(beforeDetail.url + '?' + strParams(getParams))
    },[currentList,getBeforeChangeData,tabIndex])

    const handleOption = useMemo(() => option.columns.slice(hasSelection?1:0,hasSelection?optionSlice+1:optionSlice),[option,optionSlice,hasSelection])

    const handleOptionExtra = useMemo(() => option.columns.slice(hasSelection?optionSlice+1:optionSlice,-4),[option,optionSlice,hasSelection])

    const diffBindList = useMemo(() => {
        if(_.some([currentList,beforeChangeData,handleOption],_.isEmpty) || currentList.length !== 1) return []
        const diff = _.filter(handleOption, x => _.get(currentList[0],x.bind)!== _.get(beforeChangeData,x.bind))
        return _.map(diff, x => x.bind)
    },[currentList,beforeChangeData,handleOption])

    const close = useCallback(()=>{
        setIsAgree(null)
        setMemo(null)
        cancel()
    },[cancel])

    const confirm = useCallback(()=>{
        if(!check(isAgree, memo)) return
        let postParams = {}
        let url = ''
        setSubmitDisable(true)
        if(currentList.length === 1){
            url = '/field/updateAudit'
            postParams = {
                ...currentList[0],
                isAgree,
                whichOption: WHICH_OPTIONS[currentList[0].action],
                whichPage: _.toString(tabIndex),
                auditMemo: memo,
                createOptId: currentList[0].optId
            }
        }else{
            url = '/field/updateAuditList'
            postParams = _.map(currentList, x => _.assign({},x,{
                isAgree,
                whichOption: WHICH_OPTIONS[x.action],
                whichPage: _.toString(tabIndex),
                auditMemo: memo,
                createOptId: x.optId
            }))
        }
        doFetch(url,'post',postParams).then(()=>{
            setSubmitDisable(false)
            Messager.show('审核成功',{icon: 'success'})
            refresh()
            close()
        }).catch(err => {
            setSubmitDisable(false)
            Messager.show(err._message,{icon: 'error'})
        })
    },[isAgree, memo,close,doFetch,refresh,currentList,tabIndex])

    return <Drawer open={open} cancel={close} onOutsideClick={close} header={'审核'} confirmButtonDisabled={submitDisable} confirmButtonText={submitDisable?'提交中':'提交'}
                   className={'approval-drawer'} contentClassName={'approval-drawer-content flex-y'} demount={true} confirm={confirm}>
        {
            currentList.length === 1 &&
                <>
                    <div className="contrast flex">
                        {
                            _.get(currentList[0],'action') !== 'Del' &&
                            <div className="changed">
                                <div className="inner-header">变更后</div>
                                {
                                    _.map(handleOption, item => <DisplayItem key={uniqKeyFor()} formatTime={formatTime} label={item.header} text={_.get(currentList[0],item.bind)} isChanged={_.includes(diffBindList,item.bind)}/>)
                                }
                            </div>
                        }
                        {
                            _.get(currentList[0],'action') !== 'Add' &&
                            <div className="before-change">
                                <div className="inner-header">变更前</div>
                                {
                                    _.map(handleOption, item => <DisplayItem key={uniqKeyFor()} formatTime={formatTime} label={item.header} text={_.get(beforeChangeData,item.bind)} isChanged={_.includes(diffBindList,item.bind)}/>)
                                }
                            </div>
                        }
                    </div>
                    <div className="operate-info">
                        <div className="inner-header">操作信息</div>
                        <div className="operate-detail flex">
                            {
                                _.map(handleOptionExtra, item => <DisplayItem key={uniqKeyFor()} formatTime={formatTime} label={item.header} text={_.get(currentList[0],item.bind)}/>)
                            }
                        </div>
                        <div className={'memo-wrap'}>
                            <DisplayItem key={uniqKeyFor()} label={'申请理由'} text={_.get(currentList[0],'memo')} textMaxWidth={470}/>
                        </div>
                    </div>
                </>
        }
        <div className="approval-area">
            <div className="inner-header">审核信息</div>
            <div className="approval-select flex">
                <div className="approval-wrap" onClick={()=>setIsAgree('2')}>
                    <Radio value={isAgree === '2'}>通过</Radio>
                </div>
                <div className="approval-wrap" onClick={()=>setIsAgree('1')}>
                    <Radio value={isAgree === '1'}>驳回</Radio>
                </div>
            </div>
            <TextAreaWithTooltip value={memo} onChange={setMemo} placeholder={'请输入审核说明'} required={isAgree === '1'}/>
        </div>
    </Drawer>
}

function check(isAgree, memo){
    if(_.isNil(isAgree)){
        Messager.show('请选择审核结果',{icon: 'error'})
        return false
    }
    if(isAgree === '1' && (_.isNil(memo) || memo === '')){
        Messager.show('请输入审核说明',{icon: 'error'})
        return false
    }
    return true
}

function DisplayItem(props){
    const {label, text, isChanged, formatTime, textMaxWidth = 300} = props
    const showText = useMemo(()=>{
        return formatTime && (label === '操作时间') ? formatTime(text) : text
    },[label,text,formatTime])
    return <div className={`display-item flex ${isChanged?'changed':''}`}>
        <div className="label">{label}:</div>
        { text && text.length > 12?
            <Tooltip title={showText}>
                <div className="text" style={{maxWidth: textMaxWidth}}>{showText || '-'}</div>
            </Tooltip>
            :<div className="text" style={{maxWidth: textMaxWidth}}>{showText || '-'}</div>
        }
    </div>
}

export default ApprovalDrawer;
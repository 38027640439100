import React, { useCallback, useMemo, useState, useEffect } from 'react';
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, DatePicker } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../utils/publicFun'

const DETAIL_URL = '/costrate/info'

export default function CostRateEditLog(props) {
  const { statusOpt, treeDeptOptions, techLevelOptions, currentInfo, close, refresh } = props
  const [id, setId] = useState()
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch: getDetail, loading } = useGet()
  const { doFetch } = usePost()

  const { mode: initMode = 'detail' } = currentInfo

  const isAdd = useMemo(() => initMode === 'add', [initMode])
  const { techLevel } = useMemo(() => params || {}, [params])

  const statusOptions = useMemo(() => {
    if (!isAdd) {
      return _.map(statusOpt, o => {
        if (o.value === 'Y') return _.assign({}, o, { _disabled: true })
        return o
      })
    }
    return statusOpt
  }, [isAdd, statusOpt])

  const refreshDetail = useCallback(() => {
    if (isAdd) {
      setParams({ status: 'Y' })
    }
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?${strParams({ id })}`)
      .then(res => {
        setParams({
          ..._.pick(res, ['status', 'department', 'techLevel', 'price', 'id']),
          effectiveDate: toDate.str14ToDate(_.get(res, 'effectiveDate', '0'))
        })
        setId(res.roleID)
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [id, getDetail, isAdd])

  useEffect(() => {
    refreshDetail()
  }, [refreshDetail])

  const disableTechLevelOptions = useMemo(() => {
    const currDepartment = _.get(params, 'department')
    const existList = _.filter([], x => (x.department === currDepartment && x.status === 'Y'))
    const exitTechLevelList = _.map(existList, 'techLevel')
    const initTechLevel = techLevel
    const omitList = _.filter(exitTechLevelList, x => x !== initTechLevel)
    const disableTechLevelOptions = _.map(techLevelOptions, x => ({ ...x, _disabled: _.includes(omitList, x.value) }))
    const currTechLevel = _.get(params, 'techLevel')
    if (_.includes(omitList, currTechLevel)) {
      setParams(x => ({ ...x, techLevel: null }))
    }
    return disableTechLevelOptions
  }, [params, techLevel, techLevelOptions])

  const commit = useCallback(() => {
    if (_.some(_.values(error), x => x)) return
    if (isDisable) return
    setIsDisable(true)
    const parameter = _.assign({}, params, {
      activeFlag: 'Y',
      effectiveDate: dateFormat('YYYY-MM-DD', params.effectiveDate)
    })
    doFetch(isAdd ? '/costrate/add' : '/costrate/modify', parameter).then(res => {
      Messager.show(isAdd ? '添加成功' : '修改成功', { icon: 'success' });
      refresh()
      setIsDisable(false)
      close();
    }).catch(err => {
      setIsDisable(false)
      Messager.show(err._message, { icon: 'error' })
    });
  }, [error, params, close, doFetch, isAdd, refresh, isDisable])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) {
      setId(id)
    }
  }, [currentInfo])

  return (
    <Dialog
      cancel={close}
      header={isAdd ? '新增' : '修改'}
      style={{ width: 430 }}
      contentClassName=''
      confirm={commit}
      confirmButtonDisabled={_.some(_.values(error), x => x)}
      loading={isAdd ? false : loading}
    >
      <Form value={params} onChange={handChange} error={error} onError={setError}>
        <FormInput disabled={!isAdd} required={isAdd} bind='department' label='部门' search tree component={Select} options={treeDeptOptions} />
        <FormInput disabled={!isAdd} required={isAdd} bind='techLevel' label='职级' search component={Select} options={disableTechLevelOptions} />
        <FormInput bind='price' label='单价(元/小时)' required type='custom' validate={v => { return /^[+]{0,1}(\d+)$/.test(v) ? null : '请输入整数' }} />
        <FormInput bind='effectiveDate' label='生效日期' required component={DatePicker} />
        <FormInput disabled={isAdd} bind='status' label='状态' required component={Select} options={statusOptions} />
      </Form>
    </Dialog>
  )

  function handChange(o, key) {
    if (_.includes(['price', 'status'], key) && !isAdd) {
      if (+dateFormat('YYYYMMDDHHMMSS', o['effectiveDate']) < +dateFormat('YYYYMMDDHHMMSS', new Date())) {
        o['effectiveDate'] = new Date()
      }
    }
    if (key === 'effectiveDate') {
      if (+dateFormat('YYYYMMDD', o[key]) < +dateFormat('YYYYMMDD', new Date())) {
        o[key] = null
        Messager.show('不能小于当前日期', { icon: 'info' })
      }
    }
    setParams(o)
  }

}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Loader, Messager} from 'rootnet-ui';
import './MenuGroupUpdateDialog.scss'
import {Form, FormInput, RadioGroup, Select} from "rootnet-edit";
import _ from "lodash";
import {useGet, usePost} from "rootnet-biz/es/hooks";

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props}/>

const INIT_FORM = {
    status: '0'
}

function MenuGroupUpdateDialog(props) {
    const {close, currentInfo, systemOptions, statusOptions, refreshList} = props
    const { mode, id } = useMemo(()=>currentInfo || {},[currentInfo])
    const [formData, setFormData] = useState(mode === 'add' ? INIT_FORM : null)
    const [formError, setFormError] = useState()
    const {doFetch: submitPost} = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)
    const {doFetch: getDetail} = useGet()

    const refreshDetail = useCallback(()=>{
        if(_.isNil(id)) return
        getDetail('/uac/menu/group/info?id='+id).then(res => {
            setFormData(res)
        })
    },[getDetail,id])

    useEffect(()=>{
        refreshDetail()
    },[refreshDetail])

    const canSubmit = useMemo(()=>{
        return !_.some(_.values(formError),x=>x)
    },[formError])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const url = mode === 'add' ? '/uac/menu/addGroup' : '/uac/menu/editGroup'
        submitPost(url, formData).then(()=>{
            setSubmitLoading(false)
            Messager.show(mode === 'add'?'添加成功':'修改成功', { icon: 'success' })
            refreshList()
            close()
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitLoading, submitPost, formData, mode, refreshList, close])

    return <Dialog header={mode === 'add'?'新增':'编辑'} className={'menu-group-update-dialog'} cancel={close}
                   confirmButtonDisabled={!canSubmit} confirm={submit}>
        {
            submitLoading && <Loader fill/>
        }
        <Form value={formData} onChange={setFormData} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
            <HFormInput label={'编码'} bind={'funcGroupCode'} required/>
            <HFormInput label={'名称'} bind={'groupName'} required/>
            <HFormInput label={'系统'} bind={'moduleId'} required component={Select} options={systemOptions} disabled={mode === 'edit'}/>
            <HFormInput label={'排序'} bind={'order'} required/>
            <HFormInput label={'状态'} bind={'status'} required component={RadioGroup} options={statusOptions}/>
        </Form>
    </Dialog>
}

export default MenuGroupUpdateDialog;
import { useMemo } from 'react';
import _ from 'lodash'
import { useGet } from '../../utils/hook'
import gd from '../../base/global'

/**
 * 
 * @param {*} data 
 * @param {*} arr [text, value, tag] text与tag可以用逗号形式加入参数
 * @returns [{ text: text, vauel: value, tag: tag }]
 */
export function selectOption(data = [], arr = []) {
  const text = arr[0]
  const value = arr[1]
  const tag = arr[2] ? arr[2] : text

  return _.map(data, item => ({
    text: getText(text, item),
    value: item[value] + '',
    tag: getText(tag, item, '')
  }))
}

function getText(text, data, symbol = '-') {
  const txt = _.split(text, ',')
  const txtArr = _.map(txt, item => data[item])
  return _.join(txtArr, symbol)
}

/**
 * 
 * @param {*} codes [funcCode]
 * @returns [true || false]
 */
export function useFuncCode(codes) {
  const { data: menuData } = useGet('/common/uacFuncctrl?moduleId=BS')
  return useMemo(() => {
    if (_.isEmpty(menuData)) return []
    const funcCode = _.map(menuData, x => x.funcCode)
    return _.map(codes, v => _.includes(funcCode, v))
  }, [codes, menuData])
}

export function useRolePermissions(role) {
  const { data: jurisdictionData } = useGet('/common/uacUserRole')
  return useMemo(() => {
    if (_.isEmpty(jurisdictionData)) return []
    const getPosIds = _.map(jurisdictionData, x => x.posId)
    return _.map(role, v => _.includes(getPosIds, v))
  }, [role, jurisdictionData])
}

export function getCurrentUserInfo(name) {
  return _.get(gd, `_user.${name}`)
}
import React from 'react'
import { Card } from 'rootnet-ui'
import { Form, FormInput, Display } from 'rootnet-edit'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import './index.scss'

const HFormInput = props => <FormInput horizontal labelWidth={90} component={Display} {...props} />

export default function Macroforecast(props) {
  const { setOpenImport, isFuncCodeImport, setOpenDrawer, estimate } = props

  return (
    <Card
      title='总体预测'
      className='macroforecast'
      extra={isFuncCodeImport && <TextIconBtn onClick={() => setOpenImport(true)} text='工作量导入' icon='daoru' />}
    >
      <div className='macroforecast-content'>
        <Form value={estimate}>
          <HFormInput
            label='预计总工作量'
            bind='totalAmount'
            bindInConvert={v => <div className='macroforecast-val' onClick={() => setOpenDrawer('totalAmount')}>{`${v}（人日）`}</div>}
          />
          <HFormInput
            label='涉及模块'
            bind='involvingModule'
            bindInConvert={v => <div className='macroforecast-val' onClick={() => setOpenDrawer('involvingModule')}>{v}</div>}
          />
          {/* <HFormInput
            label='所需人员'
            bind='requiredPersonnel'
            bindInConvert={v => <div>{v}</div>}
          /> */}
          <HFormInput
            label='现有人员'
            bind='nowResearchers'
            bindInConvert={v => <div className='macroforecast-val' onClick={() => setOpenDrawer('nowResearchers')}>{v}</div>}
          />
          {/* <HFormInput
            label='预测人员缺口'
            bind='forecasterGap'
            bindInConvert={v => <div>{v}</div>}
          /> */}
          <HFormInput
            label='计划招聘'
            bind='plansHire'
            bindInConvert={v => <div>{v}</div>}
          />
        </Form>
      </div>
    </Card>
  )
}

import { Tooltip } from 'antd'
import _ from 'lodash'
import React, { Fragment, useMemo } from 'react'
import { Icon } from '../../../../../../components'
import { isNil } from '../../../../../appraise/components/method'

const data = [
  { text: '需求', value: 'req', success: 'storyNumComplete', percent: 'storyNumPercent', sum: 'storyNumSum', },
  { text: 'ISSUE', value: 'issue', success: 'issueNumComplete', percent: 'issueNumPercent', sum: 'issueNumSum', },
  { text: '自提单', value: 'dev', success: 'storyNumDevComplete', percent: 'storyNumDevPercent', sum: 'storyNumDevSum', },
  { text: '计划', value: 'stage', success: 'stageNumComplete', percent: 'stageNumPercent', sum: 'stageNumSum', },
  { text: '问题跟踪', value: 'todoList', success: 'todolistNumComplete', percent: 'todolistNumPercent', sum: 'todolistSum', },
  { text: '里程碑', value: 'milestone', success: 'milepostNumComplete', percent: 'milepostNumPercent', sum: 'milepostNumSum', },
  { text: '风险跟进', value: 'riskFollowUp', success: 'riskNumComplete', percent: 'riskNumPercent', sum: 'riskNumSum', },
  { text: '修复缺陷', value: 'repairDefect', success: 'testNumComplete', percent: 'testNumPercent', sum: 'testNumSum', icon: 'shijian1' },
  // { text: '缺陷非问题解决关闭', value: 'req', success: 'storyNumComplete',  percent: 'storyNumPercent', sum: 'storyNumSum', },
]

export default function ProjectOverview(props) {
  const { params } = props
  const defectParams = useMemo(() => {
    //testNumSum 修复缺陷  totalDefect-testNumSum = 往后遗留
    // defectDiscovery 发现缺陷   totalDefect - defectDiscovery = 遗留修复
    const { totalDefect = 0, testNumSum = 0, defectDiscovery = 0 } = params || {}
    return {
      total: totalDefect, //合计
      repair: testNumSum, //修复缺陷
      detect: defectDiscovery, //发现缺陷
      leaveBehind: _.toNumber(totalDefect) - _.toNumber(testNumSum), //往后遗留
      legacyRestoration: _.toNumber(totalDefect) - _.toNumber(defectDiscovery), //遗留修复
    }
  }, [params])
  return (
    <Fragment>
      {
        _.map(data, item => {
          return <div className='forminput-progress flex center-y' key={item.value}>
            <div className='item-label'>{item.text}</div>
            <div className='form-component-progress'>
              <DevProjectProgress
                success={_.get(params, `${item.success}`)}
                // unfinished={params?.issueNumHang}
                percent={_.get(params, `${item.percent}`)}
                sum={_.get(params, `${item.sum}`)}
                icon={item?.icon}
                defectParams={defectParams}
              />
            </div>
          </div>
        })
      }
      <div className='forminput-progress-num forminput-progress flex center-y' >
        <div className='item-label'>非解决关闭缺陷</div>
        <div className='form-component-progress'>
          <div className='dev-project-progress'>
            <div className='dev-project-progress-outer'>
              {isNil(_.get(params, 'testNumCompleteNo')) ? 0 : _.toNumber(_.get(params, 'testNumCompleteNo'))}
            </div>
            <div className='dev-project-progress-text'>
              <Tooltip title='项目下所有缺陷，关闭的解决结果为“非问题关闭、问题不修改关闭”'>
                <span>
                  <Icon name='bangzhu' style={{ fontSize: 14, color: '#5477ff' }} />
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='forminput-progress-num forminput-progress flex center-y' >
        <div className='item-label'>历史缺陷</div>
        <div className='form-component-progress'>
          <div className='dev-project-progress'>
            <div className='dev-project-progress-outer'>
              {isNil(_.get(params, 'historyTestNum')) ? 0 : _.toNumber(_.get(params, 'historyTestNum'))}
            </div>
            <div className='dev-project-progress-text'></div>
          </div>
        </div>
      </div> */}
    </Fragment>
  )
}

function DevProjectProgress(props) {
  const { success = 0, percent = 0, sum = 0, icon, defectParams = {} } = props
  const { total = 0, repair = 0, detect = 0, leaveBehind = 0, legacyRestoration = 0 } = defectParams

  const percentNum = useMemo(() => {
    return _.toNumber(percent)
  }, [percent])

  return (
    <div className='dev-project-progress'>
      <div className='dev-project-progress-outer'>
        <div className='success-progress' style={{ width: `${percentNum}%` }}>
          {/*{percentNum === 0 ? '' : success}*/}
        </div>
        {
          percentNum !== 100 &&
          <div className='unfinished-progress'>
            {/*{unfinished}*/}
          </div>
        }
        <div className="inner-text flex center">
          {success} / {sum}
        </div>
        {/*<div className="inner-white-text-wrap flex center-y" style={{ width: `${percentNum}%` }}>*/}
        {/*  <div className="inner-white-text flex center">*/}
        {/*    {success} / {sum}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className='dev-project-progress-text'>
        {percentNum}%
        {
          !isNil(icon) &&
          <Tooltip overlayClassName='dev-project-progress-total-tip' title={
            <div>
              <div className='defect-total'>
                <NumItem label={'项目缺陷合计'} bind={total} />
              </div>
              <div style={{ marginTop: 8 }}>
                <NumItem label={'修复缺陷'} bind={repair} />
                <NumItem label={'往后遗留'} bind={leaveBehind} />
              </div>
              <div style={{ marginTop: 8 }}>
                <NumItem label={'遗留修复'} bind={legacyRestoration} />
                <NumItem label={'发现缺陷'} bind={detect} />
              </div>
            </div>
          }>
            <span style={{ marginLeft: 6 }}>
              <Icon name='shijian1' style={{ fontSize: 14, color: '#5477ff' }} />
            </span>
          </Tooltip>
        }
      </div>
    </div>
  )
}

function NumItem(props) {
  const { label, bind } = props
  return <div className='dev-project-progress-num-item flex'>
    <div className='num-item-label'>{label}</div>
    <div className='num-item-bind'>{bind}</div>
  </div>
}

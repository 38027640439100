import React from "react";
import {isNil} from "rootnet-core/format";
import './Tooltip.scss';

export function Tooltip(props) {
    let {value,maxCount} = props;

    if (isNil(value)) return '';
    value = ''.concat(value);
    return <span className="tooltip">
        {value.length > maxCount && <span className="tooltip-details">{value}</span>}
        <span className='tooltip-text'>{cutStr(value,maxCount)}</span>
    </span>;
}
Tooltip.defaultProps = {
    maxCount:15
};
function cutStr(v,max) {
    return v.length<=max ? v : ''.concat(v.slice(0,max),'...');
}
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DataGrid } from 'rootnet-ui'
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { useGet } from '../../../../../utils/hook'
import VersionApplication from '../versionApplication'
import { strParams } from '../../../../../utils/publicFun'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import convertOptions from '../../../../common/ConvertOptions';
import { Box } from '../../../../common/commonComponent';
import './index.scss'
import CustomerServiceInfoDetailDialog
  from "../../../../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog";

const LIST_URL = '/issue/serviceList'
//问题性质放入状态后面

const getColumns = (props) => {
  const { allUserRes, setShowDetailDialog } = props
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '服务单编号', bind: 'CSRecordID', sortable: true, align: 'left', width: 130, tooltip: true, },
    { header: '标题', bind: 'IssueDesc', width: 270, sortable: true, align: 'left', tooltip: true,
      convert: (v, o) => <span className={'can-click-title'} onClick={()=>{
        setShowDetailDialog({
          module: 'customerService',
          id: _.get(o,'id')
        })
      }}>{v}</span> },
    { header: '状态', bind: 'Status', sortable: true, align: 'left', width: 90, tooltip: true },
    { header: '问题性质', bind: 'IssueKind', sortable: true, align: 'left', width: 90, tooltip: true },
    { header: '创建人', bind: 'CreateUser', sortable: true, align: 'left', width: 85, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '创建时间', bind: 'createTime', sortable: true, align: 'left', width: 145, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v)) },
  ]

}

const gridOptsFor = (columns) => ({
  nilText: '-',
  fixedLeft: 2,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});

export default function AssociatedService(props) {
  const { defaultData, id, allUserRes, productOptions, isFuncCodeApply } = props
  const [open, setOpen] = useState(false)
  const { doFetch, data, error, loading } = useGet()
  const [showDetailDialog, setShowDetailDialog] = useState()
  const option = useMemo(() => gridOptsFor(getColumns({ allUserRes, setShowDetailDialog })), [allUserRes])

  const refresh = useCallback(() => {
    if (_.isNil(id)) return
    doFetch(`${LIST_URL}?${strParams({ issueId: id })}`)
  }, [doFetch, id])

  const extra = useMemo(() => {
    return <>
      {
        isFuncCodeApply && <TextIconBtn
          icon='tianjia'
          text='版本申请'
          onClick={() => setOpen(true)}
          style={{ margin: '8px 0px', display: 'inline-block' }}
        />
      }
    </>
  }, [isFuncCodeApply])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <>
      <div className='associated-service'>
        <Box
          className={'flex-y x-card-singlegrid'}
          title='关联服务单'
          extra={extra}
          data={[0]}
          error={error}
          loading={loading}
        >
          <DataGrid data={data} option={option} />
        </Box>
      </div>
      {
        _.get(showDetailDialog,'module') === 'customerService' &&
        <CustomerServiceInfoDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
          id: _.get(showDetailDialog,'id')
        }}/>
      }
      {open && <VersionApplication {...{ defaultData, id, refresh, productOptions }} close={() => setOpen(false)} />}
    </>
  )
}

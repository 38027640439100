import React, { useState, useEffect, useMemo, useCallback, useReducer, useRef } from 'react'
import _ from 'lodash'
import { Tabs, Badge } from 'antd'
import { Button, Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/es/hooks'
import { Icon } from '../../../../../components'
import TrackingDetailHeader from '../trackingDetailHeader'
import ChangeRecord from '../../../../common/ChangeRecord'
import TrackingDetailRightPanel from '../trackingDetailRightPanel'
import TrackingInfo from '../trackingInfo'
import DevelopmentInformation from '../developmentInformation'
// import TestInformation from '../testInformation'
import PostMessage from '../postMessage'
// import RelatedProjects from '../relatedProjects'
import RelatedIssue from '../relatedIssue'
// import RelatedTracking from '../relatedTracking'
import TrackingUploadArea from '../trackingUploadArea'
import { strParams } from '../../../../../utils/publicFun'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import './index.scss'
import { isNil } from 'rootnet-core/format'
import TrackerDesignInfo from "../designInfo/TrackerDesignInfo";
import RequirementDetailDialog from '../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import { useFuncCode } from '../../../../common/commonMethod'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import TrackingDelDialog from "../../trackingDelDialog/TrackingDelDialog";
import gd from "../../../../../base/global";
import DefectUpdateDialog from "../../../../testMgt/defect/controls/DefectUpdateDialog";
import DevListDetailDialog from '../../../../devListMgt/devListDetailDialog'
import CodeCommitList from "../../../../common/codeCommitList/CodeCommitList";

const { TabPane } = Tabs

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
  '/common/globalconst?globalConst=storyType', // 需求类型
]

const EDIT_URL = '/bug/tracer/edit'
const DETAIL_URL = '/bug/tracer/detail'
const DEV_LIST_URL = '/bug/tracer/dev/list'
const QUERY_REQ_URL = '/bug/tracer/detail/story'

export default function TrackingDetailContent(props) {
  const { currentInfo = {}, close, switchCurrentItem, showChildList, currentIndex, refreshViewList } = props
  const { mode: initMode = 'detail' } = currentInfo
  const [id, setId] = useState()
  const [mode, setMode] = useState(initMode)
  const [formData, setFormData] = useState()
  const [activeKey, setActiveKey] = useState('info')
  const [editFormData, setEditFormData] = useState()
  const [editFormError, setEditFormError] = useState({})
  const [bugType, setBugType] = useState()
  const [switchDisabled, setSwitchDisabled] = useState(false)
  const [fileCount, setFileCount] = useState(0)
  const [showDetail, setShowDetail] = useState()
  const { data: allUserRes } = useGet('/common/userinfo')
  const [count, updateDynamic] = useReducer((x) => x + 1, 0)
  const { doFetch: getDetail } = useGet()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { doFetch } = usePost()
  const problemDescRef = useRef() //问题描述
  const modificationRef = useRef() //修改方案
  const [isLoading, setIsLoading] = useState(false)
  const { data: devData, loading: devLoading, doFetch: devDoFetch } = useGet()
  const { data: queryReqData, doFetch: queryReqFetch } = useGet()
  const [reqDelAuth, delAuth, showCodeCommitTab] = useFuncCode(['1503', '2601', '2610'])
  const [showDelDialog, setShowDelDialog] = useState(false)
  const [issueList, setIssueList] = useState()
  // const [countStage, updateStage] = useReducer((x) => x + 1, 40000)
  // const [countDoc, updateDoc] = useReducer((x) => x + 1, 20000)
  const [isClose, setIsClose] = useState(false)
  // 172096195508834304
  const [productTreeOptions, productOptions, moduleOptionsRes, storyTypeOptions, productTypeOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3, d4] = optionsRes
    const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, }))
      // a:displayName
    }))

    return [
      productTreeOptions,
      _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag })),
      d3,
      convertGlobalConstOptions(d4),
      _.map(d1, o => (
        { value: o.productId, text: o.productName, type: _.get(_.find(d2, x => x.interiorId === o.productLine), 'displayName') }
      ))
    ]
  }, [optionsRes])

  const isCts = useMemo(() => {
    return _.get(_.find(productTypeOptions, o => o.value === _.get(formData, 'productId')), 'type') === 'CTS'
  }, [productTypeOptions, formData])

  const moduleOptions = useMemo(() => {
    if (_.isEmpty(moduleOptionsRes)) return []
    if (_.isNil(_.get(editFormData, 'productId'))) return []
    const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === editFormData.productId)
    return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
  }, [editFormData, moduleOptionsRes])

  const refreshRichTextRef = useCallback((newData) => {
    const data = newData || formData
    if (problemDescRef.current) {
      let initText = _.replace(_.get(data, 'bugDesc'), /\n/g, '<br/>')
      problemDescRef.current.setContent(initText)
    }
    if (modificationRef.current) {
      let initText = _.replace(_.get(data, 'fixDesc'), /\n/g, '<br/>')
      modificationRef.current.setContent(initText)
    }
  }, [formData])

  const refreshDetail = useCallback((id) => {
    if (_.isNil(id)) return
    setSwitchDisabled(true)
    const searchParams = { tracerId: id }
    getDetail(`${DETAIL_URL}?${strParams(searchParams)}`).then(res => {
      let handleFormData = { ...res }
      handleFormData['projectId'] = _.split(_.get(res, 'projectId'), '^')
      handleFormData['securityName'] = _.split(_.get(res, 'securityName'), '^')
      handleFormData['designUseTimeHour'] = changeHourMinuteStr(res.designUseTime, 'hour')
      handleFormData['designUseTimeMinute'] = changeHourMinuteStr(res.designUseTime, 'minute')
      setFormData(handleFormData)
      setSwitchDisabled(false)
      setId(res.snowId)
      setTimeout(() => {
        if (problemDescRef.current) {
          let initText = _.replace(_.get(res, 'bugDesc'), /\n/g, '<br/>')
          problemDescRef.current.setContent(initText)
        }
        if (modificationRef.current) {
          let initText = _.replace(_.get(res, 'fixDesc'), /\n/g, '<br/>')
          modificationRef.current.setContent(initText)
        }
      }, 500)
    }).catch(err => {
      setSwitchDisabled(false)
      Messager.show(err._message, { icon: 'error' })
    })
    updateDynamic()
  }, [getDetail])

  useEffect(() => {
    if (_.isNil(id)) return
    setSwitchDisabled(true)
    getDetail(`${DETAIL_URL}?${strParams({ tracerId: id })}`).then(res => {
      let handleFormData = { ...res }
      handleFormData['projectId'] = _.split(_.get(res, 'projectId'), '^')
      handleFormData['securityName'] = _.split(_.get(res, 'securityName'), '^')
      handleFormData['designUseTimeHour'] = changeHourMinuteStr(res.designUseTime, 'hour')
      handleFormData['designUseTimeMinute'] = changeHourMinuteStr(res.designUseTime, 'minute')
      setFormData(handleFormData)
      setSwitchDisabled(false)
      setId(res.snowId)
      setTimeout(() => {
        if (problemDescRef.current) {
          let initText = _.replace(_.get(res, 'bugDesc'), /\n/g, '<br/>')
          problemDescRef.current.setContent(initText)
        }
        if (modificationRef.current) {
          let initText = _.replace(_.get(res, 'fixDesc'), /\n/g, '<br/>')
          modificationRef.current.setContent(initText)
        }
      }, 500)
    }).catch(err => {
      setSwitchDisabled(false)
      Messager.show(err._message, { icon: 'error' })
    })
    updateDynamic()
  }, [getDetail, id])

  const switchMode = useCallback((resetDesc = false) => {
    if (resetDesc) {
      refreshRichTextRef()
    }
    setMode(x => x === 'detail' ? 'edit' : 'detail')
  }, [refreshRichTextRef])

  const canSubmit = useMemo(() => {
    if (!_.get(editFormData, 'tracerTitle')) {
      return false
    }
    if (!_.get(editFormData, 'currentUser')) {
      return false
    }
    return !_.some(_.values(editFormError), x => x)
  }, [editFormData, editFormError])

  const submit = useCallback(() => {
    if (JSON.stringify(editFormData) === JSON.stringify(formData)
      && problemDescRef.current.getContent() === _.get(formData, 'bugDesc')
      && modificationRef.current.getContent() === _.get(formData, 'fixDesc')) {
      switchMode()
      return Messager.show('无变更')
    }
    if (isLoading) return
    setIsLoading(true)
    const postParams = { ...editFormData }
    postParams['projectId'] = _.join(_.get(editFormData, 'projectId'), '^')
    postParams['securityName'] = _.join(_.get(editFormData, 'securityName'), '^')
    postParams['bugDesc'] = _.replace(problemDescRef.current.getContent(), /\n/g, '')
    postParams['fixDesc'] = _.replace(modificationRef.current.getContent(), /\n/g, '')
    postParams['designUseTime'] = _.toNumber(editFormData?.designUseTimeHour) * 60 + _.toNumber(editFormData?.designUseTimeMinute)
    doFetch(EDIT_URL, postParams)///WorkflowBusiness/updateCurrentUser
      .then(() => {
        if (_.get(formData, 'currentUser') === _.get(editFormData, 'currentUser')) refreshAll()
        else {
          const currentUserParams = {
            businessId: _.get(editFormData, 'snowId'),
            businessType: 'tracer',
            funcCode: '26',
            currentUser: _.get(editFormData, 'currentUser'),
            title: _.get(editFormData, 'tracerTitle')
          }
          doFetch('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
            refreshAll()
          }).catch((err) => {
            setIsLoading(false)
            Messager.show(err._message, { icon: 'error' });
          })
        }
        function refreshAll() {
          setIsLoading(false)
          switchMode()
          refreshViewList()
          refreshDetail(id)
          Messager.show('修改成功', { icon: 'success' })
        }
      })
      .catch(err => {
        setIsLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, editFormData, formData, id, isLoading, refreshDetail, refreshViewList, switchMode])

  const devRefresh = useCallback(() => {
    if (isNil(id)) return
    devDoFetch(`${DEV_LIST_URL}?tracerId=${id}`)
  }, [devDoFetch, id])

  useEffect(() => {
    if (isNil(formData?.tracerId)) return
    queryReqFetch(`${QUERY_REQ_URL}?${strParams({ tracerId: formData.tracerId })}`)
  }, [formData, queryReqFetch])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) {
      setId(id)
    }
  }, [currentInfo])

  useEffect(() => {
    setEditFormData(formData)
  }, [formData])

  const isDetail = useMemo(() => {
    return mode === 'detail'
  }, [mode])

  const isDev = useMemo(() => {
    return _.includes(_.get(queryReqData, 'storyId'), 'DEV')
  }, [queryReqData])

  const codeCommitBizList = useMemo(() => {
    return formData?.tracerId && [formData?.tracerId]
  }, [formData])

  const [relateBizId] = useMemo(()=>{
    return [
      _.get(queryReqData, 'storyId') || _.get(formData, 'defectId') || _.get(issueList,'0.issueId'),
    ]
  },[queryReqData, formData, issueList])

  return <React.Fragment>
    <div className="tracking-detail-content flex-y">
      <div className="mock-dialog-header flex">
        <div className="dialog-title flex center-y">
          研发任务详情
          {
            !isNil(queryReqData) &&
            <div className="parent-header flex center-y req">
              <div className="parent-tag">{isDev ? '自提单' : '需求'}</div>
              <div className="parent-title" onClick={() => {
                setShowDetail({
                  module: isDev ? 'dev' : 'req',
                  id: _.get(queryReqData, 'id')
                })
              }}>{_.get(queryReqData, 'storyId')}&nbsp;&nbsp;{_.get(queryReqData, 'title')}</div>
            </div>
          }
          {
            !isNil(_.get(formData, 'defectId')) &&
            <div className="parent-header flex center-y defect">
              <div className="parent-tag">缺陷</div>
              <div className="parent-title" onClick={() => {
                setShowDetail({
                  module: 'defect',
                  id: _.get(formData, 'defectSnowId')
                })
              }}>{_.get(formData, 'defectId')}&nbsp;&nbsp;{_.get(formData, 'defectTitle')}</div>
            </div>
          }
        </div>
        <div className="mock-right-header flex center-y">
          {
            (delAuth || _.get(editFormData, 'creatorUserId') === gd.User.operator_id) &&
            <span>
              <TextIconBtn icon={'shanchu'} className={`header-del-item`} text={'删除'} onClick={() => {
                setShowDelDialog(true)
              }} />
            </span>
          }
          <span>
            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
              if (isClose) return Messager.show('状态已关闭，不允许修改', { icon: 'error' })
              switchMode(true)
              refreshDetail(id)
            }} />
          </span>
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>
      <TrackingDetailHeader {...{ id, editFormData, setEditFormData, isDetail, allUserRes, formData, bugType, refreshDetail, refreshViewList, updateDynamic, devRefresh, setIsClose }} />
      <div className="tracking-detail-main-panel flex">
        <div className="tracking-detail-left-panel">
          <Tabs className='tracking-tabs' activeKey={activeKey} onChange={setActiveKey}>
            <TabPane tab='详细信息' key={'info'}>
              <TrackingInfo {...{ isDetail, editFormData, setEditFormData, problemDescRef, modificationRef }} />
            </TabPane>
            <TabPane tab='设计信息' key={'designInformation'}>
              <TrackerDesignInfo {...{ isDetail, allUserRes, editFormData, setEditFormData, editFormError, setEditFormError, formData }} />
            </TabPane>
            <TabPane tab='开发信息' key={'devInformation'}>
              <DevelopmentInformation traceBizId={formData?.tracerId} traceDetail={formData} {...{ isDetail, editFormData, allUserRes, data: devData, loading: devLoading,
                refresh: devRefresh, tracerState: formData?.tracerState, isClose, isCts, relateBizId }} />
            </TabPane>
            {/* <TabPane tab="测试信息" key="testInformation">
              <TestInformation {...{ editFormData, id }} />
            </TabPane> */}
            <TabPane tab="发布信息" key='postMessage' forceRender={true}>
              <PostMessage {...{ isDetail, allUserRes, id: formData?.tracerId, editFormData, setEditFormData }} />
            </TabPane>
            {/* 
            <TabPane tab='关联研发任务' key='tracking'>
              <RelatedTracking {...{ id }} />
            </TabPane>
            <TabPane tab='关联项目' key={'project'}>
              <RelatedProjects {...{ id }} />
            </TabPane>
             */}
            <TabPane tab='关联ISSUE' key={'issue'} forceRender={true}>
              <RelatedIssue {...{ id: formData?.tracerId, allUserRes, setIssueList }} />
            </TabPane>
            {
              showCodeCommitTab &&
              <TabPane tab='代码提交记录' key={'codeCommit'}>
                <CodeCommitList bizIdList={codeCommitBizList} />
              </TabPane>
            }
            <TabPane tab={<Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
              附件
            </Badge>} key={'doc'} forceRender={true}>
              <div className="file-wrap">
                <TrackingUploadArea funcCode={'26'} {...{ id, mode, allUserRes, setFileCount }} />
              </div>
            </TabPane>
            <TabPane tab="动态" key="dynamic">
              <div className={'dynamic-wrap'} key={count}>
                <ChangeRecord name='研发任务' funcCode={'26'} referenceId={formData?.snowId} id={id} title={_.get(editFormData, 'tracerTitle')} linkUrl={`/trackingManagementMgt?initId=${id}`} />
              </div>
            </TabPane>
          </Tabs>
        </div>
        <TrackingDetailRightPanel {...{
          isDetail, editFormData, setEditFormData, allUserRes, editFormError, setEditFormError, productTreeOptions, productOptions, moduleOptions, setBugType,
        }} />
      </div>
      <div className="mock-footer flex center-y">
        <div />
        {
          _.isEmpty(showChildList) ? <div /> :
            <div className="footer-switch flex center-y">
              <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                onClick={() => {
                  if (switchDisabled) return
                  if (!_.includes([-1, 0], currentIndex)) {
                    switchCurrentItem('previous')
                  }
                }}>
                <Icon name='xiangqian' />&nbsp;上一个
              </div>
              <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                onClick={() => {
                  if (switchDisabled) return
                  if (!(currentIndex === showChildList?.length - 1)) {
                    switchCurrentItem('next')
                  }
                }}>
                下一个&nbsp;<Icon name='xianghou' />
              </div>
              <div className="list-num">
                {currentIndex + 1}/{showChildList?.length}
              </div>
            </div>
        }
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              switchMode(true)
              setEditFormData(formData)
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
          }
        </div>
      </div>
    </div>
    {
      _.get(showDetail, 'module') === 'req' &&
      <RequirementDetailDialog
        close={() => setShowDetail(null)}
        currentInfo={{ id: _.get(showDetail, 'id') }}
        showChildList={[]}
        delAuth={reqDelAuth}
        storyTypeOptions={storyTypeOptions}
      />
    }
    {
      _.get(showDetail, 'module') === 'dev' &&
      <DevListDetailDialog
        close={() => setShowDetail(null)}
        currentInfo={{ id: _.get(showDetail, 'id') }}
        showChildList={[]}
        delAuth={reqDelAuth}
        storyTypeOptions={storyTypeOptions}
      />
    }
    {
      showDelDialog &&
      <TrackingDelDialog id={id} close={() => setShowDelDialog(false)} afterRefresh={() => {
        refreshViewList()
        close()
      }} />
    }
    {
      _.get(showDetail, 'module') === 'defect' &&
      <DefectUpdateDialog mode={'detail'} close={() => setShowDetail(null)} currentId={_.get(showDetail, 'id')}
        refreshList={() => refreshDetail(id)} />
    }
  </React.Fragment>
}


function changeHourMinuteStr(str, mode) {
  if (str !== '0' && str !== '' && str !== null) {
    const hour = (Math.floor(str / 60)).toString().length < 2 ? '0' + (Math.floor(str / 60)).toString() :
      (Math.floor(str / 60)).toString()
    const minute = (str % 60).toString().length < 2 ? '0' + (str % 60).toString() : (str % 60).toString()
    if (mode === 'hour') return hour
    if (mode === 'minute') return minute
    return hour + '小时' + minute + '分钟';
  }
  else {
    return '';
  }
}
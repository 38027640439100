import React, {useState} from 'react';
import ColorPicker from "./ColorPicker";
import {Button} from "rootnet-ui";
import './ColorPickerPopContent.scss'

function ColorPickerPopContent(props) {
    const {defaultColor, setColor, close} = props
    const [colorState, setColorState] = useState(defaultColor)

    return <div className={'color-picker-pop-content flex-y'}>
        <ColorPicker setColor={setColorState} {...{defaultColor}}/>
        <div className="btn-group flex">
            <Button normal onClick={close}>取消</Button>
            <Button primary onClick={()=>{
                setColor(colorState)
                close()
            }}>确认</Button>
        </div>
    </div>
}

export default ColorPickerPopContent;
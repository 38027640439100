import React, { useMemo, useState, useReducer, useCallback, useEffect } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { Popover } from 'antd'
import { isNil } from 'rootnet-core/format'
import { Messager, DataGrid, Pagination } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { REQUIREMENT_STATUS_COLOR, REQUIREMENT_PRIORITY_COLOR } from '../../../../requirementMgt/requirementColor'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import { Icon } from '../../../../../components'
import WorkFlowChangePop from '../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { CustomizeFormInput } from '../../../../common/customizeOptions/CustomizeOptions'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../../common/ConvertOptions'
import useGetIdList from '../../../../common/view/hooks/useGetIdList'
import useGetAppendData from '../../../../common/view/hooks/useGetAppendData'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import { useFuncCode } from '../../../../common/commonMethod'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { API1 } from '../../../../../base/task'
import ViewArea from '../../../../common/view/ViewArea'
import DelMessage from '../../../../../components/DelMessage'
import ApplyJoinVersionDialog from '../../../../requirementMgt/applyJoinVersionDialog/ApplyJoinVersionDialog'
import RequirementCopyDialog from '../../../../requirementMgt/requirementCopyDialog/RequirementCopyDialog'
import RequirementDetailDialog from '../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import ReqViewSearchDialog from '../../../../common/publicPage/publicReq/reqViewSearchDialog'
import { Box } from '../../../../common/commonComponent'
import { strParams } from '../../../../../utils/publicFun'
import './index.scss'
import RequirementAddDialog from '../../../../requirementMgt/requirementAddDialog/RequirementAddDialog'
import DelVersionItem from '../devProjectScope/devProjectScopeAllInfo/components/delVersionItem'
import AssociatedVersion from '../devProjectScope/devProjectScopeAllInfo/components/associatedVersion'
import ImportVersion from '../devProjectScope/devProjectScopeAllInfo/components/importVersion'
import CopyMessage from '../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/copyMessage'

const PRIMARY_KEY = 'storyId'
const ENTER_DETAIL_FIELD = 'story.title'

const EDITABLE_FIELD = [
  'story.priority',
  'story.currentUser',
]

const RELATED_REQ_URL = '/implement/project/related/requirement'
const CANCEL_RELATED_REQ_URL = '/implement/project/related/requirement/del'

const REPLACE_POST_PARAMS = {}

const SPECIAL_FIELD_CONVERT = {
  'story.status': (value, showValue) => {
    return <div style={{ color: REQUIREMENT_STATUS_COLOR[value], border: `1px solid ${REQUIREMENT_STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'story.priority': (value, showValue) => {
    return <div style={{ background: REQUIREMENT_PRIORITY_COLOR[value], padding: '0px 12px', color: '#fff', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, editInfo, setEditInfo, setCurrentTableValue, delAuth, setJoinVersionId,
    updateShowList, treeStageOptions, setEditOldValue, workFlowId, setWorkFlowId, refreshList, setCopyInfo, setDelInfo, canAddChildReq,
    handlePopId, setHandlePopId, addChildDone, canCopy, getCanCopy, FUNC_CODE, setOptionalField, projectId } = props
  const { editId = null, editField = null } = editInfo || {}

  const customColumns = [
    {
      fieldId: 'statusExt', bind: 'addRes1', convert: v => {
        return <div className={'common-display-field flex center-y'}>
          {v}
        </div>
      }
    },
    {
      fieldId: 'releaseId', bind: 'list', convert: v => {
        return <div className={'common-display-field flex center-y'}>
          {_.join(v, ', ')}
        </div>
      }, tooltip: true
    },
  ]

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    if (fieldItem.fieldId === 'storyId') o['storyId'] = showValue
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === 'View_ReleaseInfo_story_project.isRelationFlag') {
      setOptionalField(_.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === tableField), 'columnId'))
      if (v === '1') o._selectable = false
    }
    if (!_.isNil(SPECIAL_FIELD_CONVERT[tableField])) {
      showValue = SPECIAL_FIELD_CONVERT[tableField](v, showValue)
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          {/*{*/}
          {/*  _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') &&*/}
          {/*  <Icon name={_.includes(foldList, o.id) ? 'biaogezhankai' : 'biaogeshouqi'} className={'expand-icon'} onClick={() => onExpandIconClick(o.id)} />*/}
          {/*}*/}
          {/*{*/}
          {/*  _.get(o, '_isParent') && _.get(o, '_isEmptyChildren') &&*/}
          {/*  <div className={'empty-parent'} />*/}
          {/*}*/}
          {/*{*/}
          {/*  !_.get(o, '_isParent') &&*/}
          {/*  <div className={'empty-child'} />*/}
          {/*}*/}
          {/*{*/}
          {/*  _.get(o, '_isParent') &&*/}
          {/*  <Tag color="blue">父</Tag>*/}
          {/*}*/}
          {/*{*/}
          {/*  !_.get(o, '_isParent') &&*/}
          {/*  <Tag color="gold">子</Tag>*/}
          {/*}*/}
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({
                id: _.get(o, 'id'),
                mode: 'detail'
              })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
        <Popover open={_.get(o, 'id') === handlePopId && addChildDone} onOpenChange={visible => {
          if (visible) {
            setHandlePopId(_.get(o, 'id'))
            if (o.pid) {
              getCanCopy(o.pid)
            }
          } else {
            setHandlePopId(null)
          }
        }}
          trigger={'click'}
          content={<div className={'requirement-handle-popover flex-y'}>
            {
              _.isNil(_.get(o, 'pid')) &&
              <div className={clsx('requirement-handle-item flex center-y', { 'able': canAddChildReq, 'disabled': !canAddChildReq })} onClick={() => {
                if (canAddChildReq) {
                  setCurrentInfo({
                    mode: 'add',
                    pid: _.get(o, 'id'),
                    parentTitle: showValue,
                    leafProjectId: projectId,
                  })
                  setHandlePopId(null)
                }
              }}>
                <Icon name={'tianjia'} className={'handle-icon'} />
                <div className="handle-text">创建子需求</div>
              </div>
            }
            <div className={clsx('requirement-handle-item flex center-y', { 'able': _.isNil(o.pid) || canCopy, 'disabled': !(_.isNil(o.pid) || canCopy) })} onClick={() => {
              if (_.isNil(o.pid) || canCopy) {
                setCopyInfo({
                  id: _.get(o, 'id'),
                  title: showValue,
                  isParent: _.isNil(_.get(o, 'pid'))
                })
                setHandlePopId(null)
              }
            }}>
              <Icon name={'wendang'} className={'handle-icon'} />
              <div className="handle-text">复制</div>
            </div>
            <div className={clsx('requirement-handle-item flex center-y', { 'able': !(_.get(o, '_isParent') && !_.get(o, '_isEmptyChildren')), 'disabled': _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') })} onClick={() => {
              if (_.get(o, '_isParent') && !_.get(o, '_isEmptyChildren')) return
              setJoinVersionId(o.id)
              setHandlePopId(null)
            }}>
              <Icon name={'xinjianchanpinchi'} className={'handle-icon'} />
              <div className="handle-text">申请加入已封板的版本</div>
            </div>
            {
              delAuth &&
              <div className="requirement-handle-item flex center-y del-item" onClick={() => {
                setDelInfo({
                  id: _.get(o, 'id'),
                  isParent: _.isNil(_.get(o, 'pid'))
                })
                setHandlePopId(null)
              }}>
                <Icon name={'shanchu'} className={'handle-icon'} />
                <div className="handle-text">删除</div>
              </div>
            }
          </div>} placement={'bottom'} destroyTooltipOnHide={true}>
          <span>
            <Icon style={{ display: 'none' }} name={'gengduo'} className={'more-handle-icon'} />
          </span>
        </Popover>
      </div>
    }
    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={FUNC_CODE} businessType={'req'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (!_.includes(EDITABLE_FIELD, tableField)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (o?.id === editId && editField === tableField) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue}
        clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType} customOptions={tableField === 'story.status' ? treeStageOptions : null}
        onFocus={() => {
          setCurrentTableValue(handleValue)
          setEditOldValue(handleValue)
        }} tree={tableField === 'story.status'}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            setCurrentTableValue(value)
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    setEditInfo({
      editId: o?.id,
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100
  }
}

const OPTIONS_URLS = [
  'common/globalconst?globalConst=ReqStatus',
]

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=storyType',
]

export default function DevProjectReq(props) {
  const { projectId, projectType, id, updateDynamic, FUNC_CODE, RELATION_FUNC_CODE, replaceParams, versionFilterSql, isPo, isPMM, canRelate, isShow, isRelevance, isDisassociation, versionCheck, selectedVersion, isVersionState, searchAll, allList, isAll, isNoPlanVersion, versionList, versionCanRelate, refreshAllList } = props
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(100)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentInfo, setCurrentInfo] = useState(null)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const [focusId, setFocusId] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [sortConfig, setSortConfig] = useState()
  const [factor, setFactor] = useState()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const [workFlowId, setWorkFlowId] = useState()
  const [copyInfo, setCopyInfo] = useState()
  const [delInfo, setDelInfo] = useState()
  const [handlePopId, setHandlePopId] = useState()
  const [joinVersionId, setJoinVersionId] = useState()
  const [foldList, setFoldList] = useState([])
  const [canAddChildReq, setCanAddChildReq] = useState(false)
  const { doFetch: authDoFetch } = useGet()
  const [addChildDone, setAddChildDone] = useState(false)
  const [canCopy, setCanCopy] = useState(false)
  const [showRelationDialog, setShowRelationDialog] = useState()
  const [selectItems, setSelectItems] = useState([])
  const [optionalField, setOptionalField] = useState()
  const { doFetch } = usePost()
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (_.isEmpty(list)) return
    _.forEach(list, o => o.type = 'REQ')
  }, [list])

  const appendObj = useMemo(() => {
    const projectSql = `View_ReleaseInfo_story_project.LeafProjectId=${isNil(projectId) ? 'empty' : `'${projectId}'`}`
    return { 'factorViewSql': _.join(_.compact([projectSql, versionFilterSql]), ' and ') }
  }, [projectId, versionFilterSql])

  useEffect(() => {
    if (_.isNil(handlePopId)) return
    authDoFetch('/story/getContainStory?id=' + handlePopId).then(res => {
      setCanAddChildReq(res)
      setAddChildDone(true)
    })
  }, [authDoFetch, handlePopId])

  useEffect(() => {
    if (_.isNil(handlePopId)) {
      setCanAddChildReq(false)
      setAddChildDone(false)
    }
  }, [handlePopId])

  const getCanCopy = useCallback((pid) => {
    authDoFetch('/story/getContainStory?id=' + pid).then(res => {
      setCanCopy(res)
    })
  }, [authDoFetch])

  const [storyTypeOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  // 表格编辑
  const [currentTableValue, setCurrentTableValue] = useState()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()
  const [editOldValue, setEditOldValue] = useState()

  const [stageOptions, treeStageOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes || {}
    const treeStageOptions = _.map(_.groupBy(d1, x => x.memo), (stateList, stageText) => {
      return ({
        text: stageText,
        value: stageText,
        _disabled: true,
        children: _.map(stateList, x => ({ value: x.interiorId, text: x.displayName }))
      })
    })
    return [
      _.map(d1, x => ({ value: x.interiorId, text: x.memo })),
      treeStageOptions
    ]
  }, [optionsRes])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValue
    if (JSON.stringify(updateValue) === JSON.stringify(editOldValue)) {
      setEditInfo(null)
      setCurrentTableValue(null)
      return
    }
    let postParams = {
      // defectId: primaryId,
      ids: [editInfo['editId']]
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    editTable('/story/updateSingle', postParams).then(() => {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
            if (editInfo.editField === 'story.status') {
              item['addRes1'] = convertOptions(updateValue, stageOptions)
            }
          }
        })
        return cloneList
      })
      setEditInfo(null)
      setCurrentTableValue(null)
      Messager.show('修改成功', { icon: 'success' });
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [editInfo, currentTableValue, editTable, editOldValue, stageOptions])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const input = document.querySelector('.requirement-data-grid').querySelector('input')
    if (!_.isNil(input)) {
      input.focus()
      input.click()
    }
  }, [editInfo])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const rootnetSelect = document.querySelector('.requirement-data-grid').querySelector('.rootnet-select')
    if (rootnetSelect) {
      rootnetSelect.addEventListener('mousewheel', e => {
        e.cancelBubble = true
      })
    }
  }, [editInfo])

  // C端列表默认查询

  const idList = useGetIdList(list)
  const appendData = useGetAppendData(idList, '/story/viewInterface')

  const appendList = useMemo(() => {
    const appendFieldList = _.map(appendData, x => _.pick(x, ['addRes1', 'list']))
    _.forEach(appendFieldList, item => {
      _.forEach(item, (value, key) => {
        if (key === 'addRes1') {
          item[key] = convertOptions(value, stageOptions)
        } else {
          item[key] = value
        }
      })
    })
    // 数字类型字段排序
    let decimalList = list
    _.forEach(fieldList, fieldItem => {
      if (fieldItem['fieldDbType'] === "DECIMAL") {
        _.forEach(decimalList, item => {
          item[fieldItem.columnId] = _.toNumber(item[fieldItem.columnId])
        })
      }
    })
    return _.map(decimalList, (x, i) => _.assign(x, appendFieldList[i]))
  }, [list, appendData, fieldList, stageOptions])

  useEffect(() => {
    setShowDataGridList(appendList)
  }, [appendList])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, showAllNodes: 'N', appendObj })

  const refreshLists = useCallback(() => {
    refreshList(replaceParams)
  }, [refreshList, replaceParams])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const [allDetailAuth, delAuth] = useFuncCode(['1507', '1503'])

  const onExpandIconClick = useCallback((id) => {
    setFoldList(oldList => {
      if (_.includes(oldList, id)) {
        return _.filter(oldList, x => x !== id)
      } else {
        return _.concat(oldList, [id])
      }
    })
  }, [])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection,
    dateOptions, getOptions, getColumns, sortable: true,
    columnsAppendParams: {
      setCurrentInfo, editInfo, setEditInfo, setCurrentTableValue, updateShowList, delAuth, setJoinVersionId, canAddChildReq, addChildDone,
      treeStageOptions, setEditOldValue, workFlowId, setWorkFlowId, refreshList: refreshLists, setCopyInfo, setDelInfo, handlePopId, setHandlePopId, onExpandIconClick, foldList, projectId,
      canCopy, getCanCopy, FUNC_CODE: '1501', setOptionalField
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        if (_.isEmpty(data)) return []
        setSortConfig(sort)
        const sortAllList = data.sort((a, b) => sortFun(a, b, sort.column))
        const sortAllDirectionList = sort.direction === 'asc' ? sortAllList : _.reverse(_.clone(sortAllList))
        const sortFirstLevelDirectionList = _.filter(sortAllDirectionList, x => _.isNil(_.get(x, 'pid')))
        let handleList = []
        _.forEach(sortFirstLevelDirectionList, item => {
          const childrenList = _.filter(sortAllDirectionList, x => _.get(x, 'pid') === item.id)
          handleList.push(item)
          _.forEach(childrenList, x => {
            handleList.push(x)
          })
        })
        return handleList
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const handleList = useMemo(() => {
    let allList = showChildList || []
    // if(!_.isNil(sortConfig) && !isTree){
    //     const sortList = _.sortBy(showChildList, x => x[sortConfig.column])
    //     showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    // }
    const firstLevelList = _.filter(allList, x => _.isNil(_.get(x, 'pid')))
    let handleList = []
    _.forEach(firstLevelList, item => {
      let parentItem = { ...item }
      const childrenList = _.filter(allList, x => _.get(x, 'pid') === item.id)
      const childrenIdList = _.map(childrenList, x => x.id)
      parentItem = { ...parentItem, childrenIdList, _isParent: true, _isEmptyChildren: _.isEmpty(childrenList) }
      handleList.push(parentItem)
      _.forEach(childrenList, x => {
        handleList.push({ ...x, _isParent: false })
      })
    })
    return handleList
  }, [showChildList])

  const showList = useMemo(() => {
    return _.filter(handleList, x => !_.includes(foldList, _.get(x, 'pid')))
  }, [handleList, foldList])

  useEffect(() => {
    if (_.isNil(focusId)) return
    _.forEach(showList, o => {
      return o._rowClass = o?.id === focusId ? 'select_row' : ''
    })
    forceUpdate()
  }, [focusId, showList])

  const onRowClick = useCallback((item) => {
    setFocusId(item.id)
  }, [])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
      ...appendObj,
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [fieldList, currentViewId, FUNC_CODE, factor, appendObj])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  const relatedReq = useCallback((idList) => {
    if (_.isEmpty(idList)) return Messager.show('无变更', { icon: 'info' })
    doFetch(`${RELATED_REQ_URL}?${strParams({ id, projectType, funcCode: RELATION_FUNC_CODE, projectId })}`, idList)
      .then(() => {
        updateDynamic()
        refreshLists()
        refreshAllList()
        Messager.show('关联成功', { icon: 'success', duration: 2000 })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [RELATION_FUNC_CODE, doFetch, id, projectId, projectType, refreshLists, updateDynamic, refreshAllList])

  const cancelRelatedReq = useCallback((items, flag = true) => {
    if (_.isEmpty(items)) return Messager.show('请选择后在进行操作', { icon: 'error' })
    const ids = _.map(items, o => o.storyId)
    doFetch(`${CANCEL_RELATED_REQ_URL}?${strParams({ projectType, funcCode: RELATION_FUNC_CODE, projectId })}`, ids)
      .then(() => {
        flag && updateDynamic()
        flag && refreshLists()
        refreshAllList()
        setSelectItems([])
        Messager.show('取消关联成功', { icon: 'success', duration: 2000 })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [RELATION_FUNC_CODE, doFetch, projectType, refreshLists, updateDynamic, projectId, refreshAllList])

  const dataManipulation = useCallback((par, flag = true) => {
    if (isDisabled) return
    setIsDisabled(true)
    doFetch('/ReleaseStory/addReleaseStoryList', par)
      .then(() => {
        setSelectItems([])
        refreshLists()
        setIsDisabled(false)
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
        setIsDisabled(false)
      })
  }, [isDisabled, doFetch, refreshLists])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        (isPo || isPMM) && canRelate && isShow && isRelevance && !versionCheck && !isNoPlanVersion &&
        <TextIconBtn text='项目关联需求' onClick={() => setShowRelationDialog('req')} />
      }
      {
        (isPo || isPMM) && canRelate && isShow && isRelevance && !versionCheck && isNoPlanVersion &&
        <TextIconBtn icon='' text='导入版本' onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setShowRelationDialog('versionPlan')
        }} />
      }
      {
        !isAll && (isPo || isPMM) && canRelate && isShow && isDisassociation && !versionCheck &&
        <TextIconBtn text={'移除项目'} onClick={() => {
          cancelRelatedReq(selectItems)
        }} />
      }
      {
        (isPo || isPMM) && canRelate && isShow && versionCheck && isVersionState &&
        <TextIconBtn text={'导入版本'} onClick={() => {
          setShowRelationDialog('joinVersion')
        }} />
      }
      {
        (isPo || isPMM) && canRelate && isShow && versionCheck && isVersionState &&
        <TextIconBtn text={'移除版本'} onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setShowRelationDialog('removeVersion')
        }} />
      }
      {
        versionCanRelate && isRelevance && versionCheck &&
        <TextIconBtn text={'复制至其他版本'} onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setShowRelationDialog('copyValues')
        }} />
      }
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [cancelRelatedReq, exportViewList, selectItems, isPo, isPMM, canRelate, isShow, isRelevance, isDisassociation, versionCheck, isVersionState, isAll, isNoPlanVersion, versionCanRelate])

  const disabledId = useMemo(() => {
    if (_.isNil(optionalField)) return []
    return _.map(_.filter(list, o => o[optionalField] === '1'), o => o.id)
  }, [list, optionalField])

  return (
    <div className='dev-project-req'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading} replaceParams={replaceParams}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'需求管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={showList} onRowClick={onRowClick} selection={selectItems} onSelectionChange={setSelectItems} className={'requirement-data-grid'} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        joinVersionId &&
        <ApplyJoinVersionDialog id={joinVersionId} close={() => setJoinVersionId(null)} type={'REQ'} />
      }
      {
        !_.isNil(copyInfo) &&
        <RequirementCopyDialog close={() => setCopyInfo(null)} {...{ copyInfo, refreshList: refreshLists, setCurrentInfo }} />
      }
      {
        _.get(currentInfo, 'mode') === 'add' &&
        <RequirementAddDialog
          afterRefresh={refreshList}
          close={() => setCurrentInfo(null)}
          {...{ currentInfo }}
        />
      }
      {
        _.includes(['detail', 'edit'], _.get(currentInfo, 'mode')) &&
        <RequirementDetailDialog refreshViewList={refreshLists} close={() => setCurrentInfo(null)} setCurrentInfo={setCurrentInfo}
          outerAllDetailAuth={allDetailAuth} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, delAuth, storyTypeOptions }} />
      }
      {
        !_.isNil(delInfo) &&
        <DelMessage close={() => setDelInfo(null)} refresh={refreshLists} method={'get'} url={'/story/deleteStory?id=' + _.get(delInfo, 'id')}>
          {
            _.get(delInfo, 'isParent') ? '父需求删除，其下子需求一同删除，是否继续？' : '是否删除该需求？'
          }
        </DelMessage>
      }
      {
        showRelationDialog === 'req' &&
        <ReqViewSearchDialog
          close={() => setShowRelationDialog(null)}
          initId={_.map(_.filter(allList, o => o.taskType === 'REQ'), 'id')}
          disabledId={disabledId}
          FUNC_CODE={'3600'}
          {...{ relatedReq, cancelRelatedReq }}
        />
      }
      {
        showRelationDialog === 'joinVersion' &&
        <AssociatedVersion
          searchAll={searchAll}
          defaultData={versionCheck ? _.filter(allList, o => _.includes(_.split(o.releaseIds, ','), selectedVersion)) : allList}
          close={() => setShowRelationDialog(null)}
          refreshList={() => {
            refreshLists()
            refreshAllList()
          }}
          {...{ selectedVersion }}
        />
      }
      {
        showRelationDialog === 'removeVersion' &&
        <DelVersionItem
          releaseid={selectedVersion}
          close={() => setShowRelationDialog(null)}
          selection={_.map(selectItems, o => _.assign({}, o, { taskType: 'REQ' }))}
          refreshList={() => {
            refreshLists()
            refreshAllList()
          }}
          {...{ setSelection: setSelectItems }}
        />
      }
      {
        showRelationDialog === 'versionPlan' &&
        <ImportVersion
          {...{ selection: _.map(selectItems, o => _.assign({}, o, { taskType: 'REQ' })), setSelection: setSelectItems, versionList }}
          refreshList={() => {
            refreshLists()
            refreshAllList()
          }}
          close={() => setShowRelationDialog(null)}
        />
      }
      {
        showRelationDialog === 'copyValues' &&
        <CopyMessage
          {...{ projectId, dataManipulation }}
          selectedRows={selectItems}
          actionType={showRelationDialog}
          currentId={selectedVersion}
          close={() => setShowRelationDialog(null)}
          isDisabled={true}
          isDisplay={false}
          changeData={{}}
        />
      }
    </div>
  )

  function sortFun(a, b, bind) {
    if (_.isNil(a[bind])) return -1
    const aStr = isNil(a[bind]) ? '' : a[bind]
    const bStr = isNil(b[bind]) ? '' : b[bind]
    if (_.isNumber(a[bind]) && _.isNumber(b[bind])) {
      return a[bind] - b[bind]
    }
    if (_.isNumber(a[bind]) ^ _.isNumber(b[bind])) {
      return (_.toNumber(a[bind]) || 0) - (_.toNumber(b[bind]) || 0)
    }
    return aStr.localeCompare(bStr)
  }

}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ConferenceRecordsDetailProjectRelation.scss'
import { useGet } from "rootnet-biz/es/hooks";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import convertOptions from "../../../../common/ConvertOptions";
import { Tooltip } from "antd";
import Icon from "../../../../../components/Icon";
import { DataGrid, Messager } from "rootnet-ui";
import DelMessage from "../../../../../components/DelMessage";
import ViewQueryDialog from '../../../../common/view/viewQueryDialog/ViewQueryDialog';
import { usePost } from 'rootnet-biz/lib/hooks';
import { isNil } from '../../../../appraise/components/method';

const RELEVANCE_URL = '/userProject/stage/related/records'

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=devSegment',
]

function getColumns(props) {
    const { setDisassociateId, stageOptions, isRelevance } = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', stretch: false, width: 40 },
        { header: '项目ID', bind: 'projectId', width: 150, stretch: false, tooltip: true },
        {
            header: '项目名称', bind: 'projectName', width: 180, tooltip: true,
            convert: (v, o) => <div className={'name-wrap'} onClick={() => {
                window.open('/#/devProjectMgt?initId=' + o.id, '_blank')
            }}>
                {v}
            </div>
        },
        { header: '项目阶段', bind: 'stage', width: 150, tooltip: true, convert: v => convertOptions(v, stageOptions) },
        { header: '任务名称', bind: 'stageTitle', width: 150, tooltip: true },
        {
            header: '操作', bind: '', width: 60, stretch: false, align: 'center',
            convert: (v, o) => <Tooltip title={'取消关联'}>
                <span className={'flex center'} onClick={() => {
                    if (isRelevance) {
                        if (isNil(o.stageId)) setDisassociateId(o.id)
                        else setDisassociateId(o.stageId)
                    }
                    else setDisassociateId(o.stageId)
                }}>
                    <Icon name={'quxiaoguanlian'} className={'disassociate-icon'} />
                </span>
            </Tooltip>
        },
    ]
}

function getOption(columns) {
    return {
        columns,
        autoFill: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
        fixedRight: 1,
    }
}

function ConferenceRecordsDetailProjectRelation(props) {
    const { cid, isRelevance, isDetail, setDevList = () => { } } = props
    const { data: list, doFetch: getList } = useGet()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [disassociateId, setDisassociateId] = useState()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { doFetch: submitPost } = usePost()

    const [stageOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const refreshList = useCallback(() => {
        if (_.isNil(cid)) return
        getList('/conferencerecord/related/project?recordId=' + cid)
            .then(res => setDevList(res))
    }, [cid, getList, setDevList])

    useEffect(() => {
        refreshList()
    }, [refreshList])

    const option = useMemo(() => {
        return getOption(getColumns({ setDisassociateId, stageOptions, isRelevance }))
    }, [stageOptions, isRelevance])

    const relevanceDev = useCallback((data) => {
        if (isLoading) return
        setIsLoading(true)
        const id = _.get(data, 'id')
        const initListId = _.get(_.head(list), 'id')
        if (id === initListId) return
        const postParams = {
            id: id,
            conferenceRecordIds: cid,
            disassociateRecordIds: null,
        }
        submitPost(RELEVANCE_URL, postParams)
            .then(() => {
                setIsLoading(false)
                Messager.show('修改成功', { icon: 'success' })
                refreshList()
            }).catch(err => {
                setIsLoading(false)
                Messager.show(err._message, { icon: 'error' })
            })
    }, [isLoading, list, submitPost, cid, refreshList])

    return <div className={'conference-records-detail-project-relation flex-y'}>
        {
            isRelevance && !isDetail && _.size(list) === 0 &&
            <div className={'add-btn-wrap flex'}>
                <span className={'add-text'} onClick={() => setOpen(true)}>关联研发项目</span>
            </div>
        }
        <DataGrid data={list} option={option} className='x-datagrid' />
        {
            disassociateId &&
            <DelMessage url={'/userProject/stage/disassociate/record'} params={isRelevance ? { referenceId: disassociateId, recordId: cid, funcType: 'dev' } : { referenceId: disassociateId, recordId: cid }}
                close={() => setDisassociateId(null)} refresh={refreshList} method={'post'}>
                确认解除关联吗？
            </DelMessage>
        }
        {
            open &&
            <ViewQueryDialog
                close={() => setOpen(false)}
                bizName='研发项目'
                bizField={'userproject.projectID'}
                initValue={_.map(list, o => o.id)}
                initItemValue={list}
                multiple={false}
                funcCode={'0009'}
                outerSetItem={relevanceDev}
                refreshViewList={refreshList}
            />
        }
    </div>
}

export default ConferenceRecordsDetailProjectRelation;
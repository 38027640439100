import React, { useCallback } from 'react'
import { Dialog } from 'rootnet-ui'
import './index.scss'

export default function IsOnlyLog(props) {
  const { submit, setOpen, close } = props

  const confirm = useCallback((v) => {
    submit({ isDepend: v })
    close()
    setOpen(true)
  }, [close, submit, setOpen])

  return (
    <Dialog
      cancel={() => confirm('0')}
      header={'提示'}
      confirm={() => confirm('1')}
      className='is-only-log'
      confirmButtonText='是'
      cancelButtonText='否'
      style={{
        paddingTop: 0
      }}
    >
      <div className='is-only-log-text'>添加子任务后，主任务是否可以独立完成？</div>
      <div className='hint'>
        <div>选择"是"则不受子任务状态控制</div>
        <div>选择"否"则全部子任务完成后才可以完成主任务</div>
      </div>
    </Dialog>
  )
}

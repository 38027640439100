import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import { useGet } from '../../../utils/hook'
import { useFuncCode } from '../../common/commonMethod'
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions'
import DepartmentMenu from './components/departmentMenu'
import ResourceForecastDetail from './components/resourceForecastDetail'
import './index.scss'

const IMPORT_PERMISSION = '520201' // 导入权限
const EDIT_IMPORT_PERMISSION = '520203' // 更新导入权限
const MODULE_PERMISSION = '520205' // 涉及模块权限
const EDIT_ASSIGNED_PERSONNEL = '520210' // 现有人员权限
const funcCode = [IMPORT_PERMISSION, EDIT_IMPORT_PERMISSION, MODULE_PERMISSION, EDIT_ASSIGNED_PERSONNEL]
const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=userPost',
    '/common/globalconst?globalConst=userTechLevel',
]

export default function ResourceForecast(props) {
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [deptId, setDeptId] = useState()

    const [positionOptions, techLevelOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const [isFuncCodeImport, isFuncCodeEdit, isFuncCodeModule, isFuncCodePeople] = useFuncCode(funcCode)
    return <div className='resource-forecast fill'>
        {/* 部门菜单 */}
        <DepartmentMenu setDeptId={setDeptId} />
        {/* 年度资源预测详情 */}
        <ResourceForecastDetail {...{ deptId, isFuncCodeImport, isFuncCodeEdit, isFuncCodeModule, isFuncCodePeople, positionOptions, techLevelOptions, defaultPositionOptions: _.get(globalConstOptionsRes, '[0]', []) }} />
    </div>
}
import React, { useMemo } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { strParams, summaryWithHeader } from "../../../../utils/publicFun";
import { Box } from '../../../common/commonComponent';
import { DataGrid } from 'rootnet-ui';
import { N2 } from 'rootnet-core/format';

function Operation(props) {
  const { data, params } = props;
  const history = useHistory();
  return <div className="operation">
    <span onClick={() => toPage('show')}>详情</span>
  </div>;
  function toPage() {
    const itemInfo = {
      item_customer: data.customer,
      item_projectName: data.projectName,
      item_principal: data.principal,
      item_projectStatusList: data.projectStatus,
    };
    history.push({ pathname: '/presalesprojectcost/'.concat(getParams()) })
    function getParams() {
      const origin = _.omit(data, ['customer', 'projectName', 'principal', 'projectStatus']);
      return strParams(_.assign(origin, itemInfo, params));
    }
  }
}

const optionsFor = ({ initParams, presalesProStatus }) => {
  return (
    [
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 70 },
      { header: '客户', bind: 'customerName', align: 'left', },
      { header: '项目名称', bind: 'projectName', align: 'left', width: 300, tooltip: true },
      { header: '项目类型', bind: 'projectTypeName', align: 'left', width: 150, tooltip: true },
      { header: '项目状态', bind: 'projectStatus', align: 'left', width: 78, convert: v => _.get(_.find(presalesProStatus, x => x.value === v), 'name') },
      { header: '项目经理', bind: 'principalName', align: 'left', width: 78 },
      { header: '计划工时(人日)', bind: 'planTime', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '计划成本(元)', bind: 'planCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '实际工时(人日)', bind: 'useTime', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '实际成本(元)', bind: 'useCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '操作', convert: function _(e, o) { return <Operation params={initParams} data={o} /> }, align: 'center', width: 60 },
    ]
  )
}

const gridOptsFor = (options, data) => ({
  virtualized: false,
  resizable: true,
  fixedLeft: 2,
  fixedRight: 1,
  columns: options,
  nilText: '-',
  emptyText: '-',
  summary: [
    summaryWithHeader(options, data)
  ]
});

function List(props) {
  const { data = [], initParams, presalesProStatus } = props;
  const option = useMemo(() => gridOptsFor(optionsFor({ initParams, presalesProStatus }), data), [initParams, presalesProStatus, data]);
  return (
    <Box style={{ flex: 1, marginTop: 4 }} className='flex-y x-card-singlegrid' title='项目列表' data={data} {...props}>
      <DataGrid option={option} data={data} className='row-fill' />
    </Box>
  )
}
export default List;
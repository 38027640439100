import React, { useState, useCallback, useMemo, useEffect } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Icon } from '../../../components'
import { successTips, errorTips } from '../../../utils/messagerTips'
import { strParams } from '../../../utils/publicFun'
import './index.scss'

const ADD_URL = '/businessCollection/add' //关注   referenceId type
const DEL_URL = '/businessCollection/delete' //取消关注
const CHECK_URL = '/businessCollection/getIsCollection' //检查是否关注

export default function ConcernedIcon(props) {//关注
  const { flag, referenceId, type, mode = '', top, left } = props
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()
  const { doFetch: checkFetch, data: checkFlag } = usePost()
  const [isFlag, setIsFlag] = useState(false)

  const isDetail = useMemo(() => mode === 'detail', [mode])

  useEffect(() => {
    if (isDetail && !_.isNil(referenceId)) checkFetch(`${CHECK_URL}?${strParams({ id: referenceId })}`, {})
  }, [isDetail, checkFetch, referenceId])

  useEffect(() => {
    setIsFlag(() => {
      if (isDetail) return checkFlag
      return flag
    })
  }, [isDetail, checkFlag, flag])

  const addConcerned = useCallback(() => {
    setIsDisable(true)
    if (isDisable) return
    doFetch(ADD_URL, { referenceId, type })
      .then(() => {
        setIsFlag(true)
        setIsDisable(false)
        successTips('关注成功')
      })
      .catch(err => {
        setIsDisable(false)
        errorTips(err._message)
      })
  }, [isDisable, doFetch, referenceId, type])

  const delConcerned = useCallback(() => {
    setIsDisable(true)
    if (isDisable) return
    doFetch(`${DEL_URL}?${strParams({ id: referenceId })}`, {})
      .then(() => {
        setIsFlag(false)
        setIsDisable(false)
        successTips('已取消关注')
      })
      .catch(err => {
        setIsDisable(false)
        errorTips(err._message)
      })
  }, [isDisable, doFetch, referenceId])

  return (
    <Icon
      style={_.assign({},
        isDetail && { marginLeft: 8, marginTop: 2 },
        !_.isNil(left) && { marginLeft: left },
        !_.isNil(top) && { marginTop: top },
        !isFlag && !isDetail && { display: 'none' },
      )}
      name={isFlag ? 'icon_yishoucang' : 'shoucang2'}
      onClick={isFlag ? delConcerned : addConcerned}
      className={cls('concerned-icon', { 'concerned-icon-show': isFlag })}
    />
  )
}

import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {CheckBox} from "rootnet-edit";
import _ from "lodash";
import convertTableAlign from "../../../../common/view/convertTableAlign";
import findConvert from "../../../../common/view/findConvert";
import useGetViewConfig from "../../../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../../../common/view/hooks/useGetTreeList";
import {DataGrid, Dialog, Pagination} from "rootnet-ui";
import ViewArea from "../../../../common/view/ViewArea";
import {Box} from "../../../../common/commonComponent";
import './ReqViewSearchDialog.scss'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, selectedId } = props

    const customColumns = []

    return [
        { header: '', width: 40, stretch: false, align: 'center', convert: (v, o) => {
                return <CheckBox value={o.id === selectedId}/>
            }},
        { header: '#', width: 40, stretch: false, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
            }
        }
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '0004'

function ReqViewSearchDialog(props) {
    const {initId, outerSetId, outerSetItem, close} = props
    const [selectedId, setSelectedId] = useState(initId)
    const [selectedItem, setSelectedItem] = useState()
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode})

    const dateOptions = useGetDateOptions()

    const onRowClick = useCallback((item)=>{
        if(selectedId === item.id){
            setSelectedId(null)
            setSelectedItem(null)
        }else{
            setSelectedId(item.id)
            const bizItem = _.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'story.storyId')
            const bizId = _.get(item, _.get(bizItem,'columnId'))
            setSelectedItem({
                id: item.id,
                bizId: bizId
            })
        }
    },[selectedId, fieldList])

    useEffect(()=>{
        _.forEach(list, o => {
            return o._rowClass = selectedId === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    },[list, selectedId])

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams:{selectedId},
    })

    const confirm = useCallback(()=>{
        if(outerSetId){
            outerSetId(selectedId)
        }
        if(outerSetItem && initId !== selectedId){
            outerSetItem(selectedItem)
        }
        close()
    },[outerSetId,close,selectedId,outerSetItem,selectedItem, initId])

    return <Dialog header={'需求查询'} className={'req-view-query-dialog'} cancel={close} confirm={confirm}>
        <div className={'req-view-query-content fill flex-y'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                      {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                          currentViewId, setCurrentViewId, params, pageSize}}/>
            <Box title={'需求列表'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
                <DataGrid option={options} data={dataGridList} onRowClick={(item)=>onRowClick(item)}/>
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                            onChange={(pageNum, pageSize) => {
                                setPageSize(pageSize)
                                setParams(x => _.assign({}, x, {pageNum, pageSize}))
                            }} />
            </Box>
        </div>
    </Dialog>
}

export default ReqViewSearchDialog;
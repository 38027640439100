import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { Tree, Tabs } from 'antd'
import { Loader } from 'rootnet-ui'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'

function initTreeData(data) {
  if (_.isEmpty(data)) return []
  const listMenu = JSON.parse(JSON.stringify(data))
  _.forEach(listMenu, item => delete item.children)
  const map = {}
  _.forEach(listMenu, item => map[item.funcCode] = item)
  const newListMenu = []
  _.forEach(listMenu, r => {
    r.title = r.funcName
    r.key = r.id
    let parents = map[r.pid]
    if (parents) {
      ((parents.children) || (parents.children = [])).push(r)

    } else newListMenu.push(r)
  })
  return newListMenu
}

export default function RoleMenu(props) {
  const { data, loading, currentId, systemOpt } = props
  const [activeTab, setActiveTab] = useState()

  const itemData = useMemo(() => {
    if (_.isEmpty(data)) return []
    setActiveTab(_.get(_.head(data), 'moduleID'))
    return _.assign({}, ..._.map(_.groupBy(data, 'moduleID'), (v, k) => ({ [k]: initTreeData(v) })))
  }, [data])

  return (
    <div className='role-menu'>
      {(!_.isNil(currentId) && loading) && <Loader fill />}
      {_.isEmpty(itemData) && <div className='tree-empty'>暂无数据</div>}
      {
        !_.isEmpty(itemData) &&
        <Tabs
          activeKey={activeTab}
          onTabClick={setActiveTab}
          tabBarExtraContent={<div style={{ color: '#7A8199' }}>已分配菜单</div>}
          items={
            _.map(itemData, (treeData, key) => {
              return {
                label: convertOptions(key, systemOpt) || key,
                key: key,
                children: <Tree treeData={treeData} />
              }
            })
          } />
      }
    </div>
  )
}

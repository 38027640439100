import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Button, DataGrid } from 'rootnet-ui'
// import { OperationList } from '../../common/OperationList'
import { Box } from '../../common/commonComponent'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Select } from 'rootnet-edit'
import { strParams } from '../../../utils/publicFun'
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions'
import { isNil } from '../../appraise/components/method'
import DateSelector from '../../common/dateSelector/DateSelector'

const LIST_URL = '/listenRabbtMq/getListenRabbtMq'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=Yes_Or_No',
]

const getColumns = (props) => {
  return [
    { header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center' },
    { header: '交换机', bind: 'exchange', sortable: true, width: 200, tooltip: true },
    { header: '路由键', bind: 'routingKey', sortable: true, width: 200, tooltip: true },
    { header: '请求体', bind: 'body', sortable: true, width: 200, tooltip: true },
    { header: '消费系统', bind: 'listenSystem', sortable: true, width: 150, tooltip: true },
    { header: '消费时间', bind: 'listenTime', sortable: true, width: 180, tooltip: true },
    { header: '生产系统', bind: 'sendSystem', sortable: true, width: 150, tooltip: true },
    { header: '生产时间', bind: 'sendTime', sortable: true, width: 180, tooltip: true },
    { header: '发送ID', bind: 'sendId', sortable: true, width: 260, tooltip: true },
    { header: '是否成功', bind: 'successFlag', sortable: true, width: 80, tooltip: true },
    // { header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation }
  ]
  // function renderOperation(v, o) {
  //   const operateOption = [
  //     {
  //       text: '手动重试',
  //       mode: 'detail',
  //       onClick: () => { }
  //     },
  //   ]
  //   return <OperationList options={operateOption} />;
  // }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  fixedRight: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

export default function RabbitMqMgt() {
  const [params, setParams] = useState()
  const { data, doFetch: getList, loading, error } = useGet()

  const option = useMemo(() => {
    return getOptions(getColumns())
  }, [])

  const refresh = useCallback(() => {

    getList(`${LIST_URL}?${strParams(_.omit(params, ['sendTime']))}`)
  }, [params, getList])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className='rabbit-mq-mgt fill flex-y'>
      <Options initParams={params} search={setParams} />
      <Box className='flex-y x-card-singlegrid' title='客服服务系统消息列表' data={data} loading={loading} error={error}>
        <DataGrid className='row-fill' data={data} option={option} />
        {/* <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setParams(x => {
              const parameter = _.assign({}, x, { pageNum, pageSize })
              setParams(parameter)
              return parameter
            })
          }} /> */}
      </Box>
    </div>
  )
}

const HFormInput = (props) => <FormInput componentWidth={150} {...props} />

function Options(props) {
  const { initParams, search } = props
  const [params, setParams] = useState(initParams)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [yseOrNoOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const handChange = useCallback((o, k) => {
    if (k === 'sendTime') {
      if (!isNil(o[k])) {
        const { begin, end } = o[k] || {}
        o['startTime'] = begin
        o['endTime'] = end
      }
    }
    setParams(o)
  }, [])

  return <div className="x rims_options cost-center-options">
    <Form onChange={handChange} value={params}>
      <HFormInput label='交换机' bind='exchange' />
      <HFormInput label='路由键' bind='routingKey' />
      <HFormInput label='请求体' bind='body' />
      <HFormInput label='生产时间' bind='sendTime' component={DateSelector} componentWidth={220} />
      <HFormInput label='发送ID' bind='sendId' />
      <HFormInput label='是否成功' bind='successFlag' component={Select} options={yseOrNoOptions} search clear />
      <Button primary onClick={() => search({...params})} className='btn'>查询</Button>
      <Button text onClick={() => {
        setParams(obj => {
          const initParams = _.assign({}, ..._.map(obj, (v, k) => ({ [k]: null })))
          search(initParams)
          return initParams
        })
      }} className='btn reset-btn'>重置</Button>
    </Form>
  </div>
}
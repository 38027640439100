//注：此文件所有函数仅限在project-share内部使用

//和clsx完全一致
//author：yanglvfeng
export function P_Clsx () {
    let i=0, tmp, x, str='';
    while (i < arguments.length) {
        tmp = arguments[i++]
        if (tmp) {
            x = toVal(tmp)
            if (x) {
                str && (str += ' ');
                str += x
            }
        }
    }
    return str;
}
function toVal(mix) {
    let k, y, str='';

    if (typeof mix === 'string' || typeof mix === 'number') {
        str += mix;
    } else if (typeof mix === 'object') {
        if (Array.isArray(mix)) {
            for (k=0; k < mix.length; k++) {
                if (mix[k]) {
                    y = toVal(mix[k])
                    if (y) {
                        str && (str += ' ');
                        str += y;
                    }
                }
            }
        } else {
            for (k in mix) {
                if (mix[k]) {
                    str && (str += ' ');
                    str += k;
                }
            }
        }
    }

    return str;
}

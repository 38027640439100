import React,{useEffect} from 'react';
import _ from 'lodash';
import Echarts from 'echarts-for-react';
import {Card} from 'rootnet-ui';
import { useGet } from "../../../utils/hook";

//进度差
function ProgressLeadBar(props) {
    const {data} = props;
    const totalDay = _.get(data,'totalDay');
    const finishedNum = _.get(data,'finishedNum');
    const actualDay = _.get(data,'actualDay');
    // const everyDayWork = totalDay/4;
    // const xData = Array.from(Array(5),(x,i)=>(everyDayWork*i).toFixed(2));

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        legend: [{
            show: true,
            icon: "roundRect",
            itemWidth: 14,
            itemHeight: 14,
            itemGap: 40,
            textStyle: {
                padding: [2, 0, 0, 0],
            },
            top: 12,
        }],
        grid: {
            left: '3%',
            right: '10%',
            bottom: '3%',
            containLabel: true
        },
        color:['#5477FF','#38E097'],
        xAxis:{
            type: 'value',
            axisTick: { show: false },
            splitLine:{ show:false },
            axisLine: {
                lineStyle: {
                    type: 'dashed',
                }
            },
            max:totalDay,
        },
        yAxis: {
            type: 'category',
            data: ['工时'],
            nameGap: 20,
            axisTick: {
                show: false
            },
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            },
            nameTextStyle:{
                padding:[0,72,0,0]
            },
        },
        series: [
            {
                name:'实际工时',
                type: 'bar',
                barMaxWidth:14,
                data:[actualDay],
                label: {
                    normal: {
                        position: 'right',
                        show: true,
                    },
                },
            },
            {
                name:'标准工时',
                type: 'bar',
                barMaxWidth:14,
                data:[finishedNum],
                label: {
                    normal: {
                        position: 'right',
                        show: true,
                    },
                },
            },
        ]
    };
    return <Echarts option={option} style={{height:240}}/>
}

export function ProgressLead(props) {
    const {projectId} = props;
    const {data,doFetch,loading,error} = useGet();

    useEffect(()=>{
        if(!_.isNil(projectId)){
            const url = `develop/project/task/statistic?projectId=${projectId}`;
            doFetch(url);
        }
    },[projectId,doFetch]);

    return <Card title='成本差' loading={loading} error={error}>
        {data && <ProgressLeadBar data={data}/>}
    </Card>
}
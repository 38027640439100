import React, {useCallback, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import {useGet, usePost} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import {FormInput, Select} from "rootnet-edit";

function UpdateStatusDialog(props) {
    const {close, header, caseIds, refresh} = props
    const [status, setStatus] = useState("02")
    const {data} = useGet('/common/globalconst?globalConst=CaseStatus')
    const {doFetch} = usePost()

    const options = useMemo(()=>{
        if(_.isEmpty(data)) return []
        return convertGlobalConstOptions(data)
    },[data])

    const submit = useCallback(()=>{
        doFetch('/test_case/update/all',{caseIds,status}).then(()=>{
            Messager.show('修改成功', { icon: 'success' });
            refresh()
            close()
        }).catch(err => Messager.show(err.Message, {icon: 'error'}))
    },[status,caseIds,doFetch, close, refresh])

    return <Dialog header={header} cancel={close} confirm={submit}>
        <FormInput label='用例状态' value={status} onChange={setStatus} component={Select} options={options} horizontal/>
    </Dialog>
}

export default UpdateStatusDialog;
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertTableAlign from '../common/view/convertTableAlign'
import findConvert from '../common/view/findConvert'
import useGetViewConfig from '../common/view/hooks/useGetViewConfig'
import useRefreshList from '../common/view/hooks/useRefreshList'
import useGetDateOptions from '../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../common/view/hooks/useGetTreeList'
import { Box } from '../common/commonComponent'
import ViewArea from '../common/view/ViewArea'
import { TextIconBtn } from '../common/TextIconBtn'
import { ScheduleManagementContext } from '../common/Context'
import './index.scss'
import convertOptions from '../common/ConvertOptions'
import CustomerServiceInfoDetailDialog from '../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog'
import { CustomizeFormInput } from '../common/customizeOptions/CustomizeOptions'
import { gd } from 'rootnet-core/base'
import { useRolePermissions } from '../common/commonMethod'

const PRIMARY_KEY = 'isDeal'
const ENTER_DETAIL_FIELD = 'apiAuthTable.CSRecordID'
const EDITABLE_FIELD = ['apiAuthTable.isDeal']
const IDS_URL = '/UserSetting/getUniversalInterfaces?code=id&codeName=CSRecordID&tableName=apiAuthTable'
const REPLACE_POST_PARAMS = {}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, idList, setCurrentInfo, editInfo, setEditInfo, setCurrentTableValue, setEditOldValue, updateShowList, isApiSpecial } = props

  const { editId = null, editField = null } = editInfo || {}

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    const createUserBind = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'CustomerServiceInfo.CreateUser'), 'columnId')
    const createUser = o[createUserBind]
    const isEdit = (createUser === gd.User.operator_id || isApiSpecial)
    if (tableField === ENTER_DETAIL_FIELD) {
      const id = convertOptions(o.id, idList, 'displayName', 'interiorId')
      return <div className='enter-detail-field-text'
        onClick={() => {
          setCurrentInfo({ mode: 'customerService', id })
        }}
      >{showValue}</div>
    }
    if (o?.id === editId && editField === tableField) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue}
        clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => {
          setCurrentTableValue(handleValue)
          setEditOldValue(handleValue)
        }} tree={tableField === 'story.status'}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            setCurrentTableValue(value)
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    return <div className={'common-display-field'} style={{ width: '50px', cursor: (_.includes(EDITABLE_FIELD, tableField) && isEdit) ? 'pointer' : 'unset' }} onClick={() => {
      if ((_.includes(EDITABLE_FIELD, tableField) && isEdit)) {
        onEditFieldClick({ o, fieldItem })
      }
    }}>
      {showValue || '-'}
    </div>
  }
  function onEditFieldClick({ o, fieldItem }) {
    setEditInfo({
      editId: o?.id,
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '2010'

export default function ApiAuthTable() {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const [currentInfo, setCurrentInfo] = useState()
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)
  const { data: idList, doFetch: getIdlist } = useGet(IDS_URL)

  const [currentTableValue, setCurrentTableValue] = useState()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()
  const [editOldValue, setEditOldValue] = useState()
  const [isApiSpecial] = useRolePermissions(['apiUpdate'])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValue
    if (JSON.stringify(updateValue) === JSON.stringify(editOldValue)) {
      setEditInfo(null)
      setCurrentTableValue(null)
      return
    }
    let postParams = {
      // defectId: primaryId,
      id: editInfo['editId']
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      postParams[editInfo['fieldId']] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    editTable('/customerService/updateApi', postParams).then(() => {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      setCurrentTableValue(null)
      Messager.show('修改成功', { icon: 'success' });
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [editInfo, currentTableValue, editTable, editOldValue])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshIds = useCallback(() => {
    getIdlist(IDS_URL)
  }, [getIdlist])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const refresh = useCallback((replaceParams) => {
    refreshList(replaceParams)
    refreshIds()
  }, [refreshList, refreshIds])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { idList, setCurrentInfo, editInfo, setEditInfo, setCurrentTableValue, setEditOldValue, updateShowList, isApiSpecial } })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, 'API授权表')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [exportViewList, allowExport])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  return (
    <div className='api-auth-table fill flex-y'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, refreshList: refresh
        }} />
      <Box title={'API授权表'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'customerService' &&
        <CustomerServiceInfoDetailDialog
          close={() => setCurrentInfo(null)} refreshViewList={refresh}
          currentInfo={{ id: _.get(currentInfo, 'id') }}
        />
      }
    </div>
  )
}

import React, { useState, useEffect, useContext, useCallback, useMemo, useReducer } from 'react'
import _, { get } from 'lodash'
import { Button, Popover, Tooltip } from 'antd'
import { Dialog, Tab, TabItem, Messager, Button as RootButton } from 'rootnet-ui'
import { FormInput, Display, Form } from 'rootnet-edit'
import { Icon } from '../../../../components'
import { EXECUTION_COLOR } from './publicData'
import { useGet, useApi } from '../../../../utils/hook'
import { TableContext, ValueContext } from '../../../common/Context'
import ExecutiveHistory from '../../testCase/components/executiveHistory'
import AddAndEdit from '../../testCase/components/addAndEdit'
import { FormContext } from '../../../common/Context'
import { localRefresh } from '../../components/getTreeList'
import Demand from '../../testCase/components/demand'
import Defect from '../../testCase/components/Defect'
import DefectUploadArea from '../../defect/controls/DefectUploadArea'
import DefectUpdateDialog from '../../defect/controls/DefectUpdateDialog'
import gd from '../../../../base/global'
import ChangeRecord from '../../../common/ChangeRecord'
import PopoverComment from '../../defect/controls/PopoverComment'
import RequirementDetailDialog from '../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import { useFuncCode } from '../../../common/commonMethod'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import TestCaseIssue from '../../testCase/components/testCaseIssue'
import IssueDetailDialog from '../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import CaseDefect from '../../testCase/components/caseDefect'
import { strParams } from '../../../../utils/publicFun'

const EDIT_ECHO_URL = '/test_case/selecttestcasebyid' //编辑回显
const CORRELATION_ECHO_URL = '/test_case/selectassociateddemand'//需求回显 研发任务回显
const EXECUTIVE_HISTORY_URL = '/test_case/selectTestCasePerformHistory' //执行历史
const IMPLEMENT_URL = '/common/globalconst?globalConst=caseResultStatus'
const EXECUTE_URL = '/test/plan/insertTestCaseResult' //变更执行结果
const QUERY_FILE_URL = '/mapping/files/queryNew'
const DEFECT_URL = '/test_case/selectDefectInCase'//缺陷

// 产品 & 模块
const optionsUrl = [
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
]

const PENETRATE_URL = [
  '/common/globalconst?globalConst=storyType', // 需求类型
]

const APPLY_PERMISSION = '1902' //申请权限
const QA_CHECK_PERMISSION = '1910' //QA检测功能权限

const HFormInput = (props) => <FormInput horizontal componentWidth={180} labelWidth={105} component={Display}  {...props} />

export default function ListDetail() {
  const { modeAll, setModeAll, caseData, planId, tableData, setTableData, refresh, setCaseData, getCase, setCurrentId, actualResultOpt,
    resultEnforcementRef,
    setActive: setResultActive,
    active: resultActive,
    ActualResult,
    refreshOnlyId,
    onlyId,
  } = useContext(TableContext)
  const { convertCollection, basicInformationData, allRefresh } = useContext(ValueContext)
  const [params, setParams] = useState()
  const [mode, setMode] = useState('detail')
  const [open, setOpen] = useState(null)
  const [demandData, setDemandData] = useState([]) // 选中的关联需求
  // const [trackingData, setTrackingData] = useState([]) // 选中的关联研发任务
  const [issueData, setIssueData] = useState([]) // 选中的关联研发任务
  const [fileList, setFileList] = useState([])
  const { data: allData, doFetch: allFetch, loading } = useGet()
  const { data: executionData } = useGet(IMPLEMENT_URL)
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch } = useApi()
  const [commentText, setCommentText] = useState()
  const [commentPopoverVisible, setCommentPopoverVisible] = useState(false)
  const { data: optionsRes } = useGet(optionsUrl)
  const [active, setActive] = useState(0)
  const [showDetail, setShowDetail] = useState()
  const [reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck] = useFuncCode(['1503', APPLY_PERMISSION, QA_CHECK_PERMISSION])
  const { data: penetrateRes } = useGet(PENETRATE_URL)
  const [countIssue, upDateIssue] = useReducer((x) => x + 1, 0)//ISSUE
  const [countReq, upDateReq] = useReducer((x) => x + 1, 0)//REQ
  const [submitLoading, setSubmitLoading] = useState(false)

  const [storyTypeOptions] = useMemo(() => {
    if (_.isEmpty(penetrateRes)) return []
    const [d1] = penetrateRes
    return [
      convertGlobalConstOptions(d1)
    ]
  }, [penetrateRes])

  const [productOptions, moduleOptionsRes] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    return [productOptions, d3]
  }, [optionsRes])

  const handleMenuClick = useCallback(({ status }) => {
    if (resultEnforcementRef.current.loading) {
      return Messager.show('图片提交中,请稍后')
    }
    if (submitLoading) return
    setSubmitLoading(true)
    const actualResult = resultEnforcementRef.current.getContent()
    const parameter = _.assign({}, { caseIdList: [modeAll.data.id], planId, status, actualResult, id: onlyId })
    doFetch(EXECUTE_URL, 'post', parameter)
      .then((res) => {
        setSubmitLoading(false)
        setResultActive(null)
        setOpen(_.assign({}, { visible: false }))
        allRefresh()
        refresh()
        const listData = localRefresh(tableData, res)
        setTableData(listData)
        setCaseData(getCase(listData))
        setParams(x => _.assign({}, x, { result: status }))
        setModeAll(x => _.assign({}, x, { data: _.assign({}, x.data, { result: status, resultId: _.head(res)?.resultId }) }))
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setSubmitLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [resultEnforcementRef, modeAll.data.id, planId, onlyId, doFetch, setResultActive, allRefresh, tableData, setTableData, setCaseData, getCase, setModeAll, submitLoading, refresh])

  const name = useMemo(() => {
    const findName = _.find(executionData, item => item.interiorId === params?.result)
    if (!params?.result) return '空'
    return <span>
      {_.get(findName, 'displayName')}
    </span >
  }, [executionData, params])

  useEffect(() => {
    if (modeAll) {
      const caseId = modeAll.data.id
      const url = [
        `${EDIT_ECHO_URL}?caseId=${caseId}`,
        `${CORRELATION_ECHO_URL}?caseId=${caseId}&type=Req`,
        `${CORRELATION_ECHO_URL}?caseId=${caseId}&type=Issue`,
        `${EXECUTIVE_HISTORY_URL}?caseId=${caseId}`,
        `${DEFECT_URL}?caseId=${caseId}`
      ]
      allFetch(url)
      doFetch(`${QUERY_FILE_URL}?funcCode=0501&referenceId=${caseId}`).then(res => setFileList(res))
    }
  }, [allFetch, doFetch, modeAll])

  useEffect(() => {
    if (allData) {
      setParams(x => _.assign({}, x, _.get(allData, '[0]'), { result: modeAll.data.result, actualResult: modeAll.data.actualResult }))
      setDemandData(_.get(allData, '[1]'))
      setIssueData(_.get(allData, '[2]'))
    }
  }, [allData, modeAll])

  const cardSwitch = useCallback((position) => {
    const index = _.findIndex(caseData, o => o.onlyId === modeAll.data.onlyId)
    const isUp = position === 'up'
    let switchData;
    if (isUp && index > 0) {
      switchData = caseData[index - 1]
      setParams(_.assign({}))
      setCurrentId(_.get(switchData, 'onlyId'))
      return setModeAll(x => _.assign({}, x, { data: switchData }))
    } else if (!isUp && index + 1 !== _.size(caseData)) {
      switchData = caseData[index + 1]
      setParams(_.assign({}))
      setCurrentId(_.get(switchData, 'onlyId'))
      return setModeAll(x => _.assign({}, x, { data: switchData }))
    }
    return Messager.show(`已经是${isUp ? '第' : '最后'}一个了`, { icon: 'info' })
  }, [caseData, modeAll, setCurrentId, setModeAll])

  const index = useMemo(() => _.findIndex(caseData, o => {
    return o.onlyId === modeAll?.data.onlyId
  }) + 1, [caseData, modeAll])

  useEffect(() => {
    const dialog = document.querySelector('.caseTesting')
    dialog.addEventListener('click', () => setCommentPopoverVisible(false))
  }, [])

  const contextValue = {
    demandData: _.get(allData, '[1]'),
    trackData: _.get(allData, '[2]'),
    executiveData: _.get(allData, '[3]'),
    defectData: _.get(allData, '[4]'),
    error: {},
    setError: () => { },
    params,
    setParams,
    isDetail: true,
    modeAll,
    setShowDetail,
    caseId: modeAll?.data.id,
  }

  const refreshList = useCallback(() => {
    refresh()
    get(gd, 'planDefects', () => { })()
    setModeAll(x => _.assign({}, x))
  }, [refresh, setModeAll])

  useEffect(() => {
    const box = document.querySelector('.dialog')
    function close(e) {
      if (open?.visible) {
        setTimeout(() => {
          if (resultEnforcementRef.current) {
            resultEnforcementRef.current.setContent(_.clone(''))
          }
        }, 800)
        setResultActive(null)
        setOpen({ visible: false })
      }
    }
    if (box) {
      box.addEventListener('click', close)
      return () => box.removeEventListener('click', close)
    }
  }, [open, resultEnforcementRef, setResultActive])

  return (
    <FormContext.Provider value={contextValue}>
      <Dialog
        loading={loading}
        header={'用例详情'}
        className='caseTesting plan-case'
        style={{ padding: '0 16px' }}
        confirmButtonVisible={false}
        cancel={() => setModeAll(false)}
        footerVisible={false}
      >
        <div className='testCase-content'>
          <div>
            <FormInput
              horizontal
              label='标题'
              labelWidth={50}
              component={Display}
              value={params?.caseName}
              className='useCase-title-border'
              onChange={v => setParams(x => _.assign({}, x, { caseName: v }))}
            />


            <Tab active={active} onChange={setActive}>
              <TabItem header='详情'>
                <AddAndEdit />
              </TabItem>
              <TabItem header='需求'>
                <div key={countReq} style={{
                  flex: 1,
                  display: 'flex',
                  overflow: 'hidden',
                }}>
                  <Demand flag={true} />
                </div>
              </TabItem>
              <TabItem header='ISSUE'>
                <div key={countIssue} style={{
                  flex: 1,
                  display: 'flex',
                  overflow: 'hidden',
                }}>
                  <TestCaseIssue flag={true} />
                </div>
              </TabItem>
              {/* <TabItem header='研发任务'>
                <Track />
              </TabItem> */}
              <TabItem header='验证缺陷'>
                <CaseDefect id={_.get(params, 'id')} />
              </TabItem>
              <TabItem header='发现缺陷'>
                <Defect />
              </TabItem>
              <TabItem header='动态'>
                {
                  active === 4 && <ChangeRecord name='用例' commentReferenceId={_.get(modeAll, 'data.id')} title={params?.caseName} funcCode={'0501'} referenceId={_.get(modeAll, 'data.id')} id={params?.caseId} linkUrl={`/testCaseMgt?treeId=${params?.libraryId}&initId=${_.get(modeAll, 'data.id')}`} />
                }
              </TabItem>
              <TabItem header='执行历史'>
                <ExecutiveHistory />
              </TabItem>
              <TabItem header='附件'>
                <DefectUploadArea id={modeAll?.data?.id} funcCode={'0501'} {...{ allUserRes, fileList, setFileList, mode: modeAll?.mode }} />
              </TabItem>
            </Tab>
          </div>

          {/* 右侧 */}
          <div className='editConditionRight' style={{ paddingTop: 12 }}>
            <div className='edit-header'>
              <Popover
                destroyTooltipOnHide={true}
                open={(open?.visible && open?.id === modeAll.data.id)}
                onOpenChange={visible => {
                  setOpen({ visible: true, id: modeAll.data.id })
                  if (!visible) {
                    setResultActive(null)
                  } else refreshOnlyId()
                }}
                trigger="click"
                overlayClassName='popoverMore'
                content={ActualResult({ data: actualResultOpt, changeResult: handleMenuClick, id: [modeAll.data.id], resultEnforcementRef, setActive: setResultActive, active: resultActive, resultId: onlyId, allUserRes, setOpen })}
              >
                <Button
                  className='actualResultSelBtn'
                  style={{ background: EXECUTION_COLOR[_.get(params, 'result')] }}
                >
                  执行结果：{name}
                </Button>
              </Popover>
              <Button type="primary" onClick={() => setMode('add')}>提缺陷</Button>
            </div>

            <div className='editCondition'>
              <Form value={params} onChange={setParams}>

                <HFormInput label='所属用例库' bind='libraryName' />
                <HFormInput label='用例目录' bind='tableName' />
                <div className="mock-form-item flex">
                  <div className="mock-label">关联需求/自提单</div>
                  <div className="mock-form-content">
                    {
                      !_.isEmpty(demandData) ?
                        <ul className='relationList'>
                          {
                            _.map(demandData, (o, i) => {
                              return <Tooltip key={`${o.interiorReqNo}${i}`} title={o.title}>
                                <li>
                                  <span className={'can-enter-text'} onClick={() => setShowDetail({ type: 'req', id: o.id })}>{o.title}</span>
                                </li>
                              </Tooltip>

                            })
                          }
                        </ul> : <div style={{ height: 8 }}></div>
                    }
                  </div>
                </div>

                <div className="mock-form-item flex">
                  <div className="mock-label">关联ISSUE</div>
                  <div className="mock-form-content">
                    {
                      !_.isEmpty(issueData) ?
                        <ul className='relationList'>
                          {
                            _.map(issueData, o => {
                              return <Tooltip key={o.id} title={o.shortDesc}>
                                <li key={o.id}>
                                  <span className={'can-enter-text'} onClick={() => setShowDetail({ type: 'issue', id: o.id })}>{o.shortDesc}</span>
                                </li>
                              </Tooltip>
                            })
                          }
                        </ul> : <div style={{ height: 8 }}></div>
                    }
                  </div>
                </div>

                <HFormInput label='用例状态' bind='statusName' />
                <HFormInput label='用例类型' bind='typeName' />
                <HFormInput label='测试阶段' bind='testPhaseName' />
                <HFormInput label='优先级' bind='priorityName' />
                <HFormInput label='正反例' bind='positiveOrNegativeName' />
                <HFormInput label='自动化实现' bind='automaticName' />
                <HFormInput tree label='所属产品' bind='productName' />
                <HFormInput label='所属模块' bind='moduleName' />
                <HFormInput label='维护人' bind='maintenanceUserName' />
                <HFormInput label='菜单' bind='menu' />


                <HFormInput label='创建人' bind='createUserName' />
                <HFormInput label='创建时间' bind='createTime' />
                <HFormInput label='更新人' bind='updateUserName' />
                <HFormInput label='更新时间' bind='updateTime' />
                <HFormInput label='用例编号' bind='caseId' convert={v => {
                  const obj = { treeId: _.get(params, 'libraryId'), initId: _.get(params, 'id') }
                  return <div style={{
                    color: '#5477ff',
                    cursor: 'pointer'
                  }} onClick={() => {
                    window.open(`/#/testCaseMgt?${strParams(obj)}`, '_blank')
                  }}>{v}</div>
                }} />
              </Form>
            </div>
          </div>
        </div>
        <div className='testCase-footer'>
          <div></div>
          <div className='arrow-switch'>
            <div
              className={index === 1 ? 'disabled' : ''}
              onClick={() => cardSwitch('up')}
            >
              <Icon name='xiangqian' />&nbsp;上一个
            </div>
            <div
              className={index === _.size(caseData) ? 'disabled' : ''}
              onClick={() => cardSwitch('down')}
            >
              下一个&nbsp;<Icon name='xianghou' />
            </div>
            <div>
              {index}/{_.size(caseData)}
            </div>
            <Popover content={
              <PopoverComment
                referenceId={_.get(params, 'tableId')}
                funcCode={'0501'}
                {...{ allUserRes, commentText, setCommentText }}
                title={params?.caseName}
                linkUrl={''}
                close={() => setCommentPopoverVisible(false)} />
            }
              trigger="click" placement="topRight" open={commentPopoverVisible}>
              <Tooltip title="评论信息">
                <div className={'comment-wrap flex center'} onClick={() => setCommentPopoverVisible(x => !x)}>
                  <Icon name={'zidingyishezhi1'} className={'comment-icon'} />
                </div>
              </Tooltip>
            </Popover>
          </div>
          <div>
            <RootButton text onClick={() => setModeAll(false)}>取消</RootButton>
          </div>
        </div>
      </Dialog>
      {
        _.get(showDetail, 'type') === 'req' &&
        <RequirementDetailDialog
          close={() => setShowDetail(null)}
          delAuth={reqDelAuth}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          showChildList={[]}
          storyTypeOptions={storyTypeOptions}
          refreshViewList={() => {
            refresh()
            allRefresh()
            upDateReq()
          }}
        />
      }
      {
        _.get(showDetail, 'type') === 'issue' &&
        <IssueDetailDialog
          refreshViewList={() => {
            refresh()
            allRefresh()
            upDateIssue()
          }}
          close={() => setShowDetail(null)}
          showChildList={[]}
          currentInfo={{
            id: _.get(showDetail, 'id'),
            mode: 'detail',
          }}
          {...{ isFuncCodeApply, isFuncCodeQaCheck }}
        />
      }
      {
        _.get(showDetail, 'type') === 'defect' &&
        <DefectUpdateDialog
          showChildList={[]}
          currentIndex={0}
          refreshList={() => {
            refresh()
            allRefresh()
          }}
          setCurrentId={() => { }}
          close={() => { setShowDetail(null); setMode('detail') }}
          currentId={_.get(showDetail, 'id')}
          {...{ productOptions, moduleOptionsRes, convertCollection, mode, setMode }}
        />
      }
      {
        mode === 'add' && <DefectUpdateDialog
          close={() => setMode('detail')}
          executiveOutcomes={() => handleMenuClick({ key: '03' })}
          replaceParams={{ 'planId': planId, caseId: modeAll?.data?.id }}
          addDefaultParams={
            _.assign({},
              _.pick(params, ['priority', 'productId']),
              {
                subSysId: params?.moduleId,
                originPhase: _.size(_.split(_.get(params, 'testPhase'), ',')) === 1 ? _.get(params, 'testPhase') : null,
                releaseId: _.get(basicInformationData, 'relatedReleaseId'),
                // fixVersion: _.get(basicInformationData, 'relatedReleaseId'),
                // discoveryProject: _.get(basicInformationData, 'userProjectId'),
                fixProjectIdList: _.get(basicInformationData, 'userProjectId') ? [_.get(basicInformationData, 'userProjectId')] : null,
                projectId: _.get(basicInformationData, 'projectId'),
                menuName: params.menu,
                // tracerId: getTracerId(trackingData),
                interiorReqNo: _.size(demandData) === 1 ? _.head(demandData)?.interiorReqNo : null,
                issueId: _.size(issueData) > 0 ? _.head(issueData)?.id : null,
                issueIdName: _.size(issueData) > 0 ? _.head(issueData)?.issueId : null,
                storyId: _.size(demandData) === 1 ? _.head(demandData)?.id : null
              })
          }
          // defaultDescription={getDescription(_.pick(params, ['caseSteps', 'expectResult', 'preconditions']))}
          {...{ convertCollection, mode, setMode, setCurrentId: () => { }, refreshList, productOptions, moduleOptionsRes }}
        />
      }
    </FormContext.Provider>
  )

}
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { DataGrid } from 'rootnet-ui'
import { Box } from '../../common/commonComponent'
import Options from '../options'
import EditBox from './EditBox'
import { useGet } from '../../../utils/hook'
import { strParams } from '../../../utils/publicFun'
import './index.scss'
import { isNil } from 'rootnet-core/format'

const LIST_URL = 'costrate/waitList'

function getColumns(refresh) {
  return [
    { header: '#', convert: (v, o, i) => i + 1, aligh: 'center', width: 40 },
    { header: '部门', bind: 'deptAllName', aligh: 'left', width: 330, tooltip: true, sortable: true },
    { header: '职级', bind: 'techLevel', aligh: 'left', width: 120, sortable: true },
    { header: '单价(元/小时)', bind: 'price', align: 'right', width: 130, convert: convertPrice, sortable: true },
  ]

  function convertPrice(v, o) {
    const val = (isNil(v) || v === '-') ? 0 : v
    return <EditBox val={val} data={o} refresh={refresh} />
  }
}

function getOptions(columns) {
  return {
    resizable: true,
    virtualized: false,
    fixedLeft: 1,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
    columns,
  }
}

export default function CostRatePending() {
  const [params, setParams] = useState({})
  const [list, setList] = useState([])
  const { data, loading, error, doFetch } = useGet()

  const refresh = useCallback((parameter = {}) => {
    setParams(parameter)
    const url = _.isEmpty(parameter) ? LIST_URL : `${LIST_URL}?${strParams(parameter)}`
    doFetch(url)
  }, [doFetch])

  useEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setList(_.orderBy(data, ['deptAllName', 'techLevel'], 'asc'))
    }
  }, [data])

  const option = useMemo(() => getOptions(getColumns(() => refresh(params))), [params, refresh])

  return (
    <div className='fill flex-y costRatePending'>
      <Options isBind={false} initParams={{}} search={refresh} />
      <Box title="待处理列表" className='x-card-singlegrid' data={list} loading={loading} error={error}>
        <DataGrid data={list} option={option} />
      </Box>
    </div>
  )
}

import React, {useEffect, useState} from 'react';
import {ChromePicker} from 'react-color'

function ColorPicker(props) {
    const {defaultColor, setColor} = props
    const [backgroundColor, setBackgroundColor] = useState(defaultColor || '#122A88')

    useEffect(()=>{
        setColor(defaultColor || '#122A88')
    },[defaultColor, setColor])

    return <div>
        <ChromePicker color={backgroundColor} disableAlpha={true} onChange={color => {
            let hex = color.hex || '';
            let hexUpperCase = hex.toLocaleUpperCase();
            setBackgroundColor(hexUpperCase)
            if(setColor){
                setColor(hexUpperCase)
            }
        }}/>
    </div>
}

export default ColorPicker;
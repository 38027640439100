import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tooltip } from 'antd'
import { Dialog, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, DatePicker, Display } from 'rootnet-edit'
import UserSelect from '../../../personSelectPop/UserSelect'
import { useGet, useApi } from '../../../../../utils/hook'
import convertGlobalConstOptions from '../../../ConvertGlobalConstOptions'
import gd from '../../../../../base/global'
import { Icon } from '../../../../../components'
import RichTextEditor from '../../../../common/richTextEditor/TinyEditor'
import './index.scss'
import convertOptions from "../../../ConvertOptions";

const { str14ToDate, str8ToDate } = toDate
const ADD_URL = '/todolist/add' // 跟进事项新增
const EDIT_URL = '/todolist/update'  // 跟进事项编辑
const DETAIL_URL = '/todolist/getTodolistrecord'
const LIST_URL = [
  '/common/globalconst?globalConst=PRIORITYLIST', // 优先级
  '/common/globalconst?globalConst=AUDITTYPE', // 审核状态
  '/common/globalconst?globalConst=todoType', // 事项类型
  '/common/globalconst?globalConst=FollowUpStatus', // 状态
  '/common/department?activeFlag=Y', // 部门
  '/UserSetting/getUniversalInterfaces?code=部门ID&codeName=部门全路径&tableName=view_department', // 部门全路径
]

function getTypeId(data = {}) {
  const { IssueId, storyId, defectId, caseId, planId, ProjectId, ID, sSubject, CSRecordID, ProjectName } = data
  const impId = (!_.isNil(ProjectId) && !_.isNil(ID)) ? ID : undefined
  return CSRecordID || IssueId || storyId || defectId || caseId || planId || impId || sSubject || ProjectName || ''
}

function buildTree(allList, list, keyName, parentName, level) {
  return _.map(list, listItem => {
    const childList = _.filter(allList, allItem => _.get(allItem, parentName) === _.get(listItem, keyName))
    if (level >= 2 || childList.length === 0) return convertDepartment(listItem)
    return _.assign(convertDepartment(listItem), { children: buildTree(allList, childList, keyName, parentName, level + 1) })
  })
}

function convertDepartment(item) {
  return { text: item.departMentName, value: item.departMentId }
}

const HFormInput = props => <FormInput horizontal labelWidth={80} componentWidth={230} {...props} />

export default function FollowMattersDetail(props) {
  const { id, funcCode, mode, close, refresh, relateId, blur = () => { }, linkUrl, defaultType, pid = null, upDate = () => { }, defaultParams = {} } = props
  const [params, setParams] = useState({})
  const [error, setError] = useState()
  const [disabled, setDisabled] = useState(false)
  const [noteExpand, setNoteExpand] = useState(true)
  const noteRef = useRef()
  const { doFetch, loading } = useApi()
  const { data: optionRes } = useGet(LIST_URL)

  const isAdd = useMemo(() => mode === 'add', [mode])

  const eventType = useMemo(() => {
    return _.isNil(defaultType) ? funcCode : defaultType
  }, [defaultType, funcCode])

  const [priorityOpt, auditStatusOpt, eventTypeOpt, statusOpt, treeDeptOptions, deptAllPathOptions] = useMemo(() => {
    if (_.isEmpty(optionRes)) return []
    const [d1, d2, d3, d4, d5, d6] = optionRes || []
    const firstLevelDepList = _.filter(d5, x => _.get(x, 'parentDept') === "")
    const treeDeptOptions = buildTree(d5, firstLevelDepList, "departMentId", "parentDept", 0)
    return [
      convertGlobalConstOptions(d1),
      convertGlobalConstOptions(d2),
      convertGlobalConstOptions(d3),
      convertGlobalConstOptions(d4),
      treeDeptOptions,
      convertGlobalConstOptions(d6),
    ]
  }, [optionRes])

  const formList = useMemo(() => {
    return _.compact([
      {
        label: '主题',
        bind: 'subject',
        required: true,
        componentWidth: 556,
      },
      !isAdd && {
        label: '状态',
        bind: 'status',
        required: true,
        component: Select,
        options: statusOpt,
      },
      {
        label: '负责人',
        bind: 'principal',
        required: true,
        component: UserSelect,
      },
      !isAdd && {
        label: '处理人',
        bind: 'currentUser',
        required: true,
        component: UserSelect,
      },
      {
        label: '参与人',
        bind: 'otherPerson',
        component: UserSelect,
        multiple: true,
        bindInConvert: v => isNil(v) ? [] : _.split(v, ','),
        bindOutConvert: v => _.join(v, ','),
      },
      {
        label: '提出人',
        bind: 'sponsor',
        component: UserSelect,
      },
      {
        label: '提出时间',
        bind: 'proposedTime',
        required: true,
        component: DatePicker,
        bindInConvert: v => str8ToDate(v),
        bindOutConvert: v => dateFormat('YYYYMMDD', v)
      },
      {
        label: '归属部门',
        bind: 'dataDepartment',
        tree: true,
        component: Select,
        options: treeDeptOptions,
        clear: true,
        convert: v => convertOptions(v, deptAllPathOptions,)
      },
      {
        label: '时间要求',
        bind: 'requireDate',
        required: true,
        component: DatePicker,
        bindInConvert: v => str14ToDate(v),
        bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      },
      {
        label: '优先级',
        bind: 'priority',
        required: true,
        component: Select,
        options: priorityOpt,
      },
      {
        label: '审核人',
        bind: 'auditPerson',
        component: UserSelect,
      },
      !isAdd && {
        label: '审核状态',
        bind: 'auditStatus',
        component: Select,
        clear: true,
        options: auditStatusOpt,
        disabled: params?.auditPerson !== gd.User.operator_id

      },
      {
        label: '事项类型',
        bind: 'eventType',
        component: Select,
        clear: true,
        options: eventTypeOpt,
      },
      !isAdd && {
        label: '来源',
        bind: 'source',
        component: Display,
        bindInConvert: v => <Tooltip title={v}>
          <div
            className={cls('ellipsis-line', { 'source-text-hover': params?.toUrlPath })}
            onClick={() => params?.toUrlPath ? window.open('#' + params?.toUrlPath) : null}>
            {v}
          </div>
        </Tooltip>
      },
    ])
  }, [auditStatusOpt, eventTypeOpt, isAdd, params, priorityOpt, statusOpt, treeDeptOptions, deptAllPathOptions])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (noteRef?.current.loading) {
      return Messager.show("图片上传中，请稍后保存")
    }
    setDisabled(true)
    const url = isAdd ? ADD_URL : EDIT_URL
    const parameter = _.assign({ funcCode }, params, isAdd && { relateId, auditPerson: _.get(params, 'auditPerson') || gd.User.operator_id, recUser: gd.User.operator_id, toUrlPath: linkUrl }, funcCode === '23' && { id, todolistOrOther: 0 }, !_.isNil(pid) && { pid }, defaultParams)
    const postParams = { ..._.omit(parameter, ['source']), note: noteRef.current.getContent() }
    doFetch(url, 'post', isAdd ? [postParams] : postParams)
      .then(() => {
        upDate()
        blur()
        close()
        refresh()
        setDisabled(false)
        Messager.show(isAdd ? '添加成功' : '修改成功', { icon: 'success' })
      }).catch(err => {
        setDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [blur, check, close, defaultParams, doFetch, funcCode, id, isAdd, linkUrl, params, pid, refresh, relateId, upDate])

  useEffect(() => {
    if (isAdd) return setParams({ status: '1', priority: 'B', eventType, proposedTime: dateFormat('YYYYMMDD', new Date()) })
    doFetch(`${DETAIL_URL}?id=${id}&funcCode=${funcCode}`)
      .then(res => {
        const pick = ['id', 'relateId', 'status', 'subject', 'requireDate', 'principal', 'otherPerson', 'priority', 'auditPerson', 'auditStatus', 'eventType', 'toUrlPath', 'note']
        setParams({ ..._.pick(_.head(res), pick), source: getTypeId(_.get(_.head(res), 'source')) })
        setTimeout(() => {
          if (noteRef.current) {
            noteRef.current.setContent(_.get(_.head(res), 'note') || '')
          }
        }, 500)
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, eventType, funcCode, id, isAdd])

  return (
    <Dialog
      className='follow-matters-detail'
      header='跟进事项'
      confirm={_.debounce(confirm, 300)}
      cancel={close}
      loading={isAdd ? false : loading}
      confirmButtonDisabled={disabled}
    >
      <Form value={params} onChange={handChange} error={error} onError={setError}>
        {
          _.map(formList, item => {
            return <HFormInput key={item.bind} {...item} />
          })
        }
        <div className="area-wrap note-wrap flex-y">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: noteExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setNoteExpand(v => !v)} />
            详细描述
          </div>
          <div className="rich-text-area" style={{ display: noteExpand ? "block" : 'none' }}>
            <RichTextEditor ref={noteRef} height={400} />
          </div>
        </div>
      </Form>
    </Dialog>
  )

  function handChange(o, key) {
    if (key === 'auditPerson') {
      if (isNil(o[key])) o.auditStatus = null
    }
    setParams(o)
  }

}

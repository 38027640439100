import React, {useMemo, useState} from 'react';
import {DataGrid} from "rootnet-ui";
import OperationList from "../../../../../../project_share/components/OperationList";
import TraceRelateBranchDialog from "./traceRelateBranchDialog/TraceRelateBranchDialog";
import TraceRelateNewBranchDialog from "./traceRelateNewBranchDialog/TraceRelateNewBranchDialog";
import {Tooltip} from "antd";
import DelMessage from "../../../../../../components/DelMessage";
import Icon from "../../../../../../components/Icon";

function getColumns(props){
    const {setShowDialogInfo, setDelId} = props
    return [
        { header: '关联组件', bind: 'shortName', width: 180, tooltip: true,
            convert: (v, o) => <div className={'flex center-y'}>
                <div className={'can-enter-text'} onClick={()=>setShowDialogInfo(
                    {
                        module: 'componentInfo',
                        mode: 'detail',
                        id: o?.programId,
                    }
                )}>
                    {v}
                </div>
                <Icon className={'del-icon can-click-icon'} name='shanchu' style={{fontSize: 16, marginLeft: 12, color: 'red'}} onClick={()=>setDelId(o?.id)}/>
            </div> },
        { header: 'git项目', bind: 'sourceCodePath', width: 300, tooltip: true },
        { header: '特性分支', bind: 'branchName', width: 300, tooltip: true, convert: v => <Tooltip title={v}>{v}</Tooltip> },
        { header: '操作', bind: '', width: 180, tooltip: true, align: 'center',stretch: false, convert: renderOperation },
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '关联分支',
                onClick: ()=>setShowDialogInfo({
                    module: 'relate',
                    programId: o?.programId,
                    componentInfo: o,
                })
            },
            {
                text: '新增分支',
                onClick: ()=>setShowDialogInfo({
                    module: 'addBranch',
                    programId: o?.programId,
                    componentInfo: o,
                })
            },
        ];
        return <OperationList options={operateOption}/>;
    }
}

function getOptions(columns){
    return {
        nilText: '-',
        emptyText: '-',
        resizable: true,
        autoFill: true,
        virtualized: true,
        fixedRight: 1,
        columns,
    }
}

function TraceRelateComponentList(props) {
    const {list, traceBizId, relateBizId, traceDetail, refreshList, listLoading} = props
    const [showDialogInfo, setShowDialogInfo] = useState()
    const [delId, setDelId] = useState()

    const option = useMemo(()=>{
       return getOptions(getColumns({setShowDialogInfo, setDelId}))
    },[setShowDialogInfo])

    return <>
        <DataGrid data={list} option={option} loading={listLoading}/>
        {
            showDialogInfo?.module === 'relate' &&
            <TraceRelateBranchDialog programId={showDialogInfo?.programId} close={()=>setShowDialogInfo(null)}
                                     {...{traceBizId, relateBizId, traceDetail, showDialogInfo, refreshList}}/>
        }
        {
            showDialogInfo?.module === 'addBranch' &&
            <TraceRelateNewBranchDialog close={()=>setShowDialogInfo(null)} {...{showDialogInfo, relateBizId, refreshList, traceDetail}}/>
        }
        {
            delId &&
            <DelMessage url={'/devops/uprods/trace/program/delete'} refresh={refreshList} method={'post'} params={{id: delId}} close={()=>setDelId(null)} confirmButtonText={'删除'}>
                确定解除关联？
            </DelMessage>
        }
    </>
}

export default TraceRelateComponentList;
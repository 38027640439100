import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Select } from 'rootnet-edit'
import { Button, DataGrid, Pagination } from 'rootnet-ui'
import UserSelect from '../../../../../common/personSelectPop/UserSelect'
import { Box } from '../../../../../common/commonComponent'
import CascadeSelect from '../../../../../common/cascadeSelect/CascadeSelect'
import { errorTips, successTips } from '../../../../../../utils/messagerTips'
import './index.scss'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../../../common/ConvertOptions'
import { downCsv, normalTransCsv } from '../../../../../common/download'
import { N2 } from 'rootnet-core/format'
// import DateSelector from '../../../../../common/dateSelector/DateSelector'

const LIST_URL = '/workingHoursManagement/selectCostForProject'

const OPTIONS_URLS = [
  '/common/department?activeFlag=Y' //部门
]

const GLOBAL_CONST_OPTIONS_URLS = [
  'UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&displayColor=true&filter=InteriOrid&filterParams=1,2&globalConst=workHoursStatus&tableName=GlobalConst',//状态
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=projectCostBudget',
]

const getColumn = (props) => {
  const { stateOpt } = props
  return [
    { header: '类型', bind: 'typeName', sortable: true, align: 'left', width: 80, tooltip: true, convert: v => v },
    { header: '员工', bind: 'userAccountName', sortable: true, align: 'left', width: 90, tooltip: true, convert: v => v },
    { header: '员工部门', bind: 'departmentName', sortable: true, align: 'left', width: 200, tooltip: true, convert: v => v },
    { header: '编号', bind: 'id', sortable: true, align: 'left', width: 110, tooltip: true, convert: v => v },
    {
      header: '状态', bind: 'state', sortable: true, align: 'left', width: 80, tooltip: true, convert: v => {
        return <div style={{ color: convertOptions(v, stateOpt, 'color') }}>{convertOptions(v, stateOpt)}</div>
      },
      csvConvert: v => convertOptions(v, stateOpt)
    },
    { header: '工时周期', bind: 'workCycle', sortable: true, align: 'left', width: 130, tooltip: true, convert: v => v },
    { header: '工时（人日）', bind: 'useDay', sortable: true, align: 'right', width: 90, tooltip: true, convert: v => v },
    { header: '成本（元）', bind: 'price', sortable: true, align: 'right', width: 90, tooltip: true, convert: v => N2(v) },
  ]
}

const gridOptsFor = (columns) => ({
  nilText: '-',
  // fixedLeft: 2,
  // fixedRight: 1,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});


export default function DetailCost(props) {
  const { projectId, data, loading, error, total, pageNum, initParams, refresh } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const [parameter, setParameter] = useState(initParams)
  const [pageSize, setPageSize] = useState(20)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { doFetch: exportFetch } = usePost()

  const [stateOpt, typeOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const [deptOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes || []
    return [
      _.map(d1, o => ({ text: o.departMentName, value: o.departMentId, pid: o.parentDept }))
    ]
  }, [optionsRes])

  const csvExport = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)
    const getParams = _.assign({}, parameter, { pageNum: 1, pageSize: 1000, projectId })
    exportFetch(LIST_URL, getParams)
      .then(res => {
        const { rows: list } = res?.pageResult
        const options = getColumn({ stateOpt })
        const exportData = normalTransCsv(options, list)
        downCsv(exportData, '项目成本明细')
        setIsDisabled(false)
        successTips('导出成功')
      })
      .catch(err => {
        setIsDisabled(false)
        errorTips(err._message)
      })
  }, [isDisabled, exportFetch, stateOpt, parameter, projectId])

  const option = useMemo(() => gridOptsFor(getColumn({ stateOpt })), [stateOpt])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn text='导出' icon='daochu' onClick={csvExport} />
    </div>
  }, [csvExport])

  return (
    <div className='project-cost-detail'>
      <Option search={refresh} {...{ setParameter, stateOpt, typeOpt, deptOptions, initParams }} />
      <Box className='flex-y x-card-singlegrid' title='项目成本明细' data={data} error={error} extra={extra} loading={loading}>
        <DataGrid data={data} option={option} />
        <Pagination selector total={total} current={pageNum} pageSize={pageSize} onChange={(pageNum, pageSize) => {
          setPageSize(pageSize)
          setParameter(x => {
            const params = _.assign({}, x, { pageNum, pageSize })
            refresh(params)
            return params
          })
        }} />
      </Box>
    </div>
  )
}

function Option(props) {
  const { projectId, search, setParameter, stateOpt, typeOpt, deptOptions, initParams } = props
  const [params, setParams] = useState(initParams)
  return <div className='flex c-options project-cost-detail-option'>
    <Form value={params} onChange={setParams}>
      <FormInput label='类型' bind='types' component={Select} options={typeOpt} clear multiple />
      <FormInput label='编号' bind='id' />
      <FormInput label='员工' bind='userAccounts' multiple component={UserSelect} allowClear />
      <FormInput label='员工部门' bind='departments' component={CascadeSelect} multiple search clear options={deptOptions} />
      <FormInput label='状态' bind='states' component={Select} options={stateOpt} clear multiple />
      {/* <FormInput label='工时周期' bind='workCycle' range component={DateSelector} clear componentWidth={220} /> */}
    </Form>
    <div className='search-btn-group flex'>
      <Button className={'search-btn'} primary onClick={() => {
        search(params)
        setParameter(params)
      }} >查询</Button>
      <Button className={'reset-btn'} text onClick={() => {
        setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), initParams))
        search({ ...initParams, projectId })
        setParameter({ ...initParams, projectId })
      }} >重置</Button>
    </div>
  </div>
}

import React, { useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { Form, FormInput, Select, TextArea } from 'rootnet-edit'
import { useGet, useApi } from '../../../../utils/hook'
// import PersonSelect from "../../../common/personSelect/PersonSelect";
import UserSelect from '../../../common/personSelectPop/UserSelect'

const PARENT_URL = '/caseLibrary/caseLibraryfather'
const ADD_URL = '/caseLibrary/add'
const EDIT_SEARCH_URL = '/caseLibrary/one'
const EDIT_URL = '/caseLibrary/update'

const TITLE_NAME = Object.freeze({
  add: '添加用例库',
  edit: '编辑用例库',
  addChild: '添加子用例库',
})

export default function DisplayForm(props) {
  const { modes, close, refresh, pid, id } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const { data: parentData, doFetch: parentDoFetch } = useGet()
  const { data: editData, doFetch: editDoFetch } = useGet()
  const { doFetch } = useApi()
  // const { data: allUserRes} = useGet('/common/userinfo')

  const showParent = useMemo(() => {
    return (modes === 'addChild' || (modes === 'edit' && Number(pid) !== 0))
  }, [modes, pid])

  useEffect(() => {
    if (modes === 'addChild') {
      const val = { pid }
      setParams(val)
    }
    if (showParent) parentDoFetch(PARENT_URL)
  }, [modes, parentDoFetch, pid, showParent])

  useEffect(() => {
    if (modes === 'edit') editDoFetch(`${EDIT_SEARCH_URL}?cseLibrarId=${id}`)
  }, [editDoFetch, id, modes])

  useEffect(() => {
    if (!editData) return
    if (modes === 'edit') {
      setParams(_.assign({}, _.pick(editData, ['id', 'memo', 'name', 'pid']), {
        participants: _.compact(_.split(editData.participants, ',')),
        editor: _.compact(_.split(editData.editor, ',')),
      }))
    }
  }, [editData, modes])

  const parentOpt = useMemo(() => {
    return _.map(parentData, item => ({ text: item.name, value: item.id }))
  }, [parentData])

  const header = useMemo(() => {
    return TITLE_NAME[modes]
  }, [modes])

  const searchList = [
    {
      required: true,
      label: '用例库标题',
      bind: 'name',
    },
    showParent && {
      required: true,
      label: '父级用例库',
      bind: 'pid',
      component: Select,
      options: parentOpt,
      search: true,
    },
    {
      label: '编辑人员',
      bind: 'editor',
      multiple: true,
      allowClear: true,
      component: UserSelect,
      value: _.get(params, 'editor', []),
    },
    {
      label: '关注人员',
      bind: 'participants',
      multiple: true,
      allowClear: true,
      component: UserSelect,
      value: _.get(params, 'participants', []),
    },
    {
      label: '说明',
      bind: 'memo',
      component: TextArea,
      maxLength: 100,
    },
  ]

  return (
    <Dialog
      header={header}
      confirm={confirm}
      cancel={close}
      className='testCase-displayForm'
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        {
          _.map(_.compact(searchList), item => {
            return <FormInput
              horizontal
              key={item.bind}
              componentWidth={400}
              {...item}
            />
          })
        }
      </Form>
    </Dialog>
  )

  function check() {
    return _.every(_.values(error), isNil)
  }

  function confirm() {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    let editParams = []

    if (modes === 'edit') {
      const defaultData = ['memo', 'name', 'editor', 'participants']
      const defaultList = _.pick(editData, defaultData)
      editParams = _.compact(_.map(defaultList, (val, key) => {
        if (key === 'editor' && (params[key]) !== val) return key
        if (key === 'participants' && (params[key]) !== val) return key
        if (val !== params[key] && key !== 'participants' && key !== 'editor') return key
      }))
    }

    const parameter = _.assign({},
      modes === 'edit' && _.pick(params, ['id', 'pid', ...editParams]),
      modes === 'add' && { pid: 0, ...params },
      modes === 'addChild' && params,
      ((modes === 'edit' && _.includes(editParams, 'editor')) || modes === 'addChild' || modes === 'add') && params.editor &&
      { editor: _.isEmpty(params.editor) ? '' : _.join(params.editor, ',') },
      ((modes === 'edit' && _.includes(editParams, 'participants')) || modes === 'addChild' || modes === 'add') && params.participants &&
      { participants: _.isEmpty(params.participants) ? '' : _.join(params.participants, ',') },
    )
    const url = modes === 'edit' ? EDIT_URL : ADD_URL

    doFetch(url, 'post', parameter)
      .then(() => {
        close()
        refresh()
        Messager.show(`${TITLE_NAME[modes]}成功`, { icon: 'success' })
      })
      .catch((err) => {
        close()
        Messager.show(err._message, { icon: 'error' })
      })

  }

}

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import './RequirementInfo.scss'
import Icon from "../../../../components/Icon";
import { DatePicker, Form, FormInput, Select, Display, Input, TextArea } from "rootnet-edit";
import _ from "lodash";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import RichTextEditor from "../../../common/richTextEditor/TinyEditor";
import convertOptions from "../../../common/ConvertOptions";
import { Image, Tooltip } from "antd";
import CascadeSelect from "../../../common/cascadeSelect/CascadeSelect";
import { uniqKeyFor } from "rootnet-biz/es/utils";
import clsx from 'clsx';

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=PRIORITYLIST',
    'common/globalconst?globalConst=ReqType',
    // 'common/globalconst?globalConst=storySort',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=storyCategory',
    'common/globalconst?globalConst=releaseType',
    'common/globalconst?globalConst=BizProcess',
    'common/globalconst?globalConst=reqLevel',
]

const OPTIONS_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/verQuery/queryCustOptions',
]

function RequirementInfo(props) {
    const { editFormData, setEditFormData, isDetail, initFormData, descRef, infoError, setInfoError, customerOptionsRes, getProjectId, solutionRef } = props
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const [solutionExpand, setSolutionExpand] = useState(true)
    const [testSuggestExpand, setTestSuggestExpand] = useState(true)

    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    // const {data: salesProjectIdRes, doFetch: getSalesProjectId} = useGet()
    // const {data: allSalesProjectIdRes} = useGet('/sales/project')
    const [isInited, setInited] = useState(false)
    const [imageVisible, setImageVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    // const {data: jiraUrlRes} = useGet('/common/globalpara?paraId=Jira')

    // const jiraUrl = useMemo(()=>{
    //     return _.get(jiraUrlRes,'paraValue')
    // },[jiraUrlRes])

    const { customer, custSystem } = useMemo(() => editFormData || {}, [editFormData])

    useEffect(() => {
        if (isInited) return
        if (_.isNil(custSystem)) return
        getProjectId('/story/getbugProjectName?id=' + custSystem).then(res => {
            // const customer = _.get(res,'0.customer')
            // getSalesProjectId('/sales/project?tCustId='+customer)
        })
        setInited(true)
    }, [custSystem, getProjectId, isInited])

    useLayoutEffect(() => {
        if (_.isEmpty(initFormData)) return
        setTimeout(() => {
            if (descRef.current) {
                let initText = _.replace(_.get(initFormData, 'description'), /\n/g, '<br/>') || ''
                initText = initText.replace(/[^<>]+(?=<)/g, match => match.replace(/\s/g, '&nbsp;'));
                descRef.current.setContent(initText)
            }
            if (solutionRef.current) {
                let initText = _.replace(_.get(initFormData, 'solution'), /\n/g, '<br/>') || ''
                initText = initText.replace(/[^<>]+(?=<)/g, match => match.replace(/\s/g, '&nbsp;'));
                solutionRef.current.setContent(initText)
            }
        }, 500)
    }, [initFormData, descRef, solutionRef])

    const customerOptions = useMemo(() => {
        if (_.isEmpty(customerOptionsRes)) return []
        return _.map(customerOptionsRes, x => ({ text: x.customerName, value: x.customer }))
    }, [customerOptionsRes])

    const [priorityOptions, reqSortOptions, characterOptions, releaseTypeOptions, bizProcessOptions, reqLevelOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const [productOptions, moduleOptionsRes, custSystemOptions, allProductOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4] = optionsRes
        const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        return [
            productOptions,
            d3,
            _.map(d4, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}` })),
            _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.projectName}${x.projectId}` }))
        ]
    }, [optionsRes])

    const sortCustSystemOptions = useMemo(() => {
        const initList = _.get(initFormData, 'custSystems')
        return _.sortBy(custSystemOptions, x => {
            return !_.includes(initList, x.value)
        })
    }, [custSystemOptions, initFormData])

    // const needSalesProjectIdFilter = useMemo(()=>{
    //     const reqSort = _.get(editFormData, 'reqSort')
    //     return _.includes(['SPC','NORMAL'],reqSort)
    // },[editFormData])

    // const salesProjectIdFilterOptions = useMemo(()=>{
    //     const salesProjectIdOptions = _.map(salesProjectIdRes, x => ({value: _.toString(x.id), text: x.projectName, salesSort: x.salesSort}))
    //     let salesProjectIdFilterOptions = needSalesProjectIdFilter ? _.filter(salesProjectIdOptions, x => x.salesSort !== 'Presales') : salesProjectIdOptions
    //     return salesProjectIdFilterOptions.sort((a,b) => {
    //         return _.get(a,'text').localeCompare(_.get(b,'text'))
    //     })
    // },[salesProjectIdRes, needSalesProjectIdFilter])

    // const salesProjectIdOptions = useMemo(()=>{
    //     const salesProjectId = _.get(editFormData, 'salesprojectid')
    //     if(_.isNil(salesProjectId)) return salesProjectIdFilterOptions
    //     const valueList = _.map(salesProjectIdFilterOptions, 'value')
    //     if(!_.includes(valueList, salesProjectId)){
    //         // setEditFormData(old => ({...old, salesprojectid: null}))
    //         const findItem = _.find(allSalesProjectIdRes, x => _.toString(x.id) === salesProjectId)
    //         if(_.isNil(findItem)){
    //             return salesProjectIdFilterOptions
    //         }else{
    //             return _.concat([{text: _.get(findItem,'projectName'), value: _.toString(_.get(findItem,'id')), _disabled: true}],salesProjectIdFilterOptions)
    //         }
    //     }
    //     return salesProjectIdFilterOptions
    // },[editFormData, salesProjectIdFilterOptions, allSalesProjectIdRes])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(_.get(editFormData, 'productId'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === editFormData.productId)
        return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId }))
    }, [editFormData, moduleOptionsRes])

    const changeForm = useCallback((newObj, bind) => {
        setEditFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'custSystem') {
                cloneObj['salesProjectId'] = null
                cloneObj['custSystems'] = _.isNil(_.get(newObj, 'custSystem')) ? [] : [_.get(newObj, 'custSystem')]
                getProjectId('/story/getbugProjectName?id=' + _.get(newObj, 'custSystem'))
            }
            if (bind === 'reqSort') {
                if (!_.includes(['SPC', 'NORMAL', 'FUNCTION'], cloneObj['reqSort'])) {
                    cloneObj['salesprojectid'] = null
                }
                if (_.includes(['SPC', 'NORMAL'], cloneObj['reqSort']) && (cloneObj['leafProjectId'] !== initFormData['leafProjectId'])) {
                    cloneObj['leafProjectId'] = null
                }
            }
            return cloneObj
        })
    }, [getProjectId, setEditFormData, initFormData])

    useEffect(() => {
        if (_.isEmpty(customerOptionsRes)) return
        const valueList = _.map(customerOptionsRes, 'customer')
        if (!_.isNil(custSystem) && !_.includes(valueList, customer)) {
            const filterStatus = _.filter(customerOptionsRes, o => o?.status !== '1')
            const customerValue = _.get(filterStatus, '0.customer')
            changeForm({ customer: customerValue })
        }
    }, [customerOptionsRes, custSystem, changeForm, customer])

    // const salesProjectIdRequired = useMemo(()=>{
    //     if(type !== 'CUS') return false
    //     const reqSort = _.get(editFormData, 'reqSort')
    //     return _.includes(['SPC','NORMAL','FUNCTION'],reqSort)
    // },[editFormData, type])

    const previewImage = useCallback(e => {
        if (e.target.tagName === 'IMG') {
            setImageSrc(e.target.src)
            setImageVisible(true)
        }
    }, [])

    const isCUS = useMemo(() => {
        return _.get(editFormData, 'type') === 'CUS'
    }, [editFormData])

    useEffect(() => {
        if (isCUS) {
            if (_.isNil(_.get(editFormData, 'reqLevel'))) {
                setInfoError(x => _.assign({}, x, { 'reqLevel': '必填项！' }))
            }
        } else {
            setInfoError(x => _.assign({}, x, { 'reqLevel': null }))
        }
    }, [isCUS, editFormData, setInfoError])

    const reqLevelTips = useMemo(() => {
        return <div>
            需求等级：
            {
                _.map(reqLevelOptions, x => {
                    return <div key={uniqKeyFor()}>
                        <br />
                        {`${x.text}： ${x.memo}`}
                    </div>
                })
            }
        </div>
    }, [reqLevelOptions])

    return <div className={'requirement-info-wrap'}>
        <div className="area-wrap base-info-wrap">
            <div className="area-header base-info-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
                基本信息
            </div>
            <div className="area-content-wrap" style={{ height: baseInfoExpand ? '' : 0 }}>
                <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
                    <HFormInput label={'需求等级'} bind={'reqLevel'} required={isCUS} clear={!isCUS} component={isDetail ? Display : Select}
                        options={reqLevelOptions} convert={v => convertOptions(v, reqLevelOptions)} />
                    <Tooltip title={reqLevelTips} overlayStyle={{ width: 430, maxWidth: 430, whiteSpace: 'pre-wrap' }}>
                        <span className={'cursor-pointer'}>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'需求类别'} bind={'category'} required component={isDetail ? Display : Select} labelWidth={86}
                        options={characterOptions} convert={v => convertOptions(v, characterOptions)} />
                    <HFormInput label={'优先级'} bind={'priority'} required component={isDetail ? Display : Select} options={priorityOptions} convert={v => convertOptions(v, priorityOptions)} />
                    <HFormInput label={'所属产品'} bind={'productId'} search required component={isDetail ? Display : Select} tree options={productOptions} convert={v => convertOptions(v, allProductOptions)} />
                    <HFormInput label={'子产品'} bind={'subSysId'} search required component={isDetail ? Display : Select}
                        options={moduleOptions} convert={v => convertOptions(v, moduleOptions)} componentClass={'sub-sys-id-options'} />
                    <HFormInput label={'需求标识'} bind={'reqSort'} required component={isDetail ? Display : Select} options={reqSortOptions}
                        convert={v => convertOptions(v, reqSortOptions)} />
                    {/* disabled={!_.isNil(_.get(initFormData,'leafProjectId'))} */}
                    <HFormInput label={'发布方式'} bind={'releaseType'} component={isDetail ? Display : Select} options={releaseTypeOptions}
                        convert={v => convertOptions(v, releaseTypeOptions)} />
                    <HFormInput label={'客户系统'} bind={'custSystem'} search required component={isDetail ? Display : Select}
                        options={custSystemOptions} convert={v => convertOptions(v, custSystemOptions)} componentClass={'customer-system-options'} />
                    <HFormInput label={'提出客户'} bind={'customer'} search required component={isDetail ? Display : Select} options={customerOptions} convert={v => convertOptions(v, customerOptions)} />
                    <HFormInput label={'发布客户系统'} bind={'custSystems'} search required component={isDetail ? Display : Select} multiple clear toolbar={false}
                        options={sortCustSystemOptions} convert={v => {
                            const showValue = _.join(_.map(v, x => convertOptions(x, custSystemOptions)), '、')
                            return <Tooltip title={showValue}>
                                <div className={'one-line-text'}>
                                    {showValue || '-'}
                                </div>
                            </Tooltip>
                        }} />
                    {/*<HFormInput label={'所属商机'} bind={'salesprojectid'} search required={salesProjectIdRequired} disabled={!salesProjectIdRequired} componentClass={'sales-project-id-options'}*/}
                    {/*            component={isDetail? Display: Select} options={salesProjectIdOptions}*/}
                    {/*            convert={v =>*/}
                    {/*            (<div className={'sales-project-id'}>*/}
                    {/*                <Tooltip title={convertOptions(v, salesProjectIdOptions)}>*/}
                    {/*                    {convertOptions(v, salesProjectIdOptions)}*/}
                    {/*                </Tooltip>*/}
                    {/*            </div>)*/}
                    {/*            }/>*/}
                    <HFormInput label={'业务流程环节'} bind={'bizProcess'} component={isDetail ? Display : CascadeSelect} options={bizProcessOptions}
                        convert={v => convertOptions(v, bizProcessOptions)} />
                    <HFormInput label={'客户问题编号'} bind={'custprojectid'} component={isDetail ? Display : Input} className={clsx({ 'text-area-detail-style': isDetail })} />
                    <HFormInput label={'客户提出人'} bind={'custcontact'} component={isDetail ? Display : Input} />
                    <HFormInput label={'客户部门'} bind={'custdepart'} component={isDetail ? Display : Input} />
                    <HFormInput label={'费用部门'} bind={'custfeedepartment'} component={isDetail ? Display : Input} />
                    <HFormInput label={'联系方式'} bind={'custcontactinfo'} component={isDetail ? Display : Input} />
                    <HFormInput label={'提出日期'} bind={'custinputdate'} component={isDetail ? Display : DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}
                    />
                    <HFormInput label={'希望提供日期'} bind={'custexpecteddate'} component={isDetail ? Display : DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }} />
                    <HFormInput label={'客户项目名称'} bind={'custprojectname'} component={isDetail ? Display : Input} />
                    {/*<HFormInput label={'jiraId'} bind={'jiraId'} component={Display}*/}
                    {/*            convert={v => v ? <div className={'jira-id'} onClick={()=>{*/}
                    {/*                window.open(jiraUrl+v, "_blank")*/}
                    {/*            }}>*/}
                    {/*                {v}*/}
                    {/*            </div>:null}/>*/}
                </Form>
            </div>
        </div>
        <div className="area-wrap desc-wrap flex-y">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
                描述
                {
                    !isDetail &&
                    <span style={{ color: 'red' }}>（必填）</span>
                }
            </div>
            <div className="rich-text-detail-wrap flex" style={{ display: isDetail && descExpand ? "block" : 'none' }}>
                <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'description') }} />
            </div>
            <div className="rich-text-area" style={{ display: !isDetail && descExpand ? "block" : 'none' }}>
                <RichTextEditor ref={descRef} height={400} />
            </div>
        </div>
        <div className="area-wrap desc-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: solutionExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setSolutionExpand(v => !v)} />
                解决方案
            </div>
            <div className="rich-text-detail-wrap flex" style={{ display: isDetail && solutionExpand ? "block" : 'none' }}>
                <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'solution') }} />
            </div>
            <div className="rich-text-area" style={{ display: !isDetail && solutionExpand ? "block" : 'none' }}>
                <RichTextEditor ref={solutionRef} height={400} />
            </div>
        </div>
        <div className="area-wrap desc-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: testSuggestExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setTestSuggestExpand(v => !v)} />
                用户测试建议
            </div>
            <div className="rich-text-detail-wrap flex" style={{ display: isDetail && testSuggestExpand ? "block" : 'none' }}>
                {_.get(editFormData, 'testSuggest')}
            </div>
            <div className="rich-text-area" style={{ display: !isDetail && testSuggestExpand ? "block" : 'none' }}>
                <TextArea value={_.get(editFormData, 'testSuggest')} onChange={(value) => setEditFormData(x => ({ ...x, testSuggest: value }))}
                    maxLength={1000} className={'test-suggest-textarea'} />
            </div>
        </div>
        <Image
            style={{ display: 'none' }}
            src={imageSrc}
            preview={{
                visible: imageVisible,
                src: imageSrc,
                onVisibleChange: visible => {
                    setImageVisible(visible)
                }
            }}
        />
    </div>
}

export default RequirementInfo;
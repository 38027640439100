//node_modules
import React, { useState, useMemo, useReducer, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { Select, TextInput, FormInput, Button, DatePicker, } from 'rootnet-ui';
import { DataChange, isNil } from 'rootnet-core/format'

//Local_modules
import { useGet } from '../../../utils/hook';
import { strParams } from '../../../utils/publicFun';
import Detail from '../../task/common/Details'
import Panel from '../../task/common/Panel'

//scss_style
import '../../task/TaskList';

const urls = [
    "common/userinfo",
    "common/globalconst?globalConst=TaskStatus"
];
const url_list = 'develop/project/task/list'

function reducer(state, action) {
    const { name, value } = action
    if (name === 'testCompleteDate') {
        state.beginDate = DataChange(_.get(value, 'begin'))
        state.endDate = DataChange(_.get(value, 'end'))
    }
    return { ...state, [name]: value }
}

function Option(props) {
    const { dataOpt: data, search, project } = props
    const [state, dispatch] = useReducer(reducer, { issueStateList: '0' })
    const [options_pricipal, options_status] = useMemo(() => {
        const [d2, d3] = data || [null, null];
        const item = { text: "全部", value: "" };
        const ops_p = [item, ..._.map(d2, o => { return { text: ''.concat(o.userAccount, '-', o.userName), value: o.userAccount } })];
        const ops_s = [item, ..._.map(d3, o => { return { text: o.displayName, value: o.interiorId } })];
        return [ops_p, ops_s];
    }, [data]);

    useEffect(() => {
        if (isNil(project)) return
        const param = _.assign(state, { ...project })
        search(param)
    }, [project, search, state])

    function query() {
        const param = _.assign(state, { ...project })
        search(param)
    }

    const { issueStateList } = state

    return <div className="list-option">
        <FormInput label="负责人" component={Select}
            search
            options={options_pricipal}
            onChange={(e, o) => { dispatch({ name: "principal", value: _.get(o, 'value') }) }}
        />
        <FormInput label="研发任务ID" component={TextInput} placeholder='请输入'
            onChange={(e, o) => { dispatch({ name: "tracerId", value: e }) }}
        />
        <FormInput label="截止时间" component={DatePicker} range
            onChange={e => { dispatch({ name: "testCompleteDate", value: e }) }}
        />
        <FormInput label="状态" component={Select}
            options={options_status}
            onChange={(e, o) => { dispatch({ name: "issueStateList", value: _.get(o, 'value') }) }}
            value={issueStateList}
        />
        <Button primary onClick={query}>确定</Button>
    </div>
}

function TaskDetailsPro(props) {
    return <Detail {...props} />
}

function TaskListPro(props) {
    const { project } = props
    const [param, setParam] = useState(props);
    const { data: dataOpt } = useGet(urls);
    const { data, doFetch, loading, error } = useGet()

    const search = useCallback((arg) => {
        setParam(arg)
        const optParams = ['projectId', 'id', 'principal', 'beginDate', 'endDate', 'tracerId', 'issueStateList']
        const params = _.pick(arg, optParams)
        doFetch(`${url_list}?${strParams(params)} `)
    }, [doFetch]);

    return <div className="v-task-list">
        <Option dataOpt={dataOpt} search={search} project={project} />
        <Panel param={param} data={data} loading={loading} error={error} data_New={dataOpt} {...props} page='pro_detail' />
    </div>
}

export {
    TaskDetailsPro,
    TaskListPro,
}

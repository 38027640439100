import React from 'react'
import _ from 'lodash'
import { Progress, Empty } from 'antd'
import { Icon } from '../../../components'
import { TextIconBtn } from '../../common/commonComponent'
import { uniqKeyFor } from '../../../project_share/utils/utils'
import { strParams } from '../../../utils/publicFun'
import { getDate, isInterval, beforDate } from './method'

const STATUS_COLOR = {
  '01': '#FF7D00',
  '02': '#5477ff',
  '03': '#c4c4c4',
}

export default function ListItem(props) {
  const { data = [], routingHop, setCurrentId, statusOpt, setRelationId, history } = props

  return (
    <div className="card-group flex">
      {
        _.isEmpty(data) ? <div style={{ width: '100%' }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div> :
          _.map(data, (item, i) => {
            const disabled = (isInterval(item.beginDate, item.endDate) && item.status === '02')
            const isEvaluationList = (beforDate(item.beginDate) && item.status === '01' && item.flag === 'true')
            return <div className="card-item card-detail flex-y" key={i}>
              <div className="card-header flex center-y">
                <div className='card-left flex'>
                  <div className="card-status">
                    <span style={{ backgroundColor: STATUS_COLOR[item.status] }}>
                      {_.find(statusOpt, o => item.status === o.value)?.text}
                    </span>
                  </div>
                  <div className="card-title">{item.title}</div>
                </div>

                <div className='card-right'>
                  <TextIconBtn
                    onClick={() => {
                      if (!isEvaluationList) return
                      setRelationId(item.id)
                      history.replace(_.assign({}, { pathname: '/360Evaluate' }, { search: strParams({ relation: item.id }) }))
                    }}
                    text='评价名单'
                    icon='gerenzhongxin'
                    disabled={!isEvaluationList}
                    className={(!isEvaluationList) ? 'card-btn-disabled' : ''}
                  />
                  <TextIconBtn
                    onClick={() => {
                      if (!disabled) return
                      setCurrentId(item.id)
                      routingHop(item.id)
                    }}
                    disabled={!disabled}
                    text='开始评价'
                    icon='gengxinshijian'
                    className={!disabled ? 'card-btn-disabled' : ''}
                  />
                </div>
              </div>
              <div className='card-content flex'>

                <NumItem icon='shuhui' num={item.evaluationNum} text='需评价数量' />

                <NumItem icon='tianjiazhibiaolie' num={item.noEvaluationNum} text='待评价数量' />

                <div className='content-progress'>
                  <Progress percent={((item.haveEvaluation / item.evaluationNum) * 100).toFixed(2)} />
                </div>

                <div className='content-item flex-y'>
                  <div className='content-num'>{getDate(item.beginDate, item.endDate)}</div>
                  <div className='content-text'>评价要求时间</div>
                </div>

              </div>
            </div>
          }).concat(new Array(4).fill(0).map(() => (<div key={uniqKeyFor()} className='empty-item' />)))
      }
    </div>
  )
}

function NumItem(props) {
  const { icon, num, text } = props
  return <div className='flex content-item'>
    <div className='content-icon'><Icon name={icon} /></div>
    <div className='content-rig'>
      <div className='content-num'>{num}</div>
      <div className='content-text'>{text}</div>
    </div>
  </div>
}

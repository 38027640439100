import React, {useCallback, useEffect, useMemo, useState, useImperativeHandle,forwardRef} from 'react';
import _ from "lodash";
import {Button} from 'rootnet-ui'
import {FormInput, Input, Form, Select, DatePicker} from "rootnet-edit";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import './CustomizeOptions.scss'
import {Icon} from "../../../components";
import {Popover, Empty, Badge} from "antd";
import {useGet} from "../../../utils/hook";
import {strParams} from "../../../utils/publicFun";
import convertGlobalConstOptions from "../ConvertGlobalConstOptions";
import UserSelect from "../personSelectPop/UserSelect";
import {isNil} from 'rootnet-core/format'
import DateSelector from "../dateSelector/DateSelector";
import CascadeSelect from "../cascadeSelect/CascadeSelect";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import TextAreaInput from "../textAreaInput/TextAreaInput";
import ViewAdvancedQuery from "./viewAdvancedQuery/ViewAdvancedQuery";
import {handleTreeOptions} from "../TreeFunc";

const {dateStringToDate} = toDate;

function CustomizeOptions(props, ref) {
    const { search, getInitParams, allOptions = [], baseOptions = [],funcCode, replaceParams = null, onSearch = ()=>{},
        replaceAllParams, resetSearch, pageSize, extraBtnArea, loading = false, allColumns, afterUpdatedRefresh, advancedQueryNum } = props;
    const filterOptions = useMemo(()=>{
        if(_.isEmpty(allOptions)) return []
        return _.filter(allOptions, x => _.get(x, 'custom') !== 'Y' && _.get(x, 'filterDisplayFlag') !== 'N' && _.get(x, 'filterDisplayFlag') !== '04')
    },[allOptions])
    const [ params, setParams ] = useState();
    const [ showAddPop, setShowAddPop ] = useState(false)
    const [searchNotAddText, setSearchNotAddText] = useState()
    const [activeOptions, setActiveOptions] = useState(baseOptions)
    const {data: customizeInitParamsRes, doFetch: getCustomizeInitParams} = useGet()
    const {data: fieldConvertList, doFetch: getFieldConvertList} = useGet()
    const [convertCollection, setConvertCollection] = useState({})
    const [uniqueSourceList, setUniqueSourceList] = useState([])
    const [isShowAdvancedQuery, setIsShowAdvancedQuery] = useState(false)
    const [isInited, setIsInited] = useState(false)

    useEffect(()=>{
        if(_.isEmpty(activeOptions)) return
        const globalCountFieldList = _.filter(activeOptions, x => !_.isNil(x.fieldDateSource) && !_.isNil(x.fieldDateDetailSource))
        const globalCountList = _.map(globalCountFieldList, x => x.fieldDateDetailSource)
        setUniqueSourceList(_.uniq(globalCountList))
    },[activeOptions,getFieldConvertList])

    useEffect(()=>{
        if(_.isEmpty(uniqueSourceList)) return
        getFieldConvertList(uniqueSourceList)
    },[uniqueSourceList, getFieldConvertList])

    useEffect(()=>{
        if(_.isEmpty(fieldConvertList)) return
        const globalCountFieldList = _.filter(activeOptions, x => !_.isNil(x.fieldDateSource) && !_.isNil(x.fieldDateDetailSource))
        const globalCountNameSourceList = _.map(globalCountFieldList, x => ({
            tableField: x.bind,
            dataSource: x.fieldDateDetailSource
        }))
        const convertCollection = {}
        _.forEach(globalCountNameSourceList, (obj) => {
            convertCollection[obj.tableField] = convertGlobalConstOptions(getSource(obj.dataSource))
        })
        setConvertCollection(convertCollection)

        function getSource(source){
            const index = _.findIndex(uniqueSourceList, x => x === source )
            return fieldConvertList[index]
        }
    },[activeOptions,fieldConvertList, uniqueSourceList])

    const mustSelectList = useMemo(()=>{
        if(_.isEmpty(filterOptions)) return []
        return _.map(_.filter(filterOptions, x => x.filterDisplayFlag === "01"), 'bind')
    },[filterOptions])

    const defaultSelectList = useMemo(()=>{
        if(_.isEmpty(filterOptions)) return []
        return _.map(_.filter(filterOptions, x => x.filterDisplayFlag === "02"), 'bind')
    },[filterOptions])

    useEffect(()=>{
        if(_.isNil(customizeInitParamsRes) || _.isEmpty(filterOptions)) return
        if(isInited) return
        if(resetSearch){
            const keyList = [...mustSelectList, ...defaultSelectList]
            const firstTimeParams = {}
            _.forEach(keyList, x => firstTimeParams[x] = null)
            setActiveOptions(_.filter(filterOptions, x => _.includes(keyList,x.bind)))
            setParams({...firstTimeParams,...getInitParams()})
            search({...firstTimeParams, ...getInitParams(), ...replaceParams})
        }else if(!_.isNil(replaceAllParams)){
            const keyList = _.map(replaceAllParams, (v,k) => k)
            setActiveOptions(_.filter(filterOptions, x => _.includes(keyList,x.bind) ))
            setParams({...getInitParams(),...replaceAllParams})
            search({...getInitParams(), ...replaceAllParams})
        }else{
            if(customizeInitParamsRes === 'first time enter'){
                const keyList = [...mustSelectList, ...defaultSelectList]
                const firstTimeParams = {}
                _.forEach(keyList, x => firstTimeParams[x] = null)
                setActiveOptions(_.filter(filterOptions, x => _.includes(keyList,x.bind)))
                setParams({...firstTimeParams,...getInitParams()})
                search({...firstTimeParams, ...getInitParams(), ...replaceParams})
            }else{
                let customizeInitParams
                try{
                    customizeInitParams = customizeInitParamsRes === '' ? {} :JSON.parse(customizeInitParamsRes)
                }catch (e){
                    console.error(e)
                    customizeInitParams = {}
                }
                const mustSelectParams = {}
                _.forEach(mustSelectList, x => mustSelectParams[x] = null)
                const keyList = _.map(customizeInitParams, (v,k) => k)
                const activeKeyList = _.union(mustSelectList, keyList)
                setActiveOptions(_.filter(filterOptions, x => _.includes(activeKeyList,x.bind) ))
                setParams({...mustSelectParams,...getInitParams(),...customizeInitParams})
                search({...mustSelectParams,...getInitParams(),...customizeInitParams, ...replaceParams})
            }
        }
        setIsInited(true)
    },[customizeInitParamsRes,filterOptions,search,getInitParams,replaceParams,mustSelectList,defaultSelectList,replaceAllParams,resetSearch, isInited])

    useEffect(()=>{
        if(_.isNil(funcCode)) return
        getCustomizeInitParams('/UserSetting/getViews?'+strParams({funcCode:funcCode,type: '01'}))
    },[funcCode,getCustomizeInitParams])

    const resetAllOptions = useCallback(()=>{
        const initParams = {
            pageNum: 1,
        }
        _.map(activeOptions, x => initParams[x.bind] = null)
        return {...initParams,...replaceParams}
    },[activeOptions,replaceParams])

    const notAddCondition = useMemo(()=>{
        const allNotAdd = _.filter(filterOptions, allItem => {
            return _.isNil(_.find(activeOptions, activeItem => activeItem.bind === allItem.bind))
        })
        if(_.isNil(searchNotAddText) || searchNotAddText === ''){
            return allNotAdd
        }else{
            return _.filter(allNotAdd, x => _.includes(x.label, searchNotAddText))
        }
    },[filterOptions, activeOptions, searchNotAddText])

    useImperativeHandle(ref, () => ({
        resetParams:()=>{
            search(oldParams => {
                const searchParams = {...resetAllOptions(), pageSize: _.get(oldParams, 'pageSize')}
                setParams(searchParams)
                onSearch(searchParams)
                return searchParams
            })
        },
        refreshParams: ()=>{
            search({
                ...params,
                pageNum: 1,
                pageSize: pageSize || _.get(params, 'pageSize'),
                ...replaceParams
            })
            onSearch({
                ...params,
                pageNum: 1,
                pageSize: pageSize || _.get(params, 'pageSize'),
                ...replaceParams
            })
        }
    }))

    const addCondition = useMemo(()=>{
        return <div className='add-condition-list-wrap'>
            {
                _.map(notAddCondition, listItem => (<div className={'add-condition-list-item flex center-y'} key={uniqKeyFor()}
                                                         onClick={()=>setActiveOptions(x => {
                                                             setSearchNotAddText(null)
                                                             setShowAddPop(false)
                                                             setParams(oldParams => {
                                                                 const newParams = oldParams || {}
                                                                 newParams[listItem.bind] = null
                                                                 return newParams
                                                             })
                                                             return _.concat(x,[listItem])
                                                         })}>
                    {listItem.label}
                </div>))
            }
            {
                _.isEmpty(notAddCondition) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </div>
    },[notAddCondition])

    const addConditionSearch = useMemo(()=>{
        return <Input placeholder="搜索过滤条件" value={searchNotAddText} onChange={setSearchNotAddText} suffix={<Icon name={'sousuo1'}/>}/>
    },[searchNotAddText])

    const onPopChange = useCallback((visible)=>{
        if(visible){
            setShowAddPop(true)
        }else{
            setSearchNotAddText(null)
            setShowAddPop(false)
        }
    },[])

    const isIncludeBaseOptions = useCallback((currentConfig) => {
        const currentBind = _.get(currentConfig,'bind')
        return !_.isNil(_.find(baseOptions, x => _.get(x, 'bind') === currentBind))
    },[baseOptions])

    const closeFormInput = useCallback((currentConfig)=>{
        const currentBind = _.get(currentConfig,'bind')
        setActiveOptions(activeOptions => {
            const newOptions = _.filter(activeOptions, x  => _.get(x, 'bind') !== currentBind)
            setParams(oldParams => {
                return _.omit(oldParams, currentBind)
            })
            return ([...newOptions])
        })
    },[])

    return <div className='customize-options'>
        <div className="c-options">
            <Form value={params} onChange={setParams}>
                {
                    _.map(activeOptions, config => {
                        return <div className='single-form-input' key={`${config.bind}`}>
                            <CustomizeFormInput {...config} clear search convertCollection={convertCollection} isQuery={true} defaultValue={_.get(params,config.bind)}/>
                            <Icon name={'quxiao'} className={'close-form-input-icon'} style={{display: isIncludeBaseOptions(config) || _.includes(mustSelectList, config.bind) ? 'none' : ''}} onClick={()=>closeFormInput(config)}/>
                        </div>
                    })
                }
            </Form>
            <Popover content={addCondition} title={addConditionSearch} trigger="click" placement="bottom" open={showAddPop} overlayClassName={'add-condition-pop'}
                     onOpenChange={onPopChange}>
                {
                    activeOptions.length !== filterOptions.length && <Icon name={'tianjia'} className='add-options-icon' onClick={() => setShowAddPop(x => !x)}/>
                }
            </Popover>
            <div className="search-btn-group flex">
                <Button className={'search-btn'} primary onClick={() => {
                    if(loading) return
                    search({
                        ...params,
                        pageNum: 1,
                        pageSize: pageSize || _.get(params, 'pageSize'),
                        ...replaceParams
                    })
                    onSearch({
                        ...params,
                        pageNum: 1,
                        pageSize: pageSize || _.get(params, 'pageSize'),
                        ...replaceParams
                    })
                }}>查询</Button>
                <Button className={'reset-btn'} text onClick={() => {
                    if(loading) return
                    search(oldParams => {
                        const searchParams = {...resetAllOptions(), pageSize: _.get(oldParams, 'pageSize')}
                        setParams(searchParams)
                        onSearch(searchParams)
                        return searchParams
                    })
                }}>重置</Button>
                <Badge count={advancedQueryNum} size="small" offset={[-8, 8]}>
                    <Button className={'reset-btn'} text onClick={()=>setIsShowAdvancedQuery(true)}>高级查询</Button>
                </Badge>
                {
                    _.isNil(extraBtnArea) ? '' : extraBtnArea
                }
            </div>
        </div>
        {
            isShowAdvancedQuery &&
                <ViewAdvancedQuery close={()=>setIsShowAdvancedQuery(false)} {...{allColumns, funcCode, afterUpdatedRefresh}}/>
        }
    </div>
}

export function CustomizeFormInput(props){
    const {fieldHtmlType,convertCollection,bind, viewConditionType, customOptions, isQuery, fieldHtmlDetailType, defaultValue, accuracy} = props
    if(viewConditionType === "personnel"){
        if(isQuery){
            return <FormInput {...props} componentStyle={{...props.style,overflowY: 'auto', height: 32}} component={UserSelect} multiple bindInConvert={v => !!v ?_.split(v,','):[]} bindOutConvert={v => (v || []).join(',')}/>
        }
        if(fieldHtmlType === '3'){
            return <FormInput {...props} component={UserSelect} multiple bindInConvert={v => !!v ?_.split(v,','):[]} bindOutConvert={v => (v || []).join(',')}/>
        }else if(fieldHtmlType === '4'){
            return <FormInput {...props} component={UserSelect}/>
        }
    }
    if(fieldHtmlType === '5' && isQuery){
        // return <FormInput {...props} component={DatePicker} bindInConvert={v => dateStringToDate(convertInTime(v))} bindOutConvert={v => convertOutTime(dateFormat('YYYY-MM-DD HH:MM:SS',v))}/>
        // return <FormInput {...props} component={DatePicker} range componentWidth={210} bindInConvert={convertInRangeTime} bindOutConvert={convertOutRangeTime}/>
        return <FormInput {...props} component={DateSelector} componentWidth={250} bindInConvert={convertInRangeTime} bindOutConvert={convertOutRangeTime}/>
    }else if(fieldHtmlType === '5'  && !isQuery){
        return <FormInput {...props} component={DatePicker} bindInConvert={v => dateStringToDate(convertInTime(v))} bindOutConvert={v => convertOutTime(dateFormat('YYYY-MM-DD HH:MM:SS',v))}/>
    }else if(fieldHtmlType === '7'  && !isQuery){
        const options = _.isEmpty(defaultValue) ? _.get(convertCollection,bind) : extendOptions([defaultValue],_.get(convertCollection,bind))
        const displayOptions = _.filter(options, x => _.get(x,'displayFlag') !== 'N')
        return <FormInput {...props} component={CascadeSelect} options={customOptions || displayOptions || []} search/>
    }else if(fieldHtmlType === '8' || (fieldHtmlType === '7' && isQuery)){
        const options = _.isEmpty(defaultValue) ? _.get(convertCollection,bind) : extendOptions(_.isArray(defaultValue)?defaultValue:_.split(defaultValue,/,|\^/),_.get(convertCollection,bind))
        const displayOptions = _.filter(options, x => _.get(x,'displayFlag') !== 'N')
        return <FormInput {...props} component={CascadeSelect} options={customOptions || displayOptions || []} search multiple
                          bindInConvert={v => !!v ?_.split(v,/,|\^/):[]} bindOutConvert={v => (v || []).join(',')}/>
    }else if(fieldHtmlType === '4' && !isQuery){
        const options = _.isEmpty(defaultValue) ? _.get(convertCollection,bind) : extendOptions([defaultValue],_.get(convertCollection,bind))
        const displayOptions = _.filter(options, x => _.get(x,'displayFlag') !== 'N')
        return <FormInput {...props} component={Select} options={customOptions || displayOptions || []} search/>
    }else if(fieldHtmlType === '3' || (fieldHtmlType === '4' && isQuery)){
        const options = _.isEmpty(defaultValue) ? _.get(convertCollection,bind) : extendOptions(_.isArray(defaultValue)?defaultValue:_.split(defaultValue,/,|\^/),_.get(convertCollection,bind))
        const displayOptions = _.filter(options, x => _.get(x,'displayFlag') !== 'N')
        return <FormInput {...props} component={Select} options={customOptions || displayOptions || []} multiple search bindInConvert={v => _.split(v,/,|\^/)} bindOutConvert={v => (v || []).join(',')}/>
    }else if(fieldHtmlType === '9'){
        const options = _.isEmpty(defaultValue) ? _.get(convertCollection,bind) : extendOptions([defaultValue],_.get(convertCollection,bind))
        const displayOptions = _.filter(options, x => _.get(x,'displayFlag') !== 'N')
        const firstLevelList = _.filter(displayOptions, x => isNil(x.pid))
        const treeOptions = handleTreeOptions(displayOptions, firstLevelList, 'value', 'pid', 'text')
        return <FormInput {...props} component={Select} options={treeOptions|| []} search tree/>
    }else if(fieldHtmlType === '2' && !isQuery){
        return <FormInput {...props} component={TextAreaInput}/>
    }else{
        let digit = null
        if(fieldHtmlDetailType === '02'){
            digit = 0
        }
        if(!isNil(accuracy) && fieldHtmlDetailType === '03'){
            digit = _.toNumber(accuracy)
        }
        return <FormInput {...props} type={_.includes(['02','03'],fieldHtmlDetailType)?'number':null} digit={digit}/>
    }

    function extendOptions(values, options){
        if(_.isEmpty(values)) return options
        const valueList = _.map(options, 'value')
        if(_.some(valueList, x => _.includes(x,',') || _.includes(x,'^'))) return options
        const notIncludeValues = _.filter(values, x => !_.includes(valueList, x))
        const notIncludeList = _.map(notIncludeValues, x => ({value: x, text: x}))
        return _.concat(notIncludeList,options? options :[])
    }

    function convertInTime(v){
        return isNil(v)? null: _.replace(v, /'.0'/g,'')
    }

    function convertOutTime(v){
        return isNil(v)? null: v + '.0'
    }

    function convertInRangeTime(v){
        if(isNil(v)) return null
        const obj = JSON.parse(v)
        return {
            begin: dateStringToDate(convertInTime(_.get(obj, 'begin'))),
            end: dateStringToDate(convertInTime(_.get(obj, 'end'))),
        }
    }

    function convertOutRangeTime(v){
        if(isNil(v)) return null
        return JSON.stringify(
            // {
            //     begin: convertOutTime(dateFormat('YYYY-MM-DD HH:MM:SS',_.get(v, 'begin'))),
            //     end: convertOutTime(dateFormat('YYYY-MM-DD HH:MM:SS',getLastDate(_.get(v, 'end')))),
            // }
            {
                begin: _.get(v, 'begin') + ' 00:00:00.0',
                end: _.get(v, 'end') + ' 23:59:59.9',
            }
        )
    }

    // function getLastDate(date){
    //     if(_.isNil(date)) return null
    //     return new Date(new Date(date.toLocaleDateString()).getTime()+24*60*60*1000-1)
    // }

}

export default forwardRef(CustomizeOptions);
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ResourceInfoCard.scss'
import {Card, Button} from 'rootnet-ui'
import _ from 'lodash'
import moment from 'moment'
import {Icon} from "../../../../components";
import {Tooltip, Radio, Popover} from "antd";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import UserInfoCard from "./userInfoCard/UserInfoCard";
import ResourcePlan from "./resourcePlan/ResourcePlan";
import { BaseTable } from 'ali-react-table'
import {uniqKeyFor} from "../../../../project_share/utils/utils";

const saturationOptions = [
    { label: '全部', value: 'all' },
    { label: '超饱和', value: 'overSaturation' },
    { label: '不饱和', value: 'underSaturation' },
]

function getColumn(props){
    const {showDateList, onFoldIconClick, foldList, allKeyList, setFoldList, searchUserTask,foldUserList, restDays,
        onTaskItemClick, onPersonDateClick,searchUserListTask,initAllUser,setFoldUserList} = props

    const infoCols = [
        {code: 'infoCol', name: <div className={'info-col-header flex center-y'}>
                <Tooltip title={_.isEmpty(foldList)?'全部收起':'全部展开'}>
                    <span>
                        <Icon name={_.isEmpty(foldList)?'biaogeshouqi':'biaogezhankai'} className={'all-expand-icon'}
                              onClick={()=>{
                                  if(_.isEmpty(foldList)){
                                      setFoldList(allKeyList)
                                      setFoldUserList(initAllUser)
                                  }else{
                                      setFoldList([])
                                      setFoldUserList([])
                                      searchUserListTask(initAllUser)
                                  }
                                  // setFoldList(_.isEmpty(foldList) ? allKeyList: [])
                              }}/>
                    </span>
                </Tooltip>
                岗位/模块/人员
            </div>,width: 280,lock: true, render: (v, o) => {
        const key = _.get(o,'_key')
        const isUserRow = o._level === 2
        const isFold = (!isUserRow && _.includes(foldList,key)) || (isUserRow && _.includes(foldUserList,_.get(o,'userAccount')))
        return <div className={'info-col-item flex center-y'} style={{marginLeft: 16 * o._level + 12}}>
            {
                [0,1,2].includes(o._level) &&
                <Icon name={isFold?'biaogezhankai':'biaogeshouqi'} className={'expand-icon'} onClick={()=> {
                    if(isUserRow){
                        searchUserTask(o)
                    }
                    onFoldIconClick(key, isUserRow, _.get(o,'userAccount'))
                }}/>
            }
            {
                [0,1].includes(o._level) && <span>{v}</span>
            }
            {
                o._level === 3 && <div className={'task-name-in-info-col-wrap flex center-y'}>
                    {
                        _.get(o,'projectInclude') &&
                        <Tooltip title={'属于项目'}>
                            <span className={'linked-icon'}>
                                <Icon name={'guanlian'}/>
                            </span>
                        </Tooltip>
                    }
                    {
                        !_.get(o,'projectInclude') &&
                        <span className={'none-linked-icon'}/>
                    }
                    <Tooltip title={v}>
                        <span className={'task-name-in-info-col flex center-y'}>
                            {v}
                        </span>
                    </Tooltip>
                </div>
            }
            {
                o._level === 2 &&
                <Popover content={<UserInfoCard user={o.userAccount}/>} trigger="click" placement={'right'} zIndex={10}
                         >
                    <span className={'user-name-item'}>{v}</span>
                </Popover>
            }

        </div>
    }},
    {code: 'saturation', name: '饱和度',width: 80,lock: true, align: 'center',render: v => {
        let backgroundColor
        if(v > 1){
            backgroundColor = '#FF0000'
        }else if(v <= 0.6){
            backgroundColor = '#ED7D31'
        }else{
            backgroundColor = '#70AD47'
        }
        return _.isNil(v)?null:<div className={'saturation-item flex center'} style={{backgroundColor: backgroundColor}}>
            {_.round(v * 100,1) + "%"}
        </div>
    } },
        {code: 'toPlan', name: '可计划(h)',width: 80,lock: true, align: 'center' },
    ]

    const groupedDates = _.groupBy(showDateList, x => moment(x).format("gggg年M月"))
    const dateCols = _.map(groupedDates, (dateList, month) => ({
        name: month,
        children: _.map(dateList, date => {
            const day = moment(date).format("d")
            const isRestDay = _.includes(restDays,date)
            return ({
                name: <div className={'date-header-item flex-y center-y'}>
                    <div>{moment(date).format("DD")}</div>
                    <div style={{marginLeft: 1}}>
                        {convertDateToWeek(day)}
                    </div>
                </div>,
                code: date,
                width: _.size(dateList) === 1 ? 84 :42,
                align: 'center',
                render: (v, o) => {
                    if(o._level === 3){
                        if(v){
                            return <div className={`task-item ${isRestDay && "rest-day"} ${v?'has-task-item':''} cell-full-item`}
                                        onClick={()=>onTaskItemClick(o,date)}/>
                        }else{
                            return <div className={`task-item ${isRestDay && "rest-day"} cell-full-item`}
                                        onClick={()=>onTaskItemClick(o,date)}/>
                        }
                    }

                    let valueColor
                    if(o._level === 2){
                        if(v > 8){
                            valueColor = 'red'
                        }else if(v < 5){
                            valueColor = '#70AD47'
                        }
                    }

                    if(o._level === 2){
                        return <div className={`date-item flex center ${isRestDay && "rest-day"} person-row`} style={{color: valueColor}}
                                onClick={()=>onPersonDateClick(o,date)}>
                            {v?_.round(v,1):null}
                        </div>
                    }

                    return <div className={`date-item flex center ${isRestDay && "rest-day"}`} style={{color: valueColor}}>
                        {v?_.round(v,1):null}
                    </div>
                }
            })
        })
    }))

    return _.concat([],infoCols,dateCols)
}

function ResourceInfoCard(props) {
    const {formData, positionOptions,selectedDeptId, selectedProjectId,setForceUpdateSummary} = props
    const [foldList, setFoldList] = useState([])
    const [foldUserList, setFoldUserList] = useState([])
    const [userTaskResultList, setUserTaskResultList] = useState([])
    const [saturationFilter, setSaturationFilter] = useState('all')
    const [planInfo, setPlanInfo] = useState()
    const {data: groupList, doFetch: getList, loading, error} = usePost()
    const {data: restDaysRes} = useGet('/kanban/dateList')
    const {doFetch: getUserTask} = usePost()

    const sortedGroupList = useMemo(()=>{
        if(_.isEmpty(groupList)) return []
        return _.sortBy(groupList,[sortPosition])
        function sortPosition(o){
            const positionList = _.map(positionOptions,'value')
            const findIndex = _.findIndex(positionList,x => x === _.get(o,'position'))
            return findIndex === -1 ? 9999 : findIndex
        }
    },[positionOptions, groupList])

    const restDays = useMemo(()=>{
        if(_.isNil(restDaysRes)) return []
        return _.map(_.filter(restDaysRes, x => x.flag === 'Y'),'jobdate')
    },[restDaysRes])

    const refreshTable = useCallback((resetFoldUser = true)=>{
        if(_.isNil(formData)) return
        if(_.isNil(_.get(formData,'department'))) return
        const searchParams = {
            ...formData
        }
        if(!_.isNil(selectedProjectId)){
            searchParams['relatedType'] = 'PRJ'
            searchParams['relatedTask'] = selectedProjectId
        }
        getList('/userPlan/list',searchParams).then(res => {
            if(resetFoldUser){
                let initAllUser = []
                _.forEach(res, positionItem => {
                    _.forEach(_.get(positionItem,'moduleList'), moduleItem =>{
                        _.forEach(_.get(moduleItem,'userList'), userItem => {
                            initAllUser.push(userItem.userAccount)
                        })
                    })
                })
                setFoldUserList(initAllUser)
            }
        })
    },[formData, getList,selectedProjectId])

    useEffect(()=>{
        refreshTable()
    },[refreshTable])

    const showDateList = useMemo(()=>{
        return getEveryDay(formData.firstDate, formData.lastDate)
    },[formData])

    const searchUserListTask = useCallback((userAccountList)=>{
        const searchParams = {
            userAccountList: userAccountList,
            firstDate: _.get(formData,'firstDate'),
            lastDate: _.get(formData,'lastDate'),
            module: _.get(formData,'module'),
            projectToStory: selectedProjectId,
        }
        getUserTask('/userPlan/related',searchParams).then(res => {
            const cloneList = _.clone(res)
            const groupByUserAccount = _.groupBy(cloneList,'userAccount')
            const appendList = []
            _.forEach(groupByUserAccount, (userTaskList,userAccount) => {
                const groupByTaskId = _.groupBy(userTaskList,'relatedTask')
                const currentUserResult = _.map(groupByTaskId, (taskList, taskId) => {
                    let timeLists = _.map(taskList, item => {
                        const everyDay = getEveryDay(item.firstDate, item.lastDate)
                        // if(item.haveFestivo === "N"){
                        //     return _.filter(everyDay, x => !_.includes(restDays, x))
                        // }else{
                        //     return everyDay
                        // }
                        return everyDay
                    }) || []
                    let timeList = _.uniq(_.concat(...timeLists))
                    return ({
                        userAccount:userAccount,
                        relatedTask:_.get(taskList,'0.relatedTask'),
                        taskTimeList:timeList,
                        storyId: _.get(taskList,'0.storyId'),
                        storyName: _.get(taskList,'0.storyName'),
                        relatedType: _.get(taskList,'0.relatedType'),
                        projectName: _.get(taskList,'0.projectName'),
                        projectInclude: _.get(taskList,'0.projectInclude'),
                    })
                })
                appendList.push(currentUserResult)
            })
            setUserTaskResultList(oldList => {
                const filterList = _.filter(oldList, x => !_.includes(userAccountList,x.userAccount))
                return _.concat(filterList, ...appendList)
            })
        })
    },[formData, getUserTask, selectedProjectId])

    const searchUserTask = useCallback((item)=>{
        const {userAccount} = item
        // if(_.includes(searchedUserList,userAccount)) return
        searchUserListTask([userAccount])
    },[searchUserListTask])

    const initAllUser = useMemo(()=>{
        let initAllUser = []
        _.forEach(sortedGroupList, positionItem => {
            _.forEach(_.get(positionItem,'moduleList'), moduleItem =>{
                _.forEach(_.get(moduleItem,'userList'), userItem => {
                    initAllUser.push(userItem.userAccount)
                })
            })
        })
        return initAllUser
    },[sortedGroupList])

    const afterPlanUpdate = useCallback(()=>{
        refreshTable(false)
        const currentExpandUserList = _.filter(initAllUser, x => !_.includes(foldUserList,x))
        if(_.isEmpty(currentExpandUserList)) return
        searchUserListTask(currentExpandUserList)
        if(setForceUpdateSummary){
            setForceUpdateSummary(x => x + 1)
        }
    },[refreshTable,initAllUser,foldUserList,searchUserListTask,setForceUpdateSummary])

    const showList = useMemo(()=>{
        let showList = []
        _.forEach(sortedGroupList, positionItem => {
            showList.push({
                infoCol: positionItem.positionName,
                position: positionItem.position,
                _level: 0,
                _key: `position-${positionItem.position}`,
                _parentKeyList: [],
            })
            // if(_.includes(foldList, `position-${positionItem.position}`)) return
            _.forEach(_.get(positionItem,'moduleList'), moduleItem => {
                const timeObj = {}
                _.forEach(showDateList, time => {
                    let saturationFilterUserList
                    if(saturationFilter === 'overSaturation'){
                        saturationFilterUserList = _.filter(_.get(moduleItem,'userList'), x => _.get(x,'saturation') > 1)
                    }else if(saturationFilter === 'underSaturation'){
                        saturationFilterUserList = _.filter(_.get(moduleItem,'userList'), x => _.get(x,'saturation') <= 0.6)
                    }else{
                        saturationFilterUserList = _.get(moduleItem,'userList')
                    }
                    const timeList = _.map(saturationFilterUserList,time)
                    const roundTimeList = _.map(timeList, v => v ? _.round(v,1):v)
                    timeObj[time] = _.toNumber(_.sum(roundTimeList)?.toFixed(1)) || null
                })
                const userToPlan = _.map(_.get(moduleItem,'userList'),'toPlan')
                showList.push({
                    infoCol: moduleItem.moduleName,
                    _level: 1,
                    toPlan: _.round(_.sum(userToPlan),1),
                    _key: `position-${positionItem.position}-module-${moduleItem.module}`,
                    _parentKeyList: [`position-${positionItem.position}`],
                    ...timeObj,
                })
                // if(_.includes(foldList, `position-${positionItem.position}-module-${moduleItem.module}`)) return
                _.forEach(_.get(moduleItem,'userList'), userItem => {
                    if(saturationFilter === 'overSaturation' && userItem.saturation < 1) return
                    if(saturationFilter === 'underSaturation' && userItem.saturation > 0.6) return
                    showList.push({
                        infoCol: userItem.userAccountName,
                        userAccount: userItem.userAccount,
                        _level: 2,
                        _key: `position-${positionItem.position}-module-${moduleItem.module}-user-${userItem.userAccount}`,
                        _parentKeyList: [`position-${positionItem.position}`,`position-${positionItem.position}-module-${moduleItem.module}`],
                        ...userItem
                    })
                    const taskList = _.filter(userTaskResultList, x => x.userAccount === userItem.userAccount)
                    if(_.includes(foldUserList,userItem.userAccount)) return
                    const sortedTaskList = _.sortBy(taskList,x => !_.get(x,'projectInclude'))
                    _.forEach(sortedTaskList, taskItem => {
                        const taskTimeList = {}
                        _.forEach(taskItem.taskTimeList, x => {
                            taskTimeList[x] = true
                        })
                        showList.push({
                            infoCol: taskItem.relatedType === 'REQ'?`${taskItem.storyId}-${taskItem.storyName}`:taskItem.projectName,
                            userAccount: taskItem.userAccount,
                            relatedType: taskItem.relatedType,
                            relatedTask: taskItem.relatedTask,
                            storyId: taskItem.storyId,
                            storyName: taskItem.storyName,
                            projectName: taskItem.projectName,
                            projectInclude: taskItem.projectInclude,
                            _level: 3,
                            _parentKeyList: [`position-${positionItem.position}`,`position-${positionItem.position}-module-${moduleItem.module}`],
                            ...taskTimeList
                        })
                    })
                })
            })
        })
        _.forEach(showList, item => {
            if(item._level === 0){
                const childrenList = _.filter(showList, x => (x._level === 1 && _.includes(x._parentKeyList,item._key)))
                item['toPlan'] = _.round(_.sum(_.map(childrenList,'toPlan')),1)
                _.forEach(showDateList, date => {
                    item[date] = _.round(_.sum(_.map(childrenList,x => _.get(x, date))),1)
                })
            }
        })
        const filterList = _.filter(showList, item => !_.some(foldList, x => _.includes(item._parentKeyList,x)))
        return filterList
    },[sortedGroupList, foldList, showDateList, userTaskResultList, foldUserList, saturationFilter])

    const allKeyList = useMemo(()=>{
        const allKeyList = []
        _.forEach(showList, item => {
            if(item._level === 2) return
            allKeyList.push(item._key)
        })
        return allKeyList
    },[showList])

    const onFoldIconClick = useCallback((key, isUserRow, userAccount)=>{
        if(isUserRow){
            setFoldUserList(oldList => {
                if(_.includes(oldList,userAccount)){
                    return _.filter(oldList, x => x !== userAccount)
                }else{
                    return _.concat(oldList, [userAccount])
                }
            })
        }else{
            setFoldList(oldList => {
                if(_.includes(oldList,key)){
                    return _.filter(oldList, x => x !== key)
                }else{
                    return _.concat(oldList, [key])
                }
            })
        }
    },[])

    const onTaskItemClick = useCallback((taskItem, date) => {
        setPlanInfo({
            mode: 'edit',
            searchInfo:{
                userAccountList: [taskItem.userAccount],
                firstDate: date,
                lastDate: date,
                relatedTask: taskItem.relatedTask
            },
            initInfo: {
                initRelatedType: taskItem.relatedType,
                initReqId: taskItem.relatedType === 'REQ'?taskItem.relatedTask:null,
                initProjectId: taskItem.relatedType === 'PRJ'?taskItem.relatedTask:null,
            }
        })
    },[])

    const onPersonDateClick = useCallback((item, date)=>{
        setPlanInfo({
            mode: 'add',
            searchInfo:{
                userAccountList: [item.userAccount],
            },
            initInfo: {
                initList: [
                    {
                        uniqId: uniqKeyFor(),
                        timeType: '0',
                        userAccount: item.userAccount,
                        firstDate: date,
                        lastDate: date,
                    }
                ]
            }
        })
    },[])

    const tableOption = useMemo(()=>{
       return getColumn({showDateList, onFoldIconClick, foldList, allKeyList, setFoldList,searchUserTask,
           foldUserList, restDays, onTaskItemClick, onPersonDateClick,searchUserListTask,initAllUser,setFoldUserList})
    },[showDateList, onFoldIconClick, foldList, allKeyList,searchUserTask,foldUserList,restDays, onTaskItemClick, onPersonDateClick,searchUserListTask,initAllUser])

    const extra = useMemo(()=>{
        const isProjectAdd = !_.isNil(selectedProjectId)
        return <div className={'extra-wrap flex center-y'}>
            <Radio.Group options={saturationOptions} onChange={ e => setSaturationFilter(e.target.value)} value={saturationFilter} optionType="button" buttonStyle="solid"/>
            <Button primary className={'extra-plan-btn'} onClick={()=>setPlanInfo({
                mode:'add',
                initInfo: {
                    initRelatedType: isProjectAdd?'PRJ':'REQ',
                    initProjectId: isProjectAdd?selectedProjectId:null,
                }
            })}>添加计划</Button>
        </div>
    },[saturationFilter, selectedProjectId])

    return <div className={'resource-info-card flex'}>
        <Card title={'详细信息'} className={'outer-card'} contentClass={'outer-card-content flex'} extra={extra} loading={loading} error={error}>
            <BaseTable dataSource={showList} columns={tableOption}
                       style={{
                           '--row-height': "34px",
                           '--header-bgcolor': '#F5F5F5',
                           '--header-color': '#252525'
                       }}
            />
        </Card>
        {
            planInfo &&
            <ResourcePlan close={()=>setPlanInfo(null)} {...{planInfo, afterPlanUpdate,selectedDeptId}}/>
        }
    </div>
}

function getEveryDay(start, end) {
    let dateList = [];
    let startTime = getDate(start);
    let endTime = getDate(end);

    while ((endTime.getTime() - startTime.getTime()) >= 0) {
        let year = startTime.getFullYear();
        let month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1;
        let day = startTime.getDate().toString().length === 1 ? "0" + startTime.getDate() : startTime.getDate();
        dateList.push(year + "-" + month + "-" + day);
        startTime.setDate(startTime.getDate() + 1);
    }
    return dateList;
}


function getDate(dateStr) {
    let temp = dateStr.split("-");
    let date = new Date(temp[0], temp[1] - 1, temp[2]);
    return date;
}

function convertDateToWeek(value){
    switch (value){
        case '1' : return "一";
        case '2' : return "二";
        case '3' : return "三";
        case '4' : return "四";
        case '5' : return "五";
        case '6' : return "六";
        case '0' : return "日";
        default: return "未知"
    }
}

export default ResourceInfoCard;
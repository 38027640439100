import React, { useState } from 'react'
import LeftMenu from './detailControls/leftMenu';
import RightMenu from './detailControls/rightMenu';
import { parseParams } from './../../../utils/publicFun';
import './WorkTimeDetail.scss';

const url = window.location.href;
let par = url.split('?')[1] || '';
par = parseParams(par);

function WorkTimeDetail() {
    const [sonData, setSonData] = useState(null);
    const [childData, setChildData] = useState(null);

	function menuClick(son, child){
		setSonData(son)
		setChildData(child)
	}

	return <div className='workTimeDetail'>
		<LeftMenu onClick={menuClick} {...par} />
		<RightMenu sonData={sonData} {...par} childData={childData} />
	</div>
}


export default WorkTimeDetail

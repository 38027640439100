import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { Input, Button } from 'antd'
import { FormInput as RootnetInput, Form } from 'rootnet-edit'
import { isNil } from 'rootnet-core/format'
import { post, get } from '../../utils/request'
import { strParams } from '../../utils/publicFun'
import { errorTips } from '../../utils/messagerTips'
import InputPassWord from "./InputPassWord";

const SECURITY_CODE_URL = '/userInfoForget/getGenerateVerificationCode' //获取验证码
const VERIFICATION_CODE_URL = '/userInfoForget/verification' //校验验证码
const CHANGE_PASSWORD_URL = '/userInfoForget/updateUserInfo'//修改密码

const mail = '@croot.com'
const retrievalMethod = [
  { id: 0, title: '邮箱找回' },
  { id: 1, title: '企业微信找回' },
]

export function SelectionMethod(props) {
  const { next, setAction } = props

  const nextStep = useCallback((id) => {
    next()
    setAction(id)
  }, [next, setAction])

  return <div className='selection-method'>
    {
      _.map(retrievalMethod, item => {
        return (
          <div className='method' key={item.id}>
            <div>
              <div></div>
              <div>{item.title}</div>
            </div>
            <div><Button onClick={() => nextStep(item.id)}>立即验证</Button></div>
          </div>
        )
      })
    }
  </div>
}

export function Verification(props) {
  const { next, prev, action, user, setUser } = props
  const [obtain, setObtain] = useState(false)
  const [num, setNum] = useState()
  const [securityCode, setSecurityCode] = useState('')

  const handClick = () => {
    if (!isNil(user)) {
      setObtain(true)
      const type = action ? '' : `&mail=${user}${mail}`
      get(`${SECURITY_CODE_URL}?userId=${user}&verificationCodeType=${action}${type}`)
        .then(res => { })
        .catch(err => { })
    } else errorTips('请先输入用户名')
  }

  const countDown = useCallback((number = 60) => {
    if (number > 1) {
      setNum(number--)
      setTimeout(() => countDown(number), 1000)
    } else setObtain(false)
    return number
  }, [])

  const nextStep = useCallback(() => {
    const reg = /^[0-9]{6}$/
    if (reg.test(securityCode)) {
      get(`${VERIFICATION_CODE_URL}?${strParams({ verificationCode: securityCode, userId: user, verificationCodeType: action + '' })}`)
        .then(res => {
          const { code, msg } = res
          if (code === 0) { next() }
          else errorTips(msg)
        })
        .catch(err => { })
    } else errorTips('请输入正确验证码')
  }, [action, next, securityCode, user])

  useEffect(() => {
    if (obtain) countDown()
  }, [countDown, obtain])

  return <div className='verification'>

    <div className='header'>您正在使用 <span>“{action ? '企业微信' : '邮箱'}”</span> 验证身份，请验证身份，请收邮件重置登录密码 </div>

    <div className='content'>
      <div>
        <Input
          placeholder='请输入用户名'
          value={user}
          onChange={(e) => setUser(e.target.value)}
          suffix={action ? '' : mail}
        />
      </div>
      <div>
        <Input
          placeholder='请输入验证码'
          onChange={(e) => setSecurityCode(e.target.value)}
          addonAfter={obtain ? `${num}秒后重新获取` : <span onClick={handClick}>点击获取</span>}
        />
      </div>
      <div>
        <Button
          onClick={nextStep}
          className={clsx({ 'btn-disabled': (_.size(user) >= 0 && _.size(securityCode) === 0) })}
          disabled={(_.size(user) >= 0 && _.size(securityCode) === 0)}
        >
          下一步
        </Button>
      </div>

    </div>

    <div className='footer'><span onClick={prev}>重新选择验证方式</span></div>

  </div>
}

const passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z~!@#$%^&*()_+<>?:"{},./|;'[\]]{6,12}$/

export function ResetPassword(props) {
  const { next, action, user } = props
  const [params, setParams] = useState({})

  const nextStep = useCallback(() => {
    const { newPassword, verifyPassword } = params
    if (newPassword === verifyPassword) {
      if (!passwordReg.test(verifyPassword)) return
      post(CHANGE_PASSWORD_URL, { userId: user, password: verifyPassword, verificationCodeType: action + '' })
        .then((res) => {
          const { code } = res
          if (code === 0) next()
          else { console.log(res) }
        })
        .catch(() => { })
    } else errorTips('密码不一致')

  }, [action, next, params, user])

  return <div className='reset-password'>
    <Form onChange={setParams} value={params}>
      <div><RootnetInput component={InputPassWord} bind='newPassword' placeholder='请输入您的新密码' /></div>
      <div><RootnetInput component={InputPassWord} bind='verifyPassword' placeholder='请再次输入新密码' /></div>
    </Form>
    <div><Button onClick={nextStep}>立即重置</Button></div>
  </div>
}

export function ResetSuccess(props) {
  const { loginPage } = props

  const nextStep = useCallback(() => {
    loginPage()
  }, [loginPage])

  return <div className='reset-success'>
    <div>
      <span>
        <span></span>
        <span>密码重置成功！</span>
      </span>
    </div>
    <div><Button onClick={nextStep}>返回登录</Button></div>
  </div>
}
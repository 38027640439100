import React from 'react';
import {Dialog} from 'rootnet-ui'
import ConferenceRecordsDetailContent from "./ConferenceRecordsDetailContent";
import './ConferenceRecordsDetailDialog.scss'

function ConferenceRecordsDetailDialog(props) {
    const {refreshViewList = ()=>{}, close = ()=>{}} = props

    return <Dialog className={'conference-records-detail-dialog'} headerVisible={false} footerVisible={false}>
        <ConferenceRecordsDetailContent  {...props} {...{refreshViewList,close}}/>
    </Dialog>
}

export default ConferenceRecordsDetailDialog;
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { Messager } from 'rootnet-ui'
import { DatePicker, Display, Form, FormInput, Input, Select } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { useGet } from 'rootnet-biz/lib/hooks'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../common/ConvertOptions'
import { uniqKeyFor } from '../../../../project_share/utils/utils'
import { useFuncCode } from '../../../common/commonMethod'
import UserSelect from '../../../common/personSelectPop/UserSelect'
import VersionSelect from '../../../requirementMgt/applyJoinVersionDialog/VersionSelect'
import ApplyJoinVersionDialog from '../../../requirementMgt/applyJoinVersionDialog/ApplyJoinVersionDialog'
import { Icon } from '../../../../components'
import DisplaySelect from '../../../common/displaySelect/DisplaySelect'
import { strParams } from '../../../../utils/publicFun'
import './index.scss'
import ClosurePlanMessage from '../../../common/closurePlanMessage'

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=AUDITTYPE',
  '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=R,L',
]

const OPTIONS_URLS = [
  '/develop/release/list?stateList=T&versionType=0',
  '/project/leafList',
  '/develop/release/list?stateList=C&versionType=0',
  '/develop/release/list?stateList=R,L&versionType=0',
]

const VERSION_UNDER_APPROVAL_URL = '/ReleaseStoryLog/getReleaseStoryLogList' //审批中版本

export default function DevListDetailRightPanel(props) {
  const { isDetail, allUserRes, editFormData, setEditFormData, needBizFlag, initFormData, showAllDetailAuth, infoError,
    setInfoError, id, refreshReleaseList } = props

  const [assessExpand, setAssessExpand] = useState(true)
  const [planExpand, setPlanExpand] = useState(true)
  const [createInfoExpand, setCreateInfoExpand] = useState(true)
  const [businessExpand, setBusinessExpand] = useState(true)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { doFetch, data: underApprobal } = useGet()
  const [showJoinVersion, setShowJoinVersion] = useState(false)
  const [closurePlan, setClosurePlan] = useState()

  useEffect(() => {
    if (_.isNil(id)) return
    doFetch(`${VERSION_UNDER_APPROVAL_URL}?${strParams({ storyId: id, auditType: '1', auditFlag: 'U' })}`)
  }, [doFetch, id])

  const [releaseStoryListOptions, leftAllProjectOptions, closurePlanOpt, releasedOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const allProjectOptions = _.map(d2, item => ({
      text: item.projectName, value: item.id, projectStatus: item.projectStatus,
      projectType: item.projectType, budgetRevProject: item.budgetRev
    }))
    return [
      _.map(d1, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId })),
      _.sortBy(allProjectOptions, 'text'),
      _.map(d3, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId })),
    ]
  }, [optionsRes])

  const releaseStoryListOptionsWithExtend = useMemo(() => {
    if (_.isEmpty(releaseStoryListOptions)) return []
    const initReleaseList = _.get(editFormData, 'releaseList')
    const valueList = _.map(releaseStoryListOptions, 'value')
    const findNotIncludedList = _.filter(initReleaseList, item => !_.includes(valueList, item))
    const findNotIncludedOptions = _.map(findNotIncludedList, x => ({ label: x, text: x, value: x }))
    return _.concat(findNotIncludedOptions, releaseStoryListOptions)
  }, [releaseStoryListOptions, editFormData])

  const baseProjectOptions = useMemo(() => {
    if (_.isEmpty(leftAllProjectOptions)) return []
    if (_.get(editFormData, 'type') === 'CUS') {
      if (_.includes(['SPC', 'NORMAL', 'FUNCTION'], _.get(editFormData, 'reqSort'))) {
        return _.filter(leftAllProjectOptions, x => x.projectType === 'CUS')
      } else {
        return _.filter(leftAllProjectOptions, x => x.projectType === 'DEV')
      }
    } else {
      return _.filter(leftAllProjectOptions, x => x.projectType === 'DEV')
    }
  }, [editFormData, leftAllProjectOptions])

  const extendProjectOptions = useMemo(() => {
    const leafProjectId = _.get(editFormData, 'leafProjectId')
    if (!leafProjectId) return baseProjectOptions
    const projectValueList = _.map(baseProjectOptions, 'value')
    if (_.includes(projectValueList, leafProjectId)) {
      return baseProjectOptions
    } else {
      const initItem = _.find(leftAllProjectOptions, x => x.value === leafProjectId)
      if (initItem) {
        return _.concat([initItem], baseProjectOptions)
      } else {
        return baseProjectOptions
      }
    }
  }, [baseProjectOptions, editFormData, leftAllProjectOptions])

  const [auditTypeOptions, releaseIdOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const releaseIdExtendOptions = useMemo(() => {
    return releaseIdOptions
  }, [releaseIdOptions])

  const changeForm = useCallback((newObj, bind) => {
    setEditFormData((oldObj) => {
      let cloneObj = { ...oldObj, ...newObj }
      if (bind === 'releaseList') {
        const oldList = _.get(oldObj, 'releaseList')
        const newList = _.get(newObj, 'releaseList')
        if (_.size(oldList) > _.size(newList)) {
          const releaseid = _.head(_.filter(oldList, v => !_.includes(newList, v)))
          const findReleasedItem = _.find(releasedOpt, o => o.value === releaseid)
          if (!_.isNil(findReleasedItem)) {
            cloneObj[bind] = oldList
            Messager.show('版本已发布或已上线，无法删除', { icon: 'error' })
          }else {
            const findItem = _.find(closurePlanOpt, o => o.value === releaseid)
            if (!_.isNil(findItem)) {
              cloneObj[bind] = oldList
              setClosurePlan(newList)
            }
          }
        }
      }
      if (bind === 'leafProjectId') {
        if (cloneObj['leafProjectId']) {
          cloneObj['budgetRevProject'] = _.get(oldObj, 'budgetrev')
        } else {
          cloneObj['budgetRevProject'] = null
        }
      }
      return cloneObj
    })
  }, [setEditFormData, closurePlanOpt, releasedOpt])

  const delReleaseList = useCallback((delItem) => {
    setEditFormData(oldObj => {
      const tempObj = _.clone(oldObj)
      const releaseList = _.get(tempObj, 'releaseList')
      tempObj['releaseList'] = _.filter(releaseList, x => x !== delItem)
      return tempObj
    })
  }, [setEditFormData])

  const canSelectProject = useMemo(() => {
    if (_.get(editFormData, 'type') !== 'CUS') {
      return true
    }
    const reqSort = _.get(editFormData, 'reqSort')
    const businessAuditState = _.get(editFormData, 'businessauditstate')
    return !(_.includes(['SPC', 'NORMAL'], reqSort) && (businessAuditState !== 'Y'));
  }, [editFormData])

  const canClearProject = useMemo(() => {
    if (_.get(initFormData, 'type') !== 'CUS') {
      return true
    }
    return _.isNil(_.get(initFormData, 'leafProjectId'))
  }, [initFormData])

  const [editReleaseListAuth, editLeafProjectIdAuth] = useFuncCode(['1502', '1505'])

  return <div className={'dev-list-detail-right-panel flex-y'}>
    <div className="area-wrap assess-wrap">
      <div className="area-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: assessExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setAssessExpand(v => !v)} />
        评估信息
      </div>
      <div className="area-content-wrap" style={{ height: assessExpand ? '' : 0 }}>
        <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
          <HFormInput label={'负责人'} bind={'assessor'} required component={isDetail ? Display : UserSelect} hiddenUserList={[_.get(editFormData, 'assessauditor')]}
            convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
          {
            showAllDetailAuth &&
            <HFormInput label={'工作量'} bind={'workload'} suffix={'人日'} component={isDetail ? Display : Input} type={'number'} min={0} digit={2}
              convert={v => {
                if (_.isNil(v)) return '-'
                return v + '人日'
              }}
            />
          }
          <HFormInput label={'计划发布日期'} bind={'releaseDate'} component={isDetail ? Display : DatePicker} clear
            bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
            convert={v => {
              if (_.isNil(v)) return '-'
              if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
              return dateFormat('YYYY-MM-DD', v) || '-'
            }}
          />
          <Tooltip title={'五个工作日内进行的商务确认有效'}>
            <span>
              <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
            </span>
          </Tooltip>
          <HFormInput label={'研发负责人'} bind={'devUser'} component={isDetail ? Display : UserSelect}
            convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
          <HFormInput label={'测试人员'} bind={'analystTestUser'} component={isDetail ? Display : UserSelect}
            convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
        </Form>
      </div>
    </div>
    <div className="area-wrap plan-wrap">
      <div className="area-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: planExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setPlanExpand(v => !v)} />
        版本规划
      </div>
      <div className="area-content-wrap" style={{ height: planExpand ? '' : 0 }}>
        <Form value={editFormData} onChange={changeForm}>
          <HFormInput search label={'发现版本'} bind={'findVersion'} component={isDetail ? Display : DisplaySelect} required options={releaseIdExtendOptions} clear convert={v => convertOptions(v, releaseIdExtendOptions)} />
          {
            !isDetail &&
            <HFormInput label={'版本计划'} bind={'releaseList'} disabled={isDetail || !editReleaseListAuth} component={VersionSelect} isControlled={true}
              toolbar={false} search multiple options={releaseStoryListOptionsWithExtend} componentClass={'release-list-options'} />
          }
          {
            !isDetail &&
            <Tooltip title={'申请加入已封板的版本'}>
              <span>
                <Icon name={'tianjia'} className={'join-version-icon'} style={{ fontSize: 14, color: '#5477FF' }}
                  onClick={() => setShowJoinVersion(true)} />
              </span>
            </Tooltip>
          }
          {
            isDetail &&
            <div className={'release-list-wrap flex center-y'}>
              <div className="mock-label">版本计划</div>
              {
                !_.isEmpty(_.get(editFormData, 'releaseList')) &&
                <div className={'release-list-show-wrap'}>
                  {
                    _.map(_.get(editFormData, 'releaseList'), item => {
                      return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                        <Tooltip title={convertOptions(item, releaseStoryListOptionsWithExtend)} placement="left">
                          <span className={'release-list-item-text'}>{convertOptions(item, releaseStoryListOptionsWithExtend)}</span>
                        </Tooltip>
                        {
                          !isDetail && editReleaseListAuth &&
                          <Icon name='baocuo' className={'release-list-item-icon'} onClick={() => delReleaseList(item)} />
                        }
                      </div>
                    })
                  }
                </div>
              }
            </div>
          }
          {
            !_.isEmpty(underApprobal) &&
            <div className={'release-list-wrap flex center-y'}>
              <div className="mock-label">审批中版本</div>
              <div className={'release-list-show-wrap'}>
                {
                  _.map(underApprobal, o => {
                    return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                      <Tooltip title={o.releaseId} placement="left">
                        <span className={'release-list-item-text'}>{o.releaseId}</span>
                      </Tooltip>
                    </div>
                  })
                }
              </div>
            </div>
          }
          <HFormInput label={'所属项目'} bind={'leafProjectId'} clear={canClearProject} component={(isDetail || !editLeafProjectIdAuth) ? Display : Select} search options={extendProjectOptions}
            convert={v => convertOptions(v, leftAllProjectOptions)} componentStyle={{ height: isDetail ? 'unset' : '' }} disabled={!canSelectProject} className={'leaf-project-id-form-input'} />
        </Form>
      </div>
    </div>
    {
      needBizFlag === 'Y' &&
      <div className="area-wrap">
        <div className="area-header flex center-y">
          <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: businessExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBusinessExpand(v => !v)} />
          商务确认信息
        </div>
        <div className="area-content-wrap" style={{ height: businessExpand ? '' : 0 }}>
          <Form value={editFormData}>
            <HFormInput label={'商务审批人'} bind={'businessauditor'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
            <HFormInput label={'审批状态'} bind={'businessauditstate'} component={Display} convert={v => convertOptions(v, auditTypeOptions)} />
            <HFormInput label={'审批时间'} bind={'businessaudittime'} component={Display}
              convert={v => {
                if (_.isNil(v)) return '-'
                if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
              }}
            />
          </Form>
        </div>
      </div>
    }
    <div className="area-wrap create-info-wrap">
      <div className="area-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: createInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCreateInfoExpand(v => !v)} />
        创建信息
      </div>
      <div className="area-content-wrap" style={{ height: createInfoExpand ? '' : 0 }}>
        <Form value={editFormData}>
          <HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
          <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
            convert={v => {
              if (_.isNil(v)) return '-'
              if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
              return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
            }}
          />
        </Form>
      </div>
    </div>
    {
      showJoinVersion &&
      <ApplyJoinVersionDialog id={id} close={() => setShowJoinVersion(false)} type={'REQ'} afterRefresh={refreshReleaseList} />
    }
    {
      !_.isNil(closurePlan) &&
      <ClosurePlanMessage
        id={id}
        type='DEV'
        releaseidList={closurePlan}
        close={() => setClosurePlan(null)}
        afterRefresh={refreshReleaseList}
      />
    }
  </div>
}

import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { Drawer, Switch } from 'antd'
import { Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { Select, Input, DatePicker } from 'rootnet-edit'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { BaseTable, useTablePipeline, features } from 'ali-react-table'
import { existingPersonnelFun } from '../formatData'
import { useApi } from '../../../../../utils/hook'
import { Icon } from '../../../../../components'
import './index.scss'

const ADD_RUL = '/userFactor/add'
const EDIT_URL = '/userFactor/edit'

const { str14ToDate, dateStringToDate } = toDate

const priorityOpt = _.map(new Array(10), (v, i) => ({ text: i + 1, value: `${i + 1}` }))

function getColumns(props) {
  const { editInfo, setEditInfo, editUpdate, setValue, moduleOpt, positionOptions, techLevelOptions, isFuncCodePeople } = props
  const { editId = null, editField = null } = editInfo || {}

  const selectOpt = {
    priority: priorityOpt,
    module: moduleOpt,
    role: positionOptions,
    techLevel: techLevelOptions
  }

  return [
    { name: '人员', code: 'userName', align: 'left', width: 120, },
    {
      align: 'center',
      name: '人员当前基本信息',
      children: [
        { name: '角色', code: 'skill', align: 'center', width: 90, },
        { name: '职级', code: 'techLevelNow', align: 'center', width: 70, },
        { name: '入职日期', code: 'joinDateNow', align: 'center', width: 90, },
      ],
    },
    {
      align: 'center',
      name: '人员技能标签',
      children: [
        { name: '生效日期', code: 'effectiveDate', align: 'center', width: 90, render: (v, o) => convertEditField(v, o, 'effectiveDate') },
        { name: '岗位', code: 'postionName', align: 'center', width: 90, render: (v, o) => convertEditField(v, o, 'role') },
        { name: '职级', code: 'techLevel', align: 'center', width: 70, render: (v, o) => convertEditField(v, o, 'techLevel') },
        { name: '模块', code: 'modelName', align: 'center', width: 120, render: (v, o) => convertEditField(v, o, 'module') },
        { name: '产出系数', code: 'productivityFactor', align: 'center', width: 80, render: (v, o) => convertEditField(v, o, 'productivityFactor') },
        { name: '优先级', code: 'priority', align: 'center', width: 80, render: (v, o) => convertEditField(v, o, 'priority') },
      ],
    },
  ]
  function convertEditField(v, o, bind) {
    if (!isFuncCodePeople) return v
    if (editId === o.idUserFactors && editField === bind) {
      if (_.includes(['priority', 'techLevel', 'module', 'role'], bind)) {
        return <Select
          defaultValue={o[bind]}
          options={selectOpt[bind]}
          onChange={val => editUpdate({ newVal: val, oldVal: v, id: o.id, bind, children: o.collect, item: o })}
        />
      } else if (_.includes(['effectiveDate'], bind)) {
        return <DatePicker
          mode='date'
          defaultValue={dateStringToDate(`${v} 00:00:00`)}
          onChange={date => editUpdate({ newVal: dateFormat('YYYYMMDDHHMMSS', date), oldVal: `${_.join(_.split(v, '-'), '')}000000`, id: o.id, bind, children: o.collect, item: o })}
        />
      }
      return <Input
        autoFocus
        defaultValue={v}
        type='number'
        min={0}
        max={1}
        digit={1}
        onChange={num => setValue(num)}
        onBlur={() => editUpdate({ oldVal: v, id: o.id, bind, children: o.collect, item: o })}
        onEnter={() => editUpdate({ oldVal: v, id: o.id, bind, children: o.collect, item: o })}
      />
    }
    return <div
      className='convert-edit-field-style'
      onClick={() => setEditInfo({ editId: o.idUserFactors, editField: bind })}
    >
      {isNil(v) ? '-' : v}
      <Icon className='edit-icon' name='bianji2' />
    </div>
  }
}

export default function ExistingPersonnel(props) {
  const { visible, close = () => { }, data: list, refresh, moduleOpt, positionOptions, techLevelOptions, isModule, isFuncCodePeople, year } = props
  const [editInfo, setEditInfo] = useState({})
  const [value, setValue] = useState()
  const [flag, setFlag] = useState(false)
  const [openKeys, onChangeOpenKeys] = useState()
  const [isInherit, setIsInherit] = useState(false)
  const { doFetch } = useApi()

  const editUpdate = useCallback(props => {
    const { id, newVal, oldVal, bind, children, item } = props
    const newValue = _.isNil(newVal) ? value : newVal
    if (newValue === oldVal || _.isNil(newValue)) return destroyValue()
    const isAdd = _.isNil(id)
    if (isAdd && bind !== 'module' && _.isNil(item?.module)) {
      Messager.show('请先填写模块后再进行操作', { icon: 'error' })
      destroyValue()
      return
    }
    if (_.includes(['priority', 'effectiveDate', 'productivityFactor'], bind) && !isAdd) {
      const findData = _.find(children, o => o.id === id)
      if ('effectiveDate' === bind) {
        const filterList = _.filter(children, o => (o.id !== id && dateFormat('YYYY-MM-DD', str14ToDate(newValue)) === o.effectiveDate))
        const sum = _.sum(_.map(filterList, o => _.toNumber(o.productivityFactor)))
        const newSum = _.toNumber(findData.productivityFactor) + sum
        if (newSum > 1) return Messager.show('当前人员产出系数不能超过一', { icon: 'error' })
        const size = _.size(_.filter(filterList, o => o.priority === findData.priority))
        if (size > 0) return Messager.show('当前人员优先级只能唯一', { icon: 'error' })
      }
      if ('priority' === bind) {
        const filterList = _.filter(children, o => (o.id !== id && findData.effectiveDate === o.effectiveDate && newValue === o[bind]))
        if (_.size(filterList) > 0) return Messager.show('当前人员优先级只能唯一', { icon: 'error' })
      }
      if ('productivityFactor' === bind) {
        const filterList = _.filter(children, o => (o.id !== id && findData.effectiveDate === o.effectiveDate))
        const sum = _.sum(_.map(filterList, o => _.toNumber(o[bind])))
        const newSum = _.toNumber(newValue) + sum
        if (newSum > 1) return Messager.show('当前人员产出系数不能超过一', { icon: 'error' })
      }
    }

    doFetch(isAdd ? ADD_RUL : EDIT_URL, 'post', _.assign({}, !isAdd && {
      id,
      [bind]: (bind === 'productivityFactor' && newValue === '') ? '0' : newValue
    }, isAdd && {
      userAccount: item.userAccount,
      effectiveDate: `${year}0101000000`,
      [bind]: (bind === 'productivityFactor' && newValue === '') ? '0' : newValue,
    }))
      .then(() => {
        refresh()
        destroyValue()
        setIsInherit(true)
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, refresh, value, year])

  const dataSource = useMemo(() => {
    const data = existingPersonnelFun(_.cloneDeep(list), isModule)
    if (flag) return _.filter(data, o => filterData(o.collect) < 1)
    return data
    function filterData(datas) {
      const numArr = _.map(datas, item => isNil(item.productivityFactor) ? 0 : _.toNumber(item.productivityFactor))
      return _.sum(numArr)
    }
  }, [flag, isModule, list])

  const closeAll = useCallback(() => {
    close()
    destroyValue()
    setFlag(false)
    setIsInherit(false)
    onChangeOpenKeys(getKey(dataSource))
  }, [close, dataSource])

  const title = useMemo(() => {
    return <div className='headr-title flex'>
      <div className='title'>现有人员</div>
      <div className='switch-type'>
        <div className='flex'>
          产出系数小于1：
          <Switch
            onChange={v => setFlag(v)}
            checked={flag}
            checkedChildren="开"
            unCheckedChildren="关"
          />
        </div>
      </div>
    </div>
  }, [flag])

  useEffect(() => {
    if (!isInherit && !_.isEmpty(dataSource)) {
      onChangeOpenKeys(getKey(dataSource))
    }
  }, [dataSource, isInherit])

  const pipeline = useTablePipeline()
    .input({
      dataSource, columns: getColumns({
        editInfo,
        setEditInfo,
        editUpdate,
        setValue,
        moduleOpt,
        positionOptions,
        techLevelOptions,
        isFuncCodePeople
      })
    })
    .primaryKey('idUserFactors')
    .use(
      features.treeMode({
        clickArea: 'cell',
        openKeys,
        onChangeOpenKeys,
      }),
    )

  return (
    <Drawer
      title={title}
      destroyOnClose
      onClose={closeAll}
      visible={visible}
      className='existing-personnel antd-default-style'
    >
      <BaseTable
        {...pipeline.getProps()}
        style={{
          '--row-height': "34px",
          '--header-bgcolor': '#F5F5F5',
          '--header-color': '#252525'
        }}
      />
    </Drawer>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

function getKey(data, arr = []) {
  _.forEach(data, o => {
    if (!_.isEmpty(o.children)) {
      arr.push(o.idUserFactors)
      getKey(o.children, arr)
    }
  })
  return arr
}

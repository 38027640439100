import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Button } from 'rootnet-ui'
import { Popover, Badge, Empty } from 'antd'
import { Form, Input, FormInput, Select } from 'rootnet-edit'
import UserSelect from '../../../../../../common/personSelectPop/UserSelect'
import DateSelector from '../../../../../../common/dateSelector/DateSelector'
import { Icon } from '../../../../../../../components'

const formData = [
  { label: 'ID', bind: 'storyId' },
  { label: '标题', bind: 'title' },
  { label: '当前处理人', bind: 'currentUser', component: UserSelect, multiple: true, componentStyle: { overflowY: 'auto', height: 32 }, bindInConvert: v => !!v ? _.split(v, ',') : [], bindOutConvert: v => (v || []).join(',') },
  { label: '状态', bind: 'status', component: Select, multiple: true, clear: true },
  { label: '产品', bind: 'productId', component: Select, multiple: true, tree: true, clear: true },
  { label: '客户系统', bind: 'custSystem', component: Select, clear: true },
  { label: '需求类型', bind: 'storyType', component: Select, clear: true, multiple: true, },
  { label: '需求负责人', bind: 'assessor', component: UserSelect, multiple: true, componentStyle: { overflowY: 'auto', height: 32 }, bindInConvert: v => !!v ? _.split(v, ',') : [], bindOutConvert: v => (v || []).join(',') },
  { label: '研发负责人', bind: 'devUser', component: UserSelect, multiple: true, componentStyle: { overflowY: 'auto', height: 32 }, bindInConvert: v => !!v ? _.split(v, ',') : [], bindOutConvert: v => (v || []).join(',') },
  { label: '加入人', bind: 'updateUser', component: UserSelect, multiple: true, componentStyle: { overflowY: 'auto', height: 32 }, bindInConvert: v => !!v ? _.split(v, ',') : [], bindOutConvert: v => (v || []).join(',') },
  { label: '加入时间', bind: 'updateTime', component: DateSelector },
  { label: '测试负责人', bind: 'testUser', component: UserSelect },
  { label: '客户服务编号', bind: 'csRecordId' },
  { label: '回归标识', bind: 'regression', component: Select, clear: true },
  { label: '创建人', bind: 'createUser', component: UserSelect },
  { label: '创建时间', bind: 'createTime', component: DateSelector },
]

export default function Options(props) {
  const { searchNum, params, setParams, search, productTreeOptions, customerSystemOpt, storyTypeOptions, workflowOpt, yseOrNoOpt, RightFun } = props
  const [searchAreaOpen, setSearchAreaOpen] = useState(false)
  const [activeOptions, setActiveOptions] = useState(formData)
  const [showAddPop, setShowAddPop] = useState(false)
  const [searchNotAddText, setSearchNotAddText] = useState()

  const addConditionSearch = useMemo(() => {
    return <Input placeholder="搜索过滤条件" value={searchNotAddText} onChange={setSearchNotAddText} suffix={<Icon name={'sousuo1'} />} />
  }, [searchNotAddText])

  const onPopChange = useCallback((visible) => {
    if (visible) {
      setShowAddPop(true)
    } else {
      setSearchNotAddText(null)
      setShowAddPop(false)
    }
  }, [])

  const notAddCondition = useMemo(() => {
    _.forEach(formData, o => {
      if (o.bind === 'productId') o.options = productTreeOptions
      if (o.bind === 'custSystem') o.options = customerSystemOpt
      if (o.bind === 'storyType') o.options = storyTypeOptions
      if (o.bind === 'status') o.options = workflowOpt
      if (o.bind === 'regression') o.options = yseOrNoOpt
    })
    const allNotAdd = _.filter(formData, allItem => {
      return _.isNil(_.find(activeOptions, activeItem => activeItem?.bind === allItem?.bind))
    })
    if (_.isNil(searchNotAddText) || searchNotAddText === '') {
      return allNotAdd
    } else {
      return _.filter(allNotAdd, x => _.includes(x.label, searchNotAddText))
    }
  }, [activeOptions, customerSystemOpt, productTreeOptions, searchNotAddText, storyTypeOptions, workflowOpt, yseOrNoOpt])

  const addCondition = useMemo(() => {
    return <div className='add-condition-list-wrap'>
      {
        _.map(notAddCondition, listItem => (<div className={'add-condition-list-item flex center-y'} key={listItem.bind}
          onClick={() => setActiveOptions(x => {
            setSearchNotAddText(null)
            setShowAddPop(false)
            setParams(oldParams => {
              const newParams = oldParams || {}
              newParams[listItem.bind] = null
              return newParams
            })
            return _.concat(x, [listItem])
          })}>
          {listItem.label}
        </div>))
      }
      {
        _.isEmpty(notAddCondition) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
    </div>
  }, [notAddCondition, setParams])

  const closeFormInput = useCallback((currentConfig) => {
    const currentBind = _.get(currentConfig, 'bind')
    setActiveOptions(activeOptions => {
      const newOptions = _.filter(activeOptions, x => _.get(x, 'bind') !== currentBind)
      setParams(oldParams => {
        return _.omit(oldParams, currentBind)
      })
      return ([...newOptions])
    })
  }, [setParams])

  return (
    <div className={'view-area-wrap flex-y'}>
      <div className="view-area flex">
        <div className='system-view-area flex center-y'>
          <Badge count={searchNum} size="small" offset={[8, 0]}>
            <div className={`search-btn flex center-y ${searchAreaOpen ? 'search-area-open' : ''}`} onClick={() => setSearchAreaOpen(x => !x)}>
              <Icon name={'sousuo1'} className={'search-icon'} />
              <div className="search-text">搜索</div>
            </div>
          </Badge>
        </div>
        <RightFun />
      </div>
      <div className="custom-search-area" style={{ display: searchAreaOpen ? '' : 'none' }}>
        <div className='customize-options'>
          <div className="c-options">
            <Form value={params} onChange={setParams}>
              {
                _.map(activeOptions, config => {
                  return <div className='single-form-input' key={`${config.bind}`}>
                    <FormInput {...config} clear search className='single-form-input' />
                    <Icon name={'quxiao'} className={'close-form-input-icon'} onClick={() => closeFormInput(config)} />
                  </div>
                })
              }
            </Form>
            <Popover content={addCondition} title={addConditionSearch} trigger="click" placement="bottom" open={showAddPop} overlayClassName={'add-condition-pop'}
              onOpenChange={onPopChange}
            >
              <Icon name={'tianjia'} className='add-options-icon' onClick={() => setShowAddPop(x => !x)} />
            </Popover>
            <div className="search-btn-group flex">
              <Button className={'search-btn'} primary onClick={() => { search(params) }} >查询</Button>
              <Button onClick={() => {
                search()
                setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })),
                  !_.isNil(x?.updateTime) && { updateTime: { begin: null, end: null } },
                  !_.isNil(x?.createTime) && { createTime: { begin: null, end: null } },
                ))
              }} className={'reset-btn'} text >重置</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

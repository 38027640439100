import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, Messager, Tab, TabItem, Loader } from 'rootnet-ui'
import './TrackingAddDialog.scss'
import _ from "lodash";
import { FormInput, Form, Select, RadioGroup } from "rootnet-edit";
import Icon from "../../../../components/Icon";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import TextAreaInput from "../../../common/textAreaInput/TextAreaInput";
import RichTextEditor from "../../../common/richTextEditor/TinyEditor";
import { Anchor } from 'antd';
import { isNil } from "rootnet-core/format";
import { usePost } from "rootnet-biz/es/hooks";

const { Link } = Anchor;

const YNOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=BugType',
    'common/globalconst?globalConst=PRIORITYLIST',
    'common/globalconst?globalConst=performanceFlag',
    'common/globalconst?globalConst=MODIFYCODETYPE',
    'common/globalconst?globalConst=BUGSOURCE',
    'common/globalconst?globalConst=ISSUEWAY',
]

const OPTIONS_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/verQuery/queryCustOptions',
]

const TEMPLATE_URLS = [
    '/issue/getDemoTxt?id=10',
    '/issue/getDemoTxt?id=11',
    '/issue/getDemoTxt?id=12',
    '/issue/getDemoTxt?id=13',
]

const BASE_FORM = {
    performanceFlag: 'NOCHANGE',
    tracerPri: 'B',
    dictChange: 'N',
    bugSource: 'S0',
    issueToExtend: 'N',
    issueToCustomer: 'Y',
    upgradeDesc: '无'
}

const upgradeDescOptions = [
    { text: '无', value: 'N' },
    { text: '有', value: 'Y' },
]

function TrackingAddDialog(props) {
    const { close, initForm = {}, afterRefresh } = props
    const [formData, setFormData] = useState(_.merge({ ...BASE_FORM }, _.pickBy(initForm, x => !_.isNil(x))))
    const [formError, setFormError] = useState()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { data: templatesRes } = useGet(TEMPLATE_URLS)
    const [areaFold, setAreaFold] = useState({})
    const bugDescRef = useRef()
    const fixDescRef = useRef()
    const mainContentRef = useRef()
    const { doFetch: getTemplate } = useGet()
    const [addLoading, setAddLoading] = useState(false)
    const { doFetch: submitPost } = usePost()
    const [inited, setInited] = useState(false)
    const [hasUpgradeDesc, setHasUpgradeDesc] = useState('N')
    const [upgradeDescTemplate, setUpgradeDescTemplate] = useState()

    useEffect(() => {
        if (inited) return
        bugDescRef.current.setContent(_.get(initForm, 'bugDesc') || '')
        fixDescRef.current.setContent(_.get(initForm, 'fixDesc') || '')
        setInited(true)
    }, [initForm, inited])

    useEffect(() => {
        getTemplate('/issue/getDemoTxt?id=46').then(res => {
            const template = _.get(res, 'demoValue')
            setFormData(old => {
                old['dataBaseDesc'] = template
                old['dataBaseDescMySql'] = template
                old['dataBaseDescDM'] = template
                old['dataBaseDescSQLServer'] = template
                return old
            })
        })
    }, [getTemplate])

    useEffect(() => {
        getTemplate('/issue/getDemoTxt?id=49').then(res => {
            const template = _.get(res, 'demoValue')
            setFormData(old => {
                old['dataBaseQury'] = template
                return old
            })
        })
    }, [getTemplate])

    useEffect(() => {
        getTemplate('/issue/getDemoTxt?id=52').then(res => {
            const template = _.get(res, 'demoValue')
            setFormData(old => {
                old['needModedText'] = template
                return old
            })
        })
    }, [getTemplate])

    useEffect(() => {
        getTemplate('/issue/getDemoTxt?id=53').then(res => {
            const template = _.get(res, 'demoValue')
            setUpgradeDescTemplate(template)
            // setFormData(old => {
            //     old['upgradeDesc'] = template
            //     return old
            // })
        })
    }, [getTemplate])

    useEffect(() => {
        getTemplate('/issue/getDemoTxt?id=107').then(res => {
            const template = _.get(res, 'demoValue')
            setFormData(old => _.assign({}, old, { influenceDesc: template }))
        })
    }, [getTemplate])

    const onAreaExpandChange = useCallback((area) => {
        setAreaFold(old => {
            const clone = _.clone(old)
            clone[area] = !old[area]
            return clone
        })
    }, [])

    const [bugTemplate, newFeatureTemplate, performanceTemplate, marketTemplate] = useMemo(() => {
        if (_.isEmpty(templatesRes)) return []
        return _.map(templatesRes, x => _.get(x, 'demoValue'))
    }, [templatesRes])

    const [bugTypeOptions, priorityOptions, performanceFlagOptions, modifyCodeTypeOptions, bugSourceOptions, issueWayOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const [productTreeOptions, moduleOptionsRes, customerSystemOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [productRes, productLineRes, subProductRes, customerSystemRes] = optionsRes
        const productTreeOptions = _.map(_.groupBy(productRes, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(productLineRes, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))

        return [
            productTreeOptions,
            subProductRes,
            _.map(customerSystemRes, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}` })),
        ]
    }, [optionsRes])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(_.get(formData, 'productId'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === formData.productId)
        return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId }))
    }, [moduleOptionsRes, formData])

    const changeForm = useCallback((newObj, bind) => {
        setFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'productId') {
                cloneObj['subSysId'] = null
            }
            if (bind === 'bugType') {
                let modifiedBugDesc = false
                if (!_.includes(_.map([bugTemplate, newFeatureTemplate, marketTemplate, performanceTemplate], purifyRichText), purifyRichText(bugDescRef.current.getContent()))) {
                    modifiedBugDesc = true
                }
                if (!modifiedBugDesc || isNil(bugDescRef.current.getContent())) {
                    if (newObj['bugType'] === 'M') {
                        bugDescRef.current.setContent(bugTemplate)
                    }
                    if (newObj['bugType'] === 'N') {
                        bugDescRef.current.setContent(newFeatureTemplate)
                    }
                    if (newObj['bugType'] === 'P') {
                        bugDescRef.current.setContent(performanceTemplate)
                    }
                    if (newObj['bugType'] === 'K') {
                        bugDescRef.current.setContent(marketTemplate)
                    }
                }
            }
            return cloneObj
        })
    }, [bugTemplate, newFeatureTemplate, marketTemplate, performanceTemplate])

    const onAnchorClick = useCallback((e) => {
        e.preventDefault()
    }, [])

    const releaseNotesNoChange = useMemo(() => {
        return _.size(_.trim(_.get(formData, 'releaseNotes'))) === 0
        // purifyRichText(_.get(formData, 'releaseNotes')) === purifyRichText(releaseNotesTemplate)

        // function purifyRichText(richText = ''){
        //     const regex = /(<([^>]+)>)/ig
        //     return richText.replace(regex, "")
        //         .replace(/\s/g,"")
        //         .replace(/ /g,"")
        //         .replace(/&nbsp;/g,"")
        // }
    }, [formData])

    const canSubmit = useMemo(() => {
        if (!_.get(formData, 'tracerTitle')) return false
        if (releaseNotesNoChange) return false
        return !_.some(_.values(formError), x => x)
    }, [formData, formError, releaseNotesNoChange])

    const submit = useCallback(() => {
        if (isNil(bugDescRef.current.getContent())) {
            return Messager.show("请填写问题描述")
        }
        if (isNil(fixDescRef.current.getContent())) {
            return Messager.show("请填写修改方案")
        }
        if (addLoading) return
        setAddLoading(true)
        const postParams = {
            ...formData,
            securityName: _.join(_.get(formData, 'securityName'), '^'),
            bugDesc: bugDescRef.current.getContent(),
            fixDesc: fixDescRef.current.getContent(),
        }
        submitPost('/bug/tracer/add', postParams).then(() => {
            setAddLoading(false)
            Messager.show('添加成功', { icon: 'success' })
            if (afterRefresh) {
                afterRefresh()
            }
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setAddLoading(false)
        })
    }, [formData, addLoading, submitPost, close, afterRefresh])

    const onUpgradeDescChange = useCallback((value) => {
        setHasUpgradeDesc(value)
        if (value === 'Y') {
            setFormData(old => {
                old['upgradeDesc'] = upgradeDescTemplate
                return old
            })
        } else {
            setFormData(old => {
                old['upgradeDesc'] = '无'
                return old
            })
        }
    }, [upgradeDescTemplate])

    return <Dialog header={'新建研发任务'} className={'tracking-add-dialog'} contentClassName={'flex-y'} confirmButtonDisabled={!canSubmit} confirm={submit} cancel={close}>
        <div className={'content-wrap flex'}>
            <div className="main-content flex-y" ref={mainContentRef}>
                <div id={'tracking-title'}>
                    <HFormInput label={'标题'} value={_.get(formData, 'tracerTitle')}
                        onChange={v => setFormData(x => ({ ...x, tracerTitle: v }))} required componentWidth={772} />
                </div>
                <div className="area-wrap tracking-base-info-wrap" id={'tracking-base-info-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['baseInfo'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('baseInfo')} />
                        基本信息
                    </div>
                    <div className="area-content-wrap" style={{ height: areaFold['baseInfo'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={'类型'} bind={'bugType'} required component={Select} options={bugTypeOptions} />
                            <HFormInput label={'优先级'} bind={'tracerPri'} required component={Select} options={priorityOptions} />
                            <HFormInput label={'性能影响'} bind={'performanceFlag'} required component={Select} options={performanceFlagOptions} />
                            <HFormInput label={'产品'} bind={'productId'} required component={Select} search tree options={productTreeOptions} />
                            <HFormInput label={'子产品'} bind={'subSysId'} required component={Select} search options={moduleOptions} />
                            <HFormInput label={'修改类型'} bind={'modifyCodeType'} required component={Select} options={modifyCodeTypeOptions} />
                            <HFormInput label={'修改字典'} bind={'dictChange'} required component={RadioGroup} options={YNOptions} />
                            <HFormInput label={'来源'} bind={'bugSource'} clear component={Select} options={bugSourceOptions} />
                            <HFormInput label={'是否开放API'} bind={'issueToExtend'} required component={RadioGroup} options={YNOptions} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-customer-info-wrap" id={'tracking-customer-info-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['customerInfo'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('customerInfo')} />
                        客户相关信息
                    </div>
                    <div className="area-content-wrap" style={{ height: areaFold['customerInfo'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={'发布方式'} bind={'issueway'} required component={Select} options={issueWayOptions} />
                            <HFormInput label={'发布给客户'} bind={'issueToCustomer'} required component={RadioGroup} options={YNOptions} />
                            <HFormInput label={'客户需求编号'} bind={'clientIssueNo'} />
                            <HFormInput label={'需求来源'} bind={'projectId'} required search component={Select} options={customerSystemOptions} />
                            <HFormInput label={'发布客户'} bind={'securityName'} required search multiple component={Select} options={customerSystemOptions} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-bug-desc-wrap" id={'tracking-bug-desc-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['bugDesc'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('bugDesc')} />
                        问题描述<span style={{ color: 'red' }}>（必填）</span>
                    </div>
                    <div className="area-content-wrap rich-text-area" style={{ height: areaFold['bugDesc'] ? 0 : '' }}>
                        <RichTextEditor ref={bugDescRef} height={250} />
                    </div>
                </div>
                <div className="area-wrap tracking-fix-desc-wrap" id={'tracking-fix-desc-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['fixDesc'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('fixDesc')} />
                        修改方案<span style={{ color: 'red' }}>（必填）</span>
                    </div>
                    <div className="area-content-wrap rich-text-area" style={{ height: areaFold['fixDesc'] ? 0 : '' }}>
                        <RichTextEditor ref={fixDescRef} height={250} />
                    </div>
                </div>
                <div className="area-wrap tracking-data-base-wrap" id={'tracking-data-base-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['database'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('database')} />
                        修改数据库脚本
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['database'] ? 0 : '' }}>
                        <Tab>
                            <TabItem header='Oracle'>
                                <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                                    <HFormInput label={''} bind={'dataBaseDesc'} component={TextAreaInput} componentWidth={852} rows={5} />
                                </Form>
                            </TabItem>
                            <TabItem header='Mysql'>
                                <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                                    <HFormInput label={''} bind={'dataBaseDescMySql'} component={TextAreaInput} componentWidth={852} rows={5} />
                                </Form>
                            </TabItem>
                            <TabItem header='SQL Server'>
                                <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                                    <HFormInput label={''} bind={'dataBaseDescSQLServer'} component={TextAreaInput} componentWidth={852} rows={5} />
                                </Form>
                            </TabItem>
                            <TabItem header='DM'>
                                <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                                    <HFormInput label={''} bind={'dataBaseDescDM'} component={TextAreaInput} componentWidth={852} rows={5} />
                                </Form>
                            </TabItem>
                            <TabItem header='查询脚本'>
                                <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                                    <HFormInput label={''} bind={'dataBaseQury'} component={TextAreaInput} componentWidth={852} rows={5} />
                                </Form>
                            </TabItem>
                        </Tab>
                    </div>
                </div>
                <div className="area-wrap tracking-release-notes-wrap" id={'tracking-release-notes-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['releaseNotes'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('releaseNotes')} />
                        菜单位置<span style={{ color: 'red' }}>（必填）</span>
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['releaseNotes'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={''} bind={'releaseNotes'} required component={TextAreaInput} componentWidth={852} rows={5} outerError={releaseNotesNoChange} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-need-modify-text-wrap" id={'tracking-need-modify-text-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['needModify'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('needModify')} />
                        修改文档说明
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['needModify'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={''} bind={'needModedText'} component={TextAreaInput} componentWidth={852} rows={5} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-upgrade-desc-wrap" id={'tracking-upgrade-desc-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['upgradeDesc'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('upgradeDesc')} />
                        升级配置说明
                        <RadioGroup options={upgradeDescOptions} value={hasUpgradeDesc} onChange={onUpgradeDescChange} />
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['upgradeDesc'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={''} bind={'upgradeDesc'} component={TextAreaInput} componentWidth={852} rows={5} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-influence-desc-wrap" id={'tracking-influence-desc-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['influenceDesc'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('influenceDesc')} />
                        影响分析
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['influenceDesc'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={''} bind={'influenceDesc'} component={TextAreaInput} componentWidth={852} rows={25} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-stk-info-wrap" id={'tracking-stk-info-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['stkInfo'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('stkInfo')} />
                        业务概要说明
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['stkInfo'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={''} bind={'stkinfo'} component={TextAreaInput} componentWidth={852} rows={5} />
                        </Form>
                    </div>
                </div>
                <div className="area-wrap tracking-client-desc-wrap" id={'tracking-client-desc-wrap'}>
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: areaFold['clientDesc'] ? 'rotate(-90deg)' : 'none' }} onClick={() => onAreaExpandChange('clientDesc')} />
                        客户问题描述
                    </div>
                    <div className="area-content-wrap desc-wrap" style={{ height: areaFold['clientDesc'] ? 0 : '' }}>
                        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                            <HFormInput label={''} bind={'clientDesc'} component={TextAreaInput} componentWidth={852} rows={5} />
                        </Form>
                    </div>
                </div>
            </div>
            <div className="anchor-wrap flex-y">
                <Anchor onClick={onAnchorClick} getContainer={() => mainContentRef.current}>
                    <Link href="#tracking-title" title={<div className={'anchor-text'}>标题</div>} />
                    <Link href="#tracking-base-info-wrap" title={<div className={'anchor-text'}>基本信息</div>} />
                    <Link href="#tracking-customer-info-wrap" title={<div className={'anchor-text'}>客户相关信息</div>} />
                    <Link href="#tracking-bug-desc-wrap" title={<div className={'anchor-text'}>问题描述</div>} />
                    <Link href="#tracking-fix-desc-wrap" title={<div className={'anchor-text'}>修改方案</div>} />
                    <Link href="#tracking-data-base-wrap" title="修改数据库脚本" />
                    <Link href="#tracking-release-notes-wrap" title={<div className={'anchor-text'}>菜单位置</div>} />
                    <Link href="#tracking-need-modify-text-wrap" title="修改文档说明" />
                    <Link href="#tracking-upgrade-desc-wrap" title="升级配置说明" />
                    <Link href="#tracking-influence-desc-wrap" title="影响分析" />
                    <Link href="#tracking-stk-info-wrap" title="业务概要说明" />
                    <Link href="#tracking-client-desc-wrap" title="客户问题描述" />
                </Anchor>
            </div>
            {
                addLoading &&
                <Loader fill />
            }
        </div>
    </Dialog>
}

function purifyRichText(richText = '') {
    const regex = /(<([^>]+)>)/ig
    return richText.replace(regex, "")
        .replace(/\s/g, "")
        .replace(/ /g, "")
        .replace(/&nbsp;/g, "")
}

export default TrackingAddDialog;
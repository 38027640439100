import React, { useMemo, useState, useReducer } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import { useGet } from 'rootnet-biz/lib/hooks'
import { FormInput, Select } from 'rootnet-edit'
import ArrowCrumbs from '../../../../common/ArrowCrumbs'
import VersionRequirementMgt from './versionRequirementMgt'
import ChangeHistory from './changeHistory'
import VersionSetting from './versionSetting'
import { strParams } from '../../../../../utils/publicFun'
import Developing from '../../../../Developing'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { getDisabled } from './components/permission'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import VersionDefects from './versionDefects'
import VersionMaking from './versionMaking'
import ReleaseRecord from './releaseRecord'
import { useFuncCode, useRolePermissions } from '../../../../common/commonMethod'
import VersionDashboard from "../../controls/versionDashboard/VersionDashboard";
import VersionTestPlan from './versionTestPlan'
import './index.scss'
import VersionBaseLineList from './versionBaseLineList'
import VersionReqStageTask from './versionReqStageTask'
import VersionRelateComponent from "./versionRelateComponent/VersionRelateComponent";

const STATUS_URL = [
  '/common/globalconst?globalConst=TRACERSTATE', // 研发任务状态
  '/common/globalconst?globalConst=REQSTATUS', // 需求状态
  '/common/globalconst?globalConst=ISSUESTATUS', // ISSUE状态
  '/common/globalconst?globalConst=storyType', // 需求类型
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor', //工作流状态
  '/common/globalconst?globalConst=PRIORITYLIST', //优先级
  '/common/globalconst?globalConst=Yes_Or_No', //是否
]

//?userAccount=' + userAccount
export default function VersionDetail(props) {
  const { history, versionOpt, currentId, goBack, setCurrentId, apply, isEditCode, isReqCode, allUserRes, firstOrder = 'req', secondOrder, isProjectCode, isAddCode, refreshList } = props
  const [activeTab, setActiveTab] = useState(firstOrder)
  const [mulcustId, setMulcustId] = useState([])
  const [principal, setPrincipal] = useState()
  const [productId, setProductId] = useState()
  const [poUser, setPoUser] = useState()
  const [snowId, setSnowId] = useState()
  const [state, setState] = useState()
  const [versionCheck, setVersionCheck] = useState(false)
  const [checkOpen, setCheckOpen] = useState()
  const [detailData, setDetailData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, isReqStageExport
  ] = useFuncCode(['1503', '1902', '1910', '081040'])
  const [isALL, isPMM, isCM, isVersionYGH, isQA] = useRolePermissions(['ALL', 'PMM', 'CM', 'versionYGH', 'QA'])
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig('081000', setIsLoading)
  const { data: statusRes } = useGet(STATUS_URL)
  const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0)
  const [inventoryDynamic, updateInventoryDynamic] = useReducer((x) => x + 1, 0)
  const [count, forceUpdate] = useReducer((x) => x + 1, 0)
  const [countDefect, upDateDefect] = useReducer((x) => x + 1, 0)//缺陷

  const { producttype, projectid, productOwner } = useMemo(() => detailData || {}, [detailData])

  const isShow = useMemo(() => {
    return !(_.includes(['B', 'J'], producttype) && _.includes(['R', 'L', 'V'], state))
  }, [producttype, state])

  const [taskOpt, reqOpt, issueOpt, storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt] = useMemo(() => {
    if (_.isEmpty(statusRes)) return []
    return _.map(statusRes, o => convertGlobalConstOptions(o))
  }, [statusRes])

  const isDisabled = useMemo(() => {
    return getDisabled({ state, principal, poUser, isReqCode, isProjectCode })
  }, [state, principal, poUser, isReqCode, isProjectCode])

  const isState = useMemo(() => {
    return !_.includes(['R', 'L'], state)
  }, [state])

  const goBackList = useMemo(() => {
    return [
      { text: '版本列表' },
      {
        text: <FormInput search horizontal
          options={versionOpt}
          component={Select}
          value={currentId}
          onChange={v => {
            setCurrentId(v)
            history.replace({ pathname: '/versionMsg', search: strParams({ initId: v, firstOrder, secondOrder }) })
          }}
        />,
        active: true
      },
    ]
  }, [currentId, firstOrder, history, secondOrder, setCurrentId, versionOpt])

  const operations = useMemo(() => {
    return {
      left: <div>
        <ArrowCrumbs data={goBackList} goBack={goBack} />
      </div>
    }
  }, [goBack, goBackList])

  return (//secondOrder
    <div className='version-detail flex-y fill'>
      <Tabs
        centered
        className='version-tabs'
        activeKey={activeTab}
        tabBarExtraContent={operations}
        onChange={v => {
          if (versionCheck) return setCheckOpen(true)
          history.replace({
            pathname: '/versionMsg',
            search: strParams(_.assign({}, { initId: currentId, firstOrder: v },
              v === 'changeHistory' && { secondOrder: 'listChange' },
              v === 'versionMaking' && { secondOrder: 'taskListMaking' },
              v === 'set' && { secondOrder: 'basicInformation' }))
          })
          if (v === 'set') forceUpdate()
          setActiveTab(v)
        }}
        items={[
          {
            key: 'req',
            label: `任务`,
            children: <VersionRequirementMgt projectId={projectid} {...{ currentId, apply, isReqCode, isProjectCode, allUserRes, mulcustId, principal, productId, versionOpt, isDisabled, poUser, updateInventoryDynamic, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt, snowId, isVersionYGH }} />,
          },
          {
            key: 'baseLineList',
            label: `版本清单`,
            children: <VersionBaseLineList {...{ currentId, apply, isReqCode, isProjectCode, allUserRes, mulcustId, principal, productId, versionOpt, isDisabled, poUser, updateInventoryDynamic, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt, snowId, isALL, isState, isShow }} />,
          },
          {
            key: 'defect',
            label: `缺陷`,
            children: <React.Fragment key={countDefect}>
              <VersionDefects {...{ ReleaseID: currentId, isLoading, funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection, principal, poUser, productId: detailData?.productid, detailData, isShow }} />
            </React.Fragment>,
          },
          {
            key: 'testPlan',
            label: `测试计划`,
            children: <VersionTestPlan {...{ versionId: currentId, isShow }} />,
          },
          {
            key: 'reqStageTask',
            label: `需求阶段任务`,
            children: <VersionReqStageTask {...{ currentId, productOwner, isReqStageExport }} />
          },
          {
            key: 'changeHistory',
            label: `变更历史`,
            children: <ChangeHistory {...{ currentId, allUserRes, taskOpt, reqOpt, issueOpt, history, secondOrder, firstOrder: activeTab, countDynamic, snowId, inventoryDynamic, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt }} />,
          },
          {
            key: 'versionMaking',
            label: `版本制作`,
            children: <VersionMaking {...{ currentId, history, secondOrder, firstOrder: activeTab, allUserRes, isShow, isALL, principal }} />,
          },
          {
            key: 'releaseRecord',
            label: `发布记录`,
            children: <ReleaseRecord {...{ currentId, allUserRes }} />,
          },
          {
            key: 'versionDependency',
            label: `版本依赖`,
            children: <div style={{ position: 'relative', height: '100%' }}><Developing /></div>,
          },
          {
            key: 'relateComponent',
            label: `关联组件`,
            children: <VersionRelateComponent {...{currentId}}/>,
          },
          {
            key: 'dashboard',
            label: `仪表盘`,
            children: <VersionDashboard {...{ currentId }} />,
          },
          {
            key: 'set',
            label: `设置`,
            forceRender: true,
            children: <VersionSetting  {...{
              currentId,
              isEditCode,
              allUserRes,
              setMulcustId,
              versionOpt,
              history,
              secondOrder,
              firstOrder: activeTab,
              setPrincipal,
              principal,
              updateDynamic,
              setProductId,
              setSnowId,
              setState,
              setPoUser,
              setDetailData,
              detailData,
              count,
              versionCheck,
              setVersionCheck,
              checkOpen,
              setCheckOpen,
              isShow,
              isALL, isPMM, isCM, upDateDefect, isAddCode, refreshList, isQA
            }} />,
          },
        ]}
      />
    </div>
  )
}

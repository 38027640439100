import React, { useCallback, useContext, useMemo, useState, useEffect, useReducer } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import convertTableAlign from '../common/view/convertTableAlign'
import findConvert from '../common/view/findConvert'
import useGetViewConfig from '../common/view/hooks/useGetViewConfig'
import useRefreshList from '../common/view/hooks/useRefreshList'
import useGetDateOptions from '../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../common/view/hooks/useGetTreeList'
import { Box } from '../common/commonComponent'
import ViewArea from '../common/view/ViewArea'
import { TextIconBtn } from '../common/TextIconBtn'
import { ScheduleManagementContext } from '../common/Context'
import WorkFlowChangePop from '../workFlow/workFlowChangePop/WorkFlowChangePop'
import { pathSearchFor } from '../../utils/publicFun'
import RequirementDetailDialog from '../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import { useFuncCode } from '../common/commonMethod'
import './index.scss'

const ENTER_DETAIL_FIELD = 'story.title'
const RICH_TEXT_FIELD = ['story_extend.custCommunicationLog']

const EDITABLE_FIELD = [
  // 'story.priority', 'workflow_business.currentUser'
]

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, refreshList, workFlowId, setWorkFlowId, setCurrentInfo } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: !_.includes(_.concat([ENTER_DETAIL_FIELD], RICH_TEXT_FIELD), `${fieldItem.tableName}.${fieldItem.fieldId}`),
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (_.includes(RICH_TEXT_FIELD, tableField)) {
      return <Tooltip title={<div className='req-rich-text-val' dangerouslySetInnerHTML={{ __html: showValue }} />}><div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div></Tooltip>
    }
    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={'1501'} businessType={'req'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({
                id: _.get(o, 'id'),
                mode: 'detail'
              })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
      </div>
    }
    if (!_.includes(EDITABLE_FIELD, tableField)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '1500'

export default function RequirementMgtList(props) {
  const { location } = props;
  const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
  const [focusId, setFocusId] = useState()
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [sortConfig, setSortConfig] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const [workFlowId, setWorkFlowId] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)

  const [allDetailAuth, delAuth] = useFuncCode(['1507', '1503'])//addAuth 150101

  const onRowClick = useCallback((item) => {
    setFocusId(item.id)
  }, [])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({
    currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor
  })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: {
      setCurrentInfo, workFlowId, setWorkFlowId, refreshList,
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, showChildList])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    Messager.show('导出中...')
    downloadPostProgress('/UserSetting/exportExcel', postParams, '需求列表')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [exportViewList, allowExport])

  useEffect(() => {
    if (_.isNil(focusId)) return
    _.forEach(showList, o => {
      return o._rowClass = o?.id === focusId ? 'select_row' : ''
    })
    forceUpdate()
  }, [focusId, showList])

  return (
    <div className={'requirement-mgt-list fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'需求列表'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>

        <DataGrid option={options} data={dataGridList} onRowClick={onRowClick} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['detail', 'edit'], _.get(currentInfo, 'mode')) &&
        <RequirementDetailDialog
          refreshViewList={refreshList} close={() => setCurrentInfo(null)}
          outerAllDetailAuth={allDetailAuth} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, delAuth, setCurrentInfo }}
        />
      }
    </div>
  )
}
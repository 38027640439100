import React, {useCallback, useState} from 'react';
import Icon from "../../../../../components/Icon";
import {Display, Form, FormInput, Input} from "rootnet-edit";
import './CustomerRecord.scss'
import _ from "lodash";
import TinyEditor from "../../../../common/richTextEditor/TinyEditor";

const HFormInput = props => <FormInput horizontal labelWidth={150} componentWidth={180} {...props}/>

function CustomerRecord(props) {
    const {isDetail, editFormData,setEditFormData, customerRecordRef} = props
    const [reporterExpand, setReporterExpand] = useState(true)
    const [richTextExpand, setRichTextExpand] = useState(true)

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[setEditFormData])

    return <div className={'customer-record'}>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: reporterExpand ? 'none' : 'rotate(-90deg)'}}
                      onClick={()=>setReporterExpand(v => !v)}/>
                客户报告人信息
            </div>
            <div className="area-content-wrap" style={{height: reporterExpand ? '' : 0}}>
                <Form value={editFormData} onChange={changeForm} userActive={false}>
                    <HFormInput label={'客户问题报告人'} bind={'reporter'} component={isDetail? Display: Input}/>
                    <HFormInput label={'客户报告人Email'} bind={'reporterEmail'} component={isDetail? Display: Input}/>
                    <HFormInput label={'客户报告人其他信息'} bind={'reporterDossier'} component={isDetail? Display: Input} componentWidth={532}/>
                </Form>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: richTextExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setRichTextExpand(v => !v)}/>
                沟通记录详情
            </div>
            <div className="area-content-wrap" style={{height: richTextExpand ? '' : 0}}>
                <div className="rich-text-detail-wrap flex" style={{display: isDetail ? "block": 'none'}}>
                    <div dangerouslySetInnerHTML={{__html: _.get(editFormData,'communicateNote')?.replace(/\n/g, '<br/>')}}/>
                </div>
                <div className="rich-text-area" style={{display: !isDetail ? "block": 'none'}}>
                    <TinyEditor ref={customerRecordRef} height={350}/>
                </div>
            </div>
        </div>
    </div>
}

export default CustomerRecord;
import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import gd from './base/global';
import './App.scss';
import './views/style.scss';
import './project_share/style/_class.scss';
import { MessageBox, Messager } from 'rootnet-ui';
import { MainPanel } from './views/MainPanel';
import api, { API1 } from './base/task';
import ProjectListRouter from './views/project/ProjectListRouter';
import WorkTimeDetail from './views/costManage/workTime/WorkTimeDetail';
import WorkTime from "./views/costManage/workTime/WorkTime";
import AuthorizationManager from './views/authority/AuthorizationManager/AuthorizationManager';
import { Cache } from "./base/cache";
import _ from 'lodash'
import WaterMark from "./views/common/WaterMark";
import { ScheduleManagementContext, GuideContext, MenuContext } from './views/common/Context'
import { dateFormat } from "rootnet-core/dateFormat";
import useGet from "rootnet-biz/es/hooks/useGet";

import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd'

import introJs from 'intro.js'
import "intro.js/introjs.css";
import 'intro.js/themes/introjs-modern.css';
import RequirementMgt from "./views/requirementMgt/RequirementMgt";
import RequirementDetailContent from "./views/requirementMgt/requirementDetailDialog/RequirementDetailContent";
import IssueMgt from "./views/issueMgt/IssueMgt";
import IssueDetailContent from "./views/issueMgt/components/issueDetailDialog/IssueDetailContent";
import ConferenceRecords from "./views/conferenceMgt/conferenceRecords/ConferenceRecords";
import ConferenceRecordsDetailContent
	from "./views/conferenceMgt/conferenceRecords/conferenceRecordsDetail/ConferenceRecordsDetailContent";
import LOGO from './assets/images/logo.png'
import UserMenu from './userMenu';
import { logout, ssoLogout } from './utils/account';
import TopMenu from './topMenu';
import { _cache } from './utils/cache';
import useRouterCache from './views/useRouterCache';
import { pathSearchFor } from "./utils/publicFun";
import AppBarQuickCreate from "./views/quickCreate/AppBarQuickCreate";
import { uniqKeyFor } from './project_share/utils/utils';

const CLIENT = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop'

const PC_HOME_PAGE = '/workplace'
// const PC_HOME_PAGE = '/versionMsg'

function App(props) {
	const user = gd.User;
	const { data: allUserRes } = useGet('/common/userinfo')
	const { data: cutOffDateListRes } = useGet('/worktime/cutOffDate/query?cutOffDateFlag=Y')
	const [showMissingHours, setShowMissingHours] = useState(false)
	const [guideList, setGuideList] = useState([])
	const seniorStaff = _.map(_.filter(allUserRes, o => _.toNumber(o.department) === 96), o => o.userAccount)

	useEffect(() => {
		if (_.isEmpty(allUserRes)) return
		const allServingUser = _.filter(allUserRes, x => _.get(x, 'indexValidFlag') === 1)
		localStorage.setItem('all_serving_user', JSON.stringify(allServingUser))
		localStorage.setItem('all_user', JSON.stringify(allUserRes))
	}, [allUserRes])

	useEffect(() => {
		if (!_.isEmpty(guideList)) {
			let intro1 = introJs(document.getElementById('v-app'))
			intro1.setOptions({
				steps: guideList,
				prevLabel: "上一步",
				nextLabel: "下一步",
				doneLabel: "知道了",
			}).start();
			intro1.onexit(() => {
				setGuideList([])
			})
		}
	}, [guideList])


	const [lastCutOffDate, limitCutOffDate, beforeCutOffDate] = useMemo(() => {
		if (_.isEmpty(cutOffDateListRes)) return []
		const now = dateFormat('YYYY-MM-DD', new Date())
		const cutOffDateList = _.sortBy(_.map(cutOffDateListRes, x => x.jobdate))
		const lastCutOffDate = _.find(_.reverse([...cutOffDateList]), x => x < now)
		const beforeCutOffDate = _.find(_.reverse([...cutOffDateList]), x => x < lastCutOffDate)
		const limitCutOffDate = _.get(_.find(cutOffDateListRes, x => x.jobdate === lastCutOffDate), 'cutoffday')
		if (now >= lastCutOffDate && now <= limitCutOffDate && CLIENT !== 'Mobile') {
			api.get(`/worktime/missinghours?pageNum=1&pageSize=20&beginDate=${beforeCutOffDate.replace(/-/g, '')}&endDate=${lastCutOffDate.replace(/-/g, '')}&userAccount=${gd._user.operator_name}`).then(missingHoursRes => {
				const { rows: missingHoursList } = missingHoursRes.data
				if (missingHoursList.length > 0) {
					setShowMissingHours(true)
				}
			})
		}
		return [lastCutOffDate, limitCutOffDate, beforeCutOffDate]
	}, [cutOffDateListRes])

	useEffect(() => {
		if (_.isEmpty(allUserRes)) return
		const userInfo = _.find(allUserRes, x => x.userAccount === gd._user.operator_name)
		gd.Area = _.get(userInfo, 'area')
		localStorage.setItem('current_user_info', JSON.stringify(userInfo))
	}, [allUserRes])

	const urlList = Cache.get('urlList')
	if (!_.isEmpty(urlList)) {
		const excludeUrlList = ['/worktimeaddC', '/AuthorizationManager', '/report-demo', '/requirementMgtC', '/requirementDetailC'
			, '/issueC', '/issueDetailC', '/conferenceRecordsC', '/conferenceRecordsDetailC']
		let currentUrl = '/' + window.location.hash.split('/')[1]
		if (_.includes(currentUrl, '?')) currentUrl = currentUrl.split('?')[0]
		if (!_.includes(excludeUrlList, currentUrl) && !_.includes(urlList, currentUrl))
			props.history.push(CLIENT === 'Mobile' ? '/metadataMgt' : PC_HOME_PAGE)
	}

	const pathName = props.history.location.pathname
	const isWechat = sessionStorage.getItem('isWechat')

	const toMissingHoursPage = () => {
		setShowMissingHours(false)
		localStorage.setItem('firstShowMissingHours', 'false')
		props.history.push(`/missingHours?userAccount=${gd._user.operator_name}&beginDate=${beforeCutOffDate.replace(/-/g, '')}&endDate=${lastCutOffDate.replace(/-/g, '')}`)
	}

	return <GuideContext.Provider value={{ guideList, setGuideList }}>
		<div id={'v-app'} className={`v-app fill ${_.isNil(isWechat) ? '' : 'is-wechat'}`}>
			<ConfigProvider locale={zhCN}>
				<ShowArea {...{ allUserRes, pathName }} history={props.history} />
			</ConfigProvider>
			<WaterMark text={_.get(user, 'operator_name')} />
			{
				!_.includes(seniorStaff, user?.operator_name) && showMissingHours && localStorage.getItem('firstShowMissingHours') === 'true' &&
				<MessageBox header={'重要提醒'} confirmButtonText='查看缺失工时' cancelButtonText='我知道了' confirm={toMissingHoursPage} cancel={() => {
					setShowMissingHours(false)
					localStorage.setItem('firstShowMissingHours', 'false')
				}}>
					<div style={{ width: 550 }}>
						当前日期已超过上期成本归集截止日期【{lastCutOffDate}】，请务必在【{limitCutOffDate}】之前将【{lastCutOffDate}】（含）之前的缺失工时补充完整，过时将无法再填写时间工具。
					</div>
				</MessageBox>
			}
		</div>
	</GuideContext.Provider>
}


export default function WrapApp(props) {
	const { location } = props;
	const { ssoToken = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
	const uuid = gd.User?.uuid

	if (_.isNil(uuid) && _.isNil(ssoToken)) {
		ssoLogout({ location })
	} else {
		return <App {...props} />
	}
}

let newObject = {}

function ShowArea(props) {
	const { pathName } = props
	const [menuData, setMenuData] = useState()
	const [scheduleManagement, setScheduleManagement] = useState([])
	const [list, setList] = useState({})
	const [currentMenu, setCurrentMenu] = useState()
	const { openInfo, setOpenInfo, close } = useOpenInfo()
	const { currentUrl, setCurrentUrl } = useCurrentUrl(setOpenInfo);
	const { setRouteSafe, isSafe, setRouteDanger } = useRouteSafeMode();
	const {
		activeMenuList,
		activeUrl,
		onMenuItemClick,
		onTabChange,
		onTabRemove,
		CloseMenu
	} = useRouterCache()

	useEffect(() => {
		api.get(`/common/uacFuncctrl?moduleId=BS`)
			.then(res => {
				setMenuData(res.data)
				_cache.set('allMenuList', res.data)
			})
	}, [])

	useBlockRoute(isSafe, setCurrentUrl);
	useSkipRoute({ isSafe, currentUrl, setCurrentUrl, close });
	useListenBeforeUnload(isSafe);

	useEffect(() => {
		if (_.isEmpty(menuData)) return
		const condition = _.find(activeMenuList, o => o.url === pathName)
		if (_.isNil(condition)) {
			const findeData = _.find(menuData, o => o.url === pathName)
			if (findeData) {
				const currentMenu = _.map([findeData], r => (_.assign({}, r, {
					to: r.url,
					text: r.funcName,
					expanded: false,
				})))
				onMenuItemClick(_.head(currentMenu))
			}
		}
	}, [activeMenuList, pathName, menuData, onMenuItemClick])

	useEffect(() => {
		setScheduleManagement(_.orderBy(_.values(newObject), 'date', 'desc'))
	}, [list])

	useEffect(() => {
		if (_.isEmpty(scheduleManagement)) newObject = {}
	}, [scheduleManagement])

	if (pathName === '/worktimeaddC') return <WorkTime />
	if (pathName === '/AuthorizationManager') return <AuthorizationManager />
	if (pathName === '/requirementMgtC') return <RequirementMgt />
	if (pathName === '/requirementDetailC') return <RequirementDetailContent />
	if (pathName === '/issueC') return <IssueMgt />
	if (pathName === '/issueDetailC') return <IssueDetailContent />
	if (pathName === '/conferenceRecordsC') return <ConferenceRecords />
	if (pathName === '/conferenceRecordsDetailC') return <ConferenceRecordsDetailContent />

	const contextValue = {
		downloadGetProgress,
		downloadPostProgress,
		scheduleManagement,
		setScheduleManagement,
		setRouteDanger,
		currentMenu,
		setCurrentMenu,
		menuData,
	}

	const menuContextValue = {
		activeMenuList,
		activeUrl,
		onMenuItemClick,
		onTabChange,
		onTabRemove,
		CloseMenu
	}

	return <ScheduleManagementContext.Provider value={contextValue}>
		<MenuContext.Provider value={menuContextValue}>
			<>
				{
					CLIENT === 'Mobile' ?
						<Route component={MainPanel} /> :
						<>
							<AppBar {...props} {...{ menuData, currentMenu, setCurrentMenu, onMenuItemClick }} />
							<Switch>
								<Route path="/worktime/detail" component={WorkTimeDetail} />
								<Route path="/project/detail" component={ProjectListRouter} />
								<Route component={MainPanel} />
							</Switch>
						</>
				}
				{
					openInfo.type === 'skipTip' && <MessageBox header='提醒' confirm={setRouteSafe} cancel={() => close()}>
						您正处于编辑状态，如果跳转则会放弃当前编辑内容，您确认放弃吗？
					</MessageBox>
				}
			</>
		</MenuContext.Provider>
	</ScheduleManagementContext.Provider>

	async function downloadGetProgress(url, name, close = () => { }, loading = () => { }) {
		const date = uniqKeyFor()
		loading(true)
		const title = '导出'
		scheduleManager({ name, loading: true, date, title })
		await API1.downloadGet(url)
			.then(() => {
				scheduleManager({ name, loading: false, date, title })
				loading(false)
				close()
			})
			.catch(err => {
				loading(false)
				scheduleManager({ name, loading: false, error: true, errMsg: err._message, title, date })
			})
	}

	async function downloadPostProgress(url, data, name, close = () => { }, loading = () => { }) {
		const date = uniqKeyFor()
		loading(true)
		const title = '导出'
		scheduleManager({ name, loading: true, date, title })
		await API1.downloadPost(url, data)
			.then(() => {
				loading(false);
				close();
				scheduleManager({ name, loading: false, date, title })
			})
			.catch(err => {
				loading(false);
				Messager.show(err._message, { icon: 'error' })
				scheduleManager({ name, loading: false, error: true, errMsg: err._message, title, date })
			})
	}

	function scheduleManager(action) {
		newObject = _.assign({}, newObject, { [action.date]: action })
		setList(newObject)
	}
}

function AppBar(props) {
	const { history, allUserRes, menuData, currentMenu, setCurrentMenu, onMenuItemClick, location } = props

	const goHome = useCallback(() => {
		const findeData = _.find(menuData, o => o.funcCode === '0101')
		const homeData = _.map([findeData], r => (_.assign({}, r, {
			to: r.url,
			text: r.funcName,
			expanded: false,
		})))
		history.replace('/workplace')
		onMenuItemClick(_.head(homeData))
	}, [onMenuItemClick, menuData, history])

	const handleClick = useCallback(() => {
		logout({ location });
	}, [location])

	return <div className='appbar'>
		<div className='title' style={{ cursor: 'pointer' }} onClick={goHome}>
			<img src={LOGO} alt='' />
			RIMS 根网管理系统
		</div>
		<TopMenu {...{ menuData, history, currentMenu, setCurrentMenu }} />
		<div className='header-user-operation flex center-y'>
			<AppBarQuickCreate />
			<UserMenu logout={handleClick} {...{ allUserRes }} />
		</div>
	</div>
}

function useOpenInfo() {
	const [openInfo, setOpenInfo] = useState({});
	const close = useCallback((url) => {
		setOpenInfo({});
	}, []);
	return { openInfo, setOpenInfo, close };
}

function useListenBeforeUnload(isSafe) {
	useEffect(() => {
		window.addEventListener('beforeunload', handle)
		return () => window.removeEventListener('beforeunload', handle)

		function handle(e) {
			if (isSafe) return;
			e.preventDefault();
			e.stopPropagation();
			e.returnValue = '';
		}
	}, [isSafe])
}

function useBlockRoute(isSafe, setCurrentUrl) {
	const history = useHistory();
	useEffect(() => {
		return history.block((location) => {
			if (!isSafe) setCurrentUrl(location.pathname);
			return isSafe
		})
	}, [history, isSafe, setCurrentUrl])
}

function useSkipRoute({ isSafe, currentUrl, setCurrentUrl, close }) {
	const history = useHistory();

	useEffect(() => {
		if (!isSafe || !currentUrl) return undefined;
		history.replace(currentUrl);
		// history.push(currentUrl);
		setCurrentUrl();
		close();
	}, [history, isSafe, currentUrl, setCurrentUrl, close])
}

function useCurrentUrl(setOpenInfo) {
	const urlRef = useRef();

	const setCurrentUrl = useCallback((url) => {
		urlRef.current = url;
		if (_.size(_.split(url, '/')) === 2) setOpenInfo({ type: 'skipTip' });
		else setOpenInfo({})
	}, [setOpenInfo])

	return { currentUrl: urlRef.current, setCurrentUrl }
}

function useRouteSafeMode() {
	const [isSafe, setIsSafe] = useState(true);

	const setRouteSafe = useCallback(() => setIsSafe(true), []);

	const setRouteDanger = useCallback(() => setIsSafe(false), []);

	return { isSafe, setRouteSafe, setRouteDanger };
}

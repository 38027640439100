import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import Icon from "../../project_share/components/Icon";
import { Form, FormInput } from "rootnet-edit";
import './WorkFlowMgt.scss'
import _ from 'lodash'
import { Box } from "../common/commonComponent";
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import WorkFlowDetail from "./workFlowDetail/WorkFlowDetail";
import clsx from "clsx";
import { useGet } from "rootnet-biz/es/hooks";
import { Popover, Tree } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { strParams } from "../../utils/publicFun";
import convertOptions from "../common/ConvertOptions";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { TextIconBtn } from "../common/TextIconBtn";

function getColumns(props) {
    const { allUserRes, handlePopId, setHandlePopId, setCurrentInfo } = props
    return [
        { header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center', stretch: false },
        {
            header: '工作流名称', bind: 'name', width: 200, stretch: false,
            convert: (v, o) => {
                return <div className={'enter-field-wrap flex center-y'}>
                    <div className="enter-detail-field flex">
                        <div className="enter-detail-field-text" onClick={() => {
                            setCurrentInfo({
                                id: o.id,
                                mode: 'detail'
                            })
                        }}>
                            {v}
                        </div>
                    </div>
                    <Popover open={_.get(o, 'id') === handlePopId} onOpenChange={visible => {
                        if (visible) {
                            setHandlePopId(_.get(o, 'id'))
                        } else {
                            setHandlePopId(null)
                        }
                    }}
                        trigger={'click'} placement={'bottom'} destroyTooltipOnHide={true}
                        content={<div className={'work-flow-handle-popover flex-y'}>
                            <div className={'handle-item flex center-y'} onClick={() => {
                                setCurrentInfo({
                                    id: o.id,
                                    mode: 'edit'
                                })
                                setHandlePopId(null)
                            }}>
                                <Icon name={'bianjizhibiaolie1'} className={'handle-icon'} />
                                <div className="handle-text">编辑</div>
                            </div>
                            <div className={'handle-item flex center-y'}>
                                <Icon name={'wendang'} className={'handle-icon'} />
                                <div className="handle-text">复制</div>
                            </div>
                            <div className={'handle-item flex center-y'}>
                                <Icon name={'tianjiazhibiaolie'} className={'handle-icon version-icon'} />
                                <div className="handle-text">版本</div>
                            </div>
                            <div className={'handle-item del-item flex center-y'}>
                                <Icon name={'shanchu'} className={'handle-icon'} />
                                <div className="handle-text">删除</div>
                            </div>
                        </div>}
                    >
                        <span>
                            <Icon name={'gengduo'} className={'more-handle-icon'} />
                        </span>
                    </Popover>
                </div>
            }
        },
        { header: '说明', bind: 'memo', tooltip: true, width: 200 },
        { header: '应用类型', bind: 'systemFlag', tooltip: true, width: 80 },
        {
            header: '最后更新人', bind: 'updateUser', tooltip: true, width: 100,
            convert: (v, o) => convertOptions(v || _.get(o, 'createUser'), allUserRes, 'userName', 'userAccount')
        },
        {
            header: '最后更新时间', bind: 'updateTime', tooltip: true, width: 150,
            convert: (v, o) => dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v || _.get(o, 'createTime')))
        },
        {
            header: '创建时间', bind: 'createTime', tooltip: true, width: 150,
            convert: (v, o) => dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v || _.get(o, 'createTime')))
        },
    ]
}

function getOptions(options) {
    return {
        nilText: '-',
        emptyText: '-',
        resizable: true,
        columns: options,
        virtualized: false,
        autoFill: true,
    }
}

const INIT_PARAMS = {
    pageNum: 1,
    pageSize: 20,
    wtype: 'all'
}

function WorkFlowList(props) {
    const { currentInfo, setCurrentInfo, setListOptions } = props
    const [isFold, setIsFold] = useState(false)
    const [params, setParams] = useState(INIT_PARAMS)
    const { data: leftMenuData, doFetch: getLeftMenu } = useGet()
    const { data: listRes, doFetch: getList } = useGet()
    const { data: allUserRes } = useGet('/common/userinfo')
    const [handlePopId, setHandlePopId] = useState()
    const [focusId, setFocusId] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    useEffect(() => {
        const searchParams = {
            ...params,
            wtype: _.get(params, 'wtype') === 'all' ? null : _.get(params, 'wtype')
        }
        getList('/workflow/getWorkFlow?' + strParams(searchParams)).catch(err => {
            Messager.show(err.Message, { icon: 'error' })
        })
    }, [getList, params])

    const getMenuList = useCallback(() => {
        getLeftMenu('/workflow/getWorkflowType').catch(err => {
            Messager.show(err.Message, { icon: 'error' })
        })
    }, [getLeftMenu])

    useEffect(() => {
        getMenuList()
    }, [getMenuList])

    const leftMenuList = useMemo(() => {
        const total = _.sum(_.map(leftMenuData, x => _.toNumber(_.size(x.workflowLists)))) || 0
        return [
            {
                title: `所有的（${total}）`,
                key: 'all',
                children: _.map(leftMenuData, x => ({
                    title: `${x.pageName}（${_.size(x.workflowLists)}）`,
                    key: x.pageEngineId
                }))
            }
        ]
    }, [leftMenuData])

    const wtype = useMemo(() => {
        return _.get(params, 'wtype')
    }, [params])

    const { total, pageNum, pageSize, rows: list } = useMemo(() => (listRes || {}), [listRes]);

    useEffect(() => {
        setListOptions(_.map(list, x => ({ text: x.name, value: x.id })))
    }, [list, setListOptions])

    const onFormChange = useCallback((newObj, bind) => {
        setParams(oldObj => {
            return { ...oldObj, ...newObj }
        })
    }, [])

    const option = useMemo(() => {
        return getOptions(getColumns({ allUserRes, handlePopId, setHandlePopId, setCurrentInfo }))
    }, [allUserRes, handlePopId, setCurrentInfo])

    const onRowClick = useCallback((item) => {
        setFocusId(item.id)
    }, [])

    useEffect(() => {
        if (_.isNil(focusId)) return
        _.forEach(list, o => {
            return o._rowClass = o?.id === focusId ? 'select_row' : ''
        })
        forceUpdate()
    }, [focusId, list])

    const extra = useMemo(() => {
        return <TextIconBtn icon='tianjia' text='新建' onClick={() => setCurrentInfo({ mode: 'add' })} />
    }, [setCurrentInfo])

    return <div className={clsx('work-flow-list flex fill', { 'hidden-page': !_.isNil(currentInfo) })}>
        <div className="work-flow-mgt-left-panel flex" style={{ width: isFold ? 40 : 'unset' }}>
            {
                !isFold &&
                <div className="app-type-wrap flex-y">
                    <Tree
                        switcherIcon={<DownOutlined />}
                        defaultExpandedKeys={['all']}
                        selectedKeys={[wtype]}
                        treeData={leftMenuList}
                        onSelect={(selectedKeys) => {
                            if (_.isEmpty(selectedKeys)) return
                            const key = _.head(selectedKeys)
                            setParams(old => ({
                                ...old,
                                wtype: key
                            }))
                        }}
                    />
                </div>
            }
            <div className="fold-bar flex center" onClick={() => setIsFold(x => !x)} style={{ width: isFold ? 40 : 22 }}>
                <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{ transform: isFold ? 'none' : 'rotate(180deg)' }} />
            </div>
        </div>
        <div className="work-flow-mgt-right-panel flex-y">
            <div className="search-condition-wrap">
                <Form value={params} onChange={onFormChange}>
                    <FormInput bind={'searchText'} placeholder={'搜索关键字'} suffix={<Icon name={'sousuo1'} />} componentWidth={280} />
                </Form>
            </div>
            <Box className='flex-y x-card-singlegrid' title={'工作流列表'} data={list} extra={extra}>
                <DataGrid className='x-datagrid' option={option} data={list} onRowClick={onRowClick} />
                <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
                    onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
            </Box>
        </div>
    </div>
}

// const init = {
//     // id: '993531180051595264',
//     mode: 'add'
// }

function WorkFlowMgt(props) {
    const [currentInfo, setCurrentInfo] = useState()
    const [listOptions, setListOptions] = useState()

    return <>
        {
            currentInfo && <WorkFlowDetail {...{ currentInfo, setCurrentInfo, listOptions }} />
        }
        <WorkFlowList {...{ currentInfo, setCurrentInfo, setListOptions }} />
    </>
}

export default WorkFlowMgt;
import React, { useState, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Card, DataGrid, Dialog, FormInput, Select, MessageBox, Messager, InputGroup } from 'rootnet-ui';
import {Input} from 'rootnet-edit'
import { hasData } from 'rootnet-core/system'
import { Dateformat, isNil, N2 } from 'rootnet-core/format'
import { IconButton, Nodata } from '../../../components';
import { useGet } from '../../../utils/hook';
import api from './../../../base/task';
import gd from './../../../base/global'

// const mockUrl = 'http://dev.croot.com/mock/rims';
const mockUrl = '';

const modeMap = {
    'add': '新增',
    'edit': '修改',
    'delete': '删除'
};
const urlMap = {
    'add': `${mockUrl}/develop/project/authority/add`,
    'edit': `${mockUrl}/develop/project/authority/modify`,
    'delete': `${mockUrl}/develop/project/authority/delete`
};

const cannotDel = "已产生实际用时，不可删除！"

function CreateAuthorizedDialog(props) {
    const { availabel_stuff = [], options_stuff = [], options_type = [], mode, participantsMsg, handleConfirm, ...rest } = props;
    const groupInput = useRef(null);
    const { planUseTime, actualUseTime, userId, jobType: initJobType, factor: initFactor } = participantsMsg;
    const [participantId, setParticipantId] = useState(userId);
    const [time, setTime] = useState(planUseTime);
    const [factor, setFactor] = useState(initJobType);
    const [jobType, setJobType] = useState(initFactor);
    const standardTime = useMemo(() =>
        (!isNil(factor)) && (!isNil(time)) ? (N2(factor * time)) + 'h' : '-'
        , [factor, time])
    return (
        <>
            <Dialog className="create-autorized-dialog" confirm={confirm} {...rest} >
                <InputGroup ref={groupInput}>
                    <FormInput
                        label="项目参与人:"
                        required
                        horizontal
                        name='participant'
                        component={Select}
                        search
                        clear
                        disabled={mode === 'edit' ? true : false}
                        options={mode === 'edit' ? options_stuff : availabel_stuff}
                        value={mode === 'add' ? participantId : userId}
                        className={mode === 'edit' ? 'edit' : ''}
                        onChange={(v, o) => {
                            setParticipantId(_.get(o, 'value'))
                            setFactor(_.get(o, 'factor'))
                        }
                        }
                    />
                    <FormInput
                        label="职级系数:"
                        required
                        horizontal
                        name='grid'
                        component={Input}
                        className={mode === 'edit' ? 'edit' : ''}
                        search
                        clear
                        disabled
                        value={factor}
                    />
                    <FormInput
                        label="授权类型:"
                        required
                        horizontal
                        name='authType'
                        component={Select}
                        search
                        clear
                        options={options_type}
                        value={mode === 'add' ? null : jobType}
                        onChange={(v, o) => {
                            setJobType(_.get(o, 'value'))
                        }
                        }
                    />
                    <FormInput
                        label="计划用时(H):"
                        required horizontal
                        component={Input}
                        name='planTime'
                        type="number"
                        digit={0}
                        placeholder={'请输入'}
                        min={mode === 'add' ? 0 : actualUseTime}
                        value={mode === 'add' ? null : time}
                        onChange={(v, o) => setTime(v || '')} />
                    <div className='standardTime'><span>标准工时：</span><span>{standardTime}</span></div>
                </InputGroup>
            </Dialog>;
        </>
    )

    function confirm() {
        if (!groupInput.current.validate()) return;
        handleConfirm({ userId: participantId, planUseTime: time, jobType })
    }
};

const colConfig = [
    { header: "#", convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: "项目参与人", bind: "userName", width: 120, align: "right", },
    { header: "授权类型", bind: "jobTypeName", width: 120, align: "right", },
    { header: "计划用时(H)", bind: "planUseTime", width: 120, align: "right", },
    { header: "计划工时(H)", bind: "planUseTime", width: 120, align: "right", convert: (v, o) => o.factor ? v * o.factor : '' },
    { header: "实际用时(H)", bind: "actualUseTime", width: 160, align: "right", sortable: true },
    { header: "实际工时(H)", bind: "actualUseTime", width: 160, align: "right", sortable: true, convert: (v, o) => o.factor ? v * o.factor : '' },
    { header: "授权人", bind: "authorizerName", width: 120, align: 'center', sortable: true },
    { header: "授权时间", bind: "authorizerTime", width: 200, align: "right", convert: (v) => Dateformat(v, 'hr'), sortable: true }
]

const urls = [
    `${mockUrl}/common/userinfo`,
    // `${mockUrl}/worktime/jobtype/list?isNeedAuthFlag=Y`
]

function Authorized(props) {
    const currentUser = _.get(gd, '[User][userMainId]');
    const { projectType, principal, id } = props;
    const hasRight = currentUser === principal;
    const urldata = `${mockUrl}/develop/project/authority/list?projectId=${id}`;
    const { data, loading, error, doFetch } = useGet(urldata);
    const { data: stuff_Data, loading: stuff_loading, error: stuff_error } = useGet(urls);

    const [listData, participantList] = useMemo(() => {
        const d1 = data ? data : null;
        const participantList = data ? data.map(e => e.userId) : [];
        return [d1, participantList]
    }, [data]);

    const [options_stuff, availabel_stuff, options_type] = useMemo(() => {
        const d1 = stuff_Data ? _.map(stuff_Data[0], o => { return { key: o.userAccount, text: `${o.userAccount}-${o.userName}`, value: o.userAccount, factor: o.factor } }) : [];
        const d2 = d1.filter(e => !(participantList.includes(e.key)));
        const temp = [
            {
                "id": 61,
                "pid": 15,
                "name": "项目计划",
                "utFlag": "Y",
                "isNeedAuthFlag": "Y"
            }
        ]
        const d3 = stuff_Data ? _.map(temp, o => { return { key: o.id, text: o.name, value: o.id } }) : [];
        // const d3 = stuff_Data ? _.map(stuff_Data[1], o => { return { key: o.id, text: o.name, value: o.id } }) : [];
        return [d1, d2, d3]
    }, [participantList, stuff_Data])
    const [showDialog, setShowDialog] = useState(false);
    const [participantsMsg, setParticipantsMsg] = useState({});
    const [message, setMessage] = useState(false)
    const extra = useMemo(() => hasRight ? [<IconButton key="add" icon="zengjia" circle onClick={() => setShowDialog('add')}></IconButton>] : '', [hasRight]);
    const datagrid_option = {
        columns: [...colConfig],
        selection: true,
        sort: {
            column: 'authorizerTime',
            direction: 'desc'
        }
    };
    const options = optFor(datagrid_option, hasRight);
    const closeMessage = () => setMessage(false)
    return (
        <div className='flex-y authorized'>
            <Card title="授权明细" style={{ flex: 1, overflow: 'hidden' }} extra={extra} loading={loading || stuff_loading} error={error || stuff_error}>
                {hasData(listData) && <DataGrid option={options} data={listData}></DataGrid>}
                {showDialog && <CreateAuthorizedDialog
                    header={modeMap[showDialog]}
                    confirmButtonText='保存'
                    mode={showDialog}
                    participantsMsg={hasData(participantsMsg) ? participantsMsg : {}}
                    options_stuff={options_stuff}
                    availabel_stuff={availabel_stuff}
                    cancel={() => setShowDialog(false)}
                    style={{ width: 644, height: 246 }}
                    handleConfirm={handleConfirm}
                    options_type={options_type}
                />}
                {!hasData(listData) && <Nodata />}
            </Card>
            {message && (message === cannotDel) && <MessageBox header="提示" confirm={closeMessage} cancel={closeMessage} className={'content-center-dialog'}>{cannotDel}</MessageBox>}
            {_.isObject(message) && <MessageBox header="提示" confirm={() => { deleteItem(); closeMessage() }} cancel={closeMessage} className={'content-center-dialog'}>{`确定要删除“${message.userName}”的授权`}</MessageBox>}

        </div>

    )

    function fresh() {
        doFetch(urldata)
    }

    function deleteItem() {
        api.post(urlMap['delete'], { id: message.id }).then(res => {
            const { msg } = res;
            Messager.show(msg, { icon: 'success' });
            fresh();
        }
        ).catch(error => Messager.show(error._message, { icon: 'error' }))
    }

    function handleConfirm(value) {
        let params = null;
        if (showDialog === 'add') params = { projectid: id, projectType, ...value }
        if (showDialog === 'edit') { const { id } = participantsMsg; params = { id, ...value } }
        setShowDialog(false)
        api.post(urlMap[showDialog], params).then((res) => {
            const { msg } = res;
            Messager.show(msg, { icon: 'success' })
            fresh();
        }).catch((err) => Messager.show(err._message, { icon: 'error' }))
    }

    function optFor(option, flag) {
        const operations = {
            header: "操作", align: "center", width: 160,
            convert: (v, o) => {
                return <div className="operations">
                    <span onClick={() => { setShowDialog('edit'); setParticipantsMsg(o); }}>修改</span>
                    <span onClick={() => { if (o.actualUseTime) { setMessage(cannotDel) } else setMessage(o) }}>删除</span>
                </div>;
            }
        };
        if (flag) option.columns.splice(1, 0, operations);
        return option
    }

}

export default Authorized
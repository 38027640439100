import React, {useCallback, useMemo, useState} from 'react';
import './ConferenceRecordsDetailRightPanel.scss'
import _ from "lodash";
import {DatePicker, Display, Form, FormInput, Input, Select, TextArea} from "rootnet-edit";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertOptions from "../../../../common/ConvertOptions";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {Tooltip} from "antd";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import moment from 'moment';
import {Messager} from 'rootnet-ui'
import { getTreeOpt } from '../../method';
import Icon from "../../../../../components/Icon";
import clsx from "clsx";
import DisplaySelect from '../../../../common/displaySelect/DisplaySelect';

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={186} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=sConferenceType',
    'common/globalconst?globalConst=AuditPassFlag',
]

const optionsUrls = [
    '/UserSetting/getUniversalInterfaces?code=t_Custid&codeName=t_Custname&tableName=bug_tcust',
]

function ConferenceRecordsDetailRightPanel(props) {
    const {isDetail, allUserRes, editFormData, setEditFormData, infoError, setInfoError, auditError, setAuditError, needAudit, emptyAuditPassReason} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: allOptionsRes } = useGet(optionsUrls)
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [auditInfoExpand, setAuditInfoExpand] = useState(true)
    const [createInfoExpand, setCreateInfoExpand] = useState(true)

    const [sConferenceTypeOptions, auditPassFlagOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => getTreeOpt(x))
    },[globalConstOptionsRes])

    const [custOptions] = useMemo(()=>{
        if(_.isEmpty(allOptionsRes)) return []
        const [d1] = allOptionsRes
        const custOptions = _.map(d1, x => ({label: x.displayName, text: x.displayName, value: x.interiorId, tag: x.interiorId}))
        return [custOptions]
    },[allOptionsRes])

    const sConferenceTypeOptDefault = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        const [d1] = globalConstOptionsRes
        return _.map(d1,x=>({ text: x.displayName, value: x.interiorId}))
    },[globalConstOptionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            if(bind === 'sConferenceType'){
                if(_.includes(['S','DS','REQ','TC'],cloneObj['sConferenceType'])){
                    cloneObj['scustomer'] = 'NBGJ'
                }
            }
            if(_.includes(['dOccurTimeBegin','dOccurTimeEnd'], bind)){
                if(handleTime(_.get(cloneObj,'dOccurTimeBegin')) > handleTime(_.get(cloneObj,'dOccurTimeEnd'))){
                    if(bind === 'dOccurTimeBegin'){
                        cloneObj['dOccurTimeBegin'] = oldObj['dOccurTimeBegin']
                        Messager.show('开始时间不能晚于结束时间')
                    }else{
                        cloneObj['dOccurTimeEnd'] = oldObj['dOccurTimeEnd']
                        Messager.show('结束时间不能早于开始时间')
                    }
                }
            }
            return cloneObj
        })

        function handleTime(timeStr){
            return moment(toDate.str14ToDate(timeStr)).format('HH:mm:ss')
        }
    },[setEditFormData])

    return <div className={'conference-records-detail-right-panel flex-y'}>
        <div className="area-wrap base-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: baseInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setBaseInfoExpand(v => !v)}/>
                基本信息
            </div>
            <div className="area-content-wrap" style={{height: baseInfoExpand ? '' : 0}}>
                <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({},x,v))}>
                    <div className={'flex center-y'}>
                        <HFormInput label={'会议分类'} tree bind={'sConferenceType'} component={isDetail? Display: DisplaySelect} required options={sConferenceTypeOptions} convert={v => convertOptions(v,sConferenceTypeOptDefault)}/>
                        <Tooltip title={'IPD研发流程中的评审会议活动请到【评审管理】记录，在此记录无效！'}>
                            <span className={'cursor-pointer'}>
                                <Icon name={'bangzhu'} style={{fontSize: 14, color: '#5477FF'}}/>
                            </span>
                        </Tooltip>
                    </div>
                    <HFormInput label={'会议日期'} bind={'dOccurTime'} component={isDetail? Display:DatePicker} clear required
                                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                                convert={v => {
                                    if(_.isNil(v)) return '-'
                                    if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD', v) || '-'
                                }}
                    />
                    <div className={'flex center-y'}>
                        <HFormInput label={'会议时间'} bind={'dOccurTimeBegin'} component={isDetail? Display:DatePicker} clear required mode='time'
                                    showSeconds={false} componentWidth={isDetail?36:75} placeholder={'开始'}
                                    bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                                    convert={v => {
                                        if(_.isNil(v)) return '-'
                                        if(_.isString(v)) return moment(toDate.str14ToDate(v)).format('HH:mm')
                                        return moment(v).format('HH:mm') || '-'
                                    }}
                        />
                        至
                        <HFormInput label={''} bind={'dOccurTimeEnd'} component={isDetail? Display:DatePicker} clear required mode='time'
                                    showSeconds={false} componentWidth={isDetail?36:75} placeholder={'结束'}
                                    bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                                    convert={v => {
                                        if(_.isNil(v)) return '-'
                                        if(_.isString(v)) return moment(toDate.str14ToDate(v)).format('HH:mm')
                                        return moment(v).format('HH:mm') || '-'
                                    }}
                        />
                    </div>
                    <HFormInput label={'会议客户'} bind={'scustomer'} component={isDetail? Display: Select} required search options={custOptions} convert={v => convertOptions(v, custOptions)}/>
                    <HFormInput label={'会议主持'} bind={'sCaption'} component={isDetail? Display: Input} className={'long-text'}/>
                    <HFormInput label={'本方参与人'} bind={'sOurPerformer'} multiple required component={isDetail? Display: UserSelect}
                                convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, allUserRes,'userName','userAccount')),'、')}>
                                    <div className={'ellipsis-line'}>
                                        {_.join(_.map(v, x => convertOptions(x, allUserRes,'userName','userAccount')),'、')}
                                    </div>
                                </Tooltip>}/>
                    <HFormInput label={'对方参与人'} bind={'sCustomerPerformer'} component={isDetail? Display: Input} className={'long-text'}/>
                    <HFormInput label={'会议地点'} bind={'sOccurPlace'} component={isDetail? Display: Input}/>
                    {
                        !isDetail &&
                        <HFormInput label={'会议议题'} bind={'topic'} component={TextArea} placeholder={isDetail?'':' 请输入'} maxLength={2000}
                                    readOnly={isDetail} counter={!isDetail} componentClass={`memo-textarea ${isDetail? 'memo-textarea-detail': ''}`}/>
                    }
                    <div className={'flex textarea-detail-wrap'}>
                        {
                            isDetail &&
                            <HFormInput label={'会议议题'} bind={''} componentWidth={0} component={Display} convert={v => ""}/>
                        }
                        {
                            isDetail && <div className={'flex textarea-detail-text'}>
                                {_.get(editFormData,'topic')}
                            </div>
                        }
                    </div>
                </Form>
            </div>
        </div>
        {
            needAudit &&
            <div className="area-wrap audit-info-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{transform: auditInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setAuditInfoExpand(v => !v)}/>
                    评审信息
                </div>
                <div className="area-content-wrap" style={{height: auditInfoExpand ? '' : 0}}>
                    <Form value={editFormData} onChange={changeForm} error={auditError} onError={v => setAuditError(x => _.assign({},x,v))}>
                        <HFormInput label={'评审状态'} bind={'auditPassFlag'} component={isDetail? Display: Select} required search options={auditPassFlagOptions} convert={v => convertOptions(v, auditPassFlagOptions)}/>
                        <HFormInput label={'评审决策人'} bind={'auditor'} required clear component={isDetail? Display: UserSelect}
                                    convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                        {/*{*/}
                        {/*    !isDetail &&*/}
                        {/*    <HFormInput label={'关联需求'} bind={'storyId'} component={TextArea} placeholder={isDetail?'':' 请输入'} maxLength={100}*/}
                        {/*                readOnly={isDetail} counter={!isDetail} componentClass={clsx(`memo-textarea`,{'memo-textarea-detail': isDetail} )}/>*/}
                        {/*}*/}
                        {/*<div className={'flex textarea-detail-wrap'}>*/}
                        {/*    {*/}
                        {/*        isDetail &&*/}
                        {/*        <HFormInput label={'关联需求'} bind={''} componentWidth={0} component={Display} convert={() => ""}/>*/}
                        {/*    }*/}
                        {/*    {*/}
                        {/*        isDetail && <div className={'flex textarea-detail-text'}>*/}
                        {/*            {_.get(editFormData,'storyId')}*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*</div>*/}
                        {
                            !isDetail &&
                            <HFormInput label={'评审理由'} bind={'auditPassReason'} component={TextArea} placeholder={isDetail?'':' 请输入'} maxLength={500} required
                                        readOnly={isDetail} counter={!isDetail} componentClass={clsx(`memo-textarea`,{'memo-textarea-detail': isDetail,'error':emptyAuditPassReason} )}/>
                        }
                        <div className={'flex textarea-detail-wrap'}>
                            {
                                isDetail &&
                                <HFormInput label={'评审理由'} bind={''} componentWidth={0} component={Display} convert={() => ""} required/>
                            }
                            {
                                isDetail && <div className={'flex textarea-detail-text'}>
                                    {_.get(editFormData,'auditPassReason')}
                                </div>
                            }
                        </div>
                    </Form>
                </div>
            </div>
        }
        <div className="area-wrap create-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: createInfoExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setCreateInfoExpand(v => !v)}/>
                创建信息
            </div>
            <div className="area-content-wrap" style={{height: createInfoExpand ? '' : 0}}>
                <Form value={editFormData}>
                    <HFormInput label={'记录人'} bind={'sAddPerson'} component={Display}
                                convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                    <HFormInput label={'记录时间'} bind={'dAddTime'} component={Display}
                                convert={v => {
                                    if(_.isNil(v)) return '-'
                                    if(_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                                }}/>
                </Form>
            </div>
        </div>
    </div>
}

export default ConferenceRecordsDetailRightPanel;
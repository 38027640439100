import React from 'react'
import _ from 'lodash'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import BubbleBox from '../../../../common/BubbleBox'

export default function SelectedOperation(props) {
  const { selectedList, copyList, setAction, setSelectedList, SettingPriority, isAC, delData } = props
  return (
    <div className='multiple-header' >
      <div className='header-left' >
        <div>已选中<span>{_.size(selectedList)}</span>项</div>
        <div><BubbleBox text='设置优先级'><SettingPriority /></BubbleBox></div>
        <div><TextIconBtn onClick={copyList} icon='tongjihuizong' text='复制' /></div>
        <div><TextIconBtn onClick={() => isAC ? delData() : setAction('delete')} icon='shanchu' text='删除' /></div>
      </div>
      <div className='header-right'>
        <div onClick={() => setSelectedList([])}>取消选择</div>
      </div>
    </div>
  )
}

import React, { useCallback, useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog } from 'rootnet-ui'
import { dateFormat } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, DatePicker } from 'rootnet-edit'
import DepartmentSelect from '../../../../components/departmentSelect'
import { ScheduleManagementContext } from '../../../../../common/Context'
import { strParams } from '../../../../../../utils/publicFun'
import './index.scss'

const EXPORT_URL = '/humanManpower/export'

export default function ManpowerExportLog(props) {
  const { close, exportTypeOpt, exportParams } = props
  const [params, setParams] = useState(exportParams)
  const [error, setError] = useState()
  const [continuous, setContinuous] = useState(false)
  const { downloadGetProgress } = useContext(ScheduleManagementContext)

  const confirm = useCallback(() => {
    if (continuous) return
    const { displayMonth } = params
    const getParams = _.assign({}, _.omit(params, ['displayMonth']), {
      beginDate: dateFormat('YYYY-MM-DD', _.get(displayMonth, 'begin')),
      endDate: dateFormat('YYYY-MM-DD', _.get(displayMonth, 'end')),
    })
    downloadGetProgress(`${EXPORT_URL}?${strParams(getParams)}`, '人力管道预估', close, setContinuous)
  }, [downloadGetProgress, params, close, continuous])

  const canSubmit = useMemo(() => {
    return _.some(_.values(error), x => x)
  }, [error])

  return (
    <Dialog
      header='导出'
      confirm={confirm}
      cancel={close}
      className='manpower-export-log'
      confirmButtonDisabled={canSubmit}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput required horizontal label='类型' bind='type' component={Select} labelWidth={50} options={exportTypeOpt} />
        <FormInput required horizontal label='期间' bind='displayMonth' component={DatePicker} labelWidth={50} range mode='month' />
        <FormInput required horizontal label='部门' bind='departmentList' component={DepartmentSelect} labelWidth={50} multiple componentStyle={{ height: 32, overflowY: 'auto' }} />
      </Form>
    </Dialog>
  )
}

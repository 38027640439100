import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from "lodash";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import convertOptions from "../../common/ConvertOptions";
import {Icon} from "../../../components";
import {Drawer, Tooltip} from 'rootnet-ui'
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {compose} from "../../../utils/publicFun";
import './FieldDefinitionLogDrawer.scss'

const {str14ToDate} = toDate
const formatTime = compose(dateFormat('YYYY-MM-DD HH:MM:SS'),str14ToDate)

function FieldDefinitionLogDrawer(props) {
    const {open, cancel,logDetail,options} = props
    const [logList, setLogList] = useState([])

    const handleOption = useMemo(() => options.columns.slice(0,-1),[options])

    useEffect(()=>{
        setLogList(_.map(logDetail, x => ({...x, _expand: true})))
    },[logDetail])

    const onClickExpandIcon = useCallback((index)=>{
        setLogList(value => _.map(value, (x,i) => {
            return i === index? {...x, _expand: !x._expand}: x
        }))
    },[])

    return <Drawer open={open} cancel={cancel} onOutsideClick={cancel} header={'日志信息'} cancelButtonVisible={false} confirmButtonVisible={false}
                   className={'field-definition-log-drawer'} contentClassName={'field-definition-log-drawer-content flex-y'} demount={true}>
        {
            _.map(logList, (item, index) => <div className={'timeline-item flex'} key={uniqKeyFor()}>
                <div className="time">{formatTime(item.time)}</div>
                <div className="line-group flex-y center-x">
                    <div className="circle" style={{borderColor: '#5477FF'}}/>
                    <div className="line"/>
                </div>
                <div className="fold-icon">
                    <Icon name={item._expand?'biaogeshouqi':'biaogezhankai'} onClick={()=>onClickExpandIcon(index)}/>
                </div>
                <div className="log-detail flex-y" style={{height: item._expand? 'auto': 25}}>
                    <div className={'log-line'}>
                        <LogItem label={'操作人'} text={item.optId}/>
                        <LogItem label={'操作动作'} text={item.action}/>
                    </div>
                    {
                        _.map(handleOption, x =>
                                <LogItem key={uniqKeyFor()} label={x.header} style={{display:compareBind(item,x.bind)?'none':''}}
                        beforeText={_.get(x,'options') && _.get(item.before,x.bind)?convertOptions(_.get(item.before,x.bind),x.options):_.get(item.before,x.bind)}
                        afterText={_.get(x,'options') && _.get(item.after,x.bind)?convertOptions(_.get(item.after,x.bind),x.options):_.get(item.after,x.bind)}/>)
                    }
                </div>
            </div>)
        }
    </Drawer>
}

function compareBind(item,bind){
    return _.get(item.before,bind) === _.get(item.after,bind)
}

function LogItem(props){
    const {label, style, beforeText, afterText,text} = props
    return <div className="log-item flex" style={style}>
        <div className="label">{label}:</div>
        <div className="text-area flex">
            {
                text && <LogText text={text}/>
            }
            {
                !textIsNil(beforeText) &&
                <LogText text={beforeText} textStyle={{color:'red',backgroundColor:'#FDE6E6',textDecoration:'line-through'}}/>
            }
            {
                _.every([beforeText,afterText], v => !textIsNil(v)) && <span className={'to-icon'}> > </span>
            }
            {
                !textIsNil(afterText) &&
                <LogText text={afterText} textStyle={{color:'#3FBD8E',backgroundColor:'#E9F7F2'}}/>
            }
        </div>
    </div>
}

function textIsNil(text){
    return _.isNil(text) || ( _.isString(text) && (text.replace(/\s*/g,"") === ''))
}

function LogText(props){
    const {text, textStyle} = props
    return text && _.toString(text.length) > 12?
        <Tooltip title={text}>
            <div className="text" style={textStyle}>{text}</div>
        </Tooltip>
        :<div className="text" style={textStyle}>{text}</div>
}

export default FieldDefinitionLogDrawer;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, DataGrid} from 'rootnet-ui'
import {Form, FormInput, Select} from "rootnet-edit";
import '../common/SystemMgtCommon.scss'
import {Icon} from "../../../components";
import _ from 'lodash'
import useToDetail from "../../commonV2/hooks/useToDetail";
import {Route, Switch} from "react-router-dom";
import SystemDetail from "./SystemDetail";
import {useGet} from "../../../utils/hook";
import {pathSearchFor, strParams} from "../../../utils/publicFun";
import DisplayFormItem from "../common/DisplayFormItem";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import {Box} from "../../common/commonComponent";
import OperationList from "../../../project_share/components/OperationList";
import {TextIconBtn} from "../../../project_share/components";
import Remark from "../common/Remark";
import gd from "../../../base/global"

export default function Router() {
    return <Switch>
        <Route path='/appSystemMgt' component={SystemMgt} exact/>
        <Route path='/appSystemMgt/detail' component={SystemDetail}/>
    </Switch>
}

const getColumns = (props) => {
    const {toDetailWithParams} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '应用系统名称', bind: 'systemName',sortable: true,width: 150, tooltip: true},
        {header: '系统负责人', bind: 'systemManager',sortable: true,width: 100},
        {header: '系统说明', bind: 'directions',sortable: true,width: 300, tooltip: true},
        {header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation}
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick: () => toDetailWithParams({systemId: o.systemId,systemName: o.systemName})
            },
        ];
        return <OperationList options={operateOption}/>;
    }
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    virtualized: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
});

const INIT_PARAMS = {
    systemName: null,
    systemManager: []
}

function transformHistoryParams(params, key){
    let value = _.get(params,key)
    return value?value.split(','):null
}

function getTransformObj(params, keyList){
    const newObj = {}
    _.forEach(keyList, x => newObj[x] = transformHistoryParams(params, x))
    return newObj
}
const optionsUrls = [
    '/common/systemManager'
]

function SystemMgt(props) {
    const {history} = props
    const searchParams = pathSearchFor(history.location.search)
    const handleHistoryParams = useMemo(()=>{
        const newObj = getTransformObj(searchParams,['state','systemManager'])
        return _.assign({},searchParams,newObj)
    },[searchParams])
    const [params, setParams] = useState(_.isEmpty(searchParams)?INIT_PARAMS:handleHistoryParams)
    const toDetail = useToDetail('/appSystemMgt/detail')
    const {data: list, doFetch: getList, loading, error} = useGet()
    const [isCard, setIsCard] = useState(true)
    const {data: optionsRes} = useGet(optionsUrls)

    const [ systemManagerOps] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes
        return [
            _.map(d1, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))
        ]
    },[optionsRes])

    const canSearch = useMemo(()=>{
        if(_.isEmpty(systemManagerOps)) return false
        const userAccount = _.get(gd, '_user.operator_id');
        if(_.find(systemManagerOps, x => x.value === userAccount)){
            setParams(x => ({...x, systemManager: [userAccount]}))
        }else{
            setParams(x => ({...x, systemManager: []}))
        }
        return true
    },[systemManagerOps])

    useEffect(()=>{
        if(!canSearch) return
        getList('/system/list?'+strParams(params))
    } ,[getList,params,canSearch])

    const toDetailWithParams = useCallback((pathParams)=>{
        const itemInfo = _.mapKeys(params, (v, k) => 'item_' + k)
        toDetail({...pathParams,...itemInfo})
    },[params,toDetail])

    const option = useMemo(()=>{
        return getTableOption(getColumns({searchParams,toDetailWithParams}))
    },[searchParams,toDetailWithParams])

    const cardExtra = useMemo(()=>{
        return <div className='flex card-extra center-y'>
            <TextIconBtn text='新建系统' icon={'tianjia'}/>
            <TextIconBtn text={`切换${isCard?'表格':'卡片'}`} icon={isCard?'biaoge':'tubiao'} onClick={()=>setIsCard(x => !x)}/>
        </div>
    },[isCard])

    return  <div className='system-mgt fill flex-y system-mgt-common'>
        <Options search={setParams} systemManagerOps={systemManagerOps}/>
        <Box title='应用系统列表' className='flex-y x-card-singlegrid' data={list} extra={cardExtra}
             style={{flex: isCard?0:1}} loading={loading} error={error}>
            {
                !isCard &&
                <DataGrid data={list} option={option}/>
            }
        </Box>
        {
            isCard &&
            <div className="card-group flex">
                {
                    _.map(list, (item, i) => {
                        return <div className="card-item card-detail flex-y" key={i}>
                            <div className="card-header flex center-y">
                                <div className="card-title">{item.systemName}</div>
                                <Icon name='shanchu2' className='del-icon'/>
                            </div>
                            <DisplayForm value={item}/>
                            <div className="bottom-text flex center" onClick={()=>toDetailWithParams({systemId: item.systemId,systemName: item.systemName})}>
                                系统详情
                            </div>
                        </div>
                    }).concat(new Array(4).fill(0).map(() => (<div key={uniqKeyFor()} className='empty-item'/> )))
                }
            </div>
        }
    </div>
}

function OneLineDisplayForm(props){
    return <DisplayFormItem textStyle={{width: 260}} {...props}/>
}

function DisplayForm(props){
    const {value} = props
    return <div className='display-form flex-y'>
        <OneLineDisplayForm text={value.systemManager} label='负责人'/>
        <Remark label='系统说明' text={value.directions} twoLine/>
    </div>
}

function Options(props){
    const {search, systemManagerOps} = props
    const [params, setParams] = useState(INIT_PARAMS)

    useEffect(()=>{
        if(_.isEmpty(systemManagerOps)) return
        const userAccount = _.get(gd, '_user.operator_id');
        if(_.find(systemManagerOps, x => x.value === userAccount)){
            setParams(x => ({...x, systemManager: [userAccount]}))
        }else{
            setParams(x => ({...x, systemManager: []}))
        }
    },[systemManagerOps])

    return <div className='c-options'>
        <Form onChange={setParams} value={params}>
            <FormInput label="应用系统名称" bind="systemName"/>
            <FormInput label="系统负责人" component={Select} clear multiple bind="systemManager" search options={systemManagerOps}/>
        </Form>
        <Button primary onClick={() => search({...params})} className='btn'>查询</Button>
        <Button text onClick={() => {
            const userAccount = _.get(gd, '_user.operator_id');
            const initParams = _.find(systemManagerOps, x => x.value === userAccount) ? {...INIT_PARAMS,systemManager:[userAccount]}:INIT_PARAMS
            setParams({...initParams})
            search({...initParams})
        }} className='btn reset-btn'>重置</Button>
    </div>
}
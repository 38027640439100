import React, { useState, useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { Dialog, DataGrid, Pagination, Button } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import convertOptions from '../../../../../common/ConvertOptions'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import DateSelector from '../../../../../common/dateSelector/DateSelector'
import RequirementDetailDialog from '../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import IssueDetailDialog from '../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import './index.scss'

const { str14ToDate } = toDate

const SEARCH_URL = '/projectAuditLog/queryProjectAuditLogs'
const GLOBAL_CONST_URLS = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=projectAuditLogAuditFlag'
]

const TYPE_COLOR = {
  'req': '#5477FF',
  'dev': '#5477FF',
  'task': '#5C595B',
  'issue': '#FF5454',
  'defect': '#EC641C',
}

const getColumns = (props) => {
  const { setShowDetail, flagOpt } = props
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: 'ID', bind: 'number', sortable: true, width: 180, tooltip: true },
    { header: '标题', bind: 'title', sortable: true, width: 240, convert: getTitle },
    { header: '在项目中', bind: 'isProjectIdFlag', sortable: true, width: 80 },
    // { header: '状态', bind: 'status', sortable: true, width: 110, tooltip: true, convert: getStatus, csvConvert: csvGetStatus },
    // { header: '是否在版本', bind: 'inRelease', sortable: true, width: 130, tooltip: true },
    { header: '操作动作', bind: 'optFlag', sortable: true, width: 90, tooltip: true, },
    { header: '操作人', bind: 'operUser', sortable: true, width: 90, tooltip: true, },
    { header: '操作时间', bind: 'optTime', sortable: true, width: 140, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)), csvConvert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)) },
    { header: '备注', bind: 'optMemo', sortable: true, width: 200, tooltip: true },
    { header: '审核人', bind: 'auditUser', sortable: true, width: 90, tooltip: true, },
    { header: '审核状态', bind: 'auditFlag', sortable: true, width: 80, tooltip: true, convert: v => convertOptions(v, flagOpt), csvConvert: v => convertOptions(v, flagOpt) },
    { header: '审核时间', bind: 'auditTime', sortable: true, width: 140, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)), csvConvert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)) },
    { header: '审核备注', bind: 'auditMemo', sortable: true, width: 200, tooltip: true, },
  ]
  function getTitle(v, o) {
    return <div className='flex title-hidden'>
      <div className='type-color' style={{ backgroundColor: TYPE_COLOR[o.businessType], marginLeft: (o.businessType === 'req' || o.businessType === 'dev') ? 2 : 0 }}>
        {_.toUpper(o.businessType) === 'DEFECT' ? 'BUG' : _.toUpper(o.businessType)}
      </div>
      <Tooltip title={v}>
        <div className='text' onClick={() => {
          if (o.businessType === 'req') {
            setShowDetail({
              type: 'REQ',
              id: o.businessId
            })
          }
          else if (o.businessType === 'issue') {
            setShowDetail({
              type: 'ISSUE',
              id: o.businessId
            })
          }
        }}>
          {v}
        </div>
      </Tooltip>
    </div>
  }
}

const getOption = (columns) => ({
  columns,
  autoFill: true,
  resizable: true,
  virtualized: false,
  fixedLeft: 2,
  nilText: '-',
  emptyText: '-',
})

export default function ProjectChangeHistory(props) {
  const { close, projectId } = props
  const [params, setParams] = useState({ pageNum: 1, pageSize: 20 })
  const [pageSize, setPageSize] = useState(20)
  const [showDetail, setShowDetail] = useState()
  const { data: optionsRes } = useGet(GLOBAL_CONST_URLS)
  const { data, doFetch, loading } = usePost()

  const { total, pageNum, rows: list } = useMemo(() => (data || {}), [data]);

  const [flagOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    return _.map(optionsRes, o => convertGlobalConstOptions(o))
  }, [optionsRes])

  const option = useMemo(() => {
    return getOption(getColumns({ setShowDetail, flagOpt }))
  }, [flagOpt])

  const refresh = useCallback((par = {}) => {
    doFetch(SEARCH_URL, par)
  }, [doFetch])

  const search = useCallback((o) => {
    const time = _.isNil(_.get(o, 'time')) ? {} : { beginDate: o.time.begin, endDate: o.time.end }
    refresh(_.assign({}, time, _.omit(o, ['time']), { projectId, pageSize }))
    setParams(o)
  }, [refresh, projectId, pageSize])

  useEffect(() => {
    refresh({ projectId, pageNum: 1, pageSize: 20 })
  }, [refresh, projectId])

  return (
    <>
      <Dialog
        header='项目变更历史'
        cancel={close}
        className='project-change-history'
        footerVisible={false}
      >
        <div className='project-change-history-content'>
          <Option {...{ params, search }} />
          <DataGrid loading={loading} data={list} option={option} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              search(_.assign({}, params, { pageNum, pageSize }))
              // setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </div>
      </Dialog>
      {
        _.get(showDetail, 'type') === 'REQ' &&
        <RequirementDetailDialog
          close={() => setShowDetail(null)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
        />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
        />
      }
    </>
  )
}

function Option(props) {
  const { params, search } = props
  const [parameter, setParameter] = useState(params)
  return <div className='x rims_options'>
    <Form value={parameter} onChange={setParameter}>
      <FormInput label='操作时间' bind='time' component={DateSelector} clear />
      <FormInput label='编号' bind='number' />
    </Form>
    <Button style={{ marginLeft: 0 }} className='btn' primary onClick={() => { search(parameter) }}>查询</Button>
  </div>
}
import React, {useEffect, useMemo, useState} from 'react';
import './SpecialComponentCatalog.scss'
import {useGet} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import {DataGrid} from 'rootnet-ui'
import {TextIconBtn} from "../../../../common/TextIconBtn";
import {API1} from "../../../../../base/task";
import {Box} from "../../../../common/commonComponent";

function getColumns(props){
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40, stretch: false },
        { header: '特殊组件目录', bind: 'specialDirectory', width: 100, tooltip: true},
    ]
}

function getOptions(cols){
    return {
        nilText: '-',
        emptyText: '-',
        fixedLeft: 1,
        resizable: true,
        columns: cols,
        virtualized: true,
        autoFill: true,
    }
}

function SpecialComponentCatalog(props) {
    const {id} = props
    const {doFetch: getList} = useGet()
    const [list, setList] = useState()

    const option = useMemo(()=>{
        return getOptions(getColumns({}))
    },[])

    useEffect(()=>{
        if(_.isNil(id)) return
        getList('/special/components/list?id='+id).then(res => {
            setList(_.filter(res, x => !_.isNil(x)))
        })
    },[id, getList])

    const extra = useMemo(()=>{
        return <TextIconBtn icon={'xiazai2'} text='导出' className={'handle-btn'} onClick={_.debounce(() => API1.downloadGet('/special/components/export?id='+id), 1000)}/>
    },[id])

    return <div className={'special-component-catalog flex-y'}>
        <Box title={'特殊组件目录'} className='flex-y x-card-singlegrid' extra={extra} data={list}>
            <DataGrid option={option} data={list}/>
        </Box>
    </div>
}

export default SpecialComponentCatalog;
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './ProductComponentList.scss'
import {Table, Form, Select, Input} from "antd";
import _ from "lodash";
import {ProductContext} from "../../../common/Context";
import convertOptions from "../../../common/ConvertOptions";
import Icon from "../../../../project_share/components/Icon";

const YNOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

function getColumns(props){
    const {delItem, isDetail} = props
    return _.compact([
        {
            title: '#',
            dataIndex: '',
            width: 40,
            align: 'center',
            render: (v, o, i) => i + 1,
        },
        {
            title: <span style={{marginLeft: 12}}>组件目录</span>,
            dataIndex: 'specialDirectory',
            key: 'specialDirectory',
            width: 400,
            editable: !isDetail,
            render: (value) => <div style={{marginLeft: isDetail? 12: 0}}>
                {value}
            </div>
        },
        {
            title: <span style={{marginLeft: 12}}>是否过期</span>,
            dataIndex: 'overdue',
            key: 'overdue',
            width: 180,
            editable: !isDetail,
            render: (value) => <div style={{marginLeft: isDetail? 12: 0}}>
                {convertOptions(value, YNOptions)}
            </div>
        },
        !isDetail && {
            title: <span style={{marginLeft: 12}}>删除</span>,
            dataIndex: 'delete',
            key: 'delete',
            width: 80,
            align: 'center',
            render: (v,o) => <Icon name={'shanchu'} className={'del-icon'} onClick={()=>delItem(_.get(o,'id'))}/>
        },
    ])
}

function ProductComponentList(props) {
    const {list, setList, isDetail} = props
    const [form] = Form.useForm()

    const handleSave = useCallback((row)=>{
        const index = _.findIndex(list, x => x.id === row.id)
        setList(oldList => {
            return _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
        })
    },[list, setList])

    const delItem = useCallback((delId)=>{
        setList(old => {
            return _.filter(old, x => _.get(x,'id') !== delId)
        })
    },[setList])

    const columns = useMemo(()=>{
        return _.map(getColumns({delItem, isDetail}), col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                })
            }
        })
    },[handleSave, delItem, isDetail])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        return <Form form={form} component={false}>
            <ProductContext.Provider value={{form: form}}>
                <tr {...restProps} />
            </ProductContext.Provider>
        </Form>
    },[form])

    return <div className={'product-component-list'}>
        <Table
            size={'small'}
            sticky={true}
            pagination={false}
            dataSource={list}
            columns={columns}
            rowKey="id"
            components={{
                body: {
                    row: EditableRow,
                    cell: EditableCell
                },
            }}
        />
    </div>
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(ProductContext) || {}

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        setEditing(x => !x);
        form.setFieldsValue({ [dataIndex]:  record[dataIndex] });
    },[dataIndex, record, form])

    const save = () => {
        try{
            form.validateFields().then((changeObj)=>{
                toggleEdit();
                handleSave({ ...record, ...changeObj })
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        } catch (errInfo) {
            console.error(errInfo);
        }
    }

    let childNode = children;

    if(editable){
        if(editing){
            let editNode
            if(dataIndex === 'overdue'){
                editNode = (<Select ref={inputRef} options={YNOptions} onSelect={save} allowClear={false}
                                    defaultOpen onBlur={()=>setEditing(false)} optionFilterProp="label"
                />)
            }else{
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </Form.Item>
            )
        }else{
            childNode =(
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }
    return <td {...restProps}>{childNode}</td>;
}

export default ProductComponentList;
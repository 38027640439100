import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import UserSelect from '../../personSelectPop/UserSelect'
import './index.scss'

const SHARE_URL = '/UserSetting/shareView'

export default function ShareViewUser(props) {
  const { close, id, menuCode } = props
  const [value, setValue] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (_.isEmpty(value)) return Messager.show('请选择用户', { icon: 'error' })
    if (_.size(value) > 100) return Messager.show('用户不能超过一百位', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    doFetch(SHARE_URL, { viewId: id, userIds: value, menuCode })
      .then(() => {
        setIsDisable(false)
        Messager.show('分享成功', { icon: 'success' })
        close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [id, value, doFetch, isDisable, close, menuCode])

  return (
    <Dialog
      header='分享用户'
      cancel={close}
      confirm={confirm}
      className='share-view-user-log'
    >
      <FormInput horizontal label='用户' value={value} onChange={setValue} component={UserSelect} componentWidth={210} multiple />
    </Dialog>
  )
}

import React from 'react'
import { Dialog } from 'rootnet-ui'
import ProjectManpowerSummaryDetailContent from './projectManpowerSummaryDetailContent'
import './index.scss'

export default function ProjectManpowerSummaryDetailLog(props) {
  return (
    <Dialog
      headerVisible={false}
      footerVisible={false}
      className='project-manpower-summary-detail-dialog'
    >
      <ProjectManpowerSummaryDetailContent {...props} />
    </Dialog>
  )
}

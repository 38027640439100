import React, { useContext, useMemo } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { DataGrid, } from 'rootnet-ui'
import { Icon } from '../../../../components'
import { FormContext } from '../../../common/Context'
import { EXECUTION_COLOR } from '../../testPlan/components/publicData'
import TestPlanStageUploadArea from '../../testPlan/components/TestPlanStageUploadArea'

const getColumns = () => ([
  { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  { header: '测试计划', bind: 'planName', tooltip: true, sortable: true },
  { header: '执行结果', width: 80, bind: 'statusName', tooltip: true, sortable: true, convert: statusName },
  { header: '执行人', bind: 'executorName', width: 90, tooltip: true, sortable: true },
  {
    header: '实际执行结果', bind: 'actualResult', width: 190, convert: v => {
      return <Tooltip overlayClassName='actual-result-html-tip' title={<div dangerouslySetInnerHTML={{ __html: v || '' }} />}>
        <div className='actual-result-html' dangerouslySetInnerHTML={{ __html: v || '' }} />
      </Tooltip>
    }
  },
  { header: '执行时间', bind: 'executTime', width: 150, convert: v => v, tooltip: true, sortable: true },
  {
    header: '附件', width: 60, convert: (v, o) => {
      return <Popover content={<TestPlanStageUploadArea funcCode={'0503'}
        {...{ id: _.head(o.mappingFiles)?.referenceId }} />}>
        <span className={'flex center relate-document-icon-wrap'} style={{ marginLeft: 12 }}>
          <Icon name={'lianjie'} style={{ fontSize: 14 }} className={'relate-document-icon'} />({_.size(o.mappingFiles)})
        </span>
      </Popover>
    }
  },
])

function statusName(v, o) {
  return <span style={{ color: EXECUTION_COLOR[o?.status] }}>{v}</span>
}

const getTableOption = (columns) => ({
  columns,
  autoFill: true,
  virtualized: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
});

export default function ExecutiveHistory() {
  const { executiveData: data } = useContext(FormContext)

  const option = useMemo(() => getTableOption(getColumns()), [])

  return (
    <DataGrid className='executiveHistory' data={data} option={option} />
  )
}

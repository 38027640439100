import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager, Button, Loader} from 'rootnet-ui'
import {Form, FormInput, Select, TextArea, CheckBox, Display, Input} from "rootnet-edit";
import './SaleProductUpdateDialog.scss'
import convertOptions from "../../common/ConvertOptions";
import _ from "lodash";
import usePost from "rootnet-biz/es/hooks/usePost";
import {useGet} from "rootnet-biz/es/hooks";
import {strParams} from "../../../utils/publicFun";
import {Tooltip, Switch} from "antd";
import Icon from "../../../components/Icon";
import {dateFormat, toDate} from "rootnet-core/dateFormat";

const getInitParams = () => {
    return {
        moduleName: null,
        pid: null,
        unit: null,
        quantity: null,
        priceStand: null,
        memo: null,
    }
}

const HFormInput = (props) => <FormInput horizontal componentWidth={200} labelWidth={100} {...props}/>

function SaleProductUpdateDialog(props) {
    const {productLineOptions, salesSystemOptions, salesProductTypeOptions, close, info, refresh, update, setCurrentId,
        setNewCurrentId, editAuth = false, isManager, allUserRes} = props
    const {mode: initMode, currentInfo: initInfo} = useMemo(()=>info || {},[info])
    const [mode, setMode] = useState(initMode)
    const [params, setParams] = useState(initInfo || getInitParams())
    const [continueAdd, setContinueAdd] = useState(true)
    const [error, setError] = useState()
    const {doFetch} = usePost()
    const {data: parentModuleOptionsRes, doFetch: getParentModuleOptions} = useGet()
    const [newestParams, setNewParams] = useState(initInfo)
    const [openFlag, setOpenFlag] = useState(initMode==='add'?'Y':_.get(initInfo,'openFlag'))
    const [loading, setLoading] = useState(false)

    const parentModuleOptions = useMemo(()=>{
        if(_.isEmpty(parentModuleOptionsRes)) return []
        return _.map(parentModuleOptionsRes, x => ({text: x.moduleName, value: x.id}))
    },[parentModuleOptionsRes])

    const confirmDisabled = useMemo(()=>{
       return _.some(_.values(error), x => x)
    },[error])

    const searchParentModuleOptions = useCallback((searchParams)=>{
        getParentModuleOptions('/salesproductInfo/selectForModule?'+strParams(searchParams))
    },[getParentModuleOptions])

    useEffect(()=>{
        if(_.some([params.system, params.productType],v => _.isNil(v))) return
        const searchParams = {
            system: params.system,
            projectType: params.productType
        }
        searchParentModuleOptions(searchParams)
    },[params.system, params.productType, searchParentModuleOptions])

    const submit = useCallback(()=>{
        if(loading) return
        setLoading(true)
        const url = mode === 'add' ? '/salesproductInfo/add' : '/salesproductInfo/modify'
        const submitParams = {...params, openFlag}
        if(_.isNil(_.find(parentModuleOptions, x => x.value === submitParams.pid))){
            submitParams['pid'] = null
        }
        doFetch(url, submitParams).then(res => {
            setLoading(false)
            Messager.show(mode === 'add'? '添加成功' : '修改成功', { icon: 'success' });
            if(mode === 'add'){
                const newKeyPath = `${submitParams.system}${submitParams.productType}${_.get(submitParams,'pid') || ''}${res}`
                setCurrentId(newKeyPath)
                setNewCurrentId(newKeyPath)
                if(continueAdd){
                    setParams(x => {
                        const nextParams = ({...x, ...getInitParams(), openFlag})
                        setNewParams(nextParams)
                        return nextParams
                    })
                }else{
                    close()
                }
                const searchParams = {
                    system: submitParams.system,
                    projectType: submitParams.productType
                }
                searchParentModuleOptions(searchParams)
                refresh()
            }else{
                update(submitParams)
                setNewParams(submitParams)
                setMode('detail')
            }
        }).catch(err => {
            setLoading(false)
            Messager.show(err._message, { icon: 'error' });
        })
    },[doFetch, params, close, mode, continueAdd, refresh, update, parentModuleOptions, searchParentModuleOptions, setCurrentId, setNewCurrentId, openFlag, loading])

    return <Dialog className={`sales-product-update-dialog ${mode === 'detail'?'detail-mode':''}`} cancel={close}
            headerVisible={false} footerVisible={false}>
        <div className="sales-product-detail-content">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    {mode === 'add'?'添加产品信息':'产品信息'}
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        mode !== 'add' && editAuth &&
                        <Tooltip title={mode === 'detail' ? '进入编辑' : '退出编辑'}>
                            <span>
                                <Icon name={'bianji2'} className={`header-edit-icon ${mode === 'detail' ? '' : 'edit-active-icon'}`} onClick={() => {
                                    setMode(x => x==='edit'?'detail':'edit')
                                    setParams(newestParams)
                                    setOpenFlag(_.get(newestParams,'openFlag'))
                                }} />
                            </span>
                        </Tooltip>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <div className="sales-product-detail-main-panel">
                <Form value={params} onChange={setParams} error={error} onError={setError}>
                    <HFormInput label={'产品线'} bind={'productLine'} required search options={productLineOptions}
                                convert={v => convertOptions(v, productLineOptions)} component={mode === 'add'?Select:Display}/>
                    <HFormInput label={'产品'} bind={'system'} required search options={salesSystemOptions}
                                convert={v => convertOptions(v, salesSystemOptions)} component={mode === 'add'?Select:Display}/>
                    <HFormInput label={'销售类别'} bind={'productType'} required search options={salesProductTypeOptions}
                                convert={v => convertOptions(v, salesProductTypeOptions)} component={mode === 'add'?Select:Display}/>
                    <HFormInput label={'可售标识'} bind={''} component={Display} componentWidth={0} convert={()=>''}/>
                    <Switch checked={openFlag === 'Y'} disabled={!editAuth || mode !== 'edit'} checkedChildren={'可售'} unCheckedChildren={'不可售'} onChange={value => setOpenFlag(value?'Y':'N')}/>
                    <HFormInput label={'模块名称'} bind={'moduleName'} required component={mode === 'detail'? Display: Input}/>
                    <HFormInput label={'父级模块'} bind={'pid'} search clear options={parentModuleOptions}
                                convert={v => convertOptions(v, parentModuleOptions)} component={mode === 'add'?Select:Display}/>
                    <HFormInput label={'单位'} bind={'unit'} component={mode === 'detail'? Display: Input}/>
                    <HFormInput label={'数量'} bind={'quantity'} type={'number'} component={mode === 'detail'? Display: Input}/>
                    {
                        isManager &&
                        <HFormInput label={'标准单价'} bind={'priceStand'} type={'number'} component={mode === 'detail'? Display: Input}/>
                    }
                    <div/>
                    <HFormInput label={'描述'} bind={'memo'} componentWidth={516} component={mode === 'detail'? Display: TextArea} componentClass={'long-text'}/>
                    {
                        mode !== 'add' &&
                            <div>
                                <HFormInput label={'创建人'} bind={'createUser'} component={Display}
                                            convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                                <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
                                            convert={v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.num14ToDate(v))}/>
                                <HFormInput label={'修改人'} bind={'updateUser'} component={Display}
                                            convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                                <HFormInput label={'修改时间'} bind={'updateTime'} component={Display}
                                            convert={v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.num14ToDate(v))}/>
                            </div>
                    }
                    {
                        mode === 'add' &&
                        <div className="continue-add">
                            <CheckBox value={continueAdd} onChange={setContinueAdd}>继续创建下一条</CheckBox>
                        </div>
                    }
                </Form>
                { loading && <Loader fill/> }
            </div>
            <div className="mock-footer flex center-y">
                <div />
                <div className="btn-group flex">
                    {
                        mode !== 'edit'  &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        mode === 'edit'  &&
                        <Button normal onClick={()=>{
                            setMode('detail')
                            setParams(newestParams)
                        }}>取消</Button>
                    }
                    {
                        mode !== 'detail' &&
                        <Button primary onClick={submit} disable={confirmDisabled}>确认</Button>
                    }
                </div>
            </div>
        </div>
    </Dialog>
}

export default SaleProductUpdateDialog;
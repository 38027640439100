import React, {useEffect} from 'react';
import './UserInfoCard.scss'
import _ from "lodash";
import { Divider, Tag } from 'antd';
import {uniqKeyFor} from "../../../../../project_share/utils/utils";
import {useGet} from "rootnet-biz/es/hooks";
import {Loader} from 'rootnet-ui'

function UserInfoCard(props) {
    const {user} = props
    const {data: userInfo, doFetch: getUserInfo, loading} = useGet()

    useEffect(()=>{
        if(_.isNil(user)) return
        getUserInfo('/userFactor/userInfo?userAccount='+user)
    },[user, getUserInfo])

    return <div className={'user-info-card flex-y'}>
        <div className="user-header flex center-y">
            <div className="user-avatar flex center" style={{background: '#8DC8EA'}}>
                {_.isEmpty(userInfo)?'':_.get(userInfo,'userName')[0]}
            </div>
            <div className="user-header-right flex-y">
                <div className="user-name">{_.get(userInfo,'userName')}</div>
                <div className="user-dept">
                    {_.get(userInfo,'departmentPath')}
                </div>
            </div>
        </div>
        <Divider style={{margin : '8px 0'}}/>
        <div className="user-content flex-y">
            <div className="user-join-date user-content-item">
                入职日期：{_.get(userInfo,'joinDate')}
            </div>
            <div className="user-position user-content-item">
                岗位：{_.get(userInfo,'positionName')}
            </div>
            <div className="user-rank user-content-item">
                职级：{_.get(userInfo,'techLevel')}
            </div>
            <div className="user-skill-wrap flex user-content-item">
                <div>业务技能：</div>
                <div className={'user-skill-group'}>
                    {
                        _.map(_.get(userInfo,'skillsName'), x => <Tag color="#108ee9" key={uniqKeyFor()}>{x}</Tag>)
                    }
                </div>
            </div>
        </div>
        {
            loading && <Loader fill/>
        }
    </div>
}

export default UserInfoCard;
import React, {useMemo} from 'react';
import './MobileMyCenter.scss'
import {Icon} from "../../../components";
import _ from "lodash";
import {useGet} from "../../../utils/hook";
import gd from "../../../base/global";

function getNameImgColor(sex){
    if(_.isEmpty(sex)) return '#D3D3D3'
    return sex === '女'?'#FF99FF':'#8DC8EA'
}

function MobileMyCenter(props) {
    const {history} = props
    const {data: allUserRes} = useGet('/common/userinfo')

    const userInfo = useMemo(()=>{
        if(_.isEmpty(allUserRes)) return {}
        const userInfo = _.find(allUserRes, x => x.userAccount === gd._user.operator_name)
        localStorage.setItem('current_user_info', JSON.stringify(userInfo))
        return userInfo
    },[allUserRes])

    return <div className={'mobile-my-center flex-y'}>
        <div className="my-info-wrap flex">
            <div className="name-img flex center" style={{background: getNameImgColor(_.get(userInfo,'sex'))}}>{_.isEmpty(userInfo)?'':_.get(userInfo,'userName')[0]}</div>
            <div className="my-info flex-y center-y">
                <div className="name">{_.get(userInfo,'userName')}</div>
                <div className="user-no">员工编号：{_.get(userInfo,'userId')}</div>
            </div>
        </div>
        <div className='cell-group'>
            <div className="cell flex center-y" onClick={()=>history.push('/mobileAttendanceMgt')}>
                <div className="cell-left flex center-y">
                    <Icon name={'kaoqinguanli'} className={'cell-icon'} style={{color: '#3f7dff'}}/>
                    <div className="cell-text">考勤机照片管理</div>
                </div>
                <Icon name={'xianghou'} className={'to-icon'}/>
            </div>
            <div className="cell flex center-y">
                <div className="cell-left flex center-y">
                    <Icon name={'shezhi1'} className={'cell-icon'}/>
                    <div className="cell-text">设置</div>
                </div>
                <Icon name={'xianghou'} className={'to-icon'}/>
            </div>
        </div>
    </div>
}

export default MobileMyCenter;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Messager,Dialog} from 'rootnet-ui'
import './MobileAttendanceMgt.scss'
import _ from "lodash";
import {useApi} from "../../../utils/hook";
import gd from "../../../base/global";
import {strParams} from "../../../utils/publicFun";
import {API2} from "rootnet-core/base";

function MobileAttendanceMgt(props) {
    const [ imgData, setImgData ] = useState('')
    const [ confirmImgData,setConfirmImgData ] = useState('')
    const [ showConfirm, setShowConfirm ] = useState(false)
    const [imgId, setImgId] = useState()
    const {doFetch} = useApi()
    const userInfo = useMemo(()=>JSON.parse(localStorage.getItem('current_user_info')) || {},[])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const userId = useMemo(()=>{
        return _.get(userInfo,'userId')
    },[userInfo])

    useEffect(()=>{
        if(_.isNil(imgId)) return
        API2.get('/storeFiles/getFileUrl?id='+imgId).then(res => {
            setImgData(res.data)
        }).catch(err => {
            console.log('err', err)
        })
    },[imgId,doFetch])

    useEffect(()=>{
        if(_.isNil(userId)) return
        doFetch('/AttendanceInfo/getFileId?userId='+userId,'get').then(res => {
            if(res !== '图片ID不存在！'){
                setImgId(res)
            }
        })
    },[userId,doFetch])

    const _upImg = useCallback(() => {
        let upImages = document.getElementById("upImages")
        upImages.click();
    },[])

    const upChange = useCallback((event) => {
        let imgFile = event.currentTarget.files[0];///获得input的第一个图片
        if(!imgFile) return

        let reader = new FileReader();//filereader.readasdataurl读取图像文件转换为流
        reader.readAsDataURL(imgFile);

        reader.onload = function (event) {//读取数据时会触发一个load事件
            let img = new Image();
            img.src = `${this.result}`;
            img.onload = () => {
                if(img.width < 480 || img.height < 640) return Messager.show('照片像素需要高度大于640，宽度大于480', { icon: 'error' })
                dealImage(this.result, 600, (zipBase64)=>{
                    setConfirmImgData(zipBase64)
                    setShowConfirm(true)
                })
            }
        };
    },[])

    const closeDialog = useCallback(()=>{
        setShowConfirm(false)
        setConfirmImgData('')
    },[])

    const confirmDialog = useCallback(()=>{
        setIsSubmitting(true)
        const jpgFile = dataURLtoFile(confirmImgData)

        const fileData = new FormData()
        fileData.append('file', jpgFile)
        let postParams = {
            bucketName: 'attendance',
            createUser: gd._user.operator_name,
            userId: userId
        }

        if(!_.isNil(imgId)){
            postParams = {...postParams, id: imgId}
        }
        API2.post('/storeFiles/uploadFile?'+strParams(postParams),fileData).then(res =>{
            setImgId(res.data.id)
            const saveParams = {
                fileId: res.data.id,
                userId: userId,
                createUser: userInfo.userAccount
            }
            doFetch('/AttendanceInfo/updateAttendanceInfo?'+strParams(saveParams),'get').then(() => {
                setImgId(res.data.id)
                Messager.show("保存图片成功！", { icon: "success" });
            })
            setIsSubmitting(false)
            closeDialog()
        }).catch(err => {
            Messager.show(err._message,{icon:'error'});
            setIsSubmitting(false)
        })
    },[confirmImgData,closeDialog,doFetch,userId,imgId,userInfo])

    return <div className='mobile-attendance-mgt flex-y fill'>
        <div className="pic-cell flex center-y" onClick={_upImg}>
            <div className="cell-title">照片</div>
            <div className="show-images-area flex center">
                {
                    imgData !== '' && <img src={imgData} style={{ width: '2rem', height: '2rem',objectFit:'contain' }} alt=''/>
                }
                {
                    imgData === '' && <div className='no-img-text'>未上传照片</div>
                }
            </div>
            <input id='upImages' type='file' style={{ display: 'none' }}  accept="image/*" onChange={upChange} />
        </div>
        <div className="common-cell-group flex-y">
            <div className="common-cell flex center-y">
                <div className="common-cell-title">姓名</div>
                <div className="common-cell-text">{_.get(userInfo,'userName')}</div>
            </div>
            <div className="common-cell flex center-y">
                <div className="common-cell-title">员工编号</div>
                <div className="common-cell-text">{_.get(userInfo,'userId')}</div>
            </div>
        </div>
        {
            showConfirm && <Dialog header='确认图像' className='mobile-attendance-mgt-confirm-dialog' confirm={confirmDialog} cancel={closeDialog}
                                       confirmButtonDisabled={isSubmitting} confirmButtonText={isSubmitting?'提交中':'确认'}>
                <div className="show-images-area">
                    {
                        confirmImgData !== '' && <img src={confirmImgData} style={{ width: '4rem', height: '6rem',objectFit:'contain' }} alt=''/>
                    }
                </div>
            </Dialog>
        }
    </div>
}

function dataURLtoFile(dataUrl, filename = 'file') {
    let arr = dataUrl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    // let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.jpg`, {
        type: mime
    })
}

const dealImage = (base64Src, w, callback) => {
    let base64 = base64Src
    let newImage = new Image()
    //压缩系数0-1之间
    let quality = 1
    newImage.src = base64
    newImage.setAttribute('crossOrigin', 'Anonymous')    //url为外域时需要
    let imgWidth, imgHeight
    newImage.onload = function () {
        imgWidth = this.width
        imgHeight = this.height
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        if (Math.max(imgWidth, imgHeight) > w) {
            if (imgWidth > imgHeight) {
                canvas.width = w
                canvas.height = w * imgHeight / imgWidth
            } else {
                canvas.height = w
                canvas.width = w * imgWidth / imgHeight
            }
        } else {
            canvas.width = imgWidth
            canvas.height = imgHeight
            quality = 1
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height)
        let ba = canvas.toDataURL('image/jpeg', quality) //压缩语句
        // 如想确保图片压缩到自己想要的尺寸,如要求在100-1000kb之间，请加以下语句，quality初始值根据情况自定
        while (base64.length / 1024 > 1000) {
            quality -= 0.01;
            base64 = canvas.toDataURL("image/jpeg", quality);
        }
        // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
        // while (base64.length / 1024 < 100) {
        //     quality += 0.1;
        //     base64 = canvas.toDataURL("image/jpeg", quality);
        // }
        callback(ba) //必须通过回调函数返回，否则无法及时拿到该值
    }
}

export default MobileAttendanceMgt;
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Loader } from 'rootnet-ui'
import { Form, FormInput, Select } from 'rootnet-edit'
import ListItem from './components/ListItem'
import ListDetail from './controls/listDetail'
import { pathSearchFor, strParams } from '../../utils/publicFun'
import EvaluationPage from './controls/evaluationPage'
import { useGet } from '../../utils/testHook'
import convertGlobalConstOptions from '../common/ConvertGlobalConstOptions'
import EvaluationList from './controls/evaluationList'
import { isInterval, beforDate } from './components/method'
import './index.scss'

const QUEST_URL = '/quest-list/get' //问卷列表
const RELATION_URL = '/quest-peopleevaluatelist/get/relation' //评价关系列表
const LIST_URL = '/quest-peopleevaluatelist/get'
const OPTIONS_URL = [
  '/common/globalconst?globalConst=questStatus',
  '/common/userinfo?indexValidFlag=1'
]

function getInitParams() {
  return {
    title: null,
    status: null,
  }
}

export default function Appraise(props) {
  const { history } = props
  const { evaluateId = null, questId = null, relation = null } = pathSearchFor(history.location.search)
  const [currentId, setCurrentId] = useState(questId) // 评价人员详情
  const [detailId, setDetailId] = useState(evaluateId) // 评价人员列表
  const [relationId, setRelationId] = useState(relation) // 评价名单
  const [params, setParams] = useState({})
  const { data: listRes } = useGet(OPTIONS_URL)
  const { data, doFetch, error, loading } = useGet()
  const { data: list, doFetch: listFetch, error: listError, loading: listLoading } = useGet()
  const { data: relationData, doFetch: relationFetch, error: relationError, loading: relationLoading } = useGet()

  const [statusOpt, userOpt] = useMemo(() => {
    if (_.isNil(listRes)) return []
    const [d1, d2] = listRes || []
    return [
      convertGlobalConstOptions(d1),
      d2,
      // _.map(d2, o => ({ value: o.userAccount, label: o.userName, tag: `${o.userAccount}${o.userName}` }))
    ]
  }, [listRes])

  const listOpt = useMemo(() => _.map(data, o => ({ text: o.title, value: o.id, _disabled: !(isInterval(o.beginDate, o.endDate) && o.status === '02') })), [data])
  const listOpts = useMemo(() => _.map(data, o => ({ text: o.title, value: o.id, _disabled: !(beforDate(o.beginDate) && o.status === '01' && o.flag === 'true') })), [data])
  const userList = useMemo(() => _.map(list, o => ({ text: o.userName, value: o.id, status: o.status })), [list])

  const listRefresh = useCallback((parameter) => {
    const str = strParams(parameter)
    if (currentId) listFetch(`${LIST_URL}?questId=${currentId}${str === '' ? '' : '&' + str}`)
  }, [currentId, listFetch])

  const relationRefresh = useCallback(() => {
    relationFetch(`${RELATION_URL}?questId=${relationId}`)
  }, [relationFetch, relationId])

  const refresh = useCallback((parameter) => {
    const str = strParams(parameter)
    doFetch(`${QUEST_URL}${str === '' ? '' : '?' + str}`)
  }, [doFetch])

  const userDetail = useMemo(() => {
    if (_.isNil(detailId) || _.isEmpty(list)) return {}
    return _.find(list, o => o.id === detailId)
  }, [detailId, list])

  useEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    if (!_.isNil(currentId)) listRefresh()
  }, [currentId, evaluateId, listRefresh])

  useEffect(() => {
    if (!_.isNil(relationId)) relationRefresh()
  }, [relationId, relationRefresh])

  return (
    <>
      <div className='appraise fill flex-y' style={{ display: (!_.isNil(currentId) || !_.isNil(relationId)) ? 'none' : '' }}>
        <Option {...{ params, setParams, refresh, statusOpt }} />
        {loading && <Loader />}
        {(!loading && _.isNil(error)) && <ListItem {...{ data, routingHop, setCurrentId, statusOpt, setRelationId, history }} />}
      </div>
      {(!_.isNil(currentId) && _.isNil(evaluateId)) && <ListDetail {...{ currentId, history, routingHop, listOpt, list, listError, listLoading, listRefresh, setDetailId }} refres={() => refresh(params)} />}
      {
        !_.isNil(evaluateId) &&
        <EvaluationPage
          headerTitle={_.get(_.find(listOpt, o => o.value === currentId), 'text')}
          {...{ history, routingHop, currentId, evaluateId, userDetail, userList, detailId, setDetailId, listRefresh }}
        />
      }
      {
        !_.isNil(relationId) &&
        <EvaluationList {...{ history, relationId, setRelationId, listOpt: listOpts, relationData, relationError, relationLoading, userOpt, relationRefresh }} />
      }
    </>
  )
  function routingHop(id = null, pathname = '/360Evaluate') {
    setCurrentId(id)
    history.replace(_.assign({}, { pathname }, !_.isNil(id) && { search: strParams({ questId: id }) }))
  }
}

function Option(props) {
  const { refresh, params, setParams, statusOpt } = props

  return <div className='c-options'>
    <Form value={params} onChange={setParams}>
      <FormInput placeholder='名称模糊搜索' bind='title' />
      <FormInput horizontal label='状态' bind='status' component={Select} options={statusOpt} clear />
    </Form>
    <Button primary className='btn' onClick={() => refresh(params)}>查询</Button>
    <Button text className='btn reset-btn' onClick={() => {
      refresh(getInitParams())
      setParams(getInitParams())
    }}>重置</Button>
  </div>
}
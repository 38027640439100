import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { dateFormat } from 'rootnet-core/dateFormat'
import { useGet, usePost } from 'rootnet-biz/es/hooks'
import { Form, FormInput, Select, TextArea } from 'rootnet-edit'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import FollowMattersDetail from '../../../common/ChangeRecord/components/followMattersDetail'
import gd from '../../../../base/global'
import './index.scss'

const EDIT_URL = '/issue/detailMultiple'
const STATUS_URL = '/common/globalconst?globalConst=AUDITTYPE'

const HFormInput = props => <FormInput horizontal labelWidth={80} componentWidth={280} {...props} />

export default function QaCheckDialog(props) {
  const { id, close, refresh } = props
  const [params, setParams] = useState({ qaCheckResult: 'Y' })
  const [open, setOpen] = useState(false)
  const { data: statusRes } = useGet(STATUS_URL)
  const { doFetch } = usePost()

  const statusOpt = useMemo(() => {
    if (_.isEmpty(statusRes)) return []
    return convertGlobalConstOptions(statusRes)
  }, [statusRes])

  const submit = useCallback(() => {
    const parameter = {
      ...params,
      id,
      qaCheckUser: gd.User.operator_id,
      qaCheckTime: dateFormat('YYYYMMDDHHMMSS', new Date()),
    }
    doFetch(EDIT_URL, parameter)
      .then(() => {
        close()
        refresh()
        Messager.show('检查成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [close, doFetch, id, params, refresh])

  return (
    <>
      <Dialog
        header='QA检查'
        confirm={submit}
        cancel={close}
        style={{ width: 400 }}
        className='qa-check-dialog'
      >
        <div className='add-follow-btn'>
          <span className='hover-add-follow' onClick={() => setOpen(true)}>
            添加跟进事项
          </span>
        </div>
        <Form value={params} onChange={setParams}>
          <HFormInput required label='检查结果' bind='qaCheckResult' component={Select} options={statusOpt} />
          <HFormInput label='备注说明' bind='qacheckMemo' component={TextArea} />
        </Form>
      </Dialog>
      {
        open && <FollowMattersDetail id={id} relateId={id} funcCode={'19'} mode={'add'} close={() => setOpen(false)}
          refresh={refresh} linkUrl={'/issue?initId=' + id} defaultType='2' />
      }
    </>
  )
}

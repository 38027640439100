import React from 'react'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../../../components'

const { str14ToDate } = toDate

function getOptFlag(v) {
  if (v === 'add') return '增加'
  else if (v === 'edit') return '编辑'
  else if (v === 'del') return '删除'
  else return v
}

export function getColumnsUntreated(props) {
  const { setOpen, setSelectData, setShowDetail } = props
  return [
    { selection: true },
    // { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '编号', bind: 'number', sortable: true, width: 145, tooltip: true },
    {
      header: '标题', bind: 'title', sortable: true, width: 200, tooltip: true, convert: (v, o) => {
        const type = o['businessType']
        return <div style={{ color: '#5477ff', cursor: 'pointer' }} onClick={() => {
          if (type === 'req') setShowDetail({
            type: 'REQ',
            id: o.businessId
          })
          else if (type === 'issue') setShowDetail({
            type: 'ISSUE',
            id: o.businessId
          })
        }}>
          {v}
        </div>
      }
    },
    { header: '操作动作', bind: 'optFlag', sortable: true, width: 90, tooltip: true, convert: getOptFlag },
    { header: '申请人', bind: 'operUser', sortable: true, width: 100, tooltip: true, },
    {
      header: '申请时间', bind: 'optTime', sortable: true, width: 140, tooltip: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    },
    { header: '申请理由', bind: 'optMemo', sortable: true, width: 140, tooltip: true },
    { header: '操作', sortable: true, width: 140, convert: operation },
  ]

  function operation(v, o) {
    return <div className='flex req-operation'>
      <div className='agree' onClick={() => { setOpen('agree'); setSelectData([o]) }}>同意<Icon name='chenggong' /></div>
      <div className='reject' onClick={() => { setOpen('reject'); setSelectData([o]) }}>拒绝<Icon name='shibai' /></div>
    </div>
  }
}

export function getColumnsOther(props) {
  const { setShowDetail } = props
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '编号', bind: 'number', sortable: true, width: 160, tooltip: true },
    {
      header: '标题', bind: 'title', sortable: true, width: 200, tooltip: true, convert: (v, o) => {
        const type = o['businessType']
        return <div style={{ color: '#5477ff', cursor: 'pointer' }} onClick={() => {
          if (type === 'req') setShowDetail({
            type: 'REQ',
            id: o.businessId
          })
          else if (type === 'issue') setShowDetail({
            type: 'ISSUE',
            id: o.businessId
          })
        }}>
          {v}
        </div>
      }
    },
    { header: '操作动作', bind: 'optFlag', sortable: true, width: 90, tooltip: true, convert: getOptFlag },
    { header: '申请人', bind: 'operUser', sortable: true, width: 100, tooltip: true, },
    {
      header: '申请时间', bind: 'optTime', sortable: true, width: 140, tooltip: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    },
    { header: '申请理由', bind: 'optMemo', sortable: true, width: 140, tooltip: true },
    { header: '审核人', bind: 'auditUser', sortable: true, width: 140, tooltip: true, },
    {
      header: '动作', bind: 'auditFlag', sortable: true, width: 100, tooltip: true, convert: v => {
        if (v === 'N') return <div className='flag-reject'>拒绝<Icon name='shibai' /></div>
        if (v === 'Y') return <div className='flag-agree'>同意<Icon name='chenggong' /></div>
        return '-'
      }
    },
    {
      header: '审核时间', bind: 'auditTime', sortable: true, width: 140, tooltip: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    },
    { header: '理由', bind: 'auditMemo', sortable: true, width: 100, tooltip: true },
  ]
}
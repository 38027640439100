import React from 'react'
import { Dialog } from 'rootnet-ui'
import './index.scss'
import StageQualityAuditDetailContent from './stageQualityAuditDetailContent'

export default function StageQualityAuditDetail(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog className={'stage-quality-audit-detail-dialog'} headerVisible={false} footerVisible={false}>
      <StageQualityAuditDetailContent  {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

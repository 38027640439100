import React from 'react'
import ExportApiDialog from '../../../../common/ExportApiDialog'
import { tableFormat } from './method'

const EXPORT_URL = '/projectTemplate/export'

export default function ExportExcel(props) {
  const { close, parameter } = props
  return (
    <ExportApiDialog
      name='导出模板'
      close={close}
      option={tableFormat}
      url={EXPORT_URL}
      parameter={parameter}
    />
  )
}

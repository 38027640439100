//node_modules
import React, { useReducer, useMemo, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { Select, TextInput, FormInput, Dialog, DatePicker, InputGroup, Messager } from 'rootnet-ui';
import { GetTime, GetDate } from 'rootnet-core/format'

//Local_modules
import API from '../../base/task';
import { useGet } from '../../utils/hook';

const urlMap = {
    'add': 'develop/project/task/add',
    'modify': 'develop/project/task/modify'
}

const urlParam = [
    'id', 'productId', 'interiorReqNo', 'title', 'issueType', 'bondprovider',
    'principal', 'projectPri', 'issueState', 'issueId', 'releaseProject',
    'custDepart', 'feeDepartment', 'tracerId', 'designCompleteDate', 'devCompleteDate',
    'testCompleteDate', 'designUseDay', 'devUseDay', 'testUseDay'
]

function reducer(state, action) {
    const { name, value } = action
    if (name === 'init') {
        const opt_No = _.get(value, '[0]')
        if (_.size(opt_No) === 1) state.interiorReqNo = _.get(opt_No, '[0].interiorReqNo')//下拉数据只有1条时显示第一个值
        state.productId = _.get(value, 'project.value2.productId');
        state.bondprovider = _.get(value, 'project.value2.custId');
        const data = _.get(value, 'data.[4][0]')
        _.assign(state, data, {
            designCompleteDate: GetDate(_.get(data, 'designCompleteDate')),
            devCompleteDate: GetDate(_.get(data, 'devCompleteDate')),
            testCompleteDate: GetDate(_.get(data, 'testCompleteDate')),
        })
    }
    return { ...state, [name]: value }
}
const intData = {
    designUseDay: 0,
    devUseDay: 0,
    testUseDay: 0,
    issueType: 'NEW',
    projectPri: 'B',
    issueState: '0',
}

export function TaskDialog(props) {
    const { data_New, mode, fresh, id,
        param: { projectId, project = null, project: { value2: { projectSort = '' } } },
    } = props

    const isEdit = mode === 'modify'
    const groupRef = useRef();
    const [state, dispatch] = useReducer(reducer, intData)
    const { data, doFetch } = useGet()
    const isProjectSort = _.isEqual(projectSort, '1')//1为客户需求
    const [interiorReqNoOpt, bondproviderOpt, projectPriOpt, productIdOpt, principalOpt, issueStateOpt] = useMemo(() => {
        const [d1, d2, d3, d4] = data || [null, null, null, null];
        const opt_No = [..._.map(d1, o => { return { text: o.title, value: o.interiorReqNo } })];
        const opt_bond = [..._.map(d2, o => { return { text: o.projectName, value: o.projectId } })];
        const opt_pro = [..._.map(d3, o => { return { text: _.concat(o.interiorId, '-', o.displayName), value: o.interiorId } })];
        const opt_prod = [..._.map(d4, o => { return { text: o.productName, value: o.productId } })];
        const ops_p = _.get(data_New, [0]);
        const ops_s = _.get(data_New, [1]);
        dispatch({ name: 'init', value: { data, project } })
        return [opt_No, opt_bond, opt_pro, opt_prod, ops_p, ops_s];
    }, [data, data_New, project]);

    const getData = useCallback(() => {
        const urls = [
            `develop/requirement/list?projectId=${projectId}`,
            `common/system?status=02`,
            "common/globalconst?globalConst=PRIORITYLIST",
            'develop/product/list',
        ]
        if (isEdit) urls.push(`develop/project/task/list?id=${id}`)
        doFetch(urls)
    }, [projectId, doFetch, id, isEdit]);

    useEffect(() => {
        getData()
    }, [getData]);

    function confirm() {
        const group = groupRef.current;
        if (!group.validate()) return;
        const param_ = _.pick(state, urlParam)
        const params = _.assign({ ...param_ }, {
            designCompleteDate: GetTime(designCompleteDate),
            devCompleteDate: GetTime(devCompleteDate),
            testCompleteDate: GetTime(testCompleteDate),
            projectId
        })
        API.post(urlMap[mode], params).then(res => {
            const { msg } = res;
            props.cancel()
            fresh()
            Messager.show(msg, { icon: 'success' });
        }
        ).catch(
            error => Messager.show(error._message, { icon: 'error' })
        )
    }

    const {
        interiorReqNo, productId, issueType, issueState, bondprovider,
        projectPri, principal, designUseDay, devUseDay, testUseDay,
        tracerId, title, custDepart, feeDepartment, releaseProject,
        issueId, designCompleteDate, devCompleteDate, testCompleteDate,
    } = state

    const optionData = [
        {
            label: '需求编号：', required: true, component: Select, value: interiorReqNo, options: interiorReqNoOpt, isShow: isProjectSort, name: 'interiorReqNo',
            onChange: (e, o) => dispatch({ name: "interiorReqNo", value: _.get(o, 'value') })
        },
        {
            label: '产品：', required: true, component: Select, search: true, value: productId, options: productIdOpt, name: 'productId',
            onChange: (e, o) => dispatch({ name: "productId", value: _.get(o, 'value') })
        },
        {
            label: '状态：', required: true, component: Select, value: issueState, options: issueStateOpt, isShow: isEdit, name: 'issueState',
            onChange: (e, o) => dispatch({ name: "issueState", value: _.get(o, 'value') })
        },
        {
            label: '任务：', required: true, component: TextInput, value: title, name: 'title', cn: 'w_max', placeholder: '请输入任务',
            onChange: e => dispatch({ name: "title", value: e })
        },
        {
            label: '性质：', required: true, component: Select, value: issueType, options: issueTypeOpt, name: 'issueType',
            onChange: (e, o) => dispatch({ name: "issueType", value: _.get(o, 'value') })
        },

        {
            label: '客户：', required: true, component: Select, search: true, value: bondprovider, options: bondproviderOpt, name: 'bondprovider',
            onChange: (e, o) => dispatch({ name: "bondprovider", value: _.get(o, 'value') })
        },
        {
            label: '负责人：', required: true, component: Select, search: true, value: principal, options: principalOpt, name: 'principal',
            onChange: (e, o) => dispatch({ name: "principal", value: _.get(o, 'value') })
        },
        {
            label: '优先级：', required: true, component: Select, value: projectPri, options: projectPriOpt, name: 'projectPri',
            onChange: (e, o) => dispatch({ name: "projectPri", value: _.get(o, 'value') })
        },
        {
            label: '客户部门：', component: TextInput, value: custDepart, isShow: isProjectSort,
            onChange: (e, o) => dispatch({ name: "custDepart", value: e })
        },
        {
            label: '费用部门：', component: TextInput, value: feeDepartment, isShow: isProjectSort,
            onChange: (e, o) => dispatch({ name: "feeDepartment", value: e })
        },
        {
            label: '版本编号：', component: TextInput, value: releaseProject, placeholder: '请输入版本编号',
            onChange: (e, o) => dispatch({ name: "releaseProject", value: e })
        },
        {
            label: '问题编号：', component: TextInput, value: issueId, placeholder: '请输入问题编号',
            onChange: (e, o) => dispatch({ name: "issueId", value: e })
        },
        {
            label: '设计完成时间：', required: true, component: DatePicker, value: designCompleteDate, name: 'designCompleteDate',
            onChange: (e, o) => dispatch({ name: "designCompleteDate", value: e })
        },
        {
            label: '设计(人日)：', type: 'number', required: true, component: TextInput, value: designUseDay, name: 'designUseDay',
            onChange: (e, o) => dispatch({ name: "designUseDay", value: e })
        },
        {
            label: '开发完成时间：', required: true, component: DatePicker, value: devCompleteDate, name: 'devCompleteDate',
            onChange: (e, o) => dispatch({ name: "devCompleteDate", value: e })
        },
        {
            label: '开发(人日)：', type: 'number', required: true, component: TextInput, value: devUseDay, name: 'devUseDay',
            onChange: (e, o) => dispatch({ name: "devUseDay", value: e })
        },
        {
            label: '测试完成时间：', required: true, component: DatePicker, value: testCompleteDate, name: 'testCompleteDate',
            onChange: (e, o) => dispatch({ name: "testCompleteDate", value: e })
        },
        {
            label: '测试(人日)：', type: 'number', required: true, component: TextInput, value: testUseDay, name: 'testUseDay',
            onChange: (e, o) => dispatch({ name: "testUseDay", value: e })
        },
        {
            label: '研发任务ID：', component: TextInput, value: tracerId, placeholder: '请输入研发任务ID',
            onChange: (e, o) => dispatch({ name: "tracerId", value: e })
        }
    ]

    return <>
        <Dialog className="create-task-dialog" confirm={confirm} cancel={props.cancel} {...props} >
            <div className='text_onInput span2'>
                <span>项目：</span> {projectId}
            </div>
            <InputGroup ref={groupRef}>
                {
                    _.map(optionData, (o, i) => <FormInputV horizontal key={i} className={_.get(o, 'cn')}{...o} />)
                }
            </InputGroup>
            <p>研发任务标题：暂时还没有设计接口</p>
        </Dialog>
    </>
}


function FormInputV(props) {
    const { isShow = true } = props
    return isShow && <FormInput {...props} />
}

const issueTypeOpt = [
    { text: 'NEW-新增', value: 'NEW' },
    { text: 'BUG-修改', value: 'BUG' },
]
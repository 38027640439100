import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState, useReducer } from 'react'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { isNil } from 'rootnet-core/format'
import { Form, FormInput, Select, CheckBox } from 'rootnet-edit'
import { DataGrid, Messager, Button } from 'rootnet-ui'
import { Tooltip } from 'antd'
import { Icon } from '../../../../../../../components'
import { strParams } from '../../../../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../../../../common/ConvertOptions'
import formattedData from './formattedData'
import WarningLog from './warningLog'
import './index.scss'

const MANAGEMENT_INFORMATION_URL = '/releaseRelation/getVersionContent' //releasebasic 基础版本
const SUBPRODUCT_URL = '/releaseRelation/getSubProduct' //查询子产品信息
const VERSION_URL = '/releaseRelation/getReleaseInfo' //版本
const OPERATE_URL = '/releaseRelation/maintainReleaseRelation' // 添加 编辑
const VERSION_STATE_URL = '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=VERSIONSTATE'

const getColumns = (props) => {
  const { basicVersionId, currentVersionId, versionStateOpt, setList, setRelateReleaseIds, isDetail, setParams, setIsAdd } = props
  return [
    {
      header: '产品', bind: 'productName', width: 230, convert: (v, o, i, d) => {
        if (o.colspan === 0) return { value: clearProduch(), rowSpan: _.size(_.get(_.groupBy(d, 'productId'), `${o.productId}`)) }
        return clearProduch()
        function clearProduch() {
          return <div className='clear-produch'>
            {v}
            {
              !isDetail && <Icon name='shibai' onClick={() => {
                const filterData = _.filter(d, list => (o.productId === list.productId && isNil(list.basicsVersions)))
                setRelateReleaseIds(x => _.concat(x, _.map(_.filter(filterData, j => j.relateRelease === '1'), obj => obj.currentVersions)))
                setList(x => {
                  const currentVersionList = x.currentVersionList
                  const ids = _.map(filterData, o => o.currentVersions)
                  return _.assign({}, x, {
                    currentVersionList: _.filter(currentVersionList, o => !_.includes(ids, o.relateReleaseId)),
                  })
                })
              }} />
            }
          </div>
        }
      }
    },
    { header: '子产品', bind: 'subSysName', width: 200, tooltip: true, },
    {
      header: `基础版本：${basicVersionId}`, align: 'center', children: [
        { header: '关联版本', bind: 'basicsVersions', tooltip: true, width: 180, },
        {
          header: '是否推荐', bind: 'basicsRecommend', width: 70, align: 'center', convert: v => {
            if (isNil(v)) return null
            return v === 'Y' ? '是' : '否'
          }
        },
        { header: '版本状态', bind: 'basicsState', align: 'center', convert: v => convertOptions(v, versionStateOpt) },
      ]
    },
    {
      header: `当前版本：${currentVersionId}`, align: 'center', class: 'current-version-background', children: [
        { header: <DtoVersion />, bind: 'currentVersions', tooltip: true, width: 200, convert: (v, o, i, d) => convertEditField(v, o, 'currentVersions', d), class: 'current-version-background' },
        { header: '是否推荐', bind: 'currentRecommend', width: 70, align: 'center', convert: (v, o) => convertEditField(v, o, 'currentRecommend'), class: 'current-version-background' },
        { header: '版本状态', bind: 'currentState', align: 'center', convert: v => convertOptions(v, versionStateOpt), class: 'current-version-background' },
      ]
    },
  ]

  function DtoVersion() {
    return <>
      关联版本
      {
        !isDetail &&
        <CheckBox style={{ height: 'auto', marginLeft: 28 }} onChange={v => {
          if (v) {
            const arr = []
            setList(x => {
              const basicVersionList = x.basicVersionList
              const currentVersionList = x.currentVersionList
              _.forEach(basicVersionList, o => {
                if (!_.some(currentVersionList, d => `${d.productId}${d.subSysId}${d.relateReleaseId}` === `${o.productId}${o.subSysId}${o.relateReleaseId}`)) {
                  return arr.push({ ...o, relateRelease: "2", dto: true })
                }
              })
              return _.assign({}, x, { currentVersionList: _.concat(currentVersionList, arr) })
            })
          } else {
            setList(x => {
              return _.assign({}, x, {
                currentVersionList: _.filter(x.currentVersionList, o => isNil(o.dto))
              })
            })
          }
        }}>
          同前版本
        </CheckBox>
      }
    </>
  }

  function convertEditField(v, o, bind, list) {
    if (isDetail) {
      if (bind === 'currentVersions') return v
      if (bind === 'currentRecommend') {
        if (isNil(v)) return null
        return v === 'Y' ? '是' : '否'
      }
    }

    if (bind === 'currentRecommend') {
      return <FormInput
        style={{ margin: 0 }}
        value={v === 'Y'}
        component={CheckBox}
        onChange={flag => {
          setList(x => _.assign({}, x, {
            currentVersionList: _.map(x.currentVersionList, d => {
              if (`${d.productId}${d.subSysId}${d.relateReleaseId}` === `${o.productId}${o.subSysId}${o.currentVersions}`) {
                return { ...d, recommendFlag: flag ? 'Y' : 'N' }
              }
              return d
            })
          }))
        }}
      />
    }

    if (bind === 'currentVersions' && !isNil(o.basicsVersions) && isNil(v)) {
      return <div className='operation-icon'>
        <Tooltip title='同前版本'>
          <span>
            <Icon className='edit-icon' name='fuzhi' onClick={() => {
              setList(x => {
                return _.assign({}, x, {
                  currentVersionList: _.concat(x.currentVersionList, [{
                    productId: o.productId,
                    productName: o.productName,
                    recommendFlag: o.basicsRecommend,
                    relateRelease: "2",
                    relateReleaseId: o.basicsVersions,
                    releaseId: currentVersionId,
                    state: o.basicsState,
                    subSysId: o.subSysId,
                    subSysName: o.subSysName,
                  }])
                })
              })
            }} />
          </span>
        </Tooltip>
        <Tooltip title='添加'>
          <span>
            <Icon className='edit-icon' name='tianjia' onClick={() => {
              setIsAdd(true)
              setParams(_.pick(o, ['productId', 'subSysId']))
            }} />
          </span>
        </Tooltip>
      </div>
    }

    return <div
      className='convert-edit-field-style'
    >
      {v}
      <div className='operation-icon'>
        {
          o.relateRelease === '2' &&
          <Tooltip title='添加'>
            <span>
              <Icon className='edit-icon' name='tianjia' onClick={() => {
                setIsAdd(true)
                setParams(_.pick(o, ['productId', 'subSysId']))
              }} />
            </span>
          </Tooltip>
        }
        <Tooltip title='删除'>
          <span>
            <Icon className='edit-icon' name='shanchu' onClick={() => {
              o.relateRelease === '1' && setRelateReleaseIds(x => _.concat(x, [o.currentVersions]))
              setList(x => _.assign({}, x, {
                currentVersionList: _.filter(x.currentVersionList, d => {
                  return `${d.productId}${d.subSysId}${d.relateReleaseId}` !== `${o.productId}${o.subSysId}${o.currentVersions}`
                })
              }))
            }} />
          </span>
        </Tooltip>
      </div>

    </div>
  }

}

const RootCheckBox = (props) => {
  return <CheckBox {...props}>推荐</CheckBox>
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  // autoFill: true,
});

const HFormInput = props => <FormInput horizontal componentWidth={240} {...props} />

export default function VersionAssociated(props) {
  const { currentId, detailData, versionMode, setVersionMode, setVersionCheck, checkOpen, setCheckOpen, } = props
  const [params, setParams] = useState()
  const [isAdd, setIsAdd] = useState(false)
  const [list, setList] = useState()
  const [relateReleaseIds, setRelateReleaseIds] = useState([])
  const { doFetch: getInfo, data: infoData } = useGet()
  const { doFetch: getSubProductFetch, data: subProductData } = useGet()
  const { data: versionStateRes } = useGet(VERSION_STATE_URL)
  const { doFetch: getVersionFetch, data: versionData } = usePost()
  const { doFetch: getFetch } = usePost()
  const [hint, forceUpdate] = useReducer((x) => x + 1, 0)
  const { productId, subSysId } = useMemo(() => params || {}, [params])

  const isDetail = useMemo(() => versionMode === 'detail', [versionMode])

  const versionStateOpt = useMemo(() => {
    if (_.isEmpty(versionStateRes)) return []
    return convertGlobalConstOptions(versionStateRes)
  }, [versionStateRes])

  const getVersion = useCallback(({ productId, subSysId }) => {
    getVersionFetch(VERSION_URL, _.assign({ productid: productId }, !isNil(subSysId) && { subsysid: subSysId }))
  }, [getVersionFetch])

  const getSubProduct = useCallback(({ productId }) => {
    getSubProductFetch(`${SUBPRODUCT_URL}?productId=${productId}`)
  }, [getSubProductFetch])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionData)) return []
    return _.map(versionData, o => ({
      text: o.releasename,
      value: o.releaseid,
      state: o.state
    }))
  }, [versionData])

  const option = useMemo(() => {
    return getOptions(getColumns({
      basicVersionId: isNil(detailData?.releasebasic) ? '' : detailData?.releasebasic,
      currentVersionId: currentId, setList, setRelateReleaseIds,
      versionStateOpt, isDetail, setParams, setIsAdd,
    }))
  }, [detailData, currentId, versionStateOpt, isDetail])

  const releaseInfoList = useMemo(() => {
    if (_.isEmpty(_.get(list, 'releaseInfoList', []))) return []
    return _.map(_.get(list, 'releaseInfoList', []), o => ({
      text: `${o.productId}-${o.productName}`,
      value: o.productId,
      txt: o.productName,
    }))
  }, [list])

  const subProductOpt = useMemo(() => {
    if (_.isEmpty(subProductData)) return []
    return _.map(subProductData, o => ({
      text: `${o.subSysId}-${o.subSysName}`,
      value: o.subSysId,
      txt: o.subSysName,
    }))
  }, [subProductData])

  const dataSource = useMemo(() => {
    if (_.isNil(list)) return []
    const data = formattedData(list)
    return data
  }, [list])

  const filterVersionOpt = useMemo(() => {
    if (_.isEmpty(versionOpt)) return []
    const ids = _.map(dataSource, o => o.currentVersions)
    return _.filter(versionOpt, o => !_.includes(ids, o.value))
  }, [dataSource, versionOpt])

  const refresh = useCallback(() => {
    if (isNil(detailData?.releasebasic) || isNil(currentId)) return
    getInfo(`${MANAGEMENT_INFORMATION_URL}?${strParams({ basicVersionId: detailData.releasebasic, currentVersionId: currentId })}`)
      .then(res => setList(res))
  }, [currentId, detailData, getInfo])

  useEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    if (isNil(productId)) return
    getVersion({ productId, subSysId })
  }, [productId, getVersion, subSysId])

  useEffect(() => {
    if (isNil(productId)) return
    getSubProduct({ productId })
  }, [getSubProduct, productId])

  const addFun = useCallback(() => {
    setIsAdd(x => {
      if (x) forceUpdate()
      return true
    })
  }, [])

  const delParams = useCallback(() => {
    setIsAdd(false)
    setParams(x => {
      return _.assign({}, ..._.map(x, (v, k) => ({ [k]: k === 'recommendFlag' ? false : null })))
    })
  }, [])

  const confirm = useCallback((flag) => {
    const listData = _.get(list, 'currentVersionList', [])
    getFetch(OPERATE_URL, {
      list: _.map(listData, o => _.omit(o, ['updateTime', 'updateUser'])),
      relateReleaseIds,
      releaseId: currentId
    })
      .then(() => {
        refresh()
        setRelateReleaseIds([])
        delParams()
        setVersionMode('detail')
        Messager.show('关联成功', { icon: 'success' })
        if (flag) {
          setCheckOpen(false)
          setVersionCheck(false)
        }
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [currentId, delParams, getFetch, list, refresh, relateReleaseIds, setCheckOpen, setVersionCheck, setVersionMode])

  const saveData = useCallback(() => {
    if (isNil(params?.productId) || isNil(params?.versionId)) return
    const { productId, versionId, recommendFlag, subSysId } = params
    const state = _.get(_.find(versionOpt, o => o.value === versionId), 'state', '')
    const subSysName = _.get(_.find(subProductOpt, o => o.value === subSysId), 'txt', '')
    const productName = _.get(_.find(releaseInfoList, o => o.value === productId), 'txt', '')
    setList(x => _.assign({}, x, {
      currentVersionList: _.concat(x.currentVersionList, [{
        ...params,
        productName,
        relateReleaseId: versionId,
        recommendFlag: recommendFlag ? 'Y' : 'N',
        isAdd: true,
        subSysName,
        subSysId: isNil(subSysId) ? '' : subSysId,
        state,
        releaseId: currentId,
        relateRelease: '2',
      }])
    }))
    delParams()
  }, [currentId, delParams, params, releaseInfoList, subProductOpt, versionOpt])

  const cancel = useCallback((flag = true) => {
    setList(_.clone(infoData))
    setRelateReleaseIds([])
    flag && setVersionMode('detail')
  }, [infoData, setVersionMode])

  const onChange = useCallback((o, bind) => {
    if (bind === 'productId') {
      o.subSysId = null
      o.versionId = null
    }
    if (bind === 'subSysId') o.versionId = null
    return setParams(o)
  }, [])

  useEffect(() => {
    if (hint > 0) Messager.show('请保存数据后，在进行操作', { 'icon': 'error' })
  }, [hint])

  useEffect(() => {
    if (isDetail) {
      delParams()
      cancel(false)
    }
  }, [cancel, delParams, isDetail])

  useEffect(() => {
    if (_.isEmpty(infoData) || _.isEmpty(list)) return
    const editIds = _.map(list.currentVersionList, o => o.relateReleaseId)
    const defaultIds = _.map(infoData.currentVersionList, o => o.relateReleaseId)
    if (_.size(editIds) !== _.size(defaultIds)) return setVersionCheck(true)
    if (!_.every(defaultIds, v => _.includes(editIds, v))) return setVersionCheck(true)
    return setVersionCheck(false)
  }, [infoData, list, setVersionCheck])

  return (
    <div className='version-associated'>
      <div className='version-associated-form'>
        <div className='version-associated-form-data'>
          <DataGrid data={dataSource} option={option} />
        </div>
        {
          (isAdd && !isDetail) &&
          <div className='add-version-item'>
            <Form value={params} onChange={onChange} >
              <HFormInput bind='productId' component={Select} options={releaseInfoList} search placeholder='请选择产品' />
              <HFormInput bind='subSysId' component={Select} options={subProductOpt} search placeholder='请选择子产品' />
              <HFormInput bind='versionId' component={Select} options={filterVersionOpt} search placeholder='请选择版本' />
              <HFormInput bind='recommendFlag' component={RootCheckBox} componentWidth={60} />
            </Form>
            <div className='action-icon'>
              <div className='add-color' onClick={saveData}>添加</div>
              <div className='del-color' onClick={delParams}>删除</div>
            </div>
          </div>
        }

        {
          !isDetail &&
          <div className='add-data-item' onClick={addFun}>
            <Icon name='fix-xinzeng' /> 添加
          </div>
        }
      </div>
      {
        !isDetail &&
        <div className='footer'>
          <Button primary onClick={confirm}>保存</Button>
          <Button onClick={cancel}>取消</Button>
        </div>
      }
      {checkOpen && <WarningLog confirm={confirm} close={() => { setCheckOpen(false); setVersionCheck(false); cancel() }} />}
    </div>
  )
}

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Dialog, Messager, Button } from 'rootnet-ui'
import { Form, FormInput, Display } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/es/hooks'
import { getTaskFormConfig } from '../../impProjectMgt/impProjectDetail/impTaskInfoDialog/getTaskFormConfig'
import RichTextEditor from '../../../common/richTextEditor/TinyEditor'
import gd from '../../../../base/global'
import { strParams } from '../../../../project_share/utils/utils'
import ImpReportWorkTimeDialog from '../../impProjectMgt/impProjectDetail/impReportWorkTimeDialog/ImpReportWorkTimeDialog'
import './TaskInfoDialog.scss'

const optionsUrls = [
  '/common/globalconst?globalConst=PlanStatus',
  '/common/globalconst?globalConst=PRIORITYLIST',
  '/common/globalconst?globalConst=segment',
  '/common/userinfo',
]

const milestoneFlagOptions = [
  { text: '是', value: '1' },
  { text: '否', value: '0' },
]

const HFormInput = (props) => <FormInput labelWidth={120} componentWidth={200} horizontal {...props} />

export default function TaskInfoDialog(props) {
  const { mode: initMode, taskId, principal, projectId, taskName, close, search } = props
  const [mode, setMode] = useState(initMode)
  const [formInfo, setFormInfo] = useState()
  const [params, setParams] = useState()
  const [taskTimeInfo, setTaskTimeInfo] = useState()
  const [formError, setFormError] = useState()
  const [showReportWorkTime, setShowReportWorkTime] = useState(false)
  const { data: allOptionsRes, loading: allLoading } = useGet(optionsUrls)
  const { doFetch: getInfo, loading } = useGet()
  const { doFetch: getTaskTimeInfo } = useGet()
  const { doFetch: update } = usePost()
  const richEditorRef = useRef()

  const logLoading = useMemo(() => allLoading || loading, [allLoading, loading])

  const isDetailMode = useMemo(() => mode === 'detail', [mode])

  const [isPrincipal, isTaskPrincipal] = useMemo(() => {
    return [
      principal === _.get(gd, '_user.operator_id'),
      _.get(formInfo, 'principal') === _.get(gd, '_user.operator_id')
    ]
  }, [formInfo, principal])

  useEffect(() => {
    getTaskTimeInfo('/worktime/project/detail?' + strParams({
      userAccount: gd.User.operator_id,
      projectType: 'SERVICE',
      taskId,
    })).then(res => setTaskTimeInfo(_.head(res)))
      .catch((err) => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [getTaskTimeInfo, taskId])

  const refresh = useCallback(() => {
    if (_.isNil(taskId)) return
    getInfo('/implementTask/list/info?id=' + taskId).then(res => {
      setParams(res)
      setFormInfo(res)
      richEditorRef.current.setContent(_.get(res, 'detail') ? _.get(res, 'detail') : '')
    }).catch((err) => {
      Messager.show(err._message, { icon: 'error' })
    })
  }, [taskId, getInfo])

  const [planStatusOptions, priorityOptions, segmentOptions, allUserRes] = useMemo(() => {
    if (_.isEmpty(allOptionsRes)) return []
    const [d1, d2, d3, d4] = allOptionsRes
    const planStatusAllOptions = _.map(d1, x => ({ label: x.displayName, text: x.displayName, value: x.interiorId }))
    const planStatusOptions = _.filter(planStatusAllOptions, x => _.includes(['01', '02', '03', '04', '06'], x.value))
    const priorityOptions = _.map(d2, x => ({ label: x.displayName, text: x.displayName, value: x.interiorId }))
    const segmentOptions = _.map(d3, x => ({ label: x.displayName, text: x.displayName, value: x.interiorId, sortSerial: x.sortSerial }))
    return [planStatusOptions, priorityOptions, segmentOptions, d4]
  }, [allOptionsRes])

  const completedNotesRequired = useMemo(() => {
    if (_.isNil(params)) return false
    return params['milestoneFlag'] === '1'
  }, [params])

  const formConfig = useMemo(() => {
    return getTaskFormConfig({
      planStatusOptions, priorityOptions, segmentOptions, milestoneFlagOptions,
      allUserRes, completedNotesRequired, mode
    })
  }, [planStatusOptions, priorityOptions, segmentOptions, allUserRes, completedNotesRequired, mode])

  const requiredKeyList = useMemo(() => {
    return _.map(_.filter(formConfig, x => _.get(x, 'required')), 'bind')
  }, [formConfig])

  const saveBtnDisabled = useMemo(() => {
    return _.some(requiredKeyList, key => {
      const value = _.get(params, key)
      return _.isNil(value) || value === ''
    })
  }, [requiredKeyList, params])

  const submit = useCallback(() => {
    const postParams = { ...params, serial: Number(params?.maxSerial) + 1, detail: richEditorRef.current.getContent() }
    const url = '/implementTask/update'
    update(url, postParams).then(() => {
      Messager.show('修改成功', { icon: 'success' })
      setMode('detail')
      search()
      refresh()
    }).catch((err) => {
      Messager.show(err._message, { icon: 'error' })
    })
  }, [params, refresh, search, update])

  useEffect(() => {
    refresh()
  }, [refresh])

  const onFormChange = useCallback((changeObj, bind) => {
    let personParams = {}
    if (bind === 'principal') {
      let newUserList = params['userList']
      if (!_.isNil(params['principal'])) {
        newUserList = _.filter(newUserList, x => x !== params['principal'])
      }
      newUserList = _.union(newUserList, [changeObj['principal']])
      personParams = { userList: _.clone(newUserList) }
    }
    if (bind === 'userList') {
      if (!_.isNil(params['principal'])) {
        if (!_.includes(changeObj['userList'], params['principal'])) {
          return Messager.show('负责人必须为参与人')
        }
      }
    }
    setParams(oldObj => {
      const newObj = { ...oldObj, ...changeObj, ...personParams }
      if (_.get(newObj, 'planBeginDate') && _.get(newObj, 'planEndDate')) {
        if (_.includes(['planBeginDate', 'planEndDate'], bind)) {
          if (_.get(newObj, 'planBeginDate') > _.get(newObj, 'planEndDate')) {
            if (bind === 'planBeginDate') {
              Messager.show('计划开始时间不能晚于计划结束时间')
            } else {
              Messager.show('计划结束时间不能早于计划开始时间')
            }
            return oldObj
          }
        }
      }
      if (_.get(newObj, 'actualBeginDate') && _.get(newObj, 'actualEndDate')) {
        if (_.includes(['actualBeginDate', 'actualEndDate'], bind)) {
          if (_.get(newObj, 'actualBeginDate') > _.get(newObj, 'actualEndDate')) {
            if (bind === 'actualBeginDate') {
              Messager.show('实际开始时间不能晚于实际结束时间')
            } else {
              Messager.show('实际结束时间不能早于实际开始时间')
            }
            return oldObj
          }
        }
      }
      return newObj

    })
  }, [params])

  return (
    <Dialog
      header={'任务'}
      className={'board-task-info-dialog'}
      footerVisible={false}
      cancel={close}
      loading={logLoading}
    >
      <div className={cls('board-content', { 'detail-mode': isDetailMode })}>
        <Form value={params} onChange={onFormChange} error={formError} setError={setFormError}>
          {
            _.map(formConfig, config => {
              return <HFormInput {...config} key={config.bind}
                component={isDetailMode ? Display : _.get(config, 'component')}
                bindInConvert={isDetailMode ? v => v : _.get(config, 'bindInConvert')}
                bindOutConvert={isDetailMode ? v => v : _.get(config, 'bindOutConvert')}
                disabledList={[_.get(params, 'principal')]}
              />
            })
          }
        </Form>
        <div className="rich-text-form-item flex">
          <div className="mock-label">任务描述</div>
          <div className="rich-text-area" style={{ display: isDetailMode ? 'none' : 'unset' }}>
            <RichTextEditor ref={richEditorRef} />
          </div>
          <div className="rich-text-value-show-wrap" style={{ display: isDetailMode ? 'unset' : 'none' }}>
            {
              _.isNil(_.get(params, 'detail')) || _.get(params, 'detail') === '' ?
                <div>-</div>
                : <div dangerouslySetInnerHTML={{ __html: _.get(params, 'detail') }} />
            }
          </div>
        </div>
      </div>

      <div className='footer'>
        {
          isDetailMode && (isPrincipal || isTaskPrincipal) &&
          <Button primary className={"imp-task-info-footer-btn"} onClick={() => {
            setMode('edit')
            richEditorRef.current.setContent(_.get(params, 'detail') ? _.get(params, 'detail') : '')
          }}>编辑</Button>
        }
        {
          isDetailMode && !_.isNil(taskTimeInfo) &&
          <Button normal className={"imp-task-info-footer-btn"} onClick={() => setShowReportWorkTime(true)}>填写工时</Button>
        }
        {
          isDetailMode && _.isNil(taskTimeInfo) &&
          <Button normal className={"imp-task-info-footer-btn"} onClick={close}>关闭</Button>
        }
        {
          !isDetailMode &&
          <Button normal className={"imp-task-info-footer-btn"} onClick={() => {
            if (mode === 'add') {
              close()
            } else {
              setMode('detail')
              setParams(formInfo)
            }
          }}>取消</Button>
        }
        {
          !isDetailMode &&
          <Button primary className={"imp-task-info-footer-btn"} onClick={submit} disabled={saveBtnDisabled}>保存</Button>
        }
      </div>
      <ImpReportWorkTimeDialog
        {...{ projectId, showReportWorkTime, setShowReportWorkTime, taskTimeInfo, taskId, taskName }}
      />
    </Dialog>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './PersonSelectPop.scss'
import { Input, CheckBox } from 'rootnet-edit';
import Icon from "../../../components/Icon";
import { Tabs, Empty, Tree, Spin } from 'antd';
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import PersonSelectUserItem from "./PersonSelectUserItem";
import { buildUserDeptTree } from "./buildUserDeptTree";
import { Button, Messager } from 'rootnet-ui'
import usePost from "rootnet-biz/es/hooks/usePost";
import {isNil} from "rootnet-core/format";

const { TabPane } = Tabs;

function PersonSelectPop(props) {
    const { onSelectConfirm, setVisible, hiddenUserList, multiple, allowClear, mustList, allowDeptList, allowUserList } = props
    const [activeTab, setActiveTab] = useState('recent')
    const { data: allUserRes, doFetch: getAllUserRes, loading: allUserLoading } = useGet()
    const [selectValue, setSelectValue] = useState(multiple? []: null)
    const { data: allDeptRes } = useGet('/common/department?activeFlag=Y')
    const [expandedKeys, setExpandedKeys] = useState([])
    const [searchText, setSearchText] = useState()
    const [showResignedUser, setShowResignedUser] = useState(false)
    const { doFetch: sendRecent } = usePost()

    const isEmptyAllowList = useMemo(()=>{
        if(!_.isEmpty(allowUserList)) return false
        if(!_.isEmpty(allowDeptList)) return false
        return true
    },[allowDeptList,allowUserList])

    const getChildrenDeptIdList = useCallback((idList)=>{
        if(_.isEmpty(idList)) return []
        const childrenList = []
        const childrenIdList = _.map(_.filter(allDeptRes, x => _.includes(idList, x.parentDept)),'departMentId')
        findChildrenDept(childrenIdList)
        return childrenList
        function findChildrenDept(idList){
            _.forEach(idList, id => {
                if(_.includes(childrenList, id)) return
                childrenList.push(id)
                const children = _.map(_.filter(allDeptRes, x => x.parentDept === id),'departMentId')
                if(!_.isEmpty(children)){
                    findChildrenDept(children)
                }
            })
        }
    },[allDeptRes])

    const showDeptRes = useMemo(()=>{
        if(isEmptyAllowList) return allDeptRes
        let parentIdList = []
        _.forEach(allowDeptList, x => findParentDept(x))
        const childrenList = getChildrenDeptIdList(allowDeptList)
        const showDeptList = _.compact(_.concat(parentIdList,childrenList))
        return _.filter(allDeptRes, x => _.includes(showDeptList, x.departMentId))

        function findParentDept(id){
            if(_.includes(parentIdList, id)) return
            parentIdList.push(id)
            const parentId = _.get(_.find(allDeptRes, x => x.departMentId === id),'parentDept')
            if(!isNil(parentId)){
                findParentDept(parentId)
            }
        }
    },[allowDeptList, allDeptRes, isEmptyAllowList, getChildrenDeptIdList])

    // 在职人员
    const staffRes = useMemo(()=>{
        return _.filter(allUserRes, x => _.get(x, 'indexValidFlag') === 1)
    },[allUserRes])

    // 用户选择显示的所有人员
    const showAllUserRes = useMemo(()=>{
        return showResignedUser ? allUserRes : staffRes
    },[showResignedUser, allUserRes, staffRes])

    const selectedUserAccountList = useMemo(()=>{
        const multipleValue = _.map(selectValue, 'userAccount')
        const radioValue = _.get(selectValue, 'userAccount')
        const radioValueList = _.isNil(radioValue) ? [] : [radioValue]
        return multiple ? multipleValue : radioValueList
    },[multiple, selectValue])

    const filterAllUser = useMemo(() => {
        const filterNoHiddenList = _.isEmpty(hiddenUserList) ? showAllUserRes  : _.filter(showAllUserRes, x => !_.includes(hiddenUserList, x.userAccount))
        let filterAllowList = []
        if(_.isEmpty(allowDeptList) && _.isEmpty(allowUserList)){
            filterAllowList = filterNoHiddenList
        }else{
            const allowAllDeptList = _.compact(_.concat(allowDeptList,getChildrenDeptIdList(allowDeptList)))
            filterAllowList = _.filter(filterNoHiddenList, x => _.includes(allowAllDeptList, x.department) || _.includes(allowUserList, x.userAccount))
        }
        return filterAllowList
    }, [hiddenUserList, showAllUserRes, allowDeptList, allowUserList, getChildrenDeptIdList])

    const filterAllStaff = useMemo(() => {
        const filterNoHiddenList = _.isEmpty(hiddenUserList) ? staffRes  : _.filter(staffRes, x => !_.includes(hiddenUserList, x.userAccount))
        let filterAllowList = []
        if(_.isEmpty(allowDeptList) && _.isEmpty(allowUserList)){
            filterAllowList = filterNoHiddenList
        }else{
            const allowAllDeptList = _.compact(_.concat(allowDeptList,getChildrenDeptIdList(allowDeptList)))
            filterAllowList = _.filter(filterNoHiddenList, x => _.includes(allowAllDeptList, x.department) || _.includes(allowUserList, x.userAccount))
        }
        return filterAllowList
    }, [hiddenUserList, staffRes, allowDeptList, allowUserList, getChildrenDeptIdList])

    useEffect(() => {
        if (_.isEmpty(allUserRes)) return
        if (_.isNil(props.defaultValue)) return
        if (multiple){
            setSelectValue(_.filter(allUserRes, x => _.includes(props.defaultValue, x.userAccount)))
        }else{
            setSelectValue(_.find(allUserRes, x => props.defaultValue === x.userAccount))
        }
    }, [props.defaultValue, allUserRes, multiple])

    useEffect(() => {
        // getAllUserRes('/common/userinfo?indexValidFlag=1')
        getAllUserRes('/common/userinfo')
    }, [getAllUserRes])

    const onSelect = useCallback((selectedItem) => {
        if (multiple) {
            setSelectValue(oldList => {
                const oldUserAccountList = _.map(oldList, 'userAccount')
                if (_.includes(oldUserAccountList, _.get(selectedItem, 'userAccount'))) {
                    return oldList
                } else {
                    return _.concat(oldList, [selectedItem])
                }
            })
        } else {
            setSelectValue(selectedItem)
        }
    }, [multiple])

    const onClear = useCallback((clearKey) => {
        if (multiple) {
            setSelectValue(oldList => {
                return _.filter(oldList, x => x.userAccount !== clearKey)
            })
        } else {
            setSelectValue(null)
        }
    }, [multiple])

    const onExpand = useCallback((expandedKeys) => {
        setExpandedKeys(expandedKeys)
    }, [])

    const onNodeClick = useCallback((key) => {
        setExpandedKeys(oldList => {
            if (_.includes(oldList, key)) {
                return _.filter(oldList, x => x !== key)
            } else {
                return [...oldList, key]
            }
        })
    }, [])

    const getAllChildDeptId = useCallback((deptId)=>{
        let deptIdList = [deptId]
        findChildNode(deptId)
        function findChildNode(pid){
            const childDepartmentList = _.filter(showDeptRes, x => x.parentDept === pid)
            _.forEach(childDepartmentList, x => {
                deptIdList.push(x.departMentId)
                findChildNode(x.departMentId)
            })
        }
        return deptIdList
    },[showDeptRes])

    const onDeptAddClick = useCallback((e, clickDeptId)=>{
        e.stopPropagation()
        const addDeptList = getAllChildDeptId(clickDeptId)
        const allUserInDept = _.filter(staffRes, x => _.includes(addDeptList, x.department))
        setSelectValue(oldList => {
            const newList = _.concat(oldList,allUserInDept)
            return _.uniqBy(newList, x => x.userAccount)
        })
    },[staffRes, getAllChildDeptId])

    const allUserSelectedInDept = useCallback((deptId)=>{
        if(!multiple) return false
        const allDeptList = getAllChildDeptId(deptId)
        const allUserInDept = _.filter(staffRes, x => _.includes(allDeptList, x.department))
        const allUserInDeptUserAccount = _.map(allUserInDept, 'userAccount')
        const selectedUserAccount = _.map(selectValue, 'userAccount')
        return _.every(allUserInDeptUserAccount, x => _.includes(selectedUserAccount,x))
    },[getAllChildDeptId,selectValue, multiple,staffRes])

    const deptUserTreeData = useMemo(() => {
        return buildUserDeptTree({
            userRes:filterAllStaff,
            deptRes:showDeptRes,
            onNodeClick,
            onSelect,
            selectedUserAccountList,
            onDeptAddClick,
            multiple,
            allUserSelectedInDept
        })
    }, [filterAllStaff, showDeptRes, onNodeClick, onSelect, selectedUserAccountList, onDeptAddClick, multiple,allUserSelectedInDept])

    const showUserList = useMemo(() => {
        if (!searchText) return filterAllUser
        setActiveTab('all')
        return _.filter(filterAllUser, user => {
            return _.includes(user.userName, searchText) || _.includes(user.userAccount, searchText) || _.includes(user.departmentAllPath, searchText) || user.userId === searchText
        })
    }, [searchText, filterAllUser])

    const recentUserList = useMemo(() => {
        const filterRecentUserList = _.filter(filterAllUser, x => !_.isNil(x.recentFlag))
        return _.slice(_.reverse(_.sortBy(filterRecentUserList, 'recentFlag')),0,10)
    }, [filterAllUser])

    const onConfirm = useCallback(() => {
        const multipleValue = _.map(selectValue, 'userAccount')
        const radioValue = _.get(selectValue, 'userAccount')
        const radioValueList = _.isNil(radioValue) ? [] : [radioValue]
        onSelectConfirm(multiple ? multipleValue : radioValue)
        sendRecent('/test_defect/common/people', { current_owners: multiple ? multipleValue : radioValueList })
            .then(() => {
                setVisible(false)
            }).catch(err => {
                setVisible(false)
                Messager.show(err.Message, { icon: 'error' })
            })
    }, [multiple, selectValue, onSelectConfirm, sendRecent, setVisible])

    return <div className={'person-select-pop flex-y'}>
        <Input autoFocus className={'person-search'} placeholder={'请搜索人员'} value={searchText} onChange={setSearchText}
            suffix={<Icon name={'sousuo1'} className={'person-search-icon'} />} />
        <div className="person-select-main-panel flex">
            <div className="person-select-left">
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="全部" key="all">
                        <div className={'all-user flex-y'}>
                            {
                                allUserLoading && <Spin />
                            }
                            {
                                _.map(showUserList, userInfo =>
                                    <PersonSelectUserItem key={`all-${_.get(userInfo, 'userId')}`} {...{ userInfo, onSelect,selectedUserAccountList }} />)
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="常用" key="recent">
                        <div className={'recent-user flex-y'}>
                            {
                                _.map(recentUserList, userInfo =>
                                    <PersonSelectUserItem key={`all-${_.get(userInfo, 'userId')}`} {...{ userInfo, onSelect,selectedUserAccountList }} />)
                            }
                            {
                                allUserLoading && <Spin />
                            }
                            {
                                _.isEmpty(recentUserList) && !allUserLoading &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="部门" key="dept">
                        <div className="dept-user-warp flex">
                            <Tree treeData={deptUserTreeData}
                                onExpand={onExpand}
                                expandedKeys={expandedKeys}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div className="person-select-right flex-y">
                <div className="person-select-right-header flex center-y">
                    <div className="person-selected-num-wrap flex">
                        已选人员
                        {
                            !_.isEmpty(selectValue) && multiple &&
                            <div className="person-selected-num">{_.size(selectValue)}人</div>
                        }
                    </div>
                    {
                        allowClear && <div className="clear-all-selected" onClick={() => setSelectValue(multiple?[]:null)}>
                            清空所选
                        </div>}
                </div>
                <div className="person-select-right-content flex-y">
                    {
                        _.isEmpty(selectValue) &&
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    {
                        multiple &&
                        _.map(selectValue, userInfo =>
                            <PersonSelectUserItem key={`selected-${_.get(userInfo, 'userId')}`} isRemove={!_.includes(mustList,_.get(userInfo, 'userAccount'))} {...{ userInfo, onSelect, onClear }} />)
                    }
                    {
                        !multiple && !_.isEmpty(selectValue) && <PersonSelectUserItem key={`selected-${_.get(selectValue, 'userId')}`} isRemove={allowClear} {...{ userInfo: selectValue, onSelect, onClear }} />
                    }
                </div>
            </div>
        </div>
        <div className="confirm-footer flex center-y">
            <div className="confirm-footer-left">
                <CheckBox value={showResignedUser} onChange={setShowResignedUser}>显示离职人员</CheckBox>
            </div>
            <div className="confirm-footer-right flex">
                <Button normal onClick={() => {
                    setVisible(false)
                }}>取消</Button>
                <Button primary onClick={onConfirm}>确定</Button>
            </div>
        </div>
    </div>
}

export default PersonSelectPop;
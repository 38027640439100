import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../../../utils/publicFun'

const GET_FTP_INFO = '/releaseLaunch/ftp/info' //获取登录FTP信息
const FILE_LIST_URL = '/releaseLaunch/fileList' // 获取文件列表

export default function GetFileListLog(props) {
  const { customerId, releaseId, close, changeFile } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const { doFetch: getInfo } = useGet()
  const { doFetch } = usePost()

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    doFetch(`${FILE_LIST_URL}?${strParams({ releaseId })}`, params)
      .then(res => {
        changeFile(x => _.assign({}, x, { fileList: res }))
        close()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, releaseId, params, check, changeFile, close])

  useEffect(() => {
    getInfo(`${GET_FTP_INFO}?${strParams({ customerId, releaseId })}`)
      .then(res => {
        setParams(_.assign({}, { ...res, custId: customerId }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getInfo, doFetch, customerId, releaseId])

  return (
    <Dialog header='获取文件列表' cancel={close} confirm={confirm} style={{ width: 450 }}>
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput required horizontal labelWidth={70} componentWidth={330} label='用户名' bind='userId' />
        <FormInput required horizontal labelWidth={70} componentWidth={330} label='密码' bind='pwd' />
        <FormInput required horizontal labelWidth={70} componentWidth={330} label='FTP路径' bind='ftpPath' />
      </Form>
    </Dialog>
  )
}

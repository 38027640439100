import React, { useMemo, useContext } from 'react'
import _, { isNull } from 'lodash'
import { Dialog, DataGrid } from 'rootnet-ui'
import { Box } from './commonComponent'
import { TextIconBtn } from './TextIconBtn'
import { ScheduleManagementContext } from './Context'

const gridOptsFor = (columns) => ({
  columns,
  autoFill: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
});

const defaultInsertion = {
  header: "导入失败原因",
  bind: "failureReason",
  convert: (v, o) => <span style={{ color: 'red' }}> {o?.failureReason || o?.failReason}</span>,
  sortable: true,
  tooltip: true,
  width: 140,
}

export default function ImportFiltering(props) {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const { closeAll, data, defaultOptions, title, abnormal, isObj, params, exportFieldname, showExport, suffixName = 'Desc' } = props
  const defaultOption = _.cloneDeep(_.isArray(defaultOptions) ? defaultOptions : defaultOptions.columns)

  const option = useMemo(() => {
    _.forEach(defaultOption, column => {
      column.csvConvert = (v, o) => exportText(v, o, column)
      if (_.has(column, 'convert') && column.bind === 'createTime') return
      column.convert = (v, o) => replaceField(v, o, column)
    })
    return gridOptsFor(_.filter(defaultOption, o => !_.includes(['操作', '#', '', undefined], o.header)))
    function replaceField(v, o, column) {
      const val = _.isNil(column?.converts) ? v : column.converts(v, o)
      const bind = _.get(column, 'bind')
      if (o[`${bind}${suffixName}`] === '0') return <span style={{ color: 'red' }}>此字段为必填项</span>
      if (!isNull(o[`${bind}${suffixName}`])) return <span style={{ color: 'red' }}> {val}</span>
      return val
    }
    function exportText(v, o, column) {
      const val = _.isNil(column?.converts) ? v : column.converts(v, o)
      const bind = _.get(column, 'bind')
      if (o[`${bind}${suffixName}`] === '0') return '此字段为必填项'
      return val
    }
  }, [defaultOption, suffixName])

  const errorOption = useMemo(() => {
    const { columns } = option
    const column = _.clone(columns)
    column.unshift(defaultInsertion)
    return _.assign({}, option, { columns: column })
  }, [option])

  return (
    <Dialog
      cancel={closeAll}
      header={'执行结果'}
      className='importFiltering'
      cancelButtonText='关闭'
      confirmButtonVisible={false}
    >
      <Box title={`符合要求（这些数据已提交）共计：${_.size(_.get(data, '[0]'))}条`} data={_.get(data, '[0]')}>
        <DataGrid option={option} data={_.get(data, '[0]')} />
      </Box>
      <Box
        title={`不符合要求（这些数据不会被导入）共计：${_.size(_.get(data, '[1]'))}条`}
        data={_.get(data, '[1]')}
        extra={showExport && <TextIconBtn text='导出' icon={'daochu'} onClick={_.debounce(download, 300)} />}
      >
        <DataGrid option={errorOption} data={_.get(data, '[1]')} />
      </Box>
    </Dialog>
  )

  function download() {
    let abnormalData = _.get(data, '[1]')
    if (isObj) abnormalData = _.assign({}, params, { [exportFieldname]: _.get(data, '[1]') })
    downloadPostProgress(abnormal, abnormalData, `${title}-异常数据`)
  }

}

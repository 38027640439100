import React from 'react'
import { Dialog } from 'rootnet-ui'
import './index.scss'
import TrackingDetailContent from './trackingDetailContent'

export default function TrackingDetailDialog(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog
      header={'研发任务详情'}
      headerVisible={false}
      footerVisible={false}
      className={'tracking-detail-dialog'}
    >
      <TrackingDetailContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Options from "./Options";
import {Icon} from "../../../project_share/components";
import {Tooltip, DataGrid} from 'rootnet-ui'
import {N2} from "rootnet-core/format";
import _ from "lodash";
import {Box} from "../../common/commonComponent";
import {useGet} from "../../../utils/hook";
import {compose, strParams} from "../../../utils/publicFun";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import './ContractInfo.scss'
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import CostDetails from "../../costManage/projectManagement/controls/costDetails";

const formatTime = compose(dateFormat('YYYY-MM-DD'),toDate.str14ToDate);

const usedCostHeader = <span>
  已用成本<Tooltip title='可以通过点击当前列数据查看对应的成本明细(支持导出)'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
</span>

function yearFirstEnd() {
    const firstDay = new Date(new Date().setFullYear((new Date().getFullYear()-1)))
    const endDay = new Date()
    return {
        beginDate: dateFormat('YYYYMMDD', firstDay),
        endDate: dateFormat('YYYYMMDD', endDay),
    }
}

const getColumns = (props) => {
    const {handleTreeClick, projectStatusOptions, setCostDetail} = props
    return [
        {header: '合同/项目名称', bind: 'contractName', width: 400, tooltip: true, convert: addLeftIcon },
        {header: '项目状态', bind: 'projectStatus', width: 150, tooltip: true, convert:v =>  convertOptions(v,projectStatusOptions)},
        {header: '负责人', bind: '', width: 150, tooltip: true, convert: (v,o) =>  o.isContract?o.businessAuditorName:o.projectManagerName},
        {header: '合同编号', bind: 'contractNum', width: 150, tooltip: true},
        {header: '合同签署日期', bind: 'signDate', width: 150, tooltip: true, convert: formatTime},
        {header: '交付日期', bind: 'deliveryDate', width: 150, tooltip: true},
        { header: '预算总成本', bind: 'budgetCost', width: 95, align: 'right', tooltip: true, convert: (v, o) => {
                let bgColor = '#33CC8A'
                let costPercent = calCostPercent(o)
                if(costPercent >= 100){
                    bgColor = '#F5221B'
                }else if(costPercent >= 80){
                    bgColor = '#FAAD14'
                }
                return <Tooltip title={`已用成本${costPercent}%`}>
                    <div className={'budget-cost-wrap flex-y'}>
                        <div className={'budget-cost-value'}>{N2(v)}</div>
                        <div className="progress-bg">
                            <div className={'progress'} style={{background: bgColor, width: `${_.min([costPercent,100])}%`}}/>
                        </div>
                    </div>
                </Tooltip>
            } },
        {header: '已分配成本', bind: 'assignedCost', width: 95, align: 'right', convert: N2, tooltip: true},
        {header: '未分配成本', bind: 'assignableCost', width: 95, align: 'right', convert: N2, tooltip: true},
        {header: usedCostHeader, bind: 'usedCost', width: 95, align: 'right', tooltip: true, convert: convertUsedCost},
        { header: '预算总收入', bind: 'budgetRev', width: 95, align: 'right', convert: N2, tooltip: true },
        { header: '预算利润率', bind: 'profit', width: 90, align: 'right', convert: (v, o) => `${isFinite((_.round((o.budgetRev - o.budgetCost) / o.budgetRev * 100, 1)).toFixed(1)) ? (_.round((o.budgetRev - o.budgetCost) / o.budgetRev * 100, 1)).toFixed(1) : 0}%`, tooltip: true },
        { header: '项目ID', bind: 'id', width: 165, tooltip: true, },
        { header: '客户', bind: 'customerName', width: 90, tooltip: true },
        { header: '核价单号', bind: 'priceId', width: 200, tooltip: true, },
    ]

    function convertUsedCost(v, o) {
        if (_.isNil(v)) return null
        const showValue = <span>{N2(v)}</span>
        return <div className='col-hover-blue' onClick={() => clickUsedCost(o)} style={{ color: v > o.budgetCost?'red':'#5477FF' }}>{showValue}</div>
        function clickUsedCost(o) {
            setCostDetail({ projectName: o.projectName, searchParams: o.isContract?o.contractNum:o.id,priceFlag:'Y',
                csvFileName: o.isContract?`${o.contractNum} ${o.contractName}`:o.projectName })
        }
    }

    function calCostPercent(o){
        if(_.toNumber(o.usedCost) === 0){
            return 0
        }else if(_.toNumber(o.budgetCost) === 0 ){
            return 100
        }else{
            return _.round((_.toNumber(o.usedCost)/_.toNumber(o.budgetCost))*100,1)
        }
    }

    function addLeftIcon(v,o,i){
        return <span className='tree-col' style={{ marginLeft: o.isContract? 0 : 30,cursor: o.isContract?'pointer':'' }}  onClick={() => { if(o.isContract) handleTreeClick(o, i) }}>
            <Icon name="arrow_drop_down" className='arrow_drop_down'
                  style={{ transform: o._expand ? 'none' : 'rotate(-90deg)', visibility: _.isEmpty(o.list) ? 'hidden' : '' }} />
            <span dangerouslySetInnerHTML={{ __html: o.isContract? o.contractName : o.projectName }} />
        </span>
    }
}

const getOption = (columns) => {
    return {
        columns,
        fixedLeft: 1,
        autoFill: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
    }
}

const OPTION_URLS = [
    '/common/globalconst?globalConst=PSAProjectStatus'
]

function getInitParams(){
    return ({
        ...yearFirstEnd(),
        contractNum:'',
        contractName:'',
        projectName:'',
        principal:null,
        customer:null,
        projectId:'',
        projectStatus:null,
        projectType:null,
    })
}

function ContractInfo(props) {
    const {data:listRes, doFetch: getList, loading, error} = useGet()
    const [params, setParams] = useState(getInitParams())
    const [showList, setShowList] = useState([])
    const {data: optionsRes} = useGet(OPTION_URLS)
    const [costDetail, setCostDetail] = useState({})

    const [projectStatusOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes
        return [
            convertGlobalConstOptions(d1)
        ]
    },[optionsRes])

    useEffect(()=>{
        const notNullParams = _.pickBy(params, x => !(_.isEmpty(x) || x ===''))
        getList('project/contractlist?priceflag=Y&'+strParams(notNullParams))
    },[getList,params])

    const allList = useMemo(()=>{
        if(_.isEmpty(listRes)) return []
        const expandList = []
        _.forEach(listRes, contractItem => {
            expandList.push({...contractItem,isContract: true,parentContract: '',_expand: false, _level: 0})
            if(!_.isEmpty(_.get(contractItem, 'list'))){
                _.forEach(_.get(contractItem, 'list'), projectItem => {
                    expandList.push({...projectItem,isContract: false,parentContract: _.get(contractItem,'contractNum'),_expand: false, _level: 1})
                })
            }
        })
        return expandList
    },[listRes])

    useEffect(()=>{
        const firstLevelList = _.filter(allList, x => x.parentContract === '')
        setShowList(firstLevelList)
    },[allList])

    const handleTreeClick = useCallback((o, i) => {
        const updateItem = _.assign({}, showList[i], { _expand: !showList[i]._expand })
        if (o._expand) {
            let endIndex = -1
            for (let currentIndex = i + 1; currentIndex < showList.length; currentIndex++) {
                if (showList[currentIndex]._level <= o._level) {
                    endIndex = currentIndex
                    break
                }
            }
            setShowList(showList.slice(0, i).concat(updateItem).concat(endIndex !== -1 ? showList.slice(endIndex) : []))
        } else {
            const addList = _.filter(allList, item => item.parentContract === o.contractNum)
            setShowList(showList.slice(0, i).concat(updateItem).concat(addList).concat(showList.slice(i + 1)))
        }
    }, [allList, showList])

    const option = useMemo(()=>{
        return getOption(getColumns({handleTreeClick, projectStatusOptions,setCostDetail}))
    },[handleTreeClick, projectStatusOptions])

    return <div className='contract-info flex-y fill'>
        <Options initParams={params} search={setParams} {...{projectStatusOptions,getInitParams}}/>
        <Box title={'合同信息'} className={'flex-y x-card-singlegrid'} data={showList} loading={loading} error={error}>
            <DataGrid  className='x-data contract-info-card' data={showList} option={option}/>
        </Box>
        {!_.isEmpty(costDetail) && <CostDetails costProjectDetail={costDetail} setCostProjectDetail={setCostDetail} />}
    </div>
}

export default ContractInfo;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ViewPop from "./ViewPop";
import {TextIconBtn} from "../TextIconBtn";
import {Popover} from "antd";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import {strParams} from "../../../utils/publicFun";

function ViewExtraTextIcon(props) {
    const {getFieldList,allColumns,funcCode,currentViewId, setCurrentViewId,refreshList, replaceParams = null} = props
    const [showViewPop, setShowViewPop] = useState(false)
    const {doFetch: getViewList} = useGet()
    const [viewList, setViewList] = useState([])

    const currentView = useMemo(()=>{
        return _.find(viewList, x => x.id === currentViewId)
    },[viewList,currentViewId])

    const selectCurrentId = useCallback((currentViewId,viewList)=>{
        if(_.isEmpty(viewList)) return
        if(_.includes(_.map(viewList, x => x.id), currentViewId)) return
        // const myViewList = _.filter(viewList, x => x.userAccount !== 'system')
        let currentView = _.find(viewList, x => x.lastViewedFlag === "Y")
        if(_.isNil(currentView)){
            currentView = _.find(viewList, x => x.userAccount === 'system')
        }
        if(_.isNil(currentView)){
            currentView = _.head(viewList)
        }
        const lastViewedId = _.get(currentView,'id')
        setCurrentViewId(lastViewedId)
    },[setCurrentViewId])

    const refreshViewList = useCallback(()=>{
        getViewList('/UserSetting/getViews?'+strParams({funcCode,type: '02'})).then(res => {
            selectCurrentId(currentViewId,res)
            setViewList(_.sortBy(res,'sort'))
        })
    },[funcCode,getViewList,selectCurrentId,currentViewId])

    const refreshFieldList = useCallback(()=>{
        if(_.isNil(currentViewId)) return
        getFieldList('/UserSetting/getDisplayedColumns?viewId='+currentViewId)
    },[currentViewId,getFieldList])

    const afterUpdatedRefresh = useCallback(()=>{
        refreshViewList()
        refreshList(replaceParams)
        refreshFieldList()
    },[refreshViewList,refreshList,refreshFieldList,replaceParams])

    const closeViewPop = useCallback(()=>{
        setShowViewPop(false)
    },[])

    useEffect(()=>{
        if(_.isNil(funcCode)) return
        refreshViewList()
    },[refreshViewList,funcCode])

    useEffect(()=>{
        if(_.isEmpty(allColumns)) return
        refreshList(replaceParams)
    },[refreshList,allColumns,replaceParams])

    useEffect(()=>{
        if(_.isNil(currentViewId)) return
        refreshFieldList()
    },[currentViewId,refreshFieldList])

    return <div className='view-extra-text-icon'>
        <Popover overlayClassName={'view-pop'} content={<ViewPop refresh={afterUpdatedRefresh} {...{viewList,currentViewId,refreshViewList, setCurrentViewId,funcCode,closeViewPop,allColumns}}/>}
                 placement="bottom" trigger="click" open={showViewPop} onOpenChange={setShowViewPop} zIndex={5}>
            <TextIconBtn className={'view-text-wrap'} icon='xinxichaxun' text={`视图${currentView? ' : '+ currentView.subType: ''}`} onClick={()=>setShowViewPop(x => !x)}/>
        </Popover>
    </div>
}

export default ViewExtraTextIcon;
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Tooltip, Table, Tree } from 'antd'
import { Dialog, Button, Pagination } from 'rootnet-ui'
import { Form, FormInput, Select } from 'rootnet-edit'
import { Box } from '../../../common/commonComponent'
import { Icon } from '../../../../components'
import useGet from 'rootnet-biz/lib/hooks/useGet'
import { strParams } from '../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import gd from '../../../../base/global'
import './AssociatedTestcase.scss'
import { selectOption } from '../../../common/commonMethod'
import { filterTreeList } from '../../testCase/components/getTreeList'
import {usePost} from "rootnet-biz/lib/hooks";

const TREE_DATA_URL = '/testcasetable/list' //caseLibraryId
const TREE_LIST_URL = '/test/plan/chooseTestCaseRelated' //libraryId
const MY_CASE_URL = '/test_case/selectMyCase'
const DETAILS_URL = '/test/plan/selectTestPlanInformation' //测试计划中的
const CASE_URL = `/caseLibrary/list`
const OPTIONS_URL = [
  '/common/globalconst?globalConst=casestatus',
  '/common/userinfo?indexValidFlag=1',
]

const treeUrl = (libraryId, params = {}) => {
  return [
    `${TREE_DATA_URL}?${strParams({ caseLibraryId: libraryId, ...params })}`,
    `${TREE_LIST_URL}?${strParams({ libraryId, ...params })}`
  ]
}

const iconColor = {
  req: '#2B78E4',
  case: '#86D735',
  trace: '#7B68EE',
}

const queryCondition = () => ([
  { label: '测试计划中的', key: 0 },
  { label: '全部', key: 1 },
  { label: '我创建的', key: 2 },
])

const getColumns = () => {
  return [
    { title: '标题', dataIndex: 'caseName', key: 'caseName', fixed: 'left', width: '40%', ellipsis: true, },
    { title: 'ID', dataIndex: 'id', key: 'id', render: (v, o) => o[`${_.isNil(o.type) ? 'case' : o.type}Id`] },
    { title: '用例状态', dataIndex: 'statusName', key: 'statusName' },
  ]
}

function getId(data, arr = []) {
  _.forEach(data, o => {
    if (!_.isEmpty(arr)) return
    if (_.isNil(o.children)) return arr.push(_.get(o, 'id'))
    getId(o.children, arr)
  })
  return arr
}

function getInitParams(active, pageSize = 20) {
  const init = {
    0: {
      id: null,
      title: null,
      statusList: null,
      userAccount: null,
    },
    1: {
      caseName: null,
      createUser: null,
      status: null,
    },
    2: {
      pageNum: 1,
      pageSize: pageSize,
      idOrTitle: null,
      statusList: null,
    },
  }
  return init[active]
}

function formatTree(tree, children, level = 0) {
  _.forEach(tree, item => {
    if (item?.caseId) {
      item.title = item.caseName
      item.key = item.id
    } else {
      const childList = _.filter(children, o => item.id === o.tableId)
      if (item.id === '1') item.allCount = _.size(childList)
      item.title = <span key={`${item.id}9525`}>
        {item.name}
        （{_.get(item, 'allCount', 0)}）
      </span>
      item.level = level
      item.key = item.id
      item.icon = <Icon name='wenjianjia' style={{ fontSize: 14 }} />
      if (!_.isEmpty(childList)) {
        const filterData = _.filter(item.children, o => _.find(childList, k => o.caseId === k.caseId))
        const filterChildren = _.filter(childList, o => !_.includes(_.map(filterData, k => k.caseId), o.caseId))
        item.children = item.children ? [...filterChildren, ...item.children] : filterChildren
      }
      if (item?.children) formatTree(item.children, children, level + 1)
    }
  })
  return tree
}

function recursion(data, level = 0) {
  _.map(data, item => {
    const type = _.isNil(item.type) ? 'case' : item.type
    item.caseName = <Tooltip placement="topLeft" title={item.caseName}>
      <span className='title-icon' style={{ background: iconColor[type] }} >{_.upperCase(type)}</span>
      <span>{item.caseName}</span>
    </Tooltip>
    item.level = level
    item.onlyId = _.isNil(item.onlyId) ? item.id : item.onlyId
    recursion(item.testCaseList, level + 1)
  })
  return data
}

export default function AssociatedTestcase(props) {
  const { setRelation, planId, caseId, associatedTestcase } = props
  const [selected, setSelected] = useState([caseId]) //提交的
  const [params, setParams] = useState()
  const [listData, setListData] = useState()
  const [active, setActive] = useState(0)
  const [data, setData] = useState()
  const { error, doFetch } = useGet()
  const { doFetch: doFetchPost } = usePost()
  const { data: allList, doFetch: allFetch } = useGet()

  const [statusOpt, userAccountOpt, versionOpt] = useMemo(() => {
    if (allList) {
      const [d1, d2, d3] = allList
      return [
        convertGlobalConstOptions(d1),
        selectOption(d2, ['userName', 'userAccount', 'userName,userAccount']),
        filterTreeList(d3)
      ]
    }
    return []
  }, [allList])

  const refresh = useCallback((parameter = {}, active) => {
    if (active === 0) {
      if (_.isNil(planId)) return
      const par = _.assign({}, { planId }, parameter,
        parameter?.status && { status: _.join(parameter?.status, ',') })
      doFetchPost(DETAILS_URL,{...par, pageNo: 1, pageSize: 99999}).then(res => {
        setData(res)
        setListData(recursion(res?.lists))
      })
    } else if (active === 1 && !_.isNil(parameter?.libraryId)) {
      doFetch(treeUrl(parameter.libraryId, _.omit(parameter, ['libraryId']))).then(res => {
        setData(res)
        const [d1, d2] = res
        const list = _.filter(d1, o => !_.isNil(o.id))
        setListData(formatTree([{ name: '未规划目录', id: '1', pid: '0' }, ...list], d2))
      })
    } else if (active === 2) {
      doFetch(`${MY_CASE_URL}?${strParams(parameter)}`).then(res => {
        setData(res)
        setListData(_.clone(recursion(res.rows)))
      })
    }
  }, [doFetch, planId, doFetchPost])

  useEffect(() => {
    refresh({}, 0)
  }, [refresh])

  useEffect(() => {
    allFetch(_.concat(OPTIONS_URL, [`${CASE_URL}?login=${gd.User.operator_id}`]))
  }, [allFetch])

  const option = useMemo(() => getColumns(), [])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { setSelected([_.get(_.head(selectedRows), 'id')]) },
    getCheckboxProps: (record) => ({
      disabled: _.isNil(record.caseId),
      name: record.name,
    }),
  };

  function treeChange(v, o) {
    const { node } = o
    if (_.isNil(node?.children)) {
      if (_.isNil(node?.caseId)) return
      setSelected(v)
    } else {
      const id = getId([node]) //_.get(_.head(node.children), 'id', null)
      setSelected(id)
    }
  }

  return (
    <Dialog
      header={'关联用例'}
      className='AssociatedTestcaseDialog'
      confirm={confirm}
      cancel={() => setRelation(false)}
    >
      <SelectQuery pageSize={data?.pageSize} {...{ setParams, refresh, active, setActive, setListData }} />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 8, overflow: 'hidden' }}>
        <Options pageSize={data?.pageSize} {...{ statusOpt, userAccountOpt, versionOpt, refresh, params, setParams, active }} />
        <div className='flex' style={{ flex: 1 }}>
          <div className='query-list'>
            <Box
              data={[0]}
              className='x-card-singlegrid'
              error={error}
            >
              {
                active !== 1 && <Table
                  columns={option}
                  dataSource={listData}
                  pagination={false}
                  size='small'
                  bordered
                  rowKey={record => _.isNil(record.id) ? record.onlyId : record.id}
                  scroll={{ y: 99999 }}
                  childrenColumnName='testCaseList'
                  rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selected,
                    ...rowSelection,
                  }}
                />
              }
              {
                active === 1 && <Tree
                  showLine={{
                    showLeafIcon: false,
                  }}
                  defaultExpandAll
                  blockNode
                  height={358}
                  treeData={listData}
                  selectedKeys={selected}
                  onSelect={treeChange}
                  showIcon
                />
              }
              {active === 2 && <Pagination pageSize={data?.pageSize} total={data?.total} current={data?.pageNum} selector
                onChange={(pageNum, pageSize) => {
                  setParams(x => {
                    const par = _.assign({}, x, { pageNum, pageSize })
                    refresh(par, active)
                    return par
                  })
                }} />}
            </Box>
          </div>
        </div>
      </div>
    </Dialog>
  )

  function confirm() {
    associatedTestcase(selected, planId)
    setRelation(false)
  }
}

function Options(props) {
  const { refresh, params, setParams, statusOpt, userAccountOpt, versionOpt, active, pageSize } = props
  const [libraryId, setLibraryId] = useState(null)
  useEffect(() => { if (active !== 1) { setLibraryId(null) } }, [active])
  return <div className='selectUseCaseOpt'>
    {
      active === 1 &&
      <FormInput
        required
        style={{ marginLeft: 0 }}
        tree
        search
        label='用例库'
        bind='libraryId'
        value={libraryId}
        onChange={(v) => { refresh({ libraryId: v }, active); setParams(x => _.assign({}, x, { libraryId: v })); setLibraryId(v) }}
        component={Select}
        options={versionOpt}
      />
    }
    <div className={'customize-options'}>
      <div className='c-options'>
        <Form value={params} onChange={setParams}>
          {
            _.map(getFormInput(active), item => {
              return <FormInput key={item.bind}   {...item} />
            })
          }
        </Form>
        <div className="search-btn-group flex">
          <Button
            primary
            className={'search-btn'}
            onClick={() => refresh(params, active)}
          >查询</Button>
          <Button className={'reset-btn'} text onClick={() => {
            refresh({ ...getInitParams(active, pageSize), libraryId }, active)
            setParams(_.assign({}, { libraryId }, getInitParams(active, pageSize)))
          }}>重置</Button>
        </div>
      </div>
    </div>
  </div>

  function getFormInput(type) {
    const searchList = {
      0: [
        {
          label: 'ID',
          className: 'searchIconInput',
          prefix: <Icon name='sousuo1' />,
          bind: 'id',
          placeholder: '搜索ID',
        },
        {
          label: '标题',
          className: 'searchIconInput',
          prefix: <Icon name='sousuo1' />,
          bind: 'title',
          placeholder: '搜索标题',
        },
        {
          label: '状态',
          bind: 'statusList',
          component: Select,
          options: statusOpt,
          multiple: true,
          search: true,
          clear: true,
        },
        {
          label: '创建人',
          bind: 'userAccount',
          component: Select,
          options: userAccountOpt,
          search: true,
          clear: true,
        }
      ],
      1: [
        {
          label: '标题',
          bind: 'caseName',
        },
        {
          label: '创建人员',
          bind: 'createUser',
          search: true,
          clear: true,
          component: Select,
          options: userAccountOpt
        },
        {
          label: '状态',
          bind: 'status',
          search: true,
          clear: true,
          component: Select,
          options: statusOpt,
          multiple: true
        }
      ],
      2: [
        {
          label: '搜索ID或标题',
          className: 'searchIconInput',
          prefix: <Icon name='sousuo1' />,
          bind: 'idOrTitle',
          placeholder: '搜索ID或标题',
        },
        {
          label: '状态',
          bind: 'statusList',
          component: Select,
          options: statusOpt,
          multiple: true,
          search: true,
          clear: true,
        },
      ]
    }
    return searchList[type]
  }
}

function SelectQuery(props) {
  const { setParams, active, setActive, refresh, pageSize, setListData } = props

  const parameter = useMemo(() => queryCondition(gd.User.operator_id), [])

  return <div className='query-condition'>
    {
      _.map(parameter, item => {
        return <div key={item.key}>
          <span
            className={active === item.key ? 'active' : ''}
            onClick={() => handClick(item)}
          >
            {item.label}
          </span>
        </div>
      })
    }
  </div>

  function handClick(data) {
    setActive(data.key)
    setListData(null)
    refresh(data.key !== 2 ? {} : getInitParams(data.key, pageSize), data.key)
    setParams(_.assign({}, getInitParams(data.key, pageSize)))
  }

}


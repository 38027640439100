import React from 'react';
import { dateFormat } from 'rootnet-core/dateFormat'

function getColumns(name, setShowDetailDialog) {
  return {
    'service': [
      { header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center' },
      { header: '服务单编号', bind: 'csrecordID', width: 100, tooltip: true, },
      { header: '标题', bind: 'issueDesc', width: 350, tooltip: true, convert: (v, o) => jumpUrl(v,'customerService',o.id)},
      { header: '状态', bind: 'statusName', width: 100, tooltip: true, },
      { header: '创建人', bind: 'createUserName', width: 80, tooltip: true, },
      { header: '创建时间', bind: 'createTime', width: 140, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', new Date(v)) },
    ],
    'task': [
      { header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center' },
      { header: '研发任务编号', bind: 'tracerId', width: 150, tooltip: true, sortable: true, },
      { header: '研发任务名称', bind: 'tracerTitle', width: 200, tooltip: true, sortable: true, convert: (v, o) => jumpUrl(v,'tracker',o.tracerId) },
      { header: '研发任务状态', bind: 'tracerStateName', width: 90, tooltip: true, sortable: true, },
      { header: '创建人', bind: 'createUserName', width: 90, tooltip: true, sortable: true, },
      { header: '创建时间', bind: 'createTime', width: 140, tooltip: true, sortable: true, },
    ],
  }[name]

  function jumpUrl(v, module ,id,) {
    return <div
        className='hover-title-line'
        style={{ color: '#5477ff', cursor: 'pointer' }}
        onClick={() => {
          setShowDetailDialog({
            module: module,
            id: id,
          })
        }}>
      {v}
    </div>
  }
}


export const gridOptsFor = (name, setShowDetailDialog) => ({
  resizable: true,
  fixedLeft: 2,
  autoFill: true,
  nilText: '-',
  emptyText: '-',
  columns: getColumns(name, setShowDetailDialog),
});
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Badge, Tabs, Tooltip } from 'antd'
import { Messager, Button, Loader } from 'rootnet-ui'
import { Display, FormInput, Input } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../../../components'
import ReviewMinutesDetailHeader from './reviewMinutesDetailHeader'
import { isNil } from '../../../../../appraise/components/method'
import ChangeRecord from '../../../../../common/ChangeRecord'
import RequirementUploadArea from '../../../../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea'
import ReviewMinutesDetailRightPanel from './reviewMinutesDetailRightPanel'
import ReviewMinutesDetailInfo from './reviewMinutesDetailInfo'
import ConferenceRecordsDetailTodo from '../../../../conferenceRecords/conferenceRecordsDetail/conferenceRecordsDetailTodo/ConferenceRecordsDetailTodo'
import ConferenceRecordsDetailRelation from '../../../../conferenceRecords/conferenceRecordsDetail/conferenceRecordsDetailRelation/ConferenceRecordsDetailRelation'
import ConferenceRecordsDetailProjectRelation from '../../../../conferenceRecords/conferenceRecordsDetail/conferenceRecordsDetailProjectRelation/ConferenceRecordsDetailProjectRelation'
import ReviewMinutesDetailContentList from './reviewMinutesDetailContentList'
import './index.scss'
import { timeDifference } from '../../../common/commonFun'
import ContentTemplateSelect from '../../../../../common/contentTemplateSelect'
import useContentTemplateNum from '../../../../../common/hooks/useContentTemplateNum'
import EditTipLog from '../../../../../common/contentTemplateSelect/editTipLog'

export default function ReviewMinutesDetailContent(props) {
  const { refreshViewList, currentInfo = {}, close, switchCurrentItem, showChildList, currentIndex } = props
  const { mode: initMode = 'detail' } = currentInfo
  const [mode, setMode] = useState(initMode)
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [infoError, setInfoError] = useState()
  const [participantList, setParticipantList] = useState()
  const [cid, setCid] = useState()
  const [id, setId] = useState()
  const [fileCount, setFileCount] = useState(0)
  const [activeKey, setActiveKey] = useState('reviewContent')
  const [switchDisabled, setSwitchDisabled] = useState(false)
  const [contentLoading, setContentLoading] = useState(true)
  const [issueList, setIssueList] = useState([])
  const infoRichEditorRef = useRef()
  const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0)
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch: getDetail } = useGet()
  const { data: originalParticipantList, doFetch: getParticipantList } = useGet()
  const { doFetch: submitForm } = usePost()
  const [reqList, setReqList] = useState([])
  const [devList, setDevList] = useState([])

  const [openItem, setOpenItem] = useState(null)
  const [templateItem, setTemplateItem] = useState()
  const addContentTemplateNum = useContentTemplateNum()
  const [reviewLibrary, setReviewLibrary] = useState()

  const { reviewCategory } = useMemo(() => editFormData || {}, [editFormData])
  const isReqExplain = useMemo(() => reviewCategory === '02', [reviewCategory])

  useEffect(() => {
    if (reqList && devList) { }
  }, [reqList, devList])
  useEffect(() => {
    const { cid } = currentInfo || {}
    if (!_.isNil(cid)) {
      setCid(cid)
    }
  }, [currentInfo])

  useEffect(() => {
    setEditFormData(formData)
  }, [formData])

  const isDetail = useMemo(() => {
    return mode === 'detail'
  }, [mode])

  const participantListRequired = useMemo(() => {
    const filterList = _.filter(participantList, x => _.includes(['02', '03'], _.get(x, 'attendance')))
    return _.every(filterList, item => !isNil(_.get(item, 'explanation')))
  }, [participantList])

  const refreshDetail = useCallback((cid) => {
    if (_.isNil(cid)) return
    setSwitchDisabled(true)
    setContentLoading(true)
    getDetail('/conferencerecord/info?iid=' + cid).then(res => {
      let detail = { ...res }
      detail['sOurPerformer'] = _.split(_.get(res, 'sOurPerformer'), '^')
      detail['absentPersonnel'] = _.compact(_.split(_.get(res, 'absentPersonnel'), '^'))
      setFormData(detail)
      setReviewLibrary(_.get(detail, 'reviewLibrary'))
      setSwitchDisabled(false)
      setContentLoading(false)
      setCid(cid)
      setId(res.id)
      updateDynamic()
      setIssueList(_.sortBy(_.get(res, 'issuesList'), 'scheduledCompletionDate'))
    }).catch(err => {
      setSwitchDisabled(false)
      setContentLoading(false)
      Messager.show(err._message, { icon: 'error' })
    })
  }, [getDetail, updateDynamic])

  useEffect(() => {
    if (_.isNil(cid)) return
    setSwitchDisabled(true)
    setContentLoading(true)
    getDetail('/conferencerecord/info?iid=' + cid).then(res => {
      let detail = { ...res }
      detail['sOurPerformer'] = _.split(_.get(res, 'sOurPerformer'), '^')
      detail['absentPersonnel'] = _.compact(_.split(_.get(res, 'absentPersonnel'), '^'))
      setFormData(detail)
      setReviewLibrary(_.get(detail, 'reviewLibrary'))
      setSwitchDisabled(false)
      setContentLoading(false)
      setTimeout(() => {
        if (infoRichEditorRef.current) {
          infoRichEditorRef.current.setContent(_.get(detail, 'sRecord') || '')
        }
      }, 1000)
      setCid(cid)
      setId(res.id)
      updateDynamic()
      setIssueList(_.sortBy(_.get(res, 'issuesList'), 'scheduledCompletionDate'))
    }).catch(err => {
      setSwitchDisabled(false)
      setContentLoading(false)
      Messager.show(err._message, { icon: 'error' })
    })
  }, [cid, getDetail])


  const canSubmit = useMemo(() => {
    if (!_.get(editFormData, 'sSubject')) return false
    return !_.some(_.values(infoError), x => x)
  }, [editFormData, infoError])

  const refreshParticipantList = useCallback(() => {
    if (_.isNil(cid)) return
    getParticipantList('/conferencerecord/conference/list?recordId=' + cid).then(res => {
      setParticipantList(res)
    })
  }, [cid, getParticipantList])

  const switchMode = useCallback((resetDesc = false) => {
    if (resetDesc) {
      if (infoRichEditorRef.current) {
        infoRichEditorRef.current.setContent(_.get(formData, 'sRecord') || '')
      }
    }
    setIssueList(_.get(formData, 'issuesList', []))
    setMode(x => x === 'detail' ? 'edit' : 'detail')
  }, [formData])

  const issueListRequired = useMemo(() => {
    const requiredList = ['proposer', 'problemLevel', 'problemDescription', 'problemConfirmed', 'verificationClosed', 'issueHandler', 'scheduledCompletionDate', 'verifier']
    return _.every(issueList, item => {
      return _.every(requiredList, field => !isNil(_.get(item, field)))
    })
  }, [issueList])

  const submit = useCallback(() => {
    if (!canSubmit) return
    if (!issueListRequired) return Messager.show('请填写【问题清单】中的必填项')
    if (!participantListRequired) {
      setActiveKey('participant')
      return Messager.show('请填写【参会情况】中的必填项')
    }
    if (!isReqExplain && isNil(reviewLibrary)) return Messager.show('请填写【评审材料】中的必填项')
    if (isReqExplain && (fileCount <= 0 && isNil(reviewLibrary))) return Messager.show('请上传附件或填写评审资料配置库目录')
    if (JSON.stringify(editFormData) === JSON.stringify(formData)
      && infoRichEditorRef.current.getContent() === _.get(formData, 'sRecord')
      && JSON.stringify(participantList) === JSON.stringify(originalParticipantList)
      && JSON.stringify(issueList) === JSON.stringify(_.get(formData, 'issuesList'))
      && JSON.stringify(reviewLibrary) === JSON.stringify(_.get(formData, 'reviewLibrary'))) {
      switchMode()
      return Messager.show('无变更')
    }
    const addIssueList = _.map(_.filter(issueList, o => _.get(o, 'operatorType') === 'add'), x => _.omit(x, ['id']))
    const addExcept = _.filter(issueList, o => _.get(o, 'operatorType') !== 'add')
    const delIssueList = _.map(_.filter(_.get(formData, 'issuesList'), o => !_.includes(_.map(addExcept, 'id'), o.id)), x => _.assign({}, x, { operatorType: 'del' }))
    const editIssueList = _.map(addExcept, o => {
      const findItem = _.find(_.get(formData, 'issuesList'), x => x.id === o.id)
      return _.assign({}, o, JSON.stringify(findItem) !== JSON.stringify(o) && { operatorType: 'edit' })
    })
    const issueListArr = _.concat(addIssueList, editIssueList, delIssueList)

    const startTime = _.get(editFormData, 'dOccurTimeBegin')
    const endTime = _.get(editFormData, 'dOccurTimeEnd')
    const reviewDuration = (!isNil(startTime) && !isNil(endTime)) ? timeDifference(toDate.str14ToDate(startTime), toDate.str14ToDate(endTime)) : null
    const postParams = _.assign({}, editFormData, {
      dOccurTimeBegin: dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(editFormData, 'dOccurTime'))) +
        ' ' + moment(toDate.str14ToDate(_.get(editFormData, 'dOccurTimeBegin'))).format('HH:mm') + ":00",
      dOccurTimeEnd: dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(editFormData, 'dOccurTime'))) +
        ' ' + moment(toDate.str14ToDate(_.get(editFormData, 'dOccurTimeEnd'))).format('HH:mm') + ":00",
      sRecord: infoRichEditorRef.current.getContent(),
      sOurPerformer: _.join(_.get(editFormData, 'sOurPerformer'), '^'),
      absentPersonnel: _.join(_.get(editFormData, 'absentPersonnel'), '^'),
      reviewDuration,
      reviewLibrary,
    }, { issuesList: _.map(issueListArr, o => _.assign({}, o, { meetingId: cid })) })
    submitForm('/conferencerecord/modify', postParams)
      .then(() => {
        const participantParams = {
          recordId: cid,
          conferenceSituationList: _.map(participantList, x => ({
            recordId: cid,
            attendance: x.attendance,
            conferee: x.conferee,
            explanation: x.explanation,
          }))
        }
        submitForm('/conferencerecord/conference/edit', participantParams).then(() => {
          Messager.show('修改成功', { icon: 'success' })
          refreshDetail(cid)
          refreshParticipantList()
          switchMode()
          refreshViewList()
          addContentTemplateNum(templateItem)
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [cid, canSubmit, formData, editFormData, submitForm, originalParticipantList, participantList, participantListRequired, refreshDetail, refreshParticipantList, refreshViewList, switchMode, issueList, issueListRequired, addContentTemplateNum, templateItem, fileCount, isReqExplain, reviewLibrary])

  useEffect(() => {
    if (_.isNil(editFormData)) return
    setParticipantList(oldList => {
      return _.map(_.get(editFormData, 'sOurPerformer'), user => {
        const findItem = _.find(oldList, x => x.conferee === user)
        return _.isNil(findItem) ? ({
          conferee: user,
          attendance: '01',
          explanation: null,
        }) : findItem
      })
    })
  }, [editFormData])

  useEffect(() => {
    refreshParticipantList()
  }, [refreshParticipantList])

  return (
    <>
      <div className='review-minutes-detail-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title">
            研发评审记录
          </div>
          <div className="mock-right-header flex center-y">
            {
              !isDetail &&
              <ContentTemplateSelect
                style={{ marginRight: 18 }}
                type={'2'}
                objectType={_.get(editFormData, 'reviewCategory')}
                onChange={(v, item) => {
                  setOpenItem(item)
                }}
              />
            }
            <Tooltip title={isDetail ? '进入编辑' : '退出编辑'}>
              <span>
                <Icon name={'bianji2'} className={`header-edit-icon ${isDetail ? '' : 'edit-active-icon'}`} onClick={() => {
                  setTemplateItem(null)
                  switchMode(true)
                  setEditFormData(formData)
                  setParticipantList(originalParticipantList)
                }} />
              </span>
            </Tooltip>
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <ReviewMinutesDetailHeader {...{ editFormData, isDetail, setEditFormData }} />
        <div className="mock-detail-main-panel flex">
          <div className="mock-detail-left-panel">
            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              items={[
                {
                  label: '评审内容',
                  key: 'reviewContent',
                  children: <ReviewMinutesDetailInfo {...{ infoRichEditorRef, isDetail, editFormData }} />
                },
                {
                  label: '问题清单',
                  key: 'contentList',
                  children: <ReviewMinutesDetailContentList
                    refresh={() => refreshDetail(cid)}
                    {...{ isDetail, cid, allUserRes, issueList, setIssueList }}
                  />
                },
                {
                  label: '跟进事项',
                  key: 'todoList',
                  children: <ConferenceRecordsDetailTodo
                    {...{ cid, allUserRes, updateDynamic, funcCode: '2420', url: '/reviewMinutesMgt?initId=', isEdit: !isDetail }}
                  />
                },
                {
                  label: '关联需求',
                  key: 'req',
                  forceRender: true,
                  children: <ConferenceRecordsDetailRelation {...{ cid, isRelevance: true, isDetail, setReqList }} />
                },
                {
                  label: '关联研发项目',
                  key: 'devProject',
                  forceRender: true,
                  children: <ConferenceRecordsDetailProjectRelation {...{ cid, isRelevance: true, isDetail, setDevList }} />
                },
                {
                  label: <Badge
                    size="small"
                    offset={[8, 0]}
                    count={fileCount}
                    color={'#CCCCCC'}
                  >
                    评审材料
                  </Badge>,
                  key: 'doc',
                  forceRender: true,
                  children: <div className="file-wrap">
                    <div className='flex'>
                      <FormInput className='reviewLibrary' required={!isReqExplain} horizontal bind='reviewLibrary' label='评审资料配置库目录' labelWidth={150} componentWidth={300} value={reviewLibrary} onChange={setReviewLibrary} component={isDetail ? Display : Input} convert={v => {
                        return <div onClick={() => { window.open(v, "_blank") }} style={{ cursor: 'pointer', color: '#5477ff' }}>{v}</div>
                      }} />
                      <Tooltip title={'如跳转失败,请填写http://或https://'}>
                        <span style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                          <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                      </Tooltip>
                    </div>
                    <RequirementUploadArea
                      isUpload={!isReqExplain}
                      funcCode={'2420'} {...{ id, mode, setFileCount, allUserRes, }}
                    />
                  </div>
                },
                {
                  label: '动态',
                  key: 'dynamic',
                  children: <div className={'dynamic-wrap'} key={countDynamic}>
                    {
                      cid &&
                      <ChangeRecord
                        name={'研发评审记录'}
                        funcCode={'2420'}
                        referenceId={cid}
                        title={_.get(editFormData, 'sSubject')}
                      />
                    }
                  </div>
                },
              ]}
            />
          </div>
          <ReviewMinutesDetailRightPanel {...{ isDetail, allUserRes, editFormData, setEditFormData, infoError, setInfoError }} />
          {contentLoading && <Loader fill />}
        </div>
        <div className="mock-footer flex center-y">
          <div />
          {
            _.isEmpty(showChildList) ? <div /> :
              <div className="footer-switch flex center-y">
                <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                  onClick={() => {
                    if (switchDisabled) return
                    if (!_.includes([-1, 0], currentIndex)) {
                      switchCurrentItem('previous')
                    }
                  }}>
                  <Icon name='xiangqian' />&nbsp;上一个
                </div>
                <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                  onClick={() => {
                    if (switchDisabled) return
                    if (!(currentIndex === showChildList?.length - 1)) {
                      switchCurrentItem('next')
                    }
                  }}>
                  下一个&nbsp;<Icon name='xianghou' />
                </div>
                <div className="list-num">
                  {currentIndex + 1}/{showChildList?.length}
                </div>
              </div>
          }
          <div className="btn-group flex">
            {
              isDetail &&
              <Button normal onClick={close}>关闭</Button>
            }
            {
              !isDetail &&
              <Button normal onClick={() => {
                switchMode(true)
                setEditFormData(formData)
              }}>取消</Button>
            }
            {
              !isDetail &&
              <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
            }
          </div>
        </div>
      </div>
      {
        !_.isNil(openItem) &&
        <EditTipLog
          item={openItem}
          close={() => setOpenItem(null)}
          onChange={(v, item) => {
            setTemplateItem(item)
            if (infoRichEditorRef.current) {
              infoRichEditorRef.current.setContent(v)
            }
          }}
        />
      }
    </>
  )
}

import React, { useCallback, useRef } from 'react'
import _ from 'lodash'
import { Dialog } from 'rootnet-ui'
import DevProjectMember from '../../../devProjectAddDialog/devProjectMember'
import './index.scss'

export default function MemberEditLog(props) {
  const { close, defaultData, submit, loading } = props
  const memberRef = useRef()
  const handCilck = useCallback(() => {
    const flag = userContrast(defaultData, memberRef.current)
    submit(memberRef.current, flag)
  }, [submit, defaultData])

  const onChange = useCallback((o) => {
    memberRef.current = o
  }, [])
  return (
    <Dialog
      cancel={close}
      header='编辑项目成员'
      className={'dev-project-edit-member-dialog'}
      confirm={handCilck}
      loading={loading}
    >
      <DevProjectMember onChange={onChange} {...{ defaultData }} />
    </Dialog>
  )
}

function userContrast(oldData, newData) {
  const oldItem = _.find(oldData, o => o.globalconstId === 'PO')
  const newItem = _.find(newData, o => o.globalconstId === 'PO')
  return _.get(oldItem, 'projectMembers') ===  _.get(newItem, 'projectMembersArray')
}

import {DatePicker, Select, RadioGroup} from "rootnet-edit";
import convertOptions from "../../../../common/ConvertOptions";
import _ from "lodash";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import UserSelect from "../../../../common/personSelectPop/UserSelect";

export function getTaskFormConfig(props){
    const {planStatusOptions, priorityOptions, segmentOptions,  milestoneFlagOptions, allUserRes, completedNotesRequired, mode, principal} = props
    return [
        {
            label: '任务名称',
            bind: 'itemName',
            required: true,
        },
        {
            label: '阶段',
            bind: 'segment',
            required: true,
            component: Select,
            options: segmentOptions,
            convert: v => convertOptions(v, segmentOptions),
        },
        {
            label: '状态',
            bind: 'status',
            required: true,
            component: Select,
            options: planStatusOptions,
            convert: v => convertOptions(v, planStatusOptions),
        },
        {
            label: '负责人',
            bind: 'principal',
            required: true,
            allowClear: false,
            component: UserSelect,
            convert: v => convertOptions(v, allUserRes, 'userName','userAccount')
        },
        {
            label: '计划开始时间',
            bind: 'planBeginDate',
            required: true,
            component: DatePicker,
            bindOutConvert: v => v ? dateFormat('YYYY-MM-DD',v) : v,
            bindInConvert: v => v ? toDate.dateStringToDate(v) : v,
        },
        {
            label: '计划结束时间',
            bind: 'planEndDate',
            required: true,
            component: DatePicker,
            bindOutConvert: v => v ? dateFormat('YYYY-MM-DD',v) : v,
            bindInConvert: v => v ? toDate.dateStringToDate(v) : v,
        },
        {
            label: '计划工时',
            bind: 'planUseTime',
            required: true,
            type: 'number',
            digit: 1,
            min: 0,
            suffix: '人日',
            convert: v => {
                if(_.isNil(v)) return '-'
                return v + '人日'
            }
        },
        {
            label: '优先级',
            bind: 'priority',
            required: true,
            component: Select,
            options: priorityOptions,
            convert: v => convertOptions(v, priorityOptions),
        },
        {
            label: '实际开始时间',
            bind: 'actualBeginDate',
            component: DatePicker,
            clear: true,
            bindOutConvert: v => v ? dateFormat('YYYY-MM-DD',v) : v,
            bindInConvert: v => v ? toDate.dateStringToDate(v) : v,
        },
        {
            label: '实际结束时间',
            bind: 'actualEndDate',
            component: DatePicker,
            clear: true,
            bindOutConvert: v => v ? dateFormat('YYYY-MM-DD',v) : v,
            bindInConvert: v => v ? toDate.dateStringToDate(v) : v,
        },
        {
            label: '实际工时',
            bind: 'actualUseTime',
            type: 'number',
            disabled: true,
            digit: 1,
            min: 0,
            suffix: '人日',
            convert: v => {
                if(_.isNil(v)) return '-'
                return v + '人日'
            }
        },
        {
            label: '标准工时',
            bind: 'standUseTime',
            disabled: mode !== 'add',
            type: 'number',
            digit: 1,
            min: 0,
            suffix: '人日',
            required: mode === 'add',
            convert: v => {
                if(_.isNil(v)) return '-'
                return v + '人日'
            }
        },
        {
            label: '参与人',
            bind: 'userList',
            multiple: true,
            mustList: [principal],
            component: UserSelect,
            convert: v => _.join(_.map(v, x => convertOptions(x, allUserRes, 'userName','userAccount')),'， ')
        },
        {
            label: '里程碑',
            bind: 'milestoneFlag',
            component: RadioGroup,
            options: milestoneFlagOptions,
            convert: v => convertOptions(v, milestoneFlagOptions),
        },
        {
            label: '交付物',
            bind: 'completedNotes',
            required: completedNotesRequired,
            componentWidth: 537,
        },
        {
            label: '主责单位',
            bind: 'receiveDepartment',
            required: true,
        },
        {
            label: '配合单位',
            bind: 'sponsorDepartment',
        },
        {
            label: '配合责任人',
            bind: 'sponsorPerson',
        },
        {
            label: '实施说明',
            bind: 'memo',
            componentWidth: 537,
        },
        {
            label: '延误说明',
            bind: 'delayExplain',
            componentWidth: 537,
        },
    ]
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import {Display, Form, FormInput} from 'rootnet-edit'
import './AnalysisStandardDialog.scss'
import _ from 'lodash'
import {useGet, usePost} from "rootnet-biz/lib/hooks";

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={100} type={'number'} suffix={'%'}
                                       min={0} max={100} digit={0} {...props} />

function AnalysisStandardDialog(props) {
    const {close, projectId, refreshAnalysisInfo} = props
    const [list, setList] = useState()
    const [params, setParams] = useState()
    const [error, setError] = useState()
    const {data: initList, doFetch: getInitList} = useGet()
    const {doFetch: submitPost} = usePost()

    useEffect(()=>{
        if(_.isNil(projectId)) return
        getInitList('/userprojectEarnedValue/list?projectId='+projectId).then(res => {
            setList(res)
            let initParams = {}
            _.forEach(res, x => {
                initParams[_.get(x,'stageName')] = _.get(x,'weight') * 100
            })
            setParams(initParams)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[getInitList,projectId])

    const sumPercent = useMemo(()=>{
        const valueList = _.values(params)
        return _.sum(_.map(valueList, x => _.toNumber(x) || 0))
    },[params])

    const canSubmit = useMemo(()=>{
        if(sumPercent !== 100) return false
        return _.every(error, v => _.isNil(v))
    },[sumPercent, error])

    const onFormChange = useCallback((newObj, bind)=>{
        setParams(newObj)
        setList(oldList => _.map(oldList, item => {
            if(item.stageName === bind){
                return {
                    ...item,
                    weight: _.toNumber(_.get(newObj, bind, 0)) / 100
                }
            }else{
                return item
            }
        }))
    },[])

    const submit = useCallback(()=>{
        const submitParams = _.map(list, x => ({
            ...x,
            projectId
        }))
        submitPost('/userprojectEarnedValue/update',submitParams).then(()=>{
            Messager.show('修改成功', { icon: 'success' })
            refreshAnalysisInfo()
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitPost, projectId, close, refreshAnalysisInfo, list])

    return <Dialog header={'修改标准'} className={'analysis-standard-dialog'} confirmButtonDisabled={!canSubmit} cancel={close} confirm={submit}>
        {
            initList &&
            <Form value={params} onChange={onFormChange} error={error} onError={setError}>
                {
                    _.map(initList, x => {
                        return <HFormInput label={x.stageName} bind={x.stageName}/>
                    })
                }
            </Form>
        }
        <FormInput label={'合计'} component={Display} convert={()=>sumPercent + '%'} horizontal labelWidth={100} componentWidth={150}  />
    </Dialog>
}

export default AnalysisStandardDialog;
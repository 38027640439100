import React, {useCallback, useMemo} from 'react';
import { Tab, TabItem } from 'rootnet-ui'
import './MetadataMgt.scss'
import {useGet} from "../../utils/hook";
import _ from 'lodash'
import {pathSearchFor} from "../../utils/publicFun";
import MetadataPage from "./MetadataPage";
// import EvolutionaryRelease from "./evolutionaryRelease/EvolutionaryRelease";
// import {HtmlEditor} from 'rootnet-ext';

function MetadataMgt(props) {
    const {history} = props
    const {initTabIndex = 0, initApprovalMode = 'default'} = pathSearchFor(history.location.search)
    const userPositionList = JSON.parse(localStorage.getItem('userPositionList'))
    const canCheck = _.includes(userPositionList,'MetadataCheck')
    const {data: menuRes} = useGet('/common/uacFuncctrl?moduleId=BS')

    const tabDetail = useMemo(()=>{
        if(_.isEmpty(menuRes)) return []
        return _.filter(menuRes, x => x.funcType === 3)
    },[menuRes])

    const getCheckFlag = useCallback((funcName)=>{
        return _.toString(_.get(_.find(tabDetail, x => x.funcName === funcName),'checkFlag')) || '0'
    },[tabDetail])

    return <Tab className='fill metadata-mgt' active={_.toNumber(initTabIndex)} onChange={(index)=>history.replace("/metadataMgt?initTabIndex="+index)}>
        <TabItem header='数据字典'>
            <MetadataPage tabIndex={'0'} title='数据字典' checkFlag={getCheckFlag('数据字典')} canCheck={canCheck} initApprovalMode={initApprovalMode}/>
        </TabItem>
        <TabItem header='常量定义'>
            <MetadataPage tabIndex={'1'} title='常量定义' checkFlag={getCheckFlag('常量定义')} canCheck={canCheck} initApprovalMode={initApprovalMode}/>
        </TabItem>
        <TabItem header='根网数据类型'>
            <MetadataPage tabIndex={'2'} title='根网数据类型' checkFlag={getCheckFlag('根网数据类型')} canCheck={canCheck} initApprovalMode={initApprovalMode}/>
        </TabItem>
        <TabItem header='标准词根查询'>
            <MetadataPage tabIndex={'3'} title='标准词根查询' checkFlag={getCheckFlag('标准词根查询')} canCheck={canCheck} initApprovalMode={initApprovalMode}/>
        </TabItem>
        {/*<TabItem header='test'>*/}
        {/*    <EvolutionaryRelease/>*/}
        {/*</TabItem>*/}
        {/*<TabItem header='test'>*/}
        {/*    <HtmlEditor onChange={setRichText}/>*/}
        {/*</TabItem>*/}
    </Tab>
}

export default MetadataMgt;
import React, {useCallback, useMemo} from 'react';
import _ from "lodash";
import {Tooltip} from "antd";
import { Icon } from '../../../../../../../components'
import './PersonDepartGroupSelectUserItem.scss'

function PersonDepartGroupSelectUserItem(props) {
    const {userInfo, onSelect = ()=>{}, onClear = ()=>{}, isRemove, selectedUserAccountList = []} = props

    const itemSelected = useMemo(()=>{
        return _.includes(selectedUserAccountList, _.get(userInfo,'userAccount'))
    },[userInfo, selectedUserAccountList])

    const onUserItemClick = useCallback(()=>{
        if(isRemove) return
        onSelect(userInfo)
    },[isRemove, userInfo, onSelect])

    return <div className="person-depart-group-select-user-item flex center-y" onClick={onUserItemClick} style={{cursor: isRemove ? 'unset': 'pointer'}}>
        <div className="person-depart-group-select-content flex center-y">
            <div className="user-avatar flex center" style={{background: getNameImgColor(_.get(userInfo,'sex'))}}>
                {_.isEmpty(userInfo)?'':_.get(userInfo,'userName')[0]}
                {
                    itemSelected &&
                    <div className={'item-selected flex center'}>
                        √
                    </div>
                }
            </div>
            <div className="user-info flex-y">
                <div className="user-info-header flex center-y">
                    <div className="user-name">
                        {_.get(userInfo,'userName')} -
                    </div>
                    {
                        _.get(userInfo, 'indexValidFlag') === 1 &&
                        <div className="user-all-dept flex center-y">
                            <Tooltip zIndex={9999} title={_.get(userInfo,'departmentAllPath')}>
                                <div className={'user-dept'}>
                                    {_.get(userInfo,'departmentName')}
                                </div>
                            </Tooltip>
                        </div>
                    }
                    {
                        _.get(userInfo, 'indexValidFlag') === 0 &&
                        <div className={'resigned-user'}>
                            已离职
                        </div>
                    }
                </div>
                <div className="user-info-footer flex center-y">
                    <div className="user-id">
                        {_.get(userInfo,'userId')}
                    </div>
                    <div className="user-account">
                        {_.get(userInfo,'userAccount')}
                    </div>
                </div>
            </div>
        </div>
        {
            isRemove && <div className={'clear-icon-wrap'}>
                <Icon name={'baocuo'} className={'clear-icon'} onClick={()=>onClear(userInfo)}/>
            </div>
        }
    </div>
}

function getNameImgColor(sex){
    if(_.isEmpty(sex)) return '#D3D3D3'
    return sex === '女'?'#F696D5':'#4BB8F3'
}

export default PersonDepartGroupSelectUserItem;
import _ from 'lodash'
import { Icon } from '../../../../../../../components'
import PersonDepartGroupSelectUserItem from './PersonDepartGroupSelectUserItem'

export function buildUserGroupTree(props) {
  const { userRes, userGroupRes, onSelect, selectedUserAccountList, onUserGroupNodeClick, setSelectUserGroup, setSelectValue, userGroupUserSelect } = props

  return getUserGroupChildren({
    allUserList: userRes,
    userGroupList: userGroupRes,
    onSelect,
    selectedUserAccountList,
    onUserGroupNodeClick,
    setSelectUserGroup,
    setSelectValue,
    userGroupUserSelect,
  })
}

function getUserGroupChildren(props) {
  const { allUserList, userGroupList, onSelect, selectedUserAccountList, onUserGroupNodeClick, setSelectUserGroup, setSelectValue, userGroupUserSelect } = props
  return _.map(userGroupList, item => {
    const { optIdList = [] } = item
    const filterUser = _.filter(allUserList, o => _.includes(optIdList, o.userAccount))
    const children = _.map(filterUser, o => _.assign({}, o, { title: <PersonDepartGroupSelectUserItem userInfo={o} onSelect={onSelect} {...{ selectedUserAccountList }} />, value: o.userAccount, type: 'user' }))
    return _.assign({}, item, {
      title: <div className={'dept-name-wrap flex center-y'} onClick={() => onUserGroupNodeClick(item.id)}>
        <div>
          <span onClick={(e) => {
            e.stopPropagation()
            setSelectUserGroup(o => {
              const findItem = _.find(o, items => items.value === item.id)
              return _.concat([], o, _.isNil(findItem) ? [{ text: item.groupName, value: item.id }] : [])
            })
          }}>{item.groupName}</span>
          {
            userGroupUserSelect(optIdList) &&
            <div className={'dept-item-selected flex center'}>
              √
            </div>
          }
        </div>
        <Icon name={'tianjia'} className={'add-dept-icon'} onClick={e => {
          e.stopPropagation()
          setSelectValue(x => {
            const filterData = _.filter(children, o => !_.includes(_.map(x, k => k.userAccount), o.userAccount))
            return _.concat(x, filterData)
          })
        }} />
      </div>,
      key: item.id,
      value: item.id,
      type: 'userGroup',
      children,
    })
  })
}

import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Checkbox } from 'antd'
import { Button, Dialog, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Select } from 'rootnet-edit'
import { Icon } from '../../../../../../../components'
import TextAreaInput from '../../../../../../common/textAreaInput/TextAreaInput'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import { strParams } from '../../../../../../../utils/publicFun'
import { InventoryExport } from '../../versionRequirementMgt/exportLog'
import CheckListSplit from './checkListSplit'
import GetFileListLog from './getFileListLog'
import PostMailLog from './postMailLog'
import './index.scss'

const CUSTOMER_INFO_URL = '/releaseLaunch/customer/info' //获取客户相关信息
const BASIC_VERSION_URL = '/releaseLaunch/proReleaseId' // 升级路径基础版本
const OTHER_INFO_URL = '/releaseLaunch/upgrade/path' // 获取版本升级路径
const PUBLISH_URL = '/releaseLaunch/launch/save' // 发布并保存
const CUSTOMER_LIST_URL = '/releaseLaunch/cust/list' //发布客户系统

const HFormInput = props => <FormInput horizontal labelWidth={135}  {...props} /> //required

export default function ReleaseVersionLog(props) {
  const { releaseId, close, refreshList } = props
  const [params, setParams] = useState()
  const [checked, setChecked] = useState(true)
  const [exportType, setExportType] = useState()
  const [openEmail, setOpenEmail] = useState()
  const [mailingListExpand, setMailingListExpand] = useState(true) // 邮件列表
  const [downloadPathExpand, setDownloadPathExpand] = useState(true) // 下载路径
  const [listFilesExpand, setListFilesExpand] = useState(true) // 文件列表
  const [otherInfoExpand, setOtherInfoExpand] = useState(true) // 其他信息
  const [isDisabled, setIsDisabled] = useState(false)
  const [upgradePath, setUpgradePath] = useState()
  const { doFetch: basicVersionFetch } = useGet()
  const { doFetch } = usePost()
  const { data: customerData, doFetch: customerFetch } = useGet()

  const customerOpt = useMemo(() => {
    if (_.isEmpty(customerData)) return []
    return _.map(customerData, o => ({ text: o.customerName, value: o.customerId, tag: `${o.customerName}${o.customerId}` }))
  }, [customerData])

  useEffect(() => {
    if (isNil(releaseId)) return
    customerFetch(`${CUSTOMER_LIST_URL}?${strParams({ releaseId })}`)
  }, [releaseId, customerFetch])

  const { customerId, proReleaseId } = useMemo(() => params || {}, [params])

  useEffect(() => {
    if (isNil(customerId)) return
    basicVersionFetch(`${BASIC_VERSION_URL}?${strParams({ customerId })}`)
      .then(res => {
        setParams(x => _.assign({}, x, { proReleaseId: res }))
      })
  }, [basicVersionFetch, customerId])

  useEffect(() => {
    if (isNil(customerId) || isNil(releaseId)) return
    basicVersionFetch(`${CUSTOMER_INFO_URL}?${strParams({ releaseId, customerId })}`)
      .then(res => {
        setParams(x => _.assign({}, x, {
          mainList: isNil(res.emaillist) ? '' : filterErrorBind(res.emaillist),
          ccList: isNil(res.cclist) ? '' : filterErrorBind(res.cclist),
          mailPath: isNil(res.releasepath) ? '' : res.releasepath,
          fileList: '',
          mailMemo: isNil(res.otherInfo) ? '' : res.otherInfo,
          defaultMailMemo: isNil(res.otherInfo) ? '' : res.otherInfo,
        }))
      })
  }, [customerId, basicVersionFetch, releaseId])

  useEffect(() => {
    if (isNil(customerId)) return
    setUpgradePath(null)
  }, [customerId])

  const getVersionPath = useCallback(() => {
    if (isNil(releaseId) || isNil(proReleaseId)) return
    basicVersionFetch(`${OTHER_INFO_URL}?${strParams({ releaseId, proReleaseId })}`)
      .then(res => {
        setUpgradePath(res.upgradePath)
        setParams(x => _.assign({}, x, { mailMemo: `升级顺序:\n${res.upgradePath}\n${x.defaultMailMemo}`, upgradePath: res.upgradePath }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [basicVersionFetch, releaseId, proReleaseId])

  const confirm = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)
    doFetch(`${PUBLISH_URL}?${strParams({ releaseId, customerId: params?.customerId })}`, [{ ..._.omit(params, ['proReleaseId']), releaseId }])
      .then((res) => {
        if (checked) setOpenEmail(res)
        else {
          close()
          Messager.show('发布成功', { icon: 'success' })
        }
        setIsDisabled(false)
        refreshList()
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, releaseId, params, checked, close, refreshList, isDisabled])

  return (
    <Dialog
      className='release-version-log'
      header='发布版本'
      cancel={close}
      footerVisible={false}
    >
      <div className='edit-content'>
        <Form value={params} onChange={setParams} >
          <HFormInput required search label='发布客户系统' bind='customerId' component={Select} options={customerOpt} />
          <HFormInput label='升级路径基础版本' bind='proReleaseId' />
          <div className="area-wrap base-info-wrap">

            <div className="area-wrap desc-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: mailingListExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setMailingListExpand(v => !v)} />
                邮件列表
              </div>
              <div className="area-content-wrap area-content-wrap-select" style={{ display: mailingListExpand ? 'block' : 'none' }}>
                <HFormInput multiple label='收件人' labelWidth={80} bind='mainList' />
                <HFormInput multiple label='抄送人' labelWidth={80} bind='ccList' />
              </div>
            </div>

            <div className="area-wrap desc-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: downloadPathExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDownloadPathExpand(v => !v)} />
                下载路径
              </div>
              <div className="area-content-wrap" style={{ display: downloadPathExpand ? 'inline-flex' : 'none' }}>
                <HFormInput bind='mailPath' component={TextAreaInput} />
              </div>
            </div>

            <div className="area-wrap desc-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: listFilesExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setListFilesExpand(v => !v)} />
                文件列表
                <TextIconBtn text='获取文件列表' style={{ color: '#5477ff' }} onClick={() => {
                  if (isNil(customerId)) return Messager.show('请选择发布客户系统', { icon: 'error' })
                  setExportType('fileList')
                }} />
              </div>
              <div className="area-content-wrap" style={{ display: listFilesExpand ? 'inline-flex' : 'none' }}>
                <HFormInput bind='fileList' component={TextAreaInput} />
              </div>
            </div>

            <div className="area-wrap desc-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: otherInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setOtherInfoExpand(v => !v)} />
                其他信息
                <TextIconBtn text='获取升级路径' style={{ color: '#5477ff' }} onClick={() => {
                  if (isNil(proReleaseId)) return Messager.show('请填写升级路径基础版本', { icon: 'error' })
                  getVersionPath()
                }} />
              </div>
              <div className="area-content-wrap" style={{ display: otherInfoExpand ? 'inline-flex' : 'none' }}>
                <HFormInput bind='mailMemo' component={TextAreaInput} />
              </div>
            </div>

          </div>
        </Form>
      </div>
      <div className='footer flex center-y'>
        <div><Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>发布邮件</Checkbox></div>
        <div className='operation-btn'>
          <Button normal onClick={() => {
            if (isNil(customerId)) return Messager.show('请选择发布客户系统', { icon: 'error' })
            if (isNil(upgradePath)) return Messager.show('请先获取升级路径', { icon: 'error' })
            setExportType('releaseNote')
          }}>导出releaseNote</Button>
          <Button normal onClick={() => {
            if (isNil(customerId)) return Messager.show('请选择发布客户系统', { icon: 'error' })
            if (isNil(upgradePath)) return Messager.show('请先获取升级路径', { icon: 'error' })
            setExportType('checklist')
          }}>checkList拆分</Button>
        </div>
        <div className='btn-group flex'>
          <Button primary disable={isNil(customerId)} onClick={confirm}>发布并保存</Button>
          <Button normal onClick={close}>取消</Button>
        </div>
      </div>
      {exportType === 'releaseNote' && <InventoryExport upgradePath={_.split(upgradePath, '-->')} defaultClient={[customerId]} currentId={releaseId} mulcustId={[customerId]} close={() => setExportType(false)} />}
      {exportType === 'checklist' && <CheckListSplit {...{ custId: customerId, upgradePath }} close={() => setExportType(false)} />}
      {exportType === 'fileList' && <GetFileListLog {...{ customerId, releaseId, changeFile: setParams, close: () => setExportType(false) }} />}
      {!isNil(openEmail) && <PostMailLog {...{ initParams: openEmail, closeAll: () => { setOpenEmail(null); close(); } }} />}
    </Dialog>
  )
}

function filterErrorBind(data, key = ';') {
  return _.join(_.compact(_.split(data, ';')), ';')
}
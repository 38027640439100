import React, { useMemo, useState, useCallback } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { Box } from '../../../../common/commonComponent'
import ViewArea from '../../../../common/view/ViewArea'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import { Icon } from '../../../../../components'
import './index.scss'
import UserGroupDetailDialog from './controls/userGroupDetailDialog'
import AssigningRole from '../../../components/assigningRole'

const ENTER_DETAIL_FIELD = 'groupName'
const EDITABLE_FIELD = ['groupName']

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, handlePopId, setHandlePopId } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)

    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
        <Popover
          trigger={'click'}
          placement={'bottom'}
          destroyTooltipOnHide={true}
          open={_.get(o, 'id') === handlePopId}
          onOpenChange={visible => {
            if (visible) setHandlePopId(_.get(o, 'id'))
            else setHandlePopId(null)
          }}
          content={
            <div className={'user-group-handle-popover flex-y'}>
              <div className='user-group-handle-item flex center-y' onClick={() => {
                setCurrentInfo({ mode: 'assigningRole', id: o.id })
                setHandlePopId(null)
              }}>
                <div className="handle-text">分配角色</div>
              </div>
            </div>
          }
        >
          <span>
            <Icon name={'gengduo'} className={'more-handle-icon'} />
          </span>
        </Popover>
      </div>
    }

  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '88050302'

export default function UserGroupList(props) {
  const { currentId, setCurrentId, refreshuserGroupPersonnel, htmlWidth } = props
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState()
  const [currentInfo, setCurrentInfo] = useState(null)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [handlePopId, setHandlePopId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, handlePopId, setHandlePopId }, optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showList = useMemo(() => {
    let showList = dataGridList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, dataGridList])

  const onRowClick = useCallback((item) => {
    _.forEach(showList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    setCurrentId(item?.id)
    refreshuserGroupPersonnel(item?.id)
  }, [showList, setCurrentId, refreshuserGroupPersonnel])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='tianjia' text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
    </div>
  }, [])

  return (
    <div className='user-group-list' style={{ width: htmlWidth }}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Table {...{ list, extra, boxLoading, error, options, dataGridList: showList, onRowClick, pageSize, total, pageNum, setPageSize, setParams }} />
      {
        _.includes(['add', 'detail'], _.get(currentInfo, 'mode')) &&
        <UserGroupDetailDialog
          {...{ currentInfo }}
          refreshViewList={() => { refreshList(); refreshuserGroupPersonnel(currentId) }}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'assigningRole' &&
        <AssigningRole close={() => setCurrentInfo(null)} refresh={refreshList} type='group' id={currentId} funcCode={FUNC_CODE} />
      }
    </div>
  )
}

function Table(props) {
  const { list, extra, boxLoading, error, options, dataGridList, onRowClick, pageSize, total, pageNum, setPageSize, setParams } = props
  return <Box title={'用户组'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
    <DataGrid option={options} data={dataGridList} onRowClick={onRowClick} />
    <Pagination pageSize={pageSize} total={total} current={pageNum} selector
      onChange={(pageNum, pageSize) => {
        setPageSize(pageSize)
        setParams(x => _.assign({}, x, { pageNum, pageSize }))
      }} />
  </Box>
}


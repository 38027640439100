import _ from "lodash";

export function createExtraNode(data){
    const rootNode = _.find(data, x => x.level === 0)
    const projectList = _.filter(data, x => x.level === 1)
    const moduleList = _.filter(data, x => x.level === 2)
    const submoduleList = _.filter(data, x => x.level === 3)
    let handledData = addRootNodeRight(data,rootNode)
    handledData = addMiddleNode(handledData, projectList,'productAssociationSystemDos')
    handledData = addMiddleNode(handledData, moduleList,'productAssociationSystemSonDos')
    handledData = addLastNode(handledData, submoduleList)
    return handledData
}

function addRootNodeRight(data,rootNode){
    const rightNode = {
        id: `right-root`,
        key: `right-root`,
        x: rootNode.x + 1800,
        y: rootNode.y,
        symbolSize: 0,
    }
    data.push(rightNode)
    return data
}

function addMiddleNode(data, list, childName){
    _.forEach(list, x => {
        const leftNode = {
            group: x.parent,
            id: `left-${x.id}`,
            key: `left-${x.key}`,
            x: x.x - 1800,
            y: x.y,
            symbolSize: 0,
        }
        const rightNode = {
            id: `right-${x.id}`,
            key: `right-${x.key}`,
            x: x.x + 1800,
            y: x.y,
            symbolSize: 0,
        }
        data.push(leftNode)
        const childList = _.get(x,childName)
        if(childList.length !== 0)  data.push(rightNode)
    })
    return data
}

function addLastNode(data, list){
    _.forEach(list, x => {
        const leftNode = {
            group: x.parent,
            id: `left-${x.id}`,
            key: `left-${x.key}`,
            x: x.x - 1800,
            y: x.y,
            symbolSize: 0,
        }
        data.push(leftNode)
    })
    return data
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import clsx from "clsx";
import './MenuMgtTwoColumns.scss'
import _ from "lodash";
import {isNil} from "../../../appraise/components/method";
import {Tabs, Tree} from 'antd';
import MenuInfo from "./menuInfo/MenuInfo";
import {Loader} from "rootnet-ui";
import {useGet} from "rootnet-biz/es/hooks";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";

function handleTreeOptions(allList, currentList, valueField, parentField, textField){
    return _.map(currentList, item => {
        const childrenList = _.filter(allList, x => x[parentField] === item[valueField])
        const currentItem = {id: item.id, value: item[valueField], key: item[valueField], label: item[textField], title: item[textField], text: `${item.funcName}（${item.funcCode}）`}
        if(!_.isEmpty(childrenList)) _.assign(currentItem, {children: handleTreeOptions(allList, childrenList, valueField, parentField, textField )})
        return currentItem
    })
}

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=system',
]

function MenuMgtTwoColumns(props) {
    const {isTree, refreshViewList, countInnerTree} = props
    const {data: allList, doFetch: getAllList, loading} = useGet()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [selectedModule, setSelectedModule] = useState('BS')
    const [selectedId, setSelectedId] = useState()

    const [systemOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const systemItem = useMemo(()=>{
        return _.map(systemOptions, x => ({
            label: x.text, key: x.value
        }))
    },[systemOptions,])

    const refreshTreeList = useCallback(()=>{
        console.log('countInnerTree',countInnerTree)
        getAllList('/uac/menu/list?moduleId='+selectedModule)
    },[getAllList,selectedModule, countInnerTree])

    useEffect(()=>{
        refreshTreeList()
    },[refreshTreeList])

    const currentSystemList = useMemo(()=>{
        const menuOptions = _.filter(allList, x => x.moduleId === selectedModule)
        return _.map(menuOptions, x => ({
            text: `${x.funcName}（${x.funcCode}）`,
            value: x.funcCode
        }))
    },[allList, selectedModule])

    const treeOptions = useMemo(()=>{
        if(_.isEmpty(allList)) return []
        let firstLevel = _.filter(allList, x => isNil(_.get(x,'pid')))
        return handleTreeOptions(allList, firstLevel, 'funcCode', 'pid', 'funcName')
    },[allList])

    const onTreeSelect = useCallback((selectedKeys, node)=>{
        setSelectedId(node.node.id)
    },[])

    return <div className={clsx('menu-mgt-two-columns flex', {'is-tree': isTree})}>
        <div className="menu-tree-wrap flex-y">
            {
                loading && <Loader fill/>
            }
            <Tabs items={systemItem} activeKey={selectedModule} onChange={setSelectedModule}/>
            <Tree
                defaultExpandAll
                treeData={treeOptions}
                onSelect={onTreeSelect}
            />
        </div>
        <MenuInfo id={selectedId} {...{refreshViewList,currentSystemList,treeOptions, refreshTreeList}}/>
    </div>
}

export default MenuMgtTwoColumns;
import React, {useCallback, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {handleTree} from "../../TreeFunc";
import {isNil} from "rootnet-core/format";
import {Icon} from "../../../../project_share/components";
import {Switch} from 'rootnet-ui'
import useGetDateOptions from "./useGetDateOptions";

export default function useGetTreeList(props){
    const {fieldList,list,convertCollection,getOptions,getColumns,columnsAppendParams = {}, optionsAppendParams ={},sortable=true} = props
    const [isTree, setIsTree] = useState(false)
    const [showList, setShowList] = useState([])

    const numList = useMemo(()=>{
        const numColList = _.map(_.filter(fieldList, x => _.includes(['02','03'], x.fieldHtmlDetailType)),'columnId')
        return _.map(list, item => {
            _.forEach(numColList, key => {
                item[key] = isNil(_.get(item,key))? null: _.toNumber(_.get(item,key))
            })
            return item
        })
    },[fieldList, list])

    const dateOptions = useGetDateOptions()

    const handleList = useMemo(()=>{
        if(_.isEmpty(numList)) return []
        if(_.isNil(fieldList) || fieldList.length < 2) return numList
        const treeList = _.map(_.groupBy(numList, x => x[_.head(fieldList).columnId]), (firstItem, firstName) => ({
            keyPath: handleNull(firstName),
            _firstName: firstName,
            _oneChild: firstItem.length === 1,
            _child: _.map(firstItem, item => ({
                keyPath:  handleNull(firstName) + item.id,
                ...item,
            }))
        }))

        const expandList = handleTree(treeList, '_child', 'keyPath')

        return marginList(expandList, 0)

        function handleNull (v){
            return isNil(v)?'NULL':v
        }

        function marginList(numList, level){
            let skipFlag = -1;
            const handleList = []
            _.forEach(numList, (v,index) => {
                if(skipFlag === index) return
                if(v._level === level && v._oneChild){
                    handleList.push(_.assign({},numList[index+1],v,{_merge: true}))
                    skipFlag = index+1;
                    return
                }
                handleList.push(v)
            })
            return handleList
        }
    },[fieldList,numList])

    useEffect(()=>{
        setShowList(handleList)
    },[handleList])

    const handleTreeClick = useCallback((handleList,o,i,parentName, keyName)=>{
        const updateItem = _.assign({},showList[i],{_expand: !showList[i]._expand})
        if(o._expand){
            const endIndex = _.reduce(showList.slice(i+1),(acc,x,index)=>{
                if(acc!==-1) return acc;
                const currentIndex = (index + i + 1);
                return (showList[currentIndex]._level <= o._level) ? currentIndex : acc;
            },-1);
            setShowList(showList.slice(0,i).concat(updateItem,endIndex !== -1? showList.slice(endIndex): []))
        }else{
            const filterList = _.filter(handleList, item => item[parentName] === o[keyName])
            const addList = _.map(filterList, item => _.assign(item, {_level: o._level+1,_expand: false}))
            setShowList(showList.slice(0,i).concat(updateItem,addList,showList.slice(i+1)))
        }
    },[showList])

    const addLeftIcon = useCallback((v, o, i) => {
        return <div className='arrow-drop-down-group flex center-y' style={{cursor: 'pointer',marginLeft: 8}} onClick={() => {handleTreeClick(handleList,o,i,'_parent','keyPath')}}>
            <Icon name="arrow_drop_down" className='arrow_drop_down'
                  style={{transform: _.get(o,'_expand') ? 'none' : 'rotate(-90deg)', marginRight: 5, display: 'inline-block'}}/>
            {v}
        </div>
    },[handleTreeClick,handleList])

    const dataGridList = useMemo(()=>{
        return isTree ? showList : numList
    },[isTree, showList, numList])

    const options = useMemo(()=>{
        const opts = getColumns({fieldList,convertCollection,dateOptions,...columnsAppendParams})
        const newOpts = _.map(opts, x => _.assign({},x,{sortable: sortable}))
        if(!isTree){
            return ({...getOptions(newOpts), ...optionsAppendParams})
        }

        const firstOptItem = _.find(opts, x => x.header !== '#' && _.isString(x.header))
        if(_.get(firstOptItem, '_custom') !== 'N'){
            return ({...getOptions(newOpts), ...optionsAppendParams, sort: null})
        }else{
            const firstBind = firstOptItem.bind
            const handleOpts = _.map(opts, (x) => {
                if(_.get(x,'bind') !== firstBind) return x
                return _.assign({},x,
                    {
                        convert: (v, o, i) => {
                            const showValue = x.convert ? x.convert(v) : v
                            if(o._merge) return <span style={{marginLeft: 25}}>{showValue}</span>
                            return o._level === 0 ? addLeftIcon(showValue, o, i) : ' '
                        },
                        bind: '_firstName',
                        width: x.width + 20
                    })
            })
            return ({...getOptions(handleOpts), ...optionsAppendParams, sort: null})
        }
    },[fieldList,convertCollection,dateOptions,isTree,addLeftIcon,getOptions,getColumns,columnsAppendParams,optionsAppendParams, sortable])

    const TreeSwitch = useCallback(()=>{
        return <Switch checkedComponent='树状' uncheckedComponent='平铺' checked={isTree} onChange={setIsTree}/>
    },[isTree])

    return ({
        options,
        dataGridList,
        TreeSwitch,
        isTree,
        colList: numList
    })

}
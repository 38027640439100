import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box} from "../../common/commonComponent";
import {DataGrid,Button,Pagination} from 'rootnet-ui'
import OperationList from "../../../project_share/components/OperationList";
import {Form, FormInput, Select} from "rootnet-edit";
import './ProjectView.scss'
import {TextIconBtn} from "../../common/TextIconBtn";
import {Route, Switch, useHistory} from "react-router-dom";
import ViewDetail from "./controls/ViewDetail";
import {useGet} from "../../../utils/hook";
import {strParams} from "../../../project_share/utils/utils";
import _ from "lodash";
import {toDate, dateFormat} from 'rootnet-core/dateFormat'
import {historyParamsFor} from "../../../utils/publicFun";
import DelMessage from "../../../components/DelMessage";
import CreateView from "../createView/CreateView";
import {Cache} from "../../../base/cache";

const { str14ToDate } = toDate;
const CACHE_KEY = 'createView'

export default function Router() {
    return <Switch>
        <Route path='/projectView/detail' component={ViewDetail}/>
        <Route path='/projectView/edit' component={CreateView}/>
        <Route component={ProjectView}/>
    </Switch>
}

const getColumns = (history,props,params) => {
    const {projectViewTypeOptions,viewStatusOptions,setDelViewId,userAccount} = props
    return [
        {header: '视图名称', bind: 'viewName',sortable: true,width: 130, tooltip: true},
        {header: '状态', bind: 'viewStatus',sortable: true,width: 130, convert: v => convertOptions(v,viewStatusOptions)},
        {header: '任务总数', bind: 'listNum',sortable: true,align: 'right',width: 100},
        {header: '超期个数', bind: 'overdueNum',sortable: true,align: 'right',width: 100, convert: convertOverdueNum},
        {header: '完成个数', bind: 'completeNum',sortable: true,align: 'right',width: 100},
        {header: '计划人日', bind: 'planTime',sortable: true,align: 'right',width: 110},
        {header: '完成工作比例', bind: 'completeRate',sortable: true,align: 'right',width: 100,convert: v => parseInt(v*100+0.1) + '%'},
        {header: '开始日期', bind: 'beginDate',sortable: true,width: 100, convert: convertDate },
        {header: '结束日期', bind: 'endDate',sortable: true,width: 100, convert: convertDate},
        {header: '任务清单开始日期', bind: 'listBeginDate',sortable: true,width: 120, convert: convertDate},
        {header: '任务清单结束日期', bind: 'listEndDate',sortable: true,width: 120, convert: convertDate},
        {header: '类型', bind: 'viewType',sortable: true,width: 80, convert: v => convertOptions(v,projectViewTypeOptions)},
        {header: '创建人员', bind: 'createUserName',sortable: true,width: 80},
        {header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation}
    ]
    function isDisable(o){
        return !(userAccount === o['createUser'] || _.includes(o['adminUserArrays'],userAccount))
    }

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick:()=>history.push({pathname: '/projectView/detail',search: strParams({...params,viewId: o.id})}),
            },
            {
                text: '编辑',
                mode: 'edit',
                disabled: isDisable(o),
                onClick:()=>history.push({pathname: '/projectView/edit',search: strParams({...params,viewId: o.id, scene:'edit' , type: o.viewType, clear: 'done'})}),
            },
            {
                text: '删除',
                mode: 'delete',
                disabled: isDisable(o),
                onClick: ()=>setDelViewId(o.id)
            }
        ];
        return <OperationList options={operateOption}/>;
    }
    
    function convertOptions(v, options){
        return _.get(_.find(options, x => x.value === v),'text')
    }
    
    function convertDate(v){
        return dateFormat('YYYY-MM-DD', str14ToDate(v))
    }

    function convertOverdueNum(v){
        if (v === 0) return v
        return <span style={{ color: 'red', fontWeight: 600 }}>{v}</span>
    }
}

const getTableOption = (columns,setSortConfig) => ({
    columns,
    autoFill: true,
    resizable: true,
    virtualized: false,
    fixedLeft: 1,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
    onSort: (data, sort)=>{
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
    }
});

const OPTION_URLS = [
    '/common/userinfo',
    '/common/globalconst?globalConst=viewType',
    '/common/globalconst?globalConst=ProjectStatus'
]


const INIT_PARAMS = {
    pageNum: 1,
    pageSize: 20,
    viewStatus: ['0','3'],
    // userName: 'ALL'
}

function ProjectView(props) {
    const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
    const historyParams = useMemo(()=>historyParamsFor(_.get(props.history,'location.pathParams')),[props.history]);
    const handleHistoryParams = useMemo(()=>{
        const omitParams = _.omit(historyParams,['viewId'])
        let viewStatus = _.get(historyParams,'viewStatus')
        let viewType = _.get(historyParams,'viewType')
        viewStatus = viewStatus?_.get(historyParams,'viewStatus').split(','):null
        viewType = viewType?_.get(historyParams,'viewType').split(','):null
        return _.assign({},omitParams,{viewStatus,viewType})
    },[historyParams])

    const history = useHistory();
    const [ params, setParams ] = useState(historyParams?handleHistoryParams:INIT_PARAMS);
    const {data: optionsRes} = useGet(OPTION_URLS)
    const {data:listRes, doFetch:getList,loading,error} = useGet()
    const {data: menuRes} = useGet('/common/uacFuncctrl?moduleId=BS')
    const [delViewId, setDelViewId] = useState()
    const [currentId, setCurrentId] = useState(_.get(historyParams,'viewId'))
    const [sortConfig, setSortConfig] = useState()

    const onRowClick = useCallback((o) => {
        setCurrentId(o.id)
    },[])

    useEffect(()=>{
        Cache.set(CACHE_KEY, null)
        Cache.set('CREATE_VIEW_PARAMS', null)
    },[])

    const funcCode = useMemo(()=>{
        const pathname = history.location.pathname
        return _.get(_.find(menuRes, x => x.url === pathname),'funcCode')
    },[history,menuRes])

    const [createUserOptions, projectViewTypeOptions, viewStatusOptions] = useMemo(()=>{
        const [d1,d2,d3] = optionsRes || []
        const createUserOptions = _.map(d1,x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))
        const projectViewTypeOptions = _.map(d2,x => ({value: x.interiorId, text: x.displayName}))
        const viewStatusOptions = _.map(d3,x => ({value: x.interiorId, text: x.displayName}))
        return [createUserOptions, projectViewTypeOptions, viewStatusOptions]
    },[optionsRes])

    const refresh = useCallback(()=>{
        let getParams = params
        if(_.isNil(_.get(getParams, 'userName')) || _.get(getParams, 'userName') !== 'ALL'){
            getParams['userName'] = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
        }
        getList('/view/getList?'+strParams(getParams))
    },[params,getList])

    useEffect(()=>{
        refresh()
    },[refresh])

    const { pageSize, total, pageNum, viewListResp:list } = useMemo(()=>(listRes || {}),[listRes]);

    const option = useMemo(()=>{
        return getTableOption(getColumns(history,{projectViewTypeOptions,viewStatusOptions,setDelViewId,userAccount},params),setSortConfig)
    },[history,projectViewTypeOptions,viewStatusOptions,params,userAccount])

    const extra = <>
        <TextIconBtn icon='tianjia' text='创建视图' onClick={()=>history.push('/createView')}/>
    </>

    useEffect(()=>{
        let showList = list || []
        if(!_.isNil(sortConfig)){
            const sortList = _.sortBy(list, x => x[sortConfig.column])
            showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
        }
        const handleIndex = _.findIndex(showList, x => x.id === currentId)
        if(handleIndex !== -1){
            const currentPage = document.getElementsByClassName('project-view')[0]
            const rows = currentPage.getElementsByClassName('row')
            _.forEach(rows, (x,i) => {
                x.style.backgroundColor = i === handleIndex? '#edf3f9': '#fff'
            })

        }
    },[sortConfig,currentId,list])

    return <div className='project-view flex-y fill'>
        <Options initParams={INIT_PARAMS} search={setParams} handleHistoryParams={handleHistoryParams} historyParams={historyParams}
                 createUserOptions={createUserOptions} projectViewTypeOptions={projectViewTypeOptions}
                 viewStatusOptions={viewStatusOptions}/>
        <Box title='视图列表' className='flex-y x-card-singlegrid' data={list} extra={extra} loading={loading} error={error}>
            <DataGrid option={option} data={list} onRowClick={onRowClick}/>
            <Pagination pageSize={ pageSize } total={ total } current={ pageNum }
                        onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>
        </Box>
        {
            delViewId &&
            <DelMessage url={`/viewDetail/delete?viewId=${delViewId}&funcCode=${funcCode}`} close={()=>setDelViewId(null)} refresh={refresh}>
                是否确认删除该视图？
            </DelMessage>
        }
    </div>
}

function Options(props){
    const { initParams, search,createUserOptions, projectViewTypeOptions, viewStatusOptions, handleHistoryParams, historyParams } = props;
    const [ params, setParams ] = useState(historyParams?handleHistoryParams:initParams);

    const userNameOptions = useMemo(()=>{
        return [
            {value: 'ALL',text: '全部视图'},
            {value: _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id'),text: '我关注的'},
        ]
    },[])

    return <div className='c-options project-view-options'>
        <Form value={params} onChange={setParams}>
            <FormInput bind="viewName" label="视图名称" placeholder='请输入关键词搜索' componentWidth={150}/>
            <FormInput bind="viewType" label="类型" component={Select} clear multiple options={projectViewTypeOptions} componentWidth={150}/>
            <FormInput bind="viewStatus" label="状态" component={Select} clear multiple options={viewStatusOptions} componentWidth={150}/>
            <FormInput bind="createUser" label="创建人员" component={Select} clear search options={createUserOptions} componentWidth={150}/>
            <FormInput bind="userName" label="视图筛选" component={Select} search options={userNameOptions} componentWidth={150}/>
        </Form>
        <Button primary onClick={() => search({...params,pageNum:1})} className='btn'>查询</Button>
        <Button text onClick={() => {
            setParams({...INIT_PARAMS})
            search({...INIT_PARAMS})
        }} className='btn reset-btn'>重置</Button>
    </div>
}



import React, {useEffect, useMemo, useState} from 'react';
import {Dialog, Tab, TabItem, DataGrid, Messager} from 'rootnet-ui'
import {FormInput, Select} from "rootnet-edit";
import {Box} from "../../../common/commonComponent";
import {useApi, useGet} from "../../../../utils/hook";
import _ from 'lodash'
import {strParams} from "../../../../utils/publicFun";

const getColumns = (props) => {
    const {tracerStateOptions} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: 'ID', bind: 'id',width: 150, tooltip: true},
        {header: '标题', bind: 'detailTitle',width: 160, tooltip: true},
        {header: '负责人', bind: 'detailPrincipal',width: 80},
        {header: '研发任务ID', bind: 'tracerId',width: 160, tooltip: true},
        {header: '研发任务状态', bind: 'tracerState',width: 120, tooltip: true, convert: v => convertOptions(v, tracerStateOptions)},
    ]
    function convertOptions(v, options){
        return _.get(_.find(options, x => x.value === v),'text')
    }
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
});

const tabShow = ['2','1','3']

const OPTIONS_URLS = [
    '/viewImport/getVersion',
    '/common/globalconst?globalConst=TRACERSTATE',
    '/view/getViewName?userName=ALL',
]

function ImportDialog(props) {
    const userName = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
    const {close, viewId, refresh} = props
    const [importTabIndex, setImportTabIndex] = useState(0)
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const {data: list, doFetch: getList, loading, error} = useGet()
    const [releaseId, setReleaseId] = useState()
    const {doFetch} = useApi()

    const [versionListOptions, tracerStateOptions, viewOptions] = useMemo(()=>{
        const [d1, d2, d3] = optionsRes || []
        const versionListOptions = _.map(d1, x => ({value: x, text: x}))
        const tracerStateOptions = _.map(d2, x => ({value: x.interiorId, text: `${x.interiorId}-${x.displayName}`}))
        const viewOptions = _.map(d3, x => ({value: x.viewId, text: x.viewName}))
        return [versionListOptions,tracerStateOptions, viewOptions]
    },[optionsRes])

    useEffect(()=>{
        if(_.isNil(optionsRes)) return
        const currentViewName = _.get(_.find(viewOptions, x => _.toLower(x.value) === _.toLower(viewId)),'text')
        const currentVersionName = _.find(versionListOptions, x => _.toLower(x.text) === _.toLower(currentViewName))
        const defaultReleaseId = _.isNil(currentVersionName)?_.get(_.head(versionListOptions),'value'):_.get(currentVersionName,'value')
        setReleaseId(defaultReleaseId)
    },[optionsRes,versionListOptions,viewId, viewOptions])

    useEffect(()=>{
        if(_.isNil(releaseId)) return
        const getParams = {viewId, releaseID:releaseId}
        getList(`/viewImport/getImportVerData?`+strParams(getParams))
    },[viewId,releaseId,getList])

    const importVersionOption = useMemo(()=>{
        return getOption(getColumns({tracerStateOptions}))
    },[tracerStateOptions])

    const filterList = useMemo(()=>{
        return _.filter(list, x => x.status === tabShow[importTabIndex])
    },[list, importTabIndex])

    const isConfirmButtonDisabled = useMemo(()=>{
        return (importTabIndex === 2) || _.isNil(releaseId)
    },[importTabIndex,releaseId])

    return <Dialog header='导入版本' cancel={close} confirmButtonText='导入' confirm={confirm}
                   className='version-import-dialog' contentClassName='flex-y' confirmButtonDisabled={isConfirmButtonDisabled}>
        <div className="select-version">
            <FormInput component={Select} label='版本编号' options={versionListOptions} search value={releaseId} componentWidth={250} onChange={setReleaseId}/>
        </div>
        <div className="main-panel flex-y">
            <div className="tab-group">
                <Tab onChange={setImportTabIndex}>
                    <TabItem header='未导入'/>
                    <TabItem header='未创建'/>
                    <TabItem header='已导入'/>
                </Tab>
            </div>
            <Box data={filterList} className='flex-y x-card-singlegrid import-version-box' loading={loading} error={error}>
                <DataGrid option={importVersionOption} data={filterList}/>
            </Box>
        </div>
    </Dialog>

    function confirm(){
        const postParams = {viewId, releaseID:releaseId, userName}
        doFetch('/viewImport/importVer','post',postParams).then(res=>{
            if(res.message){
                const message = res.message.replace(/\n/,'<br>')
                return Messager.show(<div dangerouslySetInnerHTML={{__html:message}}/>, { icon: 'error' })
            }
            const failCount = res.faild.length
            const successCount = res.success.length
            const sum = failCount + successCount
            const reason = _.reduce(res.faild,(acc, x) => {
                acc += `\n失败记录：${x.tracerId}   失败原因（${x.msg}）`
                return acc
            },'')
            Messager.show(`本次操作共${sum}条数据，成功${successCount}条，失败${failCount}条` + reason, { icon: 'info' })
            refresh()
            close()
        }).catch(err => {
            const message = err._message.replace(/\n/,'<br>')
            Messager.show(<div dangerouslySetInnerHTML={{__html:message}}/>, { icon: 'error' })
        })
    }
}

export default ImportDialog;
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput, TextArea, Display } from "rootnet-edit";
import { isNil } from '../../../appraise/components/method';
import { Icon } from '../../../../components';
import { usePost } from 'rootnet-biz/lib/hooks';

const HFormInput = (props) => <FormInput {...props} labelWidth={110} componentWidth={450} horizontal />

export default function JoinPlanVersion(props) {
  const { id, close, afterRefresh, type, submit: submits, showJoinPlanVersion } = props
  const [formData, setFormData] = useState({ releaseIdList: _.map(showJoinPlanVersion, 'value') })
  const [formError, setFormError] = useState()
  const { doFetch: submitPost } = usePost()
  const canSubmit = useMemo(() => {
    if (isNil(_.get(formData, 'optMemo'))) return false
    return _.every(formError, _.isNil)
  }, [formError, formData])

  const submit = useCallback(() => {
    const params = {
      storyId: id,
      type: type,
      auditType: '1',
      releaseStatus: 'C',
      ...formData
    }
    submitPost('/ReleaseStoryLog/addReleaseStoryLogList', params)
      .then(() => {
        Messager.show('提交申请成功', { icon: 'success' })
        if (afterRefresh) {
          afterRefresh(true)
        }
        submits(false, _.map(showJoinPlanVersion, o => ({ releaseId: o.value })))
        close()
      }).catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [submitPost, close, id, formData, type, afterRefresh, submits, showJoinPlanVersion])

  return (
    <Dialog header={'申请加入预规划项目'} className={'apply-join-version-dialog'} confirmButtonDisabled={!canSubmit} cancel={close} confirm={submit}>
      <Form value={formData} onChange={setFormData} error={formError} onError={setFormError}>
        <HFormInput label={'预规划项目'} bind={'releaseIdList'} component={Display} convert={v => _.join(v, '，')} />
        <HFormInput label={'申请说明'} bind={'optMemo'} required component={TextArea} />
      </Form>
      <div className={'tips-wrap flex center-y'}>
        <Icon name={'tixing'} className={'tips-icon'} />
        <div className={'tips-text'}>选择已封板的预规划项目，只有负责人通过后才会加入对应的预规划项目中</div>
      </div>
    </Dialog>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Popover, Tabs } from 'antd'
import { Loader } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Icon } from '../../../components'
import { TextIconBtn } from '../TextIconBtn'
import { strParams } from '../../../utils/publicFun'
import { useFuncCode } from '../commonMethod'
import './index.scss'

const QUERY_LIST_URL = '/demoTxt/list'

const FUNC_CODE_ARR = ['8810', '881002']

/**
 * 
 * 会议纪要     0
 * 周报   日报      1
 * 
 */
const defaultTemplateId = ['55', '56']
export default function ContentTemplateSelect(props) {
  const { type, objectType, onChange, isAdd, style, isDefault } = props
  const [open, setOpen] = useState(false)
  const { data, doFetch, loading } = useGet()
  const [isManageCode, isPersonCode] = useFuncCode(FUNC_CODE_ARR)
  const { demotxts, templateConfigs } = useMemo(() => data || {}, [data]) //templateConfigs
  const [isDisabled, setIsDisabled] = useState(true)

  const refresh = useCallback(() => {
    if (_.isNil(type) || _.isNil(objectType)) return
    setIsDisabled(true)
    doFetch(`${QUERY_LIST_URL}?${strParams({ type, objectType })}`)
      .then(() => setIsDisabled(false))
      .catch((err) => setIsDisabled(false))
  }, [doFetch, type, objectType])

  useEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    if (!isAdd || _.isNil(type) || _.isNil(objectType)) return
    // || _.includes(defaultTemplateId, data?.templateConfigs?.id)
    if (_.isEmpty(data)) { onChange(''); return }
    const findeItem = _.find(data.demotxts, o => (o.type === type && o.objectType === objectType && o.isDefault === '0'))
    const findelLastItem = data.templateConfigs
    if (!_.isNil(findelLastItem)) { onChange(_.get(findelLastItem, 'demoValue'), _.assign({}, findelLastItem)); return }
    if (!_.isNil(findeItem)) { onChange(_.get(findeItem, 'demoValue'), _.assign({}, findeItem)); return }
    onChange('')
  }, [isAdd, data, type, objectType, onChange])

  useEffect(() => {
    if (!isDefault || isDisabled) return
    if (_.isNull(templateConfigs) || _.includes(defaultTemplateId, templateConfigs?.id)) { onChange(''); return }
    const findelLastItem = templateConfigs
    if (!_.isNil(findelLastItem)) { onChange(_.get(findelLastItem, 'demoValue'), _.assign({}, findelLastItem)); return }
    onChange('')
  }, [isDefault, templateConfigs, onChange, isDisabled])

  const commonData = useMemo(() => {
    if (_.isEmpty(demotxts)) return []
    return _.filter(demotxts, o => o.category !== '1')
  }, [demotxts])

  const personData = useMemo(() => {
    if (_.isEmpty(demotxts)) return []
    return _.filter(demotxts, o => o.category === '1')
  }, [demotxts])

  return (
    <Popover
      trigger={'click'}
      open={open}
      onOpenChange={setOpen}
      placement={'rightBottom'}
      overlayClassName='content-template-select-popover'
      content={
        <div className='content-template-select-popover-content'>
          {loading && !_.isNil(type) && !_.isNil(objectType) && <Loader fill />}
          <Tabs centered items={[
            {
              label: '公共模板',
              key: 'common',
              children: <ItemList data={commonData} handClick={onChange} />
            },
            isPersonCode &&
            {
              label: '个人模板',
              key: 'person',
              children: <ItemList data={personData} handClick={onChange} />
            },
          ]} />
          {
            isManageCode &&
            <div className='template-mgt-btn'>
              <TextIconBtn
                icon='xitong1'
                text='模板管理'
                onClick={() => window.open('#/contentTemplateMgt')}
              />
            </div>
          }
        </div>
      }
    >
      <TextIconBtn
        icon='mofabi'
        className='mo-fa-bang-icon'
        style={_.assign({ marginLeft: 8 }, style, open && { color: '#5477ff' })}
      />
    </Popover>
  )
}

ContentTemplateSelect.defaultProps = {
  onChange: () => { },
  isAdd: false,
  style: {},
  isDefault: false,
}

function ItemList(props) {
  const { data, handClick } = props
  return <div className='content-template-select-list'>
    {
      _.map(data, o => {
        return <div className='flex template-item' key={o.id} onClick={() => {
          handClick(o.demoValue, o, true)
        }}>
          <div className='template-item-icon'><Icon name='wendang' /></div>
          <div className='template-item-text'>{o.demoName}</div>
        </div>
      })
    }
  </div>
}

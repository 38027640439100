import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './CustomerServiceInfoHeader.scss'
import _ from "lodash";
import {Popover, Tooltip} from "antd";
import {Icon} from "../../../../components";
import {Messager, Button} from 'rootnet-ui'
import {copyText} from "rootnet-core/clipboard";
import {FormInput} from "rootnet-edit";
import convertOptions from "../../../common/ConvertOptions";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import {strParams} from "../../../../utils/publicFun";
import ConcernedIcon from '../../../common/concernedIcon';

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function getTagColor(value){
    switch(value){
        case 'PROD': return '#F33F2B'
        case 'UAT': return '#008CEC'
        case 'TESTONLY': return '#00A950'
        default: return '#FFFFFF'
    }
}

const FUNC_CODE = '20'

function CustomerServiceInfoHeader(props) {
    const {editFormData, isDetail, setEditFormData, allUserRes, id, refreshViewList, refreshDetail, updateDynamic, countStatus} = props
    const [showCopyPop, setShowCopyPop] = useState(false)
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: workFlowInfo, doFetch: getStatus} = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

    const status = useMemo(()=>{
        return _.get(workFlowInfo,'factorId')
    },[workFlowInfo])

    const [workFlowStatusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const refreshStatus = useCallback(()=>{
        if(_.isNil(id)) return
        // 刷新工作流
        console.log('countStatus',countStatus)
        const params = {
            businessType: 'customer',
            businessId: id
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
    },[getStatus, id, countStatus])

    useEffect(()=>{
        refreshStatus()
    },[refreshStatus])

    const copy = useCallback((value)=>{
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', {icon: 'success'});
    },[])

    const CopyArea = useCallback(()=>{
        const detailUrl = window.location.host + `/#/customerServiceInfo?initId=` + _.get(editFormData,'id')
        return <div className={'flex customer-service-info-copy-popover center-y'}>
            <Button onClick={()=>copy(_.get(editFormData,'issueDesc'))} normal>复制标题</Button>
            <Button onClick={()=>copy(_.get(editFormData,'csrecordID'))} normal>复制客户服务信息编号</Button>
            <Button onClick={()=>copy(detailUrl)} normal>复制客户服务信息的URL</Button>
            <Button onClick={()=>copy(`${_.get(editFormData,'csrecordID')}\n${_.get(editFormData,'issueDesc')}\n${detailUrl}`)} normal>复制编号+标题+URL</Button>
        </div>
    },[copy, editFormData])

    const workFlowColor = useMemo(()=>{
        return convertOptions(status, workFlowStatusOptions, 'color')
    },[workFlowStatusOptions, status])

    return <div className={'customer-service-info-header flex center-y'}>
        <div className="header-left">
            <div className="id-wrap flex center-y">
                <div className="type-tag flex center-y" style={{background: getTagColor(_.get(editFormData,'issuetype'))}}>{_.get(editFormData,'issuetype')}</div>
                <div className="id">{_.get(editFormData,'csrecordID')}</div>
                <ConcernedIcon
                    type='CUSTOMERSERVICE'
                    mode='detail'
                    referenceId={editFormData?.id}
                />
            </div>
            {
                isDetail &&
                <div className="title-wrap flex center-y">
                    <div className="title">
                        <Tooltip title={_.get(editFormData,'issueDesc')}>
                            {_.get(editFormData,'issueDesc')}
                        </Tooltip>
                    </div>
                    <Popover content={<CopyArea/>} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                        <Icon name={'fuzhi'} className={'copy-icon'}/>
                    </Popover>
                </div>
            }
            {
                !isDetail && <FormInput horizontal bind={'issueDesc'} label={'标题'} required value={_.get(editFormData, 'issueDesc')}
                                        onChange={v => setEditFormData(x => ({...x, issueDesc: v}))} maxLength={250}/>
            }
        </div>
        <div className="header-right flex center-y">
            <div className="header-right-item flex center-y">
                <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{color: '#165DFF'}}/>
                <div className="header-right-info flex-y">
                    {
                        isDetail &&
                        <div className="header-right-value">
                            <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes,'userName','userAccount') || '无'}</div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <FormInput horizontal value={_.get(editFormData, 'currentUser')} required component={UserSelect} componentWidth={90}
                                   onChange={v => setEditFormData(x => ({...x, currentUser: v})) }  />
                    }
                    <div className="header-right-field">
                        处理人
                    </div>
                </div>
            </div>
            <Popover destroyTooltipOnHide={true}
                     content={<WorkFlowChangePop businessId={id} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                         refreshStatus()
                         refreshViewList()
                         refreshDetail(id)
                         updateDynamic()
                     }} funcCode={FUNC_CODE} businessType={'customer'}/>}
                     onOpenChange={visible => {
                         if(!visible){
                             setShowWorkFlowPop(null)
                         }
                     }}
                     trigger={'click'}
                     placement="bottomRight"
                     open={showWorkFlowPop}
            >
                <div className="header-right-item flex center-y status-wrap-item" onClick={()=>setShowWorkFlowPop(true)}>
                    <Icon name={'renling'} className={'header-right-icon'} style={{color: workFlowColor}}/>
                    <div className="header-right-info flex-y">
                        <div className="header-right-value">
                            <div className={'status-tag flex center'} style={{color: workFlowColor, border: `1px solid ${workFlowColor}`,padding: '0 8px', borderRadius: 4}}>
                                {convertOptions(status, workFlowStatusOptions)}
                            </div>
                        </div>
                        <div className="header-right-field">
                            当前状态
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    </div>
}

export default CustomerServiceInfoHeader;
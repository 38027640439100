import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, TextArea, DatePicker, Input, RadioGroup, CheckBox } from 'rootnet-edit'
import UserSelect from '../../../../../../../common/personSelectPop/UserSelect'
import convertOptions from '../../../../../../../common/ConvertOptions'
import { preserveOpt } from '../commonData'
import './index.scss'

const { str14ToDate } = toDate

const ADD_URL = '/releaseProducer/add'

const HFormInput = (props) => <FormInput horizontal labelWidth={135} componentWidth={200} {...props} />

const listForm = (props) => {
  const { versionTypeOpt, allUserRes } = props
  return [
    { required: true, label: '制作类型', bind: 'productType', search: true, component: Select, options: versionTypeOpt, bindInConvert: v => _.trim(v), convert: v => convertOptions(_.trim(v), versionTypeOpt) },
    { required: true, label: '制作人', bind: 'producter', component: UserSelect, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      required: true, label: '时间要求', bind: 'completeDate', component: DatePicker, bindInConvert: v => str14ToDate(v),
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
    { required: true, label: '制作次数', bind: 'imakeTimes', component: Input, type: 'number', digit: 0, min: 0 },
    {
      required: true, label: '花费时间', bind: 'imakeTime', component: Input, type: 'number', digit: 0, min: 0, componentWidth: 160, suffix: '小时', convert: v => {
        if (isNil(v)) return '-'
        return `${v}小时`
      }
    },
    { required: true, label: '前一版本号', bind: 'previousRelease', component: Input, componentWidth: 551 },
    { required: true, label: '本次版本号', bind: 'thisRelease', component: Input, componentWidth: 551 },
    { required: true, label: '版本制作基础', bind: 'makeBasic', component: Input, componentWidth: 551 },
    { label: '备注', bind: 'memo', component: TextArea, componentWidth: 551, className: 'text-area-detail', maxLength: 500 },
    { required: true, label: '备份文件是否保存', bind: 'saveCode', component: RadioGroup, options: preserveOpt },
    { label: '代码保存位置', bind: 'codePosition', component: Input, componentWidth: 551 },
    { required: true, label: '复核人', bind: 'reviewProducter', component: UserSelect, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { label: '是否发送消息', bind: 'sendEmail', component: CheckBox, componentWidth: 20 },
  ]
}

export default function VersionMakingAddDialog(props) {
  const { currentId, close, versionTypeOpt, allUserRes, refreshList } = props
  const [params, setParams] = useState({ completeDate: `${dateFormat('YYYYMMDD', new Date())}000000` })
  const [isDisabled, setIsDisabled] = useState(false)
  const [error, setError] = useState()
  const { doFetch } = usePost()

  const formData = useMemo(() => {
    return listForm({ versionTypeOpt, allUserRes })
  }, [versionTypeOpt, allUserRes])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check() || isNil(params?.saveCode)) return Messager.show('请填写必填项', { icon: 'error' })
    if (isDisabled) return
    setIsDisabled(true)
    doFetch(ADD_URL, { releaseId: currentId, ...params })
      .then(() => {
        Messager.show('添加成功', { icon: 'success' })
        close()
        refreshList()
        setIsDisabled(false)
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [check, close, currentId, doFetch, params, refreshList, isDisabled])

  return (
    <Dialog
      header='版本制作增加'
      confirm={confirm}
      cancel={close}
      className='version-making-add-dialog'
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        {
          _.map(formData, o => {
            return <HFormInput key={o.bind} {...o} />
          })
        }
      </Form>
    </Dialog>
  )
}

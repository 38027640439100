import React, { useMemo, useState } from 'react'
import _ from "lodash";
import { Cascader, Tooltip } from 'antd'
import { isNil } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/es/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, TextArea, Display } from 'rootnet-edit'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import { Icon } from '../../../../../components';
// import gd from '../../../../../base/global';
import ReviewDialog from '../reviewDialog';
import convertOptions from '../../../../common/ConvertOptions';
import { Box } from '../../../../common/commonComponent';
import { TextIconBtn } from '../../../../common/TextIconBtn';
import './index.scss'

/*
  rootAnalysis 根因分析 
  rootType     根因分类
*/
const ROOT_TYPE_URL = '/common/globalconst?globalConst=rootType'

const HFormInput = (props) => <FormInput horizontal component={Display} labelWidth={110} componentWidth={200} {...props} />

export default function RootCauseAnalysis(props) {
  const { id, isDetail, editFormData, setEditFormData, refresh, allUserRes, statusOpt } = props
  const [rootAnalysis, setRootAnalysis] = useState(true) // 根因分析
  const [rootMeasures, setRootMeasures] = useState(true) // 根因解决措施
  const [rootEvolve, setRootEvolve] = useState(true) // 根因当前进度
  const [openReview, setOpenReview] = useState(false)
  const { data: typeRes } = useGet(ROOT_TYPE_URL)

  const typeOpt = useMemo(() => {
    if (_.isNil(typeRes)) return []
    return typeRes
  }, [typeRes])

  const rootTypeVal = useMemo(() => getRootType(typeOpt, editFormData?.rootType), [typeOpt, editFormData])

  const extra = useMemo(() => {
    return <>
      <TextIconBtn icon='fuheguanli' text='根因评审' onClick={() => setOpenReview(true)} />
    </>
  }, [])

  return (
    <>
      <div className='root-cause-analysis'>
        <Box
          title='根因分析'
          extra={extra}
          data={[0]}
          className='flex-y x-card-singlegrid'
        >
          <Form value={editFormData} onChange={setEditFormData}>
            {
              // gd.User.operator_id === editFormData?.rootReviewUser &&
              // <div className='review-btn'><Button primary onClick={() => setOpenReview(true)}>评审</Button></div>
            }
            <div className='rootnet-forminput horizontal root-type'>
              <label>根因分类</label>
              <div className='form-component'>
                {
                  isDetail && <Tooltip title={rootTypeVal}><span className='ellipsis-line'>{rootTypeVal}</span></Tooltip>
                }
                {
                  !isDetail && <Cascader
                    // multiple
                    maxTagCount="responsive"
                    style={{ width: '100%' }}
                    options={getTreeOpt(typeOpt)}
                    value={_.filter(_.split(editFormData?.rootType, ','), v => !isNil(v))}
                    onChange={v => {
                      setEditFormData(x => _.assign({}, x, { rootType: _.join(v, ',') }))
                    }}
                    showSearch={{ filter: (inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) }}
                  />
                }

              </div>
            </div>

            <HFormInput label='根因负责人' bind='rootPrincipal' component={isDetail ? Display : UserSelect}
              convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')}
            />

            <div className="area-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: rootAnalysis ? 'none' : 'rotate(-90deg)' }} onClick={() => setRootAnalysis(v => !v)} />
                根因分析
              </div>
              <div className="area-content-wrap" style={{ height: rootAnalysis ? '' : 0 }}>
                {!isDetail && <HFormInput label='' bind='rootAnalysis' counter={false} maxLength={99999} component={TextArea} />}
                {isDetail && <div className='mtext-style'>{_.get(editFormData, 'rootAnalysis')}</div>}
              </div>
            </div>

            <div className="area-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: rootMeasures ? 'none' : 'rotate(-90deg)' }} onClick={() => setRootMeasures(v => !v)} />
                根因解决措施
              </div>
              <div className="area-content-wrap" style={{ height: rootMeasures ? '' : 0 }}>
                {!isDetail && <HFormInput label='' bind='rootMeasures' counter={false} maxLength={99999} component={TextArea} />}
                {isDetail && <div className='mtext-style'>{_.get(editFormData, 'rootMeasures')}</div>}
              </div>
            </div>

            <div className="area-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: rootEvolve ? 'none' : 'rotate(-90deg)' }} onClick={() => setRootEvolve(v => !v)} />
                根因当前进度
              </div>
              <div className="area-content-wrap" style={{ height: rootEvolve ? '' : 0 }}>
                {!isDetail && <HFormInput label='' bind='rootEvolve' counter={false} maxLength={99999} component={TextArea} />}
                {isDetail && <div className='mtext-style'>{_.get(editFormData, 'rootEvolve')}</div>}
              </div>
            </div>

            <HFormInput label='根因评审人' bind='rootReviewUser'
              convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')}
            />
            <HFormInput label='根因评审状态' bind='rootReviewStatus' options={statusOpt}
              convert={v => convertOptions(v, statusOpt, 'text', 'value')}
            />
            <HFormInput label='根因评审时间' bind='rootReviewTime'
              // bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)}
              // bindInConvert={v => toDate.str14ToDate(v)}
              convert={v => {
                if (_.isNil(v)) return '-'
                if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
              }} />
            <HFormInput className='text-area-pre-wrap' label='评审备注' bind='rootReviewMemo' componentWidth={532} />
          </Form>
        </Box>
      </div>
      {openReview && <ReviewDialog {...{ id, statusOpt, refresh }} close={() => setOpenReview(false)} />}
    </>
  )
}

function getRootType(data, rootType) {
  if (isNil(rootType)) return '-'
  const rootTypeList = _.split(rootType, ',')
  const list = _.map(rootTypeList, v => {
    return _.get(_.find(data, o => o.interiorId === v), 'displayName')
  })
  return _.join(list, '/')
}

function getTreeOpt(data) {
  const filterData = _.filter(data, x => isNil(x.pId))
  const filterList = _.filter(data, x => !isNil(x.pId))
  const distinguishData = _.groupBy(filterList, x => x.pId)
  return _.map(filterData, item => {
    const children = _.find(distinguishData, (v, k) => k === item.interiorId)
    return _.assign({
      label: item.displayName,
      value: item.interiorId,
    }, !_.isEmpty(children) && { children: tier(children, distinguishData) })
  })
}

function tier(data, allData) {
  return _.map(data, item => {
    const children = _.find(allData, (v, k) => k === item.interiorId)
    return _.assign({
      label: item.displayName,
      value: item.interiorId,
    }, !_.isEmpty(children) && { children: tier(children, allData) })
  })
}

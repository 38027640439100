import React, {useState, useEffect, useCallback, useMemo, useRef, useReducer} from 'react';
import './RightPanel.scss'
import Icon from "../../../../project_share/components/Icon";
import {Select, Form, FormInput, Grid, Display, Input} from 'rootnet-edit'
import { Button, Messager } from 'rootnet-ui';
import _ from 'lodash'
import { TextIconBtn } from "../../../common/TextIconBtn";
import { useGet } from "rootnet-biz/es/hooks";
import convertOptions from "../../../common/ConvertOptions";
import DefectLinkTracerDialog from "./DefectLinkTracerDialog";
import { Tabs, Popover, Button as AntButton, Tooltip } from "antd";
import TestCaseDataGrid from "./TestCaseDataGrid";
import PopoverComment from "./PopoverComment";
import TinyEditor from "../../../common/richTextEditor/TinyEditor";
import { copyText } from "rootnet-core/clipboard";
import DefectUploadArea from "./DefectUploadArea";
import { strParams } from "../../../../utils/publicFun";
import { selectOption } from "../../../common/commonMethod";
import ChangeRecord from '../../../common/ChangeRecord';
import ReqViewSearchDialog
    from "../../../customerServiceInfo/customerServiceInfoDetailDialog/customerServiceBaseInfo/reqViewSearchDialog/ReqViewSearchDialog";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import CascadeSelect from "../../../common/cascadeSelect/CascadeSelect";
import {isNil} from "../../../appraise/components/method";
import RequirementDetailDialog from "../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import DefectUpdateDialog from "./DefectUpdateDialog";
import usePost from "rootnet-biz/es/hooks/usePost";
import gd from "../../../../base/global";
import usePosition from "../../../common/hooks/usePosition";
import DisplaySelect from "../../../common/displaySelect/DisplaySelect";

const { TabPane } = Tabs

const HFormInput = (props) => <FormInput labelWidth={110} componentWidth={180} horizontal {...props} />

// 封装后的阻止冒泡功能
const stopPropagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
}

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSeverity',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectType',
    '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseID&tableName=View_ReleaseInfo_Version&filter=State',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectOriginPhase',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectRecurrence',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSource',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSolutionResult',
]

const optionsUrl = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/userProject/list'
]

const FUNC_CODE = '0504'

function RightPanel(props) {
    const { isFullScreen, setIsFullScreen, list, currentId, setCurrentId, refreshList } = props
    const [currentItem, setCurrentItem] = useState()
    const [isFold, setIsFold] = useState(false)
    const { data: detail, doFetch: getDetail } = useGet()
    const [activeBind, setActiveBind] = useState()
    const [bindName, setBindName] = useState()
    const [showSearchTracerDialog, setShowSearchTracerDialog] = useState(false)
    const [showSearchDialog, setShowSearchDialog] = useState()
    const { data: testCaseList, doFetch: getTestCaseList } = useGet()
    const { doFetch: doFetchPost } = usePost()
    const richEditorRef = useRef()
    const [showCopyPop, setShowCopyPop] = useState(false)
    const [isEditRichText, setIsEditRichText] = useState(false)
    const { data: allUserRes } = useGet('/common/userinfo')
    const [activeTabKey, setActiveTabKey] = useState('desc')
    const [commentText, setCommentText] = useState()
    const [initReleaseId, setInitReleaseId] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: optionsRes} = useGet(optionsUrl)
    const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
    const {data: workFlowInfo, doFetch: getStatus} = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [editReleaseIdAuth] = usePosition(['QXXG'])

    const {doFetch: getRelease} = usePost()
    const {doFetch: getDiscoveryProject} = useGet()
    const [fixVersionOptions, setFixVersionOptions] = useState([])

    const status = useMemo(()=>{
        return _.get(workFlowInfo,'factorId')
    },[workFlowInfo])

    const refreshStatus = useCallback(()=>{
        if(_.isNil(currentId)) return
        const params = {
            businessType: 'defect',
            businessId: currentId
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
    },[getStatus, currentId])

    useEffect(()=>{
        refreshStatus()
    },[refreshStatus])

    const [workFlowStatusOptions, severityOptions, typeOptions,releaseIdOptions,originPhaseOptions,recurrenceOptions,sourceOptions,solutionResultOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [productTreeOptions, productOptions, moduleOptionsRes, allDevProjectOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4] = optionsRes
        const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        const productOptions = _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        return [productTreeOptions, productOptions, d3,
            _.map(d4, x => ({text:x.projectName, value: x.projectID, status: x.status}))
        ]
    },[optionsRes])

    const effectiveProject = useMemo(()=>{
        const options = _.filter(allDevProjectOptions, x => !_.includes(['1','7', x.status]))
        return _.sortBy(options, x => {
            const fixProjectList = _.get(detail,'fixProjectIdList')
            return !_.includes(fixProjectList, x.value)
        })
    },[allDevProjectOptions, detail])

    // 根据 发现版本 查 发现项目
    const updateDiscoveryProject = useCallback((releaseId)=>{
        getDiscoveryProject('/releaseinfo/getProject?releaseId='+releaseId).then(res => {
            setCurrentItem(x => _.assign({}, x, { discoveryProject: _.get(res, 'projectid') }))
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[getDiscoveryProject])

    const getFixVersionOptions = useCallback((fixProjectList)=>{
        getRelease('/releaseinfo/getVersionWithProject',fixProjectList).then(res => {
            setFixVersionOptions(_.map(res, x => ({
                text: x.releasename,
                value: x.releaseid,
                state: x.state,
                displayFlag: _.includes(['T','C'],x.state) ? 'Y': 'N',
            })))
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[getRelease])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(_.get(currentItem, 'productId'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === currentItem.productId)
        return selectOption(filterModuleOptions, ['subSysName', 'subSysId'])
    }, [currentItem, moduleOptionsRes])

    useEffect(() => {
        // 在 document 上绑定点击事件，隐藏弹出层
        // document.addEventListener("click", () => setActiveBind(null));
    }, [])

    useEffect(() => {
        if (isEditRichText) richEditorRef.current.setContent(_.get(detail, 'description') ? _.get(detail, 'description') : '')
    }, [isEditRichText, detail])

    useEffect(() => {
        if (_.isNil(currentId)) return
        getDetail('/test_defect/get/testDefect/info?defectId=' + currentId).then(res => {
            richEditorRef.current.setContent(_.get(res, 'description') ? _.get(res, 'description') : '')
            const testCaseParams = {
                defectId: currentId,
                caseId: _.get(res, 'caseId'),
                planId: _.get(res, 'planId'),
            }
            setInitReleaseId(_.get(res, 'releaseId'))
            getTestCaseList('/test_defect/case/info?' + strParams(testCaseParams))
            if(!_.isEmpty(_.get(res,'fixProjectIdList'))){
                getFixVersionOptions(_.get(res,'fixProjectIdList'))
            }else{
                setFixVersionOptions([])
            }
        })
        updateDynamic()
    }, [getDetail, currentId, getTestCaseList, getFixVersionOptions])

    useEffect(() => {
        if (_.isNil(detail)) return
        const initParams = {...detail}
        setCurrentItem(initParams)
    }, [detail, setCurrentItem])

    const currentIndex = useMemo(() => {
        return _.findIndex(list, x => _.get(x, 'id') === currentId)
    }, [list, currentId])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentId(switchDirection === 'next' ? list[currentIndex + 1].id : list[currentIndex - 1].id)
    }, [list, setCurrentId, currentIndex])

    const updateFixProject = useCallback((newFixProject, oldFixProject, currentFixVersion)=>{
        getRelease('/releaseinfo/getVersionWithProject',newFixProject).then(res => {
            const newVersionOptions = _.map(res, x => ({
                text: x.releasename,
                value: x.releaseid,
                state: x.state,
                displayFlag: _.includes(['T','C'],x.state) ? 'Y': 'N',
            }))
            const newVersionIdList = _.map(res, 'releaseid')
            const allIncludeFixVersion = _.every(currentFixVersion, x => _.includes(newVersionIdList, x))
            if(allIncludeFixVersion){
                setCurrentItem(x => _.assign({}, x, { fixProjectIdList: newFixProject }))
                setFixVersionOptions(newVersionOptions)
                setBindName('fixProjectIdList')
                setActiveBind(null)
            }else{
                const notIncludeVersion = _.filter(currentFixVersion, x => !_.includes(newVersionIdList, x))
                Messager.show(`项目不允许删除，请先删除该项目下的${_.join(notIncludeVersion,'、')}版本后重试`)
                setCurrentItem(x => _.assign({}, x, { fixProjectIdList: oldFixProject }))
                setActiveBind(null)
            }
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[getRelease])

    const defectIsHaveTracer = useMemo(()=>{
        return _.get(currentItem,'isHaveTracer')
    },[currentItem])

    const isHistoryQuestion = useMemo(()=>{
        return _.get(currentItem,'historyQuestion') === 'Y'
    },[currentItem])

    const onBlurForm = useCallback((bind) => {
        if (bind === 'current_owners' && (_.isEmpty(currentItem['current_owners']) || currentItem[bind] === detail[bind])) return setActiveBind('current_owners')
        if (bind === 'releaseId') {
            updateDiscoveryProject(_.get(currentItem,'releaseId'))
        }
        if(bind === 'fixVersion'){
            if(defectIsHaveTracer && isHistoryQuestion){
                const currentFixVersion = isNil(_.get(currentItem,'fixVersion')) ? [] : _.split(_.get(currentItem,'fixVersion'),'^')
                const oldFixVersion = isNil(_.get(detail,'fixVersion')) ? [] : _.split(_.get(detail,'fixVersion'),'^')
                const cannotDelVersion = _.filter(oldFixVersion, item => {
                    const findItem = _.find(fixVersionOptions, x => x.value === item)
                    return _.includes(['R','L'], _.get(findItem,'state'))
                })
                if(!_.every(cannotDelVersion, x => _.includes(currentFixVersion, x))){
                    Messager.show(`不允许删除已发布/已上线的版本`)
                    setCurrentItem(x => _.assign({}, x, { fixVersion: _.get(detail,'fixVersion') }))
                    setActiveBind(null)
                    return
                }
            }
        }
        if (bind === 'fixProjectIdList'){
            if(_.isEmpty(_.get(currentItem,'fixProjectIdList'))){
                setCurrentItem(x => _.assign({}, x, { fixProjectIdList: [], fixVersion: '' }))
            }else{
                if(!isNil(_.get(detail,'fixVersion'))){
                    const currentFixVersion = _.split(_.get(detail,'fixVersion'),'^')
                    updateFixProject(_.get(currentItem,'fixProjectIdList'),_.get(detail,'fixProjectIdList'),currentFixVersion)
                }
            }
        }
        setBindName(bind)
        setActiveBind(null)
    }, [currentItem, detail, updateDiscoveryProject, updateFixProject, defectIsHaveTracer, fixVersionOptions, isHistoryQuestion])

    const refreshDetail = useCallback((id)=>{
        getDetail('/test_defect/get/testDefect/info?defectId=' + id)
        updateDynamic()
    },[getDetail, updateDynamic])

    const update = useCallback((newCurrentItem) => {
        const updateItem = _.isNil(newCurrentItem) ? currentItem : newCurrentItem
        const postParams = {
            ...updateItem,
            description: richEditorRef.current.getContent(),
        }
        if(isNil(postParams['fixProjectIdList'])){
            postParams['fixProjectIdList'] = []
            postParams['fixVersion'] = ''
        }
        postParams['fixVersionState'] = null
        doFetchPost('/test_defect/update', postParams).then(() => {
            if(_.get(detail,'currentUser') === _.get(postParams,'currentUser')){
                afterRefresh()
            }else{
                const currentUserParams = {
                    businessId: _.get(postParams,'id'),
                    businessType: 'defect',
                    funcCode: '0504',
                    currentUser: _.get(postParams, 'currentUser'),
                    title: _.get(postParams,'title')
                }
                doFetchPost('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(()=>{
                    afterRefresh()
                }).catch((err) => {
                    Messager.show(err._message, { icon: 'error' });
                    setBindName(null)
                })
            }

        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
            setBindName(null)
        })

        function afterRefresh(){
            setIsEditRichText(false)
            setBindName(null)
            refreshList()
            refreshDetail(currentId)
            Messager.show(`修改成功`, { icon: 'success' })
        }
    }, [doFetchPost, currentItem, refreshList, currentId, refreshDetail, detail])

    useEffect(() => {
        if (bindName && _.isNil(activeBind)) {
            if (currentItem[bindName] === detail[bindName]) return setBindName(null)
            if (bindName === 'current_owners' && _.isEmpty(currentItem['current_owners'])) {
                setActiveBind('current_owners')
                return Messager.show('请选择至少一个处理人', { icon: 'error' })
            }
            update()
        }
    }, [activeBind, currentItem, update, bindName, detail])

    const changeForm = useCallback((value, bind) => {
        setCurrentItem(x => {
            const newObj = { ...x }
            newObj[bind] = value
            if (bind === 'productId') {
                newObj['subSysId'] = null
            }
            return newObj
        })
        if (_.isNil(value)) {
            onBlurForm(bind)
        }
    }, [setCurrentItem, onBlurForm])

    const itemComponent = useCallback((bind, component) => {
        if (activeBind !== bind) return Display
        return component
    }, [activeBind])

    const copy = useCallback((value) => {
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', { icon: 'success' });
    }, [])

    const CopyArea = useCallback(() => {
        const id = _.get(currentItem, 'defectId')
        const idTitle = `【${_.get(currentItem, 'defectId')}】${_.get(currentItem, 'title')}`
        const detailUrl = window.location.host + `/#/testDefectMgt?initId=` + currentId
        return <div className={'flex'}>
            <AntButton onClick={() => copy(id)}>复制ID</AntButton>
            <div style={{ width: 10 }} />
            <AntButton onClick={() => copy(idTitle)}>复制ID和标题</AntButton>
            <div style={{ width: 10 }} />
            <AntButton onClick={() => copy(detailUrl)}>复制缺陷URL</AntButton>
        </div>
    }, [copy, currentItem, currentId])

    const RichTextTab = useCallback(() => {
        return <div className={'rich-text-tab'}>
            <span style={{ marginRight: 12 }}>描述</span>
            <Icon className={'rich-text-tab-icon'} name={isEditRichText ? 'zhuxiao' : 'bianji'} onClick={() => setIsEditRichText(x => !x)} />
        </div>
    }, [isEditRichText])

    const onFormInputClick = useCallback((bind, func) => {
        if (selectText().length === 0) {
            if (_.isNil(func)) {
                setActiveBind(bind)
            } else {
                func()
            }
        }
        function selectText() {
            if (document.selection) { //IE浏览器下
                return document.selection.createRange().text
            } else {  //非IE浏览器下
                return window.getSelection().toString()
            }
        }
    }, [])

    const onCommentVisibleChange = useCallback((visible) => {
        if (!visible) {
            setCommentText(null)
        }
    }, [])

    const releaseIdExtendOptions = useMemo(() => {
        let options = releaseIdOptions
        if (isNil(initReleaseId)) return options
        const valueList = _.map(options, 'value')
        if (!_.includes(valueList, initReleaseId)) {
            options = _.concat([{ text: initReleaseId, value: initReleaseId }], options)
        }
        return options
    }, [releaseIdOptions, initReleaseId])

    const fixVersionExtendOptions = useMemo(()=>{
        if(_.isEmpty(fixVersionOptions)) return []
        const initFixVersion = _.get(currentItem,'fixVersion')
        let initVersionList = _.split(_.get(currentItem,'fixVersion'),'^') || []
        if(isNil(initFixVersion)){
            initVersionList = []
        }
        // const valueList = _.map(fixVersionOptions,'value')
        // const findNotIncludedList = _.filter(initVersionList, item => !_.includes(valueList, item))
        // const findNotIncludedOptions = _.map(findNotIncludedList, x => ({label: x,text: x, value: x}))
        // const extendOptions = _.concat(findNotIncludedOptions,fixVersionOptions)
        return _.map(fixVersionOptions, x => ({
            ...x,
            displayFlag: _.includes(initVersionList, x.value) ? 'Y': x.displayFlag
        }))
    },[fixVersionOptions, currentItem])

    const onReqSelected = useCallback((item)=>{
        setCurrentItem(old=>{
            const cloneObj = _.clone(old)
            cloneObj['storyId'] = _.get(item, 'id')
            cloneObj['interiorReqNo'] = _.get(item, 'bizId')
            update(cloneObj)
            return cloneObj
        })
    },[update])

    const getDisplayOptions = useCallback((options)=>{
        return _.filter(options, x => _.get(x,'displayFlag') !== 'N')
    },[])

    const canEditReleaseId = useMemo(()=>{
        return (gd.User.operator_id === currentItem?.createUser && convertOptions(status, workFlowStatusOptions) === '新建') || editReleaseIdAuth
    },[editReleaseIdAuth, status, workFlowStatusOptions, currentItem])

    return <div className={`defect-right-panel fill flex-y ${isFullScreen ? 'full-screen' : ''}`}>
        <div className="panel-header flex center-y">
            <div className="header-left flex center-y" onClick={stopPropagation}>
                {
                    activeBind === 'title' ?
                        <FormInput value={_.get(currentItem, 'title')} onChange={v => changeForm(v, 'title')} autoFocus
                            required componentWidth={500} placeholder={'请填写缺陷标题'} onBlur={() => onBlurForm('title')} /> :
                        <div className="defect-title" onMouseUp={() => onFormInputClick('title')}>
                            【{_.get(currentItem, 'defectId')}】{_.get(currentItem, 'title')}
                        </div>
                }
                {
                    activeBind !== 'title' &&
                    <Popover content={<CopyArea />} placement="bottom" trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                        <Icon name={'fuzhi'} className={'copy-icon'} />
                    </Popover>
                }
            </div>
            <div className="header-icon-group flex center-y">
                <TextIconBtn icon='shangsheng' text=' 上一个' className='previous' onClick={() => switchCurrentItem('previous')} disabled={currentId && currentIndex === 0} />
                <TextIconBtn icon='shangsheng' text=' 下一个' className='next' onClick={() => switchCurrentItem('next')} disabled={!_.isNil(list) && currentId && currentIndex === list.length - 1} />
                <Icon name={isFullScreen ? 'suoxiao' : 'fangda'} className='header-icon' onClick={() => setIsFullScreen(x => !x)} />
            </div>
        </div>
        <div className="panel-content flex-y" style={{ overflow: isFullScreen ? 'hidden' : 'auto' }}>
            {
                !isFullScreen && <div className={`panel-form flex ${isFold ? 'is-fold' : ''}`} onClick={stopPropagation}>
                    <Form value={currentItem} onChange={(obj, bind) => changeForm(obj[bind], bind)}>
                        <Popover destroyTooltipOnHide={true}
                                 content={<WorkFlowChangePop businessId={currentId} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                                     refreshStatus()
                                     refreshList()
                                     refreshDetail(currentId)
                                 }} funcCode={FUNC_CODE} businessType={'defect'}/>}
                                 onOpenChange={visible => {
                                     if(!visible){
                                         setShowWorkFlowPop(null)
                                     }
                                 }}
                                 trigger={'click'}
                                 placement="bottomRight"
                                 open={showWorkFlowPop}
                        >
                            <div className={'editable-formInput flex center-y'} onClick={()=>setShowWorkFlowPop(true)}>
                                <HFormInput
                                    bind='status'
                                    label='状态'
                                    component={Display}
                                    convert={()=>convertOptions(status, workFlowStatusOptions) || '-'}
                                />
                                <Icon name={'bianji'} className={'edit-display-icon'} />
                            </div>
                        </Popover>
                        <div onMouseUp={() => onFormInputClick('severity')}
                             className={`editable-formInput flex center-y ${activeBind === 'severity' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('severity')}
                        >
                            <HFormInput label={'严重程度'} bind={'severity'} component={itemComponent('severity', Select)} search
                                        options={getDisplayOptions(severityOptions)} required convert={v => convertOptions(v, severityOptions)}
                            />
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('severity')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('type')}
                             className={`editable-formInput flex center-y ${activeBind === 'type' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('type')}
                        >
                            <HFormInput search label={'缺陷类型'} bind={'type'} component={itemComponent('type', Select)}
                                        options={getDisplayOptions(typeOptions)} required convert={v => convertOptions(v, typeOptions)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('type')} />
                        </div>
                        <HFormInput search label={'发现项目'} bind={'discoveryProject'} component={Display} convert={v => convertOptions(v, allDevProjectOptions)}/>
                        <div onMouseUp={() => {
                            if(canEditReleaseId) {
                                onFormInputClick('releaseId')
                            }
                        }}
                             className={`editable-formInput flex center-y ${activeBind === 'releaseId' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('releaseId')}
                        >
                            <HFormInput label={'发现版本'} bind={'releaseId'} component={itemComponent('releaseId', Select)} required search
                                        options={getDisplayOptions(releaseIdExtendOptions)} convert={v => convertOptions(v, releaseIdExtendOptions)}
                            />
                            {
                                canEditReleaseId &&
                                <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('releaseId')} />
                            }
                        </div>
                        <div onMouseUp={() => onFormInputClick('fixProjectIdList')}
                             className={`editable-formInput flex center-y ${activeBind === 'fixProjectIdList' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('fixProjectIdList')}
                        >
                            <HFormInput search label={'修复项目'} bind={'fixProjectIdList'} component={itemComponent('fixProjectIdList', DisplaySelect)} multiple clear
                                        options={effectiveProject} toolbar={false}
                                        convert={list => {
                                            const text = _.join(_.map(list, x => convertOptions(x, allDevProjectOptions)),'、')
                                            return <Tooltip title={text}>
                                                <span>{text}</span>
                                            </Tooltip>
                                        }}/>
                        </div>
                        <div onMouseUp={() => onFormInputClick('fixVersion')}
                             className={`editable-formInput flex center-y ${activeBind === 'fixVersion' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('fixVersion')}
                        >
                            <HFormInput search label={'修复版本'} bind={'fixVersion'} component={itemComponent('fixVersion', Select)}
                                        options={getDisplayOptions(fixVersionExtendOptions)} multiple={defectIsHaveTracer}
                                        bindInConvert={v => {
                                            if(!defectIsHaveTracer) return v
                                            if(_.isString(v)){
                                                return _.split(v,'^')
                                            }
                                            return v
                                        }}
                                        bindOutConvert={v => {
                                            if(!defectIsHaveTracer) return v
                                            if(_.isArray(v)){
                                                return _.join(v,'^')
                                            }
                                            return v
                                        }}
                                        clear convert={v => {
                                let value = _.isArray(v)?v: _.split(v,'^')
                                const text = _.join(_.map(value, x => convertOptions(x, fixVersionExtendOptions)),'、')
                                return <Tooltip title={text}>
                                    <span>{text}</span>
                                </Tooltip>
                            }}
                            />
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('fixVersion')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('originPhase')}
                             className={`editable-formInput flex center-y ${activeBind === 'originPhase' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('originPhase')}
                        >
                            <HFormInput search label={'发现阶段'} bind={'originPhase'} component={itemComponent('originPhase', Select)}
                                        options={getDisplayOptions(originPhaseOptions)} required convert={v => convertOptions(v, originPhaseOptions)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('originPhase')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('repairMergedVersion')}
                             className={`editable-formInput flex center-y ${activeBind === 'repairMergedVersion' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('repairMergedVersion')}
                        >
                            <HFormInput label={'修复合入版本'} bind={'repairMergedVersion'} component={itemComponent('repairMergedVersion', Input)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('repairMergedVersion')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('recurrence')}
                             className={`editable-formInput flex center-y ${activeBind === 'recurrence' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('recurrence')}
                        >
                            <HFormInput search label={'重现概率'} bind={'recurrence'} component={itemComponent('recurrence', Select)}
                                        options={getDisplayOptions(recurrenceOptions)} clear convert={v => convertOptions(v,recurrenceOptions )}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('recurrence')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('source')}
                             className={`editable-formInput flex center-y ${activeBind === 'source' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('source')}
                        >
                            <HFormInput search label={'缺陷根源'} bind={'source'} component={itemComponent('source', CascadeSelect)}
                                        options={getDisplayOptions(sourceOptions || [])} clear convert={v => convertOptions(v, sourceOptions)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('source')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('productId')}
                             className={`editable-formInput flex center-y ${activeBind === 'productId' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('productId')}
                        >
                            <HFormInput search label={'所属产品'} bind={'productId'} component={itemComponent('productId', Select)} options={productTreeOptions}
                                        required tree convert={v => convertOptions(v, productOptions)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('productId')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('subSysId')}
                             className={`editable-formInput flex center-y ${activeBind === 'subSysId' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('subSysId')}
                        >
                            <HFormInput search label={'所属模块'} bind={'subSysId'} component={itemComponent('subSysId', Select)}
                                        options={getDisplayOptions(moduleOptions)} clear convert={v => convertOptions(v, moduleOptions)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('subSysId')} />
                        </div>
                        <div onMouseUp={() => onFormInputClick('repeatDefectId')}
                             className={`editable-formInput flex center-y ${activeBind === 'repeatDefectId' ? 'active-formInput' : 'display-formInput'}`}
                             onBlur={() => onBlurForm('repeatDefectId')}
                        >
                            <HFormInput label={'重复缺陷ID'} bind={'repeatDefectId'} component={itemComponent('repeatDefectId', Input)}/>
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('repeatDefectId')} />
                        </div>
                        <div className={'editable-formInput flex center-y'}
                            onClick={() => onFormInputClick('', () => setShowSearchDialog('req'))}>
                            <HFormInput
                                bind='interiorReqNo'
                                label='所属需求/自提单'
                                component={Display}
                                convert={(v) => <span className={'can-enter-text'} onClick={(e)=>{
                                    e.preventDefault()
                                    e.stopPropagation();
                                    setShowDetailDialog({
                                        module: 'req',
                                        id: _.get(currentItem, 'storyId')
                                    })
                                }}>{v}</span>}
                            />
                            <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setShowSearchDialog('req')} />
                        </div>
                        <HFormInput label={'解决结果'} bind={'solutionResult'} component={Display} convert={v => convertOptions(v, solutionResultOptions)}/>
                        <HFormInput label={'验证不通过次数'} bind={'noPassNum'} component={Display}/>
                        <HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                        <HFormInput label={'创建时间'} bind={'createTime'} component={Display}/>
                    </Form>
                </div>
            }
            {
                !isFullScreen && <div className="fold-bar-wrap flex center">
                    <div className={'fold-bar flex center'} onClick={() => setIsFold(x => !x)}>
                        <Icon name='Stop' className={`fold-icon ${isFold ? 'is-fold' : ''}`} />
                    </div>
                </div>
            }
            <div className="full-screen-content flex">
                <div className="common-content flex-y">
                    <Tabs onChange={setActiveTabKey}>
                        <TabPane tab={<RichTextTab />} key={'desc'} forceRender={true}>
                            <div className={'rich-text-area-wrap flex-y'}>
                                <div className={'tab-content'} style={{ display: isEditRichText ? "unset" : 'none' }}>
                                    <TinyEditor ref={richEditorRef} height={350}/>
                                </div>
                                <div className="rich-text-detail-wrap" style={{ display: isEditRichText ? "none" : 'unset' }} onClick={() => setIsEditRichText(true)}>
                                    {
                                        _.isNil(_.get(detail, 'description')) || _.get(detail, 'description') === '' ?
                                            <div className={'no-description'}>暂无内容，请点击输入描述信息</div>
                                            : <div dangerouslySetInnerHTML={{ __html: _.get(detail, 'description') }} />
                                    }
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab='附件' key={'file'}>
                            <div className={'right-panel-defect-file-area flex'}>
                                <DefectUploadArea id={currentId} funcCode={'0504'} allUserRes={allUserRes} />
                            </div>
                        </TabPane>
                        <TabPane tab='动态' key={'dynamic'} forceRender={true}>
                            <div className={'dynamic-wrap'} key={countDynamic}>
                                <ChangeRecord className='defect-change-record' name='缺陷' title={_.get(currentItem, 'title')} funcCode={'0504'} referenceId={currentId} commentReferenceId={_.get(currentItem, 'defectId')} linkUrl={`/testDefectMgt?initId=${currentId}`} id={_.get(currentItem, 'defectId')} />
                            </div>
                        </TabPane>
                        <TabPane tab='测试用例' key={'testCase'} forceRender={true}>
                            <TestCaseDataGrid list={_.isEmpty(testCaseList) ? [] : [testCaseList]} />
                        </TabPane>
                    </Tabs>
                    {
                        activeTabKey === 'desc' && isEditRichText &&
                        <div className="save-btn">
                            <Button primary onClick={() => update()}>保存</Button>
                        </div>
                    }
                </div>
                {
                    isFullScreen && <div className="columns-form flex-y center-x" onClick={stopPropagation}>
                        <Grid cols={1}>
                            <Form value={currentItem} onChange={(obj, bind) => changeForm(obj[bind], bind)}>
                                <Popover destroyTooltipOnHide={true}
                                         content={<WorkFlowChangePop businessId={currentId} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                                             refreshStatus()
                                             refreshList()
                                             refreshDetail(currentId)
                                         }} funcCode={FUNC_CODE} businessType={'defect'}/>}
                                         onOpenChange={visible => {
                                             if(!visible){
                                                 setShowWorkFlowPop(null)
                                             }
                                         }}
                                         trigger={'click'}
                                         placement="bottomRight"
                                         open={showWorkFlowPop}
                                >
                                    <div className={'editable-formInput flex center-y'} onClick={()=>setShowWorkFlowPop(true)}>
                                        <HFormInput
                                            bind='status'
                                            label='状态'
                                            component={Display}
                                            convert={()=>convertOptions(status, workFlowStatusOptions) || '-'}
                                        />
                                        <Icon name={'bianji'} className={'edit-display-icon'} />
                                    </div>
                                </Popover>
                                <div onMouseUp={() => onFormInputClick('severity')}
                                     className={`editable-formInput flex center-y ${activeBind === 'severity' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('severity')}
                                >
                                    <HFormInput label={'严重程度'} bind={'severity'} component={itemComponent('severity', Select)} search
                                                options={getDisplayOptions(severityOptions)} required convert={v => convertOptions(v, severityOptions)}
                                    />
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('severity')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('type')}
                                     className={`editable-formInput flex center-y ${activeBind === 'type' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('type')}
                                >
                                    <HFormInput search label={'缺陷类型'} bind={'type'} component={itemComponent('type', Select)}
                                                options={getDisplayOptions(typeOptions)} required convert={v => convertOptions(v, typeOptions)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('type')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('releaseId')}
                                     className={`editable-formInput flex center-y ${activeBind === 'releaseId' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('releaseId')}
                                >
                                    <HFormInput search label={'发现版本'} bind={'releaseId'} component={itemComponent('releaseId', Select)} required
                                                options={getDisplayOptions(releaseIdExtendOptions)} clear convert={v => convertOptions(v, releaseIdExtendOptions)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('releaseId')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('fixVersion')}
                                     className={`editable-formInput flex center-y ${activeBind === 'fixVersion' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('fixVersion')}
                                >
                                    <HFormInput search label={'修复版本'} bind={'fixVersion'} component={itemComponent('fixVersion', Select)}
                                                required options={getDisplayOptions(fixVersionExtendOptions)} multiple={defectIsHaveTracer}
                                                bindInConvert={v => {
                                                    if(!defectIsHaveTracer) return v
                                                    if(_.isString(v)){
                                                        return _.split(v,'^')
                                                    }
                                                    return v
                                                }}
                                                bindOutConvert={v => {
                                                    if(!defectIsHaveTracer) return v
                                                    if(_.isArray(v)){
                                                        return _.join(v,'^')
                                                    }
                                                    return v
                                                }}
                                                clear convert={v => {
                                        let value = _.isArray(v)?v: _.split(v,'^')
                                        const text = _.join(_.map(value, x => convertOptions(x, fixVersionExtendOptions)),'、')
                                        return <Tooltip title={text}>
                                            <span>{text}</span>
                                        </Tooltip>
                                    }}
                                    />
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('fixVersion')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('originPhase')}
                                     className={`editable-formInput flex center-y ${activeBind === 'originPhase' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('originPhase')}
                                >
                                    <HFormInput search label={'发现阶段'} bind={'originPhase'} component={itemComponent('originPhase', Select)}
                                                options={getDisplayOptions(originPhaseOptions)} required convert={v => convertOptions(v, originPhaseOptions)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('originPhase')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('repairMergedVersion')}
                                     className={`editable-formInput flex center-y ${activeBind === 'repairMergedVersion' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('repairMergedVersion')}
                                >
                                    <HFormInput label={'修复合入版本'} bind={'repairMergedVersion'} component={itemComponent('repairMergedVersion', Input)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('repairMergedVersion')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('recurrence')}
                                     className={`editable-formInput flex center-y ${activeBind === 'recurrence' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('recurrence')}
                                >
                                    <HFormInput search label={'重现概率'} bind={'recurrence'} component={itemComponent('recurrence', Select)}
                                                options={getDisplayOptions(recurrenceOptions)} clear convert={v => convertOptions(v,recurrenceOptions )}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('recurrence')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('source')}
                                     className={`editable-formInput flex center-y ${activeBind === 'source' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('source')}
                                >
                                    <HFormInput search label={'缺陷根源'} bind={'source'} component={itemComponent('source', CascadeSelect)}
                                                options={getDisplayOptions(sourceOptions || [])} clear convert={v => convertOptions(v, sourceOptions)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('source')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('productId')}
                                     className={`editable-formInput flex center-y ${activeBind === 'productId' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('productId')}
                                >
                                    <HFormInput search label={'所属产品'} bind={'productId'} component={itemComponent('productId', Select)} options={productTreeOptions}
                                                required tree convert={v => convertOptions(v, productOptions)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('productId')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('subSysId')}
                                     className={`editable-formInput flex center-y ${activeBind === 'subSysId' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('subSysId')}
                                >
                                    <HFormInput search label={'所属模块'} bind={'subSysId'} component={itemComponent('subSysId', Select)}
                                                options={getDisplayOptions(moduleOptions)} clear convert={v => convertOptions(v, moduleOptions)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('subSysId')} />
                                </div>
                                <div onMouseUp={() => onFormInputClick('repeatDefectId')}
                                     className={`editable-formInput flex center-y ${activeBind === 'repeatDefectId' ? 'active-formInput' : 'display-formInput'}`}
                                     onBlur={() => onBlurForm('repeatDefectId')}
                                >
                                    <HFormInput label={'重复缺陷ID'} bind={'repeatDefectId'} component={itemComponent('repeatDefectId', Input)}/>
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setActiveBind('repeatDefectId')} />
                                </div>
                                <div className={'editable-formInput flex center-y'}
                                     onClick={() => onFormInputClick('', () => setShowSearchDialog('req'))}>
                                    <HFormInput
                                        bind='interiorReqNo'
                                        label='所属需求/自提单'
                                        component={Display}
                                        convert={(v) => <span className={'can-enter-text'} onClick={(e)=>{
                                            e.preventDefault()
                                            e.stopPropagation();
                                            setShowDetailDialog({
                                                module: 'req',
                                                id: _.get(currentItem, 'storyId')
                                            })
                                        }}>{v}</span>}
                                    />
                                    <Icon name={'bianji'} className={'edit-display-icon'} onClick={() => setShowSearchDialog('req')} />
                                </div>
                                <HFormInput label={'解决结果'} bind={'solutionResult'} component={Display} convert={v => convertOptions(v, solutionResultOptions)}/>
                                <HFormInput label={'验证不通过次数'} bind={'noPassNum'} component={Display}/>
                                <HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                                <HFormInput label={'创建时间'} bind={'createTime'} component={Display}/>
                            </Form>
                        </Grid>
                    </div>
                }
            </div>
        </div>
        <Popover content={<PopoverComment referenceId={_.get(currentItem, 'defectId')} funcCode={'0504'} {...{ allUserRes, commentText, setCommentText }}
            title={`【${_.get(currentItem, 'defectId')}】${_.get(currentItem, 'title')}`} linkUrl={`/testDefectMgt?initId=${currentId}`} />}
            trigger="click" placement="topRight" onOpenChange={onCommentVisibleChange}>
            <Tooltip title="评论信息">
                <div className={'fixed-comment-fixed flex center'}>
                    <Icon name={'zidingyishezhi1'} className={'fixed-comment-icon'} />
                </div>
            </Tooltip>
        </Popover>
        {
            showSearchTracerDialog && <DefectLinkTracerDialog close={() => setShowSearchTracerDialog(false)} update={update} changeForm={setCurrentItem} initTracerId={_.get(currentItem, 'tracerId')} />
        }
        {
            showSearchDialog === 'req' &&
            <ReqViewSearchDialog initId={_.get(currentItem, 'storyId')} close={()=>setShowSearchDialog(null)} outerSetItem={onReqSelected}/>
        }
        {
            _.get(showDetailDialog,'module') === 'req' &&
            <RequirementDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog,'id')
            }}/>
        }
        {
            _.get(showDetailDialog,'module') === 'defect' &&
            <DefectUpdateDialog mode={'detail'} close={()=>setShowDetailDialog(null)} currentId={_.get(showDetailDialog, 'id')}
                                refreshList={refreshList}/>
        }
    </div>
}

export default RightPanel;
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { Popconfirm } from 'antd'
import { Form, FormInput, Select } from 'rootnet-edit'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Icon } from '../../../../../components'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { filterDisplayFlagOptions } from '../../../../common/publicFunc'
import convertOptions from '../../../../common/ConvertOptions'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import './index.scss'
import { uniqKeyFor } from '../../../../../project_share/utils/utils'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import { isNil } from '../../../../appraise/components/method'

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=projectRole', //项目角色
]


export default function DevProjectMember(props) {
  const { onChange = () => { }, canSubmit = () => { }, defaultData } = props
  const [data, setData] = useState([])
  const [expand, setExpand] = useState(true)
  const [params, setParams] = useState()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [projectRoleList] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return globalConstOptionsRes
  }, [globalConstOptionsRes])

  const [projectRoleOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const filterSelectOpt = useMemo(() => {
    if (_.isEmpty(projectRoleOptions)) return []
    if (_.isEmpty(params)) return projectRoleOptions
    return _.filter(projectRoleOptions, o => !_.includes(_.map(_.values(params), v => v), o.value))
  }, [params, projectRoleOptions])

  const addDataItem = useCallback(() => {
    const id = uniqKeyFor()
    const addItem = [{
      id,
      projectRole: null,
      projectMember: null,
      required: false,
    }]
    setData(x => _.concat(x, addItem))
  }, [])

  const delDataItem = useCallback((id) => {
    setData(x => _.filter(x, o => o.id !== id))
    setParams(x => _.omit(x, [`${id}Role`, `${id}Member`]))
  }, [])

  useEffect(() => {
    if (_.isEmpty(projectRoleList)) return
    if (_.isEmpty(defaultData)) {
      const filterData = filterDisplayFlagOptions(_.sortBy(projectRoleList, o => isNil(o.memo)))
      const initData = _.map(filterData, o => ({
        id: o.id,
        projectRole: o.interiorId,
        projectMember: null,
        required: o.memo === '1'
      }))
      setData(initData)
      setParams(_.assign(..._.map(filterData, o => ({ [`${o.id}Role`]: o.interiorId }))))
    } else {
      const initData = _.map(defaultData, o => ({
        id: uniqKeyFor(),
        projectRole: o.globalconstId,
        projectMember: _.includes(['PO', 'CM', 'QA'], o.globalconstId) ? o.projectMembers : _.split(o.projectMembers, ','),
        required: _.get(_.find(projectRoleList, item => item.interiorId === o.globalconstId), 'memo') === '1'
      }))
      setData(_.orderBy(initData, 'required', 'desc'))
      setParams(_.assign(..._.map(initData, o => ({ [`${o.id}Role`]: o.projectRole, [`${o.id}Member`]: o.projectMember }))))
    }

  }, [projectRoleList, defaultData])

  useEffect(() => {
    if (_.isEmpty(data)) return
    const changeVale = _.compact(_.map(data, o => {
      if (!_.isNil(_.get(params, `${o.id}Role`)) && !_.isEmpty(_.get(params, `${o.id}Member`))) {
        return {
          globalconstId: _.get(params, `${o.id}Role`),
          projectMembersArray: _.includes(['PO', 'CM', 'QA'], _.get(params, `${o.id}Role`)) ? _.get(params, `${o.id}Member`) : _.join(_.get(params, `${o.id}Member`), ','),
        }
      }
    }))
    const requiredList = _.filter(data, 'required')
    const requiredIds = _.map(requiredList, 'projectRole')
    canSubmit(!_.every(requiredIds, v => _.includes(_.map(changeVale, 'globalconstId'), v)))
    onChange(changeVale)
  }, [onChange, data, params, canSubmit])

  return (
    <div className='dev-project-member'>
      <div className={`area-wrap`}>
        <div className="area-header flex center-y">
          <div>
            <Icon
              name="arrow_drop_down"
              className='fold-icon'
              style={{ display: 'inline-block', transform: expand ? 'none' : 'rotate(-90deg)' }}
              onClick={() => setExpand(x => !x)}
            />
            项目成员
          </div>
          <div>
            <TextIconBtn icon='tianjia' text='添加' onClick={addDataItem} />
          </div>
        </div>
        <div className="area-content-wrap" style={{ flex: expand ? 1 : 'none', height: expand ? '' : 0 }}>

          <div className='project-member-table'>
            <div className='project-member-table-header flex'>
              <div className='header-item'>项目角色</div>
              <div className='header-item'>项目成员</div>
            </div>
            <div className='project-member-table-content'>
              <Form value={params} onChange={setParams}>
                {
                  _.map(data, item => {
                    return <div className='list-item flex' key={item.id}>
                      {
                        item.required &&
                        <div className='item-field item-field-required'>
                          <div className='item-role-required'>
                            {convertOptions(item.projectRole, projectRoleOptions)}
                          </div>
                        </div>
                      }
                      {
                        !item.required &&
                        <div className='item-field'>
                          <FormInput search bind={`${item.id}Role`} component={Select} options={_.isNil(_.get(params, `${item.id}Role`)) ? filterSelectOpt : [_.find(projectRoleOptions, o => o.value === _.get(params, `${item.id}Role`))]} />
                        </div>
                      }
                      <div className='item-field'>
                        <FormInput required={item.required} multiple={!_.includes(['PO', 'CM', 'QA'], _.get(params, `${item.id}Role`))} bind={`${item.id}Member`} component={UserSelect} />
                      </div>
                      {
                        !item.required &&
                        <div className='item-field item-field-icon'>
                          <span style={{ display: 'inline-block' }}>
                            <Popconfirm
                              title="确认删除？"
                              onConfirm={() => delDataItem(item.id)}
                              okText="删除"
                              cancelText="取消"
                              overlayInnerStyle={{ padding: 8, width: 200 }}
                            >
                              <Icon name='shanchu' />
                            </Popconfirm>
                          </span>
                        </div>
                      }
                    </div>
                  })
                }
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

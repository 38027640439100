import React, {useMemo, useState} from 'react';
import './VersionPlanConditionFilterPop.scss'
import {Form, FormInput, Select} from "rootnet-edit";
import UserSelect from "../../../../../../common/personSelectPop/UserSelect";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../../../common/ConvertGlobalConstOptions";
import {Button} from "rootnet-ui";
import {isNil} from "rootnet-core/format";

const HFormInput = props => <FormInput componentWidth={230} {...props}/>

const stageFlagOptions = [
    {value: 0, text: '正常'},
    {value: 1, text: '风险'},
    {value: 2, text: '异常'},
]

const typeOptions = [
    {value: 'req', text: '需求'},
    {value: 'issue', text: 'ISSUE'},
    {value: 'dev', text: '开发自提单'},
]

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=workflowStoryStatus',
    'common/globalconst?globalConst=workflowIssueStatus',
    'common/globalconst?globalConst=storyType',
    'common/globalconst?globalConst=reqMileStone',
]

function VersionPlanConditionFilterPop(props) {
    const {initParams, search, close} = props
    const [formData, setFormData] = useState(initParams)
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [reqStatusOptions, issueStatusOptions, reqTypeOptions, stageOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const statusOptions = useMemo(()=>{
        if(_.isEmpty(reqStatusOptions)) return []
        if(_.isEmpty(issueStatusOptions)) return []
        return _.uniqBy(_.concat(reqStatusOptions, issueStatusOptions),'value')
    },[reqStatusOptions, issueStatusOptions])

    return <div className={'version-plan-condition-filter-pop flex-y'}>
        <Form value={formData} onChange={setFormData}>
            <HFormInput bind={'idOrTitle'} label={'ID或标题'}/>
            <HFormInput bind={'bizType'} label={'类型'} component={Select} multiple clear options={typeOptions}/>
            <HFormInput bind={'storyType'} label={'需求类型'} component={Select} multiple clear search options={reqTypeOptions}/>
            <HFormInput bind={'status'} label={'状态'} component={Select} multiple clear search options={statusOptions}/>
            <HFormInput bind={'selectedStage'} label={'阶段'} component={Select} multiple clear search options={stageOptions}/>
            <HFormInput bind={'stageOwner'} label={'阶段负责人'} component={UserSelect} multiple/>
            <HFormInput bind={'stageFlag'} label={'标识'} component={Select} options={stageFlagOptions} multiple clear/>
        </Form>
        <div className="btn-group flex center-y">
            <Button normal onClick={close}>取消</Button>
            <Button primary onClick={()=>{
                const handleParams = {...formData}
                _.forEach(formData, (v, k) => {
                    if(isNil(v) || _.isEmpty(v)){
                        handleParams[k] = null
                    }
                })
                search(handleParams)
                close()
            }}>确认</Button>
        </div>
    </div>
}

export default VersionPlanConditionFilterPop;
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Dialog, Messager } from 'rootnet-ui'
import { Tooltip } from 'antd'
import './ApplyJoinVersionDialog.scss'
import { Form, FormInput, TextArea, Display } from "rootnet-edit";
import { Icon } from "../../../components";
import VersionSelect from "./VersionSelect";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import { usePost } from "rootnet-biz/es/hooks";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { isNil } from "../../appraise/components/method";
import { strParams } from '../../../utils/publicFun';

const HFormInput = (props) => <FormInput {...props} labelWidth={90} componentWidth={450} horizontal />

const VERSION_UNDER_APPROVAL_URL = '/ReleaseStoryLog/getReleaseStoryLogList' //审批中版本

function ApplyJoinVersionDialog(props) {
    const { id, close, type, afterRefresh, projectIds = [] } = props
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const [releaseListOptions, setReleaseListOptions] = useState([])
    const { doFetch: getReleaseList } = useGet()
    const { doFetch: submitPost } = usePost()
    const { doFetch, data: underApprobal } = useGet()

    useEffect(() => {
        if (_.isEmpty(projectIds)) return setReleaseListOptions([])
        const obj = { stateList: 'C', versionType: 0, projectIds }
        getReleaseList(`/develop/release/list?${strParams(obj)}`)
            .then(res => {
                setReleaseListOptions(_.map(res, item => ({
                    label: <div className={'flex space-between'}>
                        <div>{item.releaseId} </div>
                        <div> {dateFormat('YYYY-MM-DD', toDate.str14ToDate(item.issueDate))}</div>
                    </div>,
                    text: item.releaseId,
                    value: item.releaseId
                })))
            })
            .catch((err) => {
                Messager.show(err._message, { icon: 'error' });
            })
    }, [projectIds, getReleaseList])

    useEffect(() => {
        if (_.isNil(id)) return
        doFetch(`${VERSION_UNDER_APPROVAL_URL}?${strParams({ storyId: id, auditType: '1', auditFlag: 'U', type })}`)
    }, [doFetch, id, type])

    const canSubmit = useMemo(() => {
        if (isNil(_.get(formData, 'optMemo'))) return false
        return _.every(formError, _.isNil)
    }, [formError, formData])

    const submit = useCallback(() => {
        const params = {
            storyId: id,
            type: type,
            auditType: '1',
            releaseStatus: 'C',
            ...formData
        }
        submitPost('/ReleaseStoryLog/addReleaseStoryLogList', params).then(() => {
            Messager.show('提交申请成功', { icon: 'success' })
            if (afterRefresh) {
                afterRefresh(true)
            }
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [submitPost, close, id, formData, type, afterRefresh])

    return <Dialog header={'申请加入版本'} className={'apply-join-version-dialog'} confirmButtonDisabled={!canSubmit} cancel={close} confirm={submit}>
        <Form value={{ underApprobal }}>
            {
                !_.isEmpty(underApprobal) &&
                <HFormInput label='审批中版本' bind='underApprobal' component={Display} convert={v => {
                    const underApprobalIds = _.map(v, o => o.releaseId)
                    return <Tooltip title={_.join(underApprobalIds, '，')}>
                        <div className='apply-join-under-approbal'>{_.join(underApprobalIds, '，')}</div>
                    </Tooltip>
                }} />
            }
        </Form>
        <Form value={formData} onChange={setFormData} error={formError} onError={setFormError}>
            <HFormInput label={'选择版本'} bind={'releaseIdList'} component={VersionSelect} multiple options={releaseListOptions} required />
            <HFormInput label={'申请说明'} bind={'optMemo'} required component={TextArea} />
        </Form>
        <div className={'tips-wrap flex center-y'}>
            <Icon name={'tixing'} className={'tips-icon'} />
            <div className={'tips-text'}>选择所属项目下，已封版的版本，只有负责人通过后才会加入对应的版本中</div>
        </div>
    </Dialog >
}

export default ApplyJoinVersionDialog;
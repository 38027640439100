import {useApi as useApi_, useInit as useInit_, useTransfer as useTransfer_} from "./hook";

//！！！这个文件不要用了！！！

export const useApi = useApi_;

export const useRender = useInit_;

export const useInit = useInit_

export const useTransfer = useTransfer_

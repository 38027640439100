import React, { useState, useMemo, useCallback, useEffect, useReducer } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import { useGet } from 'rootnet-biz/lib/hooks'
import { FormInput, Select } from 'rootnet-edit'
import ArrowCrumbs from '../../../../common/ArrowCrumbs'
import { strParams } from '../../../../../utils/publicFun'
import './index.scss'
import PlanVersionSetting from './planVersionSetting'
import PlanVersionBaseLineList from './planVersionBaseLineList'
import ChangeHistory from './changeHistory'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { useFuncCode } from '../../../../common/commonMethod'
import { getDisabled } from '../../../versionMsg/components/versionDetail/components/permission'

const VERSION_DETAIL_URL = '/releaseinfo/detail'

const GLOBAL_CONST_URLS = [
  '/common/globalconst?globalConst=storyType', // 需求类型
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor', //工作流状态
  '/common/globalconst?globalConst=PRIORITYLIST', //优先级
  '/common/globalconst?globalConst=Yes_Or_No', //是否
]

const REQ_FUNC_CODE = '081015' //版本封板需求调整
const PROJECT_FUNC_CODE = '081020' //版本规划

export default function PlanVersionDetail(props) {
  const { history, firstOrder = 'baseLineList', secondOrder, goBack, currentId, setCurrentId, versionOpt, allUserRes, planversionOpt } = props
  const [activeTab, setActiveTab] = useState(firstOrder)
  const [inventoryDynamic, updateInventoryDynamic] = useReducer((x) => x + 1, 0)
  const { data: defaultData, doFetch } = useGet()
  const { data: globalConstRes } = useGet(GLOBAL_CONST_URLS)
  const [isReqCode, isProjectCode] = useFuncCode([REQ_FUNC_CODE, PROJECT_FUNC_CODE])

  const { functionarys: principals, pouser: poUser, state, functionarys, snowId } = useMemo(() => defaultData || {}, [defaultData])

  const isDisabled = useMemo(() => {
    return getDisabled({ state, principals, poUser, isReqCode, isProjectCode })
  }, [state, principals, poUser, isReqCode, isProjectCode])

  const [storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt] = useMemo(() => {
    if (_.isEmpty(globalConstRes)) return []
    return _.map(globalConstRes, o => convertGlobalConstOptions(o))
  }, [globalConstRes])

  const refresh = useCallback((id) => {
    if (_.isNil(id)) return
    doFetch(`${VERSION_DETAIL_URL}?${strParams({ releaseid: id })}`)
  }, [doFetch])

  const goBackList = useMemo(() => {
    return [
      { text: '预规划项目列表' },
      {
        text: <FormInput search horizontal
          options={planversionOpt}
          component={Select}
          value={currentId}
          onChange={v => {
            history.replace({ pathname: '/planVersionMgt', search: strParams({ initId: v, firstOrder, secondOrder }) })
            setCurrentId(v)
          }}
        />,
        active: true
      },
    ]
  }, [currentId, firstOrder, history, secondOrder, setCurrentId, planversionOpt])

  const operations = useMemo(() => {
    return {
      left: <div>
        <ArrowCrumbs data={goBackList} goBack={goBack} />
      </div>
    }
  }, [goBack, goBackList])

  useEffect(() => {
    refresh(currentId)
  }, [currentId, refresh])

  return (
    <div className='plan-version-detail fill flex-y'>
      <Tabs
        centered
        className='plan-version-tabs'
        activeKey={activeTab}
        tabBarExtraContent={operations}
        onChange={v => {
          history.replace({
            pathname: '/planVersionMgt',
            search: strParams(_.assign({}, { initId: currentId, firstOrder: v },
              v === 'changeHistory' && { secondOrder: 'listChange' },
            ))
          })
          setActiveTab(v)
        }}
        items={[
          {
            key: 'baseLineList',
            label: `基线清单`,
            children: <PlanVersionBaseLineList {...{ currentId, allUserRes, storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt, isReqCode, isProjectCode, principals, poUser, isDisabled, updateInventoryDynamic, state, snowId }} />
          },
          {
            key: 'changeHistory',
            label: `变更历史`,
            children: <ChangeHistory
              snowId={snowId}
              {...{ currentId, history, firstOrder, secondOrder, allUserRes, workflowOpt, inventoryDynamic }}
            />
          },
          {
            key: 'set',
            label: `设置`,
            forceRender: true,
            children: <PlanVersionSetting
              refresh={() => refresh(currentId)}
              {...{ defaultData, versionOpt, allUserRes, functionarys }}
            />
          }
        ]}
      />
    </div>
  )
}

import React from 'react';
import _ from 'lodash';
import './OperationList.scss';
import { P_Clsx as clsx } from "../utils/utils-sp";

/*
维护者：mw
时间:2020-06-18
*/
export default function OperationList(props) {
    const { onClick, options, className, style } = props;

    function handleClick(e, opt) {
        if (_.isFunction(_.get(opt, 'onClick'))) return opt.onClick(opt, e)
        if (_.isFunction(onClick)) onClick(opt, e)
    }

    return <div className={clsx('all-operate',className)} style={style}>
        {
            _.map(options, (o, i) => {
                const {disabled,text} = o;
                return <span key={i} className={clsx({disabled})}
                             onClick={(e) => handleClick(e, o)}>{text}</span>
            })
        }
    </div>
}

OperationList.defaultProps={
    // options: [
    //     {
    //         text:String || Element,//必填
    //         onClick:Func,//选填
    //     }
    // ]
};

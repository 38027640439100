import React, { useMemo, useState } from 'react'
import { Select, FormInput } from 'rootnet-edit'
import { Button, Tooltip } from 'rootnet-ui'
import _ from 'lodash'
import cls from 'clsx'
import Icon from "../../../../project_share/components/Icon";
import { Slogan } from "./Slogan";
import { dateFormat } from "rootnet-core/dateFormat";
import ExportWeekly from './exportWeekly'

const btnList = [
	{ text: '上周', value: 'last' },
	{ text: '本周', value: '' },
	{ text: '下周', value: 'next' }
]

function Option(props) {
	const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
	const { getWeekDate, getWeekCont, loading, workWeek, utFlagRate, userList, personnel, setPersonnel, allRefresh, lastCutOffDate, nextCutOffDate, limitCutOffDate } = props
	const [isExport, setIsExport] = useState(false)
	const [userOpt, isLeader] = useMemo(() => {
		const opt_user = _.map(userList, o => ({ text: o.userName, value: o.userAccount, isLeader: o.managerFlag, tag: o.userName + o.userAccount }))
		const info_person = _.find(userList, item => item.userAccount === userAccount)
		const is_leader = _.get(info_person, 'managerFlag') === 'Y'
		return [opt_user, is_leader]
	}, [userList, userAccount])

	const cutOffDateTips = useMemo(() => {
		return [`重要提醒：当前日期已超过上期成本归集截止日期【${lastCutOffDate}】，请务必在【${limitCutOffDate}】之前将【${lastCutOffDate}】（含）之前的缺失工时补充完整，过时将无法再填写时间工具。`]
	}, [lastCutOffDate, limitCutOffDate])

	const isShowCutOffDateTips = useMemo(() => {
		if (_.isNil(lastCutOffDate) || _.isNil(limitCutOffDate)) return false
		const now = dateFormat('YYYY-MM-DD', new Date())
		if (now >= lastCutOffDate && now <= limitCutOffDate) return true
		return false
	}, [lastCutOffDate, limitCutOffDate])

	return <>
		<div className='x worktimeOption'>
			<div className='optionLeft flex center-y'>
				{isLeader && <FormInput component={Select} value={personnel} horizontal options={userOpt} search onChange={handleChange} />}
				{
					_.map(btnList, ({ text, value }) => {
						return <Button className={cls({ buttonActive: !value })} key={value} onClick={() => getWeekDate({ ...workWeek, userId: personnel, flag: value })} disabled={loading}>
							{text}
						</Button>
					})
				}
				<Tooltip title='刷新'>
					<Icon name={'shuaxin1'} className={'refresh-icon'} onClick={allRefresh} />
				</Tooltip>
				<Button style={{ marginLeft: 10 }} onClick={() => setIsExport(true)}>导出周报</Button>
			</div>
			<div className='optionRight flex center-y'>
				{
					isShowCutOffDateTips &&
					<div className="options-right-item cut-off-date-tips">
						<Slogan sloganList={cutOffDateTips}></Slogan>
					</div>
				}
				<div className={'options-right-item'}>成本归集截止日期
					<Tooltip title='成本归集截止日期代表成本归集的最后日期，超过此日期则无法再新增和修改截止日期（含）之前的记录' placement='top'>
						<Icon name='bangzhu' style={{ color: '#5477FF', fontSize: '14px', marginLeft: 4, cursor: 'pointer' }} />
					</Tooltip>
					：{nextCutOffDate}</div>
				<div className={'options-right-item'}>本周UT达标率：{utFlagRate}%</div>
			</div>
		</div>
		{isExport && <ExportWeekly userAccount={personnel} setIsExport={setIsExport} {..._.pick(workWeek, ['beginDate', 'endDate'])} />}
	</>

	function handleChange(o) {
		getWeekCont({ ...workWeek, userId: o })
		setPersonnel(o)
	}
}

export default Option
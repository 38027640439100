import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Messager, Dialog, Loader } from 'rootnet-ui'
import './RequirementStageUpdateDialog.scss'
import { Tabs } from 'antd';
import Icon from "../../../../../components/Icon";
import _ from "lodash";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import RequirementStageUpdateHeader from "./requirementStageUpdateHeader/RequirementStageUpdateHeader";
import RequirementStageInfo from "./requirementStageInfo/RequirementStageInfo";
import ChangeRecord from "../../../../common/ChangeRecord";
import ReqStageRelateConference from "./reqStageRelateConference/ReqStageRelateConference";
import { TextIconBtn } from "../../../../common/TextIconBtn";
import clsx from "clsx";
import { dateFormat } from "rootnet-core/dateFormat";
import { useFuncCode } from "../../../../common/commonMethod";
import gd from "../../../../../base/global";
import ReviewMinutesAdd from '../../../../conferenceMgt/reviewMinutesMgt/controls/reviewMinutesAdd';
import { isNil } from '../../../../appraise/components/method';

const { TabPane } = Tabs;

const DIALOG_TITLE = {
    'add': '新增阶段',
    'edit': '编辑阶段',
    'detail': '阶段详情',
}

const STAGE_RELATE_CONFERENCE_TYPE = {
    'Req-Review': {
        type: 'REQ',
        title: '【需求评审】'
    },
    'Design-Detailed': {
        type: 'DS',
        title: '【设计评审】'
    },
    'Design-Outline': {
        type: 'DS',
        title: '【设计评审】'
    },
    'Design-Test': {
        type: 'TC',
        title: '【测试用例评审】'
    },
}

const initDeliveryList = [{ id: 1, value: null, type: 'add' }]

function RequirementStageUpdateDialog(props) {
    const { stageOptions, allUserRes, milestoneFlagOptions, storyId, currentStageInfo, close,
        showList, refreshList, updateDynamic, updateDoc, setShowList, reqInfo, bizType, outerVersionOptions = [] } = props
    const { stageId, mode: initMode, stageObj, stageIndex } = currentStageInfo || {}
    const [mode, setMode] = useState(initMode)
    const [editFormData, setEditFormData] = useState()
    const [formError, setFormError] = useState()
    const { doFetch: update } = usePost()
    const { data: initDetail, doFetch: getDetail } = useGet()
    const [activeKey, setActiveKey] = useState('info')
    const [showAddConference, setShowAddConference] = useState(false)
    const [countInnerDynamic, updateInnerDynamic] = useReducer((x) => x + 1, 0)
    const [countConference, updateConference] = useReducer((x) => x + 1, 1000)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [countStatus, updateStatus] = useReducer((x) => x + 1, 0)
    const [workFlowText, setWorkFlowText] = useState()
    const [deliveryList, setDeliveryList] = useState(initDeliveryList)

    const versionOptions = useMemo(() => {
        if (mode === 'add') return outerVersionOptions
        return _.map(_.get(initDetail, 'storyReleaseStoryList'), x => ({
            text: x.releaseid,
            value: x.releaseid,
        }))
    }, [mode, outerVersionOptions, initDetail])

    const refreshDetail = useCallback(() => {
        getDetail('/story/stage/info?stageId=' + stageId).then(res => {
            setEditFormData(res)
            updateStatus()
            setDeliveryList(_.isEmpty(_.get(res, 'delivery')) ? initDeliveryList : _.get(res, 'delivery'))
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getDetail, stageId])

    useEffect(() => {
        if (initMode === 'add') {
            setEditFormData({ milestoneflag: 'N', status: '01', releaseIdList: [] })
        } else {
            refreshDetail()
        }
    }, [initMode, refreshDetail])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const [PMAuth] = useFuncCode(['152603'])

    const canEditPlan = useMemo(() => {
        if (mode === 'add') return true
        if (PMAuth) return true
        const canEditPersonList = [_.get(initDetail, 'createuser'), _.get(initDetail, 'owner'), _.get(initDetail, 'currentUser')]
        if (workFlowText === '初始化' && _.includes(canEditPersonList, gd.User.operator_id)) {
            return true
        }
        return false
    }, [workFlowText, PMAuth, initDetail, mode])

    const canSubmit = useMemo(() => {
        if (!_.every(deliveryList, o => !isNil(o.delivery))) return false
        if (!_.get(editFormData, 'title')) {
            return false
        }
        if (!_.get(editFormData, 'status')) {
            return false
        }
        return !_.some(_.values(formError), x => x)
    }, [formError, editFormData, deliveryList])

    const submit = useCallback(() => {
        const submitForm = _.omit(initDetail, 'relatedocument')
        const submitEditForm = _.omit(editFormData, 'relatedocument')
        if (JSON.stringify(submitForm) === JSON.stringify(submitEditForm)) {
            if (JSON.stringify(_.get(initDetail, 'delivery')) === JSON.stringify(deliveryList)) {
                setMode('detail')
                // return Messager.show('无变更')
                return
            }
        }
        if (submitLoading) return
        setSubmitLoading(true)
        const serial = mode === 'add' ? (_.get(_.last(showList), 'serial') + 1 || 1) : _.get(editFormData, 'serial')
        let completeTimeActual = _.get(editFormData, 'completetimeactual')
        if (_.isNil(completeTimeActual) && _.get(editFormData, 'status') === '03') {
            completeTimeActual = dateFormat('YYYYMMDD', new Date()) + '000000'
        }

        // 版本计划
        let recordList = []
        const initVersionList = _.get(initDetail, 'releaseIdList')
        const currentVersionList = _.get(editFormData, 'releaseIdList')
        _.forEach(initVersionList, x => {
            if (!_.includes(currentVersionList, x)) {
                const delId = _.get(_.find(_.get(editFormData, 'recordList'), item => item.releaseid === x), 'id')
                recordList.push({
                    optionType: 'DEL',
                    id: delId,
                })
            }
        })
        _.forEach(currentVersionList, x => {
            if (!_.includes(initVersionList, x)) {
                recordList.push({
                    optionType: 'ADD',
                    releaseid: x,
                    storyid: _.get(editFormData, 'id'),
                })
            }
        })
        const addItems = _.filter(deliveryList, o => o.type === 'add')
        const editItems = _.filter(deliveryList, o => o.type === 'update')
        const fileterList = _.filter(_.get(initDetail, 'delivery'), o => !_.includes(_.map(deliveryList, 'id'), o.id))
        const delItems = _.map(fileterList, o => ({ ...o, type: 'del' }))
        const deliveryData = _.map(_.concat(addItems, editItems, delItems), o => ({ ...o, stageId }))
        const postParams = _.assign({}, editFormData, {
            storyId: storyId,
            serial: serial,
            workloadplan: _.get(editFormData, 'workloadplan') ? N2Float(_.get(editFormData, 'workloadplan')) : null,
            workloadactual: _.get(editFormData, 'workloadactual') ? N2Float(_.get(editFormData, 'workloadactual')) : null,
            completetimeactual: completeTimeActual,
            bizType,
            recordList,
        }, mode === 'add' && { delivery: deliveryList })
        const url = mode === 'add' ? '/story/stage/add' : '/story/stage/update'
        update(url, postParams).then(() => {
            if (mode === 'add') {
                Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' })
                setSubmitLoading(false)
                refreshList()
                close()
            } else {
                update('/story/stage/updateDelivery', deliveryData)
                    .catch(err => Messager.show(err.Message, { icon: 'error' }))
                const currentUserParams = {
                    businessId: _.get(editFormData, 'id'),
                    businessType: 'stage',
                    funcCode: '1526',
                    currentUser: _.get(editFormData, 'currentUser'),
                    title: _.get(editFormData, 'title')
                }
                update('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                    Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' })
                    setSubmitLoading(false)
                    refreshList()
                    refreshDetail()
                    updateDynamic()
                    updateInnerDynamic()
                    setMode('detail')
                }).catch(err => {
                    setSubmitLoading(false)
                    Messager.show(err.Message, { icon: 'error' });
                })
            }
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err.Message, { icon: 'error' });
        })
    }, [editFormData, storyId, showList, mode, refreshList, close, update, refreshDetail, updateDynamic, initDetail,
        updateInnerDynamic, submitLoading, bizType, deliveryList, stageId])

    const switchMode = useCallback((reset = false) => {
        if (reset) {
            setEditFormData(initDetail)
            setDeliveryList(_.isEmpty(_.get(initDetail, 'delivery')) ? initDeliveryList : _.get(initDetail, 'delivery'))
        }
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [initDetail])

    const currentAddConferenceInfo = useMemo(() => {
        return _.get(STAGE_RELATE_CONFERENCE_TYPE, _.get(editFormData, 'stage'))
    }, [editFormData])

    const currentAddConferenceVersionText = useMemo(() => {
        const version = _.get(reqInfo, 'releaseList')
        if (_.isEmpty(version)) return ''
        return `【${_.join(version, ',')}】`
    }, [reqInfo])

    const conferenceTitle = useMemo(() => {
        if (bizType === 'req') return _.get(reqInfo, 'title')
        if (bizType === 'issue') return _.get(reqInfo, 'shortDesc')
    }, [bizType, reqInfo])

    return <Dialog className={'requirement-stage-update-dialog'} headerVisible={false} footerVisible={false} contentClassName={clsx({ 'is-add-content': mode === 'add' })}>
        <div className="content-wrap flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    {DIALOG_TITLE[mode]}
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        mode !== 'add' &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'tianjia'} className={`header-edit-text-icon`} text={'创建评审纪要'} onClick={() => {
                                setShowAddConference(true)
                            }} />
                        </span>
                    }
                    {
                        mode !== 'add' &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                                switchMode(true)
                            }} />
                        </span>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            {
                mode !== 'add' &&
                <RequirementStageUpdateHeader id={stageId} refreshViewList={refreshList} {...{
                    editFormData, isDetail, setEditFormData, mode,
                    allUserRes, refreshDetail, updateDynamic, countStatus, setWorkFlowText
                }} />
            }
            <div className="content-main-panel flex">
                {
                    submitLoading && <Loader fill />
                }
                <div className="detail-left-panel">
                    <Tabs activeKey={activeKey} onChange={setActiveKey}>
                        <TabPane tab='描述' key={'info'}>
                            <RequirementStageInfo {...{
                                editFormData, setEditFormData, formError, setFormError, stageOptions, milestoneFlagOptions, mode, isDetail,
                                stageObj, stageIndex, storyId, allUserRes, updateDoc, setShowList, canEditPlan, versionOptions, deliveryList, setDeliveryList
                            }} />
                        </TabPane>
                        {
                            mode !== 'add' &&
                            <TabPane tab='会议纪要' key={'conference'}>
                                <div className={'conference-tab-wrap'} key={countConference}>
                                    <ReqStageRelateConference afterRefresh={refreshList}
                                        listUrl={'/story/stage/recordListByStage'}
                                        disassociateUrl={'/story/stage/disassociate/record'}
                                        relateUrl={'/story/stage/related/record'}
                                        {...{ stageId, updateInnerDynamic }} />
                                </div>
                            </TabPane>
                        }
                    </Tabs>
                </div>
                {
                    mode !== 'add' &&
                    <div className="dynamic-wrap" key={countInnerDynamic}>
                        {
                            !_.isNil(stageId) &&
                            <ChangeRecord name={'阶段任务'} funcCode={'1526'} referenceId={stageId} id={_.get(editFormData, 'stageId')}
                                title={_.get(editFormData, 'title')} followBtn={false} commentWidth={'90%'} />
                        }
                    </div>
                }
            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div />
            <div />
            <div className="btn-group flex">
                {
                    isDetail &&
                    <Button normal onClick={close}>关闭</Button>
                }
                {
                    !isDetail &&
                    <Button normal onClick={() => {
                        if (mode === 'add') {
                            close()
                        } else {
                            switchMode(true)
                        }
                    }}>取消</Button>
                }
                {
                    !isDetail &&
                    <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                }
            </div>
        </div>
        {
            showAddConference &&
            <ReviewMinutesAdd close={() => setShowAddConference(false)}
                refreshList={() => {
                    updateInnerDynamic()
                    refreshList()
                    updateConference()
                }}
                initForm={{
                    sSubject: `${_.get(currentAddConferenceInfo, 'title') || ''}${currentAddConferenceVersionText}${conferenceTitle || ''}`,
                    stageSource: 'req',
                    stageId: stageId,
                    sOurPerformer: [],
                    relatedRequirements: [storyId],
                }}
            />
        }
    </Dialog>
}

function N2Float(valueParams) {
    if (_.isNil(valueParams)) return ''
    let value = Math.round(parseFloat(valueParams) * 100) / 100;
    let s = value.toString().split(".");
    if (s.length === 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

export default RequirementStageUpdateDialog;
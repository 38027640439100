import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import { Editor, rootCtx, editorViewCtx, parserCtx, defaultValueCtx, editorViewOptionsCtx } from '@milkdown/core';
import { nordLight } from '@milkdown/theme-nord';
import { ReactEditor, useEditor } from '@milkdown/react';
import { history } from '@milkdown/plugin-history';
import { listener, listenerCtx } from '@milkdown/plugin-listener';
import { menu, menuPlugin, defaultConfig } from "@milkdown/plugin-menu";
// import { slash } from '@milkdown/plugin-slash';
import { Slice } from '@milkdown/prose/model';
import { gfm } from '@milkdown/preset-gfm';
import './MarkdownEditor.scss'
import { block } from '@milkdown/plugin-block';
import { cursor } from '@milkdown/plugin-cursor';

function MarkdownEditor(props, ref) {
    const {readOnly, onChange, initValue} = props

    const myMenu = menu.configure(menuPlugin, {
        config: defaultConfig.map(section => {
            return section.map( item => {
                if (item.type === 'select'){
                    switch (item.text) {
                        case 'Heading': {
                            return {
                                ...item,
                                text: '字号',
                                options: [
                                    { id: '1', text: '大标题' },
                                    { id: '2', text: '中标题' },
                                    { id: '3', text: '小标题' },
                                    { id: '0', text: '文本' },
                                ],
                            };
                        }
                        default:
                            return item;
                    }
                }else if(item.key === 'Undo'){
                    return {
                        ...item,
                        text: '回退'
                    }
                }else{
                    return item
                }

            })
        })
    })

    const {editor, getInstance, loading: editorLoading } = useEditor((root) =>
        Editor.make()
            .config((ctx) => {
                ctx.set(rootCtx, root);
                ctx.set(defaultValueCtx, initValue);
                ctx.update(editorViewOptionsCtx, (prev) => ({ ...prev, editable: () => !readOnly }));
                ctx.get(listenerCtx).markdownUpdated((_, markdown) => {
                    onChange?.(markdown);
                });
            })
            .use(nordLight)
            .use(gfm)
            .use(history)
            .use(block)
            .use(cursor)
            .use(listener)
            .use(myMenu)
    ,[initValue, readOnly, onChange]);

    useImperativeHandle(ref, () => ({
        update: (markdown) => {
            if (editorLoading) return;
            const editor = getInstance();
            editor?.action((ctx) => {
                const view = ctx.get(editorViewCtx);
                const parser = ctx.get(parserCtx);
                const doc = parser(markdown);
                if (!doc) return;
                const state = view.state;
                view.dispatch(state.tr.replace(0, state.doc.content.size, new Slice(doc.content, 0, 0)));
            });
        },
    }));

    useEffect(() => {
        if (editorLoading) return;
        const editor = getInstance();
        if (!editor) return;
    }, [editorLoading, getInstance]);

    return <div className={'markdown-editor-wrap flex'}>
        <ReactEditor editor={editor}/>
    </div>
}

export default forwardRef(MarkdownEditor);
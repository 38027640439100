import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import cls from 'clsx'
import _ from 'lodash'
import { Table, Tooltip, Popover } from 'antd'
import { Messager, Tooltip as RootNetTooltip, Button, Pagination, MessageBox } from 'rootnet-ui'
import { EXECUTION_COLOR } from './publicData'
import { Box } from '../../../common/commonComponent'
import { TextIconBtn } from '../../../common/TextIconBtn'
import { ValueContext, TableContext } from '../../../common/Context'
import BubbleBox from '../../../common/BubbleBox'
import SelectUseCaseLog from './selectUseCaseLog'
import ImportApiDialog from '../../../common/ImportApiDialog'
import DelMessage from '../../../../components/DelMessage'
import ListDetail from './listDetail'
import { Distribution, ExecuteDrawer, Implement } from './moreComponents'
import PlanUseCasesOptin from './planUseCasesOptin'
import { useGet } from 'rootnet-biz/lib/hooks'
import { useApi } from '../../../../utils/hook'
import { Icon } from '../../../../components'
import { localRefresh } from '../../components/getTreeList'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import RichTextEditor from '../../../common/richTextEditor/TinyEditor'
import PersonSelect from '../../../common/personSelect/PersonSelect'
import TestPlanUploadArea from './TestPlanUploadArea'
import './planUseCases.scss'
import ExportTestPlanApiDialog from './exportTestPlanApiDialog'
import ViewQueryDialog from '../../../common/view/viewQueryDialog/ViewQueryDialog'
import { strParams } from '../../../../utils/publicFun'
import RequirementDetailDialog from '../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import IssueDetailDialog from '../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import AssociatedUseCases from './associatedUseCases'
import convertOptions from '../../../common/ConvertOptions'
import DevListDetailDialog from '../../../devListMgt/devListDetailDialog'
import PlanStageLog from './planStageLog'
import UseCaseReminder from './useCaseReminder'
import DefectUpdateDialog from '../../defect/controls/DefectUpdateDialog'

const DEL_URL = '/test/plan/deleteConditions'
const EXPORT_URL = '/test_case/exportFile'
const IMPORT_URL = '/test_case/field/upload'
const TEMPLATE_URL = '/field/download?flag=8'
const EXPORT_ABNORMAL_DATA_URL = '/test_case/field/export'
const IMPLEMENT_RESULT_URL = '/common/globalconst?globalConst=caseResultStatus'
const EXECUTE_URL = '/test/plan/insertTestCaseResult'
const ALLOT_URL = '/test/plan/updateTestCaseResult' //分配负责人

const GLOBAL_CONST_URL = [
  '/common/globalconst?globalConst=casePriority',          // 优先级
  '/common/globalconst?globalConst=casestatus',            // 用例状态
  '/common/globalconst?globalConst=CaseType',              // 用例类型
  '/common/globalconst?globalConst=testDefectOriginPhase', // 用例阶段
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=View_productInfo&pIdParams=pId', //所属产品
  '/UserSetting/getUniversalInterfaces?code=subSysId&codeName=subSysName&tableName=prodSubsysInfo', //所属模块
]

const GET_ID_URL = '/test/plan/getRelateIds'

const iconColor = {
  dev: '#2B78E4',
  req: '#2B78E4',
  case: '#86D735',
  trace: '#7B68EE',
  issue: 'red',
  bug: '#EE631D'
}

const textHint = '已过滤“作废”的用例！'
// const TEXT_HINT = <div>
//   将导入与测试计划同<span style={{ color: 'red' }}>【测试阶段】</span>的用例，当测试计划的所属版本实际发布时间之后创建的用例，且通过需求/issue/缺陷关联的用例，不再进行自动更新进本测试计划中来！
// </div>

function getTreeId(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o.onlyId)
    if (!_.isEmpty(o.testCaseList)) getTreeId(o.testCaseList, arr)
  })
  return arr
}

const getColumns = (props) => {
  const { judge = true, setModeAll, actualResultOpt, changeResult, expanded, setExpanded, expandAll, editId, setEditId, save, allUserRes, resultEnforcementRef, setActive, active, refreshOnlyId, onlyId, setOpen, open, priorityOpt, statusOpt, typeOpt, testPhaseOpt, productOpt, moduleOpt } = props

  if (judge) {
    return [
      {
        title: <UnfoldAndStow />, dataIndex: 'caseName', key: 'caseName', fixed: 'left', width: 400, ellipsis: true, header: '标题', bind: 'title',
        render: (v, o) => {
          const isDetail = o?.flag !== 'case'
          const isDel = o.level !== 0
          return <div className='title-case-name'>
            <div
              onClick={() => isDetail ? setModeAll({ mode: _.toUpper(o?.flag), id: o.relateId }) : onClick(isDetail, { mode: 'detail', data: o })}
              style={{ color: '#5477ff', cursor: 'pointer' }}
            >
              {v}
            </div>
            <div>
              {
                _.includes(['req', 'dev', 'issue'], o.flag) &&
                <RootNetTooltip title='测试阶段列表'>
                  <span>
                    <Icon name='jingshishuoming'
                      onClick={() => {
                        setModeAll({ mode: 'stage', id: o.relateId, type: o.flag })
                      }}
                    />
                  </span>
                </RootNetTooltip>
              }
              {
                !isDel && <RootNetTooltip title='移除'>
                  <span style={{ marginLeft: 8 }}>
                    <Icon
                      name='shanchu2'
                      onClick={() => onClick(isDel, { mode: 'delete', id: o.flag === 'case' ? [{ type: o.flag, relateId: o.id }] : [{ type: o.flag, relateId: o[`relateId`] }] })}
                    />
                  </span>
                </RootNetTooltip>
              }
            </div>
          </div>
        }
      },
      {
        title: 'ID', dataIndex: 'id', key: 'id', width: 130, header: 'ID', bind: 'id', render: (v, o) => {
          if (o?.flag === 'bug') return _.get(o, 'defectId')
          return o[`${o?.flag}Id`]
        }
      },
      { title: '优先级', dataIndex: 'priority', key: 'priority', header: '优先级', bind: 'priority', width: 70, align: 'center', render: v => convertOptions(v, priorityOpt), },
      { title: '用例状态', dataIndex: 'status', width: 85, key: 'status', header: '用例状态', bind: 'status', align: 'center', render: v => convertOptions(v, statusOpt), },
      { title: '用例负责人', dataIndex: 'principle', width: 95, key: 'principle', header: '用例负责人', bind: 'principle', render: principleName },
      { title: '评审人', dataIndex: 'reviewer', width: 95, key: 'reviewer', header: '评审人', bind: 'reviewer', render: v => convertOptions(v, allUserRes, 'userName', 'userAccount', { showOrigin: true }) },
      {
        title: '执行状态', dataIndex: 'result', width: 110, key: 'result', header: '执行状态', bind: 'result',
        render: (v, o) => {
          if (o.flag === 'case') {
            return <Popover
              destroyTooltipOnHide={true}
              open={(open?.visible && open?.id === o.onlyId)}
              onOpenChange={visible => {
                setOpen({ visible: true, id: o.onlyId })
                if (!visible) { setActive(null) }
                else refreshOnlyId()
              }}
              trigger="click"
              overlayClassName='popoverMore'
              content={ActualResult({ data: actualResultOpt, changeResult, id: [o?.id], resultEnforcementRef, setActive, active, resultId: onlyId, allUserRes, setOpen })}
            >
              <span className='actualResult' style={{ color: EXECUTION_COLOR[o?.result] }}>
                {convertOptions(v, actualResultOpt)} <Icon name='bianji2' style={{ color: '#5477ff' }} />
              </span>
            </Popover>
          }
          return convertOptions(v, actualResultOpt)
        }
      },
      { title: '执行人', dataIndex: 'executor', width: 95, key: 'executor', header: '执行人', bind: 'executor', render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
      {
        title: '执行次数', dataIndex: 'executeNum', key: 'executeNum', width: 70, header: '执行次数', bind: 'executeNum',
        render: executeNum
      },
      {
        title: '执行时间', dataIndex: 'executTime', key: 'executTime', width: 130, header: '执行时间', bind: 'executTime'
      },
      {
        title: '缺陷个数', dataIndex: 'issueNum', key: 'issueNum', width: 70, header: '缺陷个数', bind: 'issueNum',
      },
      { title: '用例类型', dataIndex: 'type', width: 95, key: 'type', header: '用例类型', bind: 'type', render: v => convertOptions(v, typeOpt), },
      { title: '用例阶段', dataIndex: 'testPhase', width: 95, key: 'testPhase', header: '用例阶段', bind: 'testPhase', render: testPhase },
      { title: '创建人', dataIndex: 'createUser', width: 90, key: 'createUser', header: '创建人', bind: 'createUser', render: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
      {
        title: '备注', dataIndex: 'remark', width: 200, key: 'remark', header: '备注', bind: 'remark', render: v => {
          return <Tooltip title={v} overlayStyle={{ whiteSpace: 'pre-wrap' }}>
            <div className='ellipsis-line'>{v}</div>
          </Tooltip>
        }
      },
      { title: '所属产品', dataIndex: 'productId', width: 95, key: 'productId', header: '所属产品', bind: 'productId', render: v => convertOptions(v, productOpt), },
      { title: '所属模块', dataIndex: 'moduleId', width: 95, key: 'moduleId', header: '所属模块', bind: 'moduleId', render: v => convertOptions(v, moduleOpt), },
    ]
  }

  return [
    { header: '测试计划ID', bind: 'planId', width: 140, tooltip: true },
    { header: '类型', bind: 'type', width: 90, tooltip: true },
    { header: '关联ID', bind: 'relateId', width: 140, tooltip: true },
  ]

  function testPhase(v) {
    const mapList = _.map(_.split(v, ','), val => convertOptions(val, testPhaseOpt))
    const joinStr = _.join(mapList, ',')
    return <Tooltip title={joinStr}><div className='test-phase-text'>{joinStr}</div></Tooltip>
  }

  function principleName(v, o) {
    const userName = convertOptions(v, allUserRes, 'userName', 'userAccount')
    if (o?.flag !== 'case') return userName
    if (o.onlyId === editId) {
      return <div className='plan-principle-name'>
        <PersonSelect
          defaultOpen
          autoFocus
          useOuterUser={true}
          outerAllUserRes={allUserRes}
          value={o.principle}
          onBlur={() => setEditId(null)}
          size='small'
          onChange={(v) => { save({ caseIdList: [o?.id], principle: v }, o); setEditId(null) }}
        />
      </div>
    }
    return <div onClick={() => setEditId(o.onlyId)} style={{ cursor: 'pointer', height: 22 }}>{userName}</div>
  }

  function UnfoldAndStow() {
    return <>
      <Icon
        style={{ fontSize: 12, marginRight: 8, fontWeight: 400, cursor: 'pointer' }}
        onClick={() => { setExpanded(x => { expandAll(!x); return !x }) }} name={expanded ? 'biaogeshouqi' : 'biaogezhankai'} />
      标题
    </>
  }

  function executeNum(v, o) {
    if (o.flag !== 'case') return v
    return <span
      className='executeNum'
      onClick={() => setModeAll({ mode: 'execute', caseId: o.id })}
    >{v}</span>
  }

  function onClick(is, mode) {
    if (is) return Messager.show('就你能？', { icon: 'error' })
    setModeAll(mode)
  }

};

function ActualResult({ data, changeResult, id, resultEnforcementRef, setActive, active, resultId, allUserRes, setOpen }) {
  const checked = _.isNil(active) ? '02' : active
  return <div className='result-enforcement'>
    <div className='content'>
      <div className='left'>
        {
          _.map(data, o => {
            return <span
              key={o?.value}
              className={checked === o?.value ? 'active' : ''}
              onClick={() => setActive(o?.value)}
              style={{ color: EXECUTION_COLOR[o?.value] }}
            >
              {o?.text}
            </span>
          })
        }
      </div>
      <div className='right'>
        <div style={{ color: '#738299', padding: '2px 0 4px 0' }}>实际执行结果：</div>
        <div className="rich-text-area" >
          <RichTextEditor ref={resultEnforcementRef} />
        </div>
        <TestPlanUploadArea id={resultId} funcCode={'0503'} allUserRes={allUserRes} />
      </div>
    </div>
    <div className='footer'>
      <div style={{ width: 110, background: '#f3f3f3' }}></div>
      <div>
        <Button primary onClick={() => changeResult({ caseIdList: id, status: checked })}>提交</Button>
        <Button text onClick={() => {
          setTimeout(() => {
            if (resultEnforcementRef.current) {
              resultEnforcementRef.current.setContent(_.clone(''))
            }
          }, 800)
          setOpen(_.assign({}, { visible: false }))
        }}>取消</Button>
      </div>
    </div>
  </div>
}

const MoreOperation = (moreOperations) => {
  return <div className='allConditions-popover'>
    <div onClick={() => moreOperations('delete')}>移除</div>
    <div onClick={() => moreOperations('implement')}>执行</div>
    <div onClick={() => moreOperations('distribution')}>分配用例负责人</div>
  </div>
}

const SelectUseCase = (setModeAll) => {
  return <div className='allConditions-popover'>
    <div onClick={() => setModeAll({ mode: 'case' })}>从用例库选择</div>
    <div onClick={() => setModeAll({ mode: 'req' })}>通过需求/自提单选择</div>
    <div onClick={() => setModeAll({ mode: 'issue' })}>通过ISSUE选择</div>
    <div onClick={() => setModeAll({ mode: 'defect' })}>通过缺陷选择</div>
  </div>
}

const getTableOption = (columns) => ({
  columns,
  autoFill: true,
  virtualized: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
})

function recursion(data, level = 0, onlyId = '') {
  _.map(data, item => {
    item.caseName = <Tooltip placement="topLeft" title={item.caseName}>
      <span className='title-icon' style={{ background: iconColor[item.flag] }} >{_.upperCase(item.flag)}</span>
      <span>{item.caseName}</span>
    </Tooltip>
    item.level = level
    item.onlyId = item.onlyId + onlyId
    recursion(item.testCaseList, level + 1, item.caseId)
  })
  return data
}

function getCase(data, arr = []) {
  _.map(data, item => {
    if (item.flag === 'case') return arr.push(_.pick(item, ['caseId', 'id', 'onlyId', 'actualResult', 'result', 'resultId']))
    getCase(item.testCaseList, arr)
  })
  return arr
}

export default function PlanUseCases(props) {
  const { casePageNum, setCasePageNum, casePageSize, setCasePageSize, casePageTotal } = props
  const { planId, testPlanDetails: data, loading, error, refresh, params, allRefresh } = useContext(ValueContext)
  const [selectData, setSelectData] = useState([])
  const [modeAll, setModeAll] = useState()
  const [tableData, setTableData] = useState([])
  const [caseData, setCaseData] = useState()
  const [isSearch, setIsSearch] = useState(true)
  const [currentId, setCurrentId] = useState()
  const [active, setActive] = useState(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [editId, setEditId] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [onlyId, setOnlyId] = useState(null)
  const [open, setOpen] = useState(null)
  const resultEnforcementRef = useRef()
  const { data: actualResultList } = useGet(IMPLEMENT_RESULT_URL)
  const { data: relateShowIdList } = useGet(GET_ID_URL)
  const { doFetch: getOnlyId } = useGet()
  const { doFetch } = useApi()
  const { data: allUserRes, doFetch: getAllUserRes } = useGet()
  const [submitLoading, setSubmitLoading] = useState(false)
  const { doFetch: getIssueFetch, data: issueDefaultData } = useGet()
  const { doFetch: getReqFetch, data: reqDefaultData } = useGet()
  const { doFetch: getCaseFetch, data: caseDefaultData } = useGet()
  const { doFetch: getDefectFetch, data: defectDefaultData } = useGet()
  const [checkDefaultPrincipal, setCheckDefaultPrincipal] = useState()
  const [useCaseReminder, setUseCaseReminder] = useState(false)

  const { data: globalConstRes } = useGet(GLOBAL_CONST_URL)

  const [priorityOpt, statusOpt, typeOpt, testPhaseOpt, productOpt, moduleOpt] = useMemo(() => {
    if (globalConstRes) {
      const [d1, d2, d3, d4, d5, d6] = globalConstRes
      return [
        convertGlobalConstOptions(d1),
        convertGlobalConstOptions(d2),
        convertGlobalConstOptions(d3),
        convertGlobalConstOptions(_.filter(d4, o => o.displayFlag !== 'N')),
        convertGlobalConstOptions(d5),
        convertGlobalConstOptions(d6),
      ]
    }
    return []
  }, [globalConstRes])

  const relateShowIdData = useMemo(() => {
    if (_.isEmpty(relateShowIdList)) return []
    return _.map(relateShowIdList, o => ({ text: o.relateId, value: o.id }))
  }, [relateShowIdList])

  const getIssueData = useCallback(() => {
    if (_.isNil(planId)) return
    getIssueFetch(`/test/plan/selectAssociatedCategory?${strParams({ type: 'issue', planId })}`)
  }, [planId, getIssueFetch])

  const getReqData = useCallback(() => {
    if (_.isNil(planId)) return
    getReqFetch(`/test/plan/selectAssociatedCategory?${strParams({ type: 'req', planId })}`)
  }, [planId, getReqFetch])

  const getCaseData = useCallback(() => {
    if (_.isNil(planId)) return
    getCaseFetch(`/test/plan/selectAssociatedCategory?${strParams({ type: 'case', planId })}`)
  }, [planId, getCaseFetch])

  const getDefectData = useCallback(() => {
    getDefectFetch(`/test/plan/selectAssociatedCategory?${strParams({ type: 'defect', planId })}`)
  }, [planId, getDefectFetch])

  useEffect(() => {
    getIssueData()
    getReqData()
    getCaseData()
    getDefectData()
  }, [getIssueData, getReqData, getCaseData, getDefectData])

  const importRefresh = useCallback(() => {
    refresh(params)
    getIssueData()
    getReqData()
    getCaseData()
    getDefectData()
  }, [refresh, getIssueData, getReqData, getCaseData, getDefectData, params])

  const refreshOnlyId = useCallback(() => {
    getOnlyId('/test_case/productGetOnlyId')
      .then(res => setOnlyId(res))
  }, [getOnlyId])

  useEffect(() => {
    getAllUserRes('/common/userinfo')
  }, [getAllUserRes])

  const actualResultOpt = useMemo(() => convertGlobalConstOptions(actualResultList), [actualResultList])

  const expandAll = useCallback((flag) => {
    setExpandedRowKeys(flag ? getTreeId(tableData) : [])
  }, [tableData])

  const save = useCallback((params, data) => {
    doFetch(ALLOT_URL, 'post', { planId, ...params })
      .then((res) => {
        getAllUserRes('/common/userinfo')
        setTableData(x => localRefresh(x, [{ ...data, principle: params?.principle, principleName: res }]))
      })
      .catch((err) => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, getAllUserRes, planId])

  const changeResult = useCallback((par) => {
    if (resultEnforcementRef.current.loading) {
      return Messager.show('图片提交中,请稍后')
    }
    if (submitLoading) return
    setSubmitLoading(true)
    doFetch(EXECUTE_URL, 'post', { planId, ...par, id: onlyId, actualResult: resultEnforcementRef.current.getContent() })
      .then((res) => {
        setTimeout(() => {
          if (resultEnforcementRef.current) {
            resultEnforcementRef.current.setContent(_.clone(''))
          }
        }, 800)
        setSubmitLoading(false)
        setActive(null)
        setOpen(_.assign({}, { visible: false }))
        allRefresh()
        refresh(params)
        const listData = localRefresh(tableData, res)
        setTableData(listData)
        setCaseData(getCase(listData))
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setSubmitLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [allRefresh, doFetch, planId, tableData, onlyId, submitLoading, refresh, params])

  useEffect(() => {
    if (data) {
      setTableData(_.filter(recursion(data), o => o.flag !== null))
      setCaseData(getCase(data))
    }
  }, [data])

  useEffect(() => {
    const box = document.querySelector('.v-app')
    function close(e) {
      if (open?.visible) {
        setTimeout(() => {
          if (resultEnforcementRef.current) {
            resultEnforcementRef.current.setContent(_.clone(''))
          }
        }, 800)
        setActive(null)
        setOpen({ visible: false })
      }
    }
    if (box) {
      box.addEventListener('click', close)
      return () => box.removeEventListener('click', close)
    }
  }, [open])

  const columns = useMemo(() => getColumns({
    judge: true,
    setModeAll,
    actualResultOpt,
    changeResult,
    expanded,
    setExpanded,
    expandAll,
    editId,
    setEditId,
    save,
    allUserRes,
    setActive,
    active,
    resultEnforcementRef,
    refreshOnlyId,
    onlyId,
    setOpen,
    open,
    priorityOpt, statusOpt, typeOpt, testPhaseOpt, productOpt, moduleOpt
  }), [active, actualResultOpt, allUserRes, changeResult, editId, expandAll, expanded, onlyId, open, refreshOnlyId, save, priorityOpt, statusOpt, typeOpt, testPhaseOpt, productOpt, moduleOpt])
  const defaultOptions = useMemo(() => getTableOption(getColumns({ judge: false })), [])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectData(selectedRows)
    },
    checkStrictly: false
  };

  const takeParentId = useCallback(() => {
    const idList = _.compact(_.map(selectData, o => {
      if (o.level === 0) {
        if (o.flag === 'case') return { relateId: o[`id`], type: o.flag }
        return { relateId: o[`relateId`], type: o.flag }
      }
    }))
    return idList
  }, [selectData])

  const getCaseId = useCallback(() => {
    const idList = _.compact(_.map(selectData, o => { if (o.flag === 'case') { return o[`id`] } }))
    return idList
  }, [selectData])

  const moreOperations = useCallback((action) => {
    const idAll = action === 'delete' ? takeParentId() : getCaseId()
    if (_.isEmpty(idAll)) {
      if (action === 'implement') { Messager.show(`请选择要执行的测试用例`, { icon: 'info' }); return false }
      else if (action !== 'delete') return Messager.show(`请选择数据或选择标题为CASE`, { icon: 'info' })
      if (action === 'delete') return Messager.show(`请选择数据或选择父节点`, { icon: 'info' })
    }
    setModeAll({ mode: action, id: idAll })
    return true
  }, [getCaseId, takeParentId])

  const associatedReq = useCallback((data, isDefaultPrincipal) => {
    if (scalarArrayEquals(reqDefaultData, data)) {
      setModeAll(false)
      Messager.show('数据未修改', { icon: 'info' })
      return
    }
    const initId = _.map(reqDefaultData, o => o.id)
    doFetch(`/test/plan/addConditions`, 'post', {
      planId,
      type: "req",
      principalFlag: isDefaultPrincipal ? 'Y' : 'N',
      relateIdListAdd: checkDataArr(data, initId),
      relateIdListDev: checkDataArr(initId, data)
    })
      .then(() => {
        refresh(params)
        getReqData()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
    function scalarArrayEquals(arr1, arr2) {
      return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.find(arr1, item => item['id'] === o))
    }
    function checkDataArr(arr1, arr2) {
      return _.filter(arr1, v => !_.includes(arr2, v))
    }
  }, [doFetch, reqDefaultData, setModeAll, refresh, planId, getReqData, params])

  const associatedIssue = useCallback((data, isDefaultPrincipal) => {
    if (scalarArrayEquals(issueDefaultData, data)) {
      setModeAll(false)
      Messager.show('数据未修改', { icon: 'info' })
      return
    }
    const initId = _.map(issueDefaultData, o => o.id)
    doFetch(`/test/plan/addConditions`, 'post', {
      planId,
      type: "issue",
      principalFlag: isDefaultPrincipal ? 'Y' : 'N',
      relateIdListAdd: checkDataArr(data, initId),
      relateIdListDev: checkDataArr(initId, data)
    })
      .then(() => {
        refresh(params)
        getIssueData()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
    function scalarArrayEquals(arr1, arr2) {
      return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.find(arr1, item => item['id'] === o))
    }
    function checkDataArr(arr1, arr2) {
      return _.filter(arr1, v => !_.includes(arr2, v))
    }
  }, [doFetch, issueDefaultData, setModeAll, refresh, planId, getIssueData, params])

  const associatedCase = useCallback((data, isDefaultPrincipal) => {
    if (scalarArrayEquals(caseDefaultData, data)) {
      Messager.show('数据未修改', { icon: 'info' })
      return
    }
    const parameter = _.assign({},
      {
        planId,
        type: 'case',
        principalFlag: isDefaultPrincipal ? 'Y' : 'N',
        relateIdListAdd: checkData(data, caseDefaultData),
        relateIdListDev: checkData(caseDefaultData, data),
      })
    doFetch('/test/plan/addConditions', 'post', parameter)
      .then(() => {
        refresh(params)
        getCaseData()
        setSubmitLoading(false)
        Messager.show(`成功`, { icon: 'success' })
      })
      .catch((err) => {
        setSubmitLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
    function scalarArrayEquals(arr1, arr2) {
      return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.find(arr1, item => item['id'] === o['id']))
    }

    function checkData(arr1, arr2) {
      return _.compact(_.map(arr1, o => { if (!_.find(arr2, item => item['id'] === o['id'])) return o['id'] }))
    }

  }, [caseDefaultData, refresh, doFetch, getCaseData, planId, params])

  const associatedDefect = useCallback((data, isDefaultPrincipal) => {
    if (scalarArrayEquals(defectDefaultData, data)) {
      Messager.show('数据未修改', { icon: 'info' })
      return
    }
    const initId = _.map(defectDefaultData, o => o.id)
    const parameter = _.assign({},
      {
        planId,
        type: 'defect',
        principalFlag: isDefaultPrincipal ? 'Y' : 'N',
        relateIdListAdd: checkDataArr(data, initId),
        relateIdListDev: checkDataArr(initId, data),
      })
    doFetch('/test/plan/addConditions', 'post', parameter)
      .then(() => {
        refresh(params)
        getDefectData()
        setSubmitLoading(false)
        Messager.show(`成功`, { icon: 'success' })
      })
      .catch((err) => {
        setSubmitLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
    function scalarArrayEquals(arr1, arr2) {
      return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.find(arr1, item => item['id'] === o['id']))
    }
    function checkDataArr(arr1, arr2) {
      return _.filter(arr1, v => !_.includes(arr2, v))
    }
  }, [planId, doFetch, refresh, getDefectData, params, defectDefaultData])

  const relateCase = useCallback((isDefaultPrincipal) => {
    const module = _.get(checkDefaultPrincipal, 'module')
    const data = _.get(checkDefaultPrincipal, 'data')
    if (module === 'req') {
      associatedReq(data, isDefaultPrincipal)
    } else if (module === 'issue') {
      associatedIssue(data, isDefaultPrincipal)
    } else if (module === 'case') {
      associatedCase(data, isDefaultPrincipal)
    } else if (module === 'defect') {
      associatedDefect(data, isDefaultPrincipal)
    }
    setCheckDefaultPrincipal(null)
  }, [checkDefaultPrincipal, associatedReq, associatedIssue, associatedCase, associatedDefect])

  const contextValue = {
    refresh: () => refresh(params),
    modeAll,
    setModeAll,
    planId,
    caseData,
    isSearch,
    tableData,
    setTableData,
    setCaseData,
    getCase,
    setCurrentId,
    actualResultOpt,
    resultEnforcementRef,
    setActive,
    active,
    changeResult,
    ActualResult,
    refreshOnlyId,
    onlyId,
  }

  const MoreOperations = useCallback(() => MoreOperation(moreOperations), [moreOperations])
  const SelectUseCases = useCallback(() => SelectUseCase(setModeAll), [])

  const handledTableData = useMemo(() => {
    return _.map(tableData, item => {
      if (item.flag === 'case') return item
      const numList = _.map(_.get(item, 'testCaseList'), 'executeNum')
      const executeNum = _.sum(numList)
      item['executeNum'] = executeNum


      const issueNumList = _.map(_.get(item, 'testCaseList'), 'issueNum')
      const issueNum = _.sum(issueNumList)
      item['issueNum'] = issueNum
      return item
    })
  }, [tableData])

  return (
    <TableContext.Provider value={contextValue}>
      <div className={cls('planUseCases fill')}>
        <PlanUseCasesOptin {...{ expandAll, actualResultOpt, isSearch, setCasePageNum, priorityOpt, statusOpt, typeOpt, testPhaseOpt, relateShowIdData, productOpt, moduleOpt }} />
        <Box className='x-card-singlegrid' title='用例列表' error={error} loading={loading} data={data} extra={<Extra />}>
          {
            (tableData && !loading) && <Table
              bordered
              columns={columns}
              dataSource={handledTableData}
              pagination={false}
              expandAllRows
              selectedRowKeys={selectData}
              rowKey={record => record.onlyId}
              rowSelection={{ ...rowSelection }}
              childrenColumnName='testCaseList'
              size="small"
              scroll={{ y: 9999999, x: 1300 }}
              expandedRowKeys={expandedRowKeys}
              rowClassName={record => currentId === record?.onlyId ? 'l-table-row-active' : ''}
              onRow={record => ({
                onClick: () => setCurrentId(record.onlyId)
              })}
              onExpand={(bool, row) => {
                if (bool) setExpandedRowKeys(x => [...x, row.onlyId])
                else {
                  const index = expandedRowKeys.findIndex((e) => e === row.onlyId)
                  const newArray = [...expandedRowKeys]
                  newArray.splice(index, 1)
                  setExpandedRowKeys(newArray)
                }
              }}
            />
          }
          <Pagination pageSize={casePageSize} current={casePageNum} total={casePageTotal} selector
            onChange={(pageNum, pageSize) => {
              setCasePageNum(pageNum)
              setCasePageSize(pageSize)
            }} />
        </Box>
      </div>
      {useCaseReminder && <UseCaseReminder close={() => setUseCaseReminder(false)} />}
      {_.includes(['re', 'trace'], modeAll?.mode) && <SelectUseCaseLog />}
      {
        modeAll?.mode === 'req' && <ViewQueryDialog
          bizName='需求/自提单'
          initValue={_.map(reqDefaultData, o => o.id)}
          initItemValue={reqDefaultData}
          multiple={true}
          close={() => setModeAll(null)}
          funcCode={'0004'}
          outerSetId={data => setCheckDefaultPrincipal({ module: 'req', data: data })}
          textHint={textHint}
        />
      }
      {
        modeAll?.mode === 'issue' &&
        <ViewQueryDialog
          bizName='ISSUE'
          initValue={_.map(issueDefaultData, o => o.id)}
          // initItemValue={issueData}  associatedIssue
          multiple={true}
          close={() => setModeAll(null)}
          funcCode={'0005'}
          outerSetId={data => setCheckDefaultPrincipal({ module: 'issue', data: data })}
          textHint={textHint}
        />
      }
      {modeAll?.mode === 'case' &&
        <AssociatedUseCases
          outerSetItem={data => setCheckDefaultPrincipal({ module: 'case', data: data })}
          caseDefaultData={caseDefaultData}
          close={() => setModeAll(null)}
          textHint={textHint}
        />
        // <SelectUseCaseTreeLog />
      }
      {
        modeAll?.mode === 'defect' &&
        <ViewQueryDialog
          bizName='缺陷'
          initValue={_.map(defectDefaultData, o => o.id)}
          multiple={true}
          close={() => setModeAll(null)}
          funcCode={'00123'}
          outerSetId={data => setCheckDefaultPrincipal({ module: 'defect', data: data })}
          textHint={textHint}
        />
      }
      {
        modeAll?.mode === 'stage' &&
        <PlanStageLog {...modeAll} close={() => setModeAll(null)} />
      }
      {//导出
        modeAll?.mode === 'export' && <ExportTestPlanApiDialog
          name='测试计划'
          close={() => setModeAll(null)}
          url={EXPORT_URL}
          parameter={_.assign(params, { planId, flag: 1 })}
        />
      }

      {//导入
        modeAll?.mode === 'import' &&
        <ImportApiDialog
          title='测试计划'
          abnormal={EXPORT_ABNORMAL_DATA_URL}
          importUrl={IMPORT_URL}
          template={TEMPLATE_URL}
          close={() => setModeAll(null)}
          refresh={importRefresh}
          parameter={{ flag: 1 }}
          abnormalParams={{ whichPage: 1 }}
          exportFieldname='testPlanRelatedVos'
          defaultOptions={defaultOptions}
        />
      }

      {//删除
        modeAll?.mode === 'delete' && <DelMessage
          header={<span className='DelMessage-lz'>移除用例<span>（已选{_.size(modeAll.id)}个用例）</span></span>}
          method='post'
          submitText='移除成功'
          refresh={importRefresh}
          close={() => setModeAll(null)}
          params={{ relateIdListDev: modeAll.id, planId }}
          url={DEL_URL}
        >
          <div style={{ flex: 1 }}>
            <div>确定移除用例吗？</div>
            <div style={{ fontSize: 12, color: '#BBBBBB' }}>请注意：移除【需求、issue、缺陷】时，将移除该单据下所有的用例，请谨慎操作！</div>
          </div>
        </DelMessage>
      }

      {//执行
        modeAll?.mode === 'implement' && <Implement />
      }

      {//分配用例负责人
        modeAll?.mode === 'distribution' && <Distribution />
      }

      {
        modeAll?.mode === 'detail' && <ListDetail />
      }
      {
        modeAll?.mode === 'REQ' &&
        <RequirementDetailDialog close={() => setModeAll(null)} currentInfo={{
          id: _.get(modeAll, 'id')
        }} />
      }
      {
        modeAll?.mode === 'DEV' &&
        <DevListDetailDialog close={() => setModeAll(null)} currentInfo={{
          id: _.get(modeAll, 'id')
        }} />
      }
      {
        modeAll?.mode === 'ISSUE' &&
        <IssueDetailDialog close={() => setModeAll(null)} currentInfo={{
          id: _.get(modeAll, 'id')
        }} />
      }
      {
        modeAll?.mode === 'BUG' &&
        <DefectUpdateDialog
          mode={'detail'}
          close={() => setModeAll(null)}
          currentId={_.get(modeAll, 'id')}
        />
      }
      {
        !_.isNil(checkDefaultPrincipal) &&
        <MessageBox headerVisible={false} className={'content-center-dialog'} confirm={() => relateCase(true)} cancel={() => relateCase(false)}
          confirmButtonText={'是'} cancelButtonText={'否'}>
          是否把用例负责人默认为维护人
        </MessageBox>
      }
      {/* 执行次数 */}
      <ExecuteDrawer />

    </TableContext.Provider>
  )

  function Extra() {
    return <>
      {false && <TextIconBtn icon='' text='用例待办提醒' onClick={() => setUseCaseReminder(true)} />}
      <TextIconBtn icon='daochu' text='导出' onClick={() => setModeAll({ mode: 'export' })} />
      <TextIconBtn icon='daoru' text='导入' onClick={() => setModeAll({ mode: 'import' })} />
      <TextIconBtn icon='shaixuan' text='筛选' onClick={() => setIsSearch(x => !x)} />
      <BubbleBox text='选择用例' placement='top'><SelectUseCases /></BubbleBox>
      <BubbleBox placement='topLeft'><MoreOperations /></BubbleBox>
    </>
  }

}

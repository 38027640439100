import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import ViewQueryDialog from '../../../../../../common/view/viewQueryDialog/ViewQueryDialog'

const ADD_URL = '/ReleaseStory/addReleaseStoryList'

function scalarArrayEquals(arr1, arr2) {
  return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.find(arr1, v => v === o))
}
function checkDataArr(arr1, arr2) {
  return _.filter(arr1, v => !_.includes(arr2, v))
}

export default function RelevanceIssue(props) {
  const { open, data, close, releaseid, refresh, loading, appendParams = {}, replaceType, textHint, appendObj } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const initIdList = useMemo(() => _.map(data, 'id'), [data])

  const relatedIssue = useCallback((idList) => {
    if (scalarArrayEquals(initIdList, idList)) {
      Messager.show('无变更', { icon: 'info' })
      close()
      return
    }
    if (isDisabled) return
    setIsDisabled(true)
    const paramsArr = []
    const addIds = checkDataArr(idList, initIdList)
    const delIds = checkDataArr(initIdList, idList)
    if (!_.isEmpty(addIds)) paramsArr.push({ releaseid, type: replaceType || 'ISSUE', optionType: 'ADD', storyidList: addIds, ...appendParams })
    if (!_.isEmpty(delIds)) paramsArr.push({ releaseid, type: replaceType || 'ISSUE', optionType: 'DEL', storyidList: delIds, ...appendParams })
    doFetch(ADD_URL, paramsArr)
      .then(() => {
        setIsDisabled(false)
        refresh()
        close()
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [initIdList, doFetch, close, refresh, releaseid, isDisabled, appendParams, replaceType])

  return (
    <>
      {
        open &&
        <ViewQueryDialog
          bizName='ISSUE'
          multiple={true}
          initValue={initIdList}
          initItemValue={data}
          funcCode={'0005'}
          outerSetId={relatedIssue}
          initLoading={loading}
          {...{ close, textHint, appendObj }}
        />
      }
    </>
  )
}

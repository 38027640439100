import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { DataGrid, Pagination, Progress, Tooltip } from 'rootnet-ui';
import ImpProjectDetail from "./impProjectDetail/ImpProjectDetail";
import _ from "lodash";
import convertTableAlign from "../../common/view/convertTableAlign";
import findConvert from "../../common/view/findConvert";
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";
import { Box } from "../../common/commonComponent";
import TextIconBtn from "../../../project_share/components/TextIconBtn";
import './ImpProjectMgt.scss'
import { pathSearchFor, strParams } from "../../../utils/publicFun";
import useGetIdList from "../../common/view/hooks/useGetIdList";
import useGetAppendData from "../../common/view/hooks/useGetAppendData";
import { API1 } from "../../../base/task";
import { N2, P2, N0, isNil } from "rootnet-core/format";
import { Icon } from "../../../project_share/components";
import ViewArea from "../../common/view/ViewArea";

const ENTER_DETAIL_FIELD = 'ImplementProject.ProjectName'
const HEAD_ICON_TOOLTIP = {
    'ImplementProject.projectProgress': `算法是项目规化中，全部完成任务'标准工时'/全部任务'标准工时'，'标准工时'为0不参于计算。`,//项目进度
    'ImplementProject.AcceptanceDate': '技术上线使用的第一个交易日，一般是周一',//实际验收日期
}

const STATUS_COLOR = {
    '01': '#338FE5',
    '02': '#F0A100',
    '03': '#24B47E',
    '04': '#A9A9A9',
    '06': '#F85E5E',
}

const PRIORITY_COLOR = {
    'T': '#F5221B',
    'A': '#FFC20E',
    'B': '#5477ff',
    'C': '#A9A9A9',
}
// 实际验收日期
const SPECIAL_FIELD_CONVERT = {
    'ImplementProject.ProjectStatus': (value, showValue) => {
        return <div className={'status-field flex center'} style={{ background: STATUS_COLOR[value], width: 60, padding: '1px 12px 2px', color: '#fff', borderRadius: 4 }}>
            {showValue}
        </div>
    },
    'ImplementProject.Priority': (value, showValue) => {
        return <div className={'priority-field flex center'} style={{ color: PRIORITY_COLOR[value], border: `1px solid ${PRIORITY_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
            {showValue}
        </div>
    },
    'ImplementProject.riskLevel': (value, showValue, color) => {
        return <div style={{ color }}>{showValue}</div>
    }
}
function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentId, appendData, history } = props
    const customColumns = [
        {
            header: <span>
                项目进度<Tooltip title={`算法是项目规化中，全部完成任务'标准工时'/全部任务'标准工时'，'标准工时'为0不参于计算。`}><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>, bind: 'projectProgress', convert: (v, o) => convertProgress(o.id)
        },
        { header: '预计周期', bind: 'expectedCycle', convert: v => v ? v + '天' : null },
        {
            header: <span>
                时间消耗<Tooltip title='（系统当前日期 - 预计开始日期 - 挂起时长）/ 预计周期 * 100%'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
            , bind: 'timeUsed'
        },
        {
            header: <span>
                提升比例<Tooltip title='项目进度 - 时间消耗'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
            , bind: 'improvePercent'
        },
        {
            header: <span>
                未来投入<Tooltip title='预算总成本 - 实际成本'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
            , bind: 'residualCost', convert: N2
        },
        {
            header: <span>
                投入总成本<Tooltip title='实际成本 + 未来投入'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
            , bind: 'totalCost', convert: N2
        },
        {
            header: <span>
                节约比例<Tooltip title='（预估总成本-投入总成本）/预估总成本*100%'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
            , bind: 'savingRatio', convert: P2
        },
        {
            header: <span>
                实际成本<Tooltip title='实际花费'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
            , bind: 'usedCost', convert: N2
        },
        { header: '合同', bind: 'contractNameExt', convert: (v, o) => o.contractName, tooltip: true, },
        { header: '项目经理', bind: 'PrincipalExt', convert: (v, o) => o.userName, tooltip: true, },
    ]

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            return {
                ..._.find(customColumns, x => x.bind === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        } else {
            const field = `${fieldItem.tableName}.${fieldItem.fieldId}`
            return {
                header: headIcon(fieldItem.fieldName, field),
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem, field)
            }
        }
    }

    function convertEditField(v, o, fieldItem, field) {
        if (_.isNil(_.get(SPECIAL_FIELD_CONVERT, field))) return convertField(v, o, fieldItem)
        const getColor = _.get(_.find(convertCollection[field], o => o.value === v), 'color')
        return _.get(SPECIAL_FIELD_CONVERT, field)(v, convertField(v, o, fieldItem), getColor)
    }

    function headIcon(name, field) {
        if (!isNil(HEAD_ICON_TOOLTIP[field])) {
            return <span>
                {name}<Tooltip title={HEAD_ICON_TOOLTIP[field]}><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
            </span>
        }
        return name
    }

    function convertField(v, o, fieldItem) {
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        if (`${fieldItem.tableName}.${fieldItem.fieldId}` === ENTER_DETAIL_FIELD) {
            return <div className={'enter-detail-field'} onClick={() => {
                setCurrentId(o.id)
                history.replace({ pathname: '/impProjectMgt', search: strParams({ initProjectId: o.id, firstOrder: 'details' }) })
            }}>
                {showValue || '-'}
            </div>
        }
        return showValue || '-'
    }

    function convertProgress(id) {
        const appendItem = _.find(appendData, x => x.id === id)
        const { projectProgress } = appendItem?.object || {}
        const projectNum = N0((_.toNumber(projectProgress) || 0) * 100)
        return <Progress percent={projectNum} />
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: false,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 100
    }
}

const FUNC_CODE = '1801'

function ImpProjectMgt(props) {
    const { history } = props
    const { initProjectId = null, firstOrder = 'details' } = pathSearchFor(props.history.location.search)
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [currentId, setCurrentId] = useState(initProjectId)
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [focusId, setFocusId] = useState(initProjectId)
    const [sortConfig, setSortConfig] = useState()
    const [factor, setFactor] = useState()

    const idList = useGetIdList(list)
    const appendData = useGetAppendData(idList, '/implement/project/get/result')

    const appendList = useMemo(() => {
        let appendFieldList = _.map(appendData, x => _.pick(x?.object, ['projectProgress', 'expectedCycle', 'timeUsed', 'improvePercent', 'residualCost', 'totalCost', 'savingRatio', 'usedCost', 'contractName', 'userName']))
        _.forEach(appendFieldList, item => {
            _.forEach(item, (value, key) => {
                if (_.includes(['contractName', 'userName', 'timeUsed', 'improvePercent'], key)) item[key] = value
                else item[key] = _.toNumber(value)
            })
        })
        // 数字类型字段排序
        let decimalList = list
        _.forEach(fieldList, fieldItem => {
            if (fieldItem['fieldDbType'] === "DECIMAL") {
                _.forEach(decimalList, item => {
                    item[fieldItem.columnId] = _.toNumber(item[fieldItem.columnId])
                })
            }
        })
        return _.map(decimalList, (x, i) => _.assign(x, appendFieldList[i]))
    }, [list, appendData, fieldList])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const dateOptions = useGetDateOptions()

    const { options, dataGridList, TreeSwitch } = useGetTreeList({
        fieldList, list: appendList, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: { setCurrentId, appendData, history },
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                return _.orderBy(data, x => x[sort.column], sort.direction)
            }
        },
    })

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            fieldExportList: _.map(fieldList, 'columnId'),
            fieldNameExportList: _.map(fieldList, 'fieldName'),
            factor: factor
        }
        API1.downloadPost('/implement/exportExcel', postParams)
    }, [currentViewId, fieldList, factor])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <TreeSwitch />
            <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
        </div>
    }, [exportViewList])

    useEffect(() => {
        _.forEach(dataGridList, o => {
            return o._rowClass = o?.id === focusId ? 'select_row' : ''
        })
        forceUpdate()
    }, [focusId, dataGridList])

    const onRowClick = useCallback((item) => {
        setFocusId(item.id)
    }, [])

    return (
        <div className={'imp-project-mgt fill flex-y'}>
            <div className={'flex-y fill'} style={{ display: !isNil(currentId) ? 'none' : '' }}>
                <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                    {...{
                        getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
                        currentViewId, setCurrentViewId, params, pageSize
                    }} />
                <Box title={'实施项目管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                    <DataGrid option={options} data={dataGridList} onRowClick={onRowClick} />
                    <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, { pageNum, pageSize }))
                        }} />
                </Box>
            </div>
            {
                !isNil(currentId) && <ImpProjectDetail goBack={() => {
                    setCurrentId(null)
                    history.replace({ pathname: '/impProjectMgt' })
                }} {...{ currentId, setCurrentId, convertCollection, refreshList, history, firstOrder }} />
            }
        </div>
    )
}

export default ImpProjectMgt;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Cascader} from "antd";
import _ from 'lodash'
import {isNil} from "../../appraise/components/method";
import './CascadeSelect.scss'
import convertOptions from "../ConvertOptions";
import {REQUIRED_ERROR, useControlledError} from "../personSelectPop/useControlledError";
import clsx from "clsx";

function handleTreeOptions(allList, currentList, valueField, parentField, textField){
    return _.map(currentList, item => {
        const childrenList = _.filter(allList, x => x[parentField] === item[valueField])
        const currentItem = {value: item[valueField], label: item[textField]}
        if(!_.isEmpty(childrenList)) _.assign(currentItem, {children: handleTreeOptions(allList, childrenList, valueField, parentField, textField )})
        return currentItem
    })
}

function CascadeSelect(props) {
    const {options=[], multiple=false, valueField = 'value', textField = 'text', parentField = 'pid', style, required, disabled = false} = props
    const [stateValue, setStateValue] = useState()
    const [isInited, setIsInited] = useState(false)
    const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);

    useEffect(() => {
        const err = (required && _.isEmpty(stateValue)) ? REQUIRED_ERROR : null;
        if (err !== error) {
            setStateError(err);
            if (props.onError)
                props.onError(err);
        }
    }, [stateValue, required, props, error, setStateError])

    const isControlled = useMemo(()=>{
        return !_.isNil(props.value) || _.isNil(props.defaultValue)
    },[props.value, props.defaultValue])

    const initData = useCallback((data) =>{
        if(_.isEmpty(options)) return
        if(multiple){
            if(!_.isNil(data) && !_.isArray(data)) return
            const init = _.map(data, dataItem => {
                return findAllPath(dataItem,[dataItem])
            })
            setStateValue(init)
        }else{
            setStateValue(data)
        }
        setIsInited(true)

        function findAllPath(currentId, pathList){
            const findItem = _.find(options, x => _.get(x,valueField) === currentId)
            const rootValue = _.get(_.head(options),'rootValue')
            if(isNil(_.get(findItem,parentField)) || (_.get(findItem,parentField) === rootValue)){
                return pathList
            }else{
                const findParentItem = _.find(options, x => _.get(x,valueField) === _.get(findItem,parentField))
                const newList = _.concat([_.get(findParentItem,valueField)],pathList)
                return findAllPath(_.get(findParentItem,valueField),newList)
            }
        }
    },[options, valueField, parentField,multiple])

    useEffect(()=>{
        if(isControlled){
            initData(props.value)
        }
    },[isControlled, initData, props.value])

    useEffect(()=>{
        if(isInited && !isControlled) return
        if(_.isNil(props.value)) return
        initData(props.value)
    },[props.value,initData, isInited, isControlled])

    useEffect(()=>{
        if(isInited && isControlled) return
        if(_.isNil(props.defaultValue)) return
        initData(props.defaultValue)
    },[props.defaultValue,initData,isInited, isControlled])

    const treeOptions = useMemo(()=>{
        if(_.isEmpty(options)) return []
        const rootValue = _.get(_.head(options),'rootValue')
        let firstLevel = _.filter(options, x => isNil(_.get(x,parentField)) || (_.get(x,parentField) === rootValue))
        return handleTreeOptions(options, firstLevel, valueField, parentField, textField)
    },[options, valueField, parentField, textField])

    const onSelectChange = useCallback((newValue) => {
        if(multiple){
            setStateValue(newValue)
        }else{
            const value = _.isArray(newValue) ? _.last(newValue) : newValue
            setStateValue(value)
        }
        if (props.onChange){
            if(multiple){
                props.onChange(_.map(newValue, x => _.last(x)))
            }else{
                props.onChange(_.last(newValue))
            }
            // props.onChange(_.last(newValue))
        }
    },[props, multiple])

    const show_error = useMemo(() => {
        return error && errorVisible
    }, [error, errorVisible])

    const handleBlur = useCallback(()=>{
        setUserActive(true)
        if(props.onBlur){
            props.onBlur()
        }
    },[props,setUserActive])

    return <Cascader onChange={onSelectChange} options={treeOptions}
                     // value={_.split(stateValue,',')}
                     value={stateValue}
                     // expandTrigger="hover"
                     // showCheckedStrategy={Cascader.SHOW_CHILD}
                     // changeOnSelect={true}
                     // placement={'bottomRight'}
                     suffixIcon={()=> null}
                     displayRender={(label, selectedOptions) => {
                         if(_.some(selectedOptions,x => !_.isNil(x))){
                             return _.get(_.last(selectedOptions),'label')
                         }
                         return multiple ? _.last(label) : _.map(label, x => convertOptions(x, options, textField, valueField))
                     }}
                     onDropdownVisibleChange={visible => {
                         if(!visible){
                             handleBlur()
                         }
                     }}
                     multiple={multiple}
                     placeholder={'请选择'}
                     className={clsx('cascade-select',{error: show_error})}
                     style={style}
                     onFocus={()=>{
                         if(props.onFocus){
                             props.onFocus()
                         }
                     }}
                     onBlur={()=>{
                         if(props.onBlur){
                             props.onBlur()
                         }
                     }}
                     disabled={disabled}
                     showSearch={{
                         filter: (inputValue, path) => _.some(path, option => _.includes(option.label, inputValue)),
                         limit: Infinity
                     }}
    />
}

export default CascadeSelect;
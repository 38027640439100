import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Loader} from "rootnet-ui";
import {FormInput, Select} from "rootnet-edit";
import _ from "lodash";
import {usePost} from "rootnet-biz/es/hooks";
import {errorTips, successTips} from "../../../../../../../utils/messagerTips";
import VersionCreateBranchResultDialog from "../versionCreateBranchResultDialog/VersionCreateBranchResultDialog";

function VersionSelectBranchDialog(props) {
    const {componentList, showDialogInfo, close, releaseBizId, refreshList} = props
    const {module, idList, type} = useMemo(()=>showDialogInfo,[showDialogInfo])
    const [branch, setBranch] = useState()
    const {data: branchList, doFetch: getBranchList} = usePost()
    const {doFetch: submitPost} = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [createResultList, setCreateResultList] = useState()

    const selectedItemList = useMemo(()=>{
       return _.filter(componentList, x => _.includes(idList, x?.programId))
    },[componentList, idList])

    const gitUrlList = useMemo(()=>{
        return _.map(selectedItemList,'gitUrl')
    },[selectedItemList])

    useEffect(()=>{
        if(_.isEmpty(gitUrlList)) return
        getBranchList('/devops/uscs/code/branch/getAllBranch',{gitUrlList})
    },[getBranchList, gitUrlList])

    const branchListOptions = useMemo(()=>{
        return _.map(branchList, x => ({
            text: x,
            value: x
        }))
    },[branchList])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        if(module === 'createBranch'){
            const addList = _.map(selectedItemList, x => ({
                productReleaseId: x?.productReleaseId,
                programId: x?.programId,
                gitUrl: x?.gitUrl,
            }))
            const postParams = {
                addList,
                baseBranchName: branch,
                releaseName: releaseBizId,
                type,
            }
            submitPost('/devops/uprods/product/program/createBranch',postParams).then(res => {
                setSubmitLoading(false)
                if(_.every(res, x => x?.status === 0)){
                    successTips('创建成功')
                    refreshList()
                    close()
                }else if(_.size(res) === 1){
                    errorTips(_.get(res,'0.reason'))
                }else{
                    setCreateResultList(res)
                }
            }).catch(err => {
                setSubmitLoading(false)
                errorTips(err._message)
            })
        }else if(module === 'relateBranch'){
            const selectedItem = _.get(selectedItemList,0)
            const postParams = {
                productReleaseId: selectedItem?.productReleaseId,
                programId: selectedItem?.programId,
                gitUrl: selectedItem?.gitUrl,
                baseBranchName: branch,
                type,
            }
            submitPost('/devops/uprods/product/program/relevancy',postParams).then(res => {
                setSubmitLoading(false)
                successTips('关联成功')
                refreshList()
                close()
            }).catch(err => {
                setSubmitLoading(false)
                errorTips(err._message)
            })
        }
    },[module, releaseBizId, selectedItemList, type, branch, close, refreshList, submitPost, submitLoading])

    return <Dialog header={'选择分支'} cancel={close} contentStyle={{padding: 16}}
                   confirmButtonDisabled={_.isNil(branch)} confirm={submit}>
        {
            submitLoading && <Loader fill/>
        }
        <FormInput label={module === 'createBranch'?'源分支（基线版本）':'分支名称'} value={branch} onChange={setBranch} componentWidth={280} component={Select} search
                   options={branchListOptions}/>
        {
            !_.isEmpty(createResultList) &&
            <VersionCreateBranchResultDialog list={createResultList} close={()=> {
                setCreateResultList(null)
                refreshList()
                close()
            }}/>
        }
    </Dialog>
}

export default VersionSelectBranchDialog;
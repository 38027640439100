import React, { useState, useMemo, useCallback, useContext, useRef, useEffect, useReducer } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tooltip } from 'antd'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { Box } from '../../../../common/commonComponent'
import ViewArea from '../../../../common/view/ViewArea'
import { ScheduleManagementContext } from '../../../../common/Context'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import { isNil } from '../../../../appraise/components/method'
import AdjustStateEdit from './adjustStateEdit'
import { CustomizeFormInput } from '../../../../common/customizeOptions/CustomizeOptions'
import RequirementDetailDialog from '../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import { Icon } from '../../../../../components'
import './index.scss'

const EDIT_URL = '/storyMatrix/updateAndAdd'

const PRIMARY_KEY = 'storyId'
const ENTER_DETAIL_FIELD = 'View_Story_Matrix.title'
const EDITABLE_FIELD = [
  'View_Story_Matrix.analyseStatus',
  'View_Story_Matrix.outlineStatus',
  'View_Story_Matrix.detailStatus',
  'View_Story_Matrix.testPlanStatus',
  'View_Story_Matrix.testCaseStatus',
  'View_Story_Matrix.codeReviewStatus',
  'View_Story_Matrix.devTestStatus',
  'View_Story_Matrix.checkAcceptStatus',
  'View_Story_Matrix.versionBuildStatus',
  'View_Story_Matrix.deploymentManual',
  'View_Story_Matrix.businessDescription',
  'View_Story_Matrix.memo',
]

const REPLACE_POST_PARAMS = {}

const SPECIAL_FIELD_CONVERT = {
  'View_Story_Matrix.analyseStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.outlineStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.detailStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.testPlanStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.testCaseStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.codeReviewStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.devTestStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.checkAcceptStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.versionBuildStatus': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.deploymentManual': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
  'View_Story_Matrix.businessDescription': (value, showValue, color) => {
    return <div style={{ color }}>{showValue}</div>
  },
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, editInfo, setEditInfo, currentTableValueRef, updateShowList, isPo, isPMM, setCurrentInfo } = props

  const { editId = null, editField = null } = editInfo || {}

  const customColumns = []

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => <div className={'flex center'}>{i + 1}</div>, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: !_.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], `${fieldItem.tableName}.${fieldItem.fieldId}`),
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[tableField])) {
      if (!isNil(v)) {
        const getColor = _.get(_.find(convertCollection[tableField], o => o.value === v), 'color')
        showValue = SPECIAL_FIELD_CONVERT[tableField](v, showValue, getColor)
      }
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
      </div>
    }
    if (!_.includes(EDITABLE_FIELD, tableField)) {
      return <Tooltip placement='bottom' overlayClassName='req-matrix-tooltip' title={_.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) ? <div dangerouslySetInnerHTML={{ __html: showValue }} /> : null}>
        <div className={cls('common-display-field', { 'text-cverflo-hiding': _.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) })}>
          {showValue || '-'}
        </div>
      </Tooltip>
    }
    if (o?.id === editId && editField === tableField) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType}
        convertCollection={convertCollection}
        defaultValue={handleValue}
        clear={fieldItem.requiredFlag === 'N'}
        allowClear={fieldItem.requiredFlag === 'N'}
        defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`}
        componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => currentTableValueRef.current = handleValue}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId], oldValue: handleValue })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (isPo || isPMM) {
      return <Tooltip placement='bottom' overlayClassName='req-matrix-tooltip' title={_.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) ? <div dangerouslySetInnerHTML={{ __html: showValue }} /> : null}>
        <div className={cls('common-edit-field flex center-y', { 'text-cverflo-hiding': _.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) })} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120, }} onClick={() => onEditFieldClick({ o, fieldItem })}>
          {showValue}
        </div>
      </Tooltip>
    }
    if (_.includes(EDITABLE_FIELD, tableField)) {
      return <Tooltip placement='bottom' overlayClassName='req-matrix-tooltip' title={_.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) ? <div dangerouslySetInnerHTML={{ __html: showValue }} /> : null}>
        <div className={cls('common-display-field', { 'text-cverflo-hiding': _.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) })}>
          {showValue || '-'}
        </div>
      </Tooltip>
    }
    return <Tooltip placement='bottom' overlayClassName='req-matrix-tooltip' title={_.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) ? <div dangerouslySetInnerHTML={{ __html: showValue }} /> : null}>
      <div className={cls('common-display-field', { 'text-cverflo-hiding': _.includes(['View_Story_Matrix.description', 'View_Story_Matrix.memo'], tableField) })}>
        {showValue || '-'}
      </div>
    </Tooltip>
  }
  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === ENTER_DETAIL_FIELD), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 4,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '1511'

export default function DevProjectReqMatrix(props) {
  const { projectId, isPo, isPMM, isShow, versionListRes } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [factor, setFactor] = useState()
  const [currentInfo, setCurrentInfo] = useState()
  const [selectedList, setSelectList] = useState([])
  const [focusId, setFocusId] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()

  // 表格编辑
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()
  const [isDisable, setIsDisable] = useState(false)

  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const versionList = useMemo(() => {
    return _.get(versionListRes, 'releaseInfoList') || []
  }, [versionListRes])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const appendObj = useMemo(() => {
    if (_.isEmpty(versionList)) return {
      'factorViewSql': `(View_Story_Matrix.LeafProjectId  = '${projectId}')`
    }
    const ids = _.map(versionList, o => `(ISNULL(View_Release_Story.regression,'N') != 'Y' and ReleaseInfo.ReleaseID ='${o.releaseID}')`)
    const newIds = _.map(versionList, o => `View_Release_Story.releaseIds not like '%${o.releaseID}%'`)
    return { 'factorViewSql': `((View_Story_Matrix.LeafProjectId  = '${projectId}'   and (View_Release_Story.releaseIds is null or ( ${_.join(newIds, ' and ')}  )))  or  ((View_Story_Matrix.LeafProjectId = '${projectId}' and ReleaseInfo.projectId = '${projectId}' and (${_.join(ids, ' or ')}) and (View_Story_Matrix.projectId is null or View_Story_Matrix.projectId = '${projectId}' ))  and  VIEW_leafStory.pid IS NULL AND  ISNULL(ReleaseInfo.versionInner, 'N' ) != 'Y'  and  ISNULL( View_Release_Story.regression, 'N' ) != 'Y'   )) AND VIEW_leafStory.pid  IS NULL` }
  }, [versionList, projectId])

  const replaceParams = useMemo(() => {
    if (_.isNil(projectId)) return
    return ({
      'View_Story_Matrix.LeafProjectId': isNil(projectId) ? 'empty' : projectId,
      // 'ReleaseInfo.projectId': isNil(projectId) ? 'empty' : projectId,
    })
  }, [projectId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

  const dateOptions = useGetDateOptions()

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    if (_.includes(['3', '4', '5'], editInfo.fieldHtmlType) && newValue === undefined) { setEditInfo(null); return }
    if (isDisable) return
    setIsDisable(true)
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    let postParams = {
      ids: [editInfo['editId']],
      projectId,
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      postParams['fieldNameList'] = [editInfo['fieldId']]
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    editTable(EDIT_URL, postParams).then(() => {
      afterRefresh()
    }).catch((err) => {
      setIsDisable(false)
      Messager.show(err._message, { icon: 'error' });
    })
    function afterRefresh() {
      setIsDisable(false)
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable, isDisable, projectId])

  const { options, dataGridList } = useGetTreeList({ fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, updateShowList, isPo, isPMM }, })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
      ...appendObj
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '需求矩阵')
  }, [fieldList, currentViewId, factor, downloadPostProgress, appendObj])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        ((isPMM || isPo) && isShow) && <TextIconBtn text={'调整状态'} onClick={() => {
          if (_.isEmpty(selectedList)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setCurrentInfo({ mode: 'status' })
        }} />
      }
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList, selectedList, isPo, isPMM, isShow])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(dataGridList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, dataGridList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? dataGridList[currentIndex + 1].id : dataGridList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [dataGridList, currentIndex])

  useEffect(() => {
    if (_.isNil(focusId)) return
    _.forEach(dataGridList, o => {
      return o._rowClass = o?.id === focusId ? 'select_row' : ''
    })
    forceUpdate()
  }, [focusId, dataGridList])

  return (
    <div className={'dev-project-req-matrix fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={<div className='flex dev-project-req-matrix-text'>需求矩阵<Tooltip title='非inner版本下，回归标识为“否”的需求（若需求在多个非inner版本下，展示存在回归标识为“否”的需求）'><span><Icon name='bangzhu' style={{ marginLeft: 8, marginTop: 1, fontSize: 14, color: '#5477ff' }} /></span></Tooltip></div>} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} selection={selectedList} onSelectionChange={setSelectList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['status'], _.get(currentInfo, 'mode')) &&
        <AdjustStateEdit
          projectId={projectId}
          ids={_.map(selectedList, 'id')}
          close={() => setCurrentInfo(null)}
          refreshViewList={() => {
            refreshList()
            setSelectList()
          }} />
      }
      {
        _.includes(['detail', 'edit'], _.get(currentInfo, 'mode')) &&
        <RequirementDetailDialog
          refreshViewList={refreshList}
          close={() => setCurrentInfo(null)}
          currentInfo={{
            id: _.get(currentInfo, 'id')
          }}
          {...{ switchCurrentItem, showChildList: dataGridList, currentIndex, }}
        />
      }
    </div>
  )
}

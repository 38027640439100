import React, {useMemo} from 'react';
import './FilterCondition.scss'
import {Card} from 'rootnet-ui';
import { Input } from 'antd';
import Icon from "../../../../../components/Icon";

const { TextArea } = Input;

function FilterCondition(props) {
    const {fullScreen, setFullScreen, filterConditionText, setFilterConditionText} = props

    const extra = useMemo(()=>{
        return <Icon name={fullScreen === 'filterCondition'?'suoxiao':'fangda'} className={'full-screen-icon'} onClick={onClickFullScreenIcon}/>
        function onClickFullScreenIcon(){
            setFullScreen(x => x?null:'filterCondition')
        }
    },[fullScreen, setFullScreen])

    return <Card title={'业务功能过滤条件'} className={`filter-condition-wrap ${fullScreen === 'filterCondition' ?'full-screen':''}`}
                 contentClass={'flex-y filter-condition-card'} extra={extra}
                 style={{display: fullScreen && (fullScreen !== 'filterCondition') ? 'none' : ''}}>
        <TextArea value={filterConditionText} onChange={e => setFilterConditionText(e.target.value)}
                  autoSize={{ minRows: 6, maxRows: fullScreen === 'filterCondition' ? null : 6 }}
                  placeholder={'例如：table.field is not null'}/>
    </Card>
}

export default FilterCondition;
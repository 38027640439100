import React from 'react'
import _ from 'lodash'
import TinyEditor from '../../../../../../common/richTextEditor/TinyEditor'
import './index.scss'

export default function ReviewMinutesDetailInfo(props) {
  const { infoRichEditorRef, isDetail, editFormData } = props

  return (
    <div className='review-minutes-detail-info'>
      <div className="rich-text-detail-wrap flex" style={{ display: isDetail ? "block" : 'none' }}>
        <div className={'rich-text-detail'} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'sRecord')?.replace(/\n/g, '<br/>') }} />
      </div>
      <div className="rich-text-area" style={{ display: !isDetail ? "block" : 'none' }}>
        <TinyEditor ref={infoRichEditorRef} />
      </div>
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './CustomerServiceBaseInfo.scss'
import Icon from "../../../../components/Icon";
import { DatePicker, Display, Form, Select, FormInput, Input } from "rootnet-edit";
import _ from "lodash";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import convertOptions from "../../../common/ConvertOptions";
import { Image, Popover } from 'antd';
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import RichTextEditor from "../../../common/richTextEditor/TinyEditor";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import { uniqKeyFor } from "../../../../project_share/utils/utils";
import { strParams } from "../../../../utils/publicFun";
import { isNil } from "../../../appraise/components/method";
import { TextIconBtn } from "../../../common/TextIconBtn";
import DelMessage from "../../../../components/DelMessage";
import RequirementDetailDialog from "../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import IssueDetailDialog from "../../../issueMgt/components/issueDetailDialog/IssueDetailDialog";
import ViewQueryDialog from "../../../common/view/viewQueryDialog/ViewQueryDialog";
import { Messager } from 'rootnet-ui';
import FeedbackMessageDialog from './feedbackMessageDialog';
import AcceptanceResultDialog from './acceptanceResultDialog';
import { useFuncCode } from '../../../common/commonMethod';

const HFormInput = props => <FormInput horizontal labelWidth={130} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=CSissuekind',
    '/common/globalconst?globalConst=PRIORITYLIST',
    '/common/globalconst?globalConst=Scene',
    '/common/globalconst?globalConst=ISSUESTATUS',
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor',
    '/UserSetting/getUniversalInterfaces?code=t_custId&codeName=t_custName&tableName=bug_tcust',
]

const OPTIONS_URLS = [
    '/customerserviceinfo/selectiveCustomerReleaseRec',
    '/story/getbugProject',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/common/globalconst?globalConst=ISSUESTYPE',
]

const YNOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]


function CustomerServiceBaseInfo(props) {
    const { formError, setFormError, editFormData, setEditFormData, isDetail, allUserRes, descRef, formData, submit, updateDynamic, refreshDetail } = props
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [customerInfoExpand, setCustomerInfoExpand] = useState(true)
    const [analysisExpand, setAnalysisExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const [imageVisible, setImageVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [showRelationDialog, setShowRelationDialog] = useState()
    const { data: issueVersionInfoList, doFetch: getIssueVersionInfoList } = useGet()
    const { data: reqVersionInfoList, doFetch: getReqVersionInfoList } = useGet()
    const { doFetch: getContact } = useGet()
    const [delParams, setDelParams] = useState()
    const [contactOptions, setContactOptions] = useState([])
    const [showDetailDialog, setShowDetailDialog] = useState()
    const { data: relateIssueWorkflowInfo, doFetch: getRelateIssueWorkflowInfo } = useGet()
    const { data: relateReqWorkflowInfo, doFetch: getRelateReqWorkflowInfo } = useGet()
    const { data: custIdRes, doFetch: getCustId } = useGet()
    const [isEdit] = useFuncCode(['200101'])

    const customer = useMemo(() => _.get(editFormData, 'customer'), [editFormData])

    useEffect(() => {
        if (_.isNil(customer)) return
        getCustId('/story/getbugProjectName?id=' + customer)
    }, [customer, getCustId])

    const customerOptions = useMemo(() => {
        if (_.isEmpty(custIdRes)) return []
        if (_.isNil(customer)) return []
        const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
        return _.map(filterStatus, x => ({ text: x.customerName, value: x.customer, supportUser: x.supportUser }))
    }, [custIdRes, customer])

    const [issueKindOptions, priorityOptions, sceneOptions, versionStatusOptions, workFlowStatusOptions, customerAllOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    useEffect(() => {
        const issueId = _.get(editFormData, 'issueId')
        if (_.isNil(issueId)) return
        const params = {
            businessType: 'issue',
            businessId: issueId
        }
        getRelateIssueWorkflowInfo('/WorkflowBusiness/getWorkflowBusiness?' + strParams(params))
    }, [editFormData, getRelateIssueWorkflowInfo])

    const issueStatus = useMemo(() => {
        return _.get(relateIssueWorkflowInfo, 'factorId')
    }, [relateIssueWorkflowInfo])

    const issueWorkflowColor = useMemo(() => {
        return _.get(_.find(workFlowStatusOptions, x => x.value === issueStatus), 'color') || '#000'
    }, [workFlowStatusOptions, issueStatus])

    useEffect(() => {
        const reqId = _.get(editFormData, 'interiorReqNo')
        if (_.isNil(reqId)) return
        const params = {
            businessType: 'req',
            businessId: reqId
        }
        getRelateReqWorkflowInfo('/WorkflowBusiness/getWorkflowBusiness?' + strParams(params))
    }, [editFormData, getRelateReqWorkflowInfo])

    const reqStatus = useMemo(() => {
        return _.get(relateReqWorkflowInfo, 'factorId')
    }, [relateReqWorkflowInfo])

    const reqWorkflowColor = useMemo(() => {
        return _.get(_.find(workFlowStatusOptions, x => x.value === reqStatus), 'color') || '#000'
    }, [workFlowStatusOptions, reqStatus])

    const [allVersionOptions, custIdOptions, productTreeOptions, moduleOptionsRes, allProductOptions, issueTypeOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4, d5, d6] = optionsRes
        const productTreeOptions = _.map(_.groupBy(d3, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d4, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        return [
            _.map(d1, x => ({ text: x.releaseid, value: x.releaseid })),
            _.map(d2, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, supportUser: x.supportUser, tCustId: x.customer })),
            productTreeOptions,
            d5,
            _.map(d3, x => ({ value: x.productId, text: x.productName, tag: `${x.projectName}${x.projectId}` })),
            _.map(d6, x => ({ text: x.interiorId, value: x.interiorId })),
        ]
    }, [optionsRes])

    useEffect(() => {
        if (_.isNil(formData)) return
        getIssueVersionInfoList('/releaseinfo/detailMult?' + strParams({ releaseid: _.get(formData, 'versionIssueList') }))
    }, [getIssueVersionInfoList, formData])

    useEffect(() => {
        if (_.isNil(formData)) return
        getReqVersionInfoList('/releaseinfo/detailMult?' + strParams({ releaseid: _.get(formData, 'versionStoryList') }))
    }, [getReqVersionInfoList, formData])

    const reqVersionInfoPop = useMemo(() => {
        return <div className={'flex relation-version-pop'}>
            {
                _.map(reqVersionInfoList, x => {
                    return <div className={'req-info flex-y'} key={uniqKeyFor()}>
                        <div>{_.get(x, 'releasename')}</div>
                        <div>{convertOptions(_.get(x, 'state'), versionStatusOptions)}</div>
                        <div>版本日期：{dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(x, 'issuedate')))}</div>
                        <div>实际发布：{dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(x, 'realreleasedate')))}</div>
                    </div>
                })
            }
        </div>
    }, [versionStatusOptions, reqVersionInfoList])

    const issueVersionInfoPop = useMemo(() => {
        return <div className={'flex relation-version-pop'}>
            {
                _.map(issueVersionInfoList, x => {
                    return <div className={'req-info flex-y'} key={uniqKeyFor()}>
                        <div>{_.get(x, 'releasename')}</div>
                        <div>{convertOptions(_.get(x, 'state'), versionStatusOptions)}</div>
                        <div>版本日期：{dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(x, 'issuedate')))}</div>
                        <div>实际发布：{dateFormat('YYYY-MM-DD')(toDate.str14ToDate(_.get(x, 'realreleasedate')))}</div>
                    </div>
                })
            }
        </div>
    }, [versionStatusOptions, issueVersionInfoList])

    const changeForm = useCallback((newObj, bind) => {
        setEditFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'authorizationStartDate') {
                if (!isNil(cloneObj['deadline']) && cloneObj[bind] > cloneObj['deadline']) {
                    cloneObj[bind] = null
                    Messager.show('不能大于API授权到期日', { icon: 'error' })
                }
            }
            if (bind === 'deadline') {
                if (!isNil(cloneObj['authorizationStartDate']) && cloneObj[bind] < cloneObj['authorizationStartDate']) {
                    cloneObj[bind] = null
                    Messager.show('不能小于API授权开始日', { icon: 'error' })
                }
            }
            if (bind === 'devPrincipal') {
                if (_.isNil(cloneObj[bind])) cloneObj[bind] = ''
            }
            if (bind === 'apiAuthFlag') {
                if (cloneObj[bind] === 'N') {
                    cloneObj['conNumber'] = null
                    cloneObj['deadline'] = null
                    cloneObj['authorizationStartDate'] = null
                    cloneObj['customerDepart'] = null
                }
            }
            if (bind === 'productType') {
                cloneObj['subSysId'] = null
            }
            if (bind === 'issueKind') {
                if (newObj['issueKind'] !== '12') {
                    cloneObj['version'] = null
                    cloneObj['applyversion'] = null
                }
            }
            if (bind === 'customer') {
                cloneObj['linker'] = null
                cloneObj['custContactInfo'] = null
                getCustId('/story/getbugProjectName?id=' + _.get(newObj, 'customer')).then(custIdRes => {
                    const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
                    const customerName = _.get(filterStatus, '0.customer')
                    changeForm({ customerName }, 'customerName')
                })
            }
            if (bind === 'linker') {
                if (_.isNil(cloneObj['linker'])) {
                    cloneObj['custContactInfo'] = null
                    cloneObj['linkerEmail'] = null
                } else {
                    cloneObj['custContactInfo'] = convertOptions(cloneObj['linker'], contactOptions, 'phone')
                    cloneObj['linkerEmail'] = convertOptions(cloneObj['linker'], contactOptions, 'email')
                }
            }
            return cloneObj
        })
    }, [setEditFormData, contactOptions, getCustId])

    const versionDisabled = useMemo(() => {
        return _.get(editFormData, 'issueKind') !== '12'
    }, [editFormData])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(_.get(editFormData, 'productType'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === _.get(editFormData, 'productType'))
        return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
    }, [editFormData, moduleOptionsRes])

    const previewImage = useCallback(e => {
        if (e.target.tagName === 'IMG') {
            setImageSrc(e.target.src)
            setImageVisible(true)
        }
    }, [])

    const onRelateIssue = useCallback((item) => {
        if (isDetail) {
            submit({
                ...editFormData,
                issueId: item?.id || null,
                issueBizId: item?.bizId || null,
            })
        } else {
            setEditFormData(old => {
                return {
                    ...old,
                    issueId: item?.id || null,
                    issueBizId: item?.bizId || null,
                }
            })
        }
    }, [setEditFormData, isDetail, editFormData, submit])

    const onRelateReq = useCallback((item) => {
        if (isDetail) {
            submit({
                ...editFormData,
                interiorReqNo: item?.id || null,
                reqBizId: item?.bizId || null,
            })
        } else {
            setEditFormData(old => {
                return {
                    ...old,
                    interiorReqNo: item?.id || null,
                    reqBizId: item?.bizId || null,
                }
            })
        }
    }, [setEditFormData, isDetail, editFormData, submit])

    const customerName = useMemo(() => {
        return _.get(editFormData, 'customerName')
    }, [editFormData])

    const refreshContact = useCallback(() => {
        if (isNil(customerName)) return
        getContact('/custlinker/listByCustId?tcustId=' + customerName).then(res => {
            if (_.isEmpty(res)) return setContactOptions([])
            const options = _.map(res, x => ({
                text: _.get(x, 'linker'),
                value: _.toString(_.get(x, 'id')),
                phone: _.get(x, 'phone') || _.get(x, 'landLine'),
                email: _.get(x, 'email'),
            }))
            setContactOptions(_.orderBy(options, 'linker', 'asc'))
        })
    }, [customerName, getContact])

    useEffect(() => {
        refreshContact()
    }, [refreshContact])

    const linkerRequired = useMemo(() => {
        return _.get(editFormData, 'customer') !== 'NBGJ'
    }, [editFormData])

    useEffect(() => {
        if (linkerRequired) {
            if (isNil(_.get(editFormData, 'linker'))) {
                setFormError(x => _.assign({}, x, { 'linker': '必填项！' }))
            }
        } else {
            setFormError(x => _.assign({}, x, { 'linker': null }))
        }
    }, [editFormData, linkerRequired, setFormError])

    useEffect(() => {
        const apiAuthFlag = _.get(editFormData, 'apiAuthFlag') === 'Y'
        const conNumber = _.get(editFormData, 'conNumber')
        const deadline = _.get(editFormData, 'deadline')
        const authorizationStartDate = _.get(editFormData, 'authorizationStartDate')
        const customerDepart = _.get(editFormData, 'customerDepart')
        setFormError(x => _.assign({}, x,
            { conNumber: null, deadline: null, authorizationStartDate: null, customerDepart: null },
            apiAuthFlag && {
                conNumber: isNil(conNumber) ? '必填项！' : null,
                deadline: isNil(deadline) ? '必填项！' : null,
                authorizationStartDate: isNil(authorizationStartDate) ? '必填项！' : null,
                customerDepart: isNil(customerDepart) ? '必填项！' : null,
            }))
    }, [editFormData, setFormError])

    return <div className={'customer-service-base-info flex-y'}>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
                基本信息
            </div>
            <div className="area-content-wrap" style={{ height: baseInfoExpand ? '' : 0 }}>
                <Form value={editFormData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                    <HFormInput label={'发生日期'} bind={'occurTime'} component={isDetail ? Display : DatePicker} required
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}
                    />
                    <HFormInput label={'问题性质'} bind={'issueKind'} clear component={isDetail ? Display : Select} options={issueKindOptions} convert={v => convertOptions(v, issueKindOptions)} />
                    <HFormInput label={'优先级'} bind={'priority'} required component={isDetail ? Display : Select} options={priorityOptions} convert={v => convertOptions(v, priorityOptions)} />
                    <HFormInput label={'分类标记'} bind={'issuetype'} required component={isDetail ? Display : Select} options={issueTypeOptions} convert={v => convertOptions(v, issueTypeOptions)} />
                    <HFormInput search label={'基础版本'} bind={'version'} clear component={isDetail ? Display : Select} options={allVersionOptions} convert={v => convertOptions(v, allVersionOptions)} disabled={versionDisabled} />
                    <HFormInput search label={'申请版本'} bind={'applyversion'} clear component={isDetail ? Display : Select} options={allVersionOptions} convert={v => convertOptions(v, allVersionOptions)} disabled={versionDisabled} />
                    <HFormInput label={'负责人'} bind={'principal'} component={isDetail ? Display : UserSelect} required
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput
                        multiple
                        label={'产研负责人'}
                        bind={'devPrincipal'}
                        className={isDetail ? 'groups-people-detail' : 'groups-people-edit'}
                        component={isDetail ? Display : UserSelect}
                        bindOutConvert={v => _.join(v, ',')}
                        convert={userStr => {
                            const userNameArr = _.map(_.split(userStr, ','), v => convertOptions(v, allUserRes, 'userName', 'userAccount'))
                            return _.join(userNameArr, '，')
                        }}
                    />
                    <HFormInput label={'分析人'} bind={'analyst'} component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'预期解决日期'} bind={'promiseDate'} component={isDetail ? Display : DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}
                    />
                    <HFormInput label={'创建人'} bind={'createUser'} component={Display}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                        }} />
                </Form>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: customerInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCustomerInfoExpand(v => !v)} />
                客户信息
            </div>
            <div className="area-content-wrap" style={{ height: customerInfoExpand ? '' : 0 }}>
                <Form value={editFormData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                    <HFormInput label={'客户系统'} bind={'customer'} required search component={isDetail ? Display : Select}
                        options={custIdOptions} convert={v => convertOptions(v, custIdOptions)} />
                    <HFormInput label={'客户名称'} bind={'customerName'} required search component={isDetail ? Display : Select}
                        options={customerOptions} convert={v => convertOptions(v, customerAllOptions)} />
                    <HFormInput label={'客户联系人'} bind={'linker'} required={linkerRequired} clear component={isDetail ? Display : Select}
                        options={contactOptions} convert={v => convertOptions(v, contactOptions)} search />
                    <HFormInput label={'联系方式'} bind={'custContactInfo'} component={isDetail ? Display : Input} />
                    <HFormInput label={'产品名称'} bind={'productType'} required tree component={isDetail ? Display : Select} options={productTreeOptions} convert={v => convertOptions(v, allProductOptions)} />
                    <HFormInput label={'子产品'} bind={'subSysId'} clear component={isDetail ? Display : Select} options={moduleOptions} convert={v => convertOptions(v, moduleOptions)} />
                    <HFormInput label={'客户问题编号'} bind={'clientIssueNo'} component={isDetail ? Display : Input} />
                    <HFormInput label={'问题提出场景'} bind={'scene'} clear component={isDetail ? Display : Select} options={sceneOptions} convert={v => convertOptions(v, sceneOptions)} />
                    <HFormInput label={'竞争对手'} bind={'competitor'} component={isDetail ? Display : Input} />
                    <HFormInput label={'随访人员'} bind={'userList'} component={isDetail ? Display : UserSelect} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'是否涉及API授权'} bind={'apiAuthFlag'} component={isDetail ? Display : Select} options={YNOptions} required convert={v => convertOptions(v, YNOptions)} />
                    {
                        _.get(editFormData, 'apiAuthFlag') === 'Y' &&
                        <>
                            <HFormInput label={'API授权连接数'} bind={'conNumber'} required type='number' digit={0} component={isDetail ? Display : Input} />
                            <HFormInput label={'API授权开始日'} bind={'authorizationStartDate'} component={isDetail ? Display : DatePicker} required bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                                convert={v => {
                                    if (_.isNil(v)) return '-'
                                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD', v) || '-'
                                }} />
                            <HFormInput label={'API授权到期日'} bind={'deadline'} component={isDetail ? Display : DatePicker} required bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                                convert={v => {
                                    if (_.isNil(v)) return '-'
                                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD', v) || '-'
                                }} />
                            <HFormInput required label='客户提出部门' bind='customerDepart' component={isDetail ? Display : Input} />
                        </>
                    }
                    <div />
                    <HFormInput label='客户平台编号' bind='csarecordID' component={Display} className='customer-question-number-form' convert={v => {
                        if (isNil(v)) return null
                        return <div className='customer-question-number flex'>
                            <div className='number'>{v}</div>
                            <div className='operation-all flex'>
                                <div className='feedback-message' onClick={() => {
                                    setShowDetailDialog({
                                        module: 'feedbackMessage',
                                        id: _.get(editFormData, 'id')
                                    })
                                }}>反馈留言</div>
                                <div className='acceptance-result' onClick={() => {
                                    setShowDetailDialog({
                                        module: 'acceptanceResult',
                                        id: _.get(editFormData, 'id')
                                    })
                                }}>受理结果填写</div>
                            </div>
                        </div>
                    }} />
                </Form>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: analysisExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setAnalysisExpand(v => !v)} />
                分析信息
            </div>
            <div className="area-content-wrap" style={{ height: analysisExpand ? '' : 0 }}>
                {
                    _.isNil(_.get(editFormData, 'issueId')) &&
                    <TextIconBtn icon={'sousuo1'} text={'关联ISSUE'} className={'add-relation-icon'} onClick={() => setShowRelationDialog('issue')} />
                }
                {
                    !_.isNil(_.get(editFormData, 'issueId')) &&
                    <div className={'relation-info flex center-y'}>
                        <div className={'type-tag flex center issue'}>ISSUE</div>
                        <div className="relation-id-wrap">
                            <div className="relation-id" onClick={() => {
                                setShowDetailDialog({
                                    module: 'issue',
                                    id: _.get(editFormData, 'issueId')
                                })
                            }}>
                                {_.get(editFormData, 'issueBizId')}
                            </div>
                        </div>
                        <div className="relation-icon">
                            <Icon name={'bianji2'} className={'handle-icon edit-icon'} onClick={() => setShowRelationDialog('issue')} />
                            <Icon name={'shanchu'} className={'handle-icon del-icon'} onClick={() => {
                                if (isDetail) {
                                    setDelParams({ ...editFormData, issueId: null })
                                } else {
                                    setEditFormData(x => ({ ...x, issueId: null }))
                                }
                            }} />
                        </div>
                        {
                            isDetail &&
                            <div className="relation-status" style={{ color: issueWorkflowColor, border: `1px solid ${issueWorkflowColor}` }}>
                                {convertOptions(issueStatus, workFlowStatusOptions)}
                            </div>
                        }
                        {
                            isDetail &&
                            <Popover content={issueVersionInfoPop} color={'#000000bb'} overlayClassName={'relation-version-pop-wrap'}>
                                <div className="relation-version">{_.get(editFormData, 'versionIssueList')}</div>
                            </Popover>
                        }
                    </div>
                }
                <div />
                {
                    _.isNil(_.get(editFormData, 'interiorReqNo')) &&
                    <TextIconBtn icon={'sousuo1'} text={'关联需求'} className={'add-relation-icon'} onClick={() => setShowRelationDialog('req')} />
                }
                {
                    !_.isNil(_.get(editFormData, 'interiorReqNo')) &&
                    <div className={'relation-info flex center-y'}>
                        <div className={'type-tag flex center req'}>REQ</div>
                        <div className="relation-id-wrap">
                            <div className="relation-id" onClick={() => {
                                setShowDetailDialog({
                                    module: 'req',
                                    id: _.get(editFormData, 'interiorReqNo')
                                })
                            }}>
                                {_.get(editFormData, 'reqBizId')}
                            </div>
                        </div>
                        <div className="relation-icon">
                            <Icon name={'bianji2'} className={'handle-icon edit-icon'} onClick={() => setShowRelationDialog('req')} />
                            <Icon name={'shanchu'} className={'handle-icon del-icon'} onClick={() => {
                                if (isDetail) {
                                    setDelParams(({ ...editFormData, interiorReqNo: null }))
                                } else {
                                    setEditFormData(x => ({ ...x, interiorReqNo: null }))
                                }
                            }} />
                        </div>
                        {
                            isDetail &&
                            <div className="relation-status" style={{ color: reqWorkflowColor, border: `1px solid ${reqWorkflowColor}` }}>
                                {convertOptions(reqStatus, workFlowStatusOptions)}
                            </div>
                        }
                        {
                            isDetail &&
                            <Popover content={reqVersionInfoPop} color={'#000000bb'} overlayClassName={'relation-version-pop-wrap'}>
                                <div className="relation-version">{_.get(editFormData, 'versionStoryList')}</div>
                            </Popover>
                        }
                    </div>
                }
            </div>
        </div>
        <div className="area-wrap desc-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
                问题详细描述
                {
                    !isDetail &&
                    <span style={{ color: 'red' }}>（必填）</span>
                }
            </div>
            <div className="rich-text-detail-wrap flex" style={{ display: isDetail && descExpand ? "block" : 'none' }}>
                <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'reqDetail') }} />
            </div>
            <div className="rich-text-area" style={{ display: !isDetail && descExpand ? "block" : 'none' }}>
                <RichTextEditor ref={descRef} />
            </div>
        </div>
        <Image
            style={{ display: 'none' }}
            src={imageSrc}
            preview={{
                visible: imageVisible,
                src: imageSrc,
                onVisibleChange: visible => {
                    setImageVisible(visible)
                }
            }}
        />
        {
            showRelationDialog === 'issue' &&
            <ViewQueryDialog close={() => setShowRelationDialog(null)} initValue={_.get(editFormData, 'issueId')}
                outerSetItem={onRelateIssue} funcCode={'0005'} bizField={'ProductionIssue.IssueId'} bizName={'issue'}
                initItemValue={_.get(editFormData, 'issueId') ? { id: _.get(editFormData, 'issueId'), bizId: _.get(editFormData, 'issueBizId') } : null}
            />
        }
        {
            showRelationDialog === 'req' &&
            <ViewQueryDialog close={() => setShowRelationDialog(null)} initValue={_.get(editFormData, 'interiorReqNo')}
                outerSetItem={onRelateReq} funcCode={'3600'} bizField={'story.storyId'} bizName={'需求'}
                initItemValue={_.get(editFormData, 'interiorReqNo') ? { id: _.get(editFormData, 'interiorReqNo'), bizId: _.get(editFormData, 'reqBizId') } : null}
            />
        }
        {
            delParams &&
            <DelMessage close={() => setDelParams(null)} refresh={() => submit(delParams)}>
                确认解除关联吗？
            </DelMessage>
        }
        {
            _.get(showDetailDialog, 'module') === 'req' &&
            <RequirementDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'issue' &&
            <IssueDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {//反馈留言
            _.get(showDetailDialog, 'module') === 'feedbackMessage' &&
            <FeedbackMessageDialog
                id={_.get(showDetailDialog, 'id')}
                close={() => setShowDetailDialog(null)}
                {...{ allUserRes, isEdit }}
            />
        }
        {//受理结果填写
            _.get(showDetailDialog, 'module') === 'acceptanceResult' &&
            <AcceptanceResultDialog
                id={_.get(showDetailDialog, 'id')}
                defaultData={_.clone(formData)}
                close={() => setShowDetailDialog(null)}
                updateDynamic={updateDynamic}
                isEdit={isEdit}
                refreshDetail={refreshDetail}
            />
        }
    </div>
}

export default CustomerServiceBaseInfo;
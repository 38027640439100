import React, {useCallback, useEffect, useMemo, useState} from 'react';
import DescItem from "../common/DescItem";
import {pathSearchFor, strParams} from "../../../utils/publicFun";
import {TextIconBtn} from "../../../project_share/components";
import {Card, DataGrid} from 'rootnet-ui'
import {Box} from "../../common/commonComponent";
import OperationList from "../../../project_share/components/OperationList";
import {Display, Form, FormInput, Grid} from "rootnet-edit";
import _ from "lodash";
import {useGet} from "../../../utils/hook";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import useToDetail from "../../commonV2/hooks/useToDetail";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import SubmoduleDetailDialog from "./SubmoduleDetailDialog";
import DetailsGoBackWithTextClick from "../../common/DetailsGoBackWithTextClick";

const getColumns = (props) => {
    const {stateOptions, collocationOptions, releaseOptions, offerOptions,setSubmoduleDetail} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '子模块', bind: 'prodSubsysSonName',sortable: true,width: 100, tooltip: true},
        {header: '负责人', bind: 'prodSubsysSonManager',sortable: true,width: 100},
        {header: '配置方式', bind: 'prodSubsysSonCollocation',sortable: true,width: 100, convert:v => convertOptions(v,collocationOptions)},
        {header: '发布方式', bind: 'prodSubsysSonRelease',sortable: true,width: 100, convert:v => convertOptions(v,releaseOptions)},
        {header: '报价方式', bind: 'prodSubsysSonOffer',sortable: true,width: 100, convert:v => convertOptions(v,offerOptions)},
        {header: '子模块状态', bind: 'prodSubsysSonState',sortable: true,width: 100, convert:v => convertOptions(v,stateOptions)},
        {header: '被引用次数', bind: 'prodSubsysSonUsed',sortable: true,align: 'right',width: 100},
        {header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation}
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick: ()=>setSubmoduleDetail(o)
            },
        ];
        return <OperationList options={operateOption}/>;
    }
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    virtualized: true,
    resizable: true,
    fixedLeft: 2,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
});

const OPTION_URLS = [
    '/common/globalconst?globalConst=state',
    '/common/globalconst?globalConst=collocationType',
    '/common/globalconst?globalConst=releaseType',
    '/common/globalconst?globalConst=offerType',
]

function ModuleDetail(props) {
    const {history} = props;
    const searchParams = pathSearchFor(history.location.search);
    const {productId,prodSubsysId,systemId} = searchParams
    const [isCard, setIsCard] = useState(true)
    const {data: info, doFetch: getInfo} = useGet()

    const goBackToList = useToDetail('/moduleMgt')
    const goToProjectDetail = useToDetail('/productMgt/detail')

    const {data: optionsRes} = useGet(OPTION_URLS)
    const [submoduleDetail, setSubmoduleDetail] = useState({})

    const [stateOptions, collocationOptions, releaseOptions, offerOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        return _.map(optionsRes, x => convertGlobalConstOptions(x))
    },[optionsRes])

    const goBackParams = useMemo(()=>{
        const backParams = _.omit(searchParams, ['productId','prodSubsysId','prodSubsysName'])
        return _.mapKeys(backParams, (v ,k) => k.replace('item_',''))
    },[searchParams])

    const goBack = useCallback(()=>{
        const {systemId,systemName,productId,productName} = searchParams
        if(productName) return goToProjectDetail({systemId,systemName,productId,productName})
        goBackToList(goBackParams)
    },[goBackToList,goToProjectDetail,goBackParams,searchParams])

    useEffect(()=>{
        if (_.some([productId,prodSubsysId],_.isNil)) return
        const getParams = {productId,prodSubsysId,isSystem: _.isNil(systemId)?1:2}
        getInfo('/prodSubsys/detail?'+strParams(getParams))
    },[getInfo,productId,prodSubsysId,systemId])

    const {prodSubsysDetailDos: list = []} = useMemo(()=>info || {},[info])

    const option = useMemo(()=>{
        return getTableOption(getColumns({stateOptions, collocationOptions, releaseOptions, offerOptions,setSubmoduleDetail}))
    },[stateOptions, collocationOptions, releaseOptions, offerOptions,setSubmoduleDetail])

    const descTitle = useMemo(()=>{
        return <div className='desc-left flex center-y'>
            <div className="desc-title">{_.get(info,'prodSubsysName')}</div>
            <div className="desc-directions">{_.get(info,'directions')}</div>
        </div>
    },[info])

    const descExtra = useMemo(()=>{
        return <div className='desc-extra flex'>
            <TextIconBtn text='编辑' icon='bianji'/>
        </div>
    },[])

    const detailExtra = useMemo(()=>{
        return <TextIconBtn text={`切换${isCard?'表格':'卡片'}`} icon={isCard?'biaoge':'tubiao'} onClick={()=>setIsCard(x => !x)}/>
    },[isCard])

    const breadcrumb = useMemo(()=>{
        const {systemId,systemName,productId,productName,prodSubsysName} = searchParams
        return <div className="breadcrumb flex">
            {
                systemId && <div className='breadcrumb-item'>
                    <span className='breadcrumb-item-text'
                          onClick={()=>{history.push({pathname:'/appSystemMgt/detail',search:strParams({systemId,systemName})})}}>
                        {systemName}
                    </span>/
                </div>
            }
            {
                productName && <div className='breadcrumb-item'>
                    <span className='breadcrumb-item-text'
                          onClick={()=>{history.push({pathname:'/productMgt/detail',search:strParams({systemId,systemName,productId,productName})})}}>
                        {productName}
                    </span>/
                </div>
            }
            {prodSubsysName}
        </div>
    },[searchParams,history])

    const sortList = useMemo(()=>{
        return list.sort(sortCollocation)
    },[list])

    return <div className='module-detail system-mgt-detail-common flex-y fill'>
        <div className="back-area flex center-y">
            <DetailsGoBackWithTextClick onClick={goBack} />
            <div className="breadcrumb-wrap">
                {breadcrumb}
            </div>
        </div>
        <Card title={descTitle} className='desc-card' contentClass='desc-content flex' extra={descExtra}>
            <DescItem header='模块ID' text={_.get(info,'prodSubsysId')}/>
            <DescItem header='模块状态' text={convertOptions(_.get(info,'prodSubsysState'),stateOptions)}/>
            <DescItem header='所属产品' text={_.get(info,'belonging')}/>
            <DescItem header='负责人' text={_.get(info,'prodSubsysManager')}/>
            <DescItem header='配置方式' text={convertOptions(_.get(info,'collocation'),collocationOptions)}/>
            <DescItem header='发布方式' text={convertOptions(_.get(info,'release'),releaseOptions)}/>
            <DescItem header='报价方式' text={convertOptions(_.get(info,'offer'),offerOptions)}/>
        </Card>
        <Box title='子模块构成' className='flex-y x-card-singlegrid' data={sortList} extra={detailExtra} style={{flex: isCard?0:1}}>
            {
                !isCard &&
                <DataGrid data={sortList} option={option}/>
            }
        </Box>
        {
            isCard && <div className='card-group flex'>
                {
                    _.map(sortList, (item, i) => {
                        return <CardItem key={i} itemData={item} setSubmoduleDetail={setSubmoduleDetail}>
                            <HFormInput bind='prodSubsysSonManager' label='负责人'/>
                            <HFormInput bind='prodSubsysSonState' label='子模块状态' convert={v => convertOptions(v,stateOptions)}/>
                            <HFormInput bind='prodSubsysSonCollocation' label='配置方式' convert={v => convertOptions(v,collocationOptions)}/>
                            <HFormInput bind='prodSubsysSonRelease' label='发布方式' convert={v => convertOptions(v,releaseOptions)}/>
                            <HFormInput bind='prodSubsysSonOffer' label='报价方式' convert={v => convertOptions(v,offerOptions)}/>
                            <HFormInput bind='prodSubsysSonUsed' label='被引用次数'/>
                        </CardItem>
                    }).concat(new Array(4).fill(0).map(() => (<div key={uniqKeyFor()} className='empty-item'/> )))
                }
            </div>
        }
        {
            !_.isEmpty(submoduleDetail) && <SubmoduleDetailDialog data={submoduleDetail} close={()=>setSubmoduleDetail(null)}>
                <HFormInput bind='prodSubsysSonId' label='子模块ID'/>
                <HFormInput bind='prodSubsysSonState' label='子模块状态' convert={v => convertOptions(v,stateOptions)}/>
                <HFormInput bind='moduleIdParentName' label='所属模块'/>
                <HFormInput bind='prodSubsysSonManager' label='负责人'/>
                <HFormInput bind='prodSubsysSonCollocation' label='配置方式' convert={v => convertOptions(v,collocationOptions)}/>
                <HFormInput bind='prodSubsysSonRelease' label='发布方式' convert={v => convertOptions(v,releaseOptions)}/>
                <HFormInput bind='prodSubsysSonOffer' label='报价方式' convert={v => convertOptions(v,offerOptions)}/>
            </SubmoduleDetailDialog>
        }
    </div>
}


function sortCollocation(a,b){
    return a.prodSubsysSonCollocation - b.prodSubsysSonCollocation
}

const HFormInput = (props) => <FormInput horizontal component={Display} className='h-form-input' componentClass='h-form-input-component' labelWidth={90} componentWidth={100} {...props}/>

function CardItem(props){
    const {itemData, children, setSubmoduleDetail} = props

    const tagList = []
    if(itemData.prodSubsysSonCollocation === '01') tagList.push('基础')
    if(itemData.prodSubsysSonRelease === '01') tagList.push('独立报价')

    return <div className='card-item module-card-item'>
        <div className="card-header flex center-y">
            <div className="card-title">子模块-{itemData.prodSubsysSonName}</div>
            <div className="tags-group flex">
                {
                    _.map(tagList, (item,i) => {
                        return <div className='tag' key={i}>
                            {item}
                        </div>
                    })
                }
            </div>
        </div>
        <div className="card-detail">
            <Form value={itemData}>
                <Grid cols={2}>
                    {children}
                </Grid>
            </Form>
        </div>
        <div className="bottom-text flex center">
            <div className='bottom-text-item' onClick={()=>setSubmoduleDetail(itemData)}>子模块详情</div>
            <div className='bottom-text-item'>编辑</div>
        </div>
    </div>
}

export default ModuleDetail;
import _ from 'lodash'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { isNil } from 'rootnet-core/format'

export function estimateInitData(data, status) {
  const filterData = _.filter(data, o => {
    if (o.isTrue === '0') {
      if (isNil(status)) return true
      else if (status === '01' && (isNil(o.status) || o.status === status) && (isNil(o.projectStatus) || o.projectStatus === status)) return true
      else if (status === '02' && (o.status === status || o.projectStatus === status)) return true
      return false
    }
    return false
  })
  return _.concat(..._.map(_.groupBy(filterData, 'departmentId'), (array, keys) => {
    return _.concat(..._.map(_.groupBy(array, 'projectId'), (arr, key) => {
      return _.concat(..._.map(_.groupBy(arr, 'projectType'), (val, k) => {
        return _.assign(..._.concat(..._.map(_.groupBy(val, 'evaluationDate'), (v, j) => {
          const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(j)), '-')
          const month = `${getDate[0]}-${getDate[1]}`
          return _.map(v, o => {
            return _.assign(
              {},
              _.omit(o, ['evaluationDate']),
              {
                [`workloadOccupied,${month}`]: o.workloadOccupied,
                [`manpowerPipelineId${month}`]: o.manpowerPipelineId,
                [`id${month}`]: o.id,
                evaluationDate: month
              }
            )
          })
        })), {
          workloadOccupiedSummary: summaryBind(val, 'workloadOccupied'),
        })
      }))
    }))
  }))
}

export function departmentInitData(data) {
  const filterList = _.filter(data, o => ((o.status === '01' || isNil(o.status)) && (o.projectStatus === '01' || isNil(o.projectStatus))))
  const temporalInterval = _.map(_.groupBy(filterList, 'evaluationDate'), arr => {
    const date = _.get(_.head(arr), 'evaluationDate')
    const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(date)), '-')
    const month = _.size(_.split(date, '-')) >= 2 ? date : `${getDate[0]}-${getDate[1]}`
    return month
  })
  const listData = _.concat(..._.map(_.groupBy(filterList, 'departmentId'), (array, keys) => {
    const filterData = _.concat(..._.map(_.groupBy(array, 'evaluationDate'), arr => _.head(arr)))
    return _.assign(..._.concat(..._.map(_.groupBy(array, 'evaluationDate'), (v, j) => {
      const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(j)), '-')
      const month = _.size(_.split(j, '-')) >= 2 ? j : `${getDate[0]}-${getDate[1]}`
      const item = _.head(v)
      return _.assign(
        {},
        _.omit(item, ['evaluationDate']),
        {
          [`id${month}`]: item.id,
          [`manpowerPipelineId${month}`]: item.manpowerPipelineId,
          [`departmentManpowerNum${month}`]: _.get(item, 'departmentManpower'),
          [`workloadOccupiedNum${month}`]: summaryBind(v, 'workloadOccupied'),
          [`departmentManpower${month}`]: _.get(item, 'departmentManpower'),
          [`workloadOccupied${month}`]: pageShow(summaryBind(v, 'workloadOccupied'), _.get(item, 'departmentManpower')),
          evaluationDate: month
        }
      )
    })), {
      departmentManpowerSummary: <span style={{ fontWeight: 'bold' }}>{summaryBind(filterData, 'departmentManpower')}</span>,
      workloadOccupiedSummary: pageShow(summaryBind(array, 'workloadOccupied'), summaryBind(filterData, 'departmentManpower'), true),
      departmentManpowerSummaryNum: summaryBind(filterData, 'departmentManpower'),
      workloadOccupiedSummaryNum: summaryBind(array, 'workloadOccupied'),
    })
  }))

  const newData = _.map(listData, o => _.assign({}, o))
  return getTreeSummary(departmentTreeData(newData), temporalInterval)

}

export function summaryInitData(data) {
  const listName = [
    { '占用评估汇总': 'workloadOccupiedTotal' },
    { '部门人力汇总': 'departmentManpowerTotal' },
    { '可用人力汇总（人月）': 'departmentManpowerUsedMonthTotal' },
    { '可用人力汇总（人日）': 'departmentManpowerUsedDayTotal' },
  ]
  return _.map(listName, (o) => {
    const listData = _.assign(..._.concat(..._.map(_.groupBy(data, 'evaluationMonth'), (list, keys) => {
      const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(keys)), '-')
      const month = `${getDate[0]}-${getDate[1]}`
      return {
        [`month${month}`]: _.includes(['departmentManpowerUsedMonthTotal', 'departmentManpowerUsedDayTotal'], _.head(_.values(o))) ? isNegative(_.round(summaryBind(list, _.head(_.values(o))), 2)) : summaryBind(list, _.head(_.values(o)))
      }
    })))

    const sum = summaryBind(data, _.head(_.values(o)))

    return _.assign({}, {
      name: _.head(_.keys(o)),
      total: <span style={{ fontWeight: 'bold', color: sum >= 0 ? '#13151A' : 'red' }}>{sum}</span>,
    }, listData)
  })
}

function isNegative(num) {
  if (_.toNumber(num) >= 0) return num
  return <span style={{ color: 'red' }}>{num}</span>
}

function summaryBind(list, bind, num = 0) {
  return (_.sum(_.concat(_.map(list, o => _.toNumber(o[bind]) * 100), [_.toNumber(num) * 100])) / 100)
}

function departmentTreeData(data) {
  if (_.isEmpty(data)) return []
  _.forEach(data, item => delete item.children)
  const map = {}
  _.forEach(data, item => map[item.departmentId] = item)
  const newList = []
  _.forEach(data, r => {
    let parents = map[r.departmentPId]
    if (parents) {
      ((parents.children) || (parents.children = [])).push(r)

    } else newList.push(r)
  })
  return newList
}

function getTreeSummary(data, temporalInterval) {
  _.forEach(data, item => {
    if (!_.isEmpty(item.children)) {
      const list = delayering(item.children)
      _.forEach(temporalInterval, v => {
        item[`departmentManpower${v}`] = summaryBind(list, `departmentManpowerNum${v}`, _.get(item, `departmentManpowerNum${v}`))
        item[`workloadOccupied${v}`] = pageShow(summaryBind(list, `workloadOccupiedNum${v}`, _.get(item, `workloadOccupiedNum${v}`)), summaryBind(list, `departmentManpowerNum${v}`, _.get(item, `departmentManpowerNum${v}`)), false, '#333333')
      })
      item.flag = true
      item.departmentManpowerSummary = <span style={{ fontWeight: 'bold' }}>{summaryBind(list, 'departmentManpowerSummaryNum', item.departmentManpowerSummaryNum)}</span>
      item.workloadOccupiedSummary = pageShow(summaryBind(list, 'workloadOccupiedSummaryNum', item.workloadOccupiedSummaryNum), summaryBind(list, 'departmentManpowerSummaryNum', item.departmentManpowerSummaryNum), true)
      getTreeSummary(item.children, temporalInterval)
    }
  })
  return data
}

function pageShow(num, contrastNum, isBold, color = '#5477ff') {
  if (num === 0) return num
  return <span style={_.assign(
    { color: num > contrastNum ? 'red' : color, padding: '0 12px', display: 'inline-block' },
    isBold && { color: num > contrastNum ? 'red' : '#333333', fontWeight: 'bold' })
  }>
    {num}
  </span>
}

function delayering(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o)
    if (!_.isEmpty(o.children)) delayering(o.children, arr)
  })
  return arr
}

// function departmentTreeData(pArry, arry) {
//   _.forEach(pArry, item => {
//     item.children = arry.filter(o => item.departmentId === o.departmentPId)
//     if (_.size(item.children) > 0) departmentTreeData(item.children, arry)
//   })
//   return pArry
// }
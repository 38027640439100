import React, { useMemo } from 'react'
import _ from 'lodash'
import { DataGrid, Tooltip } from 'rootnet-ui'
import { Box } from '../../../../common/commonComponent';
import './index.scss'

function getColumns(props) {
  return [
    { header: '部门', bind: 'depFullPathName', width: 100, tooltip: true, sortable: true },
    { header: 'ID', bind: 'userID', width: 70, tooltip: true, sortable: true },
    { header: '登录名', bind: 'optId', width: 110, tooltip: true, sortable: true },
    { header: '姓名', bind: 'userName', width: 110, tooltip: true, sortable: true },
    {
      header: '角色', bind: 'roleName', width: 230, sortable: true, convert: v => {
        return <Tooltip title={v} style={{ maxWidth: 400 }}>
          <div className='role-list-overflow'>
            {v || '-'}
          </div>
        </Tooltip>
      }
    },
    { header: '状态', bind: 'stateName', width: 80, tooltip: true, sortable: true },
  ]
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

export default function UserGroupPersonnel(props) {
  const { data, loading, error, currentId } = props

  const options = useMemo(() => getOptions(getColumns({})), [])

  const showList = useMemo(() => {
    const showList = data || []
    return _.map(showList, o => _.assign({}, o, { userID: _.toNumber(o.userID) }))
  }, [data])

  return (
    <div className='user-group-personnel'>
      <Box title={'人员'} className='flex-y x-card-singlegrid' data={showList} loading={(_.isNil(currentId) && loading) ? false : loading} error={error}>
        <DataGrid option={options} data={showList} />
      </Box>
    </div>
  )
}

import React, { useCallback, useState, useMemo } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, TextArea, Display } from 'rootnet-edit'
import VersionSelect from '../applyJoinVersionDialog/VersionSelect'
import { isNil } from '../../appraise/components/method'
import convertOptions from '../../common/ConvertOptions'
import { Icon } from '../../../components'
import './index.scss'

const SUBMIT_URL = '/projectAuditLog/batchAdd'

const HFormInput = (props) => <FormInput {...props} labelWidth={90} componentWidth={450} horizontal />

export default function ApplyJoinProjectDialog(props) {
  const { mode, close, leftAllProjectOptions, projectAuditLog, leafProjectIds, initParams = {}, businessId, businessType, refresh } = props
  const [params, setParams] = useState(initParams)
  const [error, setError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()

  const isAdd = useMemo(() => mode === 'add', [mode])

  const canSubmit = useMemo(() => {
    if (isNil(_.get(params, 'optMemo'))) return false
    return _.every(error, _.isNil)
  }, [error, params])

  const filterProject = useMemo(() => {
    if (_.isEmpty(leftAllProjectOptions)) return []
    const projectIds = _.map(projectAuditLog, 'projectId')
    const filterList = _.filter(leftAllProjectOptions, o => !_.includes(_.concat(projectIds, leafProjectIds), o.value))
    return _.map(filterList, o => _.assign({}, o, { tag: `${_.get(o, 'text')}${_.get(o, 'value')}` }))
  }, [projectAuditLog, leafProjectIds, leftAllProjectOptions])

  const submit = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const data = _.get(params, 'projectIdList', [])
    const initData = _.map(data, v => ({ businessId, businessType, optFlag: mode, optMemo: _.get(params, 'optMemo', ''), projectId: v }))
    doFetch(SUBMIT_URL, initData)
      .then(() => {
        refresh()
        setIsDisable(false)
        Messager.show('提交申请成功', { icon: 'success' })
        close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
    // setEditFormData(x => {
    //   const filterRsd = _.filter(x?.projectAuditLogs, o => !_.includes(_.map(initData, 'projectId'), o.projectId))
    //   const concatData = _.compact(_.concat([], filterRsd, initData))
    //   return _.assign({}, x, { projectAuditLogs: concatData })
    // })
  }, [close, params, mode, businessId, businessType, isDisable, doFetch, refresh])

  return (
    <Dialog
      header={isAdd ? '申请加入项目' : '申请删除项目'}
      className={'apply-join-project-dialog'}
      confirmButtonDisabled={!canSubmit}
      cancel={close}
      confirm={submit}
    >

      {
        !_.isEmpty(projectAuditLog) && isAdd &&
        <HFormInput label='审批中项目' bind='underApprobal' component={Display} convert={() => {
          const projectAuditLogIds = _.map(projectAuditLog, o => o.projectName)
          return <Tooltip title={_.join(projectAuditLogIds, '，')}>
            <div className='apply-join-under-approbal'>{_.join(projectAuditLogIds, '，')}</div>
          </Tooltip>
        }} />
      }
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <HFormInput label={'选择项目'} bind={'projectIdList'} component={isAdd ? VersionSelect : Display} multiple options={filterProject} required optionFilterProp='tag' convert={arr => {
          const nameArr = _.map(arr, v => {
            return convertOptions(v, leftAllProjectOptions)
          })
          return <Tooltip title={_.join(nameArr, '，')}>
            <div className='apply-join-under-approbal'>{_.join(nameArr, '，')}</div>
          </Tooltip>
        }} />
        <HFormInput label={'申请说明'} bind={'optMemo'} required component={TextArea} />
      </Form>
      <div className={'tips-wrap flex center-y'}>
        <Icon name={'tixing'} className={'tips-icon'} />
        <div className={'tips-text'}>项目已基线，需要PO审核后生效</div>
      </div>
    </Dialog>
  )
}

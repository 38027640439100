import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState, Fragment } from 'react'
import cls from 'clsx'
import { Form, FormInput, RadioGroup, TextArea, Select } from 'rootnet-edit'
import { Button, Loader, Messager, MessageBox } from 'rootnet-ui'
import { useGet, useApi } from '../../../utils/testHook'
import { strParams } from '../../../utils/publicFun'
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions'
import ArrowCrumbs from '../../common/ArrowCrumbs'
import { toChinesNum, isNil } from '../components/method'
import './evaluationPage.scss'

const EVALUATION_URL = '/quest-detail/info'
const ADD_URL = '/quest-result/add'
const EDIT_URL = '/quest-result/update'
const QUEST_TYPE_URL = '/common/globalconst?globalConst=peopleEvaluateQuestType0630' // peopleEvaluateQuestType
const QUEST_TYPES_URL = '/common/globalconst?globalConst=peopleEvaluateQuestType' // peopleEvaluateQuestType

const ids = ['22063001', '22063002']

const COMPONENT_TYPE = {
  '1': RadioGroup,
  '2': TextArea,
}

function contrast(num) {
  if (num === 0) return 0
  else if ((4 <= num && num < 5) || (1 < num && num <= 2.5)) return 2
  else if ((num === 5) || (num === 1)) return 3
}

export default function EvaluationPage(props) {
  const { history, routingHop, currentId: questId, evaluateId, userDetail, headerTitle, userList, detailId, setDetailId, listRefresh } = props
  const [params, setParams] = useState()
  const [memoData, setMemoData] = useState()
  const [radio, setRadio] = useState()
  const [err, setErr] = useState()
  const [open, setOpen] = useState(false)
  const { data: questTypeOption } = useGet(_.includes(ids, questId) ? QUEST_TYPE_URL : QUEST_TYPES_URL)
  const { data, doFetch, loading } = useGet()
  const { doFetch: getFetch } = useApi()

  const { title, directions, questDetailVoList } = useMemo(() => (data || {}), [data])
  const { userName, name, status, evaluators } = useMemo(() => (userDetail || {}), [userDetail]) //

  const refresh = useCallback(() => {
    if (isNil(evaluateId) && isNil(questId)) return
    doFetch(`${EVALUATION_URL}?${strParams({ evaluateId, questId })}`)
  }, [doFetch, evaluateId, questId])

  const questTypeOpt = useMemo(() => convertGlobalConstOptions(questTypeOption), [questTypeOption])

  const searchList = useMemo(() => {
    if (_.isEmpty(questDetailVoList)) return
    const sortData = _.orderBy(questDetailVoList, 'serial', 'asc')
    const classifyData = _.groupBy(sortData, o => o.questType)
    return _.map(classifyData, (v, k) => {
      return {
        title: k,
        problemList: _.map(v, (item, i) => {
          const strLen = _.size(_.trim(_.get(params, `${item.questionId}`))) >= 10
          const required = item.requiredFlag === 'Y'
          return _.assign({
            key: item.id,
            label: `${i + 1}、${item.question}`,
            bind: item.questionId,
            required,
            component: COMPONENT_TYPE[item.type],
            options: questTypeOpt,
            isMemo: item.type === '1',
            className: cls({ 'radio-row': _.size(questTypeOpt) > 5 })
          }, item.type === '2' && {
            required,
            example: item.questType === 'example',
            maxLength: 500,
            componentWidth: 550,
            className: cls('reason', { error: (!required || strLen) ? false : true })
          })
        })
      }
    })
    // return _.map(_.orderBy(questDetailVoList, 'serial', 'asc'), (item, i) => {
    //   const strLen = _.size(_.trim(_.get(params, `${item.questionId}`))) >= 10
    //   const required = item.requiredFlag === 'Y'
    //   return _.assign({
    //     key: item.id,
    //     label: `${i + 1}、${item.question}`,
    //     bind: item.questionId,
    //     required,
    //     component: COMPONENT_TYPE[item.type],
    //     options: questTypeOpt,
    //     isMemo: item.type === '1'
    //   }, item.type === '2' && {
    //     example: item.questType === 'example',
    //     maxLength: 500,
    //     componentWidth: 550,
    //     className: cls('reason', { error: strLen ? false : true })
    //   })
    // })
  }, [params, questDetailVoList, questTypeOpt])

  const exampleList = useCallback((num = 0) => {
    if (_.isEmpty(questDetailVoList)) return
    const list = _.filter(questDetailVoList, o => o.questType === 'example')
    return _.map(list.splice(0, num), o => o.id)
  }, [questDetailVoList])

  const score = useMemo(() => {
    if (_.isEmpty(questDetailVoList)) return
    const parameter = _.map(radio, v => params[v])
    const sum = _.reduce(_.compact(_.values(parameter)), (acc, v) => acc + Number(v), 0)
    const filterLen = _.size(_.filter(questDetailVoList, o => o.questType !== 'example'))
    return (sum / filterLen)
  }, [params, questDetailVoList, radio])

  const check = useCallback((data) => {
    return _.some(data, isNil)
  }, [])

  const confirm = useCallback(() => {
    if (check(_.map(radio, v => params[v]))) return Messager.show('请填写必填项', { icon: 'error' })
    // const radioMemo = _.compact(_.map(radio, v => { if (params[v] === '1' || params[v] === '5') { return v + 'Memo' } }))
    // if (_.some(_.map(radioMemo, k => !(_.size(_.trim(params[k])) >= 10)), Boolean)) return Messager.show('请填写评论示例', { icon: 'error' })
    if (!_.isEmpty(memoData) && _.every(memoData, v => !(_.size(params[v]) >= 10))) return Messager.show('请填写必填项', { icon: 'error' })
    const sampleJudge = _.map(exampleList(contrast(score)), v => !(_.size(params[v]) >= 10))//!(_.size(params[v]) >= 10)
    if (!_.isEmpty(exampleList(contrast(score))) && _.some(sampleJudge, Boolean)) return Messager.show('请填写事例', { icon: 'error' })
    const keys = _.filter(_.keys(params), v => !v.includes('Memo'))
    const parameter = _.map(keys, v => {
      return _.assign({}, {
        questId,
        evaluateId,
        questionId: v,
        result: params[v],
        createUser: evaluators,
      })
    })
    /*
      (params[v] === '1' || params[v] === '5') && 
      {memo: params[v + 'Memo']}
     */
    const isAdd = status === '01'
    const url = isAdd ? ADD_URL : EDIT_URL
    getFetch(url, 'post', parameter)
      .then(() => {
        listRefresh()
        const findData = _.find(userList, o => o.status === '01')
        if (_.isNil(findData)) {
          routingHop(questId)
          Messager.show('已全部评论', { icon: 'info' })
        } else { setOpen(true) }
        Messager.show(`评价成功`, { icon: 'success' })
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [check, evaluateId, evaluators, exampleList, getFetch, listRefresh, memoData, params, questId, radio, routingHop, score, status, userList])

  useEffect(() => {
    if (_.isEmpty(questDetailVoList)) return
    const defaultValue = _.map(questDetailVoList, item => ({ [item.questionId]: item.result }))
    setParams(_.assign({}, ...defaultValue))
    setMemoData(_.map(_.filter(questDetailVoList, item => (item.type === '2' && item.questType !== 'example' && item.requiredFlag === 'Y')), item => item.questionId))
    setRadio(_.map(_.filter(questDetailVoList, item => (item.type === '1' && item.requiredFlag === 'Y')), item => item.questionId))
  }, [questDetailVoList])

  useEffect(() => {
    refresh()
  }, [refresh])

  const goBack = useMemo(() => {
    return [
      { text: '360评价', onClick: () => routingHop() },
      { text: headerTitle, onClick: () => routingHop(questId) },
      {
        text: <FormInput search horizontal
          options={userList}
          value={detailId}
          onChange={v => {
            setDetailId(v)
            history.replace({ pathname: '/360Evaluate', search: strParams({ questId, evaluateId: v }) })
          }}
          component={Select}
        />, active: true
      }
    ]
  }, [detailId, headerTitle, history, questId, routingHop, setDetailId, userList])

  const nextArticle = useCallback(() => {
    const findData = _.find(userList, o => o.status === '01')
    if (_.isNil(findData)) {
      routingHop(questId)
      Messager.show('已全部评论', { icon: 'info' })
      return
    }
    const nextId = _.get(findData, 'value')
    history.replace({ pathname: '/360Evaluate', search: strParams({ questId, evaluateId: nextId }) })
    setDetailId(nextId)
    setOpen(false)
  }, [history, questId, routingHop, setDetailId, userList])

  return (
    <>
      <div className='evaluation-details fill flex-y'>
        <div style={{ background: '#fff', padding: '4px 0px' }}><ArrowCrumbs goBack={() => { routingHop(questId) }} data={goBack} /></div>
        {
          loading ? <Loader /> :
            <div className='content'>

              <div className='title'>{title}</div>

              <div className='instruction'>
                <div className='identification'>指导语：</div>
                <div dangerouslySetInnerHTML={{ __html: directions }} />
              </div>

              <div className='evaluation-form'>

                <div className='appraiser'>您此次评价对象为：<span style={{ fontWeight: 900, color: '#5477ff' }}>{userName}</span><span style={{ marginLeft: 16 }}>{name}</span></div>

                <div className='form-detail'>
                  <Form value={params} onChange={setParams} error={err} onError={setErr}>
                    {
                      _.map(searchList, (o, i) => {
                        return <Fragment key={i}>
                          {(!isNil(o.title) && o.title !== 'example') && <div> {toChinesNum(i + 1)}、{o.title}</div>}
                          <div>
                            {
                              _.map(o.problemList, item => {
                                return <div key={item.key}>
                                  {!item.example && <FormInput {..._.omit(item, ['isMemo'])} />}
                                  {
                                    (item.example && _.includes(exampleList(contrast(score)), item.key)) &&
                                    <FormInput
                                      {..._.omit(item, ['isMemo', 'example'])}
                                      required
                                      className={cls('reason', { error: _.size(_.trim(_.get(params, `${item.bind}`))) >= 10 ? false : true })}
                                    />
                                  }
                                </div>
                              })
                            }
                          </div>
                        </Fragment>
                      })
                    }
                  </Form>
                </div>

                <div className='operating-btn'>
                  <Button primary onClick={_.debounce(confirm, 300)}>提交</Button>
                  <Button onClick={() => routingHop(questId)}>取消</Button>
                </div>

              </div>

            </div>
        }
      </div>
      {
        open &&
        <MessageBox
          header={'提示'}
          closeButtonVisible
          confirm={nextArticle}
          cancel={() => { setOpen(false); routingHop(questId) }}
          contentStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 700 }}
        >
          是否评价下个人员
        </MessageBox>
      }
    </>
  )

}

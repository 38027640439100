import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useGet, usePost } from "rootnet-biz/es/hooks";
import _ from 'lodash'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import { Box } from "../../../../common/commonComponent";
import convertTableAlign from '../../../../common/view/convertTableAlign';
import findConvert from '../../../../common/view/findConvert';
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig';
import useRefreshList from '../../../../common/view/hooks/useRefreshList';
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList';
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions';
import { TextIconBtn } from '../../../../common/TextIconBtn';
import ViewArea from '../../../../common/view/ViewArea';
import './ContactPerson.scss'
import { ScheduleManagementContext } from '../../../../common/Context';

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        } else {
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
            }
        }
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '2020'

function ContactPerson(props) {
    const { customerID } = props
    const { downloadPostProgress } = useContext(ScheduleManagementContext)
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [factor, setFactor] = useState()
    const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const appendObj = useMemo(() => {
        return { factorViewSql: `customer_linker.customer_Id = '${customerID}'` }
    }, [customerID])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns })

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = _.assign({}, {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }, appendObj)
        Messager.show('导出中...')
        downloadPostProgress('/UserSetting/exportExcel', postParams, '客户联系人')
    }, [downloadPostProgress, currentViewId, fieldList, factor, appendObj])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            {
                allowExport === 'Y' &&
                <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
            }
        </div>
    }, [exportViewList, allowExport])

    return (
        <div className={'contact-person fill flex-y'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                {...{
                    getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize
                }} />
            <Box title={<div className='contact-person-head'>
                <span className='title'>联系人</span>
                <span className='hint'>如修改或新增客户联系人信息，请在客户联系人菜单下维护</span>
            </div>} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                <DataGrid option={options} data={dataGridList} />
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, { pageNum, pageSize }))
                    }} />
            </Box>
        </div>
    )
}

export default ContactPerson;
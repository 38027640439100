import React, { useMemo, useState, useCallback } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../common/view/convertTableAlign'
import findConvert from '../common/view/findConvert'
import useGetViewConfig from '../common/view/hooks/useGetViewConfig'
import useRefreshList from '../common/view/hooks/useRefreshList'
import useGetDateOptions from '../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../common/view/hooks/useGetTreeList'
import ViewArea from '../common/view/ViewArea'
import { Box } from '../common/commonComponent'
import { TextIconBtn } from '../common/TextIconBtn'
import { useContext } from 'react'
import { ScheduleManagementContext } from '../common/Context'
import { isNil } from "../appraise/components/method";
import moment from 'moment'
import './index.scss'
import RequirementDetailDialog from '../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import IssueDetailDialog from '../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import CustomerServiceInfoDetailDialog from '../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog'
import DefectUpdateDialog from '../testMgt/defect/controls/DefectUpdateDialog'

const PICK_BIND = ['workflow_business.businesstype', 'View_currentinfo_timeConsume.referenceId']
const TITLE_BIND = 'view_workflow_name.title'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (_.includes(PICK_BIND, tableField)) {
      o[fieldItem.fieldId] = v
    }
    if (TITLE_BIND === tableField && !isNil(o?.referenceId)) {
      return <div className="enter-detail-field flex">
        <div className="enter-detail-field-text" onClick={() => {
          setCurrentInfo({
            mode: 'detail',
            id: _.get(o, 'referenceId'),
            type: _.get(o, 'businesstype'),
          })
        }}>
          {showValue || '-'}
        </div>
      </div>
    }
    if (tableField === 'View_currentinfo_timeConsume.timeConsume') {
      if (isNil(v)) {
        return '-'
      } else {
        const d = moment.duration({ minutes: _.toNumber(v) })
        if (_.floor(d.asDays()) === 0) {
          if (d.hours() === 0) {
            return d.minutes() + '分'
          } else {
            return d.hours() + '时' + d.minutes() + '分'
          }
        } else {
          return _.floor(d.asDays()) + '天' + d.hours() + '时' + d.minutes() + '分'
        }
      }
    }

    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '29'

export default function WorkflowTimeDetailsMgt() {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [factor, setFactor] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [currentInfo, setCurrentInfo] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: {
      setCurrentInfo,
    }
  })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '工作流耗时明细列表')
  }, [downloadPostProgress, currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList])

  return (
    <div className={'workflow-time-details-mgt fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'工作流耗时明细列表'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'type') === 'req' &&
        <RequirementDetailDialog
          refreshViewList={refreshList}
          close={() => setCurrentInfo(null)}
          {...{ currentInfo }}
        />
      }
      {
        _.get(currentInfo, 'type') === 'defect' &&
        <DefectUpdateDialog
          mode={'detail'}
          refreshList={refreshList}
          close={() => setCurrentInfo(null)}
          currentId={_.get(currentInfo, 'id')}
        />
      }
      {
        _.get(currentInfo, 'type') === 'issue' &&
        <IssueDetailDialog
          refreshViewList={refreshList}
          close={() => setCurrentInfo(null)}
          {...{ currentInfo }}
        />
      }
      {
        _.get(currentInfo, 'type') === 'customer' &&
        <CustomerServiceInfoDetailDialog
          refreshViewList={refreshList}
          close={() => setCurrentInfo(null)}
          {...{ currentInfo }}
        />
      }
    </div>
  )
}

import React, { Fragment, useState } from 'react';
import { Card } from 'rootnet-ui';
import { Icon } from '../../../components';
import Edit from './Edit';
import './Right.scss';
import { MilestoneCardCore } from "../../common/MilestoneCardCore";

function Right(props) {
    let { product, title, data, loading, error, refresh } = props;
    let [state, setState] = useState(''); //编辑状态
    return <Fragment>
        <Card className="milepost-right x"
            loading={loading} error={error}
            title={title}
            extra={
                <Fragment>
                    <div className='edit' onClick={() => { setState('edit') }}><Icon name="bianjizhibiaolie1" /></div>
                </Fragment>
            }>
            <MilestoneCardCore data={data} product={product}/>
        </Card>
        {
            state === 'edit' &&
            <Edit
                data={data}
                product={product}
                loading={loading}
                error={error}
                title="编辑"
                cancel={() => setState('')}
                confirm={refresh}
            />
        }
    </Fragment>
}

export default Right;
import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import DepartmentDetail from './departmentDetail'
import EstimateDetail from './estimateDetail'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import ManpowerAddLog from './manpowerAddLog'
import ManpowerExportLog from './manpowerExportLog'
// import { useFuncCode } from '../../../../common/commonMethod'
import ManpowerProjectMaintenanceLog from './manpowerProjectMaintenanceLog'
import { estimateInitData } from '../../../components/formatData'
import './index.scss'
import ManpowerDetailLog from './manpowerDetailLog'

export default function ManpowerDetailList(props) {
  const { projectOpt, projectOption, projectTypeOpt, itemClassOpt, exportTypeOpt, refresh, exportParams, activeTab = 'departmentDetail', setActiveTab, refreshAffair, data, status, statusOpt } = props
  const [mode, setMode] = useState()
  // const [isProjectMaintenance] = useFuncCode(['401001'])

  const tabBarExtraContent = useMemo(() => {
    return <div className='flex' style={{ paddingRight: 16 }}>
      {/* {
        isProjectMaintenance &&
        <TextIconBtn text='项目维护' icon='bianji2' onClick={() => setMode({ type: 'projectMaintenance' })} />
      } */}
      {/* <TextIconBtn text='导入' icon='daoru' /> */}
      <TextIconBtn text='导出' icon='daochu' onClick={() => setMode({ type: 'export' })} />
      <TextIconBtn text='新增' icon='tianjia' onClick={() => setMode({ type: 'add' })} />
    </div>
  }, [])

  const dataSource = useMemo(() => estimateInitData(data, status), [data, status])

  return (
    <>
      <Tabs
        className='manpower-detail-list-tabs'
        activeKey={activeTab}
        onChange={setActiveTab}
        tabBarExtraContent={tabBarExtraContent}
        items={[
          {
            label: '部门明细',
            key: 'departmentDetail',
            children: <DepartmentDetail {...props} />
          },
          {
            label: '预估明细',
            key: 'estimateDetail',
            children: <EstimateDetail {...props} />
          }
        ]}
      />
      {
        _.get(mode, 'type') === 'add' &&
        <ManpowerAddLog close={() => setMode(null)} {...{ projectOption, itemClassOpt, refresh, dataSource, setMode }} />
      }
      {
        _.get(mode, 'type') === 'export' &&
        <ManpowerExportLog close={() => setMode(null)} {...{ projectOpt, exportTypeOpt, exportParams }} />
      }
      {
        _.get(mode, 'type') === 'projectMaintenance' &&
        <ManpowerProjectMaintenanceLog close={() => setMode(null)} {...{ projectTypeOpt, refreshAffair, refreshList: refresh }} />
      }
      {
        _.get(mode, 'type') === 'edit' &&
        <ManpowerDetailLog {...{ initId: _.get(mode, 'id'), refresh, projectOpt, projectOption, itemClassOpt, statusOpt }} close={() => setMode(null)} />
      }
    </>
  )
}



import React, { useRef } from 'react';
import cls from 'classnames';
import { Icon } from "../../components";
import { useMount } from "../../utils/hook";
import './TextIncoBtn.scss';

export function TextIconBtn(props) {
    const { style, icon, size, className, text, disabled, ...rest } = props;
    const ref = useRef(null);

    useMount(() => {
        const node = ref.current;
        const nodeInfo = node.getBoundingClientRect();
        const parentInfo = getContainer(node).getBoundingClientRect();
        const leftLead = nodeInfo.left - parentInfo.left;
        const rightLead = parentInfo.right - nodeInfo.left;
        const positionName = leftLead > rightLead ? 'tib-right' : 'tib-left';
        node.className = node.className.concat(' ', positionName);
    });

    return <div style={style} className={cls('x textIconBtn', className, { disabled })} ref={ref} {...rest}>
        <Icon name={icon} style={{ fontSize: size }} />
        {text}
    </div>;
}
TextIconBtn.defaultProps = {
    size: 14
};

function getContainer(n) {
    const p = n.parentElement;
    const isContainer = getComputedStyle(p, null).overflow !== 'visible' || p.tagName === "HTML";
    return isContainer ? p : getContainer(p);
}
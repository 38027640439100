import React, {useEffect, useMemo} from 'react';
import {Drawer, DataGrid} from 'rootnet-ui'
import {N2} from "rootnet-core/format";
import {useGet} from "../../../utils/hook";
import './FieldDictDrawer.scss'
import _ from 'lodash'
import {Box} from "../../common/commonComponent";

const getColumns = (props) =>{
    return [
        { header: '子项标识', bind: 'fieldDictSubCode', width: 120, sortable: true, tooltip: true },
        { header: '子项名称', bind: 'fieldDictSubName', width: 150, sortable: true, tooltip: true },
        { header: '顺序', bind: 'displayOrder', width: 120, sortable: true, tooltip: true, convert:N2 },
        { header: '说明', bind: 'fieldDictSubDesc', width: 200, sortable: true, tooltip: true  },
    ]
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
});

function FieldDictDrawer(props) {
    const {open, cancel, fieldDictId} = props
    const {data, doFetch, loading, error} = useGet()

    useEffect(()=>{
        if(!_.isNil(fieldDictId)){
            doFetch('/fieldDict/alllist?dictConstId='+fieldDictId)
        }
    },[fieldDictId,doFetch])

    const option = useMemo(()=>{
        return getTableOption(getColumns())
    },[])

    return <Drawer open={open} cancel={cancel} onOutsideClick={cancel} header={'常量信息'} className={'field-dict-drawer'} contentClassName={'flex-y'}
                   cancelButtonVisible={false} confirmButtonVisible={false}>
        <div className={"flex"}>
            <div className={'desc-item flex center'}>
                <span className='label'>常量标识：</span>
                <span className='value'>{fieldDictId || '-'}</span>
            </div>
            <div className={'desc-item flex center'}>
                <span className='label'>常量名称：</span>
                <span className='value'>{_.get(_.head(data),'fieldDictName') || '-'}</span>
            </div>
        </div>
        <Box data={data} className='field-dict-box x-card-singlegrid flex-y' loading={loading} error={error}>
            <DataGrid data={ data} option={option}/>
        </Box>
    </Drawer>
}

export default FieldDictDrawer;
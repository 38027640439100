import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, Button } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import './index.scss'

const HFormInput = props => <FormInput horizontal labelWidth={80} componentWidth={495} {...props} />

export default function TransferHandlerDialog(props) {
  const { close, confirm, size = 0, list, isWorkTimeSearch } = props
  const [params, setParams] = useState()

  const header = useMemo(() => {
    return <div className='transfer-handler-header'>
      <span className='title'>转移处理人</span>
      <span className='hint'>（{size}条记录）</span>
    </div>
  }, [size])

  const handClick = useCallback(() => {
    confirm({ list, params: _.assign({}, params, { flag: isWorkTimeSearch ? 'Y' : 'N' }) })
  }, [list, params, confirm, isWorkTimeSearch])

  return (
    <Dialog
      header={header}
      cancel={close}
      footerVisible={false}
      // headerVisible={false}
      className='transfer-handler-dialog-edit'
    >
      <div className='transfer-handler-dialog-edit-content flex-y'>
        <div className="transfer-handler-dialog-edit-main-panel">
          <Form value={params} onChange={setParams}>
            <HFormInput required label='转移至' bind='reviewerList' multiple component={UserSelect} />
            <HFormInput className='text-area-input-edit' required label='事由' bind='memo' component={TextAreaInput} />
          </Form>
        </div>
        <div className="mock-footer flex center-y">
          <div className='footer-hint'>
            {!isWorkTimeSearch && '提示：仅允许您的当前待办转移给其他人！'}
          </div>
          <div className="btn-group flex">
            <Button normal onClick={close}>取消</Button>
            <Button primary disable={((_.size(_.trim(_.get(params, 'memo'))) <= 0) || _.isNil(_.get(params, 'reviewerList')))} onClick={handClick}>确认</Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

import React, {useCallback, useEffect, useState, useMemo} from 'react';
import _ from 'lodash'
import cls from 'clsx'
import { Popover, Tag } from 'antd';
import { isNil } from 'rootnet-core/format';
import PersonSelectPop from "./PersonSelectPop";
import convertOptions from "../ConvertOptions";
import { REQUIRED_ERROR, useControlledError } from "./useControlledError";
import './UserSelect.scss'
import {ErrorTooltip} from "rootnet-edit/lib/base/Tooltip";

function UserSelect(props) {
    const outerAllUserRes = JSON.parse(localStorage.getItem('all_user'))
    const { required, onError, hiddenUserList, style, placeholder, disabled, multiple, allowClear, mustList, defaultOpen,
        allowDeptList, allowUserList } = props
    const [stateValue, setStateValue] = useState()
    const [visible, setVisible] = useState(defaultOpen)
    const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);
    const [owner, setOwner] = useState(null)
    const [isInited, setIsInited] = useState(false)

    const isControlled = useMemo(()=>{
        return !_.isNil(props.value) || _.isNil(props.defaultValue)
    },[props.value, props.defaultValue])

    const initData = useCallback((data)=>{
        if(multiple){
            let handleData
            if(_.isArray(data)){
                handleData = data
            }else{
                handleData = data?_.split(data, ','):null
            }
            setStateValue(handleData)
        }else{
            setStateValue(data)
        }
        setIsInited(true)
    },[multiple])

    useEffect(()=>{
        if(isControlled){
            initData(props.value)
        }
    },[isControlled, initData, props.value])

    useEffect(()=>{
        if(isInited && !isControlled) return
        if(_.isNil(props.value)) return
        initData(props.value)
    },[props.value,initData, isInited, isControlled])

    useEffect(()=>{
        if(isInited && isControlled) return
        if(_.isNil(props.defaultValue)) return
        initData(props.defaultValue)
    },[props.defaultValue,initData,isInited, isControlled])

    useEffect(() => {
        const err = (required && _.isEmpty(stateValue)) ? REQUIRED_ERROR : null;
        if (err !== error) {
            setStateError(err);
            if (props.onError)
                props.onError(err);
        }
    }, [stateValue, required, onError, props, error, setStateError])

    const show_error = useMemo(() => {
        return error && errorVisible && !_.isEmpty(outerAllUserRes)
    }, [error, errorVisible, outerAllUserRes])

    const handleFocus = useCallback(()=>{
        if(props.onFocus){
            props.onFocus()
        }
    },[props])

    const handleBlur = useCallback(()=>{
        setUserActive(true)
        if(props.onBlur){
            props.onBlur()
        }
    },[props,setUserActive])

    const onRemove = useCallback((removeItem) => {
        setStateValue(oldList => {
            const newList = multiple ? _.filter(oldList, x => x !== removeItem) : null
            if (props.onChange) {
                props.onChange(newList)
            }
            return newList
        })
    }, [multiple, props])

    const onSelectConfirm = useCallback(newValue => {
        setStateValue(newValue)
        if (props.onChange) {
            props.onChange(newValue)
        }
    }, [props])

    const onVisibleChange = useCallback(visible => {
        if(!visible){
            handleBlur()
        }
        setVisible(disabled ? false : visible)
    },[disabled, handleBlur])

    return <Popover content={<PersonSelectPop defaultValue={stateValue}
        {...{ onSelectConfirm, setVisible, hiddenUserList, multiple, allowClear, mustList, allowDeptList, allowUserList }} />}
        trigger="click" overlayClassName={'user-select-pop'}
        destroyTooltipOnHide={true} zIndex={9998} placement="bottom"
        open={visible} onOpenChange={onVisibleChange}>
        <div className={cls('user-select flex center-y', { error: show_error, disabled })} ref={setOwner} onFocus={handleFocus} style={{ ...style }}>
            {
                (multiple && !_.isEmpty(stateValue)) &&
                _.map(stateValue, item => <Tag closable={!_.includes(mustList,item) && allowClear} color={'#f0f0f0'} key={item} onClose={() => onRemove(item)}>{
                    convertOptions(item, outerAllUserRes, 'userName', 'userAccount') === '' ? item: convertOptions(item, outerAllUserRes, 'userName', 'userAccount')
                }</Tag>)
            }
            {
                (!multiple && !isNil(stateValue)) && <Tag closable={allowClear} color={'#f0f0f0'} onClose={() => onRemove(stateValue)}>{
                    convertOptions(stateValue, outerAllUserRes, 'userName', 'userAccount') === '' ? stateValue : convertOptions(stateValue, outerAllUserRes, 'userName', 'userAccount')
                }</Tag>
            }
            {
                _.isEmpty(stateValue) &&
                <div className={'user-select-placeholder'}>{placeholder}</div>
            }
        </div>
        {
            show_error &&
            <ErrorTooltip error={error} target={owner} />
        }
    </Popover>
}

UserSelect.defaultProps = {
    placeholder: '请选择',
    multiple: false,
    disabled: false,
    allowClear: true,
    marginLeft: false,
}

export default UserSelect;
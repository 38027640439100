import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Messager } from 'rootnet-ui'
import TextAreaInput from '../textAreaInput/TextAreaInput'

const DEL_URL = '/ReleaseStory/updateForReleaseStory'

export default function ClosurePlanMessage(props) {
  const { type, releaseidList, close, id, afterRefresh, setEditFormData = () => { }, oldData = [] } = props
  const [optMemo, setOptMemo] = useState()
  const [isDisabled, setIsDisabled] = useState()
  const { doFetch } = usePost()

  const isType = useMemo(() => _.includes(type, 'PLAN'), [type])

  const confirm = useCallback(() => {
    if (_.size(_.trim(optMemo)) <= 0) return Messager.show('请填写理由', { icon: 'error' })
    if (isDisabled) return
    setIsDisabled(true)
    const postParams = {
      type,
      optMemo,
      storyid: id,
      releaseidList,
    }

    doFetch(DEL_URL, postParams)
      .then((res) => {
        setEditFormData(x => _.assign({}, x, { _planReleaseList: _.filter(_.get(x, '_planReleaseList', []), o => o.value !== _.head(releaseidList)) }))
        afterRefresh()
        close()
        Messager.show(res, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })

  }, [id, type, optMemo, doFetch, isDisabled, close, afterRefresh, releaseidList, setEditFormData])

  return (
    <Dialog
      header='提示'
      confirm={confirm}
      cancel={() => {
        setEditFormData(x => _.assign({}, x, { _planReleaseList: oldData }))
        close()
      }}
      style={{
        paddingTop: 0,
        width: 530,
        height: 190,
      }}
    >
      <div style={{ padding: '8px 0' }}>{isType ? '预规划项目已封版，移出，请填写理由' : '版本已封版，移出，请填写理由'}</div>
      <TextAreaInput
        value={optMemo}
        onChange={setOptMemo}
        placeholder='请填写理由'
        autoSize={{ minRows: 6, maxRows: 6 }}
      />
    </Dialog>
  )
}

import React from 'react'
import _ from 'lodash'
import { Button } from 'rootnet-ui'
import { FormInput, Display } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'


export function TimeInterception(time, isTrue = false) {
  const { str14ToDate } = toDate
  const timeFormat = isTrue ? 'YYYY-MM-DD HH:MM:SS' : 'YYYY-MM-DD'
  return dateFormat(timeFormat, str14ToDate(time))
}

export function AuditTemplate(props) {
  const { header, data, jumpPage, btnText, withdraw = null } = props
  return <div className='AuditTemplate'>
    <p className='labelHeader'>{header}<span>{_.size(data)}/条</span></p>
    {
      _.map(data, (o, i) => {
        return (
          <dl key={i} onClick={(e) => jumpPage(e, 'details', o)}>
            <dt>
              <div>{o.tabName}</div>
              <div>
                <span>{TimeInterception(o.inputTime)}</span>
                <span>{o.typeName}</span>
                {/* <span><span>TYPE</span>{o.typeName}</span> */}
              </div>
            </dt>
            <dd>
              {
                (btnText === '撤回' && o.auditFlag === '0') &&
                <Button
                  cancel
                  className='initial'
                  onClick={(e) => withdraw ? withdraw(e, o) : jumpPage(e, 'examine', o)}
                >
                  {btnText}
                </Button>
              }

            </dd>
          </dl>
        )
      })
    }
  </div>
}

export function Processed(props) {
  const { pendingData, jumpPage } = props
  return <div>
    <p className='labelHeader'>待处理<span>{_.size(pendingData)}/条</span></p>
    {
      _.map(pendingData, (o, i) => {
        return (
          <dl onClick={(e) => jumpPage(e, 'details', o, 1)} key={i}>
            <dt>
              <p>{o.tabName}<span>被驳回</span></p>
              <div style={{ padding: '0.1rem 0' }}>{o.typeName}</div>
              <FormInput horizontal label='操作时间' value={TimeInterception(o.inputTime)} component={Display} /><br />
              <FormInput horizontal label='审核说明' value={o.auditMemo} component={Display} />
            </dt>
            {/* <dd>
              <Button className='initial' cancel>修改</Button>
              <Button className='initial' cancel>详情</Button>
            </dd> */}
          </dl>
        )
      })
    }
  </div>
}
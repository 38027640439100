import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './WorkflowEditableLeftPanel.scss'
import {useGet, usePost} from "rootnet-biz/es/hooks";
import { AutoComplete } from 'antd';
import {isNil} from "../../../../appraise/components/method";
import _ from 'lodash'
import {Button, Messager} from 'rootnet-ui'

function WorkflowEditableLeftPanel(props) {
    const {graphRef} = props
    const [newNodeName, setNewNodeName] = useState()
    const {data: allNodeList, doFetch: getAllNodeList} = useGet()
    const {doFetch: addNodePost} = usePost()

    const refreshAllList = useCallback(()=>{
        getAllNodeList('/WorkflowFactor/getWorkflowFactorList')
    },[getAllNodeList])

    const options = useMemo(()=>{
        if(_.isEmpty(allNodeList)) return []
        return _.map(allNodeList, x => ({label: x.name, value: x.id}))
    },[allNodeList])

    useEffect(()=>{
        refreshAllList()
    },[refreshAllList])

    const onSelect = useCallback((value, option)=>{
        setNewNodeName(option.label)
    },[])

    const canSubmit = useMemo(()=>{
        if(isNil(newNodeName)) return false
        return true
    },[newNodeName])

    const addNode = useCallback(()=>{
        const findItem = _.find(allNodeList, x => x.name === newNodeName)
        if(isNil(findItem)){
            addNodePost('/WorkflowFactor/addWorkflowFactor',{name: newNodeName}).then(id => {
                const newNode = graphRef.current.createNode({
                    id: id,
                    shape: 'custom-big-rect',
                    label: newNodeName,
                })
                graphRef.current.addNode(newNode)
                setNewNodeName(null)
                refreshAllList()
            }).catch(err => Messager.show(err._message, { icon: 'error' }))
        }else{
            const newNode = graphRef.current.createNode({
                id: findItem.id,
                shape: 'custom-big-rect',
                label: newNodeName,
            })
            graphRef.current.addNode(newNode)
            setNewNodeName(null)
        }
    },[newNodeName, allNodeList, refreshAllList, addNodePost, graphRef])

    return <div className={'workflow-editable-left-panel flex-y'}>
        <AutoComplete value={newNodeName} onChange={setNewNodeName} options={options} onSelect={onSelect}
                      placeholder={'节点文本'}
                      filterOption={(inputValue, option) =>{
                          if(isNil(inputValue)) return true
                          return _.includes(option.label,inputValue)
                      }}/>
        <Button className={'add-btn'} primary disable={!canSubmit} onClick={addNode}>新增节点</Button>
    </div>
}

export default WorkflowEditableLeftPanel;
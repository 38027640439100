import React, {useCallback, useContext, useEffect, useMemo, useState, useRef} from 'react';
import {Table, Button, Form, Input, Alert, Select, DatePicker, InputNumber, Tooltip, ConfigProvider, Empty} from 'antd';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {MenuOutlined} from "@ant-design/icons";
import {arrayMoveImmutable} from "array-move";
import './ImpProjectPlan.scss'
import _ from 'lodash'
import {ImpProjectEditableContext} from "../../../../common/Context";
import convertOptions from "../../../../common/ConvertOptions";
import ImpProjectTemplateListDialog from "../impProjectTemplateListDialog/ImpProjectTemplateListDialog";
import ImpTaskInfoDialog from "../impTaskInfoDialog/ImpTaskInfoDialog";
import Icon from "../../../../../components/Icon";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import moment from 'moment';
import {Messager, MessageBox} from 'rootnet-ui'
import {pathSearchFor, strParams} from "../../../../../utils/publicFun";
import ImportApiDialog from "../../../../common/ImportApiDialog";
import api from "../../../../../base/task";
import UserSelect from "../../../../common/personSelectPop/UserSelect";

const milestoneFlagOptions = [
    {
        label: '是',
        value: '1',
    },
    {
        label: '否',
        value: '0',
    },
]

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
function getColumns(props){
    const {setCurrentTaskInfo, allUserRes, planStatusOptions, priorityOptions, segmentOptions, showList, projectId, isPrincipal} = props
    return _.concat(isPrincipal?[
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            fixed: 'left',
            render: () => <DragHandle />,
        },
    ]:[],
        [
            {
                title: '#',
                width: 40,
                align: 'center',
                fixed: 'left',
                render: (value, obj, index) => {
                    const previous = showList[index].serial
                    const next = _.get(showList, `[${index + 1}]serial`)
                    const newSerial = _.isNil(next) ? previous + 1 : (previous + next) / 2
                    return <div className={'table-index-col flex center'}>
                        <div className="index">{index + 1}</div>
                        {
                            isPrincipal &&
                            <div className="add-next-row-icon" onClick={()=>setCurrentTaskInfo(
                                {
                                    mode: 'add',
                                    serial: newSerial,
                                    listIndex: index,
                                    initFormInfo: {
                                        segment: obj['segment'],
                                        projectId
                                    }
                                })}>
                                <Icon name='tianjia' style={{fontSize: 18}}/>
                            </div>
                        }
                    </div>
                }
            },
            {
                title: <span style={{marginLeft: 12}}>阶段</span>,
                dataIndex: 'segment',
                key: 'segment',
                width: 140,
                fixed: 'left',
                editable: true,
                render: (value) => convertOptions(value, segmentOptions, 'label')
            },
            {
                title: '任务名称',
                dataIndex: 'itemName',
                key: 'itemName',
                width: 200,
                fixed: 'left',
                render: (value, obj, index) => <Tooltip placement="topLeft" title={value}>
                    <span className={'task-name-header'} style={{color: '#5477ff', cursor: 'pointer'}} onClick={()=>
                        setCurrentTaskInfo({mode: 'detail', id: _.get(obj,'id'), listIndex: index})
                    }>{value}</span>
                </Tooltip>,
            },
            {
                title: <span style={{marginLeft: 12}}>状态</span>,
                dataIndex: 'status',
                key: 'status',
                width: 100,
                editable: true,
                render: (value) => convertOptions(value, planStatusOptions, 'label')
            },
            {
                title: <span style={{marginLeft: 12}}>里程碑</span>,
                dataIndex: 'milestoneFlag',
                key: 'milestoneFlag',
                width: 80,
                editable: true,
                render: (value) => value === '1' ? '是' : null
            },
            {
                title: <span style={{marginLeft: 12}}>负责人</span>,
                dataIndex: 'principal',
                key: 'principal',
                width: 100,
                editable: true,
                render: v => convertOptions(v, allUserRes, 'userName','userAccount')
            },
            {
                title: <span className={'receive-department-header'} style={{marginLeft: 12}}>主责单位</span>,
                dataIndex: 'receiveDepartment',
                key: 'receiveDepartment',
                width: 100,
                editable: true,
                render: value => <Tooltip placement="topLeft" title={value}>
                    <span className={'receive-department-value'}>{value}</span>
                </Tooltip>
            },
            {
                title: <span style={{marginLeft: 12}}>优先级</span>,
                dataIndex: 'priority',
                key: 'priority',
                width: 80,
                editable: true,
                render: (value) => convertOptions(value, priorityOptions, 'label')
            },
            {
                title: <span style={{marginLeft: 12}}>标准工时(人日)</span>,
                dataIndex: 'standUseTime',
                key: 'standUseTime',
                width: 130,
                render: (value) => <span style={{marginLeft: 12}}>{value}</span>
            },
            {
                title: <span style={{marginLeft: 12}}>计划开始时间</span>,
                dataIndex: 'planBeginDate',
                key: 'planBeginDate',
                width: 130,
                editable: true,
            },
            {
                title: <span style={{marginLeft: 12}}>计划结束时间</span>,
                dataIndex: 'planEndDate',
                key: 'planEndDate',
                width: 130,
                editable: true,
            },
            {
                title: <span style={{marginLeft: 12}}>计划工时(人日)</span>,
                dataIndex: 'planUseTime',
                key: 'planUseTime',
                width: 130,
                editable: true,
            },
            {
                title: <span style={{marginLeft: 12}}>参与人</span>,
                dataIndex: 'userList',
                key: 'userList',
                width: 160,
                editable: true,
                ellipsis: {
                    showTitle: false,
                },
                render: v => {
                    const showValue = _.join(_.map(v, x => convertOptions(x, allUserRes, 'userName','userAccount')),', ')
                    return <Tooltip placement="topLeft" title={showValue}>
                        {showValue}
                    </Tooltip>
                },
            },
        ])

}

function getImportColumns(props){
    return [
        {header: '阶段', bind: 'segment', width: 120, tooltip: true},
        {header: '任务名称', bind: 'itemName', width: 200, tooltip: true},
        {header: '状态', bind: 'status', width: 100, tooltip: true},
        {header: '里程碑', bind: 'milestoneFlag', width: 100, tooltip: true},
        {header: '负责人', bind: 'principal', width: 160, tooltip: true},
        {header: '优先级', bind: 'priority', width: 100, tooltip: true},
        {header: '标准用时(人日)', bind: 'standUseTime', width: 150, tooltip: true},
        {header: '计划开始时间', bind: 'planBeginDate', width: 150, tooltip: true},
        {header: '计划结束时间', bind: 'planEndDate', width: 150, tooltip: true},
        {header: '计划用时(人日)', bind: 'planUseTime', width: 150, tooltip: true},
        {header: '参与人', bind: 'userList', width: 160, tooltip: true},
    ]
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const optionsUrls = [
    '/common/globalconst?globalConst=PlanStatus',
    '/common/globalconst?globalConst=PRIORITYLIST',
    '/common/globalconst?globalConst=segment',
]

function ImpProjectPlan(props) {
    const {currentId,isPrincipal,updateDynamic, setProjectProgress, refreshList, projectName} = props
    const {initTaskDetailId} = pathSearchFor(props.history.location.search)
    const [showList, setShowList] = useState([])
    const [selectedList, setSelectedList] = useState()
    const [showDialog, setShowDialog] = useState()
    const {doFetch: getList, loading} = useGet()
    const { data: allUserRes } = useGet('/common/userinfo')
    const { data: allOptionsRes } = useGet(optionsUrls)
    const {doFetch: updateRow} = usePost()
    const [currentTaskInfo, setCurrentTaskInfo] = useState(initTaskDetailId ? {mode: 'detail', id: initTaskDetailId} : null)
    const [form] = Form.useForm()

    const exportList = useCallback(()=>{
        api.downloadPost('/implementTask/exportList?'+ strParams({projectId:currentId, projectName: projectName}))
    },[currentId, projectName])

    const taskIdList = useMemo(()=>{
        if(_.isEmpty(showList)) return []
        return _.map(showList, 'id')
    },[showList])

    useEffect(()=>{
        const sum = _.sum(_.map(showList,'standUseTime'))
        const done = _.sum(_.map(_.filter(showList, x => x.status=== "03"),'standUseTime'))
        if(_.toNumber(sum) === 0) return setProjectProgress(0)
        return setProjectProgress(done/sum)
    },[showList, setProjectProgress])

    const [planStatusOptions, priorityOptions, segmentOptions] = useMemo(()=>{
        if(_.isEmpty(allOptionsRes)) return []
        const [d1, d2, d3] = allOptionsRes
        const planStatusAllOptions = _.map(d1, x => ({label: x.displayName, text: x.displayName, value: x.interiorId}))
        const planStatusOptions = _.filter(planStatusAllOptions, x => _.includes(['01','02','03','04','06'],x.value))
        const priorityOptions = _.map(d2, x => ({label: x.displayName, text: x.displayName, value: x.interiorId}))
        const segmentOptions = _.map(d3, x => ({label: x.displayName, text: x.displayName, value: x.interiorId, sortSerial: x.sortSerial}))
        return [planStatusOptions, priorityOptions, segmentOptions]
    },[allOptionsRes])

    const refresh = useCallback(()=>{
        if(_.isNil(currentId)) return
        getList('/implementTask/list?projectId='+currentId).then(res => {
            setShowList(res)
        })
    },[getList, currentId])

    useEffect(()=>{
        refresh()
    },[refresh])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = showList.findIndex(x => x.id === restProps['data-row-key']);
        return <Form form={form} component={false}>
            <ImpProjectEditableContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </ImpProjectEditableContext.Provider>
        </Form>
    },[showList, form])

    const updateEditRow = useCallback((updateItem)=>{
        updateRow('/implementTask/update',updateItem).then(()=>{
            Messager.show('修改成功', { icon: 'success' })
            updateDynamic()
            refreshList()
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[updateRow,updateDynamic,refreshList])

    const handleSave = useCallback((row, bind)=>{
        if(_.get(row,'planBeginDate') && _.get(row,'planEndDate')){
            if(_.includes(['planBeginDate','planEndDate'], bind)){
                if(_.get(row,'planBeginDate') > _.get(row,'planEndDate')){
                    if(bind === 'planBeginDate'){
                        Messager.show('计划开始时间不能晚于计划结束时间')
                    }else{
                        Messager.show('计划结束时间不能早于计划开始时间')
                    }
                    return
                }
            }
        }
        const index = _.findIndex(showList, x => x.id === row.id)
        updateEditRow(row)
        setShowList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return _.sortBy(newList, 'serial')
        })
    },[showList, updateEditRow])

    const columns = useMemo(()=>{
        return _.map(getColumns({setCurrentTaskInfo, allUserRes, planStatusOptions, priorityOptions, segmentOptions, showList, projectId: currentId,isPrincipal}), col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                    planStatusOptions,
                    priorityOptions,
                    segmentOptions,
                    isPrincipal,
                    showList,
                })
            }
        })
    },[handleSave, allUserRes, planStatusOptions, priorityOptions, segmentOptions, showList, currentId,isPrincipal])

    const customizeRenderEmpty = useCallback((component)=>{
        if(component === 'Select') return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        return (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
            {
                isPrincipal &&
                <Button onClick={() => setCurrentTaskInfo({
                    mode: 'add',
                    serial: 1,
                    initFormInfo: {projectId: currentId}
                })}>创建任务</Button>
            }
        </Empty>)
    },[currentId, isPrincipal])

    const selectedMessage = useMemo(()=>{
        return <div className={'selected-message flex center-y'}>
            已选择
            <span className={'selected-count'}>{_.size(selectedList)}</span>
            项
            <span className={'bulk-del-text'} onClick={()=>setShowDialog('delete')}>删除</span>
        </div>
    },[selectedList])

    const del = useCallback(()=>{
        const postParams = {
            projectId: currentId,
            taskIdList: selectedList
        }
        updateRow('/implementTask/delete',postParams).then( cannotDelIdList  => {
            if(_.isEmpty(cannotDelIdList)){
                Messager.show('删除成功' , { icon: 'success' });
                setSelectedList([])
            }else{
                const cannotDelTaskList = _.map(_.filter(showList, x => _.includes(cannotDelIdList,x.id)),'itemName')
                Messager.show(`以下任务已填报工时，不允许删除： ${_.join(cannotDelTaskList,'、')}` , { icon: 'info' });
                setSelectedList(cannotDelIdList)
            }
            refresh();
            setShowDialog(null)
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[updateRow,selectedList,refresh, showList, currentId])

    return <div className={'imp-project-plan flex-y'}>
        <div className="handle-header flex center-y">
            {
                isPrincipal &&
                <div className="handle-group flex">
                    <Button type="primary" onClick={()=>setShowDialog('templateList')}>从模版引入</Button>
                    <Button onClick={exportList}>导出</Button>
                    <Button onClick={()=>setShowDialog('import')}>导入</Button>
                </div>
            }
            {
                !_.isEmpty(selectedList) &&
                <Alert message={selectedMessage} type="info" showIcon/>
            }
        </div>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
                loading={loading}
                size={'small'}
                rowClassName={() => isPrincipal?'editable-row':''}
                sticky={true}
                pagination={false}
                dataSource={showList}
                columns={columns}
                rowKey="id"
                rowSelection={isPrincipal?
                    {
                        onChange: setSelectedList
                    }: null}
                scroll={{x: 950,y: 410}}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: EditableRow,
                        cell: EditableCell
                    },
                }}
            />
        </ConfigProvider>
        {
            showDialog === 'templateList' && <ImpProjectTemplateListDialog close={()=>setShowDialog(null)} projectId={currentId} {...{taskIdList,refresh}}/>
        }
        {
            showDialog === 'import' &&
            <ImportApiDialog close={()=>setShowDialog(null)} template={`/implementTask/download`}
                             defaultOptions={getImportColumns()} refresh={refresh} importUrl={'/implementTask/upload'} title={'实施项目任务'}
                             abnormal={'/implementTask/export'} exportFieldname={'ImplementTaskVos'}/>
        }
        {
            showDialog === 'delete' && <MessageBox header={'提示'} confirm={del} cancel={()=>setShowDialog(null)} className={'common-del-message-dialog content-center-dialog'}>
                确认删除选中任务吗？
            </MessageBox>
        }
        {
            currentTaskInfo && <ImpTaskInfoDialog close={()=>setCurrentTaskInfo(null)} outerUserRes={allUserRes} listRefresh={refresh} projectId={currentId} refreshViewList={refreshList}
                                                  {...{currentTaskInfo, planStatusOptions, priorityOptions, segmentOptions, isPrincipal,showList,setCurrentTaskInfo}}/>
        }
    </div>

    function checkDragPosition({oldIndex, newIndex}){
        const currentSegment = _.get(showList[oldIndex],'segment')
        const currentSegmentSortSerial = _.get(_.find(segmentOptions, x => x.value ===  currentSegment),'sortSerial')
        if(newIndex === 0){
            const nextSegment = _.get(showList[newIndex],'segment')
            const nextSegmentSortSerial = _.get(_.find(segmentOptions, x => x.value ===  nextSegment),'sortSerial')
            return currentSegmentSortSerial <= nextSegmentSortSerial
        }else if(newIndex + 1 === _.size(showList)){
            const previousSegment = _.get(showList[newIndex],'segment')
            const previousSegmentSortSerial = _.get(_.find(segmentOptions, x => x.value ===  previousSegment),'sortSerial')
            return previousSegmentSortSerial <= currentSegmentSortSerial
        }else{
            const previousSegment = _.get(showList[oldIndex < newIndex? newIndex: newIndex - 1],'segment')
            const previousSegmentSortSerial = _.get(_.find(segmentOptions, x => x.value ===  previousSegment),'sortSerial')
            const nextSegment = _.get(showList[oldIndex < newIndex? newIndex + 1: newIndex],'segment')
            const nextSegmentSortSerial = _.get(_.find(segmentOptions, x => x.value ===  nextSegment),'sortSerial')
            return previousSegmentSortSerial <= currentSegmentSortSerial && currentSegmentSortSerial <= nextSegmentSortSerial
        }
    }

    function onSortEnd({ oldIndex, newIndex }){
        if (oldIndex !== newIndex) {
            if(!checkDragPosition({ oldIndex, newIndex })){
                return Messager.show('不允许跨阶段调整任务顺序')
            }
            if (oldIndex < newIndex) {
                const previous = showList[newIndex].serial
                const next = _.get(showList, `[${newIndex + 1}]serial`)
                showList[oldIndex].serial = _.isNil(next) ? previous + 1 : (previous + next) / 2
            }else if (oldIndex > newIndex) {
                const previous = _.get(showList, `[${newIndex - 1}]serial`, 0)
                const next = showList[newIndex].serial
                showList[oldIndex].serial = (previous + next) / 2
            }
            updateRow('/implementTask/update', _.pick(showList[oldIndex], ['id', 'serial']))
                .catch(err => Messager.show(err._message, { icon: 'error' }))
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setShowList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, planStatusOptions, priorityOptions, segmentOptions, isPrincipal,showList, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(ImpProjectEditableContext) || {}

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const getSegmentSort = useCallback((value)=>{
        return _.get(_.find(segmentOptions, x => x.value === value),'sortSerial')
    },[segmentOptions])

    const toggleEdit = useCallback(()=>{
        if(!isPrincipal) return setEditing(false)
        setEditing(x => !x);
        let value
        if(_.includes(['planBeginDate','planEndDate'],dataIndex)){
            value = _.isNil(record[dataIndex]) ? null :moment(record[dataIndex], 'YYYY-MM-DD')
        }else{
            value = record[dataIndex]
        }
        form.setFieldsValue({ [dataIndex]:  value });
    },[dataIndex, record, form, isPrincipal])

    const save = () => {
        try {
            form.validateFields().then((changeObj)=>{
                const key = _.head(_.keys(changeObj))
                if(key === 'receiveDepartment'){
                    if(!changeObj[key]){
                        return Messager.show('主责单位不能为空',{icon: 'error'})
                    }
                }
                if(_.includes(['planBeginDate','planEndDate'],key)){
                    changeObj[key] = _.isNil(changeObj[key]) ? null :moment(changeObj[key]).format('YYYY-MM-DD')
                }
                let replaceParams = {}
                if(key === 'principal'){
                    let newUserList = record['userList']
                    if(!_.isNil(record['principal'])){
                        newUserList = _.filter(newUserList, x => x !== record['principal'])
                    }
                    newUserList = _.union(newUserList, [changeObj['principal']])
                    replaceParams = {userList: newUserList}
                }

                if(key === 'userList'){
                    if(!_.isNil(record['principal'])){
                        if(!_.includes(changeObj['userList'], record['principal'])){
                            setEditing(false)
                            return Messager.show('负责人必须为参与人')
                        }
                    }
                }

                if(key === 'segment'){
                    const listIndex = _.findIndex(showList, x => x.id === record['id'])
                    let normalSort
                    if(listIndex + 1 !== _.size(showList)){
                        normalSort = getSegmentSort(changeObj['segment']) <= getSegmentSort(_.get(showList[listIndex+1],'segment'))
                            && getSegmentSort(changeObj['segment']) >= getSegmentSort(_.get(showList[listIndex],'segment'))
                    }else{
                        normalSort = false
                    }
                    if(!normalSort){
                        const biggerSortItem = _.find(showList, x => getSegmentSort(_.get(x, 'segment')) > getSegmentSort(changeObj['segment']))
                        if(_.isNil(biggerSortItem)){
                            replaceParams = {serial : _.toNumber(_.get(_.last(showList),'serial')) + 1}
                        }else{
                            const biggerSortItemIndex = _.findIndex(showList, x => x.id === biggerSortItem.id)
                            if(biggerSortItemIndex === 0){
                                replaceParams = {serial : _.toNumber(_.get(biggerSortItem,'serial')) / 2}
                            }else{
                                replaceParams = {serial : (_.toNumber(_.get(showList[biggerSortItemIndex-1],'serial')) + _.toNumber(_.get(biggerSortItem,'serial'))) / 2}
                            }
                        }
                    }
                }
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    handleSave({ ...record, ...changeObj, ...replaceParams }, key);
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            });
        } catch (errInfo) {
            console.error(errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        if(editing){
            let editNode
            if(dataIndex === 'segment'){
                editNode = (<Select ref={inputRef} options={segmentOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(dataIndex === 'status'){
                editNode = (<Select ref={inputRef} options={planStatusOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(dataIndex === 'priority'){
                editNode = (<Select ref={inputRef} options={priorityOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(dataIndex === 'milestoneFlag'){
                editNode = (<Select ref={inputRef} options={milestoneFlagOptions} onSelect={save} allowClear defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(_.includes(['planBeginDate','planEndDate'],dataIndex)){
                editNode = (<DatePicker ref={inputRef} onChange={save} allowClear={false} open={true} onBlur={()=>setEditing(false)} autoFocus />)
            }else if(dataIndex === 'principal'){
                editNode = (<UserSelect ref={inputRef} onChange={save} allowClear={false} defaultOpen={true} onBlur={()=>setEditing(false)} style={{width: 100}}/>)
            }else if(dataIndex === 'receiveDepartment'){
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }else if(dataIndex === 'userList'){
                editNode = (<UserSelect ref={inputRef} multiple onChange={save} defaultOpen={true} onBlur={()=>setEditing(false)} />)
            }else if(_.includes(['standUseTime','planUseTime'],dataIndex)){
                editNode = (<InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} step={0.1} formatter={limitDecimals}
                                         parser={limitDecimals}/>)
            }else{
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </Form.Item>
            )
        }else{
            childNode =(
                <div className="imp-plan-editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }

    return <td {...restProps}>{childNode}</td>;
}
const limitDecimals = (value) => {
    if(typeof value === 'string') {
        return _.floor(_.toNumber(value),1)
    } else if (typeof value === 'number') {
        return _.floor(value, 1)
    } else {
        return ''
    }
};

export default ImpProjectPlan;
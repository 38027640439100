import React, { useState } from 'react';
import { Button } from 'rootnet-ui'
import { Select, Form, FormInput, Input, } from 'rootnet-edit';
import { addAll } from '../../../../utils/jsondata'

function Options(props) {
    const { setParams, initParams, opts } = props
    const [devClientOpt, proManagerOpt, presalesProStatusOpt] = opts
    const { custId, projectName, principal, projectStatusList } = initParams
    const [value, setValue] = useState(initParams)
    return <div className="x rims_options">
        <Form onChange={setValue} value={value}>
            <FormInput label="客户" component={Select} bind="customer" options={addAll(devClientOpt)} defaultValue={custId} search />
            <FormInput label="项目名称" component={Input} bind="projectName" search defaultValue={projectName} />
            <FormInput label="项目经理" component={Select} bind="principal" search options={proManagerOpt} defaultValue={principal} clear />
            <FormInput label="项目状态" component={Select} bind="projectStatusList" multiple options={presalesProStatusOpt} defaultValue={projectStatusList} />
        </Form>
        <Button style={{ marginLeft: 0 }} className='btn' primary onClick={() => { setParams(value) }}>查询</Button>
    </div>

}
export default Options;

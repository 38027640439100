import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from "lodash";
import { Messager, Loader } from 'rootnet-ui'
import { useGet, usePost } from "rootnet-biz/es/hooks";
import { API2 } from "rootnet-core/base";
import { Empty, Modal, Upload } from "antd";
import { strParams } from '../../../../utils/publicFun';
import { useApi } from '../../../../utils/hook';
import gd from '../../../../base/global';
import './TestPlanStageUploadArea.scss'

function TestPlanStageUploadArea(props) {
  const { id, stageDocList, stageObj, updateDoc, setShowList, stageIndex, setStageDocList, setEditFormData, funcCode } = props
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const { doFetch } = useApi()
  const fileAction = useRef()
  const { data: fileListRes, doFetch: getFileList } = useGet()
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState(false)
  const { doFetch: updateStage } = usePost()
  const [inited, setInited] = useState(false)

  const refresh = useCallback((newStageDocList) => {
    const queryParams = {
      referenceId: id,
      funcCode
    }
    getFileList('/mapping/files/queryNew?' + strParams(queryParams)).then(res => {
      const handleList = _.map(res, x => ({
        ...x,
        originalName: x.name,
        name: x.fullName
    }))
      setFileList(handleList)
    })
  }, [getFileList, id, funcCode])

  useEffect(() => {
    if (inited) return
    if (_.isNil(id)) return
    refresh()
    setInited(true)
  }, [id, refresh, inited])

  const handleCancel = useCallback(() => {
    setPreviewVisible(false)
  }, [])

  const handlePreview = useCallback(async (file) => {
    if (!isImg(_.get(file, 'fullName'))) return
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }, [])

  const handleChange = useCallback(({ file, fileList: newFileList }) => {
    if (!_.isNil(_.get(file, 'url')) || !_.isNil(_.get(file, 'response.url'))) {
      const currentItem = ({
        uid: _.get(file, 'response.uid') || _.get(file, 'uid'),
        type: _.get(file, 'type'),
        name: _.get(file, 'response.name') || _.get(file, 'name'),
        status: 'done',
        url: _.get(file, 'response.url') || _.get(file, 'url'),
      })
      const url = fileAction.current === 'add' ? '/mapping/files/add' : '/mapping/files/delete'
      let postParams
      if (fileAction.current === 'add') {
        postParams = [{ ...currentItem, referenceId: id, funcCode }]
      } else {
        const uid = _.get(file, 'response.uid') || _.get(file, 'uid')
        const fileId = _.get(_.find(fileListRes, x => x.uid === uid), 'id')
        postParams = [fileId]
      }
      doFetch(url, 'post', postParams).then(() => {
        Messager.show(fileAction.current === 'add' ? '添加成功' : '删除成功', { icon: 'success' });

        const uid = _.get(file, 'response.uid') || _.get(file, 'uid')
        const newStageDocList = fileAction.current === 'add' ? _.concat([], stageDocList || [], [uid]) : _.filter(stageDocList, x => x !== uid)
        const newStage = {
          ...stageObj,
          relatedocument: _.join(newStageDocList, ',')
        }
        updateStage('/story/stage/update', newStage).then(() => {
          setShowList(oldList => {
            return _.map(oldList, (item, i) => {
              if (i !== stageIndex) return item
              return ({
                ...item,
                relatedocument: _.join(newStageDocList, ',')
              })
            })
          })
          if (setEditFormData) {
            setEditFormData(old => ({
              ...old,
              relatedocument: _.join(newStageDocList, ',')
            }))
          }

          if (setStageDocList) {
            setStageDocList(newStageDocList)
          }
          updateDoc()
          setLoading(false)
          refresh(newStageDocList)
        }).catch((err) => {
          Messager.show(err._message, { icon: 'error' })
        })
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' })
      })
    }
    const postFileList = _.map(newFileList, x => ({
      id: _.get(x, id),
      uid: _.get(x, 'response.uid') || _.get(x, 'uid'),
      type: _.get(x, 'type'),
      name: _.get(x, 'response.name') || _.get(x, 'name'),
      status: 'done',
      url: _.get(x, 'response.url') || _.get(x, 'url'),
    }))
    setFileList(postFileList)
  }, [fileAction, doFetch, id, refresh, fileListRes, updateDoc, stageDocList, stageObj, updateStage, stageIndex, setShowList, setStageDocList, setEditFormData, funcCode])

  const showUploadList = useMemo(() => {
    return ({
      showPreviewIcon: true,
      showDownloadIcon: true,
      showRemoveIcon: false,
    })
  }, [])

  const checkFileSize = useCallback((file) => {
    if (file.size / 1024 / 1024 < 30) {
      return true
    } else {
      Messager.show('上传文件大小不超过30M', { icon: 'error' });
      refresh()
      return false
    }
  }, [refresh])

  const customRequest = useCallback((options) => {
    setLoading(true)
    fileAction.current = 'add'
    const { onSuccess, onError, file } = options;
    if (!checkFileSize(file)) {
      return setLoading(false)
    }
    const fileData = new FormData()
    fileData.append('file', file)
    let postParams = {
      bucketName: 'requirement',
      createUser: gd._user.operator_name,
    }
    API2.post('/minioCommon/uploadFile?' + strParams(postParams), fileData).then(res => {
      const fileItem = {
        uid: res.data.id,
        name: `${res.data.name}${res.data.ext}`,
        status: 'done',
        url: res.data.url,
      }

      onSuccess(fileItem)
    }).catch(err => {
      onError()
      setLoading(false)
      Messager.show(err._message, { icon: 'error' });
    })
  }, [checkFileSize])

  const onDownload = useCallback((file) => {
    const url = _.get(file, 'url') || _.get(file, 'response.url')
    window.open(url, "_self")
  }, [])

  const onRemove = useCallback((file) => {
    fileAction.current = 'remove'
    const postParams = {
      id: _.get(file, 'response.uid') || _.get(file, 'uid'),
      updateUser: gd._user.operator_name,
    }
    API2.post('/minioCommon/removeFile', postParams).then(() => {
      return true
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      return false
    })
  }, [])


  return <div className={`test-plan-stage-upload-area flex-y ${_.isEmpty(fileList) ? 'empty-field-list' : ''}`}>
    <Upload
      customRequest={customRequest}
      listType="picture"
      fileList={fileList}
      onPreview={handlePreview}
      onChange={handleChange}
      onDownload={onDownload}
      onRemove={onRemove}
      showUploadList={showUploadList}
    >
      {/* <UploadButton /> */}
    </Upload>
    <Modal
      visible={previewVisible}
      title={previewTitle}
      footer={null}
      onCancel={handleCancel}
      zIndex={3000}
      style={{maxHeight: '100%', maxWidth: '96%', top: 10}}
      width={'unset'}
    >
      <img alt="example" style={{ width: '100%' }} src={previewImage} />
    </Modal>
    {loading && <Loader fill />}
    {_.isEmpty(fileList) && !loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
  </div>
}

function isImg(name) {
  let idx = _.lastIndexOf(name, '.')
  if (idx === -1) {
    console.warn('name格式不正确，请检查');
    return;
  }
  let type = name.substr(idx + 1);
  const imgArr = [
    'bmp', 'jpg', 'png', 'tif', 'gif',
    'pcx', 'tga', 'exif', 'fpx', 'svg',
    'psd', 'cdr', 'pcd', 'dxf', 'ufo',
    'eps', 'ai', 'raw', 'WMF', 'webp'
  ];
  return imgArr.indexOf(type) !== -1;
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default TestPlanStageUploadArea;


import React, { useCallback, useContext, useState } from 'react'
import { Dialog } from 'rootnet-ui'
import { DatePicker } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { ScheduleManagementContext } from '../../../common/Context'
import { strParams } from '../../../../utils/publicFun'

const { str14ToDate } = toDate

export default function ExportWeekly(props) {
  const { userAccount, beginDate, endDate, setIsExport } = props
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState({ begin: str14ToDate(beginDate + '000000'), end: str14ToDate(endDate + '235959') })

  const confirm = useCallback(() => {
    const { begin, end } = params
    const beginDate = dateFormat('YYYY-MM-DD HH:MM:SS', begin)
    const endDate = dateFormat('YYYY-MM-DD', end) + ' 23:59:59'
    downloadGetProgress(`/worktime/exportWeekly?${strParams({ beginDate, endDate, userAccount })}`, '周报')
    setIsExport(false)
  }, [downloadGetProgress, params, setIsExport, userAccount])

  return (
    <Dialog
      header='导出周报'
      confirm={confirm}
      cancel={() => setIsExport(false)}
      confirmButtonText='导出'
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0 }}
    >
      日期区间：<DatePicker range style={{ width: 200 }} value={params} onChange={setParams} />
    </Dialog >
  )
}

import React from 'react'
import { Dialog } from 'rootnet-ui'
import CustomerInfoDetailContent from './customerInfoDetailContent'
import './index.scss'

export default function CustomerInfoDetail(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog className={'customer-info-detail-dialog'} headerVisible={false} footerVisible={false}>
      <CustomerInfoDetailContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

import React from 'react'
import { Dialog } from 'rootnet-ui'
import { TextArea } from 'rootnet-ui'

export default function DevstatusExplainDialog(props) {
  const { submitExplain, close, cancelDescription, setCancelDescription } = props

  return (
    <Dialog
      header={'裁剪/合并说明'}
      confirm={submitExplain}
      cancel={close}
    >
      <TextArea
        counter={false}
        maxLength={5000}
        value={cancelDescription} onChange={setCancelDescription}
      />
    </Dialog>
  )
}

import _ from "lodash"
import { isNil } from "rootnet-core/format"
import { uniqKeyFor } from "../../../../../../../project_share/utils/utils"

/*
  basicVersionList 基础版本信息
  currentVersionList 当前版本信息
  releaseInfoList 产品信息
 */

export default function formattedData(props) {
  const { basicVersionList, currentVersionList, releaseInfoList } = props
  //isBasics
  const basicVersionData = _.map(basicVersionList, o => _.assign({}, o, { isBasics: true })) //基础版本
  const currentVersionData = _.map(currentVersionList, o => _.assign({}, o, { isBasics: false })) //当前版本
  const initData = mergeData({ basicVersionData, currentVersionData, releaseInfoList })
  const listData = _.concat(..._.map(_.groupBy(initData, 'productId'), (d, k) => {
    return _.orderBy(_.map(d, (o, i) => {
      return _.assign({}, o, { colspan: i, id: uniqKeyFor() })
    }), 'colspan', 'asc')
  }))
  return listData
}


function findName(data, val) {
  const findData = _.find(data, o => o.productId === val)
  return `${_.get(findData, 'productId')}-${_.get(findData, 'productName')}`
}

function mergeData({ basicVersionData, currentVersionData, releaseInfoList }) {
  const data = _.concat(basicVersionData, currentVersionData)
  return _.concat(..._.map(_.groupBy(data, 'productId'), (d, k) => {
    return _.concat(..._.map(_.groupBy(d, 'subSysId'), data => {// relateReleaseId
      return _.concat(..._.map(_.groupBy(data, 'relateReleaseId'), list => {
        return _.assign({}, ..._.map(list, (o, i) => {
          if (o.isBasics) {
            return _.assign({}, {
              productName: findName(releaseInfoList, o.productId),
              subSysName: isNil(o.subSysId) ? '' : `${o.subSysId}-${o.subSysName}`,
              basicsVersions: o.relateReleaseId,
              basicsRecommend: o.recommendFlag,
              basicsState: o.state,
            }, _.pick(o, ['productId', 'subSysId', 'updateTime', 'updateUser']))
          } else {
            return _.assign({}, {
              productName: findName(releaseInfoList, o.productId),
              subSysName: isNil(o.subSysId) ? '' : `${o.subSysId}-${o.subSysName}`,
              currentVersions: o.relateReleaseId,
              currentRecommend: o.recommendFlag,
              currentState: o.state,
            }, _.pick(o, ['productId', 'subSysId', 'updateTime', 'updateUser', 'relateRelease']))
          }
        }))
      }))
    }))
  }))
}
import React, {useMemo, useState} from 'react';
import {Button} from 'rootnet-ui'
import {FormInput, Form, DatePicker, Select} from 'rootnet-edit'
import './Options.scss'
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {useGet} from "../../../utils/hook";
import _ from 'lodash'
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";

const {str8ToDate} = toDate

const RFormInput = (props) => <FormInput componentWidth={150} {...props}/>

const OPTIONS_URLS = [
    '/common/userinfo',
    '/customer/customerList',
    '/common/globalconst?globalConst=PSAProjectType'
]

function Options(props) {
    const {initParams, search, projectStatusOptions,getInitParams} = props
    const [params, setParams] = useState(initParams)
    const [isFold, setIsFold] = useState(true)
    const {data: optionsRes} = useGet(OPTIONS_URLS)

    const [userOptions,customerOptions, projectTypeOptions] = useMemo(()=>{
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3] = optionsRes
        return [
            _.map(d1, v => ({ value: v.userAccount, text: v.userName, tag: `${v.userAccount}${v.userName}` })),
            _.map(d2, v => ({ text: v.tCustname, value: v.tCustid, tag: `${v.tCustname}${v.tCustid}` })),
            convertGlobalConstOptions(d3)
        ]
    },[optionsRes])

    return <div className={`contract-options ${isFold? 'options-show-one-line':''}`}>
        <div className="c-options options-with-reset">
            <Form value={params} onChange={setParams}>
                <RFormInput label='合同编号' bind='contractNum'/>
                <RFormInput label='合同名称' bind='contractName'/>
                <RFormInput label='项目名称' bind='projectName'/>
                <RFormInput label='合同签署开始日期' bind='beginDate' component={DatePicker} bindInConvert={str8ToDate} bindOutConvert={v => dateFormat('YYYYMMDD',v)} clear/>
                <RFormInput label='合同签署截止日期' bind='endDate' component={DatePicker} bindInConvert={str8ToDate} bindOutConvert={v => dateFormat('YYYYMMDD',v)} clear/>
            </Form>
            <div className='fold-text' onClick={()=>setIsFold(x => !x)}>{isFold?'展开':'收起'}</div>
            <Button style={{ marginLeft: 8}} primary onClick={() => search(params)}>查询</Button>
            <Button style={{ marginLeft: 6, border: '1px solid #7A8199'}} text onClick={() => {
                setParams(getInitParams())
                search(getInitParams())
            }}>重置</Button>
        </div>
        <div className="extra-options">
            <Form value={params} onChange={setParams}>
                <RFormInput label='负责人' bind='principal' component={Select} search options={userOptions} clear/>
                <RFormInput label='客户' bind='customer' component={Select} search options={customerOptions} clear/>
                <RFormInput label='项目ID' bind='id'/>
                <RFormInput label='项目状态' bind='projectStatus' component={Select} options={projectStatusOptions} clear/>
                <RFormInput label='项目分类' bind='projectType' component={Select} options={projectTypeOptions} clear/>
            </Form>
        </div>
    </div>
}

export default Options;
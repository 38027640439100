import React, {useCallback, useMemo, useState} from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import {DataGrid, Messager, Pagination} from 'rootnet-ui'
import convertTableAlign from '../../../../../common/view/convertTableAlign'
import findConvert from '../../../../../common/view/findConvert'
import useGetViewConfig from '../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../../common/view/hooks/useGetTreeList'
import ViewArea from '../../../../../common/view/ViewArea'
import { Box } from '../../../../../common/commonComponent'
import './index.scss'
import { strParams } from '../../../../../../utils/publicFun'
import {API1} from "../../../../../../base/task";
import {TextIconBtn} from "../../../../../common/TextIconBtn";

const ENTER_DETAIL_FIELD = 'projectName'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === 'bug_project.status') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <div className={'common-display-field work-flow-status-field'}
        style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4, cursor: 'auto' }}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          window.open(`#/customerSystemMgt?${strParams({ initId: o.id })}`)
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }}>
      {showValue || '-'}
    </div>
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '250102'

export default function SystemListing(props) {
  const { customerId } = props
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    return ({ 'bug_project.projectId': customerId, })
  }, [customerId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns })

  const exportViewList = useCallback(()=>{
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    Messager.show('导出中...')
    API1.downloadPost('/UserSetting/exportExcel',postParams)
  },[currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList}/>
    </div>
  }, [exportViewList])

  return (
    <div className='customer-system-listing'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={'系统清单'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </div>
  )
}

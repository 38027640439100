import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './DataAuthMgt.scss'
import {Select} from "rootnet-edit";
import {Button, Card, Messager} from "rootnet-ui";
import {BaseTable, useTablePipeline, features} from "ali-react-table";
import _ from "lodash";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertOptions from "../../common/ConvertOptions";
import {Icon} from "../../../project_share/components";
import {Checkbox, Tooltip} from "antd";
import {TextIconBtn} from "../../common/TextIconBtn";
import {isNil} from "rootnet-core/format";
import {usePost} from "rootnet-biz/lib/hooks";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import DataAuthAddRole from "./dataAuthAddRole/DataAuthAddRole";
import DelMessage from "../../../components/DelMessage";
import UserDeptGroupSelect from "../../common/userDeptGroupSelect/UserDeptGroupSelect";

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=roleID&codeName=roleName&tableName=uac_role',
]

function DataAuthMgt(props) {
    const [params, setParams] = useState()
    const [tempParams, setTempParams] = useState()
    const [openKeys, setOpenKeys] = useState()
    const { data: allUserRes } = useGet('/common/userinfo')
    const { data: allDeptRes } = useGet('/common/department?activeFlag=Y')
    const [editInfo, setEditInfo] = useState()
    const {data: roleOptionsRes, doFetch: getRoleList} = useGet()
    const [editData, setEditData] = useState()
    const editDataRef = useRef()
    const [hiddenUser, setHiddenUser] = useState(true)
    const [hiddenLeave, setHiddenLeave] = useState(true)
    const [showAdd, setShowAdd] = useState(false)
    const {data: listRes, doFetch: getList} = usePost()
    const {doFetch: submitPost} = usePost()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [roleList, setRoleList] = useState()
    const [delRoleId, setDelRoleId] = useState()

    const [allRoleOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    useEffect(()=>{
        let roleList = [
            {
                roleId: 'Manager',
                roleName: '部门经理',
            },
        ]
        _.forEach(listRes, item => {
            _.forEach(_.get(item,'info'), x => {
                const roleIdList = _.map(roleList, 'roleId')
                if(!_.includes(roleIdList, x.roleId)){
                    roleList.push({
                        roleId: x.roleId,
                        roleName: x.roleName,
                    })
                }
            })
        })
        setRoleList(_.reverse(roleList))
    },[listRes])

    const searchUniqIdList = useMemo(()=>{
        return _.compact(_.union(..._.map(params, 'valueList')))
    },[params])

    const refreshDetail = useCallback(()=>{
        getList('/dataPermission/list',_.isEmpty(searchUniqIdList) ? []: searchUniqIdList).catch(err => {
            Messager.show(err.Message, { icon: 'error' })
        })
    },[getList, searchUniqIdList])

    useEffect(()=>{
        refreshDetail()
    },[refreshDetail])

    useEffect(()=>{
        if(_.isNil(editInfo)) return
        getRoleList('/common/uacUserRole?roleId='+_.get(editInfo,'role'))
    },[getRoleList, editInfo])

    const roleOptions = useMemo(()=>{
        return _.map(roleOptionsRes, x => ({
            value: x.userAccount,
            text: convertOptions(x.userAccount, allUserRes, 'userName','userAccount') || x.userAccount,
            tag: x.userAccount,
        }))
    },[roleOptionsRes, allUserRes])

    const handleData = useMemo(()=>{
        const companyItem = {
            type: 'dept',
            departMentId: 'ALL',
            departMentName: '北京根网科技有限公司',
            parentDept: null,
        }
        const allDeptResWithCompany = _.compact(_.concat(companyItem, allDeptRes))
        let deptList = _.map(allDeptResWithCompany, item => {
            const listItem = _.find(listRes, x => x.uniqId === item.departMentId)
            const handleItem = {
                type: 'dept',
                departmentId: item.departMentId,
                departmentName: item.departMentName,
                departmentPid: item.parentDept,
                dataRange: item.departMentName,
                uniqId: item.departMentId,
            }
            handleItem['info'] = _.get(listItem,'info')
            handleItem['Manager'] = isNil(_.get(item,'manager')) ? [] : [_.get(item,'manager')]
            _.forEach(_.get(listItem, 'info'), x => {
                handleItem[x.roleId] = _.map(_.get(x,'userIdList'),'userId')
            })
            return handleItem
        })
        if(hiddenUser){
            return deptList
        }
        let userList = _.map(allUserRes, item => {
            const listItem = _.find(listRes, x => x.uniqId === item.userAccount)
            const handleItem = {
                type: 'user',
                userAccount: item.userAccount,
                userName: item.userName,
                userDepartment: item.department,
                indexValidFlag: item.indexValidFlag,
                dataRange: item.userName,
                uniqId: item.userAccount,
            }
            handleItem['info'] = _.get(listItem,'info')
            handleItem['Manager'] = isNil(_.get(item,'departMentManager')) ? [] : [_.get(item,'departMentManager')]
            _.forEach(_.get(listItem, 'info'), x => {
                handleItem[x.roleId] = _.map(_.get(x,'userIdList'),'userId')
            })
            return handleItem
        })
        if(hiddenLeave){
            const effectiveUserList = _.filter(userList, x => x.indexValidFlag === 1)
            return _.compact(_.concat(deptList, effectiveUserList))
        }else{
            return _.compact(_.concat(deptList, userList))
        }
    },[hiddenUser, hiddenLeave, allDeptRes, listRes, allUserRes])

    const treeData = useMemo(()=>{
        if(_.isNil(handleData)) return []
        if(!_.isEmpty(searchUniqIdList)){
            return _.filter(handleData, x => _.includes(searchUniqIdList, x.uniqId))
        }
        const parentDeptList = _.filter(handleData, x => x.type === 'dept' && isNil(_.get(x,'departmentPid')))
        buildTree(parentDeptList)
        const deptIdList = _.map(_.filter(handleData, x => x.type === 'dept'),'departmentId')
        const noDeptUser = _.filter(handleData, x => x.type === 'user' && !_.includes(deptIdList,x.userDepartment))
        function buildTree(parentList){
            _.forEach(parentList, deptItem => {
                const childrenUserList = _.filter(handleData, x => x.type === 'user' && _.get(x,'userDepartment') === deptItem.departmentId)
                const childrenDeptList = _.filter(handleData, x => x.type === 'dept' && _.get(x,'departmentPid') === deptItem.departmentId)
                const childrenList = _.compact(_.concat(childrenUserList, childrenDeptList))
                if(_.isNil(childrenList)) return
                deptItem['children'] = childrenList
                buildTree(deptItem['children'])
            })
        }
        return _.compact(_.concat(parentDeptList, noDeptUser))
    },[handleData, searchUniqIdList])

    useEffect(()=>{
        // 默认展开两层
        if(_.isNil(allDeptRes)) return
        const parentDeptList = _.filter(allDeptRes, x => isNil(_.get(x,'parentDept')))
        const parentDeptIdList = _.map(parentDeptList, 'departMentId')
        setOpenKeys(parentDeptIdList)
    },[allDeptRes])

    useEffect(()=>{
        editDataRef.current =editData
    },[editData])

    const submit = useCallback(()=>{
        if(_.isEqual(editDataRef.current, editInfo.initValue)){
            return setEditInfo(null)
        }
        let submitParams = []
        const oldValue = editInfo.initValue
        const newValue = editDataRef.current
        const addList = _.filter(newValue, x => !_.includes(oldValue, x))
        if(!_.isEmpty(addList)){
            _.forEach(addList, x => {
                submitParams.push({
                    optType: 'add',
                    uniqId: editInfo.uniqId,
                    type: editInfo.type,
                    roleId: editInfo.role,
                    userId: x,
                })
            })
        }
        const delList = _.filter(oldValue, x => !_.includes(newValue,x))
        if(!_.isEmpty(delList)){
            const findItem = _.find(handleData, x => x.uniqId === editInfo.uniqId)
            const findRoleInfo = _.find(_.get(findItem,'info'), x => x.roleId === editInfo.role)
            const oldUserIdList = _.get(findRoleInfo, 'userIdList')
            _.forEach(delList, x => {
                submitParams.push({
                    optType: 'del',
                    id: convertOptions(x, oldUserIdList, 'id','userId')
                })
            })
        }
        submitPost('/dataPermission/update',submitParams).then(()=>{
            setEditInfo(null)
            Messager.show("修改成功", { icon: 'success' })
            refreshDetail()
        }).catch(err => {
            Messager.show(err.Message, { icon: 'error' })
        })
    },[editInfo, handleData, submitPost, refreshDetail])

    const columns = useMemo(()=>{
       return [
           { code: 'dataRange', name: '数据范围', width: 300 },
           ..._.map(roleList, item => ({
               code: item.roleId,
               title: <div className={'table-header-cell flex center-y'}>
                   {item.roleName}
                   {
                       !_.includes(['Manager'],item.roleId) &&
                       <Icon name={'shanchu'} className={'del-icon'} onClick={()=>{
                           setDelRoleId(item.roleId)
                       }}/>
                   }
               </div>,
               width: 200,
               render: (v, o) => {
                   const isEditing = _.get(editInfo,'uniqId') === o.uniqId && _.get(editInfo,'role') === item.roleId
                   const showText = _.join(_.map(v, x => convertOptions(x, allUserRes, 'userName','userAccount')), '、')
                   const filterData = _.map(_.filter(v,val=>!_.includes(_.map(roleOptions,'value'),val)),val=>({text:convertOptions(val, allUserRes, 'userName','userAccount'),value:val}))
                    const concatroleOptions = concatData(_.concat(roleOptions, filterData), allUserRes)

                   return <div className={'role-table-cell flex center-y'}>
                       {
                           !isEditing &&
                           <Tooltip title={showText} placement="topLeft">
                               <div className={'detail-cell one-line-text'}>
                                   {showText}
                               </div>
                           </Tooltip>
                       }
                       {
                           !isEditing && (item.roleId !== 'Manager') &&
                           <Icon name={'bianji2'} className={'edit-icon'} onClick={()=>{
                               setEditInfo({
                                   type: o.type,
                                   uniqId: o.uniqId,
                                   role: item.roleId,
                                   initValue: _.get(o,item.roleId) || []
                               })
                               setEditData(_.get(o,item.roleId) || [])
                           }}/>
                       }
                       {
                           isEditing &&
                           <Select defaultValue={_.get(editInfo,'initValue')} multiple onChange={setEditData} options={concatroleOptions} search onBlur={submit} autoFocus/>
                       }
                   </div>
               }
           }))
       ]
    },[allUserRes, editInfo, roleOptions, submit, roleList])

    const pipeline = useTablePipeline()
        .input({ dataSource: treeData, columns })
        .primaryKey('uniqId')
        .use(features.treeMode({
            openKeys,
            onChangeOpenKeys: setOpenKeys
        }))

    const extra = useMemo(()=>{
       return <div className={'extra-wrap flex'}>
           <Checkbox onChange={e => setHiddenUser(e.target.checked)} checked={hiddenUser}>隐藏用户</Checkbox>
           <Checkbox onChange={e => setHiddenLeave(e.target.checked)} checked={hiddenLeave}>隐藏离职</Checkbox>
           <TextIconBtn icon='tianjia' text='新增' onClick={() => setShowAdd(true)} />
       </div>
    },[hiddenUser, hiddenLeave])

    const onAddRole = useCallback((roleId)=>{
        const currentRole = _.find(allRoleOptions, x => roleId === x.value)
        const addItem = {
            roleId: currentRole.value,
            roleName: currentRole.text,
        }
        setRoleList(old => {
            return _.concat(addItem, old)
        })
        setShowAdd(false)
    },[allRoleOptions])

    return <div className={'data-auth-mgt fill flex-y'}>
        <div className={'c-options flex center-y'}>
            <UserDeptGroupSelect value={tempParams} onChange={setTempParams} multiple componentWidth={400}
                                 canSelectGroup={false} allowClear={true} style={{width: 450, marginLeft: 8}} placeholder={'查询数据范围'}/>
            <div className="search-btn-group flex">
                <Button className={'search-btn'} primary onClick={()=>setParams({...tempParams})}>查询</Button>
                <Button className={'reset-btn'} normal onClick={() => {
                    setTempParams(null)
                    setParams(null)
                }}>重置</Button>
            </div>
        </div>
        <Card title={'数据权限控制'} extra={extra}>
            <BaseTable {...pipeline.getProps()} />
        </Card>
        {
            showAdd &&
            <DataAuthAddRole close={()=>setShowAdd(false)} currentRoleList={roleList} {...{onAddRole, allRoleOptions}}/>
        }
        {
            delRoleId &&
            <DelMessage
                close={()=>setDelRoleId(null)}
                url={`/dataPermission/deleteRoleById?roleId=`+delRoleId}
                refresh={refreshDetail}
                method={'post'}
            >
                确认删除该角色下所有的数据？
            </DelMessage>
        }
    </div>
}

export default DataAuthMgt;

function concatData(opt, allUserRes){
   return _.map(opt, o => {
        const indexValidFlag = _.get(_.find(allUserRes, item => item.userAccount === o.value), 'indexValidFlag')
       if(indexValidFlag === 0) return _.assign({},o,{text:`${o.text}（离职）`})
       return o
    })
}
import React from 'react';
import './MessageNotify.scss'
import {FormInput, Form, Select} from "rootnet-edit";
import UserSelect from "../../../common/personSelectPop/UserSelect";
const HFormInput = props => <FormInput horizontal labelWidth={70} componentWidth={250} {...props}/>

function MessageNotify(props) {
    const {formData, setFormData, personFieldOptions, userGroupOptions} = props

    return <div className={'work-flow-message-notify flex-y'}>
        <div className="tips-text">
            状态流转时，会给对应的人员发送消息通知
        </div>
        <Form value={formData} onChange={setFormData}>
            <HFormInput label={'用户组'} bind={'userGroup'} component={Select} options={userGroupOptions} multiple/>
            <HFormInput label={'人员字段'} bind={'peopleFieldSnowId'} component={Select} options={personFieldOptions} multiple/>
            <HFormInput label={'其他用户'} bind={'otherUser'} component={UserSelect} multiple/>
        </Form>
    </div>
}

export default MessageNotify;
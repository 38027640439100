import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import './CustomizeMenuGroup.scss'
import OperationList from "../../../project_share/components/OperationList";
import {Box} from "../../common/commonComponent";
import {DataGrid, Messager} from "rootnet-ui";
import {TextIconBtn} from "../../common/TextIconBtn";
import MenuGroupUpdateDialog from "./menuGroupUpdateDialog/MenuGroupUpdateDialog";
import _ from 'lodash'
import {Switch, Tree} from "antd";
import MenuAllocationDialog from "./menuAllocationDialog/MenuAllocationDialog";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import {isNil} from "../../appraise/components/method";
import usePost from "rootnet-biz/es/hooks/usePost";

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=system',
    '/common/globalconst?globalConst=state',
]

function getColumns(props){
    const {setCurrentInfo, setAllocationObj, systemOptions, statusOptions} = props
    return [
        { header: '编码', bind: 'funcGroupCode', width: 100, tooltip: true, sortable: true},
        { header: '名称', bind: 'groupName', width: 100, tooltip: true, sortable: true,
            convert: (v, o) => <div className={'can-enter-text'}
                               onClick={()=>setCurrentInfo({mode: 'edit', id: o.id})}
            >{v}</div>},
        { header: '系统', bind: 'moduleId', width: 60, tooltip: true, sortable: true, convert: v => convertOptions(v, systemOptions)},
        { header: '状态', bind: 'status', width: 60, tooltip: true, sortable: true, convert: v => convertOptions(v, statusOptions)},
        { header: '排序', bind: 'order', width: 60, tooltip: true, sortable: true},
        { header: '操作', bind: '', align: 'center', width: 100, stretch: false, convert: renderOperation}
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '分配菜单',
                onClick: ()=>setAllocationObj(o)
            },
        ];
        return <OperationList options={operateOption}/>;
    }
}

function getOptions(cols){
    return {
        nilText: '-',
        emptyText: '-',
        fixedLeft: 2,
        fixedRight: 1,
        resizable: true,
        columns: cols,
        virtualized: true,
        autoFill: true,
    }
}

function CustomizeMenuGroup(props) {
    const [currentInfo, setCurrentInfo] = useState()
    const [allocationObj, setAllocationObj] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: list, doFetch: getList} = useGet()
    const [focusObj, setFocusObj] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const {doFetch: getMenuList} = useGet()
    const [countTree, forceUpdateTree] = useReducer((x) => x + 1, 1000)
    const [menuList, setMenuList] = useState()
    const [expandedKeys, setExpandedKeys] = useState()
    const {doFetch: submitDisplay} = usePost()

    const currentGroupId = useMemo(()=>{
        return _.get(focusObj,'id')
    },[focusObj])

    const refreshMenuList = useCallback(()=>{
        if(_.isNil(currentGroupId)) return
        setExpandedKeys([])
        getMenuList('/uac/menu/group/menuList?id='+currentGroupId).then(res => {
            setMenuList(res)
        })
    },[getMenuList, currentGroupId])

    useEffect(()=>{
        refreshMenuList()
    },[refreshMenuList])

    const onDisplayClick = useCallback((menuItem, isChecked)=>{
        const submitParams = {
            menuGroupId: _.get(focusObj,'funcGroupCode'),
            menuId: _.get(menuItem,'funcCode'),
            moduleId: _.get(menuItem,'moduleId'),
            isShow: isChecked ? 'Y': 'N'
        }
        submitDisplay('/uac/menu/editIsShow',submitParams).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
        setMenuList(old => _.map(old, x => {
            if(x.id === menuItem.id){
                x['isShow'] = isChecked ? 'Y': 'N'
            }
            return x
        }))
    },[focusObj, submitDisplay])

    const handleTreeOptions = useCallback((allList, currentList, valueField, parentField, textField)=>{
        return _.map(currentList, item => {
            const childrenList = _.filter(allList, x => x[parentField] === item[valueField])
            const node = <div className={'tree-item flex center-y'}>
                <div className="tree-item-text">
                    {item[textField]}
                </div>
                <Switch checked={_.get(item,'isShow') === 'Y'} checkedChildren={'显示'} unCheckedChildren={'隐藏'}
                        onClick={()=>onDisplayClick(item, _.get(item,'isShow') !== 'Y')}/>
            </div>
            const currentItem = {id: item.id, value: item[valueField], key: item[valueField], label: item[textField], title: node, text: item[textField]}
            if(!_.isEmpty(childrenList)) _.assign(currentItem, {children: handleTreeOptions(allList, childrenList, valueField, parentField, textField )})
            return currentItem
        })
    },[onDisplayClick])

    const treeOptions = useMemo(()=>{
        if(_.isEmpty(menuList)) return []
        const activeMenuList = _.filter(menuList, x => x.status === 0)
        let firstLevel = _.filter(activeMenuList, x => isNil(_.get(x,'pid')))
        forceUpdateTree()
        return handleTreeOptions(activeMenuList, firstLevel, 'funcCode', 'pid', 'funcName')
    },[menuList, handleTreeOptions])

    const refreshList = useCallback(()=>{
        getList('/uac/menu/group/list').catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[getList])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const [systemOptions, statusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const option = useMemo(()=>{
        return getOptions(getColumns({setCurrentInfo, setAllocationObj,systemOptions, statusOptions}))
    },[systemOptions, statusOptions])

    const extra = useMemo(()=>{
        return <TextIconBtn text='新增' icon='tianjia' onClick={()=>setCurrentInfo({mode: 'add'})}/>
    },[])

    useEffect(() => {
        if (_.isNil(currentGroupId)) return
        _.forEach(list, o => {
            return o._rowClass = o?.id === currentGroupId ? 'select_row' : ''
        })
        forceUpdate()
    }, [currentGroupId, list])

    const onRowClick = useCallback((item) => {
        setFocusObj(item)
    }, [])

    return <div className={'customize-menu-group fill flex'}>
        <div className={'customize-menu-group-left-panel flex'}>
            <Box title={'自定义菜单组'} data={list} extra={extra}>
                <DataGrid option={option} data={list} onRowClick={onRowClick}/>
            </Box>
        </div>
        <div className="customize-menu-group-right-panel flex-y">
            <Box title={'已分配菜单'} data={treeOptions} key={countTree}>
                <Tree treeData={treeOptions} defaultExpandAll={false} selectable={false} expandedKeys={expandedKeys} onExpand={setExpandedKeys}/>
            </Box>
        </div>
        {
            !_.isNil(currentInfo) &&
            <MenuGroupUpdateDialog close={()=>setCurrentInfo(null)} {...{currentInfo, systemOptions, statusOptions, refreshList}}/>
        }
        {
            !_.isNil(allocationObj) &&
            <MenuAllocationDialog close={()=>setAllocationObj(null)} {...{allocationObj, refreshMenuList}}/>
        }
    </div>
}

export default CustomizeMenuGroup;
import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tooltip, Loader } from 'rootnet-ui'
import { Icon } from '../../components'
import { ScheduleManagementContext } from '../common/Context'
import './ScheduleManagement.scss'

export default function ScheduleManagement() {
  const { scheduleManagement, setScheduleManagement } = useContext(ScheduleManagementContext)
  const [isClose, setIsClose] = useState(true)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(scheduleManagement)) {
      const open = _.every(scheduleManagement, item => item?.loading)
      open && setOpen(open)
      setIsClose(false)
    }
    else setIsClose(true)
  }, [scheduleManagement])


  return (
    !isClose && <>
      {!isOpen && <Icon name='shenqingzhong' className='globalDisplay' onClick={() => setOpen(true)} />}
      {
        isOpen &&
        <div className='ScheduleManagement'>

          <div className='header'>
            <span>进度管理器</span>
            <span>
              <Tooltip title='收起'><Icon name='Open_' onClick={() => setOpen(false)} /></Tooltip>
              <Tooltip title='关闭'><Icon name='quxiao' onClick={() => { setIsClose(true); setScheduleManagement([]) }} /></Tooltip>
            </span>
          </div>

          <div className='content'>
            {
              _.map(scheduleManagement, item => {
                return (
                  <dl key={item.date}>
                    <dd><Icon name='tongjihuizong' /></dd>
                    <dt>
                      <div>
                        <div>{item.title}：{item.name}</div>
                        {
                          item.error ?
                            <div style={{ color: 'red' }}>{item.errMsg}</div> :
                            <div className={cls({ success: !item.loading })}>{item.loading ? '数据加载中' : '数据加载完成'}</div>
                        }
                      </div>
                      <div>
                        {item.loading && <Loader text={null} />}
                      </div>
                    </dt>
                  </dl>
                )
              })
            }
          </div>

        </div>
      }
    </>
  )
}

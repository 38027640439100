import React from 'react';
import ReactDOM from 'react-dom';
import 'rootnet-ui/lib/style.css';
import './index.scss';
import 'antd/dist/antd.min.css';
import './antd.scss';
import 'rootnet-biz/lib/components/style.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
// import { Login } from './login';
import { Initialize } from './utils/utility';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import ForgetPassword from "./views/forgetPassword";
import SsoLogin from "./ssoLogin";
moment.locale('zh-cn');

Initialize();

ReactDOM.render(<ConfigProvider locale={zh_CN}>
    <Router>
        <Switch>
            <Route path="/login" component={SsoLogin} />
            <Route path="/forgetPassword" component={ForgetPassword} />
            <Route component={App} />
        </Switch>
    </Router>
</ConfigProvider>, document.getElementById('root'));
serviceWorker.unregister();
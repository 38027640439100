import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './FuncTable.scss'
import {Pagination, DataGrid} from 'rootnet-ui'
import {FormInput} from "rootnet-edit";
import {Icon} from "../../../components";
import _ from "lodash";
import {Box} from "../../common/commonComponent";
import {TextIconBtn} from "../../common/TextIconBtn";
import {usePost} from "rootnet-biz/es/hooks";
import OperationList from "../../../project_share/components/OperationList";
import DelMessage from "../../../components/DelMessage";

function getColumns(props){
    const {setMode,setCurrentId, setDelId} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
        { header: '菜单功能编号', bind: 'funcCode', width: 180, tooltip: true, sortable:true },
        { header: '菜单功能名称', bind: 'funcName', width: 180, tooltip: true, sortable:true },
        { header: '主表', bind: 'primaryTable', width: 180, tooltip: true, sortable:true },
        { header: '操作', bind: '', align: 'center', width: 120, isTransfer: false, convert: renderOperation }
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '编辑',
                mode: 'edit',
                onClick: ()=>{
                    setMode('edit')
                    setCurrentId(o.id)
                }
            },
            {
                text: '删除',
                mode: 'delete',
                onClick: () => setDelId(o.id)
            }
        ];
        return <OperationList options={operateOption}/>;
    }
}

function getOptions(columns){
    return ({
        columns,
        nilText: '-',
        emptyText: '-',
        resizable: true,
        virtualized: false,
    })
}

function getInitParams(){
    return {
        pageNum: 1,
        pageSize: 20
    }
}

function FuncTable(props) {
    const {setMode, setCurrentId} = props
    const [params, setParams] = useState(getInitParams())
    const [searchText, setSearchText] = useState()
    const {data:listRes, doFetch: getList, loading, error} = usePost()
    const [delId, setDelId] = useState()

    const refresh = useCallback(()=>{
        const postParams = {
            action: 'query',
            searchName: _.trim(searchText),
            ...params,
        }
        getList('/FuncTable/maintain',postParams)
    },[getList, params, searchText])

    useEffect(()=>{
        refresh()
    },[refresh])

    const {pageSize, total, pageNum, rows:list} = useMemo(()=>(listRes || {}),[listRes]);

    const onSearchNameChange = useCallback((text)=>{
        setSearchText(text)
        setParams(x => ({...x, pageNum: 1, pageSize: 20}))
    },[])

    const extra = useMemo(()=>{
        return <TextIconBtn icon='tianjia' text='新增' onClick={()=>setMode('add')}/>
    },[setMode])

    const options = useMemo(()=>{
        return getOptions(getColumns({setMode,setCurrentId, setDelId}))
    },[setMode,setCurrentId])

    return <div className={'func-table fill flex-y'}>
        <div className="search-area">
            <FormInput value={searchText} onChange={onSearchNameChange} placeholder={'功能号 / 功能名称 / 主表'} suffix={<Icon name={'sousuo1'}/>} componentWidth={230}/>
        </div>
        <Box title={'业务功能列表'} className='flex-y x-card-singlegrid' data={list} loading={loading} error={error} extra={extra}>
            <DataGrid option={options} data={list}/>
            <Pagination pageSize={ pageSize } total={ total } current={ pageNum } selector
                        onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>

        </Box>
        {
            !_.isNil(delId) && <DelMessage url={'/FuncTable/maintain'} params={{action:'del',id: delId}} close={()=> setDelId(null)} refresh={refresh} method={'post'}>
                确认删除吗？
            </DelMessage>
        }
    </div>
}

export default FuncTable;
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Input } from 'rootnet-edit'
import { Tooltip, Popover } from 'antd'
import useGet from 'rootnet-biz/es/hooks/useGet'
import { DataGrid, Loader, Messager } from 'rootnet-ui'
import { BaseTable, useTablePipeline, features } from 'ali-react-table'
import { Icon } from '../../../../../components'
import { useApi } from '../../../../../utils/hook'
import { Box } from '../../../../common/commonComponent'
import { strParams } from '../../../../../utils/publicFun'
import { personnelOption, recruitmentOption } from '../getTableOption'
import './index.scss'

const PERSONNEL_URL = '/resourceInfo/existingPersonnel'
const EDIT_URL = '/planRecruit/edit' //修改计划招聘

const nameTip = {
  demandResource: ['资源需求', '根据月度预测工作量计算各岗位职级所需人数'],
  numberPersonnel: ['人员数量', '当前人员数量（考虑了人员投入比例），包含前期招聘'],
  numberCurrent: ['折算人数', '考虑当前人员的新人系数，最终折算人数'],
  gapPersonnel: ['人员缺口', '资源需求-折算人数'],
  recruitmentPlanned: ['计划招聘', '自定义招聘数量，支持修改'],
}

function getName(bind) {
  return <div>
    <span>{nameTip[bind][0]}</span>
    <Tooltip title={nameTip[bind][1]}>
      <span><Icon name='jingshishuoming' /></span>
    </Tooltip>
  </div>
}

function getColumns(props) {
  const { expanded, setExpanded, year, visible, setVisible, PersonnelDetails, editInfo, setEditInfo, setValue, editUpdate, open, put, switchChecked } = props
  const filterMonth = switchChecked ? 0 : new Date().getMonth()
  const { editId = null, editField = null } = editInfo || {}
  const columnHead = [
    {
      name: <div>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => setExpanded(x => {
            if (!x) open()
            else put()
            return !x
          })}>
          <Icon name={expanded ? 'biaogeshouqi' : 'biaogezhankai'} />
        </span>
        <span style={{ marginLeft: 8 }}>模块/岗位/职级</span>
      </div>,
      code: 'all',
      width: 200,
      lock: true,
      getCellProps: (v, o) => {
        if (o.summary) return { rowSpan: 1, colSpan: 2 }
      }
    },
    { name: '模块岗位职级系数', code: 'moduleCoefficient', width: 100, lock: true, align: 'center' },
  ]
  const columnMonth = _.map(new Array(12), (v, i) => {
    if (i < filterMonth && filterMonth !== 0) return
    return {
      name: `${i + 1}月`,
      align: 'center',
      children: [
        { name: getName('demandResource'), code: `demandResource${i + 1}`, width: 85, align: 'center', render: v => distinguishMonthStyle(v, i) },
        { name: getName('numberPersonnel'), code: `numberPersonnel${i + 1}`, width: 85, align: 'center', render: v => distinguishMonthStyle(v, i) },
        {
          name: getName('numberCurrent'),
          code: `numberCurrent${i + 1}`,
          width: 85,
          align: 'center',
          render: (v, o) => {
            if (_.isNaN(_.toNumber(v))) return <div className={(i + 1) % 2 === 0 ? 'distinguish-month' : ''}>{v}</div>
            return <div className={(i + 1) % 2 === 0 ? 'distinguish-month' : ''}>
              <Popover
                trigger="click"
                content={<PersonnelDetails />}
                open={visible?.key === `${o.key}+${i + 1}`}
                getPopupContainer={trigger => trigger.parentNode}
                zIndex={9999}
                placement="top"
                destroyTooltipOnHide
                mouseEnterDelay={1}
                onOpenChange={(flag) => setVisible(flag ? _.assign({}, o, { year, month: i + 1, key: `${o.key}+${i + 1}` }) : false)}
              >
                <span style={{ cursor: 'pointer', display: 'inline-block', color: '#5477ff' }}>{v}</span>
              </Popover>
            </div>
          }
        },
        { name: getName('gapPersonnel'), code: `gapPersonnel${i + 1}`, width: 85, align: 'center', render: v => distinguishMonthStyle(v, i, true) },
        { name: getName('recruitmentPlanned'), code: `recruitmentPlanned${i + 1}`, width: 85, align: 'center', render: (v, o) => convertEditField(v, o, i, `recruitmentPlanned${i + 1}`) },
      ]
    }
  })
  function convertEditField(v, o, i, bind) {
    if (editId === o[`ids${i + 1}`] && editField === bind) {
      return <Input
        autoFocus
        defaultValue={v}
        type='number'
        min={0}
        max={999999999}
        digit={0}
        onChange={num => setValue(num)}
        onBlur={() => editUpdate({ val: v, id: o[`id${i + 1}`], item: o, month: i + 1, bind })}
        onEnter={() => editUpdate({ val: v, id: o[`id${i + 1}`], item: o, month: i + 1, bind })}
      />
    }

    if (_.isNaN(_.toNumber(v))) return distinguishMonthStyle(v, i)

    return <div
      className={'convert-edit-field-style'}
      onClick={() => setEditInfo({ editId: o[`ids${i + 1}`], editField: bind })}
    >
      {distinguishMonthStyle(v, i, false, true)}
    </div>
  }
  function distinguishMonthStyle(v, i, flag = false, dom = false) {
    return <div
      style={(flag && v >= 0.5) ? { color: 'red', fontWeight: 900 } : {}}
      className={(i + 1) % 2 === 0 ? 'distinguish-month' : 'distinguish-month-fill'}
    >
      {v}
      {dom && <Icon className='edit-icon' name='bianji2' />}
    </div>
  }
  return _.concat(columnHead, _.compact(columnMonth))
}

export default function PersonnelGap(props) {
  const { data, year, loading, refresh, isInherit, setIsInherit, switchChecked } = props
  const [editInfo, setEditInfo] = useState()
  const [value, setValue] = useState()
  const [openKeys, onChangeOpenKeys] = useState()
  const [expanded, setExpanded] = useState(true)
  const [visible, setVisible] = useState(false)
  const { data: personnelData, loading: personnelLoading, doFetch } = useGet()
  const { doFetch: upDateFetch } = useApi()

  const personnelOptions = useMemo(() => personnelOption(), [])
  const recruitmentOptions = useMemo(() => recruitmentOption(), [])

  const editUpdate = useCallback((props) => {
    const { val, id, item, month } = props
    if (_.toNumber(value) < 0) {
      Messager.show('不能为负数', { icon: 'error' })
      return destroyValue()
    }
    if (val === value || _.isNil(value)) return destroyValue()
    const parameter = {
      id,
      position: item.postion,
      techLevel: item.all,
      module: item.model,
      planRecruit: value === '' ? '0' : value,
      effectiveDate: `${year}-${month < 10 ? '0' + month : month}-01 00:00:00`
    }
    upDateFetch(EDIT_URL, 'post', parameter)
      .then(() => {
        refresh()
        destroyValue()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [refresh, upDateFetch, value, year])

  const footerDataSource = useMemo(() => {
    const monthlyCollect = _.map(new Array(12), (v, i) => ({
      [`demandResource${i + 1}`]: getCollect(data, `demandResource${i + 1}`),
      [`numberPersonnel${i + 1}`]: getCollect(data, `numberPersonnel${i + 1}`),
      [`numberCurrent${i + 1}`]: getCollect(data, `numberCurrent${i + 1}`),
      [`gapPersonnel${i + 1}`]: getCollect(data, `gapPersonnel${i + 1}`, [], true),
      [`recruitmentPlanned${i + 1}`]: getCollect(data, `recruitmentPlanned${i + 1}`),
    }))
    return [_.assign({
      summary: true,
      all: <div className='total-row'><div className='summary'>汇总</div></div>,
    }, ...monthlyCollect)]
  }, [data])

  useEffect(() => {
    if (visible === false) return
    const parameter = {
      module: visible.model,
      position: visible.postion,
      techLevel: visible.all,
      dateTimeResources: `${visible.year}-${visible.month < 10 ? '0' + visible.month : visible.month}`,
    }
    doFetch(`${PERSONNEL_URL}?${strParams(parameter)}`)
  }, [doFetch, visible])

  const PersonnelDetails = useCallback(() => {
    if (personnelLoading) return <Loader />
    return <div className='personnel-gap-details'>
      <Box title='现有人员' data={[0]}>
        <DataGrid data={_.get(personnelData, 'resourcesDataList') || []} option={personnelOptions} />
      </Box>
      <Box title='招聘计划' data={[0]}>
        <DataGrid data={_.get(personnelData, 'resourcesPlanRecruitList') || []} option={recruitmentOptions} />
      </Box>
    </div>
  }, [personnelData, personnelLoading, personnelOptions, recruitmentOptions])

  useEffect(() => {
    if (isInherit && !_.isEmpty(data)) {
      onChangeOpenKeys(getKey(data))
      setTimeout(() => setIsInherit(false), 0)
    }
  }, [data, expanded, isInherit, setIsInherit])

  const pipeline = useTablePipeline()
    .input({
      dataSource: data, columns: getColumns({
        expanded,
        setExpanded,
        year,
        visible,
        setVisible,
        PersonnelDetails,
        editInfo,
        setEditInfo,
        setValue,
        editUpdate,
        open: () => onChangeOpenKeys(getKey(data)),
        put: () => onChangeOpenKeys([]),
        switchChecked,
      })
    })
    .primaryKey('key')
    .use(
      features.treeMode({
        clickArea: 'cell',
        openKeys,
        onChangeOpenKeys,
      }),
    )


  return (
    <div className='personnel-gap'>
      <BaseTable
        isLoading={loading}
        footerDataSource={footerDataSource}
        useVirtual
        {...pipeline.getProps()}
        style={{
          '--row-height': "34px",
          '--header-bgcolor': '#F5F5F5',
          '--header-color': '#252525'
        }} />
    </div>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

function getCollect(data, bind, arr = [], flag) {
  _.forEach(data, o => {
    if (o.noKey) {
      if (_.isNaN(_.toNumber(o[bind]))) arr.push(0)
      else arr.push(_.toNumber(o[bind]))
    }
    if (!_.isEmpty(o.children)) getCollect(o.children, bind, arr, flag)
  })
  return <div
    style={(_.sum(arr).toFixed(1) >= 0.5 && flag) ? { color: 'red' } : {}}
    className='summary-bold'
  >
    {_.sum(arr).toFixed(1)}
  </div >
}

function getKey(data, arr = []) {
  _.forEach(data, o => {
    if (!o.noKey) arr.push(o.key)
    if (!_.isEmpty(o.children)) getKey(o.children, arr)
  })
  return arr
}

import React from 'react';
import {Dialog} from 'rootnet-ui'
import './CustomerServiceInfoDetailDialog.scss'
import CustomerServiceInfoContent from "./CustomerServiceInfoContent";

function CustomerServiceInfoDetailDialog(props) {
    return <Dialog className={'customer-service-info-detail-dialog'} headerVisible={false} footerVisible={false}>
        <CustomerServiceInfoContent {...props}/>
    </Dialog>
}

export default CustomerServiceInfoDetailDialog;
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Dialog, Messager, Button, Loader } from 'rootnet-ui'
import './RequirementAddDialog.scss'
import { FormInput, Select, Form, DatePicker } from "rootnet-edit";
import Icon from "../../../components/Icon";
import RichTextEditor from "../../common/richTextEditor/TinyEditor";
import _ from 'lodash'
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import { usePost } from "rootnet-biz/es/hooks";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { Checkbox, Popover, Tooltip, Tag } from "antd";
import RequirementAddUploadArea from "./requirementAddUploadArea/RequirementAddUploadArea";
import { strParams } from "../../../utils/publicFun";
import UserSelect from '../../common/personSelectPop/UserSelect';
import convertOptions from "../../common/ConvertOptions";
import { copyText } from "rootnet-core/clipboard";
import RequirementSelectDialog from "../requirementSelectDialog/RequirementSelectDialog";
import clsx from "clsx";
import CascadeSelect from "../../common/cascadeSelect/CascadeSelect";

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props} />
const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=PRIORITYLIST',
    'common/globalconst?globalConst=ReqType',
    // 'common/globalconst?globalConst=storySort',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=storyCategory',
    'common/globalconst?globalConst=BizProcess',
    'common/globalconst?globalConst=storyType',
    'common/globalconst?globalConst=reqLevel',
]

const OPTIONS_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/verQuery/queryCustOptions',
    'custlinker/listByCustId',
]

const INIT_FORM_DATA = {
    priority: 'B',
    character: 'NEW'
}

const CONTINUE_ADD_REPLACE_PARAMS = {
    title: null,
    custprojectID: null,
    custContact: null,
    custDepart: null,
    custFeeDepartment: null,
    custContactInfo: null,
    custInputDate: null,
    custExpectedDate: null,
}

function RequirementAddDialog(props) {
    const { initType = 'CUS', afterRefresh = () => { }, close, currentInfo, submitAppendParams = {}, initParams } = props
    const { CSRecordID, relateCSAttach, pid: initPid = null, parentTitle: initParentTitle = null, serviceId,
        serviceFileId, leafProjectId } = currentInfo || {}
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const [docExpand, setDocExpand] = useState(true)
    const [baseInfoShowMore, setBaseInfoShowMore] = useState(true)
    const richEditorRef = useRef()
    const [formData, setFormData] = useState(initParams || INIT_FORM_DATA)
    const [formError, setFormError] = useState()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { data: customerOptionsRes, doFetch: getProjectId } = useGet()
    // const {data: salesProjectIdRes, doFetch: getSalesProjectId} = useGet()
    const { doFetch: addReq } = usePost()
    const [continueAdd, setContinueAdd] = useState(false)
    const { data: onlyId, doFetch: getOnlyId } = useGet()
    const { data: allUserRes } = useGet('/common/userinfo')
    const { doFetch: getCSInitInfo } = useGet()
    const { doFetch: uploadCSFile } = usePost()
    const [countFile, updateFile] = useReducer((x) => x + 1, 60000)
    const [type, setType] = useState(initType)
    const [showTypeSelect, setShowTypeSelect] = useState(false)
    const [parentTitle, setParentTitle] = useState(initParentTitle)
    const [pid, setPid] = useState(initPid)
    const [showParentReq, setShowParentReq] = useState(false)
    const { doFetch: getParentInfo } = useGet()
    const [canCreate, setCanCreate] = useState(false)
    const { doFetch: getCreateAuth } = useGet()
    const { doFetch: getServiceInfo } = useGet()
    const { doFetch: getAPI } = useGet()
    const { doFetch: postAPI } = usePost()
    const [addLoading, setAddLoading] = useState(false)
    const [fileCount, setFileCount] = useState()

    const { custSystem, customer } = useMemo(() => formData || {}, [formData])

    useEffect(() => {
        if (_.isNil(pid)) return setCanCreate(true)
        getCreateAuth('/story/getContainStory?id=' + pid).then(res => {
            setCanCreate(res)
        })
    }, [pid, getCreateAuth])

    useEffect(() => {
        if (_.isNil(initPid)) return
        getParentInfo('/story/getDetail?id=' + initPid).then(res => {
            setType(res.type)
            setFormData(x => ({
                ...x,
                title: res.title,
                category: res.category,
                priority: res.priority,
                reqSort: res.reqSort,
                custSystem: res.custSystem,
                customer: res.customer,
                custSystems: [res.custSystem],
                custprojectID: res.custprojectid,
                custContact: res.custcontact,
                custDepart: res.custdepart,
                custFeeDepartment: res.custfeedepartment,
                custContactInfo: res.custcontactinfo,
                custInputDate: res.custinputdate ? dateFormat('YYYY-MM-DD', toDate.str14ToDate(res.custinputdate)) : null,
                custExpectedDate: res.custexpecteddate ? dateFormat('YYYY-MM-DD', toDate.str14ToDate(res.custexpecteddate)) : null,
                custProjectName: res.custprojectname,
                productId: res.productId,
                subSysId: res.subSysId,
                // interfaceUser: res.interfaceUser,
                assessor: res.assessor,
                impAssessor: res.impassessor,
            }))
            getProjectId('/story/getbugProjectName?id=' + _.get(res, 'custSystem'))
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [initPid, getParentInfo, getProjectId])

    useEffect(() => {
        if (_.isNil(pid)) return
        getParentInfo('/story/getDetail?id=' + pid).then(res => {
            setType(res.type)
            setFormData(x => ({
                ...x,
                type: res.type,
                reqSort: res.reqSort,
            }))
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [pid, getParentInfo])

    useEffect(() => {
        if (_.isNil(CSRecordID)) return
        getCSInitInfo('/serviceSelf/getCustomerServiceInfo?id=' + CSRecordID).then(res => {
            const replaceParams = {
                custSystem: res.customer,
                custSystems: [res.customer],
                custContact: res.linker,
                custContactInfo: res.custContactInfo,
                custInputDate: dateFormat('YYYY-MM-DD', new Date()),
                productId: res.productType,
                subSysId: res.subSysId,
                // interfaceUser: res.principal,
                currentUser: res.analyst,
                priority: res.priority,
                title: res.issueDesc,
            }
            setFormData(x => _.assign({}, x, replaceParams))
            setTimeout(() => {
                // let initText = _.replace(res.reqDetail, /\n/g, '<br/>') || ''
                // initText = initText.replace(/[^<>]+(?=<)/g, match => match.replace(/\s/g, '&nbsp;'));
                let initText = res.reqDetail || ''
                if (richEditorRef.current) {
                    richEditorRef.current.setContent(initText)
                }
            }, 2000)
            getProjectId('/story/getbugProjectName?id=' + res.customer)
        })
    }, [CSRecordID, getCSInitInfo, getProjectId])

    const [productOptions, moduleOptionsRes, custSystemOptions, custcontactOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4, d5] = optionsRes
        const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        return [
            productOptions,
            d3,
            _.map(d4, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}` })),
            _.map(d5, x => ({ value: _.toString(x.id), text: x.linker, })),
        ]
    }, [optionsRes])

    useEffect(() => {
        if (_.isNil(serviceId)) return
        getServiceInfo('/customerService/list?id=' + serviceId).then(res => {
            const replaceParams = {
                title: res.issueDesc,
                priority: res.priority,
                productId: res.productType,
                subSysId: res.subSysId,
                custContact: convertOptions(res.linker, custcontactOptions) || res.linker,
                custContactInfo: res.custContactInfo,
                custSystem: res.customer,
                custSystems: [res.customer],
                customer: res.customerName,
                // interfaceUser: res.principal,
                CSRecordID: res.csrecordID,
            }
            if (richEditorRef.current) {
                richEditorRef.current.setContent(res.reqDetail)
            }
            setFormData(x => _.assign({}, x, replaceParams))
            getProjectId('/story/getbugProjectName?id=' + _.get(res, 'customer'))
        })
    }, [serviceId, getServiceInfo, getProjectId, custcontactOptions])

    const getNewOnlyId = useCallback(() => {
        getOnlyId('/test_case/productGetOnlyId').then(id => {
            setFormData(x => _.assign({}, x, { id }))
            if (!!relateCSAttach) {
                const fileParams = { id: relateCSAttach, storyId: id, funcCode: "15" }
                uploadCSFile('/serviceSelf/add?' + strParams(fileParams))
                    .then(() => {
                        updateFile()
                    }).catch((err) => {
                        Messager.show(err._message, { icon: 'error' })
                    })
            }
            if (!!serviceFileId) {
                getAPI('/mapping/files/queryNew?' + strParams({ funcCode: '20', referenceId: serviceFileId })).then(serviceFileList => {
                    const newFileList = _.map(serviceFileList, x => ({
                        funcCode: '15',
                        name: x.fullName,
                        referenceId: id,
                        status: "done",
                        type: x.type,
                        uid: x.uid,
                        url: x.url
                    }))
                    postAPI('/mapping/files/add', newFileList).then(() => {
                        updateFile()
                    }).catch(err => Messager.show(err._message, { icon: 'error' }))
                }).catch(err => Messager.show(err._message, { icon: 'error' }))
            }
        })
    }, [getOnlyId, relateCSAttach, uploadCSFile, postAPI, getAPI, serviceFileId])

    useEffect(() => {
        getNewOnlyId()
    }, [getNewOnlyId])

    const customerOptions = useMemo(() => {
        if (_.isEmpty(customerOptionsRes)) return []
        const filterStatus = _.filter(customerOptionsRes, o => o?.status !== '1')
        return _.map(filterStatus, x => ({ text: x.customerName, value: x.customer }))
    }, [customerOptionsRes])

    const [priorityOptions, reqSortOptions, characterOptions, bizProcessOptions, storyTypeOptions, reqLevelOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    // const needSalesProjectIdFilter = useMemo(()=>{
    //     const reqSort = _.get(formData, 'reqSort')
    //     return _.includes(['SPC','NORMAL'],reqSort)
    // },[formData])

    // const salesProjectIdFilterOptions = useMemo(()=>{
    //     const salesProjectIdOptions = _.map(salesProjectIdRes, x => ({value: _.toString(x.id), text: x.projectName, salesSort: x.salesSort}))
    //     let salesProjectIdFilterOptions = needSalesProjectIdFilter ? _.filter(salesProjectIdOptions, x => x.salesSort !== 'Presales') : salesProjectIdOptions
    //     return salesProjectIdFilterOptions.sort((a,b) => {
    //         return _.get(a,'text').localeCompare(_.get(b,'text'))
    //     })
    // },[salesProjectIdRes, needSalesProjectIdFilter])

    // const salesProjectIdOptions = useMemo(()=>{
    //     const salesProjectId = _.get(formData, 'salesProjectId')
    //     if(_.isNil(salesProjectId)) return salesProjectIdFilterOptions
    //     const valueList = _.map(salesProjectIdFilterOptions, 'value')
    //     if(!_.includes(valueList, salesProjectId)){
    //         setFormData(old => ({...old, salesProjectId: null}))
    //         // const findItem = _.find(salesProjectIdRes, x => _.toString(x.id) === salesProjectId)
    //         // return _.concat([{text: _.get(findItem,'projectName'), value: _.toString(_.get(findItem,'id'))}],salesProjectIdFilterOptions)
    //     }
    //     return salesProjectIdFilterOptions
    // },[formData, salesProjectIdFilterOptions])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(_.get(formData, 'productId'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === formData.productId)
        return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId }))
    }, [formData, moduleOptionsRes])

    const TypeSelect = useCallback(() => {
        const filterData = _.filter(storyTypeOptions, o => !_.includes(['DEV', 'BUG'], o.value))
        return <div className={'add-story-type-select flex-y'}>
            {
                _.map(filterData, item => (
                    <div className={`type-name flex center-y ${item.value === type ? 'current-type' : ''}`} key={item.value}
                        onClick={() => {
                            setType(item.value)
                            setShowTypeSelect(false)
                        }}>
                        {item.text}
                    </div>
                ))
            }
        </div>
    }, [storyTypeOptions, type])

    const TitlePrefix = useCallback(() => {
        return <Popover content={<TypeSelect />} trigger="click" placement="bottom" open={showTypeSelect}
            onOpenChange={visible => {
                if (_.isNil(pid)) {
                    setShowTypeSelect(visible)
                }
            }} overlayStyle={{ paddingTop: 0 }}>
            <div className={clsx('title-prefix flex center-y', { 'disabled': !_.isNil(pid), 'able': _.isNil(pid) })} onClick={() => {
                if (_.isNil(pid)) {
                    setShowTypeSelect(true)
                }
            }}>
                {convertOptions(type, storyTypeOptions)}
                <Icon className={'arrow-icon'} name={'zhankaijiantouxia'} />
            </div>
        </Popover>
    }, [type, storyTypeOptions, showTypeSelect, pid])

    const canSubmit = useMemo(() => {
        if (!canCreate) return false
        if (!_.get(formData, 'title')) return false
        return !_.some(_.values(formError), x => x)
    }, [formData, formError, canCreate])

    useEffect(() => {
        const initCustSystem = _.get(initParams, 'custSystem')
        if (!_.isNil(initCustSystem)) {
            getProjectId('/story/getbugProjectName?id=' + initCustSystem)
        }
    }, [getProjectId, initParams])

    useEffect(() => {
        const initDesc = _.get(initParams, 'description')
        richEditorRef.current.setContent(initDesc || '')
    }, [initParams])

    const changeForm = useCallback((newObj, bind) => {
        setFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'custSystem') {
                cloneObj['salesProjectId'] = null
                cloneObj['custSystems'] = _.isNil(_.get(newObj, 'custSystem')) ? [] : [_.get(newObj, 'custSystem')]
                getProjectId('/story/getbugProjectName?id=' + _.get(newObj, 'custSystem'))
            }
            if (bind === 'reqSort') {
                if (!_.includes(['SPC', 'NORMAL', 'FUNCTION'], cloneObj['reqSort'])) {
                    cloneObj['salesProjectId'] = null
                }
            }
            return cloneObj
        })
    }, [getProjectId])

    useEffect(() => {
        if (_.isEmpty(customerOptionsRes)) return
        const valueList = _.map(customerOptionsRes, 'customer')
        if (!_.isNil(custSystem) && !_.includes(valueList, customer)) {
            const filterStatus = _.filter(customerOptionsRes, o => o?.status !== '1')
            const newCustomer = _.get(filterStatus, '0.customer')
            changeForm({ customer: newCustomer })
        }
    }, [customerOptionsRes, custSystem, changeForm, customer])

    // const salesProjectIdRequired = useMemo(()=>{
    //     if(type !== 'CUS') return false
    //     const reqSort = _.get(formData, 'reqSort')
    //     return _.includes(['SPC','NORMAL','FUNCTION'],reqSort)
    // },[formData, type])

    const needBizFlag = useMemo(() => {
        if (type !== 'CUS') return 'N'
        const reqSort = _.get(formData, 'reqSort')
        return _.includes(['SPC', 'NORMAL', 'FUNCTION'], reqSort) ? 'Y' : 'N'
    }, [formData, type])

    const clearUrl = useCallback(() => {
        let url = window.location.href;
        if (url.indexOf("?") !== -1) {
            url = url.replace(/(\?)[^'"]*/, '');
            window.history.pushState({}, 0, url);
        }
    }, [])

    const continueClear = useCallback(() => {
        clearUrl()
        setFormData(x => _.assign({}, x, CONTINUE_ADD_REPLACE_PARAMS))
        richEditorRef.current.setContent('')
        getNewOnlyId()
    }, [getNewOnlyId, clearUrl])

    const checkRichTextRequired = useCallback(() => {
        return purifyRichText(richEditorRef.current.getContent()) === ""
        function purifyRichText(richText = '') {
            const regex = /(<([^>]+)>)/ig
            return richText.replace(regex, "")
                .replace(/\s/g, "")
                .replace(/ /g, "")
                .replace(/&nbsp;/g, "")
        }
    }, [])

    const submit = useCallback(() => {
        if (checkRichTextRequired()) {
            return Messager.show("请填写描述")
        }
        if (richEditorRef.current.loading) {
            return Messager.show("图片上传中，请稍后保存")
        }
        if (addLoading) return
        setAddLoading(true)
        const postParams = {
            ...formData,
            type: type,
            status: 'R0',
            businessType: 'req',
            needBizFlag: needBizFlag,
            currentUser: !!_.get(formData, 'currentUser') ? _.get(formData, 'currentUser') : _.get(formData, 'assessor'),
            description: _.replace(richEditorRef.current.getContent() || '', /\n/g, ''),
            pid: pid,
            leafProjectId,
            ...submitAppendParams,
        }
        if (CSRecordID) {
            postParams['CSRecordID'] = CSRecordID
        }
        addReq('/story/add', postParams).then(() => {
            Messager.show('添加成功', { icon: 'success' })
            if (fileCount > 0) {
                addReq('/story/updateIsUploadFile', { id: _.get(formData, 'id'), isUploadFiles: 'Y' })
                    .then(() => {
                        execute()
                    })
                    .catch(() => {
                        execute()
                    })
            } else {
                execute()
            }
            function execute() {
                afterRefresh()
                setAddLoading(false)
                if (continueAdd) {
                    continueClear()
                } else {
                    close()
                }
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setAddLoading(false)
        })
    }, [checkRichTextRequired, addLoading, formData, type, needBizFlag, pid, CSRecordID, addReq, afterRefresh,
        continueAdd, continueClear, close, leafProjectId, submitAppendParams, fileCount])

    // useEffect(()=>{
    //     if(salesProjectIdRequired){
    //         if(_.isNil(_.get(formData,'salesProjectId'))){
    //             setFormError(x=>_.assign({},x,{'salesProjectId': '必填项！'}))
    //         }else{
    //             setFormError(x=>_.assign({},x,{'salesProjectId': null}))
    //         }
    //     }else{
    //         setFormError(x=>_.assign({},x,{'salesProjectId': null}))
    //     }
    // },[salesProjectIdRequired, formData])

    const copy = useCallback((value) => {
        copyText(value);
        Messager.show('数据已复制到剪切板', { icon: 'success' });
    }, [])

    const isCUS = useMemo(() => {
        return type === 'CUS'
    }, [type])

    useEffect(() => {
        if (isCUS) {
            if (_.isNil(_.get(formData, 'reqLevel'))) {
                setFormError(x => _.assign({}, x, { 'reqLevel': '必填项！' }))
            }
        } else {
            setFormError(x => _.assign({}, x, { 'reqLevel': null }))
        }
    }, [isCUS, formData])

    const reqLevelTips = useMemo(() => {
        return <div>
            需求等级：
            {
                _.map(reqLevelOptions, x => {
                    return <div>
                        <br />
                        {`${x.text}： ${x.memo}`}
                    </div>
                })
            }
        </div>
    }, [reqLevelOptions])

    return <Dialog header={'新增需求'} className={'requirement-add-dialog'} footerVisible={false} headerVisible={false}>
        <div className="mock-dialog-header flex">
            <div className="dialog-title">
                新增需求
            </div>
            <div className="mock-right-header flex center-y">
                <div className={'close-area flex center'} onClick={() => {
                    clearUrl()
                    close()
                }}>
                    <Icon name={'quxiao'} className={'close-icon'} />
                </div>
            </div>
        </div>
        <div className="content-wrap flex-y">
            {
                addLoading && <Loader fill />
            }
            <HFormInput label={'标题'} value={_.get(formData, 'title')} onChange={v => setFormData(x => ({ ...x, title: v }))} prefix={<TitlePrefix />} required componentWidth={772} />
            <div className={'parent-req-wrap flex center-y'}>
                <div className="parent-req-left-wrap flex center-y">
                    <div className="label-text">父需求</div>
                    <div className="parent-req-text">
                        {parentTitle}
                    </div>
                </div>
                <div className={'parent-req-handle-wrap flex center-y'}>
                    <Tooltip title="复制父需求标题">
                        <span><Icon name={'wendang'} onClick={() => copy(parentTitle)} /></span>
                    </Tooltip>
                    <Tooltip title="替换需求">
                        <span><Icon name={'sousuo1'} onClick={() => setShowParentReq(true)} /></span>
                    </Tooltip>
                    <Tooltip title="删除">
                        <span><Icon name={'quxiao'} className={'del-icon'} onClick={() => {
                            setPid(null)
                            setParentTitle(null)
                        }} /></span>
                    </Tooltip>
                </div>
            </div>
            {
                parentTitle &&
                <div className="tips-text flex center-y">
                    <Icon name={'tixing'} className={'tips-icon'} />
                    <Tag color="orange">
                        {
                            canCreate ? '创建此子需求后，如果父需求已经规划了版本，父需求会被移出版本，请谨慎操作！' : '当前父需求不允许创建子需求，请重新选择！'
                        }
                    </Tag>
                </div>
            }
            <div className="area-wrap base-info-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
                    基本信息
                </div>
                <div className="area-content-wrap" style={{ height: baseInfoExpand ? (baseInfoShowMore ? '' : 160) : 0 }}>
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                        <HFormInput label={'需求等级'} bind={'reqLevel'} required={isCUS} clear={!isCUS} component={Select} options={reqLevelOptions} />
                        <Tooltip title={reqLevelTips} overlayStyle={{ width: 430, maxWidth: 430, whiteSpace: 'pre-wrap' }}>
                            <span className={'cursor-pointer'}>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput label={'需求类别'} bind={'category'} required component={Select} options={characterOptions} labelWidth={86} />
                        <HFormInput label={'优先级'} bind={'priority'} required component={Select} options={priorityOptions} />
                        <HFormInput label={'需求标识'} bind={'reqSort'} required component={Select} options={reqSortOptions} disabled={!_.isNil(pid)} />
                        <HFormInput label={'所属产品'} bind={'productId'} required search component={Select} tree options={productOptions} />
                        <HFormInput label={'子产品'} bind={'subSysId'} required search component={Select} options={moduleOptions} componentClass={'sub-sys-id-options'} />
                        {/*<HFormInput label={'根网接口人'} bind={'interfaceUser'} required component={UserSelect}/>*/}
                        <HFormInput label={'客户系统'} bind={'custSystem'} required search component={Select} options={custSystemOptions} />
                        <HFormInput label={'提出客户'} bind={'customer'} required search component={Select} options={customerOptions} />
                        <HFormInput label={'发布客户系统'} bind={'custSystems'} required search component={Select} options={custSystemOptions} multiple toolbar={false} clear />
                        {/*<HFormInput label={'所属商机'} bind={'salesProjectId'} required={salesProjectIdRequired} search componentClass={'sales-project-id-options'}*/}
                        {/*            disabled={!salesProjectIdRequired} component={Select} options={salesProjectIdOptions}/>*/}
                        <HFormInput label={'需求负责人'} bind={'assessor'} required component={UserSelect} />
                        <HFormInput label={'业务流程环节'} bind={'bizProcess'} component={CascadeSelect} options={bizProcessOptions} />
                        <HFormInput label={'实施评估人'} bind={'impAssessor'} component={UserSelect} allowClear />
                        <HFormInput label={'客户问题编号'} bind={'custprojectID'} />
                        <HFormInput label={'客户提出人'} bind={'custContact'} />
                        <HFormInput label={'客户部门'} bind={'custDepart'} />
                        <HFormInput label={'费用部门'} bind={'custFeeDepartment'} />
                        <HFormInput label={'联系方式'} bind={'custContactInfo'} />
                        <HFormInput label={'提出日期'} bind={'custInputDate'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                        <HFormInput label={'希望提供日期'} bind={'custExpectedDate'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                        <HFormInput label={'客户项目名称'} bind={'custProjectName'} />
                    </Form>
                </div>
                <div className={'base-info-show-more'} style={{ display: baseInfoExpand ? 'block' : 'none' }}>
                    <span className={'base-info-show-more-text'} onClick={() => setBaseInfoShowMore(v => !v)}>
                        {baseInfoShowMore ? '收起更多' : '展开更多'}
                    </span>
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
                    描述
                    <span style={{ color: 'red' }}>（必填）</span>
                </div>
                <div className="rich-text-area" style={{ display: descExpand ? 'block' : 'none' }}>
                    <RichTextEditor ref={richEditorRef} />
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: docExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDocExpand(v => !v)} />
                    附件
                </div>
                <div className="doc-area" style={{ display: docExpand ? 'block' : 'none' }} key={countFile}>
                    <RequirementAddUploadArea id={onlyId} allUserRes={allUserRes} funcCode={'15'} setFileCount={setFileCount} />
                </div>
            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div className={'continue-add-check'}>
                {
                    _.isNil(serviceId) && _.isNil(serviceFileId) && _.isNil(relateCSAttach) && _.isNil(CSRecordID) &&
                    <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
                }
            </div>
            <div className="btn-group flex">
                <Button normal onClick={() => {
                    clearUrl()
                    close()
                }}>取消</Button>
                <Button primary disable={!canSubmit} onClick={submit}>确认</Button>
            </div>
        </div>
        {
            showParentReq &&
            <RequirementSelectDialog close={() => setShowParentReq(false)} setPid={setPid} setParentTitle={setParentTitle}
                initPid={pid} initTitle={parentTitle} />
        }
    </Dialog>
}

export default RequirementAddDialog;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './VersionUpdateRecord.scss'
import {DataGrid} from 'rootnet-ui'
import {TextIconBtn} from "../../../../common/TextIconBtn";
import VersionDetailDialog from "./versionDetailDialog/VersionDetailDialog";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertOptions from "../../../../common/ConvertOptions";
import { Switch } from 'antd';
import {isNil} from "../../../../appraise/components/method";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import Icon from "../../../../../components/Icon";
import DelMessage from "../../../../../components/DelMessage";

const { str14ToDate } = toDate

function getColumns(props){
    const {typeOptions, envOptions, allUserRes, setCurrentInfo, setDelId} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40, stretch: false },
        { header: '升级版本', bind: 'releaseID', width: 200, tooltip: true, sortable:true,
            convert: (v, o) => <div className={'enter-detail-field-wrap flex center-y'}>
                <div className="enter-detail-field" onClick={()=>setCurrentInfo({
                    recID: o.recID,
                    mode: 'edit'
                })}>{v}</div>
                <Icon name={'shanchu'} className="del-icon" onClick={()=>setDelId(o.recID)}/>
            </div>},
        { header: '升级环境', bind: 'updateEnv', width: 100, tooltip: true, sortable:true, convert: v => convertOptions(v,envOptions)},
        { header: '升级日期', bind: 'updateDate', width: 150, tooltip: true, sortable:true, convert: v => dateFormat('YYYY-MM-DD')(str14ToDate(v))},
        { header: '外挂升级程序', bind: 'subSysName', width: 150, tooltip: true, sortable:true},
        { header: '对接券商', bind: 'extConntoBrokers', width: 150, tooltip: true, sortable:true},
        { header: '升级类型', bind: 'updateType', width: 100, tooltip: true, sortable:true, convert: v => convertOptions(v,typeOptions)},
        { header: '升级人员', bind: 'updateUser', width: 100, tooltip: true, sortable:true,},
        { header: '版本升级目的', bind: 'updateDesc', width: 150, tooltip: true, sortable:true,},
        { header: '创建人', bind: 'recUser', width: 100, tooltip: true, sortable:true, convert: v => convertOptions(v, allUserRes,'userName','userAccount')},
        { header: '创建时间', bind: 'recTime', width: 150, tooltip: true, sortable:true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(str14ToDate(v))},
    ]
}

function getOptions(cols){
    return {
        nilText: '-',
        emptyText: '-',
        fixedLeft: 1,
        resizable: true,
        columns: cols,
        virtualized: true,
        autoFill: true,
    }
}

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=UpdateType',
    'common/globalconst?globalConst=UpdateEnv',
]

function VersionUpdateRecord(props) {
    const {allUserRes, id, updateDynamic} = props
    const [currentInfo, setCurrentInfo] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: detail,doFetch: getList} = useGet()
    const [isFilter, setIsFilter] = useState(false)
    const [delId, setDelId] = useState()

    const refreshList = useCallback(()=>{
        getList('/customer/releaserec/list?id='+id)
    },[getList, id])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const [typeOptions, envOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const options = useMemo(()=>{
        return getOptions(getColumns({typeOptions, envOptions, allUserRes, setCurrentInfo, setDelId}))
    },[typeOptions, envOptions, allUserRes])

    const filterList = useMemo(()=>{
        const list = _.get(detail,'list')
        if(!isFilter) return list
        return _.filter(list, x => !isNil(_.get(x,'prodsubsysid')))
    },[isFilter, detail])

    const toDetail = useCallback(id => {
        if(_.isNil(id)) return
        setCurrentInfo({
            recID: id,
            mode: 'edit'
        })
    },[])

    return <div className={'version-update-record flex-y'}>
        <div className="version-update-header flex center-y">
            <div className="version-update-header-left flex">
                <div className="version-env flex center">
                    当前生产环境：
                    {
                        _.get(detail,'nowProRecId')?
                            <div className="version-env-text" onClick={()=>toDetail(_.get(detail,'nowProRecId'))}>{_.get(detail,'nowPro') || '-'}</div>: '-'
                    }
                </div>
                <div className="version-env flex center">
                    当前测试环境：
                    {
                        _.get(detail,'nowTestRecId')?
                            <div className="version-env-text" onClick={()=>toDetail(_.get(detail,'nowTestRecId'))}>{_.get(detail,'nowTest') || '-'}</div>: '-'
                    }
                </div>
            </div>
            <div className="version-update-header-right flex center-y">
                <Switch checkedChildren="外挂" unCheckedChildren="全部" checked={isFilter} onChange={setIsFilter}/>
                <TextIconBtn icon={'tianjia'} text='添加' onClick={()=>{setCurrentInfo({mode: 'add'})}}/>
            </div>
        </div>
        <div className="table-wrap flex">
            <DataGrid option={options} data={filterList}/>
        </div>
        {
            !_.isNil(currentInfo) &&
            <VersionDetailDialog {...{typeOptions, envOptions, allUserRes, currentInfo, setCurrentInfo, refreshList,id,updateDynamic}}/>
        }
        {
            !_.isNil(delId) &&
            <DelMessage close={()=>setDelId(null)} refresh={()=>{
                refreshList()
                updateDynamic()
            }} method={'get'} url={`/customer/releaserec/delete?RecID=${delId}&id=${id}`}>
                确定删除？
            </DelMessage>
        }
        </div>
}

export default VersionUpdateRecord;
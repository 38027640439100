import React, {useEffect, useMemo, useState} from "react";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import {strParams} from "../../../utils/publicFun";
import {FormInput, RadioGroup, Select} from "rootnet-edit";
import {Button} from 'rootnet-ui'

const envOptions = [
    { text: "生产环境", value: '1' },
    { text: "测试环境", value: '0' },
]

const HFormInput = props => <FormInput component={Select} componentWidth={234} horizontal {...props}/>

export default function Options(props){
    const {search} = props
    const [customerSystem, setCustomerSystem] = useState()
    const [currentVersion, setCurrentVersion] = useState()
    const [targetVersion, setTargetVersion] = useState()
    const [selectedEnv, setSelectedEnv] = useState('1')
    const {data: customerSystemOptionsRes} = useGet('/version/getNowVersion')
    const {doFetch: getVersionOptions} = useGet()
    const [filterVersionOptions, setFilterVersionOptions] = useState()
    const {data: allVersionOptionsRes} = useGet('/version/getTargetVersion')

    useEffect(()=>{
        if(_.isNil(customerSystem)) return
        getVersionOptions('/version/getNowVersion?'+strParams({customerID: customerSystem, updateEnv: selectedEnv})).then(res => {
            const uniqList = _.uniqBy(res, x => x.releaseid)
            const uniqOptions = _.map(uniqList, x => ({value: x.releaseid, text: x.releaseid}))
            setFilterVersionOptions(uniqOptions)
        })
    },[customerSystem,getVersionOptions,selectedEnv])

    const customerSystemOptions = useMemo(()=>{
        if(_.isEmpty(customerSystemOptionsRes)) return []
        const uniqList = _.uniqBy(customerSystemOptionsRes, x => `${x.customerid}-${x.projectName}`)
        return _.map(uniqList, x => ({value: x.customerid, text: `${x.customerid}-${x.projectName}`}))
    },[customerSystemOptionsRes])

    const allVersionOptions = useMemo(()=>{
        if(_.isEmpty(allVersionOptionsRes)) return []
        return _.map(allVersionOptionsRes,x => ({value: x.releaseID, text: x.releaseID}))
    },[allVersionOptionsRes])

    const versionOptions = useMemo(()=>{
        return _.isNil(customerSystem) ? allVersionOptions : filterVersionOptions
    },[customerSystem,allVersionOptions,filterVersionOptions])

    const disabledEnvOptions = useMemo(()=>{
        return _.isNil(customerSystem)? _.map(envOptions, x => ({...x, _disabled: true})) :envOptions
    },[customerSystem])

    useEffect(()=>{
        if(_.isNil(customerSystem)) setFilterVersionOptions(null)
    },[customerSystem])

    useEffect(()=>{
        if(_.isNil(filterVersionOptions)) return
        const valueList = _.map(filterVersionOptions, x => x.value)
        if(!_.includes(valueList,currentVersion)) setCurrentVersion(_.head(valueList))
    },[filterVersionOptions,currentVersion])

    return <div className={'options-area'}>
        <div className={'first-line-options flex'}>
            <HFormInput label={'客户系统'} options={customerSystemOptions} value={customerSystem} onChange={setCustomerSystem} clear search/>
            <RadioGroup options={disabledEnvOptions} value={selectedEnv} onChange={setSelectedEnv} defaultValue={'1'}/>
        </div>
        <div className="second-line-options">
            <HFormInput label={'当前版本'} value={currentVersion} onChange={setCurrentVersion} options={versionOptions} clear search/>
            <HFormInput label={'目标版本'} value={targetVersion} onChange={setTargetVersion} options={allVersionOptions} clear search/>
            <Button primary className={'query-btn'} onClick={()=>search({releaseID: currentVersion, releaseIDTarget: targetVersion})}>查询</Button>
        </div>
    </div>
}
import React, { useState, useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import _ from 'lodash'
import { dateFormat } from 'rootnet-core/dateFormat'
import useExactHeight from '../../../common/hooks/useExactHeight'
import ProjectManpowerSummaryDetailSummary from '../projectManpowerSummaryDetailSummary'
import ProjectManpowerSummaryList from '../projectManpowerSummaryList'
import './index.scss'

export default function ProjectManpowerSummaryDetailInfo(props) {
  const { data, totalData, loading, error, dateInterval, refresh, projectOpt, projectTypeOpt, projectAllOpt, allUserRes, itemClassOpt, parameter } = props
  const [summaryInfoExpanded, setSummaryInfoExpanded] = useState(true)
  const [exactContainerRef, containerRef, updateHeight] = useExactHeight(40)

  const monthArray = useMemo(() => {
    return getDateArry(dateInterval)
  }, [dateInterval])

  const onScroll = useCallback((e) => {
    if (e.deltaY > 0) {
      setSummaryInfoExpanded(false)
    }
  }, [])

  useEffect(() => {
    const rightPanel = document.querySelector('.project-manpower-summary-list')
    if (rightPanel) {
      rightPanel.addEventListener('mousewheel', onScroll)
    }
    return () => {
      if (rightPanel) {
        rightPanel.removeEventListener('mousewheel', onScroll)
      }
    }
  }, [onScroll])

  useLayoutEffect(() => {
    return updateHeight(summaryInfoExpanded);
  }, [summaryInfoExpanded, updateHeight]);

  return (
    <div className='project-manpower-summary-detail-info flex-y'>
      <div className="containerParent" ref={exactContainerRef} onTransitionEnd={transitionEnd}>
        <div className='container' ref={containerRef}>
          <ProjectManpowerSummaryDetailSummary {...{ summaryInfoExpanded, setSummaryInfoExpanded, data: totalData, loading, error, monthArray }} />
        </div>
      </div>
      <ProjectManpowerSummaryList {...{ data, loading, error, monthArray, projectOpt, projectTypeOpt, refresh, projectAllOpt, allUserRes, itemClassOpt, parameter }} />
    </div>
  )
  function transitionEnd() {
    if (summaryInfoExpanded) exactContainerRef.current.style.height = 'auto';//设置为`auto`的原因是需要保证子级菜单展开时，父级菜单的高度响应变化
  }
}

function getDateArry(dateInterval) {
  const start = dateFormat('YYYY-MM-DD', _.get(dateInterval, 'begin'))
  const end = dateFormat('YYYY-MM-DD', _.get(dateInterval, 'end'))
  const dateArry = [];
  const s1 = start.split("-");
  const s2 = end.split("-");
  let mCount = 0;
  if (parseInt(s1[0]) < parseInt(s2[0])) {
    mCount = (parseInt(s2[0]) - parseInt(s1[0])) * 12 + parseInt(s2[1]) - parseInt(s1[1]) + 1;
  } else {
    mCount = parseInt(s2[1]) - parseInt(s1[1]) + 1;
  }
  if (mCount > 0) {
    let startM = parseInt(s1[1]);
    let startY = parseInt(s1[0]);
    for (let i = 0; i < mCount; i++) {
      if (startM < 12) {
        dateArry[i] = startY + "-" + (startM > 9 ? startM : "0" + startM);
        startM += 1;
      } else {
        dateArry[i] = startY + "-" + (startM > 9 ? startM : "0" + startM);
        startM = 1;
        startY += 1;
      }
    }
  }
  return dateArry;
}


import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './FieldSetting.scss'
import {Form, Input, Select, Table} from "antd";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {MenuOutlined} from "@ant-design/icons";
import _ from "lodash";
import {arrayMoveImmutable} from "array-move";
import {WorkFlowEditContext} from "../../../common/Context";
import {TextIconBtn} from "../../../common/TextIconBtn";
import {uniqKeyFor} from "../../../../project_share/utils/utils";
import Icon from "../../../../project_share/components/Icon";
import convertOptions from "../../../common/ConvertOptions";
import {isNil} from "../../../appraise/components/method";
import {useGet} from "rootnet-biz/es/hooks";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const isRequiredOptions = [
    { text: '是', label: '是', value: '1' },
    { text: '否', label: '否', value: '2' },
]

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=workflowFieldType',
]

function getColumns(props){
    const {delItem, fieldIdOptions, workflowFieldTypeOptions, editItemRef, convertCollection} = props
    return [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: <span style={{marginLeft: 12}}>字段名称</span>,
            dataIndex: 'fieldSnowId',
            key: 'fieldSnowId',
            width: 180,
            editable: true,
            render: (value) => convertOptions(value, fieldIdOptions)
        },
        {
            title: <span style={{marginLeft: 12}}>默认值类型</span>,
            dataIndex: 'defaultType',
            key: 'defaultType',
            width: 180,
            editable: true,
            render: (value) => convertOptions(value, workflowFieldTypeOptions)
        },
        {
            title: <span style={{marginLeft: 12}}>默认值/默认字段</span>,
            dataIndex: 'defaultValue',
            key: 'defaultValue',
            width: 180,
            editable: true,
            render: (value, obj) => <div className={'fill flex center-y'} style={{paddingLeft: 12}} onClick={()=> {
                editItemRef.current = obj
            }}>
                {
                    _.get(obj, 'defaultType') === '1' &&
                    <div>{convertOptions(value, fieldIdOptions)}</div>
                }
                {
                    (_.get(obj, 'defaultType') === '0') && isNil(convertOptions(_.get(obj,'fieldSnowId'), fieldIdOptions, 'fieldDateDetailSource')) &&
                    <div>{value}</div>
                }
                {
                    (_.get(obj, 'defaultType') === '0') && !isNil(convertOptions(_.get(obj,'fieldSnowId'), fieldIdOptions, 'fieldDateDetailSource')) &&
                    <div>{convertOptions(value, _.get(convertCollection, convertOptions(_.get(obj,'fieldSnowId'), fieldIdOptions, 'tableField')))}</div>
                }
            </div>,
        },
        {
            title: <span style={{marginLeft: 12}}>是否必填</span>,
            dataIndex: 'isRequired',
            key: 'isRequired',
            width: 100,
            editable: true,
            render: (value) => convertOptions(value, isRequiredOptions)
        },
        {
            title: <span style={{marginLeft: 12}}>提示文本</span>,
            dataIndex: 'placeholder',
            key: 'placeholder',
            width: 100,
            editable: true,
        },
        {
            title: <span style={{marginLeft: 12}}>删除</span>,
            dataIndex: 'delete',
            key: 'delete',
            width: 60,
            render: (v,o) => <Icon name={'shanchu'} className={'del-icon'} onClick={()=>delItem(_.get(o,'id'))}/>
        },
    ]
}

function FieldSetting(props) {
    const {fieldList, convertCollection, list, setList} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [form] = Form.useForm()
    const editItemRef = useRef()

    const [workflowFieldTypeOptions] = useMemo(()=> {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const fieldIdOptions = useMemo(()=>{
        return _.map(fieldList, x => ({value: x.id, label: `${x.fieldName} (${x.tableName}.${x.fieldId})`, text: `${x.fieldName} (${x.tableName}.${x.fieldId})`,
            fieldDateDetailSource: x.fieldDateDetailSource,
            tableField: `${x.tableName}.${x.fieldId}`}))
    },[fieldList])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = _.findIndex(list, x => x.id === restProps['data-row-key'])
        return <Form form={form} component={false}>
            <WorkFlowEditContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </WorkFlowEditContext.Provider>
        </Form>
    },[list, form])

    const handleSave = useCallback((row)=>{
        const index = _.findIndex(list, x => x.id === row.id)
        setList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return _.sortBy(newList, 'sort')
        })
    },[list, setList])

    const delItem = useCallback((delId)=>{
        setList(old => {
            return _.filter(old, x => _.get(x,'id') !== delId)
        })
    },[setList])

    const columns = useMemo(()=>{
        return _.map(getColumns({delItem, fieldIdOptions, workflowFieldTypeOptions, editItemRef, convertCollection}), col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    fieldIdOptions,
                    workflowFieldTypeOptions,
                    editItemRef,
                    convertCollection,
                })
            }
        })
    },[handleSave, delItem, fieldIdOptions, workflowFieldTypeOptions, convertCollection])

    const addNewItem = useCallback(()=>{
        setList(old => {
            const newItem = {
                id: uniqKeyFor(),
                defaultType: '0',
                isRequired: '2'
            }
            return _.concat(old,[newItem])
        })
    },[setList])

    return <div className={'work-flow-field-setting flex-y'}>
        <div className="tips-text flex">
            状态流转时，需要填写的字段，可以设置必填和默认值
        </div>
        <div className={'table-wrap flex'}>
            <Table
                size={'small'}
                sticky={true}
                pagination={false}
                dataSource={list}
                columns={columns}
                rowKey="id"
                scroll={{x: 800, y: `calc(100% - 45px)`}}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: EditableRow,
                        cell: EditableCell
                    },
                }}
            />
        </div>
        <div className={'add-btn-wrap'}>
            <TextIconBtn icon={'tianjia'} text={'添加字段'} className={'add-btn'} onClick={addNewItem}/>
        </div>
    </div>

    function onSortEnd({oldIndex, newIndex}){
        if (oldIndex !== newIndex){
            const newList = arrayMoveImmutable([].concat(list), oldIndex, newIndex).filter(el => !!el);
            setList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, fieldIdOptions, workflowFieldTypeOptions,
        editItemRef, convertCollection, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(WorkFlowEditContext) || {}

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        setEditing(x => !x);
        form.setFieldsValue({ [dataIndex]:  record[dataIndex] });
    },[dataIndex, record, form])

    const save = () => {
        try{
            form.validateFields().then((changeObj)=>{
                const key = _.head(_.keys(changeObj))
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    if(_.includes(['defaultType','fieldSnowId'],key)){
                        handleSave({ ...record, ...changeObj, defaultValue: null});
                    }else{
                        handleSave({ ...record, ...changeObj });
                    }
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        } catch (errInfo) {
            console.error(errInfo);
        }
    }

    let childNode = children;

    if(editable){
        if(editing){
            let editNode
            if(dataIndex === 'fieldSnowId'){
                editNode = (<Select ref={inputRef} options={fieldIdOptions} onSelect={save} allowClear={false}
                                    defaultOpen onBlur={()=>setEditing(false)} showSearch optionFilterProp="label"
                />)
            }else if(dataIndex === 'defaultType'){
                editNode = (<Select ref={inputRef} options={workflowFieldTypeOptions} onSelect={save} allowClear={false}
                                    defaultOpen onBlur={()=>setEditing(false)}
                />)
            }else if(dataIndex === 'defaultValue'){
                if(_.get(editItemRef.current,'defaultType') === '1'){
                    editNode = (<Select ref={inputRef} options={fieldIdOptions} onSelect={save} allowClear={true}
                                        defaultOpen onBlur={()=>setEditing(false)} showSearch optionFilterProp="label"
                    />)
                }else if(!isNil(convertOptions(_.get(editItemRef.current,'fieldSnowId'), fieldIdOptions, 'fieldDateDetailSource'))){
                    const tableField = convertOptions(_.get(editItemRef.current,'fieldSnowId'), fieldIdOptions, 'tableField')
                    const options = _.get(convertCollection, tableField)
                    editNode = (<Select ref={inputRef} options={options} onSelect={save} allowClear={true}
                                        defaultOpen onBlur={()=>setEditing(false)} showSearch optionFilterProp="label"
                    />)
                }else{
                    editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
                }
            }else if(dataIndex === 'isRequired'){
                editNode = (<Select ref={inputRef} options={isRequiredOptions} onSelect={save} allowClear={false}
                                    defaultOpen onBlur={()=>setEditing(false)}
                />)
            }else{
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </Form.Item>
            )
        }else{
            childNode =(
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }
    return <td {...restProps}>{childNode}</td>;
}

export default FieldSetting;
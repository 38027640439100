// import useGetFuncCode from "./useGetFuncCode";
import UseGetAllColumns from "./useGetAllColumns";
import UseGetOptionsConfig from "./useGetOptionsConfig";
import useGetFieldConvertCollection from "./useGetFieldConvertCollection";
import useGet from "rootnet-biz/es/hooks/useGet";

export default function useGetViewConfig(funcCode,setIsLoading) {
    const {data: fieldList = [], doFetch: getFieldList} = useGet()

    const allColumns = UseGetAllColumns(funcCode)
    const optionsConfig = UseGetOptionsConfig(allColumns)
    const convertCollection = useGetFieldConvertCollection(allColumns,setIsLoading)
    return {funcCode, allColumns, optionsConfig,fieldList, getFieldList, convertCollection}
}

import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Drawer, DataGrid, Pagination } from 'rootnet-ui'
import { dateFormat } from 'rootnet-core/dateFormat'
import './index.scss'

const LIST_URL = '/workingHoursManagement/selectPage'

const initParams = {
  pageNum: 1,
  pageSize: 20,
}

export default function TimeDetailsList(props) {
  const { open, close, params: defaultParams, option } = props
  const [pageSize, setPageSize] = useState(20)
  const [parameter, setParameter] = useState()
  const { doFetch, data: resData, loading } = usePost()
  const { rows: data = [], total, pageNum } = useMemo(() => resData || {}, [resData])

  const refresh = useCallback((params = {}) => {
    const { period } = params
    const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
    const getParams = _.assign({}, params, !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval })
    doFetch(LIST_URL, _.omit(getParams, ['period']))
  }, [doFetch])

  useEffect(() => {
    if (!open) return
    refresh({ ..._.omit(defaultParams, ['pageNum', 'pageSize']), ...initParams })
  }, [open, refresh, defaultParams])

  useEffect(() => {
    if (parameter) return
  }, [parameter])

  useEffect(() => {
    setParameter({ ..._.omit(defaultParams, ['pageNum', 'pageSize']), ...initParams })
  }, [defaultParams])

  return (
    <Drawer
      open={open}
      header={'工时明细'}
      cancel={close}
      onOutsideClick={close}
      footerVisible={false}
      contentStyle={{ padding: '4px 8px', overflowY: 'auto' }}
      className='time-details-list-drawer'
      loading={loading}
    >
      <div className='time-details-list-drawer-content'>
        <DataGrid data={data} option={option} />
        <Pagination selector total={total} current={pageNum} pageSize={pageSize} onChange={(pageNum, pageSize) => {
          setPageSize(pageSize)
          setParameter(x => {
            const params = _.assign({}, x, { pageNum, pageSize })
            refresh(params)
            return params
          })
        }} />
      </div>
    </Drawer>
  )
}

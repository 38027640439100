import React, {useCallback, useState} from 'react';
import TinyEditor from "../../../../common/richTextEditor/TinyEditor";
import _ from "lodash";
import './IssueInfo.scss'
import {Image} from "antd";

function IssueInfo(props) {
    const {infoRichEditorRef, isDetail, editFormData} = props
    const [imageVisible, setImageVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()

    const previewImage = useCallback(e => {
        if(e.target.tagName === 'IMG'){
            setImageSrc(e.target.src)
            setImageVisible(true)
        }
    },[])

    return <div className={'issue-info'}>
        <div className="rich-text-detail-wrap flex" style={{display: isDetail ? "block": 'none'}}>
            <div onClick={previewImage} dangerouslySetInnerHTML={{__html: _.get(editFormData,'impact')?.replace(/\n/g, '<br/>')}}/>
        </div>
        <div className="rich-text-area" style={{display: !isDetail ? "block": 'none'}}>
            <TinyEditor ref={infoRichEditorRef}/>
        </div>
        <Image
            style={{ display: 'none' }}
            src={imageSrc}
            preview={{
                visible: imageVisible,
                src: imageSrc,
                onVisibleChange: visible => {
                    setImageVisible(visible)
                }
            }}
        />
    </div>
}

export default IssueInfo;
import {useEffect} from 'react';
import usePost from "rootnet-biz/es/hooks/usePost";
import _ from "lodash";
import {Messager} from 'rootnet-ui'

export default function useGetAppendData(idList,url) {
    const {data: appendData = [], doFetch: getAppendData} = usePost()

    // 通过idList 自定义追加数据接口
    useEffect(()=>{
        if(_.isEmpty(idList)) return
        getAppendData(url,idList).catch(err => Messager.show(err._message, { icon: 'error' }))
    },[getAppendData,idList,url])

    return appendData
}
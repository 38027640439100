import React, { useState } from 'react';
import _ from 'lodash';
import clsx from "clsx";
import { Card } from 'rootnet-ui';
import { pathParamsFor, strParams } from "../../../utils/publicFun";
import { useGet } from "../../../utils/hook";
import { TaskListPro as TaskList } from './TaskList'

function LeftMenu(props) {
    const { data, project, loading, error, onClick } = props;
    const projectId = _.get(project, 'projectId')
    return <Card className="details-left-menu" loading={loading} error={error} >
        {
            _.map(data, (x, i) => {
                return <div className={clsx('details-left-menu-item', { active: _.toString(x.projectId) === _.toString(projectId) })}
                    key={i} onClick={() => onClick({
                        projectId: x.projectId,
                        projectSort: x.projectSort
                    })}>
                    {x.projectName}
                </div>
            })
        }
    </Card>
}

function TaskDetail(props) {
    const { location: { search } } = props;
    const params = pathParamsFor(search)
    const [project, setProject] = useState({
        projectId: _.get(params, 'projectId'),
        projectSort: _.get(params, 'projectSort')
    });
    const { data, loading, error } = useGet('develop/project/list?'.concat(strParams(_.omit(params, 'id'))));
    return <div className="milepost x">
        <LeftMenu project={project} data={data} loading={loading} error={error} onClick={setProject} />
        <TaskList project={project} {...props} />
    </div>
}

export default TaskDetail;
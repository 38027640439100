import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import './IssueDetailRightPanel.scss'
import Icon from "../../../../../components/Icon";
import { Display, Form, FormInput, Select, Input, RadioGroup, DatePicker } from "rootnet-edit";
import _ from "lodash";
import convertOptions from "../../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { Tooltip } from "antd";
import { strParams } from "../../../../../utils/publicFun";
import { isNil } from 'rootnet-core/format'
import { handleTreeOptions } from "../../../../common/TreeFunc";
import CascadeSelect from "../../../../common/cascadeSelect/CascadeSelect";
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput';

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props} />

const YNOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=BizProcess',
    'common/globalconst?globalConst=occurFrequency',
    // 'common/globalconst?globalConst=EffectGrade_2.0',
    'common/globalconst?globalConst=exchProject',
    'common/globalconst?globalConst=ISSUEKIND',
    'common/globalconst?globalConst=IssueKindDetail',
    'common/globalconst?globalConst=closeType',
    'common/globalconst?globalConst=parProcess',
]

const OPTIONS_URLS = [
    'common/globalconst?globalConst=EffectGrade',
    '/story/getbugProject',
    'common/globalconst?globalConst=ISSUESTYPE',
    'common/globalconst?globalConst=broker',
    'common/globalconst?globalConst=claimFlag',
]

function IssueDetailRightPanel(props) {
    const { isDetail, editFormData, setEditFormData, allUserRes, editFormError, setEditFormError, productTreeOptions,
        productOptions, moduleOptions, deptUserListOptions, allVersionOptionsRes, allVersionOptions, formData, moduleOptionsRes, summaryReasons, setSummaryReasons, isReasonChange, isShowCause, isEditIssueKind } = props
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: custIdRes, doFetch: getCustId } = useGet()
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [baseInfoShowMore, setBaseInfoShowMore] = useState(false)
    const [responsibilityExpand, setResponsibilityExpand] = useState(true)
    const [createInfoExpand, setCreateInfoExpand] = useState(true)
    const { data: relateVersionOptionsRes, doFetch: getRelateVersionOptions } = useGet()
    const [relateVersionSearchParams, setRelateVersionSearchParams] = useState()
    const { data: versionOptionsRes, doFetch: getVersionOptions } = useGet()
    const versionSearchParams = useRef()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const { data: jiraUrlRes } = useGet('/common/globalpara?paraId=Jira')

    const jiraUrl = useMemo(() => {
        return _.get(jiraUrlRes, 'paraValue')
    }, [jiraUrlRes])

    const getVersion = useCallback((editFormData, checkVersion = false) => {
        if (_.isNil(_.get(editFormData, 'issuesType')) || _.isNil(_.get(editFormData, 'custId'))) return
        const searchParams = {}
        searchParams['updateenv'] = _.get(editFormData, 'issuesType') === 'PROD' ? '1' : '0'
        searchParams['customerid'] = _.get(editFormData, 'custId')
        searchParams['updateDate'] = dateFormat('YYYYMMDD')(toDate.str14ToDate(_.get(editFormData, 'occurDate')))
        if (!_.isNil(_.get(editFormData, 'productName'))) {
            const productItem = _.find(productOptions, x => x.value === _.get(editFormData, 'productName'))
            if (_.get(productItem, 'independentVerFlag') === 'Y') {
                searchParams['productid'] = _.get(editFormData, 'productName')
            }
        }
        if (!_.isNil(_.get(editFormData, 'prodsubsysid'))) {
            let moduleOptions = []
            if (_.get(editFormData, 'bizProcess') === '04') {
                const filterModuleOptions = _.filter(moduleOptionsRes, x => ['STOCK', 'FUTURE'].includes(x.extConn))
                moduleOptions = _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
            } else {
                if (_.isNil(_.get(editFormData, 'productName'))) return []
                const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === editFormData.productName)
                moduleOptions = _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
            }
            const productItem = _.find(moduleOptions, x => x.value === _.get(editFormData, 'prodsubsysid'))
            if (_.get(productItem, 'independentVerFlag') === 'Y') {
                searchParams['prodsubsysid'] = _.get(editFormData, 'prodsubsysid')
            }
        }
        if (JSON.stringify(searchParams) === JSON.stringify(versionSearchParams.current)) return
        versionSearchParams.current = searchParams
        getVersionOptions('/customerserviceinfo/selectiveCustomerReleaseRec?' + strParams(searchParams)).then(res => {
            if (!checkVersion) return
            if (_.isEmpty(res)) {
                setEditFormData(old => {
                    if (_.isNil(old)) return
                    old['versionId'] = null
                    return old
                })
            } else {
                const lastVersion = _.get(_.head(res), 'releaseid')
                setEditFormData(old => {
                    if (_.isNil(old)) return
                    old['versionId'] = lastVersion
                    return old
                })
            }
            forceUpdate()
        })
    }, [getVersionOptions, productOptions, moduleOptionsRes, setEditFormData])

    const versionOptions = useMemo(() => {
        if (_.isNil(_.get(editFormData, 'issuesType')) || _.isNil(_.get(editFormData, 'custId'))) {
            return allVersionOptions
        }
        const resultList = _.map(versionOptionsRes, x => ({ text: x.releaseid, value: x.releaseid }))
        const showVersionList = _.isEmpty(resultList) ? allVersionOptions : resultList
        const valueList = _.map(showVersionList, 'value')
        const oldVersionId = _.get(formData, 'versionId')
        if (_.isNil(oldVersionId)) {
            return showVersionList
        } else if (_.includes(valueList, oldVersionId)) {
            return showVersionList
        } else {
            return _.concat([{ text: oldVersionId, value: oldVersionId }], showVersionList)
        }
    }, [editFormData, versionOptionsRes, allVersionOptions, formData])

    useEffect(() => {
        if (_.isNil(formData)) return
        if (_.isNil(_.get(formData, 'custId'))) return
        getCustId('/story/getbugProjectName?id=' + _.get(formData, 'custId'))
    }, [formData, getCustId])

    useEffect(() => {
        if (_.isNil(formData)) return
        getVersion(formData, false)
    }, [formData, getVersion])

    const [bizProcessOptions, occurFrequencyOptions, exchProjectOptions, issueKindOptions, issueKindDetailOptions,
        closeTypeOptions, parProcessOptions] = useMemo(() => {
            if (_.isEmpty(globalConstOptionsRes)) return []
            return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
        }, [globalConstOptionsRes])

    const [effectGradeOptions, custIdOptions, issuesTypeOptions, brokerOptions, claimFlagOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4, d5] = optionsRes
        const filterD1 = _.filter(d1, x => x.displayFlag !== 'N')
        return [
            _.map(filterD1, x => ({ text: x.displayName, value: x.interiorId, memo: x.memo })),
            _.map(d2, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, supportUser: x.supportUser })),
            _.map(d3, x => ({ text: x.interiorId, value: x.interiorId })),
            _.map(d4, x => ({ text: x.interiorId, value: x.interiorId })),
            _.map(d5, x => ({ text: x.displayName, value: x.interiorId })),
        ]
    }, [optionsRes])

    // 关联版本编号
    useEffect(() => {
        if (_.isNil(_.get(editFormData, 'issuesType')) || _.isNil(_.get(editFormData, 'custId'))) return
        const searchParams = {}
        searchParams['updateenv'] = _.get(editFormData, 'issuesType') === 'PROD' ? '1' : '0'
        searchParams['customerid'] = _.get(editFormData, 'custId')
        searchParams['productid'] = isNil(_.get(editFormData, 'relateProductId')) ? null : _.get(editFormData, 'relateProductId')
        if (JSON.stringify(searchParams) === JSON.stringify(relateVersionSearchParams)) return
        setRelateVersionSearchParams(searchParams)
        getRelateVersionOptions('/customerserviceinfo/selectiveCustomerReleaseRec?' + strParams(searchParams))
    }, [editFormData, getRelateVersionOptions, relateVersionSearchParams])

    const relateVersionOptions = useMemo(() => {
        if (_.isNil(_.get(editFormData, 'issuesType')) || _.isNil(_.get(editFormData, 'custId'))) return []
        const resultList = _.map(relateVersionOptionsRes, x => ({ text: x.releaseid, value: x.releaseid }))
        return _.isEmpty(resultList) ? allVersionOptions : resultList
    }, [editFormData, relateVersionOptionsRes, allVersionOptions])

    // 外挂版本
    const exConnVersionOptions = useMemo(() => {
        const filterList = _.filter(allVersionOptionsRes, x => x.productid === 'TradeAdapter')
        const sortList = _.sortBy(filterList, x => {
            if (_.isNil(x.updateDate)) {
                return 0
            }
            return new Date(x.updateDate).getMilliseconds()
        })
        return _.map(sortList, x => ({ text: x.releaseid, value: x.releaseid }))
    }, [allVersionOptionsRes])

    const changeForm = useCallback((newObj, bind) => {
        setEditFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'effectGrade') {
                if (_.get(formData, 'effectGrade') === newObj[bind]) {
                    cloneObj['severityRemarks'] = null
                    setSummaryReasons(x => _.assign({}, x, { severity: false }))
                    setEditFormError(x => _.assign({}, x, { severityRemarks: null }))
                } else setSummaryReasons(x => _.assign({}, x, { severity: true }))
            }
            // 关联系统和客户
            if (bind === 'custId') {
                cloneObj['customer'] = null
                getCustId('/story/getbugProjectName?id=' + _.get(newObj, 'custId')).then(custIdRes => {
                    const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
                    const customer = _.get(filterStatus, '0.customer')
                    changeForm({ customer }, 'customer')
                })
            }
            if (bind === 'bizProcess') {
                // 业务流程环节 为 04 时, 清空 对接券商 和 外挂版本
                if (newObj['bizProcess'] !== '04' && _.get(oldObj, 'productName') !== 'TradeAdapter') {
                    cloneObj['extConntoBrokers'] = null
                    cloneObj['exconnVer'] = null
                }
                // 业务流程环节 切换04和其他值时，清空子产品
                if (newObj['bizProcess'] === '04' && _.get(oldObj, 'bizProcess') !== '04') {
                    cloneObj['prodsubsysid'] = null
                }
                if (newObj['bizProcess'] !== '04' && _.get(oldObj, 'bizProcess') === '04') {
                    cloneObj['prodsubsysid'] = null
                }
            }
            if (bind === 'productName') {
                cloneObj['prodsubsysid'] = null
                if (newObj['productName'] !== 'TradeAdapter' && _.get(oldObj, 'bizProcess') !== '04') {
                    cloneObj['extConntoBrokers'] = null
                    cloneObj['exconnVer'] = null
                }
            }
            if (bind === 'issueKind') {
                cloneObj['issueKindDetail'] = null
            }
            if (bind === 'relateProductId') {
                cloneObj['relateVersionId'] = null
            }
            getVersion(cloneObj, true)
            if (bind === 'issueKind') {
                if (_.size(newObj['issueKind']) > 1 && _.includes(newObj['issueKind'], '13')) {
                    cloneObj['issueKind'] = _.filter(newObj['issueKind'], x => x !== "13")
                }
                cloneObj['issueKindDetail'] = null
            }
            return cloneObj
        })
    }, [setEditFormData, getCustId, getVersion, formData, setSummaryReasons, setEditFormError])

    const effectGradeTips = useMemo(() => {
        return <div className={'effect-grade-tips'}>
            严重程度详细信息
            {
                _.map(effectGradeOptions, (item, i) => <div key={`effectGrade-${item.value}`} className={'effect-grade-tips-item'}>
                    <br />
                    【{i + 1}-{item.text}】:
                    <br />
                    {`    ${item.memo}`}
                </div>)
            }
            <br />
            <div style={{ height: 22 }} />
            <div>修改权限：创建人可改</div>
        </div>
    }, [effectGradeOptions])

    const brokersRequired = useMemo(() => {
        return _.get(editFormData, 'bizProcess') === '04'
    }, [editFormData])

    const customerOptions = useMemo(() => {
        if (_.isEmpty(custIdRes)) return []
        const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
        return _.map(filterStatus, x => ({ text: x.customerName, value: x.customer, supportUser: x.supportUser }))
    }, [custIdRes])

    // useEffect(()=>{
    //     if(_.isEmpty(custIdRes)) return
    //     const valueList = _.map(custIdRes,'customer')
    //     if(!_.isNil(editFormData) && !_.includes(valueList, _.get(editFormData,'customer'))){
    //         const customer = _.get(custIdRes,'0.customer')
    //         changeForm({customer})
    //     }
    // },[custIdRes, editFormData, changeForm])

    useEffect(() => {
        if (brokersRequired) {
            if (_.isEmpty(_.get(editFormData, 'extConntoBrokers'))) {
                setEditFormError(x => _.assign({}, x, { 'extConntoBrokers': '必填项！' }))
            }
            if (_.isEmpty(_.get(editFormData, 'exconnVer'))) {
                setEditFormError(x => _.assign({}, x, { 'exconnVer': '必填项！' }))
            }
            if (_.isEmpty(_.get(editFormData, 'prodsubsysid'))) {
                setEditFormError(x => _.assign({}, x, { 'prodsubsysid': '必填项！' }))
            }
        } else {
            setEditFormError(x => _.assign({}, x, { 'extConntoBrokers': null, 'exconnVer': null, 'prodsubsysid': null }))
        }
    }, [editFormData, brokersRequired, setEditFormError])

    const exchProjectRequired = useMemo(() => {
        return _.get(editFormData, 'isExch') === 'Y'
    }, [editFormData])

    useEffect(() => {
        if (_.get(editFormData, 'isExch') === 'Y' && _.isNil(_.get(editFormData, 'exchProject'))) {
            setEditFormError(x => _.assign({}, x, { 'exchProject': '必填项！' }))
        } else {
            setEditFormError(x => _.assign({}, x, { 'exchProject': null }))
        }
    }, [editFormData, setEditFormError])

    useEffect(() => {
        const markReason = _.get(editFormData, 'markReason')
        setEditFormError(x => _.assign({}, x, { markReason: isShowCause ? isNil(markReason) ? '必填项！' : null : null }))
    }, [editFormData, setEditFormError, isShowCause])

    const filterIssueKindDetailOptions = useMemo(() => {
        const issueKind = _.get(editFormData, 'issueKind')
        if (_.isEmpty(issueKind)) return []
        return _.filter(issueKindDetailOptions, x => _.includes(issueKind, x.memo))
    }, [editFormData, issueKindDetailOptions])

    const limitIssueKind = useMemo(() => {
        return ['08', '03', '04', '05', '06', '10'].includes(editFormData?.issueStatus)
    }, [editFormData])

    const handleIssueKindOptions = useMemo(() => {
        if (limitIssueKind) {
            return _.filter(issueKindOptions, x => x.value !== '13')
        } else {
            return issueKindOptions
        }
    }, [issueKindOptions, limitIssueKind])

    const treeIssueKindOptions = useMemo(() => {
        if (_.isEmpty(handleIssueKindOptions)) return []
        const firstLevelList = _.filter(handleIssueKindOptions, x => _.isNil(x.pid))
        const issueKind = _.get(editFormData, 'issueKind')
        let disabledList = []
        if (isEditIssueKind) {
            if (!_.isEmpty(issueKind) && !_.includes(issueKind, '13')) {
                const pid = _.get(_.find(handleIssueKindOptions, v => v.value === _.head(issueKind)), 'pid')
                disabledList = _.map(_.filter(handleIssueKindOptions, x => !(x.value === pid || x.pid === pid)), 'value')
            }
        } else {
            const initIssueKind = _.get(formData, 'issueKind')
            const findItem = _.find(handleIssueKindOptions, v => v.value === _.head(initIssueKind))
            const pid = _.get(findItem, 'pid')
            disabledList = _.map(_.filter(handleIssueKindOptions, x => !(x.value === pid || x.pid === pid)), 'value')
        }
        const issueKindList = handleTreeOptions(handleIssueKindOptions, firstLevelList, 'value', 'pid', 'text', disabledList)
        return issueKindList
    }, [isEditIssueKind, handleIssueKindOptions, formData, editFormData])

    useEffect(() => {
        if (!isShowCause) setEditFormData(x => _.assign({}, x, { markReason: null }))
    }, [isShowCause, setEditFormData])

    return <div className={'issue-detail-right-panel flex-y'}>
        <div className="area-wrap base-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
                基本信息
            </div>
            <div className="area-content-wrap" style={{ height: baseInfoExpand ? (baseInfoShowMore ? '' : _.get(summaryReasons, 'severity') ? 638 : 606) : 0 }}>
                <Form value={editFormData} onChange={changeForm} userActive={false} error={editFormError} onError={v => setEditFormError(x => _.assign({}, x, v))}>
                    <HFormInput label={'分类标记'} bind={'issuesType'} required component={isDetail ? Display : Select}
                        options={issuesTypeOptions} convert={v => convertOptions(v, issuesTypeOptions)} />
                    <HFormInput label={'严重程度'} bind={'effectGrade'} required component={isDetail ? Display : Select}
                        options={effectGradeOptions} convert={v => convertOptions(v, effectGradeOptions)} disabled={!isReasonChange} />
                    <Tooltip title={effectGradeTips} overlayStyle={{ width: 740, maxWidth: 740, whiteSpace: 'pre-wrap' }}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    {
                        (_.get(summaryReasons, 'severity') && !isDetail) &&
                        <HFormInput label={'变更理由'} bind={'severityRemarks'} required component={TextAreaInput} placeholder='严重程度变更理由' />
                    }
                    <HFormInput label={'发生日期'} bind={'occurDate'} required component={isDetail ? Display : DatePicker}
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }} />
                    <HFormInput label={'问题性质'} bind={'issueKind'} multiple search required component={isDetail ? Display : Select} tree clear
                        options={treeIssueKindOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, issueKindOptions)), '、')}>
                            <div className={'ellipsis-line'}>
                                {_.join(_.map(v, x => convertOptions(x, issueKindOptions)), '、')}
                            </div>
                        </Tooltip>} />
                    <Tooltip title={`问题性质仅允许同一个大类下多选，跨一级的变更，请联系刘凯或所属部门产品总监`}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'问题性质细分'} bind={'issueKindDetail'} multiple search clear component={isDetail ? Display : Select}
                        options={filterIssueKindDetailOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, issueKindDetailOptions)), '、')}>
                            <div className={'ellipsis-line'}>
                                {_.join(_.map(v, x => convertOptions(x, issueKindDetailOptions)), '、')}
                            </div>
                        </Tooltip>} />
                    <HFormInput label={'关闭类型'} bind={'closeType'} component={Display} convert={v => convertOptions(v, closeTypeOptions) || v} />
                    <HFormInput label={'客户系统'} bind={'custId'} required search component={isDetail ? Display : Select}
                        options={custIdOptions} convert={v => convertOptions(v, custIdOptions)} />
                    <HFormInput label={'客户名称'} bind={'customer'} required search component={isDetail ? Display : Select}
                        options={customerOptions} convert={v => convertOptions(v, customerOptions)} />
                    <HFormInput label={'业务流程环节'} bind={'bizProcess'} required component={isDetail ? Display : Select}
                        options={bizProcessOptions} convert={v => convertOptions(v, bizProcessOptions)} />
                    <HFormInput label={'产研流程环节'} bind={'parProcess'} search clear component={isDetail ? Display : CascadeSelect}
                        options={parProcessOptions} convert={v => convertOptions(v, parProcessOptions)} />
                    <HFormInput label={'产品名称'} bind={'productName'} required search tree component={isDetail ? Display : Select}
                        options={productTreeOptions} convert={v => convertOptions(v, productOptions)} />
                    <HFormInput label={'子产品名称'} bind={'prodsubsysid'} required={brokersRequired} search component={isDetail ? Display : Select}
                        options={moduleOptions} convert={v => convertOptions(v, moduleOptions)} componentClass={'long-options'} />
                    <HFormInput label={'影响交易'} bind={'timeRange'} required component={isDetail ? Display : RadioGroup}
                        options={YNOptions} convert={v => convertOptions(v, YNOptions)} />
                    <HFormInput label={'解决时间要求'} bind={'promiseDate'} required component={isDetail ? Display : DatePicker}
                        bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }} />
                    <HFormInput label={'客户环境版本编号'} bind={'versionId'} required search component={isDetail ? Display : Select}
                        options={versionOptions} convert={v => {
                            return convertOptions(v, versionOptions)
                        }} componentClass={'long-options'} />
                    {
                        isShowCause &&
                        <>
                            <HFormInput required label={'TESTONLY原因'} bind={'markReason'} component={isDetail ? Display : Input} convert={v => {
                                return <Tooltip title={v}>
                                    <div className={'ellipsis-line'}>{v}</div>
                                </Tooltip>
                            }} />
                            <Tooltip title={'分类标记选择testonly，客户环境版本编号为正式版本，需要说明原因！'} overlayStyle={{ width: 740, maxWidth: 740, whiteSpace: 'pre-wrap' }}>
                                <span>
                                    <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                </span>
                            </Tooltip>
                        </>
                    }
                    <HFormInput label={'投诉/索赔标识'} bind={'claimFlag'} component={isDetail ? Display : Select}
                        options={claimFlagOptions} convert={v => convertOptions(v, claimFlagOptions)} />
                    <HFormInput label={'交易所问题'} bind={'isExch'} required component={isDetail ? Display : RadioGroup}
                        options={YNOptions} convert={v => convertOptions(v, YNOptions)} />
                    <HFormInput label={'交易所项目'} bind={'exchProject'} required={exchProjectRequired} search clear component={isDetail ? Display : Select}
                        options={exchProjectOptions} convert={v => convertOptions(v, exchProjectOptions)} />
                    <HFormInput label={'不通过次数'} bind={'noPassNum'} component={Display} />
                    <HFormInput label={'jiraId'} bind={'jiraId'} component={Display}
                        convert={v => v ? <div className={'jira-id'} onClick={() => {
                            window.open(jiraUrl + v, "_blank")
                        }}>
                            {v}
                        </div> : null} />
                    <HFormInput label={'对接券商'} bind={'extConntoBrokers'} required={brokersRequired} disabled={!brokersRequired} search multiple component={isDetail ? Display : Select}
                        options={brokerOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, brokerOptions)), '、')}>
                            <div className={'ellipsis-line'}>
                                {_.join(_.map(v, x => convertOptions(x, brokerOptions)), '、')}
                            </div>
                        </Tooltip>} />
                    <Tooltip title={`业务流程环节为【外挂接口】时必填`}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'外挂版本'} bind={'exconnVer'} required={brokersRequired} disabled={!brokersRequired} search component={isDetail ? Display : Select}
                        options={exConnVersionOptions} convert={v => convertOptions(v, exConnVersionOptions)} componentClass={'long-options'} />
                    <Tooltip title={`业务流程环节为【外挂接口】时必填`}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'关联产品'} bind={'relateProductId'} search tree clear component={isDetail ? Display : Select}
                        options={productTreeOptions} convert={v => convertOptions(v, productOptions)} />
                    <HFormInput label={'关联版本'} bind={'relateVersionId'} search clear component={isDetail ? Display : Select}
                        options={relateVersionOptions} convert={v => convertOptions(v, relateVersionOptions)} componentClass={'long-options'} />
                    <HFormInput label={'发生频率'} bind={'occurFrequency'} clear component={isDetail ? Display : Select}
                        options={occurFrequencyOptions} convert={v => convertOptions(v, occurFrequencyOptions)} />
                    <HFormInput label={'客户问题编号'} bind={'clientIssueNo'} component={isDetail ? Display : Input} />
                </Form>
            </div>
            <div className={'base-info-show-more'} style={{ display: baseInfoExpand ? 'block' : 'none' }}>
                <span className={'base-info-show-more-text'} onClick={() => setBaseInfoShowMore(v => !v)}>
                    {baseInfoShowMore ? '收起更多' : '展开更多'}
                </span>
            </div>
        </div>
        {
            _.get(editFormData, 'affirmPlayers') &&
            <div className="area-wrap responsibility-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: responsibilityExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setResponsibilityExpand(v => !v)} />
                    责任认定信息
                </div>
                <div className="area-content-wrap" style={{ height: responsibilityExpand ? '' : 0 }}>
                    <Form value={editFormData}>
                        <HFormInput label={'主要责任归属'} bind={'mainResponsor'} component={Display} convert={v => convertOptions(v, deptUserListOptions)} />
                        <HFormInput label={'相关责任归属'} bind={'infeResponsor'} component={Display} convert={v => convertOptions(v, deptUserListOptions)} />
                        <HFormInput label={'认定人'} bind={'affirmPlayers'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                        <HFormInput label={'认定时间'} bind={'affirmDate'} component={Display}
                            convert={v => {
                                if (_.isNil(v)) return '-'
                                if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                            }} />
                    </Form>
                </div>
            </div>
        }
        <div className="area-wrap create-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: createInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCreateInfoExpand(v => !v)} />
                创建信息
            </div>
            <div className="area-content-wrap" style={{ height: createInfoExpand ? '' : 0 }}>
                <Form value={editFormData}>
                    <HFormInput label={'创建人'} bind={'inputUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                    <HFormInput label={'创建时间'} bind={'inputDate'} component={Display}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                        }}
                    />
                    <HFormInput label={'最后修改人'} bind={'updateUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                    <HFormInput label={'最后修改时间'} bind={'lastUpdateTime'} component={Display}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                        }}
                    />
                </Form>
            </div>
        </div>
    </div>
}

export default IssueDetailRightPanel;
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { isNil, GetTime } from 'rootnet-core/format'
import { Card, DataGrid, Select, Tag } from 'rootnet-ui';
import { Icon } from '../../components';
import { useGet } from '../../utils/hook';
import _ from 'lodash';
import { GetDate, Dateformat } from 'rootnet-core/format';
import './WorkBench.scss';

function display(d, member) {
    if (_.isNumber(d) || _.isString(d))
        return _.toString(d);
    if (_.isObject(d))
        return _.get(d, member);
    return "-";
}
function Summary(props) {
    return <div className="summary">
        <Icon name={props.icon} className={props.alert ? "alert" : undefined} />
        <div className="data">
            <span>{display(props.value, props.member)}</span>
            <span>{props.text}</span>
        </div>
    </div>
}

const periodList = [
    { text: '本周', period: 'week' },
    { text: '最近一月', period: 'month' },
    { text: '最近三月', period: 'quarter' }
];
function Statistics(props) {
    const { data, doFetch } = useGet();
    const [showPicker, setShowPicker] = useState(false);
    const [index, setIndex] = useState(0);
    const timeType = useMemo(() => periodList[index], [index]);
    useEffect(() => {
        const url = `develop/tracer/completion?timeType=${timeType.period}`;
        doFetch(url);
    }, [doFetch, timeType]);

    return (
        <>
            <div className="statistics">
                <div>
                    <Summary text="在途事项" icon="shengyurenwu" value={props.count} alert />
                </div>
                <div>
                    <Summary text="开发完成" icon="kaifawancheng" value={data} member="devCompletions" />
                    <Summary text="设计完成" icon="shejiwancheng" value={data} member="designCompletions" />
                    <Summary text="测试完成" icon="ceshiwancheng" value={data} member="testCompletions" />
                    <Summary text={`${timeType.text}完成`} icon="wancheng" value={data} member="allCompletions" />
                    <Icon name="shenglve" onClick={() => setShowPicker(true)} />
                </div>
            </div>
            {showPicker && <Picker onClick={(i) => { setIndex(i); setShowPicker(false) }} onClickOutside={() => setShowPicker(false)} defaultIndex={index} list={periodList} />}
        </>
    )
}

function TurnTo(props) {
    const { id, path, content } = props
    const history = useHistory();
    return <span className='workbenchToTask' onClick={handleClick}>{isNil(content) ? '-' : content}</span>
    function handleClick() {
        history.push(`/${path}/${id}`)
    }
}

function generateTags(o) {
    function getColor(type) {
        switch (type) {
            case "研发任务": return "#5477FF";
            case "任务": return "#934BEB";
            case "BUG": return "#F5AF3D";
            case "ISSUE": return "#FF2929";
            default: return "#5477FF";
        }
    }
    const tags = [];
    if (new Date() > GetDate(o.completeDate))
        tags.push(<Tag key="expired" color="#F76D43">超期</Tag>)
    tags.push(<Tag key="type" color={getColor(o.type)}>{o.type}</Tag>);
    return tags;
}

const optionFor = ({ options_role, options_status }) => {
    const statusConvert = (v) => options_status[v];
    const userRoleConvet = (v) => options_role[v];
    return {
        columns: [
            { header: "任务", bind: "title", convert: (v, o) => o.type === '任务' ? <TurnTo id={o.taskId} path={'task/detail'} content={v} /> : v, width: 340, tooltip: true },
            { header: "任务ID", bind: "taskId", width: 185 },
            { header: "截止日期", bind: "completeDate", width: 112, convert: (v) => Dateformat(v) === Dateformat(GetTime(new Date())) ? '今天' : Dateformat(v) },
            { header: "项目", bind: "projectName", width: 190,tooltip: true },
            { header: "状态", bind: "status", width: 160, convert: statusConvert },
            { header: "角色", bind: "userRole", convert: userRoleConvet },
            { header: "", width: 600, bind: "type", convert: (v, o) => generateTags(o) }
        ],
        // autoFill: true,
        virtualized: false
    };
}

const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const handleClick = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref, callback]);
};

function Picker(props) {
    const { list, defaultIndex, onClick, onClickOutside } = props;
    const [currentIndex, setCurrentIndex] = useState(defaultIndex);
    const ref = useRef(null)
    useClickOutside(ref, onClickOutside)
    return (
        <div className="periodPicker" ref={ref}>
            <ul>
                {
                    list.map((e, i) => <li className='item' key={e.period} onClick={() => { setCurrentIndex(i); onClick(i) }}>
                        <div className='item-icon'>{currentIndex === i && <Icon name="Checkmark" style={{ color: '#5477FF' }} />}</div>
                        <span className='item-text'>{e.text}</span>
                    </li>)
                }
            </ul>
        </div>
    )
}
Picker.defaultProps = {
    defaultIndex: 0,
    list: [],
    onClick: () => { },
    onclickoutside: () => { }
};
const urls = ["/develop/unfinishedlist", "/common/globalconst?globalConst=USERROLE", "/common/globalconst?globalConst=taskstatus", "/common/globalconst?globalConst=TRACERSTATE"]

function TracerList(props) {
    const { data, loading, error } = useGet(urls);
    const { setCount } = props;
    const [select, setSelect] = useState("all");
    const [select_options, options_role, options_status] = useMemo(() => {
        const d1 = data ? data[0] : null;
        const d2 = data ? data[1] : null;
        const d3 = data ? data[2] : null;
        const d4 = data ? data[3] : null;
        const ops_select = [{ text: "全部", value: "all" }, ..._.map(_.uniq(_.map(d1, o => o.type)), v => { return { text: v, value: v } })]
        const ops_m = {};
        _.map(d2, o => { ops_m[o.interiorId] = o.displayName });
        const ops_s = {};
        _.map(d3, o => { ops_s[o.interiorId] = o.displayName });
        _.map(d4, o => { ops_s[o.interiorId] = o.displayName });
        return [ops_select, ops_m, ops_s];
    }, [data]);

    const result = useMemo(() => {
        const d1 = data ? data[0] : [];
        if (select === "all")
            return d1;
        return _.filter(d1, o => o.type === select);
    }, [data, select])

    useEffect(() => {
        const d1 = data ? data[0] : null;
        if (_.isNil(d1))
            setCount("-");
        else if (_.isArray(d1))
            setCount(d1.length);
    }, [data, setCount])

    const option = useMemo(()=>optionFor({ options_role, options_status }),[options_role, options_status])

    return <Card title="待办任务" className="list" loading={loading} error={error} style={{ overflow: 'hidden' }} extra={<Select options={select_options} defaultValue={select} onChange={(e, o) => setSelect(o.value)} />}>
        <DataGrid className='no_across_line' option={option} data={result} />
    </Card>
}




export default function WorkBench(props) {
    const [count, setCount] = useState("-");
    return <div className="fill flex-y v-workbench">
        <Statistics count={count} />
        <TracerList setCount={setCount} />
    </div>
}
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from "lodash";
import {FormInput, Select} from "rootnet-edit";
import {Dialog, Loader} from "rootnet-ui";
import {useGet, usePost} from "rootnet-biz/lib/hooks";
import {errorTips, successTips} from "../../../../../../../utils/messagerTips";

function TraceRelateNewBranchDialog(props) {
    const {close, showDialogInfo, relateBizId, traceDetail, refreshList} = props
    const [branch, setBranch] = useState()
    const {doFetch: submitPost} = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)

    const {data: branchList, doFetch: getBranchList} = useGet()

    useEffect(()=>{
        if(_.isNil(showDialogInfo)) return
        getBranchList('/devops/uprods/trace/program/queryRecommendedBranch?traceId='+showDialogInfo?.componentInfo?.traceId)
    },[getBranchList, showDialogInfo])

    const branchListOptions = useMemo(()=>{
        return _.map(branchList, x => ({
            text: x,
            value: x
        }))
    },[branchList])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const submitParams = {
            traceId: showDialogInfo?.componentInfo?.traceId,
            programId: showDialogInfo?.componentInfo?.programId,
            traceName: traceDetail?.tracerTitle,
            reqNewNo: relateBizId,
            baseBranchName: branch
        }
        submitPost('/devops/uprods/trace/program/addFeatureBranch',submitParams).then(()=>{
            setSubmitLoading(false)
            successTips('新增成功')
            refreshList()
            close()
        }).catch(err => {
            setSubmitLoading(false)
            errorTips(err._message)
        })
    },[showDialogInfo, submitPost, traceDetail, relateBizId, branch, refreshList, close, submitLoading])

    return <Dialog header={'创建特性分支'} className={'trace-relate-new-branch-dialog'} cancel={close} contentStyle={{padding: 16}}
                   confirmButtonDisabled={_.isNil(branch)} confirm={submit}>
        {
            submitLoading && <Loader fill/>
        }
        <FormInput label={'源分支（基线版本）'} value={branch} onChange={setBranch} componentWidth={280} component={Select} search
                   options={branchListOptions}/>
    </Dialog>
}

export default TraceRelateNewBranchDialog;
import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import './CheckConditionGroup.scss'
import {uniqKeyFor} from "../../../../project_share/utils/utils";
import _ from 'lodash'
import Icon from "../../../../project_share/components/Icon";
import {Popover, Tooltip} from "antd";
import clsx from "clsx";
import {Form, FormInput, Select} from "rootnet-edit";
import {TextIconBtn} from "../../TextIconBtn";
import {isNil} from "../../../appraise/components/method";
import {Button} from "rootnet-ui";
import DynamicFormInput from "../viewCondition/DynamicFormInput";
import {useGet} from "rootnet-biz/lib/hooks";

const relationOptions = [
    { text: '与（and）', value: 'and' },
    { text: '或（or）', value: 'or' },
]

const dynamicOptions = [
    { text: '固定值', value: 'n' },
    { text: '动态值', value: 'y' },
]

const dynamicOptionsUrl = [
    '/common/globalconst?globalConst=dynamicTime',
    '/common/globalconst?globalConst=dynamicDepartment',
    '/common/globalconst?globalConst=dynamicPersonnel',
    '/common/globalconst?globalConst=dynamicDate',
]

// const initData = {
//     isConditionGroup: true,
//     conditionRelation: 'and',
//     conditionList: [
//         {
//             isConditionGroup: false,
//             field: null,
//             relation: null,
//             value: null
//         },
//     ]
// }

function CheckConditionGroup(props) {
    const {allColumns, editData, setEditData, readOnly = false} = props
    const [showRelationSelect, setShowRelationSelect] = useState(false)
    const [relationInfo, setRelationInfo] = useState()
    const [countUpdate, forceUpdate] = useReducer((x) => x + 1, 0)
    const {data: viewConditionTypeRes} = useGet('/common/globalconst?globalConst=viewConditionType')
    const {doFetch: getConditionOptions} = useGet()
    const {data: dynamicOptionsRes} = useGet(dynamicOptionsUrl)
    const [relationOptionsGroup, setRelationOptionsGroup] = useState([])

    const [dynamicTimeOptions, dynamicDepartmentOptions, dynamicPersonOptions, dynamicDateOptions] = useMemo(()=>{
        if(_.isEmpty(dynamicOptionsRes)) return []
        return _.map(dynamicOptionsRes, x => convertDynamicOptions(x))

        function convertDynamicOptions(list){
            return _.map(list, x => ({ text: x.displayName, value: x.memo }))
        }
    },[dynamicOptionsRes])

    const allColumnsOptions = useMemo(()=>{
        if(_.isEmpty(allColumns)) return []
        const filterColumnsOptions = _.filter(allColumns, x => {
            return _.get(x,'custom') !== 'Y' && _.get(x, 'filterDisplayFlag') !== 'N' && _.get(x, 'filterDisplayFlag') !== '04'
        })
        return _.map(filterColumnsOptions, x => ({
            text: `${x.fieldName}（${x.tableName}.${x.fieldId}）`,
            value: `${x.tableName}.${x.fieldId}`,
            id: x.id,
            viewConditionType: x.viewConditionType,
            fieldDateSource: x.fieldDateSource,
            fieldDateDetailSource: x.fieldDateDetailSource,
            fieldName: x.fieldName,
            custom: x.custom,
            filterDisplayFlag: x.filterDisplayFlag,
            viewDisplayFlag:x.viewDisplayFlag,
            fieldHtmlType: x.fieldHtmlType
        }))
    },[allColumns])

    useEffect(()=>{
        if(_.isNil(viewConditionTypeRes)) return
        const urls = _.map(viewConditionTypeRes, x => `/common/globalconst?globalConst=${x.interiorId}`)
        getConditionOptions(urls).then(res => {
            let relationOptionsGroup = {}
            _.forEach(viewConditionTypeRes, (x, i) => {
                relationOptionsGroup[x.interiorId] = convertRelationOptions(res[i])
            })
            setRelationOptionsGroup(relationOptionsGroup)
        })
        function convertRelationOptions(list){
            return _.map(list, x => ({ text: `${x.displayName}(${x.memo})`, value: x.memo }))
        }
    },[viewConditionTypeRes, getConditionOptions])

    const getRelationOptions = useCallback((fieldId)=>{
        const viewConditionType = _.get(_.find(allColumnsOptions, x => x.value === fieldId),'viewConditionType')
        return relationOptionsGroup[viewConditionType]
    },[relationOptionsGroup, allColumnsOptions])

    const changeRelation = useCallback((newValue)=>{
        const key = _.get(relationInfo, 'key')
        setEditData(old => {
            _.set(old, key , newValue)
            return old
        })
        forceUpdate()
    },[relationInfo, setEditData])

    const RelationSelect = useCallback(()=>{
        return <div className={'condition-relation-select flex-y'}>
            {
                _.map(relationOptions, item => (<div className={clsx('relation-item flex center-y',{'current-relation': item.value === _.get(relationInfo,'relation')})}
                                                     key={item.value} onClick={()=> {
                    changeRelation(item.value)
                    setShowRelationSelect(false)
                }}>
                    {item.text}
                </div>))
            }
        </div>
    },[relationInfo, changeRelation])

    const changeForm = useCallback((newObj, bind, key) => {
        setEditData(old => {
            _.set(old, key+'.'+bind, newObj[bind])
            if(bind === 'field'){
                _.set(old, key+'.value', null)
                _.set(old, key+'.dynamicSign', 'n')
                const relationOptions = getRelationOptions(_.get(newObj, 'field'))
                if(!_.includes(relationOptions,newObj['relation'])){
                    _.set(old, key+'.relation', _.get(relationOptions, '0.value'))
                }
            }
            if(bind === 'relation'){
                _.set(old, key+'.value', null)
                if(newObj['relation'] === 'dynamicTime'){
                    _.set(old, key+'.firstCount', 0)
                    _.set(old, key+'.firstDTStyle', 'beforeTheMonth')
                    _.set(old, key+'.secondCount', 0)
                    _.set(old, key+'.secondDTStyle', 'beforeTheMonth')
                }
                if(!_.includes(['In', 'Not In','=','<>','>=', '<='],newObj['relation'])){
                    _.set(old, key+'.dynamicSign', 'n')
                }
            }
            if(bind === 'dynamicSign'){
                _.set(old, key+'.value', null)
            }
            if(_.get(newObj,'relation') === 'dynamicTime'){
                const value = {
                    firstCount: newObj['firstCount'],
                    firstDTStyle: newObj['firstDTStyle'],
                    secondCount: newObj['secondCount'],
                    secondDTStyle: newObj['secondDTStyle'],
                }
                _.set(old, key+'.value', JSON.stringify(value))
            }
            return old
        })
        if(_.includes(['field','relation','dynamicSign'], bind)){
            forceUpdate()
        }
    },[getRelationOptions, setEditData])

    const addConditionField = useCallback((outerKey, innerIndex)=>{
        const newItem = {
            isConditionGroup: false,
            field: null,
            relation: null,
            value: null
        }
        setEditData(old => {
            const list = _.get(old, outerKey) || []
            if(_.isNil(innerIndex)){
                _.set(old, outerKey, _.concat(list, [newItem]))
            }else{
                _.set(old, outerKey, _.concat(_.slice(list, 0, innerIndex+1),[newItem],_.slice(list, innerIndex+1)))
            }
            return old
        })
        forceUpdate()
    },[setEditData])

    const delConditionField = useCallback((outerKey, innerIndex)=>{
        setEditData(old => {
            const list = _.get(old, outerKey)
            _.set(old, outerKey, _.concat(_.slice(list, 0, innerIndex),_.slice(list, innerIndex+1)))
            return old
        })
        forceUpdate()
    },[setEditData])

    const addConditionGroup = useCallback(outerKey => {
        const newItem = {
            isConditionGroup: true,
            conditionRelation: 'and',
            conditionList: [
                {
                    isConditionGroup: false,
                    field: null,
                    relation: null,
                    value: null
                },
            ]
        }
        setEditData(old => {
            const list = _.get(old, outerKey) || []
            _.set(old, outerKey, _.concat(list, [newItem]))
            return old
        })
        forceUpdate()
    },[setEditData])

    const delConditionItem = useCallback((parentOuterKey, index)=>{
        setEditData(old => {
            if(isNil(parentOuterKey)){
                return null
            }
            const list = _.get(old, parentOuterKey)
            _.set(old, parentOuterKey, _.concat(_.slice(list, 0, index),_.slice(list, index+1)))
            return old
        })
        forceUpdate()
    },[setEditData])

    const addConditionItem = useCallback(()=>{
        setEditData({
            isConditionGroup: true,
            conditionRelation: 'and',
            conditionList: []
        })
    },[setEditData])

    const delAbandonField = useCallback((contentProps)=>{
        const {item, parentKey = ''} = contentProps
        const outerKey = parentKey + 'conditionList'
        const delPathList = []
        _.map(item.conditionList, (innerItem, innerIndex) => {
            if(innerItem.isConditionGroup){
                delAbandonField({item: innerItem, parentKey: `${outerKey}.${innerIndex}.`})
            }else{
                const innerKey = `${outerKey}.${innerIndex}`
                const field = _.get(editData, innerKey + '.field')
                if(!_.isEmpty(allColumnsOptions) && !_.isNil(field)){
                    const allField = _.map(allColumnsOptions, 'value')
                    if(!_.includes(allField, field)){
                        delPathList.unshift({
                            outerKey,
                            innerIndex
                        })
                    }
                }
            }
        })
        _.map(delPathList, x => {
            delConditionField(x.outerKey, x.innerIndex)
        })
    },[editData, allColumnsOptions, delConditionField])

    useEffect(()=>{
        if(_.isNil(editData)) return
        delAbandonField({item: editData})
    },[editData, delAbandonField])

    const ConditionContent = useCallback(contentProps => {
        const {item, index, parentKey = '', parentOuterKey = ''} = contentProps
        const relationKey = parentKey + 'conditionRelation'
        const outerKey = parentKey + 'conditionList'
        return <div className="condition-group-wrap" key={uniqKeyFor()}>
            <div className="condition-group flex center-y">
                <div className="relation-line"/>
                <Popover content={<RelationSelect/>} trigger="click" placement="bottom"
                         open={showRelationSelect && _.get(relationInfo,'key') === relationKey && !readOnly}
                         onOpenChange={visible => {
                             setShowRelationSelect(visible)
                         }} overlayStyle={{paddingTop: 0}}>
                    <div className="relation-select flex center-y" onClick={()=>{
                        setRelationInfo({
                            relation: item.conditionRelation,
                            key: relationKey
                        })
                    }}>
                        <div className="relation-select-text flex center">
                            {item.conditionRelation}
                        </div>
                        <Icon name={'jiantou-xiangxia'} className={'select-icon'}/>
                    </div>
                </Popover>
                <div className="condition-list flex-y">
                    {
                        _.map(item.conditionList, (innerItem, innerIndex) => {
                            if(innerItem.isConditionGroup){
                                return ConditionContent({item: innerItem, index: innerIndex, parentKey: `${outerKey}.${innerIndex}.`, parentOuterKey: outerKey})
                            }else{
                                const innerKey = `${outerKey}.${innerIndex}`
                                const viewConditionType = _.get(_.find(allColumnsOptions, x => x.value === _.get(innerItem,'field')),'viewConditionType')
                                const showDeptDynamicSign = viewConditionType === 'departmentType' && _.includes(['In', 'Not In'],innerItem.relation)
                                const showDateDynamicSign = viewConditionType === 'date' && _.includes(['>=', '<='],innerItem.relation)
                                const showUserDynamicSign = viewConditionType === 'personnel' && _.includes(['In', 'Not In','=','<>'],innerItem.relation)
                                const showDynamicSign = showDeptDynamicSign || showDateDynamicSign || showUserDynamicSign
                                return <div className="condition-item flex center-y" key={uniqKeyFor()}>
                                    <Form value={innerItem} onChange={(newObj, bind) => changeForm(newObj, bind, innerKey)}>
                                        <FormInput bind={'field'} component={Select} options={allColumnsOptions} search placeholder={'请选择字段'} disabled={readOnly}/>
                                        <FormInput bind={'relation'} component={Select} options={getRelationOptions(innerItem.field)} search placeholder={'请选择关系'} disabled={readOnly}/>
                                        {
                                            showDynamicSign &&
                                            <FormInput bind={'dynamicSign'} component={Select} options={dynamicOptions} placeholder={'是否动态值'}
                                                       defaultValue={'n'} disabled={readOnly} componentWidth={80}/>
                                        }
                                        {
                                            <DynamicFormInput conditionItem={innerItem} filterColumnsOptions={allColumnsOptions} disabled={readOnly}
                                                              {...{dynamicTimeOptions, dynamicDepartmentOptions, dynamicPersonOptions, dynamicDateOptions}}/>
                                        }
                                    </Form>
                                    {
                                        readOnly && (_.get(innerItem,'isMatchCondition') === false) &&
                                        <Tooltip title={'不符合条件'}>
                                            <span>
                                                <Icon name={'baocuo'} className={'error-icon'}/>
                                            </span>
                                        </Tooltip>
                                    }
                                    {
                                        !readOnly &&
                                        <Icon name={'tianjia'} className={'add-icon'} onClick={()=>addConditionField(outerKey, innerIndex)}/>
                                    }
                                    {
                                        !readOnly &&
                                        <Icon name={'shanjian'} className={'sub-icon'} onClick={()=>delConditionField(outerKey, innerIndex)}/>
                                    }
                                </div>
                            }
                        })
                    }
                </div>
            </div>
            {
                !readOnly &&
                <div className="add-item-wrap flex center-y">
                    <TextIconBtn icon={'tianjia'} text={'字段'} onClick={()=>addConditionField(outerKey)}/>
                    <TextIconBtn icon={'tianjia'} text={'添加条件组'} onClick={()=>addConditionGroup(outerKey)}/>
                </div>
            }
            {
                !readOnly &&
                <Icon name={'fix-a-guanbi'} className={'del-condition-icon'} onClick={()=>delConditionItem(parentOuterKey, index)}/>
            }
        </div>
    },[relationInfo, showRelationSelect, changeForm, addConditionField, delConditionField, addConditionGroup,
        delConditionItem, allColumnsOptions, getRelationOptions, dynamicTimeOptions, readOnly, dynamicDepartmentOptions,
        dynamicPersonOptions, dynamicDateOptions])

    return <div className={'check-condition-group-wrap flex-y'} key={countUpdate}>
        {
            !_.isNil(editData) &&
            ConditionContent({item: editData})
        }
        <div className={'add-condition-item flex center-y'}>
            {
                _.isEmpty(editData) &&
                <Button normal className={'add-condition-item-btn'} onClick={addConditionItem}>添加条件组</Button>
            }
        </div>
    </div>
}

export default CheckConditionGroup;
import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../../../utils/publicFun'
import './index.scss'

const CANCEL_RELATED_REQ_URL = '/implement/project/related/requirement/del' //取消关联需求
const CANCEL_RELATED_ISSUE_URL = '/issue/delIssueDevProject'  //取消关联ISSUE
const CANCEL_RELATED_DEFECT_URL = '/userProject/relationDefectProject' //取消关联缺陷

export default function RemoveItem(props) {
  const { close, selection, setSelection, refreshList, id, projectId } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)

    const urlAndParamsArr = getTypeParams({ id, projectId, data: selection })

    Promise.all(_.map(urlAndParamsArr, o => doFetch(o.url, o.params)))
      .then(res => {
        setSelection([])
        setIsDisabled(false)
        refreshList()
        close()
        Messager.show('移除成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err, { icon: 'error' })
      })
  }, [isDisabled, id, projectId, selection, doFetch, setSelection, refreshList, close])

  return (
    <Dialog
      header={<span className='header-style'>移除{'项目'}<span>（已选{_.size(selection)}个）</span></span>
      }
      className='dev-project-remove-item'
      cancel={close}
      confirm={confirm}
      style={{
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
      }}
    >
      确认移除{'项目'}？
    </Dialog >
  )
}

function getTypeParams({ data, id, projectId }) {
  const arr = []
  const filterReqData = _.filter(data, o => o.taskType === 'REQ')
  const filterIssueData = _.filter(data, o => o.taskType === 'ISSUE')
  const filterDefectData = _.filter(data, o => o.taskType === 'DEFECT')

  if (!_.isEmpty(filterReqData)) {
    const ids = _.map(filterReqData, 'idReq')
    const params = { funcCode: '36', projectType: 'DEV', projectId }
    arr.push({
      url: `${CANCEL_RELATED_REQ_URL}?${strParams(params)}`,
      params: ids
    })
  }
  if (!_.isEmpty(filterIssueData)) { //雪花
    const ids = _.map(filterIssueData, 'id')
    const params = { id, userProjectId: projectId, issueIds: ids }
    arr.push({ url: CANCEL_RELATED_ISSUE_URL, params, })
  }
  if (!_.isEmpty(filterDefectData)) { //雪花
    const ids = _.map(filterDefectData, 'id')
    const params = { action: 'del', id, fixProjectId: projectId, testDefects: _.map(ids, x => ({id: x})) }
    arr.push({ url: CANCEL_RELATED_DEFECT_URL, params, })
  }
  return arr
}
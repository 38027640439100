import React, { useCallback, useEffect, useMemo, useState, useLayoutEffect } from 'react';
import { DataGrid, Button } from 'rootnet-ui'
import { useGet } from "../../../utils/hook";
import { strParams } from "../../../project_share/utils/utils";
import OperationList from "../../../project_share/components/OperationList";
import { Box } from "../../common/commonComponent";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import './CostCenter.scss'
import UpdateDialog from "./UpdateDialog";
import _ from "lodash";
import { Form, FormInput, Select } from "rootnet-edit";
import { compose } from "../../../utils/publicFun";
import { Icon } from '../../../project_share/components'
import TextIconBtn from "../../../project_share/components/TextIconBtn";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import { handleTreeOptions } from "../../common/TreeFunc";

const getColumns = (props) => {
    const { setDialogData, userRight, handleTreeClick, getPath, allList, activeFlagOptions, currentId, deptOptions, deptOptionsRes } = props
    const { str14ToDate } = toDate
    const convertTime = compose(dateFormat('YYYY-MM-DD HH:MM:SS'), str14ToDate);
    return {
        resizable: true,
        fixedLeft: 3,
        fixedRight: 1,
        onRenderRow: o => {
            const isCurrent = o.costId === currentId
            return {
                style: { backgroundColor: isCurrent ? '#edf3f9' : '' },
            }
        },
        columns: [
            { header: '#', bind: '_index', width: 40, align: 'center', convert: v => v === -1 ? null : v + 1 },
            { header: '成本中心ID', bind: 'costId', width: 230, convert: addLeftIcon, tooltip: true },
            { header: '成本中心名称', bind: 'name', width: 200, tooltip: true, convert: v => <span dangerouslySetInnerHTML={{ __html: v }} /> },
            { header: '状态', bind: 'activeFlag', align: 'center', width: 100, convert: v => convertOptions(v, activeFlagOptions) },
            { header: '负责人', bind: 'principalName', width: 100, convert: v => <span dangerouslySetInnerHTML={{ __html: v }} /> },
            { header: '部门', bind: 'deptId', width: 150, convert: v => convertOptions(v, deptOptions) },
            { header: '创建人', bind: 'createUserName', width: 100 },
            { header: '创建时间', bind: 'createTime', width: 180, tooltip: true, convert: convertTime },
        ].concat(userRight === 'all' ?
            [{ header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation }] : [])
    }

    function addLeftIcon(v, o, i) {
        return <span className='id-col' style={{ marginLeft: o._level * 30 }} onClick={() => { handleTreeClick(o, i) }}>
            <Icon name="arrow_drop_down" className='arrow_drop_down'
                style={{ transform: o._expand ? 'none' : 'rotate(-90deg)', visibility: o.children ? '' : 'hidden' }} />
            <span dangerouslySetInnerHTML={{ __html: v }} />
        </span>
    }

    function getParentDeptId(deptId, deptOptionsRes) {
        if (_.isNil(deptId)) return ''
        return _.get(_.find(deptOptionsRes, x => x.departMentId === deptId), 'parentDept')
    }

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '修改',
                mode: 'edit',
                disabled: o.activeFlag === 'N' || userRight !== 'all',
                onClick: () => setDialogData({ mode: 'edit', header: '修改', path: getPath(o.pid), detail: _.find(allList, obj => obj.id === o.id), pDeptId: getParentDeptId(o.deptId, deptOptionsRes) })
            },
            {
                text: '添加子中心',
                mode: 'addChild',
                disabled: o.activeFlag === 'N' || userRight !== 'all',
                onClick: () => setDialogData({ mode: 'addChild', header: '增加', path: getPath(o.id), detail: { pid: o.id }, pDeptId: o.deptId })
            }
        ];
        return <OperationList options={operateOption} />;
    }
}

const INIT_PARAMS = {
    costId: null,
    name: null,
    principal: null,
    activeFlag: 'Y'
}

function CostCenter() {
    const { data: allListRes, doFetch: getList, loading, error } = useGet();
    const [params, setParams] = useState(INIT_PARAMS)
    const [dialogData, setDialogData] = useState({})
    const { data: userPositionRes } = useGet('/common/uacUserRole')
    const [showList, setShowList] = useState([])
    const { data: activeFlagRes } = useGet('/common/globalconst?globalConst=ActiveFlag')
    const [currentId, setCurrentId] = useState()
    const [showFocus, setShowFocus] = useState({})
    const { data: deptOptionsRes } = useGet('/common/department?activeFlag=Y')
    const { data: allActiveCostCenterRes, doFetch: getAllActiveCostCenter } = useGet()
    const { data: allCostCenterRes, doFetch: getAllCostCenter } = useGet()
    const { pid, costId } = showFocus || {}

    const activeFlagOptions = useMemo(() => {
        if (_.isEmpty(activeFlagRes)) return []
        return convertGlobalConstOptions(activeFlagRes)
    }, [activeFlagRes])

    const [deptOptions, deptTreeOptions] = useMemo(() => {
        if (_.isEmpty(deptOptionsRes)) return []
        const allDeptList = _.map(deptOptionsRes, x => ({ value: x.departMentId, text: x.departMentName }))
        const firstLevelDepList = _.filter(deptOptionsRes, x => _.get(x, 'parentDept') === "")
        const deptTreeOptions = handleTreeOptions(deptOptionsRes, firstLevelDepList, "departMentId", "parentDept", 'departMentName')
        return [allDeptList, deptTreeOptions]
    }, [deptOptionsRes])

    const allList = useMemo(() => {
        return _.sortBy(allListRes, 'costId', v => _.toNumber(v))
    }, [allListRes])

    const uniqCostIdList = useMemo(() => {
        if (_.isEmpty(allCostCenterRes)) return []
        return _.map(allCostCenterRes, x => x.costId)
    }, [allCostCenterRes])

    const userPositionList = useMemo(() => {
        if (_.isNil(userPositionRes)) return null
        return _.map(userPositionRes, x => x.posId)
    }, [userPositionRes])

    const userRight = useMemo(() => {
        if (_.isNil(userPositionList)) return null
        const allRightList = ['OPS', 'IT']
        const limitRightList = ['Manager', 'PM', 'PMO']
        if (_.intersection(userPositionList, allRightList).length > 0) return 'all'
        if (_.intersection(userPositionList, limitRightList).length > 0) return 'limit'
        return 'none'
    }, [userPositionList])

    const extra = <>
        {userRight === 'all' &&
            <TextIconBtn icon='tianjia' text='建立一级中心' onClick={() => setDialogData({ mode: 'add', header: '增加' })} />
        }
    </>

    const search = useCallback(() => {
        const url = `/worktime/costcenter?${strParams(params)}`
        getList(url);
        getAllActiveCostCenter('/worktime/costcenter?activeFlag=Y')
        getAllCostCenter('/worktime/costcenter')
    }, [params, getList, getAllActiveCostCenter, getAllCostCenter])

    const getPath = useCallback((id) => {
        if(_.isNil(id) || id === '') return ''
        const currentItem = _.find(allList, item => item.id === id)
        if (_.isNil(currentItem.pid)) {
            return currentItem.name
        } else {
            return getPath(currentItem.pid) + '/' + currentItem.name
        }
    }, [allList])

    useEffect(() => {
        search()
    }, [search]);

    const buildTree = useCallback((list) => {
        return _.map(list, listItem => {
            const childList = _.filter(allList, allItem => allItem.pid === listItem.id)
            if (childList.length === 0) return listItem
            return _.assign(listItem, { children: buildTree(childList) })
        })
    }, [allList])

    const openTree = useCallback((id) => {
        if (!_.isEmpty(allList)) {
            const currentItem = _.find(allList, item => item.id === id)
            if (_.isNil(currentItem.pid)) {
                return [currentItem]
            } else {
                return openTree(currentItem.pid)
            }
        }
    }, [allList])

    const onRowClick = useCallback((o) => {
        setCurrentId(o.costId)
    }, [])

    const expandList = useMemo(() => {
        if (_.isNil(allList)) return []
        const filterList = _.filter(allList, item => _.isNil(item.pid))
        const expandList = handleTree(buildTree(filterList), 'children', 'id')
        const noExpandList = _.map(filterList, item => _.assign(item, { _level: 0 }))
        return _.map(expandList, item => {
            const index = _.findIndex(noExpandList, x => x.costId === item.costId)
            return _.assign({}, item, { _index: index })
        })
    }, [allList, buildTree])

    const noExpandList = useMemo(() => {
        if (_.isNil(allList)) return []
        const filterList = _.filter(allList, item => _.isNil(item.pid))
        const noExpandList = _.map(filterList, item => _.assign(item, { _level: 0 }))
        if (pid) {
            const showFocusData = openTree(pid)
            const expandList = handleTree(buildTree(showFocusData), 'children', 'id')
            const findIndex = _.findIndex(noExpandList, item => showFocusData[0].id === item.id)
            setCurrentId(costId)
            const openData = noExpandList.slice(0, findIndex).concat(expandList).concat(noExpandList.slice(findIndex + 1))
            return _.map(openData, item => {
                const index = _.findIndex(noExpandList, x => x.costId === item.costId)
                return _.assign({}, item, { _index: index })
            })
        }
        return _.map(noExpandList, (item, i) => _.assign({}, item, { _index: i }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allList, buildTree, openTree])

    useEffect(() => {
        if (_.every(_.values(_.omit(params, ['activeFlag'])), x => _.isNil(x) || x === '') && _.get(params, 'activeFlag') !== 'N') {
            // 不展开
            setShowList(noExpandList)
        } else {
            // 展开
            setShowList(expandList)
        }
    }, [params, expandList, noExpandList])

    function handleTree(treeData, childrenName, keyName) {
        const treeList = []
        add(_.cloneDeep(treeData))
        return treeList

        function add(data, level = 0, parent = null) {
            if (!_.isArray(data)) return treeList
            data.forEach(item => {
                item._level = level
                item._parent = parent
                item._children = hasChildren(item)
                item._expand = true;
                item._childrenList = _.map(item[childrenName], x => x[keyName])
                treeList.push(item)
                if (item._children) return add(item[childrenName], level + 1, item[keyName])
            })

            function hasChildren(item) {
                return _.has(item, childrenName) && !_.isEmpty(item[childrenName])
            }
        }
    }

    const handleTreeClick = useCallback((o, i) => {
        const updateItem = _.assign({}, showList[i], { _expand: !showList[i]._expand })
        if (o._expand) {
            let endIndex = -1
            for (let currentIndex = i + 1; currentIndex < showList.length; currentIndex++) {
                if (showList[currentIndex]._level <= o._level) {
                    endIndex = currentIndex
                    break
                }
            }
            setShowList(showList.slice(0, i).concat(updateItem).concat(endIndex !== -1 ? showList.slice(endIndex) : []))
        } else {
            const allList = _.map(expandList, item => _.assign({}, item, { _expand: false }))
            const filterList = _.filter(allList, item => item.pid === o.id)
            const addList = _.map(filterList, item => _.assign(item, { _level: o._level + 1 }))
            setShowList(showList.slice(0, i).concat(updateItem).concat(addList).concat(showList.slice(i + 1)))
            showFocus?.pid && setShowFocus({ ...showFocus, pid: null })
        }
    }, [expandList, showFocus, showList])

    const columnsOption = useMemo(() => {
        return getColumns({ setDialogData, userRight, handleTreeClick, getPath, allList, activeFlagOptions, currentId, deptOptions, deptOptionsRes })
    }, [userRight, handleTreeClick, getPath, allList, activeFlagOptions, currentId, deptOptions, deptOptionsRes])

    useLayoutEffect(() => {
        if (_.isEmpty(showList)) return
        const scroll = document.getElementsByClassName('grid')[0]
        if (scroll && showFocus.costId && !_.isNil(showFocus.pid)) {
            scroll.scrollTop = _.findIndex(showList, item => showFocus.costId === item.costId) * 35 - 175
        }
    }, [costId, showFocus, showList])

    return <div className='cost-center table-fill flex-y'>
        <Options setShowFocus={setShowFocus} initParams={params} search={setParams} userRight={userRight} activeFlagOptions={activeFlagOptions} deptTreeOptions={deptTreeOptions} />
        <Box title='成本中心' style={{ marginTop: 0 }} className='flex-y x-card-singlegrid'
            data={showList} loading={loading} error={error} extra={extra}>
            <DataGrid data={getHighLightText()} option={columnsOption} onRowClick={onRowClick} />
        </Box>
        {
            dialogData.mode &&
            <UpdateDialog close={() => setDialogData({})} refresh={search}
                initData={_.get(dialogData, 'detail')}
                setShowFocus={setShowFocus}
                {...dialogData}
                {...{ allActiveCostCenterRes, deptOptionsRes, uniqCostIdList }}
            />
        }
    </div>
    function getHighLightText() {
        const searchData = ['costId', 'name', 'principal']
        _.forEach(searchData, v => {
            if (!_.isEmpty(params[v])) {
                _.forEach(showList, o => {
                    v === 'principal' && params[v] === o[v] ?
                        o[v + 'Name'] = _.replace(o[v + 'Name'], new RegExp(o[v + 'Name'], 'g'), `<span style="background:#F7E0C1">${o[v + 'Name']}</span>`) :
                        o[v] = _.replace(o[v], new RegExp(params[v], 'g'), `<span style="background:#F7E0C1">${params[v]}</span>`)
                })
            }
        })
        return showList
    }
}

const RFormInput = (props) => <FormInput componentWidth={150} {...props} />

function Options(props) {
    const { initParams, search, activeFlagOptions, deptTreeOptions, setShowFocus } = props
    const [params, setParams] = useState(initParams)
    const { data: principalOptionsRes } = useGet('/common/userinfo')

    const principalOptions = useMemo(() => {
        if (_.isEmpty(principalOptionsRes)) return []
        return _.map(principalOptionsRes, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}-${x.userName}` }))
    }, [principalOptionsRes])

    return <div className="x rims_options cost-center-options">
        <Form onChange={setParams} value={params}>
            <RFormInput
                label="成本中心ID"
                bind="costId" />
            <RFormInput
                label="成本中心名称"
                bind="name" />
            <RFormInput
                label="负责人"
                component={Select}
                search
                clear
                options={principalOptions}
                bind="principal" />
            <RFormInput
                label="状态"
                component={Select}
                options={activeFlagOptions}
                clear
                bind="activeFlag" />
            <RFormInput
                label="部门"
                component={Select}
                options={deptTreeOptions}
                clear
                tree
                bind="deptId" />
        </Form>
        <Button primary onClick={() => { search(params); setShowFocus({}) }} className='btn'>查询</Button>
        <Button text onClick={() => {
            setParams({ ...INIT_PARAMS })
            search({ ...INIT_PARAMS })
            setShowFocus({})
        }} className='btn reset-btn'>重置</Button>
    </div>
}

export default CostCenter;
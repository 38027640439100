import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Select } from 'antd';
import _ from 'lodash'
import {REQUIRED_ERROR, useControlledError} from "./useControlledError";
import cls from 'clsx';
import './PersonSelect.scss'
import usePost from "rootnet-biz/es/hooks/usePost";
import useGet from "rootnet-biz/es/hooks/useGet";

function PersonSelect(props) {
    const {style, required, onError, useOuterUser = false, outerAllUserRes, multiple, ref, onSelect: onOuterSelect, autoFocus, disabledList, isControlled, defaultOpen, size} = props
    const [stateValue, setStateValue] = useState();
    const [searchText, setSearchText] = useState()
    const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);
    const {doFetch: recordSelect} = usePost()
    const {data: innerAllUserRes, doFetch: getAllUserRes} = useGet()
    const [hadSelected, setHadSelected] = useState(false)
    const [isInited, setIsInited] = useState(false)

    useEffect(()=>{
        if(useOuterUser) return
        getAllUserRes('/common/userinfo')
    },[useOuterUser, getAllUserRes])

    const allUserRes = useMemo(()=>{
        if(hadSelected) return innerAllUserRes
        return useOuterUser ? outerAllUserRes : innerAllUserRes
    },[useOuterUser, innerAllUserRes, outerAllUserRes, hadSelected])

    const recentOptions = useMemo(()=>{
        const recentUserList = _.filter(allUserRes, x => !_.isNil(x.recentFlag))
        return _.map(recentUserList, x => ({label: `${x.userName} - ${x.departmentName}`, value: x.userAccount, tags: `${x.userName}${x.userAccount}${x.userId}${x.departmentName}`, disabled: _.includes(disabledList,x.userAccount)}))
    },[allUserRes, disabledList])

    const allOptions = useMemo(()=>{
        return _.map(allUserRes, x => ({label: `${x.userName} - ${x.departmentName}`, value: x.userAccount, tags: `${x.userName}${x.userAccount}${x.userId}${x.departmentName}`, disabled: _.includes(disabledList,x.userAccount)}))
    },[allUserRes, disabledList])

    const initData = useCallback((data)=>{
        if(multiple){
            const initList = []
            let handleData
            if(_.isArray(data)){
                handleData = data
            }else{
                handleData = _.split(data, ',')
            }
            _.forEach(handleData, item => {
                const findItem = _.find(allOptions, x => x.value === item)
                if(!_.isNil(findItem)) initList.push(findItem)
            })
            setStateValue(initList)
        }else{
            const findItem = _.find(allOptions, x => x.value === data)
            setStateValue(findItem)
        }
        setIsInited(true)
    },[allOptions,multiple])

    useEffect(()=>{
        if(isInited && !isControlled) return
        if(_.isNil(props.value)) return
        if(_.isEmpty(allOptions)) return
        initData(props.value)
    },[props.value,allOptions,initData, isInited, isControlled])

    useEffect(()=>{
        if(isInited) return
        if(_.isNil(props.defaultValue)) return
        if(_.isEmpty(allOptions)) return
        initData(props.defaultValue)
    },[props.defaultValue,allOptions,initData,isInited])

    const showRecent = useMemo(()=>{
        return _.isNil(searchText) || searchText === ''
    },[searchText])

    const options = useMemo(()=>{
        return showRecent ? recentOptions : allOptions
    },[showRecent,recentOptions,allOptions])

    const onSelect = useCallback((selectedItem)=>{
        setSearchText(null)
        recordSelect('/test_defect/common/people', {current_owners: [_.get(selectedItem, 'value')]})
            .then(()=> {
                getAllUserRes('/common/userinfo')
                setHadSelected(true)
                if(onOuterSelect){
                    onOuterSelect()
                }
            })
    },[recordSelect, getAllUserRes, onOuterSelect])

    const handleSelect = useCallback((newValue)=>{
        setStateValue(newValue)
        if(props.onChange){
            if(props.multiple){
                const valueList = _.map(newValue, 'value')
                props.onChange(valueList, newValue)
            }else{
                props.onChange(_.get(newValue, 'value'), newValue)
            }
        }
    },[props])

    useEffect(() => {
        const err = (required && _.isEmpty(stateValue)) ? REQUIRED_ERROR : null;
        if (err !== error) {
            setStateError(err);
            if (props.onError)
                props.onError(err);
        }
    }, [stateValue,required,onError,props,error,setStateError])

    const show_error = useMemo(()=>{
        return error && errorVisible && !_.isEmpty(allUserRes)
    },[error,errorVisible, allUserRes])

    const handleBlur = useCallback(()=>{
        setUserActive(true)
        if(props.onBlur){
            props.onBlur()
        }
    },[props,setUserActive])

    const handleFocus = useCallback(()=>{
        if(props.onFocus){
            props.onFocus()
        }
    },[props])

    return <Select options={options} labelInValue mode={multiple ? 'multiple' : 'unset'} value={stateValue} onChange={handleSelect} required={required} ref={ref} defaultOpen={defaultOpen}
                   onSearch={setSearchText} onSelect={onSelect} optionFilterProp="tags" style={style} allowClear={_.isNil(props.allowClear) ? !multiple : props.allowClear} showSearch
                   placeholder={props.placeholder ? props.placeholder :'请选择'} className={cls('person-select',{error: show_error},props.className)} onBlur={handleBlur} size={size}
                   disabled={props.disabled} onFocus={handleFocus} autoFocus={autoFocus} dropdownRender={menu => (<div>
                        {
                            showRecent && <div style={{color: '#B0BAC5', paddingLeft: 12, marginBottom: 3, fontSize: 10}}>最近输入</div>
                        }
                        {menu}
                    </div>)}
    />
}

PersonSelect.defaultProps = {
    style: {},
    size:'middle',
    required: false,
    disabled: false,
    multiple: false,
    autoFocus: false,
}

export default PersonSelect;
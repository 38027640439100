import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Input} from 'rootnet-edit'
import Icon from "../../../../project_share/components/Icon";
import {Tree} from 'antd'
import _ from 'lodash'
import './ResourceDeptSearch.scss'
import useGet from "rootnet-biz/es/hooks/useGet";
import {isNil} from "../../../appraise/components/method";

function ResourceDeptSearch(props) {
    const {changeDept,initDept, setOuterChildList, isGetInitDept = true} = props
    const {data: allDeptRes} = useGet('/common/department?activeFlag=Y')
    const [expandedKeys, setExpandedKeys] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const [searchText, setSearchText] = useState()
    const {data: initDeptRes} = useGet('/userPlan/department')

    useEffect(()=>{
        const selectedDeptId = _.get(_.split(_.head(selectedKeys),'-'),"1")
        if(_.isNil(selectedDeptId)) return
        if(changeDept){
            changeDept(selectedDeptId)
        }
    },[selectedKeys, changeDept])

    useEffect(()=>{
        if(setOuterChildList){
            const selectedDeptId = _.get(_.split(_.head(selectedKeys),'-'),"1")
            if(isNil(selectedDeptId)) return setOuterChildList(null)
            let allChildrenDept = [selectedDeptId]
            addChild([selectedDeptId])
            return setOuterChildList(allChildrenDept)

            function addChild(deptList){
                if(_.isEmpty(deptList)) return
                const childrenList = _.filter(allDeptRes, x => _.includes(deptList, x.parentDept))
                const childrenIdList = _.map(childrenList, 'departMentId')
                allChildrenDept = _.concat(allChildrenDept, childrenIdList)
                addChild(childrenList)
            }
        }
    },[allDeptRes, setOuterChildList, selectedKeys])

    const extendDeptRes = useMemo(()=>{
        if(_.isEmpty(allDeptRes)) return []
        const companyNode = {
            activeFlag: "Y",
            departMentId: "",
            departMentName: "北京根网科技有限公司",
            manager: "male",
            parentDept: "croot",
        }
        return _.concat([companyNode],allDeptRes)
    },[allDeptRes])

    useEffect(()=>{
        if(_.isEmpty(allDeptRes)) return
        setExpandedKeys(['dept-'])
    },[allDeptRes])

    useEffect(()=>{
        if(_.isNil(initDeptRes) && _.isNil(initDept)) return
        if(!isGetInitDept) return
        const init = initDept || _.get(initDeptRes,'department')
        setSelectedKeys(['dept-'+ init])
        const parentIdGroup = getLinkedParentIdList(init,extendDeptRes)
        setExpandedKeys(_.map(parentIdGroup, x => 'dept-' + x ))
    },[initDeptRes,extendDeptRes,initDept, isGetInitDept])

    const showDeptGroup = useMemo(()=>{
        if(!searchText){
            return extendDeptRes
        }
        const expandedKeysItemList = _.filter(extendDeptRes, x => {
            return _.includes(_.toLower(x.departMentName), _.toLower(searchText))
        })
        const expandedKeys = _.map(expandedKeysItemList, x => x.departMentId)
        const idGroup = _.map(expandedKeys, x => getLinkedIdList(x,extendDeptRes))
        const parentIdGroup = _.map(expandedKeys, x => getLinkedParentIdList(x,extendDeptRes))
        const showIdGroup = _.union(...idGroup)
        const showParentIdGroup = _.union(...parentIdGroup)
        setExpandedKeys(_.map(showParentIdGroup, x => 'dept-' + x ))
        return _.filter(extendDeptRes, x => _.includes(showIdGroup, x.departMentId))
    },[searchText, extendDeptRes])

    const deptUserTreeData = useMemo(()=>{
        return getDepartmentChildren(showDeptGroup,'croot')
    },[showDeptGroup])

    useEffect(()=>{
        if(!searchText){
            return setExpandedKeys(['dept-'])
        }
        // const list = _.map(_.map(showDeptGroup, 'departMentId'), x => 'dept-' + x)
        // setExpandedKeys(list)
    },[searchText])

    const onExpand = useCallback((expandedKeys)=>{
        setExpandedKeys(expandedKeys)
    },[])

    return <div className={'resource-dept-search flex-y'}>
        <Input className={'search-wrap'} placeholder={'搜索部门'} value={searchText} onChange={setSearchText}
               suffix={<Icon name={'sousuo1'} className={'search-icon'}/>}/>
        <div className={'dept-tree'}>
            <Tree treeData={deptUserTreeData}
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  selectedKeys={selectedKeys}
                  onSelect={(keys,e) => setSelectedKeys([e.node.key])}
                  // autoExpandParent={true}
            />
        </div>
    </div>
}

function getDepartmentChildren(allDepartmentList,departmentId){
    const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === departmentId)
    const childDepartmentOptions = _.map(childDepartmentList, x => ({
        title: <div>{x.departMentName}</div>,
        name: x.departMentName,
        key: `dept-${x.departMentId}`,
        type: 'department',
        children: getDepartmentChildren(allDepartmentList,x.departMentId),
    }))
    return [...childDepartmentOptions]
}


function getLinkedIdList(id, allDeptList){
    let idList = []
    appendParentId(id, idList)
    appendChildrenId(id, idList)
    return idList

    function appendParentId(id, idList){
        const findItem = _.find(allDeptList, x => x.departMentId === id)
        if(findItem){
            idList.push(id)
            const parentId = _.get(findItem,'parentDept')
            return appendParentId(parentId, idList)
        }else{
            return
        }
    }

    function appendChildrenId(id, idList){
        const childrenList = _.filter(allDeptList, x => x.parentDept === id)
        if(_.isEmpty(childrenList)){
            return
        }else{
            const childrenIdList = _.map(childrenList, x => x.departMentId)
            _.forEach(childrenIdList, x => idList.push(x))
            _.forEach(childrenIdList, x => appendChildrenId(x, idList))
        }
    }
}

function getLinkedParentIdList(id, allDeptList){
    let idList = []
    appendParentId(id, idList)
    return idList

    function appendParentId(id, idList){
        const findItem = _.find(allDeptList, x => x.departMentId === id)
        if(findItem){
            const parentId = _.get(findItem,'parentDept')
            idList.push(parentId)
            return appendParentId(parentId, idList)
        }else{
            return
        }
    }
}

export default ResourceDeptSearch;
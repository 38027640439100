import React, { useState, useMemo, useRef, useCallback, useContext, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { FormContext } from 'antd/lib/form/context'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Table, Tooltip, Popconfirm, Form, Select, Input, DatePicker, InputNumber, Button } from 'antd'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { Icon } from '../../../../../components'
import { isNil } from '../../../../appraise/components/method'
import { uniqKeyFor } from '../../../../../project_share/utils/utils'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'

const LIST_URL = '/paymentMilestones/query'
const EDIT_URL = '/paymentMilestones/maintain'

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const YesNoOptions = [
  { value: 'Y', label: '是' },
  { value: 'N', label: '否' },
]

function getColumns(props) {
  const { editable, delItem } = props

  const cols1 = [
    {
      title: '里程碑编号',
      dataIndex: 'number',
      key: 'number',
      width: 100,
      editable: editable,
    },
    {
      title: '里程碑描述',
      dataIndex: 'infoDesc',
      key: 'infoDesc',
      width: 200,
      editable: editable,
      render: value => {
        if (isNil(value)) return ''
        return <Tooltip placement="topLeft" title={value}>
          <div style={{ marginLeft: editable ? 0 : 12, whiteSpace: 'pre-wrap' }}>{value}</div>
        </Tooltip>
      }
    },
    {
      title: '里程碑付款金额',
      dataIndex: 'payment',
      key: 'payment',
      width: 120,
      editable: editable,
    },
    {
      title: '计划到达日期',
      dataIndex: 'plannedArrivalDate',
      key: 'plannedArrivalDate',
      width: 120,
      editable: editable,
      render: (value) => {
        if (isNil(value)) {
          return <div>{value}</div>
        }
        return dateFormat('YYYY-MM-DD', toDate.str14ToDate(value))
      }
    },
    {
      title: '是否到达',
      dataIndex: 'arrived',
      key: 'arrived',
      width: 100,
      editable: editable,
      render: value => {
        if (isNil(value)) return ''
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, YesNoOptions, 'label')}
        </div>
      }
    },
    {
      title: '实际到达日期',
      dataIndex: 'actualArrivalDate',
      key: 'actualArrivalDate',
      width: 120,
      editable: editable,
      render: (value) => {
        if (isNil(value)) {
          return <div>{value}</div>
        }
        return dateFormat('YYYY-MM-DD', toDate.str14ToDate(value))
      }
    },
    {
      title: '是否开票',
      dataIndex: 'invoiced',
      key: 'invoiced',
      width: 100,
      editable: editable,
      render: value => {
        if (isNil(value)) return ''
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, YesNoOptions, 'label')}
        </div>
      }
    },
    {
      title: '是否回款',
      dataIndex: 'paymentCollection',
      key: 'paymentCollection',
      width: 100,
      editable: editable,
      render: value => {
        if (isNil(value)) return ''
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, YesNoOptions, 'label')}
        </div>
      }
    },
    {
      title: '备注说明',
      dataIndex: 'remark',
      key: 'remark',
      width: 300,
      editable: editable,
      render: value => {
        if (isNil(value)) return ''
        return <Tooltip placement="topLeft" title={value}>
          <div style={{ marginLeft: editable ? 0 : 12, whiteSpace: 'pre-wrap' }}>{value}</div>
        </Tooltip>
      }
    },
  ]

  const delCols = [
    {
      title: '',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (value, obj) => {
        return <Popconfirm
          title="确认删除？"
          onConfirm={() => delItem(obj.id, obj.action === 'add')}
          okText="删除"
          cancelText="取消"
          overlayInnerStyle={{ padding: 8, width: 200 }}
        >
          <Icon className={'imp-pay-list-del-icon'} name='shanchu' style={{ fontSize: 16 }} />
        </Popconfirm>
      }
    }
  ]
  return _.concat(editable ? delCols : [], cols1)
}

export default function ImpPayMilestone(props) {
  const { edit, implementProjectId } = props
  const [list, setList] = useState([])
  const [form] = Form.useForm()
  const [mode, setMode] = useState('detail')
  const [isDisable, setIsDisable] = useState(false)
  const { data: listRes, doFetch: getList, loading } = usePost()
  const { doFetch } = usePost()

  const { rows: data } = useMemo(() => listRes || {}, [listRes])

  const refresh = useCallback(() => {
    getList(LIST_URL, { pageNum: 1, pageSize: 1000, implementProjectId })
  }, [getList, implementProjectId])

  const initList = useCallback(() => {
    if (_.isEmpty(data)) setList([])
    else {
      const initData = _.map(data, o => _.assign({}, o, { action: 'edit' }))
      setList(initData)
    }
  }, [data])

  const editable = useMemo(() => (edit && mode === 'edit'), [edit, mode])

  const EditableRow = useCallback(({ index, ...restProps }) => {
    const currentIndex = list.findIndex(x => x.id === restProps['data-row-key']);
    return <Form form={form} component={false}>
      <FormContext.Provider value={{ form: form }}>
        <SortableItem index={currentIndex} {...restProps} />
      </FormContext.Provider>
    </Form>
  }, [form, list])

  const handleSave = useCallback((row, bind) => {
    const index = _.findIndex(list, x => x.id === row.id)
    setList(oldList => {
      const newList = _.map(oldList, (item, i) => {
        if (i !== index) return item
        return ({
          ...item,
          ...row
        })
      })
      return _.sortBy(newList, 'serial')
    })
  }, [list])

  const addItem = useCallback(() => {
    // getOnlyId('/test_case/productGetOnlyId').then(id => {
    setList(old => {
      return _.concat(old, [_.assign({
        id: uniqKeyFor(),
        action: 'add',
      })])
    })
  }, [])

  const delItem = useCallback((delId, flag) => {// flag true 为新增
    setList(oldList => _.filter(oldList, x => x.id !== delId))
    // if (flag) { }
    // else { }
  }, [])

  const columns = useMemo(() => {
    return _.map(getColumns({ editable, delItem }), col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          showList: list,
        })
      }
    })
  }, [editable, delItem, handleSave, list])

  const confirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const deleteList = _.filter(data, o => !_.includes(_.map(list, 'id'), o.id))
    const delData = _.map(deleteList, o => _.assign({}, o, { action: 'del' }))
    const bindList = _.map(getColumns({ editable: false, delItem: false }), o => o.key)
    const filterEmptyList = _.filter(list, o => _.some(bindList, v => !isNil(o[v])))
    const postParams = _.map(_.concat(filterEmptyList, delData), o => _.assign({}, o, { implementProjectId }))
    doFetch(EDIT_URL, postParams)
      .then(() => {
        refresh()
        setMode('detail')
        setIsDisable(false)
        Messager.show('保存成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, doFetch, refresh, data, list, implementProjectId])

  useEffect(() => {
    initList()
  }, [initList])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className='imp-pay-milestone'>
      {
        edit &&
        <div className='imp-handle-btn'>
          {
            mode === 'edit' &&
            <Button type={'primary'} onClick={confirm}>保存</Button>
          }
          <Button onClick={() => {
            initList()
            setMode(x => x === 'edit' ? 'detail' : 'edit')
          }}>{mode === 'edit' ? '退出编辑' : '编辑'}</Button>
        </div>
      }

      <Table
        loading={loading}
        size={'small'}
        rowClassName={'editable-row'}
        columns={columns}
        pagination={false}
        dataSource={list}
        rowKey="id"
        sticky={true}
        scroll={{ x: 700 }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell
          }
        }}
      />
      {
        editable &&
        <div className='add-data-item' onClick={addItem}>
          <Icon name='fix-xinzeng' /> 添加一行数据
        </div>
      }
    </div>
  )
}

function DraggableContainer(props) {
  return <SortableContainer
    useDragHandle
    disableAutoscroll
    helperClass="row-dragging"
    {...props}
  />
}

function EditableCell(props) {
  const { editable, dataIndex, record, children, handleSave, ...restProps } = props
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const { form } = useContext(FormContext || {})

  useEffect(() => {
    if (editing) {
      if (!_.isNil(inputRef.current)) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  const toggleEdit = useCallback(() => {
    setEditing(x => !x);
    let value
    if (_.includes(['plannedArrivalDate', 'actualArrivalDate'], dataIndex)) {
      value = _.isNil(record[dataIndex]) ? null : moment(record[dataIndex], 'YYYY-MM-DD')
    } else {
      value = record[dataIndex]
    }
    form.setFieldsValue({ [dataIndex]: value });
  }, [dataIndex, record, form])

  const save = () => {
    try {
      form.validateFields().then(changeObj => {
        const key = _.head(_.keys(changeObj))
        if (_.includes(['plannedArrivalDate', 'actualArrivalDate'], key)) {
          changeObj[key] = _.isNil(changeObj[key]) ? null : moment(changeObj[key]).format('YYYYMMDDHHMMSS')
        }
        toggleEdit();
        if (changeObj[key] !== record[key]) {
          handleSave({ ...record, ...changeObj }, key);
        }
        if (!_.isNil(inputRef.current)) {
          inputRef.current.blur()
        }
        setEditing(false)
      })
    } catch (errInfo) {
      console.error(errInfo);
    }
  }

  let childNode = children;
  if (editable) {
    if (editing) {
      let editNode
      if (_.includes(['remark', 'infoDesc'], dataIndex)) {
        editNode = <Input.TextArea ref={inputRef} onBlur={save} />
      } else if (_.includes(['arrived', 'invoiced', 'paymentCollection'], dataIndex)) {
        editNode = <Select ref={inputRef} options={YesNoOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditing(false)} />
      } else if (_.includes(['plannedArrivalDate', 'actualArrivalDate'], dataIndex)) {
        editNode = (<DatePicker ref={inputRef} onChange={save} allowClear={false} open={true} onBlur={() => setEditing(false)} autoFocus />)
      } else if (_.includes(['number', 'payment'], dataIndex)) {
        editNode = (<InputNumber ref={inputRef} onBlur={save} onPressEnter={save} />)
      } else {
        editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
      }
      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
        >
          {editNode}
        </Form.Item>
      )
    } else {
      childNode = (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>

}

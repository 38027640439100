import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './ConferenceRecordsParticipantList.scss'
import {
    Form as AntdForm,
    Input,
    Select as AntdSelect,
    Table,
    Tooltip
} from "antd";
import convertOptions from "../../../common/ConvertOptions";
import _ from "lodash";
import {sortableContainer, sortableElement} from "react-sortable-hoc";
import {ParticipantListInConferenceRecordsEditContext} from "../../../common/Context";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import useGet from "rootnet-biz/es/hooks/useGet";
import {isNil} from "rootnet-core/format";

function getColumns(props){
    const {allUserRes, attendanceOptions, editable} = props
    return [
        {
            title: <span style={{marginLeft: 12}}>参与人</span>,
            dataIndex: 'conferee',
            key: 'conferee',
            width: 120,
            render: v => <div style={{marginLeft: 12}}>
                {convertOptions(v, allUserRes, 'userName','userAccount')}
            </div>
        },
        {
            title: <span style={{marginLeft: 12}}>参会情况</span>,
            dataIndex: 'attendance',
            key: 'attendance',
            width: 120,
            editable: editable,
            render: (value) => <div style={{marginLeft: editable?0:12}}>
                {convertOptions(value, attendanceOptions, 'label')}
            </div>
        },
        {
            title: <span style={{marginLeft: 12}}>情况说明</span>,
            dataIndex: 'explanation',
            key: 'explanation',
            width: 300,
            editable: editable,
            ellipsis: {
                showTitle: false,
            },
            render: (value, obj) => {
                if(_.includes(['02','03'],_.get(obj,'attendance')) && isNil(value)){
                    return <div style={{color: 'red', opacity: 0.5}}>请填写情况说明（必填）</div>
                }
                return <Tooltip placement="topLeft" title={value}>
                    <span className={'ellipsis-value'} style={{marginLeft: editable?0:12}}>{value}</span>
                </Tooltip>
            }
        },
    ]
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const optionsUrls = [
    '/common/globalconst?globalConst=Attendance',
]

function ConferenceRecordsParticipantList(props) {
    const {participantList, setParticipantList, allUserRes, editable} = props
    const { data: allOptionsRes } = useGet(optionsUrls)
    const [form] = AntdForm.useForm()

    const [attendanceOptions] = useMemo(()=>{
        if(_.isEmpty(allOptionsRes)) return []
        const [d1] = allOptionsRes
        const attendanceOptions = _.map(d1, x => ({label: x.displayName, text: x.displayName, value: x.interiorId}))
        return [attendanceOptions]
    },[allOptionsRes])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = participantList.findIndex(x => x.conferee === restProps['data-row-key']);
        return <AntdForm form={form} component={false}>
            <ParticipantListInConferenceRecordsEditContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </ParticipantListInConferenceRecordsEditContext.Provider>
        </AntdForm>
    },[participantList, form])

    const handleSave = useCallback((row, bind)=>{
        const index = _.findIndex(participantList, x => x.conferee === row.conferee)
        setParticipantList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return _.sortBy(newList, 'serial')
        })
    },[participantList, setParticipantList])

    const columns = useMemo(()=>{
        return _.map(getColumns({allUserRes, attendanceOptions, editable}),col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                    showList: participantList,
                    attendanceOptions,
                })
            }
        })
    },[allUserRes, handleSave, participantList, attendanceOptions, editable])

    return <div className={'conference-records-participant-list'}>
        <Table
            size={'small'}
            rowClassName={'editable-row'}
            columns={columns}
            pagination={false}
            dataSource={participantList}
            rowKey="conferee"
            sticky={true}
            components={{
                body:{
                    wrapper: DraggableContainer,
                    row: EditableRow,
                    cell: EditableCell
                }
            }}
        />
    </div>

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            {...props}
        />
    }
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, showList,attendanceOptions, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(ParticipantListInConferenceRecordsEditContext || {})

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        setEditing(x => !x);
        let value
        value = record[dataIndex]
        form.setFieldsValue({ [dataIndex]:  value });
    },[dataIndex, record, form])

    const save = () => {
        try {
            form.validateFields().then(changeObj => {
                const key = _.head(_.keys(changeObj))
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    handleSave({ ...record, ...changeObj }, key);
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        }catch (errInfo){
            console.error(errInfo);
        }
    }

    let childNode = children;

    if (editable){
        if(editing){
            let editNode
            if(dataIndex === 'conferee'){
                editNode = (<UserSelect ref={inputRef} onChange={save} defaultOpen={true} onBlur={()=>setEditing(false)} />)
            }else if(dataIndex === 'attendance'){
                editNode = (<AntdSelect ref={inputRef} options={attendanceOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else{
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }

            childNode = (
                <AntdForm.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </AntdForm.Item>
            )
        }else{
            childNode =(
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }

    return <td {...restProps}>{childNode}</td>;
}

export default ConferenceRecordsParticipantList;
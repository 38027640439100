import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Select, FormInput, Grid, Form, TextArea } from 'rootnet-edit'
import { Dialog, ScrollViewer, Messager } from 'rootnet-ui'
import { GetDate } from 'rootnet-core/format'
import { useGet } from "../../../../utils/hook";
import Api from '../../../../base/task'
import _ from 'lodash'
import cls from 'clsx'
import { dateFormat } from "rootnet-core/dateFormat";
import { strParams } from "../../../../utils/publicFun";
import WaterMarkDialog from "../../../common/WaterMarkDialog";

const INIT_FOM_DATA = {
  auditUseTime: null,
  remark: '',
  custProjectId: null,
  releaseId: null,
}

function yearBetween() {
  const firstDay = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365)
  const endDay = new Date(Date.now())
  return {
    startDate: dateFormat('YYYY-MM-DD', firstDay),
    endDate: dateFormat('YYYY-MM-DD', endDay),
  }
}

function EditDialog(props) {
  const { close, jobInfo, modify, getWeekCont, usedTime, date, jobType, mode, isSelf, searchUser, lastCutOffDate, limitCutOffDate, beforeCutOffDate } = props
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [error, setError] = useState({})
  const [site, setSite] = useState(0)
  const [jobItemInfo, setJobItemInfo] = useState({})
  const { data: clientInfo, doFetch: getClientList } = useGet();
  const { data: versionInfo, doFetch: getVersionInfo } = useGet();

  const [jobTypeList, formType, clientList, versionList] = useMemo(() => {
    const { children = [], type = '' } = jobInfo
    const lib_client = _.map(clientInfo, item => ({ text: `${item.projectId}-${item.projectName}`, value: item.projectId }))
    const lib_version = _.map(versionInfo, item => ({ text: `${item.releaseId}-${item.releaseName}`, value: item.releaseId }))
    return [children, type, lib_client, lib_version]
  }, [jobInfo, clientInfo, versionInfo])
  const [showContinueDialog, setShowContinueDialog] = useState(false)
  const [cardDetailTime, setCardDetailTime] = useState(0)
  const [countTime, setCountTime] = useState(0)

  useEffect(() => {
    let auditUseTime = _.toNumber(_.get(params, 'auditUseTime'))
    if (auditUseTime <= 0) {
      setError(x => _.assign({}, x, { 'auditUseTime': '实际用时需大于0' }))
      return
    }
    if (modify) {
      auditUseTime = auditUseTime - cardDetailTime / 60
    }
    if (!_.isNil(auditUseTime) && ((auditUseTime * 60 + usedTime + countTime * 60) > 8 * 60)) {
      setError(x => _.assign({}, x, { 'auditUseTime': '每天用时不能大于8小时' }))
      return
    }
    setError(x => _.assign({}, x, { 'auditUseTime': null }))
  }, [params, usedTime, modify, cardDetailTime, countTime])

  const handleTabChange = useCallback((options) => {
    if (modify)
      _.assign(options, { id: jobInfo.id })
    const { id, jobType, content, site, utFlag: optionsUtFlag } = options
    const { utFlag: jobInfoUtFlag } = jobInfo
    const utFlag = optionsUtFlag ? optionsUtFlag : jobInfoUtFlag
    const params = { id, jobType, content, status: utFlag === 'Y' ? 'submit' : 'approve' }
    setJobItemInfo(params)
    setSite(site)
  }, [modify, jobInfo])

  useEffect(() => {
    getClientList(`/common/system?status=02`)
    getVersionInfo(`/develop/release/list?stateList=R,L&versionType=0&` + strParams(yearBetween()))
    // getVersionInfo(`/develop/release/list?stateList=R,L`)
    if (modify) {
      Api.get(`/worktime/other/detail?id=${_.get(jobInfo, 'id')}&userAccount=${searchUser}`).then((res) => {
        const itemInfo = _.get(res, 'data[0]')
        const itemParams = _.pick(itemInfo, ['workDate', 'auditUseTime', 'custProjectId', 'releaseId', 'remark'])
        setParams({ ...itemParams, workDate: GetDate(itemParams.workDate), auditUseTime: _.round(itemParams.auditUseTime / 60, 1) })
        handleTabChange({ ...jobInfo, ...itemInfo })
        setCardDetailTime(itemParams.auditUseTime)
      })
    } else {
      const option = _.get(jobInfo, 'children[0]')
      handleTabChange({ ...option, site: 0, jobType: option.id, content: option.name })
    }
  }, [getClientList, getVersionInfo, handleTabChange, modify, jobInfo, searchUser])

  const headerTitle = useMemo(() => {
    return _.get(_.find(jobType, item => item.id === jobInfo.jobTypeId), 'name') || mode
  }, [jobInfo, jobType, mode])

  return <div>
    <WaterMarkDialog header={`${headerTitle}（${date}）`} confirmButtonVisible={isSelf}
      className='worktime-edit' confirm={handleSubmit} cancel={() => close(false)} draggable={true} loading={loading} {...props}>
      <div className='worktime-cont'>
        <TypeList jobType={jobTypeList} onChange={handleTabChange} siteIndex={site} handleError={handleErrorInfo} />
        <div className='edit-content'>
          <div className='edit-form'>
            <Grid cols={2}>
              {getFormCont(jobInfo)}
            </Grid>
          </div>
        </div>
      </div>
    </WaterMarkDialog>
    {
      showContinueDialog &&
      <Dialog headerVisible={false} confirm={clear} cancel={exit} className={'content-center-dialog'}>
        增加成功，是否继续增加？
      </Dialog>
    }
  </div>

  function clear() {
    const auditUseTime = _.toNumber(params.auditUseTime)
    setCountTime(x => x + auditUseTime)
    setShowContinueDialog(false)
    setParams(INIT_FOM_DATA)
  }

  function exit() {
    setShowContinueDialog(false)
    close(false)
  }

  function handleChangeFormData(formObj, bind) {
    if (bind === 'auditUseTime' && _.toNumber(_.get(formObj, 'auditUseTime')) > 8) {
      return Messager.show('实际用时上限为8h')
    }
    setParams(formObj)
  }

  function getFormCont(options) {
    if (formType === 'other') {
      return <Form onChange={handleChangeFormData} value={params} error={error} onError={setError}>
        <Grid cols={2}>
          <FormInput horizontal label='实际用时(h)' bind='auditUseTime' required type='number' min={0.1} digit={1} labelWidth={100} />
          <FormInput horizontal label='工作内容说明' bind='remark' component={TextArea} colSpan={2} maxLength={200} componentWidth={440} labelWidth={100} className='remark' />
        </Grid>
      </Form>
    }
    return <Form onChange={handleChangeFormData} value={params} error={error} onError={setError}>
      <FormInput horizontal label='实际用时(h)' bind='auditUseTime' required type='number' min={0.1} digit={1} labelWidth={100} />
      <FormInput horizontal label='客户系统' bind='custProjectId' component={Select} options={clientList} search labelWidth={100} clear />
      {_.get(jobItemInfo, 'jobType') === 2112 &&
        <FormInput horizontal label='版本编号' bind='releaseId' component={Select} labelWidth={100} options={versionList} search required />}
      <FormInput horizontal label='工作内容说明' bind='remark' component={TextArea} colSpan={2} maxLength={200} componentWidth={440} labelWidth={100} className='remark' />
    </Form>
  }

  async function handleSubmit() {
    if (date <= beforeCutOffDate) return Messager.show(`${lastCutOffDate}前的成本已核算，不允许修改工时`, { icon: 'error' })
    const now = dateFormat('YYYY-MM-DD', new Date())
    if (date <= lastCutOffDate && now > limitCutOffDate) return Messager.show(`${lastCutOffDate}前的成本已核算，不允许修改工时`, { icon: 'error' })
    const handleError = _.get(jobItemInfo, 'jobType') === 2112 ? error : _.omit(error, ['releaseId'])
    if (_.some(_.values(handleError), x => x)) {
      const errMsg = _.find(_.values(handleError), x => !_.isNil(x))
      Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
      return false
    }
    const options = { ...params, workDate: date.replace(/-/g, ''), auditUseTime: params.auditUseTime * 60, actionType: modify ? 'EDIT' : 'ADD', }
    try {
      setLoading(true)
      await Api.post('/worktime/edit', [{ ...options, ...jobItemInfo }])
      await getWeekCont()
      setLoading(false)
      Messager.show('提交成功！', { icon: 'success' })
      if (!modify) {
        setShowContinueDialog(true)
        return
      }
      close(false)
    } catch (err) {
      setLoading(false)
      Messager.show(err.Message, { icon: 'error' })
    }
  }

  function handleErrorInfo(option) {
    if (formType === 'service' && option.jobType !== 2112) {
      setError({ ...error, releaseId: null })
      setParams({ ...params, releaseId: '' })
    }
  }

}

function TypeList(params) {
  const { jobType, siteIndex = 0, onChange, handleError } = params
  const site = siteIndex * 40 + 17
  return <div className='edit-libType'>
    <ScrollViewer className='fill' style={{ width: 195 }}>
      <div className='edit-border'>
        {
          _.map(jobType, (item, index) => {
            return <div className='job-type-item' key={item.id} onClick={() => handleClick(item, index)}>
              <span className={cls({ selectActive: index === siteIndex })}>{item.name}</span>
              {_.some(jobType, i => i.utFlag === 'Y') &&
                <div className='ut' style={{ visibility: item.utFlag === 'Y' ? '' : 'hidden' }}>UT</div>
              }
            </div>
          })
        }
        <em style={{ top: site }}></em>
      </div>
    </ScrollViewer>
  </div>

  function handleClick(options, index) {
    const params = { ...options, site: index, jobType: options.id, content: options.name }
    onChange(params)
    handleError(params)
  }
}

export default EditDialog
// transform: [`translateY(${site}px)`]
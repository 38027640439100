import { useCallback } from 'react'
import { usePost } from 'rootnet-biz/es/hooks'

const COMMON_URL = '/test/common/operations'
/**
 * 01 消息类
 * 02 读取类
 * 03 功能类
 * 04 人员类
 * 05 需求
 * 06 研发任务
 * 07 缺陷
 * 08 用例
 * 09 版本
 * 10 项目
 * 11 产品
 */
export default function useCommon() {
  const { doFetch } = usePost()
  /* type referenceId */
  return useCallback((params) => {
    doFetch(COMMON_URL, params)
  }, [doFetch])
}

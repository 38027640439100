import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Icon from "../../../../../components/Icon";
import { Messager } from 'rootnet-ui'
import { Display, Form, FormInput, Input, RadioGroup, Select, TextArea } from "rootnet-edit";
import _ from "lodash";
import './IssueAnalysis.scss'
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import convertOptions from "../../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import TinyEditor from "../../../../common/richTextEditor/TinyEditor";
import { Image, Tooltip } from "antd";
import VersionSelect from "../../../../requirementMgt/applyJoinVersionDialog/VersionSelect";
import { uniqKeyFor } from "../../../../../project_share/utils/utils";
import ApplyJoinVersionDialog from "../../../../requirementMgt/applyJoinVersionDialog/ApplyJoinVersionDialog";
import { handleTreeOptions } from "../../../../common/TreeFunc";
import ClosurePlanMessage from '../../../../common/closurePlanMessage'
import ViewQueryDialog from "../../../../common/view/viewQueryDialog/ViewQueryDialog";
import IssueDetailDialog from "../IssueDetailDialog";
import gd from '../../../../../base/global';
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput';
import ApplyJoinProjectDialog from '../../../../requirementMgt/applyJoinProjectDialog';
import { useRolePermissions } from '../../../../common/commonMethod';
import DisplaySelect from '../../../../common/displaySelect/DisplaySelect';
import { isNil } from '../../../../appraise/components/method';
import { strParams } from '../../../../../utils/publicFun';

const yesNoOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=Influence',
    'common/globalconst?globalConst=ISSUEKIND',
    'common/globalconst?globalConst=IssueKindDetail',
    '/UserSetting/getUniversalInterfaces?code=ProjectID&codeName=ProjectName&tableName=UserProject',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=issueTestGroup'
    // '/UserSetting/getUniversalInterfaces?code=ProjectID&codeName=ProjectName&tableName=UserProject&filter=status&filterParams=0,2,4,5,6'
]

const OPTIONS_URLS = [
    '/develop/release/list?stateList=C&versionType=0',
    '/develop/release/list?stateList=R,L&versionType=0',
    '/develop/release/list?stateList=C&versionType=1',
    '/develop/release/list?stateList=R,L&versionType=1',
    '/develop/release/list?stateList=T,C&versionType=1',
]

function IssueAnalysis(props) {
    const { isDetail, editFormData, setEditFormData, allUserRes, resolveRef, editFormError, setEditFormError, id, refreshReleaseList, underApprobal, planUnderApprobal, initFormData, isAll, summaryReasons, setSummaryReasons, isReasonChange, leftAllProjectOptions, isEditIssueKind } = props // allVersionOptions
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const [analysisExpand, setAnalysisExpand] = useState(true)
    const [richTextExpand, setRichTextExpand] = useState(true)
    const [testSuggestExpand, setTestSuggestExpand] = useState(true)
    const [showJoinProjectDel, setShowJoinProjectDel] = useState(false)
    const [showJoinProject, setShowJoinProject] = useState(false)
    const [showJoinVersion, setShowJoinVersion] = useState(false)
    const [imageVisible, setImageVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [closurePlan, setClosurePlan] = useState()
    const [closurePlanVersion, setClosurePlanVersion] = useState()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [showSearchDialog, setShowSearchDialog] = useState()
    const [oldData, setOldData] = useState([])
    const [testGroupRole] = useRolePermissions(['testGroup'])
    const [releaseStoryListOptions, setReleaseStoryListOptions] = useState([])
    const { doFetch: getReleaseList } = useGet()

    const { devProjects } = useMemo(() => initFormData || {}, [initFormData])

    const { isRecurrent } = useMemo(() => editFormData || {}, [editFormData])

    const isRelatedIssue = useMemo(() => (isRecurrent === 'Y'), [isRecurrent])

    const previewImage = useCallback(e => {
        if (e.target.tagName === 'IMG') {
            setImageSrc(e.target.src)
            setImageVisible(true)
        }
    }, [])

    const allUnderApprobal = useMemo(() => {
        const planVersion = _.isEmpty(planUnderApprobal) ? [] : planUnderApprobal
        return _.concat(_.isEmpty(underApprobal) ? [] : underApprobal, planVersion)
    }, [underApprobal, planUnderApprobal])

    const [closurePlanOpt, releasedOpt, closurePlanOpts, releasedOpts, planVersionOpt] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d2, d3, d4, d5, d6,] = optionsRes
        return [
            _.map(d2, x => ({ label: x.releaseId, text: x.releaseId, value: x.releaseId })),
            _.map(d3, x => ({ label: x.releaseId, text: x.releaseId, value: x.releaseId })),
            _.map(d4, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d5, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d6, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId, state: item.stateKey, principal: item.functionarys })),
        ]
    }, [optionsRes])

    const releaseStoryListOptionsWithExtend = useMemo(() => {
        const noVersion = _.isEmpty(underApprobal) ? [{ label: '不涉及版本规划', text: '不涉及版本规划', value: '不涉及版本规划' }] : []
        const initReleaseList = _.get(editFormData, 'releaseIdList')
        if (_.isEmpty(releaseStoryListOptions)) return _.concat(noVersion, _.map(_.filter(initReleaseList, v => v !== '不涉及版本规划'), x => ({ label: x, text: x, value: x })))
        const valueList = _.map(releaseStoryListOptions, 'value')
        const findNotIncludedList = _.filter(initReleaseList, item => !_.includes(valueList, item) && item !== '不涉及版本规划')
        const findNotIncludedOptions = _.map(findNotIncludedList, x => ({ label: x, text: x, value: x }))
        return _.concat(noVersion, findNotIncludedOptions, releaseStoryListOptions)
    }, [releaseStoryListOptions, editFormData, underApprobal])

    const releaseStoryListOptWithExtend = useMemo(() => {
        if (_.isEmpty(planVersionOpt)) return []
        const initReleaseList = _.get(editFormData, 'planReleaseIdList')
        const valueList = _.map(planVersionOpt, 'value')
        const findNotIncludedList = _.filter(initReleaseList, item => !_.includes(valueList, item))
        const findNotIncludedOptions = _.map(findNotIncludedList, x => ({ label: x, text: x, value: x }))
        const allList = _.concat(findNotIncludedOptions, planVersionOpt)
        if (_.isEmpty(planUnderApprobal)) return allList
        const values = _.map(planUnderApprobal, 'releaseId')
        return _.filter(allList, o => !_.includes(values, o.value))
    }, [planVersionOpt, editFormData, planUnderApprobal])

    const [influenceOptions, issueKindOptions, issueKindDetailOptions, allDevProjectOptions, testGroupOptions] = useMemo(() => {//complexityOptions
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const allDevProjectOpt = useMemo(() => {
        const defaultItem = [{ text: '不涉及项目规划', value: '不涉及项目规划' }]
        return _.concat(defaultItem, allDevProjectOptions)
    }, [allDevProjectOptions])

    const devProjectOpt = useMemo(() => {
        const defaultItem = [{ text: '不涉及项目规划', value: '不涉及项目规划' }]
        const devProjects = _.get(initFormData, 'devProjects')
        const filterList = _.filter(leftAllProjectOptions, o => o.examine === 'N')
        const options = _.filter(filterList, o => !_.includes(['1', '3', '7'], o?.status))
        if (_.isEmpty(devProjects)) return _.concat(defaultItem, options)
        const values = _.map(leftAllProjectOptions, 'value')
        const filterData = _.filter(devProjects, o => !_.includes(values, o.value))
        const concatData = _.filter(leftAllProjectOptions, o => _.includes(filterData, o.value))
        return _.concat(defaultItem, concatData, options)
    }, [initFormData, leftAllProjectOptions])

    const filterIssueKindDetailOptions = useMemo(() => {
        const issueKind = _.get(editFormData, 'issueKind')
        if (_.isEmpty(issueKind)) return []
        return _.filter(issueKindDetailOptions, x => _.includes(issueKind, x.memo))
    }, [editFormData, issueKindDetailOptions])

    const updateReleaseList = useCallback((projectIds, oldProject, currentVersion, discoveryProjectOnce, filterDelData, flag = true) => {
        if (_.isEmpty(projectIds)) return setReleaseStoryListOptions([])
        const obj = { versionType: 0, projectIds }
        getReleaseList(`/develop/release/list?${strParams(obj)}`)
            .then(res => {
                const options = _.map(_.filter(res, o => o.stateKey === 'T'), item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId }))
                if (flag) {
                    setReleaseStoryListOptions(options)
                } else {
                    discoveryProjectOnce = false
                    const underApprobalKeys = _.map(underApprobal, 'releaseId')
                    const newVersionIdList = _.concat(_.map(res, 'releaseId'), underApprobalKeys, ['不涉及版本规划'])
                    const allIncludeVersion = _.every(currentVersion, x => _.includes(newVersionIdList, x))
                    if (allIncludeVersion) {
                        if (!_.isEmpty(filterDelData)) {
                            setEditFormData(x => _.assign({}, x, { devProjects: oldProject }))
                            setShowJoinProjectDel({ projectIdList: filterDelData })
                        } else {
                            setReleaseStoryListOptions(options)
                        }
                    } else {
                        Messager.show(`请先删除该项目下的版本计划，再操作删除项目！`, { icon: 'error' })
                        setEditFormData(x => _.assign({}, x, { devProjects: oldProject }))
                    }
                }

            })
            .catch((err) => {
                if (!flag) discoveryProjectOnce = false
                Messager.show(err._message, { icon: 'error' });
            })
    }, [getReleaseList, setEditFormData, underApprobal])

    const changeForm = useCallback((newObj, bind) => {
        let discoveryProjectOnce = false
        setEditFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'devProjects') {
                if (!discoveryProjectOnce) {
                    discoveryProjectOnce = true
                    const newProject = _.get(newObj, 'devProjects', [])
                    const oldProject = _.get(oldObj, 'devProjects', [])
                    const initProject = _.get(initFormData, 'devProjects', [])
                    const filterProjectY = _.filter(leftAllProjectOptions, o => o.examine === 'Y')
                    const delData = _.filter(initProject, v => !_.includes(newProject, v))
                    const filterDelData = _.filter(delData, v => _.includes(_.map(filterProjectY, 'value'), v))
                    const deleteData = _.filter(oldProject, v => !_.includes(newProject, v))
                    const underApprobalOps = _.map(underApprobal, o => ({ value: o.releaseId, projectId: o.projectId, flag: true }))
                    // const concatData = _.concat(releaseStoryListOptionsWithExtend, underApprobalOps)
                    const filterVersion = _.filter(underApprobalOps, o => {
                        return (o.flag && _.includes(deleteData, o.projectId))
                    })
                    const filterPlanVersion = _.filter(oldObj['releaseIdList'], v => v !== '不涉及版本规划')
                    if ((_.isEmpty(_.get(newObj, 'devProjects')) && !_.isEmpty(filterPlanVersion)) || !_.isEmpty(filterVersion)) {
                        cloneObj[bind] = oldObj[bind]
                        discoveryProjectOnce = false
                        Messager.show(`请先删除该项目下的版本计划，再操作删除项目！`, { icon: 'error' })
                    } else if (_.isEmpty(_.get(newObj, 'devProjects')) && _.isEmpty(underApprobal)) {
                        discoveryProjectOnce = false
                        setReleaseStoryListOptions([])
                    } else {
                        const currentVersion = _.isEmpty(_.get(oldObj, 'releaseIdList')) ? [] : _.get(oldObj, 'releaseIdList')
                        updateReleaseList(cloneObj[bind], oldProject, currentVersion, discoveryProjectOnce, filterDelData, false)
                    }
                    // if (_.size(filterPlanVersion) > 0 && _.size(deleteData) > 0) {
                    //     cloneObj[bind] = oldObj[bind]
                    //     // const versionList = _.map(cloneObj['releaseIdList'], 'value')
                    //     Messager.show(`请先删除该项目下的版本计划，再操作删除项目！`, { icon: 'error' })
                    // } else {
                    //     updateReleaseList(cloneObj[bind])
                    // if (!_.isEmpty(filterDelData)) {
                    //     cloneObj[bind] = _.compact(_.concat([], cloneObj[bind], filterDelData))
                    //     setShowJoinProjectDel({ projectIdList: filterDelData })
                    // }
                    // }
                }
            }
            if (bind === 'complexity') {
                if (_.get(initFormData, 'complexity') === newObj[bind]) {
                    cloneObj['complexityRemarks'] = null
                    setSummaryReasons(x => _.assign({}, x, { complexity: false }))
                    setEditFormError(x => _.assign({}, x, { complexityRemarks: null }))
                } else setSummaryReasons(x => _.assign({}, x, { complexity: true }))


            }
            if (bind === 'issueKind') {
                if (_.size(newObj['issueKind']) > 1 && _.includes(newObj['issueKind'], '13')) {
                    cloneObj['issueKind'] = _.filter(newObj['issueKind'], x => x !== "13")
                }
                cloneObj['issueKindDetail'] = null
            }
            if (bind === 'releaseIdList') {
                const oldList = _.get(oldObj, 'releaseIdList')
                const newList = _.get(newObj, 'releaseIdList')

                if (_.size(oldList) > _.size(newList)) {
                    const releaseid = _.head(_.filter(oldList, v => !_.includes(newList, v)))
                    const findReleasedItem = _.find(releasedOpt, o => o.value === releaseid)
                    if (!_.isNil(findReleasedItem)) {
                        cloneObj[bind] = oldList
                        Messager.show('版本已发布或已上线，无法删除', { icon: 'error' })
                    } else {
                        const findItem = _.find(closurePlanOpt, o => o.value === releaseid)
                        if (!_.isNil(findItem)) {
                            cloneObj[bind] = oldList
                            setClosurePlan(newList)
                        } else {
                            if (_.includes(oldList, '不涉及版本规划')) {
                                cloneObj['releaseIdList'] = _.filter(_.get(newObj, 'releaseIdList'), x => x !== '不涉及版本规划')
                            } else if (_.includes(_.get(newObj, 'releaseIdList'), '不涉及版本规划')) {
                                cloneObj['releaseIdList'] = ['不涉及版本规划']
                            }
                        }
                    }
                }
            }
            if (bind === 'planReleaseIdList') {
                const values = _.map(planUnderApprobal, 'releaseId')
                const oldList = _.get(oldObj, 'planReleaseIdList')
                const newList = _.get(newObj, 'planReleaseIdList')
                cloneObj['_planReleaseList'] = _.map(newList, v => {
                    const findItme = _.find(planVersionOpt, o => o.value === v)
                    const principal = _.split(_.get(findItme, 'principal', ''), ',')
                    if (_.includes(principal, gd.User.operator_id) || isAll) {
                        return _.assign({}, findItme, { state: 'T' })
                    }
                    return findItme
                })
                setOldData(_.map(oldList, v => {
                    const findItme = _.find(planVersionOpt, o => o.value === v)
                    const principal = _.split(_.get(findItme, 'principal', ''), ',')
                    if (_.includes(principal, gd.User.operator_id) || isAll) {
                        return _.assign({}, findItme, { state: 'T' })
                    }
                    return findItme
                }))
                const oldArr = _.filter(oldList, v => !_.includes(values, v))
                const newArr = _.filter(newList, v => !_.includes(values, v))
                if (_.size(oldArr) > _.size(newArr)) {
                    const releaseid = _.head(_.filter(oldArr, v => !_.includes(newArr, v)))
                    const principal = _.split(_.get(_.find(planVersionOpt, o => o.value === releaseid), 'principal', ''), ',')
                    if (_.includes(principal, gd.User.operator_id) || isAll) {

                    } else {
                        const findReleasedItem = _.find(releasedOpts, o => o.value === releaseid)
                        if (!_.isNil(findReleasedItem)) {
                            cloneObj[bind] = oldArr
                            Messager.show('预规划项目已立项完成，不允许删除', { icon: 'error' })
                        } else {
                            const findItem = _.find(_.filter(closurePlanOpts, item => _.includes(_.get(initFormData, 'planReleaseIdList'), item.value)), o => o.value === releaseid)
                            if (!_.isNil(findItem)) {
                                cloneObj[bind] = oldArr
                                setClosurePlanVersion(newArr)
                            }
                        }
                    }
                }
            }
            return cloneObj
        })
    }, [setEditFormData, closurePlanOpt, releasedOpt, closurePlanOpts, releasedOpts, planVersionOpt, initFormData, planUnderApprobal, isAll, setSummaryReasons, setEditFormError, leftAllProjectOptions, updateReleaseList, underApprobal])

    const richTextRequired = useMemo(() => {
        return ['08', '03', '04', '05', '06', '10'].includes(editFormData?.issueStatus)
    }, [editFormData])

    const limitIssueKind = useMemo(() => {
        return ['08', '03', '04', '05', '06', '10'].includes(editFormData?.issueStatus)
    }, [editFormData])

    const isTestGroup = useMemo(() => {
        return (gd.User.operator_id === _.get(initFormData, 'inputUser') || testGroupRole)
    }, [initFormData, testGroupRole])

    const handleIssueKindOptions = useMemo(() => {
        if (limitIssueKind) {
            return _.filter(issueKindOptions, x => x.value !== '13')
        } else {
            return issueKindOptions
        }
    }, [issueKindOptions, limitIssueKind])

    const treeIssueKindOptions = useMemo(() => {
        const firstLevelList = _.filter(handleIssueKindOptions, x => _.isNil(x.pid))
        const issueKind = _.get(editFormData, 'issueKind')
        let disabledList = []
        if (isEditIssueKind) {
            if (!_.isEmpty(issueKind) && !_.includes(issueKind, '13')) {
                const pid = _.get(_.find(handleIssueKindOptions, v => v.value === issueKind[0]), 'pid')
                disabledList = _.map(_.filter(handleIssueKindOptions, x => !(x.value === pid || x.pid === pid)), 'value')
            }
        } else {
            const initIssueKind = _.get(initFormData, 'issueKind')
            const findItem = _.find(handleIssueKindOptions, v => v.value === _.head(initIssueKind))
            const pid = _.get(findItem, 'pid')
            disabledList = _.map(_.filter(handleIssueKindOptions, x => !(x.value === pid || x.pid === pid)), 'value')
        }
        const issueKindList = handleTreeOptions(handleIssueKindOptions, firstLevelList, 'value', 'pid', 'text', disabledList)
        return issueKindList
    }, [handleIssueKindOptions, editFormData, initFormData, isEditIssueKind])

    const onIssueSelected = useCallback((item) => {
        setEditFormData(old => {
            const cloneObj = _.clone(old)
            cloneObj['relatedIssue'] = _.get(item, 'id') || ''
            cloneObj['relatedIssueBizId'] = _.get(item, 'bizId') || ''
            return cloneObj
        })
    }, [setEditFormData])

    const ReleaseListSelect = useCallback((props) => {
        return <span style={{ margin: 0 }} onClick={() => {
            if (_.isEmpty(releaseStoryListOptionsWithExtend)) return Messager.show('请先选择研发项目，版本计划为研发项目范围下，状态为【清单整理中】的版本！', { icon: 'error' })
        }}>
            <VersionSelect {...props} />
        </span>
    }, [releaseStoryListOptionsWithExtend])

    useEffect(() => {
        const relatedIssue = _.get(editFormData, 'relatedIssue')
        setEditFormError(x => _.assign({}, x, { relatedIssue: isRelatedIssue ? isNil(relatedIssue) ? '必填项！' : null : null, relatedIssueBizId: isRelatedIssue ? isNil(relatedIssue) ? '必填项！' : null : null }))
    }, [editFormData, isRelatedIssue, setEditFormError])

    useEffect(() => {
        if (_.isEmpty(devProjects)) return
        updateReleaseList(devProjects)
    }, [devProjects, updateReleaseList])

    return <div className={'issue-analysis'}>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: analysisExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setAnalysisExpand(v => !v)} />
                分析信息
            </div>
            <div className="area-content-wrap" style={{ height: analysisExpand ? '' : 0 }}>
                <Form value={editFormData} onChange={changeForm} error={editFormError} onError={v => setEditFormError(x => _.assign({}, x, v))}>
                    <HFormInput label={'问题负责人'} bind={'personCharge'} required component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'问题分析人'} bind={'principal'} required component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'测试责任组'} bind={'testGroup'} component={isDetail ? Display : DisplaySelect} search options={testGroupOptions} convert={v => convertOptions(v, testGroupOptions)} disabled={!isTestGroup} />
                    <HFormInput label={'维护负责人'} bind={'supportPerson'} clear component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'问题影响'} bind={'influence'} required multiple search component={isDetail ? Display : Select}
                        options={influenceOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, influenceOptions)), '、')}>
                            <div className={'ellipsis-line'}>
                                {_.join(_.map(v, x => convertOptions(x, influenceOptions)), '、')}
                            </div>
                        </Tooltip>} />
                    <HFormInput label={'问题性质'} bind={'issueKind'} multiple search required component={isDetail ? Display : Select} tree clear
                        options={treeIssueKindOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, handleIssueKindOptions)), '、')}>
                            <div className={'ellipsis-line'}>
                                {_.join(_.map(v, x => convertOptions(x, handleIssueKindOptions)), '、')}
                            </div>
                        </Tooltip>} />
                    <Tooltip title={`问题性质仅允许同一个大类下多选，跨一级的变更，请联系刘凯或所属部门产品总监`}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'问题性质细分'} bind={'issueKindDetail'} multiple search clear component={isDetail ? Display : Select}
                        options={filterIssueKindDetailOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, filterIssueKindDetailOptions)), '、')}>
                            <div className={'ellipsis-line'}>
                                {_.join(_.map(v, x => convertOptions(x, filterIssueKindDetailOptions)), '、')}
                            </div>
                        </Tooltip>} />
                    <HFormInput label={'是否低级问题'} bind={'complexity'} component={isDetail ? Display : Select} required
                        options={yesNoOptions} convert={v => convertOptions(v, yesNoOptions)} disabled={!isReasonChange} />
                    <Tooltip title={
                        <div>
                            低级错误（Stupid Mistake）<br />
                            【定义】：<br />
                            客户无法忍受或体验极差的ISSUE<br />
                            【场景】：<br />
                            1、提供给客户的测试用例，客户发现问题，内部未发现问题<br />
                            2、基本业务规则（明文规定的要求）客户现场出现问题<br />
                            3、未满足客户明确提出的功能要求<br />
                            4、SQL脚本类的语法错误<br />
                            <div style={{ height: 22 }} />
                            <div>修改权限：创建人可改</div><br />
                        </div>
                    }>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput labelWidth={106} label={'是否重犯'} bind={'isRecurrent'} component={isDetail ? Display : Select} required
                        options={yesNoOptions} convert={v => convertOptions(v, yesNoOptions)} />
                    <Tooltip title={'版本升级后现场仍有问题'}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    {
                        (_.get(summaryReasons, 'complexity') && !isDetail) &&
                        <HFormInput label={'变更理由'} bind={'complexityRemarks'} required component={TextAreaInput} placeholder='低级问题变更理由' />
                    }
                    <HFormInput className='new-version' label={'在新版本已解决'} bind={'resolved'} required component={isDetail ? Display : RadioGroup}
                        options={yesNoOptions} convert={v => convertOptions(v, yesNoOptions)} componentWidth={186}
                    />
                    {/* <HFormInput label={'解决的具体版本'} bind={'newVersion'} required={isResolved} clear search labelWidth={isDetail?120:216} component={isDetail? Display: Select}
                                options={allVersionOptions} componentClass={'long-options'} multiple
                                convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, allVersionOptions)),'、')}>
                                    <div className={'ellipsis-line'}>
                                        {_.join(_.map(v, x => convertOptions(x, allVersionOptions)),'、')}
                                    </div>
                                </Tooltip>}/> */}
                    {/*<HFormInput label={'关联ISSUE'} bind={'relatedIssue'} component={isDetail? Display: Input} componentWidth={502}/>*/}
                    <HFormInput
                        required={isRelatedIssue}
                        bind='relatedIssueBizId'
                        label='关联ISSUE'
                        placeholder='查找'
                        className='search-tracer'
                        onFocus={() => setShowSearchDialog('issue')}
                        prefix={<Icon name='sousuo1' />}
                        suffix={<Icon name='baocuo' onClick={() => onIssueSelected()} />}
                        component={isDetail ? Display : Input}
                        convert={(v) => <span className={'can-enter-text'} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation();
                            setShowDetailDialog({
                                module: 'issue',
                                id: _.get(editFormData, 'relatedIssue')
                            })
                        }}>{v}</span>}
                    />
                    <HFormInput label={'产品工作量'} bind={'productWorkload'} component={isDetail ? Display : Input}
                        type={'number'} suffix={'人日'} min={0} digit={2}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            return v + '人日'
                        }} />
                    <HFormInput label={'开发工作量'} bind={'fixWorkload'} component={isDetail ? Display : Input}
                        type={'number'} suffix={'人日'} min={0} digit={2}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            return v + '人日'
                        }} />
                    <HFormInput label={'测试工作量'} bind={'testWorkload'} component={isDetail ? Display : Input}
                        type={'number'} suffix={'人日'} min={0} digit={2}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            return v + '人日'
                        }} />
                    <HFormInput label={'总工作量'} component={Display}
                        convert={() => _.sum([
                            _.toNumber(_.get(editFormData, 'productWorkload')) || 0,
                            _.toNumber(_.get(editFormData, 'fixWorkload')) || 0,
                            _.toNumber(_.get(editFormData, 'testWorkload')) || 0,
                        ]) + '人日'} />
                    {
                        !isDetail && <HFormInput label={'研发项目'} bind={'devProjects'} search multiple component={isDetail ? Display : Select} toolbar={false} clear
                            options={devProjectOpt} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, allDevProjectOpt)), '、')}>
                                <div className={'ellipsis-line'}>
                                    {_.join(_.map(v, x => convertOptions(x, allDevProjectOpt)), '、')}
                                </div>
                            </Tooltip>} />
                    }
                    {
                        isDetail &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">研发项目</div>
                            {
                                !_.isEmpty(_.get(editFormData, 'devProjects')) &&
                                <div className={'release-list-show-wrap flex'}>
                                    {
                                        _.map(_.get(editFormData, 'devProjects'), item => {
                                            return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                                <span className={'release-list-item-text'}>{convertOptions(item, allDevProjectOpt)}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        !isDetail &&
                        <Tooltip title={'申请加入已基线项目'}>
                            <span>
                                <Icon name={'tianjia'} className={'join-version-icon'} style={{ fontSize: 14, color: '#5477FF', marginRight: 4 }}
                                    onClick={() => setShowJoinProject(true)} />
                            </span>
                        </Tooltip>
                    }
                    {
                        !_.isEmpty(_.get(editFormData, 'projectAuditLog', [])) &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">审核中项目</div>
                            <div className={'release-list-show-wrap'}>
                                {
                                    _.map(_.get(editFormData, 'projectAuditLog', []), o => {
                                        return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                            <Tooltip title={o.projectName} placement="left">
                                                <span className={'release-list-item-text'}>{o.projectName}</span>
                                            </Tooltip>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                    <br />
                    {
                        !isDetail &&
                        <HFormInput label={'版本计划'} bind={'releaseIdList'} disabled={isDetail} component={ReleaseListSelect} isControlled={true} componentWidth={502}
                            toolbar={false} search multiple options={releaseStoryListOptionsWithExtend} componentClass={'release-list-options'} />
                    }
                    {
                        !isDetail &&
                        <Tooltip title={'申请加入已封板的版本。只允许选择所属项目下的进行中的版本计划！'}>
                            <span>
                                <Icon name={'tianjia'} className={'join-version-icon'} style={{ fontSize: 14, color: '#5477FF', marginRight: 4 }}
                                    onClick={() => setShowJoinVersion(true)} />
                            </span>
                        </Tooltip>
                    }
                    {
                        !isDetail &&
                        <Tooltip title={
                            <div>
                                请先选择研发项目，版本计划为研发项目范围下，状态为【清单整理中】的版本！
                                不涉及版本规划：当前ISSUE，不需要通过新增版本就可以修复现场问题并能获取客户认可，版本计划可以选择此项，例如客户申请历史版本，发布时丢失了组件，通过重新提供全量组件，即可修复现场问题。此项为特例选项，请谨慎使用！
                            </div>
                        }>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                    }
                    <br />
                    {
                        !isDetail &&
                        <HFormInput label={'预规划项目'} bind={'planReleaseIdList'} disabled={isDetail} component={VersionSelect} isControlled={true} componentWidth={502}
                            toolbar={false} search multiple options={releaseStoryListOptWithExtend} componentClass={'release-list-options'} />
                    }
                    {
                        isDetail &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">版本计划</div>
                            {
                                !_.isEmpty(_.get(editFormData, 'releaseIdList')) &&
                                <div className={'release-list-show-wrap flex'}>
                                    {
                                        _.map(_.get(editFormData, 'releaseIdList'), item => {
                                            return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                                <span className={'release-list-item-text'}>{convertOptions(item, releaseStoryListOptionsWithExtend)}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                            <Tooltip title={
                                <div>
                                    不涉及版本规划：当前ISSUE，不需要通过新增版本就可以修复现场问题并能获取客户认可，版本计划可以选择此项，例如客户申请历史版本，发布时丢失了组件，通过重新提供全量组件，即可修复现场问题。此项为特例选项，请谨慎使用！
                                </div>
                            }>
                                <span>
                                    <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                </span>
                            </Tooltip>
                        </div>
                    }
                    <br />
                    {
                        isDetail &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">预规划项目</div>
                            {
                                !_.isEmpty(_.get(editFormData, 'planReleaseIdList')) &&
                                <div className={'release-list-show-wrap flex'}>
                                    {
                                        _.map(_.get(editFormData, 'planReleaseIdList'), item => {
                                            return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                                <span className={'release-list-item-text'}>{convertOptions(item, releaseStoryListOptWithExtend)}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    <br />
                    {
                        !_.isEmpty(allUnderApprobal) &&
                        <HFormInput label='审核中版本' value={allUnderApprobal} component={Display} componentWidth={502} convert={v => {
                            const underApprobalIds = _.join(_.map(v, o => o.releaseId), '，')
                            return <Tooltip title={underApprobalIds}>
                                <div className='apply-join-under-approbal'>{underApprobalIds}</div>
                            </Tooltip>
                        }} />
                    }
                </Form>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: richTextExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setRichTextExpand(v => !v)} />
                解决方案
                {
                    richTextRequired &&
                    <span style={{ color: 'red' }}>（必填）</span>
                }
            </div>
            <div className="area-content-wrap" style={{ height: richTextExpand ? '' : 0 }}>
                <div className="rich-text-detail-wrap flex" style={{ display: isDetail ? "block" : 'none' }}>
                    <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'whyAndHow')?.replace(/\n/g, '<br/>') }} />
                </div>
                <div className="rich-text-area" style={{ display: !isDetail ? "block" : 'none' }}>
                    <TinyEditor ref={resolveRef} height={350} />
                </div>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: testSuggestExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setTestSuggestExpand(v => !v)} />
                用户测试建议
            </div>
            <div className="rich-text-detail-wrap flex" style={{ display: isDetail && testSuggestExpand ? "block" : 'none' }}>
                {_.get(editFormData, 'testSuggest')}
            </div>
            <div className="rich-text-area" style={{ display: !isDetail && testSuggestExpand ? "block" : 'none' }}>
                <TextArea value={_.get(editFormData, 'testSuggest')} onChange={(value) => setEditFormData(x => ({ ...x, testSuggest: value }))}
                    maxLength={1000} className={'test-suggest-textarea'} />
            </div>
        </div>
        <Image
            style={{ display: 'none' }}
            src={imageSrc}
            preview={{
                visible: imageVisible,
                src: imageSrc,
                onVisibleChange: visible => {
                    setImageVisible(visible)
                }
            }}
        />
        {
            showJoinVersion &&
            <ApplyJoinVersionDialog id={id} close={() => setShowJoinVersion(false)} type={'ISSUE'} afterRefresh={refreshReleaseList} projectIds={_.get(editFormData, 'devProjects')} />
        }
        {
            showJoinProject &&
            <ApplyJoinProjectDialog
                mode='add'
                businessId={id}
                businessType='issue'
                leafProjectIds={_.get(initFormData, 'devProjects', [])}
                projectAuditLog={_.get(editFormData, 'projectAuditLog', [])}
                close={() => setShowJoinProject(false)}
                leftAllProjectOptions={_.filter(leftAllProjectOptions, o => o.examine === 'Y')}
                refresh={refreshReleaseList}
            />
        }
        {
            showJoinProjectDel &&
            <ApplyJoinProjectDialog
                mode='del'
                businessId={id}
                businessType='issue'
                initParams={showJoinProjectDel}
                leafProjectIds={_.get(initFormData, 'devProjects', [])}
                projectAuditLog={_.get(editFormData, 'projectAuditLog', [])}
                close={() => {
                    setShowJoinProjectDel(false)
                }}
                leftAllProjectOptions={_.filter(leftAllProjectOptions, o => o.examine === 'Y')}
                refresh={refreshReleaseList}
            />
        }
        {
            !_.isNil(closurePlan) &&
            <ClosurePlanMessage
                id={id}
                type='ISSUE'
                releaseidList={closurePlan}
                close={() => setClosurePlan(null)}
                afterRefresh={refreshReleaseList}
            />
        }
        {
            !_.isNil(closurePlanVersion) &&
            <ClosurePlanMessage
                id={id}
                type='PLANISSUE'
                releaseidList={closurePlanVersion}
                close={() => setClosurePlanVersion(null)}
                afterRefresh={refreshReleaseList}
                setEditFormData={setEditFormData}
                oldData={oldData}
            />
        }
        {
            showSearchDialog === 'issue' &&
            <ViewQueryDialog
                bizName='ISSUE'
                initValue={_.get(editFormData, 'relatedIssue')}
                close={() => setShowSearchDialog(null)}
                funcCode={'0005'}
                outerSetItem={onIssueSelected}
                bizField='ProductionIssue.IssueId'
            />
        }
        {
            _.get(showDetailDialog, 'module') === 'issue' &&
            <IssueDetailDialog
                close={() => setShowDetailDialog(null)}
                currentInfo={{
                    id: _.get(showDetailDialog, 'id'),
                    mode: 'detail',
                }}
            />
        }
    </div>
}

export default IssueAnalysis;
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tabs, Switch } from 'antd'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, DatePicker, Select } from 'rootnet-edit'
import Macroforecast from '../macroforecast'
import PersonnelGap from '../personnelGap'
import Workload from '../workload'
import ResourcePoolImport from '../resourcePoolImport'
import EstimatedTotalWorkVolume from '../estimatedTotalWorkVolume'
import ModuleInformation from '../moduleInformation'
import ExistingPersonnel from '../existingPersonnel'
import { useGet } from 'rootnet-biz/es/hooks'
import { strParams } from '../../../../../utils/publicFun'
import { personnelGapFun, workloadFun } from '../formatData'
import { selectOption } from '../../../../common/commonMethod'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import { ScheduleManagementContext } from '../../../../common/Context'
import './index.scss'

const { TabPane } = Tabs
const { str8ToDate } = toDate

const TEMPLATE_URL = '/field/download?flag=5' // 下载模板
const TOTAL_AMOUNT_URL = 'resourceInfo/allList' // 预计总工作量
const PERSONNEl_GAP_URL = '/resourceInfo/list' // 人员缺口预测
const WORKLOAD_URL = '/resourceInfo/capacityList' // 工作量预测
const MODULE_URL = '/moduleInfo/getModelIdAndName' // 模块下拉数据
const INVOLVING_MODULE_URL = '/resourceInfo/moduleInfo' // 涉及模块
const NOW_RESEARCHERS_URL = '/userFactor/list' // 现在人员
const PERSONNELGAP_EXPORT_URL = '/resourceInfo/exportExcel' //人员缺口预测导出
const WORKLOAD_EXPORT_URL = '/resourceInfo/exportCapacityExcel' // 工作量预测导出

export default function ResourceForecastDetail(props) {
  const { deptId, isFuncCodeImport, isFuncCodeEdit, isFuncCodeModule, isFuncCodePeople, positionOptions, techLevelOptions, defaultPositionOptions } = props
  const { downloadGetProgress, downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState({ dateTimeResources: dateFormat('YYYY', new Date()) })
  const [activeKey, setActiveKey] = useState('personnelGap')
  const [openImport, setOpenImport] = useState(false)
  const [openDrawer, setOpenDrawer] = useState()
  const [estimate, setEstimate] = useState()
  const [isInherit, setIsInherit] = useState(false)
  const [switchChecked, setSwitchChecked] = useState(false)
  const [moduleOpt, setModuleOpt] = useState([])

  const { data: moduleData, doFetch: moduleFetch } = useGet()
  const { data: totalAmountData, doFetch: totalAmountFetch } = useGet()
  const { data: personnelGapData, doFetch: personnelGapFetch, loading: personnelGapLoading } = useGet()
  const { data: workloadData, doFetch: workloadFetch, loading: workloadLoading } = useGet()
  const { data: involvingModuleData, doFetch: involvingModuleFetch } = useGet()
  const { data: nowResearchersData, doFetch: nowResearchersFetch } = useGet()

  const isModule = useMemo(() => _.isEmpty(moduleData), [moduleData])

  const workloadList = useMemo(() => {
    if (_.isEmpty(workloadData) || _.isEmpty(personnelGapData)) return []
    return workloadFun(workloadData, personnelGapData, isModule)
  }, [personnelGapData, workloadData, isModule])

  const operations = useMemo(() => {
    return <div className='flex'>
      <div className='flex'>
        显示全部月份：
        <Switch
          onChange={v => setSwitchChecked(v)}
          checked={switchChecked}
          checkedChildren="开"
          unCheckedChildren="关"
        />
      </div>
      <TextIconBtn onClick={() => {
        const name = activeKey === 'personnelGap' ? '人员缺口预测' : '工作量预测'
        if (activeKey === 'personnelGap') {
          downloadGetProgress(`${PERSONNELGAP_EXPORT_URL}?${strParams(_.omit(params, 'department'))}`, name)
        } else {
          downloadPostProgress(WORKLOAD_EXPORT_URL, exportData(workloadList, params?.dateTimeResources), name)
        }
      }} icon='daochu' text='导出' style={{ marginRight: 16 }} />
    </div>
  }, [activeKey, downloadGetProgress, params, switchChecked, workloadList, downloadPostProgress])

  const personnelGapList = useMemo(() => {
    if (_.isEmpty(personnelGapData) && _.isEmpty(defaultPositionOptions)) return []
    return personnelGapFun(personnelGapData, isModule, defaultPositionOptions)
  }, [isModule, personnelGapData, defaultPositionOptions])

  const moduleInfoFetch = useCallback((parameter = {}, flag = false) => {
    involvingModuleFetch(`${INVOLVING_MODULE_URL}?${strParams(_.assign({}, parameter, flag && { showAll: 0 }))}`)
  }, [involvingModuleFetch])

  const refreshAll = useCallback((parameter = {}, flag = false) => {
    const param = _.omit(parameter, 'department')
    personnelGapFetch(`${PERSONNEl_GAP_URL}?${strParams(param)}`).then(() => setIsInherit(true))
    workloadFetch(`${WORKLOAD_URL}?${strParams(param)}`)
    totalAmountFetch(`${TOTAL_AMOUNT_URL}?${strParams(param)}`)
    moduleInfoFetch(param, flag)
    nowResearchersFetch(`${NOW_RESEARCHERS_URL}?${strParams(parameter)}`)
  }, [personnelGapFetch, workloadFetch, totalAmountFetch, moduleInfoFetch, nowResearchersFetch])

  useEffect(() => {
    if (_.isNil(deptId)) return
    moduleFetch(`${MODULE_URL}?department=${deptId}`).then(res => {
      if (_.isEmpty(res)) {
        setParams(x => _.assign({}, x, { modelList: null }))
        setModuleOpt([])
        return
      }
      const modelIds = _.map(res, o => o.moduleId)
      setParams(x => _.assign({}, x, { modelList: _.join(modelIds, ','), department: deptId }))
      return setModuleOpt(selectOption(res, ['moduleIdName', 'moduleId']))
    })
  }, [deptId, moduleFetch])

  useEffect(() => {
    if (_.isNil(params?.modelList)) return
    refreshAll(params)
  }, [params, refreshAll])

  useEffect(() => {
    let totalAmount = 0
    let involvingModule = 0 // 涉及模块
    let requiredPersonnel = 0
    let nowResearchers = 0
    let forecasterGap = 0
    let plansHire = 0
    if (_.isEmpty(moduleData)) return setEstimate({ totalAmount, involvingModule, requiredPersonnel, nowResearchers, forecasterGap, plansHire, })
    totalAmount = _.sum(_.map(totalAmountData, o => {
      if (_.isNaN(_.toNumber(o.workLoad))) return 0
      return _.toNumber(o.workLoad)
    }))
    involvingModule = _.size(_.keys(_.groupBy(personnelGapData, 'model')))
    nowResearchers = _.size(_.keys(_.groupBy(nowResearchersData, 'userName')))
    plansHire = _.toNumber(_.get(_.head(personnelGapData), 'totalPlannedRecruitment', '0'))
    setEstimate(_.assign({ totalAmount, involvingModule, requiredPersonnel, nowResearchers, forecasterGap, plansHire, }))
  }, [moduleData, nowResearchersData, params, personnelGapData, totalAmountData])

  return (
    <div className='resource-forecast-detail'>

      <div>
        <Form value={params} onChange={setParams}>
          <FormInput
            horizontal
            label='选择时间周期'
            bind='dateTimeResources'
            mode='year'
            component={DatePicker}
            bindInConvert={v => str8ToDate(v + '0101')}
            bindOutConvert={v => dateFormat('YYYY', v)}
          />
          <FormInput
            horizontal
            multiple
            label='模块'
            bind='modelList'
            options={moduleOpt}
            component={Select}
            bindInConvert={v => _.split(v, ',')}
            bindOutConvert={v => _.join(v, ',')}
          />
        </Form>
      </div>
      {/* 总体预测 */}
      {
        (isModule || _.isEmpty(totalAmountData)) ? <>
          <div className='import-annual-forecast-workload'>
            <div>
              <TextIconBtn size={24} onClick={() => setOpenImport(true)} icon='tianjia' text='请导入年度预测工作量' />
            </div>
          </div>
        </> : <>
          <Macroforecast {...{ estimate, setOpenImport, isFuncCodeImport, setOpenDrawer }} />

          <Tabs activeKey={activeKey} onChange={setActiveKey} tabBarExtraContent={operations}>
            <TabPane tab='人员缺口预测' key={'personnelGap'} >
              <PersonnelGap data={personnelGapList} {...{ year: params.dateTimeResources, loading: personnelGapLoading, refresh: () => refreshAll(params), isInherit, setIsInherit, switchChecked }} />
            </TabPane>
            <TabPane tab='工作量预测' key={'workload'} >
              <Workload dataSource={workloadList} {...{ loading: workloadLoading, switchChecked }} />
            </TabPane>
          </Tabs>
        </>
      }

      {openImport && <ResourcePoolImport {...{ showExport: false, refresh: () => refreshAll(params), template: TEMPLATE_URL, close: () => setOpenImport(false), forecastYear: params?.dateTimeResources }} />}
      <EstimatedTotalWorkVolume {...{ visible: openDrawer === 'totalAmount', close: () => setOpenDrawer(false), data: totalAmountData, refresh: () => refreshAll(params), isFuncCodeEdit, isModule }} />
      <ModuleInformation {...{ visible: openDrawer === 'involvingModule', close: () => setOpenDrawer(false), data: involvingModuleData, refresh: (flag) => refreshAll(params, flag), isFuncCodeModule, isModule, year: params?.dateTimeResources, moduleInfoFetch: (flag) => moduleInfoFetch(params, flag), defaultPositionOptions }} />
      <ExistingPersonnel {...{ visible: openDrawer === 'nowResearchers', close: () => setOpenDrawer(false), data: nowResearchersData, refresh: () => refreshAll(params), moduleOpt, positionOptions, techLevelOptions, isModule, isFuncCodePeople, year: params?.dateTimeResources, }} />
    </div>
  )
}

function exportData(data, dateTimeResources) {
  if (_.isEmpty(data)) return
  const listData = _.map(data, item => {
    return _.map(new Array(12), (v, i) => {
      return {
        modelName: item.modelName,
        forecastWorkload: item[`forecastWorkload${i + 1}`],
        existingCapacity: item[`existingCapacity${i + 1}`],
        differenceWorkload: item[`differenceWorkload${i + 1}`],
        month: `${i + 1}月`,
        dateTimeResources
      }
    })
  })
  return _.concat(...listData)
}
import _ from 'lodash'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { N2 } from 'rootnet-core/format'
import { DataGrid, Dialog } from 'rootnet-ui'
import { Radio } from 'rootnet-edit'
import { useGet } from '../../../../utils/hook'
import { DownCsvV2 } from '../../../common/download'
import { Box } from '../../../common/commonComponent'
import { Icon } from '../../../../project_share/components'

const getCostColumn = () => {
  return [
    { header: '工作类型', bind: 'jobTypeName' },
    { header: '项目ID', bind: 'projectId' },
    { header: '项目名称', bind: 'projectName' },
    { header: '人员', bind: 'userName' },
    { header: '部门', bind: 'departmentName' },
    { header: '工作日期', bind: 'workDate' },
    { header: '用时（h）', bind: 'useTime', csvConvert: convertTime },
    { header: '工时（h）', bind: 'workTime', csvConvert: convertTime },
    { header: '已用成本（元）', bind: 'useCost' },
    { header: '研发任务ID', bind: 'tracerId' },
    { header: '需求', bind: 'interiorReqNo' },
  ]
  function convertTime(v) {
    return v ? parseInt((v / 60) * 10) / 10 : ''
  }
}

const getCostColumns = (handleTreeClick, selectedVal) => {
  const switchName = { projectName: '项目名称', departmentName: '部门', jobTypeName: '工作类型' }
  const type = _.filter(['projectName', 'departmentName', 'jobTypeName'], v => selectedVal !== v)
  return [
    { header: switchName[selectedVal], bind: selectedVal, width: 180, tooltip: true, convert: (v, o, i) => o._level === 2 ? '' : addLeftIcon(v, o, i) },
    { header: '项目ID', bind: 'projectId', width: 90, tooltip: true },
    { header: switchName[type[0]], bind: type[0], width: 90, tooltip: true },
    { header: '人员', bind: 'userName', width: 90, tooltip: true },
    { header: switchName[type[1]], bind: type[1], width: 90, tooltip: true },
    { header: '工作日期', bind: 'workDate', width: 90, tooltip: true },
    { header: '用时（h）', bind: 'useTime', align: 'right', width: 85, tooltip: true, convert: (v, o) => o._level <= 1 ? cumulativeTime(v) : convertTime(v) },
    { header: '工时（h）', bind: 'workTime', align: 'right', width: 85, tooltip: true, convert: (v, o) => o._level <= 1 ? cumulativeTime(v) : convertTime(v) },
    { header: '已用成本（元）', bind: 'useCost', align: 'right', width: 110, tooltip: true, convert: (v, o) => o._level <= 1 ? cumulativeTime(N2(v)) : N2(v) },
    { header: '研发任务ID', bind: 'tracerId', width: 160, tooltip: true },
    { header: '需求', bind: 'interiorReqNo', width: 160, tooltip: true },
  ]
  function convertTime(v) {
    return v ? parseInt((v / 60) * 10) / 10 : ''
  }
  function cumulativeTime(name) { return <span style={{ fontWeight: '900' }}>{name}</span> }

  function addLeftIcon(v, o, i) {
    return <span className='leftArrow' onClick={() => handleTreeClick(o, i)
    }>
      <Icon
        name="arrow_drop_down"
        className='arrow_drop_down'
        style={{
          transform: _.get(o, '_expand') ? 'none' : 'rotate(-90deg)',
          marginLeft: o._level * 15,
        }} />
      {v}
    </span >
  }

}

const getCostOption = (columns, combined) => ({
  columns,
  summary: [
    [
      { header: '合计', colSpan: 6, align: "right" },
      { header: d => combined(d, 'useTime').toFixed(1), align: "right" },
      { header: d => combined(d, 'workTime').toFixed(1), align: "right" },
      { header: d => N2(combined(d, 'useCost')), align: "right" },
    ]
  ],
  autoFill: true,
  virtualized: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
});


export default function CostDetails(props) {
  const { costProjectDetail, setCostProjectDetail } = props
  const { data: costList, doFetch: getCostList, loading: costListLoading, error: costListError } = useGet()
  const [showList, setShowList] = useState()
  const [selectedVal, setSelectedVal] = useState('departmentName')

  useEffect(() => {
    if (_.isEmpty(costProjectDetail)) return
    const url = `/project/costlists?projectId=${costProjectDetail.searchParams}${_.isNil(_.get(costProjectDetail,'priceFlag'))?'':('&priceflag='+costProjectDetail.priceFlag)}`
    getCostList(url)
  }, [costProjectDetail, getCostList])

  const handleList = useMemo(() => {
    if (_.isNil(costList)) return []
    const treeList = _.map(_.groupBy(_.orderBy(costList, 'workDate', 'desc'), x => x[selectedVal === 'projectName'?'projectId':selectedVal]), (departmentItems, departmentName) => ({
      [selectedVal]: selectedVal === 'projectName'? _.get(departmentItems[0],'projectName') : departmentName,
      keyPath: departmentName,
      projectId: selectedVal === 'projectName'? _.get(departmentItems[0],'projectId') : null,
      useTime: _.reduce(departmentItems, (acc, x) => _.toNumber(acc) + _.toNumber((x.useTime / 60).toFixed(1)), 0).toFixed(1),
      workTime: _.reduce(departmentItems, (acc, x) => _.toNumber(acc) + _.toNumber(x.workTime / 60), 0).toFixed(1),
      useCost: _.reduce(departmentItems, (acc, x) => _.toNumber(acc) + _.toNumber(x.useCost), 0),
      child: _.map(_.groupBy(departmentItems, x => x.userName), (userNameItems, userName) => ({
        [selectedVal]: userName,
        keyPath: userName,
        projectId: selectedVal === 'projectName'? _.get(departmentItems[0],'projectId') : null,
        useTime: _.reduce(userNameItems, (acc, x) => _.toNumber(acc) + _.toNumber((x.useTime / 60).toFixed(1)), 0).toFixed(1),
        workTime: _.reduce(userNameItems, (acc, x) => _.toNumber(acc) + _.toNumber(x.workTime / 60), 0).toFixed(1),
        useCost: _.reduce(userNameItems, (acc, x) => _.toNumber(acc) + _.toNumber(x.useCost), 0),
        child: userNameItems,
      }))
    }))
    return handleTree(treeList, 'child', 'keyPath')
  }, [costList, selectedVal])

  useEffect(() => {
    const userList = _.filter(handleList, item => item._level <= 0)
    setShowList(userList)
  }, [handleList])

  const handleTreeClick = useCallback((o, i) => {
    const updateItem = _.assign({}, showList[i], { _expand: !showList[i]._expand })
    if (o._expand) {
      let endIndex = -1
      for (let currentIndex = i + 1; currentIndex < showList.length; currentIndex++) {
        if (showList[currentIndex]._level <= o._level) {
          endIndex = currentIndex
          break
        }
      }
      setShowList(showList.slice(0, i).concat(updateItem, endIndex !== -1 ? showList.slice(endIndex) : []))
    } else {
      const filterList = _.get(o, 'child')
      const addList = _.map(filterList, item => _.assign(item, { _level: o._level + 1, _expand: false }))
      setShowList(showList.slice(0, i).concat(updateItem, addList, showList.slice(i + 1)))
    }
  }, [showList])

  const option = useMemo(() => getCostOption(getCostColumns(handleTreeClick, selectedVal), combined), [handleTreeClick, selectedVal])

  return (
    <Dialog header='成本详情' cancel={() => setCostProjectDetail({})} cancelButtonVisible={false} confirmButtonVisible={false} className='cost-detail-dialog'>
      <div className="cost-dialog-content">
        <div className="up">
          <span style={{ marginRight: '50px' }}>
            <Radio value={selectedVal === 'departmentName'} onChange={() => setSelectedVal('departmentName')}>按部门</Radio>
            <Radio value={selectedVal === 'projectName'} onChange={() => setSelectedVal('projectName')}>按项目</Radio>
            <Radio value={selectedVal === 'jobTypeName'} onChange={() => setSelectedVal('jobTypeName')}>按工作类型</Radio>
          </span>
          <DownCsvV2 tit={costProjectDetail.csvFileName} value={costList} options={getCostColumn()} style={{ marginRight: '7px' }} />
        </div>
        <Box data={showList} className='x-card-singlegrid' loading={costListLoading} error={costListError}>
          <DataGrid data={showList} option={option} className='x-datagrid' />
        </Box>
      </div>
    </Dialog>
  )

  function handleTree(treeData, childrenName, keyName) {
    const treeList = []
    add(_.cloneDeep(treeData))
    return treeList

    function add(data, level = 0, parent = null) {
      if (!_.isArray(data)) return treeList
      data.forEach(item => {
        item._level = level
        item._parent = parent
        item._hasChildren = hasChildren(item)
        item._expand = false;
        item._childrenList = _.map(item[childrenName], x => x[keyName])
        treeList.push(item)
        if (item._hasChildren) add(item[childrenName], level + 1, item[keyName])
      })

      function hasChildren(item) {
        return _.has(item, childrenName) && !_.isEmpty(item[childrenName])
      }
    }
  }

  function combined(d, bind) {
    return _.reduce(d, (acc, x) => {
      if (_.get(x, `${bind}`) && _.get(x, '_level') === 0) {
        return _.toNumber(acc) + _.toNumber((x[bind]))
      }
      return _.toNumber(acc)
    }, 0)
  }

}

import React, {useState} from 'react';
import FuncTable from "./FuncTable";
import FuncTableEdit from "./components/editPage/FuncTableEdit";

function FuncTableWrap(props) {
    const [mode, setMode] = useState('list')
    const [currentId, setCurrentId] = useState()
    return <div className={'fill'}>
        {
            mode === 'list' && <FuncTable {...{setMode, setCurrentId}}/>
        }
        {
            mode !== 'list' && <FuncTableEdit {...{mode, setMode,currentId ,setCurrentId}}/>
        }
    </div>
}

export default FuncTableWrap;
import React, {useMemo, useState} from 'react';
import {Dialog} from "rootnet-ui";
import _ from "lodash";
import {Display, FormInput, Select} from "rootnet-edit";
import convertOptions from "../../../../../../common/ConvertOptions";

function TraceRunPipelineBranchSelect(props) {
    const {branchList, close, onSubmit} = props
    const [branch, setBranch] = useState()

    const branchListOptions = useMemo(()=>{
        return _.map(branchList, x => ({
            text: x?.targetBranchName,
            value: x?.targetBranchName,
        }))
    },[branchList])

    const sourceBranch = useMemo(()=>{
        return convertOptions(branch, branchList, 'featureBranchName', 'targetBranchName')
    },[branchList, branch])

    return <Dialog header={'分支合并参数确认'} className={'trace-run-pipeline-branch-dialog'} contentClassName={'flex-y'} cancel={close} contentStyle={{padding: 16}}
                   confirmButtonDisabled={_.isNil(branch)} confirm={()=>onSubmit(branch)}>
        <FormInput label={'目标分支（研发任务存在多个版本）'} value={branch} onChange={setBranch} componentWidth={280} component={Select} search
                   options={branchListOptions}/>
        <FormInput label={'源分支'} value={sourceBranch} componentWidth={280} component={Display} componentStyle={{height: 'unset', minHeight: 32}}/>
    </Dialog>
}

export default TraceRunPipelineBranchSelect;
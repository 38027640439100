import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, Dialog, Messager, Button, Tooltip } from 'rootnet-ui'
import { Form, FormInput, Input, TextArea } from 'rootnet-edit'
import Icon from "../../../../components/Icon";
import { useApi } from "../../../../utils/hook";
import _ from 'lodash'
import './IssuesDialog.scss'
import './RadioDialog.scss'
import { SearchBox } from "../common/commonfunc";
import WaterMarkDialog from "../../../common/WaterMarkDialog";

const modeConfig = [
    {
        id: 38,
        mode: 'ISSUES处理',
        url: '/worktime/issue/detail',
        fieldList: ['issueId', 'shortDesc'],
        name: 'ISSUES ID/名称',
        primaryKey: 'issueId',
        addUrl: '/issueSelf/relatedlist?issueId=',
        addText: 'issue',
    },
    {
        id: 6,
        mode: '版本',
        url: '/worktime/releaseinfo/detail',
        fieldList: ['releaseId'],
        name: '版本ID/名称',
        primaryKey: 'releaseId',
        addUrl: '/develop/release/list?versionType=0&releaseId=',
        addText: '版本',
    },
    {
        id: 7,
        mode: '版本',
        url: '/worktime/releaseinfo/detail',
        fieldList: ['releaseId'],
        name: '版本ID/名称',
        primaryKey: 'releaseId',
        addUrl: '/develop/release/list?versionType=0&releaseId=',
        addText: '版本',
    },
    {
        id: 19,
        mode: '服务单处理',
        url: '/worktime/service/detail',
        fieldList: ['csRecordId', 'issueDesc'],
        name: '服务单ID/名称',
        primaryKey: 'csRecordId',
        addUrl: '/serviceSelf/relatedlist?csRecordId=',
        addText: '服务单',
    },
    {
        id: 8,
        mode: '工作量评估',
        url: '/worktime/req/detail',
        fieldList: ['interiorReqNo', 'title'],
        name: '需求ID/名称',
        primaryKey: 'interiorReqNo',
        addUrl: '/develop/requirement/list?interiorReqNo=',
        addText: '需求',
    },
    {
        id: 39,
        mode: '工作量评估',
        url: '/worktime/req/detail',
        fieldList: ['interiorReqNo', 'title'],
        name: '需求ID/名称',
        primaryKey: 'interiorReqNo',
        addUrl: '/develop/requirement/list?interiorReqNo=',
        addText: '需求',
    },
]

function getColumnsHeader(id) {
    return _.find(modeConfig, x => x.id === id).name
}

function convertName(id, o) {
    switch (id) {
        case 38:
            return `${o.issueId}-${o.shortDesc}`
        case 6:
            return o.releaseId
        case 7:
            return o.releaseId
        case 19:
            return `${o.csRecordId}-${o.issueDesc}`
        case 8:
            return `${o.interiorReqNo}-${o.title}`
        case 39:
            return `${o.interiorReqNo}-${o.title}`
        default:
            return ''
    }
}

const getTableOption = (props) => {
    const { id } = props
    return {
        autoFill: true,
        columns: [
            { selection: true },
            {
                header: getColumnsHeader(id),
                convert: (v, o) => convertName(id, o),
                width: 600,
                tooltip: true
            },
            {
                header: '累计投入(h)',
                bind: 'sumUseTime',
                align: 'right',
                convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : '',
                width: 120
            },
        ]
    }
}

const HFormInput = (props) => {
    return <FormInput horizontal componentWidth={120} labelWidth={100} type='number' digit={1} min={0.1} required {...props} />;
}
const INIT_FORM_DATA = {
    auditUseTime: null,
}

// ISSUES、版本、服务单、需求弹窗
function IssuesDialog(props) {
    const { close, date, jobType, refresh, mode, usedTime, detail, cardDetail, id, beforeCutoffDay, isSelf, searchUser, showCutoffDay } = props
    const addJobType = id === 8 ? detail.children[0].id : id
    const isAdd = useMemo(() => _.isNil(cardDetail), [cardDetail])
    const initFormData = useMemo(() => isAdd ? INIT_FORM_DATA : { auditUseTime: cardDetail.auditUseTime / 60 }, [isAdd, cardDetail])
    const initRemark = useMemo(() => isAdd ? '' : cardDetail.remark, [isAdd, cardDetail])
    const dateNoLine = useMemo(() => date.replace(/-/g, ''), [date])
    const [searchText, setSearchText] = useState('')
    const [selectedList, setSelectList] = useState([])
    const [formData, setFormData] = useState(initFormData)
    const [error, setError] = useState(null)
    const { doFetch } = useApi()
    const [remark, setRemark] = useState(initRemark)
    const [addTracerId, setAddTracerId] = useState(null)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [noBox, setNoBox] = useState(false)
    const [list, setList] = useState([])
    const [showContinueDialog, setShowContinueDialog] = useState(false)
    const [countTime, setCountTime] = useState(0)

    const option = useMemo(() => {
        return getTableOption({ id });
    }, [id]);

    useEffect(() => {
        let auditUseTime = _.toNumber(_.get(formData, 'auditUseTime'))
        if (auditUseTime <= 0) {
            setError(x => _.assign({}, x, { 'auditUseTime': '实际用时需大于0' }))
            return
        }
        if (!isAdd) {
            auditUseTime = auditUseTime - cardDetail.auditUseTime / 60
        }
        if (!_.isNil(auditUseTime) && ((auditUseTime * 60 + usedTime + countTime * 60) > 8 * 60)) {
            setError(x => _.assign({}, x, { 'auditUseTime': '每天用时不能大于8小时' }))
            // return
        }
    }, [formData, usedTime, cardDetail, isAdd, countTime])

    useEffect(() => {
        if (_.some([jobType, dateNoLine], _.isNil)) return
        const url = _.find(modeConfig, x => x.id === id).url
        doFetch(`${url}?jobType=${jobType}&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`, 'get').then(res => {
            setList(res)
        })
    }, [doFetch, jobType, dateNoLine, id, searchUser])

    const isSubString = useCallback((arr, subString) => {
        let hasSubString = false
        _.forEach(arr, item => {
            if (_.includes(_.toLower(item), _.toLower(subString))) hasSubString = true
        })
        return hasSubString
    }, [])

    const filterList = useMemo(() => {
        if (list.length === 0) return []
        setSelectList([])
        const primaryKey = _.find(modeConfig, x => x.id === id).primaryKey
        let uniqList = _.uniqBy(list, x => x[primaryKey])
        if (!isAdd) {
            const primaryKey = _.find(modeConfig, x => x.id === id).primaryKey
            const selectedItemPrimaryKey = _.find(list, x => x.id === cardDetail.id)[primaryKey]
            let initSelect = _.filter(uniqList, x => x[primaryKey] === selectedItemPrimaryKey)
            // cardDetail 不在 uniqList 和 list
            if (initSelect.length === 0) {
                initSelect = [cardDetail]
                uniqList.unshift(cardDetail)
            }
            const cardIndex = _.findIndex(uniqList, x => x[primaryKey] === initSelect[0][primaryKey])
            uniqList.unshift(uniqList.splice(cardIndex, 1)[0])
            if (!isSelf) uniqList = initSelect
        }
        if (_.isNull(searchText)) return uniqList
        const pickList = _.find(modeConfig, x => x.id === id).fieldList
        return _.filter(uniqList, item => isSubString(_.values(_.pick(item, pickList)), _.trim(searchText)))
    }, [list, searchText, isSubString, id, cardDetail, isAdd, isSelf])

    useEffect(() => {
        if (isAdd || list.length === 0) return
        const primaryKey = _.find(modeConfig, x => x.id === id).primaryKey
        const selectedItemPrimaryKey = _.find(list, x => x.id === cardDetail.id)[primaryKey]
        let initSelect = _.filter(filterList, x => x[primaryKey] === selectedItemPrimaryKey)
        if (initSelect.length === 0) {
            initSelect = _.filter(filterList, x => x.id === cardDetail.id)
        }
        if (!isSelf) initSelect = filterList
        setSelectList(initSelect)
    }, [filterList, cardDetail, id, isAdd, list, close, isSelf])

    return <div>
        <WaterMarkDialog cancel={close} header={getDialogHeader(true)} className='issues-dialog radio-dialog' confirm={submit}
            confirmButtonDisabled={filterList.length === 0} confirmButtonVisible={isSelf}>
            <div className="options">
                <div className="form-group">
                    <Form error={error} onError={setError} value={formData} onChange={handleChangeFormData}>
                        <HFormInput label='实际用时(h)' bind='auditUseTime' />
                    </Form>
                </div>
                <div className="options-right">
                    <Input value={searchText} onChange={setSearchText} placeholder='请搜索'
                        prefix={<Icon name='sousuo1' className='search-icon' />} />
                    <Tooltip title='可选择未在列表中的记录进行工时填报。'><Button className='add-button flex center' onClick={() => setShowAddDialog(true)}>
                        <Icon name='zengjia' className='add-icon' />
                        更多
                    </Button></Tooltip>
                    {showAddDialog && <SearchBox label={_.find(modeConfig, x => x.id === id).addText} confirm={addTraConfirm} cancel={() => setShowAddDialog(false)} onChange={setAddTracerId} value={addTracerId} />}
                    {noBox && <SearchBox confirm={() => { setShowAddDialog(true); setNoBox(false) }} nodata width={10} className='nodata' />}
                </div>
            </div>
            <div className='data-grid-wrap x-datagrid'>
                <DataGrid data={filterList} option={option} onSelectionChange={singleChoice} selection={selectedList} />
            </div>
            <TextArea value={remark} onChange={setRemark} placeholder='请输入工作内容说明...' className='remark-input' maxLength={100000} counter={false} />
            <div className='dialog-tips'>注：点击右上角【+更多】可选择未在列表中的记录进行工时填报。</div>
        </WaterMarkDialog>
        {
            showContinueDialog &&
            <Dialog headerVisible={false} confirm={clear} cancel={exit} className={'content-center-dialog'}>
                增加成功，是否继续增加？
            </Dialog>
        }
    </div>

    function handleChangeFormData(formObj, bind) {
        if (bind === 'auditUseTime' && _.toNumber(_.get(formObj, 'auditUseTime')) > 8) {
            return Messager.show('实际用时上限为8h')
        }
        setFormData(formObj)
    }

    function clear() {
        const auditUseTime = _.toNumber(formData.auditUseTime)
        setCountTime(x => x + auditUseTime)
        setShowContinueDialog(false)
        setFormData(INIT_FORM_DATA)
        setSelectList([])
        setSearchText('')
        setRemark('')
        const url = _.find(modeConfig, x => x.id === id).url
        doFetch(`${url}?jobType=${jobType}&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`, 'get').then(res => {
            setList(res)
        })
    }

    function exit() {
        setShowContinueDialog(false)
        close()
    }

    function addTraConfirm() {
        const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
        const addUrl = _.find(modeConfig, x => x.id === id).addUrl
        doFetch(`${addUrl + addTracerId}&userAccount=${userAccount}`, 'get').then(res => {
            if (res.length === 0) {
                setNoBox(true)
                return
            }
            const primaryKey = _.find(modeConfig, x => x.id === id).primaryKey
            // 判断添加的数据是否全在列表中
            if (_.every(res, x => _.find(list, item => x[primaryKey] === item[primaryKey]))) {
                return Messager.show('记录已存在！', { icon: 'error' })
            }
            setList(x => res.concat(x))
        }).catch(err => Messager.show(err.Message, { icon: 'error' }))
        setShowAddDialog(false)
    }

    function getDialogHeader(isDate) {
        if (isDate) return `${id === 8 ? detail.children[0].name : mode}（${date}）`
        return `${id === 8 ? detail.children[0].name : mode}`
    }

    function singleChoice(arr) {
        const primaryKey = _.find(modeConfig, x => x.id === id).primaryKey
        if (selectedList.length === 0) {
            setSelectList(arr)
        } else {
            const filterItem = _.find(arr, item => item[primaryKey] !== selectedList[0][primaryKey])
            setSelectList(_.isNil(filterItem) ? [] : [filterItem])
        }
    }

    function check() {
        if (_.some(_.values(error), x => x)) {
            const errMsg = _.find(_.values(error), x => !_.isNil(x))
            Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
            return false
        }
        const auditUseTime = _.toNumber(formData.auditUseTime)
        if (auditUseTime < 0 || auditUseTime > 8) {
            Messager.show('实际用时需在0-8h之间', { icon: 'error' })
            return false
        }
        if (selectedList.length === 0) {
            Messager.show(`请选择${_.find(modeConfig, x => x.id === id).addText}`, { icon: 'error' })
            return false
        }
        return true
    }

    function getSubmitParams() {
        const selectedItem = selectedList[0]
        let appendObj
        switch (id) {
            case 38:
                appendObj = {
                    content: `${selectedItem.issueId}-${selectedItem.shortDesc}`,
                    issueId: selectedItem.issueId
                }
                break
            case 6:
                appendObj = {
                    content: selectedItem.releaseId,
                    releaseId: selectedItem.releaseId
                }
                break
            case 7:
                appendObj = {
                    content: selectedItem.releaseId,
                    releaseId: selectedItem.releaseId
                }
                break
            case 19:
                appendObj = {
                    content: `${selectedItem.csRecordId}-${selectedItem.issueDesc}`,
                    csRecordId: selectedItem.csRecordId
                }
                break
            case 8:
                appendObj = {
                    content: `${selectedItem.interiorReqNo}-${selectedItem.title}`,
                    reqId: selectedItem.reqId || selectedItem.id
                }
                break
            case 39:
                appendObj = {
                    content: `${selectedItem.interiorReqNo}-${selectedItem.title}`,
                    reqId: selectedItem.reqId || selectedItem.id
                }
                break
            default:
        }
        return {
            jobType: addJobType,
            workDate: dateNoLine,
            auditUseTime: formData.auditUseTime * 60,
            remark: remark,
            status: isAdd ? 'approve' : null,
            actionType: "ADD",
            ...appendObj
        }
    }

    function submit() {
        if (beforeCutoffDay) return Messager.show(`${showCutoffDay}前的成本已核算，不允许修改工时`, { icon: 'error' })
        if (!check()) return
        const params = getSubmitParams()
        const postParams = isAdd ? [{ ...params, content: `【${getDialogHeader(false)}】${params.content}` }] : [
            _.assign({}, params, {
                id: cardDetail.id,
                actionType: "EDIT",
                content: `【${getDialogHeader(false)}】${params.content}`
            })
        ]
        doFetch('/worktime/edit', 'post', postParams).then(() => {
            Messager.show(`${isAdd ? '添加' : '修改'}成功`, { icon: 'success' })
            refresh()
            if (isAdd) {
                setShowContinueDialog(true)
                return
            }
            close()
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
        })
    }

}

export default IssuesDialog;
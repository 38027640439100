import React from 'react';
import { Card } from 'rootnet-ui';
import _ from 'lodash';
import './Left.scss';

function Left(props) {
    const { data, product, loading, error, titleClick } = props;
    return <Card className="milepost-left x" loading={loading} error={error} >
        {
            _.map(data, (item, index) => {
                return <div className={`item ${item.id === product.id ? 'active' : ''}`} 
                    key={index} 
                    title={item.projectName}
                    onClick={() => { 
                        if(item.id === product.id) return;
                        titleClick(item);
                    }}
                >
                    {item.projectName}
                </div>
            })
        }
    </Card>
}

export default Left;
import React, { useState } from 'react'
import _ from 'lodash'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import gd from '../../../../base/global'
import './index.scss'
import DailyReportCommentInfo from './dailyReportCommentInfo'
import { Icon } from '../../../../components'
import { Dialog, Loader } from 'rootnet-ui'

const { str14ToDate } = toDate;

const USER_COLOR = ['#B9CC4F', '#96BF65', '#C4A1EE', '#F5B168', '#F79C6F', '#F0C75A', '#8DC8EA', '#A1A5EE', '#79C7B7']
const saveUserColor = {}

export default function DailyReportCommentRecord(props) {
  const { data, delComment, funcCode, replyInfo, setReplyInfo, referenceId, refresh, loading, sort, setSort, defaultParams } = props
  const [currentId, setCurrentId] = useState()
  return (
    <div className='daily-report-comment-record flex flex-y'>
      {/* 头部 */}
      <div className='daily-report-comment-record-header flex'>
        <div className='header-text'>
          评论
        </div>
        <div className='header-btn'>
          <Icon
            className='sort-btn'
            name='paixu'
            onClick={() => setSort(x => !x)}
            style={_.assign({}, sort && { color: '#5477ff' })}
          />
        </div>
      </div>
      {/* 评论内容 */}
      <div className='daily-report-comment-record-content'>
        {
          loading ? <Loader fill /> :
            _.map(data, (item, i) => {
              const { user, updateUser, updateTime, actionType, message, list } = item || {}
              const time = dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(updateTime))
              const sortList = _.orderBy(list, 'updateTime', sort ? 'desc' : 'asc')
              return <div className='record-box' key={i}>
                <div className='record-icon'>{getIcon(actionType, user)}</div>
                <div className='comment-all'>
                  <div className='record-comment'>
                    <div>
                      <div>
                        {user}
                        &nbsp;&nbsp;
                        {time}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className='comments-operation'>
                          <span onClick={() => setReplyInfo(item)}>回复</span>
                          &nbsp;&nbsp;
                          {
                            (_.isEmpty(sortList) && gd.User.operator_id === updateUser) &&
                            <span onClick={() => setCurrentId(item.id)}>删除</span>
                          }
                        </span>
                      </div>
                      <div>{message}</div>
                    </div>
                  </div>
                  {_.map(sortList, (o, indx) => (
                    <div key={indx} className='level-two'>
                      <div
                        className='record-icon'>{getIcon(o.actionType, o.user, o.action)}</div>
                      <div className='record-comment'>

                        <div>
                          <div>
                            {o.user}
                            &nbsp;&nbsp;
                            回复
                            &nbsp;&nbsp;
                            {user}
                            &nbsp;&nbsp;
                            {dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(o.updateTime))}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className='comments-operation'>
                              {
                                gd.User.operator_id === o.updateUser &&
                                <span onClick={() => setCurrentId(o.id)}>删除</span>
                              }
                            </span>
                          </div>
                          <div>{o.message}</div>
                        </div>

                      </div>
                    </div>
                  ))}
                </div>
              </div>
            })
        }
      </div>
      {/* 底部 */}
      <div className='daily-report-comment-record-footer'>
        <DailyReportCommentInfo {...{ funcCode, replyInfo, setReplyInfo, referenceId, refresh, defaultParams }} />
      </div>
      {
        currentId && <DeltipLog close={() => setCurrentId(null)} {...{ delComment, currentId }} />
      }
    </div>
  )
}

function getIcon(type = '0', user = '') {
  if (type === '0' || type === 'todo') {
    if (_.isNil(saveUserColor[user])) saveUserColor[user] = USER_COLOR[_.random(0, _.size(USER_COLOR) - 1)]
    return <div className='head-portrait'
      style={{ background: saveUserColor[user] }}>{_.get(user, `[0]`)}</div>
  }
}

function DeltipLog(props) {
  const { close, currentId, delComment } = props
  return <Dialog
    header='提示'
    confirm={() => delComment(currentId, close)}
    cancel={close}
    className='del-comment-tip-log'
  >
    确定删除当前评论么？
  </Dialog>
}
import React, { useMemo, useState, useRef, useEffect, useCallback, useContext } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { Form, FormInput, Display, DatePicker } from 'rootnet-edit'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { Table, Popconfirm, InputNumber, Input, Form as AntdForm, TreeSelect } from 'antd'
import { Icon } from '../../../../../../components'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import { ManpowerAddContext } from '../../../../../common/Context'
import { uniqKeyFor } from '../../../../../../project_share/utils/utils'
import DepartmentSelect from '../../../../components/departmentSelect'
import { dateFormat } from 'rootnet-core/dateFormat'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../utils/publicFun'
import './index.scss'

const ADD_URL = '/humanManpower/add'
const DEPARTMENT_MANPOWER_URL = '/humanManpower/getDepartmentNum'

function getColumns(props) {
  const { editable = 'false', delDataItem, projectOption, itemClassOpt } = props
  const allOption = _.concat(..._.map(projectOption, o => o.children))
  const cols = [
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>项目/事务</span>,
      dataIndex: 'projectId',
      key: 'projectId',
      editable,
      width: 120,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }
        return _.get(_.find(allOption, o => o.value === value), 'title') || value
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>预估类型</span>,
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: value => {
        return _.get(_.find(itemClassOpt, o => o.value === value), 'text') || value
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>占用工作量评估</span>,
      dataIndex: 'workloadOccupied',
      key: 'workloadOccupied',
      editable,
      width: 90,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }
        return `${value}人月`
      }
    }
  ]
  const operation = [
    {
      title: '操作',
      width: 30,
      align: 'center',
      fixed: 'right',
      render: (v, o) => {
        return <>
          <Popconfirm
            title="确认删除？"
            onConfirm={() => delDataItem(o.id)}
            okText="删除"
            cancelText="取消"
            overlayInnerStyle={{ padding: 8, width: 200 }}
          >
            <Icon className={'manpower-del-icon'} name='shanchu' style={{ fontSize: 16 }} />
          </Popconfirm>
        </>
      }
    }
  ]
  return _.concat(cols, operation)
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default function ManpowerAddLog(props) {
  const { close, projectOption, itemClassOpt, refresh, initParams = {}, dataSource, setMode } = props
  const [data, setData] = useState([{ id: uniqKeyFor() }])
  const [params, setParams] = useState(initParams)
  const [error, setError] = useState()
  const [continuous, setContinuous] = useState(false)
  const [form] = AntdForm.useForm()
  const { doFetch } = usePost()
  const { doFetch: getDepartmentManpower } = useGet()
  const { departmentId } = useMemo(() => params || {}, [params])

  useEffect(() => {
    if (_.isEmpty(data)) return
    setParams(x => _.assign({}, x, {
      evaluationSummary: (_.sum(_.map(data, o => _.isNil(o.workloadOccupied) ? 0 : Number(o.workloadOccupied) * 100)) / 100)
    }))
  }, [data])

  useEffect(() => {
    if (isNil(departmentId)) return
    getDepartmentManpower(`${DEPARTMENT_MANPOWER_URL}?${strParams({ departmentId })}`)
      .then((res) => {
        setParams(x => _.assign({}, x, { departmentManpower: res }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getDepartmentManpower, departmentId])

  const delDataItem = useCallback((id) => {
    setData(x => _.filter(x, o => o.id !== id))
  }, [])

  const EditableRow = useCallback(({ index, ...restProps }) => {
    const currentIndex = data.findIndex(x => x.id === restProps['data-row-key'])
    return <AntdForm form={form} component={false}>
      <ManpowerAddContext.Provider value={{ form }}>
        <SortableItem index={currentIndex} {...restProps} />
      </ManpowerAddContext.Provider>
    </AntdForm>
  }, [data, form])

  const handleSave = useCallback((row, bind) => {
    const index = _.findIndex(data, x => x.id === row.id)
    setData(oldList => {
      const newList = _.map(oldList, (item, i) => {
        if (i !== index) return item
        return ({
          ...item,
          ...row
        })
      })
      return _.sortBy(newList, 'serial')
    })
  }, [data, setData])

  const columns = useMemo(() => {
    return _.map(getColumns({ delDataItem, projectOption, itemClassOpt }), col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          showList: data,
          projectOption,
        })
      }
    })
  }, [data, handleSave, delDataItem, projectOption, itemClassOpt])

  const canSubmit = useMemo(() => {
    const isError = !_.some(_.values(error), x => x)
    const requiredList = _.map(data, o => (!isNil(o.projectId) && !isNil(o.workloadOccupied)))
    const isRequiredList = _.every(requiredList)
    return isError && isRequiredList
  }, [error, data])

  const confirm = useCallback(() => {
    if (continuous) return
    setContinuous(true)
    const { evaluationDate } = params
    const monthDate = dateFormat('YYYYMMDDHHMMSS', evaluationDate)
    const postParams = _.assign({}, params, {
      evaluationDate: monthDate,
      humanProjectEstimates: _.map(data, o => {
        return _.omit(o, ['id'])
      })
    })
    doFetch(ADD_URL, postParams)
      .then(() => {
        refresh()
        setContinuous(false)
        close()
        Messager.show('新增成功', { icon: 'success' })
      })
      .catch(err => {
        setContinuous(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [params, data, doFetch, refresh, close, continuous])

  const handChange = useCallback((o, key) => {
    if (key === 'evaluationDate' || key === 'departmentId') {
      if (!_.isNil(o['evaluationDate']) && !_.isNil(o['departmentId'])) {
        const upDateDate = _.split(dateFormat('YYYY-MM-DD', o['evaluationDate']), '-')
        const upDateNum = `${upDateDate[0]}-${upDateDate[1]}`
        const findItem = _.find(dataSource, item => (item['departmentId'] === o['departmentId'] && !_.isNil(_.get(item, `manpowerPipelineId${upDateNum}`))))
        if (!_.isNil(findItem)) setMode({ type: 'edit', id: _.get(findItem, `manpowerPipelineId${upDateNum}`) })
      }
    }
    if (key === 'evaluationDate') {
      const currentDate = _.split(dateFormat('YYYY-MM-DD', new Date()), '-')
      const upDateDate = _.split(dateFormat('YYYY-MM-DD', o[key]), '-')
      const currentNum = `${currentDate[0]}${currentDate[1]}`
      const upDateNum = `${upDateDate[0]}${upDateDate[1]}`
      if (upDateNum < currentNum) {
        o[key] = null
        Messager.show('不能小于当前月份', { icon: 'error' })
      }
    }
    setParams(o)
  }, [dataSource, setMode])

  return (
    <Dialog
      header='新增'
      confirm={confirm}
      cancel={close}
      className='manpower-add-log'
      confirmButtonDisabled={!canSubmit}
    >
      <div className='search-option'>
        <Form value={params} onChange={handChange} error={error} onError={setError}>
          <FormInput required horizontal label='月份' bind='evaluationDate' component={DatePicker} mode='month' clear />
          <FormInput required horizontal label='部门' bind='departmentId' component={DepartmentSelect} componentStyle={{ height: 32, overflowY: 'auto' }} mode='add' />
          <FormInput horizontal label='部门人力' bind='departmentManpower' component={Display} componentWidth={180} convert={v => {
            return isNil(v) ? v : `${v}人`
          }} />
          <FormInput horizontal label='占用评估汇总' bind='evaluationSummary' component={Display} convert={v => {
            return isNil(v) ? v : `${v}人月`
          }} />
        </Form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TextIconBtn
          style={{ color: '#5477ff' }}
          text='添加评估'
          icon='tianjia'
          onClick={() => setData(x => _.concat([], x, [{ id: uniqKeyFor() }]))}
        />
      </div>
      <Table
        size={'small'}
        rowClassName={'editable-row'}
        columns={columns}
        pagination={false}
        dataSource={data}
        rowKey="id"
        sticky={true}
        scroll={{ y: 220 }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell
          }
        }}
      />
    </Dialog>
  )

  function DraggableContainer(props) {
    return <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      {...props}
    />
  }

}

function EditableCell(props) {
  const { title, editable, children, dataIndex, record, handleSave, must, showList, projectOption, ...restProps } = props
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const { form } = useContext(ManpowerAddContext || {})

  useEffect(() => {
    if (editing) {
      if (!_.isNil(inputRef.current)) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  const toggleEdit = useCallback(() => {
    setEditing(x => !x);
    let value
    value = record[dataIndex]
    form.setFieldsValue({ [dataIndex]: value });
  }, [dataIndex, record, form])

  const save = () => {
    try {
      form.validateFields().then(changeObj => {
        const key = _.head(_.keys(changeObj))
        let newObj = changeObj
        toggleEdit();
        if (key === 'projectId') {
          const allOption = _.concat(..._.map(projectOption, o => o.children))
          newObj = _.assign({}, changeObj, {
            type: _.get(_.find(allOption, o => o.value === changeObj[key]), 'pid'),
            projectType: _.get(_.find(allOption, o => o.value === changeObj[key]), 'status')
          })
        }
        if (changeObj[key] !== record[key]) {
          handleSave({ ...record, ...newObj }, key);
        }
        if (!_.isNil(inputRef.current)) {
          inputRef.current.blur()
        }
        setEditing(false)
      })
    } catch (errInfo) {
      console.error(errInfo);
    }
  }

  const treeData = _.orderBy(projectOption, 'value', 'desc')

  let childNode = children;
  if (editable) {
    if (editing) {
      let editNode
      if (dataIndex === 'projectId') {
        editNode = (<TreeSelect autoFocus ref={inputRef} onSelect={save} allowClear={false} onBlur={() => setEditing(false)} treeData={treeData} showSearch treeNodeFilterProp='title' />)
      } else if (dataIndex === 'workloadOccupied') {
        editNode = (<InputNumber addonAfter={'人月'} ref={inputRef} min={0} onPressEnter={save} onBlur={save} />)
      } else if (!isNil(dataIndex)) {
        editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
      } else {
        editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
      }

      childNode = (
        <AntdForm.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: must,
              message: `${title}不能为空`,
            },
          ]}
        >
          {editNode}
        </AntdForm.Item>
      )
    } else {
      childNode = (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>;
}

import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import _ from "lodash";
import { DataGrid, Pagination, Messager } from "rootnet-ui";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import {Box} from "../common/commonComponent";
import ViewArea from "../common/view/ViewArea";
import {pathSearchFor} from "../../utils/publicFun";
import './CustomerServiceInfo.scss'
import CustomerServiceInfoAddDialog from "./customerServiceInfoAddDialog/CustomerServiceInfoAddDialog";
import {TextIconBtn} from "../common/TextIconBtn";
import CustomerServiceInfoDetailDialog from "./customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../common/ConvertGlobalConstOptions";
import convertOptions from "../common/ConvertOptions";
import {Popover} from "antd";
import WorkFlowChangePop from "../workFlow/workFlowChangePop/WorkFlowChangePop";
import {API1} from "../../base/task";
import ConcernedIcon from '../common/concernedIcon';

const ENTER_DETAIL_FIELD = 'CustomerServiceInfo.IssueDesc'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentInfo, statusOptions, workFlowId, setWorkFlowId, refreshList } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== ENTER_DETAIL_FIELD,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`

        if(tableField === ENTER_DETAIL_FIELD){
            return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
                <div className="enter-detail-field flex">
                    <div className="enter-detail-field-text" onClick={()=> {
                        setCurrentInfo({
                            id: _.get(o,'id'),
                            mode: 'detail'
                        })
                    }}>
                        {showValue || '-'}
                    </div>
                    <ConcernedIcon
                        left={8}
                        type='CUSTOMERSERVICE'
                        referenceId={o?.id}
                        flag={_.get(o, 'perColl', 'N') === 'Y'}
                    />
                </div>
            </div>
        }

        if(tableField === 'workflow_business.factorId'){
            const convertRule = _.find(convertCollection, (v,k) => k === tableField)
            const color = convertOptions(v, convertRule, 'color')
            return <Popover destroyTooltipOnHide={true}
                            content={<WorkFlowChangePop businessId={workFlowId} close={()=>setWorkFlowId(null)}
                                                        refreshList={refreshList} funcCode={FUNC_CODE} businessType={'customer'}/>}
                            onOpenChange={visible => {
                                if(!visible){
                                    setWorkFlowId(null)
                                }
                            }}
                            trigger={'click'}
                            placement="bottom"
                            open={o.id === workFlowId}
            >
                <div className={'common-display-field work-flow-status-field'} onClick={()=>setWorkFlowId(o.id)}
                     style={{color: color, border: `1px solid ${color}`,padding: '0 8px', borderRadius: 4}}>
                    {showValue || '-'}
                </div>
            </Popover>
        }

        if(tableField === 'CustomerServiceInfo.Status'){
            return <div className={'status-field flex center'} style={{ border: `1px solid ${convertOptions(v, statusOptions, 'color')}`, padding: '0 8px', color: convertOptions(v, statusOptions, 'color'), borderRadius: 4 }}>
                {showValue}
            </div>
        }

        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '20'

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=CSRecordStatus',
]

function CustomerServiceInfo(props) {
    const { location } = props;
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [focusId, setFocusId] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [sortConfig, setSortConfig] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [workFlowId, setWorkFlowId] = useState()
    const [factor, setFactor] = useState()

    const [statusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
    // const [currentInfo, setCurrentInfo] = useState({mode: 'add'})

    const appendObj = useMemo(() => {
        return {collectionType: 'CUSTOMERSERVICE'}
    },[])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions,
        getOptions, getColumns, columnsAppendParams: {setCurrentInfo, statusOptions, workFlowId, setWorkFlowId, refreshList},
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                const sortList = _.sortBy(data, x => x[sort.column])
                return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
            }
        },
    })

    const exportViewList = useCallback(()=>{
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        Messager.show('导出中...')
        API1.downloadPost('/UserSetting/exportExcel',postParams)
    },[currentViewId, fieldList, factor])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList}/>
            <TextIconBtn icon={'tianjia'} text={'新增'} onClick={()=>setCurrentInfo({mode: 'add'})}/>
        </div>
    }, [exportViewList])

    const showList = useMemo(() => {
        let showList = dataGridList || []
        if (!_.isNil(sortConfig)) {
            showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, dataGridList])

    const currentIndex = useMemo(() => {
        const currentId = _.get(currentInfo, 'id')
        if (_.isNil(currentId)) return 0
        return _.findIndex(showList, x => _.get(x, 'id') === currentId)
    }, [currentInfo, showList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentInfo(old => {
            const cloneObj = { ...old }
            const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
            cloneObj['id'] = newId
            setFocusId(newId)
            return cloneObj
        })
    }, [showList, currentIndex])

    const onRowClick = useCallback((item) => {
        _.forEach(dataGridList, o => {
            return o._rowClass = item?.id === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    }, [dataGridList])

    useEffect(() => {
        if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    return (
        <div className={'customer-service-info-mgt fill flex-y'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams}
                      {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                          currentViewId, setCurrentViewId, loading, params, pageSize}}/>
            <Box title={'客户服务信息'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                <DataGrid option={options} data={dataGridList} onRowClick={onRowClick}/>
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                            onChange={(pageNum, pageSize) => {
                                setPageSize(pageSize)
                                setParams(x => _.assign({}, x, {pageNum, pageSize}))
                            }} />
            </Box>
            {
                _.get(currentInfo,'mode') === 'add' &&
                <CustomerServiceInfoAddDialog close={()=>setCurrentInfo(null)} refreshViewList={refreshList} {...{setFocusId}}/>
            }
            {
                !_.isNil(_.get(currentInfo, 'id')) &&
                <CustomerServiceInfoDetailDialog close={()=>setCurrentInfo(null)} refreshViewList={refreshList} showChildList={showList}
                                                 {...{currentInfo, switchCurrentItem, currentIndex}}/>
            }
        </div>
    )
}

export default CustomerServiceInfo;
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { MessageBox, Messager } from 'rootnet-ui'
import { Form, FormInput, Select, CheckBox, RadioGroup } from 'rootnet-edit'
import TextAreaInput from '../../../../../../common/textAreaInput/TextAreaInput'
import { useGet } from 'rootnet-biz/lib/hooks'
import './index.scss'
import { strParams } from '../../../../../../../utils/publicFun'
import { selectOption } from '../../../../../../common/commonMethod'

const VERSION_URL = '/releaseinfo/getReleasesByProject'

const yseOrNoOpt = [
  {
    text: '是',
    value: 'Y'
  },
  {
    text: '否',
    value: 'N'
  },
]

export default function CopyMessage(props) {
  const { dataManipulation, close, currentId, changeData, actionType, selectedRows, isDisabled, projectId, isDisplay = true } = props
  const [params, setParams] = useState(isDisplay ? { isDel: false, regression: 'N' } : {})
  const { data: versionRes, doFetch } = useGet()

  useEffect(() => {
    doFetch(`${VERSION_URL}?${strParams({ projectId, statusList: 'T,C' })}`)
  }, [doFetch, projectId])

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionRes)) return []
    return selectOption(_.filter(versionRes, o => o.releaseid !== currentId), ['releasename', 'releaseid'])
  }, [versionRes, currentId])

  const handleConfirm = useCallback(() => {
    if (isNil(params?.remark) && isDisplay) return Messager.show('请填写说明信息', { icon: 'error' })
    const paramsArr = []
    if (actionType === 'copy') {
      if (params?.isDel) paramsArr.push(_.assign({}, changeData, _.omit(params, ['isDel', 'regression']), { optionType: 'DEL', releaseid: currentId }))
      paramsArr.push(_.assign({}, changeData, _.omit(params, ['isDel']), changeData?.type === 'DEV' && { type: 'REQ' }))

      dataManipulation(paramsArr)
    } else {
      const reqList = _.filter(selectedRows, o => ((o.type === 'REQ' && !_.isNil(o.inRelease)) || (!isDisplay && o.type === 'REQ')))
      const issueList = _.filter(selectedRows, o => o.type === 'ISSUE')
      const defectList = _.filter(selectedRows, o => o.type === 'DEFECT')
      const devList = _.filter(selectedRows, o => o.type === 'DEV')
      if (params?.isDel) {
        if (!_.isEmpty(reqList)) paramsArr.push(_.assign({ type: 'REQ', optionType: 'DEL', releaseid: currentId, storyidList: _.map(reqList, o => o.id), remark: params?.remark }))
        if (!_.isEmpty(issueList)) paramsArr.push(_.assign({ type: 'ISSUE', optionType: 'DEL', releaseid: currentId, storyidList: _.map(issueList, o => o.id), remark: params?.remark }))
        if (!_.isEmpty(defectList)) paramsArr.push(_.assign({ type: 'DEFECT', optionType: 'DEL', releaseid: currentId, storyidList: _.map(defectList, o => o.id), remark: params?.remark }))
        if (!_.isEmpty(devList)) paramsArr.push(_.assign({ type: 'REQ', optionType: 'DEL', releaseid: currentId, storyidList: _.map(devList, o => o.id), remark: params?.remark }))
      }
      if (!_.isEmpty(reqList)) paramsArr.push(_.assign({ type: 'REQ', optionType: 'ADD', storyidList: _.map(reqList, o => o.id) }, _.omit(params, ['isDel'])))
      if (!_.isEmpty(issueList)) paramsArr.push(_.assign({ type: 'ISSUE', optionType: 'ADD', storyidList: _.map(issueList, o => o.id) }, _.omit(params, ['isDel'])))
      if (!_.isEmpty(defectList)) paramsArr.push(_.assign({ type: 'DEFECT', optionType: 'ADD', storyidList: _.map(defectList, o => o.id) }, _.omit(params, ['isDel'])))
      if (!_.isEmpty(devList)) paramsArr.push(_.assign({ type: 'REQ', optionType: 'ADD', storyidList: _.map(devList, o => o.id) }, _.omit(params, ['isDel'])))
      dataManipulation(paramsArr)
    }
    close()
  }, [actionType, changeData, close, currentId, dataManipulation, params, selectedRows, isDisplay])

  return (
    <MessageBox
      header={<div>
        <span>复制</span>
        <span style={{ color: '#BBBBBB', fontWeight: 'normal', paddingLeft: 8 }}>所选版本须为当前版本同所属项目下的版本，批量操作复制和回归！</span>
      </div>}
      confirm={handleConfirm}
      cancel={close}
      className='version-req-copy-log'
    >
      <Form value={params} onChange={setParams}>
        <FormInput search horizontal label='版本ID' bind='releaseid' component={Select} options={versionOpt} componentWidth={300} />
        {
          isDisplay &&
          <>
            <div style={{ paddingLeft: 52 }}>
              <CheckBox
                disabled={isDisabled}
                value={_.get(params, 'isDel', false)}
                onChange={v => setParams(x => _.assign({}, x, { isDel: v }))}>
                从当前版本中移除
              </CheckBox>
              <FormInput required horizontal label='回归' bind='regression' component={RadioGroup} options={yseOrNoOpt} />
            </div>
            <FormInput className='text-area-input-edit' horizontal required component={TextAreaInput} bind='remark' label='说明' componentWidth={300} />
          </>
        }
      </Form>
    </MessageBox>
  )
}

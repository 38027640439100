import React, {useMemo} from 'react';
import './ResourceSummaryInfo.scss'
import {Card, DataGrid, Button} from 'rootnet-ui'
import _ from "lodash";
import Icon from "../../../../project_share/components/Icon";
import {Tooltip} from "antd";

function getTechLevelColumns(props){
    const {notProjectSum} = props
    return [
        { header: '岗位(人日)', bind:'position', width: 90 },
        {
            header: 'T2',
            align: 'center',
            children: _.concat(
                notProjectSum ? [{header:'现有', bind:'T2-allPlan', width: 60, align:'center'}]:[]
                ,[{header:'已计划', bind:'T2-hadPlan', width: 60, align:'center'}]
            )
        },
        {
            header: 'T3',
            align: 'center',
            children: _.concat(
                notProjectSum ? [{header:'现有', bind:'T3-allPlan', width: 60, align:'center'}]:[]
                ,[{header:'已计划', bind:'T3-hadPlan', width: 60, align:'center'}]
            )
        },
        {
            header: 'T4',
            align: 'center',
            children: _.concat(
                notProjectSum ? [{header:'现有', bind:'T4-allPlan', width: 60, align:'center'}]:[]
                ,[{header:'已计划', bind:'T4-hadPlan', width: 60, align:'center'}]
            )
        },
        {
            header: 'T5',
            align: 'center',
            children: _.concat(
                notProjectSum ? [{header:'现有', bind:'T5-allPlan', width: 60, align:'center'}]:[]
                ,[{header:'已计划', bind:'T5-hadPlan', width: 60, align:'center'}]
            )
        },
        {
            header: 'T6',
            align: 'center',
            children: _.concat(
                notProjectSum ? [{header:'现有', bind:'T6-allPlan', width: 60, align:'center'}]:[]
                ,[{header:'已计划', bind:'T6-hadPlan', width: 60, align:'center'}]
            )
        },
        {
            header: '其他',
            align: 'center',
            children: _.concat(
                notProjectSum ? [{header:'现有', bind:'other-allPlan', width: 60, align:'center'}]:[]
                ,[{header:'已计划', bind:'other-hadPlan', width: 60, align:'center'}]
            )
        },
    ].concat(notProjectSum?
        [ { header: '饱和度', bind:'saturation', width: 50, align: 'center', convert: v => {
                let backgroundColor
                if(v > 1){
                    backgroundColor = '#FF0000'
                }else if(v <= 0.6){
                    backgroundColor = '#ED7D31'
                }else{
                    backgroundColor = '#70AD47'
                }
                return _.isNil(v)?null:<div className={'saturation-item flex center'} style={{backgroundColor: backgroundColor}}>
                    {_.round(v * 100,1) + "%"}
                </div>
            } }]:
        [{
            header: '合计',
            align: 'center',
            convert: (v,o)=>{
                const columnBindList = ['T2-hadPlan','T3-hadPlan','T4-hadPlan', 'T5-hadPlan','T6-hadPlan','other-hadPlan']
                const numList = _.pick(o,columnBindList)
                const sumPosition = _.round(_.reduce(numList,(acc, x)=>_.toNumber(acc) + (_.toNumber(x) || 0),0),2)
                return sumPosition
            }
        }])
}

function getTechLevelOption(cols,notProjectSum){
    const columnBindList = notProjectSum ?['T2-allPlan','T2-hadPlan','T3-allPlan','T3-hadPlan','T4-allPlan','T4-hadPlan','T5-allPlan',
        'T5-hadPlan','T6-allPlan','T6-hadPlan','other-allPlan','other-hadPlan',]:
        ['T2-hadPlan','T3-hadPlan','T4-hadPlan', 'T5-hadPlan','T6-hadPlan','other-hadPlan']
    const bindSumList = _.map(columnBindList, x => sumCol(x))
    return {
        autoFill: true,
        resizable: false,
        fixedLeft: 1,
        fixedRight: 1,
        virtualized: true,
        columns: cols,
        summary: [
            _.concat([{ header: '汇总', align: "center" }],bindSumList,[{ header: '', align: "center" }])
        ],
    }
}

function sumCol(colName){
    return { header: data => _.round(_.reduce(_.map(data,colName),(acc, x)=>_.toNumber(acc) + (_.toNumber(x) || 0),0),2), align: "center" }
}

function getModuleColumns(props){
    const {summaryModuleHeaderList,notProjectSum} = props

    const moduleHeader = _.map(summaryModuleHeaderList, headerItem => ({
        header: <Tooltip title={headerItem.moduleName}>
            <div style={{width: 120}} className={'module-header'}>
                {headerItem.moduleName}
            </div>
        </Tooltip>,
        align: 'center',
        children: _.concat(
            notProjectSum ? [{header:'现有', bind:`${headerItem.module}-allPlan`, width: 70, align:'center'}]:[]
            ,[{header:'已计划', bind:`${headerItem.module}-hadPlan`, width: notProjectSum?70:130, align:'center'}]
        )
    }))

    const sumCol = [{ header: '饱和度', bind:'saturation', width: 50, align: 'center', convert: v => {
            let backgroundColor
            if(v > 1){
                backgroundColor = '#FF0000'
            }else if(v <= 0.6){
                backgroundColor = '#ED7D31'
            }else{
                backgroundColor = '#70AD47'
            }
            return _.isNil(v)?null:<div className={'saturation-item flex center'} style={{backgroundColor: backgroundColor}}>
                {_.round(v * 100,1) + "%"}
            </div>
        }}]

    return _.concat([{ header: '岗位(人日)', bind:'position', width: 90 }], moduleHeader,notProjectSum?sumCol:[])
}

function getModuleOption(cols, headerList, notProjectSum){
    let summary = [{ header: '汇总', align: "center" }]
    _.forEach(headerList, headerItem => {
        if(notProjectSum){
            summary.push(sumCol(`${headerItem.module}-allPlan`))
        }
        summary.push(sumCol(`${headerItem.module}-hadPlan`))
    })
    summary.push({ header: '', align: "center" })
    return {
        autoFill: true,
        resizable: false,
        fixedLeft: 1,
        fixedRight: 1,
        virtualized: true,
        columns: cols,
        summary:[summary]
    }
}

function ResourceSummaryInfo(props) {
    const {summaryInfoExpanded,setSummaryInfoExpanded,summaryLevelRes,summaryModuleRes,positionOptions,selectedProjectId,
        summaryLevelLoading,summaryLevelError,summaryModuleLoading,summaryModuleError,selectedDeptId} = props

    const summaryLevelList = useMemo(()=>{
        if(_.isEmpty(summaryLevelRes)) return []
        let showSummaryLevelList = []
        _.forEach(summaryLevelRes, positionItem => {
            const listItem = {
                position: positionItem.positionName,
                saturation: positionItem.saturation
            }
            _.forEach(positionItem.levelDistribute, levelItem => {
                listItem[`${levelItem.techLevel}-allPlan`] = levelItem.allPlan
                listItem[`${levelItem.techLevel}-hadPlan`] = levelItem.hadPlan
            })
            showSummaryLevelList.push(listItem)
        })
        return _.sortBy(showSummaryLevelList,[sortPosition])
        function sortPosition(o){
            const positionList = _.map(positionOptions,'text')
            const findIndex = _.findIndex(positionList,x => x === _.get(o,'position'))
            return findIndex === -1 ? 9999 : findIndex
        }
    },[summaryLevelRes, positionOptions])
    
    const notProjectSum = useMemo(()=>{
       return _.isNil(selectedProjectId)
    },[selectedProjectId])

    const [summaryModuleList, summaryModuleHeaderList] = useMemo(()=>{
        if(_.isEmpty(summaryModuleRes)) return []
        let showSummaryModuleList = []
        let summaryModuleHeaderValueList = []
        let summaryModuleHeaderList = []
        _.forEach(summaryModuleRes, positionItem => {
            const listItem = {
                position: positionItem.positionName,
                saturation: positionItem.saturation
            }
            _.forEach(positionItem.levelDistribute, moduleItem => {
                listItem[`${moduleItem.module}-allPlan`] = moduleItem.allPlan
                listItem[`${moduleItem.module}-hadPlan`] = moduleItem.hadPlan
                if(!_.includes(summaryModuleHeaderValueList, moduleItem.module)){
                    summaryModuleHeaderValueList.push(moduleItem.module)
                    summaryModuleHeaderList.push({
                        module: moduleItem.module,
                        moduleName: moduleItem.moduleName,
                    })
                }
            })
            showSummaryModuleList.push(listItem)
        })
        showSummaryModuleList = _.sortBy(showSummaryModuleList,[sortPosition])
        return [
            showSummaryModuleList,
            summaryModuleHeaderList
        ]
        function sortPosition(o){
            const positionList = _.map(positionOptions,'text')
            const findIndex = _.findIndex(positionList,x => x === _.get(o,'position'))
            return findIndex === -1 ? 9999 : findIndex
        }
    },[summaryModuleRes, positionOptions])

    const extra = useMemo(()=>{
        return <div className={'extra-wrap flex center-y'}>
            <div className={'expand-text flex center-y'} onClick={()=>setSummaryInfoExpanded(x => !x)}>
                <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{transform: summaryInfoExpanded ?  'rotate(-90deg)' : 'rotate(90deg)'}}/>
                {summaryInfoExpanded? "收起":'展开'}
            </div>
            <Button primary className={'extra-import-btn'} onClick={()=> {
                window.open("/#/StoryScheduling?initDept="+selectedDeptId, "_blank")
            }}>排期预测</Button>
        </div>
    },[summaryInfoExpanded, setSummaryInfoExpanded,selectedDeptId])

    const techLevelOption = useMemo(()=>{
        return getTechLevelOption(getTechLevelColumns({notProjectSum}),notProjectSum)
    },[notProjectSum])

    const moduleOption = useMemo(()=>{
        return getModuleOption(getModuleColumns({summaryModuleHeaderList,notProjectSum}),summaryModuleHeaderList,notProjectSum)
    },[summaryModuleHeaderList,notProjectSum])

    return <div className={'resource-summary-info'}>
        <Card title={'汇总信息'} className={'outer-card'} contentClass={'outer-card-content flex'} extra={extra}>
            <div className={'table-wrap flex'}>
                <div className={'position-table'}>
                    <DataGrid data={summaryLevelList} option={techLevelOption} loading={summaryLevelLoading} error={summaryLevelError}/>
                </div>
                <div className={'module-table'}>
                    <DataGrid data={summaryModuleList} option={moduleOption} loading={summaryModuleLoading} error={summaryModuleError}/>
                </div>
            </div>
        </Card>
    </div>
}

export default ResourceSummaryInfo;
import React, { useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { Menu } from 'antd'
import './topMenu.scss'
import useGet from "rootnet-biz/es/hooks/useGet";
import gd from "./base/global";

export default function TopMenu(props) {
  const { menuData, history, currentMenu, setCurrentMenu } = props
  const {data: webSizeUrl} = useGet('/getAppVersion/list')
  const {data: biMenu} = useGet('/common/uacFuncctrl?moduleId=BI')

  const items = useMemo(() => {
    const filterData = _.filter(menuData, o => o.funcType === 0)
    const orderData = _.orderBy(filterData, 'mainOrder', 'asc')
    const groupData = _.groupBy(orderData, 'groupName')
    const menuList = _.map(groupData, (_v, k) => (_.assign({
      label: k,
      key: k,
    })))
    if(_.isEmpty(biMenu)){
      return menuList
    }else{
      return _.concat(menuList, {label: '报表中心', key: 'bi'})
    }
  }, [menuData, biMenu])

  const toBI = useCallback(()=>{
    if(_.isNil(webSizeUrl)) return
    const biUrl = _.get(webSizeUrl,'biUrl')
    window.open(`http://${biUrl}/#/login?ssoToken=${gd.User.uuid}`,'_blank')
  },[webSizeUrl])

  const onMenuClick = useCallback((e) => {
    if(e.key === 'bi'){
      toBI()
    }else{
      setCurrentMenu(e.key)
    }
  }, [setCurrentMenu, toBI])

  useEffect(() => {
    if (_.isEmpty(menuData)) return
    const findData = _.find(menuData, o => o.url === history.location.pathname)
    setCurrentMenu(_.get(findData, 'groupName', '首页'))
  }, [menuData, history, setCurrentMenu])

  return (
    <div className='top-menu-list'>
      <Menu selectedKeys={[currentMenu]} onClick={onMenuClick} mode='horizontal' items={items} />
    </div>
  )
}

import React, { useCallback, useState } from 'react'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/es/hooks'
import { dateFormat } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, TextArea } from 'rootnet-edit'
import './index.scss'
import gd from '../../../../../base/global'

const EDIT_URL = '/issue/detailMultiple'
const HFormInput = (props) => <FormInput horizontal labelWidth={70} componentWidth={300} {...props} />

export default function ReviewDialog(props) {
  const { close, statusOpt, refresh, id } = props
  const [params, setParams] = useState({ rootReviewStatus: 'Y' })
  const { doFetch } = usePost()
  const confirm = useCallback(() => {
    const postParams = {
      ...params,
      id,
      rootReviewUser: gd.User.operator_id,
      rootReviewTime: dateFormat('YYYYMMDDHHMMSS', new Date())
    }
    doFetch(EDIT_URL, postParams)
      .then(() => {
        close()
        refresh()
        Messager.show('评审成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, params, id, close, refresh])

  return (
    <Dialog className='review-dialog' header='根因评审' cancel={close} confirm={confirm}>
      <Form value={params} onChange={setParams}>
        <HFormInput required label='评审状态' bind='rootReviewStatus' component={Select} options={statusOpt} />
        <HFormInput label='备注' bind='rootReviewMemo' component={TextArea} />
      </Form>
    </Dialog>
  )
}

import React, { useMemo, useContext } from 'react'
import _ from 'lodash'
import { Dialog, DataGrid } from 'rootnet-ui'
import { Box } from '../../../../../common/commonComponent'
import { ScheduleManagementContext } from '../../../../../common/Context'
import { workloadColumns, workloadGridOptsFor } from '../../getTableOption'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import './index.scss'

const defaultInsertion = {
  header: "导入失败原因",
  bind: "failureReason",
  convert: (v, o) => <span style={{ color: 'red' }}> {v}</span>,
  sortable: true,
  tooltip: true,
  width: 140,
}

export default function ImportResults(props) {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const { data, showExport, closeAll } = props

  const defaultOption = _.cloneDeep(workloadColumns({ isImport: true }))

  const option = useMemo(() => {
    return _.assign(workloadGridOptsFor(), { fixedLeft: 0, columns: _.filter(defaultOption, o => !_.includes(['操作', '#', '', undefined], o.header)) })
  }, [defaultOption])

  const errorOption = useMemo(() => {
    const { columns } = option
    const column = _.cloneDeep(columns)
    _.forEach(column, column => {
      if (_.has(column, 'convert') && !_.includes(['model', 'total'], column.bind)) return
      column.convert = (v, o) => replaceField(v, o, column)
    })
    column.unshift(defaultInsertion)
    return _.assign({}, option, { columns: column })
    function replaceField(v, o, column) {
      const bind = _.get(column, 'bind')
      if (o[`${bind}Desc`] === '0') return <span style={{ color: 'red' }}>此字段为必填项</span>
      if (!_.isNull(o[`${bind}Desc`])) return <span style={{ color: 'red' }}> {v}</span>
      return v
    }
  }, [option])

  return (
    <Dialog
      cancel={closeAll}
      header={'执行结果'}
      className='import-results'
      cancelButtonText='关闭'
      confirmButtonVisible={false}
    >
      <Box title={`符合要求（这些数据已提交）共计：${_.size(_.get(data, '[0]'))}条`} data={_.get(data, '[0]')}>
        <DataGrid option={option} data={_.get(data, '[0]')} />
      </Box>
      <Box
        title={`不符合要求（这些数据不会被导入）共计：${_.size(_.get(data, '[1]'))}条`}
        data={_.get(data, '[1]')}
        extra={showExport && <TextIconBtn text='导出' icon={'daochu'} onClick={_.debounce(download, 300)} />}
      >
        <DataGrid option={errorOption} data={_.get(data, '[1]')} />
      </Box>
    </Dialog>
  )

  function download() {
    // let abnormalData = _.get(data, '[1]')
    // if (isObj) abnormalData = _.assign({}, params, { [exportFieldname]: _.get(data, '[1]') })
    //abnormal, abnormalData, `${title}-异常数据`, new Date()
    downloadPostProgress()
  }
}

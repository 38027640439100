import React, { useMemo } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { BaseTable } from 'ali-react-table'
import { Icon } from '../../../../../components'
import './index.scss'

const nameTip = {
  existingCapacity: ['现有产能', '根据折算后的人员数量标准化为标准T3投入人日'],
  // capacityExistingCapacity: ['现有产能+招聘产能', '折算标准工作量'],
  forecastWorkload: ['预测工作量', '自定义导入的月度预测工作量'],
  differenceWorkload: ['差额', '预测工作量-现有产能'],
}

function getName(bind) {
  return <div>
    <span>{nameTip[bind][0]}</span>
    <Tooltip title={nameTip[bind][1]}>
      <span><Icon name='jingshishuoming' /></span>
    </Tooltip>
  </div>
}

function getColumns(props) {
  const { switchChecked } = props
  const filterMonth = switchChecked ? 0 : new Date().getMonth()
  const columnHead = [
    {
      name: '模块',
      code: 'modelName',
      width: 200,
      lock: true,
    }
  ]
  const columnMonth = _.map(new Array(12), (v, i) => {
    if (i < filterMonth && filterMonth !== 0) return
    return {
      name: `${i + 1}月`,
      align: 'center',
      children: [
        { name: getName('forecastWorkload'), code: `forecastWorkload${i + 1}`, width: 70, align: 'center', render: v => distinguishMonthStyle(v, i) },
        { name: getName('existingCapacity'), code: `existingCapacity${i + 1}`, width: 70, align: 'center', render: v => distinguishMonthStyle(v, i) },
        // { name: getName('capacityExistingCapacity'), code: `capacityExistingCapacity${i + 1}`, width: 70, align: 'center', },
        { name: getName('differenceWorkload'), code: `differenceWorkload${i + 1}`, width: 70, align: 'center', render: v => distinguishMonthStyle(v, i) },
      ]
    }
  })
  function distinguishMonthStyle(v, i) {
    return <div className={(i + 1) % 2 === 0 ? 'distinguish-month' : ''}>{v}</div>
  }
  return _.concat(columnHead, _.compact(columnMonth))
}

export default function Workload(props) {
  const { dataSource, loading, switchChecked } = props



  const columns = useMemo(() => getColumns({ switchChecked }), [switchChecked])

  const footerDataSource = useMemo(() => {
    const monthlyCollect = _.map(new Array(12), (v, i) => ({
      [`existingCapacity${i + 1}`]: getCollect(dataSource, `existingCapacity${i + 1}`),
      [`capacityExistingCapacity${i + 1}`]: getCollect(dataSource, `capacityExistingCapacity${i + 1}`),
      [`forecastWorkload${i + 1}`]: getCollect(dataSource, `forecastWorkload${i + 1}`),
      [`differenceWorkload${i + 1}`]: getCollect(dataSource, `differenceWorkload${i + 1}`),
    }))
    return [_.assign({
      modelName: <div className='total-row'><div className='summary'>汇总</div></div>,
    }, ...monthlyCollect)]
  }, [dataSource])

  return (
    <div className='workload'>
      <BaseTable
        useVirtual
        isLoading={loading}
        dataSource={dataSource}
        columns={columns}
        footerDataSource={footerDataSource}
        style={{
          '--row-height': "34px",
          '--header-bgcolor': '#F5F5F5',
          '--header-color': '#252525'
        }}
      />
    </div>
  )
}

function getCollect(data, bind, arr = []) {
  _.forEach(data, o => {
    if (_.isNaN(_.toNumber(o[bind]))) arr.push(0)
    else arr.push(_.toNumber(o[bind]))
    if (!_.isEmpty(o.children)) getCollect(o.children, bind, arr)
  })
  return <div className='summary-bold'>{_.sum(arr).toFixed(1)}</div>
}
import _ from "lodash";
import { Dateformat } from "rootnet-core/format";

/*
设置日期option限定
@author 杨吕锋
@params
日期支持14位或8位数字，或日期对象
{
   begin:20200101000000,
   end:20200101000000
}
* */

export function dateOptFor(o = {}) {
  const { begin, end } = o;

  const date = {
    begin: setDate(begin),
    end: setDate(end),
  };
  return {
    limit: date,
    available: date,
  };

  function setDate(d) {
    if (_.isNil(d)) return;
    return _.isDate(d) ? d : new Date(Dateformat(d));
  }
}

/*
@author mw && ylf
@params {a:1,b:2} => 'a=1&b=2'
*/
export function strParams(params) {
  if (!_.isObject(params)) return "";
  const paramsArray = [];
  _.mapKeys(params, (value, key) => {
    !_.isNil(value) &&
      paramsArray.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
  });
  return paramsArray.join("&");
}

/*
@author mw && ylf
@params  'a=1&b=2' => {a:1,b:2}
*/
export function parseParams(strParams) {
  if (!_.isString(strParams)) return {};
  return strParams.split("&").reduce((acc, e) => {
    let [key, value] = e.split("=");
    if (value === "true") value = true;
    if (value === "false") value = false;

    acc[decodeURIComponent(key)] = decodeURIComponent(value);
    return acc;
  }, {});
}

/*
简单生成uniqKey
@author ylf
@return string 例如：'u-1595405113378-0'
* */
let uniqKeyFlag = 0;
export function uniqKeyFor() {
  uniqKeyFlag++;
  return "".concat("u-", new Date().getTime(), "-", uniqKeyFlag);
}

/*
@author wuyifei
@params generate_menu(temp, data, level)
{
    temp:null, 
    data:[],初始化数据
    level:4，层级
}
@return Array 树形结构数据
*/
export function generate_menu(temp = null, data, level) {
  const subs = _.sortBy(_.filter(data, (o) => _.size(o.funcCode) === level),['funcCode']);
  _.forEach(subs, (r) => {
    r.text = r.funcName;
    r.to = r.menuUrl;
    r.expanded = false;
    r.icon = _.get(r, "icon", "");
    if (temp) {
      const parent = _.find(temp, (o) => _.startsWith(r.funcCode, o.funcCode));
      if (parent) {
        if (_.isNil(parent.children)) parent.children = [];
        parent.children.push(r);
      }
    }
  });
  if (subs.length > 0) generate_menu(subs, data, level + 2);
  return subs;
}

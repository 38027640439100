import React, { useMemo } from 'react';
import { Tooltip } from "antd";
import _ from "lodash";
import { FormInput, Select } from "rootnet-edit";
import { Icon } from "../../../../../../../components";
import convertOptions from "../../../../../../common/ConvertOptions";
import './DevProjectStageUpdateHeader.scss'

const STATUS_COLOR = {
    '01': '#FFB65D',
    '02': '#165DFF',
    '03': '#00A950',
    '04': '#C9CDD4',
    '06': '#C9CDD4',
    '07': '#0DA5AA',
    '08': '#722ED1',
}

function DevProjectStageUpdateHeader(props) {
    const { editFormData, isDetail, setEditFormData, statusOptions, isParentTemplate, isFinanceCoding, isCoding } = props

    const statusOpt = useMemo(() => {
        if (_.isEmpty(statusOptions)) return []
        if ((isFinanceCoding && isCoding) || _.get(editFormData, 'isBaselineRelease') === 'Y') {
            return _.map(statusOptions, o => {
                if (o.value === '03') return _.assign({}, o, { _disabled: true })
                return o
            })
        } else return statusOptions
    }, [statusOptions, isFinanceCoding, isCoding, editFormData])

    return <div className={'dev-project-stage-update-header flex'}>
        <div className="header-left">
            {
                (isDetail || isParentTemplate) &&
                <div className="title-wrap flex center-y">
                    <div className="title">
                        <Tooltip title={_.get(editFormData, 'title')}>
                            {_.get(editFormData, 'title')}
                        </Tooltip>
                    </div>
                </div>
            }
            {
                (!isDetail && !isParentTemplate) && <FormInput horizontal bind={'title'} label={'任务名称'} required value={_.get(editFormData, 'title')}
                    onChange={v => setEditFormData(x => ({ ...x, title: v }))} maxLength={250} labelWidth={112} />
            }
        </div>
        <div className="header-right flex center-y">
            <div className="header-right-item flex center-y">
                <Icon name={'renling'} className={'header-right-icon'} style={{ color: _.get(STATUS_COLOR, _.get(editFormData, 'status')) }} />
                <div className="header-right-info flex-y">
                    {
                        (isDetail || isParentTemplate) &&
                        <div className="header-right-value">
                            <div className={'status-tag flex center'} style={{ color: _.get(STATUS_COLOR, _.get(editFormData, 'status')), border: `1px solid ${_.get(STATUS_COLOR, _.get(editFormData, 'status'))}`, padding: '0 8px', borderRadius: 4 }}>
                                {convertOptions(_.get(editFormData, 'status'), statusOptions)}
                            </div>
                        </div>
                    }
                    {
                        (!isDetail && !isParentTemplate) &&
                        <FormInput horizontal bind={'status'} required value={_.get(editFormData, 'status')} component={Select} componentWidth={90}
                            onChange={v => {
                                setEditFormData(x => _.assign({}, x, { status: v }))
                            }} options={statusOpt} />

                    }
                    <div className="header-right-field">
                        当前状态
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default DevProjectStageUpdateHeader;
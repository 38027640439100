import React, {forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Messager} from 'rootnet-ui'
import './RequirementStage.scss'
import {useGet, usePost} from "rootnet-biz/es/hooks";
import _ from 'lodash';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {MenuOutlined} from "@ant-design/icons";
import convertOptions from "../../../common/ConvertOptions";
import {Alert, DatePicker, Form, Input, InputNumber, Select, Table, Tooltip, Popover} from "antd";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import {arrayMoveImmutable} from "array-move";
import {RequirementStageEditContext} from "../../../common/Context";
import moment from "moment";
import PersonSelect from "../../../common/personSelect/PersonSelect";
import RequirementStageUpdateDialog from "./requirementStageUpdateDialog/RequirementStageUpdateDialog";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import DelMessage from "../../../../components/DelMessage";
import Icon from "../../../../components/Icon";
import RequirementStageUploadArea from "./requirementStageUploadArea/RequirementStageUploadArea";
import RequirementTemplateListDialog from "../requirementTemplateListDialog/RequirementTemplateListDialog";
import ConferenceQueryDialog from "./conferenceQueryDialog/ConferenceQueryDialog";
import {uniqKeyFor} from "../../../../project_share/utils/utils";
import ConferenceRecordsDetailDialog
    from "../../../conferenceMgt/conferenceRecords/conferenceRecordsDetail/ConferenceRecordsDetailDialog";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import {useFuncCode} from "../../../common/commonMethod";
import gd from "../../../../base/global";
import { isNil } from 'rootnet-core/format';
import ReviewMinutesDetailDialog from '../../../conferenceMgt/reviewMinutesMgt/controls/reviewMinutesDetailDialog';

const milestoneFlagOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=reqMileStone',
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor',
]

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const FUNC_CODE = '1526'

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
function getColumns(props){
    const {stageOptions, workFlowStatusOptions, id, allUserRes, setCurrentStageInfo, updateDoc, setShowList, editAuth,
        setConferenceInfo, setShowDetailDialog, refreshList, workFlowId, setWorkFlowId, editItemRef, bizType, defaultVersionList} = props

    return _.compact([
        editAuth && {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            fixed: 'left',
            render: () => <DragHandle />,
        },
        {
            title: '#',
            width: 40,
            align: 'center',
            fixed: 'left',
            render: (value, obj, index) => index + 1
        },
        {
            title: <span style={{marginLeft: 12}}>阶段</span>,
            dataIndex: 'stage',
            key: 'stage',
            width: 180,
            fixed: 'left',
            editable: true,
            render: (value) => convertOptions(value, stageOptions)
        },
        {
            title: <span style={{marginLeft: 12}}>任务名称</span>,
            dataIndex: 'title',
            key: 'title',
            width: 200,
            fixed: 'left',
            render: (value, obj, index) => <Tooltip placement="topLeft" title={value}>
                    <span className={'task-name-header'} style={{color: '#5477ff', cursor: 'pointer'}}
                          onClick={()=>setCurrentStageInfo({
                              stageId: _.get(obj,'id'),
                              mode: 'detail',
                              stageObj: obj,
                              stageIndex: index,
                          })}>{value}</span>
            </Tooltip>,
        },
        {
            title: <span style={{marginLeft: 12}}>里程碑</span>,
            dataIndex: 'milestoneflag',
            key: 'milestoneflag',
            width: 80,
            editable: true,
            render: (value) => value === 'Y' ? '是' : null
        },
        {
            title: <span style={{marginLeft: 12}}>状态</span>,
            dataIndex: 'factorId',
            key: 'factorId',
            width: 100,
            render: (value, obj) => {
                const workflowColor = _.get(_.find(workFlowStatusOptions, x => x.value === value),'color') || '#000'
                return <Popover destroyTooltipOnHide={true}
                                content={<WorkFlowChangePop businessId={obj.id} close={()=>setWorkFlowId(null)} refreshList={refreshList}
                                                            funcCode={FUNC_CODE} businessType={'stage'}/>}
                                onOpenChange={visible => {
                                    if(!visible){
                                        setWorkFlowId(null)
                                    }
                                }}
                                trigger={'click'}
                                placement="bottom"
                                open={obj.id === workFlowId}
                >
                    <span className={'common-display-field work-flow-status-field'} onClick={()=>setWorkFlowId(obj.id)}
                         style={{color: workflowColor, border: `1px solid ${workflowColor}`,padding: '0 8px', borderRadius: 4}}>
                        {convertOptions(value, workFlowStatusOptions) || '-'}
                    </span>
                </Popover>
            }
        },
        {
            title: <span style={{marginLeft: 12}}>负责人</span>,
            dataIndex: 'owner',
            key: 'owner',
            width: 170,
            editable: true,
            render: value => convertOptions(value, allUserRes,'userName','userAccount')
        },
        {
            title: <span style={{marginLeft: 12}}>处理人</span>,
            dataIndex: 'currentUser',
            key: 'currentUser',
            width: 170,
            editable: true,
            render: value => convertOptions(value, allUserRes,'userName','userAccount')
        },
        _.includes(['issue','req'],bizType) && {
            title: <span style={{marginLeft: 12}}>进度</span>,
            dataIndex: 'speed',
            key: 'speed',
            width: 170,
            editable: true,
            render: (value,obj) => {
                if(isNil(value)) return
                return <div className={'fill flex center-y'} style={{paddingLeft: 12}} onClick={()=> {
                    editItemRef.current = obj
                }}>
                    {value}%
                </div>
            }
        },
        _.includes(['issue','req'],bizType) && {
            title: <span style={{marginLeft: 12}}>计划开始</span>,
            dataIndex: 'taskStartTime',
            key: 'taskStartTime',
            width: 170,
            editable: true,
            render: (value, obj) => <div className={'fill flex center-y'} style={{paddingLeft: 12}} onClick={()=> {
                editItemRef.current = obj
            }}>
                {dateFormat('YYYY-MM-DD', toDate.str14ToDate(value))}
            </div>,
        },
        {
            title: <span style={{marginLeft: 12}}>计划完成</span>,
            dataIndex: 'completetimeplan',
            key: 'completetimeplan',
            width: 130,
            editable: true,
            render: (value, obj) => <div className={'fill flex center-y'} style={{paddingLeft: 12}} onClick={()=> {
                editItemRef.current = obj
            }}>
                {dateFormat('YYYY-MM-DD', toDate.str14ToDate(value))}
            </div>,
        },
        {
            title: <span style={{marginLeft: 12}}>计划投入(人日)</span>,
            dataIndex: 'workloadplan',
            key: 'workloadplan',
            width: 120,
            editable: true,
            render: (value, obj) => <div className={'fill flex center-y'} style={{paddingLeft: 12}} onClick={()=> {
                editItemRef.current = obj
            }}>
                {value}
            </div>,
        },
        {
            title: <span style={{marginLeft: 12}}>实际完成</span>,
            dataIndex: 'completetimeactual',
            key: 'completetimeactual',
            width: 130,
            editable: true,
            render: value => dateFormat('YYYY-MM-DD', toDate.str14ToDate(value)),
        },
        {
            title: <span style={{marginLeft: 12}}>实际投入(人日)</span>,
            dataIndex: 'workloadactual',
            key: 'workloadactual',
            width: 120,
            editable: true,
        },
        {
            title: <span style={{marginLeft: 12}}>版本计划</span>,
            dataIndex: 'releaseIdList',
            key: 'releaseIdList',
            width: 150,
            render: value => {
                return _.isEmpty(value) ? defaultVersionList : _.join(value,'\n')
            }
        },
        {
            title: <span style={{marginLeft: 12}}>评审纪要</span>,
            dataIndex: 'conferenceList',
            key: 'conferenceList',
            width: 150,
            render: (value, obj) => {
                return <div className={'conference-field flex-y'}>
                    {
                        _.map(_.get(obj,'conferenceList'), item => <div className={'conference-item'} key={uniqKeyFor()}>
                            <div className="conference-text" onClick={()=>{
                                setShowDetailDialog({
                                    module: item.sconferenceType === 'AM' ? 'reviewMinutes' : 'conference',
                                    id: _.get(item, 'iid')
                                })
                            }}>
                                {_.get(item,'ssubject')}
                            </div>
                        </div>)
                    }
                </div>
            }
        },
        {
            title: <span style={{marginLeft: 12}}>关联</span>,
            dataIndex: 'relateConference',
            key: 'relateConference',
            width: 60,
            fixed: 'right',
            render: (value, obj) => {
                return <Icon name={'guanlian'} className={'relate-conference-icon'} onClick={()=>{
                    setConferenceInfo({
                        id: obj.id,
                        initList: _.map(_.get(obj,'conferenceList'),'iid')
                    })}
                }/>
            }
        },
        {
            title: <span style={{marginLeft: 12}}>附件</span>,
            dataIndex: 'relatedocument',
            key: 'relatedocument',
            width: 60,
            fixed: 'right',
            render: (value, obj, index) => {
                let stageDocList = value?_.split(value,','):[]
                return <Popover content={<RequirementStageUploadArea stageDocList={stageDocList} stageObj={obj} stageIndex={index} funcCode={'15'}
                                                                     {...{id, allUserRes,updateDoc,setShowList}}/>}>
                        <span className={'flex center relate-document-icon-wrap'} style={{marginLeft: 12}}>
                            <Icon name={'lianjie'} style={{fontSize: 14}} className={'relate-document-icon'}/>({_.size(stageDocList)})
                        </span>
                </Popover>
            }
        },
    ])
}

function RequirementStage(props) {
    const {id, allUserRes, updateDoc, updateDynamic, countStage, editAuth, reqInfo, bizType, outerVersionOptions} = props
    const {doFetch: getList, loading} = useGet()
    const [showList, setShowList] = useState([])
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [currentStageInfo, setCurrentStageInfo] = useState()
    const {doFetch: updateRow} = usePost()
    const [selectedList, setSelectedList] = useState()
    const [showDialog, setShowDialog] = useState()
    const [conferenceInfo, setConferenceInfo] = useState()
    const {doFetch: updateConference} = useGet()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [form] = Form.useForm()
    const [workFlowId, setWorkFlowId] = useState()
    const editItemRef = useRef()

    const defaultVersionList = useMemo(()=>{
        return _.join(_.map(outerVersionOptions,'value'),'\n')
    },[outerVersionOptions])

    const [stageOptions, workFlowStatusOptions] = useMemo(()=> {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const refresh = useCallback(()=>{
        if(_.isEmpty(id)) return
        if(_.isNil(countStage)) return
        getList('/story/stage/list?storyId='+id).then(res => {
            updateConference('/story/stage/recordList?storyId='+id).then(conferenceRes => {
                const handledList = _.map(res, item => ({
                    ...item,
                    conferenceList: _.get(_.find(conferenceRes, x => x.id === item.id),'recordList') || []
                }))
                setShowList(handledList)
            }).catch((err) => {
                setShowList(res)
                Messager.show(err._message, { icon: 'error' })
            })
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[getList, id, countStage, updateConference])

    useEffect(()=>{
        refresh()
    },[refresh])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = _.findIndex(showList, x => x.id === restProps['data-row-key'])
        return <Form form={form} component={false}>
            <RequirementStageEditContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </RequirementStageEditContext.Provider>
        </Form>
    },[showList, form])

    const updateEditRow = useCallback((updateItem)=>{
        const newItem = {
            ...updateItem,
            bizType,
            workloadplan: _.get(updateItem, 'workloadplan') ? N2Float(_.get(updateItem, 'workloadplan')) : null,
            workloadactual: _.get(updateItem, 'workloadactual') ? N2Float(_.get(updateItem, 'workloadactual')) : null,
        }
        updateRow('/story/stage/update',newItem).then(()=>{
            const currentUserParams = {
                businessId: _.get(updateItem,'id'),
                businessType: 'stage',
                funcCode: '1526',
                currentUser: _.get(updateItem, 'currentUser'),
                title: _.get(updateItem,'title')
            }
            updateRow('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(()=>{
                updateDynamic()
                Messager.show('修改成功', { icon: 'success' })
            }).catch((err) => {
                updateDynamic()
                Messager.show(err._message, { icon: 'error' })
            })
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[updateRow,updateDynamic, bizType])

    const handleSave = useCallback((row)=>{
        const index = _.findIndex(showList, x => x.id === row.id)
        updateEditRow(row)
        setShowList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return _.sortBy(newList, 'serial')
        })
    },[showList, updateEditRow])

    const [PMAuth] = useFuncCode(['152603'])

    const columns = useMemo(()=>{
        return _.map(getColumns({stageOptions, workFlowStatusOptions, allUserRes, setCurrentStageInfo, id, updateDoc,
            setShowList, editAuth, setConferenceInfo, setShowDetailDialog, refreshList: refresh, workFlowId, setWorkFlowId,
            editItemRef, bizType, defaultVersionList}), col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    editAuth,
                    stageOptions,
                    PMAuth,
                    editItemRef
                })
            }
        })
    },[stageOptions, workFlowStatusOptions, handleSave, allUserRes, editAuth, id, updateDoc, refresh, workFlowId,
        PMAuth, bizType, defaultVersionList])

    const selectedMessage = useMemo(()=>{
        return <div className={'selected-message flex center-y'}>
            已选择
            <span className={'selected-count'}>{_.size(selectedList)}</span>
            项
            <span className={'bulk-del-text'} onClick={()=>setShowDialog('delete')}>删除</span>
        </div>
    },[selectedList])

    return <div className={`requirement-stage-wrap ${editAuth?'can-edit':'can-not-edit'}`} key={countStage}>
        {
            editAuth &&
            <div className="handle-header-wrap flex center-y">
                <div className="right-header-wrap">
                    <Button normal onClick={()=>setShowDialog('templateList')}>从模版引入</Button>
                    <Button primary onClick={()=>setCurrentStageInfo({mode: 'add'})}>新增</Button>
                </div>
                {
                    !_.isEmpty(selectedList) &&
                    <Alert message={selectedMessage} type="info" showIcon/>
                }
                {
                    _.isEmpty(selectedList) &&
                        <div className={'total-text flex'}>
                            共{_.size(showList)}条数据
                        </div>
                }
            </div>
        }
        <Table
            loading={loading}
            size={'small'}
            sticky={true}
            pagination={false}
            dataSource={showList}
            columns={columns}
            rowKey="id"
            scroll={{x: 950, y: editAuth?`calc(100% - 48px)`:`100%`}}
            rowSelection={editAuth?
                {
                    onChange: setSelectedList
                }: null}
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: EditableRow,
                    cell: EditableCell
                },
            }}
        />
        {
            !_.isNil(currentStageInfo) &&
            <RequirementStageUpdateDialog storyId={id} close={()=>setCurrentStageInfo(null)} refreshList={refresh} stageDocList={showList}
                                          {...{stageOptions, allUserRes, milestoneFlagOptions,currentStageInfo,
                                              showList,updateDynamic,updateDoc,setShowList, reqInfo, bizType, outerVersionOptions}}/>
        }
        {
            showDialog === 'templateList' &&
                <RequirementTemplateListDialog close={()=>setShowDialog(null)} refresh={refresh} templateType={'req'}
                                               submitParams={{storyId: id, bizType}} submitUrl={'/story/stage/addExport'}/>
        }
        {
            showDialog === 'delete' &&
            <DelMessage method={'post'} close={()=>setShowDialog(null)} url={'/story/stage/delete'}
                        refresh={()=>{
                            setSelectedList(null)
                            refresh()
                        }}
                        params={selectedList}>
                确认删除选中阶段吗？
            </DelMessage>
        }
        {
            conferenceInfo &&
            <ConferenceQueryDialog close={()=>setConferenceInfo(null)} initInfo={conferenceInfo}
                                   afterUpdateRefresh={()=>refresh()}
                                   relateUrl={'/story/stage/related/record'}
            />
        }
        {
            _.get(showDetailDialog,'module') === 'conference' &&
            <ConferenceRecordsDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                cid: _.get(showDetailDialog,'id')
            }}/>
        }
        {
            _.get(showDetailDialog,'module') === 'reviewMinutes' &&
            <ReviewMinutesDetailDialog
                close={()=>setShowDetailDialog(null)} 
                currentInfo={{
                    cid: _.get(showDetailDialog,'id')
                }}
            />
        }
    </div>

    function onSortEnd({oldIndex, newIndex}){
        if (oldIndex !== newIndex){
            if (oldIndex < newIndex) {
                const previous = showList[newIndex].serial
                const next = _.get(showList, `[${newIndex + 1}]serial`)
                showList[oldIndex].serial = _.isNil(next) ? previous + 1 : (previous + next) / 2
            }else if (oldIndex > newIndex) {
                const previous = _.get(showList, `[${newIndex - 1}]serial`, 0)
                const next = showList[newIndex].serial
                showList[oldIndex].serial = (previous + next) / 2
            }
            updateRow('/story/stage/update', showList[oldIndex])
                .catch(err => Messager.show(err._message, { icon: 'error' }))
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setShowList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }
}

const EditableCell = forwardRef(function(props) {
    const {title, editable, children, dataIndex, record, handleSave, must, editAuth = false, stageOptions, PMAuth, editItemRef, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(RequirementStageEditContext) || {}

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        if(!editAuth) return setEditing(false)
        setEditing(x => !x);
        let value
        if(_.includes(['completetimeplan','completetimeactual','taskStartTime'],dataIndex)){
            value = _.isNil(record[dataIndex]) ? null :moment(record[dataIndex], 'YYYYMMDDHHmmss')
        }else{
            value = record[dataIndex]
        }
        form.setFieldsValue({ [dataIndex]:  value });
    },[dataIndex, record, form, editAuth])

    const save = () => {
        try{
            form.validateFields().then((changeObj)=>{
                const key = _.head(_.keys(changeObj))
                if(_.includes(['completetimeplan','workloadplan','taskStartTime','speed'],key)){
                    if(!PMAuth){
                        const currentObj = editItemRef.current
                        if(currentObj['factorIdName'] === '初始化'){
                            const canEditPersonList = [_.get(currentObj,'createuser'), _.get(currentObj,'owner'), _.get(currentObj,'currentUser')]
                            if(!_.includes(canEditPersonList, gd.User.operator_id)){
                                if(!_.isNil(inputRef.current)){
                                    inputRef.current.blur()
                                }
                                setEditing(false)
                                Messager.show('无修改权限')
                                return
                            }
                        }else{
                            if(!_.isNil(inputRef.current)){
                                inputRef.current.blur()
                            }
                            setEditing(false)
                            Messager.show('无修改权限')
                            return
                        }
                    }
                }

                if(_.includes(['completetimeplan','completetimeactual','taskStartTime'],key)){
                    changeObj[key] = _.isNil(changeObj[key]) ? null :moment(changeObj[key]).format('YYYYMMDD') + '000000'
                }
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    handleSave({ ...record, ...changeObj });
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        } catch (errInfo) {
            console.error(errInfo);
        }
    }

    let childNode = children;

    if(editable){
        if(editing){
            let editNode
            if(dataIndex === 'stage'){
                editNode = (<Select ref={inputRef} options={stageOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(dataIndex === 'milestoneflag'){
                editNode = (<Select ref={inputRef} options={milestoneFlagOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(_.includes(['currentUser', 'owner'],dataIndex)){
                editNode = (<PersonSelect ref={inputRef} onSelect={save} allowClear={false} defaultOpen={true} onBlur={()=>setEditing(false)} style={{width: 160}} autoFocus/>)
            }else if(_.includes(['completetimeplan','completetimeactual','taskStartTime'],dataIndex)){
                editNode = (<DatePicker ref={inputRef} onChange={save} allowClear={true} open={true} onBlur={()=>setEditing(false)} autoFocus />)
            }else if(_.includes(['workloadplan','workloadactual'],dataIndex)){
                editNode = (<InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} step={1} formatter={limitDecimals}
                                         parser={limitDecimals}/>)
            }else if(dataIndex === 'speed'){
                editNode = (<InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} max={100} step={1} precision={0} formatter={(v) => {
                    if(isNil(v)) return
                    return `${v}%`
                }} />)
            }else{
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </Form.Item>
            )
        }else{
            childNode =(
                <div className="requirement-stage-editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }
    return <td {...restProps}>{childNode}</td>;
})

const limitDecimals = (value) => {
    if(typeof value === 'string') {
        return _.floor(_.toNumber(value),2)
    } else if (typeof value === 'number') {
        return _.floor(value, 2)
    } else {
        return ''
    }
};

function N2Float(valueParams){
    if(_.isNil(valueParams)) return ''
    let value = Math.round(parseFloat(valueParams) * 100) / 100;
    let s = value.toString().split(".");
    if(s.length === 1){
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

export default RequirementStage;
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { isNil, N2 } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination, Button } from 'rootnet-ui'
import { Form, FormInput, Select } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { strParams, summaryWithHeader } from '../../../../../../../../utils/publicFun'
import { Box } from '../../../../../../../common/commonComponent'
import './index.scss'

const LIST_URL = '/devproject/list'

const getColumns = ({ projectStatusOpt }) => {
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '项目名称', bind: 'projectName', sortable: true, align: 'left', width: 300, tooltip: true },
    { header: '项目状态', bind: 'projectStatus', sortable: true, align: 'left', width: 78, convert: convertProjectStatus, csvConvert: convertProjectStatus, },
    { header: '项目经理', bind: 'principalName', sortable: true, align: 'left', width: 85 },
    { header: '计划工时(人日)', bind: 'planTime', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '计划成本(元)', bind: 'planCost', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '实际工时(人日)', bind: 'useTime', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '实际成本(元)', bind: 'useCost', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '创建时间', bind: 'inputDate', sortable: true, width: 150, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
  ]

  function convertProjectStatus(v) {
    return _.get(_.find(projectStatusOpt, x => x.value === v), 'name')
  }
}

const getOptions = (options, data) => ({
  nilText: '-',
  fixedLeft: 2,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns: options,
  virtualized: false,
  summary: [
    summaryWithHeader(options, data)
  ],
})

function getInitParams(customer) {
  return {
    pageNum: 1,
    pageSize: 20,
    customer,
  }
}

export default function DevProject(props) {
  const { customerId, proManagerOpt, projectStatusOpt } = props
  const [params, setParams] = useState()
  const { data: listRes, error, loading, doFetch } = useGet()

  const options = useMemo(() => getOptions(getColumns({ projectStatusOpt })), [projectStatusOpt])
  const { pageSize, total, pageNum, rows: data } = useMemo(() => (listRes || {}), [listRes]);

  const search = useCallback((o) => {
    doFetch(`${LIST_URL}?${strParams(o)}`)
  }, [doFetch])

  useEffect(() => {
    if (isNil(customerId)) return
    setParams(getInitParams(customerId))
    search(getInitParams(customerId))
  }, [search, customerId])

  return (
    <div className='customer-dev-project'>
      <Option {...{ proManagerOpt, projectStatusOpt, search, params }} />
      <Box className='flex-y x-card-singlegrid' title='研发项目' error={error} loading={loading} data={data} >
        <DataGrid data={data} option={options} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setParams(x => {
              const parameter = _.assign({}, x, { pageNum, pageSize })
              search(parameter)
              return parameter
            })
          }} />
      </Box>
    </div>
  )
}

function Option(props) {
  const { proManagerOpt, projectStatusOpt, params, search } = props
  const [parameter, setParameter] = useState()

  useEffect(() => {
    setParameter(params)
  }, [params])

  return (
    <div className='x rims_options'>
      <Form onChange={setParameter} value={parameter}>
        <FormInput label="项目名称" bind="projectName" search />
        <FormInput label="项目经理" component={Select} bind="principal" search clear options={proManagerOpt} />
        <FormInput label="项目状态" component={Select} bind="projectStatusList" multiple options={projectStatusOpt} />
      </Form>
      <Button style={{ marginLeft: 0 }} className='btn' primary onClick={() => { search(parameter) }}>查询</Button>
    </div>
  )
}

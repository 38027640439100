import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RequirementDetailHeader.scss'
import { Popover, Tooltip } from "antd";
import { copyText } from "rootnet-core/clipboard";
import { Messager, Button } from 'rootnet-ui'
import { Icon } from "../../../../components";
import _ from 'lodash'
import { FormInput, Select } from "rootnet-edit";
import convertOptions from "../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import {
    REQUIREMENT_PRIORITY_COLOR,
} from "../../requirementColor";
import UserSelect from '../../../common/personSelectPop/UserSelect';
import { strParams } from "../../../../utils/publicFun";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import ConcernedIcon from '../../../common/concernedIcon';
import DisplaySelect from '../../../common/displaySelect/DisplaySelect';

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=PRIORITYLIST',
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

const FUNC_CODE = '1501'

function RequirementDetailHeader(props) {
    const { editFormData, isDetail, setEditFormData, allUserRes, id, refreshViewList, refreshAddChild, refreshDetail, updateDynamic, setIsClose, storyTypeOptions } = props
    const [showCopyPop, setShowCopyPop] = useState(false)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: workFlowInfo, doFetch: getStatus } = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

    const [status, type, factorName] = useMemo(() => {
        return [
            _.get(workFlowInfo, 'factorId'),
            _.get(workFlowInfo, 'systemFlag'),
            _.get(workFlowInfo, 'factorName')
        ]
    }, [workFlowInfo])

    const isStory = useMemo(() => ('STORY' === _.toUpper(type)), [type])

    const isCUS = useMemo(() => {
        const isFactorName = _.includes(['需求审核', '需求分析', '需求复核'], factorName)
        return (('CUS' === _.toUpper(type) && isFactorName) ||
            ('STORY' === _.toUpper(type) && isFactorName)
        )
    }, [type, factorName])

    const storyTypeOpt = useMemo(() => {
        return _.map(storyTypeOptions, o => {
            return {
                ...o,
                _disabled: isStory && o.value === 'CUS'
            }
        })
    }, [storyTypeOptions, isStory])

    const refreshStatus = useCallback(() => {
        if (_.isNil(id)) return
        const params = {
            businessType: 'req',
            businessId: id
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?' + strParams(params))
    }, [getStatus, id])

    useEffect(() => {
        refreshStatus()
    }, [refreshStatus])

    const [priorityOptions, workFlowStatusOptions] = useMemo(() => {
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const workflowColor = useMemo(() => {
        return _.get(_.find(workFlowStatusOptions, x => x.value === status), 'color') || '#000'
    }, [workFlowStatusOptions, status])

    const copy = useCallback((value) => {
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', { icon: 'success' });
    }, [])

    const CopyArea = useCallback(() => {
        const detailUrl = window.location.host + `/#/requirementMgt?initId=` + _.get(editFormData, 'id')
        return <div className={'flex requirement-title-copy-popover center-y'}>
            <Button onClick={() => copy(_.get(editFormData, 'title'))} normal>复制标题</Button>
            <Button onClick={() => copy(_.get(editFormData, 'storyId'))} normal>复制需求ID</Button>
            <Button onClick={() => copy(`${_.get(editFormData, 'storyId')}\n${_.get(editFormData, 'title')}`)} normal>复制ID+标题</Button>
            <Button onClick={() => copy(detailUrl)} normal>复制需求URL</Button>
            <Button onClick={() => copy(`${_.get(editFormData, 'storyId')}\n${_.get(editFormData, 'title')}\n${detailUrl}`)} normal>复制ID+标题+URL</Button>
        </div>
    }, [copy, editFormData])

    useEffect(() => {
        setIsClose(convertOptions(status, workFlowStatusOptions) === '关闭')
    }, [setIsClose, status, workFlowStatusOptions])

    return <div className={'requirement-detail-header flex'}>
        <div className="header-left">
            <div className="req-id-wrap flex center-y">
                {
                    (!isCUS || isDetail) &&
                    <div className="type-tag flex center-y">{_.get(editFormData, 'type')}</div>
                }
                {
                    isCUS && !isDetail &&
                    <FormInput horizontal value={_.get(editFormData, 'type')} component={DisplaySelect} componentWidth={120}
                        onChange={v => setEditFormData(x => ({ ...x, type: v }))} options={storyTypeOpt} search />
                }
                <div className="req-id">{_.get(editFormData, 'storyId')}</div>
                <ConcernedIcon
                    type='REQ'
                    mode='detail'
                    referenceId={editFormData?.id}
                />
            </div>
            {
                isDetail &&
                <div className="req-title-wrap flex center-y">
                    <div className="req-title">
                        <Tooltip title={_.get(editFormData, 'title')}>
                            {_.get(editFormData, 'title')}
                        </Tooltip>
                    </div>
                    <Popover content={<CopyArea />} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                        <Icon name={'fuzhi'} className={'copy-icon'} />
                    </Popover>
                </div>
            }
            {
                !isDetail && <FormInput className='req-input-title' horizontal bind={'title'} label={'标题'} required value={_.get(editFormData, 'title')}
                    onChange={v => setEditFormData(x => ({ ...x, title: v }))} />
            }
        </div>
        <div className="header-right flex">
            <div className="header-right-item flex center-y">
                <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{ color: '#165DFF' }} />
                <div className="header-right-info flex-y">
                    {
                        isDetail &&
                        <div className="header-right-value">
                            <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes, 'userName', 'userAccount')}</div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <FormInput horizontal required={status !== '172096195508834304'} value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                            onChange={v => setEditFormData(x => ({ ...x, currentUser: v }))} />
                    }
                    <div className="header-right-field">
                        处理人
                    </div>
                </div>
            </div>
            <div className="header-right-item flex center-y">
                <Icon name={'fengkongguanli'} className={'header-right-icon'} style={{ color: REQUIREMENT_PRIORITY_COLOR[_.get(editFormData, 'priority')] }} />
                <div className="header-right-info flex-y">
                    {
                        isDetail &&
                        <div className="header-right-value">
                            <div className={'priority-tag flex center'} style={{ background: REQUIREMENT_PRIORITY_COLOR[_.get(editFormData, 'priority')], padding: '0px 12px', color: '#fff', borderRadius: 4 }}>
                                <span className={'priority-tag-text'}>{convertOptions(_.get(editFormData, 'priority'), priorityOptions)}</span>
                            </div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <FormInput horizontal required value={_.get(editFormData, 'priority')} component={Select} componentWidth={90}
                            onChange={v => setEditFormData(x => ({ ...x, priority: v }))} options={priorityOptions} />

                    }
                    <div className="header-right-field">
                        优先级
                    </div>
                </div>
            </div>
            <Popover destroyTooltipOnHide={true}
                content={<WorkFlowChangePop businessId={id} close={() => setShowWorkFlowPop(null)} refreshList={() => {
                    refreshStatus()
                    refreshViewList()
                    refreshAddChild()
                    refreshDetail(id)
                    updateDynamic()
                }} funcCode={FUNC_CODE} businessType={'req'} />}
                onOpenChange={visible => {
                    if (!visible) {
                        setShowWorkFlowPop(null)
                    }
                }}
                trigger={'click'}
                placement="bottomRight"
                open={showWorkFlowPop}
            >
                <div className="header-right-item flex center-y status-wrap-item" onClick={() => setShowWorkFlowPop(true)}>
                    <Icon name={'renling'} className={'header-right-icon'} style={{ color: workflowColor }} />
                    <div className="header-right-info flex-y">
                        <div className="header-right-value">
                            <div className={'status-tag flex center'} style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
                                {convertOptions(status, workFlowStatusOptions) || '-'}
                            </div>
                        </div>
                        <div className="header-right-field">
                            当前状态
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    </div>
}

export default RequirementDetailHeader;
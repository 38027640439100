import { useState } from 'react';

export const REQUIRED_ERROR = "必填项！";

export function useControlledError(props) {
    const [stateError, setStateError] = useState(null);
    const [active, setActive] = useState(false);
    const user_active = props.userActive === false ? active : true;

    const error = props.error === undefined ? stateError : props.error;
    return [error, user_active !== false || error !== REQUIRED_ERROR, setStateError, setActive];
}
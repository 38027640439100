import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, DataGrid} from 'rootnet-ui';
import OperationList from "../../project_share/components/OperationList";
import './ReleaseInfoListDialog.scss'
import {TextIconBtn} from "../common/TextIconBtn";
import ReleaseInfoDialog from "./ReleaseInfoDialog";
import {useGet} from "../../utils/hook";
import {toDate,dateFormat} from 'rootnet-core/dateFormat';
import _ from 'lodash'
import DelMessage from "../../components/DelMessage";
import useAuth from "../common/hooks/useAuth";

function getColumns(props){
    const {setShowUpdateInfo, setDelId,editAuth} = props
    return [
        { header: '版本号', bind: 'releaseId', width: 200, tooltip: true},
        { header: '主要更新内容', bind: 'memo', width: 330, tooltip: true },
        { header: '版本上线日期', bind: 'releaseDate', align: 'center', width: 100, tooltip: true, convert: v => dateFormat('YYYY-MM-DD')(toDate.str14ToDate(v))},
        { header: '创建人', bind: 'createUser', width: 100, tooltip: true },
        { header: '创建时间', bind: 'createTime', width: 100, tooltip: true, convert: v => dateFormat('YYYY-MM-DD')(toDate.str14ToDate(v)) },
        { header: '操作', bind: '', align: 'center', width: 160, isTransfer: false, convert: renderOperation }
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick: ()=>setShowUpdateInfo({mode: 'detail', id: o.id})
            }
        ].concat(editAuth ? [
            {
            text: '编辑',
            mode: 'edit',
            onClick: ()=>setShowUpdateInfo({mode: 'edit', id: o.id})
            },
            {
                text: '删除',
                mode: 'delete',
                onClick: ()=>setDelId(o.id)
            }
            ]:[]);
        return <OperationList options={operateOption}/>;
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedRight: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function ReleaseInfoListDialog(props) {
    const {close} = props
    const {data: list, doFetch: getList} = useGet()
    const [showUpdateInfo, setShowUpdateInfo] = useState()
    const [delId, setDelId] = useState()

    const editAuth = useAuth('1601')

    const option = useMemo(()=>{
        return getOptions(getColumns({setShowUpdateInfo,setDelId,editAuth}))
    },[editAuth])

    const refresh = useCallback(()=>{
        getList('/promotion/list')
    },[getList])

    useEffect(()=>{
        refresh()
    },[refresh])

    return (<Dialog header="内部版本信息" contentClassName={'release-info-list-dialog'} cancel={close} cancelButtonText={'关闭'} confirmButtonVisible={false}>
        <div className={'flex-y fill'}>
            {
                editAuth &&
                <div className={'add-header-area flex'}>
                    <TextIconBtn icon={'tianjia'} text={'新增'} onClick={()=>setShowUpdateInfo({mode: 'add'})}/>
                </div>
            }
            <DataGrid className='fill' data={list} option={option}/>
            {
                !_.isNil(showUpdateInfo) &&
                <ReleaseInfoDialog releaseList={list} onCancel={()=>setShowUpdateInfo(null)} {...{refresh,showUpdateInfo}}/>
            }
            {
                !_.isNil(delId) && <DelMessage url={'/promotion/delete'} params={{id: delId}} close={()=> setDelId(null)} refresh={refresh} method={'post'}>
                    确认删除吗？
                </DelMessage>
            }
        </div>
    </Dialog>)
}

export default ReleaseInfoListDialog;
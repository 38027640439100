import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog,Messager,Loader} from 'rootnet-ui'
import {Form, FormInput, Select} from 'rootnet-edit'
import _ from "lodash";
import {useApi, useGet} from "../../../utils/hook";
import {Icon} from "../../../components";
import TextAreaWithTooltip from "../common/TextAreaWithTooltip";
import {Tooltip} from "antd";

const HFormInput = (props) =>  <FormInput horizontal labelWidth={110} componentWidth={280} {...props}/>

const OPTIONS_URLS = [
    '/fieldType/select',
    '/fieldDict/select',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
]

const WHICH_OPTIONS = {
    'add': '0',
    'edit': '1'
}

function EditDialog(props) {
    const {close, mode, initData, refresh, approvalMode, checkFlag} = props
    const isEdit = mode === 'edit'
    const {data: optionRes} = useGet(OPTIONS_URLS)
    const [formData, setFormData] = useState(initData)
    const [error, setError] = useState()
    const {doFetch} = useApi()
    const {data: uniqListRes, doFetch: getUniqListRes} = useGet()
    const [loading, setLoading] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(()=>{
        getUniqListRes('/fieldCommon/getName?whichPage=0')
    },[getUniqListRes])

    const uniqList = useMemo(()=>{
        if(_.isEmpty(uniqListRes)) return []
        return _.map(uniqListRes, x => x.code)
    },[uniqListRes])

    const [fieldTypeOptions, fieldDictIdOptions,productOptions] = useMemo(()=>{
        if(_.isEmpty(optionRes)) return []
        const [d1,d2,d3,d4] = optionRes
        return [
            _.map(d1, x => ({value: x.fieldTypeCode, text: `${x.fieldTypeCode}-${x.fieldTypeName}`, tag: `${x.fieldTypeCode}-${x.fieldTypeName}`})),
            _.map(d2, x => ({value: x.fieldDictId, text: `${x.fieldDictId}-${x.fieldDictName}`, tag: `${x.fieldDictId}-${x.fieldDictName}`})),
            _.map(_.groupBy(d3,product => product.productLine), (productLineItem, productLine) => ({
                text: _.get(_.find(d4, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
                children: _.map(productLineItem, x => ({value: x.productId, text: x.productName}))
            }))
        ]
    },[optionRes])

    const check = useCallback(()=>{
        if(checkFlag === '1'){
            if(!_.get(formData, 'memo')){
                Messager.show('请填写必填项', { icon: 'error' })
                return false
            }
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }else{
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }
    },[error, checkFlag, formData])

    useEffect(()=>{
        if(!isEdit && _.get(formData,'fieldCode') &&  _.includes(uniqList, formData.fieldCode)){
            setError(x=>_.assign({},x,{'fieldCode': '字典名重复'}))
        }
        const fieldDesc = _.get(formData, 'fieldDesc')
        if(!fieldDesc){
            setError(x => _.assign({},x,{fieldDesc: '必填项！'}))
        }else{
            setError(x => _.assign({},x,{fieldDesc: null}))
        }
    },[formData,uniqList, isEdit])

    const submit = useCallback(()=>{
        if(!check()) return
        const isPending = approvalMode === 'pending'
        const postParams = {...formData,whichPage:'0',whichOption:WHICH_OPTIONS[mode],isAgree: isPending? '0': null,isApproval: checkFlag}
        const url = approvalMode === 'default' || mode === 'add' || _.get(formData,'auditFlag') === '1' ? '/field/save' : '/field/updateAudit'
        setLoading(true)
        setSubmitDisable(true)
        doFetch(url, 'post',postParams).then(()=>{
            setSubmitDisable(false)
            Messager.show(checkFlag==='1'?`数据已提交，待审核`:'提交成功，已入库', { icon: 'success' })
            refresh()
            close()
        }).catch(err => {
            if([1052, 1053, 1054].includes(err._code)){
                setError(x => ({x, fieldCode: err._message}))
            }
            setSubmitDisable(false)
            Messager.show(err._message, { icon: err._message === '当前数据您已提交审批，若修改请撤销审批'?'info':'error' });
            setLoading(false)
        })
    },[check,doFetch,formData,close,mode,refresh,approvalMode,checkFlag])

    const TipsDom = useCallback(()=>{
        return <div>
            <div>字段命名规则：</div>
            <div>1.小驼峰规则（小驼峰式命名法: 第一个单词以小写字母开始,第二个单词的首字母大写。例如:firstName、lastName）</div>
            <div>2.字段的单词都需要来源于标准词根</div>
            <div>3.字段名称规则为标准词根英文全称拼接或者英文简称拼接，不允许混用</div>
            <div>4.最多输入30个字符</div>
        </div>
    },[])

    const toDictStandardRoot = useCallback(()=>{
        window.open('/#/metadataMgt?initTabIndex=3','_blank')
    },[])

    return <Dialog header={isEdit?'修改':'新增'} className='data-dict-edit-dialog' cancel={close} confirm={submit} confirmButtonDisabled={submitDisable} confirmButtonText={submitDisable?'提交中':'提交'}>
        <Form value={formData} onChange={setFormData} error={error} onError={setError}>
            <div style={{height: 16}}>
                <div style={{marginLeft: 118, display: _.get(error,'fieldCode')==='字典名重复'?'block':'none'}} className={'form-error-msg'}>
                    <Icon name='tixing' style={{marginRight: 5}}/>
                    字典名重复
                </div>
            </div>
            <HFormInput label='字典名' bind='fieldCode' required disabled={isEdit} maxLength={30}/>
            <Tooltip title={<TipsDom/>} placement={'bottom'}>
                <span>
                    <Icon name={'bangzhu'} style={{ fontSize: '14px', color: '#5477FF', width: 14, height: 14, cursor: 'pointer' }}/>
                </span>
            </Tooltip>
            <span className={'to-dict-standard-root-text'} onClick={toDictStandardRoot}>查询标准词根</span>
            <HFormInput label='字典名称' bind='fieldName' required/>
            <HFormInput label='根网数据类型' bind='fieldTypeCode' required component={Select} options={fieldTypeOptions} search/>
            <HFormInput label='常量标识' bind='fieldDictId' component={Select} options={fieldDictIdOptions} search clear/>
            <HFormInput label='使用产品' bind='productId' options={productOptions} required component={Select} tree clear search multiple bindInConvert={v => v && v.split(',')} bindOutConvert={v => v&&v.join(',')}/>
            <HFormInput label='字典说明' bind='fieldDesc' component={TextAreaWithTooltip} value={_.get(formData, 'fieldDesc')} className={'desc-textarea desc-required'} required/>
            <HFormInput label='申请理由' bind='memo' component={TextAreaWithTooltip} value={_.get(formData, 'memo')} className={`desc-textarea ${checkFlag === '1'? 'desc-required' :''}`} required={checkFlag === '1'}/>
        </Form>
        {loading && <Loader fill text={'提交中...'}/>}
    </Dialog>
}

function checkRequire(error){
    if(_.some(_.values(error),x=>x)){
        const errMsg = _.find(_.values(error), x=>!_.isNil(x))
        Messager.show(errMsg==='必填项！'?'请填写必填项':errMsg, { icon: 'error' })
        return false
    }
    return true
}

export default EditDialog;
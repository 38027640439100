import React from 'react';
import {Card,Button} from 'rootnet-ui';
import { hasData } from 'rootnet-core/system';
import { Nodata } from "../../components";
import { TextIconBtn as TextIconBtn_ } from "./TextIconBtn";

export const TextIconBtn = TextIconBtn_;

export function Box(props) {
    const { data, children, loading, special, ...rest } = props;
    return <Card loading={loading} {...rest}>
        {hasData(data) && !loading && children}
        {!hasData(data) && !loading && <Nodata/>}
        {special}
    </Card>;
}

export function OptBtn(props) {
    const { children,style, ...rest } = props;

    return <Button primary style={Object.assign({ marginLeft: 56, minWidth: 74, height: 31 }, style)} {...rest}>
        {children}
    </Button>;
}
OptBtn.defaultProps={
    children:'查询'
};
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import _ from 'lodash'
import { Button, Messager } from 'rootnet-ui'
import { Badge, Popover, Tabs, Tooltip } from 'antd'
import { dateFormat } from 'rootnet-core/dateFormat'
import { isNil } from 'rootnet-core/format'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../utils/publicFun'
import gd from '../../../../base/global'
import { useFuncCode } from '../../../common/commonMethod'
import { TextIconBtn } from '../../../common/TextIconBtn'
import { Icon } from '../../../../components'
import DevListDetailHeader from '../devListDetailHeader'
import DevListDetailRightPanel from '../devListDetailRightPanel'
import PopoverComment from '../../../testMgt/defect/controls/PopoverComment'
import SyncToJiraDialog from '../../../requirementMgt/requirementDetailDialog/syncToJira/SyncToJiraDialog'
import TrackingAddDialog from '../../../trackingManagementMgt/components/trackingAddDialog/TrackingAddDialog'
import CustomerServiceInfoDetailDialog from '../../../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog'
import TrackingDetailDialog from '../../../trackingManagementMgt/components/trackingDetailDialog'
import ChangeRecord from '../../../common/ChangeRecord'
import RequirementUploadArea from '../../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea'
import { gridOptsFor } from '../../../requirementMgt/requirementDetailDialog/components/method'
import PublicUseCase from '../../../common/publicPage/publicUseCase'
import PublicDefect from '../../../common/publicPage/publicDefect'
import RequirementStage from '../../../requirementMgt/requirementDetailDialog/requirementStage/RequirementStage'
import './index.scss'
import DevListInfo from '../devListInfo'
import TaskTableDetails from '../../../requirementMgt/requirementDetailDialog/components/taskTableDetails'

const FUNC_CODE = '1512'
const TASK_URL = '/customerServiceInfoNew/getUserprojectlistinfo'

const convertIssueWay = {
  '0': '0',
  '1': '2',
  '2': '4',
}

export default function DevListDetailContent(props) {
  const { storyID: storyIdC = null, isCS, outerAllDetailAuth } = window.external
  const { refreshViewList = () => { }, currentInfo = {}, close = () => { }, switchCurrentItem, showChildList, currentIndex, } = props
  const { mode: initMode = 'detail' } = currentInfo
  const [mode, setMode] = useState(initMode)
  const { doFetch: getDetail } = useGet()
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch: submitForm } = usePost()
  const descRef = useRef()
  const solutionRef = useRef()
  const customerRecordsRef = useRef() // 客户沟通记录
  const [infoError, setInfoError] = useState()
  const [activeKey, setActiveKey] = useState('info')
  const [count, updateDynamic] = useReducer((x) => x + 1, 0)
  const [countTask, updateTask] = useReducer((x) => x + 1, 0)
  const [countDoc, updateDoc] = useReducer((x) => x + 1, 10000)
  const [countStage, updateStage] = useReducer((x) => x + 1, 20000)
  const [id, setId] = useState()
  const [switchDisabled, setSwitchDisabled] = useState(false)
  const { data: customerOptionsRes, doFetch: getProjectId } = useGet()
  const [showSyncJira, setShowSyncJira] = useState(false)
  // const {doFetch: updateJiraDoFetch} = usePost()
  const [commentText, setCommentText] = useState()
  const [commentPopoverVisible, setCommentPopoverVisible] = useState(false)
  const [showAddTrack, setShowAddTrack] = useState(false)
  const [showDetailDialog, setShowDetailDialog] = useState()
  const [isClose, setIsClose] = useState(true)
  const [fileCount, setFileCount] = useState(0)
  const { data: allVersionRes } = useGet('/develop/release/list?versionType=0')

  const { doFetch: getVerifyResult } = useGet()

  const isDetail = useMemo(() => mode === 'detail', [mode])

  const handShowAddTrack = useCallback(() => {
    getVerifyResult(`${'/bug/tracer/add/check'}?${strParams({ storyId: _.get(formData, 'id') })}`)
      .then(() => {
        setShowAddTrack(true)
      }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getVerifyResult, formData])

  const refreshDetail = useCallback((id, storyIdC, open) => {
    if (_.isNil(id) && _.isNil(storyIdC)) return
    setSwitchDisabled(true)
    getDetail('/story/getDetail?' + strParams({ id, storyId: storyIdC })).then(res => {
      let handleFormData = { ...res }
      if (handleFormData['description']) {
        // handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '<br/>')
        handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '')
      }
      setFormData(handleFormData)
      setEditFormData(handleFormData)
      setSwitchDisabled(false)
      setId(res.id)
    }).catch(err => {
      setSwitchDisabled(false)
      Messager.show(err._message, { icon: 'error' })
    })
    updateDynamic()
    updateTask()
  }, [getDetail, updateDynamic])

  const refreshReleaseList = useCallback(() => {
    if (_.isNil(id) && _.isNil(storyIdC)) return
    getDetail('/story/getDetail?' + strParams({ id, storyId: storyIdC })).then(res => {
      const releaseList = _.get(res, 'releaseList')
      // setFormData(x => ({...x,releaseList}))
      setEditFormData(old => {
        const clone = _.clone(old)
        clone['releaseList'] = releaseList
        return clone
      })
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [id, storyIdC, getDetail])

  useEffect(() => {
    if (_.isNil(id) && _.isNil(storyIdC)) return
    setSwitchDisabled(true)
    getDetail('/story/getDetail?' + strParams({ id, storyId: storyIdC })).then(res => {
      let handleFormData = { ...res }
      if (handleFormData['description']) {
        // handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '<br/>')
        handleFormData['description'] = handleFormData['description']?.replace(/\n/g, '')
      }
      setFormData(handleFormData)
      setEditFormData(handleFormData)
      setSwitchDisabled(false)
      setId(res.id)
      getProjectId('/story/getbugProject?id=' + _.get(res, 'custSystem'))
    }).catch(err => {
      setSwitchDisabled(false)
      Messager.show(err._message, { icon: 'error' })
    })
    updateDynamic()
  }, [id, storyIdC, getDetail, getProjectId])

  const switchMode = useCallback((resetDesc = false) => {
    if (resetDesc) {
      if (descRef.current) {
        // let initText = _.replace(_.get(formData, 'description'), /\n/g, '<br/>')
        let initText = _.replace(_.get(formData, 'description'), /\n/g, '')
        descRef.current.setContent(initText || '')
      }
      if (solutionRef.current) {
        // let initText = _.replace(_.get(formData, 'solution'), /\n/g, '<br/>')
        let initText = _.replace(_.get(formData, 'solution'), /\n/g, '')
        solutionRef.current.setContent(initText || '')
      }
    }
    setMode(x => x === 'detail' ? 'edit' : 'detail')
  }, [formData])

  const canSubmit = useMemo(() => {
    if (!_.get(editFormData, 'title')) {
      return false
    }
    if (!_.get(editFormData, 'currentUser') && !isClose) {
      return false
    }
    return !_.some(_.values(infoError), x => x)
  }, [infoError, editFormData, isClose])

  const needBizFlag = useMemo(() => {
    if (_.get(editFormData, 'type') !== 'CUS') return 'N'
    const reqSort = _.get(editFormData, 'reqSort')
    return _.includes(['SPC', 'NORMAL', 'FUNCTION'], reqSort) ? 'Y' : 'N'
  }, [editFormData])

  const checkRichTextRequired = useCallback(() => {
    return purifyRichText(descRef.current.getContent()) === ""
    function purifyRichText(richText = '') {
      const regex = /(<([^>]+)>)/ig
      return richText.replace(regex, "")
        .replace(/\s/g, "")
        .replace(/ /g, "")
        .replace(/&nbsp;/g, "")
    }
  }, [])

  const submit = useCallback(() => {
    const customerRecords = _.isNil(customerRecordsRef.current) ? _.get(formData, 'custcommunicationlog') : customerRecordsRef.current.getContent()
    if (JSON.stringify(editFormData) === JSON.stringify(formData)
      && descRef.current.getContent() === _.get(formData, 'description')
      && solutionRef.current.getContent() === _.get(formData, 'solution')
      && customerRecords === _.get(formData, 'custcommunicationlog')) {
      switchMode()
      return Messager.show('无变更')
    }

    if (checkRichTextRequired()) {
      return Messager.show("请填写描述")
    }
    if (descRef?.current.loading || solutionRef?.current.loading) {
      return Messager.show("图片上传中，请稍后保存")
    }
    const postParams = _.assign(
      {
        ...editFormData,
        needBizFlag: needBizFlag,
        // salesprojectid: _.get(editFormData,'salesprojectid')? _.get(editFormData,'salesprojectid') : '',
        // assessauditor: _.get(editFormData,'assessauditor')? _.get(editFormData,'assessauditor') : '',
        // assessflag: _.get(editFormData,'assessflag')? _.get(editFormData,'assessflag') : '',
        // impassessor: _.get(editFormData,'impassessor')? _.get(editFormData,'impassessor') : '',
        // devUser: _.get(editFormData,'devUser')? _.get(editFormData,'devUser') : '',
        // analystTestUser: _.get(editFormData,'analystTestUser')? _.get(editFormData,'analystTestUser') : '',
        feevalue: _.get(editFormData, 'feevalue') ? N2Float(_.get(editFormData, 'feevalue')) : '',
        workload: _.get(editFormData, 'workload') ? N2Float(_.get(editFormData, 'workload')) : '',
        impworkload: _.get(editFormData, 'impworkload') ? N2Float(_.get(editFormData, 'impworkload')) : '',
        releaseList: _.get(editFormData, 'releaseList') ? _.get(editFormData, 'releaseList') : [],
        description: _.replace(descRef.current.getContent(), /\n/g, ''),
        // description: descRef.current.getContent(),
        solution: _.replace(solutionRef.current.getContent(), /\n/g, ''),
        // solution: solutionRef.current.getContent(),
        custcommunicationlog: isNil(customerRecords) ? null : _.replace(customerRecords, /\n/g, ''),
        // custcommunicationlog: customerRecords,
        updateuserStoryBusiness: gd.User.operator_name,
        createuserStoryBusiness: gd.User.operator_name,
      },
      _.get(formData, 'businessauditstate') !== _.get(editFormData, 'businessauditstate') && {
        businessaudittime: dateFormat('YYYYMMDDHHMMSS', new Date()),
        businessauditor: gd.User.operator_name,
      }
    )

    const setNull = []
    _.forEach(postParams, (v, k) => {
      if (_.isNil(v) || v === '') {
        // setNull.push({
        //     field: k,
        //     value: v
        // })
        setNull.push(k)
      }
    })

    postParams['setNull'] = setNull

    submitForm('/story/updateDetailNew', _.omit(postParams, ['updateTime', 'releaseList', 'releaseStoryList', 'currentUser'])).then(() => {
      const releaseParams = {
        storyid: id,
        type: 'REQ',
        releaseidList: _.get(editFormData, 'releaseList')
      }
      submitForm('/ReleaseStory/updateForReleaseStory', releaseParams).then(res => {
        if (_.get(formData, 'currentUser') === _.get(editFormData, 'currentUser')) {
          afterRefresh(res)
        } else {
          const currentUserParams = {
            businessId: _.get(editFormData, 'id'),
            businessType: 'dev',
            funcCode: '1501',
            currentUser: _.get(editFormData, 'currentUser'),
            title: _.get(editFormData, 'title')
          }
          submitForm('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
            afterRefresh(res)
          }).catch(err => Messager.show(err._message, { icon: 'error' }))
        }
      }).catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
    }).catch(err => Messager.show(err._message, { icon: 'error' }))

    function afterRefresh(res) {
      Messager.show(res || '修改成功', { icon: 'success' })
      const isStateEqual = (_.get(formData, 'businessauditstate') !== _.get(editFormData, 'businessauditstate'))
      if (_.get(editFormData, 'reqSort') === 'FUNCTION' && isStateEqual && _.get(editFormData, 'businessauditstate') === 'Y') {
        refreshDetail(id, undefined, true)
      } else refreshDetail(id)
      switchMode()
      refreshViewList()
    }
  }, [submitForm, editFormData, refreshDetail, switchMode, refreshViewList, needBizFlag, formData, id, checkRichTextRequired])

  const [editAuth, allDetailAuth] = useFuncCode(['1504', '1507'])

  const showAllDetailAuth = useMemo(() => {
    return outerAllDetailAuth || allDetailAuth
  }, [outerAllDetailAuth, allDetailAuth])

  const defaultDefectParams = useMemo(() => {
    return { 'test_defect.storyId': id, 'test_defect.issueId': null }
  }, [id])

  const defaultUseCaseParams = useMemo(() => {
    if (_.isNil(_.get(formData, 'storyId'))) return {}
    return { 'View_test_caseRelated_Req.relateId': _.get(formData, 'storyId'), 'View_test_caseRelated_Issue.relateId': null, 'test_defect_case_related.defectId': null }
  }, [formData])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) {
      setId(id)
    }
  }, [currentInfo])

  useEffect(() => {
    const dialog = document.querySelector('.dev-list-detail-content')
    dialog.addEventListener('click', () => setCommentPopoverVisible(false))
  }, [])

  const outerVersionOptions = useMemo(() => {
    const versionList = _.get(formData, 'releaseList')
    return _.map(versionList, item => {
      const findItem = _.find(allVersionRes, x => x.releaseId === item)
      return {
        text: findItem?.releaseName,
        value: findItem?.releaseId,
      }
    })
  }, [formData, allVersionRes])

  return <React.Fragment>
    <div className="dev-list-detail-content flex-y">
      <div className="mock-dialog-header flex">
        <div className="dialog-title flex center-y">
          开发自提单信息
        </div>
        <div className="mock-right-header flex center-y">
          {
            formData &&
            <span style={{ marginRight: 16 }}>
              <TextIconBtn icon={'-task'} className={`header-edit-text-icon`} text={'创建研发任务'} onClick={handShowAddTrack} />
            </span>
          }
          {
            editAuth &&
            <span>
              <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                switchMode(true)
                refreshDetail(id)
              }} />
            </span>
          }
          {
            (!storyIdC) &&
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          }
        </div>
      </div>
      <DevListDetailHeader {...{ editFormData, setEditFormData, isDetail, allUserRes, formData, id, refreshViewList, refreshDetail, updateDynamic, setIsClose }} />
      <div className="dev-list-detail-main-panel flex">
        <div className="dev-list-detail-left-panel">
          <Tabs
            activeKey={activeKey}
            onChange={setActiveKey}
            items={_.compact([
              {
                label: `描述`,
                key: 'info',
                forceRender: true,
                children: <DevListInfo
                  initFormData={formData}
                  {...{
                    descRef,
                    isDetail,
                    infoError,
                    solutionRef,
                    editFormData,
                    setInfoError,
                    getProjectId,
                    setEditFormData,
                    customerOptionsRes,
                  }}
                />,
              },
              showAllDetailAuth && {
                label: `阶段`,
                key: 'stage',
                children: <RequirementStage
                  reqInfo={formData}
                  bizType={'dev'}
                  {...{
                    id,
                    editAuth,
                    updateDoc,
                    countStage,
                    allUserRes,
                    updateDynamic,
                    outerVersionOptions
                  }}
                />,
              },
              {
                label: `缺陷`,
                key: 'defect',
                children: <PublicDefect
                  defaultParams={defaultDefectParams}
                  addDefaultParams={{
                    storyId: _.get(formData, 'id'),
                    interiorReqNo: _.get(formData, 'storyId'),
                    productId: _.get(formData, 'productId'),
                    releaseId: _.size(_.get(formData, 'releaseList', [])) === 1 ? _.head(_.get(formData, 'releaseList')) : null,
                    // fixVersion: _.size(_.get(formData, 'releaseList', [])) === 1 ? _.head(_.get(formData, 'releaseList')) : null,
                  }}
                />,
              },
              {
                label: `用例`,
                key: 'useCase',
                children: <PublicUseCase
                  defaultParams={defaultUseCaseParams}
                  addDefaultParams={{
                    productId: _.get(formData, 'productId'),
                    moduleId: _.get(formData, 'subSysId'),
                  }}
                  addDefaultReq={[{
                    id: _.get(formData, 'id'),
                  }]}
                />,
              },
              showAllDetailAuth && {
                label: `任务清单`,
                key: 'task',
                children: <div
                  key={countTask}
                  className='dev-list-table-details'
                >
                  <TaskTableDetails
                    {...{
                      url: TASK_URL,
                      option: gridOptsFor('task', setShowDetailDialog),
                      id: _.get(formData, 'storyId'),
                      setShowDetailDialog,
                    }}
                  />
                </div>,
              },
              showAllDetailAuth && {
                label: <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                  附件
                </Badge>,
                key: 'doc',
                forceRender: true,
                children: <div
                  key={countDoc}
                  className="file-wrap"
                >
                  <RequirementUploadArea
                    afterDelRefresh={updateStage}
                    funcCode={'15'}
                    {...{
                      id,
                      mode,
                      isCS,
                      allUserRes,
                      setFileCount
                    }}
                  />
                </div>,
              },
              showAllDetailAuth && {
                label: `动态`,
                key: 'dynamic',
                children: <div
                  key={count}
                  className={'dynamic-wrap'}
                >
                  <ChangeRecord
                    name='开发自提单'
                    funcCode={'1501'}
                    referenceId={id}
                    associatedField={'title'}
                    associatedFieldName={'阶段任务'}
                    id={_.get(editFormData, 'storyId')}
                    title={_.get(editFormData, 'title')}
                    linkUrl={`/devMgt?initId=${id}`}
                  />
                </div>,
              }
            ])}
          />
        </div>
        <DevListDetailRightPanel initFormData={formData}
          {...{
            isDetail, allUserRes, editFormData, setEditFormData, needBizFlag, showAllDetailAuth,
            infoError, setInfoError, id, refreshReleaseList
          }} />
      </div>
      <div className="mock-footer flex center-y">
        <div />
        {
          _.isEmpty(showChildList) ? <div /> :
            <div className="footer-switch flex center-y">
              <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                onClick={() => {
                  if (switchDisabled) return
                  if (!_.includes([-1, 0], currentIndex)) {
                    switchCurrentItem('previous')
                  }
                }}>
                <Icon name='xiangqian' />&nbsp;上一个
              </div>
              <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                onClick={() => {
                  if (switchDisabled) return
                  if (!(currentIndex === showChildList?.length - 1)) {
                    switchCurrentItem('next')
                  }
                }}>
                下一个&nbsp;<Icon name='xianghou' />
              </div>
              <div className="list-num">
                {currentIndex + 1}/{showChildList?.length}
              </div>

              <Popover content={<PopoverComment
                referenceId={id}
                funcCode={FUNC_CODE}
                {...{ allUserRes, commentText, setCommentText }}
                title={`【${_.get(formData, 'storyId')}】${_.get(formData, 'title')}`}
                linkUrl={`/requirementMgt?initId=${id}`}
                close={() => setCommentPopoverVisible(false)}
              />}
                trigger="click" placement="topRight" open={commentPopoverVisible}>
                <Tooltip title="评论信息">
                  <div className={'comment-wrap flex center'} onClick={() => setCommentPopoverVisible(x => !x)}>
                    <Icon name={'zidingyishezhi1'} className={'comment-icon'} />
                  </div>
                </Tooltip>
              </Popover>
            </div>
        }
        <div className="btn-group flex">
          {
            isDetail && !storyIdC &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              switchMode(true)
              setEditFormData(formData)
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
          }
        </div>
      </div>
    </div>
    {
      showSyncJira &&
      <SyncToJiraDialog close={() => setShowSyncJira(false)} id={_.get(editFormData, 'storyId')} flag={'req'}
        afterRefresh={
          () => {
            refreshDetail(id)
            refreshViewList()
          }
        }
      />
    }
    {
      showAddTrack && <TrackingAddDialog close={() => setShowAddTrack(false)}
        afterRefresh={() => updateTask()}
        initForm={{
          bugType: 'N',
          tracerPri: _.get(formData, 'priority'),
          productId: _.get(formData, 'productId'),
          subSysId: _.get(formData, 'subSysId'),
          bugSource: _.get(formData, 'type') === 'CUS' ? "S1" : 'S0',
          issueway: _.get(convertIssueWay, _.get(formData, 'releaseType')),
          projectId: _.get(formData, 'custSystem'),
          securityName: _.get(formData, 'custSystem') ? [_.get(formData, 'custSystem')] : null,
          bugDesc: _.get(formData, 'description'),
          fixDesc: _.get(formData, 'solution'),
          storyId: _.get(formData, 'storyId'),
          id: _.get(formData, 'id'),
          tracerTitle: _.get(formData, 'title'),
        }}
      />
    }
    {
      _.get(showDetailDialog, 'module') === 'customerService' &&
      <CustomerServiceInfoDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
        id: _.get(showDetailDialog, 'id')
      }} />
    }
    {
      _.get(showDetailDialog, 'module') === 'tracker' &&
      <TrackingDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
        id: _.get(showDetailDialog, 'id')
      }} />
    }
  </React.Fragment >
}

function N2Float(valueParams) {
  if (_.isNil(valueParams)) return ''
  let value = Math.round(parseFloat(valueParams) * 100) / 100;
  let s = value.toString().split(".");
  if (s.length === 1) {
    value = value.toString() + ".00";
    return value;
  }
  if (s.length > 1) {
    if (s[1].length < 2) {
      value = value.toString() + "0";
    }
    return value;
  }
}

import React from 'react'
import _ from 'lodash'
import ViewQueryDialog from '../../../common/view/viewQueryDialog/ViewQueryDialog'

export default function AssociatedUseCases(props) {
  const { caseDefaultData, close, outerSetItem, textHint } = props

  return (
    <ViewQueryDialog
      bizName='选择用例'
      initValue={_.map(caseDefaultData, o => o.id)}
      initItemValue={caseDefaultData}
      close={close}
      multiple={true}
      funcCode={'0022'}
      outerSetItem={outerSetItem}
      refreshViewList={() => { }}
      textHint={textHint}
    />
  )
}


import _ from 'lodash'
import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Form, FormInput, Select, Radio } from 'rootnet-edit'
import { Dialog, DataGrid } from 'rootnet-ui'
import { strParams } from '../../../../project_share/utils/utils'
import { Icon } from '../../../../project_share/components'
import { useGet } from '../../../../utils/hook'

const WORKTIME_URL = 'worktime/costcenter?activeFlag=Y'

const getColumns = ({ radio, setRadio, handleTreeClick }) => {
  return {
    autoFill: true,
    columns: [
      { header: '', bind: 'id', convert: (v, o) => <Radio value={_.get(radio, 'costId') === o.costId} onChange={() => setRadio(o)} />, width: 60, align: 'center' },
      { header: '名称', bind: 'name', width: 340, tooltip: true, convert: addLeftIcon },
      { header: 'ID', bind: 'costId', width: 150, tooltip: true },
      { header: '负责人', width: 100, convert: (v, o) => o.principalName, tooltip: true },
    ],
  }
  function addLeftIcon(v, o, i) {//
    return <span style={{ marginLeft: o.level * 30 }}>
      <Icon name="arrow_drop_down" className='arrow_drop_down' onClick={() => { handleTreeClick(o, i) }}
        style={{ transform: o.expanded ? 'none' : 'rotate(-90deg)', visibility: o.children ? '' : 'hidden', cursor: 'pointer' }} />
      {v}
    </span>
  }
}

const item = { text: '全部', value: '' }
export default function TreeTable(props) {
  const { turnOn, setTurnOn, params, setParams } = props
  const [radio, setRadio] = useState()
  const [parameter, setParameter] = useState({ principal: '' })
  const [showList, setShowList] = useState([])
  const { data: listData, doFetch: getList, loading } = useGet(WORKTIME_URL)
  const { data: headData } = useGet('/common/userinfo')
  const headOpt = useMemo(() => _.map(headData, o => { return { text: o.userName, value: o.userAccount, tag: `${o.userAccount}-${o.userName}` } }), [headData])
  useEffect(() => {
    search(parameter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const costId = turnOn === 1 ? 'costCenter' : 'revenueCenter'
    if (listData) {
      setRadio(_.find(listData, v => v.costId === params[costId]))
    }
  }, [listData, turnOn, params])
  /***********************************************************************************************/

  const buildTree = useCallback((list) => {
    return _.map(list, listItem => {
      const childList = _.filter(listData, allItem => allItem.pid === listItem.id)
      if (childList.length === 0) return listItem
      return _.assign(listItem, { children: buildTree(childList) })
    })
  }, [listData])

  const expandList = useMemo(() => {
    if (_.isNil(listData)) return []
    const filterList = _.sortBy(_.filter(listData, item => _.isNil(item.pid)), ['id'])
    const expandList = handleTree(buildTree(filterList), 'children', 'id')
    return _.map(expandList, (item, i) => _.assign({}, item, { _index: i }))
  }, [listData, buildTree])

  useEffect(() => {
    setShowList(expandList)
  }, [expandList])

  function handleTree(treeData, childrenName, keyName) {
    const treeList = []
    add(_.cloneDeep(treeData))
    return treeList

    function add(data, level = 0, parent = null) {
      if (!_.isArray(data)) return treeList
      data.forEach(item => {
        item.level = level
        item.parent = parent
        item._children = hasChildren(item)
        item.expanded = true;
        item._childrenList = _.map(item[childrenName], x => x[keyName])
        treeList.push(item)
        if (item._children) return add(item[childrenName], level + 1, item[keyName])
      })

      function hasChildren(item) {
        return _.has(item, childrenName) && !_.isEmpty(item[childrenName])
      }
    }
  }
  /***********************************************************************************************/
  const handleTreeClick = useCallback((o, i) => {
    const updateItem = _.assign({}, showList[i], { expanded: !showList[i].expanded })
    if (o.expanded) {
      let endIndex = -1
      for (let currentIndex = i + 1; currentIndex < showList.length; currentIndex++) {
        if (showList[currentIndex].level <= o.level) {
          endIndex = currentIndex
          break
        }
      }
      setShowList(showList.slice(0, i).concat(updateItem).concat(endIndex !== -1 ? showList.slice(endIndex) : []))//
    } else {
      const allList = _.map(expandList, item => _.assign({}, item, { expanded: false }))
      const filterList = _.filter(allList, item => item.pid === o.id)
      const addList = _.map(filterList, item => _.assign(item, { level: o.level + 1 }))
      setShowList(showList.slice(0, i).concat(updateItem).concat(addList).concat(showList.slice(i + 1)))//_.orderBy(, 'costId', 'asc')
    }
  }, [expandList, showList])
  const radiosOption = useMemo(() => getColumns({ radio, setRadio, handleTreeClick }), [radio, handleTreeClick])

  // useEffect(() => {
  //   if (!_.isEmpty(showList)) {
  //     const scroll = document.getElementsByClassName('grid')[0]
  //     if (scroll) scroll.scrollTop = _.findIndex(showList, o => o.costId === (turnOn === 1 ? params.costCenter : params.revenueCenter)) * 35
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showList, params])

  const search = useCallback((o) => {
    getList(`${WORKTIME_URL}&${strParams(o)}`)
  }, [getList])

  useEffect(() => {
    search(parameter)
  }, [parameter, search])

  return (
    <Dialog className='subsidyDialog-promptBox' header={turnOn === 1 ? '成本中心' : '收入中心'}
      confirmButtonDisabled={!radio} contentStyle={{ height: 400 }}
      onOutsideClick={() => setTurnOn(false)}
      cancel={() => setTurnOn(false)}
      confirm={promptBoxSubmit}
      style={{ padding: 0 }}
      loading={loading}
    >
      {/*{loading && <Loader fill />}*/}
      <div>
        <Form value={parameter} onChange={setParameter}>
          <FormInput horizontal label='ID' componentWidth={130} bind='costId' />
          <FormInput horizontal label='名称' componentWidth={130} bind='name' />
          <FormInput horizontal search label='负责人' clear componentWidth={200} component={Select} options={[item, ...headOpt]} bind='principal' />
          {/*<Button style={{ height: '30px' }} primary onClick={() => search(parameter)}>查询</Button>*/}
        </Form>
      </div>
      <div className='subsidyDialog-promptBox-table-fill' style={{ height: '330px' }}>
        <DataGrid data={showList} option={radiosOption} onRowClick={onRowClick} />
      </div>
    </Dialog>
  )
  function onRowClick(o) {
    setRadio(o)
  }

  function promptBoxSubmit() {
    const defaultData = turnOn === 1 ? {
      // id: params.costCenterId,
      listId: _.get(radio, 'id'),
      costCenter: _.get(radio, 'costId'),
      costCenterId: _.get(radio, 'costId'),
      costCenterName: _.get(radio, 'name'),
    } : {
      // id: params.revenueCenterId,
      revenueCenter: _.get(radio, 'costId'),
      revenueCenterId: _.get(radio, 'costId'),
      revenueCenterName: _.get(radio, 'name'),
    }
    const copyData = copyFn()
    setParams(x => _.assign({}, x, defaultData, copyData))

    setTurnOn(false)
    function copyFn() {
      if (turnOn === 1 && !_.get(params, 'revenueCenterName') && Number(_.get(params, 'budgetRev')) > 0) {
        return {
          revenueCenter: _.get(radio, 'costId'),
          revenueCenterId: _.get(radio, 'costId'),
          revenueCenterName: _.get(radio, 'name'),
        }
      } else if (turnOn === 2 && !_.get(params, 'costCenterName') && Number(_.get(params, 'budgetCost')) > 0) {
        return {
          costCenter: _.get(radio, 'costId'),
          costCenterId: _.get(radio, 'costId'),
          costCenterName: _.get(radio, 'name'),
        }
      }
    }
  }
}

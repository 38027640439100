import React from 'react';
import _ from "lodash";
import {Tooltip} from 'rootnet-ui'
import {TextArea} from "rootnet-edit";
import {Icon} from "../../../components";
import './TextAreaWithTooltip.scss'

export default function TextAreaWithTooltip(props){
    const {value, required = true} = props

    return <div className={`text-area-with-tooltip ${required && (_.isNil(value) || value ==='') ?'empty-text-area':''}`}>
        <Tooltip title={<ErrorTooltipText error={'必填项！'}/>} theme='light' placement={'top-start'} className={'error-tooltip'}>
            <TextArea placeholder={'请输入'} {...props}/>
        </Tooltip>
    </div>
}

function ErrorTooltipText(props){
    const { error } = props;
    return <div>
        <Icon name='baocuo' size={18} style={{color: '#F5221B', marginRight: 8}}/>
        <span>{error}</span>
    </div>
}
import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/lib/hooks'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { Form, FormInput, Display } from 'rootnet-edit'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'

const HFormInput = props => <FormInput horizontal labelWidth={112} componentWidth={180} component={Display} {...props} />

const YNOptions = [
  { text: '是', value: 'Y' },
  { text: '否', value: 'N' },
]

export default function ApiAuthList(props) {
  const { projectId, allUserRes, allSystemOptions } = props
  const { doFetch, data } = useGet()

  useEffect(() => {
    if (_.isNil(projectId)) return
    doFetch(`/bug/project/selectAPIAuthInfo?customer=${projectId}`)
  }, [doFetch, projectId])

  const customerOpt = useMemo(() => {
    return _.map(allSystemOptions, o => ({ text: o.text, value: o.projectId }))
  }, [allSystemOptions])

  const formList = useMemo(() => {
    return [
      {
        label: '客户服务单号',
        bind: 'csrecordID',
      },
      {
        label: '客户系统',
        bind: 'customer',
        convert: v => convertOptions(v, customerOpt)
      },
      {
        label: '连接数',
        bind: 'conNumber',
      },
      {
        label: '授权到期日',
        bind: 'deadline',
        convert: v => {
          if (_.isNil(v)) return '-'
          if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
          return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
        }
      },
      {
        label: '状态',
        bind: 'status',
        convert: v => convertOptions(v, YNOptions)
      },
      {
        label: '创建时间',
        bind: 'createTime',
        convert: v => {
          if (_.isNil(v)) return '-'
          if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
          return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
        }
      },
      {
        label: '创建人',
        bind: 'createUser',
        convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
      },
    ]
  }, [allUserRes, customerOpt])

  return (
    <div className='customer-api-auth-list'>
      <Form value={data}>
        {
          _.map(formList, o => {
            return <HFormInput {...o} />
          })
        }
      </Form>
    </div>
  )
}

import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Card, DataGrid, Select, FormInput, Button, TextInput, MultiSelect } from 'rootnet-ui';
import { Icon, IconButton } from '../../components';
import './ProjectList.scss';
import { useGet } from '../../utils/hook';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { P2 } from 'rootnet-core/format';
import { Dateformat } from 'rootnet-core/format';
import { strParams } from "../../utils/publicFun";
import API1 from "../../base/task";
import { ProjectDialog } from "./controls/ProjectDialog";
import gd from "../../base/global";
import clsx from "clsx";

function optionParamsReducer(state, action) {
    const { type, value } = action;
    if (type === 'projectStatusList') state[type] = _.map(value, x => x.value);
    else state[type] = value;
    return { ...state };
}

const urls = ["common/user/position?posId=PM", "common/globalconst?globalConst=ProjectStatus"];
const initParams = { principal: _.get(gd,'User.userMainId'), projectStatusList: ['0', '3'], projectName: null };

function Option(props) {
    const { setParam } = props;
    const [params_o, paramsDispatch_o] = useReducer(optionParamsReducer, initParams);
    const { data } = useGet(urls);
    const [options_manager, options_status, selection_status] = useMemo(() => {
        const d1 = data ? data[0] : null;
        const d2 = data ? data[1] : null;
        const item = { text: "全部", value: '' };
        const ops_m = [item, ..._.map(d1, o => { return { text: ''.concat(o.userAccount, '-', o.userName), value: o.userAccount } })];
        const ops_s = _.map(d2, o => { return { text: o.displayName, value: o.interiorId } });
        const sel_s = _.filter(ops_s, x => ['0', '3'].includes(x.value));
        return [ops_m, ops_s, sel_s];
    }, [data]);

    return <div className="list-option">
        <FormInput label="项目经理"
            component={Select}
            options={options_manager}
            onChange={(e, o) => paramsDispatch_o({ type: 'principal', value: o.value })}
            defaultValue={initParams.principal}
            search />
        <FormInput label='项目名称'
            component={TextInput}
            onChange={value => paramsDispatch_o({ type: 'projectName', value })}
            placeholder='请输入' />
        <FormInput label="状态"
            component={MultiSelect}
            options={options_status}
            onChange={(e, d) => paramsDispatch_o({ type: 'projectStatusList', value: d })}
            selection={selection_status} />
        <Button primary onClick={() => setParam(params_o)}>查询</Button>
    </div>;
}

function gridOptsFor(status, setShowDialog, params, history) {
    return {
        autoFill: false,
        selection: true,
        fixedLeft:3,
        columns: [
            {
                header:"#",convert:(v,o,i)=>i+1,width:40
            },{
                header:'预警',convert:(v,o)=><Icon name={iconNameFor(getRisk(v,o))} className={clsx('icon-bg warn',getRisk(v,o))}/>,align:'center',width:40
            },{
                header: "操作", align: "center", width: 240,
                convert: (v, o) => {
                    return <div className="operations">
                        <span onClick={(e) => do_operation(e, o, params, "detail")}>详情</span>
                        <span onClick={(e) => do_operation(e, o, params, "edit")}>修改</span>
                        <span onClick={(e) => do_operation(e, o, params, "approval")}>审批</span>
                        {o.auditStatus !== 'N' && <span style={{width:72}} onClick={(e) => do_operation(e, o, params, "submit")}>指派审批</span>}
                    </div>;
                }
            },
            { header: "项目名称", bind: "projectName", width: 280,tooltip:true },
            { header: "项目经理", bind: "principalName", width: 120 },
            { header: "截止日期", bind: "completeDate", width: 112, sortable: true, convert: (v) => Dateformat(v) },
            { header: "状态", bind: "auditStatus", convert: translate_status, width: 140, sortable: true },
            { header: "总工作量", bind: "useDay", width:120,align: "right", sortable: true },
            { header: "进度", bind: "progress", convert: P2,width:120,align: "right", sortable: true },
            { header: "进度差", bind: "progressDiff", convert: P2, width:120,align: "right", sortable: true },
            { header: "项目成本差",bind:'stdUseDay',convert:translate_cost_diff},
            { header: "成本差额",bind:'stdUseDay',convert:translate_cost_diff_per}
        ],
    };

    function do_operation(e, target, params, type) {
        e.preventDefault();
        e.stopPropagation();
        let _target = _.assign({}, target);
        _target.auditMemo = _.isNil(_target.auditMemo) ? null : escape(target.auditMemo);
        let _params = strParams(_.assign({}, params, _.pick(_target, ['id', 'projectId', 'beginDate', 'completeDate', 'projectSort', 'auditMemo'])));
        if (type === 'detail') history.push(`project/detail?${_params}`);
        if (type === 'edit') return setShowDialog({ type: 'edit', values: target });
        if (type === 'approval') return history.push(`/approval?${_params}`)
    }

    function translate_status(v,o) {
        return v==='N' ? '不通过' : status[_.get(o,'status')]
    }
    function translate_cost_diff(v,o) {
        if(_.isNil(v) || v===0) return '--';
        return P2((_.get(o,'auditUseDay') / v) - 1);
    }
    function translate_cost_diff_per(v,o) {
        if(_.isNil(v) || v===0) return '--';
        return _.get(o,'auditUseDay') - v;
    }

    function iconNameFor(v) {
        return v==='normal' ? 'zhengchang' : 'fengxian'
    }

    function getRisk(v,o) {
        const costDiff = _.get(o,'auditUseDay') - v;
        const progressDiff = _.get(o,'progressDiff');
        // console.log(costDiff, progressDiff);
        if(isHighRisk()) return 'high-risk';
        if(isMiddleRisk()) return 'middle-risk';
        if(isLowRisk()) return 'low-risk';
        if(isNormal()) return 'normal';
        
        function isHighRisk() {
            return costDiff > 0.5 || progressDiff===0.5 || progressDiff>0.5;
        }
        
        function isMiddleRisk() {
            return isRisk(costDiff) || isRisk(progressDiff);

            function isRisk(v) {
                return v===0.2 || (v>0.2 && v <0.5)
            }
        }
        
        function isLowRisk() {
            return costDiff>0 && costDiff<0.2 &&  progressDiff>0 && progressDiff<0.2;
        }

        function isNormal() {
            return (costDiff<0 || costDiff===0) && (progressDiff<0 || progressDiff===0);
        }
    }
}

function Panel(props) {
    const { param } = props;
    const [updateId, update] = useReducer(x => x + 1, 0);
    const [status,setStatus] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const { data, loading, error, doFetch } = useGet();
    const history = useHistory();

    useEffect(() => {
        API1.get("common/globalconst?globalConst=PROJECTSTATUS").then(res=>{
            setStatus(_.reduce(res.data, (acc, x) => _.assign(acc, { [x.interiorId]: x.displayName }), {}))
        })
    }, []);

    useEffect(() => {
        doFetch(`develop/project/list?${strParams(param)}`);
    }, [param, doFetch, updateId]);

    const extra = useMemo(() => [<IconButton key="add" icon="zengjia" circle onClick={() => setShowDialog({ type: 'add', values: {} })} />, <IconButton icon="daochu" key="export" circle />], []);

    const datagrid_option = useMemo(() => {
        return gridOptsFor(status, setShowDialog, param, history);
    }, [status, param, history]);

    return <div className="list-panel">
        <Card title="项目列表" loading={loading} error={error} extra={extra}>
            <DataGrid option={datagrid_option} data={data} selection={data} />
        </Card>
        {showDialog && <ProjectDialog info={showDialog} close={() => setShowDialog(false)} update={update} />}
    </div>;
}

export default function ProjectList(props) {
    const [param, setParam] = useState(initParams);
    return <div className="v-project-list">
        <Option setParam={setParam} />
        <Panel param={param} />
    </div>
}
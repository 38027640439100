import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager, Loader} from 'rootnet-ui'
import {FormInput, Form, Select} from "rootnet-edit";
import './SyncToJiraDialog.scss'
import _ from "lodash";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import {strParams} from "../../../../utils/publicFun";

function SyncToJiraDialog(props) {
    const {close, id, flag, afterRefresh} = props
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const [step, setStep] = useState(0)
    const {doFetch: getInitProjectId} = useGet()
    const {data: optionRes, doFetch: getOptions} = useGet()
    const {doFetch: submitPost} = usePost()
    const [jiraId, setJiraId] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        getOptions('/jira/key')
    },[getOptions])

    useEffect(()=>{
        if(_.isNil(id)) return
        if(_.isNil(flag)) return
        getInitProjectId('/jira/judge?'+strParams({id, flag})).then(res => {
            setFormData({
                projectId: _.get(res, 'jiraProductKey')
            })
        })
    },[getInitProjectId, id, flag])

    const projectOptions = useMemo(()=>{
        if(_.isNil(optionRes)) return []
        return _.map(optionRes, x => ({value: x.projectKey, text: x.projectName}))
    },[optionRes])

    const canSubmit = useMemo(()=>{
        return _.every(formError, _.isNil)
    },[formError])

    const submit = useCallback(()=>{
        if(step !== 0){
            close()
        }
        if(loading) return
        setLoading(true)
        const urlGroup = {
            req: '/jira/story/push',
            issue: '/jira/issue/push',
        }
        const url = _.get(urlGroup,flag)
        if(_.isNil(url)) return Messager.show('类型不存在')
        const params = {
            issuetype: _.get(formData,'projectId'),
            ids: [id],
            flag: '2'
        }
        submitPost(url, params).then(res => {
            setLoading(false)
            setJiraId(_.get(_.head(res), 'jirakey'))
            setStep(2)
            if(afterRefresh){
                afterRefresh()
            }
        }).catch(err => {
            setLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitPost, flag, id, formData, step, close, afterRefresh, loading])

    return <Dialog header={'同步到jira'} className={'sync-to-jira-dialog'} cancel={close} cancelButtonText={'关闭'}
                   confirmButtonDisabled={!canSubmit} confirm={submit}>
        {
            step === 0 &&
            <div>
                <Form value={formData} onChange={setFormData} error={formError} onError={setFormError}>
                    <FormInput label={'jira项目'} bind={'projectId'} required horizontal labelWidth={80} search
                               componentWidth={300} component={Select} options={projectOptions}/>
                </Form>
                <div className="tips-text">选择项目后，将需求同步到对应项目中</div>
            </div>
        }
        {
            step === 1 &&
            <div className={'tips'}>
                已经同步到jira，无需重复操作！jira中对应的ID为：{jiraId}
            </div>
        }
        {
            step === 2 &&
            <div className={'tips'}>
                同步成功！jira中对应的ID为：{jiraId}
            </div>
        }
        {
            loading &&
            <Loader fill text={'同步中'}/>
        }
    </Dialog>
}

export default SyncToJiraDialog;
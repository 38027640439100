import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ResourcePlan.scss'
import {Dialog, Messager} from 'rootnet-ui'
import { Radio } from 'antd';
import {Select, FormInput, Form, CheckBox} from "rootnet-edit";
import Icon from "../../../../../components/Icon";
import {TextIconBtn} from "../../../../common/TextIconBtn";
import _ from 'lodash'
import {uniqKeyFor} from "../../../../../project_share/utils/utils";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import ResourceSelectReq from "./resourceSelectReq/ResourceSelectReq";
import {strParams} from "../../../../../utils/publicFun";
import DateSelector from "../../../../common/dateSelector/DateSelector";

const typeOptions = [
    { label: '需求', value: 'REQ' },
    { label: '项目', value: 'PRJ' },
]

const timeOptions = [
    { text: '每天', value: '0', memo: 'everyday' },
    { text: '合计', value: '1', memo: 'total' },
]

function ResourcePlan(props) {
    const {close, planInfo, afterPlanUpdate, selectedDeptId} = props
    const {mode = 'add', searchInfo, initInfo} = planInfo || {}
    const {initRelatedType, initReqId, initList, initProjectId} = initInfo || {}
    const {userAccountList: initUserList} = searchInfo || {}
    const [type, setType] = useState(initRelatedType?initRelatedType:'REQ')
    const [reqId, setReqId] = useState(initReqId?initReqId:null)
    const [showReqSelectDialog, setShowReqSelectDialog] = useState(false)
    const [planList, setPlanList] = useState(initList?initList:[])
    const [planInitList, setPlanInitList] = useState([])
    const {doFetch: getPlanList} = usePost()
    const {doFetch: submit} = usePost()
    const {data: reqInfo, doFetch: getReqInfo} = useGet()
    const {data: allProjectList, doFetch: getAllProjectList} = useGet()
    const [projectId, setProjectId] = useState(initProjectId?initProjectId:null)
    const [showAll] = useState(true)
    const [totalTime, setTotalTime] = useState(0)
    const {doFetch: getTotalTime} = useGet()
    const [reqPlanTime, setReqPlanTime] = useState()

    const allProjectOptions = useMemo(()=>{
        return _.map(allProjectList, x => ({text: x.projectName, value: x.id, planTime: x.budgetCostTime}))
    },[allProjectList])

    const projectPlanTime = useMemo(()=>{
        if(_.isNil(projectId)) return null
        return _.get(_.find(allProjectOptions, x => x.value === projectId),'planTime')
    },[projectId,allProjectOptions])

    useEffect(()=>{
        if(_.isNil(selectedDeptId)) return
        getAllProjectList('/demandInput/getProject?department='+selectedDeptId)
    },[getAllProjectList, selectedDeptId])

    useEffect(()=>{
        if(_.isNil(reqId)) return
        getReqInfo('/userPlan/storyTranslate?storyId='+reqId).then(res => {
            setReqPlanTime(_.get(res,'workload') || 0)
        })
    },[getReqInfo,reqId])

    useEffect(()=>{
        if(type === 'REQ' && _.isNil(reqId)) return setTotalTime(0)
        if(type === 'PRJ' && _.isNil(projectId)) return setTotalTime(0)
        const relatedType = type
        const relatedTask = type === 'REQ' ? reqId: projectId
        const searchParams = {
            relatedType,
            relatedTask
        }
        getTotalTime('/demandInput/getScheduledTime?'+strParams(searchParams)).then(res => setTotalTime(res))
    },[getTotalTime,type,reqId,projectId])

    const changePlan = useCallback((newObj,bind, uniqId)=>{
        setPlanList(oldList => {
            return _.map(oldList, item => {
                if(item.uniqId !== uniqId) return item
                if (_.get(newObj, 'firstDate') && _.get(newObj, 'lastDate')) {
                    if (_.includes(['firstDate', 'lastDate'], bind)) {
                        if (_.get(newObj, 'firstDate') > _.get(newObj, 'lastDate')) {
                            if (bind === 'firstDate') {
                                Messager.show('开始时间不能晚于结束时间')
                            } else {
                                Messager.show('结束时间不能早于开始时间')
                            }
                            return item
                        }
                    }
                }
                const cloneObj = _.clone(newObj)
                if(bind === 'dateRange'){
                    if(_.isNil(_.get(newObj,'dateRange'))){
                        cloneObj['firstDate'] = null
                        cloneObj['lastDate'] = null
                    }else{
                        cloneObj['firstDate'] = _.get(newObj, 'dateRange.begin')
                        cloneObj['lastDate'] = _.get(newObj, 'dateRange.end')
                    }
                }
                return ({...item, ...cloneObj})
            })
        })
    },[])

    const hasChangedTask = useMemo(()=>{
        if(type !== initRelatedType) return true
        if(type === 'REQ'){
            if(reqId !== initReqId) return true
        }
        return false
    },[type,reqId,initRelatedType, initReqId])

    const getPostListParams = useCallback(currList => {
        const addList = _.filter(currList, x => _.isNil(_.get(x,'id')))
        const addUniqIdList = _.map(addList,'uniqId')

        const initIdList = _.map(planInitList, 'id')
        const finalIdList = _.filter(_.map(currList, 'id'), x => !_.isNil(x))
        const delIdList = _.xor(initIdList,finalIdList)

        const otherList = _.filter(planInitList, x => !_.includes(addUniqIdList,x.uniqId) && !_.includes(delIdList,_.get(x,'id')))

        let diffList = []
        _.forEach(otherList, oldItem => {
            const currItem = _.find(currList, x => x.uniqId === oldItem.uniqId) || {}
            const isEqual = JSON.stringify(oldItem) === JSON.stringify(currItem)
            if(hasChangedTask || !isEqual){
                diffList.push(currItem)
            }
        })

        const addPostList = _.map(addList, x => ({
            action: 'add',
            ...x
        }))

        const delPostList = _.map(delIdList, x => ({
            action: 'del',
            id: x
        }))

        const editPostList = _.map(diffList, x => ({
            action: 'edit',
            ...x
        }))

        return _.concat([],addPostList,delPostList,editPostList)
    },[planInitList,hasChangedTask])

    const searchUser = useMemo(()=>{
        return _.get(searchInfo,'userAccountList.0')
    },[searchInfo])

    useEffect(()=>{
        if(mode === "edit"){
            const searchParams = showAll ? _.pick(searchInfo,'relatedTask') : searchInfo
            const currUser = _.get(searchInfo,'userAccountList.0')
            getPlanList('/userPlan/related',searchParams).then(res => {
                const handleInitList = _.map(res, item => {
                    const pickParams = _.pick(item, ['id','timeType','userAccount','firstDate','lastDate','planTime'])
                    return {
                        uniqId: uniqKeyFor(),
                        ...pickParams,
                        dateRange: {
                            begin: _.get(item,'firstDate'),
                            end: _.get(item,'lastDate'),
                        },
                        haveFestivo: _.get(item,'haveFestivo') === 'Y',
                    }
                })
                const sortedList = _.sortBy(handleInitList, x => x.userAccount !== currUser)
                setPlanList(sortedList)
                setPlanInitList(sortedList)
            })
        }
    },[mode, searchInfo, getPlanList, showAll])

    const addPerson = useCallback(()=>{
        setPlanList(oldList => {
            const lastItem = _.last(oldList)
            const newObj = {
                uniqId: uniqKeyFor(),
                timeType: '0',
                userAccount: _.head(initUserList)
            }
            if(lastItem){
                newObj['firstDate'] = _.get(lastItem,'firstDate')
                newObj['lastDate'] = _.get(lastItem,'lastDate')
                newObj['dateRange'] = _.get(lastItem,'dateRange')
            }
            return _.concat(oldList,[newObj])
        })
    },[initUserList])

    const delPerson = useCallback((uniqId)=>{
        setPlanList(oldList => {
            return _.filter(oldList, x => x.uniqId !== uniqId)
        })
    },[])

    // const totalTime = useMemo(()=>{
    //     const effectiveList = _.filter(planList, item =>  !(!_.get(item,'firstDate') || !_.get(item,'lastDate')|| !_.get(item,'planTime')))
    //     let countTime = 0
    //     _.forEach(effectiveList, item => {
    //         if(item.timeType === '1'){
    //             countTime += _.toNumber(item.planTime)
    //         }else{
    //             let duration = new Date(item.lastDate) - new Date(item.firstDate)
    //             let days = Math.floor(duration / (24 * 3600 * 1000));
    //             const time = (days + 1) * item.planTime
    //             countTime += _.toNumber(time)
    //         }
    //     })
    //     return countTime
    // },[planList])

    const onSubmit = useCallback(()=>{
        const effectiveList = _.filter(planList, item =>  !(!_.get(item,'firstDate') || !_.get(item,'lastDate')|| !_.get(item,'planTime')))
        const postParamsList = getPostListParams(effectiveList)
        if(_.isEmpty(postParamsList)){
            Messager.show("无变更", { icon: 'info' })
            close()
            return
        }
        const relatedType = type
        const relatedTask = type === 'REQ' ? reqId: projectId
        const postParams = _.map(postParamsList, item => ({
            relatedTask,
            relatedType,
            ...item,
            planTime: _.toNumber(item.planTime),
            haveFestivo: item.haveFestivo ? 'Y' : 'N'
        }))
        submit('/userPlan/operate',postParams).then(()=>{
            Messager.show("提交成功", { icon: 'success' })
            afterPlanUpdate()
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[planList,close, afterPlanUpdate, submit, getPostListParams,reqId, type,projectId])

    const confirmButtonDisabled = useMemo(()=>{
        return _.isNil(type === 'REQ' ? reqId: projectId)
    },[type,reqId, projectId])

    return <Dialog className={'resource-plan'} header={'计划'} cancel={close} confirm={onSubmit} confirmButtonDisabled={confirmButtonDisabled}>
        <div className={'type-wrap flex'}>
            <Radio.Group options={typeOptions} value={type} onChange={e => setType(e.target.value)} optionType="button" buttonStyle="solid"/>
            {
                type === 'REQ' &&
                <div className={`mock-req-select flex center-y ${reqId?'':'placeholder'}`} onClick={()=>setShowReqSelectDialog(true)}>
                    {reqId?`${_.get(reqInfo,'storyId') || ''}-${_.get(reqInfo,'title') || ''}`:'请选择需求'}
                </div>
            }
            {
                type === 'PRJ' &&
                <Select placeholder={'请选择项目'} search options={allProjectOptions} value={projectId} onChange={setProjectId}/>
            }
        </div>
        <div className="time-handle-wrap flex cneter-y">
            <div className={'total-time-wrap flex center-y'}>
                <div className="plan-time">
                    计划安排：{(type === 'REQ' ?reqPlanTime:projectPlanTime) || ' - '}小时
                </div>
                <div className={'total-time'}>
                    共计安排：{totalTime}小时
                </div>
            </div>
            <div className={'add-person-btn'}>
                <TextIconBtn icon={'tianjia'} text={'添加计划'} onClick={addPerson}/>
            </div>
        </div>
        <div className="plan-main-panel flex-y">
            <div className="plan-main-panel-header flex">
                <div className="plan-header-person">人员</div>
                <div className="plan-header-time-range">时间周期</div>
                <div className="plan-header-include-rest">含非工作日</div>
                <div className="plan-header-plan-time">计划时间</div>
            </div>
            <div className="plan-group">
                {
                    _.map(planList, item => {
                        return <div className={`plan-item flex center-y ${searchUser === _.get(item,'userAccount') ?'curr-user':''}`} key={item.uniqId}>
                            <Form value={item} onChange={(newObj, bind) => changePlan(newObj, bind, item.uniqId)}>
                                <FormInput bind={'userAccount'} placeholder={'请选择人员'} required componentWidth={120} component={UserSelect} search/>
                                <div style={{width: 20}}/>
                                {/*<FormInput bind={'firstDate'} placeholder={'开始时间'} componentWidth={120} component={DatePicker} required clear*/}
                                {/*           bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>*/}
                                {/*-*/}
                                {/*<FormInput bind={'lastDate'}  placeholder={'结束时间'} componentWidth={120} component={DatePicker} required clear*/}
                                {/*           bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>*/}
                                <FormInput bind={'dateRange'} componentWidth={260} component={DateSelector} required/>
                                <FormInput bind={'haveFestivo'} componentWidth={20} component={CheckBox}/>
                                <FormInput bind={'timeType'} componentWidth={80} component={Select} options={timeOptions}/>
                                <FormInput bind={'planTime'} componentWidth={140} type={"number"} suffix={"小时"} min={0} digit={1} required/>
                            </Form>
                            <Icon className={'del-icon'} name={'shanchu'} onClick={()=>delPerson(item.uniqId)}/>
                        </div>
                    })
                }
            </div>
            <div className="add-person-bottom-btn">
                <TextIconBtn icon={'tianjia'} text={'添加计划'} onClick={addPerson}/>
            </div>
            {/*<div className="show-all-btn-wrap">*/}
            {/*    {*/}
            {/*        mode === 'edit' && !showAll &&*/}
            {/*        <Button normal className={'show-all-btn'} onClick={()=>setShowAll(true)}>显示全部</Button>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
        {
            showReqSelectDialog &&
                <ResourceSelectReq close={()=>setShowReqSelectDialog(false)} outerSetReqId={setReqId} initReqId={reqId}/>
        }
    </Dialog>
}

export default ResourcePlan;
import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Dialog, Button } from 'rootnet-ui'
import { Form, FormInput, Display } from 'rootnet-edit'
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'
import './index.scss'

export default function RefuseDialog(props) {
  const { close, confirm, size = 0, list } = props
  const [params, setParams] = useState({ size, state: '2' })
  const [error, setError] = useState()

  const handClick = useCallback(() => {
    confirm({ list, params: _.omit(params, ['size']) })
  }, [list, params, confirm])

  return (
    <Dialog
      header={'拒绝'}
      cancel={close}
      footerVisible={false}
      className='refuse-dialog-edit'
      style={{
        width: 600
      }}
    >
      <div className='refuse-dialog-edit-content flex-y'>
        <div className="refuse-dialog-edit-main-panel">
          <Form value={params} onChange={setParams} error={error} onError={setError}>
            <FormInput
              horizontal
              labelWidth={80}
              componentWidth={495}
              bind='size'
              label='条数'
              component={Display}
              convert={v => `${v}条`}
            />
            <FormInput
              required
              horizontal
              className='text-area-input-edit'
              labelWidth={80}
              componentWidth={495}
              bind='refuseReason'
              label='拒绝意见'
              component={TextAreaInput}
            />
          </Form>
        </div>
        <div className="mock-footer flex center-y">
          <div className='footer-hint'>
          </div>
          <div className="btn-group flex">
            <Button normal onClick={close}>取消</Button>
            <Button primary disable={_.size(_.trim(_.get(params, 'refuseReason'))) <= 0} onClick={handClick}>确认</Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import {Form, FormInput, Select, RadioGroup, Display} from "rootnet-edit";
import './FieldDefinitionUpdateDialog.scss'
import _ from "lodash";
import {strParams} from "../../../utils/publicFun";
import {useApi, useGet} from "../../../utils/hook";
import {Icon} from "../../../components";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import {Divider} from "antd";
import {isNil} from "../../appraise/components/method";

const HFormInput = (props) => <FormInput horizontal componentWidth={160} labelWidth={150} {...props}/>

const options = [
    { text: "是", value: true },
    { text: "否", value: false },
]

const continueOptions = [
    { text: "继续添加", value: true },
    { text: "返回列表", value: false },
]

const customOptions = [
    { text: "是", value: 'Y' },
    { text: "否", value: 'N' },
]

function getInitParams(){
    return {
        custom:'N',
        tableName:null,
        fieldId:null,
        fieldName:null,
        fieldDbType:"VARCHAR",
        fieldDbSize:null,
        accuracy:null,
        fieldHtmlType:"1",
        fieldHtmlDetailType:null,
        fieldDateSource:null,
        viewConditionType:"blurry",
        formQueryType:"Like",
        fieldDateDetailSource:null,
        displayFlag: true,
        columnWidth: 100,
        labelWidth: 150,
        contentWidth: 200,
        alignment: '01',
        dateStyle: '01',
        requiredFlag: false
    }
}

function getInitTableRelationList(){
    return [{tableId:null,fieldId:null,relation:null}]
}

function FieldDefinitionUpdateDialog(props) {
    const {close,optionsGroup, refresh, currentInfo, formQueryTypeOptions} = props
    const {mode, initData} = useMemo(()=> currentInfo || {} ,[currentInfo])
    const [fieldDbTypeOptions, fieldHtmlTypeOptions, fieldHtmlDetailTypeOptions, , fieldHtmlFilterTypeOptions,alignmentOptions, dateStyleOptions] = useMemo(()=> optionsGroup || [], [optionsGroup])
    const [params, setParams] = useState(initData || getInitParams())
    const [baseFormError, setBaseFormError] = useState()
    const [continueAdd, setContinueAdd] = useState(true)
    const {doFetch} = useApi()
    const [isRepeat, setIsRepeat] = useState(false)
    const [tableRelationList, setTableRelationList] = useState(_.isEmpty(_.get(initData,'tableRelations'))? getInitTableRelationList(): _.get(initData,'tableRelations'))
    const {data: tableRelationOptionsRes, doFetch: getTableRelationOptions} = useGet()
    const {data: relationOptionsRes } = useGet('/common/globalconst?globalConst=relation')

    const isCustomField = useMemo(()=>{
        return _.get(params, 'custom') === 'Y'
    },[params])

    const relationOptions = useMemo(()=>{
        if(_.isEmpty(relationOptionsRes)) return []
        return convertGlobalConstOptions(relationOptionsRes)
    },[relationOptionsRes])

    const tableRelationOptions = useMemo(()=>{
        return _.map(tableRelationOptionsRes, x => ({value: x.tableName, text: x.tableName}))
    },[tableRelationOptionsRes])

    const checkSubmit = useMemo(()=>{
        if(_.some(_.values(baseFormError),x=>x)) return false
        if(_.isNil(_.get(params,'displayFlag')) || _.isNil(_.get(params,'requiredFlag'))) return false
        if(isRepeat) return
        return true
    },[baseFormError,params, isRepeat])

    const submit = useCallback(()=>{
        const tableRelations = _.filter(tableRelationList, tableRelationItem => _.every(_.pick(tableRelationItem,['tableId','fieldId','relation']), x => !(_.isNil(x) || x === '')))
        const postParams = {
            ...params,
            tableRelations,
            action: mode === 'add' ? 'add' :'edit',
            displayFlag: _.get(params, 'displayFlag')?'Y':'N',
            requiredFlag: _.get(params, 'requiredFlag')?'Y':'N',
            fieldDateSource: isNil(_.trim(_.get(params, 'fieldDateDetailSource'))) ? null : '1',
            fieldDateDetailSource: _.trim(_.get(params, 'fieldDateDetailSource')),
        }
        doFetch('/FieldDefinition/maintain','post',postParams).then(res => {
            Messager.show("保存成功！", { icon: "success" });
            refresh()
            if( mode === 'add' && continueAdd){
                setParams(x => ({...x,...getInitParams(),tableName: x.tableName}))
                setTableRelationList(getInitTableRelationList())
            }else{
                close()
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[params, continueAdd, doFetch, close,refresh,mode,tableRelationList])

    useEffect(()=>{
        const tableName = params.tableName
        const fieldId = params.fieldId
        if(_.some([tableName,fieldId], x => _.isNil(x) || x === '')) return setIsRepeat(false)
        if(mode === 'edit' && tableName === initData.tableName && fieldId === initData.fieldId) return setIsRepeat(false)
        doFetch('/FieldDefinition/getFlag?'+strParams({tableName:tableName,fieldId:fieldId}),'get').then(res => {
            setIsRepeat(res === 'Y')
            if(res === 'Y'){
                setBaseFormError(x => ({...x, tableName: '表名 + 字段ID 已存在',fieldId: '表名 + 字段ID 已存在'}))
            }else{
                setBaseFormError(x => ({...x, tableName: null,fieldId: null}))
            }
        })
    },[params.tableName,params.fieldId,doFetch,initData,mode])

    useEffect(()=>{
        const tableName = params.tableName
        if(_.isNil(tableName) || tableName === '') return
        doFetch('/TableDefinition/getTableDefinition?tableId='+tableName,'get').then(res => {
            if(!_.isNil(res)){
                setParams(old => {
                    if(_.isNil(old['name']) || old['name'] === ''){
                        const newParams = _.clone(old)
                        newParams['name'] = res['tableName']
                        return newParams
                    }else{
                        return old
                    }
                })
            }
        })
    },[params.tableName,doFetch])

    useEffect(()=>{
        if(_.isNil(params.fieldDateDetailSource) || params.fieldDateDetailSource === ''){
            const fieldDateSource = params.fieldDateSource
            if(fieldDateSource === "2"){
                setParams(x => ({...x , fieldDateDetailSource: '/common/globalconst?globalConst='}))
            }
        }
    },[params.fieldDateSource,params.fieldDateDetailSource])

    const onTableRelationChange = useCallback((value, bind, index)=>{
        if(bind === 'tableId'){
            value['fieldId'] = null
        }
        setTableRelationList(x => {
            x[index] = value
            return _.clone(x)
        })
    },[])

    const onBaseFormChange = useCallback((value, bind)=>{
        if(bind === 'tableId'){
            setTableRelationList(getInitTableRelationList())
        }
        if(bind === 'fieldHtmlType'){
            if(value['fieldHtmlType'] === '5'){
                value['formQueryType'] = 'betweenAnd'
            }
        }
        setParams(value)
    },[])

    const delTableRelationItem = useCallback((index)=>{
        if(index === 0 && tableRelationList.length === 1){
            setTableRelationList(getInitTableRelationList())
        }else{
            setTableRelationList(x => {
                x.splice(index, 1)
                return _.clone(x)
            })
        }
    },[tableRelationList])

    useEffect(()=>{
        getTableRelationOptions('/FieldDefinition/getTableAndField')
    },[getTableRelationOptions])

    const getRelationOptions = useCallback((tableName)=>{
        if(_.isEmpty(tableRelationOptionsRes) || _.isNil(tableName)) return []
        const fieldGroup = _.get(_.find(tableRelationOptionsRes, x => x.tableName === tableName),'fields')
        return _.map(fieldGroup, x => ({value: x.fieldId, text: x.fieldId}))
    },[tableRelationOptionsRes])

    return <Dialog header={mode === 'add'?'新增':'修改'} className={'field-definition-update-dialog flex-y'}
                   confirmButtonDisabled={!checkSubmit} cancel={close} confirm={submit}>
        <Divider orientation="left">基本信息</Divider>
        <div className='red repeat-tableName-msg' style={{display: isRepeat ? '' : 'none'}}>表名 + 字段ID 已存在</div>
        <Form value={params} onChange={onBaseFormChange} error={baseFormError} onError={setBaseFormError}>
            <HFormInput bind={'custom'} label={'是否为自定义字段'} required component={RadioGroup} options={customOptions}/>
            <HFormInput component={Display} style={{visibility: 'hidden'}} componentWidth={480}/>
            <HFormInput bind='tableName' label={'表名'} required/>
            <HFormInput bind='name' label={'中文表名'} required/>
            <HFormInput bind='fieldId' label={'字段ID'} required/>
            {/*{isCustomField && <HFormInput component={Display} style={{visibility: 'hidden'}} componentWidth={200}/>}*/}
            <HFormInput bind='fieldName' label={'字段名称'} required/>
            {!isCustomField && <HFormInput bind='fieldDbType' label={'字段数据库类型'} required component={Select} search options={fieldDbTypeOptions}/>}
            {!isCustomField && <HFormInput bind='fieldDbSize' label={'字段数据库大小'} type='number'/>}
            {!isCustomField && <HFormInput bind='fieldHtmlType' label={'字段页面类型'} required search component={Select} options={fieldHtmlTypeOptions}/>}
            {!isCustomField && <HFormInput bind='fieldHtmlDetailType' label={'字段页面详细类型'} component={Select} search options={fieldHtmlDetailTypeOptions} clear/>}
            {!isCustomField && <HFormInput bind='accuracy' label={'精确到小数点后几位'} type='number'/>}
            {/*{!isCustomField && <HFormInput component={Display} style={{visibility: 'hidden'}}/>}*/}
            {/*{!isCustomField && <HFormInput bind='fieldDateSource' label={'字段数据源'} component={Select} search options={fieldDateSourceOptions} clear/>}*/}
            {!isCustomField && <HFormInput bind='fieldDateDetailSource' label={'字段详细数据源'} componentWidth={812} clear/>}
            {!isCustomField && <HFormInput bind='viewConditionType' label={'视图条件过滤类型'} required search component={Select} options={fieldHtmlFilterTypeOptions}/>}
            {!isCustomField && <HFormInput bind='formQueryType' label={'查询过滤类型'} required search component={Select} options={formQueryTypeOptions}/>}
            {!isCustomField && <HFormInput bind='queryFilter' label={'查询过滤值'}/>}
            <HFormInput bind='displayFlag' label={'是否在页面显示'} required component={RadioGroup} options={options}/>
            {!isCustomField && <HFormInput bind='requiredFlag' label={'是否必填'} required component={RadioGroup} options={options} labelWidth={216}/>}
            {!isCustomField && <HFormInput component={Display} style={{visibility: 'hidden'}} componentWidth={200}/>}
            <Divider orientation="left">样式信息</Divider>
            <HFormInput bind='columnWidth' label={'表格列宽'} required suffix={'像素'}/>
            <HFormInput bind='alignment' label={'列对齐方式'} required component={Select} options={alignmentOptions}/>
            {isCustomField && <HFormInput component={Display} style={{visibility: 'hidden'}} componentWidth={200}/>}
            {!isCustomField && <HFormInput bind='dateStyle' label={'日期样式'} component={Select} options={dateStyleOptions}/>}
            <HFormInput bind='labelWidth' label={'表单项标签宽度'} required suffix={'像素'}/>
            <HFormInput bind='contentWidth' label={'表单项内容组件宽度'} required suffix={'像素'}/>
        </Form>
        {!isCustomField && <Divider orientation="left">关联关系</Divider>}
        {
            !isCustomField && _.map(tableRelationList, (tableRelationItem, index) => (<div className={'table-relation-item flex center-y'} key={index}>
                <Form value={tableRelationItem} onChange={(value, bind) => onTableRelationChange(value, bind, index)}>
                    <HFormInput bind='tableId' label={'关联表'} component={Select} search clear options={tableRelationOptions}/>
                    <HFormInput bind='fieldId' label={'关联字段'} component={Select} search clear options={getRelationOptions(tableRelationItem['tableId'])}/>
                    <HFormInput bind='relation' label={'关联关系'} component={Select} search clear options={relationOptions}/>
                    <Icon name={'tianjia'} className={'table-relation-icon add-icon'} onClick={()=> setTableRelationList(x => _.concat(x,[{tableId:null,fieldId:null,relation: "1:1"}]))}/>
                    <Icon name={'shanjian'} className={'table-relation-icon del-icon'} onClick={()=>{delTableRelationItem(index)}}/>
                </Form>
            </div>))
        }
        <Divider/>
        {
            mode === 'add' &&
            <HFormInput label={'添加之后'} component={RadioGroup} options={continueOptions} value={continueAdd} onChange={setContinueAdd}/>
        }
    </Dialog>
}

export default FieldDefinitionUpdateDialog;
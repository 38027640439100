import React,{useEffect} from 'react';
import Echarts from 'echarts-for-react';
import _ from 'lodash';
import {P2} from 'rootnet-core/format';
import {Card} from 'rootnet-ui';
import { useGet } from "../../../utils/hook";

//完成度
function Bar(props) {
    const {data:{designRatio,devRation,testRatio,devOfDesignRatio,testOfDevRatio}} = props;

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: (res) => {
                return _.reduce(res,(acc,x)=>''.concat(acc,`${x.marker}${x.seriesName}：${P2(x.value)}<br/>`),`${_.get(res,'[0].axisValue')}<br/>`);
            }
        },
        legend: [{
            show: true,
            icon: "roundRect",
            itemWidth: 14,
            itemHeight: 14,
            itemGap: 40,
            textStyle: {
                padding: [2, 0, 0, 0],
            },
            top: 12,
        }],
        grid: {
            left: '5%',
            right: '14%',
            bottom: '3%',
            containLabel: true
        },
        color:['#FF9A54','#5477FF'],
        xAxis: {
            name:'完成度',
            type: 'value',
            boundaryGap: [0, 0.01],
            axisTick: { show: false },
            splitLine:{ show:false },
            axisLine: {
                lineStyle: {
                    type: 'dashed',
                }
            },
            axisLabel:{
                formatter: P2
            },
            max:1
        },
        yAxis: {
            type: 'category',
            data: ['设计','开发','测试'],
            nameGap: 20,
            axisTick: {
                show: false
            },
            nameTextStyle:{
                padding:[0,72,0,0]
            },
        },
        series: [
            {
                name: '基于上一环节完成度',
                type: 'bar',
                data: [null,devOfDesignRatio,testOfDevRatio],
                barMaxWidth:14,
                label: {
                    normal: {
                        position: 'right',
                        show: true,
                        formatter: (x)=>P2(x.data)
                    }
                },
            },{
                name: '总完成度',
                type: 'bar',
                data: [designRatio,devRation,testRatio],
                barMaxWidth:14,
                label: {
                    normal: {
                        position: 'right',
                        show: true,
                        formatter: (x)=>P2(x.data)
                    },
                },
            }
        ]
    };
    return <Echarts option={option} style={{height:240}}/>
}

export function Completeness(props) {
    const {projectId} = props;
    const {data,doFetch,loading,error} = useGet();

    useEffect(()=>{
        if(!_.isNil(projectId)){
            const url = `/develop/project/task/ratio?projectId=${projectId}`;
            doFetch(url);
        }
    },[projectId,doFetch]);

    // console.log(data);
    return <Card title='完成度' loading={loading} error={error}>
        {data && <Bar data={data}/>}
    </Card>
}
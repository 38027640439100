import React, { useState, useCallback } from 'react'
import { FormInput } from 'rootnet-edit'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import UserSelect from '../../../../common/personSelectPop/UserSelect'

const TRANSFER_MGT_URL = '/demoTxt/editManager'

export default function TransferMgtLog(props) {
  const { close, idList, refreshList, setCheckKeys } = props
  const [isDisable, setIsDisable] = useState(false)
  const [userId, setUserId] = useState()
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (isNil(userId)) return Messager.show('请选择管理人', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    doFetch(TRANSFER_MGT_URL, { idList, userId })
      .then((res) => {
        close()
        setIsDisable(false)
        refreshList()
        setCheckKeys([])
        Messager.show(res, { icon: 'info' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, idList, isDisable, userId, close, refreshList, setCheckKeys])

  return (
    <Dialog
      header='转移管理人'
      cancel={close}
      confirm={confirm}
    >
      <FormInput required horizontal label='管理人' component={UserSelect} value={userId} onChange={setUserId} labelWidth={65} componentWidth={200} />
    </Dialog>
  )
}

import React, { useMemo, useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Popover, Form, DatePicker, Input, Select, Button } from 'antd'
import { Icon } from '../../../../components'
import './FilterQuery.scss'

const ranges = {
  '本年': [moment().startOf('year'), moment().endOf('year')],
  '本季度': [moment().startOf('quarter'), moment().endOf('quarter')],
  '本月': [moment().startOf('month'), moment().endOf('month')],
}

export default function FilterQuery(props) {
  const { params, setParams, userOpt, taskDate, setTaskDate, search, setCurrentMonth } = props
  const [visible, setVisible] = useState(false)
  const [parameter, setParameter] = useState({})
  const [taskDat, setTaskDat] = useState({})

  const disabledDate = useCallback(current => {
    const { dates } = parameter || {}
    if (!dates || dates.length === 0) return false;
    const tooLate = dates[0] && current?.diff(moment(dates[0]), 'days') > 180;
    const tooEarly = dates[1] && moment(dates[1])?.diff(current, 'days') > 180;
    return tooEarly || tooLate;
  }, [parameter]);

  const onOpenChange = open => {
    if (open) setParameter(x => _.assign({}, x, { hackValue: [], dates: [] }))
    else setParameter(x => _.assign({}, x, { hackValue: undefined }))
  };

  const confirm = useCallback((par, date) => {
    setParams(par)
    search(par)
    setCurrentMonth(_.get(date, '[0]', null))
    setTaskDate({ beginDate: _.get(date, '[0]', null), endDate: _.get(date, '[1]', null) })
  }, [search, setCurrentMonth, setParams, setTaskDate])

  const condition = useMemo(() => {
    return <div className='condition'>
      <div className='content'>
        <Form layout="vertical">

          <Form.Item label="日期区间">
            <DatePicker.RangePicker
              size='middle'
              allowClear={false}
              style={{ width: 250 }}
              disabledDate={disabledDate}
              onOpenChange={onOpenChange}
              onChange={(v, d) => setParameter(x => _.assign({}, x, { date: d }))}
              ranges={ranges}
              onCalendarChange={(v, d) => { setParameter(x => _.assign({}, x, { dates: d })); setTaskDat(d) }}
              value={parameter?.hackValue || _.isEmpty(parameter.date) ? null : getMoment(_.get(parameter, 'date', null))}
            />
          </Form.Item>

          <Form.Item label="项目名称">
            <Input size='middle' allowClear placeholder='请输入项目名称' style={{ width: 250 }}
              value={parameter.projectName}
              onChange={e => { e.persist(); setParameter(x => _.assign({}, x, { projectName: e.target.value })) }}
            />
          </Form.Item>
          <Form.Item label="参与人">
            <Select
              size='middle'
              allowClear
              showSearch
              options={userOpt}
              value={parameter.userAccount}
              style={{ width: 250 }}
              placeholder='请选择参与人'
              optionFilterProp={'tag'}
              onChange={v => setParameter(x => _.assign({}, x, { userAccount: v }))}
            />
          </Form.Item>
        </Form>
      </div>
      <div className='footer'>
        <div>
          <Button size='small' onClick={close}>取消</Button>
          <Button size='small' type="primary" onClick={() => confirm(parameter, taskDat)}>确认</Button>
        </div>
      </div>
    </div>
  }, [confirm, disabledDate, parameter, taskDat, userOpt])

  useEffect(() => {
    if (visible) {
      setParameter(_.assign({}, params, { date: [_.get(taskDate, 'beginDate'), _.get(taskDate, 'endDate')] }))
      setTaskDat([_.get(taskDate, 'beginDate'), _.get(taskDate, 'endDate')])
    }
  }, [params, taskDate, visible])

  return (
    <Popover
      trigger="click"
      open={visible}
      content={condition}
      overlayClassName='filter-query'
      onOpenChange={(visible) => setVisible(visible)}
    >
      <div className='filter-query-text' style={{ cursor: 'pointer' }}>
        <Icon name='shaixuan' />过滤
        <span>{getParamsLen(params)}</span>
      </div>
    </Popover>
  )

  function close() {
    setTaskDat([])
    setVisible(false)
    setParameter({})
  }

}

function getParamsLen(data, date) {
  const size = _.size(_.compact(_.values(_.pick(data, ['date', 'projectName', 'userAccount']))))
  // const len = _.isEmpty(_.compact(_.values(date))) ? size : size + 1
  return size
}

function getMoment(date) {
  return [moment(_.get(date, '[0]', null)), moment(_.get(date, '[1]', null))]
}
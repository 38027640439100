import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../common/view/convertTableAlign'
import findConvert from '../../common/view/findConvert'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import { TextIconBtn } from '../../common/TextIconBtn'
import { API1 } from '../../../base/task'
import ViewArea from '../../common/view/ViewArea'
import { Box } from '../../common/commonComponent'
import { Icon } from '../../../components'
import MessageWithdrawLog from './controls/messageWithdrawLog'
import './index.scss'

const ENTER_DETAIL_FIELD = 'messageInfo.title'
const EDITABLE_FIELD = ['messageInfo.title']

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, handlePopId, setHandlePopId } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`

    if (!_.includes(EDITABLE_FIELD, tableField)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <Tooltip title={showValue}>
              <div className="enter-detail-field-text" onClick={() => {
                // setCurrentInfo({ mode: 'detail', id: o.id })
              }}>
                {showValue || '-'}
              </div>
            </Tooltip>
          </div>
        </div>
        <Popover
          trigger={'click'}
          placement={'bottom'}
          destroyTooltipOnHide={true}
          open={_.get(o, 'id') === handlePopId}
          onOpenChange={visible => {
            if (visible) setHandlePopId(_.get(o, 'id'))
            else setHandlePopId(null)
          }}
          content={
            <div className={'message-handle-popover flex-y'}>
              <div className='message-handle-item flex center-y' onClick={() => {
                setCurrentInfo({ mode: 'withdraw', id: o.id })
                setHandlePopId(null)
              }}>
                <div className="handle-text">撤回</div>
              </div>
            </div>
          }
        >
          <span>
            <Icon name={'gengduo'} className={'more-handle-icon'} />
          </span>
        </Popover>
      </div>
    }

  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '8877'

export default function MessageMgt() {
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const [handlePopId, setHandlePopId] = useState()
  const [currentInfo, setCurrentInfo] = useState(null)
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: {
      setCurrentInfo, handlePopId, setHandlePopId
    }
  })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    Messager.show('导出中...')
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [exportViewList, allowExport])

  return (
    <div className={'message-center-mgt fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'消息中心管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'withdraw' &&
        <MessageWithdrawLog
          id={_.get(currentInfo, 'id')}
          close={() => setCurrentInfo(null)}
          refresh={() => refreshList()}
        />
      }
    </div>
  )
}

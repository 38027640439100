import React from 'react';
import './IssueDetailDialog.scss'
import {Dialog} from 'rootnet-ui'
import IssueDetailContent from "./IssueDetailContent";

function IssueDetailDialog(props) {
    return <Dialog className={'issue-detail-dialog'} headerVisible={false} footerVisible={false}>
        <IssueDetailContent {...props}/>
    </Dialog>
}

export default IssueDetailDialog;
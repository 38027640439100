import React, {useCallback, useMemo} from 'react';
import './LeftPanel.scss'
import _ from "lodash";
import {Pagination} from 'antd'
import {uniqKeyFor} from "../../../../project_share/utils/utils";
import convertOptions from "../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function LeftPanel(props) {
    const {setParams, listRes, currentId, setCurrentId,fieldList,convertCollection,pageSize} = props
    const {total, pageNum, rows:list} = useMemo(()=>(listRes || {}),[listRes]);
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [workFlowStatusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const getWorkFlowColor = useCallback((status)=>{
        return convertOptions(status, workFlowStatusOptions, 'color') || '#A9A9A9'
    },[workFlowStatusOptions])

    const tableList = useMemo(()=>{
        if(_.isEmpty(list) || _.isEmpty(fieldList)) return []
        let convertField = {}
        _.forEach(fieldList, fieldItem => {
            convertField[fieldItem['columnId']] = `${fieldItem['tableName']}.${fieldItem['fieldId']}`
        })
        return _.map(list, listItem => {
            const newObj = {}
            _.forEach(convertField, (v, k) => {
                newObj[v] = listItem[k]
            })
            newObj['id'] = listItem['id']
            return newObj
        })
    },[list, fieldList])

    return <div className="defect-left-panel flex-y">
        <div className="list-area flex-y">
            {
                _.map(tableList, listItem => {
                    return <div className={`list-item flex-y ${currentId === _.get(listItem, 'id') ? 'active': ''}`}
                                key={uniqKeyFor()} onClick={()=> {
                        setCurrentId(listItem.id)
                    }}>
                        <div className="list-item-top flex center-y">
                            <div className="list-item-top-left flex center-y">
                                <div className="item-defect-id">{_.get(listItem, 'test_defect.defectId')}</div>
                            </div>
                            <div className="item-status flex-y center"
                                 style={{
                                     color: getWorkFlowColor(_.get(listItem, 'workflow_business.factorId')),
                                     borderColor: getWorkFlowColor(_.get(listItem, 'workflow_business.factorId')),
                                 }}
                            >{convertField(listItem, 'workflow_business.factorId',convertCollection)}</div>
                            {/*<div className="item-status">{convertField(listItem, 'test_defect.status',convertCollection)}</div>*/}
                        </div>
                        <div className="list-item-bottom">
                            {_.get(listItem, 'test_defect.title')}
                        </div>
                    </div>
                })
            }
        </div>
        <div className="pagination-area flex center">
            <Pagination pageSize={pageSize} total={total} current={pageNum} size="small" showSizeChanger={false}
                        onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>
        </div>
    </div>
}

function convertField(listItem, field, convertCollection){
    const value = _.get(listItem, field)
    const options = _.get(convertCollection,field)
    return convertOptions(value, options)
}

export default LeftPanel;
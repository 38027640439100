import React, { useState, useMemo, useCallback, useEffect, useReducer } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { Tabs, Tooltip } from 'antd'
import { Button, Messager, Switch } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Display, Input, Select } from 'rootnet-edit'
import { isNil } from '../../../../../../appraise/components/method'
import convertOptions from '../../../../../../common/ConvertOptions'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import ChangeRecord from '../../../../../../common/ChangeRecord'
import { Icon } from '../../../../../../../components'
import './index.scss'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'

const DETAIL_URL = '/customerLinker/new/info'
const ADD_URL = '/customerLinker/new/add'
const EDIT_URL = '/customerLinker/new/update'

const CUST_ID_URLS = '/story/getbugProjectName'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=customer_linker_range',
]

const HFormInput = props => <FormInput horizontal labelWidth={135} componentWidth={180} {...props} />

export default function InfoFormLogContent(props) {
  const { refreshViewList, currentInfo = {}, close, referenceId, initForm = {}, setInitFormData = () => { }, isEdit: isEdits
  } = props
  const { mode: initMode = 'detail' } = currentInfo
  const [mode, setMode] = useState(initMode)
  const [formData, setFormData] = useState(initForm)
  const [editFormData, setEditFormData] = useState(initForm)
  const [infoError, setInfoError] = useState()
  const [id, setId] = useState()
  const { doFetch: submitForm } = usePost()
  const { doFetch: getDetail } = useGet()
  const { data: custIdRes, doFetch: getCustId } = useGet()
  const { data: customerRes, doFetch: getCustomer } = useGet()
  const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const { tCustid, viewAuthType } = useMemo(() => editFormData || {}, [editFormData])

  const isDetail = useMemo(() => mode === 'detail', [mode])
  const isEdit = useMemo(() => mode === 'edit', [mode])
  const isAdd = useMemo(() => mode === 'add', [mode])

  const [dataPermissionOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const custIdOptions = useMemo(() => {
    if (_.isEmpty(custIdRes)) return []
    const list = _.map(_.groupBy(_.filter(custIdRes, o => !isNil(o.customer)), 'customer'), arr => _.head(arr))
    return _.map(list, x => ({ text: x.customerName, value: x.customer, supportUser: x.supportUse, tag: `${x.customerName}${x.customer}` }))
  }, [custIdRes])

  const customerOptions = useMemo(() => {
    if (_.isEmpty(customerRes) || _.isNil(tCustid)) return []
    return _.map(customerRes, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, tCustId: x.customer }))
  }, [customerRes, tCustid])

  const refreshDetail = useCallback(() => {
    getDetail(`${DETAIL_URL}?id=${id}`)
      .then(res => {
        const params = _.assign({}, res, { tCustid: res['tcustid'] })
        setFormData(params)
        setEditFormData(params)
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [getDetail, id])

  const refreshCustId = useCallback(() => {
    getCustId(CUST_ID_URLS)
  }, [getCustId])

  useEffect(() => {
    if (_.isNil(tCustid)) return
    getCustomer('/story/getbugProject?custId=' + tCustid)
  }, [tCustid, getCustomer])

  useEffect(() => {
    refreshCustId()
  }, [refreshCustId])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) setId(id)
  }, [currentInfo])

  useEffect(() => {
    if (!_.isNil(id)) {
      refreshDetail()
    }
  }, [id, refreshDetail])

  const handChange = useCallback((o, key) => {
    if (key === 'viewAuthType') {
      if (o[key] !== '02') {
        o['viewAuthRange'] = ''
      }
    }
    setEditFormData(o)
  }, [])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(infoError), x => x)
  }, [infoError])

  const submit = useCallback(() => {
    if (JSON.stringify(editFormData) === JSON.stringify(formData)) {
      setMode(x => x === 'detail' ? 'edit' : 'detail')
      return Messager.show('无变更')
    }

    submitForm(isEdit ? EDIT_URL : ADD_URL, { ...editFormData, id, referenceId })
      .then(() => {
        updateDynamic()
        setInitFormData(x => _.assign({}, x, {}, { linker: _.get(editFormData, 'linker'), custContactInfo: _.get(editFormData, 'phone') }))
        refreshDetail()
        refreshViewList()
        isEdit && setMode(x => x === 'detail' ? 'edit' : 'detail')
        isAdd && close()
        Messager.show(isEdit ? '修改成功' : '新增成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [editFormData, formData, submitForm, isEdit, id, refreshDetail, refreshViewList, referenceId, close, isAdd, setInitFormData])

  useEffect(() => {
    if (isNil(_.get(editFormData, 'phone'))) {
      setInfoError(x => _.assign({}, x, { phone: null }))
    }
  }, [editFormData])

  useEffect(() => {
    const isViewAuthType = _.get(editFormData, 'viewAuthType') === '02'
    setInfoError(x => _.assign({}, x, { viewAuthRange: isViewAuthType ? isNil(_.get(editFormData, 'viewAuthRange')) ? '必填项！' : null : null }))
  }, [editFormData])

  return (
    <div className='linker-info-form-log-content flex-y'>
      <div className="mock-dialog-header flex">
        <div className="dialog-title">
          {isAdd ? '新增联系人' : '详情'}
        </div>
        <div className="mock-right-header flex center-y">
          {
            (!isAdd || (!isAdd && isEdits)) &&
            <span>
              <TextIconBtn
                icon={'bianji2'}
                className={`header-edit-text-icon`}
                text={isDetail ? '进入编辑' : '退出编辑'}
                onClick={() => {
                  refreshDetail()
                  setMode(x => x === 'detail' ? 'edit' : 'detail')
                }} />
            </span>
          }
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>

      <div className='linker-info-form-main-panel'>
        <Tabs className={clsx({ 'linker-info-detail-tabs-add': isAdd })} items={[
          {
            label: '详情',
            key: 'detail',
            children: <>
              <Form value={editFormData} onChange={handChange} error={infoError} onError={setInfoError}>
                <HFormInput label={'联系人'} bind={'linker'} required component={isDetail ? Display : Input} />
                <HFormInput label={'职务'} bind={'post'} component={isDetail ? Display : Input} />
                <HFormInput label={'办公电话'} bind={'landline'} component={isDetail ? Display : Input} />
                <HFormInput label={'部门'} bind={'department'} component={isDetail ? Display : Input} />
                <HFormInput label={'手机号码'} bind={'phone'} type='tel' component={isDetail ? Display : Input} />
                <HFormInput label={'QQ'} bind={'qq'} type='number' component={isDetail ? Display : Input} />
                <HFormInput label={'邮箱'} bind={'email'} component={isDetail ? Display : Input} />
                <HFormInput
                  required
                  label={'客户'}
                  bind={'tCustid'}
                  component={isDetail ? Display : _.isEmpty(initForm) ? Select : Display}
                  options={custIdOptions}
                  convert={v => {
                    if (isNil(v)) return null
                    return convertOptions(v, custIdOptions)
                  }}
                  search
                />
                <HFormInput
                  search
                  multiple
                  label={'客户系统'}
                  bind={'customerId'}
                  component={isDetail ? Display : _.isEmpty(initForm) ? Select : Display}
                  options={customerOptions}
                  bindInConvert={v => {
                    if (_.isEmpty(v) || isNil(v)) return null
                    return _.split(v, ',')
                  }}
                  bindOutConvert={v => {
                    if (_.isEmpty(v) || isNil(v)) return ''
                    return _.join(v, ',')
                  }}
                  convert={join => {
                    if (isNil(join)) return null
                    const arr = _.split(join, ',')
                    if (_.isEmpty(arr)) return null
                    const str = _.map(arr, v => convertOptions(v, customerOptions))
                    return _.join(str, '，')
                  }}
                />
                {
                  (isEdit || isDetail) &&
                  <HFormInput
                    label={'状态'}
                    bind={'state'}
                    checked={_.get(editFormData, 'state') === 'Y'}
                    componentWidth={65}
                    component={isDetail ? Display : Switch}
                    checkedComponent='有效'
                    uncheckedComponent='无效'
                    bindOutConvert={v => v ? 'Y' : 'N'}
                    convert={v => {
                      if (isNil(v)) return '-'
                      if (v === 'Y') return '有效'
                      return '无效'
                    }}
                  />
                }
                <HFormInput label={'办公地址'} bind={'address'} component={isDetail ? Display : Input} componentWidth={511} />
                <HFormInput label={'客户平台数据权限'} bind={'viewAuthType'} component={isDetail ? Display : Select} componentWidth={511} options={dataPermissionOptions} convert={v => convertOptions(v, dataPermissionOptions)} />
                <Tooltip title='用于根网客户平台，控制客户可见服务单的数据范围配置'>
                  <span>
                    <Icon name='bangzhu' style={{ color: '#5477ff', fontSize: 14 }} />
                  </span>
                </Tooltip>
                <HFormInput required={viewAuthType === '02'} label={'客户平台部门权限'} bind={'viewAuthRange'} component={isDetail ? Display : Input} componentWidth={511} placeholder='部门和部门之间用英文逗号隔开' disabled={viewAuthType !== '02'} />
              </Form>
            </>,
          },
          {
            label: '动态',
            key: 'dynamic',
            children: <div className={'dynamic-wrap'} key={countDynamic}>
              <ChangeRecord funcCode='2020' referenceId={id} name={'客户联系人'} />,
            </div>
          }
        ]} />

      </div>

      <div className="mock-footer flex center-y">
        <div />
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              // switchMode(true)
              close()
              setEditFormData(formData)
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary onClick={submit} disable={!canSubmit} >确认</Button>
          }
        </div>
      </div>
    </div>
  )
}

import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Button, Loader, Messager } from 'rootnet-ui'
import { Form, FormInput, Display, Select } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../../utils/publicFun'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import { Icon } from '../../../../../../../components'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import convertOptions from '../../../../../../common/ConvertOptions'
import UserSelect from '../../../../../../common/personSelectPop/UserSelect'
import './index.scss'

const DETAIL_URL = '/story/getbugProjectSystem'
const ADD_URL = '/story/insertBugProjectSystem'
const EDIT_URL = '/story/updateBugProjectSystem'

const HFormInput = props => <FormInput horizontal labelWidth={80} componentWidth={180} {...props} />

export default function CustomerInfoDetailContent(props) {
  const { close, currentInfo, allUserRes, systemId, refreshViewList, customerOptions, statusOptions } = props
  const { mode: initMode = 'detail' } = currentInfo
  const [id, setId] = useState()
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [infoError, setInfoError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [mode, setMode] = useState(initMode)
  const { doFetch: getDetail, loading } = useGet()
  const { doFetch } = usePost()

  const isAdd = useMemo(() => mode === 'add', [mode])
  const isDetail = useMemo(() => mode === 'detail', [mode])

  const refreshDetail = useCallback(() => {
    if (isAdd) { }
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?${strParams({ id })}`)
      .then(res => {
        const params = _.head(res)
        setFormData(params)
        setEditFormData(params)
        setId(params.id)
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [id, isAdd, getDetail])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(infoError), x => x)
  }, [infoError])

  const submit = useCallback(() => {
    if (!isAdd && JSON.stringify(formData) === JSON.stringify(editFormData)) {
      setMode('detail')
      return Messager.show('无变更')
    }
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.assign({}, editFormData, isAdd && { systemId })
    doFetch(isAdd ? ADD_URL : EDIT_URL, postParams)
      .then(() => {
        Messager.show(isAdd ? '新增成功' : '修改成功', { icon: 'success' })
        refreshDetail()
        refreshViewList()
        setMode('detail')
        setIsDisable(false)
        isAdd && close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, doFetch, isAdd, refreshDetail, refreshViewList, close, formData, editFormData, systemId])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) setId(id)
  }, [currentInfo])

  useEffect(() => {
    refreshDetail()
  }, [refreshDetail])

  return (
    <div className='customer-info-detail-content flex-y'>
      {!isAdd && loading && <Loader fill />}
      <div className="mock-dialog-header flex">
        <div className="dialog-title flex center-y">
          {isAdd && '新增'}
          {isDetail && '详情'}
          {(!isAdd && !isDetail) && '修改'}
        </div>
        <div className="mock-right-header flex center-y">
          {
            (!isAdd) &&
            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
              refreshDetail()
              setMode(x => x === 'detail' ? 'edit' : 'detail')
            }} />
          }
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>

      <div className="customer-info-detail-main-panel">
        <Form value={editFormData} onChange={setEditFormData} error={infoError} onError={setInfoError}>
          <HFormInput search label={'客户名称'} bind={'customer'} required component={isDetail ? Display : Select} options={customerOptions} convert={v => convertOptions(v, customerOptions)} />
          <HFormInput label={'状态'} bind={'status'} required component={isDetail ? Display : Select} options={statusOptions} convert={v => convertOptions(v, statusOptions)} />
          <HFormInput label={'客服人员'} bind={'customerService'} component={isDetail ? Display : UserSelect} allowClear
            convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
          {
            !isAdd &&
            <>
              <HFormInput label={'创建人'} bind={'createUser'} component={Display}
                convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
              <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
                convert={v => {
                  if (_.isNil(v)) return '-'
                  if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                  return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                }} />
              <HFormInput label={'修改人'} bind={'updateUser'} component={Display}
                convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
              <HFormInput label={'修改时间'} bind={'updateTime'} component={Display}
                convert={v => {
                  if (_.isNil(v)) return '-'
                  if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                  return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                }} />
            </>
          }
        </Form>
      </div>

      <div className="mock-footer flex center-y">
        <div />
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              close()
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary disable={!canSubmit} onClick={submit} >确认</Button>
          }
        </div>
      </div>
    </div>
  )
}

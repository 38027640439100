import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import BasicInformation from './basicInformation'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import { pathSearchFor, strParams } from '../../../../../../utils/publicFun'
import gd from '../../../../../../base/global'
import VersionAssociated from './versionAssociated'
import './index.scss'

const { TabPane } = Tabs

export default function VersionSetting(props) {
  const { currentId, isEditCode, allUserRes, setMulcustId, versionOpt, history, secondOrder = 'basicInformation', firstOrder, setPrincipal, principal, updateDynamic, setProductId, setSnowId, setState, setPoUser, setDetailData, detailData, count, versionCheck, setVersionCheck, checkOpen, setCheckOpen, isShow, isALL, isPMM, isCM, upDateDefect = () => { }, isQA } = props
  const [mode, setMode] = useState('detail')
  const [versionMode, setVersionMode] = useState('detail')
  const [activeTab, setActiveTab] = useState(secondOrder)
  const { secondOrder: secondOrders } = useMemo(() => pathSearchFor(_.get(history.location, 'search')), [history])

  useEffect(() => {
    if (count) { }
    setActiveTab(_.clone(secondOrders))
  }, [count, secondOrders])

  const tabBarExtraContent = useMemo(() => {
    return {
      right: <div style={{ textAlign: 'right', paddingRight: 16 }}>
        {
          secondOrder === 'basicInformation' && <div className='flex'>
            <TextIconBtn style={{ display: 'inline-block' }} icon='bianji2' text={mode === 'detail' ? '进入编辑' : '退出编辑'} onClick={() => setMode(x => x === 'detail' ? 'edit' : 'detail')} />
          </div>
        }
        {
          (secondOrder === 'versionAssociated' && !isQA) && <TextIconBtn style={{ display: 'inline-block' }} icon='bianji2' text={versionMode === 'detail' ? '进入编辑' : '退出编辑'} onClick={() => setVersionMode(x => x === 'detail' ? 'edit' : 'detail')} />
        }
      </div>
    }
  }, [isQA, mode, secondOrder, versionMode])

  const isEdit = useMemo(() => {
    if (isShow) return (isEditCode || gd.User.operator_id === principal)
    return (isALL || isPMM || isCM)
  }, [isShow, isALL, isPMM, isCM, isEditCode, principal])

  return (
    <div className='version-setting'>
      <Tabs centered activeKey={activeTab} tabBarExtraContent={(isEdit || isQA) ? tabBarExtraContent : {}}
        onChange={v => {
          if (versionCheck) return setCheckOpen(true)
          history.replace({ pathname: '/versionMsg', search: strParams({ initId: currentId, firstOrder, secondOrder: v, }) })
          setActiveTab(v)
        }}
      >
        <TabPane forceRender tab="基本信息" key="basicInformation">
          <BasicInformation {...{ currentId, mode, setMode, isEditCode, allUserRes, setMulcustId, versionOpt, setPrincipal, updateDynamic, setProductId, setSnowId, setState, setPoUser, setDetailData, upDateDefect }} />
        </TabPane>
        <TabPane tab="版本关联" key="versionAssociated">
          <VersionAssociated {...{
            currentId, detailData, versionMode, setVersionMode, setVersionCheck, checkOpen,
            setCheckOpen,
          }} />
        </TabPane>
      </Tabs>
    </div>
  )
}

import React from 'react'
import { Dialog } from 'rootnet-ui'

const association = {
  'demand': '需求',
  'tracking': '研发任务',
  'defect': '缺陷',
}

export default function Disassociation(props) {
  const { submitAssociation, setDisassociation, disassociation } = props
  return (
    <Dialog
      header='取消关联'
      confirm={confirm}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0 }}
      cancel={() => setDisassociation(null)}
    >
      确认解除与该{association[disassociation.type]}关联关系？
    </Dialog>
  )
  function confirm() {
    const { type, data } = disassociation
    submitAssociation(type, data)
    setDisassociation(null)
  }
}

import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Card } from 'rootnet-ui';
import { Badge, Tabs } from 'antd'
import './WorkplaceTodo.scss'
import { Icon } from "../../../project_share/components";
import _ from "lodash";
import clsx from "clsx";
import WorkplaceViewCommonTemplate from "./workplaceViewCommonTemplate/WorkplaceViewCommonTemplate";
import { useGet } from "rootnet-biz/es/hooks";
import convertOptions from "../../common/ConvertOptions";
import gd from '../../../base/global';

const headerItems = [
    { label: '我的待办', key: '01' },
    { label: '我的已办', key: '02' },
    { label: '我创建的', key: '03' },
]

function WorkplaceTodo(props) {
    const { fullScreenCard, onSwitchFullScreen } = props
    const [activeModule, setActiveModule] = useState('req')
    const [activeSearch, setActiveSearch] = useState('01')
    const [count, forceUpdate] = useReducer((x) => x + 1, 0)

    const { data: moduleTotalNum, doFetch: getModuleTotalNum } = useGet()

    const caseAppendObj = useMemo(()=>{
        return {factorViewSql: `View_test_plan_case_num.principle = '${_.get(gd,'User.operator_id')}'`}
    },[])

    const refreshModuleTotalNum = useCallback(() => {
        getModuleTotalNum('/workBench/total?type=' + _.toNumber(activeSearch)+'&leftType=case')
        .then(res => {
         const items = _.compact(['req', 'issue', 'defect', activeSearch === '01' && 'case', 'tracer', 'stage', 'todo', 'customer', 'voice'])
         const filterData = _.filter(items, v => {
            const findItme = _.find(res,o => (v === o.type && o.total > 0))
            return _.get(findItme, 'type')
         })
         setActiveModule(x => {
            if(_.isEmpty(filterData) || _.includes(filterData, x)) return x
            return _.head(filterData)
         })
        })
    }, [getModuleTotalNum, activeSearch])

    useEffect(() => {
        refreshModuleTotalNum()
    }, [refreshModuleTotalNum])

    const getModuleNum = useCallback((module) => {
        if (_.isNil(moduleTotalNum)) return 0
        return convertOptions(module, moduleTotalNum, 'total', 'type') || 0
    }, [moduleTotalNum])

    const extra = useMemo(() => {
        return <div className={'extra-wrap flex center-y'}>
            <Icon name={'shuaxin1'} className={'can-click-icon handle-icon'} onClick={() => {
                forceUpdate()
                refreshModuleTotalNum()
            }} />
            <Icon name={fullScreenCard === 'todo' ? 'suoxiao' : 'fangda'} className={'can-click-icon handle-icon'} onClick={() => onSwitchFullScreen('todo')} />
        </div>
    }, [fullScreenCard, onSwitchFullScreen, refreshModuleTotalNum])

    const tabItems = useMemo(() => {
        return _.compact([
            getModuleNum('req') > 0 && {
                label: <Badge count={getModuleNum('req')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#5477FF' }}>REQ</div>
                        <span className={'module-name'}>需求</span>
                    </div>
                </Badge>, key: 'req',
                children: <WorkplaceViewCommonTemplate funcCode={'9001'} workflowFuncCode={'1501'} enterDetailField={'story.title'}
                    module={'req'} replaceViewId={'9001' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            getModuleNum('issue') > 0 &&{
                label: <Badge count={getModuleNum('issue')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#FF5454' }}>ISSUE</div>
                        <span className={'module-name'}>ISSUE</span>
                    </div>
                </Badge>, key: 'issue',
                children: <WorkplaceViewCommonTemplate funcCode={'9002'} workflowFuncCode={'19'} enterDetailField={'ProductionIssue.ShortDesc'}
                    module={'issue'} replaceViewId={'9002' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            getModuleNum('defect') > 0 && {
                label: <Badge count={getModuleNum('defect')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#EE631D' }}>BUG</div>
                        <span className={'module-name'}>缺陷</span>
                    </div>
                </Badge>, key: 'defect',
                children: <WorkplaceViewCommonTemplate funcCode={'9003'} workflowFuncCode={'0504'} enterDetailField={'test_defect.title'}
                    module={'defect'} replaceViewId={'9003' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            (activeSearch === '01' && getModuleNum('case') > 0) && {
                label: <Badge count={getModuleNum('case')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#009EFF' }}>CASE</div>
                        <span className={'module-name'}>用例</span>
                    </div>
                </Badge>, key: 'case',
                children: <WorkplaceViewCommonTemplate funcCode={'9010'} workflowFuncCode={'0501'} enterDetailField={'test_plan.planName'}
                    module={'case'} replaceViewId={'9010' + activeSearch} afterViewRefresh={refreshModuleTotalNum} appendObj={caseAppendObj} />
            },
            getModuleNum('tracer') > 0 && {
                label: <Badge count={getModuleNum('tracer')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#5C595B' }}>TASK</div>
                        <span className={'module-name'}>研发任务</span>
                    </div>
                </Badge>, key: 'tracer',
                children: <WorkplaceViewCommonTemplate funcCode={'9004'} workflowFuncCode={'26'} enterDetailField={'bug_tracer.tracerTitle'}
                    module={'tracer'} replaceViewId={'9004' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            getModuleNum('stage') > 0 && {
                label: <Badge count={getModuleNum('stage')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#5C595B' }}>TASK</div>
                        <span className={'module-name'}>阶段任务</span>
                    </div>
                </Badge>, key: 'stage',
                children: <WorkplaceViewCommonTemplate funcCode={'9005'} workflowFuncCode={'1526'} enterDetailField={'story_stage.title'}
                    module={'stage'} replaceViewId={'9005' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            getModuleNum('todo') > 0 && {
                label: <Badge count={getModuleNum('todo')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#6CC7FF' }}>TODO</div>
                        <span className={'module-name'}>跟进事项</span>
                    </div>
                </Badge>, key: 'todo',
                children: <WorkplaceViewCommonTemplate funcCode={'9006'} workflowFuncCode={'23'} enterDetailField={'todoListRecord.subject'}
                    module={'todo'} replaceViewId={'9006' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            getModuleNum('customer') > 0 && {
                label: <Badge count={getModuleNum('customer')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#AAB9F7' }}>CS</div>
                        <span className={'module-name'}>客户服务</span>
                    </div>
                </Badge>, key: 'customer',
                children: <WorkplaceViewCommonTemplate funcCode={'9007'} workflowFuncCode={'20'} enterDetailField={'CustomerServiceInfo.IssueDesc'}
                    module={'customer'} replaceViewId={'9007' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
            getModuleNum('voice') > 0 && {
                label: <Badge count={getModuleNum('voice')} size="small" offset={[12, 0]}>
                    <div className={'flex center-y'}>
                        <div className="tab-tag flex center" style={{ background: '#F38FCF' }}>VOICE</div>
                        <span className={'module-name'}>客户声音</span>
                    </div>
                </Badge>, key: 'voice',
                children: <WorkplaceViewCommonTemplate funcCode={'9009'} workflowFuncCode={'0851'} enterDetailField={'customer_voice.title'}
                    module={'voice'} replaceViewId={'9009' + activeSearch} afterViewRefresh={refreshModuleTotalNum} />
            },
        ])
    }, [activeSearch, getModuleNum, refreshModuleTotalNum, caseAppendObj])

    return <div className={clsx('workplace-todo flex-y', { 'hidden-card': !_.isNil(fullScreenCard), 'show-card': fullScreenCard === 'todo' })}>
        <Card title={<Tabs items={headerItems} activeKey={activeSearch} onChange={setActiveSearch} />}
            extra={extra} className='x-card-singlegrid'>
            <div className={'workplace-todo-wrap'} key={count}>
                <Tabs items={tabItems} activeKey={activeModule} tabPosition={'left'} onChange={setActiveModule} />
            </div>
        </Card>
    </div>
}

export default WorkplaceTodo;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DatePicker, Form, FormInput, Select} from "rootnet-edit";
import {Button, DataGrid, Pagination, Messager, MessageBox} from 'rootnet-ui'
import './common.scss'
import {Box} from "../../../common/commonComponent";
import OperationList from "../../../../project_share/components/OperationList";
import {Icon} from "../../../../components";
import {useGet} from "../../../../utils/hook";
import {pathSearchFor, strParams} from "../../../../utils/publicFun";
import {toDate, dateFormat} from 'rootnet-core/dateFormat'
import {Cache} from "../../../../base/cache";
import _ from "lodash";
import DetailsGoBackWithTextClick from "../../../common/DetailsGoBackWithTextClick";

const { str14ToDate } = toDate;
const CACHE_KEY = 'createView'
const CURRENT_CACHE_KEY = 'issueList'
const PRIMARY_KEY = 'issueId'

function weekFirstEnd(){
    // 上周
    const firstDay = new Date(Date.now() - 1000*60*60*24*7)
    // const firstDay = new Date(Date.now()-(new Date().getDay()+720)*1000*60*60*24)
    // const endDay = new Date(Date.now()-(new Date().getDay())*1000*60*60*24)
    const endDay = new Date(Date.now())
    return {
        begin: firstDay,
        end: endDay
    }
}

const getColumns = (props) => {
    const {statusOptions, setAddedList, effectGradeOptions,kindOptions} = props
    return [
        {selection:true},
        {header: '问题编号', bind: 'issueId',sortable:true,width: 160, tooltip: true},
        {header: '发生日期', bind: 'occurDate',sortable:true,width: 100, convert: convertDate},
        {header: '问题简单描述', bind: 'shortDesc',sortable:true,width: 250, tooltip: true},
        {header: '分类标记', bind: 'issuesType',sortable:true,width: 125, tooltip: true},
        {header: '问题状态', bind: 'issueStatus',sortable:true,width: 100, tooltip: true, convert: v => convertOptions(v,statusOptions)},
        {header: '修复问题研发任务', bind: 'tracerId',sortable:true,width: 160, tooltip: true},
        {header: '严重程度', bind: 'effectGrade',sortable:true,width: 80, convert: v => convertOptions(v,effectGradeOptions)},
        {header: '问题性质', bind: 'issueKindArrays',sortable:true,width: 150, tooltip: true, convert: convertIssueKind},
        {header: '产品名称', bind: 'productName',sortable:true,width: 100, tooltip: true},
        {header: '客户', bind: 'customerName',sortable:true,width: 85, tooltip: true},
        {header: '系统', bind: 'custIdName',sortable:true,width: 85, tooltip: true},
        {header: '负责人', bind: 'personChargeName',sortable:true,width: 90},
        {header: '分析人', bind: 'principalName',sortable:true,width: 90},
        {header: '记录日期', bind: 'inputDate',sortable:true,width: 100, convert: convertDate},
        {header: '操作', bind: '', align: 'center', width: 90, isTransfer: false, convert: renderOperationWrap}
    ]

    function convertIssueKind(arr){
        return _.map(arr, v => convertOptions(v,kindOptions)).join(',')
    }

    function renderOperationWrap(v, o){
        const all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const notInCache = _.isNil(_.find(currentCache, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]))
        if(notInCache){
            return renderAddOperation(v, o)
        }else{
            return renderDelOperation(v, o)
        }
    }
    function renderAddOperation(v, o){
        const addOption = [{
            text: '添加',
            onClick: ()=>addCache(o)
        }]
        return <OperationList options={addOption}/>;
    }

    function renderDelOperation(v, o){
        const disableOption = [{
            text: '已添加',
            disabled: true
        }]
        const delOption = [{
            text: '移除',
            onClick: ()=>delCache(o)
        }]
        return <div className='del-operation-group'>
            <OperationList className='added-operation-btn' options={disableOption}/>
            <OperationList className='del-operation-btn' options={delOption}/>
        </div>
    }

    function delCache(o){
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        all[CURRENT_CACHE_KEY] = _.filter(currentCache, x => x[PRIMARY_KEY] !== o[PRIMARY_KEY])
        Cache.set(CACHE_KEY, all)
        setAddedList(all[CURRENT_CACHE_KEY])
    }

    function addCache(o){
        let all = Cache.get(CACHE_KEY)
        if(_.isNil(all)) all = {}
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        if(_.isNil(currentCache)) return add([o])
        const notInCache = _.isNil(_.find(currentCache, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]))
        return notInCache?add(all[CURRENT_CACHE_KEY].concat(o)):Messager.show('已经在添加列表中',{icon:'error'})
        function add(list){
            all[CURRENT_CACHE_KEY] = list
            Cache.set(CACHE_KEY, all)
            setAddedList(all[CURRENT_CACHE_KEY])
            return Messager.show('已添加',{icon:'success'})
        }
    }

    function convertOptions(v, options){
        return _.get(_.find(options, x => x.value === v),'text')
    }

    function convertDate(v){
        return dateFormat('YYYY-MM-DD', str14ToDate(v))
    }
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedLeft: 1,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
});

const DFormInput = (props) => <FormInput componentWidth={180} {...props}/>

const INIT_PARAMS = {
    issueId:null,
    shortDesc:null,
    productID:null,
    issueStatus:null,
    issuesType:null,
    effectGrade:null,
    issueKind:null,
    pageNum: 1,
    pageSize: 20,
    occurDate: weekFirstEnd(),
    occurBeginDate: dateFormat('YYYY-MM-DD', weekFirstEnd().begin),
    occurEndDate: dateFormat('YYYY-MM-DD', weekFirstEnd().end),
}

const CONVERT_OPTIONS_URLS = [
    '/common/globalconst?globalConst=ISSUESTATUS',
    '/common/globalconst?globalConst=EffectGrade',
    '/common/globalconst?globalConst=ISSUEKIND',
]

function CreateIssueView(props) {
    const {location} = props;
    const pathParams = useMemo(() => pathSearchFor(location.search), [location]);
    const {scene = 'add'} = pathParams
    const [params, setParams] = useState(INIT_PARAMS)
    const [selectedList, setSelectedList] = useState([])
    const {data: listRes, doFetch: getList, loading, error} = useGet()
    const [addList,setAddedList] = useState([])
    const {data: convertOptionsRes} = useGet(CONVERT_OPTIONS_URLS)
    const [beforeSelectedList, setBeforeSelectedList] = useState([])
    const [showLeaveDialog, setShowLeaveDialog] = useState(false)
    const [isSaved, setIsSaved] = useState(true)

    const [statusOptions, effectGradeOptions,kindOptions] = useMemo(()=>{
        const [d1, d2, d3] = convertOptionsRes || []
        const statusOptions = _.map(d1, x => ({value: x.interiorId, text: x.displayName}))
        const effectGradeOptions = _.map(d2, x => ({value: x.interiorId, text: x.displayName}))
        const kindOptions = _.map(d3, x => ({value: x.interiorId, text: x.displayName}))
        return [statusOptions, effectGradeOptions,kindOptions]
    },[convertOptionsRes])

    useEffect(()=>{
        const getParams = _.omit(params,['occurDate','inputDate'])
        getList('/viewDetail/viewItemsVerIssue?'+strParams(getParams))
    },[params, getList])

    const { pageSize, total, pageNum, list } = useMemo(()=>(listRes || {}),[listRes]);

    useEffect(()=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const keyList = _.map(currentCache, x => x[PRIMARY_KEY])
        const selected = _.filter(list, x => _.includes(keyList, x[PRIMARY_KEY]))
        setSelectedList(selected)
        setBeforeSelectedList(selected)
    },[addList,list])

    const optionsRes = useMemo(()=>{
        return {
            statusOptions,effectGradeOptions,kindOptions
        }
    },[statusOptions,effectGradeOptions,kindOptions])

    const option = useMemo(()=>{
        return getOption(getColumns({setAddedList, ...optionsRes}))
    },[optionsRes])

    const addCacheList = useCallback((list)=>{
        let all = Cache.get(CACHE_KEY)
        if(_.isNil(all)) all = {}
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        add(_.isNil(currentCache)?list:[...all[CURRENT_CACHE_KEY],...list])

        function add(addList){
            all[CURRENT_CACHE_KEY] = addList
            Cache.set(CACHE_KEY, all)
            setAddedList(all[CURRENT_CACHE_KEY])
        }
    },[])

    const delCacheList = useCallback((list)=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const delKeyList = _.map(list, x => x[PRIMARY_KEY])
        all[CURRENT_CACHE_KEY] = _.filter(currentCache, x =>  !_.includes(delKeyList, x[PRIMARY_KEY]))
        Cache.set(CACHE_KEY, all)
        setAddedList(all[CURRENT_CACHE_KEY])
        Messager.show('已保存',{icon:'success'})
    },[])

    const handleSelected = useCallback((list)=>{
        const beforeKeyList = _.map(beforeSelectedList, x => x[PRIMARY_KEY])
        const afterKeyList = _.map(list, x => x[PRIMARY_KEY])
        const addList = _.filter(list, x => !_.includes(beforeKeyList, x[PRIMARY_KEY]))
        const delList = _.filter(beforeSelectedList, x => !_.includes(afterKeyList, x[PRIMARY_KEY]))
        addCacheList(addList)
        delCacheList(delList)
        setIsSaved(true)
    },[beforeSelectedList,addCacheList,delCacheList])


    const extra = useMemo(()=>{
        return <>
            <Button primary onClick={()=>handleSelected(selectedList)}>保存</Button>
        </>
    },[selectedList,handleSelected])

    useEffect(()=>{
        const all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const keyList = _.map(currentCache, x => x[PRIMARY_KEY])
        const selected = _.filter(list, x => _.includes(keyList, x[PRIMARY_KEY]))
        setBeforeSelectedList(selected)
        setSelectedList(selected)
        setAddedList(selected)
    },[list])


    return  <div className='create-project-view flex-y fill create-view-list'>
        <DetailsGoBackWithTextClick onClick={handleClickLeave} />
        <Options initParams={params} search={setParams} {...optionsRes}/>
        <Box title='ISSUE清单' className='flex-y x-card-singlegrid' data={list} extra={extra} loading={loading} error={error}>
            <DataGrid option={option} data={list} selection={selectedList} onSelectionChange={list=>{setSelectedList(list);setIsSaved(false)}}/>
            <Pagination pageSize={ pageSize } total={ total } current={ pageNum }
                        onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>
        </Box>
        {
            showLeaveDialog &&
            <MessageBox header='提示' confirm={goBack} cancel={()=>setShowLeaveDialog(false)}>
                当前内容未保存，确定离开吗？
            </MessageBox>
        }
    </div>

    function handleClickLeave(){
        if(isSaved) return goBack()
        setShowLeaveDialog(true)
    }
    function goBack(){
        const url = scene === 'add' ? '/createView?' : '/projectView/edit?'
        props.history.push(url + strParams(pathParams))
    }
}

const OPTION_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/common/globalconst?globalConst=ISSUESTYPE',
]

function Options(props){
    const {initParams, search, statusOptions, effectGradeOptions,kindOptions} = props;
    const [params, setParams] = useState(initParams);
    const [isExpand, setIsExpand] = useState(true)
    const {data: optionsRes} = useGet(OPTION_URLS)

    const [productOptions,typeOptions] = useMemo(()=>{
        const [d1, d2, d3] = optionsRes || []
        const productOptions = _.map(_.groupBy(d1,product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            children: _.map(productLineItem, x => ({value: x.productId, text: x.productName}))
        }))
        const typeOptions = _.map(d3, x => ({value: x.interiorId, text: x.interiorId}))
        return [productOptions,typeOptions]
    },[optionsRes])

    return <div className='options'  style={{height: isExpand ? 144 : 72}}>
        <div className="c-options no-margin-top">
            <Form value={params} onChange={obj => setParams(x => ({...x,...obj}))}>
                <DFormInput bind="issueId" label='问题编号'/>
                <DFormInput bind="shortDesc" label='问题简单描述'/>
                <DFormInput bind="productId" label="产品" component={Select} search tree multiple clear options={productOptions}/>
                <DFormInput bind="occurDate" label="发生日期" component={DatePicker} range componentWidth={200}/>
            </Form>
            <Icon name='tiaojianshouqi' className='expand-icon'
                  onClick={()=>setIsExpand(x => !x)} style={{transform: isExpand ? 'rotate(90deg)' : 'rotate(-90deg)'}}/>
            <Button primary onClick={convertSearch} className='btn'>查询</Button>
            <Button text onClick={() => {
                setParams(initParams)
                search(initParams)
            }} className='btn reset-btn'>重置</Button>
        </div>
        <div className="c-options">
            <Form value={params} onChange={obj => setParams(x => ({...x,...obj}))}>
                <DFormInput bind="issueStatus" label="状态" component={Select} clear multiple options={statusOptions}/>
                <DFormInput bind="issuesType" label="分类标记" component={Select} clear multiple options={typeOptions}/>
                <DFormInput bind="effectGrade" label="严重程度" component={Select} clear multiple options={effectGradeOptions}/>
                <DFormInput bind="issueKind" label="问题性质" component={Select} clear multiple componentWidth={200} options={kindOptions}/>
                <DFormInput bind="inputDate" label="记录日期" component={DatePicker} clear range componentWidth={200}/>
            </Form>
        </div>
    </div>

    function convertSearch(){
        const occurBeginDate = _.get(params, 'occurDate.begin')
        const occurEndDate = _.get(params, 'occurDate.end')
        const inputBeginDate = _.get(params, 'inputDate.begin')
        const inputEndDate = _.get(params, 'inputDate.end')
        const dateParams = {
            occurBeginDate: convertDate(occurBeginDate),
            occurEndDate: convertDate(occurEndDate),
            inputBeginDate: convertDate(inputBeginDate),
            inputEndDate: convertDate(inputEndDate),
        }
        search({...params,...dateParams})
    }

    function convertDate(v){
        return v?dateFormat('YYYY-MM-DD', v):null
    }
}

export default CreateIssueView;
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Dialog, Messager, Transfer} from 'rootnet-ui'
import {Display, FormInput, CheckBox} from "rootnet-edit";
import './ViewUpdateDialog.scss'
import _ from 'lodash'
import {uniqKeyFor} from "../../../project_share/utils/utils";
import {useGet} from "rootnet-biz/es/hooks";
import CheckConditionGroup from "./checkConditionGroup/CheckConditionGroup";
import usePost from "rootnet-biz/es/hooks/usePost";
import {isNil} from "../../appraise/components/method";

const HFormInput = (props) => <FormInput horizontal labelWidth={120} componentWidth={590} {...props}/>

function ViewUpdateDialog(props) {
    const {updatePopInfo, setUpdatePopInfo,funcCode,closeViewPop,setCurrentViewId,allColumns,refresh,systemViewEditAuth, currentViewId, resetParams} = props
    const currentViewIdRef = useRef({id:currentViewId,isUpdate:false,count:0})
    const {mode, initViewInfo = null} = updatePopInfo

    useEffect(()=>{
        if(currentViewIdRef.current.isUpdate){
            if(currentViewIdRef.current.id === currentViewId) refresh()
        }
    },[currentViewId,refresh,currentViewIdRef.current.count])

    const {data: nowViewInfo, doFetch: getNowViewInfo} = useGet()
    const [title, setTitle] = useState()
    const [factor, setFactor] = useState()
    const [showColumns, setShowColumns] = useState([])
    const {doFetch: submitPost} = usePost()
    const {data: titleListRes, doFetch: getTitleList} = useGet()
    const {data: sysTitleListRes, doFetch: getSysTitleList} = useGet()

    // 勾选框
    const [isSystemView, setIsSystemView] = useState(false)
    const [isDefaultView, setIsDefaultView] = useState(false)

    useEffect(()=>{
        const {subType: initTitle = null, factor: initFactor = '', isSystemView: initIsSystemView = false, isDefaultView:initIsDefaultView = false} = nowViewInfo || {subType: null, factor: null}
        if(mode === 'edit'){
            setTitle(initTitle)
        }
        setFactor(isNil(initFactor)?null:JSON.parse(initFactor))
        setIsSystemView( systemViewEditAuth && initIsSystemView)
        setIsDefaultView(systemViewEditAuth && initIsDefaultView)
    },[nowViewInfo, mode, systemViewEditAuth])

    const [initTitle, initResultSet] = useMemo(()=>{
        return [
            _.get(nowViewInfo,'subType') ,
            _.get(nowViewInfo,'resultSet') || '' ,
            // _.get(nowViewInfo,'factor') || '' ,
        ]
    },[nowViewInfo])

    useEffect(()=>{
        if(mode !== 'add'){
            getNowViewInfo('/UserSetting/getViewOrUpdate?id='+initViewInfo['id'])
        }
    },[mode, getNowViewInfo, initViewInfo])

    useEffect(()=>{
        if(_.isNil(funcCode)) return
        getTitleList('/UserSetting/getViewNames?funcCode='+funcCode)
    },[funcCode,getTitleList])

    useEffect(()=>{
        if(_.isNil(funcCode)) return
        getSysTitleList('/UserSetting/getViewNames?isQuerySystem=1&funcCode='+funcCode)
    },[funcCode,getSysTitleList])

    const titleList = useMemo(()=>{
        if(_.isEmpty(titleListRes)) return []
        return _.map(titleListRes, x => x.subType)
    },[titleListRes])

    const systemTitleList = useMemo(()=>{
        if(_.isEmpty(sysTitleListRes)) return []
        return _.map(sysTitleListRes, x => x.subType)
    },[sysTitleListRes])

    // useEffect(()=>{
    //     if(mode !== 'add'&& !_.isNil(initFactor) && initFactor !== ''){
    //         const factorParse = JSON.parse(initFactor)
    //         setConditionRelation(_.get(factorParse,'conditionRelation') || 'and')
    //         const initConditionList = _.get(factorParse,'conditionList') || []
    //         const handleConditionList = _.map(initConditionList, item => {
    //             if(_.get(item,'relation') === 'dynamicTime'){
    //                 const value = _.get(item,'value')
    //                 const dynamicTimeObj = JSON.parse(value)
    //                 return ({...item, ...dynamicTimeObj})
    //             }else{
    //                 return item
    //             }
    //         })
    //         setConditionList(handleConditionList)
    //     }
    // },[initFactor,mode])

    const allColumnsOptions = useMemo(()=>{
        if(_.isEmpty(allColumns)) return []
        return _.map(allColumns, x => ({
            text: `${x.fieldName}`,
            value: `${x.tableName}.${x.fieldId}`,
            id: x.id,
            viewConditionType: x.viewConditionType,
            fieldDateSource: x.fieldDateSource,
            fieldDateDetailSource: x.fieldDateDetailSource,
            fieldId: x.fieldId,
            fieldName: x.fieldName,
            tableName: x.tableName,
            custom: x.custom,
            filterDisplayFlag: x.filterDisplayFlag,
            viewDisplayFlag:x.viewDisplayFlag,
            fieldHtmlType: x.fieldHtmlType
        }))
    },[allColumns])

    const mustSelectList = useMemo(()=>{
        if(_.isEmpty(allColumnsOptions)) return []
        return _.map(_.filter(allColumnsOptions, x => x.viewDisplayFlag === '01'),'value')
    },[allColumnsOptions])

    const defaultSelectList = useMemo(()=>{
        if(_.isEmpty(allColumnsOptions)) return []
        return _.map(_.filter(allColumnsOptions, x => x.viewDisplayFlag === '02'),'value')
    },[allColumnsOptions])

    useEffect(()=>{
        if(_.isEmpty(allColumnsOptions)) return
        const mustSelect = _.filter(allColumnsOptions, x => _.includes(mustSelectList, x.value))
        const mustSelectColumns = _.map(mustSelect, x => _.pick(x, ['text','value','id']))
        const defaultSelect = _.filter(allColumnsOptions, x => _.includes(defaultSelectList, x.value))
        const defaultSelectColumns = _.map(defaultSelect, x => _.pick(x, ['text','value','id']))
        let initShowColumns = []
        if(mode !== 'add'&&!_.isNil(initResultSet) && initResultSet !== ''){
            const resultSetParse = JSON.parse(initResultSet)
            initShowColumns = _.map(resultSetParse, x => _.pick(_.find(allColumnsOptions, allItem => allItem.value === x.value),['text','value','id']))
            _.forEach(initShowColumns, x => {
                if(_.isNil(_.find(initShowColumns, initItem => initItem.id === x.id))){
                    initShowColumns.unshift(x)
                }
            })
        }else{
            initShowColumns = [...mustSelectColumns,...defaultSelectColumns]
        }
        _.forEach(initShowColumns, x => {
            if(_.includes(mustSelectList, x.value)){
                x['text'] = x['text'] + '(必选)'
            }
        })
        setShowColumns(initShowColumns)
    },[initResultSet,allColumnsOptions,mode,mustSelectList,defaultSelectList])

    const submit = useCallback(()=>{
        const postParams = {
            id: _.get(initViewInfo, 'id'),
            action: mode === 'copy' ? 'add': mode,
            menuCode: funcCode,
            type: '02',
            subType: title,
            lastViewedFlag: 'Y',
            factor: factor ? JSON.stringify(factor) : '',
            resultSet: JSON.stringify(showColumns),
            isSystemView,
            isDefaultView
        }
        submitPost('/UserSetting/maintain',postParams).then((res)=>{
            Messager.show(mode === 'edit'?'修改成功':'添加成功', { icon: 'success' })
            if(mode === 'edit'){
                setCurrentViewId(oldId => {
                    const currId = _.get(initViewInfo, 'id')
                    // if(oldId === currId){
                    //     refresh()
                    // }
                    currentViewIdRef.current = {
                        id:oldId,
                        isUpdate:true,
                        count: currentViewIdRef.current.count + 1
                    }
                    return currId
                })
            }else{
                resetParams()
                setCurrentViewId(res)
            }
            closeViewPop()
            setUpdatePopInfo(null)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[title,factor,showColumns,funcCode,mode,submitPost,setUpdatePopInfo,initViewInfo,closeViewPop,setCurrentViewId,isSystemView,isDefaultView, resetParams])

    const hiddenColumns = useMemo(()=>{
        if(_.isEmpty(allColumnsOptions)) return
        const canShowColumns = _.filter(allColumnsOptions, x => x.viewDisplayFlag !== 'N')
        const filterOptions = _.filter(canShowColumns, allItem => _.isNil(_.find(showColumns, x => x.value === allItem.value)))
        return _.map(filterOptions, x => ({text: x.text, value: x.value,id: x.id}))
    },[allColumnsOptions,showColumns])

    const titleRepeatError = useMemo(()=>{
        if(mode === 'edit' && title === initTitle) return false
        if(_.isNil(title) || title === '') return false
        const omitList = _.filter(isSystemView?systemTitleList:titleList, x => {
            if(mode === 'edit') return x !== initTitle
            return true
        })
        return _.includes(omitList, title)
    },[titleList,title,initTitle,mode, isSystemView,systemTitleList])

    const titleError = useMemo(()=>{
        if(_.isNil(title) || title === '') return '必填项'
        if(titleRepeatError) return '视图标题已存在'
        return null
    },[title,titleRepeatError])

    const canSubmit = useMemo(()=>{
        if(_.isNil(title) || title === '') return false
        if(titleRepeatError) return false
        // if(_.isEmpty(conditionList)) return false
        if(_.isEmpty(showColumns)) return false
        return true
    },[title,showColumns,titleRepeatError])

    const changeIsSystemView = useCallback((newValue)=>{
        if(!newValue){
            setIsDefaultView(false)
        }
        setIsSystemView(newValue)
    },[])

    return <Dialog header={mode === 'edit'? '修改视图': '新增视图'} className={'view-update-dialog flex-y'} contentClassName={'flex-y'} cancel={()=>setUpdatePopInfo(null)} confirmButtonDisabled={!canSubmit}
                    confirm={submit}>
        {
            titleRepeatError && <div className={'title-repeat'}>视图标题已存在</div>
        }
        <HFormInput label={'标题'} required value={title} onChange={setTitle} error={titleError} className={'title-form-item'}/>
        <div className={'view-condition-wrap flex hidden-component'}>
            <HFormInput label={'设置视图条件'} componentWidth={0} component={Display}/>
            <CheckConditionGroup allColumns={allColumns} editData={factor} setEditData={setFactor}/>
            {/*<ViewCondition {...{allColumnsOptions,setFactor,conditionList,setConditionList,conditionRelation,setConditionRelation}}/>*/}
        </div>
        <div className={'view-field-transfer-wrap flex hidden-component'}>
            <HFormInput label={'设置显示字段'} componentWidth={0} component={Display}/>
            <ViewFieldTransfer {...{allColumnsOptions,setShowColumns,showColumns,hiddenColumns,mustSelectList}}/>
        </div>
        {
            systemViewEditAuth &&
            <div className={'check-wrap'}>
                <CheckBox value={isSystemView} onChange={changeIsSystemView}>设置为系统视图，所有人可见</CheckBox>
                <div/>
                {
                    isSystemView &&
                    <CheckBox value={isDefaultView} onChange={setIsDefaultView}>设置为默认显示视图</CheckBox>
                }
            </div>
        }
    </Dialog>
}

function ViewFieldTransfer(props){
    const {setShowColumns, showColumns,hiddenColumns,allColumnsOptions,mustSelectList} = props
    const [target, setTarget] = useState(showColumns)
    const [source, setSource] = useState(hiddenColumns)

    const allOptions = useMemo(()=>{
        if(_.isEmpty(allColumnsOptions)) return []
        let allList = _.map(allColumnsOptions, x => _.pick(x, ['text','value','id']))
        _.forEach(allList, x => {
            if(_.includes(mustSelectList, x.value)){
                x['text'] = x['text'] + '(必选)'
            }
        })
        return allList
    },[allColumnsOptions,mustSelectList])

    useEffect(()=>{
        setTarget(showColumns)
    },[showColumns])

    useEffect(()=>{
        setSource(hiddenColumns)
    },[hiddenColumns])

    return <div className={'view-field-transfer'} key={uniqKeyFor()}>
        <div className="view-field-transfer-header flex center-y">
            <div className='header-item'>隐藏的字段</div>
            <div className='header-item second-header-item'>显示的字段（请至少选择一个字段）</div>
        </div>
        <Transfer source={source} target={target} onChange={(target,source) => {
            if(target.length < showColumns.length){
                const newTargetList = _.filter(showColumns, oldItem => {
                    if(_.includes(mustSelectList, oldItem.value)) return true
                    if(_.includes(_.map(target, 'value'), oldItem.value)) return true
                    return false
                })
                const newSourceList = _.filter(allOptions, x => !_.includes(_.map(newTargetList, 'value'),x.value))
                setTarget(newTargetList)
                setSource(newSourceList)
                setShowColumns(newTargetList)
            }else{
                setTarget(target)
                setSource(source)
                setShowColumns(target)
            }
        }}/>
    </div>
}

export default ViewUpdateDialog;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from 'lodash'
import {DataGrid, Button, Messager, Tooltip} from 'rootnet-ui'
import './ResourcePlanBaseModel.scss'
import {Input} from "rootnet-edit";
import {Select} from 'rootnet-edit'
import {useGet, usePost} from "rootnet-biz/es/hooks";
import {strParams} from "../../../../../../utils/publicFun";
import Icon from "../../../../../../project_share/components/Icon";

function getColumns(props){
    const {changeWorkload, changePersonNum,calculateInvestment, deleteModule} = props
    return [
        {header: '涉及模块', bind: 'moduleName', width: 120, convert: (v, o) => {
                return _.get(o,'showModule') ? <div>
                    {v}
                    <Tooltip title={'删除该模块下所有内容'}>
                        <span onClick={()=>deleteModule(o.module)}>
                            <Icon className={'module-col-delete'} name='shanchu'/>
                        </span>
                    </Tooltip>
                </div> : ''
            }},
        {header: '标准工作量(人日)', bind: 'workload', width: 120,convert: (v, o)=>{
            if(_.get(o,'showModule')){
                return <Input placeholder={'输入标准工作量(人日)'} value={v === 0 ? null : v} digit={1} type={"number"} onBlur={calculateInvestment} onChange={v => changeWorkload(_.get(o,'module'),v)}/>
            }
         }},
        {header: '岗位', bind: 'positionName', width: 80, convert: (v, o) => _.get(o,'showPosition')?v:''},
        {header: '职级', bind: 'techLevel', width: 80},
        {header: '需投入(人日)', bind: 'investment', width: 80},
        {header: '可参与人员数量', bind: 'userNum', width: 100, convert: (v, o)=> {
                return <Input placeholder={`最多${_.get(o,"maxUserNum") || 0}人参与`} value={v === 0 ? null : v}
                              max={_.get(o,"maxUserNum") || 0} digit={0} type={"number"}
                              onChange={v => changePersonNum(_.get(o,'module'),_.get(o,'position'),_.get(o,'techLevel'),v)}/>
            }},
    ]
}

function getOption(cols){
    return {
        autoFill: true,
        resizable: true,
        virtualized: true,
        columns: cols,
    }
}

function ResourcePlanBaseModel(props) {
    const {moduleOptions, department, setPlanList,initPlanList,selectedModuleList, setSelectedModuleList} = props
    const [list, setList] = useState(initPlanList)
    const [addModuleValue, setAddModuleValue] = useState()
    const {doFetch: getModuleInfo} = useGet()
    const {doFetch: getModuleCalculate} = usePost()
    const [moduleLoading, setModuleLoading] = useState(false)

    const addModuleList = useMemo(()=>{
        return _.uniq(_.map(list, 'module')) || []
    },[list])

    useEffect(()=>{
        setPlanList(list)
    },[list,setPlanList])

    const filterModuleList = useMemo(()=>{
        return _.map(moduleOptions, x => ({
            ...x,
            _disabled: _.includes(addModuleList, x.value)
        }))
    },[moduleOptions, addModuleList])

    const calculateInvestment = useCallback(()=>{
        getModuleCalculate('/demandInput/moduleCalculate',list).then(res => {
            setList(res)
        })
    },[getModuleCalculate,list])

    const changeWorkload = useCallback((module, inputValue)=>{
        const cloneList = _.clone(list)
        const newList = _.map(cloneList, x => {
            if(_.get(x,'module') === module){
                return ({
                    ...x,
                    workload: _.toNumber(inputValue)
                })
            }else{
                return x
            }
        })
        setList(newList)
    },[list])

    const changePersonNum = useCallback((module, position, techLevel, inputValue)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.map(cloneList, x => {
                if(_.get(x,'module') === module && _.get(x,'position') === position && _.get(x,'techLevel') === techLevel){
                    return ({
                        ...x,
                        userNum: _.toNumber(inputValue)
                    })
                }else{
                    return x
                }
            })
        })
    },[])

    const showList = useMemo(()=>{
        const positionSortList = ['demand','frontEndDev','backEndDev','otherDev','test']
        return _.sortBy(list,[sortModule, sortPosition, sortRank])
        function sortModule(o){
            const moduleList = _.uniq(_.map(list,'module'))
            return _.findIndex(moduleList,x => x === _.get(o,'module'))
        }
        function sortPosition(o){
            return _.findIndex(positionSortList,x => x === _.get(o,'position'))
        }
        function sortRank(o){
            return _.get(o,'techLevel')
        }
    },[list])

    const handleList = useMemo(()=>{
        let lastModule
        let lastPosition
        return _.map(showList, x => {
            if(_.get(x,'module') !== lastModule){
                lastModule = _.get(x,'module')
                lastPosition = _.get(x,'position')
                return {
                    showModule: true,
                    showPosition: true,
                    ...x,
                }
            }else if(_.get(x,'position') !== lastPosition){
                lastPosition = _.get(x,'position')
                return {
                    showPosition: true,
                    ...x,
                }
            }else{
                return x
            }
        })
    },[showList])

    const deleteModule = useCallback((module)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.filter(cloneList, x => x.module !== module)
        })
    },[])

    const option = useMemo(()=>{
        return getOption(getColumns({changeWorkload,changePersonNum,calculateInvestment, deleteModule}))
    },[changeWorkload, changePersonNum, calculateInvestment, deleteModule])

    useEffect(()=>{
        if(_.isEmpty(selectedModuleList)) return
        setModuleLoading(true)
        Promise.all(_.map(selectedModuleList, x => getModuleInfo('/demandInput/echo?'+strParams({
            module: x,
            department: department
        })))).then(values => {
            setModuleLoading(false)
            const list = _.concat(...values)
            const sortedList =  _.sortBy(list,[sortModule,sortPosition, sortRank])
            setList(sortedList)
            setSelectedModuleList([])
        }).catch(err => {
            Messager.show(err,{icon:'error'})
            setModuleLoading(false)
        })

        function sortModule(o){
            return _.get(o,'module')
        }
        const positionSortList = ['demand','frontEndDev','backEndDev','otherDev','test']
        function sortPosition(o){
            return _.findIndex(positionSortList,x => x === _.get(o,'position'))
        }
        function sortRank(o){
            return _.get(o,'techLevel')
        }
    },[selectedModuleList,getModuleInfo,department,setSelectedModuleList])

    const addModule = useCallback(()=>{
        if(_.isNil(addModuleValue)) return Messager.show("请先选择要添加的模块")
        if(moduleLoading) return
        setModuleLoading(true)
        const searchParams = {
            module: addModuleValue,
            department: department
        }
        getModuleInfo('/demandInput/echo?'+strParams(searchParams)).then(res => {
            setAddModuleValue(null)
            setModuleLoading(false)
            if(_.isEmpty(res)) return Messager.show("无数据")
            const sortedRes =  _.sortBy(res,[sortModule,sortPosition, sortRank])
            setList(old => _.concat(old, sortedRes))
        }).catch(err => {
            Messager.show(err,{icon:'error'})
            setModuleLoading(false)
        })

        function sortModule(o){
            return _.get(o,'module')
        }
        const positionSortList = ['demand','frontEndDev','backEndDev','otherDev','test']
        function sortPosition(o){
            return _.findIndex(positionSortList,x => x === _.get(o,'position'))
        }
        function sortRank(o){
            return _.get(o,'techLevel')
        }
    },[getModuleInfo, addModuleValue,department,moduleLoading])

    return <div className={'resource-plan-base-model'}>
        <DataGrid data={handleList} option={option}/>
        <div className={'add-module-wrap flex center-y'}>
            <Select value={addModuleValue} onChange={setAddModuleValue} placeholder={'请选择添加的模块'} options={filterModuleList} search/>
            <Button primary style={{height: 32}} onClick={addModule}>添加模块</Button>
        </div>
    </div>
}
export default ResourcePlanBaseModel;
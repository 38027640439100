import React, { useState, useMemo, useReducer, useEffect, useCallback, useRef } from 'react';
import _ from "lodash";
import { Popover, Tooltip } from 'antd'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import usePost from "rootnet-biz/es/hooks/usePost";
import { Box } from '../common/commonComponent';
import { TextIconBtn } from '../common/TextIconBtn';
import useGetDateOptions from '../common/view/hooks/useGetDateOptions';
import useGetTreeList from '../common/view/hooks/useGetTreeList';
import useGetViewConfig from '../common/view/hooks/useGetViewConfig';
import useRefreshList from '../common/view/hooks/useRefreshList';
import convertTableAlign from '../common/view/convertTableAlign';
import findConvert from '../common/view/findConvert';
import IssueAdd from "./components/issueAdd/IssueAdd";
import IssueDetailDialog from "./components/issueDetailDialog/IssueDetailDialog";
import { Icon } from '../../components';
import IssueDelete from './components/issueDelete';
import { useFuncCode } from '../common/commonMethod';
import ViewArea from "../common/view/ViewArea";
import { pathSearchFor } from "../../utils/publicFun";
import { CustomizeFormInput } from '../common/customizeOptions/CustomizeOptions';
import './IssueMgt.scss'
import QaCheckDialog from './components/qaCheckDialog';
import WorkFlowChangePop from "../workFlow/workFlowChangePop/WorkFlowChangePop";
import { isNil } from "rootnet-core/format";
import { API1 } from '../../base/task'
import { useGet } from 'rootnet-biz/lib/hooks';
import convertGlobalConstOptions from '../common/ConvertGlobalConstOptions';
import convertOptions from '../common/ConvertOptions';
import ConcernedIcon from '../common/concernedIcon';

// const EXPORT_URL = '/issueSelf/exportExcel'
// const QUERY_URL = '/UserSetting/getWhereCondition'

const DEL_PERMISSION = '1901' //删除权限
const APPLY_PERMISSION = '1902' //申请权限
// const QA_CHECK_PERMISSION = '1910' //QA检测功能权限
const PRIMARY_KEY = 'IssueId'
const ENTER_DETAIL_FIELD = 'ShortDesc'
// const DEAL_WITH_LOGO = 'dealType' //处理标识  'dealType',

const EDITABLE_FIELD = ['ShortDesc', 'qaCheckResult', 'ISSUESTYPE', 'PersonCharge', 'Principal', 'Impact',
    'WhyAndHow', 'OptStep', 'LogList', 'communicateNote', 'detail', 'testCheckAnalysis', 'testMeasures', 'testEvolve',
    'rootAnalysis', 'rootMeasures', 'rootEvolve', 'currentUser']
const RICH_TEXT_FIELD = ['Impact', 'WhyAndHow', 'OptStep', 'LogList', 'communicateNote', 'detail', 'testCheckAnalysis', 'testMeasures', 'testEvolve', 'rootAnalysis', 'rootMeasures', 'rootEvolve']

const PROBLEM_STATE_COLOR = {
    '01': '#3AA3FD',
    '02': '#F76A00',
    '03': '#F76A00',
    '04': '#00A950',
    '05': '#C9CDD4',
    '06': '#00A950',
    '07': '#C9CDD4',
    '08': '#F76A00',
    // '09': '#0000FF',
    '10': '#C9CDD4',
}

const ORDER_SEVERITY_COLOR = {
    '01': '#F60421',// 致命
    '02': '#F76A00',// 严重
    '03': '#165DFF',// 重要问题
    '04': '#93C36B',// 一般问题
    '05': '#97AFD0',// 改善建议
}

const DEAL_TYPE_COLOR = {
    '0': '#93C36B',
    '1': '#FF8C00',
    '2': 'red',
    '3': 'red',
}

const SPECIAL_FIELD_CONVERT = {
    'IssueStatus': (value, showValue) => <div className={'status-field flex center'} style={{ border: `1px solid ${PROBLEM_STATE_COLOR[value]}`, padding: '1px 12px 2px', color: PROBLEM_STATE_COLOR[value], borderRadius: 4 }}>
        {showValue}
    </div>,
    'EffectGrade': (value, showValue) => <div style={{ color: ORDER_SEVERITY_COLOR[value] }}>{showValue}</div>,
    'dealType': (value, showValue) => <div className='status-deal-type'><span style={{ backgroundColor: DEAL_TYPE_COLOR[value] }}></span>{showValue || '-'}</div>,
    'positioningDuration': (value, showValue) => <div className='status-deal-type'><span style={{ backgroundColor: DEAL_TYPE_COLOR[value] }}></span>{showValue || '-'}</div>
}

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentInfo, isFuncCodeDel, editInfo, setEditInfo,
        currentTableValueRef, updateShowList, setEditOldValue, workFlowId, setWorkFlowId, refreshList, appendExpirationMark, appendPositionDuration, dealtypeOptions } = props
    const { editId = null, editField = null } = editInfo || {}
    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            const customColumns = [
                { header: '超期标识', bind: 'dealType', width: 180, tooltip: true, convert: (v, o) => getCustomName(v, o, appendExpirationMark, fieldItem.fieldId, fieldItem) },
                { header: '定位时长标识', bind: 'positioningDuration', width: 180, tooltip: true, convert: (v, o) => getCustomName(v, o, appendPositionDuration, fieldItem.fieldId, fieldItem) },
                { header: '处理时长（天）', bind: 'dealTime', width: 180, tooltip: true, convert: (v, o) => getCustomName(v, o, appendExpirationMark, fieldItem.fieldId, fieldItem) }
            ]
            return _.find(customColumns, x => x.bind === fieldItem.fieldId) || { header: '', bind: '', width: 100 }
        } else {
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                fieldId: fieldItem.fieldId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }

    }

    function getCustomName(v, o, data, bind, fieldItem) {

        const findData = _.find(data, item => item?.id === o?.id)
        let showValue = null
        if (bind === 'dealTime') {
            showValue = _.get(findData, bind)
        } else if (bind === 'dealType' && _.isNil(findData)) {
            const val = _.get(o, fieldItem.columnId)
            showValue = convertOptions(val, dealtypeOptions)
            showValue = SPECIAL_FIELD_CONVERT[bind](val, showValue)
        } else {
            showValue = convertOptions(_.get(findData, bind), dealtypeOptions)
            showValue = SPECIAL_FIELD_CONVERT[bind](_.get(findData, bind), showValue)
        }
        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }

    function convertEditField(v, o, fieldItem) {
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
            showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
        }
        if (tableField === 'workflow_business.factorId') {
            const options = _.get(convertCollection, tableField)
            const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
            return <Popover destroyTooltipOnHide={true}
                content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={FUNC_CODE} businessType={'issue'} />}
                onOpenChange={visible => {
                    if (!visible) {
                        setWorkFlowId(null)
                    }
                }}
                trigger={'click'}
                placement="bottom"
                open={o.id === workFlowId}
            >
                <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
                    style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
                    {showValue || '-'}
                </div>
            </Popover>
        }
        if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
            return <div className={'common-display-field'}>
                {showValue || '-'}
            </div>
        }
        if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
            const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
            const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
            return <CustomizeFormInput
                fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
                bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
                componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
                viewConditionType={fieldItem.viewConditionType}
                onFocus={() => {
                    currentTableValueRef.current = handleValue
                    setEditOldValue(handleValue)
                }
                }
                onChange={value => {
                    if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
                        updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
                    } else {
                        currentTableValueRef.current = value
                    }
                }
                }
                onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
                onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
            />
        }
        if (_.includes(RICH_TEXT_FIELD, fieldItem.fieldId)) {
            return <Tooltip title={<div className='issue-rich-text-val' dangerouslySetInnerHTML={{ __html: showValue }} />}><div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div></Tooltip>
        }
        if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
            return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
                <div className={'enter-detail-field'} onClick={() => {
                    setCurrentInfo({ mode: 'detail', id: o.id })
                }}>{showValue}</div>
                <ConcernedIcon
                    left={8}
                    type='ISSUE'
                    referenceId={o?.id}
                    flag={_.get(o, 'perColl', 'N') === 'Y'}
                />
                <Icon name={'bianji2'} className="enter-detail-field-delete-icon" onClick={() => onEditFieldClick({ o, fieldItem })} />
                {
                    isFuncCodeDel &&
                    <Icon name={'shanchu2'} className="enter-detail-field-delete-icon"
                        onClick={() => setCurrentInfo({ mode: 'delete', id: o.id })} />
                }
                {/*{*/}
                {/*    isFuncCodeQaCheck &&*/}
                {/*    <Icon style={{ fontSize: 18 }} name={'QAyanshou'} className="enter-detail-field-delete-icon"*/}
                {/*        onClick={() => setCurrentInfo({ mode: 'qa', id: o.id })} />*/}
                {/*}*/}
            </div>
        }
        return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
            {showValue || '-'}
        </div>
    }

    function onEditFieldClick({ o, fieldItem }) {
        const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'ProductionIssue.ShortDesc'), 'columnId')
        setEditInfo({
            editId: o?.id,
            title: _.get(o, titleColumnId),
            editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
            fieldId: fieldItem.fieldId,
            columnId: fieldItem.columnId,
            fieldHtmlType: fieldItem.fieldHtmlType,
            viewConditionType: fieldItem.viewConditionType,
        })
    }

}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 3,
    resizable: true,
    columns: options,
    virtualized: false,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 100
    }
}

const FUNC_CODE = '19'

// {id: "950350949732319232", mode: 'detail'}

function IssueMgt(props) {
    const { CSRecordID, relateCSAttach, isCS } = window.external
    const { location } = props;
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(100)
    const [isLoading, setIsLoading] = useState(true)
    const [focusId, setFocusId] = useState()
    const [currentViewId, setCurrentViewId] = useState()
    const [sortConfig, setSortConfig] = useState()
    const [factor, setFactor] = useState()
    const [isFuncCodeDel, isFuncCodeApply] = useFuncCode([DEL_PERMISSION, APPLY_PERMISSION])
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const [workFlowId, setWorkFlowId] = useState()

    const { data: dealtypeRes } = useGet('/common/globalconst?globalConst=dealtype')
    const { data: appendPositionDuration, doFetch: getPositionDuration } = usePost()
    const { data: appendExpirationMark, doFetch: getExpirationMark } = usePost()

    const CView = useMemo(() => {
        return window.location.hash === '#/issueC'
    }, [])

    const dealtypeOptions = useMemo(() => {
        if (_.isEmpty(dealtypeRes)) return []
        return convertGlobalConstOptions(dealtypeRes)
    }, [dealtypeRes])

    useEffect(() => {
        if (_.isNil(CSRecordID)) return
        setCurrentInfo({
            mode: 'add',
            CSRecordID,
            relateCSAttach
        })
    }, [CSRecordID, relateCSAttach])

    // 表格编辑
    const currentTableValueRef = useRef()
    const [editInfo, setEditInfo] = useState()
    const [showDataGridList, setShowDataGridList] = useState()
    const [editOldValue, setEditOldValue] = useState()
    const { doFetch: editTable } = usePost()

    const updateShowList = useCallback(({ newValue, primaryId }) => {
        if (_.isNil(editInfo)) return
        const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
        if (JSON.stringify(updateValue) === JSON.stringify(editOldValue) || _.isNil(updateValue)) {
            setEditInfo(null)
            currentTableValueRef.current = null
            return
        }
        let postParams = {
            // defectId: primaryId,
            id: editInfo['editId']
        }
        if (editInfo['fieldId'] === 'ShortDesc' && (_.isNil(_.trim(updateValue)) || _.trim(updateValue) === '')) {
            setEditInfo(null)
            currentTableValueRef.current = null
            return
        }
        postParams['fieldName'] = editInfo['fieldId']
        postParams['fieldValue'] = updateValue
        if (editInfo['fieldId'] === 'currentUser') {
            if (isNil(updateValue)) {
                setEditInfo(null)
                currentTableValueRef.current = null
                return
            }
            const currentUserParams = {
                businessId: _.get(editInfo, 'editId'),
                businessType: 'issue',
                funcCode: FUNC_CODE,
                currentUser: updateValue,
                title: _.get(editInfo, 'title')
            }
            editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
                afterRefresh()
            }).catch((err) => {
                Messager.show(err._message, { icon: 'error' });
            })
        } else {
            editTable('/issue/detailSingle', postParams).then(() => {
                afterRefresh()
            }).catch((err) => {
                Messager.show(err._message, { icon: 'error' });
            })
        }
        function afterRefresh() {
            setShowDataGridList(oldList => {
                const cloneList = _.clone(oldList)
                _.forEach(cloneList, item => {
                    if (item.id === _.get(editInfo, 'editId')) {
                        item[editInfo.columnId] = updateValue
                    }
                })
                return cloneList
            })
            setEditInfo(null)
            currentTableValueRef.current = null
            Messager.show('修改成功', { icon: 'success' });
        }
    }, [editInfo, editOldValue, editTable])

    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

    useEffect(() => {//超期标识
        if (_.isEmpty(list)) return
        const idList = _.map(list, o => o.id)
        getExpirationMark(`/issueSelf/selectDealTime`, idList)
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [list, getExpirationMark])

    useEffect(() => {//定位时长
        if (_.isEmpty(list)) return
        const idList = _.map(list, o => o.id)
        getPositionDuration(`/issueSelf/selectPositioningDuration`, idList)
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [list, getPositionDuration])

    const appendObj = useMemo(() => {
        return { collectionType: 'ISSUE' }
    }, [])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj })

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const dateOptions = useGetDateOptions()

    const { options, dataGridList, isTree } = useGetTreeList({
        fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: {
            setCurrentInfo, isFuncCodeDel, editInfo, setEditInfo, currentTableValueRef,
            updateShowList, setEditOldValue, workFlowId, setWorkFlowId, refreshList, appendExpirationMark, appendPositionDuration, dealtypeOptions
        },
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                return _.orderBy(data, x => x[sort.column], sort.direction)
            }
        },
    })

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        API1.downloadPost('/UserSetting/exportExcel', postParams)
    }, [currentViewId, fieldList, factor])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            {
                !(isCS || CView) &&
                <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
            }
            <TextIconBtn icon='tianjia' text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
        </div>
    }, [isCS, CView, exportViewList])

    const showChildList = useMemo(() => {
        return _.filter(dataGridList, x => !_.get(x, '_children')) || []
    }, [dataGridList])

    const showList = useMemo(() => {
        let showList = showChildList || []
        if (!_.isNil(sortConfig) && !isTree) {
            showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, isTree, showChildList])

    const currentIndex = useMemo(() => {
        const currentId = _.get(currentInfo, 'id')
        if (_.isNil(currentId)) return 0
        return _.findIndex(showList, x => _.get(x, 'id') === currentId)
    }, [currentInfo, showList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentInfo(old => {
            const cloneObj = { ...old }
            const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
            cloneObj['id'] = newId
            setFocusId(newId)
            return cloneObj
        })
    }, [showList, currentIndex])

    // const defaultOption = useMemo(() => {
    //     const notCustomList = _.filter(fieldList, x => x.custom === 'N')
    //     return _.map(notCustomList, o => ({
    //         header: o.fieldName,
    //         bind: o.fieldId,
    //     }))
    // }, [fieldList])

    useEffect(() => {
        setShowDataGridList(list)
    }, [list])

    useEffect(() => {
        if (_.isNil(editInfo)) return
        const input = document.querySelector('.issue-data-grid').querySelector('input')
        if (!_.isNil(input)) {
            input.focus()
            input.click()
        }
    }, [editInfo])

    useEffect(() => {
        if (_.isNil(editInfo)) return
        const rootnetSelect = document.querySelector('.issue-data-grid').querySelector('.rootnet-select')
        if (rootnetSelect) {
            rootnetSelect.addEventListener('mousewheel', e => {
                e.cancelBubble = true
            })
        }
    }, [editInfo])

    return <div className={'issue-mgt fill flex-y'}>
        <div className={'flex-y fill'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                {...{
                    getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize
                }} />
            <Box title={'ISSUE管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                <Table {...{ options, showList, dataGridList, focusId }} />
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, { pageNum, pageSize }))
                    }} />
            </Box>
        </div>
        {
            _.get(currentInfo, 'mode') === 'add' &&
            <IssueAdd close={() => setCurrentInfo(null)} refreshViewList={refreshList} {...{ currentInfo }} />
        }
        {
            _.get(currentInfo, 'mode') === 'delete' &&
            <IssueDelete id={_.get(currentInfo, 'id')} close={() => setCurrentInfo(null)} {...{ refreshList }} />
        }
        {
            _.get(currentInfo, 'mode') === 'detail' &&
            <IssueDetailDialog close={() => setCurrentInfo(null)} refreshViewList={refreshList} {...{ isFuncCodeApply, currentInfo, switchCurrentItem, showChildList, currentIndex }} />
        }
        {
            _.get(currentInfo, 'mode') === 'qa' &&
            <QaCheckDialog close={() => setCurrentInfo(null)} {...{ id: _.get(currentInfo, 'id'), refresh: refreshList }} />
        }
    </div>
}

function Table(props) {
    const { options, showList, dataGridList, focusId } = props
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const onRowClick = useCallback((item) => {
        _.forEach(dataGridList, o => {
            return o._rowClass = item?.id === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    }, [dataGridList])

    useEffect(() => {
        if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    return <DataGrid option={options} data={showList} onRowClick={onRowClick} className='issue-data-grid' />
}

export default IssueMgt;
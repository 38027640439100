import React, { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Button, Messager, DataGrid } from 'rootnet-ui'
import { Form, FormInput, Display, Select, Input } from 'rootnet-edit'
import { Icon } from '../../../../../../../components'
// import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import TextAreaInput from '../../../../../../common/textAreaInput/TextAreaInput'
import convertOptions from '../../../../../../common/ConvertOptions'
// import { strParams } from '../../../../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import DisplaySelect from '../../../../../../common/displaySelect/DisplaySelect'
import InfoStageUpload from '../../infoStageUpload'
import { isNil } from '../../../../../../appraise/components/method'
import './index.scss'

const EDIT_URL = '/userProject/stage/quality/update'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=qualityStatus',
]

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={180} {...props} />

function getColumns(props) {
  return [
    { header: '序号', align: 'center', width: 40, tooltip: true, convert: (v, o, i) => i + 1 },
    { header: '活动名称', bind: 'title', align: 'left', width: 200, tooltip: true, },
    { header: '负责人', bind: 'ownerName', align: 'left', width: 120, tooltip: true, },
    { header: '交付件', bind: 'delivery', align: 'left', width: 260, tooltip: true, },
    { header: '交付件配置路径', bind: 'notesUrl', align: 'left', width: 200, tooltip: true, },
  ]
}

const gridOptsFor = (columns) => ({
  nilText: '-',
  fixedLeft: 2,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});

export default function StageQualityAuditDetailContent(props) {
  const { close, currentInfo, refreshViewList, STAGE_FLOW_LIST, allData, projectId, updateOuterFile, allUserRes, userprojectType } = props
  const { mode: initMode = 'edit', } = currentInfo
  const [mode, setMode] = useState(initMode)
  const [id, setId] = useState()
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [infoError, setInfoError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  // const { doFetch: getDetail, loading } = useGet()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { doFetch } = usePost()

  const { id: stageId, processCompliance } = useMemo(() => editFormData || {}, [editFormData])
  const { qualityResult } = useMemo(() => formData || {}, [formData])

  const isDetail = useMemo(() => mode === 'detail', [mode])

  const currentData = useMemo(() => {
    const keyNodes = _.get(_.find(allData, o => o.id === id), 'keyNodes')
    const children = _.filter(_.orderBy(_.filter(_.filter(allData, o => !_.includes(['09', '10'], o.status)), o => o.keyNodes === keyNodes), 'serial', 'asc'), o => !_.isNil(o.parentId))
    return children
  }, [id, allData])

  const [statusOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, o => convertGlobalConstOptions(o))
  }, [globalConstOptionsRes])

  const approvalStageOption = useMemo(() => {
    const list = _.map(_.groupBy(allData, 'keyNodes'), arr => _.head(arr))
    return _.map(list, o => {
      const findItem = _.find(allData, item => (o.keyNodes === item.keyNodes && item.isBaselineRelease === 'Y'))
      return {
        text: o.keyNodes,
        value: o.id,
        _disabled: _.isNil(findItem) ? true : _.includes(['09', '10'], _.get(findItem, 'status'))

      }
    })
  }, [allData])

  const refreshDetail = useCallback(() => {
    const params = _.find(allData, o => o.id === id)
    if (!_.isNil(params)) {
      const pickParams = { ..._.pick(params, ['id', 'keyNodes', 'qualityId', 'qualityStatus', 'notes', 'qualityResult', 'fileUrl', 'processCompliance']) }
      setFormData(pickParams)
      setEditFormData(pickParams)
    } else {
      setFormData({ id })
      setEditFormData({ id })
    }
  }, [id, allData])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(infoError), x => x)
  }, [infoError])

  const submit = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.assign({}, { processCompliance: null }, editFormData, { projectId, id: editFormData.qualityId }, !isNil(editFormData?.fileUrl) && { fileUrl: _.isArray(editFormData?.fileUrl) ? _.join(editFormData?.fileUrl, ',') : editFormData?.fileUrl })
    doFetch(EDIT_URL, postParams)
      .then(() => {
        Messager.show('编辑成功', { icon: 'success' })
        refreshViewList()
        setIsDisable(false)
        close()
      }).catch((err) => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, isDisable, refreshViewList, editFormData, projectId, close])

  const option = useMemo(() => gridOptsFor(getColumns()), [])

  const handChange = useCallback((o, key) => {
    if (key === 'id') {
      setId(o[key])
    }
    setEditFormData(o)
  }, [])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) setId(id)
  }, [currentInfo])

  useEffect(() => {
    refreshDetail()
  }, [refreshDetail])

  useEffect(() => {
    if (qualityResult === '05') setMode('detail')
    else setMode('edit')
  }, [qualityResult])

  const isProcessCompliance = useMemo(() => {
    const stageName = convertOptions(stageId, approvalStageOption)
    return (_.includes(['TR5', 'TP5'], stageName) || userprojectType === 'PAT')
  }, [stageId, approvalStageOption, userprojectType])

  useEffect(() => {
    setInfoError(x => _.assign({}, x,
      isProcessCompliance && {
        processCompliance: isNil(processCompliance) ? '必填项！' : null
      }))
  }, [isProcessCompliance, processCompliance])

  return (
    <div className='stage-quality-audit-detail-content flex-y'>
      {/* {loading && <Loader fill />} */}
      <div className="mock-dialog-header flex">
        <div className="dialog-title flex center-y">
          {/* {isDetail && '阶段质量审核-详情'}
          {!isDetail && '阶段质量审核-编辑'} */}
          阶段质量审核
        </div>
        <div className="mock-right-header flex center-y">

          {/* <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
            refreshDetail()
            setMode(x => x === 'detail' ? 'edit' : 'detail')
          }} /> */}


          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>

      <div className="stage-quality-audit-detail-main-panel">
        <Form value={editFormData} onChange={handChange} error={infoError} onError={setInfoError}>
          <HFormInput required label='审核阶段' bind='id' component={Select} options={approvalStageOption} convert={v => convertOptions(v, approvalStageOption)} />
          <br />
          <HFormInput label='审核状态' bind='qualityResult' component={Display} convert={v => {
            return <div style={{ color: convertOptions(v, STAGE_FLOW_LIST, 'color') }}>
              {convertOptions(v, statusOptions)}
              <Icon
                style={{ color: convertOptions(v, STAGE_FLOW_LIST, 'color'), fontSize: 14, marginLeft: 6 }}
                name={convertOptions(v, STAGE_FLOW_LIST, 'icon')}
              />
            </div>
          }} />
          <br />
          <HFormInput required label='审核结果' bind='qualityStatus' component={isDetail ? Display : DisplaySelect} options={statusOptions} convert={v => convertOptions(v, statusOptions)} />
          <Tooltip
            placement="bottom"
            overlayClassName='stage-help-tooltip'
            title={'当原【通过、有条件通过】状态，改为不通过时，当前阶段以后的状态，将需要重新审核！'}>
            <span>
              <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
            </span>
          </Tooltip>
          <br />
          <HFormInput required={isProcessCompliance} label='流程符合度' bind='processCompliance' component={isDetail ? Display : Input} type='number' digit={2} suffix='%' convert={v => {
            if(isNil(v))return '-'
            return `${v}%`
          }} />
          <br />
          <HFormInput label='交付件' component={Display} convert={() => {
            return <Tooltip
              placement="bottom"
              overlayClassName='deliverable-list-tooltip'
              title={<DataGrid data={currentData} option={option} />}
            >
              <div style={{ color: '#5477FF' }}>查看</div>
            </Tooltip>
          }} />
          <br />
          <HFormInput label='审核备注' bind='notes' className={isDetail ? 'text-area-input-detail' : 'text-area-input-edit'} component={isDetail ? Display : TextAreaInput} componentWidth={471} />
          <br />
          <HFormInput
            label='附件'
            bind='fileUrl' className='text-area-input-detail'
            componentWidth={471}
            component={Display} convert={(v) => {
              const stageDocList = isNil(v) ? [] : _.split(v, ',')
              return <InfoStageUpload
                id={projectId}
                funcCode={'36'}
                afterDelRefresh={updateOuterFile}
                defaultName={editFormData?.keyNodes}
                stageDocList={stageDocList}
                setList={(list) => setEditFormData(o => _.assign({}, o, { fileUrl: list }))}
                {...{ allUserRes, isDetail }}
              />
            }} />
        </Form>
      </div>

      <div className="mock-footer flex center-y">
        <div className='footer-hint'>
          提示：提交后请在动态区域-工作流处，查看审核留痕记录！
        </div>
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              close()
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary disable={!canSubmit} onClick={submit} >确认</Button>
          }
        </div>
      </div>
    </div>
  )
}

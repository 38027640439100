import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Loader, Messager} from 'rootnet-ui'
import './MenuDetailDialog.scss'
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import {Display, Input, RadioGroup, Select, FormInput, Form} from "rootnet-edit";
import convertOptions from "../../../common/ConvertOptions";
import {TextIconBtn} from "../../../common/TextIconBtn";
import Icon from "../../../../components/Icon";
import {usePost} from "rootnet-biz/es/hooks";
import {isNil} from "../../../appraise/components/method";

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=functype',
    '/common/globalconst?globalConst=state',
]

function handleTreeOptions(allList, currentList, valueField, parentField, textField){
    return _.map(currentList, item => {
        const childrenList = _.filter(allList, x => x[parentField] === item[valueField])
        const currentItem = {value: item[valueField], text: `${item[textField]}（${item[valueField]}）`}
        if(!_.isEmpty(childrenList)) _.assign(currentItem, {children: handleTreeOptions(allList, childrenList, valueField, parentField, textField )})
        return currentItem
    })
}

const INIT_FORM = {
    statusStr: '0'
}

function MenuDetailDialog(props) {
    const {currentInfo, close, initSystem, refreshViewList, updateInnerTree} = props
    const { mode: initMode = 'detail', id: initId } = currentInfo || {}
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [mode, setMode] = useState(initMode)
    const {data: initDetail, doFetch: getDetail} = useGet()
    const [editFormData, setEditFormData] = useState(mode === 'add' ? INIT_FORM : {})
    const [id, setId] = useState(initId)
    const [formError, setFormError] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)
    const {doFetch: submitPost} = usePost()
    const {data: allList, doFetch: getAllList} = useGet()

    const refreshTreeList = useCallback(()=>{
        getAllList('/uac/menu/list?moduleId='+initSystem).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[getAllList,initSystem])

    const menuOptions = useMemo(()=>{
        const currentSystemList = _.filter(allList, x => x.moduleId === initSystem)
        return _.map(currentSystemList, x => ({
            text: `${x.funcName}（${x.funcCode}）`,
            value: x.funcCode
        }))
    },[allList, initSystem])

    useEffect(()=>{
        refreshTreeList()
    },[refreshTreeList])

    const treeOptions = useMemo(()=>{
        if(_.isEmpty(allList)) return []
        let firstLevel = _.filter(allList, x => isNil(_.get(x,'pid')))
        return handleTreeOptions(allList, firstLevel, 'funcCode', 'pid', 'funcName')
    },[allList])

    useEffect(() => {
        const { id } = currentInfo || {}
        if (!_.isNil(id)) {
            setId(id)
        }
    }, [currentInfo])

    const refreshDetail = useCallback(()=>{
        if(_.isNil(id)) return
        getDetail('/uac/menu/info?id='+id).then(res => {
            setEditFormData(res)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[id,getDetail])

    useEffect(() => {
        refreshDetail()
    },[refreshDetail])

    const [funcTypeOptions, statusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const isDetail = useMemo(()=>{
        return mode === 'detail'
    },[mode])

    const canSubmit = useMemo(()=>{
        return !_.some(_.values(formError),x=>x)
    },[formError])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const url = mode === 'add' ? '/uac/menu/add' : '/uac/menu/edit'
        let postParams = {...editFormData}
        if(mode === 'add'){
            postParams['moduleId'] = initSystem
        }
        submitPost(url,postParams).then(()=>{
            setSubmitLoading(false)
            Messager.show(mode === 'add'?'添加成功':'修改成功', { icon: 'success' })
            refreshViewList()
            updateInnerTree()
            if(mode === 'add'){
                close()
            }else{
                setMode('detail')
                refreshDetail()
                refreshTreeList()
            }
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitLoading, editFormData, submitPost, close, refreshDetail, initSystem, mode, refreshViewList, refreshTreeList, updateInnerTree])

    const onCancelClick = useCallback(()=>{
        if(mode === 'edit'){
            setEditFormData(initDetail)
            setMode('detail')
        }else{
            close()
        }
    },[mode, close, initDetail])

    return <Dialog headerVisible={false} className={'menu-detail-dialog'} confirm={submit}
                   confirmButtonVisible={mode !== 'detail'} confirmButtonDisabled={!canSubmit} confirmButtonText={'保存'}
                   cancelButtonText={mode !== 'detail'?'取消':'关闭'} cancel={onCancelClick}>
        <div className="menu-detail-dialog-content flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    菜单
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        mode !== 'add' &&
                        <span style={{marginRight: 16}}>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail?'进入编辑':'退出编辑'} onClick={()=>{
                                setMode(x => x === 'detail' ? 'edit' : 'detail')
                                setEditFormData(initDetail)
                            }}/>
                        </span>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <div className="main-content-wrap">
                {
                    submitLoading && <Loader fill/>
                }
                <Form value={editFormData} onChange={setEditFormData} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
                    <HFormInput label={'编码'} bind={'funcCode'} component={isDetail? Display: Input} required/>
                    <HFormInput label={'菜单名称'} bind={'funcName'} component={isDetail? Display: Input} required/>
                    <HFormInput label={'类型'} bind={'funcTypeStr'} component={isDetail? Display: Select} required
                                options={funcTypeOptions} convert={v => convertOptions(v, funcTypeOptions)}/>
                    <HFormInput
                        label={'状态'}
                        bind={'statusStr'}
                        component={isDetail? Display: RadioGroup}
                        options={statusOptions}
                        convert={v => convertOptions(v, statusOptions)}
                        required
                    />
                    <HFormInput label={'上级菜单'} bind={'pid'} clear component={isDetail? Display: Select} options={treeOptions} tree search
                                convert={v => convertOptions(v, menuOptions)}/>
                    <HFormInput label={'排序号'} bind={'order'} component={isDetail? Display: Input}/>
                    <HFormInput label={'icon'} bind={'icon'} component={isDetail? Display: Input}/>
                    <HFormInput label={'url'} bind={'menuUrl'} component={isDetail? Display: Input}/>
                    {
                        mode !== 'add' &&
                        <div className={'role-name-wrap flex'}>
                            <div className="mock-label">所属角色</div>
                            <div className={'role-name'}>
                                {_.get(editFormData,'roleNames')}
                            </div>
                        </div>
                    }
                </Form>
            </div>
        </div>
    </Dialog>
}

export default MenuDetailDialog;
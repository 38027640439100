import React, { useMemo } from 'react'
import _ from 'lodash'
import { Button, Loader } from 'rootnet-ui'
import { parseParams } from '../../../../project_share/utils/utils'
import { Icon } from '../../../../project_share/components'
import { TimeInterception } from '../../common'
import { useGet } from '../../../../utils/hook'
import Header from '../../Header'
import './Details.scss'

const AUDIT_FLAG_COLOR = [
  '#FAAD14',
  '#F5221B',
  '#33CC8A',
  '#5477FF',
  '#7A8199',
]

const defaultData = [
  [
    ['操作人', 'optName'],
    ['操作时间', 'inputTime'],
    ['操作动作', 'action'],
    ['审核状态', 'auditFlag'],
    ['审核人', 'auditOptName'],
    ['审核时间', 'auditTime'],
  ],
  [
    ['操作人', 'optId'],
    ['操作动作', 'action'],
  ],
  [
    ['审核人', 'auditOptId'],
    ['审核说明', 'auditMemo'],
  ]
]
const data = [
  [
    ['字典名', 'fieldCode'],
    ['字典名称', 'fieldName'],
    ['使用产品', 'productIdName'],
    ['根网数据类型', 'fieldTypeCode'],
    ['常量标识', 'fieldDictId'],
    ['字典说明', 'fieldDesc'],
  ],
  [
    ['常量标识', 'fieldDictId'],
    ['常量名称', 'fieldDictName'],
    ['根网数据类型', 'fieldTypeCode'],
    ['子项标识', 'fieldDictSubCode'],
    ['子项名称', 'fieldDictSubName'],
    ['说明', 'fieldDictSubDesc'],
  ],
  [
    ['根网数据类型', 'fieldTypeCode'],
    ['类型名称', 'fieldTypeName'],
    ['mysql类型', 'mysqlType'],
    ['sqlserver类型', 'sqlserverType'],
    ['oracle类型', 'oracleType'],
    ['字段长度', 'normLength'],
    ['精度', 'normPrecision'],
    ['类型说明', 'dictTypeDesc'],
  ]
]
export default function Details(props) {
  const { history, location } = props
  const params = JSON.parse(parseParams(location.search.split('?')[1]).data)
  const { data: logData, loading } = useGet(`/field/change?whichPage=${params.tabIndex}&${name(params)}`)
  const { data: auditFlag } = useGet('/common/globalconst?globalConst=auditFlag')

  const filterData = useMemo(() => {
    if (!logData) return
    return _.map(logData, o => {
      const keys = o.before || []
      const arr = []
      _.map(Object.keys(keys), (v, i) => {
        if (_.values(keys)[i] !== _.values(o.after)[i]) arr.push(v)
      })
      if (!_.isEmpty(arr)) return arr
    })
  }, [logData])

  return (
    <div className='MetadataDetails'>
      <Header
        goBack={() => history.push(`/mobileHome?initTabIndex=0`)}
        content='任务详情'
        icon='xiangqian'
      />
      <section>
        {loading && <Loader />}
        <h1>{params.tabName}</h1>
        <div className='information'>
          <DisplayData data={params} option={data[params.tabIndex].concat(defaultData[0])} />
        </div>
        <h1>日志信息</h1>
        {
          _.size(logData) ?
            _.map(logData, (o, i) => {
              return <div key={i} className='information'>
                <p>
                  <span>
                    <span style={{ background: AUDIT_FLAG_COLOR[o.auditFlag] }} ></span>
                    {auditStatus(o.auditFlag)}
                  </span>
                  <span>{TimeInterception(_.get(o, 'time'), true)}</span>
                </p>

                {/* {
                  o.action !== 'Add' && <> */}
                {/* <p className='label'>
                      <span>变更前</span>
                    </p> */}
                <DisplayData option={currentField(o.auditFlag)} data={o.after} />
                <DisplayData
                  change={filterData[i]}
                  option={data[params.tabIndex]}
                  after={o.after}
                  before={o.before}
                />
                {/* </>
                } */}
                {/*
                  o.action !== 'Del' && <>
                    <p className='label'>
                      <span>变更后</span>
                    </p>
                    <DisplayData after={a[i]} data={_.assign({}, o.after, o)} option={currentField(o.auditFlag)} />
                  </>
                */}
              </div>
            }) : <div className='information noData'>暂无数据</div>
        }
      </section>
      <footer>
        {
          Number(params.tabItem) ?
            <Button primary
              disable={_.includes(['0', '2'], params.auditFlag) || params.action === 'Del'}
              onClick={() => history.push(`/metadataMgt/details/edit${location.search}`)}>修改</Button>
            :
            <Button primary onClick={() => history.push(`/metadataMgt/examine${location.search}`)}>审核</Button>
        }
      </footer>
    </div>
  )

  function currentField(isShow) {
    if (isShow !== '0') return defaultData[2].concat(defaultData[1])
    return defaultData[1]
  }

  function auditStatus(flag) {
    return _.get(_.filter(auditFlag, o => o.interiorId === flag), '[0]displayName')
  }

  function DisplayData(props) {
    const { option, data, change, after, before } = props

    if (data) return <Information data={data} />

    if (change) {
      return _.map(option, (v, i) => {
        if (_.find(change, val => val === v[1])) {
          return <div className='perData' key={i}>
            <span>{v[0]}</span>
            <span>
              <span>{parameter(before, v[1])}</span>
              &nbsp;<Icon name='xianghou' />&nbsp;
              <span>{parameter(after, v[1])}</span>
            </span>
          </div>
        }
      })
    }

    return <Information data={before || after} />

    function Information({ data }) {
      return _.map(option, (v, i) => {
        return <div className='perData' key={i}>
          <span>{v[0]}</span>
          <span>{parameter(data, v[1])}</span>
        </div>
      })
    }

    function parameter(data, bind) {
      if (_.isEmpty(data[bind])) data[bind] = '-'
      if (bind === 'inputTime') return TimeInterception(data[bind])
      if (bind === 'auditTime') return TimeInterception(data[bind])
      if (bind === 'auditFlag') return auditStatus(data[bind])
      return data[bind]
    }
  }
  function name(o) {
    if (o.tabIndex === 0) return `fieldCode=${o.fieldCode}`
    if (o.tabIndex === 1) return `fieldDictId=${o.fieldDictId}&fieldDictSubCode=${o.fieldDictSubCode}`
    if (o.tabIndex === 2) return `fieldTypeCode=${o.fieldTypeCode}`
  }
}

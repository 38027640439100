import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Messager, DataGrid, Card } from 'rootnet-ui'
import { GetTime } from 'rootnet-core/format'
import { hasData } from 'rootnet-core/system';
import Api from '../../../../base/task';
import Option from './../common/option';
import { strParams } from './../../../../utils/publicFun';
import { getAllDate, getTabData, getCol } from '../common/commonfunc';
import { Box } from '../../../common/commonComponent';
import Memo from '../common/Remark';
import _ from 'lodash';
import './../common/common.scss';
import './project.scss';

// let mockUrl = 'http://dev.croot.com/mock/rims';
const OVERT_TIPS = '累计投入已超过授权用时，请修改用时！'
let mockUrl = '';
let beginDate = GetTime(new Date());
function sumWeek(obj, tableData, o) {
	const temp = _.filter(tableData, item => {
		const commonVarify = item.projectId === o.projectId && !item.isTotal
		if (_.get(item, 'taskId')) return commonVarify && item.taskId === o.taskId
		return commonVarify
	})
	const res = _.reduce(temp.map(x => {
		let num = 0;
		_.forEach(obj, (item, i2) => {
			let v = _.get(x, `[whichDay${item.date.split('-').join('')}]`)
			v = v ? parseFloat(v) : 0;
			num += v;
		})
		return num
	}), (acc, item) => {
		acc += item
		return acc
	}, 0)
	return res
}
function optionFor(obj, tableData) {
	return [
		{ header: '项目', bind: 'projectName', convert: (v, o, i, data) => data.findIndex(x => x.projectName === o.projectName) === i ? v : '', width: 215, tooltip: true },
		{ header: '任务', bind: 'taskName', width: 200, tooltip: true },
		{
			header: '授权用时', bind: 'planTime', width: 80, convert: (v, o) => {
				if (_.get(o, 'isTotal') || _.get(o, 'taskId')) return v ? v / 60 : ''
				return ''
			}
		},
		{
			header: '累计投入', bind: 'sumUseTime', width: 80, convert: (v, o) => {
				if (_.get(o, 'taskId')) return <span style={{ color: Number(_.get(o, 'planTime') || 0) / 60 < (_.get(o, 'orginSumUseTime') - _.get(o, 'orginWeek') + sumWeek(obj, tableData, o)) ? 'red' : 'inherit' }}>{_.get(o, 'orginSumUseTime') - _.get(o, 'orginWeek') + sumWeek(obj, tableData, o)}</span>
				if (_.get(o, 'isTotal')) { return <span style={{ color: Number(_.get(o, 'planTime') || 0) / 60 < (_.get(o, 'orginSumUseTime') - _.get(o, 'orginWeek') + sumWeek(obj, tableData, o)) ? 'red' : 'inherit' }}>{_.get(o, 'orginSumUseTime') - _.get(o, 'orginWeek') + sumWeek(obj, tableData, o)}</span> }
				return ''
			}
		},
		{
			header: '本周投入', bind: 'createUser', width: 80,
			convert: (v, o, i) => {
				let num = 0;
				_.forEach(obj, (item, i2) => {
					let v = _.get(tableData, `[${i}][whichDay${item.date.split('-').join('')}]`)
					v = v ? parseFloat(v) : 0;
					num += v;
				})
				if (_.get(o, 'isTotal')) {
					return <span className="primary">{sumWeek(obj, tableData, o)}</span>
				}
				return <span className="primary">{num}</span>
			}, class: 'weekTotal'
		},
	]
}

// data 合并
function dataCon(menu, tab) {
	menu = _.get(menu, 'children');
	if (_.isEmpty(menu)) return tab;
	// 1: 根据项目分类
	// 2：把三级目录分入到列表   有的不变，没有的添加
	// 3: 分割tab
	let _tab = [];
	_.forEach(tab, it => {
		let has = _.some(_tab, it2 => {
			let state = false;
			if (it2.projectId === it.projectId) {
				state = true;
				it2.list.push(it)
			}
			return state;
		})
		if (has) return;
		_tab.push({ projectId: it.projectId, list: [it] })
	})
	tab = _.map(_tab, it => {
		let list = _.get(it, 'list');
		_.forEach(menu, it2 => {
			let _state = false;
			list = _.map(list, it3 => {
				if (it3.jobType === it2.id) {
					_state = true;
					it3.taskName = it2.name
				}
				return it3;
			})
			if (_state) return;
			let orgObj = { taskName: it2.name, jobType: it2.id };
			let nowObj = _.pick(it.list[0], ['planTime', 'projectId', 'projectName_2', 'projectName', 'projectId','sumUseTime'])
			if (_.get(it, 'list[0].taskName')) it.list.push(_.assign({}, nowObj, orgObj))
			else it.list[0] = _.assign({}, nowObj, orgObj)

		})
		const orginSumUseTime = _.get(it,'list[0].sumUseTime',0)/60
		const orginWeek = _.reduce(_.get(it, 'list'), (acc, item) => {
			const oneDay = _.reduce(_.entries(item).filter(x => _.get(x, '0', '').includes('whichDay')), (acc, o) => {
				acc += _.toNumber(o[1])
				return acc
			}, 0)
			acc += oneDay || 0
			return acc
		}, 0)
		it.list.unshift({ ..._.pick(it.list[0], ['planTime', 'projectId', 'projectName_2', 'projectName', 'projectId']), isTotal: true, orginSumUseTime, orginWeek })
		return it;
	})
	let _res = [];
	_.forEach(_tab, it => {
		_res = _.concat([], _res, it.list)
	})
	return _res;
}

function addSumOrign(data) {
	return _.map(data, x => {
		const orginSumUseTime = (_.toNumber(_.get(x, 'sumUseTime')) || 0) / 60
		const orginWeek = _.reduce(_.entries(x).filter(x => _.get(x, '0', '').includes('whichDay')), (acc, o) => {
			acc += _.toNumber(o[1])
			return acc
		}, 0)
		return { ...x, orginSumUseTime, orginWeek }
	})
}

let _childData = {};
let minDay = '';
let typeList = {
	15: 'DEV',
	28: 'PRESALES',
	2001: 'SERVICE',
}

// 项目排序
function compare(key) {
	return function (value1, value2) {
		var val1 = value1[key];
		var val2 = value2[key];
		return val1 - val2;
	}
}

function Project(props) {
	const { pid, childData } = props;
	const [flag, setFlag] = useState(null)
	const [ajax, setAjax] = useState({})
	const [tableData, setTableData] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [option, setOption] = useState(null)
	const editItem = useRef(null)
	const [key, setKey] = useState(1);
	const [update, setUpdate] = useState(0);
	const [memo, setMemo] = useState(null)
	const date = useRef('')
	const [searchText, setSearchText] = useState(null);
	const [markable, setMarkable] = useState(false)
	let maxDay = useRef('');
	_childData = childData;

	const init = useCallback((data, obj) => {
		let par = {
			jobType: _.get(_childData, 'id'),
			jobTypePid: pid,
			beginDate: data.beginDate,
			endDate: data.endDate,
			projectType: typeList[_.get(_childData, 'id')]
		}
		let _state = 'taskName';
		setOption(null);

		if (_.get(_childData, 'id') === 2001) {
			_state = 'jobType';
		}
		Api.get(`${mockUrl}/worktime/project/detail?${strParams(par)}`).then(res => {
			setOrgData(_.cloneDeep(_.filter(res.data, x => x.id)));
			res.data = _.map(res.data, item => {
				if (_.get(item, 'auditUseTime')) item.auditUseTime = (item.auditUseTime / 60)
				return item
			})
			res.data = res.data.sort(compare('projectId'))
			let _data = null;
			_data = getTabData(res.data, 'projectName', ['projectName', _state]);
			// 只有实施项目合并菜单
			if (_.get(_childData, 'id') === 2001) {
				_data = dataCon(_childData, _data);
			} else {
				_data = addSumOrign(_data)
			}
			// 过滤没有taskId的项目
			setTableData(_data)
			getOpt(_data)
			setAjax({ loading: false })
		}).catch((err) => {
			Messager.show(err.Message, { icon: 'error' });
			setAjax({ loading: false })
		})

		function getOpt(_data) {
			if (minDay) {
				return setOption(getCol(obj, _data, setTableData, optionFor, maxDay.current, 0, ['projectId', _state]));
			}

			// 获取最大修改日期
			let par = { paraId: 'WorkTimeDateline' }
			Api.get(`/common/globalpara?${strParams(par)}`).then(res => {
				maxDay.current = _.get(res, 'data.paraValue')
				setOption(getCol(obj, _data, setTableData, optionFor, maxDay.current, 0, ['projectId', _state]));
			}).catch((err) => {
				Messager.show(err.Message, { icon: 'error' });
			})
		}
	}, [pid])

	useEffect(() => {
		setFlag(_.assign({}, { flag: '' }))
	}, [props])

	useEffect(() => {
		if (_.isNil(flag)) return;
		setAjax({ loading: true })
		let par = { beginDate, flag: flag.flag };
		Api.get(`/worktime/plandate?${strParams(par)}`).then(res => {
			date.current = res.data;
			beginDate = _.get(res, 'data.beginDate');
			let _week = getAllDate(res.data);
			init(res.data, _week);
		}).catch((err) => {
			Messager.show(err.Message, { icon: 'error' });
		})
	}, [flag, init])

	function search() {
		if (overLineCheck()) return Messager.show(OVERT_TIPS, { icon: 'error' })
		let params = getData(orgData, tableData, _.get(_childData, 'id'), _.get(_childData, 'name'));
		// 过滤没有改变的数据  actionType 不纯在的
		params = _.filter(params, item => item.actionType);
		if (_.isEmpty(params)) return Messager.show('请修改后提交', { icon: 'error' })
		setAjax({ loading: true })
		Api.post('/worktime/edit', params).then(res => {
			init(date.current, getAllDate(date.current))
			Messager.show(res.msg, { icon: 'success' })
		}).catch(err => {
			console.log(err)
			Messager.show(err._message, { icon: 'error' })
		})
		function overLineCheck() {
			if (_.get(_childData, 'id') !== 2001) {
				return _.some(tableData, lessThanPlanTime)
			}
			else {
				return _.some(_.filter(tableData, x => x.isTotal), lessThanPlanTime)
			}
			function lessThanPlanTime(x) {
				const sumTime = sumWeek(getAllDate(date.current), tableData, x)
				return Number(_.get(x, 'planTime') || 0) / 60 < (_.get(x, 'orginSumUseTime') - _.get(x, 'orginWeek') + sumTime)
			}
		}
	}

	const filterDate = useMemo(() => {
		if (hasData(tableData)) {
			let list = _.concat([], tableData);
			if (searchText) {
				list = _.filter(list, item => {
					return _.includes(item.taskName, searchText)
				})
			}
			return list
		}
		return []
	}, [tableData, searchText])
	useEffect(() => {
		if (searchText && hasData(tableData)) {
			tableData.forEach(x => x._rowClass = '');
			editItem.current = null;
			setMemo('');
			setMarkable(false)
		}
	}, [searchText, tableData])

	useEffect(() => {
		if (editItem.current) {
			setTableData(x => {
				_.find(x, item => `${item.projectId}`.concat(item.taskName) === editItem.current).remark = memo;
				return [...x]
			})
		}
	}, [memo, setTableData])

	useEffect(() => {
		setKey(x => x + 1)
		setMemo('')
		editItem.current = null
		setMarkable(false)
	}, [setKey, flag, setMarkable])

	useEffect(() => {
		if (editItem.current && tableData) {
			setMarkable(_.filter(_.entries(tableData.find(item => `${item.projectId}`.concat(item.taskName) === editItem.current)), x => x[0].includes('whichDay')).some(x => x[1]))
		}
	}, [tableData, setMarkable, update])

	return <Card className="work_Time_list" loading={ajax.loading}>
		<Option
			flag={_.get(flag, 'flag') || ''}
			weekChange={(v) => setFlag({ flag: v })}
			search={search}
			inpSearch={setSearchText}
		/>
		<div className="list-con">
			<Box className='flex-y x-card-singlegrid' data={filterDate} loading={!option || ajax.loading}>
				<DataGrid option={option} data={filterDate} className='row-fill choice' key={key}
					onRowClick={(v) => {
						_.forEach(filterDate, e => e._rowClass = '');
						v._rowClass = 'deep';
						editItem.current = `${v.projectId}`.concat(v.taskName);
						setUpdate(x => x + 1);
						setMemo((_.isNull(_.get(v, 'remark', '')) ? '' : _.get(v, 'remark')))
					}}

				/>
			</Box>
			<Memo onChange={setMemo} value={memo} markable={markable} />
		</div>
	</Card>
}


const getData = (orgData, tableData, pid, name) => {
	let _data = _.cloneDeep(tableData);
	// 1: tableData 分解
	let dataList = [];
	_.forEach(_data, it => {
		_.forIn(it, (v, k) => {
			if (_.includes(k, 'whichDay')) {
				let _par = {
					workDate: k.split('Day')[1],
					auditUseTime: v,
					remark: it.remark,
					jobType: _.get(it, 'jobType') || pid
				}
				let preData = {};
				if (name === '实施工作') preData = orgData.find(x => x.projectId === it.projectId && x.jobType === it.jobType && x.workDate === _par.workDate)
				else preData = orgData.find(x => x.projectId === it.projectId && x.taskName === it.taskName && x.workDate === _par.workDate)
				dataList.push(_.assign({}, _.omit(it, ['auditUseTime', 'workDate', 'id']), _.omit(preData, ['auditUseTime', 'workDate']), _par));
			}
		})

	})
	let list = _.map(dataList, it => {
		const flag = _.find(orgData, it2 => {
			if (name === '实施工作') return it2.projectId === it.projectId && it2.jobType === it.jobType && it2.workDate === it.workDate
			return it2.workDate === it.workDate && it2.projectId === it.projectId && it2.taskName === it.taskName
		})
		if (!flag && _.toNumber(it.auditUseTime)) {
			it.actionType = 'ADD';
			it.content = name;
			it.status = 'approve';
			it.auditUseTime = it.auditUseTime * 60;
			it.projectType = typeList[pid];
			it = _.pick(it, ['jobType', 'workDate', 'content', 'remark', 'auditUseTime', 'status', 'custProjectId', 'projectId', 'projectType', 'taskId', 'actionType',])
		}
		else {
			if (flag && _.toNumber(it.auditUseTime) && ((_.toNumber(flag.auditUseTime) / 60 !== _.toNumber(it.auditUseTime)) || flag.remark !== it.remark)) {
				it.actionType = 'EDIT';
				it.auditUseTime = it.auditUseTime * 60;
				it = _.pick(it, ['id', 'auditUseTime', 'actionType', 'remark'])
			}
			if (flag && flag.auditUseTime && !_.toNumber(it.auditUseTime)) {
				it.actionType = 'DEL';
				it = _.pick(it, ['id', 'actionType'])
			}
		}
		return it;
	})
	return list;
}

export default Project

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog,Messager,Loader} from 'rootnet-ui'
import {Form, FormInput, Grid, Select} from "rootnet-edit";
import _ from "lodash";
import {Icon} from "../../../components";
import TextAreaWithTooltip from "../common/TextAreaWithTooltip";
import {useApi, useGet} from "../../../utils/hook";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";

const HFormInput = (props) =>  <FormInput horizontal componentWidth={280} {...props}/>

const OPTIONS_URLS = [
    '/common/globalconst?globalConst=mysqlType',
    '/common/globalconst?globalConst=sqlserverType',
    '/common/globalconst?globalConst=oracleType',
]

const WHICH_OPTIONS = {
    'add': '0',
    'edit': '1'
}

function EditDialog(props) {
    const {close, mode, initData, refresh, approvalMode, checkFlag} = props
    const isEdit = mode === 'edit'
    const {data: optionRes} = useGet(OPTIONS_URLS)
    const [formData, setFormData] = useState(initData)
    const [error, setError] = useState()
    const {doFetch} = useApi()
    const {data: uniqListRes, doFetch: getUniqListRes} = useGet()
    const [loading, setLoading] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(false)

    const [mysqlTypeOptions, sqlserverOptions, oracleOptions] = useMemo(()=>{
        if(_.isEmpty(optionRes)) return []
        return _.map(optionRes, x => convertGlobalConstOptions(x))
    },[optionRes])

    useEffect(()=>{
        getUniqListRes('/fieldCommon/getName?whichPage=2')
    },[getUniqListRes])

    const uniqList = useMemo(()=>{
        if(_.isEmpty(uniqListRes)) return []
        return _.map(uniqListRes, x => x.code)
    },[uniqListRes])

    const check = useCallback(()=>{
        if(checkFlag === '1'){
            if(!_.get(formData, 'memo')){
                Messager.show('请填写必填项', { icon: 'error' })
                return false
            }
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }else{
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }
    },[error, checkFlag, formData])

    useEffect(()=>{
        if(!isEdit && _.get(formData,'fieldTypeCode')){
            if(_.includes(uniqList, formData.fieldTypeCode)){
                setError(x=>_.assign({},x,{'fieldTypeCode': '根网数据类型重复'}))
            }
            if(!/^R_.*/.exec(formData.fieldTypeCode)){
                setError(x=>_.assign({},x,{'fieldTypeCode': '根网数据类型必须以R_开头'}))
            }
        }
        const dbList = _.pick(formData,['mysqlType','sqlserverType','oracleType'])
        if(_.isEmpty(dbList) || _.every(dbList, _.isNil)){
            setError(x=>_.assign({},x,{'dbRequired': '请至少选择一种数据库类型'}))
        }else{
            setError(x=>_.assign({},x,{'dbRequired': null}))
        }
    },[formData,uniqList, isEdit])

    const submit = useCallback(()=>{
        if(!check()) return
        const isPending = approvalMode === 'pending'
        const postParams = {...formData,whichPage:'2',whichOption:WHICH_OPTIONS[mode],isAgree: isPending? '0': null,isApproval: checkFlag}
        const url = approvalMode === 'default' || mode === 'add' || _.get(formData,'auditFlag') === '1' ? '/fieldType/save' : '/field/updateAudit'
        setLoading(true)
        setSubmitDisable(true)
        doFetch(url, 'post',postParams).then(()=>{
            setSubmitDisable(false)
            Messager.show(checkFlag==='1'?`数据已提交，待审核`:'提交成功，已入库', { icon: 'success' })
            refresh()
            close()
        }).catch(err => {
            setSubmitDisable(false)
            Messager.show(err._message, { icon: 'error' });
            setLoading(false)
        })
    },[check,doFetch,formData,close,mode,refresh,approvalMode,checkFlag])

    return <Dialog header={isEdit?'修改':'新增'} className='rootnet-data-type-edit-dialog' cancel={close} confirm={submit} confirmButtonDisabled={submitDisable} confirmButtonText={submitDisable?'提交中':'提交'}>
        <Form value={formData} onChange={setFormData} error={error} onError={setError}>
            <Grid cols={1}>
                <div style={{height: 16}}>
                    <div style={{marginLeft: 118, display: _.get(error,'fieldTypeCode')?'block':'none'}} className={'form-error-msg'}>
                        <Icon name='tixing' style={{marginRight: 5}}/>
                        {_.get(error,'fieldTypeCode')}
                    </div>
                </div>
                <HFormInput label='根网数据类型' bind='fieldTypeCode' required disabled={isEdit} defaultValue={'R_'}/>
                <HFormInput label='类型名称' bind='fieldTypeName' required/>

                <div style={{height: 16}}>
                    <div style={{marginLeft: 118, display: _.get(error,'dbRequired')?'block':'none'}} className={'form-error-msg'}>
                        <Icon name='tixing' style={{marginRight: 5}}/>
                        {_.get(error,'dbRequired')}
                    </div>
                </div>

                <HFormInput label='mysql类型' bind='mysqlType' component={Select} options={mysqlTypeOptions} search clear/>
                <HFormInput label='sqlserver类型' bind='sqlserverType' component={Select} options={sqlserverOptions} search clear/>
                <HFormInput label='oracle类型' bind='oracleType' component={Select} options={oracleOptions} search clear/>
                <HFormInput label='字段长度' bind='normLength' type='number'/>
                <HFormInput label='精度' bind='normPrecision' type='number'/>
                <HFormInput label='类型说明' bind='dictTypeDesc' component={TextAreaWithTooltip} value={_.get(formData, 'dictTypeDesc')} className={'desc-textarea'}/>
                <HFormInput label='申请理由' bind='memo' component={TextAreaWithTooltip} value={_.get(formData, 'memo')} className={`desc-textarea ${checkFlag === '1'? 'desc-required' :''}`} required={checkFlag === '1'}/>
            </Grid>
        </Form>
        {loading && <Loader fill text={'提交中...'}/>}
    </Dialog>
}

function checkRequire(error){
    if(_.some(_.values(error),x=>x)){
        const errMsg = _.find(_.values(error), x=>!_.isNil(x))
        Messager.show(errMsg==='必填项！'?'请填写必填项':errMsg, { icon: 'error' })
        return false
    }
    return true
}

export default EditDialog;
import React from 'react';
import { useHistory } from "react-router-dom";
import { LinkButton } from 'rootnet-ui';
import { Icon } from '../../../components';
import { pathParamsFor } from "../../../utils/publicFun";
import Modify from "./controls/Modify";
import BaseInfo from "./controls/BaseInfo";
import TaskTab from "./controls/TaskTab";
import Milestone from "./controls/Milestone";
import './Approval.scss';

//项目 --审批详情页面
function Approval(props) {
    const history = useHistory();
    let { location: { search = {} } } = history;
    search = pathParamsFor(search);
    search.auditMemo = search.auditMemo ? unescape(search.auditMemo) : '';
    
    return <div className="approval x flex-y">
        <LinkButton className="router-back" circle onClick={() => history.go(-1)}>
            <Icon name="erjititlefanhui" />
        </LinkButton>
        <div className="approval-con">
            <div className="con-left">
                <Modify title="变更记录" search={search} />
                <BaseInfo title="基本信息" search={search} />
                <Milestone title="里程碑" search={search}/>
                <TaskTab title="任务明细" search={search} />
            </div>
            <div className="con-right"></div>
        </div>
    </div>
}

export default Approval;
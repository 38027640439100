import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { FormInput, Select } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { DataGrid, Dialog, Messager, Pagination, Button } from 'rootnet-ui'
import { selectOption } from '../../../../../../../common/commonMethod'
import { Box } from '../../../../../../../common/commonComponent'
import { strParams } from '../../../../../../../../utils/publicFun'


const RELATED_REQ_URL = '/implement/project/related/requirement' //关联需求
const RELATED_ISSUE_URL = '/issue/relationIssueDevProject' //关联ISSUE

function getColumns(props) {
  return [
    { selection: true },
    { header: '编号', bind: 'number', width: 180, tooltip: true, sortable: true, },
    { header: '标题', bind: 'title', width: 200, tooltip: true, sortable: true, },
    { header: '类型', bind: 'typeName', width: 120, tooltip: true, sortable: true, },
    { header: '创建人', bind: 'createUser', width: 120, tooltip: true, sortable: true, },
    {
      header: '创建时间', bind: 'createTime', width: 150, tooltip: true, sortable: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.dateStringToDate(v))
    },
  ]
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function ImportPlannedProject(props) {
  const { close, id, projectId, refreshList, } = props // allList
  const [params, setParams] = useState(getInitParams())
  const [selectedList, setSelectedList] = useState()
  const { data: globalConstOptionsRes, doFetch: getOption } = useGet()
  const { data: listRes, doFetch: getList, error } = usePost()
  const { doFetch } = usePost()
  const [listLoading, setListLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const { pageNum, pageSize, total, rows: list } = useMemo(() => listRes || {}, [listRes])

  useEffect(() => {
    const url = ['/develop/release/list?versionType=1']
    getOption(url)
  }, [getOption])

  const [versionOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return [selectOption(_.filter(_.head(globalConstOptionsRes), o => o.stateKey === 'L'), ['releaseName', 'releaseId'])]
  }, [globalConstOptionsRes])

  useEffect(() => {
    if (_.isNil(_.get(params, 'proReleaseId'))) return
    setListLoading(true)
    const searchParams = _.assign({}, {
      ...params,
      projectId,
      // releaseId: currentId,
      type: 'PLAN'
    })

    getList('/releaseinfo/gerRelationInfo', searchParams).then(res => {
      const list = _.get(res, 'rows')
      setSelectedList(old => {
        const resultIdList = _.map(list, 'id')
        return _.map(old, item => {
          if (_.includes(resultIdList, item.id)) {
            return _.find(list, x => x.id === item.id)
          } else {
            return item
          }
        })
      })
      setListLoading(false)
    }).catch(err => {
      setListLoading(false)
      Messager.show(err._message, { icon: 'error' })
    })
  }, [params, getList, projectId])

  const options = useMemo(() => {
    return getOptions(getColumns())
  }, [])

  const submit = useCallback(() => {
    if (_.isEmpty(selectedList)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
    if (submitLoading) return
    setSubmitLoading(true)
    const urlAndParamsArr = getTypeParams({ id, projectId, data: selectedList })
    Promise.all(_.map(urlAndParamsArr, o => doFetch(o.url, o.params)))
      .then(res => {
        setSubmitLoading(false)
        refreshList()
        close()
        Messager.show('导入成功', { icon: 'success' })
      })
      .catch(err => {
        setSubmitLoading(false)
        Messager.show(err, { icon: 'error' })
      })

  }, [id, projectId, submitLoading, refreshList, selectedList, close, doFetch])


  const newList = useMemo(() => {
    if (_.isEmpty(list)) return []
    // const idList = _.map(allList, 'id')
    // return _.filter(list, o => !_.includes(idList, o.id))
    return list
  }, [list])

  return (
    <Dialog header={'从预规划项目导入'} className={'import-from-version-dialog'}
      cancel={close} footerVisible={false} >
      <div className={'import-from-version-content flex-y'}>
        <div>
          <FormInput value={_.get(params, 'proReleaseId')} onChange={(v) => {
            setSelectedList([])
            setParams(old => ({ ...old, pageNum: 1, proReleaseId: v }))
          }} label={'选择预规划项目'} required horizontal
            component={Select} componentWidth={300} options={versionOptions} search />
        </div>
        <div className="table-wrap flex-y">
          <Box title={'清单列表'} className='flex-y x-card-singlegrid' data={list} loading={listLoading} error={error}>
            <DataGrid option={options} data={newList} selection={selectedList} onSelectionChange={setSelectedList} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
              onChange={(pageNum, pageSize) => {
                setParams(x => _.assign({}, x, { pageNum, pageSize }))
              }} />
          </Box>

        </div>
      </div>
      <div className="mock-footer flex center-y">
        <div className='text-hint'>
          仅允许预规划项目状态为”<span style={{ color: 'red' }}>立项完成</span>“的数据导入，请预规划项目的负责人，及时变更状态！
        </div>
        <div className="btn-group flex">
          <Button normal onClick={close}>取消</Button>
          <Button primary onClick={submit} disable={_.isEmpty(selectedList)}>导入</Button>
        </div>
      </div>
    </Dialog>
  )
}

function getTypeParams({ data, id, projectId }) {
  const arr = []
  const filterReqData = _.filter(data, o => o.type === 'REQ')
  const filterIssueData = _.filter(data, o => o.type === 'ISSUE')

  if (!_.isEmpty(filterReqData)) {
    const ids = _.map(filterReqData, 'number')
    const params = { funcCode: '36', projectType: 'DEV', projectId, id }
    arr.push({
      url: `${RELATED_REQ_URL}?${strParams(params)}`,
      params: ids
    })
  }
  if (!_.isEmpty(filterIssueData)) {
    const ids = _.map(filterIssueData, 'id')
    const params = { id, userProjectId: projectId, issueIds: ids }
    arr.push({ url: RELATED_ISSUE_URL, params, })
  }

  return arr
}

import _ from "lodash";
import React from "react";

export default function buildRoleData(props){
    const {allRoleRes, onSelect, selectedRoleIdList} = props
    return _.map(allRoleRes, roleInfo => ({
        title: <div className={'dept-name-wrap flex center-y'}  onClick={(e)=>{
            e.stopPropagation()
            onSelect(roleInfo,'role')
        }}>
            <div>
                {roleInfo.roleName}
                {
                    _.includes(selectedRoleIdList, roleInfo.id) &&
                    <div className={'dept-item-selected flex center'}>
                        √
                    </div>
                }
            </div>
        </div>,
        key: `role-${roleInfo.id}`,
        type: 'role',
    }))
}
import React from 'react';

function DisplayFormItem(props){
    const {label, text, textStyle, labelStyle} = props
    return <div className='display-form-item flex'>
        <div className="label" style={labelStyle}>{label}</div>
        <div className="show-text" style={textStyle}>{text || '-'}</div>
    </div>
}

export default DisplayFormItem;
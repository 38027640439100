import React, { useReducer, useState } from 'react'
import { Tabs } from 'antd'
import DevProjectRiskFollowUp from '../devProjectRiskFollowUp'
import DevProjectTodolist from '../devProjectTodolist'
import { strParams } from '../../../../../utils/publicFun'
import './index.scss'

export default function DevProjectRiskFollowUpAndTodoList(props) {
  const { history, projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions, allUserRes, isShow, currentId, updateDynamic, secondOrder = 'riskFollowUp' } = props
  const [activeTab, setActiveTab] = useState(secondOrder)
  const [countRiskFollowUp, upDateRiskFollowUp] = useReducer((x) => x + 1, 0) //风险跟进
  const [countTodolist, upDateTodolist] = useReducer((x) => x + 1, 0)//跟踪单
  return (
    <div className='dev-project-risk-follow-up-and-todo-list'>
      <Tabs
        centered
        activeKey={activeTab}
        onChange={v => {
          if (v === 'riskFollowUp') {
            upDateRiskFollowUp()
          } else if (v === 'todoList') {
            upDateTodolist()
          }
          history.replace({ pathname: '/devProjectMgt', search: strParams({ initId: currentId, firstOrder: 'riskFollowUpAndTodoList', secondOrder: v }) })
          setActiveTab(v)
        }}
        items={[
          {
            key: 'riskFollowUp',
            label: '风险跟进',
            children: <div key={countRiskFollowUp} className={'tab-content-wrap flex'}>
              <DevProjectRiskFollowUp {...{ projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions, allUserRes, isShow }} />
            </div>
          },
          {
            key: 'todoList',
            label: '问题/事项跟踪',
            children: <div key={countTodolist} className={'tab-content-wrap flex'}>
              <DevProjectTodolist {...{ projectId: currentId, updateDynamic, isShow }} />
            </div>,
          },
        ]} />
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { DataGrid } from 'rootnet-ui'
import Echarts from 'echarts-for-react'
import { N2 } from 'rootnet-core/format'
import { Icon } from '../../../../../../components'
import { Box } from '../../../../../common/commonComponent'
import { isNil } from '../../../../../appraise/components/method'
import { TextIconBtn } from "../../../../../common/TextIconBtn";
import AnalysisStandardDialog from "./analysisStandardDialog/AnalysisStandardDialog";
import { useGet } from "rootnet-biz/lib/hooks";
import { strParams } from "../../../../../../utils/publicFun";
import './index.scss'
const OPEN_URL = 'http://family.croot.com/api/login/sso/oa'

function FormItem(props) {
  const { label, bind, style = {}, unit = '' } = props
  const val = isNil(bind) ? '-' : unit === '' ? bind : N2(bind)
  return <div className='total-cost-form-item flex'>
    <div className='form-item-label'>{label}</div>
    <div {...{ style }} className='form-item-bind'>{unit}{val}</div>
  </div>
}

const title = <div className='header flex'>
  <div className='title'>项目成本合计</div>
  <Tooltip title='人力成本，包含进行中、已确认工时成本；OA费用，包含审核已完成的费用报销、差旅报销、采购付款的项目费用流程。'>
    <span><Icon name='bangzhu' /></span>
  </Tooltip>
</div>

const getPercentage = (used, total) => {
  if (isNil(total)) return '-'
  const initUsed = isNil(used) ? 0 : _.toNumber(used)
  const initTotal = isNil(total) ? 0 : _.toNumber(total)
  const divide = _.divide(initUsed, initTotal)
  // const divide = _.divide((initTotal - initUsed), initTotal)
  if (!_.isFinite(divide)) return `0%`
  const percentage = _.round(divide * 100)
  const color = getColor(used, total)
  return <span style={{ color }}>{percentage}%</span>
}

const getColor = (used, total) => {
  if (isNil(total)) return '#13151A'
  const initUsed = isNil(used) ? 0 : _.toNumber(used)
  const initTotal = isNil(total) ? 0 : _.toNumber(total)
  const divide = _.divide(initUsed, initTotal)
  // const divide = _.divide((initTotal - initUsed), initTotal)
  const percentage = _.round(divide * 100)
  if (percentage >= 100) return 'red'
  // if (percentage >= 20) return '#ECA509'
  return '#469E44'
}

const isMinus = (num) => {
  const number = isNil(num) ? 0 : _.toNumber(num)
  if (number === 0) return '#13151A'
  if (number < 0) return 'red'
  return '#469E44'
}

export default function TotalCost(props) {
  const { summaryInfoExpanded, setSummaryInfoExpanded, totalCost, isPo, isPMM, isShow, projectInfo, hourData, isPm } = props
  const { projectBudget, costUsed, shuCost, humanCostUsed, reimburseCostUsed, useday, hoursUsed, offProjectBudget } = useMemo(() => totalCost || {}, [totalCost]) //confirmDay, toBeConfirmedDay,
  const {
    productUsedWorkload, devUsedWorkload, testUsedWorkload, manageUsedWorkload, //已用
    productRemainWorkload, devRemainWorkload, testRemainWorkload, manageRemainWorkload, //剩余
  } = useMemo(() => hourData || {}, [hourData])
  const [showAnalysisDialog, setShowAnalysisDialog] = useState(false)
  const { data: analysisInfo, doFetch: getAnalysisInfo } = useGet()

  const refreshAnalysisInfo = useCallback(() => {
    if (_.isNil(projectInfo)) return
    const params = {
      projectId: projectInfo.id,
      projectType: projectInfo.userprojectType,
      projectBudget: projectInfo.projectBudget || 0,
      costUsed: isNil(costUsed) ? 0 : costUsed
    }
    getAnalysisInfo('/userProject/getProjectEVA?' + strParams(params))
  }, [getAnalysisInfo, projectInfo, costUsed])

  useEffect(() => {
    refreshAnalysisInfo()
  }, [refreshAnalysisInfo])

  // const [analysisStatus] = useMemo(() => {
  //   if (_.isNil(analysisInfo)) return ['-']
  //   const CPI = _.get(analysisInfo, 'cpi')
  //   const SPI = _.get(analysisInfo, 'spi')
  //   if (CPI < 1 && SPI < 1) {
  //     return [
  //       '项目费用超支，进度落后',
  //       <div>
  //         项目团队的绩效水平差<br />
  //         测量绩效的费用基准不合理
  //       </div>
  //     ]
  //   } else if (CPI < 1 && SPI > 1) {
  //     return [
  //       '项目费用超支，进度超前',
  //       '赶工'
  //     ]
  //   } else if (CPI > 1 && SPI < 1) {
  //     return [
  //       '项目费用节约，进度落后',
  //       '资源没真投入到项目工作中'
  //     ]
  //   } else if (CPI > 1 && SPI > 1) {
  //     return [
  //       '项目费用节约，进度超前',
  //       <div>
  //         项目团队的绩效水平高<br />
  //         测量绩效的费用基准不合理
  //       </div>
  //     ]
  //   } else if (CPI === 1 && SPI === 1) {
  //     return [
  //       '项目费用与落后表现与预期相一致',
  //       '项目费用与进度表现与预期一致'
  //     ]
  //   } else {
  //     return ['-']
  //   }
  // }, [analysisInfo])

  const extra = useMemo(() => {
    return <div className={'extra-wrap flex center-y'}>
      <div className={'expand-text flex center-y'} onClick={() => setSummaryInfoExpanded(x => !x)}>
        <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{ transform: summaryInfoExpanded ? 'rotate(-90deg)' : 'rotate(90deg)' }} />
        {summaryInfoExpanded ? "收起" : '展开'}
      </div>
    </div>
  }, [summaryInfoExpanded, setSummaryInfoExpanded])

  return (
    <div className='total-cost-page'>
      <Box className='flex-y x-card-singlegrid' title={title} extra={extra} data={[0]}>
        <div className="flex-y card-content-wrap">
          <div className={'flex'}>
            <div className='total-cost-left'>
              <div className='header'>
                预算消耗
                <span className='unit' >单位：元</span>
              </div>
              <div className='content flex'>
                <div className='content-left' style={{ minWith: 250, maxWidth: 290, flex: 'none' }}>
                  <div className='residue'>
                    <span className='percentage'>{getPercentage(costUsed, projectBudget)}</span>
                    <span className='text'>已用预算</span>
                    {
                      (isShow && (isPo || isPMM || isPm)) &&
                      <span style={{ color: '#429EC7', cursor: 'pointer' }} onClick={() => window.open(`${OPEN_URL}?templateId=-5684931995845638419`)}>变更预算申请</span>
                    }
                  </div>
                  <FormItem label={'项目预算'} bind={projectBudget} unit='￥' />
                  <FormItem label={'已用成本'} bind={costUsed} unit='￥' />
                  <FormItem label={'剩余预算'} bind={shuCost} unit='￥' style={{ color: isMinus(shuCost) }} />
                  <FormItem label={'计划外预算'} bind={offProjectBudget} unit='￥' style={{ color: isMinus(offProjectBudget) }} />
                </div>
                <div className='content-right'>
                  <MoneyConsume {...{ humanCostUsed, reimburseCostUsed }} />
                </div>
              </div>
            </div>
            <div className='total-cost-right'>
              <div className='header'>
                工时消耗
                <span className='unit' >单位：人日</span>
              </div>
              <div className='content flex'>
                <div className='content-left'>
                  <div className='residue'>
                    <span className='percentage'>{getPercentage(hoursUsed, useday)}</span>
                    <span className='text'>已用工时</span>
                    {
                      (isShow && (isPo || isPMM || isPm)) &&
                      <span style={{ color: '#429EC7', cursor: 'pointer' }} onClick={() => window.open(`${OPEN_URL}?templateId=-5684931995845638419`)}>变更工时申请</span>
                    }
                  </div>
                  <FormItem label={'预估工时'} bind={useday} />
                  <FormItem label={'实际工时'} bind={hoursUsed} />
                  <FormItem label={'剩余工时'} bind={(_.toNumber(useday) - _.toNumber(hoursUsed)).toFixed(1)} style={{ color: isMinus((_.toNumber(useday) - _.toNumber(hoursUsed)).toFixed(1)) }} />
                </div>
                <div className='content-right' style={{ minWidth: 255 }}>
                  <ManDayConsume {...{
                    productUsedWorkload, devUsedWorkload, testUsedWorkload, manageUsedWorkload,
                    productRemainWorkload, devRemainWorkload, testRemainWorkload, manageRemainWorkload,
                  }} />
                </div>
              </div>
            </div>
          </div>
          <div className="net-present-value-analysis flex-y">
            <div className="card-header-text">
              项目挣值分析
            </div>
            <div className="net-present-value-analysis-content flex center-y">
              <Tooltip overlayClassName='analysis-reason-tooltip' title={<AnalysisReason />}>
                <div className="analysis-status">
                  指标说明 <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                </div>
              </Tooltip>
              {/* <div className="analysis-tips">
                <Tooltip overlayClassName='analysis-reason-tooltip' title={<AnalysisReason />}>
                  <span>
                    <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                    <span className="analysis-tips-text">说明</span>
                  </span>
                </Tooltip>
              </div> */}
              <div className="analysis-item flex">
                <div className="analysis-label">
                  <span className='analysis-classify'>CV</span>
                  <span className='analysis-text'>费用偏差</span>
                  ：
                </div>
                <div className="analysis-num">
                  {_.get(analysisInfo, 'cv', '-')}
                </div>
              </div>
              <div className="analysis-item flex">
                <div className="analysis-label">
                  <span className='analysis-classify'>SV</span>
                  <span className='analysis-text'>进度偏差</span>
                  ：
                </div>
                <div className="analysis-num">
                  {_.get(analysisInfo, 'sv', '-')}
                </div>
              </div>
              <div className="analysis-item flex">
                <div className="analysis-label">
                  <span className='analysis-classify'>CPI</span>
                  <span className='analysis-text'>成本绩效指数</span>
                  ：
                </div>
                <div className="analysis-num">
                  {_.get(analysisInfo, 'cpi', '-')}
                </div>
              </div>
              <div className="analysis-item flex">
                <div className="analysis-label">
                  <span className='analysis-classify'>SPI</span>
                  <span className='analysis-text'>进度绩效指数</span>
                  ：
                </div>
                <div className="analysis-num">
                  {_.get(analysisInfo, 'spi', '-')}
                </div>
              </div>
              {
                (isPMM || isPm) &&
                <TextIconBtn text='修改标准' icon={'bianji2'} className={'edit-btn'} onClick={() => setShowAnalysisDialog(true)} />
              }
            </div>
          </div>
        </div>
      </Box>
      {
        showAnalysisDialog &&
        <AnalysisStandardDialog close={() => setShowAnalysisDialog(false)} projectId={projectInfo?.id} {...{ refreshAnalysisInfo }} />
      }
    </div>
  )
}

function MoneyConsume(props) {
  const { humanCostUsed, reimburseCostUsed } = props

  const data = [
    { value: reimburseCostUsed, name: '报销付款' },
    { value: humanCostUsed, name: '人力' },
  ]
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: o => {
        return `${o.name}：￥${N2(_.toNumber(_.get(o, 'value', 0)))}`
      }
    },
    legend: {
      top: '5%',
      left: 'center',
      formatter: param => {
        const findItme = _.find(data, o => o.name === param)
        return `${param}：￥${N2(_.toNumber(_.get(findItme, 'value', 0)))}`
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['35%', '60%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'left'
        },
        labelLine: {
          show: false
        },
        color: ["#546FC6", "#91CD74"],
        data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  return <Echarts option={option} style={{ height: 240 }} />
}

function ManDayConsume(props) {
  const {
    productUsedWorkload, devUsedWorkload, testUsedWorkload, manageUsedWorkload,
    productRemainWorkload, devRemainWorkload, testRemainWorkload, manageRemainWorkload,
  } = props
  // const data = [
  //   { value: toBeConfirmedDay, name: '审核中' },
  //   { value: confirmDay, name: '审核完成' },
  // ]

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: [
        `产品 ${percent(productUsedWorkload, productRemainWorkload)}%`,
        `测试 ${percent(testUsedWorkload, testRemainWorkload)}%`,
        `研发 ${percent(devUsedWorkload, devRemainWorkload)}%`,
        `管理 ${percent(manageUsedWorkload, manageRemainWorkload)}%`
      ]
    },
    series: [
      {
        name: '已用',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
        },
        emphasis: {
          focus: 'series'
        },
        data: [productUsedWorkload, testUsedWorkload, devUsedWorkload, manageUsedWorkload],
        color: '#91CC75'
      },
      {
        name: '剩余',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          // formatter: (params) => Math.round(params.value * 1000) / 10 + '%'
        },
        emphasis: {
          focus: 'series'
        },
        data: [productRemainWorkload, testRemainWorkload, devRemainWorkload, manageRemainWorkload],
        color: '#73C0DE'
      },
    ]
  };

  return <Echarts option={option} style={{ height: 240 }} />
}

function getColumns(props) {
  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    { header: '状况', bind: 'condition', width: 320, tooltip: true, },
    { header: '造成状况可能原因', bind: 'cause', width: 300, tooltip: true, },
  ]
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  columns: options,
  virtualized: true,
});

const data = [
  {
    condition: 'CPI<1,SPI<1:项目费用超支，进度落后', cause: <div>
      <div>项目团队的绩效水平差</div>
      <div>测量绩效的费用基准不合理</div>
    </div>
  },
  { condition: 'CPI<1SPI>1:项目费用超支，进度超前', cause: '赶工' },
  { condition: 'CPI>1,SPI<1:项目费用节约，进度落后', cause: '资源没真投入到项目工作中' },
  {
    condition: 'CPI>1.SPI>1:页目费用节约，进度超前', cause: <div>
      <div>项目团队的绩效水平高</div>
      <div>测量绩效的费用基准不合理</div>
    </div>
  },
  { condition: 'CPI=1.SPI=1:项目费用与落后表现与预期相一致', cause: '项目费用与进度表现与预期一致' },
]

function AnalysisReason() {

  const options = getOptions(getColumns())

  return <DataGrid option={options} data={data} />
}

function percent(used, residue) {
  const total = _.add(used, residue)
  const num = _.divide(_.toNumber(used), _.toNumber(total))
  return _.divide(parseInt(((_.isNaN(num) ? 0 : num) * 100) * 10), 10)
}
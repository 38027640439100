import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DataGrid, Messager, Switch, Tooltip } from 'rootnet-ui'
import { Form, FormInput, Input, TextArea } from "rootnet-edit";
import { useApi, useGet } from "../../../../utils/hook";
import _ from "lodash";
import './ServiceDialog.scss'
import './RadioDialog.scss'
import Icon from "../../../../project_share/components/Icon";
import WaterMarkDialog from "../../../common/WaterMarkDialog";

const getTableOption = () => {
    return {
        autoFill: true,
        columns: [
            { selection: true },
            {
                header: '项目',
                bind: 'projectName',
                width: 250,
                tooltip: true
            },
            {
                header: '任务',
                bind: 'taskName',
                width: 150,
                tooltip: true
            },
            {
                header: '销售负责人',
                bind: 'sales',
                width: 90,
                tooltip: true
            },
            {
                header: '项目经理',
                bind: 'projectManager',
                width: 90,
                tooltip: true
            },
            {
                header: '系统',
                bind: 'customer',
                width: 150,
                tooltip: true
            },
            {
                header: <span>
                    可填写用时(h)<Tooltip title='本人项目授权用时 - 任务累计投入汇总' style={{ fontWeight: 500 }}><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
                </span>,
                bind: 'remainUseTime',
                align: 'right',
                convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : 0,
                width: 124
            },
            {
                header: '累计投入(h)',
                bind: 'sumUseTime',
                align: 'right',
                convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : '',
                width: 100
            },
        ]
    }
}

const INIT_FORM_DATA = {
    auditUseTime: null,
}

function ServiceDialog(props) {
    const { close, date, jobType, refresh, usedTime, cardDetail, beforeCutoffDay, isSelf, searchUser, showCutoffDay } = props

    const isAdd = useMemo(() => _.isNil(cardDetail), [cardDetail])
    const initFormData = useMemo(() => isAdd ? INIT_FORM_DATA : { auditUseTime: cardDetail.auditUseTime / 60 }, [isAdd, cardDetail])
    const initRemark = useMemo(() => isAdd ? '' : cardDetail.remark, [cardDetail, isAdd])
    const dateNoLine = useMemo(() => date.replace(/-/g, ''), [date])
    const [formData, setFormData] = useState(initFormData)
    const { data: listRes, doFetch: getList } = useGet()
    const [error, setError] = useState(null)
    const [selectedList, setSelectList] = useState([])
    const { doFetch } = useApi()
    const [remark, setRemark] = useState(initRemark)
    const [showContinueDialog, setShowContinueDialog] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [showCanSelect, setShowCanSelect] = useState(isSelf)
    const [countTime, setCountTime] = useState(0)

    useEffect(() => {
        let auditUseTime = _.toNumber(_.get(formData, 'auditUseTime'))
        if (auditUseTime <= 0) {
            setError(x => _.assign({}, x, { 'auditUseTime': '实际用时需大于0' }))
            return
        }
        if (!_.isNil(auditUseTime) && ((auditUseTime * 60 + usedTime + countTime * 60 - (isAdd ? 0 : cardDetail.auditUseTime)) > 8 * 60)) {
            setError(x => _.assign({}, x, { 'auditUseTime': '每天用时不能大于8小时' }))
            return
        }
        if (selectedList.length !== 0 && !_.isNil(auditUseTime)) {
            const remainUseTime = selectedList[0].remainUseTime / 60
            if (auditUseTime > remainUseTime) {
                setError(x => _.assign({}, x, {
                    'auditUseTime': `实际用时【${auditUseTime.toFixed(1)}小时】不能大于可填写用时【${remainUseTime.toFixed(1)}小时】，
                    请联系项目经理${selectedList[0].projectManager}进行授权`
                }))
                return
            }
        }
        setError(x => _.assign({}, x, { 'auditUseTime': null }))
    }, [formData, usedTime, selectedList, cardDetail, isAdd, countTime])

    useEffect(() => {
        if (_.some([jobType, dateNoLine], _.isNil)) return
        getList(`/worktime/project/detail?jobTypePid=25&projectType=SERVICE&jobType=2001&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`)
    }, [getList, jobType, dateNoLine, searchUser])

    const isSubString = useCallback((arr, subString) => {
        let hasSubString = false
        _.forEach(arr, item => {
            if (_.includes(_.toLower(item), _.toLower(subString))) hasSubString = true
        })
        return hasSubString
    }, [])

    const filterList = useMemo(() => {
        if (_.isNil(listRes)) return []
        let uniqList = _.uniqBy(listRes, x => x['taskId'])

        if (!isAdd) {
            // 增加该项可填写用时
            uniqList = _.map(_.uniqBy(listRes, x => x['taskId']), x => {
                const projectId = _.get(_.find(listRes, item => item.taskId === cardDetail.taskId), 'projectId')
                if (x.projectId === projectId) {
                    return ({ ...x, remainUseTime: x.remainUseTime + cardDetail.auditUseTime })
                }
                return x
            })
            // 初始选中
            const initSelect = _.filter(uniqList, x => x.taskId === cardDetail.taskId)
            if (initSelect.length === 0) {
                uniqList = [cardDetail]
            } else {
                const cardIndex = _.findIndex(uniqList, x => x.taskId === cardDetail.taskId)
                uniqList.unshift(uniqList.splice(cardIndex, 1)[0])
                if (!isSelf) uniqList = initSelect
            }
        }
        if (showCanSelect) {
            uniqList = _.filter(uniqList, x => _.toNumber(x.remainUseTime) > 0)
        }
        if (_.isNull(searchText)) return uniqList
        return _.filter(uniqList, item => isSubString(_.values(_.pick(item, ['projectName', 'taskName'])), _.trim(searchText)))
    }, [listRes, searchText, isSubString, isAdd, cardDetail, showCanSelect, isSelf])

    useEffect(() => {
        if (isAdd || _.isNil(listRes)) return
        const initSelect = _.filter(filterList, x => x.taskId === cardDetail.taskId)
        setSelectList(initSelect)
    }, [filterList, cardDetail, isAdd, listRes, close])

    const option = useMemo(() => {
        return getTableOption({});
    }, []);

    return <div>
        <WaterMarkDialog cancel={close} header={`项目（实施）（${date}）`} className='service-dialog radio-dialog' confirm={submit}
            confirmButtonDisabled={filterList.length === 0} confirmButtonVisible={isSelf}>
            <div className="right-content flex-y">
                <div className="options">
                    <div className="form-group">
                        <Form error={error} onError={setError} value={formData} onChange={handleChangeFormData}>
                            <FormInput label='实际用时(h)' bind='auditUseTime' horizontal componentWidth={120} labelWidth={100} min={0.1} type='number' digit={1} required />
                        </Form>
                    </div>
                    <div className="options-right flex center-y">
                        <Switch className='option-switch' checked={showCanSelect} onChange={setShowCanSelect} checkedComponent='可填' uncheckedComponent='全部' />
                        <Input value={searchText} onChange={setSearchText} placeholder='请搜索' prefix={<Icon name='sousuo1' className='search-icon' />} />
                    </div>
                </div>
                <div className='data-grid-wrap x-datagrid'>
                    <DataGrid data={filterList} option={option} onSelectionChange={singleChoice} selection={selectedList} />
                </div>
                <TextArea value={remark} onChange={setRemark} placeholder='请输入工作内容说明...' className='remark-input' maxLength={100000} counter={false} />
            </div>
        </WaterMarkDialog>
        {
            showContinueDialog &&
            <Dialog headerVisible={false} confirm={clear} cancel={exit} className={'content-center-dialog'}>
                增加成功，是否继续增加？
            </Dialog>
        }
    </div>

    function handleChangeFormData(formObj, bind) {
        if (bind === 'auditUseTime' && _.toNumber(_.get(formObj, 'auditUseTime')) > 8) {
            return Messager.show('实际用时上限为8h')
        }
        setFormData(formObj)
    }

    function clear() {
        const auditUseTime = _.toNumber(formData.auditUseTime)
        setCountTime(x => x + auditUseTime)
        setShowContinueDialog(false)
        setFormData(INIT_FORM_DATA)
        setSelectList([])
        setRemark('')
        getList(`/worktime/project/detail?jobTypePid=25&projectType=SERVICE&jobType=2001&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`)
    }

    function exit() {
        setShowContinueDialog(false)
        close()
    }

    function singleChoice(arr) {
        if (selectedList.length === 0) {
            setSelectList(arr)
        } else {
            const filterItem = _.find(arr, item => item.taskId !== selectedList[0].taskId)
            setSelectList(_.isNil(filterItem) ? [] : [filterItem])
        }
    }

    function check() {
        if (_.some(_.values(error), x => x)) {
            const errMsg = _.find(_.values(error), x => !_.isNil(x))
            Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
            return false
        }
        const auditUseTime = _.toNumber(formData.auditUseTime)
        if (auditUseTime < 0 || auditUseTime > 8) {
            Messager.show('实际用时需在0-8h之间', { icon: 'error' })
            return false
        }
        if (selectedList.length === 0) {
            Messager.show('请选择项目', { icon: 'error' })
            return false
        }
        return true
    }

    function submit() {
        if (beforeCutoffDay) return Messager.show(`${showCutoffDay}前的成本已核算，不允许修改工时`, { icon: 'error' })
        if (!check()) return
        const selectedItem = selectedList[0]
        const params = {
            jobType: 2001,
            workDate: dateNoLine,
            content: '实施工作-' + selectedItem.taskName,
            remark: remark,
            auditUseTime: formData.auditUseTime * 60,
            status: isAdd ? 'approve' : null,
            custProjectId: selectedItem.custProjectId,
            projectType: 'SERVICE',
            projectId: selectedItem.projectId,
            taskId: selectedItem.taskId,
            actionType: "ADD",
        }
        const postParams = isAdd ? [params] : [
            _.assign({}, params, {
                id: cardDetail.id,
                actionType: "EDIT"
            })
        ]
        doFetch('/worktime/edit', 'post', postParams).then(() => {
            Messager.show(`添加成功`, { icon: 'success' })
            refresh()
            if (isAdd) {
                setShowContinueDialog(true)
                return
            }
            close()
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
        })
    }
}

export default ServiceDialog;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './PopoverComment.scss'
import {dateFormat, toDate} from 'rootnet-core/dateFormat'
import _ from 'lodash'
import {Messager} from 'rootnet-ui';
import { Button, Mentions } from 'antd';
import {uniqKeyFor} from "../../../../project_share/utils/utils";
import {usePost} from "rootnet-biz/es/hooks";
import Icon from "../../../../components/Icon";
import gd from "../../../../base/global";
const { Option, getMentions } = Mentions;

function getNameImgColor(sex){
    if(_.isEmpty(sex)) return '#D3D3D3'
    return sex === '女'?'#FF99FF':'#8DC8EA'
}

function Comment(props){
    const {commentItem, setReplyInfo, delComment} = props

    return <div className={'comment-wrap flex'} key={uniqKeyFor()}>
        <div className="name-img flex center" style={{background: getNameImgColor(_.get(commentItem,'sex'))}}>
            {_.isEmpty(commentItem)?'':_.get(commentItem,'author')[0]}
        </div>
        <div className="comment-item-wrap">
            <div className={'comment-header flex'}>
                <div className={'comment-author'}>{commentItem.author}</div>
                <div className={'comment-datetime'}>{commentItem.datetime}</div>
            </div>
            <div className="comment-content-detail">
                {commentItem.content}
            </div>
            <div className={'comment-actions flex'}>
                {commentItem.isTopComment && <span onClick={() => setReplyInfo(commentItem)}>回复</span>}
                {commentItem.updateUser === gd._user.operator_name && <span onClick={()=>delComment(commentItem.id)}>删除</span>}
            </div>
            <div className="sub-comment">
                {
                    _.map(_.get(commentItem,'children'), childrenItem => <Comment commentItem={childrenItem} key={uniqKeyFor()} {...{delComment,setReplyInfo}}/>)
                }
            </div>
        </div>
    </div>
}

function getUserName(value){
    return _.split(_.split(value,')')[0],'(')[1] || ''
}

function PopoverComment(props) {
    const {referenceId, funcCode, allUserRes, title, linkUrl, commentText, setCommentText, close} = props
    const [commentListRes, setCommentList] = useState()
    const [replyInfo, setReplyInfo] = useState()
    const [loading, setLoading] = useState(false)
    const {doFetch} = usePost()

    const getComment = useCallback(()=>{
        const postParams = {
            referenceId, funcCode,
        }
        doFetch('/PublicMark/maintain/query',postParams).then(res => {
            setCommentList(res)
        }).catch(err => {
            Messager.show(err._message,{icon:'error'});
        })
    },[referenceId, funcCode, doFetch ])

    const commentList = useMemo(() => {
        if(_.isEmpty(commentListRes)) return null
        return _.map(commentListRes, x => ({
            isTopComment: true,
            id: x.id,
            updateUser: x.updateUser,
            author: _.get(_.find(allUserRes, item => item.userAccount === x.updateUser),'userName'),
            sex: _.get(_.find(allUserRes, item => item.userAccount === x.updateUser),'sex'),
            content: x.message,
            datetime: dateFormat('YYYY-MM-DD HH:MM:SS',toDate.str14ToDate(x.updateTime)),
            children: _.map(x.list, child => ({
                isTopComment: false,
                id: child.id,
                updateUser: x.updateUser,
                author: _.get(_.find(allUserRes, item => item.userAccount === child.updateUser),'userName'),
                sex: _.get(_.find(allUserRes, item => item.userAccount === child.updateUser),'sex'),
                content: child.message,
                datetime: dateFormat('YYYY-MM-DD HH:MM:SS',toDate.str14ToDate(child.updateTime)),
            }))
        }))
    },[commentListRes, allUserRes])

    useEffect(()=>{
        if(!_.isNil(referenceId) && !_.isNil(funcCode)) getComment()
    },[getComment,referenceId, funcCode])

    const addComment = useCallback(()=>{
        if(_.isNil(commentText) || commentText === '') return Messager.show('评论内容不能为空')
        if(loading) return
        setLoading(true)
        const postParams = {
            referenceId, funcCode, title,
            message: commentText,
            optIds: _.map( getMentions(commentText), x => getUserName(x.value)),
            pid: _.isNil(replyInfo) ? null :  _.get(replyInfo, 'id'),
            cardUrl: linkUrl
        }
        doFetch('/PublicMark/maintain/add',postParams).then(() => {
            getComment()
            setCommentText(null)
            setReplyInfo(null)
            Messager.show('添加评论成功',{icon:'success'});
            setLoading(false)
        }).catch(err => {
            Messager.show(err._message,{icon:'error'});
            setLoading(false)
        })
    },[commentText, referenceId, funcCode, doFetch, getComment, title, replyInfo, linkUrl,setCommentText, loading])

    const delComment = useCallback((id)=>{
        const postParams = {
            referenceId, funcCode, id
        }
        doFetch('/PublicMark/maintain/del', postParams).then(() => {
            getComment()
            Messager.show('删除成功',{icon:'success'});
        }).catch(err => {
            Messager.show(err._message,{icon:'error'});
        })
    },[referenceId, funcCode,doFetch,getComment])

    return <div className={'popover-comment-wrap flex-y'}>
        <div className="comment-area-header">评论</div>
        <div className={'comment-area'}>
            {
                _.map(commentList, commentItem => <Comment key={uniqKeyFor()} {...{commentItem, setReplyInfo, delComment}}/>)
            }
        </div>
        {
            !_.isNil(replyInfo) &&
            <div className={'reply-info flex center-y'}>
                <div className="reply-info-content">{`回复 ${replyInfo.author} : ${replyInfo.content}`}</div>
                <Icon className={'reply-info-del-icon'} name={'shibai'} onClick={()=>setReplyInfo(null)}/>
            </div>
        }
        <Mentions rows={3} placeholder="可以使用@通知他人" value={commentText} onChange={setCommentText}>
            {
                _.map(allUserRes, x => <Option key={x.userAccount} value={`${x.userName}(${x.userAccount})`}>
                    {x.userName}({x.userAccount})
                </Option>)
            }
        </Mentions>
        <div className={'flex center-y btn-group'}>
            <Button type="primary" className={'submit-btn'} onClick={addComment} loading={loading}>
                添加评论
            </Button>
            {
                close && <Button onClick={()=>close()}>取消</Button>
            }
        </div>
    </div>
}

export default PopoverComment;
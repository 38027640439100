import React, { useState, useMemo, useRef, useCallback, useContext, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Table, Tooltip, Popconfirm, Form, Select, Input, DatePicker } from 'antd'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { isNil } from '../../../../appraise/components/method'
import convertOptions from '../../../../common/ConvertOptions'
import { Icon } from '../../../../../components'
import { FormContext } from 'antd/lib/form/context'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import { useGet } from 'rootnet-biz/lib/hooks'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import './index.scss'

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const YesNoOptions = [
  { value: 'Y', label: '是' },
  { value: 'N', label: '否' },
]

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=problemLevel',
]

function getColumns(props) {
  const { allUserRes, editable, deletable, delItem, problemLevelOptions } = props

  const cols1 = [
    {
      title: '#',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (value, obj, index) => index + 1
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>提出人</span>,
      dataIndex: 'proposer',
      key: 'proposer',
      width: 100,
      editable: editable,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, allUserRes, 'userName', 'userAccount')}
        </div>
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>问题级别</span>,
      dataIndex: 'problemLevel',
      key: 'problemLevel',
      width: 85,
      editable: editable,
      render: (value) => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, problemLevelOptions, 'label')}
        </div>
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>问题描述</span>,
      dataIndex: 'problemDescription',
      key: 'problemDescription',
      width: 300,
      editable: editable,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <Tooltip placement="topLeft" title={value}>
          <div style={{ marginLeft: editable ? 0 : 12, whiteSpace: 'pre-wrap' }}>{value}</div>
        </Tooltip>
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>问题是否确认</span>,
      dataIndex: 'problemConfirmed',
      key: 'problemConfirmed',
      width: 120,
      editable: editable,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, YesNoOptions, 'label')}
        </div>
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>问题是否验证关闭</span>,
      dataIndex: 'verificationClosed',
      key: 'verificationClosed',
      width: 138,
      editable: editable,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, YesNoOptions, 'label')}
        </div>
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>问题处理人</span>,
      dataIndex: 'issueHandler',
      key: 'issueHandler',
      width: 100,
      editable: editable,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, allUserRes, 'userName', 'userAccount')}
        </div>
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>计划完成日期</span>,
      dataIndex: 'scheduledCompletionDate',
      key: 'scheduledCompletionDate',
      editable,
      width: 150,
      render: (value, obj) => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }
        return dateFormat('YYYY-MM-DD', toDate.str14ToDate(value))
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>验证人</span>,
      dataIndex: 'verifier',
      key: 'verifier',
      width: 100,
      editable: editable,
      render: value => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5, marginLeft: editable ? 0 : 12 }}>必填</div>
        }
        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, allUserRes, 'userName', 'userAccount')}
        </div>
      }
    },
  ]
  const delCols = [
    {
      title: '',
      width: 40,
      align: 'center',
      fixed: 'left',
      render: (value, obj) => {
        return <Popconfirm
          title="确认删除？"
          onConfirm={() => delItem(obj.id)}
          okText="删除"
          cancelText="取消"
          overlayInnerStyle={{ padding: 8, width: 200 }}
        >
          <Icon className={'issue-list-del-icon'} name='shanchu' style={{ fontSize: 16 }} />
        </Popconfirm>
      }
    }
  ]
  return _.concat(deletable ? delCols : [], cols1)
}

export default function ReviewMinutesMgtIssueList(props) {
  const { editable, deletable, allUserRes, issueList, setIssueList, updateEditRow } = props
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const [form] = Form.useForm()
  const EditableRow = useCallback(({ index, ...restProps }) => {
    const currentIndex = issueList.findIndex(x => x.id === restProps['data-row-key']);
    return <Form form={form} component={false}>
      <FormContext.Provider value={{ form: form }}>
        <SortableItem index={currentIndex} {...restProps} />
      </FormContext.Provider>
    </Form>
  }, [form, issueList])

  const [problemLevelOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const handleSave = useCallback((row, bind) => {
    if (updateEditRow) {
      updateEditRow(row)
    }
    const index = _.findIndex(issueList, x => x.id === row.id)
    setIssueList(oldList => {
      const newList = _.map(oldList, (item, i) => {
        if (i !== index) return item
        return ({
          ...item,
          ...row
        })
      })
      return _.sortBy(newList, 'serial')
    })
  }, [updateEditRow, issueList, setIssueList])

  const delItem = useCallback((delId) => {
    setIssueList(oldList => _.filter(oldList, x => x.id !== delId))
  }, [setIssueList])

  const columns = useMemo(() => {
    return _.map(getColumns({ editable, deletable, allUserRes, delItem, problemLevelOptions }), col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          showList: issueList,
          problemLevelOptions,
        })
      }
    })
  }, [editable, deletable, allUserRes, delItem, handleSave, issueList, problemLevelOptions])

  return (
    <div className='review-minutes-issue-list'>
      <Table
        size={'small'}
        rowClassName={'editable-row'}
        columns={columns}
        pagination={false}
        dataSource={issueList}
        rowKey="id"
        sticky={true}
        scroll={{ x: 700 }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell
          }
        }}
      />
    </div>
  )
  function DraggableContainer(props) {
    return <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      {...props}
    />
  }
}

function EditableCell(props) {
  const { title, must, editable, dataIndex, record, children, handleSave, problemLevelOptions, ...restProps } = props
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const { form } = useContext(FormContext || {})

  useEffect(() => {
    if (editing) {
      if (!_.isNil(inputRef.current)) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  const toggleEdit = useCallback(() => {
    setEditing(x => !x);
    let value
    if (dataIndex === 'scheduledCompletionDate') {
      value = _.isNil(record[dataIndex]) ? null : moment(record[dataIndex], 'YYYY-MM-DD')
    } else {
      value = record[dataIndex]
    }
    form.setFieldsValue({ [dataIndex]: value });
  }, [dataIndex, record, form])

  const save = () => {
    try {
      form.validateFields().then(changeObj => {
        const key = _.head(_.keys(changeObj))
        if (key === 'scheduledCompletionDate') {
          changeObj[key] = _.isNil(changeObj[key]) ? null : moment(changeObj[key]).format('YYYYMMDDHHMMSS')
        }
        toggleEdit();
        if (changeObj[key] !== record[key]) {
          handleSave({ ...record, ...changeObj }, key);
        }
        if (!_.isNil(inputRef.current)) {
          inputRef.current.blur()
        }
        setEditing(false)
      })
    } catch (errInfo) {
      console.error(errInfo);
    }
  }

  let childNode = children;
  if (editable) {
    if (editing) {
      let editNode
      if (_.includes(['proposer', 'issueHandler', 'verifier'], dataIndex)) {
        editNode = <UserSelect ref={inputRef} onChange={save} defaultOpen={true} onBlur={() => setEditing(false)} />
      } else if (dataIndex === 'problemLevel') {
        editNode = <Select ref={inputRef} options={problemLevelOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditing(false)} />
      } else if (dataIndex === 'problemDescription') {
        editNode = <Input.TextArea ref={inputRef} onBlur={save} />
      } else if (_.includes(['problemConfirmed', 'verificationClosed'], dataIndex)) {
        editNode = <Select ref={inputRef} options={YesNoOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditing(false)} />
      } else if (dataIndex === 'scheduledCompletionDate') {
        editNode = (<DatePicker ref={inputRef} onChange={save} allowClear={false} open={true} onBlur={() => setEditing(false)} autoFocus />)
      }
      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: must,
              message: `${title}不能为空`,
            },
          ]}
        >
          {editNode}
        </Form.Item>
      )
    } else {
      childNode = (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>
}

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './WorkflowEditablePanel.scss'
import WorkflowEditableLeftPanel from "./workflowEditableLeftPanel/WorkflowEditableLeftPanel";
import {Graph, Shape} from "@antv/x6";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import {InputNumber, Radio} from "antd";
import WorkFlowEditDialog from "../../workFlowEditDialog/WorkFlowEditDialog";

// #region 初始化图形
const ports = {
    groups: {
        top: {
            position: 'top',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
        right: {
            position: 'right',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
        bottom: {
            position: 'bottom',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
        left: {
            position: 'left',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
    },
    items: [
        {
            group: 'top',
        },
        {
            group: 'right',
        },
        {
            group: 'bottom',
        },
        {
            group: 'left',
        },
    ],
}

Graph.registerNode(
    'custom-big-rect',
    {
        inherit: 'rect',
        width: 80,
        height: 50,
        attrs: {
            body: {
                strokeWidth: 1,
                stroke: '#5F95FF',
                fill: '#EFF4FF',
            },
            text: {
                fontSize: 12,
                fill: '#262626',
            },
        },
        ports: {
            ...ports,
            items: [
                {
                    group: 'top',
                    id: 'topLeft',
                },
                {
                    group: 'top',
                    id: 'top',
                },
                {
                    group: 'top',
                    id: 'topRight',
                },
                {
                    group: 'right',
                    id: 'rightTop',
                },
                {
                    group: 'right',
                    id: 'right',
                },
                {
                    group: 'right',
                    id: 'rightBottom',
                },
                {
                    group: 'bottom',
                    id: 'bottomLeft',
                },
                {
                    group: 'bottom',
                    id: 'bottom',
                },
                {
                    group: 'bottom',
                    id: 'bottomRight',
                },
                {
                    group: 'left',
                    id: 'leftTop',
                },
                {
                    group: 'left',
                    id: 'left',
                },
                {
                    group: 'left',
                    id: 'leftBottom',
                },
            ],
        },
    },
    true,
)

function WorkflowEditablePanel(props) {
    const {graphData, setGraphData, forceRender, workflowId, fieldList, convertCollection, funcCode} = props
    const graphContainer = useRef()
    const [currentContextMenuCell, setCurrentContextMenuCell] = useState()
    const graphRef = useRef()
    const onlyIdRef = useRef()
    const {doFetch: getOnlyId} = useGet()
    const [lineType, setLineType] = useState('center')
    const lineTypeRef = useRef()
    const [lineRouter, setLineRouter] = useState('normal')
    const lineRouterRef = useRef()
    const [routerPadding, setRouterPadding] = useState(60)
    const routerPaddingRef = useRef()

    useEffect(()=>{
        setTimeout(()=>{
            if(graphRef.current){
                graphRef.current.fromJSON(graphData)
            }
        },1000)
        // eslint-disable-next-line
    },[forceRender])

    const getNewOnlyId = useCallback(()=>{
        getOnlyId('/test_case/productGetOnlyId').then(id => onlyIdRef.current = id)
    },[getOnlyId])

    useEffect(()=>{
        getNewOnlyId()
    },[getNewOnlyId])

    useEffect(()=>{
        lineTypeRef.current = lineType
    },[lineType])

    useEffect(()=>{
        lineRouterRef.current = lineRouter
    },[lineRouter])

    useEffect(()=>{
        routerPaddingRef.current = routerPadding
    },[routerPadding])

    const createEdge = useCallback(()=>{
        getNewOnlyId()
        let routerSettingArgs = {}
        if(lineTypeRef.current === 'center'){
            routerSettingArgs = {
                offset: 'center',
            }
        }else if(lineTypeRef.current === 'left'){
            routerSettingArgs = {
                side: 'left',
                padding: routerPaddingRef.current,
            }
        }else if(lineTypeRef.current === 'right'){
            routerSettingArgs = {
                side: 'right',
                padding: routerPaddingRef.current,
            }
        }else if(lineTypeRef.current === 'top'){
            routerSettingArgs = {
                side: 'top',
                padding: routerPaddingRef.current,
            }
        }else if(lineTypeRef.current === 'bottom'){
            routerSettingArgs = {
                side: 'bottom',
                padding: routerPaddingRef.current,
            }
        }
        return new Shape.Edge({
            attrs: {
                line: {
                    stroke: '#A2B1C3',
                    strokeWidth: 2,
                    targetMarker: {
                        name: 'block',
                        width: 12,
                        height: 8,
                    },
                },
            },
            id: onlyIdRef.current,
            zIndex: 0,
            router: {
                name: lineRouterRef.current,
                args: routerSettingArgs
            }
        })
    },[getNewOnlyId])

    useEffect(()=>{
        if(_.isNil(graphContainer)) return

        // #region 初始化画布
        const graph = new Graph({
            container: graphContainer.current,
            grid: true,
            mousewheel: {
                enabled: true,
                zoomAtMousePosition: true,
                modifiers: 'ctrl',
                minScale: 0.5,
                maxScale: 3,
            },
            width: '100%',
            height: '100%',
            background:{
                color: '#f5f5f5'
            },
            connecting: {
                router: {
                    name: 'manhattan',
                    args: {
                        padding: 1,
                    },
                },
                connector: {
                    name: 'rounded',
                    args: {
                        radius: 8,
                    },
                },
                anchor: 'center',
                connectionPoint: 'anchor',
                allowBlank: false,
                snap: {
                    radius: 20,
                },
                createEdge,
                validateConnection({ targetMagnet }) {
                    return !!targetMagnet
                },
            },
            highlighting: {
                magnetAdsorbed: {
                    name: 'stroke',
                    args: {
                        attrs: {
                            fill: '#5F95FF',
                            stroke: '#5F95FF',
                        },
                    },
                },
            },
            resizing: true,
            rotating: true,
            history: true,
            selecting: {
                enabled: true,
                rubberband: true,
                showNodeSelectionBox: true,
            },
            snapline: true,
            keyboard: true,
            clipboard: true,
            panning: {
                enabled: true,
                modifiers: 'shift',
            },
        });

        // #endregion

        // #region 快捷键与事件

        //undo redo
        graph.bindKey(['meta+z', 'ctrl+z'], () => {
            if (graph.history.canUndo()) {
                graph.history.undo()
                setGraphData(graph.toJSON())
            }
            return false
        })
        graph.bindKey(['meta+shift+z', 'ctrl+shift+z'], () => {
            if (graph.history.canRedo()) {
                graph.history.redo()
                setGraphData(graph.toJSON())
            }
            return false
        })

        // select all
        graph.bindKey(['meta+a', 'ctrl+a'], () => {
            const nodes = graph.getNodes()
            if (nodes) {
                graph.select(nodes)
            }
        })

        //delete
        // graph.bindKey('backspace', () => {
        //     const cells = graph.getSelectedCells()
        //     if (cells.length) {
        //         graph.removeCells(cells)
        //     }
        // })
        graph.bindKey(['ctrl+backspace','shift+backspace','meta+backspace','meta+backspace'], () => {
            const cells = graph.getSelectedCells()
            if (cells.length) {
                graph.removeCells(cells)
                setGraphData(graph.toJSON())
            }
        })

        // 控制连接桩显示/隐藏
        const showPorts = (ports, show) => {
            for (let i = 0, len = ports.length; i < len; i = i + 1) {
                ports[i].style.visibility = show ? 'visible' : 'hidden'
            }
        }
        graph.on('node:mouseenter', () => {
            const container = graphContainer.current
            const ports = container.querySelectorAll(
                '.x6-port-body',
            )
            showPorts(ports, true)
        })
        graph.on('node:mouseleave', () => {
            const container = graphContainer.current
            const ports = container.querySelectorAll(
                '.x6-port-body',
            )
            showPorts(ports, false)
        })

        // 文本编辑
        graph.on('cell:dblclick', ({ cell, e }) => {
            const isNode = cell.isNode()
            const name = cell.isNode() ? 'node-editor' : 'edge-editor'
            cell.removeTool(name)
            cell.addTools({
                name,
                args: {
                    event: e,
                    attrs: {
                        backgroundColor: isNode ? '#EFF4FF' : '#FFF',
                        fontSize: '12',
                    },
                },
            })
        })

        // 监听数据
        graph.on('cell:change:*', ()=>{
            setGraphData(graph.toJSON())
        })

        graph.on('edge:contextmenu', ({ x, y, cell,view}) => {
            setCurrentContextMenuCell(cell)
        });

        graph.on('edge:mouseenter', ({ cell }) => {
            cell.addTools([
                {
                    name: 'source-arrowhead',
                },
                {
                    name: 'target-arrowhead',
                    args: {
                        attrs: {
                            fill: 'red',
                        },
                    },
                },
            ])
        })

        graph.on('edge:mouseleave', ({ cell }) => {
            cell.removeTool('source-arrowhead')
            cell.removeTool('target-arrowhead')
        })

        // #endregion
        if(_.isNil(graphRef.current)){
            graphRef.current = graph
        }
        // graph.fromJSON(initGraph)
        // #endregion
    },[createEdge, getNewOnlyId, setGraphData])

    // useEffect(()=>{
    //     graphRef.current.fromJSON(initGraph)
    // },[])

    // useEffect(()=>{
    //     console.log('graphRef',graphRef)
    //     graphRef.current.fromJSON(initGraph)
    // },[graphRef])

    const currentCellInfo = useMemo(()=>{
        if(_.isNil(currentContextMenuCell)) return null
        const id = _.get(currentContextMenuCell,'id')
        return _.find(_.get(graphData,'cells'),x => x.id === id)
    },[currentContextMenuCell, graphData])

    return <div className={'workflow-editable-panel flex'}>
        <WorkflowEditableLeftPanel {...{graphRef}}/>
        <div id={'graph-container'} ref={graphContainer}/>
        <div className={'line-setting flex-y'}>
            <div className="header-text">
                线段路由配置
            </div>
            <Radio.Group onChange={e => {
                setLineRouter(e.target.value)
                if(e.target.value !== 'oneSide'){
                    setLineType('center')
                }
            }} value={lineRouter}>
                <Radio value={'normal'}>默认路由</Radio>
                <Radio value={'orth'}>正交路由</Radio>
                <Radio value={'oneSide'}>单侧路由</Radio>
                <Radio value={'manhattan'}>智能正交路由</Radio>
                <Radio value={'metro'}>地铁线路由</Radio>
                <Radio value={'er'}>实体关系路由</Radio>
            </Radio.Group>
            {
                lineRouter === 'oneSide' &&
                <div className={'flex-y'}>
                    <div className="header-text">
                        线段参数配置
                    </div>
                    <Radio.Group onChange={e => setLineType(e.target.value)} value={lineType}>
                        <Radio value={'center'}>普通</Radio>
                        <Radio value={'left'}>左侧</Radio>
                        <Radio value={'right'}>右侧</Radio>
                        <Radio value={'top'}>上侧</Radio>
                        <Radio value={'bottom'}>下侧</Radio>
                    </Radio.Group>
                    <div className="header-text">
                        单侧路由偏移量
                    </div>
                    <InputNumber min={1} value={routerPadding} onChange={setRouterPadding} />
                </div>
            }
        </div>
        {/*<Button primary onClick={()=>{*/}
        {/*    console.log('json',JSON.stringify(graphData))*/}
        {/*    console.log('graphData',graphData)*/}
        {/*}}>保存</Button>*/}
        {
            !_.isNil(currentContextMenuCell) && _.get(currentContextMenuCell,'shape') === 'edge' &&
            <WorkFlowEditDialog close={()=>setCurrentContextMenuCell(null)} cellInfo={currentCellInfo} cells={_.get(graphData,'cells')}
                                {...{workflowId, fieldList, convertCollection, funcCode}}/>
        }
    </div>
}

export default WorkflowEditablePanel;
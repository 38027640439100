import React from 'react'
import { Table, ConfigProvider } from 'antd'
import { Icon } from '../../../../../components';

const customizeRenderEmpty = (clickAdd, isAdd) => (
  <div style={{ textAlign: 'center' }}>
    {
      isAdd ?
        <>
          <Icon name='tianjia' onClick={clickAdd} style={{ fontSize: 30, cursor: 'pointer', color: '#5477ff' }} />
          <p>暂无数据，请先添加数据</p>
        </> :
        <>
          <p>暂无数据，请进行选择</p>
        </>
    }
  </div>
);

export default function DragTable(props) {
  const { clickAdd, isAdd = true, ...res } = props

  return (
    <ConfigProvider renderEmpty={() => customizeRenderEmpty(clickAdd, isAdd)}>
      <Table
        size='small'
        rowClassName={() => 'editable-row'}
        pagination={false}
        rowKey='id'
        scroll={{ y: 500, x: 880 }}
        bordered
        {...res}
      />
    </ConfigProvider>
  )
}

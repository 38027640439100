import React, { useState, useCallback } from 'react'
import { Form, Input, Upload, Modal } from 'antd'
import './index.scss'

const FormItem = Form.Item

export default function Feedback(props) {
  const { form, setParams, fileList, setFileList, customRequest, deleteFile, getBase64 } = props
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handlePreview = useCallback(async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  }, [getBase64])

  const fileChange = useCallback((props) => {
    const { fileList } = props
    setFileList(fileList)
  }, [setFileList])

  return (
    <>
      <div className='problemResearch-feedback'>
        <Form form={form} onValuesChange={(v, o) => setParams(o)} >
          <FormItem name="issueDesc" >
            <div className='issue-desc'><Input size="large" placeholder="请输入问题简单描述" /></div>
          </FormItem>
          <FormItem name="reqDetail" >
            <div className='req-detail'><Input.TextArea className='l-scroll-style' placeholder='请输入详细描述' /></div>
          </FormItem>
        </Form>
      </div>
      <div style={{ marginTop: 8 }}>
        <Upload
          accept="image/*"
          customRequest={customRequest}
          listType="picture-card"
          fileList={fileList}
          onChange={fileChange}
          onRemove={deleteFile}
          onPreview={handlePreview}
        >
          {fileList.length < 5 && '+ 上传图片'}
        </Upload>
      </div>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  )
}

import {useMemo} from 'react';
import _ from "lodash";

export default function useGetOptionsConfig(allColumns) {
    return useMemo(()=>{
        if(_.isEmpty(allColumns)) return []
        return _.map(allColumns, x => ({
            label: x.fieldName,
            bind: `${x.tableName}.${x.fieldId}`,
            formQueryType: x.formQueryType,
            fieldHtmlType: x.fieldHtmlType,
            fieldDateSource: x.fieldDateSource,
            fieldDateDetailSource: x.fieldDateDetailSource,
            custom: x.custom,
            filterDisplayFlag: x.filterDisplayFlag,
            viewDisplayFlag: x.viewDisplayFlag,
            viewConditionType: x.viewConditionType,
        }))
    },[allColumns])
}
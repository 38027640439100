import _ from 'lodash'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'

export function projectSummaryInit(data) {
  const filterData = _.filter(data, o => (o.isTrue === '0' && (_.isNil(o.projectStatus) || o.projectStatus === '01')))
  return _.concat(..._.map(_.groupBy(filterData, 'projectId'), (arr, key) => {
    return _.concat(..._.map(_.groupBy(arr, 'type'), (val, k) => {//projectType
      return _.assign(..._.concat(..._.map(_.groupBy(val, 'evaluationDate'), (v, j) => {
        const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(j)), '-')
        const month = `${getDate[0]}-${getDate[1]}`
        const item = _.head(v)
        return _.assign(
          {},
          _.omit(item, ['evaluationDate']),
          {
            [`workloadOccupied,${month}`]: summaryBind(v, 'workloadOccupied'),
            [`manpowerPipelineId${month}`]: item.manpowerPipelineId,
            [`id${month}`]: item.id,
            evaluationDate: month
          }
        )
      })), {
        pipelineEstimatesTotalSummary: summaryBind(val, 'pipelineEstimatesTotal'),
        workloadOccupiedSummary: summaryBind(val, 'workloadOccupied'),
      })
    }))
  }))
}

export function summaryProjectInitData(data) {
  const listName = [
    { '占用评估汇总': 'workloadOccupiedTotal' },
    // { '部门人力汇总': 'departmentManpowerTotal' },
    // { '可用人力汇总（人月）': 'departmentManpowerUsedMonthTotal' },
    // { '可用人力汇总（人日）': 'departmentManpowerUsedDayTotal' },
  ]
  return _.map(listName, (o) => {
    const listData = _.assign(..._.concat(..._.map(_.groupBy(data, 'evaluationMonth'), (list, keys) => {
      const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(keys)), '-')
      const month = `${getDate[0]}-${getDate[1]}`
      return {
        [`month${month}`]: _.includes(['departmentManpowerUsedMonthTotal', 'departmentManpowerUsedDayTotal'], _.head(_.values(o))) ? isNegative(summaryBind(list, _.head(_.values(o)))) : summaryBind(list, _.head(_.values(o)))
      }
    })))

    const sum = summaryBind(data, _.head(_.values(o)))

    return _.assign({}, {
      name: _.head(_.keys(o)),
      total: <span style={{ fontWeight: 'bold', color: sum >= 0 ? '#13151A' : 'red' }}>{sum}</span>,
    }, listData)
  })
}

function isNegative(num) {
  if (_.toNumber(num) >= 0) return num
  return <span style={{ color: 'red' }}>{num}</span>
}

function summaryBind(list, bind) {
  return (_.sum(_.map(list, o => _.toNumber(o[bind]) * 100)) / 100)
}
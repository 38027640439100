import React, { useState } from "react";
import Icon from "./Icon";
import { MessageBox } from 'rootnet-ui';
import './DetailsGoBack.scss';
import { P_Clsx } from "../utils/utils-sp";

/*
开发者：mw
当前维护者：ylf
时间: 2020-06-18
*/
export default function DetailsGoBack(props) {
    const { text = '返回', onClick, useTips, className, style,messageInfo } = props;
    const [message, setMessage] = useState(false);

    return <div className={P_Clsx('all-go-back',className)} style={style}>
        <Icon name='erjititlefanhui' onClick={useTips ? () => setMessage(true) : onClick} />
        <span className="all-back-title">{text}</span>
        {
            message &&
            <MessageBox
                header="提示"
                cancel={() => setMessage(false)}
                confirm={onClick}
            >
                {messageInfo}
            </MessageBox>
        }
    </div>;
}
DetailsGoBack.defaultProps={
    useTips:false,//是否使用弹窗
    messageInfo:'离开当前页面，内容将不被保存',//弹窗提示信息
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from "lodash";
import { Button, Messager } from 'rootnet-ui'
import { Input, CheckBox } from 'rootnet-edit';
import { Tabs, Empty, Tree, Spin } from 'antd';
import usePost from "rootnet-biz/es/hooks/usePost";
import useGet from "rootnet-biz/es/hooks/useGet";
import { Icon } from '../../../../../../../components';
import PersonDepartGroupSelectUserItem from "./PersonDepartGroupSelectUserItem";
import { buildUserDeptTree } from "./buildUserDeptTree";
import './PersonDepartGroupSelectPop.scss'
import SelectTypeItem from './selectTypeItem';
import { buildUserGroupTree } from './buildUserGroupTree';

const { TabPane } = Tabs;

function PersonDepartGroupSelectPop(props) {
    const { onSelectConfirm, hiddenUserList, multiple, allowClear, close, groupValue, departValue } = props
    const [activeTab, setActiveTab] = useState('recent')
    const { data: allUserRes, doFetch: getAllUserRes, loading: allUserLoading } = useGet()
    const [selectValue, setSelectValue] = useState(multiple ? [] : null)
    const [selectDept, setSelectDept] = useState([])
    const [selectUserGroup, setSelectUserGroup] = useState([])
    const { data: allDeptRes } = useGet('/common/department?activeFlag=Y')
    const { data: allUserGroupRes } = useGet('/uac/user/group/get/all')
    const [expandedKeys, setExpandedKeys] = useState(['dept-0'])
    const [expandedUserGroupKeys, setExpandedUserGroupKeys] = useState([])
    const [searchText, setSearchText] = useState()
    const [showResignedUser, setShowResignedUser] = useState(false)
    const { doFetch: sendRecent } = usePost()
    // 在职人员
    const staffRes = useMemo(() => {
        return _.filter(allUserRes, x => _.get(x, 'indexValidFlag') === 1)
    }, [allUserRes])
    // 用户选择显示的所有人员
    const showAllUserRes = useMemo(() => {
        return showResignedUser ? allUserRes : staffRes
    }, [showResignedUser, allUserRes, staffRes])

    const selectedUserAccountList = useMemo(() => {
        const multipleValue = _.map(selectValue, 'userAccount')
        const radioValue = _.get(selectValue, 'userAccount')
        const radioValueList = _.isNil(radioValue) ? [] : [radioValue]
        return multiple ? multipleValue : radioValueList
    }, [multiple, selectValue])

    const filterAllUser = useMemo(() => {
        if (_.isEmpty(hiddenUserList)) return showAllUserRes
        return _.filter(showAllUserRes, x => !_.includes(hiddenUserList, x.userAccount))
    }, [hiddenUserList, showAllUserRes])

    const filterAllStaff = useMemo(() => {
        if (_.isEmpty(hiddenUserList)) return staffRes
        return _.filter(staffRes, x => !_.includes(hiddenUserList, x.userAccount))
    }, [hiddenUserList, staffRes])

    useEffect(() => {
        if (_.isEmpty(allUserRes)) return
        if (_.isNil(props.defaultValue)) return
        setSelectValue(_.filter(allUserRes, x => _.includes(props.defaultValue, x.userAccount)))
    }, [props.defaultValue, allUserRes])

    useEffect(() => {
        if (_.isEmpty(allUserGroupRes) || _.isEmpty(groupValue)) return
        const findData = _.filter(allUserGroupRes, o => _.includes(groupValue, o.id))
        const initData = _.map(findData, o => ({ text: o.groupName, value: o.id }))
        setSelectUserGroup(initData)
    }, [allUserGroupRes, groupValue])

    useEffect(() => {
        if (_.isEmpty(allDeptRes) || _.isEmpty(departValue)) return
        const findData = _.filter(allDeptRes, o => _.includes(departValue, o.departMentId))
        const initData = _.map(findData, o => ({ text: o.departMentName, value: o.departMentId }))
        setSelectDept(initData)
    }, [allDeptRes, departValue])

    useEffect(() => {
        if (_.isEmpty(allUserRes)) return
        if (_.isNil(props.defaultValue)) return
        if (multiple) {
            setSelectValue(_.filter(allUserRes, x => _.includes(props.defaultValue, x.userAccount)))
        } else {
            setSelectValue(_.find(allUserRes, x => props.defaultValue === x.userAccount))
        }
    }, [props.defaultValue, allUserRes, multiple])

    useEffect(() => {
        // getAllUserRes('/common/userinfo?indexValidFlag=1')
        getAllUserRes('/common/userinfo')
    }, [getAllUserRes])

    const onSelect = useCallback((selectedItem) => {
        if (multiple) {
            setSelectValue(oldList => {
                const oldUserAccountList = _.map(oldList, 'userAccount')
                if (_.includes(oldUserAccountList, _.get(selectedItem, 'userAccount'))) {
                    return oldList
                } else {
                    return _.concat(oldList, [selectedItem])
                }
            })
        } else {
            setSelectValue(selectedItem)
        }
    }, [multiple])

    const onExpand = useCallback((expandedKeys) => {
        setExpandedKeys(expandedKeys)
    }, [])

    const onUserGroupExpand = useCallback((expandedKeys) => {
        setExpandedUserGroupKeys(expandedKeys)
    }, [])

    const onNodeClick = useCallback((key) => {
        setExpandedKeys(oldList => {
            if (_.includes(oldList, key)) {
                return _.filter(oldList, x => x !== key)
            } else {
                return [...oldList, key]
            }
        })
    }, [])

    const onUserGroupNodeClick = useCallback((key) => {
        setExpandedUserGroupKeys(oldList => {
            if (_.includes(oldList, key)) {
                return _.filter(oldList, x => x !== key)
            } else {
                return [...oldList, key]
            }
        })
    }, [])

    const getAllChildDeptId = useCallback((deptId) => {
        let deptIdList = [deptId]
        findChildNode(deptId)
        function findChildNode(pid) {
            const childDepartmentList = _.filter(allDeptRes, x => x.parentDept === pid)
            _.forEach(childDepartmentList, x => {
                deptIdList.push(x.departMentId)
                findChildNode(x.departMentId)
            })
        }
        return deptIdList
    }, [allDeptRes])

    const onDeptAddClick = useCallback((e, clickDeptId) => {
        e.stopPropagation()
        const addDeptList = getAllChildDeptId(clickDeptId)
        const allUserInDept = _.filter(staffRes, x => _.includes(addDeptList, x.department))
        setSelectValue(oldList => {
            const newList = _.concat(oldList, allUserInDept)
            return _.uniqBy(newList, x => x.userAccount)
        })
    }, [staffRes, getAllChildDeptId])

    const allUserSelectedInDept = useCallback((deptId) => {
        if (!multiple) return false
        const allDeptList = getAllChildDeptId(deptId)
        const allUserInDept = _.filter(staffRes, x => _.includes(allDeptList, x.department))
        const allUserInDeptUserAccount = _.map(allUserInDept, 'userAccount')
        const selectedUserAccount = _.map(selectValue, 'userAccount')
        return _.every(allUserInDeptUserAccount, x => _.includes(selectedUserAccount, x))
    }, [getAllChildDeptId, selectValue, multiple, staffRes])

    const userGroupUserSelect = useCallback((userList) => {
        if (_.isEmpty(userList)) return false
        return _.every(userList, v => _.includes(_.map(selectValue, k => k.userAccount), v))
    }, [selectValue])

    const deptUserTreeData = useMemo(() => {
        return buildUserDeptTree({
            userRes: filterAllStaff,
            deptRes: allDeptRes,
            onNodeClick,
            onSelect,
            selectedUserAccountList,
            onDeptAddClick,
            multiple,
            allUserSelectedInDept,
            setSelectDept,
        })
    }, [filterAllStaff, allDeptRes, onNodeClick, onSelect, selectedUserAccountList, onDeptAddClick, multiple, allUserSelectedInDept])

    const userGroupTreeData = useMemo(() => {
        return buildUserGroupTree({
            userGroupRes: allUserGroupRes,
            userRes: filterAllStaff,
            onSelect,
            selectedUserAccountList,
            onUserGroupNodeClick,
            setSelectUserGroup,
            setSelectValue,
            userGroupUserSelect
        })
    }, [filterAllStaff, allUserGroupRes, onSelect, selectedUserAccountList, onUserGroupNodeClick, userGroupUserSelect])

    const showUserList = useMemo(() => {
        if (!searchText) return filterAllUser
        setActiveTab('all')
        return _.filter(filterAllUser, user => {
            return _.includes(user.userName, searchText) || _.includes(user.userAccount, searchText) || _.includes(user.departmentAllPath, searchText) || user.userId === searchText
        })
    }, [searchText, filterAllUser])

    const recentUserList = useMemo(() => {
        const filterRecentUserList = _.filter(filterAllUser, x => !_.isNil(x.recentFlag))
        return _.reverse(_.sortBy(filterRecentUserList, 'recentFlag'))
    }, [filterAllUser])

    const onConfirm = useCallback(() => {
        const multipleValue = _.map(selectValue, 'userAccount')
        const departValue = _.map(selectDept, 'value')
        const groupValue = _.map(selectUserGroup, 'value')
        onSelectConfirm({ user: multipleValue, depart: departValue, group: groupValue })
        sendRecent('/test_defect/common/people', { current_owners: multipleValue })
            .then(() => {
                // close()
            })
            .catch(err => {
                // close()
                Messager.show(err.Message, { icon: 'error' })
            })
    }, [selectValue, onSelectConfirm, sendRecent, selectDept, selectUserGroup])

    return <div className={'person-depart-group-select-pop flex-y'}>
        <Input autoFocus className={'person-depart-group-search'} placeholder={'请搜索人员'} value={searchText} onChange={setSearchText}
            suffix={<Icon name={'sousuo1'} className={'person-depart-group-search-icon'} />} />
        <div className="person-depart-group-select-main-panel flex">
            <div className="person-depart-group-select-left">
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="全部" key="all">
                        <div className={'all-user flex-y'}>
                            {
                                allUserLoading && <Spin />
                            }
                            {
                                _.map(showUserList, userInfo =>
                                    <PersonDepartGroupSelectUserItem key={`all-${_.get(userInfo, 'userId')}`} {...{ userInfo, onSelect, selectedUserAccountList }} />)
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="常用" key="recent">
                        <div className={'recent-user flex-y'}>
                            {
                                _.map(recentUserList, userInfo =>
                                    <PersonDepartGroupSelectUserItem key={`all-${_.get(userInfo, 'userId')}`} {...{ userInfo, onSelect, selectedUserAccountList }} />)
                            }
                            {
                                allUserLoading && <Spin />
                            }
                            {
                                _.isEmpty(recentUserList) && !allUserLoading &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="部门" key="dept">
                        <div className="dept-user-warp flex">
                            <Tree treeData={deptUserTreeData}
                                onExpand={onExpand}
                                expandedKeys={expandedKeys}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="用户组" key="userGroup">
                        <div className="dept-user-warp flex">
                            <Tree treeData={userGroupTreeData}
                                onExpand={onUserGroupExpand}
                                expandedKeys={expandedUserGroupKeys}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div className="person-depart-group-select-right flex-y">
                <div className="person-depart-group-select-right-header flex center-y">
                    <div className="person-depart-group-selected-num-wrap flex">
                        已选
                        {
                            (!_.isEmpty(selectValue) || !_.isEmpty(selectDept) || !_.isEmpty(selectUserGroup)) &&
                            <div className="person-depart-group-selected-num">{_.sum([_.size(selectValue), _.size(selectDept), _.size(selectUserGroup)])}项</div>
                        }
                    </div>
                    {
                        allowClear && <div className="clear-all-selected" onClick={() => {
                            setSelectValue([])
                            setSelectDept([])
                            setSelectUserGroup([])
                        }}>
                            清空所选
                        </div>}
                </div>
                <div className="person-depart-group-select-right-content flex-y">
                    {
                        (_.isEmpty(selectValue) && _.isEmpty(selectDept) && _.isEmpty(selectUserGroup)) &&
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    {
                        !_.isEmpty(selectValue) && <SelectTypeItem icon='putongyonghu' data={selectValue} clear={setSelectValue} label='userName' bind='userAccount' />
                    }
                    {
                        !_.isEmpty(selectDept) &&
                        <SelectTypeItem icon='zengjiazibumen' data={selectDept} clear={setSelectDept} />
                    }
                    {
                        !_.isEmpty(selectUserGroup) &&
                        <SelectTypeItem icon='tuandui' data={selectUserGroup} clear={setSelectUserGroup} />
                    }
                </div>
            </div>
        </div>
        <div className="confirm-footer flex center-y">
            <div className="confirm-footer-left">
                <CheckBox value={showResignedUser} onChange={setShowResignedUser}>显示离职人员</CheckBox>
            </div>
            <div className="confirm-footer-right flex">
                <Button normal onClick={() => {
                    close()
                }}>取消</Button>
                <Button primary onClick={onConfirm}>确定</Button>
            </div>
        </div>
    </div>
}

export default PersonDepartGroupSelectPop;
import React, { useEffect, useState, Fragment, useMemo, useCallback } from 'react'
import _ from 'lodash'
import clsx from 'clsx';
import { Dropdown, Empty, Menu } from 'antd'
import { FormInput, Display } from 'rootnet-edit';
import { isNil } from 'rootnet-core/format';
import { Dialog, DataGrid, Loader, Button, Messager } from 'rootnet-ui'
import { toDate, dateFormat } from 'rootnet-core/dateFormat';
import { usePost } from 'rootnet-biz/es/hooks';
import { useGet } from '../../../utils/hook';
import { Icon } from '../../../components';
import { strParams } from '../../../utils/publicFun';
import { uniqKeyFor } from '../../../project_share/utils/utils';
import CommentInfo from './components/commentInfo';
import gd from '../../../base/global';
import FollowMattersItem from './components/followMattersItem';
import './index.scss'

const { str14ToDate } = toDate;

const DEL_COMMENTS_URL = '/PublicMark/maintain/del' //删除评论
const GET_RECORD_URL = '/PublicMark/getPublicMark' //获取记录
const FOLLOW_DETAILS_URL = '/todolist/getTodolistrecord'
const TYPE_URL = 'UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=referenceType'

const USER_COLOR = ['#B9CC4F', '#96BF65', '#C4A1EE', '#F5B168', '#F79C6F', '#F0C75A', '#8DC8EA', '#A1A5EE', '#79C7B7']
const saveUserColor = {}
const typeStyle = Object.freeze({
    null: 'quanbu',
    1: 'bianji2',
    2: 'pinglun',
    3: 'guanlian',
    4: 'jurassic_process',
    5: 'zidingyishezhi1',
})

const iconType = Object.freeze({
    0: 'pinglun',
    1: 'bianji2',
    2: 'jurassic_process',
    3: 'guanlian',
    4: 'guanlian',
    5: 'guanlian',
    6: 'guanlian',
    7: 'guanlian',
    8: 'bianji2',
    9: 'guanlian',
    10: 'jurassic_process',
})

const option = {
    autoFill: true,
    resizable: true,
    virtualized: false,
    nilText: '-',
    emptyText: '-',
    columns: [
        { header: '变更前', bind: 'old', convert: o => <div className='inner-rich-text' dangerouslySetInnerHTML={{ __html: o }} /> },
        { header: '变更后', bind: 'new', convert: o => <div className='inner-rich-text' dangerouslySetInnerHTML={{ __html: o }} /> },
    ]
};


const notParams = ['创建人', '创建时间', '更新时间', '更新人']
const defaultActions = { interiorId: null, displayName: '所有' }

/*
   1 - 会议, 2 - QA, 3 - 实施项目,
   4 - 销售项目, 5 - 研发项目, 6 - ISSUE,
   7 - 计划, 8 - 任务清单, 9 - 实施项目管理,
   10 - 缺陷, 11 - 需求, 
*/

export default function ChangeRecord(props) {
    const {
        id = null,
        title = '',
        linkUrl = null,
        funcCode,
        name = '',
        referenceId,
        className = '',
        commentReferenceId = null,
        associatedField,
        associatedFieldName,
        defaultAction = defaultActions,
        followBtn = true,
        showSelect = true,
        commentWidth,
        defaultRefresh = () => { },
        todoListName = '跟进事项',
    } = props

    const { data: typeData } = useGet(TYPE_URL)
    const { data, doFetch, loading } = useGet()
    const { data: followData, doFetch: followFetch } = useGet()
    const [active, setActive] = useState(defaultAction)
    const [reverl, setReverl] = useState({})
    const [open, setOpen] = useState(false)
    const [replyInfo, setReplyInfo] = useState(null)
    const [delLoading, setDelLoading] = useState(false)
    const { doFetch: getFetch } = usePost()

    const allData = useMemo(() => {
        if (!_.isNil(data) && !_.isNil(followData)) {
            _.forEach(followData, o => {
                o.actionType = 'todo'
                o.updateTime = o.recTime
            })
            if (active?.interiorId === '5') return _.orderBy(followData, 'updateTime', 'desc')
            return _.orderBy(_.concat(data, isNull(active?.interiorId) ? followData : []), 'updateTime', 'desc')
        }
        return []
    }, [active, data, followData])

    const followRefresh = useCallback(() => {
        followFetch(`${FOLLOW_DETAILS_URL}?relateId=${referenceId}&funcCode=${funcCode}`)
    }, [followFetch, funcCode, referenceId])

    const refresh = useCallback((referenceType = null) => {
        const params = _.assign(
            {},
            { funcCode, referenceId, associatedField },
            !_.isNil(commentReferenceId) && { commentReferenceId },
            referenceType + '' !== 'null' && { referenceType }
        )
        doFetch(`${GET_RECORD_URL}?${strParams(params)}`)
    }, [commentReferenceId, doFetch, funcCode, referenceId, associatedField])

    const delComment = useCallback((id, close = () => { }) => {
        if (delLoading) return
        setDelLoading(true)
        getFetch(DEL_COMMENTS_URL, { referenceId, funcCode, id })
            .then(() => {
                refresh()
                setDelLoading(false)
                Messager.show('删除成功', { icon: 'success' });
            })
            .catch(err => {
                setDelLoading(false)
                Messager.show(err._message, { icon: 'error' })
            })
    }, [funcCode, getFetch, referenceId, refresh, delLoading])

    const allRefresh = useCallback((referenceType = null) => {
        if (referenceType !== '5') refresh(referenceType)
        followRefresh()
    }, [followRefresh, refresh])

    useEffect(() => {
        allRefresh()
    }, [allRefresh])

    const menu = useMemo(() => {
        if (!typeData) return <></>
        const opt = [defaultAction, ...typeData]
        return (
            <Menu
                className='change-type-menu'
                onClick={(e) => {
                    allRefresh(e.key)
                    setActive({
                        displayName: _.get(_.find(opt, o => o.interiorId + '' === e.key + ''), 'displayName'),
                        interiorId: e.key
                    })
                }}>
                {
                    _.map(opt, item => {
                        return <Menu.Item key={item?.interiorId}>
                            <span>
                                <Icon name={typeStyle[item?.interiorId]} />&nbsp;{item?.displayName}
                            </span>
                        </Menu.Item>
                    })
                }
            </Menu>
        )
    }, [allRefresh, defaultAction, typeData])

    return (
        <div className={clsx('change-record', className)}>
            {
                showSelect &&
                <div className={'change-type-wrap'} style={{ marginBottom: 8 }}>
                    <Dropdown className='change-type' overlay={menu} trigger={['click']}>
                        <div>
                            {_.get(active, 'displayName')}&nbsp;
                            <Icon name='zhankaijiantouxia' />
                        </div>
                    </Dropdown>
                </div>
            }
            {
                _.isEmpty(allData) && !loading && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
            {
                loading ? <Loader /> :
                    <Record {...{ data: allData, reverl, setReverl, setOpen, name, associatedFieldName, delComment, setReplyInfo, funcCode, refresh: allRefresh, referenceId, linkUrl, todoListName }} />
            }
            <CommentInfo {...{ commentWidth, funcCode, referenceId, refresh: () => { allRefresh(); defaultRefresh() }, replyInfo, setReplyInfo, linkUrl, id, title, followBtn }} />
            {
                open &&
                <Dialog
                    header='变更详情'
                    confirm={() => setOpen(false)}
                    cancel={() => setOpen(false)}
                    footerVisible={false}
                    className='record-detail-dialog'
                >
                    <FormInput horizontal label='修改人' value={open.user} component={Display} />
                    <FormInput horizontal label='修改时间' value={open.time} component={Display} />
                    <DataGrid data={[open]} option={option} />
                    <div className='footer'>
                        <Button normal onClick={() => setOpen(false)}>取消</Button>
                    </div>
                </Dialog>
            }
        </div>
    )
}

function Record(props) {
    const { data, reverl, setReverl, setOpen, name, associatedFieldName, delComment, setReplyInfo, funcCode, refresh, referenceId, linkUrl, todoListName } = props
    const [currentId, setCurrentId] = useState()
    return (
        <div style={{ flex: 1, overflow: 'auto' }}>

            {
                _.map(data, (item, i) => {
                    const { user, updateUser, updateTime, markObjects, actionDesc, actionType, action, message, list, markObjectList, details, remarks } = item || {} //referenceType
                    const changeSelf = _.isEmpty(markObjectList)
                    const time = dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(updateTime))

                    const mainTable = [{
                        associatedField: null,
                        list: markObjects
                    }]

                    const appendList = _.concat(_.isEmpty(markObjects) ? [] : mainTable, markObjectList)
                    if (actionType === 'todo') return <FollowMattersItem funcCode={funcCode} getIcon={getIcon} key={i} {...item} refresh={refresh} referenceId={referenceId} linkUrl={linkUrl} todoListName={todoListName} />
                    if (_.isNil(message) && _.isNil(details) && _.isNil(remarks) && _.isEmpty(appendList)) return <></>
                    return <div className='record-box' key={i}>
                        <div className='record-icon'>{getIcon(actionType, user, action)}</div>
                        {
                            Number(actionType) === 0 ?
                                <div className='comment-all'>
                                    <div className='record-comment'>

                                        <div>
                                            <div>
                                                {user}
                                                &nbsp;&nbsp;
                                                {time}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span className='comments-operation'>
                                                    <span onClick={() => setReplyInfo(item)}>回复</span>
                                                    &nbsp;&nbsp;
                                                    {
                                                        (_.isEmpty(list) && gd.User.operator_id === updateUser) &&
                                                        <span onClick={() => setCurrentId(item.id)}>删除</span>
                                                    }
                                                </span>
                                            </div>
                                            <div>{message}</div>
                                        </div>
                                    </div>
                                    {_.map(list, (o, indx) => (
                                        <div key={indx} className='level-two'>
                                            <div
                                                className='record-icon'>{getIcon(o.actionType, o.user, o.action)}</div>
                                            <div className='record-comment'>

                                                <div>
                                                    <div>
                                                        {o.user}
                                                        &nbsp;&nbsp;
                                                        回复
                                                        &nbsp;&nbsp;
                                                        {user}
                                                        &nbsp;&nbsp;
                                                        {dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(o.updateTime))}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <span className='comments-operation'>
                                                            {
                                                                gd.User.operator_id === o.updateUser &&
                                                                <span onClick={() => setCurrentId(o.id)}>删除</span>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div>{o.message}</div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='record-data'>

                                    <div className='record-header'>
                                        <div className='left'>
                                            <div>{user || updateUser}</div>
                                            {action === 'add' && <div>{actionDesc}了{name}</div>}
                                        </div>

                                        <div className='right'>
                                            {time}
                                        </div>
                                    </div>

                                    <div className='record-content'>
                                        {
                                            action === 'add' && <div className='record-add' key={i}></div>
                                        }
                                        {
                                            action !== 'add' && <>
                                                {
                                                    _.map(appendList, innerList => {
                                                        let handleList = _.map(_.get(innerList, 'list'), x => _.assign({}, x, { associatedField: _.get(innerList, 'associatedField') }))
                                                        return _.map(handleList, (item, index) => {
                                                            const { fieldName, newFieldValue, oldFieldValue, fieldHtmlType } = item
                                                            if (_.includes(notParams, fieldName)) return <Fragment key={uniqKeyFor()} />
                                                            if (fieldHtmlType === '2') {
                                                                return <div className='mtext' key={uniqKeyFor()}>
                                                                    <div className='mtext-header'>
                                                                        <div
                                                                            className='change-action'>{actionDesc}了{fieldName}</div>
                                                                        <div className='examine' onClick={() => setOpen({
                                                                            time,
                                                                            user,
                                                                            new: newFieldValue,
                                                                            old: oldFieldValue
                                                                        })}>查看变更记录
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        onClick={() => setReverl(x => _.assign({}, x, { [`${updateTime}${fieldName}`]: !x[`${updateTime}${fieldName}`] }))}
                                                                        className={clsx('mtext-content', { 'reverl-all': !reverl[`${updateTime}${fieldName}`] })}
                                                                    >
                                                                        {
                                                                            _.isNil(newFieldValue) ? '-' :
                                                                                <div className='rich-text'
                                                                                    dangerouslySetInnerHTML={{ __html: newFieldValue }} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            return <div className='each' key={uniqKeyFor()}>
                                                                {
                                                                    index === 0 && !changeSelf && !!_.get(item, 'associatedField') && _.get(item, 'associatedField') !== 'null' &&
                                                                    <div
                                                                        style={{ color: '#929292' }}>{associatedFieldName}【{_.get(item, 'associatedField')}】： </div>
                                                                }
                                                                <div className='change-action'>{actionDesc}了{fieldName}</div>
                                                                <div className='change-data'>
                                                                    {
                                                                        _.isNil(oldFieldValue) ?
                                                                            <><span
                                                                                className='new'>{_.isNil(newFieldValue) ? '-' : newFieldValue}</span>；</> :
                                                                            <>
                                                                                <span className='old'>{oldFieldValue}</span>
                                                                                <Icon name='xianghou' />
                                                                                <span
                                                                                    className='new'>{_.isNil(newFieldValue) ? '-' : newFieldValue}</span>；
                                                                            </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        })
                                                    })
                                                }
                                            </>
                                        }
                                        { //(actionType === '9' && referenceType === '1') &&
                                            !isNil(details) &&
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>{details}</div>
                                        }
                                        {
                                            !isNil(remarks) &&
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>备注：{remarks}</div>
                                        }
                                    </div>

                                </div>
                        }

                    </div>
                })
            }
            {currentId && <DeltipLog close={() => setCurrentId(null)} {...{ delComment, currentId }} />}
        </div >
    )
}

function DeltipLog(props) {
    const { close, currentId, delComment } = props
    return <Dialog
        header='提示'
        confirm={() => delComment(currentId, close)}
        cancel={close}
        className='del-comment-tip-log'
    >
        确定删除当前评论么？
    </Dialog>
}

function isNull(val) {
    return val === null || val === 'null'
}

function getIcon(type, user, action) {
    if (type === '0' || type === 'todo') {
        if (_.isNil(saveUserColor[user])) saveUserColor[user] = USER_COLOR[_.random(0, _.size(USER_COLOR) - 1)]
        return <div className='head-portrait'
            style={{ background: saveUserColor[user] }}>{_.get(user, `[0]`)}</div>
    } else if (action === 'add') {
        return <Icon name='zengjia' />
    }
    return <Icon name={iconType[type]} />
}
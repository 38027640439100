import React from 'react'
import { Dialog } from 'rootnet-ui'
import RiskFollowUpDetailContent from './riskFollowUpDetailContent'
import './index.scss'

export default function RiskFollowUpDetail(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog className={'risk-follow-up-detail-dialog'} headerVisible={false} footerVisible={false}>
      <RiskFollowUpDetailContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

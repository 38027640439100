import React, { useState, useMemo, useEffect, useCallback, useRef, useContext } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/es/hooks'
import { DataGrid, Pagination, Messager, Tooltip } from 'rootnet-ui'
import findConvert from '../../common/view/findConvert'
import convertTableAlign from '../../common/view/convertTableAlign'
import ResourceDeptSearch from '../../resourcePoolMgt/resourceSchedule/resourceDeptSearch/ResourceDeptSearch'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import ViewArea from '../../common/view/ViewArea'
import { Box } from '../../common/commonComponent'
import './index.scss'
import { TextIconBtn } from '../../common/TextIconBtn'
import AssigningRole from '../components/assigningRole'
import { useTreeExpend } from '../../commonV2/hooks'
import { ScheduleManagementContext } from '../../common/Context'

const EDITABLE_FIELD = ['roleNameList']
const ENTER_DETAIL_FIELD = 'roleNameList'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions } = props

  const customColumns = []

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <Tooltip title={showValue} style={{ maxWidth: 400 }}>
        <div className='role-list-overflow'>
          {showValue || '-'}
        </div>
      </Tooltip>
    }
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 200
  }
}

const FUNC_CODE = '88050301'

export default function DepartmentAndUserMgt() {
  const [searchDept, setSearchDept] = useState(null)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(200)
  const [isLoading, setIsLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState()
  const [checkedKeys, setCheckKeys] = useState()
  const [currentInfo, setCurrentInfo] = useState(null)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const { downloadPostProgress } = useContext(ScheduleManagementContext)

  const wrapperDom = useRef()
  const { expendIcon, expend, wrapperEmpyt } = useTreeExpend({ wrapperDom })

  useEffect(() => {
    if (searchDept) { }
    setCheckKeys([])
  }, [searchDept])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    return ({ 'jobrecord_user.[department]': searchDept, })
  }, [searchDept])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const refreshLists = useCallback(() => {
    refreshList(replaceParams)
  }, [refreshList, replaceParams])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => sort.column, sort.direction)
      }
    },
  })

  const showList = useMemo(() => {
    let showList = dataGridList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, dataGridList])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '部门/用户')
  }, [downloadPostProgress, fieldList, currentViewId, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn text='分配角色' onClick={() => {
        if (_.isEmpty(checkedKeys)) return Messager.show('请选择用户', { icon: 'error' })
        setCurrentInfo({ mode: 'assigningRole' })
      }} />
      <TextIconBtn text='导出' icon='daochu' onClick={exportViewList} />
    </div>
  }, [checkedKeys, exportViewList])

  return (
    <div className='department-and-user-mgt fill flex-y'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize,
          replaceParams
        }} />

      <Box title={'部门/用户'} className='flex-y' data={dataGridList} extra={extra} loading={boxLoading} error={error}>
      </Box>
      <div className={'two-columns-wrap flex'}>
        {
          expend ? <div className="left-panel flex-y">
            <div style={{ textAlign: 'center' }}><Tooltip title='收起'>{expendIcon}</Tooltip></div>
            <ResourceDeptSearch changeDept={setSearchDept} />
          </div> : <div className='flex flex-y expend-depart-n'>
            <div className='expendIconWrap'>
              <Tooltip title='展开'>{expendIcon}</Tooltip>
            </div>
            {wrapperEmpyt}
          </div>
        }

        <div className="right-panel flex-y">
          <DataGrid option={options} data={showList} loading={boxLoading} error={error} selection={checkedKeys} onSelectionChange={setCheckKeys} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </div>
      </div>
      {
        _.get(currentInfo, 'mode') === 'assigningRole' &&
        <AssigningRole close={() => setCurrentInfo(null)} refresh={() => { refreshLists(); setCheckKeys([]) }} type='user' id={_.map(checkedKeys, o => o.id)} funcCode={FUNC_CODE} />
      }
    </div>
  )
}

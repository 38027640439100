import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {DatePicker} from "antd";
import _ from 'lodash'
import {REQUIRED_ERROR, useControlledError} from "../personSelectPop/useControlledError";
import moment from 'moment'
import cls from 'clsx'
import './DateSelector.scss'

const { RangePicker } = DatePicker;

function DateSelector(props) {
    const {required, onError, disabled, style, allowClear=true} = props
    const [stateValue, setStateValue] = useState()
    const [error, errorVisible, setStateError] = useControlledError(props);
    const [isInited, setIsInited] = useState(false)
    const [forceUpdateCount, forceUpdate] = useReducer((x) => x + 1, 1)

    const isControlled = useMemo(()=>{
        return !_.isNil(props.value) || _.isNil(props.defaultValue)
    },[props.value, props.defaultValue])

    const initData = useCallback((data, forceUpdate)=>{
        if(!_.isNil(data) || forceUpdate){
            const beginDate = _.get(data,'begin')
            const endDate = _.get(data,'end')
            setStateValue([
                beginDate?moment(beginDate):null,
                endDate?moment(endDate):null,
            ])
        }
        setIsInited(true)
    },[])

    useEffect(()=>{
        if(isControlled){
            initData(props.value, true)
        }
    },[isControlled, initData, props.value])

    // useEffect(()=>{
    //     if(isInited && !isControlled) return
    //     if(_.isNil(props.value)) return
    //     initData(props.value)
    // },[props.value,initData, isInited, isControlled])

    useEffect(()=>{
        if(isInited && isControlled) return
        if(_.isNil(props.defaultValue)) return
        initData(props.defaultValue)
    },[props.defaultValue,initData,isInited, isControlled])

    useEffect(() => {
        const err = (required && _.isEmpty(stateValue)) ? REQUIRED_ERROR : null;
        if (err !== error) {
            setStateError(err);
            if (props.onError)
                props.onError(err);
        }
    }, [stateValue, required, onError, props, error, setStateError])

    const show_error = useMemo(() => {
        return error && errorVisible
    }, [error, errorVisible])

    const onDateChange = useCallback(newValue => {
        setStateValue(newValue)
        if (props.onChange) {
            if(_.isNil(newValue)){
                props.onChange(null)
            }else{
                const [beginMoment, endMoment] = newValue || []
                const beginDate = beginMoment.format('YYYY-MM-DD')
                const endDate = endMoment.format('YYYY-MM-DD')
                props.onChange({
                    begin: beginDate,
                    end: endDate
                })
            }
        }
    },[props])

    // 每天凌晨0点刷新
    useEffect(()=>{
        let timer = setInterval(() => {
            if(new Date().getHours() === 0){
                forceUpdate()
            }
        },60000)
        return () =>{
            clearInterval(timer)
        }
    },[])

    const ranges = useMemo(()=>{
        if(forceUpdateCount)
        return {
            '今天': [
                moment(),
                moment()
            ],
            '本周': [
                moment().startOf('week'),
                moment().endOf('week')
            ],
            '本月': [
                moment().startOf('month'),
                moment().endOf('month')
            ],
            // '本季度': [
            //     moment().startOf('quarter'),
            //     moment().endOf('quarter')
            // ],
            '本年': [
                moment().startOf('year'),
                moment().endOf('year')
            ],
            '最近一周': [
                moment(new Date()).subtract(1,'week'),
                moment(new Date())
            ],
            '最近一个月': [
                moment(new Date()).subtract(1,'month'),
                moment(new Date())
            ],
            '最近三个月': [
                moment(new Date()).subtract(1,'quarter'),
                moment(new Date())
            ],
            '最近一年': [
                moment(new Date()).subtract(1,'year'),
                moment(new Date())
            ],
        }
    },[forceUpdateCount])

    return <RangePicker value={stateValue} onChange={onDateChange} ranges={ranges} allowClear={allowClear}
                        style={{ ...style }} className={cls('date-selector',{ error: show_error, disabled })}/>
}

export default DateSelector;
import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Popover, Tooltip } from 'antd'
import useGet from "rootnet-biz/es/hooks/useGet"
import usePost from "rootnet-biz/es/hooks/usePost"
import gd from './base/global'
import { Icon } from './components'
import { GuideContext } from './views/common/Context'
import ReleaseInfoDialog from "./views/releaseInfo/ReleaseInfoDialog"
import ReleaseInfoListDialog from "./views/releaseInfo/ReleaseInfoListDialog"
import './userMenu.scss'
import convertOptions from './views/common/ConvertOptions'
import { TextIconBtn } from './views/common/TextIconBtn'
import ProblemFeedback from './views/problemFeedback'

class UserMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: null, isFeedback: false };
	}

	handleClick = visible => {
		this.setState({ open: visible });
	}

	handleClickFeedback = visible => {
		this.setState({ isFeedback: visible });
	}

	render() {
		const user = gd.User;
		const _user = gd._user
		const allUserRes = this.props.allUserRes
		const userName = convertOptions(_user.operator_name, allUserRes, 'userName', 'userAccount')
		if (user === null) return null;
		const { open, isFeedback } = this.state;
		const a = <div className='user-menu-select'>
			<div className='user-menu-del-style'>用户 ：{userName}</div>
			<div onClick={() => this.handleClick(false)}><ReleaseInfoItem open={open} /></div>
			{/* <div className='cut-off-rule' onClick={this.props.logout}>退出登录</div> */}
		</div>
		return (
			<>
				<div className='user-feedback flex center'>
					<Tooltip title='问题反馈'>
						<TextIconBtn icon='bianjixiugai' text='' size={20} onClick={() => this.handleClickFeedback(true)} />
					</Tooltip>
				</div>
				<Popover
					content={a}
					trigger="click"
					open={open}
					onOpenChange={this.handleClick}
					overlayClassName='user-menu-popover'
				>
					<div className={cls('user-menu account', { open })}>
						<Icon name="putongyonghu" />
						<span style={{ marginLeft: 5 }}>欢迎您，<span style={{ color: '#FFC98F' }}>{userName}</span></span>
						<div style={{ display: 'none' }}><ReleaseInfoItem open={open} /></div>
					</div>

				</Popover>
				<div className='user-login-out'>
					<TextIconBtn icon='yichu' text='退出' onClick={this.props.logout} />
				</div>
				<ProblemFeedback visible={isFeedback} close={() => this.handleClickFeedback(false)} />
			</>
		)
	}
}

export default UserMenu

function ReleaseInfoItem(props) {
	const { open } = props
	const { setGuideList } = useContext(GuideContext)
	const [showReleaseInfoDialog, setShowReleaseInfoDialog] = useState(false)
	const [showReleaseDetailDialog, setShowReleaseDetailDialog] = useState(false)
	const { doFetch } = usePost()
	const { doFetch: showGuide } = useGet()

	useEffect(() => {
		if (!_.isNil(open)) return
		showGuide('/promotion/guide?funcCode=16').then(res => {
			if (res) {
				setGuideList(x => _.concat(x, [
					{
						element: document.querySelector('.release-info-btn'),
						intro: '点击可查看当前以及历史内部版本更新内容。'
					},
					{
						element: document.querySelector('.left-menu'),
						intro: '点击菜单右边对应的星标★，将常用功能添加到常用中心，使操作更便捷。'
					},
				]))
			}
		})
	}, [showGuide, setGuideList, open])

	useEffect(() => {
		if (!_.isNil(open)) return
		doFetch('/promotion/releaseNote').then(res => {
			setShowReleaseDetailDialog(res)
		})
	}, [doFetch, open])

	return <div className="appbar_help flex center">
		<div className='list-item' onClick={() => setShowReleaseInfoDialog(true)}>内部版本信息</div>
		{
			showReleaseInfoDialog &&
			<ReleaseInfoListDialog close={() => setShowReleaseInfoDialog(false)} />
		}
		{
			showReleaseDetailDialog &&
			<ReleaseInfoDialog onCancel={() => setShowReleaseDetailDialog(false)} showUpdateInfo={{ mode: 'detail' }} />
		}
	</div>;
}
import React from 'react';
import clsx from 'clsx';
import { Dateformat, GetTime } from 'rootnet-core/format'
import { FormInput } from 'rootnet-edit';
import Nodata from '../../../../components/Nodata';
import { Button } from 'rootnet-ui';
import _ from 'lodash';
import './SearchBox.scss'

/*
*  得到对应日期
*/
export const getAllDate = ({ beginDate = '', endDate = '' }) => {
	let dateArr = []
	const start = new Date(Dateformat(beginDate))
	const end = new Date(Dateformat(endDate))
	const unixStart = start.getTime()
	const unixEnd = end.getTime()
	const oneDay = 24 * 60 * 60 * 1000;
	for (let stamp = unixStart; stamp <= unixEnd; stamp += oneDay) {
		const formatDate = new Date(parseInt(stamp))
		const whichDay = '周' + '日一二三四五六'.charAt(formatDate.getDay())
		dateArr.push({ date: Dateformat(GetTime(formatDate)), whichDay })
	}
	return dateArr
}

// 得到table---data
export const getTabData = (res, type, childType = 'taskId') => {
	let list = [];
	// 1: 拼接日历date 合并
	res = _.map(res, item => {
		item = item || {};
		if (item.workDate) item[`whichDay${item.workDate}`] = item.auditUseTime;
		return item;
	})
	// 2: taskId 合并  时间合并
	_.forEach(res, it => {
		let state = true;
		list = _.map(list, it2 => {
			if (flate(childType, it2, it)) {
				state = false;
				it2 = _.assign({}, it, it2)
			}
			return it2;
		})
		if (state) list.push(it);
	})
	return list;

	function flate(childType, a, b) {
		if (_.isString(childType)) return a[childType] === b[childType];
		if (_.isArray(childType)) return childType.every(x => a[x] === b[x])

	}
}

/*
* 得到table列
* obj => 返回的周期; {}
* tableData => 数据data;  []
* setTableData => 渲染的data; Hook
* optFor => 初始化table列; Function
* maxDay => 这个日期之前不让修改; str
*/
export const getCol = (obj, tableData, setTableData, optFor, maxDay, insertPosition, uniqueBind = '') => {
	let list = _.map(obj, (item, i) => {
		let _arr = item.date.split('-');
		let y = _arr[0];
		let m = _arr[1];
		let d = _arr[2];
		let bind = `whichDay${y}${m}${d}`;
		let header = `${item.whichDay}(${m}-${d})`;
		let col = { header, bind, width: 100, convert: (v, o, i, d, c) => { return tabCh(o, i, bind, d, uniqueBind) } };
		return col;
	})
	let oriList = _.concat([], optFor(obj, tableData, setTableData));
	if (insertPosition) oriList.splice(insertPosition, 0, ...list);
	else oriList.splice(oriList.length, 0, ...list)
	return tableOptsFor(oriList);
	function tableOptsFor(opts) {
		return {
			resizable: true,
			fixedLeft: 2,
			nilText: '-',
			emptyText: '-',
			columns: opts,
			autoFill: true,
			sort: {
				column: uniqueBind,
				direction: 'desc'
			}
		}
	}
	function tabCh(o, i, type, data, uniqueBind) {
		let nowT = secDate(type.split('Day')[1])
		let maxT = secDate(maxDay);
		let permitT = permitTime();
		if(_.get(o,'isTotal')) return ''
		if ((!maxT || nowT > maxT) && nowT < permitT) {
			return <FormInput
				key={`${_.get(o, `${uniqueBind}`)}${type}`}
				type='number'
				digit={1}
				min={0}
				value={_.get(_.filter(data, x => searchUnique(uniqueBind,x,o)), `[0][${type}]`)}
				onChange={(e) => {
					_.find(data, x => searchUnique(uniqueBind,x,o))[type] = e
					setTableData(_.concat([], data))
				}}
			/>
		} else {
			return _.get(_.filter(data, x => searchUnique(uniqueBind,x,o)), `[0][${type}]`)
		}

		function searchUnique(uniqueBind,a,b) {
			if (_.isString(uniqueBind)) return a[uniqueBind] === b[uniqueBind];
			if (_.isArray(uniqueBind)) return uniqueBind.every(x => a[x] === b[x])
		}
	}
	// 得到对应的时间戳
	function secDate(time) {
		if (_.isNil(time)) return '';
		time = _.toString(time);
		let ys = time.substring(0, 4);
		let ms = time.substring(4, 6);
		let ds = time.substring(6, 8);
		return new Date(ys, ms, ds).getTime();
	}

	function permitTime() {
		const date = new Date();
		return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate() + 1).getTime()
	}
}

export function SearchBox(props) {
	const { confirm, cancel, onChange, value, nodata, className = '', label } = props;
	return (
		<div className={clsx(['searchBox', className])}>
			{
				!nodata && <>
					<FormInput onChange={onChange} horizontal label={label} value={value} />
					<div className='footer'>
						<Button primary onClick={confirm}>确认</Button>
						<Button text onClick={cancel}>取消</Button>
					</div>
				</>
			}
			{
				nodata && <>
					<Nodata text='暂无相关数据' width={40} Component={function () { return (<Button primary style={{ marginTop: 16 }} onClick={confirm}>重新添加</Button>) }} />
				</>
			}
		</div>
	)
}

// export function getFirstWeekDay() {
// 	return GetTime(new Date(Date.now() - (new Date().getDay() - 1) * 1000 * 60 * 60 * 24))
// }


import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Dialog, Button, Messager, Loader} from 'rootnet-ui'
import './GlobalConstUpdateDialog.scss'
import {Display, Form, FormInput, Input} from 'rootnet-edit'
import TextAreaInput from "../../common/textAreaInput/TextAreaInput";
import {Table, Form as AntdForm, Select, Input as AntdInput, Popover, Tooltip} from "antd";
import _ from 'lodash'
import {arrayMoveImmutable} from "array-move";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {GlobalConstEditContext} from "../../common/Context";
import convertOptions from "../../common/ConvertOptions";
import {MenuOutlined} from "@ant-design/icons";
import {uniqKeyFor} from "rootnet-biz/es/utils";
import ColorPickerPopContent from "../../common/colorPicker/ColorPickerPopContent";
import {usePost} from "rootnet-biz/es/hooks";
import useGet from "rootnet-biz/es/hooks/useGet";
import {isNil} from "../../appraise/components/method";
import {TextIconBtn} from "../../common/TextIconBtn";
import Icon from "../../../components/Icon";

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props}/>
const YNOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function getColumns(props){
    const {setDisplayColor,showColorPickerId,setShowColorPickerId, isDetail} = props
    return _.compact([
        !isDetail && {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            fixed: 'left',
            render: () => <DragHandle />,
        },
        {
            title: '#',
            width: 40,
            align: 'center',
            fixed: 'left',
            render: (value, obj, index) => index + 1
        },
        {
            title: <span style={{marginLeft: 12}}>枚举值</span>,
            dataIndex: 'interiOrid',
            key: 'interiOrid',
            width: 150,
            editable: !isDetail,
            render: v => <div className={'display-value'}>{v}</div>
        },
        {
            title: <span style={{marginLeft: 12}}>父级Id</span>,
            dataIndex: 'pid',
            key: 'pid',
            width: 150,
            editable: !isDetail,
            render: v => <Tooltip title={v}><div className={'display-value'}>{v}</div></Tooltip> 
        },
        {
            title: <span style={{marginLeft: 12}}>显示名称</span>,
            dataIndex: 'constDisplayName',
            key: 'constDisplayName',
            width: 200,
            editable: !isDetail,
            render: (value, obj) => <div className={'display-value'} style={{color: _.get(obj,'displayColor')}}>{value}</div>
        },
        {
            title: <span style={{marginLeft: 12}}>显示标志</span>,
            dataIndex: 'displayFlag',
            key: 'displayFlag',
            width: 80,
            editable: !isDetail,
            render: (value) => <div className={'display-value'}>{convertOptions(value, YNOptions)}</div>
        },
        {
            title: <span style={{marginLeft: 12}}>颜色</span>,
            dataIndex: 'displayColor',
            key: 'displayColor',
            width: 100,
            render: (value, obj, index) => isDetail ? <div className={'display-value'} style={{color: value}}>{value}</div> :
                <Popover open={_.get(obj,'uid') === showColorPickerId} trigger="click"
                         content={<ColorPickerPopContent defaultColor={value} setColor={color => setDisplayColor(color, index)} close={()=>setShowColorPickerId(null)}/>}>
                    <div className={'color-wrap flex center-y'} style={{color: value}} onClick={()=>setShowColorPickerId(_.get(obj,'uid'))}>{value}</div>
                </Popover>
        },
        {
            title: <span style={{marginLeft: 12}}>特征</span>,
            dataIndex: 'memo',
            key: 'memo',
            width: 80,
            editable: !isDetail,
            render: v => <Tooltip title={v}><div className={'display-value'}>{v}</div></Tooltip>
        },
    ])
}

function GlobalConstUpdateDialog(props) {
    const {currentInfo, close, refreshViewList} = props
    const {mode: initMode, id: initId} = useMemo(()=>currentInfo || {},[currentInfo])
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const [selectedList, setSelectedList] = useState([])
    const [showList, setShowList] = useState([])
    const [showColorPickerId, setShowColorPickerId] = useState()
    const [form] = AntdForm.useForm()
    const {doFetch: submitPost} = usePost()
    const [mode, setMode] = useState(initMode || 'detail')
    const [contentLoading, setContentLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [id, setId] = useState(initId)
    const {data: initForm, doFetch: getDetail} = useGet()
    const {data: checkNoRepeat,doFetch: checkRepeatGet} = useGet()

    const isDetail = useMemo(()=>{
        return mode === 'detail'
    },[mode])

    useEffect(() => {
        const { id } = currentInfo || {}
        if (!_.isNil(id)) {
            setId(id)
        }
    }, [currentInfo])

    const refreshDetail = useCallback(()=>{
        if(_.isNil(id)) return
        setContentLoading(true)
        getDetail('/common/globalConst/info?id='+id).then(res => {
            setFormData(res)
            const initList = _.map(_.get(res,'globalConstList'), x => {
                x['uid'] = uniqKeyFor()
                return x
            })
            setShowList(initList)
            setContentLoading(false)
        }).catch(err => {
            setContentLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[getDetail, id])

    useEffect(()=>{
        refreshDetail()
    },[refreshDetail])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = _.findIndex(showList, x => _.get(x,'uid') === restProps['data-row-key'])
        return <AntdForm form={form} component={false}>
            <GlobalConstEditContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </GlobalConstEditContext.Provider>
        </AntdForm>
    },[showList, form])

    const handleSave = useCallback((row)=>{
        const index = _.findIndex(showList, x => x.uid === row.uid)
        setShowList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return _.sortBy(newList, 'sortSerial')
        })
    },[showList])

    const setDisplayColor = useCallback((newColor, index)=>{
        setShowList(oldList => {
            return _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    displayColor: newColor
                })
            })
        })
    },[])
    
    const columns = useMemo(()=>{
        return _.map(getColumns({setDisplayColor, showColorPickerId, setShowColorPickerId, isDetail}), col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                })
            }
        })
    },[handleSave, showColorPickerId, setDisplayColor, isDetail])

    const addItem = useCallback(()=>{
       setShowList(oldList => {
           const newItem = {
               uid: uniqKeyFor(),
               displayFlag: 'Y',
               sortSerial: _.isEmpty(oldList) ? 1 : _.max(_.map(oldList, x => x.sortSerial)) + 1
           }
           return _.concat([],oldList, [newItem])
       })
    },[])

    const delItem = useCallback(()=>{
        setShowList(oldList => {
            return _.filter(oldList, x => !_.includes(selectedList, _.get(x,'uid')))
        })
        setSelectedList([])
    },[selectedList])

    const checkRepeat = useCallback((globalConst)=>{
        if(isNil(globalConst)) return
        if(globalConst === _.get(initForm,'globalConst')) return
        checkRepeatGet('/common/checkGlobalConstIsRepeat?globalConst='+globalConst)
    },[checkRepeatGet, initForm])

    const onFormChange = useCallback((newObj, bind)=>{
        if(bind === 'globalConst'){
            checkRepeat(_.get(newObj,'globalConst'))
        }
        setFormData(newObj)
    },[checkRepeat])

    const isRepeat = useMemo(()=>{
        if(isNil(_.get(formData,'globalConst'))) return false
        if(mode === 'add'){
            return !checkNoRepeat
        }else{
            if(_.get(formData,'globalConst') === _.get(initForm,'globalConst')) return false
            return !checkNoRepeat
        }
    },[checkNoRepeat, formData, initForm, mode])

    const canSubmit = useMemo(()=>{
        if(isRepeat) return false
        if(_.isEmpty(showList)) return false
        return _.every(formError, _.isNil)
    },[formError, showList, isRepeat])

    const checkListItemRepeat = useCallback(()=>{
        const idList = _.map(showList,x => _.get(x,'interiOrid'))
        const sortList = _.sortBy(idList)
        let repeatId
        for(let i=0; i<sortList.length; i++){
            if(sortList[i] === sortList[i+1]){
                repeatId = sortList[i]
                break;
            }
        }
        if(repeatId){
            Messager.show(`枚举值不能重复，重复值为：${repeatId}`)
            return false
        }else{
            return true
        }
    },[showList])

    const submit = useCallback(()=>{
        if(!checkListItemRepeat()) return
        if(submitLoading) return
        setSubmitLoading(true)
        const globalConstList = _.map(showList, x => {
            if(_.isNil(_.get(x,'id'))){
                return {
                    ...x,
                    id: null
                }
            }else{
                return x
            }
        })
        const sortedGlobalConstList = _.sortBy(globalConstList,'sortSerial')
        _.forEach(sortedGlobalConstList, (x, i) => {
            x['sortSerial'] = i + 1
        })
        const submitParams = {
            ...formData,
            globalConstList: sortedGlobalConstList,
            globalConstListOld: _.get(initForm,'globalConstList') || []
        }
        submitPost('/common/globalConst/addOrUpdate',submitParams).then((newIdList)=>{
            setSubmitLoading(false)
            Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' })
            refreshViewList()
            if(_.isEmpty(newIdList)){
                close()
            }else if(_.includes(newIdList, id)){
                refreshDetail()
                setMode('detail')
            }else{
                setId(_.head(newIdList))
                setMode('detail')
            }
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[formData, showList, mode, submitPost, close, refreshViewList, submitLoading, initForm, checkListItemRepeat, id, refreshDetail])

    return <Dialog headerVisible={false} className={'global-const-update-dialog flex-y'} contentClassName={'flex-y'}
                   confirmButtonDisabled={!canSubmit} confirm={submit} cancel={close}>
        <div className="mock-dialog-header flex">
            <div className="dialog-title">
                枚举详情
            </div>
            <div className="mock-right-header flex center-y">
                {
                    mode !== 'add' &&
                    <span style={{marginRight: 16}}>
                        <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={mode === 'detail'?'进入编辑':'退出编辑'}
                                     onClick={()=>{
                                         if(mode === 'detail'){
                                             setMode('edit')
                                         }else{
                                             const initList = _.map(_.get(initForm,'globalConstList'), x => {
                                                 x['uid'] = uniqKeyFor()
                                                 return x
                                             })
                                             setShowList(initList)
                                             setFormData(initForm)
                                             setMode('detail')
                                         }
                                     }}
                        />
                    </span>
                }
                <div className={'close-area flex center'} onClick={close}>
                    <Icon name={'quxiao'} className={'close-icon'} />
                </div>
            </div>
        </div>
        <div className="global-const-update-content flex-y">
            {
                (contentLoading || submitLoading) && <Loader fill/>
            }
            <div className="form-wrap flex-y">
                <Form value={formData} onChange={onFormChange} error={formError} onError={setFormError}>
                    {
                        isRepeat &&
                        <div className={'repeat-tips'}>重复的枚举id</div>
                    }
                    <div className={'flex'}>
                        <HFormInput label={'枚举ID'} bind={'globalConst'} required component={isDetail?Display:Input}/>
                        <HFormInput label={'枚举名称'} bind={'globalConstName'} required component={isDetail?Display:Input}/>
                    </div>
                    {
                        isDetail && <div className={'textarea-field-wrap flex'}>
                            <HFormInput label='备注' componentWidth={0} component={Display} convert={()=>''}/>
                            <div className={'textarea-show-value'} style={{width: 476}}>
                                {
                                    _.get(formData,'globalConstMemo')
                                }
                            </div>
                        </div>
                    }
                    {
                        !isDetail && <HFormInput className={'text-area-input-edit'} label='备注' bind='globalConstMemo'
                                                 component={TextAreaInput} componentWidth={476} />
                    }
                </Form>
            </div>
            {
                !isDetail &&
                <div className="handle-btn-group flex center-y">
                    <Button normal className={'handle-btn'} onClick={addItem}>添加行</Button>
                    <Button normal className={'handle-btn'} disable={_.isEmpty(selectedList)} onClick={delItem}>删除行</Button>
                </div>
            }
            <Table
                // loading={loading}
                size={'small'}
                sticky={true}
                pagination={false}
                dataSource={showList}
                columns={columns}
                rowKey="uid"
                scroll={{y: `calc(100% - 48px)`}}
                rowSelection={isDetail ? null : {onChange: setSelectedList}}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: EditableRow,
                        cell: EditableCell
                    },
                }}
            />
        </div>
    </Dialog>

    function onSortEnd({oldIndex, newIndex}){
        if (oldIndex !== newIndex){
            if (oldIndex < newIndex) {
                const previous = showList[newIndex].sortSerial
                const next = _.get(showList, `[${newIndex + 1}]sortSerial`)
                showList[oldIndex].sortSerial = _.isNil(next) ? previous + 1 : (previous + next) / 2
            }else if (oldIndex > newIndex) {
                const previous = _.get(showList, `[${newIndex - 1}]sortSerial`, 0)
                const next = showList[newIndex].sortSerial
                showList[oldIndex].sortSerial = (previous + next) / 2
            }
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setShowList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, editAuth = true, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(GlobalConstEditContext) || {}

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        if(!editAuth) return setEditing(false)
        setEditing(x => !x);
        form.setFieldsValue({ [dataIndex]:  record[dataIndex] });
    },[dataIndex, record, form, editAuth])

    const save = () => {
        try{
            form.validateFields().then((changeObj)=>{
                const key = _.head(_.keys(changeObj))
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    handleSave({ ...record, ...changeObj });
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        } catch (errInfo) {
            console.error(errInfo);
        }
    }

    let childNode = children;

    if(editable){
        if(editing){
            let editNode
            if(dataIndex === 'displayFlag'){
                editNode = (<Select ref={inputRef} options={YNOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else{
                editNode = (<AntdInput ref={inputRef} onPressEnter={save} onBlur={save} />)
            }
            childNode = (
                <AntdForm.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </AntdForm.Item>
            )
        }else{
            childNode =(
                <div className="dev-project-stage-editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }
    return <td {...restProps}>{childNode}</td>;
}

export default GlobalConstUpdateDialog;
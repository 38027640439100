import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RequirementDetailRightPanel.scss'
import Icon from "../../../../components/Icon";
import { Messager } from 'rootnet-ui'
import { DatePicker, Display, Form, FormInput, Input, Select } from "rootnet-edit";
// import PersonSelect from "../../../common/personSelect/PersonSelect";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import { Tooltip } from "antd";
import _ from "lodash";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import convertOptions from "../../../common/ConvertOptions";
import { uniqKeyFor } from "../../../../project_share/utils/utils";
import { useFuncCode } from "../../../common/commonMethod";
import UserSelect from '../../../common/personSelectPop/UserSelect';
import VersionSelect from "../../applyJoinVersionDialog/VersionSelect";
import ApplyJoinVersionDialog from "../../applyJoinVersionDialog/ApplyJoinVersionDialog";
import { strParams } from '../../../../utils/publicFun';
import ClosurePlanMessage from '../../../common/closurePlanMessage';
import gd from '../../../../base/global';
import ApplyJoinProjectDialog from '../../applyJoinProjectDialog';

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=AUDITTYPE',
    'common/globalconst?globalConst=AUDITLIST',
]

const OPTIONS_URLS = [
    // '/develop/release/list?stateList=T&versionType=0',
    // '/userProject/list',
    '/develop/release/list?stateList=C&versionType=0',
    '/develop/release/list?stateList=R,L&versionType=0',
    '/develop/release/list?stateList=C&versionType=1',
    '/develop/release/list?stateList=R,L&versionType=1',
    '/develop/release/list?stateList=T,C&versionType=1',
]

const VERSION_UNDER_APPROVAL_URL = '/ReleaseStoryLog/getReleaseStoryLogList?type=REQ' //审批中版本

function RequirementDetailRightPanel(props) {
    const { isDetail, allUserRes, editFormData, setEditFormData, needBizFlag, initFormData, showAllDetailAuth, infoError,
        setInfoError, id, isParent, hasChildrenReq, refreshReleaseList, planUnderApprobal, isAll, leftAllProjectOptions } = props
    const [assessExpand, setAssessExpand] = useState(true)
    const [planExpand, setPlanExpand] = useState(true)
    const [createInfoExpand, setCreateInfoExpand] = useState(true)
    const [businessExpand, setBusinessExpand] = useState(true)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { doFetch, data: underApprobal } = useGet()

    const [showJoinProject, setShowJoinProject] = useState(false)
    const [showJoinProjectDel, setShowJoinProjectDel] = useState(false)
    const [showJoinVersion, setShowJoinVersion] = useState(false)
    const [closurePlan, setClosurePlan] = useState()
    const [closurePlanVersion, setClosurePlanVersion] = useState()
    const [oldData, setOldData] = useState([])
    const { doFetch: getReleaseList } = useGet()
    const [releaseStoryListOptions, setReleaseStoryListOptions] = useState([])

    const { leafProjectIds } = useMemo(() => initFormData || {}, [initFormData])

    const allUnderApprobal = useMemo(() => {
        const planVersion = _.isEmpty(planUnderApprobal) ? [] : planUnderApprobal
        return _.concat(_.isEmpty(underApprobal) ? [] : underApprobal, planVersion)
    }, [underApprobal, planUnderApprobal])

    const releaseUnderReview = useCallback(() => {//审核中版本数据
        if (_.isNil(id)) return
        doFetch(`${VERSION_UNDER_APPROVAL_URL}&${strParams({ storyId: id, auditType: '1', auditFlag: 'U' })}`)
    }, [doFetch, id])

    useEffect(() => releaseUnderReview(), [releaseUnderReview])

    // const releaseStoryListOptions = useMemo(() => {
    //     if (_.isEmpty(releaseListRes)) return []
    //     return _.map(releaseListRes, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId }))
    // }, [releaseListRes])

    const [closurePlanOpt, releasedOpt, closurePlanOpts, releasedOpts, planVersionOpt] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d2, d3, d4, d5, d6,] = optionsRes

        return [
            // _.map(d1, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d2, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d3, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d4, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d5, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId })),
            _.map(d6, item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId, state: item.stateKey, principal: item.functionarys })),
        ]
    }, [optionsRes])

    const releaseStoryListOptionsWithExtend = useMemo(() => {
        const initReleaseList = _.get(editFormData, 'releaseList')
        if (_.isEmpty(releaseStoryListOptions)) return _.map(initReleaseList, x => ({ label: x, text: x, value: x }))
        const valueList = _.map(releaseStoryListOptions, 'value')
        const findNotIncludedList = _.filter(initReleaseList, item => !_.includes(valueList, item))
        const findNotIncludedOptions = _.map(findNotIncludedList, x => ({ label: x, text: x, value: x }))
        return _.concat(findNotIncludedOptions, releaseStoryListOptions)
    }, [releaseStoryListOptions, editFormData])

    const releaseStoryListOptWithExtend = useMemo(() => {
        if (_.isEmpty(planVersionOpt)) return []
        const initReleaseList = _.get(editFormData, 'planReleaseList')
        const valueList = _.map(planVersionOpt, 'value')
        const findNotIncludedList = _.filter(initReleaseList, item => !_.includes(valueList, item))
        const findNotIncludedOptions = _.map(findNotIncludedList, x => ({ label: x, text: x, value: x }))
        const allList = _.concat(findNotIncludedOptions, planVersionOpt)
        if (_.isEmpty(planUnderApprobal)) return allList
        const values = _.map(planUnderApprobal, 'releaseId')
        return _.filter(allList, o => !_.includes(values, o.value))
    }, [planVersionOpt, editFormData, planUnderApprobal])

    const extendProjectOptions = useMemo(() => {
        if (_.isEmpty(leftAllProjectOptions)) return []
        const filterList = _.filter(leftAllProjectOptions, o => o.examine === 'N')
        const leafProjectIds = _.get(initFormData, 'leafProjectIds')
        const options = _.filter(filterList, o => !_.includes(['1', '3', '7'], o?.status))
        if (!leafProjectIds) return options
        // const projectValueList = _.map(options, 'value')
        // const initItem = _.filter(_.filter(leftAllProjectOptions, o => o.examine === 'Y'), x => _.includes(leafProjectIds, x.value))
        const initItem = _.filter(leftAllProjectOptions, x => _.includes(leafProjectIds, x.value))
        // console.log(_.filter(leftAllProjectOptions, o => o.examine === 'Y'));

        if (!_.isEmpty(initItem)) {
            return _.compact(_.concat(initItem, options))
        }
        return options

    }, [leftAllProjectOptions, initFormData])

    const [auditTypeOptions, auditListOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const updateReleaseList = useCallback((projectIds, oldProject, currentVersion, discoveryProjectOnce, filterDelData, flag = true) => {
        if (_.isEmpty(projectIds)) return setReleaseStoryListOptions([])
        const obj = { versionType: 0, projectIds }
        getReleaseList(`/develop/release/list?${strParams(obj)}`)
            .then(res => {
                const options = _.map(_.filter(res, o => o.stateKey === 'T'), item => ({ label: item.releaseId, text: item.releaseId, value: item.releaseId, projectId: item.projectId }))
                if (flag) {
                    setReleaseStoryListOptions(options)
                } else {
                    discoveryProjectOnce = false
                    const underApprobalKeys = _.map(underApprobal, 'releaseId')
                    const newVersionIdList = _.concat(_.map(res, 'releaseId'), underApprobalKeys, ['不涉及版本规划'])
                    const allIncludeVersion = _.every(currentVersion, x => _.includes(newVersionIdList, x))
                    if (allIncludeVersion) {
                        if (!_.isEmpty(filterDelData)) {
                            setEditFormData(x => _.assign({}, x, { leafProjectIds: oldProject }))
                            setShowJoinProjectDel({ projectIdList: filterDelData })
                        } else {
                            setReleaseStoryListOptions(options)
                        }
                    } else {
                        Messager.show(`请先删除该项目下的版本计划，再操作删除项目！`, { icon: 'error' })
                        setEditFormData(x => _.assign({}, x, { leafProjectIds: oldProject }))
                    }
                }
            })
            .catch((err) => {
                if (!flag) discoveryProjectOnce = false
                Messager.show(err._message, { icon: 'error' });
            })
    }, [getReleaseList, setEditFormData, underApprobal])

    const changeForm = useCallback((newObj, bind) => {
        let discoveryProjectOnce = false
        setEditFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'releaseList') {
                const oldList = _.get(oldObj, 'releaseList')
                const newList = _.get(newObj, 'releaseList')
                if (_.size(oldList) > _.size(newList)) {
                    const releaseid = _.head(_.filter(oldList, v => !_.includes(newList, v)))
                    const findReleasedItem = _.find(releasedOpt, o => o.value === releaseid)
                    if (!_.isNil(findReleasedItem)) {
                        cloneObj[bind] = oldList
                        Messager.show('版本已发布或已上线，无法删除', { icon: 'error' })
                    } else {
                        const findItem = _.find(closurePlanOpt, o => o.value === releaseid)
                        if (!_.isNil(findItem)) {
                            cloneObj[bind] = oldList
                            setClosurePlan(newList)
                        }
                    }
                }
            }
            if (bind === 'planReleaseList') {
                const values = _.map(planUnderApprobal, 'releaseId')
                const oldList = _.get(oldObj, 'planReleaseList')
                const newList = _.get(newObj, 'planReleaseList')
                cloneObj['_planReleaseList'] = _.map(newList, v => {
                    const findItme = _.find(planVersionOpt, o => o.value === v)
                    const principal = _.split(_.get(findItme, 'principal', ''), ',')
                    if (_.includes(principal, gd.User.operator_id) || isAll) {
                        return _.assign({}, findItme, { state: 'T' })
                    }
                    return findItme
                })
                setOldData(_.map(oldList, v => {
                    const findItme = _.find(planVersionOpt, o => o.value === v)
                    const principal = _.split(_.get(findItme, 'principal', ''), ',')
                    if (_.includes(principal, gd.User.operator_id) || isAll) {
                        return _.assign({}, findItme, { state: 'T' })
                    }
                    return findItme
                }))
                const oldArr = _.filter(oldList, v => !_.includes(values, v))
                const newArr = _.filter(newList, v => !_.includes(values, v))
                if (_.size(oldArr) > _.size(newArr)) {
                    const releaseid = _.head(_.filter(oldArr, v => !_.includes(newArr, v)))
                    const principal = _.split(_.get(_.find(planVersionOpt, o => o.value === releaseid), 'principal', ''), ',')
                    if (_.includes(principal, gd.User.operator_id) || isAll) {

                    } else {
                        const findReleasedItem = _.find(releasedOpts, o => o.value === releaseid)
                        if (!_.isNil(findReleasedItem)) {
                            cloneObj[bind] = oldArr
                            Messager.show('预规划项目已立项完成，不允许删除', { icon: 'error' })
                        } else {
                            const findItem = _.find(_.filter(closurePlanOpts, item => _.includes(_.get(initFormData, 'planReleaseList'), item.value)), o => o.value === releaseid)
                            if (!_.isNil(findItem)) {
                                cloneObj[bind] = oldArr
                                setClosurePlanVersion(newArr)
                            }
                        }
                    }

                }
            }
            if (bind === 'leafProjectIds') {
                if (!discoveryProjectOnce) {

                    if (cloneObj['leafProjectIds']) {
                        cloneObj['budgetRevProject'] = _.get(oldObj, 'budgetrev')
                    } else {
                        cloneObj['budgetRevProject'] = null
                    }
                    const newProject = _.get(newObj, 'leafProjectIds', [])
                    const oldProject = _.get(oldObj, 'leafProjectIds', [])
                    const initProject = _.get(initFormData, 'leafProjectIds', [])
                    const filterProjectY = _.filter(leftAllProjectOptions, o => o.examine === 'Y')
                    const delData = _.filter(initProject, v => !_.includes(newProject, v))
                    const filterDelData = _.filter(delData, v => _.includes(_.map(filterProjectY, 'value'), v))
                    const deleteData = _.filter(oldProject, v => !_.includes(newProject, v))
                    const underApprobalOps = _.map(underApprobal, o => ({ value: o.releaseId, projectId: o.projectId, flag: true }))
                    // const concatData = _.concat(releaseStoryListOptionsWithExtend, underApprobalOps)
                    const filterVersion = _.filter(underApprobalOps, o => {
                        return (o.flag && _.includes(deleteData, o.projectId))
                    })

                    if ((_.isEmpty(_.get(newObj, 'leafProjectIds')) && !_.isEmpty(_.get(oldObj, 'releaseList'))) || !_.isEmpty(filterVersion)) {
                        cloneObj[bind] = oldObj[bind]
                        discoveryProjectOnce = false
                        Messager.show(`请先删除该项目下的版本计划，再操作删除项目！`, { icon: 'error' })
                    } else if (_.isEmpty(_.get(newObj, 'leafProjectIds'))) {
                        discoveryProjectOnce = false
                        setReleaseStoryListOptions([])
                    } else {
                        const currentVersion = _.isEmpty(_.get(oldObj, 'releaseList')) ? [] : _.get(oldObj, 'releaseList')
                        updateReleaseList(cloneObj[bind], oldProject, currentVersion, discoveryProjectOnce, filterDelData, false)
                    }

                    // if (_.size(cloneObj['releaseList']) > 0 && _.size(deleteData) > 0) {
                    //     cloneObj[bind] = oldObj[bind]
                    //     // const versionList = cloneObj['releaseList']
                    //     Messager.show(`请先删除该项目下的版本计划，再操作删除项目！`, { icon: 'error' })
                    // } else {
                    //     updateReleaseList(cloneObj[bind])
                    //     if (!_.isEmpty(filterDelData)) {
                    //         cloneObj[bind] = _.compact(_.concat([], cloneObj[bind], filterDelData))
                    //         setShowJoinProjectDel({ projectIdList: filterDelData })
                    //     }
                    // }
                }
            }
            return cloneObj
        })
    }, [setEditFormData, closurePlanOpt, releasedOpt, closurePlanOpts, releasedOpts, planVersionOpt, initFormData, planUnderApprobal, isAll, leftAllProjectOptions, updateReleaseList, underApprobal])

    const delReleaseList = useCallback((delItem, bind = 'releaseList') => {
        setEditFormData(oldObj => {
            const tempObj = _.clone(oldObj)
            const releaseList = _.get(tempObj, bind)
            tempObj[bind] = _.filter(releaseList, x => x !== delItem)
            return tempObj
        })
    }, [setEditFormData])

    const canClearProject = useMemo(() => {
        if (_.get(initFormData, 'type') !== 'CUS') {
            return true
        }
        return _.isNil(_.get(initFormData, 'leafProjectIds'))
    }, [initFormData])

    const [editReleaseListAuth, editLeafProjectIdAuth] = useFuncCode(['1502', '1505'])

    const ReleaseListSelect = useCallback((props) => {
        return <span style={{ margin: 0 }} onClick={() => {
            if (_.isEmpty(releaseStoryListOptionsWithExtend)) return Messager.show('请先选择所属项目，版本计划为所属项目范围下，状态为【清单整理中】的版本！', { icon: 'error' })
        }}>
            <VersionSelect {...props} />
        </span>
    }, [releaseStoryListOptionsWithExtend])

    useEffect(() => {
        if (_.isEmpty(leafProjectIds)) return
        updateReleaseList(leafProjectIds)
    }, [leafProjectIds, updateReleaseList])

    return <div className={'requirement-detail-right-panel flex-y'}>
        <div className="area-wrap assess-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: assessExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setAssessExpand(v => !v)} />
                评估信息
            </div>
            <div className="area-content-wrap" style={{ height: assessExpand ? '' : 0 }}>
                <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
                    {/*<HFormInput label={'根网接口人'} bind={'interfaceUser'} required component={isDetail ? Display : UserSelect}*/}
                    {/*    convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />*/}
                    <HFormInput label={'需求负责人'} bind={'assessor'} required component={isDetail ? Display : UserSelect} hiddenUserList={[_.get(editFormData, 'assessauditor')]}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    {
                        showAllDetailAuth &&
                        <>
                            <HFormInput label={'需求工作量'} bind={'workload'} suffix={'人日'} component={Display} type={'number'} min={0} digit={2}
                                convert={v => {
                                    const productWorkload = _.get(initFormData, 'productWorkload')
                                    const developmentWorkload = _.get(initFormData, 'developmentWorkload')
                                    const testWorkload = _.get(initFormData, 'testWorkload')
                                    if (_.isNil(productWorkload) && _.isNil(developmentWorkload) && _.isNil(testWorkload)) {
                                        if (!_.isNil(v)) return v + '人日'
                                        return '-'
                                    }
                                    return ((_.toNumber(productWorkload) * 100) + (_.toNumber(developmentWorkload) * 100) + (_.toNumber(testWorkload) * 100)) / 100 + '人日'
                                }}
                            />
                            <div style={{ marginLeft: 41 }}>
                                （
                                <HFormInput className="workload-num" placeholder='人日' labelWidth={41} componentWidth={30} label={'产品'} bind={'productWorkload'} component={isDetail ? Display : Input} type={'number'} min={0} digit={2} />
                                <HFormInput className="workload-num" placeholder='人日' labelWidth={41} componentWidth={30} label={'开发'} bind={'developmentWorkload'} component={isDetail ? Display : Input} type={'number'} min={0} digit={2} />
                                <HFormInput className="workload-num" placeholder='人日' labelWidth={41} componentWidth={30} label={'测试'} bind={'testWorkload'} component={isDetail ? Display : Input} type={'number'} min={0} digit={2} />
                                ）
                            </div>
                        </>
                    }
                    <HFormInput label={'计划发布日期'} bind={'releaseDate'} component={isDetail ? Display : DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}
                    />
                    <Tooltip title={'五个工作日内进行的商务确认有效'}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'评估审核人'} bind={'assessauditor'} component={isDetail ? Display : UserSelect} allowClear convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} hiddenUserList={[_.get(editFormData, 'assessor')]} />
                    <Tooltip title={'需求负责人和评估审核人不能是同一个人'}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                        </span>
                    </Tooltip>
                    <HFormInput label={'审核状态'} bind={'assessflag'} component={isDetail ? Display : Select} options={auditTypeOptions} convert={v => convertOptions(v, auditTypeOptions)} />
                    {
                        showAllDetailAuth &&
                        <HFormInput label={'实施评估工作量'} bind={'impworkload'} suffix={'人日'} component={isDetail ? Display : Input} type={'number'} min={0} digit={2}
                            convert={v => {
                                if (_.isNil(v)) return '-'
                                return v + '人日'
                            }}
                        />
                    }
                    <HFormInput label={'实施评估人'} bind={'impassessor'} allowClear component={isDetail ? Display : UserSelect} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'需求分析人'} bind={'analystuser'} component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'分析复核人'} bind={'analystauditor'} component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'分析复核状态'} bind={'analystflag'} component={isDetail ? Display : Select}
                        options={auditListOptions} convert={v => convertOptions(v, auditListOptions)} />
                    <HFormInput label={'研发负责人'} bind={'devUser'} component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'需求测试人'} bind={'analystTestUser'} component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'客户专员'} bind={'customerSpecialist'} component={isDetail ? Display : UserSelect}
                        convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'验收状态'} bind={'acceptanceStatus'} component={Display} convert={v => convertOptions(v, auditTypeOptions)} />
                    <HFormInput label={'不通过次数'} bind={'noPassNum'} component={Display} />
                    {/*<HFormInput label={'关闭类型'} bind={'closeType'} component={isDetail ? Display : Select} options={[]}*/}
                    {/*    convert={v => v} />*/}
                </Form>
            </div>
        </div>
        <div className="area-wrap plan-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: planExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setPlanExpand(v => !v)} />
                需求规划
            </div>
            <div className="area-content-wrap" style={{ height: planExpand ? '' : 0 }}>
                <Form value={editFormData} onChange={changeForm}>
                    {
                        !isDetail &&
                        <HFormInput label={'预规划项目'} bind={'planReleaseList'} disabled={isDetail || !editReleaseListAuth || (isParent && hasChildrenReq)} component={VersionSelect} isControlled={true}
                            toolbar={false} search multiple options={releaseStoryListOptWithExtend} componentClass={'release-list-options'} />
                    }
                    {
                        isDetail &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">预规划项目</div>
                            {
                                !_.isEmpty(_.get(editFormData, 'planReleaseList')) &&
                                <div className={'release-list-show-wrap'}>
                                    {
                                        _.map(_.get(editFormData, 'planReleaseList'), item => {
                                            return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                                <Tooltip title={convertOptions(item, releaseStoryListOptWithExtend)} placement="left">
                                                    <span className={'release-list-item-text'}>{convertOptions(item, releaseStoryListOptWithExtend)}</span>
                                                </Tooltip>
                                                {
                                                    !isDetail && editReleaseListAuth &&
                                                    <Icon name='baocuo' className={'release-list-item-icon'} onClick={() => delReleaseList(item, 'planReleaseList')} />
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        !isDetail &&
                        <HFormInput label={'所属项目'} multiple bind={'leafProjectIds'} clear={canClearProject} disabled={isDetail || !editLeafProjectIdAuth || (isParent && hasChildrenReq)} component={isDetail ? Display : Select} search options={extendProjectOptions}
                            convert={v => {
                                const text = _.join(_.map(v, o => convertOptions(o, leftAllProjectOptions)), '，')
                                return <Tooltip title={text}>
                                    <div className='leafProjectIds-tip'>{text}</div>
                                </Tooltip>
                            }} componentStyle={{ height: isDetail ? 'unset' : '' }} className={'leaf-project-id-form-input'} />
                    }
                    {
                        isDetail &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">所属项目</div>
                            {
                                !_.isEmpty(_.get(editFormData, 'leafProjectIds')) &&
                                <div className={'release-list-show-wrap'}>
                                    {
                                        _.map(_.get(editFormData, 'leafProjectIds'), item => {
                                            return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                                <Tooltip title={convertOptions(item, leftAllProjectOptions)} placement="left">
                                                    <span className={'release-list-item-text'}>{convertOptions(item, leftAllProjectOptions)}</span>
                                                </Tooltip>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        !isDetail && !(isParent && hasChildrenReq) &&
                        <Tooltip title={'申请加入已基线项目'}>
                            <span>
                                <Icon name={'tianjia'} className={'join-project-icon'} style={{ fontSize: 14, color: '#5477FF' }}
                                    onClick={() => setShowJoinProject(true)}
                                />
                            </span>
                        </Tooltip>
                    }
                    {
                        !_.isEmpty(_.get(editFormData, 'projectAuditLog', [])) &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">审核中项目</div>
                            <div className={'release-list-show-wrap'}>
                                {
                                    _.map(_.get(editFormData, 'projectAuditLog', []), o => {
                                        return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                            <Tooltip title={o.projectName} placement="left">
                                                <span className={'release-list-item-text'}>{o.projectName}</span>
                                            </Tooltip>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <HFormInput label={'版本计划'} bind={'releaseList'} disabled={isDetail || !editReleaseListAuth || (isParent && hasChildrenReq)} component={ReleaseListSelect} isControlled={true}
                            toolbar={false} search multiple options={releaseStoryListOptionsWithExtend} componentClass={'release-list-options'} />
                    }
                    {
                        !isDetail && !(isParent && hasChildrenReq) &&
                        <Tooltip title={'申请加入已封板的版本。只允许选择所属项目下的进行中的版本计划！'}>
                            <span>
                                <Icon name={'tianjia'} className={'join-version-icon'} style={{ fontSize: 14, color: '#5477FF' }}
                                    onClick={() => setShowJoinVersion(true)} />
                            </span>
                        </Tooltip>
                    }
                    {
                        isDetail &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">版本计划</div>
                            {
                                !_.isEmpty(_.get(editFormData, 'releaseList')) &&
                                <div className={'release-list-show-wrap'}>
                                    {
                                        _.map(_.get(editFormData, 'releaseList'), item => {
                                            return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                                <Tooltip title={convertOptions(item, releaseStoryListOptionsWithExtend)} placement="left">
                                                    <span className={'release-list-item-text'}>{convertOptions(item, releaseStoryListOptionsWithExtend)}</span>
                                                </Tooltip>
                                                {
                                                    !isDetail && editReleaseListAuth &&
                                                    <Icon name='baocuo' className={'release-list-item-icon'} onClick={() => delReleaseList(item)} />
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        !_.isEmpty(allUnderApprobal) &&
                        <div className={'release-list-wrap flex center-y'}>
                            <div className="mock-label">审核中版本</div>
                            <div className={'release-list-show-wrap'}>
                                {
                                    _.map(allUnderApprobal, o => {
                                        return <div className={'release-list-item flex center-y'} key={uniqKeyFor()}>
                                            <Tooltip title={o.releaseId} placement="left">
                                                <span className={'release-list-item-text'}>{o.releaseId}</span>
                                            </Tooltip>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }
                </Form>
            </div>
        </div>
        {
            needBizFlag === 'Y' && isParent &&
            <div className="area-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: businessExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBusinessExpand(v => !v)} />
                    商务确认信息
                </div>
                <div className="area-content-wrap" style={{ height: businessExpand ? '' : 0 }}>
                    <Form value={editFormData}>
                        <HFormInput label={'商务审批人'} bind={'businessauditor'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                        <HFormInput label={'审批状态'} bind={'businessauditstate'} component={Display} convert={v => convertOptions(v, auditTypeOptions)} />
                        <HFormInput label={'审批时间'} bind={'businessaudittime'} component={Display}
                            convert={v => {
                                if (_.isNil(v)) return '-'
                                if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                            }}
                        />
                    </Form>
                </div>
            </div>
        }
        <div className="area-wrap create-info-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: createInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCreateInfoExpand(v => !v)} />
                创建信息
            </div>
            <div className="area-content-wrap" style={{ height: createInfoExpand ? '' : 0 }}>
                <Form value={editFormData}>
                    <HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} />
                    <HFormInput label={'创建时间'} bind={'createTime'} component={Display}
                        convert={v => {
                            if (_.isNil(v)) return '-'
                            if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                        }}
                    />
                </Form>
            </div>
        </div>
        {
            showJoinVersion &&
            <ApplyJoinVersionDialog id={id} close={() => setShowJoinVersion(false)} type={'REQ'} afterRefresh={() => {
                refreshReleaseList()
                releaseUnderReview()
            }} projectIds={_.get(editFormData, 'leafProjectIds')} />
        }
        {
            showJoinProject &&
            <ApplyJoinProjectDialog
                mode='add'
                businessId={id}
                businessType='req'
                leafProjectIds={_.get(initFormData, 'leafProjectIds', [])}
                projectAuditLog={_.get(editFormData, 'projectAuditLog', [])}
                close={() => setShowJoinProject(false)}
                leftAllProjectOptions={_.filter(leftAllProjectOptions, o => o.examine === 'Y')}
                refresh={refreshReleaseList}
            />
        }
        {
            showJoinProjectDel &&
            <ApplyJoinProjectDialog
                mode='del'
                businessId={id}
                businessType='req'
                initParams={showJoinProjectDel}
                leafProjectIds={_.get(initFormData, 'leafProjectIds', [])}
                projectAuditLog={_.get(editFormData, 'projectAuditLog', [])}
                close={() => {
                    setShowJoinProjectDel(false)
                }}
                leftAllProjectOptions={_.filter(leftAllProjectOptions, o => o.examine === 'Y')}
                refresh={refreshReleaseList}
            />
        }
        {
            !_.isNil(closurePlan) &&
            <ClosurePlanMessage
                id={id}
                type='REQ'
                releaseidList={closurePlan}
                close={() => setClosurePlan(null)}
                afterRefresh={refreshReleaseList}
            />
        }
        {
            !_.isNil(closurePlanVersion) &&
            <ClosurePlanMessage
                id={id}
                type='PLANREQ'
                releaseidList={closurePlanVersion}
                close={() => setClosurePlanVersion(null)}
                afterRefresh={refreshReleaseList}
                setEditFormData={setEditFormData}
                oldData={oldData}
            />
        }
    </div>
}

export default RequirementDetailRightPanel;
import React, { useState, useCallback, useContext } from 'react'
import { Popover } from 'antd'
import { Messager } from 'rootnet-ui'
import { copyText } from "rootnet-core/clipboard"
import { ModeContext, ValueContext } from '../../../common/Context'
import { Icon } from '../../../../components'
import { TextIconBtn } from '../../../common/commonComponent'


export function BubbleCard() {
  const { selectedTreeObj, setMode } = useContext(ValueContext)
  const { id, name, fullPath } = selectedTreeObj
  const [clicked, setClicked] = useState(false)

  const copy = useCallback((value) => {
    copyText(value);
    Messager.show('数据已复制到剪切板', { icon: 'success' });
  }, [])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={() => setMode({ modes: 'addChild', pid: id })}>创建子目录</div>
      <div onClick={() => setMode({ modes: 'edit', id })}>修改目录</div>
      <div onClick={() => setMode({ modes: 'delete', id, name })}>删除目录</div>
      <div onClick={() => copy(fullPath)}>复制全路径</div>
    </div>
  }, [copy, fullPath, id, name, setMode])

  return (
    // <Tooltip
    //   title="更多"
    //   placement='right'
    // >
    <Popover
      trigger="click"
      open={clicked}
      placement='right'
      onOpenChange={(visible) => setClicked(visible)}
      content={<Content />}
      overlayClassName='popoverMore'
      zIndex={5}
    >

      <Icon name="gengduo2" onClick={() => setClicked(x => !x)} />
    </Popover>
    // </Tooltip>

  )
}

export function MoreOperations(props) {
  const { createRelationAuth, saveFileAuth, exportViewList } = props
  const { delSelect, setModeAll, updateStatusSelect } = useContext(ModeContext)
  const [clicked, setClicked] = useState(false)

  const onClick = useCallback((mode) => {
    setModeAll({ mode })
    setClicked(false)
  }, [setModeAll])

  const exportList = useCallback(() => {
    exportViewList()
    setClicked(false)
  }, [exportViewList])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={() => onClick('import')}>导入</div>
      <div onClick={exportList}>导出</div>
      <div onClick={() => onClick('move')}>移动</div>
      <div onClick={() => onClick('copy')}>复制</div>
      <div onClick={updateStatusSelect}>修改状态</div>
      {
        createRelationAuth &&
        <div onClick={() => onClick('addTags')}>批量增加标签</div>
      }
      {
        saveFileAuth &&
        <div onClick={() => onClick('saveCase')}>归档</div>
      }
      <div onClick={() => onClick('plan')}>关联测试计划</div>
      <div onClick={delSelect} > 删除</div>
    </div>
  }, [delSelect, onClick, updateStatusSelect, createRelationAuth, saveFileAuth, exportList])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='更多操作' onClick={() => setClicked(x => !x)} />
    </Popover>
  )

}

import _ from "lodash";
import PersonSelectUserItem from "./PersonSelectUserItem";
import React from "react";
import Icon from "../../../components/Icon";

export function buildUserDeptTree(props){
    const {userRes, deptRes, onNodeClick, onSelect, selectedUserAccountList, onDeptAddClick, multiple,
        allUserSelectedInDept, canSelectDept, selectedDeptIdList} = props
    const activeDepartmentList = _.filter(deptRes, x => x.activeFlag === 'Y')
    const treeData = getDepartmentChildren({
        allUserList:userRes,
        allDepartmentList: activeDepartmentList,
        department: '',
        onNodeClick,
        onSelect,
        selectedUserAccountList,
        onDeptAddClick,
        multiple,
        allUserSelectedInDept,
        canSelectDept,
        selectedDeptIdList
    })
    return treeData
}

function getDepartmentChildren(props){
    const {allUserList,allDepartmentList,department,onNodeClick, onSelect, selectedUserAccountList, onDeptAddClick,
        multiple, allUserSelectedInDept, canSelectDept, selectedDeptIdList} = props
    const userList = _.filter(allUserList, x => x.department === department)
    const userOptions = _.map(userList, x => ({key: `${x.userAccount}${x.userName}`,
        title: <PersonSelectUserItem userInfo={x} onSelect={onSelect} {...{selectedUserAccountList}}/>, type: 'user'}))
    const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
    const childDepartmentOptions = _.map(childDepartmentList, x => ({
        title: <div className={'dept-name-wrap flex center-y'} onClick={(e)=>{
            if(canSelectDept){
                e.stopPropagation()
                onSelect(x,'department')
            }else{
                onNodeClick(`dept-${x.departMentId}`)
            }
        }}>
            <div>{x.departMentName}
                {
                    canSelectDept && _.includes(selectedDeptIdList, x.departMentId) &&
                    <div className={'dept-item-selected flex center'}>
                        √
                    </div>
                }
                {
                    !canSelectDept && !(_.isEmpty(_.filter(allUserList, item => item.department === x.departMentId)) &&
                        _.isEmpty(_.filter(allDepartmentList, item => item.parentDept === x.departMentId))) &&
                    allUserSelectedInDept(x.departMentId) &&
                    <div className={'dept-item-selected flex center'}>
                        √
                    </div>
                }
            </div>
            {
                multiple &&
                <Icon name={'tianjia'} className={'add-dept-icon'} onClick={e => onDeptAddClick(e,x.departMentId)}/>
            }
        </div>,
        key: `dept-${x.departMentId}`,
        type: 'department',
        children: getDepartmentChildren({
            allUserList,
            allDepartmentList,
            department: x.departMentId,
            onNodeClick,
            onSelect,
            selectedUserAccountList,
            onDeptAddClick,
            multiple,
            allUserSelectedInDept,
            canSelectDept,
            selectedDeptIdList
        }),
    }))
    return [...userOptions, ...childDepartmentOptions]
}
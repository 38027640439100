import React from 'react'
import { Dialog } from 'rootnet-ui'
import ContentTemplateDetailContent from './contentTemplateDetailContent'
import './index.scss'

export default function ContentTemplateDetailDialog(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog className={'content-template-detail-dialog'} headerVisible={false} footerVisible={false}>
      <ContentTemplateDetailContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

import _ from 'lodash'
import React, { useContext } from 'react'
import { Form, FormInput, TextArea, Display } from 'rootnet-edit'
import { FormContext } from '../../../common/Context'

export default function AddAndEdit() {
  const {
    params, setParams, isDetail
  } = useContext(FormContext)

  const leftFormList = [
    {
      label: '测试目的',
      bind: 'objective',
    },
    {
      label: '前置条件',
      bind: 'preconditions',
    },
    {
      label: '数据准备',
      bind: 'dataPre',
    },
    {
      label: '用例步骤',
      bind: 'caseSteps',
    },
    {
      label: '预期结果',
      bind: 'expectResult',
    },
    {
      label: '备注',
      bind: 'remark',
    },
  ]

  return (
    <>
      <div className='displayDetail'>
        <Form value={params} onChange={setParams} >
          {/* 左侧 */}
          <div className='flex-y'>
            {
              _.map(leftFormList, item => (
                <FormInput
                  key={item.bind}
                  maxLength={2048}
                  counter={false}
                  disabled={isDetail}
                  component={isDetail ? Display : TextArea}
                  {...item}
                />
              ))
            }
          </div>
        </Form>
      </div>
    </>
  )

}




import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {DatePicker, Display, Form, FormInput, Select, TextArea} from 'rootnet-edit'
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import _ from "lodash";
import convertOptions from "../../../common/ConvertOptions";
import {N2, number_format} from "rootnet-core/format";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import './RequirementBusiness.scss'
import Icon from "../../../../components/Icon";
import { Popover } from 'antd';
import {uniqKeyFor} from "../../../../project_share/utils/utils";

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=AUDITTYPE',
    'common/globalconst?globalConst=feeType',
    'common/globalconst?globalConst=stagnationReason',
]

const FeeValueSuffix = {
    '0': '%',
    '1': '人日',
    '2': '元'
}
const FeeValueDigit = {
    '0': 2,
    '1': 2,
    '2': 2
}
const FeeTypeLabel = {
    '0': '收费比例',
    '1': '工作量',
    '2': '收费金额'
}

function RequirementBusiness(props) {
    const {isDetail, allUserRes, editFormData, setEditFormData} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [showFeeTypePop, setShowFeeTypePop] = useState(false)

    const feeType = useMemo(()=>{
        return _.get(editFormData,'feetype')
    },[editFormData])

    const [auditTypeOptions, feeTypeOptions, stagnationReasonOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData( oldObj => {
            const cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[setEditFormData])

    useEffect(()=>{
        if(_.isNil(editFormData)) return
        if(!_.get(editFormData, 'feetype')){
            changeForm({feetype: '2'})
        }
    },[editFormData, changeForm])

    const FeeTypePrefixPop = useCallback(()=>{
        return <div className={'fee-type-prefix-pop flex-y'}>
            {
                _.map(feeTypeOptions, item => (<div key={uniqKeyFor()} className={`fee-type-prefix-pop-item flex center-y ${feeType === item.value ? 'active': ''}`}
                    onClick={()=>{
                        changeForm({feetype: item.value, feevalue: null})
                        setShowFeeTypePop(false)
                    }}>
                    {item.text}
                </div>))
            }
        </div>
    },[feeTypeOptions, feeType, changeForm])

    const FeeTypePrefix = useCallback(()=>{
        return <Popover content={<FeeTypePrefixPop/>} placement="bottom" open={showFeeTypePop} onOpenChange={setShowFeeTypePop}>
            <div className={'fee-type-prefix'} onClick={()=>setShowFeeTypePop(true)}>
                {convertOptions(feeType, feeTypeOptions)}
                <Icon name={'fix-xia'} style={{marginLeft: 2}}/>
            </div>
        </Popover>
    },[feeTypeOptions, feeType, showFeeTypePop])

    return <div className={'requirement-business-wrap'}>
        <Form value={editFormData} onChange={changeForm}>
            <HFormInput label={'提交商务日期'} bind={'offerbizdate'} component={isDetail? Display:DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}/>
            {
                isDetail &&
                <HFormInput label={FeeTypeLabel[_.get(editFormData,'feetype')] || '收费金额'} bind={'feevalue'} component={Display}
                convert={v => v? (number_format(v, 'N'+(FeeValueDigit[_.get(editFormData,'feetype')] || '2')) + (FeeValueSuffix[_.get(editFormData,'feetype')]||'元')) : '-'}
                />
            }
            {
                !isDetail &&
                <HFormInput label={'收费类型'} bind={'feevalue'} prefix={<FeeTypePrefix/>} suffix={FeeValueSuffix[_.get(editFormData,'feetype')]}
                            type={'number'} min={0} digit={FeeValueDigit[_.get(editFormData,'feetype')] || 2} required={_.isNil(_.get(editFormData,'pid'))}/>
            }
            <HFormInput label={'收费日期'} bind={'chargedate'} component={isDetail? Display:DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}/>
            <HFormInput label={'审批人'} bind={'businessauditor'} component={Display}
                        convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
            <HFormInput label={'审批状态'} bind={'businessauditstate'} component={isDetail? Display: Select} required={_.isNil(_.get(editFormData,'pid'))}
                        options={auditTypeOptions} convert={v => convertOptions(v, auditTypeOptions)}/>
            <HFormInput label={'审批时间'} bind={'businessaudittime'} component={Display}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
                        }}
            />
            <HFormInput label={'核价单ID'} bind={'priceid'} component={Display}/>
            <HFormInput label={'研发预算收入'} bind={'budgetrev'} component={Display} convert={v => v ? N2(v)+'元' : '-'}/>
            <HFormInput label={'实施预算收入'} bind={'servicerev'} component={Display} convert={v => v ? N2(v)+'元' : '-'}/>
            <HFormInput label={'待商务确认日期'} bind={'toBusinessConfirmationDate'} component={isDetail? Display:DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}/>
            <HFormInput label={'核价日期'} bind={'pricingDate'} component={isDetail? Display:DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}/>
            <HFormInput label={'报价日期'} bind={'quotationDate'} component={isDetail? Display:DatePicker} clear
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}/>
            <HFormInput label={'停滞原因'} bind={'stagnationReason'} component={isDetail? Display: Select} clear
                        options={stagnationReasonOptions} convert={v => convertOptions(v, stagnationReasonOptions)}/>
            <HFormInput label={'备注信息'} bind={'businessauditmemo'} component={TextArea} componentWidth={482} placeholder={isDetail?'':'请输入'}
                        readOnly={isDetail} counter={!isDetail} componentClass={`memo-textarea ${isDetail? 'memo-textarea-detail': ''}`}/>
        </Form>
    </div>
}

export default RequirementBusiness;
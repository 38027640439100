import React, { useMemo, useState } from 'react';
import _ from "lodash";
import { usePost } from 'rootnet-biz/lib/hooks';
import { DataGrid, Pagination } from "rootnet-ui";
import convertTableAlign from '../common/view/convertTableAlign';
import findConvert from '../common/view/findConvert';
import useGetViewConfig from '../common/view/hooks/useGetViewConfig';
import useRefreshList from '../common/view/hooks/useRefreshList';
import useGetDateOptions from '../common/view/hooks/useGetDateOptions';
import useGetTreeList from '../common/view/hooks/useGetTreeList';
import ViewArea from '../common/view/ViewArea';
import { Box } from '../common/commonComponent';

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
      }
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '42'

export default function SalesLeadsMgt(props) {
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns })

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
    </div>
  }, [])

  return (
    <div className={' fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'销售机会'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </div>
  )
}

import _ from 'lodash'
import cls from 'clsx'
import React, { useEffect, useMemo, useState, useReducer, useCallback, useContext } from 'react'
import { Tooltip as AntdTooltip } from 'antd'
import { DataGrid, Tooltip, Pagination } from 'rootnet-ui'
import useGet from "rootnet-biz/es/hooks/useGet";
import usePost from "rootnet-biz/es/hooks/usePost";
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import DisplayForm from './components/displayForm'
import DelMessage from '../../../components/DelMessage'
import TestPlanDetail from './components/testPlanDetail'
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import findConvert from "../../common/view/findConvert";
import useGetIdList from "../../common/view/hooks/useGetIdList";
import useGetAppendData from "../../common/view/hooks/useGetAppendData";
import convertTableAlign from "../../common/view/convertTableAlign";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";
import useGetFactor from '../../common/view/hooks/useGetFactor'
import gd from '../../../base/global'
import { GuideContext } from "../../common/Context";
import { Icon } from '../../../components'
import './index.scss'
import { pathSearchFor } from '../../../utils/publicFun'
import ViewArea from "../../common/view/ViewArea";

const DEL_URL = '/test/plan/delete'
const LIST_URL = '/test/plan/list'

const getColumns = (props) => {
  const { fieldList, convertCollection, setMode, setDetail, appendData, dateOptions } = props
  const customColumns = [
    { header: '结果分布', bind: 'distributionOfResults', width: 180, tooltip: true, convert: (v, o) => (caseResultVoList(o)), class: 'progress-color' },
    { header: '已测用例', bind: 'testedCases', width: 80, tooltip: true, align: 'center', convert: (v, o) => measured(o) }
  ]

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return _.find(customColumns, x => x.bind === fieldItem.columnId) || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    switch (fieldItem.fieldId) {
      case 'planName':
        return <>
          <span className='enter-detail-field' onClick={() => setDetail({ planId: o.id })}>
            {findConvert(fieldItem, v, convertCollection, dateOptions) || '-'}
          </span>
          <span className='edit-icon'>
            <AntdTooltip title='编辑' getPopupContainer={trigger => trigger.parentNode}>
              <span>
                <Icon name='bianji2' onClick={() => setMode({ modes: 'edit', data: o })} />
              </span>
            </AntdTooltip>

            <AntdTooltip title='删除' getPopupContainer={trigger => trigger.parentNode}>
              <span>
                <Icon
                  name='shanchu2'
                  className='del-icon'
                  onClick={() => setMode({ modes: 'delete', planId: o.id })}
                />
              </span>
            </AntdTooltip>
          </span>
        </>
      default:
        return findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
    }

  }

  function measured(o) {
    const findData = _.find(appendData, item => item?.id === o?.id)
    const { rate } = findData?.object || {}
    return rate
  }

  function caseResultVoList(o) {
    const findData = _.find(appendData, item => item?.id === o?.id)
    const { blocking, fail, failed, skip, through, unenforced, throughed, fai, skiped, blockinged, unableCount, unableCounted } = findData?.object || {}
    return <div className='progressSet'>
      {through !== '0%' && <Progress width={cutStr(through)} fraction={throughed} color='#50C398' name='通过' />}
      {fail !== '0%' && <Progress width={cutStr(fail)} fraction={fai} color='#EA5252' name='失败' />}
      {blocking !== '0%' && <Progress width={cutStr(blocking)} fraction={blockinged} color='#F3B433' name='阻塞' />}
      {failed !== '0%' && <Progress width={cutStr(failed)} fraction={unenforced} color='#898688' name='未执行' />}
      {skip !== '0%' && <Progress width={cutStr(skip)} fraction={skiped} color='#C8C5C7' name='跳过' />}
      {unableCount !== '0%' && <Progress width={cutStr(unableCount)} fraction={unableCounted} color='#000000' name='无法执行' />}
    </div>
  }

  function Progress(props) {
    const { width, fraction, color, name } = props
    const style = {
      width: 8,
      height: 8,
      marginRight: 4,
      borderRadius: 4,
      background: color,
      display: 'inline-block',
    }
    const DOM = <div className='tips-style'>
      <div>
        <span style={style}></span>
        {width} {name}</div>
      <div style={{ marginLeft: 12 }}>{fraction}用例</div>
    </div>

    return <Tooltip title={DOM}>
      <div className='progressColor' style={{ width: width, height: 6, background: color }} />
    </Tooltip>
  }

  function cutStr(str) {
    return str
    // return str && Math.round(str.substr(0, _.size(str) - 1) * 1) + '%'
  }
}

let sortConfig = {}

const getOptions = (columns) => ({
  columns,
  autoFill: true,
  virtualized: false,
  resizable: true,
  fixedLeft: 2,
  nilText: '-',
  emptyText: '-',
})

const conditional = () => ({
  sort: sortConfig,
  onSort: (data, sort) => {
    sortConfig = sort
    return _.orderBy(data, x => x[sort.column], sort.direction)
  }
})

function TestPlanIndex(props) {
  const { location, outerViewFuncCode, factorViewSql, isAdd = true } = props
  const [detail, setDetail] = useState(false)
  const [data, setData] = useState([])
  return (
    <>
      {detail && <TestPlanDetail data={data} detail={detail} setDetail={setDetail} />}
      <TestPlan {...{ detail, setDetail, setData, location, outerViewFuncCode, factorViewSql, isAdd }} />
    </>
  )
}

export default TestPlanIndex

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '0503'

function TestPlan(props) {
  const { detail, setDetail, setData, location, outerViewFuncCode, factorViewSql, isAdd } = props
  const { planId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [params, setParams] = useState(getInitParams())
  const [currentId, setCurrentId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const viewFuncCode = useMemo(() => outerViewFuncCode || FUNC_CODE, [outerViewFuncCode])
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(viewFuncCode, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { data: planData, doFetch: planFetch } = usePost()
  const { pageSize, total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const getFactor = useGetFactor({ params, allColumns })
  const factor = useMemo(() => getFactor(), [getFactor])
  const { doFetch: getSpl } = usePost()

  const appendObj = useMemo(() => {
    return { factorViewSql }
  }, [factorViewSql])

  useEffect(() => {
    if (!_.isNil(planId)) setDetail({ planId })
  }, [planId, setDetail])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const queryData = useMemo(() => ({ viewId: currentViewId, params, factor }), [currentViewId, factor, params])

  const planRefresh = useCallback((queryParams) => {
    const { viewId, factor, params } = queryParams
    let factorPar = {}

    if (factor) {
      factorPar = _.get(JSON.parse(factor), 'conditionList')
      factorPar = _.assign({}, JSON.parse(factor), { conditionList: factorPar })
      const parameter = { viewId, factor: factorPar }
      const paramsData = _.omit(params, ['pageNum', 'pageSize'])
      getSpl('/UserSetting/getWhereCondition', { id: viewId, action: "query", menuCode: viewFuncCode, type: "01", factor: JSON.stringify(factorPar) }).then(res => {
        planFetch(LIST_URL, _.assign({}, parameter, paramsData, { factor: res }))
      })
    }
  }, [getSpl, planFetch, viewFuncCode])

  useEffect(() => {
    setData(planData)
  }, [planData, setData])

  const { setGuideList } = useContext(GuideContext)
  const { doFetch: showGuide } = useGet()

  useEffect(() => {
    showGuide('/promotion/guide?funcCode=22').then(res => {
      if (res) {
        setGuideList(x => _.concat(x, [
          {
            element: document.querySelector('.view-text-wrap'),
            intro: '视图：可以通过配置个人视图查看自己需要的信息。' +
              '支持自定义列表显示字段，配置字段条件，视图之间可以自由切换'
          }
        ]))
      }
    })
  }, [showGuide, setGuideList])
  const dateOptions = useGetDateOptions()

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, appendObj })

  const refresh = useCallback((replaceParams) => {
    refreshList(replaceParams)
    planRefresh(queryData)
    setCurrentId(_.get(gd, 'testPlanCurrentId', null))
  }, [planRefresh, queryData, refreshList])

  const idList = useGetIdList(list)
  const appendData = useGetAppendData(idList, '/test/plan/get/result')

  const [mode, setMode] = useState(null)

  const { options, dataGridList, TreeSwitch } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { setMode, setDetail, appendData },
    optionsAppendParams: conditional()
  })

  useEffect(() => {
    if (mode?.modes === 'add') sortConfig = {}
  }, [mode])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TreeSwitch />
      {isAdd && <TextIconBtn icon='tianjia' text='新增' onClick={() => setMode({ modes: 'add' })} />}
    </div>
  }, [isAdd])

  return (
    <>
      <div className={cls('fill flex-y TestPlan', { isTestPlan: detail })}>
        <ViewArea funcCode={viewFuncCode} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList: refresh, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Table {...{ error, boxLoading, extra, list, dataGridList, options, pageSize, total, pageNum, setParams, currentId }} />
      </div>

      {_.includes(['add', 'edit'], mode?.modes) && < DisplayForm refresh={refresh} close={() => setMode(null)} {...mode} />}

      {
        mode?.modes === 'delete' &&
        <DelMessage
          refresh={refresh}
          close={() => setMode(null)}
          url={`${DEL_URL}?planId=${mode?.planId}`}
        >
          确定删除当前内容？
        </DelMessage>
      }
    </>
  )

}

function Table(props) {
  const { error, boxLoading, extra, list, dataGridList, options, pageSize, total, pageNum, setParams, currentId } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const onRowClick = useCallback((item) => {
    gd.testPlanCurrentId = item?.id
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [dataGridList])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return <Box className='x-card-singlegrid' title='测试计划列表' error={error} loading={boxLoading} extra={extra} data={list}>
    <DataGrid data={dataGridList} option={options} onRowClick={onRowClick} />
    <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
      onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
  </Box>
}


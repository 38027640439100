const REQUIREMENT_PRIORITY_COLOR = {
    'T': '#A1151E',
    'A': '#F85E5E',
    'B': '#93C36B',
    'C': '#97AFD0',
}

const REQUIREMENT_STATUS_COLOR = {
    'R0': '#FFB65D',
    'R1': '#FFB65D',
    'R5': '#FFB65D',
    'R2': '#722ED1',
    'R8': '#722ED1',
    'R9': '#722ED1',
    'R6': '#165DFF',
    'S3': '#C9CDD4',
    'R3': '#0DA5AA',
    'R10': '#F7BA1E',
    'S0': '#00B42A',
    'S1': '#00B42A',
    'S7': '#165DFF',
    'R4': '#C9CDD4',
    'S4': '#00B42A',
    'S6': '#C9CDD4',
    'S2': '#C9CDD4',
    'S5': '#C9CDD4',
}

const REQUIREMENT_WORKFLOW_STATUS_COLOR = {
    '172096194976157696': '#FFB65D',
    '172096195341062144': '#FFB65D',
    '172096195366227968': '#FFB65D',
    '172096195387199488': '#165DFF',
    '172096195420753920': '#722ED1',
    '172096195445919744': '#0DA5AA',
    '172096195466891264': '#00B42A',
    '172096195483668480': '#00B42A',
    '172096195525611520': '#00B42A',
    '172096195508834304': '#C9CDD4',
    '172096195542388736': '#C9CDD4',
    '172096195559165952': '#C9CDD4',
    '172096195559165953': '#C9CDD4',
    '172096195559165954': '#C9CDD4',
    '172096195559165955': '#C9CDD4',
    '172096195559165956': '#C9CDD4',
}

const REQUIREMENT_CN_STATUS_COLOR = {
    '需求评估': '#FFB65D',
    '商务': '#165DFF',
    '需求分析': '#722ED1',
    '开发': '#0DA5AA',
    '测试': '#F7BA1E',
    '版本': '#00B42A',
    '关闭': '#C9CDD4',
}

const REQUIREMENT_ANALYSISTIMEDOUT_COLOR = {
    '0': '#93C36B',
    '1': 'red',
}

export { REQUIREMENT_PRIORITY_COLOR, REQUIREMENT_STATUS_COLOR, REQUIREMENT_CN_STATUS_COLOR, REQUIREMENT_WORKFLOW_STATUS_COLOR, REQUIREMENT_ANALYSISTIMEDOUT_COLOR }
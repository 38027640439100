import React, { useEffect } from 'react';
import { useGet } from '../../../../utils/hook';
import { Dateformat } from 'rootnet-core/format';
import CardList from './CardList';
import _ from 'lodash';
import './Modify.scss';

const options = [
    {
        title: '',
        className: 'row',
        list: [
            { label: "项目名称", bind: "projectName", tooltip: false },
        ]
    },
    {
        title: '',
        list: [
            { label: "创建人", bind: "inputUser" },
            { label: "创建时间", bind: "inputDate", convert: (v) => { return Dateformat(v) } },

            { label: "状态", bind: "completeDate" },            
            { label: "分类", bind: "completeDate" },

            { label: "项目经理", bind: "principalName" },

            { label: "客户", bind: "completeDate" },
            { label: "产品线", bind: "productLine" },

            { label: "产品", bind: "productName" },
            { label: "设计开始时间", bind: "beginDate", convert: (v) => { return Dateformat(v) } },
            { label: "设计截止时间", bind: "creatEndDate", convert: (v) => { return Dateformat(v) } },
            { label: "开发开始时间", bind: "devBeginDate", convert: (v) => { return Dateformat(v) } },
            { label: "开发截止时间", bind: "devEndDate", convert: (v) => { return Dateformat(v) } },
            { label: "测试开始时间", bind: "testBeginDate", convert: (v) => { return Dateformat(v) } },
            { label: "测试截止时间", bind: "testEndDate", convert: (v) => { return Dateformat(v) } },
            { label: "项目结束时间", bind: "completeDate", convert: (v) => { return Dateformat(v) } },
            { label: "计划投入(人日)", bind: "useDay" },
        ]
    },
    {
        title: '',
        className: 'row',
        list: [
            { label: "项目目标", bind: "completeDate", classes: 'row', tooltip: false },
        ]
    }
]

//项目 -- 基本信息
function BaseInfo(props) {
    let { data, doFetch, loading, error } = useGet();
    const { search, title } = props;
    useEffect(() => {
        const url = `develop/project/list?id=${_.get(search, 'id')}`;
        doFetch(url);
    }, [doFetch, search]);

    return  <CardList 
            title={title}
            loading={loading} 
            error={error}
            options={options}
            data={data || {}}
        />
}

export default BaseInfo;
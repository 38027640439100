import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import _ from "lodash";
import convertTableAlign from "../../common/view/convertTableAlign";
import findConvert from "../../common/view/findConvert";
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";
import ViewArea from "../../common/view/ViewArea";
import { Box } from "../../common/commonComponent";
import { DataGrid, Pagination, Messager } from "rootnet-ui";
import ConferenceRecordsAdd from "./conferenceRecordsAdd/ConferenceRecordsAdd";
import { TextIconBtn } from "../../common/TextIconBtn";
import './ConferenceRecords.scss'
import { pathSearchFor } from "../../../utils/publicFun";
import { API1 } from "../../../base/task";
import ImportApiDialog from "../../common/ImportApiDialog";
import {Popover, Tooltip} from "antd";
import ConferenceRecordsDetailDialog from "./conferenceRecordsDetail/ConferenceRecordsDetailDialog";
import { useFuncCode } from '../../common/commonMethod';
import DelMessage from '../../../components/DelMessage';
import gd from '../../../base/global';

const ENTER_DETAIL_FIELD = 'ConferenceRecord.sSubject'
const RICH_TEXT_FIELD = ['ConferenceRecord.sRecord']
const DEL_URL = '/conferencerecord/delBatch'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentInfo } = props

    const customColumns = []

    return [
        { selection: true },
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        } else {
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem) {
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if (fieldItem.fieldId === 'sAddPerson') o.sAddPerson = v
        if (_.includes(RICH_TEXT_FIELD, tableField)) {
            return <Tooltip title={<div className='rich-text-value-in-table' dangerouslySetInnerHTML={{ __html: showValue?.replace(/\n/g, '<div/>') }} />}>
                <div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div>
            </Tooltip>
        }
        if (tableField === ENTER_DETAIL_FIELD) {
            return <div className={'common-display-field enter-detail-field-wrap flex center-y'} onClick={() => {
                setCurrentInfo({
                    cid: _.get(o, 'id'),
                    mode: 'detail'
                })
            }}>
                <div className="enter-detail-field">
                    {showValue || '-'}
                </div>
            </div>
        }

        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 2,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

function getImportColumns(props) {
    return [
        { header: '主题', bind: 'sSubject', width: 120, tooltip: true },
        { header: '会议日期', bind: 'dOccurTime', width: 120, tooltip: true },
        { header: '开始时间', bind: 'dOccurTimeBegin', width: 120, tooltip: true },
        { header: '结束时间', bind: 'dOccurTimeEnd', width: 120, tooltip: true },
        { header: '会议类型', bind: 'sConferenceType', width: 120, tooltip: true },
        { header: '客户', bind: 'scustomer', width: 120, tooltip: true },
        { header: '会议地址', bind: 'sOccurPlace', width: 120, tooltip: true },
        { header: '我方参与人', bind: 'sOurPerformer', width: 120, tooltip: true },
        { header: '对方参与人', bind: 'sCustomerPerformer', width: 120, tooltip: true },
        { header: '会议主持', bind: 'sCaption', width: 120, tooltip: true },
        { header: '议题', bind: 'topic', width: 120, tooltip: true },
        { header: '会议内容', bind: 'sRecord', width: 120, tooltip: true },
        { header: '【跟进事项】主题', bind: 'subject', width: 150, tooltip: true },
        { header: '【跟进事项】负责人', bind: 'principal', width: 150, tooltip: true },
        { header: '【跟进事项】时间要求', bind: 'requireDate', width: 150, tooltip: true },
        { header: '【跟进事项】参与人', bind: 'otherPerson', width: 150, tooltip: true },
        { header: '【跟进事项】优先级', bind: 'priority', width: 150, tooltip: true },
        { header: '【跟进事项】详细描述', bind: 'note', width: 150, tooltip: true },
    ]
}

const FUNC_CODE = '2401'
const DEL_CODE = '2402'

function ConferenceRecords(props) {
    const { customer: customerCS = null, isCS, cleanAll, initMode = null } = window.external
    const { location } = props
    const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [isDelFuncCode] = useFuncCode([DEL_CODE])
    const replaceAllParams = useMemo(() => {
        if (!!customerCS) return ({ 'ConferenceRecord.scustomer': customerCS })
        return null
    }, [customerCS])
    const initInfo = useMemo(() => {
        if (initMode === 'add') return { mode: 'add' }
        if (initId) return { cid: initId, mode: 'detail' }
        return null
    }, [initMode, initId])
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [currentInfo, setCurrentInfo] = useState(initInfo)
    const [sortConfig, setSortConfig] = useState()
    const [focusId, setFocusId] = useState()
    const [factor, setFactor] = useState()
    const [showImport, setShowImport] = useState(false)
    const [selectId, setSelectId] = useState([])
    const [showDownloadPop, setShowDownloadPop] = useState(false)

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, searchUrl: '/conferencerecord/list', getFactor: setFactor })

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const dateOptions = useGetDateOptions()

    const delFun = useCallback(() => {
        if (_.isEmpty(selectId)) return Messager.show('请选择会议后再进行删除', { icon: 'error' })
        if (isDelFuncCode || _.every(selectId, o => o.sAddPerson === gd.User.operator_id)) return setCurrentInfo({ mode: 'delete' })
        else Messager.show('只可删除本人创建的会议纪要', { icon: 'error' })
    }, [selectId, isDelFuncCode])

    const { options, dataGridList, isTree } = useGetTreeList({
        fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: { setCurrentInfo },
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                return _.orderBy(data, x => x[sort.column], sort.direction)
            }
        },
    })

    const exportViewList = useCallback(() => {
        const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
        const postParams = {
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: FUNC_CODE,
            fieldList: _.map(notCustomList, 'columnId'),
            fieldNameList: _.map(notCustomList, 'fieldName'),
            factor: factor
        }
        API1.downloadPost('/conferencerecord/export', postParams)
    }, [currentViewId, fieldList, factor])

    const exportSelect = useCallback(()=>{
        if (_.isEmpty(selectId)) return Messager.show('请选择会议后再进行导出')
        API1.downloadPost('/conferencerecord/downLoad', _.map(selectId,'id'))
    },[selectId])

    const DownloadPopContent = useCallback(()=>{
        return <div className={'flex-y conference-export-wrap'}>
            <div className="conference-export-item flex center-y" onClick={()=>{
                exportViewList()
                setShowDownloadPop(false)
            }}>
                导出列表
            </div>
            <div className="conference-export-item flex center-y" onClick={()=>{
                exportSelect()
                setShowDownloadPop(false)
            }}>
                导出跟进事项
            </div>
        </div>
    },[exportViewList, exportSelect])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            {
                !isCS &&
                <TextIconBtn icon='daoru' text='导入' onClick={() => setShowImport(true)} />
            }
            {
                !isCS &&
                <Popover content={<DownloadPopContent/>} trigger="click" placement="bottomRight" open={showDownloadPop} onOpenChange={setShowDownloadPop}>
                    <TextIconBtn icon='xiazai2' text='导出' />
                </Popover>
            }
            <TextIconBtn icon='tianjia' text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
            <TextIconBtn icon='shanchu' text='删除' onClick={delFun} />
        </div>
    }, [delFun, isCS, showDownloadPop])

    const showChildList = useMemo(() => {
        return _.filter(dataGridList, x => !_.get(x, '_children')) || []
    }, [dataGridList])

    const showList = useMemo(() => {
        let showList = showChildList || []
        if (!_.isNil(sortConfig) && !isTree) {
            showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, isTree, showChildList])

    const currentIndex = useMemo(() => {
        const currentId = _.get(currentInfo, 'cid')
        if (_.isNil(currentId)) return 0
        return _.findIndex(showList, x => _.get(x, 'id') === currentId)
    }, [currentInfo, showList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentInfo(old => {
            const cloneObj = { ...old }
            const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
            cloneObj['cid'] = newId
            setFocusId(newId)
            return cloneObj
        })
    }, [showList, currentIndex])

    return (
        <div className={'conference-records-wrap fill flex-y'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} resetSearch={cleanAll} loading={boxLoading}
                {...{
                    getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize, replaceAllParams
                }} />
            <Box title={'会议纪要'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
                <Table {...{ options, showList, dataGridList, focusId, setSelectId }} />
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, { pageNum, pageSize }))
                    }} />
            </Box>
            {
                _.get(currentInfo, 'mode') === 'add' &&
                <ConferenceRecordsAdd close={() => setCurrentInfo(null)} {...{ refreshList }} />
            }
            {
                _.get(currentInfo, 'mode') === 'detail' &&
                <ConferenceRecordsDetailDialog refreshViewList={refreshList} close={() => setCurrentInfo(null)} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex }} />
            }
            {
                _.get(currentInfo, 'mode') === 'delete' &&
                <DelMessage
                    header={<span className='DelMessage-lz'>删除会议<span>（已选{_.size(selectId)}个会议）</span></span>}
                    method='post'
                    refresh={() => { refreshList(); setSelectId([]) }}
                    close={() => setCurrentInfo(null)}
                    params={_.map(selectId, 'id')}
                    url={DEL_URL}
                >确定删除会议吗？</DelMessage>
            }
            {
                showImport &&
                <ImportApiDialog close={() => setShowImport(false)} template={`/conferencerecord/field/download`}
                    defaultOptions={getImportColumns()} refresh={refreshList} importUrl={'/conferencerecord/import'} title={'会议纪要'}
                    abnormal={''} showExport={false} exportFieldname={'ImplementTaskVos'} />
            }
        </div>
    )
}

function Table(props) {
    const { options, showList, dataGridList, focusId, setSelectId } = props
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const onRowClick = useCallback((item) => {
        _.forEach(dataGridList, o => {
            return o._rowClass = item?.id === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    }, [dataGridList])

    useEffect(() => {
        if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    return <DataGrid onSelectionChange={setSelectId} option={options} data={showList} onRowClick={onRowClick} className='data-grid' />
}
export default ConferenceRecords;
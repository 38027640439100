import React from 'react';
import classnames from 'classnames';
import ProtoTypes from 'prop-types';
import "./RadioGroup.scss";


class Radio extends React.Component {
    render() {
        const { children, name, selectedValue, value, disabled } = this.props
        return <label>
            <input type='radio' disabled={disabled} name={name} readOnly checked={selectedValue === value} value={value} />
            {children}
        </label>
    }
}

class RadioGroup extends React.Component {
    static defaultProps = {
        Component: "div"
    }
    render() {
        const cn = classnames('x', 'radiogroup', { 'disabled': this.props.disabled })
        const { Component, name, selectedValue, ...rest } = this.props;
        const children = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { name, selectedValue, onChange: this.onChange });
        })
        return (<Component className={cn} {...rest} style={this.props.style}>{children}</Component>)
    }
}

Radio.propTypes = {
    onChange: ProtoTypes.func
}

RadioGroup.propTypes = {
    name: ProtoTypes.string,
    onChange: ProtoTypes.func,
    children: ProtoTypes.node.isRequired,
    Component: ProtoTypes.oneOfType([
        ProtoTypes.string,
        ProtoTypes.func,
        ProtoTypes.object
    ])
}

//使用方法
//     < RadioGroup name = 'single' selectedValue = { this.state.selectedValue } onChange = { this.handleChange } >
//         <Radio value="PC">单基金报告</Radio>
//         <Radio value="APP">多基金报告</Radio>
// </RadioGroup >

export { RadioGroup, Radio }



import React, { useCallback } from 'react'
import { Dialog } from 'rootnet-ui'

export default function AcceptDialog(props) {
  const { size = 0, close, confirm, list } = props

  const handClick = useCallback(() => {
    confirm({ list, params: { state: '1' } })
  }, [list, confirm])

  return (
    <Dialog
      header={'接受'}
      cancel={close}
      confirm={handClick}
      style={{
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 350,
      }}
    >
      <div>请确定批量接受{size}条记录!</div>
    </Dialog>
  )
}

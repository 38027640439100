import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { FormInput } from 'rootnet-edit'
import { Dateformat, N2 } from 'rootnet-core/format'
import { pathParamsFor } from '../../../../utils/publicFun'
import DetailsGoBack from "./../../../../project_share/components/DetailsGoBack"
import { useGet } from '../../../../utils/hook'
import { DataGrid, Pagination } from 'rootnet-ui'
import AuthorizeDialog from './AuthorizeDialog'
import { Box } from '../../../common/commonComponent'
import { strParams } from '../../../../utils/publicFun'
import "./Detail.scss"

// const urls = ['/common/globalconst?globalConst=ProjectStatus', '/common/globalconst?globalConst=PlanStatus', '/common/globalconst?globalConst=PresalesProjectStatus']

function Operation(props) {
  const { data, setIsShow, setCurrobj } = props
  return <div className="operation">
    <span onClick={() => { setCurrobj(data); setIsShow(true) }}>授权管理</span>
  </div>;
}

const optionsTaskFor = (setIsShow, setCurrobj) => {
  return (
    [
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 50 },
      { header: '任务主题', bind: 'taskName', align: 'left', tooltip: true, width: 150 },
      // { header: '任务负责人', bind: 'principalName', align: 'left', width: 80, tooltip: true, },
      { header: '计划工时(人日)', bind: 'planTime', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '计划成本(元)', bind: 'planCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '实际工时(人日)', bind: 'useTime', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '实际成本(元)', bind: 'useCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '操作', convert: function _(e, o) { return <Operation data={o} setIsShow={setIsShow} setCurrobj={setCurrobj} /> }, align: 'center', width: 80 },
    ]
  )
}
const optionsPlanFor = () => {
  return (
    [
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 50 },
      { header: '计划工时(人日)', bind: 'planTime', align: 'right', convert: v => N2(v / 8 / 60), isTotal: true },
      { header: '计划成本(元)', bind: 'planCost', align: 'right', width: 150, convert: N2, isTotal: true },
      { header: '成本中心', bind: 'costcenter', align: 'left', width: 150 },
      { header: '补贴标识', bind: 'subsidyFlag', align: 'left', width: 150 },
      { header: '录入人', bind: 'inputUserName', align: 'left', width: 150 },
      { header: '录入时间', bind: 'inputDate', align: 'left', width: 155, convert: v => Dateformat(v, 'hr'), tooltip: true, },
    ]
  )
}
const optionsFull = () => {
  return (
    [
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 70 },
      { header: '员工', bind: 'userName', align: 'left', },
      { header: '工时分类', bind: 'jobTypeName', align: 'left', width: 140 },
      { header: '工作日期', bind: 'workDate', align: 'left', width: 140, convert: (v) => Dateformat(v), tooltip: true, },
      { header: '计划/授权用时(人日)', bind: 'planTime', align: 'right', width: 140, convert: v => N2(v / 8 / 60), isTotal: true },
      { header: '实际用时(人日)', bind: 'useTime', align: 'right', width: 140, convert: v => N2(v / 8 / 60), isTotal: true },
      { header: '实际工时(人日)', bind: 'useWorkTime', align: 'right', width: 140, convert: v => N2(v / 8 / 60), isTotal: true },
      { header: '实际成本(元)', bind: 'useCost', align: 'right', width: 140, convert: N2, isTotal: true },
    ]
  )
}

const gridOptsFor = (options) => ({
  virtualized: false,
  resizable: true,
  fixedLeft: 2,
  fixedRight: 1,
  columns: options,
  autoFill: true,
  nilText: '-',
  emptyText: '-',
})

const defaultPage = {
  pageNum: 1,
  pageSize: 10,
}

function Detail(props) {
  const { match } = props;
  const pathParams = useMemo(() => pathParamsFor(_.get(match, 'params.id')), [match]);
  const { data, loading, error, doFetch } = useGet();
  const { data: planData, loading: planLoading, error: planError, doFetch: planDoFetch } = useGet();
  const { data: fullData, loading: FullLoading, error: fullError, doFetch: fullDoFetch } = useGet();

  const [isShow, setIsShow] = useState(false)
  const [currobj, setCurrobj] = useState({})
  const [plannedPage, setPlannedPage] = useState(defaultPage)
  const [actualPage, setActualPage] = useState(defaultPage)

  const { planTime, planCost, useTime, useCost, principalName, id, authorizedTime, item_projectName, uuid, item_principal } = pathParams

  useEffect(() => {
    const url = '/presales/presalestask'
    doFetch(`${url}?projectId=${id}`)//任务信息-授权
    planDoFetch(`/common/projectplantime?projectId=${id}&projectType=PRESALES&${strParams(plannedPage)}`)//计划工时
    fullDoFetch(`/common/projectusertime?projectId=${id}&${strParams({ ...actualPage, projectType: 'PRESALES' })}`)//实际工时
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFetch, planDoFetch, fullDoFetch, id, uuid])

  const optionTask = useMemo(() => gridOptsFor(optionsTaskFor(setIsShow, setCurrobj), data), [data]);
  const optionPlan = useMemo(() => gridOptsFor(optionsPlanFor(), planData), [planData]);
  const optionFull = useMemo(() => gridOptsFor(optionsFull(), fullData), [fullData]);
  const extra = (mode, data) => {//value={mode === 'task' ? taskVal : fullVal}
    return <FormInput
      maxLength={20}
      placeholder={mode === 'task' ? '请输入任务主题' : '请输入员工'}
      disabled={!(!_.isNil(data) && (data.length !== 0))}
      onChange={mode === 'task' ? taskChange : fullChange}
    />
  }
  return (<div className='projectcostdetail'>
    {!uuid && <DetailsGoBack onClick={goBack} />}
    <Box style={{ marginTop: 0 }} title='成本信息' data={[pathParams]} extra={`${setDefaultText(item_projectName)}-${setDefaultText(principalName)}`} >
      <div className='detail-top'>
        <div className='detail-top-child'>
          <dl>
            <dt >{N2(planTime)}</dt>
            <dd>计划工时（人日）</dd>
          </dl>
          <dl>
            <dt>￥{N2(planCost)}</dt>
            <dd>计划成本</dd>
          </dl>
        </div>
        <div className='detail-top-child'>
          <dl>
            <dt>{N2(useTime)}</dt>
            <dd>实际工时（人日）</dd>
          </dl>
          <dl>
            <dt>￥{N2(useCost)}</dt>
            <dd>实际成本</dd>
          </dl>
        </div>
      </div>
    </Box>

    <Box title='任务信息' className='maxHeight' data={data} loading={loading} error={error} extra={extra('task', data)}>
      <DataGrid className='row-fill' data={_.get(data, 'preSalesTaskVos')} option={optionTask} />
    </Box>

    <Box title='计划工时' className='detail-task' data={_.get(planData, 'rows')} loading={planLoading} error={planError}>
      <DataGrid className='row-fill' data={_.get(planData, 'rows')} option={optionPlan} />
      <Pagination pageSize={plannedPage.pageSize} total={_.get(planData, 'total')} current={plannedPage.pageNum} onChange={(v, o) => page(v, o, 'Planned')} />
    </Box>

    <Box title='实际工时' className='detail-task' data={_.get(fullData, 'rows')} loading={FullLoading} error={fullError} extra={extra('full', _.get(fullData, 'rows'))}>
      <DataGrid className='row-fill' data={_.get(fullData, 'rows')} option={optionFull} />
      <Pagination pageSize={actualPage.pageSize} total={_.get(fullData, 'total')} current={actualPage.pageNum} onChange={(v, o) => page(v, o, 'Actual')} />
    </Box>

    {isShow && <AuthorizeDialog projectId={id} id={_.get(currobj, 'id')} name={_.get(currobj, `${'taskName'}`)} authorizedTime={authorizedTime} planTime={planTime} setIsShow={setIsShow} principal={item_principal} />}
  </div>
  )
  function goBack() {
    props.history.push({
      pathname: '/presalesprojectcost',
      pathParams: {
        ..._.pick(pathParams, ['customer', 'projectName', 'principal']),
        projectStatusList: _.get(pathParams, 'projectStatusList', '').split(',')
      }
    })
  }

  function taskChange(val) {//taskName
    const name = 'taskName'
    const url = '/presales/presalestask'
    if (val) {
      doFetch(`${url}?projectId=${id}&${strParams({ [name]: val })}`)
      // setTaskPage({ ...taskPage, [name]: val, pageNum: 1 })
    } else {
      doFetch(`${url}?projectId=${id}`)
      // setTaskPage({ ..._.pick(taskPage, ['pageSize']), pageNum: 1 })
    }
  }
  function fullChange(val) {
    if (val) {
      fullDoFetch(`/common/projectusertime?projectId=${id}&projectType=PRESALES&${strParams({ ...actualPage, userName: val, pageNum: 1 })}`)
      setActualPage({ ...actualPage, userName: val, pageNum: 1 })
    } else {
      fullDoFetch(`/common/projectusertime?projectId=${id}&projectType=PRESALES&${strParams({ ..._.pick(actualPage, ['pageSize']), pageNum: 1 })}`)
      setActualPage({ ..._.pick(actualPage, ['pageSize']), pageNum: 1 })
    }
  }

  function setDefaultText(str) {
    if (_.isNil(str) || str === '') return '-'
    return str;
  }
  function page(pageNum, pageSize, content) {
    if (content === 'Planned') {
      setPlannedPage({ pageNum, pageSize })
      planDoFetch(`/common/projectplantime?projectId=${id}&pageNum=${pageNum}&pageSize=${pageSize}&projectType=PRESALES`);//计划工时
    } else if (content === 'Actual') {
      fullDoFetch(`/common/projectusertime?projectId=${id}&pageNum=${pageNum}&pageSize=${pageSize}&projectType=PRESALES${_.get(actualPage, 'userName') ? '&userName=' + _.get(actualPage, 'userName') : ''}`);//实际工时
      setActualPage({ ...actualPage, pageNum, pageSize })
    }
    //   const name = 'taskName'
    //   const url = '/presales/presalestask'
    //   doFetch(`${url}?projectId=${id}&pageNum=${pageNum}&pageSize=${pageSize}${_.get(taskPage, name) ? '&' + name + '=' + _.get(taskPage, name) : ''}`)
    //   setTaskPage({ ...taskPage, pageNum, pageSize })
    //   return;
  }

}
export default Detail;


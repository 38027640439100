import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ImpReportWorkTimeDialog.scss'
import { Modal } from 'antd';
import {DatePicker, Form, FormInput, TextArea} from 'rootnet-edit';
import {useGet, usePost} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import {toDate,dateFormat} from 'rootnet-core/dateFormat';
import {Messager} from 'rootnet-ui'

const {str8ToDate} = toDate;

const initFormData = {
    workDate: dateFormat('YYYYMMDD',new Date()),
    auditUseTime: null,
    remark: null,
}

function ImpReportWorkTimeDialog(props) {
    const {taskId, projectId, showReportWorkTime, setShowReportWorkTime, taskTimeInfo, taskName} = props
    const [formData, setFormData] = useState(initFormData)
    const [error, setError] = useState()
    const {data:cutOffDateListRes} = useGet('/worktime/cutOffDate/query?cutOffDateFlag=Y')
    const {doFetch: addWorkTime} = usePost()

    const [lastCutOffDate,limitCutOffDate, beforeCutOffDate] = useMemo(()=>{
        if(_.isEmpty(cutOffDateListRes)) return []
        const now = dateFormat('YYYY-MM-DD',new Date())
        const cutOffDateList = _.sortBy(_.map(cutOffDateListRes, x => x.jobdate))
        const lastCutOffDate = _.find(_.reverse([...cutOffDateList]), x => x < now)
        const beforeCutOffDate = _.find(_.reverse([...cutOffDateList]), x => x < lastCutOffDate)
        const limitCutOffDate = _.get(_.find(cutOffDateListRes, x => x.jobdate === lastCutOffDate),'cutoffday')
        return [lastCutOffDate,limitCutOffDate,beforeCutOffDate]
    },[cutOffDateListRes])

    useEffect(()=>{
        let auditUseTime = _.toNumber(_.get(formData,'auditUseTime'))
        if(_.isNil(_.get(taskTimeInfo,'remainUseTime')) || _.toNumber(_.get(taskTimeInfo,'remainUseTime')) === 0){
            setError(x=>_.assign({},x,{'auditUseTime': '无可填工时'}))
            return
        }
        if(_.isNil(_.get(formData,'auditUseTime'))){
            setError(x=>_.assign({},x,{'auditUseTime': '必填项！'}))
            return
        }
        if(auditUseTime<=0){
            setError(x=>_.assign({},x,{'auditUseTime': '实际用时需大于0'}))
            return
        }
        if(auditUseTime>24){
            setError(x=>_.assign({},x,{'auditUseTime': '不能超过24小时'}))
            return
        }
        if(!_.isNil(auditUseTime)){
            const remainUseTime = _.toNumber(taskTimeInfo.remainUseTime)
            if(auditUseTime > remainUseTime/60){
                setError(x=>_.assign({},x,
                    {'auditUseTime': `不能超过剩余授权用时【${(remainUseTime/60).toFixed(1)}小时】
                    ，请联系项目经理${taskTimeInfo.projectManager}进行授权`}))
                return
            }
        }
        setError(x=>_.assign({},x,{'auditUseTime': null}))
    },[formData,taskTimeInfo])

    const remainTime = useMemo(()=>{
        return  _.toNumber((_.get(taskTimeInfo,'remainUseTime'))/ 60)
    },[taskTimeInfo])

    const remainTimeText = useMemo(()=>{
        if(_.isNil(taskTimeInfo)) return '无'
        return parseFloat(remainTime.toFixed(1)) + '小时'
    },[taskTimeInfo, remainTime])

    const confirmBtnDisabled = useMemo(()=>{
        return _.some(_.values(error), x => !_.isNil(x))
    },[error])
    
    const checkDate = useCallback((date)=>{
        const now = new Date()
        if(date > now) return false
        if(date <= new Date(beforeCutOffDate)) return false
        if(date <= new Date(lastCutOffDate) && now > new Date(limitCutOffDate)) return false
    },[beforeCutOffDate,lastCutOffDate,limitCutOffDate])

    const submit = useCallback(()=>{
        const postParams = {
            ...formData,
            actionType: "ADD",
            auditUseTime: _.toNumber(_.get(formData, 'auditUseTime')) * 60,
            content: "实施工作-"+taskName,
            jobType: 2001,
            projectType: "SERVICE",
            status: "approve",
            taskId: taskId,
            projectId: projectId
        }
        addWorkTime('/worktime/edit',[postParams]).then(()=>{
            Messager.show('填报工时成功', { icon: 'success' })
            setShowReportWorkTime(false)
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[formData, taskId, projectId, addWorkTime, setShowReportWorkTime, taskName])

    const close = useCallback(()=>{
        setFormData(initFormData)
        setShowReportWorkTime(false)
    },[setShowReportWorkTime])

    return <Modal className={'imp-report-work-time-dialog'} visible={showReportWorkTime} okButtonProps={{disabled: confirmBtnDisabled}}
                  onOk={submit} onCancel={close}>
        <div className={'imp-report-work-time-wrap'}>
            <Form value={formData} onChange={setFormData} error={error} onError={setError}>
                <FormInput label={'填报日期'} bind='workDate' horizontal componentWidth={180} labelWidth={100} required component={DatePicker}
                           bindInConvert={str8ToDate} bindOutConvert={v => dateFormat('YYYYMMDD',v)} dataRender={checkDate}/>
                <FormInput label={'实际用时'} bind='auditUseTime' suffix={'小时'} horizontal componentWidth={180} labelWidth={100} min={0.1} type='number' digit={1} required/>
                <span className={'remain-time-text'}>
                    剩余可填：{remainTimeText}
                </span>
                <FormInput label={'工作内容'} bind={'remark'} placeholder='请输入工作内容说明...' horizontal componentWidth={320} labelWidth={100} component={TextArea}/>
            </Form>
        </div>
    </Modal>
}

export default ImpReportWorkTimeDialog;
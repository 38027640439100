import React, {Fragment} from 'react';
import fp from "lodash/fp";
import {GetTime, isNil} from 'rootnet-core/format';
import {hasData} from 'rootnet-core/system';
import cls from 'clsx';
import {TextInput, FormInput, Select as Dropdown, DatePicker as DatePickerR,Button} from 'rootnet-ui';
import { Fn, unitReg, unitFor, Obj } from "../../utils/publicFun";
import {Tooltip} from "../common/Tooltip";
import './Details.scss';
import {Icon} from "../../components";

export const Input = props => <TextInput placeholder='请输入' {...props}/>;
const HFormInput = (props) => <FormInput {...props} horizontal/>
Input.displayName = 'Input';
export const Select = props => <Dropdown {...props}/>;
Select.displayName = 'Select';
export const DatePicker = props => <DatePickerR {...props}/>;
DatePicker.displayName = 'DatePicker';

export function ShowItem(props) {
    let {label, bind, convert,format,data={}, style, className, visible,options,component,textStyle,titStyle,align,text,type,suffix,tooltip} = props;

    data=Obj(data);

    if(isNil(text)){
        text = format ? format(data[bind], data) : convert(data[bind], data);
        if(fp.get('displayName',component)==='Select' && hasData(options)) text = fp.get('text',options.find(e=>e.value===text));
        if(suffix&&suffix!=='%') text = ''.concat(text,suffix);
    }

    let maxCount = tooltip===false ? (Infinity) : (type==='number' ? 20 : 15);
    return <div  className='details'>
        {visible && <span className={cls('details_item', className)} style={{textAlign:align,...style}}>
        <span className="details_item_tit" style={titStyle}>{addColon(label)}</span>
        <span className='details_item_text' style={textStyle}>
            {isNil(text) ? '-' : <Tooltip value={text} maxCount={maxCount}/>}
        </span>
     </span>}
    </div>;
}
ShowItem.defaultProps = {
    convert: x => x,
    visible: true,
    align:'left',
    textStyle:{},
    titStyle:{},
    tooltip:true
};

export const ShowTableHeader = (props)=>{
    const {header,textStyle={},...rest} = props;
    return <ShowItem {...rest} text={header} textStyle={fp.assign(textStyle,{color:'#738299'})}/>;
};

export function EditItem(props) {
    const {label,bind, component, convert, data, options, style, unit, suffix, visible,onChange,onTextChange,required,validate,ref,min,max,...rest} = props;
    const text = convert(data[bind], data);
    const params = {
        type: component.displayName,
        value: text,
        options,
        unit
    };

    return <div className='details'>
        {
            visible && <div style={style} className='details_item'>
                <HFormInput value={valueFor(params)} style={{height: '30px', ...style}} suffix={suffix || unitForWithType(params)}
                            label={addColon(label)}
                            options={Fn(options,()=>options)(data)}
                            data={data}
                            required={Fn(required,()=>required)(data)}
                            clear={!Fn(required,()=>required)(data)}
                            min= {Fn(min,()=>min)(data)}
                            max= {Fn(max,()=>max)(data)}
                            ref={ref}
                            validate={(...x)=>Fn(validate,()=>null)(data,...x)}
                            onChange={(...x)=>Fn(onChange)(bind,...x)}
                            onTextChange={(...x)=>Fn(onTextChange)(bind,...x)}
                            name={bind}
                            component={component} {...rest} />
            </div>
        }
    </div>;
}
EditItem.defaultProps = {
    convert: x => x,
    data: {},
    style: {},
    visible: true,
};

function addColon(v) {
    return isNil(v) ? '' : ''.concat(v,'：');
}

function unitForWithType(params) {
    const {type, value, unit} = params;
    if (!unit) return;
    if (['UnitInput','Input'].includes(type)) return unitFor(value);
}

function valueFor(params) {
    const {type, value, unit,options} = params;
    if (type === 'Input') return inputValueFor(value, unit);
    if (type === 'DatePicker') return dateValueFor(value);
    if (type === 'Select') return selectValueFor(value,options);
    if (type === 'UnitInput') return unitInputValueFor(value, unit);
}

export function inputValueFor(v, u) {
    const value = unitInputValueFor(v, u);
    return value === '-' ? null : value;
}

function unitInputValueFor(v, u) {
    if (!u) return v;
    if (!unitReg.test(v)) return v;
    return unitReg.exec(v)[1];
}

export function dateValueFor(value) {
    const reg = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
    if (reg.test(value)) return new Date(value);
}

function selectValueFor(value,options) {
    return options && value;
}

const Btn = ({style,...rest})=><Button style={fp.assign({width: 77, height: 32},style)} {...rest}/>;

export function DetailsEditBtn(props) {
    const {mode,setMode,commit,cancel,edit,commitText} = props;
    return <Fragment>
        {['edit','add'].includes(mode) && <div className='flex'>
            <Btn primary onClick={commit}>{commitText}</Btn>
            <Btn style={{marginRight:12}} text onClick={handleCancel}>取消</Btn>
        </div>}
        {mode === 'show' && <Btn primary style={{marginRight:12}} onClick={handleEdit}>编辑</Btn>}
    </Fragment>;

    function handleCancel() {
        setMode('show');
        cancel();
    }
    function handleEdit() {
        setMode('edit');
        edit();
    }
}
DetailsEditBtn.defaultProps = {
    setMode:()=>{},
    commitText:'提交',
    commit:()=>{},
    cancel:()=>{},
    edit:()=>{}
};

export function DetailsGoBack(props) {
    const {text='返回',goBack} = props;
    return <div style={{height: 56, lineHeight: '56px',marginRight:'auto'}}>
        <Icon name='erjititlefanhui' style={{margin: '0 24px 0 12px'}} onClick={goBack}/>
        <span style={{fontWeight:700}}>{text.includes('undefined')?'':text}</span>
    </div>;
}

export const DetailsItem = (props)=>{
    const {mode,component,isAdd,...rest} = props;
    if(isAdd!==true && mode==='edit' && component) return <EditItem component={component} {...rest}/>;
    return <ShowItem component={component} {...rest}/>;
};

//公用处理方法
export const curSetSelectValue = dispatch => (type,e, o) => dispatch({ type, value: fp.getOr(null,'value',o),data:o });
export const curSetInputValue = dispatch => (type,value)=> dispatch({ type, value });
export const curSetDateValue = dispatch => (type,d) => dispatch({ type, value: GetTime(d) });
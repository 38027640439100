import React, {useEffect, useMemo, useState} from 'react';
import { Card, DataGrid, Messager} from 'rootnet-ui'
import './VersionUpgradeQuery.scss'
import _ from 'lodash'
import {Box} from "../../common/commonComponent";
import {TextIconBtn} from "../../common/TextIconBtn";
import Options from "./Options";
import {strParams} from "../../../utils/publicFun";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import ExportCsvDialog from "../../common/ExportCsvDialog";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import { Switch, Tag, Tooltip } from 'antd';

const isOverOptions = [
    {
        text: '已修复',
        value: 'Y'
    },
    {
        text: '未修复',
        value: 'N'
    },
]

function getColumns(props){
    const {effectGradeOptions, issuesStatusOptions} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: 'issueID', bind: 'issueid',width: 150, sortable: true, tooltip: true},
        {header: '标题', bind: 'shortdesc',width: 400, sortable: true, tooltip: true},
        {header: '严重程度', bind: 'effectgrade',width: 100, sortable: true, tooltip: true, convert: v => convertOptions(v, effectGradeOptions), csvConvert: true},
        {header: '状态', bind: 'issuestatus',width: 100, sortable: true, tooltip: true, convert: v => convertOptions(v, issuesStatusOptions), csvConvert: true},
        {header: '修复问题研发任务', bind: 'tracerid',width: 180, sortable: true, tooltip: true},
        {header: '修复版本', bind: 'fixVersionList',width: 180, sortable: true, tooltip: true, convert: convertTooltipsVersion},
        {header: '目标版本是否修复', bind: 'isOver',width: 140, sortable: true, align: 'center', tooltip: true, convert: convertIsOver, csvConvert: v => convertOptions(v, isOverOptions)},
        {header: '引发问题研发任务', bind: 'ssourcetracer',width: 180, sortable: true, tooltip: true},
        {header: '影响版本', bind: 'impactVersionList',width: 200, sortable: true, tooltip: true, convert: convertTooltipsVersion},
    ]

    function convertIsOver(v){
        return v ==='Y' ? <Tag color="#87d068">已修复</Tag>: <Tag color="#F3B433">未修复</Tag>
    }
}

function convertTooltipsVersion(v){
    const showValue = _.join(v, ', ')
    return <Tooltip title={showValue}>
        <span>{showValue}</span>
    </Tooltip>
}

function getOptions(cols){
    return {
        resizable: true,
        virtualized: true,
        fixedLeft: 1,
        nilText: '-',
        emptyText: '-',
        columns: cols,
    }
}

const convertUrl = [
    '/common/globalconst?globalConst=EffectGrade',
    '/common/globalconst?globalConst=ISSUESTATUS',
]

function VersionUpgradeQuery(props) {
    const [params, setParams] = useState()
    const {data, doFetch, error} = useGet()
    const [loading, setLoading] = useState(false)
    const {data: convertRes} = useGet(convertUrl)
    const [showExportDialog, setShowExportDialog] = useState(false)
    const [filterIsOver, setFilterIsOver] = useState(true)

    const showError = useMemo(()=>{
        return _.includes([1043, 1044, 1045, 1046, 1047, 1048, 1049, 1050, 1051],error?._code) ? null: error
    },[error])

    const [effectGradeOptions, issuesStatusOptions] = useMemo(()=>{
        if(_.isNil(convertRes)) return []
        return _.map(convertRes, x => convertGlobalConstOptions(x))
    },[convertRes])

    const {issueInfoList, upgradePath = [], issueInfoTotal, targetVersionTotalCoverage = 0, totalNum = null} = useMemo(() => data || {},[data])

    const showList = useMemo(()=>{
        if(filterIsOver){
            return issueInfoList
        }else{
            return _.filter(issueInfoList, x => x.isOver !== 'Y')
        }
    },[issueInfoList, filterIsOver])

    const [totalNumGroup, percentGroup] = useMemo(()=>{
        if(_.isEmpty(issueInfoTotal)) return []
        const typeList = ['01', '02', '03', '04', '05']
        return [
            _.map(typeList, x => noData(x) ? 0 : getData(x).totalNum),
            _.map(typeList, x => noData(x) ? '0.00%' : getData(x).targetVersionCoverage),
        ]

        function getData(v){
            return _.find(issueInfoTotal, x => x['versionType'] === v)
        }

        function noData(v){
            return _.isNil(getData(v))
        }
    },[issueInfoTotal])

    useEffect(()=>{
        if(_.isNil(params)) return
        if(_.isNil(params.releaseID) && _.isNil(params.releaseIDTarget)) return Messager.show('请选择要查询的版本')
        const searchParams = _.isNil(params.releaseID) ? {
            ...params,
            releaseID: params.releaseIDTarget,
            releaseIDTarget: null
            }: params
        setLoading(true)
        doFetch('/version/getVersionInfo?'+strParams(searchParams))
            .then(()=>setLoading(false))
            .catch(err => {
                Messager.show(err.Message, { icon: 'error' });
                setLoading(false)
            })
    },[doFetch, params])

    const option = useMemo(()=>{
        return getOptions(getColumns({effectGradeOptions, issuesStatusOptions}))
    },[effectGradeOptions, issuesStatusOptions])

    const extra = useMemo(()=>{
        return <div className={'flex center-y'}>
            <Switch checkedChildren="所有的" unCheckedChildren="未修复" defaultChecked onChange={setFilterIsOver}/>
            <TextIconBtn text='导出' icon={'daochu'} disabled={_.isEmpty(issueInfoList)} onClick={()=>setShowExportDialog(true)}/>
        </div>
    },[issueInfoList])

    return <div className={'version-upgrade-query fill flex-y'}>
        <Options search={setParams}/>
        <div className="middle-area flex">
            <Card title={'升级路径'} loading={loading}>
                <div className="upgrade-detail card-content-wrap">
                    {
                        _.map(_.reverse(_.clone(upgradePath)), (x, i) => <span key={uniqKeyFor()}>
                            {
                                i !== 0 && <span className={'arrow'}> —> </span>
                            }
                            {x}
                        </span>)
                    }
                </div>
            </Card>
            <Card title={'issue信息汇总'} contentClass={'card-content-wrap flex-y'} loading={loading}>
                <div className="issues-collection-count flex">
                    <div className="issue-total">
                        总数：{totalNum}
                    </div>
                    <div className="issue-coverage">
                        目标版本修复覆盖率：{targetVersionTotalCoverage}
                    </div>
                </div>
                {
                    !_.isNil(totalNum) &&
                    <div className="issues-progress flex">
                        <Progress title={'致命'} color={'#990033'} count={_.get(totalNumGroup, 0)} coverage={_.get(percentGroup, 0)} sum={totalNum}/>
                        <Progress title={'严重'} color={'#EA5252'} count={_.get(totalNumGroup, 1)}  coverage={_.get(percentGroup, 1)} sum={totalNum}/>
                        <Progress title={'重要问题'} color={'#F3B433'} count={_.get(totalNumGroup, 2)} coverage={_.get(percentGroup, 2)} sum={totalNum}/>
                        <Progress title={'一般问题'} color={'#5477ff'} count={_.get(totalNumGroup, 3)} coverage={_.get(percentGroup, 3)} sum={totalNum}/>
                        <Progress title={'改善建议'} color={'#A9A9A9'} count={_.get(totalNumGroup, 4)} coverage={_.get(percentGroup, 4)} sum={totalNum}/>
                    </div>
                }
            </Card>
        </div>
        <Box className='flex-y x-card-singlegrid' title='issue列表信息' data={showList} extra={extra} loading={loading} error={showError}>
            <DataGrid className='row-fill' data={showList} option={option} />
        </Box>
        {
            showExportDialog &&
            <ExportCsvDialog close={()=>setShowExportDialog(false)} option={option} title={`${params.releaseID} -- ${params.releaseIDTarget} issue信息汇总`} list={showList}/>
        }
    </div>
}

function Progress(props){
    const {title, color, count, coverage, sum = 100} = props

    const colorDotStyle = {
        width: 8,
        height: 8,
        marginRight: 8,
        borderRadius: 4,
        background: color,
        display: 'inline-block',
    }

    const TooltipDom = <div className='version-upgrade-query-progress-tooltip-dom'>
        <div className='title-line flex center-y'>
            <div className={'color-dot'} style={colorDotStyle}/>
            {title}
        </div>
        <div>总数： {count}</div>
        <div>目标版本修复覆盖率： {coverage}</div>
    </div>

    return <Tooltip title={TooltipDom} placement={'bottom'}>
        {
            count !== 0 &&
            <div className='progress-bar' style={{width: `${count*100/sum}%`, height: 16, background: color}}/>
        }
    </Tooltip>
}

export default VersionUpgradeQuery;
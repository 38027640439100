import _ from "lodash";
import {uniqKeyFor} from "../../../../project_share/utils/utils";

function getChildList(data,key,childrenName, level) {
    return _.reduce(data, (acc, x)=> {
        let childrenList = _.get(x, childrenName)
        childrenList = _.map(childrenList, item =>({...item,parent: _.get(x,key),key: uniqKeyFor(),level:level}) )
        if(_.isEmpty(childrenList)) childrenList = [null]
        return _.concat(acc, childrenList)
    },[])
}

export function createNode(data,systemName){
    const projectList = _.map(data, x => ({
        ...x,
        key: uniqKeyFor(),
        level: 1,
        parent: 'root'
    }))
    const moduleList = getChildList(projectList,'key','productAssociationSystemDos',2)
    const submoduleList = getChildList(moduleList,'key','productAssociationSystemSonDos',3)
    return getNodeData(submoduleList,moduleList,projectList,data,systemName)
}

const itemStyle = {
    color: '#fff'
}

function getNodeData(submoduleList,moduleList,projectList,data,systemName){
    let handledList = pushSubmoduleNode(submoduleList)
    handledList = pushModuleNode(handledList,moduleList)
    handledList = pushProjectNode(handledList,projectList)
    handledList = pushRootNode(handledList,data,systemName)
    return handledList
}

function pushSubmoduleNode(submoduleList){
    let handledList = []
    _.forEach(submoduleList, (v, i) => {
        if(_.isNil(v)){
            return
        }
        handledList.push({...v,itemStyle, x: 3600*v.level, y: i*600,id:v.key,name: v.prodSubsysSonName})
    })
    return handledList
}

function pushModuleNode(handledList, moduleList){
    let moduleCount = 0
    _.forEach(moduleList, v => {
        if(_.isNil(v)){
            moduleCount += 1
            return
        }
        const childrenLength = v.productAssociationSystemSonDos.length
        const label = {id:v.key,name: v.prodSubsysName}

        if(childrenLength === 0){
            handledList.push({...v,...label,itemStyle, x: 3600*v.level, y: moduleCount*600})
            moduleCount += 1
        }else{
            const childrenList = _.filter(handledList, x => x.parent === v.key)
            const averageY = _.reduce(_.map(childrenList, x => x.y),(acc, x) => {return acc += x},0)/childrenLength
            handledList.push({...v,...label,itemStyle, x: 3600*v.level, y: averageY})
            moduleCount += childrenLength
        }
    })
    return handledList
}

function pushProjectNode(handledList, projectList){
    let lastProjectY = 0
    _.forEach(projectList, v => {
        const childrenLength = v.productAssociationSystemDos.length
        const label = {id:v.key,name: v.productName}
        if(childrenLength === 0){
            lastProjectY += 600
            handledList.push({...v,...label,itemStyle, x: 3600*v.level, y: lastProjectY})
        }else{
            const childrenList = _.filter(handledList, x => x.parent === v.key)
            const averageY = _.reduce(_.map(childrenList, x => x.y),(acc, x) => {return acc += x},0)/childrenLength
            handledList.push({...v,...label,itemStyle, x: 3600*v.level, y: averageY})
            lastProjectY = averageY
        }
    })
    return handledList
}

function pushRootNode(handledList,data,systemName){
    const childrenLength = data.length
    const childrenList = _.filter(handledList, x => x.parent === 'root')
    const averageY = _.reduce(_.map(childrenList, x => x.y),(acc, x) => {return acc += x},0)/childrenLength
    handledList.push({itemStyle,level: 0, x: 0, y: averageY,id:'root',name:systemName})
    return handledList
}
import _ from 'lodash'
import gd from '../../../../base/global'
import convertOptions from '../../../common/ConvertOptions'

export default function authorityJudge(props) {
  const { accessData, allUserRes, principal, recUser, status } = props
  const posIdArr = _.map(accessData, o => o.posId)
  if (status === '4') {
    if (_.includes(posIdArr, '90')) return true
    return false
  }
  if (gd.User.operator_id === principal) return true
  const userName = convertOptions(gd.User.operator_id, allUserRes, 'userName', 'userAccount')
  if (userName === recUser) return true
  return _.some(posIdArr, v => _.includes(['90', 'CompanyLeader', 'assistant'], v))
}

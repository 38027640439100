import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useGet, usePost} from "rootnet-biz/lib/hooks";
import convertOptions from "../ConvertOptions";
import {Card, DataGrid, Loader} from "rootnet-ui";
import {TextIconBtn} from "../TextIconBtn";
import _ from "lodash";
import {strParams} from "../../../utils/publicFun";
import clsx from "clsx";
import './CodeCommitList.scss'
import {Icon} from "../../../components";
import {isNil} from "rootnet-core/format";
import CodeDiff from "../CodeDiff/CodeDiff";

function getColumns(props){
    const {allUserRes, setDetailInfo} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', stretch: false, width: 40 },
        { header: 'CommitID', bind: 'commitId', width: 100, sortable: true, tooltip: true,stretch: false,
            convert: (v, o) => {
                return <div className={'can-enter-text'} onClick={()=>{
                    setDetailInfo(o)
                }}>
                    {v}
                </div>
            }},
        { header: '分支名称', bind: 'branchName', width: 150, sortable: true, tooltip: true,stretch: false},
        { header: '说明', bind: 'remark', width: 200, sortable: true, tooltip: true},
        { header: 'git项目', bind: 'projectName', width: 150, sortable: true, tooltip: true,stretch: false},
        { header: '提交人', bind: 'updater', width: 100, sortable: true, tooltip: true,stretch: false,
            convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount', {showOrigin: true})},
        { header: '提交时间', bind: 'commitDate', width: 150, sortable: true, tooltip: true,stretch: false},
    ]
}

function getOptions(cols){
    return {
        nilText: '-',
        emptyText: '-',
        fixedLeft: 1,
        resizable: true,
        virtualized: true,
        autoFill: true,
        columns: cols,
    }
}

function CodeCommitList(props) {
    const {bizIdList} = props
    const allUserRes = useMemo(()=>JSON.parse(localStorage.getItem('all_user')),[])
    const {doFetch: getList, loading} = usePost()
    const [detailInfo, setDetailInfo] = useState()
    const {doFetch: getCommitDetail} = useGet()
    const [diffText, setDiffText] = useState()
    const [list, setList] = useState()

    useEffect(()=>{
        if(_.isNil(detailInfo)) return
        const searchParams = {
            projectId: detailInfo?.projectId,
            commitId: detailInfo?.commitId
        }
        getCommitDetail('/devops/uscs/code/commit/record/queryCommitDetail?'+strParams(searchParams)).then(res => {
            setDiffText(res)
        })
    },[getCommitDetail, detailInfo])

    const refreshList = useCallback(()=>{
        if(_.isEmpty(bizIdList)){
            setList(null)
        }else{
            getList('/devops/uscs/code/commit/record/rimsByBusinessNo',bizIdList).then(res => {
                setList(res)
            })
        }
    },[getList, bizIdList])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const options = useMemo(()=>{
        return getOptions(getColumns({allUserRes, setDetailInfo}))
    },[allUserRes])

    const extra = useMemo(()=>{
       return <TextIconBtn text={'刷新'} icon={'shuaxin1'} onClick={refreshList}/>
    },[refreshList])

    return <div className={'code-commit-list flex-y fill'}>
        <Card title={'提交记录'} className={clsx('x-card-singlegrid fill',{'hidden': !_.isNil(detailInfo)})} extra={extra}>
            <DataGrid option={options} data={list} loading={loading}/>
        </Card>
        {
            !_.isNil(detailInfo) &&
            <div className={'commit-detail-wrap flex-y fill'}>
                <div className="commit-detail-header flex">
                    <div className="back-area flex center-y" onClick={()=> {
                        setDetailInfo(null)
                        setDiffText(null)
                    }}>
                        <Icon name={'erjititlefanhui1'} className={'back-icon'}/>
                        <div className="back-text">返回</div>
                    </div>
                    <div className="commit-detail-info flex-y">
                        <div className="commit-detail-info-header flex center-y">
                            <div className="commit-detail-info-remark one-line-text">
                                {detailInfo?.remark || '无'}
                            </div>
                        </div>
                        <div className="commit-detail-update-info flex center-y">
                            <div className="commit-detail-update-info-item">
                                <div className="commit-detail-info-branch flex center-y">
                                    <Icon name={'line-gitbranchfenzhi'} style={{marginRight: 4}}/>
                                    {detailInfo?.branchName}
                                </div>
                            </div>
                            <div className="commit-detail-update-info-item">
                                commit: {detailInfo?.commitId}
                            </div>
                            <div className="commit-detail-update-info-item">
                                更新人: {convertOptions(detailInfo?.updater, allUserRes, 'userName', 'userAccount', {showOrigin: true})}
                            </div>
                            <div className="commit-detail-update-info-item">
                                更新时间: {detailInfo?.updateTime}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="commit-code-diff-wrap flex-y">
                    {
                        isNil(diffText) && <Loader fill/>
                    }
                    <CodeDiff diffText={diffText}/>
                </div>
            </div>
        }
    </div>
}

export default CodeCommitList;
import React from 'react'
import PublicTemplate from '../../common/publicPage/publicTemplate'

const LIST_CODE = '8803' //list
const ADD_PERMISSION = '880301' //创建复制权限
const EDIT_PERMISSION = '880302' //编辑权限
const DEL_PERMISSION = '880303' //删除权限

export default function TemplateMgt() {
  return <PublicTemplate {...{ LIST_CODE, ADD_PERMISSION, EDIT_PERMISSION, DEL_PERMISSION, type: 'all' }} />
}

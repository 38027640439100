import React, { useState } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import ListChange from './listChange'
import ChangeRecord from '../../../../../common/ChangeRecord'
import { strParams } from '../../../../../../utils/publicFun'
import './index.scss'

const { TabPane } = Tabs

export default function ChangeHistory(props) {
  const { currentId, allUserRes, taskOpt, reqOpt, issueOpt, history, secondOrder = 'listChange', firstOrder, countDynamic, snowId, inventoryDynamic, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt } = props
  const [activeTab, setActiveTab] = useState(secondOrder)
  return (
    <div className='version-change-history'>
      <Tabs centered activeKey={activeTab}
        onChange={v => {
          history.replace({ pathname: '/versionMsg', search: strParams({ initId: currentId, firstOrder, secondOrder: v, }) })
          setActiveTab(v)
        }}
      >
        <TabPane tab="清单变动" key="listChange">
          <React.Fragment key={inventoryDynamic}>
            <ListChange {...{ currentId, allUserRes, taskOpt, reqOpt, issueOpt, inventoryDynamic, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt }} />
          </React.Fragment>
        </TabPane>
        <TabPane tab="版本基本信息变更" key="essentialInformation">
          {
            !_.isNil(snowId) &&
            <div className={'dynamic-wrap'} key={countDynamic}>
              <ChangeRecord name='版本' funcCode={'0801'} referenceId={snowId} id={currentId} title={currentId} linkUrl={`/versionMsg?initId=${currentId}`} associatedField='content' associatedFieldName='' />
            </div>
          }
        </TabPane>
      </Tabs>
    </div>
  )
}

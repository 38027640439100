import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormInput} from "rootnet-edit";
import {DataGrid, Pagination, Messager} from "rootnet-ui";
import {Icon} from "../../../components";
import './FieldDefinition.scss'
import {Box} from "../../common/commonComponent";
import _ from "lodash";
import OperationList from "../../../project_share/components/OperationList";
import {TextIconBtn} from "../../common/TextIconBtn";
import FieldDefinitionUpdateDialog from "./FieldDefinitionUpdateDialog";
import usePost from "rootnet-biz/es/hooks/usePost";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import DelMessage from "../../../components/DelMessage";
import useGet from "rootnet-biz/es/hooks/useGet";
import FieldDefinitionLogDrawer from "./FieldDefinitionLogDrawer";
import ExportCsvDialog from "../../common/ExportCsvDialog";
import ImportApiDialog from "../../common/ImportApiDialog";
import {strParams} from "../../../utils/publicFun";

function getColumns(props){
    const {setCurrentInfo,optionsGroup,setDelId,setLogId, formQueryTypeOptions} = props
    const [fieldDbTypeOptions, fieldHtmlTypeOptions, fieldHtmlDetailTypeOptions, fieldDateSourceOptions, viewConditionTypeOptions] = optionsGroup

    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
        { header: '表名', bind: 'tableName', width: 130, tooltip: true, sortable:true },
        { header: '字段ID', bind: 'fieldId', width: 130, tooltip: true, sortable:true },
        { header: '字段名称', bind: 'fieldName', width: 100, tooltip: true, sortable:true },
        { header: '字段数据库类型', bind: 'fieldDbType', width: 120, tooltip: true, sortable:true, convert:v => convertOptions(v, fieldDbTypeOptions),options: fieldDbTypeOptions },
        { header: '字段数据库大小', bind: 'fieldDbSize', width: 120, tooltip: true, sortable:true },
        { header: '字段精确度', bind: 'accuracy', width: 120, tooltip: true, sortable:true },
        { header: '字段页面类型', bind: 'fieldHtmlType', width: 120, tooltip: true, sortable:true, convert:v => convertOptions(v, fieldHtmlTypeOptions),options:fieldHtmlTypeOptions },
        { header: '字段页面详细类型', bind: 'fieldHtmlDetailType', width: 130, tooltip: true, sortable:true, convert:v => convertOptions(v, fieldHtmlDetailTypeOptions),options:fieldHtmlDetailTypeOptions },
        { header: '字段数据源', bind: 'fieldDateSource', width: 100, tooltip: true, sortable:true, convert:v => convertOptions(v, fieldDateSourceOptions),options: fieldDateSourceOptions},
        { header: '字段详细数据源', bind: 'fieldDateDetailSource', width: 260, tooltip: true, sortable:true },
        { header: '视图条件过滤类型', bind: 'viewConditionType', width: 125, tooltip: true, sortable:true, convert: v => convertOptions(v, viewConditionTypeOptions) },
        { header: '查询过滤类型', bind: 'formQueryType', width: 125, tooltip: true, sortable:true, convert: v => convertOptions(v, formQueryTypeOptions) },
        { header: '是否在页面界面显示', bind: 'displayFlag', width: 140, tooltip: true, sortable:true },
        { header: '是否必填', bind: 'requiredFlag', width: 100, tooltip: true, sortable:true },
        { header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation}
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '日志',
                mode: 'log',
                onClick: ()=>setLogId(o.id)
            },
            {
                text: '修改',
                mode: 'edit',
                onClick: ()=>setCurrentInfo({mode:'edit',initData: {...o,displayFlag: o.displayFlag === 'Y',requiredFlag: o.requiredFlag === 'Y'}})
            },
            {
                text: '删除',
                mode: 'delete',
                onClick: ()=>setDelId(o.id)
            }
        ];
        return <OperationList options={operateOption}/>;
    }
}

const getOptions = (options,setSortConfig) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 4,
    fixedRight: 1,
    resizable: true,
    columns: options,
    virtualized: false,
    autoFill: true,
    onSort: (data, sort)=>{
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
    }
});

function getInitParams(){
    return {
        pageNum: 1,
        pageSize: 100
    }
}

const optionsUrls = [
    '/common/globalconst?globalConst=sqlserverType',
    '/common/globalconst?globalConst=FieldHtmlType',
    '/common/globalconst?globalConst=FieldHtmlDetailType',
    '/common/globalconst?globalConst=FieldDateSource',
    '/common/globalconst?globalConst=viewConditionType',
    '/common/globalconst?globalConst=alignment',
    '/common/globalconst?globalConst=dateStyle',
]

function FieldDefinition(props) {
    const [searchText, setSearchText] = useState()
    const [currentInfo, setCurrentInfo] = useState()
    const {data:listRes, doFetch: getList, loading, error} = usePost()
    const [params, setParams] = useState(getInitParams())
    const {data: optionsRes} = useGet(optionsUrls)
    const [delId, setDelId] = useState()
    const [logId, setLogId] = useState()
    const {data: logDetail, doFetch: getLogDetail} = useGet()
    const [showDialog, setShowDialog] = useState()
    const {data: formQueryTypeOptionsRes} = useGet('/common/globalconst?globalConst=formQueryType')
    const [currentId, setCurrentId] = useState()
    const [sortConfig, setSortConfig] = useState()
    const [pageSize, setPageSize] = useState(100)

    const formQueryTypeOptions = useMemo(()=>{
       if(_.isEmpty(formQueryTypeOptionsRes)) return []
        return _.map(formQueryTypeOptionsRes, x => ({ text: `${x.displayName}(${x.memo})`, value: x.memo }))
    },[formQueryTypeOptionsRes])

    useEffect(()=>{
        if(_.isNil(logId)) return
        getLogDetail('/FieldDefinition/selectLog?id='+logId)
    },[logId,getLogDetail])

    const optionsGroup = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        return _.map(optionsRes,x => convertGlobalConstOptions(x))
    },[optionsRes])

    const options = useMemo(()=>{
        return getOptions(getColumns({setCurrentInfo,optionsGroup,setDelId,setLogId,formQueryTypeOptions}),setSortConfig)
    },[optionsGroup,formQueryTypeOptions])

    const {total, pageNum, rows:list} = useMemo(()=>(listRes || {}),[listRes]);

    useEffect(()=>{
        let showList = list || []
        if(!_.isNil(sortConfig)){
            const sortList = _.sortBy(list, x => x[sortConfig.column])
            showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
        }
        const handleIndex = _.findIndex(showList, x => x.id === currentId)
        if(handleIndex !== -1){
            const currentPage = document.getElementsByClassName('field-definition')[0]
            const rows = currentPage.getElementsByClassName('row')
            _.forEach(rows, (x,i) => {
                x.style.backgroundColor = i === handleIndex? '#edf3f9': '#fff'
            })
        }
    },[sortConfig,currentId,list])

    const refresh = useCallback(()=>{
        const postParams = {
            action: 'query',
            searchName: searchText,
            ...params
        }
        getList('/FieldDefinition/maintain',postParams).then((res)=>{
            if(params.pageNum !== 1 && res.rows.length === 0){
                setParams(x => ({...x,pageNum: x.pageNum-1}))
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[params,getList,searchText])

    useEffect(()=>{
        refresh()
    },[refresh])

    const onSearchNameChange = useCallback((text)=>{
        setSearchText(text)
        setParams(x => ({...x, pageNum: 1}))
    },[])

    const onRowClick = useCallback((o) => {
        setCurrentId(o.id)
    },[])

    const extra = useMemo(()=>{

        return <div className='extra-group flex center-y'>
            <TextIconBtn icon='daoru' text='导入' onClick={()=>setShowDialog('import')}/>
            <TextIconBtn icon='xiazai2' text='导出' disabled={_.isEmpty(list)} onClick={()=>setShowDialog('export')}/>
            <TextIconBtn icon='tianjia' text='新增' onClick={()=>setCurrentInfo({mode:'add',initData: null})}/>
        </div>
    },[list])

    return <div className='field-definition fill flex-y'>
        <div className="search-area">
            <FormInput value={searchText} onChange={onSearchNameChange} placeholder={'表名 / 字段ID / 字段名称'} suffix={<Icon name={'sousuo1'}/>} componentWidth={230}/>
        </div>
        <Box title={'表字段定义'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={loading} error={error}>
            <DataGrid option={options} data={list} onRowClick={onRowClick}/>
            <Pagination pageSize={ pageSize } total={ total } current={ pageNum } selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        {
            !_.isEmpty(currentInfo) && <FieldDefinitionUpdateDialog close={()=>setCurrentInfo(null)} {...{optionsGroup,refresh,currentInfo,formQueryTypeOptions}}/>
        }
        {
            delId &&
            <DelMessage url={`/FieldDefinition/maintain`} method={'post'}  close={()=>setDelId(null)} refresh={refresh} params={{action: 'del',id: delId}}>
                是否确认删除？
            </DelMessage>
        }
        {
            showDialog === 'export' &&
            <ExportCsvDialog close={()=>setShowDialog(null)} option={options} isApi={true} url={'/FieldDefinition/export'} searchParams={{searchName:searchText}}
                             title={'表字段定义'}/>
        }
        {
            showDialog === 'import' &&
                <ImportApiDialog close={()=>setShowDialog(null)} template={`/FieldDefinition/model/download?${strParams({modelName:'字段定义.xlsx'})}`}
                                 defaultOptions={options} refresh={refresh} importUrl={'/FieldDefinition/upload'} parameter={{flag: 0,isApproval:0}} title={'字段定义基础表'}
                                 abnormal={'/FieldDefinition/export'} exportFieldname={'fieldDefinitionVos'}/>
        }
        <FieldDefinitionLogDrawer open={!_.isNil(logId)} cancel={()=>setLogId(null)} {...{options,logDetail}}/>
    </div>
}

export default FieldDefinition;
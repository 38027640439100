import React from 'react';
import { Dateformat, GetTime } from 'rootnet-core/format'
import { FormInput } from 'rootnet-edit';
import _ from 'lodash'

/*
*  得到对应日期
*/ 
export const getAllDate = ({ beginDate = '', endDate = '' }) => {
    let dateArr = []
    const start = new Date(Dateformat(beginDate))
    const end = new Date(Dateformat(endDate))
    const unixStart = start.getTime()
    const unixEnd = end.getTime()
    const oneDay = 24 * 60 * 60 * 1000;
    for (let stamp = unixStart; stamp <= unixEnd; stamp += oneDay) {
        const formatDate = new Date(parseInt(stamp))
        const whichDay = '周' + '日一二三四五六'.charAt(formatDate.getDay())
        dateArr.push({ date: Dateformat(GetTime(formatDate)), whichDay })
    }
    return dateArr
}

/*
* table 项目名称相同的 合并
* list => 数据data;  []
* type => 合并的类别
*/
export const tableHeader = (list, type) => {
	list = list || [];
	type = type || 'projectName';
	let projectId = '';
	list = _.map(list, item => {
		item[`${type}_2`] = item[`${type}_2`] || item[`${type}`];
		if(item.projectId === projectId) item[type] = '';
		if(item.projectId !== projectId){
			projectId = item.projectId;
			item[type] = item[`${type}_2`];
		}
		return item;
	})
	return list;
}

// 得到table---data
// 通过什么合并table  task
// 通过什么合并名称   type
export const getTabData = (res, type, task) => {
	let list = [];
	task = task || 'jobType';
	// 1: 拼接日历date 合并
	res = _.map(res, item => {
		item = item || {};
		item[`whichDay${item.workDate}`] = item.auditUseTime;
		item[`whichId${item.workDate}`] = item.id;
		return item;
	})

	// 2: jobType 合并  时间合并
	_.forEach(res, it => {
		let state = true;
		list = _.map(list, it2 => {
			if(it2[task] === it[task] && it[task]){
				state = false;
				it2 = _.assign({}, it, it2)
			}
			return it2;
		})
		if(state) list.push(it);
	})
	// 3：projectId 合并  projectName项目名称置空
	list = tableHeader(list, type)
	return list;
}

/*
* 得到table列
* obj => 返回的周期; {}
* tableData => 数据data;  []
* setTableData => 渲染的data; Hook
* optFor => 初始化table列; Function
* minDay => 这个日期之前不让修改; str
* maxDay => 这个日期之后不让修改; str
*/
export const getCol = (obj, tableData, setTableData, optFor, minDay, maxDay) => {
	let list = _.map(obj, (item, i) => {
		let _arr = item.date.split('-');
		let y = _arr[0];
		let m = _arr[1];
		let d = _arr[2];
		let bind = `whichDay${y}${m}${d}`;
		let header = `${item.whichDay}(${m}-${d})`;
		let col = { header, bind, width: 100, convert: (v, o, i) => { return tabCh(o, i, bind) } };
		return col;
	})
	list = _.concat([], optFor(obj, tableData), list);
	return tableOptsFor(list);
	function tableOptsFor(opts) {
		return {
			resizable: true,
			fixedLeft: 1,
			nilText: '-',
			emptyText: '-',
			columns: opts,
		}
	}
	function tabCh(o, i, type){
		let nowT = type.split('whichDay')[1];
		if(timeJudge(nowT, minDay, maxDay)){
			let v = _.get(tableData, `[${i}][${type}]`);
			return <FormInput
				value={v}
				type='number'
				digit={1}
				min={0}
				onChange={(e) => {
					tableData[i][type] = e;
					setTableData(_.concat([], tableData))
				}}
			/>
		}else{
			return _.get(tableData, `[${i}][${type}]`)
		}
		// 时间判断 
		// 1：如果没有最大和最小时间  输入框展示  true
		// 2：有最大时间  小于最大时间  输入框展示  true
		// 3：有最小时间  大于最小时间  输入框展示  true
		// 4：其他文字  false
		function timeJudge(nowT, minT, maxT){
			nowT = secDate(nowT);
			if(!minT && !maxT) return true;
			if(maxT && (nowT < secDate(maxT)) && ((minT && nowT > secDate(minT)) || !minT)) return true;
			if(minT && nowT > secDate(minT) && ((maxT && nowT <= secDate(maxT)) || !maxT)) return true;
			return false;
		}
	}
	// 得到对应的时间戳
	function secDate(time){
		if(_.isNil(time)) return '';
		time = _.toString(time);
		let ys = time.substring(0, 4);
		let ms = time.substring(4, 6);
		let ds = time.substring(6, 8);
		return new Date(ys, ms, ds).getTime();
	}
}

/*
* table数据拆分
* 得到对应的删除，编辑 和 添加 
* judge 额外判断条件
*/ 
export const getData = (orgData, tableData, judge) => {
	let _data = _.cloneDeep(tableData);
	judge = judge || [];
	// 1: tableData 分解
	let dataList = [];
	_.forEach(_data, it => {
		_.forIn(it, (v, k) => {
			if(_.includes(k, 'whichDay')){
				let _par = {
					workDate: k.split('Day')[1],
					auditUseTime: v
				}
				dataList.push(_.assign({}, it, _par));
			} 
		})
	})

	let list = _.map(dataList, it => {
        // 得到修改前的 auditUseTime
        let audit = null;
		let newAudit = it.auditUseTime;
		it.id = _.get(it, `whichId${it.workDate}`)
        _.forEach(orgData, it2 => {
            if(it2.workDate === it.workDate){
				if(_.isEmpty(judge)) return audit = it2.auditUseTime;
				let _r = _.every(judge, _k => {
					return it2[_k] === it[_k]
				})
				if(_r){
					return audit = it2.auditUseTime
				}
            }
		})

		// 1: 以前不存在，现在存在  add
		// 2：以前存在，现在存在   且不相等   edit
		// 3：以前存在，现在不存在  del
        if(!audit && newAudit){
            it.actionType = 'ADD'
        }else if(audit && newAudit && (newAudit!=='0') && _.toNumber(audit) !== _.toNumber(newAudit)){
            it.actionType = 'EDIT';
        }else if(audit && (!newAudit || newAudit === '0')){
            it.actionType = 'DEL';
		}
        return it;
	})

	// 过滤whichDay 和 whichId
	// 得到对应的id
	list = _.map(list, item => {
		item.id = item[`whichId${item.workDate}`] || null;
		_.forIn(item, (v, k) => {
			if(_.includes(k, 'whichDay') || _.includes(k, 'whichId')){
				item = _.omit(item, k)
			}
		})
		return item
	})

	return list;
}
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Form, FormInput, Select} from "rootnet-edit";
import {Button, DataGrid, Pagination, Messager, MessageBox} from 'rootnet-ui'
import './common.scss'
import {Box} from "../../../common/commonComponent";
import OperationList from "../../../../project_share/components/OperationList";
import {Icon} from "../../../../components";
import {useGet} from "../../../../utils/hook";
import {pathSearchFor, strParams} from "../../../../utils/publicFun";
import _ from "lodash";
import {toDate, dateFormat} from 'rootnet-core/dateFormat'
import {Cache} from '../../../../base/cache'
import DetailsGoBackWithTextClick from "../../../common/DetailsGoBackWithTextClick";

const { str14ToDate } = toDate;
const CACHE_KEY = 'createView'
const CURRENT_CACHE_KEY = 'taskList'
const PRIMARY_KEY = 'projectDetailId'

const getColumns = (props) => {
    const {statusOptions, setAddedList, tracerStateOptions} = props
    return [
        {selection:true,sortable:true},
        {header: '需求ID', bind: 'interiorReqNo',sortable:true,width: 160, tooltip: true},
        {header: '任务清单', bind: 'projectDetailName',sortable:true,width: 300, tooltip: true},
        {header: '状态', bind: 'projectDetailStatus',sortable:true,width: 160, tooltip: true, convert: v => convertOptions(v,statusOptions)},
        {header: '设计负责人', bind: 'detailUserName',sortable:true,width: 160},
        {header: '设计计划完成', bind: 'designCompleteDate',sortable:true,width: 150, convert: convertDate},
        {header: '开发计划完成', bind: 'devCompleteDate',sortable:true,width: 150, convert: convertDate},
        {header: '测试计划完成', bind: 'testCompleteDate',sortable:true,width: 150, convert: convertDate},
        {header: '研发任务ID', bind: 'tracerId',sortable:true,width: 200, tooltip: true},
        {header: '研发任务状态', bind: 'tracerState',sortable:true,width: 160, tooltip: true, convert: v => convertOptions(v,tracerStateOptions)},
        {header: '操作', bind: '', align: 'center',width: 90, isTransfer: false, convert: renderOperationWrap}
    ]

    function renderOperationWrap(v, o){
        const all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const notInCache = _.isNil(_.find(currentCache, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]))
        if(notInCache){
            return renderAddOperation(v, o)
        }else{
            return renderDelOperation(v, o)
        }
    }
    function renderAddOperation(v, o){
        const addOption = [{
            text: '添加',
            onClick: ()=>addCache(o)
        }]
        return <OperationList options={addOption}/>;
    }

    function renderDelOperation(v, o){
        const disableOption = [{
            text: '已添加',
            disabled: true
        }]
        const delOption = [{
            text: '移除',
            onClick: ()=>delCache(o)
        }]
        return <div className='del-operation-group'>
            <OperationList className='added-operation-btn' options={disableOption}/>
            <OperationList className='del-operation-btn' options={delOption}/>
        </div>
    }

    function delCache(o){
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        all[CURRENT_CACHE_KEY] = _.filter(currentCache, x => x[PRIMARY_KEY] !== o[PRIMARY_KEY])
        Cache.set(CACHE_KEY, all)
        setAddedList(all[CURRENT_CACHE_KEY])
    }

    function addCache(o){
        let all = Cache.get(CACHE_KEY)
        if(_.isNil(all)){
            all = {}
            all[CURRENT_CACHE_KEY] = [o]
            Cache.set(CACHE_KEY, all)
            setAddedList(all[CURRENT_CACHE_KEY])
            return Messager.show('已添加',{icon:'success'})
        }
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        if(_.isNil(currentCache)){
            all[CURRENT_CACHE_KEY] = [o]
            Cache.set(CACHE_KEY, all)
            setAddedList(all[CURRENT_CACHE_KEY])
            return Messager.show('已添加',{icon:'success'})
        }
        const notInCache = _.isNil(_.find(currentCache, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]))
        if(notInCache){
            all[CURRENT_CACHE_KEY] = all[CURRENT_CACHE_KEY].concat(o)
            Cache.set(CACHE_KEY, all)
            setAddedList(all[CURRENT_CACHE_KEY])
            return Messager.show('已添加',{icon:'success'})
        }else{
            return Messager.show('已经在添加列表中',{icon:'error'})
        }

    }

    function convertOptions(v, options){
        return _.get(_.find(options, x => x.value === v),'text')
    }
    function convertDate(v){
        return dateFormat('YYYY-MM-DD', str14ToDate(v))
    }
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedLeft: 2,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
});

const DFormInput = (props) => <FormInput componentWidth={180} {...props}/>

const INIT_PARAMS = {
    productId: null,
    custId: null,
    projectDetailId: null,
    issueState: null,
    designPrincipal: null,
    pageNum: 1,
    pageSize: 20,
    id: null,
}

const CONVERT_OPTIONS_URLS = [
    '/common/globalconst?globalConst=ProjectStatus',
    '/common/globalconst?globalConst=TRACERSTATE',
]

function CreateTaskView(props) {
    const {location} = props;
    const pathParams = useMemo(() => pathSearchFor(location.search), [location]);
    const {scene = 'add'} = pathParams
    const [params, setParams] = useState(_.assign(INIT_PARAMS,pathParams))
    const [selectedList, setSelectedList] = useState([])
    const [addList,setAddedList] = useState([])
    const {data: listRes, doFetch: getList, loading, error} = useGet()
    const {data: convertOptionsRes} = useGet(CONVERT_OPTIONS_URLS)
    const [beforeSelectedList, setBeforeSelectedList] = useState([])
    const { pageSize, total, pageNum, list } = useMemo(()=>(listRes || {}),[listRes]);
    const [showLeaveDialog, setShowLeaveDialog] = useState(false)
    const [isSaved, setIsSaved] = useState(true)

    useEffect(()=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const keyList = _.map(currentCache, x => x[PRIMARY_KEY])
        const selected = _.filter(list, x => _.includes(keyList, x[PRIMARY_KEY]))
        setSelectedList(selected)
        setBeforeSelectedList(selected)
    },[addList,list])

    const [statusOptions,tracerStateOptions] = useMemo(()=>{
        const [d1, d2] = convertOptionsRes || []
        const statusOptions = _.map(d1, x => ({value: x.interiorId, text: x.displayName}))
        const tracerStateOptions = _.map(d2, x => ({value: x.interiorId, text: `${x.interiorId}-${x.displayName}`}))
        return [statusOptions,tracerStateOptions]
    },[convertOptionsRes])

    useLayoutEffect(()=>{
        getList('/viewDetail/viewItemTask?'+strParams(params))
    },[params, getList])

    const option = useMemo(()=>{
        return getOption(getColumns({statusOptions,tracerStateOptions,setAddedList}))
    },[statusOptions,tracerStateOptions])

    const addCacheList = useCallback((list)=>{
        let all = Cache.get(CACHE_KEY);
        if(!_.isPlainObject(all)) all = {};
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        return _.isArray(currentCache) ?  add(all[CURRENT_CACHE_KEY].concat(list)) : add(list) ;

        function add(l){
            all[CURRENT_CACHE_KEY] = l;
            Cache.set(CACHE_KEY, all)
            setAddedList(all[CURRENT_CACHE_KEY]);
        }
    },[])

    const delCacheList = useCallback((list)=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const delKeyList = _.map(list, x => x[PRIMARY_KEY])
        all[CURRENT_CACHE_KEY] = _.filter(currentCache, x =>  !_.includes(delKeyList, x[PRIMARY_KEY]))
        Cache.set(CACHE_KEY, all)
        setAddedList(all[CURRENT_CACHE_KEY])
        Messager.show('已保存',{icon:'success'})
    },[])

    const handleSelected = useCallback((list)=>{
        const beforeKeyList = _.map(beforeSelectedList, x => x[PRIMARY_KEY])
        const afterKeyList = _.map(list, x => x[PRIMARY_KEY])
        const addList = _.filter(list, x => !_.includes(beforeKeyList, x[PRIMARY_KEY]))
        const delList = _.filter(beforeSelectedList, x => !_.includes(afterKeyList, x[PRIMARY_KEY]))
        addCacheList(addList)
        delCacheList(delList)
        setIsSaved(true)
    },[beforeSelectedList,addCacheList,delCacheList])

    const extra = useMemo(()=>{
        return <>
            <Button primary onClick={()=>handleSelected(selectedList)}>保存</Button>
        </>
    },[selectedList,handleSelected])

    useEffect(()=>{
        const all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const keyList = _.map(currentCache, x => x[PRIMARY_KEY])
        const selected = _.filter(list, x => _.includes(keyList, x[PRIMARY_KEY]))
        setBeforeSelectedList(selected)
        setSelectedList(selected)
        setAddedList(selected)
    },[list])

    return  <div className='create-project-view flex-y fill create-view-list'>
        <DetailsGoBackWithTextClick onClick={handleClickLeave} />
        <Options initParams={params} search={setParams} statusOptions={statusOptions}/>
        <Box title='任务清单' className='flex-y x-card-singlegrid' data={list} extra={extra} loading={loading} error={error}>
            <DataGrid option={option} data={list} selection={selectedList} onSelectionChange={list=>{setSelectedList(list);setIsSaved(false)}}/>
            <Pagination pageSize={ pageSize } total={ total } current={ pageNum }
                        onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>
        </Box>
        {
            showLeaveDialog &&
            <MessageBox header='提示' confirm={goBack} cancel={()=>setShowLeaveDialog(false)}>
                当前内容未保存，确定离开吗？
            </MessageBox>
        }
    </div>

    function handleClickLeave(){
        if(isSaved) return goBack()
        setShowLeaveDialog(true)
    }

    function goBack(){
        const url = scene === 'add' ? '/createView?' : '/projectView/edit?'
        props.history.push(url + strParams(pathParams))
    }
}

const OPTION_URLS = [
    '/viewCommon/getProductInfo',
    '/common/system',
    '/common/globalconst?globalConst=ProductLine',
]

function Options(props){
    const {initParams, search, statusOptions} = props;
    const [params, setParams] = useState(initParams);
    const [isExpand, setIsExpand] = useState(false)
    const {data: optionsRes} = useGet(OPTION_URLS)
    const {data: projectNameOptionsRes, doFetch: getProjectNameOptions} = useGet()
    const [canSearch, setCanSearch] = useState(false)
    const {data: projectDetailManagerRes, doFetch: getProjectDetailManager} = useGet()

    const projectDetailManage = useMemo(()=>{
        if(_.isNil(projectDetailManagerRes)) return []
        const filterManager = _.filter(projectDetailManagerRes, x => !_.some([x.designPrincipal,x.userName],_.isNil))
        const uniqManager = _.uniqBy(filterManager, x => x.designPrincipal)
        return _.map(uniqManager, x => ({value: x.designPrincipal, text: x.userName, tag: x.designPrincipal+x.userName}))
    },[projectDetailManagerRes])

    useEffect(()=>{
        const getParams = {productId: params.productId, custId: params.custId }
        getProjectNameOptions('/viewCommon/getUserProject?'+strParams(getParams))
    },[params.productId, params.custId, getProjectNameOptions])

    const projectNameOptions = useMemo(()=>{
        return _.map(projectNameOptionsRes, x => ({value: x.id, text: x.projectName,projectId: x.projectId}))
    },[projectNameOptionsRes])

    useEffect(()=>{
        const projectId = _.get(_.find(projectNameOptions, x => x.value === params.id),'projectId')
        const expandUrl = _.isNil(projectId)? '' : `?projectId=${projectId}`
        getProjectDetailManager('/viewCommon/getDesignPrincipalName'+expandUrl)
    },[getProjectDetailManager,params.id,projectNameOptions])

    useEffect(()=>{
        if(_.isNil(params.designPrincipal)) return
        if(_.isNil(params.id)) return
        if(!_.isNil(_.find(projectDetailManage, x => x.value === params.designPrincipal))) return
        setParams(x => _.assign({}, x, {designPrincipal: null}))
    },[params.id, params.designPrincipal,projectDetailManage])

    useEffect(()=>{
        if(_.isNil(projectDetailManagerRes) || projectDetailManagerRes.length ===0){
            setParams(x => _.assign(x, {designPrincipal: null}))
        }
    },[projectDetailManagerRes])

    useEffect(()=>{
        if(_.isNil(projectNameOptions)) return setParams(x => _.assign(x,{id: null}))
        if(_.isNil(params.id)) return setCanSearch(false)
        if(_.isNil(_.find(projectNameOptions, x => x.value === params.id))){
            setParams(x => _.assign(x,{id: null}))
            setCanSearch(false)
            return
        }
        setCanSearch(true)
    },[projectNameOptions,params.id])

    const [productOptions, customerOptions, filterStatusOptions] = useMemo(()=>{
        const [d1, d2, d3] = optionsRes || []
        const productOptions = _.map(_.groupBy(d1,product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d3, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            children: _.map(productLineItem, x => ({value: x.productId, text: x.productName}))
        }))
        const customerOptions = _.map(d2, x => ({value: x.projectId, text: `${x.projectId}-${x.projectName}`}))
        const filterStatusOptions = _.filter(statusOptions, x => x.value !== '3')
        return [productOptions,customerOptions,filterStatusOptions]
    },[optionsRes,statusOptions])

    return <div className='options'  style={{height: isExpand ? 144 : 72}}>
        <div className="c-options no-margin-top">
            <Form value={params} onChange={obj => setParams(x => ({...x,...obj}))}>
                <DFormInput bind="productId" label="产品" component={Select} search tree multiple clear options={productOptions}/>
                <DFormInput bind="custId" label="客户" component={Select} search clear options={customerOptions}/>
                <DFormInput bind="id" label='项目名称' component={Select} search required options={projectNameOptions}/>
                <DFormInput bind="projectDetailId" label='任务清单'/>
            </Form>
            <Icon name='tiaojianshouqi' className='expand-icon'
                  onClick={()=>setIsExpand(x => !x)} style={{transform: isExpand ? 'rotate(90deg)' : 'rotate(-90deg)'}}/>
            <Button primary onClick={() => search({...params})} className='btn' disabled={!canSearch}>查询</Button>
            <Button text onClick={() => {
                setParams(initParams)
                search(initParams)
            }} className='btn reset-btn'>重置</Button>
        </div>
        <div className="c-options">
            <Form value={params} onChange={obj => setParams(x => ({...x,...obj}))}>
                <DFormInput bind="issueState" label="任务状态" component={Select} clear options={filterStatusOptions}/>
                <DFormInput bind="designPrincipal" label="设计负责人" component={Select} search clear options={projectDetailManage}/>
            </Form>
        </div>
    </div>
}

export default CreateTaskView;
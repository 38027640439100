import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination } from 'rootnet-ui'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { Box } from '../../common/commonComponent'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import ViewArea from '../../common/view/ViewArea'
import convertTableAlign from '../../common/view/convertTableAlign'
import findConvert from '../../common/view/findConvert'
import { pathSearchFor, strParams } from '../../../utils/publicFun'
import VersionDetail from './components/versionDetail'
import './index.scss'
import { TextIconBtn } from '../../common/TextIconBtn'
import AddVersion from './components/addVersion'
import { useFuncCode } from '../../common/commonMethod'
import { API1 } from '../../../base/task'
import { Icon } from '../../../components'
import ADD_PICK from './components/addVersion/addPick'

const FUNC_CODE = '0801'
const ENTER_DETAIL_FIELD = 'ReleaseID'
const EDITABLE_FIELD = ['ReleaseID']
const VERSION_LIST_URL = '/develop/release/list?stateList=T,C,L,R&versionType=0'
const ADD_FUNC_CODE = '081005' //版本增加
const EDIT_FUNC_CODE = '081010' //版本修改
const REQ_FUNC_CODE = '081015' //版本封板需求调整
const PROJECT_FUNC_CODE = '081020' //版本规划
const COPY_FUNC_CODE = '081030' //版本复制
const VERSION_DETAIL_URL = '/releaseinfo/detail'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentId, history, setCurrentInfo, isCopyCode } = props

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId === ENTER_DETAIL_FIELD ? false : true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)

    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }

    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentId(o.id)
          history.replace({ pathname: '/versionMsg', search: strParams({ initId: o.id, firstOrder: 'req', }) })
        }}>{showValue}</div>
        {
          isCopyCode &&
          <Icon name='fuzhi' className="enter-detail-field-edit-icon" onClick={() => {
            setCurrentInfo({ id: o.id, mode: 'copy' })
          }} />
        }
      </div>
    }

  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function VersionMsg(props) {
  const { location, history } = props;
  const { initId = null, apply = false, firstOrder, secondOrder } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [currentInfo, setCurrentInfo] = useState()
  const [currentId, setCurrentId] = useState(initId)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [sortConfig, setSortConfig] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [factor, setFactor] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { data: versionData } = useGet(VERSION_LIST_URL)
  const { data: allUserRes } = useGet('/common/userinfo')
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [isAddCode, isEditCode, isReqCode, isProjectCode, isCopyCode] = useFuncCode([ADD_FUNC_CODE, EDIT_FUNC_CODE, REQ_FUNC_CODE, PROJECT_FUNC_CODE, COPY_FUNC_CODE])

  const { data: copyData, doFetch: copyFetch, loading: copyLoading } = useGet()

  useEffect(() => {
    if (currentInfo?.mode === 'copy') {
      copyFetch(`${VERSION_DETAIL_URL}?${strParams({ releaseid: currentInfo.id })}`)
    }
  }, [currentInfo, copyFetch])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionData)) return []
    return _.map(_.orderBy(versionData, 'issueDate', 'desc'), o => ({ text: o.releaseId, value: o.releaseId }))
  }, [versionData])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { setCurrentId, history, setCurrentInfo, isCopyCode },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      {
        isAddCode &&
        <TextIconBtn icon='tianjia' text='新增' onClick={() => setOpen(true)} />
      }
    </div>
  }, [exportViewList, isAddCode])
  return (
    <div className='version-msg fill flex-y'>
      <div className={'flex-y fill'} style={{ display: currentId ? 'none' : '' }}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Box title={'版本管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <DataGrid option={options} data={showList} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
      </div>
      {
        currentId && <VersionDetail goBack={() => {
          setCurrentId(null)
          history.replace({ pathname: '/versionMsg' })
        }} {...{ apply, currentId, convertCollection, refreshList, history, versionOpt, setCurrentId, isProjectCode, isEditCode, isReqCode, allUserRes, firstOrder, secondOrder, isAddCode }} />
      }
      {open && <AddVersion close={() => setOpen(false)} refresh={refreshList} />}
      {
        _.get(currentInfo, 'mode') === 'copy' && !copyLoading &&
        <AddVersion
          defaultParams={_.pick(copyData, ADD_PICK)}
          close={() => setCurrentInfo(null)}
          refresh={refreshList}
        />
      }
    </div>
  )
}

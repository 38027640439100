import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer} from 'react';
import {DataGrid, Pagination} from 'rootnet-ui'
import _ from "lodash";
import './DefectDataGrid.scss'
import {useGet} from "rootnet-biz/es/hooks";
import {GuideContext} from "../../../common/Context";

function DefectDataGrid(props) {
    const {setGuideList} = useContext(GuideContext)
    const {setParams, listRes, options, dataGridList, sortConfig, isTree, currentId, setCurrentId, pageSize,
        setPageSize, selectedIdList} = props
    const {total, pageNum} = useMemo(()=>(listRes || {}),[listRes]);
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const {doFetch: showGuide} = useGet()

    useEffect(()=>{
        showGuide('/promotion/guide?funcCode=0504').then(res => {
            if(res){
                setGuideList(x => _.concat(x,[
                    {
                        element: document.querySelector('.quick-add-area'),
                        intro: '支持快速新建缺陷，仅填写【标题】和【处理人】即可。'
                    }
                ]))
            }
        })
    },[showGuide,setGuideList])

    useLayoutEffect(()=>{
        let showList = dataGridList || []
        if(!_.isNil(sortConfig) && !isTree){
            const sortList = _.sortBy(dataGridList, x => x[sortConfig.column])
            showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
        }
        const handleIndex = _.findIndex(showList, x => x.id === currentId)
        if(handleIndex !== -1){
            const currentPage = document.getElementsByClassName('defect-data-grid')
            if(currentPage.length === 0) return
            const currentDom = currentPage[0]
            const rows = currentDom.getElementsByClassName('row')
            let evt = document.createEvent("MouseEvents");
            evt.initEvent("click", true, true);
            _.forEach(rows, (x,i) => {
                if(i === handleIndex){
                    x.dispatchEvent(evt)
                }
            })
        }
    },[sortConfig,currentId,dataGridList,isTree])

    useEffect(()=>{
        _.forEach(dataGridList, o => {
            return o._rowClass = (o?.id === currentId || _.includes(selectedIdList, o?.id)) ? 'select_row' : ''
        })
        forceUpdate()
    },[currentId,dataGridList, selectedIdList])

    const onRowClick = useCallback((item) => {
        setCurrentId(item.id)
    },[setCurrentId])

    return <div className={'defect-data-grid fill flex-y'}>
        <DataGrid className='defect-data-grid-table' data={dataGridList} option={options} onRowClick={onRowClick}/>
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, {pageNum, pageSize}))
                    }}/>
    </div>
}

export default DefectDataGrid;
import { useState, useCallback, useLayoutEffect } from 'react';
import { getTargetElement } from './helper';

const useSize = (target) => {
    const [size, setSize] = useState({ width: null, height: null });

    const onResize = useCallback(() => {
        const el = getTargetElement(target);
        setSize({
            width: el?.clientWidth,
            height: el?.clientHeight,
        });
    }, [target]);

    useLayoutEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return (() => {
            window.removeEventListener('resize', onResize);
        });
    }, [onResize]);

    return size;
};

export default useSize;

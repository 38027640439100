import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import { useGet } from '../../../utils/testHook'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select } from 'rootnet-edit'
import { DataGrid, Button } from 'rootnet-ui'
import { strParams } from '../../../utils/publicFun'
import { Box } from '../../common/commonComponent'
import convertGlobalConstOptions from '../../common/ConvertGlobalConstOptions'
import ArrowCrumbs from '../../common/ArrowCrumbs'
import { isNil } from 'rootnet-core/format'
import './listDetails.scss'

const { str14ToDate } = toDate

const OPTIONS_URL = [
  '/common/globalconst?globalConst=peopleEvaluateRelation',
  '/common/globalconst?globalConst=peopleEvaluateStatus',
]

const STATUS_COLOR = {
  '01': '#FFB65D',
  '02': '#C9CDD4',
}

const getColumns = (props) => {
  const { currentId, history, statusOpt, setDetailId } = props //relationOpt
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '被评者', bind: 'userName', sortable: true, align: 'left', width: 100, tooltip: true, convert: showDetail },
    { header: '部门', bind: 'url', width: 300, sortable: true, align: 'left', tooltip: true },
    { header: '岗位', bind: 'name', sortable: true, align: 'left', width: 100, tooltip: true },
    // { header: '关系', bind: 'relation', sortable: true, align: 'left', width: 60, tooltip: true, convert: getRelation },
    { header: '状态', bind: 'status', sortable: true, align: 'left', width: 85, tooltip: true, convert: getStatus },
    { header: '评价时间', bind: 'createTime', sortable: true, align: 'left', width: 120, tooltip: true, convert: getDate },
  ]

  // function getRelation(v) {
  //   return _.find(relationOpt, item => item.value === v)?.text
  // }

  function getStatus(v) {
    return <div>
      <span style={{ width: 10, height: 10, marginRight: 8, borderRadius: '50%', display: 'inline-block', backgroundColor: STATUS_COLOR[v] }}></span>
      {_.find(statusOpt, item => item.value === v)?.text}
    </div>
  }

  function getDate(date) {
    if (isNil(date)) return ''
    return dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(date))
  }

  function showDetail(v, o) {
    return <div
      className='enter-detail-field'
      onClick={() => {
        setDetailId(o.id)
        history.replace({ pathname: '/360Evaluate', search: strParams({ questId: currentId, evaluateId: o.id }) })
      }}
    >
      {v}
    </div>
  }

}

const gridOptsFor = (columns) => ({
  nilText: '-',
  fixedLeft: 2,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});

const getInitParams = () => ({
  relation: null,
  status: null,
})

export default function ListDetails(props) {
  const { currentId, history, routingHop, listOpt, list, listError, listLoading = false, listRefresh, setDetailId, refres } = props
  const [params, setParams] = useState({})
  const { data: optionsRes } = useGet(OPTIONS_URL)

  const [relationOpt, statusOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2] = optionsRes
    return [
      convertGlobalConstOptions(d1),
      convertGlobalConstOptions(d2),
    ]
  }, [optionsRes])

  const option = useMemo(() => gridOptsFor(getColumns({ currentId, history, relationOpt, statusOpt, setDetailId })), [currentId, history, relationOpt, setDetailId, statusOpt])

  const goBack = useMemo(() => {
    return [
      { text: '360评价' },
      {
        text: <FormInput search horizontal
          options={listOpt}
          component={Select}
          defaultValue={currentId}
          onChange={v => { routingHop(v) }}
        />, active: true
      }
    ]
  }, [currentId, listOpt, routingHop])

  return (
    <div className='appraise-list-details fill flex-y'>
      <div style={{ backgroundColor: '#fff', padding: '4px 0' }}>
        <ArrowCrumbs goBack={() => { routingHop(); refres() }} data={goBack} />
      </div>
      <div className='flex-y' style={{ flex: 1, overflow: 'hidden' }}>
        <Option {...{ relationOpt, statusOpt, listRefresh, params, setParams }} />
        <Box className='flex-y x-card-singlegrid' title='评价人员列表' data={list} error={listError} loading={listLoading}>
          <DataGrid data={list} option={option} />
        </Box>
      </div>
    </div>
  )
}

function Option(props) {
  const { relationOpt, statusOpt, listRefresh, params, setParams } = props
  return <div className='c-options'>
    <Form value={params} onChange={setParams} >
      <FormInput horizontal label='关系' bind='relation' component={Select} options={relationOpt} clear />
      <FormInput horizontal label='状态' bind='status' component={Select} options={statusOpt} clear />
    </Form>
    <Button primary className='btn' onClick={() => listRefresh(params)}>查询</Button>
    <Button text className='btn reset-btn' onClick={() => {
      listRefresh(getInitParams())
      setParams(getInitParams())
    }}>重置</Button>
  </div>
}
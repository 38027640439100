import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Popconfirm, Tooltip } from 'antd'
import { Button, Loader, Messager } from 'rootnet-ui'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Display, Select, Input, DatePicker } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Icon, } from '../../../../../../../components'
import { TextIconBtn } from '../../../../../../common/commonComponent'
import { strParams } from '../../../../../../../utils/publicFun'
import TextAreaInput from '../../../../../../common/textAreaInput/TextAreaInput'
import convertOptions from '../../../../../../common/ConvertOptions'
import UserSelect from '../../../../../../common/personSelectPop/UserSelect'
import './index.scss'

const ADD_URL = '/riskFollow/add'
const EDIT_URL = '/riskFollow/update'
const DETAIL_URL = '/riskFollow/info'
const DEL_URL = '/riskFollow/delete'

// const GLOBAL_CONST_OPTIONS_URLS = [
//   '/common/globalconst?globalConst=CasePriority', // 发生概率 风险等级
//   '/common/globalconst?globalConst=Yes_Or_No',    // 是否已发生  是否展示首页
//   '/common/globalconst?globalConst=riskStatus',   // 风险状态
// ]

const HFormInput = props => <FormInput horizontal labelWidth={105} componentWidth={180} {...props} />

export default function RiskFollowUpDetailContent(props) {
  const { close, currentInfo, refreshViewList, projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions, allUserRes } = props
  const { mode: initMode = 'detail' } = currentInfo
  const [id, setId] = useState()
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [infoError, setInfoError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [mode, setMode] = useState(initMode)
  const { doFetch: getDetail, loading } = useGet()
  const { doFetch } = usePost()
  // const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const isAdd = useMemo(() => mode === 'add', [mode])
  const isDetail = useMemo(() => mode === 'detail', [mode])

  // const [rankOptions, whetherOptions, statusOptions] = useMemo(() => {
  //   if (_.isEmpty(globalConstOptionsRes)) return []
  //   return _.map(globalConstOptionsRes, o => convertGlobalConstOptions(o))
  // }, [globalConstOptionsRes])

  const refreshDetail = useCallback(() => {
    if (isAdd) {
      const initParams = { riskStatus: '0', isHomePage: 'N' }
      setFormData(initParams)
      setEditFormData(initParams)
    }
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?${strParams({ id })}`)
      .then(res => {
        const initParams = _.assign({}, res, { responsiblePerson: _.split(res.responsiblePerson, ',') })
        setFormData(initParams)
        setEditFormData(initParams)
        setId(res.id)
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [id, isAdd, getDetail])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(infoError), x => x)
  }, [infoError])

  const delClick = useCallback(() => {
    doFetch(DEL_URL, [id])
      .then(() => {
        refreshViewList()
        Messager.show('删除成功', { icon: 'success' })
        close()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, id, close, refreshViewList])

  const submit = useCallback(() => {
    if (!isAdd && JSON.stringify(formData) === JSON.stringify(editFormData)) {
      setMode('detail')
      return Messager.show('无变更')
    }
    if (_.isNil(projectId) && isAdd) return Messager.show('请返回列表后再次进入')
    if (_.size(_.trim(_.get(editFormData, 'riskTitle'))) <= 0) return Messager.show('请填写风险标题', { icon: 'error' })
    if (_.size(_.trim(_.get(editFormData, 'riskDesc'))) <= 0) return Messager.show('请填写风险描述', { icon: 'error' })
    if (_.size(_.trim(_.get(editFormData, 'riskImpact'))) <= 0) return Messager.show('请填写风险影响', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.assign({}, editFormData, isAdd && { projectId }, { responsiblePerson: _.join(editFormData.responsiblePerson, ',') })
    doFetch(isAdd ? ADD_URL : EDIT_URL, isAdd ? postParams : [postParams])
      .then(() => {
        Messager.show(isAdd ? '新增成功' : '修改成功', { icon: 'success' })
        refreshDetail()
        refreshViewList()
        setMode('detail')
        setIsDisable(false)
        isAdd && close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, doFetch, isAdd, refreshDetail, close, refreshViewList, projectId, formData, editFormData])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) setId(id)
  }, [currentInfo])

  useEffect(() => {
    refreshDetail()
  }, [refreshDetail])

  return (
    <div className='risk-follow-up-detail-content flex-y'>
      {!isAdd && loading && <Loader fill />}
      <div className="mock-dialog-header flex">
        <div className="dialog-title flex center-y">
          {isAdd && '新增风险跟进'}
          {isDetail && '详情'}
          {(!isAdd && !isDetail) && '修改'}
        </div>
        <div className="mock-right-header flex center-y">
          {
            (!isAdd && (isPo || isPMM)) &&
            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
              refreshDetail()
              setMode(x => x === 'detail' ? 'edit' : 'detail')
            }} />
          }
          {
            (!isAdd && (isPo || isPMM)) && <Popconfirm
              title="确认删除？"
              onConfirm={delClick}
              okText="删除"
              cancelText="取消"
              overlayInnerStyle={{ padding: 8, width: 200 }}
            >
              <TextIconBtn style={{ marginLeft: 16 }} icon='shanchu2' text={'删除'} />
            </Popconfirm>
          }
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>

      <div className="process-user-group-detail-main-panel">
        <Form value={editFormData} onChange={setEditFormData} error={infoError} onError={setInfoError}>
          <HFormInput required label='风险标题' bind='riskTitle' component={isDetail ? Display : Input} componentWidth={481} />
          <HFormInput required label='发生概率' bind='probability' options={rankOptions} component={isDetail ? Display : Select} convert={v => convertOptions(v, rankOptions)} />
          <HFormInput required label='是否已发生' bind='isOccurred' options={whetherOptions} component={isDetail ? Display : Select} convert={v => convertOptions(v, whetherOptions)} />
          <Tooltip title='如已发生，请创建问题跟踪单'>
            <span>
              <Icon name='bangzhu' />
            </span>
          </Tooltip>
          <HFormInput required label='风险状态' bind='riskStatus' options={riskStatusOptions} component={isDetail ? Display : Select} convert={v => convertOptions(v, riskStatusOptions)} />
          <HFormInput required label='风险等级' bind='riskRating' options={rankOptions} component={isDetail ? Display : Select} convert={v => convertOptions(v, rankOptions)} />
          <HFormInput label='是否展示首页' bind='isHomePage' options={whetherOptions} component={isDetail ? Display : Select} convert={v => convertOptions(v, whetherOptions)} />
          <HFormInput required multiple label='责任人' bind='responsiblePerson' component={isDetail ? Display : UserSelect} convert={v => {
            if (_.isNil(v)) return null
            let users = []
            users = v
            if (typeof v === 'string') users = _.split(v, ',')
            return _.join(_.map(users, val => convertOptions(val, allUserRes, 'userName', 'userAccount')), '，')
          }} />
          <HFormInput required label='计划关闭时间' bind='plannedClosingTime' component={isDetail ? Display : DatePicker} bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
            convert={v => {
              if (_.isNil(v)) return '-'
              if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
              return dateFormat('YYYY-MM-DD', v) || '-'
            }} />
          <HFormInput required label='风险描述' bind='riskDesc' className={isDetail ? 'text-area-input-detail' : 'text-area-input-edit'} component={isDetail ? Display : TextAreaInput} componentWidth={481} />
          <HFormInput required label='风险影响' bind='riskImpact' className={isDetail ? 'text-area-input-detail' : 'text-area-input-edit'} component={isDetail ? Display : TextAreaInput} componentWidth={481} />
          <HFormInput label='规避措施' bind='dodgeMeasure' className={isDetail ? 'text-area-input-detail' : 'text-area-input-edit'} component={isDetail ? Display : TextAreaInput} componentWidth={481} />
          <HFormInput label='应急预案' bind='contingencyPlan' className={isDetail ? 'text-area-input-detail' : 'text-area-input-edit'} component={isDetail ? Display : TextAreaInput} componentWidth={481} />
        </Form>
      </div>
      <div className="mock-footer flex center-y">
        <div />
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              close()
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary disable={!canSubmit} onClick={submit} >确认</Button>
          }
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, Messager } from 'rootnet-ui'
import { FormInput, Form } from 'rootnet-edit'
import './LeaveDialog.scss'
import _ from 'lodash'
import { useApi } from "../../../../utils/hook";
import WaterMarkDialog from "../../../common/WaterMarkDialog";
const INIT_FORM_DATA = {
    auditUseTime: null
}
function LeaveDialog(props) {
    const { close, date, jobTypeList, refresh, usedTime, cardDetail, beforeCutoffDay, isSelf, showCutoffDay } = props
    const leftMenu = useMemo(() => _.get(_.find(jobTypeList, x => x.name === '休假'), 'children'), [jobTypeList])
    const isAdd = useMemo(() => _.isNil(cardDetail), [cardDetail])
    const initFormData = useMemo(() => isAdd ? INIT_FORM_DATA : { auditUseTime: cardDetail.auditUseTime / 60 }, [isAdd, cardDetail])
    const initActiveIndex = useMemo(() => {
        return isAdd ? 0 : _.findIndex(leftMenu, x => x.name === cardDetail.content)
    }, [cardDetail, leftMenu, isAdd])

    const [activeIndex, setActiveIndex] = useState(initActiveIndex)
    const dateNoLine = useMemo(() => date.replace(/-/g, ''), [date])
    const [formData, setFormData] = useState(initFormData)
    const [error, setError] = useState(null)
    const { doFetch } = useApi()
    const [showContinueDialog, setShowContinueDialog] = useState(false)
    const [countTime, setCountTime] = useState(0)

    useEffect(() => {
        let auditUseTime = _.toNumber(_.get(formData, 'auditUseTime'))
        if (auditUseTime <= 0) {
            setError(x => _.assign({}, x, { 'auditUseTime': '实际用时需大于0' }))
            return
        }
        if (!isAdd) {
            auditUseTime = auditUseTime - cardDetail.auditUseTime / 60
        }
        if (!_.isNil(auditUseTime) && ((auditUseTime * 60 + usedTime + countTime * 60) > 8 * 60)) {
            setError(x => _.assign({}, x, { 'auditUseTime': '每天用时不能大于8小时' }))
            // return
        }
    }, [formData, usedTime, cardDetail, isAdd, countTime])

    return <div>
        <WaterMarkDialog cancel={close} confirm={submit} header={`休假（${date}）`} className='leave-dialog'
            confirmButtonVisible={isSelf}>
            <div className="left-menu">
                {
                    _.map(leftMenu, (leftMenuItem, leftMenuIndex) => {
                        return <div key={leftMenuItem.id} onClick={() => setActiveIndex(leftMenuIndex)} className={`left-menu-item ${leftMenuIndex === activeIndex ? 'active' : ''}`}>
                            <div className="left-item-text">
                                {leftMenuItem.name}
                            </div>
                        </div>
                    })
                }
            </div>
            <div className='right-content'>
                <Form error={error} onError={setError} value={formData} onChange={handleChangeFormData}>
                    <FormInput label='实际用时(h)' bind='auditUseTime' horizontal componentWidth={180} labelWidth={100} min={0.1} required type='number' digit={1} />
                </Form>
            </div>
        </WaterMarkDialog>
        {
            showContinueDialog &&
            <Dialog headerVisible={false} confirm={clear} cancel={exit} className={'content-center-dialog'}>
                增加成功，是否继续增加？
            </Dialog>
        }
    </div>

    function handleChangeFormData(formObj, bind) {
        if (bind === 'auditUseTime' && _.toNumber(_.get(formObj, 'auditUseTime')) > 8) {
            return Messager.show('实际用时上限为8h')
        }
        setFormData(formObj)
    }

    function clear() {
        const auditUseTime = _.toNumber(formData.auditUseTime)
        setCountTime(x => x + auditUseTime)
        setShowContinueDialog(false)
        setFormData(INIT_FORM_DATA)
    }

    function exit() {
        setShowContinueDialog(false)
        close()
    }

    function check() {
        if (_.some(_.values(error), x => x)) {
            const errMsg = _.find(_.values(error), x => !_.isNil(x))
            Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
            return false
        }
        const auditUseTime = _.toNumber(formData.auditUseTime)
        if (auditUseTime <= 0) {
            Messager.show('实际用时需大于0', { icon: 'error' })
            return false
        }
        return true
    }

    function submit() {
        if (beforeCutoffDay) return Messager.show(`${showCutoffDay}前的成本已核算，不允许修改工时`, { icon: 'error' })
        if (!check()) return
        const params = {
            jobType: leftMenu[activeIndex].id,
            workDate: dateNoLine,
            content: leftMenu[activeIndex].name,
            auditUseTime: formData.auditUseTime * 60,
            status: isAdd ? 'approve' : null,
            actionType: "ADD",
        }
        const postParams = isAdd ? [params] : [
            _.assign({}, params, {
                id: cardDetail.id,
                actionType: "EDIT"
            })
        ]
        doFetch('/worktime/edit', 'post', postParams).then(() => {
            Messager.show(`${isAdd ? '添加' : '修改'}成功`, { icon: 'success' })
            refresh()
            if (isAdd) {
                setShowContinueDialog(true)
                return
            }
            close()
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
        })
    }
}

export default LeaveDialog;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './UserDeptGroupSelectPop.scss'
import useGet from "rootnet-biz/es/hooks/useGet";
import usePost from "rootnet-biz/es/hooks/usePost";
import _ from "lodash";
import {isNil} from "rootnet-core/format";
import {buildUserDeptTree} from "../personSelectPop/buildUserDeptTree";
import {Button, Messager} from "rootnet-ui";
import {CheckBox, Input} from "rootnet-edit";
import Icon from "../../../components/Icon";
import {Empty, Spin, Tabs, Tree} from "antd";
import PersonSelectUserItem from "../personSelectPop/PersonSelectUserItem";
import UserDeptGroupItem from "./userDeptGroupItem/UserDeptGroupItem";
import {buildUserGroupTree} from "./buildUserGroupTree";
import convertOptions from "../ConvertOptions";
import buildRoleData from "./buildRoleData";

const { TabPane } = Tabs;

const initData = {
    user: [],
    department: [],
    group: [],
    role: [],
}

const KEY_MAP = {
    'user': 'userAccount',
    'department': 'departMentId',
    'group': 'id',
    'role': 'id',
}

function UserDeptGroupSelectPop(props) {
    const { onSelectConfirm, setVisible, hiddenUserList, multiple, allowClear, mustUserList, allowDeptList, allowUserList,
        canSelectDept, canSelectGroup, canSelectRole } = props
    const [activeTab, setActiveTab] = useState('recent')
    const { data: allUserRes, doFetch: getAllUserRes, loading: allUserLoading } = useGet()
    const [selectValue, setSelectValue] = useState()
    const { data: allDeptRes, loading: allDeptLoading } = useGet('/common/department?activeFlag=Y')
    const { data: allGroupRes, loading: allGroupLoading } = useGet('/uac/user/group/get/all?status=0')
    const { data: originalRoleRes, loading: allRoleLoading } = useGet('/uac/role/get/all/role')
    const [expandedDeptKeys, setExpandedDeptKeys] = useState([])
    const [searchText, setSearchText] = useState()
    const [showResignedUser, setShowResignedUser] = useState(false)
    const { doFetch: sendRecent } = usePost()
    const [expandedGroupKeys, setExpandedGroupKeys] = useState([])

    const allRoleRes = useMemo(()=>{
        return _.filter(originalRoleRes, x => x?.status === 0)
    },[originalRoleRes])

    const isEmptyValue = useMemo(()=>{
        if(_.isNil(selectValue)) return true
        if(!_.isEmpty(_.get(selectValue,'user'))) return false
        if(!_.isEmpty(_.get(selectValue,'department'))) return false
        if(!_.isEmpty(_.get(selectValue,'group'))) return false
        if(!_.isEmpty(_.get(selectValue,'role'))) return false
        return true
    },[selectValue])

    const showDeptRes = useMemo(()=>{
        if(_.isEmpty(allowDeptList)) return allDeptRes
        let showDeptList = []
        _.forEach(allowDeptList, x => findParentDept(x))
        function findParentDept(id){
            if(_.includes(showDeptList, id)) return
            showDeptList.push(id)
            const parentId = _.get(_.find(allDeptRes, x => x.departMentId === id),'parentDept')
            if(!isNil(parentId)){
                findParentDept(parentId)
            }
        }
        return _.filter(allDeptRes, x => _.includes(showDeptList, x.departMentId))
    },[allowDeptList, allDeptRes])

    // 在职人员
    const staffRes = useMemo(()=>{
        return _.filter(allUserRes, x => _.get(x, 'indexValidFlag') === 1)
    },[allUserRes])

    // 用户选择显示的所有人员
    const showAllUserRes = useMemo(()=>{
        return showResignedUser ? allUserRes : staffRes
    },[showResignedUser, allUserRes, staffRes])

    const selectedUserAccountList = useMemo(()=>{
        return _.map(_.get(selectValue, 'user'),'userAccount') || []
    },[selectValue])

    const selectedDeptIdList = useMemo(()=>{
        return _.map(_.get(selectValue, 'department'),'departMentId') || []
    },[selectValue])

    const selectedGroupIdList = useMemo(()=>{
        return _.map(_.get(selectValue, 'group'),'id') || []
    },[selectValue])

    const selectedRoleIdList = useMemo(()=>{
        return _.map(_.get(selectValue, 'role'),'id') || []
    },[selectValue])

    const filterAllUser = useMemo(() => {
        const filterNoHiddenList = _.isEmpty(hiddenUserList) ? showAllUserRes  : _.filter(showAllUserRes, x => !_.includes(hiddenUserList, x.userAccount))
        let filterAllowList = []
        if(_.isEmpty(allowDeptList) && _.isEmpty(allowUserList)){
            filterAllowList = filterNoHiddenList
        }else{
            filterAllowList = _.filter(filterNoHiddenList, x => _.includes(allowDeptList, x.department) || _.includes(allowUserList, x.userAccount))
        }
        return filterAllowList
    }, [hiddenUserList, showAllUserRes, allowDeptList, allowUserList])

    const filterAllStaff = useMemo(() => {
        const filterNoHiddenList = _.isEmpty(hiddenUserList) ? staffRes  : _.filter(staffRes, x => !_.includes(hiddenUserList, x.userAccount))
        let filterAllowList = []
        if(_.isEmpty(allowDeptList) && _.isEmpty(allowUserList)){
            filterAllowList = filterNoHiddenList
        }else{
            filterAllowList = _.filter(filterNoHiddenList, x => _.includes(allowDeptList, x.department) || _.includes(allowUserList, x.userAccount))
        }
        return filterAllowList
    }, [hiddenUserList, staffRes, allowDeptList, allowUserList])

    useEffect(() => {
        if (_.isEmpty(allUserRes)) return
        if (_.isNil(props.defaultValue)) return
        setSelectValue(()=>{
            const user = _.filter(allUserRes, x => _.includes(getTypeValue('user',props.defaultValue), x.userAccount))
            const department = _.filter(allDeptRes, x => _.includes(getTypeValue('department',props.defaultValue), x.departMentId))
            const group = _.filter(allGroupRes, x => _.includes(getTypeValue('group',props.defaultValue), x.id))
            const role = _.filter(allRoleRes, x => _.includes(getTypeValue('role',props.defaultValue), x.id))
            return {
                user,
                department,
                group,
                role,
            }
        })
    }, [props.defaultValue, allUserRes, allDeptRes, allGroupRes, allRoleRes])

    useEffect(() => {
        getAllUserRes('/common/userinfo')
    }, [getAllUserRes])

    const onSelect = useCallback((selectedItem, type = 'user') => {
        if (multiple) {
            setSelectValue(old => {
                const oldIdList = _.map(_.get(old, type), KEY_MAP[type])
                if (_.includes(oldIdList, _.get(selectedItem, KEY_MAP[type]))) {
                    return old
                } else {
                    const newObj = {
                        ...old,
                    }
                    newObj[type] = _.compact(_.concat(_.get(old, type), [selectedItem]))
                    return newObj
                }
            })
        } else {
            const newValue = {
                ...initData
            }
            newValue[type] = [selectedItem]
            setSelectValue(newValue)
        }
    }, [multiple])

    const onClear = useCallback((clearKey, type = 'user') => {
        setSelectValue(old => {
            const cloneObj = _.clone(old)
            cloneObj[type] = _.filter(_.get(old,type), x => _.get(x,KEY_MAP[type]) !== clearKey)
            return cloneObj
        })
    }, [])

    const onDeptNodeClick = useCallback((key) => {
        setExpandedDeptKeys(oldList => {
            if (_.includes(oldList, key)) {
                return _.filter(oldList, x => x !== key)
            } else {
                return [...oldList, key]
            }
        })
    }, [])

    const onGroupNodeClick = useCallback((key) => {
        setExpandedGroupKeys(oldList => {
            if (_.includes(oldList, key)) {
                return _.filter(oldList, x => x !== key)
            } else {
                return [...oldList, key]
            }
        })
    }, [])

    const getAllChildDeptId = useCallback((deptId)=>{
        let deptIdList = [deptId]
        findChildNode(deptId)
        function findChildNode(pid){
            const childDepartmentList = _.filter(showDeptRes, x => x.parentDept === pid)
            _.forEach(childDepartmentList, x => {
                deptIdList.push(x.departMentId)
                findChildNode(x.departMentId)
            })
        }
        return deptIdList
    },[showDeptRes])

    const onDeptAddClick = useCallback((e, clickDeptId)=>{
        e.stopPropagation()
        const addDeptList = getAllChildDeptId(clickDeptId)
        const allUserInDept = _.filter(staffRes, x => _.includes(addDeptList, x.department))
        setSelectValue(old => {
            const newUserList = _.compact(_.concat(_.get(old,'user'),allUserInDept))
            return {
                ...old,
                user: _.uniqBy(newUserList, x => x.userAccount)
            }
        })
    },[staffRes, getAllChildDeptId])

    const allUserSelectedInDept = useCallback((deptId)=>{
        if(!multiple) return false
        const allDeptList = getAllChildDeptId(deptId)
        const allUserInDept = _.filter(staffRes, x => _.includes(allDeptList, x.department))
        const allUserInDeptUserAccount = _.map(allUserInDept, 'userAccount')
        const selectedUserAccount = _.map(_.get(selectValue,'user'), 'userAccount')
        return _.every(allUserInDeptUserAccount, x => _.includes(selectedUserAccount,x))
    },[getAllChildDeptId,selectValue, multiple,staffRes])

    const deptUserTreeData = useMemo(() => {
        return buildUserDeptTree({
            userRes:filterAllStaff,
            deptRes:showDeptRes,
            onNodeClick: onDeptNodeClick,
            onSelect,
            selectedUserAccountList,
            onDeptAddClick,
            multiple,
            allUserSelectedInDept,
            canSelectDept,
            selectedDeptIdList
        })
    }, [filterAllStaff, showDeptRes, onDeptNodeClick, onSelect, selectedUserAccountList, onDeptAddClick, multiple,
        allUserSelectedInDept, selectedDeptIdList, canSelectDept])

    const userGroupTreeData = useMemo(()=>{
        return buildUserGroupTree({
            userRes:filterAllStaff,
            allGroupRes,
            onNodeClick: onGroupNodeClick,
            onSelect,
            selectedUserAccountList,
            selectedGroupIdList,
            canSelectGroup,
        })
    },[filterAllStaff, allGroupRes, selectedUserAccountList, onSelect, selectedGroupIdList, onGroupNodeClick, canSelectGroup])

    const roleData = useMemo(()=>{
        return buildRoleData({
            allRoleRes,
            onSelect,
            selectedRoleIdList
        })
    },[allRoleRes, onSelect, selectedRoleIdList])

    const showUserList = useMemo(() => {
        if (!searchText) return filterAllUser
        setActiveTab('all')
        return _.filter(filterAllUser, user => {
            return _.includes(user.userName, searchText) || _.includes(user.userAccount, searchText) || _.includes(user.departmentAllPath, searchText) || user.userId === searchText
        })
    }, [searchText, filterAllUser])

    const recentUserList = useMemo(() => {
        const filterRecentUserList = _.filter(filterAllUser, x => !_.isNil(x.recentFlag))
        return _.slice(_.reverse(_.sortBy(filterRecentUserList, 'recentFlag')),0,10)
    }, [filterAllUser])

    const onConfirm = useCallback(() => {
        const confirmItem = []
        confirmItem.push({
            type: 'user',
            valueList: _.map(_.get(selectValue, 'user'),'userAccount')
        })
        confirmItem.push({
            type: 'department',
            valueList: _.map(_.get(selectValue, 'department'),'departMentId')
        })
        confirmItem.push({
            type: 'group',
            valueList: _.map(_.get(selectValue, 'group'),'id')
        })
        confirmItem.push({
            type: 'role',
            valueList: _.map(_.get(selectValue, 'role'),'id')
        })
        onSelectConfirm(isEmptyValue? null: confirmItem)
        if(_.isEmpty(_.map(_.get(selectValue, 'user'),'userAccount'))){
            setVisible(false)
        }else{
            sendRecent('/test_defect/common/people', { current_owners: _.map(_.get(selectValue, 'user'),'userAccount') })
                .then(() => {
                    setVisible(false)
                }).catch(err => {
                setVisible(false)
                Messager.show(err.Message, { icon: 'error' })
            })
        }
    }, [selectValue, onSelectConfirm, sendRecent, setVisible, isEmptyValue])

    return <div className={'user-dept-group-select-pop flex-y'}>
        <Input autoFocus className={'person-search'} placeholder={'请搜索人员'} value={searchText} onChange={setSearchText}
               suffix={<Icon name={'sousuo1'} className={'person-search-icon'} />} />
        <div className="person-select-main-panel flex">
            <div className="person-select-left">
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab="全部" key="all">
                        <div className={'all-user flex-y'}>
                            {
                                allUserLoading && <Spin />
                            }
                            {
                                _.map(showUserList, userInfo =>
                                    <PersonSelectUserItem key={`all-${_.get(userInfo, 'userId')}`} {...{ userInfo, onSelect,selectedUserAccountList }} />)
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="常用" key="recent">
                        <div className={'recent-user flex-y'}>
                            {
                                _.map(recentUserList, userInfo =>
                                    <PersonSelectUserItem key={`all-${_.get(userInfo, 'userId')}`} {...{ userInfo, onSelect,selectedUserAccountList }} />)
                            }
                            {
                                allUserLoading && <Spin />
                            }
                            {
                                _.isEmpty(recentUserList) && !allUserLoading &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="部门" key="dept">
                        <div className="dept-user-warp flex">
                            {
                                allDeptLoading && <Spin />
                            }
                            <Tree treeData={deptUserTreeData}
                                  onExpand={setExpandedDeptKeys}
                                  expandedKeys={expandedDeptKeys}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="用户组" key="group">
                        <div className="dept-user-warp flex">
                            {
                                allGroupLoading && <Spin />
                            }
                            <Tree treeData={userGroupTreeData}
                                  onExpand={setExpandedGroupKeys}
                                  expandedKeys={expandedGroupKeys}
                            />
                        </div>
                    </TabPane>
                    {
                        canSelectRole &&
                        <TabPane tab="角色" key="role">
                            {
                                allRoleLoading && <Spin />
                            }
                            <div className="dept-user-warp flex">
                                <Tree treeData={roleData}/>
                            </div>
                        </TabPane>
                    }
                </Tabs>
            </div>
            <div className="person-select-right flex-y">
                <div className="person-select-right-header flex center-y">
                    <div className="person-selected-num-wrap flex">
                        已选
                    </div>
                    {
                        allowClear && <div className="clear-all-selected" onClick={() => setSelectValue(initData)}>
                            清空所选
                        </div>}
                </div>
                <div className="person-select-right-content flex-y">
                    {
                        isEmptyValue &&
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                    {
                        _.map(_.get(selectValue,'user'), userInfo =>
                            <PersonSelectUserItem key={`selected-${_.get(userInfo, 'userId')}`} isRemove={!_.includes(mustUserList,_.get(userInfo, 'userAccount'))} {...{ userInfo, onSelect, onClear }} />)
                    }
                    {
                        _.map(_.get(selectValue,'department'), deptInfo =>
                            <UserDeptGroupItem type={'department'} key={_.get(deptInfo,'departMentId')} value={_.get(deptInfo,'departMentId')} text={_.get(deptInfo,'departMentName')}
                                               icon={'zengjiazibumen'} {...{ onClear }}/>)
                    }
                    {
                        _.map(_.get(selectValue,'group'), groupInfo =>
                            <UserDeptGroupItem type={'group'} key={_.get(groupInfo,'id')} value={_.get(groupInfo,'id')} text={_.get(groupInfo,'groupName')}
                                               icon={'tuandui'} {...{ onClear }}/>)
                    }
                    {
                        _.map(_.get(selectValue,'role'), roleInfo =>
                            <UserDeptGroupItem type={'role'} key={_.get(roleInfo,'id')} value={_.get(roleInfo,'id')} text={_.get(roleInfo,'roleName')}
                                               icon={'geren'} {...{ onClear }}/>)
                    }
                </div>
            </div>
        </div>
        <div className="confirm-footer flex center-y">
            <div className="confirm-footer-left">
                <CheckBox value={showResignedUser} onChange={setShowResignedUser}>显示离职人员</CheckBox>
            </div>
            <div className="confirm-footer-right flex">
                <Button normal onClick={() => {
                    setVisible(false)
                }}>取消</Button>
                <Button primary onClick={onConfirm}>确定</Button>
            </div>
        </div>
    </div>
}

function getTypeValue(type, data){
    return convertOptions(type,data,'valueList','type')
}

export default UserDeptGroupSelectPop;
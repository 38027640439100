import React, { useState, useEffect, useReducer, useCallback } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { Button, Popconfirm } from 'antd'
import { Dialog, Loader, Button as RootBtn } from 'rootnet-ui'
import { DownloadOutlined } from '@ant-design/icons'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'
import { successTips, errorTips } from '../../../../../utils/messagerTips'
import FileUpload from '../../../../common/fileUpload/FileUpload'
import { isNil } from '../../../../appraise/components/method'
import { strParams } from '../../../../../utils/publicFun'
import { Icon } from '../../../../../components'
import './index.scss'
import convertOptions from '../../../../common/ConvertOptions'
import { gd } from 'rootnet-core/base'

const COMMENT_SECTION_INFO_URL = '/messageRecord/list' // 评论区信息
const COMMENT_ADD_URL = '/messageRecord/add' // 添加评论
const COMMENT_DEL_URL = '/messageRecord/delete' // 删除评论

export default function FeedbackMessageDialog(props) {
  const { id, close, allUserRes, isEdit } = props
  const [comment, setComment] = useState()
  const { data, loading: dataLoading, doFetch } = useGet()
  const { doFetch: submitFetch } = usePost()
  const [isDisable, setIsDisable] = useState(false)
  const [isDelDisable, setIsDelDisable] = useState(false)
  const [files, setFiles] = useState([])
  const [fileCount, setFileCount] = useState(0)
  const [countFile, updateFile] = useReducer((x) => x + 1, 60000)

  const refresh = useCallback(() => {
    if (_.isNil(id)) return
    doFetch(`${COMMENT_SECTION_INFO_URL}?${strParams({ referenceId: id })}`)
      .catch(err => errorTips(err._message))
  }, [id, doFetch])

  const submit = useCallback(() => {
    if (isNil(_.trim(comment))) return errorTips('请填写留言后在进行操作！')
    if (isDisable) return
    setIsDisable(true)
    const urlList = JSON.stringify(files)
    const postParams = { referenceId: id, comment, urlList, jumpUrl: `/serviceOrderMgt?initId=${id}` }
    submitFetch(COMMENT_ADD_URL, postParams)
      .then(() => {
        updateFile()
        setComment('')
        setFiles([])
        refresh()
        successTips('发布成功！')
        setIsDisable(false)
      })
      .catch(err => {
        setIsDisable(false)
        errorTips(err._message)
      })
  }, [id, files, comment, refresh, isDisable, submitFetch])

  const delCommentSectionItem = useCallback((id) => {
    if (isDelDisable) return
    setIsDelDisable(true)
    submitFetch(`${COMMENT_DEL_URL}?id=${id}`)
      .then(() => {
        refresh()
        successTips('删除成功！')
        setIsDelDisable(false)
      })
      .catch(err => {
        setIsDelDisable(false)
        errorTips(err._message)
      })
  }, [isDelDisable, submitFetch, refresh])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <Dialog
      // loading={loading}
      className='feedback-message-dialog'
      headerVisible={false}
      footerVisible={false}
      cancel={close}
    >
      <div className='feedback-message-dialog-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title flex">
            反馈留言
            <div className='title-hint'>客户可在根网客户服务平台，同步可以看到反馈留言</div>
          </div>
          <div className="mock-right-header flex center-y">
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <div className="content-main-panel">
          <div className='feedback-message-comment'>
            {
              isEdit &&
              <div className='publish-comment'>
                <TextAreaInput value={comment} onChange={setComment} maxLength={250} showCount />
                <div key={countFile} className='upload-files'>
                  <Button
                    size='small'
                    type='primary'
                    onClick={submit}
                    loading={isDisable}>
                    发布
                  </Button>
                  <FileUpload funcCode={'1801'} {...{ id, mode: 'edit', setFiles, setFileCount, allUserRes, isUpload: fileCount <= 10, isComment: true, }} />
                </div>
              </div>
            }
            <div className='comment-section'>
              {dataLoading && <Loader fill />}
              <CommentSectionItem {...{ data, allUserRes, delCommentSectionItem }} />
            </div>
          </div>
        </div>
        <div className="mock-footer flex center-y">
          <div />
          <div className="btn-group flex">
            <RootBtn normal onClick={close}>关闭</RootBtn>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function CommentSectionItem(props) {
  const { data, allUserRes, delCommentSectionItem } = props
  return _.map(_.orderBy(data, 'createTime', 'desc'), (item, i) => {
    const isRoot = _.get(item, 'rootFlag') === 'Y'
    const urlList = JSON.parse(_.get(item, 'urlList', null))
    return <div key={i} className={clsx('comment-section-item flex')}>
      {/* 左侧头像 */}
      <div className='head-portrait'>
        {/* 客户头像 */}
        {
          !isRoot &&
          <div className='client'><Icon name='putongyonghu' /></div>
        }
        {/* 根网客服 */}
        {
          isRoot &&
          <div className='img' />
        }
      </div>
      {/* 右侧内容 */}
      <div className='detail-content'>
        {/* 头部-> 名称 评论时间 */}
        <div className='header flex'>
          <div className='user-name'>
            {/* 客户名 */}
            {!isRoot && _.get(item, 'userId')}
            {/* 根网客服 */}
            {isRoot && `根网-${convertOptions(_.get(item, 'userId'), allUserRes, 'userName', 'userAccount')}`}
          </div>
          <div className='time'>
            {
              gd?.User?.operator_id === _.get(item, 'userId') &&
              <Popconfirm
                title='提示'
                overlayClassName='customer-contact-del-item'
                description='确认删除么？'
                onConfirm={() => delCommentSectionItem(_.get(item, 'id'))}
              >
                <Icon name='shanchu2' className='icon-del' />
              </Popconfirm>
            }
            {_.get(item, 'createTime')}
          </div>
        </div>
        {/* 评论内容 */}
        <div className='message'>{_.get(item, 'comment')}</div>
        {/* 文件内容 */}
        {
          _.isArray(urlList) && !_.isEmpty(urlList) &&
          <div className='file'>
            {
              _.map(urlList, (o, key) => {
                return <div
                  key={key}
                  className='file-itmes flex'
                  onClick={() => onDownload({ url: o.url })}
                >
                  <div className='file-text'>{o.name}</div>
                  <DownloadOutlined />
                </div>
              })
            }
          </div>
        }
      </div>
    </div>
  })
}

function onDownload(file) {
  const url = _.get(file, 'url')
  window.open(url, "_self")
}

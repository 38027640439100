import React, {useEffect, useMemo, useState} from 'react';
import {Dialog, DataGrid, Pagination,Tooltip} from 'rootnet-ui'
import {Select} from "rootnet-edit";
import {Box} from "../../../common/commonComponent";
import {useGet} from "../../../../utils/hook";
import {strParams} from "../../../../utils/publicFun";
import _ from "lodash";
import {toDate, dateFormat} from 'rootnet-core/dateFormat'

const { str14ToDate } = toDate;
const getColumns = (props) => {
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '是否在视图中', bind: 'exist',sortable: true,width: 100},
        {header: '操作类型', bind: 'actionType',sortable: true,width: 80},
        {header: '操作人', bind: 'username',sortable: true,width: 75},
        {header: '操作时间', bind: 'inputTime',sortable: true,width: 150,convert: convertDate},
        {header: '类型', bind: 'detailType',sortable: true,width: 80},
        {header: '项目名称', bind: 'projectName',sortable: true,width: 140, tooltip: true},
        {header: 'ID', bind: 'id',sortable: true,width: 160, tooltip: true},
        {header: '任务清单', bind: 'shortDesc',width: 150,convert: convertShortDesc},
        {header: '负责人', bind: 'principal',sortable: true,width: 75},
    ]
    function convertShortDesc(v){
        return <Tooltip title={v}>
            <span className='short-desc'>{v}</span>
        </Tooltip>
    }
    function convertDate(v){
        return dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    }
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
});

const INIT_PARAMS = {
    pageNum: 1,
    pageSize: 20
}

const typeOptions = [
    {value: 'All', text: '全部'},
    {value: 'Add', text: 'Add'},
    {value: 'Del', text: 'Del'},
]

function LogDialog(props) {
    const {close, viewId} = props
    const [params, setParams] = useState(INIT_PARAMS)
    const [type, setType] = useState('All')
    const {data: listRes, doFetch: getList, loading, error} = useGet()

    const { pageSize, total, pageNum, list, add, del, allTotal } = useMemo(()=>(listRes || {}),[listRes]);

    useEffect(()=>{
        const getParams = {...params,type}
        getList(`/viewListChange/getListChangeData?viewId=${viewId}&`+strParams(getParams))
    },[params, getList, viewId, type])

    const option = useMemo(()=>{
        return getOption(getColumns())
    },[])

    return <Dialog header='清单变动' cancel={close} confirm={close}
                   confirmButtonText='关闭' cancelButtonVisible={false} className='log-dialog' contentClassName='flex-y'>
        <div className="log-header flex">
            <div className="header-left flex">
                <div className="log-sum">共有纪录：{allTotal}</div>
                <div className="log-sum">Add：{add}</div>
                <div className="log-sum">Del：{del}</div>
            </div>
            <div className="selection">
                <Select value={type} onChange={v => {setType(v);setParams(INIT_PARAMS)}} options={typeOptions}/>
            </div>
        </div>
        <div className="main-panel flex-y">
            <Box data={list} className='flex-y x-card-singlegrid' loading={loading} error={error}>
                <DataGrid option={option} data={list}/>
                <Pagination pageSize={ pageSize } total={ total } current={ pageNum }
                            onChange={ (pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize })) }/>
            </Box>
        </div>
    </Dialog>
}

export default LogDialog;
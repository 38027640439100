import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import TextAreaInput from '../../../../../../../common/textAreaInput/TextAreaInput'
import './index.scss'

const DEL_URL = '/ReleaseStory/addReleaseStoryList'

export default function DelVersionItem(props) {
  const { close, selection, setSelection, refreshList, releaseid } = props
  const [remark, setRemark] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (_.size(_.trim(remark)) === 0) return Messager.show('请填写说明信息', { icon: 'error' })
    if (isDisabled) return
    setIsDisabled(true)
    const urlAndParamsArr = getTypeParams({ data: selection, remark, releaseid })
    doFetch(DEL_URL, urlAndParamsArr)
      .then(res => {
        setSelection([])
        setIsDisabled(false)
        refreshList()
        close()
        Messager.show('移除成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err, { icon: 'error' })
      })
  }, [isDisabled, doFetch, close, refreshList, selection, setSelection, remark, releaseid])

  return (
    <Dialog
      className='dev-project-del-version-item'
      header={<span className='header-style'>移除{'版本'}
        <span>（已选{_.size(selection)}个）</span>
        <span>版本清单中的缺陷，跟随项目的设置，不允许版本中做移除操作！</span>
      </span>}
      confirm={confirm}
      cancel={close}
    >
      <FormInput className='text-area-input-edit' horizontal required component={TextAreaInput} bind='remark' label='说明' componentWidth={440} value={remark} onChange={setRemark} />
    </Dialog>
  )
}

function getTypeParams({ data, releaseid, remark }) {
  const filterReqData = _.filter(data, o => o.taskType === 'REQ')
  const filterIssueData = _.filter(data, o => o.taskType === 'ISSUE')
  const filterDefectData = _.filter(data, o => o.taskType === 'DEFECT')
  const paramsArr = _.compact([
    !_.isEmpty(filterReqData) && { type: 'REQ', optionType: 'DEL', releaseid, storyidList: _.map(filterReqData, 'id'), remark },
    !_.isEmpty(filterIssueData) && { type: 'ISSUE', optionType: 'DEL', releaseid, storyidList: _.map(filterIssueData, 'id'), remark },
    !_.isEmpty(filterDefectData) && { type: 'DEFECT', optionType: 'DEL', releaseid, storyidList: _.map(filterDefectData, 'id'), remark },
  ])
  return paramsArr
}

import React, { useCallback } from 'react'
import { Form, Select, FormInput } from 'rootnet-edit'
import { isNil } from '../../../appraise/components/method'

const HFormInput = props => <FormInput horizontal labelWidth={70} componentWidth={250} {...props} />

export default function ExclusionCurrentUser(props) {
  const { formData, setFormData, exclusionNodeOptions } = props

  const handChange = useCallback((o, bind) => {
    if (bind === 'exclusionFactor' && isNil(o['exclusionFactor'])) return setFormData(null)
    setFormData(o)
  }, [setFormData])

  return (
    <div className='work-flow-exclusion-current-user flex-y'>
      <Form value={formData} onChange={handChange}>
        <HFormInput
          clear
          search
          label={'互斥节点'}
          component={Select}
          bind={'exclusionFactor'}
          options={exclusionNodeOptions}
        />
      </Form>
    </div>
  )
}

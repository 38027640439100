import React, { useMemo, useState, useCallback, useEffect, useReducer, Fragment } from 'react';
import _ from "lodash";
import { Tooltip } from 'antd'
import { isNil } from "rootnet-core/format";
import { DataGrid, Pagination } from "rootnet-ui";
import { useGet, usePost } from 'rootnet-biz/lib/hooks';
import convertTableAlign from '../../../../common/view/convertTableAlign';
import findConvert from '../../../../common/view/findConvert';
import { Icon } from '../../../../../components';
import { TextIconBtn } from '../../../../common/TextIconBtn';
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig';
import { useFuncCode } from '../../../../common/commonMethod';
import useRefreshList from '../../../../common/view/hooks/useRefreshList';
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions';
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList';
import { API1 } from '../../../../../base/task'
import FollowMattersDetail from '../../../../common/ChangeRecord/components/followMattersDetail';
import TodoListDetailDialog from '../../../../todolist/components/todoListDetailDialog';
import { Box } from '../../../../common/commonComponent';
import ViewArea from '../../../../common/view/ViewArea';
import './index.scss'

const FUNC_CODE = '3666'
const DLE_CODE = '2301'
const ENTER_DETAIL_FIELD = 'subject'
const EDITABLE_FIELD = ['subject']

const STATUS_URL = [
  '/common/globalconst?globalConst=FollowUpStatus',
  '/common/globalconst?globalConst=todoOverDue',
]

const STATUS_COLOR = {
  '01': '#0285BD', //未进行
  '02': "#24B47E", //完成
  '03': '#AAAAAA', //挂起
  '04': '#FF0000', //逾期未完成
  '05': '#FFBF00', //逾期完成
  '06': '#FF6600', //即将到期
  '07': '#A9A9A9', //关闭
}

const SPECIAL_FIELD_CONVERT = {
  'overdue': (value, showValue, { overdueOpt }) => {
    const backgroundColor = _.get(_.find(overdueOpt, o => o.interiorId === value), 'displayColor')
    return <div className={'status-field flex center'} >
      <div style={{ backgroundColor, borderRadius: '50%', width: 8, height: 8, marginRight: 8, }} />
      <div style={{ color: backgroundColor }}>{showValue}</div>
    </div>
  },
  'status': (value, showValue, { statusOpt }) => {
    const backgroundColor = _.get(_.find(statusOpt, o => o.interiorId === value), 'displayColor')
    return <div className={'status-field flex center'} style={{ backgroundColor, color: 'white', padding: '0px 8px', borderRadius: 4, width: 64 }}>
      {showValue}
    </div>
  },
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, onExpandIconClick, foldList, statusOpt, overdueOpt, } = props

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId === ENTER_DETAIL_FIELD ? false : true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }

  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue, { statusOpt, overdueOpt })
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    // if (fieldItem.fieldId === 'status') {
    //   const requireDate = o[_.find(fieldList, item => item.fieldId === 'requireDate')?.columnId]
    //   // const status = _.find(fieldList, item => item.fieldId === 'status')?.columnId
    //   return stateMatter({ requireDate, status: v })
    // }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'} style={{ width: '100%' }}>
        <div className="left-enter-field-wrap flex center-y" style={{ width: '100%' }}>
          {
            _.get(o, '_isParent') && !_.get(o, '_isEmptyChildren') &&
            <Icon name={_.includes(foldList, o.id) ? 'biaogezhankai' : 'biaogeshouqi'} className={'expand-icon'} onClick={() => onExpandIconClick(o.id)} />
          }
          {
            _.get(o, '_isParent') && _.get(o, '_isEmptyChildren') &&
            <div className={'empty-parent'} />
          }
          {
            !_.get(o, '_isParent') &&
            <div className={'empty-child'} />
          }
          {/* {
            _.get(o, '_isParent') &&
            <Tag color="blue">父</Tag>
          }
          {
            !_.get(o, '_isParent') &&
            <Tag color="gold">子</Tag>
          } */}
          <Tooltip title={showValue}>
            <div className="enter-detail-field" style={{ flex: 1 }} onClick={() => {
              setCurrentInfo({
                id: _.get(o, 'id'),
                mode: 'detail'
              })
            }}>
              {showValue || '-'}
            </div>
          </Tooltip>
        </div>
      </div>
    }

  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100
  }
}

export default function DevProjectTodolist(props) {
  const { projectId, updateDynamic, isShow } = props
  const [id, setId] = useState(null)
  const [currentInfo, setCurrentInfo] = useState(null)
  const [params, setParams] = useState()//_.assign(getInitParams(), !_.isNil(defaultParams) && defaultParams)
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [focusId, setFocusId] = useState()
  const [sortConfig, setSortConfig] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const [pageSize, setPageSize] = useState(100)
  const [onlyId, setOnlyId] = useState(null)
  const [factor, setFactor] = useState()
  const [upDate, setUpDate] = useReducer((x) => x + 1, 0)
  const [foldList, setFoldList] = useState([])
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const { doFetch: getOnlyId } = useGet()
  const [isDelCode] = useFuncCode([DLE_CODE])
  const { data: optionRes } = useGet(STATUS_URL)

  const [statusOpt, overdueOpt] = useMemo(() => {
    if (_.isEmpty(optionRes)) return []
    return optionRes
  }, [optionRes])


  const initSearchParams = useCallback(() => {
    getInitParams()
  }, [])

  const replaceParams = useMemo(() => {
    if (_.isNil(projectId)) return
    return ({ 'todoListRecord.relateId': projectId, })
  }, [projectId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

  const refreshLists = useCallback(() => {
    refreshList(replaceParams)
  }, [refreshList, replaceParams])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const onExpandIconClick = useCallback((id) => {
    setFoldList(oldList => {
      if (_.includes(oldList, id)) {
        return _.filter(oldList, x => x !== id)
      } else {
        return _.concat(oldList, [id])
      }
    })
  }, [])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, sortable: true,
    columnsAppendParams: { setCurrentInfo, onExpandIconClick, foldList, statusOpt, overdueOpt },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        if (_.isEmpty(data)) return []
        setSortConfig(sort)
        const sortAllList = data.sort((a, b) => sortFun(a, b, sort.column))
        const sortAllDirectionList = sort.direction === 'asc' ? sortAllList : _.reverse(_.clone(sortAllList))
        const sortFirstLevelDirectionList = _.filter(sortAllDirectionList, x => _.isNil(_.get(x, 'pid')))
        let handleList = []
        _.forEach(sortFirstLevelDirectionList, item => {
          const childrenList = _.filter(sortAllDirectionList, x => _.get(x, 'pid') === item.id)
          handleList.push(item)
          _.forEach(childrenList, x => {
            handleList.push(x)
          })
        })
        return handleList
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const handleList = useMemo(() => {
    let allList = showChildList || []
    // if(!_.isNil(sortConfig) && !isTree){
    //     const sortList = _.sortBy(showChildList, x => x[sortConfig.column])
    //     showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    // }
    const firstLevelList = _.filter(allList, x => _.isNil(_.get(x, 'pid')))
    let handleList = []
    _.forEach(firstLevelList, item => {
      let parentItem = { ...item }
      const childrenList = _.filter(allList, x => _.get(x, 'pid') === item.id)
      const childrenIdList = _.map(childrenList, x => x.id)
      parentItem = { ...parentItem, childrenIdList, _isParent: true, _isEmptyChildren: _.isEmpty(childrenList) }
      handleList.push(parentItem)
      _.forEach(childrenList, x => {
        handleList.push({ ...x, _isParent: false })
      })
    })
    return handleList
  }, [showChildList])

  const showList = useMemo(() => {
    return _.filter(handleList, x => !_.includes(foldList, _.get(x, 'pid')))
  }, [handleList, foldList])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      {isShow && <TextIconBtn onClick={() => setOpen(true)} icon='tianjia' text='新增' />}
    </>
  }, [exportViewList, isShow])

  useEffect(() => {
    if (open) getOnlyId('/test_case/productGetOnlyId').then(res => setOnlyId(res))
  }, [getOnlyId, open])

  return (
    <Fragment>
      <div className='dev-project-todolist'>
        <ViewArea
          funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList: refreshLists, total, optionsConfig, getInitParams: initSearchParams,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Box title={'问题跟踪'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <Table {...{ options, showList, dataGridList, focusId }} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
      </div>
      {
        (open && !_.isNil(onlyId)) &&
        <FollowMattersDetail
          id={onlyId}
          funcCode={'36'}
          mode={'add'}
          close={() => { setOpen(false); setOnlyId(null) }}
          refresh={() => { refreshLists(); updateDynamic() }}
          linkUrl={`/devProjectMgt?initId=${projectId}`}
          pid={currentInfo?.id}
          upDate={setUpDate}
          relateId={projectId}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'detail' &&
        <TodoListDetailDialog todoListName={'问题跟踪'} close={() => { setCurrentInfo(null); setId(null) }} refreshViewList={refreshLists} {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, setFocusId, setOpen, id, setId, STATUS_COLOR, upDate, isDelCode }} />
      }
    </Fragment>
  )

  function Table(props) {
    const { options, showList, dataGridList, focusId } = props
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const onRowClick = useCallback((item) => {
      _.forEach(dataGridList, o => {
        return o._rowClass = item?.id === o?.id ? 'select_row' : ''
      })
      forceUpdate()
    }, [dataGridList])

    useEffect(() => {
      if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    return <DataGrid option={options} data={showList} onRowClick={onRowClick} className='todo-list-data-grid' />
  }

  function sortFun(a, b, bind) {
    if (_.isNil(a[bind])) return -1
    const aStr = isNil(a[bind]) ? '' : a[bind]
    const bStr = isNil(b[bind]) ? '' : b[bind]
    if (_.isNumber(a[bind]) && _.isNumber(b[bind])) {
      return a[bind] - b[bind]
    }
    if (_.isNumber(a[bind]) ^ _.isNumber(b[bind])) {
      return (_.toNumber(a[bind]) || 0) - (_.toNumber(b[bind]) || 0)
    }
    return aStr.localeCompare(bStr)
  }


}

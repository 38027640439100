import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import './IssueAdd.scss'
import { Dialog, Button, Messager, Loader } from 'rootnet-ui'
import { FormInput, Select, Form, DatePicker, RadioGroup, Input } from "rootnet-edit";
import { Checkbox, Tooltip } from "antd";
import _ from "lodash";
import Icon from "../../../../components/Icon";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import RichTextEditor from "../../../common/richTextEditor/TinyEditor";
import { strParams } from "../../../../utils/publicFun";
import { usePost } from "rootnet-biz/es/hooks";
import RequirementAddUploadArea
    from "../../../requirementMgt/requirementAddDialog/requirementAddUploadArea/RequirementAddUploadArea";
import { handleTreeOptions } from "../../../common/TreeFunc";
import CascadeSelect from "../../../common/cascadeSelect/CascadeSelect";
import { isNil } from '../../../appraise/components/method';
import ViewQueryDialog from '../../../common/view/viewQueryDialog/ViewQueryDialog';

const { str14ToDate } = toDate

const YNOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const HFormInput = props => <FormInput horizontal labelWidth={112} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=BizProcess',
    'common/globalconst?globalConst=occurFrequency',
    'common/globalconst?globalConst=Influence',
    'common/globalconst?globalConst=ISSUEKIND',
    'common/globalconst?globalConst=IssueKindDetail',
    'common/globalconst?globalConst=claimFlag',
    'common/globalconst?globalConst=exchProject',
    'common/globalconst?globalConst=parProcess',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=issueTestGroup'
]

const OPTIONS_URLS = [
    'common/globalconst?globalConst=EffectGrade',
    'common/globalconst?globalConst=ISSUESTYPE',
    '/story/getbugProject',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    'common/globalconst?globalConst=broker',
]

const INIT_FORM_DATA = {
    timeRange: 'N',
    isExch: 'N',
    resolved: 'N',
    issueKind: ['13'],
    claimFlag: 'none',
    promiseDate: dateFormat('YYYY-MM-DD', new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)),
    occurDate: dateFormat('YYYY-MM-DD', new Date()),
}

function IssueAdd(props) {
    const { close, refreshViewList = () => { }, currentInfo, relatedIssue, submitAppendParams = {}, initParams } = props
    const { CSRecordID, relateCSAttach, serviceId, serviceFileId } = currentInfo || {}
    const [continueAdd, setContinueAdd] = useState(false)
    const [formData, setFormData] = useState(initParams || INIT_FORM_DATA)
    const [formError, setFormError] = useState()
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [analysisExpand, setAnalysisExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const [fileExpand, setFileExpand] = useState(true)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { data: custIdRes, doFetch: getCustId } = useGet()
    const { data: allVersionOptionsRes } = useGet('/customerserviceinfo/selectiveCustomerReleaseRec')
    const { data: versionOptionsRes, doFetch: getVersionOptions } = useGet()
    const { data: relateVersionOptionsRes, doFetch: getRelateVersionOptions } = useGet()
    const { data: id, doFetch: getOnlyId } = useGet()
    const richEditorRef = useRef()
    const { doFetch: addPost } = usePost()
    const [addLoading, setAddLoading] = useState(false)
    const { data: allUserRes } = useGet('/common/userinfo')
    const [countFile, updateFile] = useReducer((x) => x + 1, 60000)
    const { data: impactTemplate, doFetch: getTemplate } = useGet()
    const { data: whyHowRes } = useGet('/issue/getDemoTxt?id=41')
    const { data: communicateNoteRes } = useGet('/issue/getDemoTxt?id=42')
    const [versionSearchParams, setVersionSearchParams] = useState()
    const [relateVersionSearchParams, setRelateVersionSearchParams] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const { doFetch: getCSInitInfo } = useGet()
    const { doFetch: uploadCSFile } = usePost()
    const { doFetch: getServiceInfo } = useGet()
    const { doFetch: getAPI } = useGet()
    const { doFetch: postAPI } = usePost()
    const [showSearchDialog, setShowSearchDialog] = useState()

    const { versionId, issuesType, isRecurrent } = useMemo(() => formData || {}, [formData])

    const isRelatedIssue = useMemo(() => (isRecurrent === 'Y'), [isRecurrent])

    useEffect(() => {
        if (_.isNil(CSRecordID)) return
        setAddLoading(true)
        getCSInitInfo('/serviceSelf/getCustomerServiceInfo?id=' + CSRecordID).then(res => {
            const replaceParams = {
                occurDate: dateFormat('YYYY-MM-DD', str14ToDate(res.occurTime)),
                issuesType: res.issuetype,
                custId: res.customer,
                productName: res.productType,
                prodsubsysid: res.subSysId,
                personCharge: res.principal,
                principal: res.analyst,
                shortDesc: res.issueDesc
            }
            setFormData(x => _.assign({}, x, replaceParams))
            setTimeout(() => {
                // let initText = _.replace(res.reqDetail, /\n/g, '<br/>') || ''
                // initText = initText.replace(/[^<>]+(?=<)/g, match => match.replace(/\s/g, '&nbsp;'));
                let initText = res.reqDetail || ''
                if (richEditorRef.current) {
                    richEditorRef.current.setContent(initText)
                }
                setAddLoading(false)
            }, 2000)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setAddLoading(false)
        })
    }, [CSRecordID, getCSInitInfo])

    useEffect(() => {
        if (_.isNil(serviceId)) return
        setAddLoading(true)
        getServiceInfo('/customerService/list?id=' + serviceId).then(res => {
            const replaceParams = {
                occurDate: dateFormat('YYYY-MM-DD', str14ToDate(res.occurTime)),
                shortDesc: res.issueDesc,
                issuesType: res.issuetype,
                custId: res.customer,
                customer: res.customerName,
                personCharge: res.principal,
                principal: res.analyst,
                productName: res.productType,
                prodsubsysid: res.subSysId,
                csRecordID: res.csrecordID,
            }
            setFormData(x => _.assign({}, x, replaceParams))
            if (richEditorRef.current) {
                richEditorRef.current.setContent(concatTemplate(res.reqDetail))
            }
            setAddLoading(false)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setAddLoading(false)
        })
        function concatTemplate(value) {
            return `<p><span style=\\"font-size: 10pt;\\"><strong>问题描述*</strong></span></p><div>${value}</div><p><span style=\\"font-size: 10pt;\\"><strong>问题影响*</strong></span></p><p>&nbsp;</p><p><span style=\\"font-size: 10pt;\\"><strong>现场临时解决方案*</strong></span></p><p>&nbsp;</p>`
        }
    }, [serviceId, getServiceInfo])

    useEffect(() => {
        if (!_.isNil(serviceId)) return
        if (!_.isNil(_.get(initParams, 'impact'))) return
        getTemplate('/issue/getDemoTxt?id=40').then(res => {
            setTimeout(() => {
                if (richEditorRef.current) {
                    richEditorRef.current.setContent(res.demoValue)
                }
            }, 800)
        })
    }, [getTemplate, serviceId, initParams])

    const getNewOnlyId = useCallback(() => {
        getOnlyId('/test_case/productGetOnlyId').then(id => {
            updateFile()
            setFormData(x => _.assign({}, x, { id }))
            if (!!relateCSAttach) {
                const fileParams = { id: relateCSAttach, storyId: id, funcCode: '19' }
                uploadCSFile('/serviceSelf/add?' + strParams(fileParams))
                    .then(() => {
                        updateFile()
                    }).catch((err) => {
                        Messager.show(err._message, { icon: 'error' })
                    })
            }
            if (!!serviceFileId) {
                getAPI('/mapping/files/queryNew?' + strParams({ funcCode: '20', referenceId: serviceFileId })).then(serviceFileList => {
                    const newFileList = _.map(serviceFileList, x => ({
                        funcCode: '19',
                        name: x.fullName,
                        referenceId: id,
                        status: "done",
                        type: x.type,
                        uid: x.uid,
                        url: x.url
                    }))
                    postAPI('/mapping/files/add', newFileList).then(() => {
                        updateFile()
                    }).catch(err => Messager.show(err._message, { icon: 'error' }))
                }).catch(err => Messager.show(err._message, { icon: 'error' }))
            }
        })
    }, [getOnlyId, relateCSAttach, uploadCSFile, serviceFileId, getAPI, postAPI])

    useEffect(() => {
        getNewOnlyId()
    }, [getNewOnlyId])

    const [bizProcessOptions, occurFrequencyOptions, influenceOptions, issueKindOptions, issueKindDetailOptions,
        claimFlagOptions, exchProjectOptions, parProcessOptions, testGroupOptions] = useMemo(() => {//complexityOptions
            if (_.isEmpty(globalConstOptionsRes)) return []
            return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
        }, [globalConstOptionsRes])

    const treeIssueKindOptions = useMemo(() => {
        const firstLevelList = _.filter(issueKindOptions, x => _.isNil(x.pid))
        const issueKind = _.get(formData, 'issueKind')
        let disabledList = []
        if (!_.isEmpty(issueKind) && !_.includes(issueKind, '13')) {
            const pid = _.get(_.find(issueKindOptions, v => v.value === issueKind[0]), 'pid')
            disabledList = _.map(_.filter(issueKindOptions, x => !(x.value === pid || x.pid === pid)), 'value')
        }
        return handleTreeOptions(issueKindOptions, firstLevelList, 'value', 'pid', 'text', disabledList)
    }, [issueKindOptions, formData])

    const [effectGradeOptions, issuesTypeOptions, custIdOptions, productTreeOptions, productOptions, moduleOptionsRes, brokerOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4, d5, d6, d7] = optionsRes
        const productTreeOptions = _.map(_.groupBy(d4, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d5, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        const filterD1 = _.filter(d1, x => x.displayFlag !== 'N')
        return [
            _.map(filterD1, x => ({ text: x.displayName, value: x.interiorId, memo: x.memo })),
            _.map(d2, x => ({ text: x.interiorId, value: x.interiorId })),
            _.map(d3, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, supportUser: x.supportUser })),
            productTreeOptions,
            _.map(d4, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag })),
            d6,
            _.map(d7, x => ({ text: x.interiorId, value: x.interiorId })),
        ]
    }, [optionsRes])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.get(formData, 'bizProcess') === '04') {
            const filterModuleOptions = _.filter(moduleOptionsRes, x => ['STOCK', 'FUTURE'].includes(x.extConn))
            return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
        } else {
            if (_.isNil(_.get(formData, 'productName'))) return []
            const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === formData.productName)
            return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
        }
    }, [formData, moduleOptionsRes])

    // 版本编号
    useEffect(() => {
        if (_.isNil(_.get(formData, 'issuesType')) || _.isNil(_.get(formData, 'custId'))) return
        const searchParams = {}
        searchParams['updateenv'] = _.get(formData, 'issuesType') === 'PROD' ? '1' : '0'
        searchParams['customerid'] = _.get(formData, 'custId')
        searchParams['updateDate'] = dateFormat('YYYYMMDD')(toDate.dateStringToDate(_.get(formData, 'occurDate')))
        if (!_.isNil(_.get(formData, 'productName'))) {
            const productItem = _.find(productOptions, x => x.value === _.get(formData, 'productName'))
            if (_.get(productItem, 'independentVerFlag') === 'Y') {
                searchParams['productid'] = _.get(formData, 'productName')
            }
        }
        if (!_.isNil(_.get(formData, 'prodsubsysid'))) {
            const productItem = _.find(moduleOptions, x => x.value === _.get(formData, 'prodsubsysid'))
            if (_.get(productItem, 'independentVerFlag') === 'Y') {
                searchParams['prodsubsysid'] = _.get(formData, 'prodsubsysid')
            }
        }
        if (JSON.stringify(searchParams) === JSON.stringify(versionSearchParams)) return
        setVersionSearchParams(searchParams)
        getVersionOptions('/customerserviceinfo/selectiveCustomerReleaseRec?' + strParams(searchParams))
    }, [getVersionOptions, formData, productOptions, moduleOptions, versionSearchParams])

    const allVersionOptions = useMemo(() => {
        return _.map(allVersionOptionsRes, x => ({ text: x.releaseid, value: x.releaseid, productType2: x.productType2 }))
    }, [allVersionOptionsRes])

    const versionOptions = useMemo(() => {
        if (_.isNil(_.get(formData, 'issuesType')) || _.isNil(_.get(formData, 'custId'))) {
            return allVersionOptions
        }
        const resultList = _.map(versionOptionsRes, x => ({ text: x.releaseid, value: x.releaseid, productType2: x.productType2 }))
        return _.isEmpty(resultList) ? allVersionOptions : resultList
    }, [formData, versionOptionsRes, allVersionOptions])

    const isShowCause = useMemo(() => {
        const findItem = _.find(versionOptions, o => o.value === versionId)
        const isOfficialEdition = _.get(findItem, 'productType2') === 'R'
        return (issuesType === 'TESTONLY' && isOfficialEdition)
    }, [issuesType, versionId, versionOptions])

    // 关联版本编号
    useEffect(() => {
        if (_.isNil(_.get(formData, 'issuesType')) || _.isNil(_.get(formData, 'custId'))) return
        const searchParams = {}
        searchParams['updateenv'] = _.get(formData, 'issuesType') === 'PROD' ? '1' : '0'
        searchParams['customerid'] = _.get(formData, 'custId')
        searchParams['productid'] = _.get(formData, 'relateProductId')
        if (JSON.stringify(searchParams) === JSON.stringify(relateVersionSearchParams)) return
        setRelateVersionSearchParams(searchParams)
        getRelateVersionOptions('/customerserviceinfo/selectiveCustomerReleaseRec?' + strParams(searchParams))
    }, [formData, getRelateVersionOptions, relateVersionSearchParams])

    const relateVersionOptions = useMemo(() => {
        if (_.isNil(_.get(formData, 'issuesType')) || _.isNil(_.get(formData, 'custId'))) return []
        const resultList = _.map(relateVersionOptionsRes, x => ({ text: x.releaseid, value: x.releaseid }))
        return _.isEmpty(resultList) ? allVersionOptions : resultList
    }, [formData, relateVersionOptionsRes, allVersionOptions])

    const exConnVersionOptions = useMemo(() => {
        const filterList = _.filter(allVersionOptionsRes, x => x.productid === 'TradeAdapter')
        const sortList = _.sortBy(filterList, x => {
            if (_.isNil(x.updateDate)) {
                return 0
            }
            return new Date(x.updateDate).getMilliseconds()
        })
        return _.map(sortList, x => ({ text: x.releaseid, value: x.releaseid }))
    }, [allVersionOptionsRes])

    const filterIssueKindDetailOptions = useMemo(() => {
        const issueKind = _.get(formData, 'issueKind')
        if (_.isEmpty(issueKind)) return []
        return _.filter(issueKindDetailOptions, x => _.includes(issueKind, x.memo))
    }, [formData, issueKindDetailOptions])

    const customerOptions = useMemo(() => {
        if (_.isEmpty(custIdRes)) return []
        if (_.isNil(_.get(formData, 'custId'))) return []
        const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
        return _.map(filterStatus, x => ({ text: x.customerName, value: x.customer, supportUser: x.supportUser }))
    }, [custIdRes, formData])

    useEffect(() => {
        if (_.isNil(formData.custId)) return
        getCustId('/story/getbugProjectName?id=' + formData.custId)
    }, [formData.custId, getCustId])

    const changeForm = useCallback((newObj, bind) => {
        setFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            // 关联系统和客户
            if (bind === 'custId') {
                cloneObj['customer'] = null
                // getCustId('/story/getbugProjectName?id='+_.get(newObj,'custId'))
            }
            if (bind === 'bizProcess') {
                // 业务流程环节 为 04 时, 清空 对接券商 和 外挂版本
                if (newObj['bizProcess'] !== '04' && _.get(oldObj, 'productName') !== 'TradeAdapter') {
                    cloneObj['extConntoBrokers'] = null
                    cloneObj['exconnVer'] = null
                }
                // 业务流程环节 切换04和其他值时，清空子产品
                if (newObj['bizProcess'] === '04' && _.get(oldObj, 'bizProcess') !== '04') {
                    cloneObj['prodsubsysid'] = null
                }
                if (newObj['bizProcess'] !== '04' && _.get(oldObj, 'bizProcess') === '04') {
                    cloneObj['prodsubsysid'] = null
                }
            }
            if (bind === 'productName') {
                cloneObj['prodsubsysid'] = null
                if (newObj['productName'] !== 'TradeAdapter' && _.get(oldObj, 'bizProcess') !== '04') {
                    cloneObj['extConntoBrokers'] = null
                    cloneObj['exconnVer'] = null
                }
            }
            if (bind === 'issueKind') {
                if (_.size(newObj['issueKind']) > 1 && _.includes(newObj['issueKind'], '13')) {
                    cloneObj['issueKind'] = _.filter(newObj['issueKind'], x => x !== "13")
                }
                cloneObj['issueKindDetail'] = null
            }
            if (bind === 'relateProductId') {
                cloneObj['relateVersionId'] = null
            }
            return cloneObj
        })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(versionOptionsRes)) {
            const lastVersion = _.get(_.head(versionOptionsRes), 'releaseid')
            setFormData(old => {
                if (_.isNil(old)) return
                old['versionId'] = lastVersion
                return old
            })
        } else {
            setFormData(old => {
                if (_.isNil(old)) return
                old['versionId'] = null
                return old
            })
        }
        forceUpdate()
    }, [versionOptionsRes])

    useEffect(() => {
        if (_.isEmpty(custIdRes)) return
        const valueList = _.map(custIdRes, 'customer')
        if (!_.isNil(formData) && !_.isNil(_.get(formData, 'custId')) && !_.includes(valueList, _.get(formData, 'customer'))) {
            const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
            const customer = _.get(filterStatus, '0.customer')
            changeForm({ customer })
        }
    }, [custIdRes, formData, changeForm])

    const effectGradeTips = useMemo(() => {
        return <div className={'effect-grade-tips'}>
            严重程度详细信息
            {
                _.map(effectGradeOptions, (item, i) => <div key={`effectGrade-${item.value}`} className={'effect-grade-tips-item'}>
                    <br />
                    【{i + 1}-{item.text}】:
                    <br />
                    {`    ${item.memo}`}
                </div>)
            }
        </div>
    }, [effectGradeOptions])

    const brokersRequired = useMemo(() => {
        return _.get(formData, 'bizProcess') === '04' || _.get(formData, 'productName') === 'TradeAdapter'
    }, [formData])

    const isResolved = useMemo(() => {
        return _.get(formData, 'resolved') === 'Y'
    }, [formData])

    useEffect(() => {
        if (brokersRequired) {
            if (_.isEmpty(_.get(formData, 'extConntoBrokers'))) {
                setFormError(x => _.assign({}, x, { 'extConntoBrokers': '必填项！' }))
            }
            if (_.isEmpty(_.get(formData, 'exconnVer'))) {
                setFormError(x => _.assign({}, x, { 'exconnVer': '必填项！' }))
            }
            if (_.isEmpty(_.get(formData, 'prodsubsysid'))) {
                setFormError(x => _.assign({}, x, { 'prodsubsysid': '必填项！' }))
            }
        } else {
            setFormError(x => _.assign({}, x, { 'extConntoBrokers': null, 'exconnVer': null, 'prodsubsysid': null }))
        }
    }, [formData, brokersRequired])

    useEffect(() => {
        if (_.get(formData, 'resolved') === 'Y' && _.isNil(_.get(formData, 'newVersion'))) {
            setFormError(x => _.assign({}, x, { 'newVersion': '必填项！' }))
        } else {
            setFormError(x => _.assign({}, x, { 'newVersion': null }))
        }
    }, [formData])

    const exchProjectRequired = useMemo(() => {
        return _.get(formData, 'isExch') === 'Y'
    }, [formData])

    useEffect(() => {
        if (_.get(formData, 'isExch') === 'Y' && _.isNil(_.get(formData, 'exchProject'))) {
            setFormError(x => _.assign({}, x, { 'exchProject': '必填项！' }))
        } else {
            setFormError(x => _.assign({}, x, { 'exchProject': null }))
        }
    }, [formData])

    const canSubmit = useMemo(() => {
        if (!_.get(formData, 'shortDesc')) return false
        return !_.some(_.values(formError), x => x)
    }, [formData, formError])

    const clearUrl = useCallback(() => {
        let url = window.location.href;
        if (url.indexOf("?") !== -1) {
            url = url.replace(/(\?)[^'"]*/, '');
            window.history.pushState({}, 0, url);
        }
    }, [])

    const continueClear = useCallback(() => {
        clearUrl()
        setFormData(old => {
            const cloneObj = { ...old }
            // _.forEach(cloneObj, (value, key) => cloneObj[key] = null)
            // return {
            //     ...cloneObj,
            //     timeRange: 'N',
            //     resolved: 'N',
            //     promiseDate: dateFormat('YYYY-MM-DD',new Date(new Date().getTime() + 2*24*60*60*1000))
            // }
            return {
                ...cloneObj,
                shortDesc: null,
                issuesType: null,
                effectGrade: null,
            }
        })
        richEditorRef.current.setContent(_.get(impactTemplate, 'demoValue'))
        getNewOnlyId()
    }, [getNewOnlyId, impactTemplate, clearUrl])

    const checkRichTextRequired = useCallback(() => {
        return purifyRichText(richEditorRef.current.getContent()) === purifyRichText(_.get(impactTemplate, 'demoValue'))
        function purifyRichText(richText = '') {
            // const regex = /(<([^>]+)>)/ig
            // return richText.replace(regex, "")
            //     .replace(/\s/g,"")
            //     .replace(/&nbsp;/g,"")
            return richText.replace(/\s/g, "")
                .replace(/&nbsp;/g, "")
        }
    }, [impactTemplate])

    useEffect(() => {
        const initDesc = _.get(initParams, 'impact')
        richEditorRef.current.setContent(initDesc || '')
    }, [initParams])

    const submit = useCallback(() => {
        if (checkRichTextRequired()) {
            return Messager.show("请填写详细描述")
        }
        if (richEditorRef.current.loading) {
            return Messager.show("图片上传中，请稍后保存")
        }
        if (addLoading) return
        setAddLoading(true)
        const postParams = {
            ...formData,
            extConntoBrokers: _.join(_.get(formData, 'extConntoBrokers'), '^'),
            influence: _.join(_.get(formData, 'influence'), '^'),
            issueKind: _.join(_.get(formData, 'issueKind'), '^'),
            issueKindDetail: _.join(_.get(formData, 'issueKindDetail'), '^'),
            newVersion: _.join(_.get(formData, 'newVersion'), '^'),
            impact: _.replace(richEditorRef.current.getContent(), /\n/g, ''),
            issueStatus: '01',
            issueId: '0',
            whyAndHow: _.replace(_.get(whyHowRes, 'demoValue'), /\n/g, ''),
            communicateNote: _.replace(_.get(communicateNoteRes, 'demoValue'), /\n/g, ''),
            optStep: '',
            logList: '',
            currentUser: _.get(formData, 'personCharge'),
            id,
            ...submitAppendParams,
        }
        if (CSRecordID) {
            postParams['csRecordID'] = CSRecordID
        }
        addPost('/issue/add', postParams).then((res) => {
            // addPost('/issue/responserAdd', {issueId: res.issueId})
            // .then(()=>{
            setAddLoading(false)
            Messager.show('添加成功', { icon: 'success' })
            if (_.isFunction(relatedIssue)) {
                relatedIssue([id], false)
            }
            refreshViewList()
            if (continueAdd) {
                continueClear()
            } else {
                close()
            }
            // })
            // .catch(err => {
            //     Messager.show(err._message, { icon: 'error' })
            //     setAddLoading(false)
            // })
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setAddLoading(false)
        })
    }, [checkRichTextRequired, addLoading, formData, whyHowRes, communicateNoteRes, id, CSRecordID, addPost,
        relatedIssue, refreshViewList, continueAdd, continueClear, close, submitAppendParams])

    const onIssueSelected = useCallback((item) => {
        setFormData(old => {
            const cloneObj = _.clone(old)
            cloneObj['relatedIssue'] = _.get(item, 'id') || ''
            cloneObj['relatedIssueBizId'] = _.get(item, 'bizId') || ''
            return cloneObj
        })
    }, [setFormData])

    useEffect(() => {
        const markReason = _.get(formData, 'markReason')
        setFormError(x => _.assign({}, x, { markReason: isShowCause ? isNil(markReason) ? '必填项！' : null : null }))
    }, [formData, setFormError, isShowCause])

    useEffect(() => {
        const relatedIssueBizId = _.get(formData, 'relatedIssueBizId')
        setFormError(x => _.assign({}, x, { relatedIssueBizId: isRelatedIssue ? isNil(relatedIssueBizId) ? '必填项！' : null : null }))
    }, [formData, isRelatedIssue])

    useEffect(() => {
        if (!isShowCause) setFormData(x => _.assign({}, x, { markReason: null }))
    }, [isShowCause, setFormData])

    return <Dialog header={'新增ISSUE'} className={'issue-add-dialog'} footerVisible={false} cancel={() => {
        clearUrl()
        close()
    }}>
        <div className="content-wrap flex-y">
            <HFormInput label={'标题'} value={_.get(formData, 'shortDesc')} onChange={v => setFormData(x => ({ ...x, shortDesc: v }))} required componentWidth={808} />
            <div className="area-wrap base-info-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
                    基本信息
                </div>
                <div className="area-content-wrap" style={{ height: baseInfoExpand ? '' : 0 }}>
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                        <HFormInput label={'分类标记'} bind={'issuesType'} required component={Select} options={issuesTypeOptions} />
                        <HFormInput label={'严重程度'} bind={'effectGrade'} required component={Select} options={effectGradeOptions} />
                        <Tooltip title={effectGradeTips} overlayStyle={{ width: 740, maxWidth: 740, whiteSpace: 'pre-wrap' }}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput label={'影响交易'} bind={'timeRange'} required component={RadioGroup} options={YNOptions} labelWidth={98} />
                        <HFormInput label={'客户系统'} bind={'custId'} required component={Select} search options={custIdOptions} />
                        <HFormInput label={'客户名称'} bind={'customer'} required component={Select} search options={customerOptions} />
                        <HFormInput label={'业务流程环节'} bind={'bizProcess'} required component={Select} options={bizProcessOptions} />
                        <HFormInput label={'产品名称'} bind={'productName'} required component={Select} search tree options={productTreeOptions} />
                        <HFormInput label={'子产品名称'} bind={'prodsubsysid'} component={Select} search required={brokersRequired} clear={!brokersRequired} options={moduleOptions} componentClass={'long-options'} />
                        <HFormInput label={'对接券商'} bind={'extConntoBrokers'} component={Select} required={brokersRequired} clear={!brokersRequired} disabled={!brokersRequired} toolbar={false} search multiple options={brokerOptions} />
                        <Tooltip title={`业务流程环节为【外挂接口】时必填`}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput label={'发生日期'} bind={'occurDate'} required component={DatePicker} bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                        <HFormInput label={'版本编号'} bind={'versionId'} component={Select} required search options={versionOptions} componentClass={'long-options'} />
                        <HFormInput label={'外挂版本'} bind={'exconnVer'} component={Select} search required={brokersRequired} clear={!brokersRequired}
                            disabled={!brokersRequired} options={exConnVersionOptions} componentClass={'long-options'} />
                        <Tooltip title={`业务流程环节为【外挂接口】时必填`}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput label={'解决时间要求'} bind={'promiseDate'} required component={DatePicker} bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                        <HFormInput label={'关联产品'} bind={'relateProductId'} component={Select} search tree clear options={productTreeOptions} />
                        <HFormInput label={'关联版本'} bind={'relateVersionId'} component={Select} clear search options={relateVersionOptions} componentClass={'long-options'} />
                        <HFormInput label={'交易所问题'} bind={'isExch'} required component={RadioGroup} options={YNOptions} />
                        <HFormInput label={'交易所项目'} bind={'exchProject'} component={Select} clear required={exchProjectRequired} options={exchProjectOptions} labelWidth={204} />
                        <HFormInput label={'发生频率'} bind={'occurFrequency'} component={Select} clear options={occurFrequencyOptions} />
                        <HFormInput label={'产研流程环节'} bind={'parProcess'} component={CascadeSelect} search clear options={parProcessOptions} />
                        <HFormInput label={'维护负责人'} bind={'supportPerson'} component={UserSelect} />
                        <HFormInput label={'客户问题编号'} bind={'clientIssueNo'} />
                        {
                            isShowCause &&
                            <>
                                <HFormInput required label={'TESTONLY原因'} bind={'markReason'} component={Input} />
                                <Tooltip title={'分类标记选择testonly，客户环境版本编号为正式版本，需要说明原因！'} overlayStyle={{ width: 740, maxWidth: 740, whiteSpace: 'pre-wrap' }}>
                                    <span>
                                        <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                    </span>
                                </Tooltip>
                            </>
                        }
                    </Form>
                </div>
            </div>
            <div className="area-wrap analysis-info-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: analysisExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setAnalysisExpand(v => !v)} />
                    分析信息
                </div>
                <div className="area-content-wrap" style={{ height: analysisExpand ? '' : 0 }}>
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                        <HFormInput label={'问题负责人'} bind={'personCharge'} required component={UserSelect} />
                        <HFormInput label={'问题分析人'} bind={'principal'} required component={UserSelect} />
                        <HFormInput label={'测试责任组'} bind={'testGroup'} required component={Select} search options={testGroupOptions} />
                        <HFormInput label={'问题影响'} bind={'influence'} required component={Select} multiple options={influenceOptions} />
                        <HFormInput label={'问题性质'} bind={'issueKind'} required multiple component={Select} clear tree options={treeIssueKindOptions} />
                        <Tooltip title={`问题性质仅允许同一个大类下多选，跨一级的变更，请联系刘凯或所属部门产品总监`}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput label={'问题性质细分'} bind={'issueKindDetail'} component={Select} multiple search clear
                            options={filterIssueKindDetailOptions} labelWidth={98} />
                        <HFormInput
                            label={'关联ISSUE'}
                            bind={'relatedIssueBizId'}
                            required={isRelatedIssue}
                            placeholder='查找'
                            className='search-tracer'
                            onFocus={() => setShowSearchDialog('issue')}
                            prefix={<Icon name='sousuo1' />}
                            suffix={<Icon name='baocuo' onClick={() => onIssueSelected()} />}
                        />
                        <HFormInput label={'问题在新版本已解决'} bind={'resolved'} required component={RadioGroup} options={YNOptions} labelWidth={182} />
                        <HFormInput label={'解决的具体版本'} bind={'newVersion'} required={isResolved} component={Select} labelWidth={134}
                            clear search options={allVersionOptions} componentClass={'long-options'} multiple />
                        <HFormInput label={'投诉/索赔标识'} bind={'claimFlag'} component={Select} options={claimFlagOptions} />
                        <HFormInput label={'是否低级问题'} bind={'complexity'} component={Select} options={YNOptions} required />
                        <Tooltip title={
                            <div>
                                低级错误（Stupid Mistake）<br />
                                【定义】：<br />
                                客户无法忍受或体验极差的ISSUE<br />
                                【场景】：<br />
                                1、提供给客户的测试用例，客户发现问题，内部未发现问题<br />
                                2、基本业务规则（明文规定的要求）客户现场出现问题<br />
                                3、未满足客户明确提出的功能要求<br />
                                4、SQL脚本类的语法错误<br />
                            </div>
                        }>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput labelWidth={98} label={'是否重犯'} bind={'isRecurrent'} component={Select} options={YNOptions} required />
                        <Tooltip title={'版本升级后现场仍有问题'}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                    </Form>
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
                    详细描述
                    <span style={{ color: 'red' }}>（必填）</span>
                </div>
                <div className="rich-text-area" style={{ display: descExpand ? 'block' : 'none' }}>
                    <RichTextEditor ref={richEditorRef} />
                </div>
            </div>
            <div className="area-wrap desc-wrap" >
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: fileExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setFileExpand(v => !v)} />
                    附件
                </div>
                <div className="doc-area" style={{ display: fileExpand ? 'block' : 'none' }} key={countFile}>
                    <RequirementAddUploadArea id={id} allUserRes={allUserRes} funcCode={'19'} />
                </div>
            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div className={'continue-add-check'}>
                {
                    _.isNil(serviceId) && _.isNil(serviceFileId) && _.isNil(relateCSAttach) && _.isNil(CSRecordID) &&
                    <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
                }
            </div>
            <div className="btn-group flex">
                <Button normal onClick={() => {
                    clearUrl()
                    close()
                }}>取消</Button>
                <Button primary disable={!canSubmit} onClick={submit}>确认</Button>
            </div>
        </div>
        {
            addLoading &&
            <Loader fill />
        }
        {
            showSearchDialog === 'issue' &&
            <ViewQueryDialog
                bizName='ISSUE'
                initValue={_.get(formData, 'relatedIssue')}
                close={() => setShowSearchDialog(null)}
                funcCode={'0005'}
                outerSetItem={onIssueSelected}
                bizField='ProductionIssue.IssueId'
            />
        }
    </Dialog>
}

export default IssueAdd;
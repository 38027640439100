import React from 'react'
import { Dialog } from 'rootnet-ui'


export default function Disassociation(props) {
  const { associatedTestcase, setDisassociation, planId } = props
  return (
    <Dialog
      header='取消关联'
      confirm={confirm}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0 }}
      cancel={() => setDisassociation(null)}
    >
      确认解除与该用例关联关系？
    </Dialog>
  )
  function confirm() {
    associatedTestcase(null, planId, false)
    setDisassociation(null)
  }
}

import React, { useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Button, Messager } from 'rootnet-ui'
import { TextArea, } from 'rootnet-edit'
import { useApi } from '../../../../utils/hook'
import { parseParams } from '../../../../project_share/utils/utils'
import Header from '../../Header'
import adopt from '../../../../assets/images/adopt.png'
import reject from '../../../../assets/images/reject.png'
import './Examine.scss'

const WHICH_OPTIONS = {
  'Add': '0',
  'Edit': '1',
  'Del': '2'
}

export default function Examine(props) {
  const { history, location } = props
  const params = JSON.parse(parseParams(location.search.split('?')[1]).data)
  const [inputVal, setInputVal] = useState()
  const [radio, setRadio] = useState(true)
  const { doFetch } = useApi()
  return (
    <div className='Examine'>
      <Header goBack={() => history.go(-1)} content='RIMS' icon='xiangqian' />
      <section>
        <h1>审核信息</h1>

        <div>

          <div>

            <dl>
              <dt>
                {/* <Icon name={radio ? 'zhengque' : 'chenggong'} onClick={() => isRadio(true)} /> */}
                <img src={radio ? adopt : reject} onClick={() => isRadio(true)} alt='' />
              </dt>
              <dd>通过</dd>
            </dl>

            <dl>
              <dt>
                <img src={!radio ? adopt : reject} onClick={() => isRadio(false)} alt='' />
                {/* <Icon name={!radio ? 'baocuo' : 'shibai'} onClick={() => isRadio(false)} /> */}
              </dt>
              <dd>驳回</dd>
            </dl>

          </div>

          <div>
            <TextArea
              className={cls({ error: (!radio && _.size(inputVal) === 0) })}
              value={inputVal}
              onChange={(value) => setInputVal(value)}
              placeholder='请输入审核说明'
            />
          </div>

        </div>

      </section>
      <footer>
        <Button primary onClick={submit}>确认提交</Button>
      </footer>
    </div>
  )
  function isRadio(judge) {
    setRadio(judge)
  }
  function submit() {
    if (!radio && _.size(inputVal) === 0) return Messager.show('请填写审核说明', { icon: 'error' })
    const auditData = _.assign(params, {
      isAgree: radio ? '2' : '1',
      auditMemo: inputVal,
      whichOption: WHICH_OPTIONS[params.action],
      whichPage: params.tabIndex,
    })
    doFetch('/field/updateAudit', 'post', auditData).then(() => {
      Messager.show('审核成功', { icon: 'success' })
      history.push('/mobileHome?initTabIndex=0')
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
    })
  }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './WorkTimeTable.scss'
import { Card, DataGrid, Dialog, Drawer, Messager } from "rootnet-ui";
import _ from 'lodash'
import Icon from "../../../../components/Icon";
import { TextIconBtn } from "../../../common/TextIconBtn";
import OperationList from "../../../../project_share/components/OperationList";
import { uniqKeyFor } from "rootnet-biz/es/utils";
import { InputNumber, Input, Popover, Progress, Popconfirm, Tooltip, Select as AntdSelect } from "antd";
import WorkTimeSelectProject from "../workTimeSelectProject/WorkTimeSelectProject";
import clsx from "clsx";
import { DeleteOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { useGet } from "rootnet-biz/es/hooks";
import { Select } from "rootnet-edit";
import convertOptions from "../../../common/ConvertOptions";
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import ChangeRecord from "../../../common/ChangeRecord";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import gd from "../../../../base/global";
import { isNil } from '../../../appraise/components/method';
import RichTextEditor from '../../../common/richTextEditor/TinyEditor'

const { TextArea } = Input;

const statusColor = {
    '审核中': '#FCA000',
    '审核完成': '#00BC81',
    '审核拒绝': '#FF4D56',
}

// 临时过滤补丁项目
const PATList = [
    'PRJ-20220902-004-008-006',
    'PRJ-20220902-005-003-030',
    'PRJ-20220902-005-002-038',
    'PRJ-20220902-004-006-009',
    'PRJ-20210623-001-005',
    'PRJ-20220902-005-002-037',
    'PRJ-20220902-004-008-005',
    'PRJ-20220902-004-006-006',
]

function getColumns(props) {
    const { onFormChange, projectPopId, setProjectPopId, onSelectProject, isDetail, setShowDynamicId, transactionOptions,
        onSelectTransaction, delItem, devProjectStatusOptions, recommendProjectList, allProjectList, filterTransactionOptions,
        allUserRes, othersReport, devHourTypeOptions } = props
    return [
        {
            header: '名称', bind: 'projectId', width: 300, tooltip: true, stretch: false, convert: (v, o) => {
                if (isDetail) {
                    return <div style={{ marginLeft: 18 }}>{_.get(o, 'projectName')}</div>
                } else {
                    const findItem = _.find(transactionOptions, x => x.value === v)
                    const pid = _.get(findItem, 'pid')
                    const showFilterTransactionOptions = _.map(filterTransactionOptions, o => {
                        if (o.value === pid) {
                            return _.assign({}, o, { children: _.compact(_.concat(findItem, o.children)) })
                        }
                        return o
                    })
                    return <div style={{ marginLeft: 18 }}>
                        {
                            o.type !== 'transaction' &&
                            <Popover open={o.uniqId === projectPopId} onOpenChange={visible => setProjectPopId(visible ? o.uniqId : null)}
                                trigger={'click'} placement={'bottomLeft'}
                                content={<WorkTimeSelectProject close={() => setProjectPopId(null)}
                                    onSelect={obj => onSelectProject(o.uniqId, obj)}
                                    {...{ devProjectStatusOptions, recommendProjectList, allProjectList, allUserRes, othersReport }} />}
                                overlayClassName={'work-time-select-project-pop'}
                            >
                                <div className={clsx('project-select-wrap one-line-text', { 'placeholder-text': _.isNil(v) })}>
                                    {_.get(o, 'projectName') || '请选择'}
                                </div>
                            </Popover>
                        }
                        {
                            o.type === 'transaction' && <Select tree value={v} onChange={value => onSelectTransaction(o.uniqId, value)} options={showFilterTransactionOptions} style={{ width: 260 }} className={'transaction-select'} />
                        }
                    </div>
                }
            }
        },
        {
            header: <div className='work-time-working-project-type'>类型</div>, bind: 'workingProjectType', width: 120, stretch: false, convert: (v, o) => {
                if (isDetail) {
                    return <div className={'flex center-y'}>
                        {convertOptions(v, devHourTypeOptions)}
                    </div>
                } else {
                    if (o.type === 'transaction') return ''
                    return <AntdSelect
                        value={v}
                        placeholder='请选择'
                        options={devHourTypeOptions}
                        onChange={newValue => onFormChange(o.uniqId, newValue, 'workingProjectType')}
                        style={{ width: '100%' }}
                    />
                }
            }
        },
        {
            header: '周投入百分比', bind: 'weeklyInputPercentage', width: 120, stretch: false, convert: (v, o) => {
                if (isDetail) {
                    let color = '#52C41A'
                    if (_.get(o, 'stateName') === '审核拒绝') {
                        color = '#909090'
                    }
                    return <div className={'weekly-input-percentage flex center-y'}>
                        <Progress percent={v || 0} showInfo={false} size={'small'} strokeColor={color} />
                        <span className={'percentage-text'}>
                            {v || 0}%
                        </span>
                    </div>
                } else {
                    return <InputNumber value={v} onChange={newValue => onFormChange(o.uniqId, newValue, 'weeklyInputPercentage')}
                        min={0} max={100} precision={0} addonAfter={'%'} controls={false} />
                }
            }
        },
        {
            header: '描述', bind: 'description', width: 300, tooltip: true, convert: (v, o) => {
                if (isDetail) {
                    return <Popover content={<div className={'work-time-description-pop'}>{v}</div>} placement={'bottomLeft'}
                        overlayClassName={'work-time-description-pop-wrap'} >
                        <div className={'description-wrap one-line-text'}>
                            {v}
                        </div>
                    </Popover>
                } else {
                    return <TextArea placeholder="请输入描述" value={v} onChange={e => onFormChange(o.uniqId, e.target.value, 'description')}
                        autoSize={{ minRows: 1, maxRows: 6 }} style={{ width: 280 }} className={'description-textarea'} />
                }
            }
        },
        {
            header: '当前审核人', bind: 'workingHoursReviews', width: 120, stretch: false, tooltip: true, convert: (v, o) => {
                return _.join(_.compact(_.map(v, 'reviewerName')), '、')
            }
        },
        {
            header: '状态', bind: 'stateName', width: 100, stretch: false, convert: (v, o) => {
                if (_.isNil(v)) return null
                const refuseItem = _.find(o.workingHoursReviews, x => x.state === '2')
                const refuseUser = _.get(refuseItem, 'reviewer')
                const refuseReason = _.get(refuseItem, 'refuseReason')
                return <Tooltip title={v === '审核拒绝' && <div>
                    审核人：{convertOptions(refuseUser, allUserRes, 'userName', 'userAccount')}<br />拒绝意见：{refuseReason}
                </div>} color={'#fff'} overlayInnerStyle={{ color: '#13151A' }}>
                    <div className={'flex center-y'}>
                        <div className={'state-wrap flex center'} style={{ color: _.get(statusColor, v), border: `1px solid ${_.get(statusColor, v)}` }}>
                            {v}
                        </div>
                        {
                            v === '审核拒绝' &&
                            <Icon name='jingshishuoming' style={{ fontSize: 14, marginLeft: 4 }} />
                        }
                    </div>
                </Tooltip>
            }
        },
        { header: '项目编码', bind: 'projectCode', width: 160, tooltip: true, stretch: false },
        { header: '提交时间', bind: 'updateTime', width: 140, stretch: false, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
        { header: '操作', bind: '', width: 60, align: 'center', stretch: false, convert: renderOperation },
    ]

    function renderOperation(v, o) {
        const operateOption = _.compact([
            !isDetail && {
                text: <Popconfirm
                    title="确定删除吗？"
                    okText="删除"
                    cancelText="取消"
                    onConfirm={() => delItem(o.uniqId)}
                    overlayInnerStyle={{ padding: 6, width: 118 }}
                >
                    <DeleteOutlined />
                </Popconfirm>,
                mode: 'del',
            },
            isDetail && {
                text: <Tooltip title={'流转记录'} color={'#fff'} overlayInnerStyle={{ color: '#13151A' }}><FieldTimeOutlined /></Tooltip>,
                mode: 'dynamic',
                onClick: () => setShowDynamicId(o.id),
            },
        ])
        return <OperationList options={operateOption} style={{ fontSize: 16 }} />;
    }
}

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=projectStatusNew',  //项目状态
    'common/globalconst?globalConst=workingProjectType', //项目工时类型
]

function WorkTimeTable(props) {
    const { editData, setEditData, mode, initData, detailLoading, submitLoading, othersReport, importLastWeekData, richEditorRef, initExperience } = props
    const [foldType, setFoldType] = useState([])
    const [projectPopId, setProjectPopId] = useState()
    const [showDynamicId, setShowDynamicId] = useState()
    const { data: transactionRes, doFetch: getFetchTransaction } = useGet()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: allUserRes } = useGet('/common/userinfo')
    const [laseWeekHint, setLaseWeekHint] = useState(false)

    const { data: projectListRes, doFetch: getProjectList } = useGet()

    useEffect(() => {
        if (isNil(othersReport)) getFetchTransaction('/worktime/jobtype/list?newFlag=0')
        else getFetchTransaction('/worktime/jobtype/list?newFlag=0&userAccount=' + othersReport)
    }, [getFetchTransaction, othersReport])

    useEffect(() => {
        getProjectList('/workBench/myProject?userAccount=' + (othersReport || gd.User.operator_id)).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    }, [getProjectList, othersReport])

    const [recommendProjectList, allProjectList] = useMemo(() => {
        // const projectItems = _.filter(editData, x => _.includes(['dev', 'imp'], x.type)) || []
        // const projectItemsIdList = _.map(projectItems, 'projectId')
        // !_.includes(projectItemsIdList, x.id) && 
        const recommendProjectList = _.filter(_.get(projectListRes, 'myProject'), x => !_.includes(PATList, x.projectID)) || []
        const allProjectList = _.filter(_.get(projectListRes, 'allProject'), x => !_.includes(PATList, x.projectID)) || []
        return [
            recommendProjectList,
            allProjectList,
        ]
    }, [projectListRes])

    const [devProjectStatusOptions, devHourTypeOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const transactionOptions = useMemo(() => {
        const concatData = _.concat(..._.map(transactionRes, o => _.get(o, 'children', [])))
        return _.map(concatData, o => ({
            pid: _.toString(o.pid),
            value: _.toString(o.id),
            text: o.name,
        }))
    }, [transactionRes])

    const filterTransactionOptions = useMemo(() => {
        const transactionItems = _.filter(editData, x => x.type === 'transaction') || []
        const transactionItemsIdList = _.map(transactionItems, 'projectId')
        return filterOptions(transactionRes, [], true, transactionItemsIdList)
        function filterOptions(data, arr, disabled = true, filterList) {
            _.forEach(data, o => {
                if (!_.includes(filterList, _.toString(o.id))) {
                    arr.push({
                        pid: _.toString(o.pid),
                        value: _.toString(o.id),
                        text: o.name,
                        _disabled: disabled,
                        children: !_.isEmpty(o.children) ? filterOptions(o.children, [], false, filterList) : []
                    })
                }
            })
            return arr
        }
    }, [transactionRes, editData])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const showList = useMemo(() => {
        let handleList = []
        const projectItems = _.filter(editData, x => _.includes(['dev', 'imp'], x.type)) || []
        handleList.push({
            projectId: '项目',
            weeklyInputPercentage: _.sum(_.map(projectItems, x => _.toNumber(_.get(x, 'weeklyInputPercentage')) || 0)),
            _isMenu: 'project',
        })
        if (!_.includes(foldType, 'project')) {
            handleList = handleList.concat(projectItems)
            if (!isDetail) {
                handleList.push({
                    type: 'dev',
                    _addType: 'dev',
                    name: '项目',
                })
            }
        }
        const transactionItems = _.filter(editData, x => x.type === 'transaction') || []
        handleList.push({
            projectId: '事务',
            weeklyInputPercentage: _.sum(_.map(transactionItems, x => _.toNumber(_.get(x, 'weeklyInputPercentage')) || 0)),
            _isMenu: 'transaction',
        })
        if (!_.includes(foldType, 'transaction')) {
            handleList = handleList.concat(transactionItems)
            if (!isDetail) {
                handleList.push({
                    type: 'transaction',
                    _addType: 'transaction',
                    name: '事务',
                })
            }
        }
        // const experienceItems = _.filter(editData, x => x.type === 'experience') || []
        handleList.push({
            projectId: '心得体会',
            _isMenu: 'experience',
        })
        return handleList
    }, [editData, foldType, isDetail])

    const onFormChange = useCallback((uniqId, newValue, bind) => {
        setEditData(old => {
            return _.map(old, item => {
                if (item.uniqId === uniqId) {
                    item[bind] = newValue
                    return item
                } else {
                    return item
                }
            })
        })
    }, [setEditData])

    const onSelectProject = useCallback((uniqId, projectObj) => {
        setEditData(old => {
            return _.map(old, item => {
                if (item.uniqId === uniqId) {
                    item['projectId'] = projectObj.projectId
                    item['projectName'] = projectObj.projectName
                    item['projectCode'] = projectObj.projectCode
                    return item
                } else {
                    return item
                }
            })
        })
    }, [setEditData])

    const onSelectTransaction = useCallback((uniqId, value) => {
        const text = convertOptions(value, transactionOptions)
        setEditData(old => {
            return _.map(old, item => {
                if (item.uniqId === uniqId) {
                    item['projectId'] = value
                    item['projectName'] = text
                    return item
                } else {
                    return item
                }
            })
        })
    }, [setEditData, transactionOptions])

    const delItem = useCallback((delId) => {
        setEditData(old => _.filter(old, x => x.uniqId !== delId))
    }, [setEditData])

    const option = useMemo(() => {
        return ({
            autoFill: true,
            resizable: true,
            // virtualized: true,
            rowHeight: 50,
            fixedRight: 1,
            columns: getColumns({
                onFormChange, projectPopId, setProjectPopId, onSelectProject, isDetail,
                setShowDynamicId, transactionOptions, onSelectTransaction, delItem, devProjectStatusOptions,
                recommendProjectList, allProjectList, filterTransactionOptions, allUserRes, othersReport,
                devHourTypeOptions
            }),
            onRenderRow: (o) => {
                const menu = _.get(o, '_isMenu')
                const addType = _.get(o, '_addType')
                if (!_.isNil(menu)) {
                    if (menu === 'experience') {
                        return {
                            class: 'one-line-row-wrap experience-line-row-wrap',
                            component: <div className='experience-item-box'>
                                <div className={'menu-row flex center-y'} onClick={() => {
                                    setFoldType(old => _.includes(old, menu) ? _.filter(old, x => x !== menu) : _.concat(old, menu))
                                }}>
                                    <Icon name={'zhankaijiantouxia'} className='fold-icon' style={{ transform: _.includes(foldType, menu) ? 'rotate(-90deg)' : 'none' }} />
                                    {_.get(o, 'projectId')}
                                </div>
                                {
                                    isDetail &&
                                    <div className="rich-text-detail-wrap flex" style={{ padding: 8, display: _.includes(foldType, menu) ? 'none' : 'block' }}>
                                        <div className={'rich-text-detail'} dangerouslySetInnerHTML={{ __html: initExperience }} />
                                    </div>
                                }
                                {
                                    !isDetail &&
                                    <div className="rich-text-area" style={{ padding: 8, display: _.includes(foldType, menu) ? 'none' : 'block' }} >
                                        <RichTextEditor ref={richEditorRef} height={500} />
                                    </div>
                                }
                            </div>
                        }
                    }
                    return {
                        class: 'one-line-row-wrap flex center-y',
                        component: <div className={'menu-row flex center-y'} onClick={() => {
                            setFoldType(old => _.includes(old, menu) ? _.filter(old, x => x !== menu) : _.concat(old, menu))
                        }}>
                            <Icon name={'zhankaijiantouxia'} className='fold-icon' style={{ transform: _.includes(foldType, menu) ? 'rotate(-90deg)' : 'none' }} />
                            {_.get(o, 'projectId')}
                            <div className={'menu-total'}>（ 合计： {_.get(o, 'weeklyInputPercentage')}% ）</div>
                        </div>
                    }
                }
                if (!_.isNil(addType)) {
                    return {
                        class: 'one-line-row-wrap flex center-y',
                        component: <div className={'add-row flex center-y'}>
                            <TextIconBtn icon={'fix-xinzeng'} text={`添加`} style={{ fontSize: 12 }} onClick={() => {
                                setEditData(old => _.concat(old, {
                                    type: addType,
                                    uniqId: uniqKeyFor(),
                                }))
                            }} />
                        </div>
                    }
                }
                if (_.get(o, 'stateName') === '审核拒绝') {
                    return {
                        class: 'normal-row refuse-row',
                    }
                }
                return {
                    class: 'normal-row',
                }
            },
        })
    }, [foldType, onFormChange, projectPopId, onSelectProject, isDetail, setEditData, transactionOptions,
        onSelectTransaction, delItem, devProjectStatusOptions, recommendProjectList, allProjectList,
        filterTransactionOptions, allUserRes, othersReport, devHourTypeOptions, richEditorRef, initExperience])

    const cardTitle = useMemo(() => {
        const effectiveItems = _.filter(initData, x => x.stateName !== '审核拒绝')
        const effectiveTotal = _.sum(_.map(effectiveItems, x => _.toNumber(_.get(x, 'weeklyInputPercentage')) || 0))
        const currentTotal = _.sum(_.map(editData, x => _.toNumber(_.get(x, 'weeklyInputPercentage')) || 0))
        const showTotal = isDetail ? effectiveTotal : currentTotal
        return <div className={'card-title flex center-y'}>
            <div className="header-text">工时提报</div>
            <div className="this-week-total-text">
                本周合计：
            </div>
            <div className="this-week-total-num" style={{ color: showTotal === 100 ? '#52C41A' : 'red' }}>
                {showTotal}%
            </div>
            {
                !isDetail &&
                <div className={'title-tips-wrap flex center-y'}>
                    <Icon name='jingshishuoming' />
                    工时填报合计需要等于100%
                </div>
            }
        </div>
    }, [editData, isDetail, initData])

    const extra = useMemo(() => {
        return <>
            {!isDetail && <TextIconBtn style={{ color: '#5477ff' }} icon={'xiazai2'} text='导入上周数据' onClick={() => setLaseWeekHint(true)} />}
        </>
    }, [isDetail])

    const close = useCallback(() => {
        setLaseWeekHint(false)
    }, [])

    return <div className={'work-time-table flex'}>
        <Card title={cardTitle} className='flex-y x-card-singlegrid' loading={detailLoading || submitLoading} extra={extra}>
            <DataGrid option={option} data={showList} />
        </Card>
        <Drawer header={'流转记录'} footerVisible={false} open={!_.isNil(showDynamicId)}
            cancel={() => setShowDynamicId(null)} onOutsideClick={() => setShowDynamicId(null)}
            contentStyle={{ padding: '4px 8px', width: 500, overflowY: 'auto' }} className={'work-time-table-dynamic'}>
            {
                !_.isNil(showDynamicId) &&
                <ChangeRecord name='工时' funcCode={'7001'} referenceId={showDynamicId} id={showDynamicId} title={''} />
            }
        </Drawer>
        {
            laseWeekHint && <Dialog
                header='提示'
                cancel={close}
                className='lase-week-hint-log'
                confirm={() => importLastWeekData(allProjectList, close)}
            >
                将导入上周工时填报内容，已填写的内容将被覆盖，如项目已不符合提报规则，将不会带出项目信息，请确认！
            </Dialog>
        }
    </div>
}

export default WorkTimeTable;
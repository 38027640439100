import _ from "lodash"
import { uniqKeyFor } from "../../../../project_share/utils/utils";

export function dateChange(num = 1, date = false) {
  if (!date) {
    date = new Date();//没有传入值时,默认是当前日期
    date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }
  date += " 00:00:00";//设置为当天凌晨12点
  date = Date.parse(new Date(date)) / 1000;//转换为时间戳
  date += (86400) * num;//修改后的时间戳
  var newDate = new Date(parseInt(date) * 1000);//转换为时间
  return newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate();
}

export function getAllDate(dateList) {
  let dateArr = []
  _.forEach(_.orderBy(dateList, 'jobdate', 'asc'), item => {
    const formatDate = new Date(item.jobdate)
    const getMonth = (formatDate.getMonth() + 1) < 10 ? `0${formatDate.getMonth() + 1}` : formatDate.getMonth() + 1
    const getDates = formatDate.getDate() < 10 ? `0${formatDate.getDate()}` : formatDate.getDate()
    const getTime = `${formatDate.getFullYear()}年${getMonth}月`
    const getDate = `${getMonth}月${getDates}日`
    const whichDay = '日一二三四五六'.charAt(formatDate.getDay()) //'星期' +
    dateArr.push({ date: getDate, whichDay, flag: item.flag, jobdate: item.jobdate, getTime, getDates })
  })
  return dateArr
}

export function filterTime(date, data) {
  const { beginDate, endDate, month } = date || {}
  if (_.isNil(month)) {
    return _.filter(data, item => {
      return (new Date(beginDate) <= new Date(item.jobdate) && new Date(endDate) >= new Date(item.jobdate))
    })
  }
  return _.filter(data, item => {
    return new Date(month).getMonth() === new Date(item.jobdate).getMonth() && new Date(month).getFullYear() === new Date(item.jobdate).getFullYear()
  })
}

export function getTreeId(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o.id)
    if (!_.isEmpty(o.children)) getTreeId(o.children, arr)
  })
  return arr
}

export const getTreeData = (data, isArea) => {
  const areaData = _.map(_.groupBy(data, x => x.area), item => {
    return {
      level: 1,
      id: uniqKeyFor(),
      userName: _.head(item)?.area,
      totalTime: _.reduce(item, (acc, x) => acc + x.totalTime, 0),
      children: _.map(item, o => {
        return {
          level: 2,
          id: uniqKeyFor(),
          userName: o.userName,
          totalTime: o.totalTime,
          children: _.map(o.porjectList, k => {
            return {
              level: 3,
              id: k.projectId,
              ...k
            }
          })
        }
      })
    }
  })

  const projectData = _.map(data, item => {
    return {
      level: 1,
      id: item.projectId,
      ..._.omit(item, ['userList']),
      children: _.map(item.userList, o => {
        return {
          level: 2,
          id: uniqKeyFor(),
          initProjectId: item.projectId,
          principal: item.principal,
          ...o
        }
      })
    }
  })

  return isArea ? areaData : projectData
}

export function getDaysBetween(before, after) {
  const startDate = Date.parse(before);
  const endDate = Date.parse(after);
  if ((startDate > endDate) || (startDate === endDate)) return 0;
  const days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}

export function getSections(data, flag = false) {
  if (flag) return _.max(_.map(data, o => o.planEndDate))
  return _.min(_.map(data, o => o.planBeginDate))
}

export function getStartTime(data, jobdate, flag = false) {
  if (flag) return _.find(data, o => o.planEndDate === jobdate)
  return _.find(data, o => o.planBeginDate === jobdate)
}
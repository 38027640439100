import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Loader, Messager} from "rootnet-ui";
import './MenuAllocationDialog.scss'
import {Empty, Tree} from "antd";
import _ from 'lodash'
import {isNil} from "../../../appraise/components/method";
import {useGet, usePost} from "rootnet-biz/es/hooks";

function handleTreeOptions(allList, currentList, valueField, parentField, textField){
    return _.map(currentList, item => {
        const childrenList = _.filter(allList, x => x[parentField] === item[valueField])
        const currentItem = {id: item['id'], key: item[valueField], title: item[textField], funcType: _.get(item, 'funcType')}
        if(!_.isEmpty(childrenList)) _.assign(currentItem, {children: handleTreeOptions(allList, childrenList, valueField, parentField, textField )})
        return currentItem
    })
}

function MenuAllocationDialog(props) {
    const {close, allocationObj, refreshMenuList} = props
    const {id, moduleId} = useMemo(()=>allocationObj || {},[allocationObj])
    const [checkedKeys, setCheckedKeys] = useState()
    const {doFetch: getAllocationIdList, loading: initKeysListLoading} = useGet()
    const {doFetch: submitPost} = usePost()
    const {data: allMenuList, loading: menuLoading} = useGet('/uac/menu/list')
    const [submitLoading, setSubmitLoading] = useState(false)

    const currentSystemMenuList = useMemo(()=>{
        return _.filter(allMenuList, x => x.moduleId === moduleId && x.status === 0)
    },[allMenuList, moduleId])

    const currentCheckedIdList = useMemo(()=>{
        if(_.isEmpty(currentSystemMenuList)) return []
        return _.map(_.filter(currentSystemMenuList, x => _.includes(checkedKeys, x.funcCode)),'id')
    },[currentSystemMenuList, checkedKeys])

    const treeOptions = useMemo(()=>{
        if(_.isEmpty(currentSystemMenuList)) return []
        let firstLevel = _.filter(currentSystemMenuList, x => isNil(_.get(x,'pid')))
        return handleTreeOptions(currentSystemMenuList, firstLevel, 'funcCode', 'pid', 'funcName')
    },[currentSystemMenuList])

    useEffect(()=>{
        if(_.isNil(id)) return
        getAllocationIdList('/uac/menu/group/menuList?id='+id).then(res => {
            setCheckedKeys(_.map(res, 'funcCode'))
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[getAllocationIdList, id])

    const getAllChildId = useCallback((id)=>{
        let childrenIdList = []
        findChildNode(id)
        function findChildNode(pid){
            const childList = _.filter(currentSystemMenuList, x => x.pid === pid)
            _.forEach(childList, x => {
                childrenIdList.push(x.funcCode)
                findChildNode(x.funcCode)
            })
        }
        return childrenIdList
    },[currentSystemMenuList])

    // 排除funcType为5
    const getAllCheckChildId = useCallback((id)=>{
        let childrenIdList = []
        findChildNode(id)
        function findChildNode(pid){
            const childList = _.filter(currentSystemMenuList, x => x.pid === pid && _.get(x,'funcType') !== 5)
            _.forEach(childList, x => {
                childrenIdList.push(x.funcCode)
                findChildNode(x.funcCode)
            })
        }
        return childrenIdList
    },[currentSystemMenuList])

    const getAllParentId = useCallback((id)=>{
        let parentIdList = []
        findParentNode(id)
        function findParentNode(id){
            const currentItem = _.find(currentSystemMenuList, x => x.funcCode === id)
            const parentId = _.get(currentItem, 'pid')
            if(isNil(parentId)) return
            parentIdList.push(parentId)
            findParentNode(parentId)
        }
        return parentIdList
    },[currentSystemMenuList])

    const onCheck = useCallback((checkedKeys, info)=>{
        const key = _.get(info, 'node.key')
        const checked = _.get(info, 'checked')
        const allChildrenIdList = getAllChildId(key)
        const allCheckChildrenIdList = getAllCheckChildId(key)
        const allParentIdList = getAllParentId(key)

        if(checked){
            setCheckedKeys(old => _.compact(_.uniq(_.concat(old, [key], allCheckChildrenIdList, allParentIdList))))
        }else{
            const newCheckedKeys = _.filter(checkedKeys.checked, x => !_.includes(allChildrenIdList, x))
            setCheckedKeys(newCheckedKeys)
        }
    },[getAllChildId, getAllParentId, getAllCheckChildId])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        const postParams = {
            funcGroupId: id,
            menuIds: _.join(currentCheckedIdList, ',')
        }
        submitPost('/uac/menu/group/assignMenu', postParams).then(()=>{
            setSubmitLoading(false)
            Messager.show('修改成功', { icon: 'success' })
            refreshMenuList()
            close()
        }).catch(err => {
            setSubmitLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitPost, id, submitLoading, refreshMenuList, close, currentCheckedIdList])

    return <Dialog header={'分配菜单'} className={'menu-allocation-dialog'} cancel={close} confirm={submit}>
        {
            (menuLoading || submitLoading || initKeysListLoading) && <Loader fill/>
        }
        {
            _.isEmpty(treeOptions) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        {
            !_.isEmpty(treeOptions) &&
            <Tree checkable treeData={treeOptions} defaultExpandAll={true} selectable={false} checkStrictly={true}
                  checkedKeys={checkedKeys} onCheck={onCheck}/>
        }
    </Dialog>
}

export default MenuAllocationDialog;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Loader, Messager} from 'rootnet-ui'
import './WorkFlowEditDialog.scss'
import Icon from "../../../project_share/components/Icon";
import { Tag, Tabs, Input } from 'antd';
import FieldSetting from "./fieldSetting/FieldSetting";
import MessageNotify from "./messageNotify/MessageNotify";
import AuthControl from "./authControl/AuthControl";
import PostAction from "./postAction/PostAction";
import _ from 'lodash'
import convertOptions from "../../common/ConvertOptions";
import {isNil} from "../../appraise/components/method";
import useGet from "rootnet-biz/es/hooks/useGet";
import {usePost} from "rootnet-biz/es/hooks";
import CheckConditionGroup from "../../common/view/checkConditionGroup/CheckConditionGroup";
import {CheckBox, FormInput} from "rootnet-edit";
import WorkflowCurrentUserLimit from "./workflowCurrentUserLimit/WorkflowCurrentUserLimit";
import ExclusionCurrentUser from './exclusionCurrentUser';

const { TabPane } = Tabs;
const { TextArea } = Input;

const OPTIONS_URLS = [
    '/WorkflowUsergroup/getWorkflowUsergroupList',
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor' // 互斥节点数据源
]

function WorkFlowEditDialog(props) {
    const {close, cellInfo, cells, workflowId, fieldList, convertCollection, funcCode} = props
    const [activeKey, setActiveKey] = useState('beforeCondition')
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const [beforeConditionList, setBeforeConditionList] = useState()
    const [afterConditionList, setAfterConditionList] = useState()
    const [hideNodeConditionList, setHideNodeConditionList] = useState()
    const [fieldSettingList, setFieldSettingList] = useState()
    const [postActionList, setPostActionList] = useState()
    const [messageNotifyForm, setMessageNotifyForm] = useState()
    const [authControlForm, setAuthControlForm] = useState()
    const {doFetch: getDetail, loading: detailLoading} = useGet()
    const {doFetch: submitPost} = usePost()
    const [isShowBeforeConditionText, setIsShowBeforeConditionText] = useState(false)
    const [beforeConditionText, setBeforeConditionText] = useState()
    const [isShowAfterConditionText, setIsShowAfterConditionText] = useState(false)
    const [afterConditionText, setAfterConditionText] = useState()
    const [exclusionCurrentUser, setExclusionCurrentUser] = useState()
    const [textRemind, setTextRemind] = useState()

    const [limitRangeCondition, setLimitRangeCondition] = useState([])
    const [limitRange, setLimitRange] = useState([])
    const [sort, setSort] = useState(0)

    useEffect(()=>{
        getDetail('/workflow/getWorkflowInfo?configId='+_.get(cellInfo,'id')).then(res => {
            // 初始化条件校验
            setHideNodeConditionList(_.get(res,'hideNodeConditionList'))
            setBeforeConditionList(_.get(res,'beforeConditionList'))
            setAfterConditionList(_.get(res,'afterConditionList'))
            setIsShowBeforeConditionText(_.get(res,'beforeConditionList.isShowConditionText') || false)
            setBeforeConditionText(_.get(res,'beforeConditionList.conditionText') || null)
            setIsShowAfterConditionText(_.get(res,'afterConditionList.isShowConditionText') || false)
            setAfterConditionText(_.get(res,'afterConditionList.conditionText') || null)
            setExclusionCurrentUser(_.get(res, 'exclusionCurrentUser') || null)
            setTextRemind(_.get(res, 'workflowRemind.textRemind') || null)
            setLimitRangeCondition(_.get(res, 'currentUserRanges') || null)
            setLimitRange(_.map(_.get(res, 'currentUserRanges'),'range') || [])
            setSort(_.get(res, 'sort', 0))
            // 初始化字段设置
            const initFieldSettingList = _.map(_.get(res, 'fieldConfigList'), item => {
                const fieldSnowItem = _.find(fieldList, x => x.tableName === item.tableName && x.fieldId === item.fieldId)
                const fieldSnowId = _.get(fieldSnowItem,'id')
                let defaultValue = item.defaultValue
                if(item.defaultType === '1'){
                    defaultValue = _.get(_.find(fieldList, x => x.tableName === item.defaultTableName && x.fieldId === item.defaultValue),'id')
                }
                return ({
                    ...item,
                    fieldSnowId,
                    defaultValue,
                })
            })
            setFieldSettingList(initFieldSettingList)

            // 初始化后置动作
            const initPostActionList = _.map(_.get(res, 'actionList'), item => {
                const fieldSnowItem = _.find(fieldList, x => x.tableName === item.tableName && x.fieldId === item.fieldId)
                const fieldSnowId = _.get(fieldSnowItem,'id')
                let fieldValue = item.fieldValue
                if(item.valueType === '1'){
                    fieldValue = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === item.fieldValue),'id')
                }
                return ({
                    ...item,
                    fieldSnowId,
                    fieldValue,
                })
            })
            setPostActionList(initPostActionList)

            // 初始化消息通知
            const initMessageNotifyForm = _.get(res, 'messageVo')
            initMessageNotifyForm['peopleFieldSnowId'] = _.map(_.get(initMessageNotifyForm,'peopleFieldList'), item => {
                return _.get(_.find(fieldList, x => x.tableName === item.tableName && x.fieldId === item.personnelField),'id')
            })
            setMessageNotifyForm(initMessageNotifyForm)

            // 初始化权限控制
            const initAuthControlForm = _.get(res, 'jurisdictionVo')
            initAuthControlForm['peopleFieldSnowId'] = _.map(_.get(initAuthControlForm,'peopleFieldList'), item => {
                return _.get(_.find(fieldList, x => x.tableName === item.tableName && x.fieldId === item.personnelField),'id')
            })
            setAuthControlForm(initAuthControlForm)
        })
    },[getDetail, fieldList, cellInfo])

    const [userGroupOptions, exclusionNodeOptions] = useMemo(()=>{
        if(_.isNil(optionsRes)) return []
        const [d1, d2] = optionsRes
        return [
            _.map(d1, x => ({text: x.userGroup, value: x.id})),
            _.map(d2, x => ({text: x.displayName, value: x.interiorId})),
        ]
    },[optionsRes])

    const submitMessageNotifyForm = useMemo(()=>{
        const submitMessageNotifyForm = _.clone(messageNotifyForm) || {}
        submitMessageNotifyForm['peopleFieldList'] = _.map(_.get(messageNotifyForm,'peopleFieldSnowId'), snowId => {
            const fieldItem = _.find(fieldList, x => x.id === snowId)
            return ({
                personnelField: _.get(fieldItem,'fieldId'),
                tableName: _.get(fieldItem,'tableName'),
            })
        })
        submitMessageNotifyForm['peopleFieldSnowId'] = null
        return submitMessageNotifyForm
    },[messageNotifyForm, fieldList])

    const submitAuthControlList = useMemo(()=>{
        const submitAuthControlList = _.clone(authControlForm) || {}
        submitAuthControlList['peopleFieldList'] = _.map(_.get(authControlForm,'peopleFieldSnowId'), snowId => {
            const fieldItem = _.find(fieldList, x => x.id === snowId)
            return ({
                personnelField: _.get(fieldItem,'fieldId'),
                tableName: _.get(fieldItem,'tableName'),
            })
        })
        submitAuthControlList['peopleFieldSnowId'] = null
        return submitAuthControlList
    },[authControlForm, fieldList])

    const submitFieldSettingList = useMemo(()=>{
        return _.map(fieldSettingList, (x, i) => {
            const cloneObj = _.clone(x)
            if(_.get(cloneObj,'fieldSnowId')){
                cloneObj['fieldId'] = convertOptions(cloneObj.fieldSnowId, fieldList, 'fieldId','id')
                cloneObj['tableName'] = convertOptions(cloneObj.fieldSnowId, fieldList, 'tableName','id')
            }
            if(isNil(_.get(cloneObj,'defaultValue'))){
                cloneObj['defaultType'] = '0'
            }else{
                if(_.get(cloneObj,'defaultType') === '1'){
                    // 字段值
                    const defaultFieldSnowId = cloneObj['defaultValue']
                    cloneObj['defaultValue'] = convertOptions(defaultFieldSnowId, fieldList, 'fieldId','id')
                    cloneObj['defaultTableName'] = convertOptions(defaultFieldSnowId, fieldList, 'tableName','id')
                }
            }
            cloneObj['sort'] = i + 1
            cloneObj['id'] = null
            cloneObj['fieldSnowId'] = null
            return cloneObj
        })
    },[fieldSettingList, fieldList])

    const submitPostActionList = useMemo(()=>{
        return _.map(postActionList, x => {
            const cloneObj = _.clone(x)
            if(_.get(cloneObj,'fieldSnowId')){
                cloneObj['fieldId'] = convertOptions(cloneObj.fieldSnowId, fieldList, 'fieldId','id')
                cloneObj['tableName'] = convertOptions(cloneObj.fieldSnowId, fieldList, 'tableName','id')
                cloneObj['fieldDbType'] = convertOptions(cloneObj.fieldSnowId, fieldList, 'fieldDbType','id')
            }
            if(isNil(_.get(cloneObj,'fieldValue')) && _.get(cloneObj,'valueType') === '1'){
                cloneObj['valueType'] = '0'
            }else{
                if(_.get(cloneObj,'valueType') === '1'){
                    // 字段值
                    const defaultFieldSnowId = cloneObj['fieldValue']
                    const fieldItem = _.find(fieldList, x => x.id === defaultFieldSnowId)
                    cloneObj['fieldValue'] = `${_.get(fieldItem,'tableName')}.${_.get(fieldItem,'fieldId')}`
                }
            }
            cloneObj['id'] = null
            cloneObj['fieldSnowId'] = null
            cloneObj['fieldValue'] = cloneObj['fieldValue'] || ''
            if(_.get(cloneObj,'valueType') === '5'){
                cloneObj['fieldValue'] = null
            }
            return cloneObj
        })
    },[postActionList, fieldList])

    const [sourceText, targetText, currentText] = useMemo(()=>{
        const sourceId = _.get(cellInfo,'source.cell')
        const sourceItem = _.find(cells, x => x.id === sourceId)
        const sourceText = _.get(sourceItem,'attrs.text.text')
        const targetId = _.get(cellInfo,'target.cell')
        const targetItem = _.find(cells, x => x.id === targetId)
        const targetText = _.get(targetItem,'attrs.text.text')
        const currentText = _.get(cellInfo, 'labels.0.attrs.label.text')
        return [sourceText, targetText, currentText ]
    },[cellInfo, cells])

    const submitBeforeConditionList = useCallback(()=>{
        if(_.isNil(beforeConditionList)) return null
        if(_.isEmpty(_.get(beforeConditionList,'conditionList'))) return null
        const cloneObj = _.clone(beforeConditionList)
        cloneObj['isShowConditionText'] = isShowBeforeConditionText
        cloneObj['conditionText'] = beforeConditionText
        return cloneObj
    },[beforeConditionList, isShowBeforeConditionText, beforeConditionText])

    const submitAfterConditionList = useCallback(()=>{
        if(_.isNil(afterConditionList)) return null
        if(_.isEmpty(_.get(afterConditionList,'conditionList'))) return null
        const cloneObj = _.clone(afterConditionList)
        cloneObj['isShowConditionText'] = isShowAfterConditionText
        cloneObj['conditionText'] = afterConditionText
        return cloneObj
    },[afterConditionList, isShowAfterConditionText, afterConditionText])

    const submitCurrentUserRanges = useCallback(()=>{
        if(_.isEmpty(limitRangeCondition)) return []
        const submitCurrentUserRanges = _.map(_.clone(limitRangeCondition), item => {
            if(_.isNil(item)){
                return {
                    conditionList: [],
                    conditionRelation: "and",
                    isConditionGroup: true
                }
            }else{
                return item
            }
        })
        _.forEach(submitCurrentUserRanges, (item, i) => {
            item['range'] = limitRange[i]
            item['sort'] = _.toString(i)
        })
        return submitCurrentUserRanges
    },[limitRange, limitRangeCondition])

    const submit = useCallback(()=>{
        const submitParams = {
            id: _.get(cellInfo,'id'),
            workflowId,
            beforeConditionList: submitBeforeConditionList(),
            afterConditionList: submitAfterConditionList(),
            hideNodeConditionList,
            fieldConfigList: submitFieldSettingList,
            messageVo: submitMessageNotifyForm,
            jurisdictionVo: submitAuthControlList,
            actionList: submitPostActionList,
            actionName: currentText,
            startFactor: _.get(cellInfo,'source.cell'),
            endFactor: _.get(cellInfo,'target.cell'),
            pageEngineId: funcCode,
            exclusionCurrentUser,
            workflowRemind: {
                textRemind
            },
            currentUserRanges: submitCurrentUserRanges(),
            sort: _.toNumber(sort || 0),
        }
        submitPost('/workflow/updateWorkflowInfo',submitParams).then(()=>{
            Messager.show('保存成功', { icon: 'success' })
            close()
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    },[cellInfo, workflowId, submitFieldSettingList, submitMessageNotifyForm, submitAuthControlList,
        submitPostActionList, close, submitPost, currentText, funcCode, submitBeforeConditionList,
        submitAfterConditionList, exclusionCurrentUser, textRemind, submitCurrentUserRanges, sort, hideNodeConditionList])

    const personFieldOptions = useMemo(()=>{
        const personFieldList = _.filter(fieldList, x => x.viewConditionType === 'personnel')
        return _.map(personFieldList, x => ({
            value: x.id,
            text: `${x.fieldName} (${x.tableName}.${x.fieldId})`,
        }))
    },[fieldList])

    return <Dialog className={'work-flow-edit-dialog'} header={'流转配置'} cancel={close} confirm={submit}>
        <div className={'work-flow-edit-wrap flex-y'}>
            {
                detailLoading && <Loader fill/>
            }
            <div className={'work-flow-info-wrap flex center-y'}>
                <div className="work-flow-name">
                    流转动作名称：{currentText}
                </div>
                <div className="work-flow-node-wrap flex center-y">
                    <Tag color="blue">{sourceText}</Tag>
                    <Icon name={'erjititlefanhui'} className={'next-icon'}/>
                    <Tag color="blue">{targetText}</Tag>
                </div>
                <div className="sort-wrap flex center-y">
                    <FormInput label={'排序'} value={sort} onChange={setSort} type='number' digit={0} min={0} horizontal componentWidth={80}/>
                </div>
            </div>
            <Tabs activeKey={activeKey} onChange={setActiveKey}>
                <TabPane tab="流转前校验" key="beforeCondition">
                    <CheckConditionGroup allColumns={fieldList} editData={beforeConditionList} setEditData={setBeforeConditionList}/>
                    <div className={'not-match-tips-wrap flex-y'}>
                        <div className="tips-header flex space-between center-y">
                            <div className="tips-text">提示：</div>
                            <div className="is-show-text">
                                <CheckBox value={isShowBeforeConditionText} onChange={setIsShowBeforeConditionText}>当条件不满足时，展示提示信息</CheckBox>
                            </div>
                        </div>
                        <TextArea value={beforeConditionText} onChange={e => setBeforeConditionText(e.target.value)} autoSize={{minRows: 3}}/>
                    </div>
                </TabPane>
                <TabPane tab="流转后校验" key="afterCondition">
                    <CheckConditionGroup allColumns={fieldList} editData={afterConditionList} setEditData={setAfterConditionList}/>
                    <div className={'not-match-tips-wrap flex-y'}>
                        <div className="tips-header flex space-between center-y">
                            <div className="tips-text">提示：</div>
                            <div className="is-show-text">
                                <CheckBox value={isShowAfterConditionText} onChange={setIsShowAfterConditionText}>当条件不满足时，展示提示信息</CheckBox>
                            </div>
                        </div>
                        <TextArea value={afterConditionText} onChange={e => setAfterConditionText(e.target.value)} autoSize={{minRows: 3}}/>
                    </div>
                </TabPane>
                <TabPane tab="字段设置" key="fieldSetting">
                    <FieldSetting list={fieldSettingList} setList={setFieldSettingList} {...{fieldList, convertCollection}}/>
                </TabPane>
                <TabPane tab="消息通知" key="messageNotify">
                    <MessageNotify formData={messageNotifyForm} setFormData={setMessageNotifyForm} {...{personFieldOptions, userGroupOptions}}/>
                </TabPane>
                <TabPane tab="权限控制" key="authControl">
                    <AuthControl formData={authControlForm} setFormData={setAuthControlForm} {...{personFieldOptions, userGroupOptions}}/>
                </TabPane>
                <TabPane tab="后置动作" key="postAction">
                    <PostAction list={postActionList} setList={setPostActionList} {...{fieldList, convertCollection}}/>
                </TabPane>
                <TabPane tab="提示文本" key="textRemind">
                    <TextArea placeholder={'请输入工作流提示文本'} value={textRemind} onChange={e => setTextRemind(e.target.value)} autoSize={{minRows: 3}}/>
                </TabPane>
                {/*<TabPane tab="处理人部门范围" key="currentUserDept">*/}
                {/*    <CheckConditionGroup allColumns={fieldList} editData={departmentConditionList} setEditData={setDepartmentConditionList}/>*/}
                {/*    <CurrentUserLimitDept value={departmentList} setValue={setDepartmentList}/>*/}
                {/*</TabPane>*/}
                <TabPane tab="处理人范围" key="limitRange">
                    <WorkflowCurrentUserLimit {...{fieldList,limitRangeCondition, setLimitRangeCondition,limitRange, setLimitRange}}/>
                </TabPane>
                <TabPane tab="处理人互斥" key="exclusionCurrentUser">
                    <ExclusionCurrentUser formData={exclusionCurrentUser} setFormData={setExclusionCurrentUser} {...{exclusionNodeOptions}} />
                </TabPane>
                <TabPane tab="不展示条件" key="hideNodeCondition">
                    <CheckConditionGroup allColumns={fieldList} editData={hideNodeConditionList} setEditData={setHideNodeConditionList}/>
                </TabPane>
            </Tabs>
        </div>
    </Dialog>
}

export default WorkFlowEditDialog;
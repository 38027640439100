import React, { useMemo, useState } from 'react'
import { isNil } from 'rootnet-core/format'
import { DataGrid } from 'rootnet-ui'
import convertOptions from '../../../../../common/ConvertOptions'
import { OperationList } from '../../../../../common/OperationList'
import MatchcodeDetails from './matchcodeDetails'

function getColumns(props) {
  const { allUserRes, setOpen, codeReviewResultOpt } = props
  return [
    { header: '检视人员', bind: 'codeReviewUser', width: 90, tooltip: true, align: 'center', convert: codeReviewUser },
    // { header: '开发人员', bind: 'userId', width: 90, tooltip: true, align: 'center', convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '发现问题个数', bind: 'codeReviewErrCount', width: 90, tooltip: true, align: 'center' },
    { header: '检视状态', bind: 'codeReviewResult', width: 90, tooltip: true, align: 'center', convert: v => convertOptions(v, codeReviewResultOpt) },
    // { header: '首次接收时间', bind: 'firstDevAcceptdate', width: 120, tooltip: true, align: 'center' },
    // { header: '完成时间', bind: 'firstTestAcceptdate', width: 120, tooltip: true, align: 'center' },
    { header: '操作', width: 90, tooltip: true, align: 'center', convert: createOperation },
  ]
  function codeReviewUser(v) {
    const userName = convertOptions(v, allUserRes, 'userName', 'userAccount')
    return isNil(userName) ? v : userName
  }
  function createOperation(v, o, i) {
    const options = [
      { text: '详情', onClick: () => setOpen({ id: o.id, loginUser: o.codeReviewUser }) }
    ]
    return <OperationList options={options} />
  }

}

const gridOptsFor = (columns) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  autoFill: true,
  virtualized: true,
  fixedRight: 1,
  columns,
})

export default function Matchcode(props) {
  const { allUserRes, data, loading, refresh, stateOpt, codeReviewResultOpt, tracerState, isClose } = props
  const [open, setOpen] = useState(false)
  const option = useMemo(() => gridOptsFor(getColumns({ allUserRes, setOpen, codeReviewResultOpt })), [allUserRes, codeReviewResultOpt])

  return (
    <>
      <DataGrid loading={loading} data={data} option={option} />
      {open && <MatchcodeDetails {...{ refresh, stateOpt, codeReviewResultOpt, allUserRes, ...open, close: () => setOpen(false), tracerState, isClose }} />}
    </>
  )
}

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Input } from 'antd';
import './TextAreaInput.scss'
import clsx from "clsx";
import {useControlledError} from "../personSelect/useControlledError";
import _ from 'lodash'
import {isNil} from "../../appraise/components/method";

const { TextArea } = Input;

function TextAreaInput(props) {
    const {className, onChange, outerError , ...rest} = props
    const [stateValue, setStateValue] = useState(props.value || props.defaultValue)
    const [edit, setEdit] = useState(false)
    const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);
    const boxRef = useRef()

    const value = useMemo(()=>{
        return props.value === undefined ? stateValue : _.toString(props.value)
    },[props.value, stateValue])

    const handleChange = useCallback((e)=>{
        const v = e.target.value
        if (v !== value) {
            setStateValue(v)
            if (onChange){
                onChange(v)
            }
        }
    },[onChange, value])

    const handleFocus = useCallback(()=>{
        setEdit(true)
        if(props.onFocus){
            props.onFocus()
        }
    },[props])

    const handleBlur = useCallback(()=>{
        setUserActive(true)
        setEdit(false)
        if(props.onBlur){
            props.onBlur()
        }
    },[props, setUserActive])

    useEffect(() => {
        const err = (props.required && isNil(stateValue)) ? '必填项！' : null
        if (err !== error) {
            setStateError(err)
            if (props.onError){
                props.onError(err)
            }
        }
    }, [stateValue, props, error, setStateError])

    const show_error = useMemo(()=>{
        return (error || outerError) && errorVisible && !edit
    },[error, errorVisible, edit, outerError])

    return <TextArea className={clsx('text-area-input',{ error: show_error, disabled: props.disabled },className)} ref={boxRef}
                     value={stateValue} onChange={handleChange} onBlur={handleBlur} onFocus={handleFocus}
                     {...rest} />
}

TextAreaInput.defaultProps = {
    placeholder: '请输入',
    rows: 3,
    required: false,
    disabled: false,
}

export default TextAreaInput;
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Button, Dialog, Messager, Loader } from 'rootnet-ui'
import './DevProjectStageUpdateDialog.scss'
import clsx from "clsx";
import { TextIconBtn } from "../../../../../common/TextIconBtn";
import Icon from "../../../../../../components/Icon";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import DevProjectStageUpdateHeader from "./devProjectStageUpdateHeader/DevProjectStageUpdateHeader";
import { Badge, Tabs } from "antd";
import _ from "lodash";
import ChangeRecord from "../../../../../common/ChangeRecord";
import DevProjectStageInfo from "./devProjectStageInfo/DevProjectStageInfo";
import convertGlobalConstOptions from "../../../../../common/ConvertGlobalConstOptions";
import DevProjectStageUploadArea from "./devProjectStageUploadArea/DevProjectStageUploadArea";
import { dateFormat } from "rootnet-core/dateFormat";
import { isNil } from "rootnet-core/format";
import ReqStageRelateConference
    from "../../../../../requirementMgt/requirementDetailDialog/requirementStage/requirementStageUpdateDialog/reqStageRelateConference/ReqStageRelateConference";
import ConferenceRecordsAdd
    from "../../../../../conferenceMgt/conferenceRecords/conferenceRecordsAdd/ConferenceRecordsAdd";

const { TabPane } = Tabs;
const DIALOG_TITLE = {
    'add': '新增阶段',
    'edit': '编辑阶段',
    'detail': '阶段详情',
}

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=DevPlanStatus',
]

function DevProjectStageUpdateDialog(props) {
    const { currentStageInfo, close, showList, projectId, refreshList, updateDynamic, updateOuterFile, setShowList, projectInfo, keyNodesOptions, editAuth, isShow, isFinanceCoding } = props
    const { data: allUserRes } = useGet('/common/userinfo')
    const { stageId, mode: initMode, stageObj, stageIndex, isCoding } = currentStageInfo || {}
    const [mode, setMode] = useState(initMode)
    const [editFormData, setEditFormData] = useState()
    const [formError, setFormError] = useState()
    const stageRichEditorRef = useRef()
    const { doFetch: update } = usePost()
    const { data: initDetail, doFetch: getDetail } = useGet()
    const [activeKey, setActiveKey] = useState('info')
    const [countInnerDynamic, updateInnerDynamic] = useReducer((x) => x + 1, 0)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [stageDocList, setStageDocList] = useState()
    const [fileCount, setFileCount] = useState(0)
    const [countConference, updateConference] = useReducer((x) => x + 1, 0)
    const [showAddConference, setShowAddConference] = useState(false)

    useEffect(() => {
        const value = _.get(stageObj, 'relateDocument')
        setStageDocList(isNil(value) ? [] : _.split(value, ','))
    }, [stageObj])

    const refreshDetail = useCallback(() => {
        getDetail('/userProject/stage/info?id=' + stageId).then(res => {
            setEditFormData(res)
            setTimeout(() => {
                if (stageRichEditorRef.current) {
                    stageRichEditorRef.current.setContent(_.get(res, 'delivery') ? _.get(res, 'delivery') : '')
                }
            }, 500)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getDetail, stageId])

    useEffect(() => {
        if (initMode === 'add') {
            setEditFormData({ isParent: 'N', milestoneFlag: 'N', status: '01', isBaselineRelease: 'N', isStageQualityAudit: 'N', })
        } else {
            refreshDetail()
        }
    }, [initMode, refreshDetail])

    const [statusOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const isAdd = useMemo(() => {
        return mode === 'add'
    }, [mode])

    const switchMode = useCallback((reset = false) => {
        if (reset) {
            setEditFormData(initDetail)
        }
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [initDetail])

    const canSubmit = useMemo(() => {
        if (!_.get(editFormData, 'title')) {
            return false
        }
        if (!_.get(editFormData, 'status')) {
            return false
        }
        return !_.some(_.values(formError), x => x)
    }, [formError, editFormData])

    const isStatus = useMemo(() => {
        return _.includes(['09', '10'], _.get(editFormData, 'status'))
    }, [editFormData])

    const submit = useCallback(() => {
        const submitForm = _.omit(initDetail, 'relateDocument')
        const submitEditForm = _.omit(editFormData, 'relateDocument')
        if (JSON.stringify(submitForm) === JSON.stringify(submitEditForm)) {
            if (_.get(initDetail, 'delivery') === stageRichEditorRef.current.getContent()) {
                setMode('detail')
                return Messager.show('无变更')
            }
        }
        if (submitLoading) return
        setSubmitLoading(true)
        const serial = isAdd ? (_.get(_.last(showList), 'serial') + 1 || 1) : _.get(editFormData, 'serial')
        let completeTimeActual = _.get(editFormData, 'completeTimeActual')
        if (_.isNil(completeTimeActual) && _.get(editFormData, 'status') === '03') {
            completeTimeActual = dateFormat('YYYYMMDD', new Date()) + '000000'
        }
        const postParams = _.assign({}, {
            ...editFormData,
            status: isAdd ? '01' : _.get(editFormData, 'status'),
            projectId,
            serial,
            delivery: stageRichEditorRef.current.getContent(),
            workloadActual: _.get(editFormData, 'workloadActual') ? N2Float(_.get(editFormData, 'workloadActual')) : null,
            completeTimeActual,
        }, isStatus && { startTimePlan: null, completeTimePlan: null, completeTimeActual: null })

        const url = isAdd ? '/userProject/stage/add' : '/userProject/stage/update'
        update(url, postParams).then(() => {
            Messager.show(isAdd ? '添加成功' : '修改成功', { icon: 'success' })
            setSubmitLoading(false)
            refreshList()
            if (isAdd) {
                close()
            } else {
                refreshDetail()
                updateDynamic()
                updateInnerDynamic()
                setMode('detail')
            }
        }).catch(err => {
            Messager.show(err.Message, { icon: 'error' });
            setSubmitLoading(false)
        })
    }, [isAdd, showList, editFormData, projectId, update, refreshList, updateDynamic, refreshDetail, updateInnerDynamic, close, initDetail, submitLoading, isStatus])

    const [isParentTemplate, isTemplate] = useMemo(() => {
        return [
            (_.get(initDetail, 'isTemplate') === 'Y' && _.isNil(_.get(initDetail, 'parentId'))),
            _.get(initDetail, 'isTemplate') === 'Y'
        ]
    }, [initDetail])

    return <Dialog className={'dev-project-stage-update-dialog'} headerVisible={false} footerVisible={false} contentClassName={clsx({ 'is-add-content': isAdd })}>
        <div className="content-wrap flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    {DIALOG_TITLE[mode]}
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        editAuth &&
                        <>
                            {
                                mode !== 'add' && isShow &&
                                <span style={{ marginRight: 16 }}>
                                    <TextIconBtn icon={'tianjia'} className={`header-edit-text-icon`} text={'创建会议纪要'} onClick={() => {
                                        setShowAddConference(true)
                                    }} />
                                </span>
                            }
                            {
                                !isAdd && isShow &&
                                <span style={{ marginRight: 16 }}>
                                    <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                                        switchMode(true)
                                    }} />
                                </span>
                            }
                        </>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            {
                !isAdd &&
                <DevProjectStageUpdateHeader {...{ editFormData, isDetail, setEditFormData, statusOptions, isParentTemplate, isFinanceCoding, isCoding }} />
            }
            <div className="content-main-panel flex">
                <div className="detail-left-panel">
                    <Tabs activeKey={activeKey} onChange={setActiveKey}>
                        <TabPane tab='描述' key={'info'}>
                            <DevProjectStageInfo {...{ editFormData, setEditFormData, formError, setFormError, mode, isDetail, statusOptions, allUserRes, stageRichEditorRef, keyNodesOptions, isParentTemplate, isTemplate, isStatus }} />
                        </TabPane>
                        {
                            mode !== 'add' &&
                            <TabPane tab='会议纪要' key={'conference'}>
                                <div className={'conference-tab-wrap'} key={countConference}>
                                    <ReqStageRelateConference afterRefresh={refreshList}
                                        listUrl={'/userProject/stage/recordListByStage'}
                                        disassociateUrl={'/userProject/stage/disassociate/record'}
                                        relateUrl={'/userProject/stage/related/record'}
                                        {...{ stageId, updateInnerDynamic, isShow }} />
                                </div>
                            </TabPane>
                        }
                        <TabPane tab={
                            <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                                附件
                            </Badge>
                        } key={'file'} forceRender={true}>
                            <DevProjectStageUploadArea id={projectId} stageDocList={stageDocList} funcCode={'36'}
                                {...{ allUserRes, stageIndex, stageObj, updateOuterFile, setShowList, setFileCount, isDel: isShow, isUpload: isShow }} />
                        </TabPane>
                    </Tabs>
                </div>
                {
                    mode !== 'add' &&
                    <div className="dynamic-wrap" key={countInnerDynamic}>
                        {
                            !_.isNil(stageId) &&
                            <ChangeRecord name='研发项目阶段' funcCode={'1702'} referenceId={stageId} id={stageId}
                                title={_.get(editFormData, 'title')} followBtn={false} commentWidth={'90%'} />
                        }
                    </div>
                }
                {
                    submitLoading &&
                    <Loader fill />
                }
            </div>
            <div className="mock-footer flex center-y">
                <div />
                <div />
                <div className="btn-group flex">
                    {
                        isDetail &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        !isDetail &&
                        <Button normal onClick={() => {
                            if (mode === 'add') {
                                close()
                            } else {
                                switchMode(true)
                            }
                        }}>取消</Button>
                    }
                    {
                        !isDetail &&
                        <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                    }
                </div>
            </div>
        </div>
        {
            showAddConference &&
            <ConferenceRecordsAdd close={() => setShowAddConference(false)}
                refreshList={() => {
                    updateInnerDynamic()
                    refreshList()
                    updateConference()
                }}
                initForm={{
                    sSubject: `${_.get(projectInfo, 'projectName') || ''}`,
                    sConferenceType: 'PRJ',
                    scustomer: _.get(projectInfo, 'tCustId') || null,
                    stageSource: 'devProject',
                    stageId: stageId
                }}
            />
        }
    </Dialog>
}

function N2Float(valueParams) {
    if (_.isNil(valueParams)) return ''
    let value = Math.round(parseFloat(valueParams) * 100) / 100;
    let s = value.toString().split(".");
    if (s.length === 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

export default DevProjectStageUpdateDialog;
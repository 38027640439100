import React from 'react';
import { useHistory } from "react-router-dom";
import { Tab, TabItem, LinkButton } from 'rootnet-ui';
import { Icon } from '../../components';
import BaseInfo from "./baseInfo/BaseInfo";
import TaskDetail from "./taskDetail/TaskDetail";
import Milepost from './Milepost/Milepost';
import Auth from './Authorized/Auth'
import './ProjectListRouter.scss';

//产品管理 --TAB配置路由
function Routers(props) {
    const history = useHistory();
    return <div className="rims-router x flex-y">
        <LinkButton className="router-back" circle onClick={() => history.push('/project')}>
            <Icon name="erjititlefanhui" />
        </LinkButton>
        <Tab active={0}>
            <TabItem header="基本信息"><BaseInfo {...props} /></TabItem>
            <TabItem header="任务明细"><TaskDetail {...props} /></TabItem>
            <TabItem header="里程碑"><Milepost {...props} /></TabItem>
            <TabItem header="授权管理"><Auth {...props} /></TabItem>
        </Tab>
    </div>
}

export default Routers;
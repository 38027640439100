import React, { useLayoutEffect } from 'react'
import _ from 'lodash'
import RichTextEditor from '../../../common/richTextEditor/TinyEditor'
import './index.scss'

export default function CustomerCommunicationRecord(props) {
  const { initFormData, isDetail, editFormData, customerRecordsRef } = props

  useLayoutEffect(() => {
    if (_.isEmpty(initFormData)) return
    setTimeout(() => {
      if (customerRecordsRef.current) {
        const value = _.isNil(initFormData?.custcommunicationlog) ? '' : initFormData?.custcommunicationlog
        customerRecordsRef.current.setContent(value)
      }
    }, 500)
  }, [initFormData, customerRecordsRef])

  return (
    <div className='customer-communication-record'>
      <div className="rich-text-detail-wrap flex" style={{ display: isDetail ? "block" : 'none' }}>
        <div dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'custcommunicationlog') }} />
      </div>
      <div className="rich-text-area" style={{ display: !isDetail ? "block" : 'none' }}>
        <RichTextEditor ref={customerRecordsRef} height={'100%'}/>
      </div>
    </div>
  )
}

import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import { Icon } from '../../../../../../components'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import DisplaySelect from '../../../../../common/displaySelect/DisplaySelect'
import './index.scss'

const EDIT_URL = '/storyMatrix/updateAndAdd'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=storyMatrixFields',
  '/common/globalconst?globalConst=StoryMatrix',
]

const HFormInput = props => <FormInput horizontal labelWidth={60} componentWidth={310} {...props} />

export default function AdjustStateEdit(props) {
  const { close, refreshViewList, ids, projectId } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [bindOptins, statusOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, o => convertGlobalConstOptions(o))
  }, [globalConstOptionsRes])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(error), x => x)
  }, [error])

  const confirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.assign({}, params, { ids, projectId })
    doFetch(EDIT_URL, postParams)
      .then(() => {
        refreshViewList()
        setIsDisable(false)
        Messager.show('修改成功', { icon: 'success' })
        close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, doFetch, params, refreshViewList, close, ids, projectId])

  return (
    <Dialog
      headerVisible={false}
      className='adjust-state-edit-dialog'
      cancel={close}
      confirm={confirm}
      confirmButtonDisabled={!canSubmit}
    >
      <div className='adjust-state-edit-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title flex center-y">
            调整状态
          </div>
          <div className="mock-right-header flex center-y">
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <div className="adjust-state-edit-main-panel">
          <Form value={params} onChange={setParams} error={error} onError={setError}>
            <HFormInput required search label='阶段' bind='fieldNameList' component={DisplaySelect} options={bindOptins} multiple />
            <HFormInput required search label='状态' bind='fieldValue' component={DisplaySelect} options={statusOptions} />
          </Form>
        </div>
      </div>
    </Dialog>
  )
}

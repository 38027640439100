import _ from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormInput, Radio } from 'rootnet-edit'
import { Dialog, Messager } from 'rootnet-ui'
import { useApi } from '../../../../utils/hook'
import { ModeContext } from '../../../common/Context'

const LIST_URL = '/test/plan/list'
const ADD_URL = '/test/plan/addConditions'

const headeStyle = {
  fontSize: 1,
  color: '#BBBBBB'
}

export default function AssociatedTestPlan() {
  const { setModeAll, selectData, refresh, getSqlData } = useContext(ModeContext)
  const [params, setParams] = useState()
  const [selectedVal, setSelectedVal] = useState(null)
  const { data, doFetch, loading } = useApi()

  useEffect(() => {
    doFetch(LIST_URL, 'post', _.assign({}, params, { status: [1, 2] }, getSqlData && { factor: getSqlData }))
  }, [doFetch, getSqlData, params])

  const Header = useCallback(() => {
    return <div>
      <span>关联到测试计划</span>
      <span style={headeStyle}>（已选{_.size(selectData)}个用例）</span>
    </div>
  }, [selectData])

  return (
    <Dialog
      cancel={() => setModeAll(null)}
      header={<Header />}
      loading={loading}
      className='associatedTestPlan'
      confirm={confirm}
      confirmButtonDisabled={_.isNull(selectedVal)}
    >
      <FormInput label='选择未完成的测试计划' onChange={v => setParams({ planName: v })} componentWidth={480} />
      <ul className='choosePlan'>
        {
          _.map(data, o => {
            return <li key={o.id} onClick={() => setSelectedVal(o.id)}>
              <Radio value={selectedVal === o.id} onChange={() => setSelectedVal(o.id)} />
              <div className='separateStyle'>
                <span>{o.planName}</span>
                <span style={{ color: o.code === '未开始' ? '#34B728' : '#FFB630' }}>{o.code}</span>
              </div>
            </li>
          })
        }
      </ul>
    </Dialog>
  )

  function confirm() {
    if (_.isEmpty(selectData)) return Messager.show('请在测试用例页面选择数据后在进行操作', { icon: 'error' })
    const listId = _.map(selectData, o => o.id)
    const parameter = { relateIdListAdd: listId, planId: selectedVal, type: 'case' }
    doFetch(ADD_URL, 'post', parameter)
      .then(() => {
        refresh()
        Messager.show(`关联成功`, { icon: 'success' })
        setModeAll(null)
      })
      .catch((err) => {
        Messager.show(err._message, { icon: 'error' })
        setModeAll(null)
      })
  }

}

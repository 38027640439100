import React, { useState } from 'react'
import _ from 'lodash'
import { FormInput, Display, TextArea, Form } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'

const HFormInput = (props) => <FormInput horizontal component={Display} labelWidth={110} componentWidth={200} {...props} />

export default function TestAnalysis(props) {
  const { isDetail, editFormData, setEditFormData, allUserRes } = props
  const [checkAnalysis, setCheckAnalysis] = useState(true) // 验证分析
  const [solutions, setSolutions] = useState(true) // 解决措施
  const [currentProgress, setCurrentProgress] = useState(true) // 当前进度

  return (
    <div className='test-analysis'>
      <Form value={editFormData} onChange={setEditFormData}>
        <FormInput horizontal labelWidth={110} componentWidth={180} label='测试负责人'
          bind='testPrincipal'
          component={isDetail ? Display : UserSelect}
          convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')}
        />
        <div className="area-wrap">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: checkAnalysis ? 'none' : 'rotate(-90deg)' }} onClick={() => setCheckAnalysis(v => !v)} />
            测试验证分析
          </div>
          <div className="area-content-wrap" style={{ height: checkAnalysis ? '' : 0 }}>
            {!isDetail && <HFormInput label='' bind='testCheckAnalysis' counter={false} maxLength={99999} component={TextArea} />}
            {isDetail && <div className='mtext-style'>{_.get(editFormData, 'testCheckAnalysis')}</div>}
          </div>
        </div>

        <div className="area-wrap">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: solutions ? 'none' : 'rotate(-90deg)' }} onClick={() => setSolutions(v => !v)} />
            测试解决措施
          </div>
          <div className="area-content-wrap" style={{ height: solutions ? '' : 0 }}>
            {!isDetail && <HFormInput label='' bind='testMeasures' counter={false} maxLength={99999} component={TextArea} />}
            {isDetail && <div className='mtext-style'>{_.get(editFormData, 'testMeasures')}</div>}
          </div>
        </div>

        <div className="area-wrap">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: currentProgress ? 'none' : 'rotate(-90deg)' }} onClick={() => setCurrentProgress(v => !v)} />
            测试当前进度
          </div>
          <div className="area-content-wrap" style={{ height: currentProgress ? '' : 0 }}>
            {!isDetail && <HFormInput label='' bind='testEvolve' counter={false} maxLength={99999} component={TextArea} />}
            {isDetail && <div className='mtext-style'>{_.get(editFormData, 'testEvolve')}</div>}

          </div>
        </div>
      </Form>
    </div>
  )
}

import cls from 'clsx'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState, useReducer } from 'react'
import { Form, FormInput, Select } from 'rootnet-edit'
import { Button, DataGrid, Tooltip, Pagination } from 'rootnet-ui'
import { Prompt } from 'react-router-dom'
import { isNil, N2 } from 'rootnet-core/format'
import { Icon } from '../../../project_share/components'
import { OperationList } from '../../common/OperationList'
import { useGet } from '../../../utils/hook'
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import { Jurisdictions, priceMark, Shuttle } from './controls/jurisdictions'
import DrawerTemplate from './controls/DrawerTemplate'
import CostDetails from './controls/costDetails'
import './projectManagement.scss'
import gd from "../../../base/global";
import InfoForm from './controls/infoForm'
import InfoDetail from './controls/infoDetail'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { API1 } from '../../../base/task'
import { pathSearchFor } from '../../../utils/publicFun'
import UserSelect from "../../common/personSelectPop/UserSelect";
import { useFuncCode } from '../../common/commonMethod'

const publicUrl = '/common/globalconst?globalConst='
const URL = [
  publicUrl + 'PSAProjectType',
  publicUrl + 'PSAProjectStatus',
  '/common/userinfo',
  '/customer/customerList',
  '/common/user/positions?posIds=PM,PMO,SurpportEM,SurpportPM'
]

const priceFlagOpt = [
  { value: 'N', text: 'N' },
  { value: 'Y', text: 'Y' },
]

const projectStatus = Object.freeze({
  '01': ['进行中', 'black'],
  '02': ['挂起', '#E15722'],
  '03': ['完成', '#33CC8A '],
  '04': ['作废', '#F5221B'],
})

const prompt = <span>
  已用成本<Tooltip title='可以通过点击当前列数据查看对应项目的成本明细(支持导出)'><Icon style={{ fontSize: '14px', color: '#5477FF' }} name='bangzhu' /></Tooltip>
</span>

const getColumns = (tree, jurisdiction, history, setOpen, setCostProjectDetail, setIsShow, setIsDetail, setOperation) => {
  return {
    fixedLeft: 2,
    fixedRight: 1,
    nilText: '-',
    columns: [
      { header: '#', bind: 'order', width: 40, align: 'center', },
      { header: '项目', bind: 'projectName', width: 260, convert: (v, o) => tree(v, o), class: 'tab-leve' },
      { header: '项目分类', bind: 'projectTypeName', width: 100, tooltip: true, },
      { header: '项目状态', bind: 'projectStatus', width: 75, convert: v => <span style={{ color: _.get(projectStatus, `${[v]}[1]`) }}>{_.get(projectStatus, `${[v]}[0]`)}</span> },
      { header: '项目经理', bind: 'projectManagerName', width: 90, tooltip: true },
      { header: '交付日期', bind: 'endDate', width: 110, convert: v => v ? _.split(v, ' ', 1) : v, tooltip: true },
      {
        header: '预算总成本', bind: 'budgetCost', width: 95, align: 'right', tooltip: true, convert: (v, o) => {
          let bgColor = '#33CC8A'
          let costPercent = calCostPercent(o)
          if (costPercent >= 100) {
            bgColor = '#F5221B'
          } else if (costPercent >= 80) {
            bgColor = '#FAAD14'
          }
          return <Tooltip title={`已用成本${costPercent}%`}>
            <div className={'budget-cost-wrap flex-y'}>
              <div className={'budget-cost-value'}>{N2(v)}</div>
              <div className="progress-bg">
                <div className={'progress'} style={{ background: bgColor, width: `${_.min([costPercent, 100])}%` }} />
              </div>
            </div>
          </Tooltip>
        }
      },
      { header: '已分配成本', bind: 'assignedCost', width: 95, align: 'right', convert: v => N2(v), tooltip: true },
      { header: '未分配成本', bind: 'assignableCost', width: 95, align: 'right', convert: (v, o) => o.budgetCost - o.assignedCost >= 0 ? N2(o.budgetCost - o.assignedCost) : <span style={{ color: 'red' }}>{N2(o.budgetCost - o.assignedCost)}</span>, tooltip: true },
      { header: prompt, bind: 'usedCost', width: 95, align: 'right', tooltip: true, convert: convertUsedCost },
      { header: '预算总收入', bind: 'budgetRev', width: 95, align: 'right', convert: N2, tooltip: true },
      { header: '预算利润率', bind: 'profit', width: 90, align: 'right', convert: (v, o) => `${isFinite((_.round((o.budgetRev - o.budgetCost) / o.budgetRev * 100, 1)).toFixed(1)) ? (_.round((o.budgetRev - o.budgetCost) / o.budgetRev * 100, 1)).toFixed(1) : 0}%`, tooltip: true },
      { header: '项目ID', bind: 'id', width: 165, tooltip: true, },
      { header: '客户', bind: 'customerName', width: 90, tooltip: true },
      { header: '核价单号', bind: 'priceId', width: 200, tooltip: true, },
      { header: '创建时间', bind: 'createTime', width: 150, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
      { header: '操作', width: 200, align: 'center', convert: createOperation },
    ],
  }

  function calCostPercent(o) {
    if (_.toNumber(o.usedCost) === 0) {
      return 0
    } else if (_.toNumber(o.budgetCost) === 0) {
      return 100
    } else {
      return _.round((_.toNumber(o.usedCost) / _.toNumber(o.budgetCost)) * 100, 1)
    }
  }

  function convertUsedCost(v, o) {
    if (_.isNil(v)) return null
    const showValue = <span style={{ color: v > o.budgetCost ? 'red' : null }}>{N2(v)}</span>
    return showValue
    // return <div className='col-hover-blue' onClick={() => clickUsedCost(o)} style={{ color: v > o.budgetCost ? 'red' : '#5477FF' }}>{showValue}</div>
    // function clickUsedCost(o) {
    //   setCostProjectDetail({ csvFileName: o.projectName, searchParams: o.id, priceFlag: o.priceFlag })
    // }
  }

  function createOperation(v, o, i) {
    const moreDisable = !(project_type() && (Number(o.level) === 0 || Number(o.leafProjectFlag) === 1))
    const userAccount = _.get(gd, '_user.operator_id');
    const options = [
      { text: '修改', onClick: () => setOperation({ mode: 'edit', data: o }), disabled: (o.projectStatus === '03' || o.projectStatus === '04') || jurisdiction === 'Manager' || o.jurisdiction },
      {
        text: '添加子项目',
        onClick: () => setOperation({ mode: 'addSubproject', data: o }),
        disabled: (o.projectStatus === '03' || o.projectStatus === '04') || o.jurisdiction || jurisdiction === 'Manager' || o.level === 4 || o.leafProjectFlag === '1'
      },
      {
        text: '更多', value: 'more', children: [
          { text: '补贴信息', disabled: o.projectStatus === '04' || moreDisable, onClick: () => history.push(`/subsidy?id=${o.id}`) },
          { text: '需求分配', disabled: isDisabled() || o.projectStatus === '04' || moreDisable || (o.leafProjectFlag === '1' && o.projectType === "IMP"), onClick: () => setOpen(o) },
          { text: '变更记录', onClick: () => setIsShow(o) }
        ]
      },
    ]
    return <OperationList options={options} />
    function project_type() {
      if (o.projectType) {
        return o.projectType.split(',').some(v => (v === 'IMP' || v === 'CUS' || v === 'DEV'))
      }
    }
    function isDisabled() {
      if (jurisdiction) {
        if (jurisdiction === 'PMO') return false
        if (userAccount === o.projectManager) return false
        return true
      }
    }
  }
}

let len = 0
// function removal(o) {
//   const result = []
//   const repeat = {}
//   if (o) {
//     for (let i = 0; i < o.length; i++) {
//       if (!repeat[o[i].userAccount]) {
//         result.push(o[i])
//         repeat[o[i].userAccount] = true
//       }
//     }
//     return result
//   }
// }

const INIT_PARAMS = {
  projectName: null,
  projectType: null,
  customer: null,
  projectManager: null,
  id: null,
  businessId: null,
  projectStatus: null,
  createUser: null,
  priceId: null,
  priceFlag: null,
}

const INIT_PAGING = {
  pageNum: 1,
  pageSize: 20,
}

const projectManagerUrl = '/common/user/positions?posIds=PM,PMO,SurpportEM,SurpportPM'
export default function ProjectManagement(props) {
  const { history, location } = props
  const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
  const { data: jurisdictionData } = useGet('/common/uacUserRole') //权限
  const jurisdiction = useMemo(() => Jurisdictions(jurisdictionData), [jurisdictionData])
  const priceFlag = useMemo(() => priceMark(jurisdictionData), [jurisdictionData])
  !JSON.parse(sessionStorage.getItem('searchData')) && sessionStorage.setItem('searchData', JSON.stringify(
    _.assign({},
      !_.isEmpty(priceFlag) && { priceFlag },
      (jurisdiction === 'All' || jurisdiction === 'PM' || jurisdiction === 'PMO') && { projectManager: userAccount, projectStatus: '01' }
    )
  ))
  const searchData = JSON.parse(sessionStorage.getItem('searchData'))
  const [listData, setListData] = useState([])
  const [open, setOpen] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [isDetail, setIsDetail] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [paging, setPaging] = useState(INIT_PAGING)
  const [isAdd] = useFuncCode(['030101'])
  const paramsRef = useRef()
  const { data: projectManagerRes } = useGet(projectManagerUrl)

  const { data: listDatas, loading, doFetch, error } = useGet()

  const [costProjectDetail, setCostProjectDetail] = useState({})

  const [operation, setOperation] = useState(null)

  const { pageSize, total, pageNum, rows: list } = useMemo(() => listDatas || {}, [listDatas]);

  useEffect(() => {
    if (!_.isNil(initId)) { }
  }, [initId])

  useEffect(() => {
    if (_.isNil(projectManagerRes)) return
    let initUrl = `/project/list?${_.isNil(initId) ? 'projectStatus=01' : 'id=' + initId}`
    if (!_.isEmpty(priceFlag)) initUrl += `&priceFlag=${_.join(priceFlag, ',')}`
    if (_.find(projectManagerRes, x => x.userAccount === userAccount) && _.isNil(initId)) {
      initUrl += `&projectManager=${userAccount}`
    }
    if (jurisdiction) {
      const cloneData = _.assign({}, searchData,
        !_.isEmpty(searchData.priceFlag) && { priceFlag: _.join(searchData.priceFlag, ',') }
      )
      doFetch(!_.isEmpty(searchData) ? `/project/list?${decodeURIComponent(strParams(_.assign(cloneData, paging)))}` : initUrl + '&' + strParams(paging))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdiction, projectManagerRes, priceFlag])

  useEffect(() => {
    setListData(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const levelOrder = useCallback((o) => {
    let num = 0
    let levels = null
    recursion(o, 0, false, null)
    function recursion(o, level, judge) {
      _.forEach(o, v => {
        v.level = v.level !== null ? v.level : level
        if ((jurisdiction === 'All' || jurisdiction === 'PM') && v.projectManager === userAccount) {
          v.jurisdiction = false
          levels = v.level
        } else {
          v.jurisdiction = true
          levels = null
        }
        if (_.isNull(levels) && levels < v.level) v.jurisdiction = false
        if (jurisdiction === 'PMO') v.jurisdiction = false
        if (v.level === 0) v.order = ++num
        v.isTrue = v.isTrue === undefined ? judge : v.isTrue
        v.conditions = v.conditions === undefined ? judge : v.conditions
        if (_.get(v, 'childrenProjectList')) {
          return recursion(v.childrenProjectList, level + 1, judge)
        }
      })
    }
    return o
  }, [jurisdiction, userAccount])

  const expandAllChildren = useCallback((childList) => {
    let allChildList = []
    _.forEach(childList, x => {
      const current = _.assign(x, { isTrue: true, conditions: true })
      allChildList.push(current)
      if (_.get(x, 'childrenProjectList')) {
        allChildList = allChildList.concat(expandAllChildren(_.get(x, 'childrenProjectList')))
      }
    })
    return allChildList
  }, [])

  const clickRotate = useCallback((o) => {
    const num = listData.indexOf(o)
    if (!_.get(o, 'conditions') === false && _.get(o, 'childrenProjectList')) {
      recursive(o, false)
      recursives(o, false)
      listData.splice(num + 1, len)
      len = 0 //长度归零
    } else if (_.get(o, 'childrenProjectList')) {
      listData.splice(num + 1, 0, ...expandAllChildren(_.get(o, 'childrenProjectList')))
    }
    o.isTrue = !o.isTrue
    o.conditions = !o.conditions

    setListData(_.clone(listData))
    function recursive(o, level) {
      if (_.get(o, 'childrenProjectList')) {
        if (o.isTrue) len += o.childrenProjectList.length//长度
        _.forEach(o.childrenProjectList, val => {
          val.conditions = level
          return recursive(val, level)
        })
      }
    }
    function recursives(o, level) {
      if (_.get(o, 'childrenProjectList')) {
        _.forEach(o.childrenProjectList, val => {
          val.isTrue = level
          return recursives(val, level)
        })
      }
    }
  }, [expandAllChildren, listData])

  const tree = useCallback((v, o) => {
    const priceFlag = o.priceFlag === 'Y'
    if (o.leafProjectFlag === '1' || o.level === 4) return <span className='leftArrow verticalAlignment' >
      <Icon name="yezijiedian" className='arrow_drop_down' style={{ marginLeft: o.level * 20, color: '#33CC8A' }} />
      <Icon name={priceFlag ? 'hejiaxiangmu' : 'feihejiaxiangmu'} className={cls('projectLabel', { projectLabelY: priceFlag, projectLabelN: !priceFlag, priceColor: priceFlag && !isNil(o.contractNum) })} />
      <Tooltip title={v}><span className='enter-detail-field' onClick={() => setIsDetail(o)}>{v}</span></Tooltip>
    </span>
    if (!o.childrenProjectList) return <span className='verticalAlignment'>
      <Icon name="arrow_drop_down" className='arrow_drop_down' style={{ marginLeft: o.level * 20, visibility: 'hidden' }} />
      <Icon name={priceFlag ? 'hejiaxiangmu' : 'feihejiaxiangmu'} className={cls('projectLabel', { projectLabelY: priceFlag, projectLabelN: !priceFlag, priceColor: priceFlag && !isNil(o.contractNum) })} />
      <Tooltip title={v}><span className='enter-detail-field' onClick={() => setIsDetail(o)}>{v}</span></Tooltip>
    </span>
    return <span className='leftArrow verticalAlignment'>
      <Icon onClick={() => { clickRotate(o) }} name="arrow_drop_down" className='arrow_drop_down'
        style={{ transform: _.get(o, 'conditions') ? 'none' : 'rotate(-90deg)', marginLeft: o.level * 20 }} />
      <Icon name={priceFlag ? 'hejiaxiangmu' : 'feihejiaxiangmu'} className={cls('projectLabel', { projectLabelY: priceFlag, projectLabelN: !priceFlag, priceColor: priceFlag && !isNil(o.contractNum) })} />
      <Tooltip title={v}><span className='enter-detail-field' onClick={() => setIsDetail(o)}>{v}</span></Tooltip>
      {
        (_.toNumber(o.leafFirstProjectFlag) === 1 && _.toNumber(o.level) === 0) &&
        < Icon name="yezijiedian" className='arrow_drop_down' style={{ marginLeft: 8, color: '#33CC8A' }} />
      }
    </span>
  }, [clickRotate])

  const levelData = useMemo(() => levelOrder(listData), [levelOrder, listData])
  const option = useMemo(() => getColumns(tree, jurisdiction, history, setOpen, setCostProjectDetail, setIsShow, setIsDetail, setOperation), [history, jurisdiction, tree])

  const Extra = useCallback(() => {
    const params = paramsRef.current
    if (isAdd) {
      return <>
        <TextIconBtn icon='tianjia' text='建立一级项目' onClick={() => setOperation({ mode: 'add' })} />
        <TextIconBtn icon='daochu' text='导出' onClick={_.debounce(() => API1.downloadGet('/project/export?' + strParams(params)), 300)} />
      </>
    }
    return <TextIconBtn icon='daochu' text='导出' onClick={_.debounce(() => API1.downloadGet('/project/export?' + strParams(params)), 300)} />
  }, [isAdd])

  return (
    <div className="flex-y fill projectManagement-box">
      <Option {...{ initId, paramsRef, searchData, search, jurisdiction, priceFlag, userAccount, paging, setPaging }} />
      <Box
        title='项目管理'
        error={error}
        loading={loading}
        data={listData}
        className='flex-y x-card-singlegrid'
        extra={<Extra />}
      >
        <Table data={levelData} option={option} currentId={currentId} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => { search({ ..._.get(paramsRef, 'current', {}), pageNum, pageSize }); setPaging(x => _.assign({}, x, { pageNum, pageSize })) }} />
      </Box>
      {<Prompt message={location => {
        if (!_.includes(location.pathname.split('/'), 'projectManagement')) {
          sessionStorage.removeItem('searchData')
        }
      }} />}
      <Shuttle open={open} search={() => search(paramsRef.current)} data={{ projectId: _.get(open, 'id'), budgetCost: _.get(open, 'budgetCost'), projectType: _.get(open, 'projectType') }} setOpen={setOpen} />
      {!_.isEmpty(costProjectDetail) && <CostDetails costProjectDetail={costProjectDetail} setCostProjectDetail={setCostProjectDetail} />}
      <InfoDetail open={isDetail} cancel={() => setIsDetail(false)} jurisdiction={jurisdiction} setOperation={setOperation} />
      <InfoForm search={() => search(paramsRef.current)} open={operation} cancel={() => setOperation(null)} />
      <DrawerTemplate open={isShow} setOpen={setIsShow} />
    </div>
  )

  function search(o) {
    setCurrentId(_.get(gd, 'projectManagement', null))
    const searchDatas = _.cloneDeep(o)
    if (searchDatas.priceFlag) searchDatas.priceFlag = _.join(searchDatas.priceFlag, ',')
    sessionStorage.setItem('searchData', JSON.stringify(o))
    const dataConditions = isNil(searchDatas?.priceId) ? _.omit(searchDatas, ['priceId']) : searchDatas
    doFetch(`/project/list?${strParams(dataConditions)}`)
  }
}

function Table(props) {
  const { data, option, currentId } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const onRowClick = useCallback((item) => {
    gd.projectManagement = item?.id
    _.forEach(data, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [data])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return <DataGrid className='project-mgt-card' data={data} onRowClick={onRowClick} option={option} />

}

function Option(props) {
  const { initId, search, searchData, jurisdiction, userAccount, priceFlag, paramsRef, paging, setPaging } = props
  const { data: allData } = useGet(URL)
  // const [classifiedData, statusData, projectManagerData, customerData, managerData] = allData || []
  const [classifiedData, statusData, , customerData,] = allData || []
  const classifiedOpt = useMemo(() => _.map(classifiedData, o => { return { text: o.displayName, value: o.interiorId } }), [classifiedData])
  const customerOpt = useMemo(() => _.map(customerData, o => { return { text: o.tCustname, value: o.tCustid, tag: `${o.tCustname}${o.tCustid}` } }), [customerData])
  const statusOpt = useMemo(() => _.map(statusData, o => { return { text: o.displayName, value: o.interiorId } }), [statusData])
  // const managerOpt = useMemo(() => _.map(removal(managerData), o => { return { text: `${o.userName}`, value: o.userAccount, tag: `${o.userAccount} ${o.userName}` } }), [managerData])
  // const creatorOpt = useMemo(() => _.map(projectManagerData, o => { return { text: `${o.userName}`, value: o.userAccount, tag: `${o.userAccount} ${o.userName}` } }), [projectManagerData])
  const [params, setParams] = useState(!_.isEmpty(searchData) ? searchData : { projectStatus: '01' })
  const [isShow, setIsShow] = useState(_.isNil(initId) ? false : true)
  const [error, setError] = useState()

  useEffect(() => {
    setParams(val => {
      return _.assign(val, paging,
        (jurisdiction === 'All' || jurisdiction === 'PM' || jurisdiction === 'PMO') && { projectManager: userAccount },
        { priceFlag },
        !_.isNil(initId) && { id: initId, projectManager: null, projectStatus: null }
      )
    })
  }, [jurisdiction, userAccount, priceFlag, paging, initId])

  useEffect(() => {
    setParams(val => {
      return _.assign(val, searchData)
    })
  }, [searchData])

  useEffect(() => { paramsRef.current = params }, [params, paramsRef])

  return (
    <div className="project-option">
      <div className='flex'>
        <Form onChange={setParams} value={params}>
          <FormInput label='项目名称' bind='projectName' componentWidth={160} />
          <FormInput search clear label='项目状态' bind='projectStatus' options={statusOpt} component={Select} componentWidth={160} />
          <FormInput search clear label='项目分类' bind='projectType' options={classifiedOpt} component={Select} componentWidth={140} />
          <FormInput search clear label='客户' bind='customer' options={customerOpt} component={Select} componentWidth={140} />
          {/*<FormInput search clear label='项目经理' bind='projectManager' options={managerOpt} component={Select} componentWidth={140} />*/}
          <FormInput search clear label='项目经理' bind='projectManager' component={UserSelect} componentWidth={140} />
        </Form>
        <b onClick={() => setIsShow(!isShow)}>{isShow ? '收起' : '展开'}</b>
        <div style={{ marginTop: 28, marginLeft: 30 }}>
          <Button style={{ marginLeft: 0 }} className='btn' primary disable={_.some(_.values(error), x => x)}
            onClick={() => {
              search(_.assign(params, INIT_PAGING))
              setPaging(INIT_PAGING)
            }}>查询</Button>
          <Button text className='reset-btn btn' onClick={() => {
            sessionStorage.removeItem('searchData')
            setParams(INIT_PARAMS)
            search(_.assign(INIT_PARAMS, INIT_PAGING))
            setPaging(INIT_PAGING)
          }} >重置</Button>
        </div>
      </div>
      <div className={cls('option-hide', isShow && 'option-show')}>
        <Form onChange={setParams} value={params} error={error} onError={setError}>
          <FormInput label='项目ID' bind='id' componentWidth={160} />
          {/*<FormInput search clear label='创建人' bind='createUser' options={creatorOpt} component={Select} componentWidth={160} />*/}
          <FormInput search clear label='创建人' bind='createUser' component={UserSelect} componentWidth={160} />
          <FormInput label='核价单号' bind='priceId' componentWidth={140} />
          <FormInput clear label='核价项目标识' bind='priceFlag' multiple options={priceFlagOpt} component={Select} componentWidth={140} />
        </Form>
      </div>
    </div>
  )
}

function strParams(params) {
  if (!_.isObject(params)) return '';
  const paramsArray = [];
  _.mapKeys(params, (value, key) => {
    !isNil(value) && paramsArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  });
  return paramsArray.join('&')
}
import React, {useCallback, useRef, useState} from 'react';
import _ from "lodash";
import {Input as AntdInput, Tag} from "antd";
import {uniqKeyFor} from "../../../../../project_share/utils/utils";
import {PlusOutlined} from "@ant-design/icons";
import {isNil} from "../../../../appraise/components/method";
import './EditableEmailList.scss'

function EditableEmailList(props) {
    const {editFormData, setEditFormData, isDetail, bind=''} = props
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [editInputValue, setEditInputValue] = useState('')
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const editInputRef = useRef()
    const newInputRef = useRef()

    const onTagClose = useCallback((index, bind)=>{
        setEditFormData( oldObj => {
            const oldValue = _.get(oldObj,bind)
            const newValue = _.filter(oldValue, (o,i) => i!== index)
            let cloneObj = {...oldObj}
            cloneObj[bind] = newValue
            return cloneObj
        })
    },[setEditFormData])

    const onEditTagInputConfirm = useCallback((bind)=>{
        setEditFormData( oldObj => {
            const oldValue = _.get(oldObj,bind)
            const newValue = _.map(oldValue, (item, index) => {
                if(editInputIndex === index){
                    return editInputValue
                }else{
                    return item
                }
            })
            let cloneObj = {...oldObj}
            cloneObj[bind] = newValue
            setEditInputIndex(-1)
            setEditInputValue('')
            return cloneObj
        })
    },[editInputIndex,editInputValue,setEditFormData])

    const onAddTagInputConfirm = useCallback((bind)=>{
        if(!isNil(inputValue)){
            setEditFormData( oldObj => {
                const oldValue = _.get(oldObj,bind)
                let cloneObj = {...oldObj}
                cloneObj[bind] = _.isEmpty(oldValue) ? [inputValue] : _.concat(oldValue, [inputValue])
                return cloneObj
            })
        }
        setInputVisible(false)
        setInputValue('')
    },[inputValue,setEditFormData])

    return <div className="editable-email-list">
        {
            _.map(_.get(editFormData,bind), (item, index) => {
                if(editInputIndex === index){
                    return (
                        <AntdInput
                            ref={editInputRef}
                            key={'main-email-'+bind}
                            size="small"
                            className="tag-input"
                            value={editInputValue}
                            onChange={e => setEditInputValue(e.target.value)}
                            onBlur={()=>onEditTagInputConfirm(bind)}
                            onPressEnter={()=>onEditTagInputConfirm(bind)}
                        />
                    )
                }else{
                    return (<Tag color="#f0f0f0" closable={!isDetail} onClose={()=>onTagClose(index,bind)} key={uniqKeyFor()}>
                                    <span
                                        onDoubleClick={e => {
                                            //     this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                                            //         this.editInput.focus();
                                            //     });
                                            setEditInputIndex(index)
                                            setEditInputValue(item)
                                            setTimeout(()=>{
                                                if(!_.isNil(editInputRef.current)){
                                                    editInputRef.current.focus()
                                                }
                                            },0)
                                            e.preventDefault()
                                        }}
                                    >
                                        {item}
                                    </span>
                    </Tag>)
                }
            })
        }
        {
            inputVisible &&
            <AntdInput
                ref={newInputRef}
                key={'main-email-new'}
                size="small"
                className="tag-input"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onBlur={()=>onAddTagInputConfirm(bind)}
                onPressEnter={()=>onAddTagInputConfirm(bind)}
            />
        }
        {
            !isDetail && !inputVisible &&
            <Tag className="tag-plus" color="#f0f0f0" onClick={()=>{
                setInputVisible(true)
                setTimeout(()=>{
                    if(!_.isNil(newInputRef.current)){
                        newInputRef.current.focus()
                    }
                },0)
            }}>
                <PlusOutlined /> 添加新邮箱
            </Tag>
        }
    </div>
}

export default EditableEmailList;
import React, { useMemo, useState } from 'react';
import _ from "lodash";
import { DataGrid, Pagination } from "rootnet-ui";
import { Box } from "../../common/commonComponent";
import ViewExtraTextIcon from "../../common/view/ViewExtraTextIcon";
import usePost from "rootnet-biz/es/hooks/usePost";
import CustomizeOptions from "../../common/customizeOptions/CustomizeOptions";
import useGetViewConfig from "../../common/view/hooks/useGetViewConfig";
import findConvert from "../../common/view/findConvert";
import useRefreshList from "../../common/view/hooks/useRefreshList";
import convertTableAlign from "../../common/view/convertTableAlign";
import useGetDateOptions from "../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../common/view/hooks/useGetTreeList";

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions } = props

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
      }
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function PreCheckReport() {
  const [params, setParams] = useState(getInitParams())
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig('1805', setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { pageSize, total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList, TreeSwitch } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns })

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TreeSwitch />
      <ViewExtraTextIcon {...{ params, setParams, getList, getFieldList, allColumns, funcCode, currentViewId, setCurrentViewId, refreshList }} />
    </div>
  }, [params, getList, getFieldList, allColumns, funcCode, currentViewId, setCurrentViewId, refreshList])

  return (
    <div className={' fill flex-y'}>
      <CustomizeOptions search={setParams} allOptions={optionsConfig} baseOptions={[]} {...{ getInitParams, funcCode }} />
      <Box title={'预开工核对报告'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
      </Box>
    </div>
  )
}

import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './ConferenceRecordsTodoList.scss'
import {
    DatePicker as AntdDatePicker,
    Form as AntdForm,
    Input,
    Popconfirm,
    Select as AntdSelect,
    Table,
    Tooltip
} from "antd";
import Icon from "../../../../components/Icon";
import convertOptions from "../../../common/ConvertOptions";
import _ from "lodash";
import {sortableContainer, sortableElement} from "react-sortable-hoc";
import {TodoListInConferenceRecordsEditContext} from "../../../common/Context";
import moment from "moment";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import useGet from "rootnet-biz/es/hooks/useGet";
import {isNil} from 'rootnet-core/format'
import TodoListDetailContent from "../../../todolist/components/todoListDetailDialog/todoListDetailContent";
import {Dialog} from 'rootnet-ui'
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";

function getColumns(props){
    const {allUserRes, priorityOptions, delTodoItem, editable, deletable, showDetail, setShowId, showStatusCol, statusOptions} = props
    const cols1 = [
        {
            title: <span className={'header-required'} style={{marginLeft: 2}}>主题</span>,
            dataIndex: 'subject',
            key: 'subject',
            width: 150,
            fixed: 'left',
            editable: editable,
            render: (value, obj) => {
                if(isNil(value)){
                    return <div style={{color: 'red', opacity: 0.5}}>必填</div>
                }
                return <Tooltip placement="topLeft" title={value}>
                    {
                        showDetail ? <span className={'title-text'} onClick={()=>setShowId(obj.id)}>{value}</span> :
                            <span style={{marginLeft: editable?0:12}}>{value}</span>
                    }
                </Tooltip>
            }
        },
        {
            title: <span className={'header-required'} style={{marginLeft: 2}}>负责人</span>,
            dataIndex: 'principal',
            key: 'principal',
            width: 100,
            editable: editable,
            render: value => {
                if(isNil(value)){
                    return <div style={{color: 'red', opacity: 0.5, marginLeft: editable?0:12}}>必填</div>
                }
                return <div style={{marginLeft: editable?0:12}}>
                    {convertOptions(value, allUserRes, 'userName', 'userAccount')}
                </div>
            }
        },
    ]
    const cols2 = [
        {
            title: <span className={'header-required'} style={{marginLeft: 2}}>时间要求</span>,
            dataIndex: 'requireDate',
            key: 'requireDate',
            width: 120,
            editable: editable,
            render: value => {
                if(isNil(value)){
                    return <div style={{color: 'red', opacity: 0.5}}>必填</div>
                }
                return <div style={{marginLeft: editable?0:12}}>
                    {value}
                </div>
            }
        },
        {
            title: <span style={{marginLeft: 12}}>参与人</span>,
            dataIndex: 'otherPerson',
            key: 'otherPerson',
            width: 110,
            editable: editable,
            ellipsis: {
                showTitle: false,
            },
            render: v => {
                const showValue = _.join(_.map(v, x => convertOptions(x, allUserRes, 'userName','userAccount')),', ')
                return <Tooltip placement="topLeft" title={showValue}>
                    <div style={{marginLeft: editable?0:12}}>
                        {showValue}
                    </div>
                </Tooltip>
            },
        },
        {
            title: <span className={'header-required'} style={{marginLeft: 2}}>优先级</span>,
            dataIndex: 'priority',
            key: 'priority',
            width: 70,
            editable: editable,
            render: (value) => <div style={{marginLeft: editable?0:12}}>
                {convertOptions(value, priorityOptions, 'label')}
            </div>
        },
        {
            title: <span style={{marginLeft: 12}}>审核人</span>,
            dataIndex: 'auditPerson',
            key: 'auditPerson',
            width: 90,
            editable: editable,
            ellipsis: {
                showTitle: false,
            },  
            render: value => {
                if(_.isNil(value)) return
                return <div style={{marginLeft: editable?0:12}}>
                    {convertOptions(value, allUserRes, 'userName', 'userAccount')}
                </div>
            }
        },
        {
            title: <span style={{marginLeft: 12}}>提出人</span>,
            dataIndex: 'sponsor',
            key: 'sponsor',
            width: 90,
            editable: editable,
            ellipsis: {
                showTitle: false,
            },
            render: value => {
                if(_.isNil(value)) return
                return <div style={{marginLeft: editable?0:12}}>
                    {convertOptions(value, allUserRes, 'userName', 'userAccount')}
                </div>
            }
        },
        {
            title: <span style={{marginLeft: 12}}>详细描述</span>,
            dataIndex: 'note',
            key: 'note',
            width: 200,
            editable: editable,
            // ellipsis: {
            //     showTitle: false,
            // },
            render: value => <Tooltip placement="topLeft" title={value}>
                <div style={{marginLeft: editable?0:12, whiteSpace: 'pre-wrap'}}>{value}</div>
            </Tooltip>
        },
    ]

    return _.concat(
        deletable?[
        {
            title: '',
            width: 40,
            align: 'center',
            fixed: 'left',
            render: (value, obj) => {
                return <Popconfirm
                    title="确认删除？"
                    onConfirm={()=>delTodoItem(obj.id)}
                    okText="删除"
                    cancelText="取消"
                    overlayInnerStyle={{padding: 8, width: 200}}
                >
                    <Icon className={'todo-del-icon'} name='shanchu' style={{fontSize: 16}}/>
                </Popconfirm>
            }
        }
    ]:[],
        cols1,
        showStatusCol? [
            {
                title: <span className={'header-required'} style={{marginLeft: 2}}>状态</span>,
                dataIndex: 'factorId',
                key: 'factorId',
                width: 100,
                render: value => {
                    const backgroundColor = _.get(_.find(statusOptions, o => o.value === value), 'color')
                    return <span style={{ backgroundColor, color: 'white', padding: '0px 8px', borderRadius: 4, width: 64 }}>
                        {
                            convertOptions(value, statusOptions)
                        }
                    </span>
                }
            }
        ]:[],
        cols2
        )
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=workflowTodoStatus',
]

const optionsUrls = [
    '/common/globalconst?globalConst=PRIORITYLIST',
]

function ConferenceRecordsTodoList(props) {
    const {todoList, setTodoList, allUserRes, editable, updateEditRow, deletable, showDetail = false, refreshList, showStatusCol = false} = props
    const { data: allOptionsRes } = useGet(optionsUrls)
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [showId, setShowId] = useState()
    const [todoListId, setTodoListId] = useState()
    const [form] = AntdForm.useForm()

    const [statusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [priorityOptions] = useMemo(()=>{
        if(_.isEmpty(allOptionsRes)) return []
        const [d1] = allOptionsRes
        const priorityOptions = _.map(d1, x => ({label: x.displayName, text: x.displayName, value: x.interiorId}))
        return [priorityOptions]
    },[allOptionsRes])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = todoList.findIndex(x => x.id === restProps['data-row-key']);
        return <AntdForm form={form} component={false}>
            <TodoListInConferenceRecordsEditContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </TodoListInConferenceRecordsEditContext.Provider>
        </AntdForm>
    },[todoList, form])

    const handleSave = useCallback((row, bind)=>{
        if(updateEditRow){
            updateEditRow(row)
        }
        const index = _.findIndex(todoList, x => x.id === row.id)
        setTodoList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return _.sortBy(newList, 'serial')
        })
    },[todoList, setTodoList, updateEditRow])

    const delTodoItem = useCallback((delId)=>{
        setTodoList(oldList => {
            return _.filter(oldList, x => x.id !== delId)
        })
    },[setTodoList])

    const columns = useMemo(()=>{
        return _.map(getColumns({allUserRes, priorityOptions, delTodoItem, editable, deletable, showDetail, setShowId, showStatusCol, statusOptions}),col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                    showList: todoList,
                    priorityOptions,
                })
            }
        })
    },[allUserRes, handleSave, todoList, priorityOptions, delTodoItem, editable, deletable, showDetail, showStatusCol, statusOptions])

    const currentIndex = useMemo(() => {
        return _.findIndex(todoList, x => _.get(x, 'id') === showId)
    }, [todoList, showId])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setShowId(switchDirection === 'next' ? todoList[currentIndex + 1].id : todoList[currentIndex - 1].id)
    }, [todoList, currentIndex])

    return <div className={'conference-records-todo-list'}>
        <Table
            size={'small'}
            rowClassName={'editable-row'}
            columns={columns}
            pagination={false}
            dataSource={todoList}
            rowKey="id"
            sticky={true}
            scroll={{x:700}}
            components={{
                body:{
                    wrapper: DraggableContainer,
                    row: EditableRow,
                    cell: EditableCell
                }
            }}
        />
        {
            showId &&
            <Dialog className={'todo-detail-dialog'} headerVisible={false} footerVisible={false}>
                <TodoListDetailContent refreshViewList={refreshList} currentInfo={{mode:'detail',id: showId}} close={()=>{setShowId(null);setTodoListId(null)}} id={todoListId} setId={setTodoListId}
                                       switchCurrentItem={switchCurrentItem} showChildList={todoList} currentIndex={currentIndex}/>
            </Dialog>
        }
    </div>

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            {...props}
        />
    }
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, showList,priorityOptions, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(TodoListInConferenceRecordsEditContext || {})

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        setEditing(x => !x);
        let value
        if(dataIndex === 'requireDate'){
            value = _.isNil(record[dataIndex]) ? null :moment(record[dataIndex], 'YYYY-MM-DD')
        }else{
            value = record[dataIndex]
        }
        form.setFieldsValue({ [dataIndex]:  value });
    },[dataIndex, record, form])

    const save = () => {
        try {
            form.validateFields().then(changeObj => {
                const key = _.head(_.keys(changeObj))
                if(key === 'requireDate'){
                    changeObj[key] = _.isNil(changeObj[key]) ? null :moment(changeObj[key]).format('YYYY-MM-DD')
                }
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    handleSave({ ...record, ...changeObj }, key);
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        }catch (errInfo){
            console.error(errInfo);
        }
    }

    let childNode = children;

    if (editable){
        if(editing){
            let editNode
            if(_.includes(['principal', 'sponsor', 'auditPerson'], dataIndex)){
                editNode = (<UserSelect ref={inputRef} onChange={save} defaultOpen={true} onBlur={()=>setEditing(false)} />)
            }else if(dataIndex === 'otherPerson'){
                editNode = (<UserSelect ref={inputRef} multiple onChange={save} defaultOpen={true} onBlur={()=>setEditing(false)} />)
            }else if(dataIndex === 'priority'){
                editNode = (<AntdSelect ref={inputRef} options={priorityOptions} onSelect={save} allowClear={false} defaultOpen onBlur={()=>setEditing(false)}/>)
            }else if(dataIndex === 'requireDate'){
                editNode = (<AntdDatePicker ref={inputRef} onChange={save} allowClear={false} open={true} onBlur={()=>setEditing(false)} autoFocus />)
            }else if(dataIndex === 'note'){
                editNode = (<Input.TextArea ref={inputRef} onBlur={save} />)
            }else{
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }

            childNode = (
                <AntdForm.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </AntdForm.Item>
            )
        }else{
            childNode =(
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }

    return <td {...restProps}>{childNode}</td>;
}

export default ConferenceRecordsTodoList;
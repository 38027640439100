import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './EmailReminder.scss'
import {useGet} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import Icon from "../../../../../components/Icon";
import {DataGrid} from 'rootnet-ui'
import DelMessage from "../../../../../components/DelMessage";
import {TextIconBtn} from "../../../../common/TextIconBtn";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import convertOptions from "../../../../common/ConvertOptions";
import EmailReminderDetail from "./emailReminderDetail/EmailReminderDetail";
import {API1} from "../../../../../base/task";
import {strParams} from "../../../../../utils/publicFun";
import {Box} from "../../../../common/commonComponent";

function getColumns(props){
    const {setCurrentInfo, setDelId, standbyOptions} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40, stretch: false },
        { header: '主/备', bind: 'flag', width: 60, tooltip: true, convert: v => convertOptions(v, standbyOptions)},
        { header: '联系人', bind: 'recipient', width: 100, tooltip: true},
        { header: '邮件地址', bind: 'mail', width: 300, tooltip: true,
            convert: (v, o) => <div className={'enter-detail-field-wrap flex center-y'}>
                <div className="enter-detail-field" onClick={()=>setCurrentInfo({
                    id: o.id,
                    mode: 'edit'
                })}>{v}</div>
                <Icon name={'shanchu'} className="del-icon" onClick={()=>setDelId(o.id)}/>
            </div>},
        { header: '座机号', bind: 'telephone', width: 100, tooltip: true},
        { header: '手机号', bind: 'mobile', width: 100, tooltip: true},
        { header: '创建人', bind: 'createUser', width: 150, tooltip: true},
        { header: '创建时间', bind: 'createTime', width: 150, tooltip: true},
    ]
}

function getOptions(cols){
    return {
        nilText: '-',
        emptyText: '-',
        fixedLeft: 1,
        resizable: true,
        columns: cols,
        virtualized: true,
        autoFill: true,
    }
}

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=LinkerReserve',
]

function EmailReminder(props) {
    const {id} = props
    const {data: list, doFetch: getList} = useGet()
    const [currentInfo, setCurrentInfo] = useState()
    const [delId, setDelId] = useState()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [standbyOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const refreshList = useCallback(()=>{
        if(_.isNil(id)) return
        getList('/bug/project/customerAlertmail?id='+id)
    },[getList,id])

    useEffect(()=>{
        refreshList()
    },[refreshList])

    const option = useMemo(()=>{
        return getOptions(getColumns({setCurrentInfo, setDelId,standbyOptions}))
    },[standbyOptions])

    const extra = useMemo(()=>{
        return <div className={'handle-right-wrap flex'}>
            <TextIconBtn icon={'tianjia'} text='添加' className={'handle-btn'} onClick={()=>setCurrentInfo({mode: 'add'})}/>
            <TextIconBtn icon={'xiazai2'} text='导出' className={'handle-btn'} onClick={_.debounce(() => API1.downloadGet('/bug/project/export?id='+id), 1000)}/>
        </div>
    },[id])

    return <div className={'email-reminder flex-y'}>
        <Box title={'提醒邮箱地址'} className='flex-y x-card-singlegrid' data={list} extra={extra}>
            <DataGrid option={option} data={list}/>
        </Box>
        {
            !_.isNil(currentInfo) &&
            <EmailReminderDetail close={()=>setCurrentInfo(null)} customerOnlyId={id} {...{currentInfo, refreshList, standbyOptions}}/>
        }
        {
            !_.isNil(delId) &&
            <DelMessage close={()=>setDelId(null)} refresh={refreshList} method={'get'} url={'/bug/project/deleteMail?'+strParams({id:delId,customerOnlyId:id})}>
                确定删除？
            </DelMessage>
        }
    </div>
}

export default EmailReminder;
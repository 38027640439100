import React, {useState} from 'react';
import './VersionDashboard.scss'
import Icon from "../../../../../project_share/components/Icon";
import clsx from "clsx";
import VersionPlanDashboard from "./versionPlanDashboard/VersionPlanDashboard";

function VersionDashboard(props) {
    const {currentId} = props
    const [isFold, setIsFold] = useState(true)
    const [activeTab, setActiveTab] = useState('versionPlan')

    return <div className={'version-dashboard-wrap flex'}>
        <div className="version-dashboard-list flex" style={{width: isFold ? 40 : 140}}>
            <div className={clsx('version-dashboard-list-item flex center-y',{selected: activeTab === 'versionPlan'})}
                 style={{display: isFold ? 'none' :'flex'}} onClick={()=>setActiveTab('setActiveTab')}>
                <div className={'version-dashboard-list-item-text flex center-y'}>
                    版本计划看板
                </div>
            </div>
            <div className="fold-bar flex center" onClick={()=>setIsFold(x => !x)} style={{width: isFold ? 38 : 22}}>
                <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{transform: isFold ?  'none' : 'rotate(180deg)'}}/>
            </div>
        </div>
        <div className="version-dashboard-content">
            <VersionPlanDashboard {...{currentId}}/>
        </div>
    </div>
}

export default VersionDashboard;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormInput, Form, Display, TextArea, Select } from 'rootnet-edit';
import _ from 'lodash'
import moment from 'moment';
import { Button, Popover, Progress, Tooltip as AntdTooltip } from 'antd'
import { Messager, Tooltip } from 'rootnet-ui'
import { copyText } from 'rootnet-core/clipboard';
import { useGet, usePost } from "rootnet-biz/es/hooks";
import getFormConfig from "./getFormConfig";
import { toDate } from "rootnet-core/dateFormat";
import DiffReasonDialog from "./DiffReasonDialog";
import ImpAuthorizeDialog from "../../../../authority/controls/Dialog";
import gd from "../../../../../base/global";
import { Icon } from '../../../../../components';
import ChangeRecord from '../../../../common/ChangeRecord';
import UserSelect from '../../../../common/personSelectPop/UserSelect';
import convertOptions from '../../../../common/ConvertOptions';
import './ImpProjectInfo.scss'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions';
import { useRolePermissions } from '../../../../common/commonMethod';
import { isNil } from '../../../../appraise/components/method';

const HFormInput = props => <FormInput labelWidth={125} componentWidth={180} horizontal {...props} />

const ASSOCIATED_VERSION_URL = '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version'

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=delayproblemCategory',  //延误问题类别
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=ImplementProject',  // 项目管理主体
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=ISVALID', //是否参与运营统计
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=updateFrequency' // 进展更新频率
]

function getNameImgColor(sex) {
    if (_.isEmpty(sex)) return '#D3D3D3'
    return sex === '女' ? '#F696D5' : '#4BB8F3'
}

function ImpProjectInfo(props) {
    const { currentId, convertCollection, refreshList, isPrincipal, setIsPrincipal, updateDynamic, projectProgress, setProjectName, count, projectName, setProjectId } = props
    const { data: allUserRes } = useGet('/common/userinfo')
    const [formInfo, setFormInfo] = useState()
    const [editFormInfo, setEditFormInfo] = useState()
    const { doFetch: getDetail } = useGet()
    const { doFetch: update } = usePost()
    const [mode, setMode] = useState('detail')
    const [diffKeyList, setDiffKeyList] = useState([])
    const [showDiffDialog, setShowDiffDialog] = useState(false)
    const [showDialog, setShowDialog] = useState()
    const [formError, setFormError] = useState()
    const { doFetch: getTCustIdOptions } = useGet()
    const [tCustIdOptions, setTCustIdOptions] = useState([])
    const [showCopyPop, setShowCopyPop] = useState(false)
    const [expand, setExpand] = useState()
    const { data: associatedVersionRes } = useGet(ASSOCIATED_VERSION_URL)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [isQA] = useRolePermissions(['QA'])

    const isDetail = useMemo(() => mode === 'detail', [mode])
    const isEdit = useMemo(() => mode === 'edit', [mode])
    const isEditQA = useMemo(() => mode === 'editQA', [mode])

    const isNotYet = useMemo(() => { //是否是未进行状态
        return _.get(editFormInfo, 'serviceStatus') === '10'
    }, [editFormInfo])

    const acceptanceDateRequired = useMemo(() => {
        const projectStatus = _.get(editFormInfo, 'projectStatus')
        return projectStatus === '03'
    }, [editFormInfo])

    const associatedVersionOpt = useMemo(() => {
        if (_.isEmpty(associatedVersionRes)) return []
        return convertGlobalConstOptions(associatedVersionRes)
    }, [associatedVersionRes])

    const [delayproblemCategoryOpt, projectSubjectOpt, operationStatisticsOpt, updateFrequencyOpt] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const formConfig = useMemo(() => {
        return getFormConfig({ allUserRes, convertCollection, acceptanceDateRequired, tCustIdOptions, associatedVersionOpt, delayproblemCategoryOpt, projectSubjectOpt, editFormInfo, operationStatisticsOpt, updateFrequencyOpt, isEditQA, isNotYet })
    }, [allUserRes, convertCollection, acceptanceDateRequired, tCustIdOptions, associatedVersionOpt, delayproblemCategoryOpt, projectSubjectOpt, editFormInfo, operationStatisticsOpt, updateFrequencyOpt, isEditQA, isNotYet])

    useEffect(() => {
        if (_.isEmpty(formConfig)) return
        setExpand(_.assign(..._.map(formConfig, o => ({ [o.bind]: true }))))
    }, [formConfig])

    const refresh = useCallback(() => {
        getDetail('/implement/project/info?id=' + currentId).then(info => {
            setProjectName(_.get(info, 'projectName'))
            setProjectId(_.get(info, 'projectId'))
            handleInfo(info)
            setFormInfo(info)
            setEditFormInfo(info)
        })
    }, [currentId, getDetail, setProjectId, setProjectName])

    useEffect(() => {
        setFormInfo(info => {
            if (_.isNil(info)) return info
            const handleProjectProgress = projectProgress ? projectProgress : _.get(info, 'projectProgress')
            info['projectProgress'] = handleProjectProgress
            if (_.isNil(handleProjectProgress) || _.toNumber(handleProjectProgress) === 0) {
                info['improvePercent'] = null
            } else {
                info['improvePercent'] = (_.toNumber(handleProjectProgress)) - (_.toNumber(_.get(info, 'timeUsed')))
            }
            return info
        })
        setEditFormInfo(info => {
            if (_.isNil(info)) return info
            const handleProjectProgress = projectProgress ? projectProgress : _.get(info, 'projectProgress')
            info['projectProgress'] = handleProjectProgress
            if (_.isNil(handleProjectProgress) || _.toNumber(handleProjectProgress) === 0) {
                info['improvePercent'] = null
            } else {
                info['improvePercent'] = (_.toNumber(handleProjectProgress)) - (_.toNumber(_.get(info, 'timeUsed')))
            }
            return info
        })
    }, [projectProgress])

    useEffect(() => {
        refresh()
    }, [refresh])

    const tCustId = useMemo(() => {
        return _.get(editFormInfo, 'tcustId')
    }, [editFormInfo])

    useEffect(() => {
        if (_.isNil(tCustId)) return
        getTCustIdOptions('/UserSetting/getUniversalInterfaces?code=projectId&codeName=projectName&tableName=bug_project&filter=t_custId&filterParams=' + tCustId).then(res => {
            setTCustIdOptions(_.map(res, x => ({ value: x.interiorId, text: x.displayName })))
        })
    }, [getTCustIdOptions, tCustId])

    const requiredKeyList = useMemo(() => {
        const formList = _.concat(..._.map(formConfig, o => o.children))
        return _.map(_.filter(formList, x => _.get(x, 'required')), 'bind')
    }, [formConfig])

    const saveBtnDisabled = useMemo(() => {
        return _.some(requiredKeyList, key => {
            const value = _.get(editFormInfo, key)
            return _.isNil(value) || value === ''
        })
    }, [requiredKeyList, editFormInfo])

    const submit = useCallback(({ editReason, diffKeyList }) => {
        const postDiff = _.pick(editFormInfo, diffKeyList)
        const postParams = {
            ...postDiff,
            id: _.get(editFormInfo, 'id'),
        }
        if (!!editReason) {
            postParams['editReason'] = editReason
        }
        _.forEach(postParams, (value, key) => {
            if (_.isNil(value)) {
                postParams[key] = ''
            }
        })
        if (postParams['pauseTime'] === '') {
            postParams['pauseTime'] = 0
        }
        update('/implement/project/update', postParams).then(() => {
            Messager.show('修改成功', { icon: 'success' })
            setMode('detail')
            setShowDiffDialog(false)
            refresh()
            refreshList()
            updateDynamic()
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [update, editFormInfo, refresh, refreshList, updateDynamic])

    const save = useCallback(() => {
        // 未来投入显示值是否与计算值相等 或 等于初始值
        const initResidualCost = _.toNumber(_.get(formInfo, 'budgetCost')) - _.toNumber(_.get(formInfo, 'usedCost'))
        const sameResidualCost = initResidualCost === _.toNumber(_.get(editFormInfo, 'residualCost')) || _.toNumber(_.get(editFormInfo, 'residualCost')) === _.get(formInfo, 'residualCost')

        const originalDiff = objDiff(formInfo, editFormInfo)
        const diff = _.omit(originalDiff, ['planDay', 'timeUsed', 'improvePercent', 'residualCost', 'totalCost', 'savingRatio'])
        const diffKeyList = _.concat(_.keys(diff), sameResidualCost ? [] : ['residualCost'])

        const needReasonList = ['principal', 'projectStatus', 'planBeginDate', 'planEndDate']

        const intersectionList = _.intersection(needReasonList, diffKeyList)
        if (_.isEmpty(diffKeyList)) {
            setMode('detail')
            return Messager.show('无变更')
        }
        if (_.isEmpty(intersectionList)) {
            submit({ diffKeyList })
        } else {
            setDiffKeyList(diffKeyList)
            setShowDiffDialog(true)
        }
    }, [editFormInfo, formInfo, submit])

    const changeForm = useCallback((value, bind) => {
        setEditFormInfo(oldObj => {
            const newObj = { ...oldObj }
            newObj[bind] = value
            handleInfo(newObj, projectProgress)
            if (_.get(newObj, 'planBeginDate') && _.get(newObj, 'planEndDate')) {
                if (_.includes(['planBeginDate', 'planEndDate'], bind)) {
                    if (_.get(newObj, 'planBeginDate') > _.get(newObj, 'planEndDate')) {
                        if (bind === 'planBeginDate') {
                            Messager.show('计划开始时间不能晚于计划结束时间')
                        } else {
                            Messager.show('计划结束时间不能早于计划开始时间')
                        }
                        return oldObj
                    }
                }
            }
            if (_.get(newObj, 'beginDate') && _.get(newObj, 'closeTime')) {
                if (_.includes(['beginDate', 'closeTime'], bind)) {
                    if (_.get(newObj, 'beginDate') > _.get(newObj, 'closeTime')) {
                        if (bind === 'beginDate') {
                            Messager.show('实际开始时间不能晚于实际结束时间')
                        } else {
                            Messager.show('实际结束时间不能早于实际开始时间')
                        }
                        return oldObj
                    }
                }
            }
            if (bind === 'totalContractAmount' || bind === 'deliveredAmount') {
                const totalAmount = newObj['totalContractAmount']
                const amountPaid = newObj['deliveredAmount']
                // if (!_.isNil(totalAmount) && _.isNil(amountPaid)) return totalAmount
                const undeliveredAmount = _.subtract(_.isNil(totalAmount) ? 0 : totalAmount, _.isNil(amountPaid) ? 0 : amountPaid)
                newObj['undeliveredAmount'] = _.isNaN(undeliveredAmount) ? 0 : undeliveredAmount
            }
            return newObj
        })
    }, [projectProgress])

    const copy = useCallback((value) => {
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', { icon: 'success' });
    }, [])

    const CopyArea = useCallback(() => {
        return <div className={'flex imp-project-title-copy-popover center-y'}>
            <Button onClick={() => copy(_.get(editFormInfo, 'projectName'))} normal>复制项目名称</Button>
            <Button onClick={() => copy(_.get(editFormInfo, 'id'))} normal>复制ID</Button>
        </div>
    }, [copy, editFormInfo])

    useEffect(() => {
        const userPositionList = JSON.parse(localStorage.getItem('userPositionList'))
        const isEM = _.includes(userPositionList, 'Imp_EM')
        setIsPrincipal(isEM || _.get(gd, '_user.operator_id') === _.get(formInfo, 'principal'))
    }, [setIsPrincipal, formInfo])

    useEffect(() => {
        const acceptanceDate = _.get(editFormInfo, 'acceptanceDate')
        if (acceptanceDateRequired && _.isNil(acceptanceDate)) {
            setFormError(x => _.assign({}, x, { acceptanceDate: '必填项！' }))
        } else {
            setFormError(x => _.assign({}, x, { acceptanceDate: null }))
        }
    }, [editFormInfo, acceptanceDateRequired])

    useEffect(() => {
        setFormError(x => _.assign({}, x, {
            closeTime: (isEditQA || isNotYet) ? null : _.isNil(_.get(editFormInfo, 'closeTime')) ? '必填项！' : null,
            approvalDate: (isEditQA || isNotYet) ? null : _.isNil(_.get(editFormInfo, 'approvalDate')) ? '必填项！' : null,
        }))
    }, [isEditQA, isNotYet, editFormInfo])

    const projectPercent = useMemo(() => {
        let num = 0
        if (_.isNil(editFormInfo?.projectProgress)) num = 0
        else num = _.ceil(_.toNumber(editFormInfo?.projectProgress) * 100, 2)
        return num
    }, [editFormInfo])

    const userInfo = useMemo(() => {
        if (_.isEmpty(allUserRes)) return {}
        return _.find(allUserRes, o => o.userAccount === editFormInfo?.principal)
    }, [editFormInfo, allUserRes])

    return <div className={'imp-project-info-wrap'}>
        <div className="imp-project-handle-header flex center-y">
            <div className='handle-title'>
                {
                    (isDetail || isEditQA) &&
                    <div className="imp-title-wrap flex center-y">
                        <div className="imp-title">
                            <Tooltip title={_.get(editFormInfo, 'projectName')}>
                                {_.get(editFormInfo, 'projectName')}
                            </Tooltip>
                        </div>
                        <Popover content={<CopyArea />} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                            <Icon name={'fuzhi'} className={'copy-icon'} />
                        </Popover>
                    </div>
                }
                {
                    isEdit && <FormInput labelWidth={100} horizontal bind={'projectName'} label={'项目名称'} required value={_.get(editFormInfo, 'projectName')}
                        onChange={v => setEditFormInfo(x => ({ ...x, projectName: v }))} maxLength={250} />
                }
            </div>
            <div className="handle-group flex">
                {
                    mode === 'detail' && isPrincipal &&
                    <Button onClick={() => setShowDialog('authorize')}>工时授权</Button>
                }
                {mode === 'detail' && (isPrincipal || isQA) &&
                    <Button onClick={() => {
                        if (isQA) setMode('editQA')
                        else setMode('edit')
                    }}>编辑项目信息</Button>}
                {(isEdit || isEditQA) && <Button onClick={() => {
                    setMode('detail')
                    setEditFormInfo(formInfo)
                }}>退出编辑</Button>}
                {(isEdit || isEditQA) && <Button onClick={save} type={'primary'} disabled={isEditQA ? false : saveBtnDisabled}>保存信息</Button>}
            </div>
        </div>
        <div className={`imp-project-info ${mode === 'detail' ? 'detail-mode' : ''}`}>
            <div className='left-project-info'>
                <Form value={editFormInfo} onChange={(obj, bind) => changeForm(obj[bind], bind)} error={formError} onError={setFormError}>
                    {
                        _.map(formConfig, config => {
                            return <div className={`area-wrap area-wrap-${config.bind}`} key={config.bind}>
                                <div className="area-header flex center-y">
                                    <Icon
                                        name="arrow_drop_down"
                                        className='fold-icon'
                                        style={{ transform: _.get(expand, `${[config.bind]}`, true) ? 'none' : 'rotate(-90deg)' }}
                                        onClick={() => setExpand(x => _.assign({}, x, { [config.bind]: !x[config.bind] }))}
                                    />
                                    {config.name}
                                </div>
                                <div className="area-content-wrap" style={{ height: _.get(expand, `${[config.bind]}`, true) ? '' : 0 }}>
                                    {
                                        _.map(config.children, item => {
                                            if (_.includes(['planEndDate', 'approvalDate', 'onlineDate', 'acceptanceDate', 'closeTime'], item.bind)) {
                                                const component = () => {
                                                    if (isDetail) return Display
                                                    if (isEditQA || isNotYet) return _.get(item, 'component')
                                                    if (isNil(_.get(formInfo, item.bind))) return _.get(item, 'component')
                                                    return Display
                                                }
                                                return <HFormInput
                                                    {...item}
                                                    key={item.bind}
                                                    component={component()}
                                                    // component={isEditQA ? _.get(item, 'component') : ((isNil(_.get(formInfo, item.bind))) && isEdit) ? _.get(item, 'component') : Display}
                                                    required={(isEditQA || (isNotYet && _.includes(['closeTime', 'approvalDate'], item.bind))) ? false : _.get(item, 'required', false)}
                                                />
                                            }
                                            if (_.includes(['archiveDirectory'], item.bind)) {
                                                return <div className='flex flex-y' key={item.bind} >
                                                    <HFormInput {...item} required={isEditQA ? false : _.get(item, 'required', false)} component={(isDetail || isEditQA) ? Display : _.get(item, 'component')} />
                                                </div>
                                            }
                                            if (_.includes(['riskLevel', 'onlineDate'], item.bind)) {
                                                return <React.Fragment key={item.bind}>
                                                    <HFormInput {...item} required={isEditQA ? false : _.get(item, 'required', false)} component={(isDetail || isEditQA) ? Display : _.get(item, 'component')} componentWidth={168} />
                                                    <AntdTooltip title={item.iconText} overlayStyle={{ maxWidth: 800 }}>
                                                        <span>
                                                            <Icon name='bangzhu' style={{ color: '#5477ff' }} />
                                                        </span>
                                                    </AntdTooltip>
                                                </React.Fragment>
                                            }
                                            return <HFormInput {...item} key={item.bind} required={isEditQA ? false : _.get(item, 'required', false)} component={(isDetail || isEditQA) ? Display : _.get(item, 'component')} />
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </Form>
            </div>
            <div className='right-info flex flex-y'>
                <Form value={editFormInfo} onChange={(obj, bind) => changeForm(obj[bind], bind)} error={formError} onError={setFormError}>
                    <div className='project-detail'>
                        <div className='project-detail-top' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 16px 8px 16px' }}>
                            <div className='project-manager'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: '50%',
                                        backgroundColor: getNameImgColor(_.get(userInfo, 'sex')),
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        fontSize: 18,
                                        fontWeight: 700,
                                    }}>
                                        {_.isEmpty(userInfo) ? '' : _.get(userInfo, 'userName')[0]}
                                    </div>
                                    <div style={{ paddingLeft: 8 }}>
                                        <div><FormInput required allowClear={false} bind='principal' component={(isDetail || isEditQA) ? Display : UserSelect} componentWidth={75} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} /></div>
                                        <div style={{ fontSize: 12, color: '#738299' }}>项目经理</div>
                                    </div>
                                </div>
                            </div>
                            <div className='project-state'>
                                {
                                    isEdit &&
                                    <FormInput required
                                        bind='projectStatus'
                                        component={Select}
                                        componentWidth={75}
                                        options={_.get(convertCollection, 'ImplementProject.ProjectStatus')}
                                        convert={v => convertOptions(v, _.get(convertCollection, 'ImplementProject.ProjectStatus'))}
                                    />}
                                {(isDetail || isEditQA) &&
                                    <div>
                                        {convertOptions(_.get(editFormInfo, 'projectStatus'), _.get(convertCollection, 'ImplementProject.ProjectStatus'))}
                                    </div>}
                                <div style={{ fontSize: 12, color: '#738299' }}>项目状态</div>
                            </div>
                            <div className='project-schedule'>
                                <Progress type="circle" percent={projectPercent} width={75} strokeWidth={10} format={percent => {
                                    return <div>
                                        <div style={{ fontWeight: 700 }}>{percent}%</div>
                                        <div style={{ fontSize: 12, color: '#738299', marginTop: 5 }}>项目进度</div>
                                    </div>
                                }} />
                            </div>
                        </div>
                        {
                            (isDetail || isEditQA) ? <Tooltip className='project-brief-tooltip' title={editFormInfo?.projectDesc}>
                                <div className='project-brief'>
                                    <FormInput
                                        bind={'projectDesc'}
                                        maxLength={10000}
                                        counter={false}
                                        label={'项目简介'}
                                        component={Display}
                                    />
                                </div>
                            </Tooltip> :
                                <div className='project-brief'>
                                    <FormInput
                                        bind={'projectDesc'}
                                        maxLength={10000}
                                        counter={false}
                                        label={'项目简介'}
                                        component={TextArea}
                                    />
                                </div>
                        }
                    </div>
                </Form>
                <div className={'dynamic-wrap'} key={count}>
                    <ChangeRecord name='实施项目' funcCode={'1801'} referenceId={currentId} associatedField={'itemName'} associatedFieldName={'任务'} id={currentId} title={projectName} linkUrl={`/impProjectMgt?initProjectId=${currentId}`} />
                </div>
            </div>
        </div >

        {
            showDiffDialog && <DiffReasonDialog close={() => setShowDiffDialog(false)} diffKeyList={diffKeyList} oldObj={formInfo} newObj={editFormInfo} {...{ submit, formConfig, allUserRes, projectStatusOpt: _.get(convertCollection, 'ImplementProject.ProjectStatus') }} />
        }
        {
            showDialog === 'authorize' &&
            <ImpAuthorizeDialog setIsShow={setShowDialog} projectType={'SERVICE'}
                open={{
                    principal: _.get(formInfo, 'principal'),
                    projectNames: _.get(formInfo, 'projectName')
                }}
                currobj={{
                    id: _.get(formInfo, 'id'),
                    principal: _.get(formInfo, 'principal'),
                    planTime: _.toNumber(_.get(formInfo, 'useday')) * 8 * 60
                }} />
        }
    </div >
}

function objDiff(object, other) {
    let diff = {};
    let vChildren;
    for (let key in object) {
        if (typeof object[key] === "object" && typeof other[key] === "object" && object[key] && other[key]) {
            vChildren = objDiff(object[key], other[key]);
            if (Object.keys(vChildren).length > 0) {
                diff[key] = vChildren;
            }


        } else if (object[key] !== other[key]) {
            diff[key] = object[key];

        }
    }
    return diff;
}

function handleInfo(info, projectProgress) {
    // 预计周期
    // 计划结束日期 - 计划开始日期
    if (_.get(info, 'planBeginDate') && _.get(info, 'planEndDate')) {
        const planBeginDate = moment(toDate.str14ToDate(_.get(info, 'planBeginDate')))
        const planEndDate = moment(toDate.str14ToDate(_.get(info, 'planEndDate')))
        const durationPlanDate = moment.duration(planEndDate.diff(planBeginDate))
        info['planDay'] = _.floor(durationPlanDate.asDays())
    }

    // 时间消耗
    // 1、当项目状态为 未进行： 不计算时间消耗
    // 2、当项目状态为 完成： 时间消耗 = (实际结束日期 - 预计开始时间 - 挂起时长）/ 预计周期
    // 3、其他情况：时间消耗 = (当前日期 - 预计开始时间 - 挂起时长）/ 预计周期
    if (_.get(info, 'projectStatus') === '01') {
        info['timeUsed'] = null
    } else if (_.get(info, 'planBeginDate') && _.get(info, 'planDay')) {
        const planBeginDate = moment(toDate.str14ToDate(_.get(info, 'planBeginDate')))
        if (_.get(info, 'projectStatus') === '03') {
            if (_.isNil(_.get(info, 'closeTime'))) {
                info['timeUsed'] = null
            } else {
                const closeTime = moment(toDate.str14ToDate(_.get(info, 'closeTime')))
                const durationPlanDate = moment.duration(closeTime.diff(planBeginDate))
                info['timeUsed'] = (_.floor(durationPlanDate.asDays()) - _.toNumber(_.get(info, 'pauseTime'))) / _.toNumber(_.get(info, 'planDay'))
            }
        } else {
            const durationPlanDate = moment.duration(moment().diff(planBeginDate))
            info['timeUsed'] = (_.floor(durationPlanDate.asDays()) - _.toNumber(_.get(info, 'pauseTime'))) / _.toNumber(_.get(info, 'planDay'))
        }
    } else {
        info['timeUsed'] = null
    }

    // 提升比例
    // 项目进度 - 时间消耗
    const handleProjectProgress = projectProgress ? projectProgress : _.get(info, 'projectProgress')
    if (_.isNil(handleProjectProgress) || _.toNumber(handleProjectProgress) === 0) {
        info['improvePercent'] = null
    } else {
        info['improvePercent'] = (_.toNumber(handleProjectProgress)) - (_.toNumber(_.get(info, 'timeUsed')))
    }

    // 未来投入（未落库情况）
    // 预估总成本 - 实际成本
    if (_.isNil(_.get(info, 'residualCost'))) {
        info['residualCost'] = _.floor(_.toNumber(_.get(info, 'budgetCost')) - _.toNumber(_.get(info, 'usedCost')), 2)
    }

    // 投入总成本
    // 实际成本 + 未来投入
    info['totalCost'] = (_.toNumber(_.get(info, 'usedCost'))) + (_.toNumber(_.get(info, 'residualCost')))

    // 节约比例
    //（预估总成本 - 投入总成本）/ 预估总成本
    if (_.get(info, 'budgetCost')) {
        info['savingRatio'] = (_.toNumber(_.get(info, 'budgetCost')) - _.toNumber(_.get(info, 'totalCost'))) / _.toNumber(_.get(info, 'budgetCost'))
        if (info['savingRatio'] < 0) {
            info['savingRatio'] = 0
        }
    }

    return info
}

export default ImpProjectInfo;
import React, { useState, useContext, useCallback, useRef, useEffect } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Card, Dialog, Loader, ScrollViewer } from 'rootnet-ui'
import { Form, FormInput, CheckBox } from 'rootnet-edit'
import { ScheduleManagementContext } from '../../../common/Context'
import { Icon } from '../../../../components'
import './exportTestPlanApiDialog.scss'

const formList = [
  {
    header: '基础字段', bind: 'basics', children: [
      { header: 'ID', bind: 'id', },
      { header: '标题', bind: 'title', },
      { header: '用例状态', bind: 'status', },
      { header: '评审人', bind: 'reviewer', },
      { header: '评审时间', bind: 'reviewTime', },
      { header: '优先级', bind: 'priority', },
      { header: '用例类型', bind: 'type', },
      { header: '正反例', bind: 'positiveOrNegativeName', },
      { header: '自动化实现', bind: 'automaticName', },
      { header: '测试阶段', bind: 'testPhase', },
      { header: '菜单', bind: 'menu', },
      { header: '所属用例库', bind: 'libraryName', },
      { header: '用例目录', bind: 'tableName', },
      { header: '所属产品', bind: 'productName', },
      { header: '所属模块', bind: 'moduleName', },
      { header: '维护人', bind: 'maintenanceUser', },
      { header: '测试目的', bind: 'objective', },
      { header: '前置条件', bind: 'preconditions', },
      { header: '数据准备', bind: 'dataPre', },
      { header: '用例步骤', bind: 'caseSteps', },
      { header: '预期结果', bind: 'expectResult', },
      { header: '备注', bind: 'remark', },
      { header: '创建人', bind: 'createUser', },
      { header: '创建时间', bind: 'createTime', },
      { header: '更新人', bind: 'updateUser', },
      { header: '更新时间', bind: 'updateTime', },
      { header: '关联需求ID', bind: 'relateShowReqId', },
      { header: '关联需求标题', bind: 'relateShowReqName', },
      { header: '关联IssueID', bind: 'relateShowIssueId', },
      { header: '关联Issue标题', bind: 'relateShowIssueName', },
      { header: '关联缺陷ID', bind: 'relateShowDefectId', },
      { header: '关联缺陷标题', bind: 'relateShowDefectName', },
    ]
  },
  {
    header: '用例执行字段', bind: 'caseExecution', children: [
      { header: '执行状态', bind: 'result', },
      { header: '执行人', bind: 'executor', },
      { header: '执行次数', bind: 'executeNum', },
      { header: '缺陷个数', bind: 'issueNum', },
      { header: '执行时间', bind: 'executTime', },
      { header: '实际执行结果', bind: 'actualResult', },
      { header: '用例负责人', bind: 'principle', },
      { header: '上传图片', bind: 'writeCellDataFile', },
    ]
  },
]

const list = _.flatten(_.map(formList, o => o.children))
const basicsList = _.get(_.find(formList, o => o.bind === 'basics'), 'children', [])
const caseExecutionList = _.get(_.find(formList, o => o.bind === 'caseExecution'), 'children', [])

function detectionCheckAll(data, currentData, bind = 'header') {
  const filterData = _.filter(currentData, v => _.includes(_.map(data, k => k[bind]), v))
  return _.size(filterData) === _.size(data)
}

const HFormInput = props => <FormInput componentWidth={140} component={CheckBox}  {...props} />

export default function ExportTestPlanApiDialog(props) {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const { close, parameter = {}, url, name = '', useName = false } = props
  const [params, setParams] = useState()
  const [all, setAll] = useState({})
  const [downloadOptions, setDownloadOptions] = useState()
  const [submitLoading, setSubmitLoading] = useState(false)

  const selectAll = useCallback((bind, flag) => {
    setAll(x => _.assign({}, x, { [bind]: flag }))
    const findData = _.find(formList, o => o.bind === bind)
    setParams(x => _.assign({}, x, _.fromPairs(_.map(_.get(findData, 'children', []), o => [o.bind, flag]))))
  }, [])

  const getDownLoadList = useCallback((checkedList) => {
    return setDownloadOptions(_.compact(_.map(checkedList, v => _.find(list, o => v === o.header))))
  }, [])

  const handleFormChange = useCallback(formObj => {
    const screenData = {}
    _.forEach(formObj, (val, key) => { if (val) screenData[key] = val })
    const data = _.compact(_.map(formObj, (v, k) => { if (v) return k }))
    setAll(x => _.assign({}, x, {
      basics: detectionCheckAll(basicsList, data, 'bind'),
      caseExecution: detectionCheckAll(caseExecutionList, data, 'bind')
    }))
    setParams(screenData)
  }, [])

  const confirm = useCallback(() => {
    if (submitLoading) return
    const fieldList = _.map(downloadOptions, o => o.bind)
    const fieldNameList = _.map(downloadOptions, o => o.header)
    const urlParams = _.assign({ fieldList: _.join(fieldList, ',') }, useName && { fieldList, fieldNameList }, parameter)
    setSubmitLoading(true)
    downloadPostProgress(url, urlParams, name, close, setSubmitLoading)
  }, [close, downloadOptions, downloadPostProgress, name, parameter, url, useName, submitLoading])

  useEffect(() => {
    setAll(_.assign({}, ..._.map(formList, o => ({ [o.bind]: true }))))
    setParams(_.assign({}, ..._.map(list, o => ({ [o.bind]: true }))))
  }, [])

  return (
    <Dialog
      className='export-test-plan-api-dialog'
      cancel={close}
      header={'导出'}
      confirm={confirm}
      confirmButtonText='确认导出'
      confirmButtonDisabled={_.size(downloadOptions) === 0 || submitLoading}
    >
      {
        submitLoading && <Loader fill />
      }
      <div className={'left-panel-content'}>
        <Form value={params} onChange={handleFormChange}>
          {
            _.map(formList, o => {
              return <Card
                key={o.bind}
                title={o.header}
                extra={<CheckBox
                  value={all[o.bind]}
                  onChange={v => selectAll(o.bind, v)}>全选</CheckBox>
                }
              >
                {
                  _.map(o.children, item => {
                    return <HFormInput key={item.bind} bind={item.bind}>{item.header}</HFormInput>
                  })
                }
              </Card>
            })
          }
        </Form>
      </div>
      <DisplayData params={params} setParams={setParams} setAll={setAll} getDownLoadList={getDownLoadList} />
    </Dialog>
  )
}

function DisplayData(props) {
  const { params, setParams, setAll, getDownLoadList } = props
  const [checkedList, setCheckedList] = useState([])

  const changeOrder = useCallback((drag, o) => {
    const t = _.without(checkedList, drag);
    const index = _.indexOf(t, o);
    t.splice(index, 0, drag)
    const dataOrder = []
    _.forEach(t, v => _.find(list, o => { if (v === o.header) dataOrder.push(o) }));
    const filterData = _.reduce(dataOrder, (initialData, o) => { initialData[o.bind] = true; return initialData }, {})
    setCheckedList([...t, '']);
    setParams(filterData);
  }, [checkedList, setParams])

  const dragObjRef = useRef()

  useEffect(() => {//当前选定的字段
    const selectedFields = []
    const listArr = _.map(params, (val, key) => { if (val) return key })
    _.map(listArr, v => { _.map(list, o => { if (o.bind === v) selectedFields.push(o.header) }) })
    setCheckedList([...selectedFields, ''])
  }, [params])

  useEffect(() => {
    getDownLoadList(checkedList)
  }, [checkedList, getDownLoadList])

  return (
    <div className={'right-panel-content'}>
      <ScrollViewer>
        {
          _.map(checkedList, (v, i) => {
            return (
              <DragItem
                key={i}
                value={v}
                dragObjRef={dragObjRef}
                changeOrder={changeOrder}
              >
                {v && `∷ ${v}`}{v && < Icon onClick={() => deleteField(v)} name='close' />}
              </DragItem>
            )
          })
        }
      </ScrollViewer>
    </div>
  )

  function deleteField(val) {
    const filterData = {}
    const filterList = _.filter(checkedList, v => v !== val)
    const data = _.filter(list, o => _.find(filterList, v => o.header === v))
    _.forEach(data, o => filterData[o.bind] = true)
    setAll(x => _.assign({}, x, {
      basics: detectionCheckAll(basicsList, filterList),
      caseExecution: detectionCheckAll(caseExecutionList, filterList)
    }))
    setParams(filterData)
    setCheckedList([...filterList, ''])
  }

}

function DragItem(props) {
  const { children, value, dragObjRef, changeOrder } = props

  return <div className={cls("dragItem", { cancelStyle: !value })}
    draggable={value !== ''}
    onDragStart={e => handleDragStart(e, value)}
    onDragEnter={e => handleDragEnter(e, value)}
    onDragLeave={handleDragLeave}
    onDrop={e => handleDrop(e, value)}
    onDragOver={e => handleDragOver(e, value)}
    onDragEnd={handleDragEnd}
  >
    {children}
  </div>

  function handleDragStart(e, o) {
    dragObjRef.current = o;
  }
  function handleDragEnd() {
    dragObjRef.current = null;
  }
  function handleDragEnter(e, o) {
    if (dragObjRef.current === o) return;
    const ele = e.currentTarget;
    if (ele) ele.classList.add("dragover");
  }
  function handleDragLeave(e) {
    const ele = e.currentTarget;
    if (ele) ele.classList.remove("dragover");
  }
  function handleDrop(e, o) {
    const ele = e.currentTarget;
    if (ele) ele.classList.remove("dragover");
    if (dragObjRef.current === o) return;
    const drag = dragObjRef.current;
    dragObjRef.current = null;
    changeOrder(drag, o);
  }
  function handleDragOver(e, o) {
    e.preventDefault();
    if (o !== dragObjRef.current) e.dataTransfer.dropEffect = "move";
    else e.dataTransfer.dropEffect = "none";
  }
}

import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import './ViewArea.scss'
import _ from "lodash";
import {strParams} from "../../../utils/publicFun";
import useGet from "rootnet-biz/es/hooks/useGet";
import Icon from "../../../components/Icon";
import {Popover, Badge} from "antd";
import ViewPop from "./ViewPop";
import CustomizeOptions from "../customizeOptions/CustomizeOptions";
import {useFuncCode} from "../commonMethod";
import clsx from "clsx";
import {usePost} from "rootnet-biz/es/hooks";
import {isNil} from "../../appraise/components/method";

function ViewArea(props) {
    const {getFieldList, allColumns, funcCode, refreshList, replaceParams, replaceAllParams, total, optionsConfig,
        getInitParams, search, currentViewId, setCurrentViewId, loading, params, resetSearch, pageSize, extraBtnArea,
        outerSetViewList, onOuterViewItemClick} = props
    const {doFetch: getViewList} = useGet()
    const [showViewPop, setShowViewPop] = useState(false)
    const [searchAreaOpen, setSearchAreaOpen] = useState(false)
    const searchRef = useRef()
    const [viewList, setViewList] = useState([])
    const [advancedQueryNum, setAdvancedQueryNum] = useState(0)
    const {doFetch: advancedQueryInfo} = usePost()

    const selectCurrentId = useCallback((currentViewId,viewList)=>{
        if(_.isEmpty(viewList)) return
        if(_.includes(_.map(viewList, x => x.id), currentViewId)) return
        let currentView = _.find(viewList, x => x.lastViewedFlag === "Y")
        if(_.isNil(currentView)){
            currentView = _.find(viewList, x => x.userAccount === 'system')
        }
        if(_.isNil(currentView)){
            currentView = _.head(viewList)
        }
        const lastViewedId = _.get(currentView,'id')
        setCurrentViewId(lastViewedId)
    },[setCurrentViewId])

    const getAdvancedQueryInfo = useCallback(()=>{
        advancedQueryInfo('/UserSetting/getAdvancedQuery',{menuCode: funcCode}).then(res => {
            if(isNil(res)){
                setAdvancedQueryNum(0)
            }else{
                const initDetail = JSON.parse(res)
                const conditionList = _.get(initDetail,'conditionList')
                setAdvancedQueryNum(_.size(conditionList))
            }
        })
    },[advancedQueryInfo, funcCode])

    const refreshViewList = useCallback(()=>{
        getViewList('/UserSetting/getViews?'+strParams({funcCode,type: '02'})).then(res => {
            selectCurrentId(currentViewId,res)
            setViewList(_.sortBy(res,'sort'))
            if(outerSetViewList){
                outerSetViewList(_.sortBy(res,'sort'))
            }
        })
        getAdvancedQueryInfo()
    },[funcCode,getViewList,selectCurrentId,currentViewId, outerSetViewList, getAdvancedQueryInfo])

    useEffect(()=>{
        if(_.isNil(funcCode)) return
        refreshViewList()
    },[refreshViewList,funcCode])

    const refreshFieldList = useCallback(()=>{
        if(_.isNil(currentViewId)) return
        getFieldList('/UserSetting/getDisplayedColumns?viewId='+currentViewId)
    },[currentViewId,getFieldList])

    useEffect(()=>{
        if(_.isNil(currentViewId)) return
        refreshFieldList()
    },[currentViewId,refreshFieldList])

    const showOuterViewList = useMemo(()=>{
        if(_.isEmpty(viewList)) return []
        return _.filter(viewList, x => x.showOuter)
    },[viewList])

    const afterUpdatedRefresh = useCallback(()=>{
        refreshViewList()
        refreshList(replaceParams)
        refreshFieldList()
    },[refreshViewList,refreshList,refreshFieldList,replaceParams])

    const closeViewPop = useCallback(()=>{
        setShowViewPop(false)
    },[])

    const moreItemText = useMemo(()=>{
        const currentView = _.find(viewList, x => x.id === currentViewId)
        if(currentView){
            if(currentView.showOuter){
                return '更多'
            }else{
                return currentView.subType
            }
        }
        return '更多'
    },[currentViewId, viewList])

    const isCustomView = useMemo(()=>{
        const currentView = _.find(viewList, x => x.id === currentViewId)
        if(currentView){
            return !_.get(currentView,'showOuter')
        }
        return false
    },[currentViewId, viewList])

    useLayoutEffect(()=>{
        if(_.isEmpty(allColumns)) return
        refreshList(replaceParams)
    },[refreshList,allColumns,replaceParams])

    const [systemViewEditAuth] = useFuncCode(['2201'])

    const searchConditionNum = useMemo(()=>{
        const formParam = _.omit(params,['pageNum','pageSize'])
        return _.get(_.countBy(formParam, v => !!v), 'true')
    },[params])

    useEffect(()=>{
        if(!_.isNil(searchConditionNum) && searchConditionNum !== 0){
            setSearchAreaOpen(true)
        }
    },[searchConditionNum])

    return <div className={'view-area-wrap flex-y'}>
        <div className="view-area flex">
            <div className="system-view-area flex center-y">
                {
                    _.map(showOuterViewList, viewItem => <div className={clsx('system-view-item flex center-y',{'active-system-view-item':currentViewId === viewItem.id,'forbid':loading})} key={viewItem.id}
                                                           onClick={()=>{
                                                               if(loading) return
                                                               setCurrentViewId(viewItem.id)
                                                               searchRef.current.refreshParams()
                                                               if(onOuterViewItemClick){
                                                                   onOuterViewItemClick()
                                                               }
                                                           }}>
                        <div className="system-view-name">
                            {viewItem.subType}
                        </div>
                        {
                            currentViewId === viewItem.id && !_.isNil(total) && !loading &&
                            <div className="total">
                                {total}
                            </div>
                        }
                    </div>)
                }
                <Popover overlayClassName={'view-pop'} content={<ViewPop refresh={afterUpdatedRefresh} outerSystemViewEditAuth={systemViewEditAuth}
                                                                         resetParams={()=>searchRef.current.resetParams()} refreshParams={()=>searchRef.current.refreshParams()}
                                                                         {...{viewList,currentViewId,refreshViewList, setCurrentViewId,funcCode,closeViewPop,allColumns,onOuterViewItemClick}}/>}
                         placement="bottom" trigger="click" open={showViewPop} onOpenChange={setShowViewPop} zIndex={5}>
                    <div className={`system-view-item flex center-y more-item ${isCustomView ? 'active-system-view-item' : ''}`}>
                        <div className="system-view-name">
                            {moreItemText}
                        </div>
                        {
                            isCustomView && !_.isNil(total) && !loading &&
                            <div className="total">
                                {total}
                            </div>
                        }
                        <Icon className={'more-icon'} name={'arrow_drop_down'}/>
                    </div>
                </Popover>
                <Badge count={searchConditionNum} size="small" offset={[8, 0]}>
                    <div className={`search-btn flex center-y ${searchAreaOpen? 'search-area-open' :''}`} onClick={()=>setSearchAreaOpen(x => !x)}>
                        <Icon name={'sousuo1'} className={'search-icon'}/>
                        <div className="search-text">搜索</div>
                    </div>
                </Badge>
            </div>
        </div>
        <div className="custom-search-area" style={{display: searchAreaOpen? '' : 'none'}}>
            <CustomizeOptions ref={searchRef} allOptions={optionsConfig} baseOptions={[]}
                              {...{ search, getInitParams, funcCode,replaceAllParams, resetSearch,pageSize,extraBtnArea,replaceParams,loading, allColumns, afterUpdatedRefresh, advancedQueryNum }} />
        </div>
    </div>
}

export default ViewArea;
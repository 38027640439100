import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import './VersionDetailDialog.scss'
import {DatePicker, Form, FormInput, Input, RadioGroup, Select, TextArea} from "rootnet-edit";
import convertOptions from "../../../../../common/ConvertOptions";
import UserSelect from "../../../../../common/personSelectPop/UserSelect";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import _ from "lodash";
import Icon from "../../../../../../components/Icon";
import useGet from "rootnet-biz/es/hooks/useGet";
import {isNil} from "../../../../../appraise/components/method";
import {usePost} from "rootnet-biz/es/hooks";

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={170} {...props}/>

const OPTIONS_URLS = [
    '/customerserviceinfo/selectiveCustomerReleaseRec',
    '/common/globalconst?globalConst=broker',
    '/develop/product/subproduct/list',
]

const initData = {
    updateEnv: '0',
    updateType: '0'
}

function VersionDetailDialog(props) {
    const {typeOptions, envOptions, allUserRes,currentInfo, setCurrentInfo, refreshList, id, updateDynamic} = props
    const {mode, recID} = useMemo(()=>currentInfo || {},[currentInfo])
    const [purposeExpand, setPurposeExpand] = useState(true)
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const {data: detail, doFetch: getDetail} = useGet()
    const [formData, setFormData] = useState(initData)
    const [infoError, setInfoError] = useState()
    const {data:relatedVersion, doFetch: getRelatedVersion} = useGet()
    const {doFetch: submitPost} = usePost()

    const relatedVersionOptions = useMemo(()=>{
        if(_.isNil(relatedVersion)) return []
        return _.map(relatedVersion, x => ({
            text: x.relateReleaseId,
            value: x.relateReleaseId
        }))
    },[relatedVersion])

    useEffect(()=>{
        if(_.isNil(recID)) return
        getDetail('/customer/releaserec/info?RecID='+recID).then(res => {
            setFormData(res)
            if(_.get(res,'releaseID')){
                getRelatedVersion('/customer/releaserec/associated/ver?ReleaseID='+_.get(res,'releaseID'))
            }
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    },[getDetail,recID,getRelatedVersion])

    const [versionOptions, brokerOptions, moduleOptions] = useMemo(()=>{
        if(_.isNil(optionsRes)) return []
        const [d1, d2, d3] = optionsRes
        const filterModuleOptions = _.filter(d3, x => ['STOCK', 'FUTURE'].includes(x.extConn))
        const moduleOptions =  _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
        return [
            _.map(d1, x => ({text: x.releaseid, value: x.releaseid})),
            _.map(d2, x => ({text: x.interiorId, value: x.interiorId})),
            moduleOptions
        ]
    },[optionsRes])

    const changeForm = useCallback((newObj, bind)=>{
        setFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            if(bind === 'updateType'){
                cloneObj['updateUser'] = null
            }
            if(bind === 'releaseID'){
                cloneObj['releaseIds'] = []
                if(_.get(newObj,'releaseID')){
                    getRelatedVersion('/customer/releaserec/associated/ver?ReleaseID='+_.get(newObj,'releaseID'))
                }
            }
            if(bind === 'prodsubsysid'){
                cloneObj['extConntoBrokersList'] = []
            }
            return cloneObj
        })
    },[getRelatedVersion])

    const innerUser = useMemo(()=>{
        return _.get(formData,'updateType') === "1"
    },[formData])

    const canSubmit = useMemo(()=>{
        if(isNil(_.trim(_.get(formData,'updateDesc')))) return false
        return _.every(infoError, _.isNil)
    },[infoError,formData])

    const brokersRequired = useMemo(()=>{
        return !_.isNil(_.get(formData,'prodsubsysid'))
    },[formData])

    useEffect(()=>{
        if(brokersRequired){
            if(_.isEmpty(_.get(formData,'extConntoBrokersList'))){
                setInfoError(x=>_.assign({},x,{'extConntoBrokersList': '必填项！'}))
            }
        }else{
            setInfoError(x => _.assign({},x,{'extConntoBrokersList': null}))
        }
    },[formData,brokersRequired])

    const extendModuleOptions = useMemo(()=>{
        if(_.isNil(moduleOptions)) return []
        if(_.isNil(detail) || _.isNil(_.get(detail,'prodsubsysid'))) return moduleOptions
        const prodsubsysid =  _.get(detail,'prodsubsysid')
        const valueList = _.map(moduleOptions, x => _.get(x,'value'))
        if(_.includes(valueList,prodsubsysid)){
            return moduleOptions
        }else{
            return _.concat([{text:prodsubsysid,value:prodsubsysid}],moduleOptions)
        }
    },[detail, moduleOptions])

    const submit = useCallback(()=>{
        const postParams = {
            ...formData,
            projectId: id
        }
        const url = mode === 'add' ? '/customer/releaserec/add' :'/customer/releaserec/update'
        submitPost(url,postParams).then(()=>{
            Messager.show("提交成功", { icon: 'success' })
            refreshList()
            updateDynamic()
            setCurrentInfo(null)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitPost, formData, setCurrentInfo, refreshList, id, mode, updateDynamic])

    return <Dialog header={'版本升级纪录'} className={'version-detail-dialog'} cancel={()=>setCurrentInfo(null)} confirmButtonDisabled={!canSubmit} confirm={submit}>
        <Form value={formData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({},x,v))}>
            <HFormInput label={'升级版本'} bind={'releaseID'} required component={Select} search options={versionOptions}/>
            <HFormInput label={'关联版本'} bind={'releaseIds'} component={Select} multiple search options={relatedVersionOptions}/>
            <HFormInput label={'升级日期'} bind={'updateDate'} required component={DatePicker}
                        bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                        convert={v => {
                            if(_.isNil(v)) return '-'
                            if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                            return dateFormat('YYYY-MM-DD', v) || '-'
                        }}/>
            <HFormInput label={'升级环境'} bind={'updateEnv'} required component={RadioGroup}
                        options={envOptions} convert={v => convertOptions(v, envOptions)} componentWidth={180}
            />
            <HFormInput label={'升级类型'} bind={'updateType'} required component={RadioGroup}
                        options={typeOptions} convert={v => convertOptions(v, typeOptions)} labelWidth={117} componentWidth={180}
            />
            <div/>
            <HFormInput label={'升级人员'} bind={'updateUser'} required component={innerUser?UserSelect:Input}
                        convert={v => innerUser?convertOptions(v, allUserRes,'userName','userAccount'):v}/>
            <HFormInput label={'外挂升级程序'} bind={'prodsubsysid'} component={Select} options={extendModuleOptions} search clear/>
            <HFormInput label={'对接券商'} bind={'extConntoBrokersList'} required={brokersRequired} disabled={!brokersRequired} component={Select} options={brokerOptions} search clear multiple/>
        </Form>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: purposeExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setPurposeExpand(v => !v)}/>
                版本升级目的 <span style={{color: 'red'}}>（必填）</span>
            </div>
            <div className="area-content-wrap" style={{height: purposeExpand ? '' : 0}}>
                <TextArea className={'purpose-textarea'} value={_.get(formData,'updateDesc')} onChange={(v)=>changeForm({updateDesc:v},'updateDesc')}/>
            </div>
        </div>
    </Dialog>
}

export default VersionDetailDialog;
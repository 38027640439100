import React, { useMemo } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import DevProject from './components/devProject'
import ImpProject from './components/impProject'
import PresalesProject from './components/presalesProject'
import { useGet } from 'rootnet-biz/lib/hooks'
import './index.scss'

const URLS = [
  // '/common/system',
  '/common/userinfo',
  '/common/globalconst?globalConst=ProjectStatus', //研发
  '/common/globalconst?globalConst=PlanStatus', //实施
  '/common/globalconst?globalConst=PresalesProjectStatus', //售前
]

export default function AssociatedProject(props) {
  const { data: selectRes } = useGet(URLS)

  const [proManagerOpt, devStatusOpt, impStatusOpt, presalesStatusOpt] = useMemo(() => {
    if (_.isEmpty(selectRes)) return []
    const [d1, d2, d3, d4] = selectRes || []
    return [
      // _.map(d1, v => ({ value: v.projectId, text: ''.concat(v.projectId, '-', v.projectName) })),
      _.map(d1, v => ({ value: v.userAccount, text: `${v.userName} ${v.indexValidFlag === 0 ? '(离职)' : ''} - ${v.userAccount}`, tag: `${v.userAccount}${v.userName}` })),
      _.map(d2, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
      _.map(d3, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
      _.map(d4, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
    ]
  }, [selectRes])

  return (
    <div className='customer-associated-project'>
      <Tabs
        tabPosition='left'
        className='customer-associated-project-tabs'
        items={[
          {
            label: '研发项目',
            key: 'devProject',
            children: <DevProject {...{ ...props, proManagerOpt, projectStatusOpt: devStatusOpt }} />,
          },
          {
            label: '实施项目',
            key: 'impProject',
            children: <ImpProject {...{ ...props, proManagerOpt, projectStatusOpt: impStatusOpt }} />,
          },
          {
            label: '售前项目',
            key: 'presalesProject',
            children: <PresalesProject {...{ ...props, proManagerOpt, projectStatusOpt: presalesStatusOpt }} />,
          },
        ]} />
    </div>
  )
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './VersionPlanDashboard.scss'
import { DataGrid, Messager } from 'rootnet-ui';
import _ from 'lodash'
import { Box } from "../../../../../common/commonComponent";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../../common/ConvertGlobalConstOptions";
import { Icon } from "../../../../../../components";
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import RequirementDetailDialog from "../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import { TextIconBtn } from "../../../../../common/TextIconBtn";
import { Badge, Popover, Checkbox } from 'antd';
import VersionPlanConditionFilterPop from "./versionPlanConditionFilterPop/VersionPlanConditionFilterPop";
import { strParams } from "../../../../../../utils/publicFun";
import clsx from "clsx";
import { ScheduleManagementContext } from "../../../../../common/Context";
import IssueDetailDialog from "../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog";
import DevListDetailDialog from '../../../../../devListMgt/devListDetailDialog';

const formatDate = v => dateFormat('YYYY-MM-DD')(toDate.str14ToDate(v));

const STAGE_STATUS_COLOR = {
    0: '#93C36B',
    1: '#FDBC03',
    2: 'red',
}

const STAGE_BACKGROUND_COLOR = {
    0: 'unset',
    1: '#FDBC03',
    2: '#FD8081',
}

const TYPE_COLOR = {
    'req': '#5477FF',
    'dev': '#5477FF',
    'issue': '#FF5454',
}

function getColumns(props) {
    const { showReqMileStoneOptions, dataRes, setShowDetailDialog, totalWorkload } = props

    const baseHeaders = [
        { header: '编号', bind: 'businessId', width: 170, tooltip: true },
        {
            header: '标题', bind: 'title', width: 200, tooltip: true,
            convert: (v, o) => ({
                value: <div className={'flex center-y'}>
                    <div className="title-tag flex center" style={{ backgroundColor: TYPE_COLOR[_.get(o, 'bizType.value')] }}>
                        {_.toUpper(_.get(o, 'bizType.value'))}
                    </div>
                    <div className={'can-enter-text flex center-y'} onClick={() => {
                        if (_.get(o, 'bizType.value') === 'req') {
                            setShowDetailDialog({
                                module: 'req',
                                id: _.get(o, 'id.value')
                            })
                        }
                        if (_.get(o, 'bizType.value') === 'issue') {
                            setShowDetailDialog({
                                module: 'issue',
                                id: _.get(o, 'id.value')
                            })
                        }
                        if (_.get(o, 'bizType.value') === 'dev') {
                            setShowDetailDialog({
                                module: 'dev',
                                id: _.get(o, 'id.value')
                            })
                        }
                    }}
                    >{_.get(v, 'value')}</div>
                </div>,
                rowSpan: _.get(v, 'rowSpan')
            })
        },
        { header: '状态', bind: 'statusName', width: 90, tooltip: true },
        {
            header: <div className={'flex-y'}>
                <div>工作量(人日)</div>
                <div>合计:{totalWorkload}</div>
            </div>, bind: 'workload', width: 100, tooltip: true, align: 'right'
        },
    ]

    const reqMileStoneHeaders = _.map(showReqMileStoneOptions, reqMileStone => {
        const reqMileStoneItem = _.find(_.get(dataRes, 'tableHeadData'), x => _.get(x, 'storyStage') === _.get(reqMileStone, 'value'))
        return {
            header: <div className={'flex center-y'}>
                {_.get(reqMileStone, 'text')}
                <Icon name={'qizi'} className={'milestone-icon'} style={{ color: _.get(STAGE_STATUS_COLOR, _.get(reqMileStoneItem, 'flag')) }} />
                {formatDate(_.get(reqMileStoneItem, 'releaseDate'))}
            </div>,
            align: 'center',
            children: [
                {
                    header: <div className={'flex center-y'}>
                        <div className="header-count-item">共计{_.get(reqMileStoneItem, 'sumCount') || 0}</div>
                        <div className="header-count-item">风险{_.get(reqMileStoneItem, 'riskCount') || 0}</div>
                        <div className="header-count-item">异常{_.get(reqMileStoneItem, 'anomalousCount') || 0}</div>
                        <div className="header-count-item">工作量{_.get(reqMileStoneItem, 'sumWorkload') || 0}人日</div>
                    </div>,
                    align: 'center',
                    children: [
                        {
                            header: '负责人', bind: `${_.get(reqMileStone, 'value')}-ownerName`, width: 80
                            , convert: (v, o) => <div className={'inner-cell flex center-y'}
                                style={{ backgroundColor: _.get(STAGE_BACKGROUND_COLOR, _.get(o, `${_.get(reqMileStone, 'value')}-flag`)) }}>{v}</div>
                        },
                        {
                            header: '状态', bind: `${_.get(reqMileStone, 'value')}-statusName`, width: 80
                            , convert: (v, o) => <div className={'inner-cell flex center-y'}
                                style={{ backgroundColor: _.get(STAGE_BACKGROUND_COLOR, _.get(o, `${_.get(reqMileStone, 'value')}-flag`)) }}>{v}</div>
                        },
                        {
                            header: '工作量(人日)', bind: `${_.get(reqMileStone, 'value')}-workload`, width: 90, align: 'right',
                            convert: (v, o) => <div className={'inner-cell flex center-y right'}
                                style={{ backgroundColor: _.get(STAGE_BACKGROUND_COLOR, _.get(o, `${_.get(reqMileStone, 'value')}-flag`)) }}>{v}</div>
                        },
                        {
                            header: '计划完成', bind: `${_.get(reqMileStone, 'value')}-completeTimePlan`, width: 90,
                            convert: (v, o) => <div className={'inner-cell flex center-y'}
                                style={{ backgroundColor: _.get(STAGE_BACKGROUND_COLOR, _.get(o, `${_.get(reqMileStone, 'value')}-flag`)) }}>{formatDate(v)}</div>
                        },
                        {
                            header: '实际完成', bind: `${_.get(reqMileStone, 'value')}-completeTimeActual`, width: 90,
                            convert: (v, o) => <div className={'inner-cell flex center-y'}
                                style={{ backgroundColor: _.get(STAGE_BACKGROUND_COLOR, _.get(o, `${_.get(reqMileStone, 'value')}-flag`)) }}>{formatDate(v)}</div>
                        },
                    ]
                }
            ]
        }
    })
    return _.concat(baseHeaders, reqMileStoneHeaders)
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 2,
    resizable: true,
    columns: options,
    virtualized: false,
    autoFill: true,
});

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=reqMileStone',
]

const INIT_STATUS_LIST = [
    '关闭', '进行中', '初始化', '未进行'
]

function VersionPlanDashboard(props) {
    const { currentId } = props
    const { downloadGetProgress } = useContext(ScheduleManagementContext)
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: dataRes, doFetch: getList, loading, error } = useGet()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [searchExtraParams, setSearchExtraParams] = useState({})
    const [showFilterPop, setShowFilterPop] = useState(false)
    const [filterStatusList, setFilterStatusList] = useState(INIT_STATUS_LIST)
    const [statusCount, setStatusCount] = useState()

    useEffect(() => {
        if (_.isNil(dataRes)) return
        const mainData = _.get(dataRes, 'tableMainData')
        let count = {
            '关闭': 0,
            '进行中': 0,
            '初始化': 0,
            '未进行': 0,
        }
        _.forEach(mainData, item => {
            _.forEach(_.get(item, 'stageList'), x => {
                const statusName = _.get(x, 'statusName')
                count[statusName] = count[statusName] + 1
            })
        })
        setStatusCount(count)
    }, [dataRes])

    const statusOptions = useMemo(() => {
        return _.map(INIT_STATUS_LIST, item => ({
            label: `${item}：${_.get(statusCount, item) || 0}`,
            value: item,
        }))
    }, [statusCount])

    const refresh = useCallback(() => {
        if (_.isNil(currentId)) return
        const searchParams = {
            releaseId: currentId,
            ...searchExtraParams
        }
        getList('/releaseStory/panel/list?' + strParams(searchParams))
            .catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [getList, currentId, searchExtraParams])

    useEffect(() => {
        refresh()
    }, [refresh])

    const [reqMileStoneOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const showReqMileStoneOptions = useMemo(() => {
        const selectedStage = _.get(searchExtraParams, 'selectedStage')
        if (_.isEmpty(selectedStage)) return reqMileStoneOptions
        return _.filter(reqMileStoneOptions, x => _.includes(selectedStage, x.value))
    }, [searchExtraParams, reqMileStoneOptions])

    const list = useMemo(() => {
        const reqList = _.get(dataRes, 'tableMainData')
        const handleList = []
        _.forEach(reqList, reqItem => {
            const stageGroup = _.groupBy(_.get(reqItem, 'stageList'), 'stage')
            let maxStageLength = 1
            _.forEach(stageGroup, stageList => {
                const stageLength = _.size(stageList)
                if (stageLength > maxStageLength) {
                    maxStageLength = stageLength
                }
            })
            for (let i = 0; i < maxStageLength; i++) {
                const newItem = {}
                if (i === 0) {
                    newItem['id'] = { value: _.get(reqItem, 'id'), rowSpan: maxStageLength }
                    newItem['businessId'] = { value: _.get(reqItem, 'businessId'), rowSpan: maxStageLength }
                    newItem['bizType'] = { value: _.get(reqItem, 'bizType'), rowSpan: maxStageLength }
                    newItem['title'] = { value: _.get(reqItem, 'title'), rowSpan: maxStageLength }
                    newItem['statusName'] = { value: _.get(reqItem, 'statusName'), rowSpan: maxStageLength }
                    newItem['workload'] = { value: _.get(reqItem, 'workload'), rowSpan: maxStageLength }
                }
                _.forEach(stageGroup, (stageList, stage) => {
                    const stageItem = _.get(stageList, i)
                    if (_.isNil(stageItem)) return
                    if (!_.includes(filterStatusList, _.get(stageItem, 'statusName'))) return
                    newItem[`${stage}-ownerName`] = _.get(stageItem, 'ownerName')
                    newItem[`${stage}-statusName`] = _.get(stageItem, 'statusName')
                    newItem[`${stage}-workload`] = _.get(stageItem, 'workload')
                    newItem[`${stage}-completeTimePlan`] = _.get(stageItem, 'completeTimePlan')
                    newItem[`${stage}-completeTimeActual`] = _.get(stageItem, 'completeTimeActual')
                    newItem[`${stage}-flag`] = _.get(stageItem, 'flag')
                })
                handleList.push(newItem)
            }
        })
        return handleList
    }, [dataRes, filterStatusList])

    const totalWorkload = useMemo(() => {
        return combined(list, 'workload.value').toFixed(1)
    }, [list])

    const option = useMemo(() => {
        return getOptions(getColumns({ showReqMileStoneOptions, dataRes, setShowDetailDialog, totalWorkload }))
    }, [showReqMileStoneOptions, dataRes, totalWorkload])

    const extraParamsCount = useMemo(() => {
        let count = 0
        _.forEach(searchExtraParams, v => {
            if (!_.isNil(v)) {
                count++
            }
        })
        return count
    }, [searchExtraParams])

    const extra = useMemo(() => {
        const searchParams = {
            releaseId: currentId,
            ...searchExtraParams
        }
        return <div className={'extra-wrap flex center-y'}>
            <div className={'count-wrap flex center-y'}>
                <div className={'total-count'}>
                    共计：{_.sum(_.map(_.get(dataRes, 'tableHeadData'), 'sumCount')) || 0}
                </div>
                <Checkbox.Group options={statusOptions} value={filterStatusList} onChange={setFilterStatusList} />
            </div>
            <TextIconBtn text={'刷新'} icon={'shuaxin1'} onClick={refresh} />
            <TextIconBtn text={'导出'} icon={'xiazai2'} onClick={() => {
                downloadGetProgress('/releaseStory/panel/list/export?' + strParams(searchParams), '版本计划看板')
            }} />
            <Popover content={<VersionPlanConditionFilterPop search={setSearchExtraParams} initParams={searchExtraParams} close={() => setShowFilterPop(false)} />}
                open={showFilterPop} placement="bottomRight" trigger="click" destroyTooltipOnHide={true} onOpenChange={setShowFilterPop}>
                <Badge count={extraParamsCount} size="small" offset={[4, 0]} color={'#5477ff'}>
                    <TextIconBtn text={'过滤'} icon={'shaixuan'} className={clsx('filter-extra', { 'active': extraParamsCount !== 0 })} onClick={() => setShowFilterPop(x => !x)} />
                </Badge>
            </Popover>
        </div>
    }, [refresh, searchExtraParams, showFilterPop, extraParamsCount, currentId, downloadGetProgress, dataRes, filterStatusList, statusOptions])

    return <div className={'version-plan-dashboard-wrap'}>
        <Box className='flex-y x-card-singlegrid' title={'版本计划看板'} data={list} extra={extra} loading={loading} error={error}>
            <DataGrid className='x-datagrid' option={option} data={list} />
        </Box>
        {
            _.get(showDetailDialog, 'module') === 'req' &&
            <RequirementDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'dev' &&
            <DevListDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'issue' &&
            <IssueDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
    </div>
}

function combined(d, bind) {
    return _.reduce(d, (acc, x) => {
        return _.toNumber(acc) + _.toNumber((_.get(x, `${bind}`) || 0))
    }, 0)
}

export default VersionPlanDashboard;
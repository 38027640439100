import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination } from 'rootnet-ui'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { Box } from '../../common/commonComponent'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import ViewArea from '../../common/view/ViewArea'
import convertTableAlign from '../../common/view/convertTableAlign'
import findConvert from '../../common/view/findConvert'
import { pathSearchFor, strParams } from '../../../utils/publicFun'
import { API1 } from '../../../base/task'
import { TextIconBtn } from '../../common/TextIconBtn'
import './index.scss'
import AddPlanVersion from './controls/addPlanVersion'
import PlanVersionDetail from './controls/planVersionDetail'
import { useFuncCode } from '../../common/commonMethod'

const FUNC_CODE = '0802'
const ADD_FUNC_CODE = '080205' //新增权限
const ENTER_DETAIL_FIELD = 'ReleaseID'
const EDITABLE_FIELD = ['ReleaseID']

const PLAN_VERSION_LIST_URL = '/develop/release/list?stateList=T,C,L,R&versionType=1'
const VERSION_LIST_URL = '/develop/release/list?stateList=T,C,L,R&versionType=0'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentId, history } = props

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)

    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }

    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentId(o.id)
          history.replace({ pathname: '/planVersionMgt', search: strParams({ initId: o.id, firstOrder: 'baseLineList', }) })
        }}>{showValue}</div>
      </div>
    }

  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function PlanVersionMgt(props) {
  const { location, history } = props
  const { initId = null, firstOrder, secondOrder } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [currentId, setCurrentId] = useState(initId)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [sortConfig, setSortConfig] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [factor, setFactor] = useState()
  const { data: planversionData } = useGet(PLAN_VERSION_LIST_URL)
  const { data: versionData } = useGet(VERSION_LIST_URL)
  const { data: allUserRes } = useGet('/common/userinfo')
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes])
  const [isAddCode] = useFuncCode([ADD_FUNC_CODE])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionData)) return []
    return _.map(_.orderBy(versionData, 'issueDate', 'desc'), o => ({ text: o.releaseId, value: o.releaseId }))
  }, [versionData])

  const planversionOpt = useMemo(() => {
    if (_.isEmpty(planversionData)) return []
    return _.map(_.orderBy(planversionData, 'issueDate', 'desc'), o => ({ text: o.releaseId, value: o.releaseId }))
  }, [planversionData])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentId, history },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showList = useMemo(() => {
    let showList = dataGridList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, dataGridList])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      {
        isAddCode &&
        <TextIconBtn icon='tianjia' text='新增' onClick={() => setOpen(true)} />
      }
    </div>
  }, [isAddCode, exportViewList])

  return (
    <div className={'flex-y fill'}>
      <div className='plan-version-mgt fill flex-y' style={{ display: currentId ? 'none' : '' }}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Box title={'预规划项目管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <DataGrid option={options} data={showList} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
      </div>
      {
        currentId &&
        <PlanVersionDetail
          goBack={() => {
            setCurrentId(null)
            history.replace({ pathname: '/planVersionMgt' })
          }}
          {...{ history, firstOrder, secondOrder, currentId, setCurrentId, versionOpt, allUserRes, planversionOpt }}
        />
      }
      {
        open && <AddPlanVersion close={() => setOpen(false)} refresh={refreshList} {...{ versionOpt }} />
      }
    </div>
  )
}

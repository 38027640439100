import React, { useState, useEffect, useMemo } from 'react'
import { Switch, Route } from 'react-router-dom'
import _ from 'lodash'
import Option from './controls/Option'
import List from './controls/List'
import { useGet, useApi } from '../../../utils/hook'
import { useRender } from '../../../utils/customHook'
import Details from "./controls/Details"
import { historyParamsFor, strParams } from '../../../utils/publicFun'
import './ProjectCost.scss';

const urls = [
  '/common/system',
  '/common/userinfo',
  '/common/globalconst?globalConst=PresalesProjectStatus'
]

export default function ProjectCostManagement() {
  return <Switch>
    <Route path='/presalesprojectcost/:id' component={Details} />
    <Route component={ProjectCost} />
  </Switch>;
}

function initParamsFor(userAccount) {
  return {
    customer: '',
    projectName: '',
    principal: userAccount,
    projectStatusList: ['0', '1']
  }
}

function ProjectCost(props) {
  const { history } = props;
  const [setInit, Render] = useRender()
  const { data: optsData } = useGet(urls)
  const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
  const historyParams = useMemo(() => historyParamsFor(history.location.pathParams), [history])

  const [params, setParams] = useState(historyParams || initParamsFor(userAccount))
  const { data, doFetch, loading, error } = useApi();

  const opts = useMemo(() => {
    const [devClient, proManager, presalesProStatus] = optsData || []
    return [
      _.map(devClient, v => ({ value: v.projectId, text: ''.concat(v.custType, '-', v.projectName) })),
      _.map(proManager, v => ({ value: v.userAccount, text: v.userName, tag: `${v.userAccount}${v.userName}` })),
      _.map(presalesProStatus, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
    ]
  }, [optsData])
  const presalesProStatusOpt = opts[2]

  useEffect(() => {
    setInit('loading')
    const url = '/presales/presalesproject'
    doFetch(`${url}?${strParams(_.pick(params, ['customer', 'projectName', 'principal', 'projectStatusList']))}`).then(() => setInit('children'))
  }, [params, doFetch, setInit])

  return <div className="flex-y fill projectcost" >
    <Render>
      <Option search={search} userAccount={userAccount} setParams={setParams} initParams={params} opts={opts} />
      <List loading={loading} error={error} initParams={params} data={data} presalesProStatus={presalesProStatusOpt} />
    </Render>
  </div>

  function search(x) {
    setParams(p => {
      const res = _.assign({}, p, x);
      return !_.isEqual(res, p) ? res : p;
    });
  }
}

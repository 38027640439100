import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './ShowField.scss'
import {Card} from 'rootnet-ui';
import {Form, Input, Popover, Table} from 'antd';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { Radio } from 'antd';
import {TextIconBtn} from "../../../../common/TextIconBtn";
import _ from "lodash";
import AddFieldListPopover from "../addFieldListPopover/AddFieldListPopover";
import Icon from "../../../../../components/Icon";
import {FuncTableShowFieldContext} from "../../../../common/Context";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

function getColumns(props){
    const {onRadioChange, delItem} = props
    return [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '字段名称',
            dataIndex: 'fieldName',
            key: 'fieldName',
            width: 120,
        },
        {
            title: '别名',
            dataIndex: 'displayFieldName',
            key: 'displayFieldName',
            width: 120,
            editable: true,
        },
        {
            title: '视图查询展示',
            dataIndex: 'displayLevel',
            key: 'displayLevel',
            width: 220,
            render: (value, obj, index) => {
                return <Radio.Group value={value} onChange={e => onRadioChange(e.target.value, index)}>
                    <Radio value={'01'}>必选</Radio>
                    <Radio value={'02'}>可选</Radio>
                    <Radio value={'03'}>默认</Radio>
                </Radio.Group>
            }
        },
        {
            title: '操作',
            key: 'operation',
            width: 60,
            render: (value, obj) => <span className={'delete-text'} onClick={()=>delItem(obj.tableFieldName)}>删除</span>,
        },
    ];
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function ShowField(props) {
    const {fullScreen, setFullScreen,allFieldList,showFieldList, setShowFieldList} = props
    const [form] = Form.useForm()

    const showList = useMemo(()=>{
        if(_.isEmpty(allFieldList)) return []
        const allIdList = _.map(allFieldList, x => x.id)
        const filterShowList = _.filter(showFieldList, x => _.includes(allIdList, x.fieldId))
        return _.map(filterShowList, item => {
            const fieldItem = _.find(allFieldList, x => x.id === item.fieldId)
            item['fieldName'] = `${fieldItem.name}-${fieldItem.fieldName}`
            item['tableFieldName'] = `${fieldItem.tableName}.${fieldItem.fieldId}`
            return item
        })
    },[allFieldList,showFieldList])

    const notAddList = useMemo(()=>{
        const idList = _.map(showFieldList, x => x.fieldId)
        return _.filter(allFieldList, x => !_.includes(idList,x.id))
    },[showFieldList, allFieldList])

    const onRadioChange = useCallback((value, index)=>{
        setShowFieldList(oldList => {
            const tempList = _.clone(oldList)
            tempList[index]['displayLevel'] = value
            return tempList
        })
    },[setShowFieldList])

    const extra = useMemo(()=>{
        return <div className={'flex center-y'}>
            <Popover trigger="click" content={<AddFieldListPopover list={notAddList} setList={setShowFieldList} defaultParams={{displayLevel: '03'}}/>}>
                <TextIconBtn icon={'tianjia'} text={'添加字段'} disabled={_.isEmpty(notAddList)}/>
            </Popover>
            <Icon name={fullScreen === 'filterCondition'?'suoxiao':'fangda'} className={'full-screen-icon'} onClick={onClickFullScreenIcon}/>
        </div>

        function onClickFullScreenIcon(){
            setFullScreen(x => x ? null : 'showField')
        }
    },[notAddList,setShowFieldList,fullScreen, setFullScreen])

    const delItem = useCallback(delTableFieldName => {
        setShowFieldList(oldList => {
            const tempList = _.clone(oldList)
            return _.filter(tempList, x => x.tableFieldName !== delTableFieldName)
        })
    },[setShowFieldList])

    const handleSave = useCallback((row)=>{
        const index = _.findIndex(showFieldList, x => x.fieldId === row.fieldId)
        setShowFieldList(oldList => {
            const newList = _.map(oldList, (item, i) => {
                if(i !== index) return item
                return ({
                    ...item,
                    ...row
                })
            })
            return newList
        })
    },[showFieldList, setShowFieldList])

    const columns = useMemo(()=>{
        return _.map(getColumns({onRadioChange,delItem}), col => {
            if(!col.editable){
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                })
            }
        })
    },[onRadioChange,delItem, handleSave])

    // 可编辑单元格
    const EditableRow = useCallback(({index, ...restProps})=>{
        const currentIndex = _.findIndex(showFieldList, x => x.fieldId === restProps['data-row-key'])
        return <Form form={form} component={false}>
            <FuncTableShowFieldContext.Provider value={{form: form}}>
                <SortableItem index={currentIndex} {...restProps} />
            </FuncTableShowFieldContext.Provider>
        </Form>
    },[showFieldList, form])

    return <Card title={'展示字段'} className={`show-field-wrap ${fullScreen === 'showField' ?'full-screen':''}`} contentClass={'flex-y center show-field-card'}
                 extra={extra} style={{display: fullScreen && (fullScreen !== 'showField') ? 'none' : ''}}>
        <Table
            size={'small'}
            sticky={true}
            pagination={false}
            dataSource={showList}
            columns={columns}
            rowKey="fieldId"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: EditableRow,
                    cell: EditableCell
                },
            }}
        />
    </Card>

    function onSortEnd({ oldIndex, newIndex }){
        if (oldIndex !== newIndex) {
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setShowFieldList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }

    // function DraggableBodyRow({ className, style, ...restProps }){
    //     const index = showFieldList.findIndex(x => x.fieldId === restProps['data-row-key']);
    //     return <SortableItem index={index} {...restProps} />;
    // }
}

function EditableCell(props){
    const {title, editable, children, dataIndex, record, handleSave, must, ...restProps} = props
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const {form} = useContext(FuncTableShowFieldContext) || {}

    useEffect(()=>{
        if(editing){
            if(!_.isNil(inputRef.current)){
                inputRef.current.focus()
            }
        }
    },[editing])

    const toggleEdit = useCallback(()=>{
        setEditing(x => !x);
        form.setFieldsValue({ [dataIndex]:  record[dataIndex] });
    },[dataIndex, record, form])

    const save = () => {
        try{
            form.validateFields().then((changeObj)=>{
                const key = _.head(_.keys(changeObj))
                toggleEdit();
                if(changeObj[key] !== record[key]){
                    handleSave({ ...record, ...changeObj });
                }
                if(!_.isNil(inputRef.current)){
                    inputRef.current.blur()
                }
                setEditing(false)
            })
        } catch (errInfo) {
            console.error(errInfo);
        }
    }

    let childNode = children;

    if(editable){
        if(editing){
            let editNode = <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </Form.Item>
            )
        }else{
            childNode =(
                <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }
    return <td {...restProps}>{childNode}</td>;
}

export default ShowField;
import React, { useCallback, useContext, useState } from 'react'
import _ from 'lodash'
import ReviewMinutesMgtIssueList from '../../../reviewMinutesMgtIssueList'
import { ScheduleManagementContext } from '../../../../../../common/Context'
import ImportApiDialog from '../../../../../../common/ImportApiDialog'
import './index.scss'

const EXPORT_URL = '/conferencerecord/export/issueList'

const IMPORT_URL = '/conferencerecord/file/upload'
const TEMPLATE_URL = '/FieldDefinition/model/download?modelName=问题清单导入模板.xlsx'

export default function ReviewMinutesDetailContentList(props) {
  const { cid, allUserRes, issueList, setIssueList, isDetail, refresh } = props
  const [mode, setMode] = useState()
  const { downloadPostProgress } = useContext(ScheduleManagementContext)

  const addItem = useCallback(() => {
    setIssueList(x => {
      const lastItme = _.last(x)
      return _.concat([], x, [{
        id: `${_.get(lastItme, 'id', 0) + 1}`,
        operatorType: 'add',
      }])
    })
  }, [setIssueList])

  const clickExport = useCallback(() => {
    downloadPostProgress(`${EXPORT_URL}`, [cid], '问题清单')
  }, [cid, downloadPostProgress])

  return (
    <div className='review-minutes-detail-content-list'>
      <div className={'add-btn-wrap flex'}>
        {
          !isDetail &&
          <span className={'add-text'} onClick={addItem}>添加问题清单</span>
        }
        <span className={'add-text'} onClick={() => setMode('import')}>导入</span>
        <span className={'add-text'} onClick={clickExport}>导出</span>
      </div>
      <ReviewMinutesMgtIssueList
        editable={!isDetail}
        deletable={!isDetail}
        {...{ allUserRes, issueList, setIssueList, updateEditRow: () => { } }}
      />
      {
        mode === 'import' &&
        <ImportApiDialog
          title='问题清单导入'
          parameter={{ iid: cid }}
          importUrl={IMPORT_URL}
          template={TEMPLATE_URL}
          refresh={refresh}
          close={() => setMode(null)}
          isExecutionList={false}
        />
      }
    </div>
  )
}

import React, { useState, useCallback } from 'react'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Messager } from 'rootnet-ui'

export default function UseCaseReminder(props) {
  const { close } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)
    doFetch('', {})
      .then(() => {
        setIsDisabled(false)
        close()
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisabled, doFetch, close])

  return (
    <Dialog
      header='提示'
      confirm={confirm}
      cancel={close}
      style={{
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      将给当前未执行用例的测试负责人发送消息提醒，请确认，且勿重复点击！
    </Dialog>
  )
}

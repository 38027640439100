import React, { Fragment } from 'react';
import { ShowItem } from "../../../common/Details";
import { Card } from 'rootnet-ui';
import _ from 'lodash';
import './CardList.scss';

function CardList(props) {
    const { data, loading, error, title, options, className, style, insertRender } = props;
    return <Card
        className={`card-List x ${className}`}
        style={style}
        data={data}
        loading={loading}
        error={error}
        title={title}
    >
        <div className="card-List-con">
            {
                _.map(options, (it, ind) => {
                    return <Fragment key={ind}>
                        <div className={`con ${it.className}`}>
                            {
                                _.map(it.list, (it2, ind2) => {
                                    return <ShowItem
                                        data={data}
                                        {...it2}
                                        key={ind2}
                                    />
                                })
                            } 
                        </div>
                    </Fragment>                   
                })
            }
            
        </div>
        {
            insertRender && insertRender
        }
    </Card>
}

export default CardList;
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash'
import cls from 'clsx'
import { Popover, Tag } from 'antd'
import { isNil } from 'rootnet-core/format'
import { ErrorTooltip } from 'rootnet-edit/lib/base/Tooltip';
import { REQUIRED_ERROR, useControlledError } from './useControlledError'
import DepartmentSelectPop from './departmentSelectPop';
import { useGet } from 'rootnet-biz/lib/hooks';
import convertOptions from '../../../common/ConvertOptions';
import { strParams } from '../../../../utils/publicFun';
import './index.scss'
export default function DepartmentSelect(props) {
  const { required, onError, hiddenUserList, style, placeholder, disabled, multiple, allowClear, mustList, defaultOpen, mode } = props
  const [stateValue, setStateValue] = useState()
  const [visible, setVisible] = useState(defaultOpen)
  const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);
  const [owner, setOwner] = useState(null)
  const [isInited, setIsInited] = useState(false)
  const { data: allDeptRes, doFetch: getDept } = useGet()
  const { doFetch } = useGet()

  useEffect(() => {
    doFetch(`/common/uacUserRole`).then(res => {
      const isAll = _.find(res, o => o.posId === 'ALL')
      getDept(`/common/department?${strParams({ activeFlag: 'Y', isAll: isNil(isAll) ? '1' : '0' })}`)
    })
  }, [getDept, doFetch])

  const isAdd = useMemo(() => mode === 'add', [mode])

  const isControlled = useMemo(() => {
    return !_.isNil(props.value) || _.isNil(props.defaultValue)
  }, [props.value, props.defaultValue])

  const initData = useCallback((data) => {
    if (multiple) {
      let handleData
      if (_.isArray(data)) {
        handleData = data
      } else {
        handleData = data ? _.split(data, ',') : null
      }
      setStateValue(handleData)
    } else {
      setStateValue(data)
    }
    setIsInited(true)
  }, [multiple])

  useEffect(() => {
    if (isControlled) {
      initData(props.value)
    }
  }, [isControlled, initData, props.value])

  useEffect(() => {
    if (isInited && !isControlled) return
    if (_.isNil(props.value)) return
    initData(props.value)
  }, [props.value, initData, isInited, isControlled])

  useEffect(() => {
    if (isInited && isControlled) return
    if (_.isNil(props.defaultValue)) return
    initData(props.defaultValue)
  }, [props.defaultValue, initData, isInited, isControlled])

  useEffect(() => {
    const err = (required && _.isEmpty(stateValue)) ? REQUIRED_ERROR : null;
    if (err !== error) {
      setStateError(err);
      if (props.onError)
        props.onError(err);
    }
  }, [stateValue, required, onError, props, error, setStateError])

  const show_error = useMemo(() => {
    return error && errorVisible
  }, [error, errorVisible])

  const handleFocus = useCallback(() => {
    if (props.onFocus) {
      props.onFocus()
    }
  }, [props])

  const handleBlur = useCallback(() => {
    setUserActive(true)
    if (props.onBlur) {
      props.onBlur()
    }
  }, [props, setUserActive])

  const onRemove = useCallback((removeItem) => {
    setStateValue(oldList => {
      const newList = multiple ? _.filter(oldList, x => x !== removeItem) : null
      if (props.onChange) {
        props.onChange(newList)
      }
      return newList
    })
  }, [multiple, props])

  const onSelectConfirm = useCallback(newValue => {
    setStateValue(newValue)
    if (props.onChange) {
      props.onChange(newValue)
    }
  }, [props])

  const onOpenChange = useCallback(visible => {
    if (!visible) {
      handleBlur()
    }
    setVisible(disabled ? false : visible)
  }, [disabled, handleBlur])

  return <Popover
    zIndex={9998}
    trigger="click"
    open={visible}
    placement="bottom"
    destroyTooltipOnHide={true}
    onOpenChange={onOpenChange}
    overlayClassName={'department-select-popover'}
    content={
      <DepartmentSelectPop
        defaultValue={stateValue}
        {...{ onSelectConfirm, setVisible, hiddenUserList, multiple, allowClear, mustList, allDeptRes, isAdd }}
      />
    }
  >
    <div
      className={cls('department-select flex center-y', { error: show_error, disabled })}
      ref={setOwner}
      onFocus={handleFocus}
      style={{ ...style }}
    >
      {
        (multiple && !_.isEmpty(stateValue)) &&
        _.map(stateValue, item => {
          return <Tag
            key={item}
            color={'#f0f0f0'}
            onClose={() => onRemove(item)}
            closable={!_.includes(mustList, item)}
          >
            {convertOptions(item, allDeptRes, 'departMentName', 'departMentId')}
          </Tag>
        })
      }
      {
        (!multiple && !isNil(stateValue)) &&
        <Tag
          color={'#f0f0f0'}
          closable={allowClear}
          onClose={() => onRemove(stateValue)}
        >
          {convertOptions(stateValue, allDeptRes, 'departMentName', 'departMentId')}
        </Tag>
      }
      {
        _.isEmpty(stateValue) &&
        <div className={'department-select-placeholder'}>{placeholder}</div>
      }
    </div>
    {
      show_error &&
      <ErrorTooltip error={error} target={owner} />
    }
  </Popover>
}

DepartmentSelect.defaultProps = {
  placeholder: '请选择',
  multiple: false,
  disabled: false,
  allowClear: true,
  marginLeft: false,
  defaultOpen: false,
}

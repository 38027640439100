import React, {useCallback, useEffect, useState} from 'react';
import {Button, Dialog, Messager} from 'rootnet-ui'
import _ from "lodash";
import './ViewAdvancedQuery.scss'
import usePost from "rootnet-biz/es/hooks/usePost";
import CheckConditionGroup from "../../view/checkConditionGroup/CheckConditionGroup";

function ViewAdvancedQuery(props) {
    const {close, allColumns, funcCode, afterUpdatedRefresh} = props
    const [factor, setFactor] = useState()
    const {doFetch: getDetail} = usePost()
    const {doFetch: submitPost} = usePost()

    useEffect(()=>{
        if(_.isNil(funcCode)) return
        getDetail('/UserSetting/getAdvancedQuery', {menuCode: funcCode}).then(res => {
            setFactor(JSON.parse(res) || null)
        })
    },[getDetail,funcCode])

    const submit = useCallback(()=>{
        const submitParams = {
            menuCode: funcCode,
            factor: factor ? JSON.stringify(factor) : '',
        }
        submitPost('/UserSetting/advancedQuerySettings', submitParams).then(()=>{
            Messager.show('修改成功', {icon: 'success'})
            if(afterUpdatedRefresh){
                afterUpdatedRefresh()
            }
            close()
        }).catch(err => Messager.show(err.Message, {icon: 'error'}))
    },[submitPost, funcCode, factor, afterUpdatedRefresh, close])

    const onReset = useCallback(()=>{
        setFactor(null)
    },[])

    return <Dialog header={'高级查询'} className={'view-advanced-query flex-y'} contentClassName={'flex-y'} footerVisible={false} cancel={close}>
        <div className="content-wrap flex-y">
            <CheckConditionGroup allColumns={allColumns} editData={factor} setEditData={setFactor}/>
        </div>
        <div className="mock-footer flex center-y">
            <div className={'reset-text can-enter-text'} onClick={onReset}>重置</div>
            <div className="btn-group flex">
                <Button normal onClick={close}>取消</Button>
                <Button primary onClick={submit}>确认</Button>
            </div>
        </div>
    </Dialog>
}

export default ViewAdvancedQuery;
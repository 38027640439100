import React, {useState} from 'react';
import './MyCenter.scss'
import _ from 'lodash'
import {uniqKeyFor} from "../../project_share/utils/utils";
import AttendanceMgt from "./AttendanceMgt/AttendanceMgt";

const tabs = [
    {
        title: '考勤机照片管理'
    },
]

function MyCenter(props) {
    const [activeIndex, setActiveIndex] = useState(0)
    return <div className='my-center flex fill'>
        <div className="row-tab flex-y">
            {
                _.map(tabs, (x, i) =>
                    <div className={`row-tab-item ${i === activeIndex?'active':''}`} onClick={()=>setActiveIndex(i)} key={uniqKeyFor()}>{x.title}</div>
                )
            }
        </div>
        {
            activeIndex === 0 && <AttendanceMgt/>
        }
    </div>
}

export default MyCenter;
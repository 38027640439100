import _ from 'lodash';

class CacheData {
    _cache = new Map();
    get(key, defaultValue = undefined) {
        if (!this._cache.has(key) && defaultValue !== undefined)
            this._cache.set(key, defaultValue)
        return this._cache.get(key);
    }
    set(key, value) {
        if (value === undefined)
            this._cache.delete(key);
        else
            this._cache.set(key, value);
    }
    updateTo(key, value) {
        const v = this.get(key, {});
        _.assign(v, value);
        return true;
    }
    updateFrom(key, value) {
        if (_.isObject(value)) {
            const v = this.get(key);
            if (v) {
                _.assign(value, v);
                return true;
            }
        }
        return false;
    }
    clear() {
        this._cache.clear();
    }
}

class Cache {
    _cache = new CacheData();

    get Cache() {
        return this._cache;
    }
}

const cache = (new Cache()).Cache;
export { cache as Cache };
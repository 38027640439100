import React, {useMemo} from 'react';
import {Select} from "rootnet-edit";
import _ from "lodash";

function DisplaySelect(props) {
    const {options = []} = props

    const displayOptions = useMemo(()=>{
        return _.filter(options, x => _.get(x,'displayFlag') !== 'N')
    },[options])

    return <Select {...props} options={displayOptions}/>
}

export default DisplaySelect;
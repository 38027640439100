import React, { useState, useEffect, useMemo, useCallback, useRef, useReducer } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Tooltip } from 'antd'
import { Dialog, Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, DatePicker, Display } from 'rootnet-edit'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import VersionSelect from '../../../../requirementMgt/applyJoinVersionDialog/VersionSelect'
import { Icon } from '../../../../../components'
import RichTextEditor from '../../../../common/richTextEditor/TinyEditor'
import RequirementAddUploadArea from '../../../../requirementMgt/requirementAddDialog/requirementAddUploadArea/RequirementAddUploadArea'
import { isNil } from '../../../../appraise/components/method'
import ReviewMinutesMgtIssueList from '../reviewMinutesMgtIssueList'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import './index.scss'
import { timeDifference } from '../../common/commonFun'
import convertOptions from '../../../../common/ConvertOptions'
import DisplaySelect from '../../../../common/displaySelect/DisplaySelect'
import ContentTemplateSelect from '../../../../common/contentTemplateSelect'
import useContentTemplateNum from '../../../../common/hooks/useContentTemplateNum'
import EditTipLog from '../../../../common/contentTemplateSelect/editTipLog'

const FUNC_CODE = '2420'
const ADD_URL = '/conferencerecord/add'

const HFormInput = (props) => <FormInput labelWidth={90} componentWidth={200} horizontal {...props} />

const ONLY_ID_URL = '/test_case/productGetOnlyId'
const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=reviewCategory', //评审类别
  'common/globalconst?globalConst=AuditPassFlag', //评审状态
  '/UserSetting/getUniversalInterfaces?code=id&codeName=storyId&tableName=Story', // 关联需求
  '/UserSetting/getUniversalInterfaces?code=id&codeName=projectName&tableName=UserProject&filter=status&filterParams=0,1,2,3,4,5,6'//项目名称
]

const initExpand = {
  content: true,
  inventory: true,
  todoList: true,
  materials: true,
}

const initFormData = {
  sOurPerformer: [],
  relatedRequirements: [],
}

export default function ReviewMinutesAdd(props) {
  const { close, refreshList, initForm = null } = props
  const [formData, setFormData] = useState(_.isNil(initForm) ? initFormData : initForm)
  const [formError, setFormError] = useState()
  const [expand, setExpand] = useState(initExpand)
  const [issueList, setIssueList] = useState([])
  const [addLoading, setAddLoading] = useState(false)
  const richEditorRef = useRef()
  const [countFile, updateFile] = useReducer((x) => x + 1, 60000)
  const { data: id, doFetch: getOnlyId } = useGet()
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch } = usePost()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { reviewCategory } = useMemo(() => formData || {}, [formData])

  const [openItem, setOpenItem] = useState(null)
  const [templateItem, setTemplateItem] = useState()
  const addContentTemplateNum = useContentTemplateNum()
  const [fileCount, setFileCount] = useState(0)

  const isReqExplain = useMemo(() => reviewCategory === '02', [reviewCategory])

  const [reviewCategoryOpt, auditPassFlagOptions, reqOpt, devProjectOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const getNewOnlyId = useCallback(() => {
    getOnlyId(ONLY_ID_URL)
      .then(id => {
        updateFile()
        setFormData(x => _.assign({}, x, { id }))
      })
  }, [getOnlyId])

  useEffect(() => {
    getNewOnlyId()
  }, [getNewOnlyId])

  const issueListRequired = useMemo(() => {
    const requiredList = ['proposer', 'problemLevel', 'problemDescription', 'problemConfirmed', 'verificationClosed', 'issueHandler', 'scheduledCompletionDate', 'verifier']
    return _.every(issueList, item => {
      return _.every(requiredList, field => !isNil(_.get(item, field)))
    })
  }, [issueList])

  const canSubmit = useMemo(() => {
    return _.every(formError, v => _.isNil(v))
  }, [formError])

  const addNewIssueList = useCallback(() => {
    setIssueList(x => {
      const lastItme = _.last(x)
      return _.concat([], x, [{ id: _.get(lastItme, 'id', 0) + 1, operatorType: 'add' }])
    })
  }, [])

  const changeForm = useCallback((newObj, bind) => {
    setFormData(oldObj => {
      let cloneObj = { ...oldObj, ...newObj }
      if (_.get(cloneObj, 'dOccurTimeBegin') && _.get(cloneObj, 'dOccurTimeEnd')) {
        if (_.includes(['dOccurTimeBegin', 'dOccurTimeEnd'], bind)) {
          if (_.get(cloneObj, 'dOccurTimeBegin') > _.get(cloneObj, 'dOccurTimeEnd')) {
            if (bind === 'dOccurTimeBegin') {
              cloneObj['dOccurTimeBegin'] = null
              Messager.show('开始时间不能晚于结束时间')
            } else {
              cloneObj['dOccurTimeEnd'] = null
              Messager.show('结束时间不能早于开始时间')
            }
          }
        }
      }
      if (bind === 'sConferenceType') {
        const oldValue = _.get(oldObj, 'sConferenceType')
        const newValue = _.get(newObj, 'sConferenceType')
        const needAuditType = ['DS', 'REQ', 'TC']
        if (_.includes(needAuditType, oldValue) === !_.includes(needAuditType, newValue)) {
          cloneObj['auditPassFlag'] = null
          cloneObj['auditor'] = null
          cloneObj['storyId'] = null
        }
        if (_.includes(['S', 'DS', 'REQ', 'TC'], cloneObj['sConferenceType'])) {
          cloneObj['scustomer'] = 'NBGJ'
        }
      }
      // if (bind === 'reviewCategory') {
      //   const reviewCategory = _.get(cloneObj, 'reviewCategory')
      //   if (reviewCategory !== '02') {
      //     updateCount()
      //     cloneObj['relatedRequirements'] = null
      //   }
      // }
      return cloneObj
    })
  }, [])

  const onFormError = useCallback((newValue) => {
    setFormError(newValue)
  }, [])

  const submit = useCallback(() => {
    if (!canSubmit) return
    if (!issueListRequired) return Messager.show('请填写【问题清单】中的必填项')
    const reviewLibrary = _.get(formData, 'reviewLibrary')
    if (isReqExplain && (fileCount <= 0 && isNil(reviewLibrary))) return Messager.show('请上传附件或填写评审资料配置库目录')
    if (addLoading) return
    setAddLoading(true)

    const issuesList = _.map(issueList, o => _.assign({}, { meetingId: _.get(formData, 'id') }, _.omit(o, ['id']),))
    const startTime = _.get(formData, 'dOccurTimeBegin')
    const endTime = _.get(formData, 'dOccurTimeEnd')
    const reviewDuration = (!isNil(startTime) && !isNil(endTime)) ? timeDifference(startTime, endTime) : null
    const postParams = _.assign({}, formData, { issuesList }, {
      dOccurTimeBegin: _.get(formData, 'dOccurTime') + ' ' + moment(_.get(formData, 'dOccurTimeBegin')).format('HH:mm') + ":00",
      dOccurTimeEnd: _.get(formData, 'dOccurTime') + ' ' + moment(_.get(formData, 'dOccurTimeEnd')).format('HH:mm') + ":00",
      sRecord: richEditorRef.current.getContent(),
      sOurPerformer: _.join(_.get(formData, 'sOurPerformer'), '^'),
      absentPersonnel: _.join(_.get(formData, 'absentPersonnel'), '^'),
      reviewDuration,
      sConferenceType: 'AM',
      //下面参数 邮件翻译
      sConferenceTypeName: '评审会议',
      reviewCategoryName: convertOptions(_.get(formData, 'reviewCategory'), reviewCategoryOpt),
      auditPassFlagName: convertOptions(_.get(formData, 'auditPassFlag'), auditPassFlagOptions),
      sOurPerformerName: _.join(_.map(_.get(formData, 'sOurPerformer'), v => convertOptions(v, allUserRes, 'userName', 'userAccount')), '，'),
      projectNameName: convertOptions(_.get(formData, 'projectName'), devProjectOpt),
    })

    doFetch(ADD_URL, postParams)
      .then(() => {
        addContentTemplateNum(templateItem)
        setAddLoading(false)
        refreshList()
        close()
      })
      .catch(err => {
        setAddLoading(false)
        refreshList()
        close()
        Messager.show(err.Message, { icon: 'error' })
      })
  }, [canSubmit, issueListRequired, addLoading, formData, refreshList, doFetch, close, issueList, reviewCategoryOpt, auditPassFlagOptions, allUserRes, devProjectOpt, templateItem, addContentTemplateNum, fileCount, isReqExplain])

  const templateChange = useCallback((v, item, flag) => {
    if (flag) setOpenItem(item)
    else {
      setTemplateItem(item)
      if (richEditorRef.current) {
        richEditorRef.current.setContent(v)
      }
    }
  }, [])

  useEffect(() => {
    const relatedRequirements = _.get(formData, 'relatedRequirements')
    const projectName = _.get(formData, 'projectName')

    setFormError(x => _.assign({}, x,
      isReqExplain && { relatedRequirements: isNil(relatedRequirements) ? '必填项！' : null, projectName: null },
      !isReqExplain && { relatedRequirements: null, projectName: isNil(projectName) ? '必填项！' : null },
    ))
  }, [isReqExplain, formData])

  useEffect(() => {
    const reviewLibrary = _.get(formData, 'reviewLibrary')
    setFormError(x => _.assign({}, x,
      isReqExplain && { reviewLibrary: null },
      !isReqExplain && { reviewLibrary: isNil(reviewLibrary) ? '必填项！' : null },
    ))
  }, [formData, fileCount, isReqExplain])

  return (
    <Dialog
      header={"新增研发评审记录"}
      className={'review-minutes-add-dialog'}
      confirm={submit}
      cancel={close}
      confirmButtonDisabled={!canSubmit}
      headerVisible={false}
    >
      <div className='review-minutes-add flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title">
            新增会议纪要
          </div>
          <div className="mock-right-header flex center-y">
            <ContentTemplateSelect
              type={'2'}
              isAdd={true}
              objectType={_.get(formData, 'reviewCategory')}
              onChange={templateChange}
            />
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <div className='mock-detail-main-panel'>
          <Form value={formData} onChange={changeForm} error={formError} onError={onFormError}>
            <HFormInput label={'标题'} bind={'sSubject'} required componentWidth={812} />
            <HFormInput required label={'评审类别'} bind={'reviewCategory'} search component={DisplaySelect} options={reviewCategoryOpt} />
            <HFormInput label={'评审日期'} bind={'dOccurTime'} required component={DatePicker}
              bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
            <HFormInput label={'评审时间'} bind={'dOccurTimeBegin'} required component={DatePicker} mode='time'
              showSeconds={false} componentWidth={86} placeholder={'开始'}
              bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
            至
            <HFormInput label={''} bind={'dOccurTimeEnd'} required component={DatePicker} mode='time'
              showSeconds={false} componentWidth={86} placeholder={'结束'}
              bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
            <HFormInput label={'评审时长'} bind={''} component={Display} convert={() => {
              const startTime = _.get(formData, 'dOccurTimeBegin')
              const endTime = _.get(formData, 'dOccurTimeEnd')
              if (!isNil(startTime) && !isNil(endTime)) return timeDifference(startTime, endTime)
              return '-'
            }} />
            <HFormInput label={'会议主持'} bind={'sCaption'} />
            <HFormInput label={'会议地点'} bind={'sOccurPlace'} />
            <HFormInput label={'评审结论'} bind={'auditPassFlag'} required search component={DisplaySelect} options={auditPassFlagOptions} />
            <Tooltip title={'评审结论不允许修改，请谨慎选择！'}>
              <span>
                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
              </span>
            </Tooltip>
            <HFormInput labelWidth={76} label={'评审组长'} bind={'auditor'} required component={UserSelect} />
            <HFormInput clear={isReqExplain} required={!isReqExplain} label={'项目名称'} bind={'projectName'} search component={Select} options={devProjectOpt} />
            <Tooltip title={'仅允许单个项目，如未填写项目，则取需求所在的项目，如需求在多个项目下，则默认取第一个。'}>
              <span>
                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
              </span>
            </Tooltip>
            <div />
            <HFormInput required={isReqExplain} label={'关联需求'} bind={'relatedRequirements'} component={VersionSelect} options={reqOpt} componentWidth={506} multiple={true} optionFilterProp='label' isControlled={true} value={_.get(formData, 'relatedRequirements', null)} />
            <HFormInput label={'评审参与人'} bind={'sOurPerformer'} required component={UserSelect} multiple componentWidth={812} />
            <HFormInput label={'未出席人员'} bind={'absentPersonnel'} component={UserSelect} multiple />


            <div className="area-wrap desc-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: expand['content'] ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(o => _.assign({}, o, { content: !o['content'] }))} />
                评审内容
              </div>
              <div className="rich-text-area" style={{ display: expand['content'] ? 'block' : 'none' }}>
                <RichTextEditor ref={richEditorRef} height={300} />
              </div>
            </div>

            <div className="area-wrap desc-wrap">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: expand['inventory'] ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(o => _.assign({}, o, { inventory: !o['inventory'] }))} />
                <span style={{ color: 'red' }}>问题清单</span>
              </div>
              <div className="todo-list-wrap" style={{ display: expand['inventory'] ? 'block' : 'none' }}>
                {
                  !_.isEmpty(issueList) &&
                  <ReviewMinutesMgtIssueList
                    editable={true}
                    deletable={true}
                    {...{ issueList, setIssueList, allUserRes }}
                  />

                }
                <span className={'add-todo-list-text'} onClick={addNewIssueList}>
                  添加问题清单
                </span>
              </div>
            </div>

            <div className="area-wrap desc-wrap" >
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: expand['materials'] ? 'none' : 'rotate(-90deg)' }} onClick={() => setExpand(o => _.assign({}, o, { materials: !o['materials'] }))} />
                评审材料
              </div>
              <div className="doc-area" style={{ display: expand['materials'] ? 'block' : 'none' }} key={countFile}>
                <HFormInput required={!isReqExplain} bind='reviewLibrary' label='评审资料配置库目录' labelWidth={150} componentWidth={812} />

                <RequirementAddUploadArea id={id} allUserRes={allUserRes} funcCode={FUNC_CODE} setFileCount={setFileCount} isUpload={!isReqExplain} />

              </div>
            </div>
          </Form>
        </div>
      </div>
      {
        !_.isNil(openItem) &&
        <EditTipLog
          item={openItem}
          close={() => setOpenItem(null)}
          onChange={(v, item) => {
            setTemplateItem(item)
            if (richEditorRef.current) {
              richEditorRef.current.setContent(v)
            }
          }}
        />
      }
    </Dialog>
  )
}
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { copyText } from 'rootnet-core/clipboard'
import { useGet } from 'rootnet-biz/es/hooks'
import { Messager, Button } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import convertOptions from '../../../../common/ConvertOptions'
import { TRACKING_TYPE_COLOR } from '../../trackingColor'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import './index.scss'
import WorkFlowChangePop from '../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { strParams } from '../../../../../utils/publicFun'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import ConcernedIcon from '../../../../common/concernedIcon'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

const FUNC_CODE = '26'

export default function TrackingDetailHeader(props) {
  const { id, editFormData, isDetail, setEditFormData, bugType, refreshDetail, refreshViewList, updateDynamic, devRefresh, allUserRes, setIsClose } = props
  const [showCopyPop, setShowCopyPop] = useState(false)
  const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: workFlowInfo, doFetch: getStatus } = useGet()

  const status = useMemo(() => {
    return _.get(workFlowInfo, 'factorId')
  }, [workFlowInfo])

  useEffect(() => {
    setIsClose(status === '172096195508834304')
  }, [setIsClose, status])

  const refreshStatus = useCallback(() => {
    if (_.isNil(id)) return
    const params = {
      businessType: 'tracer',
      businessId: id
    }
    getStatus('/WorkflowBusiness/getWorkflowBusiness?' + strParams(params))
  }, [getStatus, id])

  useEffect(() => {
    refreshStatus()
  }, [refreshStatus])

  const [workFlowStatusOptions] = useMemo(() => {
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const workflowColor = useMemo(() => {
    return _.get(_.find(workFlowStatusOptions, x => x.value === status), 'color') || '#000'
  }, [workFlowStatusOptions, status])

  const copy = useCallback((value) => {
    copyText(value)
    setShowCopyPop(false)
    Messager.show('数据已复制到剪切板', { icon: 'success' })
  }, [])

  const CopyArea = useCallback(() => {
    const detailUrl = window.location.host + `/#/trackingManagementMgt?initId=` + _.get(editFormData, 'snowId')
    return <div className={'flex tracking-title-copy-popover center-y'}>
      {/*<Button onClick={() => copy(_.get(editFormData, 'tracerTitle'))} normal>复制标题</Button>*/}
      {/*<Button onClick={() => copy(_.get(editFormData, 'tracerId'))} normal>复制研发任务ID</Button>*/}
      <Button onClick={() => copy(`${_.get(editFormData, 'tracerId')}\n${_.get(editFormData, 'tracerTitle')}`)} normal>复制研发任务ID+标题</Button>
      <Button onClick={() => copy(detailUrl)} normal>复制研发任务的URL</Button>
    </div>
  }, [copy, editFormData])

  return (
    <div className='tracking-detail-header flex'>
      <div className="header-left">
        <div className="tracking-id-wrap flex center-y">
          {
            !_.isNil(bugType) &&
            <div className="type-tag flex center-y" style={{ backgroundColor: TRACKING_TYPE_COLOR[bugType] }}>{bugType}</div>
          }
          <div className="tracking-id">{_.get(editFormData, 'tracerId')}</div>
          <ConcernedIcon
            type='TRACE'
            mode='detail'
            referenceId={editFormData?.id}
          />
        </div>
        {
          isDetail &&
          <div className="tracking-title-wrap flex center-y">
            <div className="tracking-title">
              <Tooltip title={_.get(editFormData, 'tracerTitle')}>
                {_.get(editFormData, 'tracerTitle')}
              </Tooltip>
            </div>
            <Popover content={<CopyArea />} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
              <Icon name={'fuzhi'} className={'copy-icon'} />
            </Popover>
          </div>
        }
        {
          !isDetail && <FormInput horizontal bind={'tracerTitle'} label={'标题'} required value={_.get(editFormData, 'tracerTitle')}
            onChange={v => setEditFormData(x => ({ ...x, tracerTitle: v }))} />
        }
      </div>
      <div className="header-right flex">
        <div className="header-right-item flex center-y">
          <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{ color: '#165DFF' }} />
          <div className="header-right-info flex-y">
            {
              isDetail &&
              <div className="header-right-value">
                <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes, 'userName', 'userAccount')}</div>
              </div>
            }
            {
              !isDetail &&
              <FormInput horizontal required value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                onChange={v => setEditFormData(x => ({ ...x, currentUser: v }))} />
            }
            <div className="header-right-field">
              处理人
            </div>
          </div>
        </div>
        <Popover destroyTooltipOnHide={true}
          content={<WorkFlowChangePop businessId={editFormData?.snowId} close={() => setShowWorkFlowPop(null)} refreshList={() => {
            refreshStatus()
            refreshViewList()
            refreshDetail(id)
            updateDynamic()
            devRefresh()
          }} funcCode={FUNC_CODE} businessType={'tracer'} />}
          onOpenChange={visible => {
            if (!visible) {
              setShowWorkFlowPop(null)
            }
          }}
          trigger={'click'}
          placement="bottomRight"
          open={showWorkFlowPop}
        >
          <div className="header-right-item flex center-y status-wrap-item" onClick={() => setShowWorkFlowPop(true)}>
            <Icon name={'renling'} className={'header-right-icon'} style={{ color: workflowColor }} />
            <div className="header-right-info flex-y">
              <div className="header-right-value">
                <div className={'status-tag flex center'} style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
                  {convertOptions(status, workFlowStatusOptions) || '-'}
                </div>
              </div>
              <div className="header-right-field">
                当前状态
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

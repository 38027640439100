import React from "react";
import { Button } from 'rootnet-ui';
import { Icon } from './Icon'
import './IconButton.scss'

function IconButton(props) {
    const { className, children, icon, ...other } = props;
    return <Button {...other} className={className ? `${className} icon-button` : "icon-button"}>
        <Icon name={icon} />
        {children}
    </Button>
}
IconButton.defaultProps = {
    icon: 'zengjia',
}
export default IconButton;






























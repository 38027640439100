import React, { useState, useMemo, useCallback, useRef, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Box, TextIconBtn } from '../../../../common/commonComponent'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import ViewArea from '../../../../common/view/ViewArea'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import './index.scss'
import IssueDetailDialog from '../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import { useFuncCode } from '../../../../common/commonMethod'
import WorkFlowChangePop from '../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { isNil } from 'rootnet-core/format'
import { CustomizeFormInput } from '../../../../common/customizeOptions/CustomizeOptions'
import { ScheduleManagementContext } from '../../../../common/Context'
import ViewQueryDialog from "../../../../common/view/viewQueryDialog/ViewQueryDialog";
import DelVersionItem from '../devProjectScope/devProjectScopeAllInfo/components/delVersionItem'
import AssociatedVersion from '../devProjectScope/devProjectScopeAllInfo/components/associatedVersion'
import ImportVersion from '../devProjectScope/devProjectScopeAllInfo/components/importVersion'
import CopyMessage from '../../../../versionMgt/versionMsg/components/versionDetail/versionRequirementMgt/copyMessage'


const FUNC_CODE = '3611'
const PRIMARY_KEY = 'IssueId'
const ENTER_DETAIL_FIELD = 'ShortDesc'
// const DEAL_WITH_LOGO = 'dealType' //处理标识  'dealType',

const EDITABLE_FIELD = ['ShortDesc', 'qaCheckResult', 'EffectGrade', 'ISSUESTYPE', 'PersonCharge', 'Principal', 'Impact',
  'WhyAndHow', 'OptStep', 'LogList', 'communicateNote', 'detail', 'testCheckAnalysis', 'testMeasures', 'testEvolve',
  'rootAnalysis', 'rootMeasures', 'rootEvolve', 'currentUser']
const RICH_TEXT_FIELD = ['Impact', 'WhyAndHow', 'OptStep', 'LogList', 'communicateNote', 'detail', 'testCheckAnalysis', 'testMeasures', 'testEvolve', 'rootAnalysis', 'rootMeasures', 'rootEvolve']


const PROBLEM_STATE_COLOR = {
  '01': '#3AA3FD',
  '02': '#F76A00',
  '03': '#F76A00',
  '04': '#00A950',
  '05': '#C9CDD4',
  '06': '#00A950',
  '07': '#C9CDD4',
  '08': '#F76A00',
  // '09': '#0000FF',
  '10': '#C9CDD4',
}

const ORDER_SEVERITY_COLOR = {
  '01': '#F60421',// 致命
  '02': '#F76A00',// 严重
  '03': '#165DFF',// 重要问题
  '04': '#93C36B',// 一般问题
  '05': '#97AFD0',// 改善建议
}

const DEAL_TYPE_COLOR = {
  '0': '#93C36B',
  '1': '#FF8C00',
  '2': 'red',
  '3': 'red',
}

const SPECIAL_FIELD_CONVERT = {
  'IssueStatus': (value, showValue) => <div className={'status-field flex center'} style={{ border: `1px solid ${PROBLEM_STATE_COLOR[value]}`, padding: '1px 12px 2px', color: PROBLEM_STATE_COLOR[value], borderRadius: 4 }}>
    {showValue}
  </div>,
  'EffectGrade': (value, showValue) => <div style={{ color: ORDER_SEVERITY_COLOR[value] }}>{showValue}</div>,
  'dealType': (value, showValue) => <div className='status-deal-type'><span style={{ backgroundColor: DEAL_TYPE_COLOR[value] }}></span>{showValue || '-'}</div>,
  'positioningDuration': (value, showValue) => <div className='status-deal-type'><span style={{ backgroundColor: DEAL_TYPE_COLOR[value] }}></span>{showValue || '-'}</div>
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setShowDetail, workFlowId, setWorkFlowId, refreshList, editInfo,
    setEditInfo, currentTableValueRef, updateShowList, setEditOldValue, projectMap } = props
  const projectId = _.get(projectMap, 'projectId')
  const { editId = null, editField = null } = editInfo || {}

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
    }
    if (tableField === 'View_issue_custSystems.userProjectIds') {
      if (isNil(v)) {
        o._selectable = false
      } else {
        const valueList = _.split(v, ',')
        o._selectable = _.includes(valueList, projectId)
      }
    }
    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={'19'} businessType={'issue'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => {
          currentTableValueRef.current = handleValue
          setEditOldValue(handleValue)
        }
        }
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }
        }
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (_.includes(RICH_TEXT_FIELD, fieldItem.fieldId)) {
      return <Tooltip title={<div className='issue-rich-text-val' dangerouslySetInnerHTML={{ __html: showValue }} />}><div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div></Tooltip>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setShowDetail(o.id)
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }
  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'ProductionIssue.ShortDesc'), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function DevProjectIssue(props) {
  const { versionFilterSql, projectMap, issueRelateFactorViewSql, canRelate, isRelevance, isDisassociation, versionCheck, selectedVersion, isVersionState, searchAll, allList, isAll, isNoPlanVersion, versionList, versionCanRelate, projectId, refreshAllList } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [sortConfig, setSortConfig] = useState()
  const [factor, setFactor] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [showDetail, setShowDetail] = useState()
  const [isFuncCodeApply, isFuncCodeQaCheck] = useFuncCode(['1902', '1910'])
  const [workFlowId, setWorkFlowId] = useState()
  // 表格编辑
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const [editOldValue, setEditOldValue] = useState()
  const { doFetch: editTable } = usePost()
  const [showRelationDialog, setShowRelationDialog] = useState()
  const { doFetch: relateIssuePost } = usePost()
  const [selectItems, setSelectItems] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    if (_.isEmpty(list)) return
    _.forEach(list, o => o.type = 'ISSUE')
  }, [list])

  const appendObj = useMemo(() => {
    return { 'factorViewSql': versionFilterSql }
  }, [versionFilterSql])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    if (JSON.stringify(updateValue) === JSON.stringify(editOldValue) || _.isNil(updateValue)) {
      setEditInfo(null)
      currentTableValueRef.current = null
      return
    }
    let postParams = {
      // defectId: primaryId,
      id: editInfo['editId']
    }
    if (editInfo['fieldId'] === 'ShortDesc' && (_.isNil(_.trim(updateValue)) || _.trim(updateValue) === '')) {
      setEditInfo(null)
      currentTableValueRef.current = null
      return
    }
    postParams['fieldName'] = editInfo['fieldId']
    postParams['fieldValue'] = updateValue
    if (editInfo['fieldId'] === 'currentUser') {
      if (isNil(updateValue)) {
        setEditInfo(null)
        currentTableValueRef.current = null
        return
      }
      const currentUserParams = {
        businessId: _.get(editInfo, 'editId'),
        businessType: 'issue',
        funcCode: FUNC_CODE,
        currentUser: updateValue,
        title: _.get(editInfo, 'title')
      }
      editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    } else {
      editTable('/issue/detailSingle', postParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    }
    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editOldValue, editTable])

  const afterRefresh = useCallback(() => {
    setSelectItems([])
  }, [])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, appendObj, getFactor: setFactor, afterRefresh })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: {
      setShowDetail, workFlowId, setWorkFlowId, refreshList, editInfo, setEditInfo,
      currentTableValueRef, updateShowList, setEditOldValue, projectMap
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
      ...appendObj,
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, 'ISSUE')
  }, [fieldList, currentViewId, factor, downloadPostProgress, appendObj])

  const onRelate = useCallback((list) => {
    if (_.isEmpty(list)) return
    const postParams = {
      id: _.get(projectMap, 'currentId'),
      userProjectId: _.get(projectMap, 'projectId'),
      issueIds: _.map(list, 'id')
    }
    relateIssuePost('/issue/relationIssueDevProject', postParams).then(res => {
      Messager.show('关联成功', { icon: 'success' });
      refreshAllList()
      refreshList()
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
    })
  }, [projectMap, relateIssuePost, refreshList, refreshAllList])

  const cancelRelate = useCallback(() => {
    if (_.isEmpty(selectItems)) return Messager.show('请选择后再进行操作')
    const postParams = {
      id: _.get(projectMap, 'currentId'),
      userProjectId: _.get(projectMap, 'projectId'),
      issueIds: _.map(selectItems, 'id')
    }
    relateIssuePost('/issue/delIssueDevProject', postParams).then(res => {
      refreshList()
      Messager.show('取消关联成功', { icon: 'success' });
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
    })
  }, [selectItems, projectMap, refreshList, relateIssuePost])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        canRelate && isRelevance && !versionCheck && !isNoPlanVersion &&
        <TextIconBtn text='项目关联ISSUE' onClick={() => setShowRelationDialog('issue')} />
      }
      {
        canRelate && isRelevance && !versionCheck && isNoPlanVersion &&
        <TextIconBtn icon='' text='导入版本' onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setShowRelationDialog('versionPlan')
        }} />
      }
      {
        !isAll && canRelate && isDisassociation && !versionCheck &&
        <TextIconBtn text={'移除项目'} onClick={() => {
          cancelRelate()
        }} />
      }
      {
        canRelate && versionCheck && isVersionState &&
        <TextIconBtn text={'导入版本'} onClick={() => {
          setShowRelationDialog('joinVersion')
        }} />
      }
      {
        canRelate && versionCheck && isVersionState &&
        <TextIconBtn text={'移除版本'} onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setShowRelationDialog('removeVersion')
        }} />
      }
      {
        versionCanRelate && isRelevance && versionCheck &&
        <TextIconBtn text={'复制至其他版本'} onClick={() => {
          if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
          setShowRelationDialog('copyValues')
        }} />
      }
      <TextIconBtn text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList, cancelRelate, canRelate, isRelevance, isDisassociation, versionCheck, isVersionState, isAll, isNoPlanVersion, selectItems, versionCanRelate])

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  const dataManipulation = useCallback((par, flag = true) => {
    if (isDisabled) return
    setIsDisabled(true)
    editTable('/ReleaseStory/addReleaseStoryList', par)
      .then(() => {
        setSelectItems([])
        refreshList()
        setIsDisabled(false)
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
        setIsDisabled(false)
      })
  }, [isDisabled, editTable, refreshList])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  return (
    <div className='dev-project-issue'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'ISSUE管理'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error} extra={extra}>
        <DataGrid option={options} data={showList} selection={selectItems} onSelectionChange={setSelectItems} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        !_.isNil(showDetail) &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={refreshList}
          currentInfo={{ id: showDetail }} showChildList={[]}
          {...{ isFuncCodeApply, isFuncCodeQaCheck }}
        />
      }
      {
        showRelationDialog === 'issue' &&
        <ViewQueryDialog close={() => setShowRelationDialog(null)} initValue={[]}
          outerSetItem={onRelate} funcCode={'0005'} bizField={'ProductionIssue.IssueId'} bizName={'ISSUE'} multiple={true}
          initItemValue={[]} appendObj={{ factorViewSql: issueRelateFactorViewSql }}
        />
      }
      {
        showRelationDialog === 'joinVersion' &&
        <AssociatedVersion
          searchAll={searchAll}
          defaultData={versionCheck ? _.filter(allList, o => _.includes(_.split(o.releaseIds, ','), selectedVersion)) : allList}
          close={() => setShowRelationDialog(null)}
          refreshList={() => {
            refreshList()
            refreshAllList()
          }}
          {...{ selectedVersion }}
        />
      }
      {
        showRelationDialog === 'removeVersion' &&
        <DelVersionItem
          releaseid={selectedVersion}
          close={() => setShowRelationDialog(null)}
          selection={_.map(selectItems, o => _.assign({}, o, { taskType: 'ISSUE' }))}
          refreshList={() => {
            refreshList()
            refreshAllList()
          }}
          {...{ setSelection: setSelectItems }}
        />
      }
      {
        showRelationDialog === 'versionPlan' &&
        <ImportVersion
          {...{ selection: _.map(selectItems, o => _.assign({}, o, { taskType: 'ISSUE' })), setSelection: setSelectItems, versionList }}
          close={() => setShowRelationDialog(null)}
          refreshList={() => {
            refreshList()
            refreshAllList()
          }}
        />
      }
      {
        showRelationDialog === 'copyValues' &&
        <CopyMessage
          {...{ projectId, dataManipulation }}
          selectedRows={selectItems}
          actionType={showRelationDialog}
          currentId={selectedVersion}
          close={() => setShowRelationDialog(null)}
          isDisabled={true}
          isDisplay={false}
          changeData={{}}
        />
      }
    </div>
  )
}

import React, { useContext, useMemo } from 'react'
import _ from 'lodash'
import { DataGrid } from 'rootnet-ui'
import { Icon } from '../../../../components'
import { FormContext } from '../../../common/Context'
import { OperationList } from '../../../common/OperationList'
import { TextIconBtn } from '../../../common/TextIconBtn'

const getColumns = ({ setDisassociation, isDetail, setShowDetail }) => {
  return _.compact([
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    { header: 'ID', bind: 'defectId', tooltip: true, sortable: true, width: 120, },
    {
      header: '标题', bind: 'title', tooltip: true, sortable: true, width: 220, convert: (v, o) => {
        return <div className='detail-field-text' onClick={() => {
          setShowDetail({ type: 'defect', id: o.id })
        }}>
          {v}
        </div>
      }
    },
    { header: '测试计划', bind: 'planName', tooltip: true, sortable: true, width: 100 },
    { header: '严重程度', bind: 'severityName', convert: v => v, tooltip: true, sortable: true, width: 85 },
    { header: '优先级', bind: 'priorityName', convert: v => v, tooltip: true, sortable: true, width: 70 },
    { header: '状态', bind: 'statusName', convert: v => v, tooltip: true, sortable: true, width: 70 },
    { header: '处理人', bind: 'current_owners', convert: v => v, tooltip: true, sortable: true, width: 70 },
    { header: '创建人', bind: 'createUserName', convert: v => v, tooltip: true, sortable: true, width: 70 },
    { header: '创建时间', bind: 'createTime', convert: v => v, tooltip: true, sortable: true, width: 150 },
    !isDetail && { header: '操作', width: 50, algin: 'center', convert: renderOperation },
  ])

  function renderOperation(_v, o, _i, d) {
    const operateOption = [
      {
        text: <Icon name='quxiaoguanlian' />,
        onClick: () => setDisassociation({ type: 'defect', data: _.filter(d, item => o.defectId !== item.defectId) })
      }
    ]
    return <OperationList options={operateOption} />;
  }
}

const getTableOption = (columns, isDetail) => (_.assign({
  columns,
  autoFill: true,
  virtualized: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
}, !isDetail && { fixedRight: 1 }));

export default function Defect() {
  const { defectData, setDisassociation, setRelation, isDetail, setShowDetail } = useContext(FormContext)

  const option = useMemo(() => getTableOption(getColumns({ setDisassociation, isDetail, setShowDetail }), isDetail), [isDetail, setDisassociation, setShowDetail])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
      {
        !isDetail && <div>
          <TextIconBtn
            style={{ margin: '8px 0', display: 'inline-block' }}
            icon='sousuo1'
            text='关联缺陷'
            onClick={() => setRelation('defect')}
          />
        </div>
      }

      <DataGrid className='executiveHistory' data={defectData} option={option} />
    </div>
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { dateFormat } from 'rootnet-core/dateFormat'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Messager } from 'rootnet-ui'
import VersionForm from '../versionForm'
import './index.scss'

const ADD_URL = '/releaseinfo/add'

export default function AddVersion(props) {
  const { close, refresh = ()=>{}, defaultParams = {}, projectDisable = false } = props
  const [params, setParams] = useState({ state: 'T', issuedate: dateFormat('YYYYMMDD', new Date()) + '000000', ...defaultParams })
  const [error, setError] = useState()
  const [releaseContentVoList, setReleaseContentVoList] = useState([])
  const [releaseMilestoneList, setReleaseMilestoneList] = useState([])
  const { doFetch } = usePost()
  const { data: versionData } = useGet('/develop/release/list?stateList=T,C,L,R&versionType=0')
  const { data: allUserRes } = useGet('/common/userinfo')

  const versionOpt = useMemo(() => {
    if (_.isEmpty(versionData)) return []
    return _.map(_.orderBy(versionData, 'issueDate', 'desc'), o => ({ text: o.releaseId, value: o.releaseId }))
  }, [versionData])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (params.defaultReleaseid === params.releaseid) return Messager.show('请修改版本编号', { icon: 'error' })
    doFetch(ADD_URL, _.assign(params, { releaseContentVoList, releaseMilestoneList }))
      .then(() => {
        close()
        refresh()
        Messager.show('添加成功', { icon: 'success' })
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [check, close, doFetch, params, refresh, releaseContentVoList, releaseMilestoneList])

  return (
    <Dialog
      className='add-version-log'
      header='新增'
      confirm={confirm}
      cancel={close}
    >
      <VersionForm {...{ isDetail: false, params, setParams, error, setError, mode: 'add', releaseContentVoList, releaseMilestoneList, setReleaseContentVoList, setReleaseMilestoneList, allUserRes, versionOpt, projectDisable }} />
    </Dialog>
  )
}

export const TRACKING_STATUS_COLOR = {
  'T0': '#108EE9', //新建
  'T1': '#F59A23',//开发人员修改中
  'T2': '#70B603',//开发测试中
  'T3': '#70B603',//待指派测试
  'T4': '#70B603',//产品测试中
  'T5': '#70B603',//测试完成有错误
  'T6': '#7F7F7F',//完成
  'T7': '#70B603',//开发测试通过
  'T8': '#7F7F7F',//暂缓
  'T9': '#7F7F7F',//搁置
}

export const TRACKING_TYPE_COLOR = {
  'BUG': '#F33F2B', //红色
  '新功能': '#108EE9', //蓝色
  '性能': '#70B603', //绿色
  '交易所': '#F59A23', //橙色
}
import {useCallback} from "react";
import _ from "lodash";

export default function useGetFactor({params, allColumns}){
    return useCallback((replaceParams = null) => {
        if(_.isNil(allColumns)) return
        const formParam = _.omit(params,['pageNum','pageSize'])
        const conditionList = _.map({...formParam,...replaceParams}, (v,k) => {
            const currentItem = _.find(allColumns, x => `${x.tableName}.${x.fieldId}` === k)
            return ({field: k, relation:_.get(currentItem, 'formQueryType') || '=',value: v || ''})
        })
        return JSON.stringify({
            conditionRelation: 'and',
            conditionList: conditionList
        })
    },[params,allColumns])
}
import React, { useState, useMemo, useCallback, useEffect, useReducer, useRef } from 'react'
import _ from 'lodash'
import { Tooltip, Tabs, Popover, Image } from "antd";
import { Button, Messager, DataGrid } from 'rootnet-ui'
import { useGet, usePost } from "rootnet-biz/es/hooks";
import { dateFormat, toDate } from 'rootnet-core/dateFormat';
import { Icon } from '../../../../../components';
import TodoListDetailHeader from '../todoListDetailHeader';
import TodoListForm from '../todoListForm';
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions';
import ChangeRecord from '../../../../common/ChangeRecord';
import ContrastLog from '../contrastLog';
import convertOptions from '../../../../common/ConvertOptions';
import TodoListDetailDialog from '..';
import './index.scss'
import DelMessage from '../../../../../components/DelMessage';
import TurnParent from './turnParent';
import RelevanceTodoList from './relevanceTodoList';
import { isNil } from 'rootnet-core/format';
import IsOnlyLog from './isOnlyLog';
import gd from '../../../../../base/global';
import authorityJudge from '../../authorityJudge';
import { useFuncCode } from "../../../../common/commonMethod";
import FollowMattersDetail from "../../../../common/ChangeRecord/components/followMattersDetail";
import ConcernedIcon from '../../../../common/concernedIcon';

const { TabPane } = Tabs

const DEL_URL = '/todolist/delete'
const EDIT_URL = '/todolist/update'
const DETAIL_URL = '/todolist/getTodolistrecord'

function getColumns(props) {
  const { allUserRes, statusOpt, setSonMode, setMode, setEditFormData, formData, setCurrentInfoSon } = props
  return [//align: '',
    {
      header: '主题', bind: 'subject', width: 150, tooltip: true, convert: (v, o) => {
        return <div className={'enter-detail-field'} onClick={() => {
          setMode('detail')
          setEditFormData(formData)
          setSonMode({ mode: 'detail' })
          setCurrentInfoSon({ mode: 'detail', id: o.id })
        }}>
          {v}
        </div>
      }
    },
    {
      header: '状态', bind: 'status', width: 100, tooltip: true, convert: (v, o) => {
        const backgroundColor = _.get(_.find(statusOpt, o => o.value === v), 'color')
        return <div className={'status-field flex center'} style={{ backgroundColor, color: 'white', padding: '0px 8px', borderRadius: 4, width: 64 }}>{convertOptions(v, statusOpt)}</div>
      }
    },

    //
    {
      header: '时间要求', bind: 'requireDate', width: 90, tooltip: true, convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
    { header: '负责人', bind: 'principal', width: 100, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
  ]
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

export default function TodoListDetailContent(props) {
  const { refreshViewList = () => { }, currentInfo = {}, close = () => { }, switchCurrentItem, showChildList, currentIndex, setFocusId = () => { }, upDate, todoListName = '跟进事项' } = props
  const { mode: initMode = 'detail', id: initId } = currentInfo
  const [id, setId] = useState(initId ? initId : null)
  const [mode, setMode] = useState(initMode)
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [editFormError, setEditFormError] = useState()
  const [switchDisabled, setSwitchDisabled] = useState(false)
  const [contrast, setContrast] = useState(false)
  const [sonMode, setSonMode] = useState(null)
  const [activeKey, setActiveKey] = useState('info')
  const [currentInfoSon, setCurrentInfoSon] = useState({ mode: initMode })
  const [actionType, setActionType] = useState()
  const [count, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
  const { data: allUserRes } = useGet('/common/userinfo')
  const { data: statusRes } = useGet('/common/globalconst?globalConst=FollowUpStatus')
  const { data: accessData, doFetch: accessFetch } = useGet()
  const { doFetch: getDetail } = useGet()
  const { doFetch: submitPost } = usePost()

  const [canMove, setCanMove] = useState(false);
  const [htmlWidth, setHtmlWidth] = useState(610);
  const prevXRef = useRef(null);
  const panelRef = useRef(null)

  const [isDelCode] = useFuncCode(['2301'])
  const [open, setOpen] = useState(false)
  const [onlyId, setOnlyId] = useState(null)
  const { doFetch: getOnlyId } = useGet()

  const noteRef = useRef()
  const [imageVisible, setImageVisible] = useState(false)
  const [imageSrc, setImageSrc] = useState()
  const [disabled, setDisabled] = useState(false)

  const previewImage = useCallback(e => {
    if (e.target.tagName === 'IMG') {
      setImageSrc(e.target.src)
      setImageVisible(true)
    }
  }, [])

  useEffect(() => {
    if (open) getOnlyId('/test_case/productGetOnlyId').then(res => setOnlyId(res))
  }, [getOnlyId, open])

  const isDetail = useMemo(() => mode === 'detail', [mode])
  const isEdit = useMemo(() => {
    if (_.isEmpty(accessData) || _.isEmpty(allUserRes)) return false
    return authorityJudge({ accessData, allUserRes, ..._.pick(editFormData, ['principal', 'recUser', 'status']) })
  }, [accessData, allUserRes, editFormData])
  const isDisplay = useMemo(() => !_.isEmpty(editFormData?.todolistrecordSonList), [editFormData])

  const statusOpt = useMemo(() => convertGlobalConstOptions(statusRes), [statusRes])

  const switchMode = useCallback((isChange) => {
    setEditFormData(formData)
    isChange && setMode(x => x === 'detail' ? 'edit' : 'detail')
  }, [formData])

  const option = useMemo(() => {
    return getOptions(getColumns({ allUserRes, statusOpt, setSonMode, setMode, setEditFormData, formData, setCurrentInfoSon }))
  }, [allUserRes, statusOpt, formData])

  const refreshDetail = useCallback((id) => {
    if (_.isNil(id)) return
    setSwitchDisabled(true)
    getDetail(`${DETAIL_URL}?id=${id}`)
      .then(res => {
        const data = _.head(res)
        let detail = { ...data }
        setFormData(detail)
        setSwitchDisabled(false)
        setId(data.id)
        setTimeout(() => {
          if (noteRef.current) {
            noteRef.current.setContent(_.get(_.head(res), 'note') || '')
          }
        }, 500)
      })
      .catch(err => {
        setSwitchDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
    updateDynamic()
  }, [getDetail, setId])

  useEffect(() => {
    if (_.isNil(id)) return
    refreshDetail(id)
  }, [id, refreshDetail, upDate])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) {
      setId(id)
    }
  }, [currentInfo, setId])

  useEffect(() => {
    setEditFormData({ ...formData })
  }, [formData])


  const submit = useCallback((par = {}) => {
    if (disabled) return
    if (JSON.stringify({ ...editFormData, ...par }) === JSON.stringify(formData) && noteRef.current.getContent() === _.get(formData, 'note')) {
      switchMode()
      return Messager.show('无变更')
    }

    if (noteRef?.current.loading) {
      return Messager.show("图片上传中，请稍后保存")
    }

    if (editFormData?.isDepend === '0') {
      const isUnfinished = _.some(editFormData?.todolistrecordSonList, o => o.status === '1')
      if (isUnfinished) return Messager.show('存在未完成的子任务', { icon: 'error' })
    }
    setDisabled(true)
    submitPost(EDIT_URL, { ..._.omit(editFormData, ['source', 'recUser', 'currentUser']), todolistOrOther: 0, ...par, note: noteRef.current.getContent() }).then(() => {
      const currentUserParams = {
        businessId: _.get(editFormData, 'id'),
        businessType: 'todo',
        funcCode: '23',
        currentUser: _.get(editFormData, 'currentUser'),
        title: _.get(editFormData, 'subject')
      }
      submitPost('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch(err => {
        setDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
      function afterRefresh() {
        Messager.show('成功', { icon: 'success' })
        setDisabled(false)
        setFocusId(editFormData?.id)
        refreshDetail(editFormData?.id)
        switchMode(_.isEmpty(par))
        if (refreshViewList) {
          refreshViewList()
        }
      }
    }).catch(err => {
      setDisabled(false)
      Messager.show(err._message, { icon: 'error' })
    })
  }, [editFormData, formData, refreshDetail, refreshViewList, setFocusId, submitPost, switchMode, disabled])

  const handClick = useCallback(() => {
    if (_.includes(['2', '3'], editFormData?.status) && editFormData?.status !== formData?.status) return setContrast(true)
    else return submit()
  }, [editFormData, formData, submit])

  const canSubmit = useMemo(() => {
    if (!_.get(editFormData, 'subject')) return false
    if (!_.get(editFormData, 'currentUser')) return false
    return !_.some(_.values(editFormError), x => x)
  }, [editFormData, editFormError])

  const currentIndexSon = useMemo(() => {
    const currentId = _.get(currentInfoSon, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(editFormData?.todolistrecordSonList, x => _.get(x, 'id') === currentId)
  }, [currentInfoSon, editFormData])

  const switchCurrentItemSon = useCallback((switchDirection = 'next') => {
    setCurrentInfoSon(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? editFormData?.todolistrecordSonList[currentIndexSon + 1].id : editFormData?.todolistrecordSonList[currentIndexSon - 1].id
      cloneObj['id'] = newId
      // setFocusId(newId)
      return cloneObj
    })
  }, [editFormData, currentIndexSon])

  useEffect(() => {
    accessFetch(`/common/uacUserRole`)
  }, [accessFetch])

  return (//todolistrecordSonList
    <>
      <div className={'todo-list-detail-content flex-y'}>
        <div className="mock-dialog-header flex">
          <div className="dialog-title">
            {todoListName}
          </div>
          <div className="mock-right-header flex center-y">
            <ConcernedIcon
              top={0}
              type='TODOLIST'
              mode='detail'
              referenceId={editFormData?.id}
            />
            {
              isEdit &&
              <Tooltip title={isDetail ? '进入编辑' : '退出编辑'}>
                <span>
                  <Icon name={'bianji2'} className={`header-edit-icon ${isDetail ? '' : 'edit-active-icon'}`} onClick={() => {
                    switchMode(true)
                    setEditFormData(formData)
                  }} />
                </span>
              </Tooltip>
            }
            <MoreOperations {...{ isPid: isNil(editFormData?.pid), isDisplay, setActionType, isDelCode, user: editFormData?.recUser, loginUser: convertOptions(gd.User.operator_id, allUserRes, 'userName', 'userAccount') }} />
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <TodoListDetailHeader id={_.get(editFormData, 'id')} {...{
          editFormData, isDetail, setEditFormData, allUserRes,
          statusOpt, refreshDetail, refreshViewList, updateDynamic
        }} />
        <div className="todo-list-detail-main-panel flex"
          onMouseMove={handleMove} ref={panelRef}
          onMouseLeave={() => setCanMove(false)}
          onMouseUp={() => setCanMove(false)}
        >
          <Tabs className='todo-list-tabs' style={{ flexWrap: 'wrap', width: htmlWidth }} activeKey={activeKey} onChange={setActiveKey}>
            <TabPane tab='详细信息' key={'info'}>
              <div className='todo-list-form'>
                <TodoListForm {...{ isDetail, editFormData, setEditFormData, editFormError, setEditFormError, allUserRes, isDisplay, noteRef, previewImage }} />
              </div>
            </TabPane>
            {
              isNil(editFormData?.pid) &&
              <TabPane tab='子任务' key={'subtask'}>
                <div style={{ flex: 1, overflow: 'hidden' }}>
                  {
                    isDisplay &&
                    <DataGrid data={editFormData?.todolistrecordSonList} option={option} />
                  }
                  <div className='add-subtask' onClick={() => {
                    if (_.isEmpty(editFormData?.todolistrecordSonList)) setActionType('isOnly')
                    else setOpen(true)
                  }}><Icon name='tianjia' />添加子任务</div>
                </div>
              </TabPane>
            }
          </Tabs>
          <div className='todo-list-trag' onMouseDown={() => setCanMove(true)}><Icon name='yidongshu' /></div>
          <div className={'dynamic-wrap'} style={{ marginLeft: 8, flex: 1 }} key={count}>
            {
              !_.isNil(id) && <ChangeRecord name={todoListName} funcCode={'23'} referenceId={id} id={_.get(editFormData, 'subject')} title={_.get(editFormData, 'subject')} linkUrl={`/todolist?initId=${id}`} followBtn={false} commentWidth={'90%'} />
            }
          </div>
        </div>
        <div className="mock-footer flex center-y">
          <div />
          {
            _.isEmpty(showChildList) ? <div /> :
              <div className="footer-switch flex center-y">
                <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                  onClick={() => {
                    if (switchDisabled) return
                    if (!_.includes([-1, 0], currentIndex)) {
                      switchCurrentItem('previous')
                    }
                  }}>
                  <Icon name='xiangqian' />&nbsp;上一个
                </div>
                <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                  onClick={() => {
                    if (switchDisabled) return
                    if (!(currentIndex === showChildList?.length - 1)) {
                      switchCurrentItem('next')
                    }
                  }}>
                  下一个&nbsp;<Icon name='xianghou' />
                </div>
                <div className="list-num">
                  {currentIndex + 1}/{showChildList?.length}
                </div>
              </div>
          }
          <div className="btn-group flex">
            {
              isDetail &&
              <Button normal onClick={close}>关闭</Button>
            }
            {
              !isDetail &&
              <Button normal onClick={() => {
                switchMode(true)
                setEditFormData(formData)
              }}>取消</Button>
            }
            {
              !isDetail &&
              <Button primary onClick={handClick} disable={!canSubmit}>确认</Button>
            }
          </div>
        </div>
      </div>
      {
        _.get(sonMode, 'mode') === 'detail' &&
        <TodoListDetailDialog close={() => { setSonMode(null) }} {...{
          refreshViewList: () => { refreshViewList(); refreshDetail(editFormData?.id) },
          currentInfo: currentInfoSon,
          switchCurrentItem: switchCurrentItemSon,
          showChildList: editFormData?.todolistrecordSonList,
          currentIndex: currentIndexSon,
          setFocusId,
          setOpen,
          upDate
        }} />
      }
      {
        actionType === 'isOnly' && <IsOnlyLog close={() => setActionType(null)} {...{ setOpen, submit }} />
      }
      {
        actionType === 'delete' &&
        <DelMessage
          method='post'
          refresh={() => { refreshViewList(); close() }}
          close={() => setActionType(null)}
          params={{}}
          url={`${DEL_URL}?id=${id}`}
        >确定删除吗？</DelMessage>
      }
      {
        actionType === 'turnParent' && <TurnParent submit={submit} close={() => setActionType(null)} />
      }
      {
        _.includes(['turnSon', 'exchangeParent'], actionType) &&
        <RelevanceTodoList allUserRes={allUserRes} submit={submit} close={() => setActionType(null)} />
      }
      {
        contrast &&
        <ContrastLog
          {...{
            submit,
            statusOpt,
            close: () => setContrast(false),
            oldVal: formData?.status,
            newVal: editFormData?.status,
            params: {
              funcCode: "23",
              cardUrl: `/todolist?initId=${id}`,
              referenceId: id,
              title: `【${id}】${_.get(editFormData, 'subject')}`,
            }
          }}
        />
      }
      {
        (open && !_.isNil(onlyId)) &&
        <FollowMattersDetail
          id={onlyId}
          funcCode={'23'}
          mode={'add'}
          close={() => { setOpen(false); setOnlyId(null) }}
          refresh={() => {
            refreshDetail(editFormData?.id)
            if (refreshViewList) {
              refreshViewList()
            }
          }}
          linkUrl={`/todolist?initId=${onlyId}`}
          pid={currentInfo?.id}
        />
      }
      <Image
        style={{ display: 'none' }}
        src={imageSrc}
        preview={{
          visible: imageVisible,
          src: imageSrc,
          onVisibleChange: visible => {
            setImageVisible(visible)
          }
        }}
      />
    </>
  )

  function handleMove(e) {
    if (!canMove) return;
    e.preventDefault();
    e.stopPropagation();
    const prevX = prevXRef.current;
    prevXRef.current = e.clientX
    if (prevX === null) return null;
    const offset = e.clientX - prevX;
    setHtmlWidth(x => {
      if (x + offset < 400) {
        prevXRef.current = prevX
        return 400
      } else if ((x + offset) > 620) {
        prevXRef.current = prevX
        return x
      } else {
        return x + offset
      }
    });
  }

}

function MoreOperations({ isPid, isDisplay, setActionType, isDelCode, user, loginUser }) {
  const [clicked, setClicked] = useState(false)

  const onClick = useCallback((type) => {
    setActionType(type)
    setClicked(false)
  }, [setActionType])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      {(isPid && !isDisplay) && <div onClick={() => onClick('turnSon')}>转化为子任务</div>}
      {!isPid && <div onClick={() => onClick('exchangeParent')}>更换父级任务</div>}
      {!isPid && <div onClick={() => onClick('turnParent')}>变更为父级任务</div>}
      {(isDelCode || user === loginUser) && <div onClick={() => onClick('delete')}>删除</div>}
    </div>
  }, [isDelCode, isDisplay, isPid, loginUser, onClick, user])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <Tooltip title={'更多'}>
        <span>
          <Icon name={'gengduo'} className={`header-edit-icon`} onClick={() => setClicked(x => !x)} />
        </span>
      </Tooltip>
    </Popover>
  )
}


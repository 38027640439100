import React, {useCallback, useEffect, useState} from 'react';
import './TagsArea.scss'
import { Tag, Popover } from 'antd';
import _ from 'lodash'
import {PlusOutlined} from "@ant-design/icons";
import TagsPopover from "./tagsPopover/TagsPopover";
import {useGet} from "rootnet-biz/es/hooks";
import {strParams} from "../../../utils/publicFun";
import DelMessage from "../../../components/DelMessage";
import {useFuncCode} from "../../common/commonMethod";

function TagsArea(props) {
    const {id, funcCode} = props
    const [showTagsPop, setShowTagsPop] = useState(false)
    const {data: tagsList, doFetch: getTagsList} = useGet()
    const [delId, setDelId] = useState()

    const refreshTags = useCallback(()=>{
        const params = {
            referenceId: id,
            funcCode
        }
        getTagsList('/tag/mapping/select?'+strParams(params))
    },[getTagsList, id, funcCode])

    useEffect(()=>{
        refreshTags()
    },[refreshTags])

    const onTagClose = useCallback((e, tagInfo)=>{
        e.preventDefault()
        setDelId(tagInfo.id)
    },[])

    const [createTagAuth, createRelationAuth, delRelationAuth] = useFuncCode(['880401','880402','880403'])

    return <div className={'tags-area flex'}>
        {
            _.map(tagsList, x => {
                return <Tag closable={delRelationAuth} color={x.tagColor} onClose={e => onTagClose(e, x)} key={x.tagName}>{x.tagName}</Tag>
            })
        }
        {
            createRelationAuth &&
            <Popover content={<TagsPopover close={()=>setShowTagsPop(false)} {...{id, funcCode, tagsList, refreshTags, createTagAuth}}/>}
                     open={showTagsPop} placement="bottom" onOpenChange={setShowTagsPop} trigger={'click'} destroyTooltipOnHide={true}>
                <Tag className="add-tag-btn cursor-pointer">
                    <PlusOutlined /> 添加标签
                </Tag>
            </Popover>
        }
        {
            !_.isNil(delId) &&
            <DelMessage close={()=>setDelId(null)} refresh={refreshTags} method={'post'} url={'/tag/mapping/del?id='+delId}>
                确定删除此标签？
            </DelMessage>
        }
    </div>
}

export default TagsArea;
import React, { useState } from 'react'
import { Tabs } from 'antd'
import { strParams } from '../../../../../../utils/publicFun'
import ChangeRecord from '../../../../../common/ChangeRecord'
import './index.scss'
import ListChange from './listChange'

export default function ChangeHistory(props) {
  const { snowId, currentId, history, firstOrder, secondOrder, allUserRes, workflowOpt, inventoryDynamic } = props
  const [activeTab, setActiveTab] = useState(secondOrder)

  return (
    <div className='plan-version-change-history'>
      <Tabs
        centered
        activeKey={activeTab}
        onChange={v => {
          history.replace({ pathname: '/planVersionMgt', search: strParams({ initId: currentId, firstOrder, secondOrder: v, }) })
          setActiveTab(v)
        }}
        items={[
          {
            key: 'listChange',
            label: `清单变动`,
            children: <React.Fragment key={inventoryDynamic}>
              <ListChange {...{ currentId, allUserRes, workflowOpt }} />
            </React.Fragment>
          },
          {
            key: 'essentialInformation',
            label: `版本基本信息变更`,
            children: <ChangeRecord
              name='预规划项目'
              funcCode={'0802'}
              referenceId={snowId}
              id={currentId}
              title={currentId}
              linkUrl={`/planVersionMgt?initId=${currentId}`}
              associatedField='content'
              associatedFieldName=''
            />
          }
        ]}
      />
    </div>
  )
}

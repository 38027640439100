import React, {useCallback, useState} from 'react';
import './ReproduceBug.scss'
import Icon from "../../../../../components/Icon";
import _ from "lodash";
import TinyEditor from "../../../../common/richTextEditor/TinyEditor";
import {DatePicker, Display, Form, FormInput, Input, RadioGroup} from "rootnet-edit";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import convertOptions from "../../../../common/ConvertOptions";
import {dateFormat, toDate} from "rootnet-core/dateFormat";

const yesNoOptions =[
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props}/>

function ReproduceBug(props) {
    const {isDetail, editFormData,setEditFormData, allUserRes, optStepRef, logListRef} = props
    const [testExpand, setTestExpand] = useState(true)
    const [optStepExpand, setOptStepExpand] = useState(true)
    const [logListExpand, setLogListExpand] = useState(true)

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[setEditFormData])

    return <div className={'reproduce-bug'}>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: testExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setTestExpand(v => !v)}/>
                测试信息
            </div>
            <div className="area-content-wrap" style={{height: testExpand ? '' : 0}}>
                <Form value={editFormData} onChange={changeForm} userActive={false}>
                    <HFormInput label={'是否重现'} bind={'canRecur'} component={isDetail? Display: RadioGroup}
                                options={yesNoOptions} convert={v => convertOptions(v, yesNoOptions)} labelWidth={120} componentWidth={isDetail?88:180}/>
                    <HFormInput label={'重现测试日期'} bind={'recurTime'} component={isDetail? Display: DatePicker} labelWidth={212}
                                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS',v)} bindInConvert={v => toDate.str14ToDate(v)}
                                convert={v => {
                                    if(_.isNil(v)) return '-'
                                    if(_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                                    return dateFormat('YYYY-MM-DD', v) || '-'
                                }}/>
                    <HFormInput label={'测试人员'} bind={'recurPerson'} component={isDetail? Display: UserSelect}
                                convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                    <HFormInput label={'测试用时'} bind={'recurUseTime'} component={isDetail? Display: Input} type={'number'} suffix={'小时'}
                                convert={v => {
                                    if(_.isNil(v)) return '-'
                                    return v + '小时'
                                }}/>
                </Form>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: optStepExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setOptStepExpand(v => !v)}/>
                操作步骤
            </div>
            <div className="area-content-wrap" style={{height: optStepExpand ? '' : 0}}>
                <div className="rich-text-detail-wrap flex" style={{display: isDetail ? "block": 'none'}}>
                    <div dangerouslySetInnerHTML={{__html: _.get(editFormData,'optStep')?.replace(/\n/g, '<br/>')}}/>
                </div>
                <div className="rich-text-area" style={{display: !isDetail ? "block": 'none'}}>
                    <TinyEditor ref={optStepRef} height={350}/>
                </div>
            </div>
        </div>
        <div className="area-wrap">
            <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{transform: logListExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setLogListExpand(v => !v)}/>
                日志文件清单
            </div>
            <div className="area-content-wrap" style={{height: logListExpand ? '' : 0}}>
                <div className="rich-text-detail-wrap flex" style={{display: isDetail ? "block": 'none'}}>
                    <div dangerouslySetInnerHTML={{__html: _.get(editFormData,'logList')?.replace(/\n/g, '<br/>')}}/>
                </div>
                <div className="rich-text-area" style={{display: !isDetail ? "block": 'none'}}>
                    <TinyEditor ref={logListRef} height={350}/>
                </div>
            </div>
        </div>
    </div>
}

export default ReproduceBug;
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DataGrid, Button, Messager, Pagination } from 'rootnet-ui';
import { N2 } from 'rootnet-core/format';
import { useGet } from "rootnet-biz/es/hooks";
import _ from 'lodash'
import gd from "../../../base/global";
import { pathSearchFor, strParams } from "../../../utils/publicFun";
import { getDeptTreeOptions } from "../../common/TreeFunc";
import { Box } from "../../common/commonComponent";
import './QuickQueryDialog.scss'
import { Form, FormInput, Select, DatePicker } from "rootnet-edit";
import { toDate, dateFormat } from 'rootnet-core/dateFormat';
import { TextIconBtn } from "../../common/TextIconBtn";
import { ScheduleManagementContext } from "../../common/Context";

const { str8ToDate } = toDate;

function weekFirstEnd() {
    const firstDay = new Date(Date.now() - (new Date().getDay() - 1) * 1000 * 60 * 60 * 24)
    const endDay = new Date(Date.now() - (new Date().getDay() - 7) * 1000 * 60 * 60 * 24)
    return {
        beginDate: dateFormat('YYYYMMDD', firstDay),
        endDate: dateFormat('YYYYMMDD', endDay),
    }
}

function getColumns(props) {
    return [
        { header: '部门', bind: 'department', align: 'left', width: 150, tooltip: true, sortable: true },
        { header: '员工', bind: 'userName', align: 'left', width: 100, tooltip: true, sortable: true },
        { header: '工号', bind: 'userCode', align: 'left', width: 100, tooltip: true, sortable: true, },
        { header: '工作日期', bind: 'workDate', align: 'left', width: 120, tooltip: true, sortable: true },
        { header: '填报用时（小时）', bind: 'fillUseTime', align: 'left', width: 100, tooltip: true, sortable: true, convert: N2 },
        { header: '确认用时（小时）', bind: 'auditUseTime', align: 'left', width: 100, tooltip: true, sortable: true, convert: N2 },
        { header: '缺失时长（小时）', bind: 'missingHours', align: 'left', width: 100, tooltip: true, sortable: true, convert: N2 },
        { header: '原因', bind: 'missingReason', align: 'left', width: 140, tooltip: true, sortable: true },
    ]
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
});

function MissingHours(props) {
    const { downloadGetProgress } = useContext(ScheduleManagementContext)
    const { data: listRes, doFetch: getList, loading, error } = useGet()
    const { data: userList } = useGet(`/common/userinfo?indexValidFlag=1`)
    const { data: deptOptionsRes } = useGet(`/common/department?activeFlag=Y`)
    const [params, setParams] = useState()

    const deptTreeOptions = useMemo(() => {
        return getDeptTreeOptions(deptOptionsRes)
    }, [deptOptionsRes])

    const userOptions = useMemo(() => {
        if (_.isEmpty(userList)) return []
        return _.map(userList, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}` }))
    }, [userList])

    const option = useMemo(() => {
        return getOption(getColumns())
    }, [])

    const { pageSize, total, pageNum, rows: list } = useMemo(() => listRes || {}, [listRes]);

    useEffect(() => {
        if (_.isEmpty(userList)) return
        const historyParams = pathSearchFor(props.location.search) || {}
        const currentUser = gd.User.operator_id
        const currentDept = _.get(_.find(userList, x => x.userAccount === currentUser), 'department')
        setParams({
            pageNum: 1,
            pageSize: 20,
            department: currentDept,
            ...weekFirstEnd(),
            ...historyParams
        })
    }, [userList, props.location.search])

    const getChildDept = useCallback((pDeptId) => {
        let searchDept = [pDeptId]
        getChildLoop(pDeptId)
        return searchDept

        function getChildLoop(deptId) {
            const childDeptList = _.filter(deptOptionsRes, x => x.parentDept === deptId)
            if (!_.isEmpty(childDeptList)) {
                _.forEach(childDeptList, x => {
                    searchDept.push(x.departMentId)
                    getChildLoop(x.departMentId)
                })
            }
        }
    }, [deptOptionsRes])

    useEffect(() => {
        if (_.isEmpty(userList) || _.isNil(params)) return
        const formDeptParams = _.get(params, 'department')
        let deptParams
        if (_.isNil(formDeptParams) || formDeptParams === '') {
            deptParams = null
        } else {
            deptParams = getChildDept(formDeptParams)
        }

        const endDate = _.get(params, 'endDate')
        const now = dateFormat('YYYYMMDD', new Date())

        getList('/worktime/missinghours?' + strParams({ ...params, department: deptParams, endDate: _.isNil(endDate) ? now : endDate }))


    }, [getList, params, userList, getChildDept])

    const downLoadCsv = useCallback(() => {
        const formDeptParams = _.get(params, 'department')
        let deptParams
        if (_.isNil(formDeptParams) || formDeptParams === '') {
            deptParams = null
        } else {
            deptParams = getChildDept(formDeptParams)
        }
        const endDate = _.get(params, 'endDate')
        const now = dateFormat('YYYYMMDD', new Date())
        const csvParams = _.omit(params, ['pageNum', 'pageSize'])
        downloadGetProgress(`/worktime/exportMissingHours?${strParams({ ...csvParams, department: deptParams, endDate: _.isNil(endDate) ? now : endDate })}`, 'MissingHours导出记录')
    }, [params, downloadGetProgress, getChildDept])

    return <div className="missing-hours flex-y fill">
        <Options initParams={params} search={setParams} {...{ deptTreeOptions, userOptions }} />
        <Box title='MissingHours' data={list} className='x-card-singlegrid' loading={loading} error={error}
            extra={<TextIconBtn icon='daochu' text='导出' onClick={_.debounce(downLoadCsv, 300)} />}>
            <DataGrid data={list} option={option} className='x-datagrid' />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
    </div>
}

function Options(props) {
    const { initParams, search, deptTreeOptions, userOptions } = props
    const [params, setParams] = useState(initParams)
    useEffect(() => {
        setParams(initParams)
    }, [initParams])

    const handleChange = useCallback((options, key) => {
        if (key === 'beginDate' && options[key] > options['endDate'] && !_.isEmpty(options['endDate'])) return Messager.show('开始时间不能大于结束时间', { icon: 'error' })
        if (key === 'endDate' && options[key] < options['beginDate'] && !_.isEmpty(options['endDate'])) return Messager.show('结束时间不能小于开始时间', { icon: 'error' })
        setParams(options)
    }, [])

    return <div className='c-options'>
        <Form onChange={handleChange} value={params} >
            <FormInput label="部门" bind="department" component={Select} options={deptTreeOptions} tree search clear />
            <FormInput label="员工" bind="userAccount" component={Select} options={userOptions} search clear />
            <FormInput label="开始时间" bind="beginDate" component={DatePicker} bindInConvert={str8ToDate} bindOutConvert={v => dateFormat('YYYYMMDD', v)} />
            <FormInput label="结束时间" bind="endDate" component={DatePicker} bindInConvert={str8ToDate} bindOutConvert={v => dateFormat('YYYYMMDD', v)} clear />
        </Form>
        <Button primary onClick={() => search({ ...params, pageNum: 1, pageSize: 20, })} className='query-btn'>查询</Button>
    </div>

}

export default MissingHours;
import React, { useState, useContext } from 'react'
import { Button } from 'rootnet-ui'
import { Form, FormInput, Select } from 'rootnet-edit'
import { TabContext } from '../common/Context'

function getInitParams(isBind) {
  if (isBind) {
    return ({
      departmentList: null,
      techLevelList: null,
      status: null
    })
  }
  return ({
    departmentId: null,
    techLevel: null
  })
}

export default function Options(props) {
  const { search, initParams, isBind = true } = props
  const { treeDeptOptions, techLevelOptions, statusOpt } = useContext(TabContext)
  const [params, setParams] = useState(initParams)

  return <div className='c-options'>
    <Form value={params} onChange={setParams}>
      <FormInput bind={isBind ? 'departmentList' : 'departmentId'} label='部门' search tree clear component={Select} options={treeDeptOptions} />
      <FormInput bind={isBind ? 'techLevelList' : 'techLevel'} label='职级' search clear multiple component={Select} options={techLevelOptions}
        bindInConvert={v => v ? v.split(',') : null} bindOutConvert={v => v ? v.join(',') : null} />
      {
        isBind && <FormInput bind='status' label='状态' component={Select} options={statusOpt} multiple clear
          bindInConvert={v => v ? v.split(',') : null} bindOutConvert={v => v ? v.join(',') : null}
        />
      }
    </Form>
    <Button style={{ marginLeft: 15 }} primary onClick={() => { search(params) }}>查询</Button>
    <Button style={{ marginLeft: 10, border: '1px solid #7A8199' }} className='reset-btn' text
      onClick={() => { setParams(getInitParams(isBind)); search(getInitParams(isBind)) }}>重置</Button>
  </div>

}

import React from 'react'
import { TextArea } from 'rootnet-edit'
import { Box } from '../../../common/commonComponent';
import './memo.scss'

function Memo(props) {
	let { onChange, value='', markable= true } = props;
	return <Box title="备注" className='flex-y work-time-detail-memo x-card-singlegrid ' data={[{}]}>
		<TextArea onChange={onChange} style={{ margin: 10 }} value={value} counter={false} maxLength={100000} disabled={!markable} />
	</Box>
}

export default Memo
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Pagination} from 'rootnet-ui'
import './WorkplaceDynamic.scss'
import clsx from "clsx";
import _ from "lodash";
import {Timeline} from "antd";
import useWebsocket from "../../common/hooks/useWebsocket";
import gd from "../../../base/global";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import {strParams} from "../../../utils/publicFun";
import moment from 'moment'
import {isNil} from "../../appraise/components/method";

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

function WorkplaceDynamic(props) {
    const {fullScreenCard} = props
    const {data:listRes, doFetch: getInitList} = useGet()
    const [params, setParams] = useState(getInitParams())
    const [list, setList] = useState([])
    const {doFetch: onReadPost} = usePost()
    const [reconnectTryNum, setReconnectTryNum] = useState(0)
    const {doFetch: refreshGlobalUuid} = useGet()

    const {total, pageNum, pageSize } = useMemo(() => (listRes || {}), [listRes]);

    const { wsData, readyState, reconnect, closeWebSocket } = useWebsocket({
        // url: ' ws://wsProxy/websocket/jiwenqian',
        // url: window.location.protocol.replace("https:","wss:").replace("http:","ws:") + '//172.20.101.16:9527/websocket/'+gd.User.operator_id, // 开发环境
        // url: window.location.protocol.replace("https:","wss:").replace("http:","ws:") + '//172.20.101.16:9528/websocket/'+gd.User.operator_id, // 版本环境
        url: window.location.protocol.replace("https:","wss:").replace("http:","ws:") + '//rims.croot.com/websocket/'+gd.User.operator_id, // 生产环境
        // url: 'ws://'+window.location.host+'/wsProxy/websocket/jiwenqian',
    })

    useEffect(()=>{
        getInitList('/messageinfo/getMessageinfoList?'+strParams(params)).then(res => {
            setList(_.get(res,'rows') || [])
        }).catch(err => {
            console.log('err._message',err._message)
        })
    },[getInitList, params])

    useEffect(() => {
        // 接受到socket数据， 进行业务逻辑处理
        if (Object.keys(wsData).length !== 0) {
            if(pageNum === 1){
                setList(old => {
                    const idList = _.map(old, 'id')
                    if(_.includes(idList, wsData.id)){
                        return old
                    }else{
                        return _.concat([wsData],old)
                    }
                })
            }
        }

        // 如果是已关闭且是当前页面自动重连
        if (readyState.key === 3) {
            // 最大重连次数限制
            if(reconnectTryNum <= 5){
                reconnect()
                setReconnectTryNum(x => x + 1)
            }
        }

    }, [wsData, readyState, reconnect, pageNum, reconnectTryNum])

    useEffect(()=>{
        // 卸载后操作
        return ()=>{
            closeWebSocket()
        }
    },[closeWebSocket])

    const showList = useMemo(()=>{
        return _.map(list, x => {
            const date = moment(x.createTime)
            const day = date.format('YYYY/MM/DD')
            const time = date.format('HH:mm')
            return {
                ...x,
                day,
                time
            }
        })
    },[list])

    const onRead = useCallback((id)=>{
        onReadPost('/messageinfo/updateMessageinfoToRead?id='+id)
        setList(old => _.map(old, x => {
            if(x.id === id){
                return {
                    ...x,
                    isRead: 1
                }
            }else{
                return x
            }
        }))
    },[onReadPost])

    const toUrl = useCallback((url)=>{
       refreshGlobalUuid('/common/refreshGlobalUuid').then(()=>{
           window.open(url)
       }).catch(()=>{
           window.open(url)
       })
    },[refreshGlobalUuid])

    return <div className={clsx('workplace-dynamic', {'hidden-card': !_.isNil(fullScreenCard)})}>
        <Card title={'我的消息'}>
            <div className="content-wrap flex-y">
                <div className="message-wrap flex-y">
                    <Timeline>
                        {
                            _.map(showList, (item, index) => {
                                const showDay = index === 0 || (item.day !== showList[index-1].day)
                                return (<Timeline.Item key={item.id}>
                                    <div className={clsx("time-content-wrap",{'show-day': showDay})}>
                                        <div className="time-wrap flex-y">
                                            {
                                                showDay &&
                                                <div className="day">{item.day}</div>
                                            }
                                            <div className="time">{item.time}</div>
                                        </div>
                                        <div className={clsx("time-content-wrap flex-y",{
                                            'no-read': item.isRead === 0 && !isNil(item.toUrl),
                                            'no-url': isNil(item.toUrl),
                                            'has-url': !isNil(item.toUrl),
                                        })}
                                              onClick={()=>{
                                                  if(isNil(item.toUrl)) return
                                                  onRead(item.id)
                                                  toUrl(item.toUrl)
                                              }}>
                                            <span className={'time-content-title'}>【{item.title}】</span>
                                            <div/>
                                            <span className={'time-content'}>
                                            {item.content}
                                            </span>
                                        </div>
                                    </div>
                                </Timeline.Item>)
                            })
                        }
                    </Timeline>
                </div>
                <Pagination pageSize={pageSize} total={total} current={pageNum}
                            onChange={(pageNum, pageSize) => {
                                setParams(x => _.assign({}, x, {pageNum, pageSize}))
                            }} />
            </div>
        </Card>
    </div>
}

export default WorkplaceDynamic;
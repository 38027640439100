import _ from 'lodash';
import React, { useState } from 'react'
import { toDate, dateFormat } from 'rootnet-core/dateFormat';
import FollowMattersDetail from '../followMattersDetail';
import './index.scss'

const { str14ToDate, str8ToDate } = toDate

/*
 #FF0000 逾期未完成
 #FFFF00 逾期完成
 #0285BD 未进行
 #24B47E 正常完成
 #AAAAAA 挂起

 1 未进行
 2 执行完成
 3 挂起
*/

function stateMatter({ closeTime, status, requireDate }) {
  if (_.isNull(closeTime) && status === '1' && new Date() > str14ToDate(requireDate)) {
    return <span className='status' style={{ backgroundColor: '#FF0000' }}>逾期未完成</span>
  }
  if (status === '2' && str8ToDate(dateFormat('YYYYMMDD', str14ToDate(closeTime))) > str14ToDate(requireDate)) {
    return <span className='status' style={{ backgroundColor: '#FFCC00' }}>逾期完成</span>
  }
  if (status === '1') {
    return <span className='status' style={{ backgroundColor: '#0285BD' }}>未进行</span>
  }
  if (status === '2' && str8ToDate(dateFormat('YYYYMMDD', str14ToDate(closeTime))) <= str14ToDate(requireDate)) {
    return <span className='status' style={{ backgroundColor: '#24B47E' }}>正常完成</span>
  }
  if (status === '3') {
    return <span className='status' style={{ backgroundColor: '#AAAAAA' }}>挂起</span>
  }
}

export default function FollowMattersItem(props) {
  const { principalName, subject, requireDate, updateTime, recUser, getIcon, id, funcCode, refresh, referenceId, status, closeTime, source, todoListName } = props
  const [open, setOPen] = useState(false)
  return (
    <>
      <div className='record-box follow-matters-item'>

        <div className='record-icon'>
          {getIcon('todo', recUser)}
        </div>

        <div className='follow-matters-content'>

          <div className='name-row'>
            <span>{recUser}</span>
            <span className='create-time-row'>{dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(updateTime))}</span>
          </div>

          <div className='title-row'>
            <span style={{ color: '#929292' }}>添加了{todoListName}</span>
            {stateMatter({ status, requireDate, closeTime })}
            <span className='operation' onClick={() => setOPen(true)}>{subject}</span>
          </div>

          <div className='principal-row'>
            <span style={{ color: '#929292' }}>负责人：</span>
            <span>{principalName}</span>
          </div>

          <div className='time-row'>
            <span style={{ color: '#929292' }}>时间要求：</span>
            <span>{dateFormat('YYYY-MM-DD', str14ToDate(requireDate))}</span>
          </div>

        </div>

      </div>
      {open && <FollowMattersDetail {...{ id, refresh, funcCode, relateId: referenceId, close: () => setOPen(false), source }} />}
    </>
  )
}

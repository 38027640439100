import _ from 'lodash'
import { isNil } from 'rootnet-core/format'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Select, Display, Input, DatePicker, CheckBox } from 'rootnet-edit'
import convertOptions from '../../../../common/ConvertOptions'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import gd from '../../../../../base/global'

function filterOption(data) {
  return _.filter(data, o => _.get(o, 'displayFlag') !== 'N')
}

const YNOptions = [
  { text: '是', value: 'Y' },
  { text: '否', value: 'N' },
]

export function essentialInfo(props) {
  const { mode, productTreeOptions, productOptions, moduleOptions, stateOpt, typeOpt, natureOpt, scaleOpt, classifyOpt, allUserRes, customerSystemOpt, projectOpt, projectAllOpt, functionary, projectDisable, isEditProject, versionInner, isPMM, isQA, defaultData } = props
  const isDetail = mode === 'detail'
  const isEdit = mode === 'edit'
  const isAdd = mode === 'add'
  const isPrincipal = (((functionary === gd.User.operator_id && isEdit) || (isPMM && isEdit)) || isAdd)
  const isPrincipals = ((isQA && isEdit) || isAdd)
  const isVersionApprovalDateComponent = ((isQA && isEdit) || isAdd || (isEdit && isNil(_.get(defaultData, 'versionApprovalDate'))))
  const isVersionApprovalDate = (isPrincipal && versionInner !== 'Y')
  return [
    {
      required: true,
      tree: true,
      search: true,
      label: '产品ID',
      bind: 'productid',
      component: isDetail ? Display : Select,
      options: productTreeOptions,
      convert: v => convertOptions(v, productOptions),
    },
    {
      clear: true,
      search: true,
      label: '子产品ID',
      bind: 'subsysid',
      component: isDetail ? Display : Select,
      options: moduleOptions,
      convert: v => convertOptions(v, moduleOptions),
    },
    {
      required: true,
      label: '版本编号',
      bind: 'releaseid',
      component: isDetail ? Display : Input,
      disabled: isEdit,
    },
    {
      required: true,
      label: '版本名称',
      bind: 'releasename',
      component: isDetail ? Display : Input,
    },
    {
      required: true,
      label: '负责人',
      bind: 'functionary',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      required: true,
      label: '版本状态',
      bind: 'state',
      options: filterOption(stateOpt),
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, stateOpt),
    },
    {
      required: isAdd ? true : isVersionApprovalDate,
      label: '版本立项日期',
      bind: 'versionApprovalDate',
      component: !isVersionApprovalDateComponent ? Display : DatePicker,
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      bindInConvert: v => toDate.str14ToDate(v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
    {
      required: isAdd ? true : isPrincipal,
      label: '计划发布日期',
      bind: 'issuedate',
      component: !isPrincipals ? Display : DatePicker,
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      bindInConvert: v => toDate.str14ToDate(v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
    {
      clear: true,
      label: '实际发布日期',
      bind: 'realreleasedate',
      mode: 'datetime',
      component: !isPrincipal ? Display : DatePicker,
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      bindInConvert: v => toDate.str14ToDate(v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
      }
    },
    {
      required: true,
      label: '版本类型',
      bind: 'producttype2',
      options: filterOption(typeOpt),
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, typeOpt),
    },
    {
      required: true,
      label: '版本性质',
      bind: 'producttype',
      options: filterOption(natureOpt),
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, natureOpt),
    },
    {
      required: true,
      label: '版本规模',
      bind: 'sproductround',
      options: filterOption(scaleOpt),
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, scaleOpt),
    },
    {
      required: true,
      label: '版本分类',
      bind: 'sprodecttpye3',
      options: filterOption(classifyOpt),
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, classifyOpt),
    },
    {
      clear: true,
      label: '发布客户系统',
      bind: 'mulcustid',
      className: isDetail ? 'text-area-detail' : '',
      options: customerSystemOpt,
      component: isDetail ? Display : Select,
      convert: v => {
        const value = _.split(v, '^')
        return _.join(_.map(value, val => _.get(_.find(customerSystemOpt, o => o.value === val), 'label')), '，')
      },
      componentWidth: 642,
    },
    {
      required: true,
      clear: true,
      search: true,
      label: '项目',
      bind: 'projectid',
      disabled: isEditProject ? false : (isEdit || projectDisable),
      options: projectOpt,
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, projectAllOpt),
    },
    {
      clear: true,
      label: '上线日期',
      bind: 'beginusedate',
      component: isDetail ? Display : DatePicker,
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      bindInConvert: v => toDate.str14ToDate(v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
    {
      label: '客户版本编号',
      bind: 'custreleaseid',
      component: isDetail ? Display : Input,
    },
    {
      label: '客户版本名称',
      bind: 'custreleasename',
      component: isDetail ? Display : Input,
    },
    {
      label: 'po负责人',
      bind: 'pouser',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      label: '测试负责人',
      bind: 'testleaderid',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      label: '环境负责人',
      bind: 'testenvmaint',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      label: '技术支持人',
      bind: 'techsupportor',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      required: true,
      label: '上线特定时间要求',
      bind: 'moretimerequest',
      component: isDetail ? Display : Input,
      className: isDetail ? 'text-area-detail' : '',
      // componentWidth: 616,
    },
    {
      label: '研发负责人',
      bind: 'devUser',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      label: '产品负责人',
      bind: 'productOwner',
      component: isDetail ? Display : UserSelect,
      convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount')
    },
    {
      required: true,
      label: 'inner',
      bind: 'versionInner',
      disabled: isDetail,
      options: YNOptions,
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, YNOptions),
      componentWidth: isDetail ? 14 : 230,
      tooltip: '允许调整已发布/上线的inner版本的研发任务内容和范围，请谨慎修改！',
      icon: 'bangzhu'
    }
  ]
}

export function productionRelated(props) {
  const { mode, versionListOpt, releasebasic, producttype, noGlobalversioncontrol } = props
  const isDetail = mode === 'detail'
  const versionListOpts = _.concat([{ value: '无基础版本', text: '无基础版本' }], versionListOpt)
  const addVersionListOpt = _.concat([{ value: '无升级到主线的最小版本', text: '无升级到主线的最小版本' }], versionListOpt)
  const turnBackVersionListOpt = _.concat([{ text: '无转回主线版本', value: '无转回主线版本' }], versionListOpt)
  return [
    {
      search: true,
      required: true,
      label: '基础版本',
      bind: 'releasebasic',
      options: versionListOpts,
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, versionListOpts),
    },
    {
      clear: true,
      search: true,
      label: '升级的下一个主线版本',
      bind: 'updatetomainlineleastversion',
      options: addVersionListOpt,
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, addVersionListOpt),
    },
    {
      label: '收拢版本清单',
      bind: 'morereleasebase',
      options: versionListOpt,
      component: isDetail ? Display : Select,
      componentWidth: 642,
      convert: v => {
        const value = _.split(v, '^')
        return _.join(_.map(value, val => _.get(_.find(versionListOpt, o => o.value === val), 'text')), '，')
      },
    },
    {
      // required: true,
      label: '数据库版本',
      bind: 'globalversioncontrol',
      component: isDetail ? Display : Input,
      disabled: true,
      tooltip: true,
    },
    {
      label: '此版本无脚本',
      bind: 'noGlobalversioncontrol',
      component: isDetail ? Display : CheckBox,
      disabled: (noGlobalversioncontrol && _.isNil(releasebasic)),
      componentWidth: 14,
    },
    {
      label: '制作基础',
      bind: 'makebasic',
      component: isDetail ? Display : Input,
      disabled: releasebasic !== '无基础版本'
    },
    {
      clear: true,
      search: true,
      label: '回归主线版本',
      bind: 'shouldgotoreleaseid',
      options: turnBackVersionListOpt,
      component: isDetail ? Display : Select,
      convert: v => convertOptions(v, turnBackVersionListOpt),
      disabled: (isNil(releasebasic) || (_.includes(['Y', 'B', 'J'], producttype) && !isNil(releasebasic) && releasebasic === '无基础版本'))
    },
    {
      label: '回归至本版本',
      bind: 'returnThisReleaseId',
      component: Display,
    }
  ]
}
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Popover } from 'antd'
import { Icon } from '../../../../../../components'
import './index.scss'
import { isNil } from '../../../../../appraise/components/method'

// shijian1  代办  等待
// shibai  不通过
// chenggong 通过   有条件通过

export default function ProjectStage(props) {
  const { allData, statusOptions, setKeyNodes, setStageQualityAudit, STAGE_FLOW_LIST, auditAuthority } = props
  const [hovered, setHovered] = useState()
  const [stageOrder, setStageOrder] = useState()

  const keyNodesData = useMemo(() => {
    if (_.isEmpty(allData)) return []
    return _.keys(_.groupBy(allData, 'keyNodes'))
  }, [allData])

  const dataStage = useMemo(() => {
    if (_.isEmpty(allData)) return []
    let arr = []
    let array = []
    _.forEach(allData, o => {
      if (_.includes(arr, o.keyNodes)) { }
      else {
        arr.push(o.keyNodes)
        const findItem = _.find(allData, item => (o.keyNodes === item.keyNodes && item.isBaselineRelease === 'Y'))
        array.push(_.assign({}, _.pick(o, ['id', 'keyNodes', 'status', 'qualityResult']), !_.isNil(findItem) && {
          _qualityAudit: _.isNil(findItem) ? false : !_.includes(['09', '10'], _.get(findItem, 'status'))
        }))
      }
    })
    setStageOrder(arr)
    return array
  }, [allData])

  const Content = useCallback(({ keyNodes }) => {
    const children = _.filter(_.orderBy(_.filter(_.filter(allData, o => !_.includes(['09', '10'], o.status)), o => o.keyNodes === keyNodes), 'serial', 'asc'), o => !_.isNil(o.parentId))
    if (_.isEmpty(children)) return null
    const quantityCompletion = _.filter(children, o => o.status === '03')
    return <div className='dev-project-stage-content'>
      <div className='quantity-completion'>完成度：{_.size(quantityCompletion)}/{_.size(children)}</div>
      <div className='stage-content'>
        {
          _.map(children, (o) => {
            const color = _.get(_.find(statusOptions, k => k.value === o.status), 'color')
            return <div className='flex stage-item' key={o.id}>
              <div className='stage-name'>{o.title}</div>
              <div className='stage-status' style={{ color }}>{o.statusName}</div>
            </div>
          })
        }
      </div>
    </div>
  }, [allData, statusOptions])

  const [lastIndex, nextIndex] = useMemo(() => {
    const filterData = _.filter(allData, o => !_.includes(['09', '10'], o.status))
    const yseNoArr = _.map(stageOrder, (v, i) => {
      const filterArr = _.filter(filterData, o => (o.keyNodes === v && o.isParent !== 'Y'))
      if (_.isEmpty(filterArr)) return null
      return _.every(filterArr, o => o.status === '03')
    })
    let isTrue = false
    const newYseNoArr = _.map(yseNoArr, v => {
      if (_.isNil(v)) return v
      if (isTrue) return false
      if (v === false) {
        isTrue = true
        return v
      }
      if (v === true) return v
    })
    const findIndex = _.findLastIndex(newYseNoArr, v => v === true)
    const nextIndex = _.findIndex(yseNoArr, v => v === false)
    return [
      findIndex === -1 ? 0 : findIndex + 1,
      nextIndex === -1 ? _.size(yseNoArr) - 1 : nextIndex
    ]
  }, [stageOrder, allData])

  const getItemBind = useCallback((status, bind = 'icon') => {
    const findItem = _.find(STAGE_FLOW_LIST, o => o.value === (isNil(status) ? '05' : status))
    return _.get(findItem, bind)
  }, [STAGE_FLOW_LIST])

  useEffect(() => {
    if (_.isEmpty(keyNodesData)) return
    setKeyNodes(keyNodesData[nextIndex])
  }, [setKeyNodes, keyNodesData, nextIndex])

  return (
    <div className='project-stage-arrow-crumbs'>
      <ul className='breadcrumb'>
        {
          _.map(dataStage, (item, i) => {
            return <Popover
              placement="bottom"
              key={item.id}
              open={item.keyNodes === hovered}
              trigger="hover"
              content={<Content keyNodes={item.keyNodes} />}
              onOpenChange={open => {
                if (open) setHovered(item.keyNodes)
                else setHovered(false)
              }}
            >
              <li
                className={cls('breadcrumb-item', {
                  'breadcrumb-item-last': _.size(dataStage) === i + 1,
                  'breadcrumb-item-complete': lastIndex > i,
                  'breadcrumb-item-underway': i === lastIndex,
                  'breadcrumb-item-disabled': _.includes(['09', '10'], item.status),
                })}
              // onClick={() => _.isNil(item.onClick) ? onClick(i, useTips) : item.onClick()}
              >
                {item.keyNodes}
                {
                  (item?._qualityAudit) &&
                  <Icon
                    name={getItemBind(item?.qualityResult)}
                    style={{ color: getItemBind(item?.qualityResult, 'color') }}
                    className={cls('icon-btn', { 'icon-disable': !auditAuthority })}
                    onClick={() => {
                      if (!auditAuthority) return
                      setStageQualityAudit({ mode: 'edit', id: item.id })
                    }}
                  />
                }
              </li>
            </Popover>
          })
        }
      </ul>
    </div>
  )
}

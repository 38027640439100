import React, { useEffect, useMemo } from 'react';
import { useGet } from '../../../../utils/hook';
import { Card, DataGrid } from 'rootnet-ui';
import { Dateformat } from 'rootnet-core/format';
import _ from 'lodash';
import './TaskTab.scss';

const options = [
    { header: "#", align: "center", width: 40, convert: (v, o, i) => <span>{i + 1}</span> },
    { header: "任务", align: "left", bind: "title", width: 300 },
    { header: "负责人", align: "left", bind: "principalName", width: 100 },
    { header: "截止日期", align: "left", bind: "testCompleteDate", width: 120, sortable: true, convert: (v) => Dateformat(v) },
    { header: "状态", align: "center", bind: "issueState", width: 80 },
    { header: "客户", align: "left", bind: "bondproviderName", width: 100 },
    { header: "产品", align: "left", bind: "productName", width: 160 },
    { header: "研发任务ID", align: "left", bind: "tracerId", width: 200 },
    { header: "研发任务状态", align: "left", bind: "tracerState", width: 120 },
    { header: "需求ID", align: "left", bind: "interiorReqNo", width: 140 },
    { header: "任务ID", align: "left", bind: "id", width: 140 },
]

function tableOptsFor(opts, data) {
    return {
        resizable: true,
        fixedLeft: 1,
        autoFill: true,
        nilText: '-',
        emptyText: '-',
        columns: opts,
    }
}

//项目 -- 任务明细
function TaskTab(props) {
    let { data, doFetch, loading, error } = useGet();
    const { search, title } = props;
    useEffect(() => {
        const url = `develop/project/task/list?id=${_.get(search, 'id')}`;
        doFetch(url);
    }, [doFetch, search]);

    const option = useMemo(()=>tableOptsFor(options, data),[data])

    return <Card
        title={title}
        loading={loading}
        error={error}
        className="approval-table x"
        data={data}
    >
        <DataGrid option={option} data={data} />
    </Card>
}

export default TaskTab;
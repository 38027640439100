import React, { useMemo, useCallback, useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tooltip } from 'antd'
import { isNil } from 'rootnet-core/format'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, DatePicker, Display, RadioGroup } from 'rootnet-edit'
import { useGet } from '../../../../../utils/hook'
import convertOptions from '../../../../common/ConvertOptions'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import gd from '../../../../../base/global'
import { Icon } from '../../../../../components'
import TextAreaInput from "../../../../common/textAreaInput/TextAreaInput";
import RichTextEditor from '../../../../common/richTextEditor/TinyEditor'

const { str14ToDate, str8ToDate } = toDate

const LIST_URL = [
  '/common/globalconst?globalConst=PRIORITYLIST', // 优先级
  '/common/globalconst?globalConst=AUDITTYPE', // 审核状态
  '/common/globalconst?globalConst=todoType', // 事项类型
  '/common/department?activeFlag=Y', // 部门
  '/UserSetting/getUniversalInterfaces?code=部门ID&codeName=部门全路径&tableName=view_department', // 部门全路径
]

const radioOptions = [
  { text: "是", value: '1' },
  { text: "否", value: '0' },
]

function buildTree(allList, list, keyName, parentName, level) {
  return _.map(list, listItem => {
    const childList = _.filter(allList, allItem => _.get(allItem, parentName) === _.get(listItem, keyName))
    if (level >= 2 || childList.length === 0) return convertDepartment(listItem)
    return _.assign(convertDepartment(listItem), { children: buildTree(allList, childList, keyName, parentName, level + 1) })
  })
}

function convertDepartment(item) {
  return { text: item.departMentName, value: item.departMentId }
}

const HFormInput = props => <FormInput horizontal labelWidth={80} componentWidth={200} {...props} />

export default function TodoListForm(props) {
  const { isDetail, editFormData, setEditFormData, editFormError, setEditFormError, allUserRes, isDisplay, noteRef, previewImage } = props
  const [noteExpand, setNoteExpand] = useState(true)
  const { data: optionRes } = useGet(LIST_URL)

  const [priorityOpt, auditStatusOpt, eventTypeOpt, treeDeptOptions, deptAllPathOptions] = useMemo(() => {
    if (_.isEmpty(optionRes)) return []
    const [d1, d2, d3, d4, d5] = optionRes || []
    const firstLevelDepList = _.filter(d4, x => _.get(x, 'parentDept') === "")
    const treeDeptOptions = buildTree(d4, firstLevelDepList, "departMentId", "parentDept", 0)
    return [
      convertGlobalConstOptions(d1),
      convertGlobalConstOptions(d2),
      convertGlobalConstOptions(d3),
      treeDeptOptions,
      convertGlobalConstOptions(d5)
    ]
  }, [optionRes])

  const changeForm = useCallback((newObj, bind) => {
    setEditFormData(oldObj => {
      let cloneObj = { ...oldObj, ...newObj, source: oldObj?.source }
      return cloneObj
    })
  }, [setEditFormData])

  const typeId = useMemo(() => {
    if (_.isNil(_.get(editFormData, 'source'))) return null
    const { IssueId, storyId, defectId, caseId, planId, ProjectId, ID, sSubject, CSRecordID, ProjectName } = editFormData.source
    const impId = (!_.isNil(ProjectId) && !_.isNil(ID)) ? ID : undefined
    return CSRecordID || IssueId || storyId || defectId || caseId || planId || impId || sSubject || ProjectName || ''
  }, [editFormData])
  // console.log(str8ToDate(editFormData?.proposedTime));
  const formList = useMemo(() => {
    return _.compact([
      {
        label: '负责人',
        bind: 'principal',
        required: true,
        component: UserSelect,
        convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'),
      },
      // {
      //   label: '处理人',
      //   bind: 'currentUser',
      //   required: true,
      //   component: UserSelect,
      //   convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'),
      // },
      {
        label: '参与人',
        bind: 'otherPerson',
        component: UserSelect,
        multiple: true,
        bindInConvert: v => isNil(v) ? [] : _.split(v, ','),
        bindOutConvert: v => _.join(v, ','),
        convert: v => {
          const userList = _.join(_.map(v, x => convertOptions(x, allUserRes, 'userName', 'userAccount')), '、')
          return <Tooltip title={userList}>
            <div className={'ellipsis-line'}>{userList}</div>
          </Tooltip>
        },
      },
      {
        label: '提出人',
        bind: 'sponsor',
        component: UserSelect,
        convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'),
      },
      {
        label: '提出时间',
        bind: 'proposedTime',
        required: true,
        component: DatePicker,
        bindInConvert: v => str8ToDate(v),
        bindOutConvert: v => dateFormat('YYYYMMDD', v),
        convert: v => {
          if (_.isNil(v)) return '-'
          if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str8ToDate(v))
          return dateFormat('YYYY-MM-DD', v) || '-'
        }
      },
      {
        label: '归属部门',
        bind: 'dataDepartment',
        tree: true,
        component: Select,
        clear: true,
        options: treeDeptOptions,
        convert: v => convertOptions(v, deptAllPathOptions)
      },
      {
        label: '时间要求',
        bind: 'requireDate',
        required: true,
        component: DatePicker,
        bindInConvert: v => isDetail ? v : str14ToDate(v),
        bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
        convert: v => {
          if (_.isNil(v)) return '-'
          if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
          return dateFormat('YYYY-MM-DD', v) || '-'
        }
      },
      {
        label: '优先级',
        bind: 'priority',
        required: true,
        component: Select,
        options: priorityOpt,
        convert: v => convertOptions(v, priorityOpt),
      },
      {
        label: '审核人',
        bind: 'auditPerson',
        component: UserSelect,
        convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'),
      },
      {
        label: '审核状态',
        bind: 'auditStatus',
        component: Select,
        clear: true,
        options: auditStatusOpt,
        disabled: editFormData?.auditPerson !== gd.User.operator_id,
        convert: v => convertOptions(v, auditStatusOpt),
      },
      {
        label: '事项类型',
        bind: 'eventType',
        component: Select,
        clear: true,
        options: eventTypeOpt,
        convert: v => convertOptions(v, eventTypeOpt),
      },
      {
        label: '来源',
        bind: 'source',
        component: Display,
        convert: v => <Tooltip title={v}>
          <div
            className={cls('ellipsis-line', { 'source-text-hover': editFormData?.toUrlPath })}
            onClick={() => editFormData?.toUrlPath ? window.open('#' + editFormData?.toUrlPath) : null}>
            {v}
          </div>
        </Tooltip>
      },
      isDisplay && {
        label: <><Tooltip title={
          <div>
            <div>跟进事项完成状态是否依赖于关联的子任务的完成状态</div>
            <div>选择"是"则不受子任务状态控制</div>
            <div>选择"否"则全部子任务完成后才可以完成主任务</div>
          </div>
        }><span style={{ marginRight: 4 }}><Icon name='icon_standby' /></span></Tooltip>是否独立完成</>,
        bind: 'isDepend',
        component: RadioGroup,
        options: radioOptions,
        labelWidth: 110,
        componentWidth: isDetail ? 170 : 200,
        convert: v => {
          if (v === '1') return '是'
          if (v === '0') return '否'
          return '-'
        }
      },
      {
        label: '详细描述',
        bind: 'note',
        component: TextAreaInput,
        componentWidth: 500,
        maxLength: 1000,
        className: 'text-area-detail',
      },
      {
        label: '创建人',
        bind: 'recUser',
        component: Display,
      },
      {
        label: '创建时间',
        bind: 'recTime',
        component: Display,
        convert: v => {
          if (_.isNil(v)) return '-'
          if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
          return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
        }
      },
    ])
  }, [priorityOpt, auditStatusOpt, editFormData, eventTypeOpt, isDisplay, allUserRes, isDetail, treeDeptOptions, deptAllPathOptions])

  return (
    <Form
      value={_.assign({}, editFormData, { source: typeId })}
      onChange={changeForm}
      error={editFormError}
      onError={v => setEditFormError(x => _.assign({}, x, v))}
    >
      {
        _.map(formList, item => {
          if (item.bind === 'note') {
            return <div key={item.bind} className="area-wrap note-wrap flex-y">
              <div className="area-header flex center-y">
                <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: noteExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setNoteExpand(v => !v)} />
                详细描述
              </div>
              <div className="rich-text-detail-wrap flex" style={{ display: isDetail && noteExpand ? "block" : 'none' }}>
                <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'note') }} />
              </div>
              <div className="rich-text-area" style={{ display: !isDetail && noteExpand ? "block" : 'none' }}>
                <RichTextEditor ref={noteRef} height={400} />
              </div>
            </div>
          }
          return <HFormInput key={item.bind} {...item} component={isDetail ? Display : item.component} />
        })
      }
    </Form>
  )
}

import React, {useCallback, useMemo, useState} from 'react';
import _ from "lodash";
import convertTableAlign from "../../../../common/view/convertTableAlign";
import findConvert from "../../../../common/view/findConvert";
import useGetViewConfig from "../../../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../../../common/view/hooks/useGetTreeList";
import ViewArea from "../../../../common/view/ViewArea";
import {Box} from "../../../../common/commonComponent";
import { DataGrid, Pagination, Dialog, Messager } from "rootnet-ui";
import './ConferenceQueryDialog.scss'
import {CheckBox} from 'rootnet-edit';

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, selectedIdList } = props

    const customColumns = []

    return [
        { header: '', width: 40, stretch: false, align: 'center', convert: (v, o) => {
            return <CheckBox value={_.includes(selectedIdList,o.id)}/>
        }},
        { header: '#', width: 40, stretch: false, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
            }
        }
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '0003'

function ConferenceQueryDialog(props) {
    const {initInfo, close, afterUpdateRefresh, relateUrl} = props
    const {initList = [], id} = useMemo(()=>initInfo || {},[initInfo])
    const [selectedIdList, setSelectedIdList] = useState(initList)
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const {doFetch: submitPost} = usePost()

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, searchUrl: '/conferencerecord/list' })

    const dateOptions = useGetDateOptions()

    const onCheckboxClick = useCallback((id)=>{
        setSelectedIdList(oldList => {
            if(_.includes(oldList, id)){
                return _.filter(oldList, x=> x !== id)
            }else{
                return _.concat([], oldList, [id])
            }
        })
    },[setSelectedIdList])

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams:{selectedIdList},
    })

    const submit = useCallback(()=>{
        if(isLoading) return
        setIsLoading(true)
        const relateIds = _.filter(selectedIdList, x => !_.includes(initList, x)) || []
        const disassociateIds = _.filter(initList, x => !_.includes(selectedIdList, x)) || []
        const postParams = {
            id,
            conferenceRecordIds: _.join(relateIds,','),
            disassociateRecordIds: _.join(disassociateIds,','),
        }
        submitPost(relateUrl, postParams).then(()=>{
            setIsLoading(false)
            Messager.show('修改成功', { icon: 'success' })
            afterUpdateRefresh()
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setIsLoading(false)
        })
    },[submitPost, id, isLoading, selectedIdList, close, afterUpdateRefresh, initList, relateUrl])

    return <Dialog header={'会议纪要查询'} className={'conference-query-dialog'} cancel={close} confirm={submit}>
        <div className={'conference-query-content fill flex-y'}>
            <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                      {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                          currentViewId, setCurrentViewId, params, pageSize}}/>
            <Box title={'会议纪要'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
                <DataGrid option={options} data={dataGridList} onRowClick={(item)=>onCheckboxClick(item.id)}/>
                <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                            onChange={(pageNum, pageSize) => {
                                setPageSize(pageSize)
                                setParams(x => _.assign({}, x, {pageNum, pageSize}))
                            }} />
            </Box>
        </div>
    </Dialog>
}
export default ConferenceQueryDialog;
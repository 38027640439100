import React, { useEffect } from 'react'
import _ from 'lodash'
import { Drawer } from 'rootnet-ui'
import { Form, FormInput, Display } from 'rootnet-edit'
import { useGet } from '../../../utils/hook'
import './DataTypeDrawer.scss'

const DATA_TYPE_DETAIL = '/fieldType/get'

const detailList = [
  { label: '根网数据类型', bind: 'fieldTypeCode', },
  { label: '类型名称', bind: 'fieldTypeName', },
  { label: 'mysql类型', bind: 'mysqlType', },
  { label: 'sqlserver类型', bind: 'sqlserverType', },
  { label: 'oracle类型', bind: 'oracleType', },
  { label: '字段长度', bind: 'normLength', },
  { label: '精度', bind: 'normPrecision', },
  { label: '类型说明', bind: 'dictTypeDesc', },
]

export default function DataTypeDrawer(props) {
  const { open, cancel, dataTypeId } = props
  const { data, doFetch, loading } = useGet()

  useEffect(() => {
    if (open) doFetch(`${DATA_TYPE_DETAIL}?fieldTypeCode=${dataTypeId}`)
  }, [dataTypeId, doFetch, open])

  return (
    <Drawer
      open={open}
      cancel={cancel}
      footerVisible={false}
      header={'数据类型信息'}
      onOutsideClick={cancel}
      className='data-type-drawer'
      loading={loading}
    >
      <Form value={data}>
        {
          _.map(detailList, item => {
            return (
              <FormInput key={item.bind} horizontal {...item} component={Display} labelWidth={120} />
            )
          })
        }
      </Form>
    </Drawer>
  )
}

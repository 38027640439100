import React, { useState, useCallback } from 'react'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Messager } from 'rootnet-ui'

const WITHDRAW_URL = '/messageinfo/updateMessageinfoRecall'

export default function MessageWithdrawLog(props) {
  const { id, close, refresh } = props
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    doFetch(`${WITHDRAW_URL}?id=${id}`, {})
      .then(() => {
        Messager.show('撤回成功', { icon: 'success' })
        refresh()
        close()
        setIsDisable(false)
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisable, doFetch, id, close, refresh])

  return (
    <Dialog
      header='撤回'
      confirm={confirm}
      cancel={close}
      style={{
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

      }}
    >
      是否撤回当前信息
    </Dialog>
  )
}

import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { Messager, Button } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { copyText } from 'rootnet-core/clipboard'
import { Icon } from '../../../../../components'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'
import WorkFlowChangePop from "../../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import {strParams} from "../../../../../utils/publicFun";
import UserSelect from "../../../../common/personSelectPop/UserSelect";

const GLOBAL_CONST_OPTIONS_URLS = [
  '/common/globalconst?globalConst=workflowTodoStatus'
]

export default function TodoListDetailHeader(props) {
  const { editFormData, isDetail, setEditFormData, id, refreshDetail, refreshViewList, updateDynamic, allUserRes } = props
  const [showCopyPop, setShowCopyPop] = useState(false)
  const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const {data: workFlowInfo, doFetch: getStatus} = useGet()
  const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

  const status = useMemo(()=>{
    return _.get(workFlowInfo,'factorId')
  },[workFlowInfo])

  const [workFlowStatusOptions] = useMemo(()=>{
    if(_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  },[globalConstOptionsRes])

  const refreshStatus = useCallback(()=>{
    if(_.isNil(id)) return
    const params = {
      businessType: 'todo',
      businessId: id
    }
    getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
  },[getStatus, id])

  useEffect(()=>{
    refreshStatus()
  },[refreshStatus])

  const workFlowColor = useMemo(()=>{
    return convertOptions(status, workFlowStatusOptions, 'color')
  },[workFlowStatusOptions, status])

  const copy = useCallback((value) => {
    copyText(value);
    setShowCopyPop(false)
    Messager.show('数据已复制到剪切板', { icon: 'success' });
  }, [])

  const CopyArea = useCallback(() => {
    const detailUrl = window.location.host + `/#/todolist?initId=` + _.get(editFormData,'id')
    return <div className={'flex todo-list-title-copy-popover center-y'}>
      <Button onClick={() => copy(_.get(editFormData, 'subject'))} normal>复制标题</Button>
      <Button onClick={() => copy(detailUrl)} normal>复制跟进事项URL</Button>
    </div>
  }, [copy, editFormData])

  return (
    <div className={'todo-list-detail-header flex'}>
      <div className="header-left">
        {/* <div className="req-id-wrap flex center-y">
          <div className="type-tag flex center-y" style={{ background: getTagColor(_.get(editFormData, 'issuesType')) }}>{_.get(editFormData, 'issuesType')}</div>
          <div className="req-id">{_.get(editFormData, 'issueId')}</div>
        </div> */}
        {
          isDetail &&
          <div className="req-title-wrap flex center-y">
            <div className="req-title">
              <Tooltip title={_.get(editFormData, 'subject')}>
                {_.get(editFormData, 'subject')}
              </Tooltip>
            </div>
            <Popover content={<CopyArea />} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
              <Icon name={'fuzhi'} className={'copy-icon'} />
            </Popover>
          </div>
        }
        {
          !isDetail && <FormInput horizontal bind={'subject'} label={'标题'} required value={_.get(editFormData, 'subject')}
            onChange={v => setEditFormData(x => ({ ...x, subject: v }))} maxLength={250} />
        }
      </div>
      <div className="header-right flex center-y">
        <div className="header-right-item flex center-y">
          <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{color: '#165DFF'}}/>
          <div className="header-right-info flex-y">
            {
              isDetail &&
              <div className="header-right-value">
                <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes,'userName','userAccount') || '无'}</div>
              </div>
            }
            {
              !isDetail &&
              <FormInput horizontal value={_.get(editFormData, 'currentUser')} required component={UserSelect} componentWidth={90}
                         onChange={v => setEditFormData(x => ({...x, currentUser: v})) }  />
            }
            <div className="header-right-field">
              处理人
            </div>
          </div>
        </div>
        <Popover destroyTooltipOnHide={true}
                 content={<WorkFlowChangePop businessId={id} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                   refreshStatus()
                   refreshViewList()
                   refreshDetail(id)
                   updateDynamic()
                 }} funcCode={'23'} businessType={'todo'}/>}
                 onOpenChange={visible => {
                   if(!visible){
                     setShowWorkFlowPop(null)
                   }
                 }}
                 trigger={'click'}
                 placement="bottomRight"
                 open={showWorkFlowPop}
        >
          <div className="header-right-item flex center-y status-wrap-item cursor-pointer" onClick={()=>setShowWorkFlowPop(true)}>
            <Icon name={'renling'} className={'header-right-icon'} style={{color: workFlowColor}}/>
            <div className="header-right-info flex-y">
              <div className="header-right-value">
                <div className={'status-tag flex center'} style={{color: workFlowColor, border: `1px solid ${workFlowColor}`,padding: '0 8px', borderRadius: 4}}>
                  {convertOptions(status, workFlowStatusOptions)}
                </div>
              </div>
              <div className="header-right-field">
                当前状态
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

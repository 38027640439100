import React, { useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import cls from 'clsx'
import { Tab, TabItem, Loader, Messager } from 'rootnet-ui'
import { Input } from 'rootnet-edit'
import { AuditTemplate, Processed } from '../common'
import { useGet, useApi } from '../../../utils/hook'
import { parseParams } from '../../../project_share/utils/utils'
import './MetadataMgtMob.scss'

const DATA_TYPE = ['全部', '数据字典', '常量定义', '根网数据类型']

const URLS = [
  '/field/auditList?approvalMode=approval',
  '/fieldDict/auditList?approvalMode=approval',
  '/fieldType/auditList?approvalMode=approval',
  '/field/auditList?approvalMode=pending',
  '/fieldDict/auditList?approvalMode=pending',
  '/fieldType/auditList?approvalMode=pending',
]

export default function MetadataMgtMob() {
  const history = useHistory()
  const tabItem = _.get(parseParams(history.location.search.split('?')[1]), 'type')
  const [params, setParams] = useState('全部')
  const [selectType, setSelectType] = useState(-1)
  const [show, setShow] = useState(false)
  const [tabIndex, setTabIndex] = useState(Number(tabItem) || 0)
  const userPositionList = JSON.parse(localStorage.getItem('userPositionList'))
  const canCheck = _.includes(userPositionList, 'MetadataCheck')

  const { doFetch } = useApi()

  const { data, loading, doFetch: getDoFetch } = useGet(URLS)

  const [auditData, pendingData] = useMemo(() => [_.slice(data, 0, 3), _.slice(data, 3, 6)], [data])

  const pendingList = useCallback((recall) => {
    if (!pendingData) return []
    const arr = _.map(pendingData, (listData, index) => {
      return _.filter(listData, o => {
        o.tabIndex = index
        o.tabName = DATA_TYPE[index + 1]
        o.typeName = typeName(o, index)
        if (recall) return _.includes(['0', '1', '3'], o.auditFlag)
        return Number(o.auditFlag) === 1
      })
    }).flat(1)
    if (_.isNull(selectType) || selectType === -1) return arr
    return _.filter(arr, o => o.tabIndex === selectType)
  }, [pendingData, selectType])

  const auditList = useMemo(() => {
    if (!auditData) return []
    const arr = _.map(auditData, (listData, index) => {
      return _.map(listData, o => {
        o.tabIndex = index
        o.tabName = DATA_TYPE[index + 1]
        o.typeName = typeName(o, index)
        return o
      })
    }).flat(1)
    if (_.isNull(selectType) || selectType === -1) return arr
    return _.filter(arr, o => o.tabIndex === selectType)
  }, [auditData, selectType])

  return (
    <div className='MetadataMgtMob'>
      {
        show && <SearchBox />
      }
      <header className='mes-header'>
        <Tab onChange={setTabIndex} active={tabIndex}>
          <TabItem header='我的待办' />
          <TabItem header='我的申请' />
        </Tab>
      </header>
      <section>
        {loading && <Loader />}
        <div className='queryData'>
          <div className='mask' onClick={() => setShow(true)}></div>
          <Input className='search' placeholder='搜索您的内容' value={params} />
        </div>
        <SwitchPages />
      </section>
    </div>
  )

  function SwitchPages() {

    if (tabIndex) return <AuditTemplate
      btnText='撤回'
      header='提交待审核'
      jumpPage={jumpPage}
      withdraw={withdraw}
      data={pendingList(true)}
    />
    return <div className='MyAgent'>
      <AuditTemplate header='待审核' btnText='审核' jumpPage={jumpPage} data={canCheck ? auditList : []} />
      <Processed jumpPage={jumpPage} pendingData={pendingList()} />
    </div>
  }

  function jumpPage(e, path, data, tabItem = tabIndex) {
    e.stopPropagation()
    data.tabItem = tabItem
    data.type = tabIndex
    if (data) return history.push(`/metadataMgt/${path}?data=${encodeURIComponent(JSON.stringify(data))}`)
    return history.push(`/metadataMgt/${path}`)
  }

  function withdraw(e, params) {
    e.stopPropagation()
    const withdrawData = _.assign({
      isAgree: '3',
      whichPage: params.tabIndex,
      whichOption: '0',
    }, params)

    doFetch('/field/updateAudit', 'post', withdrawData).then(() => {
      Messager.show('撤回成功', { icon: 'success' })
      getDoFetch(URLS)
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
    })
  }

  function typeName(o, index) {
    if (index === 0) return o.fieldName
    if (index === 1) return `${o.fieldDictId}-${o.fieldDictSubCode}`
    if (index === 2) return o.fieldTypeName
  }

  function SearchBox() {
    return <div className='search-Box'>
      <div className='queryData'>
        <Input className='search' value={params} />
        <span onClick={() => setShow(false)}>取消</span>
      </div>
      <Tab active={1}>
        <TabItem header='综合' disabled ></TabItem>
        <TabItem header='类型' >
          <ul className='type-box'>
            {
              _.map(DATA_TYPE, (v, i) => {
                return <li
                  key={v}
                  className={cls({ selection: i - 1 === selectType })}
                  onClick={() => searchName(i)}>
                  {v}
                </li>
              })
            }
          </ul>
        </TabItem>
      </Tab>
    </div>
  }

  function searchName(index) {
    setShow(false)
    setParams(DATA_TYPE[index])
    setSelectType(index - 1)
  }

}

import React, { useState, useMemo, useEffect, useCallback } from 'react'
import _ from 'lodash'
import clsx from "clsx";
import { Select, Tooltip } from "antd";
import { Button } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { formList, OPTIONS_URLS, GLOBAL_CONST_URLS } from '../../addPlanVersion/formList'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import { isNil } from '../../../../../appraise/components/method'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import { successTips, errorTips } from '../../../../../../utils/messagerTips'
import gd from '../../../../../../base/global'
import convertOptions from "../../../../../common/ConvertOptions";
import usePosition from "../../../../../common/hooks/usePosition";
import { Icon } from '../../../../../../components';
import './index.scss'

const EDIT_URL = '/releaseinfo/update'

const HFormInput = props => <FormInput horizontal labelWidth={95} componentWidth={240} {...props} />

export default function PlanVersionSetting(props) {
  const { defaultData, versionOpt, refresh, functionarys, allUserRes } = props
  const [params, setParams] = useState({})
  const [error, setError] = useState()
  const [mode, setMode] = useState('detail')
  const [isDisable, setIsDisable] = useState(false)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstRes } = useGet(GLOBAL_CONST_URLS)
  const { doFetch } = usePost()

  const [editAuth] = usePosition(['ALL'])

  const isDetail = useMemo(() => mode === 'detail', [mode])
  const { releasebasic } = useMemo(() => params || {}, [params])

  const [productLineOpt, typeOpt, natureOpt, stateOpt] = useMemo(() => {
    if (_.isEmpty(globalConstRes)) return []
    return _.map(globalConstRes, v => convertGlobalConstOptions(v))
  }, [globalConstRes])

  const [customerSystemOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes
    return [
      _.concat([{ label: '全部客户', text: '全部客户', value: 'ALL' }], _.map(d1, o => ({ label: o.projectName, text: o.projectName, value: o.projectId }))),
    ]
  }, [optionsRes])

  const versionListOpt = useMemo(() => {
    let addDefault = []
    if (_.isEmpty(versionOpt)) return []
    const filterData = _.filter(versionOpt, o => !isNil(o.value))
    if (!isNil(releasebasic) && _.isNil(_.find(filterData, o => o.value === releasebasic))) {
      addDefault = [{ text: releasebasic, value: releasebasic }]
    }
    return _.concat(filterData, addDefault)
  }, [versionOpt, releasebasic])

  const formData = useMemo(() => {
    return formList({ productLineOpt, typeOpt, natureOpt, stateOpt, versionListOpt, customerSystemOpt, allUserRes, mode })
  }, [productLineOpt, typeOpt, natureOpt, stateOpt, versionListOpt, customerSystemOpt, allUserRes, mode])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const submit = useCallback(() => {
    if (!check()) return errorTips('请填写必填项')
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.assign({}, params, { versionType: '1', functionarys: _.join(params.functionarys, ',') },
      // !_.isNil(params?.mulcustid) && { mulcustid: _.join(params.mulcustid, ',') }
    )
    doFetch(EDIT_URL, _.omit(postParams, ['sdf']))
      .then(() => {
        setMode('detail')
        successTips('编辑成功')
        setIsDisable(false)
        refresh()
      })
      .catch(err => {
        setIsDisable(false)
        errorTips(err._message)
      })
  }, [check, doFetch, params, refresh, isDisable])

  const handChange = useCallback((o, bind) => {
    if (bind === 'mulcustid') {
      if (_.includes(o[bind], 'ALL')) {
        o[bind] = ['ALL']
      }
    }
    setParams(o)
  }, [])

  useEffect(() => {
    if (isNil(defaultData)) return
    const initParams = _.assign({}, defaultData, !isNil(defaultData.functionarys) && { functionarys: _.split(defaultData.functionarys, ',') })
    setParams(initParams)
  }, [defaultData])

  return (
    <div className='plan-version-setting'>
      {
        (editAuth || _.includes(functionarys, gd.User.operator_id)) &&
        <div className='header flex' style={{ justifyContent: 'space-between', padding: 8 }} >
          <div />

          <TextIconBtn
            icon='bianji2'
            style={{ display: 'inline-block' }}
            text={mode === 'detail' ? '进入编辑' : '退出编辑'}
            onClick={() => {
              refresh()
              setMode(x => x === 'detail' ? 'edit' : 'detail')
            }}
          />
        </div>
      }
      <Form value={params} onChange={handChange} error={error} onError={setError}>
        {
          _.map(formData, item => {
            if (!_.isNil(item?.icon)) {
              return <>
                <HFormInput key={item.bind} {...item} />
                <Tooltip title={item?.title}>
                  <span>
                    <Icon name={item.icon} style={{ fontSize: 14, color: '#5477FF' }} />
                  </span>
                </Tooltip>
              </>
            }
            return <HFormInput key={item.bind} {...item} componentWidth={item.bind === 'releasename' ? 591 : 240} />
          })
        }
        <div className='mulcust-select-style'>
          <label style={{ width: 95 }}>发布客户系统</label>
          {
            !isDetail &&
            <Select
              className={clsx({ 'mulcustid-style-color': _.isEmpty(_.compact(_.split(_.get(params, 'mulcustid'), '^'))) })}
              mode='multiple'
              placeholder='请选择发布客户系统'
              style={{ width: '592px' }}
              allowClear
              options={customerSystemOpt}
              optionFilterProp='label'
              value={_.compact(_.split(_.get(params, 'mulcustid', ''), ','))}
              onChange={v => {
                const size = _.size(v) - 1
                let val = v[size] === 'ALL' ? ['ALL'] : v
                if (_.includes(v, 'ALL') && v[size] !== 'ALL') {
                  val = _.filter(val, k => k !== 'ALL')
                }
                setParams(x => _.assign({}, x, { mulcustid: _.join(val, ',') }))
              }}
            />
          }
          {
            isDetail &&
            _.join(_.map(_.split(_.get(params, 'mulcustid'), ','), val => convertOptions(val, customerSystemOpt)), '，')
          }
        </div>
      </Form>
      {
        !isDetail &&
        <div className='footer'>
          <Button disable={!check()} primary onClick={submit}>保存</Button>
          <Button onClick={() => {
            refresh()
            setMode('detail')
          }}>取消</Button>
        </div>
      }
    </div >
  )
}

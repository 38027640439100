import React, { Fragment, useMemo, useState, useRef, useEffect, useCallback } from "react";
import _ from 'lodash';
import { Select, Dialog, DatePicker, TextInput as Input, InputGroup, Messager, ScrollViewer, Button } from 'rootnet-ui';
import { GetTime, GetDate } from 'rootnet-core/format';
import { useGet } from "../../../utils/hook";
import { Icon } from '../../../components';
import API1 from "../../../base/task";
import './Edit.scss';

const urls = ["/common/globalconst?globalConst=devMileStone", "/common/globalconst?globalConst=mileStoneStatus"];
const titleHead = [
    { name: '节点', className: 'required' },
    { name: '计划完成时间', className: 'required small' },
    { name: '状态', className: 'required small' },
    { name: '实际完成时间', className: 'small' },
    { name: '备注' },
]
let num = 0;

function ListHeader(props) {
    return <div className="row_item_list title x">
        <div className="left">
            {
                _.map(props.data, (item, index) => {
                    return <div className={`x-forminput ${item.className}`} key={index}>
                        <label className="input-label">{item.name}</label>
                    </div>
                })
            }
        </div>
    </div>
}

function Edit(props) {
    const { title, cancel, confirm, product, loading, error } = props;
    const { data } = useGet(urls);
    const [datas, setDatas] = useState([]);
    const groupRef = useRef();
    const datasHandle = useCallback((type, value, index) => {
        let _datas = _.map(datas, (item, i) => {
            if (i === index) item[type] = value;
            return item;
        })
        setDatas(_datas);
    }, [datas]);

    const [ops_mileStoneType, ops_deadlineTime, ops_status] = useMemo(() => {
        if (!_.isArray(data)) return [];
        const [d1, d2] = data;
        const ops_deadlineTime = { available: { begin: GetDate(product.beginDate), end: GetDate(product.completeDate) } }
        const ops_mileStoneType = _.map(d1, o => ({ text: o.displayName, value: o.interiorId }));
        const ops_status = _.map(d2, o => ({ text: o.displayName, value: o.interiorId }));
        return [ops_mileStoneType, ops_deadlineTime, ops_status]
    }, [data, product]);

    useEffect(() => {
        num = 0;
        let _obj = _.map(props.data, item => {
            item.num = num;
            num++;
            return item;
        })
        setDatas(_obj.reverse())
    }, [props.data]);

    return <Dialog className="milepost-edit x"
        header={title}
        cancel={cancel}
        confirm={commits}
        loading={loading}
        error={error}
        footerVisible={!_.isEmpty(datas)}
    >

        <div className="tips">tips：基准清单封板和上线节点必须设置</div>
        {
            _.isEmpty(datas) && !loading &&
            <div className="tips-con">
                <div className="con-tit">您还没设置任何里程碑，请点击下方按钮添加</div>
                <Button primary onClick={accountAdd}>添加里程碑</Button>
            </div>
        }
        {
            !_.isEmpty(datas) &&
            <Fragment>
                <ListHeader data={titleHead} />
                <ScrollViewer className='x dialog-scroll' style={{ width: 'auto', flex: '1' }}>
                    <InputGroup ref={groupRef}>
                        {
                            _.map(datas, (item, index) => {
                                return <div className="row_item_list x" key={item.num}>
                                    <div className="left">
                                        <div className='x-forminput'>
                                            <Select
                                                required
                                                name={`mileStoneType${index}`}
                                                options={ops_mileStoneType}
                                                value={item.mileStoneType}
                                                onChange={(e, o) => datasHandle('mileStoneType', _.get(o, 'value'), index)}
                                            />
                                        </div>
                                        <div className='x-forminput small'>
                                            <DatePicker
                                                required
                                                name={`deadlineTime${index}`}
                                                option={ops_deadlineTime}
                                                value={GetDate(item.deadlineTime)}
                                                onChange={(e, o) => datasHandle('deadlineTime', GetTime(e), index)}
                                            />
                                        </div>
                                        <div className='x-forminput small'>
                                            <Select
                                                required
                                                name={`status${index}`}
                                                options={ops_status}
                                                value={item.status}
                                                onChange={(e, o) => datasHandle('status', _.get(o, 'value'), index)}
                                            />
                                        </div>
                                        <div className='x-forminput small'>
                                            <DatePicker
                                                required = {item.status === 'Close'}
                                                name={`completeDate${index}`}
                                                value={GetDate(item.completeDate)}
                                                onChange={(e, o) => datasHandle('completeDate', GetTime(e), index)}
                                            />
                                        </div>
                                        <div className='x-forminput' title={item.memo}>
                                            <Input
                                                name={`memo${index}`}
                                                placeholder="请输入"
                                                value={item.memo}
                                                onChange={(e, o) => datasHandle('memo', e, index)}
                                            />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <Icon name='yichu' style={{ color: 'red', marginRight: 8 }}
                                            onClick={o => { accountCut(o, index) }}
                                        />
                                        {
                                            datas.length - 2 < index &&
                                            <Icon name='zengjia' style={{ color: '#1890FF' }} onClick={o => accountAdd(item, index)} />
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </InputGroup>
                </ScrollViewer>
            </Fragment>
        }
    </Dialog>;

    function accountCut(o, index) {
        let _datas = _.filter(datas, (item, i) => {
            return i !== index;
        })
        setDatas(_datas);
    }

    // 1: 没有数据   添加节点信息 为 基准清单封板和上线的节点
    // 2：有数据     添加上条数据的节点信息
    function accountAdd(o, index) {
        let _obj = [];
        if(_.isEmpty(datas)){
            _obj = [
                { num, mileStoneType: 'BenchmarkSeal', status: 'Open' },
                { num: num + 1, mileStoneType: 'ProductsOnline', status: 'Open' }
            ]
            num = num + 2;
        }else{
            _obj = [ {num, mileStoneType: o.mileStoneType, status: 'Open'} ]
            num++;
        }
        setDatas(_.concat([], datas, _obj))
    }

    function check(){
        // 1 必填校验
        const inputMap = ['mileStoneType', 'deadlineTime', 'status'];
        let validate = groupRef.current.validate();
        let tips = ['请补全信息后提交', '基准清单封板节点和上线节点必须存在且不可重复'];
        let benchTimes = 0;
        let prodTimes = 0;
        datas.some((it, ind) => {
            return inputMap.some((ite, inde) => {
                let flag = groupRef && groupRef.current.getInput(`${ite}${ind}`) && groupRef.current.getInput(`${ite}${ind}`).error;
                flag = flag ? true : false;
                let _dom = groupRef.current.getInput(`${ite}${ind}`);
                if (_.get(_dom, 'view.current')) {
                    _dom.view.current.scrollIntoView({ block: 'center' });
                }
                return flag;
            })
        })
        if(!validate){
            Messager.show(tips[0], { icon: 'error' });
            return validate
        }

        // 2 基准清单封板和上线 状态必须存在  且不可重复
        _.forEach(datas, item => {
            if(item.mileStoneType === 'BenchmarkSeal') benchTimes++;
            if(item.mileStoneType === 'ProductsOnline') prodTimes++;
        })
        if(benchTimes !== 1 || prodTimes !== 1){
            validate = false;
            Messager.show(tips[1], { icon: 'error' });
        }

        return validate;
    }

    function commitAjax(){
        // 新旧数据比较
        let params = _.forEach(datas, (item, index) => {
            // 代表新增
            if(item.num > props.data.length - 1){
                item.iid = product.id;
                item.projectType = 'DEV';
                item.actionType = 'ADD';
            }else{
                // 代表编辑
                item.actionType = 'EDIT';
            }
        })
        // 处理删除数据
        _.forEach(props.data, (item, index) => {
            let _has = _.some(datas, item2 => {
                return item2.num === index;
            })
            if(!_has) params.push(_.assign({}, item, {actionType: 'DEL'}))
        })
        return API1.post('/common/milestone/edit', params)
    }

    function commits() {
        if(!check()) return;
        commitAjax().then(res => {
            Messager.show(res.msg, { icon: 'success' });
            confirm();
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
        })
    }
}

export default Edit;

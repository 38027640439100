//node_modules
import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Card, DataGrid, MessageBox, Messager } from 'rootnet-ui';
import { Dateformat, isNil } from 'rootnet-core/format'
import { hasData } from 'rootnet-core/system'

//Local_modules
import { useGet } from '../../../utils/hook';
import API from '../../../base/task';
import { IconButton, Nodata } from '../../../components';
import { TaskDialog } from '../TaskDialog'

//scss_style
import '../TaskList.scss';

const url_list = 'develop/project/task/list'
const issueState = ['进行中', '关闭', '暂缓']

export default function Panel(props) {
    const { param, data, loading, error, data_panel: data_New } = props
    const history = useHistory()
    const projectId = _.get(param, 'projectId')
    const [current, setCurrent] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [id, setId] = useState('');
    const { doFetch } = useGet();


    const datagrid_option = {
        columns: [
            {
                header: "#", width: 40, align: "center", convert: (v, o, i) => i + 1
            },
            {
                header: "操作", align: "center", width: 150,
                convert: (v, o) => {
                    return <div className="operations">
                        <span onClick={(e) => do_operation(e, o, "detail")}>详情</span>
                        <span onClick={(e) => do_operation(e, o, "modify")}>修改</span>
                        <span onClick={(e) => do_operation(e, o, "delete")}>删除</span>
                        {/* <span onClick={(e) => do_operation(e, o, "create")}>建单</span> */}
                    </div>;
                }
            },
            { header: "任务", bind: "title", width: 300, tooltip: true, align: 'left' },
            { header: "负责人", bind: "pricipalName", width: 84, align: 'left' },
            { header: "截止日期", bind: "testCompleteDate", width: 112, align: 'left', sortable: true, convert: (v) => Dateformat(v) },
            { header: "状态", bind: "issueState", width: 70, convert: (v) => issueState[v] },
            { header: "客户", bind: "bondproviderName", width: 136, align: 'left', tooltip: true },
            { header: "产品", bind: "productName", width: 180, align: 'left', tooltip: true },
            { header: "研发任务ID", bind: "tracerId", width: 185, align: 'left' },
            { header: "研发任务状态", bind: "tracerState", width: 170, align: 'left' },
            { header: "需求ID", bind: "interiorReqNo", width: 185, align: 'left' },
            { header: "任务ID", bind: "id", width: 185, align: 'left' },

        ],
        autoFill: true,
        selection: true,
        fixedLeft: 3
    };
    const fresh = useCallback(() => {
        doFetch(`${url_list}?projectId=${projectId}&issueStateList=0`)
    }, [projectId, doFetch])

    function do_operation(e, target, type) {
        e.preventDefault();
        e.stopPropagation();
        const id = _.get(target, 'id')
        setId(id)
        if (type === 'delete') {
            setCurrent(target)
            !isNil(id) ? Messager.show('已关联研发任务，不允许删除', { icon: 'warn' }) : setShowMsg(true)
        }
        if (type === 'modify') {
            setShowEditDialog(true)
            doFetch(`${url_list}?id=${id}`)
        }
        if (type === 'detail') {
            history.push(`task/detail/${id}`)
        }


    }
    function delItem() {
        const id = _.get(current, 'id')
        API.post('develop/project/task/delete', { id }).then(res => {
            setShowMsg(false)
            fresh()
            Messager.show('删除成功', { icon: 'success' })
        }).catch((err) => Messager.show(err._message, { icon: 'error' }))
    }

    const extra = useMemo(() => [
        <IconButton key="add" icon="zengjia" circle onClick={() => setShowDialog(!showDialog)} />,
        <IconButton icon="daochu" key="export" circle />], [showDialog]);

    return <div className="list-panel">
        <Card title="任务明细" extra={extra} loading={loading} error={error}>
            {hasData(data)
                ? <DataGrid option={datagrid_option} data={data} />
                : <Nodata />
            }
        </Card>
        {showDialog && <TaskDialog header="新建任务"
            cancel={() => setShowDialog(false)}
            mode='add'
            param={param}
            data_New={data_New}
            fresh={fresh}
        />}

        {showEditDialog && <TaskDialog header="修改任务"
            cancel={() => setShowEditDialog(false)}
            mode='modify'
            param={param}
            data_New={data_New}
            id={id}
            fresh={fresh}
        />}

        {showMsg && < MessageBox header="提示" confirm={delItem} cancel={() => { setShowMsg(false) }}> 确定要删除“{_.get(current, 'title')}”</MessageBox>}
    </div >
}

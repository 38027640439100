import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { isNil } from 'rootnet-core/format'
import { Form, FormInput, Select, DatePicker, Input, TextArea, RadioGroup, Display, CheckBox } from 'rootnet-edit'
import { Messager } from 'rootnet-ui'
import convertOptions from '../../../../../../../../../common/ConvertOptions'
import UserSelect from '../../../../../../../../../common/personSelectPop/UserSelect'
import HeadTag from '../../../../../../headTag'
import { statusOpt, preserveOpt } from '../../../commonData'

const { str14ToDate } = toDate

const HFormInput = (props) => <FormInput horizontal labelWidth={135} componentWidth={200} {...props} />

const listForm = (props) => {
  const { isDetail, versionTypeOpt, allUserRes } = props

  return [
    { required: true, label: '制作类型', bind: 'productType', component: Display, options: versionTypeOpt, bindInConvert: v => _.trim(v), convert: v => convertOptions(_.trim(v), versionTypeOpt) },
    { required: true, label: '制作人', bind: 'producter', component: UserSelect, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      required: true, label: '时间要求', bind: 'completeDate', component: DatePicker, bindInConvert: v => isDetail ? v : str14ToDate(v),
      bindOutConvert: v => dateFormat('YYYYMMDDHHMMSS', v),
      convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }
    },
    { required: true, label: '制作次数', bind: 'imakeTimes', component: Input, type: 'number', digit: 0, min: 0 },
    {
      required: true, label: '花费时间', bind: 'imakeTime', component: Input, type: 'number', digit: 0, min: 0, componentWidth: 160, suffix: '小时', convert: v => {
        if (isNil(v)) return '-'
        return `${v}小时`
      }
    },
    { required: true, label: '前一版本号', bind: 'previousRelease', component: Input, componentWidth: 551 },
    { required: true, label: '本次版本号', bind: 'thisRelease', component: Input, componentWidth: 551 },
    { required: true, label: '版本制作基础', bind: 'makeBasic', component: Input, componentWidth: 551 },
    { label: '备注', bind: 'memo', component: TextArea, componentWidth: 551, className: 'text-area-detail', maxLength: 500 },
    { required: true, label: '备份文件是否保存', bind: 'saveCode', component: RadioGroup, options: preserveOpt },
    { label: '代码保存位置', bind: 'codePosition', component: Input, componentWidth: 551 },
    { required: true, label: '复核人', bind: 'reviewProducter', component: UserSelect, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { label: '复核状态', bind: 'reviewResult', component: Select, options: statusOpt, convert: v => convertOptions(_.trim(v), statusOpt) },
    { label: '复核时间', bind: 'reviewTime', component: Display },
    { label: '复核内容', bind: 'reviewInfo', component: TextArea, componentWidth: 551, className: 'text-area-detail', maxLength: 99999, counter: false },
    { label: '是否发送消息', bind: 'sendEmail', component: CheckBox, componentWidth: 20, },
  ]
}

export default function VersionMakingInfo(props) {
  const { isDetail, editFormData, setEditFormData, editFormError, setEditFormError, versionTypeOpt, allUserRes, productTypeList } = props

  const formData = useMemo(() => {
    return listForm({ isDetail, versionTypeOpt, allUserRes })
  }, [isDetail, versionTypeOpt, allUserRes])

  const onChange = useCallback((o, k) => {
    if (k === 'productType') {
      const productList = _.map(productTypeList, o => o.value)
      if (!_.includes(productList, o[k])) {
        setEditFormData(x => x)
        Messager.show('无法选择列表已有数据', { 'icon': 'error' })
        return
      }
    }
    setEditFormData(o)
  }, [productTypeList, setEditFormData])

  return (
    <div className='version-making-info'>
      <Form value={editFormData} onChange={onChange} error={editFormError} onError={setEditFormError}>
        {
          _.map(formData, o => {
            if (isDetail && o.bind === 'sendEmail') return
            if (o.bind === 'reviewProducter') {
              return <React.Fragment key={o.bind}>
                <HeadTag text={'版本复核相关信息'} />
                <HFormInput {...o} component={isDetail ? Display : o.component} />
              </React.Fragment>
            }
            return <HFormInput key={o.bind} {...o} component={isDetail ? Display : o.component} />
          })
        }
      </Form>
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Dialog, Messager, Loader, Button, DataGrid } from 'rootnet-ui';
import { FormInput, Form, Display, Input, RadioGroup, CheckBox as RootCheckBox } from 'rootnet-edit';
import './DefectUpdateDialog.scss'
import _ from "lodash";
import { Popover, Tabs, Tooltip, Image, Checkbox } from 'antd';
import { Icon } from "../../../../components";
import { useApi } from "../../../../utils/hook";
import DefectUploadArea from "./DefectUploadArea";
import convertOptions from "../../../common/ConvertOptions";
import { useGet } from "rootnet-biz/es/hooks";
import TestCaseDataGrid from "./TestCaseDataGrid";
import PopoverComment from "./PopoverComment";
import { selectOption, useFuncCode } from "../../../common/commonMethod";
import ChangeRecord from '../../../common/ChangeRecord';
import AssociatedTestcase from './AssociatedTestcase';
import Disassociation from './Disassociation';
import { TextIconBtn } from "../../../common/TextIconBtn";
import DefectUpdateHeader from "./defectUpdateHeader/DefectUpdateHeader";
import ReqViewSearchDialog
    from "../../../customerServiceInfo/customerServiceInfoDetailDialog/customerServiceBaseInfo/reqViewSearchDialog/ReqViewSearchDialog";
import CascadeSelect from "../../../common/cascadeSelect/CascadeSelect";
import { isNil } from "../../../appraise/components/method";
import RequirementDetailDialog from "../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import ViewQueryDialog from '../../../common/view/viewQueryDialog/ViewQueryDialog';
import IssueDetailDialog from '../../../issueMgt/components/issueDetailDialog/IssueDetailDialog';
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import clsx from "clsx";
import TrackingAddDialog from "../../../trackingManagementMgt/components/trackingAddDialog/TrackingAddDialog";
import DefectTracerView from "./defectTracerView/DefectTracerView";
import DisplaySelect from "../../../common/displaySelect/DisplaySelect";
import DevListDetailDialog from '../../../devListMgt/devListDetailDialog'
import gd from "../../../../base/global";
import usePosition from "../../../common/hooks/usePosition";
import { usePost } from "rootnet-biz/lib/hooks";
import DefectContent from './defectContent';
import PublicUseCase from '../../../common/publicPage/publicUseCase';
import CodeCommitList from "../../../common/codeCommitList/CodeCommitList";
import UserSelect from '../../../common/personSelectPop/UserSelect';

const YNOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const dialogHeader = {
    add: '新增缺陷',
    edit: '修改缺陷',
    detail: '缺陷详情'
}

const APPLY_PERMISSION = '1902' //申请权限
const QA_CHECK_PERMISSION = '1910' //QA检测功能权限

const { TabPane } = Tabs;

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSeverity',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectType',
    // '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseID&tableName=View_ReleaseInfo_Version&filter=State',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectOriginPhase',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectRecurrence',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSource',
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSolutionResult',
    '/UserSetting/getUniversalInterfaces?code=projectId&codeName=projectName&tableName=bug_project',
]

const optionsUrl = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/userProject/list',
]

const createTracerTips = <div>
    1.仅历史问题，并且非inner修复版本未发布时可以创建研发任务<br />
    2.是否为历史缺陷需要在开发人员定位流程中标识<br />
    3.非流程中的历史缺陷标识，仅研发组长可修改<br />
    4.已创建研发任务的缺陷历史标识任何人不允许修改<br />
    5.迭代/需求验收测试，默认非历史问题
</div>

function RecurrenceTips(props) {
    const option = {
        columns: [
            { header: '枚举', bind: 'enum', width: 110 },
            { header: '说明', bind: 'explain', width: 420 },
        ],
        // autoFill: true,
        resizable: true,
        virtualized: false,
        nilText: '-',
        emptyText: '-',
    }
    const data = [
        { enum: '必现', explain: '每一次测试都出现' },
        { enum: '有条件概率重现', explain: '在测试过程中出现三次（包括三次）以上的认定为有条件概率重现问题' },
        { enum: '小概率重现', explain: '在测试过程中出现两次的问题认定为无规律重新问题' },
        { enum: '很难重现', explain: '测试过程中仅出现一次，且在一个月内未重现的问题认定为很难重现问题' },
    ]
    return <DataGrid data={data} option={option} />
}

const HFormInput = (props) => <FormInput {...props} labelWidth={110} componentWidth={190} horizontal />

function DefectUpdateDialog(props) {
    const { mode: initMode, setCurrentId, close, currentId, refreshList = () => { }, replaceParams, switchCurrentItem,
        showChildList, currentIndex, addDefaultParams, defaultDescription, executiveOutcomes, ADD_PICK } = props
    const [params, setParams] = useState(addDefaultParams || {})
    const { data: formData, doFetch: getDetail } = useGet()
    const [showSearchDialog, setShowSearchDialog] = useState()
    const { doFetch } = useApi()
    const richEditorRef = useRef()
    const [loading, setLoading] = useState(false)
    const { data: allUserRes, loading: userLoading } = useGet('/common/userinfo')
    const [commentText, setCommentText] = useState()
    const [initDiscoveryProject, setInitDiscoveryProject] = useState()
    const [commentPopoverVisible, setCommentPopoverVisible] = useState(false)
    const [relation, setRelation] = useState(false)
    const [disassociation, setDisassociation] = useState(null)
    const [imageVisible, setImageVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
    const [countTracer, updateTracer] = useReducer((x) => x + 1, 0)
    const { data: templateRes, doFetch: getTemplate } = useGet()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [continueAdd, setContinueAdd] = useState(false)
    const [isFuncCodeApply, isFuncCodeQaCheck, editHistoryQuestionFieldAuth, showCodeCommitTab]
        = useFuncCode([APPLY_PERMISSION, QA_CHECK_PERMISSION, '050410', '050415'])
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(optionsUrl)
    const [formError, setFormError] = useState()
    const [mode, setMode] = useState(initMode || 'detail')
    const [showAddTrack, setShowAddTrack] = useState(false)
    const [statusName, setStatusName] = useState()
    const [editReleaseIdAuth, isASM] = usePosition(['QXXG', 'ASM'])

    const { doFetch: getRelease } = usePost()
    const [releaseOptions, setReleaseOptions] = useState([])
    const [fixVersionOptions, setFixVersionOptions] = useState([])
    const [caseParams, setCaseParams] = useState()

    const { productId, caseId, planId, realCaseId, caseName, realPlanId, planName } = useMemo(() => params || {}, [params])
    useEffect(() => {
        setCaseParams({ caseId, realCaseId, caseName, planId, realPlanId, planName })
    }, [caseId, planId, realCaseId, caseName, realPlanId, planName])

    const [severityOptions, typeOptions, originPhaseOptions, recurrenceOptions, sourceOptions, solutionResultOptions, customOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const recurrenceOpt = useMemo(() => {
        return _.filter(recurrenceOptions, o => o.value !== '05')
    }, [recurrenceOptions])

    const [productTreeOptions, productOptions, moduleOptionsRes, allDevProjectOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4] = optionsRes
        const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        const productOptions = _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        return [productTreeOptions, productOptions, d3,
            _.map(d4, x => ({ text: x.projectName, value: x.projectID, status: x.status }))
        ]
    }, [optionsRes])

    const effectiveProject = useMemo(() => {
        const isAdd = mode === 'add'
        const options = _.filter(allDevProjectOptions, x => !_.includes(['1', '7'], x.status))
        const fixProjectList = isAdd ? _.get(addDefaultParams, 'fixProjectIdList') : _.get(formData, 'fixProjectIdList')
        const concatData = _.filter(allDevProjectOptions, o => {
            if (isAdd) return (_.includes(['1', '7'], o.status) && _.includes(fixProjectList, o.value) && (o.text === '无项目'))
            return (_.includes(['1', '7'], o.status) && _.includes(fixProjectList, o.value))
        })
        return _.sortBy(_.concat([], concatData, options), x => {
            return !_.includes(fixProjectList, x.value)
        })

    }, [allDevProjectOptions, formData, mode, addDefaultParams])


    const getReleaseOptions = useCallback((id) => {
        getRelease('/releaseinfo/getVersionWithProject', [id]).then(res => {
            setReleaseOptions(_.map(res, x => ({
                text: x.releasename,
                value: x.releaseid,
                state: x.state,
                // displayFlag: x.state === 'V' ? 'N': 'Y',
            })))
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    }, [getRelease])

    const getFixVersionOptions = useCallback((fixProjectList) => {
        getRelease('/releaseinfo/getVersionWithProject', fixProjectList).then(res => {
            setFixVersionOptions(_.map(res, x => ({
                text: x.releasename,
                value: x.releaseid,
                state: x.state,
                displayFlag: _.includes(['T', 'C'], x.state) ? 'Y' : 'N',
                _disable: _.includes(['R', 'L'], x.state)
            })))
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    }, [getRelease])

    // 发现版本数据源，如果初始值没有发现项目，数据源取所有版本
    useEffect(() => {
        if (mode !== 'add') return
        const initDiscoveryProject = _.get(addDefaultParams, 'projectId')
        if (_.isEmpty(initDiscoveryProject)) {
            setReleaseOptions([])
        } else {
            getReleaseOptions(initDiscoveryProject)
        }
    }, [addDefaultParams, getReleaseOptions, mode])

    // 修复版本数据源，如果初始值有修复项目，数据源限定为初始 修复项目 的下所属版本
    useEffect(() => {
        if (mode !== 'add') return
        const initFixProject = _.get(addDefaultParams, 'fixProjectIdList')
        if (_.isEmpty(initFixProject)) {
            setFixVersionOptions([])
        } else {
            getFixVersionOptions(initFixProject)
        }
    }, [addDefaultParams, mode, getFixVersionOptions])

    const isIterate = useMemo(() => {
        return _.get(params, 'originPhase') === '03'
    }, [params])

    const isHistoryDefects = useMemo(() => _.get(params, 'historyQuestion') === 'Y', [params])

    const canEditReleaseId = useMemo(() => {
        return (gd.User.operator_id === params?.createUser && statusName === '新建') || editReleaseIdAuth || mode === 'add'
    }, [params, statusName, editReleaseIdAuth, mode])

    const canEditSeverity = useMemo(() => {
        if (mode === 'add') return true
        return (_.includes(['新建', '测试经理审核'], statusName) || (statusName === '技术裁决委员会裁决' && isASM))
    }, [statusName, isASM, mode])

    const isToPublicEdit = useMemo(() => {
        return statusName === '开发组长审核' && formData?.currentUser === gd.User.operator_id
    }, [statusName, formData])

    const handleOriginPhaseOptions = useMemo(() => {
        if (mode === 'add') return originPhaseOptions
        return _.map(originPhaseOptions, x => {
            if (_.includes(['03'], _.get(formData, 'originPhase')) && x.value !== '03') {
                return _.assign({}, x,)
            } else if (!_.includes(['03'], _.get(formData, 'originPhase')) && x.value === '03') {
                return _.assign({}, x,)
            } else if (_.get(params, 'canEditOriginPhase') === true && x.value === '03') {
                return _.assign({}, x,)
            }
            return x
        })

        // if (_.get(params, 'canEditOriginPhase') !== false) return originPhaseOptions
        // return _.map(originPhaseOptions, x => ({
        //     _disabled: x.value === '03',
        //     ...x
        // }))
    }, [originPhaseOptions, params, mode, formData])

    useEffect(() => {
        if (mode !== 'add') return
        if (!_.isNil(defaultDescription)) return
        getTemplate('/issue/getDemoTxt?id=85').then(res => {
            const template = _.get(res, 'demoValue')
            if (richEditorRef.current) {
                setTimeout(() => richEditorRef.current.setContent(template || ''), 600)
            }
        })
    }, [getTemplate, mode, defaultDescription])

    const refreshDetail = useCallback((id) => {
        setLoading(true)
        getDetail('/test_defect/get/testDefect/info?defectId=' + id, 'get').then(res => {
            setParams(res)
            setInitDiscoveryProject(_.get(res, 'projectId'))
            if (!_.isEmpty(_.get(res, 'fixProjectIdList'))) {
                getFixVersionOptions(_.get(res, 'fixProjectIdList'))
            }
            if (!_.isEmpty(_.get(res, 'projectId'))) {
                getReleaseOptions(_.get(res, 'projectId'))
            }
            if (richEditorRef.current) {
                setTimeout(() => {
                    richEditorRef.current?.setContent(_.get(res, 'description') || '')
                }, 600)
            }
            setLoading(false)
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
            setLoading(false)
        })
    }, [getDetail, getFixVersionOptions, getReleaseOptions])

    useEffect(() => {
        if (mode === 'add') {
            if (!_.isNil(defaultDescription)) {
                if (!_.isNil(richEditorRef.current.getContent())) return
                if (richEditorRef.current) {
                    setTimeout(() => richEditorRef.current.setContent(defaultDescription), 600)
                }
            }
            setParams(x => ({ status: "new", priority: '02', severity: '03', recurrence: '01', ...x, }))
            return
        }
        if (!_.isNil(currentId)) {
            refreshDetail(currentId)
        }
    }, [mode, defaultDescription, currentId, refreshDetail])

    useEffect(() => {
        if (mode === 'add') {
            doFetch('/test_case/productGetOnlyId', 'get').then(res => {
                setParams(x => ({ ...x, id: res }))
            })
        }
    }, [doFetch, mode])

    const confirmButtonDisabled = useMemo(() => {
        if (isNil(_.get(params, 'title'))) return true
        if (mode !== 'add' && isNil(_.get(params, 'currentUser'))) return true
        return _.some(formError, v => !_.isNil(v))
    }, [params, mode, formError])

    const switchMode = useCallback((resetDesc = false) => {
        if (resetDesc) {
            if (richEditorRef.current) {
                richEditorRef.current.setContent(_.get(formData, 'description') || '')
            }
        }
        // setParams(formData)
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [formData, setMode])

    const continueClear = useCallback(() => {
        doFetch('/test_case/productGetOnlyId', 'get').then(res => {
            setParams(oldObj => {
                let cloneObj = _.clone(oldObj)
                cloneObj['description'] = _.get(templateRes, 'demoValue') || ''
                cloneObj['title'] = null
                cloneObj['id'] = res
                return cloneObj
            })
        })
        if (richEditorRef.current) {
            richEditorRef.current.setContent(_.get(templateRes, 'demoValue') || '')
        }
    }, [templateRes, doFetch])

    const associatedTestcase = useCallback((parameter, planId) => {
        const id = currentId
        doFetch('/test_defect/updateRelevance', 'post', [{ id, caseId: parameter, planId }])
            .then(() => {
                Messager.show('成功', { icon: 'success' })
                setParams(x => _.assign({}, x, { caseId: parameter }))
            })
            .catch((err) => Messager.show(err._message, { icon: 'error' }))
    }, [currentId, doFetch])

    const submit = useCallback(() => {
        const onlyList = _.filter([
            !isNil(_.get(params, 'issueIdName')),
            !isNil(_.get(params, 'interiorReqNo')),
            !isNil(_.get(params, 'extensionTestDiscovery')),
        ], v => v)
        if (_.size(onlyList) === 0 || _.size(onlyList) > 1) {
            return Messager.show('所属需求、所属issue、扩展测试发现，不允许全部为空，且只能填写一项！', { icon: 'error' })
        }
        setLoading(true)
        const url = mode === 'add' ? '/test_defect/add' : '/test_defect/update'
        const postParams = {
            ...params,
            description: richEditorRef.current.getContent() ? richEditorRef.current.getContent() : '',
        }
        if (isNil(postParams['fixProjectIdList'])) {
            postParams['fixProjectIdList'] = []
            postParams['fixVersion'] = ''
        }
        if (isNil(postParams['projectId'])) {
            postParams['projectId'] = ''
            postParams['releaseId'] = ''
        }
        const submitParams = { ...postParams, ...replaceParams }
        submitParams['fixVersionState'] = null
        doFetch(url, 'post', submitParams).then(res => {
            if (_.get(caseParams, 'caseId') !== params?.caseId || _.get(caseParams, 'planId') !== params?.planId) {
                associatedTestcase(_.get(caseParams, 'caseId'), _.get(caseParams, 'planId'))
            }
            if (mode === 'add' || _.get(formData, 'currentUser') === _.get(params, 'currentUser')) {
                afterRefresh()
            } else {
                const currentUserParams = {
                    businessId: _.get(params, 'id'),
                    businessType: 'defect',
                    funcCode: '0504',
                    currentUser: _.get(params, 'currentUser'),
                    title: _.get(params, 'title')
                }
                doFetch('/WorkflowBusiness/updateCurrentUser', 'post', currentUserParams).then(() => {
                    afterRefresh()
                }).catch((err) => {
                    Messager.show(err._message, { icon: 'error' });
                    setLoading(false)
                })
            }

        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
            setLoading(false)
        })

        function afterRefresh() {
            Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' });
            if (setCurrentId) {
                setCurrentId(_.get(params, 'id'))
            }
            if (refreshList) {
                refreshList(replaceParams)
            }
            setLoading(false)
            if (mode === 'add') {
                if (continueAdd) {
                    continueClear()
                } else {
                    close()
                }
            } else {
                refreshDetail(_.get(params, 'id'))
                switchMode()
                updateDynamic()
            }
            if (!_.isNil(executiveOutcomes)) executiveOutcomes()
        }
    }, [mode, params, doFetch, replaceParams, refreshList, close, executiveOutcomes, refreshDetail,
        updateDynamic, switchMode, formData, setCurrentId, continueAdd, continueClear, associatedTestcase, caseParams])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(productId)) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === productId)
        const options = selectOption(filterModuleOptions, ['subSysName', 'subSysId'])
        return options
    }, [productId, moduleOptionsRes])

    useEffect(() => {
        if (_.size(moduleOptions) === 1) {
            setParams(x => _.assign({}, x, { subSysId: _.get(_.head(moduleOptions), 'value') }))
        }
        return
    }, [moduleOptions])

    const updateReleaseProject = useCallback((newFixProject, oldFixProject, currentFixVersion, discoveryProjectOnce) => {
        getRelease('/releaseinfo/getVersionWithProject', newFixProject).then(res => {
            discoveryProjectOnce = false
            const newVersionOptions = _.map(res, x => ({
                text: x.releasename,
                value: x.releaseid,
                state: x.state,
            }))
            const newVersionIdList = _.concat(_.map(res, 'releaseid'), ['不涉及版本规划'])
            const allIncludeFixVersion = _.every(currentFixVersion, x => _.includes(newVersionIdList, x))
            if (allIncludeFixVersion) {
                setParams(x => _.assign({}, x, { projectId: _.head(newFixProject) }))
                setReleaseOptions(newVersionOptions)
            } else {
                const notIncludeVersion = _.filter(currentFixVersion, x => !_.includes(newVersionIdList, x))
                Messager.show(`项目不允许删除，请先删除该项目下的${_.join(notIncludeVersion, '、')}版本后重试`)
                setParams(x => _.assign({}, x, { projectId: oldFixProject }))
            }
        }).catch((err) => {
            discoveryProjectOnce = false
            Messager.show(err._message, { icon: 'error' });
        })
    }, [getRelease])

    const updateFixProject = useCallback((newFixProject, oldFixProject, currentFixVersion) => {
        getRelease('/releaseinfo/getVersionWithProject', newFixProject).then(res => {
            const newVersionOptions = _.map(res, x => ({
                text: x.releasename,
                value: x.releaseid,
                state: x.state,
                displayFlag: _.includes(['T', 'C'], x.state) ? 'Y' : 'N',
                _disable: _.includes(['R', 'L'], x.state)
            }))
            const newVersionIdList = _.map(res, 'releaseid')
            const allIncludeFixVersion = _.every(currentFixVersion, x => _.includes(newVersionIdList, x))
            if (allIncludeFixVersion) {
                setParams(x => _.assign({}, x, { fixProjectIdList: newFixProject }))
                setFixVersionOptions(newVersionOptions)
            } else {
                const notIncludeVersion = _.filter(currentFixVersion, x => !_.includes(newVersionIdList, x))
                Messager.show(`项目不允许删除，请先删除该项目下的${_.join(notIncludeVersion, '、')}版本后重试`)
                setParams(x => _.assign({}, x, { fixProjectIdList: oldFixProject }))
            }
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' });
        })
    }, [getRelease])

    const defectIsHaveTracer = useMemo(() => {
        return _.get(formData, 'isHaveTracer')
    }, [formData])

    const isHistoryQuestion = useMemo(() => {
        return _.get(formData, 'historyQuestion') === 'Y'
    }, [formData])

    const onFormChange = useCallback((newObj, bind) => {
        let discoveryProjectOnce = false
        setParams(oldObj => {
            const cloneObj = { ...oldObj, ...newObj }
            if (bind === 'historyQuestion') {
                if (cloneObj[bind] === 'N' || _.isNil(cloneObj[bind])) {
                    if (_.size(_.split(cloneObj['fixVersion'], '^')) > 1) {
                        Messager.show('非历史问题的修复版本，只允许1个，请确认！', { icon: 'error' })
                        cloneObj[bind] = oldObj[bind]
                    } else {
                        cloneObj['isToPublic'] = null
                    }
                }
            }
            if (bind === 'productId') {
                cloneObj['subSysId'] = null
            }
            // if (bind === 'releaseId') {
            //     if (_.isEmpty(_.get(newObj, 'releaseId'))) {
            //         cloneObj['discoveryProject'] = null
            //     } else {
            //         // updateDiscoveryProject(_.get(newObj, 'releaseId'))
            //     }
            // }
            if (bind === 'projectId') {
                if (!discoveryProjectOnce) {
                    discoveryProjectOnce = true
                    if (_.isEmpty(_.get(newObj, 'projectId')) && !isNil(_.get(oldObj, 'releaseId'))) {
                        cloneObj['projectId'] = _.get(oldObj, 'projectId')
                        Messager.show(`项目不允许删除，请先删除该项目下的${_.get(oldObj, 'releaseId')}版本后重试`)
                        discoveryProjectOnce = false
                    } else if (_.isEmpty(_.get(newObj, 'projectId'))) {
                        setReleaseOptions([])
                        discoveryProjectOnce = false
                    } else {
                        const oldValue = _.get(oldObj, 'projectId')
                        const newValue = [_.get(newObj, 'projectId')]
                        const currentFixVersion = isNil(_.get(oldObj, 'releaseId')) ? [] : [_.get(oldObj, 'releaseId')]
                        updateReleaseProject(newValue, oldValue, currentFixVersion, discoveryProjectOnce)
                    }
                }
            }
            if (bind === 'fixProjectIdList') {
                if (_.isEmpty(_.get(newObj, 'fixProjectIdList')) && !isNil(_.get(oldObj, 'fixVersion'))) {
                    cloneObj['fixProjectIdList'] = _.get(oldObj, 'fixProjectIdList')
                    Messager.show(`项目不允许删除，请先删除该项目下的${_.join(_.split(_.get(oldObj, 'fixVersion'), '^'), '、')}版本后重试`)
                } else if (_.isEmpty(_.get(newObj, 'fixProjectIdList'))) {
                    setFixVersionOptions([])
                } else {
                    const oldValue = _.get(oldObj, 'fixProjectIdList')
                    const newValue = isHistoryQuestion ? _.get(newObj, 'fixProjectIdList') : _.filter(_.get(newObj, 'fixProjectIdList'), x => !_.includes(oldValue, x))
                    const currentFixVersion = isNil(_.get(oldObj, 'fixVersion')) ? [] : _.split(_.get(oldObj, 'fixVersion'), '^')
                    updateFixProject(newValue, oldValue, currentFixVersion)
                }
            }
            if (bind === 'fixVersion') {
                if (isHistoryQuestion) {
                    const currentFixVersion = isNil(_.get(newObj, 'fixVersion')) ? [] : _.split(_.get(newObj, 'fixVersion'), '^')
                    const oldFixVersion = isNil(_.get(formData, 'fixVersion')) ? [] : _.split(_.get(formData, 'fixVersion'), '^')
                    const cannotDelVersion = _.filter(oldFixVersion, item => {
                        const findItem = _.find(fixVersionOptions, x => x.value === item)
                        return _.includes(['R', 'L'], _.get(findItem, 'state'))
                    })
                    if (!_.every(cannotDelVersion, x => _.includes(currentFixVersion, x))) {
                        Messager.show(`不允许删除已发布/已上线的版本`)
                        cloneObj['fixVersion'] = _.get(oldObj, 'fixVersion')
                    }
                }
            }
            return cloneObj
        })
    }, [updateFixProject, fixVersionOptions, formData, isHistoryQuestion, updateReleaseProject])

    const releaseIdExtendOptions = useMemo(() => {
        if (_.isEmpty(releaseOptions)) return []
        // let options = releaseOptions
        // if (isNil(initReleaseId)) return options
        // const valueList = _.map(options, 'value')
        // if (!_.includes(valueList, initReleaseId)) {
        //     options = _.concat([{ text: initReleaseId, value: initReleaseId }], options)
        // }
        return releaseOptions
    }, [releaseOptions])

    const fixVersionExtendOptions = useMemo(() => {
        if (_.isEmpty(fixVersionOptions)) return []
        const initFixVersion = _.get(formData, 'fixVersion')
        let initVersionList = _.split(_.get(formData, 'fixVersion'), '^') || []
        if (isNil(initFixVersion)) {
            initVersionList = []
        }
        // const valueList = _.map(fixVersionOptions,'value')
        // const findNotIncludedList = _.filter(initVersionList, item => !_.includes(valueList, item))
        // const findNotIncludedOptions = _.map(findNotIncludedList, x => ({label: x,text: x, value: x}))
        // const extendOptions = _.concat(findNotIncludedOptions,fixVersionOptions)
        const options = _.map(fixVersionOptions, x => ({
            ...x,
            displayFlag: _.includes(initVersionList, x.value) ? 'Y' : x.displayFlag
        }))
        return _.sortBy(options, x => {
            return !_.includes(initVersionList, x.value)
        })
    }, [fixVersionOptions, formData])

    useEffect(() => {
        const dialog = document.querySelector('.defect-update-dialog')
        dialog.addEventListener('click', () => setCommentPopoverVisible(false))
    }, [])

    const previewImage = useCallback(e => {
        if (e.target.tagName === 'IMG') {
            setImageSrc(e.target.src)
            setImageVisible(true)
        }
    }, [])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const onReqSelected = useCallback((item) => {
        setParams(old => {
            const cloneObj = _.clone(old)
            cloneObj['storyId'] = _.get(item, 'id') || ''
            cloneObj['interiorReqNo'] = _.get(item, 'bizId') || ''
            return cloneObj
        })
    }, [])

    const onIssueSelected = useCallback((item) => {
        setParams(old => {
            const cloneObj = _.clone(old)
            cloneObj['issueId'] = _.get(item, 'id') || ''
            cloneObj['issueIdName'] = _.get(item, 'bizId') || ''
            return cloneObj
        })
    }, [])

    const canEditHistoryQuestionField = useMemo(() => {
        if (_.get(formData, 'isHaveTracer')) return false
        if (_.includes(_.get(formData, 'fixVersionState'), 'R') || _.includes(_.get(formData, 'fixVersionState'), 'L')) {
            return false
        }
        return editHistoryQuestionFieldAuth
    }, [formData, editHistoryQuestionFieldAuth])

    const canCreateTracer = useMemo(() => {
        if (_.get(formData, 'historyQuestion') === 'Y' && _.get(formData, 'originPhase') !== '03') {
            if (!_.includes(_.get(formData, 'fixVersionState'), 'R') && !_.includes(_.get(formData, 'fixVersionState'), 'L')) {
                return true
            }
        }
        return false
    }, [formData])

    const canEditFixVersion = useMemo(() => {
        const initFixVersion = _.get(formData, 'fixVersion')
        if (isNil(initFixVersion)) return true
        if (defectIsHaveTracer) return true
        if (!isHistoryQuestion) return true
        return true
        // const findItem = _.find(fixVersionExtendOptions, x => x.value === initFixVersion)
        // return !_.includes(['R', 'L'], _.get(findItem, 'state'))
    }, [formData, defectIsHaveTracer, isHistoryQuestion])

    const isTestStage = useMemo(() => {
        return _.get(formData, 'originPhase') === '03'
    }, [formData])

    const ReleaseIdSelect = useCallback((props) => {
        return <span onClick={() => {
            if (_.isEmpty(releaseIdExtendOptions)) return Messager.show('请先选择发现项目，发现版本为发现项目范围下的版本！', { icon: 'error' })
        }}>
            <DisplaySelect {...props} />
        </span>
    }, [releaseIdExtendOptions])

    const FixVersionSelect = useCallback((props) => {
        return <span onClick={() => {
            if (_.isEmpty(fixVersionExtendOptions)) return Messager.show('请先选择修复项目，修复版本为修复项目范围下，状态为未【发布/上线】的版本！', { icon: 'error' })
        }}>
            <DisplaySelect {...props} />
        </span>
    }, [fixVersionExtendOptions])

    const discoveryProjectOptions = useMemo(() => {
        const isAdd = mode === 'add'
        const isProjectId = isAdd ? _.get(addDefaultParams, 'projectId') : initDiscoveryProject
        let options = _.filter(allDevProjectOptions, o => o?.status !== '7')
        if (_.isEmpty(options)) return []
        if (isNil(isProjectId)) return options
        const valueList = _.map(options, 'value')

        if (!_.includes(valueList, isProjectId)) {
            const findItem = _.find(allDevProjectOptions, o => isAdd ? (o.value === isProjectId && o.text === '无项目') : (o.value === isProjectId))
            options = _.isNil(findItem) ? options : _.concat([findItem], options)
        }
        return options
    }, [allDevProjectOptions, initDiscoveryProject, mode, addDefaultParams])

    useEffect(() => {
        const projectId = _.get(params, 'projectId')
        const releaseId = _.get(params, 'releaseId')
        if (!isIterate) {
            if (isNil(projectId)) {
                setFormError(x => _.assign({}, x, { 'releaseId': '请先选择发现项目' }))
                return
            }
            if (isNil(releaseId)) {
                setFormError(x => _.assign({}, x, { 'releaseId': '必填项！' }))
                return
            }
        }
        else setFormError(x => _.assign({}, x, { 'releaseId': null }))
    }, [params, isIterate])

    useEffect(() => {
        if (mode !== 'add') {
            const causingProblemInfo = _.get(params, 'causingProblemInfo')
            const isToPublic = _.get(params, 'isToPublic')
            if (isHistoryDefects) {
                setFormError(x => _.assign({}, x, {
                    'causingProblemInfo': isNil(causingProblemInfo) ? '必填项！' : null,
                }, isToPublicEdit && { 'isToPublic': isNil(isToPublic) ? '必填项！' : null, }))
            } else setFormError(x => _.assign({}, x, { 'causingProblemInfo': null, 'isToPublic': null }))
        }
    }, [params, isHistoryDefects, mode, isToPublicEdit])

    useEffect(() => {
        const autoDiscover = _.get(params, 'autoDiscover')
        if (_.isNil(autoDiscover)) setFormError(x => _.assign({}, x, { autoDiscover: '必填项！' }))
        else setFormError(x => _.assign({}, x, { autoDiscover: null }))
    }, [params])

    const defaultUseCaseParams = useMemo(() => {
        if (_.isNil(_.get(params, 'id'))) return {}
        return {
            'View_test_caseRelated_Req.relateId': null, 'View_test_caseRelated_Issue.relateId': null,
            'test_defect_case_related.defectId': _.get(params, 'id'),
        }
    }, [params])

    const codeCommitBizList = useMemo(() => {
        return formData?.defectId && [formData?.defectId]
    }, [formData])

    return <Dialog className={'defect-update-dialog flex-y'} contentClassName={'flex-y'} cancel={close}
        footerVisible={false} headerVisible={false}>
        <div className={'defect-update-dialog-content flex-y'}>
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    {dialogHeader[mode]}
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        mode === 'detail' &&
                        <TextIconBtn icon={'fuzhi'} className={`header-edit-text-icon`} text={'复制'} onClick={() => setShowDetailDialog({ module: 'addDefect' })} />
                    }
                    {
                        mode === 'detail' &&
                        <Tooltip title={createTracerTips} >
                            <span style={{ marginRight: 16 }}>
                                <TextIconBtn icon={'-task'} className={`header-edit-text-icon`} disabled={!canCreateTracer} text={'创建研发任务'} onClick={() => setShowAddTrack(true)} />
                            </span>
                        </Tooltip>
                    }
                    {
                        mode !== 'add' &&
                        <span style={{ marginRight: 16 }}>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                                switchMode(true)
                                refreshDetail(currentId)
                            }} />
                        </span>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <DefectUpdateHeader editFormData={params} setEditFormData={setParams} refreshViewList={refreshList} {...{ isDetail, currentId, refreshDetail, updateDynamic, mode, allUserRes, setStatusName }} />
            <div className="defect-update-dialog-main-panel flex">
                <div className="left-panel flex-y">
                    <Tabs>
                        <TabPane tab='内容' key={'desc'} forceRender={true}>
                            <DefectContent
                                isAdd={mode === 'add'}
                                isDetail={mode === 'detail'}
                                editFormData={params}
                                setEditFormData={setParams}
                                {...{ richEditorRef, previewImage, allUserRes }}
                            />
                        </TabPane>
                        {
                            mode !== 'add' &&
                            <TabPane tab='研发任务' key={'tracer'}>
                                <div className="tracer-wrap" key={countTracer}>
                                    <DefectTracerView defectSnowId={_.get(params, 'id')} />
                                </div>
                            </TabPane>
                        }
                        {
                            mode !== 'add' && showCodeCommitTab &&
                            <TabPane tab='代码提交记录' key={'codeCommit'}>
                                <CodeCommitList bizIdList={codeCommitBizList} />
                            </TabPane>
                        }
                        <TabPane tab='附件' key={'file'}>
                            <div className="file-wrap">
                                <DefectUploadArea id={_.get(params, 'id')} funcCode={'0504'} {...{ mode, allUserRes }} />
                            </div>
                        </TabPane>
                        {
                            mode !== 'add' &&
                            <TabPane tab='发现测试计划' key={'findTestCase'}>
                                <TestCaseDataGrid
                                    defaultParams={caseParams}
                                    setCaseParams={setCaseParams}
                                    {...{ setRelation, mode, setDisassociation }}
                                />
                            </TabPane>
                        }
                        {
                            mode !== 'add' &&
                            <TabPane tab='验证用例' key={'testCase'}>
                                <PublicUseCase
                                    defaultParams={defaultUseCaseParams}
                                    addDefaultParameter={{
                                        testDefectId: _.get(params, 'id')
                                    }}
                                />
                            </TabPane>
                        }
                        {
                            mode !== 'add' &&
                            <TabPane tab='动态' key={'dynamic'}>
                                <div className={'dynamic-wrap'} key={countDynamic}>
                                    <ChangeRecord name='缺陷' title={_.get(params, 'title')} funcCode={'0504'} referenceId={currentId} commentReferenceId={_.get(params, 'defectId')} linkUrl={`/testDefectMgt?initId=${currentId}`} id={_.get(params, 'defectId')} />
                                </div>
                            </TabPane>
                        }
                    </Tabs>
                </div>
                <div className="right-panel-wrap flex-y">
                    <div className={clsx('right-panel', { 'is-detail': isDetail })}>
                        <Form value={params} onChange={onFormChange} error={formError} onError={setFormError}>
                            <HFormInput search label={'严重程度'} bind={'severity'} component={isDetail ? Display : DisplaySelect} options={severityOptions} required convert={v => convertOptions(v, severityOptions)} disabled={!canEditSeverity} />
                            {
                                mode !== 'add' &&
                                <Tooltip title={'测试经理审核后，不允许修改严重程度，如需调整，可通过技术裁决委员会裁决！'}>
                                    <span>
                                        <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                    </span>
                                </Tooltip>
                            }
                            <HFormInput search label={'缺陷类型'} bind={'type'} component={isDetail ? Display : DisplaySelect} options={typeOptions} required convert={v => convertOptions(v, typeOptions)} />
                            <HFormInput search label={'发现阶段'} bind={'originPhase'} component={isDetail ? Display : DisplaySelect} options={handleOriginPhaseOptions} required convert={v => convertOptions(v, originPhaseOptions)} />
                            <HFormInput search label={'发现项目'} bind={'projectId'} component={isDetail ? Display : DisplaySelect} required options={discoveryProjectOptions} convert={v => convertOptions(v, allDevProjectOptions)} />
                            <HFormInput search label={'发现版本'} bind={'releaseId'} component={isDetail ? Display : ReleaseIdSelect} required={!isIterate}
                                options={releaseIdExtendOptions} clear convert={v => convertOptions(v, releaseIdExtendOptions)}
                                disabled={!canEditReleaseId} />
                            {
                                mode !== 'add' &&
                                <Tooltip title={'新建节点创建人可修改，其他情况的修改请联系团队内小组长！'}>
                                    <span>
                                        <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                    </span>
                                </Tooltip>
                            }
                            <HFormInput required={true} search label={'修复项目'} bind={'fixProjectIdList'} component={isDetail ? Display : DisplaySelect} multiple
                                options={effectiveProject} toolbar={false}
                                convert={list => {
                                    const text = _.join(_.map(list, x => convertOptions(x, allDevProjectOptions)), '、')
                                    return <Tooltip title={text}>
                                        <span>{text}</span>
                                    </Tooltip>
                                }} />
                            {/*{*/}
                            {/*    !isHistoryQuestion &&*/}
                            {/*    <HFormInput search label={'修复项目'} bind={'fixProjectIdList'} component={isDetail? Display: DisplaySelect}*/}
                            {/*                options={effectiveProject}*/}
                            {/*                bindInConvert={list => _.isEmpty(list) ? null : _.head(list)}*/}
                            {/*                bindOutConvert={v => _.isNil(v)? [] : [v]}*/}
                            {/*                convert={v => convertOptions(v, allDevProjectOptions)}/>*/}
                            {/*}*/}
                            {
                                mode !== 'add' &&
                                <>
                                    <HFormInput search label={'修复版本'} bind={'fixVersion'} component={isDetail ? Display : FixVersionSelect}
                                        options={fixVersionExtendOptions} multiple={isHistoryQuestion} disabled={!canEditFixVersion}
                                        bindInConvert={v => {
                                            if (!isHistoryQuestion) return v
                                            if (_.isString(v)) {
                                                return _.split(v, '^')
                                            }
                                            return v
                                        }}
                                        bindOutConvert={v => {
                                            if (!isHistoryQuestion) return v
                                            if (_.isArray(v)) {
                                                return _.join(v, '^')
                                            }
                                            return v
                                        }}
                                        clear convert={v => {
                                            let value = _.isArray(v) ? v : _.split(v, '^')
                                            const text = _.join(_.map(value, x => convertOptions(x, fixVersionExtendOptions, 'text', 'value', { showOrigin: true })), '、')
                                            return <Tooltip title={text}>
                                                <span>{text}</span>
                                            </Tooltip>
                                        }}
                                    />
                                    <Tooltip title={'请先选择修复项目，修复版本为修复项目范围下的未发布/上线的版本'}>
                                        <span>
                                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            <HFormInput required search label={'重现概率'} bind={'recurrence'} component={isDetail ? Display : DisplaySelect} options={recurrenceOpt} clear convert={v => convertOptions(v, recurrenceOptions)} />
                            <Tooltip overlayClassName='recurrence-tooltip' title={<RecurrenceTips />}>
                                <span>
                                    <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                </span>
                            </Tooltip>
                            <HFormInput search label={'缺陷根源'} bind={'source'} component={isDetail ? Display : CascadeSelect} options={sourceOptions || []} clear convert={v => convertOptions(v, sourceOptions)} />
                            <HFormInput search label={'所属产品'} bind={'productId'} component={isDetail ? Display : DisplaySelect} options={productTreeOptions} required tree convert={v => convertOptions(v, productOptions)} />
                            <HFormInput required search label={'所属模块'} bind={'subSysId'} component={isDetail ? Display : DisplaySelect} options={moduleOptions} clear convert={v => convertOptions(v, moduleOptions)} />
                            {
                                mode !== 'add' && !isTestStage &&
                                <HFormInput search label={'历史问题'} bind={'historyQuestion'} component={isDetail ? Display : DisplaySelect} disabled={!canEditHistoryQuestionField}
                                    options={YNOptions} clear convert={v => convertOptions(v, YNOptions)} />
                            }
                            {
                                mode !== 'add' &&
                                <HFormInput required={isHistoryDefects} label={'引发问题信息'} bind={'causingProblemInfo'} component={isDetail ? Display : Input} placeholder='研发任务单号/版本号/None（五年以前不追溯）' />
                            }
                            {
                                _.get(params, 'historyQuestion') === 'Y' &&
                                <>
                                    <HFormInput required={isToPublicEdit} search label={'是否对外发布'} bind={'isToPublic'} component={(isDetail || !isToPublicEdit) ? Display : DisplaySelect} disabled={!isToPublicEdit} options={YNOptions} clear convert={v => convertOptions(v, YNOptions)} />
                                    <Tooltip title={'【开发组长审核】阶段，且开发组长的处理人可改！'}>
                                        <span>
                                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                                        </span>
                                    </Tooltip>
                                </>
                            }
                            <HFormInput search label={'发布客户'} bind={'custId'} component={isDetail ? Display : DisplaySelect} options={customOptions} convert={v => convertOptions(v, customOptions)} />
                            <HFormInput
                                bind='interiorReqNo'
                                label='所属需求/自提单'
                                placeholder='查找'
                                className='search-tracer'
                                onFocus={() => setShowSearchDialog('req')}
                                prefix={<Icon name='sousuo1' />}
                                suffix={<Icon name='baocuo' onClick={() => onReqSelected()} />}
                                component={mode === 'detail' ? Display : Input}
                                convert={(v) => <span className={'can-enter-text'} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation();
                                    setShowDetailDialog({
                                        module: _.includes(v, 'DEV') ? 'dev' : 'req',
                                        id: _.get(params, 'storyId')
                                    })
                                }}>{v}</span>}
                            />
                            <HFormInput
                                bind='issueIdName'
                                label='所属ISSUE'
                                placeholder='查找'
                                className='search-tracer'
                                onFocus={() => setShowSearchDialog('issue')}
                                prefix={<Icon name='sousuo1' />}
                                suffix={<Icon name='baocuo' onClick={() => onIssueSelected()} />}
                                component={mode === 'detail' ? Display : Input}
                                convert={(v) => <span className={'can-enter-text'} onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation();
                                    setShowDetailDialog({
                                        module: 'issue',
                                        id: _.get(params, 'issueId')
                                    })
                                }}>{v}</span>}
                            />
                            <HFormInput
                                label={'扩展测试发现'}
                                bind={'extensionTestDiscovery'}
                                component={RootCheckBox}
                                disabled={isDetail}
                                componentStyle={{
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                                bindInConvert={v => {
                                    return v === 'Y'
                                }}
                                bindOutConvert={v => {
                                    if (v) return 'Y'
                                    return null
                                }}
                            />
                            <HFormInput
                                required
                                label={'自动化发现'}
                                bind={'autoDiscover'}
                                component={isDetail ? Display : RadioGroup}
                                options={YNOptions}
                                convert={v => convertOptions(v, YNOptions)}
                            />
                            {
                                mode !== 'add' &&
                                <div>
                                    <HFormInput label={'解决结果'} bind={'solutionResult'} component={Display} convert={v => convertOptions(v, solutionResultOptions)} />
                                    <HFormInput label={'验证不通过次数'} bind={'noPassNum'} component={Display} />
                                    <HFormInput label={'重复缺陷ID'} bind={'repeatDefectId'} component={isDetail ? Display : Input}
                                        convert={v => {
                                            if (isNil(v)) {
                                                return '-'
                                            } else if (_.get(formData, 'repeatId')) {
                                                return <div className={'can-enter-text'} onClick={() => setShowDetailDialog({
                                                    module: 'defect',
                                                    id: _.get(formData, 'repeatId'),
                                                })}>
                                                    {v}
                                                </div>
                                            } else {
                                                return v
                                            }
                                        }} />
                                    <HFormInput label={'缺陷责任人'} bind={'bugOwner'} component={isDetail ? Display : UserSelect} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                                    <HFormInput label={'创建人'} bind={'createUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                                    <HFormInput label={'创建时间'} bind={'createTime'} component={Display} />
                                </div>
                            }
                        </Form>
                    </div>
                </div>
            </div>
            <div className="defect-update-dialog-footer flex center-y">
                {
                    mode === 'add' &&
                    <div className={'continue-add-check'}>
                        <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
                    </div>
                }
                {
                    mode !== 'add' &&
                    <div />
                }
                {
                    mode !== 'add' &&
                    <div className={'footer-middle-wrap flex center-y'}>
                        {
                            !_.isEmpty(showChildList) &&
                            <div className="footer-switch flex center-y">
                                <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!_.includes([-1, 0], currentIndex)) {
                                            switchCurrentItem('previous')
                                        }
                                        setCommentText(null)
                                    }}>
                                    <Icon name='xiangqian' />&nbsp;上一个
                                </div>
                                <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!(currentIndex === showChildList?.length - 1)) {
                                            switchCurrentItem('next')
                                        }
                                        setCommentText(null)
                                    }}>
                                    下一个&nbsp;<Icon name='xianghou' />
                                </div>
                                <div className="list-num">
                                    {currentIndex + 1}/{showChildList?.length}
                                </div>
                            </div>
                        }
                        <Popover content={<PopoverComment referenceId={_.get(params, 'defectId')} funcCode={'0504'} {...{ allUserRes, commentText, setCommentText }}
                            title={`【${_.get(params, 'defectId')}】${_.get(params, 'title')}`} linkUrl={`/testDefectMgt?initId=${currentId}`}
                            close={() => setCommentPopoverVisible(false)} />}
                            trigger="click" placement="topRight" open={commentPopoverVisible}>
                            <Tooltip title="评论信息">
                                <div className={'comment-wrap flex center'} onClick={() => setCommentPopoverVisible(x => !x)}>
                                    <Icon name={'zidingyishezhi1'} className={'comment-icon'} />
                                </div>
                            </Tooltip>
                        </Popover>
                    </div>

                }
                <div className="handle-btn">
                    {mode !== 'detail' && <Button primary onClick={submit} disabled={confirmButtonDisabled || loading}>确认</Button>}
                    <Button onClick={close} normal>关闭</Button>
                </div>
            </div>
        </div>
        {
            showSearchDialog === 'req' &&
            <ReqViewSearchDialog initId={_.get(params, 'storyId')} close={() => setShowSearchDialog(null)} outerSetItem={onReqSelected} />
        }
        {
            showSearchDialog === 'issue' &&
            <ViewQueryDialog
                bizName='ISSUE'
                initValue={_.get(params, 'issueId')}
                close={() => setShowSearchDialog(null)}
                funcCode={'0005'}
                outerSetItem={onIssueSelected}
                bizField='ProductionIssue.IssueId'
            />
        }
        {relation && <AssociatedTestcase planId={params?.planId} caseId={_.get(params, 'caseId')}  {...{ setRelation, associatedTestcase }} />}
        {disassociation && <Disassociation {...{ associatedTestcase, setDisassociation }} planId={params?.planId} />}
        {(loading || userLoading) && <Loader fill />}
        {
            _.get(showDetailDialog, 'module') === 'req' &&
            <RequirementDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'dev' &&
            <DevListDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'issue' &&
            <IssueDetailDialog
                close={() => setShowDetailDialog(null)}
                showChildList={[]}
                currentInfo={{
                    id: _.get(showDetailDialog, 'id'),
                    mode: 'detail',
                }}
                {...{ isFuncCodeApply, isFuncCodeQaCheck }}
            />
        }
        {
            _.get(showDetailDialog, 'module') === 'defect' &&
            <DefectUpdateDialog mode={'detail'} close={() => setShowDetailDialog(null)} currentId={_.get(showDetailDialog, 'id')}
                refreshList={refreshList} />
        }
        {
            _.get(showDetailDialog, 'module') === 'addDefect' &&
            <DefectUpdateDialog
                mode={'add'}
                close={() => setShowDetailDialog(null)}
                addDefaultParams={_.assign(_.pick(params, ADD_PICK),
                    { title: `【复制】${_.get(params, 'title')}` },
                    _.get(params, 'projectId') === 'PRJ-20220112-002-013' && { projectId: null, releaseId: null },
                    _.includes(_.get(params, 'fixProjectIdList'), 'PRJ-20220112-002-013') && { fixProjectIdList: null },
                )}
                defaultDescription={_.get(params, 'description')}
                refreshList={refreshList}
            />
        }
        <Image
            style={{ display: 'none' }}
            src={imageSrc}
            preview={{
                visible: imageVisible,
                src: imageSrc,
                onVisibleChange: visible => {
                    setImageVisible(visible)
                }
            }}
        />
        {
            showAddTrack && <TrackingAddDialog close={() => setShowAddTrack(false)}
                afterRefresh={() => {
                    refreshDetail(currentId)
                    updateTracer()
                }}
                initForm={{
                    tracerTitle: _.get(formData, 'title'),
                    bugType: "M",
                    tracerPri: 'B',
                    performanceFlag: 'NOCHANGE',
                    productId: _.get(formData, 'productId'),
                    subSysId: _.get(formData, 'subSysId'),
                    dictChange: 'N',
                    bugSource: 'S0',
                    issueToExtend: 'N',
                    issueToCustomer: 'Y',
                    projectId: 'NBGJ',
                    securityName: isNil(_.get(formData, 'custId')) ? ['NBGJ'] : [_.get(formData, 'custId')],
                    bugDesc: _.get(formData, 'description'),
                    defectId: _.get(formData, 'id'),
                    defectFixVersion: _.get(formData, 'fixVersion'),
                }}
            />
        }
    </Dialog>
}

export default DefectUpdateDialog;
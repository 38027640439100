import React from 'react'
import { useGet } from 'rootnet-biz/es/hooks'
import { DataGrid } from 'rootnet-ui'
import './TableDetails.scss'

export default function TableDetails(props) {
  const { id, url, option } = props
  const { data } = useGet(`${url}?id=${id}`)
  return (
    <DataGrid data={data} option={option} />
  )
}

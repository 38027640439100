import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import ViewQueryDialog from '../../../../../../common/view/viewQueryDialog/ViewQueryDialog'

const ADD_URL = '/releaseStory/relationDefectBaseline'
const textHint = <span>修复版本为当前版本，历史问题，且已过滤缺陷单中<span style={{color: 'red'}}>发布客户</span>为空的数据</span>
export default function RelevanceDefect(props) {
  const { open, data, close, releaseid, refresh, loading, snowId } = props
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const outerSetId = useCallback((idList) => {
    if (_.isEmpty(idList)) {
      Messager.show('无变更', { icon: 'info' })
      close()
      return
    }
    if (isDisabled) return
    setIsDisabled(true)
    let params = {
      id: snowId,
      defectMark: 'Y',
      releaseId: releaseid,
      storyIds: _.map(idList, v => ({ id: v })),
    }
    doFetch(ADD_URL, params)
      .then(() => {
        setIsDisabled(false)
        refresh()
        close()
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, close, refresh, releaseid, isDisabled, snowId])

  return (
    <>
      {
        open &&
        <ViewQueryDialog
          textHint={textHint}
          bizName='缺陷'
          multiple={true}
          initValue={[]}
          initItemValue={data}
          funcCode={'050402'}
          outerSetId={outerSetId}
          initLoading={loading}
          replaceParams={{ 'release_story.releaseId': releaseid }}
          {...{ close }}
        />
      }
    </>
  )
}

import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { CheckBox } from 'rootnet-edit'
import { toDate } from 'rootnet-core/dateFormat'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../utils/publicFun'

const CHECK_CANCEL_URL = '/costrate/batchModify'
const DETAIL_URL = '/costrate/info'

export default function CostRateCancel(props) {
  const { close, refresh, currentId } = props
  const [checked, setChecked] = useState(false)
  const [isHint, setIsHint] = useState(false)
  const [params, setParams] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()
  const { doFetch: getDetail, loading } = useGet()

  useEffect(() => {
    if (_.isNil(currentId)) return
    getDetail(`${DETAIL_URL}?${strParams({ id: currentId })}`)
      .then(res => {
        setParams({
          departmentName: res.departmentName, initParams: {
            ..._.pick(res, ['status', 'department', 'techLevel', 'price', 'id']),
            effectiveDate: toDate.str14ToDate(_.get(res, 'effectiveDate', '0'))
          }
        })
      })
  }, [getDetail, currentId])

  const submit = useCallback(() => {
    if (!checked) {
      if (isDisable) return
      setIsDisable(true)
      doFetch('/costrate/modify', { ..._.get(params, 'initParams', {}), status: 'N' })
        .then(() => {
          refresh()
          setChecked(false)
          setIsDisable(false)
          close()
          Messager.show(`作废成功`, { icon: 'success' })
        })
        .catch(err => {
          setIsDisable(false)
          Messager.show(err._message, { icon: 'error' })
        })
    } else setIsHint(true)

  }, [doFetch, params, refresh, checked, close, isDisable])

  const reconfirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    doFetch(CHECK_CANCEL_URL, [currentId])
      .then(() => {
        refresh()
        setIsHint(false)
        setChecked(false)
        setIsDisable(false)
        close()
        Messager.show(`作废成功`, { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, currentId, refresh, close, isDisable])

  return (
    <Dialog
      header='提示'
      confirm={submit}
      loading={loading}
      cancel={() => { close(); setChecked(false) }}
    >
      <div style={{ paddingLeft: 22, fontWeight: 900 }}>
        确认作废吗？
      </div>
      <div style={{ color: 'red' }}>
        <CheckBox value={checked} onChange={setChecked}>
          作废【{_.get(params, 'departmentName', '')}】及其子部门所有costrate，操作不可恢复，请慎重选择。
        </CheckBox>
      </div>
      {
        isHint && <Dialog
          header='提示'
          confirm={reconfirm}
          cancel={() => {
            setIsHint(false)
            setChecked(false)
            close()
          }}
          style={{
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 550,
          }}
        >
          <div style={{ color: 'red' }}>
            【{_.get(params, 'departmentName', '')}】及其子部门所有costrate将被作废，请确认！
          </div>
        </Dialog>
      }
    </Dialog>
  )
}

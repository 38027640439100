import cls from 'clsx'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Form, FormInput, Select } from 'rootnet-edit'
import { DataGrid, Messager, Loader, Drawer } from 'rootnet-ui'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../project_share/components'
import { useApi, useGet } from '../../../../utils/hook'
import '../projectManagement.scss'
import { isNil } from 'rootnet-core/format'

const { str14ToDate } = toDate

// const URL = [
//   '/common/globalpara?paraId=StdPrice_service',//实施 IMP
//   '/common/globalpara?paraId=StdPrice',//研发 DEV CUS
// ]

const getOption = (mustData) => ({
  nilText: '-',
  autoFill: true,
  fixedLeft: 3,
  // selection:true,
  columns: [
    { selection: true, width: 30 },
    { header: 'ID', bind: 'interiorReqNo', width: 125, tooltip: true, convert: v => v.replace(new RegExp('REQ_'), ''), class: 'table-index' },
    { header: '标题', bind: 'title', width: 270, tooltip: true, },
    { header: '负责人', bind: 'reqAdmin', width: 70, tooltip: true, },
    { header: '计划工时（人日）', bind: getUseDay(mustData), width: 90, tooltip: true, },
    { header: '提出日期', bind: 'reqDate', width: 90, convert: v => dateFormat('YYYY-MM-DD', str14ToDate(v)), tooltip: true, },
    { header: '客户', bind: 'customerName', width: 90, tooltip: true, },
  ]
});

function getUseDay(data) {
  if (_.get(data, 'projectType') === 'DEV' || _.get(data, 'projectType') === 'CUS') return 'useDay'
  if (_.get(data, 'projectType') === 'IMP') return 'serviceUseDay'
  return ''
}

export function Jurisdictions(jurisdictionData) {
  const powerData = ['PMO', 'OPS', 'PM', 'PMLeader', 'SurpportEM', 'SurpportPM', 'Manager']
  const UserRights = _.find(powerData, v => _.find(jurisdictionData, o => v === o.posId))
  if (UserRights === 'PMO' || UserRights === 'OPS') return UserRights
  return (_.find(jurisdictionData, v => v.posId === 'PM') && _.find(jurisdictionData, v => v.posId === 'Manager')) ? 'All' : UserRights
}

export function priceMark(jurisdiction) {
  if (_.find(jurisdiction, o => o.posId === 'Manager')) return ['Y', 'N']
  const pricing = ['PMO', 'OPS', 'SurpportPM', 'SurpportEM']
  const noPricing = _.find(jurisdiction, o => o.posId === 'PM')
  const UserRights = _.find(pricing, v => _.find(jurisdiction, o => v === o.posId))
  if (UserRights && noPricing) return ['Y', 'N']
  else if (UserRights && !noPricing) return ['Y']
  else if (!UserRights && noPricing) return ['N']
  else return []
}



export function Shuttle(props) {
  const { setOpen, open, data, search: projectSearch } = props
  const { projectStatus, projectName, leafProjectFlag, id } = open
  const { data: listData, doFetch: getDoFetch } = useGet() //项目名称下拉数据
  // const { data: defaultData, doFetch: getlistDofetch } = useGet()
  const { doFetch, loading, error } = useApi() //已分配、未分配 数据
  const { doFetch: shuttleDoFetch } = useApi() //提交需求分配 
  // const [subsidyDatam, subsidyDatams] = defaultData || []
  const [params, setParams] = useState({ projectName: open ? id : '' })
  const status = projectStatus === '03' || projectStatus === '04'
  const [source, setSource] = useState([]) //默认左数据
  const [target, setTarget] = useState([]) //默认右数据
  const [sourceData, setSourceData] = useState([]) //左当前选中数据
  const [targetData, setTargetData] = useState([]) //右当前选中数据
  const [allData, setAllData] = useState([]) //穿梭数据
  const [mustData, setMustData] = useState() //必传数据

  const [leftData, setLeftData] = useState(null)//查询左数据
  const [rightData, setRightData] = useState(null)//查询右数据

  const [time, setTime] = useState(0)
  const [isTrue, setIsTrue] = useState(leafProjectFlag === '1')

  const option = useMemo(() => getOption(mustData), [mustData])
  const repeat = useMemo(() => _.uniqWith(allData, _.isEqual), [allData])
  const listOpt = useMemo(() => _.map(listData, o => { return { text: o.projectName, value: o.id, projectType: o.projectType, budgetCost: o.budgetCost } }), [listData])

  useEffect(() => {
    if (!open) {
      setParams({ projectName: '', source: '', target: '' }); setSource(null); setTarget(null);
      setSourceData(null); setTargetData(null); setAllData(null);
      setMustData(null); setLeftData(null); setRightData(null);
      setTime(null); setIsTrue(null);
    }
  }, [open])

  // useEffect(() => {
  //   if (open) getlistDofetch(URL)
  // }, [getlistDofetch, open])

  useEffect(() => {
    if (id) getDoFetch(`/project/leaf/list?projectId=${id}`)
  }, [id, getDoFetch])

  useEffect(() => {
    if (leafProjectFlag === '1') projectChange(id, { projectType: _.get(data, 'projectType'), value: _.get(data, 'projectId'), budgetCost: _.get(data, 'budgetCost') })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leafProjectFlag])
  useEffect(() => {
    let num = 0
    if (target && (_.get(mustData, 'projectType') === 'DEV' || _.get(mustData, 'projectType') === 'CUS')) {
      _.forEach(target, v => {
        num += v.useDay
      })
    } else if (target && _.get(mustData, 'projectType') === 'IMP') {
      _.forEach(target, v => {
        num += v.serviceUseDay
      })
    }
    setTime(num.toFixed(1))
    // 已分配工时
  }, [target, listOpt, mustData])

  return (
    <Drawer
      open={open}
      header='需求分配'
      onOutsideClick={() => setOpen(false)}
      confirm={() => status ? setOpen(false) : commit()}
      cancel={() => setOpen(false)}
      className='shuttle-Box'
    >
      <Form onChange={setParams} value={params}>
        <div className="shuttleBox-content">
          {
            leafProjectFlag !== '1' ?
              <FormInput horizontal required search labelWidth={75} bind='projectName' label='分配至' options={listOpt} component={Select}
                onChange={(v, o) => projectChange(v, o)}
              /> :
              <FormInput disabled horizontal labelWidth={70} defaultValue={projectName} label='分配至' />
          }

          <div className='shuttleBox'>
            <div className='shuttleBox-left'>
              {isTrue && loading && <Loader fill />}
              <p className='demand'>未分配需求</p>
              <div className='search-box'>
                <FormInput disabled={_.isEmpty(source)} className='inputBox' bind='source' onChange={v => search(v, source, setLeftData)} placeholder='搜索需求ID、标题、负责人' prefix={<Icon name='sousuo1' style={{ fontSize: '15px' }} />} />
              </div>
              <DataGrid className='table-fill' loading={_.isEmpty(_.compact([params?.projectName])) ? false : loading} error={error} option={option} data={leftData || source} onSelectionChange={setSourceData} />
            </div>
            <div>
              {
                status ?
                  (<>
                    <div className={cls('icon-arrow')} ><Icon name='xianghou' /></div>
                    <div className={cls('icon-arrow')} ><Icon name='xiangqian' /></div>
                  </>)
                  :
                  (<> <div className={cls('icon-arrow', { 'select-arrow': _.get(sourceData, 'length') >= 1 })} onClick={() => jump(true)}><Icon name='xianghou' /></div>
                    <div className={cls('icon-arrow', { 'select-arrow': _.get(targetData, 'length') >= 1 })} onClick={() => jump(false)}><Icon name='xiangqian' /></div>
                  </>)
              }
            </div>
            <div>
              <p className='demand'>
                <span>已分配需求</span>
                <span style={{ float: "right" }}>已分配工时：<span style={{ color: 'blue' }}>{time}</span> 人/日</span>
              </p>
              <div className='search-box'>
                <FormInput disabled={_.isEmpty(target)} className='inputBox' bind='target' onChange={v => search(v, target, setRightData)} placeholder='搜索需求ID、标题、负责人' prefix={<Icon name='sousuo1' style={{ fontSize: '15px' }} />} />
              </div>
              <DataGrid className='table-fill' loading={_.isEmpty(_.compact([params?.projectName])) ? false : loading} error={error} option={option} data={rightData || target} onSelectionChange={setTargetData} />
            </div>
          </div>
          <span>注：已分配需求如未建立研发任务可撤回，如已建立研发任务则不可撤回</span>
        </div>
      </Form>
    </Drawer>
  )
  //数据进行左右交换时触发
  function jump(judge) {
    if (judge) {//左数据
      if (_.isEmpty(sourceData)) return
      // workingHours()
      sourceData.map(v => v.flag = v.flag === undefined ? 0 : undefined)//判断移动的数据是否返回
      const different = source.filter(v => sourceData.every(val => v.id !== val.id))//没有移动过去的左面数据
      exchangeData('source')
      if (_.get(mustData, 'projectType') !== 'IMP') _.forEach([...target, ...sourceData], v => v._selectable = !v.tracerId)//判断多选框是否禁用
      setAllData([...allData, ...sourceData].filter(v => v.flag !== undefined))//将移动的数据进行筛选
      setSource(flashback(different))//把没移动的数据放回左面
      setTarget(flashback([...target, ...sourceData]))//把移动的数据放到右面
      setSourceData([])//清空左面选中的数据
    } else {//右数据
      if (_.isEmpty(targetData)) return
      targetData.map(v => v.flag = v.flag === undefined ? 1 : undefined)//判断移动的数据是否返回
      const different = target.filter(v => targetData.every(val => v.id !== val.id))//没有移动过去的右面数据
      exchangeData('target')
      setAllData([...allData, ...targetData].filter(v => v.flag !== undefined))//将移动的数据进行筛选
      setTarget(flashback(different))//把没移动的数据放回右面
      setSource(flashback([...source, ...targetData]))//把移动的数据放到左面
      setTargetData([])//清空右面选中的数据
    }
  }
  //项目名称发生改变时
  function projectChange(firstId, o) {
    setLeftData(null)
    setRightData(null)
    setAllData([])
    setMustData({ projectType: _.get(o, 'projectType'), projectId: _.get(o, 'value'), budgetCost: _.get(o, 'budgetCost') })
    setIsTrue(true)
    doFetch(`/project/requirement/list?projectId=${firstId}`).then(res => {
      res[0].forEach(v => {
        v.tag = `${v.interiorReqNo}${v.title}${v.reqAdmin}${v.reqDate}${v.customer + v.customerName}`
      })
      res[1].forEach(v => {
        if (_.get(o, 'projectType') !== 'IMP') v._selectable = !v.tracerId
        v.tag = `${v.interiorReqNo}${v.title}${v.reqAdmin}${v.reqDate}${v.customer + v.customerName}`
      })
      setSource(_.clone(flashback(res[0])))
      setTarget(_.clone(flashback(res[1])))
    })
  }

  function search(str, data, setData) {
    if (!str) return setData(null)
    const strArr = _.compact(_.split(str, '^'))
    const condition = _.filter(data, o => _.every(_.map(strArr, v => _.includes(o.tag, v)), Boolean))
    setData(condition)
  }

  //是否超过预算工时  暂不需要
  // function workingHours() {
  //   let newTime = Number(time)
  //   const currentNorm = mustData.projectType === 'IMP' ? subsidyDatam.paraValue : subsidyDatams.paraValue
  //   _.forEach(sourceData, v => {
  //     if (_.get(mustData, 'projectType') === 'DEV' || _.get(mustData, 'projectType') === 'CUS') {
  //       newTime += v.useDay
  //     } else if (_.get(mustData, 'projectType') === 'IMP') {
  //       newTime += v.serviceUseDay
  //     }
  //   })
  //   if (newTime.toFixed(1) > _.round(mustData.budgetCost / Number(currentNorm), 1)) {
  //     Messager.show(
  //       `当前需求汇总工时为：${newTime.toFixed(1)}
  //         人/日，已超出项目预算工时 ${(newTime - _.round(mustData.budgetCost / currentNorm, 1)).toFixed(1)}人日，请及时调整成本`,
  //       { icon: 'error' }
  //     )
  //   }
  // }
  //查询时的时间及移动数据
  function exchangeData(type) {
    switch (type) {
      case 'source':
        const different = _.filter(leftData, v => sourceData.every(val => v.id !== val.id))//查询时 移过去后左面的数据
        if (_.get(leftData, 'length') === _.get(sourceData, 'length')) {//如果左面数据跟左面移动数据相同时左面清空
          setLeftData([])
        } else if (!_.isEmpty(different)) {//查询时 不同时 把没移动的数据放回左面
          setLeftData(flashback(different))
        }

        if (!_.isNull(rightData)) {//查询 左面移动数据时 右面的查询数据的筛选数据
          const condition = _.filter([...target, ...sourceData], v => _.includes(v.tag, params.target))
          setRightData(flashback(condition))
        }
        return
      case 'target':
        const differents = _.filter(rightData, v => targetData.every(val => v.id !== val.id))//查询时 移过去后右面的数据
        if (_.get(rightData, 'length') === _.get(targetData, 'length')) {//如果右面数据跟右面移动数据相同时右面清空
          setRightData([])
        } else if (!_.isEmpty(differents)) {//查询时 不同时 把没移动的数据放回右面
          setRightData(flashback(differents))
        }

        if (!_.isNull(leftData)) {//查询 右面移动数据时 左面的查询数据的筛选数据
          const condition = _.filter([...source, ...targetData], v => _.includes(v.tag, params.source))
          setLeftData(flashback(condition))
        }
        return
      default:
        return
    }
  }

  function flashback(data) {
    return _.orderBy(data, 'reqDate', 'desc')
  }

  function commit() {
    const pick = ['projectId', 'projectType', 'interiorReqNo', 'useday', 'flag', 'budgetRev']
    repeat.map(v => (
      _.assign(v, leafProjectFlag === '1' ? data : mustData,
        { budgetRev: isNil(v.budgetRev) ? 0 : v.budgetRev },
        (_.get(mustData, 'projectType') === 'DEV' || _.get(mustData, 'projectType') === 'CUS') &&
        { useday: isNil(v.useDay) ? 0 : v.useDay },
        _.get(mustData, 'projectType') === 'IMP' &&
        { useday: isNil(v.serviceUseDay) ? 0 : v.serviceUseDay }
      )
    ))

    if (_.isEmpty(repeat)) { Messager.show('未发生改变', { icon: 'error' }); return }
    const cloneData = _.clone(repeat)
    console.log(cloneData)
    const parameter = _.map(cloneData, item => _.pick(item, pick))
    shuttleDoFetch('/project/requirement/distribution', 'post', parameter).then(res => {
      setOpen(false)
      projectSearch()
      Messager.show('提交成功！', { icon: 'success' })
    }).catch((err) => {
      Messager.show(err._message, { icon: 'error' })
    })
  }

}
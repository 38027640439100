import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog,Messager,Loader} from 'rootnet-ui'
import { Form, FormInput, Grid, Select } from 'rootnet-edit'
import _ from "lodash";
import {useApi, useGet} from "../../../utils/hook";
import {Icon} from "../../../components";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import TextAreaWithTooltip from "../common/TextAreaWithTooltip";

const HFormInput = (props) =>  <FormInput horizontal componentWidth={280} {...props}/>

const WHICH_OPTIONS = {
    'add': '0',
    'edit': '1'
}

function EditDialog(props) {
    const {close, mode, initData, refresh, approvalMode, checkFlag} = props
    const isEdit = mode === 'edit'
    const {data: sourceOptionsRes} = useGet('/common/globalconst?globalConst=RootSource')
    const [formData, setFormData] = useState(initData)
    const [error, setError] = useState()
    const {doFetch} = useApi()
    const {data: uniqListRes, doFetch: getUniqListRes} = useGet()
    const [loading, setLoading] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(()=>{
        getUniqListRes('/fieldCommon/getName?whichPage=3')
    },[getUniqListRes])

    const sourceOptions = useMemo(()=>{
        if(_.isEmpty(sourceOptionsRes)) return []
        return convertGlobalConstOptions(sourceOptionsRes)
    },[sourceOptionsRes])

    const uniqList = useMemo(()=>{
        if(_.isEmpty(uniqListRes)) return []
        const allList = _.map(uniqListRes, x => x.sonCode)
        return _.filter(allList, x => x !== _.get(initData,'name'))
    },[uniqListRes,initData])

    const check = useCallback(()=>{
        if(checkFlag === '1'){
            if(!_.get(formData, 'memo')){
                Messager.show('请填写必填项', { icon: 'error' })
                return false
            }
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }else{
            if(!_.trim(_.get(formData, 'memo'))){
                Messager.show('请填写申请理由', { icon: 'error' })
                return false
            }
            return checkRequire(error)
        }
    },[error, checkFlag, formData])

    useEffect(()=>{
        if(!isEdit && _.get(formData,'name') &&  _.includes(uniqList, formData.name)){
            setError(x=>_.assign({},x,{'name': '中文全称重复'}))
        }
    },[formData,uniqList, isEdit])

    const submit = useCallback(()=>{
        if(!check()) return
        const isPending = approvalMode === 'pending'
        const postParams = {...formData,whichPage:'3',whichOption:WHICH_OPTIONS[mode],isAgree: isPending? '0': null,isApproval: checkFlag}
        const url = approvalMode === 'default' || mode === 'add' || _.get(formData,'auditFlag') === '1' ? '/dictStandardRoot/save' : '/field/updateAudit'
        setLoading(true)
        setSubmitDisable(true)
        doFetch(url, 'post',postParams).then(()=>{
            setSubmitDisable(false)
            Messager.show(checkFlag==='1'?`数据已提交，待审核`:'提交成功，已入库', { icon: 'success' })
            refresh()
            close()
        }).catch(err => {
            setSubmitDisable(false)
            Messager.show(err._message, { icon: err._message === '当前数据您已提交审批，若修改请撤销审批'?'info':'error' });
            setLoading(false)
        })
    },[check,doFetch,formData,close,mode,refresh,approvalMode,checkFlag])

    return <Dialog header={isEdit?'修改':'新增'} className='data-dict-edit-dialog' cancel={close} confirm={submit} confirmButtonDisabled={submitDisable} confirmButtonText={submitDisable?'提交中':'提交'}>
        <Form value={formData} onChange={setFormData} error={error} onError={setError}>
            <Grid cols={1}>
                <div style={{height: 16}}>
                    <div style={{marginLeft: 88, display: _.get(error,'name')==='中文全称重复'?'block':'none'}} className={'form-error-msg'}>
                        <Icon name='tixing' style={{marginRight: 5}}/>
                        中文全称重复
                    </div>
                </div>
                <HFormInput label='中文全称' bind='name' required/>
                <HFormInput label='英文全称' bind='enName' required bindOutConvert={v => _.replace(v , ' ', '')}/>
                <HFormInput label='英文缩写' bind='enAb' bindOutConvert={v => _.replace(v , ' ', '')}/>
                <HFormInput label='来源' bind='source' component={Select} options={sourceOptions} search clear required/>
                <HFormInput label='申请理由' bind='memo' component={TextAreaWithTooltip} value={_.get(formData, 'memo')} className={`desc-textarea ${checkFlag === '1'? 'desc-required' :''}`} required={checkFlag === '1'}/>
            </Grid>
        </Form>
        {loading && <Loader fill text={'提交中...'}/>}
    </Dialog>
}

function checkRequire(error){
    if(_.some(_.values(error),x=>x)){
        const errMsg = _.find(_.values(error), x=>!_.isNil(x))
        Messager.show(errMsg==='必填项！'?'请填写必填项':errMsg, { icon: 'error' })
        return false
    }
    return true
}

export default EditDialog;
import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import { Dialog, Button, MessageBox, Messager } from 'rootnet-ui'
import { Icon } from '../../components'
import { useGet, useApi } from '../../utils/hook'
import api from '../../base/task'
import ImportFiltering from './ImportFiltering'
import './ImportApiDialog.scss'

const accept = ".xlsx,.xls"
const RULE_URL = '/common/globalconst?globalConst=ImportFilesMemo' //导入规则


export default function ImportApiDialog(props) {
  const {
    close, //关闭导入窗口
    refresh, //刷新列表
    template, //下载导入模板接口
    importUrl, //导入接口
    parameter = {}, //导入需要的参数
    isExecutionList = true,//是否显示执行列表数据

    defaultOptions,// 展示导入成功失败的列表
    abnormal, //导出异常数据接口
    title = '', //导出异常数据 名字
    isObj = true, //导出异常数据参数是否要 对象类型
    abnormalParams = {}, //导出异常数据  需要的参数
    exportFieldname, //导出异常数据  需要的字段名
    showExport = true,// 是否显示导出异常数据按钮
    suffixName, //显示异常数据 需要拼接的字段
  } = props
  const { data, doFetch } = useApi()

  const [params, setParams] = useState()
  const [show, setShow] = useState(false)
  const [name, setName] = useState()
  const [message, setMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <Dialog
      cancel={close}
      header={'导入'}
      className='ImportApiDialog'
      loading={loading}
      confirm={addFile}
      confirmButtonDisabled={!name || loading}
      confirmButtonText='导入'
    >
      <DataPreparation template={template} title={title} />
      <UploadData name={name} setName={setName} setParams={setParams} parameter={parameter} />

      {
        show &&
        <ImportFiltering
          data={data}
          isObj={isObj}
          title={title}
          closeAll={close}
          abnormal={abnormal}
          params={abnormalParams}
          defaultOptions={defaultOptions}
          exportFieldname={exportFieldname}
          showExport={showExport}
          suffixName={suffixName}
        />
      }

      {message && <MessageBox header='提示' cancelButtonVisible={false} confirm={() => setMessage(null)} className={'content-center-dialog'}>{message}</MessageBox>}
    </Dialog>
  )

  function addFile() {
    setLoading(true)
    doFetch(importUrl, 'post', params).then((res) => {
      setLoading(false)
      Messager.show(`执行成功`, { icon: 'success' })
      refresh()
      if (isExecutionList) setShow(true)
      else close()
    }).catch(err => {
      setMessage(err._message)
      setLoading(false)
    })
  }

}

function DataPreparation(props) {//准备数据
  const { template, title } = props
  const [open, setOpen] = useState(false)

  const { data: ruleData } = useGet(RULE_URL)

  const rule = useMemo(() => {
    if (_.isEmpty(ruleData)) return ''
    return _.find(ruleData, o => title === o.displayName)
  }, [ruleData, title])

  return (
    <div>
      <p>① 准备数据</p>
      <p>

        所有允许导入的{title}字段请参考模板;{title}字段不符合规则，整条数据不予以导入
        <span className='arrow_drop_down' onClick={() => setOpen(!open)}>
          更多字段导入规则
          <Icon name='zhankaijiantouxia' style={{ transform: !open ? 'none' : 'rotate(-180deg)' }} />
        </span>
      </p>
      {open && <ShowText />}
      <p className='Download'>
        <span onClick={() => api.download(template)}><Icon name='daochu' /> <span>下载导入模板</span></span>
      </p>
    </div>
  )
  function ShowText() {
    return <p style={{ maxHeight: 246, overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: rule ? _.get(rule, 'memo').replace(/\n/g, '<br/>').replace(/\t/g, '&emsp;') : null }} />
  }
}

function UploadData(props) {//上传数据
  const { name, setName, setParams, parameter } = props
  return (
    <div>
      <p>② 上传数据文件</p>
      <p>目前支持的文件类型为{accept}。</p>

      <label htmlFor={'inputBtn'}>
        <Button cancel style={{ marginLeft: '18px' }}>添加文件</Button>
      </label>
      <input id={'inputBtn'} type="file"
        className="file-input" onChange={fileChange}
        accept={accept}
      />
      <span style={{ marginLeft: '10px' }}>{name}</span>
    </div>
  )

  function fileChange(e) {
    const file = _.get(e, 'target.files[0]')
    const fileData = new FormData()
    _.forEach(parameter, (val, key) => fileData.append(key, val))
    fileData.append('file', file)
    const fileType = _.get(file, 'name').split('.').pop()
    if (fileType !== 'xlsx' && fileType !== 'xls') return Messager.show('仅支持 .xlsx .xls 格式的文件', { icon: 'error' })
    setName(_.get(file, 'name'))
    setParams(fileData)

    // fix： net::ERR_UPLOAD_FILE_CHANGED
    let fileInput = document.getElementById('inputBtn')
    // 文件上传完成后，清空 input 的值
    fileInput.value = '';
  }

}
import React, {useEffect, useState} from 'react'

const WaterMark = ({text = '', option = {}}) => {
    const { top = '0px', left = '0px', width, height } = option

    const [background, setBackground] = useState('')


    useEffect(() => {
        const can = document.createElement('canvas')
        // 设置画布的长宽
        can.width = option.w || 300
        can.height = option.h || 210

        const cans = can.getContext('2d')
        // 旋转角度
        cans.rotate(-15 * Math.PI / 180)
        cans.font = '18px Vedana'
        // 设置填充绘画的颜色、渐变或者模式
        cans.fillStyle = 'rgba(200, 200, 200, 0.20)'
        // 设置文本内容的当前对齐方式
        cans.textAlign = 'left'
        // 设置在绘制文本时使用的当前文本基线
        cans.textBaseline = 'Middle'
        // 在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
        cans.fillText(text, can.width / 8, can.height / 2)

        setBackground(`url(${can.toDataURL('image/png')}) left top repeat`)
    }, [text,option])

    useEffect(()=>{
        sessionStorage.setItem('waterMarkUrl',background)
    },[background])

    return <div
        className="fill"
        style={{
            top,
            left,
            width,
            height,
            background,
            zIndex: 10000,
            position:'absolute',
            pointerEvents:"none"
        }}
    />
}


export default WaterMark
import _ from 'lodash'
import React, { useState, useEffect, useMemo, useCallback, useReducer } from 'react'
import { Button, DataGrid, Pagination, Messager } from 'rootnet-ui'
import { isNil, N2 } from 'rootnet-core/format'
import { Select, Form, FormInput, Input } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import { useGet } from '../../../utils/hook'
import { Box } from '../../common/commonComponent'
import { addAll } from '../../../utils/jsondata'
import { strParams, summaryWithHeader } from '../../../utils/publicFun'
import OperationList from '../../../project_share/components/OperationList'
import DrawerTemplate from '../controls/DrawerTemplate'
import { TextIconBtn } from "../../common/TextIconBtn";
import ExportCsvDialog from "../../common/ExportCsvDialog";
import gd from '../../../base/global'
import UserSelect from '../../common/personSelectPop/UserSelect'
import '../authority.scss'
import './index.scss'

const urls = [
  '/common/system',
  '/common/userinfo',
  '/common/globalconst?globalConst=PresalesProjectStatus'
]

const EDIT_URL = '/presales/updatePresalesproject'

const getColumns = ({ setOpen, presalesProStatusOpt, editUpdate, editInfo, setEditInfo, setValue, opts }) => {
  const { editId = null, editField = null } = editInfo || {}

  const selectOpt = {
    customer: _.get(opts, `[0]`, []),
    projectStatus: _.get(opts, `[2]`, []),
  }

  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 70 },
    { header: '售前支持编号', bind: 'idCRM', width: 120, tooltip: true, sortable: true, },
    { header: '项目名称', bind: 'projectName', sortable: true, align: 'left', width: 300, tooltip: true, convert: (v, o) => editTable(v, o, 'projectName') },
    { header: '客户', bind: 'customerName', sortable: true, align: 'left', convert: (v, o) => editTable(v, o, 'customer') },
    { header: '项目类型', bind: 'projectTypeName', sortable: true, align: 'left', width: 150, tooltip: true },
    { header: '项目状态', bind: 'projectStatus', sortable: true, align: 'left', width: 78, convert: (v, o) => editTable(v, o, 'projectStatus'), csvConvert: convertProjectStatus, },
    { header: '销售负责人', bind: 'principalName', sortable: true, align: 'left', width: 85, convert: (v, o) => editTable(v, o, 'projectManager') },
    // { header: '任务负责人', bind: 'taskPrincipalName', align: 'left', width: 85 },
    { header: '计划工时(人日)', bind: 'planTime', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '计划成本(元)', bind: 'planCost', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '实际工时(人日)', bind: 'useTime', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '实际成本(元)', bind: 'useCost', sortable: true, align: 'right', width: 150, convert: N2, csvConvert: N2, isTotal: true },
    { header: '操作', align: 'center', width: 90, convert: createOperation },
  ]

  function editTable(v, o, bind) {
    if (editId === o.id && editField === bind) {
      if (_.includes(['customer', 'projectStatus'], bind)) {
        return <Select
          search
          defaultValue={o[bind]}
          options={selectOpt[bind]}
          onChange={val => editUpdate({ newVal: val, oldVal: o[bind], id: o.id, bind })}
        />
      } else if (_.includes(['projectManager'], bind)) {
        return <UserSelect defaultValue={o[bind]} onChange={val => editUpdate({ newVal: val, oldVal: o[bind], id: o.id, bind })} defaultOpen={true} allowClear={false} />
      }
      return <Input
        autoFocus
        defaultValue={v}
        onChange={val => setValue(val)}
        onBlur={() => editUpdate({ oldVal: v, id: o.id, bind })}
        onEnter={() => editUpdate({ oldVal: v, id: o.id, bind })}
      />
    }
    return <div
      className='convert-edit-field-style'
      onClick={() => setEditInfo({ editId: o.id, editField: bind })}
    >
      {
        bind === 'projectStatus' ? convertProjectStatus(v) : isNil(v) ? '-' : v
      }
    </div>
  }

  function convertProjectStatus(v) {
    return _.get(_.find(presalesProStatusOpt, x => x.value === v), 'name')
  }

  function createOperation(v, o, i) {
    const options = [
      { text: '任务信息', onClick: () => setOpen(getParams()) }
    ]
    return <OperationList options={options} />
    function getParams() {
      const origin = _.omit(o, ['customer', 'projectName']);
      origin.projectNames = o.projectName;
      return origin
    }
  }
}

const gridOptsFor = (options, data) => ({
  nilText: '-',
  fixedLeft: 2,
  fixedRight: 1,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns: options,
  virtualized: false,
  summary: [
    summaryWithHeader(options, data)
  ],
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20,
    customer: '',
    projectStatusList: ['0'],
  }
}

export default function Authoritypresales() {
  const { data: optsData } = useGet(urls)
  const { data: listRes, error, loading, doFetch } = useGet()
  const [open, setOpen] = useState(false)
  const userAccount = _.get(gd, 'User.operator_id')
  const [params, setParams] = useState({ ...getInitParams(), taskPrincipal: userAccount, })
  const [currentId, setCurrentId] = useState()
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [editInfo, setEditInfo] = useState({})
  const [value, setValue] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { doFetch: getFetch } = usePost()

  const { pageSize, total, pageNum, rows: data } = useMemo(() => (listRes || {}), [listRes]);

  const search = useCallback((o) => {
    setParams(o)
    doFetch(`/presales/presalesproject?${strParams(o)}`)
  }, [doFetch])

  const editUpdate = useCallback(props => {
    const { id, newVal, oldVal, bind, tableName = 'PreSalesProject' } = props
    const newValue = _.isNil(newVal) ? value : newVal
    if (newValue === oldVal || _.isNil(newValue)) return destroyValue()
    getFetch(EDIT_URL, {
      id,
      oldValue: oldVal,
      newValue,
      fieldId: bind,
      tableName,
    })
      .then(() => {
        search(params)
        destroyValue()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [getFetch, params, search, value])

  const opts = useMemo(() => {
    const [devClient, proManager, presalesProStatus] = optsData || []
    return [
      _.map(devClient, v => ({ value: v.projectId, text: ''.concat(v.projectId, '-', v.projectName) })),
      _.map(proManager, v => ({ value: v.userAccount, text: `${v.userName} ${v.indexValidFlag === 0 ? '(离职)' : ''} - ${v.userAccount}`, tag: `${v.userAccount}${v.userName}` })),
      _.map(presalesProStatus, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
    ]
  }, [optsData])
  const presalesProStatusOpt = opts[2]
  const option = useMemo(() => gridOptsFor(getColumns({ setOpen, presalesProStatusOpt, params, editUpdate, editInfo, setEditInfo, setValue, opts }), data), [presalesProStatusOpt, params, editUpdate, editInfo, opts, data])
  const extra = useMemo(() => {
    return <TextIconBtn icon='xiazai2' text='导出' onClick={() => setShowExportDialog(true)} disabled={_.isEmpty(data)} />
  }, [data])

  const onRowClick = useCallback((item) => {
    setCurrentId(item.id)
    _.forEach(data, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [data])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return (
    <div className='authority-box authoritypresales fill'>
      <Option search={search} opts={opts} params={params} setParams={setParams} />
      <Box className='flex-y x-card-singlegrid' title='项目列表' error={error} loading={loading} data={data} extra={extra}>
        <DataGrid className='row-fill' data={data} option={option} onRowClick={onRowClick} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setParams(x => {
              const parameter = _.assign({}, x, { pageNum, pageSize })
              search(parameter)
              return parameter
            })
          }} />
      </Box>
      <DrawerTemplate open={open} setOpen={setOpen} projectType='PRESALES' url='/presales/presalestask' projectIsClose={_.get(open, 'projectStatus') === '1'} presalesProStatusOpt={presalesProStatusOpt} />
      {
        showExportDialog && <ExportCsvDialog close={() => setShowExportDialog(false)} list={data} option={option} title={`售前授权管理`} />
      }
    </div>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

function Option({ search, opts, params }) {
  const [devClientOpt, proManagerOpt, presalesProStatusOpt] = opts
  const [parameter, setParameter] = useState(params)
  useEffect(() => {
    search(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='x rims_options'>
      <Form onChange={setParameter} value={parameter}>
        <FormInput label="售前支持编号" bind="idCRM" />
        <FormInput label="客户" component={Select} bind="customer" search options={addAll(devClientOpt)} clear />
        <FormInput label="项目名称" bind="projectName" search />
        <FormInput label="销售负责人" component={Select} bind="principal" search clear options={proManagerOpt} />
        <FormInput label="任务负责人" component={Select} bind="taskPrincipal" search clear options={proManagerOpt} />
        <FormInput label="项目状态" component={Select} bind="projectStatusList" multiple options={presalesProStatusOpt} />
      </Form>
      <Button style={{ marginLeft: 0 }} className='btn' primary onClick={() => { search(parameter) }}>查询</Button>
    </div>
  )
}

import React from 'react';
import _ from "lodash";
import ConferenceRecordsParticipantList from "../../conferenceRecordsAdd/ConferenceRecordsParticipantList";
import './ConferenceRecordsDetailParticipant.scss'

function ConferenceRecordsDetailParticipant(props) {
    const {allUserRes, isDetail, participantList, setParticipantList} = props

    return <div className={'conference-records-detail-participant'}>
        {
            !_.isNil(participantList) &&
            <ConferenceRecordsParticipantList editable={!isDetail} {...{participantList, setParticipantList, allUserRes}}/>
        }
    </div>
}

export default ConferenceRecordsDetailParticipant;
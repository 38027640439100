import _ from 'lodash'
import { Input, Select, DatePicker } from 'rootnet-edit'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import TextAreaInput from '../../../common/textAreaInput/TextAreaInput'

export default function formList(props) {
  const { parentProjectOptions = [], productLineOptions = [], productOpt = [], isPat, isAdd = false } = props
  return [
    {
      label: '基本信息',
      bind: 'basicInfo',
      children: _.compact([
        {
          required: true,
          search: true,
          label: '父级项目',
          bind: 'pid',
          component: Select,
          options: parentProjectOptions,
          componentWidth: 536,
        },
        !isAdd && {
          required: !isPat,
          label: '预计工作量(人日)',
          bind: 'useday',
          type: 'number',
          component: Input,
          min: isPat ? 0 : 0.1,
          digit: 1,
        },
        !isAdd && {
          required: !isPat,
          label: '项目预算（元）',
          bind: 'projectBudget',
          type: 'number',
          component: Input,
          min: isPat ? 0 : 0.01,
          digit: 2,
        },
        {
          required: true,
          search: true,
          label: '产品线',
          bind: 'productLine',
          component: Select,
          options: productLineOptions,
        },
        {
          search: true,
          // multiple: true,
          label: '产品',
          bind: 'productID',
          component: Select,
          options: productOpt,
        },
        {
          range: true,
          required: true,
          label: '计划日期',
          bind: 'dateInterval',
          component: DatePicker,
          // labelWidth: 100,
          // componentWidth: 200,
        },
        {
          required: true,
          label: '计划发布时间',
          bind: 'scheduledRelease',
          component: DatePicker,
          bindOutConvert: v => dateFormat('YYYY-MM-DD', v),
          bindInConvert: v => toDate.dateStringToDate(v)
          // labelWidth: 100,
          // componentWidth: 200,
        }
      ]),
    },
    {
      label: '描述',
      bind: 'describe',
      children: [
        {
          required: true,
          label: '项目简介',
          bind: 'synopsis',
          component: TextAreaInput,
          componentWidth: 872,
          maxLength: 500,
          className: 'form-text-area-style'
        }
      ],
    }
  ]
}
import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {Button, Dialog, Loader, Messager} from "rootnet-ui";
import {TextIconBtn} from "../../common/TextIconBtn";
import Icon from "../../../components/Icon";
import './ProductDetail.scss'
import _ from "lodash";
import {Tabs} from "antd";
import {Display, Form, FormInput, Input, RadioGroup, Select} from "rootnet-edit";
import convertOptions from "../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import UserSelect from "../../common/personSelectPop/UserSelect";
import RichTextEditor from "../../common/richTextEditor/TinyEditor";
import ProductComponentList from "./productComponentList/ProductComponentList";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import clsx from "clsx";
import {usePost} from "rootnet-biz/es/hooks";
import ChangeRecord from "../../common/ChangeRecord";
import gd from "../../../base/global";
import {isNil} from "../../appraise/components/method";

const { TabPane } = Tabs;

const YNOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

const HFormInput = props => <FormInput horizontal labelWidth={130} componentWidth={180} {...props}/>

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=ProductLine',
]

const OPTIONS_URLS = [
    '/productInfoSubsys/dataSource',
]

function ProductDetail(props) {
    const {currentInfo, close, refreshViewList, isProdManager} = props
    const { mode: initMode = 'detail', id: initId, isSubsProduct = false, initForm } = useMemo(()=>currentInfo || {},[currentInfo])
    const [mode, setMode] = useState(initMode)
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const [activeKey, setActiveKey] = useState('info')
    const [editFormData, setEditFormData] = useState(initForm)
    const descRef = useRef()
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const { data: allUserRes } = useGet('/common/userinfo')
    const [componentList, setComponentList] = useState([])
    const [componentExpand, setComponentExpand] = useState(true)
    const {doFetch: submitPost} = usePost()
    const [loading, setLoading] = useState(false)
    const {doFetch: getDetail} = useGet()
    const [id, setId] = useState(initId)
    const [dynamicCount, updateDynamic] = useReducer((x) => x + 1, 0)

    const canEdit = useMemo(()=>{
       return isProdManager || _.get(formData,'productManager') === gd.User.operator_id
    },[formData, isProdManager])

    const descField = useMemo(()=>{
       return isSubsProduct? 'subSysDesc' : 'prodDesc'
    },[isSubsProduct])

    const titleField = useMemo(()=>{
       return isSubsProduct? 'subSysName' : 'productName'
    },[isSubsProduct])

    const refreshDetail = useCallback(()=>{
        if(_.isNil(id)) return
        setLoading(true)
        const url = isSubsProduct ? '/productInfoSubsys/infoSon' : '/productInfoSubsys/info'
        getDetail(url+'?id='+id).then(res => {
            setFormData(res)
            setEditFormData(res)
            setComponentList(_.get(res,'proddirectoryList') || [])
            if(descRef.current){
                descRef.current.setContent(_.get(res, descField) || '')
            }
            setLoading(false)
        }).catch(err => {
            Messager.show(err._message, {icon: 'error'})
            setLoading(false)
        })
    },[getDetail,id, descField, isSubsProduct])

    useEffect(()=>{
        refreshDetail()
    },[refreshDetail])

    const [productLineOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [parentProductOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes
        const parentProductOptions = _.map(d1, x => ({
            id: x.id,
            productId: x.productId,
            productName: x.productName,
            value: x.id,
            text: x.productName,
        }))
        return [parentProductOptions]
    },[optionsRes])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const isAdd = useMemo(() => {
        return mode === 'add'
    }, [mode])

    const switchMode = useCallback((resetDesc = false, toMode) => {
        if (resetDesc) {
            if(descRef.current){
                descRef.current.setContent(_.get(formData, descField) || '')
            }
        }
        setEditFormData(formData)
        setComponentList(_.get(formData,'proddirectoryList'))
        if(toMode){
            setMode(toMode)
        }else{
            setMode(x => x === 'detail' ? 'edit' : 'detail')
        }
    }, [formData, descField])

    const changeForm = useCallback((newObj, bind)=>{
        setEditFormData( oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            if(bind === 'independentVerFlag'){
                cloneObj['verAb'] = ''
            }
            return cloneObj
        })
    },[setEditFormData])

    const submitComponentList = useMemo(()=>{
        let submitComponentList = _.filter(componentList, x => x.action === 'add') || []
        const oldList = _.get(formData,'proddirectoryList')
        const newIdList = _.map(componentList,'id')
        const delList = _.filter(oldList, x => !_.includes(newIdList, x.id)) || []
        submitComponentList = _.concat(submitComponentList,_.map(delList, x => ({...x,action: 'del'})))
        _.forEach(componentList, item => {
            const oldItem = _.find(oldList, x => x.id === item.id)
            if(!_.isNil(oldItem) && !_.isEqual(oldItem,item)){
                submitComponentList.push({
                    ...item,
                    action: 'edit'
                })
            }
        })
        return submitComponentList
    },[componentList, formData])

    const submit = useCallback(()=>{
        if (isNil(descRef.current.getContent())) {
            return Messager.show("请填写描述")
        }
        if(descRef?.current.loading){
            return Messager.show("图片上传中，请稍后保存")
        }
        if(loading) return
        setLoading(true)
        const submitParams = {
            ...editFormData,
        }
        if(isSubsProduct){
            submitParams['subSysDesc'] = descRef.current.getContent()
            submitParams['productId'] = convertOptions(_.get(editFormData,'pid'),parentProductOptions, 'productId', 'id')
            submitParams['proddirectoryList'] = submitComponentList
        }else{
            submitParams['prodDesc'] = descRef.current.getContent()
        }
        let url
        if(mode === 'add'){
            url = isSubsProduct ? '/productInfoSubsys/insertSon' : '/productInfoSubsys/insert'
        }else{
            url = isSubsProduct ? '/productInfoSubsys/updateSon' : '/productInfoSubsys/update'
        }
        submitPost(url,submitParams).then((res)=>{
            setLoading(false)
            refreshViewList()
            if(mode === 'add'){
                setId(res)
            }else{
                refreshDetail()
            }
            setMode('detail')
            Messager.show(mode === 'add'? '新增成功':'修改成功', {icon: 'success'})
            updateDynamic()
        }).catch(err => {
            setLoading(false)
            Messager.show(err._message, {icon: 'error'})
        })
    },[editFormData, isSubsProduct, mode, loading, refreshDetail, submitComponentList, parentProductOptions, submitPost, refreshViewList])

    const canSubmit = useMemo(()=>{
        if(_.isNil(_.get(editFormData,'independentVerFlag'))) return false
        return _.every(formError, v => _.isNil(v))
    },[formError, editFormData])

    const addNewItem = useCallback(()=>{
        setComponentList(old => {
            const newItem = {
                id: uniqKeyFor(),
                overdue: 'N',
                action: 'add'
            }
            return _.concat(old,[newItem])
        })
    },[])

    const verAbRequired = useMemo(()=>{
        return _.get(editFormData, 'independentVerFlag') === 'Y'
    },[editFormData])

    useEffect(()=>{
        if(verAbRequired){
            if(_.isEmpty(_.get(editFormData,'verAb'))){
                setFormError(x=>_.assign({},x,{'verAb': '必填项！'}))
            }
        }else{
            setFormError(x => _.assign({},x,{'verAb': null}))
        }
    },[editFormData, verAbRequired])

    return <Dialog className={clsx('product-detail-dialog', {'is-add': isAdd})} headerVisible={false} footerVisible={false}>
        <div className="product-detail-content flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    {
                        isSubsProduct ? '子产品' : '产品'
                    }
                    {
                        mode === 'add' ? '添加' : '详情'
                    }
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        !isAdd && canEdit &&
                        <span style={{marginRight: 16}}>
                            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail?'进入编辑':'退出编辑'} onClick={()=>{
                                switchMode(true)
                            }}/>
                        </span>
                    }
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <div className="main-content-wrap flex-y">
                {
                    loading &&
                    <Loader fill/>
                }
                <Tabs activeKey={activeKey} onChange={setActiveKey}>
                    <TabPane tab='详细信息' key={'info'} forceRender={true}>
                        {
                            !isSubsProduct &&
                            <Form value={editFormData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
                                <HFormInput label={'产品ID'} bind={'productId'} required component={isDetail? Display: Input} disabled={mode !== 'add'}/>
                                <HFormInput label={'产品名称'} bind={'productName'} required component={isDetail? Display: Input}/>
                                <HFormInput label={'产品线'} bind={'productLine'} required component={isDetail? Display: Select}
                                            options={productLineOptions} convert={v => convertOptions(v, productLineOptions)}/>
                                <HFormInput label={'产品经理'} bind={'productManager'} component={isDetail? Display: UserSelect} required
                                            convert={v => convertOptions(v, allUserRes,'userName','userAccount')}/>
                                <HFormInput
                                    label={'是否过期'}
                                    bind={'overdue'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    required
                                    convert={v => convertOptions(v, YNOptions)}
                                />
                                <HFormInput
                                    label={'产品选择标识'}
                                    bind={'selectionFlag'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    convert={v => convertOptions(v, YNOptions)}
                                    labelWidth={isDetail?130:216}
                                />
                                <HFormInput
                                    label={'是否独立发版'}
                                    bind={'independentVerFlag'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    required
                                    convert={v => convertOptions(v, YNOptions)}
                                />
                                <HFormInput label={'版本前缀'} bind={'verAb'} required={verAbRequired} component={isDetail? Display: Input}
                                            labelWidth={isDetail?130:216} disabled={!verAbRequired}/>
                            </Form>
                        }
                        {
                            isSubsProduct &&
                            <Form value={editFormData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
                                <HFormInput label={'产品'} bind={'pid'} required component={isDetail? Display: Select}
                                            componentWidth={506} options={parentProductOptions} disabled
                                            convert={v => convertOptions(v, parentProductOptions)}/>
                                <HFormInput label={'子产品ID'} bind={'subSysId'} required component={isDetail? Display: Input} disabled={mode !== 'add'}/>
                                <HFormInput label={'子产品名称'} bind={'subSysName'} required component={isDetail? Display: Input}/>
                                <HFormInput
                                    label={'是否过期'}
                                    bind={'overdue'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    required
                                    convert={v => convertOptions(v, YNOptions)}
                                />
                                <HFormInput
                                    label={'是否单独报价'}
                                    bind={'singlequote'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    convert={v => convertOptions(v, YNOptions)}
                                    labelWidth={isDetail?130:216}
                                />
                                <HFormInput
                                    label={'是否独立发版'}
                                    bind={'independentVerFlag'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    required
                                    convert={v => convertOptions(v, YNOptions)}
                                />
                                <HFormInput label={'版本前缀'} bind={'verAb'} required={verAbRequired} component={isDetail? Display: Input}
                                            labelWidth={isDetail?130:216} disabled={!verAbRequired}/>
                                <HFormInput
                                    label={'是否必选'}
                                    bind={'necessaryType'}
                                    component={isDetail? Display: RadioGroup}
                                    options={YNOptions}
                                    convert={v => convertOptions(v, YNOptions)}
                                />
                                <HFormInput
                                    label={'排序'}
                                    bind={'serial'}
                                    component={isDetail? Display: Input}
                                    type={'number'}
                                    digit={0}
                                    labelWidth={isDetail?130:216}
                                />
                                <div/>
                                <HFormInput label={'客户端程序文件名'} bind={'guiexe'} component={isDetail? Display: Input} componentWidth={506}/>
                            </Form>
                        }
                        <div className="rich-text-form-item flex">
                            <div className="mock-label required">产品描述</div>
                            <div className="rich-text-area" style={{ display: isDetail ? 'none' : 'unset' }}>
                                <RichTextEditor ref={descRef}/>
                            </div>
                            <div className="rich-text-value-show-wrap flex" style={{ display: isDetail ? 'flex' : 'none' }}>
                                {
                                    _.isNil(_.get(editFormData, descField)) || _.get(editFormData, descField) === '' ?
                                        <div></div>
                                        : <div dangerouslySetInnerHTML={{ __html: _.get(editFormData, descField) }} />
                                }
                            </div>
                        </div>
                        {
                            isSubsProduct &&
                            <div className={'area-wrap'}>
                                <div className="area-header flex center-y space-between">
                                    <div className="area-header-left flex center-y">
                                        <Icon name="arrow_drop_down" className='fold-icon' style={{transform: componentExpand ? 'none' : 'rotate(-90deg)'}} onClick={()=>setComponentExpand(v => !v)}/>
                                        组件信息
                                    </div>
                                    {
                                        !isDetail &&
                                        <div className="product-component-handle-wrap">
                                            <Button normal onClick={addNewItem} style={{height: 30}}>增加行</Button>
                                        </div>
                                    }
                                </div>
                                <div className="area-content-wrap" style={{height: componentExpand ? '' : 0}}>
                                    <ProductComponentList list={componentList} setList={setComponentList} {...{isDetail}}/>
                                </div>
                            </div>
                        }
                    </TabPane>
                    <TabPane tab='动态' key={'dynamic'}>
                        <div className={'dynamic-wrap'} key={dynamicCount}>
                            <ChangeRecord name='产品' funcCode={'44'} referenceId={id} id={id} title={_.get(editFormData, titleField)} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div className="mock-footer flex center-y space-between">
                <div />
                <div className="btn-group flex">
                    {
                        isDetail &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        !isDetail &&
                        <Button normal onClick={() => {
                            switchMode(true)
                            setEditFormData(formData)
                        }}>取消</Button>
                    }
                    {
                        !isDetail &&
                        <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                    }
                </div>
            </div>
        </div>
    </Dialog>
}

export default ProductDetail;
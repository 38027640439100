import React from 'react'
import { Dialog } from 'rootnet-ui'
import DevListDetailContent from './devListDetailContent'
import './index.scss'

export default function DevListDetailDialog(props) {
  const { refreshViewList = () => { }, close = () => { } } = props
  return (
    <Dialog
      headerVisible={false}
      footerVisible={false}
      className='dev-list-detail-dialog'
    >
      <DevListDetailContent {...props} {...{ refreshViewList, close }} />
    </Dialog>
  )
}

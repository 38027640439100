import React from 'react'
import _ from 'lodash'
import { Drawer } from 'rootnet-ui'
import ChangeRecord from '../../../../common/ChangeRecord'
import './index.scss'

export default function FlowRecordDialog(props) {
  const { close, item, open } = props
  return (
    <Drawer
      open={open}
      header={'审核记录'}
      cancel={close}
      onOutsideClick={close}
      footerVisible={false}
      contentStyle={{ padding: '4px 8px', width: 500, overflowY: 'auto' }}
      className='flow-record-drawer'
    >
      {
        !_.isNil(_.get(item, 'id')) &&
        <ChangeRecord
          name='工时'
          funcCode={'7001'}
          referenceId={_.get(item, 'id')}
          id={_.get(item, 'id')}
          title={''}
          followBtn={false}
        // showSelect={false}
        // defaultAction={{ displayName: '工作流', interiorId: '4' }}
        />
      }
    </Drawer>
  )
}

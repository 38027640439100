import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Form, FormInput, Display, Input } from 'rootnet-edit'
import { Icon } from '../../../../components';
import './TestCaseDataGrid.scss'
import ViewQueryDialog from './planAndCaseQueryDialog';

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={180} {...props} />

function TestCaseDataGrid(props) {
    const { mode, setCaseParams, defaultParams = {} } = props //setRelation, setDisassociation
    // const [params, setParams] = useState()
    const [showSearchDialog, setShowSearchDialog] = useState()

    const isPlan = useMemo(() => {
        return showSearchDialog === 'plan'
    }, [showSearchDialog])

    const handChange = useCallback((o, key) => {
        // setParams(o)
        setCaseParams(o)
    }, [setCaseParams])

    const onHandClick = useCallback((item) => {
        const items = isPlan ? { planId: item.id, realPlanId: item.planId, planName: item.planName } : { caseId: item.id, realCaseId: item.caseId, caseName: item.caseName }
        setCaseParams(x => _.assign({}, x, items))
    }, [isPlan, setCaseParams])

    return <div className='defect-test-case-data-grid' style={{ height: '100%' }} >
        {/* required clear */}
        <Form value={defaultParams} onChange={handChange}>
            <HFormInput
                bind='planName'
                label='发现测试计划'
                placeholder='查找'
                className='search-tracer'
                onFocus={() => setShowSearchDialog('plan')}
                prefix={<Icon name='sousuo1' />}
                suffix={<Icon name='baocuo' onClick={() => setCaseParams(x => _.assign({}, x, { planId: null, realPlanId: null, planName: null }))} />}
                component={mode === 'detail' ? Display : Input}
            />
            <HFormInput
                label={'发现测试计划ID'}
                bind={'realPlanId'}
                component={Display}
            />
            <HFormInput
                bind='caseName'
                label='发现用例名称'
                placeholder='查找'
                className='search-tracer'
                onFocus={() => setShowSearchDialog('case')}
                prefix={<Icon name='sousuo1' />}
                suffix={<Icon name='baocuo' onClick={() => setCaseParams(x => _.assign({}, x, { caseId: null, realCaseId: null, caseName: null }))} />}
                component={mode === 'detail' ? Display : Input}
            />
            <HFormInput
                label={'发现用例ID'}
                bind={'realCaseId'}
                component={Display}
            />
        </Form>
        {
            !_.isNil(showSearchDialog) &&
            <ViewQueryDialog
                bizName={isPlan ? '发现测试计划' : '发现用例名称'}
                initValue={isPlan ? _.get(defaultParams, 'planId') : _.get(defaultParams, 'caseId')}
                close={() => setShowSearchDialog(null)}
                funcCode={isPlan ? '380503' : '380504'}
                bizField={isPlan ? ['test_plan.planId', 'test_plan.planName'] : ['test_case.caseId', 'test_case.caseName']}
                outerSetItem={onHandClick}
            />
        }
    </div>
}

export default TestCaseDataGrid;
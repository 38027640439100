import React, { useMemo, useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Tooltip } from 'antd'
import { dateFormat } from 'rootnet-core/dateFormat'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import Option from './option'
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import { downCsv, normalTransCsv } from '../../common/download'
import { Icon } from '../../../components'
import { useRolePermissions } from '../../common/commonMethod'
import CsvExportNumLog from '../../common/csvExportNumLog'

const LIST_URL = '/workingHoursManagement/selectDeletion'

const OPTIONS_URLS = [
  '/common/department?activeFlag=Y'
]

function defaultParams() {
  const userProfile = JSON.parse(localStorage.getItem('current_user_info'))
  const period = {
    begin: moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
    end: moment(new Date()).format('YYYY-MM-DD')
  }

  return {
    pageNum: 1,
    pageSize: 20,
    userAccounts: [_.get(userProfile, 'userAccount')],
    departments: [_.get(userProfile, 'department')],
    period,
  }
}

function getColumns(props) {
  const { isExport = false } = props || {}
  return [
    { header: '员工', bind: 'userAccountName', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '工号', bind: 'userCode', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
    { header: '部门', bind: 'departmentName', sortable: true, align: 'left', width: 150, tooltip: true, convert: v => v },
    { header: '工时周期', bind: 'workCycle', sortable: true, align: 'left', width: 155, tooltip: true, convert: v => v },
    { header: '审核完成（周占比）', bind: 'confirmedPercentage', sortable: true, align: 'left', width: 150, tooltip: true, convert: v => v },
    { header: '审核中（周占比）', bind: 'progressPercentage', sortable: true, align: 'left', width: 150, tooltip: true, convert: v => v },
    {
      header: isExport ? '缺失工时' : <div>
        缺失工时
        <Tooltip title='包含"未提交"，以及被"审核拒绝"的工时'>
          <span><Icon name='bangzhu' style={{ color: '#5477FF', marginLeft: 8 }} /></span>
        </Tooltip>
      </div>,
      bind: 'deletionPercentage',
      sortable: true,
      align: 'left',
      width: 100,
      tooltip: true,
      convert: v => {
        if (_.isNil(v)) return v
        if (v === '0%') return v
        return <div style={{ color: 'red' }}>{v}</div>
      },
      csvConvert: v => v
    },
    { header: '缺失工时（小时）', bind: 'missingHours', sortable: true, align: 'left', width: 100, tooltip: true, convert: v => v },
  ]
}

const gridOptsFor = (columns) => ({
  nilText: '-',
  // fixedLeft: 2,
  // fixedRight: 1,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns,
  virtualized: false,
});

export default function WorkTimDeletion() {
  const [isDisabled, setIsDisabled] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [parameter, setParameter] = useState(defaultParams())
  const [open, setOpen] = useState(false)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { doFetch, data: resData, loading, error } = usePost()
  const { doFetch: exportFetch } = usePost()
  const [downloadExcel] = useRolePermissions(['downloadExcel'])

  const { rows: data = [], total, pageNum } = useMemo(() => resData || {}, [resData])
  const [deptOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes || []
    return [
      _.map(d1, o => ({ text: o.departMentName, value: o.departMentId, pid: o.parentDept }))
    ]
  }, [optionsRes])

  const csvExport = useCallback((downNum = 1000) => {
    if (isDisabled) return
    setIsDisabled(true)
    const { period } = parameter
    const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
    const getParams = _.assign({}, parameter, { pageNum: 1, pageSize: downNum, }, !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval })
    exportFetch(LIST_URL, _.omit(getParams, ['period']))
      .then((res) => {
        const { rows: list } = res
        const options = getColumns({ isExport: true })
        const exportData = normalTransCsv(options, list)
        downCsv(exportData, '工时缺失查询')
        setIsDisabled(false)
        Messager.show('导出成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })

  }, [exportFetch, parameter, isDisabled])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn text='导出' icon='daochu' onClick={() => {
        if (downloadExcel) setOpen(true)
        else csvExport()
      }} />
    </div>
  }, [csvExport, downloadExcel])

  const refresh = useCallback((params = {}) => {
    const { period } = params
    const timeInterval = _.isNil(period) ? null : { begin: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'begin')))} 00:00:00`, end: `${dateFormat('YYYY-MM-DD', new Date(_.get(period, 'end')))} 23:59:59` }
    const getParams = _.assign({}, params, !_.isNil(timeInterval) && { beginDate: timeInterval, endDate: timeInterval })
    doFetch(LIST_URL, _.omit(getParams, ['period']))
  }, [doFetch])

  useEffect(() => {
    refresh(defaultParams())
  }, [refresh])

  const listData = useMemo(() => {
    if (_.isEmpty(data)) return []
    return _.map(data, o => {
      o['standardSunday'] = _.isNil(o.standardSunday) ? o.standardSunday : _.toNumber(o.standardSunday)
      return o
    })
  }, [data])

  const option = useMemo(() => gridOptsFor(getColumns()), [])

  return (
    <div className='fill flex-y work-time-deletion'>
      <Option search={refresh} {...{ deptOptions, setParameter, initParams: defaultParams() }} />
      <Box className='flex-y x-card-singlegrid' title='工时缺失查询' data={listData} error={error} extra={extra} loading={loading} >
        <DataGrid data={listData} option={option} />
        <Pagination selector total={total} current={pageNum} pageSize={pageSize} onChange={(pageNum, pageSize) => {
          setPageSize(pageSize)
          setParameter(x => {
            const params = _.assign({}, x, { pageNum, pageSize })
            refresh(params)
            return params
          })
        }} />
      </Box>
      {
        open &&
        <CsvExportNumLog
          downLoad={csvExport}
          close={() => setOpen(false)}
        />
      }
    </div>
  )
}

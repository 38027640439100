import React, { useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { FormInput, Select } from 'rootnet-edit'
import { Dialog, Loader, Messager } from 'rootnet-ui'
import { Tree } from 'antd'
import { Icon } from '../../../../components'
import { useApi, useGet } from '../../../../utils/hook'
import { ModeContext } from '../../../common/Context'
import { filterTreeList } from '../../components/getTreeList'
import './copyMove.scss'

const COPY_URL = '/test_case/add'
const MOVE_URL = '/test_case/update'
const TREE_URL = '/testcasetable/list'

export default function CopyMove() {
  const {
    modeAll,
    setModeAll,
    useCasesTree,
    libraryId,
    refresh,
    selectData,
    treeRefresh,
    queryData,
  } = useContext(ModeContext)
  const [params, setParams] = useState({ targetLibraryId: libraryId, targetTableId: '1' })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTreeKeyArr, setSelectedTreeKeyArr] = useState()

  const { doFetch } = useApi()
  const { data, doFetch: treeFetch, loading: treeLoading } = useGet()

  useEffect(() => {
    treeFetch(`${TREE_URL}?caseLibraryId=${params?.targetLibraryId}`)
  }, [params, treeFetch])

  const treeList = useMemo(() => {
    if (data) return filterTreeList(data)
    return []
  }, [data])

  const isMove = useMemo(() => modeAll.mode === 'move', [modeAll.mode])
  const header = useMemo(() => isMove ? '移动到目录' : '复制用例', [isMove])

  return (
    <Dialog
      header={header}
      cancel={() => setModeAll(null)}
      className='copyMove-lz'
      confirm={confirm}
      loading={isLoading}
    >
      <div><Icon name='tixing' /> 单次移动最多支持 1000 条用例。如果选中了超过 1000 条，则会优先处理前 1000 条。</div>

      <FormInput label='选择用例库' tree
        value={params?.targetLibraryId}
        component={Select}
        options={useCasesTree}
        onChange={onChange}
      />

      <div>
        <div>选择目录</div>
        <div>
          {treeLoading && <Loader fill />}
          {_.isEmpty(treeList) ? <div className='noTreelist'>暂无数据</div> :
            <Tree
              defaultExpandAll
              blockNode
              selectedKeys={selectedTreeKeyArr}
              treeData={treeList}
              height={298}
              switcherIcon={<Icon name='zhankaijiantoushang' className='transform-rotate' />}
              onSelect={(keys, item) => {
                const { selected } = item
                if (selected) {
                  setSelectedTreeKeyArr(keys)
                  setParams(x => _.assign(x, { targetTableId: keys[0] }))
                }
              }}
            />
          }
        </div>
      </div>
    </Dialog>
  )

  function onChange(v) {
    let isTabId = false
    if (v !== libraryId) {
      isTabId = true
      setSelectedTreeKeyArr([])
    }
    setParams(x => _.assign({}, x, { targetLibraryId: v }, isTabId && { targetTableId: '' }))
  }

  function confirm() {
    if (_.isEmpty(selectData)) return Messager.show('请在测试用例页面选择数据后在进行操作', { icon: 'error' })
    if (isLoading) return
    setIsLoading(true)
    const url = isMove ? MOVE_URL : COPY_URL
    const parameter = _.assign({}, params, {
      testCaseList: _.map(selectData, item => ({ id: item.id })),
      action: isMove ? 'EDIT' : 'ADD'
    }, !isMove && { copy: 'copy' })
    doFetch(url, 'post', parameter)
      .then(() => {
        refresh()
        treeRefresh(queryData)
        setModeAll(null)
        setIsLoading(false)
        Messager.show(`${isMove ? '移动' : '复制'}成功`, { icon: 'success' })
      })
      .catch(err => {
        setModeAll(null)
        setIsLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }
}

import React, { useRef, useEffect } from 'react';
import Icon from "./Icon";
import './TextIncoBtn.scss';
import { P_Clsx } from "../utils/utils-sp";

/*
维护者：ylf
时间：2020-06-19
* */
export default function TextIconBtn(props) {
    const { style, text, icon, size, className, label, disabled , placement , ...rest } = props;
    const ref = useRef(null);

    useEffect(() => {
        const node = ref.current;

        if(['left','right'].includes(placement)){
            node.className = node.className.concat(' ',`tib-${placement}`);
            return ()=>{}
        }

        const nodeInfo = node.getBoundingClientRect();
        const parentInfo = getContainer(node).getBoundingClientRect();
        const leftLead = nodeInfo.left - parentInfo.left;
        const rightLead = parentInfo.right - nodeInfo.left;
        const positionName = leftLead > rightLead ? 'tib-right' : 'tib-left';
        node.className = node.className.concat(' ', positionName);
    }, [placement]);

    return (<div style={style} className={P_Clsx('all-textIconBtn', className, { disabled, label })} ref={ref} {...rest}>
        <Icon name={icon} style={{ fontSize: size }} />
        {label && <div className="textBox">{label}</div>}
        {text && <span className="textWrite">{text}</span>}
    </div>);
}
TextIconBtn.defaultProps = {
    icon: null,//图标名称
    size: 14,//图标大小
    label: null,//hover显示文字
    text: null,//图标右侧文字
    disabled: false,//是否禁用事件
    placement:null//手动选择显示方向 可选值'left' 'right'
};

function getContainer(n) {
    const p = n.parentElement;
    const isContainer = getComputedStyle(p, null).overflow !== 'visible' || p.tagName === "HTML";
    return isContainer ? p : getContainer(p);
}

import React, { useCallback, useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Mentions } from 'antd'
import { Button, Loader, Messager } from 'rootnet-ui';
import { usePost } from 'rootnet-biz/es/hooks';
import { Icon } from '../../../../../components';
import FollowMattersDetail from '../followMattersDetail';
import './index.scss';

const { Option, getMentions } = Mentions;

const ADD_COMMENTS_URL = '/PublicMark/maintain/add' // 添加评论

export default function CommentInfo(props) {
  const outerAllUserRes = JSON.parse(localStorage.getItem('all_serving_user'))
  const { referenceId, funcCode, refresh, linkUrl, id, title, replyInfo, setReplyInfo, followBtn, commentWidth } = props
  const [commentText, setCommentText] = useState()
  const [open, setOpen] = useState(false) //显示底部按钮
  const [showFollowForm, setShowFollowForm] = useState(false) //显示添加跟进
  const [submitLoading, setSubmitLoading] = useState(false)
  const mentionsRef = useRef()
  const { doFetch } = usePost()

  const getFocus = useCallback(() => {
    if (mentionsRef.current) mentionsRef.current.focus()
  }, [])

  const release = useCallback(() => {
    if (mentionsRef.current) mentionsRef.current.focus()
    if (_.isNil(commentText) || commentText === '') return Messager.show('评论内容不能为空')
    if (submitLoading) return
    setSubmitLoading(true)
    const postParams = {
      referenceId, funcCode, title: `【${id}】${title}`,
      message: commentText,
      optIds: _.map(getMentions(commentText), x => getUserName(x.value)),
      pid: _.isNil(replyInfo) ? null : _.get(replyInfo, 'id'),
      cardUrl: linkUrl
    }
    doFetch(ADD_COMMENTS_URL, postParams).then(() => {
      setSubmitLoading(false)
      setOpen(false)
      setCommentText(null)
      setReplyInfo(null)
      refresh()
      if (mentionsRef.current) {
        mentionsRef.current.blur()
      }
      Messager.show('添加评论成功', { icon: 'success' })
    }).catch(err => {
      setSubmitLoading(false)
      Messager.show(err._message, { icon: 'error' })
    })
  }, [commentText, doFetch, funcCode, id, linkUrl, referenceId, refresh, replyInfo, setReplyInfo, title, submitLoading])

  useEffect(() => {
    const dom = document.querySelector('body')
    if (dom) {
      dom.addEventListener('click', onClick)
      return () => dom.removeEventListener('click', onClick)
    }
    function onClick(e) {
      setOpen(false)
    }
  }, [])

  const onBtnCancel = useCallback((e) => {
    getFocus()
    setShowFollowForm(x => !x)
    e.cancelBubble = true
  }, [getFocus])

  return (
    <div className='comment-info' style={_.assign(_.isNil(commentWidth) ? { maxWidth: '60%', minWidth: '200px' } : { width: commentWidth })}>
      {
        submitLoading && <Loader fill />
      }
      {showFollowForm && <FollowMattersDetail mode='add' {...{ refresh, funcCode, relateId: referenceId, close: () => { setShowFollowForm(false); setOpen(false) }, linkUrl }} />}
      {
        !_.isNil(replyInfo) &&
        <div className={'reply-info flex center-y'}>
          <div className="reply-info-content">{`回复 ${replyInfo.user} : ${replyInfo.message}`}</div>
          <Icon className={'reply-info-del-icon'} name={'shibai'} onClick={() => setReplyInfo(null)} />
        </div>
      }

      <div className={'mentions-wrap'} onClick={(e) => { e.cancelBubble = true; setOpen(true) }}>
        <Mentions
          ref={mentionsRef}
          rows={open ? 4 : 1}
          placeholder="支持使用“@中文姓名”提醒相关人员"
          value={commentText}
          onChange={setCommentText}
        >
          {
            _.map(outerAllUserRes, x => <Option key={x.userAccount} value={`${x.userName}(${x.userAccount})`}>
              {x.userName}({x.userAccount})
            </Option>)
          }
        </Mentions>
      </div>
      <div className='mentions-bottom-btn' style={{ display: open ? 'block' : 'none' }} onClick={(e) => { setOpen(true) }}>
        {
          followBtn &&
          <Button className={'mentions-bottom-btn-cancel'} text onClick={e => onBtnCancel(e)}>
            {showFollowForm ? '取消' : '添加跟进'}
          </Button>
        }
        <Button primary className={'mentions-bottom-btn-release'} onClick={e => {
          e.cancelBubble = true;
          release()
        }}>发布</Button>
      </div>
    </div>
  )
}

function getUserName(value) {
  return _.split(_.split(value, ')')[0], '(')[1] || ''
}

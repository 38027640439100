import React, { useMemo, useState, useReducer, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../view/convertTableAlign'
import findConvert from '../../view/findConvert'
import useGetViewConfig from '../../view/hooks/useGetViewConfig'
import useRefreshList from '../../view/hooks/useRefreshList'
import useGetDateOptions from '../../view/hooks/useGetDateOptions'
import useGetTreeList from '../../view/hooks/useGetTreeList'
import { Box, TextIconBtn } from '../../commonComponent'
import ViewArea from '../../view/ViewArea'
import { ModeContext } from '../../Context'
import InfoForm from '../../../testMgt/testCase/components/infoForm'
import { getCurrentUserInfo } from '../../commonMethod'
import { strParams } from '../../../../utils/publicFun'
import './index.scss'
import {API1} from "../../../../base/task";

const LIST_URL = '/caseLibrary/list'

const ENTER_DETAIL_FIELD = 'caseName'
const EDITABLE_FIELD = ['caseName']

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setModeAll } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)

    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setModeAll({ mode: 'detail', data: o })
        }}>{showValue}</div>
      </div>
    }
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

function selectTree(data) {
  _.map(data, item => {
    item.value = item.id
    item.text = item.name
    if (!_.isEmpty(item.children)) selectTree(item.children)
  })
  return data
}

const FUNC_CODE = '0021'

export default function PublicUseCase(props) {
  const { defaultParams, addDefaultParams = null, addDefaultReq = null, addDefaultIssue = null, addDefaultParameter = null } = props
  const [params, setParams] = useState()
  const [modeAll, setModeAll] = useState() // 增 删 改
  const [currentId, setCurrentId] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { data: listData, doFetch } = useGet()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()

  const userAccount = useMemo(() => getCurrentUserInfo('operator_name'), [])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    return defaultParams
  }, [defaultParams])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setModeAll } })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='tianjia' text='新增' onClick={() => {
        setModeAll({ mode: 'add' })
      }} />
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList])

  useEffect(() => {
    doFetch(`${LIST_URL}?${strParams({ login: userAccount })}`)
  }, [doFetch, userAccount])

  const contextValue = {
    modeAll,
    setModeAll,
    refresh: refreshList,
    treeRefresh: () => { },
    queryData: [],
    libraryId: null,
    selectedTreeKeyArr: '',
    useCaseList: dataGridList,
    useCasesTree: selectTree(listData),
    setCurrentId,

    addDefaultParams,
    addDefaultReq,
    addDefaultIssue,
    addDefaultParameter,
  }

  const onRowClick = useCallback((item) => {
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [dataGridList])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return (
    <ModeContext.Provider value={contextValue}>
      <div className='public-use-case fill flex-y'>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize, replaceParams
          }} />
        <Box title={'用例'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <DataGrid option={options} data={dataGridList} onRowClick={onRowClick} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
        {/* 添加 修改 详情 */}
        {_.includes(['add', 'edit', 'detail'], modeAll?.mode) && <InfoForm />}
      </div>
    </ModeContext.Provider>
  )
}

import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isNil, N2 } from 'rootnet-core/format'
import { Drawer, DataGrid, Messager } from 'rootnet-ui'
import { FormInput, Select, Input } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import { useGet } from '../../../utils/hook'
import { Box } from '../../common/commonComponent'
import { strParams } from '../../../utils/publicFun'
import { Icon } from '../../../project_share/components'
import Dialog from './Dialog'
import OperationList from "../../../project_share/components/OperationList";
import UpdateTaskDialog from "./UpdateTaskDialog";
import { TextIconBtn } from "../../common/TextIconBtn";
import UserSelect from '../../common/personSelectPop/UserSelect'
import './DrawerTemplate.scss'

const EDIT_URL = '/presales/updatePresalesproject'

const optionsTaskFor = ({ setIsShow, setCurrobj, projectType, setTaskDetail, isEdit, presalesProStatusOpt, editInfo, setEditInfo, setValue, editUpdate }) => {
  const { editId = null, editField = null } = editInfo || {}

  const selectOpt = {
    taskStatus: presalesProStatusOpt,
  }
  return (
    _.compact([
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 50 },
      isEdit && { header: '售前支持编号', bind: 'idCRM', width: 120, tooltip: true, },
      { header: '任务主题', bind: projectType === 'DEV' ? 'title' : 'taskName', align: 'left', tooltip: true, width: 135, convert: (v, o) => editTable(v, o, 'taskName') },
      isEdit && { header: '状态', bind: 'taskStatus', width: 60, align: 'center', convert: (v, o) => editTable(v, o, 'taskStatus') },
      { header: '计划工时(人日)', bind: 'planTime', align: 'right', width: 100, convert: v => N2(v / 60 / 8), isTotal: true },
      { header: '计划成本(元)', bind: 'planCost', align: 'right', width: 100, convert: v => N2(parseInt(v * 100) / 100), isTotal: true },
      { header: '实际工时(人日)', bind: 'useTime', align: 'right', width: 100, convert: v => N2(v / 60 / 8), isTotal: true },
      { header: '实际成本(元)', bind: 'useCost', align: 'right', width: 100, convert: v => N2(parseInt(v * 100) / 100), isTotal: true },
      { header: '任务负责人', bind: 'principalName', align: 'right', width: 100, isTotal: true, convert: (v, o) => editTable(v, o, 'principal') },
      { header: '操作', convert: renderOperation, align: 'center', width: 120 },
    ])
  )

  function editTable(v, o, bind) {
    if (!isEdit) return v
    if (editId === o.id && editField === bind) {
      if (_.includes(['taskStatus'], bind)) {
        return <Select
          search
          defaultValue={o[bind]}
          options={selectOpt[bind]}
          onChange={val => editUpdate({ newVal: val, oldVal: o[bind], id: o.id, bind })}
        />
      } else if (_.includes(['principal'], bind)) {
        return <UserSelect defaultValue={o[bind]} onChange={val => editUpdate({ newVal: val, oldVal: o[bind], id: o.id, bind })} defaultOpen={true} allowClear={false} />
      }
      return <Input
        autoFocus
        defaultValue={v}
        onChange={val => setValue(val)}
        onBlur={() => editUpdate({ oldVal: v, id: o.id, bind })}
        onEnter={() => editUpdate({ oldVal: v, id: o.id, bind })}
      />
    }
    return <div
      className='convert-edit-field-style'
      onClick={() => setEditInfo({ editId: o.id, editField: bind })}
    >
      {
        bind === 'taskStatus' ? convertProjectStatus(v) : isNil(v) ? '-' : v
      }
    </div>
  }

  function convertProjectStatus(v) {
    return _.get(_.find(presalesProStatusOpt, x => x.value === v), 'name')
  }

  function renderOperation(v, o) {
    const operateOption = [
      {
        text: '授权',
        onClick: () => { setCurrobj(o); setIsShow(true) }
      },
    ];
    const extendOption = [
      {
        text: '修改',
        onClick: () => setTaskDetail({ mode: 'edit', id: o.id })
      }
    ]
    return <OperationList options={_.concat(projectType === 'DEV' ? extendOption : [], operateOption)} />;
  }
}

const gridOptsFor = (options, currentId) => ({
  virtualized: false,
  resizable: true,
  columns: options,
  autoFill: true,
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,//左固定列数目
  fixedRight: 1,//右固定列数目
  onRenderRow: o => {
    const isCurrent = o.id === currentId
    return {
      style: { backgroundColor: isCurrent ? '#edf3f9' : '' },
    }
  },
  summary: [
    [
      { header: '合计', colSpan: 2, align: "center" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + _.toNumber(x.planTime / 60 / 8), 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt(x.planCost * 100) / 100, 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + _.toNumber(x.useTime / 60 / 8), 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt(x.useCost * 100) / 100, 0)), align: "right" },
      { header: '-', colSpan: 2, align: "center" }
    ]
  ]
})

const OPTIONS_URLS = [
  '/devproject/yaskclassificationdrop',
  '/devproject/taskstatusdrop',
  '/devproject/principaldrop'
]

export default function DrawerTemplate(props) {
  const { open, setOpen, projectType, url, projectIsClose, presalesProStatusOpt } = props
  const { id } = open
  const { data, loading, error, doFetch } = useGet()
  const [params, setParams] = useState()
  const [isShow, setIsShow] = useState(false)
  const [currobj, setCurrobj] = useState({})
  const [currentId, setCurrentId] = useState()
  const [taskDetail, setTaskDetail] = useState()
  const [editInfo, setEditInfo] = useState({})
  const [value, setValue] = useState()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { doFetch: getFetch } = usePost()

  const isEdit = useMemo(() => projectType === 'PRESALES', [projectType])

  const search = useCallback((val) => {
    if (val) {
      doFetch(`${url}?projectId=${id}&${strParams(projectType === 'DEV' ? { title: val } : { taskName: val })}`)
    } else {
      doFetch(`${url}?projectId=${id}`)
    }
  }, [doFetch, id, projectType, url])

  const editUpdate = useCallback(props => {
    const { id, newVal, oldVal, bind, tableName = 'PreSalesTask' } = props
    const newValue = _.isNil(newVal) ? value : newVal
    if (newValue === oldVal || _.isNil(newValue)) return destroyValue()
    getFetch(EDIT_URL, {
      id,
      oldValue: oldVal,
      newValue,
      fieldId: bind,
      tableName,
    })
      .then(() => {
        search(params)
        destroyValue()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [getFetch, params, search, value])

  const optionTask = useMemo(() => gridOptsFor(optionsTaskFor({ setIsShow, setCurrobj, projectType, setTaskDetail, isEdit, presalesProStatusOpt, editInfo, setEditInfo, setValue, editUpdate }), currentId, _.get(data, 'preSalesTaskVos')), [projectType, isEdit, presalesProStatusOpt, editInfo, editUpdate, currentId, data]);

  const existTitleList = useMemo(() => {
    if (_.isEmpty(data)) return []
    const taskList = _.get(data, 'preSalesTaskVos')
    return _.map(taskList, x => x.title)
  }, [data])

  const updateOptions = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    return [
      _.map(d1, x => ({ value: x.name, text: x.name, _disabled: _.includes(existTitleList, x.name), jobTypeId: x.id })),
      _.map(d2, x => ({ value: x.InteriOrid, text: x.ConstDisplayName })),
      _.map(d3, x => ({ value: x.userId, text: x.userName, tag: `${x.userId} ${x.userName}` })),
    ]
  }, [optionsRes, existTitleList])

  useEffect(() => {
    if (!open) setParams(''); setIsShow(false); setCurrobj({})
  }, [open])

  const refresh = useCallback(() => {
    if (open) doFetch(`${url}?projectId=${id}`)
  }, [id, doFetch, url, open])

  useEffect(() => {
    refresh()
  }, [refresh])

  const close = useCallback(() => {
    setOpen(false)
    setCurrentId(null)
  }, [setOpen])

  return (
    <Drawer
      open={open}
      header={<span>任务信息 <span style={{ paddingLeft: '10px', color: '#7A8199' }}>{_.get(open, 'projectNames')}</span> </span>}
      loading={loading}
      cancelButtonVisible={false}
      confirmButtonVisible={false}
      cancel={close}
      onOutsideClick={close}
      className='authority-drawer drawer-template-authority'
    >
      <Box
        title='列表'
        error={error}
        loading={loading}
        data={_.get(data, 'preSalesTaskVos')}
        extra={extra()}
        className='flex-y x-card-singlegrid'
      >
        <DataGrid className='row-fill' data={_.get(data, 'preSalesTaskVos')} option={optionTask} onRowClick={onRowClick} />
      </Box>
      {isShow && <Dialog projectType={projectType} setIsShow={setIsShow} currobj={currobj} open={open} projectIsClose={projectIsClose} />}
      {
        taskDetail &&
        <UpdateTaskDialog close={() => setTaskDetail(null)} projectId={_.get(open, 'id')} {...{ taskDetail, updateOptions, existTitleList, refresh }} />
      }
    </Drawer>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

  function onRowClick(o) {
    setCurrentId(o.id)
  }

  function extra() {
    return <div className={'flex center-y'}>
      <FormInput
        placeholder='请输入任务主题'
        onChange={setParams}
        value={params}
        suffix={<Icon onClick={() => search(params)} name='sousuo1' style={{ fontSize: '20px' }} />}
      />
      {
        projectType === 'DEV' &&
        <TextIconBtn text={'增加管理任务'} icon={'tianjia'} onClick={() => setTaskDetail({ mode: 'add' })} />
      }
    </div>
  }
}


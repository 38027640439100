import _ from 'lodash'
import React, { Fragment, useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { Dialog, DataGrid, Messager } from 'rootnet-ui'
import { Select, FormInput, Form, Grid } from 'rootnet-edit'
import { Dateformat, N2 } from 'rootnet-core/format'
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import { useGet, useApi } from '../../../utils/hook'
import gd from '../../../base/global'
import { OperationList, Icon } from '../../../project_share/components'
import '../authority.scss'
import {getUserDeptOptions} from "../../common/getUserDeptOptions";

const optionsAuthorize = ({ setMode, setIsShowEditDialog, setIsShowDelDialog, setValue, lastPlanUseTime, flag,projectIsClose }) => {
  return (
    [
      { selection: true, width: 55 },
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
      { header: '参与人', bind: 'userName', width: 80, align: 'center', tooltip: true },
      { header: '计划用时(h)', bind: 'planUseTime', width: 91, align: 'right', isTotal: true, convert: convertTime, tooltip: true },
      { header: '计划工时(h)', bind: 'planWorkTime', width: 91, align: 'right', isTotal: true, convert: convertTime, tooltip: true },
      { header: '计划成本(元)', bind: 'planUseCost', width: 100, align: 'right', isTotal: true, convert: v => N2(parseInt(v * 100) / 100), tooltip: true },
      { header: '实际用时(h)', bind: 'actualUseTime', width: 100, align: 'right', isTotal: true, convert: convertTime, tooltip: true },
      { header: '实际工时(h)', bind: 'actualWorkTime', width: 100, align: 'right', isTotal: true, convert: convertTime, tooltip: true },
      { header: '实际成本(元)', bind: 'actualUseCost', width: 100, align: 'right', isTotal: true, convert: v => N2(parseInt(v * 100) / 100), tooltip: true },
      { header: '授权人', bind: 'authorizerName', width: 80, align: 'center', tooltip: true },
      { header: '授权时间', bind: 'authorizerTime', width: 150, convert: (v) => Dateformat(v, 'hr'), tooltip: true },
      {
        header: "操作", align: "center", width: 110,
        convert: function _(e, o) {
          return <OperationList options={options_operation(o)} data={o} />
        }
      },
    ]
  )
  function options_operation(o) {
    return [
      {
        text: '修改',
        disabled: !flag || projectIsClose,
        onClick: () => {
          setMode('edit')
          setIsShowEditDialog(true)
          setValue({ ...o, planUseTime: '', planTime: o.planUseTime / 60 })
          lastPlanUseTime.current = o.planUseTime / 60
        },
      },
      {
        text: '删除',
        disabled: !flag || projectIsClose,
        onClick: () => {
          if (!_.isNil(o.actualUseTime) && o.actualUseTime !== 0) return Messager.show('已存在工时填报记录，不允许删除！', { icon: 'error' })
          setValue(o)
          setIsShowDelDialog('delete')
        },
      }
    ]
  }
  function convertTime(v) {
    return parseInt((v / 60) * 10) / 10
  }
}

const gridOptsFor = (options, data) => ({
  columns: options,
  nilText: '-',
  emptyText: '-',
  autoFill: true,
  summary: [
    [
      { header: '汇总', align: "center" },
      { header: '-', colSpan: 2, align: "center" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt((x.planUseTime / 60) * 10) / 10, 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt((x.planWorkTime / 60) * 10) / 10, 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt(x.planUseCost * 100) / 100, 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt((x.actualUseTime / 60) * 10) / 10, 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt((x.actualWorkTime / 60) * 10) / 10, 0)), align: "right" },
      { header: d => N2(_.reduce(d, (acc, x) => _.toNumber(acc) + parseInt(x.actualUseCost * 100) / 100, 0)), align: "right" },
      { header: '-', colSpan: 3, align: "center" },
    ]
  ]
});

const DEPARTMENT_URLS = [
  '/common/userinfo?indexValidFlag=1',
  '/common/department?activeFlag=Y',
]

export default function Dialogs(props) {
  const { setIsShow, currobj, open, projectType, projectIsClose = false } = props
  const { id, principal: principals, planTime } = currobj
  const { principal, projectNames, principalName } = open
  const { data, loading, error, doFetch } = useGet()
  const { doFetch: getDoFetch } = useApi()
  const {data: userDeptRes} = useGet(DEPARTMENT_URLS)

  const lastPlanUseTime = useRef()
  const [mode, setMode] = useState()
  const [value, setValue] = useState({})
  const [proManager, setproManager] = useState({})
  const [multiSelect, setMultiSelect] = useState()
  const [isShowDelDialog, setIsShowDelDialog] = useState(false)
  const [isShowEditDialog, setIsShowEditDialog] = useState(false)

  const [err, setErr] = useState(null)

  const hourTime = useMemo(() => (planTime/ 60), [planTime])
  const flag = useMemo(() => (_.get(gd, '_user.operator_id') === principal || _.get(gd, '_user.operator_id') === principals), [principal, principals])

  const options = useMemo(() => {
    return gridOptsFor(optionsAuthorize({ setMode, setIsShowEditDialog, setIsShowDelDialog, setValue, lastPlanUseTime, flag,projectIsClose }), _.get(data, 'authorityVos'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const init = useCallback((id) => {
    if (projectType === 'SERVICE') return doFetch(`/authority/list?projectId=${id}&projectType=${projectType}&id=${id}`)
    return doFetch(`/authority/list?taskId=${id}&projectType=${projectType}`)
  }, [doFetch, projectType])

  const userDeptOptions = useMemo(()=>{
    if(_.isNil(userDeptRes)) return []
    const [userRes, deptRes] = userDeptRes
    const options = getUserDeptOptions(userRes, deptRes)

    const userDeptId = _.get(_.find(userRes, x => x.userAccount === _.get(gd, '_user.operator_id')),'department')
    const topDeptId = getUserTopDept(userDeptId, deptRes)
    const sortOptions = _.sortBy(options, x => x.value !== topDeptId)

    return sortOptions
  },[userDeptRes])

  useEffect(() => {
    init(id)
    getDoFetch('/common/userinfo').then(res => {
      const opts = _.map(res, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`, factor: x.factor, indexValidFlag: x.indexValidFlag }))
      setproManager(_.filter(opts, o => o.indexValidFlag === 1))
    })
  }, [doFetch, init, id, getDoFetch])

  const headerText = useMemo(()=>{
    if(_.isNil(principalName)) return setDefaultText(projectNames)
    return `${setDefaultText(projectNames)}-${setDefaultText(principalName)}`
  },[projectNames,principalName])

  return (
    <Fragment>
      <Dialog
        loading={loading}
        confirmButtonVisible={false}
        cancel={() => setIsShow(false)}
        cancelButtonText={'关闭'}
        header={headerText} className='AuthorizeDialog'>
        <div className='AuthorizeDialog-hour'>
          <div className='AuthorizeDialog-hour-top'>
            <div>
              <span>可授权工时：{parseInt((hourTime - flllHour(_.get(data, 'authorityVos'))) * 10) / 10}h</span>
              <span>已授权工时：{(parseInt(flllHour(_.get(data, 'authorityVos')) * 10) / 10 || 0).toFixed(1)}h</span>
            </div>
            {
              flag &&
              <div style={{ display: 'flex' }}>
                <TextIconBtn text='批量新增' icon='tianjia' disabled={projectIsClose} onClick={() => { setMode('addAll'); setIsShowEditDialog(true) }} />
                <TextIconBtn text='批量修改' icon='bianji' disabled={!_.size(multiSelect) > 0 || projectIsClose} onClick={() => { setMode('editAll'); setIsShowEditDialog(true) }} />
                <TextIconBtn text='批量删除' icon='shanchu' disabled={!_.size(multiSelect) > 0 || projectIsClose} onClick={() => setIsShowDelDialog('deleteAll')} />
              </div>
            }
          </div>
          <Box data={_.get(data, 'authorityVos')} error={error} className='AuthorizeDialog-list'>
            <DataGrid data={_.get(data, 'authorityVos')} option={options} onSelectionChange={setMultiSelect} />
          </Box>
        </div>
      </Dialog>
      {isShowEditDialog && DialogBox()}
      {isShowDelDialog && <Dialog
        header='提示'
        className='AuthorizeDialogDel'
        cancel={() => setIsShowDelDialog(false)}
        confirm={() => { setValue({}); delConfirm(isShowDelDialog) }}>
        <div className='text'> 确定要删除“参与人”的授权？</div>
      </Dialog>}
    </Fragment>
  )

  function DialogBox() {
    const title = Object.freeze({ addAll: '批量新增', edit: '修改', editAll: '批量修改' })
    if (mode === 'editAll') {
      value.userIdList = aggregate('userId');
      lastPlanUseTime.current = _.reduce(aggregate('planWorkTime'), (result, val) => {
        result.push(val / 60)
        return result
      }, [])
    }

    return (
      <Dialog className='AuthorizeDialogEdit AuthorizationBox' cancel={() => { setValue({}); setIsShowEditDialog(false) }} header={title[mode]}
              confirmButtonDisabled={_.some(_.values(err),x=>x)}
        confirm={() => mode === 'addAll' ? addConfirm(mode) : editConfirm(mode)} >
        {
          !(mode === 'edit') && <Grid cols={1}>
            <div className='selectPerson'>
              {_.get(value, 'userIdList') && selectPerson(value.userIdList)}
            </div>
          </Grid>
        }
        <Form onChange={setValue} value={value} error={err} onError={setErr}>
          <Grid cols={2}>
            <FormInput
              search
              required
              horizontal
              tree
              label="参与人"
              component={Select}
              options={userDeptOptions}
              clear={mode === 'addAll'}
              disabled={(mode === 'edit' || mode === 'editAll')}
              multiple={(mode === 'addAll' || mode === 'editAll')}
              bind={mode !== 'edit' ? 'userIdList' : 'userId'} />

            <FormInput
              label={mode === 'addAll' ? "计划用时" : '增加用时'}
              required
              digit={1}
              horizontal
              type='number'
              bind="planUseTime"
              componentWidth={200}
              placeholder={((mode === 'edit' || mode === 'editAll') && '填写负数可减少用时') || null}
              suffix='h' />

          </Grid>
        </Form>

        {
          (mode === 'edit' || (mode === 'addAll' && !_.isEmpty(_.get(value,'userIdList')) && _.get(value,'userIdList').length === 1)) &&
          <span style={{ marginLeft: '370px', fontSize: '10px', color: '#738299', display: 'inline-block' }}>
            最大可授权用时 : &nbsp;{factor() ? parseInt((parseInt((hourTime - flllHour(_.get(data, 'authorityVos'))) * 10) / 10 / factor()) * 10) / 10 : 0}h
          </span>
        }
      </Dialog >
    )

    function selectPerson(data) {
      const screenData = _.filter(proManager, o => _.find(data, v => v === o.value))
      return _.map(screenData, o => (<span key={o.value}>
        {o.text}
        {mode === 'addAll' ? <Icon onClick={() => deletePerson(o.value)} name='close' /> : null}
      </span>))
    }

    function deletePerson(val) {
      const person = _.assign(value, {
        userIdList: _.filter(value.userIdList, v => v !== val)
      })
      setValue(_.clone(person))
    }
  }


  function delConfirm(del) {
    const isDelete = del === 'delete'
    if (!isDelete) {
      const delArr = _.reduce(multiSelect, (result, o) => {
        if (!_.isNil(o.actualUseTime) && o.actualUseTime !== 0) {
          result.push(o.userName)
        }
        return result
      }, [])
      if (!_.isEmpty(delArr)) return Messager.show(`【${delArr}】已存在工时填报记录，不允许删除！`, { icon: 'error' })
    }
    const params = { idList: isDelete ? [_.get(value, 'id')] : aggregate('id') }
    getDoFetch('/authority/delete', 'post', params).then(() => {
      Messager.show('删除成功', { icon: 'success' });
      setIsShowDelDialog(false)
      setMultiSelect(null)
      init(id)
      setValue({})
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      setValue({})
    })
  }

  function addConfirm() {
    const type = projectType === 'SERVICE' ? 'projectId' : 'taskId'
    const addData = _.assign(
      {
        [type]: id,
        projectType: projectType,
        userIdList: value.userIdList,
        planUseTime: value.planUseTime * 60,
      })
    //授权用时超过当前人员最大可授权用时
    if (!_.isNil(_.get(data, 'authorityVos')) && _.get(data, 'authorityVos').filter(x => _.find(value.userIdList, v => x.userId === v)).length !== 0) return Messager.show('参与人不能重复', { icon: 'error' });
    let hour = factor() * _.get(value, 'planUseTime')   //新增授权公式
    if (Number(planTime) === 0) return Messager.show('当前无可授权工时', { icon: 'error' })
    if (hour > parseInt((hourTime - flllHour(_.get(data, 'authorityVos'))) * 10) / 10) return Messager.show('授权用时超过当前人员最大可授权用时', { icon: 'error' })
    if (_.get(value, 'planUseTime') < 0) return
    if (userItemFactor(addData.userIdList)) return
    getDoFetch('/authority/add', 'post', addData).then(() => {
      Messager.show('添加成功', { icon: 'success' });
      setIsShowEditDialog(false)
      setMultiSelect(null)
      init(id)
      setValue({})
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      setValue({})
    })
  }

  function editConfirm(mode) {
    const isMode = mode === 'edit'
    const editData = _.assign({},
      {
        idList: isMode ? [_.get(value, 'id')] : aggregate('id'),
        planUseTime: value.planUseTime * 60
      },
      { userIdList: isMode ? [value.userId] : value.userIdList }
    )

    if (_.toNumber(value.planUseTime) < 0) {
      if (isMode) {
        if (!(value.planTime + _.toNumber(value.planUseTime) >= value.actualUseTime / 60)) return Messager.show('计划用时不能少于实际用时', { icon: 'error' })
      } else {
        const userName = _.reduce(multiSelect, (acc, o) => {
          if (!((o.planUseTime / 60) + _.toNumber(value.planUseTime) >= o.actualUseTime / 60)) {
            acc.push(o.userName)
            return acc
          }
          return acc
        }, [])
        if (!_.isEmpty(userName)) return Messager.show(`【${userName}】计划用时不能少于实际用时`, { icon: 'error' })
      }
    } else {
      const getHover = (factor() * (Number(_.get(value, 'planUseTime'))) > (parseInt((hourTime - flllHour(_.get(data, 'authorityVos'))) * 10) / 10))
      if (getHover) return Messager.show('授权用时超过当前人员最大可授权用时', { icon: 'error' })
    }
    const time = Number(_.replace(_.get(value, 'planUseTime'), '-', ''))
    if (_.get(value, 'planUseTime') < 0 && time > _.get(value, 'planTime')) return
    if (userItemFactor(editData.userIdList)) return
    getDoFetch('/authority/modify', 'post', editData).then(() => {
      Messager.show('修改成功', { icon: 'success' });
      setIsShowEditDialog(false)
      setMultiSelect(null)
      init(id)
      setValue({})
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      setValue({})
    })
  }

  function userItemFactor(data) {
    const factorArr = []
    const filterData = _.filter(proManager, item => _.find(data, v => item.value === v))
    const userFactor = _.reduce(filterData, (result, o) => {
      if (o.factor === 0) factorArr.push(o.text)
      result.push(o.factor)
      return result
    }, [])
    if (_.isNil(filterData) || _.size(_.compact(userFactor)) !== _.size(userFactor)) {
      Messager.show(`参与人【${_.join(factorArr, ',')}】无Cost Rate值`, { icon: 'error' })
      return true
    }
    return false
  }

  function factor() {
    if (mode === 'edit') return _.get(_.filter(proManager, x => x.value === value.userId), '[0].factor')
    return _.reduce(_.filter(proManager, x => _.find(value.userIdList, v => x.value === v)), (result, val) => result + val.factor, 0)
  }

  function aggregate(name) {
    return _.reduce(multiSelect, (result, value) => {
      result.push(value[name])
      return result
    }, [])
  }

  function flllHour(data) {
    return !_.isNil(data) && data.reduce((x, item) => x + parseInt((item.planWorkTime / 60) * 10) / 10, 0)
  }

  function setDefaultText(str) {
    if (_.isNil(str) || str === '') return '-'
    return str;
  }

}

function getUserTopDept(userDeptId, deptRes){
  const currentItem = _.find(deptRes, x => x.departMentId === userDeptId)
  const currParentDept = _.get(currentItem, 'parentDept')
  if(currParentDept === '' || _.isNil(currParentDept)){
    return _.get(currentItem, 'departMentId')
  }else{
    return getUserTopDept(currParentDept, deptRes)
  }
}
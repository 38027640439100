import React, { useState } from 'react'
import { Popover } from 'antd'
import { TextIconBtn } from './TextIconBtn'

export default function BubbleBox(props) {
  const { children, placement = 'bottomLeft', trigger = 'click', text = '更多', icon = '', style } = props
  const [clicked, setClicked] = useState(false)
  return (
    <Popover
      trigger={trigger}
      open={clicked}
      placement={placement}
      content={children}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn style={style} icon={icon} text={text} onClick={() => setClicked(x => !x)} />
    </Popover>
  )
}

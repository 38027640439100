import React, { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Popover, Badge } from 'antd'
import { Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { TextIconBtn } from '../../common/TextIconBtn'
import { strParams } from '../../../utils/publicFun'
import DailyReportCommentRecord from './dailyReportCommentRecord'
import './index.scss'
import clsx from 'clsx'

const DEL_COMMENTS_URL = '/PublicMark/maintain/del' //删除评论
const GET_RECORD_URL = '/PublicMark/getPublicMark' //获取记录
const FOLLOW_DETAILS_URL = '/todolist/getTodolistrecord'

const defaultActions = { interiorId: '2', displayName: '评论' }

export default function DailyReportComment(props) {
  const { style, funcCode, referenceId, associatedField, commentReferenceId, defaultParams } = props
  const [open, setOpen] = useState(false)
  const [sort, setSort] = useState(true)
  const [replyInfo, setReplyInfo] = useState(null)
  const [commentNum, setCommentNum] = useState(0)
  const { data, doFetch, loading } = useGet()
  const { data: followData, doFetch: followFetch } = useGet()
  const { doFetch: getFetch } = usePost()
  const [delLoading, setDelLoading] = useState(false)

  const allData = useMemo(() => {
    if (!_.isNil(data) && !_.isNil(followData)) {
      _.forEach(followData, o => {
        o.actionType = 'todo'
        o.updateTime = o.recTime
      })
      if (defaultActions?.interiorId === '5') return _.orderBy(followData, 'updateTime', sort ? 'desc' : 'asc')
      return _.orderBy(_.concat(data, isNull(defaultActions?.interiorId) ? followData : []), 'updateTime', sort ? 'desc' : 'asc')
    }
    return []
  }, [data, followData, sort])

  useEffect(() => {
    if (_.isEmpty(allData)) return setCommentNum(0)
    setCommentNum(getCommentNum(allData))
  }, [allData])

  const followRefresh = useCallback(() => {
    followFetch(`${FOLLOW_DETAILS_URL}?relateId=${referenceId}&funcCode=${funcCode}`)
  }, [followFetch, funcCode, referenceId])

  const refresh = useCallback((referenceType = '2') => {
    const params = _.assign(
      {},
      { funcCode, referenceId, associatedField },
      !_.isNil(commentReferenceId) && { commentReferenceId },
      referenceType + '' !== 'null' && { referenceType }
    )
    doFetch(`${GET_RECORD_URL}?${strParams(params)}`)
  }, [commentReferenceId, doFetch, funcCode, referenceId, associatedField])

  const delComment = useCallback((id, close = () => { }) => {
    if (delLoading) return
    setDelLoading(true)
    getFetch(DEL_COMMENTS_URL, { referenceId, funcCode, id })
      .then(() => {
        close()
        refresh()
        setDelLoading(false)
        Messager.show('删除成功', { icon: 'success' });
      })
      .catch(err => {
        close()
        setDelLoading(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [funcCode, getFetch, referenceId, refresh, delLoading])

  const allRefresh = useCallback((referenceType = null) => {
    if (referenceType !== '5') refresh(referenceType)
    followRefresh()
  }, [followRefresh, refresh])

  useEffect(() => {
    allRefresh()
  }, [allRefresh])

  const handChange = useCallback((flag) => {
    setOpen(flag)
    setReplyInfo(null)
    setSort(true)
  }, [])

  return (
    <Popover
      trigger="click"
      open={open}
      onOpenChange={handChange}
      placement={'bottom'}
      overlayClassName='daily-report-comment-popover'
      content={<DailyReportCommentRecord
        data={allData}
        refresh={allRefresh}
        {...{ delComment, funcCode, replyInfo, setReplyInfo, referenceId, loading, sort, setSort, defaultParams }}
      />}
    >
      <TextIconBtn
        icon='pinglun'
        className={clsx('daily-report-comment-icon-btn', { 'daily-report-comment-badge': open })}
        text={<>评论<Badge size='small' count={commentNum} offset={[4, -2]} /></>}
        style={_.assign({}, style, open && { color: '#5477ff' })}
      />
    </Popover>
  )
}

DailyReportComment.defaultProps = {
  style: {},
  commentReferenceId: null,
}

function isNull(val) {
  return val === null || val === 'null'
}

function getCommentNum(data, arr = []) {
  arr.push(_.size(data))
  _.forEach(data, o => {
    if (!_.isEmpty(o.list)) getCommentNum(o.list, arr)
  })
  return _.sum(arr)
}
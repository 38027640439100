import React, { useMemo, useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import moment from 'moment'
import { Radio, Table, Popover, Tooltip } from 'antd'
import { isNil } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/es/hooks'
import { Box } from '../../common/commonComponent'
import { dateChange, getAllDate, getTreeId, getTreeData, filterTime, getStartTime, getSections, getDaysBetween } from './components/method'
import { strParams } from '../../../utils/publicFun'
import { Icon } from '../../../components'
import TaskInfoDialog from './components/TaskInfoDialog'
import FilterQuery from './components/FilterQuery'
import './index.scss'

const AREA_URL = '/kanban/area' //按区域
const PROJECT_URL = '/kanban/project' //按项目
const DATE_URL = '/kanban/dateList'
const USER_URL = '/common/userinfo?indexValidFlag=1'
const URL = [USER_URL, DATE_URL]


const radioOptions = [
  { label: "按区域", value: 1 },
  { label: "按项目", value: 2 },
]

const firstDate = dateChange(-14)
const lastDate = dateChange(90)

function getInitParams() {
  return {
    date: [firstDate, lastDate],
    projectName: null,
    userAccount: null,
  }
}

const COLOR = {
  '01': '#338FE5', //未进行
  '02': '#F0A100', //进行中
  '03': '#24B47E', //完成
  '04': '#A9A9A9', //取消
  '06': '#F85E5E', //暂缓
}

const getColumns = ({ isArea, dateList, setShow, expandAll, expanded, setExpanded, hiddenId }) => {
  const areaColumns = [ //按区域
    { title: <UnfoldAndStow name='参与人' />, dataIndex: "userName", key: "userName", width: 150, fixed: 'left', },
    {
      title: '项目', dataIndex: "projectName", key: "projectName", width: 200,
      ellipsis: {
        showTitle: false
      },
      fixed: 'left', render: (v, o) => {
        return <div className='project-name flex center-y'>
          <Tooltip title={o.statusName}>
            <div className='color' style={{ background: COLOR[o.projectStatus] }}></div>
          </Tooltip>
          <Tooltip title={v} placement='topLeft'>
            <div className='project-names'>{v}</div>
          </Tooltip>
        </div>
      }
    },
    {
      width: 75,
      fixed: 'left',
      align: 'center',
      key: "totalTime",
      title: "总工时(天)",
      dataIndex: "totalTime",
      render: (v, o) => <div style={{ fontWeight: _.isNil(o.projectId) ? 700 : 400 }}>{v}</div>
    },
  ]
  const projectColumns = [ //按项目
    {
      title: <UnfoldAndStow name='项目名称' />, dataIndex: "projectName", key: "projectName", width: 200, fixed: 'left',
      ellipsis: { showTitle: false },
      render: (v, o) => {
        return <div className='project-name flex center-y'>
          <Tooltip title={o.statusName}>
            <div className='color' style={{ background: COLOR[o.projectStatus] }}></div>
          </Tooltip>
          <Tooltip title={v} placement='topLeft'>
            <div className='project-names'>{v}</div>
          </Tooltip>
        </div>
      }
    },
    { title: "参与人", dataIndex: "userName", key: "userName", width: 110, fixed: 'left', align: 'center', },
    {
      width: 75,
      fixed: 'left',
      align: 'center',
      key: "totalTime",
      title: "总工时(天)",
      dataIndex: "totalTime",
      render: (v, o) => <div style={{ fontWeight: _.isNil(o.projectId) ? 400 : 700 }}>{v}</div>
    },
  ]
  const hierarchy = _.map(_.groupBy(dateList, o => o.getTime), (v, k) => ({ title: k, children: v }))
  const toDay = moment().format('YYYY-MM-DD')
  const dateColumns = _.map(hierarchy, (item, index) => {
    return {
      title: <div className='work-color month'>{item.title}</div>,
      align: 'left',
      className: 'row-right-border',
      children: _.map(item.children, (items, i) => {
        const { flag, jobdate, getDates, whichDay } = items
        const isToDay = toDay === jobdate
        return {
          title: <div
            style={_.assign({ height: 28, transform: 'scale(0.9, 0.9)' }, isToDay && { padding: 0 })}
            className={cls({ 'rest-color': flag === 'Y', 'work-color': flag !== 'Y', 'toDay-color': isToDay })
            } >
            {
              <>
                <div style={{ height: 12 }}>{getDates}</div>
                <div style={{ height: 12 }}>{isToDay ? '今天' : whichDay}</div>
              </>
            }

          </div>,
          width: 32,
          align: 'center',
          className: cls('', { 'row-right-border': i === _.findLastIndex(item.children) && index !== _.findLastIndex(hierarchy) }),
          render: (_v, o) => getTask(o, jobdate)
        }
      })
    }

  })
  const columns = isArea ? areaColumns : projectColumns

  return _.concat(columns, dateColumns)

  function getTask(item, jobdate) {
    if (_.isNil(item?.taskList)) return
    const filterDate = _.filter(item?.taskList, o => {
      const { planBeginDate, planEndDate } = o || {}
      return (new Date(jobdate) >= new Date(planBeginDate) && new Date(planEndDate) >= new Date(jobdate))
    })
    if (_.size(filterDate) === 0) return
    const orderByDate = _.orderBy(filterDate, 'planBeginDate', 'asc')
    const beginInterval = getSections(orderByDate)
    const endInterval = getSections(orderByDate, true)
    const beginTime = getStartTime(orderByDate, jobdate)
    const endTime = getStartTime(orderByDate, jobdate, true)
    const size = _.size(orderByDate) > 9 ? 1 : `0.${_.size(orderByDate)}`
    const projectId = isArea ? item.projectId : item.initProjectId
    const children = <div className='allConditions-popover task-show'>
      {
        _.map(orderByDate, o => {
          return (
            <div
              key={o.taskId}
              style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={() => setShow({ mode: 'detail', projectId, taskId: o.taskId, principal: item?.principal, taskName: o.taskName })}
            >
              <span>{o.taskName}</span>
              <span style={{ marginLeft: 16 }}>{o.planBeginDate}~{o.planEndDate}</span>
            </div>
          )
        })
      }
    </div>
    return <Popover
      trigger={'click'}
      content={children}
      placement="topLeft"
      getPopupContainer={trigger => trigger.parentNode}
    >
      <div
        className={cls('task-color-wrap', { 'task-color-begin': beginInterval === jobdate, 'task-color-end': endInterval === jobdate })}
        style={{ background: `rgba(255, 125, 0, ${size})` }}
      >
        <div className={cls('task-color', { 'task-color-begin': !_.isNil(beginTime), 'task-color-end': !_.isNil(endTime) })} style={{ background: `rgba(255, 125, 0, ${size})` }} />
      </div>
    </Popover >
  }

  function UnfoldAndStow({ name }) {
    return <>
      <Icon
        style={{ fontSize: 12, marginRight: 8, fontWeight: 400, cursor: 'pointer' }}
        onClick={() => { setExpanded(x => !x); expandAll() }} name={expanded ? 'biaogeshouqi' : 'biaogezhankai'} />
      {name}
    </>
  }
}

export default function ImpDisplayBoardMgt(props) {
  const [selectedVal, setSelectedVal] = useState(1)
  const [treeData, setTreeData] = useState([])
  const [params, setParams] = useState(getInitParams())
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [show, setShow] = useState(null)
  const [expanded, setExpanded] = useState(true)
  const [hiddenId, setHiddenId] = useState([])
  const [switchLoading, setSwitchLoading] = useState(false)
  const [taskDate, setTaskDate] = useState({ beginDate: firstDate, endDate: lastDate })
  const [currentMonth, setCurrentMonth] = useState(firstDate)
  const { data: publicData, loading: publicLoading } = useGet(URL)
  const { data, doFetch, loading, error } = useGet()

  const isArea = useMemo(() => selectedVal === 1, [selectedVal])

  const boxLoading = useMemo(() => (loading || publicLoading || switchLoading), [loading, publicLoading, switchLoading])

  useEffect(() => {
    if (selectedVal) { }
    setSwitchLoading(true)
    setTimeout(() => setSwitchLoading(false), 3000)
  }, [selectedVal])

  const userOpt = useMemo(() => {
    if (_.isEmpty(publicData)) return
    return _.map(_.get(publicData, '[0]'), o => ({ label: o.userName, value: o.userAccount, tag: `${o.userName}${o.userAccount}` }))
  }, [publicData])

  const expandAll = useCallback(() => {
    setExpandedRowKeys(expanded ? getTreeId(treeData) : [])
  }, [expanded, treeData])

  const columns = useMemo(() => {
    return getColumns({
      isArea,
      dateList: filterTime(taskDate, getAllDate(_.get(publicData, '[1]', []))),
      expandAll,
      expanded,
      setExpanded,
      setShow,
      hiddenId,
    })
  }, [expandAll, expanded, hiddenId, isArea, publicData, taskDate])

  const search = useCallback(({ userAccount, projectName, date }, num = null) => {
    const [beginDate, endDate] = date || []
    const parameter = _.assign({ beginDate, endDate }, !isNil(userAccount) && { userAccount }, !isNil(projectName) && { projectName })
    const url = [
      `${AREA_URL}?${strParams(parameter)}`,
      `${PROJECT_URL}?${strParams(parameter)}`
    ]

    doFetch(url).then(() => { if (!_.isNil(num)) horizontalScroll(num) })
  }, [doFetch])

  useEffect(() => {
    if (_.isEmpty(data)) return setTreeData([])
    const treeList = getTreeData(_.get(data, isArea ? '[0]' : '[1]'), isArea)
    setTreeData(treeList)
  }, [data, isArea])

  useEffect(() => {
    search(getInitParams())
  }, [search])

  useEffect(() => {
    if (isArea) { }
    setExpandedRowKeys([])
    expandAll()
  }, [expandAll, isArea])

  const changeMonth = useCallback((type) => {
    const { beginDate, endDate } = taskDate || {}
    if (type === 'last') { // 上一月
      const lastMonth = moment(currentMonth).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      const isBegin = new Date(lastMonth) >= new Date(beginDate) && new Date(endDate) >= new Date(lastMonth)
      const numberDays = _.floor(getDaysBetween(beginDate, lastMonth))
      if (isBegin) horizontalScroll(numberDays)
      else {
        setTaskDate(x => _.assign({}, x, { beginDate: lastMonth }))
        search(_.assign(params, { date: [lastMonth, endDate] }), numberDays)
        // setTimeout(() => horizontalScroll(0), 0)
      }
      setCurrentMonth(lastMonth)
    } else if (type === 'next') { // 下一月
      const nextMonth = moment(currentMonth).endOf('month').subtract(-1, 'month').endOf('month').format('YYYY-MM-DD')
      const isEnd = new Date(nextMonth) >= new Date(beginDate) && new Date(endDate) >= new Date(nextMonth)
      const firstDay = moment(nextMonth).startOf('month').format('YYYY-MM-DD')
      const numberDays = getDaysBetween(beginDate, firstDay)
      if (isEnd) horizontalScroll(numberDays)
      else {
        setTaskDate(x => _.assign({}, x, { endDate: nextMonth }))
        search(_.assign(params, { date: [beginDate, nextMonth] }), numberDays)
        // setTimeout(() => horizontalScroll(numberDays), 0)
      }
      setCurrentMonth(nextMonth)
    } else if (type === 'current') { // 本月
      const firstDay = moment().startOf('month').format('YYYY-MM-DD')
      const lastDay = moment().endOf('month').format('YYYY-MM-DD')
      const isBegin = new Date(firstDay) >= new Date(beginDate) && new Date(endDate) >= new Date(firstDay)
      const isEnd = new Date(lastDay) >= new Date(beginDate) && new Date(endDate) >= new Date(lastDay)
      const numberDays = _.floor(getDaysBetween(beginDate, firstDay))
      if (isBegin && isEnd) horizontalScroll(numberDays)
      else {
        setTaskDate(_.assign({}, { beginDate: firstDay, endDate: lastDay }))
        search(_.assign(params, { date: [firstDay, lastDay] }), 0)
        // horizontalScroll(0)
      }
      setCurrentMonth(firstDay)
    } else if (type === 'toDay') {
      const toDay = moment().format('YYYY-MM-DD')
      const numberDays = _.floor(getDaysBetween(beginDate, toDay))
      const isToDay = new Date(toDay) >= new Date(beginDate) && new Date(endDate) >= new Date(toDay)
      const firstDay = moment().startOf('month').format('YYYY-MM-DD')
      const lastDay = moment().endOf('month').format('YYYY-MM-DD')
      if (isToDay) horizontalScroll(numberDays)
      else {
        setTaskDate(_.assign({}, { beginDate: firstDay, endDate: lastDay }))
        search(_.assign(params, { date: [firstDay, lastDay] }), 0)
        // horizontalScroll(0)
      }
      setCurrentMonth(toDay)
    }
  }, [taskDate, currentMonth, search, params])

  const Title = useCallback(() => {
    return <div className='board-title'>
      <div className='lef'>
        <Radio.Group options={radioOptions} onChange={e => setSelectedVal(e.target.value)} value={selectedVal} />
      </div>
      <div className='middle'>
        <div className='last-month' onClick={() => changeMonth('last')}>上一月</div>
        <div className='current-month' onClick={() => changeMonth('current')}>本月</div>
        <div className='toDay' onClick={() => changeMonth('toDay')}>今天</div>
        <div className='next-month' onClick={() => changeMonth('next')}>下一月</div>
      </div>
      <div className='rig'>
        <FilterQuery {...{ params, setParams, userOpt, search, taskDate, setTaskDate, setCurrentMonth }} />
        <div style={{ display: 'flex', color: '#b1b1b1' }}>
          <div className='explain-lable'>图例说明</div>
          <div className='explain-color'>
            <div>
              {
                _.map(new Array(10), (v, i) => {
                  const index = i >= 9 ? i + 1 : `0.${i + 1}`
                  return <div key={i} style={{ background: `rgba(255,125,0,${index})`, width: 18, height: 10 }}></div>
                })
              }
            </div>
            <div>颜色越深，该日期重合的任务越多</div>
          </div>
        </div>
      </div>
    </div >
  }, [changeMonth, params, search, selectedVal, taskDate, userOpt])

  return (
    <>
      <div className='fill flex-y ImpDisplayBoardMgt'>
        <Box className='x-card-singlegrid' loading={boxLoading} error={error} title={<Title />} data={treeData}>
          {
            !_.isEmpty(treeData) &&
            <Table
              columns={columns}
              dataSource={treeData}
              pagination={false}
              rowKey='id'
              size="small"
              className="m-cover-ant-table virtual-table"
              bordered
              scroll={{ y: 9999999, x: 1300 }}
              expandedRowKeys={expandedRowKeys}
              onExpand={(bool, row) => {
                if (bool) {
                  setExpandedRowKeys(x => [...x, row.id])
                  if ((selectedVal === 1 && row.level === 2) || (selectedVal === 2 && row.level === 1)) setHiddenId(x => _.filter(x, v => v !== row.id))
                }
                else {
                  const index = expandedRowKeys.findIndex((e) => e === row.id)
                  const newArray = [...expandedRowKeys]
                  newArray.splice(index, 1)
                  setExpandedRowKeys(newArray)
                  setHiddenId(x => [...x, row.id])
                }
              }}
            />
          }
        </Box>
      </div>
      {!_.isNil(show) && <TaskInfoDialog search={() => search(params)} close={() => setShow(null)} {...show} />}
    </>
  )

  function horizontalScroll(num) {
    const table = document.querySelector('.ant-table-body')
    if (table) return table.scrollLeft = 32 * num
    return
  }

}

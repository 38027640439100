import React, {useCallback, useMemo} from 'react';
import './QueryField.scss'
import {Card} from 'rootnet-ui';
import { Table, Popover, Radio, Switch  } from 'antd';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import {TextIconBtn} from "../../../../common/TextIconBtn";
import _ from 'lodash'
import AddFieldListPopover from "../addFieldListPopover/AddFieldListPopover";
import Icon from "../../../../../components/Icon";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

function getColumns(props){
    const {onRadioChange, delItem, changeUniqueKey} = props
    return [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: '字段名称',
            dataIndex: 'fieldName',
            key: 'fieldName',
            width: 110,
        },
        {
            title: '唯一key',
            dataIndex: 'uniqueFilterFields',
            key: 'uniqueFilterFields',
            width: 70,
            align: 'center',
            render: (value, obj) => {
                return <Switch checked={value} onClick={checked => changeUniqueKey(checked, obj)}/>
            }
        },
        {
            title: '视图查询展示',
            dataIndex: 'displayLevel',
            key: 'displayLevel',
            width: 220,
            render: (value, obj, index) => {
                return <Radio.Group value={value} onChange={e => onRadioChange(e.target.value, index)}>
                    <Radio value={'01'}>必选</Radio>
                    <Radio value={'02'}>可选</Radio>
                    <Radio value={'03'}>默认</Radio>
                    <Radio value={'04'}>不显示</Radio>
                </Radio.Group>
            }
        },
        {
            title: '操作',
            key: 'operation',
            width: 60,
            render: (value, obj) => <span className={'delete-text'} onClick={()=>delItem(obj.tableFieldName)}>删除</span>,
        },
    ];
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function QueryField(props) {
    const {fullScreen, setFullScreen, allFieldList,queryFieldList, setQueryFieldList} = props

    const showList = useMemo(()=>{
        if(_.isEmpty(allFieldList)) return []
        const allIdList = _.map(allFieldList, x => x.id)
        const filterShowList = _.filter(queryFieldList, x => _.includes(allIdList, x.fieldId))
        return _.map(filterShowList, item => {
            const fieldItem = _.find(allFieldList, x => x.id === item.fieldId)
            item['fieldName'] = `${fieldItem.name}-${fieldItem.fieldName}`
            item['tableFieldName'] = `${fieldItem.tableName}.${fieldItem.fieldId}`
            return item
        })
    },[allFieldList,queryFieldList])

    const notAddList = useMemo(()=>{
        const idList = _.map(queryFieldList, x => x.fieldId)
        return _.filter(allFieldList, x => !_.includes(idList,x.id))
    },[queryFieldList, allFieldList])

    const onRadioChange = useCallback((value, index)=>{
        setQueryFieldList(oldList => {
            const tempList = _.clone(oldList)
            tempList[index]['displayLevel'] = value
            return tempList
        })
    },[setQueryFieldList])

    const delItem = useCallback(delTableFieldName => {
        setQueryFieldList(oldList => {
            const tempList = _.clone(oldList)
            return _.filter(tempList, x => x.tableFieldName !== delTableFieldName)
        })
    },[setQueryFieldList])

    const changeUniqueKey = useCallback((checked, obj)=>{
        setQueryFieldList(oldList => {
            const tempList = _.clone(oldList)
            _.forEach(tempList, x => {
                if(x.tableFieldName === obj.tableFieldName){
                    x['uniqueFilterFields'] = checked
                }else{
                    x['uniqueFilterFields'] = false
                }
            })
            return tempList
        })
    },[setQueryFieldList])

    const extra = useMemo(()=>{
        return <div className={'flex center-y'}>
            <Popover trigger="click" content={<AddFieldListPopover list={notAddList} setList={setQueryFieldList} defaultParams={{displayLevel: '03'}}/>}>
                <TextIconBtn icon={'tianjia'} text={'添加字段'} disabled={_.isEmpty(notAddList)}/>
            </Popover>
            <Icon name={fullScreen === 'filterCondition'?'suoxiao':'fangda'} className={'full-screen-icon'} onClick={onClickFullScreenIcon}/>
        </div>
        function onClickFullScreenIcon(){
            setFullScreen(x => x ? null : 'queryField')
        }
    },[notAddList, setQueryFieldList,fullScreen, setFullScreen])

    const columns = useMemo(()=>{
        return getColumns({onRadioChange,delItem, changeUniqueKey})
    },[onRadioChange,delItem, changeUniqueKey])

    return <Card title={'查询字段'} className={`query-field-wrap ${fullScreen === 'queryField' ?'full-screen':''}`} contentClass={'flex-y center query-field-card'}
                 extra={extra} style={{display: fullScreen && (fullScreen !== 'queryField') ? 'none' : ''}}>
        <Table
            size={'small'}
            sticky={true}
            pagination={false}
            dataSource={showList}
            columns={columns}
            rowKey="fieldId"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            }}
        />
    </Card>

    function onSortEnd({ oldIndex, newIndex }){
        if (oldIndex !== newIndex) {
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setQueryFieldList(newList)
        }
    }

    function DraggableContainer(props){
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }

    function DraggableBodyRow({ className, style, ...restProps }){
        const index = queryFieldList.findIndex(x => x.fieldId === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    }
}

export default QueryField;
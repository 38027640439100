import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Button, Messager} from 'rootnet-ui'
import {RadioGroup} from 'rootnet-edit'
import './ResourceImportRequirement.scss'
import {Icon} from "../../../../../components";
import ResourcePlanBaseModel from "./resourcePlanBaseModel/ResourcePlanBaseModel";
import ResourcePlanCustomize from "./resourcePlanCustomize/ResourcePlanCustomize";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import _ from 'lodash'
import ResourceSelectReq from "../../resourceInfoCard/resourcePlan/resourceSelectReq/ResourceSelectReq";

const requirementOptions = [
    { text: "需求已录入", value: "recorded" },
    { text: "需求未录入", value: "notRecorded" },
]
// const resourcePlanOptions = [
//     { text: "文件导入", value: "fileImport" },
//     { text: "界面增加", value: "addNew" },
// ]
const templateTypeOptions = [
    { text: "根据基础模型计算", value: "baseModel" },
    { text: "自定义", value: "customize" },
]

function ResourceImportRequirement(props) {
    const {close, moduleOptions, department,setShowForecast,setResourceCalculate,setShowImportDialog,saveInfo, setSaveInfo} = props
    const {initTemplateType,initRequirementRecorded, initReqId, initPlanList = []} = useMemo(()=>saveInfo || {},[saveInfo])
    const [requirementRecorded, setRequirementRecorded] = useState(initRequirementRecorded?initRequirementRecorded:"recorded")
    const [reqId, setReqId] = useState(initReqId?initReqId:null)
    const [showReqSelectDialog, setShowReqSelectDialog] = useState(false)
    const [resourcePlan] = useState("addNew")
    const [templateType, setTemplateType] = useState(initTemplateType?initTemplateType:'baseModel')
    const [planList, setPlanList] = useState(initPlanList?initPlanList:[])
    const {doFetch: resourceCalculate} = usePost()
    const {data: reqInfo, doFetch: getReqInfo} = useGet()
    const [selectedModuleList, setSelectedModuleList] = useState([])

    useEffect(()=>{
        if(_.isNil(reqId)) return
        getReqInfo('/userPlan/storyTranslate?storyId='+reqId)
    },[getReqInfo,reqId])

    const submitForecast = useCallback(()=>{
        const positionSortList = ['demand','frontEndDev','backEndDev','otherDev','test']
        let sortPlanList = _.sortBy(planList,[sortPosition])
        if(templateType === 'customize'){
            sortPlanList = _.filter(sortPlanList, x => {
                const noneTech = _.isNil(_.get(x, 'position')) || _.isNil(_.get(x, 'techLevel'))
                const noneInvestment = _.isNil(_.get(x,'investment')) || _.get(x,'investment') === '' || _.get(x,'investment') === 0
                return !(noneTech || noneInvestment);
            })
        }
        const postParams = {
            type: templateType,
            department: department,
            reqId: requirementRecorded === 'recorded' ? reqId: null,
            demandInputList: sortPlanList,
        }
        resourceCalculate('/demandInput/resourceCalculate',postParams).then(res => {
            setSaveInfo({
                initTemplateType:templateType,
                initRequirementRecorded: requirementRecorded,
                initReqId: reqId,
                initPlanList: planList,
            })
            setResourceCalculate(res)
            setShowForecast(true)
            setShowImportDialog(false)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })

        function sortPosition(o){
            return _.findIndex(positionSortList,x => x === _.get(o,'position'))
        }
    },[resourceCalculate,department,planList,templateType,setShowForecast,setResourceCalculate, setShowImportDialog,
        requirementRecorded,reqId,setSaveInfo])

    const onTemplateTypeChange = useCallback((newValue)=>{
         if(newValue === 'baseModel'){
             const moduleList = _.uniq(_.map(planList,'module'))
             setSelectedModuleList(moduleList)
             setTemplateType(newValue)
             setPlanList([])
         }else{
             setTemplateType(newValue)
         }
    },[planList])

    return <Dialog header={'需求导入'} className={'resource-import-requirement'} confirmButtonVisible={resourcePlan === 'addNew'}
                   cancel={close} confirm={submitForecast} confirmButtonDisabled={_.isEmpty(planList)}>
        <div className={'step-wrap flex center-y requirement-warp'}>
            <div className="step-header">1、选择需求</div>
            <RadioGroup options={requirementOptions} value={requirementRecorded} onChange={setRequirementRecorded}/>
        </div>
        {
            requirementRecorded === 'recorded' &&
            <div className={`mock-req-select flex center-y ${reqId?'':'placeholder'}`} onClick={()=>setShowReqSelectDialog(true)}>
                {reqId?`${_.get(reqInfo,'storyId') || ''}-${_.get(reqInfo,'title') || ''}`:'请选择需求'}
            </div>
        }
        {
            showReqSelectDialog &&
            <ResourceSelectReq close={()=>setShowReqSelectDialog(false)} outerSetReqId={setReqId} initReqId={reqId}/>
        }
        <div className={'step-wrap flex center-y resource-plan-wrap'}>
            <div className="step-header">2、资源规划</div>
            <RadioGroup options={templateTypeOptions} value={templateType} onChange={onTemplateTypeChange}/>
            {/*<RadioGroup options={resourcePlanOptions} value={resourcePlan} onChange={setResourcePlan}/>*/}
        </div>
        {
            resourcePlan === 'fileImport' &&
            <div className={'file-import-next-step-wrap'}>
                <div className={'download-template flex center-y'}>
                    <Icon name='daochu' className={'download-icon'}/>
                    <span className={'download-text'}>下载导入模版</span>
                </div>
                <div className={'step-wrap flex center-y'}>
                    <div className="step-header">3、上传附件</div>
                </div>
                <div className={'upload-memo'}>目前支持的文件类型为.xlsx</div>
                <Button primary className={'upload-btn'}>添加文件</Button>
            </div>
        }
        {
            resourcePlan === 'addNew' &&
            <div className={'add-new-next-step-wrap'}>
                {/*<div className={'template-type-radio-group'}>*/}
                {/*    <RadioGroup options={templateTypeOptions} value={templateType} onChange={setTemplateType}/>*/}
                {/*</div>*/}
                {
                    templateType === 'baseModel' &&
                    // <ResourcePlanBaseModel initPlanList={initTemplateType === 'baseModel'?initPlanList:[]} {...{moduleOptions,department,setPlanList,}}/>
                    <ResourcePlanBaseModel initPlanList={planList} {...{moduleOptions,department,setPlanList,selectedModuleList, setSelectedModuleList}}/>
                }
                {
                    templateType === 'customize' &&
                    // <ResourcePlanCustomize initPlanList={initTemplateType === 'customize'?initPlanList:[]} {...{moduleOptions,department,setPlanList}}/>
                    <ResourcePlanCustomize initPlanList={planList} {...{moduleOptions,department,setPlanList}}/>
                }
            </div>
        }
    </Dialog>
}

export default ResourceImportRequirement;
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { CheckBox } from "rootnet-edit";
import _ from "lodash";
import convertTableAlign from '../../../../common/view/convertTableAlign';
import findConvert from '../../../../common/view/findConvert';
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig';
import { usePost } from 'rootnet-biz/lib/hooks';
import useRefreshList from '../../../../common/view/hooks/useRefreshList';
import { DataGrid, Dialog, Pagination, Button } from "rootnet-ui";
import ViewArea from '../../../../common/view/ViewArea';
import { Box } from '../../../../common/commonComponent';
import '../../../../common/view/viewQueryDialog/ViewQueryDialog.scss'
import RequirementDetailDialog from '../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog';
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList';
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions';

const ENTER_DETAIL_FIELD = 'story.title'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, selectedId, multiple, onRowClick, isSelectAll, onSelectAll, setCurrentInfo } = props

  const customColumns = []

  return [
    {
      header: <CheckBox value={isSelectAll} onChange={onSelectAll} />, width: 40, stretch: false, align: 'center', convert: (v, o) => {
        return <div className={'check-item flex center'} onClick={() => onRowClick(o)}>
          <CheckBox value={multiple ? _.includes(selectedId, o.id) : o.id === selectedId} />
        </div>
      }
    },
    { header: '#', width: 40, stretch: false, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div style={{ color: '#5477FF', cursor: 'pointer' }} onClick={() => setCurrentInfo({ mode: 'detail', id: o.id })}>
        {showValue}
      </div>
    }
    return showValue
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function PlanAndCaseQueryDialog(props) {
  const { initValue, initItemValue, outerSetId, outerSetItem, close, funcCode, bizField, bizName = '', multiple,
    initLoading = false, replaceParams, textHint = '', appendObj, isClose = true } = props
  const [selectedId, setSelectedId] = useState(initValue)
  const [selectedItem, setSelectedItem] = useState(initItemValue)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(funcCode, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [sortConfig, setSortConfig] = useState()
  const [isSelectAll, setIsSelectedAll] = useState(false)
  const [pageList, setPageList] = useState([])
  const [currentInfo, setCurrentInfo] = useState()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, appendObj })

  const dateOptions = useGetDateOptions()

  const onRowClick = useCallback((item) => {
    if (multiple) {
      if (_.includes(selectedId, item.id)) {
        setSelectedId(oldList => {
          return _.filter(oldList, x => x !== item.id)
        })
        setSelectedItem(oldList => {
          return _.filter(oldList, x => x.id !== item.id)
        })
      } else {
        setSelectedId(oldList => {
          return _.compact(_.concat(oldList, [item.id]))
        })
        setSelectedItem(oldList => {
          const bizItem = _.map(bizField, val => _.find(fieldList, x => `${x.tableName}.${x.fieldId}` === val))
          const bizId = _.assign(..._.map(bizItem, o => ({ [_.get(o, 'fieldId')]: _.get(item, _.get(o, 'columnId')) })))
          const newItem = _.assign({}, {
            id: item.id,
          }, bizId)
          return _.compact(_.concat(oldList, [newItem]))
        })
      }
    } else {
      if (selectedId === item.id) {
        setSelectedId(null)
        setSelectedItem(null)
      } else {
        setSelectedId(item.id)
        const bizItem = _.map(bizField, val => _.find(fieldList, x => `${x.tableName}.${x.fieldId}` === val))
        const bizId = _.assign(..._.map(bizItem, o => ({ [_.get(o, 'fieldId')]: _.get(item, _.get(o, 'columnId')) })))
        setSelectedItem(_.assign({}, {
          id: item.id,
        }, bizId))
      }
    }
  }, [selectedId, fieldList, bizField, multiple])

  const onSelectAll = useCallback(() => {
    if (!multiple) return
    if (_.isEmpty(pageList)) return
    if (isSelectAll) {
      const pageIdList = _.map(pageList, 'id') || []
      setSelectedId(oldList => {
        return _.filter(oldList, x => !_.includes(pageIdList, x))
      })
      setSelectedItem(oldList => {
        return _.filter(oldList, x => !_.includes(pageIdList, x.id))
      })
    } else {
      setSelectedId(oldList => {
        const addIdList = _.map(pageList, 'id') || []
        return _.compact(_.uniq(_.concat(oldList, addIdList)))
      })
      setSelectedItem(oldList => {
        const selectedIdList = _.map(oldList, 'id')
        const addList = _.filter(pageList, x => !_.includes(selectedIdList, x.id))
        const bizItem = _.map(bizField, val => _.find(fieldList, x => `${x.tableName}.${x.fieldId}` === val))

        const newItemList = []
        _.forEach(addList, item => {
          const bizId = _.assign(..._.map(bizItem, o => ({ [_.get(o, 'fieldId')]: _.get(item, _.get(o, 'columnId')) })))
          const newItem = _.assign({}, {
            id: item.id,
          }, bizId)
          newItemList.push(newItem)
        })
        return _.compact(_.concat(oldList, newItemList))
      })
    }
  }, [isSelectAll, pageList, multiple, fieldList, bizField])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { selectedId, multiple, onRowClick, isSelectAll, onSelectAll, setCurrentInfo },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        const sortList = _.sortBy(data, x => x[sort.column])
        return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
      }
    },
  })

  useEffect(() => {
    setPageList(dataGridList)
  }, [dataGridList])

  useEffect(() => {
    if (!multiple) return setIsSelectedAll(false)
    if (_.isEmpty(dataGridList)) return setIsSelectedAll(false)
    if (_.isEmpty(selectedId)) return setIsSelectedAll(false)
    const pageAllIdList = _.map(dataGridList, 'id')
    const isSame = _.every(pageAllIdList, x => _.includes(selectedId, x))
    setIsSelectedAll(isSame)
  }, [dataGridList, multiple, selectedId])

  const showList = useMemo(() => {
    let showList = dataGridList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, dataGridList])

  useEffect(() => {
    if (multiple) {
      _.forEach(list, o => {
        return o._rowClass = _.includes(selectedId, o?.id) ? 'select_row' : ''
      })
    } else {
      _.forEach(list, o => {
        return o._rowClass = selectedId === o?.id ? 'select_row' : ''
      })
    }
    forceUpdate()
  }, [list, selectedId, multiple])

  const confirm = useCallback(() => {
    if (outerSetId) {
      outerSetId(selectedId)
    }
    if (outerSetItem && initValue !== selectedId) {
      outerSetItem(selectedItem)
    }
    isClose && close()
  }, [outerSetId, close, selectedId, outerSetItem, selectedItem, initValue, isClose])

  const header = useMemo(() => {
    if (multiple) {
      // return `${bizName}查询【已选择${_.size(selectedId)}项】`
      return <div className={'title-wrap flex center-y'}>
        <div className="title-text">{bizName}查询</div>
        <div className="selected-num-text">(已选{_.size(selectedId)}个)</div>
      </div>
    } else {
      return `${bizName}查询`
    }
  }, [bizName, selectedId, multiple])
  return (
    <>
      <Dialog header={header} className={'view-query-dialog'} cancel={close} confirm={confirm} confirmButtonDisabled={initLoading} footerVisible={false} >
        <div className={'issue-view-query-content flex-y'}>
          <ViewArea funcCode={funcCode} allOptions={optionsConfig} search={setParams} loading={boxLoading}
            {...{
              getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
              currentViewId, setCurrentViewId, params, pageSize, replaceParams
            }} />
          <Box title={`${bizName}列表`} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
            <DataGrid option={options} data={showList}
            // onRowClick={(item)=>onRowClick(item)}
            />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
              onChange={(pageNum, pageSize) => {
                setPageSize(pageSize)
                setParams(x => _.assign({}, x, { pageNum, pageSize }))
              }} />
          </Box>
        </div>
        <div className="mock-footer flex center-y">
          <div className='text-hint'>{textHint}</div>
          <div className="btn-group flex">
            <Button normal onClick={close}>取消</Button>
            <Button primary onClick={confirm} disable={initLoading}>确认</Button>
          </div>
        </div>
      </Dialog>
      {
        !_.isNil(currentInfo) &&
        <RequirementDetailDialog
          close={() => setCurrentInfo(null)}
          currentInfo={currentInfo}
        />
      }
    </>
  )
}

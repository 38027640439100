import React, { useCallback, useContext, useMemo, useState } from 'react'
import _ from 'lodash'
import { Switch } from 'antd'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import InfoFormLog from '../customerSystemMgt/customerDetail/contactPerson/infoFormLog'
import useGetDateOptions from '../../common/view/hooks/useGetDateOptions'
import useGetViewConfig from '../../common/view/hooks/useGetViewConfig'
import convertTableAlign from '../../common/view/convertTableAlign'
import useRefreshList from '../../common/view/hooks/useRefreshList'
import useGetTreeList from '../../common/view/hooks/useGetTreeList'
import { ScheduleManagementContext } from '../../common/Context'
import { isNil } from '../../appraise/components/method'
import { useFuncCode } from '../../common/commonMethod'
import findConvert from '../../common/view/findConvert'
import { TextIconBtn } from '../../common/TextIconBtn'
import { Box } from '../../common/commonComponent'
import ViewArea from '../../common/view/ViewArea'
import './index.scss'

const ENTER_DETAIL_FIELD = 'customer_linker.linker'
const EDIT_LOGIN_STATUS = 'customer_linker.open_flag'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, isEditState, upDateStatus } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: !_.includes([ENTER_DETAIL_FIELD, EDIT_LOGIN_STATUS], `${fieldItem.tableName}.${fieldItem.fieldId}`),
        _custom: fieldItem.custom,
        convert: (v, o, i, d) => convertEditField(v, o, fieldItem, d)
      }
    }
  }

  function convertEditField(v, o, fieldItem, listData) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (_.includes(['customer_linker.email', 'customer_linker.state'], tableField)) {
      o[fieldItem.fieldId] = v
    }
    if (isEditState && tableField === EDIT_LOGIN_STATUS) {
      return <div style={{ width: '100%' }} className='flex flex-y center-x center-y'>
        <Switch
          disabled={v !== 'Y' && (isNil(o.email) || o.state !== 'Y')}
          defaultChecked={v === 'Y'}
          checkedChildren="开启"
          unCheckedChildren="关闭"
          onChange={flag => {
            if (v !== 'Y' && (isNil(o.email) || o.state !== 'Y')) return
            upDateStatus(o.id, flag)
          }}
        />
      </div>
    }
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} >
        <div className="enter-detail-field" onClick={() => {
          setCurrentInfo({
            id: _.get(o, 'id'),
            mode: 'detail',
          })
        }}>
          {showValue || '-'}
        </div>
      </div>
    }
    return <div className={'common-edit-field'}>
      {showValue || '-'}
    </div>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '2020'

export default function CustomerContactMgt(props) {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentInfo, setCurrentInfo] = useState()
  const [factor, setFactor] = useState()
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)
  const [isEdit, isEditState] = useFuncCode(['202000', '202001'])
  const { doFetch: upDateStatusFetch } = usePost()
  // const { doFetch: delFetch } = usePost()
  const [isDisable, setIsDisable] = useState(false)

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const upDateStatus = useCallback((id, flag) => {
    if (isDisable) return
    setIsDisable(true)
    upDateStatusFetch('/customerLinker/new/update', { id, openFlag: flag ? 'Y' : 'N' })
      .then(() => {
        setIsDisable(false)
        Messager.show('更新成功', { icon: 'success' })
        // refreshList()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
        // refreshList()
      })
  }, [upDateStatusFetch, isDisable])

  // const delItem = useCallback((id) => {
  //   if (isDisable) return
  //   setIsDisable(true)
  //   delFetch(`${DEL_URL}?id=${id}`)
  //     .then(() => {
  //       setIsDisable(false)
  //       Messager.show('删除成功', { icon: 'success' })
  //       refreshList()
  //     })
  //     .catch(err => {
  //       setIsDisable(false)
  //       Messager.show(err._message, { icon: 'error' })
  //       refreshList()
  //     })
  // }, [delFetch, isDisable, refreshList])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: {
      setCurrentInfo, isEditState, upDateStatus
    }
  })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '客户联系人')
  }, [downloadPostProgress, currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        isEdit && <TextIconBtn icon='tianjia' text='新增' onClick={() => {
          setCurrentInfo({ mode: 'add' })
        }} />
      }
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [isEdit, exportViewList, allowExport])

  return (
    <div className='customer-contact-mgt fill flex-y'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'客户联系人'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['add', 'edit', 'detail'], _.get(currentInfo, 'mode')) &&
        <InfoFormLog close={() => setCurrentInfo(null)} {...{
          currentInfo, refreshViewList: refreshList, referenceId: _.get(currentInfo, 'id'), isEdit,
        }} />
      }
    </div>
  )

}
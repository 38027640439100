import {useCallback} from "react";
import _ from "lodash";
import {Messager} from 'rootnet-ui'

export default function useRefreshList(props){
    const {currentViewId, params, allColumns, getList, setParams, funcCode, searchUrl = '/UserSetting/maintain', getFactor,
        afterRefresh, replaceViewId, setLastSearchParams, showAllNodes = 'Y', appendObj} = props
    return useCallback((replaceParams = null)=>{
        if(_.isNil(currentViewId)) return
        if(_.isNil(allColumns)) return
        if(_.isNil(params)) return
        const pageParams = _.pick(params, ['pageNum','pageSize'])
        const formParam = _.omit(params,['pageNum','pageSize'])
        const conditionList = _.map({...formParam,...replaceParams}, (v,k) => {
            const currentItem = _.find(allColumns, x => `${x.tableName}.${x.fieldId}` === k)
            return ({field: k, relation:_.get(currentItem, 'formQueryType') || '=',value: _.trim(v) || ''})
        })
        const postParams = _.assign({
            action: 'query',
            id: currentViewId,
            type: '01',
            menuCode: funcCode,
            ...pageParams,
            factor: JSON.stringify({
                conditionRelation: 'and',
                conditionList: conditionList
            }),
            replaceViewId,
            showAllNodes,
        },!_.isNil(appendObj) && appendObj)
        if(getFactor){
            getFactor(postParams.factor)
        }
        if(setLastSearchParams){
            setLastSearchParams(postParams)
        }
        getList(searchUrl,postParams).then((res)=>{
            if(params.pageNum > 1 && res.rows.length === 0){
                setParams(x => ({...x,pageNum: x.pageNum-1}))
            }
            if(afterRefresh){
                afterRefresh(res)
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' });
        })
    },[currentViewId, params, allColumns, getList, setParams, funcCode, searchUrl,getFactor, afterRefresh, replaceViewId, setLastSearchParams, showAllNodes, appendObj])
}
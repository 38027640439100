import React, { useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Form, FormInput, TextArea } from 'rootnet-edit'
import { MessageBox, DataGrid, Messager } from 'rootnet-ui'

const option = {
  autoFill: true,
  columns: [
    { header: '', bind: 'title', align: 'right' },
    { header: '修改前', bind: 'before', width: 200, tooltip: true },
    { header: '修改后', bind: 'after', width: 200, tooltip: true, style: { color: '#E15722' } },
  ]
}


export default function ImportantAdjustment(props) {
  const { data, cancel, confirm, params, setParams } = props
  const [err, setErr] = useState()
  return (
    <MessageBox
      headerVisible={false}
      confirm={commit}
      cancel={cancel}
      className='important-adjustment'
    >
      <DataGrid option={option} data={data} />
      <Form onChange={setParams} value={params} onError={setErr} error={err}>
        <FormInput
          required
          label='修改理由'
          className={cls('reason', { error: _.size(_.trim(params?.modifyReason)) >= 5 ? false : true })}
          placeholder='请输入至少5位'
          componentWidth={520}
          component={TextArea}
          style={{ margin: '15px 0 0 0' }}
          bind='modifyReason'
        />
      </Form>
    </MessageBox>
  )
  function commit() {
    if (_.size(_.trim(params?.modifyReason)) < 5) return Messager.show('修改理由不得小于5', { icon: 'error' })
    confirm()
  }
}

import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import cls from 'clsx'
import _ from 'lodash'
import { Tabs } from 'antd'
import { FormInput, Display, Form, RadioGroup } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import RichTextEditor from '../../../../common/richTextEditor/TinyEditor'
import './index.scss'
import useGet from "rootnet-biz/es/hooks/useGet";
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'

const { TabPane } = Tabs

const HFormInput = (props) => <FormInput horizontal labelWidth={110} componentWidth={200} {...props} />

const listForm = [
  { bind: 'bugDesc', name: '问题描述', html: true }, // 问题描述
  { bind: 'fixDesc', name: '修改方案', html: true }, // 修改方案
  {
    bind: 'database',
    name: '修改数据库脚本',
    children: [
      { bind: 'dataBaseDesc', name: 'Oracle', height: 750, },
      { bind: 'dataBaseDescMySql', name: 'MySql', height: 750, },
      { bind: 'dataBaseDescSQLServer', name: 'SQLServer', height: 750, },
      { bind: 'dataBaseDescDM', name: 'DM', height: 200, },
      { bind: 'dataBaseQury', name: '查询脚本', height: 300, },
    ]
  }, // Oracle MySql SQLServer 查询脚本
  { bind: 'needModedText', name: '修改文档说明', height: 300, }, // 修改文档说明
  { bind: 'influenceDesc', name: '影响分析', height: 600, }, // 影响分析
  { bind: 'stkinfo', name: '业务概要说明', height: 200, }, // 业务概要说明
  { bind: 'clientDesc', name: '客户问题描述', height: 200, }, // 客户问题描述
  { bind: 'upgradeDesc', name: '升级配置说明', height: 200, }, // 升级配置说明
]

let OCCUR_EVENT = ''

const upgradeDescOptions = [
  { text: '无', value: 'N' },
  { text: '有', value: 'Y' },
]

export default function TrackingInfo(props) {
  const { isDetail, editFormData, setEditFormData, problemDescRef, modificationRef } = props
  const defaultList = _.assign(..._.map(listForm, o => ({ [o.bind]: true })))
  const [expand, setExpand] = useState(defaultList)
  const [activeKey, setActiveKey] = useState('dataBaseDesc')
  const [scrollBind, setScrollBind] = useState()
  const [hasUpgradeDesc, setHasUpgradeDesc] = useState()
  const [upgradeDescTemplate, setUpgradeDescTemplate] = useState()
  const { doFetch: getTemplate } = useGet()

  useEffect(() => {
    getTemplate('/issue/getDemoTxt?id=53').then(res => {
      const template = _.get(res, 'demoValue')
      setUpgradeDescTemplate(template)
    })
  }, [getTemplate])

  useEffect(() => {
    const upgradeDesc = _.get(editFormData, 'upgradeDesc')
    if (upgradeDesc === '无') {
      setHasUpgradeDesc('N')
    } else {
      setHasUpgradeDesc('Y')
    }
  }, [editFormData])

  const onUpgradeDescChange = useCallback((value) => {
    setHasUpgradeDesc(value)
    setEditFormData(old => _.assign({}, old, { upgradeDesc: value === 'Y' ? upgradeDescTemplate : '无' })
    )
  }, [upgradeDescTemplate, setEditFormData])

  const scrollPosition = useCallback((height = 0) => {
    const dom = document.querySelector('.tracking-detail-info')
    if (_.isNil(dom)) return
    dom.scrollTop = height
  }, [])

  const anchorBall = useCallback((index, occurEvent = 'click') => {
    if (OCCUR_EVENT === 'click' && occurEvent === 'scroll') return OCCUR_EVENT = ''
    setScrollBind(_.get(listForm, `[${index}].bind`))
    const dom = document.querySelector('.tracking-anchor-ink-ball')
    if (_.isNil(dom)) return
    const top = 14
    dom.style.top = index === 0 ? `${top}px` : `${((index * 36) + top)}px`
  }, [])

  useEffect(() => {
    anchorBall(0)
  }, [anchorBall])

  useLayoutEffect(() => {
    const dom = document.querySelector('.tracking-detail-info')
    if (_.isNil(dom)) return
    dom.addEventListener('scroll', (e) => anchorScroll(e))
    return dom.removeEventListener('scroll', (e) => anchorScroll(e))
    function anchorScroll(e) {
      const numArr = _.map(listForm, o => {
        const dom = document.querySelector(`.area-wrap${o.bind}`)
        return dom.scrollHeight + 8
      })
      const heightArr = _.map(numArr, (v, i) => {
        return _.sum(numArr.slice(0, i + 1))
      })
      const anchorIndex = _.findIndex(heightArr, v => v > e.target.scrollTop)
      anchorBall(anchorIndex, 'scroll')
    }
  }, [anchorBall])

  return (
    <div className='tracking-detail-info' style={{ height: '100%', overflow: 'auto' }}>
      <div id='tracking-info' className={'tracking-info'}>
        <Form value={editFormData} onChange={setEditFormData}>
          {
            _.map(listForm, item => {
              return <div key={item.bind} className={`area-wrap area-wrap${item.bind}`}>
                <div className="area-header flex center-y">
                  <Icon
                    name="arrow_drop_down"
                    className='fold-icon'
                    style={{ transform: expand[item.bind] ? 'none' : 'rotate(-90deg)' }}
                    onClick={() => setExpand(x => _.assign({}, x, { [item.bind]: !x[item.bind] }))}
                  />
                  {item.name}
                  {
                    item.bind === 'upgradeDesc' && !isDetail &&
                    <RadioGroup options={upgradeDescOptions} value={hasUpgradeDesc} onChange={onUpgradeDescChange} />
                  }
                </div>
                <div className="area-content-wrap" style={{ height: expand[item.bind] ? '' : 0 }}>
                  {
                    (item.html && isDetail) && <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: _.get(editFormData, `${item.bind}`) }} />
                  }
                  {
                    (item.html && !isDetail) && <div className="rich-text-area">
                      <RichTextEditor ref={item.bind === 'bugDesc' ? problemDescRef : modificationRef} />
                    </div>
                  }
                  {
                    (!_.isEmpty(item.children) && !item.html) &&
                    <Tabs className='database-tabs' activeKey={activeKey} onChange={setActiveKey}>
                      {
                        _.map(item.children, o => (
                          <TabPane forceRender={true} tab={o.name} key={o.bind}>
                            <HFormInput label='' className='english-num-styley' bind={o.bind} counter={false} maxLength={99999} component={isDetail ? Display : TextAreaInput} componentStyle={isDetail ? {} : { height: o.height }} />
                          </TabPane>
                        ))
                      }
                    </Tabs>
                  }
                  {
                    (_.isEmpty(item.children) && !item.html) &&
                    <HFormInput label='' bind={item.bind} counter={false} maxLength={99999} component={isDetail ? Display : TextAreaInput} componentStyle={isDetail ? {} : { height: item.height }} />
                  }
                </div>
              </div>
            })
          }
        </Form>
      </div>

      <div className='tracking-detail-anchor'>
        <div className='tracking-anchor'>
          <div className='tracking-anchor-ink'><span className='tracking-anchor-ink-ball visible'></span></div>
          {
            _.map(listForm, item => {
              return <div
                key={item.bind}
                className='tracking-anchor-link'
              >
                <span
                  onClick={() => {
                    const index = _.findIndex(listForm, o => item.bind === o.bind)
                    const filterList = listForm.slice(0, index)
                    const numArr = _.map(filterList, o => {
                      const dom = document.querySelector(`.area-wrap${o.bind}`)
                      return dom.scrollHeight + 8
                    })
                    OCCUR_EVENT = 'click'
                    anchorBall(index, 'click')
                    scrollPosition(_.sum(numArr))
                  }}
                  className={cls('tracking-anchor-link-title', { 'tracking-anchor-link-title-active': item.bind === scrollBind })}>{item.name}</span>
              </div>
            })
          }
        </div>
      </div>

    </div>
  )
}
import {useEffect} from 'react';
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";

// 获取当前菜单所有列字段信息
export default function useGetAllColumns(funcCode) {
    const {data: allColumns, doFetch: getAllColumns} = useGet()
    useEffect(()=>{
        if(_.isNil(funcCode)) return
        getAllColumns('/FieldDefinition/getFields?funcCode='+funcCode)
    },[getAllColumns,funcCode])

    return allColumns
}
import React, {useEffect, useState, useMemo, useCallback} from 'react'
import _ from 'lodash'
import { Icon } from '../../../../../components'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import DevelopmentCompletion from './developmentCompletion'
import Matchcode from './matchcode'
import {TextIconBtn} from "../../../../common/TextIconBtn";
import {useGet, usePost} from "rootnet-biz/lib/hooks";
import TraceRelateComponentList from "./traceRelateComponent/TraceRelateComponentList";
import TraceRelateComponentSelectDialog
  from "./traceRelateComponent/traceRelateComponentSelectDialog/TraceRelateComponentSelectDialog";
import TraceRelatePipelineList from "./traceRelatePipeline/TraceRelatePipelineList";

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=CHECKLIST', // 修改类型
  'common/globalconst?globalConst=MODIFYSTATE', // 当前状态
  'common/globalconst?globalConst=YES_OR_NO', // 是否修改字典、是否包含模板、分支代码修改标识
  'common/globalconst?globalConst=PerformanceFlag', // 性能影响
  'common/globalconst?globalConst=AUDITLIST', // 复核结果
]

export default function DevelopmentInformation(props) {
  const { allUserRes, data, loading, refresh, tracerState, isClose, isCts, traceBizId, relateBizId, traceDetail } = props
  const {data: relateComponentDetail, doFetch: getRelateComponentDetail, loading: relateComponentDetailLoading} = useGet()
  const [relateComponentList, setRelateComponentList] = useState([])
  const {data: relatePipelineList, doFetch: getRelatePipelineList, loading: relatePipelineListLoading} = usePost()
  const [foldKeyList, setFoldKeyList] = useState([])
  const [showDialogInfo, setShowDialogInfo] = useState()

  const changeFold = useCallback((key)=>{
    setFoldKeyList(oldList =>{
      if(_.includes(oldList, key)){
        return _.filter(oldList, x => x !== key)
      }else{
        return _.concat(oldList, key)
      }
    })
  },[])

  const refreshRelateComponentList = useCallback(()=>{
    if(_.isNil(traceBizId)) return
    getRelateComponentDetail('/devops/uprods/trace/program/detail?traceId='+traceBizId).then(res => {
      setRelateComponentList(_.isNil(res?.id)?[]:[res])
    })
  },[getRelateComponentDetail, traceBizId])

  useEffect(()=>{
    refreshRelateComponentList()
  },[refreshRelateComponentList])

  const refreshRelatePipelineList = useCallback(()=>{
    if(_.isNil(traceBizId)) return
    getRelatePipelineList('/devops/ubs/pipeline/rimsPipelineList',{traceId: traceBizId})
  },[getRelatePipelineList, traceBizId])

  useEffect(()=>{
    refreshRelatePipelineList()
  },[refreshRelatePipelineList])

  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [typeOpt, stateOpt, whetherOpt, performanceFlagOpt, codeReviewResultOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    const [d1, d2, d3, d4, d5] = globalConstOptionsRes
    const filterStatus = _.filter(d5, o => o.displayFlag !== 'N')
    return _.map([d1, d2, d3, d4, filterStatus], x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div>
      <div className="area-wrap">
        <div className="area-header flex center-y">
          <Icon
            name="arrow_drop_down"
            className='fold-icon'
            style={{ transform: _.includes(foldKeyList,'devStatus') ? 'rotate(-90deg)' : 'none' }}
            onClick={() => changeFold('devStatus')}
          />
          开发完成情况
        </div>
        <div className="area-content-wrap" style={{ height: _.includes(foldKeyList,'devStatus') ? 0 : '' }}>
          <DevelopmentCompletion {...{ allUserRes, data, loading, refresh, typeOpt, stateOpt, whetherOpt, performanceFlagOpt, tracerState, isClose, isCts }} />
        </div>
      </div>
      <div className="area-wrap">
        <div className="area-header flex center-y">
          <Icon
            name="arrow_drop_down"
            className='fold-icon'
            style={{ transform: _.includes(foldKeyList,'codeReview') ? 'rotate(-90deg)' : 'none' }}
            onClick={() => changeFold('codeReview')}
          />
          代码检视情况
        </div>
        <div className="area-content-wrap" style={{ height: _.includes(foldKeyList,'codeReview') ? 0 : '' }}>
          <Matchcode {...{ allUserRes, data, loading, refresh, stateOpt, codeReviewResultOpt, tracerState, isClose }} />
        </div>
      </div>
      <div className="area-wrap">
        <div className="area-header flex center-y space-between">
          <div className={'flex center-y'}>
            <Icon
                name="arrow_drop_down"
                className='fold-icon'
                style={{ transform: _.includes(foldKeyList,'relateComponent') ? 'rotate(-90deg)' : 'none' }}
                onClick={() => changeFold('relateComponent')}
            />
            关联组件
          </div>
          <div className={'flex center-y'}>
            <TextIconBtn style={{fontWeight: 'normal', marginRight: 8}} icon={'guanlian'} text={'关联组件'}
                         onClick={()=>setShowDialogInfo({module: 'relateComponent'})}/>
          </div>
        </div>
        <div className="area-content-wrap" style={{ height: _.includes(foldKeyList,'relateComponent') ? 0 : '' }}>
          <TraceRelateComponentList traceBizId={traceBizId} list={relateComponentList} refreshList={refreshRelateComponentList}
                                    listLoading={relateComponentDetailLoading}
                                    {...{relateBizId, traceDetail}}/>
        </div>
      </div>
      <div className="area-wrap">
        <div className="area-header flex center-y space-between">
          <div className={'flex center-y'}>
            <Icon
                name="arrow_drop_down"
                className='fold-icon'
                style={{ transform: _.includes(foldKeyList,'pipeline') ? 'rotate(-90deg)' : 'none' }}
                onClick={() => changeFold('pipeline')}
            />
            关联流水线
          </div>
          <div className={'flex center-y'}>
            <TextIconBtn style={{fontWeight: 'normal', marginRight: 8}} icon={'shuaxin1'} text={'刷新列表'}
                         onClick={refreshRelatePipelineList}/>
          </div>
        </div>
        <div className="area-content-wrap" style={{ height: _.includes(foldKeyList,'pipeline') ? 0 : '' }}>
          <TraceRelatePipelineList list={relatePipelineList} traceBizId={traceBizId} refreshList={refreshRelatePipelineList}
                                   listLoading={relatePipelineListLoading}/>
        </div>
      </div>
      {
        showDialogInfo?.module === 'relateComponent' &&
          <TraceRelateComponentSelectDialog close={()=>setShowDialogInfo(null)} traceBizId={traceBizId} refreshList={refreshRelateComponentList}
                                            id={relateComponentDetail?.id} isRelated={!_.isNil(relateComponentDetail)}/>
      }
    </div>
  )
}

import React, { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import clsx from "clsx";
import { Select, Tooltip } from "antd";
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Dialog } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import { formList, GLOBAL_CONST_URLS, OPTIONS_URLS } from './formList'
import { isNil } from '../../../../appraise/components/method'
import { errorTips, successTips } from '../../../../../utils/messagerTips'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { Icon } from '../../../../../components';
import './index.scss'

const HFormInput = props => <FormInput horizontal labelWidth={95} componentWidth={200} {...props} />

const ADD_URL = '/releaseinfo/addPre'

export default function AddPlanVersion(props) {
  const { close, refresh = () => { }, versionOpt } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstRes } = useGet(GLOBAL_CONST_URLS)

  const { releasebasic } = useMemo(() => params || {}, [params])

  const [productLineOpt, typeOpt, natureOpt, stateOpt] = useMemo(() => {
    if (_.isEmpty(globalConstRes)) return []
    return _.map(globalConstRes, v => convertGlobalConstOptions(v))
  }, [globalConstRes])

  const [customerSystemOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes
    return [
      _.concat([{ label: '全部客户', text: '全部客户', value: 'ALL' }], _.map(d1, o => ({ label: o.projectName, text: o.projectName, value: o.projectId }))),
    ]
  }, [optionsRes])

  const versionListOpt = useMemo(() => {
    let addDefault = []
    if (_.isEmpty(versionOpt)) return []
    const filterData = _.filter(versionOpt, o => !isNil(o.value))
    if (!isNil(releasebasic) && _.isNil(_.find(filterData, o => o.value === releasebasic))) {
      addDefault = [{ text: releasebasic, value: releasebasic }]
    }
    return _.concat(filterData, addDefault)
  }, [versionOpt, releasebasic])

  const formData = useMemo(() => {
    return formList({ productLineOpt, typeOpt, natureOpt, stateOpt, versionListOpt, customerSystemOpt, mode: 'add' })
  }, [productLineOpt, typeOpt, natureOpt, stateOpt, versionListOpt, customerSystemOpt])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (!check()) return errorTips('请填写必填项')
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.assign({}, params, { versionType: '1', functionarys: _.join(params.functionarys, ',') })
    doFetch(ADD_URL, postParams)
      .then(() => {
        successTips('添加成功')
        setIsDisable(false)
        refresh()
        close()
      })
      .catch(err => {
        setIsDisable(false)
        errorTips(err._message)
      })
  }, [check, isDisable, params, doFetch, close, refresh])

  return (
    <Dialog
      className='add-plan-version-log'
      header='新增'
      confirm={confirm}
      cancel={close}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        {
          _.map(formData, item => {
            if (!_.isNil(item?.icon)) {
              return <>
                <HFormInput key={item.bind} {...item} />
                <Tooltip title={item?.title}>
                  <span>
                    <Icon name={item.icon} style={{ fontSize: 14, color: '#5477FF' }} />
                  </span>
                </Tooltip>
              </>
            }
            return <HFormInput key={item.bind} {...item} />
          })
        }
        <div className='mulcust-select-style'>
          <label style={{ width: 95 }}>发布客户系统</label>
          <Select
            className={clsx({ 'mulcustid-style-color': _.isEmpty(_.compact(_.split(_.get(params, 'mulcustid'), '^'))) })}
            mode='multiple'
            placeholder='请选择发布客户系统'
            style={{ width: '511px' }}
            allowClear
            options={customerSystemOpt}
            optionFilterProp='label'
            value={_.compact(_.split(_.get(params, 'mulcustid', ''), ','))}
            onChange={v => {
              const size = _.size(v) - 1
              let val = v[size] === 'ALL' ? ['ALL'] : v
              if (_.includes(v, 'ALL') && v[size] !== 'ALL') {
                val = _.filter(val, k => k !== 'ALL')
              }
              setParams(x => _.assign({}, x, { mulcustid: _.join(val, ',') }))
            }}
          />
        </div>
      </Form>
    </Dialog>
  )
}

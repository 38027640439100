import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Input, Table, Tooltip } from 'antd'
import { Dialog, Button } from 'rootnet-ui'
import './SelectDetail.scss'

function getColumns(props) {
  const { isTemplate } = props

  const templateColumns = [
    { title: '模版名称', dataIndex: 'templateName', key: 'templateName', width: 200, ellipsis: true, render: v => <Tooltip title={v}>{v}</Tooltip> },
    { title: '状态', dataIndex: 'status', key: 'status', width: 50, render: v => v === 'Y' ? '启用' : '禁用' },
    { title: '类型', dataIndex: 'projectType', key: 'projectType', width: 60, },
    { title: '修改人', dataIndex: 'updateUser', key: 'updateUser', width: 80, },
    { title: '创建人', dataIndex: 'createUser', key: 'createUser', width: 80, },
  ]

  const projectColumns = [
    { title: '项目名称', dataIndex: 'projectName', key: 'projectName', width: 200, ellipsis: true, render: v => <Tooltip title={v}>{v}</Tooltip> },
    { title: '地区', dataIndex: 'areaName', key: 'areaName', width: 60, },
    { title: '项目状态', dataIndex: 'projectStatusName', key: 'projectStatusName', width: 60, },
    { title: '实施阶段', dataIndex: 'serviceStatusName', key: 'serviceStatusName', width: 80, },
    { title: '项目经理', dataIndex: 'userName', key: 'userName', width: 80, },
  ]

  return isTemplate ? templateColumns : projectColumns
}

export default function SelectDetail(props) {
  const { active, close, allData, setTemplate, template, isDevProject, projectType } = props
  const [data, setData] = useState([])
  const [radio, setRadio] = useState(template)

  const header = useMemo(() => active === 2 ? '模板选择' : '项目选择', [active])
  const isTemplate = useMemo(() => active === 2, [active])
  const columns = useMemo(() => getColumns({ isTemplate }), [isTemplate])

  useEffect(() => {
    if (_.isNil(allData)) return
    const list = active === 2 ? _.get(allData, '[0]') : _.get(allData, '[1]')
    if (isDevProject) setData(_.filter(list, o => o.projectType === _.toUpper(projectType)))
    else setData(list)
  }, [active, allData, isDevProject, projectType])

  return (
    <Dialog
      header={header}
      cancel={close}
      confirm={confirm}
      footerVisible={false}
      className='select-detail-dialog'
    >
      <div className='select-detail-content'>

        <Input.Search
          allowClear
          onSearch={onSearch}
          style={{ width: 200 }}
          placeholder={isTemplate ? '请输入模板名称' : '请输入项目名称'}
        />
        <Table
          size='small'
          columns={columns}
          dataSource={data}
          rowKey='id'
          bordered
          scroll={{ y: 412 }}
          rowSelection={{
            type: 'radio',
            onChange: (v, o) => changeRadio(_.head(o)),
            selectedRowKeys: [_.get(radio, 'id', '')],
          }}
          onRow={record => {
            return {
              onClick: () => changeRadio(record),
            };
          }}
        />
      </div>
      <div className='footer'>
        <Button normal onClick={close}>取消</Button>
        <Button onClick={confirm} primary>确认</Button>
      </div>
    </Dialog>
  )

  function changeRadio(item) {
    setRadio({ id: item.id, flag: !isTemplate, name: isTemplate ? item.templateName : item.projectName })
  }

  function confirm() {
    setTemplate(radio)
    close()
  }

  function onSearch(value) {
    const list = isTemplate ? _.get(allData, '[0]') : _.get(allData, '[1]')
    const filterData = _.filter(list, o => _.includes(isTemplate ? o.templateName : o.projectName, _.trim(value)))
    if (isDevProject) setData(_.filter(filterData, o => o.projectType === _.toUpper(projectType)))
    else setData(filterData)
  }

}

import React, {useCallback, useEffect, useReducer, useState} from 'react';
import './WorkflowCurrentUserLimit.scss'
import _ from 'lodash'
import CheckConditionGroup from "../../../common/view/checkConditionGroup/CheckConditionGroup";
import {Button} from "rootnet-ui";
import UserDeptGroupSelect from "../../../common/userDeptGroupSelect/UserDeptGroupSelect";
import {Divider} from "antd";

function WorkflowCurrentUserLimit(props) {
    const {fieldList,limitRangeCondition, setLimitRangeCondition,limitRange, setLimitRange} = props
    const [count, forceUpdate] = useReducer((x) => x + 1, 0)

    const onConditionChange = useCallback((data, index)=>{
        setLimitRangeCondition(old => {
            old[index] = data
            return old
        })
    },[setLimitRangeCondition])

    const onRangeChange = useCallback((data, index)=>{
        setLimitRange(old => {
            old[index] = data
            return old
        })
    },[setLimitRange])

    const addNew = useCallback(()=>{
        setLimitRangeCondition(old => {
            if(_.isEmpty(old)){
                return [{
                    isConditionGroup: true,
                    conditionRelation: 'and',
                    conditionList: []
                }]
            }else{
                return _.concat(old, {
                    isConditionGroup: true,
                    conditionRelation: 'and',
                    conditionList: []
                })
            }
        })
        setLimitRange(old => {
            if(_.isEmpty(old)){
                return [{}]
            }else{
                return _.concat(old, {})
            }
        })
    },[setLimitRangeCondition, setLimitRange])

    const delCondition = useCallback((index)=>{
        setLimitRangeCondition(old => {
            return _.concat(_.slice(old, 0, index),_.slice(old, index+1))
        })
        setLimitRange(old => {
            return _.concat(_.slice(old, 0, index),_.slice(old, index+1))
        })
        forceUpdate()
    },[setLimitRangeCondition, setLimitRange])

    return <div className={'workflow-current-user-limit'} key={count}>
        {
            _.map(limitRangeCondition, (condition, index) =>{
                return <WorkflowCurrentUserLimitItem onChange={ value => onConditionChange(value, index) } key={index}
                                                     initCondition={condition} initRange={_.get(limitRange,index)}
                                                     {...{fieldList, onConditionChange,onRangeChange, index, delCondition}}/>
            })
        }
        <Button normal onClick={addNew} style={{marginTop: 6}}>添加条件项</Button>
    </div>
}

function WorkflowCurrentUserLimitItem(props){
    const {fieldList, onConditionChange, initCondition, index, onRangeChange, initRange, delCondition} = props
    const [condition, setCondition] = useState(initCondition)
    const [selectRange, setSelectRange] = useState(initRange)

    useEffect(()=>{
        onConditionChange(condition, index)
    },[condition, onConditionChange, index])

    useEffect(()=>{
        onRangeChange(selectRange, index)
    },[selectRange, onRangeChange, index])

    return <div className={'workflow-current-user-limit-item flex-y'}>
        {
            index !== 0 &&
            <Divider orientation="left" plain>
                or
            </Divider>
        }
        <CheckConditionGroup allColumns={fieldList} editData={condition} setEditData={setCondition}/>
        <div className={'flex center-y'}>
            <UserDeptGroupSelect value={selectRange} onChange={setSelectRange} canSelectGroup={false} style={{width: 862}}
                                 multiple allowClear/>
            <Button accent style={{height: 32}} onClick={()=>delCondition(index)}>删除条件</Button>
        </div>
    </div>
}

export default WorkflowCurrentUserLimit;
import React from 'react'
import './index.scss'

export default function HeadTag(props) {
  const { text } = props
  return (
    <div className='head-tag'>
      <span className='left'></span>
      <span className='text'>{text}</span>
      <span className='right'></span>
    </div>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import {Form, FormInput, Select} from "rootnet-edit";
import './EmailReminderDetail.scss'
import {usePost} from "rootnet-biz/es/hooks";
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props}/>

function EmailReminderDetail(props) {
    const {currentInfo, close, refreshList, standbyOptions, customerOnlyId} = props
    const {id, mode} = useMemo(()=> currentInfo || {},[currentInfo])
    const [formData, setFormData] = useState()
    const [infoError, setInfoError] = useState()
    const {doFetch: submitPost} = usePost()
    const {doFetch: getDetail} = useGet()

    useEffect(()=>{
        if(_.isNil(id)) return
        getDetail('/bug/project/mail/info?id='+id).then(res => {
            setFormData(res)
        })
    },[getDetail, id])

    const canSubmit = useMemo(()=>{
        return _.every(infoError, v => _.isNil(v))
    },[infoError])

    const submit = useCallback(()=>{
        const postParams = {
            ...formData,
            customerOnlyId: customerOnlyId
        }
        const url = mode === 'add' ? '/bug/project/addMail' : '/bug/project/updateMail'
        submitPost(url,postParams).then(()=>{
            Messager.show("提交成功", { icon: 'success' })
            refreshList()
            close()
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    },[submitPost, refreshList, close, formData, mode, customerOnlyId])

    return <Dialog header={'提醒邮件地址'} className={'email-reminder-detail'} confirmButtonDisabled={!canSubmit} confirm={submit} cancel={close}>
        <Form value={formData} onChange={setFormData} error={infoError} onError={v => setInfoError(x => _.assign({},x,v))}>
            <HFormInput label={'主/备'} bind={'flag'} required component={Select} options={standbyOptions}/>
            <HFormInput label={'联系人'} bind={'recipient'} required/>
            <HFormInput label={'邮件'} bind={'mail'} required componentWidth={476}/>
            <HFormInput label={'手机号'} bind={'mobile'} required/>
            <HFormInput label={'座机号'} bind={'telephone'}/>
        </Form>
    </Dialog>
}

export default EmailReminderDetail;
import { Messager } from 'rootnet-ui';

export function successTips(text = '') {
    Messager.show(text, { icon: 'success' });
}

export function errorTips(text = '接口失败，请稍后重试!') {
    Messager.show(text, { icon: 'error' });
}

export function warnTips(text = '') {
    Messager.show(text, { icon: 'warn' });
}

export function infoTips(text = '') {
    Messager.show(text, { icon: 'info' });
}

import React, { useState, useEffect, useMemo } from 'react';
import { useGet } from '../../../utils/hook';
import { strParams } from "../../../utils/publicFun";
import _ from 'lodash';
import Left from './Left';
import Right from './Right';
import './Milepost.scss';
import Api from '../../../base/task';

function getParams(str) {
    let obj = {};
    if (!_.isString(str)) return {};
    str = str.split('?')[1];
    _.forEach(str.split('&'), item => {
        let _item = item.split('=');
        obj[_item[0]] = _item[1];
    })
    return obj;
}

function Milepost(props) {
    const { location: { search } } = props;
    const { data, loading, error, doFetch } = useGet();
    const [refresh, setRefresh] = useState(false); //是否刷新
    let [product, setProduct] = useState({}); //当前产品
    let [id, setId] = useState(''); //当前产品ID
    let [ajaxPar2, setAjaxPar2] = useState({}); //右侧里程碑的状态
    let [ajaxData2, setAjaxData2] = useState([]); //右侧里程碑data的状态
    let params = useMemo(() => getParams(search), [search]);

    useEffect(() => {
        let _params = {
            principal: _.isNaN(params.principal) ? '' : params.principal,
            projectName: _.isNaN(params.projectName) ? '' : params.projectName,
            projectStatusList: _.isNaN(params.projectStatusList) ? '' : params.projectStatusList,
        }
        setId(params.id);
        doFetch(`develop/project/list?${strParams(_params)}`);
    }, [doFetch, params]);

    // 设置prodcut  请求右侧数据
    useEffect(() => {
        if(_.isNil(id) || _.isNil(data)) return;
        let _obj = _.filter(data, item => {
            return item.id === parseInt(id, 10)
        })
        let _params = {
            type: 'DEV',
            iid: id || '',
        }
        setAjaxPar2({ loading: true, error: null })
        Api.get(`/common/milestone/list?${strParams(_params)}`).then(res => {
            setAjaxPar2({ loading: false, error: null })
            setAjaxData2(_.get(res, ['data']))
        }).catch(err => {
            setAjaxPar2({ loading: false, error: err._message })
        })
        setProduct(_obj[0])
    }, [data, id, refresh]);

    return <div className="milepost x">
        <Left 
            loading={loading} error={error} 
            data={data} 
            product={product}
            titleClick={(item) => setId(item.id)}
        />
        <Right 
            loading={ajaxPar2.loading} 
            error={ajaxPar2.error} 
            data={ajaxData2}
            product={product}
            title={_.get(product, 'projectName') ? `${_.get(product, 'projectName')}里程碑` : '里程碑'}
            refresh={() => setRefresh(!refresh)}
        />
    </div>
}

export default Milepost;
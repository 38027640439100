import React, {useCallback, useMemo, useState} from 'react';
import './QuickCreatePop.scss'
import {Popover} from "antd";
import {useGet} from "rootnet-biz/es/hooks";
import _ from "lodash";
import convertGlobalConstOptions from "../common/ConvertGlobalConstOptions";
import {filterDisplayFlagOptions} from "../common/publicFunc";
import {useFuncCode} from "../common/commonMethod";
import moment from 'moment'

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=storyType',
    '/common/globalconst?globalConst=typeOfRDProject',
]

function QuickCreatePop(props) {
    const {setAddInfo, setShowPop} = props
    const [showReqPop, setShowReqPop] = useState(false)
    const [showDevProjectPop, setShowDevProjectPop] = useState(false)
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [reqAuth, versionAuth, workTimeAuth, isDevProject] = useFuncCode(['150101','081005','7001','3601'])

    const [storyTypeOptions, devProjectTypeOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const ReqTypeSelect = useCallback(()=>{
        const displayStoryTypeOptions = filterDisplayFlagOptions(storyTypeOptions)
        const filterData = _.filter(displayStoryTypeOptions,o => o.value !== 'DEV')
        return <div className={'add-type-pop-group flex-y'}>
            {
                _.map(filterData, item => (
                    <div className="type-item flex center-y" key={item.value} onClick={()=>{
                        setAddInfo({module: 'req', initType: item.value})
                        setShowPop(false)
                        setShowReqPop(false)
                    }}>
                        <div className="type-tag flex center">{item.value}</div>
                        <div className="type-name">{item.text}</div>
                    </div>
                ))
            }

        </div>
    },[storyTypeOptions, setAddInfo, setShowPop])

    const DevProjectTypeSelect = useCallback(()=>{
        const displayDevProjectTypeOptions = filterDisplayFlagOptions(devProjectTypeOptions)
        return <div className={'add-type-pop-group flex-y'}>
            {
                _.map(displayDevProjectTypeOptions, item => (
                    <div className="type-item flex center-y" key={item.value} onClick={()=>{
                        setAddInfo({module: 'devProject', initType: item.value})
                        setShowPop(false)
                        setShowDevProjectPop(false)
                    }}>
                        <div className="type-tag flex center">{item.value}</div>
                        <div className="type-name">{item.text}</div>
                    </div>
                ))
            }

        </div>
    },[devProjectTypeOptions, setAddInfo, setShowPop])

    return <div className={'quick-create-pop flex-y'}>
        {
            reqAuth &&
            <Popover placement={'rightTop'} content={<ReqTypeSelect/>} overlayClassName={'add-type-select-pop'}
                     open={showReqPop} onOpenChange={setShowReqPop}>
                <div className="quick-create-item">
                    创建需求
                </div>
            </Popover>
        }
        <div className="quick-create-item" onClick={()=>{
            setAddInfo({module: 'issue'})
            setShowPop(false)
        }}>
            创建ISSUE
        </div>
        <div className="quick-create-item" onClick={()=>{
            setAddInfo({module: 'defect'})
            setShowPop(false)
        }}>
            创建缺陷
        </div>
        <div className="quick-create-item" onClick={()=>{
            setAddInfo({module: 'todoList'})
            setShowPop(false)
        }}>
            创建跟进事项
        </div>
        <div className="quick-create-item" onClick={()=>{
            setAddInfo({module: 'customerService'})
            setShowPop(false)
        }}>
            创建客户服务单
        </div>
        {
            isDevProject &&
            <Popover placement={'rightTop'} content={<DevProjectTypeSelect/>} overlayClassName={'add-type-select-pop'}
                     open={showDevProjectPop} onOpenChange={setShowDevProjectPop}>
                <div className="quick-create-item">
                    创建研发项目
                </div>
            </Popover>
        }
        <div className="quick-create-item" onClick={()=>{
            window.open(`/#/staffReportMgt?createDate=${moment().format('YYYY-MM-DD')}&createMode=week`,'_self')
            setShowPop(false)
        }}>
            创建周报
        </div>
        <div className="quick-create-item" onClick={()=>{
            window.open(`/#/staffReportMgt?createDate=${moment().format('YYYY-MM-DD')}&createMode=day`,'_self')
            setShowPop(false)
        }}>
            创建日报
        </div>
        {
            versionAuth &&
            <div className="quick-create-item" onClick={()=>{
                setAddInfo({module: 'version'})
                setShowPop(false)
            }}>
                创建版本
            </div>
        }
        <div className="quick-create-item" onClick={()=>{
            setAddInfo({module: 'customerVoice'})
            setShowPop(false)
        }}>
            创建客户声音
        </div>
        {
            workTimeAuth &&
            <div className="quick-create-item" onClick={()=>{
                window.open(`/#/workTimeReport`,'_self')
                setShowPop(false)
            }}>
                工时提报
            </div>
        }
    </div>
}

export default QuickCreatePop;
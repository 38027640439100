import React, {useMemo, useState} from 'react';
import _ from "lodash";
import convertTableAlign from "../../../../common/view/convertTableAlign";
import findConvert from "../../../../common/view/findConvert";
import useGetViewConfig from "../../../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../../../common/view/hooks/useGetTreeList";
import ViewArea from "../../../../common/view/ViewArea";
import {Box} from "../../../../common/commonComponent";
import {DataGrid, Pagination} from "rootnet-ui";
import './DefectTracerView.scss'
import TrackingDetailDialog from "../../../../trackingManagementMgt/components/trackingDetailDialog";
import {Popover} from "antd";
import WorkFlowChangePop from "../../../../workFlow/workFlowChangePop/WorkFlowChangePop";

const ENTER_DETAIL_FIELD = 'bug_tracer.tracerTitle'

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setShowDetailDialog, workFlowId, setWorkFlowId, refreshList } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if(tableField === ENTER_DETAIL_FIELD){
            return <div className="can-enter-text" onClick={()=> {
                setShowDetailDialog({
                    id: _.get(o,'id'),
                    module: 'tracer'
                })
            }}>
                {showValue || '-'}
            </div>
        }
        if(tableField === 'workflow_business.factorId'){
            const options = _.get(convertCollection,tableField)
            const workflowColor = _.get(_.find(options, x => x.value === v),'color') || '#000'
            return <Popover destroyTooltipOnHide={true}
                            content={<WorkFlowChangePop businessId={workFlowId} close={()=>setWorkFlowId(null)} refreshList={refreshList} funcCode={'26'} businessType={'tracer'}/>}
                            onOpenChange={visible => {
                                if(!visible){
                                    setWorkFlowId(null)
                                }
                            }}
                            trigger={'click'}
                            placement="bottom"
                            open={o.id === workFlowId}
            >
                <div className={'common-display-field cursor-pointer'} onClick={()=>setWorkFlowId(o.id)}
                     style={{color: workflowColor, border: `1px solid ${workflowColor}`,padding: '0 8px', borderRadius: 4}}>
                    {showValue || '-'}
                </div>
            </Popover>
        }
        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '050401'

function DefectTracerView(props) {
    const {defectSnowId} = props
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [workFlowId, setWorkFlowId] = useState()

    const replaceParams = useMemo(() => {
        return ({ 'defectTracer.defectId': defectSnowId })
    }, [defectSnowId])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions,
        getOptions, getColumns, columnsAppendParams: {setShowDetailDialog, workFlowId, setWorkFlowId, refreshList} })

    return <div className={'defect-tracer-view fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize, replaceParams}}/>
        <Box title={'研发任务'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
            <DataGrid option={options} data={dataGridList} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        {
            _.get(showDetailDialog,'module') === 'tracer' &&
            <TrackingDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                  refreshViewList={refreshList}/>
        }
    </div>
}

export default DefectTracerView;
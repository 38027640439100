import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { DataGrid } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { useGet } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import LeftList from './leftList'
import { Box } from '../../../../../../common/commonComponent'
import { strParams } from '../../../../../../../utils/publicFun'
import convertOptions from '../../../../../../common/ConvertOptions'
import { OperationList } from '../../../../../../common/OperationList'
import MakeTagsLog from './makeTagsLog'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import { ScheduleManagementContext } from '../../../../../../common/Context'
import CopyProduction from './copyProduction'
import gd from '../../../../../../../base/global'
import './index.scss'

const LIST_URL = '/verQuery/tracerList'
const MODIFY_FILE_TYPE_URL = '/common/globalconst?globalConst=CHECKLIST'
const CHECK_AUTHORITY_URL = '/verQuery/checkAuthority'
const EXPORT_URL = '/verQuery/tracerList/export'

const { str14ToDate } = toDate

const signOpt = [
  { text: '取', value: '0' },
  { text: '摘', value: '1' },
  { text: '未制作', value: null },
]

const getColumns = (props) => {
  const { allUserRes, modifyTypeOpt, setIsMake, isCheckAuthority } = props
  return [//convert
    { selection: true },
    { header: 'ID', bind: 'tracerId', width: 150, tooltip: true, sortable: true, },
    { header: '标题', bind: 'tracerTitle', width: 400, tooltip: true, sortable: true, },
    { header: '技术栈', bind: 'modifiedType', width: 140, tooltip: true, sortable: true, convert: v => convertOptions(v, modifyTypeOpt) },
    {
      header: '版本制作方式', bind: 'buildinMethod', width: 100, tooltip: true, sortable: true, convert: v => <div style={{ color: v === '1' ? 'red' : '' }}>
        {convertOptions(v, signOpt)}
      </div>
    },
    { header: '版本制作备注', bind: 'buildinMethodMemo', width: 160, tooltip: true, sortable: true, },
    { header: '制作人', bind: 'recUserId', width: 100, tooltip: true, sortable: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      header: '制作时间', bind: 'updateTime', width: 135, tooltip: true, sortable: true, convert: v => {
        if (isNil(v)) return '-'
        return dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
      }
    },
    { header: '操作', align: 'center', width: 70, convert: renderOperation },
  ]
  function renderOperation(v, o) {
    const operateOption = [
      {
        text: '制作',
        disabled: !isCheckAuthority,
        onClick: () => {
          if (!isCheckAuthority) return
          setIsMake([_.pick(o, ['tracerId', 'buildinMethod', 'buildinMethodMemo', 'modifiedType'])])
        }
      }
    ];
    return <OperationList options={operateOption} />;
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 3,
  fixedRight: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

export default function TaskListMaking(props) {
  const { currentId, allUserRes, firstOrder, secondOrder, isALL, principal } = props
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const [selectList, setSelectList] = useState()
  const [selectedKeys, setSelectedKeys] = useState()
  const [isMake, setIsMake] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const [copyProduction, setCopyProduction] = useState(false)
  const { doFetch: checkAuthority, data: isCheckAuthority } = useGet()
  const { doFetch: getList, data, loading, error } = useGet()
  const { data: modifyTypeRes, loading: modifyTypeLoading } = useGet(MODIFY_FILE_TYPE_URL)

  const refreshList = useCallback(() => {
    getList(`${LIST_URL}?${strParams({ releaseId: currentId, modifiedType: selectedKeys })}`)
  }, [currentId, getList, selectedKeys])

  const modifyTypeOpt = useMemo(() => {
    if (_.isEmpty(modifyTypeRes)) return []
    return _.map(modifyTypeRes, o => ({ text: o.displayName, value: o.interiorId }))
  }, [modifyTypeRes])

  const option = useMemo(() => {
    return getOptions(getColumns({ allUserRes, modifyTypeOpt, setIsMake, isCheckAuthority }))
  }, [allUserRes, isCheckAuthority, modifyTypeOpt])

  const extra = useMemo(() => {
    return <div className='flex'>
      {
        (isALL || principal === gd.User.operator_id) &&
        <TextIconBtn text='复制版本制作' icon='' onClick={() => setCopyProduction(true)} />
      }
      {
        <TextIconBtn disabled={isDisabled} text='导出' icon='xiazai2' onClick={() => {
          if (isDisabled) return
          downloadGetProgress(`${EXPORT_URL}?${strParams({ releaseId: currentId, modifiedType: selectedKeys })}`, '导出任务清单制作标记列表', () => { }, setIsDisabled)
        }} />
      }
      {
        (isCheckAuthority && !_.isEmpty(selectList)) &&
        <TextIconBtn text='批量制作' icon='' onClick={() => setIsMake(_.map(selectList, o => (_.pick(o, ['tracerId', 'modifiedType']))))} />
      }
    </div>
  }, [isCheckAuthority, selectList, downloadGetProgress, currentId, selectedKeys, isDisabled, isALL, principal])

  useEffect(() => {
    if (firstOrder === 'versionMaking' && secondOrder === 'taskListMaking') {
      checkAuthority(`${CHECK_AUTHORITY_URL}?${strParams({ releaseId: currentId })}`)
    }
  }, [checkAuthority, currentId, firstOrder, secondOrder])

  useEffect(() => {
    if (selectedKeys) { }
    setSelectList([])
  }, [selectedKeys])

  useEffect(() => {
    if (_.isNil(currentId) || _.isNil(selectedKeys)) return
    refreshList()
  }, [currentId, refreshList, selectedKeys])

  return (
    <>
      <div className='task-list-making flex'>
        <LeftList {...{ selectedKeys, setSelectedKeys, modifyTypeRes, loading: modifyTypeLoading }} />
        <div className='right-data'>
          <Box
            className='flex-y x-card-singlegrid'
            title='任务清单制作标记列表'
            loading={loading}
            error={error}
            data={data}
            extra={extra}
          >
            <DataGrid data={data} option={option} selection={selectList} onSelectionChange={setSelectList} />
          </Box>
        </div>
      </div>
      {
        copyProduction &&
        <CopyProduction releaseId={currentId} close={() => setCopyProduction(false)} {...{ refreshList }} />
      }
      {isMake && <MakeTagsLog {...{ selectedKey: _.head(selectedKeys), refreshList, signOpt, releaseID: currentId, list: isMake, close: () => { setIsMake(false); setSelectList([]) } }} />}
    </>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import WaterMarkDialog from "../../../common/WaterMarkDialog";
import './ContractDialog.scss'
import {Button, DataGrid} from 'rootnet-ui'
import {Form, FormInput, Select} from "rootnet-edit";
import _ from "lodash";
import {toDate,dateFormat} from 'rootnet-core/dateFormat'
import {compose, strParams} from "../../../../utils/publicFun";
import {useGet} from "rootnet-biz/es/hooks";

const formatDate = compose(dateFormat('YYYY-MM-DD'),toDate.str14ToDate);

function getColumns(props){
    return [
        {selection: true},
        { header: '合同编号', bind: 'contractNum', width: 200, tooltip: true, sortable: true },
        { header: '合同名称', bind: 'contractName', width: 400, tooltip: true, sortable: true },
        { header: '客户名称', bind: 'customerName', width: 150, tooltip: true, sortable: true },
        { header: '销售', bind: 'salesName', width: 90, tooltip: true, sortable: true },
        { header: '合同签署日期', bind: 'signDate', width: 150, tooltip: true, sortable: true, convert: formatDate },
    ]
}

function getOption(columns){
    return {
        columns,
        selection: true,
        autoFill: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
    }
}

function ContractDialog(props) {
    const {change, close, initId, customer = null} = props
    const [params, setParams] = useState()
    const {data: list, doFetch: getList, loading} = useGet()
    const [selectedList, setSelectList] = useState([])

    useEffect(()=>{
        getList('/contractInfo/list?'+strParams({customer}))
    },[getList,customer])

    const option = useMemo(()=>{
        return getOption(getColumns())
    },[])

    const onRowClick = useCallback((clickItem)=>{
        if(_.isEmpty(selectedList)){
            setSelectList([clickItem])
        }else{
            if(_.get(clickItem,'contractNum') === _.get(selectedList[0],'contractNum')){
                setSelectList([])
            }else{
                setSelectList([clickItem])
            }
        }

    },[selectedList])

    const sortList = useMemo(()=>{
        if(_.isEmpty(list)) return []
        // 没有选择客户时返回空数组
        if(_.isEmpty(customer)) return []
        return _.sortBy(list,  x => x.contractNum !== initId)
    },[list, initId, customer])

    const filterData = useMemo(()=>{
        return _.filter(sortList, item => {
            return _.every(params, (v,k) => _.isNil(v) || v === '' || _.includes(_.toLower(item[k]), _.toLower(v)))
        })
    },[params,sortList])

    useEffect(()=>{
        if(_.isEmpty(filterData)) return
        const item = _.find(filterData, x => x.contractNum === initId)
        setSelectList(_.isNil(item)?[]:[item])
    },[filterData,initId])

    const confirm = useCallback(()=>{
        change(x => ({...x, contractNum:(_.get(_.head(selectedList),'contractNum')), contractName:(_.get(_.head(selectedList),'contractName'))}))
        close()
    },[change,close,selectedList])

    const singleChoice = useCallback((arr)=>{
        if(_.isEmpty(selectedList)){
            setSelectList(arr)
        }else{
            const filterItem = _.find(arr, item => (item.contractNum !== selectedList[0].contractNum))
            if(_.get(filterItem,'contractNum') === _.get(selectedList[0],'contractNum')){
                setSelectList([])
            }else{
                setSelectList(_.isNil(filterItem)?[]:[filterItem])
            }
        }
    },[selectedList])

    return <WaterMarkDialog headerVisible={false} className='contract-dialog radio-dialog' confirm={confirm} cancel={close} loading={loading}>
        <div className="contract-wrap flex-y">
            <Options search={setParams}/>
            <DataGrid className='x-card-singlegrid' data={filterData} selection={selectedList} onSelectionChange={singleChoice} option={option} onRowClick={onRowClick}/>
        </div>
    </WaterMarkDialog>
}

function Options(props){
    const {search} = props
    const [params, setParams] = useState()
    const {data: userListRes} = useGet('/common/userinfo?indexValidFlag=1')

    const userOptions = useMemo(()=>{
        if(_.isEmpty(userListRes)) return []
        return _.map(userListRes, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))
    },[userListRes])

    return <div className='c-options'>
        <Form value={params} onChange={setParams}>
            <FormInput label='合同编号' bind='contractNum'/>
            <FormInput label='合同名称' bind='contractName'/>
            <FormInput label='销售' bind='sales' component={Select} options={userOptions} search/>
        </Form>
        <Button primary onClick={() => search({...params})}>查询</Button>
    </div>
}

export default ContractDialog;
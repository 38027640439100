import React, { useMemo } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Modal, Button } from 'antd'
import './index.scss'

export default function Dialog(props) {
  const { onOk, visible, onCancel, children, className, footerReverse, okText, cancelText, title, loading, footerAlign, footer, ...res } = props

  const footers = useMemo(() => {
    if (!footer) return null
    return _[footerReverse ? 'clone' : 'reverse']([
      <Button key='confirm' className='ant-btn-custom-circle' type='primary' loading={loading} onClick={onOk}>{okText}</Button>,
      <Button key='cancel' className='ant-btn-custom-circle' onClick={onCancel}>{cancelText}</Button>
    ])
  }, [cancelText, footer, footerReverse, loading, okText, onCancel, onOk])

  return (
    <Modal
      className={cls(`antd-dialog ${className}`, { 'footer-align': footerAlign, 'antd-title': _.isNil(title) })}
      title={<span>{title}</span>}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footers}
      {...res}
    >
      {children}
    </Modal>
  )
}

Dialog.defaultProps = {
  okText: '确认',
  cancelText: '取消',
  title: null,
  centered: true,
  maskClosable: false,
  footerReverse: false, //
  footerAlign: false, //
  className: '',
  footer: true,
  visible: true,
  loading: false,
}

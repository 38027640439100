import React, {useState} from 'react';
import {CirclePicker} from 'react-color'

function CircleColorPicker(props) {
    const {setColor, ...rest} = props
    const [selectedColor, setSelectedColor] = useState('#f44336')

    return <div className={'circle-color-picker'}>
        <CirclePicker color={selectedColor} onChange={color => {
            setSelectedColor(color.hex)
            if(setColor){
                setColor(color.hex)
            }
        }} {...rest}/>
    </div>
}

export default CircleColorPicker;
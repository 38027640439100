import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Messager, Switch } from 'rootnet-ui'
import { Form, FormInput, Display } from 'rootnet-edit'
import TextAreaInput from '../../../../../../common/textAreaInput/TextAreaInput'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import { isNil } from '../../../../../../appraise/components/method'
import './index.scss'

const UPDATE_URL = '/releaseHistory/customerDownloadSetting'

export default function RightMailDetail(props) {
  const { params, refreshList } = props
  const [open, setOpen] = useState(false)
  return (
    <div className='right-mail-detail'>
      <div className='customer-download-settings'><TextIconBtn text={'客户下载设置'} onClick={() => setOpen(true)} /></div>
      <Form value={params}>
        <FormInput horizontal labelWidth={75} label="收件人" component={Display} bind="mainList" />
        <FormInput horizontal labelWidth={75} label="抄送人" component={Display} bind="ccList" />
        <FormInput horizontal labelWidth={75} label="邮件内容" component={Display} bind="context" />
      </Form>
      {
        open &&
        <CustomerDownloadSettingsDialog
          initParams={params}
          close={() => setOpen(false)}
          {...{ refreshList }}
        />
      }
    </div>
  )
}

function CustomerDownloadSettingsDialog(props) {
  const { close, initParams = {}, refreshList } = props
  const [params, setParams] = useState(initParams)
  const [isDisabled, setIsDisabled] = useState(false)
  const { doFetch } = usePost()

  const confirm = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)
    const postParams = _.assign({}, params,)
    doFetch(UPDATE_URL, postParams)
      .then(() => {
        setIsDisabled(false)
        Messager.show('设置成功', { icon: 'success' })
        refreshList()
        close()
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisabled, doFetch, params, close, refreshList])

  const checked = useMemo(() => {
    const v = _.get(params, 'customerDownloadFlag')
    if (isNil(v)) return false
    if (_.isBoolean(v)) return v
    if (v === 'Y') return true
    if (v === 'N') return false
    return v
  }, [params])

  return <Dialog
    header={<div className='flex'>
      <div>客户下载设置</div>
      <div className='head-hint'>开启后，客户可在根网客户平台，下载中心自行下载交付文件。</div>
    </div>}
    cancel={close}
    className='customer-download-settings-dialog'
    confirm={confirm}
  >
    <Form value={params} onChange={setParams}>
      <FormInput
        horizontal
        label='根网客户平台下载'
        bind='customerDownloadFlag'
        component={Switch}
        checkedComponent=''
        uncheckedComponent=''
        checked={checked}
        bindOutConvert={v => {
          if (isNil(v)) return 'N'
          if (v) return 'Y'
          if (!v) return 'N'
        }}
      />
      <div />
      <FormInput horizontal label='增加可见范围' bind='visibility' placeholder='请输入客户邮箱 , 用英文逗号隔开' component={TextAreaInput} componentWidth={350} className='visual-range-area' />
    </Form>
  </Dialog>
}

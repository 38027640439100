import React, {useState} from 'react';
import './MobileHomePage.scss'
import Icon from "../../components/Icon";
import _ from 'lodash'
import NoPage from "./NoPage/NoPage";
import MobileMyCenter from "./MobileMyCenter/MobileMyCenter";
import {uniqKeyFor} from "../../project_share/utils/utils";
import MetadataMgtMob from "../MobileTerminal/MetadataMgtMob/MetadataMgtMob";
import '../common/rem'
import {pathSearchFor} from "../../utils/publicFun";

const tabData = [
    {
        name: '工作台',
        icon: 'gongzuotai3',
    },
    {
        name: '企业门户',
        icon: 'qiyemenhu',
    },
    {
        name: '消息',
        icon: 'xiaoxi',
    },
    {
        name: '个人中心',
        icon: 'gerenzhongxin',
    },
]

function MobileHomePage(props) {
    const {history} = props
    const {initTabIndex = '3'} = pathSearchFor(history.location.search)
    const [activeIndex, setActiveIndex] = useState(initTabIndex)

    return <div className={'mobile-home-page flex-y fill'}>
        <div className="mobile-main-panel">
            {
                activeIndex === '0' && <div className={'MetadataMgtMob'}>
                    <MetadataMgtMob />
                </div>
            }
            {
                _.includes(['0','1','2'], activeIndex) && <NoPage/>
            }
            {
                activeIndex === '3' && <MobileMyCenter {...{history}}/>
            }
        </div>
        <div className="bottom-tab flex">
            {
                _.map(tabData, (x,i) => (
                    <div key={uniqKeyFor()} className={`bottom-tab-item flex-y center ${activeIndex === _.toString(i) ?'active-tab':''}`}
                         onClick={()=>setActiveIndex(_.toString(i))}>
                        <Icon name={x.icon} style={{fontSize: '0.32rem', marginBottom: '0.04rem'}}/>
                        <span>{x.name}</span>
                    </div>
                ))
            }
        </div>
    </div>
}

export default MobileHomePage;
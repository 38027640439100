import React, { useCallback, useEffect, useMemo } from 'react';
import { Card, Loader, Messager } from 'rootnet-ui'
import './WorkplaceVersion.scss'
import _ from "lodash";
import clsx from "clsx";
import { useGet } from "rootnet-biz/es/hooks";
import moment from 'moment'
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import convertOptions from "../../common/ConvertOptions";
import { Icon } from "../../../project_share/components";

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=VERSIONSTATE', // 版本状态
]

function WorkplaceVersion(props) {
    const { fullScreenCard } = props
    // const { doFetch: refreshList } = useGet()
    const { data: list, doFetch: getList, loading } = useGet()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [statusOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const refresh = useCallback(() => {
        // refreshList('/workBench/scheduleMyVersion').then(()=>{
        getList('/workBench/myVersion')
            .catch(err => Messager.show(err._message, { icon: 'error' }))
        // }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [getList])

    useEffect(() => {
        refresh()
    }, [refresh])

    const extra = useMemo(() => {
        return <Icon name={'shuaxin1'} className={'can-click-icon handle-icon'} onClick={refresh} />
    }, [refresh])

    return <div className={clsx('workplace-version flex-y', { 'hidden-card': !_.isNil(fullScreenCard) })}>
        <Card title={'我参与的版本'} extra={extra}>
            {
                loading && <Loader fill />
            }
            <div className={'version-wrap scroll-y'}>
                {
                    _.map(list, item => {
                        const duration = moment.duration(moment(item.issueDate).diff(new moment()))
                        const days = _.ceil(duration.asDays()) > 0 ? _.ceil(duration.asDays()) : 0
                        return <div className="version-item flex" onClick={() => window.open('/#/versionMsg?initId=' + item.releaseId)} key={item.releaseId}>
                            <div className="version-info flex-y">
                                <div className="version-info-first-row flex">
                                    <div className="version-id">
                                        {item.releaseId}
                                    </div>
                                    <div className="version-status">
                                        {convertOptions(item.state, statusOptions)}
                                    </div>
                                </div>
                                <div className="version-info-second-row flex">
                                    计划发布时间： {moment(item.issueDate).format('YYYY/MM/DD')}
                                </div>
                            </div>
                            <div className="countdown-wrap flex-y center-x">
                                <div className="countdown-text">
                                    发布倒计时
                                </div>
                                <div className={clsx("countdown-day", { 'danger': days === 0, 'warning': days <= 10 })} >
                                    {days}天
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </Card>
    </div>
}

export default WorkplaceVersion;
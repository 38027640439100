import fp from 'lodash/fp';
import api from '../base/task';

//处理数据的公共方法
export const addAll = (x, n = {}) => [fp.assign(n, { key: '', value: '', text: '全部' })].concat(x);

//项目成本管理-研发项目-项目状态
export async function projectStatus() {
    const datas = await api.get(`/common/globalconst?globalConst=${'ProjectStatus'}`);
    const Item = e => ({ key: e.interiorId, value: e.interiorId + '', text: ''.concat(e.interiorId, '-', e.displayName) });
    const nameItem = e => ({ key: e.interiorId, value: e.interiorId + '', text: e.displayName });
    const options = fp.map(Item)(datas.data);
    const nameOptions = fp.map(nameItem)(datas.data);
    return Promise.resolve({ options, nameOptions });
}

//项目成本管理-实施项目-项目状态
export async function implementProjectStatus() {
    const datas = await api.get(`/common/globalconst?globalConst=${'PlanStatus '}`);
    const Item = e => ({ key: e.interiorId, value: e.interiorId + '', text: ''.concat(e.interiorId, '-', e.displayName) });
    const nameItem = e => ({ key: e.interiorId, value: e.interiorId + '', text: e.displayName });
    const options = fp.map(Item)(datas.data);
    const nameOptions = fp.map(nameItem)(datas.data);
    return Promise.resolve({ options, nameOptions });
}

//项目成本管理-售前项目-项目状态
export async function presalesProjectStatus() {
    const datas = await api.get(`/common/globalconst?globalConst=${'PresalesProjectStatus'}`);
    const Item = e => ({ key: e.interiorId, value: e.interiorId + '', text: ''.concat(e.interiorId, '-', e.displayName) });
    const nameItem = e => ({ key: e.interiorId, value: e.interiorId + '', text: e.displayName });
    const options = fp.map(Item)(datas.data);
    const nameOptions = fp.map(nameItem)(datas.data);
    return Promise.resolve({ options, nameOptions });
}
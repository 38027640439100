import React from 'react'
import { Dialog } from 'rootnet-ui'
import VersionMakingDetailContent from './versionMakingDetailContent'
import './index.scss'

export default function VersionMakingDetailDialog(props) {
  return (
    <Dialog className='version-making-detail-dialog' headerVisible={false} footerVisible={false}>
      <VersionMakingDetailContent {...props} />
    </Dialog>
  )
}
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Table } from 'antd'
import { Dialog, Button } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/es/hooks'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import DataSwitch from '../components/DataSwitch'
import ExportExcel from '../components/ExportExcel'
import convertGlobalConstOptions from "../../../ConvertGlobalConstOptions";
import convertOptions from "../../../ConvertOptions";
import './TemplateDetail.scss'

const DETAIL_URL = '/projectTemplate/list'
const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=segment',
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=PRIORITYLIST',
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=ActiveFlag',
  'common/globalconst?globalConst=devSegment',
  'common/globalconst?globalConst=keyNodes',
  '/common/globalconst?globalConst=reqMileStone',
]

function getColumns(props) {//keyNodesOpt
  const { impSegmentOpt, priorityOpt, devSegmentOpt, templateType, reqSegmentOpt } = props
  const segmentOpt = {
    'impProject': impSegmentOpt,
    'devProject': devSegmentOpt,
    'req': reqSegmentOpt,
  }
  return _.compact([
    { title: '序号', width: 45, align: 'center', render: (text, record, index) => `${index + 1}` },
    {
      title: '阶段/环节', width: 120, dataIndex: 'segment', key: 'segment', ellipsis: true,
      render: v => convertOptions(v, segmentOpt[templateType], 'label')
    },
    { title: '内容', dataIndex: 'itemName', key: 'itemName', ellipsis: true, },
    templateType === 'devProject' && {
      title: '关键节点', width: 120, dataIndex: 'keyNodes', key: 'keyNodes'
    },
    { title: '优先级', width: 60, dataIndex: 'priority', key: 'priority', align: 'center', render: v => convertOptions(v, priorityOpt) },
    { title: '标准工时(人日)', width: 120, dataIndex: 'standUseTime', align: 'right', key: 'standUseTime', },
    { title: '详细说明', dataIndex: 'detail', key: 'detail', ellipsis: true, },
    { title: '完成标准/提交物', dataIndex: 'completedNotes', key: 'completedNotes', ellipsis: true, },
  ])
}

function getType(o) {
  // return o.templateType
  return o.templateType === 'project' ? 'impProject' : o.templateType
}

export default function TemplateDetail(props) {
  const { params, switch: switchList, listData, close, showFooterTips, setCurrentId, templateType } = props
  const { id, templateName, status } = params
  const [action, setAction] = useState(null)
  const { doFetch: getDetail, loading, data } = useGet()
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [impSegmentOpt, priorityOpt, statusOpt, devSegmentOpt, keyNodesOpt, reqSegmentOpt] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const index = useMemo(() => _.findIndex(listData, o => o.id === id) + 1, [id, listData])
  const columns = useMemo(() => {
    return getColumns({ impSegmentOpt, priorityOpt, devSegmentOpt, templateType, keyNodesOpt, reqSegmentOpt })
  }, [impSegmentOpt, priorityOpt, devSegmentOpt, templateType, keyNodesOpt, reqSegmentOpt])

  const header = useMemo(() => {
    return <div className='header-title'>
      <div className='lef'>
        <div>{templateName}</div>
        <div
          style={{ backgroundColor: status === 'Y' ? '#5477ff' : '#9b9b9b' }}
        >
          {_.find(statusOpt, o => o?.value === status)?.label}
        </div>
      </div>
      <div className='rig'>
        <DataSwitch data={listData} {...{ index, switchList, setCurrentId, getType }} />
        <div><TextIconBtn icon='daochu' text='导出' onClick={() => setAction('export')} /></div>
      </div>
    </div>
  }, [index, listData, setCurrentId, status, statusOpt, switchList, templateName])

  const dataSource = useMemo(() => {
    if (_.isEmpty(data)) return []
    return _.orderBy(data, 'sortSerial', 'asc')
  }, [data])

  useEffect(() => {
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?id=${id}`)
  }, [getDetail, id])

  return (
    <>
      <Dialog
        header={header}
        loading={loading}
        className='template-detail'
        cancel={close}
        footerVisible={false}
      >
        <Table
          size='small'
          rowClassName={() => 'editable-row'}
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey='id'
          scroll={{ y: 99999, x: 880 }}
          bordered
        />
        <div className='footer flex center-y'>
          {
            showFooterTips &&
            <div className={'footer-tips'}>提示：模版调整任务请到<span style={{ color: 'red' }}>项目模版管理模块</span>进行操作</div>
          }
          <Button normal onClick={close}>关闭</Button>
        </div>
      </Dialog>
      {'export' === action && <ExportExcel parameter={{ searchName: templateName }} close={() => setAction(null)} />}
    </>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useGet} from "rootnet-biz/es/hooks";
import clsx from "clsx";
import './UserDeptGroupSelect.scss'
import _ from 'lodash'
import convertOptions from "../ConvertOptions";
import {Popover, Tag} from "antd";
import UserDeptGroupSelectPop from "./UserDeptGroupSelectPop";
import {REQUIRED_ERROR, useControlledError} from "../personSelectPop/useControlledError";
import {ErrorTooltip} from "rootnet-edit/lib/base/Tooltip";

const OPTIONS_URL = [
    '/common/userinfo',
    '/common/department?activeFlag=Y',
    '/uac/user/group/get/all',
    '/uac/role/get/all/role'
]

function UserDeptGroupSelect(props) {
    const outerAllUserRes = JSON.parse(localStorage.getItem('all_user'))
    const {required, onError, hiddenUserList, style, placeholder, disabled, multiple, allowClear, mustUserList, defaultOpen,
        allowDeptList, canSelectDept, canSelectGroup, canSelectRole} = props
    const [stateValue, setStateValue] = useState()
    const [visible, setVisible] = useState(defaultOpen)
    const [error, errorVisible, setStateError, setUserActive] = useControlledError(props);
    const [owner, setOwner] = useState(null)
    const {data: optionsRes} = useGet(OPTIONS_URL)
    const [isInited, setIsInited] = useState(false)

    const isEmptyValue = useMemo(()=>{
        if(_.isNil(stateValue)) return true
        if(!_.isEmpty(getTypeValue(stateValue,'user'))) return false
        if(!_.isEmpty(getTypeValue(stateValue,'department'))) return false
        if(!_.isEmpty(getTypeValue(stateValue,'group'))) return false
        if(!_.isEmpty(getTypeValue(stateValue,'role'))) return false
        return true
    },[stateValue])

    const [allUserRes, allDeptRes, allGroupRes, allRoleRes] = useMemo(()=>optionsRes || [],[optionsRes])

    const isControlled = useMemo(()=>{
        return !_.isNil(props.value) || _.isNil(props.defaultValue)
    },[props.value, props.defaultValue])

    const initData = useCallback((data)=>{
        setStateValue(data)
        setIsInited(true)
    },[])

    useEffect(()=>{
        if(isControlled){
            initData(props.value)
        }
    },[isControlled, initData, props.value])

    useEffect(()=>{
        if(isInited && !isControlled) return
        if(_.isNil(props.value)) return
        initData(props.value)
    },[props.value,initData, isInited, isControlled])

    useEffect(()=>{
        if(isInited && isControlled) return
        if(_.isNil(props.defaultValue)) return
        initData(props.defaultValue)
    },[props.defaultValue,initData,isInited, isControlled])

    useEffect(() => {
        const err = (required && isEmptyValue) ? REQUIRED_ERROR : null;
        if (err !== error) {
            setStateError(err);
            if (props.onError)
                props.onError(err);
        }
    }, [isEmptyValue, required, onError, props, error, setStateError])

    const show_error = useMemo(() => {
        return error && errorVisible && !_.isEmpty(outerAllUserRes)
    }, [error, errorVisible, outerAllUserRes])

    const handleFocus = useCallback(()=>{
        if(props.onFocus){
            props.onFocus()
        }
    },[props])

    const handleBlur = useCallback(()=>{
        setUserActive(true)
        if(props.onBlur){
            props.onBlur()
        }
    },[props,setUserActive])

    const onRemove = useCallback((removeItem, type)=>{
        setStateValue(old => {
            const cloneObj = _.map(old, item => {
                if(item.type !== type) return item
                item['valueList'] = _.filter(item.valueList, x => x !== removeItem)
                return item
            })
            if (props.onChange) {
                props.onChange(cloneObj)
            }
            return cloneObj
        })
    },[props])

    const onSelectConfirm = useCallback(newValue => {
        setStateValue(newValue)
        if (props.onChange) {
            props.onChange(newValue)
        }
    }, [props])

    const onVisibleChange = useCallback(visible => {
        if(!visible){
            handleBlur()
        }
        setVisible(disabled ? false : visible)
    },[disabled, handleBlur])

    return <Popover content={<UserDeptGroupSelectPop defaultValue={stateValue}
        {...{onSelectConfirm, setVisible, hiddenUserList, multiple, allowClear, mustUserList, allowDeptList, canSelectDept, canSelectGroup, canSelectRole}}/>}
        trigger="click" overlayClassName={'user-select-pop'}
        destroyTooltipOnHide={true} zIndex={9998} placement="bottom"
        open={visible} onOpenChange={onVisibleChange}
    >
        <div className={clsx('user-dept-group-select flex center-y', { error: show_error, disabled })}  ref={setOwner} onFocus={handleFocus} style={{ ...style }}>
            {
                _.map(getTypeValue(stateValue, 'user'), item => <Tag closable={!disabled} color={'#f0f0f0'} key={item} onClose={() => onRemove(item,'user')}>
                    {
                        convertOptions(item, allUserRes, 'userName', 'userAccount', {showOrigin: true})
                    }
                </Tag>)
            }
            {
                _.map(getTypeValue(stateValue, 'department'), item => <Tag closable={!disabled} color={'#f0f0f0'} key={item} onClose={() => onRemove(item,'department')}>
                    {
                        convertOptions(item, allDeptRes, 'departMentName', 'departMentId', {showOrigin: true})
                    }
                </Tag>)
            }
            {
                _.map(getTypeValue(stateValue,'group'), item => <Tag closable={!disabled} color={'#f0f0f0'} key={item} onClose={() => onRemove(item,'group')}>
                    {
                        convertOptions(item, allGroupRes, 'groupName', 'id', {showOrigin: true})
                    }
                </Tag>)
            }
            {
                _.map(getTypeValue(stateValue,'role'), item => <Tag closable={!disabled} color={'#f0f0f0'} key={item} onClose={() => onRemove(item,'role')}>
                    {
                        convertOptions(item, allRoleRes, 'roleName', 'id', {showOrigin: true})
                    }
                </Tag>)
            }
            {
                isEmptyValue &&
                <div className={'user-select-placeholder'}>{placeholder}</div>
            }
        </div>
        {
            show_error &&
            <ErrorTooltip error={error} target={owner} />
        }
    </Popover>
}

function getTypeValue(data, type){
    return convertOptions(type,data,'valueList','type')
}

UserDeptGroupSelect.defaultProps = {
    placeholder: '请选择',
    multiple: false,
    disabled: false,
    allowClear: true,
    marginLeft: false,
    canSelectDept: true,
    canSelectGroup: true,
}

export default UserDeptGroupSelect;
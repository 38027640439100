import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Messager, DataGrid, Card } from 'rootnet-ui'
import { GetTime } from 'rootnet-core/format'
import Api from '../../../../base/task';
import Option from './../common/option';
import { strParams } from './../../../../utils/publicFun';
import { getAllDate, getTabData, getCol, getData } from './../common/methods';
import { Box } from '../../../common/commonComponent';
import _ from 'lodash';
import './../common/common.scss';
import './leave.scss';

// let mockUrl = 'http://dev.croot.com/mock/rims';
let mockUrl = '';
let beginDate = GetTime(new Date());
function optionFor(obj, tableData) {
    return [
		{ header: '本周投入', bind: 'createUser', width: 80, 
			convert: (v, o, i) => { 
				let num = 0;
				_.forEach(obj, (item, i2) => {
					let v = _.get(tableData, `[${i}][whichDay${item.date.split('-').join('')}]`)
					v = v ? parseFloat(v) : 0;
					num += v;
				})
				return <span className="primary">{num}</span>
			} 
		},
    ]
}

let _childData = {};
function Project(props) {
	const { pid, childData } = props;
	const [flag, setFlag] = useState(null)
	const [ajax, setAjax] = useState({})
	const [tableData, setTableData] = useState(null)
	const [data, setData] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [option, setOption] = useState(null)
	const date = useRef('')
	_childData = childData;

	const init = useCallback((data, obj) => {
		let par = {
			jobType: _childData.id,
			jobTypePid: pid,
			beginDate: data.beginDate,
			endDate: data.endDate
		}
		setOption(null);
		Api.get(`${mockUrl}/worktime/other/detail?${strParams(par)}`).then(res => {
			res.data = _.isEmpty(res.data) ? [{}] : res.data;
			res.data = _.map(res.data, item => {
				if(_.get(item, 'auditUseTime')) item.auditUseTime = (item.auditUseTime/60).toFixed(1);
				return item
			})
			setOrgData(_.cloneDeep(res.data));
			let _data = getTabData(res.data, '', 'jobType');
			setData(_data)
			setTableData(_data)
			setOption(getCol(obj, _data, setTableData, optionFor));
			setAjax({ loading: false })
		}).catch((err) => {
			setAjax({ loading: false })
			Messager.show(err.Message, { icon: 'error' });
		})
	}, [pid])

	useEffect(() => {
		setFlag(_.assign({}, { flag: '' }))
	}, [props])

	useEffect(() => {
		if(_.isNil(flag)) return;
		setAjax({ loading: true })
		let par = { beginDate, flag: flag.flag };
		// if(!flag.flag) beginDate = GetTime(new Date());
		Api.get(`/worktime/plandate?${strParams(par)}`).then(res => {
			date.current =res.data;
			beginDate = _.get(res, 'data.beginDate');
			let _week = getAllDate(res.data);
			init(res.data, _week);
		}).catch((err) => {
			Messager.show(err.Message, { icon: 'error' });
		})
	}, [flag, init])

	function search(){
		let params = getData(orgData, tableData);
		// 过滤没有改变的数据  actionType 不纯在的
		params = _.filter(params, item => item.actionType);
		if(_.isEmpty(params)) return Messager.show('请修改后提交', { icon: 'error' })

		setAjax({ loading: true })
		Api.post('/worktime/edit', sendHand(params)).then(res => {
			init(date.current,getAllDate(date.current))
			Messager.show(res.msg, { icon: 'success' })
		}).catch(err => {
			Messager.show(err._message, { icon: 'error' })
		})
	}

	function sendHand(params){
		let _initP = {
			jobType: _childData.id,
			content: _childData.name,
		}

		return _.map(params, it => {
			it.auditUseTime = it.auditUseTime ? it.auditUseTime * 60 : it.auditUseTime
			let _par = _.pick(it, ['workDate', 'auditUseTime', 'actionType', 'id'])
			if(it.actionType === 'ADD') _par.status = 'approve';
			return _.assign({}, _par, _initP);
		})
	}

	return <Card className="work_Time_list" loading={ ajax.loading }>
		<Option
			flag={_.get(flag, 'flag') || ''} 
			weekChange={(v) => setFlag({ flag: v })}
			search={search}
			leftHid={true}
		/>
		<div className="list-con">
			<Box className='flex-y x-card-singlegrid' data={data} loading={!option || ajax.loading}>
				<DataGrid option={option} data={data} className='row-fill' />
			</Box>
		</div>
	</Card>
}

export default Project

import React from 'react';
import {Icon} from "../../../../components";
import './UserDeptGroupItem.scss'

function UserDeptGroupItem(props) {
    const {type, value, text, icon, onClear} = props
    return <div className={'user-dept-group-item flex center-y'}>
        <div><Icon name={icon} style={{ marginRight: 8, fontSize: 16 }} />{text}</div>
        <Icon className='clear-icon' name='baocuo' onClick={() => onClear(value, type)} />
    </div>
}

export default UserDeptGroupItem;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Loader} from 'rootnet-ui'
import './WorkplaceCalendar.scss'
import { Calendar, Badge } from 'antd';
import {Icon, TextIconBtn} from "../../../project_share/components";
import moment from 'moment'
import _ from 'lodash'
import ReqStageUpdateDialog from "../../stageMgt/reqStageUpdateDialog/ReqStageUpdateDialog";
import TodoListDetailDialog from "../../todolist/components/todoListDetailDialog";
import {useGet} from "rootnet-biz/es/hooks";
import {strParams} from "../../../utils/publicFun";
import {toDate} from "rootnet-core/dateFormat";
import clsx from "clsx";

const statusColor = {
    1: 'red',
    2: 'orange',
    3: '#6D7176',
    4: 'green',
    5: '#6D7176',
}

function WorkplaceCalendar(props) {
    const {fullScreenCard, onSwitchFullScreen} = props
    const [currentDate, setCurrentDate] = useState(moment())
    const [showDetailDialog, setShowDetailDialog] = useState()
    const {data: dailyReportInMonth ,doFetch: getDailyReportInMonth, loading: dailyReportLoading} = useGet()
    const {data: todoList ,doFetch: getTodoList, loading: todoListLoading} = useGet()

    const loading = useMemo(()=>{
        return dailyReportLoading || todoListLoading
    },[dailyReportLoading, todoListLoading])

    const [startOfMonth, endOfMonth] = useMemo(()=>{
        return [
            moment(currentDate).startOf('month'),
            moment(currentDate).endOf('month'),
        ]
    },[currentDate])

    const [startOfMonthWeek, endOfMonthWeek] = useMemo(()=>{
        return [
            moment(startOfMonth).startOf('week'),
            moment(endOfMonth).endOf('week'),
        ]
    },[startOfMonth, endOfMonth])

    const refresh = useCallback(()=>{
        if(_.isNil(startOfMonthWeek)) return
        if(_.isNil(endOfMonthWeek)) return
        const dailyReportSearchParams = {
            beginDate: startOfMonthWeek.format('YYYY-MM-DD'),
            endDate: endOfMonthWeek.format('YYYY-MM-DD'),
        }
        getDailyReportInMonth('/staff/report/filled?'+strParams(dailyReportSearchParams))

        const todoListSearchParams = {
            beginDate: startOfMonth.format('YYYY-MM-DD'),
            endDate: endOfMonth.format('YYYY-MM-DD'),
        }
        getTodoList('/workCalendar/todoListAndStage?'+strParams(todoListSearchParams))
    },[getDailyReportInMonth, startOfMonthWeek, endOfMonthWeek, getTodoList, startOfMonth, endOfMonth])

    useEffect(()=>{
        refresh()
    },[refresh])

    const [hasReportDays, hasReportWeeks] = useMemo(()=>{
        if(_.isEmpty(dailyReportInMonth)) return []
        const daysReport = _.filter(dailyReportInMonth, x => x.type === 'day')
        const days = _.map(daysReport, x => moment(toDate.str14ToDate(x.beginDate)))
        const weeksReport = _.filter(dailyReportInMonth, x => x.type === 'week')
        const weeks = _.map(weeksReport, x => moment(toDate.str14ToDate(x.beginDate)))
        return [days, weeks]
    },[dailyReportInMonth])

    const dateCellRender = useCallback((value)=>{
        const startOfWeek = moment(value).startOf('week')
        const endOfWeek = moment(value).endOf('week')
        const showWeeklyReport = (moment(currentDate).month() === startOfWeek.month()) || (moment(currentDate).month() === endOfWeek.month())
        const todayList = _.filter(todoList, x => moment(toDate.str14ToDate(_.get(x,'eventDate'))).isSame(value, 'day'))
        const hasDayReport = isMomentInclude(hasReportDays, value, 'day')
        const hasWeekReport = isMomentInclude(hasReportWeeks, value, 'week')
        return <div className={'date-item flex-y'}>
            {
                showWeeklyReport &&
                <div className="weekly-report flex center" onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    {
                        value.day() === 4 &&
                        <TextIconBtn text={'周报'} icon={hasWeekReport?'zhengque':'tianjia'} className={clsx('weekly-report-add',{'has-data':hasWeekReport})}
                                     onClick={()=>window.open(`/#/staffReportMgt?createDate=${value.format('YYYY-MM-DD')}&createMode=week`)}/>
                    }
                </div>
            }
            {
                value.isBetween(startOfMonth, endOfMonth) &&
                <div className={'daily-info flex-y'}>
                    <div className={'daily-report-info flex'}>
                        <TextIconBtn text={'日报'} icon={hasDayReport?'zhengque':'tianjia'} className={clsx('daily-report-add',{'has-data':hasDayReport})}
                                     onClick={()=>window.open(`/#/staffReportMgt?createDate=${value.format('YYYY-MM-DD')}&createMode=day`)}/>
                    </div>
                    <div className="todo-list-wrap flex-y">
                        {
                            _.map(todayList, x => {
                                return <div className={'todo-item flex center-y'} key={x.id}>
                                    {
                                        x.delayFlag !== 5 &&
                                        <Badge color={_.get(statusColor, x.delayFlag) || 'default'}/>
                                    }
                                    {
                                        x.delayFlag === 5 &&
                                            <Icon name={'zhengque'} className={'todo-done-icon'}/>
                                    }
                                    <div className="todo-title-wrap flex">
                                        <span className={'todo-title'} style={{color: _.get(statusColor, x.delayFlag)}} onClick={()=>setShowDetailDialog({
                                            module: _.get(x,'type'),
                                            id: _.get(x, 'id')
                                        })}>
                                            {x.title}
                                        </span>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            }
        </div>

        function isMomentInclude(list, time, type = 'day'){
            return _.some(list, x => x.isSame(time, type))
        }
    },[startOfMonth, endOfMonth, currentDate, hasReportDays, hasReportWeeks, todoList])

    const extra = useMemo(()=>{
      return <div className={'extra-wrap flex'}>
          {/*<TextIconBtn text={'工时填报'} icon={'bianji2'} onClick={()=>window.open(`/#/worktimeadd`)}/>*/}
          <Icon name={'shuaxin1'} className={'can-click-icon handle-icon'} onClick={refresh}/>
          <Icon name={fullScreenCard === 'calendar'?'suoxiao':'fangda'} className={'can-click-icon handle-icon'} onClick={()=>onSwitchFullScreen('calendar')}/>
      </div>
    },[fullScreenCard, onSwitchFullScreen, refresh])

    return <div className={clsx('workplace-calendar flex',{'hidden-card': !_.isNil(fullScreenCard), 'show-card': fullScreenCard === 'calendar'})}>
        <Card title={'工作日历'} extra={extra}>
            {
                loading && <Loader fill/>
            }
            <Calendar onPanelChange={setCurrentDate} dateCellRender={dateCellRender} />
        </Card>
        {
            _.get(showDetailDialog,'module') === 'stage' &&
                <ReqStageUpdateDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}/>
        }
        {
            _.get(showDetailDialog,'module') === 'todoList' &&
            <TodoListDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}/>
        }
    </div>
}

export default WorkplaceCalendar;
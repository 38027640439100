import React, { useMemo, useState } from 'react'
import { isNil } from 'rootnet-core/format'
import { DataGrid } from 'rootnet-ui'
import convertOptions from '../../../../../common/ConvertOptions'
import { OperationList } from '../../../../../common/OperationList'
import DevelopmentCompletionDetails from './developmentCompletionDetails'

function getColumns(props) {
  const { allUserRes, typeOpt, stateOpt, setOpen } = props
  return [
    { header: '开发人员', bind: 'userId', width: 90, tooltip: true, align: 'center', convert: getUser },
    // { header: '标准用时', bind: 'planTime', width: 100, tooltip: true, align: 'center' },
    { header: '实际用时', bind: 'realUseTime', width: 100, tooltip: true, align: 'center' },
    { header: '修改类型', bind: 'modifiedType', width: 90, tooltip: true, align: 'center', convert: v => convertOptions(v, typeOpt) },
    { header: '完成状态', bind: 'currentState', width: 90, tooltip: true, align: 'center', convert: v => convertOptions(v, stateOpt) },
    // { header: '首次接收时间', bind: 'firstDevAcceptdate', width: 120, tooltip: true, align: 'center' },
    // { header: '完成时间', bind: 'firstTestAcceptdate', width: 120, tooltip: true, align: 'center' },
    { header: '操作', width: 70, tooltip: true, align: 'center', convert: createOperation },
  ]
  function createOperation(v, o, i) {
    const options = [
      { text: '详情', onClick: () => setOpen({ id: o.id, loginUser: o.userId }) }
    ]
    return <OperationList options={options} />
  }
  function getUser(v) {
    const userName = convertOptions(v, allUserRes, 'userName', 'userAccount')
    if (isNil(userName)) return v
    return userName
  }
}

const gridOptsFor = (columns) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  autoFill: true,
  virtualized: true,
  fixedRight: 1,
  columns,
})

export default function DevelopmentCompletion(props) {
  const { allUserRes, data, loading, refresh, typeOpt, stateOpt, whetherOpt, performanceFlagOpt, tracerState, isClose, isCts } = props
  const [open, setOpen] = useState(false)

  const option = useMemo(() => gridOptsFor(getColumns({ allUserRes, typeOpt, stateOpt, setOpen })), [allUserRes, stateOpt, typeOpt])
  return (
    <>
      <DataGrid loading={loading} data={data} option={option} />
      {open && <DevelopmentCompletionDetails {...{ refresh, typeOpt, stateOpt, whetherOpt, performanceFlagOpt, ...open, close: () => setOpen(false), tracerState, isClose, isCts }} />}
    </>
  )
}

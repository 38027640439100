import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import WorkTimeReviewAndSearchList from '../components/workTimeReviewAndSearchList'
import { TextIconBtn } from '../../common/TextIconBtn'
import getColumn from './allColumns'
import { useFuncCode, useRolePermissions } from '../../common/commonMethod'

const initParams = {
  typeFlag: 'selectRecordPage',
  pageNum: 1,
  pageSize: 20,
  states: ['1', '2'],
  isAuthFlag: '0',
}

export default function WorkTimeSearch() {
  const [selectItems, setSelectItems] = useState([])
  const [currentInfo, setCurrentInfo] = useState()
  const [canSelectOthers] = useFuncCode(['700301'])
  const [downloadExcel] = useRolePermissions(['downloadExcel'])

  const handClick = useCallback((type) => {
    if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作！', { icon: 'error' })
    const ids = _.map(selectItems, 'id')
    setCurrentInfo({ mode: type, ids, list: selectItems })
  }, [selectItems])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {canSelectOthers && <TextIconBtn text='转移处理人' icon='bianji' onClick={() => handClick('transferHandler')} />}
      <TextIconBtn text='导出' icon='daochu' onClick={() => setCurrentInfo({ mode: downloadExcel ? 'downloadExcel' : 'export' })} />
    </div >
  }, [handClick, canSelectOthers, downloadExcel])

  return (
    <WorkTimeReviewAndSearchList
      title={'工时查询'}
      exportTitle='工时查询'
      initParams={initParams}
      {...{ extra, getColumn, selectItems, setSelectItems, currentInfo, setCurrentInfo }}
    />
  )
}
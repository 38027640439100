import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { RMBFormat, N2 } from 'rootnet-core/format'
import { Drawer, Tooltip } from 'rootnet-ui'
import { Form, FormInput, Display, CheckBox } from 'rootnet-edit'
import actionFormat from './actionFormat'
import { uniqKeyFor } from '../../../../project_share/utils/utils'
import { useGet } from '../../../../utils/hook'
import './infoDetail.scss'
import { Icon } from '../../../../components'

const WORKTIME_URL = '/worktime/costcenter?activeFlag=Y'
const ECHO_URL = '/project/detail'

const HFormInput = (props) => {
  const { options, required, capitalize, text, ...rest } = props
  const INPUT = <FormInput horizontal
    componentWidth={200}
    component={Display}
    options={options}
    {...rest} required={required} />
  if (capitalize) return <Tooltip title={RMBFormat(capitalize)} >{INPUT}</Tooltip>
  if (text) return <Tooltip title={text}>{INPUT}</Tooltip>
  return INPUT
}

const projectStatus = Object.freeze({
  '01': '进行中',
  '02': '挂起',
  '03': '完成',
  '04': '作废',
})

export default function InfoDetail(props) {
  const { open, cancel, setOperation, jurisdiction } = props
  const { budgetCost, budgetRev, id, level, assignedCost } = open
  const [params, setParams] = useState()
  const { data: centralData } = useGet(WORKTIME_URL)
  const { data: defaultData, loading, doFetch } = useGet()
  const listForm = useMemo(() => _.get(actionFormat, `details.form`, () => { }), [])

  const isTrue = useMemo(() => (_.isNil(budgetCost) && _.isNil(budgetRev)) ? true : false, [budgetCost, budgetRev])

  useEffect(() => {
    if (id) doFetch(`${ECHO_URL}?Id=${id}`)
  }, [doFetch, id])

  useEffect(() => {
    if (_.isEmpty(defaultData)) return
    setParams(defaultData)
  }, [defaultData])

  useEffect(() => {
    const height = document.getElementsByClassName('details-memo')[0]
    if (height) {
      height.style.marginTop = `${parseInt((height.clientHeight / 19 - 1) * 19.4)}px`
    }
  }, [params])

  return (
    <Drawer
      demount
      header={
        <>
          <div className='subProject'>详情 <span style={{ paddingLeft: '10px', color: '#7A8199' }}>{_.get(defaultData, 'projectName')}</span></div>
          {
            (!(open?.projectStatus === '03' || open?.projectStatus === '04') || jurisdiction === 'Manager' || open?.jurisdiction) &&
            <Tooltip title='编辑'>
              <Icon
                className='edit-icon'
                name='bianji2'
                disable={loading}
                onClick={() => { setOperation({ mode: 'edit', data: { ...defaultData, level, assignedCost, childrenProjectList: _.get(open, 'childrenProjectList') } }); cancel() }}
              />
            </Tooltip>
          }
        </>
      }
      open={open !== false}
      cancel={cancel}
      loading={loading}
      onOutsideClick={cancel}
      footerVisible={false}
      className='project-infoForm-detail'
    >
      <Form value={params} >
        {
          _.map(_.compact(listForm({ params, isTrue, level, handleInConvert, defaultData, Checked })), item => {
            if (_.isEmpty(item)) return <hr key={uniqKeyFor()} style={{ border: '1px solid  #E9EAF2' }} />
            return (
              <HFormInput key={item.bind ? item.bind : uniqKeyFor()} {...item} />
            )
          })
        }
      </Form>

    </Drawer>
  )

  function splicing(str, char) {
    if (!str) return ''
    return (str && !char) ? str : `${str}-${char}`
  }

  function Checked() {
    return <span style={{ marginLeft: '92px' }}>
      <CheckBox value={true} disabled={true} />
      叶子项目
    </span>
  }

  function handleInConvert(data, bind) {
    let string = []
    if (bind === 'costCenterName') {
      if (centralData && params?.costCenter) {
        string = []
        const datas = _.find(centralData, v => v.costId === params?.costCenter)
        levelString(datas)
        return string.join('/')
      }
    }
    if (bind === 'revenueCenterName') {
      if (centralData && params?.revenueCenter) {
        string = []
        const datas = _.find(centralData, v => v.costId === params?.revenueCenter)
        levelString(datas)
        return string.join('/')
      }
    }
    function levelString(radios) {
      if (!_.get(radios, 'pid')) return string.unshift(_.get(radios, 'name'))
      string.unshift(_.get(radios, 'name'))
      const data = _.find(centralData, o => o.id === _.get(radios, 'pid'))
      return levelString(data)
    }
    if (bind === 'priceFlag' && data) return data === 'Y' ? '是' : '否'
    if (bind === 'productName' && data) return <span className='benefit-text'>{data}</span>
    if (bind === 'actualStartDate' || bind === 'actualEndDate') return _.split(data, ' ')[0]
    if (bind === 'projectStatus' && data) return projectStatus[data]
    if (bind === 'customer' && data) return splicing(params?.customer, params?.customerName)
    if ((bind === 'startDate' || bind === 'endDate') && data) return data.split(' ')[0]
    if (bind === 'totalCost') return N2(_.add(Number(params?.budgetCost), Number(params?.projectSubsidies)))
    if (bind === 'totalRevenue') return N2(_.add(Number(params?.budgetRev), Number(params?.approveSubsidies)))
    if (bind === 'expectedRateOfReturn') return _.isFinite((_.round((params?.budgetRev - params?.budgetCost) / params?.budgetRev * 100, 1))) ? `${(_.round((params?.budgetRev - params?.budgetCost) / params?.budgetRev * 100, 1)).toFixed(1)}%` : '0%'
    if (bind === 'memo') return params?.memo ? <div className='details-memo' dangerouslySetInnerHTML={{ __html: params?.memo.replace(/\n/g, '<br/>').replace(/\t/g, '&emsp;') }} /> : ''
    return N2(data)
  }
}

import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, Button, Messager } from 'rootnet-ui'
import { Display, Form, FormInput } from 'rootnet-edit'
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'
import { isNil } from '../../../../appraise/components/method'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import { Icon } from '../../../../../components'
import './index.scss'
import { gd } from 'rootnet-core/base'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'

const HFormInput = props => <FormInput horizontal labelWidth={90} componentWidth={150} {...props} />

const EDIT_URL = '/customerService/modify'

export default function AcceptanceResultDialog(props) {
  const { close, defaultData, updateDynamic, isEdit, refreshDetail } = props
  const [mode, setMode] = useState('detail')
  const [params, setParams] = useState(defaultData)
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch: submitPost } = usePost()

  const isDetail = useMemo(() => {
    return mode === 'detail'
  }, [mode])

  const canSubmit = useMemo(() => {
    return isNil(_.trim(_.get(params, 'dealInfo')))
  }, [params])

  const submit = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.clone(params)
    const setNull = []
    _.forEach(params, (v, k) => {
      if (isNil(v)) {
        setNull.push({
          field: k,
          value: v
        })
      }
    })
    postParams['setNull'] = setNull
    postParams['dealUser'] = gd.User.operator_userName
    postParams['dealTime'] = dateFormat('YYYY-MM-DD HH:MM:SS', new Date())
    submitPost(EDIT_URL, postParams)
      .then(() => {
        refreshDetail()
        updateDynamic()
        setMode('detail')
        Messager.show('修改成功', { icon: 'success' })
        setIsDisable(false)
        close()
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
        setIsDisable(false)
      })
  }, [close, submitPost, params, isDisable, updateDynamic, refreshDetail])

  return (
    <Dialog
      // loading={loading}
      className='acceptance-result-dialog'
      cancel={close}
      headerVisible={false}
      footerVisible={false}
    >
      <div className='acceptance-result-dialog-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title flex">
            受理结果填写
            <div className='title-hint'>请填写最终的受理结果，客户可在根网客户服务平台，同步可以看到回复内容</div>
          </div>
          <div className="mock-right-header flex center-y">
            {
              isEdit &&
              <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                if (!isDetail) setParams(defaultData)
                setMode(isDetail ? 'edit' : 'detail')
              }} />
            }
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <div className="content-main-panel">
          <Form value={params} onChange={setParams}>
            <HFormInput label='回复人' bind='dealUser' component={Display} />
            <HFormInput label='回复时间' bind='dealTime' component={Display} convert={v => {
              if (_.isNil(v)) return '-'
              if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
              return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
            }} />
            <HFormInput label='回复内容' bind='dealInfo' component={isDetail ? Display : TextAreaInput} className='text-area-detail-style' style={{ height: 340 }} componentWidth={230} />
          </Form>
        </div>
        <div className="mock-footer flex center-y">
          <div />
          <div className="btn-group flex">
            {
              isDetail &&
              <Button normal onClick={close}>关闭</Button>
            }
            {
              !isDetail &&
              <Button normal onClick={() => {
                setParams(defaultData)
                setMode('detail')
              }}>取消</Button>
            }
            {
              !isDetail &&
              <Button primary onClick={submit} disable={canSubmit} >确认</Button>
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}

import _ from 'lodash'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { FormInput, Select } from 'rootnet-edit'
import { Form, Radio } from 'antd'
import { Messager, Switch, Dialog, Button } from 'rootnet-ui'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { useGet } from 'rootnet-biz/es/hooks'
import DataSwitch from '../components/DataSwitch'
import { TemplateEditContext } from '../../../../common/Context'
import BubbleBox from '../../../../common/BubbleBox'
import DelMessage from '../../../../../components/DelMessage'
import { uniqKeyFor } from '../../../../../project_share/utils/utils'
import { useApi } from '../../../../../utils/hook'
import ExportExcel from '../components/ExportExcel'
import AddTaskForms from '../components/AddTaskForms'
import DragTable from '../components/DragTable'
import gd from '../../../../../base/global'
import SelectedOperation from '../components/SelectedOperation'
import { getColumns, EditableCell } from '../components/method'
import SelectDetail from '../components/SelectDetail'
import './TemplateInForm.scss'

const HFormInput = (props) => <FormInput {...props} labelWidth={81} horizontal required componentWidth={350} />

const PROJECT_TASK_URL = '/implementTask/list'
const PROJECT_URL = '/implement/project/list'
const ADD_ALL_URL = '/projectTemplate/addWithdtl'
const ADD_URL = '/projectTemplatedtl/add'
const EDIT_URL = '/projectTemplatedtl/update'
const DEL_URL = '/projectTemplatedtl/delete'
const DETAIL_URL = '/projectTemplate/list'
const PROJECT_TEMPLATE_URL = '/projectTemplate/allList'
const SELECT_URL = (type) => {
  return _.compact([
    type === 'req' && '/common/globalconst?globalConst=reqMileStone', //需求
    type === 'impProject' && '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=segment', //实施项目
    type === 'devProject' && '/common/globalconst?globalConst=devSegment',//研发项目
    '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=PRIORITYLIST',
    type === 'devProject' && '/common/globalconst?globalConst=keyNodes', //关键节点
    type === 'devProject' && 'common/globalconst?globalConst=typeOfRDProject', //项目类型
    type === 'devProject' && 'common/globalconst?globalConst=projectRole', //负责角色
  ])
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const MoreOperation = (setAction, setModeAll, data) => {
  return <div className='allConditions-popover'>
    <div onClick={() => {
      setModeAll(x => ({ mode: 'copy', data, type: x.type }))
    }}>复制</div>
    <div onClick={() => setAction('export')}>导出</div>
  </div >
}

const Priority = (data, selectPriority) => {
  return <div className='allConditions-popover'>
    {
      _.map(data, o => {
        return <div
          key={o?.value}
          // style={{ color: EXECUTION_COLOR[o?.value] }}
          onClick={() => selectPriority(o?.value)}
        >
          {o?.label}
        </div>
      })
    }
  </div>
}

const radioOptions = (flag) => {
  return _.compact([
    { label: '空白模板', value: 1 },
    { label: '从模板中选择', value: 2 },
    flag && { label: '从项目中选择', value: 3 },
  ])
}

const GET_PROJECT_TYPE = Object.freeze({
  'impProject': 'IMP',
  'devProject': 'DEV',
  'req': 'CUS'
})

const GET_TEMPLATE_TYPE = Object.freeze({
  'impProject': 'project',
  'devProject': 'devProject',
  'req': 'req'
})

function getType(o) {
  return o.templateType === 'project' ? 'impProject' : o.templateType
}

export default function TemplateInForm(porps) {
  const { modeAll, setModeAll, statusData, listData, refresh, refreshList, close, isFuncCodeEdit, setCurrentId } = porps
  const { id, templateName, status } = modeAll.data || {}
  const [detailData, setDetailData] = useState([])
  const [pageName, setPageName] = useState() //模板名称
  const [action, setAction] = useState()
  const [active, setActive] = useState(1) //tab切换
  const [isChange, setIsChange] = useState(null)
  const [open, setOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [template, setTemplate] = useState(null)
  const [selectedList, setSelectedList] = useState()//选中
  const [projectType, setProjectType] = useState()
  const { doFetch: getDetail, loading } = useGet()
  const { doFetch } = useApi()
  const { data, doFetch: selectFetch } = useGet()
  const { doFetch: templateFetch, data: allData } = useGet()
  const { data: keyNodesRes, doFetch: getKeyNodes } = useGet()

  const isProject = useMemo(() => modeAll?.type === 'impProject', [modeAll])
  const isDevProject = useMemo(() => modeAll?.type === 'devProject', [modeAll])
  const isAdd = useMemo(() => modeAll?.mode === 'add', [modeAll])
  const isAC = useMemo(() => _.includes(['add', 'copy'], modeAll?.mode), [modeAll])
  const radioOpt = useMemo(() => radioOptions(isProject), [isProject])
  const initProjectType = useMemo(() => _.get(modeAll, 'initProjectType'), [modeAll])

  const [form] = Form.useForm()

  useEffect(() => {
    if (isDevProject && !isAdd) setProjectType(initProjectType)
  }, [isDevProject, initProjectType, isAdd])

  useEffect(() => {
    if (!isDevProject || _.isNil(projectType)) return
    getKeyNodes(`/common/globalconst?globalConst=${_.toUpper(projectType)}keyNodes`)
  }, [getKeyNodes, projectType, isDevProject])

  useEffect(() => {
    selectFetch(SELECT_URL(modeAll?.type))
  }, [modeAll, selectFetch])

  const isHeader = useMemo(() => {
    if (isAC) {
      return modeAll?.mode === 'add' ? '新建模板' : '复制模板'
    }
  }, [isAC, modeAll.mode])

  const dataRefresh = useCallback((id, flag = false) => {
    if (_.isNil(id)) return
    const url = flag ? `${PROJECT_TASK_URL}?projectId=${id}` : `${DETAIL_URL}?id=${id}`
    getDetail(url)
      .then(res => setDetailData(_.orderBy(res, 'sortSerial', 'asc')))
  }, [getDetail])

  const submit = useCallback((url, data) => {
    return doFetch(url, 'post', data)
  }, [doFetch])

  const [segmentOpt, priorityOpt, keyNodesOptions, devProjectTypeOptions, projectRoleList] = useMemo(() => {
    if (_.isEmpty(data)) return []
    return [
      _.map(_.get(data, '[0]'), (o, i) => ({ label: o.displayName, value: o.interiorId, sort: i + 1 })),
      _.map(_.get(data, '[1]'), o => ({ label: o.displayName, value: o.interiorId })),
      _.map(_.get(data, '[2]'), o => ({ label: o.displayName, value: o.interiorId })),
      _.map(_.get(data, '[3]'), o => ({ label: o.displayName, text: o.displayName, value: o.interiorId })),
      _.map(_.get(data, '[4]'), o => ({ label: o.displayName, text: o.displayName, value: o.interiorId })),
    ]
  }, [data])

  const keyNodesOpt = useMemo(() => {
    if (isDevProject) {
      if (_.isEmpty(keyNodesRes)) return []
      return _.map(keyNodesRes, o => ({ label: o.displayName, value: o.interiorId }))
    }
    if (_.isEmpty(keyNodesOptions)) return []
    return keyNodesOptions
  }, [isDevProject, keyNodesRes, keyNodesOptions])

  const addTask = useCallback((parameter, findIndex) => {
    if (isAC) {
      let par;
      if (!_.isNil(findIndex)) {
        par = [{ ...parameter, id: uniqKeyFor(), }]
      } else par = [{ id: uniqKeyFor(), sortSerial: 1, ...parameter }]
      setVisible(false)
      setDetailData(x => _.assign([], _.orderBy(_.concat(x, par), 'sortSerial', 'asc')))
    } else {
      submit(ADD_URL, [parameter])
        .then(() => {
          refreshList()
          dataRefresh(id)
          setVisible(false)
          Messager.show('添加成功', { icon: 'success' })
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
  }, [dataRefresh, id, isAC, refreshList, submit])

  const confirm = useCallback(() => {
    if (_.size(_.trim(pageName)) === 0) return Messager.show('请填写模板名称', { icon: 'error' })
    if (isDevProject && _.isNil(projectType)) return Messager.show('请选择项目类型', { icon: 'error' })
    if (_.isEmpty(detailData)) return Messager.show('请添加模板明细', { icon: 'error' })
    const parameter = _.assign({}, {
      status: 'Y',
      projectType: _.get(GET_PROJECT_TYPE, `${modeAll?.type}`),
      templateType: _.get(GET_TEMPLATE_TYPE, `${modeAll?.type}`),
      templateName: pageName,
      createUser: gd.User.operator_id,
      projectTemplatedtls: detailData,
    }, isDevProject && { projectType })
    submit(ADD_ALL_URL, parameter)
      .then(() => {
        refreshList()
        close()
        Messager.show('添加成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [close, detailData, modeAll, pageName, refreshList, submit, isDevProject, projectType])

  const copyList = useCallback(() => {
    const filterData = _.filter(detailData, o => _.includes(selectedList, o.id))
    const maxData = _.get(_.orderBy(filterData, 'sortSerial', 'desc'), '[0]')
    const findIndex = _.findIndex(detailData, o => o === maxData)
    const sortIndex = findIndex + 1 === _.size(detailData) ? findIndex : findIndex + 1
    const sortSerial = findIndex === sortIndex ?
      _.get(detailData[findIndex], 'sortSerial') + 0.1 :
      (_.get(detailData[findIndex], 'sortSerial') + _.get(detailData[sortIndex], 'sortSerial')) / 2
    const cloneData = _.map(filterData, (o, i) => {
      return _.assign({}, o, {
        id: uniqKeyFor(),
        mode: 'copy',
        sortSerial: i === 0 ? sortSerial : sortSerial + (i / 10)
      })
    })
    if (isAC) {
      setDetailData(x => _.assign([], _.orderBy(_.concat(x, cloneData), 'sortSerial', 'asc')))
    } else {
      submit(ADD_URL, _.map(cloneData, o => _.omit(o, 'id')))
        .then(() => {
          dataRefresh(id)
          Messager.show('复制成功', { icon: 'success' })
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }

  }, [dataRefresh, detailData, id, isAC, selectedList, submit])

  const handleSave = useCallback((row, upData) => {
    const key = _.keys(upData)[0]
    const val = _.values(upData)[0]
    const index = _.findIndex(detailData, x => x.id === row.id)
    if (detailData[index][key] === val) return
    if (isAC) {
      setDetailData(oldList => {
        return _.orderBy(_.map(oldList, (item, i) => {
          if (i !== index) return item
          return ({
            ...item,
            ...row
          })
        }), 'sortSerial', 'asc')
      })
    } else {
      doFetch(EDIT_URL, 'post', [row])
        .then(() => {
          Messager.show('编辑成功', { icon: 'success' })
          setDetailData(oldList => {
            return _.orderBy(_.map(oldList, (item, i) => {
              if (i !== index) return item
              return ({
                ...item,
                ...row
              })
            }), 'sortSerial', 'asc')
          })
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
  }, [detailData, doFetch, isAC])

  const selectPriority = useCallback((value) => {
    if (isAC) {
      setDetailData(oldData => _.assign([], _.map(oldData, o => {
        return _.assign(o, _.includes(selectedList, o.id) && { priority: value })
      })))
    } else {
      const parameter = _.map(selectedList, v => ({ id: v, priority: value }))
      submit(EDIT_URL, parameter)
        .then(() => {
          Messager.show('编辑成功', { icon: 'success' })
          setDetailData(oldData => _.assign([], _.map(oldData, o => {
            return _.assign(o, _.includes(selectedList, o.id) && { priority: value })
          })))
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
    setDetailData(x => _.assign([], x))
  }, [isAC, selectedList, submit])

  const delData = useCallback(() => {
    const filterData = _.filter(detailData, o => !_.includes(selectedList, o.id))
    setDetailData(_.assign([], filterData))
    setSelectedList([])
  }, [detailData, selectedList])

  const index = useMemo(() => _.findIndex(listData, o => o.id === id) + 1, [id, listData])
  const MoreOperations = useCallback((data) => MoreOperation(setAction, setModeAll, data), [setModeAll])
  const SettingPriority = useCallback(() => Priority(priorityOpt, selectPriority), [priorityOpt, selectPriority])

  const columns = useMemo(() => {
    return _.map(getColumns({ segmentOpt, priorityOpt, keyNodesOpt, setVisible, projectRoleList, isDevProject }), col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave
        })
      }
    })
  }, [handleSave, isDevProject, keyNodesOpt, priorityOpt, segmentOpt, projectRoleList])

  const header = useMemo(() => {
    return <div className='header-title'>
      <div className='lef'>
        <div>{templateName}</div>
        <div>
          <Switch
            checked={status === 'Y'}
            checkedComponent={_.find(statusData, item => item.interiorId === 'Y')?.displayName}
            uncheckedComponent={_.find(statusData, item => item.interiorId === 'N')?.displayName}
            onChange={checked => {
              refresh(id, 'status', { status: checked ? 'Y' : 'N' }, modeAll.data, checked === status)
              refreshList()
            }}
          />
        </div>
      </div>
      <div className='rig'>
        <DataSwitch data={listData} switchList={setModeAll} {...{ index, isFuncCodeEdit, setCurrentId, getType }} />
        <BubbleBox icon='gengduo' text=''><MoreOperations data={detailData} /></BubbleBox>
      </div>
    </div>
  }, [detailData, id, index, isFuncCodeEdit, listData, modeAll.data, refresh, refreshList, setCurrentId, setModeAll, status, statusData, templateName])

  // function checkDragPosition({ oldIndex, newIndex }) {
  //   const currentSegment = _.get(detailData[oldIndex], 'segment')
  //   const currentSegmentSortSerial = _.get(_.find(segmentOpt, x => x.value === currentSegment), 'sortSerial')
  //   if (newIndex === 0) {
  //     const nextSegment = _.get(detailData[newIndex], 'segment')
  //     const nextSegmentSortSerial = _.get(_.find(segmentOpt, x => x.value === nextSegment), 'sortSerial')
  //     return currentSegmentSortSerial <= nextSegmentSortSerial
  //   } else if (newIndex + 1 === _.size(detailData)) {
  //     const previousSegment = _.get(detailData[newIndex], 'segment')
  //     const previousSegmentSortSerial = _.get(_.find(segmentOpt, x => x.value === previousSegment), 'sortSerial')
  //     return previousSegmentSortSerial <= currentSegmentSortSerial
  //   } else {
  //     const previousSegment = _.get(detailData[oldIndex < newIndex ? newIndex : newIndex - 1], 'segment')
  //     const previousSegmentSortSerial = _.get(_.find(segmentOpt, x => x.value === previousSegment), 'sortSerial')
  //     const nextSegment = _.get(detailData[oldIndex < newIndex ? newIndex + 1 : newIndex], 'segment')
  //     const nextSegmentSortSerial = _.get(_.find(segmentOpt, x => x.value === nextSegment), 'sortSerial')
  //     return previousSegmentSortSerial <= currentSegmentSortSerial && currentSegmentSortSerial <= nextSegmentSortSerial
  //   }
  // }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let nIndex = newIndex
      // if (!checkDragPosition({ oldIndex, newIndex })) return
      if (_.get(detailData[oldIndex], 'segment') !== _.get(detailData[nIndex], 'segment')) return Messager.show('不允许跨阶段调整任务顺序')
      if (oldIndex < nIndex) {
        const last = detailData[nIndex].sortSerial
        const next = _.get(detailData, `[${nIndex + 1}]sortSerial`)
        detailData[oldIndex].sortSerial = _.isNil(next) ? last + 0.1 : (last + next) / 2
      }
      else if (oldIndex > nIndex) {
        const last = _.get(detailData, `[${nIndex - 1}]sortSerial`, 0)
        const next = detailData[nIndex].sortSerial
        detailData[oldIndex].sortSerial = (last + next) / 2
      }
      if (!isAC) {
        doFetch(EDIT_URL, 'post', [_.pick(detailData[oldIndex], ['id', 'sortSerial', 'pid'])])
          .catch(err => Messager.show(err._message, { icon: 'error' }))
      }
      const newData = arrayMoveImmutable([].concat(detailData), oldIndex, nIndex).filter(el => !!el);
      setDetailData(newData)
    }
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const EditableRow = useCallback(({ index, ...restProps }) => {
    const currentIndex = detailData.findIndex(x => x.id === restProps['data-row-key']);
    return <Form form={form} component={false}>
      <TemplateEditContext.Provider value={{ form, priorityOpt, segmentOpt, keyNodesOpt, projectRoleList, detailData }}>
        <SortableItem index={currentIndex} {...restProps} />
      </TemplateEditContext.Provider>
    </Form>
  }, [detailData, keyNodesOpt, priorityOpt, segmentOpt, projectRoleList, form])

  useEffect(() => {
    if (isAdd) return
    dataRefresh(id)
  }, [dataRefresh, id, isAdd])

  useEffect(() => {
    if (!_.isNil(template?.id)) {
      setSelectedList([])
      dataRefresh(template?.id, template?.flag)
    }
  }, [dataRefresh, template])

  useEffect(() => {
    setDetailData([])
    setSelectedList([])
    setTemplate(null)
  }, [active])

  useEffect(() => {
    if (isAC) {
      const projectUrl = `${PROJECT_URL}?principal=${gd.User.operator_id}`
      const areaUrl = gd?.Area === 'ALL' ? projectUrl : `${projectUrl}&area=${gd?.Area}`
      const templateUrl = `${PROJECT_TEMPLATE_URL}?templateType=${_.get(GET_TEMPLATE_TYPE, `${modeAll?.type}`)}`
      templateFetch(_.compact([templateUrl, isProject && areaUrl]))
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
  }, [templateFetch, modeAll, isAC, isProject])

  useEffect(() => {
    if (_.isNil(index)) return
    setSelectedList(_.assign([]))
  }, [index])

  function radioChange(value) {
    if (active === 1 && _.isEmpty(detailData)) return setActive(value)
    if ((active === 2 || active === 3) && _.isNil(template?.name)) return setActive(value)
    setIsChange(value)
  }

  const rowSelection = {
    onChange: (selectedRowKeys) => setSelectedList(selectedRowKeys),
    selectedRowKeys: selectedList,
  };

  return (
    <>
      <Dialog
        header={isAC ? isHeader : header}
        loading={(isAdd && !template?.id) ? false : loading}
        className='template-inForm'
        cancel={() => { close(); !isAC && refreshList() }}
        footerVisible={false}
      >
        <div className='template-content'>
          {
            isAC &&
            <>
              <HFormInput value={pageName} onChange={setPageName} label='模板名称' placeholder='请输入模板名称' />
              {
                isDevProject &&
                <HFormInput label='项目类型' onChange={setProjectType} placeholder='请选择项目类型' component={Select} options={devProjectTypeOptions} />
              }
              <div style={{ margin: 8 }}>
                <Radio.Group value={active} onChange={e => radioChange(e.target.value)} options={radioOpt} />
              </div>
              {
                (active === 2 || active === 3) && <HFormInput
                  value={template?.name}
                  onFocus={() => {
                    if (isDevProject && _.isNil(projectType)) return Messager.show('请先填写项目类型', { icon: 'error' })
                    setOpen(true)
                  }}
                  label={active === 2 ? '选择模板' : '选择项目'}
                  placeholder={active === 2 ? `请选择模板` : `请选择项目`}
                />
              }
            </>
          }
          <div className='template-table'>
            {
              _.size(selectedList) > 0 &&
              <SelectedOperation {...{ selectedList, copyList, setAction, setSelectedList, SettingPriority, isAC, delData }} />
            }
            <DragTable
              clickAdd={() => {
                if (isDevProject && _.isNil(projectType)) return Messager.show('请先填写项目类型', { icon: 'error' })
                setVisible({ data: { pid: id, sortSerial: 1 } })
              }}
              dataSource={detailData}
              columns={columns}
              rowSelection={{ ...rowSelection }}
              components={{ body: { wrapper: DraggableContainer, row: EditableRow, cell: EditableCell, }, }}
              scroll={{ y: 9999999, x: 880 }}
              isAdd={((isAC && active === 1) || !isAC)}
            />
          </div>

        </div>
        <div className='footer'>
          <Button normal onClick={() => { close(); refreshList() }}>{isAC ? '取消' : '关闭'}</Button>
          {isAC && <Button onClick={confirm} primary>确认</Button>}
        </div>
      </Dialog>

      {open && <SelectDetail close={() => setOpen(false)} {...{ active, allData, setTemplate, template, isDevProject, projectType }} />}

      {visible && <AddTaskForms  {...{ ...visible, setVisible, segmentOpt, priorityOpt, keyNodesOpt, projectRoleList, addTask, detailData, isDevProject }} />}

      {'export' === action && <ExportExcel parameter={{ searchName: templateName }} close={() => setAction(null)} />}

      {
        !_.isNil(isChange) && <Dialog
          header='提示'
          confirm={() => { setIsChange(null); setActive(isChange) }}
          cancel={() => setIsChange(null)}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, fontSize: 14 }}
        >
          切换后数据将被清空
        </Dialog>
      }

      {
        action === 'delete' &&
        <DelMessage
          method='post'
          refresh={() => { dataRefresh(id); setSelectedList([]) }}
          close={() => setAction(null)}
          url={DEL_URL}
          params={selectedList}
        >
          确定删除当前内容？
        </DelMessage>
      }
    </>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { Messager, Button } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { useGet } from 'rootnet-biz/lib/hooks'
import { copyText } from 'rootnet-core/clipboard'
import { Icon } from '../../../../components'
import convertOptions from '../../../common/ConvertOptions'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import UserSelect from '../../../common/personSelectPop/UserSelect'
import { strParams } from '../../../../utils/publicFun'
import WorkFlowChangePop from '../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import './index.scss'

const GLOBAL_CONST_OPTIONS_URLS = [
  '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

const FUNC_CODE = '1501'

export default function DevListDetailHeader(props) {
  const { editFormData, setEditFormData, isDetail, allUserRes, id, refreshViewList, refreshDetail, updateDynamic, setIsClose } = props

  const [showCopyPop, setShowCopyPop] = useState(false)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: workFlowInfo, doFetch: getStatus } = useGet()
  const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

  const status = useMemo(() => {
    return _.get(workFlowInfo, 'factorId')
  }, [workFlowInfo])

  const refreshStatus = useCallback(() => {
    if (_.isNil(id)) return
    const params = {
      businessType: 'dev',
      businessId: id
    }
    getStatus('/WorkflowBusiness/getWorkflowBusiness?' + strParams(params))
  }, [getStatus, id])

  useEffect(() => {
    refreshStatus()
  }, [refreshStatus])

  const [workFlowStatusOptions] = useMemo(() => {
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const workflowColor = useMemo(() => {
    return _.get(_.find(workFlowStatusOptions, x => x.value === status), 'color') || '#000'
  }, [workFlowStatusOptions, status])

  const copy = useCallback((value) => {
    copyText(value);
    setShowCopyPop(false)
    Messager.show('数据已复制到剪切板', { icon: 'success' });
  }, [])

  const CopyArea = useCallback(() => {
    const detailUrl = window.location.host + `/#/requirementMgt?initId=` + _.get(editFormData, 'id')
    return <div className={'flex dev-list-title-copy-popover center-y'}>
      <Button onClick={() => copy(_.get(editFormData, 'title'))} normal>复制标题</Button>
      <Button onClick={() => copy(_.get(editFormData, 'storyId'))} normal>复制自提单ID</Button>
      <Button onClick={() => copy(`${_.get(editFormData, 'storyId')}\n${_.get(editFormData, 'title')}`)} normal>复制ID+标题</Button>
      <Button onClick={() => copy(detailUrl)} normal>复制自提单URL</Button>
      <Button onClick={() => copy(`${_.get(editFormData, 'storyId')}\n${_.get(editFormData, 'title')}\n${detailUrl}`)} normal>复制ID+标题+URL</Button>
    </div>
  }, [copy, editFormData])

  useEffect(() => {
    setIsClose(convertOptions(status, workFlowStatusOptions) === '关闭')
  }, [setIsClose, status, workFlowStatusOptions])

  return (
    <div className='dev-list-detail-header flex'>
      <div className="header-left">
        <div className="req-id-wrap flex center-y">
          <div className="type-tag flex center-y">{_.get(editFormData, 'type')}</div>
          <div className="req-id">{_.get(editFormData, 'storyId')}</div>
        </div>
        {
          isDetail &&
          <div className="req-title-wrap flex center-y">
            <div className="req-title">
              <Tooltip title={_.get(editFormData, 'title')}>
                {_.get(editFormData, 'title')}
              </Tooltip>
            </div>
            <Popover content={<CopyArea />} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
              <Icon name={'fuzhi'} className={'copy-icon'} />
            </Popover>
          </div>
        }
        {
          !isDetail && <FormInput horizontal bind={'title'} label={'标题'} required value={_.get(editFormData, 'title')}
            onChange={v => setEditFormData(x => ({ ...x, title: v }))} />
        }
      </div>
      <div className="header-right flex">
        <div className="header-right-item flex center-y">
          <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{ color: '#165DFF' }} />
          <div className="header-right-info flex-y">
            {
              isDetail &&
              <div className="header-right-value">
                <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes, 'userName', 'userAccount')}</div>
              </div>
            }
            {
              !isDetail &&
              <FormInput horizontal required={status !== '172096195508834304'} value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                onChange={v => setEditFormData(x => ({ ...x, currentUser: v }))} />
            }
            <div className="header-right-field">
              处理人
            </div>
          </div>
        </div>
        <Popover destroyTooltipOnHide={true}
          content={<WorkFlowChangePop businessId={id} close={() => setShowWorkFlowPop(null)} refreshList={() => {
            refreshStatus()
            refreshViewList()
            refreshDetail(id)
            updateDynamic()
          }} funcCode={FUNC_CODE} businessType={'dev'} />}
          onOpenChange={visible => {
            if (!visible) {
              setShowWorkFlowPop(null)
            }
          }}
          trigger={'click'}
          placement="bottomRight"
          open={showWorkFlowPop}
        >
          <div className="header-right-item flex center-y status-wrap-item" onClick={() => setShowWorkFlowPop(true)}>
            <Icon name={'renling'} className={'header-right-icon'} style={{ color: workflowColor }} />
            <div className="header-right-info flex-y">
              <div className="header-right-value">
                <div className={'status-tag flex center'} style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
                  {convertOptions(status, workFlowStatusOptions) || '-'}
                </div>
              </div>
              <div className="header-right-field">
                当前状态
              </div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { Tabs } from 'antd'
import { strParams } from '../../../../../../utils/publicFun'
import TaskListMaking from './taskListMaking'
import VersionMakingMsg from './versionMakingMsg'
import './index.scss'

export default function VersionMaking(props) {
  const { currentId, history, firstOrder, secondOrder = 'taskListMaking', allUserRes, isShow, isALL, principal } = props
  const [activeTab, setActiveTab] = useState(secondOrder)
  return (
    <div className='version-making'>
      <Tabs centered activeKey={activeTab}
        onChange={v => {
          history.replace({ pathname: '/versionMsg', search: strParams({ initId: currentId, firstOrder, secondOrder: v, }) })
          setActiveTab(v)
        }}
        items={[
          {
            forceRender: true,
            label: '任务清单制作标记',
            key: 'taskListMaking',
            children: <TaskListMaking {...{ currentId, allUserRes, firstOrder, secondOrder, isALL, principal }} />,
          },
          {
            label: '版本制作',
            key: 'versionMakingMsg',
            children: <VersionMakingMsg {...{ currentId, allUserRes, isShow }} />,
          }
        ]}
      />
    </div>
  )
}

import _ from 'lodash'
import cls from 'clsx'
import React, { useState, useMemo, useEffect, useCallback, useReducer } from 'react'
import { Drawer, Tooltip, MessageBox, Messager } from 'rootnet-ui'
import { Form, FormInput, CheckBox } from 'rootnet-edit'
import { N2, DateForWithSkip } from 'rootnet-core/format'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import actionFormat from './actionFormat'
import { uniqKeyFor } from '../../../../project_share/utils/utils'
import { useGet, useApi } from '../../../../utils/hook'
import { selectOption } from '../../../common/commonMethod'
import gd from '../../../../base/global'
import ContractDialog from './ContractDialog'
import TreeTable from './treeTable'
import { isNil } from 'rootnet-core/format'
import { Icon } from '../../../../project_share/components'
import ImportantAdjustment from './importantAdjustment'
import './InfoForm.scss'

const ECHO_URL = '/project/detail'

const INITIAL = [
  { title: '预算成本(元)', before: 'budgetCost', after: true },
  { title: '预算收入(元)', before: 'budgetRev', after: true },
  { title: '成本中心', before: 'costCenterName', after: false },
  { title: '收入中心', before: 'revenueCenterName', after: false },
  { title: '项目周期', before: 'time', after: false },
]

const headrName = {
  add: '新增一级项目',
  addSubproject: '新增子级项目',
  edit: '编辑',
}

function removal(o) {
  const result = []
  const repeat = {}
  if (o) {
    for (let i = 0; i < o.length; i++) {
      if (!repeat[o[i].userAccount]) {
        result.push(o[i])
        repeat[o[i].userAccount] = true
      }
    }
    return result
  }
}

const { dateStringToDate } = toDate

function dateInit() {
  const firstDay = new Date(Date.now() - 1000 * 60 * 60 * 24 * 365)
  return {
    startDate: firstDay,
    endDate: dateStringToDate(DateForWithSkip(new Date(), 1) + ' 00:00:00'),
  }
}

const HFormInput = (props) => {
  const { colSpan = false, lineFeed = false, text = null, ...res } = props
  const INPUT = <FormInput className={cls({ lineFeed })} horizontal componentWidth={colSpan ? 556 : 200} {...res} />
  if (!isNil(text) && !isNil(text('', res.bind))) return <Tooltip title={text('', res.bind)}>{INPUT}</Tooltip>
  return INPUT
}

const suffix = (click) => (< Icon name="shibai" onClick={click} />)

const Checked = (props) => {
  return <span className='tooltip-tips'>
    <CheckBox {...props} />
    <Tooltip title='勾选即代表当前创建项目为叶子项目，不允许再有子项目' placement='top'><Icon name='bangzhu' style={{ color: '#5477FF', fontSize: '16px' }} /></Tooltip>
  </span>
}

export default function InfoForm(props) {
  const { open, cancel, search } = props
  const { mode, data } = open || {}
  const [options, setOptions] = useState([])
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [turnOn, setTurnOn] = useState(false)
  const [showContract, setShowContract] = useState(false)
  const [showAdjust, setShowAdjust] = useState(false)
  const [message, setMessage] = useState(false)
  const [showBeforeInvalid, setShowBeforeInvalid] = useState(false)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [count, updateCount] = useReducer((state, action) => {
    if (action) return 0;
    return 1;
  }, 0);

  const { data: listData, doFetch: getFetch, loading } = useGet()
  const { data: parentDetail, doFetch: getParentDetail } = useGet()
  const { data: echoData, doFetch: echoFetch, loading: echoLoading } = useGet()
  const { doFetch } = useApi()

  useEffect(() => {
    setOptions(listData)
  }, [listData])

  const isLoading = useMemo(() => {
    return loading || echoLoading
  }, [loading, echoLoading])

  const listForm = useMemo(() => _.get(actionFormat, `${mode}.form`, () => { }), [mode])
  const change = useMemo(() => _.get(actionFormat, `${mode}.change`, () => { }), [mode])
  const err = useMemo(() => _.get(actionFormat, `${mode}.error`, () => { }), [mode])
  const commit = useMemo(() => _.get(actionFormat, `${mode}.confirm`, () => { }), [mode])
  const defaultValues = useMemo(() => _.get(actionFormat, `${mode}.defaultV`, () => { }), [mode])

  const isAllow = useMemo(() => (_.includes(params?.projectType, 'CUS') && !params?.priceFlag), [params])

  const isOnlyPMLeader = useMemo(() => {//是否是PMLeader且不是PMO
    const userPositionList = JSON.parse(localStorage.getItem('userPositionList'))
    return _.includes(userPositionList, 'PMLeader') && !_.includes(userPositionList, 'PMO')
  }, [])

  const isPriceFlag = useMemo(() => {//是否是PMO
    const userPositionList = JSON.parse(localStorage.getItem('userPositionList'))
    return _.includes(userPositionList, 'PMO')
  }, [])

  const boxLoading = useMemo(() => {
    if (mode === 'add') return loading
    else if (mode && mode !== 'add') return (echoLoading || loading)
  }, [echoLoading, loading, mode])

  const listOpt = useMemo(() => {//需要的参数
    if (mode === 'add') {
      const [d1, d2, d3, d4, d5, d6, d7, d8, d9] = options || []
      const filterProjectTypeData = _.filter(d1, x => _.includes(['DEV', 'CUS'], x.interiorId))
      const projectType = _.get(params, 'projectType')
      const projectTypeOpt = _.map(isOnlyPMLeader ? filterProjectTypeData : d1, x => {
        if (_.includes(projectType, 'DEV')) {
          if (x.interiorId === 'DEV') {
            return { text: x.displayName, value: x.interiorId }
          } else {
            return { text: x.displayName, value: x.interiorId, _disabled: true }
          }
        }
        if (!_.isEmpty(projectType)) {
          if (x.interiorId === 'DEV') {
            return { text: x.displayName, value: x.interiorId, _disabled: true }
          } else {
            return { text: x.displayName, value: x.interiorId }
          }
        }
        return { text: x.displayName, value: x.interiorId }
      })

      return {
        projectType: projectTypeOpt,
        projectManager: selectOption(removal(d2), ['userName', 'userAccount', 'userAccount,userName']),
        productId: selectOption(d3, ['productName', 'productId', 'productName,productId']),
        projectStatus: selectOption(d4, ['displayName', 'interiorId']),
        customer: _.map(d5, o => ({ text: `${o.tCustid}-${o.tCustname}`, value: o.tCustid, salesman: o.salesman })),
        stdPriceDev: d6, stdPriceService: d7, userRes: d8, costCenterRes: d9,
      }
    } else if (mode === 'addSubproject' && echoData) {
      const [d1, d2, d3, d4, d5, d6, d7, d8, d9] = options || []
      const projectTypeOpt = _.map(_.filter(d1, o => o.interiorId === _.find(_.get(echoData, 'projectType').split(','), v => v === o.interiorId)), o => ({ text: o.displayName, value: o.interiorId }))
      return {
        projectType: projectTypeOpt,
        projectManager: selectOption(removal(d2), ['userName', 'userAccount', 'userAccount,userName']),
        productId: selectOption(d3, ['productName', 'productId', 'productName,productId']),
        projectStatus: selectOption(d4, ['displayName', 'interiorId']),
        customer: selectOption(d5, ['tCustid,tCustname', 'tCustid']),
        stdPriceDev: d6, stdPriceService: d7, userRes: d8, costCenterRes: d9,
      }
    } else if (mode === 'edit') {
      const [d1, d2, d3, d4, d5, d6, d7, d8, d9] = options || []
      const projectType = _.get(params, 'projectType')
      const projectTypeOpt = _.map(d1, x => {
        if (_.includes(projectType, 'DEV')) {
          if (x.interiorId === 'DEV') {
            return { text: x.displayName, value: x.interiorId }
          } else {
            return { text: x.displayName, value: x.interiorId, _disabled: true }
          }
        }
        if (!_.isEmpty(projectType)) {
          if (x.interiorId === 'DEV') {
            return { text: x.displayName, value: x.interiorId, _disabled: true }
          } else {
            return { text: x.displayName, value: x.interiorId }
          }
        }
        return { text: x.displayName, value: x.interiorId }
      })

      return {
        projectType: projectTypeOpt,
        projectManager: selectOption(removal(d2), ['userName', 'userAccount', 'userAccount,userName']),
        productId: selectOption(d3, ['productName', 'productId', 'productName,productId']),
        projectStatus: selectOption(d4, ['displayName', 'interiorId']),
        customer: _.map(d5, o => ({ text: `${o.tCustid}-${o.tCustname}`, value: o.tCustid, salesman: o.salesman })),
        stdPriceDev: d6, stdPriceService: d7, userRes: d8, costCenterRes: d9,
      }
    }
    return {}
  }, [echoData, isOnlyPMLeader, mode, options, params])

  const handleInConvert = useCallback((data, bind) => {
    let string = []

    // if (bind === 'costCenterName' && !isAllow) {
    if (bind === 'costCenterName') {
      if (listOpt?.costCenterRes && params?.costCenter) {
        string = []
        const datas = _.find(listOpt?.costCenterRes, v => v.costId === params?.costCenter)
        levelString(datas)
        return string.join('/')
      }
    }
    // if (bind === 'revenueCenterName' && !isAllow) {
    if (bind === 'revenueCenterName') {
      if (listOpt?.costCenterRes && params?.revenueCenter) {
        string = []
        const datas = _.find(listOpt?.costCenterRes, v => v.costId === params?.revenueCenter)
        levelString(datas)
        return string.join('/')
      }
    }
    function levelString(radios) {
      if (!_.get(radios, 'pid')) return string.unshift(_.get(radios, 'name'))
      string.unshift(_.get(radios, 'name'))
      const data = _.find(listOpt?.costCenterRes, o => o.id === _.get(radios, 'pid'))
      return levelString(data)
    }
  }, [listOpt, params])

  const requiredData = useMemo(() => {//必传数据
    if (mode === 'add') {
      return { isPriceFlag, isAllow, setShowContract, params, setParams, setTurnOn, handleInConvert, suffix }
    } else if (mode === 'addSubproject') {
      return { params, setParams, setShowContract, setTurnOn, handleInConvert, Checked, forceUpdate }
    } else if (mode === 'edit') {
      return { level: data?.level, childrenProjectList: _.get(data, 'childrenProjectList'), setShowContract, params, echoData, handleInConvert, setParams, setTurnOn }
    }
    return {}
  }, [data, echoData, handleInConvert, isAllow, isPriceFlag, mode, params])

  const adjustData = useMemo(() => {//编辑时 判断是否将重要参数进行调整
    const arr = []
    if (mode === 'edit') {
      _.forEach(INITIAL, item => {
        let upData
        const current = _.get(echoData, `${item.before}`)
        const after = _.get(params, `${item.before}`)
        if (item.after) upData = Number(after)
        else upData = after
        if (current !== upData && !_.isNil(current) && !_.isNil(upData)) arr.push({ title: item.title, before: current, after })
        if (item.before === 'time') {
          if (params?.startDate || params?.endDate) {
            const startDate = dateFormat('YYYY-MM-DD', params?.startDate)
            const endDate = dateFormat('YYYY-MM-DD', params?.endDate)
            const startDateEcho = dateFormat('YYYY-MM-DD', new Date(_.get(echoData, `startDate`)))
            const endDateEcho = dateFormat('YYYY-MM-DD', new Date(_.get(echoData, `endDate`)))
            if (startDateEcho !== startDate || endDateEcho !== endDate) {
              arr.push({
                title: item.title,
                before: `${endDateEcho} 至 ${endDateEcho}`,
                after: `${startDate} 至 ${endDate}`
              })
            }
          }
        }
      })
    }
    return arr
  }, [echoData, mode, params])

  useEffect(() => {
    if (!_.isEmpty(_.get(listOpt, 'projectType')) && echoData && mode === 'addSubproject' && count === 0 && echoData?.id === data?.id) {
      defaultValues(listOpt, echoData, setParams, data)
      forceUpdate()
      updateCount(false)
    }
    if (!_.isEmpty(_.get(listOpt, 'projectType')) && echoData && (mode === 'edit' || mode === 'details')) {
      defaultValues(listOpt, echoData, setParams, data)
      forceUpdate()
    }
  }, [listOpt, echoData, mode, defaultValues, data, count])

  useEffect(() => {
    const defaultParams = { projectStatus: '01', budgetCost: 0, budgetCostTime: 0, budgetRev: 0, projectManager: gd.User.operator_name }
    if (mode === 'add') {
      setParams(_.assign({}, defaultParams, {
        startDate: new Date(),
        endDate: lastDay()
      }))
    } else if (mode === 'addSubproject' && echoData) {
      setParams(_.assign({}, defaultParams, {
        projectType: _.get(_.split(echoData.projectType, ','), '[0]'),
        assignableCost: N2(echoData.assignableCost),
        enableCostAssignment: N2(echoData.enableCostAssignment),
        superiorProject: echoData.projectName,
        pid: echoData.id,
        priceId: echoData.priceId,
        contractName: echoData.contractName,
        contractNum: echoData.contractNum,
        productId: _.isNil(echoData.productId) ? [] : echoData.productId.split(','),
        startDate: _.isNil(_.get(echoData, 'endDate')) ? dateInit().startDate : new Date(echoData.startDate),
        endDate: _.isNil(_.get(echoData, 'endDate')) ? dateInit().endDate : new Date(echoData.endDate),
        priceFlag: echoData.priceFlag === 'Y' ? true : false,
        customer: echoData?.customer
      }))
    } else if (mode === 'edit') {
      setParams(_.assign({}, echoData, {
        assignableCost: N2(echoData?.assignableCost),
        enableCostAssignment: N2(echoData?.enableCostAssignment),
        productId: _.isNil(echoData?.productId) ? [] : echoData?.productId.split(','),
        expectedRateOfReturn: _.isFinite((_.round((echoData?.budgetRev - echoData?.budgetCost) / echoData?.budgetRev * 100, 1))) ? `${(_.round((echoData?.budgetRev - echoData?.budgetCost) / echoData?.budgetRev * 100, 1)).toFixed(1)}` : '0',
        startDate: dateStringToDate(echoData?.startDate),
        endDate: dateStringToDate(echoData?.endDate),
        projectType: Number(data?.level) === 0 ? echoData?.projectType.split(',') : echoData?.projectType,
        leafProjectFlag: Number(data?.level) === 4 || echoData?.leafProjectFlag === '1',
        budgetRev: echoData?.budgetRev ? echoData?.budgetRev : 0,
        budgetCost: echoData?.budgetCost ? echoData?.budgetCost : 0,
        priceFlag: echoData?.priceFlag === 'Y'
      }))
    }
  }, [data, echoData, mode])

  useEffect(() => {
    if (mode && mode !== 'details') getFetch(_.get(actionFormat, `${mode}.url`))
  }, [getFetch, mode])

  useEffect(() => {
    if (mode && mode !== 'add') echoFetch(`${ECHO_URL}?Id=${data?.id}&action=${mode === 'edit' ? 'edit' : 'add'}`)
  }, [data, echoFetch, mode])

  useEffect(() => {
    if (mode === 'edit' && !_.isNil(data?.pid)) getParentDetail(`${ECHO_URL}?Id=${data?.pid}`)
  }, [data, getParentDetail, mode])

  useEffect(() => {
    const level = data?.level
    if (mode === 'edit' && !isLoading) setTimeout(() => err({ params, setError, echoData, level, assignedCost: data?.assignedCost }), 100)
    else err({ params, setError, echoData, level })
  }, [data, echoData, err, mode, params, isLoading])

  return (
    <Drawer
      demount
      header={<div className='subProject'>{headrName[mode]} <span style={{ paddingLeft: '10px', color: '#7A8199' }}>{_.get(echoData, 'projectName')}</span></div>}
      open={!_.isNil(open)}
      cancel={cancels}
      className='project-infoForm'
      confirm={confirm}
      onOutsideClick={cancels}
      loading={boxLoading}
    >
      <Form value={params} onChange={handleChange} error={error} onError={setError} >
        {
          _.map(listForm(requiredData), item => {
            if (_.isEmpty(item)) return <hr key={uniqKeyFor()} style={{ border: '1px solid  #E9EAF2' }} />
            return <HFormInput key={isNil(item.bind) ? uniqKeyFor() : item.bind}  {...item} options={listOpt[item.bind]} />
          })
        }
      </Form>
      {turnOn && <TreeTable params={params} setParams={setParams} turnOn={turnOn} setTurnOn={setTurnOn} />}

      {
        showContract && <ContractDialog
          change={setParams}
          close={() => setShowContract(false)}
          initId={_.get(params, 'contractNum')}
          customer={_.get(params, 'customer')}
        />
      }

      {
        message && <MessageBox
          className={'content-center-dialog'}
          header='提示'
          cancelButtonVisible={false}
          confirm={() => setMessage(false)}
        >
          {message}
        </MessageBox>
      }

      {
        showBeforeInvalid && <MessageBox
          header='提示'
          confirm={() => commit({ params, doFetch, cancels, setMessage, echoData, search })}
          cancel={() => setShowBeforeInvalid(null)}
          className={'content-center-dialog'}
        >
          {showBeforeInvalid}
        </MessageBox>
      }

      {
        showAdjust && <ImportantAdjustment
          data={adjustData}
          cancel={() => setShowAdjust(false)}
          params={params}
          setParams={setParams}
          confirm={() => commit({ params, doFetch, cancels, setMessage, echoData, search })}
        />
      }
    </Drawer>
  )

  function confirm() {
    if (mode === 'edit') {
      const projectStatus = _.get(params, 'projectStatus')
      if (!_.includes(['03', '04'], projectStatus)) {
        if ((
          Number(echoData?.assignableCost) < Number(params?.budgetCost) ||
          Number(echoData?.enableCostAssignment) < Number(params?.budgetRev)
        ) && Number(data?.level) !== 0) return Messager.show('数据有误，请更正', { icon: 'error' })
      }
      if (projectStatus === '04') return setShowBeforeInvalid('项目作废，仅在项目信息创建错误时使用，且不能存在已分配需求和工时填报信息，作废后状态不可逆。请谨慎操作！')
      else if (projectStatus === '03') return setShowBeforeInvalid('项目关闭，意味着所有项目相关工作结束，包括成本的归集（工时填报、费用报销），关闭后状态不可逆。请谨慎操作！')
    } else if (mode === 'addSubproject') {
      if (
        (Number(echoData.assignableCost) < Number(params.budgetCost) || Number(echoData.enableCostAssignment) < Number(params.budgetRev)) &&
        mode === 'addSubproject') {
        return Messager.show('数据有误，请更正', { icon: 'error' })
      }
    }
    if (!_.every(error, _.isNil)) return Messager.show('请填写必填项', { icon: 'error' })
    if (_.size(_.trim(params?.memo)) < 5) return Messager.show('项目简介不能少于5个字符', { icon: 'error' })
    if (mode === 'edit' && !_.isEmpty(adjustData)) return setShowAdjust(true)
    commit({ params, doFetch, cancels, setMessage, echoData, search })
  }

  function handleChange(options, key) {
    const parameter = {
      error,
      params,
      echoData,
      setParams,
      changeOptions,
      getFirstLevel,
      level: data?.level,
      parentDetail,
      ...listOpt,
    }
    change(options, key, parameter)
  }

  function changeOptions(options, username) {
    const deptId = _.get(_.find(listOpt?.userRes, x => x.userAccount === username), 'department')
    const costCenter = _.find(listOpt?.costCenterRes, x => x.deptId === deptId && (getFirstLevel(listOpt?.costCenterRes, x, 'id', 'pid').costId === '01'))
    if (_.toNumber(_.get(options, 'budgetCost')) !== 0 && (_.isNil(_.get(options, 'costCenter')) || _.get(options, 'costCenter') === '')) {
      _.assign(options,
        {
          costCenter: _.get(costCenter, 'costId'),
          costCenterId: _.get(costCenter, 'costId'),
          costCenterName: _.get(costCenter, 'name'),
        })
    }
    if (_.toNumber(_.get(options, 'budgetRev')) !== 0 && (_.isNil(_.get(options, 'revenueCenter')) || _.get(options, 'revenueCenter') === '')) {
      _.assign(options,
        {
          revenueCenter: _.get(costCenter, 'costId'),
          revenueCenterId: _.get(costCenter, 'costId'),
          revenueCenterName: _.get(costCenter, 'name'),
        })
    }
  }

  function cancels() {
    cancel()
    setError()
    setParams()
    setTurnOn(false)
    setMessage(false)
    setShowAdjust(false)
    setShowContract(false)
    setShowBeforeInvalid(false)
    updateCount(true)
    setOptions([])
  }

}

function lastDay() {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth() + 1
  const day = now.getDate()
  const nextMonth = parseInt(month)
  if (parseInt(month) === 12) return new Date((parseInt(year) + 1), 0, day)// 如果是12月，要下个月，取下一年的1月
  const nextSize = new Date(year, parseInt(month) + 1, 0).getDate()
  if (nextSize < parseInt(day)) return new Date(year, nextMonth, nextSize) // 如果下个月总天数小于本月今天，取下个月最后一天
  return new Date(year, nextMonth, day)
}

function getFirstLevel(list, item, idName, pidName) {
  if (_.isNil(item[pidName])) {
    return item
  } else {
    const parentItem = _.find(list, x => x[idName] === item[pidName])
    return getFirstLevel(list, parentItem, idName, pidName)
  }
}

import CryptoJS from "crypto-js";

function base64Encode(val){
    let s = CryptoJS.enc.Utf8.parse(val);
    return CryptoJS.enc.Base64.stringify(s);
}

/**
 * Base64译码
 * @param val base编码后的字符串
 * @returns {string} 原字符串
 */
function base64Decode(val){
    let s = CryptoJS.enc.Base64.parse(val);
    return s.toString(CryptoJS.enc.Utf8);
}

export {base64Encode, base64Decode}
import React, { useState } from 'react'
import _ from 'lodash'
import { Button } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import UserSelect from '../../../common/personSelectPop/UserSelect'
import CascadeSelect from '../../../common/cascadeSelect/CascadeSelect'
import DateSelector from '../../../common/dateSelector/DateSelector'
import './index.scss'

export default function Option(props) {
  const { search, deptOptions, setParameter, initParams } = props
  const [params, setParams] = useState(initParams)
  return (
    <div className='flex c-options work-time-deletion-list-option'>
      <Form value={params} onChange={setParams}>
        <FormInput label='员工' bind='userAccounts' multiple component={UserSelect} allowClear />
        <FormInput label='部门' bind='departments' component={CascadeSelect} multiple search clear options={deptOptions} />
        <FormInput label='工时周期' bind='period' component={DateSelector} componentWidth={220} range clear />
      </Form>
      <div className='search-btn-group flex'>
        <Button className={'search-btn'} primary onClick={() => {
          search(params)
          setParameter(params)
        }} >查询</Button>
        <Button className={'reset-btn'} text onClick={() => {
          setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), initParams))
          search(initParams)
          setParameter(initParams)
        }} >重置</Button>
      </div>
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
// import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Display, Select, RadioGroup, Input } from 'rootnet-edit'
import useGet from 'rootnet-biz/es/hooks/useGet'
import { Icon } from '../../../../../components'
import convertOptions from '../../../../common/ConvertOptions'
import './index.scss'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { selectOption } from '../../../../common/commonMethod'
import { isNil } from 'rootnet-core/format'

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={180} {...props} />

const radioOptions = [
  { text: '是', value: 'Y' },
  { text: '否', value: 'N' },
]

const radioOptionsNum = [
  { text: '是', value: '1' },
  { text: '否', value: '0' },
]

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=BugType', // 类型
  'common/globalconst?globalConst=PRIORITYLIST', // 优先级
  'common/globalconst?globalConst=performanceFlag', // 性能影响
  'common/globalconst?globalConst=MODIFYCODETYPE', // 修改类型
  'common/globalconst?globalConst=BUGSOURCE', // 来源 
  'common/globalconst?globalConst=issueway', // 发布方式
]

const OPTIONS_URLS = [
  '/story/getbugProject',
]

export default function TrackingDetailRightPanel(props) {
  const {
    isDetail,
    editFormData, setEditFormData,
    allUserRes, editFormError, setEditFormError,
    productTreeOptions, productOptions, moduleOptions,
    setBugType,
  } = props
  const [baseInfoExpand, setBaseInfoExpand] = useState(true)
  const [customerInfoExpand, setCustomerInfoExpand] = useState(true)
  const [createInfoExpand, setCreateInfoExpand] = useState(true)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const [bugTypeOptions, tracerPriOptions, performanceFlagOptions, modifyCodeTypeOptions, bugSourceOptions, issuewayOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const [custIdOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes
    return [
      selectOption(d1, ['projectName', 'projectId', 'projectName,projectId'])
    ]
  }, [optionsRes])

  const changeForm = useCallback((newObj) => {
    setEditFormData(newObj)
  }, [setEditFormData])

  useEffect(() => {
    if (_.isNil(editFormData?.bugType) && _.isEmpty(bugTypeOptions)) return
    setBugType(convertOptions(editFormData?.bugType, bugTypeOptions))
  }, [setBugType, editFormData, bugTypeOptions])
  // bugTypeOptions
  return (
    <div className={'tracking-detail-right-panel flex-y'}>
      <Form value={editFormData} onChange={changeForm} userActive={false} error={editFormError} onError={setEditFormError}>
        {/* 基本信息 */}
        <div className="area-wrap base-info-wrap">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
            基本信息
          </div>
          <div className="area-content-wrap" style={{ height: baseInfoExpand ? '' : 0 }}>
            <HFormInput label={'类型'} bind={'bugType'} required component={isDetail ? Display : Select}
              options={bugTypeOptions} convert={v => convertOptions(v, bugTypeOptions)} />
            <HFormInput label={'优先级'} bind={'tracerPri'} required component={isDetail ? Display : Select}
              options={tracerPriOptions} convert={v => convertOptions(v, tracerPriOptions)} />
            <Tooltip title={'特：严重BUG，必须尽早提交现场'} overlayStyle={{ whiteSpace: 'pre-wrap' }}>
              <span>
                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
              </span>
            </Tooltip>
            <HFormInput label={'产品'} bind={'productId'} required search tree component={isDetail ? Display : Select}
              options={productTreeOptions} convert={v => convertOptions(v, productOptions)}
            />
            <HFormInput label={'子产品'} bind={'subSysId'} required search component={isDetail ? Display : Select}
              options={moduleOptions} convert={v => convertOptions(v, moduleOptions)} />
            <HFormInput label={'性能影响'} bind={'performanceFlag'} required search component={isDetail ? Display : Select}
              options={performanceFlagOptions} convert={v => convertOptions(v, performanceFlagOptions)} />
            <HFormInput label={'修改类型'} bind={'modifyCodeType'} required component={isDetail ? Display : Select}
              options={modifyCodeTypeOptions} convert={v => convertOptions(v, modifyCodeTypeOptions)} />

            <HFormInput label={'修改字典'} bind={'dictChange'} required component={isDetail ? Display : RadioGroup}
              options={radioOptions} convert={v => convertOptions(v, radioOptions)} />
            <HFormInput label={'给外围使用'} bind={'issueToExtend'} required component={isDetail ? Display : RadioGroup}
              options={radioOptions} convert={v => convertOptions(v, radioOptions)} componentClass={'long-options'} />
            <HFormInput label={'来源'} bind={'bugSource'} required component={isDetail ? Display : Select}
              options={bugSourceOptions} convert={v => convertOptions(v, bugSourceOptions)} />
          </div>
        </div>
        {/* 客户相关信息 */}
        <div className="area-wrap customer-information-info-wrap">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: customerInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCustomerInfoExpand(v => !v)} />
            客户相关信息
          </div>
          <div className="area-content-wrap" style={{ height: customerInfoExpand ? '' : 0 }}>
            <HFormInput label={'是否发布给客户'} bind={'issueToCustomer'} required component={isDetail ? Display : RadioGroup}
              bindInConvert={v => isNil(v) ? '0' : v}
              options={radioOptionsNum} convert={v => convertOptions(v, radioOptionsNum)} />
            <HFormInput label={'发布方式'} search bind={'issueway'} required component={isDetail ? Display : Select}
              options={issuewayOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, issuewayOptions)), '、')}>
                <div className={'ellipsis-line'}>
                  {_.join(_.map(v, x => convertOptions(x, issuewayOptions)), '、')}
                </div>
              </Tooltip>} />
            <HFormInput label={'需求来源'} multiple search bind={'projectId'} required component={isDetail ? Display : Select}
              options={custIdOptions} convert={v => {
                return <Tooltip title={_.join(_.map(v, x => convertOptions(x, custIdOptions)), '、')}>
                  <div className={'ellipsis-line'}>
                    {_.join(_.map(v, x => convertOptions(x, custIdOptions)), '、')}
                  </div>
                </Tooltip>
              }} />
            <HFormInput label={'发布客户'} multiple search bind={'securityName'} required component={isDetail ? Display : Select}
              options={custIdOptions} convert={v => <Tooltip title={_.join(_.map(v, x => convertOptions(x, custIdOptions)), '、')}>
                <div className={'ellipsis-line'}>
                  {_.join(_.map(v, x => convertOptions(x, custIdOptions)), '、')}
                </div>
              </Tooltip>} />
            <HFormInput label={'客户问题编号'} bind={'clientIssueNo'} component={isDetail ? Display : Input}
            />
            <HFormInput label={'客户部门'} bind={'custDepartment'} component={isDetail ? Display : Input}
            />
            <HFormInput label={'费用部门'} bind={'feeDepartment'} component={isDetail ? Display : Input}
            />
          </div>
        </div>
        {/* 创建信息 */}
        <div className="area-wrap create-info-wrap">
          <div className="area-header flex center-y">
            <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: createInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCreateInfoExpand(v => !v)} />
            创建信息
          </div>
          <div className="area-content-wrap" style={{ height: createInfoExpand ? '' : 0 }}>
            <HFormInput label={'创建人'} bind={'creatorUserId'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
            <HFormInput label={'创建时间'} bind={'createDate'} component={Display}
            // convert={v => {
            //   if (_.isNil(v)) return '-'
            //   if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
            //   return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
            // }}
            />
            {/* <HFormInput label={'最后修改人'} bind={'updateUser'} component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
            <HFormInput label={'最后修改时间'} bind={'lastUpdateTime'} component={Display}
              convert={v => {
                if (_.isNil(v)) return '-'
                if (_.isString(v)) return dateFormat('YYYY-MM-DD HH:MM:SS', toDate.str14ToDate(v))
                return dateFormat('YYYY-MM-DD HH:MM:SS', v) || '-'
              }}
            /> */}
          </div>
        </div>
      </Form>
    </div>
  )
}

import React, {useEffect, useMemo, useState} from 'react';
import {Card, DataGrid, Messager} from 'rootnet-ui'
import {pathSearchFor} from "../../../utils/publicFun";
import '../common/SystemMgtDetailCommon.scss'
import DescItem from "../common/DescItem";
import OperationList from "../../../project_share/components/OperationList";
import {Box} from "../../common/commonComponent";
import {TextIconBtn} from "../../../project_share/components";
import _ from 'lodash'
import {Display, Form, FormInput, Grid} from "rootnet-edit";
import useToDetail from "../../commonV2/hooks/useToDetail";
import {useGet} from "../../../utils/hook";
import SystemRelationDialog from "./SystemRelationDialog";
import {uniqKeyFor} from "../../../project_share/utils/utils";
import Remark from "../common/Remark";
import convertOptions from "../../common/ConvertOptions";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import DetailsGoBackWithTextClick from "../../common/DetailsGoBackWithTextClick";

const getColumns = (props) => {
    const {searchParams,toDetail,collocationOptions, productStatusOptions} = props
    return [
        {header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center'},
        {header: '产品', bind: 'productName',sortable: true,width: 150, tooltip: true},
        {header: '负责人', bind: 'productManager',sortable: true,width: 100},
        {header: '负责人备份', bind: 'productManagerBK',sortable: true,width: 100},
        {header: '配置方式', bind: 'collocation',sortable: true,width: 100, convert: v => convertOptions(v, collocationOptions)},
        {header: '产品状态', bind: 'productState',sortable: true,width: 100,convert: v => convertOptions(v, productStatusOptions)},
        {header: '包含模块', bind: 'modulars',sortable: true,width: 200, tooltip: true},
        {header: '操作', bind: '', align: 'center', width: 150, isTransfer: false, convert: renderOperation}
    ]

    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick: () => toDetail({...searchParams,productId: o.productId,productName: o.productName})
            },
        ];
        return <OperationList options={operateOption}/>;
    }
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    virtualized: true,
    resizable: true,
    fixedLeft: 2,
    fixedRight: 1,
    nilText: '-',
    emptyText: '-',
});

const optionUrls = [
    '/common/globalconst?globalConst=collocationType',
    '/common/globalconst?globalConst=state'
]

function SystemDetail(props) {
    const {history} = props
    const searchParams = pathSearchFor(history.location.search)
    const {systemId,systemName} = searchParams
    const [isCard, setIsCard] = useState(true)
    const {data: info, doFetch: getInfo} = useGet()
    const {data: sysGraph, doFetch: getSysGraph} = useGet()
    const [showRelation, setShowRelation] = useState(false)

    const {data: optionsRes} = useGet(optionUrls)

    const [collocationOptions, productStatusOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        return _.map(optionsRes, x => convertGlobalConstOptions(x))
    },[optionsRes])

    const goBack = useToDetail('/appSystemMgt')

    const goBackParams = useMemo(()=>{
        const backParams = _.omit(searchParams, ['systemId','systemName'])
        return _.mapKeys(backParams, (v ,k) => k.replace('item_',''))
    },[searchParams])

    useEffect(()=>{
        if(_.isNil(systemId)) return
        getSysGraph('/system/relation?systemId='+systemId)
    },[systemId,getSysGraph])

    useEffect(()=>{
        if (_.isNil(systemId)) return
        getInfo('/system/detail?systemId='+systemId)
    },[getInfo,systemId])

    const {systemDetailDoList: list = []} = useMemo(()=>info || {},[info])

    const toDetail = useToDetail('/productMgt/detail')

    const option = useMemo(()=>{
        return getTableOption(getColumns({searchParams,toDetail,collocationOptions, productStatusOptions}))
    },[searchParams,toDetail,collocationOptions, productStatusOptions])

    const descTitle = useMemo(()=>{
        return <div className='desc-left flex center-y'>
            <div className="desc-title">{_.get(info,'systemName')}</div>
        </div>
    },[info])

    const descExtra = useMemo(()=>{
        return <div className='desc-extra flex center-y'>
            <TextIconBtn text='关系图' icon='guanxitu02' onClick={()=>{
                if(_.isEmpty(sysGraph)) return Messager.show('暂无关系图数据',{icon:'info'})
                setShowRelation(true)
            }}/>
            <TextIconBtn text='编辑' icon='bianji'/>
        </div>
    },[sysGraph])

    const detailExtra = useMemo(()=>{
        return <TextIconBtn text={`切换${isCard?'表格':'卡片'}`} icon={isCard?'biaoge':'tubiao'} onClick={()=>setIsCard(x => !x)}/>
    },[isCard])

    const sortList = useMemo(()=>{
        return list.sort(sortCollocation)
    },[list])

    return <div className='system-detail system-mgt-detail-common flex-y fill'>
        <div className="back-area flex center-y">
            <DetailsGoBackWithTextClick onClick={()=>goBack(goBackParams)} />
            <div className="breadcrumb">
                {systemName}
            </div>
        </div>
        <Card title={descTitle} className='desc-card' extra={descExtra} contentClass='desc-content flex'>
            <DescItem header='负责人' text={_.get(info,'systemManager')}/>
            <DescItem header='系统说明' text={_.get(info,'directions')} showTips maxWidth={550}/>
        </Card>
        <Box title='产品构成' className='flex-y x-card-singlegrid' data={sortList} extra={detailExtra} style={{flex: isCard?0:1}}>
            {
                !isCard &&
                <DataGrid data={sortList} option={option}/>
            }
        </Box>
        {
            isCard && <div className='card-group flex'>
                {
                    _.map(sortList, (item, i) => {
                        return <CardItem key={i} itemData={item} toDetail={toDetail} searchParams={searchParams}>
                            <HFormInput bind='productManager' label='负责人'/>
                            <HFormInput bind='productManagerBK' label='负责人备份'/>
                            <HFormInput bind='collocation' label='配置方式' convert={v => convertOptions(v,collocationOptions)}/>
                            <HFormInput bind='productState' label='产品状态' convert={v => convertOptions(v,productStatusOptions)}/>
                        </CardItem>
                    }).concat(new Array(4).fill(0).map(() => (<div key={uniqKeyFor()} className='empty-item'/> )))
                }
            </div>
        }
        {
            showRelation && <SystemRelationDialog close={()=>{setShowRelation(false)}} data={sysGraph} systemName={systemName}/>
        }
    </div>
}

function sortCollocation(a,b){
    return a.collocation - b.collocation
}

const HFormInput = (props) => <FormInput horizontal component={Display} className='h-form-input' componentClass='h-form-input-component' labelWidth={90} componentWidth={100} {...props}/>

function CardItem(props){
    const {itemData, toDetail, searchParams, children} = props
    const tagList = []
    if(itemData.collocation === '01') tagList.push('基础')

    return <div className='card-item flex-y'>
        <div className="card-header flex center-y">
            <div className="card-title">{itemData.productName}</div>
            <div className="tags-group flex">
                {
                    _.map(tagList, (item,i) => {
                        return <div className='tag' key={i}>
                            {item}
                        </div>
                    })
                }
            </div>
        </div>
        <div className="card-detail">
            <Form value={itemData}>
                <Grid cols={2}>
                    {children}
                </Grid>
                <Remark label='包含模块' text={itemData.modulars} labelWidth={90}/>
            </Form>
        </div>
        <div className="bottom-text flex center" onClick={()=>{toDetail({...searchParams,productId: itemData.productId,productName: itemData.productName})}}>
            产品详情
        </div>
    </div>
}

export default SystemDetail;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from "lodash";
import {Popover, Tooltip} from "antd";
import {Messager, Button} from 'rootnet-ui'
import {Icon} from "../../../../../components";
import {FormInput} from "rootnet-edit";
import {copyText} from "rootnet-core/clipboard";
import './IssueDetailHeader.scss'
import convertOptions from "../../../../common/ConvertOptions";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import WorkFlowChangePop from "../../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import {strParams} from "../../../../../utils/publicFun";
import ConcernedIcon from '../../../../common/concernedIcon';

const ISSUE_CONFIRM_STATUS_COLOR = {
    '1': '#00A950',
    '2': '#008CEC',
    '5': '#868686',
}

// const ISSUE_STATUS_COLOR = {
//     '01': '#008CEC',
//     '02': '#F76A00',
//     '03': '#F76A00',
//     '08': '#F76A00',
//     '04': '#00A950',
//     '06': '#00A950',
//     '05': '#868686',
//     '07': '#868686',
//     '10': '#868686',
// }

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=IssueConfirmStatus',
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function getTagColor(value){
    switch(value){
        case 'PROD': return '#F33F2B'
        case 'UAT': return '#008CEC'
        case 'TESTONLY': return '#00A950'
        default: return '#FFFFFF'
    }
}

const FUNC_CODE = '19'

function IssueDetailHeader(props) {
    const {editFormData, isDetail, setEditFormData, allUserRes, id, refreshViewList, refreshDetail, updateDynamic, setIsClose} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [showCopyPop, setShowCopyPop] = useState(false)
    const {data: workFlowInfo, doFetch: getStatus} = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

    const status = useMemo(()=>{
        return _.get(workFlowInfo,'factorId')
    },[workFlowInfo])

    const refreshStatus = useCallback(()=>{
        if(_.isNil(id)) return
        const params = {
            businessType: 'issue',
            businessId: id
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
    },[getStatus, id])

    useEffect(()=>{
        refreshStatus()
    },[refreshStatus])

    const [issueConfirmStatusOptions, workFlowStatusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const workflowColor = useMemo(()=>{
        return _.get(_.find(workFlowStatusOptions, x => x.value === status),'color') || '#000'
    },[workFlowStatusOptions, status])

    const copy = useCallback((value)=>{
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', {icon: 'success'});
    },[])

    const CopyArea = useCallback(()=>{
        const detailUrl = window.location.host + `/#/issue?initId=` + _.get(editFormData,'id')
        return <div className={'flex issue-title-copy-popover center-y'}>
            <Button onClick={()=>copy(_.get(editFormData,'shortDesc'))} normal>复制标题</Button>
            <Button onClick={()=>copy(_.get(editFormData,'issueId'))} normal>复制ISSUE编号</Button>
            <Button onClick={()=>copy(`${_.get(editFormData, 'issueId')}\n${_.get(editFormData, 'shortDesc')}`)} normal>复制编号+标题</Button>
            <Button onClick={()=>copy(detailUrl)} normal>复制ISSUE的URL</Button>
            <Button onClick={()=>copy(`${_.get(editFormData, 'issueId')}\n${_.get(editFormData, 'shortDesc')}\n${detailUrl}`)} normal>复制编号+标题+URL</Button>
        </div>
    },[copy, editFormData])

    // const issueStatusChange = useCallback((value)=>{
    //     setEditFormData(oldObj => {
    //         let cloneObj = {...oldObj}
    //         cloneObj['issueStatus'] = value
    //         if(['08','03','04','05','06','10'].includes(value) &&  _.includes(_.get(cloneObj, 'issueKind'),'13')){
    //             setActiveKey('analysis')
    //             cloneObj['issueKind'] = null
    //         }
    //         return cloneObj
    //     })
    // },[setEditFormData, setActiveKey])

    useEffect(()=>{
        setIsClose(convertOptions(status, workFlowStatusOptions) === '关闭')
    },[setIsClose, status, workFlowStatusOptions])

    // useEffect(()=>{
    //     setIsAddStatus(status === '172096195559165957')
    // },[status, setIsAddStatus])

    return <div className={'issue-detail-header flex'}>
        <div className="header-left">
            <div className="req-id-wrap flex center-y">
                <div className="type-tag flex center-y" style={{background: getTagColor(_.get(editFormData,'issuesType'))}}>{_.get(editFormData,'issuesType')}</div>
                <div className="req-id">{_.get(editFormData,'issueId')}</div>
                <ConcernedIcon
                    type='ISSUE'
                    mode='detail'
                    referenceId={editFormData?.id}
                />
            </div>
            {
                isDetail &&
                <div className="req-title-wrap flex center-y">
                    <div className="req-title">
                        <Tooltip title={_.get(editFormData,'shortDesc')}>
                            {_.get(editFormData,'shortDesc')}
                        </Tooltip>
                    </div>
                    <Popover content={<CopyArea/>} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                        <Icon name={'fuzhi'} className={'copy-icon'}/>
                    </Popover>
                </div>
            }
            {
                !isDetail && <FormInput horizontal bind={'shortDesc'} label={'标题'} required value={_.get(editFormData, 'shortDesc')}
                                        onChange={v => setEditFormData(x => ({...x, shortDesc: v}))} maxLength={250}/>
            }
        </div>
        <div className="header-right flex center-y">
            <div className="issue-confirm-status-wrap" style={{
                borderColor: ISSUE_CONFIRM_STATUS_COLOR[_.get(editFormData,'issueConfirmStatus')],
                visibility: ['1','2','5'].includes(_.get(editFormData,'issueConfirmStatus'))?'unset':'hidden'
            }}>
                <div className="issue-confirm-status" style={{
                    borderColor: ISSUE_CONFIRM_STATUS_COLOR[_.get(editFormData,'issueConfirmStatus')],
                    color:ISSUE_CONFIRM_STATUS_COLOR[_.get(editFormData,'issueConfirmStatus')]
                }}>
                    {convertOptions(_.get(editFormData,'issueConfirmStatus'),issueConfirmStatusOptions)}
                </div>
            </div>
            <div className="header-right-item flex center-y">
                <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{color: '#165DFF'}}/>
                <div className="header-right-info flex-y">
                    {
                        isDetail &&
                        <div className="header-right-value">
                            <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes,'userName','userAccount') || '无'}</div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <FormInput horizontal required={status !== '172096195508834304'} value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                                   onChange={v => setEditFormData(x => ({...x, currentUser: v})) }  />
                    }
                    <div className="header-right-field">
                        处理人
                    </div>
                </div>
            </div>
            <Popover destroyTooltipOnHide={true}
                     content={<WorkFlowChangePop businessId={id} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                         refreshStatus()
                         refreshViewList()
                         refreshDetail(id)
                         updateDynamic()
                     }} funcCode={FUNC_CODE} businessType={'issue'}/>}
                     onOpenChange={visible => {
                         if(!visible){
                             setShowWorkFlowPop(null)
                         }
                     }}
                     trigger={'click'}
                     placement="bottomRight"
                     open={showWorkFlowPop}
            >
                <div className="header-right-item flex center-y status-wrap-item" onClick={()=>setShowWorkFlowPop(true)}>
                    <Icon name={'renling'} className={'header-right-icon'} style={{color: workflowColor}}/>
                    <div className="header-right-info flex-y">
                        <div className="header-right-value">
                            <div className={'status-tag flex center'} style={{color: workflowColor, border: `1px solid ${workflowColor}`,padding: '0 8px', borderRadius: 4}}>
                                {convertOptions(status, workFlowStatusOptions) || '-'}
                            </div>
                        </div>
                        <div className="header-right-field">
                            当前状态
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    </div>
}

export default IssueDetailHeader;
import React, {useCallback, useEffect, useMemo} from 'react';
import {Progress} from 'rootnet-ui'
import {Box} from "../../../common/commonComponent";
import {Select} from "rootnet-edit";
import {useGet} from "../../../../utils/hook";
import _ from "lodash";
import {strParams} from "../../../../utils/publicFun";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import './ViewDesc.scss'
import convertOptions from "../../../common/ConvertOptions";

const { str14ToDate } = toDate;
function ViewDesc(props) {
    const {pathParams,viewBeginDate,viewEndDate,list,viewId,setViewId,loading,error,setViewName} = props

    const handleHistoryParams = useMemo(()=>{
        let viewStatus = _.get(pathParams,'viewStatus')
        let viewType = _.get(pathParams,'viewType')
        viewStatus = viewStatus?_.get(pathParams,'viewStatus').split(','):null
        viewType = viewType?_.get(pathParams,'viewType').split(','):null
        return _.assign({},pathParams,{viewStatus,viewType})
    },[pathParams])
    const {data: viewOptionsRes, doFetch: getViewOptionsRes} = useGet()

    const viewOptions = useMemo(()=>{
        const list = viewOptionsRes || []
        return _.map(list, x => ({value: x.viewId, text: x.viewName}))
    },[viewOptionsRes])

    useEffect(()=>{
        if(_.isEmpty(viewOptions)) return
        setViewName(convertOptions(viewId,viewOptions))
    },[viewOptions,viewId,setViewName])

    useEffect(()=>{
        const params = _.omit(handleHistoryParams,['pageNum','pageSize','viewId'])
        getViewOptionsRes('/view/getViewName?'+strParams(params))
    },[getViewOptionsRes,handleHistoryParams])

    const ViewDescExtra = useCallback(()=>{
        return <>
            <Select options={viewOptions} value={viewId} onChange={setViewId}/>
        </>
    },[viewOptions,viewId,setViewId])

    const viewDesc = useMemo(()=>{
        if (_.isNil(list) || list.length === 0) return {}
        const sumTotalOverDay = _.reduce(list,(acc, x) => {acc+=_.toNumber(x.totalOverDay);return acc},0).toFixed(1)
        const sumTotalUseDay = _.reduce(list,(acc, x) => {acc+=_.toNumber(x.totalUseDay);return acc},0).toFixed(1)
        const workSchedule = (sumTotalOverDay/sumTotalUseDay).toFixed(2)*100
        const listLength = list.length
        const completeCount = _.filter(list, x => x.completeFlag === 'Y').length
        const completePercent = (completeCount/(listLength || 1)).toFixed(2)*100
        const sumDesignOverdue = _.filter(list, x => x.detailOverDue === '设计超期').length
        const sumDevOverdue = _.filter(list, x => x.detailOverDue === '开发超期').length
        const sumTestOverdue = _.filter(list, x => x.detailOverDue === '测试超期').length
        const sumOverdue = sumDesignOverdue + sumDevOverdue + sumTestOverdue

        const detailBeginTime = _.reduce(list, (acc, x)=>{
            if(!_.isNil(x.detailInputDate) &&_.toNumber(acc)>_.toNumber(x.detailInputDate)) acc = x.detailInputDate
            return acc
        },list[0].detailInputDate)

        const detailEndTime = _.reduce(list, (acc, x)=>{
            if(!_.isNil(x.testCompleteDate) && _.toNumber(acc)<_.toNumber(x.testCompleteDate)) acc = x.testCompleteDate
            if(!_.isNil(x.reqPuDate) && _.toNumber(acc)<_.toNumber(x.reqPuDate)) acc = x.reqPuDate
            return acc
        },list[0].testCompleteDate)

        const now = dateFormat('YYYYMMDDHHMMSS', new Date())
        let timeSchedule
        if((now - detailBeginTime)*(detailEndTime - detailBeginTime) === 0){
            timeSchedule = 0
        }else{
            timeSchedule = getTimeSchedule(now,detailBeginTime,detailEndTime)
        }
        if(_.toNumber(timeSchedule) > 100) timeSchedule = 100
        if(_.toNumber(timeSchedule) < 0) timeSchedule = 0

        function getTimeSchedule(now,detailBeginTime,detailEndTime){
            if(_.some([now,detailBeginTime,detailEndTime],_.isNil)){
                return 0
            }
            return (compareTime(detailBeginTime,now)/compareTime(detailBeginTime,detailEndTime)).toFixed(2)*100
        }

        function compareTime(beginTime, endTime){
            return getTime(endTime) - getTime(beginTime)
        }

        function getTime(date){
            return str14ToDate(date).getTime()
        }

        return {
            sumTotalOverDay: parseFloat(sumTotalOverDay),
            sumTotalUseDay: parseFloat(sumTotalUseDay),
            workSchedule:convertNum(workSchedule || 0),
            listLength,
            completeCount,
            completePercent:convertNum(completePercent),
            sumDesignOverdue,
            sumDevOverdue,
            sumTestOverdue,
            sumOverdue,
            timeSchedule: convertNum(timeSchedule || 0),
            detailBeginTime: dateFormat('YYYY-MM-DD', str14ToDate(detailBeginTime)),
            detailEndTime: dateFormat('YYYY-MM-DD', str14ToDate(detailEndTime)),
        }

        function convertNum(v){
            return parseInt(v+0.1)
        }
    },[list])

    return <Box title='视图详情' extra={<ViewDescExtra/>} data={list} className='view-desc' loading={loading} error={error} style={{marginTop: 0}}>
        <div className="view-desc-wrap flex">
            <div className="period">
                <div className="period-item">视图周期： {viewBeginDate} 至 {viewEndDate}</div>
                <div className="period-item">明细周期： {viewDesc.detailBeginTime} 至 {viewDesc.detailEndTime}</div>
            </div>
            <div className="schedule flex-y">
                <div className="schedule-item flex">
                    <div className='schedule-text'>时间进度</div>
                    <Progress percent={viewDesc.timeSchedule}/>
                    <div className="progress-info">{viewDesc.timeSchedule}%</div>
                </div>
                <div className="schedule-item flex">
                    <div className='schedule-text'>工作进度</div>
                    <Progress percent={viewDesc.workSchedule}/>
                    <div className="progress-info">{viewDesc.workSchedule}%</div>
                </div>
            </div>
            <div className="progress-wrap">
                <CircleProgressGroup percent={viewDesc.completePercent} progressText={`${viewDesc.completeCount}/${viewDesc.listLength}`} text='完成进度'/>
            </div>
            <div className="progress-wrap">
                <CircleProgressGroup percent={viewDesc.workSchedule} progressText={`${viewDesc.sumTotalOverDay}/${viewDesc.sumTotalUseDay}`} text='工作量'/>
            </div>
            <div className="progress-wrap overdue">
                <CircleProgressGroup percent={100} innerText={viewDesc.sumOverdue || '0'} text='超期总数'
                                     progressText={`设计${viewDesc.sumDesignOverdue}/开发${viewDesc.sumDevOverdue}/测试${viewDesc.sumTestOverdue}`} />
            </div>
        </div>
    </Box>
}

function CircleProgressGroup(props){
    const {percent, progressText, text, innerText} = props
    return <div className='circle-progress-group flex center'>
        <CircleProgress percent={percent} innerText={innerText}/>
        <div className="circle-progress-group-right flex-y center">
            <div className="progress">{progressText}</div>
            <div className="progress-text">{text}</div>
        </div>
    </div>
}

function CircleProgress(props){
    const {percent, innerText} = props
    const [leftRotate, rightRotate] = useMemo(()=>{
        if(percent < 50){
            return [225-(percent/50)*180, 225]
        }
        return [45, 225-(percent-50)/50*180]
    },[percent])
    return <div className="circle-progress-wrap">
        <div className="wrapper left">
            <div className="circle-progress left-circle"
                 style={{transform:`rotate(${leftRotate}deg)`}}/>
        </div>
        <div className="wrapper right">
            <div className="circle-progress right-circle"
                 style={{transform:`rotate(${rightRotate}deg)`}}/>
        </div>
        <div className='inner-text'>{innerText?innerText:`${percent}%`}</div>
    </div>
}

export default ViewDesc;
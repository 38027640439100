import React, { useState } from 'react'
import { Tabs } from 'antd'
import { Icon } from '../../../../../components'
import ResourceDeptSearch from '../../../resourceSchedule/resourceDeptSearch/ResourceDeptSearch'
import './index.scss'

const { TabPane } = Tabs

export default function DepartmentMenu(props) {
  const { setDeptId } = props
  const [isFold, setIsFold] = useState(false)
  const [activeTab, setActiveTab] = useState('department')
  return (
    <div className='left-department-menu flex' style={{ width: isFold ? 40 : 330 }}>
      <div className={'dept-search-wrap flex'} style={{ display: isFold ? 'none' : 'flex' }}>
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="部门" key="department">
            <ResourceDeptSearch changeDept={id => setDeptId(id === '' ? '1' : id)} />
          </TabPane>
        </Tabs>
      </div>
      <div className="fold-bar flex center" onClick={() => setIsFold(x => !x)} style={{ width: isFold ? 40 : 22 }}>
        <Icon className={'fold-icon'} name={'shuangjiantou-xiangyou'} style={{ transform: isFold ? 'none' : 'rotate(180deg)' }} />
      </div>
    </div>
  )
}

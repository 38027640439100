import React, { useContext } from 'react'
import cls from 'clsx'
import _ from 'lodash'
import { Button } from 'rootnet-ui'
import { Form, FormInput, Select } from 'rootnet-edit'
import { ValueContext } from '../../../common/Context'
import UserSelect from '../../../common/personSelectPop/UserSelect'
import CascadeSelect from '../../../common/cascadeSelect/CascadeSelect'
import clsx from "clsx";


const INIT_PARAMS = {
  id: null,
  title: null,
  priority: null,
  status: null,
  principle: null,
  statusResult: null,
  executor: null,
  pageNo: 1,
  relateShowIds: null,
  type: null,
  remark: null,
  principleEmpty: null,
  moduleIds: null,
}

const HFormInput = (props) => <FormInput componentWidth={180} labelWidth={80}  {...props} />

export default function PlanUseCasesOptin(props) {
  const { isSearch, actualResultOpt, expandAll, setCasePageNum, priorityOpt, statusOpt, typeOpt, testPhaseOpt, relateShowIdData, productOpt, moduleOpt } = props
  const { params, setParams, refresh } = useContext(ValueContext)
  return (
    <div className={cls('customize-options planUseCasesOptin', { isSearch })}>
      <div className='c-options'>
        <Form value={params} onChange={setParams}>
          <HFormInput label='ID' bind='id' />
          <HFormInput label='标题' bind='title' />
          <HFormInput label='关联issue/需求ID' bind='relateShowIds' component={Select} options={relateShowIdData} search clear multiple />
          <HFormInput label='优先级' bind='priority' component={Select} options={priorityOpt} search clear />
          <HFormInput label='用例状态' bind='status' component={Select} options={statusOpt} search clear />
          {
            !params?.principleEmpty &&
            <HFormInput label='用例负责人' bind='principle' component={UserSelect} search clear disabled={!_.isNil(params?.principleEmpty)} />
          }
          {
            params?.principleEmpty &&
            <HFormInput label='用例负责人' placeholder={'空值'} disabled={true} />
          }
          <div className={clsx('empty-btn flex center', { 'is-empty': !_.isNil(params?.principleEmpty) })}
            onClick={() => setParams(old => {
              return {
                ...old,
                principleEmpty: _.get(old, 'principleEmpty') ? null : 'Y'
              }
            })}>空</div>
          <HFormInput label='执行结果' bind='statusResult' component={Select} options={actualResultOpt} search clear />
          <HFormInput label='执行人' bind='executor' component={UserSelect} search clear />
          <HFormInput label='用例类型' bind='type' component={Select} search clear options={typeOpt} />
          <HFormInput label='用例阶段' bind='testPhase' component={Select} search clear options={testPhaseOpt} multiple
            bindInConvert={v => _.split(v, ',')} bindOutConvert={v => _.join(v, ',')} />
          <HFormInput label='评审人' bind='reviewerList' component={UserSelect} search clear multiple />
          <HFormInput label='备注' bind='remark' />
          <HFormInput label='所属产品' bind='productIds' component={CascadeSelect} search clear options={productOpt} multiple
          />
          <HFormInput label='所属模块' bind='moduleIds' component={Select} search clear options={moduleOpt} multiple
          />
        </Form>
        <div className="search-btn-group flex">
          <Button className={'search-btn'} primary onClick={() => {
            expandAll(!_.isEmpty(_.compact(_.values(params))))
            refresh({ ...params, pageNo: 1 })
            setCasePageNum(1)
          }}>查询</Button>
          <Button className={'reset-btn'} text onClick={() => {
            expandAll(false)
            setParams(INIT_PARAMS)
            refresh({ pageNo: 1 })
            setCasePageNum(1)
          }}>重置</Button>
        </div>
      </div>
    </div>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Dialog, DataGrid, Pagination, Button } from 'rootnet-ui'
import './RequirementSelectDialog.scss'
import {useGet} from "rootnet-biz/es/hooks";
import _ from "lodash";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import {strParams} from "../../../utils/publicFun";
import {Form, FormInput, Radio, Select} from "rootnet-edit";
import {Box} from "../../common/commonComponent";

function getColumns(props) {
    const { currentId } = props
    return [
        { width: 50,align: 'center', stretch:false ,convert: (v, o) => <TableRadio {...{ o, currentId }} /> },
        { header: '#',stretch:false, convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
        { header: 'ID', bind: 'interiorReqNo', width: 180, tooltip: true },
        { header: '标题', bind: 'title', width: 250, tooltip: true },
        { header: '状态', bind: 'ConstDisplayName', width: 120, tooltip: true },
        { header: '版本计划', bind: 'releaseId', width: 150, tooltip: true },
    ]

    function TableRadio(props) {
        const { o, currentId } = props
        return <Radio value={o.id === currentId} />
    }
}

function getOption(columns) {
    return {
        columns,
        selection: true,
        autoFill: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
    }
}

const getInitParams = () => {
    return ({
        pageNum: 1,
        pageSize: 20,
        id: null,
        status: null,
        branch: 0,
        idOrTitle: null,
    })
}

const optionsUrl = [
    '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C',
    '/common/globalconst?globalConst=reqstatus',
]

function RequirementSelectDialog(props) {
    const {setPid,setParentTitle, close, initPid, initTitle} = props
    const [params, setParams] = useState(getInitParams(initPid))
    const { data, doFetch, loading, error } = useGet()
    const [currentId, setCurrentId] = useState(initPid)
    const [title, setTitle] = useState(initTitle)
    const { data: optionsRes } = useGet(optionsUrl)

    const [versionOptions, statusOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2] = optionsRes
        return [
            convertGlobalConstOptions(d1),
            _.map(d2, x => ({ value: x.interiorId, text: `${x.interiorId} - ${x.displayName}` })),
        ]
    }, [optionsRes])

    const { pageNum, pageSize, total, rows: list } = useMemo(() => data || {}, [data])

    useEffect(() => {
        const searchParams = {
            type: 'Req',
            isFatherStory: 'Y',
            ...params
        }
        doFetch('/test_case/selectconditionsinformation?' + strParams(searchParams))
    }, [doFetch, params])

    const option = useMemo(() => {
        return getOption(getColumns({ currentId }))
    }, [currentId])

    const confirm = useCallback(() => {
        if(setPid){
            setPid(currentId)
        }
        if(setParentTitle){
            setParentTitle(title)
        }
        close()
    }, [setPid, close, currentId,setParentTitle, title])

    return <Dialog header={'选择需求'} className={'requirement-select-dialog flex-y'} cancel={close} confirm={confirm}>
        <Options initParams={params} search={setParams} {...{ versionOptions, statusOptions }} />
        <Box title='需求列表' className='req-list x-card-singlegrid flex-y' data={list} loading={loading} error={error}>
            <DataGrid option={option} data={list} onRowClick={o => {
                setCurrentId(o.id)
                setTitle(o.title)
            }} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
    </Dialog>
}

function Options(props) {
    const { initParams, search, versionOptions, statusOptions } = props
    const [params, setParams] = useState(initParams)

    return <div className='c-options'>
        <Form value={params} onChange={setParams}>
            <FormInput label='搜索ID或标题' bind='idOrTitle' clear />
            <FormInput label='版本ID' bind='versionId' component={Select} search clear options={versionOptions} />
            <FormInput label='状态' bind='status' component={Select} multiple search clear options={statusOptions} />
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={() => search({ ...params, pageNum: 1, pageSize: 20, })}>查询</Button>
        </div>
    </div>
}

export default RequirementSelectDialog;
import React, { useState, useEffect, useRef, useCallback } from 'react'
import _ from 'lodash'
import { Mentions } from 'antd'
import { Button, Messager } from 'rootnet-ui'
import { Icon } from '../../../../components'
import { usePost } from 'rootnet-biz/lib/hooks'
import './dailyReportCommentInfo.scss'

const ADD_COMMENTS_URL = '/PublicMark/maintain/add' // 添加评论

export default function DailyReportCommentInfo(props) {
  const { funcCode, replyInfo, setReplyInfo, referenceId, refresh, defaultParams } = props
  const [open, setOpen] = useState(false)
  const [commentText, setCommentText] = useState()
  const [submitLoading, setSubmitLoading] = useState(false)
  const mentionsRef = useRef()
  const { doFetch } = usePost()

  const getFocus = useCallback(() => {
    if (mentionsRef.current) mentionsRef.current.focus()
  }, [])

  const release = useCallback(() => {
    if (mentionsRef.current) mentionsRef.current.focus()
    if (_.isNil(commentText) || commentText === '') return Messager.show('评论内容不能为空')
    if (submitLoading) return
    setSubmitLoading(true)
    const { currentItem, userName, ...res } = defaultParams
    const commentVal = _.join(_.concat([`评论人：${userName}`,], currentItem, [`评论：${commentText}`], [`请查看！`]), '\n')
    const replyVal = _.join(_.concat([`回复人：${userName}`,], currentItem, [`回复：${commentText}`], [`请查看！`]), '\n')
    const description = _.isNil(replyInfo) ? commentVal : replyVal

    const postParams = _.assign({}, {
      funcCode,
      referenceId,
      title: '',
      message: commentText,
      description,
      pid: _.isNil(replyInfo) ? null : _.get(replyInfo, 'id'),
    }, res, !_.isNil(replyInfo) && { title: res.title.replace('评论', '回复'), optIds: [_.get(replyInfo, 'updateUser')] })
    doFetch(ADD_COMMENTS_URL, postParams).then(() => {
      setSubmitLoading(false)
      setOpen(false)
      setCommentText(null)
      setReplyInfo(null)
      refresh()
      if (mentionsRef.current) {
        mentionsRef.current.blur()
      }
      Messager.show('添加评论成功', { icon: 'success' })
    }).catch(err => {
      setSubmitLoading(false)
      Messager.show(err._message, { icon: 'error' })
    })
  }, [commentText, doFetch, funcCode, referenceId, refresh, replyInfo, setReplyInfo, submitLoading, defaultParams])

  useEffect(() => {
    const dialog = document.querySelector('.daily-report-comment-record')
    const vApp = document.querySelector('.v-app')
    const dom = dialog ? dialog : vApp
    if (dom) {
      dom.addEventListener('click', () => {
        setOpen(false)
      })
      return () => dom.removeEventListener('click', () => { })
    }
  }, [])

  useEffect(() => {
    const ref = document.querySelector('.mentions-wrap')
    if (ref) {
      ref.addEventListener('click', onMentionsClick)
      return () => ref.removeEventListener('click', onMentionsClick)
    }

    function onMentionsClick(e) {
      setOpen(true)
      e.cancelBubble = true
    }
  }, [])

  useEffect(() => {
    const ref = document.querySelector('.mentions-bottom-btn-release')
    if (ref) {
      ref.addEventListener('click', onBtnClick)
      return () => ref.removeEventListener('click', onBtnClick)
    }
    function onBtnClick(e) {
      release()
      e.cancelBubble = true
    }
  }, [getFocus, release])

  return (
    <div className='daily-report-comment-info'>
      {
        !_.isNil(replyInfo) &&
        <div className={'reply-info flex center-y'}>
          <div className="reply-info-content">{`回复 ${replyInfo.user} : ${replyInfo.message}`}</div>
          <Icon className={'reply-info-del-icon'} name={'shibai'} onClick={() => setReplyInfo(null)} />
        </div>
      }
      <div className={'mentions-wrap'}>
        <Mentions
          ref={mentionsRef}
          rows={open ? 4 : 1}
          placeholder="评论"
          value={commentText}
          onChange={setCommentText}
        />
      </div>
      <div className='mentions-bottom-btn' style={{ display: open ? 'block' : 'none' }}>
        <Button primary className={'mentions-bottom-btn-release'}>发布</Button>
      </div>
    </div>
  )
}

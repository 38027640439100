import React, {useCallback, useMemo, useState} from 'react';
import {Dialog, Messager} from 'rootnet-ui'
import './TrackingDelDialog.scss'
import usePost from "rootnet-biz/es/hooks/usePost";
import {FormInput, TextArea} from "rootnet-edit";
import cls from "clsx";
import _ from "lodash";

function TrackingDelDialog(props) {
    const {id, close, afterRefresh} = props
    const [memo, setMemo] = useState()
    const { doFetch: submitPost } = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)

    const canSubmit = useMemo(()=>{
        return _.size(_.trim(memo)) >= 5
    },[memo])

    const submit = useCallback(()=>{
        if(submitLoading) return
        setSubmitLoading(true)
        submitPost('/bug/tracer/delete',{snowId: id, memo}).then(()=>{
            Messager.show('删除成功', { icon: 'success' })
            setSubmitLoading(false)
            if(afterRefresh){
                afterRefresh()
            }
            close()
        }).catch(err => {
            Messager.show(err._message, {icon: 'error'})
            setSubmitLoading(false)
        })
    },[submitPost, close, id, memo, submitLoading, afterRefresh])

    return <Dialog className={'tracking-del-dialog'} header={'删除研发任务'} cancel={close} confirm={submit}
                   confirmButtonText={'删除'} confirmButtonDisabled={!canSubmit}>
        <FormInput
            required
            horizontal
            label='备注'
            value={memo}
            onChange={setMemo}
            component={TextArea}
            componentWidth={300}
            placeholder='请输入至少5个文本'
            className={cls('reason', { error: _.size(_.trim(memo)) < 5 })}
        />
    </Dialog>
}

export default TrackingDelDialog;
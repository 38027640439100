import _ from 'lodash'
import cls from 'clsx'
import React, { useState, useCallback, useEffect } from 'react'
import { isNil } from 'rootnet-core/format'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { Messager, Dialog } from 'rootnet-ui'
import { FormInput, TextArea } from 'rootnet-edit'
import { dateFormat } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../../../../../components'
import { strParams } from '../../../../../../../../utils/publicFun'
import './index.scss'

const EDIT_URL = '/releaseProducer/update'
const DETAIL_URL = '/releaseProducer/detail'

export default function VersionSelectLog(props) {
  const { currentId, isReview: id, close, refreshList } = props
  const [value, setValue] = useState()
  const [check, setCheck] = useState()
  const [formData, setFormData] = useState()
  const { doFetch: getDetail } = useGet()
  const { doFetch } = usePost()

  useEffect(() => {
    const { reviewResult, reviewInfo } = formData || {}
    setValue(reviewInfo)
    setCheck(reviewResult)
  }, [formData])

  const refreshDetail = useCallback((id) => {
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?${strParams({ releaseId: currentId, productType: id })}`)
      .then(res => {
        setFormData(res)
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [currentId, getDetail])

  const confirm = useCallback(() => {
    if (isNil(check)) return Messager.show('请选择复核状态', { icon: 'error' })
    if (_.size(value) <= 0) return Messager.show('复核内容', { icon: 'error' })
    doFetch(EDIT_URL, _.assign({}, formData, { reviewResult: check, reviewInfo: value }, !isNil(formData?.reviewTime) && { reviewTime: dateFormat('YYYY-MM-DD', new Date()) }))
      .then(() => {
        refreshList()
        close()
        Messager.show('复核成功', { icon: 'success' })
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [check, close, doFetch, formData, refreshList, value])

  useEffect(() => {
    if (_.isNil(id)) return
    refreshDetail(id)
  }, [id, refreshDetail])

  return <Dialog
    header='版本制作复核'
    cancel={close}
    confirm={confirm}
    className='version-select-log'
    confirmButtonText='保存'
  >
    <div className='select-log flex'>
      <div className={cls('agree', { checked: check === 'Y' })} onClick={() => setCheck('Y')}>通过<Icon name='chenggong' /></div>
      <div className={cls('reject', { checked: check === 'N' })} onClick={() => setCheck('N')}>不通过<Icon name='shibai' /></div>
    </div>
    <FormInput
      required
      horizontal
      label='复核内容'
      value={value}
      onChange={setValue}
      component={TextArea}
      labelWidth={80}
      componentWidth={360}
      counter={false}
      maxLength={99999}
      className={cls('reason', { error: _.size(value) <= 0 })}
    />
  </ Dialog >
}
import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import echarts from 'echarts'
import { FormInput, Form, Display } from 'rootnet-edit'
import { Loader } from 'rootnet-ui'
import { ValueContext } from '../../../common/Context'

const HFormInput = (props) => <FormInput horizontal component={Display} labelWidth={95}  {...props} />

export default function OverallSituation() {
  const { loading, overallSituationData: data } = useContext(ValueContext)
  const [params, setParams] = useState({})
  const echartsRef = useRef()
  const {
    fai = 0,
    rate = 0,
    skiped = 0,
    through = 0,
    throughed = 0,
    unenforced = 0,
    blockinged = 0,
    unableCounted = 0
  } = _.get(data, 'testPlanQueryVos[0].caseResultVoList') || {}

  useEffect(() => {
    if (data) {
      setParams(_.assign({}, _.pick(data, ['totalDemand', 'totalTrace', 'totalTestCase', 'totalIssue', 'totalIssues', 'totalDefect']), {
        rate,
        through,
      }))
    } else setParams({})
  }, [data, rate, through])

  useEffect(() => {
    if (loading) return
    const myChart = echarts.init(echartsRef.current)
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: true,
          },
          color: ["#50C398", "#EA5252", "#F3B433", "#898688", "#C8C5C7", '#000000'],
          data: [
            { value: subStr(throughed), name: '通过' },
            { value: subStr(fai), name: '失败' },
            { value: subStr(blockinged), name: '阻塞' },
            { value: subStr(unenforced), name: '未执行' },
            { value: subStr(skiped), name: '跳过' },
            { value: subStr(unableCounted), name: '无法执行' },
          ]
        }
      ]
    }
    option && myChart.setOption(option)

    function echartsResize() {
      myChart.resize()
    }
    window.addEventListener('resize', echartsResize, false)
    return () => {
      window.removeEventListener('resize', echartsResize)
    }

  }, [blockinged, data, fai, loading, skiped, throughed, unenforced, unableCounted])

  return (
    <div className='overallSituation' >
      {loading ? <Loader /> :
        <>
          <p>整体情况</p>
          <div >
            <div>
              <Form value={params}>
                <HFormInput label='需求总数' bind='totalDemand' />
                <HFormInput label='ISSUE总数' bind='totalIssues' />
                <HFormInput label='缺陷总数' bind='totalDefect' />
                <HFormInput label='研发任务总数' bind='totalTrace' />
                <HFormInput label='用例总数' bind='totalTestCase' />
                <HFormInput label='用例执行进度' bind='rate' />
                <HFormInput label='通过率' bind='through' />
                <HFormInput label='发现缺陷总数' bind='totalIssue' />
              </Form>
            </div>
            <div ref={echartsRef} id="main" style={{ width: 300, height: 220 }}></div>
          </div>
        </>}
    </div>
  )



  function subStr(str) {
    return _.get(_.split(str, '/'), '[0]', 0)
  }

}
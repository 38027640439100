import _ from 'lodash'
import { useCallback } from 'react'
import { usePost } from 'rootnet-biz/es/hooks'

const ADD_URL = '/demoTxt/addTemplateConfig'

export default function useContentTemplateNum() {
  const { doFetch } = usePost()

  return useCallback((params) => {
    if (_.isNil(params.id)) return
    const postParams = _.assign(
      {},
      _.pick(params, ['type', 'objectType']),
      { demoTxtId: params.id, },
    )
    return doFetch(ADD_URL, postParams)
  }, [doFetch])
}

import _ from 'lodash'
import api from '../base/task';
import gd from '../base/global';
import { _cache } from "./cache";
import { Exception } from '../base/error'
import axios from 'axios'
import Api from "../base/task";
import getExplorerInfo from "../views/common/getExplorerInfo";
import getOsInfo from "../views/common/getOsInfo";
import {pathSearchFor, strParams} from "./publicFun";

export function login(name, pwd, encrypted = false) {
    let data = new FormData();
    data.append('optid', name);
    data.append('optpwd', pwd);
    data.append('encrypted', encrypted);

    return api.post('/operator/login', data).then(res => {
        gd.User = res.data;
    });
}

export function loginCheck(props) {
    const {token, location} = props
    return new Promise((resolve, reject) => {
        axios({
            url: '/api/rms/common/user/userInfo',
            method: 'GET',
            headers: { uuid: token },
            data: {},
        })
            .then(res => {
                const { code, msg } = res.data || {}
                if (code !== 0 || _.isEmpty(_.get(res, 'data.data.data'))) {
                    const errMessage = _.isEmpty(_.get(res, 'data.data.data')) ? '未获取到当前用户基本信息' : msg
                    if(code === 2007){
                        ssoLogout({location})
                    }
                    reject(new Exception(Exception.SYSTEM, code, errMessage, res?.config?.url))
                }
                const basicDetail = _.get(res, 'data.data.data[0]')
                gd.User = _.assign({},
                    {
                        uuid: token,
                        operator_id: basicDetail.userAccount,
                        operator_name: basicDetail.userAccount,
                        operator_userName: basicDetail.userName,
                    }
                )
                loginLog()
                resolve(res)
            })
            .catch(err => {
                const { response } = err
                if (response) {
                    const { status, statusText, path } = response
                    reject(new Exception(Exception.SYSTEM, status, statusText, path))
                }
            })
    })
}

export function loginLog() {
    const explore = getExplorerInfo()
    Api.post(`/loginCount/add`, { record: {} })
    Api.post(`/loginCountLog/add`, {
        computer: getOsInfo(),
        explore: `${explore.type} ${explore.version}`,
        action: 'login',
    })
}

export function logout(props) {
    const {location} = props
    const user = gd.User;
    const optid = user.operator_id;
    const uuid = user.uuid;
    let data = new FormData();
    data.append('optid', optid);
    data.append('uuid', uuid);

    const explore = getExplorerInfo()
    Api.post(`/loginCountLog/add`, {
        computer: getOsInfo(),
        explore: `${explore.type} ${explore.version}`,
        action: 'logout',
    }).then(() => {
        api.post('/operator/logout', data).then(() => {
            sessionStorage.removeItem('waterMarkUrl')
            _cache.delete('allMenuList')
            _cache.delete('activeMenuList')
            localStorage.removeItem('left_menu_wide')
            localStorage.removeItem('left_menu_fixed')
            gd.User = null;
            console.log("登出成功。");
            ssoLogout({location})
            // window.location.reload()
        }).catch(err => {
            console.error(err.Message);
        });
    })
}

export function ssoLogout(props){
    const {location} = props
    axios({
        url: `/api/rms/getAppVersion/list`,
        method: 'GET',
    }).then((res) => {
        gd.User = null;
        const { wechat = '' } = pathSearchFor(_.get(location, 'search')) || {}
        const searchParams = {
            redirect_uri: window.location.origin,
            targetSystem: 'RIMS',
            return: location ? escape(location.pathname + location.search) : null,
            wechat: wechat
        }
        window.open(`http://${res.data.data.webSize}/#/login?`+strParams(searchParams),'_self');
    })
}

export function get_cookie(name) {
    const search = name + "="//查询检索的值
    let returnvalue = null;//返回值
    if (document.cookie.length > 0) {
        let sd = document.cookie.indexOf(search);
        if (sd !== -1) {
            sd += search.length;
            let end = document.cookie.indexOf(";", sd);
            if (end === -1)
                end = document.cookie.length;
            //unescape() 函数可对通过 escape() 编码的字符串进行解码。
            returnvalue = unescape(document.cookie.substring(sd, end))
        }
    }
    return returnvalue;
}

export function weChat(code) {
    if (code) return api.get(`/wecom/sso/login?code=${code}`)
}
import React, { useMemo, useContext } from 'react'
import _, { isNull } from 'lodash'
import { Button, Dialog, DataGrid } from 'rootnet-ui'
import { Box } from '../../../common/commonComponent'
import { TextIconBtn } from '../../../../project_share/components'
import { ScheduleManagementContext } from '../../../common/Context'

const FIELD_NAME = ['fieldVos', 'fieldDictVos', 'fieldTypeVos']

const gridOptsFor = (columns) => ({
  columns,
  autoFill: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
});

const appendColumns = [
  {
    header: '申请理由',
    bind: 'memo',
    width: 400,
  }
]

const errorReasonColumns = {
  header: "导入失败原因",
  bind: "failReason",
  convert: v => <span style={{ color: 'red' }}> {v}</span>,
  tooltip: true,
  width: 210,
}

export default function MetadataFiltering(props) {
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const { closeAll, data, defaultOptions, tabIndex, title } = props
  const defaultOption = _.cloneDeep(_.concat(defaultOptions.columns, appendColumns))

  const option = useMemo(() => {
    _.forEach(defaultOption, column => {
      if (column.bind === 'productIdName') column.bind = 'productId'
      column.csvConvert = (v, o) => exportText(v, o, column)
      if (_.has(column, 'convert') && column.bind === 'createTime') return
      column.convert = (v, o) => replaceField(v, o, column)
    })
    return gridOptsFor(_.filter(defaultOption, o => o.header !== '操作'))
    function replaceField(v, o, column) {
      const bind = _.get(column, 'bind')
      if (o[`${bind}Desc`] === '0') return <span style={{ color: 'red' }}>此字段为必填项</span>
      if (!isNull(o[`${bind}Desc`])) return <span style={{ color: 'red' }}> {v}</span>
      return v
    }
    function exportText(v, o, column) {
      const bind = _.get(column, 'bind')
      if (o[`${bind}Desc`] === '0') return '此字段为必填项'
      return v
    }
  }, [defaultOption])

  const errorOption = useMemo(() => {
    const { columns } = option
    const column = _.clone(columns)
    column.unshift(errorReasonColumns)
    return _.assign({}, option, { columns: column })
  }, [option])

  return (
    <Dialog
      cancel={closeAll}
      header={'执行结果'}
      footerVisible={false}
      className='MetadataFiltering'
    >
      <Box title='符合要求（这些数据已提交）' data={_.get(data, '[0]')}>
        <DataGrid option={option} data={_.get(data, '[0]')} />
      </Box>
      <Box
        title='不符合要求（这些数据不会被导入）'
        data={_.get(data, '[1]')}
        extra={<TextIconBtn text='导出' icon={'daochu'} onClick={download} />}
      >
        <DataGrid option={errorOption} data={_.get(data, '[1]')} />
      </Box>
      <div className='footer'>
        <Button text onClick={closeAll}>关闭</Button>
      </div>
    </Dialog>
  )

  function download() {
    const abnormalData = { whichPage: tabIndex, [FIELD_NAME[tabIndex]]: _.get(data, '[1]') }
    downloadPostProgress('/field/export', abnormalData, `${title}-异常数据`)
  }

}
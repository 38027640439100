const workflowInitPosition = {
    "cells": [
        {
            "position": {
                "x": 210,
                "y": 30
            },
            "size": {
                "width": 80,
                "height": 50
            },
            "attrs": {
                "text": {
                    "text": "开始"
                }
            },
            "visible": true,
            "shape": "custom-big-rect",
            "ports": {
                "groups": {
                    "top": {
                        "position": "top",
                        "attrs": {
                            "circle": {
                                "r": 4,
                                "magnet": true,
                                "stroke": "#5F95FF",
                                "strokeWidth": 1,
                                "fill": "#fff",
                                "style": {
                                    "visibility": "hidden"
                                }
                            }
                        }
                    },
                    "right": {
                        "position": "right",
                        "attrs": {
                            "circle": {
                                "r": 4,
                                "magnet": true,
                                "stroke": "#5F95FF",
                                "strokeWidth": 1,
                                "fill": "#fff",
                                "style": {
                                    "visibility": "hidden"
                                }
                            }
                        }
                    },
                    "bottom": {
                        "position": "bottom",
                        "attrs": {
                            "circle": {
                                "r": 4,
                                "magnet": true,
                                "stroke": "#5F95FF",
                                "strokeWidth": 1,
                                "fill": "#fff",
                                "style": {
                                    "visibility": "hidden"
                                }
                            }
                        }
                    },
                    "left": {
                        "position": "left",
                        "attrs": {
                            "circle": {
                                "r": 4,
                                "magnet": true,
                                "stroke": "#5F95FF",
                                "strokeWidth": 1,
                                "fill": "#fff",
                                "style": {
                                    "visibility": "hidden"
                                }
                            }
                        }
                    }
                },
                "items": [
                    {
                        "group": "top",
                        "id": "topLeft"
                    },
                    {
                        "group": "top",
                        "id": "top"
                    },
                    {
                        "group": "top",
                        "id": "topRight"
                    },
                    {
                        "group": "right",
                        "id": "rightTop"
                    },
                    {
                        "group": "right",
                        "id": "right"
                    },
                    {
                        "group": "right",
                        "id": "rightBottom"
                    },
                    {
                        "group": "bottom",
                        "id": "bottomLeft"
                    },
                    {
                        "group": "bottom",
                        "id": "bottom"
                    },
                    {
                        "group": "bottom",
                        "id": "bottomRight"
                    },
                    {
                        "group": "left",
                        "id": "leftTop"
                    },
                    {
                        "group": "left",
                        "id": "left"
                    },
                    {
                        "group": "left",
                        "id": "leftBottom"
                    }
                ]
            },
            "id": "1",
            "zIndex": 1
        }
    ]
}

export default workflowInitPosition
import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput, RadioGroup } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'

const EDIT_URL = '/ReleaseStory/update'

export default function RegressionMessage(props) {
  const { currentId, close, yseOrNoOpt, selectedRows, refresh } = props
  const [params, setParams] = useState({ releaseid: currentId, regression: 'N' })
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch } = usePost()

  const handleConfirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const list = _.filter(selectedRows, o => ((o.type === 'REQ' && !_.isNil(o.inRelease)) || o.type === 'ISSUE' || o.type === 'DEFECT'))
    const postParams = _.map(list, o => _.assign({}, params, { storyid: o.id }))
    doFetch(EDIT_URL, postParams)
      .then(() => {
        setIsDisable(false)
        refresh()
        close()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, isDisable, close, refresh, selectedRows, params])

  return (
    <Dialog
      header='复制'
      confirm={handleConfirm}
      cancel={close}
    >
      <Form value={params} onChange={setParams}>
        <FormInput required horizontal label='回归' bind='regression' component={RadioGroup} options={yseOrNoOpt} />
      </Form>
    </Dialog>
  )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './ResourcePlanCustomize.scss'
import _ from "lodash";
import {Input, Select} from "rootnet-edit";
import {DataGrid, Button, Messager} from 'rootnet-ui'
import Icon from "../../../../../../components/Icon";
import {Tooltip} from "antd";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../../common/ConvertGlobalConstOptions";
import {strParams} from "../../../../../../utils/publicFun";

function getColumns(props){
    const {addPosition,changePosition,addTechLevel,changeTechLevel,positionOptions, techLevelOptions,changeInvest,
        changePersonNum,deleteModule,deletePosition,deleteTechLevel} = props

    return [
        {header: '涉及模块', bind: 'moduleName', width: 140, convert: (v, o) => {
                return _.get(o,'showModule') ? <div>
                    {v}
                    <Tooltip title={'删除该模块下所有内容'}>
                        <span onClick={()=>deleteModule(o.module)}>
                            <Icon className={'delete-icon'} name='shanchu'/>
                        </span>
                    </Tooltip>
                </div> : ''
            }},
        {header: '岗位', bind: 'position', width: 100, convert: (v,o,i,d) => {
                const positionsInModule = _.filter(d, x => _.get(x,'module') === o.module)
                const usedPosition = _.uniq(_.map(positionsInModule,'position'))
                const disabledOptions = _.map(positionOptions, x => ({
                    ...x,
                    _disabled: _.includes(usedPosition, x.value)
                }))
                const canAddPosition = _.every(usedPosition, x => !_.isNil(x)) && (_.size(usedPosition) !== _.size(positionOptions))
                return _.get(o,'showPosition')?<div className={'flex center-y'}>
                    <Select placeholder={'请选择岗位'} value={v} style={{width: 110}} options={disabledOptions}
                            onChange={v => changePosition(_.get(o,'module'),_.get(o,'position'),v)}/>
                    {
                        // _.get(o,'position') &&
                        <Tooltip title={'删除该岗位下所有内容'}>
                            <span onClick={()=>deletePosition(o)}>
                                <Icon className={'delete-icon'} name='shanchu'/>
                            </span>
                        </Tooltip>
                    }
                    {
                        _.get(o,'position') && canAddPosition &&
                        <Tooltip title={'添加岗位'}>
                            <span onClick={()=>addPosition(o.module)}>
                                <Icon className={'module-col-add'} name='tianjia'/>
                            </span>
                        </Tooltip>
                    }
                </div>:''
            }},
        {header: '职级', bind: 'techLevel', width: 100, convert: (v,o,i,d) => {
                const techLevelsInPosition = _.filter(d, x => _.get(x,'module') === o.module && _.get(x,'position') === o.position)
                const usedTechLevel = _.uniq(_.map(techLevelsInPosition,'techLevel'))
                const disabledOptions = _.map(techLevelOptions, x => ({
                    ...x,
                    _disabled: _.includes(usedTechLevel, x.value)
                }))
                const canAddTechLevel = _.every(usedTechLevel, x => !_.isNil(x)) && (_.size(usedTechLevel) !== _.size(techLevelOptions))
                return <div className={'flex center-y'}>
                    <Select placeholder={'请选择岗位'} value={v} style={{width: 110}} options={disabledOptions}
                            onChange={v => changeTechLevel(_.get(o,'module'),_.get(o,'position'),_.get(o,'techLevel'),v)}/>
                    {
                        // _.get(o,'position') && _.get(o,'techLevel') &&
                        <Tooltip title={'删除该职级'}>
                            <span onClick={()=>deleteTechLevel(o)}>
                                <Icon className={'delete-icon'} name='shanchu'/>
                            </span>
                        </Tooltip>
                    }
                    {
                        _.get(o,'position') && _.get(o,'techLevel') && canAddTechLevel &&
                        <Tooltip title={'添加同岗位下职级'}>
                            <span onClick={()=>addTechLevel(o.module,o.position)}>
                                <Icon className={'module-col-add'} name='tianjia'/>
                            </span>
                        </Tooltip>
                    }
                </div>
            }},
        {header: '需投入(人日)', bind: 'investment', width: 80, convert: (v,o) => {
                const inputDisabled = _.some([o.module, o.position, o.techLevel],_.isNil)
                return <Input value={v === 0 ? null : v} placeholder={'需投入'} type={"number"} disabled={inputDisabled} suffix={"人日"} digit={1} min={0}
                              onChange={v => changeInvest(_.get(o,'module'),_.get(o,'position'),_.get(o,'techLevel'),v)}/>
            }},
        {header: '可参与人员数量', bind: 'userNum', width: 100, convert: (v,o) => {
                const inputDisabled = _.some([o.module, o.position, o.techLevel],_.isNil)
                return <Input value={v === 0 ? null : v} placeholder={`最多${_.get(o,"maxUserNum") || 0}人参与`} type={"number"} disabled={inputDisabled}
                              digit={0} min={0} max={_.get(o,"maxUserNum") || 0}
                              onChange={v => changePersonNum(_.get(o,'module'),_.get(o,'position'),_.get(o,'techLevel'),v)}/>
        }}
    ]
}

function getOption(cols){
    return {
        autoFill: true,
        resizable: true,
        virtualized: true,
        columns: cols,
    }
}

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=userPost',
    '/common/globalconst?globalConst=userTechLevel',
]

function ResourcePlanCustomize(props) {
    const {moduleOptions, department, setPlanList, initPlanList} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [list, setList] = useState(initPlanList)
    const [addModuleValue, setAddModuleValue] = useState()
    const {doFetch: getModuleCalculate} = useGet()

    const addModuleList = useMemo(()=>{
        return _.uniq(_.map(list, 'module')) || []
    },[list])

    useEffect(()=>{
        setPlanList(list)
    },[list,setPlanList])

    const filterModuleList = useMemo(()=>{
        return _.map(moduleOptions, x => ({
            ...x,
            _disabled: _.includes(addModuleList, x.value)
        }))
    },[moduleOptions, addModuleList])

    const [positionOptions, techLevelOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const showList = useMemo(()=>{
        return _.sortBy(list,[sortModule, sortPosition])
        function sortModule(o){
            const moduleList = _.uniq(_.map(list,'module'))
            return _.findIndex(moduleList,x => x === _.get(o,'module'))
        }
        function sortPosition(o){
            const positionList = _.map(positionOptions,'value')
            const findIndex = _.findIndex(positionList,x => x === _.get(o,'position'))
            return findIndex === -1 ? 9999 : findIndex
        }
        // function sortTechLevel(o){
        //     const techLevelList = _.map(techLevelOptions,'value')
        //     const findIndex = _.findIndex(techLevelList,x => x === _.get(o,'techLevel'))
        //     return findIndex === -1 ? 9999 : findIndex
        // }
    },[list,positionOptions])

    const handleList = useMemo(()=>{
        let lastModule
        let lastPosition
        return _.map(showList, x => {
            if(_.get(x,'module') !== lastModule){
                lastModule = _.get(x,'module')
                lastPosition = _.get(x,'position')
                return {
                    showModule: true,
                    showPosition: true,
                    ...x,
                }
            }else if(_.get(x,'position') !== lastPosition){
                lastPosition = _.get(x,'position')
                return {
                    showPosition: true,
                    ...x,
                }
            }else{
                return x
            }
        })
    },[showList])

    const addPosition = useCallback((module)=>{
        setList(oldList => {
            const newObj = {
                module,
                moduleName: _.find(moduleOptions, x => x.value === module).text,
                position: null,
                techLevel: null,
                investment: null,
                userNum: null,
            }
            return _.concat(oldList, [newObj])
        })
    },[moduleOptions])

    const changePosition = useCallback((module, oldPosition, newPosition)=>{
        const cloneList = _.clone(list)
        let newItem = {}
        let newList = _.map(cloneList, x => {
            if(_.get(x,'module') === module && _.get(x,'position') === oldPosition){
                newItem = {
                    ...x,
                    position: newPosition
                }
                return newItem
            }else{
                return x
            }
        })
        if(!_.isNil(_.get(newItem, 'position')) && !_.isNil(_.get(newItem, 'techLevel'))){
            const params = {
                module,
                moduleName: _.find(moduleOptions, x => x.value === module).text,
                position: newPosition,
                techLevel : _.get(newItem, 'techLevel'),
                department: department
            }
            getModuleCalculate('/demandInput/getMaxUser?'+strParams(params)).then(maxUserNum => {
                newList = _.map(_.clone(newList), x => {
                    if(_.get(x,'module') === module && _.get(x,'position') === newPosition){
                        newItem = {
                            ...x,
                            maxUserNum:maxUserNum
                        }
                        return newItem
                    }else{
                        return x
                    }
                })
                setList(newList)
            })
        }else{
            setList(newList)
        }
    },[list,department,getModuleCalculate, moduleOptions])

    const addTechLevel = useCallback((module, position)=>{
        setList(oldList => {
            const newObj = {
                module,
                moduleName: _.find(moduleOptions, x => x.value === module).text,
                position,
                techLevel: null,
                investment: null,
                userNum: null,
            }
            return _.concat(oldList, [newObj])
        })
    },[moduleOptions])

    const changeTechLevel = useCallback((module, position, oldTechLevel, newTechLevel)=>{
        const cloneList = _.clone(list)
        let newItem = {}
        let newList = _.map(cloneList, x => {
            if(_.get(x,'module') === module && _.get(x,'position') === position && _.get(x,'techLevel') === oldTechLevel){
                newItem = {
                    ...x,
                    techLevel: newTechLevel
                }
                return newItem
            }else{
                return x
            }
        })
        if(!_.isNil(_.get(newItem, 'position')) && !_.isNil(_.get(newItem, 'techLevel'))){
            const params = {
                module,
                position,
                techLevel : newTechLevel,
                department: department
            }
            getModuleCalculate('/demandInput/getMaxUser?'+strParams(params)).then(maxUserNum => {
                newList = _.map(_.clone(newList), x => {
                    if(_.get(x,'module') === module && _.get(x,'position') === position && _.get(x,'techLevel') === newTechLevel){
                        newItem = {
                            ...x,
                            maxUserNum
                        }
                        return newItem
                    }else{
                        return x
                    }
                })
                setList(newList)
            })
        }else{
            setList(newList)
        }
    },[list, department,getModuleCalculate])

    const changeInvest = useCallback((module, position, techLevel, inputValue)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.map(cloneList, x => {
                if(_.get(x,'module') === module && _.get(x,'position') === position && _.get(x,'techLevel') === techLevel){
                    return ({
                        ...x,
                        investment: _.toNumber(inputValue)
                    })
                }else{
                    return x
                }
            })
        })
    },[])

    const changePersonNum = useCallback((module, position, techLevel, inputValue)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.map(cloneList, x => {
                if(_.get(x,'module') === module && _.get(x,'position') === position && _.get(x,'techLevel') === techLevel){
                    return ({
                        ...x,
                        userNum: _.toNumber(inputValue)
                    })
                }else{
                    return x
                }
            })
        })
    },[])

    const deleteModule = useCallback((module)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.filter(cloneList, x => x.module !== module)
        })
    },[])

    const deletePosition = useCallback((item)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.filter(cloneList, x => (x.module !== item.module) || (x.position !== item.position))
        })
    },[])

    const deleteTechLevel = useCallback((item)=>{
        setList(oldList => {
            const cloneList = _.clone(oldList)
            return _.filter(cloneList, x => (x.module !== item.module) || (x.position !== item.position) || (x.techLevel !== item.techLevel))
        })
    },[])

    const option = useMemo(()=>{
        return getOption(getColumns({addPosition,changePosition,addTechLevel,changeTechLevel,positionOptions, techLevelOptions,
            changeInvest,changePersonNum,deleteModule,deletePosition,deleteTechLevel}))
    },[addPosition,changePosition,addTechLevel,changeTechLevel,positionOptions, techLevelOptions,changeInvest,
        changePersonNum,deleteModule,deletePosition,deleteTechLevel])

    const addModule = useCallback(()=>{
        if(_.isNil(addModuleValue)) return Messager.show("请先选择要添加的模块")
        const newObj = {
            module: addModuleValue,
            moduleName: _.find(moduleOptions, x => x.value === addModuleValue).text,
            position: null,
            techLevel: null,
            investment: null,
            userNum: null,
        }
        const newList = _.concat(list, [newObj])
        setList(newList)
        setAddModuleValue(null)
    },[list, addModuleValue,moduleOptions])

    return <div className={'resource-plan-customize'}>
        <DataGrid data={handleList} option={option}/>
        <div className={'add-module-wrap flex center-y'}>
            <Select value={addModuleValue} onChange={setAddModuleValue} placeholder={'请选择添加的模块'} options={filterModuleList} search/>
            <Button primary style={{height: 32}} onClick={addModule}>添加模块</Button>
        </div>
    </div>
}

export default ResourcePlanCustomize;
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { hasData } from 'rootnet-core/system'
import { copyText } from 'rootnet-core/clipboard'
import convertTableAlign from '../../../../../common/view/convertTableAlign'
import findConvert from '../../../../../common/view/findConvert'
import useGetViewConfig from '../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../../common/view/hooks/useGetTreeList'
import ViewArea from '../../../../../common/view/ViewArea'
import { Box } from '../../../../../common/commonComponent'
import { useFuncCode } from '../../../../../common/commonMethod'
import IssueDetailDialog from '../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import './index.scss'
import { API1 } from "../../../../../../base/task";
import { TextIconBtn } from "../../../../../common/TextIconBtn";
import { transCsv } from '../../../../../common/download'

const ENTER_DETAIL_FIELD = 'ShortDesc'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem),
        csvConvert: (v, o) => convertCsv(v, o, fieldItem)
      }
    }
  }

  function convertCsv(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    return showValue || '-'
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <div className={'common-display-field work-flow-status-field'}
        style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4, cursor: 'auto' }}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentInfo({ mode: 'detail', id: o.id })
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }}>
      {showValue || '-'}
    </div>
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '250104'
const APPLY_PERMISSION = '1902' //申请权限
const QA_CHECK_PERMISSION = '1910' //QA检测功能权限

export default function CustomerIssue(props) {
  const { customerId } = props
  const [currentInfo, setCurrentInfo] = useState()
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [isFuncCodeApply, isFuncCodeQaCheck] = useFuncCode([APPLY_PERMISSION, QA_CHECK_PERMISSION])
  const [factor, setFactor] = useState()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    return ({ 'ProductionIssue.Customer': customerId, })
  }, [customerId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo } })

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    Messager.show('导出中...')
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const cutPlate = useCallback(() => {
    const text = transCsv('\t')(options.columns, dataGridList);
    if (isNil(text) || !hasData(dataGridList)) return Messager.show('查询结果数据为空，无法复制', { icon: 'warn' });
    copyText(text, { format: "text/plain" });
    Messager.show('数据已复制到剪切板', { icon: 'success' });
  }, [options, dataGridList])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {!boxLoading && <TextIconBtn icon='fuzhi' text='复制' onClick={cutPlate} />}
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList, cutPlate, boxLoading])

  return (
    <div className='customer-issue'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={'客户ISSUE'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'detail' &&
        <IssueDetailDialog
          close={() => setCurrentInfo(null)}
          refreshViewList={refreshList}
          {...{ isFuncCodeApply, isFuncCodeQaCheck, currentInfo }}
        />
      }
    </div>
  )
}

import React, { useContext, useMemo, useState, useEffect, useCallback, useReducer } from 'react'
import _ from 'lodash'
import { Tooltip, Checkbox, Popover } from 'antd'
import { isNil } from 'rootnet-core/format'
import { Dialog, Tab, TabItem, Messager, Button, MessageBox } from 'rootnet-ui'
import { FormInput, Select, Display, Form, Input, DatePicker } from 'rootnet-edit'
import { useApi } from '../../../../utils/hook'
import { ModeContext, FormContext } from '../../../common/Context'
import { Icon } from '../../../../components'
import AddAndEdit from './addAndEdit'
import ExecutiveHistory from './executiveHistory'
// import RelationDialog from './RelationDialog'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import { filterTreeList } from '../../components/getTreeList'
import { selectOption, useFuncCode } from '../../../common/commonMethod'
import Demand from './demand'
import Defect from './Defect'
import DefectUploadArea from '../../defect/controls/DefectUploadArea'
import ChangeRecord from '../../../common/ChangeRecord'
import './infoForm.scss'
import PopoverComment from '../../defect/controls/PopoverComment'
import useCommon from '../../../common/hooks/useCommon'
import AssociatedDefects from './AssociatedDefects'
import Disassociation from './Disassociation'
import UserSelect from '../../../common/personSelectPop/UserSelect'
import convertOptions from '../../../common/ConvertOptions'
import RequirementDetailDialog from '../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import DefectUpdateDialog from '../../defect/controls/DefectUpdateDialog'
import useGetViewConfig from '../../../common/view/hooks/useGetViewConfig'
import TestCaseIssue from './testCaseIssue'
import ViewQueryDialog from '../../../common/view/viewQueryDialog/ViewQueryDialog'
import IssueDetailDialog from '../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import TagsArea from "../../../tagsMgt/tagsArea/TagsArea";
import { TextIconBtn } from "../../../common/TextIconBtn";
import usePost from "rootnet-biz/es/hooks/usePost";
import useGet from "rootnet-biz/es/hooks/useGet";
import DevListDetailDialog from '../../../devListMgt/devListDetailDialog'
import CaseDefect from './caseDefect'
import { dateFormat } from 'rootnet-core/dateFormat'

const ADD_URL = '/test_case/add'
const ADD_DEMAND_URL = '/test_case/adddemand'
const EDIT_URL = '/test_case/update'
const GET_CASEID_URL = '/test_case/productGetOnlyId'
// const EDIT_SEARCH_URL = '/test_case/selecttestcasebyid'

const SELECT_URL = [
  '/common/globalconst?globalConst=CaseStatus', //用例状态
  '/common/globalconst?globalConst=CaseType', //用例类型
  '/common/globalconst?globalConst=CasePriority', //优先级
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
  '/common/globalconst?globalConst=testDefectOriginPhase',
  '/common/globalconst?globalConst=Positive_Or_Negative',
  '/common/globalconst?globalConst=automatic',
]

const PENETRATE_URL = [
  '/common/globalconst?globalConst=storyType', // 需求类型
]

const TREE_URL = '/testcasetable/list'
const EDIT_ECHO_URL = '/test_case/selecttestcasebyid' //编辑回显
const CORRELATION_ECHO_URL = '/test_case/selectassociateddemand'//需求回显 研发任务回显
const EXECUTIVE_HISTORY_URL = '/test_case/selectTestCasePerformHistory' //执行历史
const QUERY_FILE_URL = '/mapping/files/queryNew'
const DEFECT_URL = '/test_case/selectDefectInCase'//缺陷
const ASSOCIATED_DEFECT_URL = '/test_defect/updateRelevance' //关联缺陷

const TITLE_NAME = Object.freeze({
  add: '新建测试用例',
  edit: '编辑测试用例',
  detail: '测试用例详情',
})

const APPLY_PERMISSION = '1902' //申请权限
const QA_CHECK_PERMISSION = '1910' //QA检测功能权限

const HFormInput = (props) => <FormInput horizontal componentWidth={180} labelWidth={105}  {...props} />

export default function InfoForm() {
  const { modeAll, setModeAll, refresh, treeRefresh, queryData, libraryId, selectedTreeKeyArr, useCaseList, useCasesTree, setCurrentId, addDefaultParams, addDefaultReq, addDefaultIssue, addDefaultParameter } = useContext(ModeContext)
  const [params, setParams] = useState({ libraryId, tableId: selectedTreeKeyArr, productId: '' })
  const [error, setError] = useState()
  const [demandData, setDemandData] = useState([]) // 选中的关联需求
  const [issueData, setIssueData] = useState([]) // 选中的关联研发任务
  const [fileList, setFileList] = useState([])
  const [relation, setRelation] = useState(false)
  const [disassociation, setDisassociation] = useState(null)
  const { data } = useGet(SELECT_URL)
  const { data: allUserRes } = useGet('/common/userinfo')
  const { doFetch } = useApi()
  const header = useMemo(() => TITLE_NAME[modeAll?.mode], [modeAll])
  const isDetail = useMemo(() => modeAll?.mode === 'detail', [modeAll])
  const isAdd = useMemo(() => modeAll?.mode === 'add', [modeAll])
  const [continueAdd, setContinueAdd] = useState(true)
  const { data: maintenanceUserOptionsRes } = useGet('/common/userinfo?indexValidFlag=1')
  const [commentText, setCommentText] = useState()
  const [commentPopoverVisible, setCommentPopoverVisible] = useState(false)
  const [active, setActive] = useState(0)
  const [showDetail, setShowDetail] = useState()
  const [countIssue, upDateIssue] = useReducer((x) => x + 1, 0)//ISSUE
  const [countReq, upDateReq] = useReducer((x) => x + 1, 0)//REQ
  const [countDefect, upDateDefect] = useReducer((x) => x + 1, 0)//REQ
  const [count, upDateCount] = useReducer((x) => x + 1, 0)
  const addCommon = useCommon()
  const [reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, saveFileAuth] = useFuncCode(['1503', APPLY_PERMISSION, QA_CHECK_PERMISSION, '050166'])
  const { data: penetrateRes } = useGet(PENETRATE_URL)

  const { data: treeData, doFetch: treeFetch, loading: treeLoading } = useGet()
  const { data: allData, doFetch: allFetch, loading } = useGet()
  const { convertCollection: defectConvertCollection } = useGetViewConfig('0504', () => { })
  const [mode, setMode] = useState('detail')
  const { libraryId: libraryIds, productId } = useMemo(() => params || {}, [params])
  const { doFetch: placeOnFilePost } = usePost()
  const { doFetch: getDefaultMaintainer } = useGet()
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    if (isAdd && !_.isNil(addDefaultParams)) setParams(x => _.assign({}, x, addDefaultParams))
  }, [isAdd, addDefaultParams])

  useEffect(() => {
    if (!isAdd) return
    getDefaultMaintainer('/test_case/defaultMaintainer').then(defaultMaintainer => {
      setParams(x => _.assign({}, x, { maintenanceUser: defaultMaintainer }))
    })
  }, [getDefaultMaintainer, isAdd])

  const [showSaveCase, setShowSaveCase] = useState(false)

  const [storyTypeOptions] = useMemo(() => {
    if (_.isEmpty(penetrateRes)) return []
    const [d1] = penetrateRes
    return [
      convertGlobalConstOptions(d1)
    ]
  }, [penetrateRes])

  useEffect(() => {
    if (!_.isNil(libraryIds)) treeFetch(`${TREE_URL}?caseLibraryId=${libraryIds}`)
  }, [libraryIds, treeFetch])

  const treeList = useMemo(() => {
    if (treeData) return filterTreeList(treeData)
    return []
  }, [treeData])

  useEffect(() => {
    if (isAdd) setParams(x => _.assign({}, x, { status: '01', priority: '02', attribute: '03' }))
  }, [isAdd])

  const allRefresh = useCallback(() => {
    const caseId = modeAll.data.id
    const url = _.concat([
      `${CORRELATION_ECHO_URL}?caseId=${caseId}&type=Req`,
      `${CORRELATION_ECHO_URL}?caseId=${caseId}&type=Issue`,
      `${EXECUTIVE_HISTORY_URL}?caseId=${caseId}`,
      `${DEFECT_URL}?caseId=${caseId}`,

    ], isAdd ? [] : [`${EDIT_ECHO_URL}?caseId=${caseId}`])
    allFetch(url)
  }, [allFetch, modeAll, isAdd])

  useEffect(() => {
    if (!isAdd && modeAll) {
      const caseId = modeAll.data.id
      allRefresh()
      doFetch(`${QUERY_FILE_URL}?funcCode=0501&referenceId=${caseId}`).then(res => setFileList(res))
    }
  }, [allRefresh, doFetch, isAdd, modeAll])

  useEffect(() => {
    if (allData) {
      !isAdd && setParams({ ..._.get(allData, '[4]'), addRelateReqBizId: null, addRelateIssueBizId: null })
      setDemandData(_.get(allData, '[0]'))
      setIssueData(_.get(allData, '[1]'))
    }
  }, [allData, isAdd])

  const maintainOpt = useMemo(() => {
    if (_.isEmpty(maintenanceUserOptionsRes)) return []
    return selectOption(maintenanceUserOptionsRes, ['userName', 'userAccount', 'userName,userAccount'])
  }, [maintenanceUserOptionsRes])

  const [statusOpt, typeOpt, priorityOpt, productOpt, moduleOptionsRes, attributeOpt, positiveNegativeOpt, automaticOpt] = useMemo(() => {
    if (!data) return []
    const [d1, d2, d3, d4, d5, d6, d7, d8, d9] = data
    const statusOpt = convertGlobalConstOptions(d1)
    const typeOpt = convertGlobalConstOptions(d2)
    const priorityOpt = convertGlobalConstOptions(d3)
    const productOpt = _.map(_.groupBy(d4, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d5, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    const attributeOpt = convertGlobalConstOptions(_.filter(d7, o => o.displayFlag !== 'N'))
    const positiveNegativeOpt = convertGlobalConstOptions(d8)
    const automaticOpt = convertGlobalConstOptions(d9)
    return [statusOpt, typeOpt, priorityOpt, productOpt, d6, attributeOpt, positiveNegativeOpt, automaticOpt]
  }, [data])

  const moduleOpt = useMemo(() => {
    if (_.get(data, '[5]') && productId) {
      const filterData = _.filter(_.get(data, '[5]'), item => productId === item.productId)
      return selectOption(filterData, ['subSysName', 'subSysId'])
    }
    return []
  }, [data, productId])

  const cardSwitch = useCallback((position) => {
    const index = _.findIndex(useCaseList, o => o.id === modeAll?.data.id)
    const isUp = position === 'up'
    let switchData;
    if (isUp && index > 0) {
      switchData = useCaseList[index - 1]
      setCurrentId(_.get(switchData, 'id'))
      return setModeAll(x => _.assign({}, x, { data: switchData }))
    } else if (!isUp && index + 1 !== _.size(useCaseList)) {
      switchData = useCaseList[index + 1]
      setCurrentId(_.get(switchData, 'id'))
      return setModeAll(x => _.assign({}, x, { data: switchData }))
    }
    return Messager.show(`已经是${isUp ? '第' : '最后'}一个了`, { icon: 'info' })
  }, [useCaseList, modeAll, setCurrentId, setModeAll])

  const index = useMemo(() => { if (!isAdd) { return _.findIndex(useCaseList, o => o.id === modeAll?.data.id) + 1 } }, [isAdd, modeAll, useCaseList])

  const submitAssociation = useCallback((type, data, flag = false) => {
    const caseId = modeAll?.data?.id
    if (_.isNil(caseId)) return
    if (type === 'demand') {
      const defaultDemand = _.get(allData, '[0]')
      const initId = _.map(defaultDemand, o => o.id)
      const newId = _.map(data, o => o.id)
      if (!scalarArrayEquals(initId, newId) || !_.isEmpty(checkDataArr(initId, newId, 'id')) || flag) { // 关联需求
        const parameter = _.assign({},
          {
            caseId,
            type: 'Req',
            relateIdListAdd: checkDataArr(newId, initId, 'id'),
            relateIdListDev: checkDataArr(initId, newId, 'id'),
          },
          flag && {
            relateIdListAdd: newId,
            relateIdListDev: []
          }
        )
        doFetch(ADD_DEMAND_URL, 'post', parameter)
          .then(() => {
            addCommon({ ids: checkDataArr(newId, initId, 'id'), type: '05' })
            !flag && allRefresh()
            upDateReq()
          })
          .catch(err => Messager.show(`关联需求 ${err._message}`, { icon: 'error' }))
      }
    }
    if (type === 'issue') {
      const defaultIssue = _.get(allData, '[1]')
      const initId = _.map(defaultIssue, o => o.id)
      const newId = _.map(data, o => o.id)
      if (!scalarArrayEquals(initId, newId) || !_.isEmpty(checkDataArr(initId, newId, 'id')) || flag) { // 关联研发任务
        const parameter = _.assign({},
          {
            caseId,
            type: 'Issue',
            relateIdListAdd: checkDataArr(newId, initId, 'id'),
            relateIdListDev: checkDataArr(initId, newId, 'id'),
          },
          flag && {
            relateIdListAdd: newId,
            relateIdListDev: []
          }
        )
        doFetch(ADD_DEMAND_URL, 'post', parameter)
          .then(() => {
            addCommon({ ids: parameter.relateIdListAdd, type: '06' })
            !flag && allRefresh()
            upDateIssue()
          })
          .catch(err => Messager.show(`关联ISSUE ${err._message}`, { icon: 'error' }))
      }
    }
    if (type === 'defect') {
      const defaultDefect = _.get(allData, '[3]')
      if (!scalarArrayEquals(defaultDefect, data) || !_.isEmpty(checkData(defaultDefect, data, 'defectId')) || flag) { // 关联缺陷
        const parameter = _.concat([],
          _.map(checkData(data, defaultDefect, 'id'), v => ({ caseId, id: v })),
          _.map(checkData(defaultDefect, data, 'id'), v => ({ caseId: null, id: v }))
        )
        doFetch(ASSOCIATED_DEFECT_URL, 'post', parameter)
          .then(() => { addCommon({ ids: checkData(data, defaultDefect, 'id'), type: '07' }); allRefresh() })
          .catch(err => Messager.show(`关联缺陷 ${err._message}`, { icon: 'error' }))
      }
    }
  }, [doFetch, modeAll, allRefresh, addCommon, allData])

  const associatedIssue = useCallback((items, flag = false) => {
    submitAssociation('issue', items, flag)
    setRelation(null)
  }, [submitAssociation])

  const associatedReq = useCallback((items, flag = false) => {
    submitAssociation('demand', items, flag)
    setRelation(null)
  }, [submitAssociation])

  useEffect(() => {
    const dialog = document.querySelector('.caseTesting')
    dialog.addEventListener('click', () => setCommentPopoverVisible(false))
  }, [])

  // 归档
  const saveCase = useCallback(() => {
    placeOnFilePost('/test_case/placeOnFile', [modeAll?.data?.id]).then(() => {
      Messager.show('已归档', { icon: 'success' })
      refresh()
      treeRefresh(queryData)
      allRefresh()
      setShowSaveCase(false)
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
      setShowSaveCase(false)
    })
  }, [placeOnFilePost, modeAll, refresh, treeRefresh, queryData, allRefresh])

  const isTimeEdit = useMemo(() => _.includes(['02', '03'], _.get(params, 'status')), [params])

  useEffect(() => {
    if (isAdd) doFetch(GET_CASEID_URL).then(res => setModeAll(x => _.assign({}, x, { data: { id: res } })))
  }, [doFetch, isAdd, setModeAll])

  useEffect(() => {
    if (isAdd && !_.isNil(addDefaultReq)) associatedReq(addDefaultReq)
  }, [isAdd, addDefaultReq, associatedReq])

  useEffect(() => {
    if (isAdd && !_.isNil(addDefaultIssue)) associatedIssue(addDefaultIssue)
  }, [isAdd, addDefaultIssue, associatedIssue])

  const contextValue = {
    loading,
    isAdd,
    isDetail,
    modeAll,
    params,
    setParams,
    error,
    setError,
    setRelation,
    caseId: modeAll?.data?.id,
    demandData: _.get(allData, '[0]'),
    issueData: _.get(allData, '[1]'),
    executiveData: _.get(allData, '[2]'),
    defectData: _.get(allData, '[3]'),
    submitAssociation,
    setDisassociation,
    setShowDetail
  }

  return (
    <FormContext.Provider value={contextValue}>
      <Dialog
        header={header}
        confirm={confirm}
        headerVisible={isAdd}
        style={{ padding: '0 16px' }}
        cancel={() => setModeAll(false)}
        className='caseTesting'
        confirmButtonVisible={!isDetail}
        loading={(!isAdd && loading) || (!_.isNil(params?.libraryId) && treeLoading) || submitLoading}
        footerVisible={isAdd}
      >
        {
          !isAdd && <div className="mock-dialog-header flex">
            <div className="dialog-title">
              {header}
            </div>
            <div className="mock-right-header flex center-y">
              {
                params?.placeOnFile !== 'Y' && saveFileAuth &&
                <span style={{ marginRight: 8 }}>
                  <TextIconBtn icon={'duizhang'} className={`header-edit-text-icon`} text={'归档'} onClick={() => {
                    setShowSaveCase(true)
                  }} />
                </span>
              }
              <span style={{ marginRight: 8 }}>
                <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                  setModeAll(x => _.assign({}, x, { mode: isDetail ? 'edit' : 'detail' }))
                }} />
              </span>
              <div className={'close-area flex center'} onClick={() => setModeAll(false)}>
                <Icon name={'quxiao'} className={'close-icon'} />
              </div>
            </div>
          </div>
        }
        <div className={`testCase-content ${isDetail ? 'testCase-content-detail' : ''}`}>
          <div>
            <div className={'flex center-y space-between'}>
              <FormInput
                horizontal
                label='标题'
                labelWidth={50}
                required={!isDetail}
                value={params?.caseName}
                style={{ flex: 1 }}
                componentStyle={{ flex: 1 }}
                component={isDetail ? Display : Input}
                onChange={v => setParams(x => _.assign({}, x, { caseName: v }))}
              />
              {
                params?.placeOnFile === 'Y' &&
                <div className="save-save-status-wrap">
                  <div className="save-save-status">
                    已归档
                  </div>
                </div>
              }
            </div>
            {
              modeAll?.mode !== 'add' &&
              <TagsArea id={modeAll?.data?.id} funcCode={'0501'} />
            }
            {!isAdd &&
              <Tab active={active} onChange={setActive}>
                <TabItem header='详情'>
                  <AddAndEdit />
                </TabItem>
                <TabItem header='需求'>
                  <div key={countReq} style={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'hidden',
                  }}>
                    <Demand />
                  </div>
                </TabItem>
                <TabItem header='ISSUE'>
                  <div key={countIssue} style={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'hidden',
                  }}>
                    <TestCaseIssue />
                  </div>
                </TabItem>
                <TabItem header='验证缺陷'>
                  <div key={countDefect} style={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'hidden',
                  }}>
                    <CaseDefect id={modeAll?.data?.id} upDateDefect={upDateDefect} isShow={true} />
                  </div>
                </TabItem>
                <TabItem header='发现缺陷'>
                  <Defect />
                </TabItem>
                <TabItem header='动态'>
                  {
                    active === 4 && <ChangeRecord name='用例' commentReferenceId={_.get(modeAll, 'data.id')} title={_.get(_.get(allData, '[4]'), 'caseName')} funcCode={'0501'} referenceId={_.get(modeAll, 'data.id')} id={_.get(_.get(allData, '[4]'), 'caseId')} linkUrl={`/testCaseMgt?treeId=${libraryId}&initId=${_.get(modeAll, 'data.id')}`} />
                  }
                </TabItem>
                <TabItem header='执行历史'>
                  <ExecutiveHistory />
                </TabItem>
                <TabItem header='附件'>
                  <DefectUploadArea id={modeAll?.data?.id} funcCode={'0501'} {...{ allUserRes, fileList, setFileList, mode: modeAll?.mode }} />
                </TabItem>
              </Tab>
            }
            {
              isAdd && <Tab>
                <TabItem header='详情'>
                  <AddAndEdit />
                </TabItem>
                <TabItem header='附件'>
                  <DefectUploadArea id={modeAll?.data?.id} funcCode={'0501'} {...{ allUserRes, fileList, setFileList, mode: modeAll?.mode }} />
                </TabItem>
              </Tab>
            }
          </div>

          {/* 右侧 */}
          <div className='editConditionRight'>
            <div className='editCondition'>
              <Form value={params} onChange={handChange} error={error} onError={setError}>
                {
                  !isDetail && <>
                    <HFormInput required label='所属用例库' bind='libraryId' component={Select} options={useCasesTree} search tree />
                    <HFormInput label='用例目录' bind='tableId' component={Select} options={treeList} search tree clear />
                  </>
                }

                {
                  isDetail && <>
                    <HFormInput label='所属用例库' bind='libraryName' component={Display} />
                    <HFormInput label='用例目录' bind='tableName' component={Display} />
                  </>
                }

                {/*<HFormInput*/}
                {/*  label='关联需求'*/}
                {/*  placeholder='查找'*/}
                {/*  // disabled={isDetail}*/}
                {/*  className='searchIconInput'*/}
                {/*  onFocus={() => setRelation('demand')}*/}
                {/*  prefix={<Icon name='sousuo1' />}*/}
                {/*/>*/}
                {
                  isDetail &&
                  <div className="mock-form-item flex">
                    <div className="mock-label right">关联需求/自提单</div>
                    <div className="mock-form-content">
                      {
                        !_.isEmpty(demandData) ?
                          <ul className='relationList'>
                            {
                              _.map(demandData, (o, i) => {
                                return <Tooltip key={`${o.interiorReqNo}${i}`} title={o.title}>
                                  <li>
                                    <span
                                      className={'can-enter-text'}
                                      onClick={() => setShowDetail({ type: _.includes(o.interiorReqNo, 'DEV') ? 'dev' : 'req', id: o.id })}
                                    >
                                      {o.title}
                                    </span>
                                  </li>
                                </Tooltip>

                              })
                            }
                          </ul> : <div style={{ height: 8 }}></div>
                      }
                    </div>
                  </div>
                }
                {
                  !isDetail &&
                  <HFormInput
                    label='关联需求/自提单'
                    placeholder='查找'
                    bind={'addRelateReqBizId'}
                    className={'relate-form-input'}
                    disabled={isDetail}
                    suffix={<div className={'suffix-search-icon flex center cursor-pointer'} onClick={() => setRelation('demand')}>
                      <Icon name='sousuo1' className={'can-enter-text'} />
                    </div>}
                  />
                }
                {
                  (!_.isEmpty(demandData) && !isDetail) &&
                  <ul className='relationList'>
                    {
                      _.map(demandData, (o, i) => {
                        return <Tooltip key={`${o.interiorReqNo}${i}`} title={o.title}>
                          <li>
                            <span className={'can-enter-text'} onClick={() => setShowDetail({ type: _.includes(o.interiorReqNo, 'DEV') ? 'dev' : 'req', id: o.id })}>{o.title}</span>
                            <span>{<Icon name='baocuo' onClick={() => delDemand(o)} />}</span>
                          </li>
                        </Tooltip>

                      })
                    }
                  </ul>
                }

                {/*<HFormInput*/}
                {/*  label='关联ISSUE'*/}
                {/*  placeholder='查找'*/}
                {/*  // disabled={isDetail}*/}
                {/*  className='searchIconInput'*/}
                {/*  onFocus={() => setRelation('issue')}*/}
                {/*  prefix={<Icon name='sousuo1' />}*/}
                {/*/>*/}
                {
                  isDetail &&
                  <div className="mock-form-item flex">
                    <div className="mock-label right">关联ISSUE</div>
                    <div className="mock-form-content">
                      {
                        !_.isEmpty(issueData) ?
                          <ul className='relationList'>
                            {
                              _.map(issueData, o => {
                                return <Tooltip key={o.id} title={o.shortDesc}>
                                  <li key={o.id}>
                                    <span className={'can-enter-text'} onClick={() => setShowDetail({ type: 'issue', id: o.id })}>{o.shortDesc}</span>
                                  </li>
                                </Tooltip>
                              })
                            }
                          </ul> : <div style={{ height: 8 }}></div>
                      }
                    </div>
                  </div>
                }
                {
                  !isDetail &&
                  <HFormInput
                    label='关联ISSUE'
                    placeholder='查找'
                    bind={'addRelateIssueBizId'}
                    className={'relate-form-input'}
                    disabled={isDetail}
                    suffix={<div className={'suffix-search-icon flex center cursor-pointer'} onClick={() => setRelation('issue')}>
                      <Icon name='sousuo1' className={'can-enter-text'} />
                    </div>}
                  />
                }
                {
                  (!_.isEmpty(issueData) && !isDetail) &&
                  <ul className='relationList'>
                    {
                      _.map(issueData, o => {
                        return <Tooltip key={o.id} title={o.shortDesc}>
                          <li key={o.id}>
                            <span className={'can-enter-text'} onClick={() => setShowDetail({ type: 'issue', id: o.id })}>{o.shortDesc}</span>
                            <span>{<Icon name='baocuo' onClick={() => delIssue(o)} />}</span>
                          </li>
                        </Tooltip>
                      })
                    }
                  </ul>

                }

                {
                  !isDetail && <>
                    <HFormInput required label='用例状态' bind='status' options={statusOpt} component={Select} />
                    {
                      isAdd ? <>
                        <HFormInput label='评审人' bind='reviewer' component={UserSelect} options={maintainOpt} />
                      </> : <>
                        <HFormInput label='评审人' bind='reviewer' component={UserSelect} options={maintainOpt} />
                        <HFormInput
                          label='评审时间'
                          bind='reviewTime'
                          component={isTimeEdit ? DatePicker : Display} mode='datetime'
                          bindInConvert={v => {
                            if (isNil(v)) return null
                            if (!isTimeEdit) return v
                            return new Date(v)
                          }}
                          bindOutConvert={v => {
                            if (isNil(v)) return null
                            if (!isTimeEdit) return v
                            return dateFormat('YYYY-MM-DD HH:MM:SS', v)
                          }}
                        />
                      </>
                    }
                    <HFormInput required label='用例类型' bind='type' options={typeOpt} component={Select} />
                    <HFormInput required label='测试阶段' bind='testPhase' options={attributeOpt} component={Select} multiple bindInConvert={v => _.split(v, ',')} bindOutConvert={v => _.join(v, ',')} />
                    <HFormInput required label='优先级' bind='priority' options={priorityOpt} component={Select} />
                    <HFormInput required label='正反例' bind='positiveOrNegative' options={positiveNegativeOpt} component={Select} />
                    <HFormInput required label='自动化实现' bind='automatic' options={automaticOpt} component={Select} />
                    <HFormInput tree label='所属产品' bind='productId' component={Select} options={productOpt} search clear />
                    <HFormInput label='所属模块' bind='moduleId' component={Select} options={moduleOpt} search clear />
                    <HFormInput label='维护人' bind='maintenanceUser' component={UserSelect} options={maintainOpt} bindInConvert={v => _.isEmpty(_.trim(v)) ? null : v} search clear />
                    <HFormInput label='菜单' bind='menu' />
                  </>
                }
                {
                  isDetail && <>
                    <HFormInput label='用例状态' bind='statusName' component={Display} />
                    <HFormInput label='评审人' bind='reviewer' component={Display} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                    <HFormInput label='评审时间' bind='reviewTime' component={Display} />
                    <HFormInput label='用例类型' bind='typeName' component={Display} />
                    <HFormInput label='测试阶段' bind='testPhaseName' component={Display} />
                    <HFormInput label='优先级' bind='priorityName' component={Display} />
                    <HFormInput label='正反例' bind='positiveOrNegativeName' component={Display} />
                    <HFormInput label='自动化实现' bind='automaticName' component={Display} />
                    <HFormInput label='所属产品' bind='productName' component={Display} />
                    <HFormInput label='所属模块' bind='moduleName' component={Display} />
                    <HFormInput label='维护人' bind='maintenanceUserName' component={Display} />
                    <HFormInput label='菜单' bind='menu' component={Display} className='text-area-detail' />
                  </>
                }
                {
                  !isAdd && <>
                    <HFormInput label='创建人' bind='createUserName' component={Display} />
                    <HFormInput label='创建时间' bind='createTime' component={Display} />
                    <HFormInput label='更新人' bind='updateUserName' component={Display} />
                    <HFormInput label='更新时间' bind='updateTime' component={Display} />
                  </>
                }
              </Form>
            </div>
          </div>
        </div>
        {
          isAdd &&
          <div className={'continue-add-check'}>
            <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
          </div>
        }
        {
          !isAdd && <div className='testCase-footer'>
            <div></div>
            <div className='arrow-switch'>
              <div
                className={index === 1 ? 'disabled' : ''}
                onClick={() => cardSwitch('up')}
              >
                <Icon name='xiangqian' />&nbsp;上一个
              </div>
              <div
                className={index === _.size(useCaseList) ? 'disabled' : ''}
                onClick={() => cardSwitch('down')}
              >
                下一个&nbsp;<Icon name='xianghou' />
              </div>
              <div>
                {index}/{_.size(useCaseList)}
              </div>
              <Popover content={
                <PopoverComment
                  referenceId={_.get(modeAll, 'data.id')}
                  funcCode={'0501'}
                  {...{ allUserRes, commentText, setCommentText }}
                  title={params?.caseName}
                  linkUrl={''}
                  close={() => setCommentPopoverVisible(false)} />
              }
                trigger="click" placement="topRight" open={commentPopoverVisible}>
                <Tooltip title="评论信息">
                  <div className={'comment-wrap flex center'} onClick={() => setCommentPopoverVisible(x => !x)}>
                    <Icon name={'zidingyishezhi1'} className={'comment-icon'} />
                  </div>
                </Tooltip>
              </Popover>
            </div>
            <div>
              {!isDetail && <Button primary onClick={confirm}>确认</Button>}
              <Button text onClick={() => setModeAll(false)}>取消</Button>
            </div>
          </div>
        }
        {
          _.get(showDetail, 'type') === 'req' &&
          <RequirementDetailDialog
            close={() => setShowDetail(null)}
            delAuth={reqDelAuth}
            currentInfo={{ id: _.get(showDetail, 'id') }}
            showChildList={[]}
            storyTypeOptions={storyTypeOptions}
            refreshViewList={() => {
              refresh()
              allRefresh()
            }}
          />
        }
        {
          _.get(showDetail, 'type') === 'dev' &&
          <DevListDetailDialog
            close={() => setShowDetail(null)}
            delAuth={reqDelAuth}
            currentInfo={{ id: _.get(showDetail, 'id') }}
            showChildList={[]}
            storyTypeOptions={storyTypeOptions}
            refreshViewList={() => {
              refresh()
              allRefresh()
            }}
          />
        }
        {
          _.get(showDetail, 'type') === 'issue' &&
          <IssueDetailDialog
            close={() => setShowDetail(null)}
            showChildList={[]}
            currentInfo={{
              id: _.get(showDetail, 'id'),
              mode: 'detail',
            }}
            refreshViewList={() => {
              refresh()
              allRefresh()
              upDateIssue()
            }}
            {...{ isFuncCodeApply, isFuncCodeQaCheck }}
          />
        }
        {
          _.get(showDetail, 'type') === 'defect' &&
          <DefectUpdateDialog
            showChildList={[]}
            currentIndex={0}
            refreshList={() => {
              refresh()
              allRefresh()
            }}
            setCurrentId={() => { }}
            close={() => { setShowDetail(null); setMode('detail') }}
            currentId={_.get(showDetail, 'id')}
            {...{ productOptions: productOpt, moduleOptionsRes, convertCollection: defectConvertCollection, mode, setMode }}
          />
        }
        {/*
          _.includes(['demand'], relation) && <RelationDialog
            relation={relation}
            demandData={demandData}
            setRelation={setRelation}
          />
      */}
        {
          relation === 'demand' && <ViewQueryDialog
            bizName='需求'
            bizField={'story.storyId'}
            initValue={_.map(demandData, o => o.id)}
            initItemValue={demandData}
            multiple={true}
            close={() => setRelation(null)}
            funcCode={'0004'}
            outerSetItem={associatedReq}
            refreshViewList={() => {
              refresh()
              allRefresh()
              upDateReq()
            }}
          />
        }
        {
          relation === 'issue' && <ViewQueryDialog
            bizName='ISSUE'
            bizField='ProductionIssue.IssueId'
            initValue={_.map(issueData, o => o.id)}
            initItemValue={issueData}
            multiple={true}
            close={() => setRelation(null)}
            funcCode={'0005'}
            outerSetItem={associatedIssue}
            refreshViewList={() => {
              refresh()
              allRefresh()
              upDateIssue()
            }}
          />
        }
        {
          relation === 'defect' && <AssociatedDefects setRelation={setRelation} />
        }
        {
          !_.isNil(disassociation) && <Disassociation {...{ submitAssociation, setDisassociation, disassociation }} />
        }
        {
          showSaveCase &&
          <MessageBox header='提醒' className={'content-center-dialog'} confirm={saveCase} cancel={() => setShowSaveCase(false)}>
            请确认归档
          </MessageBox>
        }
      </Dialog>
    </FormContext.Provider >
  )

  function handChange(o, key) {
    if (key === 'libraryId') {
      if (o[key] !== libraryId) o['tableId'] = ''
    }
    if (key === 'status') {
      if (!_.includes(['02', '03'], o[key])) {
        o['reviewTime'] = null
        o['reviewTimeToNull'] = '1'
      } else {
        o['reviewTimeToNull'] = null
      }
    }
    setParams(o)
  }

  function delDemand(o) {
    const delData = _.filter(demandData, item => item.id !== o.id)
    setDisassociation({ type: 'demand', data: delData })
  }

  function delIssue(o) {
    const delData = _.filter(issueData, item => item.id !== o.id)
    setDisassociation({ type: 'issue', data: delData })
  }

  function check() {
    return _.every(_.values(error), isNil)
  }

  function confirm() {
    if (!check() || isNil(params?.caseName)) return Messager.show('请填写必填项', { icon: 'error' })
    if (submitLoading) return
    setSubmitLoading(true)
    let editParams = []
    const url = isAdd ? ADD_URL : EDIT_URL
    const action = isAdd ? 'ADD' : 'EDIT'
    const caseId = modeAll?.data?.id

    if (!isAdd) {
      const defaultList = _.get(allData, '[4]')
      editParams = _.compact(_.map(defaultList, (val, key) => { if (val !== params[key]) return key }))
    }

    const parameter = _.assign({}, { action },
      !_.isNil(addDefaultParameter) && addDefaultParameter,
      (params?.tableId !== '0' && params?.tableId !== '1') && { targetTableId: params?.tableId },
      (params?.tableId === '0' || params?.tableId === '1' || _.isNil(params?.tableId)) && { targetTableId: null },
      {
        testCaseList: [
          _.assign({},
            !isAdd && _.pick(params, editParams),
            { libraryId, id: params.id },
            !_.isNil(params?.libraryId) && { libraryId: params?.libraryId },
            isAdd && { id: caseId, ...params },
            {
              addRelateReqBizId: _.split(params?.addRelateReqBizId, ',') || [],
              addRelateIssueBizId: _.split(params?.addRelateIssueBizId, ',') || [],
            },
          )
        ]
      }
    )
    // submitAssociation(caseId)
    _.forEach(parameter.testCaseList, o => _.forEach(o, (v, k) => o[k] = _.isNil(v) ? '' : v))

    doFetch(url, 'post', parameter)
      .then(() => {
        setSubmitLoading(false)
        Messager.show(`${header}成功`, { icon: 'success' })
        if (count > 0 && isAdd) {
          if (!_.isEmpty(issueData)) associatedIssue(issueData, true)
          if (!_.isEmpty(demandData)) associatedReq(demandData, true)
          !continueAdd && allRefresh()
        }
        if (continueAdd && isAdd) {
          doFetch(GET_CASEID_URL).then(res => setModeAll(x => _.assign({}, x, { data: { id: res } })))
          setParams(old => _.assign({}, old, {
            caseName: null,
            objective: null,
            preconditions: null,
            dataPre: null,
            caseSteps: null,
            expectResult: null,
            addRelateReqBizId: null,
            addRelateIssueBizId: null,
          }))
          upDateCount()
        } else {
          setModeAll(x => _.assign({}, x, { mode: 'detail' }))
        }
        refresh()
        treeRefresh(queryData)
      })
      .catch((err) => {
        setSubmitLoading(false)
        // setModeAll(false)
        Messager.show(err._message, { icon: 'error' })
      })

  }

  function scalarArrayEquals(arr1, arr2) {
    return _.size(arr1) === _.size(arr2) && _.every(arr2, o => _.includes(arr1, o))
  }

  function checkData(arr1, arr2, id) {
    return _.compact(_.map(arr1, o => { if (!_.includes(arr2, o)) return o[id] }))
  }

  function checkDataArr(arr1, arr2) {
    return _.filter(arr1, v => !_.includes(arr2, v))
  }

}

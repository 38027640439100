import React, { useRef, useState, useEffect, useCallback, useContext, useMemo } from 'react'
import _ from 'lodash'
import { Tree } from 'antd'
import { Form, FormInput } from 'rootnet-edit'
import { isNil } from 'rootnet-core/format'
import { Dialog, Loader, Messager, Tooltip } from 'rootnet-ui'
import { useGet, useApi } from '../../../../utils/hook'
import { useSize, useTreeExpend } from '../../../commonV2/hooks'
import { Icon } from '../../../../components'
import { BubbleCard } from './bubbleCard'
import { ValueContext } from '../../../common/Context'
import { useFuncCode } from '../../../common/commonMethod'
import DelMessage from '../../../../components/DelMessage'
import TreeDrag from './treeDrag'

const DEL_URL = '/testcasetable/delete'
const ADD_URL = '/testcasetable/add'
const EDIT_URL = '/testcasetable/update'
const EDIT_SEARCH_URL = '/testcasetable/one'

const TITLE_NAME = Object.freeze({
  add: '创建一级目录',
  edit: '修改目录',
  addChild: '添加子目录',
})

function getTreeId(data, arr = []) {
  _.forEach(data, item => {
    arr.push(item.key)
    if (item.children) getTreeId(item.children, arr)
  })
  return _.join(arr, ',')
}

export default function LeftList(props) {
  const { id, setTreeId, setTreeList, resetDirectory, treeRefresh: refresh, treeDat: data, treeLoading: loading, selectedTreeKeyArr, setSelectedTreeKeyArr, htmlWidth, queryData, refreshLists } = props
  const [mode, setMode] = useState()
  const [treeData, setTreeData] = useState([])
  const [treeDrag, setTreeDrag] = useState(false)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedTreeObj, setSelectedTreeObj] = useState({})
  const [expandAll, setExpandAll] = useState()
  const wrapperDom = useRef()
  const treeContentDom = useRef()
  const wrapperSize = useSize(treeContentDom)
  const { expendIcon, expend, wrapperEmpyt } = useTreeExpend({ wrapperDom })
  const [isFuncCodeAdd] = useFuncCode(['050103'])
  //sumCount 所有的  //total 无规划
  const formatTree = useCallback((tree, dome = false, path = '') => {
    _.forEach(tree, item => {
      item.key = item.id
      item.text = item.name
      item.value = item.id
      item.fullPath = path + item.name
      if (dome) {
        item.title = <div className='tree-list-title' key={item.id + 1111}>
          <span key={item.id + 2222} className='tree-text-overflow'>
            {item.name}（{item.allCount}）
          </span>
          <span key={item.id + 3333} className='hover-suffix'>
            {isFuncCodeAdd && <BubbleCard />}
          </span>
        </div>
      }
      else item.title = item.name
      item.children && formatTree(item.children, dome, item.fullPath + '/')
    })
    return tree
  }, [isFuncCodeAdd])

  useEffect(() => {
    const sumCount = _.get(data, '[0]sumCount', '0')
    const total = _.get(data, '[0]total', '0')
    const defaultData = [
      {
        key: '0',
        title: <>
          <Tooltip title={expandAll ? '一键收起' : '一键展开'}>
            <Icon onClick={() => setExpandAll(x => !x)} name={expandAll ? 'biaogeshouqi' : 'biaogezhankai'} />
            <span style={{ marginLeft: 4 }}>所有的（{sumCount}）</span>
          </Tooltip>
        </>
      },
      { key: '1', title: `无分类（${total}）` }
    ]

    if (data) {
      const list = _.filter(data, o => !_.isNull(o.id))
      setTreeData([...defaultData, ..._.cloneDeep(formatTree(list, true))])
      setTreeList(_.cloneDeep(formatTree(list)))
    }
    else setTreeData(defaultData)

  }, [data, expandAll, formatTree, setTreeList])

  useEffect(() => {
    if (expandAll) setExpandedKeys(getKey(treeData)) //x => _.isEmpty(x) ? getKey(treeData) : x
    else setExpandedKeys([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll])

  useEffect(() => {
    if (id) {
      setSelectedTreeKeyArr()
      setTreeId()
    }
  }, [id, setSelectedTreeKeyArr, setTreeId])

  useEffect(() => {
    if (treeData && resetDirectory && _.isNil(selectedTreeKeyArr)) {
      setSelectedTreeKeyArr(_.get(treeData, '[0]key'))
    }
  }, [resetDirectory, selectedTreeKeyArr, setSelectedTreeKeyArr, treeData])

  const contentValue = {
    id,
    mode,
    queryData,
    treeDrag,
    setTreeDrag,
    refresh,
    setMode,
    selectedTreeObj,
    setSelectedTreeObj,
    refreshLists,
  }

  return (
    <ValueContext.Provider value={contentValue}>
      <div className='leftList flex-y'>
        {
          expend && <>
            <div className='title'>
              <span>目录</span>
              <span>
                <Tooltip title='调整目录位置'><Icon name='yidong' onClick={() => setTreeDrag(formatTree(_.cloneDeep(data)))} /></Tooltip>
                {isFuncCodeAdd && <Tooltip title='添加'><Icon name='tianjia' onClick={() => setMode({ modes: 'add' })} /></Tooltip>}
                <Tooltip title='收起'>{expendIcon}</Tooltip>
              </span>
            </div>
            <div ref={treeContentDom} style={{ width: htmlWidth }}>
              {
                loading && <Loader fill />
              }
              <Tree
                blockNode
                expandedKeys={expandedKeys}
                onExpand={v => setExpandedKeys(v)}
                treeData={treeData}
                selectedKeys={selectedTreeKeyArr}
                height={wrapperSize?.height - 16}
                switcherIcon={<Icon name='zhankaijiantoushang' className='transform-rotate' />}
                onSelect={(keys, item) => {
                  const { node, selected } = item
                  setSelectedTreeObj(node)
                  if (selected) {
                    setSelectedTreeKeyArr(keys)
                    setTreeId(getTreeId([node]))
                  }
                }}
              />

            </div>
          </>
        }

        {
          !expend && <>
            <div className='expendIconWrap'>
              <Tooltip title='展开'>{expendIcon}</Tooltip>
            </div>
            {wrapperEmpyt}
          </>
        }

      </div>

      {
        treeDrag && <TreeDrag />
      }

      {
        _.includes(['add', 'edit', 'addChild'], mode?.modes) && <BulletFrame />
      }

      {
        mode?.modes === 'delete' &&
        <DelMessage
          refresh={() => refresh(queryData, true)}
          close={() => setMode(null)}
          url={`${DEL_URL}?testCaseTableId=${mode?.id}`}
        >
          目录“{mode.name}”会被删除，该目录下的用例会被移动到“未规划”目录下。此操作不可撤销，是否确定？
        </DelMessage>
      }
    </ValueContext.Provider >
  )

}

// 目录添加修改
function BulletFrame() {
  const { mode, setMode, refresh, id, queryData, refreshLists } = useContext(ValueContext)
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch: editDoFetch, data, loading } = useGet()
  const { doFetch } = useApi()

  useEffect(() => {
    if (mode.modes === 'edit') {
      editDoFetch(`${EDIT_SEARCH_URL}?testCaseTableId=${mode.id}`)
    }
  }, [editDoFetch, mode.id, mode.modes])

  useEffect(() => {
    if (mode.modes === 'edit' && data) {
      setParams(_.pick(data, ['id', 'name']))
    }
  }, [data, mode.modes])

  const header = useMemo(() => (TITLE_NAME[mode.modes]), [mode])


  return <Dialog
    header={header}
    confirm={_.debounce(confirm, 300)}
    cancel={() => setMode(null)}
    loading={loading && mode.modes === 'edit'}
  >
    <Form value={params} onChange={setParams} error={error} onError={setError}>
      <FormInput required horizontal label='目录名称' bind='name' componentWidth={370} />
    </Form>
  </Dialog>

  function check() {
    return _.every(_.values(error), isNil)
  }

  function confirm() {
    if (!check()) return Messager.show('请填写用例库标题', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    const url = mode.modes === 'edit' ? EDIT_URL : ADD_URL
    const parameter = _.assign(params, { caseLibraryId: id },
      mode.modes === 'add' && { pid: 0 },
      mode.modes === 'addChild' && { pid: mode.pid },
      mode.modes === 'edit' && { id: mode.id }
    )
    doFetch(url, 'post', parameter)
      .then(() => {
        refresh(queryData, true)
        refreshLists()
        setMode(null)
        setIsDisable(false)
        Messager.show(`${header}成功`, { icon: 'success' })
      })
      .catch((err) => {
        setMode(null)
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }

}

function getKey(data, arr = []) {
  _.forEach(data, o => {
    if (!_.isEmpty(o.children)) {
      arr.push(o.key)
      getKey(o.children, arr)
    }
  })
  return arr
}

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Image } from 'antd'
import { Form, FormInput, Select, Display, TextArea } from 'rootnet-edit'
import { Icon } from '../../../../components'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import CascadeSelect from '../../../common/cascadeSelect/CascadeSelect'
import convertOptions from '../../../common/ConvertOptions'
import RichTextEditor from '../../../common/richTextEditor/TinyEditor'
import DisplaySelect from '../../../common/displaySelect/DisplaySelect'
import './index.scss'

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=releaseType',
  'common/globalconst?globalConst=BizProcess',
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSeverity',
  '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=testDefectSource',
]

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo',
  '/common/globalconst?globalConst=ProductLine',
  '/develop/product/subproduct/list',
  '/verQuery/queryCustOptions',
]

export default function DevListInfo(props) {
  const { editFormData, setEditFormData, isDetail, initFormData, descRef, infoError, setInfoError, customerOptionsRes, getProjectId, solutionRef } = props

  const [baseInfoExpand, setBaseInfoExpand] = useState(true)
  const [descExpand, setDescExpand] = useState(true)
  const [solutionExpand, setSolutionExpand] = useState(true)
  const [testSuggestExpand, setTestSuggestExpand] = useState(true)

  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const [isInited, setInited] = useState(false)
  const [imageVisible, setImageVisible] = useState(false)
  const [imageSrc, setImageSrc] = useState()

  useEffect(() => {
    if (isInited) return
    if (_.isEmpty(editFormData)) return
    getProjectId('/story/getbugProject?id=' + _.get(editFormData, 'custSystem')).then(res => { })
    setInited(true)
  }, [editFormData, getProjectId, isInited])

  useLayoutEffect(() => {
    if (_.isEmpty(initFormData)) return
    setTimeout(() => {
      if (descRef.current) {
        let initText = _.replace(_.get(initFormData, 'description'), /\n/g, '<br/>') || ''
        initText = initText.replace(/[^<>]+(?=<)/g, match => match.replace(/\s/g, '&nbsp;'));
        descRef.current.setContent(initText)
      }
      if (solutionRef.current) {
        let initText = _.replace(_.get(initFormData, 'solution'), /\n/g, '<br/>') || ''
        initText = initText.replace(/[^<>]+(?=<)/g, match => match.replace(/\s/g, '&nbsp;'));
        solutionRef.current.setContent(initText)
      }
    }, 500)
  }, [initFormData, descRef, solutionRef])

  const customerOptions = useMemo(() => {
    if (_.isEmpty(customerOptionsRes)) return []
    return _.map(customerOptionsRes, x => ({ text: x.customerName, value: x.customer }))
  }, [customerOptionsRes])

  const [releaseTypeOptions, bizProcessOptions, severityOptions, sourceOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const [productOptions, moduleOptionsRes, custSystemOptions, allProductOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3, d4] = optionsRes
    const productOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    return [
      productOptions,
      d3,
      _.map(d4, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}` })),
      _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.projectName}${x.projectId}` }))
    ]
  }, [optionsRes])

  const moduleOptions = useMemo(() => {
    if (_.isEmpty(moduleOptionsRes)) return []
    if (_.isNil(_.get(editFormData, 'productId'))) return []
    const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === editFormData.productId)
    return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId }))
  }, [editFormData, moduleOptionsRes])

  const changeForm = useCallback((newObj, bind) => {
    setEditFormData(oldObj => {
      let cloneObj = { ...oldObj, ...newObj }
      if (bind === 'custSystem') {
        cloneObj['salesProjectId'] = null
        getProjectId('/story/getbugProject?id=' + _.get(newObj, 'custSystem'))
      }
      if (bind === 'reqSort') {
        if (!_.includes(['SPC', 'NORMAL', 'FUNCTION'], cloneObj['reqSort'])) {
          cloneObj['salesprojectid'] = null
        }
        if (_.includes(['SPC', 'NORMAL'], cloneObj['reqSort']) && (cloneObj['leafProjectId'] !== initFormData['leafProjectId'])) {
          cloneObj['leafProjectId'] = null
        }
      }
      return cloneObj
    })
  }, [getProjectId, setEditFormData, initFormData])

  useEffect(() => {
    if (_.isEmpty(customerOptionsRes)) return
    const valueList = _.map(customerOptionsRes, 'customer')
    if (!_.isNil(editFormData) && !_.includes(valueList, _.get(editFormData, 'customer'))) {
      const customer = _.get(customerOptionsRes, '0.customer')
      changeForm({ customer })
    }
  }, [customerOptionsRes, editFormData, changeForm])

  const previewImage = useCallback(e => {
    if (e.target.tagName === 'IMG') {
      setImageSrc(e.target.src)
      setImageVisible(true)
    }
  }, [])

  const isCUS = useMemo(() => {
    return _.get(editFormData, 'type') === 'CUS'
  }, [editFormData])

  useEffect(() => {
    if (isCUS) {
      if (_.isNil(_.get(editFormData, 'reqLevel'))) {
        setInfoError(x => _.assign({}, x, { 'reqLevel': '必填项！' }))
      }
    } else {
      setInfoError(x => _.assign({}, x, { 'reqLevel': null }))
    }
  }, [isCUS, editFormData, setInfoError])

  return <div className={'dev-list-info-wrap'}>
    <div className="area-wrap base-info-wrap">
      <div className="area-header base-info-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
        基本信息
      </div>
      <div className="area-content-wrap" style={{ height: baseInfoExpand ? '' : 0 }}>
        <Form value={editFormData} onChange={changeForm} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
          <HFormInput search label={'严重程度'} bind={'severity'} component={isDetail ? Display : DisplaySelect} options={severityOptions} required convert={v => convertOptions(v, severityOptions)} />
          <HFormInput label={'所属产品'} bind={'productId'} search required component={isDetail ? Display : Select} tree options={productOptions} convert={v => convertOptions(v, allProductOptions)} />
          <HFormInput label={'子产品'} bind={'subSysId'} search required component={isDetail ? Display : Select}
            options={moduleOptions} convert={v => convertOptions(v, moduleOptions)} componentClass={'sub-sys-id-options'} />
          <HFormInput label={'发布方式'} bind={'releaseType'} component={isDetail ? Display : Select} options={releaseTypeOptions}
            convert={v => convertOptions(v, releaseTypeOptions)} />
          <HFormInput label={'客户系统'} bind={'custSystem'} search required component={isDetail ? Display : Select}
            options={custSystemOptions} convert={v => convertOptions(v, custSystemOptions)} componentClass={'customer-system-options'} />
          <HFormInput label={'提出客户'} bind={'customer'} search required component={isDetail ? Display : Select} options={customerOptions} convert={v => convertOptions(v, customerOptions)} />
          <HFormInput label={'业务流程环节'} bind={'bizProcess'} component={isDetail ? Display : CascadeSelect} options={bizProcessOptions}
            convert={v => convertOptions(v, bizProcessOptions)} />
          <HFormInput required search label={'根源'} bind={'source'} component={isDetail ? Display : CascadeSelect} options={sourceOptions || []} clear convert={v => convertOptions(v, sourceOptions)} />
        </Form>
      </div>
    </div>
    <div className="area-wrap desc-wrap flex-y">
      <div className="area-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
        描述
        {
          !isDetail &&
          <span style={{ color: 'red' }}>（必填）</span>
        }
      </div>
      <div className="rich-text-detail-wrap flex" style={{ display: isDetail && descExpand ? "block" : 'none' }}>
        <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'description') }} />
      </div>
      <div className="rich-text-area" style={{ display: !isDetail && descExpand ? "block" : 'none' }}>
        <RichTextEditor ref={descRef} height={400} />
      </div>
    </div>
    <div className="area-wrap desc-wrap">
      <div className="area-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: solutionExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setSolutionExpand(v => !v)} />
        解决方案
      </div>
      <div className="rich-text-detail-wrap flex" style={{ display: isDetail && solutionExpand ? "block" : 'none' }}>
        <div onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'solution') }} />
      </div>
      <div className="rich-text-area" style={{ display: !isDetail && solutionExpand ? "block" : 'none' }}>
        <RichTextEditor ref={solutionRef} height={400} />
      </div>
    </div>
    <div className="area-wrap desc-wrap">
      <div className="area-header flex center-y">
        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: testSuggestExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setTestSuggestExpand(v => !v)} />
        用户测试建议
      </div>
      <div className="rich-text-detail-wrap flex" style={{ display: isDetail && testSuggestExpand ? "block" : 'none' }}>
        {_.get(editFormData, 'testSuggest')}
      </div>
      <div className="rich-text-area" style={{ display: !isDetail && testSuggestExpand ? "block" : 'none' }}>
        <TextArea value={_.get(editFormData, 'testSuggest')} onChange={(value) => setEditFormData(x => ({ ...x, testSuggest: value }))}
          maxLength={1000} className={'test-suggest-textarea'} />
      </div>
    </div>
    <Image
      style={{ display: 'none' }}
      src={imageSrc}
      preview={{
        visible: imageVisible,
        src: imageSrc,
        onVisibleChange: visible => {
          setImageVisible(visible)
        }
      }}
    />
  </div>
}

import React from 'react';
import './AuthControl.scss'
import {Form, Select, FormInput} from "rootnet-edit";
import UserSelect from "../../../common/personSelectPop/UserSelect";
const HFormInput = props => <FormInput horizontal labelWidth={70} componentWidth={250} {...props}/>

function AuthControl(props) {
    const {formData, setFormData, personFieldOptions, userGroupOptions} = props

    return <div className={'work-flow-auth-control flex-y'}>
        <div className="tips-text">
            状态流转时，只有有权限的用户才允许做此流转，如果为空则默认所有人有权限
        </div>
        <Form value={formData} onChange={setFormData}>
            <HFormInput label={'用户组'} bind={'userGroup'} component={Select} options={userGroupOptions} multiple/>
            <HFormInput label={'人员字段'} bind={'peopleFieldSnowId'} component={Select} options={personFieldOptions} multiple/>
            <HFormInput label={'其他用户'} bind={'otherUser'} component={UserSelect} multiple/>
        </Form>
    </div>
}

export default AuthControl;
import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Dialog, DataGrid } from 'rootnet-ui'
import { FormInput, TextArea } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/es/hooks'
import './index.scss'

const URL = '/PublicMark/maintain/add'

function getOption() {
  return {
    autoFill: true,
    columns: [
      { header: '', bind: 'fieldName', align: 'right' },
      { header: '修改前', bind: 'beforeValue', width: 300, tooltip: true },
      { header: '修改后', bind: 'afterValue', width: 300, tooltip: true, style: { color: '#E15722' } },
    ]
  }
}

export default function ContrastLog(props) {
  const { close, submit, oldVal, newVal, params, statusOpt } = props
  const [editReason, setEditReason] = useState()
  const { doFetch } = usePost()

  const list = useMemo(() => {
    return [
      {
        fieldName: '状态',
        beforeValue: convertValue(statusOpt, oldVal),
        afterValue: convertValue(statusOpt, newVal),
      }
    ]
  }, [newVal, oldVal, statusOpt])

  const handClick = useCallback(() => {
    doFetch(URL, { optIds: [], pid: null, message: editReason, ...params }).then(() => {
      submit()
      close()
    })
  }, [close, doFetch, editReason, params, submit])

  const confirmButtonDisabled = useMemo(() => {
    if (newVal === '2') return false
    else if (_.isNil(editReason) || editReason === '') return true
    else return false
  }, [editReason, newVal])

  const option = useMemo(() => getOption(), [])
  return (
    <Dialog className={''} headerVisible={false} cancel={close} confirm={handClick}
      confirmButtonDisabled={confirmButtonDisabled}>
      <DataGrid option={option} data={list} />
      <FormInput label={'备注'} component={TextArea} value={editReason} onChange={setEditReason}
        componentWidth={720} required={!(newVal === '2')} />
    </Dialog>
  )
}

function convertValue(data, val) {
  const formItem = _.find(data, x => x.value === val)
  const convert = _.get(formItem, 'text', '-')
  return _.isNil(convert) ? '-' : convert
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, OptBtn} from "../../common/commonComponent";
import {useApi, useGet} from "../../../utils/hook";
import {DataGrid, Messager, Dialog} from 'rootnet-ui';
import {FormInput, Select, DatePicker, Form, TextArea} from "rootnet-edit";
import _ from 'lodash'
import { Dateformat,Secformat} from "rootnet-core/format";
import {strParams} from "../../../utils/publicFun";
import './WorkTimeApprove.scss'
import {TextIconBtn} from "../../common/TextIconBtn";
import {toDate,dateFormat} from 'rootnet-core/dateFormat'

const { dateStringToDate } = toDate;

const getColumns = () => {
    return [
        {selection: true},
        {header: '部门', bind: 'departmentName', sortable: true,width: 130, tooltip: true},
        {header: '用户', bind: 'userName', sortable: true, tooltip: true, width: 80},
        {header: '工作类型', bind: 'jobTypeName', sortable: true, tooltip: true},
        {header: '工作日期', bind: 'workDate', sortable: true, width: 80,tooltip: true, convert: v => Dateformat(v)},
        {header: '用时(h）', bind: 'auditUseTime', sortable: true, tooltip: true, align: 'center', width: 70, convert: v => (v/60).toFixed(1)},
        {header: '状态', bind: 'status', sortable: true, tooltip: true, align: 'center', width: 70, convert: convertStatus},
        {header: '工作内容', bind: 'content', sortable: true, tooltip: true,},
        {header: '客户系统', bind: 'custProjectName', sortable: true,width: 80,tooltip: true},
        {header: '版本', bind: 'releaseId', sortable: true, tooltip: true},
        {header: '确认人', bind: 'auditPersonName', sortable: true, tooltip: true, width: 80},
        {header: '确认时间', bind: 'auditTime', sortable: true, tooltip: true, convert: v => Secformat(v).slice(0, Secformat(v).length - 3)},
    ]
    function convertStatus(v){
        switch (v) {
            case 'reject':
                return <span style={{color: '#F5221B'}}>已拒绝</span>
            case 'submit':
                return <span style={{color: '#E15722'}}>待确认</span>
            case 'approve':
                return <span style={{color: '#33CC8A'}}>已确认</span>
            default: return '-'
        }
    }
}

const getTableOption = (columns) => ({
    columns,
    autoFill: true,
    virtualized: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
});

const initParams = {
    date: {...weekFirstEnd()},
    beginDate: dateFormat('YYYY-MM-DD',weekFirstEnd().begin),
    endDate: dateFormat('YYYY-MM-DD',weekFirstEnd().end),
    queryType: "approve",
    status: 'submit',
}

const urls = ['/common/userinfo','/common/globalconst?globalConst=WorkTimeStatus'];

function Options(props) {
    const { initParams, search } = props;
    const [ params, setParams ] = useState(initParams);
    const { data } = useGet(urls);
    const [userList, statusList] = useMemo(()=>{
        const [d1, d2] = data || [];
        return [
            _.map(d1,x => (_.assign({}, {value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))),
            _.map(d2,x => (_.assign({}, {value: x.interiorId, text: x.displayName})))
        ]
    },[data])

    return <div className='c-options'>
        <Form value={params} onChange={handleChange}>
            <FormInput label='开始日期' bind='beginDate' component={ DatePicker } bindInConvert={dateStringToDate} bindOutConvert={v => dateFormat('YYYY-MM-DD',v)}/>
            <FormInput label='结束日期' bind='endDate' component={ DatePicker } bindInConvert={dateStringToDate} bindOutConvert={v => dateFormat('YYYY-MM-DD',v)}/>
            <FormInput label='用户' search clear
                       component={ Select }
                       bind='userAccount'
                       options={userList}/>
            <FormInput label='状态' search clear
                       component={ Select }
                       bind='status'
                       options={statusList}/>
        </Form>
        <OptBtn onClick={ () => search({...params}) }/>
    </div>
    function handleChange(options, key) {
        if(key === 'beginDate' && options[key] > options['endDate']) return Messager.show('开始时间不能大于结束时间', { icon: 'error' })
        if(key === 'endDate' && options[key] < options['beginDate']) return Messager.show('结束时间不能小于开始时间', { icon: 'error' })
        setParams(options)
    }
}

function WorkTimeApprove(props) {
    const {data: listData, doFetch:getList, loading, error} = useGet()
    const {doFetch: commit} = useApi()
    const {data: globalParams} = useGet('/common/globalpara?paraId=WorkTimeDateline')
    const [ params, setParams ] = useState(initParams);
    const [ selectedList, setSelectedList ] = useState([]);
    const refresh = useCallback(() => getList(`/worktime/list?${strParams(_.omit(params,'date'))}`), [params,getList])
    const [ showDialog, setShowDialog ] = useState(false)
    const [inputVal, setInputVal] = useState()
    const [handleListData, setHandleListData] = useState([])

    useEffect(()=>{
        let tempList =_.map(listData,(v)=>{
            if(v.workDate.substring(0,8) <= _.get(globalParams,'paraValue')){
                return _.assign({},v,{_selectable:false})
            }else{
                return v
            }
        })
        setHandleListData(tempList)
    },[listData,globalParams])

    useEffect(() => {
        getList(`/worktime/list?${strParams(_.omit(params,'date'))}`)
    }, [params,getList]);

    const ExtraBtnGroup = () => {
        return <>
            <TextIconBtn icon='jieshou1' text='接受' onClick={() => extraClick('approve')}/>
            <TextIconBtn icon='jujue1' text='拒绝' onClick={()=> extraClick('reject')}/>
        </>
    }

    const option = useMemo(()=>{
        return getTableOption(getColumns())
    },[])

    return <>
        <div className="flex-y fill">
            <Options initParams={initParams} search={ setParams }/>
            <Box title='工时确认' className='flex-y x-card-singlegrid'
                 data={handleListData} loading={loading} error={error}
                 extra={<ExtraBtnGroup/>}>
                <DataGrid className='no_across_line' data={handleListData} option={option}
                          onSelectionChange={v => setSelectedList(v.map(x => x.id))}/>
            </Box>
            {showDialog && <Dialog className='work-time-approve-dialog' header='拒绝' cancel={()=>{setShowDialog(false)}}
                                   confirm={()=>approve(selectedList,'reject',inputVal)}>
                <FormInput label='拒绝意见' required horizontal component={TextArea} onChange={setInputVal} counter={false} maxLength={2000}/>
            </Dialog>}
        </div>
    </>;

    function extraClick(mode= 'approve') {
        if(selectedList.length === 0){
            Messager.show('请选择需要确认的工时记录',{icon: 'error'})
            return
        }
        if(mode ===  'approve'){
            approve()
        }else{
            setInputVal(null)
            setShowDialog(true)
        }
    }

    function approve(idList = selectedList,status = 'approve',rejectReason= '') {
        if(status === 'reject' && _.isNil(inputVal)) return Messager.show('请填写拒绝意见',{icon: 'error'})
        setShowDialog(false)
        let params = {idList,status,rejectReason}
        commit('/worktime/approve','post',params).then( res => {
            Messager.show("操作成功", { icon: "success" });
            setSelectedList([])
            refresh()
        }).catch(err => {
            Messager.show(err._message, { icon: "error" });
        })
    }
}

function weekFirstEnd(){
    const firstDay = new Date(Date.now()-(new Date().getDay()-1)*1000*60*60*24)
    const endDay = new Date(Date.now()-(new Date().getDay()-7)*1000*60*60*24)
    return {
        begin: firstDay,
        end: endDay
    }
}


export default WorkTimeApprove;
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
// import tinymce from 'tinymce'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/icons/default';
import 'tinymce/themes/silver/theme'
import './TinyEditor.scss'

import 'tinymce/plugins/image'
import 'tinymce/plugins/paste'
// import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/link'
import 'tinymce/plugins/fullscreen'
// import 'tinymce/plugins/code'
// import 'tinymce/plugins/codesample'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/table'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/searchreplace'

import { Editor } from '@tinymce/tinymce-react';

import gd from "../../../base/global";
import { API2 } from "rootnet-core/base";
import { strParams } from "../../../utils/publicFun";
import { Messager } from 'rootnet-ui'

import _ from 'lodash'

function TinyEditor(props, ref) {
    const {contentStyle = ''} = props
    const {height} = props
    const editorRef = useRef(null);
    const [initCallbacks, setInitCallBacks] = useState([])
    const contentRef = useRef()


    const getContent = () => {
        if (editorRef.current) {
            return editorRef.current.getContent();
        }
    };

    const setContent = (content) => {
        contentRef.current = content
        if (editorRef.current) {
            editorRef.current.undoManager.clear();
            editorRef.current.setContent(content);
        }else{
            setInitCallBacks(() => {
             return [
                 ()=>{
                     editorRef.current.undoManager.clear();
                     editorRef.current.setContent(contentRef.current);
                 }
             ]
            })
        }
    };

    useImperativeHandle(ref, () => ({
        getContent,
        setContent,
    }));

    // const loadEditor = useCallback(()=>{
    //     tinymce.init({
    //         selector: `#tinymce${unique}`,
    //         // 翻译文件放到后端服务器
    //         language_url: "http://192.168.7.123:8089/minioCommon/getImage?id=864607401020489728",
    //         language: 'zh_CN',
    //         height: '100%',
    //         width: '100%',
    //         paste_data_images: true,
    //         skin_url: "/tinymce/skins/ui/oxide",
    //         plugins: 'image paste lists  link advlist autolink code hr table preview searchreplace',
    //         toolbar: 'code undo redo | fontsizeselect | preview bold italic  underline strikethrough hr | forecolor backcolor| table image | bullist numlist | alignleft aligncenter alignright alignjustify | link searchreplace',
    //         images_upload_handler: function (blobInfo, succFun, failFun) {
    //             const fileData = new FormData()
    //             // let file = new File([blobInfo], blobInfo.filename(), {type: contentType, lastModified: Date.now()});
    //             fileData.append('file', blobInfo.blob())
    //             let postParams = {
    //                 bucketName: 'rich-text-file',
    //                 createUser: gd._user.operator_name,
    //             }
    //             API2.post('/minioCommon/uploadFile?'+strParams(postParams),fileData).then(res => {
    //                 succFun(res.data.url)
    //             }).catch(err => {
    //                 failFun(err._message)
    //                 Messager.show(err._message,{icon:'error'});
    //             })
    //         },
    //     });
    // },[unique])


    // useEffect(() => {
    //     loadEditor();
    // }, [loadEditor]);

    // return <div id={'tinymce'+unique}/>

    return <Editor
        apiKey="dha5m89cw7pwni82aj645380m2mqe9f6sk636vzavwytkru6"
        onInit={(evt, editor) => {
            editorRef.current = editor
            setTimeout(()=>{
                if(!_.isEmpty(initCallbacks)){
                    _.forEach(initCallbacks, fn => fn())
                    setInitCallBacks([])
                }
            },0)
        }}
        tinymceScriptSrc={'./tinymce.min.js'}
        init={{
            // language_url: "http://rims.croot.com/api/minio/minioCommon/getFile?id=869157040763699200",
            language_url: "./zh_CN.js",
            language: 'zh_CN',
            height: height || '100%',
            width: '100%',
            paste_data_images: true,
            content_style: "img {max-width:100%;} " + contentStyle,
            resize: true,
            branding: false,
            elementpath: false,
            convert_urls : false,
            plugins: 'image paste lists  link autolink code hr table preview searchreplace fullscreen',
            toolbar: 'undo redo | fontsizeselect | preview bold italic  underline strikethrough | forecolor backcolor| table image link | bullist numlist | alignleft aligncenter alignright alignjustify | fullscreen',
            init_instance_callback: editor => {
                editor.on('keydown', e => {
                    let keyCode = e.keyCode
                    const fullscreen = editor?.plugins?.fullscreen?.isFullscreen()
                    if (keyCode === 27 && fullscreen) editor.editorCommands.execCommand('mceFullScreen')
                })
            },
            file_picker_callback: function(callback, value, meta) {
                //模拟出一个input用于添加本地文件
                let input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();
                input.onchange = function() {
                    let file = this.files[0];
                    let formData = new FormData();
                    console.log(file);
                    formData.append('file',file,file.name);
                    // 这里我和图片上传是一个接口
                    let postParams = {
                        bucketName: 'rich-text-file',
                        createUser: gd._user.operator_name,
                    }
                    API2.post('/minioCommon/uploadFile?' + strParams(postParams),formData).then(res =>{
                        Messager.show("上传成功", { icon: 'success' })
                        callback(res.data.url,{text: file.name});
                    }).catch(err => {
                        Messager.show(err._message, { icon: 'error' });
                    })
                }
            },
            images_upload_handler: function (blobInfo, succFun, failFun) {
                const fileData = new FormData()
                let file
                if(blobInfo.blob()[Symbol.toStringTag] === 'Blob'){
                    file = new window.File([blobInfo.blob()],`image.png`)
                }else{
                    file = blobInfo.blob()
                }
                fileData.append('file', file)
                let postParams = {
                    bucketName: 'rich-text-file',
                    createUser: gd._user.operator_name,
                }
                ref.current.loading = true
                API2.post('/minioCommon/uploadFile?' + strParams(postParams), fileData).then(res => {
                    ref.current.loading = false
                    succFun(res.data.url)
                }).catch(err => {
                    ref.current.loading = false
                    failFun(err._message)
                    Messager.show(err._message, { icon: 'error' });
                })
            },
        }}
    />
}

export default forwardRef(TinyEditor)
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Form, FormInput, Select} from "rootnet-edit";
import {Button, DataGrid,Messager,MessageBox} from 'rootnet-ui'
import './common.scss'
import {Box} from "../../../common/commonComponent";
import OperationList from "../../../../project_share/components/OperationList";
import {Cache} from "../../../../base/cache";
import _ from "lodash";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {useGet, usePost} from "../../../../utils/hook";
import {Icon} from "../../../../project_share/components";
import {diffArray, handleTree, selectAll} from "../../../common/TreeFunc";
import {pathSearchFor, strParams} from "../../../../utils/publicFun";
import DetailsGoBackWithTextClick from "../../../common/DetailsGoBackWithTextClick";

const { str14ToDate } = toDate;
const CACHE_KEY = 'createView'
const CURRENT_CACHE_KEY = 'projectList'
const PRIMARY_KEY = 'projectId'

const getColumns = (props) => {
    const {setAddList,projectStatusOptions,handleTreeClick,selectedList,setSelectedList,handleSelectionChange} = props
    return [
        {selection:true},
        {header: '项目名称', bind: 'projectName',width: 250, tooltip: true,convert: addLeftIcon},
        {header: '项目经理', bind: 'projectManagerName',width: 90},
        {header: '状态', bind: 'projectStatus',width: 90,convert: v => convertOptions(v,projectStatusOptions)},
        {header: '开始日期', bind: 'startDate',width: 100,convert: convertDate},
        {header: '结束日期', bind: 'endDate',width: 100,convert: convertDate},
        {header: '产品', bind: 'productName',width: 150, tooltip: true},
        {header: '客户', bind: 'customerName',width: 90},
        {header: '操作', bind: '', align: 'center', width: 90, isTransfer: false, convert: renderOperationWrap}
    ]

    function addLeftIcon(v, o, i) {
        return <span className='id-col' style={{marginLeft: o._level*30,cursor: o.children?'pointer':'default'}} onClick={() => {handleTreeClick(o,i,'pid','projectId')}}>
            {o.children && <Icon name="arrow_drop_down" className='arrow_drop_down' style={{transform: o._expand ? 'none' : 'rotate(-90deg)'}}/>}
            {o.leafProjectFlag === '1' && <Icon name="yezijiedian" style={{color: '#33CC8A', marginRight: 5}} />}
            {v}
        </span>
    }

    function renderOperationWrap(v, o){
        const all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const notInCache = _.isNil(_.find(currentCache, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]))
        if(o.leafProjectFlag !== '1') return
        if(notInCache){
            return renderAddOperation(v, o)
        }else{
            return renderDelOperation(v, o)
        }
    }
    function renderAddOperation(v, o){
        const addOption = [{
            text: '添加',
            onClick: ()=> {
                addCache(o)
                addSelectedList(o)
            }
        }]
        return <OperationList options={addOption}/>;
    }

    function addSelectedList(o){
        const isNotInSelectedList = _.filter(selectedList, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]).length === 0
        if(isNotInSelectedList){
            handleSelectionChange(selectedList.concat(o))
        }
    }

    function renderDelOperation(v, o){
        const disableOption = [{
            text: '已添加',
            disabled: true
        }]
        const delOption = [{
            text: '移除',
            onClick: ()=>delCache(o)
        }]
        return <div className='del-operation-group'>
            <OperationList className='added-operation-btn' options={disableOption}/>
            <OperationList className='del-operation-btn' options={delOption}/>
        </div>
    }

    function delCache(o){
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const filterCacheList = _.filter(currentCache, x => x[PRIMARY_KEY] !== o[PRIMARY_KEY])
        all[CURRENT_CACHE_KEY] = delProject(o,filterCacheList)
        Cache.set(CACHE_KEY, all)
        setAddList(all[CURRENT_CACHE_KEY])
        const filterList = delProject(o,selectedList)
        setSelectedList(filterList)
        function delProject(obj, list){
            const filterList = _.filter(list, x => x[PRIMARY_KEY] !== obj[PRIMARY_KEY])
            const parent = _.find(filterList, x => x[PRIMARY_KEY] === obj.pid)
            if(!_.isNil(parent)) return delProject(parent, filterList)
            return filterList
        }
    }

    function addCache(o){
        let all = Cache.get(CACHE_KEY)
        if(_.isNil(all)) all = {}
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        if(_.isNil(currentCache)) return add([o])
        const notInCache = _.isNil(_.find(currentCache, x => x[PRIMARY_KEY] === o[PRIMARY_KEY]))
        return notInCache?add(all[CURRENT_CACHE_KEY].concat(o)):Messager.show('已经在添加列表中',{icon:'error'})
        function add(list){
            all[CURRENT_CACHE_KEY] = list
            Cache.set(CACHE_KEY, all)
            setAddList(all[CURRENT_CACHE_KEY])
            return Messager.show('已添加',{icon:'success'})
        }
    }

    function convertOptions(v, options){
        return _.get(_.find(options, x => x.value === v),'text')
    }

    function convertDate(v){
        return dateFormat('YYYY-MM-DD', str14ToDate(v))
    }
}

const getOption = (columns) => ({
    columns,
    autoFill: true,
    resizable: true,
    fixedLeft: 2,
    fixedRight: 1,
});

const INIT_PARAMS = {
    productID: null,
    customer: null,
    projectManager: null,
    projectName: null,
    projectType: ['DEV','CUS'],
    projectStatus: ['01'],
}

const CONVERT_URLS = [
    '/common/globalconst?globalConst=PSAProjectStatus',
    '/common/user/positions?posIds=PM,PMO',
]

function CreateProjectView(props) {
    const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
    const {location} = props;
    const pathParams = useMemo(() => pathSearchFor(location.search), [location]);
    const {scene = 'add'} = pathParams
    const [params, setParams] = useState(INIT_PARAMS)
    const [selectedList, setSelectedList] = useState([])
    const {data: allList, doFetch: getList, loading, error} = usePost()
    const [addList, setAddList] = useState([])
    const {data: convertRes} = useGet(CONVERT_URLS)
    const [showList, setShowList] = useState([])
    const [beforeSelectedList, setBeforeSelectedList] = useState([])
    const [showLeaveDialog, setShowLeaveDialog] = useState(false)
    const [isSaved, setIsSaved] = useState(true)

    const [projectStatusOptions, managerOptions] = useMemo(()=>{
        const [d1, d2] = convertRes || []
        const projectStatusOptions = _.map(d1,x => ({value: x.interiorId, text: x.displayName}))
        const managerOptions = _.map(d2, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`}))
        const uniqManagerOptions = _.uniqBy(managerOptions, x => x.value)
        return [projectStatusOptions, uniqManagerOptions]
    },[convertRes])

    const handleInitParams = useMemo(()=>{
        if(_.isNil(managerOptions)) return INIT_PARAMS
        if(_.find(managerOptions, x => x.value === userAccount)){
            setParams({...INIT_PARAMS,projectManager:userAccount})
            return {...INIT_PARAMS,projectManager:userAccount}
        }
        return INIT_PARAMS
    },[managerOptions,userAccount])

    const buildTree = useCallback((list,activeList)=>{
        return _.map(list, listItem => {
            const childList = _.filter(activeList, allItem => allItem.pid === listItem.projectId)
            if(childList.length === 0) return listItem
            return _.assign(listItem,{children: buildTree(childList,activeList)})
        })
    },[])

    const filterNotLeaf = useCallback((list)=>{
        let remainList = list
        const allParentKeyList = _.map(list, x => x.pid)
        const lowestList = _.filter(list, x => !_.includes(allParentKeyList, x.projectId))
        const notLeafList = _.filter(lowestList, x => x.leafProjectFlag !== '1')
        if(notLeafList.length === 0){
            return remainList
        }else{
            const notLeafKeyList = _.map(notLeafList, x => x.projectId)
            remainList = _.filter(remainList, x => !_.includes(notLeafKeyList, x.projectId))
            return filterNotLeaf(remainList)
        }
    },[])

    const expandList = useMemo(()=>{
        if(_.isNil(allList)) return []
        const activeList = filterNotLeaf(allList)
        const filterList = _.filter(activeList, item => _.isNil(item.pid))
        return handleTree(buildTree(filterList,activeList),'children','projectId')
    },[allList,buildTree,filterNotLeaf])

    useEffect(()=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const keyList = _.map(currentCache, x => x[PRIMARY_KEY])
        const selected = _.filter(expandList, x => _.includes(keyList, x[PRIMARY_KEY]))
        setBeforeSelectedList(selected)
    },[addList,expandList])

    useEffect(()=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const keyList = _.map(currentCache, x => x[PRIMARY_KEY])
        const selected = _.filter(expandList, x => _.includes(keyList, x[PRIMARY_KEY]))
        const leafList = _.filter(selected, x => x.leafProjectFlag === '1')
        const initList = _.reduce(leafList, (acc, x) => {
            return selectAll(x, expandList,acc, 'pid', 'children', 'projectId')
        },[])
        const initKeyList = _.map(initList, x => x.projectId)
        const initSelectedList = _.filter(expandList, x => _.includes(initKeyList,x.projectId))
        setSelectedList(initSelectedList)
    },[expandList])

    const showSelectedList = useMemo(()=>{
        const keyList = _.map(selectedList, x => x.projectId)
        return _.filter(showList, x => _.includes(keyList, x.projectId))
    },[selectedList,showList])

    useEffect(()=>{
        setShowList(expandList)
    },[expandList])

    useEffect(()=>{
        if(_.isNil(managerOptions) || managerOptions.length === 0) return
        getList('/viewDetail/viewDtailPrj',params)
    },[getList,params,managerOptions])

    const handleTreeClick = useCallback((o,i,parentName, keyName)=>{
        const updateItem = _.assign({},showList[i],{_expand: !showList[i]._expand})
        if(o._expand){
            const endIndex = _.reduce(showList.slice(i+1),(acc,x,index)=>{
                if(acc!==-1) return acc;
                const currentIndex = (index + i + 1);
                return (showList[currentIndex]._level <= o._level) ? currentIndex : acc;
            },-1);
            setShowList(showList.slice(0,i).concat(updateItem).concat(endIndex !== -1? showList.slice(endIndex): []))
        }else{
            const allList =  _.map(expandList, item => _.assign({},item,{_expand: false}))
            const filterList = _.filter(allList, item => item[parentName] === o[keyName])
            const addList = _.map(filterList, item => _.assign(item, {_level: o._level+1}))
            const newShowList = showList.slice(0,i).concat(updateItem).concat(addList).concat(showList.slice(i+1))
            setShowList(newShowList)
        }
    },[expandList,showList])

    const handleSelectionChange = useCallback((newList)=>{
        if(newList.length === selectedList.length) return selectedList
        if(newList.length === showList.length){
            setSelectedList(expandList)
            return showList
        }else if(newList.length === 0){
            setSelectedList([])
            return []
        }else{
            const diffItem = diffArray(newList, selectedList, 'projectId')[0]
            const handleList = selectAll(diffItem, expandList,selectedList, 'pid', 'children', 'projectId')
            const keyList = _.map(handleList, x => x.projectId)
            const selected = _.filter(expandList, x => _.includes(keyList,x.projectId))
            setSelectedList(selected)
            return selected
        }
    },[selectedList,showList, expandList])

    const option = useMemo(()=>{
        return getOption(getColumns({setAddList,projectStatusOptions,handleTreeClick,selectedList,setSelectedList,handleSelectionChange}))
    },[setAddList,projectStatusOptions,handleTreeClick,selectedList,setSelectedList,handleSelectionChange])

    const addCacheList = useCallback((list)=>{
        let all = Cache.get(CACHE_KEY)
        if(_.isNil(all)) all = {}
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        add(_.isNil(currentCache)?list:[...all[CURRENT_CACHE_KEY],...list])

        function add(addList){
            all[CURRENT_CACHE_KEY] = addList
            Cache.set(CACHE_KEY, all)
            setAddList(all[CURRENT_CACHE_KEY])
        }
    },[])

    const delCacheList = useCallback((list)=>{
        let all = Cache.get(CACHE_KEY)
        const currentCache = _.get(all, CURRENT_CACHE_KEY)
        const delKeyList = _.map(list, x => x[PRIMARY_KEY])
        all[CURRENT_CACHE_KEY] = _.filter(currentCache, x =>  !_.includes(delKeyList, x[PRIMARY_KEY]))
        Cache.set(CACHE_KEY, all)
        setAddList(all[CURRENT_CACHE_KEY])
        Messager.show('已保存',{icon:'success'})
    },[])

    const handleSelected = useCallback((list)=>{
        const beforeKeyList = _.map(beforeSelectedList, x => x[PRIMARY_KEY])
        const afterKeyList = _.map(list, x => x[PRIMARY_KEY])
        const addList = _.filter(list, x => !_.includes(beforeKeyList, x[PRIMARY_KEY]))
        const delList = _.filter(beforeSelectedList, x => !_.includes(afterKeyList, x[PRIMARY_KEY]))
        addCacheList(addList)
        delCacheList(delList)
        setIsSaved(true)
    },[beforeSelectedList,addCacheList,delCacheList])

    const extra = useMemo(()=>{
        return <>
            <Button primary onClick={()=>handleSelected(selectedList)}>保存</Button>
        </>
    },[handleSelected,selectedList])

    return  <div className='create-project-view flex-y fill create-view-list'>
        <DetailsGoBackWithTextClick onClick={handleClickLeave} />
        <Options initParams={handleInitParams} search={setParams} managerOptions={managerOptions}/>
        <Box title='选择实体项目' className='flex-y x-card-singlegrid'
             data={showList} extra={extra} loading={loading} error={error}>
            <DataGrid option={option} data={showList} selection={showSelectedList} onSelectionChange={list=>{handleSelectionChange(list);setIsSaved(false)}}/>
        </Box>
        {
            showLeaveDialog &&
            <MessageBox header='提示' confirm={goBack} cancel={()=>setShowLeaveDialog(false)}>
                当前内容未保存，确定离开吗？
            </MessageBox>
        }
    </div>

    function handleClickLeave(){
        if(isSaved) return goBack()
        setShowLeaveDialog(true)
    }

    function goBack(){
        const url = scene === 'add' ? '/createView?' : '/projectView/edit?'
        props.history.push(url + strParams(pathParams))
    }
}

const OPTION_URLS = [
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/common/system',
]

function Options(props){
    const {initParams, search, managerOptions} = props;
    const [params, setParams] = useState(initParams);
    const {data: optionsRes} = useGet(OPTION_URLS)

    useEffect(()=>{
      setParams(initParams)
    },[initParams])

    const [productOptions,customerOptions] = useMemo(()=>{
        const [d1, d2, d3] = optionsRes || []
        const productOptions = _.map(_.groupBy(d1,product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            children: _.map(productLineItem, x => ({value: x.productId, text: x.productName}))
        }))
        const customerOptions = _.map(d3, x => ({value: x.projectId, text: `${x.projectId}-${x.projectName}`}))
        return [productOptions,customerOptions]
    },[optionsRes])

    return <div className='options c-options no-margin-top'>
        <Form value={params} onChange={obj => setParams(x => ({...x,...obj}))}>
            <FormInput bind="productID" label="产品" component={Select} clear search tree multiple
                        options={productOptions}/>
            <FormInput bind="customer" label="客户" component={Select} clear search options={customerOptions}/>
            <FormInput bind="projectManager" label="项目经理" component={Select} search clear options={managerOptions}/>
            <FormInput bind="projectName" label='项目名称'/>
        </Form>
        <Button primary onClick={() => search({...params})} className='btn'>查询</Button>
        <Button text onClick={() => {
            setParams(initParams)
            search(initParams)
        }} className='btn reset-btn'>重置</Button>
    </div>
}

export default CreateProjectView;
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Dialog } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { isNil } from 'rootnet-core/format'
import { ScheduleManagementContext } from '../../../../../../../common/Context'

const CHECK_LIST_SPLIT_URL = '/releaseLaunch/check/list/split'

export default function CheckListSplit(props) {
    const { close, custId, upgradePath } = props
    const { downloadPostProgress } = useContext(ScheduleManagementContext)
    const [svnPath, setSvnPath] = useState()
    const { data } = useGet('/releaseLaunch/svn/info')
    const [isConfirm, setIsConfirm] = useState(false)

    const confirm = useCallback(() => {
        if (isConfirm) return
        downloadPostProgress(CHECK_LIST_SPLIT_URL, { custId, svnPath, upgradePath }, 'checkList拆分', close, setIsConfirm)
    }, [downloadPostProgress, close, custId, svnPath, upgradePath, isConfirm])

    useEffect(() => {
        if (isNil(data)) return
        setSvnPath(data)
    }, [data])

    return (
        <Dialog header='checkList拆分' cancel={close} confirm={confirm}>
            <FormInput required horizontal label='版本路径' bind='svnPath' componentWidth={300} value={svnPath} onChange={setSvnPath} />
        </Dialog>
    )
}

import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Messager } from 'rootnet-ui'
import { Table } from 'antd'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Display } from 'rootnet-edit'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../components'
import convertOptions from '../../../common/ConvertOptions'
import { strParams } from '../../../../utils/publicFun'
import { isNil } from 'rootnet-core/format'
import './projectManpowerSummaryDetailContent.scss'

const DETAIL_URL = '/humanManpower/list'

function getColumns(props) {
  const cols = [
    {
      title: '#',
      width: 40,
      align: 'center',
      render: (v, o, i) => i + 1
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>月份</span>,
      dataIndex: 'evaluationDate',
      key: 'evaluationDate',
      width: 90,
      render: v => {
        const getDate = _.split(dateFormat('YYYY-MM-DD', toDate.str14ToDate(v)), '-')
        const month = `${getDate[0]}年${getDate[1]}月`
        return month
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>部门</span>,
      dataIndex: 'departmentNameAllPath',
      key: 'departmentNameAllPath',
      width: 110,
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>占用工作量评估</span>,
      dataIndex: 'workloadOccupied',
      key: 'workloadOccupied',
      width: 80,
      render: v => `${v}人月`
    }
  ]

  return cols
}

export default function ProjectManpowerSummaryDetailContent(props) {
  const { itemClassOpt, close, projectAllOpt, initParams } = props
  const [mode, setMode] = useState('detail')
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [data, setData] = useState([])
  const { doFetch: getDetail } = useGet()

  const isEdit = useMemo(() => mode === 'edit', [mode])
  const isDetail = useMemo(() => mode === 'detail', [mode])

  useEffect(() => {
    setMode(x => x)
  }, [formData])

  const refreshDetail = useCallback(() => {
    const beginDate = `${_.get(initParams, 'evaluationDate')}-01`
    const endDate = getEndData(`${_.get(initParams, 'evaluationDate')}-01`)
    getDetail(`${DETAIL_URL}?${strParams({ projectId: _.get(initParams, 'projectId'), beginDate, endDate })} `)
      .then(res => {
        const filterData = _.filter(res.humanManpowerPipeline, o => o.isTrue === '0')
        const evaluationSummary = (_.sum(_.map(filterData, o => _.isNil(o.workloadOccupied) ? 0 : Number(o.workloadOccupied) * 100)) / 100)
        const params = _.assign({}, { evaluationSummary }, _.pick(initParams, ['projectId', 'type']))
        setData(_.map(filterData, o => _.assign({}, o, { actionType: 'edit' })))
        setFormData(params)
        setEditFormData(params)
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getDetail, initParams])

  const columns = useMemo(() => {
    return _.map(getColumns({ editable: isEdit }), col => col)
  }, [isEdit])

  useEffect(() => {
    if (_.isNil(_.get(initParams, 'projectId'))) return
    refreshDetail()
  }, [refreshDetail, initParams])

  return (
    <div className={'project-manpower-summary-detail-content flex-y'}>
      <div className="mock-dialog-header flex">
        <div className="dialog-title">
          {isEdit ? '编辑' : '详情'}
        </div>
        <div className="mock-right-header flex center-y">

          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>
      <div className="project-manpower-summary-detail-main-panel flex">
        <div style={{ width: '100%' }}>
          <Form
            value={editFormData}
            onChange={setEditFormData}
          >
            <FormInput horizontal labelWidth={70} componentWidth={300} bind='projectId' label='项目/事务' component={Display} options={projectAllOpt} convert={v => convertOptions(v, projectAllOpt)} />
            <FormInput horizontal labelWidth={70} componentWidth={180} bind='type' label='预估类型' component={Display} options={itemClassOpt} convert={v => convertOptions(v, itemClassOpt)} />
            <FormInput horizontal bind='evaluationSummary' label='占用工作量评估汇总' component={Display} convert={v => {
              return isNil(v) ? v : `${v}人月`
            }} />
          </Form>
        </div>
        <Table
          size={'small'}
          rowClassName={'editable-row'}
          columns={columns}
          pagination={false}
          dataSource={data}
          rowKey="id"
          sticky={true}
          scroll={{ y: 270 }}
        />
      </div>
      <div className="mock-footer flex center-y">
        <div />

        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
        </div>
      </div>
    </div>
  )
}

function getEndData(date) {
  const mydate = new Date(date)
  const yyyy = mydate.getFullYear();
  let MM = (mydate.getMonth() + 1).toString().padStart(2, '0');
  const lastDate = new Date(yyyy, MM, 0).getDate();
  let dd = mydate.getDate().toString().padStart(2, '0');
  if (MM === 2 && dd > 28) {
    if (yyyy % 100 === 0 && yyyy % 400 === 0) {
      dd = 29
    } else if (yyyy % 100 !== 0 && yyyy % 4 === 0) {
      dd = 29;
    } else {
      dd = 28;
    }
  }
  return `${yyyy}-${MM}-${lastDate}`
}


import React, {useMemo, useState} from 'react';
import { DataGrid, Pagination } from "rootnet-ui";
import _ from "lodash";
import convertTableAlign from "../../../common/view/convertTableAlign";
import findConvert from "../../../common/view/findConvert";
import useGetViewConfig from "../../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../../common/view/hooks/useGetTreeList";
import ViewArea from "../../../common/view/ViewArea";
import './WorkplaceViewCommonTemplate.scss'
import convertOptions from "../../../common/ConvertOptions";
import {Popover} from "antd";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import RequirementDetailDialog from "../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog";
import IssueDetailDialog from "../../../issueMgt/components/issueDetailDialog/IssueDetailDialog";
import DefectUpdateDialog from "../../../testMgt/defect/controls/DefectUpdateDialog";
import TrackingDetailDialog from "../../../trackingManagementMgt/components/trackingDetailDialog";
import TodoListDetailDialog from "../../../todolist/components/todoListDetailDialog";
import ReqStageUpdateDialog from "../../../stageMgt/reqStageUpdateDialog/ReqStageUpdateDialog";
import CustomerServiceInfoDetailDialog
    from "../../../customerServiceInfo/customerServiceInfoDetailDialog/CustomerServiceInfoDetailDialog";
import DevListDetailDialog from '../../../devListMgt/devListDetailDialog';
import CustomerVoiceDetail from "../../../customerVoiceMgt/customerVoiceDetail/CustomerVoiceDetail";

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, enterDetailField, module, setShowDetailDialog, workflowFuncCode, workFlowId, setWorkFlowId, refreshList } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== enterDetailField,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if(tableField === enterDetailField){
            return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
                <div className="enter-detail-field flex">
                    <div className="enter-detail-field-text" onClick={()=> {
                        if(module === 'case'){
                            window.open('/#/testPlanMgt?planId=' + _.get(o,'id'))
                        }else{
                            setShowDetailDialog({
                                id: _.get(o,'id'),
                                module: module
                            })
                        }
                    }}>
                        {showValue || '-'}
                    </div>
                </div>
            </div>
        }

        if(tableField === 'workflow_business.factorId'){
            const convertRule = _.find(convertCollection, (v,k) => k === tableField)
            const color = convertOptions(v, convertRule, 'color')
            return <Popover destroyTooltipOnHide={true}
                            content={<WorkFlowChangePop businessId={workFlowId} close={()=>setWorkFlowId(null)}
                                                        refreshList={refreshList} funcCode={workflowFuncCode} businessType={module}/>}
                            onOpenChange={visible => {
                                if(!visible){
                                    setWorkFlowId(null)
                                }
                            }}
                            trigger={'click'}
                            placement="bottom"
                            open={o.id === workFlowId}
            >
                <div className={'common-display-field work-flow-status-field'} onClick={()=>setWorkFlowId(o.id)}
                     style={{color: color, border: `1px solid ${color}`,padding: '0 8px', borderRadius: 4}}>
                    {showValue || '-'}
                </div>
            </Popover>
        }

        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

function WorkplaceViewCommonTemplate(props) {
    const {funcCode, enterDetailField, module, replaceViewId, workflowFuncCode, afterViewRefresh, appendObj} = props
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(funcCode, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [workFlowId, setWorkFlowId] = useState()
    const [sortConfig, setSortConfig] = useState()

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const refreshList = useRefreshList(_.assign({ currentViewId, params, allColumns, getList, setParams, funcCode,
        replaceViewId, afterRefresh: afterViewRefresh }, !_.isNil(appendObj) && {appendObj}))

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: {enterDetailField, module, setShowDetailDialog, workflowFuncCode, workFlowId, setWorkFlowId, refreshList},
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                const sortList = _.sortBy(data, x => x[sort.column])
                return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
            }
        },
    })

    const showList = useMemo(() => {
        let showList = dataGridList || []
        if (!_.isNil(sortConfig)) {
            showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, dataGridList])

    return <div className={'workplace-view-common-template flex-y'}>
        <ViewArea funcCode={funcCode} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize}}/>
        <DataGrid option={options} data={showList} loading={boxLoading} error={error}/>
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                    onChange={(pageNum, pageSize) => {
                        setPageSize(pageSize)
                        setParams(x => _.assign({}, x, {pageNum, pageSize}))
                    }} />

        {
            _.get(showDetailDialog,'module') === 'req' &&
            <RequirementDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                     refreshViewList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'dev' &&
            <DevListDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                    refreshViewList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'issue' &&
            <IssueDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                               refreshViewList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'defect' &&
            <DefectUpdateDialog mode={'detail'} close={()=>setShowDetailDialog(null)} currentId={_.get(showDetailDialog, 'id')}
                                refreshList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'todo' &&
            <TodoListDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                  refreshViewList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'tracer' &&
            <TrackingDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                  refreshViewList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'stage' &&
            <ReqStageUpdateDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                  refreshList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'customer' &&
            <CustomerServiceInfoDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                             refreshViewList={refreshList}/>
        }
        {
            _.get(showDetailDialog,'module') === 'voice' &&
            <CustomerVoiceDetail close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}
                                             refreshViewList={refreshList}/>
        }
    </div>
}

export default WorkplaceViewCommonTemplate;
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Drawer } from 'antd'
import { Messager } from 'rootnet-ui'
import { BaseTable, useTablePipeline, features } from 'ali-react-table'
import { workloadColumns } from '../getTableOption'
import { totalAmountFun } from '../formatData'
import { useApi } from '../../../../../utils/hook'
import './index.scss'

const EDIT_WORKLOAD_RUL = '/workloadCalculate/edit' //编辑月份工作量

export default function EstimatedTotalWorkVolume(props) {
  const { data, visible, close = () => { }, refresh, isFuncCodeEdit, isModule } = props
  const [editInfo, setEditInfo] = useState({})
  const [expanded, setExpanded] = useState(true)
  const [openKeys, onChangeOpenKeys] = useState()
  const [value, setValue] = useState()
  const [isInherit, setIsInherit] = useState(false)
  // const [lastClickRowIndex, setLastClickRowIndex] = useState()//#edf3f9
  const { doFetch } = useApi()

  const dataSource = useMemo(() => totalAmountFun(data, isModule), [data, isModule])

  const editUpdate = useCallback((val, id) => {
    if (val === value || _.isNil(value)) return destroyValue()
    doFetch(EDIT_WORKLOAD_RUL, 'post', { id, workLoad: value === '' ? '0' : value })
      .then(() => {
        refresh()
        destroyValue()
        setIsInherit(true)
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, refresh, value])

  const footerDataSource = useMemo(() => {
    const monthlyCollect = _.map(new Array(12), (v, i) => ({
      [`workLoad${i + 1}`]: <div className='summary-bold'>
        {_.sum(_.map(dataSource, o => _.toNumber(o[`workLoads${i + 1}`]))).toFixed(1)}
      </div>
    }))
    return [_.assign({
      model: <div className='summary-bold'>汇总</div>,
      total: <div className='summary-bold'>{_.sum(_.map(dataSource, o => _.toNumber(o['totals']))).toFixed(1)}</div>
    }, ...monthlyCollect)]
  }, [dataSource])

  const closeAll = useCallback(() => {
    close()
    destroyValue()
    setIsInherit(false)
    onChangeOpenKeys(getKey(dataSource))
  }, [close, dataSource])

  useEffect(() => {
    if (!isInherit && !_.isEmpty(dataSource)) {
      onChangeOpenKeys(getKey(dataSource))
    }
  }, [dataSource, isInherit])

  const pipeline = useTablePipeline()
    .input({
      dataSource, columns: workloadColumns({
        editInfo,
        setEditInfo,
        editUpdate,
        setValue,
        isFuncCodeEdit,
        expanded,
        setExpanded,
        open: () => onChangeOpenKeys(getKey(dataSource)),
        put: () => onChangeOpenKeys([]),
      })
    })
    .primaryKey('id')
    .use(
      features.treeMode({
        clickArea: 'cell',
        openKeys,
        onChangeOpenKeys,
      }),
    )

  return (
    <Drawer
      title='预计总工作量'
      destroyOnClose
      onClose={closeAll}
      visible={visible}
      className='estimated-total-work-volume antd-default-style'
    >
      <BaseTable
        footerDataSource={footerDataSource}
        {...pipeline.getProps()}
        // getRowProps={(record) => {
        //   return {
        //     style: record.id === lastClickRowIndex ? { background: '#edf3f9' } : {},
        //     onClick() { setLastClickRowIndex(record.id) },
        //   }
        // }}
        style={{
          '--row-height': "34px",
          '--header-bgcolor': '#F5F5F5',
          '--header-color': '#252525'
        }}
      />
    </Drawer>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

function getKey(data, arr = []) {
  _.forEach(data, o => {
    if (!_.isEmpty(o.children)) {
      arr.push(o.id)
      getKey(o.children, arr)
    }
  })
  return arr
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Table } from 'antd'
import { Button, Messager, MessageBox } from 'rootnet-ui'
import { FormInput, Select as RootSelect } from 'rootnet-edit'
import { strParams } from '../../../utils/publicFun'
import ArrowCrumbs from '../../common/ArrowCrumbs'
import { Box } from '../../common/commonComponent'
import { useApi } from '../../../utils/testHook'
import UserSelect from '../../common/personSelectPop/UserSelect'
import './evaluationList.scss'

const ADD_URL = '/quest-peopleevaluatelist/add/relation'

const getColumns = (props) => {
  const { params, setParams, userOpt } = props
  return [
    { title: '#', render: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { title: '被评者', dataIndex: 'userName', key: 'userName', width: 100, tooltip: true },
    { title: '同级', dataIndex: 'atTheSameLevel', key: 'atTheSameLevel', width: 300, tooltip: true, render: (v, o) => multipleUser(v, o, 'atTheSameLevel', 'synergy') },
    { title: '协同', dataIndex: 'synergy', key: 'synergy', width: 300, tooltip: true, render: (v, o) => multipleUser(v, o, 'synergy', 'atTheSameLevel') },
  ]

  function multipleUser(v, o, label, otherLabel) {
    const filterData = _.concat(_.get(params, `${o.id}.${otherLabel}`, []), [o.beEvaluators])
    return <UserSelect
      multiple allowClear
      value={_.map(_.get(params, `${o.id}.${label}`, []), (item) => item)}
      onChange={v => setParams(x => _.assign({}, x, { [o.id]: _.assign({}, x[o.id], { [label]: v }) }))}
      outerAllUserRes={userOpt}
      hiddenUserList={filterData}
      style={{ width: '100%' }}
    />
  }
}

export default function EvaluationList(props) {
  const { history, relationId, setRelationId, listOpt, relationData, relationError, relationLoading, userOpt, relationRefresh } = props
  const [params, setParams] = useState({})
  const [defaultData, setDefaultData] = useState()
  const [message, setMessage] = useState(false)
  const { doFetch } = useApi()

  const option = useMemo(() => getColumns({ params, setParams, userOpt }), [params, userOpt])

  useEffect(() => {
    if (_.isEmpty(relationData)) return
    let parameter = {}
    _.forEach(relationData, item => {
      const { atTheSameLevel, synergy } = item || {}
      parameter = _.assign({}, parameter, {
        [item.id]: {
          // atTheSameLevel: _.map(atTheSameLevel, o => ({ [o.id]: o.evaluators })),
          // synergy: _.map(synergy, o => ({ [o.id]: o.evaluators }))
          atTheSameLevel: _.map(atTheSameLevel, o => o.evaluators),
          synergy: _.map(synergy, o => o.evaluators)
        }
      })
    })
    setDefaultData(parameter)
    setParams(parameter)
  }, [relationData])

  const returnShow = useMemo(() => {
    return _.every(_.keys(defaultData), v => {
      const newAtTheSameLevel = _.get(params[v], 'atTheSameLevel')
      const oldAtTheSameLevel = _.get(defaultData[v], 'atTheSameLevel')
      const newSynergy = _.get(params[v], 'synergy')
      const oldSynergy = _.get(defaultData[v], 'synergy')
      return (_.every(newAtTheSameLevel, item => _.includes(oldAtTheSameLevel, item)) && _.every(newSynergy, item => _.includes(oldSynergy, item)))
    })
  }, [defaultData, params])

  const routingHop = useCallback((id = null, pathname = '/360Evaluate') => {
    setMessage(false)
    setRelationId(id)
    history.replace(_.assign({}, { pathname }, !_.isNil(id) && { search: strParams({ relation: id }) }))
  }, [history, setRelationId])

  const goBackData = useMemo(() => {
    return [
      { text: '360评价' },
      {
        text: <FormInput search horizontal
          options={listOpt}
          component={RootSelect}
          defaultValue={relationId}
          onChange={v => { routingHop(v) }}
        />, active: true
      },
    ]
  }, [listOpt, relationId, routingHop])

  const confirm = useCallback(() => {
    const parameter = _.map(relationData, item => ({
      ..._.omit(item, ['atTheSameLevel', 'synergy']),
      atTheSameLevel: _.map(_.get(params, `${item.id}.atTheSameLevel`, []), v => ({ ..._.pick(item, ['questId', 'beEvaluators']), evaluators: v })),
      synergy: _.map(_.get(params, `${item.id}.synergy`, []), v => ({ ..._.pick(item, ['questId', 'beEvaluators']), evaluators: v }))
    }))

    doFetch(ADD_URL, 'post', parameter)
      .then(() => {
        relationRefresh()
        Messager.show('设置成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))

  }, [doFetch, params, relationData, relationRefresh])

  const extra = useMemo(() => {
    return <>
      <Button primary onClick={_.debounce(confirm, 300)}>保存</Button>
      <Button text onClick={() => !returnShow ? setMessage(true) : routingHop()}>取消</Button>
    </>
  }, [confirm, returnShow, routingHop])

  return (
    <>
      <div className='evaluation-list fill flex-y'>
        <div style={{ backgroundColor: '#fff', padding: '4px 0', marginBottom: 8 }}>
          <ArrowCrumbs useTips={!returnShow} goBack={() => { routingHop() }} data={goBackData} />
        </div>
        <Box
          className='flex-y x-card-singlegrid'
          title='评价关系设置'
          loading={relationLoading}
          error={relationError}
          data={relationData}
          extra={extra}
        >
          <Table scroll={{ y: 99999 }} rowKey={'id'} dataSource={relationData} columns={option} pagination={false} size={'small'} />
        </Box>
      </div>
      {
        message &&
        <MessageBox
          className={'content-center-dialog'}
          header="提示"
          cancel={() => setMessage(false)}
          confirm={() => routingHop()}
        >
          离开当前页面，内容将不被保存
        </MessageBox>
      }
    </>
  )
}

import React, { useState, useMemo, useEffect, useCallback, useReducer } from 'react'
import _ from 'lodash'
import { CheckBox } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../../../../../common/view/convertTableAlign'
import findConvert from '../../../../../../common/view/findConvert'
import useGetViewConfig from '../../../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../../../common/view/hooks/useGetTreeList'
import ViewArea from '../../../../../../common/view/ViewArea'
import { Box } from '../../../../../../common/commonComponent'
import './index.scss'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, selectedId, selectAll, checkedAll } = props

  const customColumns = []

  return [
    {
      header: <CheckBox value={selectAll} onChange={checkedAll} />, width: 40, stretch: false, align: 'center', convert: (v, o) => {
        return <CheckBox value={_.includes(selectedId, o.id)} />
      }
    },
    { header: '#', width: 40, stretch: false, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
      }
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '0005'

export default function IssueViewSearchDialog(props) {
  const { initId, relatedIssue, cancelRelatedIssue, close } = props
  const [selectedId, setSelectedId] = useState(initId)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [selectAll, setSelectAll] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const onRowClick = useCallback((item) => {
    if (_.includes(selectedId, item.id)) {
      setSelectedId(x => _.filter(x, v => v !== item.id))
    } else {
      setSelectedId(x => _.concat(x, [item.id]))
    }
  }, [selectedId])

  useEffect(() => {
    _.forEach(list, o => {
      return o._rowClass = selectedId === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [list, selectedId])

  const checkedAll = useCallback((checked) => {
    setSelectAll(checked)
    const ids = _.map(list, o => o.id)
    if (checked) {
      setSelectedId(x => {
        const filterItems = _.filter(list, o => !_.includes(x, o.id))
        return _.concat([], x, _.map(filterItems, o => o.id))
      })
    } else {
      setSelectedId(x => _.filter(x, v => !_.includes(ids, v)))
    }
  }, [list])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { selectedId, selectAll, checkedAll },
  })

  const confirm = useCallback(() => {
    const relatedIds = _.filter(selectedId, v => !_.includes(initId, v))
    const cancelRelatedIds = _.map(_.filter(initId, v => !_.includes(selectedId, v)), v => ({ id: v }))
    if (!_.isEmpty(relatedIds)) relatedIssue(relatedIds)
    if (!_.isEmpty(cancelRelatedIds)) cancelRelatedIssue(cancelRelatedIds, _.isEmpty(relatedIds))
    close()
  }, [cancelRelatedIssue, close, initId, relatedIssue, selectedId])

  useEffect(() => {
    setSelectAll(_.every(list, o => _.includes(selectedId, o.id)))
  }, [list, selectedId])

  return <Dialog header={'ISSUE查询'} className={'issue-view-query-dialog'} cancel={close} confirm={confirm}>
    <div className={'issue-view-query-content fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'ISSUE列表'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} onRowClick={(item) => onRowClick(item)} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </div>
  </Dialog>
}

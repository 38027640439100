import React, {useMemo, useState} from 'react';
import _ from "lodash";
import './AddFieldListPopover.scss'
import { Input,Empty } from 'antd';

function AddFieldListPopover(props) {
    const {list, setList, defaultParams} = props
    const [searchText, setSearchText] = useState()

    const showList = useMemo(()=>{
        if(_.isNil(searchText) || searchText === '') return list
        return _.filter(list, item => _.toLower(`${item.name}-${item.fieldName}`).indexOf(_.toLower(searchText)) !== -1)
    },[searchText, list])

    return <div className={'add-field-list-popover flex-y'}>
        <Input placeholder="搜索字段" value={searchText} onChange={e => setSearchText(e.target.value)}/>
        <div className="add-field-list flex-y scroll-y">
            {
                _.map(showList, item => <div className={'add-field-item flex center-y'} onClick={()=>addItem(item)} key={item.id}>
                    {`${item.name}-${item.fieldName}`}
                </div>)
            }
            {
                _.isEmpty(showList) &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'无数据'}/>
            }
        </div>
    </div>

    function addItem(item){
        const newItem = {
            fieldId: item.id,
            fieldName: `${item.name}-${item.fieldName}`,
            tableFieldName: `${item.tableName}.${item.fieldId}`,
            ...defaultParams
        }
        setList(x => [].concat(x, [newItem]))
    }
}

export default AddFieldListPopover;
import React, { useMemo, useState, useReducer, useCallback, useContext } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../common/view/convertTableAlign'
import findConvert from '../common/view/findConvert'
import useGetViewConfig from '../common/view/hooks/useGetViewConfig'
import useRefreshList from '../common/view/hooks/useRefreshList'
import useGetDateOptions from '../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../common/view/hooks/useGetTreeList'
import { Box } from '../common/commonComponent'
import ViewArea from '../common/view/ViewArea'
import { pathSearchFor, strParams } from '../../utils/publicFun'
import DevProjectDetail from './components/devProjectDetail'
import convertOptions from "../common/ConvertOptions";
import { TextIconBtn } from '../common/TextIconBtn'
import { filterDisplayFlagOptions } from '../common/publicFunc'
import convertGlobalConstOptions from '../common/ConvertGlobalConstOptions'
import DevProjectAddDialog from './components/devProjectAddDialog'
import './index.scss'
import { useFuncCode, useRolePermissions } from '../common/commonMethod'
import { ScheduleManagementContext } from '../common/Context'

const ENTER_DETAIL_FIELD = 'ProjectName'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, history } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`

    if (tableField === 'userproject.status') {
      const convertRule = _.find(convertCollection, (v, k) => k === `${fieldItem.tableName}.${fieldItem.fieldId}`)
      const color = convertOptions(v, convertRule, 'color') || '#000'
      return <div className={'border-field-wrap'} style={{ color: color, border: `1px solid ${color}`, padding: '0 8px', borderRadius: 4, fontSize: 12 }}>
        {showValue || '-'}
      </div>
    }
    if (tableField === 'View_Quality_Status.qualityStatus') {
      return <div style={{ color: showValue === '待审核' ? '#00A62E' : null }}>
        {showValue || '非待审核'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentInfo({ id: o.id, mode: 'detail' })
          history.replace({ pathname: '/devProjectMgt', search: strParams({ initId: o.id }) })
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 200
  }
}

const FUNC_CODE = '36'

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=typeOfRDProject',
]

export default function DevProjectMgt(props) {
  const { location, history } = props;
  const { initId = null, firstOrder = 'detail', secondOrder } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(200)
  const [isLoading, setIsLoading] = useState(true)
  const [showAddType, setShowAddType] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: allUserRes } = useGet('/common/userinfo')
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const [initType, setInitType] = useState()
  const [isProjectMgt, isPMM, isQA, isCM, isALL] = useRolePermissions(['projectMgt', 'PMM', 'QA', 'CM', 'ALL'])
  const [isAdd] = useFuncCode(['3601'])
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)
  const [factor, setFactor] = useState()
  const { downloadPostProgress } = useContext(ScheduleManagementContext)

  const [devProjectTypeOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: {
      setCurrentInfo, history
    }
  })

  const AddTypeSelect = useCallback(() => {
    const displayDevProjectTypeOptions = filterDisplayFlagOptions(devProjectTypeOptions)
    return <div className={'dev-project-type-add-group flex-y'}>
      {
        _.map(displayDevProjectTypeOptions, item => (
          <div className="dev-project-type-item flex center-y" key={item.value} onClick={() => {
            setInitType(item.value)
            setCurrentInfo({ mode: 'add' })
            setShowAddType(false)
          }}>
            <div className="type-tag flex center">{item.value}</div>
            <div className="type-name">{item.text}</div>
          </div>
        ))
      }
    </div>
  }, [devProjectTypeOptions])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '研发项目')
    // API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <Popover
        content={<AddTypeSelect />}
        trigger="click"
        placement="bottomRight"
        open={showAddType}
        onOpenChange={setShowAddType}
      >
        {isAdd && <TextIconBtn icon='tianjia' text='新增' />}
      </Popover>
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [showAddType, isAdd, allowExport, exportViewList])

  return (
    <div className={'dev-project-mgt fill flex-y'}>
      <div className={'flex-y fill'} style={{ display: _.get(currentInfo, 'mode') === 'detail' ? 'none' : '' }}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
          {...{
            getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
            currentViewId, setCurrentViewId, params, pageSize
          }} />
        <Box title={'研发项目'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          <Table {...{ options, dataGridList }} />
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => {
              setPageSize(pageSize)
              setParams(x => _.assign({}, x, { pageNum, pageSize }))
            }} />
        </Box>
      </div>
      {
        _.get(currentInfo, 'mode') === 'add' &&
        <DevProjectAddDialog close={() => setCurrentInfo(null)} {...{ initType, refreshList }} />
      }
      {
        _.get(currentInfo, 'mode') === 'detail' && <DevProjectDetail goBack={() => {
          setCurrentInfo(null)
          history.replace({ pathname: '/devProjectMgt' })
        }} {...{ currentInfo, setCurrentInfo, allUserRes, history, firstOrder, secondOrder, isProjectMgt, isPMM, isQA, isCM, isALL }} />
      }
    </div>
  )
}

function Table(props) {
  const { options, dataGridList } = props //focusId
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const onRowClick = useCallback((item) => {
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [dataGridList])

  // useEffect(() => {
  //   if (focusId) onRowClick({ id: focusId })
  // }, [focusId, onRowClick])

  return <DataGrid option={options} data={dataGridList} onRowClick={onRowClick} className='todo-list-data-grid' />
}

import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import './DailyReportMgt.scss'
import { Box } from "../common/commonComponent";
import _ from 'lodash'
import { Popover, Empty } from "antd";
import { TextIconBtn } from "../common/TextIconBtn";
import MarkdownEditor from "../markdown/MarkdownEditor";
import convertTableAlign from "../common/view/convertTableAlign";
import findConvert from "../common/view/findConvert";
import { DataGrid, Pagination, Button, Messager, Loader } from "rootnet-ui";
import useGetViewConfig from "../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../common/view/hooks/useRefreshList";
import useGetDateOptions from "../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../common/view/hooks/useGetTreeList";
import ViewArea from "../common/view/ViewArea";
import { API1 } from "../../base/task";
import clsx from "clsx";
import ResourceDeptSearch from "../resourcePoolMgt/resourceSchedule/resourceDeptSearch/ResourceDeptSearch";
import moment from 'moment'
import useGet from "rootnet-biz/es/hooks/useGet";
import { dateFormat, toDate } from 'rootnet-core/dateFormat';
import Icon from "../../components/Icon";
import DailyReportSelectDateDialog from "./dailyReportSelectDateDialog/DailyReportSelectDateDialog";
import { pathSearchFor, strParams } from "../../utils/publicFun";
import ContentTemplateSelect from '../common/contentTemplateSelect';
import useContentTemplateNum from '../common/hooks/useContentTemplateNum';
import EditTipLog from '../common/contentTemplateSelect/editTipLog';
import { isNil } from 'rootnet-core/format';
import DailyReportComment from './dailyReportComment';
import convertOptions from '../common/ConvertOptions';
import { useFuncCode } from "../common/commonMethod";

const ENTER_DETAIL_FIELD = 'staffReport.title'

const defaultObjectType = {
    day: 'dayContent',
    week: 'weekContent',
}

const defaultDateName = {
    'day': '日报',
    'week': '周报',
    'experience': '心得体会'
}

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, setCurrentId, setShowFullScreen } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        } else {
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== ENTER_DETAIL_FIELD,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem) {
        let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`

        if (tableField === ENTER_DETAIL_FIELD) {
            return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
                <div className="enter-detail-field flex">
                    <div className="enter-detail-field-text" onClick={() => {
                        setCurrentId(_.get(o, 'id'))
                        setShowFullScreen(true)
                    }}>
                        {showValue || '-'}
                    </div>
                </div>
            </div>
        }

        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

function getCommentDate({ beginDate = null, endDate = null, type = null }) {
    if (_.isNil(beginDate) || _.isNil(endDate) || _.isNil(type)) return ''
    if (_.includes(['day', 'experience'], type)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(beginDate))
    return dateFormat('YYYY-MM-DD', toDate.str14ToDate(beginDate)) + '至' + dateFormat('YYYY-MM-DD', toDate.str14ToDate(endDate))
}

const FUNC_CODE = '13'

function DailyReportMgt(props) {
    const { location, history } = props;
    const { createDate = null, createMode = null, initMode = 'add', initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
    const [currentId, setCurrentId] = useState()
    const { doFetch: getTemplate } = useGet()
    const [experienceTemplate, setExperienceTemplate] = useState()

    const markdownRef = useRef()
    const [value, setValue] = useState('')
    const [initValue, setInitValue] = useState('')

    const fullScreenMarkdownRef = useRef()
    const [showFullScreen, setShowFullScreen] = useState(false)

    const [mode, setMode] = useState('detail')
    const [showAddType, setShowAddType] = useState(false)
    const [addType, setAddType] = useState()
    const [viewList, setViewList] = useState()
    const [sortConfig, setSortConfig] = useState()
    const [focusId, setFocusId] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const [searchDept, setSearchDept] = useState(null)
    const [dateSelected, setDateSelected] = useState(moment())
    const { doFetch: submitPost } = usePost()
    const { doFetch: checkReportPost } = usePost()

    const [submitLoading, setSubmitLoading] = useState(false)
    const { data: initDetail, doFetch: getDetail } = useGet()
    const [countMarkdown, updateMarkdown] = useReducer((x) => x + 1, 0)
    const [detailLoading, setDetailLoading] = useState(false)

    const { doFetch: getAddTemplate } = usePost()

    const [replaceParams, setReplaceParams] = useState(null)
    const [checkDateType, setCheckDateType] = useState(null)

    const [lastSearchParams, setLastSearchParams] = useState()

    const [templateParams, setTemplateParams] = useState()
    const [templateFlag, setTemplateFlag] = useState(false)
    const [openItem, setOpenItem] = useState(null)
    const [templateItem, setTemplateItem] = useState()
    const addContentTemplateNum = useContentTemplateNum()
    const [canCheckAllView, setCanCheckAllView] = useState(false)

    const { data: userInfo } = useGet('/common/user/nowUserInfo')
    const { data: allUserRes } = useGet('/common/userinfo')

    const { doFetch: getWorkTime } = usePost()
    const [workTimeAuth] = useFuncCode(['7001'])

    const [userAccount, userName] = useMemo(() => {
        return [
            _.get(userInfo, 'userAccount'),
            _.get(userInfo, 'userName') || '',
        ]
    }, [userInfo])

    const type = useMemo(() => {
        return addType || _.get(initDetail, 'type')
    }, [addType, initDetail])

    useEffect(() => {
        getTemplate('/issue/getDemoTxt?id=57').then(res => {
            setExperienceTemplate(_.get(res, 'demoValue'))
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getTemplate])

    const refreshDetail = useCallback(() => {
        if (_.isNil(currentId)) return
        setDetailLoading(true)
        getDetail('/staff/report/detail?id=' + currentId).then(res => {
            setDetailLoading(false)
            setValue(_.get(res, 'content'))
            setInitValue(_.get(res, 'content'))
            setDateSelected(moment(toDate.str14ToDate(_.get(res, 'beginDate'))))
            updateMarkdown()
        }).catch(err => {
            setDetailLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getDetail, currentId])

    useEffect(() => {
        refreshDetail()
    }, [refreshDetail])

    const dynamicsTitle = useMemo(() => {
        const typeNameOptions = {
            'day': '日报',
            'week': '周报',
            'experience': '心得体会'
        }
        const typeName = _.get(typeNameOptions, type)
        const dateRange = _.includes(['day', 'experience'], type) ? moment(dateSelected).format('YYYY/MM/DD') :
            `${moment(dateSelected).startOf('week').format('YYYY/MM/DD')} - ${moment(dateSelected).endOf('week').format('YYYY/MM/DD')}`
        return `${typeName}_${userName}(${dateRange})`
    }, [type, dateSelected, userName])

    const title = useMemo(() => {
        return mode === 'detail' ? _.get(initDetail, 'title') : dynamicsTitle
    }, [mode, initDetail, dynamicsTitle])

    const isAllView = useMemo(() => {
        if (_.isEmpty(viewList)) return null
        if (_.isNil(currentViewId)) return null
        const currentView = _.find(viewList, x => x.id === currentViewId)
        return _.get(currentView, 'subType') === '所有的'
    }, [viewList, currentViewId])

    useEffect(() => {
        setReplaceParams(old => {
            if (isAllView === null) return null
            const newObj = {
                'jobrecord_user.[department]': isAllView ? searchDept : null
            }
            if (JSON.stringify(old) === JSON.stringify(newObj)) {
                return old || null
            } else {
                return newObj || null
            }
        })
    }, [isAllView, searchDept])

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const onOuterViewItemClick = useCallback(() => {
        setCurrentId(null)
        setFocusId(null)
    }, [])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, setLastSearchParams })

    const dateOptions = useGetDateOptions()

    const { options, dataGridList } = useGetTreeList({
        fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
        columnsAppendParams: { setCurrentId, setShowFullScreen },
        optionsAppendParams: {
            sort: sortConfig,
            onSort: (data, sort) => {
                setSortConfig(sort)
                const sortList = _.sortBy(data, x => x[sort.column])
                return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
            }
        },
    })

    useEffect(() => {
        if (dataGridList) {
            setCanCheckAllView(true)
        }
    }, [dataGridList])

    useEffect(() => {
        if (!canCheckAllView) return
        if (isAllView) return setCanCheckAllView(false)
        if (_.isNil(currentId) || !_.isEmpty(dataGridList)) {
            setCurrentId(_.get(_.head(dataGridList), 'id'))
            setFocusId(_.get(_.head(dataGridList), 'id'))
        }
        setCanCheckAllView(false)
    }, [dataGridList, currentId, isAllView, canCheckAllView])

    const showList = useMemo(() => {
        let showList = dataGridList || []
        if (!_.isNil(sortConfig)) {
            showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
        }
        return showList
    }, [sortConfig, dataGridList])

    const currentIndex = useMemo(() => {
        if (_.isNil(currentId)) return 0
        return _.findIndex(showList, x => _.get(x, 'id') === currentId)
    }, [currentId, showList])

    const switchCurrentItem = useCallback((switchDirection = 'next') => {
        setCurrentId(() => {
            const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
            setFocusId(newId)
            setCurrentId(newId)
            return newId
        })
    }, [showList, currentIndex])

    const findAddTemplate = useCallback((type, date = moment(), templateValue) => {
        setTemplateFlag(false)
        setDateSelected(moment(date))
        const beginDate = _.includes(['day', 'experience'], type) ? moment(date).format('YYYY-MM-DD') :
            moment(date).startOf('week').format('YYYY-MM-DD')
        const endDate = _.includes(['day', 'experience'], type) ? moment(date).format('YYYY-MM-DD') :
            moment(date).endOf('week').format('YYYY-MM-DD')
        if (!isNil(templateValue)) {
            setValue(templateValue)
            setInitValue(templateValue)
            updateMarkdown()
            setTemplateParams(null)
            return
        }
        const postParams = {
            beginDate,
            endDate,
            type
        }
        setSubmitLoading(true)
        getAddTemplate('/staff/report/template', postParams).then(res => {
            setInitValue(res)
            updateMarkdown()
            setTemplateParams(null)
            setSubmitLoading(false)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setSubmitLoading(false)
            setTemplateParams(null)
        })
    }, [getAddTemplate])


    // 检查改日期是否填写周报/日报
    const checkReport = useCallback((type, date = moment(), mode, id) => {
        const beginDate = _.includes(['day', 'experience'], type) ? moment(date).format('YYYY-MM-DD') :
            moment(date).startOf('week').format('YYYY-MM-DD')
        const endDate = _.includes(['day', 'experience'], type) ? moment(date).format('YYYY-MM-DD') :
            moment(date).endOf('week').format('YYYY-MM-DD')
        const postParams = {
            beginDate,
            endDate,
            type
        }
        if (mode === 'detail' && !_.isNil(id)) return setCurrentId(id)
        checkReportPost('/staff/report/check', postParams).then(res => {
            if (_.isNil(res)) {
                setTemplateParams({ type, date })
                setTemplateFlag(true)
            } else {
                setMode('edit')
                if (currentId === _.get(res, 'id')) {
                    refreshDetail()
                } else {
                    setCurrentId(_.get(res, 'id'))
                    setFocusId(_.get(res, 'id'))
                }
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [checkReportPost, currentId, refreshDetail])

    const onCheckDate = useCallback((type, date, mode = 'add', id) => {
        setAddType(type)
        setMode(mode)
        setShowFullScreen(true)
        checkReport(type, date, mode, id)
        setCheckDateType(null)
    }, [checkReport])

    useEffect(() => {
        if (createDate) {
            onCheckDate(createMode, createDate, initMode, initId)
        }
    }, [createDate, createMode, onCheckDate, initMode, initId])

    const AddTypeSelect = useCallback(() => {
        return <div className={'daily-report-type-add-group flex-y'}>
            <div className="daily-report-type-add-item flex center-y" onClick={() => {
                setCheckDateType('day')
                setShowAddType(false)
            }}>
                日报
            </div>
            <div className="daily-report-type-add-item flex center-y" onClick={() => {
                setCheckDateType('week')
                setShowAddType(false)
            }}>
                周报
            </div>
            <div className="daily-report-type-add-item flex center-y" onClick={() => {
                setInitValue(experienceTemplate)
                setAddType('experience')
                setMode('add')
                setShowFullScreen(true)
                setShowAddType(false)
                setDateSelected(moment())
            }}>
                心得体会
            </div>
        </div>
    }, [experienceTemplate])

    // const onDateChange = useCallback((date)=>{
    //     // if(mode === 'add' && type !== 'experience'){
    //     //     if(replaceEnter(initAddTemplate) === replaceEnter(value)){
    //     //         findAddTemplate(type, date)
    //     //     }
    //     // }
    //     if(type !== 'experience'){
    //         checkReport(type, date)
    //     }
    //     setDateSelected(date)
    //
    //     // function replaceEnter(str = ''){
    //     //     return _.replace(str,/\n|\r/g, '')
    //     // }
    // },[type, checkReport])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <TextIconBtn icon='xiazai2' text='导出' disabled={_.isEmpty(dataGridList)} onClick={() => API1.downloadPost('/staff/report/export', lastSearchParams)} />
            <Popover content={<AddTypeSelect />} trigger="click" placement="bottomRight" open={showAddType} onOpenChange={setShowAddType}>
                <TextIconBtn text='填写' icon='bianji2' onClick={() => setShowAddType(x => !x)} />
            </Popover>
        </div>
    }, [showAddType, lastSearchParams, dataGridList])

    const onRowClick = useCallback((item) => {
        _.forEach(dataGridList, o => {
            return o._rowClass = item?.id === o?.id ? 'select_row' : ''
        })
        forceUpdate()
    }, [dataGridList])

    useEffect(() => {
        if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    const submit = useCallback(() => {
        if (submitLoading) return
        setSubmitLoading(true)

        const beginDate = _.includes(['day', 'experience'], type) ? moment(dateSelected).format('YYYY-MM-DD') :
            moment(dateSelected).startOf('week').format('YYYY-MM-DD')
        const endDate = _.includes(['day', 'experience'], type) ? moment(dateSelected).format('YYYY-MM-DD') :
            moment(dateSelected).endOf('week').format('YYYY-MM-DD')
        let submitParams = {
            beginDate: beginDate,
            endDate: endDate,
            title: title,
            content: value,
            type: type
        }
        if (mode === 'edit') {
            submitParams['id'] = currentId
        }
        const url = mode === 'add' ? '/staff/report/add' : '/staff/report/edit'
        submitPost(url, submitParams).then(newId => {
            history.replace({ pathname: '/staffReportMgt' })
            Messager.show(mode === 'add' ? '添加成功' : '修改成功', { icon: 'success' })
            setSubmitLoading(false)
            refreshList()
            if (mode === 'add') {
                setCurrentId(newId)
                setFocusId(newId)
            } else {
                refreshDetail()
            }
            setMode('detail')
            addContentTemplateNum(templateItem)
            // if(mode === 'add'){
            //     setShowFullScreen(false)
            // }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setSubmitLoading(false)
        })
    }, [type, title, dateSelected, submitLoading, refreshList, value, currentId, refreshDetail, mode, submitPost, addContentTemplateNum, templateItem, history])

    const canEdit = useMemo(() => {
        return _.get(initDetail, 'createUser') === userAccount
    }, [initDetail, userAccount])

    const templateChange = useCallback((v, item, flag) => {
        if (flag) setOpenItem(item)
        else {
            const { type, date } = templateParams || {}
            setMode('add')
            findAddTemplate(type, date, v)
        }
    }, [findAddTemplate, templateParams])

    const getWorkTimeContent = useCallback(() => {
        setSubmitLoading(true)
        const searchParams = {
            penetrationBeginDate: moment(dateSelected).startOf('week').format('YYYY-MM-DD'),
            penetrationEndDate: moment(dateSelected).endOf('week').format('YYYY-MM-DD'),
        }
        getWorkTime('/staff/report/getWorkHoursReporting', searchParams).then(res => {
            setValue(res)
            setInitValue(res)
            updateMarkdown()
            setSubmitLoading(false)
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setSubmitLoading(false)
        })
    }, [getWorkTime, dateSelected])

    return <div className={'daily-report-mgt fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading} outerSetViewList={setViewList}
            {...{
                getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig, onOuterViewItemClick,
                currentViewId, setCurrentViewId, params, pageSize, replaceParams
            }} />
        <Box title={'工作报告列表'} className='flex-y x-card-singlegrid' data={dataGridList} extra={extra}>
        </Box>
        <div className={'two-columns-wrap flex'}>
            {
                !isAllView &&
                <div className="left-panel flex-y">
                    {
                        boxLoading && <Loader fill />
                    }
                    <div className="list-area flex-y">
                        {
                            _.map(dataGridList, item => {
                                return <div className={clsx('list-item flex center-y', { active: item.id === focusId })} key={item.id} onClick={() => {
                                    setFocusId(item.id)
                                    setCurrentId(item.id)
                                }}>
                                    {
                                        _.get(item, _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === ENTER_DETAIL_FIELD), 'columnId'))
                                    }
                                </div>
                            })
                        }
                        {
                            _.isEmpty(dataGridList) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>
                    <div className="pagination-area flex center">
                        <Pagination pageSize={pageSize} total={total} current={pageNum} size="small" showSizeChanger={false}
                            onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
                    </div>
                </div>
            }
            {
                isAllView &&
                <div className="left-panel flex-y">
                    <ResourceDeptSearch changeDept={setSearchDept} isGetInitDept={false} />
                </div>
            }
            {
                !isAllView &&
                <div className="right-panel flex-y">
                    {
                        (detailLoading || submitLoading) && <Loader fill />
                    }
                    {
                        !_.isEmpty(dataGridList) &&
                        <div className="right-panel-header flex center-y">
                            <div className="right-panel-header-title flex center-y">
                                <div className="title">
                                    {title}
                                </div>
                            </div>
                            <div className="right-panel-header-handle flex center-y">
                                {
                                    (mode === 'add' || (!isDetail && canEdit)) &&
                                    <ContentTemplateSelect
                                        type={'1'}
                                        isDefault={templateFlag}
                                        objectType={_.get(defaultObjectType, type)}
                                        onChange={templateChange}
                                    />
                                }
                                {
                                    (mode !== 'add' && !_.isNil(currentId)) &&
                                    <DailyReportComment
                                        funcCode={FUNC_CODE}
                                        referenceId={currentId}
                                        defaultParams={{
                                            title: `[${_.get(defaultDateName, type)}]评论提醒`,
                                            optIds: [_.get(initDetail, 'createUser')],
                                            userName,
                                            currentItem: [
                                                `${_.get(defaultDateName, type)}：${convertOptions(_.get(initDetail, 'createUser'), allUserRes, 'userName', 'userAccount')}的[${getCommentDate(initDetail || {})}]${_.get(defaultDateName, type)}`
                                            ],
                                            cardUrl: `/staffReportMgt?${strParams({ createDate: dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(initDetail, 'createTime'))), createMode: _.get(initDetail, 'type'), initMode: 'detail', initId: currentId })}`
                                        }}
                                    />
                                }
                                {
                                    canEdit &&
                                    <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'}
                                        onClick={() => {
                                            setMode(old => old === 'detail' ? 'edit' : 'detail')
                                            setValue(_.get(initDetail, 'content'))
                                            setInitValue(_.get(initDetail, 'content'))
                                            setTemplateItem(null)
                                        }} />
                                }
                                {
                                    ((mode === 'edit' && canEdit) || mode === 'add') && type === 'week' && workTimeAuth &&
                                    <Button primary onClick={getWorkTimeContent} style={{ width: 90 }}>拉取工时内容</Button>
                                }
                                {
                                    mode !== 'detail' &&
                                    <Button primary onClick={submit}>提交</Button>
                                }
                            </div>
                        </div>
                    }
                    {
                        !_.isEmpty(dataGridList) &&
                        <div className="markdown-area-wrap" key={countMarkdown}>
                            <MarkdownEditor ref={markdownRef} onChange={setValue} initValue={initValue} readOnly={isDetail} />
                        </div>
                    }
                    {
                        _.isEmpty(dataGridList) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </div>
            }
            {
                isAllView &&
                <div className="right-panel flex-y">
                    <DataGrid option={options} data={dataGridList} onRowClick={onRowClick} loading={boxLoading} error={error} />
                    <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, { pageNum, pageSize }))
                        }} />
                </div>
            }
        </div>
        {
            showFullScreen &&
            <div className={'full-screen flex-y'}>
                <div className="full-screen-wrap flex-y">
                    {
                        ((mode !== 'add' && detailLoading) || submitLoading) && <Loader fill />
                    }
                    <div className="full-screen-header flex center-y">
                        <div className="full-screen-header-left flex center-y">
                            <div className="title-wrap">
                                <div className={'title'}>{title}</div>
                            </div>
                            {/*{*/}
                            {/*    mode !== 'detail' &&*/}
                            {/*    <div className="select-time flex center-y">*/}
                            {/*        日期：*/}
                            {/*        <DatePicker value={dateSelected} onChange={onDateChange} picker={_.includes(['day','experience'],type)? 'date': "week"} allowClear={false}/>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        {
                            (mode === 'detail' && !_.isEmpty(showList)) &&
                            <div className="footer-switch flex center-y">
                                <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (detailLoading) return
                                        if (!_.includes([-1, 0], currentIndex)) {
                                            switchCurrentItem('previous')
                                        }
                                    }}>
                                    <Icon name='xiangqian' />&nbsp;上一个
                                </div>
                                <div className={`footer-switch-item center ${currentIndex === showList?.length - 1 ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (detailLoading) return
                                        if (!(currentIndex === showList?.length - 1)) {
                                            switchCurrentItem('next')
                                        }
                                    }}>
                                    下一个&nbsp;<Icon name='xianghou' />
                                </div>
                                <div className="list-num">
                                    {currentIndex + 1}/{showList?.length}
                                </div>
                            </div>
                        }
                        <div className="handle-group flex center-y">
                            {
                                (mode === 'add' || (!isDetail && canEdit)) &&
                                <ContentTemplateSelect
                                    type={'1'}
                                    isDefault={templateFlag}
                                    objectType={_.get(defaultObjectType, type)}
                                    onChange={templateChange}
                                />
                            }
                            {
                                (mode !== 'add' && !_.isNil(currentId)) &&
                                <DailyReportComment
                                    funcCode={FUNC_CODE}
                                    referenceId={currentId}
                                    defaultParams={{
                                        title: `[${_.get(defaultDateName, type)}]评论提醒`,
                                        optIds: [_.get(initDetail, 'createUser')],
                                        userName,
                                        currentItem: [
                                            `${_.get(defaultDateName, type)}：${convertOptions(_.get(initDetail, 'createUser'), allUserRes, 'userName', 'userAccount')}的[${getCommentDate(initDetail || {})}]${_.get(defaultDateName, type)}`
                                        ],
                                        cardUrl: `/staffReportMgt?${strParams({ createDate: dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(initDetail, 'createTime'))), createMode: _.get(initDetail, 'type'), initMode: 'detail', initId: currentId })}`
                                    }}
                                />
                            }
                            {
                                mode !== 'add' && canEdit &&
                                <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'}
                                    onClick={() => {
                                        setMode(old => old === 'detail' ? 'edit' : 'detail')
                                        setValue(_.get(initDetail, 'content'))
                                        setInitValue(_.get(initDetail, 'content'))
                                        setTemplateItem(null)
                                    }} />
                            }
                            {
                                ((mode === 'edit' && canEdit) || mode === 'add') && type === 'week' && workTimeAuth &&
                                <Button primary onClick={getWorkTimeContent} style={{ width: 90 }}>拉取工时内容</Button>
                            }
                            {
                                mode !== 'edit' &&
                                <Button onClick={() => {
                                    setAddType(null)
                                    setMode('detail')
                                    setShowFullScreen(false)
                                    setDateSelected(moment())
                                    refreshDetail()
                                    history.replace({ pathname: '/staffReportMgt' })
                                }} normal>关闭</Button>
                            }
                            {
                                mode !== 'detail' &&
                                <Button primary onClick={submit}>提交</Button>
                            }
                        </div>
                    </div>
                    <div className={`full-screen-markdown-wrap ${mode}`} key={countMarkdown}>
                        <MarkdownEditor ref={fullScreenMarkdownRef} onChange={setValue} initValue={initValue} readOnly={isDetail} />
                    </div>
                </div>
            </div>
        }
        {
            !_.isNil(checkDateType) &&
            <DailyReportSelectDateDialog close={() => setCheckDateType(null)} onCheckDate={onCheckDate} type={checkDateType} />
        }
        {
            !_.isNil(openItem) &&
            <EditTipLog
                item={openItem}
                close={() => setOpenItem(null)}
                onChange={(v, item) => {
                    setValue(v)
                    setInitValue(v)
                    setTemplateItem(item)
                    updateMarkdown()
                }}
            />
        }
    </div>
}

export default DailyReportMgt;
import React from 'react'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../../../../../components'
import convertOptions from '../../../../../../common/ConvertOptions'

const { str14ToDate } = toDate

function getOptFlag(v) {
  if (v === 'ADD') return '增加'
  else if (v === 'EDIT') return '编辑'
  else if (v === 'DEL') return '删除'
  else return v
}

export function getColumnsUntreated(props) {
  const { setOpen, setApplyId, allUserRes, setShowDetail } = props
  return [
    { selection: true },
    // { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '编号', bind: 'storyId', sortable: true, width: 145, tooltip: true },
    {
      header: '标题', bind: 'title', sortable: true, width: 200, tooltip: true, convert: (v, o) => {
        const type = o['type']
        return <div style={{ color: '#5477ff', cursor: 'pointer' }} onClick={() => {
          if (type === 'req') setShowDetail({
            type: 'REQ',
            id: o.storyIssueId
          })
          else if (type === 'issue') setShowDetail({
            type: 'ISSUE',
            id: o.storyIssueId
          })
        }}>
          {v}
        </div>
      }
    },
    { header: '操作动作', bind: 'optFlag', sortable: true, width: 90, tooltip: true, convert: getOptFlag },
    { header: '申请人', bind: 'operUser', sortable: true, width: 100, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      header: '申请时间', bind: 'optTime', sortable: true, width: 140, tooltip: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    },
    { header: '申请理由', bind: 'optMemo', sortable: true, width: 140, tooltip: true },
    { header: '操作', sortable: true, width: 140, convert: operation },
  ]

  function operation(v, o) {
    return <div className='flex req-operation'>
      <div className='agree' onClick={() => { setOpen('agree'); setApplyId([o.id]) }}>同意<Icon name='chenggong' /></div>
      <div className='reject' onClick={() => { setOpen('reject'); setApplyId([o.id]) }}>拒绝<Icon name='shibai' /></div>
    </div>
  }
}

export function getColumnsOther(props) {
  const { allUserRes, setShowDetail } = props
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '编号', bind: 'storyId', sortable: true, width: 160, tooltip: true },
    {
      header: '标题', bind: 'title', sortable: true, width: 200, tooltip: true, convert: (v, o) => {
        const type = o['type']
        return <div style={{ color: '#5477ff', cursor: 'pointer' }} onClick={() => {
          if (type === 'req') setShowDetail({
            type: 'REQ',
            id: o.storyIssueId
          })
          else if (type === 'issue') setShowDetail({
            type: 'ISSUE',
            id: o.storyIssueId
          })
        }}>
          {v}
        </div>
      }
    },
    { header: '操作动作', bind: 'optFlag', sortable: true, width: 90, tooltip: true, convert: getOptFlag },
    { header: '申请人', bind: 'operUser', sortable: true, width: 100, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      header: '申请时间', bind: 'optTime', sortable: true, width: 140, tooltip: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    },
    { header: '申请理由', bind: 'optMemo', sortable: true, width: 140, tooltip: true },
    { header: '审核人', bind: 'auditOptId', sortable: true, width: 140, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    {
      header: '动作', bind: 'auditFlag', sortable: true, width: 100, tooltip: true, convert: v => {
        if (v === 'N') return <div className='flag-reject'>拒绝<Icon name='shibai' /></div>
        if (v === 'Y') return <div className='flag-agree'>同意<Icon name='chenggong' /></div>
        return '-'
      }
    },
    {
      header: '审核时间', bind: 'auditTime', sortable: true, width: 140, tooltip: true,
      convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v))
    },
    { header: '理由', bind: 'auditMemo', sortable: true, width: 100, tooltip: true },
  ]
}

/*
"id":"9480",                //类型：Mixed  必有字段  备注：ID
            "releaseId":"mixed",                //类型：Mixed  必有字段  备注：版本ID
            "storyId":"REQ_NEW_130627004",                //类型：String  必有字段  备注：需求ID
            "title":"基金席位，需要开关控制，启用席位1后其他席位自动为不启用",                //类型：String  必有字段  备注：标题
            "type":"req",                //类型：Mixed  必有字段  备注：需求、issue标识 req-需求，issue-issue
            "auditType":"0",                //类型：Mixed  必有字段  备注：类型标识 0-无需审核，1-需要审核
            "operUser":"maxiaoxia",                //类型：String  必有字段  备注：操作人
            "optFlag":"EDIT",                //类型：String  必有字段  备注：操作动作 Add-增加，Del-删除，Edit-编辑
            "optTime":"20220602000000",                //类型：String  必有字段  备注：操作时间
            "optMemo":"她加的",                //类型：String  必有字段  备注：备注
            "auditOptId":"sunlinman",                //类型：String  必有字段  备注：审核人
            "auditFlag":"U",                //类型：Mixed  必有字段  备注：审核标识 U-未审核，N-不通过，Y-通过
            "auditTime":"20220602000000",                //类型：Mixed  必有字段  备注：审核时间
            "auditMemo":"复核通过",                //类型：Mixed  必有字段  备注：审核备注
            "releaseStatus":"C",                //类型：Mixed  必有字段  备注：版本状态
            "status":"S5",                //类型：String  必有字段  备注：状态
            "inRelease":"Y",                //类型：String  必有字段  备注：是否在版本
            "beginDate":"mixed",                //类型：Mixed  必有字段  备注：无
            "endDate":"mixed"                //类型：Mixed  必有字段  备注：无
*/
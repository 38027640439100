import React from 'react';
import './WorkFlowGraphDialog.scss'
import WorkFlowGraph from "./WorkFlowGraph";
import {Modal} from 'antd'

function WorkFlowGraphDialog(props) {
    const {initData, close, currentNodeName} = props
    return <Modal title={'工作流'} visible={true} width={'80%'} footer={null} className={'work-flow-graph-dialog'} confirmButtonVisible={false} onCancel={close} cancelButtonText={'关闭'}>
        <WorkFlowGraph {...{initData, currentNodeName}}/>
    </Modal>
}

export default WorkFlowGraphDialog;
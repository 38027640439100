import React, { useMemo, useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { Dialog, Button, DataGrid, Pagination } from 'rootnet-ui'
import { Form, FormInput, Select, CheckBox } from 'rootnet-edit'
import { useGet } from '../../../../../../../utils/hook'
import { strParams } from '../../../../../../../utils/publicFun'
import { Icon } from '../../../../../../../components'
import { selectOption } from '../../../../../../common/commonMethod'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import { Box } from '../../../../../../common/commonComponent'
import './index.scss'

const DEMAND_AND_TRACKING_URL = '/test_case/selectconditionsinformation' // 研发任务展示信息
const DEFAULT_LIST_URL = '/test/common/get/operations'
const OPTIONS_URL = [//研发任务
  '/common/globalconst?globalConst=tracerstate', // 状态
  '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C', //版本计划
  '/common/userinfo?indexValidFlag=1', // 测试人员
]

const queryCondition = [
  { label: '最近浏览', key: 0 },
  { label: '全部', key: 1, params: { branch: 0 } },
  { label: '我负责的', key: 2, params: { branch: 1 } },
  { label: '进行中的', key: 3, params: { branch: 2 } },
  { label: '已完成的', key: 4, params: { branch: 3 } },
]

const getOption = ({ selectedList }) => {
  const idField = 'tracerId'
  const selectedIdList = _.map(selectedList, x => x._id || x[idField])
  return {
    autoFill: true,
    virtualized: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
    columns: [
      { width: 40, algin: 'center', convert: (v, o) => <CheckBox value={_.includes(selectedIdList, _.get(o, idField))} /> },
      { header: 'ID', bind: 'tracerId', tooltip: true, width: 120 },
      { header: '标题', bind: 'tracerTitle', tooltip: true, width: 230 },
      {
        header: '状态',
        bind: 'tracerState',
        tooltip: true,
        width: 90,
        convert: getStatus
      },
    ],
    onRenderRow: o => {
      const isCurrent = _.includes(selectedIdList, _.get(o, idField))
      return {
        style: { backgroundColor: isCurrent ? '#EDF1FF' : '' },
      }
    },
  }
  function getStatus(v, o) {
    return `${v}-${o.ConstDisplayName}`
  }
}

function getInitParams(pageSize = 20) {
  return {
    pageNum: 1,
    pageSize: pageSize,
  }
}

const INIT_PARAMS = {
  idOrTitle: null,
  status: null,
  versionId: null,
}

export default function AssociatedTrackingLog(props) {
  const { close, trackingData, submit, delTrack } = props
  const [params, setParams] = useState()
  const [active, setActive] = useState(0)
  const [listData, setListData] = useState()
  const [selectedList, setSelectedList] = useState(trackingData)
  const { data: defaultData, doFetch: getFetch, loading: defaultLoading, error: defaultError } = useGet()
  const { data, loading, error, doFetch } = useGet()

  const { pageSize, total, pageNum, rows } = useMemo(() => data || {}, [data])

  const refresh = useCallback((parameter) => {
    const par = _.assign({}, parameter,
      parameter?.status && { status: _.join(parameter?.status, ',') })
    doFetch(`${DEMAND_AND_TRACKING_URL}?${strParams({ type: 'Trace', ...par })}`)
  }, [doFetch])

  useEffect(() => {
    if (active === 0) getFetch(`${DEFAULT_LIST_URL}?type=06`)
  }, [active, getFetch])

  useEffect(() => {
    if (active === 0) {
      setListData(_.map(_.compact(defaultData), item => ({
        tracerId: item.tracerId,
        tracerTitle: item.tracerTitle,
        tracerState: item.tracerState,
        ConstDisplayName: item.tracerStateName,
      })))
    }
    else if (!_.isEmpty(rows)) setListData(rows)
    else setListData([])
  }, [active, defaultData, rows])

  const onRowClick = useCallback(o => {
    const idField = 'tracerId'
    const currentId = _.get(o, idField)
    const selectedIdList = _.map(selectedList, x => x._id)
    if (_.includes(selectedIdList, currentId)) {
      setSelectedList(oldList => _.filter(oldList, item => item._id !== currentId))
    } else {
      setSelectedList(oldList => ([...oldList, { _id: currentId, ...o }]))
    }
  }, [selectedList])

  const option = useMemo(() => {
    return getOption({ selectedList })
  }, [selectedList])

  const confirm = useCallback(() => {
    const addList = _.filter(selectedList, o => !_.includes(_.map(trackingData, o => o.tracerId), o.tracerId))
    const delList = _.filter(trackingData, o => !_.includes(_.map(selectedList, o => o.tracerId), o.tracerId))
    if (!_.isEmpty(addList)) submit(_.map(addList, o => o.tracerId))
    if (!_.isEmpty(delList)) delTrack(_.map(delList, o => o.id))
    close()
  }, [close, delTrack, selectedList, submit, trackingData])

  return (
    <Dialog
      header='关联研发任务'
      cancel={close}
      confirm={confirm}
      className='associated-tracking-log'
    >
      <SelectQuery {...{ setParams, refresh, pageSize, active, setActive }} />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 8, overflow: 'hidden' }}>
        <Options {...{ pageSize, refresh, params, setParams }} />
        <div className='flex' style={{ flex: 1 }}>
          <div className='query-list'>
            <Box
              data={[0]}
              className='x-card-singlegrid'
              error={active === 0 ? defaultError : error}
              loading={active === 0 ? defaultLoading : loading}
            >
              <DataGrid
                className='x-datagrid'
                option={option}
                data={listData}
                onRowClick={onRowClick}
              />
              {
                active !== 0 && <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
                  onChange={(pageNum, pageSize) => refresh(_.assign({}, params, { pageNum, pageSize }))} />
              }
            </Box>
          </div>

          <div className='selected-list'>
            <div>已选 {_.size(selectedList)} 项</div>
            <ul>
              {
                _.map(selectedList, o => {
                  return <Tooltip key={o.tracerId} title={o.tracerTitle}>
                    <li>
                      <span>{o.tracerTitle}</span>
                      <span><Icon name='baocuo' onClick={() => delSelect(o)} /></span>
                    </li>
                  </Tooltip>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </Dialog>
  )

  function delSelect(o) {
    const delData = _.filter(selectedList, item => item.tracerId !== o.tracerId)
    setSelectedList(delData)
    setListData([...listData])
  }

}

function Options(props) {
  const { refresh, params, setParams, pageSize, active } = props
  const { data } = useGet(OPTIONS_URL)

  const [statusOpt, versionOpt, testManOpt] = useMemo(() => {
    if (data) {
      const [d1, d2, d3] = data
      return [
        selectOption(d1, ['interiorId,displayName', 'interiorId']),
        convertGlobalConstOptions(d2),
        selectOption(d3, ['userName', 'userAccount', 'userName,userAccount'])
      ]
    }
    return []
  }, [data])

  return <div className={'customize-options'}>
    <div className='c-options'>
      <Form value={params} onChange={setParams}>

        <FormInput label='搜索ID或标题' className='searchIconInput' prefix={<Icon name='sousuo1' />} bind='idOrTitle' placeholder='搜索ID或标题' />
        <FormInput label='版本计划' bind='versionId' component={Select} options={versionOpt} search clear componentWidth={150} />
        <FormInput label='状态' bind='status' component={Select} options={_.orderBy(statusOpt, 'value', 'asc')} multiple search clear componentWidth={150} />
        <FormInput label='测试人员' bind='testMan' component={Select} options={testManOpt} search clear componentWidth={150} />
      </Form>
      <div className="search-btn-group flex">
        <Button
          primary
          className={'search-btn'}
          disable={active === 0}
          onClick={() => refresh({ ...params, ...getInitParams(pageSize) })}>查询</Button>
        <Button disable={active === 0} className={'reset-btn'} text onClick={() => {
          refresh(_.assign(getInitParams(pageSize), _.pick(params, ['branch'])))
          setParams(x => ({ ...INIT_PARAMS, ..._.pick(x, ['branch']) }))
        }}>重置</Button>
      </div>
    </div>
  </div >
}

function SelectQuery(props) {
  const { setParams, refresh, pageSize, active, setActive } = props

  const parameter = useMemo(() => queryCondition, [])

  return <div className='query-condition'>
    {
      _.map(parameter, item => {
        return <div key={item.key}>
          <span
            className={active === item.key ? 'active' : ''}
            onClick={() => handClick(item)}
          >
            {item.label}
          </span>
        </div>
      })
    }
  </div>

  function handClick(data) {
    setActive(data.key)
    if (data.key === 0) return setParams({})
    refresh({ ...data.params, ...getInitParams(pageSize) })
    setParams(_.assign({}, data.params, INIT_PARAMS, { testMan: null }))
  }

}
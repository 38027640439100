import React, { useCallback, useMemo, useState, useReducer } from 'react'
import _ from 'lodash'
import { Tabs } from 'antd'
import { Messager } from 'rootnet-ui'
import getColumn from './allColumns'
import WorkTimeReviewAndSearchList from '../components/workTimeReviewAndSearchList'
import { TextIconBtn } from '../../common/TextIconBtn'
import './index.scss'

const initParams = {
  typeFlag: 'selectRecordPage',
  pageNum: 1,
  pageSize: 20,
}

const title = <div className='header flex'>
  <div className='title'>工时审核</div>
  <div className='hint'>逾期未处理（员工提交后7个工作日），系统将自动确认，请及时处理待办！</div>
</div>

export default function WorkTimeReview() {
  const [activeTab, setActiveTab] = useState('checkPending')
  const [selectItems, setSelectItems] = useState([])
  const [currentInfo, setCurrentInfo] = useState()
  const [checkedSelectItems, setCheckedSelectItems] = useState([])
  const [checkedCurrentInfo, setCheckedCurrentInfo] = useState()

  const [countCheckPending, upDateCheckPending] = useReducer((x) => x + 1, 0)
  const [countChecked, updateChecked] = useReducer((x) => x + 1, 0)

  const handClick = useCallback((type) => {
    if (activeTab === 'checkPending') {
      if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作！', { icon: 'error' })
      const ids = _.map(selectItems, 'id')
      setCurrentInfo({ mode: type, ids, list: selectItems })
    } else {
      if (_.isEmpty(checkedSelectItems)) return Messager.show('请选择数据后在进行操作！', { icon: 'error' })
      const ids = _.map(checkedSelectItems, 'id')
      setCheckedCurrentInfo({ mode: type, ids, list: checkedSelectItems })
    }
  }, [activeTab, selectItems, checkedSelectItems])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        activeTab === 'checkPending' &&
        <div className='flex'>
          <TextIconBtn text='转移处理人' icon='bianji' onClick={() => handClick('transferHandler')} />
          <TextIconBtn text='接受' icon='jieshou1' onClick={() => handClick('accept')} />
          <TextIconBtn text='拒绝' icon='jujue1' onClick={() => handClick('refuse')} />
        </div>
      }
      <TextIconBtn text='导出' icon='daochu' onClick={() => {
        if (activeTab === 'checkPending') setCurrentInfo({ mode: 'export' })
        else setCheckedCurrentInfo({ mode: 'export' })
      }} />
    </div >
  }, [handClick, activeTab])

  return (
    <Tabs
      className='work-time-review-tabs'
      activeKey={activeTab}
      onChange={v => {
        setActiveTab(v)
        setSelectItems([])
        if (v === 'checkPending') upDateCheckPending()
        else if (v === 'checked') updateChecked()
      }}
      items={[
        {
          key: 'checkPending',
          label: '待审核',
          children: <React.Fragment key={countCheckPending}>
            <WorkTimeReviewAndSearchList
              exportTitle='工时审核'
              title={title}
              initParams={_.assign({ type: 'audit' }, initParams)}
              {...{ extra, getColumn, selectItems, setSelectItems, currentInfo, setCurrentInfo }}
            />
          </React.Fragment>
        },
        {
          key: 'checked',
          label: '已审核',
          children: <React.Fragment key={countChecked}>
            <WorkTimeReviewAndSearchList
              title={title}
              exportTitle='工时审核'
              initParams={_.assign({ type: 'audited' }, initParams)}
              {...{ extra, getColumn, selectItems: checkedSelectItems, setSelectItems: setCheckedSelectItems, currentInfo: checkedCurrentInfo, setCurrentInfo: setCheckedCurrentInfo }}
            />
          </React.Fragment>
        }
      ]}
    />

  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DataGrid, Messager, Button, Tooltip } from 'rootnet-ui'
import { Form, FormInput, Input, Select } from 'rootnet-edit'
import './TrackingListDialog.scss'
import Icon from "../../../../project_share/components/Icon";
import { useApi } from "../../../../utils/hook";
import _ from 'lodash'
import './RadioDialog.scss'
import { SearchBox } from "../common/commonfunc";
import WaterMarkDialog from "../../../common/WaterMarkDialog";

const HFormInput = (props) => {
    return <FormInput horizontal componentWidth={120} labelWidth={100} type='number' digit={1} {...props} />;
}

const getTableOption = () => {
    return {
        autoFill: true,
        columns: [
            { selection: true },
            { header: '角色', bind: 'tracerRole', sortable: true },
            {
                header: '研发任务ID/名称',
                bind: 'tracerId',
                convert: (v, o) => {
                    return <div className={'flex center center-y'}>
                        {
                            o.tracerRole === "版本测试" && !_.isNil(o.releaseId) &&
                            <span className={'release-id'}>{o.releaseId}</span>
                        }
                        {`${o.tracerId}-${o.tracerTitle}`}
                    </div>
                },
                width: 600,
                tooltip: true, sortable: true
            },
            {
                header: '计划用时(h)',
                bind: 'planTime',
                align: 'right',
                convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : '',
                width: 120, sortable: true
            },
            {
                header: '累计投入(h)',
                bind: 'sumUseTime',
                align: 'right',
                convert: (v) => v ? parseFloat(Number((v / 60).toFixed(1))) : '',
                width: 120, sortable: true
            },
        ]
    }
}
const INIT_FORM_DATA = {
    auditUseTime: null,
    completionRatio: null
}

// 研发任务弹窗
function TrackingListDialog(props) {
    const { close, date, refresh, usedTime, cardDetail, beforeCutoffDay, isSelf, searchUser, showCutoffDay } = props
    const isAdd = useMemo(() => _.isNil(cardDetail), [cardDetail])
    const initFormData = useMemo(() => isAdd ? INIT_FORM_DATA : { auditUseTime: cardDetail.auditUseTime / 60 }, [isAdd, cardDetail])
    const dateNoLine = useMemo(() => date.replace(/-/g, ''), [date])
    const [searchText, setSearchText] = useState('')
    const [selectedList, setSelectList] = useState([])
    const [formData, setFormData] = useState(initFormData)
    const [error, setError] = useState(null)
    const { doFetch } = useApi()
    const [addTracerId, setAddTracerId] = useState(null)
    const [showAddDialog, setShowAddDialog] = useState(false)
    const [noBox, setNoBox] = useState(false)
    const [list, setList] = useState([])
    const [showContinueDialog, setShowContinueDialog] = useState(false)
    const [countTime, setCountTime] = useState(0)
    const [filterRoleList, setFilterRoleList] = useState([])

    const option = useMemo(() => {
        return getTableOption({});
    }, []);

    useEffect(() => {
        if (selectedList.length === 0) return
        setFormData(x => _.assign({}, x, { completionRatio: _.isNil(selectedList[0].completionRatio) ? null : selectedList[0].completionRatio * 100 }))
    }, [selectedList])

    useEffect(() => {
        let auditUseTime = _.toNumber(_.get(formData, 'auditUseTime'))
        if (auditUseTime <= 0) {
            setError(x => _.assign({}, x, { 'auditUseTime': '实际用时需大于0' }))
            return
        }
        if (!_.isNil(cardDetail)) {
            auditUseTime = auditUseTime - cardDetail.auditUseTime / 60
        }
        if (!_.isNil(auditUseTime) && ((auditUseTime * 60 + usedTime + countTime * 60) > 8 * 60)) {
            setError(x => _.assign({}, x, { 'auditUseTime': '每天用时不能大于8小时' }))
            // return
        }

    }, [formData, usedTime, selectedList, cardDetail, countTime])

    useEffect(() => {
        const completionRatio = _.get(formData, 'completionRatio')
        if (!_.isNil(completionRatio) && completionRatio.length !== 0 && _.toNumber(completionRatio) <= 0) {
            setError(x => _.assign({}, x, { 'completionRatio': '完成比例需大于0' }))
            // return
        }
    }, [formData])

    useEffect(() => {
        if (_.some([dateNoLine], _.isNil)) return
        doFetch(`/worktime/tracer/detail?jobType=24&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`, 'get').then(res => {
            setList(res)
        })
    }, [doFetch, dateNoLine, searchUser])

    const isSubString = useCallback((arr, subString) => {
        let hasSubString = false
        _.forEach(arr, item => {
            if (_.includes(_.toLower(item), _.toLower(subString))) hasSubString = true
        })
        return hasSubString
    }, [])

    const filterList = useMemo(() => {
        if (list.length === 0) return []
        setSelectList([])
        let uniqList = _.uniqBy(list, x => _.toString(x.releaseId) + x.tracerRole + x.tracerId)
        if (!isAdd) {
            const selectItem = _.find(list, x => (x.id === cardDetail.id) && (x.tracerRole === cardDetail.tracerRole) && (_.toString(x.releaseId) === _.toString(cardDetail.releaseId)))
            const selectedItemPrimaryKey = (_.get(selectItem, 'releaseId') || '') + _.get(selectItem, 'tracerId') + _.get(selectItem, 'tracerRole')
            let initSelect = _.filter(uniqList, x => (_.toString(_.get(x, 'releaseId')) + _.get(x, 'tracerId') + _.get(x, 'tracerRole')) === selectedItemPrimaryKey)
            // cardDetail 不在 uniqList 和 list
            if (initSelect.length === 0) {
                initSelect = [cardDetail]
                uniqList.unshift(cardDetail)
            }
            const cardIndex = _.findIndex(uniqList, (x => x['tracerId'] === initSelect[0]['tracerId'])
                && (x => _.toString(x['releaseId']) === _.toString(initSelect[0]['releaseId']))
                && (x => _.toString(x['tracerRole']) === _.toString(initSelect[0]['tracerRole']))
            )
            uniqList.unshift(uniqList.splice(cardIndex, 1)[0])
            if (!isSelf) uniqList = initSelect
        }
        const roleList = _.isEmpty(filterRoleList) ? uniqList : _.filter(uniqList, x => _.includes(filterRoleList, x.tracerRole))
        if (_.isNull(searchText)) return roleList
        return _.filter(roleList, item => isSubString(_.values(_.pick(item, 'tracerRole', 'tracerId', 'tracerTitle')), _.trim(searchText)))
    }, [list, searchText, isSubString, cardDetail, isAdd, isSelf, filterRoleList])

    const roleOptions = useMemo(() => {
        if (_.isEmpty(list)) return []
        const options = _.map(list, x => ({ value: x.tracerRole, text: x.tracerRole }))
        return _.uniqBy(options, x => x.value)
    }, [list])

    useEffect(() => {
        if (isAdd || list.length === 0) return
        const selectItem = _.find(list, x => (x.id === cardDetail.id) && (x.tracerRole === cardDetail.tracerRole) && (_.toString(x.releaseId) === _.toString(cardDetail.releaseId)))
        const selectedItemPrimaryKey = (_.get(selectItem, 'releaseId') || '') + _.get(selectItem, 'tracerId') + _.get(selectItem, 'tracerRole')
        let initSelect = _.filter(filterList, x => (_.toString(_.get(x, 'releaseId')) + _.get(x, 'tracerId') + _.get(x, 'tracerRole')) === selectedItemPrimaryKey)
        if (initSelect.length === 0) {
            initSelect = _.filter(filterList, x => x.id === cardDetail.id)
        }
        setSelectList(initSelect)
    }, [filterList, cardDetail, list, isAdd, close])

    return <div>
        <WaterMarkDialog cancel={close} header={`研发任务（${date}）`} className='tracking-list-dialog radio-dialog'
            confirm={submit} confirmButtonDisabled={filterList.length === 0} confirmButtonVisible={isSelf}>
            <div className="options">
                <div className="form-group" >
                    <Form error={error} onError={setError} value={formData} onChange={handleChangeFormData} >
                        <HFormInput label='实际用时(h)' bind='auditUseTime' required gt={0} max={8} />
                        <HFormInput label='完成比例' bind='completionRatio' suffix='%' max={100} />
                    </Form>
                    <HFormInput label='角色筛选' component={Select} options={roleOptions} multiple value={filterRoleList} onChange={setFilterRoleList} clear />
                </div>
                <div className="options-right">
                    <Input value={searchText} onChange={setSearchText} placeholder='请搜索' prefix={<Icon name='sousuo1' className='search-icon' />} />
                    <Tooltip title='可选择未在列表中的记录进行工时填报。'><Button className='add-button' onClick={() => setShowAddDialog(true)}>
                        <Icon name='zengjia' className='add-icon' />
                        <span>更多</span>
                    </Button></Tooltip>
                    {showAddDialog && <SearchBox label='研发任务' confirm={addTraConfirm} cancel={() => setShowAddDialog(false)} onChange={setAddTracerId} value={addTracerId} />}
                    {noBox && <SearchBox confirm={() => { setShowAddDialog(true); setNoBox(false) }} nodata width={10} className='nodata' />}
                </div>
            </div>
            <div className='data-grid-wrap x-datagrid'>
                <DataGrid data={filterList} option={option} onSelectionChange={singleChoice} onRowClick={o => setSelectList([o])} selection={selectedList} />
            </div>
            <div className='dialog-tips'>注：点击右上角【+更多】可选择未在列表中的记录进行工时填报。</div>
        </WaterMarkDialog>
        {
            showContinueDialog &&
            <Dialog headerVisible={false} confirm={clear} cancel={exit} className={'content-center-dialog'}>
                增加成功，是否继续增加？
            </Dialog>
        }
    </div>

    function handleChangeFormData(formObj, bind) {
        if (bind === 'auditUseTime' && _.toNumber(_.get(formObj, 'auditUseTime')) > 8) {
            return Messager.show('实际用时上限为8h')
        }
        if (bind === 'completionRatio' && _.toNumber(_.get(formObj, 'completionRatio')) > 100) {
            return Messager.show('完成比例上限为100%')
        }
        setFormData(formObj)
    }

    function clear() {
        const auditUseTime = _.toNumber(formData.auditUseTime)
        setCountTime(x => x + auditUseTime)
        setShowContinueDialog(false)
        setFormData(INIT_FORM_DATA)
        setSelectList([])
        setSearchText('')
        doFetch(`/worktime/tracer/detail?jobType=24&beginDate=${dateNoLine}&endDate=${dateNoLine}&userAccount=${searchUser}`, 'get').then(res => {
            setList(res)
        })
    }

    function exit() {
        setShowContinueDialog(false)
        close()
    }

    function addTraConfirm() {
        const userAccount = _.get(JSON.parse(sessionStorage.getItem('current_user')), 'operator_id')
        doFetch(`/develop/tracer/participant/list?userAccount=${userAccount}&tracerId=${addTracerId}`, 'get').then(res => {
            if (res.length === 0) {
                setNoBox(true)
                return
            }
            // 判断添加的数据是否全在列表中
            if (_.every(res, x => _.find(list, item => (x.tracerRole + x.tracerId) === (item.tracerRole + item.tracerId)))) {
                return Messager.show('记录已存在！', { icon: 'error' })
            }
            const filterRes = _.filter(res, x => !_.find(list, item =>
                (_.toString(x.releaseId) + x.tracerRole + x.tracerId) === (_.toString(item.releaseId) + item.tracerRole + item.tracerId)))
            setList(x => filterRes.concat(x))
        }).catch(err => Messager.show(err.Message, { icon: 'error' }))
        setShowAddDialog(false)
    }

    function singleChoice(arr) {
        if (_.isEmpty(selectedList)) {
            setSelectList(arr)
        } else {
            const filterItem = _.find(arr, item => (item.tracerId !== selectedList[0].tracerId) ||
                ((item.tracerId === selectedList[0].tracerId) && (item.releaseId !== selectedList[0].releaseId || item.tracerRole !== selectedList[0].tracerRole)))
            setSelectList(_.isNil(filterItem) ? [] : [filterItem])
        }
    }

    function check() {
        if (_.some(_.values(error), x => x)) {
            const errMsg = _.find(_.values(error), x => !_.isNil(x))
            Messager.show(errMsg === '必填项！' ? '请填写必填项' : errMsg, { icon: 'error' })
            return false
        }
        const completionRatio = _.toNumber(formData.completionRatio)
        if (completionRatio < 0 || completionRatio > 100) {
            Messager.show('完成比例需在0-1之间', { icon: 'error' })
            return false
        }
        const auditUseTime = _.toNumber(formData.auditUseTime)
        if (auditUseTime < 0 || auditUseTime > 8) {
            Messager.show('实际用时需在0-8h之间', { icon: 'error' })
            return false
        }
        if (selectedList.length === 0) {
            Messager.show('请选择研发任务', { icon: 'error' })
            return false
        }
        return true
    }

    function submit() {
        if (beforeCutoffDay) return Messager.show(`${showCutoffDay}前的成本已核算，不允许修改工时`, { icon: 'error' })
        if (!check()) return
        const selectedItem = selectedList[0]
        const params = {
            actionType: "ADD",
            status: isAdd ? 'approve' : null,
            auditUseTime: formData.auditUseTime * 60,
            completionRatio: formData.completionRatio / 100,
            content: `【${selectedItem.tracerRole}】${selectedItem.tracerId}-${selectedItem.tracerTitle}`,
            jobType: selectedItem.jobType || selectedItem.tracerRoleId,
            tracerId: selectedItem.tracerId,
            releaseId: selectedItem.releaseId || '',
            workDate: dateNoLine
        }
        const postParams = isAdd ? [params] : [
            _.assign({}, params, {
                id: cardDetail.id,
                actionType: "EDIT"
            })
        ]
        doFetch('/worktime/edit', 'post', postParams).then(() => {
            Messager.show(`${isAdd ? '添加' : '修改'}成功`, { icon: 'success' })
            refresh()
            if (isAdd) {
                setShowContinueDialog(true)
                return
            }
            close()
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
        })
    }

}


export default TrackingListDialog;
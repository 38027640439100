import React from 'react';
import {Dialog} from 'rootnet-ui'
import _ from 'lodash'

function WaterMarkDialog(props) {
    const waterMarkUrl = sessionStorage.getItem('waterMarkUrl')
    return <Dialog {...props}>
        {props.children}
        {
            !_.isNil(waterMarkUrl) &&
            <div className="fill"
                 style={{
                     background: waterMarkUrl,
                     zIndex: 10000,
                     position:'absolute',
                     pointerEvents:"none"
                 }}
            />
        }
    </Dialog>
}

export default WaterMarkDialog;
import React, { useState } from 'react';
import { HtmlEditor, HtmlViewer } from 'rootnet-ext';
import "./HtmlEditorDemo.scss";

export default function HtmlEditorDemo(props) {
    const [value, setValue] = useState("")
    return <div className="html-editor">
        <HtmlEditor defaultValue={value} onChange={(v) => setValue(v)} />
        <HtmlViewer value={value} />
    </div>
}
import _ from 'lodash'
import React, {useState, useMemo, useEffect, useCallback} from 'react'
import { Button, DataGrid } from 'rootnet-ui'
import { Select, Form, FormInput } from 'rootnet-edit'
import { N2 } from 'rootnet-core/format'
import { Box } from '../../common/commonComponent'
import { OperationList } from '../../../project_share/components'
import OperationDialog from './OperationDialog'
import { useApi, useGet } from '../../../utils/hook'
import { strParams, parseParams } from '../../../utils/publicFun'
import { TextIconBtn } from '../../common/TextIconBtn'
import './subsidyManagement.scss'
import {handleTreeOptions} from "../../common/TreeFunc";

const getColumns = (setOpenDialog, jurisdiction, currentId) => {
  return {
    autoFill: true,
    fixedLeft: 2,
    fixedRight: 1,
    onRenderRow:o =>{
      const isCurrent = o.id === currentId
      return {
        style: {backgroundColor:isCurrent?'#edf3f9':''},
      }
    },
    columns: [
      { header: '#', convert: (v, o, i) => i + 1, width: 40, align: 'center', },
      { header: '一级项目', bind: 'firstProjectName', width: 200, tooltip: true, convert: (v, o) => v ? v : o.projectName },
      { header: '补贴类型', bind: 'subsidyType', width: 85, convert: v => v === '01' ? '立项补贴' : '项目补贴' },
      { header: '补贴金额', bind: 'subsidyAmount', align: 'right', width: 120, convert: N2 },
      { header: '成本中心', bind: 'costCenterName', width: 150, tooltip: true },
      { header: '补贴状态', bind: 'status', align: 'center', width: 80, convert: v => <span style={{ color: v === 'effective' ? 'black' : '#E15722' }}>{v === 'effective' ? '有效' : '作废'}</span> },
      { header: '申请人', bind: 'inputUserName', width: 90 },
      { header: '申请项目', bind: 'projectName', width: 140, tooltip: true },
      { header: '申请时间', bind: 'inputDate', width: 140, tooltip: true, convert: v => startTime(v) },//申请项目ID
      { header: '申请项目ID', bind: 'projectID', width: 140, tooltip: true, },//申请项目ID
      { header: '操作', width: 60, align: 'center', convert: createOperation },
    ],
  }
  function createOperation(v, o, i) {
    const options = [
      { text: '修改', disabled: jurisdiction !== 'Manager', onClick: () => o.status === 'effective' ? setOpenDialog({ id: o.id, mode: 'edit', data: o, txt: '修改补贴' }) : null },
    ]
    return <OperationList className={o.status === 'effective' ? null : 'edit-disable'} options={options} />
  }
  function startTime(v) {
    const year = `${v.substring(0, 4)}-${v.substring(4, 6)}-${v.substring(6, 8)}`
    const time = `${v.substring(8, 10)}:${v.substring(10, 12)}:${v.substring(12, 14)}`
    return `${year} ${time}`
  }
}

const item = { text: '全部', value: '' }
export default function SubsidyManagement(props) {
  const { location } = props
  const pathParams = useMemo(() => { if (location.search) { return parseParams(location.search.split('?')[1]) } }, [location])
  const { data: jurisdictionData } = useGet('/common/uacUserRole') //权限
  const [params, setParams] = useState({ subsidyType: '', costCenter: '', status: 'effective', projectId: pathParams ? decodeURI(pathParams.id) : null })
  const [openDialog, setOpenDialog] = useState({})
  const [afterReset, setAfterReset] = useState(false)
  const { data: typeData, doFetch: typeDoFetch } = useApi()
  const { data: costData, doFetch: costDoFetch } = useApi()
  const { data: statusData, doFetch: statusDoFetch } = useApi()
  const { data, doFetch, loading, error } = useApi()
  const [currentId, setCurrentId] = useState()

  const onRowClick = useCallback((o) => {
    setCurrentId(o.id)
  },[])

  const jurisdiction = useMemo(() => {
    if (_.find(jurisdictionData, v => v.posId === 'Manager')) return 'Manager'
    if (_.find(jurisdictionData, v => v.posId === 'PMO')) return 'PMO'
    if (_.find(jurisdictionData, v => v.posId === 'PM')) return 'PM'
    if (_.find(jurisdictionData, v => v.posId === 'SurpportEM')) return 'SurpportEM'
    if (_.find(jurisdictionData, v => v.posId === 'SurpportPM')) return 'SurpportPM'
  }, [jurisdictionData])

  useEffect(() => {
    doFetch('/subsidy/list?' + strParams(params))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    typeDoFetch('/common/globalconst?globalConst=PSASubsidyType')
    costDoFetch('/worktime/costcenter?activeFlag=Y')
    statusDoFetch('/common/globalconst?globalConst=PSASubsidyStatus')
  }, [typeDoFetch, costDoFetch, statusDoFetch])

  const [typeOpt, statusOpt,optCostCenterTree] = useMemo(() => {
    const opt_cost = _.map(typeData, o => { return { text: o.displayName, value: o.interiorId } })
    const firstLevelList = _.sortBy(_.filter(costData, x => _.isNil(x.pid)), x => x.id)
    const opt_cost_center_tree = handleTreeOptions(costData, firstLevelList, "id", "pid", "name")
    const opt_approve = _.map(statusData, o => { return { text: o.displayName, value: o.interiorId } })
    return [opt_cost, opt_approve,opt_cost_center_tree]
  }, [typeData, costData, statusData])


  const option = useMemo(() => getColumns(setOpenDialog, jurisdiction,currentId), [jurisdiction,currentId])
  return (
    <div className="flex-y fill subsidy">
      <Option search={search} setAfterReset={setAfterReset} typeOpt={typeOpt} optCostCenterTree={optCostCenterTree} statusOpt={statusOpt} params={params} setParams={setParams} />
      <Box className='flex-y x-card-singlegrid' title='补贴管理' error={error} loading={loading} data={data} extra={jurisdiction !== 'Manager' ? null : <TextIconBtn icon='tianjia' text='增加补贴' onClick={() => setOpenDialog({ mode: 'add', txt: '增加补贴' })} />}>
        <DataGrid data={data} option={option} onRowClick={onRowClick}/>
      </Box>
      {['edit', 'add',].includes(openDialog.mode) && <OperationDialog afterReset={afterReset} projectID={_.get(pathParams, 'id')} typeData={typeData} close={close} refresh={() => doFetch(`/subsidy/list?${strParams(params)}`)} {...openDialog} />}
    </div>
  )
  function close() {
    setOpenDialog({})
  }
  function search(o) {
    doFetch(`/subsidy/list?${strParams(o)}`)
  }

}

function Option(props) {
  const { search, typeOpt, optCostCenterTree, statusOpt, params, setParams, setAfterReset } = props


  return (
    <div className="x rims_options">
      <Form onChange={setParams} value={params}>
        <FormInput label='项目名称' bind='projectName' />
        <FormInput label='补贴类型' clear options={[item, ...typeOpt]} component={Select} bind='subsidyType' search />
        <FormInput label='成本中心' clear options={optCostCenterTree} tree component={Select} bind='costCenter' search />
        <FormInput label='补贴状态' clear options={[item, ...statusOpt]} component={Select} bind='status' search />
      </Form>
      <Button className='btn' primary onClick={() => handleFetch(true)} >查询</Button>
      <Button className='btn reset-btn' text onClick={() => handleFetch(false)} >重置</Button>
    </div>
  )
  function handleFetch(judge) {
    const options = judge ? params : { projectName: '', subsidyType: '', costCenter: '', status: 'effective', }
    !judge && setAfterReset(true)
    !judge && setParams(options)
    search(options)
  }
}
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput } from 'rootnet-edit'
import { useGet } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../utils/publicFun'
import './index.scss'

export default function DevListCopyDialog(props) {
  const { copyInfo, close, refreshList, setCurrentInfo, type } = props
  const { id } = copyInfo
  const [formData, setFormData] = useState(copyInfo)
  const [formError, setFormError] = useState(copyInfo)
  const { doFetch } = useGet()

  const canSubmit = useMemo(() => {
    return _.every(formError, _.isNil)
  }, [formError])

  const submit = useCallback(() => {
    const params = {
      id,
      title: _.get(formData, 'title'),
      sonStory: null,
    }
    doFetch('/story/copy?' + strParams(params)).then(newId => {
      Messager.show('复制成功', { icon: 'success' })
      if (setCurrentInfo) {
        setCurrentInfo({
          id: newId,
          mode: 'detail'
        })
      }
      close()
      refreshList()
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [id, doFetch, close, refreshList, formData, setCurrentInfo])

  return <Dialog className={'dev-list-copy-dialog'} header={`复制${type==='DEV'?'':'需求'}`} confirmButtonText={'保存'} cancel={close} confirm={submit} confirmButtonDisabled={!canSubmit}>
    <Form value={formData} onChange={setFormData} error={formError} onError={setFormError}>
      <FormInput bind={'title'} label={'标题'} required horizontal componentWidth={400} />
    </Form>
  </Dialog>
}

import React, { useState, useMemo, useCallback, useEffect, useRef, useReducer } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { isNil } from 'rootnet-core/format'
import { Loader, Button, Messager, Switch } from 'rootnet-ui'
import { usePost, useGet } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Select, Input, Display } from 'rootnet-edit'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import { Icon } from '../../../../../../components'
import { strParams } from '../../../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'
import AssigningAuthorization from '../../assigningAuthorization'
import MarkdownEditor from '../../../../../markdown/MarkdownEditor'
import TinyEditor from '../../../../../common/richTextEditor/TinyEditor'
import convertOptions from '../../../../../common/ConvertOptions'
import './index.scss'

const ADD_URL = '/demoTxt/add'
const EDIT_URL = '/demoTxt/edit'
const DETAIL_URL = '/demoTxt/getDetail'
const ONLY_ID_URL = '/test_case/productGetOnlyId'
const CLASSIFY_URL = '/common/globalconst?globalConst=DemoTxtType'

const GLOBAL_CONST_URL = '/common/globalconst?globalConst='

function getTreeOpt(data) {
  const filterData = _.filter(data, x => isNil(x.pId))
  const filterList = _.filter(data, x => !isNil(x.pId))
  const distinguishData = _.groupBy(filterList, x => x.pId)
  return _.map(filterData, item => {
    const children = _.find(distinguishData, (v, k) => k === item.interiorId)
    return _.assign({
      text: item.displayName,
      value: item.interiorId,
      type: item.displayColor,
    }, !_.isEmpty(children) && { _disabled: true, children: tier(children, distinguishData) })
  })
}

function tier(data, allData) {
  return _.map(data, item => {
    const children = _.find(allData, (v, k) => k === item.interiorId)
    return _.assign({
      text: item.displayName,
      value: item.interiorId,
      type: item.displayColor,
    }, !_.isEmpty(children) && { _disabled: true, children: tier(children, allData) })
  })
}

const HFormInput = props => <FormInput horizontal labelWidth={75} componentWidth={180} {...props} />
export default function ContentTemplateDetailContent(props) {
  const { refreshViewList, close, currentInfo = {}, initTemplateType } = props
  const [id, setId] = useState()
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [infoError, setInfoError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [value, setValue] = useState('')
  const { doFetch: getDetail, loading } = useGet()
  const { data: classifyRes } = useGet(CLASSIFY_URL)
  const { data: globalConstRes, doFetch: getGlobalConst, loading: globalLoading } = useGet()

  const { doFetch: getOnlyId } = useGet()
  const { doFetch } = usePost()
  const demoRef = useRef()
  const [countMarkdown, updateMarkdown] = useReducer((x) => x + 1, 0)

  const { mode: initMode = 'detail' } = currentInfo
  const [mode, setMode] = useState(initMode)
  const [isAuthorization, setIsAuthorization] = useState(false)

  const { type, objectType, demoValue, templateAuthorizations, category } = useMemo(() => editFormData || {}, [editFormData])
  const isAdd = useMemo(() => mode === 'add', [mode])
  const isDetail = useMemo(() => mode === 'detail', [mode])
  const [status, setStatus] = useState()
  const { doFetch: submitPost } = usePost()

  const isShow = useMemo(() => {
    return category === '0' || initTemplateType === '0'
  }, [category, initTemplateType])

  const typeOpt = useMemo(() => {
    if (_.isEmpty(classifyRes)) return []
    return convertGlobalConstOptions(classifyRes)
  }, [classifyRes])

  useEffect(() => {
    if (_.isNil(type) || _.isEmpty(classifyRes)) return
    const findMemo = _.get(_.find(classifyRes, o => o.interiorId === type), 'memo')
    getGlobalConst(`${GLOBAL_CONST_URL}${findMemo}`)
  }, [type, getGlobalConst, classifyRes])

  const [objectTypeOpt, objectTypeTreeOpt] = useMemo(() => {
    if (_.isEmpty(globalConstRes)) return []
    return [
      globalConstOpt(globalConstRes),
      getTreeOpt(globalConstRes)
    ]

    function globalConstOpt(data) {
      return _.map(data, o => ({
        value: o.interiorId,
        text: o.displayName,
        type: o.displayColor,
      }))
    }
  }, [globalConstRes])

  const textType = useMemo(() => {
    if (_.isNil(objectType) || _.isEmpty(globalConstRes)) return null
    return _.get(_.find(globalConstRes, o => o.interiorId === objectType), 'displayColor')
  }, [objectType, globalConstRes])

  const refreshDetail = useCallback(() => {
    if (_.isNil(id) || isAdd) return
    getDetail(`${DETAIL_URL}?${strParams({ id })}`)
      .then(res => {
        setStatus(res.status)
        setFormData(res)
        setEditFormData(res)
        setId(res.id)
        updateMarkdown()
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [isAdd, id, getDetail])

  useEffect(() => {
    if (_.isNil(textType) && !isDetail) return
    if (textType === '0') {
      console.log(demoValue)
      setTimeout(() => {
        if (demoRef.current) {
          demoRef.current.setContent(_.replace(demoValue || '', /\n/g, ''))
        }
      }, 1000)
    }
    updateMarkdown()
  }, [textType, demoValue, isDetail])

  useEffect(() => {
    refreshDetail()
  }, [refreshDetail])

  useEffect(() => {
    if (isAdd) {
      setFormData({ category: initTemplateType, templateAuthorizations: [] })
      setEditFormData({ category: initTemplateType, templateAuthorizations: [] })
      updateMarkdown()
    }
  }, [isAdd, initTemplateType])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(infoError), x => x)
  }, [infoError])

  const submit = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const defaultVal = isNil(value) ? demoValue : value
    const templateDetail = textType === '0' ? _.replace(demoRef.current.getContent(), /\n/g, '') : defaultVal
    if (isNil(templateDetail)) return Messager.show('请填写模板详情', { icon: 'error' })
    const postParams = _.assign({}, editFormData, { id, demoValue: templateDetail, status })
    doFetch(isAdd ? ADD_URL : EDIT_URL, postParams)
      .then(() => {
        Messager.show(isAdd ? '增加成功' : '修改成功', { icon: 'success' })
        refreshDetail()
        refreshViewList()
        setMode('detail')
        setIsDisable(false)
        isAdd && close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [close, doFetch, isAdd, editFormData, refreshViewList, refreshDetail, isDisable, id, value, textType, status, demoValue])

  const handChange = (o, k) => {
    if (k === 'type') {
      o['objectType'] = null
    }
    setEditFormData(o)
  }

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) setId(id)
  }, [currentInfo])

  useEffect(() => {
    if (isAdd) {
      getOnlyId(ONLY_ID_URL)
        .then(id => setId(id))
    }
  }, [isAdd, getOnlyId])

  const changeStatus = useCallback((id, status) => {
    submitPost('/demoTxt/editSingle', { id, status }).then(() => {
      Messager.show('修改成功', { icon: 'success' });
      refreshViewList()
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [submitPost, refreshViewList])

  return (
    <>
      <div className='content-template-detail-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title flex center-y">
            {isAdd && '新建模板'}
            {isDetail && '详情'}
            {(!isAdd && !isDetail) && '修改模板'}
          </div>
          <div className="mock-right-header flex center-y">
            {
              !isAdd && status &&
              <Switch checked={status === 'Y'} checkedComponent={'启用'} uncheckedComponent={'禁用'}
                onChange={() => changeStatus(formData?.id, status === 'Y' ? 'N' : 'Y')} />
            }
            {
              !isAdd &&
              <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                refreshDetail()
                setMode(x => x === 'detail' ? 'edit' : 'detail')
              }} />
            }
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>

        <div className="content-template-detail-main-panel">
          {!isAdd && loading && <Loader fill />}
          <div className='form-module'>
            <Form value={editFormData} onChange={handChange} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
              <HFormInput required label='模板名称' bind='demoName' component={isDetail ? Display : Input} componentWidth={780} />
              <div className='flex'>
                <HFormInput search required label='业务分类' bind='type' component={isAdd ? Select : Display} options={typeOpt} convert={v => convertOptions(v, typeOpt)} />
                <HFormInput tree search required label='业务对象' bind='objectType' component={isAdd ? Select : Display} options={objectTypeTreeOpt} convert={v => convertOptions(v, objectTypeOpt)} disabled={globalLoading} />
                {
                  isShow &&
                  <div className='flex authorization-display'>
                    <div>授权</div>
                    <div className='selected-num'>已选{_.size(templateAuthorizations)}</div>
                    <div className={clsx('click-configuration', { 'click-configuration-detail': isDetail })} onClick={() => {
                      if (isDetail) return
                      setIsAuthorization(true)
                    }}>点击配置</div>
                  </div>
                }
              </div>
            </Form>
          </div>
          <div className='template-details'>
            {isAdd && <div className='template-details-header'>模板详情</div>}
            {
              textType === '1' &&
              <div className='template-details-content' key={countMarkdown}>
                <MarkdownEditor
                  ref={demoRef}
                  readOnly={isDetail}
                  initValue={_.isNil(demoValue) ? '' : demoValue}
                  onChange={setValue}
                />
              </div>
            }
            {
              textType === '0' &&
              <div className='template-details-content' key={countMarkdown}>
                <div className="rich-text-detail-wrap flex" style={{ display: isDetail ? "block" : 'none' }}>
                  <div dangerouslySetInnerHTML={{ __html: demoValue }} />
                </div>
                <div className="rich-text-area" style={{ display: !isDetail ? "block" : 'none' }}>
                  <TinyEditor ref={demoRef} />
                </div>
              </div>
            }
          </div>
        </div>

        <div className="mock-footer flex center-y">
          <div />
          {/*
          _.isEmpty(showChildList) ? <div /> :
            <div className="footer-switch flex center-y">
              <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                onClick={() => {
                  if (switchDisabled) return
                  if (!_.includes([-1, 0], currentIndex)) {
                    switchCurrentItem('previous')
                  }
                }}>
                <Icon name='xiangqian' />&nbsp;上一个
              </div>
              <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                onClick={() => {
                  if (switchDisabled) return
                  if (!(currentIndex === showChildList?.length - 1)) {
                    switchCurrentItem('next')
                  }
                }}>
                下一个&nbsp;<Icon name='xianghou' />
              </div>
              <div className="list-num">
                {currentIndex + 1}/{showChildList?.length}
              </div>
            </div>
              */}
          <div className="btn-group flex">
            {
              isDetail &&
              <Button normal onClick={close}>关闭</Button>
            }
            {
              !isDetail &&
              <Button normal onClick={() => {
                close()
              }}>取消</Button>
            }
            {
              !isDetail &&
              <Button primary disable={!canSubmit} onClick={submit} >确认</Button>
            }
          </div>
        </div>
      </div>
      {
        isAuthorization &&
        <AssigningAuthorization
          id={id}
          close={() => { setIsAuthorization(null) }}
          initValue={_.get(formData, 'templateAuthorizations', [])}
          upDate={setEditFormData}
          initLoading={isAdd ? false : loading}
        />
      }
    </>
  )
}

import clsx from 'clsx'
import _ from 'lodash'
import React, { useMemo, useRef, useState, useEffect } from 'react'
import { N2 } from 'rootnet-core/format'
import { Form, FormInput, Grid, Input, Select, TextArea } from 'rootnet-edit'
import { Dialog, MessageBox, Messager, Switch, Tooltip } from 'rootnet-ui'
import { useApi, useGet } from '../../../utils/hook'
import TreeTable from '../projectManagement/controls/treeTable'

const URL = [
  '/common/globalpara?paraId=StdPrice',
  '/common/globalpara?paraId=StdPrice_service',
  '/project/applyLeafProject',
  // '/common/userinfo',
  // '/common/user/positions?posIds=OPS'
]

const HFormInputStep = _.curry(props => {
  const { component, options, required, ...rest } = props
  const INPUT = <FormInput horizontal
    component={component}
    options={options}
    {...rest} required={required} />
  if (_.get(props, 'text')) return <Tooltip title={_.get(props, 'text')}>{INPUT}</Tooltip>
  return INPUT
})

export default function OperationDialog(props) {
  const { mode, close, typeData, refresh, data, txt, projectID, afterReset } = props
  const { data: centralData, doFetch: getlist, loading: loader } = useGet('worktime/costcenter?activeFlag=Y')
  const isAdd = mode === 'add'
  const errorRef = useRef()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [applyReasonLen, SetApplyReasonLen] = useState(true)
  const [params, setParams] = useState(isAdd ? { projectID } : {
    ..._.pick(data, [
      'id',
      'projectID',
      'subsidyType',
      'costCenter',
      'subsidyAmount',
      // 'subsidyUseTime',
      'status',
      'projectName',
      'costCenterName'
    ]),
    listId: data.costCenter,
    subsidyUseTime: _.get(data, 'subsidyUseTime')/8
  })
  const { doFetch } = useApi()
  const { data: defaultData } = useGet(URL)
  const [subsidyDatam, subsidyDatams, projectData] = useMemo(()=>defaultData || [],[defaultData])
  const typeOpt = useMemo(() => _.map(typeData, o => { return { text: o.displayName, value: o.interiorId } }), [typeData])

  const projectOpt = useMemo(() => (data) => {
    let arr = []

    recursion(data)
    function recursion(o) {
      _.forEach(o, v => {
        if (v.projectStatus === '01') {
          if (_.get(v, 'leafProjectFlag') === '1' || _.get(v, 'pId') === null) {
            arr.push(_.pick(v, ['id', 'projectName', 'projectType', 'pId', 'projectStatus']))
          }
          if (_.get(v, 'childrenProjectList')) {
            return recursion(_.get(v, 'childrenProjectList'))
          }
        }
      })
    }
    if (isAdd && !afterReset && projectID) _.assign(params, { projectID: `${projectID},${_.get(_.find(arr, v => v.id === projectID), 'projectType')}` })
    const newArr = _.reduce(arr, function (result, value) {
      result.push({
        pId: value.pId,
        text: value.projectName,
        value: `${value.id},${value.projectType}`,
        tag: `${value.id}${value.projectName}`,
      })
      return result;
    }, [])

    const filData = _.filter(newArr, v => !v.pId)
    const newData = _.map(filData, (o, i) => {
      const index = newArr.indexOf(filData[i])
      const indexNext = newArr.indexOf(filData[i + 1])
      const arrData = newArr.slice(index + 1, indexNext)
      return _.assign(o, { children: arrData })
    })
    return newData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const HFormInput = useMemo(() => HFormInputStep(), [])
  useEffect(() => setLoading(loader), [loader])
  return (
    <Dialog className='subsidyDialog' loading={loading} header={txt} confirm={getConfirm(mode)} cancel={close} >
      <Grid cols={2}>
        <Form value={params} onChange={handleChange} onError={error => errorRef.current = error} >
          <HFormInput
            clear
            search
            colSpan={2}
            label='申请项目'
            required={isAdd}
            disabled={!isAdd}
            labelWidth={103.73}
            componentWidth={472.38}
            component={isAdd ? Select : Input}
            options={projectOpt(projectData)}
            bind={isAdd ? 'projectID' : 'projectName'}
            placeholder='支持ID/名称筛选'
            tree />
          <HFormInput required={isAdd} search clear label='补贴类型' options={typeOpt} component={Select} disabled={!isAdd} bind='subsidyType' />
          <HFormInput required={isAdd} search label='成本中心' disabled={!isAdd} onFocus={() => setOpen(1)} bind='costCenterName' placeholder='请选择' text={handleInConvert('', 'costCenterName')} bindInConvert={handleInConvert} />
          <HFormInput required={isAdd} type='number' digit={1} gt={0} label='补贴金额(元)' disabled={isAdd ? !params.projectID : true} bind='subsidyAmount' format={v => v ? N2(v) : null} />
          <HFormInput required={isAdd} type='number' digit={1} label='补贴工时(人日)' disabled={isAdd ? !params.projectID : true} bind='subsidyUseTime' />
          {isAdd ?
            <HFormInput
              label='申请理由'
              colSpan={2}
              onChange={v => v.length >= 5 ? SetApplyReasonLen(false) : SetApplyReasonLen(true)}
              className={clsx('reason', { error: applyReasonLen })}
              placeholder='请输入至少5位'
              labelWidth={103.73}
              component={TextArea}
              maxLength={500}
              required={isAdd}
              bind='applyReason' />
            :
            <HFormInput
              label='补贴状态'
              component={Switch}
              labelWidth={103.73}
              checkedComponent='有效'
              bind='status'
              uncheckedComponent='作废'
              checked={params.status === 'effective'} />
          }
        </Form>
      </Grid>
      {message && <MessageBox className={'content-center-dialog'} header='提示' cancelButtonVisible={false} confirm={() => setMessage(null)}>{message}</MessageBox>}
      {/* {open && <Dialog
        className='subsidyDialog-promptBox'
        confirmButtonDisabled={!radio}
        headerVisible={false}
        onOutsideClick={() => setOpen(false)}
        cancel={() => setOpen(false)}
        confirm={promptBoxSubmit}
        style={{ padding: 0 }}
      >
        <div>
          <Form value={parameter} onChange={search}>
            <HFormInput label='ID' componentWidth={130} bind='ID' />
            <HFormInput label='名称' componentWidth={130} bind='name' />
            <HFormInput search clear label='负责人' componentWidth={200} component={Select} options={[item, ...headOpt]} bind='principal' />
          </Form>
        </div>
        <div className='subsidyDialog-promptBox-table-fill'>
          <DataGrid data={showList} option={radiosOption} />
        </div>
      </Dialog>
      } */}
      {open && <TreeTable listData={centralData} getlist={getlist} loading={loader} params={params} setParams={setParams} turnOn={open} setTurnOn={setOpen} />}

    </Dialog>
  )

  function handleInConvert(data, bind) {
    let string = []
    if (bind === 'costCenterName') {
      if (centralData && params.listId) {
        string = []
        const datas = _.find(centralData, v => v.id === params.listId)
        datas && levelString(datas)
        return string.join('/')
      }
    }
    function levelString(radios) {
      if (!_.get(radios, 'pid')) return string.unshift(_.get(radios, 'name'))
      string.unshift(_.get(radios, 'name'))
      const data = _.find(centralData, o => o.id === _.get(radios, 'pid'))
      return levelString(data)
    }
  }

  function handleChange(options, key) {
    let currentNorm
    if (_.get(options, 'projectID')) currentNorm = _.get(options, 'projectID').split(',')[1] === 'DEV' ? subsidyDatam.paraValue : subsidyDatams.paraValue
    if ((key === 'subsidyUseTime' || key === 'subsidyAmount')) {
      if (_.isNil(options[key])) {
        _.assign(options, { subsidyUseTime: '', subsidyAmount: '' })
      } else {
        key === 'subsidyUseTime' && (options['subsidyAmount'] = _.round(options[key] * (currentNorm * 8), 1))
        key === 'subsidyAmount' && (options['subsidyUseTime'] = _.round(options[key] / (currentNorm * 8), 1))
      }
    }
    setParams(options)
  }

  function getConfirm(mode) {
    return {
      add: commonConfirm('/subsidy/add'),
      edit: commonConfirm('/subsidy/modify'),
    }[mode]

    function commonConfirm(url) {
      return () => {
        if (!isAdd && (params.status === true || params.status === 'effective')) {
          close()
          return
        }
        if (!isAdd && data.subsidyType === '01') {
          if (Number(data.subsidyAmount) > Number(data.residuePreTotalIncome)) return Messager.show('项目预算不足，无法修改', { icon: 'error' })
        }
        if (!isAdd && data.subsidyType === '02') {
          if (Number(data.subsidyAmount) > Number(data.residuePreTotalCost)) return Messager.show('项目预算不足，无法修改', { icon: 'error' })
        }
        if (isAdd && (!_.every(errorRef.current, _.isNil))) { Messager.show('请填写必填项', { icon: 'error' }); return }
        if (isAdd && applyReasonLen) { Messager.show('申请理由不能少于5个字符', { icon: 'error' }); return }
        if ((isAdd && !applyReasonLen) || !isAdd) {
          const handleParams = _.assign({},params,{subsidyUseTime: _.toNumber(_.get(params, 'subsidyUseTime'))*8})
          const submitData = isAdd ? _.assign({}, { ...handleParams, costCenter: _.get(handleParams, 'listId'), projectID: handleParams['projectID'].split(',')[0] }) : _.assign({}, _.pick({ ...handleParams, status: handleParams.status ? 'effective' : 'invalid' }, ['id', 'status', 'projectID', 'subsidyAmount']))
          setLoading(true)
          doFetch(url, 'post', submitData).then(() => {
            Messager.show('提交成功！', { icon: 'success' })
            refresh()
            close()
            setLoading(false)
          }).catch((err) => {
            setLoading(false)
            setMessage(err._message)
          })
        }
      }
    }
  }

}

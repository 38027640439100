import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Input } from 'rootnet-edit'
import { Messager } from 'rootnet-ui'
import { BaseTable, useTablePipeline, features } from 'ali-react-table'
import { usePost } from 'rootnet-biz/lib/hooks'
import { estimateInitData } from '../../../../components/formatData'
import { Icon } from '../../../../../../components'
import ManpowerDetailLog from '../manpowerDetailLog'
import convertOptions from '../../../../../common/ConvertOptions'
import ManpowerAddLog from '../manpowerAddLog'
import './index.scss'

const EDIT_URL = '/humanProjectEstimate/update'

function getColumns(props) {
  const { monthArray, editInfo, setEditInfo, setValue, editUpdate, setOpen, itemClassOpt, projectAllOpt, setAddParams, dataSource } = props
  const allOption = _.concat(..._.map(projectAllOpt, o => o.children))
  const { editId = null, editField = null } = editInfo || {}
  const columnHead = [
    { name: '#', code: '', width: 40, lock: true, align: 'center', render: (v, o, i) => i + 1 },
    {
      name: '部门', code: 'departmentName', width: 300, lock: true, render: (v, o) => {
        return <span>
          {v}
          {o.status === '02' && <span style={{ color: '#d6d8e0' }}>（无效）</span>}
        </span>
      }, features: { sortable: true }
    },
    {
      name: '项目/事务', code: 'projectId', width: 150, lock: true, render: (value, o) => {
        const val = _.get(_.find(allOption, o => o.value === value), 'text') || value
        return <span>
          {val}
          {o.projectStatus === '02' && <span style={{ color: '#d6d8e0' }}>（无效）</span>}
        </span>
      }, features: { sortable: true }
    },
    {
      name: '预估类型', code: 'type', width: 100, lock: true, align: 'center', render: v => convertOptions(v, itemClassOpt), features: { sortable: true }
    },
    {
      name: '占用评估合计', code: 'workloadOccupiedSummary', width: 100, lock: true, align: 'center', render: v => {
        return <span style={{ fontWeight: 'bold' }}>{v}</span>
      }, features: { sortable: true }
    },
  ]
  const columnMonth = _.map(monthArray, o => {
    return {
      name: o, code: `workloadOccupied,${o}`, width: 100, align: 'center', render: (v, d) => convertEditField(v, d, `workloadOccupied${o}${d.projectId}`, o), features: { sortable: true }
    }
  })
  return _.concat(columnHead, _.compact(columnMonth))

  function convertEditField(v, o, bind, month) {
    const isDetail = o.projectStatus === '02' || o.status === '02'
    const queryId = _.get(_.find(dataSource, item => (item.departmentId === o.departmentId && !_.isNil(_.get(item, `manpowerPipelineId${month}`)))), `manpowerPipelineId${month}`)
    if (editId === _.get(o, `id${month}`) && editField === bind) {
      return <Input
        autoFocus
        min={0}
        digit={0}
        defaultValue={v}
        style={{ width: 75, height: 20 }}
        onChange={num => setValue(num)}
        onBlur={() => editUpdate({ val: v, id: _.get(o, `id${month}`), item: o })}
        onEnter={() => editUpdate({ val: v, id: _.get(o, `id${month}`), item: o })}
      />
    }
    if (_.isNil(queryId)) {
      return <div
        className='empty-cell'
        onClick={() => setAddParams({ evaluationDate: new Date(`${month}-01`), ..._.pick(o, ['departmentId']) })}
      >
        {v}
      </div>
    }
    return <span className='edit-cell-operation'>
      <span
        className='display-num'
      >
        <span
          style={_.assign({}, !isDetail && {
            cursor: 'pointer',
            color: '#5477ff',
            padding: '0 12px',
            display: 'inline-block'
          }, _.isNil(v) && { width: '100%', height: '100%' })}
          onClick={() => {
            if (isDetail) return
            setOpen(queryId)
          }}
        >
          {v}
        </span>
      </span>
      {
        (!isDetail && !_.isNil(_.get(o, `id${month}`))) && <Icon
          name='bianji2'
          className='edit-icon'
          onClick={() => {
            setEditInfo({
              editId: _.get(o, `id${month}`),
              editField: bind,
            })
          }}
        />
      }
      <span className='decorate'></span>
    </span>
  }
}


export default function EstimateDetail(props) {
  const { data, loading, monthArray, refresh, projectOpt, projectAllOpt, projectOption, itemClassOpt, statusOpt, status } = props
  const [editInfo, setEditInfo] = useState()
  const [value, setValue] = useState()
  const [open, setOpen] = useState()
  const [addParams, setAddParams] = useState(null)
  const { doFetch: upDateFetch } = usePost()

  const editUpdate = useCallback((props) => {
    const { val, id } = props
    if (_.toNumber(value) < 0) {
      Messager.show('不能为负数', { icon: 'error' })
      return destroyValue()
    }
    if (val === value || _.isNil(value)) return destroyValue()
    const postParams = _.assign({}, {
      id,
      workloadOccupied: value
    })
    upDateFetch(EDIT_URL, postParams)
      .then(() => {
        refresh()
        destroyValue()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [value, upDateFetch, refresh])

  const dataSource = useMemo(() => estimateInitData(data, status), [data, status])

  const columns = useMemo(() => getColumns({ monthArray, editInfo, setEditInfo, setValue, editUpdate, setOpen, itemClassOpt, projectAllOpt, setAddParams, dataSource }), [monthArray, editInfo, editUpdate, itemClassOpt, projectAllOpt, dataSource])

  const pipeline = useTablePipeline()
    .input({ dataSource, columns })
    .use(
      features.sort({
        mode: 'single',
        highlightColumnWhenActive: false,
      })
    )

  return (
    <div className='manpower-estimate-detail'>
      <BaseTable {...pipeline.getProps()}
        isLoading={loading}
        style={{
          '--row-height': "34px",
          '--header-bgcolor': '#F5F5F5',
          '--header-color': '#252525'
        }}
      />
      {
        open &&
        <ManpowerDetailLog {...{ initId: open, refresh, projectOpt, projectOption, itemClassOpt, statusOpt }} close={() => setOpen(null)} />
      }
      {
        !_.isNil(addParams) &&
        <ManpowerAddLog  {...{ projectOption, itemClassOpt, refresh }} initParams={addParams} close={() => setAddParams(null)} />
      }
    </div>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

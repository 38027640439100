import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tabs, Tooltip } from 'antd'
import { Dialog, Messager, Button } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Display, Select, Input } from 'rootnet-edit'
import { strParams } from '../../../../../../utils/publicFun'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import convertOptions from '../../../../../common/ConvertOptions'
import TextAreaInput from '../../../../../common/textAreaInput/TextAreaInput'
import gd from '../../../../../../base/global'
import { Icon } from '../../../../../../components'
import './developmentCompletionDetails.scss'
import { isNil } from 'rootnet-core/format'
import DisplaySelect from '../../../../../common/displaySelect/DisplaySelect'
import convertGlobalConstOptions from '../../../../../common/ConvertGlobalConstOptions'

const DEV_DETAIL_URL = '/bug/tracer/dev/detail'
const EDIT_URL = '/bug/tracer/dev/edit'
const TEMPLATE_URL = '/issue/getDemoTxt'
const ASSEMBLY_URL = '/common/globalconst?globalConst=assembly'

const HFormInput = props => <FormInput horizontal labelWidth={125} componentWidth={188} {...props} />

// const selfTestValue = `自测结论：
// 自测用例描述：`

export default function DevelopmentCompletionDetails(props) {
  const { id, loginUser, close, typeOpt, stateOpt, whetherOpt, performanceFlagOpt, refresh, tracerState, isClose, isCts } = props //
  const [params, setParams] = useState({})
  const [mode, setMode] = useState('detail')
  const [activeKey, setActiveKey] = useState('modifiedDesc')
  const { doFetch: getFetch } = useGet()
  const { doFetch: getTemplate } = useGet()
  const { doFetch } = usePost()
  const [formError, setFormError] = useState()
  const [selfTestValue, setSelfTestValue] = useState()
  const { data: assemblyRes } = useGet(ASSEMBLY_URL)

  const { selfTest } = params || {}

  useEffect(() => {
    getTemplate(`${TEMPLATE_URL}?id=100`)
      .then(res => {
        setSelfTestValue(_.get(res, 'demoValue'))
      })
  }, [getTemplate])

  const assemblyOpt = useMemo(() => {
    if (_.isEmpty(assemblyRes)) return {}
    return convertGlobalConstOptions(assemblyRes)
  }, [assemblyRes])

  const canSubmit = useMemo(() => {
    if (selfTest === selfTestValue) return false
    return !_.some(_.values(formError), x => x)
  }, [formError, selfTest, selfTestValue])

  const isDetail = useMemo(() => {
    return mode === 'detail'
  }, [mode])

  const refreshData = useCallback(() => {
    getFetch(`${DEV_DETAIL_URL}?${strParams({ Id: id })}`)
      .then(res => {
        setParams(_.assign({}, res, {
          assembly: _.split(_.get(res, 'assembly'), ','),
          hour: changeHourMinutestr(res.realUseTime, 'hour'),
          minute: changeHourMinutestr(res.realUseTime, 'minute'),
        }, (mode === 'edit' && isNil(res.selfTest) && {
          selfTest: selfTestValue
        })))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getFetch, id, mode, selfTestValue])

  const confirm = useCallback(() => {
    if (selfTestValue === params?.selfTest) return Messager.show('请填写自我测试', { icon: 'error' })
    const postParams = _.assign({}, _.omit(params, ['hour', 'minute']), {
      assembly: _.join(_.get(params, 'assembly'), ','),
      realUseTime: _.toNumber(params?.hour) * 60 + _.toNumber(params?.minute)
    })
    doFetch(EDIT_URL, postParams)
      .then(() => {
        refresh()
        refreshData()
        setMode('detail')
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, params, refresh, refreshData, selfTestValue])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  return (
    <Dialog
      header={'开发填写详情'}
      style={{ width: 1000 }}
      className='development-completion-details'
      headerVisible={false}
      footerVisible={false}
    >
      <div className='development-completion-details-content flex-y'>
        <div className="mock-dialog-header flex">
          <div className="dialog-title">
            开发填写详情
          </div>
          <div className="mock-right-header flex center-y">
            {
              (loginUser === gd.User.operator_id && tracerState !== 'T6') &&
              <span>
                <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                  if (isClose) return Messager.show('状态已关闭，不允许修改', { icon: 'error' })
                  refreshData()
                  setMode(x => x === 'detail' ? 'edit' : 'detail')
                }} />
              </span>
            }
            <div className={'close-area flex center'} onClick={close}>
              <Icon name={'quxiao'} className={'close-icon'} />
            </div>
          </div>
        </div>
        <div className="development-completion-details-main-panel">
          <Form value={params} onChange={setParams} error={formError} onError={v => setFormError(x => _.assign({}, x, v, { selfTest: isNil(selfTest) ? "必填项！" : null }))}>
            <div>

              <HFormInput label='修改类型' bind='modifiedType' options={typeOpt} required component={isDetail ? Display : Select} convert={v => convertOptions(v, typeOpt)} />
              {
                isDetail ? <HFormInput label='花费时间' bind='realUseTime' component={Display} convert={v => changeHourMinutestr(v)} /> :
                  <div style={{ display: 'inline-block' }}>
                    <div className='real-use-time'>花费时间</div>
                    <div style={{ display: 'inline-block', width: 204 }}>
                      <HFormInput bind='hour' type='number' component={Input} componentWidth={45} placeholder='小时' min={0} max={999} digit={0} />小时
                      <HFormInput bind='minute' type='number' component={Input} componentWidth={45} placeholder='分钟' min={0} max={59} digit={0} />分钟
                    </div>
                  </div>
              }
              <HFormInput label='当前状态' bind='currentState' options={stateOpt} component={isDetail ? Display : Select} convert={v => convertOptions(v, stateOpt)} />
              <HFormInput label='是否修改字典' bind='dictChange' required options={whetherOpt} component={isDetail ? Display : Select} convert={v => convertOptions(v, whetherOpt)} />
              <HFormInput label='是否包含模板' bind='templateFlag' required options={whetherOpt} component={isDetail ? Display : Select} convert={v => convertOptions(v, whetherOpt)} />
              <HFormInput label='性能影响' bind='performanceFlag' options={performanceFlagOpt} component={isDetail ? Display : Select} convert={v => convertOptions(v, performanceFlagOpt)} />
              <HFormInput label='分支代码修改标识' bind='branchFlag' options={whetherOpt} component={isDetail ? Display : Select} labelWidth={125} componentWidth={846} convert={v => convertOptions(v, whetherOpt)} />
              <HFormInput label='最新EXE位置' bind='exePath' component={isDetail ? Display : Input} labelWidth={125} componentWidth={846} maxLength={200} />
              <HFormInput label='回归主线变更集' bind='changeSet' component={isDetail ? Display : Input} labelWidth={125} componentWidth={846} />
              {
                isCts &&
                <HFormInput required label='组件' bind='assembly' component={isDetail ? Display : DisplaySelect} labelWidth={125} componentWidth={846} options={assemblyOpt} search multiple convert={v => {
                  return <Tooltip title={_.join(_.map(v, x => convertOptions(x, assemblyOpt)), '、')}>
                    <div className={'ellipsis-line'}>
                      {_.join(_.map(v, x => convertOptions(x, assemblyOpt)), '、')}
                    </div>
                  </Tooltip>
                }} />
              }
              <HFormInput label='修改文件' bind='modifiedFiles' required component={isDetail ? Display : TextAreaInput} labelWidth={125} componentWidth={846} counter='false' maxLength={999999} className={`style-memo ${isDetail ? 'style-memo-detail' : 'style-memo-edit'}`} />
            </div>
            <div style={{ padding: '0 8px', overflow: 'hidden' }}>
              <Tabs activeKey={activeKey} onChange={setActiveKey} items={[
                {
                  label: <div className={'required-text'}>修改描述</div>,
                  key: 'modifiedDesc',
                  forceRender: true,
                  children: <HFormInput label='' bind='modifiedDesc' required component={isDetail ? Display : TextAreaInput} />
                },
                {
                  label: '查询脚本',
                  key: 'databaseQury',
                  forceRender: true,
                  children: <HFormInput label='' bind='databaseQury' component={isDetail ? Display : TextAreaInput} />
                },
                {
                  label: <div className={'required-text'}>自我测试</div>,
                  key: 'selfTest',
                  forceRender: true,
                  children: <HFormInput
                    label=''
                    bind='selfTest'
                    className='selfTest-textArea'
                    required={isNil(selfTest)}
                    component={isDetail ? Display : TextAreaInput}
                    maxLength={999999} />

                },
                {
                  label: '相关功能测试',
                  key: 'linkTest',
                  forceRender: true,
                  children: <HFormInput label='' bind='linkTest' component={isDetail ? Display : TextAreaInput} />
                },
                {
                  label: <div className={'required-text'}>建议测试</div>,
                  key: 'suggestTest',
                  forceRender: true,
                  children: <HFormInput label='' bind='suggestTest' required component={isDetail ? Display : TextAreaInput} />
                },
              ]} />
            </div>
          </Form>
        </div>
        {/* 底部 */}
        <div className="mock-footer flex center-y">
          <div className="btn-group flex">
            {
              isDetail &&
              <Button normal onClick={close}>关闭</Button>
            }
            {
              !isDetail &&
              <Button normal onClick={() => {
                refreshData()
                setMode(x => x === 'detail' ? 'edit' : 'detail')
              }}>取消</Button>
            }
            {
              !isDetail &&
              <Button primary onClick={confirm} disable={!canSubmit}>确认</Button>
            }
          </div>
        </div>
      </div>
    </Dialog >
  )
}

function changeHourMinutestr(str, mode) {
  if (str !== '0' && str !== '' && str !== null) {
    const hour = (Math.floor(str / 60)).toString().length < 2 ? '0' + (Math.floor(str / 60)).toString() :
      (Math.floor(str / 60)).toString()
    const minute = (str % 60).toString().length < 2 ? '0' + (str % 60).toString() : (str % 60).toString()
    if (mode === 'hour') return hour
    if (mode === 'minute') return minute
    return hour + '小时' + minute + '分钟';
  }
  else {
    return '';
  }
}


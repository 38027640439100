import React, { useMemo, useReducer, useState } from 'react';
import _ from 'lodash'
import { Tabs, Badge } from 'antd';
import { useGet } from 'rootnet-biz/lib/hooks';
import { FormInput, Select } from 'rootnet-edit'
import ImpProjectInfo from "./impProjectInfo/ImpProjectInfo";
import ImpProjectPlan from "./impProjectPlan/ImpProjectPlan";
import ArrowCrumbs from "../../../common/ArrowCrumbs";
import { strParams } from '../../../../utils/publicFun';
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions';
import ImpProjectIssue from './impProjectIssue';
import PublicReqMgt from '../../../common/publicPage/publicReq';
import { isNil } from 'rootnet-core/format';
import './ImpProjectDetail.scss'
import FileUpload from '../../../common/fileUpload/FileUpload';
import ImpPayMilestone from './impPayMilestone';

const { TabPane } = Tabs;

function ImpProjectDetail(props) {
    const { currentId, setCurrentId, convertCollection, goBack, refreshList, history, firstOrder } = props
    const [activeTab, setActiveTab] = useState(isNil(firstOrder) ? 'details' : firstOrder)
    const [isPrincipal, setIsPrincipal] = useState(false)
    const [count, updateDynamic] = useReducer((x) => x + 1, 0)
    const [projectProgress, setProjectProgress] = useState()
    const [projectName, setProjectName] = useState()
    const [projectId, setProjectId] = useState()
    const [fileCount, setFileCount] = useState(0)
    const { data: allUserRes } = useGet('/common/userinfo')
    // const [countOuterFile, updateOuterFile] = useReducer((x) => x + 1, 0) // 附件
    const { data: imgProjectRes } = useGet('/UserSetting/getUniversalInterfaces?code=ID&codeName=ProjectName&tableName=ImplementProject')

    const listOpt = useMemo(() => {
        if (_.isEmpty(imgProjectRes)) return []
        return convertGlobalConstOptions(imgProjectRes)
    }, [imgProjectRes])

    const goBackData = useMemo(() => {
        return [
            { text: '实施项目' },
            {
                text: <FormInput search horizontal
                    options={listOpt}
                    component={Select}
                    value={currentId}
                    onChange={v => {
                        setCurrentId(v)
                        history.replace({ pathname: '/impProjectMgt', search: strParams({ initProjectId: v, firstOrder: activeTab }) })
                    }}
                />, active: true
            },
        ]
    }, [activeTab, currentId, history, listOpt, setCurrentId])

    const operations = useMemo(() => {
        return {
            left: <ArrowCrumbs goBack={goBack} data={goBackData} />,
        }
    }, [goBack, goBackData])

    return <div className={'imp-project-detail flex-y'}>
        <Tabs tabBarExtraContent={operations} activeKey={activeTab} onChange={v => {
            history.replace({ pathname: '/impProjectMgt', search: strParams({ initProjectId: currentId, firstOrder: v }) })
            setActiveTab(v)
        }}>
            <TabPane forceRender tab="项目详情" key="details">
                <ImpProjectInfo {...{ currentId, convertCollection, refreshList, isPrincipal, setIsPrincipal, updateDynamic, projectProgress, setProjectName, projectName, count, setProjectId }} />
            </TabPane>
            <TabPane tab="项目规划" key="plan">
                <ImpProjectPlan {...{ currentId, history, isPrincipal, updateDynamic, setProjectProgress, refreshList, projectName }} />
            </TabPane>
            <TabPane tab="需求" key="req">
                <PublicReqMgt {...{ projectId, projectType: 'IMP', id: currentId, updateDynamic, FUNC_CODE: '180166', RELATION_FUNC_CODE: '1801', replaceParams: { 'View_Requirement_mapping.LeafProjectId': isNil(projectId) ? 'empty' : projectId }, RELATION_REQ_FUNC_CODE: '3600' }} />
            </TabPane>
            <TabPane tab="ISSUE" key="issue">
                <ImpProjectIssue {...{ impProjectId: currentId, updateDynamic }} />
            </TabPane>
            <TabPane tab="付款里程碑" key="payMilestone">
                <ImpPayMilestone edit={isPrincipal} implementProjectId={currentId} />
            </TabPane>
            <TabPane tab={<Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                文档
            </Badge>} key="document">
                <div className={'tab-content-wrap file-wrap fill'}>
                    <FileUpload funcCode={'1801'} {...{ id: currentId, mode: 'edit', setFileCount, allUserRes, isUpload: isPrincipal }} />
                </div>
            </TabPane>
        </Tabs>
    </div>
}

export default ImpProjectDetail;
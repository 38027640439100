import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Drawer, Switch } from 'antd'
import { Input } from 'rootnet-edit'
import { DataGrid, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { useGet, useApi } from '../../../../../utils/hook'
import { moduleInformationFun } from '../formatData'
import { Icon } from '../../../../../components'
import './index.scss'

const RANK_URL = '/common/globalconst?globalConst=userTechLevel'
const ADD_POST_URL = '/moduleFactor/add' // 添加模块岗位分工系数
const EDIT_POST_URL = '/moduleFactor/edit' // 编辑模块岗位分工系数
const ADD_RANK_URL = '/moduleRank/add' // 新增模块职级结构
const EDIT_RANK_URL = '/moduleRank/edit' // 编辑模块职级结构

function getColumns(props) {
  const { rankData, editInfo, setEditInfo, editUpdate, setValue, isFuncCodeModule, defaultPositionOptions } = props
  const { editId = null, editField = null } = editInfo || {}
  const size = _.size(defaultPositionOptions)
  return [
    { header: '模块', bind: 'moduleName', align: 'left', width: 150, tooltip: true, convert: (v, o, i) => getRowSpan(i) ? ({ value: v, rowSpan: size }) : v },
    { header: '岗位分类', bind: 'positionName', align: 'center', width: 90, tooltip: true },
    { header: '模块岗位分工系数', bind: 'factor', align: 'center', width: 110, tooltip: true, convert: (v, o) => convertEditField(v, o, 'factor', o.idModuleFactor) },
    {
      align: 'center',
      header: '模块职级结构',
      children: _.map(rankData, o => ({
        header: o.displayName,
        bind: o.interiorId,
        align: 'center',
        width: 90,
        tooltip: true,
        convert: (v, item) => convertEditField(v, item, o.interiorId, item[`${o.interiorId}Id`])
      }))
    },

  ]
  function getRowSpan(num) {
    if (num === 0) return true
    return num % size === 0
  }

  function convertEditField(v, o, bind, id) {
    const val = getPercentage(v)
    if (!isFuncCodeModule) return val === 0 ? '-' : `${val}%`
    let url = EDIT_RANK_URL
    if (bind !== 'factor' && isNil(id)) url = ADD_RANK_URL
    if (bind === 'factor' && isNil(o.idModuleFactor)) url = ADD_POST_URL
    if (bind === 'factor' && !isNil(o.idModuleFactor)) url = EDIT_POST_URL
    if (editId === `${o.module}${o.position}` && editField === bind) {
      return <Input
        autoFocus
        defaultValue={val}
        type='number'
        suffix='%'
        min={0}
        max={100}
        onChange={num => setValue(num)}
        onBlur={() => editUpdate(val, id, url, o, bind)}
        onEnter={() => editUpdate(val, id, url, o, bind)}
      />
    }
    return <div
      className='convert-edit-field-style'
      onClick={() => setEditInfo({ editId: `${o.module}${o.position}`, editField: bind })}
    >
      {val === 0 ? '-' : `${val}%`}
      <Icon className='edit-icon' name='bianji2' />
    </div>
  }

  function getPercentage(v) {
    if (_.isNaN(_.toNumber(v)) || _.toNumber(v) === 0) return 0
    if (_.indexOf((v * 100).toString(), '.') === -1) return v * 100
    else {
      const l = _.get(_.split((v * 100).toString(), '.'), '[1][0]', '0')
      return `${parseInt(v * 100)}${l === '0' ? '' : '.' + l}`
    }
  }
}

const gridOptsFor = (options) => ({
  onRenderRow: (o, i, columns) => {
    return {
      style: _.assign({}, o.index % 2 === 0 && { backgroundColor: '#eeeeee' })
    }
  },
  nilText: '-',
  fixedLeft: 2,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns: options,
  virtualized: false,
});

export default function ModuleInformation(props) {
  const { visible, refresh, data, close = () => { }, isFuncCodeModule, isModule, year, moduleInfoFetch, defaultPositionOptions } = props
  const [editInfo, setEditInfo] = useState({})
  const [value, setValue] = useState()
  const [flag, setFlag] = useState(false)
  const { data: rankData, loading } = useGet(RANK_URL)
  const { doFetch } = useApi()
  const dataSource = useMemo(() => moduleInformationFun(data, isModule, defaultPositionOptions), [data, defaultPositionOptions, isModule])

  const editUpdate = useCallback((val, id, url, item, bind) => {
    if (val === value || _.isNil(value)) return destroyValue()
    let parameter = {}
    const result = value === '' ? '0' : value / 100
    if (url === EDIT_POST_URL || url === ADD_POST_URL) {
      parameter = _.assign({
        factor: result,
      }, url === ADD_POST_URL && { ..._.pick(item, ['effectiveDate', 'module', 'position']), dateTimeResources: year }
        , url === EDIT_POST_URL && { id }
      )
    }

    if (url === EDIT_RANK_URL || url === ADD_RANK_URL) {
      parameter = _.assign({
        techLevelPercent: result,
      }, url === ADD_RANK_URL && { ..._.pick(item, ['module', 'position']), effectiveDate: `${year}0101000000`, techLevel: bind }
        , url === EDIT_RANK_URL && { id }
      )
    }

    doFetch(url, 'post', parameter)
      .then(() => {
        refresh(flag)
        destroyValue()
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => {
        destroyValue()
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, flag, refresh, value, year])

  const option = useMemo(() => gridOptsFor(getColumns({ rankData, editInfo, setEditInfo, editUpdate, setValue, isFuncCodeModule, defaultPositionOptions })), [editInfo, editUpdate, isFuncCodeModule, defaultPositionOptions, rankData])

  const title = useMemo(() => {
    return <div className='headr-title flex'>
      <div className='title'>模块信息</div>
      <div className='switch-type'>
        <Switch checkedChildren="全部模块" unCheckedChildren="当前模块" onChange={v => { setFlag(v); moduleInfoFetch(v) }} />
      </div>
    </div>
  }, [moduleInfoFetch])

  return (
    <Drawer
      title={title}
      destroyOnClose
      onClose={() => { destroyValue(); close(); moduleInfoFetch(false); setFlag(false) }}
      visible={visible}
      className='module-information antd-default-style'
    >
      <DataGrid loading={loading} data={dataSource} option={option} />
    </Drawer>
  )

  function destroyValue() {
    setEditInfo({ editId: null, editField: null })
    setValue(null)
  }

}

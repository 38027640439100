import React, { useState } from "react";
import _ from 'lodash'
import { MessageBox, Messager } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { isNil } from "rootnet-core/format";
import TextAreaInput from "../../../../../../common/textAreaInput/TextAreaInput";
import './index.scss'

export default function DelMessage(props) {
  const { header = '删除', close, changeData, selectedRows, dataManipulation, actionType, currentId, versionType = false } = props;
  const [remark, setRemark] = useState()

  return <MessageBox header={header} confirm={handleConfirm} cancel={close} className={'version-req-del-log content-center-dialog common-del-message-dialog'}>
    <FormInput className='text-area-input-edit' horizontal required component={TextAreaInput} bind='remark' label='说明' componentWidth={300} value={remark} onChange={setRemark} />
  </MessageBox>

  function handleConfirm() {
    if (isNil(remark)) return Messager.show('请填写说明信息', { icon: 'error' })

    if (actionType === 'del') {
      dataManipulation([_.assign({}, changeData, { releaseid: currentId, remark }, versionType && { type: 'PLANREQ' }, changeData?.type === 'DEV' && { type: 'REQ' })])
    }
    else {
      const paramsArr = []
      const reqList = _.filter(selectedRows, o => (o.type === 'REQ' && !_.isNil(o.inRelease)))
      const issueList = _.filter(selectedRows, o => o.type === 'ISSUE')
      const defectList = _.filter(selectedRows, o => o.type === 'DEFECT')
      const devList = _.filter(selectedRows, o => o.type === 'DEV')
      if (!_.isEmpty(reqList)) paramsArr.push(_.assign({ type: versionType ? 'PLANREQ' : 'REQ', optionType: 'DEL', releaseid: currentId, storyidList: _.map(reqList, o => o.id), remark }))
      if (!_.isEmpty(issueList)) paramsArr.push(_.assign({ type: versionType ? 'PLANISSUE' : 'ISSUE', optionType: 'DEL', releaseid: currentId, storyidList: _.map(issueList, o => o.id), remark }))
      if (!_.isEmpty(defectList)) paramsArr.push(_.assign({ type: 'DEFECT', optionType: 'DEL', releaseid: currentId, storyidList: _.map(defectList, o => o.id), remark }))
      if (!_.isEmpty(devList)) paramsArr.push(_.assign({ type: versionType ? 'PLANREQ' : 'REQ', optionType: 'DEL', releaseid: currentId, storyidList: _.map(devList, o => o.id), remark }))
      dataManipulation(paramsArr)
    }
    close()
  }
}

import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react'
import _ from 'lodash'
import { DataGrid, Pagination, Messager, Dialog } from 'rootnet-ui'
import { CheckBox } from 'rootnet-edit'
import { useGet, usePost } from 'rootnet-biz/lib/hooks';
import { Box } from '../../../../common/commonComponent';
import { TextIconBtn } from '../../../../common/TextIconBtn';
import convertTableAlign from '../../../../common/view/convertTableAlign';
import findConvert from '../../../../common/view/findConvert';
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig';
import useRefreshList from '../../../../common/view/hooks/useRefreshList';
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions';
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList';
import { ScheduleManagementContext } from '../../../../common/Context';
import ViewArea from '../../../../common/view/ViewArea';
import ViewQueryDialog from '../../../../common/view/viewQueryDialog/ViewQueryDialog';
import DefectUpdateDialog from '../../../defect/controls/DefectUpdateDialog';
import './index.scss'

const ECHO_DEFECT_URL = '/test_case/selectDefectCase'
const ASSOCIATED_DEFECT_URL = '/test/defect/case/related/operator' //关联缺陷
const ENTER_DETAIL_FIELD = 'test_defect.title'
function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, selectedId, onRowClick, isSelectAll, onSelectAll, setCurrentInfo } = props

  const customColumns = []

  return [
    {
      header: <CheckBox value={isSelectAll} onChange={onSelectAll} />, width: 40, stretch: false, align: 'center', convert: (v, o) => {
        return <div className={'check-item flex center'} onClick={() => onRowClick(o)}>
          <CheckBox value={_.includes(selectedId, o.id)} />
        </div>
      }
    },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }
  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className='enter-detail-field-wrap'>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentInfo({ mode: 'defect', id: o.id })
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-display-field'}>
      {showValue || '-'}
    </div>
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '00122'

export default function CaseDefect(props) {
  const { id, upDateDefect, isShow = false } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [currentInfo, setCurrentInfo] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [factor, setFactor] = useState()
  const { data: allowExport } = useGet(`/FuncTable/getAllowExportFlag?funcCode=` + FUNC_CODE)
  const { doFetch } = usePost()
  const { doFetch: getDefect, data: defaultDefect } = useGet()

  const [isSelectAll, setIsSelectedAll] = useState(false)
  const [selectedId, setSelectedId] = useState()
  const [pageList, setPageList] = useState([])
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if (_.isNil(id)) return
    getDefect(`${ECHO_DEFECT_URL}?caseId=${id}`)
  }, [id, getDefect])

  const replaceParams = useMemo(() => {
    if (_.isNil(id)) return {}
    return {
      'test_defect_case_related.caseId': id,
    }
  }, [id])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, })

  const dateOptions = useGetDateOptions()

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '缺陷')
  }, [currentViewId, fieldList, factor, downloadPostProgress])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        isShow && <>
          <TextIconBtn text='关联缺陷' icon='sousuo1' onClick={() => {
            setCurrentInfo({ mode: 'relevance' })
          }} />
          <TextIconBtn text='取消关联' icon='quxiaoguanlian' onClick={() => {
            if (_.size(selectedId) === 0) return Messager.show('请选择后在进行删除', { icon: 'error' })
            setCurrentInfo({ mode: 'cancel' })

          }} />
        </>
      }
      {
        allowExport === 'Y' &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
    </div>
  }, [allowExport, exportViewList, selectedId, isShow])

  const handChange = useCallback((data) => {
    const ids = _.map(defaultDefect, o => o?.defectId)
    if (!_.isEmpty(checkData(data, ids)) || !_.isEmpty(checkData(ids, data))) {
      const parameter = _.concat([],
        _.map(checkData(data, ids), v => ({ caseId: id, defectId: v, type: 'add' })),
        _.map(checkData(ids, data), v => ({ caseId: id, defectId: v, type: 'del' }))
      )
      doFetch(ASSOCIATED_DEFECT_URL, parameter)
        .then(() => {
          upDateDefect()
          setSelectedId(null)
          refreshList()
          Messager.show('成功', { icon: 'success' })
        })
        .catch(err => {
          Messager.show(`${err._message}`, { icon: 'error' })
        })
    }
  }, [id, doFetch, refreshList, defaultDefect, upDateDefect])

  const delItems = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    if (_.isEmpty(selectedId)) return
    const parameter = _.map(selectedId, v => ({ caseId: id, defectId: v, type: 'del' }))
    doFetch(ASSOCIATED_DEFECT_URL, parameter)
      .then(() => {
        upDateDefect()
        setSelectedId(null)
        setCurrentInfo(null)
        refreshList()
        setIsDisable(false)
        Messager.show('成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(`${err._message}`, { icon: 'error' })
      })
  }, [id, selectedId, doFetch, refreshList, isDisable, upDateDefect])

  const onRowClick = useCallback((item) => {
    if (_.includes(selectedId, item.id)) {
      setSelectedId(oldList => {
        return _.filter(oldList, x => x !== item.id)
      })
    } else {
      setSelectedId(oldList => {
        return _.compact(_.concat(oldList, [item.id]))
      })
    }
  }, [selectedId])

  const onSelectAll = useCallback(() => {
    if (isSelectAll) {
      const pageIdList = _.map(pageList, 'id') || []
      setSelectedId(oldList => {
        return _.filter(oldList, x => !_.includes(pageIdList, x))
      })
    } else {
      setSelectedId(oldList => {
        const addIdList = _.map(pageList, 'id') || []
        return _.compact(_.uniq(_.concat(oldList, addIdList)))
      })
    }
  }, [isSelectAll, pageList])

  const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { selectedId, onRowClick, isSelectAll, onSelectAll, setCurrentInfo }, })

  useEffect(() => {
    setPageList(dataGridList)
  }, [dataGridList])

  useEffect(() => {
    if (_.isEmpty(dataGridList)) return setIsSelectedAll(false)
    if (_.isEmpty(selectedId)) return setIsSelectedAll(false)
    const pageAllIdList = _.map(dataGridList, 'id')
    const isSame = _.every(pageAllIdList, x => _.includes(selectedId, x))
    setIsSelectedAll(isSame)
  }, [dataGridList, selectedId])

  return (
    <div className='case-defect-l fill'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={'验证缺陷'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'relevance' &&
        <ViewQueryDialog
          bizName='缺陷'
          initValue={_.map(defaultDefect, o => o?.defectId)}
          multiple={true}
          close={() => setCurrentInfo(null)}
          funcCode={'0012'}
          outerSetId={handChange}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'cancel' &&
        <Dialog
          header='提示'
          cancel={() => setCurrentInfo(null)}
          confirm={delItems}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 0,
          }}
        >
          确认删除吗？
        </Dialog>
      }
      {
        _.get(currentInfo, 'mode') === 'defect' &&
        <DefectUpdateDialog
          mode={'detail'}
          close={() => setCurrentInfo(null)}
          currentId={_.get(currentInfo, 'id')}
          refreshList={refreshList}
        />
      }
    </div>
  )
}

function checkData(arr1, arr2) {
  return _.compact(_.map(arr1, o => { if (!_.includes(arr2, o)) return o }))
}

import React, { useCallback, useState } from 'react'
import { Dialog } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'

export default function CsvExportNumLog(props) {
  const { downLoad, close } = props
  const [exportNum, setExportNum] = useState(10000)

  const confirm = useCallback(() => {
    downLoad(exportNum)
    close()
  }, [close, downLoad, exportNum])

  return (
    <Dialog
      header={'导出'}
      cancel={close}
      confirm={confirm}
    >
      <FormInput
        horizontal
        label='导出数量'
        type='number'
        min={0}
        digit={0}
        value={exportNum}
        onChange={setExportNum}
      />
    </Dialog>
  )
}

import React, {useCallback, useMemo, useState} from 'react';
import './CustomerSystemAdd.scss'
import {Dialog, Button, Messager} from 'rootnet-ui'
import {Form, FormInput, TextArea, Select} from 'rootnet-edit'
import _ from "lodash"
import {Checkbox} from "antd";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import {usePost} from "rootnet-biz/es/hooks";

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=status',
    'common/globalconst?globalConst=industry',
]

const OPTIONS_URLS = [
    '/customer/customerList'
]

const HFormInput = props => <FormInput horizontal labelWidth={90} componentWidth={180} {...props}/>

function CustomerSystemAdd(props) {
    const {close,refreshViewList} = props
    const [formData, setFormData] = useState()
    const [formError, setFormError] = useState()
    const [continueAdd, setContinueAdd] = useState(false)
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: optionsRes} = useGet(OPTIONS_URLS)
    const [loading, setLoading] = useState(false)
    const {doFetch: submitPost} = usePost()

    const [statusOptions, industryOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const [customerOptions] = useMemo(()=>{
        if(_.isEmpty(optionsRes)) return []
        const [d1] = optionsRes
        return [
            _.map(d1, x => ({ text: x.tCustname, value: x.tCustid, tag: `${x.tCustname}${x.tCustid}` }))
        ]
    },[optionsRes])

    const changeForm = useCallback((newObj)=>{
        setFormData(oldObj => {
            let cloneObj = {...oldObj, ...newObj}
            return cloneObj
        })
    },[])

    const canSubmit = useMemo(()=>{
       return _.every(formError, _.isNil)
    },[formError])

    const continueClear = useCallback(()=>{
        setFormData(old => {
            const cloneObj = {...old}
            _.forEach(cloneObj, (value, key) => cloneObj[key] = null)
            return {
                ...cloneObj,
            }
        })
    },[])

    const submit = useCallback(()=>{
        if(loading) return
        setLoading(true)
        const tCustName = _.get(_.find(customerOptions, x => x.value === _.get(formData,'tCustId')),'text')
        const postData = {
            ...formData,
            tCustName
        }
        submitPost('/bug/project/add',postData).then(() => {
            setLoading(false)
            Messager.show('添加成功', { icon: 'success' })
            refreshViewList()
            if(continueAdd){
                continueClear()
            }else{
                close()
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setLoading(false)
        })
    },[loading, continueAdd, continueClear,close,formData,submitPost,refreshViewList,customerOptions])

    return <Dialog header={'新增系统'} className={'customer-system-add'} footerVisible={false} cancel={()=>close()}>
        <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({},x,v))}>
            <HFormInput label={'系统ID'} bind={'projectId'} required/>
            <HFormInput label={'系统名称'} bind={'projectName'} required/>
            <HFormInput label={'状态'} bind={'status'} required options={statusOptions} component={Select}/>
            <HFormInput label={'客户名称'} bind={'tCustId'} required options={customerOptions} component={Select} search/>
            <HFormInput label={'行业'} bind={'tCusttype'} required options={industryOptions} component={Select}/>
            <HFormInput label={'备注'} bind={'addMemo'} component={TextArea} componentWidth={752} maxLength={200}/>
        </Form>
        <div className="mock-footer flex center-y">
            <div className={'continue-add-check'}>
                <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
            </div>
            <div className="btn-group flex">
                <Button normal onClick={close}>取消</Button>
                <Button primary disabled={!canSubmit} onClick={submit}>确认</Button>
            </div>
        </div>
    </Dialog>
}

export default CustomerSystemAdd;
import React from 'react'
import { Icon } from '../../../project_share/components'
import './header.scss'
export default function Header(props) {
  const { goBack, content, icon } = props
  return (
    <header className='commonHead'>
      <p><Icon onClick={() => goBack()} name={icon} /></p>
      <p>{content}</p>
      <p></p>
    </header>
  )
}

import React, { useContext, useEffect, useMemo, useState, useRef } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager, Drawer, DataGrid, Tooltip } from 'rootnet-ui'
import { Select, Form, FormInput } from 'rootnet-edit'
import { EXECUTION_COLOR } from './publicData'
import { TableContext, ValueContext } from '../../../common/Context'
import { useGet, useApi } from '../../../../utils/hook'
import { Box } from '../../../common/commonComponent'
import { strParams } from '../../../../utils/publicFun'
import { localRefresh } from '../../components/getTreeList'
import PersonSelect from "../../../common/personSelect/PersonSelect";
import RichTextEditor from '../../../common/richTextEditor/TinyEditor'
import TestPlanStageUploadArea from './TestPlanStageUploadArea'
import { Icon } from '../../../../components'

const ADD_URL = '/test/plan/insertTestCaseResult' // 执行
const EDIT_URL = '/test/plan/updateTestCaseResult' //分配负责人
const STATUS_URL = '/common/globalconst?globalConst=caseResultStatus'
const EXECUTE_URL = '/test/plan/selectTestCaseResult'
const USER_URL = '/common/userinfo?indexValidFlag=1'

const headeStyle = {
  fontSize: 1,
  color: '#BBBBBB'
}

const getTableOption = {
  autoFill: true,
  virtualized: true,
  resizable: true,
  nilText: '-',
  emptyText: '-',
  columns: [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    { header: '执行人', bind: 'executorName', width: 90, tooltip: true },
    { header: '执行状态', bind: 'statusName', width: 60, tooltip: true, convert: status },
    {
      header: '实际执行结果', bind: 'actualResult', width: 200, convert: v => {
        return <Tooltip title={<div dangerouslySetInnerHTML={{ __html: v || '' }} />}>
          <div className='actual-result-html' dangerouslySetInnerHTML={{ __html: v || '' }} />
        </Tooltip>
      }
    },
    { header: '执行时间', bind: 'executTime', width: 120, tooltip: true },
    {
      header: '附件', width: 60, convert: (v, o) => {
        return <Popover content={<TestPlanStageUploadArea funcCode={'0503'}
          {...{ id: _.head(o.mappingFiles)?.referenceId }} />}>
          <span className={'flex center relate-document-icon-wrap'} >
            <Icon name={'lianjie'} style={{ fontSize: 14 }} className={'relate-document-icon'} />({_.size(o.mappingFiles)})
          </span>
        </Popover>
      }
    },
  ],
}

function status(v, o) {
  return <span style={{ color: EXECUTION_COLOR[o.status] }}>{v}</span>
}

export function Implement() {
  const { setModeAll, modeAll, planId, tableData, setTableData } = useContext(TableContext)
  const { allRefresh } = useContext(ValueContext)
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const resultEnforcementRef = useRef()
  const { data } = useGet(STATUS_URL)
  const { doFetch } = useApi()
  const [isDisable, setIsDisable] = useState(false)

  const header = useMemo(() => {
    return <>
      执行
      <span style={headeStyle}>{`（已选择${_.size(modeAll.id)}个）`}</span>
    </>
  }, [modeAll.id])

  const option = useMemo(() => {
    if (data) return _.map(data, x => {
      return {
        value: x.interiorId,
        text: <span style={{ color: EXECUTION_COLOR[x.interiorId] }}>{x.displayName}</span>,
        tag: `${x.interiorId} ${x.displayName}`
      }
    })
  }, [data])

  return (
    <Dialog
      header={header}
      cancel={() => setModeAll(null)}
      confirm={confirm}
      confirmButtonText='提交'
      className='implement'
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput required horizontal label='测试结果' bind='status' labelWidth={100} componentWidth={600} component={Select} options={option} />
        <div className="rich-text-area flex" style={{ minHeight: 300 }}>
          <label>实际执行结果</label>
          <div style={{ width: 600 }}><RichTextEditor ref={resultEnforcementRef} /></div>
        </div>
      </Form>
    </Dialog>
  )

  function confirm() {
    if (!check()) return Messager.show('请填写测试结果', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    const actualResult = resultEnforcementRef.current.getContent()
    const parameter = _.assign({}, params, { caseIdList: modeAll.id, planId, principle: modeAll.principle, actualResult })
    doFetch(ADD_URL, 'post', parameter)
      .then((res) => {
        setIsDisable(false)
        allRefresh()
        setModeAll(null)
        setTableData(localRefresh(tableData, res))
        Messager.show(`执行成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisable(false)
        setModeAll(null)
        Messager.show(err._message, { icon: 'error' })
      })
  }

}

export function Distribution() {
  const { setModeAll, modeAll, refresh, planId } = useContext(TableContext)
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { data: allUserRes } = useGet(USER_URL)
  const { doFetch } = useApi()

  const header = useMemo(() => {
    return <>
      分配用例负责人
      <span style={headeStyle}>{`（已选择${_.size(modeAll.id)}个）`}</span>
    </>
  }, [modeAll.id])

  return (
    <Dialog
      header={header}
      cancel={() => setModeAll(null)}
      confirm={confirm}
      className='distribution'
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput
          search
          required
          horizontal
          label='用例负责人'
          bind='principle'
          componentWidth={300}
          useOuterUser={true}
          outerAllUserRes={allUserRes}
          component={PersonSelect} />
      </Form>
    </Dialog>
  )
  function confirm() {
    if (!check()) return Messager.show('请填写用例负责人', { icon: 'error' })
    if (isDisable) return
    setIsDisable(true)
    const parameter = _.assign({}, params, { caseIdList: modeAll.id, planId })
    doFetch(EDIT_URL, 'post', parameter)
      .then(() => {
        setIsDisable(false)
        refresh()
        setModeAll(null)
        Messager.show(`分配用例负责人成功`, { icon: 'success' })
      })
      .catch((err) => {
        setIsDisable(false)
        setModeAll(null)
        Messager.show(err._message, { icon: 'error' })
      })
  }
}

export function ExecuteDrawer() {//执行次数
  const { setModeAll, modeAll, planId } = useContext(TableContext)
  const { data, doFetch, error, loading } = useGet()
  const isShow = useMemo(() => modeAll?.mode === 'execute', [modeAll])
  useEffect(() => {
    if (isShow) {
      const parameter = {
        planId,
        caseId: modeAll.caseId,
      }
      doFetch(`${EXECUTE_URL}?${strParams(parameter)}`)
    }
  }, [doFetch, isShow, modeAll, planId])

  return <Drawer
    header='测试用例执行结果'
    className='executeDrawer'
    cancel={() => setModeAll(null)}
    onOutsideClick={() => setModeAll(null)}
    open={isShow} demount={true}
    footerVisible={false}
  >
    <Box className='x-card-singlegrid' title='用例结果' error={error} loading={loading} data={data}>
      <DataGrid data={data} option={getTableOption} />
    </Box>
  </Drawer>

}

function check(error) {
  return _.every(_.values(error), isNil)
}
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Checkbox, Select as AntdSelect } from 'antd'
import { isNil } from 'rootnet-core/format'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Button, Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput, RadioGroup, Select, CheckBox } from 'rootnet-edit'
import { ScheduleManagementContext } from '../../../../../../common/Context'
import { strParams } from '../../../../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import './index.scss'
import convertOptions from '../../../../../../common/ConvertOptions'

const EXPORT_SCRIPT_URL = '/releaseinfo/export/script' // 脚本导出
const FILE_ENCODING_URL = '/common/globalconst?globalConst=CHARSETS'

const GET_DATA_INFO_URL = '/checkList/data/base/info' //获取数据库信息
const TEMPLATE_TYPR_URL = '/common/globalconst?globalConst=CheckListTemplateType'
const TEST_LINK_URL = '/checkList/test/connect' //测试链接
const EXPORT_CHECK_LIST_URL = '/checkList/export' // checkList导出

const EXPORT_MODIFY_FILE_URL = '/exportModifyFile/export' //修改文件
const EXPORT_MODIFY_FILE_POST_URL = '/exportModifyFile/exportUserProject' //修改文件
const MODIFY_FILE_TYPE_URL = '/exportModifyFile/check/list'

const EXPORT_INVENTORY_URL = '/develop/bugSaveasModule/exportReleaseNote'

const DEFAULT_URL = '/releaseinfo/export/filter'

const HFormInput = props => <FormInput horizontal labelWidth={100} componentWidth={240} {...props} />

export function InventoryExport(props) { // 清单
  const { close, currentId, mulcustId, defaultClient, upgradePath = null, isClient = false, projectId, isAll } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [isShow, setIsShow] = useState(false)
  const [security, setSecurity] = useState([])
  const { data: securityRes } = useGet('/story/getbugProject')
  const { data: defaultData } = useGet(`${DEFAULT_URL}?type=ExportReleaseNote`)
  const [isLoading, setIsLoading] = useState()

  const securityOpt = useMemo(() => {
    if (_.isEmpty(securityRes)) return []
    const filterData = _.filter(securityRes, o => _.includes(mulcustId, o.projectId))
    const concatData = _.concat(_.includes(mulcustId, 'ALL') ? [{ projectName: '全部客户', projectId: 'ALL' }] : [], isClient ? securityRes : filterData)

    return _.map(concatData, o => ({
      label: o.projectName,
      value: o.projectId,
    }))

  }, [mulcustId, securityRes, isClient])

  const confirm = useCallback(() => { //transferTemplateName
    if (isLoading) return
    const par = _.assign({}, { releaseIds: _.isEmpty(upgradePath) ? _.split(currentId, ',') : upgradePath, securityNames: security, transferTemplateName: 'releaseNote' }, params, isAll && { projectId })
    downloadPostProgress(isAll ? '/develop/bugSaveasModule/exportAllReleaseNote' : EXPORT_INVENTORY_URL, par, '导出ReleaseNote', close, setIsLoading)
  }, [close, currentId, downloadPostProgress, params, security, upgradePath, isLoading, isAll, projectId])

  useEffect(() => {
    if (!isShow) {
      setSecurity([])
    }
    const defaultParams = JSON.parse(defaultData)
    const initParams = _.isNil(defaultData) ? { cSRecordID: false, upgradeDesc: false } : _.assign({}, defaultParams, { cSRecordID: _.get(defaultParams, 'csaRecordID', false) })
    setParams(_.assign({}, initParams, { productFlag: isShow, haveDev: false }))
  }, [isShow, defaultData, defaultClient])

  useEffect(() => {
    if (!_.isEmpty(defaultClient)) {
      setSecurity(defaultClient)
      setIsShow(true)
    }
  }, [defaultClient])

  return (//'导出releaseNote'
    <Dialog
      header={<div className='title-wrap flex center-y'>
        <div className='title-text'>导出releaseNote</div>
        <div className='text-hint'>其中”历史缺陷“的导出，只包含研发项目【项目范围】里的历史缺陷！</div>
      </div>}
      className='inventory-export-log'
      confirm={confirm}
      cancel={close}
      loading={isLoading}
    >
      <Form value={params} onChange={setParams}>
        <div style={{ paddingLeft: '10px', minHeight: 32 }}>
          <Checkbox
            checked={isShow}
            value={isShow}
            onChange={e => setIsShow(e.target.checked)}
          >
            按客户导出
          </Checkbox>
          {
            isShow &&
            <>
              <AntdSelect
                mode='multiple'
                placeholder='请选择客户'
                style={{ width: '215px' }}
                allowClear
                options={securityOpt}
                optionFilterProp='label'
                value={security}
                onChange={v => setSecurity(v)}
              />
              <Checkbox
                style={{ marginLeft: 8 }}
                checked={_.get(params, 'productFlag', false)}
                value={_.get(params, 'productFlag', false)}
                onChange={e => setParams(x => {
                  return _.assign({}, x, { productFlag: e.target.checked })
                })}
              >
                按产品导出
              </Checkbox>
            </>
          }
          <div style={{ marginTop: 8 }}>
            <Checkbox
              checked={_.get(params, 'cSRecordID', false)}
              value={_.get(params, 'cSRecordID', false)}
              onChange={e => setParams(x => {
                return _.assign({}, x, { cSRecordID: e.target.checked })
              })}
            >
              客户服务单编号
            </Checkbox>
            <Checkbox
              checked={_.get(params, 'upgradeDesc', false)}
              value={_.get(params, 'upgradeDesc', false)}
              onChange={e => setParams(x => {
                return _.assign({}, x, { upgradeDesc: e.target.checked })
              })}
            >
              升级配置说明
            </Checkbox>
            <Checkbox
              checked={_.get(params, 'customer', false)}
              value={_.get(params, 'customer', false)}
              onChange={e => setParams(x => {
                return _.assign({}, x, { customer: e.target.checked })
              })}
            >
              客户
            </Checkbox>
          </div>
          <div style={{ marginTop: 8 }} >
            <Checkbox
              checked={_.get(params, 'haveDev', false)}
              value={_.get(params, 'haveDev', false)}
              onChange={e => setParams(x => {
                return _.assign({}, x, { haveDev: e.target.checked })
              })}
            >
              包含开发自提单
            </Checkbox>
          </div>
        </div>
      </Form>
    </Dialog>
  )
}

const scriptOpt = [
  { value: 'ORACLE', text: 'ORACLE' },
  { value: 'MYSQL', text: 'MYSQL' },
  { value: 'SQLSERVER', text: 'SQLSERVER' },
  { value: 'DM', text: 'DM' },
]

const scriptTypeOpt = [
  { value: 'CTS', text: 'CTS' },
  { value: 'other', text: '非CTS' },
  { value: 'EMSS', text: 'EMSS' },
]

export function ScriptExport(props) { // 脚本
  const { close, currentId, projectId, isAll, mulcustId, isClient } = props
  const [params, setParams] = useState({ type: 'ORACLE', charsets: 'GB2312', scriptType: 'CTS' })
  const { data: charsetsRes } = useGet(FILE_ENCODING_URL)
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const { data: defaultData } = useGet(`${DEFAULT_URL}?type=ExportScript`)
  const { data: securityRes } = useGet('/story/getbugProject')

  const securityOpt = useMemo(() => {
    if (_.isEmpty(securityRes)) return []
    const filterData = _.filter(securityRes, o => _.includes(mulcustId, o.projectId))
    const concatData = isClient ? securityRes : filterData

    return _.map(concatData, o => ({
      text: o.projectName,
      value: o.projectId,
    }))

  }, [mulcustId, securityRes, isClient])

  const charsetsOpt = useMemo(() => {
    if (_.isEmpty(charsetsRes)) return []
    return convertGlobalConstOptions(charsetsRes)
  }, [charsetsRes])

  const confirm = useCallback(() => {
    const { custId } = params
    const custName = convertOptions(custId, securityOpt)
    const par = _.assign({}, { ReleaseIds: currentId }, params, !isNil(custName) && { custName }, isAll && { projectId })
    downloadPostProgress(isAll ? '/releaseinfo/export/allScript' : EXPORT_SCRIPT_URL, par, '导出脚本', close)
  }, [close, currentId, downloadPostProgress, params, projectId, isAll, securityOpt])

  useEffect(() => {
    if (isNil(defaultData)) return
    const defaultParams = JSON.parse(defaultData)
    setParams(defaultParams)
  }, [defaultData])

  return (
    <Dialog
      header='导出脚本'
      className='script-export-log'
      confirm={_.debounce(confirm, 300)}
      cancel={close}
    >
      <Form value={params} onChange={setParams}>
        {/* <FormInput horizontal label='客户' bind='custId' component={Select} options={securityOpt} placeholder='请选择客户' search clear /> */}
        {/* <div /> */}
        <FormInput horizontal label='数据库' bind='type' component={RadioGroup} options={scriptOpt} />
        <div />
        <FormInput horizontal label='特殊脚本格式' bind='scriptType' component={RadioGroup} options={scriptTypeOpt} />
        <div />
        <FormInput horizontal label='文件编码' bind='charsets' component={Select} options={charsetsOpt} />
      </Form>
    </Dialog>
  )
}

export function ModifyFileExport(props) { // 修改文件
  const { close, currentId, projectName, projectId, isAll } = props
  const [modifiedType, setModifiedType] = useState()
  const { data: modifyTypeRes, loading } = useGet(MODIFY_FILE_TYPE_URL)
  const [onlyMakeList, setOnlyMakeList] = useState(true)
  const { downloadGetProgress, downloadPostProgress } = useContext(ScheduleManagementContext)
  const { data: defaultData } = useGet(`${DEFAULT_URL}?type=ExportModifyFile`)

  const isProjectName = useMemo(() => {
    return isNil(projectName)
  }, [projectName])

  const modifyTypeOpt = useMemo(() => {
    if (_.isEmpty(modifyTypeRes)) return []
    const key = _.keys(modifyTypeRes)
    const val = _.values(modifyTypeRes)
    return _.map(key, (v, i) => ({ text: val[i], value: v }))
  }, [modifyTypeRes])

  const confirm = useCallback(() => {
    if (_.isNil(modifiedType)) return Messager.show('请选择导出文件列表类型', { icon: 'error' })
    if (isProjectName) {
      downloadGetProgress(`${EXPORT_MODIFY_FILE_URL}?${strParams({ releaseId: currentId, modifiedType, onlyMakeList: onlyMakeList ? 'Y' : 'N' })}`, '导出修改文件', close)
    } else {
      const par = _.assign({}, { modifiedType, onlyMakeList: onlyMakeList ? 'Y' : 'N', projectName, releaseIdList: _.split(currentId, ',') }, isAll && { projectId })
      downloadPostProgress(isAll ? '/exportModifyFile/exportAllUserProject' : EXPORT_MODIFY_FILE_POST_URL, par, '导出修改文件', close)
    }
  }, [close, currentId, downloadGetProgress, downloadPostProgress, modifiedType, onlyMakeList, isProjectName, projectName, isAll, projectId])

  useEffect(() => {
    if (isNil(defaultData)) return
    const { modifiedType, onlyMakeList } = JSON.parse(defaultData) || {}
    setModifiedType(modifiedType)
    !isNil(onlyMakeList) && setOnlyMakeList(onlyMakeList === 'Y')
  }, [defaultData])

  return (
    <Dialog
      header='导出修改文件'
      className='modify-file-export-log'
      cancel={close}
      loading={loading}
      footerVisible={false}
    >
      <div className="content-panel flex-y">
        <div className='label-text'>选择导出文件列表类型：</div>
        <RadioGroup value={modifiedType} options={modifyTypeOpt} onChange={setModifiedType} />
      </div>
      <div className="mock-footer flex center-y">
        <div>
          <CheckBox value={onlyMakeList} onChange={setOnlyMakeList}>仅导出可制作清单</CheckBox>
        </div>
        <div className="btn-group flex">
          <Button normal onClick={close}>取消</Button>
          <Button primary onClick={_.debounce(confirm, 300)}>确认</Button>
        </div>
      </div>
    </Dialog>
  )
}

const dbTypeOpt = [
  { value: 'Oracle', text: 'Oracle' },
  { value: 'Mysql', text: 'Mysql' },
]

const fileMailFlagOpt = [
  { value: '0', text: '浏览器下载' },
  { value: '1', text: '邮件链接下载（较多文件时）' },
]

export function CheckListExport(props) { // checklist
  const { close, currentId, mulcustId, isClient, isAll, versionOption, projectId } = props
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [dbType, setDbType] = useState(_.isNil(sessionStorage.getItem('dbType-ver')) ? 'Oracle' : sessionStorage.getItem('dbType-ver'))
  const [params, setParams] = useState({ dbType, fileMailFlag: '0' })
  const [error, setError] = useState()
  const [isShow, setIsShow] = useState(false)
  const [custIdList, setCustIdList] = useState([])
  const [disable, setDisable] = useState(false)
  const { doFetch, loading } = useGet()
  const { data: typeRes } = useGet(TEMPLATE_TYPR_URL)
  const { doFetch: testLinkFetch } = usePost()
  const { doFetch: sendEmail } = usePost()
  const { data: securityRes } = useGet('/story/getbugProject')
  const [open, setOpen] = useState(false)

  const securityOpt = useMemo(() => {
    if (_.isEmpty(securityRes)) return []
    const filterData = _.filter(securityRes, o => _.includes(mulcustId, o.projectId))
    const concatData = _.concat(_.includes(mulcustId, 'ALL') ? [{ projectName: '全部客户', projectId: 'ALL' }] : [], isClient ? securityRes : filterData)

    return _.map(concatData, o => ({
      label: o.projectName,
      value: o.projectId,
    }))

  }, [mulcustId, securityRes, isClient])

  const typeOpt = useMemo(() => {
    if (_.isEmpty(typeRes)) return []
    return convertGlobalConstOptions(typeRes)
  }, [typeRes])

  const check = useCallback((flag = false) => {
    if (flag) _.every(_.values(_.omit(error, ['svnPath', 'svnDBPath', 'templateType'])), isNil)
    return _.every(_.values(error), isNil)
  }, [error])

  const testLink = useCallback((flag = true) => {
    if (!check(true)) return Messager.show('请填写必填项', { icon: 'error' })
    const releaseId = isAll ? _.get(params, 'releaseId') : currentId
    testLinkFetch(`${TEST_LINK_URL}?releaseId=${releaseId}`, _.assign({}, params))
      .then(() => {
        flag && Messager.show('测试成功', { icon: 'success' })
      })
      .catch(err => {
        Messager.show(err.Message, { icon: 'error' })
      })
  }, [check, currentId, params, testLinkFetch, isAll])

  const confirm = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    // if (_.isNil(_.get(params, 'releaseId')) && _.get(params, 'haveNoRelease') !== true) return Messager.show('请填写选择版本字段或未规划字段', { icon: 'error' })
    setDisable(true)
    testLink(false)
    const par = _.assign({}, { ..._.omit(params, ['releaseId']), custIdList, haveNoRelease: _.get(params, 'haveNoRelease') === true, }, isAll && { projectId, releaseIds: _.split(currentId, ',') })
    const releaseId = isAll ? _.get(params, 'releaseId') : currentId
    if (_.get(params, 'fileMailFlag') === '1') {
      sendEmail(`${EXPORT_CHECK_LIST_URL}?${strParams({ releaseId })}`, par)
        .then(() => {
          Messager.show('请求成功', { icon: 'success' })
          cancel()
        })
        .catch(err => {
          setDisable(false)
          Messager.show(err._message, { icon: 'error' })
        })

    } else {
      downloadPostProgress(`${EXPORT_CHECK_LIST_URL}?${strParams({ releaseId })}`, par, 'checkList导出', cancel, setDisable)
    }

    function cancel() {//disable
      setDisable(false)
      setOpen(false)
      close(false)
    }
  }, [check, close, currentId, custIdList, downloadPostProgress, params, testLink, isAll, projectId, sendEmail])

  useEffect(() => {
    doFetch(`${GET_DATA_INFO_URL}?dbType=${dbType}`)
      .then(res => {
        setParams(_.assign({ dbType, fileMailFlag: '0', isUpdateFileList: true }, _.pick(res, ['ip', 'sid', 'userId', 'pwd', 'prefix', 'svnPath', 'svnDBPath', 'port', 'templateType'])))
      })
  }, [dbType, doFetch])

  return (
    <Dialog
      header='导出checkList'
      className='check-list-export-log'
      cancel={close}
      loading={loading}
      confirm={_.get(params, 'fileMailFlag') === '1' ? () => setOpen(true) : confirm}
      confirmButtonDisabled={disable}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        {
          isAll &&
          <>
            <HFormInput required label='选择版本' bind='releaseId' component={Select} options={versionOption} clear search />
            <HFormInput label='未规划' bind='haveNoRelease' component={CheckBox} componentWidth={14} />
          </>
        }
        <div className='title-bold'>登录数据库信息</div>
        <HFormInput required label='数据库类型' bind='dbType' component={RadioGroup} options={dbTypeOpt} onChange={(v) => { sessionStorage.setItem('dbType-ver', v); setDbType(v) }} />
        <HFormInput required label='服务器地址' bind='ip' />
        <HFormInput required label='端口' bind='port' />
        <HFormInput required label='数据库' bind='sid' />
        <HFormInput required label='用户名' bind='userId' />
        <HFormInput required label='密码' bind='pwd' />
        <div className='flex'>
          <HFormInput label='表名前缀' bind='prefix' componentWidth={150} />
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>例如：ctsdb.</div>
        </div>
        <Button onClick={testLink}>测试链接</Button>
        <div className='title-bold'>SVN路径</div>
        <HFormInput required label='版本路径' bind='svnPath' />
        <HFormInput required label='DB路径' bind='svnDBPath' />
        <div className='title-bold'>导出文件模板</div>
        <HFormInput required label='选择模板' bind='templateType' component={Select} options={typeOpt} />
        <div className='export-customers'>
          <Checkbox
            checked={isShow}
            value={isShow}
            onChange={e => setIsShow(e.target.checked)}
          >
            按客户导出
          </Checkbox>
          {
            isShow &&
            <AntdSelect
              mode='multiple'
              placeholder='请选择客户'
              allowClear
              options={securityOpt}
              optionFilterProp='label'
              value={custIdList}
              onChange={v => setCustIdList(v)}
            />
          }
        </div>
        <HFormInput label='处理文件清单' bind='isUpdateFileList' component={CheckBox} />
        <HFormInput required label='下载方式' bind='fileMailFlag' component={RadioGroup} options={fileMailFlagOpt} />
      </Form>
      {
        open && <Dialog
          header='提示'
          cancel={() => setOpen(false)}
          loading={loading}
          confirm={confirm}
          confirmButtonDisabled={disable}
        >
          请关注邮件提醒，文件较多时，处理时长约半小时以内，请勿重复点击。
        </Dialog>
      }
    </Dialog>
  )
}


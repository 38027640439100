import React, { useCallback, useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Loader, Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Form, FormInput, Display, Input, RadioGroup } from 'rootnet-edit'
import { Icon } from '../../../../../../../../components'
import { TextIconBtn } from '../../../../../../../common/TextIconBtn'
import TextAreaInput from '../../../../../../../common/textAreaInput/TextAreaInput'
import './index.scss'
import { strParams } from '../../../../../../../../utils/publicFun'
import convertOptions from '../../../../../../../common/ConvertOptions'
import { isNil } from 'rootnet-core/format'
import UserSelect from '../../../../../../../common/personSelectPop/UserSelect'

const ADD_URL = '/uac/user/group/add'
const EDIT_URL = '/uac/user/group/edit'
const DETAIL_URL = '/uac/user/group/select'

const HFormInput = props => <FormInput horizontal labelWidth={75} componentWidth={180} {...props} />

const statusOpt = [
  { text: "启用", value: '0' },
  { text: "禁用", value: '1' },
]

export default function UserGroupDetailContent(props) {
  const { refreshViewList, close, currentInfo = {}, } = props
  const [id, setId] = useState()
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [infoError, setInfoError] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const { doFetch: getDetail, loading } = useGet()
  const { doFetch } = usePost()
  const { data: allUserRes } = useGet('/common/userinfo')

  const { mode: initMode = 'detail' } = currentInfo
  const [mode, setMode] = useState(initMode)

  const isAdd = useMemo(() => mode === 'add', [mode])
  const isDetail = useMemo(() => mode === 'detail', [mode])

  const refreshDetail = useCallback(() => {
    if (isAdd) {
      setFormData({ status: '0' })
      setEditFormData({ status: '0' })
    }
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?${strParams({ id })}`)
      .then(res => {
        setFormData(res)
        setEditFormData(res)
        setId(res.id)
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [id, getDetail, isAdd])

  useEffect(() => {
    refreshDetail()
  }, [refreshDetail])

  const canSubmit = useMemo(() => {
    if (isNil(_.get(editFormData, 'status'))) {
      return false
    }
    return !_.some(_.values(infoError), x => x)
  }, [infoError, editFormData])

  const submit = useCallback(() => {
    if (!isAdd && JSON.stringify(formData) === JSON.stringify(editFormData)) {
      setMode('detail')
      return Messager.show('无变更')
    }
    if (isDisable && !isAdd) return
    !isAdd && setIsDisable(true)
    const postParams = _.assign({}, editFormData, !isAdd && { id: formData?.id })
    doFetch(isAdd ? ADD_URL : EDIT_URL, postParams)
      .then(() => {
        Messager.show(isAdd ? '增加成功' : '修改成功', { icon: 'success' })
        refreshDetail()
        refreshViewList()
        setMode('detail')
        isAdd && close()
        !isAdd && setIsDisable(false)
      })
      .catch(err => {
        !isAdd && setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [close, doFetch, isAdd, formData, editFormData, refreshViewList, refreshDetail, isDisable])

  useEffect(() => {
    const { id } = currentInfo || {}
    if (!_.isNil(id)) {
      setId(id)
    }
  }, [currentInfo])

  return (
    <div className='user-group-detail-content flex-y'>
      <div className="mock-dialog-header flex">
        <div className="dialog-title flex center-y">
          {isAdd && '增加'}
          {isDetail && '详情'}
          {(!isAdd && !isDetail) && '修改'}
        </div>
        <div className="mock-right-header flex center-y">
          {
            !isAdd &&
            <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
              refreshDetail()
              setMode(x => x === 'detail' ? 'edit' : 'detail')
            }} />
          }
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>

      <div className="user-group-detail-main-panel">
        {!isAdd && loading && <Loader fill />}
        <Form value={editFormData} onChange={setEditFormData} error={infoError} onError={v => setInfoError(x => _.assign({}, x, v))}>
          <HFormInput required label='组名' bind='groupName' component={isDetail ? Display : Input} />
          <HFormInput required label='排序' bind='sort' component={isDetail ? Display : Input} type='number' />
          <HFormInput required label='状态' bind='status' component={isDetail ? Display : RadioGroup} options={statusOpt}
            bindInConvert={v => _.toString(v)} convert={v => convertOptions(_.toString(v), statusOpt)} />
          <HFormInput className={isDetail ? 'groups-people-detail' : 'groups-people-edit'} multiple allowClear label='组人员' bind='optIdList' component={isDetail ? Display : UserSelect} componentWidth={446} convert={arr => {
            const userNameArr = _.map(arr, v => convertOptions(v, allUserRes, 'userName', 'userAccount'))
            return _.join(userNameArr, '，')
          }} />
          <HFormInput label='描述' bind='memo' className={isDetail ? 'text-area-input-detail' : 'text-area-input-edit'} component={isDetail ? Display : TextAreaInput} componentWidth={446} />
        </Form>
      </div>

      <div className="mock-footer flex center-y">
        <div />
        {/*
        _.isEmpty(showChildList) ? <div /> :
          <div className="footer-switch flex center-y">
            <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
              onClick={() => {
                if (switchDisabled) return
                if (!_.includes([-1, 0], currentIndex)) {
                  switchCurrentItem('previous')
                }
              }}>
              <Icon name='xiangqian' />&nbsp;上一个
            </div>
            <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
              onClick={() => {
                if (switchDisabled) return
                if (!(currentIndex === showChildList?.length - 1)) {
                  switchCurrentItem('next')
                }
              }}>
              下一个&nbsp;<Icon name='xianghou' />
            </div>
            <div className="list-num">
              {currentIndex + 1}/{showChildList?.length}
            </div>
          </div>
            */}
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              close()
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary disable={!canSubmit} onClick={submit} >确认</Button>
          }
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import { useGet } from '../../../../utils/hook';
import { strParams } from "../../../../utils/publicFun";
import { Card } from 'rootnet-ui';
import { MilestoneCardCore } from "../../../common/MilestoneCardCore";
import _ from 'lodash';
import './Milestone.scss';

//里程碑
function Milestone(props) {
    const { search: { beginDate, id, completeDate } } = props;
    const { data, doFetch, loading, error } = useGet();

    useEffect(() => {
        if (!_.isNil(id)) {
            const params_ = {
                type: 'DEV',
                iid: id
            };
            const url = `common/milestone/list?`.concat(strParams(params_));
            doFetch(url);
        }
    }, [id, doFetch]);

    const product = { beginDate, completeDate };
    return <Card className='milestone-card milestone-mile x'
            loading={loading} error={error}
            data={data}
            title='里程碑'
        >
        <MilestoneCardCore product={product} data={data} />
    </Card>
}

export default Milestone;

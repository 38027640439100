import _ from "lodash";
import PersonSelectUserItem from "../personSelectPop/PersonSelectUserItem";
import React from "react";

export function buildUserGroupTree(props){
    const {userRes, allGroupRes, onSelect, selectedUserAccountList,onNodeClick, canSelectGroup, selectedGroupIdList} = props
    return _.map(allGroupRes, groupInfo => {
        const activeInnerUserList = _.filter(userRes, x => _.includes(groupInfo.optIdList, x.userAccount))
        const userOptions = _.map(activeInnerUserList, user => {
            return {
                key: `${groupInfo.id}-${user.userAccount}${user.userName}`,
                title: <PersonSelectUserItem userInfo={user} onSelect={onSelect} {...{selectedUserAccountList}}/>,
                type: 'user'
            }
        })
        return {
            title: <div className={'dept-name-wrap flex center-y'}  onClick={(e)=>{
                if(canSelectGroup){
                    e.stopPropagation()
                    onSelect(groupInfo,'group')
                }else{
                    onNodeClick(`group-${groupInfo.id}`)
                }
            }}>
                <div>
                    {groupInfo.groupName}
                    {
                        canSelectGroup && _.includes(selectedGroupIdList, groupInfo.id) &&
                        <div className={'dept-item-selected flex center'}>
                            √
                        </div>
                    }
                </div>
            </div>,
            key: `group-${groupInfo.id}`,
            type: 'group',
            children: userOptions
        }
    })
}
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Dialog, Button, Messager } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Select } from 'rootnet-edit'
import { isNil } from '../../../../../../../appraise/components/method'
import { strParams } from '../../../../../../../../utils/publicFun'
import convertOptions from '../../../../../../../common/ConvertOptions'
import { selectOption } from '../../../../../../../common/commonMethod'
import './index.scss'

const COPY_VERSION_URL = '/verQuery/saveVerQueryAndTracer'

export default function CopyProduction(props) {
  const { close, releaseId, refreshList } = props
  const [open, setOpen] = useState(false)
  const [versionId, setVersionId] = useState()
  const [isDisabled, setIsDisabled] = useState(false)
  const { data: globalConstOptionsRes, doFetch: getOptions } = useGet()
  const { doFetch } = usePost()

  useEffect(() => {
    getOptions(`/releaseinfo/copyVersionList?${strParams({ releaseId })}`)
  }, [getOptions, releaseId])

  const versionOptions = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return selectOption(globalConstOptionsRes, ['releasename', 'releaseid'])
  }, [globalConstOptionsRes])

  const confirm = useCallback(() => {
    if (isDisabled) return
    setIsDisabled(true)
    doFetch(`${COPY_VERSION_URL}?${strParams({ releaseId: versionId, releaseIdTo: releaseId })}`)
      .then(() => {
        refreshList()
        setOpen(false)
        setIsDisabled(false)
        close()
        Messager.show('分配成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [isDisabled, doFetch, versionId, releaseId, close, refreshList])

  return (
    <>
      <Dialog
        header='从版本复制'
        cancel={close}
        className='copy-production-log'
        footerVisible={false}
      >
        <div className={'copy-production-content flex-y'}>
          <Select search options={versionOptions} value={versionId} onChange={setVersionId} placeholder='请选择版本' />
        </div>
        <div className="mock-footer flex center-y">
          <div className='text-hint'>
            提示：将复制所选版本的“版本制作”、“任务清单制作标记”！
          </div>
          <div className="btn-group flex">
            <Button normal onClick={close}>取消</Button>
            <Button primary onClick={() => setOpen(true)} disable={isNil(versionId)}>确定</Button>
          </div>
        </div>
      </Dialog>
      {
        open && <Dialog
          header='确定'
          cancel={() => setOpen(false)}
          confirm={confirm}
          className='copy-production-secondary-confirmation'
        >
          <div>
            将复制 <span style={{ color: '#5477ff' }}>{convertOptions(versionId, versionOptions)}</span> 版本的“版本制作”、”任务清单制作标记“至本版本中，复制包含2个版本的交集内容，请确认！
          </div>
        </Dialog>
      }
    </>
  )
}


import _ from 'lodash'

function getTreeList(tree) {
  _.forEach(tree, item => {
    item.key = item.id
    item.text = item.name
    item.value = item.id
    item.title = item.name
    item.children && getTreeList(item.children)
  })
  return tree
}

export function filterTreeList(data) {
  const treeList = _.filter(data, item => item.id !== null)
  return getTreeList(treeList)
}
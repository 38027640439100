import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './CustomerVoiceDetailHeader.scss'
import {Popover, Tooltip} from "antd";
import _ from "lodash";
import {FormInput} from "rootnet-edit";
import {Icon} from "../../../../components";
import convertOptions from "../../../common/ConvertOptions";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import useGet from "rootnet-biz/es/hooks/useGet";
import {strParams} from "../../../../utils/publicFun";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";

const FUNC_CODE = '0851'

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function CustomerVoiceDetailHeader(props) {
    const {editFormData, isDetail, setEditFormData, allUserRes, id, refreshViewList, refreshDetail, updateDynamic} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: workFlowInfo, doFetch: getStatus} = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

    const status = useMemo(()=>{
        return _.get(workFlowInfo,'factorId')
    },[workFlowInfo])

    const refreshStatus = useCallback(()=>{
        if(_.isNil(id)) return
        const params = {
            businessType: 'voice',
            businessId: id
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
    },[getStatus, id])

    useEffect(()=>{
        refreshStatus()
    },[refreshStatus])

    const [workFlowStatusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const workflowColor = useMemo(()=>{
        return _.get(_.find(workFlowStatusOptions, x => x.value === status),'color') || '#000'
    },[workFlowStatusOptions, status])

    return <div className={'customer-voice-detail-header flex'}>
        <div className="header-left">
            <div className="req-id-wrap flex center-y">
                <div className="req-id">{_.get(editFormData,'voiceId')}</div>
            </div>
            {
                isDetail &&
                <div className="req-title-wrap flex center-y">
                    <div className="req-title">
                        <Tooltip title={_.get(editFormData,'title')}>
                            {_.get(editFormData,'title')}
                        </Tooltip>
                    </div>
                </div>
            }
            {
                !isDetail && <FormInput horizontal bind={'title'} label={'标题'} required value={_.get(editFormData, 'title')}
                                        onChange={v => setEditFormData(x => ({...x, title: v}))} maxLength={250}/>
            }
        </div>
        <div className="header-right flex center-y">
            <div className="header-right-item flex center-y">
                <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{color: '#165DFF'}}/>
                <div className="header-right-info flex-y">
                    {
                        isDetail &&
                        <div className="header-right-value">
                            <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes,'userName','userAccount') || '无'}</div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <FormInput horizontal value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                                   onChange={v => setEditFormData(x => ({...x, currentUser: v})) }  />
                    }
                    <div className="header-right-field">
                        处理人
                    </div>
                </div>
            </div>
            <Popover destroyTooltipOnHide={true}
                     content={<WorkFlowChangePop businessId={id} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                         refreshStatus()
                         refreshViewList()
                         refreshDetail(id)
                         updateDynamic()
                     }} funcCode={FUNC_CODE} businessType={'voice'}/>}
                     onOpenChange={visible => {
                         if(!visible){
                             setShowWorkFlowPop(null)
                         }
                     }}
                     trigger={'click'}
                     placement="bottomRight"
                     open={showWorkFlowPop}
            >
                <div className="header-right-item flex center-y status-wrap-item" onClick={()=>setShowWorkFlowPop(true)}>
                    <Icon name={'renling'} className={'header-right-icon'} style={{color: workflowColor}}/>
                    <div className="header-right-info flex-y">
                        <div className="header-right-value">
                            <div className={'status-tag flex center'} style={{color: workflowColor, border: `1px solid ${workflowColor}`,padding: '0 8px', borderRadius: 4}}>
                                {convertOptions(status, workFlowStatusOptions) || '-'}
                            </div>
                        </div>
                        <div className="header-right-field">
                            当前状态
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    </div>
}

export default CustomerVoiceDetailHeader;
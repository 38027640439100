import _ from "lodash";

export default function convertGlobalConstOptions(data) {
    return _.map(data, x => ({
        value: _.get(x, 'interiorId'),
        text: _.get(x, 'displayName', ''),
        label: _.get(x, 'displayName'),
        tag: `${_.get(x, 'interiorId')} ${_.get(x, 'displayName')}`,
        memo: _.get(x, 'memo'),
        pid: _.get(x, 'pid') || _.get(x, 'pId'),
        color: _.get(x, 'displayColor') || '',
        displayFlag: _.get(x, 'displayFlag'),
        rootValue: _.get(x, 'rootValue'),
    }))
}
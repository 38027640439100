import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { DataGrid } from 'rootnet-ui'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Form, FormInput, Select, CheckBox } from 'rootnet-edit'
import { useGet } from '../../../../../../../utils/hook'
import DateSelector from '../../../../../../common/dateSelector/DateSelector'
import { strParams } from '../../../../../../../project_share/utils/utils'
import './index.scss'
import convertGlobalConstOptions from '../../../../../../common/ConvertGlobalConstOptions'
import convertOptions from '../../../../../../common/ConvertOptions'
import ExportCsvDialog from '../../../../../../common/ExportCsvDialog'
import { TextIconBtn } from '../../../../../../common/TextIconBtn'
import RequirementDetailDialog from '../../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import TrackingDetailDialog from '../../../../../../trackingManagementMgt/components/trackingDetailDialog'
import IssueDetailDialog from '../../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import DefectUpdateDialog from '../../../../../../testMgt/defect/controls/DefectUpdateDialog'
import DevListDetailDialog from '../../../../../../devListMgt/devListDetailDialog'

const { str14ToDate } = toDate

const LIST_URL = '/releaseStory/story/log'

const GLOBAL_CONST_URLS = [
  '/common/globalconst?globalConst=actionType2',
  '/common/globalconst?globalConst=AUDITTYPE',
]

const TYPE_COLOR = {
  'req': '#5477FF',
  'dev': '#5477FF',
  'task': '#5C595B',
  'issue': '#FF5454',
  'defect': '#EC641C',
}

const getColumns = (props) => {
  const { allUserRes, actionOpt, flagOpt, setShowDetail, workflowOpt } = props
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: 'ID', bind: 'storyId', sortable: true, width: 180, tooltip: true },
    { header: '标题', bind: 'title', sortable: true, width: 240, convert: getTitle },
    { header: '状态', bind: 'status', sortable: true, width: 110, tooltip: true, convert: getStatus, csvConvert: csvGetStatus },
    { header: '是否在版本', bind: 'inRelease', sortable: true, width: 130, tooltip: true },
    { header: '操作动作', bind: 'optFlag', sortable: true, width: 90, tooltip: true, convert: v => convertOptions(v, actionOpt), csvConvert: v => convertOptions(v, actionOpt) },
    { header: '操作人', bind: 'operUser', sortable: true, width: 90, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'), csvConvert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '操作时间', bind: 'optTime', sortable: true, width: 140, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)), csvConvert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)) },
    { header: '备注', bind: 'optMemo', sortable: true, width: 200, tooltip: true },
    { header: '审核人', bind: 'auditOptId', sortable: true, width: 90, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'), csvConvert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '审核状态', bind: 'auditFlag', sortable: true, width: 80, tooltip: true, convert: v => convertOptions(v, flagOpt), csvConvert: v => convertOptions(v, flagOpt) },
    { header: '审核时间', bind: 'auditTime', sortable: true, width: 140, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)), csvConvert: v => dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(v)) },
    { header: '审核备注', bind: 'auditMemo', sortable: true, width: 200, tooltip: true, },
  ]
  function getTitle(v, o) {
    return <div className='flex title-hidden'>
      <div className='type-color' style={{ backgroundColor: TYPE_COLOR[o.type], marginLeft: (o.type === 'req' || o.type === 'dev') ? 2 : 0 }}>
        {_.toUpper(o.type) === 'DEFECT' ? 'BUG' : _.toUpper(o.type)}
      </div>
      <Tooltip title={v}>
        <div className='text' onClick={() => {
          if (o.type === 'req') {
            setShowDetail({
              type: 'REQ',
              id: o.storyIssueId
            })
          }
          else if (o.type === 'issue') {
            setShowDetail({
              type: 'ISSUE',
              id: o.storyIssueId
            })
          }
          else if (o.type === 'task') {
            setShowDetail({
              type: 'TASK',
              id: o.storyId
            })
          }
          else if (o.type === 'defect') {
            setShowDetail({
              type: 'DEFECT',
              id: o.storyIssueId
            })
          }
          else if (o.type === 'dev') {
            setShowDetail({
              type: 'DEV',
              id: o.storyIssueId
            })
          }
        }}>
          {v}
        </div>
      </Tooltip>
    </div>
  }
  function csvGetStatus(v, o) {
    const val = convertOptions(v, workflowOpt) || '-'
    return val
  }
  function getStatus(v, o) {
    // if (o.type === 'req') return convertOptions(v, reqOpt) || v
    // else if (o.type === 'issue') return convertOptions(v, issueOpt) || v
    // else if (o.type === 'task') return convertOptions(v, taskOpt) || v
    const val = convertOptions(v, workflowOpt) || '-'
    const color = convertOptions(v, workflowOpt, 'color')
    const style = {
      color: color,
      border: `1px solid ${color}`,
      padding: '0px 8px',
      borderRadius: '4px',
      display: 'inline-block',
    }
    return <div style={style}>{val}</div>
  }
}

const getOption = (columns) => ({
  columns,
  autoFill: true,
  resizable: true,
  virtualized: false,
  fixedLeft: 2,
  nilText: '-',
  emptyText: '-',
});

export default function ListChange(props) {
  const { currentId, allUserRes, reqDelAuth, isFuncCodeApply, isFuncCodeQaCheck, storyTypeOptions, workflowOpt } = props
  const [params, setParams] = useState()
  const [numSummary, setNumSummary] = useState({ del: 0, add: 0 })
  const { doFetch, data, loading } = useGet()
  const { data: optionsRes } = useGet(GLOBAL_CONST_URLS)
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [showDetail, setShowDetail] = useState()

  const [actionOpt, flagOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    return _.map(optionsRes, o => convertGlobalConstOptions(o))
  }, [optionsRes])

  useEffect(() => {
    if (_.isEmpty(data)) return
    const listData = _.groupBy(data, 'optFlag')
    setNumSummary({ add: _.size(listData.ADD), del: _.size(listData.DEL) })
  }, [data])

  const refresh = useCallback((par = {}) => {
    doFetch(`${LIST_URL}?releaseId=${currentId}&${strParams(par)}`)
  }, [currentId, doFetch])

  const option = useMemo(() => {
    return getOption(getColumns({ allUserRes, actionOpt, flagOpt, setShowDetail, workflowOpt }))
  }, [actionOpt, allUserRes, flagOpt, workflowOpt])

  const search = useCallback((o) => {
    const time = _.isNil(_.get(o, 'time')) ? {} : { beginDate: o.time.begin, endDate: o.time.end }
    refresh(_.assign({}, time, _.omit(o, ['time', 'change']), _.get(o, 'change') === true && { releaseStatus: 'C' }))
    setParams(o)
  }, [refresh])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className='version-list-change'>
      <SearchOption {...{ params, search, numSummary, actionOpt, setShowExportDialog }} />
      <div className='list-content'>
        <DataGrid loading={loading} data={data} option={option} onRowClick={() => { }} />
      </div>
      {
        showExportDialog &&
        <ExportCsvDialog
          close={() => setShowExportDialog(false)}
          list={data}
          option={option}
          title={`清单变动`}
        />
      }
      {
        _.get(showDetail, 'type') === 'REQ' && <RequirementDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => refresh(params)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          delAuth={reqDelAuth}
          showChildList={[]}
          storyTypeOptions={storyTypeOptions}
        />
      }
      {
        _.get(showDetail, 'type') === 'TASK' &&
        <TrackingDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => refresh(params)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          showChildList={[]}
        />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => refresh(params)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          showChildList={[]}
          {...{ isFuncCodeApply, isFuncCodeQaCheck }}
        />
      }
      {
        _.get(showDetail, 'type') === 'DEFECT' &&
        <DefectUpdateDialog
          mode={'detail'}
          close={() => setShowDetail(null)}
          refreshList={() => refresh(params)}
          currentId={_.get(showDetail, 'id')}
        />
      }
      {
        _.get(showDetail, 'type') === 'DEV' &&
        <DevListDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => refresh(params)}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          delAuth={reqDelAuth}
          showChildList={[]}
          storyTypeOptions={storyTypeOptions}
        />
      }
    </div>
  )
}

function SearchOption(props) {
  const { params, search, numSummary, actionOpt, setShowExportDialog } = props
  return <div className='search-option'>
    <div className='form-option'>
      <Form value={params} onChange={search}>
        <FormInput horizontal label='操作时间' bind='time' component={DateSelector} clear />
        <FormInput horizontal label='操作动作' bind='optFlag' component={Select} options={actionOpt} clear />
        <FormInput horizontal label='需求ID' bind='storyId' />
        <FormInput horizontal label='仅显示封板后的清单变动' bind='change' component={CheckBox} />
      </Form>
    </div>
    <div className='action-type'>
      <span>共计：{numSummary.add + numSummary.del}</span>
      <span>新增：{numSummary.add}</span>
      <span>删除：{numSummary.del}</span>
      <TextIconBtn icon='daochu' text={'导出'} onClick={() => setShowExportDialog(true)} />
    </div>
  </div>
}

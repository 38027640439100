import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Tooltip } from 'antd'
import { isNil } from 'rootnet-core/format'
import { Messager, Button } from 'rootnet-ui'
import { dateFormat } from 'rootnet-core/dateFormat'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { Icon } from '../../../../../../../../../components'
import { strParams } from '../../../../../../../../../utils/publicFun'
import VersionMakingInfo from './versionMakingInfo'
import './index.scss'

const EDIT_URL = '/releaseProducer/update'
const DETAIL_URL = '/releaseProducer/detail'

export default function VersionMakingDetailContent(props) {
  const { currentId, currentInfo, close, versionTypeOpt, allUserRes, refreshList, productTypeList } = props
  const { id, mode: initMode = 'detail' } = currentInfo
  const [mode, setMode] = useState(initMode)
  const [formData, setFormData] = useState()
  const [editFormData, setEditFormData] = useState()
  const [editFormError, setEditFormError] = useState({})
  const [isDisabled, setIsDisabled] = useState()
  const { doFetch: getDetail } = useGet()
  const { doFetch: upDateFetch } = usePost()
  const isDetail = useMemo(() => mode === 'detail', [mode])

  const switchMode = useCallback(() => {
    setEditFormData(formData)
    setMode(x => x === 'detail' ? 'edit' : 'detail')
  }, [formData])

  const productTypeLists = useMemo(() => {
    const findData = _.find(versionTypeOpt, o => o.value === _.trim(formData?.productType))
    return _.orderBy(_.concat(_.isNil(findData) ? [] : [findData], productTypeList), 'value', 'asc')
  }, [formData, productTypeList, versionTypeOpt])

  const refreshDetail = useCallback((id) => {
    if (_.isNil(id)) return
    getDetail(`${DETAIL_URL}?${strParams({ releaseId: currentId, productType: id })}`)
      .then(res => {
        setFormData({ ...res, sendEmail: true })
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [currentId, getDetail])

  const handClick = useCallback(() => {
    if (JSON.stringify(editFormData) === JSON.stringify(formData)) return Messager.show('无变更')
    if (isNil(editFormData?.saveCode)) return Messager.show('请填写必填项', { icon: 'error' })
    if (isDisabled) return
    setIsDisabled(true)
    upDateFetch(EDIT_URL, _.assign({}, editFormData, !isNil(editFormData?.reviewTime) && { reviewTime: dateFormat('YYYY-MM-DD', new Date()) }))
      .then(() => {
        Messager.show('编辑成功', { icon: 'success' })
        switchMode()
        refreshList()
        refreshDetail(id)
        setIsDisabled(false)
      })
      .catch(err => {
        setIsDisabled(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [upDateFetch, editFormData, switchMode, refreshList, refreshDetail, id, formData, isDisabled])

  const canSubmit = useMemo(() => {
    return !_.some(_.values(editFormError), x => x)
  }, [editFormError])

  useEffect(() => {
    if (_.isNil(id)) return
    refreshDetail(id)
  }, [refreshDetail, id])

  useEffect(() => {
    setEditFormData({ ...formData })
  }, [formData])

  return (
    <div className={'version-making-detail-content flex-y'}>
      <div className="mock-dialog-header flex">
        <div className="dialog-title">
          版本制作
        </div>
        <div className="mock-right-header flex center-y">
          <Tooltip title={isDetail ? '进入编辑' : '退出编辑'}>
            <span>
              <Icon name={'bianji2'} className={`header-edit-icon ${isDetail ? '' : 'edit-active-icon'}`} onClick={() => {
                switchMode(true)
                setEditFormData(formData)
              }} />
            </span>
          </Tooltip>
          <div className={'close-area flex center'} onClick={close}>
            <Icon name={'quxiao'} className={'close-icon'} />
          </div>
        </div>
      </div>
      <div className='mock-dialog-content'>
        <VersionMakingInfo {...{ isDetail, editFormData, setEditFormData, editFormError, setEditFormError, versionTypeOpt, allUserRes, productTypeList: productTypeLists }} />
      </div>
      <div className="mock-footer flex center-y">
        <div className="btn-group flex">
          {
            isDetail &&
            <Button normal onClick={close}>关闭</Button>
          }
          {
            !isDetail &&
            <Button normal onClick={() => {
              switchMode(true)
              setEditFormData(formData)
            }}>取消</Button>
          }
          {
            !isDetail &&
            <Button primary onClick={handClick} disable={!canSubmit}>确认</Button>
          }
        </div>
      </div>
    </div>
  )
}

import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import cls from 'clsx'
import _ from 'lodash'
import { DataGrid, Pagination } from 'rootnet-ui'
import gd from '../../../../base/global'
import { Box } from '../../../common/commonComponent'
import { ModeContext } from '../../../common/Context'
import './rightDataGrid.scss'
import ViewArea from "../../../common/view/ViewArea";

export default function RightDataGrid() {
  const {
    data,
    selectData,
    setSelectData,
    setParams,
    replaceParams,
    currentId,
    selectedTreeKeyArr,
    allColumns,
    getFieldList,
    refreshList,
    currentViewId,
    setCurrentViewId,
    params,
    setPageSize,
  } = useContext(ModeContext)

  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const { extra, dataGridList, options, pageSize, total, pageNum, boxLoading, optionsConfig, getInitParams, funcCode, error } = data || {}

  // function onSearch(params) {
  //   const formParam = _.omit(params, ['pageNum', 'pageSize'])
  //   const conditionList = _.map({ ...formParam, ...replaceParams }, (v, k) => {
  //     const currentItem = _.find(allColumns, x => `${x.tableName}.${x.fieldId}` === k)
  //     return ({ field: k, relation: _.get(currentItem, 'formQueryType') || '=', value: v || '' })
  //   })
  //   const factor = JSON.stringify({
  //     conditionRelation: 'and',
  //     conditionList: conditionList
  //   })
  //   treeRefresh({ ...queryData, params, factor })
  // }

  const onRowClick = useCallback((item) => {
    gd.testCaseRightDataGrid = item?.id
    _.forEach(dataGridList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [dataGridList])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return (
    <div className={cls('rightDataGrid')}>
      <div key={_.head(selectedTreeKeyArr)}>
        {/*<CustomizeOptions replaceParams={replaceParams} search={setParams} allOptions={optionsConfig} baseOptions={[]} {...{ getInitParams, funcCode, onSearch }} />*/}
        <ViewArea funcCode={funcCode} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                    currentViewId, setCurrentViewId, params, pageSize, replaceParams}}/>
      </div>
      <Box className='flex-y x-card-singlegrid' title='用例列表' extra={extra} data={dataGridList} error={error} loading={boxLoading}>
        <DataGrid className='x-datagrid' option={options} data={dataGridList} onSelectionChange={setSelectData} defaultSelection={selectData} onRowClick={onRowClick} />
        <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, {pageNum, pageSize}))
          }} />
      </Box>
    </div >
  )


}
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import './ConferenceRecordsAdd.scss'
import { Dialog, Messager, Loader } from 'rootnet-ui'
import { Form, FormInput, Select, TextArea, DatePicker } from 'rootnet-edit'
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from "lodash";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import UserSelect from "../../../common/personSelectPop/UserSelect";
import Icon from "../../../../components/Icon";
import RichTextEditor from "../../../common/richTextEditor/TinyEditor";
import RequirementAddUploadArea from "../../../requirementMgt/requirementAddDialog/requirementAddUploadArea/RequirementAddUploadArea";
import ConferenceRecordsTodoList from "./ConferenceRecordsTodoList";
import ConferenceRecordsParticipantList from "./ConferenceRecordsParticipantList";
import { usePost } from "rootnet-biz/es/hooks";
import { isNil } from "../../../appraise/components/method";
import moment from 'moment'
import gd from "../../../../base/global";
import { getTreeOpt } from '../method';
import clsx from "clsx";
import ContentTemplateSelect from '../../../common/contentTemplateSelect';
import useContentTemplateNum from '../../../common/hooks/useContentTemplateNum';
import EditTipLog from '../../../common/contentTemplateSelect/editTipLog';
import DisplaySelect from '../../../common/displaySelect/DisplaySelect';
import {Tooltip} from "antd";

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=sConferenceType',
    'common/globalconst?globalConst=AuditPassFlag',
]

const optionsUrls = [
    '/UserSetting/getUniversalInterfaces?code=t_Custid&codeName=t_Custname&tableName=bug_tcust',
]

const HFormInput = (props) => <FormInput labelWidth={90} componentWidth={200} horizontal {...props} />

const initFormData = {
    sConferenceType: null,
    sOurPerformer: [],
}

function ConferenceRecordsAdd(props) {
    const { close, refreshList, initForm = {} } = props
    const [formData, setFormData] = useState({ ...initFormData, ...initForm })
    const [descExpand, setDescExpand] = useState(true)
    const [fileExpand, setFileExpand] = useState(true)
    const [todoListExpand, setTodoListExpand] = useState(true)
    const [participantListExpand, setParticipantListExpand] = useState(true)
    const { data: id, doFetch: getOnlyId } = useGet()
    const { doFetch: getTodoOnlyId } = useGet()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: allOptionsRes } = useGet(optionsUrls)
    const richEditorRef = useRef()
    const [addLoading, setAddLoading] = useState(false)
    const { data: allUserRes } = useGet('/common/userinfo')
    const [countFile, updateFile] = useReducer((x) => x + 1, 60000)
    const [todoList, setTodoList] = useState([])
    const [participantList, setParticipantList] = useState([])
    const [formError, setFormError] = useState()
    const { doFetch: addConference } = usePost()
    const { doFetch: addParticipantList } = usePost()

    const [openItem, setOpenItem] = useState(null)
    const [templateItem, setTemplateItem] = useState()
    const addContentTemplateNum = useContentTemplateNum()

    const getNewOnlyId = useCallback(() => {
        getOnlyId('/test_case/productGetOnlyId').then(id => {
            updateFile()
            setFormData(x => _.assign({}, x, { id }))
        })
    }, [getOnlyId])

    useEffect(() => {
        getNewOnlyId()
    }, [getNewOnlyId])

    useEffect(() => {
        setParticipantList(oldList => {
            return _.map(_.get(formData, 'sOurPerformer'), user => {
                const findItem = _.find(oldList, x => x.conferee === user)
                return _.isNil(findItem) ? ({
                    conferee: user,
                    attendance: '01',
                    explanation: null,
                }) : findItem
            })
        })
    }, [formData])

    const [sConferenceTypeOptions, auditPassFlagOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => getTreeOpt(x))
    }, [globalConstOptionsRes])

    const [custOptions] = useMemo(() => {
        if (_.isEmpty(allOptionsRes)) return []
        const [d1] = allOptionsRes
        const custOptions = _.map(d1, x => ({ label: x.displayName, text: x.displayName, value: x.interiorId, tag: x.interiorId }))
        return [custOptions]
    }, [allOptionsRes])

    const addNewTodo = useCallback(() => {
        getTodoOnlyId('/test_case/productGetOnlyId').then(id => {
            setTodoList(old => {
                return _.concat(old, [{
                    id: id,
                    otherPerson: [],
                    priority: "B",
                }])
            })
        })
    }, [getTodoOnlyId])

    const changeForm = useCallback((newObj, bind) => {
        setFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (_.get(cloneObj, 'dOccurTimeBegin') && _.get(cloneObj, 'dOccurTimeEnd')) {
                if (_.includes(['dOccurTimeBegin', 'dOccurTimeEnd'], bind)) {
                    if (_.get(cloneObj, 'dOccurTimeBegin') > _.get(cloneObj, 'dOccurTimeEnd')) {
                        if (bind === 'dOccurTimeBegin') {
                            cloneObj['dOccurTimeBegin'] = null
                            Messager.show('开始时间不能晚于结束时间')
                        } else {
                            cloneObj['dOccurTimeEnd'] = null
                            Messager.show('结束时间不能早于开始时间')
                        }
                    }
                }
            }
            if (bind === 'sConferenceType') {
                const oldValue = _.get(oldObj, 'sConferenceType')
                const newValue = _.get(newObj, 'sConferenceType')
                const needAuditType = ['DS', 'REQ', 'TC']
                if (_.includes(needAuditType, oldValue) === !_.includes(needAuditType, newValue)) {
                    cloneObj['auditPassFlag'] = null
                    cloneObj['auditor'] = null
                    cloneObj['storyId'] = null
                    cloneObj['auditPassReason'] = null
                }
                if (_.includes(['S', 'DS', 'REQ', 'TC'], cloneObj['sConferenceType'])) {
                    cloneObj['scustomer'] = 'NBGJ'
                }
            }
            return cloneObj
        })
    }, [])


    const needAudit = useMemo(() => {
        const sConferenceType = _.get(formData, 'sConferenceType')
        return _.includes(['DS', 'REQ', 'TC'], sConferenceType)
    }, [formData])

    const emptyAuditPassReason = useMemo(() => {
        return isNil(_.trim(_.get(formData, 'auditPassReason')))
    }, [formData])

    const canSubmit = useMemo(() => {
        if (needAudit && emptyAuditPassReason) return false
        return _.every(formError, v => _.isNil(v))
    }, [formError, needAudit, emptyAuditPassReason])

    const todoListRequired = useMemo(() => {
        const requiredList = ['subject', 'principal', 'requireDate', 'priority']
        return _.every(todoList, item => {
            return _.every(requiredList, field => !isNil(_.get(item, field)))
        })
    }, [todoList])

    const participantListRequired = useMemo(() => {
        const filterList = _.filter(participantList, x => _.includes(['02', '03'], _.get(x, 'attendance')))
        return _.every(filterList, item => !isNil(_.get(item, 'explanation')))
    }, [participantList])

    const submit = useCallback(() => {
        if (!canSubmit) return
        if (!todoListRequired) return Messager.show('请填写【跟进事项】中的必填项')
        if (!participantListRequired) return Messager.show('请填写【参会情况】中的必填项')
        if (addLoading) return
        setAddLoading(true)
        const postParams = {
            ...formData,
            dOccurTimeBegin: _.get(formData, 'dOccurTime') + ' ' + moment(_.get(formData, 'dOccurTimeBegin')).format('HH:mm') + ":00",
            dOccurTimeEnd: _.get(formData, 'dOccurTime') + ' ' + moment(_.get(formData, 'dOccurTimeEnd')).format('HH:mm') + ":00",
            sRecord: richEditorRef.current.getContent(),
            sOurPerformer: _.join(_.get(formData, 'sOurPerformer'), '^'),
        }
        const todoParams = _.map(todoList, x => ({
            ...x,
            // relateId:cid,
            funcCode: '2401',
            status: "1",
            eventType: "2401",
            otherPerson: _.join(_.get(x, 'otherPerson'), ','),
            recUser: gd.User.operator_id,
            toUrlPath: '/conferenceRecords?initId=',
        }))
        addConference('/conferencerecord/add', { ...postParams, todoLists: todoParams }).then(res => {
            const cid = _.get(res, 'iid')
            addParticipantList('/conferencerecord/conference/add', _.map(participantList, x => ({ ...x, recordId: cid }))).then(() => {
                Messager.show('添加成功', { icon: 'success' })
                addContentTemplateNum(templateItem)
                .then(()=>{
                    setAddLoading(false)
                    refreshList()
                    close()
                })
                .catch(err => {
                    setAddLoading(false)
                    refreshList()
                    close()
                    Messager.show(`上传当前模板失败：${err.Message}`, {icon: 'error'})
                })
                // addTodoList('/todolist/add',todoParams).then(()=>{
                // }).catch(err => {
                //     setAddLoading(false)
                //     Messager.show(err.Message, {icon: 'error'})
                // })
            }).catch(err => {
                setAddLoading(false)
                Messager.show(`会议纪要：${err.Message}`, { icon: 'error' })
            })
        }).catch(err => {
            setAddLoading(false)
            Messager.show(`会议纪要：${err.Message}`, { icon: 'error' })
        })
    }, [formData, canSubmit, addConference, todoListRequired, participantListRequired, participantList, addParticipantList, refreshList, close, todoList, addLoading, addContentTemplateNum, templateItem])

    useEffect(() => {
        if (!needAudit) {
            setFormError(old => {
                let cloneObj = _.clone(old)
                cloneObj['auditPassFlag'] = null
                cloneObj['auditor'] = null
                cloneObj['storyId'] = null
                cloneObj['auditPassReason'] = null
                return cloneObj
            })
        }
    }, [needAudit])

    const onFormError = useCallback((newValue) => {
        if (!needAudit) {
            setFormError(() => {
                let cloneObj = _.clone(newValue)
                cloneObj['auditPassFlag'] = null
                cloneObj['auditor'] = null
                cloneObj['storyId'] = null
                cloneObj['auditPassReason'] = null
                return cloneObj
            })
        } else {
            setFormError(newValue)
        }
    }, [needAudit])

    const templateChange = useCallback((v, item, flag) => {
        if (flag) setOpenItem(item)
        else {
            setTemplateItem(item)
            if (richEditorRef.current) {
                richEditorRef.current.setContent(v)
            }
        }

    }, [])

    return <Dialog
        header={"新增会议纪要"}
        className={'conference-records-add-dialog'}
        confirm={submit}
        cancel={close}
        confirmButtonDisabled={!canSubmit}
        headerVisible={false}
    >
        <div className="conference-records-add flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    新增会议纪要
                </div>
                <div className="mock-right-header flex center-y">
                    <ContentTemplateSelect
                        type={'0'}
                        isAdd={true}
                        objectType={_.get(formData, 'sConferenceType')}
                        onChange={templateChange}
                    />
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <div className='mock-detail-main-panel' onScroll={()=>{
                document.querySelector('.conference-records-add').click()
            }}>
                <Form value={formData} onChange={changeForm} error={formError} onError={onFormError}>
                    <HFormInput label={'标题'} bind={'sSubject'} required componentWidth={812} />
                    <HFormInput label={'会议分类'} bind={'sConferenceType'} required component={DisplaySelect} tree options={sConferenceTypeOptions} />
                    <Tooltip title={'IPD研发流程中的评审会议活动请到【评审管理】记录，在此记录无效！'}>
                            <span className={'cursor-pointer'}>
                                <Icon name={'bangzhu'} style={{fontSize: 14, color: '#5477FF'}}/>
                            </span>
                    </Tooltip>
                    <HFormInput label={'会议日期'} bind={'dOccurTime'} required component={DatePicker} labelWidth={76}
                        bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                    <HFormInput label={'会议时间'} bind={'dOccurTimeBegin'} required component={DatePicker} mode='time' clear
                        showSeconds={false} componentWidth={75} placeholder={'开始'}
                        bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                    至
                    <HFormInput label={''} bind={'dOccurTimeEnd'} required component={DatePicker} mode='time' clear
                        showSeconds={false} componentWidth={75} placeholder={'结束'}
                        bindOutConvert={v => dateFormat('YYYY-MM-DD HH:MM:SS', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                    <HFormInput label={'会议客户'} bind={'scustomer'} required search component={Select} options={custOptions} />
                    <HFormInput label={'会议主持'} bind={'sCaption'} />
                    <HFormInput label={'会议地点'} bind={'sOccurPlace'} />
                    {
                        needAudit &&
                        <HFormInput label={'评审状态'} bind={'auditPassFlag'} required search component={Select} options={auditPassFlagOptions} />
                    }
                    {
                        needAudit &&
                        <HFormInput label={'评审决策人'} bind={'auditor'} required component={UserSelect} />
                    }
                    {
                        needAudit && <div />
                    }
                    {/*{*/}
                    {/*    needAudit &&*/}
                    {/*    <HFormInput label={'关联需求'} bind={'storyId'} component={TextArea} maxLength={100}/>*/}
                    {/*}*/}
                    {
                        needAudit &&
                        <HFormInput label={'评审理由'} bind={'auditPassReason'} required componentWidth={812} component={TextArea} maxLength={500}
                            componentClass={clsx({ 'textarea-error': emptyAuditPassReason })} />
                    }
                    <HFormInput label={'本方参与人'} bind={'sOurPerformer'} required component={UserSelect} multiple componentWidth={812} />
                    <HFormInput label={'对方参与人'} bind={'sCustomerPerformer'} componentWidth={812} />
                    <HFormInput label={'会议议题'} bind={'topic'} componentWidth={812} component={TextArea} maxLength={2000} />
                </Form>
                <div className="area-wrap desc-wrap">
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
                        会议内容
                    </div>
                    <div className="rich-text-area" style={{ display: descExpand ? 'block' : 'none' }}>
                        <RichTextEditor ref={richEditorRef} height={350} />
                    </div>
                </div>
                <div className="area-wrap desc-wrap">
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: todoListExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setTodoListExpand(v => !v)} />
                        跟进事项
                    </div>
                    <div className="todo-list-wrap" style={{ display: todoListExpand ? 'block' : 'none' }}>
                        {
                            !_.isEmpty(todoList) &&
                            <ConferenceRecordsTodoList editable={true} deletable={true} {...{ todoList, setTodoList, allUserRes }} />
                        }
                        <span className={'add-todo-list-text'} onClick={addNewTodo}>
                            添加跟进事项
                        </span>
                    </div>
                </div>
                <div className="area-wrap desc-wrap">
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: participantListExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setParticipantListExpand(v => !v)} />
                        参会情况
                    </div>
                    <div className="participant-list-wrap" style={{ display: participantListExpand ? 'block' : 'none' }}>
                        <ConferenceRecordsParticipantList editable={true} {...{ participantList, setParticipantList, allUserRes }} />
                    </div>
                </div>
                <div className="area-wrap desc-wrap" >
                    <div className="area-header flex center-y">
                        <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: fileExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setFileExpand(v => !v)} />
                        附件
                    </div>
                    <div className="doc-area" style={{ display: fileExpand ? 'block' : 'none' }} key={countFile}>
                        <RequirementAddUploadArea id={id} allUserRes={allUserRes} funcCode={'2401'} />
                    </div>
                </div>
                {
                    addLoading &&
                    <Loader fill text={'提交中'} />
                }
            </div>
        </div>
        {
            !_.isNil(openItem) &&
            <EditTipLog
                item={openItem}
                close={() => setOpenItem(null)}
                onChange={(v, item) => {
                    setTemplateItem(item)
                    if (richEditorRef.current) {
                        richEditorRef.current.setContent(v)
                    }
                }}
            />
        }
    </Dialog>
}

export default ConferenceRecordsAdd;
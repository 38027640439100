import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {Button, Dialog, Loader, Messager} from 'rootnet-ui'
import './CustomerVoiceDetail.scss'
import {TextIconBtn} from "../../common/TextIconBtn";
import Icon from "../../../components/Icon";
import CustomerVoiceDetailHeader from "./customerVoiceDetailHeader/CustomerVoiceDetailHeader";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import _ from "lodash";
import {Badge, Tabs} from "antd";
import CustomerVoiceDetailInfo from "./customerVoiceDetailInfo/CustomerVoiceDetailInfo";
import ChangeRecord from "../../common/ChangeRecord";
import RequirementUploadArea
    from "../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea";
import IssueAdd from "../../issueMgt/components/issueAdd/IssueAdd";
import RequirementAddDialog from "../../requirementMgt/requirementAddDialog/RequirementAddDialog";
import CustomerServiceInfoAddDialog
    from "../../customerServiceInfo/customerServiceInfoAddDialog/CustomerServiceInfoAddDialog";
import {isNil} from "../../appraise/components/method";
import {dateFormat, toDate} from "rootnet-core/dateFormat";

const FUNC_CODE = '0851'
const {TabPane} = Tabs;

function CustomerVoiceDetail(props) {
    const {refreshViewList = () => { }, currentInfo = {}, close = () => { }, switchCurrentItem, showChildList, currentIndex} = props
    const { mode: initMode = 'detail' } = currentInfo
    const [mode, setMode] = useState(initMode)
    const [id, setId] = useState()
    const { doFetch: getDetail } = useGet()
    const [formData, setFormData] = useState()
    const [editFormData, setEditFormData] = useState()
    const [editFormError, setEditFormError] = useState()
    const { data: allUserRes } = useGet('/common/userinfo')
    const [activeKey, setActiveKey] = useState('info')
    const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0) // 动态
    const descRef = useRef()
    const resolveRef = useRef()
    const [fileCount, setFileCount] = useState(0)
    const [switchDisabled, setSwitchDisabled] = useState(false)
    const [contentLoading, setContentLoading] = useState(false)
    const [showDialog, setShowDialog] = useState()
    const {doFetch: submitPost} = usePost()

    const [canMove, setCanMove] = useState(false);
    const [htmlWidth, setHtmlWidth] = useState(360);
    const prevXRef = useRef(null);
    const panelRef = useRef(null)

    useEffect(() => {
        const { id } = currentInfo || {}
        if (!_.isNil(id)) {
            setId(id)
        }
    }, [currentInfo])

    const isDetail = useMemo(()=>{
        return mode === 'detail'
    },[mode])

    const refreshDetail = useCallback(()=>{
        if(_.isNil(id)) return
        setSwitchDisabled(true)
        setContentLoading(true)
        getDetail('/customerVoice/getCustomerVoice?id='+id).then(res => {
            setFormData(res)
            setEditFormData(res)
            setSwitchDisabled(false)
            setContentLoading(false)
        }).catch(err => {
            setSwitchDisabled(false)
            setContentLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
        updateDynamic()
    },[getDetail, id])

    useEffect(()=>{
        refreshDetail()
    },[refreshDetail])

    const refreshRichTextRef = useCallback((newData) => {
        const data = newData || formData
        if (descRef.current) {
            descRef.current.setContent(_.replace(_.get(data, 'details') || '', /\n/g, ''))
        }
        if (resolveRef.current) {
            resolveRef.current.setContent(_.replace(_.get(data, 'solution') || '', /\n/g, ''))
        }
    }, [formData])

    const switchMode = useCallback((resetDesc = false) => {
        if (resetDesc) {
            refreshRichTextRef()
        }
        setEditFormData(formData)
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [refreshRichTextRef, formData])

    const canSubmit = useMemo(()=>{
        return !_.some(_.values(editFormError),x=>x)
    },[editFormError])

    const submit = useCallback(()=>{
        if(isNil(descRef.current.getContent())){
            return Messager.show("请填写问题详细描述")
        }
        if(contentLoading) return
        setContentLoading(true)
        const submitParams = {
            ...editFormData,
            details: descRef.current.getContent(),
            solution: resolveRef.current.getContent(),
        }
        submitPost('/customerVoice/update',_.omit(submitParams,['currentUser'])).then(()=>{
            if(_.get(formData,'currentUser') === _.get(editFormData,'currentUser')){
                afterRefresh()
            }else{
                const currentUserParams = {
                    businessId: _.get(editFormData,'id'),
                    businessType: 'voice',
                    funcCode: FUNC_CODE,
                    currentUser: _.get(editFormData, 'currentUser'),
                    title: _.get(editFormData,'title')
                }
                submitPost('/WorkflowBusiness/updateCurrentUser',currentUserParams).then(()=>{
                    afterRefresh()
                }).catch(err => {
                    Messager.show(err._message, { icon: 'error' })
                    setContentLoading(false)
                })
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setContentLoading(false)
        })
        function afterRefresh(){
            Messager.show('修改成功', { icon: 'success' })
            setContentLoading(false)
            refreshDetail()
            switchMode(false, 'detail')
            if(refreshViewList){
                refreshViewList()
            }
        }
    },[contentLoading, editFormData, submitPost, switchMode, refreshViewList, refreshDetail, formData])

    return <Dialog className={'customer-voice-detail'} headerVisible={false} footerVisible={false}>
        <div className={'customer-voice-detail-content flex-y'}>
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    客户声音详情
                </div>
                <div className="mock-right-header flex center-y">
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'-task'} className={`header-edit-text-icon`} text={'创建客户服务单'}
                                     onClick={()=>{
                                         setShowDialog({
                                             module: 'createCustomerService',
                                             params: {
                                                 voiceId: id
                                             }
                                         })
                                     }}/>
                    </span>
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'bug-copy'} className={`header-edit-text-icon`} text={'创建ISSUE'}
                                     onClick={()=>{
                                         setShowDialog({
                                             module: 'createIssue',
                                             params: {voiceId: id}
                                         })
                                     }}/>
                    </span>
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'xuqiu1'} className={`header-edit-text-icon`} text={'创建需求'}
                                     onClick={()=>{
                                         setShowDialog({
                                             module: 'createReq',
                                             params: {voiceId: id}
                                         })
                                     }}/>
                    </span>
                    <span style={{ marginRight: 16 }}>
                        <TextIconBtn icon={'bianji2'} className={`header-edit-text-icon`} text={isDetail ? '进入编辑' : '退出编辑'} onClick={() => {
                            switchMode(true)
                            refreshDetail(id)
                        }} />
                    </span>
                    <div className={'close-area flex center'} onClick={close}>
                        <Icon name={'quxiao'} className={'close-icon'} />
                    </div>
                </div>
            </div>
            <CustomerVoiceDetailHeader {...{ editFormData, isDetail, setEditFormData, setActiveKey, allUserRes, id, refreshViewList, refreshDetail, updateDynamic }} />
            <div className="main-content-wrap flex"
                 onMouseMove={handleMove} ref={panelRef}
                 onMouseLeave={() => setCanMove(false)}
                 onMouseUp={() => setCanMove(false)}
            >
                <div className="left-content flex" >
                    <Tabs activeKey={activeKey} onChange={setActiveKey}>
                        <TabPane tab='详细信息' key={'info'} forceRender={true}>
                            <CustomerVoiceDetailInfo {...{isDetail, editFormData, setEditFormData, allUserRes, editFormError,
                                setEditFormError, descRef, resolveRef, refreshDetail, submit}}/>
                        </TabPane>
                        <TabPane tab={
                            <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                                附件
                            </Badge>
                        } key={'doc'} forceRender={true}>
                            <div className="file-wrap">
                                <RequirementUploadArea funcCode={FUNC_CODE} {...{ id, mode, setFileCount, allUserRes }} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                <div className='drag-wrap' onMouseDown={() => setCanMove(true)}><Icon name='yidongshu' /></div>
                <div className={'dynamic-wrap'} key={countDynamic} style={{ flexWrap: 'wrap', width: htmlWidth }}>
                    <ChangeRecord name='客户声音' funcCode={FUNC_CODE} referenceId={id} id={_.get(editFormData, 'id')}
                                  title={_.get(editFormData, 'shortDesc')}
                                  commentWidth={'90%'}/>
                </div>
            </div>
            {contentLoading && <Loader fill />}
            <div className="mock-footer flex center-y">
                <div />
                {
                    _.isEmpty(showChildList) ? <div /> :
                        <div className="footer-switch flex center-y">
                            <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                                 onClick={() => {
                                     if (switchDisabled) return
                                     if (!_.includes([-1, 0], currentIndex)) {
                                         switchCurrentItem('previous')
                                     }
                                 }}>
                                <Icon name='xiangqian' />&nbsp;上一个
                            </div>
                            <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                                 onClick={() => {
                                     if (switchDisabled) return
                                     if (!(currentIndex === showChildList?.length - 1)) {
                                         switchCurrentItem('next')
                                     }
                                 }}>
                                下一个&nbsp;<Icon name='xianghou' />
                            </div>
                            <div className="list-num">
                                {currentIndex + 1}/{showChildList?.length}
                            </div>
                        </div>
                }
                <div className="btn-group flex">
                    {
                        isDetail &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        !isDetail &&
                        <Button normal onClick={() => {
                            switchMode(true)
                            setEditFormData(formData)
                        }}>取消</Button>
                    }
                    {
                        !isDetail &&
                        <Button primary onClick={()=>submit()} disable={!canSubmit}>确认</Button>
                    }
                </div>
            </div>
        </div>
        {
            _.get(showDialog,'module') === 'createReq' &&
            <RequirementAddDialog afterRefresh={()=>{
                refreshDetail()
            }}
                                  close={()=>setShowDialog(null)}
                                  submitAppendParams={_.get(showDialog,'params')}
                                  initParams={{
                                      title: formData.title,
                                      custSystem: formData.customer,
                                      custContact: formData.customerLinker,
                                      custContactInfo: formData.contactUs,
                                      description: formData.details,
                                      priority: 'B',
                                      character: 'NEW'
                                  }}
            />
        }
        {
            _.get(showDialog,'module') === 'createIssue' &&
            <IssueAdd close={() => setShowDialog(null)} refreshViewList={refreshDetail}
                      submitAppendParams={_.get(showDialog,'params')}
                      initParams={{
                          shortDesc: formData.title,
                          custId: formData.customer,
                          occurDate: dateFormat('YYYY-MM-DD',toDate.str14ToDate(formData.happenDate)),
                          impact: formData.details,
                          timeRange: 'N',
                          isExch: 'N',
                          resolved: 'N',
                      }}
            />
        }
        {
            _.get(showDialog,'module') === 'createCustomerService' &&
            <CustomerServiceInfoAddDialog close={() => setShowDialog(null)} refreshViewList={refreshDetail}
                                          submitAppendParams={_.get(showDialog,'params')}
                                          initForm={{
                                              issueDesc: formData.title,
                                              occurTime: dateFormat('YYYY-MM-DD',toDate.str14ToDate(formData.happenDate)),
                                              customer: formData.customer,
                                              reqDetail: formData.details,
                                          }}
            />
        }
    </Dialog>

    function handleMove(e) {
        if (!canMove) return;
        e.preventDefault();
        e.stopPropagation();
        const prevX = prevXRef.current;
        prevXRef.current = e.clientX
        if (prevX === null) return null;
        const offset = e.clientX - prevX;
        setHtmlWidth(x => {
            if (x - offset < 360) {
                prevXRef.current = prevX
                return 360
            } else if ((x - offset) > 650) {
                prevXRef.current = prevX
                return x
            } else {
                return x - offset
            }
        });
    }
}

export default CustomerVoiceDetail;
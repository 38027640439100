import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Dialog, Button, Messager, Loader } from 'rootnet-ui'
import './CustomerServiceInfoAddDialog.scss'
import { Checkbox, Tooltip } from "antd";
import _ from "lodash";
import { DatePicker, Form, FormInput, Input, Select } from "rootnet-edit";
import Icon from "../../../components/Icon";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import UserSelect from "../../common/personSelectPop/UserSelect";
import RichTextEditor from "../../common/richTextEditor/TinyEditor";
import RequirementAddUploadArea
    from "../../requirementMgt/requirementAddDialog/requirementAddUploadArea/RequirementAddUploadArea";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../common/ConvertGlobalConstOptions";
import { usePost } from "rootnet-biz/es/hooks";
import { isNil } from "../../appraise/components/method";
// import CustomerContactAddDialog from "../customerContactAddDialog/CustomerContactAddDialog";
import clsx from "clsx";
import convertOptions from "../../common/ConvertOptions";
import InfoFormLog from '../../customerInformationMgt/customerSystemMgt/customerDetail/contactPerson/infoFormLog'
import { useFuncCode } from '../../common/commonMethod';

const HFormInput = props => <FormInput horizontal labelWidth={130} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    '/common/globalconst?globalConst=CSissuekind',
    '/common/globalconst?globalConst=PRIORITYLIST',
    '/common/globalconst?globalConst=Scene',
]

const OPTIONS_URLS = [
    '/customerserviceinfo/selectiveCustomerReleaseRec',
    '/story/getbugProject',
    '/viewCommon/getProductInfo',
    '/common/globalconst?globalConst=ProductLine',
    '/develop/product/subproduct/list',
    '/common/globalconst?globalConst=ISSUESTYPE',
]

const YNOptions = [
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const BASE_FORM = {
    occurTime: dateFormat('YYYY-MM-DD', new Date()),
    priority: 'B',
    scene: '05'
}

function CustomerServiceInfoAddDialog(props) {
    const { close, refreshViewList = () => { }, setFocusId = () => { }, initForm = {}, submitAppendParams = {} } = props
    const [continueAdd, setContinueAdd] = useState(false)
    const [formData, setFormData] = useState(_.merge(BASE_FORM, _.pickBy(initForm, x => !_.isNil(x))))
    const [formError, setFormError] = useState()
    const [baseInfoExpand, setBaseInfoExpand] = useState(true)
    const [customerInfoExpand, setCustomerInfoExpand] = useState(true)
    const [analysisExpand, setAnalysisExpand] = useState(true)
    const [descExpand, setDescExpand] = useState(true)
    const [docExpand, setDocExpand] = useState(true)
    const richEditorRef = useRef()
    const { data: allUserRes } = useGet('/common/userinfo')
    const { data: onlyId, doFetch: getOnlyId } = useGet()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const { data: optionsRes } = useGet(OPTIONS_URLS)
    const { doFetch: submitPost } = usePost()
    const [submitLoading, setSubmitLoading] = useState(false)
    const { doFetch: getContact } = useGet()
    const [showAddContact, setShowAddContact] = useState(false)
    const [countFile, updateFile] = useReducer((x) => x + 1, 60000)
    const [contactOptions, setContactOptions] = useState([])
    const { data: custIdRes, doFetch: getCustId } = useGet()
    const [isAddLinkman] = useFuncCode(['202000'])

    const { customer } = useMemo(() => formData || {}, [formData])

    useEffect(() => {
        if (_.isNil(customer)) return
        getCustId('/story/getbugProjectName?id=' + customer)
    }, [customer, getCustId])

    const customerOptions = useMemo(() => {
        if (_.isEmpty(custIdRes)) return []
        if (_.isNil(customer)) return []
        const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
        return _.map(filterStatus, x => ({ text: x.customerName, value: x.customer, supportUser: x.supportUser }))
    }, [custIdRes, customer])

    useEffect(() => {
        const initReqDetail = _.get(initForm, 'reqDetail')
        if (_.isNil(initReqDetail)) return
        richEditorRef.current.setContent(initReqDetail || '')
    }, [initForm])

    const [issueKindOptions, priorityOptions, sceneOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const [allVersionOptions, custIdOptions, productTreeOptions, moduleOptionsRes, issueTypeOptions, productManagerOptions] = useMemo(() => {
        if (_.isEmpty(optionsRes)) return []
        const [d1, d2, d3, d4, d5, d6] = optionsRes
        const productTreeOptions = _.map(_.groupBy(d3, product => product.productLine), (productLineItem, productLine) => ({
            text: _.get(_.find(d4, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
            value: productLine,
            _disabled: true,
            children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
        }))
        return [
            _.map(d1, x => ({ text: x.releaseid, value: x.releaseid })),
            _.map(d2, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}`, tCustId: x.customer })),
            productTreeOptions,
            d5,
            _.map(d6, x => ({ text: x.interiorId, value: x.interiorId })),
            _.map(d3, x => ({ productManager: x.productManager, value: x.productId }))
        ]
    }, [optionsRes])

    const moduleOptions = useMemo(() => {
        if (_.isEmpty(moduleOptionsRes)) return []
        if (_.isNil(_.get(formData, 'productType'))) return []
        const filterModuleOptions = _.filter(moduleOptionsRes, x => x.productId === _.get(formData, 'productType'))
        return _.map(filterModuleOptions, x => ({ text: x.subSysName, value: x.subSysId, tag: x.productId, independentVerFlag: x.independentVerFlag, extConn: x.extConn }))
    }, [formData, moduleOptionsRes])

    const getNewOnlyId = useCallback(() => {
        getOnlyId('/test_case/productGetOnlyId').then(id => {
            setFormData(x => _.assign({}, x, { id }))
            updateFile()
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getOnlyId])

    useEffect(() => {
        getNewOnlyId()
    }, [getNewOnlyId])

    const versionDisabled = useMemo(() => {
        return _.get(formData, 'issueKind') !== '12'
    }, [formData])

    const changeForm = useCallback((newObj, bind) => {
        setFormData(oldObj => {
            let cloneObj = { ...oldObj, ...newObj }
            if (bind === 'authorizationStartDate') {
                if (!isNil(cloneObj['deadline']) && cloneObj[bind] > cloneObj['deadline']) {
                    cloneObj[bind] = null
                    Messager.show('不能大于API授权到期日', { icon: 'error' })
                }
            }
            if (bind === 'deadline') {
                if (!isNil(cloneObj['authorizationStartDate']) && cloneObj[bind] < cloneObj['authorizationStartDate']) {
                    cloneObj[bind] = null
                    Messager.show('不能小于API授权开始日', { icon: 'error' })
                }
            }
            if (bind === 'apiAuthFlag') {
                if (cloneObj[bind] === 'N') {
                    cloneObj['conNumber'] = null
                    cloneObj['deadline'] = null
                    cloneObj['authorizationStartDate'] = null
                    cloneObj['customerDepart'] = null
                }
            }
            if (bind === 'productType') {
                cloneObj['subSysId'] = null
            }
            if (bind === 'issueKind') {
                if (newObj['issueKind'] !== '12') {
                    cloneObj['version'] = null
                    cloneObj['applyversion'] = null
                }
            }
            if (bind === 'productType') {
                const newValue = _.get(newObj, 'productType')
                const findItem = _.find(productManagerOptions, x => x.value === newValue)
                const productManager = _.get(findItem, 'productManager')
                if (!isNil(productManager)) {
                    cloneObj['principal'] = productManager
                    cloneObj['analyst'] = productManager
                }
            }
            if (bind === 'customer') {
                cloneObj['linker'] = null
                cloneObj['custContactInfo'] = null
                getCustId('/story/getbugProjectName?id=' + _.get(newObj, 'customer')).then(custIdRes => {
                    const filterStatus = _.filter(custIdRes, o => o?.status !== '1')
                    const customerName = _.get(filterStatus, '0.customer')
                    changeForm({ customerName }, 'customerName')
                })
            }
            if (bind === 'linker') {
                if (_.isNil(cloneObj['linker'])) {
                    cloneObj['custContactInfo'] = null
                    cloneObj['linkerEmail'] = null
                } else {
                    cloneObj['custContactInfo'] = convertOptions(cloneObj['linker'], contactOptions, 'phone')
                    cloneObj['linkerEmail'] = convertOptions(cloneObj['linker'], contactOptions, 'email')
                }
            }
            return cloneObj
        })
    }, [productManagerOptions, contactOptions, getCustId])

    const continueClear = useCallback(() => {
        richEditorRef.current.setContent('')
        setFormData(old => {
            // const cloneObj = {...old}
            // _.forEach(cloneObj, (v,k) => {
            //     cloneObj[k] = null
            // })
            return {
                ...old,
                issueDesc: null,
            }
        })
        getNewOnlyId()
    }, [getNewOnlyId])

    const canSubmit = useMemo(() => {
        return !_.some(_.values(formError), x => x)
    }, [formError])

    const submit = useCallback(() => {
        if (isNil(richEditorRef.current.getContent())) {
            return Messager.show("请填写详细描述")
        }
        if (submitLoading) return
        setSubmitLoading(true)
        const postParams = {
            ...formData,
            reqDetail: _.replace(richEditorRef.current.getContent(), /\n/g, ''),
            status: '01',
            ...submitAppendParams,
        }
        submitPost('/customerService/add', postParams).then(() => {
            Messager.show('添加成功', { icon: 'success' })
            setSubmitLoading(false)
            refreshViewList()
            if (setFocusId) {
                setFocusId(_.get(formData, 'id'))
            }
            if (continueAdd) {
                continueClear()
            } else {
                close()
            }
        }).catch(err => {
            Messager.show(err._message, { icon: 'error' })
            setSubmitLoading(false)
        })
    }, [formData, submitPost, submitLoading, refreshViewList, continueAdd, close, continueClear, setFocusId, submitAppendParams])

    const customerName = useMemo(() => {
        return _.get(formData, 'customerName')
    }, [formData])

    const refreshContact = useCallback(() => {
        if (isNil(customerName)) return
        getContact('/custlinker/listByCustId?tcustId=' + customerName).then(res => {
            if (_.isEmpty(res)) return setContactOptions([])
            const options = _.map(res, x => ({
                text: _.get(x, 'linker'),
                value: _.get(x, 'id'),
                phone: _.get(x, 'phone') || _.get(x, 'landLine'),
                email: _.get(x, 'email'),
            }))
            setContactOptions(options)
        })
    }, [customerName, getContact])

    useEffect(() => {
        refreshContact()
    }, [refreshContact])

    const linkerRequired = useMemo(() => {
        return _.get(formData, 'customer') !== 'NBGJ'
    }, [formData])

    useEffect(() => {
        if (linkerRequired) {
            if (isNil(_.get(formData, 'linker'))) {
                setFormError(x => _.assign({}, x, { 'linker': '必填项！' }))
            }
        } else {
            setFormError(x => _.assign({}, x, { 'linker': null }))
        }
    }, [formData, linkerRequired])

    useEffect(() => {
        const apiAuthFlag = _.get(formData, 'apiAuthFlag') === 'Y'
        const conNumber = _.get(formData, 'conNumber')
        const deadline = _.get(formData, 'deadline')
        const authorizationStartDate = _.get(formData, 'authorizationStartDate')
        const customerDepart = _.get(formData, 'customerDepart')
        setFormError(x => _.assign({}, x,
            { conNumber: null, deadline: null, authorizationStartDate: null, customerDepart: null },
            apiAuthFlag && {
                conNumber: isNil(conNumber) ? '必填项！' : null,
                deadline: isNil(deadline) ? '必填项！' : null,
                authorizationStartDate: isNil(authorizationStartDate) ? '必填项！' : null,
                customerDepart: isNil(customerDepart) ? '必填项！' : null,
            }))
    }, [formData])

    return <Dialog header={'新增'} className={'customer-service-info-add-dialog'} footerVisible={false} cancel={close}>
        <div className="content-wrap flex-y">
            <HFormInput label={'标题'} value={_.get(formData, 'issueDesc')} onChange={v => setFormData(x => ({ ...x, issueDesc: v }))}
                required componentWidth={770} className={'title-wrap'} />
            <div className="area-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: baseInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setBaseInfoExpand(v => !v)} />
                    基本信息
                </div>
                <div className="area-content-wrap">
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                        <HFormInput label={'发生日期'} bind={'occurTime'} component={DatePicker} required bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                        <HFormInput label={'问题性质'} bind={'issueKind'} clear search component={Select} options={issueKindOptions} />
                        <HFormInput label={'优先级'} bind={'priority'} required search component={Select} options={priorityOptions} />
                        <HFormInput label={'分类标记'} bind={'issuetype'} required search component={Select} options={issueTypeOptions} />
                        <HFormInput label={'基础版本'} bind={'version'} search clear component={Select} options={allVersionOptions} disabled={versionDisabled} />
                        <Tooltip title={'问题性质为【版本申请】时可填'}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                        <HFormInput label={'申请版本'} bind={'applyversion'} search clear component={Select} options={allVersionOptions}
                            disabled={versionDisabled} labelWidth={116} />
                        <Tooltip title={'问题性质为【版本申请】时可填'}>
                            <span>
                                <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF' }} />
                            </span>
                        </Tooltip>
                    </Form>
                </div>
            </div>
            <div className="area-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: customerInfoExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setCustomerInfoExpand(v => !v)} />
                    客户信息
                </div>
                <div className="area-content-wrap">
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                        <HFormInput label={'客户系统'} bind={'customer'} required search component={Select} options={custIdOptions} />
                        <HFormInput label={'客户名称'} bind={'customerName'} required search component={Select} options={customerOptions} />
                        <HFormInput label={'客户联系人'} bind={'linker'} required={linkerRequired} search clear component={Select} options={contactOptions} />
                        {
                            isAddLinkman &&
                            <Tooltip title={'新增联系人'}>
                                <span>
                                    <Icon name={'tianjia'} className={clsx('add-contact-icon', { 'disabled': _.isNil(customerName) })} style={{ fontSize: 14 }}
                                        onClick={() => {
                                            if (_.isNil(customerName)) return
                                            setShowAddContact(true)
                                        }} />
                                </span>
                            </Tooltip>
                        }
                        <HFormInput label={'联系方式'} bind={'custContactInfo'} />
                        <HFormInput label={'产品名称'} bind={'productType'} required search tree component={Select} options={productTreeOptions} />
                        <HFormInput label={'子产品'} bind={'subSysId'} search clear component={Select} options={moduleOptions} />
                        <HFormInput label={'客户问题编号'} bind={'clientIssueNo'} />
                        <HFormInput label={'问题提出场景'} bind={'scene'} search clear component={Select} options={sceneOptions} />
                        <HFormInput label={'竞争对手'} bind={'competitor'} />
                        <HFormInput label={'随访人员'} bind={'userList'} component={UserSelect} allowClear />
                        <HFormInput label={'是否涉及API授权'} bind={'apiAuthFlag'} component={Select} options={YNOptions} required />
                        {
                            _.get(formData, 'apiAuthFlag') === 'Y' &&
                            <>
                                <HFormInput label={'API授权连接数'} bind={'conNumber'} required type='number' digit={0} />
                                <HFormInput label={'API授权开始日'} bind={'authorizationStartDate'} component={DatePicker} required bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                                <HFormInput label={'API授权到期日'} bind={'deadline'} component={DatePicker} required bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                                <HFormInput required label='客户提出部门' bind='customerDepart' component={Input} />
                            </>
                        }
                    </Form>
                </div>
            </div>
            <div className="area-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: analysisExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setAnalysisExpand(v => !v)} />
                    分析信息
                </div>
                <div className="area-content-wrap">
                    <Form value={formData} onChange={changeForm} error={formError} onError={v => setFormError(x => _.assign({}, x, v))}>
                        <HFormInput label={'负责人'} bind={'principal'} component={UserSelect} required />
                        <HFormInput className='groups-people-edit' label={'产研负责人'} bind={'devPrincipal'} component={UserSelect} multiple bindOutConvert={v => _.join(v, ',')} />
                        <HFormInput label={'分析人'} bind={'analyst'} component={UserSelect} />
                        <HFormInput label={'预期解决日期'} bind={'promiseDate'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} bindInConvert={v => toDate.dateStringToDate(v)} />
                    </Form>
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: descExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDescExpand(v => !v)} />
                    问题详细描述
                    <span style={{ color: 'red' }}>（必填）</span>
                </div>
                <div className="rich-text-area" style={{ display: descExpand ? 'block' : 'none' }}>
                    <RichTextEditor ref={richEditorRef} />
                </div>
            </div>
            <div className="area-wrap desc-wrap">
                <div className="area-header flex center-y">
                    <Icon name="arrow_drop_down" className='fold-icon' style={{ transform: docExpand ? 'none' : 'rotate(-90deg)' }} onClick={() => setDocExpand(v => !v)} />
                    附件
                </div>
                <div className="doc-area" style={{ display: docExpand ? 'block' : 'none' }} key={countFile}>
                    <RequirementAddUploadArea id={onlyId} allUserRes={allUserRes} funcCode={'20'} />
                </div>
            </div>
        </div>
        <div className="mock-footer flex center-y">
            <div className={'continue-add-check'}>
                <Checkbox checked={continueAdd} onChange={e => setContinueAdd(e.target.checked)}>连续新增</Checkbox>
            </div>
            <div className="btn-group flex">
                <Button normal onClick={close}>取消</Button>
                <Button primary disabled={!canSubmit || submitLoading} onClick={submit}>确认</Button>
            </div>
        </div>
        {
            submitLoading &&
            <Loader fill text={'提交中'} />
        }
        {
            showAddContact && <InfoFormLog
                currentInfo={{ mode: 'add' }}
                close={() => setShowAddContact(false)}
                refreshViewList={refreshContact}
                // setInitFormData={setFormData}
                initForm={{
                    // linker: _.get(formData, 'linker'),
                    // phone: _.get(formData, 'custContactInfo'),
                    customerId: _.get(formData, 'customer'),//客户系统
                    tCustid: _.get(formData, 'customerName'), //客户名称
                }}
            />

        }
    </Dialog>
}

export default CustomerServiceInfoAddDialog;
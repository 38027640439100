import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { Popover, Checkbox, Table, Badge } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { ScheduleManagementContext } from '../../../../../common/Context'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import Options from './options'
import { selectOption } from '../../../../../common/commonMethod'
import { strParams } from '../../../../../../utils/publicFun'
import gd from '../../../../../../base/global'
import { getColumns } from '../../../../versionMsg/components/versionDetail/components/permission'
import JoinApply from '../../../../versionMsg/components/versionDetail/versionRequirementMgt/joinApply'
import DelMessage from '../../../../versionMsg/components/versionDetail/versionRequirementMgt/delMessage'
import RequirementDetailDialog from '../../../../../requirementMgt/requirementDetailDialog/RequirementDetailDialog'
import IssueDetailDialog from '../../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import ShuttleColumn from '../../../../versionMsg/components/versionDetail/versionRequirementMgt/shuttleColumn'
import RegressionMessage from '../../../../versionMsg/components/versionDetail/versionRequirementMgt/regressionMessage'
import RelevanceReq from '../../../../versionMsg/components/versionDetail/versionRequirementMgt/relevanceReq/relevanceReq'
import RelevanceIssue from '../../../../versionMsg/components/versionDetail/versionRequirementMgt/relevanceIssue/relevanceIssue'
import './index.scss'
import ImportApiDialog from '../../../../../common/ImportApiDialog'

const CheckboxGroup = Checkbox.Group;

const JOIN_APPLY_URL = '/releaseStory/story/log'
const REQ_LIST_URL = '/releaseStory/list'
const EXPORT_INVENTORY_LIST_URL = '/releaseStory/list/export'
const OPERATION_URL = '/ReleaseStory/addReleaseStoryList'
// const EXPORT_REQ_MATRIX_URL = '/releaseStory/storyMatrix/export' //导出需求矩阵表
const ALL_COLUMNS_URL = '/releaseStory/getPlanCustomShowField' //所有列表
const EXPORT_MEETING_LIST_URL = '/releaseStory/ratMeetingList/export'
const EDIT_URL = '/ReleaseStory/update'

const OPTIONS_URLS = [
  '/viewCommon/getProductInfo', // 产品
  '/common/globalconst?globalConst=ProductLine', // 产品
  '/verQuery/queryCustOptions', // 客户系统
]

const plainOptions = [
  { label: '父需求', value: 'FREQ' },
  { label: '需求', value: 'REQ' },
  { label: 'ISSUE', value: 'ISSUE' },
]

const optValues = _.map(plainOptions, o => o.value)

export default function PlanVersionBaseLineList(props) {
  const { currentId, allUserRes, storyTypeOptions, workflowOpt, priorityOpt, yseOrNoOpt, isReqCode, isProjectCode, principals, poUser, isDisabled, updateInventoryDynamic, state } = props
  const [joinApply, setJoinApply] = useState()
  const [typeChange, setTypeChange] = useState(optValues)
  const [typeNum, setTypeNum] = useState({})
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [expanded, setExpanded] = useState(true)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [params, setParams] = useState()
  const [actionType, setActionType] = useState()
  const [changeData, setChangeData] = useState({})
  const [editInfo, setEditInfo] = useState()
  const { data: listRes, doFetch, loading } = useGet()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { downloadGetProgress } = useContext(ScheduleManagementContext)
  const { doFetch: operationFetch } = usePost()
  const [showDetail, setShowDetail] = useState()
  const [totalWorkload, setTotalWorkload] = useState(0)
  const [openColumn, setOpenColumn] = useState(false)
  const [untreatedNum, setUntreatedNum] = useState(0)
  const { data: allColumns } = usePost(ALL_COLUMNS_URL)

  const { data, doFetch: getList } = useGet()
  const [searchNum, setSearchNum] = useState()

  const isPlanning = useMemo(() => {
    return (isReqCode || isProjectCode || _.includes(principals, gd.User.operator_id) || gd.User.operator_id === poUser)
  }, [isReqCode, isProjectCode, principals, poUser])

  useEffect(() => {
    setExpanded(true)
    setSelectedRows([])
    setExpandedRowKeys([])
    setSelectedRowKeys([])
    setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), !_.isNil(x?.updateTime) && { updateTime: { begin: null, end: null } }))
  }, [currentId])

  const refresh = useCallback(() => {
    getList(`${JOIN_APPLY_URL}?auditType=1&releaseStatus=C&releaseId=${currentId}`)
      .then(res => {
        setUntreatedNum(_.size(_.filter(res, o => o.auditFlag === 'U')))
      })
  }, [currentId, getList])

  useEffect(() => {
    refresh()
  }, [refresh])

  const [productTreeOptions, productOptions, customerSystemOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const productTreeOptions = _.map(_.groupBy(d1, product => product.productLine), (productLineItem, productLine) => ({
      text: _.get(_.find(d2, x => x.interiorId === productLine), 'displayName') || productLine || '无产品线',
      value: productLine,
      _disabled: true,
      children: _.map(productLineItem, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}` }))
    }))
    return [
      productTreeOptions,
      _.map(d1, x => ({ value: x.productId, text: x.productName, tag: `${x.productId} ${x.productName}`, productLine: x.productLine, independentVerFlag: x.independentVerFlag })),
      selectOption(d3, ['projectName', 'projectId']),
    ]
  }, [optionsRes])

  const dataSource = useMemo(() => {
    if (_.isEmpty(listRes)) return []
    return filterType(_.get(listRes, 'treeNodes', []), typeChange, currentId)
  }, [listRes, typeChange, currentId])

  const listExport = useCallback(() => {
    if (_.isNil(currentId)) return
    const time = _.isNil(params?.updateTime) ? {} : { beginDate: params.updateTime.begin, endDate: params.updateTime.end }
    const typeList = _.join(typeChange, ',')
    const par = _.assign({}, _.omit(params, ['updateTime']), { releaseId: currentId, typeList, versionType: '1' }, time)
    downloadGetProgress(`${EXPORT_INVENTORY_LIST_URL}?${strParams(par)}`, '导出列表', () => { })
  }, [currentId, downloadGetProgress, params, typeChange])

  // const exportDemandMatrixList = useCallback(() => {
  //   if (_.isNil(currentId)) return
  //   downloadGetProgress(`${EXPORT_REQ_MATRIX_URL}?${strParams({ releaseId: currentId, versionBaseline: 'baseline' })}`, '导出需求矩阵列表', uniqKeyFor(), () => { })
  // }, [currentId, downloadGetProgress])

  const exportMeetingList = useCallback(() => {
    if (_.isNil(currentId)) return
    downloadGetProgress(`${EXPORT_MEETING_LIST_URL}?${strParams({ releaseId: currentId, versionBaseline: 'baseline', versionType: '1' })}`, '导出RAT会议清单', () => { })
  }, [currentId, downloadGetProgress])

  const search = useCallback((par = {}) => {
    if (_.isNil(currentId)) return
    setSearchNum(_.size(_.keys(par)))
    const time = _.isNil(par?.updateTime) ? {} : { beginDate: par.updateTime.begin, endDate: par.updateTime.end }
    const pars = _.assign({}, _.omit(par, ['updateTime']), { releaseId: currentId }, time, { versionBaseline: 'baseline', versionType: '1' })
    doFetch(`${REQ_LIST_URL}?${strParams(pars)}`)
  }, [currentId, doFetch])

  const editChange = useCallback(({ newVal, oldVal, id }) => {
    if (newVal === oldVal || _.isNil(id)) { setEditInfo(null); return }
    const postParams = [
      {
        releaseid: currentId,
        storyid: id,
        regression: newVal,
      }
    ]
    operationFetch(EDIT_URL, postParams)
      .then(() => {
        updateInventoryDynamic()
        search(params)
        setEditInfo(null)
        Messager.show('修改成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [operationFetch, currentId, search, params, updateInventoryDynamic])

  const dataManipulation = useCallback((par, flag = true) => {
    //releaseid: currentId, type, optionType: mode, storyidList
    operationFetch(OPERATION_URL, par)
      .then(() => {
        updateInventoryDynamic()
        flag && search(params)
        flag && setChangeData({})
        if (_.includes(['delValues', 'copyValues'], actionType)) {
          setSelectedRowKeys([])
          setSelectedRows([])
        }

        Messager.show('成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [actionType, operationFetch, params, search, updateInventoryDynamic])

  const reqList = useMemo(() => {
    return getReqList(_.get(listRes, 'treeNodes', []))
  }, [listRes])

  const issueList = useMemo(() => {
    return getIssueList(_.get(listRes, 'treeNodes', []))
  }, [listRes])

  const columns = useMemo(() => {
    return getColumns({
      productOptions, customerSystemOpt, storyTypeOptions, allUserRes, setActionType,
      setChangeData, isDisabled, expanded, setExpanded, setShowDetail, workflowOpt, priorityOpt, yseOrNoOpt, editInfo, setEditInfo, editChange, isPlanning,
      allColumns, customField: _.get(listRes, 'customField', ''), isWrap: _.get(listRes, 'isWrap', false),
      isCopy: false
    })
  }, [allUserRes, customerSystemOpt, expanded, isDisabled, productOptions, storyTypeOptions, workflowOpt, listRes, allColumns, priorityOpt, yseOrNoOpt, editInfo, editChange, isPlanning])

  useEffect(() => {
    if (_.isEmpty(listRes)) return
    const tileList = tileData(parentReq(_.get(listRes, 'treeNodes', [])))
    const groupData = _.groupBy(tileList, 'type')
    const numObj = _.assign({}, ..._.map(plainOptions, o => ({ [o.value]: _.size(_.get(groupData, o.value, [])) })))
    setTypeNum(numObj)
  }, [listRes])

  useEffect(() => {
    search()
  }, [currentId, doFetch, search])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows)
      setSelectedRowKeys(selectedRowKeys)
    },
  };

  useEffect(() => {
    if (actionType === 'delValues' || actionType === 'copyValues' || actionType === 'regressionValues') {
      if (_.size(selectedRows) === 0) {
        setActionType(null)
        Messager.show('请选择数据后再进行操作', { icon: 'error' })
      }
    }
  }, [actionType, selectedRows])

  const RightFun = useCallback(() => {
    const getNumOpt = _.map(plainOptions, o => {
      return _.assign({}, o, {
        label: o.value === 'FREQ' ? o.label : <span style={{ color: '#8A8888' }}>{`${o.label}：${_.get(typeNum, o.value, 0)}`}</span>
      })
    })
    return <div className='flex req-fun'>
      <div className='type-num'>
        <span>总工作量：{totalWorkload}</span>
      </div>
      <div className='type-change'>
        <CheckboxGroup options={getNumOpt} value={typeChange} onChange={v => {
          const filterList = _.filter(v, k => _.includes(['REQ', 'FREQ'], k))
          if (_.size(filterList) === 1 && _.includes(filterList, 'FREQ')) setTypeChange(x => {
            return _.includes(x, 'REQ') ? _.filter(v, k => k !== 'FREQ') : _.concat(v, ['REQ'])
          })
          else setTypeChange(v)
        }} />
      </div>
      {
        _.includes(principals, gd.User.operator_id) &&
        <Badge count={untreatedNum} size="small" offset={[8, 0]}>
          <TextIconBtn icon='' text='变动申请' onClick={() => setJoinApply(true)} />
        </Badge>
      }
      <MoreOperations {...{ listExport, exportMeetingList }} />
      {
        isPlanning && _.includes(principals, gd.User.operator_id) &&
        <ReleasePlanning  {...{ state, setActionType, isDisabled }} />
      }
      <TextIconBtn icon='shezhi' text='' onClick={() => setOpenColumn(true)} />
    </div>
  }, [exportMeetingList, listExport, totalWorkload, typeChange, typeNum, isPlanning, isDisabled, untreatedNum, principals, state])

  useEffect(() => {
    if (_.isEmpty(listRes)) return
    let filterData = parentReq(_.get(listRes, 'treeNodes', []))
    const tileList = tileData(filterData)
    filterData = tileList
    filterData = _.filter(filterData, o => _.includes(['REQ', 'ISSUE'], o.type))
    const sumWorkload = _.sum(_.map(filterData, o => _.toNumber(o.workload)))
    const sum = parseInt(sumWorkload * 100)
    setTotalWorkload(sum / 100)
  }, [listRes])

  useEffect(() => {
    if (_.isEmpty(dataSource)) return
    if (expanded) setExpandedRowKeys(getKey(dataSource))
    else setExpandedRowKeys([])
  }, [dataSource, expanded])

  return (
    <div className='plan-version-base-line-list flex-y'>
      <div className='header'>
        <Options {...{ searchNum, params, setParams, search, productTreeOptions, customerSystemOpt, storyTypeOptions, workflowOpt, yseOrNoOpt, RightFun }} />
      </div>
      <div className='req-table'>
        <Table
          bordered
          size='small'
          rowKey={record => {
            if (record.type === 'TASK') return record.pid + record.storyId
            return record.storyId
          }}
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          rowSelection={{ ...rowSelection, checkStrictly: false, selectedRowKeys }}
          pagination={false}
          scroll={{ y: 9999999, x: 1300 }}
          expandedRowKeys={expandedRowKeys}
          onExpand={(bool, row) => {
            if (bool) setExpandedRowKeys(x => [...x, row.storyId])
            else {
              const index = expandedRowKeys.findIndex((e) => e === row.storyId)
              const newArray = [...expandedRowKeys]
              newArray.splice(index, 1)
              setExpandedRowKeys(newArray)
            }
          }}
        />
      </div>
      {
        joinApply && !_.isEmpty(allUserRes) &&
        <JoinApply
          close={() => setJoinApply(false)}
          refreshHome={() => search(params)}
          {...{ refresh, allUserRes, data }}
        />
      }
      <RelevanceReq
        funcCode={'3600'}
        open={actionType === 'REQ'}
        loading={loading}
        data={reqList}
        releaseid={currentId}
        close={() => setActionType(null)}
        replaceType={'PLANREQ'}
        refresh={() => {
          search(params)
          updateInventoryDynamic()
        }}
        appendParams={{
          versionType: '1'
        }}
      />
      <RelevanceIssue
        open={actionType === 'ISSUE'}
        loading={loading}
        data={issueList}
        releaseid={currentId}
        close={() => setActionType(null)}
        replaceType={'PLANISSUE'}
        refresh={() => {
          search(params)
          updateInventoryDynamic()
        }}
        appendParams={{
          versionType: '1'
        }}
      />
      {
        (actionType === 'del' || (actionType === 'delValues' && _.size(selectedRows) > 0)) &&
        <DelMessage
          header={
            <span className='DelMessage-lz'>
              删除
              <span>（已选{actionType === 'del' ? _.size(changeData.storyidList) : _.size(selectedRows)}个）</span>
            </span>
          }
          versionType={true}
          close={() => setActionType(null)}
          currentId={currentId}
          actionType={actionType}
          changeData={changeData}
          selectedRows={selectedRows}
          dataManipulation={dataManipulation}
        />
      }
      {
        ((actionType === 'regressionValues' && _.size(selectedRows) > 0)) &&
        <RegressionMessage
          yseOrNoOpt={yseOrNoOpt}
          close={() => setActionType(null)}
          selectedRows={selectedRows}
          currentId={currentId}
          refresh={() => {
            updateInventoryDynamic()
            search(params)
          }}
        />
      }
      {
        openColumn &&
        <ShuttleColumn
          {...{ allColumns, customField: _.get(listRes, 'customField', ''), isWrap: _.get(listRes, 'isWrap', false) }}
          refresh={() => {
            refresh()
            search(params)
            setSelectedRowKeys([])
            setSelectedRows([])
          }}
          close={() => setOpenColumn(false)}
        />
      }
      {
        _.get(showDetail, 'type') === 'REQ' &&
        <RequirementDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          showChildList={[]}
        />
      }
      {
        _.get(showDetail, 'type') === 'ISSUE' &&
        <IssueDetailDialog
          close={() => setShowDetail(null)}
          refreshViewList={() => {
            refresh()
            search(params)
          }}
          currentInfo={{ id: _.get(showDetail, 'id') }}
          showChildList={[]}
        />
      }
      {
        _.includes(['reqExcel', 'issueExcel'], actionType) &&
        <ImportApiDialog
          title=''
          parameter={{
            releaseId: currentId,
            type: 'reqExcel' === actionType ? 'PLANREQ' : 'PLANISSUE',
          }}
          isExecutionList={false}
          close={() => setActionType(null)}
          importUrl={'reqExcel' === actionType ? '/ReleaseStory/importStory' : '/ReleaseStory/importIssue'}
          template={'reqExcel' === actionType ? '/field/download?flag=10' : '/field/download?flag=11'}
          refresh={() => {
            refresh()
            search(params)
          }}
        />
      }
    </div>
  )
}

function filterType(data, condition, currentId) {
  let filterList = _.cloneDeep(data)
  const tileList = tileData(filterList)
  filterList = tileList
  if (!_.includes(condition, 'FREQ')) filterList = _.filter(filterList, o => (!(o.type === 'REQ' && o.inRelease !== currentId)))
  if (!_.includes(condition, 'REQ')) filterList = _.filter(filterList, o => (o.type !== 'REQ'))
  if (!_.includes(condition, 'ISSUE')) filterList = _.filter(filterList, o => o.type !== 'ISSUE')
  if (!_.includes(condition, 'DEFECT')) filterList = _.filter(filterList, o => o.type !== 'DEFECT')
  if (!_.includes(condition, 'TASK')) filterList = _.filter(filterList, o => o.type !== 'TASK')
  if (!_.includes(condition, 'DEV')) filterList = _.filter(filterList, o => o.type !== 'DEV')
  if (_.size(condition) === 1 && _.includes(condition, 'TASK')) {
    const obj = {}
    _.forEach(filterList, o => {
      if (o.type === 'TASK') obj[o.storyId] = o
    })
    filterList = _.values(obj)
  }
  return treeData(filterList)
}

function parentReq(data, arr = []) {
  _.forEach(data, o => {
    if (_.isEmpty(o.subStoryList)) arr.push(o)
    else {
      if (_.includes(_.map(o.subStoryList, x => x.type), 'REQ')) parentReq(o.subStoryList, arr)
      else arr.push(o)
    }
  })
  return arr
}

function getReqList(data) {
  let filterList = tileData(data)
  filterList = _.filter(filterList, o => (o.type === 'REQ' || o.type === 'DEV'))
  return _.map(filterList, item => (_.assign({
    interiorReqNo: item.storyId,
  }, _.pick(item, ['title', 'id']))))
}

function getIssueList(data) {
  let filterList = tileData(data)
  filterList = _.filter(filterList, o => o.type === 'ISSUE')
  return _.map(filterList, item => ({
    id: item.id,
    issueId: item.storyId,
    shortDesc: item.title,
  }))
}

function treeData(data) {
  _.forEach(data, o => { delete o.children })
  let map = {}
  _.forEach(data, o => {
    if (o.type === 'TASK') {
      map[o.storyId + o.pid] = o
    } else map[o.id] = o
  })

  let val = [];
  _.forEach(data, o => {
    let parent = map[o.pid];
    if (parent) ((parent.children) || (parent.children = [])).push(o);
    else val.push(o);
  })

  return val;
}

function tileData(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o)
    if (!_.isEmpty(o.subStoryList)) tileData(o.subStoryList, arr)
  })
  return arr
}

function ReleasePlanning({ state, setActionType, isDisabled }) {
  const [clicked, setClicked] = useState(false)

  const className = useMemo(() => isDisabled ? 'item-operation-disabled' : '', [isDisabled])

  const onClick = useCallback((mode) => {
    setActionType(mode)
    setClicked(false)
  }, [setActionType])

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('reqExcel')}>导入需求（EXCEL）</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('issueExcel')}>导入ISSUE（EXCEL）</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('REQ')}>导入需求</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('ISSUE')}>导入ISSUE</div>
      <div className={className} onClick={() => isDisabled ? () => { } : onClick('delValues')}>移除</div>
      <div onClick={() => onClick('regressionValues')}>编辑回归标识</div>
    </div>
  }, [className, isDisabled, onClick])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='更多' disabled={state === 'L'} onClick={() => {
        if (state === 'L') return
        setClicked(x => !x)
      }} />
    </Popover>
  )

}

function MoreOperations({ listExport, exportMeetingList }) {
  const [clicked, setClicked] = useState(false)

  const Content = useCallback(() => {
    return <div className='allConditions-popover'>
      <div onClick={listExport}>导出列表</div>
      <div onClick={exportMeetingList}>导出RAT会议清单</div>
    </div>
  }, [listExport, exportMeetingList])

  return (
    <Popover
      trigger="click"
      open={clicked}
      placement='bottomLeft'
      content={<Content />}
      onOpenChange={(visible) => setClicked(visible)}
      overlayClassName='popoverMore'
    >
      <TextIconBtn icon='' text='导出' onClick={() => setClicked(x => !x)} />
    </Popover>
  )

}

function getKey(data, arr = [], id = 'storyId') {
  _.forEach(data, o => {
    // if (o.type === 'TASK') return
    arr.push(o[id])
    if (!_.isEmpty(o.children)) getKey(o.children, arr, id)
  })
  return arr
}


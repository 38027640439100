import React, { useCallback } from 'react'
import { Dialog } from 'rootnet-ui'

export default function EditTipLog(props) {
  const { close, item, onChange } = props

  const confirm = useCallback(() => {
    onChange(item.demoValue, item)
    close()
  }, [onChange, item, close])

  return <Dialog
    header='提示'
    className='content-template-edit-tip-log'
    confirm={confirm}
    cancel={close}
  >
    选择模板后将覆盖当前编辑内容，请确认！
  </Dialog>
}

import React, {useMemo, useState} from 'react';
import {DataGrid, Dialog} from "rootnet-ui";
import './DelayInfoDialog.scss'
import useGet from "rootnet-biz/es/hooks/useGet";
import _ from 'lodash'
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import convertOptions from "../../../common/ConvertOptions";
import convertGlobalConstOptions from "../../../common/ConvertGlobalConstOptions";
import ReqStageUpdateDialog from "../../../stageMgt/reqStageUpdateDialog/ReqStageUpdateDialog";
import TodoListDetailDialog from "../../../todolist/components/todoListDetailDialog";

const typeOptions = [
    {value: 'todoList', text: '跟进事项'},
    {value: 'stage', text: '阶段任务'},
]

function getColumns(props){
    const {allUserRes, workFlowStatusOptions, setShowDetailDialog} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40, stretch: false },
        { header: '标题', bind: 'title', width: 200, tooltip: true, sortable:true, convert: (v,o) => {
            return <div className={'can-enter-text'} onClick={()=>{
                setShowDetailDialog({
                    module: _.get(o,'type'),
                    id: _.get(o, 'id')
                })
            }}>
                {v}
            </div>
            }},
        { header: '状态', bind: 'status', width: 100, tooltip: true, sortable:true, convert: v => {
                const workflowColor = _.get(_.find(workFlowStatusOptions, x => x.value === v),'color') || '#000'
                return <div className={'status-tag flex center'} style={{color: workflowColor, border: `1px solid ${workflowColor}`,padding: '0 8px', borderRadius: 4}}>
                    {convertOptions(v, workFlowStatusOptions) || '-'}
                </div>
            }},
        { header: '业务类型', bind: 'type', width: 100, tooltip: true, sortable:true, convert: v => convertOptions(v, typeOptions) },
        { header: '计划完成时间', bind: 'eventDate', width: 100, tooltip: true, sortable:true, convert: v => dateFormat('YYYY-MM-DD', toDate.str14ToDate(v)) },
        { header: '负责人', bind: 'owner', width: 100, tooltip: true, sortable:true, convert: v => convertOptions(v, allUserRes,'userName','userAccount') },
        { header: '创建人', bind: 'createUser', width: 100, tooltip: true, sortable:true, convert: v => convertOptions(v, allUserRes,'userName','userAccount') },
        { header: '创建时间', bind: 'createTime', width: 100, tooltip: true, sortable:true, convert: v => dateFormat('YYYY-MM-DD', toDate.str14ToDate(v)) },
    ]
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 2,
    resizable: true,
    columns: options,
    virtualized: false,
    autoFill: true,
});

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function DelayInfoDialog(props) {
    const {close} = props
    const {data: delayInfo, loading} = useGet('/workCalendar/delayEvent/count')
    const { data: allUserRes } = useGet('/common/userinfo')
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [showDetailDialog, setShowDetailDialog] = useState()

    const [workFlowStatusOptions] = useMemo(()=>{
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const delayList = useMemo(()=>{
        return _.get(delayInfo,'eventList')
    },[delayInfo])

    const option = useMemo(()=>{
        return getOptions(getColumns({allUserRes, workFlowStatusOptions, setShowDetailDialog}))
    },[allUserRes, workFlowStatusOptions])

    return <Dialog header={'超期未完成&即将超期'} className={'delay-info-dialog'} cancel={close} cancelButtonText={'关闭'} confirmButtonVisible={false}>
        <DataGrid data={delayList} option={option} loading={loading}/>
        {
            _.get(showDetailDialog,'module') === 'stage' &&
            <ReqStageUpdateDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}/>
        }
        {
            _.get(showDetailDialog,'module') === 'todoList' &&
            <TodoListDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{id: _.get(showDetailDialog,'id')}}/>
        }
    </Dialog>
}

export default DelayInfoDialog;
import React, {useEffect, useMemo} from 'react'
import { isNil } from 'rootnet-core/format'
import { DataGrid } from 'rootnet-ui'
import { useGet } from '../../../../../utils/hook'
import convertOptions from '../../../../common/ConvertOptions'

const ISSUE_URL = '/bug/tracer/detail/issue'

function getColumns(props) {
  const { allUserRes } = props
  return [
    { header: 'issue编号', bind: 'issueId', width: 130, tooltip: true, align: 'center', },
    { header: '标题', bind: 'shortDesc', width: 150, tooltip: true, convert: shortDesc },
    { header: '状态', bind: 'issueStatus', width: 80, tooltip: true, align: 'center', },
    { header: '类型', bind: 'type', width: 80, tooltip: true, align: 'center' },
    { header: '创建人', bind: 'inputUser', width: 90, tooltip: true, align: 'center', convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '创建时间', bind: 'inputDate', width: 120, tooltip: true, align: 'center' },
  ]
  function shortDesc(v, o) {
    if (isNil(v)) return '-'
    return <span
      style={{ cursor: 'pointer', color: '#5477ff' }}
      onClick={() => window.open(`#/issue?initId=${o.id}`)}>
      {v}
    </span>
  }
}

const gridOptsFor = (columns) => ({
  nilText: '-',
  emptyText: '-',
  resizable: true,
  autoFill: true,
  virtualized: true,
  columns,
})

export default function RelatedIssue(props) {
  const { id, allUserRes, setIssueList } = props
  const { data, loading } = useGet(`${ISSUE_URL}?tracerId=${id}`)
  const option = useMemo(() => gridOptsFor(getColumns({ allUserRes })), [allUserRes])

  useEffect(()=>{
    setIssueList(data)
  },[setIssueList, data])

  return (
    <div className='related-issue'>
      <DataGrid loading={loading} data={data} option={option} />
    </div>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Anchor } from 'antd'
import { FormInput, Display, Form } from 'rootnet-edit'
import { Icon } from '../../../../../components'
import TinyEditor from '../../../../common/richTextEditor/TinyEditor'
import TextAreaInput from '../../../../common/textAreaInput/TextAreaInput'
import './index.scss'
import { useGet } from 'rootnet-biz/lib/hooks'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import convertOptions from '../../../../common/ConvertOptions'

const HFormInput = (props) => <FormInput horizontal labelWidth={110} componentWidth={200} {...props} />

const listForm = [
  { bind: 'description', name: '缺陷单信息', html: true, },
  { bind: 'testManagerReviewInfo', name: '测试经理审核信息', demoTxt: '101', rows: 18 },  //101
  {
    bind: 'devLocatingInformation', name: '开发人员定位信息',
    children: [{ bind: 'causeAnalysis', name: '原因分析', }]
  },
  { bind: 'devMaInAuditInfo', name: '开发组长定位审核信息', demoTxt: '102', rows: 10 },  //102
  { bind: 'devManModifiInfo', name: '开发人员实施修改信息', demoTxt: '103', rows: 26 },  //103
  { bind: 'devMaAuditInfo', name: '开发组长审核信息', demoTxt: '104', rows: 12 },  //104
  { bind: 'cmAuditInfo', name: 'CM审核信息', demoTxt: '105', rows: 4 },  //105
  {
    bind: 'organizationTestInformation', name: '测试经理组织测试信息',
    children: [{ bind: 'returnToTester', name: '回归测试人员', }]
  },
  { bind: 'returnToTestInfo', name: '回归测试信息', demoTxt: '106', rows: 8 },  //106
]

const demoTxtList = _.map(_.filter(listForm, o => !_.isNil(o?.demoTxt)), 'demoTxt')
const demoTxtUrl = _.map(demoTxtList, v => `/issue/getDemoTxt?id=${v}`)

export default function DefectContent(props) {
  const { isDetail, editFormData, setEditFormData, richEditorRef, previewImage, isAdd, allUserRes } = props
  const defaultList = _.assign(..._.map(listForm, o => ({ [o.bind]: true })))
  const [expand, setExpand] = useState(defaultList)
  const { doFetch } = useGet()
  const mainContentRef = useRef()

  useEffect(() => {
    if (isAdd) {
      doFetch(demoTxtUrl)
        .then(res => {
          const demoTxtObj = _.assign(..._.map(res, o => {
            const findItem = _.find(listForm, k => o.id === k?.demoTxt)
            if (_.isNil(findItem)) return {}
            return { [_.get(findItem, 'bind')]: o.demoValue }
          }))
          setEditFormData(x => _.assign({}, x, demoTxtObj))
        })
    }
  }, [doFetch, setEditFormData, isAdd])

  return (
    <div className='defect-content-info flex' ref={mainContentRef}>
      <Form value={editFormData} onChange={setEditFormData}>
        <div className={`${isAdd ? 'defect-info-add' : 'defect-info'} flex-y`}>
          {
            _.map(listForm, (item, i) => {
              if (i > 0 && isAdd) return <></>
              return <div id={`area-wrap-${item.bind}`} className={`area-wrap area-wrap-${item.bind}`} key={item.bind}>

                <div className="area-header flex center-y">
                  <Icon
                    name="arrow_drop_down"
                    className='fold-icon'
                    style={{ transform: expand[item.bind] ? 'none' : 'rotate(-90deg)' }}
                    onClick={() => setExpand(x => _.assign({}, x, { [item.bind]: !x[item.bind] }))}
                  />
                  {item.name}
                </div>

                <div className="area-content-wrap" style={{ height: expand[item.bind] ? '' : 0 }}>

                  {
                    (item.html && isDetail) &&
                    <div className='description-detail' style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} onClick={previewImage} dangerouslySetInnerHTML={{ __html: _.get(editFormData, `${item.bind}`) }} />
                  }

                  {
                    item.html && !isDetail &&
                    <div className="rich-text-area">
                      <TinyEditor ref={richEditorRef} unique={'defect-update-dialog'} contentStyle={'p{font-size: 14px}'}/>
                    </div>
                  }

                  {
                    (!_.isEmpty(item.children) && !item.html) &&
                    <React.Fragment>
                      {
                        _.map(item.children, o => {
                          if (o.bind === 'returnToTester') {
                            return <HFormInput key={o.bind} label={o.name} bind={o.bind} counter={false} maxLength={99999} component={isDetail ? Display : UserSelect} convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount') || v} />
                          }
                          return <HFormInput key={o.bind} horizontal={false} label={o.name} bind={o.bind} counter={false} maxLength={99999} component={isDetail ? Display : TextAreaInput} />
                        })
                      }
                    </React.Fragment>
                  }

                  {
                    (_.isEmpty(item.children) && !item.html) &&
                    <HFormInput label='' bind={item.bind} counter={false} maxLength={99999} component={isDetail ? Display : TextAreaInput} rows={_.get(item, 'rows', 4)} />
                  }

                </div>

              </div>
            })
          }
        </div>
      </Form>

      {
        !isAdd &&
        <div className='defect-detail-anchor flex-y'>
          <Anchor
            offsetTop={1}
            affix={true}
            onClick={e => e.preventDefault()}
            getContainer={() => mainContentRef.current}
          >
            {
              _.map(listForm, item => {
                return <Anchor.Link href={`#area-wrap-${item.bind}`} title={item.name} />
              })
            }
          </Anchor>
        </div>
      }
    </div>
  )
}

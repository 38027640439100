import {useMemo} from "react";
import {_cache} from "../../../utils/cache";
import _ from "lodash";

export default function useAuth(funcCode){
    return useMemo(()=>{
        if(_.isNil(funcCode)) return false
        const allFuncCode = _.map(_cache.get('allMenuList'), 'funcCode')
        return _.includes(allFuncCode, funcCode)
    },[funcCode])
}
import React from 'react'
import PublicTemplate from '../../common/publicPage/publicTemplate'

const LIST_CODE = '1802' //list
const ADD_PERMISSION = '180201' //创建复制权限
const EDIT_PERMISSION = '180202' //编辑权限
const DEL_PERMISSION = '180203' //删除权限

export default function TemplateMgt() {
  return <PublicTemplate {...{ LIST_CODE, ADD_PERMISSION, EDIT_PERMISSION, DEL_PERMISSION }} />
}

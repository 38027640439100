import React, {useMemo} from 'react';
import {DataGrid, Dialog} from "rootnet-ui";
import {Box} from "../../../../../../common/commonComponent";
import './VersionCreateBranchResultDialog.scss'
import {TextIconBtn} from "../../../../../../common/TextIconBtn";
import _ from "lodash";
import {downCsv, normalTransCsv} from "../../../../../../common/download";

const STATUS_COLOR = {
    0: '#00A950',
    1: 'red',
}

function getColumns(props){
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', stretch: false, width: 40 },
        { header: 'git项目', bind: 'gitUrl', width: 200, tooltip: true },
        { header: '状态', bind: 'status', width: 80, tooltip: true, stretch: false,
            convert: v => <div style={{color: _.get(STATUS_COLOR,v)}}>
                {v === 0 ? '成功': '失败'}
            </div>, csvConvert: v => v === 0 ? '成功': '失败' },
        { header: '原因', bind: 'reason', width: 200, tooltip: true },
    ]
}

function getOptions(columns){
    return {
        nilText: '-',
        emptyText: '-',
        resizable: true,
        autoFill: true,
        virtualized: true,
        columns,
    }
}

function VersionCreateBranchResultDialog(props) {
    const {list, close} = props

    const option = useMemo(()=>{
        return getOptions(getColumns({}))
    },[])

    const extra = useMemo(()=>{
        return <div className={'extra-wrap flex center-y'}>
            <TextIconBtn icon={'daochu'} text={'下载'} onClick={()=>downCsv(normalTransCsv(getColumns(), list), '返回结果')}/>
        </div>
    },[list])

    return <Dialog header={'返回结果'} className={'version-create-branch-result-dialog'} confirmButtonVisible={false}
                   cancelButtonText={'关闭'} cancel={close}>
        <Box className='flex-y x-card-singlegrid' title={'返回结果列表'} data={list} extra={extra}>
            <DataGrid className='x-card-singlegrid' data={list} option={option}/>
        </Box>
    </Dialog>
}

export default VersionCreateBranchResultDialog;
import React, { useCallback, useMemo, useEffect } from 'react';
import './DevProjectStageInfo.scss'
import { DatePicker, Display, Form, FormInput, Input, RadioGroup, Select } from "rootnet-edit";
import convertOptions from "../../../../../../common/ConvertOptions";
import { useGet } from "rootnet-biz/es/hooks";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../../../common/ConvertGlobalConstOptions";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import RichTextEditor from "../../../../../../common/richTextEditor/TinyEditor";
import TextAreaInput from "../../../../../../common/textAreaInput/TextAreaInput";

const YNOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

const HFormInput = props => <FormInput horizontal labelWidth={110} componentWidth={180} {...props} />

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=devSegment',
    // 'common/globalconst?globalConst=keyNodes',
    'common/globalconst?globalConst=projectRole',
]
// isParentTemplate ? Display : isDetail ? Display : Input
// (isParentTemplate || isTemplate) ? Display : isDetail ? Display : Input
function DevProjectStageInfo(props) {
    const { editFormData, setEditFormData, formError, setFormError, mode, isDetail, stageRichEditorRef, keyNodesOptions, isParentTemplate, isTemplate, isStatus } = props

    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

    const [devSegmentOptions, projectRoleOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const isEdit = useMemo(() => mode === 'edit', [mode])

    const handChange = useCallback((o, k) => {
        if (k === 'isParent') {
            o['manageRole'] = null
        }

        setEditFormData(o)
    }, [setEditFormData])

    useEffect(() => {
        if (isStatus) {
            setFormError({ cancelDescription: _.isNil(_.trim(_.get(editFormData, 'isParent'))) ? '必填项！' : null })
        } else {
            setFormError(x => _.assign({}, x, {
                manageRole: (_.get(editFormData, 'isParent') === 'N' && _.isNil(_.get(editFormData, 'manageRole'))) ? '必填项！' : null
            }))
        }
    }, [isStatus, editFormData, setFormError])

    return <div className={'dev-project-stage-info'}>
        <Form value={editFormData} onChange={handChange} error={formError} onError={setFormError}>
            {
                mode === 'add' &&
                <HFormInput label={'任务名称'} bind={'title'} component={isDetail ? Display : Input} required componentWidth={487} />
            }
            <HFormInput label={'阶段'} bind={'stage'} component={(isParentTemplate || isTemplate || isEdit || isStatus) ? Display : isDetail ? Display : Select}
                convert={v => convertOptions(v, devSegmentOptions)} options={devSegmentOptions} required={isParentTemplate ? false : true} />
            <HFormInput label={'关键节点'} bind={'keyNodes'} component={isDetail ? Display : Select} options={keyNodesOptions}
                required={isParentTemplate ? false : true} />
                {/* (isParentTemplate || isTemplate || isEdit || isStatus) ? Display : isDetail ? Display : Select */}
            {/* <HFormInput label={'负责人'} bind={'owner'} component={isDetail ? Display : UserSelect}
                convert={v => convertOptions(v, allUserRes, 'userName', 'userAccount')} required marginLeft /> */}
            <HFormInput label={'是否父级阶段'} bind={'isParent'} component={(isParentTemplate || isTemplate || isStatus) ? Display : isDetail ? Display : RadioGroup}
                options={YNOptions} convert={v => convertOptions(v, YNOptions)} />
            <HFormInput labelWidth={(isParentTemplate || isTemplate) ? 110 : isDetail ? 110 : 196} label={'里程碑'} bind={'milestoneFlag'} component={(isParentTemplate || isTemplate || isStatus) ? Display : isDetail ? Display : RadioGroup}
                options={YNOptions} convert={v => convertOptions(v, YNOptions)} />
            <HFormInput label='子任务' bind='sonTask' component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : Input} />
            {
                _.get(editFormData, 'isParent') === 'N' &&
                <HFormInput required={!isStatus} label='负责角色' bind='manageRole' options={projectRoleOptions} component={(isParentTemplate || isTemplate || isStatus) ? Display : isDetail ? Display : Select} convert={v => convertOptions(v, projectRoleOptions)} />
            }
            <HFormInput label={'计划开始时间'} bind={'startTimePlan'} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : DatePicker} required={(isParentTemplate || isStatus) ? false : true}
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD', v) || '-'
                }} />
            <HFormInput label={'计划结束时间'} bind={'completeTimePlan'} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : DatePicker} required={(isParentTemplate || isStatus) ? false : true}
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD', v) || '-'
                }} />
            <HFormInput label={'实际完成时间'} bind={'completeTimeActual'} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : DatePicker} clear
                bindOutConvert={v => dateFormat('YYYYMMDDHHMMSS', v)} bindInConvert={v => toDate.str14ToDate(v)}
                convert={v => {
                    if (_.isNil(v)) return '-'
                    if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
                    return dateFormat('YYYY-MM-DD', v) || '-'
                }} />
            <HFormInput label={'实际投入'} bind={'workloadActual'} convert={v => _.isNil(v) ? '-' : v}
                type={'number'} digit={2} min={0} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : Input} />
            <HFormInput label={'阶段质量审计'} bind={'isBaselineRelease'} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : RadioGroup}
                options={YNOptions} convert={v => convertOptions(v, YNOptions)} />
            <HFormInput label={'基线发布'} bind={'isStageQualityAudit'} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : RadioGroup}
                options={YNOptions} convert={v => convertOptions(v, YNOptions)} labelWidth={(isParentTemplate || isStatus) ? 110 : isDetail ? 110 : 196} />
            <HFormInput label={'配置库路径'} bind={'notesUrl'} component={(isParentTemplate || isStatus) ? Display : isDetail ? Display : Input} componentWidth={487} placeholder={'交付件配置库路径'} />
            <div className="rich-text-form-item flex">
                <div className="mock-label">交付物</div>
                <div className="rich-text-area" style={{ display: (isParentTemplate || isTemplate || isStatus) ? 'none' : isDetail ? 'none' : 'unset' }}>
                    <RichTextEditor ref={stageRichEditorRef} />
                </div>
                <div className="rich-text-value-show-wrap flex" style={{ display: (isParentTemplate || isTemplate || isStatus) ? 'flex' : isDetail ? 'flex' : 'none' }}>
                    {
                        _.isNil(_.get(editFormData, 'delivery')) || _.get(editFormData, 'delivery') === '' ?
                            <div></div>
                            : <div dangerouslySetInnerHTML={{ __html: _.get(editFormData, 'delivery') }} />
                    }
                </div>
            </div>
            {
                (isDetail || isParentTemplate) && <div className={'textarea-field-wrap flex'}>
                    <HFormInput label='裁剪/合并说明' componentWidth={0} component={Display} convert={() => ''} />
                    <div className={'textarea-show-value'} style={{ width: 487 }}>
                        {
                            _.get(editFormData, 'cancelDescription')
                        }
                    </div>
                </div>
            }
            {
                (isEdit && !isParentTemplate) && <HFormInput required={_.includes(['09', '10'], _.get(editFormData, 'status'))} className={'text-area-input-edit'} label='裁剪/合并说明' bind='cancelDescription'
                    component={TextAreaInput} componentWidth={487} />
            }
            {
                ((isDetail && isParentTemplate) ? true : isDetail ? true : false) && <div className={'textarea-field-wrap flex'}>
                    <HFormInput label='备注' componentWidth={0} component={Display} convert={() => ''} />
                    <div className={'textarea-show-value'} style={{ width: 487 }}>
                        {
                            _.get(editFormData, 'memo')
                        }
                    </div>
                </div>
            }
            {
                ((!isDetail && isParentTemplate) ? true : isDetail ? false : true) && <HFormInput className={'text-area-input-edit'} label='备注' bind='memo'
                    component={TextAreaInput} componentWidth={487} />
            }
        </Form>
    </div>
}

export default DevProjectStageInfo;
import React, {useCallback, useMemo, useState} from 'react';
import _ from "lodash";
import convertTableAlign from "../../../common/view/convertTableAlign";
import findConvert from "../../../common/view/findConvert";
import useGetViewConfig from "../../../common/view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../../common/view/hooks/useRefreshList";
import useGetDateOptions from "../../../common/view/hooks/useGetDateOptions";
import useGetTreeList from "../../../common/view/hooks/useGetTreeList";
import ViewArea from "../../../common/view/ViewArea";
import {Box} from "../../../common/commonComponent";
import {DataGrid, Pagination} from "rootnet-ui";
import './ChildrenReqViewTab.scss'
import {TextIconBtn} from "../../../common/TextIconBtn";
import RequirementAddDialog from "../../requirementAddDialog/RequirementAddDialog";
import {REQUIREMENT_PRIORITY_COLOR} from "../../requirementColor";
import clsx from "clsx";
import Icon from "../../../../components/Icon";
import {Popover} from "antd";
import DelMessage from "../../../../components/DelMessage";
import RequirementCopyDialog from "../../requirementCopyDialog/RequirementCopyDialog";
import ApplyJoinVersionDialog from "../../applyJoinVersionDialog/ApplyJoinVersionDialog";
import RequirementDetailDialog from "../RequirementDetailDialog";
import WorkFlowChangePop from "../../../workFlow/workFlowChangePop/WorkFlowChangePop";

const ENTER_DETAIL_FIELD = 'story.title'

const SPECIAL_FIELD_CONVERT = {
    'story.priority': (value, showValue) => {
        return <div  style={{background: REQUIREMENT_PRIORITY_COLOR[value], padding: '0px 12px', color: '#fff', borderRadius: 4}}>
            {showValue}
        </div>
    },
}

function getColumns(props) {
    const { fieldList, convertCollection, dateOptions, handlePopId, setHandlePopId, setDelId, setCopyInfo, delAuth,
        setJoinVersionId, canAddChildReq, setShowDetailDialog, afterRefresh, workFlowId, setWorkFlowId } = props

    const customColumns = []

    return [
        { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
    ].concat(_.map(fieldList, x => handleColumn(x)))

    function handleColumn(fieldItem) {
        if (_.get(fieldItem, 'custom') === 'Y') {
            // 自定义字段
            return {
                header: fieldItem.fieldName,
                ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
                align: convertTableAlign(fieldItem.alignment),
                width: _.toNumber(fieldItem.columnWidth) || 120,
            } || { header: '', bind: '', width: 100 }
        }else{
            return {
                header: fieldItem.fieldName,
                bind: fieldItem.columnId,
                width: _.toNumber(fieldItem.columnWidth) || 120,
                align: convertTableAlign(fieldItem.alignment),
                tooltip: true,
                _custom: fieldItem.custom,
                convert: (v, o) => convertEditField(v, o, fieldItem)
            }
        }
    }

    function convertEditField(v, o, fieldItem){
        let showValue = findConvert(fieldItem,v,convertCollection, dateOptions)
        const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
        if(!_.isNil(SPECIAL_FIELD_CONVERT[tableField])){
            showValue =  SPECIAL_FIELD_CONVERT[tableField](v, showValue)
        }
        if(tableField === ENTER_DETAIL_FIELD){
            return <div className={'title-wrap flex center-y space-between'}>
                <div className="title" onClick={()=>{
                    setShowDetailDialog({
                        module: 'req',
                        id: _.get(o, 'id')
                    })
                }}>{v}</div>
                <Popover  placement={'bottom'} destroyTooltipOnHide={true} open={_.get(o,'id') === handlePopId}
                          onOpenChange={visible => {
                              if(visible){
                                  setHandlePopId(_.get(o,'id'))
                              }else{
                                  setHandlePopId(null)
                              }
                          }}
                          trigger={'click'}
                          content={<div className={'children-requirement-handle-popover flex-y'}>
                              <div className={clsx('requirement-handle-item flex center-y', {able: canAddChildReq, disabled: !canAddChildReq})} onClick={()=> {
                                  if(canAddChildReq){
                                      setCopyInfo({
                                          id: _.get(o, 'id'),
                                          title: v,
                                          isParent: false
                                      })
                                      setHandlePopId(null)
                                  }
                              }}>
                                  <Icon name={'wendang'} className={'handle-icon'}/>
                                  <div className="handle-text">复制</div>
                              </div>
                              <div className="requirement-handle-item flex center-y" onClick={()=>{
                                  setJoinVersionId(o.id)
                                  setHandlePopId(null)
                              }}>
                                  <Icon name={'xinjianchanpinchi'} className={'handle-icon'}/>
                                  <div className="handle-text">申请加入已封板的版本</div>
                              </div>
                              {
                                  delAuth &&
                                  <div className="requirement-handle-item flex center-y del-item" onClick={()=>{
                                      setDelId(o.id)
                                      setHandlePopId(null)
                                  }}>
                                      <Icon name={'shanchu'} className={'handle-icon'}/>
                                      <div className="handle-text">删除</div>
                                  </div>
                              }
                          </div>}>
                    <span>
                        <Icon name={'gengduo'} className={'more-handle-icon'}/>
                    </span>
                </Popover>
            </div>
        }
        if(tableField === 'workflow_business.factorId'){
            const options = _.get(convertCollection,tableField)
            const workflowColor = _.get(_.find(options, x => x.value === v),'color') || '#000'
            return <Popover destroyTooltipOnHide={true}
                            content={<WorkFlowChangePop businessId={workFlowId} close={()=>setWorkFlowId(null)} refreshList={afterRefresh} funcCode={'1501'} businessType={'req'}/>}
                            onOpenChange={visible => {
                                if(!visible){
                                    setWorkFlowId(null)
                                }
                            }}
                            trigger={'click'}
                            placement="bottom"
                            open={o.id === workFlowId}
            >
                <div className={'common-display-field work-flow-status-field cursor-pointer'} onClick={()=>setWorkFlowId(o.id)}
                     style={{color: workflowColor, border: `1px solid ${workflowColor}`,padding: '0 8px', borderRadius: 4}}>
                    {showValue || '-'}
                </div>
            </Popover>
        }
        return <div className={'common-display-field'}>
            {showValue || '-'}
        </div>
    }
}

const getOptions = (options) => ({
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: options,
    virtualized: true,
    autoFill: true,
});

function getInitParams() {
    return {
        pageNum: 1,
        pageSize: 20
    }
}

const FUNC_CODE = '150101'

function ChildrenReqViewTab(props) {
    const {id, setCurrentInfo, delAuth, refreshViewList,formData,setHasChildrenReq,canAddChildReq, refreshDetail} = props
    const replaceParams = useMemo(()=>({'story.pId':id}),[id])
    const [params, setParams] = useState()
    const [pageSize, setPageSize] = useState(20)
    const [isLoading, setIsLoading] = useState(true)
    const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
    const [currentViewId, setCurrentViewId] = useState()
    const { data: listRes, doFetch: getList, loading, error } = usePost()
    const {total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

    const [showAdd, setShowAdd] = useState(false)
    const [copyInfo, setCopyInfo] = useState()
    const [handlePopId, setHandlePopId] = useState()
    const [delId, setDelId] = useState()
    const [joinVersionId, setJoinVersionId] = useState()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [workFlowId, setWorkFlowId] = useState()

    const boxLoading = useMemo(() => {
        return isLoading || loading
    }, [isLoading, loading])

    const afterViewRefresh = useCallback((res)=>{
        setHasChildrenReq(res.rows.length !== 0)
    },[setHasChildrenReq])

    const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, afterRefresh: afterViewRefresh})

    const dateOptions = useGetDateOptions()

    const afterRefresh = useCallback(()=>{
        refreshViewList()
        refreshList(replaceParams)
        refreshDetail(id)
    },[refreshViewList,refreshList,refreshDetail, id, replaceParams])

    const { options, dataGridList } = useGetTreeList({ fieldList, list, convertCollection, dateOptions, getOptions,
        getColumns, columnsAppendParams: {handlePopId, setHandlePopId, setDelId, setCopyInfo, delAuth, setJoinVersionId,
            canAddChildReq, setShowDetailDialog, afterRefresh, workFlowId, setWorkFlowId} })

    const extra = useMemo(() => {
        return <div className='extra-group flex center-y'>
            <TextIconBtn icon={'tianjia'} text={'添加子需求'} disabled={!canAddChildReq} onClick={()=>setShowAdd(true)}/>
        </div>
    }, [canAddChildReq])

    return <div className={'children-req-view-tab fill flex-y'}>
        <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
                  {...{getFieldList,allColumns,refreshList,total,getInitParams,optionsConfig,
                      currentViewId, setCurrentViewId, params, pageSize, replaceParams}}/>
        <Box title={'子需求清单'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
            <DataGrid option={options} data={dataGridList} />
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => {
                            setPageSize(pageSize)
                            setParams(x => _.assign({}, x, {pageNum, pageSize}))
                        }} />
        </Box>
        {
            showAdd &&
            <RequirementAddDialog afterRefresh={afterRefresh} close={()=>setShowAdd(false)}
                                  currentInfo={{pid: id, parentTitle: _.get(formData,'title')}}/>
        }
        {
            !_.isNil(delId) &&
            <DelMessage close={()=>setDelId(null)} refresh={afterRefresh} method={'get'} url={'/story/deleteStory?id='+delId}>
                是否删除该需求？
            </DelMessage>
        }
        {
            !_.isNil(copyInfo) &&
            <RequirementCopyDialog close={()=>setCopyInfo(null)} {...{copyInfo, refreshList,setCurrentInfo}}/>
        }
        {
            joinVersionId &&
            <ApplyJoinVersionDialog id={joinVersionId} close={()=>setJoinVersionId(null)} type={'REQ'}/>
        }
        {
            _.get(showDetailDialog,'module') === 'req' &&
            <RequirementDetailDialog close={()=>setShowDetailDialog(null)} currentInfo={{
                id: _.get(showDetailDialog,'id')
            }}/>
        }
    </div>
}

export default ChildrenReqViewTab;
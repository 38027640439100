export class Exception {

    static SYSTEM = "SYSTEM";
    static SCRIPT = "SCRIPT";
    static API = "API";

    constructor(type, code, message, url = "", warning = false) {
        this._type = type;
        this._code = code;
        this._message = message;
        this._url = url;
        this._warning = warning;
    }

    get Type() {
        return this._type;
    }

    get Warning() {
        return this._warning;
    }

    get Url() {
        return this._url;
    }

    get Code() {
        return this._code;
    }

    get Message() {
        return this._message;
    }

    toString() {
        return `[${this._code}] ${this._message}`;
    }

    static NOT_LOGIN = new Exception(Exception.SYSTEM, -10, "没有有效的用户信息。", "", true);
    static SESSION_EXPIRED = new Exception(Exception.SYSTEM, 2007, "长时间未操作，请重新登录。", "", true);
}
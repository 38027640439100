import React from "react";
import _ from "lodash";
import { Dateformat, NF,DataChange } from "rootnet-core/format";
import './MilestoneCardCore.scss';

// 20201020000000 || 20201002  =>  1587460114536
function GetSeconds(str){
    if(_.isNil(str)) return str;
    str = str.toString();
    let year = str.substr(0, 4);
    let month = str.substr(4, 6);
    let day = str.substr(6, 8);
    return new Date(year, month, day).getTime();
}

// 里程碑【结束时间】的颜色，
// 项目状态为关闭，则显示为绿色  status 1
// 项目状态不为关闭，且结束时间小于当前系统时间，则显示为红色
// 项目状态不为关闭，且结束时间大于当前系统时间，则显示为灰色。
function getEndClass(product) {
    let nowTime = new Date();
    let { status, completeDate } = product;
    nowTime = DataChange(nowTime);
    if (status === '1') return 'green';
    if (completeDate > nowTime) return 'red';
    return ''
}

// 处理进度 和 颜色
// 进度  (里程碑时间-开始时间) / (项目结束时间-项目开始时间)
// 完成状态为Close-完成，计划时间小于实际完成时 红色， 否则 绿色
// 完成状态为Open-进行中，计划时间小于当前时间 红色
function dataProcess(product, data) {
    let { beginDate, completeDate } = product;
    let _nowTime = Date.parse(new Date());
    let _beginDate = GetSeconds(beginDate);
    let _completeDate = GetSeconds(completeDate);
    return _.map(data, item => {
        let _deadlineTime = GetSeconds(item.deadlineTime);
        item.process = (_deadlineTime - _beginDate) / (_completeDate - _beginDate);
        item.process = NF(item.process, 'N4');
        if (item.status === 'Open' && _beginDate < _nowTime) item.class = "red"
        if (item.status === 'Close') {
            if (_beginDate < _completeDate) {
                item.class = "red"
            } else {
                item.class = "green"
            }
        }
        return item;
    })
}

//里程碑--mengzewei
export function MilestoneCardCore(props) {
    let { product, data} = props;
    product = product || {};
    data = _.reverse(dataProcess(product, data));
    return <div className="milestone-card-core">
        <div className="line">

            <div className={`end item ${getEndClass(product)}`} style={{ width: '100%' }}>
                <div className="time">{Dateformat(product.completeDate)}</div>
                <div className="name">结束时间</div>
            </div>

            {
                _.map(data, (item, index) => {
                    return <div className={`item ${item.class}`} key={index} style={{ width: NF(item.process, 'P2') }}>
                        <div className="time">{Dateformat(item.deadlineTime)}</div>
                        <div className="name" title={item.mileStoneType}>{item.mileStoneType}</div>
                    </div>
                })
            }

            <div className="start item green">
                <div className="time">{Dateformat(product.beginDate)}</div>
                <div className="name">开始时间</div>
            </div>
        </div>
    </div>
}
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tab, TabItem } from 'rootnet-ui'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { FormInput, Select } from 'rootnet-edit'
import { ValueContext } from '../../../common/Context'
import PlanUseCases from './planUseCases'
import PlanDefects from './planDefects'
import { selectOption } from '../../../common/commonMethod'
import useGetViewConfig from "../../../common/view/hooks/useGetViewConfig"
import ChangeRecord from '../../../common/ChangeRecord'
import { TextIconBtn } from '../../../common/TextIconBtn'
import DetailDrawer from './detailDrawer'
import ArrowCrumbs from '../../../common/ArrowCrumbs'
// import { isNil } from 'rootnet-core/format'
import './testPlanDetail.scss'
import { isNil } from '../../../appraise/components/method'

// const LIST_URL = '/test/plan/list'
const ALL_LIST_URL = '/test/plan/testplaninfo'
const DETAILS_URL = '/test/plan/selectTestPlanInformation'

export default function TestPlanDetail(props) {
  const { detail, setDetail, data } = props
  const { planId } = detail || {}
  const [params, setParams] = useState({})
  const [openDetail, setOpenDetail] = useState(false)
  const { data: testPlanDetailsRes, loading, error, doFetch } = usePost()
  const { data: allList, doFetch: allFetch, loading: allLoading } = useGet()
  const [isLoading, setIsLoading] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig('050301', setIsLoading)
  const { testPlan: basicInformationData, testPlanDetailsStatistical: overallSituationData } = allList || {}
  const [activeTabKey, setActiveTabKey] = useState('用例')
  const [casePageNum, setCasePageNum] = useState(1)
  const [casePageSize, setCasePageSize] = useState(20)

  const testPlanOpt = useMemo(() => selectOption(data, ['planName', 'id']), [data])

  const refresh = useCallback((parameter = {}) => {
    const par = _.isEmpty(parameter) ? params : parameter
    const postParams = _.assign({}, par, { planId, pageNo: casePageNum, pageSize: casePageSize }, !isNil(_.get(parameter, 'pageNo')) && { pageNo: _.get(parameter, 'pageNo') })
    doFetch(DETAILS_URL, postParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFetch, planId, casePageSize, casePageNum])

  const { lists: testPlanDetails, total: casePageTotal } = useMemo(() => testPlanDetailsRes || {}, [testPlanDetailsRes])

  const allRefresh = useCallback(() => {
    allFetch(`${ALL_LIST_URL}?planId=${planId}`)
  }, [allFetch, planId])

  const goBack = useMemo(() => {
    return [
      { text: '测试计划' },
      {
        text: <FormInput tree search horizontal
          options={testPlanOpt}
          component={Select}
          value={planId}
          onChange={v => { setDetail(x => _.assign({}, x, { planId: v })) }}
        />,
        active: true
      },
    ]
  }, [planId, setDetail, testPlanOpt])

  useEffect(() => {
    refresh()
    allRefresh()
  }, [allRefresh, refresh])

  const contextValue = {
    planId,
    loading,
    error,
    basicInformationData,
    overallSituationData,
    testPlanDetails,
    setParams,
    params,
    refresh,
    allRefresh,
    isLoading,
    allLoading,
    openDetail,
    setOpenDetail,
    funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection,
  }

  return (
    <ValueContext.Provider value={contextValue}>
      <div className='testPlanDetail'>

        <div className='header' >
          <TextIconBtn style={{ color: '#5477ff' }} icon='chaxuntongji' text='基本详情' onClick={() => setOpenDetail(true)} />
        </div>

        {/* 内容 */}
        <div className='content'>

          {/* 用例 缺陷 变更记录 */}
          <Tab
            onChange={(index, config) => setActiveTabKey(config.header)}
            extra={<ArrowCrumbs data={goBack} goBack={() => setDetail(false)} />}
          >
            <TabItem header='用例'>
              <PlanUseCases {...{ casePageNum, setCasePageNum, casePageSize, setCasePageSize, casePageTotal }} />
            </TabItem>
            <TabItem header='缺陷' >
              <PlanDefects activeTabKey={activeTabKey} />
            </TabItem>
            <TabItem header='动态'>
              {
                activeTabKey === '动态' &&
                <ChangeRecord
                  className='plan-record fill'
                  name='测试计划'
                  funcCode={'0503'}
                  referenceId={planId}
                  id={_.get(basicInformationData, 'planId')}
                  title={_.get(basicInformationData, 'planName')}
                  linkUrl={`/testPlanMgt?planId=${planId}`}
                />
              }
            </TabItem>
          </Tab>

        </div>

      </div>
      {/* 基本详情 */}
      <DetailDrawer />
    </ValueContext.Provider>
  )
}

// function strParams(params) {
//   if (!_.isObject(params)) return '';
//   const paramsArray = [];
//   _.mapKeys(params, (value, key) => {
//     !isNil(value) && paramsArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
//   });
//   return paramsArray.join('&')
// }
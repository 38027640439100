import React, {useCallback, useEffect, useMemo} from 'react';
import {Card} from 'rootnet-ui';
import {Form, FormInput, Select, RadioGroup} from "rootnet-edit";
import './FuncDefinitionCard.scss'
import _ from "lodash";
import {useGet} from "rootnet-biz/es/hooks";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";

const YNOptions =[
    { text: '是', value: 'Y' },
    { text: '否', value: 'N' },
]

const HFormInput = (props) => <FormInput horizontal labelWidth={120} componentWidth={150} {...props}/>

function FuncDefinitionCard(props) {
    const {tableRes,funcDefinition, setFuncDefinition, funcDefinitionError, setFuncDefinitionError} = props
    const {data: resultTypeRes} = useGet('/common/globalconst?globalConst=ResultType')

    const resultTypeOptions = useMemo(()=>{
        return convertGlobalConstOptions(resultTypeRes)
    },[resultTypeRes])

    const tableOptions = useMemo(()=>{
        return _.map(tableRes, x => ({value: x.tableName, text: x.tableName}))
    },[tableRes])

    useEffect(()=>{
        setFuncDefinition(funcDefinition)
    },[funcDefinition, setFuncDefinition])

    const getRelationOptions = useCallback((tableName)=>{
        if(_.isEmpty(tableRes) || _.isNil(tableName)) return []
        const fieldGroup = _.get(_.find(tableRes, x => x.tableName === tableName),'fields')
        if(!_.isNil(_.get(funcDefinition,'primaryKey')) && _.isNil(_.find(fieldGroup, x => x.fieldId === _.get(funcDefinition,'primaryKey')))){
            setFuncDefinition(x => ({...x, primaryKey: null}))
        }
        return _.map(fieldGroup, x => ({value: x.fieldId, text: x.fieldId}))
    },[tableRes, funcDefinition,setFuncDefinition])

    const {style} = props
    return <Card title={'功能定义'} className={'func-definition-card-wrap'} contentClass={'flex-y func-definition-card'} style={style}>
        <Form value={funcDefinition} onChange={setFuncDefinition} error={funcDefinitionError} onError={setFuncDefinitionError}>
            <HFormInput bind={'funcCode'} label={'菜单功能编号'} required/>
            <HFormInput bind={'funcName'} label={'菜单功能名称'} required/>
            <HFormInput bind={'primaryTable'} label={'主表'} component={Select} required search options={tableOptions}/>
            <HFormInput bind={'primaryKey'} label={'主键'} component={Select} required search options={getRelationOptions(funcDefinition['primaryTable'])}/>
            <HFormInput bind={'resultType'} label={'结果集类型'} component={Select} required options={resultTypeOptions}/>
            <HFormInput bind={'isRecordQuery'} label={'是否记录查询条件'} component={RadioGroup} options={YNOptions}/>
        </Form>
    </Card>
}

export default FuncDefinitionCard;
import React, { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { Form, FormInput, Input, Select } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import './index.scss'

const ADD_URL = '/humanSpecialProject/add'

export default function ProjectMaintenanceAddLog(props) {
  const { close, projectTypeOpt, refresh, refreshAffair } = props
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const [continuous, setContinuous] = useState(false)
  const { doFetch } = usePost()

  const canSubmit = useMemo(() => {
    return _.some(_.values(error), x => x)
  }, [error])

  const confirm = useCallback(() => {
    if (continuous) return
    setContinuous(true)
    doFetch(ADD_URL, params)
      .then(() => {
        refreshAffair()
        refresh()
        setContinuous(false)
        close()
        Messager.show('新增成功', { icon: 'success' })
      })
      .catch(err => {
        setContinuous(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, close, refresh, params, refreshAffair, continuous])

  return (
    <Dialog
      header='新增'
      cancel={close}
      className='project-maintenance-add-log'
      confirmButtonDisabled={canSubmit}
      confirm={confirm}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput required horizontal label='项目名称' bind='projectName' component={Input} />
        <FormInput required horizontal label='项目类型' bind='projectType' component={Select} options={projectTypeOpt} />
      </Form>
    </Dialog>
  )
}

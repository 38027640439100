import React, { useMemo, useReducer, useState, useCallback, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { DataGrid, Messager, Pagination } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { API1 } from '../../base/task'
import {
  REQUIREMENT_PRIORITY_COLOR,
  REQUIREMENT_STATUS_COLOR,
  REQUIREMENT_CN_STATUS_COLOR,
} from '../requirementMgt/requirementColor'
import { pathSearchFor } from '../../utils/publicFun'
import useGetViewConfig from '../common/view/hooks/useGetViewConfig'
import convertGlobalConstOptions from '../common/ConvertGlobalConstOptions'
import convertOptions from '../common/ConvertOptions'
import useGetIdList from '../common/view/hooks/useGetIdList'
import useGetAppendData from '../common/view/hooks/useGetAppendData'
import useRefreshList from '../common/view/hooks/useRefreshList'
import useGetDateOptions from '../common/view/hooks/useGetDateOptions'
import { useFuncCode } from '../common/commonMethod'
import useGetTreeList from '../common/view/hooks/useGetTreeList'
import { TextIconBtn } from '../common/TextIconBtn'
import ViewArea from '../common/view/ViewArea'
import { Box } from '../common/commonComponent'
import convertTableAlign from '../common/view/convertTableAlign'
import findConvert from '../common/view/findConvert'
import { Icon } from '../../components'
import clsx from 'clsx'
import WorkFlowChangePop from '../workFlow/workFlowChangePop/WorkFlowChangePop'
import { CustomizeFormInput } from '../common/customizeOptions/CustomizeOptions'
import './index.scss'
import DevListAddLog from './devListAddLog'
import DelMessage from '../../components/DelMessage'
import DevListCopyDialog from './devListCopyDialog'
import ApplyJoinVersionDialog from '../requirementMgt/applyJoinVersionDialog/ApplyJoinVersionDialog'
import DevListDetailDialog from './devListDetailDialog'

const DEL_URL = '/story/deleteStory'

const PRIMARY_KEY = 'storyId'
const ENTER_DETAIL_FIELD = 'story.title'
const EDITABLE_FIELD = [
  'story.priority',
  'workflow_business.currentUser'
]

const REPLACE_POST_PARAMS = {}

const SPECIAL_FIELD_CONVERT = {
  'story.status': (value, showValue) => {
    return <div style={{ color: REQUIREMENT_STATUS_COLOR[value], border: `1px solid ${REQUIREMENT_STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'story.priority': (value, showValue) => {
    return <div style={{ background: REQUIREMENT_PRIORITY_COLOR[value], padding: '0px 12px', color: '#fff', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, delAuth, setJoinVersionId,
    updateShowList, treeStageOptions, setEditOldValue, workFlowId, setWorkFlowId, refreshList, setDelInfo, handlePopId, setHandlePopId, addChildDone, getCanCopy } = props
  const { editId = null, editField = null } = editInfo || {}

  const customColumns = [
    {
      fieldId: 'statusExt', bind: 'addRes1', convert: v => {
        return <div className={'common-display-field flex center-y'}>
          <div style={{ height: 8, width: 8, marginRight: 8, borderRadius: '50%', background: REQUIREMENT_CN_STATUS_COLOR[v] }} />
          {v}
        </div>
      }
    },
    {
      fieldId: 'releaseId', bind: 'list', convert: v => {
        return <div className={'common-display-field flex center-y'}>
          {_.join(v, ', ')}
        </div>
      }, tooltip: true
    },
  ]
  return _.concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: `${fieldItem.tableName}.${fieldItem.fieldId}` !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[tableField])) {
      showValue = SPECIAL_FIELD_CONVERT[tableField](v, showValue)
    }

    if (tableField === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
        <Popover open={_.get(o, 'id') === handlePopId && addChildDone} onOpenChange={visible => {
          if (visible) {
            setHandlePopId(_.get(o, 'id'))
            if (o.pid) {
              getCanCopy(o.pid)
            }
          } else {
            setHandlePopId(null)
          }
        }}
          trigger={'click'}
          content={<div className={'dev-handle-popover flex-y'}>
            {/*<div className={clsx('dev-handle-item flex center-y', { 'able': _.isNil(o.pid) || canCopy, 'disabled': !(_.isNil(o.pid) || canCopy) })} onClick={() => {*/}
            {/*  if (_.isNil(o.pid) || canCopy) {*/}
            {/*    setCopyInfo({*/}
            {/*      id: _.get(o, 'id'),*/}
            {/*      title: showValue,*/}
            {/*    })*/}
            {/*    setHandlePopId(null)*/}
            {/*  }*/}
            {/*}}>*/}
            {/*  <Icon name={'wendang'} className={'handle-icon'} />*/}
            {/*  <div className="handle-text">复制</div>*/}
            {/*</div>*/}
            <div className={clsx('dev-handle-item flex center-y', { 'able': false, 'disabled': false })} onClick={() => {
              setJoinVersionId(o.id)
              setHandlePopId(null)
            }}>
              <Icon name={'xinjianchanpinchi'} className={'handle-icon'} />
              <div className="handle-text">申请加入已封板的版本</div>
            </div>
            {
              delAuth &&
              <div className="dev-handle-item flex center-y del-item" onClick={() => {
                setDelInfo({
                  id: _.get(o, 'id'),
                  isParent: _.isNil(_.get(o, 'pid'))
                })
                setHandlePopId(null)
              }}>
                <Icon name={'shanchu'} className={'handle-icon'} />
                <div className="handle-text">删除</div>
              </div>
            }
          </div>} placement={'bottom'} destroyTooltipOnHide={true}>
          <span>
            <Icon name={'gengduo'} className={'more-handle-icon'} />
          </span>
        </Popover>
      </div>
    }

    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <Popover
        destroyTooltipOnHide={true}
        content={
          <WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)}
            refreshList={refreshList}
            funcCode={'1501'}
            businessType={'dev'}
          />
        }
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (!_.includes(EDITABLE_FIELD, tableField)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (o?.id === editId && editField === tableField) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection}
        defaultValue={handleValue}
        clear={fieldItem.requiredFlag === 'N'}
        allowClear={fieldItem.requiredFlag === 'N'}
        defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType} customOptions={tableField === 'story.status' ? treeStageOptions : null}
        onFocus={() => {
          currentTableValueRef.current = handleValue
          setEditOldValue(handleValue)
        }} tree={tableField === 'story.status'}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === ENTER_DETAIL_FIELD), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100
  }
}

const OPTIONS_URLS = [
  'common/globalconst?globalConst=ReqStatus',
]

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=storyType',
]

const FUNC_CODE = '1512'

export default function DevListMgt(props) {
  const { location } = props;
  const { customer: customerCS = null, CSRecordID, relateCSAttach, cleanAll, isCS } = window.external
  const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const replaceAllParams = useMemo(() => {
    if (!!customerCS) return ({ 'story.customer': customerCS })
    return null
  }, [customerCS])

  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(100)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
  const [focusId, setFocusId] = useState()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [sortConfig, setSortConfig] = useState()
  const [factor, setFactor] = useState()
  const [initType] = useState('DEV')
  const [workFlowId, setWorkFlowId] = useState()
  const [copyInfo, setCopyInfo] = useState()
  const [delInfo, setDelInfo] = useState()
  const [handlePopId, setHandlePopId] = useState()
  const [joinVersionId, setJoinVersionId] = useState()
  const { doFetch: authDoFetch } = useGet()
  const [canCopy, setCanCopy] = useState(false)
  const showAllNodesRef = useRef(true)

  const getCanCopy = useCallback((pid) => {
    authDoFetch('/story/getContainStory?id=' + pid).then(res => {
      setCanCopy(res)
    })
  }, [authDoFetch])

  const [storyTypeOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  useEffect(() => {
    if (_.isNil(CSRecordID)) return
    setCurrentInfo({
      mode: 'add',
      CSRecordID,
      relateCSAttach
    })
  }, [CSRecordID, relateCSAttach])

  // 表格编辑
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()
  const [editOldValue, setEditOldValue] = useState()

  const [stageOptions, treeStageOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes || {}
    const treeStageOptions = _.map(_.groupBy(d1, x => x.memo), (stateList, stageText) => {
      return ({
        text: stageText,
        value: stageText,
        _disabled: true,
        children: _.map(stateList, x => ({ value: x.interiorId, text: x.displayName }))
      })
    })
    return [
      _.map(d1, x => ({ value: x.interiorId, text: x.memo })),
      treeStageOptions
    ]
  }, [optionsRes])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    if (JSON.stringify(updateValue) === JSON.stringify(editOldValue)) {
      setEditInfo(null)
      currentTableValueRef.current = null
      return
    }
    let postParams = {
      // defectId: primaryId,
      ids: [editInfo['editId']]
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    if (editInfo['fieldId'] === 'currentUser') {
      if (isNil(updateValue)) {
        setEditInfo(null)
        currentTableValueRef.current = null
        return
      }
      const currentUserParams = {
        businessId: _.get(editInfo, 'editId'),
        businessType: 'dev',
        funcCode: '1501',
        currentUser: updateValue,
        title: _.get(editInfo, 'title')
      }
      editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    } else {
      editTable('/story/updateSingle', postParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    }

    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
            if (editInfo.editField === 'story.status') {
              item['addRes1'] = convertOptions(updateValue, stageOptions)
            }
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable, editOldValue, stageOptions])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const rootnetSelect = document.querySelector('.dev-data-grid').querySelector('.rootnet-select')
    if (rootnetSelect) {
      rootnetSelect.addEventListener('mousewheel', e => {
        e.cancelBubble = true
      })
    }
  }, [editInfo])

  // C端列表默认查询

  const idList = useGetIdList(list)
  const appendData = useGetAppendData(idList, '/story/viewInterface')

  const appendList = useMemo(() => {
    const appendFieldList = _.map(appendData, x => _.pick(x, ['addRes1', 'list']))
    _.forEach(appendFieldList, item => {
      _.forEach(item, (value, key) => {
        if (key === 'addRes1') {
          item[key] = convertOptions(value, stageOptions)
        } else {
          item[key] = value
        }
      })
    })
    // 数字类型字段排序
    let decimalList = list
    _.forEach(fieldList, fieldItem => {
      if (fieldItem['fieldDbType'] === "DECIMAL") {
        _.forEach(decimalList, item => {
          item[fieldItem.columnId] = _.toNumber(item[fieldItem.columnId])
        })
      }
    })
    return _.map(decimalList, (x, i) => _.assign(x, appendFieldList[i]))
  }, [list, appendData, fieldList, stageOptions])

  useEffect(() => {
    setShowDataGridList(appendList)
  }, [appendList])

  const refreshList = useRefreshList({
    currentViewId, params, allColumns, getList, setParams, funcCode,
    getFactor: setFactor, showAllNodes: showAllNodesRef.current ? 'Y' : 'N'
  })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const [allDetailAuth, delAuth] = useFuncCode(['1507', '1503'])


  const { options, dataGridList } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection,
    dateOptions, getOptions, getColumns, sortable: true,
    columnsAppendParams: {
      setCurrentInfo, editInfo, setEditInfo, currentTableValueRef, updateShowList, delAuth, setJoinVersionId, treeStageOptions, setEditOldValue, workFlowId, setWorkFlowId, refreshList, setCopyInfo, setDelInfo, handlePopId, setHandlePopId,
      canCopy, getCanCopy
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        if (_.isEmpty(data)) return []
        setSortConfig(sort)
        const sortAllList = data.sort((a, b) => sortFun(a, b, sort.column))
        const sortAllDirectionList = sort.direction === 'asc' ? sortAllList : _.reverse(_.clone(sortAllList))
        const sortFirstLevelDirectionList = _.filter(sortAllDirectionList, x => _.isNil(_.get(x, 'pid')))
        let handleList = []
        _.forEach(sortFirstLevelDirectionList, item => {
          const childrenList = _.filter(sortAllDirectionList, x => _.get(x, 'pid') === item.id)
          handleList.push(item)
          _.forEach(childrenList, x => {
            handleList.push(x)
          })
        })
        return handleList
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const handleList = useMemo(() => {
    let allList
    if (_.isNil(sortConfig)) {
      allList = showChildList || []
    } else {
      const sortList = _.sortBy(showChildList || [], x => x[sortConfig.column])
      allList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    }
    return allList
  }, [showChildList, sortConfig])

  const showList = useMemo(() => {
    return handleList
  }, [handleList])

  useEffect(() => {
    if (_.isNil(focusId)) return
    _.forEach(showList, o => {
      return o._rowClass = o?.id === focusId ? 'select_row' : ''
    })
    forceUpdate()
  }, [focusId, showList])

  const onRowClick = useCallback((item) => {
    setFocusId(item.id)
  }, [])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor
    }
    API1.downloadPost('/UserSetting/exportExcel', postParams)
  }, [currentViewId, fieldList, factor])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      {
        !isCS &&
        <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
      }
      {/*{*/}
      {/*  addAuth &&*/}
      {/*  <TextIconBtn text='新增' icon='tianjia' onClick={() => {*/}
      {/*    setInitType('DEV')*/}
      {/*    setCurrentInfo({ mode: 'add' })*/}
      {/*  }} />*/}
      {/*}*/}
    </div>
  }, [exportViewList, isCS])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  return (
    <div className={'dev-list-mgt fill flex-y'}>
      <ViewArea
        allOptions={optionsConfig}
        search={setParams}
        resetSearch={cleanAll}
        loading={boxLoading}
        funcCode={FUNC_CODE}
        {...{
          getFieldList, allColumns, replaceAllParams, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize,
        }} />
      <Box
        data={list}
        extra={extra}
        error={error}
        title={'开发自提单'}
        loading={boxLoading}
        className='flex-y x-card-singlegrid'
      >
        <DataGrid
          option={options} data={showList} onRowClick={onRowClick} className={'dev-data-grid'} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }}
        />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'add' &&
        <DevListAddLog
          afterRefresh={refreshList}
          close={() => setCurrentInfo(null)}
          {...{ currentInfo, initType }}
        />
      }
      {
        joinVersionId &&
        <ApplyJoinVersionDialog
          type={'REQ'}
          id={joinVersionId}
          close={() => setJoinVersionId(null)}
        />
      }
      {
        !_.isNil(copyInfo) &&
        <DevListCopyDialog
          type='DEV'
          close={() => setCopyInfo(null)}
          {...{ copyInfo, refreshList, setCurrentInfo }}
        />
      }
      {
        _.includes(['detail', 'edit'], _.get(currentInfo, 'mode')) &&
        <DevListDetailDialog
          refreshViewList={refreshList}
          close={() => setCurrentInfo(null)}
          setCurrentInfo={setCurrentInfo}
          outerAllDetailAuth={allDetailAuth}
          {...{ currentInfo, switchCurrentItem, showChildList, currentIndex, delAuth, storyTypeOptions }}
        />
      }
      {
        !_.isNil(delInfo) &&
        <DelMessage
          method={'get'}
          refresh={refreshList}
          close={() => setDelInfo(null)}
          url={`${DEL_URL}?id=${_.get(delInfo, 'id')}`}
        >
          是否删除该需求？
        </DelMessage>
      }
    </div>
  )
}

function sortFun(a, b, bind) {
  if (_.isNil(a[bind])) return -1
  const aStr = isNil(a[bind]) ? '' : a[bind]
  const bStr = isNil(b[bind]) ? '' : b[bind]
  if (_.isNumber(a[bind]) && _.isNumber(b[bind])) {
    return a[bind] - b[bind]
  }
  if (_.isNumber(a[bind]) ^ _.isNumber(b[bind])) {
    return (_.toNumber(a[bind]) || 0) - (_.toNumber(b[bind]) || 0)
  }
  return aStr.localeCompare(bStr)
}

import React, { useCallback, useMemo, useState } from 'react'
import { Pagination } from 'rootnet-ui'
import { Drawer, List, Input } from 'antd'
import { useGet } from 'rootnet-biz/es/hooks'
import { strParams } from '../../../../utils/publicFun'
import './index.scss'
import _ from 'lodash'

const SEARCH_URL = '/ES/bug/tracer'
const pageSize = 20

export default function GlobalSearch(props) {
  const { close, visible } = props
  const [showText, setShowText] = useState()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const { data, doFetch } = useGet()
  const { currentPage, totalCount } = useMemo(() => (data || {}), [data])

  const onSearch = useCallback((v, currentPage = 1) => {
    setLoading(true)
    doFetch(`${SEARCH_URL}?${strParams({ currentPage, pageSize, value: v })}`)
      .then(res => {
        setList(res.records)
        setLoading(false)
      })
  }, [doFetch])

  const closeAll = useCallback(() => {
    setList([])
    setShowText(null)
    close()
  }, [close])

  return (
    <Drawer
      title="全文检索"
      placement="right"
      onClose={closeAll}
      visible={visible}
      className='global-search-drawer'
    >
      {/* <div className='global-search-content'> */}
      <Input.Search value={showText} onChange={e => setShowText(e.target.value)} placeholder="请输入关键字搜索" onSearch={v => onSearch(v, 1)} enterButton />
      <List
        loading={loading}
        // header={<div style={{ textAlign: 'center' }}>70052次命中</div>}
        size="small"
        footer={
          (!loading && !_.isEmpty(list)) && <Pagination pageSize={pageSize} total={totalCount} current={currentPage}
            onChange={(pageNum) => {
              onSearch(showText, pageNum)
            }} />
        }
        bordered
        dataSource={list || []}
        renderItem={item => (
          <List.Item>
            <div>
              <div>
                <span
                  className='hover-tracer-id'
                  onClick={() => window.open(`#/trackingManagementMgt?initId=${purifyRichText(item.tracerId)}`)}
                >
                  {purifyRichText(item.tracerId)}
                </span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: item.tracerTitle }} />
              <div dangerouslySetInnerHTML={{ __html: item.testLeaderDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.controllDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.devTestDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.validateDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.dataBaseDescMysql }} />
              <div dangerouslySetInnerHTML={{ __html: item.validateTest }} />
              <div dangerouslySetInnerHTML={{ __html: item.devTestLeaderDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.bugDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.dataBaseDescSQLServer }} />
              <div dangerouslySetInnerHTML={{ __html: item.fixDesc }} />
              <div dangerouslySetInnerHTML={{ __html: item.dataBaseDesc }} />
            </div>
          </List.Item>
        )}
      />
      {/* </div> */}
    </Drawer>
  )
}

function purifyRichText(richText = ''){
  const regex = /(<([^>]+)>)/ig
  return richText.replace(regex, "")
      .replace(/\s/g,"")
      .replace(/ /g,"")
      .replace(/&nbsp;/g,"")
}

import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import convertTableAlign from '../../view/convertTableAlign'
import findConvert from '../../view/findConvert'
import useGetTreeList from '../../view/hooks/useGetTreeList'
import useGetDateOptions from '../../view/hooks/useGetDateOptions'
import useGetViewConfig from '../../view/hooks/useGetViewConfig'
import useRefreshList from '../../view/hooks/useRefreshList'
import ViewArea from '../../view/ViewArea'
import { Box, TextIconBtn } from '../../commonComponent'
import './index.scss'
import { isNil } from 'rootnet-core/format'
import { CustomizeFormInput } from '../../customizeOptions/CustomizeOptions'
import convertOptions from '../../ConvertOptions'
import WorkFlowChangePop from '../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { Icon } from '../../../../components'
import DefectUpdateDialog from '../../../testMgt/defect/controls/DefectUpdateDialog'

const PRIMARY_KEY = 'defectId'
const ENTER_DETAIL_FIELD = 'title'

// 'productId'
const EDITABLE_FIELD = [
  // 'title', 'status', 'severity', 'priority', 'releaseId', 'subSysId', 'source', 'type', 'originPhase', 'currentOwner', 'recurrence', 'currentUser'
]
const SEVERITY_COLOR = {
  '01': '#F5221B',
  '02': '#FF8C00',
  '03': '#FFC20E',
  '04': '#5477ff',
  '05': '#A9A9A9',
}
const PRIORITY_COLOR = {
  '01': '#F85E5E',
  '02': '#93C36B',
  '03': '#97AFD0',
}
const STATUS_COLOR = {
  'new': '#93C36B',
  'closed': '#A9A9A9',
  'suspended': '#A9A9A9',
  'rejected': '#A9A9A9',
  'postponed': '#A9A9A9',
  'in_progress': '#5477ff',
  'resolved': '#5477ff',
  'verified': '#5477ff',
  'reopened': '#5477ff',
}

const SPECIAL_FIELD_CONVERT = {
  'severity': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: SEVERITY_COLOR[value], border: `1px solid ${SEVERITY_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'priority': (value, showValue) => {
    return <div className={'priority-field'} style={{ background: PRIORITY_COLOR[value], padding: '1px 12px 2px', color: '#fff', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'status': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: STATUS_COLOR[value], border: `1px solid ${STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

const REPLACE_POST_PARAMS = {
  'currentOwner': 'current_owners'
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, updateShowList, editInfo, setEditInfo, currentTableValueRef, workFlowId, setWorkFlowId, refreshList, setCurrentId, setMode, } = props
  const { editId = null, editField = null } = editInfo || {}
  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
    }
    if (tableField === 'workflow_business.factorId') {
      const workFlowStatusOptions = _.find(convertCollection, (v, k) => k === tableField)
      const workflowStatusColor = convertOptions(v, workFlowStatusOptions, 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={'0504'} businessType={'defect'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowStatusColor, border: `1px solid ${workflowStatusColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => currentTableValueRef.current = handleValue}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentId(o.id)
          setMode('detail')
        }}>{showValue}</div>
        <Icon name={'bianji2'} className="enter-detail-field-edit-icon" onClick={() => onEditFieldClick({ o, fieldItem })} />
      </div>
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'test_defect.title'), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '0012'

export default function PublicDefect(props) {
  const { defaultParams, addDefaultParams } = props
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes])
  const [sortConfig, setSortConfig] = useState()
  const [workFlowId, setWorkFlowId] = useState()
  const [currentId, setCurrentId] = useState()
  const [mode, setMode] = useState()

  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const replaceParams = useMemo(() => {
    return defaultParams
  }, [defaultParams])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    let postParams = {
      // defectId: primaryId,
      ids: [editInfo['editId']]
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    if (editInfo['fieldId'] === 'currentUser') {
      if (isNil(updateValue)) {
        setEditInfo(null)
        currentTableValueRef.current = null
        return
      }
      const currentUserParams = {
        businessId: _.get(editInfo, 'editId'),
        businessType: 'defect',
        funcCode: FUNC_CODE,
        currentUser: updateValue,
        title: _.get(editInfo, 'title')
      }
      editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    } else {
      editTable('/test_defect/updateSingle', postParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    }

    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable])

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { updateShowList, editInfo, setEditInfo, currentTableValueRef, workFlowId, setWorkFlowId, refreshList, setCurrentId, setMode, },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        const sortList = _.sortBy(data, x => x[sort.column])
        return sort.direction === 'asc' ? sortList : _.reverse(_.clone(sortList))
      }
    }
  })

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='tianjia' text='新增' onClick={() => {
        setMode('add')
      }} />
    </div>
  }, [])

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      const sortList = _.sortBy(showChildList, x => x[sortConfig.column])
      showList = sortConfig.direction === 'asc' ? sortList : _.reverse(sortList)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  const currentIndex = useMemo(() => {
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [showList, currentId])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentId(switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id)
  }, [showList, setCurrentId, currentIndex])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  return (
    <div className='public-defect fill flex-y'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize, replaceParams
        }} />
      <Box title={'缺陷列表'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['add', 'edit', 'detail'], mode) &&
        <DefectUpdateDialog close={() => setMode(null)} {...{
          convertCollection, mode, setMode, currentId, setCurrentId, refreshList,
          switchCurrentItem, currentIndex, showChildList, addDefaultParams
        }} />
      }
    </div>
  )
}

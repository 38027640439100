import React, { useEffect, useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager } from 'rootnet-ui'
import { strParams } from '../../../../../utils/publicFun'
import AssigningUser from '../../../../authorityMgt/roleMgt/controls/roleList/controls/assigningUser'

const EDIT_URL = '/demoTxt/edit'
const DETAIL_URL = '/demoTxt/getDetail'

export default function AssigningAuthorization(props) {
  const { id, close, refresh = () => { }, initValue, upDate = () => { }, isSubmit = false, initLoading } = props
  const [params, setParams] = useState()
  const [isDisable, setIsDisable] = useState()
  const { doFetch, loading } = useGet()
  const { doFetch: postFetch } = usePost()
  const { templateAuthorizations } = useMemo(() => params || {}, [params])

  useEffect(() => {
    if (!isSubmit) return
    if (isNil(id)) return
    doFetch(`${DETAIL_URL}?${strParams({ id })}`)
      .then((res) => {
        setParams(res)
      })
      .catch((err) => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [id, doFetch, isSubmit])

  const handChange = useCallback((collect) => {
    const list = _.concat([], ..._.map(collect, (list, k) => {
      return _.map(list, v => {
        return _.assign({}, {
          type: k,
          demoID: id,
          userId: v,
        })
      })
    }))
    if (isSubmit) {
      if (isDisable) return
      setIsDisable(true)
      const postParams = _.assign({}, params, {
        templateAuthorizations: list
      })
      postFetch(EDIT_URL, postParams)
        .then(() => {
          setIsDisable(false)
          close()
          refresh()
          Messager.show('授权成功', { icon: 'success' })
        })
        .catch((err) => {
          setIsDisable(false)
          Messager.show(err._message, { icon: 'error' })
        })
    } else {
      upDate(x => _.assign({}, x, { templateAuthorizations: list }))
      close()
    }
  }, [params, postFetch, id, close, refresh, isSubmit, upDate, isDisable])

  const defaultValue = useMemo(() => {
    if (isSubmit) {
      if (_.isEmpty(templateAuthorizations)) return []
      return _.groupBy(templateAuthorizations, 'type')
    } else {
      if (_.isEmpty(initValue)) return []
      return _.groupBy(initValue, 'type')
    }
  }, [templateAuthorizations, initValue, isSubmit])

  const boxLoading = useMemo(() => {
    return isSubmit ? loading : initLoading
  }, [isSubmit, loading, initLoading])

  return (
    <Dialog
      footerVisible={false}
      style={{ padding: 0 }}
      header='授权'
      cancel={close}
      loading={boxLoading}
    >
      <AssigningUser
        close={close}
        onChange={handChange}
        value={getValue(defaultValue, 'user')}
        groupValue={getValue(defaultValue, 'group')}
        departValue={getValue(defaultValue, 'depart')}
      />
    </Dialog>
  )
}

function getValue(data, type) {
  return _.map(_.get(data, type, []), o => o.userId)
}

import React, {useCallback, useState} from 'react';
import {Dialog} from 'rootnet-ui'
import _ from "lodash";
import {DatePicker} from "antd";
import './DailyReportSelectDateDialog.scss'
import moment from 'moment'

function DailyReportSelectDateDialog(props) {
    const {type = 'date', onCheckDate, close} = props
    const [tempDateSelected, setTempDateSelected] = useState(moment())

    const onConfirm = useCallback(()=>{
        onCheckDate(type, tempDateSelected)
    },[type, onCheckDate, tempDateSelected])

    return <Dialog className={'daily-report-select-date-dialog'} contentClassName={'flex center'} header={'选择填报日期'} confirm={onConfirm} cancel={close}>
        <DatePicker value={tempDateSelected} onChange={setTempDateSelected} allowClear={false}
                    picker={_.includes(['day','experience'],type)? 'date': "week"} />
    </Dialog>
}

export default DailyReportSelectDateDialog;
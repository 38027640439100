import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Input} from 'rootnet-edit';
import './TagsPopover.scss'
import {Divider} from 'antd';
import _ from 'lodash'
import {isNil} from "rootnet-core/format";
import CircleColorPicker from "../../../common/circleColorPicker/CircleColorPicker";
import {Button, Messager} from 'rootnet-ui'
import {useGet, usePost} from "rootnet-biz/es/hooks";

function TagsPopover(props) {
    const {id, funcCode, tagsList = [], close, refreshTags, createTagAuth, isBatch = false, idList} = props
    const [searchText, setSearchText] = useState()
    const [newColor, setNewColor] = useState('#f44336')
    const [showCreateArea, setShowCreateArea] = useState(false)
    const [showCreateOption, setShowCreateOption] = useState(false)
    const {data: tagsOptions} = useGet('/tag/setting/select')
    const {doFetch: createTags} = usePost()
    const {doFetch: createRelation} = usePost()

    const unSelectedOptions = useMemo(()=>{
        const selectedNameList = _.map(tagsList, x => _.get(x,'tagName'))
        return _.filter(tagsOptions, x => !_.includes(selectedNameList, x.tagName))
    },[tagsList, tagsOptions])

    const showTagsOptions = useMemo(()=>{
        if(isNil(_.trim(searchText))) return unSelectedOptions
        return _.filter(unSelectedOptions, x => _.includes(_.toLower(x.tagName), _.toLower(_.trim(searchText))))
    },[unSelectedOptions, searchText])

    useEffect(()=>{
        if(isNil(_.trim(searchText))) return setShowCreateOption(false)
        const hasSameTag = _.some(tagsOptions, x => x.tagName === _.trim(searchText))
        if(hasSameTag){
            return setShowCreateOption(false)
        }
        setShowCreateOption(true)
    },[searchText, tagsOptions])

    const onSearchTextChange = useCallback((newValue)=>{
        setSearchText(newValue)
        setShowCreateArea(false)
    },[])

    const onRelateTags = useCallback((tagId)=>{
        const params = {
            referenceId: id,
            referenceIdList: idList,
            funcCode,
            tagId
        }
        const url = isBatch ? '/tag/mapping/add/list' : '/tag/mapping/add'
        createRelation(url, params).then(()=>{
            if(refreshTags){
                refreshTags()
            }
            if(close){
                close()
            }
        }).catch(err => Messager.show(err.Message, { icon: 'error' }))
    },[createRelation, id, funcCode, refreshTags, close, idList, isBatch])

    const onCreateTags = useCallback(()=>{
        const params = {
            tagName: _.trim(searchText),
            tagColor: newColor
        }
        createTags('/tag/setting/add', params).then(tagId => {
            onRelateTags(tagId)
        }).catch(err => Messager.show(err.Message, { icon: 'error' }))
    },[createTags, searchText, newColor, onRelateTags])

    return <div className={'tags-popover flex-y'}>
        <Input value={searchText} onChange={onSearchTextChange} placeholder={'查找或创建标签'}/>
        <Divider />
        {
            createTagAuth && showCreateOption &&
            <div className={'create-option flex cursor-pointer'} onClick={()=>{
                setShowCreateArea(true)
                setShowCreateOption(false)
            }}>
                创建新标签：{_.trim(searchText)}
            </div>
        }
        {
            !showCreateArea &&
            <div className="tag-options-wrap flex-y">
                {
                    _.map(showTagsOptions, x => {
                        return <div className={'tag-option flex center-y cursor-pointer'} key={x.tagName} onClick={()=>onRelateTags(x.id)}>
                            <div className="color-block" style={{background: x.tagColor}}/>
                            <div className="tag-text flex">{x.tagName}</div>
                        </div>
                    })
                }
            </div>
        }
        {
            showCreateArea && <div className={'flex-y'}>
                <CircleColorPicker setColor={setNewColor} circleSize={23} circleSpacing={8} width={200}/>
                <Button primary className={'create-btn'} onClick={onCreateTags}>创建标签</Button>
            </div>
        }
    </div>
}

export default TagsPopover;
import React, {useCallback, useState} from 'react';
import './ConferenceRecordsDetailHeader.scss'
import _ from "lodash";
import {Popover, Tooltip} from "antd";
import {FormInput} from "rootnet-edit";
import {Icon} from "../../../../../components";
import {Button, Messager} from "rootnet-ui";
import {copyText} from "rootnet-core/clipboard";

function ConferenceRecordsDetailHeader(props) {
    const {editFormData, isDetail, setEditFormData} = props
    const [showCopyPop, setShowCopyPop] = useState(false)

    const copy = useCallback((value)=>{
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', {icon: 'success'});
    },[])

    const CopyArea = useCallback(()=>{
        const detailUrl = window.location.host + `/#/conferenceRecords?initId=` + _.get(editFormData,'id')
        return <div className={'flex customer-service-info-copy-popover center-y'}>
            <Button onClick={()=>copy(_.get(editFormData,'sSubject'))} normal>复制标题</Button>
            <Button onClick={()=>copy(detailUrl)} normal>复制会议纪要的URL</Button>
        </div>
    },[copy, editFormData])

    return <div className={'conference-records-detail-header'}>
        <div className="header-left">
            {
                isDetail &&
                <div className="mock-title-wrap flex center-y">
                    <Tooltip title={_.get(editFormData, 'sSubject')}>
                        {_.get(editFormData, 'sSubject')}
                    </Tooltip>
                    <Popover content={<CopyArea/>} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                        <Icon name={'fuzhi'} className={'copy-icon'}/>
                    </Popover>
                </div>
            }
            {
                !isDetail && <FormInput horizontal bind={'sSubject'} label={'标题'} required value={_.get(editFormData, 'sSubject')}
                                        onChange={v => setEditFormData(x => ({...x, sSubject: v}))} maxLength={200}/>
            }
        </div>
    </div>
}

export default ConferenceRecordsDetailHeader;
import React, { useMemo, useCallback, useEffect, useState, useRef, useContext } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useGet } from 'rootnet-biz/lib/hooks'
import { Form, Table, Input, Select, DatePicker, Popconfirm, InputNumber } from 'antd'
import { sortableContainer, sortableElement } from "react-sortable-hoc"
import UserSelect from '../../../../../common/personSelectPop/UserSelect'
import { VersionMilestoneInConferenceRecordsEditContext } from '../../../../../common/Context'
import { Icon } from '../../../../../../components'
import { isNil } from 'rootnet-core/format'
import convertOptions from '../../../../../common/ConvertOptions'
import { Messager } from 'rootnet-ui'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'

const DEL_URL = '/releaseinfo/delete/releasemilestone'

function getColumns(props) {
  const { editable, delDataItem, copyDataItem, allUserRes, mileOpt } = props
  const cols = [
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>里程碑</span>,
      dataIndex: 'milestone',
      key: 'milestone',
      editable,
      width: 200,
      render: (value, obj) => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }

        return _.get(_.find(mileOpt, o => o.value === value), 'label') || value
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>计划完成日期</span>,
      dataIndex: 'completeTimePlan',
      key: 'completeTimePlan',
      editable,
      width: 200,
      render: (value, obj) => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }
        return dateFormat('YYYY-MM-DD', toDate.str14ToDate(value))
      }
    },
    {
      title: <span className={'header-required'} style={{ marginLeft: 2 }}>负责人</span>,
      dataIndex: 'owner',
      key: 'owner',
      editable,
      width: 200,
      render: (value, obj) => {
        if (isNil(value)) {
          return <div style={{ color: 'red', opacity: 0.5 }}>必填</div>
        }

        return <div style={{ marginLeft: editable ? 0 : 12 }}>
          {convertOptions(value, allUserRes, 'userName', 'userAccount')}
        </div>
      }
    },
    {
      title: '计划用时（H）',
      dataIndex: 'workloadPlan',
      key: 'workloadPlan',
      editable,
      width: 200,
    },
    {
      title: '备注',
      dataIndex: 'memo',
      key: 'memo',
      editable,
      width: 400,
    },
  ]
  const operation = [
    {
      title: '操作',
      width: 95,
      align: 'center',
      fixed: 'right',
      render: (value, obj) => {
        return <>
          <Icon className={'version-copy-icon'} name='fuzhi' style={{ fontSize: 16, marginRight: 8 }} onClick={() => copyDataItem(obj)} />
          <Popconfirm
            title="确认删除？"
            onConfirm={() => delDataItem(obj.id, obj.default)}
            okText="删除"
            cancelText="取消"
            overlayInnerStyle={{ padding: 8, width: 200 }}
          >
            <Icon className={'version-del-icon'} name='shanchu' style={{ fontSize: 16 }} />
          </Popconfirm>
        </>
      }
    }
  ]
  return editable ? _.concat(cols, operation) : cols
}

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

export default function VersionMilestone(props) {
  const { data, setData, updateEditRow, isDetail, allUserRes, currentId, isQA } = props
  const { doFetch: getOnlyId } = useGet()
  const { data: mileRes } = useGet('/common/globalconst?globalConst=reqMileStone')
  const { doFetch: delFetch } = useGet()
  const [form] = Form.useForm()

  const editable = useMemo(() => {
    if (isQA) return false
    return isDetail
  }, [isQA, isDetail])

  const mileOpt = useMemo(() => {
    if (_.isEmpty(mileRes)) return []
    return _.map(mileRes, o => ({ label: o.displayName, value: o.interiorId }))
  }, [mileRes])

  const addData = useCallback(() => {
    if (isNil(_.trim(currentId))) return Messager.show('请填写版本编号后在进行操作', { icon: 'error' })
    getOnlyId('/test_case/productGetOnlyId').then(id => {
      setData(old => {
        return _.concat(old, [_.assign({
          id: id,
          default: true
        }, !_.isNil(currentId) && { releaseId: currentId, })])
      })
    })
  }, [currentId, getOnlyId, setData])

  const EditableRow = useCallback(({ index, ...restProps }) => {
    const currentIndex = data.findIndex(x => x.id === restProps['data-row-key']);
    return <Form form={form} component={false}>
      <VersionMilestoneInConferenceRecordsEditContext.Provider value={{ form: form }}>
        <SortableItem index={currentIndex} {...restProps} />
      </VersionMilestoneInConferenceRecordsEditContext.Provider>
    </Form>
  }, [data, form])

  const handleSave = useCallback((row, bind) => {
    if (updateEditRow) {
      updateEditRow(row)
    }
    const index = _.findIndex(data, x => x.id === row.id)
    setData(oldList => {
      const newList = _.map(oldList, (item, i) => {
        if (i !== index) return item
        return ({
          ...item,
          ...row
        })
      })
      return _.sortBy(newList, 'serial')
    })
  }, [updateEditRow, data, setData])

  const delDataItem = useCallback((delId, flag) => {
    if (flag) {
      changeList()
    } else {
      delFetch(`${DEL_URL}?id=${delId}`)
        .then(() => {
          changeList()
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }

    function changeList() {
      setData(oldList => {
        return _.filter(oldList, x => x.id !== delId)
      })
    }

  }, [delFetch, setData])

  const copyDataItem = useCallback((item) => {
    getOnlyId('/test_case/productGetOnlyId')
      .then(id => {
        setData(oldList => _.concat(oldList, [{ ...item, id }]))
      })
  }, [getOnlyId, setData])

  const columns = useMemo(() => {
    return _.map(getColumns({ editable, delDataItem, copyDataItem, allUserRes, mileOpt }), col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          showList: data,
          mileOpt,
        })
      }
    })
  }, [allUserRes, copyDataItem, data, delDataItem, editable, handleSave, mileOpt])
  return (
    <>
      <Table
        size={'small'}
        rowClassName={'editable-row'}
        columns={columns}
        pagination={false}
        dataSource={data}
        rowKey="id"
        sticky={true}
        scroll={{ x: 1000 }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: EditableRow,
            cell: EditableCell
          }
        }}
      />
      {
        editable &&
        <div className='add-data-item' onClick={addData}>
          <Icon name='fix-xinzeng' /> 添加一行数据
        </div>
      }
    </>
  )
  function DraggableContainer(props) {
    return <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      {...props}
    />
  }
}

function EditableCell(props) {
  const { title, editable, children, dataIndex, record, handleSave, must, showList, mileOpt, ...restProps } = props
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const { form } = useContext(VersionMilestoneInConferenceRecordsEditContext || {})

  useEffect(() => {
    if (editing) {
      if (!_.isNil(inputRef.current)) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  const toggleEdit = useCallback(() => {
    setEditing(x => !x);
    let value
    if (dataIndex === 'completeTimePlan') {
      value = _.isNil(record[dataIndex]) ? null : moment(record[dataIndex], 'YYYY-MM-DD')
    } else {
      value = record[dataIndex]
    }
    form.setFieldsValue({ [dataIndex]: value });
  }, [dataIndex, record, form])

  const save = () => {
    try {
      form.validateFields().then(changeObj => {
        const key = _.head(_.keys(changeObj))
        if (key === 'completeTimePlan') {
          changeObj[key] = _.isNil(changeObj[key]) ? null : moment(changeObj[key]).format('YYYYMMDDHHMMSS')
        }
        toggleEdit();
        if (changeObj[key] !== record[key]) {
          handleSave({ ...record, ...changeObj }, key);
        }
        if (!_.isNil(inputRef.current)) {
          inputRef.current.blur()
        }
        setEditing(false)
      })
    } catch (errInfo) {
      console.error(errInfo);
    }
  }

  let childNode = children;
  //milestone  completeTimePlan owner workloadPlan memo
  if (editable) {
    if (editing) {
      let editNode
      if (_.includes(['owner'], dataIndex)) {
        editNode = (<UserSelect ref={inputRef} onChange={save} defaultOpen={true} onBlur={() => setEditing(false)} />)
      } else if (dataIndex === 'milestone') {
        editNode = (<Select ref={inputRef} options={mileOpt} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditing(false)} />)
      } else if (dataIndex === 'completeTimePlan') {
        editNode = (<DatePicker ref={inputRef} onChange={save} allowClear={false} open={true} onBlur={() => setEditing(false)} autoFocus />)
      } else if (dataIndex === 'memo') {
        editNode = (<Input.TextArea ref={inputRef} onBlur={save} />)
      } else if (dataIndex === 'workloadPlan') {
        editNode = (<InputNumber ref={inputRef} min={0} onPressEnter={save} onBlur={save} />)
      } else {
        editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
      }

      // else if (dataIndex === '') {
      //   editNode = (<UserSelect ref={inputRef} multiple onChange={save} defaultOpen={true} onBlur={() => setEditing(false)} />)
      // }
      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: must,
              message: `${title}不能为空`,
            },
          ]}
        >
          {editNode}
        </Form.Item>
      )
    } else {
      childNode = (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>;
}

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Graph, Shape} from "@antv/x6";
import _ from "lodash";
import './WorkFlowGraph.scss'

// #region 初始化图形
const ports = {
    groups: {
        top: {
            position: 'top',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
        right: {
            position: 'right',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
        bottom: {
            position: 'bottom',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
        left: {
            position: 'left',
            attrs: {
                circle: {
                    r: 4,
                    magnet: true,
                    stroke: '#5F95FF',
                    strokeWidth: 1,
                    fill: '#fff',
                    style: {
                        visibility: 'hidden',
                    },
                },
            },
        },
    },
    items: [
        {
            group: 'top',
        },
        {
            group: 'right',
        },
        {
            group: 'bottom',
        },
        {
            group: 'left',
        },
    ],
}

Graph.registerNode(
    'custom-circle',
    {
        inherit: 'circle',
        width: 45,
        height: 45,
        attrs: {
            body: {
                strokeWidth: 1,
                stroke: '#5F95FF',
                fill: '#EFF4FF',
            },
            text: {
                fontSize: 12,
                fill: '#262626',
            },
        },
        ports: { ...ports },
    },
    true,
)

// 初始化自定义图形
Graph.registerNode(
    'custom-rect',
    {
        inherit: 'rect',
        width: 66,
        height: 36,
        attrs: {
            body: {
                strokeWidth: 1,
                stroke: '#5F95FF',
                fill: '#EFF4FF',
            },
            text: {
                fontSize: 12,
                fill: '#262626',
            },
        },
        ports: {
            ...ports,
        },
    },
    true,
)

// 初始化自定义图形
Graph.registerNode(
    'custom-big-rect',
    {
        inherit: 'rect',
        width: 80,
        height: 50,
        attrs: {
            body: {
                strokeWidth: 1,
                stroke: '#5F95FF',
                fill: '#EFF4FF',
            },
            text: {
                fontSize: 12,
                fill: '#262626',
            },
        },
        ports: {
            ...ports,
            items: [
                {
                    group: 'top',
                    id: 'topLeft',
                },
                {
                    group: 'top',
                    id: 'top',
                },
                {
                    group: 'top',
                    id: 'topRight',
                },
                {
                    group: 'right',
                    id: 'rightTop',
                },
                {
                    group: 'right',
                    id: 'right',
                },
                {
                    group: 'right',
                    id: 'rightBottom',
                },
                {
                    group: 'bottom',
                    id: 'bottomLeft',
                },
                {
                    group: 'bottom',
                    id: 'bottom',
                },
                {
                    group: 'bottom',
                    id: 'bottomRight',
                },
                {
                    group: 'left',
                    id: 'leftTop',
                },
                {
                    group: 'left',
                    id: 'left',
                },
                {
                    group: 'left',
                    id: 'leftBottom',
                },
            ],
        },
    },
    true,
)

function WorkFlowGraph(props) {
    const {initData: initJsonData, currentNodeName} = props
    const initData = useMemo(()=>initJsonData?JSON.parse(initJsonData):{cells: []},[initJsonData])
    const [data, setData] = useState(initData)
    const graphContainer = useRef()

    useEffect(()=>{
        if(_.isNil(currentNodeName)) return setData(initData)
        const cellList = _.get(initData, 'cells')
        if(_.isEmpty(cellList)) return setData(initData)
        const nodeList = _.filter(cellList, x => _.get(x,'shape') !== 'edge')
        const currentNode = _.find(nodeList, x => _.get(x,'attrs.text.text') === currentNodeName)
        const currentNodeId = _.get(currentNode,'id')
        if(_.isNil(currentNodeId)) return setData(initData)
        const handledCellList = _.map(cellList, x => {
            if(_.get(x,'id') === currentNodeId){
                const clone = _.clone(x)
                clone['attrs']['body'] = {fill: '#1890ff'}
                clone['attrs']['label'] = {fill: '#fff'}
                return clone
            }else{
                return x
            }
        })
        setData({cells: handledCellList})
    },[initData, currentNodeName])

    useEffect(()=>{
        if(_.isNil(graphContainer)) return

        // #region 初始化画布
        const graph = new Graph({
            container: graphContainer.current,
            grid: true,
            panning: true,
            mousewheel: {
                enabled: true,
                zoomAtMousePosition: true,
                modifiers: 'ctrl',
                minScale: 0.5,
                maxScale: 3,
            },
            // width: 1000,
            // height: 600,
            height: '100%',
            background:{
                color: '#f5f5f5'
            },
            connecting: {
                router: {
                    name: 'manhattan',
                    args: {
                        padding: 1,
                    },
                },
                connector: {
                    name: 'rounded',
                    args: {
                        radius: 8,
                    },
                },
                anchor: 'center',
                connectionPoint: 'anchor',
                allowBlank: false,
                snap: {
                    radius: 20,
                },
                createEdge() {
                    return new Shape.Edge({
                        attrs: {
                            line: {
                                stroke: '#A2B1C3',
                                strokeWidth: 2,
                                targetMarker: {
                                    name: 'block',
                                    width: 12,
                                    height: 8,
                                },
                            },
                        },
                        zIndex: 0,
                    })
                },
                validateConnection({ targetMagnet }) {
                    return !!targetMagnet
                },
            },
            highlighting: {
                magnetAdsorbed: {
                    name: 'stroke',
                    args: {
                        attrs: {
                            fill: '#5F95FF',
                            stroke: '#5F95FF',
                        },
                    },
                },
            },
            interacting: {
                nodeMovable: false
            }
        });

        // #endregion
        graph.fromJSON(data)
    },[data])
    
    return <div className={'work-flow-graph'}>
        <div ref={graphContainer}/>
    </div>
}

export default WorkFlowGraph;
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import cls from 'clsx'
import { Dialog, Messager } from 'rootnet-ui'
import { FormInput, TextArea } from 'rootnet-edit'
import usePost from "rootnet-biz/es/hooks/usePost"
import { strParams } from '../../../../utils/publicFun';
import './index.scss'

const DELETE_URL = '/issue/delete'

export default function IssueDelete(props) {
  const { id, close, refreshList, cancelRelatedIssue } = props
  const [value, setValue] = useState()
  const { doFetch } = usePost()

  const submit = useCallback(() => {
    if (_.size(_.trim(value)) < 10) return Messager.show('当前字段至少10位', { icon: 'error' })
    if (_.isFunction(cancelRelatedIssue)) {
      cancelRelatedIssue([{ id }], false, delFun)
    } else {
      doFetch('/implement/project/related/issue/del', [id])
        .then(() => {
          delFun()
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
    function delFun() {
      doFetch(`${DELETE_URL}?${strParams({ id, reason: value })}`, {})
        .then(() => {
          close()
          refreshList()
          Messager.show('删除成功', { icon: 'success' })
        })
        .catch(err => Messager.show(err._message, { icon: 'error' }))
    }
  }, [cancelRelatedIssue, close, doFetch, id, refreshList, value])

  return <Dialog
    header='删除ISSUE'
    cancel={close}
    confirm={submit}
    className={'issue-delete-log'}
  >
    <FormInput
      required
      horizontal
      label='备注'
      value={value}
      onChange={setValue}
      component={TextArea}
      componentWidth={300}
      placeholder='请输入至少10位'
      className={cls('reason', { error: _.size(_.trim(value)) < 10 })}
    />
  </Dialog>;
}

import React, {useCallback, useEffect, useState} from 'react';
import './FuncTableEdit.scss'
import RelationCard from "../relationCard/RelationCard";
import FuncDefinitionCard from "../funcDefinitionCard/FuncDefinitionCard";
import FilterCondition from "../filterCondition/FilterCondition";
import QueryField from "../queryField/QueryField";
import ShowField from "../showField/ShowField";
import SortCard from "../sortCard/SortCard";
import {Button, Checkbox} from "antd";
import _ from 'lodash'
import {Messager, Dialog, Loader} from 'rootnet-ui'
import "intro.js/introjs.css";
import {useGet, usePost} from "rootnet-biz/es/hooks";
import {uniqKeyFor} from "../../../../../project_share/utils/utils";

function getInitFuncDefinition(){
    return {
        primaryTable: null,
        primaryKey: null,
        isRecordQuery: 'Y'
    }
}

export default function FuncTableEdit(props) {
    const {mode, setMode, currentId, setCurrentId} = props
    const [fullScreen, setFullScreen] = useState()
    const {data:tableRes} = useGet('/FieldDefinition/getTableAndField')
    const [funcDefinition, setFuncDefinition] = useState(getInitFuncDefinition())
    const [funcDefinitionError, setFuncDefinitionError] = useState()
    const [relation, setRelation] = useState()
    const {data: allFieldList, doFetch: getAllFieldList} = usePost()
    const {data: primaryFieldList, doFetch: getPrimaryFieldList} = usePost()
    const [queryFieldList, setQueryFieldList] = useState([])
    const [showFieldList, setShowFieldList] = useState([])
    const [sortList, setSortList] = useState([])
    const [filterConditionText, setFilterConditionText] = useState()
    const {doFetch: getPreview} = usePost()
    const [previewInfo, setPreviewInfo] = useState()
    const {doFetch: submitPost} = usePost()
    const {doFetch: getInitDetail} = useGet()
    const [isUpdateView, setIsUpdateView] = useState(false)
    const [allowExport, setAllowExport] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(_.isNil(currentId)) return
        setLoading(true)
        getInitDetail('/FuncTable/getDetails?id='+currentId).then(res => {
            setLoading(false)
            const initParams = _.pick(res, ['funcCode','funcName','primaryTable','primaryKey','resultType','isRecordQuery'])
            setFuncDefinition(initParams)
            setQueryFieldList(JSON.parse(res.conditionSet) || [])
            setShowFieldList(JSON.parse(res.resultSet) || [])
            setSortList(JSON.parse(res.orderBy) || [])
            setFilterConditionText(res.filterCondition)
            setAllowExport(res?.allowExport === 'Y')

            const initRelation = JSON.parse(res.slaveFilterCondition)
            _.forEach(initRelation, item => {
                item['id'] = uniqKeyFor()
                if(!_.isEmpty(_.get(item, 'slaveFilterConditions'))){
                    _.forEach(_.get(item, 'slaveFilterConditions'), child => {
                        child['id'] = uniqKeyFor()
                    })
                }
            })
            setRelation(initRelation || [])
        }).catch(()=>{
            setLoading(false)
        })
    },[getInitDetail, currentId])

    useEffect(()=>{
        if(_.get(funcDefinition, 'primaryTable') && !_.isNil(relation)){
            const searchParams = {
                primaryTable: funcDefinition.primaryTable,
                slaveFilterConditions: relation
            }
            getAllFieldList('/FuncTable/getFieldsFromTableRelation',searchParams)
        }
    },[funcDefinition, getAllFieldList, relation])

    useEffect(()=>{
        if(funcDefinition.primaryTable){
            const searchParams = {
                primaryTable: funcDefinition.primaryTable,
            }
            getPrimaryFieldList('/FuncTable/getFieldsFromTableRelation',searchParams)
        }
    },[funcDefinition.primaryTable, getPrimaryFieldList])

    const submit = useCallback((action)=>{
        if (_.some(_.values(funcDefinitionError), x => x)) {
            return Messager.show('请填写功能菜单' , { icon: 'error' })
        }
        const postParams = {
            id: currentId,
            action,
            ...funcDefinition,
            filterCondition: filterConditionText,
            slaveFilterConditions: relation,
            conditionSetList: queryFieldList,
            resultSetList: showFieldList,
            orderByList: sortList,
            isUpdateView: (isUpdateView || action === 'add') ? 'Y' : 'N',
            allowExport: allowExport ? 'Y' : 'N',
        }
        if(action === 'preview'){
            getPreview('/FuncTable/maintain',postParams)
                .then(res => setPreviewInfo(res))
                .catch(err => Messager.show(err._message, { icon: 'error' }))
        }else{
            setLoading(true)
            submitPost('/FuncTable/maintain',postParams).then(()=>{
                setLoading(false)
                Messager.show(action === 'add'?'添加成功':'修改成功', { icon: 'success' })
                setCurrentId(null)
                setMode('list')
            }).catch(err => {
                setLoading(false)
                Messager.show(err._message, {icon: 'error'})
            })
        }
    },[funcDefinition, relation, queryFieldList, showFieldList, filterConditionText, sortList, getPreview,
        currentId,submitPost, setMode, setCurrentId, funcDefinitionError, isUpdateView, allowExport])

    return <div className={'func-table-edit fill flex-y scroll-y'}>
        <div className="edit-panel flex-y scroll-y">
            {
                loading && <Loader fill/>
            }
            <div className="first-row flex">
                <FuncDefinitionCard style={{display: fullScreen?'none':''}} {...{tableRes,funcDefinition, setFuncDefinition,funcDefinitionError, setFuncDefinitionError}}/>
                <RelationCard {...{fullScreen, setFullScreen, tableRes, relation, setRelation}}/>
            </div>
            <div className="second-row flex" style={{display: fullScreen && (!['queryField','filterCondition'].includes(fullScreen)) ? 'none' : ''}}>
                <QueryField {...{fullScreen, setFullScreen,allFieldList,queryFieldList, setQueryFieldList}}/>
                <FilterCondition {...{fullScreen, setFullScreen, filterConditionText, setFilterConditionText}}/>
            </div>
            <div className="third-row flex" style={{display: fullScreen && (!['showField','sortCard'].includes(fullScreen)) ? 'none' : ''}}>
                <ShowField {...{fullScreen, setFullScreen,allFieldList,showFieldList, setShowFieldList}}/>
                <SortCard {...{fullScreen, setFullScreen,primaryFieldList,sortList, setSortList}}/>
            </div>
        </div>
        {
            _.isNil(fullScreen) &&
            <div className="handle-bar flex center-y">
                <Button type="primary" onClick={()=>submit(mode)}>保存</Button>
                <Button onClick={()=> {
                    setMode('list')
                    setCurrentId(null)
                }}>取消</Button>
                <Button onClick={()=>submit('preview')}>脚本预览</Button>
                {
                    mode === 'edit' &&
                    <Checkbox checked={isUpdateView} onChange={e => setIsUpdateView(e.target.checked)}>更新视图</Checkbox>
                }
                {
                    mode === 'edit' &&
                    <Checkbox checked={allowExport} onChange={e => setAllowExport(e.target.checked)}>允许导出</Checkbox>
                }
            </div>
        }
        {
            !_.isNil(previewInfo) &&
            <Dialog contentClassName={'func-preview-dialog'} header={'脚本预览'} cancelButtonText={'关闭'}
                    cancel={()=>setPreviewInfo(null)} confirmButtonVisible={false}>
                {previewInfo}
            </Dialog>
        }
    </div>
}


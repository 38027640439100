import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './DefectUpdateHeader.scss'
import _ from "lodash";
import {Button as AntButton, Popover, Tooltip} from "antd";
import {Icon} from "../../../../../components";
import {FormInput} from "rootnet-edit";
import {copyText} from "rootnet-core/clipboard";
import {Messager} from "rootnet-ui";
import WorkFlowChangePop from "../../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import convertOptions from "../../../../common/ConvertOptions";
import {strParams} from "../../../../../utils/publicFun";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import UserSelect from "../../../../common/personSelectPop/UserSelect";
import ConcernedIcon from '../../../../common/concernedIcon';

const FUNC_CODE = '0504'

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function DefectUpdateHeader(props) {
    const {isDetail, editFormData, currentId, setEditFormData, refreshDetail, refreshViewList, updateDynamic, mode, allUserRes, setStatusName} = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [showCopyPop, setShowCopyPop] = useState(false)
    const {data: workFlowInfo, doFetch: getStatus} = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

    const status = useMemo(()=>{
        return _.get(workFlowInfo,'factorId')
    },[workFlowInfo])

    const refreshStatus = useCallback(()=>{
        if(_.isNil(currentId)) return
        const params = {
            businessType: 'defect',
            businessId: currentId
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params))
    },[getStatus, currentId])

    useEffect(()=>{
        refreshStatus()
    },[refreshStatus])

    const [workFlowStatusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const copy = useCallback((value)=>{
        copyText(value);
        setShowCopyPop(false)
        Messager.show('数据已复制到剪切板', {icon: 'success'});
    },[])
    
    const CopyArea = useCallback(()=>{
        const id = _.get(editFormData,'defectId')
        const idTitle = `【${_.get(editFormData,'defectId')}】${_.get(editFormData,'title')}`
        const detailUrl = window.location.host + `/#/testDefectMgt?initId=` + currentId
        return <div className={'flex defect-title-handle-popover'}>
            <AntButton onClick={()=>copy(id)}>复制ID</AntButton>
            <div style={{width: 10}}/>
            <AntButton onClick={()=>copy(idTitle)}>复制ID和标题</AntButton>
            <div style={{width: 10}}/>
            <AntButton onClick={()=>copy(detailUrl)}>复制缺陷URL</AntButton>
            <div style={{width: 10}}/>
            <AntButton onClick={()=>copy(`${id}\n${_.get(editFormData,'title')}\n${detailUrl}`)}>复制ID+标题+URL</AntButton>
        </div>
    },[copy, editFormData,currentId])

    const workflowStatusColor = useMemo(()=>{
       return convertOptions(status, workFlowStatusOptions, 'color') || '#000'
    },[workFlowStatusOptions, status])

    useEffect(()=>{
        setStatusName(convertOptions(status, workFlowStatusOptions))
    },[setStatusName, status, workFlowStatusOptions])
    
    return <div className={'defect-update-header flex'}>
        <div className="header-left flex-y center-y">
            {
                <div className="req-id-wrap flex center-y">
                    <div className="req-id">{_.get(editFormData,'defectId')}</div>
                    {
                        mode !== 'add'&&
                        <ConcernedIcon
                            type='DEFECT'
                            mode='detail'
                            referenceId={editFormData?.id}
                        />
                    }
                </div>
            }
            {
                isDetail &&
                <div className="req-title-wrap flex center-y">
                    <div className="req-title">
                        <Tooltip title={_.get(editFormData,'title')}>
                            {_.get(editFormData,'title')}
                        </Tooltip>
                    </div>
                    <Popover content={<CopyArea/>} placement="bottom" zIndex={2001} trigger="click" open={showCopyPop} onOpenChange={setShowCopyPop}>
                        <Icon name={'fuzhi'} className={'copy-icon'}/>
                    </Popover>
                </div>
            }
            {
                !isDetail && <FormInput horizontal bind={'title'} label={'标题'} required value={_.get(editFormData, 'title')}
                                        onChange={v => setEditFormData(x => ({...x, title: v}))} maxLength={250}/>
            }
        </div>
        <div className="header-right flex center-y">
            {
                mode !== 'add' &&
                <div className="header-right-item flex center-y">
                    <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{color: '#165DFF'}}/>
                    <div className="header-right-info flex-y">
                        {
                            isDetail &&
                            <div className="header-right-value">
                                <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes,'userName','userAccount') || '无'}</div>
                            </div>
                        }
                        {
                            !isDetail &&
                            <FormInput horizontal required value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                                       onChange={v => setEditFormData(x => ({...x, currentUser: v})) }  />
                        }
                        <div className="header-right-field">
                            处理人
                        </div>
                    </div>
                </div>
            }
            {
                mode !== 'add' &&
                <Popover destroyTooltipOnHide={true}
                         content={<WorkFlowChangePop businessId={currentId} close={()=>{
                             setShowWorkFlowPop(false)
                             console.log('close')
                         }} refreshList={()=>{
                             refreshStatus()
                             refreshViewList()
                             refreshDetail(currentId)
                             updateDynamic()
                         }} funcCode={FUNC_CODE} businessType={'defect'}/>}
                         onOpenChange={visible => {
                             if(!visible){
                                 setShowWorkFlowPop(false)
                             }
                         }}
                         trigger={'click'}
                         placement="bottomRight"
                         open={showWorkFlowPop}
                >
                    <div className="header-right-item flex center-y status-wrap-item" onClick={()=>setShowWorkFlowPop(true)}>
                        <Icon name={'renling'} className={'header-right-icon'} style={{color: workflowStatusColor}}/>
                        <div className="header-right-info flex-y">
                            <div className="header-right-value">
                                <div className={'status-tag flex center'} style={{color: workflowStatusColor, border: `1px solid ${workflowStatusColor}`,padding: '0 8px', borderRadius: 4}}>
                                    {convertOptions(status, workFlowStatusOptions) || '-'}
                                </div>
                            </div>
                            <div className="header-right-field">
                                当前状态
                            </div>
                        </div>
                    </div>
                </Popover>
            }
        </div>
    </div>
}

export default DefectUpdateHeader;
import React from 'react'
import { Dialog } from 'rootnet-ui'

export default function WarningLog(props) {
  const { confirm, close } = props
  return (
    <Dialog
      header='提示'
      confirm={() => confirm(true)}
      cancel={close}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 0,
      }}
    >
      当前数据未保存 ? 是否保存 ?
    </Dialog >
  )
}

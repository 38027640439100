import _ from 'lodash'
import { isNil } from 'rootnet-core/format'

const topValue = [{
  activeFlag: "Y",
  departMentId: "0",
  departMentName: "北京根网科技有限公司",
  manager: "0",
  parentDept: ""
}]

function searchData(data, filterData, arr = []) {
  const ids = _.map(filterData, o => o.parentDept)
  _.forEach(data, o => {
    if (_.includes(ids, o.departMentId)) {
      const repetitionIds = _.map(arr, item => item.departMentId)
      if (!_.includes(repetitionIds, o.departMentId)) arr.push(o)
      if (o.parentDept !== '0') searchData(data, [o], arr)
    }
  })
  return arr
}

export function buildUserDeptTree(props) {
  const { allDeptRes, onNodeClick, multiple, setSelectDept, searchText, isAdd } = props
  const initList = _.map(allDeptRes, o => {
    const findItme = _.find(allDeptRes, item => item.departMentId === o.parentDept)
    if (o.parentDept === '') return { ...o, parentDept: '0' }
    else if (_.isNil(findItme)) return { ...o, parentDept: '0' }
    return o
  })
  const list = _.filter(initList, o => _.includes(o.departMentName, searchText))
  const allDepartmentList = isNil(searchText) ? _.concat(topValue, initList) : _.concat(topValue, searchData(initList, list, list))


  return getDepartmentChildren({
    allDepartmentList,
    department: '',
    multiple,
    onNodeClick,
    setSelectDept,
    isAdd,
  })
}

function getDepartmentChildren(props) {
  const { allDepartmentList, department, onNodeClick, setSelectDept, multiple, isAdd } = props
  const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
  const childDepartmentOptions = _.map(childDepartmentList, x => ({
    title: <div className={'dept-name-wrap flex center-y'} onClick={() => onNodeClick(`dept-${x.departMentId}`)}>
      <div>
        <span onClick={(e) => {
          e.stopPropagation()
          const childrenData = _.filter(allDepartmentList, item => (item.parentDept === x.departMentId))
          setSelectDept(o => {//allDepartmentList
            if (isAdd) {
              if (multiple) {
                if (!_.isEmpty(childrenData)) {
                  const keys = _.map(o, 'value')
                  return _.concat([], o, _.map(_.filter(allDepartmentList, item => (item.parentDept === '0' && !_.includes(keys, item.departMentId))), item => ({ text: item.departMentName, value: item.departMentId })))
                }
                const findItem = _.find(o, item => item.value === x.departMentId)
                return _.concat([], o, _.isNil(findItem) ? [{ text: x.departMentName, value: x.departMentId }] : [])
              } else {
                if (!_.isEmpty(childrenData)) return o
                return { text: x.departMentName, value: x.departMentId }
              }
            } else {
              if (multiple) {
                if (x.departMentId === '0') {
                  const keys = _.map(o, 'value')
                  return _.concat([], o, _.map(_.filter(allDepartmentList, item => (item.parentDept === '0' && !_.includes(keys, item.departMentId))), item => ({ text: item.departMentName, value: item.departMentId })))
                }
                const findItem = _.find(o, item => item.value === x.departMentId)
                return _.concat([], o, _.isNil(findItem) ? [{ text: x.departMentName, value: x.departMentId }] : [])
              } else {
                if (x.departMentId === '0') return o
                return { text: x.departMentName, value: x.departMentId }
              }
            }
          })
        }}>{x.departMentName}</span>
      </div>
    </div>,
    key: `dept-${x.departMentId}`,
    type: 'department',
    children: getDepartmentChildren({
      allDepartmentList,
      department: x.departMentId,
      onNodeClick,
      setSelectDept,
      multiple,
      isAdd
    }),
  }))
  return childDepartmentOptions
}
import _ from 'lodash'
import {uniqKeyFor} from "../../project_share/utils/utils";

// 全选\反选
export function selectAll(diffItem, allList,selectedList, parentName, childrenName, keyName) {
    let currList = selectedList
    handleChange(diffItem)
    return currList

    function handleChange(currItem, isParent = false) {
        if (_.includes(keyList(currList, keyName), _.get(currItem, keyName))) {
            // 删除父节点
            const parent = _.find(allList, item => (_.get(item, keyName) === _.get(currItem, parentName)))
            if (!_.isNil(parent) && _.includes(keyList(currList, keyName), _.get(parent, keyName))) {
                handleChange(parent, true)
                currList = _.filter(currList, x => x[keyName] !== parent[keyName])
            }
            // 删除结点
            if(_.includes(keyList(currList, keyName), _.get(currItem, keyName))){
                currList = _.filter(currList, x => x[keyName] !== currItem[keyName])
            }
            // 删除子节点
            if(isParent) return
            const childrenList = _.filter(currList, x => x[parentName] === currItem[keyName])
            _.forEach(childrenList, childItem => {
                handleChange(childItem)
            })

        } else {
            // 添加子节点
            const childrenList = _.filter(allList, item => (_.get(item, parentName) === _.get(currItem, keyName)) && !_.includes(keyList(currList, keyName), _.get(item, keyName)))
            _.forEach(childrenList, childItem => {
                handleChange(childItem)
            })
            // 添加结点
            if (!_.includes(keyList(currList, keyName), _.get(currItem, keyName))) {
                currList.push(currItem)
            }
            // 添加父结点
            const parent = _.find(allList, item => (_.get(item, keyName) === _.get(currItem, parentName)) && !_.includes(keyList(currList, keyName), _.get(item, keyName)))
            if (!_.isNil(parent)) {
                if (_.every(parent[childrenName], x => _.includes(keyList(currList, keyName), x[keyName])) && !_.includes(keyList(currList, keyName), parent[keyName])) {
                    handleChange(parent)
                }
            }
        }
    }

    function keyList(currList, keyName) {
        return _.map(currList, item => _.get(item, keyName))
    }

}

export function diffArray(arr1 = [], arr2 = [], keyName) {
    const [longList, shortList] = arr1.length > arr2.length ? [arr1, arr2] : [arr2, arr1];
    let diffArr = []
    _.forEach(longList, longListItem => {
        const key = _.get(longListItem, keyName)
        let isExit = false
        _.forEach(shortList, shortListItem => {
            if (_.get(shortListItem, keyName) === key) isExit = true
        })
        if (!isExit) diffArr.push(longListItem)
    })
    return diffArr
}

export function handleTree(treeData, childrenName, keyName) {
    const treeList = []
    add(_.cloneDeep(treeData))
    return treeList

    function add(data, level = 0, parent = null) {
        if (!_.isArray(data)) return treeList
        data.forEach(item => {
            item._id = uniqKeyFor()
            item._level = level
            item._parent = parent
            item._children = hasChildren(item)
            item._expand = true;
            item._childrenList = _.map(item[childrenName], x => x[keyName])
            treeList.push(item)
            if (item._children) return add(item[childrenName], level + 1, item[keyName])
        })

        function hasChildren(item) {
            return _.has(item, childrenName) && !_.isEmpty(item[childrenName])
        }
    }
}

export function handleTreeOptions(allList, currentList, keyName, parentKeyName, showTextName, disabledList = []){
    return _.map(currentList, item => {
        const childrenList = _.filter(allList, x => x[parentKeyName] === item[keyName])
        const currentItem = {value: item[keyName], text: item[showTextName], _disabled: _.includes(disabledList, item[keyName])}
        if(!_.isEmpty(childrenList)) _.assign(currentItem, {children: handleTreeOptions(allList, childrenList, keyName, parentKeyName, showTextName, disabledList )})
        return currentItem
    })
}

export function getDeptTreeOptions(deptOptionsRes){
    if(_.isEmpty(deptOptionsRes)) return []
    const firstLevelDepList = _.filter(deptOptionsRes, x => _.get(x, 'parentDept') === "")
    return handleTreeOptions(deptOptionsRes,firstLevelDepList,"departMentId","parentDept",'departMentName')
}
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Messager, Button, Loader } from 'rootnet-ui'
import './ConferenceRecordsDetailContent.scss'
import _ from "lodash";
import { Badge, Tabs, Tooltip } from "antd";
import Icon from "../../../../components/Icon";
import { useGet, usePost } from "rootnet-biz/es/hooks";
import ConferenceRecordsDetailHeader from "./conferenceRecordsDetailHeader/ConferenceRecordsDetailHeader";
import ConferenceRecordsDetailInfo from "./conferenceRecordsDetailInfo/ConferenceRecordsDetailInfo";
import ConferenceRecordsDetailRightPanel from "./conferenceRecordsDetailRightPanel/ConferenceRecordsDetailRightPanel";
import ChangeRecord from "../../../common/ChangeRecord";
import RequirementUploadArea
    from "../../../requirementMgt/requirementDetailDialog/requirementUploadArea/RequirementUploadArea";
import moment from "moment";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import ConferenceRecordsDetailTodo from "./conferenceRecordsDetailTodo/ConferenceRecordsDetailTodo";
import ConferenceRecordsDetailParticipant
    from "./conferenceRecordsDetailParticipant/ConferenceRecordsDetailParticipant";
import { isNil } from "../../../appraise/components/method";
import ConferenceRecordsDetailRelation from "./conferenceRecordsDetailRelation/ConferenceRecordsDetailRelation";
import ConferenceRecordsDetailProjectRelation
    from "./conferenceRecordsDetailProjectRelation/ConferenceRecordsDetailProjectRelation";
import ContentTemplateSelect from '../../../common/contentTemplateSelect';
import useContentTemplateNum from '../../../common/hooks/useContentTemplateNum';
import EditTipLog from '../../../common/contentTemplateSelect/editTipLog';

const { TabPane } = Tabs;

function ConferenceRecordsDetailContent(props) {
    const { isCS, Id: CSid } = window.external
    const { refreshViewList, currentInfo = {}, close, switchCurrentItem, showChildList, currentIndex } = props
    const { mode: initMode = 'detail' } = currentInfo
    const [mode, setMode] = useState(initMode)
    const { doFetch: getDetail } = useGet()
    const { data: originalParticipantList, doFetch: getParticipantList } = useGet()
    const [formData, setFormData] = useState()
    const [editFormData, setEditFormData] = useState()
    const { data: allUserRes } = useGet('/common/userinfo')
    const [infoError, setInfoError] = useState()
    const [auditError, setAuditError] = useState()
    const [activeKey, setActiveKey] = useState('info')
    const infoRichEditorRef = useRef()
    const [countDynamic, updateDynamic] = useReducer((x) => x + 1, 0)
    const [cid, setCid] = useState()
    const [id, setId] = useState()
    const [switchDisabled, setSwitchDisabled] = useState(false)
    const [contentLoading, setContentLoading] = useState(true)
    const [fileCount, setFileCount] = useState(0)
    const { doFetch: submitForm } = usePost()
    const [participantList, setParticipantList] = useState()

    const [openItem, setOpenItem] = useState(null)
    const [templateItem, setTemplateItem] = useState()
    const addContentTemplateNum = useContentTemplateNum()

    useEffect(() => {
        const { cid } = currentInfo || {}
        if (!_.isNil(cid)) {
            setCid(cid)
        }
    }, [currentInfo])

    useEffect(() => {
        if (!_.isNil(CSid)) {
            setCid(CSid)
        }
    }, [CSid])

    useEffect(() => {
        setEditFormData(formData)
    }, [formData])

    useEffect(() => {
        if (_.isNil(editFormData)) return
        setParticipantList(oldList => {
            return _.map(_.get(editFormData, 'sOurPerformer'), user => {
                const findItem = _.find(oldList, x => x.conferee === user)
                return _.isNil(findItem) ? ({
                    conferee: user,
                    attendance: '01',
                    explanation: null,
                }) : findItem
            })
        })
    }, [editFormData])

    const isDetail = useMemo(() => {
        return mode === 'detail'
    }, [mode])

    const refreshParticipantList = useCallback(() => {
        if (_.isNil(cid)) return
        getParticipantList('/conferencerecord/conference/list?recordId=' + cid).then(res => {
            setParticipantList(res)
        })
    }, [cid, getParticipantList])

    useEffect(() => {
        refreshParticipantList()
    }, [refreshParticipantList])

    const refreshDetail = useCallback((cid) => {
        if (_.isNil(cid)) return
        setSwitchDisabled(true)
        setContentLoading(true)
        getDetail('/conferencerecord/info?iid=' + cid).then(res => {
            let detail = { ...res }
            detail['sOurPerformer'] = _.split(_.get(res, 'sOurPerformer'), '^')
            setFormData(detail)
            setSwitchDisabled(false)
            setContentLoading(false)
            setCid(cid)
            setId(res.id)
            updateDynamic()
        }).catch(err => {
            setSwitchDisabled(false)
            setContentLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getDetail, updateDynamic])

    useEffect(() => {
        if (_.isNil(cid)) return
        setSwitchDisabled(true)
        setContentLoading(true)
        getDetail('/conferencerecord/info?iid=' + cid).then(res => {
            let detail = { ...res }
            detail['sOurPerformer'] = _.split(_.get(res, 'sOurPerformer'), '^')
            setFormData(detail)
            setSwitchDisabled(false)
            setContentLoading(false)
            setTimeout(() => {
                if (infoRichEditorRef.current) {
                    infoRichEditorRef.current.setContent(_.get(detail, 'sRecord') || '')
                }
            }, 1000)
            setCid(cid)
            setId(res.id)
            updateDynamic()
        }).catch(err => {
            setSwitchDisabled(false)
            setContentLoading(false)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [cid, getDetail])

    const switchMode = useCallback((resetDesc = false) => {
        if (resetDesc) {
            if (infoRichEditorRef.current) {
                infoRichEditorRef.current.setContent(_.get(formData, 'sRecord') || '')
            }
        }
        setMode(x => x === 'detail' ? 'edit' : 'detail')
    }, [formData])

    const needAudit = useMemo(() => {
        const sConferenceType = _.get(editFormData, 'sConferenceType')
        return _.includes(['DS', 'REQ', 'TC'], sConferenceType)
    }, [editFormData])

    const emptyAuditPassReason = useMemo(() => {
        return isNil(_.trim(_.get(editFormData, 'auditPassReason')))
    }, [editFormData])

    const canSubmit = useMemo(() => {
        if (!_.get(editFormData, 'sSubject')) return false
        if (needAudit) {
            if (_.some(_.values(auditError), x => x)) {
                return false
            }
            if (emptyAuditPassReason) return false
        }
        return !_.some(_.values(infoError), x => x)
    }, [editFormData, infoError, needAudit, auditError, emptyAuditPassReason])

    const participantListRequired = useMemo(() => {
        const filterList = _.filter(participantList, x => _.includes(['02', '03'], _.get(x, 'attendance')))
        return _.every(filterList, item => !isNil(_.get(item, 'explanation')))
    }, [participantList])

    const submit = useCallback(() => {
        if (!canSubmit) return
        if (!participantListRequired) {
            setActiveKey('participant')
            return Messager.show('请填写【参会情况】中的必填项')
        }
        if (JSON.stringify(editFormData) === JSON.stringify(formData)
            && infoRichEditorRef.current.getContent() === _.get(formData, 'sRecord')
            && JSON.stringify(participantList) === JSON.stringify(originalParticipantList)) {
            switchMode()
            return Messager.show('无变更')
        }
        let postParams = _.assign({
            ...editFormData,
            dOccurTimeBegin: dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(editFormData, 'dOccurTime'))) +
                ' ' + moment(toDate.str14ToDate(_.get(editFormData, 'dOccurTimeBegin'))).format('HH:mm') + ":00",
            dOccurTimeEnd: dateFormat('YYYY-MM-DD', toDate.str14ToDate(_.get(editFormData, 'dOccurTime'))) +
                ' ' + moment(toDate.str14ToDate(_.get(editFormData, 'dOccurTimeEnd'))).format('HH:mm') + ":00",
            sRecord: infoRichEditorRef.current.getContent(),
            sOurPerformer: _.join(_.get(editFormData, 'sOurPerformer'), '^'),
        })
        if (!needAudit) {
            postParams['auditPassFlag'] = null
            postParams['auditor'] = null
            postParams['storyId'] = null
            postParams['auditPassReason'] = null
        }
        submitForm('/conferencerecord/modify', postParams).then(() => {
            const participantParams = {
                recordId: cid,
                conferenceSituationList: _.map(participantList, x => ({
                    recordId: cid,
                    attendance: x.attendance,
                    conferee: x.conferee,
                    explanation: x.explanation,
                }))
            }
            submitForm('/conferencerecord/conference/edit', participantParams).then(() => {
                Messager.show('修改成功', { icon: 'success' })
                refreshDetail(cid)
                refreshParticipantList()
                switchMode()
                refreshViewList()
                addContentTemplateNum(templateItem)
            }).catch(err => Messager.show(err._message, { icon: 'error' }))
        }).catch(err => Messager.show(err._message, { icon: 'error' }))
    }, [canSubmit, editFormData, formData, switchMode, submitForm, refreshViewList, cid, refreshDetail, needAudit,
        participantListRequired, refreshParticipantList, participantList, originalParticipantList, addContentTemplateNum, templateItem])

    return <>
        <div className="conference-records-detail-content flex-y">
            <div className="mock-dialog-header flex">
                <div className="dialog-title">
                    会议纪要
                </div>
                <div className="mock-right-header flex center-y">
                    {
                        !isDetail &&
                        <ContentTemplateSelect
                            style={{ marginRight: 18 }}
                            type={'0'}
                            objectType={_.get(editFormData, 'sConferenceType')}
                            onChange={(v,item) => {
                                setOpenItem(item)
                            }}
                        />
                    }
                    <Tooltip title={isDetail ? '进入编辑' : '退出编辑'}>
                        <span>
                            <Icon name={'bianji2'} className={`header-edit-icon ${isDetail ? '' : 'edit-active-icon'}`} onClick={() => {
                                setTemplateItem(null)
                                switchMode(true)
                                setEditFormData(formData)
                                setParticipantList(originalParticipantList)
                            }} />
                        </span>
                    </Tooltip>
                    {
                        (!CSid) &&
                        <div className={'close-area flex center'} onClick={close}>
                            <Icon name={'quxiao'} className={'close-icon'} />
                        </div>
                    }
                </div>
            </div>
            <ConferenceRecordsDetailHeader {...{ editFormData, isDetail, setEditFormData }} />
            <div className="mock-detail-main-panel flex">
                <div className="mock-detail-left-panel">
                    <Tabs activeKey={activeKey} onChange={setActiveKey}>
                        <TabPane tab='描述' key={'info'} forceRender={true}>
                            <ConferenceRecordsDetailInfo {...{ infoRichEditorRef, isDetail, editFormData }} />
                        </TabPane>
                        <TabPane tab='跟进事项' key={'todo'} forceRender={true}>
                            <ConferenceRecordsDetailTodo {...{ cid, allUserRes, updateDynamic }} />
                        </TabPane>
                        <TabPane tab='参会情况' key={'participant'} forceRender={true}>
                            <ConferenceRecordsDetailParticipant {...{ allUserRes, isDetail, participantList, setParticipantList }} />
                        </TabPane>
                        <TabPane tab='关联需求' key={'relationReq'}>
                            <ConferenceRecordsDetailRelation {...{ cid }} />
                        </TabPane>
                        <TabPane tab='关联研发项目' key={'relationDevProject'}>
                            <ConferenceRecordsDetailProjectRelation {...{ cid }} />
                        </TabPane>
                        <TabPane tab={
                            <Badge count={fileCount} size="small" offset={[8, 0]} color={'#CCCCCC'}>
                                附件
                            </Badge>
                        } key={'doc'} forceRender={true}>
                            <div className="file-wrap">
                                <RequirementUploadArea funcCode={'2401'} {...{ id, mode, setFileCount, allUserRes, isCS }} />
                            </div>
                        </TabPane>
                        <TabPane tab='动态' key={'dynamic'}>
                            <div className={'dynamic-wrap'} key={countDynamic}>
                                {
                                    cid &&
                                    <ChangeRecord name={'会议纪要'} funcCode={'2401'} referenceId={cid} title={_.get(editFormData, 'sSubject')} />
                                }
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                <ConferenceRecordsDetailRightPanel {...{
                    isDetail, allUserRes, editFormData, setEditFormData, infoError, setInfoError,
                    auditError, setAuditError, needAudit, emptyAuditPassReason
                }} />
                {contentLoading && <Loader fill />}
            </div>
            <div className="mock-footer flex center-y">
                <div />
                {
                    _.isEmpty(showChildList) ? <div /> :
                        <div className="footer-switch flex center-y">
                            <div className={`footer-switch-item center ${_.includes([-1, 0], currentIndex) ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (switchDisabled) return
                                    if (!_.includes([-1, 0], currentIndex)) {
                                        switchCurrentItem('previous')
                                    }
                                }}>
                                <Icon name='xiangqian' />&nbsp;上一个
                            </div>
                            <div className={`footer-switch-item center ${currentIndex === showChildList?.length - 1 ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (switchDisabled) return
                                    if (!(currentIndex === showChildList?.length - 1)) {
                                        switchCurrentItem('next')
                                    }
                                }}>
                                下一个&nbsp;<Icon name='xianghou' />
                            </div>
                            <div className="list-num">
                                {currentIndex + 1}/{showChildList?.length}
                            </div>
                        </div>
                }
                <div className="btn-group flex">
                    {
                        isDetail && !CSid &&
                        <Button normal onClick={close}>关闭</Button>
                    }
                    {
                        !isDetail &&
                        <Button normal onClick={() => {
                            switchMode(true)
                            setEditFormData(formData)
                        }}>取消</Button>
                    }
                    {
                        !isDetail &&
                        <Button primary onClick={submit} disable={!canSubmit}>确认</Button>
                    }
                </div>
            </div>
        </div>
        {
            !_.isNil(openItem) &&
            <EditTipLog
                item={openItem} 
                close={()=>setOpenItem(null)}
                onChange={(v, item)=>{
                    setTemplateItem(item)
                    if (infoRichEditorRef.current) {
                        infoRichEditorRef.current.setContent(v)
                    }
                }}
            />
        }
    </>
}

export default ConferenceRecordsDetailContent;
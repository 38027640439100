import React, { useState, useMemo, useReducer, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { Popover, Tooltip } from 'antd'
import { Messager, DataGrid, Pagination } from 'rootnet-ui'
import { Icon } from '../../../../../components'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import WorkFlowChangePop from '../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { CustomizeFormInput } from '../../../../common/customizeOptions/CustomizeOptions'
import './index.scss'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import { usePost } from 'rootnet-biz/lib/hooks'
import { useFuncCode } from '../../../../common/commonMethod'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import { pathSearchFor } from '../../../../../utils/publicFun'
import ViewArea from '../../../../common/view/ViewArea'
import { Box } from '../../../../common/commonComponent'
import exportOption from '../../../../issueMgt/components/exportOption'
import IssueAdd from '../../../../issueMgt/components/issueAdd/IssueAdd'
import IssueDelete from '../../../../issueMgt/components/issueDelete'
import IssueDetailDialog from '../../../../issueMgt/components/issueDetailDialog/IssueDetailDialog'
import QaCheckDialog from '../../../../issueMgt/components/qaCheckDialog'
import ExportApiDialog from '../../../../common/ExportApiDialog'
import IssueViewSearchDialog from './components/issueViewSearchDialog'

const RELATED_ISSUE_URL = '/implement/project/related/issue'
const EXPORT_URL = '/issueSelf/exportExcel'
const CANCEL_RELATED_ISSUE_URL = '/implement/project/related/issue/del'
// const QUERY_URL = '/UserSetting/getWhereCondition'

const DEL_PERMISSION = '1901' //删除权限
const APPLY_PERMISSION = '1902' //申请权限
const QA_CHECK_PERMISSION = '1910' //QA检测功能权限
const PRIMARY_KEY = 'IssueId'
const ENTER_DETAIL_FIELD = 'ShortDesc'
// const DEAL_WITH_LOGO = 'dealType' //处理标识  'dealType',

const EDITABLE_FIELD = ['ShortDesc', 'qaCheckResult', 'EffectGrade', 'ISSUESTYPE', 'PersonCharge', 'Principal', 'Impact', 'WhyAndHow', 'OptStep', 'LogList', 'communicateNote', 'detail', 'testCheckAnalysis', 'testMeasures', 'testEvolve', 'rootAnalysis', 'rootMeasures', 'rootEvolve']
const RICH_TEXT_FIELD = ['Impact', 'WhyAndHow', 'OptStep', 'LogList', 'communicateNote', 'detail', 'testCheckAnalysis', 'testMeasures', 'testEvolve', 'rootAnalysis', 'rootMeasures', 'rootEvolve']

const PROBLEM_STATE_COLOR = {
  '01': '#3AA3FD',
  '02': '#F76A00',
  '03': '#F76A00',
  '04': '#00A950',
  '05': '#C9CDD4',
  '06': '#00A950',
  '07': '#C9CDD4',
  '08': '#F76A00',
  // '09': '#0000FF',
  '10': '#C9CDD4',
}

const ORDER_SEVERITY_COLOR = {
  '01': '#F60421',// 致命
  '02': '#F76A00',// 严重
  '03': '#165DFF',// 重要问题
  '04': '#93C36B',// 一般问题
  '05': '#97AFD0',// 改善建议
}

const DEAL_TYPE_COLOR = {
  '0': '#93C36B',
  '1': '#FF8C00',
  '2': 'red',
  '3': 'red',
}

const SPECIAL_FIELD_CONVERT = {
  'IssueStatus': (value, showValue) => <div className={'status-field flex center'} style={{ border: `1px solid ${PROBLEM_STATE_COLOR[value]}`, padding: '1px 12px 2px', color: PROBLEM_STATE_COLOR[value], borderRadius: 4 }}>
    {showValue}
  </div>,
  'EffectGrade': (value, showValue) => <div style={{ color: ORDER_SEVERITY_COLOR[value] }}>{showValue}</div>,
  'dealType': (value, showValue) => <div className='status-deal-type'><span style={{ backgroundColor: DEAL_TYPE_COLOR[value] }}></span>{showValue}</div>,
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, isFuncCodeDel, isFuncCodeQaCheck, editInfo, setEditInfo,
    setCurrentTableValue, updateShowList, setEditOldValue, workFlowId, setWorkFlowId, refreshList } = props
  const { editId = null, editField = null } = editInfo || {}

  return [
    { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      return {
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        fieldId: fieldItem.fieldId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
    }
    if (tableField === 'workflow_business.factorId') {
      const options = _.get(convertCollection, tableField)
      const workflowColor = _.get(_.find(options, x => x.value === v), 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={FUNC_CODE} businessType={'issue'} />}
        onOpenChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        open={o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowColor, border: `1px solid ${workflowColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => {
          setCurrentTableValue(handleValue)
          setEditOldValue(handleValue)
        }
        }
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            setCurrentTableValue(value)
          }
        }
        }
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (_.includes(RICH_TEXT_FIELD, fieldItem.fieldId)) {
      return <Tooltip title={<div className='issue-rich-text-val' dangerouslySetInnerHTML={{ __html: showValue }} />}><div className='rich-ellipsis-line'>{_.replace(showValue, /<\/?.+?>/g, '')}</div></Tooltip>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentInfo({ mode: 'detail', id: o.id })
        }}>{showValue}</div>
        <Icon name={'bianji2'} className="enter-detail-field-delete-icon" onClick={() => onEditFieldClick({ o, fieldItem })} />
        {
          isFuncCodeDel &&
          <Icon name={'shanchu2'} className="enter-detail-field-delete-icon"
            onClick={() => setCurrentInfo({ mode: 'delete', id: o.id })} />
        }
        {
          isFuncCodeQaCheck &&
          <Icon style={{ fontSize: 18 }} name={'QAyanshou'} className="enter-detail-field-delete-icon"
            onClick={() => setCurrentInfo({ mode: 'qa', id: o.id })} />
        }
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }

  function onEditFieldClick({ o, fieldItem }) {
    setEditInfo({
      editId: o?.id,
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 3,
  resizable: true,
  columns: options,
  virtualized: false,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 100
  }
}

const FUNC_CODE = '180188'

export default function ImpProjectIssue(props) {
  const { location, impProjectId, updateDynamic } = props;
  const { initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [currentInfo, setCurrentInfo] = useState(initId ? { id: initId, mode: 'detail' } : null)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(100)
  const [isLoading, setIsLoading] = useState(true)
  const [focusId, setFocusId] = useState()
  const [currentViewId, setCurrentViewId] = useState()
  const [sortConfig, setSortConfig] = useState()
  const [factor, setFactor] = useState()
  const [isFuncCodeDel, isFuncCodeApply, isFuncCodeQaCheck] = useFuncCode([DEL_PERMISSION, APPLY_PERMISSION, QA_CHECK_PERMISSION])
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const [workFlowId, setWorkFlowId] = useState()
  const [showRelationDialog, setShowRelationDialog] = useState()
  const [selectItems, setSelectItems] = useState([])
  const { doFetch } = usePost()

  // 表格编辑
  const [currentTableValue, setCurrentTableValue] = useState()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const [editOldValue, setEditOldValue] = useState()
  const { doFetch: editTable } = usePost()

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValue
    if (JSON.stringify(updateValue) === JSON.stringify(editOldValue) || _.isNil(updateValue)) {
      setEditInfo(null)
      setCurrentTableValue(null)
      return
    }
    let postParams = {
      // defectId: primaryId,
      id: editInfo['editId']
    }
    if (editInfo['fieldId'] === 'ShortDesc' && (_.isNil(_.trim(updateValue)) || _.trim(updateValue) === '')) {
      setEditInfo(null)
      setCurrentTableValue(null)
      return
    }
    postParams['fieldName'] = editInfo['fieldId']
    postParams['fieldValue'] = updateValue
    editTable('/issue/detailSingle', postParams).then(() => {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      setCurrentTableValue(null)
      Messager.show('修改成功', { icon: 'success' });
    })
  }, [editInfo, currentTableValue, editOldValue, editTable])

  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const replaceParams = useMemo(() => {
    return ({ 'issueProject.projectId': impProjectId, })
  }, [impProjectId])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor })

  const refreshLists = useCallback(() => {
    refreshList(replaceParams)
  }, [refreshList, replaceParams])

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: {
      setCurrentInfo, isFuncCodeDel, isFuncCodeQaCheck, editInfo, setEditInfo, setCurrentTableValue,
      updateShowList, setEditOldValue, workFlowId, setWorkFlowId, refreshList: refreshLists
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  const currentIndex = useMemo(() => {
    const currentId = _.get(currentInfo, 'id')
    if (_.isNil(currentId)) return 0
    return _.findIndex(showList, x => _.get(x, 'id') === currentId)
  }, [currentInfo, showList])

  const switchCurrentItem = useCallback((switchDirection = 'next') => {
    setCurrentInfo(old => {
      const cloneObj = { ...old }
      const newId = switchDirection === 'next' ? showList[currentIndex + 1].id : showList[currentIndex - 1].id
      cloneObj['id'] = newId
      setFocusId(newId)
      return cloneObj
    })
  }, [showList, currentIndex])

  const defaultOption = useMemo(() => {
    const notCustomList = _.filter(fieldList, x => x.custom === 'N')
    return _.map(notCustomList, o => ({
      header: o.fieldName,
      bind: o.fieldId,
    }))
  }, [fieldList])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const input = document.querySelector('.issue-data-grid').querySelector('input')
    if (!_.isNil(input)) {
      input.focus()
      input.click()
    }
  }, [editInfo])

  useEffect(() => {
    if (_.isNil(editInfo)) return
    const rootnetSelect = document.querySelector('.issue-data-grid').querySelector('.rootnet-select')
    if (rootnetSelect) {
      rootnetSelect.addEventListener('mousewheel', e => {
        e.cancelBubble = true
      })
    }
  }, [editInfo])

  const relatedIssue = useCallback((idList, flag = true) => {
    if (_.isEmpty(idList)) return Messager.show('无变更', { icon: 'info' })
    doFetch(`${RELATED_ISSUE_URL}?id=${impProjectId}`, idList)
      .then(() => {
        updateDynamic()
        flag && refreshLists()
        Messager.show('关联成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error', duration: 2000 }))
  }, [doFetch, impProjectId, refreshLists, updateDynamic])

  const cancelRelatedIssue = useCallback((items, flag = true, delFun) => {
    if (_.isEmpty(items)) return Messager.show('请选择后在进行操作', { icon: 'error' })
    const ids = _.map(items, o => o.id)
    doFetch(`${CANCEL_RELATED_ISSUE_URL}?id=${impProjectId}`, ids)
      .then(() => {
        if (_.isFunction(delFun)) {
          delFun()
          updateDynamic()
        }
        flag && updateDynamic()
        flag && refreshLists()
        Messager.show('取消关联成功', { icon: 'success', duration: 2000 })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [doFetch, impProjectId, refreshLists, updateDynamic])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn text='关联ISSUE' onClick={() => setShowRelationDialog('issue')} />
      <TextIconBtn text='取消关联ISSUE' onClick={() => cancelRelatedIssue(selectItems)} />
      <TextIconBtn icon='tianjia' text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
      <TextIconBtn icon='xiazai2' text='导出' onClick={() => setCurrentInfo({ mode: 'export' })} />
    </div>
  }, [cancelRelatedIssue, selectItems])

  return (
    <div className='imp-project-issue'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading} replaceParams={replaceParams}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'ISSUE管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <Table {...{ options, showList, dataGridList, focusId, selectItems, setSelectItems }} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.get(currentInfo, 'mode') === 'export' &&
        <ExportApiDialog
          name='ISSUE管理导出'
          action='post'
          url={EXPORT_URL}
          cuttingLine='TracerId'
          hint='一个issue可能对对应多条修复问题研发任务信息，导出修复研发任务相关字段时，issue数据会重复'
          option={exportOption}
          useName
          defaultOption={defaultOption}
          parameter={{ id: currentViewId, factor, menuCode: FUNC_CODE, type: '01', action: 'query' }}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'add' &&
        <IssueAdd close={() => setCurrentInfo(null)} refreshViewList={refreshLists} {...{ currentInfo, relatedIssue }} />
      }
      {
        _.get(currentInfo, 'mode') === 'delete' &&
        <IssueDelete id={_.get(currentInfo, 'id')} close={() => setCurrentInfo(null)} {...{ refreshList: refreshLists, cancelRelatedIssue }} />
      }
      {
        _.get(currentInfo, 'mode') === 'detail' &&
        <IssueDetailDialog close={() => setCurrentInfo(null)} refreshViewList={refreshLists} {...{ isFuncCodeApply, isFuncCodeQaCheck, currentInfo, switchCurrentItem, showChildList, currentIndex }} />
      }
      {
        _.get(currentInfo, 'mode') === 'qa' &&
        <QaCheckDialog close={() => setCurrentInfo(null)} {...{ id: _.get(currentInfo, 'id'), refresh: refreshLists }} />
      }
      {
        showRelationDialog === 'issue' &&
        <IssueViewSearchDialog close={() => setShowRelationDialog(null)} initId={_.map(showList, o => o.id)} {...{ relatedIssue, cancelRelatedIssue }} />
      }
    </div>
  )

  function Table(props) {
    const { options, showList, dataGridList, focusId, selectItems, setSelectItems } = props
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const onRowClick = useCallback((item) => {
      _.forEach(dataGridList, o => {
        return o._rowClass = item?.id === o?.id ? 'select_row' : ''
      })
      forceUpdate()
    }, [dataGridList])

    useEffect(() => {
      if (focusId) onRowClick({ id: focusId })
    }, [focusId, onRowClick])

    return <DataGrid option={options} data={showList} onRowClick={onRowClick} className='issue-data-grid' selection={selectItems} onSelectionChange={setSelectItems} />
  }

}

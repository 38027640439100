import React, { useMemo, useState, useCallback } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { DataGrid, Pagination, Dialog, Messager } from 'rootnet-ui'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import { Box } from '../../../../common/commonComponent'
import ViewArea from '../../../../common/view/ViewArea'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import RoleDetailDialog from './controls/roleDetailDialog'
import AssigningMenu from './controls/assigningMenu'
import { Icon } from '../../../../../components'
import AssigningUser from './controls/assigningUser'
import { strParams } from '../../../../../utils/publicFun'
import './index.scss'

const ENTER_DETAIL_FIELD = 'roleName'
const EDITABLE_FIELD = ['roleName']

const GET_SELECT_USER_URL = '/uac/role/select/user'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentInfo, handlePopId, setHandlePopId, getDefaultlist } = props

  const customColumns = []

  return [
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: fieldItem.fieldId !== ENTER_DETAIL_FIELD,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)

    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }
    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y space-between'}>
        <div className="left-enter-field-wrap flex center-y ">
          <div className="enter-detail-field flex">
            <div className="enter-detail-field-text" onClick={() => {
              setCurrentInfo({ mode: 'detail', id: o.id })
            }}>
              {showValue || '-'}
            </div>
          </div>
        </div>
        <Popover
          trigger={'click'}
          placement={'bottom'}
          destroyTooltipOnHide={true}
          open={_.get(o, 'id') === handlePopId}
          onOpenChange={visible => {
            if (visible) setHandlePopId(_.get(o, 'id'))
            else setHandlePopId(null)
          }}
          content={
            <div className={'role-handle-popover flex-y'}>
              <div className='role-handle-item flex center-y' onClick={() => {
                setCurrentInfo({ mode: 'assigningMenu', id: o.id })
                setHandlePopId(null)
              }}>
                <div className="handle-text">分配菜单</div>
              </div>
              <div className='role-handle-item flex center-y' onClick={() => {
                getDefaultlist(o.id)
                setCurrentInfo({ mode: 'assigningUsers', id: o.id })
                setHandlePopId(null)
              }}>
                <div className="handle-text">分配用户</div>
              </div>
            </div>
          }
        >
          <span>
            <Icon name={'gengduo'} className={'more-handle-icon'} />
          </span>
        </Popover>
      </div>
    }

  }

}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

const FUNC_CODE = '880502'

export default function RoleList(props) {
  const { currentId, setCurrentId, refreshRoleMenu, systemOpt, defaultCheckedKeys, roleMenuList, htmlWidth } = props
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState()
  const [currentInfo, setCurrentInfo] = useState(null)
  const [isDisable, setIsDisable] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const [handlePopId, setHandlePopId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { doFetch } = usePost()
  const { doFetch: getSelectUserFetch, data: defaultRes } = useGet()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const getDefaultlist = useCallback((id) => {
    getSelectUserFetch(`${GET_SELECT_USER_URL}?${strParams({ roleId: id })}`)
  }, [getSelectUserFetch])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns, columnsAppendParams: { setCurrentInfo, handlePopId, setHandlePopId, getDefaultlist }, optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showList = useMemo(() => {
    let showList = dataGridList || []
    if (!_.isNil(sortConfig)) {
      showList = _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, dataGridList])

  const onRowClick = useCallback((item) => {
    _.forEach(showList, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    refreshRoleMenu(item?.id)
    setCurrentId(item?.id)
  }, [setCurrentId, refreshRoleMenu, showList])

  const onAssigningUsers = useCallback((collect) => {
    if (isDisable) return
    setIsDisable(true)
    const postParams = _.concat([], ..._.map(collect, (list, k) => {
      return _.map(list, v => {
        const findItem = _.find(defaultRes, o => o.optId === v)
        return _.assign({}, {
          type: k,
          roleId: _.get(currentInfo, 'id'),
          optId: v,
        }, !_.isNil(findItem) && { id: _.get(findItem, 'id') })
      })
    }))
    doFetch('/uac/role/set/user', _.isEmpty(postParams) ? [{ roleId: _.get(currentInfo, 'id') }] : postParams)
      .then(() => {
        refreshList()
        refreshRoleMenu(currentId)
        setIsDisable(false)
        setCurrentInfo(null)
        Messager.show('分配成功', { icon: 'success' })
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [currentInfo, doFetch, refreshList, refreshRoleMenu, currentId, defaultRes, isDisable])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <TextIconBtn icon='tianjia' text='新增' onClick={() => setCurrentInfo({ mode: 'add' })} />
    </div>
  }, [])

  const defaultValue = useMemo(() => {
    if (_.isEmpty(defaultRes)) return []
    return _.groupBy(defaultRes, 'type')
  }, [defaultRes])

  return (
    <div className='role-list' style={{ width: htmlWidth }}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'角色管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
        <DataGrid option={options} data={showList} onRowClick={onRowClick} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {
        _.includes(['add', 'detail'], _.get(currentInfo, 'mode')) &&
        <RoleDetailDialog
          {...{ currentInfo }}
          refreshViewList={() => { refreshList(); refreshRoleMenu(currentId) }}
          close={() => setCurrentInfo(null)}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'assigningMenu' &&
        <AssigningMenu
          {...{ systemOpt, defaultCheckedKeys, roleMenuList }}
          roleID={_.get(currentInfo, 'id')}
          close={() => setCurrentInfo(null)}
          refresh={() => { refreshList(); refreshRoleMenu(currentId) }}
        />
      }
      {
        _.get(currentInfo, 'mode') === 'assigningUsers' &&
        <Dialog loading={isDisable} footerVisible={false} style={{ padding: 0 }} header='分配用户' cancel={() => setCurrentInfo(null)}  >
          <AssigningUser
            value={_.map(_.get(defaultValue, 'user', []), o => o.optId)}
            groupValue={_.map(_.get(defaultValue, 'group', []), o => o.optId)}
            departValue={_.map(_.get(defaultValue, 'depart', []), o => o.optId)}
            close={() => setCurrentInfo(null)}
            onChange={onAssigningUsers} />
        </Dialog>
      }
    </div >
  )
}

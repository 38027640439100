import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog, Button, DataGrid, Pagination} from 'rootnet-ui'
import './ResourceSelectReq.scss'
import {useGet} from "rootnet-biz/es/hooks";
import {Form, FormInput, Select, Radio} from "rootnet-edit";
import _ from 'lodash'
import convertGlobalConstOptions from "../../../../../common/ConvertGlobalConstOptions";
import {strParams} from "../../../../../../utils/publicFun";

function getColumns(props){
    const {selectedReqId} = props
    return [
        { header: '', bind: '',stretch: false,align: 'center', width: 40, convert: (v,o) => <Radio value={selectedReqId === o.id} /> },
        { header: 'ID', bind: 'interiorReqNo', tooltip: true, width: 120 },
        { header: '标题', bind: 'title', tooltip: true, width: 230 },
        { header: '状态', bind: 'reqStatus', tooltip: true, width: 80, convert: getStatus },
    ]
    function getStatus(v, o) {
        return `${v}-${o.ConstDisplayName}`
    }
}

function getOption(cols){
    return {
        selection:true,
        autoFill: true,
        virtualized: true,
        resizable: true,
        nilText: '-',
        emptyText: '-',
        columns: cols
    }
}

const INIT_PARAMS = {
    idOrTitle: null,
    versionId: null,
    status: ['R0', 'R1', 'R2', 'R3'],
    pageNum: 1,
    pageSize: 20
}

function ResourceSelectReq(props) {
    const {initReqId, outerSetReqId, close, outerSetItem} = props
    const {data: listRes, doFetch: getList, loading, error} = useGet()
    const [params, setParams] = useState(INIT_PARAMS)
    const {data: reqStatusRes} = useGet('/common/globalconst?globalConst=reqstatus')
    const {data: versionRes} = useGet('/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C')
    const [selectedRow, setSelectedRow] = useState([])
    const [selectedReqId, setSelectedReqId] = useState(initReqId)
    const [selectedItem, setSelectedItem] = useState()

    // const cleanSelected = useCallback(()=>{
    //     setSelectedRow([])
    //     setSelectedReqId(null)
    // },[])

    useEffect(()=>{
        if(_.isEmpty(selectedRow)) return
        setSelectedReqId(_.head(selectedRow).id)
    },[selectedRow])

    const {pageNum, pageSize, rows: list, total} = useMemo(()=>listRes || {},[listRes])

    const reqStatusOptions = useMemo(()=>{
        if(_.isNil(reqStatusRes)) return []
        return convertGlobalConstOptions(reqStatusRes)
    },[reqStatusRes])

    const versionOptions = useMemo(()=>{
        if(_.isNil(versionRes)) return []
        return convertGlobalConstOptions(versionRes)
    },[versionRes])

    useEffect(()=>{
        const searchParams = {
            type: 'Req',
            branch: 0,
            ...params,
        }
        getList('/test_case/selectconditionsinformation?'+strParams(searchParams))
    },[params, getList])

    const option = useMemo(()=>{
        return getOption(getColumns({selectedReqId}))
    },[selectedReqId])

    useEffect(()=>{
        if(_.isNil(list)) return
        setSelectedRow(old=>{
            if(_.isEmpty(old)) return []
            const findItem = _.filter(list, x => x.id === selectedReqId)
            if(_.isEmpty(findItem)) return old
            return findItem
        },[])
    },[list,selectedReqId])

    const onRowClick = useCallback((row)=>{
        setSelectedRow(old => {
            if(_.isEmpty(old)){
                setSelectedItem(row)
                return [row]
            }
            if(_.head(old).id === row.id){
                setSelectedReqId(null)
                setSelectedItem(null)
                return []
            }else{
                setSelectedItem(row)
                return [row]
            }
        })
    },[])

    const confirm = useCallback(()=>{
        if(outerSetReqId){
            outerSetReqId(selectedReqId)
        }
        if(outerSetItem){
            outerSetItem(selectedItem)
        }
        close()
    },[outerSetReqId,close,selectedReqId, outerSetItem, selectedItem])

    return <Dialog className={'resource-select-req'} header={`选择需求`} cancel={close} confirm={confirm}>
        <Options initParams={params} search={setParams} {...{reqStatusOptions,versionOptions}}/>
        <DataGrid data={list} option={option} loading={loading} error={error} selection={selectedRow} onRowClick={onRowClick}/>
        <Pagination jumper selector pageSize={pageSize} total={total} current={pageNum}
                    onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
    </Dialog>
}

function Options(props){
    const {initParams, search, reqStatusOptions, versionOptions} = props
    const [params, setParams] = useState(initParams)

    return <div className='c-options'>
        <Form value={params} onChange={setParams}>
            <FormInput label='ID或标题' bind='idOrTitle'/>
            <FormInput label='版本计划' bind='versionId' component={Select} options={versionOptions} search clear/>
            <FormInput label='状态' bind='status' component={Select} options={reqStatusOptions} search clear multiple/>
        </Form>
        <Button primary onClick={() => search({...params, pageNum: 1})}>查询</Button>
        <Button normal onClick={() => {
            search(INIT_PARAMS)
            setParams(INIT_PARAMS)
        }}>重置</Button>
        {/*<Button normal onClick={cleanSelected}>清除选中</Button>*/}
    </div>
}

export default ResourceSelectReq;
import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import { Tabs, Form } from 'antd'
import { Messager } from 'rootnet-ui'
import { gd, API2 } from 'rootnet-core/base'
import { isNil } from 'rootnet-core/format'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../utils/publicFun'
import Dialog from '../../components/Dialog'
import Feedback from './feedback'
import MyFeedback from './myFeedback'
import './index.scss'


const defaultParams = {
  status: '01',
  priority: 'B',
  customer: 'NBGJ',
  msgType: '9',
  principal: 'maxiaoxia',
  resource: 'RIMS',
  issueKind: '99'
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const PROBLEM_RESEARCH_URL = '/customerServiceInfoNew/resource?resource=RIMS'
const ADD_PROBLEM_URL = '/customerServiceInfoNew/addCustomerservice'

export default function ProblemFeedback(props) {
  const { visible, close } = props
  const [form] = Form.useForm();
  const [id, setId] = useState()
  const [active, setActive] = useState('1')
  const [params, setParams] = useState({})
  const [loading, setLoading] = useState(false)
  const [activeKey, setActiveKey] = useState()
  const [fileList, setFileList] = useState([]) //图片上传
  const { data, doFetch } = useGet()
  const { doFetch: getOnlyId } = useGet()
  const { doFetch: addFetch } = usePost()
  const { doFetch: getFetch } = usePost()

  const getId = useCallback(() => {
    getOnlyId('/test_case/productGetOnlyId').then(res => setId(res))
  }, [getOnlyId])

  const refresh = useCallback(() => {
    doFetch(PROBLEM_RESEARCH_URL)
    getId()
  }, [doFetch, getId])

  const addImage = useCallback((fileItem) => {
    const postParams = [{
      referenceId: id,
      funcCode: '20',
      uid: _.get(fileItem, 'response.uid') || _.get(fileItem, 'uid'),
      type: _.get(fileItem, 'type'),
      name: _.get(fileItem, 'response.name') || _.get(fileItem, 'name'),
      status: 'done',
      url: _.get(fileItem, 'response.url') || _.get(fileItem, 'url'),
    }]
    getFetch('/mapping/files/add', postParams)
      .then(() => Messager.show('上传成功', { icon: 'success' }))
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getFetch, id])

  const deleteFile = useCallback((fileItem) => {
    getFetch('/mapping/files/delete', [id]).then(() => {
      API2.post('/minioCommon/removeFile', { id: fileItem.response.uid, updateUser: gd.User.userAccount })
      Messager.show('删除成功', { icon: 'success' })
    }).catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [getFetch, id])

  const customRequest = useCallback((options) => {//0101
    const { onSuccess, onError, file } = options;
    const fileData = new FormData()
    fileData.append('file', file)
    let postParams = {
      bucketName: 'requirement',
      createUser: gd.User.userAccount,
    }
    API2.post('/minioCommon/uploadFile?' + strParams(postParams), fileData).then(res => {
      const fileItem = {
        uid: res.data.id,
        name: `${res.data.name}${res.data.ext}`,
        status: 'done',
        url: res.data.url,
      }
      onSuccess(fileItem)
      addImage(fileItem)
    }).catch(err => {
      onError()
      Messager.show(err._message, { icon: 'error' })
    })
  }, [addImage])

  const confirm = useCallback(() => {
    if (isNil(params?.issueDesc) && isNil(params?.reqDetail)) return Messager.show('请填写意见在进行提交', { icon: 'error' })
    if (isNil(params?.issueDesc)) return Messager.show('请填写问题简单描述', { icon: 'error' })
    if (isNil(params?.reqDetail)) return Messager.show('请填写详细描述', { icon: 'error' })
    setLoading(true)
    addFetch(ADD_PROBLEM_URL, { ...defaultParams, ...params, id, reqDetail: _.get(params, 'reqDetail', '').replace(/\n/g, '<br/>') }).then(() => {
      Messager.show('感谢您的反馈', { icon: 'success' })
      setLoading(false)
      setFileList([])
      refresh()
      close()
    }).catch((err => {
      setLoading(false)
      Messager.show(err._message, { icon: 'error' })
    }))
  }, [id, addFetch, close, refresh, params])

  useEffect(() => {
    if (visible) refresh()
    else setFileList([])
  }, [visible, refresh])

  return (
    <Dialog
      width={800}
      onOk={confirm}
      visible={visible}
      onCancel={close}
      okText='提交反馈'
      centered={false}
      footerReverse
      footer={active === '1'}
      className='problemResearch'
      loading={loading}
      afterClose={afterClose}
    >
      <Tabs className='problemResearch-tabs' activeKey={active} onChange={setActive} items={[
        {
          label: '意见反馈',
          key: '1',
          children: <Feedback {...{ form, params, setParams, fileList, setFileList, customRequest, deleteFile, getBase64 }} />,
        },
        {
          label: '我的反馈',
          key: '2',
          children: <MyFeedback {...{ activeKey, setActiveKey, data, getBase64 }} />,
        }
      ]}>
      </Tabs>
    </Dialog>
  )

  function afterClose() {
    setParams({ issueDesc: undefined, reqDetail: undefined })
    form.resetFields()
    setActiveKey([])
    setActive('1')
  }

}

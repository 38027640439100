import React, { useEffect, useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { DataGrid } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/lib/hooks'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import convertOptions from '../../../../common/ConvertOptions'
import { strParams } from '../../../../../utils/publicFun'
import convertGlobalConstOptions from '../../../../common/ConvertGlobalConstOptions'
import { Box } from '../../../../common/commonComponent'
import { TextIconBtn } from '../../../../common/TextIconBtn'
import CustomerInfoDetail from './customerInfoDetail'
import './index.scss'

function getColumns(props) {
  const { customerOptions, statusOptions, allUserRes, setCurrentInfo } = props
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40, stretch: false },
    {
      header: '客户名称', bind: 'customer', width: 200, tooltip: true, sortable: true,
      convert: (v, o) => <div className={'enter-detail-field-wrap flex center-y'}>
        <div className="enter-detail-field" onClick={() => setCurrentInfo({
          id: o.id,
          mode: 'detail'
        })}>{convertOptions(v, customerOptions)}</div>
      </div>
    },
    { header: '状态', bind: 'status', width: 100, tooltip: true, sortable: true, convert: v => convertOptions(v, statusOptions) },
    { header: '客服人员', bind: 'customerService', width: 150, tooltip: true, sortable: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '创建人', bind: 'createUser', width: 100, tooltip: true, sortable: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '创建时间', bind: 'createTime', width: 150, tooltip: true, sortable: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
    { header: '修改人', bind: 'updateUser', width: 100, tooltip: true, sortable: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount') },
    { header: '修改时间', bind: 'updateTime', width: 150, tooltip: true, sortable: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
  ]
}

function getOptions(cols) {
  return {
    nilText: '-',
    emptyText: '-',
    fixedLeft: 1,
    resizable: true,
    columns: cols,
    virtualized: true,
    autoFill: true,
  }
}

const OPTIONS_URLS = [
  '/customer/customerList'
]

const GLOBAL_CONST_OPTIONS_URLS = [
  'common/globalconst?globalConst=STATE',
]

export default function CustomerInformation(props) {
  const { systemId, allUserRes } = props
  const [currentInfo, setCurrentInfo] = useState()
  const { data, doFetch: getList } = useGet()
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)

  const refreshList = useCallback(() => {
    if (_.isNil(systemId)) return
    getList(`/story/getbugProjectSystem?${strParams({ systemId })}`)
  }, [getList, systemId])

  useEffect(() => {
    refreshList()
  }, [refreshList])

  const [customerOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1] = optionsRes
    return [
      _.map(d1, x => ({ text: x.tCustname, value: x.tCustid, tag: `${x.tCustname}${x.tCustid}` }))
    ]
  }, [optionsRes])

  const [statusOptions] = useMemo(() => {
    if (_.isEmpty(globalConstOptionsRes)) return []
    return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
  }, [globalConstOptionsRes])

  const options = useMemo(() => {
    return getOptions(getColumns({ customerOptions, statusOptions, allUserRes, setCurrentInfo }))
  }, [customerOptions, statusOptions, allUserRes])

  const extra = useMemo(() => {
    return <TextIconBtn icon={'tianjia'} text='添加' className={'add-btn'} onClick={() => setCurrentInfo({ mode: 'add' })} />
  }, [])

  return (
    <div className={'customer-information-wrap flex'}>
      <Box title={'客户信息'} className='flex-y x-card-singlegrid' extra={extra} data={data}>
        <DataGrid option={options} data={data} />
      </Box>
      {
        _.includes(['add', 'detail'], _.get(currentInfo, 'mode')) &&
        <CustomerInfoDetail
          close={() => setCurrentInfo(null)}
          refreshViewList={refreshList}
          {...{ currentInfo, systemId, allUserRes, customerOptions, statusOptions, }}
        />
      }
    </div>
  )
}

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './DevProjectStage.scss'
import { useGet, usePost } from "rootnet-biz/es/hooks";
import _ from "lodash";
import convertGlobalConstOptions from "../../../../common/ConvertGlobalConstOptions";
import { Button, Messager } from "rootnet-ui";
import { Alert, DatePicker, Form, Input, InputNumber, Popover, Select, Table, Tooltip, Checkbox } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import convertOptions from "../../../../common/ConvertOptions";
import { arrayMoveImmutable } from "array-move";
import { DevProjectStageEditContext } from "../../../../common/Context";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import moment from "moment";
import PersonSelect from "../../../../common/personSelect/PersonSelect";
import DevProjectStageUpdateDialog from "./devProjectStageUpdateDialog/DevProjectStageUpdateDialog";
import DelMessage from "../../../../../components/DelMessage";
import { Icon } from "../../../../../project_share/components";
import RequirementTemplateListDialog
    from "../../../../requirementMgt/requirementDetailDialog/requirementTemplateListDialog/RequirementTemplateListDialog";
import DevProjectStageUploadArea
    from "./devProjectStageUpdateDialog/devProjectStageUploadArea/DevProjectStageUploadArea";
import { API1 } from "../../../../../base/task";
import { uniqKeyFor } from "../../../../../project_share/utils/utils";
import ConferenceRecordsDetailDialog
    from "../../../../conferenceMgt/conferenceRecords/conferenceRecordsDetail/ConferenceRecordsDetailDialog";
import ConferenceQueryDialog
    from "../../../../requirementMgt/requirementDetailDialog/requirementStage/conferenceQueryDialog/ConferenceQueryDialog";
import { isNil } from '../../../../appraise/components/method';
import gd from '../../../../../base/global';
import DevstatusExplainDialog from './devstatusExplainDialog';
import ImportApiDialog from '../../../../common/ImportApiDialog';
import { strParams } from '../../../../../utils/publicFun';
import ReviewMinutesDetailDialog from '../../../../conferenceMgt/reviewMinutesMgt/controls/reviewMinutesDetailDialog';

const { RangePicker } = DatePicker

const YNOptions = [
    {
        label: '是',
        text: '是',
        value: 'Y',
    },
    {
        label: '否',
        text: '否',
        value: 'N',
    },
]

const ranges = {
    '今天': [
        moment(),
        moment()
    ],
    '本周': [
        moment().startOf('week'),
        moment().endOf('week')
    ],
    '本月': [
        moment().startOf('month'),
        moment().endOf('month')
    ],
    // '本季度': [
    //     moment().startOf('quarter'),
    //     moment().endOf('quarter')
    // ],
    '本年': [
        moment().startOf('year'),
        moment().endOf('year')
    ],
    '最近一周': [
        moment(new Date()).subtract(1, 'week'),
        moment(new Date())
    ],
    '最近一个月': [
        moment(new Date()).subtract(1, 'month'),
        moment(new Date())
    ],
    '最近三个月': [
        moment(new Date()).subtract(1, 'quarter'),
        moment(new Date())
    ],
    '最近一年': [
        moment(new Date()).subtract(1, 'year'),
        moment(new Date())
    ],
}

const IMPORT_URL = '/userProject/stage/field/upload'
const TEMPLATE_URL = '/userProject/stage/field/download'

const GLOBAL_CONST_OPTIONS_URLS = [
    'common/globalconst?globalConst=devSegment',
    // 'common/globalconst?globalConst=keyNodes',
    '/common/globalconst?globalConst=DevPlanStatus',
    '/common/globalconst?globalConst=devProjectOverdue',
]

const delayTips = `未完成：
进展正常：【计划完成】- 当前日期 >= 2天
临近需关注：0天 <= 【计划完成】- 当前日期 < 2天
延期：【计划完成】- 当前日期 < 0天

已完成：
空：【计划完成】>=【实际完成】 
延期：【计划完成】<【实际完成】
`



const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

// 暂时所有人都有编辑权限
// const editAuth = true

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
function getColumns(props) {//keyNodesOptions
    const { devSegmentOptions, statusOptions, setCurrentStageInfo, allUserRes, updateOuterFile,
        setShowList, projectId, overdueOptions, setShowDetailDialog, setConferenceInfo, editAuth, isShow } = props
    return _.concat(editAuth ? [
        {
            title: '',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            fixed: 'left',
            render: () => <DragHandle />,
        },
    ] : [],
        _.compact([
            {
                title: '#',
                width: 40,
                align: 'center',
                fixed: 'left',
                render: (value, obj, index) => index + 1
            },
            {
                title: <span style={{ marginLeft: 12 }}>阶段</span>,
                dataIndex: 'stage',
                key: 'stage',
                width: 130,
                fixed: 'left',
                // editable: true,
                className: 'stage-style-pad-lef',
                render: (value) => convertOptions(value, devSegmentOptions)
            },
            {
                title: <span style={{ marginLeft: 12 }}>关键节点</span>,
                dataIndex: 'keyNodes',
                key: 'keyNodes',
                width: 90,
                fixed: 'left',
                className: 'stage-style-pad-lef',
                // editable: true,
            },
            {
                title: <span style={{ marginLeft: 12 }}>序号</span>,
                dataIndex: 'activityNum',
                key: 'activityNum',
                width: 50,
                fixed: 'left',
                className: 'stage-style-pad-lef',
            },
            {
                title: <span style={{ marginLeft: 12 }}>任务名称</span>,
                dataIndex: 'title',
                key: 'title',
                width: 200,
                fixed: 'left',
                render: (value, obj, index) => <Tooltip placement="topLeft" title={value}>
                    <span className={'task-name-header'} style={{ color: '#5477ff', cursor: 'pointer', paddingLeft: obj.isParent === 'Y' ? 0 : 24 }}
                        onClick={() => setCurrentStageInfo({
                            stageId: _.get(obj, 'id'),
                            mode: 'detail',
                            stageObj: obj,
                            stageIndex: index,
                            isCoding: _.get(obj, '_coding'),
                        })}>{value}</span>
                </Tooltip>,
            },
            {
                title: <span style={{ marginLeft: 12 }}>子任务</span>,
                dataIndex: 'sonTask',
                key: 'sonTask',
                width: 150,
                editable: isShow,
            },
            {
                title: <span style={{ marginLeft: 12 }}>里程碑</span>,
                dataIndex: 'milestoneFlag',
                key: 'milestoneFlag',
                width: 80,
                editable: isShow,
                render: (value) => {
                    if (value === 'Y') return '是'
                    return null
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>状态</span>,
                dataIndex: 'status',
                key: 'status',
                width: 120,
                editable: isShow,
                render: (value) => {
                    const color = convertOptions(value, statusOptions, 'color')
                    return <span style={{ color: color, border: `1px solid ${color}`, padding: '2px 8px', borderRadius: 4, fontSize: 12 }}>
                        {convertOptions(value, statusOptions)}
                    </span>
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>
                    风险标识
                    <Tooltip title={delayTips} overlayStyle={{ width: 310, maxWidth: 310, whiteSpace: 'pre-wrap' }}>
                        <span>
                            <Icon name={'bangzhu'} style={{ fontSize: 14, color: '#5477FF', marginLeft: 2 }} />
                        </span>
                    </Tooltip>
                </span>,
                dataIndex: 'overdueFlag',
                key: 'overdueFlag',
                width: 100,
                render: value => {
                    const color = convertOptions(value, overdueOptions, 'color')
                    return <div className={'dot-value-wrap flex center-y'} style={{ color: color, marginLeft: 12 }}>
                        <div className="dot" style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: color, marginRight: 6 }} />
                        {convertOptions(value, overdueOptions)}
                    </div>
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>裁剪/合并说明</span>,
                dataIndex: 'cancelDescription',
                key: 'cancelDescription',
                width: 120,
                editable: isShow,
                render: v => {
                    return <Tooltip
                        overlayClassName='stage-text-display-tooltip' title={<div className='tooltip-text'>
                            {v}
                        </div>}>
                        <div className='cell-item-text-display'>{v}</div>
                    </Tooltip>
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>负责人</span>,
                dataIndex: 'ownerName',
                key: 'ownerName',
                width: 120,
                // render: value => convertOptions(value, allUserRes, 'userName', 'userAccount')
            },
            {
                title: <span style={{ marginLeft: 12 }}>计划开始 - 计划完成</span>,
                dataIndex: 'planInterval',
                key: 'planInterval',
                width: 240,
                editable: isShow,
                render: (v, o) => {
                    const { startTimePlan, completeTimePlan } = o
                    if (isNil(startTimePlan) && isNil(completeTimePlan)) return ''
                    if (!isNil(startTimePlan) && isNil(completeTimePlan)) return `${dateFormat('YYYY-MM-DD', toDate.str14ToDate(startTimePlan))} - `
                    if (isNil(startTimePlan) && !isNil(completeTimePlan)) return ` - ${dateFormat('YYYY-MM-DD', toDate.str14ToDate(completeTimePlan))}`
                    return `${dateFormat('YYYY-MM-DD', toDate.str14ToDate(startTimePlan))} - ${dateFormat('YYYY-MM-DD', toDate.str14ToDate(completeTimePlan))}`
                }
            },
            // {
            //     title: <span style={{ marginLeft: 12 }}>计划开始</span>,
            //     dataIndex: 'startTimePlan',
            //     key: 'startTimePlan',
            //     width: 130,
            //     editable: true,
            //     render: value => dateFormat('YYYY-MM-DD', toDate.str14ToDate(value)),
            // },
            // {
            //     title: <span style={{ marginLeft: 12 }}>计划完成</span>,
            //     dataIndex: 'completeTimePlan',
            //     key: 'completeTimePlan',
            //     width: 130,
            //     editable: true,
            //     render: value => dateFormat('YYYY-MM-DD', toDate.str14ToDate(value)),
            // },
            {
                title: <span style={{ marginLeft: 12 }}>投入工期</span>,
                dataIndex: 'workloadPlan',
                key: 'workloadPlan',
                width: 120,
                render: value => <span style={{ marginLeft: 12 }}>{value}</span>
            },
            {
                title: <span style={{ marginLeft: 12 }}>交付件</span>,
                dataIndex: 'delivery',
                key: 'delivery',
                width: 260,
                editable: isShow,
                render: v => {
                    return <Tooltip
                        overlayClassName='stage-text-display-tooltip' title={<div className='tooltip-text'>
                            {v}
                        </div>}>
                        <div className='cell-item-text-display'>{v}</div>
                    </Tooltip>
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>交付件配置库路径</span>,
                dataIndex: 'notesUrl',
                key: 'notesUrl',
                width: 200,
                editable: isShow,
                render: v => {
                    return <Tooltip
                        overlayClassName='stage-text-display-tooltip' title={<div className='tooltip-text'>
                            {v}
                        </div>}>
                        <div className='cell-item-text-display'>{v}</div>
                    </Tooltip>
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>实际完成</span>,
                dataIndex: 'completeTimeActual',
                key: 'completeTimeActual',
                width: 130,
                editable: isShow,
                render: value => dateFormat('YYYY-MM-DD', toDate.str14ToDate(value)),
            },
            {
                title: <span style={{ marginLeft: 12 }}>实际投入</span>,
                dataIndex: 'workloadActual',
                key: 'workloadActual',
                width: 120,
                editable: isShow,
            },
            {
                title: <span style={{ marginLeft: 12 }}>当前进展描述</span>,
                dataIndex: 'memo',
                key: 'memo',
                width: 200,
                editable: isShow,
                render: v => {
                    return <Tooltip
                        overlayClassName='stage-text-display-tooltip' title={<div className='tooltip-text'>
                            {v}
                        </div>}>
                        <div className='cell-item-text-display'>{v}</div>
                    </Tooltip>
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>会议纪要</span>,
                dataIndex: 'conferenceList',
                key: 'conferenceList',
                width: 150,
                render: (value, obj) => {
                    return <div className={'conference-field flex-y'}>
                        {
                            _.map(_.get(obj, 'conferenceList'), item => <div className={'conference-item'} key={uniqKeyFor()}>
                                <div className="conference-text" onClick={() => {
                                    setShowDetailDialog({
                                        module: item.sconferenceType === 'AM' ? 'reviewMinutes' : 'conference',
                                        id: _.get(item, 'iid')
                                    })
                                }}>
                                    {_.get(item, 'ssubject')}
                                </div>
                            </div>)
                        }
                    </div>
                }
            },
            isShow && {
                title: <span style={{ marginLeft: 12 }}>关联会议</span>,
                dataIndex: 'relateConference',
                key: 'relateConference',
                width: 75,
                fixed: 'right',
                render: (value, obj) => {
                    return <Icon name={'guanlian'} className={'relate-conference-icon'} onClick={() => {
                        setConferenceInfo({
                            id: obj.id,
                            initList: _.map(_.get(obj, 'conferenceList'), 'iid')
                        })
                    }
                    } />
                }
            },
            {
                title: <span style={{ marginLeft: 12 }}>附件</span>,
                dataIndex: 'relateDocument',
                key: 'relateDocument',
                width: 60,
                fixed: 'right',
                render: (value, obj, index) => {
                    let stageDocList = value ? _.split(value, ',') : []
                    return <Popover content={<DevProjectStageUploadArea id={projectId} stageDocList={stageDocList} stageObj={obj} stageIndex={index} funcCode={'36'}
                        {...{ allUserRes, updateOuterFile, setShowList, isDel: isShow, isUpload: isShow }} />}>
                        <span className={'flex center relate-document-icon-wrap'} style={{ marginLeft: 12 }}>
                            <Icon name={'lianjie'} style={{ fontSize: 14 }} className={'relate-document-icon'} />({_.size(stageDocList)})
                        </span>
                    </Popover>
                }
            },
        ]))
}

function DevProjectStage(props) {
    const { allUserRes, currentId: projectId, updateOuterFile, projectInfo, isPMM, isPo, keyNodesOptions, upDateDetail, isFinanceCoding, isShow } = props //isImport
    const { doFetch: getList, } = useGet()//loading
    const [showList, setShowList] = useState([])
    const [defaultList, setDefaultList] = useState()
    const { data: globalConstOptionsRes } = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const [currentStageInfo, setCurrentStageInfo] = useState()
    const { doFetch: updateRow } = usePost()
    const [selectedList, setSelectedList] = useState()
    const [showDialog, setShowDialog] = useState()
    const [form] = Form.useForm()
    const { doFetch: updateConference } = useGet()
    const [showDetailDialog, setShowDetailDialog] = useState()
    const [conferenceInfo, setConferenceInfo] = useState()
    const [schedule, setSchedule] = useState()
    const [statusExplain, setStatusExplain] = useState()
    const [cancelDescription, setCancelDescription] = useState('')
    const [myTask, setMyTask] = useState(false)
    // const [pageLoading, setPageLoading] = useState(false)
    const { userprojectType, projectName } = useMemo(() => projectInfo || {}, [projectInfo])
    const editAuth = useMemo(() => (isPMM || isPo), [isPMM, isPo])
    // const importCondition = useMemo(() => ((isPMM || isPo) && isImport !== 'Y'), [isPMM, isPo, isImport])
    const [isRefresh, setIsRefresh] = useState(false)

    const [devSegmentOptions, statusOptions, overdueOptions] = useMemo(() => {
        if (_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    }, [globalConstOptionsRes])

    const refresh = useCallback(() => {
        getList('/userProject/stage/list?projectId=' + projectId).then(res => {
            setSchedule(_.pick(res, ['complete', 'milestone', 'warn']))
            updateConference('/userProject/stage/recordList?projectId=' + projectId).then(conferenceRes => {
                const handledList = _.map(res.userProjectStageVoList, item => ({
                    ...item,
                    conferenceList: _.get(_.find(conferenceRes, x => x.id === item.id), 'recordList') || []
                }))
                // setShowList(handledList)
                setDefaultList(handledList)
            }).catch((err) => {
                // setShowList(res.userProjectStageVoList)
                setDefaultList(res.userProjectStageVoList)
                Messager.show(err._message, { icon: 'error' })
            })
        }).catch((err) => {
            Messager.show(err._message, { icon: 'error' })
        })
    }, [getList, projectId, updateConference])

    const myTaskList = useMemo(() => {
        const data = _.map(defaultList, o => o)
        const filterData = _.filter(data, o => o.owner === gd.User.operator_id)
        const pidKeys = _.map(_.groupBy(filterData, 'parentId'), (arr, key) => key)
        const findDatas = _.map(pidKeys, v => {
            return _.find(defaultList, o => o.id === v)
        })
        const sortData = _.orderBy(_.concat(filterData, findDatas), 'serial', 'asc')
        return sortData
    }, [defaultList])

    useEffect(() => {
        if (_.isEmpty(defaultList)) {
            setShowList([])
            // setPageLoading(false)
            return
        }
        if (myTask) {
            // setPageLoading(false)
            setShowList(initList(myTaskList))
        } else {
            // setPageLoading(false)
            setShowList(initList(defaultList))
        }
        function initList(data) {
            const index = _.findIndex(data, o => o.title === '基线发布')
            return _.map(data, (o, i) => {
                if (i >= index) return _.assign({}, o, { '_coding': true })
                return _.assign({}, o, { '_coding': false })
            })
        }
    }, [myTask, defaultList, myTaskList])

    useEffect(() => {
        refresh()
    }, [refresh])

    const selectedMessage = useMemo(() => {
        return <div className={'selected-message flex center-y'}>
            已选择
            <span className={'selected-count'}>{_.size(selectedList)}</span>
            项
            <span className={'bulk-del-text'} onClick={() => setShowDialog('delete')}>删除</span>
        </div>
    }, [selectedList])

    // 可编辑单元格
    const EditableRow = useCallback(({ index, ...restProps }) => {
        const currentIndex = _.findIndex(showList, x => x.id === restProps['data-row-key'])
        return <Form form={form} component={false}>
            <DevProjectStageEditContext.Provider value={{ form: form }}>
                <SortableItem index={currentIndex} {...restProps} />
            </DevProjectStageEditContext.Provider>
        </Form>
    }, [showList, form])

    const updateEditRow = useCallback((updateItem, key) => {
        const newItem = {
            ..._.omit(updateItem, ['planInterval']),
            workloadActual: _.get(updateItem, 'workloadActual') ? N2Float(_.get(updateItem, 'workloadActual')) : null,
        }

        updateRow('/userProject/stage/update', newItem).then(() => {
            setIsRefresh(true)
            setDefaultList(oldList => {
                const newList = _.map(oldList, item => {
                    if (item.id !== updateItem.id) return item
                    return ({
                        ...item,
                        ...updateItem
                    })
                })
                return _.sortBy(newList, 'serial')
            })
            // if (_.includes(['completeTimeActual', 'planInterval', 'status'], key)) refresh()
            Messager.show('修改成功', { icon: 'success' })
        }).catch((err) => {
            setDefaultList(x => x)
            Messager.show(err._message, { icon: 'error' })
        })
    }, [updateRow])

    const handleSave = useCallback((row, key, flag = false) => {
        if (!flag && key === 'status' && _.includes(['09', '10'], row[key])) {
            setStatusExplain(_.assign({}, row, { startTimePlan: null, completeTimePlan: null, completeTimeActual: null }))
            setCancelDescription(_.get(row, 'cancelDescription'))
            return
        }
        // const index = _.findIndex(showList, x => x.id === row.id)
        let updateItem = _.clone(row)
        let completeTimeActual = _.get(updateItem, 'completeTimeActual')
        if (_.isNil(completeTimeActual) && _.get(updateItem, 'status') === '03') {
            completeTimeActual = dateFormat('YYYYMMDD', new Date()) + '000000'
        }
        updateItem['completeTimeActual'] = completeTimeActual
        updateEditRow(updateItem, key)
        // setShowList(oldList => {
        //     const newList = _.map(oldList, (item, i) => {
        //         if (i !== index) return item
        //         return ({
        //             ...item,
        //             ...updateItem
        //         })
        //     })
        //     return _.sortBy(newList, 'serial')
        // })
    }, [updateEditRow])

    const submitExplain = useCallback(() => {
        if (_.size(_.trim(cancelDescription)) <= 0) return Messager.show('请填写裁剪/合并说明！', { icon: 'error' })
        handleSave(_.assign({}, statusExplain, { cancelDescription }), 'status', true)
        setCancelDescription('')
        setStatusExplain(null)
    }, [handleSave, statusExplain, cancelDescription])


    const [editingId, setEditingId] = useState(null)


    useEffect(() => {
        const vApp = document.querySelector('.v-app')
        const dom = vApp
        if (dom) {
            dom.addEventListener('click', () => {
                setEditingId(null)
            })
            return () => dom.removeEventListener('click', () => { })
        }
    }, [])

    const columns = useMemo(() => {
        return _.map(getColumns({
            devSegmentOptions, keyNodesOptions, statusOptions, setCurrentStageInfo, allUserRes,
            updateOuterFile, setShowList, projectId, overdueOptions, setShowDetailDialog, setConferenceInfo, editAuth, isShow
        }), col => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    editAuth,
                    devSegmentOptions,
                    keyNodesOptions,
                    statusOptions,
                    editingId,
                    setEditingId,
                    isFinanceCoding,
                })
            }
        })
    }, [devSegmentOptions, keyNodesOptions, statusOptions, allUserRes, handleSave, projectId, updateOuterFile, overdueOptions, editAuth, editingId, isFinanceCoding, isShow])

    const exportList = useCallback(() => {
        API1.downloadGet(`/userProject/stage/list/export?${strParams({ projectName, projectId })}`)
    }, [projectId, projectName])

    const exportOption = useMemo(() => {
        const projectColumns = [{ header: '项目ID', bind: 'projectId', aligh: 'left', width: 110, tooltip: true, sortable: true, }]
        const columns = getColumns({
            devSegmentOptions, keyNodesOptions, statusOptions, setCurrentStageInfo, allUserRes,
            updateOuterFile, setShowList, projectId, overdueOptions, setShowDetailDialog, setConferenceInfo, editAuth
        })
        const newColumns = _.map(columns, o => {
            return _.assign({}, {
                header: o?.title,
                bind: o?.key,
                aligh: 'left',
                width: o.width,
                tooltip: true,
                sortable: true,
            }, !_.isNil(o?.render) && { convert: o.render, converts: o.render })
        })

        return {
            resizable: true,
            virtualized: false,
            fixedLeft: 1,
            fixedRight: 1,
            nilText: '-',
            emptyText: '-',
            columns: _.concat(projectColumns, newColumns),
        }
    }, [devSegmentOptions, keyNodesOptions, statusOptions, allUserRes, updateOuterFile, projectId, overdueOptions, editAuth])

    return <div className={'dev-project-stage-list'}>
        <div className="handle-header-wrap flex center-y">
            <div className="right-header-wrap">
                <Checkbox onChange={() => {
                    setMyTask(x => !x);
                    // setPageLoading(true)
                }}>我的任务</Checkbox>
                {/* {editAuth && <Button normal onClick={() => setShowDialog('templateList')}>从模版引入</Button>} */}
                <Button normal disabled={!isRefresh} onClick={() => {
                    setIsRefresh(false)
                    refresh()
                }} >刷新</Button>
                {
                    (isShow && (isPMM || isPo)) &&
                    <Button normal onClick={() => setShowDialog('import')} >导入</Button>
                }
                <Button normal onClick={exportList}>导出</Button>
                {editAuth && isShow && <Button primary onClick={() => setCurrentStageInfo({ mode: 'add' })}>新增</Button>}
            </div>
            {
                !_.isEmpty(selectedList) &&
                <Alert message={selectedMessage} type="info" showIcon />
            }
            {
                <div className='total-collect flex'>
                    <div className='schedule-item'>
                        <span>完成</span>
                        <span className='schedule-num' style={{ color: '#789A42' }}>{_.get(schedule, 'complete', 0)}</span>
                    </div>
                    <div className='schedule-item'>
                        <span>里程碑</span>
                        <span className='schedule-num' style={{ color: '#3CA046' }}>{_.get(schedule, 'milestone', 0)}</span>
                    </div>
                    <div className='schedule-item'>
                        <span>异常提醒</span>
                        <span className='schedule-num' style={{ color: 'red' }}>{_.get(schedule, 'warn', 0)}</span>
                    </div>
                    {
                        isFinanceCoding &&
                        <div style={{ color: 'red', marginLeft: 24 }}>提示：项目财务编码为空，将限制计划的正常执行，请及时申请！</div>
                    }
                </div>
            }
        </div>
        <Table
            // loading={loading || pageLoading}
            size={'small'}
            sticky={true}
            pagination={false}
            dataSource={showList}
            columns={columns}
            rowKey="id"
            rowClassName={record => {
                if (_.isNil(_.get(record, 'parentId'))) {
                    if (_.includes(['09', '10'], _.get(record, 'status'))) return 'stage-parentId stage-disabled-style'
                    return 'stage-parentId'
                }
                if (_.includes(['09', '10'], _.get(record, 'status'))) return 'stage-disabled-style'
                return ''
            }}
            scroll={{ x: 950, y: editAuth ? `calc(100% - 48px)` : `100%` }}
            rowSelection={editAuth ?
                {
                    onChange: setSelectedList,
                    getCheckboxProps: record => {
                        return {
                            disabled: _.get(record, 'isTemplate') === 'Y'
                        }
                    }
                } : null}
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: EditableRow,
                    cell: EditableCell
                },
            }}
        />
        {
            !_.isNil(currentStageInfo) &&
            <DevProjectStageUpdateDialog close={() => setCurrentStageInfo(null)} refreshList={refresh}
                {...{ currentStageInfo, showList, projectId, updateOuterFile, setShowList, projectInfo, keyNodesOptions, editAuth, isShow, isFinanceCoding }} />
        }
        {
            showDialog === 'delete' &&
            <DelMessage method={'post'} close={() => setShowDialog(null)} url={'/userProject/stage/delete'}
                refresh={() => {
                    setSelectedList(null)
                    refresh()
                }}
                params={selectedList}>
                确认删除选中任务吗？
            </DelMessage>
        }
        {
            showDialog === 'import' &&
            <ImportApiDialog
                title='计划模板'
                // abnormal={EXPORT_ABNORMAL_DATA_URL}
                importUrl={`${IMPORT_URL}`}
                template={`${TEMPLATE_URL}?${strParams({ flag: _.toUpper(userprojectType) })}`}
                close={() => setShowDialog(null)}
                refresh={() => {
                    refresh()
                    upDateDetail()
                }}
                // refresh={refresh}
                parameter={{ projectType: _.toUpper(userprojectType), projectId }}
                showExport={false}
                // abnormalParams={{ whichPage: 0 }}
                // exportFieldname='costRateVos'
                defaultOptions={exportOption}
            />
        }
        {
            showDialog === 'templateList' &&
            <RequirementTemplateListDialog close={() => setShowDialog(null)} refresh={refresh} templateType={'devProject'}
                userprojectType={userprojectType}
                submitParams={{ projectId }} submitUrl={'/userProject/stage/addExport'} />
        }
        {
            conferenceInfo &&
            <ConferenceQueryDialog close={() => setConferenceInfo(null)} initInfo={conferenceInfo}
                afterUpdateRefresh={() => refresh()}
                relateUrl={'/userProject/stage/related/record'}
            />
        }
        {
            _.get(showDetailDialog, 'module') === 'conference' &&
            <ConferenceRecordsDetailDialog close={() => setShowDetailDialog(null)} currentInfo={{
                cid: _.get(showDetailDialog, 'id')
            }} />
        }
        {
            _.get(showDetailDialog, 'module') === 'reviewMinutes' &&
            <ReviewMinutesDetailDialog
                close={() => setShowDetailDialog(null)}
                currentInfo={{
                    cid: _.get(showDetailDialog, 'id')
                }}
            />
        }
        {
            !_.isNil(statusExplain) &&
            <DevstatusExplainDialog close={() => setStatusExplain(null)} {...{ submitExplain, cancelDescription, setCancelDescription }} />
        }
    </div>

    function onSortEnd({ oldIndex, newIndex }) {
        if (_.isNil(showList[oldIndex].parentId)) return Messager.show('父级不允许移动', { 'icon': 'error' })
        if (showList[oldIndex].parentId !== showList[newIndex].parentId) return Messager.show('不允许跨级移动', { 'icon': 'error' })
        if (oldIndex !== newIndex) {
            if (oldIndex < newIndex) {
                const previous = showList[newIndex].serial
                const next = _.get(showList, `[${newIndex + 1}]serial`)
                showList[oldIndex].serial = _.isNil(next) ? previous + 1 : (previous + next) / 2
            } else if (oldIndex > newIndex) {
                const previous = _.get(showList, `[${newIndex - 1}]serial`, 0)
                const next = showList[newIndex].serial
                showList[oldIndex].serial = (previous + next) / 2
            }
            updateRow('/userProject/stage/update', showList[oldIndex])
                .then(() => {
                    refresh()
                })
                .catch(err => Messager.show(err._message, { icon: 'error' }))
            const newList = arrayMoveImmutable([].concat(showList), oldIndex, newIndex).filter(el => !!el);
            setShowList(newList)
        }
    }

    function DraggableContainer(props) {
        return <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    }
}

function EditableCell(props) {
    const { title, editable, children, dataIndex, record, handleSave, must, editAuth = false, devSegmentOptions, keyNodesOptions, statusOptions, editingId, setEditingId, isFinanceCoding, ...restProps } = props

    const inputRef = useRef()
    const { form } = useContext(DevProjectStageEditContext) || {}

    const uniqId = useMemo(() => {
        return `${record?.id}-${dataIndex}`
    }, [record, dataIndex])

    const editing = useMemo(() => {
        return uniqId === editingId
    }, [uniqId, editingId])

    useEffect(() => {
        if (editing) {
            if (!_.isNil(inputRef.current)) {
                inputRef.current.focus()
            }
        }
    }, [editing])

    const toggleEdit = useCallback(() => {
        if ((_.get(record, 'isTemplate') === 'Y' && _.isNil(_.get(record, 'parentId'))) && dataIndex !== 'memo') return setEditingId(null)
        if (_.get(record, 'isTemplate') === 'Y' && _.includes(['stage', 'keyNodes', 'milestoneFlag', 'delivery'], dataIndex)) return setEditingId(null)
        if (_.includes(['09', '10'], _.get(record, 'status')) && !_.includes(['status', 'cancelDescription'], dataIndex)) return setEditingId(null)
        if (_.includes(['notesUrl'], dataIndex)) {
            if (_.includes(_.split(_.get(record, 'owner'), ','), gd.User.operator_id) || editAuth) {
                // if (!editAuth) return setEditingId(null)
                setEditingId(x => _.isNil(x) ? uniqId : null);
                form.setFieldsValue(_.assign({}, { [dataIndex]: record[dataIndex] }))
                return
            }
        }
        if (!editAuth) return setEditingId(null)
        setEditingId(x => _.isNil(x) ? uniqId : null);
        let value
        if (_.includes(['startTimePlan', 'completeTimePlan', 'completeTimeActual'], dataIndex)) {
            value = _.isNil(record[dataIndex]) ? null : moment(record[dataIndex], 'YYYYMMDDHHmmss')
        } else {
            value = record[dataIndex]
        }

        form.setFieldsValue(_.assign({}, { [dataIndex]: value }));
    }, [dataIndex, record, form, editAuth, uniqId, setEditingId])

    const save = () => {
        try {
            form.validateFields().then((changeObj) => {
                const key = _.head(_.keys(changeObj))
                if (key === 'status') {
                    if (changeObj[key] === '03' && isFinanceCoding && _.get(record, '_coding', false)) {
                        setEditingId(null)
                        Messager.show('项目财务编码为空，将限制计划的正常执行，请及时申请！', { icon: 'error' })
                        return
                    }
                    if (changeObj[key] === '03' && _.get(record, 'isBaselineRelease') === 'Y') {
                        setEditingId(null)
                        Messager.show('质量审计活动，不允许PO操作完成，以QA审计结果自动变更状态！', { icon: 'error' })
                        return
                    }
                }
                if (_.includes(['startTimePlan', 'completeTimePlan', 'completeTimeActual'], key)) {
                    changeObj[key] = _.isNil(changeObj[key]) ? null : moment(changeObj[key]).format('YYYYMMDDHHmmss')
                }
                if ('planInterval' === key) {
                    const [begin, end] = changeObj[key]
                    const newBegin = moment(begin).format('YYYYMMDDHHmmss')
                    const newEnd = moment(end).format('YYYYMMDDHHmmss')
                    changeObj['startTimePlan'] = newBegin
                    changeObj['completeTimePlan'] = newEnd
                    changeObj[key] = { begin: newBegin, end: newEnd }
                }
                toggleEdit();
                if (changeObj[key] !== record[key]) {
                    handleSave({ ...record, ...changeObj }, key);
                }
                if (!_.isNil(inputRef.current)) {
                    inputRef.current.blur()
                }
                setEditingId(null)
            })
        } catch (errInfo) {
            console.error(errInfo);
        }
    }

    let childNode = children;

    if (editable) {
        if (editing) {
            let editNode
            if (dataIndex === 'stage') {
                editNode = (<Select ref={inputRef} options={devSegmentOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditingId(null)} />)
            } else if (dataIndex === 'keyNodes') {
                editNode = (<Select ref={inputRef} options={keyNodesOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditingId(null)} />)
            } else if (_.includes(['milestoneFlag'], dataIndex)) {
                editNode = (<Select ref={inputRef} options={YNOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditingId(null)} />)
            } else if (dataIndex === 'status') {
                editNode = (<Select ref={inputRef} options={statusOptions} onSelect={save} allowClear={false} defaultOpen onBlur={() => setEditingId(null)} />)
            } else if (dataIndex === 'owner') {
                editNode = (<PersonSelect ref={inputRef} onSelect={save} allowClear={false} defaultOpen={true} onBlur={() => setEditingId(null)} style={{ width: 90 }} autoFocus />)
            } else if (_.includes(['startTimePlan', 'completeTimePlan', 'completeTimeActual'], dataIndex)) {
                editNode = (<DatePicker ref={inputRef} onChange={save} allowClear={false} open={true} onBlur={() => setEditingId(null)} autoFocus />)
            } else if (dataIndex === 'planInterval') {
                editNode = (<RangePicker ref={inputRef} onChange={save} allowClear={false} open={true} autoFocus ranges={ranges} />)
            } else if (_.includes(['workloadPlan', 'workloadActual'], dataIndex)) {
                editNode = (<InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} step={1} formatter={limitDecimals}
                    parser={limitDecimals} />)
            } else {
                editNode = (<Input ref={inputRef} onPressEnter={save} onBlur={save} />)
            }
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[
                        {
                            required: must,
                            message: `${title}不能为空`,
                        },
                    ]}
                >
                    {editNode}
                </Form.Item>
            )
        } else {
            childNode = (
                <div className="dev-project-stage-editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            )
        }
    }
    return <td {...restProps}>{childNode}</td>;
}

const limitDecimals = (value) => {
    if (typeof value === 'string') {
        return _.floor(_.toNumber(value), 2)
    } else if (typeof value === 'number') {
        return _.floor(value, 2)
    } else {
        return ''
    }
};

function N2Float(valueParams) {
    if (_.isNil(valueParams)) return ''
    let value = Math.round(parseFloat(valueParams) * 100) / 100;
    let s = value.toString().split(".");
    if (s.length === 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (s.length > 1) {
        if (s[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}

export default DevProjectStage;
import React from "react";
import _ from "lodash";
import { Icon } from "../../../../../../../components";
import PersonDepartGroupSelectUserItem from "./PersonDepartGroupSelectUserItem";

const topValue = [{
    activeFlag: "Y",
    departMentId: "0",
    departMentName: "北京根网科技有限公司",
    manager: "0",
    parentDept: ""
}]

export function buildUserDeptTree(props) {
    const { userRes, deptRes, onNodeClick, onSelect, selectedUserAccountList, onDeptAddClick, multiple, allUserSelectedInDept, setSelectDept } = props
    const activeDepartmentList = _.filter(deptRes, x => x.activeFlag === 'Y')
    const initList = _.map(activeDepartmentList, o => {
        if (o.parentDept === '') {
            return { ...o, parentDept: '0' }
        }
        return o
    })
    const allDepartmentList = _.concat(topValue, initList)

    const treeData = getDepartmentChildren({
        allUserList: userRes,
        allDepartmentList,
        department: '',
        onNodeClick,
        onSelect,
        selectedUserAccountList,
        onDeptAddClick,
        multiple,
        allUserSelectedInDept,
        setSelectDept
    })
    return treeData
}

function getDepartmentChildren(props) {
    const { allUserList, allDepartmentList, department, onNodeClick, onSelect, selectedUserAccountList, onDeptAddClick, multiple, allUserSelectedInDept, setSelectDept } = props
    const userList = _.filter(allUserList, x => x.department === department)
    const userOptions = _.map(userList, x => ({
        key: `${x.userAccount}${x.userName}`,
        title: <PersonDepartGroupSelectUserItem userInfo={x} onSelect={onSelect} {...{ selectedUserAccountList }} />, type: 'user'
    }))
    const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
    const childDepartmentOptions = _.map(childDepartmentList, x => ({
        title: <div className={'dept-name-wrap flex center-y'} onClick={() => onNodeClick(`dept-${x.departMentId}`)}>
            <div>
                <span onClick={(e) => {
                    e.stopPropagation()
                    setSelectDept(o => {//allDepartmentList
                        if (x.departMentId === '0') {
                            const keys = _.map(o, 'value')
                            return _.concat([], o, _.map(_.filter(allDepartmentList, item => (item.parentDept === '0' && !_.includes(keys, item.departMentId))), item => ({ text: item.departMentName, value: item.departMentId })))
                        }
                        const findItem = _.find(o, item => item.value === x.departMentId)
                        return _.concat([], o, _.isNil(findItem) ? [{ text: x.departMentName, value: x.departMentId }] : [])
                    })
                }}>{x.departMentName}</span>
                {
                    !(_.isEmpty(_.filter(allUserList, item => item.department === x.departMentId)) &&
                        _.isEmpty(_.filter(allDepartmentList, item => item.parentDept === x.departMentId))) &&
                    allUserSelectedInDept(x.departMentId) && x.departMentId !== '0' &&
                    <div className={'dept-item-selected flex center'}>
                        √
                    </div>
                }
            </div>
            {
                x.departMentId !== '0' &&
                <Icon name={'tianjia'} className={'add-dept-icon'} onClick={e => onDeptAddClick(e, x.departMentId)} />
            }
        </div>,
        key: `dept-${x.departMentId}`,
        type: 'department',
        children: getDepartmentChildren({
            allUserList,
            allDepartmentList,
            department: x.departMentId,
            onNodeClick,
            onSelect,
            selectedUserAccountList,
            onDeptAddClick,
            multiple,
            allUserSelectedInDept,
            setSelectDept
        }),
    }))
    return [...userOptions, ...childDepartmentOptions]
}
import _ from 'lodash'
import React, { useState, useEffect, useMemo, useCallback, useReducer } from 'react'
import { Button, DataGrid, Pagination } from 'rootnet-ui'
import { N2 } from 'rootnet-core/format'
import { Select, Form, FormInput } from 'rootnet-edit'
import { useGet } from '../../../utils/hook'
import { Box } from '../../common/commonComponent'
import { addAll } from '../../../utils/jsondata'
import { strParams, summaryWithHeader } from '../../../utils/publicFun'
import OperationList from '../../../project_share/components/OperationList'
import Dialog from '../controls/Dialog'
import '../authority.scss'
import { TextIconBtn } from "../../common/TextIconBtn";
import ExportCsvDialog from "../../common/ExportCsvDialog";
import { dateFormat, toDate } from "rootnet-core/dateFormat";
import gd from '../../../base/global'

const urls = [
  '/common/system',
  '/common/userinfo',
  '/common/globalconst?globalConst=PlanStatus'
]

const getColumns = (setOpen, presalesProStatusOpt, params) => {
  return [
    { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 70 },
    { header: '项目名称', bind: 'projectName', sortable: true, align: 'left', width: 300, tooltip: true },
    { header: '客户', bind: 'customerName', sortable: true, align: 'left', tooltip: true },
    { header: '项目状态', bind: 'projectStatus', sortable: true, align: 'left', width: 78, convert: convertProjectStatus, csvConvert: convertProjectStatus, },
    { header: '项目经理', bind: 'principalName', sortable: true, align: 'left', width: 85 },
    { header: '计划开始时间', bind: 'PlanBeginDate', sortable: true, width: 100, convert: convertPlanTime, csvConvert: convertPlanTime, },
    { header: '计划结束时间', bind: 'PlanEndDate', sortable: true, width: 100, convert: convertPlanTime, csvConvert: convertPlanTime, },
    { header: '计划工时(人日)', bind: 'planTime', sortable: true, align: 'right', width: 120, isTotal: true, convert: convertTime, csvConvert: convertTime, },
    { header: '计划成本(元)', bind: 'planCost', sortable: true, align: 'right', width: 120, isTotal: true, convert: N2, csvConvert: N2, },
    { header: '实际工时(人日)', bind: 'useTime', sortable: true, align: 'right', width: 120, isTotal: true, convert: convertTime, csvConvert: convertTime, },
    { header: '实际成本(元)', bind: 'useCost', sortable: true, align: 'right', width: 120, isTotal: true, convert: N2, csvConvert: N2, },
    { header: '创建时间', bind: 'inputDate', sortable: true, width: 150, tooltip: true, convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v)) },
    { header: '操作', align: 'center', width: 90, convert: createOperation },
  ]

  function convertPlanTime(v) {
    if (_.isNil(v)) return null
    return _.split(v, ' ')[0]
  }

  function convertProjectStatus(v) {
    return _.get(_.find(presalesProStatusOpt, x => x.value === v), 'name')
  }

  function convertTime(v) {
    return N2(parseInt((v / 60 / 8) * 100) / 100)
  }

  function createOperation(v, o) {
    const options = [
      { text: '授权管理', onClick: () => setOpen(getParams()) }
    ]
    return <OperationList options={options} />
    function getParams() {
      const origin = { ..._.omit(o, ['customer', 'projectName', 'principal']), planTime: o.planTime };
      origin.projectNames = o.projectName;
      return _.assign(origin, params);
    }
  }
}

const gridOptsFor = (options, data) => ({
  nilText: '-',
  fixedLeft: 2,
  fixedRight: 1,
  emptyText: '-',
  resizable: true,
  autoFill: true,
  columns: options,
  virtualized: false,
  summary: [
    summaryWithHeader(options, data)
  ],
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20,
    customer: '',
    projectStatusList: ['01', '02'],
  }
}

export default function Authorityimp() {
  const { data: optsData } = useGet(urls)
  const { data: listRes, error, loading, doFetch } = useGet()
  const [open, setOpen] = useState(false)
  const userAccount = _.get(gd, 'User.operator_id')
  const [params, setParams] = useState({ ...getInitParams(), principal: userAccount, })
  const [currentId, setCurrentId] = useState()
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const { pageSize, total, pageNum, rows: data } = useMemo(() => (listRes || {}), [listRes]);

  const opts = useMemo(() => {
    const [devClient, proManager, presalesProStatus] = optsData || []
    return [
      _.map(devClient, v => ({ value: v.projectId, text: ''.concat(v.projectId, '-', v.projectName) })),
      _.map(proManager, v => ({ value: v.userAccount, text: `${v.userName} ${v.indexValidFlag === 0 ? '(离职)' : ''} - ${v.userAccount}`, tag: `${v.userAccount}${v.userName}` })),
      _.map(presalesProStatus, v => ({ value: v.interiorId, text: ''.concat(v.interiorId, '-', v.displayName), name: v.displayName })),
    ]
  }, [optsData])
  const presalesProStatusOpt = opts[2]
  const option = useMemo(() => gridOptsFor(getColumns(setOpen, presalesProStatusOpt, params), data), [presalesProStatusOpt, params, data])

  const extra = useMemo(() => {
    return <TextIconBtn icon='xiazai2' text='导出' onClick={() => setShowExportDialog(true)} disabled={_.isEmpty(data)} />
  }, [data])

  const onRowClick = useCallback((item) => {
    setCurrentId(item.id)
    _.forEach(data, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [data])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return (
    <div className='authority-box fill'>
      <Option search={search} opts={opts} params={params} setParams={setParams} />
      <Box className='flex-y x-card-singlegrid' title='项目列表' error={error} loading={loading} data={data} extra={extra}>
        <DataGrid className='row-fill' data={data} option={option} onRowClick={onRowClick} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setParams(x => {
              const parameter = _.assign({}, x, { pageNum, pageSize })
              search(parameter)
              return parameter
            })
          }} />
      </Box>
      {open && <Dialog currobj={{ id: open.id, principal: open.principal, planTime: open.planTime }} open={open} setIsShow={setOpen} projectType='SERVICE'
        projectIsClose={_.includes(['03', '04'], _.get(open, 'projectStatus'))} />}
      {
        showExportDialog && <ExportCsvDialog close={() => setShowExportDialog(false)} list={data} option={option} title={`实施授权管理`} />
      }
    </div>
  )
  function search(o) {
    setParams(o)
    doFetch(`/implement/project?${strParams(o)}`)
  }
}

function Option({ search, opts, params }) {
  const [devClientOpt, proManagerOpt, presalesProStatusOpt] = opts
  const [parameter, setParameter] = useState(params)
  useEffect(() => {
    search(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='x rims_options'>
      <Form onChange={setParameter} value={parameter}>
        <FormInput label="客户" component={Select} bind="customer" search options={addAll(devClientOpt)} clear />
        <FormInput label="项目名称" bind="projectName" search />
        <FormInput label="项目经理" component={Select} bind="principal" search clear options={proManagerOpt} />
        <FormInput label="项目状态" component={Select} bind="projectStatusList" multiple options={presalesProStatusOpt} />
      </Form>
      <Button style={{ marginLeft: 0 }} className='btn' primary onClick={() => { search(parameter) }}>查询</Button>
    </div>
  )
}
import React, { useState, useMemo, useRef, useCallback, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Popover } from 'antd'
import { DataGrid, Pagination, Messager } from 'rootnet-ui'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Box } from '../../../../common/commonComponent'
import useGetDateOptions from '../../../../common/view/hooks/useGetDateOptions'
import useGetTreeList from '../../../../common/view/hooks/useGetTreeList'
import useGetViewConfig from '../../../../common/view/hooks/useGetViewConfig'
import useRefreshList from '../../../../common/view/hooks/useRefreshList'
import ViewArea from '../../../../common/view/ViewArea'
import convertTableAlign from '../../../../common/view/convertTableAlign'
import findConvert from '../../../../common/view/findConvert'
import DefectUpdateDialog from '../../../../testMgt/defect/controls/DefectUpdateDialog'
import './index.scss'
import convertOptions from '../../../../common/ConvertOptions'
import WorkFlowChangePop from '../../../../workFlow/workFlowChangePop/WorkFlowChangePop'
import { CustomizeFormInput } from '../../../../common/customizeOptions/CustomizeOptions'
import { isNil } from 'rootnet-core/format'
import { TextIconBtn } from "../../../../common/TextIconBtn";
import { ScheduleManagementContext } from '../../../../common/Context'
import ViewQueryDialog from "../../../../common/view/viewQueryDialog/ViewQueryDialog";
import AssociatedVersion from '../devProjectScope/devProjectScopeAllInfo/components/associatedVersion'
import ImportVersion from '../devProjectScope/devProjectScopeAllInfo/components/importVersion'

const PRIMARY_KEY = 'defectId'
const ENTER_DETAIL_FIELD = 'title'
const EDITABLE_FIELD = ['title']

const SEVERITY_COLOR = {
  '01': '#F5221B',
  '02': '#FF8C00',
  '03': '#FFC20E',
  '04': '#5477ff',
  '05': '#A9A9A9',
}
const PRIORITY_COLOR = {
  '01': '#F85E5E',
  '02': '#93C36B',
  '03': '#97AFD0',
}
const STATUS_COLOR = {
  'new': '#93C36B',
  'closed': '#A9A9A9',
  'suspended': '#A9A9A9',
  'rejected': '#A9A9A9',
  'postponed': '#A9A9A9',
  'in_progress': '#5477ff',
  'resolved': '#5477ff',
  'verified': '#5477ff',
  'reopened': '#5477ff',
}

const SPECIAL_FIELD_CONVERT = {
  'severity': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: SEVERITY_COLOR[value], border: `1px solid ${SEVERITY_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'priority': (value, showValue) => {
    return <div className={'priority-field'} style={{ background: PRIORITY_COLOR[value], padding: '1px 12px 2px', color: '#fff', borderRadius: 4 }}>
      {showValue}
    </div>
  },
  'status': (value, showValue) => {
    return <div className={'severity-field'} style={{ color: STATUS_COLOR[value], border: `1px solid ${STATUS_COLOR[value]}`, padding: '0 8px', borderRadius: 4 }}>
      {showValue}
    </div>
  },
}

const REPLACE_POST_PARAMS = {
  'currentOwner': 'current_owners'
}

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, setCurrentId, editInfo, setEditInfo, currentTableValueRef,
    updateShowList, workFlowId, setWorkFlowId, refreshList, setMode, selectedVersion } = props
  const { editId = null, editField = null } = editInfo || {}
  return _.compact([
    !_.isNil(selectedVersion) && { selection: true },
    { header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x))))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o) => convertEditField(v, o, fieldItem)
      }
    }
  }

  function convertEditField(v, o, fieldItem) {
    let showValue = findConvert(fieldItem, v, convertCollection, dateOptions)
    const tableField = `${fieldItem.tableName}.${fieldItem.fieldId}`
    if (!_.isNil(SPECIAL_FIELD_CONVERT[fieldItem.fieldId])) {
      showValue = SPECIAL_FIELD_CONVERT[fieldItem.fieldId](v, showValue)
    }
    if (tableField === 'workflow_business.factorId') {
      const workFlowStatusOptions = _.find(convertCollection, (v, k) => k === tableField)
      const workflowStatusColor = convertOptions(v, workFlowStatusOptions, 'color') || '#000'
      return <Popover destroyTooltipOnHide={true}
        content={<WorkFlowChangePop businessId={workFlowId} close={() => setWorkFlowId(null)} refreshList={refreshList} funcCode={'0504'} businessType={'defect'} />}
        onVisibleChange={visible => {
          if (!visible) {
            setWorkFlowId(null)
          }
        }}
        trigger={'click'}
        placement="bottom"
        visible={!_.isNil(o.id) && o.id === workFlowId}
      >
        <div className={'common-display-field work-flow-status-field'} onClick={() => setWorkFlowId(o.id)}
          style={{ color: workflowStatusColor, border: `1px solid ${workflowStatusColor}`, padding: '0 8px', borderRadius: 4 }}>
          {showValue || '-'}
        </div>
      </Popover>
    }
    if (o?.id === editId && editField === `${fieldItem.tableName}.${fieldItem.fieldId}`) {
      const primaryColumnId = _.get(_.find(fieldList, x => x.fieldId === PRIMARY_KEY), 'columnId')
      const handleValue = fieldItem.fieldHtmlType === '3' ? _.split(v, ',') : v
      return <CustomizeFormInput
        fieldHtmlType={fieldItem.fieldHtmlType} convertCollection={convertCollection} defaultValue={handleValue} clear={fieldItem.requiredFlag === 'N'} allowClear={fieldItem.requiredFlag === 'N'} defaultOpen={true}
        bind={`${fieldItem.tableName}.${fieldItem.fieldId}`} componentWidth={(_.toNumber(fieldItem.columnWidth) || 120) - 10}
        componentStyle={{ minWidth: (_.toNumber(fieldItem.columnWidth) || 120) - 10 }}
        viewConditionType={fieldItem.viewConditionType}
        onFocus={() => currentTableValueRef.current = handleValue}
        onChange={value => {
          if (_.includes(['3', '4', '5'], fieldItem.fieldHtmlType)) {
            updateShowList({ newValue: value, primaryId: o[primaryColumnId] })
          } else {
            currentTableValueRef.current = value
          }
        }}
        onBlur={() => updateShowList({ primaryId: o[primaryColumnId] })}
        onEnter={() => updateShowList({ primaryId: o[primaryColumnId] })}
      />
    }
    if (!_.includes(EDITABLE_FIELD, fieldItem.fieldId)) {
      return <div className={'common-display-field'}>
        {showValue || '-'}
      </div>
    }

    if (fieldItem.fieldId === ENTER_DETAIL_FIELD) {
      return <div className={'common-display-field enter-detail-field-wrap flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) - 10 || 120 }}>
        <div className={'enter-detail-field'} onClick={() => {
          setCurrentId(o.id)
          setMode('detail')
        }}>{showValue}</div>
      </div>
    }
    return <div className={'common-edit-field flex center-y'} style={{ minWidth: _.toNumber(fieldItem.columnWidth) || 120 }} onClick={() => onEditFieldClick({ o, fieldItem })}>
      {showValue || '-'}
    </div>
  }
  function onEditFieldClick({ o, fieldItem }) {
    const titleColumnId = _.get(_.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'test_defect.title'), 'columnId')
    setEditInfo({
      editId: o?.id,
      title: _.get(o, titleColumnId),
      editField: `${fieldItem.tableName}.${fieldItem.fieldId}`,
      fieldId: fieldItem.fieldId,
      columnId: fieldItem.columnId,
      fieldHtmlType: fieldItem.fieldHtmlType,
      viewConditionType: fieldItem.viewConditionType,
    })
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 2,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function DevProjectDefect(props) {
  const { replaceParams, factorViewSql, FUNC_CODE = '3622', isShowAdd = true, projectMap, selectedVersion, selectedVersionItem,
    defectRelateFactorViewSql = '', isRelateProject = true, canRelate = true, module, relateViewCode, relateTips, refreshVersionList, isRelevance = true, versionCheck = true, searchAll, allList, isNoPlanVersion, versionList, refreshAllList } = props //isVersionState = true,isDisassociation = true,isAll,
  const { downloadPostProgress } = useContext(ScheduleManagementContext)
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [sortConfig, setSortConfig] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [factor, setFactor] = useState()
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentId, setCurrentId] = useState()
  const [workFlowId, setWorkFlowId] = useState()
  const [showRelationDialog, setShowRelationDialog] = useState()
  const { doFetch: relatePost } = usePost()
  const [selectItems, setSelectItems] = useState([])
  const [relateLoading, setRelateLoading] = useState(false)

  // 表格编辑
  const currentTableValueRef = useRef()
  const [editInfo, setEditInfo] = useState()
  const [showDataGridList, setShowDataGridList] = useState()
  const { doFetch: editTable } = usePost()
  const [mode, setMode] = useState(null)

  // const replaceParams = useMemo(() => {
  //   if (_.isNil(projectId)) return
  //   return ({ 'ReleaseInfo.projectId': projectId, })
  // }, [projectId])

  const appendObj = useMemo(() => {
    return { factorViewSql }
  }, [factorViewSql])

  const updateShowList = useCallback(({ newValue, primaryId }) => {
    if (_.isNil(editInfo)) return
    const updateValue = (_.includes(['3', '4', '5'], editInfo.fieldHtmlType)) ? newValue : currentTableValueRef.current
    let postParams = {
      // defectId: primaryId,
      ids: [editInfo['editId']]
    }
    const replaceParams = REPLACE_POST_PARAMS[editInfo['fieldId']]
    if (_.isNil(replaceParams)) {
      // postParams[editInfo['fieldId']] = updateValue
      postParams['fieldName'] = editInfo['fieldId']
      postParams['fieldValue'] = updateValue
    } else {
      postParams[replaceParams] = updateValue
    }
    if (postParams['fieldName'] === 'title' && isNil(postParams['fieldValue'])) {
      setEditInfo(null)
      currentTableValueRef.current = null
      return
    }
    if (editInfo['fieldId'] === 'currentUser') {
      if (isNil(updateValue)) {
        setEditInfo(null)
        currentTableValueRef.current = null
        return
      }
      const currentUserParams = {
        businessId: _.get(editInfo, 'editId'),
        businessType: 'defect',
        funcCode: FUNC_CODE,
        currentUser: updateValue,
        title: _.get(editInfo, 'title')
      }
      editTable('/WorkflowBusiness/updateCurrentUser', currentUserParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    } else {
      editTable('/test_defect/updateSingle', postParams).then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    }

    function afterRefresh() {
      setShowDataGridList(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.columnId] = updateValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      currentTableValueRef.current = null
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [editInfo, editTable, FUNC_CODE])

  const afterRefresh = useCallback(() => {
    setSelectItems([])
  }, [])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode, getFactor: setFactor, appendObj, afterRefresh })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const { options, dataGridList, isTree } = useGetTreeList({
    fieldList, list: showDataGridList, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: {
      setCurrentId, editInfo, setEditInfo, currentTableValueRef, updateShowList, workFlowId,
      setWorkFlowId, refreshList, setMode, selectedVersion,
    },
    optionsAppendParams: {
      sort: sortConfig,
      onSort: (data, sort) => {
        setSortConfig(sort)
        return _.orderBy(data, x => x[sort.column], sort.direction)
      }
    },
  })

  const showChildList = useMemo(() => {
    return _.filter(dataGridList, x => !_.get(x, '_children')) || []
  }, [dataGridList])

  const showList = useMemo(() => {
    let showList = showChildList || []
    if (!_.isNil(sortConfig) && !isTree) {
      showList = _.orderBy(showChildList, sortConfig.column, sortConfig.direction)
    }
    return showList
  }, [sortConfig, isTree, showChildList])

  useEffect(() => {
    setShowDataGridList(list)
  }, [list])

  const exportViewList = useCallback(() => {
    const notCustomList = _.filter(fieldList, x => x.custom !== 'Y')
    const postParams = {
      action: 'query',
      id: currentViewId,
      type: '01',
      menuCode: FUNC_CODE,
      fieldList: _.map(notCustomList, 'columnId'),
      fieldNameList: _.map(notCustomList, 'fieldName'),
      factor: factor,
      ...appendObj
    }
    downloadPostProgress('/UserSetting/exportExcel', postParams, '缺陷')
  }, [fieldList, currentViewId, factor, downloadPostProgress, appendObj, FUNC_CODE])

  const onRelate = useCallback((list) => {
    if (_.isEmpty(list)) return
    if (relateLoading) return
    setRelateLoading(true)
    const idList = _.map(list, 'id')
    let url
    if (module === 'fixDefect') {
      url = '/userProject/batchRelationDefectProject'
    } else if (isRelateProject) {
      url = '/userProject/relationDefectProject'
    } else {
      url = '/releaseStory/relationDefectBaseline'
    }
    let postParams
    if (isRelateProject) {
      postParams = {
        action: 'add',
        id: _.get(projectMap, 'currentId'),
        fixProjectId: _.get(projectMap, 'projectId'),
        testDefects: _.map(idList, x => ({ id: x }))
      }
    } else {
      postParams = {
        id: _.get(selectedVersionItem, 'snowId'),
        defectMark: 'Y',
        releaseId: _.get(selectedVersionItem, 'releaseID'),
        storyIds: _.map(idList, v => ({ id: v })),
      }
    }
    relatePost(url, postParams).then(res => {
      refreshList()
      refreshAllList()
      refreshVersionList(false)
      setRelateLoading(false)
      Messager.show('关联成功', { icon: 'success' });
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
      setRelateLoading(false)
    })
  }, [refreshList, isRelateProject, projectMap, relatePost, selectedVersionItem, relateLoading, module, refreshVersionList, refreshAllList])

  const cancelRelate = useCallback(() => {
    if (_.isEmpty(selectItems)) return Messager.show('请选择后再进行操作')
    if (relateLoading) return
    setRelateLoading(true)
    let url
    if (module === 'fixDefect') {
      url = '/userProject/batchRelationDefectProject'
    } else if (isRelateProject) {
      url = '/userProject/relationDefectProject'
    } else {
      url = '/releaseStory/relationDefectBaseline'
    }
    let postParams
    if (isRelateProject) {
      postParams = {
        action: 'del',
        id: _.get(projectMap, 'currentId'),
        fixProjectId: _.get(projectMap, 'projectId'),
        testDefects: _.map(selectItems, x => ({ id: x.id }))
      }
    } else {
      postParams = {
        id: _.get(selectedVersionItem, 'snowId'),
        defectMark: 'N',
        releaseId: _.get(selectedVersionItem, 'releaseID'),
        storyIds: _.map(selectItems, x => ({ id: x.id }))
      }
    }
    relatePost(url, postParams).then(res => {
      refreshAllList()
      refreshList()
      refreshVersionList(false)
      Messager.show('取消关联成功', { icon: 'success' });
      setRelateLoading(false)
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' })
      setRelateLoading(false)
    })
  }, [selectItems, projectMap, refreshList, relatePost, isRelateProject, relateLoading, selectedVersionItem, module, refreshVersionList, refreshAllList])

  const canRelateVersion = useMemo(() => {
    if (_.isNil(selectedVersionItem)) return true
    return !_.includes(['R', 'L'], selectedVersionItem.state)
  }, [selectedVersionItem])

  const baseLineDefect = useMemo(() => module === 'baseLineDefect', [module])

  const extra = useMemo(() => {
    const notVersion = (isNil(selectedVersion) || selectedVersion === 'no-plan-version' || selectedVersion === 'history-defect')
    const relateText = notVersion ? '项目关联缺陷' : '版本关联缺陷'
    const cancelRelateText = notVersion ? '移除项目' : '移除版本'
    const showRelate = module === 'baseLineDefect' || (module === 'fixDefect' && (selectedVersion === 'no-plan-version' || _.isNil(selectedVersion)))
    return <div className={'extra-group flex center-y'}>
      {
        !baseLineDefect &&
        <>
          {

            canRelate && canRelateVersion && showRelate &&
            <TextIconBtn icon='guanlian' text={relateText} onClick={() => setShowRelationDialog('defect')} />
          }
          {
            canRelate && !_.isNil(selectedVersion) && canRelateVersion && showRelate &&
            <TextIconBtn icon='quxiaoguanlian' text={cancelRelateText} onClick={cancelRelate} />
          }
        </>
      }

      {
        baseLineDefect &&
        <>
          {
            canRelate && canRelateVersion && showRelate && isRelevance && !versionCheck && !isNoPlanVersion &&
            <TextIconBtn icon='' text={relateText} onClick={() => setShowRelationDialog('defect')} />
          }
          {/*
            canRelate && canRelateVersion && showRelate && isRelevance && !versionCheck && isNoPlanVersion &&
            <TextIconBtn icon='' text='导入版本' onClick={() => {
              if (_.isEmpty(selectItems)) return Messager.show('请选择数据后在进行操作', { icon: 'error' })
              setShowRelationDialog('versionPlan')
            }} />
          */}
          {/*
            !isAll && canRelate && !_.isNil(selectedVersion) && canRelateVersion && showRelate && isDisassociation && !versionCheck &&
            <TextIconBtn icon='quxiaoguanlian' text={cancelRelateText} onClick={cancelRelate} />
        */}
          {/*
            canRelate && canRelateVersion && showRelate && versionCheck && isVersionState &&
            <TextIconBtn text={'导入版本'} onClick={() => {
              setShowRelationDialog('joinVersion')
            }} />
          */}
          {/*
            canRelate && canRelateVersion && showRelate && versionCheck && isVersionState &&
            <TextIconBtn icon='quxiaoguanlian' text={cancelRelateText} onClick={cancelRelate} />
        */}
        </>
      }
      {
        isShowAdd &&
        <TextIconBtn icon='tianjia' text='新增' onClick={() => {
          setMode('add')
        }} />
      }
      <TextIconBtn icon='xiazai2' text='导出' onClick={exportViewList} />
    </div>
  }, [exportViewList, isShowAdd, selectedVersion, cancelRelate, canRelate, canRelateVersion, module, versionCheck, isRelevance, baseLineDefect, isNoPlanVersion])

  const relateTextHint = useMemo(() => {
    if (isNil(selectedVersion) || selectedVersion === 'no-plan-version') {
      return <span>
        查询结果为修复项目为当前项目，历史问题=是，且已过滤缺陷单中<span style={{ color: 'red' }}>发布客户</span>为空的数据，请及时编辑补充！
      </span>
    } else {
      return <span>
        修复版本为当前版本，历史问题，且已过滤缺陷单中<span style={{ color: 'red' }}>发布客户</span>为空的数据
      </span>
    }
  }, [selectedVersion])

  return (
    <div className='dev-project-defect'>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading} replaceParams={replaceParams}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'缺陷'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error} extra={extra}>
        <DataGrid option={options} data={showList} selection={selectItems} onSelectionChange={setSelectItems} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
      {/*{*/}
      {/*  !_.isNil(currentId) &&*/}
      {/*  <DefectUpdateDialog*/}
      {/*    mode={'detail'}*/}
      {/*    close={() => setCurrentId(null)}*/}
      {/*    currentId={currentId}*/}
      {/*    refreshList={refreshList}*/}
      {/*  />*/}
      {/*}*/}
      {
        _.includes(['add', 'edit', 'detail'], mode) &&
        <DefectUpdateDialog close={() => setMode(null)}
          addDefaultParams={{
            projectId: _.get(projectMap, 'projectId'),
            releaseId: (_.isNil(selectedVersion) || selectedVersion === 'no-plan-version') ? null : selectedVersion,
            fixProjectIdList: _.get(projectMap, 'projectId') ? [_.get(projectMap, 'projectId')] : [],
            // fixVersion: _.includes(['R', 'L'], selectedVersionItem?.state) ? null : selectedVersion,
          }}
          {...{ mode, currentId, setCurrentId, refreshList }} />
      }
      {
        showRelationDialog === 'defect' &&
        <ViewQueryDialog close={() => setShowRelationDialog(null)} initValue={[]} textHint={relateTips || relateTextHint}
          outerSetItem={onRelate} funcCode={relateViewCode} bizField={'test_defect.defectId'} bizName={'缺陷'} multiple={true}
          initItemValue={[]} appendObj={{ factorViewSql: defectRelateFactorViewSql }}
        />
      }
      {
        showRelationDialog === 'joinVersion' &&
        <AssociatedVersion
          searchAll={searchAll}
          defaultData={versionCheck ? _.filter(allList, o => _.includes(_.split(o.releaseIds, ','), selectedVersion)) : allList}
          close={() => setShowRelationDialog(null)}
          {...{
            selectedVersion, refreshList: () => {
              refreshList()
              refreshVersionList()
              refreshAllList()
            }
          }}
        />
      }
      {
        showRelationDialog === 'versionPlan' &&
        <ImportVersion
          {...{
            selection: _.map(selectItems, o => _.assign({}, o, { taskType: 'DEFECT' })), setSelection: setSelectItems, refreshList: () => {
              refreshList()
              refreshVersionList()
              refreshAllList()
            }, versionList
          }}
          close={() => setShowRelationDialog(null)}
        />
      }
    </div>
  )
}

import React, { useCallback, useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { Dialog, Button, DataGrid, Pagination } from 'rootnet-ui'
import { useGet } from 'rootnet-biz/es/hooks'
import { Form, FormInput, Radio } from 'rootnet-edit'
import UserSelect from '../../../../../common/personSelectPop/UserSelect'
import DateSelector from '../../../../../common/dateSelector/DateSelector'
import { strParams } from '../../../../../../utils/publicFun'
import { Box } from '../../../../../common/commonComponent'
import { isNil } from 'rootnet-core/format'
import convertOptions from '../../../../../common/ConvertOptions'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import './index.scss'

const LIST_URL = '/todolist/getTodolistrecordList'

function getColumns(props) {
  const { currentId, allUserRes } = props
  return [
    { width: 50, align: 'center', stretch: false, convert: (v, o) => <TableRadio {...{ o, currentId }} /> },
    { header: '#', stretch: false, convert: (v, o, i) => (i + 1), align: 'center', width: 40 },
    { header: '主题', bind: 'subject', width: 260, tooltip: true, sortable: true },
    { header: '负责人', bind: 'principal', width: 120, tooltip: true, convert: v => convertOptions(v, allUserRes, 'userName', 'userAccount'), sortable: true },
    {
      header: '时间要求', bind: 'requireDate', width: 120, tooltip: true, convert: v => {
        if (_.isNil(v)) return '-'
        if (_.isString(v)) return dateFormat('YYYY-MM-DD', toDate.str14ToDate(v))
        return dateFormat('YYYY-MM-DD', v) || '-'
      }, sortable: true
    },
    { header: '创建人', bind: 'recUser', width: 120, tooltip: true, sortable: true },
  ]

  function TableRadio(props) {
    const { o, currentId } = props
    return <Radio value={o.id === currentId} />
  }
}

function getOption(columns) {
  return {
    columns,
    selection: true,
    autoFill: true,
    resizable: true,
    nilText: '-',
    emptyText: '-',
  }
}

const getInitParams = () => {
  return ({
    pageNum: 1,
    pageSize: 20,
    subject: null,
    principal: null,
    requireDate: null,
    recUser: null,
  })
}

export default function RelevanceTodoList(props) {
  const { close, submit, allUserRes } = props
  const [params, setParams] = useState(getInitParams())
  const [currentId, setCurrentId] = useState()
  const { data, doFetch, loading, error } = useGet()

  const { pageNum, pageSize, total, rows: list } = useMemo(() => data || {}, [data])

  useEffect(() => {
    const { begin, end } = params?.requireDate || {}
    const searchParams = {
      allPId: '0',
      startTime: begin, endTime: end,
      ..._.omit(params, ['requireDate'])
    }
    doFetch(`${LIST_URL}?${strParams(searchParams)}`)
  }, [doFetch, params])

  const option = useMemo(() => {
    return getOption(getColumns({ currentId, allUserRes }))
  }, [allUserRes, currentId])

  const confirm = useCallback(() => {
    submit({ pid: currentId })
    close()
  }, [close, currentId, submit])

  return (
    <Dialog
      cancel={close}
      header={'关联跟进事项'}
      confirm={confirm}
      className='relevance-todo-list'
      confirmButtonDisabled={isNil(currentId)}
      style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 0
      }}
    >
      <Options initParams={params} search={setParams} />
      <Box title='跟进事项列表' className='todo-list-data x-card-singlegrid flex-y' data={list} loading={loading} error={error}>
        <DataGrid option={option} data={list} onRowClick={o => {
          setCurrentId(o.id)
          // setTitle(o.title)
        }} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
      </Box>
    </Dialog>
  )
}

function Options(props) {
  const { initParams, search } = props
  const [params, setParams] = useState(initParams)
  return <div className='c-options'>
    <Form value={params} onChange={setParams}>
      <FormInput label='主题' bind='subject' />
      <FormInput label='负责人' bind='principal' component={UserSelect} search clear />
      <FormInput label='创建人' bind='recUser' component={UserSelect} search clear />
      <FormInput label='时间要求' bind='requireDate' component={DateSelector} multiple search clear componentWidth={230} />
    </Form>
    <div className="search-btn-group flex">
      <Button className={'search-btn'} primary onClick={() => search({ ...params, pageNum: 1, pageSize: 20, })}>查询</Button>
    </div>
  </div>
}

import { dateFormat, toDate } from "rootnet-core/dateFormat";

const { str14ToDate } = toDate

export function isNil(value) {
  return value === null || value === undefined || value === 'null' || value === ''
}

export function toChinesNum(num) {
  const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  const getWan = (temp) => {
    const strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i === 0 && strArr[i] === 0 ? "" : (i > 0 && strArr[i] === 0 && strArr[i - 1] === 0 ? "" : changeNum[strArr[i]] + (strArr[i] === 0 ? unit[0] : unit[i]))) + newNum;
    }
    return newNum;
  }
  const overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) { noWan = "0" + noWan; }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

export function getDate(begin, end) {
  const beginDate = dateFormat('YYYY-MM-DD', str14ToDate(begin))
  const endDate = dateFormat('YYYY-MM-DD', str14ToDate(end))
  return `${beginDate} - ${endDate}`
}

export function isInterval(begin, end) {
  const beginDate = dateFormat('YYYY-MM-DD', str14ToDate(begin))
  const endDate = dateFormat('YYYY-MM-DD', str14ToDate(end))
  return (new Date() >= new Date(beginDate) && new Date() <= new Date(getNextDay(endDate)))
}

export function beforDate(begin) {
  const beginDate = dateFormat('YYYY-MM-DD', str14ToDate(begin))
  return (new Date() < new Date(beginDate))
}

function getNextDay(d) {
  d = new Date(d);
  d = +d + 1000 * 60 * 60 * 24;
  d = new Date(d);
  return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
}
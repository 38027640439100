import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Button, Messager } from 'rootnet-ui'
import { Form, FormInput, Display } from 'rootnet-edit'
import { Table, Select } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { arrayMoveImmutable } from 'array-move'
import { usePost } from 'rootnet-biz/lib/hooks'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { TextIconBtn } from '../../../../../common/TextIconBtn'
import TextAreaInput from '../../../../../common/textAreaInput/TextAreaInput'
import convertOptions from '../../../../../common/ConvertOptions'
import './index.scss'
import RiskFollowUpDetail from '../../devProjectRiskFollowUp/riskFollowUpDetail'

const EDIT_URL = '/riskFollow/update'

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const getColumns = (props) => {
  const { isDisplay, rankOptions, whetherOptions, riskStatusOptions, editInfo, setEditInfo, updateShowList, setCurrentInfo } = props
  const { editId = null, editField = null } = editInfo || {}
  return [
    {
      title: '',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: '序号',
      width: 50,
      align: 'center',
      render: (v, o, i) => i + 1
    },
    {
      title: '风险标题',
      dataIndex: 'riskTitle',
      width: 150,
      ellipsis: true,
      render: (v, o) => {
        return <div onClick={() => {
          setCurrentInfo({ mode: 'detail', id: o.id })
        }} className='risk-title-style'>{v}</div>
      }
    },
    {
      title: '风险描述',
      dataIndex: 'riskDesc',
      className: 'drag-visible',
      width: 130,
      ellipsis: true,
    },
    {
      title: '风险影响',
      dataIndex: 'riskImpact',
      width: 130,
      ellipsis: true,
    },
    {
      title: '发生概率',
      dataIndex: 'probability',
      width: 80,
      render: v => {
        const getColor = _.get(_.find(rankOptions, o => o.value === v), 'color')
        return <div
          style={{ color: getColor }}
        >{convertOptions(v, rankOptions)}</div>
      },
    },
    {
      title: '风险等级',
      dataIndex: 'riskRating',
      className: 'drag-visible',
      width: 80,
      render: v => {
        const getColor = _.get(_.find(rankOptions, o => o.value === v), 'color')
        return <div
          style={{ color: getColor }}
        >{convertOptions(v, rankOptions)}</div>
      },
    },
    {
      title: '规避措施',
      dataIndex: 'dodgeMeasure',
      width: 130,
      ellipsis: true,
    },
    {
      title: '是否已发生',
      dataIndex: 'isOccurred',
      width: 90,
      render: v => convertOptions(v, whetherOptions),
    },
    {
      title: '风险状态',
      dataIndex: 'riskStatus',
      className: 'drag-visible',
      width: 100,
      render: (v, o) => {
        if (o?.id === editId && editField === 'riskStatus') {
          return <Select
            value={v}
            size='small'
            options={riskStatusOptions}
            onChange={(newValue) => updateShowList(v, newValue)}
            onBlur={() => setEditInfo(null)}
            autoFocus
          />
        }
        const getColor = _.get(_.find(riskStatusOptions, o => o.value === v), 'color')
        return <div
          onClick={() => {
            if (isDisplay) setEditInfo({ editId: o.id, editField: 'riskStatus' })
          }}
          style={{
            color: getColor,
            borderRadius: 4,
            padding: '0 8px',
            border: `1px solid ${getColor}`,
            display: 'inline-block',
            cursor: isDisplay ? 'pointer' : 'inherit',
          }}
        >
          {convertOptions(v, riskStatusOptions)}
        </div>
      },
    },
    {
      title: '应急预案',
      dataIndex: 'contingencyPlan',
      width: 130,
      ellipsis: true,
    },

  ]
}


const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)

export default function MajorRisk(props) {
  const { isPo, isPMM, params, setParams, refresh, confirm, rankOptions, whetherOptions, riskStatusOptions, projectId, isDisplay } = props
  const [mode, setMode] = useState('detail')
  const [dataSource, setDataSource] = useState([]);
  const [currentInfo, setCurrentInfo] = useState()
  const { doFetch } = usePost()

  const [editInfo, setEditInfo] = useState()

  const isDetail = useMemo(() => mode === 'detail', [mode])
  const riskFollows = useMemo(() => _.get(params, 'riskFollows'), [params])

  useEffect(() => {
    setDataSource(_.isEmpty(riskFollows) ? [] : _.orderBy(riskFollows, 'sort', 'asc'))
  }, [riskFollows])

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex && isDisplay) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      );
      const list = _.map(newData, (o, i) => _.assign({}, o, { sort: i + 1 }))
      doFetch('/riskFollow/update', list)
        .then(() => {
          refresh()
        })
        .catch(err => {
          Messager.show(err._message, { icon: 'error' })
        })
      setDataSource(newData);
    }
  }, [doFetch, refresh, dataSource, isDisplay])

  const updateShowList = useCallback((oldValue, newValue) => {
    if (oldValue === newValue) return
    const postParams = _.assign({}, {
      [_.get(editInfo, 'editField')]: newValue,
      id: _.get(editInfo, 'editId'),
    })
    doFetch(EDIT_URL, [postParams])
      .then(() => {
        afterRefresh()
      }).catch((err) => {
        Messager.show(err._message, { icon: 'error' });
      })
    function afterRefresh() {
      setDataSource(oldList => {
        const cloneList = _.clone(oldList)
        _.forEach(cloneList, item => {
          if (item.id === _.get(editInfo, 'editId')) {
            item[editInfo.editField] = newValue
          }
        })
        return cloneList
      })
      setEditInfo(null)
      Messager.show('修改成功', { icon: 'success' });
    }
  }, [doFetch, editInfo])

  const columns = useMemo(() => getColumns({ isDisplay, rankOptions, whetherOptions, riskStatusOptions, editInfo, setEditInfo, updateShowList, setCurrentInfo }), [isDisplay, rankOptions, whetherOptions, riskStatusOptions, editInfo, updateShowList])

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className='dev-project-major-risk'>
      <Table
        size='small'
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        scroll={{
          x: 800,
        }}
      />
      <div className='risk-remarks'>
        <div className='flex header'>
          <div className='title'>风险备注</div>
          {
            isDisplay &&
            <div className='icon-btn' style={{ marginLeft: isDetail ? 0 : 24 }}>
              {isDetail && <TextIconBtn text='' icon='bianji2' onClick={() => {
                setMode('edit')
                refresh()
              }} />}
              {
                !isDetail && <>
                  <Button primary onClick={() => {
                    confirm(true)
                    setMode('detail')
                  }}>保存</Button>
                  <Button text onClick={() => {
                    setMode('detail')
                    refresh()
                  }}>取消</Button>
                </>
              }
            </div>
          }
        </div>
        <Form value={params} onChange={setParams}>
          <FormInput bind='riskReason' component={isDetail ? Display : TextAreaInput} />
        </Form>
      </div>
      {
        _.includes(['add', 'detail'], _.get(currentInfo, 'mode')) &&
        <RiskFollowUpDetail close={() => setCurrentInfo(null)}
          refreshViewList={refresh}
          {...{ currentInfo, projectId, isPo, isPMM, rankOptions, whetherOptions, riskStatusOptions }}
        />
      }
    </div>
  )
}

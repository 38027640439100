import React, { useState, useMemo } from 'react';
import _ from 'lodash'
import { Dialog, Button, MessageBox, Messager } from 'rootnet-ui'
import Icon from '../../../../components/Icon'
import MetadataFiltering from './MetadataFiltering'
import { useApi, useGet } from "../../../../utils/hook"
import api from '../../../../base/task'
import './MetadataImportDialog.scss'

const URLS = '/common/globalconst?globalConst=ImportFilesMemo'
const accept = ".xlsx" // .csv,.xls, 后缀名
const TYPE_NAME = {
  0: '数据字典',
  1: '常量定义',
  2: '根网数据类型',
  3: '标准词根查询'
}


export default function MetadataImportDialog(props) {
  const { close, tabIndex, defaultOptions, refresh, checkFlag, title } = props
  const { data, doFetch } = useApi()

  const [params, setParams] = useState()
  const [show, setShow] = useState(false)
  const [name, setName] = useState()
  const [message, setMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  // tabIndex表示菜单index
  // 0: 数据字典    1： 常量定义    2：根网数据类型

  return (
    <Dialog
      cancel={close}
      header={'导入'}
      className='MetadataImport'
      loading={loading}
      confirm={addFile}
      confirmButtonDisabled={!name}
      confirmButtonText='导入'
    >

      <DataPreparation tabIndex={tabIndex} />
      <UploadData name={name} setName={setName} setParams={setParams} tabIndex={tabIndex} checkFlag={checkFlag} />

      {show && <MetadataFiltering title={title} tabIndex={tabIndex} defaultOptions={defaultOptions} data={data} closeAll={close} />}
      {message && <MessageBox header='提示' cancelButtonVisible={false} confirm={() => setMessage(null)} className={'content-center-dialog'}>{message}</MessageBox>}
    </Dialog>
  )

  function addFile() {
    setLoading(true)
    doFetch('/field/upload', 'post', params).then(() => {
      refresh()
      setShow(true)
      Messager.show(`执行成功`, { icon: 'success' })
      setLoading(false)
    }).catch(err => {
      setMessage(err._message)
      setLoading(false)
    })
  }
}

function DataPreparation(props) {//准备数据
  const { tabIndex } = props
  const [open, setOpen] = useState(false)

  const { data: ruleData } = useGet(URLS)

  const rule = useMemo(() => {
    if (ruleData) return _.filter(ruleData, o => TYPE_NAME[tabIndex] === o.displayName)
  }, [ruleData, tabIndex])

  return (
    <div>
      <p>① 准备数据</p>
      <p>

        所有允许导入的{TYPE_NAME[tabIndex]}字段请参考模板;{TYPE_NAME[tabIndex]}字段不符合规则，整条数据不予以导入
          <span className='arrow_drop_down' onClick={() => setOpen(!open)}>
          更多字段导入规则
            <Icon name='zhankaijiantouxia' style={{ transform: !open ? 'none' : 'rotate(-180deg)' }} />
        </span>
      </p>
      {open && <ShowText />}
      <p className='Download'>
        <span onClick={() => api.download(`/field/download?flag=${tabIndex}`)}><Icon name='daochu' /> <span>下载导入模板</span></span>
      </p>
    </div>
  )
  function ShowText() {
    return <p dangerouslySetInnerHTML={{ __html: _.get(rule, '[0]memo').replace(/\n/g, '<br/>').replace(/\t/g, '&emsp;') }} />
  }
}

function UploadData(props) {//上传数据
  const { name, setName, tabIndex, checkFlag, setParams } = props
  const id = parseInt(Math.random() * 1000)
  return (
    <div>
      <p>② 上传数据文件</p>
      <p>目前支持的文件类型为{accept}。</p>

      <label htmlFor={id}>
        <Button cancel style={{ marginLeft: '18px' }}>添加文件</Button>
      </label>
      <input id={id} type="file"
        className="file-input" onChange={fileChange}
        accept={accept}
      />
      <span style={{ marginLeft: '10px' }}>{name}</span>
    </div>
  )

  function fileChange(e) {
    const file = _.get(e, 'target.files[0]')
    const fileData = new FormData()
    fileData.append('file', file)
    fileData.append('flag', tabIndex)
    fileData.append('isApproval', checkFlag)
    const fileType = _.get(file, 'name').split('.').pop()
    if (fileType !== 'xlsx') return Messager.show('仅支持 .xlsx 格式的文件', { icon: 'error' })
    setName(_.get(file, 'name'))
    setParams(fileData)
  }
}
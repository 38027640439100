import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import Api from '../../../../base/task';
import './leftMenu.scss';
import { hasData } from 'rootnet-core/system';

// const mockUrl = 'http://dev.croot.com/mock/rims';
const mockUrl = '';
let time = 0;
let newAct = null;
let _onClick = null;
let initFlag = 0;

function LeftMenu(props) {
    const { onClick, pid } = props;
    const [menu, setMenu] = useState([]);
    const [subMenu, setSubMenu] = useState(null);
    const [act, setAct] = useState(null);
    const [sonAct, setSonAct] = useState(null);
    _onClick = onClick;

    const hasId = useCallback((item) => {
        const temp = _.get(item, 'children')
        if (!hasData(temp)) return;
        let flag = temp.some(x => _.toString(_.get(x, 'id')) === pid)
        if (!flag) flag = temp.some(x => {
            return hasId(x)
        });
        return flag
    },[pid])

    useEffect(() => {
        Api.get(`${mockUrl}/worktime/jobtype/list`).then(res => {
            const hiddenMenuList = [10, 11, 17, 27, 76, 2011, 2017, 3001, 7600];
            res.data = _.filter(res.data, x => !_.includes(hiddenMenuList, x.id));
            setMenu(res.data)
            let list = _.filter(res.data, item => {
                console.log(hasId(item))
                // return (_.toString(item.id) === pid || _.get(item,'children',[]).some(x=>_.toString(_.get(x,'id'))===pid))});
                return (_.toString(item.id) === pid || hasId(item))
            });

            list = _.isEmpty(list) ? res.data[0] : list[0];
            setAct(list);
        }).catch(err => {
            console.log(err)
        })
    }, [pid,hasId])

    useEffect(() => {
        newAct = act;
        if (!_.isObject(act)) return;
        let subShow = [13, 25, 26];
        let child = null;
        if (_.includes(subShow, act.id)) child = act.children;
        setSubMenu(child)
        let childIndex = 0;
        if (initFlag === 0) {
            childIndex = _.findIndex(child, x => _.toString(_.get(x, 'id')) === pid || hasId(x));
            initFlag = 1;
        }
        setSonAct({ ..._.get(child, `${childIndex === -1 ? 0 : childIndex}`) })
    }, [act,hasId,pid])

    useEffect(() => {
        time++;
        if (time < 2) return;
        _onClick(newAct, sonAct)
    }, [sonAct])

    return <div className={`work-time-detail-left ${_.isEmpty(subMenu) ? '' : 'has'}`}>
        <div className={`l-menu`}>
            {
                _.map(menu, (item, i) => {
                    return <div
                        className={`menu-item ${_.get(act, 'id') === item.id ? 'active' : ''}`}
                        key={i}
                        onClick={() => { setAct(item) }}
                        title={item.name}
                    >
                        {item.name}
                    </div>
                })
            }
        </div>
        {
            !_.isEmpty(subMenu) &&
            <div className="l-menu">
                {
                    _.map(subMenu, (item, i) => {
                        return <div
                            className={`menu-item ${_.get(sonAct, 'id') === item.id ? 'active' : ''}`}
                            key={i}
                            onClick={() => { setSonAct(item) }}
                            title={item.name}
                        >
                            {item.name}
                        </div>
                    })
                }
            </div>
        }
    </div>


}

export default LeftMenu

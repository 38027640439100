import React, { useState, useCallback, useMemo, useEffect } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { isNil } from 'rootnet-core/format'
import { Dialog, Messager, Tooltip } from 'rootnet-ui'
import { Form, FormInput, Select, TextArea, DatePicker, Display } from 'rootnet-edit'
import { useApi, useGet } from '../../../../../utils/hook'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import { dateFormat } from 'rootnet-core/dateFormat'
import './index.scss'

const ADD_URL = '/service/add'
const OPTIONS_URLS = [
  '/verQuery/queryCustOptions',
  // 'common/globalconst?globalConst=ApplyVersionPrincipal',
]

const HFormInput = props => <FormInput horizontal labelWidth={120} componentWidth={200} {...props} />

export default function VersionApplication(props) {
  const { id, defaultData = null, refresh, close } = props
  const [params, setParams] = useState()
  const [error, setError] = useState({})
  const { doFetch } = useApi()
  const { data: optionsRes } = useGet(OPTIONS_URLS)

  const [custSystemOptions] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2] = optionsRes

    return [
      _.map(d1, x => ({ value: x.projectId, text: x.projectName, tag: `${x.projectName}${x.projectId}` })),
      d2
    ]
  }, [optionsRes])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const confirm = useCallback(() => {
    if (_.size(_.trim(params?.ReqDetail)) === 0) return Messager.show('请填写必填项', { icon: 'error' })
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    doFetch(ADD_URL, 'post', { ...params, id, isWeb: '0' })
      .then(() => {
        close()
        refresh()
        Messager.show('申请成功', { icon: 'success' })
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [check, doFetch, params, id, close, refresh])

  useEffect(() => {
    if (_.isNil(defaultData)) return
    // const versionNum = _.get(_.find(productOptions, o => o.value === defaultData?.productName), 'productLine')
    const date = defaultData?.promiseDate
    setParams({
      IssueDesc: defaultData?.shortDesc, // 问题简单描述
      // Principal: _.get(_.find(principal, o => o.interiorId === versionNum), 'displayName'), // 负责人
      Customer: defaultData?.custId, // 客户系统
      IssueId: defaultData?.id, // Issue编号
      issueId: defaultData.issueId,
      version: defaultData?.versionId, // 基础版本
      TracerID: defaultData?.tracerId, // 修复问题研发任务
      // applyversion: defaultData?.custId, // 申请版本
      PromiseDate: _.isNil(date) ? null : new Date(date), // 要求发布时间
      // ReqDetail: defaultData?.custId, // 问题详细描述
      ISSUESTYPE: defaultData?.issuesType,
      productName: defaultData?.productName,
      prodsubsysid: defaultData?.prodsubsysid,
    })
  }, [defaultData])

  return (
    <Dialog header={'版本申请新增'} className='version-application' cancel={close} confirm={confirm}>
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <HFormInput required label='问题简单描述' bind='IssueDesc' componentWidth={536} />
        {/* <HFormInput label='记录人' bind='CreateUser' component={Select} /> */}
        <HFormInput required label='产品负责人' bind='Principal' component={UserSelect} />
        <HFormInput required label='客户系统' bind='Customer' component={Select} options={custSystemOptions} />
        <HFormInput label='Issue编号' bind='issueId' component={Display} />
        <HFormInput required label='基础版本' bind='version' />
        <HFormInput label='修复问题研发任务' bind='TracerID' component={Display} className='tracer-id'
          bindInConvert={v => <Tooltip title={v}><div className='ellipsis-line'>{v}</div></Tooltip>} />
        <HFormInput required label='申请版本' bind='applyversion' />
        <HFormInput required label='要求发布时间' bind='PromiseDate' component={DatePicker} bindOutConvert={v => dateFormat('YYYY-MM-DD', v)} />
        <HFormInput required label='问题详细描述' bind='ReqDetail' component={TextArea} componentWidth={536} className={cls('reason', { error: _.size(_.trim(params?.ReqDetail)) > 0 ? false : true })} />
      </Form>
    </Dialog>
  )
}

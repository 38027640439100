import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { pathParamsFor, strParams } from "../../../utils/publicFun";
import { useGet } from "../../../utils/hook";
import Authorized from './Authorized';
import { LeftMenu } from "../LeftMenu";
import './Authorized.scss'

function BaseInfo(props) {
    const { location: { search } } = props;
    const [params, setParams] = useState(pathParamsFor(search));
    const [productId, setProductId] = useState(_.get(params, 'id'));
    const { data, loading, error } = useGet('develop/project/list?'.concat(strParams(_.omit(params, 'id'))));

    useEffect(() => {
        setParams(x => {
            if (_.toString(x.id) === _.toString(productId)) return x;
            return _.assign({}, x, { id: productId })
        });
    }, [productId]);

    const projectId = useMemo(() =>_.get(_.find(data, x => _.toString(x.id) === _.toString(productId)), 'projectId'), [data, productId]);
    const principal = useMemo(() => _.get(_.find(data, x => _.toString(x.id) === _.toString(productId)), 'principal'), [data, productId]);
    return (<div className='project-list-auth fill'>
        < LeftMenu productId={productId} data={data} loading={loading} error={error} onClick={setProductId} />
        <Authorized projectId={projectId} id={productId} projectType='DEV' principal={principal} />
    </div>);
}

export default BaseInfo;
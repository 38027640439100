import React from 'react';
import pic from '../../../assets/images/nodata.png'

function NoPage(props) {
    let { userPic = pic, text = '页面规划中...', width = 80 } = props;
    return <div className="flex center" style={{ height: '100%' }}>
        <div className='flex-y center'>
            <img src={userPic} alt="" width={width} style={{ marginBottom: 8 }} />
            <span style={{ color: '#7A8199', fontSize: '14px', marginTop: '4px' }}>{text}</span>
        </div>
    </div>
}

export default NoPage;
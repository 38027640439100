import React from 'react';
import {Dialog} from 'rootnet-ui'
import { Form, Grid} from "rootnet-edit";
import Remark from "../common/Remark";
import './SubmoduleDetailDialog.scss'

function SubmoduleDetailDialog(props) {
    const {data,close,children} = props
    return <Dialog header={data.prodSubsysSonName} contentClassName='submodule-detail-dialog x' confirm={close} cancel={close}
                   confirmButtonText={'关闭'} cancelButtonVisible={false}>
        <div className="card-item">
            <Form value={data}>
                <Grid cols={2}>
                    {children}
                </Grid>
            </Form>
            <Remark label='描述' text={data.prodSubsysSonDirections} twoLine labelWidth={90} style={{margin: 8}}/>
        </div>
    </Dialog>
}

export default SubmoduleDetailDialog;
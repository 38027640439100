import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Messager } from 'rootnet-ui'
import { Collapse, Upload, Modal } from 'antd'
import { useGet } from 'rootnet-biz/lib/hooks'
import { dateFormat, toDate } from 'rootnet-core/dateFormat'
import { Icon } from '../../../components'
import './index.scss'

const { str14ToDate } = toDate

const { Panel } = Collapse

const IMG_FILE_URL = '/mapping/files/queryNew'

export default function MyFeedback(props) {
  const { data, activeKey, setActiveKey, getBase64 } = props
  const [imgItem, setImgItem] = useState({})
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const { doFetch: imgFetch } = useGet()
  const listData = useMemo(() => _.orderBy(data, 'createTime', 'desc'), [data])

  useEffect(() => {
    if (_.isEmpty(activeKey)) return
    const keys = _.compact(_.map(activeKey, v => _.get(_.split(v, '-'), '[1]')))
    if (_.every(keys, v => !_.isNil(imgItem[v]))) return
    const id = _.last(keys)

    imgFetch(`${IMG_FILE_URL}?referenceId=${id}&funcCode=20`)
      .then(res => {
        setImgItem(x => _.assign({}, x, {
          [id]: _.map(res, o => _.assign({}, _.pick(o, ['uid', 'name', 'url']), { status: 'done' }))
        }))
      })
      .catch(err => Messager.show(err._message, { icon: 'error' }))
  }, [activeKey, imgFetch, imgItem])

  const showUploadList = useMemo(() => {
    return ({
      showPreviewIcon: true,
      showDownloadIcon: true,
      showRemoveIcon: false,
    })
  }, [])

  const onDownload = useCallback((file) => {
    const url = _.get(file, 'url') || _.get(file, 'response.url')
    window.open(url, "_self")
  }, [])

  const handlePreview = useCallback(async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  }, [getBase64])

  return (
    <>
      <div className='problemResearch-myFeedback l-scroll-style'>
        <div className='myFeedback-header'>
          <div>问题简述</div>
          <div>时间</div>
        </div>
        <Collapse
          // defaultActiveKey={['1']}
          activeKey={activeKey}
          onChange={setActiveKey}
          expandIconPosition='right'
        >
          {
            _.isEmpty(listData) ? <Nodata text='暂无反馈信息' /> :
              _.map(listData, item => {
                return <Panel header={mapStyle(item.issueDesc, item.createTime)} key={`${item.csRecordId}-${_.isNil(item?.id) ? '' : item?.id}`}>
                  <div className='merge'>
                    <DisplayDetails label='问题编号' text={item.csRecordId} />
                    <DisplayDetails label='问题状态' text={_.isNil(item?.statusName) ? '' : <div style={{
                      color: item.displayColor,
                      border: `1px solid ${item.displayColor}`,
                      padding: '0px 8px',
                      borderRadius: '4px',
                    }}>{item?.statusName}</div>} />
                  </div>
                  <DisplayDetails label='详细描述' text={<div dangerouslySetInnerHTML={{ __html: item.reqDetail }} />} />
                  <DisplayDetails label='图片' text={<Upload listType="picture-card" onRemove={false} fileList={_.get(imgItem, item.id, [])} onPreview={handlePreview} showUploadList={showUploadList} />} onDownload={onDownload} />
                </Panel>
              })
          }
        </Collapse>
      </div>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  )

  function mapStyle(issueDesc, time) {
    return <div className='mapStyle'>
      <div>{issueDesc}</div>
      <div>{getTime(time)}</div>
    </div>
  }
}

function DisplayDetails({ label, text }) {
  return <div className='display-details'>
    <span className='myFeedback-text'>{label}：</span>
    {
      _.isArray(text) ? <div className='solution'>
        {
          _.map(text, (item, i) => {
            return <div className='solution-data' key={i}>
              <div>
                <span>{item.inputuser}</span>
                <span>{getTime(item.inputdate)}</span>
              </div>
              <div className='myFeedback-text'>{item.detail}</div>
            </div>
          })
        }
      </div>
        :
        <span className='myFeedback-text'>{text}</span>
    }

  </div>
}

function getTime(time, second = true) {
  if (_.isNil(time)) return ''
  if (second) return dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(time))
  return dateFormat('YYYY-MM-DD HH:MM:SS', str14ToDate(time)).substring(0, 16)
}

function Nodata(props) {
  let { text = '没有相关数据', size = 34 } = props;
  return <div className="flex center" style={{ height: '100%' }}>
    <div className="flex-y center" style={{ margin: '48px 0' }}>
      <Icon style={{ fontSize: size }} name='shuju' />
      <span style={{ color: 'rgb(175, 181, 190)', fontSize: '16px', marginTop: '8px' }}>{text}</span>
    </div>
  </div>
}
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import cls from 'clsx'
import { Tooltip } from 'rootnet-ui'
import { FormInput } from 'rootnet-edit'
import { toDate, dateFormat } from 'rootnet-core/dateFormat'
import { Icon } from '../../../components'
import TreeTable from '../../common/TreeTable'
import { Box } from '../../common/commonComponent'
import { TextIconBtn } from '../../common/TextIconBtn'
import { getCurrentUserInfo, useFuncCode, useRolePermissions } from '../../common/commonMethod'
import DelMessage from '../../../components/DelMessage'
import TestCaseDetail from './components/testCaseDetail'
import DisplayForm from './components/displayForm'
import { pathSearchFor, strParams } from '../../../utils/publicFun'
import gd from '../../../base/global'
import './index.scss'
import useGet from "rootnet-biz/es/hooks/useGet";

const LIST_URL = '/caseLibrary/list'
const DEL_URL = '/caseLibrary/delete/caseLibrary'

const gridOptsFor = () => ({
  resizable: true,
  fixedLeft: 1,
  autoFill: true,
  nilText: '-',
  emptyText: '-',
});

const getColumns = (setMode, setDetail, data, more) => {
  const { isFuncCodeAdd, isFuncCodeDel, expanded, setExpanded, isCAM, isFuncCodeEdit } = more
  return [
    { header: <UnfoldAndStow />, bind: "name", tooltip: true, width: 200, convert: createOperation },
    { header: "用例数量", bind: "allCount", tooltip: true, width: 60 },
    { header: "创建人", bind: "createUser", tooltip: true, width: 80 },
    { header: "创建时间", bind: "createTime", tooltip: true, width: 130, convert: createTime },
    { header: "更新人", bind: "updateUser", tooltip: true, width: 80 },
    { header: "更新时间", bind: "updateTime", tooltip: true, width: 130, convert: createTime },
  ]

  function UnfoldAndStow() {
    return <>
      <TextIconBtn
        style={{ fontSize: 12, marginLeft: 0, fontWeight: 400, color: '#A9A9A9' }}
        onClick={() => setExpanded(x => !x)} icon={expanded ? 'biaogeshouqi' : 'biaogezhankai'} />
      用例库
    </>
  }

  function createTime(v) {
    const { timestampToDate } = toDate
    return dateFormat('YYYY-MM-DD HH:MM:SS', timestampToDate(v))
  }

  function createOperation(v, o, i, d) {
    const loginUserName = _.get(gd, 'User.operator_name')
    return <>
      <span className='enter-detail-field' onClick={() => setDetail({ o, libraryList: data })}>{v}</span>
      <span className='edit-icon'>
        {
          (_.includes([..._.get(o, 'adminUserArrays') ? _.get(o, 'adminUserArrays') : [], o.creatName], loginUserName) || isCAM || isFuncCodeEdit) &&
          <Tooltip title='编辑' getPopupContainer={trigger => trigger.parentNode}>
            <span>
              <Icon name='bianji2' onClick={() => setMode({ modes: 'edit', id: o.id, pid: o.pid })} />
            </span>
          </Tooltip>
        }
        {
          (Number(o.pid) === 0 && isFuncCodeAdd) &&
          <Tooltip title='添加子用例库' getPopupContainer={trigger => trigger.parentNode}>
            <span>
              <Icon name='tianjia' onClick={() => setMode({ modes: 'addChild', pid: o.id })} />
            </span>
          </Tooltip>
        }
        {
          isFuncCodeDel &&
          <Tooltip title='删除' getPopupContainer={trigger => trigger.parentNode}>
            <span>
              <Icon
                name='shanchu2'
                className='del-icon'
                onClick={() => setMode({ modes: 'delete', data: o })}
              />
            </span>
          </Tooltip>
        }
      </span>
    </>
  }

};

export default function TestCaseIndex(props) {
  const { location } = props
  const [detail, setDetail] = useState(false)
  return (
    <>
      {detail && <TestCaseDetail detail={detail} setDetail={setDetail} />}
      <TestCase detail={detail} setDetail={setDetail} location={location} />
    </>
  )
}

function TestCase(props) {
  const { detail, setDetail, location } = props
  const { treeId = null, initId = null } = useMemo(() => pathSearchFor(_.get(location, 'search')), [location]);
  const [mode, setMode] = useState()
  const [params, setParams] = useState({})
  const [expanded, setExpanded] = useState(true)
  const [currentId, setCurrentId] = useState()
  const [tableData, setTableData] = useState([])
  const { data, loading, error, doFetch } = useGet() // 用例查询列表
  const [isFuncCodeAdd, isFuncCodeDel, isFuncCodeEdit] = useFuncCode(['050101', '050102', '050100'])
  const [isCAM] = useRolePermissions(['CAM'])

  const userAccount = useMemo(() => getCurrentUserInfo('operator_name'), [])

  const refresh = useCallback((parameter = {}, flag = false) => {
    const parameters = {
      login: userAccount,
      ...parameter,
    }
    setCurrentId(_.get(gd, 'testCaseCurrentId', null))
    doFetch(`${LIST_URL}?${strParams(parameters)}`)
      .then(res => {
        if (flag && !_.isNil(treeId)) {
          setDetail({ o: { id: treeId }, libraryList: res, initId })
        }
      })
  }, [doFetch, initId, setDetail, treeId, userAccount])

  useEffect(() => {
    refresh({}, true)
  }, [refresh])

  const option = useMemo(() => gridOptsFor(), [])
  const columns = useMemo(() => getColumns(setMode, setDetail, data, { isFuncCodeAdd, isFuncCodeDel, expanded, setExpanded, isCAM, isFuncCodeEdit }), [data, expanded, isFuncCodeAdd, isFuncCodeDel, setDetail, isCAM, isFuncCodeEdit])

  useEffect(() => {
    if (data) {
      setTableData(_.clone(data))
    }
  }, [expanded, data])

  return (
    <div className={cls('testCase fill flex-y', { isTestCase: detail })}>

      <div className='option'>
        <FormInput
          componentWidth={230}
          suffix={<Icon name={'sousuo1'} />}
          value={_.get(params, 'searchName')}
          placeholder='用例库 / 创建人'
          onChange={search}
        />
      </div>

      <Box className='x-card-singlegrid' loading={loading} error={error} title='用例库列表' data={data} extra={<Extra />}>
        <TreeTable
          data={tableData}
          indentSize={40}
          option={option}
          columns={columns}
          className='treeTable'
          icon={{ befor: 'biaogezhankai', after: 'biaogeshouqi', color: '#A9A9A9' }}
          defaultExpanded={expanded}
          currentId={currentId}
          currentIdName={'testCaseCurrentId'}
        />
      </Box>

      {
        _.includes(['add', 'edit', 'addChild'], mode?.modes) &&
        <DisplayForm refresh={() => refresh(params)} close={() => setMode(null)} {...mode} />
      }

      {
        mode?.modes === 'delete' &&
        <DelMessage
          refresh={() => refresh(params)}
          close={() => setMode(null)}
          url={`${DEL_URL}?cseLibrarId=${mode?.data.id}&status=2`}
        >
          <div>
            <div>{`用例库：${_.get(mode, 'data.name', '')} `}</div>
            <span style={{ fontWeight: 900 }}>包含{_.get(mode, 'data.allCount', 0)}条</span>
            {`测试用例，删除后对应的用例一并删除，是否确定？`}
          </div>
        </DelMessage>
      }
    </div>
  )

  function search(v) {
    setParams(x => _.assign({}, x, { searchStr: v }))
    refresh({ searchStr: v })
  }

  function Extra() {
    return isFuncCodeAdd ? <TextIconBtn icon='tianjia' text='新建用例库' onClick={() => setMode({ modes: 'add' })} /> : <></>
  }
}

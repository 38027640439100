import React, { useState } from 'react'
import _ from 'lodash'
import { Button } from 'rootnet-ui'
import { Form, FormInput, Select, RadioGroup } from 'rootnet-edit'
import UserSelect from '../../../../common/personSelectPop/UserSelect'
import './index.scss'
import DateSelector from '../../../../common/dateSelector/DateSelector'
import CascadeSelect from '../../../../common/cascadeSelect/CascadeSelect'

const displayUsageOpt = [
  { text: "按记录列示", value: 'selectRecordPage' },
  { text: "按项目/事务分组", value: 'selectGroupByProjectPage' },
  { text: "按员工分组", value: 'selectEmployeeGroupingPage' },
]

export default function Option(props) {
  const { initParams = {}, search, setParameter, active, productLinesOpt, projectOpt, affairOpt, manHourTypeOpt, stateOpt, deptOptions, workingProjectTypeOpt = [], pageSize, setPageSize } = props
  const [params, setParams] = useState(initParams)
  const [isShow, setIsShow] = useState(false)

  return (
    <div className='flex c-options work-time-review-and-search-list-option'>
      <Form value={params} onChange={setParams}>
        <FormInput label='工时周期' bind='period' component={DateSelector} componentWidth={220} range clear />
        <FormInput label='员工' bind='userAccounts' multiple component={UserSelect} allowClear />
        <FormInput label='部门' bind='departments' component={CascadeSelect} multiple search clear options={deptOptions} />
        <FormInput label='显示方式' bind='typeFlag' component={RadioGroup} options={displayUsageOpt} />
        <div className='is-spread' onClick={() => setIsShow(x => !x)}>
          {isShow ? '收起' : '展开'}
        </div>
        <div className='search-btn-group flex'>
          <Button className={'search-btn'} primary onClick={() => {
            const par = { ...params, pageSize }
            search(par)
            setParameter(par)
          }} >查询</Button>
          <Button className={'reset-btn'} text onClick={() => {
            setParams(x => _.assign({}, ..._.map(x, (v, k) => ({ [k]: null })), initParams))
            search(initParams)
            setParameter(initParams)
            setPageSize(20)
          }} >重置</Button>
        </div>
        <div className='query-condition' style={{ display: isShow ? 'block' : 'none' }}>
          {active !== 'audit' && <FormInput label='状态' bind='states' component={Select} multiple search clear options={stateOpt} />}

          <FormInput label='项目产品线' bind='productLines' component={Select} multiple search clear options={productLinesOpt} />
          <FormInput label='研发项目' bind='devList' component={Select} multiple search clear options={projectOpt} />
          <FormInput label='事务' tree bind='transactionList' component={Select} multiple search clear options={affairOpt} />
          <FormInput label='项目PO' bind='projectPOs' component={UserSelect} multiple allowClear />
          <FormInput label='工时类型' bind='types' component={Select} multiple search clear options={manHourTypeOpt} />
          <FormInput label='当前处理人' bind='reviewers' component={UserSelect} multiple allowClear />
          <FormInput label='项目工时类型' bind='workingProjectType' component={Select} search clear options={workingProjectTypeOpt} />
        </div>
      </Form >

    </div >
  )
}
import React, { useCallback, useMemo, useReducer, useState, useEffect } from "react";
import clsx from "clsx";
import _ from "lodash";
import { FormInput } from "rootnet-edit";
import { DataGrid, Messager, Pagination, Switch } from "rootnet-ui";
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import convertTableAlign from "../../view/convertTableAlign";
import findConvert from "../../view/findConvert";
import useGetViewConfig from "../../view/hooks/useGetViewConfig";
import usePost from "rootnet-biz/es/hooks/usePost";
import useRefreshList from "../../view/hooks/useRefreshList";
import useGetDateOptions from "../../view/hooks/useGetDateOptions";
import useGetTreeList from "../../view/hooks/useGetTreeList";
import ViewExtraTextIcon from "../../view/ViewExtraTextIcon";
import CustomizeOptions from "../../customizeOptions/CustomizeOptions";
import { Box } from "../../commonComponent";
import { TextIconBtn } from "../../TextIconBtn";
import CardModule from "./components/CardModule";
import gd from "../../../../base/global";
import { useApi, useGet } from "../../../../utils/hook";
import { Icon } from "../../../../components";
import TemplateDetail from "./control/TemplateDetail";
import DelMessage from '../../../../components/DelMessage'
import TemplateInForm from "./control/TemplateInForm";
import { useFuncCode } from "../../commonMethod";
import ImportApiDialog from "../../ImportApiDialog";
import { tableFormat } from "./components/method";
import useGetIdList from "../../view/hooks/useGetIdList";
import useGetAppendData from "../../view/hooks/useGetAppendData";
import ExportExcel from "./components/ExportExcel";
import './index.scss'

const TASK_URL = '/projectTemplate/tasksInterface'
const IMPORT_URL = '/projectTemplate/upload'
const EXPORT_ABNORMAL_DATA_URL = '/projectTemplate/export'
const TEMPLATE_URL = '/projectTemplate/model/download?modelName=项目模板导入模板.xlsx'
const DEL_URL = '/projectTemplate/delete'
const EDIT_URL = '/projectTemplate/update'
const ACTIVE_FLAG_URL = '/UserSetting/getUniversalInterfaces?code=InteriOrid&codeName=ConstDisplayName&tableName=GlobalConst&globalConst=ActiveFlag'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, user, edit, setEdit, value, setValue, refresh, statusData, setModeAll, isFuncCodeEdit, isFuncCodeAdd, isFuncCodeDel, appendData, isType } = props
  const { id, fieldId } = edit || {}
  const customColumns = [
    { header: '任务数', bind: 'tasks', tooltip: true, convert: (v, o) => (tasks(o)), class: 'progress-color' },
  ]

  return _.concat(
    [{ header: '#', width: 40, convert: (v, o, i) => i + 1, align: 'center' }],
    _.map(fieldList, x => handleColumn(x))
  )

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') !== 'Y') {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: (v, o, _i, d) => convertAll(fieldItem, v, convertCollection, dateOptions, o)
      }
    } else {
      return _.find(customColumns, x => x.bind === fieldItem.columnId) || { header: '', bind: '', width: 100 }
    }
  }

  function tasks(o) {
    const findData = _.find(appendData, item => item?.id === o?.id)
    o.tasks = Number(_.get(findData, 'addRes1', '0'))
    return Number(_.get(findData, 'addRes1', '0'))
  }

  function convertAll(fieldItem, v, convertCollection, dateOptions, o) {
    switch (fieldItem.fieldId) {
      case 'templateName':
        return <div className='table-edit'>
          {
            (id === o.id && fieldId === fieldItem.fieldId) ?
              <FormInput
                autoFocus
                onEnter={() => refresh(o.id, fieldItem.columnId, { [fieldItem.fieldId]: value }, o, value === v)}
                onBlur={() => refresh(o.id, fieldItem.columnId, { [fieldItem.fieldId]: value }, o, value === v)}
                onChange={v => setValue(v)}
                value={value}
              /> :
              <>
                <span className='title'
                  onClick={() => setModeAll({
                    mode: isFuncCodeEdit ? 'edit' : 'detail',
                    data: o,
                    type: (o.templateType === 'project' || !isType) ? 'impProject' : o.templateType,
                    initProjectType: o.projectType
                  })}
                >
                  {findConvert(fieldItem, v, convertCollection, dateOptions) || '-'}
                </span>
                <span className='edit-icon'>
                  {
                    isFuncCodeEdit && <Tooltip title='编辑' getPopupContainer={trigger => trigger.parentNode}>
                      <span>
                        <Icon name='bianji2' onClick={() => {
                          setEdit({ id: o.id, fieldId: fieldItem.fieldId })
                          setValue(findConvert(fieldItem, v, convertCollection, dateOptions))
                        }
                        } />
                      </span>
                    </Tooltip>
                  }
                  {
                    isFuncCodeAdd && <Tooltip title='复制' getPopupContainer={trigger => trigger.parentNode}>
                      <span>
                        <Icon
                          name='tongjihuizong'
                          className='del-icon'
                          onClick={() => setModeAll({ mode: 'copy', data: o, type: (o.templateType === 'project' || !isType) ? 'impProject' : o.templateType, initProjectType: o.projectType })}
                        />
                      </span>
                    </Tooltip>
                  }
                  {
                    (isFuncCodeDel || user === o.createUser) &&
                    <Tooltip title='删除' getPopupContainer={trigger => trigger.parentNode}>
                      <span>
                        <Icon
                          name='shanchu2'
                          className='del-icon'
                          onClick={() => setModeAll({ mode: 'delete', id: o.id })}
                        />
                      </span>
                    </Tooltip>
                  }
                  {
                    <Tooltip title='导出' getPopupContainer={trigger => trigger.parentNode}>
                      <span>
                        <Icon
                          name='daochu'
                          className='del-icon'
                          onClick={() => setModeAll({ mode: 'export', templateName: o.templateName })}
                        />
                      </span>
                    </Tooltip>
                  }
                </span>
              </>
          }
        </div>
      // case 'projectType':

      //   return v
      case 'status':

        return <div className='table-edit'>
          <Switch
            disabled={!isFuncCodeEdit}
            checked={v === 'Y'}
            checkedComponent={_.find(statusData, item => item.interiorId === 'Y')?.displayName}
            uncheckedComponent={_.find(statusData, item => item.interiorId === 'N')?.displayName}
            onChange={checked => {
              refresh(o.id, fieldItem.columnId, { [fieldItem.fieldId]: checked ? 'Y' : 'N' }, o, (checked ? 'Y' : 'N') === v, checked ? 'Y' : 'N')
            }}
          />
        </div>

      default:
        return findConvert(fieldItem, v, convertCollection, dateOptions) || '-';
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

let sortConfig = {}

const conditional = ({ setDataSort }) => ({
  sort: sortConfig,
  onSort: (data, sort) => {
    sortConfig = sort
    setDataSort(sort)
    const sortList = _.orderBy(data, sort.column, sort.direction)
    return sortList
  },
})

export default function TemplateMgt(props) {
  const { LIST_CODE, ADD_PERMISSION, EDIT_PERMISSION, DEL_PERMISSION, type = null } = props
  const [params, setParams] = useState(getInitParams())
  const [isLoading, setIsLoading] = useState(true)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(LIST_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { pageSize, total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [currentId, setCurrentId] = useState()
  const [isTable, setIsTable] = useState(true)
  const [edit, setEdit] = useState(null)
  const [value, setValue] = useState(null)
  const [modeAll, setModeAll] = useState(null)
  const [dataSort, setDataSort] = useState()
  const { data: statusData } = useGet(ACTIVE_FLAG_URL)
  const { doFetch } = useApi()
  const [isFuncCodeAdd, isFuncCodeEdit, isFuncCodeDel] = useFuncCode([ADD_PERMISSION, EDIT_PERMISSION, DEL_PERMISSION])
  const user = gd.User.operator_name

  const isType = useMemo(() => type === 'all', [type])

  const idList = useGetIdList(list)
  const appendData = useGetAppendData(idList, TASK_URL)

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const dateOptions = useGetDateOptions()

  const refresh = useCallback((id, fieldId, params, data, flag, val = null) => {
    if (params?.templateName === '') { setEdit(null); setValue(null); Messager.show('名称不能为空', { icon: 'error' }); return }
    if (flag) { setEdit(null); setValue(null); return }
    doFetch(EDIT_URL, 'post', { id, ...params })
      .then(() => {
        data[fieldId] = _.isNil(val) ? value : val
        setEdit(null)
        setValue(null)
      })
      .catch(err => {
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, value])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { user, edit, setEdit, value, setValue, refresh, statusData, setModeAll, isFuncCodeEdit, isFuncCodeAdd, isFuncCodeDel, appendData, isType },
    optionsAppendParams: conditional({ setDataSort })
  })

  const dataList = useMemo(() => {
    if (dataSort) { }
    if (_.isEmpty(sortConfig)) return dataGridList
    return _.orderBy(dataGridList, sortConfig.column, sortConfig.direction)
  }, [dataGridList, dataSort])

  const cardData = useMemo(() => {
    if (_.isNil(fieldList) && _.isNil(dataList)) return []
    const cardList = _.clone(dataList)
    return _.map(cardList, item => {
      _.forEach(fieldList, o => {
        item[o.fieldId] = item[o.columnId]
      })
      return item
    })
  }, [fieldList, dataList])

  const menu = useMemo(() => {
    return <Menu onClick={(e) => setModeAll({ mode: 'add', type: e.key })}>
      <Menu.Item key="devProject">研发项目</Menu.Item>
      <Menu.Item key="impProject">实施项目</Menu.Item>
      <Menu.Item key="req">需求</Menu.Item>
    </Menu>
  }, [])

  const extra = useMemo(() => {
    return <div className='extra-group flex center-y'>
      <ViewExtraTextIcon {...{ params, setParams, getList, getFieldList, allColumns, funcCode, currentViewId, setCurrentViewId, refreshList }} />
      <TextIconBtn icon='guanli' text={isTable ? '卡片展示' : '表格展示'} onClick={() => setIsTable(x => !x)} disabled={_.isEmpty(dataList)} />
      {isFuncCodeAdd && <TextIconBtn icon='daoru' text='导入模板' onClick={() => setModeAll({ mode: 'import' })} />}
      {
        isType ? <Dropdown
          overlay={menu}
          trigger='click'
          className={clsx('template-button', { 'button-disabled': !isFuncCodeAdd })}
          disabled={!isFuncCodeAdd}
        >
          <Button size='small'>创建模板 <DownOutlined /></Button>
        </Dropdown> :
          <Button
            size='small'
            className={clsx('template-button', { 'button-disabled': !isFuncCodeAdd })}
            onClick={() => setModeAll({ mode: 'add', type: 'impProject' })}
            disabled={!isFuncCodeAdd}
          >
            创建模板
          </Button>
      }
    </div>
  }, [params, getList, getFieldList, allColumns, funcCode, currentViewId, refreshList, isTable, dataList, isFuncCodeAdd, menu, isType])

  return (
    <>
      <div className={'template-mgt fill flex-y'}>
        <CustomizeOptions search={setParams} allOptions={optionsConfig} baseOptions={[]} {...{ getInitParams, funcCode }} />
        <Box title={'模版管理'} className='flex-y x-card-singlegrid' data={list} extra={extra} loading={boxLoading} error={error}>
          {
            isTable ?
              <Table option={options} data={dataList} currentId={currentId} /> :
              <div className='template-mgt-card'>
                <CardModule
                  data={cardData}
                  {...{
                    user,
                    isFuncCodeAdd,
                    isFuncCodeEdit,
                    setModeAll,
                    appendData,
                    convertCollection,
                  }} />
              </div>
          }
          <Pagination pageSize={pageSize} total={total} current={pageNum} selector
            onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
      </div>

      {/* 导入 */}
      {
        modeAll?.mode === 'import' &&
        <ImportApiDialog
          title='项目模板'
          abnormal={EXPORT_ABNORMAL_DATA_URL}
          importUrl={IMPORT_URL}
          template={TEMPLATE_URL}
          refresh={refreshList}
          parameter={{ flag: 0 }}
          defaultOptions={tableFormat}
          close={() => setModeAll(null)}
          isObj={false}
        />
      }

      {/* 导出 */}
      {
        modeAll?.mode === 'export' && <ExportExcel parameter={{ searchName: modeAll?.templateName }} close={() => setModeAll(null)} />
      }

      {/* 新增 编辑 复制 */}
      {
        _.includes(['add', 'edit', 'copy'], modeAll?.mode) &&
        <TemplateInForm close={() => setModeAll(null)} listData={dataList} {...{ modeAll, setModeAll, statusData, isFuncCodeEdit, refresh, refreshList, setCurrentId }} />
      }

      {/* 详情 */}
      {
        modeAll?.mode === 'detail' &&
        <TemplateDetail setCurrentId={setCurrentId} switch={setModeAll} listData={dataList} params={modeAll?.data} close={() => setModeAll(null)} />
      }

      {/* 删除 */}
      {
        modeAll?.mode === 'delete' &&
        <DelMessage
          method='post'
          refresh={refreshList}
          close={() => setModeAll(null)}
          url={`${DEL_URL}?id=${modeAll?.id}`}
        >
          确定删除当前内容？
        </DelMessage>
      }
    </>
  )
}

function Table(props) {
  const { option, data, currentId } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const onRowClick = useCallback((item) => {
    // gd.testPlanCurrentId = item?.id
    _.forEach(data, o => {
      return o._rowClass = item?.id === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [data])

  useEffect(() => {
    if (currentId) onRowClick({ id: currentId })
  }, [currentId, onRowClick])

  return (
    <DataGrid
      onRowClick={onRowClick}
      option={option}
      data={data}
    />
  )
}
import React, { useCallback, useState, useMemo } from 'react'
import _ from 'lodash'
import { Dialog, Messager, Switch } from 'rootnet-ui'
import { Form, FormInput, Input, Select } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import './index.scss'

const EDIT_URL = '/humanSpecialProject/update'

export default function ProjectMaintenanceEditLog(props) {
  const { close, projectTypeOpt, refresh, refreshAffair, statusOpt, initParams, setParams: refreshParams, refreshList } = props
  const [params, setParams] = useState(initParams)
  const [error, setError] = useState()
  const [continuous, setContinuous] = useState(false)
  const { doFetch } = usePost()

  const canSubmit = useMemo(() => {
    return _.some(_.values(error), x => x)
  }, [error])

  const confirm = useCallback(() => {
    if (continuous) return
    setContinuous(true)
    doFetch(EDIT_URL, params)
      .then(() => {
        refreshAffair()
        refresh()
        refreshParams(null)
        refreshList()
        setContinuous(false)
        close()
        Messager.show('编辑成功', { icon: 'success' })
      })
      .catch(err => {
        setContinuous(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, refreshAffair, refresh, close, params, refreshParams, refreshList, continuous])

  return (
    <Dialog
      header='编辑'
      cancel={close}
      className='project-maintenance-edit-log'
      confirmButtonDisabled={canSubmit}
      confirm={confirm}
    >
      <Form value={params} onChange={setParams} error={error} onError={setError}>
        <FormInput labelWidth={81} required horizontal label='项目名称' bind='projectName' component={Input} />
        <FormInput labelWidth={81} required horizontal label='项目类型' bind='projectType' component={Select} options={projectTypeOpt} />
        <FormInput

          labelWidth={81}
          horizontal
          label='状态'
          bind='status'
          component={Switch}
          options={statusOpt}
          checkedComponent={_.get(_.find(statusOpt, o => o.value === '01'), 'text')}
          uncheckedComponent={_.get(_.find(statusOpt, o => o.value === '02'), 'text')}
          checked={params?.status === '01'}
          bindOutConvert={v => v ? '01' : '02'}
        />
      </Form>
    </Dialog>
  )
}

import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { Button } from 'rootnet-ui'
import { Form, FormInput, Select } from 'rootnet-edit'
import { useGet } from '../../../../utils/hook'
import { getCurrentUserInfo, selectOption } from '../../../common/commonMethod'
import convertGlobalConstOptions from '../../../common/ConvertGlobalConstOptions'
import { filterTreeList } from '../../testCase/components/getTreeList'

const getOptionUrl = (mode, userAccount) => {
  return {
    case: [
      '/common/globalconst?globalConst=CaseStatus', //状态
      `/caseLibrary/list?login=${userAccount}`,
      '/common/userinfo?indexValidFlag=1' //创建人员
    ],
    req: [//需求
      '/common/globalconst?globalConst=reqstatus', // 状态
      '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C', //版本计划
    ],
    trace: [//研发任务
      '/common/globalconst?globalConst=tracerstate', // 状态
      '/UserSetting/getUniversalInterfaces?code=ReleaseID&codeName=ReleaseName&tableName=View_ReleaseInfo_Version&filter=State&filterParams=T,C', //版本计划
      '/common/jobrecordUserList?indexValidFlag=1&roleFlag=DT', // 测试人员
    ]
  }[mode]
}

const HFormInput = (props) => <FormInput  {...props} />

export default function SelectUseCaseOpt(props) {
  const { params, setParams, refresh, mode, setLibraryId, INIT } = props
  const { doFetch, data } = useGet()
  const userAccount = useMemo(() => getCurrentUserInfo('operator_name'), [])


  useEffect(() => {
    doFetch(getOptionUrl(mode, userAccount))
  }, [doFetch, mode, userAccount])

  const [statusOpt, versionOpt, testManOpt] = useMemo(() => {
    if (data) {
      if (mode === 'req') {
        const [d1, d2] = data
        return [
          selectOption(d1, ['interiorId,displayName', 'interiorId']),
          convertGlobalConstOptions(d2)
        ]
      } else if (mode === 'trace') {
        const [d1, d2, d3] = data
        return [
          selectOption(d1, ['interiorId,displayName', 'interiorId']),
          convertGlobalConstOptions(d2),
          selectOption(d3, ['userName', 'userId', 'userName,userId'])
        ]
      } else if (mode === 'case') {
        const [d1, d2, d3] = data
        return [
          convertGlobalConstOptions(d1),
          filterTreeList(d2),
          selectOption(d3, ['userName', 'userAccount', 'userName,userAccount']),
        ]
      }
    }
    return []
  }, [data, mode])

  return (
    <div className='selectUseCaseOpt'>
      {
        mode === 'case' &&
        <HFormInput tree search label='用例库' bind='libraryId' onChange={setLibraryId} component={Select} options={versionOpt} />
      }
      <div className={'customize-options'}>
        <div className='c-options'>
          <Form value={params} onChange={setParams}>
            {
              _.map(getFormInput(mode), item => <HFormInput key={item.bind}  {...item} />)
            }
          </Form>
          <div className="search-btn-group flex" style={{ marginBottom: 8 }}>
            <Button className={'search-btn'} primary onClick={() => refresh(params)}>查询</Button>
            <Button className={'reset-btn'} text onClick={() => {
              setParams(_.assign({}, INIT))
              refresh(_.assign({}, { ...INIT, pageNum: 1, pageSize: 20 }))
            }}>重置</Button>
          </div>
        </div>
      </div>

    </div>
  )

  function getFormInput(mode) {
    const searchList = {
      case: [
        {
          label: '标题',
          bind: 'caseName',
        },
        {
          label: '创建人员',
          bind: 'createUser',
          search: true,
          clear: true,
          component: Select,
          options: testManOpt
        },
        {
          label: '状态',
          bind: 'status',
          search: true,
          clear: true,
          component: Select,
          options: statusOpt,
          multiple: true
        }
      ],
      req: [
        {
          label: '搜索ID或标题',
          bind: 'idOrTitle',
        },
        {
          label: '版本计划',
          bind: 'versionId',
          search: true,
          clear: true,
          component: Select,
          options: versionOpt
        },
        {
          label: '状态',
          bind: 'status',
          search: true,
          clear: true,
          component: Select,
          options: statusOpt,
          multiple: true
        },
      ],
      trace: [
        {
          label: '搜索ID或标题',
          bind: 'idOrTitle',
        },
        {
          label: '版本计划',
          bind: 'versionId',
          search: true,
          clear: true,
          component: Select,
          options: versionOpt
        },
        {
          label: '状态',
          bind: 'status',
          search: true,
          clear: true,
          component: Select,
          options: statusOpt,
          multiple: true
        },
        {
          label: '测试人员',
          bind: 'testMan',
          search: true,
          clear: true,
          component: Select,
          options: testManOpt
        }
      ]
    }
    return searchList[mode]
  }

}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './RequirementStageUpdateHeader.scss'
import {Popover, Tooltip} from "antd";
import _ from "lodash";
import {FormInput} from "rootnet-edit";
import {Icon} from "../../../../../../components";
import convertOptions from "../../../../../common/ConvertOptions";
import UserSelect from "../../../../../common/personSelectPop/UserSelect";
import WorkFlowChangePop from "../../../../../workFlow/workFlowChangePop/WorkFlowChangePop";
import useGet from "rootnet-biz/es/hooks/useGet";
import convertGlobalConstOptions from "../../../../../common/ConvertGlobalConstOptions";
import {strParams} from "../../../../../../utils/publicFun";

const FUNC_CODE = '1526'

const GLOBAL_CONST_OPTIONS_URLS = [
    '/UserSetting/getUniversalInterfaces?code=id&codeName=name&tableName=workflow_factor'
]

function RequirementStageUpdateHeader(props) {
    const {editFormData, isDetail, setEditFormData, allUserRes, id, refreshViewList, refreshDetail, updateDynamic,
        countStatus, setWorkFlowText } = props
    const {data: globalConstOptionsRes} = useGet(GLOBAL_CONST_OPTIONS_URLS)
    const {data: workFlowInfo, doFetch: getStatus} = useGet()
    const [showWorkFlowPop, setShowWorkFlowPop] = useState(false)

    const status = useMemo(()=>{
        return _.get(workFlowInfo,'factorId')
    },[workFlowInfo])

    const [workFlowStatusOptions] = useMemo(()=>{
        if(_.isEmpty(globalConstOptionsRes)) return []
        return _.map(globalConstOptionsRes, x => convertGlobalConstOptions(x))
    },[globalConstOptionsRes])

    const refreshStatus = useCallback(()=>{
        if(_.isNil(id)) return
        // 刷新工作流
        console.log('countStatus',countStatus)
        const params = {
            businessType: 'stage',
            businessId: id
        }
        getStatus('/WorkflowBusiness/getWorkflowBusiness?'+strParams(params)).then(res => {
            setWorkFlowText(_.get(res,'factorName'))
        })
    },[getStatus, id, countStatus, setWorkFlowText])

    useEffect(()=>{
        refreshStatus()
    },[refreshStatus])

    const workFlowColor = useMemo(()=>{
        return convertOptions(status, workFlowStatusOptions, 'color')
    },[workFlowStatusOptions, status])

    return <div className={'requirement-stage-update-header flex'}>
        <div className="header-left">
            {
                isDetail &&
                <div className="title-wrap flex center-y">
                    <div className="title">
                        <Tooltip title={_.get(editFormData, 'title')}>
                            {_.get(editFormData, 'title')}
                        </Tooltip>
                    </div>
                </div>
            }
            {
                !isDetail && <FormInput horizontal bind={'title'} label={'任务名称'} required value={_.get(editFormData, 'title')}
                                        onChange={v => setEditFormData(x => ({ ...x, title: v }))} maxLength={250} labelWidth={112}/>
            }
        </div>
        <div className="header-right flex center-y">
            <div className="header-right-item flex center-y">
                <Icon name={'guanliyuan1'} className={'header-right-icon'} style={{color: '#165DFF'}}/>
                <div className="header-right-info flex-y">
                    {
                        isDetail &&
                        <div className="header-right-value">
                            <div className={'current-user'}>{convertOptions(_.get(editFormData, 'currentUser'), allUserRes,'userName','userAccount') || '无'}</div>
                        </div>
                    }
                    {
                        !isDetail &&
                        <FormInput horizontal value={_.get(editFormData, 'currentUser')} component={UserSelect} componentWidth={90}
                                   onChange={v => setEditFormData(x => ({...x, currentUser: v})) }  />
                    }
                    <div className="header-right-field">
                        处理人
                    </div>
                </div>
            </div>
            <Popover destroyTooltipOnHide={true}
                     content={<WorkFlowChangePop businessId={id} close={()=>setShowWorkFlowPop(null)} refreshList={()=>{
                         refreshStatus()
                         refreshViewList()
                         refreshDetail(id)
                         if(updateDynamic){
                             updateDynamic()
                         }
                     }} funcCode={FUNC_CODE} businessType={'stage'}/>}
                     onOpenChange={visible => {
                         if(!visible){
                             setShowWorkFlowPop(null)
                         }
                     }}
                     trigger={'click'}
                     placement="bottomRight"
                     open={showWorkFlowPop}
            >
                <div className="header-right-item flex center-y status-wrap-item" onClick={()=>setShowWorkFlowPop(true)}>
                    <Icon name={'renling'} className={'header-right-icon'} style={{color: workFlowColor}}/>
                    <div className="header-right-info flex-y">
                        <div className="header-right-value">
                            <div className={'status-tag flex center'} style={{color: workFlowColor, border: `1px solid ${workFlowColor}`,padding: '0 8px', borderRadius: 4}}>
                                {convertOptions(status, workFlowStatusOptions) || '-'}
                            </div>
                        </div>
                        <div className="header-right-field">
                            当前状态
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    </div>
}

export default RequirementStageUpdateHeader;
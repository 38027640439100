import React, {useMemo} from 'react';
import {Dialog} from 'rootnet-ui'
import ReactEcharts from "echarts-for-react";
import './SystemRelationDialog.scss'
import _ from 'lodash'
import {createNode} from "./util/createNode";
import {createExtraNode} from "./util/createExtraNode";
import {createLine} from "./util/createLine";

const levelList = ['系统','产品','模块','子模块']

function SystemRelationDialog(props) {
    const {close,systemName,data} = props

    const option = useMemo(()=>{
        if(_.isEmpty(data)) return
        let handleData = createNode(data,systemName)
        handleData = createExtraNode(handleData)
        const links = createLine(handleData)
        return {
            tooltip:{
                formatter: (params) => {
                    return params.data.name
                }
            },
            series: [{
                type: 'graph',
                symbol: 'rect',
                symbolSize: [160, 30],
                roam: true,
                label: {
                    show: true,
                    rich: {
                        baseText: {
                            color: '#3F7DFF',
                            lineHeight: 30,
                            backgroundColor: '#FFF',
                            width: 110,
                            padding: [0, 10],
                            height: 30,
                            borderWidth: 1,
                            borderRadius: [4, 0, 0, 4],
                            borderColor: '#3F7DFF',
                            align: 'center',
                            verticalAlign: 'center',
                            fontSize: 14
                        },
                        baseRight: {
                            color: '#FFF',
                            lineHeight: 30,
                            backgroundColor: '#3F7DFF',
                            borderColor: '#3F7DFF',
                            borderRadius: [0, 4, 4, 0],
                            borderWidth: 1,
                            width: 50,
                            height: 30,
                            align: 'center',
                            verticalAlign: 'center',
                            textBorderColor: 'transparent',
                            fontSize: 14
                        },
                        extraText: {
                            color: '#15171F',
                            lineHeight: 30,
                            backgroundColor: '#FFF',
                            width: 110,
                            padding: [0, 10],
                            height: 30,
                            borderWidth: 1,
                            borderRadius: [4, 0, 0, 4],
                            borderColor: '#D0D1D9',
                            align: 'center',
                            verticalAlign: 'center',
                            fontSize: 14
                        },
                        extraRight: {
                            color: '#5477FF',
                            lineHeight: 30,
                            backgroundColor: '#F4F7FF',
                            borderColor: '#D0D1D9',
                            borderRadius: [0, 4, 4, 0],
                            borderWidth: 1,
                            width: 50,
                            height: 30,
                            align: 'center',
                            verticalAlign: 'center',
                            textBorderColor: 'transparent',
                            fontSize: 14
                        },
                    },
                    formatter: (a)=>{
                        const showText = a.name.length > 8 ? a.name.substring(0,8) + '...' : a.name
                        if(a.data.collocation === '01'){
                            return '{baseText|'+showText+'}{baseRight|'+levelList[a.data.level]+'}'
                        }else{
                            return '{extraText|'+showText+'}{extraRight|'+levelList[a.data.level]+'}'
                        }
                    }
                },
                edgeSymbol: ['circle', 'arrow'],
                edgeSymbolSize: [3, 0],
                data: handleData,
                links: links,
            }]
        }
    },[data,systemName])


    return <Dialog header={'关系图'} cancel={close} confirm={close} cancelButtonVisible={false} confirmButtonText={'关闭'} className={'system-relation-dialog'}>
        <div className="content-wrap flex-y center">
            <div className='graph-wrap'>
                {!_.isEmpty(data) && <ReactEcharts option={option} style={{height: '100%', width: '100%'}} className='echarts'/>}
            </div>
        </div>
        <div className="remark flex center-y">
            <div className="text">注:</div>
            <div className="blue-block"/>
            <div className="text">代表系统项目必选项</div>
        </div>
    </Dialog>
}

export default SystemRelationDialog;
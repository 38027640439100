import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Tabs, Tree } from 'antd'
import { Dialog, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { useGet, usePost } from 'rootnet-biz/lib/hooks'
import { strParams } from '../../../../../../../utils/publicFun'
import './index.scss'

const MENU_LIST_URL = '/uac/menu/list'
const ADD_MENU_URL = '/uac/role/set/menu'

function initTreeData(data, moduleId) {
  if (_.isEmpty(data)) return []
  const listMenu = JSON.parse(JSON.stringify(data))
  _.forEach(listMenu, item => delete item.children)
  const map = {}
  _.forEach(listMenu, item => map[item.funcCode] = item)
  const newListMenu = []
  _.forEach(listMenu, r => {
    r.title = r.funcName
    r.key = r.funcCode
    r.moduleId = moduleId
    let parents = map[r.pid]
    if (parents) {
      ((parents.children) || (parents.children = [])).push(r)

    } else newListMenu.push(r)
  })
  return newListMenu
}

export default function AssigningMenu(props) {
  const { roleID, close, systemOpt, defaultCheckedKeys, roleMenuList, refresh } = props
  const [activeTab, setActiveTab] = useState()
  const [checkedKeys, setCheckedKeys] = useState(defaultCheckedKeys)
  const [isDisable, setIsDisable] = useState(false)
  const { data: menuList, doFetch: getMenuList } = useGet()
  const { doFetch } = usePost()

  const treeData = useMemo(() => {
    if (_.isEmpty(menuList) || _.isEmpty(systemOpt)) return {}
    return _.assign({}, ..._.map(menuList, (arr, i) => ({
      [_.get(systemOpt[i], 'value')]: [{
        title: _.get(systemOpt[i], 'text'),
        key: _.get(systemOpt[i], 'value'),
        children: initTreeData(_.orderBy(_.filter(arr, o => o.status === 0), 'order', 'asc'), _.get(systemOpt[i], 'value'))
      }]
    })))
  }, [menuList, systemOpt])

  useEffect(() => {
    if (_.isEmpty(systemOpt)) return
    setActiveTab(_.get(_.head(systemOpt), 'value'))
    const URLS = _.map(systemOpt, o => `${MENU_LIST_URL}?${strParams({ moduleId: o.value })}`)
    getMenuList(URLS)
  }, [systemOpt, getMenuList])

  const getParentId = useCallback(({ pid, moduleId }, arr = []) => {
    const index = _.findIndex(systemOpt, o => o.value === moduleId)
    const currentData = menuList[index]
    const findItem = _.find(currentData, o => o.funcCode === pid)
    if (!_.isNil(findItem)) {
      arr.push(_.get(findItem, 'funcCode'))
      if (!isNil(_.get(findItem, 'pid'))) getParentId({ pid: _.get(findItem, 'pid'), moduleId }, arr)
    }
    return arr
  }, [systemOpt, menuList])

  const onCheck = useCallback((v, o) => {
    const { checked, node } = o
    const pid = _.get(node, 'pid')
    const moduleId = _.get(node, 'moduleId')
    const childrenId = getChildrenId([node])
    if (checked) {
      const parentId = getParentId({ pid, moduleId })
      setCheckedKeys(x => {
        const concatKeys = _.uniq(_.concat([], _.get(x, `${activeTab}`, []), parentId, childrenId))
        return _.assign({}, x, { [activeTab]: _.compact(concatKeys) })
      })
    } else {
      setCheckedKeys(x => {
        const filterKeys = _.uniq(_.filter(_.get(x, `${activeTab}`, []), val => !_.includes(childrenId, val)))
        return _.assign({}, x, { [activeTab]: _.compact(filterKeys) })
      })
    }
  }, [activeTab, getParentId])

  const confirm = useCallback(() => {
    if (isDisable) return
    setIsDisable(true)
    const concatData = _.concat(..._.map(checkedKeys, (arr, k) => {
      return _.map(arr, v => {
        const findItem = _.find(roleMenuList, o => (o.funcCode === v && k === o.moduleID))
        return _.assign({
          roleID,
          moduleID: k,
          funcCode: v,
        }, !_.isNil(findItem) && { id: _.get(findItem, 'id') })
      })
    }))
    doFetch(ADD_MENU_URL, _.isEmpty(concatData) ? [{ roleID }] : concatData)
      .then(() => {
        refresh()
        setIsDisable(false)
        Messager.show('分配菜单成功', { icon: 'success' })
        close()
      })
      .catch(err => {
        setIsDisable(false)
        Messager.show(err._message, { icon: 'error' })
      })
  }, [doFetch, roleID, checkedKeys, refresh, close, isDisable, roleMenuList])

  return (
    <Dialog
      header='分配菜单'
      confirm={confirm}
      cancel={close}
      className='assigning-menu-log'
    >
      <Tabs
        activeKey={activeTab}
        onTabClick={setActiveTab}
        items={
          _.map(systemOpt, o => {
            return {
              label: o.text,
              key: o.value,
              children: <Tree
                checkable
                checkStrictly
                defaultExpandAll
                selectable={false}
                onCheck={onCheck}
                checkedKeys={_.get(checkedKeys, `${o.value}`)}
                // selectedKeys={selectedKeys}
                treeData={_.get(treeData, `${o.value}`, [])}
              />
            }
          })
        }
      />
    </Dialog>
  )
}

function getChildrenId(data, arr = []) {
  _.forEach(data, o => {
    arr.push(o.key)
    if (!_.isEmpty(o?.children)) getChildrenId(o.children, arr)
  })
  return arr
}

import React from 'react'
import ManpowerPipelineEstimationDetail from './controls/manpowerPipelineEstimationDetail'

export default function ManpowerPipelineEstimationMgt() {
  return (
    <div className='manpower-pipeline-estimation-mgt fill flex-y'>
      <ManpowerPipelineEstimationDetail />
    </div>
  )
}

import React, { useMemo } from 'react';
import _ from "lodash";
import DevProjectScopeVersion from "../devProjectScope/devProjectScopeVersion/DevProjectScopeVersion";
import './DevProjectTestPlan.scss'
import TestPlanIndex from "../../../../testMgt/testPlan";

function DevProjectTestPlan(props) {
    const { versionStatusOptions, addVersionAuth, selectedVersionItem, setSelectedVersionItem, refreshVersionList,
        projectMap, versionListRes, versionLoading, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow } = props

    const selectedVersion = useMemo(()=>{
        return _.get(selectedVersionItem,'releaseID')
    },[selectedVersionItem])

    const factorViewSql = useMemo(() => {
        if (_.isNil(_.get(projectMap, 'projectId'))) return null
        const versionList = _.get(versionListRes, 'releaseInfoList') || []
        let factorViewSql = `test_plan.projectId = '${_.get(projectMap, 'projectId')}' `
        if (selectedVersion === 'no-plan-version') {
            const versionListSql = `(test_plan.related_releaseId not in (${_.join(_.map(versionList, x => `'${x.releaseID}'`), ',')}) or test_plan.related_releaseId is null)`
            factorViewSql += (_.isEmpty(versionList) ? 'and test_plan.related_releaseId is null' : `and ${versionListSql}`)
        } else if (!_.isNil(selectedVersion)) {
            factorViewSql = `test_plan.related_releaseId = '${selectedVersion}'`
        } else {
            const versionListSql = `test_plan.related_releaseId in (${_.join(_.map(versionList, x => `'${x.releaseID}'`), ',')})`
            factorViewSql += (_.isEmpty(versionList) ? '' : `or ${versionListSql}`)
        }
        return factorViewSql
    }, [selectedVersion, projectMap, versionListRes])

    return <div className={'dev-project-test-plan flex fill'}>
        <DevProjectScopeVersion projectId={_.get(projectMap, 'projectId')} activeModule={'testPlan'} {...{
            versionLoading, selectedVersionItem, setSelectedVersionItem,
            refreshVersionList, versionListRes, versionStatusOptions, addVersionAuth, typeOpt, natureOpt, scaleOpt, customerSystemOpt, allUserRes, isShow
        }} />
        <div className={'dev-project-test-plan-view flex'}>
            <TestPlanIndex outerViewFuncCode={'3605'} {...{ factorViewSql, isAdd: isShow }} />
        </div>
    </div>
}

export default DevProjectTestPlan;
import React, { useMemo } from 'react'
import _ from 'lodash'
import { Tab, TabItem } from 'rootnet-ui'
import { useGet } from '../../utils/hook'
import { TabContext } from '../common/Context'
import CostRatePending from './costRatePending'
import convertGlobalConstOptions from '../common/ConvertGlobalConstOptions'
import './index.scss'
import CostRateList from './costRateList'

const OPTIONS_URLS = [
  '/common/department?activeFlag=Y',
  '/common/positiontechlevel',
  '/common/globalconst?globalConst=COSTRATESTATUS'
]

function buildTree(allList, list, keyName, parentName, level) {
  return _.map(list, listItem => {
    const childList = _.filter(allList, allItem => _.get(allItem, parentName) === _.get(listItem, keyName))
    if (level >= 2 || childList.length === 0) return convertDepartment(listItem)
    return _.assign(convertDepartment(listItem), { children: buildTree(allList, childList, keyName, parentName, level + 1) })
  })
}

function convertDepartment(item) {
  return { text: item.departMentName, value: item.departMentId }
}

export default function CostRateMgt() {
  const { data: optionsRes } = useGet(OPTIONS_URLS)
  // const { data: allList, doFetch: getAllList, loading } = usePost()

  // const refresh = useCallback(() => getAllList('/costrate/list', {}), [getAllList])

  // useEffect(() => {
  //   refresh()
  // }, [refresh])

  const [deptRes, treeDeptOptions, techLevelOptions, statusOpt] = useMemo(() => {
    if (_.isEmpty(optionsRes)) return []
    const [d1, d2, d3] = optionsRes
    const firstLevelDepList = _.filter(d1, x => _.get(x, 'parentDept') === "")
    const treeDeptOptions = buildTree(d1, firstLevelDepList, "departMentId", "parentDept", 0)
    const techLevelOptions = _.map(d2, x => ({ text: x.techLevel, value: x.techLevel }))
    const uniqTechLevelOptions = _.uniqBy(techLevelOptions, x => x.value)
    const statusOpt = convertGlobalConstOptions(d3)
    return [d1, treeDeptOptions, uniqTechLevelOptions, statusOpt]
  }, [optionsRes])

  const contextValue = {
    // allList,
    // loading,
    // refresh,
    deptRes,
    treeDeptOptions,
    techLevelOptions,
    statusOpt
  }

  return (
    <TabContext.Provider value={contextValue}>
      <Tab className='fill costRateMgt' active={0}>
        <TabItem header='costrate列表'>
          <CostRateList {...{ deptRes, treeDeptOptions, techLevelOptions, statusOpt }} />
        </TabItem>
        <TabItem header='待处理'>
          <CostRatePending />
        </TabItem>
      </Tab>
    </TabContext.Provider>
  )
}

import React, {useEffect, useMemo, useState} from 'react';
import './ResourceProjectSearch.scss'
import {Input} from "rootnet-edit";
import Icon from "../../../../project_share/components/Icon";
import _ from 'lodash'
import {useGet} from "rootnet-biz/es/hooks";
import {dateFormat, toDate} from "rootnet-core/dateFormat";
import {Tooltip, Empty} from "antd";

function getNameColor(sex){
    if(_.isEmpty(sex)) return '#D3D3D3'
    return sex === '女'?'#FF99FF':'#8DC8EA'
}

function ResourceProjectSearch(props) {
    const {selectedDeptId,selectedProjectId, setSelectedProjectId} = props
    const [searchText, setSearchText] = useState()
    const {data: allProjectList, doFetch: getAllProjectList} = useGet()

    useEffect(()=>{
        if(_.isNil(selectedDeptId)) return
        getAllProjectList('/demandInput/getProject?department='+selectedDeptId)
    },[getAllProjectList, selectedDeptId])

    const filterList = useMemo(()=>{
        if(!_.trim(searchText)) return allProjectList
        return _.filter(allProjectList, item => _.some([item.projectName,item.userName,item.projectManager], x => matchText(x, searchText)))
        function matchText(text, searchText){
            return _.includes(_.toLower(text), _.trim(_.toLower(searchText)))
        }
    },[allProjectList, searchText])

    return <div className={'resource-project-search flex-y'}>
        <Input className={'search-wrap'} placeholder={'搜索项目'} value={searchText} onChange={setSearchText}
               suffix={<Icon name={'sousuo1'} className={'search-icon'}/>}/>
        <div className={'project-list-wrap'}>
            {
                _.map(filterList, item => {
                    return <div className={`project-item flex center-y ${item.id === selectedProjectId ?'selected':''}`}
                                onClick={()=>setSelectedProjectId(old => old === item.id ? null : item.id)} key={item.id}>
                        <Tooltip title={item.projectName} placement="topLeft">
                            <div className="project-name">{item.projectName}</div>
                        </Tooltip>
                        {
                            _.get(item,'power') &&
                            <Tooltip title={'查看项目详情'}>
                                <div className={'flex center'} onClick={(e)=>{
                                    e.stopPropagation()
                                    window.open('/#/projectManagement?initId='+item.id,'_blank')
                                }}>
                                    <Icon className={'to-detail-icon'} name={'xinxichaxun'}/>
                                </div>
                            </Tooltip>
                        }
                        <div className="project-principal flex center-y" style={{backgroundColor: getNameColor(item.sex)}}>
                            {item.userName}
                        </div>
                        <div className="project-time">{dateFormat('YYYY-MM-DD',toDate.str14ToDate(item.endDate))}</div>
                    </div>
                })
            }
            {
                _.isEmpty(filterList) &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </div>
    </div>
}

export default ResourceProjectSearch;
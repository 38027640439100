import React, {useMemo} from 'react';
import {Dialog} from "rootnet-ui";
import './ReqAnalysisSearchDialog.scss'
import RequirementMgt from "../../RequirementMgt";
import _ from "lodash";

function ReqAnalysisSearchDialog(props) {
    const {close, idList} = props

    const appendObj = useMemo(() => {
        return { 'factorViewSql': `story.storyId in (${_.join(_.map(idList, x => `'${x}'`),',')})`}
    }, [idList])

    return <Dialog header={'需求'} className={'req-analysis-search-dialog'} cancel={close} confirmButtonVisible={false} cancelButtonText={'关闭'}>
        <RequirementMgt appendObj={appendObj} outerFuncCode={1513} showParent={false}/>
    </Dialog>
}

export default ReqAnalysisSearchDialog;
import React, { useState, useMemo, useCallback, useReducer, useEffect } from 'react'
import _ from 'lodash'
import { CheckBox } from 'rootnet-edit'
import { usePost } from 'rootnet-biz/lib/hooks'
import { Dialog, DataGrid, Pagination } from 'rootnet-ui'
import convertTableAlign from '../../../view/convertTableAlign'
import findConvert from '../../../view/findConvert'
import { Box } from '../../../commonComponent'
import ViewArea from '../../../view/ViewArea'
import useGetTreeList from '../../../view/hooks/useGetTreeList'
import useGetViewConfig from '../../../view/hooks/useGetViewConfig'
import useRefreshList from '../../../view/hooks/useRefreshList'
import useGetDateOptions from '../../../view/hooks/useGetDateOptions'

function getColumns(props) {
  const { fieldList, convertCollection, dateOptions, selectedId, selectAll, checkedAll, disabledId } = props

  const customColumns = []

  return [
    {
      header: <CheckBox value={selectAll} onChange={checkedAll} />, width: 40, stretch: false, align: 'center', convert: (v, o) => {
        return <CheckBox disabled={_.includes(disabledId, o.id)} value={_.includes(selectedId, o.id)} />
      }
    },
    { header: '#', width: 40, stretch: false, convert: (v, o, i) => i + 1, align: 'center' },
  ].concat(_.map(fieldList, x => handleColumn(x)))

  function handleColumn(fieldItem) {
    if (_.get(fieldItem, 'custom') === 'Y') {
      // 自定义字段
      return {
        header: fieldItem.fieldName,
        ..._.find(customColumns, x => x.fieldId === fieldItem.fieldId),
        align: convertTableAlign(fieldItem.alignment),
        width: _.toNumber(fieldItem.columnWidth) || 120,
      } || { header: '', bind: '', width: 100 }
    } else {
      return {
        header: fieldItem.fieldName,
        bind: fieldItem.columnId,
        width: _.toNumber(fieldItem.columnWidth) || 120,
        align: convertTableAlign(fieldItem.alignment),
        tooltip: true,
        _custom: fieldItem.custom,
        convert: v => findConvert(fieldItem, v, convertCollection, dateOptions) || '-'
      }
    }
  }
}

const getOptions = (options) => ({
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns: options,
  virtualized: true,
  autoFill: true,
});

function getInitParams() {
  return {
    pageNum: 1,
    pageSize: 20
  }
}

export default function ReqViewSearchDialog(props) {
  const { initId, relatedReq, cancelRelatedReq, close, disabledId = [], FUNC_CODE: DEFAULT_FUNC_CODE = '0004' } = props
  const FUNC_CODE = DEFAULT_FUNC_CODE || '0004'
  const [selectedId, setSelectedId] = useState(initId)
  const [selectedItem, setSelectedItem] = useState([])
  const [selectedItemAll, setSelectedItemAll] = useState([])
  const [params, setParams] = useState()
  const [pageSize, setPageSize] = useState(20)
  const [isLoading, setIsLoading] = useState(true)
  const [selectAll, setSelectAll] = useState(false)
  const { funcCode, allColumns, optionsConfig, fieldList, getFieldList, convertCollection } = useGetViewConfig(FUNC_CODE, setIsLoading)
  const [currentViewId, setCurrentViewId] = useState()
  const { data: listRes, doFetch: getList, loading, error } = usePost()
  const { total, pageNum, rows: list } = useMemo(() => (listRes || {}), [listRes]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const boxLoading = useMemo(() => {
    return isLoading || loading
  }, [isLoading, loading])

  const refreshList = useRefreshList({ currentViewId, params, allColumns, getList, setParams, funcCode })

  const dateOptions = useGetDateOptions()

  const onRowClick = useCallback((item) => {
    const bizItem = _.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'story.storyId')
    const bizId = _.get(item, _.get(bizItem, 'columnId'))
    if (_.includes(selectedId, item.id)) {
      setSelectedId(x => _.filter(x, v => v !== item.id))
      setSelectedItem(x => _.filter(x, o => o.id !== item.id))
    } else {
      setSelectedId(x => _.concat(x, [item.id]))
      setSelectedItem(x => _.concat(x, [{
        id: item.id,
        bizId: bizId
      }]))
    }
    setSelectedItemAll(x => {
      const ids = _.map(x, o => o.id)
      if (_.includes(ids, item.id)) return x
      return _.concat(x, [{
        id: item.id,
        storyId: bizId
      }])
    })
  }, [selectedId, fieldList])

  useEffect(() => {
    _.forEach(list, o => {
      return o._rowClass = selectedId === o?.id ? 'select_row' : ''
    })
    forceUpdate()
  }, [list, selectedId])

  const checkedAll = useCallback((checked) => {
    setSelectAll(checked)
    const ids = _.map(list, o => o.id)
    const bizItem = _.find(fieldList, x => `${x.tableName}.${x.fieldId}` === 'story.storyId')
    setSelectedItemAll(x => {
      const ids = _.map(x, o => o.id)
      const items = _.map(list, item => {
        if (_.includes(ids, item.id)) return
        const bizId = _.get(item, _.get(bizItem, 'columnId'))
        return {
          id: item.id,
          bizId: bizId
        }
      })
      return _.concat(x, _.compact(items))
    })
    if (checked) {
      setSelectedId(x => {
        const filterItems = _.filter(list, o => !_.includes(x, o.id))
        return _.concat([], x, _.map(filterItems, o => o.id))
      })
      setSelectedItem(x => {
        const filterItems = _.filter(list, o => !_.includes(x, o.id))
        const items = _.map(filterItems, item => {
          const bizId = _.get(item, _.get(bizItem, 'columnId'))
          return {
            id: item.id,
            bizId: bizId
          }
        })
        return _.concat([], x, items)
      })
    } else {
      setSelectedId(x => _.filter(x, v => !_.includes(ids, v)))
      setSelectedItem(x => _.filter(x, o => !_.includes(ids, o.id)))
    }
  }, [fieldList, list])

  const { options, dataGridList } = useGetTreeList({
    fieldList, list, convertCollection, dateOptions, getOptions, getColumns,
    columnsAppendParams: { selectedId, selectAll, checkedAll, disabledId },
  })

  const confirm = useCallback(() => {
    const ids = _.map(selectedItem, o => o.bizId)
    const relatedIds = _.filter(ids, v => !_.includes(initId, v))
    const cancelRelatedIds = _.filter(initId, v => !_.includes(_.map(selectedItem, o => o.id), v))
    const storyIds = _.filter(selectedItemAll, o => _.includes(cancelRelatedIds, o.id))

    if (!_.isEmpty(relatedIds)) relatedReq(relatedIds)
    if (!_.isEmpty(storyIds)) cancelRelatedReq(storyIds, _.isEmpty(relatedIds))
    close()
  }, [selectedItem, initId, selectedItemAll, close, relatedReq, cancelRelatedReq])

  useEffect(() => {
    setSelectAll(_.every(list, o => _.includes(selectedId, o.id)))
  }, [list, selectedId])

  return <Dialog header={'需求查询'} className={'req-view-query-dialog'} cancel={close} confirm={confirm}>
    <div className={'req-view-query-content fill flex-y'}>
      <ViewArea funcCode={FUNC_CODE} allOptions={optionsConfig} search={setParams} loading={boxLoading}
        {...{
          getFieldList, allColumns, refreshList, total, getInitParams, optionsConfig,
          currentViewId, setCurrentViewId, params, pageSize
        }} />
      <Box title={'需求列表'} className='flex-y x-card-singlegrid' data={list} loading={boxLoading} error={error}>
        <DataGrid option={options} data={dataGridList} onRowClick={(item) => onRowClick(item)} />
        <Pagination pageSize={pageSize} total={total} current={pageNum} selector
          onChange={(pageNum, pageSize) => {
            setPageSize(pageSize)
            setParams(x => _.assign({}, x, { pageNum, pageSize }))
          }} />
      </Box>
    </div>
  </Dialog>
}
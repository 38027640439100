import React, { useState, useContext, useRef, useEffect, useCallback } from 'react'
import { Form, Input, Select, Tooltip, InputNumber } from 'antd'
import _ from 'lodash'
import { sortableHandle } from 'react-sortable-hoc'
import { Icon } from '../../../../../components'
import { TemplateEditContext } from '../../../../common/Context'
import convertOptions from '../../../ConvertOptions'

/*----------------导入 导出----------------*/
const columns = [
  { header: '模板名称', bind: 'templateName', tooltip: true, },
  { header: '阶段/环节', bind: 'segment', width: 120, tooltip: true, },
  { header: '内容', bind: 'itemName', width: 120, tooltip: true, },
  { header: '优先级', bind: 'priority', align: 'center', width: 60, },
  { header: '标准工时(人日)', bind: 'standUseTime', align: 'right', width: 90, },
  { header: '详细说明', bind: 'detail', tooltip: true, },
  { header: '完成标准/提交物', bind: 'completedNotes', tooltip: true, },
]

export const tableFormat = {
  nilText: '-',
  emptyText: '-',
  fixedLeft: 1,
  resizable: true,
  columns,
  virtualized: true,
  autoFill: true,
}
/*----------------详情表格---------------- */
const DragHandle = sortableHandle(() => {
  return <Icon name='yidongshu' style={{ cursor: 'grab', color: '#999', fontSize: 14 }} />
});

const prompt = v => {
  return <Tooltip placement="topLeft" title={v}>{v}</Tooltip>
}

const whetherOptions = [
  { label: '是', text: '是', value: 'Y' },
  { label: '否', text: '否', value: 'N' },
]

export function getColumns(props) {
  const { segmentOpt, priorityOpt, keyNodesOpt, setVisible, projectRoleList, isDevProject } = props
  return _.compact([
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible unhide',
      render: () => <DragHandle />
    },
    {
      title: '序号',
      width: 45,
      align: 'center',
      ellipsis: true,
      className: 'unhide',
      render: (text, record, index) => {
        return <div className='shown-below'>
          <div className='order'>
            {`${index + 1}`}
          </div>
          <div className='template-add-btn'>
            <Icon name='tianjia' onClick={() => setVisible({ data: _.assign({}, _.pick(record, ['sortSerial', 'segment', 'pid'])), index })} />
          </div>
        </div>
      },
    },
    {
      title: '阶段/环节',
      width: 170,
      dataIndex: 'segment',
      key: 'segment',
      ellipsis: true,
      editable: true,
      render: v => _.get(_.find(segmentOpt, o => o.value === v), 'label')
    },
    {
      title: '内容',
      dataIndex: 'itemName',
      key: 'itemName',
      ellipsis: true,
      editable: true,
      width: 90,
      render: prompt,
    },
    isDevProject && {
      title: '关键节点',
      dataIndex: 'keyNodes',
      key: 'keyNodes',
      ellipsis: true,
      editable: true,
      width: 90,
      render: v => _.get(_.find(keyNodesOpt, o => o.value === v), 'label'),
    },
    isDevProject && {
      title: '是否父级',
      dataIndex: 'isParent',
      key: 'isParent',
      width: 90,
      editable: true,
      render: v => convertOptions(v, whetherOptions),
    },
    {
      title: '优先级',
      width: 60,
      dataIndex: 'priority',
      key: 'priority',
      align: 'center',
      editable: true,
      render: v => _.get(_.find(priorityOpt, o => o.value === v), 'label')
    },
    {
      title: '标准工时(人日)',
      dataIndex: 'standUseTime',
      align: 'right',
      width: 120,
      key: 'standUseTime',
      editable: true,
    },
    {
      title: '详细说明',
      dataIndex: 'detail',
      key: 'detail',
      ellipsis: true,
      editable: true,
      width: 100,
      render: prompt,
    },
    isDevProject && {
      title: '里程碑',
      dataIndex: 'milestoneFlag',
      key: 'milestoneFlag',
      width: 90,
      editable: true,
      render: v => convertOptions(v, whetherOptions),
    },
    {
      title: '完成标准/提交物',
      dataIndex: 'completedNotes',
      key: 'completedNotes',
      ellipsis: true,
      editable: true,
      width: 130,
      render: prompt,
    },
    isDevProject && {
      title: '负责角色',
      dataIndex: 'manageRole',
      key: 'manageRole',
      width: 120,
      editable: true,
      render: v => convertOptions(v, projectRoleList),
    },
    isDevProject && {
      title: '阶段质量审计',
      dataIndex: 'isBaselineRelease',
      key: 'isBaselineRelease',
      width: 110,
      editable: true,
      render: v => convertOptions(v, whetherOptions),
    },
    isDevProject && {
      title: '基线发布',
      dataIndex: 'isStageQualityAudit',
      key: 'isStageQualityAudit',
      width: 90,
      editable: true,
      render: v => convertOptions(v, whetherOptions),
    }
  ])
}

/*----------------详情编辑----------------*/
export function EditableCell(props) {
  const { title, editable, children, dataIndex, record, handleSave, must, ...restProps } = props
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const { form, segmentOpt, priorityOpt, keyNodesOpt, projectRoleList, detailData } = useContext(TemplateEditContext) || {}

  useEffect(() => {
    if (editing) {
      if (!_.isNil(inputRef)) {
        inputRef.current.focus()
      }
    }
  }, [editing])

  const getSegmentSort = useCallback((value) => {
    return _.get(_.find(segmentOpt, x => x.value === value), 'sort')
  }, [segmentOpt])

  const toggleEdit = useCallback(() => {
    setEditing(x => !x);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  }, [dataIndex, record, form])

  const save = async () => {
    try {
      const values = await form.validateFields();
      const key = _.head(_.keys(values))
      let replaceParams = {}
      if (key === 'segment') {
        const listIndex = _.findIndex(detailData, x => x.id === record['id'])
        let normalSort
        if (listIndex + 1 !== _.size(detailData)) {
          normalSort = getSegmentSort(values['segment']) <= getSegmentSort(_.get(detailData[listIndex + 1], 'segment'))
            && getSegmentSort(values['segment']) >= getSegmentSort(_.get(detailData[listIndex], 'segment'))
        } else {
          normalSort = false
        }
        if (!normalSort) {
          const biggerSortItem = _.find(detailData, x => getSegmentSort(_.get(x, 'segment')) > getSegmentSort(values['segment']))
          if (_.isNil(biggerSortItem)) {
            replaceParams = { sortSerial: _.toNumber(_.get(_.last(detailData), 'sortSerial')) + 1 }
          } else {
            const biggerSortItemIndex = _.findIndex(detailData, x => x.id === biggerSortItem.id)
            if (biggerSortItemIndex === 0) {
              replaceParams = { sortSerial: _.toNumber(_.get(biggerSortItem, 'sortSerial')) / 2 }
            } else {
              replaceParams = { sortSerial: (_.toNumber(_.get(detailData[biggerSortItemIndex - 1], 'sortSerial')) + _.toNumber(_.get(biggerSortItem, 'sortSerial'))) / 2 }
            }
          }
        }
      }
      toggleEdit();
      handleSave({ ...record, ...values, ...replaceParams }, values);
      if (!_.isNil(inputRef.current)) {
        inputRef.current.blur()
      }
    } catch (errInfo) {
      console.error(errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      let editNode
      if (dataIndex === 'segment') {
        editNode = (<Select size="small" ref={inputRef} onBlur={save} options={segmentOpt} onSelect={save} defaultOpen />)
      } else if (dataIndex === 'priority') {
        editNode = (<Select size="small" ref={inputRef} onBlur={save} options={priorityOpt} onSelect={save} defaultOpen />)
      } else if (dataIndex === 'keyNodes') {
        editNode = (<Select size="small" ref={inputRef} onBlur={save} options={keyNodesOpt} onSelect={save} defaultOpen />)
      } else if (dataIndex === 'manageRole') {
        editNode = (<Select size="small" ref={inputRef} onBlur={save} options={projectRoleList} onSelect={save} defaultOpen />)
      } else if (_.includes(['isParent', 'milestoneFlag', 'isBaselineRelease', 'isStageQualityAudit'], dataIndex)) {
        editNode = (<Select size="small" ref={inputRef} onBlur={save} options={whetherOptions} onSelect={save} defaultOpen />)
      } else if (dataIndex === 'detail') {
        editNode = (<Input.TextArea className='editable-cell-small' size="small" ref={inputRef} onBlur={save} maxLength={150} />)
      } else if (dataIndex === 'completedNotes') {
        editNode = (<Input.TextArea className='editable-cell-small' size="small" ref={inputRef} onBlur={save} maxLength={150} />)
      } else if (dataIndex === 'standUseTime') {
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              { pattern: /^(\d+|\d+\.\d{1})$/, message: `保留一位小数`, },
            ]}>
            <InputNumber className='editable-cell-small' size="small" ref={inputRef} onPressEnter={save} onBlur={save} />
          </Form.Item>
        )
      } else if (dataIndex === 'itemName') {
        editNode = (<Input className='editable-cell-small' size="small" ref={inputRef} onPressEnter={save} onBlur={save} maxLength={50} />)
      } else {
        editNode = (<Input className='editable-cell-small' size="small" ref={inputRef} onPressEnter={save} onBlur={save} />)
      }
      if (dataIndex !== 'standUseTime') {
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: must,
                message: `${title}不能为空`,
              },
            ]}
          >
            {editNode}
          </Form.Item>
        )
      }
    } else {
      childNode = (
        <div className="editable-cell-value-wrap-lz" onClick={toggleEdit}>
          {children}
        </div>
      )
    }
  }

  return <td {...restProps}>{childNode}</td>;
}
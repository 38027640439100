import React, { useRef, useState, useEffect } from 'react';
import { Popup } from 'rootnet-ui'
import _ from 'lodash';
import './OperationList.scss';
import clsx from 'clsx';

export function OperationList(props) {
    const { onClick, options, className, style } = props;
    const ref = useRef(null);
    const moreRef = useRef(null);
    const [showMore, setShowMore] = useState(false);
    useOnClickOutside(moreRef, () => setShowMore(false))

    function handleClick(e, opt) {
        if (_.isFunction(_.get(opt, 'onClick'))) return opt.onClick(opt, e)
        if (_.isFunction(onClick)) onClick(opt, e)
    }

    return <div className={clsx('all-operate', className)} style={style}>
        {
            _.map(options, (o, i) => {
                const { disabled, text } = o;
                if (_.get(o, 'value') === 'more') {
                    return <span key={i}>
                        <span className={clsx('more', { disabled })} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowMore(o)
                        }} ref={ref}>
                            {text}
                        </span>
                        {
                            showMore && <Popup portal open={showMore} placement='bottom-start' className='showMore'>
                                <div className={'moreList'} ref={moreRef}>
                                    {_.map(_.get(showMore, 'children'), (v, index) => {
                                        return <span {...v} className={clsx({ disabled: v.disabled })} key={index} onClick={(e) => moreItemClick(e, v)}>
                                            {v.text}
                                        </span>
                                    })}
                                </div>
                            </Popup>
                        }
                    </span>
                }
                return <span key={i} className={clsx({ disabled })}
                    onClick={(e) => handleClick(e, o)}>{text}</span>
            })
        }
    </div>

    function moreItemClick(e, v) {
        handleClick(e, v);
        setShowMore(false)
    }
}

OperationList.defaultProps = {
    // options: [
    //     {
    //         text:String || Element,//必填
    //         onClick:Func,//选填
    //     }
    // ]
};

export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) return;
            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    },
        [ref, handler]
    );
}
import React, { useState, useEffect, useRef, useCallback, Fragment, useMemo } from 'react';
import {Loader,Transfer,Dialog} from 'rootnet-ui';
import ProductNoData from '../components/Nodata'
import _ from 'lodash';
import API from '../base/task';
import {compose, Fn, transHeaderToText} from "./publicFun";
import fp from "lodash/fp";
import TextIconBtn from "../project_share/components/TextIconBtn";

export function useApi() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const cancel_ref = useRef(API.cancelTokenSource());

    const doFetch = useCallback((url, method = 'get', params) => {
        const token = cancel_ref.current.token;

        if (_.isEmpty(url)) return Promise.reject('url为空');
        if (_.isArray(url)) {
            setLoading(true);
            return new Promise((resolve,reject)=>{
                Promise.all(url.map(x => API.get(x, token))).then(res => {
                    setData(res);
                    setError(null);
                    setLoading(false);
                    resolve(res);
                    return res;
                }).catch(err => {
                    setError(err);
                    setLoading(false);
                    reject(err);
                    return err;
                });
            });
        } else {
            if (url.includes('undefined')) return Promise.reject('参数未设置完全');
            setLoading(true);
            if (method === 'post') {
                return new Promise((resolve,reject)=>{
                    API.post(url, params, token).then((res) => {
                        setData(res.data);
                        setError(null);
                        setLoading(false);
                        resolve(res.data);
                        return res;
                    }).catch((err) => {
                        setError(err);
                        setLoading(false);
                        reject(err)
                        return err;
                    })
                })
            } else {
                return new Promise((resolve,reject)=>{
                    API.get(url, token).then((res) => {
                        setData(res.data);
                        setError(null);
                        setLoading(false);
                        resolve(res.data);
                        return res.data;
                    }).catch((err) => {
                        setError(err);
                        setLoading(false);
                        reject(err);
                        return err;
                    })
                })
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            if (cancel_ref.current) {
                cancel_ref.current.cancel();
                cancel_ref.current = null;
            }
        }
    }, []);

    return { data, doFetch, loading, error, setLoading };
}

export function useMount(func) {
    useEffect(func, [func]);
}

export function useGet(url) {
    const [status, setStatus] = useState({ data: null, loading: true, error: null });
    const pre = useRef(status);
    const cancel_ref = useRef(API.cancelTokenSource());
    const update = useCallback((url) => {
        const s = pre.current;
        if (!s.loading)
            setStatus({ data: s.data, loading: true, error: s.error });
        const token = cancel_ref.current?.token;
        if (_.isArray(url)) {
            Promise.all(_.map(url, o => API.get(o, token))).then((values) => {
                setStatus({ data: _.map(values, v => v.data), loading: false, error: null });
            }).catch((err) => {
                console.error(err);
                setStatus({ data: null, loading: false, error: err });
            })
        } else {
            API.get(url, token).then((res) => {
                setStatus({ data: res.data, loading: false, error: null });
            }).catch((err) => {
                console.error(err);
                setStatus({ data: null, loading: false, error: err });
            });
        }
    }, [pre])

    useEffect(() => {
        pre.current = status;
    }, [status])

    useEffect(() => {
        if (_.isString(url) || _.isArray(url))
            update(url);
    }, [url, update])

    useEffect(() => {
        return () => {
            if (cancel_ref.current) {
                cancel_ref.current.cancel();
                cancel_ref.current = null;
            }
        }
    }, [])

    return { ...status, doFetch: update };
}

export function usePost(url, params) {
    const [status, setStatus] = useState({ data: null, loading: true, error: null });
    const pre = useRef(status);
    const cancel_ref = useRef(API.cancelTokenSource());
    const update = useCallback((url, params) => {
        const s = pre.current;
        if (!s.loading)
            setStatus({ data: s.data, loading: true, error: s.error });
        API.post(url, params, cancel_ref.current.token).then((res) => {
            setStatus({ data: res.data, loading: false, error: null });
        }).catch((err) => {
            console.error(err);
            setStatus({ data: null, loading: false, error: err });
        });
    }, [pre]);

    useEffect(() => {
        pre.current = status;
    }, [status])

    useEffect(() => {
        if (_.isString(url))
            update(url, params);
        return () => {
            if (cancel_ref.current) {
                cancel_ref.current.cancel();
                cancel_ref.current = null;
            }
        }
    }, [url, params, update])

    return { ...status, doFetch: update };
}

export function useInit() {
    const [initStatus,setInitStatus] = useState('init00');
    const render = useCallback(function render(rProps) {
        const {children} = rProps;
        return <Fragment>
            {['init00','loading'].includes(initStatus) && <Loader fill/>}
            {['init10','ProductNoData'].includes(initStatus) && <ProductNoData/>}
            {['init20','children'].includes(initStatus) && children}
        </Fragment>
    },[initStatus]);

    return [setInitStatus,render]
}

export function useEditTransfer({ options, name, key }) {
    const { TransferR, opts, setConfig, cancel, confirm } = useTransfer({ options, name, key });
    const [show, setShow] = useState(false);

    const DialogR_ = useCallback(() => {
        return <Fragment>
            {show && <Dialog header='字段配置' cancel={execFn(cancel)} confirm={execFn(confirm)}>
                <TransferR style={{ height: 450 }} />
            </Dialog>}
        </Fragment>;

        function execFn(f) {
            return ()=>{
                Fn(f)();
                setShow(false);
            }
        }
    }, [show,cancel,confirm]);

    const EditBtn = useCallback(()=>{
        return <TextIconBtn text='字段配置' icon='bianjizhibiaolie1'
                            style={{ marginLeft: 24 }}
                            onClick={() => setShow(true)}/>
    },[])

    return { DialogR_, EditBtn, opts, setConfig }
}

export function useTransfer(props) {
    const { options, name, key } = props;

    const [config, setConfig] = useState({ options, name });
    const [opts, setOpts] = useState([]);
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);

    const reset = useCallback((type='init')=>{
        return () => {
            const { options, name } = config;
            transHeaderToText(options);
            let loadData =  JSON.parse(localStorage.getItem(name)) || options.filter(x => x.isInitDis !== false);

            const cq = k => k ? x => x[k] : x => x;
            const q = compose(JSON.stringify, fp.omit(['order']), cq(key));
            const editOpts = options.filter(x => x.isTransfer !== false);
            let loadData_s = loadData.map(q);
            const options_s = options.map(q);
            if (loadData_s.some(x => !options_s.includes(x))) {
                loadData_s = options_s;
                loadData = options;
            } else {
                loadData = filterAndOrder(options);
            }
            const r = filterAndOrder(editOpts);
            const l = filterAndOrder(editOpts, true);
            if(type==='init') setOpts(loadData);
            setSource(l);
            setTarget(r);

            function filterAndOrder(opts, isOmit = false) {
                const res = _.filter(opts, (x) => {
                    x.order = _.indexOf(loadData_s, q(x));
                    return isOmit ? x.order === -1 : x.order !== -1;
                });
                return _.orderBy(res, 'order');
            }
        }
    }, [config, key]);

    const fixedOpts = useMemo(() => config.options.filter(x => x.isTransfer === false), [config]);

    const confirm = useCallback(()=>{
        if(isNoChange()) return ;
        const res = !_.isEmpty(target) ? fixedOpts.concat(target) : [];
        setOpts(res);
        localStorage.setItem(config.name, JSON.stringify(res));

        function isNoChange(){
            const { options, name } = config;
            transHeaderToText(options);
            let loadData =  JSON.parse(localStorage.getItem(name)) || options.filter(x => x.isInitDis !== false);
            loadData = loadData.filter(x=>x.isTransfer!==false);
            const currentData = _.map(target,x=>_.omit(x,['convert']));
            return _.isEqual(currentData,loadData )
        }
    },[fixedOpts,target,config])

    const TransferR = useCallback((rProps) => {
        return <Transfer source={source} target={target} onChange={handleTransfer} {...rProps} />;

        function handleTransfer(r, l) {
            setSource(l);
            setTarget(r);
        }
    }, [source, target]);

    // useEffect(reset(), [config]);
    useEffect(()=>{
        reset()
    }, [reset]);

    return { TransferR, opts, confirm, cancel: reset('cancel'), setConfig };
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Dialog} from "rootnet-ui";
import {FormInput, Select} from "rootnet-edit";
import {usePost} from "rootnet-biz/lib/hooks";
import _ from 'lodash'
import {errorTips, successTips} from "../../../../../../../utils/messagerTips";

function TraceRelateBranchDialog(props) {
    const {traceBizId, programId, close, relateBizId, traceDetail, refreshList, showDialogInfo} = props
    const [branch, setBranch] = useState()
    const {data: branchList, doFetch: getBranchList} = usePost()
    const {doFetch: relatePost} = usePost()

    useEffect(()=>{
        if(_.isNil(traceBizId)) return
        if(_.isNil(programId)) return
        if(_.isNil(traceDetail)) return
        if(_.isNil(relateBizId)) return
        const postParams = {
            traceId: traceBizId,
            programId,
            traceName: traceDetail?.tracerTitle,
            reqNewNo: relateBizId
        }
        getBranchList(`/devops/uprods/trace/program/relevanceBranchList`,postParams)
    },[getBranchList,traceBizId,programId, relateBizId, traceDetail])

    const branchListOptions = useMemo(()=>{
       return _.map(branchList, x => ({
           text: x,
           value: x
       }))
    },[branchList])

    const submit = useCallback(()=>{
        const postParams = {
            ...showDialogInfo?.componentInfo,
            branchName: branch
        }
        relatePost('/devops/uprods/trace/program/addOrUpdate',postParams).then(()=>{
            successTips('关联成功')
            refreshList()
            close()
        }).catch(err => {
            errorTips(err._message)
        })
    },[relatePost, showDialogInfo, refreshList, close, branch])

    return <Dialog header={'关联'} className={'trace-relate-branch-dialog'} cancel={close} contentStyle={{padding: 16}}
                   confirmButtonDisabled={_.isNil(branch)} confirm={submit}>
        <FormInput label={'关联分支'} value={branch} onChange={setBranch} componentWidth={280} component={Select} search
                   options={branchListOptions}/>
    </Dialog>
}

export default TraceRelateBranchDialog;
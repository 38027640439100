import React, {useCallback, useState} from 'react';
import './Workplace.scss'
import WorkplaceSummaryInfo from "./workplaceSummaryInfo/WorkplaceSummaryInfo";
import WorkplaceTodo from "./workplaceTodo/WorkplaceTodo";
import WorkplaceDynamic from "./workplaceDynamic/WorkplaceDynamic";
import WorkplaceCalendar from "./workplaceCalendar/WorkplaceCalendar";
import WorkplaceVersion from "./workplaceVersion/WorkplaceVersion";
import clsx from "clsx";
import _ from 'lodash'

function Workplace(props) {
    const [fullScreenCard, setFullScreenCard] = useState()

    const onSwitchFullScreen = useCallback((card)=>{
        if(fullScreenCard){
            setFullScreenCard(null)
        }else{
            setFullScreenCard(card)
        }
    },[fullScreenCard])

    return <div className={clsx('workplace-wrap fill flex-y', {'has-full-screen': !_.isNil(fullScreenCard)})}>
        <WorkplaceSummaryInfo {...{fullScreenCard}}/>
        <div className={clsx("second-row flex",{'has-show-card': _.includes(['todo'],fullScreenCard)})}>
            <WorkplaceTodo {...{fullScreenCard, onSwitchFullScreen}}/>
            <WorkplaceDynamic {...{fullScreenCard}}/>
        </div>
        <div className={clsx("third-row flex",{'has-show-card': _.includes(['calendar'],fullScreenCard)})}>
            <WorkplaceCalendar {...{fullScreenCard, onSwitchFullScreen}}/>
            <WorkplaceVersion {...{fullScreenCard}}/>
        </div>
    </div>
}

export default Workplace;
import {useMemo} from 'react';
import {useGet} from "rootnet-biz/es/hooks";
import convertGlobalConstOptions from "../../ConvertGlobalConstOptions";
import _ from 'lodash'

export default function useGetDateOptions(props) {
    const {data: dateRes} = useGet('/common/globalconst?globalConst=dateStyle')
    return useMemo(()=>{
        if(_.isEmpty(dateRes)) return []
        return convertGlobalConstOptions(dateRes)
    },[dateRes])
}
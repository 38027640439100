import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './LoginStatistics.scss'
import {DatePicker, Form, FormInput, Select} from "rootnet-edit";
import {Button,DataGrid,Pagination} from "rootnet-ui";
import {Box} from "../common/commonComponent";
import OperationList from "../../project_share/components/OperationList";
import _ from "lodash";
import UserLoginLog from "./userLoginLog/UserLoginLog";
import {useGet} from "rootnet-biz/es/hooks";
import {toDate,dateFormat} from 'rootnet-core/dateFormat'
import {strParams} from "../../utils/publicFun";

function getColumns(props){
    const {toDetail} = props
    return [
        { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 40,  },
        { header: '用户', bind: 'userName', align: 'center',width: 150, tooltip: true, sortable: true },
        { header: '岗位', bind: 'positionName', align: 'center', width: 150, tooltip: true, sortable: true },
        { header: '最后一次登录时间', bind: 'lastLoginTime', align: 'center', width: 250, tooltip: true, sortable: true,
            convert: v => dateFormat('YYYY-MM-DD HH:MM:SS')(toDate.str14ToDate(v))},
        { header: '累计登录次数', bind: 'totalLoginCountInt', align: 'center', width: 150, tooltip: true, sortable: true },
        {header: '操作', bind: '', align: 'center', width: 120, isTransfer: false, convert: renderOperation}
    ]
    function renderOperation(v, o) {
        const operateOption = [
            {
                text: '详情',
                mode: 'detail',
                onClick:() => toDetail(o.userId)
            }
        ];
        return <OperationList options={operateOption}/>;
    }
}

function getOptions(columns){
    return {
        autoFill: true,
        resizable: true,
        virtualized: false,
        nilText: '-',
        emptyText: '-',
        columns,
    }
}

const INIT_PARAMS = {
    userId: null,
    startTime: null,
    endTime: null,
    pageNum: 1,
    pageSize: 20,
}

function LoginStatistics(props) {
    const [params, setParams] = useState(INIT_PARAMS)
    const {data: listRes, doFetch: getList} = useGet()
    const [detailInfo, setDetailInfo] = useState()

    const { pageSize, total, pageNum, rows: list } = useMemo(() => listRes || {}, [listRes]);

    const refresh = useCallback(()=>{
        getList('/loginCount/list?'+strParams(params))
    },[getList,params])

    useEffect(()=>{
        refresh()
    },[refresh])

    const toDetail = useCallback((userId)=>{
        setDetailInfo({
            ...params,
            userId: userId,
        })
    },[params])

    const options = useMemo(()=>{
        return getOptions(getColumns({toDetail}))
    },[toDetail])

    return <div className={'login-statistics-wrap fill flex-y'}>
        <Option search={setParams} initParams={params}/>
        <Box title={'用户登录统计'} className='flex-y x-card-singlegrid' data={list}>
            <DataGrid data={list} option={options}/>
            <Pagination pageSize={pageSize} total={total} current={pageNum} selector
                        onChange={(pageNum, pageSize) => setParams(x => _.assign({}, x, { pageNum, pageSize }))} />
        </Box>
        {
            !_.isNil(detailInfo) &&
                <UserLoginLog initParams={_.pick(detailInfo, ['startTime', 'endTime', 'userId'])} close={()=>setDetailInfo(null)}/>
        }
    </div>
}

function Option(props){
    const {search, initParams} = props
    const [params, setParams] = useState(initParams)
    const { data: allUserRes } = useGet('/common/userinfo')

    const userOptions = useMemo(()=>{
        if(_.isEmpty(allUserRes)) return []
        return _.map(allUserRes, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}-${x.userName}`}))
    },[allUserRes])

    return <div className={'c-options'}>
        <Form value={params} onChange={setParams}>
            <FormInput label={'用户'} bind={'userId'} component={Select} clear search options={userOptions}/>
            <FormInput label={'最后一次登录时间'} bind={'startTime'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
            <span className={'form-text'}>至</span>
            <FormInput bind={'endTime'} component={DatePicker} clear bindOutConvert={v => dateFormat('YYYY-MM-DD',v)} bindInConvert={v => toDate.dateStringToDate(v)}/>
        </Form>
        <div className="search-btn-group flex">
            <Button className={'search-btn'} primary onClick={()=>search({...params,pageNum: 1,pageSize: 20,})}>查询</Button>
            <Button className={'reset-btn'} text onClick={() => {
                setParams(INIT_PARAMS)
            }}>重置</Button>
        </div>
    </div>
}

export default LoginStatistics;
import _ from 'lodash'

export function getUserDeptOptions(userRes, deptRes){
    const activeDepartmentList = _.filter(deptRes, x => x.activeFlag === 'Y')
    let options = getDepartmentChildren(userRes,activeDepartmentList,'')
    markNoChildren(options)
    _.forEach(options, x => delNoChildren(x))
    options = _.filter(options, x => !x._noChildren)
    return options || []
}

function getDepartmentChildren(allUserList,allDepartmentList,department){
    const userList = _.filter(allUserList, x => x.department === department)
    const userOptions = _.map(userList, x => ({value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`,type: 'user'}))
    const childDepartmentList = _.filter(allDepartmentList, x => x.parentDept === department)
    const childDepartmentOptions = _.map(childDepartmentList, x => ({
        text: x.departMentName,
        value: x.departMentId,
        type: 'department',
        children: getDepartmentChildren(allUserList,allDepartmentList,x.departMentId),
    }))
    return [...childDepartmentOptions,...userOptions]
}

function markNoChildren(list){
    if(list.length === 0 ) {
        return true
    }
    _.forEach(list, item => {
        if(item.type === 'user') return _.assign(item, {_noChildren: false})
        if(item.type === 'department') _.assign(item, {_noChildren: markNoChildren(item.children)})
        if(_.every(item.children, x => x._noChildren)) _.assign(item, {_noChildren: true, children: []})
    })
    return false
}

function delNoChildren(item){
    if(_.every(item.children, x => x._noChildren)) _.assign(item, {children: []})
    const filterList = _.filter(item.children, x => {
        return !x._noChildren
    })
    _.assign(item, {children: filterList})
    _.forEach(item.children, x => delNoChildren(x))
}
import _ from 'lodash'
import React, { Fragment, useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { Dialog, DataGrid, Messager } from 'rootnet-ui'
import { Select, FormInput, Form } from 'rootnet-edit'
import { Dateformat, N2 } from 'rootnet-core/format'
import { summaryWithHeader } from '../../../../utils/publicFun'
import { Box } from '../../../common/commonComponent'
import { TextIconBtn } from '../../../common/TextIconBtn'
import { useGet, useApi } from '../../../../utils/hook'
import gd from '../../../../base/global'
import { OperationList } from '../../../../project_share/components'

const optionsAuthorize = ({ setMode, setIsShowEditDialog, setIsShowDelDialog, setValue, lastPlanUseTime, flag }) => {
  return (
    [
      { header: '#', convert: (v, o, i) => (i + 1), align: 'center', width: 60 },
      { header: '参与人', bind: 'userName', width: 80, align: 'center' },
      { header: '计划用时(h)', bind: 'planUseTime', width: 100, align: 'right', isTotal: true, convert: convertTime },
      { header: '计划工时(h)', bind: 'planWorkTime', width: 100, align: 'right', isTotal: true, convert: convertTime },
      { header: '计划成本(元)', bind: 'planUseCost', width: 100, align: 'right', isTotal: true, convert: N2 },
      { header: '实际用时(h)', bind: 'actualUseTime', width: 100, align: 'right', isTotal: true, convert: convertTime },
      { header: '实际工时(h)', bind: 'actualWorkTime', width: 100, align: 'right', isTotal: true, convert: convertTime },
      { header: '实际成本(元)', bind: 'actualUseCost', width: 100, align: 'right', isTotal: true, convert: N2 },
      { header: '授权人', bind: 'authorizerName', width: 80, align: 'center' },
      { header: '授权时间', bind: 'authorizerTime', width: 165, convert: (v) => Dateformat(v, 'hr') },
      {
        header: "操作", align: "center", width: 110,
        convert: function _(e, o) {
          return <OperationList options={options_operation(o)} data={o} />
        }
      },
    ]
  )
  function options_operation(o) {
    return [
      {
        text: '修改',
        disabled: !flag,
        onClick: () => {
          setMode('edit')
          setIsShowEditDialog(true)
          setValue({ ...o, planUseTime: o.planUseTime / 60 })
          lastPlanUseTime.current = o.planUseTime / 60
        },
      },
      {
        text: '删除',
        disabled: !flag,
        onClick: () => {
          if (!_.isNil(o.actualUseTime) && o.actualUseTime !== 0) return Messager.show('已存在工时填报记录，不允许删除！', { icon: 'error' })
          setValue(o)
          setIsShowDelDialog(true)
        },
      }
    ]
  }
  function convertTime(v) {
    return (v / 60).toFixed(1)
  }
}

const gridOptsFor = (options, data) => ({
  columns: options,
  nilText: '-',
  emptyText: '-',
  summary: [
    summaryWithHeader(options, data)
  ]
});

export default function Dialogs(props) {
  const { projectId, id, setIsShow, planTime, principal, name } = props
  const { data, loading, error, doFetch } = useGet()
  const [multiSelect, setMultiSelect] = useState()
  const { doFetch: getDoFetch } = useApi()
  const [mode, setMode] = useState()
  const [isShowEditDialog, setIsShowEditDialog] = useState(false)
  const [isShowDelDialog, setIsShowDelDialog] = useState(false)
  const [proManager, setproManager] = useState({})
  const [value, setValue] = useState({})
  const lastPlanUseTime = useRef()
  const flag = useMemo(() => _.get(gd, '_user.operator_id') === principal, [principal])
  const options = useMemo(() => {
    return gridOptsFor(optionsAuthorize({ setMode, setIsShowEditDialog, setIsShowDelDialog, setValue, lastPlanUseTime, flag }), _.get(data, 'authorityVos'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const init = useCallback((id) => doFetch(`/authority/list?taskId=${id}&projectType=DEV`), [doFetch])
  useEffect(() => {
    init(id, projectId)
    getDoFetch('/common/userinfo').then(res => {
      const opts = _.map(res, x => ({ value: x.userAccount, text: x.userName, tag: `${x.userAccount}${x.userName}`, factor: x.factor }))
      setproManager(opts)
    })
  }, [doFetch, projectId, init, id, getDoFetch])
  return (
    <Fragment>
      <Dialog header={name} className='AuthorizeDialog' confirmButtonVisible={false} cancel={() => setIsShow(false)} >
        <div className='AuthorizeDialog-hour'>
          <div className='AuthorizeDialog-hour-top'>
            <div>
              <span>可授权工时：{(planTime * 8) - (_.get(data, 'planTotalWorkTime') / 60) < 0.1 ? 0 : (((planTime * 8) - (_.get(data, 'planTotalWorkTime')) / 60)).toFixed(1)}h</span>
              <span>已授权工时：{(flllHour(_.get(data, 'authorityVos')) || 0).toFixed(1)}h</span>
            </div>
            {
              flag &&
              <div style={{ display: 'flex' }}>
                <TextIconBtn text='新增' icon='tianjia' onClick={() => { setMode('add'); setIsShowEditDialog(true) }} />
                <TextIconBtn text='批量新增' icon='tianjia' onClick={() => { setMode('addAll'); setIsShowEditDialog(true) }} />
                <TextIconBtn text='批量修改' icon='bianji' disabled={!_.size(multiSelect) > 0} onClick={() => { setMode('editAll'); setIsShowEditDialog(true) }} />
                <TextIconBtn text='批量删除' icon='shanchu' disabled={!_.size(multiSelect) > 0} onClick={() => setIsShowDelDialog('deleteAll')} />
              </div>
            }
          </div>
          <Box data={_.get(data, 'authorityVos')} loading={loading} error={error} className='AuthorizeDialog-list'>
            <DataGrid data={_.get(data, 'authorityVos')} option={options} onSelectionChange={setMultiSelect} />
          </Box>
        </div>
      </Dialog>
      {isShowEditDialog && dialog(mode)}
      {isShowDelDialog && <Dialog className='AuthorizeDialogDel' cancel={() => setIsShowDelDialog(false)} header='提示' confirm={() => { setValue({}); delConfirm(isShowDelDialog) }}>
        <div className='text'> 确定要删除“参与人”的授权？</div>
      </Dialog>}
    </Fragment>
  )

  function addConfirm(mode) {
    const isMode = mode === 'add'
    const addData = _.assign(
      {
        projectid: projectId,
        taskId: id,
        projectType: 'PRESALES',
        planUseTime: value.planUseTime * 60,
      },
      { userIdList: isMode ? [value.userIdList] : value.userIdList }
    )
    //授权用时超过当前人员最大可授权用时
    if (!_.isNil(_.get(data, 'authorityVos')) && _.get(data, 'authorityVos').filter(x => _.find(isMode ? [value.userIdList] : value.userIdList, v => x.userId === v)).length !== 0) return Messager.show('参与人不能重复', { icon: 'error' });
    let hour = factor() * _.get(value, 'planUseTime') + flllHour(_.get(data, 'authorityVos')) - planTime * 8//新增授权公式
    if (Number(planTime) === 0) return Messager.show('当前无可授权工时', { icon: 'error' })
    if (hour > 0) return Messager.show('授权用时超过当前人员最大可授权用时', { icon: 'error' })
    if (_.get(value, 'planUseTime') < 0) return
    if (userItemFactor(addData.userIdList)) return
    getDoFetch('/authority/add', 'post', addData).then(res => {
      Messager.show(res.msg, { icon: 'success' });
      setIsShowEditDialog(false)
      setMultiSelect(null)
      init(id, projectId)
      setValue({})
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      setValue({})
    })
  }
  function editConfirm(mode) {
    const isMode = mode === 'edit'
    const editData = _.assign(
      {
        idList: isMode ? [_.get(value, 'id')] : aggregate('id'),
        planUseTime: value.planUseTime * 60
      },
      { userIdList: isMode ? [value.userId] : value.userIdList }
    )

    if (getHover(lastPlanUseTime.current instanceof Array)) return Messager.show('授权用时超过当前人员最大可授权用时', { icon: 'error' })
    if (_.get(value, 'planUseTime') < 0) return
    if (userItemFactor(editData.userIdList)) return
    getDoFetch('/authority/modify', 'post', editData).then(res => {
      Messager.show(res.msg, { icon: 'success' });
      setIsShowEditDialog(false)
      setMultiSelect(null)
      init(id, projectId)
      setValue({})
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      setValue({})
    })
  }
  function delConfirm(del) {
    const params = { idList: del === 'delete' ? [_.get(value, 'id')] : aggregate('id') }
    getDoFetch('/authority/delete', 'post', params).then(res => {
      Messager.show(res.msg, { icon: 'success' });
      setIsShowDelDialog(false)
      setMultiSelect(null)
      init(id, projectId)
      setValue({})
    }).catch(err => {
      Messager.show(err._message, { icon: 'error' });
      setValue({})
    })
  }
  function dialog(mode) {
    const title = Object.freeze({ add: '新增', addAll: '批量新增', edit: '修改', editAll: '批量修改' })
    if (mode === 'editAll') {
      value.userIdList = aggregate('userId');
      lastPlanUseTime.current = _.reduce(aggregate('planUseTime'), (result, val) => {
        result.push(val / 60)
        return result
      }, [])
    }
    return (
      <Dialog className='AuthorizeDialogEdit' cancel={() => { setValue({}); setIsShowEditDialog(false) }} header={title[mode]}
        confirm={() => (mode === 'add' || mode === 'addAll') ? addConfirm(mode) : editConfirm(mode)} >
        <Form onChange={setValue} value={value}>
          <FormInput
            search
            required
            horizontal
            label="参与人"
            component={Select}
            options={proManager}
            clear={mode === 'add'}
            disabled={(mode === 'edit' || mode === 'editAll')}
            multiple={(mode === 'addAll' || mode === 'editAll')}
            bind={mode !== 'edit' ? 'userIdList' : 'userId'} />
          <FormInput label="计划用时" required horizontal bind="planUseTime" search type='number' digit={1} min={0} suffix='h' />
        </Form>
        {
          !(mode === 'addAll' || mode === 'editAll') &&
          <span style={{ marginLeft: '13px', fontSize: '10px', color: '#738299' }}>
            最大可授权用时 : &nbsp;{factor() ? (((planTime * 8) - (_.get(data, 'planTotalWorkTime')) / 60) / factor()).toFixed(1) : 0}h
					</span>
        }
      </Dialog >
    )
  }
  function userItemFactor(data) {
    const factorArr = []
    const filterData = _.filter(proManager, item => _.find(data, v => item.value === v))
    const userFactor = _.reduce(filterData, (result, o) => {
      if (o.factor === 0) factorArr.push(o.text)
      result.push(o.factor)
      return result
    }, [])
    if (_.isNil(filterData) || _.size(_.compact(userFactor)) !== _.size(userFactor)) {
      Messager.show(`参与人【${_.join(factorArr, ',')}】无Cost Rate值`, { icon: 'error' })
      return true
    }
    return false
  }
  function getHover(type) {
    if (type) {
      return _.some(lastPlanUseTime.current, v => {
        return (factor() * (_.get(value, 'planUseTime') - v) + flllHour(_.get(data, 'authorityVos')) - planTime * 8) > 0
      })
    }
    return (factor() * (_.get(value, 'planUseTime') - lastPlanUseTime.current) + flllHour(_.get(data, 'authorityVos')) - planTime * 8) > 0
  }
  function aggregate(name) {
    return _.reduce(multiSelect, (result, value) => {
      result.push(value[name])
      return result
    }, [])
  }
  function flllHour(data) {
    return !_.isNil(data) && data.reduce((x, item) => x + item.planWorkTime, 0) / 60
  }
  function factor() {
    if (mode === 'edit') return _.get(_.filter(proManager, x => x.value === value.userId), '[0].factor')
    else if (mode === 'add') return _.get(_.filter(proManager, x => x.value === value.userIdList), '[0].factor')
    return _.reduce(_.filter(proManager, x => _.find(value.userIdList, v => x.value === v)), (result, val) => result + val.factor, 0)
  }
}

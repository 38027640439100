import React, { useState, useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import { Dialog, Messager } from 'rootnet-ui'
import { isNil } from 'rootnet-core/format'
import { Form, FormInput, Select, TextArea } from 'rootnet-edit'
import './AddTaskForms.scss'

const HFormInput = (props) => <FormInput {...props} labelWidth={120} componentWidth={200} horizontal />

const whetherOptions = [
  { text: '是', value: 'Y' },
  { text: '否', value: 'N' },
]

export default function AddTaskForms(props) {
  const { data, setVisible, segmentOpt, priorityOpt, keyNodesOpt, projectRoleList, addTask, detailData, index = null, isDevProject } = props
  const { segment: initSegment } = data || {}
  const [params, setParams] = useState()
  const [error, setError] = useState()
  const segmentOpts = useMemo(() => _.map(segmentOpt, o => ({ text: o.label, value: o.value, sort: o.sort })), [segmentOpt])
  const priorityOpts = useMemo(() => _.map(priorityOpt, o => ({ text: o.label, value: o.value })), [priorityOpt])
  const keyNodesOpts = useMemo(() => _.map(keyNodesOpt, o => ({ text: o.label, value: o.value })), [keyNodesOpt])

  const getSegmentSort = useCallback((value) => {
    return _.get(_.find(segmentOpt, x => x.value === value), 'sort')
  }, [segmentOpt])

  const check = useCallback(() => {
    return _.every(_.values(error), isNil)
  }, [error])

  const handCilck = useCallback(() => {
    if (!check()) return Messager.show('请填写必填项', { icon: 'error' })
    if (_.size(_.trim(params.itemName)) === 0) return Messager.show('请填写内容', { icon: 'error' })
    let newSerial = null
    if (!_.isNil(index)) {
      const previous = detailData[index].sortSerial
      const next = _.get(detailData, `[${index + 1}]sortSerial`)
      newSerial = _.isNil(next) ? previous + 1 : (previous + next) / 2
    }
    const parameter = _.assign({}, params, !_.isNil(newSerial) && { sortSerial: newSerial })

    if (_.get(parameter, 'segment') !== initSegment && _.size(detailData) !== 0) {
      let normalSort
      if (index + 1 !== _.size(detailData)) {
        normalSort = getSegmentSort(_.get(parameter, 'segment')) <= getSegmentSort(_.get(detailData[index + 1], 'segment'))
          && getSegmentSort(_.get(parameter, 'segment')) >= getSegmentSort(_.get(detailData[index], 'segment'))
      } else {
        normalSort = false
      }
      if (!normalSort) {
        const biggerSortItem = _.find(detailData, x => getSegmentSort(_.get(x, 'segment')) > getSegmentSort(_.get(parameter, 'segment')))
        if (_.isNil(biggerSortItem)) {
          parameter['sortSerial'] = _.toNumber(_.get(_.last(detailData), 'sortSerial')) + 1
        } else {
          const biggerSortItemIndex = _.findIndex(detailData, x => x.id === biggerSortItem.id)
          if (biggerSortItemIndex === 0) {
            parameter['sortSerial'] = _.toNumber(_.get(biggerSortItem, 'sortSerial')) / 2
          } else {
            parameter['sortSerial'] = (_.toNumber(_.get(detailData[biggerSortItemIndex - 1], 'sortSerial')) + _.toNumber(_.get(biggerSortItem, 'sortSerial'))) / 2
          }
        }
      }
    }
    addTask(parameter, index)
  }, [addTask, check, detailData, getSegmentSort, index, initSegment, params])

  useEffect(() => {
    setParams(_.assign(_.isNil(data) ? {} : data, { priority: 'B' }, isDevProject && { isParent: 'N', milestoneFlag: 'N' }))
  }, [data, isDevProject])

  const handChange = useCallback((o, k) => {
    if (k === 'isParent') {
      o['manageRole'] = null
    }

    setParams(o)
  }, [])

  useEffect(() => {
    setError(x => _.assign({}, x, {
      manageRole: (_.get(params, 'isParent') === 'N' && _.isNil(_.get(params, 'manageRole'))) ? '必填项！' : null
    }))
  }, [params])

  return (
    <Dialog
      header={'新建'}
      confirm={handCilck}
      cancel={() => { setVisible(null); setParams({}) }}
    >
      <div className='template-add-task-forms'>
        <Form value={params} onChange={handChange} error={error} onError={setError}>
          <HFormInput required label='阶段/环节' bind='segment' options={segmentOpts} component={Select} />
          {isDevProject && <HFormInput required label='关键节点' bind='keyNodes' options={keyNodesOpts} component={Select} />}
          <HFormInput required label='优先级' bind='priority' options={priorityOpts} component={Select} />
          <HFormInput type='number' digit={1} min={0} max={99999} required={!isDevProject} label='标准工时(人日)' bind='standUseTime' />
          <HFormInput required label='任务名称' bind='itemName' maxLength={50} />
          {isDevProject && <HFormInput label='子任务' bind='sonTask' />}
          {isDevProject && <HFormInput required label='是否父级阶段' bind='isParent' options={whetherOptions} component={Select} />}
          {isDevProject && <HFormInput required label='里程碑' bind='milestoneFlag' options={whetherOptions} component={Select} />}
          {(isDevProject && _.get(params, 'isParent') === 'N') && <HFormInput required label='负责角色' bind='manageRole' options={projectRoleList} component={Select} />}
          <div />
          <HFormInput label='完成标准/提交物' bind='completedNotes' component={TextArea} maxLength={150} />
          <HFormInput label='详细说明' bind='detail' component={TextArea} maxLength={150} />
        </Form>
      </div>
    </Dialog>
  )
}
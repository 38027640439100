import _ from "lodash"

const initParams = {
  demand: {
    old: ['requirementId', 'interiorReqNo', 'requirementTitle', 'reqStatus', 'reqStatusName', 'haveSon', 'jiraId'],
    new: ['id', 'interiorReqNo', 'title', 'reqStatus', 'ConstDisplayName', 'haveSon', 'jiraId'],
  },
  tracking: {
    old: ['tracerId', 'tracerTitle', 'tracerState', 'tracerStateName'],
    new: ['tracerId', 'tracerTitle', 'tracerState', 'ConstDisplayName'],
  },
  defect: {
    old: ['id', 'defectId', 'defectTitle', 'defectStatus'],
    new: ['id', 'defectId', 'title', 'status'],
  }
}
export default function initData(data, type) {
  const newParams = _.get(initParams, `${type}.new`)
  const oldParams = _.get(initParams, `${type}.old`)
  return _.map(data, item => {
    return _.assign(..._.map(oldParams, (v, i) => ({ [newParams[i]]: item[oldParams[i]] })))
  })
}



import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Messager, DataGrid, Card, Button } from 'rootnet-ui';
import { hasData } from 'rootnet-core/system';
import _ from 'lodash';
import Api from '../../../../base/task';
import Option from '../common/option';
import { strParams } from '../../../../utils/publicFun';
import { getAllDate, getTabData, getCol, SearchBox } from '../common/commonfunc';
import { Box } from '../../../common/commonComponent';
import IconButton from '../../../../components/IconButton';
import Memo from '../common/Remark';
import './../common/common.scss';

let beginDate = '';
function optionFor(obj, tableData, setTableData) {
    return [
        { header: '服务单ID/名称', bind: 'csRecordId', convert: (v, o) => v ? v.concat(`-${o.issueDesc}`) : '', width: 440, tooltip: true },
        { header: '累计投入', bind: 'sumUseTime', convert: (v) => v ? v / 60 : '', width: 80 },
        {
            header: '本周投入', bind: 'createUser', width: 80,
            convert: (v, o, i) => {
                let num = 0;
                _.forEach(obj, (item, i2) => {
                    let v = o[`whichDay${item.date.split('-').join('')}`]
                    v = v ? parseFloat(v) : 0;
                    num += v;
                })
                return <span className="primary">{num}</span>
            }
        }
    ]
}

function Server(props) {
    const { sonData } = props;
    const [flag, setFlag] = useState(null)
    const [ajax, setAjax] = useState({})
    const [tableData, setTableData] = useState(null)  //被编辑的data
    const [orgData, setOrgData] = useState(null)  //表格化处理前的全量data
    const [option, setOption] = useState(null)
    const [showBox, setShowBox] = useState(false);
    const [noBox, setNoBox] = useState(false);
    const editItem = useRef(null);
    const [memo, setMemo] = useState('');
    const [key, setKey] = useState(1);
    const [addcsRecordId, setCsRecordId] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [markable, setMarkable] = useState(false);
    const [update, setUpdate] = useState(0)
    let maxDay = useRef('');
    const date = useRef('');
    const init = useCallback((data, obj) => {
        let par = {
            jobType: _.get(sonData, 'id'),
            beginDate: data.beginDate,
            endDate: data.endDate
        }
        Api.get(`/worktime/service/detail?${strParams(par)}`).then(res => {
            setOrgData(_.cloneDeep(res.data));
            let _data = getTabData(res.data.map(x => {
                if (x.auditUseTime) x.auditUseTime /= 60;
                return x
            }), 'csRecordId', "csRecordId");
            setTableData(_data)
            getOpt(_data)
            setAjax({ loading: false })
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
            setAjax({ loading: false })
        })

        function getOpt(_data) {
            if (maxDay.current) {
                return setOption(getCol(obj, _data, setTableData, optionFor, maxDay.current, 0, 'csRecordId'));
            }
            // 获取最大修改日期
            let par = { paraId: 'WorkTimeDateline' }
            Api.get(`/common/globalpara?${strParams(par)}`).then(res => {
                maxDay.current = _.get(res, 'data.paraValue')
                setOption(getCol(obj, _data, setTableData, optionFor, maxDay.current, 0, 'csRecordId'));
            }).catch((err) => {
                Messager.show(err.Message, { icon: 'error' });
            })
        }
    }, [sonData])

    useEffect(() => {
        setFlag(_.assign({}, { flag: '' }))
    }, [props])

    useEffect(() => {
        if (_.isNil(flag)) return;
        setAjax({ loading: true })
        if (!flag.flag) beginDate = '';
        let par = { beginDate, flag: flag.flag };
        Api.get(`/worktime/plandate?${strParams(par)}`).then(res => {
            date.current =res.data;
            beginDate = _.get(res, 'data.beginDate');
            let _week = getAllDate(res.data);
            init(res.data, _week);
        }).catch((err) => {
            Messager.show(err.Message, { icon: 'error' });
        })
    }, [flag, init])

    const search = useCallback(()=> {
        let params = getData(orgData, tableData, _.get(sonData, 'id'));
        // 过滤没有改变的数据  actionType 不纯在的
        params = _.filter(params, item => item.actionType);
        if (_.isEmpty(params)) return Messager.show('请修改后提交', { icon: 'error' })
        if (params.some(x=>Number(x.auditUseTime)<0)) return Messager.show('请填写正确的工时格式', { icon: 'error' })
        setAjax({ loading: true })
        Api.post('/worktime/edit', params).then(res => {
            init(date.current,getAllDate(date.current))
            Messager.show(res.msg, { icon: 'success' })
            setAjax({ loading: false })
        }).catch(err => {
            console.log(err)
            Messager.show(err._message, { icon: 'error' })
            setAjax({ loading: false })
        })
    },[orgData,tableData,sonData,init,setAjax])

    const filterDate = useMemo(() => {
        if (hasData(tableData)) {
            let list = _.concat([], tableData);
            if (searchText) {
                list = _.filter(list, item => {
                    return _.includes(item.csRecordId, searchText) || _.includes(item.issueDesc, searchText)
                })
            }
            return list
        }
        return []
    }, [tableData, searchText])

    useEffect(() => {
        if (searchText && hasData(tableData)) {
            tableData.forEach(x => x._rowClass = '');
            editItem.current = null;
            setMemo('');
            setMarkable(false)
        }
    }, [searchText, tableData])

    useEffect(() => {
        if (editItem.current) {
            setTableData(x => {
                _.find(x, item => item.csRecordId === editItem.current).remark = memo;
                return [...x]
            })
        }
    }, [memo, setTableData])

    useEffect(() => {
        setKey(x => x + 1)
        setMemo('')
        editItem.current = null
        setMarkable(false)
    }, [setKey, flag, setMarkable])

    useEffect(() => {
        if (editItem.current && tableData) {
            setMarkable(_.filter(_.entries(tableData.find(item => item.csRecordId === editItem.current)), x => x[0].includes('whichDay')).some(x => x[1]))
        }
    }, [tableData, setMarkable, update])

    const extra = <>
        <IconButton icon='tianjia' primary onClick={() => setShowBox(true)}>新增服务单</IconButton>
        <Button primary onClick={_.debounce(search, 2000)}>提交</Button>
    </>

    return <Card className="work_Time_list" loading={ajax.loading} >
        <Option
            rightConvert={extra}
            flag={_.get(flag, 'flag', '')}
            weekChange={(v) => setFlag({ flag: v })}
            search={search}
            inpSearch={setSearchText}
        />
        <div className="list-con">
            <Box className='flex-y x-card-singlegrid' data={filterDate} loading={!option || ajax.loading}>
                <DataGrid key={key} option={option} data={filterDate} className='row-fill choice'
                    onRowClick={(v) => {
                        _.forEach(filterDate, e => e._rowClass = '');
                        v._rowClass = 'deep';
                        editItem.current = v.csRecordId;
                        setUpdate(x => x + 1);
                        setMemo((_.isNull(_.get(v, 'remark', '')) ? '' : _.get(v, 'remark')))
                    }
                    } />
            </Box>
            <Memo onChange={setMemo} value={memo} markable={markable} />
        </div>{
            showBox && <SearchBox label={'服务单'} confirm={addTraConfirm} cancel={() => setShowBox(false)} onChange={setCsRecordId} value={addcsRecordId} />
        }
        {noBox && <SearchBox confirm={() => { setShowBox(true); setNoBox(false) }} nodata width={10} className='nodata' />}
    </Card>

    function addTraConfirm() {
        Api.get(`/serviceSelf/relatedlist?csRecordId=${addcsRecordId}`).then(res => {
            setShowBox(false)
            if (!hasData(res.data)) setNoBox(true)
            else {
                let list = [];
                list = _.filter(res.data, x => !tableData.some(item => item.csRecordId === x.csRecordId));
                if (list.length > 0) {
                    list.forEach(x => { if (x.auditUseTime) x.auditUseTime /= 60 });
                    setTableData(x => {
                        return getTabData(x.concat(list), 'csRecordId', "csRecordId");
                    })
                } else {
                    Messager.show('选择项重复')
                }
            }
        }).catch(err => Messager.show(err.Message, { icon: 'error' }))
        setShowBox(false)
    }
}

const getData = (orgData, tableData, pid) => {
    let _data = _.cloneDeep(tableData);
    // 1: tableData 分解
    let dataList = [];
    _.forEach(_data, it => {
        _.forIn(it, (v, k) => {
            if (_.includes(k, 'whichDay')) {
                let _par = {
                    workDate: k.split('Day')[1],
                    auditUseTime: v,
                    remark: it.remark,
                    jobType: pid
                }
                const preData = orgData.find(x => x.csRecordId === it.csRecordId && x.workDate === _par.workDate)
                dataList.push(_.assign({}, _.omit(it, ['auditUseTime', 'workDate', 'id']), _.omit(preData, ['auditUseTime', 'workDate']), _par));
            }
        })

    })
    let list = _.map(dataList, it => {
        const flag = _.find(orgData, it2 => {
            return it2.workDate === it.workDate && it2.csRecordId === it.csRecordId
        })
        // 1: 以前不存在，现在存在  add
        // 2：以前存在，现在存在   且不相等   edit
        // 3：以前存在，现在不存在  del
        if (!flag) {
            it.actionType = 'ADD';
            it.content = it.csRecordId.concat(`-${it.issueDesc}`)
            it.status = 'approve';
            it.auditUseTime = it.auditUseTime * 60;
            it = _.pick(it, ['jobType', 'workDate', 'content', 'remark', 'auditUseTime', 'status', 'csRecordId', 'actionType'])
        }
        else {
            if ((_.toNumber(flag.auditUseTime) / 60 !== _.toNumber(it.auditUseTime)) || flag.remark !== it.remark) {
                it.actionType = 'EDIT';
                it.auditUseTime = it.auditUseTime * 60;
                it = _.pick(it, ['id', 'auditUseTime', 'actionType', 'remark'])
            }
            if (flag.auditUseTime && !it.auditUseTime) {
                it.actionType = 'DEL';
                it = _.pick(it, ['id', 'actionType'])
            }
        }
        return it;
    })
    return list;
}


export default Server

import React, {useCallback, useMemo, useState} from 'react';
import './WorkTimeSelectProject.scss'
import {Empty, Input} from "antd";
import _ from 'lodash'
import {isNil} from "../../../appraise/components/method";
import convertOptions from "../../../common/ConvertOptions";
import {usePost} from "rootnet-biz/lib/hooks";
import {Messager} from "rootnet-ui";

function WorkTimeSelectProject(props) {
    const {close, onSelect, devProjectStatusOptions, recommendProjectList, allProjectList, allUserRes, othersReport} = props
    const [searchKey, setSearchKey] = useState()
    const {doFetch: checkProject} = usePost()
    const [loading, setLoading] = useState(false)

    const showProjectList = useMemo(()=>{
        if(isNil(searchKey)) return allProjectList
        return _.filter(allProjectList, x =>
            _.includes(_.toLower(x.projectName), _.toLower(searchKey)) || _.includes(_.toLower(x.projectID), _.toLower(searchKey)))
    },[searchKey, allProjectList])

    const onSelectItem = useCallback((obj)=>{
        if(loading) return
        setLoading(true)
        checkProject('/workingHoursManagement/selectCostForProjectForShuCost',_.assign({},{projectId: obj.id}, !isNil(othersReport)&&{userId: othersReport})).then(res => {
            setLoading(false)
            if(res === '可填报'){
                onSelect({
                    projectId: obj.id,
                    projectName: obj.projectName,
                    projectCode: obj.projectID,
                })
                close()
            }else if(res === '预算不足'){
                Messager.show(`${obj.projectName}项目预算不足，请联系项目PO${convertOptions(obj.poUser, allUserRes, 'userName','userAccount')}，做成本预算变更后提交！`,{icon:'error'});
            }else if(res === '项目已删除'){
                Messager.show(`${obj.projectName}项目已删除`,{icon:'error'});
            }else if(res === '006'){
                Messager.show(`${obj.projectName}项目，产品预计工作量为空或者不足，请联系项目PO${convertOptions(obj.poUser, allUserRes, 'userName','userAccount')}，做产品预计工作量变更后提交！`,{icon:'error'});
            }else if(res === '001'){
                Messager.show(`${obj.projectName}项目，研发预计工作量为空或者不足，请联系项目PO${convertOptions(obj.poUser, allUserRes, 'userName','userAccount')}，做研发预计工作量变更后提交！`,{icon:'error'});
            }else if(res === '016'){
                Messager.show(`${obj.projectName}项目，测试预计工作量为空或者不足，请联系项目PO${convertOptions(obj.poUser, allUserRes, 'userName','userAccount')}，做测试预计工作量变更后提交！`,{icon:'error'});
            }else if(res === '000'){
                Messager.show(`${obj.projectName}项目，管理预计工作量为空或者不足，请联系项目PO${convertOptions(obj.poUser, allUserRes, 'userName','userAccount')}，做管理预计工作量变更后提交！`,{icon:'error'});
            }
        }).catch((err)=>{
            setLoading(false)
            Messager.show(err._message,{icon:'error'});
        })
    },[onSelect, close, checkProject, loading, allUserRes, othersReport])

    return <div className={'work-time-select-project flex-y'}>
        <Input placeholder="搜索项目关键字" value={searchKey} onChange={e => setSearchKey(e.target.value)} allowClear={true}/>
        <div className={'work-time-select-project-tips'}>
            补丁版本项目工时，请填报在【事务】类型下
        </div>
        <div className="project-list-wrap">
            {
                isNil(searchKey) && !_.isEmpty(recommendProjectList) &&
                <div className="header-text">
                    您可能参与的项目
                </div>
            }
            {
                isNil(searchKey) && !_.isEmpty(recommendProjectList) &&
                <div className="project-list flex-y recommend-project-list">
                    {
                        _.map(recommendProjectList, x => {
                            return <div className="project-item flex center-y" onClick={()=>onSelectItem(x)} key={`recommend-`+x.id}>
                                <div className="project-name">
                                    {x.projectName}
                                </div>
                                <div className="project-state" style={{color: convertOptions(x.status, devProjectStatusOptions, 'color')}}>
                                    {convertOptions(x.status, devProjectStatusOptions)}
                                </div>
                                <div className="project-code">
                                    {x.projectID}
                                </div>
                            </div>
                        })
                    }
                </div>
            }
            {
                isNil(searchKey) &&
                <div className="header-text all-list-header-text">
                    项目列表
                </div>
            }
            <div className="project-list flex-y">
                {
                    _.map(showProjectList, (x, index) => {
                        return <div className="project-item flex center-y" onClick={()=>onSelectItem(x)} key={x.id + "-" + index}>
                            <div className="project-name one-line-text">
                                {x.projectName}
                            </div>
                            <div className="project-state" style={{color: convertOptions(x.status, devProjectStatusOptions, 'color')}}>
                                {convertOptions(x.status, devProjectStatusOptions)}
                            </div>
                            <div className="project-code one-line-text">
                                {x.projectID}
                            </div>
                        </div>
                    })
                }
            </div>
            {
                _.isEmpty(showProjectList) &&
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </div>
    </div>
}

export default WorkTimeSelectProject;